import React, {useMemo} from 'react';
import { useWindowDimension } from 'hooks';
import FilterButton from './filter';
import ScheduleButton from './schedule';
import RequestButton from './request';
import EditButton from './edit';
import { useSelector } from 'react-redux';
import { REQUEST_MISSING_DATES, REQUEST_UW_PARENT, SCHEDULE_MISSING_DATES, SCHEDULE_UW_PARENT } from './tooltipTexts';

const SchedulerButtons = React.forwardRef((props, schedulerRef) => {
    const windowDimension = useWindowDimension();
    const {
        currentSelectedBooking,
        currentSelection: { rowTags },
    } = useSelector(state => state.scheduler);

    const isUnassignedParent = rowTags?.type === 'UNASSIGNED_PARENT';
    const disabledButtons = currentSelectedBooking.id || isUnassignedParent;

    const disabledScheduleTooltip = useMemo(() => {
        if (currentSelectedBooking.id) {
            return SCHEDULE_MISSING_DATES;
        }
        if (isUnassignedParent) {
            return SCHEDULE_UW_PARENT;
        }
    }, [currentSelectedBooking.id, isUnassignedParent]);

    const disabledRequestTooltip = useMemo(() => {
        if (currentSelectedBooking.id) {
            return REQUEST_MISSING_DATES;
        }
        if (isUnassignedParent) {
            return REQUEST_UW_PARENT;
        }
    }, [currentSelectedBooking.id, isUnassignedParent]);

    return (
        <div className="d-flex justify-content-end">
            <FilterButton />
            <ScheduleButton
                ref={schedulerRef}
                {...windowDimension}
                disabled={disabledButtons}
                disabledTooltip={disabledScheduleTooltip}
                viewObject={props.viewObject}
                viewData={props.viewData}
            />
            <RequestButton
                ref={schedulerRef}
                {...windowDimension}
                disabled={disabledButtons}
                disabledTooltip={disabledRequestTooltip}
                viewObject={props.viewObject}
                viewData={props.viewData}
            />
            <EditButton ref={schedulerRef} {...windowDimension} />
        </div>
    );
});

export default SchedulerButtons;
