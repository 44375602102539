import React from 'react';
import PropTypes from 'prop-types';
import { ColorBlock, Wrapper } from './styles';

const ProjectStatusItem = ({ item, color }) =>
    Object.keys(item).length ? (
        <Wrapper>
            <ColorBlock color={color} /> {item.display}
        </Wrapper>
    ) : null;

ProjectStatusItem.propTypes = {
    item: PropTypes.object.isRequired,
    color: PropTypes.string.isRequired,
};

export default ProjectStatusItem;
