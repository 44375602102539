import { createSelector } from 'reselect';

export const getTags = state => state.tagReducer;

export const makeGetTags = tagType =>
    createSelector(getTags, tags => {
        let tagsByType;
        if (tagType) {
            tagsByType = tags[tagType];
        } else {
            tagsByType = tags.projectTags.concat(tags.resourceTags);
        }

        return tagsByType;
    });
