import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { params, childRoutePaths, routePaths } from 'utils/routeHelper';

export const getViewObjectSelector = resourceType => state => {
    const pathname = state.router.location.pathname;

    return getViewObject(params([...childRoutePaths, ...routePaths], pathname)?.params || {}, resourceType);
};

export const selectRouteParams = state => {
    const pathname = state.router.location.pathname;

    return params([...childRoutePaths, ...routePaths], pathname)?.params || {};
};
