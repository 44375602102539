import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { keys, map } from 'lodash';
import { RequestedLabel } from './styles';
import classNames from 'classnames';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { store } from '../../../../store';
import { makeStyles } from '@material-ui/core/styles';
import DependencyIcon from '../../../../assets/images/icons/multiple_stop.svg';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import AlarmIcon from '@material-ui/icons/Alarm';

const EventBubble = props => {
    const booking = props;
    const {
        totalEventTime,
        loggedInRights,
        keyWords,
        isDependencyExtensionInstalled,
        isDeadlinesExtensionInstalled,
    } = props;
    const classes = useStyles();

    const displayLinkedIcon = Boolean(
        isDependencyExtensionInstalled && (booking.childIds?.length || booking.parentIds?.length)
    );
    const hasRequestNotes = booking?.approvalInfo?.requesterNote != null && booking?.approvalInfo?.requesterNote !== '';
    const categoryName = booking.categoryTemplate ? ` - ${booking.categoryTemplate.name}` : '';

    const projectManagers = map(
        booking.project?.projectManagersNames || [],
        pm => `${pm.firstName}${pm.lastName ? ' ' + pm.lastName : ''}`
    );
    const projectManagersNames = projectManagers ? projectManagers.join(', ') : '';

    const showPmInTooltip = loggedInRights.isPmExtensionActive && projectManagersNames.length > 0;

    const creator = booking.bookingCreator?.firstName
        ? `${booking.bookingCreator.firstName} ${booking.bookingCreator.lastName}`
        : 'n/a';

    const note = useMemo(() => {
        return booking.note?.replace('&nbsp;', '') || '';
    }, [booking.note]);

    const isAbleToEdit = useMemo(() => {
        const { resourceId, resourceRoleRights } = store.getState().account;
        const { vacationId } = store.getState().companyReducer.company.settings;

        return isAbleToEditResourceOrVacationRequest(props, resourceId, resourceRoleRights, vacationId);
    }, [props]);

    const updater =
        !booking.bookingUpdater || !keys(booking.bookingUpdater).length
            ? 'n/a'
            : `${booking.bookingUpdater.firstName} ${booking.bookingUpdater.lastName}`;

    if (booking.private && !booking.isPermitted) {
        return (
            <>
                This booking is marked as private and you cannot
                <br />
                read the content of it. This can happen if you have no
                <br />
                permissions to this specific {keyWords.projectKeyWord} or you have no
                <br />
                permissions to the {keyWords.resourceKeyWord} and the {keyWords.projectKeyWord} has privacy
                <br />
                enabled.
            </>
        );
    }

    const isRequestedBooking = booking.type === WAITING_FOR_APPROVAL.value;
    const isRepeatBooking = booking.repeat && !booking.private;
    const containsTasks = 0 < booking.tasksTotal;
    const displayDeadlineIcon = isDeadlinesExtensionInstalled && booking.deadlineDate;

    return (
        <Row>
            <Col xs={12}>
                <div className="d-flex align-items-center mb-2">
                    {isRequestedBooking && (
                        <RequestedLabel isToolTip={true}>
                            {!isAbleToEdit && <LockOutlinedIcon />}
                            <span> requested</span>
                        </RequestedLabel>
                    )}
                    {displayLinkedIcon && (
                        <RequestedLabel isToolTip={true}>
                            <DependencyIcon className={classes.icon} />
                        </RequestedLabel>
                    )}
                    {isRepeatBooking && (
                        <RequestedLabel isToolTip={true}>
                            <LoopOutlinedIcon className={classes.icon} />
                        </RequestedLabel>
                    )}
                    {containsTasks && (
                        <RequestedLabel isToolTip={true}>
                            <FormatListBulletedOutlinedIcon className={classes.icon} />
                        </RequestedLabel>
                    )}
                    {loggedInRights.viewNotes && (booking.hasNotes || hasRequestNotes) && (
                        <RequestedLabel isToolTip={true}>
                            <DescriptionOutlinedIcon />
                        </RequestedLabel>
                    )}
                    {displayDeadlineIcon ? (
                        <RequestedLabel isToolTip>
                            <AlarmIcon className={classes.icon} />
                        </RequestedLabel>
                    ) : null}
                </div>
                <div className={classNames('tooltip-title', { 'mt-2': isRequestedBooking })}>{booking.text}</div>
                <div>{booking.project?.projectCode || ''}</div>
                <div className="tooltip-allocation">
                    {totalEventTime}
                    {categoryName}
                </div>
            </Col>
            <Col xs={12}>
                {showPmInTooltip && <div className="text-truncate tooltip-pm-section">PM: {projectManagersNames}</div>}
            </Col>
            <Col xs={12}>
                <Row className="tooltip-dates-section">
                    <Col xs={6}>
                        <div className="tooltip-sub-header">Created date</div>
                        <div className="tooltip-dates-section-date">
                            {booking.createdDate ? format(booking.createdDate, 'LLL dd, yyyy HH:mm') : 'n/a'}
                        </div>
                        <div className="tooltip-sub-header">Created by</div>
                        <div className="tooltip-dates-section-date text-truncate">{creator}</div>
                    </Col>
                    <Col xs={6}>
                        <div className="tooltip-sub-header">Updated date</div>
                        <div className="tooltip-dates-section-date">
                            {booking.updatedDate ? format(booking.updatedDate, 'LLL dd, yyyy HH:mm') : 'n/a'}
                        </div>
                        <div className="tooltip-sub-header">Last Updated by</div>
                        <div className="tooltip-dates-section-date text-truncate">{updater}</div>
                    </Col>
                </Row>
            </Col>
            {loggedInRights.viewNotes && booking.hasNotes && (
                <Col xs={12}>
                    <div className="tooltip-note">
                        <div className="booking-note-title">Note</div>
                        {note}
                    </div>
                </Col>
            )}
            {loggedInRights.viewNotes && hasRequestNotes && (
                <Col xs={12}>
                    <div className="tooltip-note">
                        <div className="booking-note-title">Request note</div>
                        {booking.approvalInfo.requesterNote}
                    </div>
                </Col>
            )}
        </Row>
    );
};

const useStyles = makeStyles({
    icon: {
        fill: '#232323',
        width: 16,
        height: 16,
    },
});

EventBubble.propTypes = {
    booking: PropTypes.object,
    totalEventTime: PropTypes.string,
    loggedInRights: PropTypes.object,
};

EventBubble.defaultProps = {
    booking: {},
    totalEventTime: '',
    loggedInRights: {},
};

export default EventBubble;
