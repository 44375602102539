import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content } from './styles';

const InitialLoading = () => (
    <Content>
        <Wrapper>
            <img src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png" />
        </Wrapper>
    </Content>
);

InitialLoading.propTypes = {
    text: PropTypes.string,
};

export default InitialLoading;
