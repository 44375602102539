import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Sidebar from './sidebar';
import Header from 'modules/header';
import RouteValidator from 'services/routeValidator/validator';
import TipsManager from 'shared/components/TipsManager';
import HubSpotChat from 'shared/components/HubSpotChat';
import { STATUS_TRIAL } from 'enums/companyStatusEnum';
import { useSelector } from 'react-redux';

// order of bootstrapTheme and react-widgets was changed because
// localhost:3000 was not having same styles order like after deploy
import 'react-widgets/dist/css/react-widgets.css';
import 'assets/styles/bootstrapTheme.css';
import 'assets/styles/common/day-picker.css';
import 'assets/styles/common/react-metismenu-standart.min.css';
import 'assets/styles/common/main.css';
import 'assets/styles/common/theme.css';
import 'assets/styles/common/buttons.css';
import 'assets/styles/common/dropdowns.css';
import 'assets/styles/common/modals.css';
import 'assets/styles/common/sidebar.css';
import 'assets/styles/scheduler/scheduler.css';
import 'assets/styles/scheduler/daypilot.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const SchedulerTemplate = props => {
    const { component: Component, isFullScreen, ...restOfProps } = props;
    const companyStatus = useSelector(state => state.companyReducer.company.status);
    return (
        <Route
            {...restOfProps}
            render={matchProps => (
                <div className={restOfProps.location.pathname.split('/')[1]} style={{ height: 'inherit' }}>
                    <Header />
                    <div className="container-fluid" style={{ height: 'inherit' }}>
                        <Row style={{ height: 'inherit' }}>
                            <div
                                className={classNames('full-screen', {
                                    on: isFullScreen,
                                    'off col-sm-3 col-md-4 col-lg-3 col-xl-2': !isFullScreen,
                                })}
                            >
                                <Sidebar rowHeight={69} />
                            </div>
                            <div
                                className={classNames('px-0 scheduler-background', {
                                    col: isFullScreen,
                                    'col-sm-9 col-md-8 col-lg-9 col-xl-10': !isFullScreen,
                                })}
                            >
                                <div className="main scheduler overflow-hidden">
                                    {Component && <Component {...matchProps} />}
                                    {restOfProps.child && (
                                        <Switch>
                                            {restOfProps.child.map(route => (
                                                <RouteValidator key={route.path} {...route} />
                                            ))}
                                        </Switch>
                                    )}
                                </div>
                            </div>
                        </Row>
                    </div>
                    <TipsManager />
                    {STATUS_TRIAL.value === companyStatus ? <HubSpotChat /> : null}
                </div>
            )}
        />
    );
};

SchedulerTemplate.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    path: PropTypes.string.isRequired,
    paramValidator: PropTypes.func,
};

SchedulerTemplate.defaultProps = {
    paramValidator: null,
};

export default SchedulerTemplate;
