import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {*[]}
 */
export default dateState => [
    {
        id: 'burnBetween',
        display: `Burn - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled & Reported Time',
        displayColumnType: 'time',
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'burnPrevious',
        display: `Burn - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled & Reported Time',
        displayColumnType: 'time',
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'burnAfter',
        display: `Burn - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled & Reported Time',
        displayColumnType: 'time',
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'burnAllTime',
        display: 'Burn All Time',
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled & Reported Time',
        displayColumnType: 'time',
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
];
