import { showReportModal } from 'actions/modalActions';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Wrapper } from './styles';
import { SettingsApplications } from '@material-ui/icons';

const BuilderMenuItem = () => {
    const dispatch = useDispatch();
    const showModal = useCallback(
        event => {
            event.preventDefault();
            dispatch(showReportModal());
        },
        [dispatch]
    );

    return (
        <Wrapper id="report-tour-builder">
            <Link to="#" onClick={showModal}>
                <div>
                    <SettingsApplications />
                    <span>Builder</span>
                </div>
            </Link>
        </Wrapper>
    );
};

export default BuilderMenuItem;
