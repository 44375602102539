import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getCategoryGroups = {
    request: ({ force, callback } = {}) => action(actionTypes.GET_CATEGORY_GROUPS['REQUEST'], { force, callback }),
    success: categoryGroups => action(actionTypes.GET_CATEGORY_GROUPS['SUCCESS'], { categoryGroups }),
    failure: () => action(actionTypes.GET_CATEGORY_GROUPS['FAILURE']),
    stop: () => action(actionTypes.GET_CATEGORY_GROUPS_STOP),
};

export const getCategoriesByGroup = {
    request: groupId => action(actionTypes.GET_CATEGORIES_BY_GROUP['REQUEST'], { groupId }),
    success: categories => action(actionTypes.GET_CATEGORIES_BY_GROUP['SUCCESS'], { categories }),
};

export const saveCategoryGroups = {
    request: data => action(actionTypes.SAVE_CATEGORY_GROUPS['REQUEST'], { data }),
    success: categoryGroups => action(actionTypes.SAVE_CATEGORY_GROUPS['SUCCESS'], { categoryGroups }),
    failure: () => action(actionTypes.SAVE_CATEGORY_GROUPS['FAILURE']),
};
