import { ReactTableDefaults } from 'react-table';

export const reportMaxDateRange = { value: 1, unit: 'years' };
export const COLUMN_UNAVAILABLE = {
    name: '__COLUMN_UNAVAILABLE__',
    value: '-',
};
export const ANONYMIZATION_STRING = '*****';
export const REPORTS_ITEMS_COUNT = {
    value: 3,
    text: (itemCount, itemsName) => `${itemCount} ${itemsName || 'Items'}`,
};

export const OTP_CODE_LENGTH = 6;
export const OTP_RECOVERY_CODE_LENGTH = 10;

export const HEADER_HEIGHT = 52;

export const AWS_S3_URL = 'https://d329beqc2zk6g7.cloudfront.net';

export const SCHEDULER_FOOTER_FIXED_HEIGHT = 49;

export const SCHEDULER_DATE_BUFFER = 5;

export const MIN_COLUMN_WIDTH = 50;

export const ALLOWED_ACCESS_TO_OLD_REPORTS = [
    // '56c43ead2c50b827073c07e8',
    // '56fad69266c856b21778df32',
    // '57a368f21d478b1f089817b5',
    // '57ac577c295c082b0828c111',
    // '59e883984141953c3dc4ccbb',
    '5b159a3446b64645a8e2d135',
];

export const DEFAULT_PAGE_SIZE = ReactTableDefaults.defaultPageSize;
export const PAGE_SIZES = ReactTableDefaults.pageSizeOptions;
