import { filter, map, find } from 'underscore';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject } from 'reducers/helpers/updater';
import * as actionTypes from '../actions/actionTypes';

const initialBookingState = {
    bookings: [],
    booking: {
        bookingCreator: {},
        bookingUpdater: {},
    },
};

const resetData = (state, action) =>
    updateObject(state, {
        [action.payload.prop]: initialBookingState[action.payload.prop],
    });

const setBookings = (state, action) =>
    updateObject(state, {
        bookings: action.payload.bookings,
    });

const setBooking = (state, action) =>
    updateObject(state, {
        booking: action.payload.booking,
    });

const createBooking = (state, action) =>
    updateObject(state, {
        bookings: [...state.bookings, ...action.payload.bookings],
    });

const deleteBooking = (state, action) =>
    updateObject(state, {
        bookings: filter(state.bookings, booking => booking._id !== action.payload.bookingId),
    });

const updateBooking = (state, action) => {
    const newBookings = map(action.payload.bookings, booking => {
        const currentBooking = find(state.bookings, oldBooking => booking._id === oldBooking._id);
        if (currentBooking) {
            return updateObject(currentBooking, booking);
        }

        return booking;
    });

    return updateObject(state, {
        bookings: newBookings,
    });
};

const deleteBookings = (state, action) =>
    updateObject(state, {
        bookings: state.bookings.filter(booking => !action.payload.bookingIds.includes(booking._id)),
    });

export default createReducer(initialBookingState, {
    [actionTypes.GET_BOOKINGS['SUCCESS']]: setBookings,
    [actionTypes.GET_BOOKING['SUCCESS']]: setBooking,
    [actionTypes.CREATE_BOOKING['SUCCESS']]: createBooking,
    [actionTypes.DELETE_BOOKING['SUCCESS']]: deleteBooking,
    [actionTypes.UPDATE_BOOKING['SUCCESS']]: updateBooking,
    [actionTypes.RESET_BOOKING_DATA]: resetData,
    [actionTypes.DELETE_BOOKINGS['SUCCESS']]: deleteBookings,
});
