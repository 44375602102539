import { createSelector } from 'reselect';
import { filter } from 'lodash';
import { STATUS_ACTIVE, TYPE_REGULAR } from 'enums/resourceEnum';

const getCompanyTree = state => state.companyTree;

export const makeGetRegularResources = () =>
    createSelector(getCompanyTree, companyTree =>
        filter(companyTree._resources, resource => TYPE_REGULAR.value === resource.type)
    );

export const selectProjectGroupIds = createSelector(
    state => state.companyTree._projectGroupsFlatten,
    state => state.companyTree._settings.grid?.displayArchivedInScheduler,
    (projectGroups, displayArchived) => {
        if (displayArchived) {
            return projectGroups.map(group => group._id);
        } else {
            return projectGroups.filter(group => group.criteria !== 'ARCHIVED').map(group => group._id);
        }
    }
);

export const selectResourceGroupIds = createSelector(
    state => state.companyTree._resourceGroupsFlatten,
    state => state.companyTree._settings.grid?.displayArchivedInScheduler,
    (resourceGroups, displayArchived) => {
        if (displayArchived) {
            return resourceGroups.map(group => group._id);
        } else {
            return resourceGroups
                .filter(group => group.criteria !== 'ARCHIVED' && group.criteria !== 'UNASSIGNED')
                .map(group => group._id);
        }
    }
);

export const selectActiveResourceIds = createSelector(
    state => state.companyTree._resources,
    resources => {
        return resources
            .filter(resource => resource.type === 'REGULAR' && resource.status === 'STATUS_ACTIVE')
            .map(resource => resource._id);
    }
);

export const selectUnassignedIds = createSelector(
    state => state.companyTree._resources,
    resources => {
        return resources.filter(resource => resource.type === 'UNASSIGNED').map(resource => resource._id);
    }
);

export const selectActiveProjectIds = createSelector(
    state => state.companyTree._projects,
    projects => {
        return projects
            .filter(project => project.status === STATUS_ACTIVE.value && project.type === 'REGULAR')
            .map(project => project._id);
    }
);

export const selectActiveEventIds = createSelector(
    state => state.companyTree._projects,
    projects => {
        return projects
            .filter(project => project.status === STATUS_ACTIVE.value && project.type === 'EVENT')
            .map(project => project._id);
    }
);

export const selectIsActiveResourceGroupPermitted = createSelector(
    state => state.companyTree._resourceGroupsFlatten,
    groups => {
        return groups.some(group => group.criteria === 'ACTIVE');
    }
);

export const selectIsActiveProjectGroupPermitted = createSelector(
    state => state.companyTree._projectGroupsFlatten,
    groups => {
        return groups.some(group => group.criteria === 'ACTIVE');
    }
);
