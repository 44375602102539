import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Close, Edit } from '@material-ui/icons';
import { ModalHeader, ModalHeaderDesc, HeaderIcon, Title, CustomTextField, InputError } from './styles';
import { hideModal } from 'actions/modalActions';
import { updateBookingSelection } from 'actions/schedulerActions';
import SuccessButton from 'shared/buttons/success';
import ScrollBar from 'shared/scrollbar';
import { useModalOffset } from 'hooks/useModalOffset';
import { isModalView } from '../../utils/isModalView';
import classnames from 'classnames';

const CommonModalTemplate = props => {
    const {
        title,
        description,
        onSave,
        children,
        windowHeight,
        loading,
        disabledSaveButton,
        saveButtonTooltip,
        additionalButton,
        onHideModalCustom,
        saveButtonText,
        saveButtonDataCy,
        extendFooter,
        extendHeader,
        grid,
        icon: Icon,
        showSaveButton,
        titleEditable,
        onTitleChange,
        submitOnPressEnter,
    } = props;
    const inputRef = useRef();
    const dispatch = useDispatch();
    const [isEditMode, setEditMode] = useState(false);

    const fullScreen = isModalView();

    const toggleEditMode = () => {
        titleEditable && setEditMode(prevState => !prevState);
    };

    const onHideModal = () => {
        if (onHideModalCustom) {
            onHideModalCustom();
        } else {
            dispatch(hideModal());
            dispatch(updateBookingSelection.request());
        }
    };

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    const offsetClassNames = useModalOffset(`rounded-0 col-sm-9 col-lg-9 col-md-8 col-xl-10 px-0`, fullScreen);

    return (
        <Modal
            className={classnames(
                'custom-modal',
                fullScreen ? 'mt-0' : '',
                submitOnPressEnter ? 'modal-submit-enter' : ''
            )}
            contentClassName={offsetClassNames || 'rounded-0 col-sm-12 col-lg-12 col-md-12 col-xl-12 px-0'}
            size="lg"
            isOpen
            fade={false}
            style={{ height: `${windowHeight - (fullScreen ? 0 : 50)}px` }}
            toggle={onHideModal}
        >
            <ScrollBar height={windowHeight - (fullScreen ? 0 : 52) - 69}>
                <ModalBody>
                    <Row className="justify-content-center">
                        <Col {...grid}>
                            <ModalHeader>
                                {Icon && (
                                    <HeaderIcon>
                                        <Icon />
                                    </HeaderIcon>
                                )}
                                {isEditMode || (!isEditMode && !title) ? (
                                    <>
                                        <CustomTextField
                                            value={title}
                                            innerRef={inputRef}
                                            onChange={onTitleChange}
                                            invalid={!title}
                                            onFocus={() => setEditMode(true)}
                                            onBlur={toggleEditMode}
                                        />
                                        {!title && (
                                            <InputError className="invalid-feedback d-block">
                                                This field is required
                                            </InputError>
                                        )}
                                    </>
                                ) : (
                                    <Title editable={titleEditable} onClick={toggleEditMode}>
                                        {title}
                                        {titleEditable && <Edit />}
                                    </Title>
                                )}
                                {extendHeader}
                                <ModalHeaderDesc>{description}</ModalHeaderDesc>
                                <Button data-cy="modal-close" color="esc" onClick={onHideModal}>
                                    <Close />
                                    <div>esc</div>
                                </Button>
                            </ModalHeader>
                        </Col>
                    </Row>
                    <div className="content">
                        <Row className="justify-content-center">
                            <Col {...grid}>{children}</Col>
                        </Row>
                    </div>
                </ModalBody>
            </ScrollBar>
            <ModalFooter className="custom">
                <Row className="justify-content-center">
                    <Col {...grid} className="d-flex justify-content-between">
                        <div>
                            {extendFooter}
                            <SuccessButton
                                className="mr-2"
                                onClick={onHideModal}
                                data-cy="button--cancel"
                                type="btn-cancel"
                                name="Cancel"
                            />
                        </div>
                        <div className="d-flex flex-row">
                            {additionalButton && additionalButton}
                            {showSaveButton && (
                                <SuccessButton
                                    onClick={onSave}
                                    name={saveButtonText}
                                    dataCy={saveButtonDataCy}
                                    disabled={disabledSaveButton || loading}
                                    tooltipText={saveButtonTooltip}
                                    tooltipEnterDelay={0}
                                    className="mr-2"
                                    type="btn-primary"
                                />
                            )}
                        </div>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
};

CommonModalTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onSave: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    extendHeader: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    extendFooter: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    Icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    windowHeight: PropTypes.number.isRequired,
    saveButtonText: PropTypes.string,
    loading: PropTypes.bool,
    disabledSaveButton: PropTypes.bool,
    saveButtonTooltip: PropTypes.string,
    showSaveButton: PropTypes.bool,
    titleEditable: PropTypes.bool,
    onTitleChange: PropTypes.func,
    additionalButton: PropTypes.element,
    grid: PropTypes.shape({
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
    }),
};

CommonModalTemplate.defaultProps = {
    saveButtonText: 'Save',
    osSave: null,
    loading: false,
    disabledSaveButton: false,
    showSaveButton: true,
    additionalButton: null,
    titleEditable: false,
    onTitleChange: null,
    grid: {
        sm: 10,
        md: 10,
        lg: 10,
        xl: 8,
    },
};

export default CommonModalTemplate;
