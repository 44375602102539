import { EventNote, Person, Folder, Label, List, Group, WatchLater } from '@material-ui/icons';
import { values, find, includes } from 'lodash';

export const RESOURCES = {
    value: 'RESOURCES',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Names`,
    filterListProp: 'resources',
    itemLabel: resource => `${resource.firstName} ${resource.lastName}`,
    itemValue: '_id',
    icon: Person,
};
export const PROJECT_MANAGERS = {
    value: 'PROJECT_MANAGERS',
    label: () => 'Project Managers',
    filterListProp: 'projectManagers',
    itemLabel: resource => `PM ${resource.firstName} ${resource.lastName}`,
    itemValue: '_id',
    icon: Label,
};

export const RESOURCE_GROUPS = {
    value: 'RESOURCE_GROUPS',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Groups`,
    filterListProp: 'resourceGroups',
    itemLabel: 'name',
    itemValue: '_id',
    icon: Group,
};
export const RESOURCE_CF = {
    value: 'RESOURCE_CF',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Custom Fields`,
    filterListProp: 'resourceCustomFields',
    nestedItems: 'choices',
    itemLabel: 'label',
    itemValue: '_id',
    nestedItemLabel: 'value',
    nestedItemValue: '_id',
    icon: Label,
};
export const RESOURCE_TAGS = {
    value: 'RESOURCE_TAGS',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Tags`,
    filterListProp: 'resourceTags',
    itemLabel: 'value',
    itemValue: '_id',
    icon: Label,
};
export const RESOURCE_ROLES = {
    value: 'RESOURCE_ROLES',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Roles`,
    filterListProp: 'resourceRoles',
    itemLabel: 'display',
    itemValue: 'value',
    icon: Label,
};
export const RESOURCE_STATUSES = {
    value: 'RESOURCE_STATUSES',
    label: ({ resourceKeyWord }) => `${resourceKeyWord} Statuses`,
    filterListProp: 'resourceStatuses',
    itemLabel: 'display',
    itemValue: 'value',
    icon: WatchLater,
};
export const RESOURCE_IS_PM = {
    value: 'RESOURCE_STATUSES',
    label: ({ resourceKeyWord, projectKeyWord }) => `${resourceKeyWord} is ${projectKeyWord} Manager`,
    filterListProp: 'resourceIsPm',
    itemValue: 'value',
    itemLabel: (item, { resourceKeyWord, projectKeyWord }) => {
        return `${resourceKeyWord} is${item.value === 'resource-is-pm' ? ' ' : ' not '}${projectKeyWord} Manager`;
    },
    icon: WatchLater,
};
export const PROJECTS = {
    value: 'PROJECTS',
    label: ({ projectKeyWord }) => `${projectKeyWord} Names`,
    filterListProp: 'projects',
    itemLabel: 'name',
    itemValue: '_id',
    icon: EventNote,
};

export const PROJECT_GROUPS = {
    value: 'PROJECT_GROUPS',
    label: ({ projectKeyWord }) => `${projectKeyWord} Groups`,
    filterListProp: 'projectGroups',
    itemLabel: 'name',
    itemValue: '_id',
    icon: Folder,
};
export const PROJECT_CF = {
    value: 'PROJECT_CF',
    label: ({ projectKeyWord }) => `${projectKeyWord} Custom Fields`,
    filterListProp: 'projectCustomFields',
    itemLabel: 'label',
    itemValue: '_id',
    nestedItems: 'choices',
    nestedItemLabel: 'value',
    nestedItemValue: '_id',
    icon: Label,
};
export const PROJECT_TAGS = {
    value: 'PROJECT_TAGS',
    label: ({ projectKeyWord }) => `${projectKeyWord} Tags`,
    filterListProp: 'projectTags',
    itemLabel: 'value',
    itemValue: '_id',
    icon: Label,
};

export const CLIENTS = {
    value: 'CLIENTS',
    label: ({ customerPluralKeyWord }) => customerPluralKeyWord,
    filterListProp: 'customers',
    itemLabel: 'name',
    itemValue: '_id',
    icon: Label,
};
export const PROJECT_STATUSES = {
    value: 'PROJECT_STATUSES',
    label: ({ projectKeyWord }) => `${projectKeyWord} Statuses`,
    filterListProp: 'projectStatuses',
    itemLabel: 'display',
    itemValue: 'value',
    icon: WatchLater,
};
export const PROJECT_CURRENCY = {
    value: 'PROJECT_CURRENCY',
    label: ({ projectKeyWord }) => `${projectKeyWord} Currency`,
    filterListProp: 'currencies',
    itemLabel: 'name',
    itemValue: 'value',
    icon: WatchLater,
};
export const PROJECT_DATES = {
    value: 'PROJECT_DATES',
    label: ({ projectKeyWord }) => `${projectKeyWord} Dates`,
    filterListProp: 'projectDates',
    itemLabel: 'display',
    itemValue: 'value',
    icon: Label,
};
export const BOOKING_STATUSES = {
    value: 'BOOKING_STATUSES',
    label: () => 'Booking - Status',
    filterListProp: 'bookingStatuses',
    itemLabel: 'display',
    itemValue: 'value',
    icon: Label,
};
export const BOOKING_CATEGORIES = {
    value: 'BOOKING_CATEGORIES',
    label: (keyWords, item) => (item && item.groupName) || 'Booking - Category',
    filterListProp: 'bookingCategories',
    itemLabel: 'name',
    itemValue: '_id',
    icon: List,
};

export const BOOKING_PROJECTS_EVENTS = {
    value: 'BOOKING_PROJECTS_EVENTS',
    label: ({ projectPluralKeyWord }, item) => (item && item.groupName) || `Booking - ${projectPluralKeyWord} / Events`,
    filterListProp: 'bookingProjectsEvents',
    itemLabel: 'name',
    itemValue: '_id',
    icon: EventNote,
};

export const BOOKING_RESOURCES_UW = {
    value: 'BOOKING_RESOURCES_UW',
    label: ({ resourcePluralKeyWord }, item) =>
        (item && item.groupName) || `Booking - ${resourcePluralKeyWord} / Unassigned Work`,
    filterListProp: 'bookingResourcesUW',
    itemLabel: resource => `${resource.firstName} ${resource.lastName}`,
    itemValue: '_id',
    icon: Person,
};

export const UTILIZATION = {
    value: 'UTILIZATION',
    label: () => 'Utilization',
    filterListProp: 'utilization',
    icon: Label,
};
export const AVAILABILITY = {
    value: 'AVAILABILITY',
    label: () => 'Availability',
    filterListProp: 'availability',
    icon: Label,
};

export const BOOKING_PROJECT_STATUSES = {
    value: 'BOOKING_PROJECT_STATUSES',
    label: ({ projectKeyWord }) => `Booking ${projectKeyWord} Statuses`,
    filterListProp: 'bookingProjectStatuses',
    itemLabel: 'display',
    itemValue: 'value',
    icon: WatchLater,
};

export const schedulerFilters = {
    RESOURCES,
    PROJECT_MANAGERS,
    RESOURCE_GROUPS,
    RESOURCE_CF,
    RESOURCE_TAGS,
    RESOURCE_ROLES,
    RESOURCE_STATUSES,
    RESOURCE_IS_PM,
    PROJECT_CURRENCY,
    PROJECTS,
    PROJECT_GROUPS,
    PROJECT_CF,
    PROJECT_TAGS,
    CLIENTS,
    PROJECT_STATUSES,
    PROJECT_DATES,
    BOOKING_STATUSES,
    BOOKING_CATEGORIES,
    BOOKING_PROJECTS_EVENTS,
    BOOKING_RESOURCES_UW,
    UTILIZATION,
    AVAILABILITY,
    BOOKING_PROJECT_STATUSES,
};

export const schedulerFiltersArray = values(schedulerFilters);

export const isConditionFilterSupported = filterName =>
    !includes(
        [
            PROJECTS.filterListProp,
            RESOURCES.filterListProp,
            BOOKING_STATUSES.filterListProp,
            BOOKING_CATEGORIES.filterListProp,
            BOOKING_PROJECTS_EVENTS.filterListProp,
            BOOKING_RESOURCES_UW.filterListProp,
        ],
        filterName
    );

export const findByFilterListProp = filterListProp =>
    find(schedulerFiltersArray, schedulerFilter => filterListProp === schedulerFilter.filterListProp);
