import { forEach } from 'lodash';
import { projectObject } from './project';
import { resourceObject } from './resource';
import { unassignedObject } from './unassigned';
import { addMenuRow } from './additionalRows';
import { hasRole } from 'utils/rightsUtil';

const getUnassignedResources = (unassignedResources, contextMenus, additionalParams) =>
    additionalParams.shouldRenderUnassigned && contextMenus.unassignedContextMenu
        ? unassignedObject({
              unassignedResources,
              contextMenu: contextMenus.unassignedContextMenu,
              unassignedChildContextMenu: contextMenus.unassignedChildContextMenu,
              hasManageUnassignedRowsRights: hasRole(additionalParams.accountRoleRights, 'manageUnassignedRows'),
              showUnassignedParentArrow: additionalParams?.showUnassignedParentArrow,
              additionalParams,
          })
        : null;

export const getSchedulerResources = (resources, unassignedResources, contextMenus, additionalParams) => {
    let daypilotResources = [];
    const unassignedResource = getUnassignedResources(unassignedResources, contextMenus, additionalParams);

    forEach(resources, resource => {
        daypilotResources.push(resourceObject(resource, contextMenus.resourceContextMenu, additionalParams));
    });

    const menuRow = addMenuRow(additionalParams.resourceGroup || additionalParams.project, {
        accountRoleRights: additionalParams.accountRoleRights,
        resourceKeyWord: additionalParams.resourceKeyWord,
        isChildRow: false,
    });
    unassignedResource && daypilotResources.unshift(unassignedResource);
    additionalParams.addMenuRow && menuRow && daypilotResources.push(menuRow);

    return daypilotResources;
};

export const getSchedulerProjects = (
    projects,
    dpChildrenResources,
    unassignedResources,
    contextMenus,
    additionalParams
) => {
    let daypilotResources = [];
    const unassignedResource = getUnassignedResources(unassignedResources, contextMenus, additionalParams);

    forEach(projects, project => {
        daypilotResources.push(
            projectObject({
                project,
                dpChildrenResources,
                contextMenu: contextMenus.projectContextMenu,
                projectContextMenu: contextMenus.projectContextMenu,
                projectResourceContextMenu: contextMenus.projectResourceContextMenu,
                filters: additionalParams.filters,
                loadResources: additionalParams.loadResources,
                accountRoleRights: additionalParams.accountRoleRights,
                keyWords: additionalParams.keyWords,
            })
        );
    });

    const menuRow = addMenuRow(additionalParams.projectGroup || additionalParams.resource, {
        accountRoleRights: additionalParams.accountRoleRights,
        projectKeyWord: additionalParams.projectKeyWord,
        isChildRow: false,
    });

    unassignedResource && daypilotResources.unshift(unassignedResource);
    additionalParams.addMenuRow && menuRow && daypilotResources.push(menuRow);

    return daypilotResources;
};
