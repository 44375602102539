/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Slider } from '@material-ui/core';
import { TwitterPicker } from 'react-color';
import { COLORS } from 'enums/colorEnum';
import { Cover, Popover, CurrentColor, SliderWrapper } from './styles';

class ColorPicker extends Component {
    static propTypes = {
        onColorChange: PropTypes.func,
        onOpacityChange: PropTypes.func,
        onClose: PropTypes.func,
        color: PropTypes.string,
        pickerWidth: PropTypes.number,
        maxHeight: PropTypes.number,
        isOpen: PropTypes.bool,
        showSelectedColor: PropTypes.bool,
        className: PropTypes.string,
        pickerPosition: PropTypes.oneOf(['left', 'right']),
        slider: PropTypes.bool,
        positionAbsolute: PropTypes.bool,
        leftPosition: PropTypes.number,
        topPosition: PropTypes.number,
        sliderColor: PropTypes.object,
    };

    static defaultProps = {
        onColorChange: null,
        onClose: null,
        color: null,
        leftPosition: null,
        topPosition: null,
        pickerPosition: 'right',
        pickerWidth: 313,
        maxHeight: null,
        onOpacityChange: null,
        slider: false,
        positionAbsolute: true,
        showSelectedColor: true,
        isOpen: false,
        sliderColor: null,
    };

    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen || false,
            className: '',
            color: '#349E71',
            opacityValue: 20,
            opacityMinValue: 20,
            opacityMaxValue: 85,
        };
    }

    componentDidMount() {
        if (this.props.showSelectedColor) {
            document.addEventListener('click', this.handleClickOutside, true);
        }

        const { color, sliderColor } = this.props;

        if (color) {
            this.setState({ color: color });
        }
        if (sliderColor) {
            this.setState({ opacityValue: sliderColor.l });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.sliderColor && prevProps.sliderColor.h !== this.props.sliderColor.h) {
            this.setState(state => ({
                opacityValue: this.props.sliderColor ? this.props.sliderColor.l : state.opacityValue,
                color: this.props.color,
            }));
        }
        if (prevProps.color !== this.props.color && this.props.color) {
            this.setState({ color: this.props.color });
        }
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isOpen: this.props.isOpen });
        }
    }

    componentWillUnmount() {
        if (this.props.showSelectedColor) {
            document.removeEventListener('click', this.handleClickOutside, true);
        }
    }

    handleClickOutside = event => {
        const domNode = this.node;

        if (domNode && !domNode.contains(event.target) && this.state.visible) {
            this.setState({ visible: false });
        }
    };

    onChange = color => {
        this.setState({ color: color.hex });
        if (this.props.onColorChange) {
            this.props.onColorChange(color);
        }
        this.onInputClick();
    };

    onInputClick = () => {
        if (this.props.showSelectedColor) {
            this.setState(state => ({ isOpen: !state.isOpen }));
        }
    };

    onSliderChange = (event, value) => {
        this.setState({ opacityValue: value });
    };

    onSliderDragEnd = () => {
        this.props.onOpacityChange && this.props.onOpacityChange(this.state.opacityValue);
    };

    render() {
        const { className, slider, showSelectedColor } = this.props;

        return (
            <div className={classnames(className)}>
                {showSelectedColor && (
                    <CurrentColor
                        className="color-picker-current"
                        onClick={this.onInputClick}
                        color={this.state.color}
                    />
                )}
                {this.state.isOpen ? (
                    <div
                        className={classnames('color-picker-popover', { 'position-absolute': showSelectedColor })}
                        ref={node => (this.node = node)}
                    >
                        <Popover
                            slider={slider}
                            topPosition={this.props.topPosition}
                            leftPosition={this.props.leftPosition}
                            maxHeight={this.props.maxHeight}
                            position={this.props.pickerPosition}
                            positionAbsolute={this.props.positionAbsolute}
                            width={this.props.pickerWidth}
                        >
                            {showSelectedColor && <Cover className="color-picker-cover" onClick={this.onInputClick} />}
                            {this.props.slider && (
                                <SliderWrapper width={this.props.pickerWidth}>
                                    <p>Contrast</p>
                                    <Slider
                                        min={this.state.opacityMinValue}
                                        max={this.state.opacityMaxValue}
                                        value={this.state.opacityValue}
                                        aria-labelledby="label"
                                        onChange={this.onSliderChange}
                                        onDragEnd={this.onSliderDragEnd}
                                    />
                                </SliderWrapper>
                            )}
                            <TwitterPicker
                                colors={COLORS}
                                triangle="hide"
                                color={this.state.color}
                                className="color-picker"
                                width={`${this.props.pickerWidth}px`}
                                onChange={this.onChange}
                            />
                        </Popover>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default ColorPicker;
