/*
    IMPORTANT
    All code in this file MUST be documented
*/

/**
 * This functions sorts by object names
 */
export const sortObjectsArrayByNameFn = arrayOfObjects => {
    return (Array.isArray(arrayOfObjects) ? arrayOfObjects : []).sort((a, b) => {
        if ((a?.name || '').toLowerCase() < (b?.name || '').toLowerCase()) {
            return -1;
        }
        if ((a?.name || '').toLowerCase() > (b?.name || '').toLowerCase()) {
            return 1;
        }
        return 0;
    });
};

/**
 * This function returns the index of an object to find, searching in a list.
 * @param {object} itemToFindIndex item to find.
 * @param {array} itemsList Array to search the index in
 * @returns number the index of the item if found. null otherwise
 */
export const findItemIndexInArrayByStringify = (itemToFindIndex = null, itemsList = []) => {
    return itemsList.findIndex(navigableItemFromList => {
        return JSON.stringify(itemToFindIndex) === JSON.stringify(navigableItemFromList);
    });
};

/**
 * Given an array, returns another array that don't have duplicates neighbours
 * @param {*} array
 * @param {*} condition
 * @returns array
 */
export const removeNeighboursDuplicatesFromArray = (array = [], condition) => {
    const withoutNeighboursDuplicates = [];
    const conditionToCheck = condition ? condition : (a, b) => a === b;
    array.forEach((menuItemContent, index) => {
        if (
            index === 0 ||
            !conditionToCheck(menuItemContent, withoutNeighboursDuplicates[withoutNeighboursDuplicates.length - 1])
        ) {
            withoutNeighboursDuplicates.push(menuItemContent);
        }
    });
    return withoutNeighboursDuplicates;
};
