import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @returns {Array}
 */
export default dateState => [
    {
        id: 'reportedTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'reportedTimeToDate',
        display: 'To Date',
        displayTimeType: 'Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedTimeFuture',
        display: 'Future',
        displayTimeType: 'Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
];
