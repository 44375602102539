import { TYPE_REGULAR } from 'enums/resourceEnum';
import React from 'react';
import PropTypes from 'prop-types';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import { RequestedLabel } from '../styles';

const Tasks = ({ loggedInRights, booking, mode }) => {
    const taskViewRights =
        (loggedInRights.isTaskExtensionActive && loggedInRights.manageOwnTasks && loggedInRights.isMyBooking) ||
        loggedInRights.manageTasks ||
        (loggedInRights.pmManageTasks && TYPE_REGULAR.value === booking.resourceInfo.type) ||
        (loggedInRights.pmManageTasks &&
            TYPE_REGULAR.value !== booking.resourceInfo.type &&
            loggedInRights.pmManageUnassignedRows);

    return taskViewRights && 0 < booking.tasksTotal ? (
        <>
            <RequestedLabel data-cy="booking-tasks-icon" mode={mode}>
                <FormatListBulletedOutlinedIcon />
            </RequestedLabel>
        </>
    ) : null;
};

Tasks.propTypes = {
    booking: PropTypes.object.isRequired,
    loggedInRights: PropTypes.object.isRequired,
};

export default Tasks;
