import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { includes, map, find } from 'lodash';
import {
    EditOutlined,
    SwapHorizOutlined,
    TextFieldsOutlined,
    ListAltOutlined,
    GroupWorkOutlined,
    RemoveCircleOutline,
    KeyboardArrowLeft,
    DeleteForeverOutlined,
    CallSplit,
    PersonOutlined,
    FolderOutlined,
    BarChart,
    Link,
    Adjust,
} from '@material-ui/icons';
import { ListItemText, ListItemIcon } from '@material-ui/core';
import AlarmIcon from '@material-ui/icons/Alarm';
import { ActionItemsRow } from '../../shared/styles';

import {
    useAppKeyWords,
    useHasRights,
    useIsExtensionInstalled,
    useLoadProjectsAndResources,
    useProjectAction,
} from 'hooks';
import { makeProjectById } from 'selectors/project';
import { makeResourceById } from 'selectors/resource';
import { updateResource } from 'actions/resourceActions';
import {
    showProjectModal,
    showResourceModal,
    showScheduleResourceModal,
    showBulkMoveModal,
} from 'actions/modalActions';
import { SINGLE, PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { DEPENDENCIES_TAB_ID, NOTE_TAB_ID, TASKS_TAB_ID } from 'modules/modals/scheduleResourceModal/tabIcons';
import { ListItem } from './../../../lists/styles';

import { APPROVED, SCHEDULED } from 'enums/bookingTypeEnum';
import { PM, TASKS, DEPENDENCIES } from 'enums/extensionShortIdEnum';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { useBooking } from 'modules/scheduler/hooks';
import classNames from 'classnames';
import ActionItem from '../../shared/actionItem';
import { selectIsDeadlinesExtensionInstalled } from '../../../../../../selectors/company';
import { selectSchedulerMode } from '../../../../../../selectors/scheduler';
import { useEditBookingTitleModal } from './editBookingTitle';

const rights = [
    {
        rights: ['manageEvents'],
        rule: 'one',
        name: 'hasManageEventsRights',
    },
    {
        rights: ['pmManageEvents'],
        rule: 'one',
        name: 'hasPMManageEventsRights',
    },
    {
        rights: ['pmAddEditProjects'],
        rule: 'one',
        name: 'hasPMEditProjectRights',
    },
    {
        rights: ['settingAddEditProjects'],
        rule: 'one',
        name: 'hasAddEditProjectRights',
    },
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasEditResourceRights',
    },
];

const MoreItems = props => {
    const {
        updateBooking,
        bulkCreateBookings,
        updateClipboard,
        onDelete,
        onClose,
        currentSelectedBooking,
        viewObject,
        schedulerRef,
        goBack,
        goToSplit,
        showSplit,
        isSplitOptionDisabled,
        onDeadlineItemClick,
    } = props;
    const dispatch = useDispatch();
    useLoadProjectsAndResources();
    const { projectKeyWord, resourceKeyWord } = useAppKeyWords();
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const isTasksExtensionInstalled = useIsExtensionInstalled(TASKS);
    const isDependenciesExtensionInstalled = useIsExtensionInstalled(DEPENDENCIES);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const { resourceInfo, project, type } = currentSelectedBooking;

    const isScheduledType = includes([SCHEDULED.value, APPROVED.value], type);
    const { resourceId, isProjectManager } = useSelector(state => state.account);
    const dpResources = useSelector(state => state.scheduler.dpResources);
    const mode = useSelector(selectSchedulerMode);
    const {
        hasManageEventsRights,
        hasPMManageEventsRights,
        hasPMEditProjectRights,
        hasAddEditProjectRights,
        hasEditResourceRights,
    } = useHasRights(rights);
    const isPMonProject = includes(project.projectManagers, resourceId);
    const hasPMRights = isPMExtensionInstalled && isProjectManager && isPMonProject;
    const canBulkMoveEvent = hasManageEventsRights || (hasPMRights && hasPMManageEventsRights);
    const showAssignToAll = !((viewObject.isProjectView && mode === SINGLE.value) || viewObject.isSingleResourceView);
    const projectSelector = useMemo(() => makeProjectById(project?._id), [project?._id]);
    const resourceSelector = useMemo(() => makeResourceById(resourceInfo?._id), [resourceInfo?._id]);
    const resource = useSelector(resourceSelector);
    const projectFound = useSelector(projectSelector);
    const { updateProject } = useProjectAction(schedulerRef);
    const { deleteBookingFromModal } = useBooking();

    const bookingModal = useCallback(
        activeTab => {
            onClose && onClose();
            dispatch(
                showScheduleResourceModal({
                    onSubmit: updateBooking,
                    activeTab: activeTab,
                    bookingId: currentSelectedBooking.id,
                    onDelete: deleteBookingFromModal,
                })
            );
        },
        [onClose, dispatch, updateBooking, currentSelectedBooking.id, deleteBookingFromModal]
    );

    const editBookingModal = useEditBookingTitleModal({
        onClose,
        currentSelectedBooking,
        updateBooking,
        mode,
        viewObject,
    });

    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => updateProject(project._id, data),
                type: project.type,
                projectId: project._id,
            })
        );
    }, [dispatch, onClose, project.type, project._id, updateProject]);

    const resourceModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showResourceModal({
                onSubmit: data => dispatch(updateResource.request(resourceInfo._id, data)),
                type: resourceInfo.type,
                resourceId: resourceInfo._id,
            })
        );
    }, [dispatch, resourceInfo.type, onClose, resourceInfo._id]);
    const bulkMoveModal = useCallback(() => {
        onClose && onClose();
        dispatch(showBulkMoveModal(schedulerRef, viewObject));
    }, [dispatch, onClose, schedulerRef, viewObject]);

    const assignToAllInGroup = useCallback(() => {
        let resourceIds = map(dpResources, resource => resource._id);
        if (viewObject.isProjectView && mode === PARENT.value) {
            resourceIds =
                find(dpResources, resource => resource._id === currentSelectedBooking.project._id)?.resources || [];
        }
        bulkCreateBookings(currentSelectedBooking.id, {
            resourceIds,
        });
    }, [bulkCreateBookings, currentSelectedBooking, dpResources, mode, viewObject.isProjectView]);

    const canEditProject =
        (projectFound?.hasRightsToProject && hasAddEditProjectRights) ||
        (projectFound?.hasPMRightsToProject && hasPMEditProjectRights);
    const canEditResource = resource?.hasRightsToResource && hasEditResourceRights;

    return (
        <>
            {isScheduledType && (
                <>
                    <ActionItemsRow className={classNames('text-center m-3')}>
                        <ActionItem
                            data-cy="scheduler-booking-modern-menu-back"
                            text="Back ..."
                            onClick={goBack}
                            className="edit-booking-mm"
                        >
                            <KeyboardArrowLeft />
                        </ActionItem>
                        <ActionItem
                            data-cy="scheduler-booking-modern-menu-delete"
                            text="Delete"
                            onClick={onDelete}
                            className="edit-booking-mm"
                        >
                            <DeleteForeverOutlined />
                        </ActionItem>
                        <ActionItem
                            data-cy="scheduler-booking-modern-menu-cut"
                            text="Cut"
                            onClick={() => updateClipboard(true)}
                            className="edit-booking-mm"
                        >
                            <RemoveCircleOutline />
                        </ActionItem>
                    </ActionItemsRow>
                    <ListItem divider onClick={editBookingModal} data-cy="scheduler-booking-modern-edit-title">
                        <ListItemIcon>
                            <EditOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Edit title" />
                    </ListItem>
                    {canBulkMoveEvent && (
                        <ListItem divider onClick={bulkMoveModal} data-cy="scheduler-booking-modern-bulk-move">
                            <ListItemIcon>
                                <SwapHorizOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Bulk move ..." />
                        </ListItem>
                    )}
                    <ListItem
                        divider
                        onClick={() => bookingModal(NOTE_TAB_ID)}
                        data-cy="scheduler-booking-modern-menu-notes"
                    >
                        <ListItemIcon>
                            <TextFieldsOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Notes ..." />
                    </ListItem>
                    <ListItem
                        divider
                        onClick={() => bookingModal(DEPENDENCIES_TAB_ID)}
                        data-cy="scheduler-booking-modern-menu-links"
                        showDisabledTextState={!isDependenciesExtensionInstalled}
                    >
                        <ListItemIcon>
                            <Link />
                        </ListItemIcon>
                        <ListItemText primary="Dependencies ..." />
                        {!isDependenciesExtensionInstalled && <Adjust style={{ color: '#e38532', fontSize: 16 }} />}
                    </ListItem>
                    <ListItem
                        divider
                        onClick={() => bookingModal(TASKS_TAB_ID)}
                        data-cy="scheduler-booking-modern-menu-tasks"
                        showDisabledTextState={!isTasksExtensionInstalled}
                    >
                        <ListItemIcon>
                            <ListAltOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Tasks ..." />
                        {!isTasksExtensionInstalled && <Adjust style={{ color: '#e38532', fontSize: 16 }} />}
                    </ListItem>
                    {showAssignToAll && (
                        <ListItem divider onClick={assignToAllInGroup} data-cy="scheduler-booking-modern-menu-assign">
                            <ListItemIcon>
                                <GroupWorkOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Assign to all in group" />
                        </ListItem>
                    )}
                    <ListItem
                        divider
                        onClick={onDeadlineItemClick}
                        data-cy="scheduler-booking-modern-menu-add-edit-deadline"
                        showDisabledTextState={!isDeadlinesExtensionInstalled}
                    >
                        <ListItemIcon>
                            <AlarmIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                !isDeadlinesExtensionInstalled || !currentSelectedBooking.deadlineDate
                                    ? 'Add deadline'
                                    : 'Edit deadline'
                            }
                        />
                        {!isDeadlinesExtensionInstalled && <Adjust style={{ color: '#e38532', fontSize: 16 }} />}
                    </ListItem>
                    {showSplit && (
                        <ListItem
                            divider
                            onClick={isSplitOptionDisabled ? undefined : goToSplit}
                            disabled={isSplitOptionDisabled}
                            data-cy="scheduler-booking-modern-menu-split"
                        >
                            <ListItemIcon>
                                <CallSplit />
                            </ListItemIcon>
                            <ListItemText primary="Split" />
                        </ListItem>
                    )}
                </>
            )}

            <ListItem divider onClick={() => bookingModal()} data-cy="scheduler-booking-modern-menu-edit-booking">
                <ListItemIcon>
                    <BarChart />
                </ListItemIcon>
                <ListItemText primary="Edit Booking ..." />
            </ListItem>

            <ListItem
                divider
                onClick={canEditProject && projectModal}
                disabled={!canEditProject}
                data-cy={`scheduler-booking-modern-menu-edit-${
                    project.type === TYPE_REGULAR.value ? projectKeyWord : 'Event'
                }`}
            >
                <ListItemIcon>
                    <FolderOutlined />
                </ListItemIcon>
                <ListItemText primary={`Edit ${project.type === TYPE_REGULAR.value ? projectKeyWord : 'Event'} ...`} />
            </ListItem>

            <ListItem
                divider
                onClick={canEditResource && resourceModal}
                disabled={!canEditResource}
                data-cy={`scheduler-booking-modern-menu-edit-${resourceKeyWord}`}
            >
                <ListItemIcon>
                    <PersonOutlined />
                </ListItemIcon>
                <ListItemText primary={`Edit ${resourceKeyWord} ...`} />
            </ListItem>
        </>
    );
};

MoreItems.propTypes = {
    updateBooking: PropTypes.func.isRequired,
    bulkCreateBookings: PropTypes.func.isRequired,
    updateClipboard: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    currentSelectedBooking: PropTypes.object.isRequired,
    viewObject: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    projectName: PropTypes.string,
    resourceName: PropTypes.string,
    goBack: PropTypes.func.isRequired,
    goToSplit: PropTypes.func.isRequired,
    showSplit: PropTypes.bool,
    isSplitOptionDisabled: PropTypes.bool,
};

MoreItems.defaultProps = {
    onClose: null,
    projectName: '',
    resourceName: '',
};

export default MoreItems;
