import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col } from 'reactstrap';
import { HeaderIconText, Item } from './styles';
import { Tooltip } from '@material-ui/core';

const ActionItem = props => {
    const {
        onClick,
        text,
        actionText,
        disabled,
        children,
        className,
        withTooltip,
        dataCy,
        tooltipTitle,
        ...restOfProps
    } = props;
    const renderContentJSX = () => (
        <Item className={classNames(className, { disabled })}>
            {children && children}
            {text && (
                <HeaderIconText className={classNames({ className, disabled })}>
                    {actionText && actionText}
                    <div className={'text-truncate'}>{text}</div>
                </HeaderIconText>
            )}
        </Item>
    );

    return (
        <Col data-cy={dataCy} onClick={!disabled ? onClick : null} {...restOfProps} className="cursor-p p-0">
            {withTooltip ? <Tooltip title={tooltipTitle}>{renderContentJSX()}</Tooltip> : renderContentJSX()}
        </Col>
    );
};

ActionItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string,
    actionText: PropTypes.string,
    dataCy: PropTypes.string,
    tooltipTitle: PropTypes.string,
    disabled: PropTypes.bool,
    withTooltip: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ActionItem.defaultPropa = {
    text: '',
    actionText: '',
    tooltipTitle: '',
    disabled: false,
    withTooltip: false,
    dataCy: '',
};

export default ActionItem;
