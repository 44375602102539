import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, ModalFooter, Alert } from 'reactstrap';
import { Close } from '@material-ui/icons';
import { hideModal as hideModalAction } from 'actions/modalActions';
import { usePressEscape } from 'hooks';
import SuccessButton from 'shared/buttons/success';
import CancelButton from 'shared/buttons/cancel';
import { EscButton, ModalContent, Header } from './styles';

export const REPEAT_MODAL_TYPES = {
    MILESTONE: {
        title: 'Delete milestone',
        description: () =>
            'This is a repeating milestone. Choose if you would like to just delete this milestone, All milestones or all future milestones.',
    },
    PHASE: {
        title: 'Delete phase',
        description: () =>
            'This is a repeating phase. Choose if you would like to just delete this phase, All phases or all future phases.',
    },
    BOOKING: {
        title: 'Update booking',
        description: isMultiMove =>
            isMultiMove
                ? 'You are trying to update one or more repeated bookings. Choose if you would like to just update selected booking or all bookings. (incl. repeated)'
                : 'This is a repeating booking. Choose if you would like to just update this booking or all bookings.',
    },
};

const EditRepeatModal = props => {
    const { type, actions, bookings } = props;

    const isMultiMove = Array.isArray(bookings);

    const dispatch = useDispatch();
    const { onCancel, updateAll: onUpdateAll, updateOne: onUpdateOne } = actions;
    /**
     * can't find in docs for reactstrap modal event on dismiss action
     * so will store in ref if action was done by user
     * if onExit action was not done we need to fire cancel()
     * to keep data in sync in scheduler
     */
    const wasConfirmed = useRef(false);

    const hideModal = useCallback(() => {
        dispatch(hideModalAction());
    }, [dispatch]);

    usePressEscape(hideModal);

    const cancel = useCallback(() => {
        wasConfirmed.current = true;
        onCancel && onCancel();
        hideModal();
    }, [hideModal, onCancel]);

    const updateAll = useCallback(() => {
        wasConfirmed.current = true;
        onUpdateAll();
        hideModal();
    }, [hideModal, onUpdateAll]);

    const updateOne = useCallback(() => {
        wasConfirmed.current = true;
        onUpdateOne();
        hideModal();
    }, [hideModal, onUpdateOne]);

    const onExit = useCallback(() => {
        if (!wasConfirmed.current) {
            cancel();
        }
    }, [cancel]);

    return (
        <Modal
            contentClassName="rounded-0 mt-5 position-relative"
            size="lg"
            isOpen={true}
            fade={false}
            style={{ width: '590px' }}
            autoFocus={false}
            toggle={hideModal}
            onExit={onExit}
        >
            <Header>{REPEAT_MODAL_TYPES[type].title}</Header>
            <ModalContent>
                <EscButton color="close" onClick={hideModal}>
                    <Close />
                    <div>esc</div>
                </EscButton>
                <div className="clearfix" />
                <div className="mt-4 mr-1 text-center">
                    <p>{REPEAT_MODAL_TYPES[type].description(isMultiMove)}</p>
                </div>
                {'BOOKING' === type && (
                    <Alert color="warning" className="mb-5">
                        <strong>Warning!</strong>
                        {isMultiMove ? (
                            <>
                                If you update just selected bookings, they will be updated to the new position and
                                removed from the repeat cycle. If you update all, all bookings will be updated to the
                                new position.
                            </>
                        ) : (
                            <>
                                If you update just this booking, it will be updated to the new position and removed from
                                the repeat cycle. If you update all, all bookings will be updated to the new position.
                            </>
                        )}
                    </Alert>
                )}
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={cancel} dataCy="button--cancel" />
                <SuccessButton name="Update all" onClick={updateAll} dataCy="button--update-all" />
                <SuccessButton name={isMultiMove ? 'Update selected' : 'Update this'} onClick={updateOne} dataCy="button--update-this" />
            </ModalFooter>
        </Modal>
    );
};

EditRepeatModal.propTypes = {
    type: PropTypes.oneOf(Object.keys(REPEAT_MODAL_TYPES)).isRequired,
    actions: PropTypes.shape({
        updateAll: PropTypes.func.isRequired,
        updateOne: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
    }),
};

export default EditRepeatModal;
