import Schema from 'form-schema-validation';
import { assign } from 'lodash';
import { errorMessages } from 'utils/schemaUtil';
import { emailValidator } from 'utils/formValidators';
import { ROLE_TEAM } from 'enums/resourceEnum';

export const getFormSchema = emailRequired =>
    new Schema(
        {
            firstName: {
                type: String,
                required: true,
            },
            lastName: {
                type: String,
                required: false,
            },
            email: {
                type: String,
                required: emailRequired,
                validators: [emailValidator],
            },
            sendInviteEmail: {
                type: Boolean,
                required: false,
            },
            role: {
                type: Object,
                required: true,
                defaultValue: ROLE_TEAM,
            },
        },
        errorMessages,
        false
    );

export const getDefaultValues = firstName =>
    assign({}, getFormSchema().getDefaultValues(), firstName ? { firstName } : {});

export const mapFormToRequest = values => ({
    sendInviteEmail: values.sendInviteEmail,
    resource: {
        email: values.email || null,
    },
    companySpecific: {
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role.value,
    },
});
