import allocationTypes from 'enums/allocationTypeEnum';
// from backend we receive allocation info for category as separate props:
// state, percentAllocation, totalBucketMinutesAllocation, minutesPerDay
// this function turns this info into allocation readable value
export const getAllocationDisplayValue = ({
    state,
    percentAllocation,
    totalBucketMinutesAllocation,
    minutesPerDay,
}) => {
    if (!state) return '';
    const allocationType = allocationTypes[state];

    const values = {
        percentage: percentAllocation,
        total: totalBucketMinutesAllocation / 60,
        hours: minutesPerDay / 60,
    };

    return `${values[allocationType.valueName] || 0} ${allocationType.unit}`;
};

// this function returns allocation readable value from fields values from Allocation component
// in which we have fields: state (allocation type) and values (array of numbers (% or hrs))
export const getAllocationDisplayValueFromFields = (state, values) =>
    getAllocationDisplayValue({
        state,
        percentAllocation: values.percentage,
        totalBucketMinutesAllocation: values.total * 60,
        minutesPerDay: values.hours * 60,
    });

export const getAllocationFieldValuesByCategory = ({
    state,
    percentAllocation,
    totalBucketMinutesAllocation,
    minutesPerDay,
}) => ({
    state,
    percentage: percentAllocation,
    total: totalBucketMinutesAllocation / 60,
    hours: minutesPerDay / 60,
});
