import { IconButton } from '@hub-mono/ui';
import React from 'react';
import createLink from 'utils/linkCreatorUtil';
import { csv } from '../../csvDownloader/lib';

export const CsvDownloader = ({ columns, data, separator, filename, disabled }) => {
    const handleClick = () => {
        let csvData = csv(columns, data, separator);
        let bomCode = '%EF%BB%BF';
        if (!filename) {
            filename = 'csv_file';
        }

        if (-1 === filename.indexOf('.csv')) {
            filename += '.csv';
        }

        let newData = `data:text/csv;charset=utf-8,${bomCode}${encodeURIComponent(csvData)}`;
        createLink(newData, filename);
    };

    return <IconButton disabled={disabled} icon="excel" onClick={handleClick} />;
};
