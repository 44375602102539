import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import {
    findBookingToolTipHoverDelayByValue,
    bookingToolTipHoverDelaysArray,
} from 'modules/scheduler/enums/bookingToolTipHoverDelayEnum';
import { Section, SectionDescription, SectionHeader } from './styles';

const BookingToolTipDisplay = ({ bookingToolTipHoverDelay, onChange }) => (
    <Section>
        <SectionHeader>Booking Tool Tip Delay:</SectionHeader>
        <SectionDescription>
            On hovering a booking, the speed the tooltip will display after hovered.
        </SectionDescription>

        <UncontrolledDropdown>
            <DropdownToggle style={{ width: '160px' }} caret>
                {findBookingToolTipHoverDelayByValue(bookingToolTipHoverDelay).label}
            </DropdownToggle>
            <DropdownMenu>
                {bookingToolTipHoverDelaysArray.map(delay => (
                    <DropdownItem key={delay.value} onClick={() => onChange({ bookingToolTipHoverDelay: delay.value })}>
                        {delay.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    </Section>
);

BookingToolTipDisplay.propTypes = {
    bookingToolTipHoverDelay: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default BookingToolTipDisplay;
