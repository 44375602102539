import React, { useCallback } from 'react';
import { Chip, Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { updateDates } from '../../global/utils/dateTranslator';

const dateRangesChips = [
    {
        label: 'This week',
        dataCy: 'range-option-this-week',
        getRange: weekType => updateDates('this', null, weekType),
    },
    {
        label: 'Next week',
        dataCy: 'range-option-next-week',
        getRange: weekType => updateDates('add', { week: 1 }, weekType),
    },
    {
        label: 'This month',
        dataCy: 'range-option-this-month',
        getRange: () => updateDates('this', null, 'month'),
    },
    {
        label: 'Next month',
        dataCy: 'range-option-next-month',
        getRange: () => updateDates('add', { months: 1 }, 'month'),
    },
    {
        label: 'This quarter',
        dataCy: 'range-option-this-quarter',
        getRange: () => updateDates('this', null, 'quarter'),
    },
];

const StyleChip = withStyles({
    root: {
        borderColor: '#ADDBC8',
        color: '#32A675',
        '&:active': {
            backgroundColor: '#30a775',
            color: 'white',
        },
    },
})(Chip);

const useStyles = makeStyles({
    root: {
        padding: '2px',
    },
});

const Item = props => {
    const { range, disabled, onClick } = props;

    const handleClick = useCallback(() => {
        onClick(range);
    }, [onClick, range]);

    return (
        <StyleChip
            disabled={disabled}
            variant="outlined"
            size="small"
            label={range.label}
            onClick={handleClick}
            disableRipple={true}
        />
    );
};

const PredefinedDates = props => {
    const { onSelect, disabled } = props;

    const classes = useStyles();

    return (
        <>
            {dateRangesChips.map(range => {
                return (
                    <Grid item key={range.dataCy} className={classes.root}>
                        <Item disabled={disabled} range={range} onClick={onSelect} />
                    </Grid>
                );
            })}
        </>
    );
};

PredefinedDates.propTypes = {
    onSelect: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

const memoized = React.memo(PredefinedDates);

export { memoized as PredefinedDates };
