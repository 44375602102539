import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getApprovees = {
    request: resourceId => action(actionTypes.GET_APPROVEES['REQUEST'], { resourceId }),
    success: approvees => action(actionTypes.GET_APPROVEES['SUCCESS'], { approvees }),
};

export const addApprovees = {
    request: ({ resourceId, approveeIds }) => action(actionTypes.ADD_APPROVEES['REQUEST'], { resourceId, approveeIds }),
    success: ({ resourceId, approveeIds }) => action(actionTypes.ADD_APPROVEES['SUCCESS'], { resourceId, approveeIds }),
    failure: ({ resourceId, approveeIds }) => action(actionTypes.ADD_APPROVEES['FAILURE'], { resourceId, approveeIds }),
};

export const deleteApprovees = {
    request: ({ resourceId, approveeIds }) =>
        action(actionTypes.DELETE_APPROVEES['REQUEST'], { resourceId, approveeIds }),
    success: ({ resourceId, approveeIds }) =>
        action(actionTypes.DELETE_APPROVEES['SUCCESS'], { resourceId, approveeIds }),
    failure: ({ resourceId, approveeIds }) =>
        action(actionTypes.DELETE_APPROVEES['FAILURE'], { resourceId, approveeIds }),
};

export const getResources = {
    request: force => action(actionTypes.GET_RESOURCES['REQUEST'], { force }),
    success: (resources, lastCallContext) =>
        action(actionTypes.GET_RESOURCES['SUCCESS'], { resources, lastCallContext }),
    stop: () => action(actionTypes.GET_RESOURCES_STOP),
};

export const getProjectManagers = {
    request: () => action(actionTypes.GET_PROJECT_MANAGERS['REQUEST']),
    success: projectManagers => action(actionTypes.GET_PROJECT_MANAGERS['SUCCESS'], { projectManagers }),
    failure: () => action(actionTypes.GET_PROJECT_MANAGERS['FAILURE']),
};

export const getResource = {
    request: id => action(actionTypes.GET_RESOURCE['REQUEST'], { id }),
    success: resource => action(actionTypes.GET_RESOURCE['SUCCESS'], { resource }),
};

export const createResource = {
    request: (data, callback, redirect = false) =>
        action(actionTypes.CREATE_RESOURCE['REQUEST'], { data, callback, redirect }),
    success: (resource, resourceGroupsAdd = []) =>
        action(actionTypes.CREATE_RESOURCE['SUCCESS'], { resource, resourceGroupsAdd }),
};

export const duplicateResource = {
    request: (resourceId, resourceGroupId, callback) =>
        action(actionTypes.DUPLICATE_RESOURCE['REQUEST'], { resourceId, resourceGroupId, callback }),
    success: (resource, resourceGroupsAdd) =>
        action(actionTypes.DUPLICATE_RESOURCE['SUCCESS'], { resource, resourceGroupsAdd }),
};

export const updateResource = {
    request: (id, body, callback) => {
        return action(actionTypes.UPDATE_RESOURCE['REQUEST'], { id, body, callback });
    },
    success: (resource, additionalInfo) => {
        return action(actionTypes.UPDATE_RESOURCE['SUCCESS'], { resource, additionalInfo });
    },
    failure: (id, body) => {
        return action(actionTypes.UPDATE_RESOURCE['FAILURE'], { id, body });
    },
};

export const deleteResource = {
    request: (id, cb) => action(actionTypes.DELETE_RESOURCE['REQUEST'], { id, cb }),
    success: id => action(actionTypes.DELETE_RESOURCE['SUCCESS'], { id }),
};

export const createMultipleResources = {
    request: (data, callback, withoutNotification) =>
        action(actionTypes.CREATE_MULTIPLE_RESOURCES['REQUEST'], { data, callback, withoutNotification }),
    success: ({ created, updated }) => action(actionTypes.CREATE_MULTIPLE_RESOURCES['SUCCESS'], { created, updated }),
};

export const resetResourceValue = {
    request: propertyName => action(actionTypes.RESET_RESOURCE_VALUE['REQUEST'], { propertyName }),
};

export const getLoggedInUser = {
    request: () => action(actionTypes.GET_LOGGED_IN_USER['REQUEST']),
    success: resource => action(actionTypes.GET_LOGGED_IN_USER['SUCCESS'], { resource }),
};

export const generateCalendarKey = {
    request: () => action(actionTypes.GENERATE_CALENDAR_KEY['REQUEST']),
    success: calendarKey => action(actionTypes.GENERATE_CALENDAR_KEY['SUCCESS'], { calendarKey }),
};

export const updateLoggedInResource = {
    request: data => action(actionTypes.UPDATE_LOGGED_IN_RESOURCE['REQUEST'], { data }),
    success: data => action(actionTypes.UPDATE_LOGGED_IN_RESOURCE['SUCCESS'], { data }),
};
