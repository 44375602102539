import React from 'react';
import { Row, Col } from 'reactstrap';
import { NotFoundImage } from './styles';

const NotFound = () => (
    <Row>
        <Col>
            <div className="text-center">
                <NotFoundImage src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png" />
                <h2>Error</h2>
                <div className="text-muted">There was some error during loading the data.</div>
            </div>
        </Col>
    </Row>
);

export default NotFound;
