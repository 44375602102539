export const requestTableCSVColumns = [
    {
        id: 'resourceFirstName',
        displayName: 'Resource First name',
    },
    {
        id: 'resourceLastName',
        displayName: 'Resource Last name',
    },
    {
        id: 'projectName',
        displayName: 'Project Name',
    },
    {
        id: 'type',
        displayName: 'Type',
    },
    {
        id: 'creatorFirstName',
        displayName: 'Creator First name',
    },
    {
        id: 'creatorLastName',
        displayName: 'Creator Last name',
    },
    {
        id: 'createdDate',
        displayName: 'Created date',
    },
    {
        id: 'approverFirstName',
        displayName: 'Approver First name',
    },
    {
        id: 'approverLastName',
        displayName: 'Approver Last name',
    },
    {
        id: 'approvalInfo.approvedOrRejectedDate',
        displayName: 'Approver/Rejected date',
    },
    {
        id: 'approvalInfo.requesterNote',
        displayName: 'Requester Note',
    },
    {
        id: 'approvalInfo.approverNote',
        displayName: 'Approver Note',
    },
];

export const teamOverviewCSVColumns = ({ vacationEventName, isVacation, isToilExtensionInstalled }) => {
    return [
        {
            id: 'resourceName',
            displayName: 'Firstname & LastName',
            show: true,
        },
        {
            id: 'ACCRUED',
            displayName: `Accrued ${vacationEventName}`,
            show: isVacation,
        },
        {
            id: 'ACCRUED_TOIL',
            displayName: 'Accrued TOIL (hrs)',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'ACCRUED_TOTAL',
            displayName: 'Accrued Total',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'WAITING_FOR_APPROVAL',
            displayName: isVacation ? `Requested ${vacationEventName}` : `Requested`,
            show: true,
        },
        {
            id: 'REQUESTED_TOIL',
            displayName: 'Requested TOIL (hrs)',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'REQUESTED_TOTAL',
            displayName: 'Requested Total',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'APPROVED',
            displayName: isVacation ? `Consumed ${vacationEventName}` : 'Approved',
            show: true,
        },
        {
            id: 'CONSUMED_TOIL',
            displayName: 'Consumed TOIL (hrs)',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'CONSUMED_TOTAL',
            displayName: 'Consumed Total',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'REMAINING',
            displayName: `Remaining ${vacationEventName}`,
            show: isVacation,
        },
        {
            id: 'REMAINING_TOIL',
            displayName: 'Remaining TOIL (hrs)',
            show: isVacation && isToilExtensionInstalled,
        },
        {
            id: 'REMAINING_TOTAL',
            displayName: 'Remaining Total',
            show: isVacation && isToilExtensionInstalled,
        },
    ].filter(item => item.show);
};
