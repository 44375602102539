import moment from 'moment';
import { values } from 'lodash';

export const DAY = {
    name: 'DAY',
    display: 'Day',
    format: 'YYYY-MM-DD',
    groupedFormat: 'YYYY-MM',
    groupBy: period => period.substring(0, 7),
    displayFormat: 'dd D',
    displayChartFormat: 'D MMM',
    displayGroupFormat: 'MMMM YYYY',
};

export const WEEK = {
    name: 'WEEK',
    display: 'Week',
    format: 'W-GGGG',
    groupedFormat: 'YYYY-MM',
    groupBy: period => moment(period, 'W-GGGG').format('YYYY-MM'),
    displayFormat: 'Do',
    displayChartFormat: 'Do MMM',
    displayGroupFormat: 'MMMM YYYY',
};

export const MONTH = {
    name: 'MONTH',
    display: 'Month',
    format: 'M-YYYY',
    groupedFormat: 'YYYY',
    groupBy: period => period.substring(period.indexOf('-') + 1),
    displayFormat: 'MMM',
    displayChartFormat: 'MMM, YYYY',
    displayGroupFormat: 'YYYY',
};

export const YEAR = {
    name: 'YEAR',
    display: 'Year',
    format: 'YYYY',
    groupBy: period => period,
    displayFormat: 'YYYY',
};

export const dateTypes = { DAY, WEEK, MONTH, YEAR };

export const dateTypeArray = values(dateTypes);
