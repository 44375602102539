import { call, delay, put, select, takeLatest, takeEvery } from 'redux-saga/effects';
import { head } from 'lodash';
import {
    createBookingLinkRequest,
    deleteBookingLinkRequest,
    getDaypilotProjectChildren,
    getDaypilotResources as getDaypilotResourcesRequest,
    getDaypilotUnassignedChildren,
} from 'api/scheduler';
import { getNewReportData } from 'api/report';
import * as actionTypes from 'actions/actionTypes';
import {
    createBookingLink,
    deleteBookingLink,
    getDaypilotChildrenResources,
    getDaypilotResources,
    getFinancialData,
    getSchedulerGroup,
    initializeFilters as initializeSchedulerFilters,
    schedulerRemoveRow,
} from 'actions/schedulerActions';
import { addNotification } from 'actions/notificationActions';
import accessDeniedHandler from 'sagas/handlers/accessDeniedHandler';
import { getAccountGridPreferences } from '../selectors/account';
import { filterFieldsByType, getCustomFields } from '../selectors/customField';
import { isActive } from '../utils/extensionUtil';
import { CUSTOM_FIELDS } from '../enums/extensionShortIdEnum';
import { waitFor } from './helpers/waitFor';
import {
    schedulerResourcesSelector,
    selectSchedulerDates,
    selectSchedulerFilterRelation,
    selectSchedulerFilters,
    selectSchedulerGroup,
    selectSchedulerInitialized,
    selectSchedulerIsGroupView,
    selectSchedulerMode,
} from '../selectors/scheduler';
import {
    getQueryFiltersFromQueryParams,
    mapCustomFieldsToFilters,
    transformFiltersToBackend,
} from '../modules/scheduler/utils/builderFiltersUtil';
import {
    dispatchRefreshDPResourcesEvent,
    dispatchRefreshUnassignedDPResourcesEvent,
    invalidateRows,
    updateBookingsWithNewDependencies,
    updateVisibleLinksOnScheduler,
} from '../modules/scheduler/utils/schedulerUtil';
import { ALL } from '../modules/scheduler/enums/filterRelationEnum';
import { formatDate } from '../utils/DateUtil';
import { getProjectColor } from 'shared/lib/projects';
import { INITIAL_FILTER_RELATION } from '../modules/scheduler/modals/builder/consts';
import { makeGetProjectGroupById } from '../selectors/projectGroup';
import { getCompanyExtensions } from '../selectors/company';
import getRequestPayload from '../modules/report/utils/financialHelper';
import { getDefaultNotificationErrorHandler, handleErrors } from './helpers/errorHandler';
import {
    createBookingLinksDatesErrorHandler,
    linkedBookingsCircularDependencyErrorHandler,
} from './helpers/bookingLinks';
import { monitoring } from '../monitoring';
import { optimisticUpdates } from '../reducers/helpers/schedulerReducerUtils';

const defaultMessage = 'Something went wrong... cannot load resources into scheduler';

function* handleDPResources(action) {
    const extensions = yield select(state => state.companyReducer.company.extensions);
    const schedulerInitialized = yield select(selectSchedulerInitialized);
    const filters = yield select(selectSchedulerFilters);
    const filterRelation = yield select(selectSchedulerFilterRelation);
    const dates = yield select(selectSchedulerDates);
    const mode = yield select(selectSchedulerMode);
    const transformedFilters = schedulerInitialized ? transformFiltersToBackend(filters, dates) : undefined;
    const relation = transformedFilters?.length ? filterRelation : ALL.value;

    try {
        const userAccountGridPreferences = yield select(getAccountGridPreferences);

        const { isGroupView, ...requestParams } = action.payload.data;

        const response = yield call(getDaypilotResourcesRequest, {
            ...requestParams,
            filters: transformedFilters,
            filterRelation: schedulerInitialized ? relation : undefined,
            userAccountPreferences: {
                grid: {
                    ...userAccountGridPreferences,
                },
            },
        });

        let newFilters;

        const { group } = response;
        if (isGroupView && group?._id) {
            if (isActive(extensions, CUSTOM_FIELDS)) {
                yield waitFor(state => state.customFieldReducer.initialized);
            }

            const customFields = yield select(getCustomFields);

            const filteredResourceCustomFields = filterFieldsByType(customFields, 'CHOICES', `resourceCustomFields`);
            const filteredProjectCustomFields = filterFieldsByType(customFields, 'CHOICES', `projectCustomFields`);

            const convertedGroupFilters = getQueryFiltersFromQueryParams({ queryParams: group.queryParams });

            const groupConvertedResourceCustomFields = convertedGroupFilters.resourceCustomFields;
            const groupConvertedProjectCustomFields = convertedGroupFilters.projectCustomFields;

            const groupResourceCustomFieldFilters = mapCustomFieldsToFilters(
                groupConvertedResourceCustomFields?.filters,
                filteredResourceCustomFields
            );

            const groupProjectCustomFieldFilters = mapCustomFieldsToFilters(
                groupConvertedProjectCustomFields?.filters,
                filteredProjectCustomFields
            );

            newFilters = {
                filters: {
                    ...convertedGroupFilters,
                    resourceCustomFields: {
                        ...(convertedGroupFilters.resourceCustomFields || { operator: ALL.value }),
                        filters: groupResourceCustomFieldFilters,
                    },
                    projectCustomFields: {
                        ...(convertedGroupFilters.projectCustomFields || { operator: ALL.value }),
                        filters: groupProjectCustomFieldFilters,
                    },
                },
                filterRelation: group.queryParams?.filterRelation || INITIAL_FILTER_RELATION,
            };
        }

        response.scheduler.records = response.scheduler.records.map(record => {
            const { holidays = [] } = record;
            return Object.assign(record, {
                color:
                    record.hasOwnProperty('useStatusColor') && record.useStatusColor
                        ? getProjectColor(record)
                        : record.color,
                backgroundColor:
                    record.hasOwnProperty('useStatusColor') && record.useStatusColor
                        ? getProjectColor(record)
                        : record.backgroundColor,
                holidaysMap: holidays.reduce((acc, holiday) => {
                    const format = holiday.repeat ? 'MM-dd' : 'yyyy-MM-dd';
                    const formatted = formatDate(holiday.date, format);
                    acc[formatted] = holiday;
                    return acc;
                }, {}),
            });
        });

        if (!schedulerInitialized) {
            yield put(getSchedulerGroup.success({ isGroupView, group }));
            yield put(initializeSchedulerFilters.request(newFilters));
        }

        if (action.payload?.cb) {
            action.payload.cb();
        }

        yield put(
            getDaypilotResources.success({
                dpResources: response.scheduler.records,
                unassignedWorkGroup: response.unassignedWorkGroup ?? {},
                mode,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(getDaypilotResources.failure());
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

function* handleFinancialRequest(action) {
    const {
        projectId,
        projectGroupId,
        resourceId,
        singleProjectView,
        isSingleProjectView,
        isProjectGroupView,
    } = action.payload;

    const projectGroup = yield select(makeGetProjectGroupById(projectGroupId));
    const extensions = yield select(getCompanyExtensions);

    const requestPayload = getRequestPayload(
        {
            projectId,
            projectGroupId,
        },
        resourceId,
        projectGroup,
        singleProjectView,
        extensions,
        { isSingleProjectView, isProjectGroupView }
    );

    try {
        const response = yield call(getNewReportData, requestPayload, { limit: 1, page: 0 });

        yield put(getFinancialData.success(head(response.data) || {}));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

function* handleUpdateRow(action) {
    // If the newer status is archived, check if there is the same on scheduler with different status, then remove it.
    if (window.schedulerRef?.current) {
        const group = yield select(selectSchedulerGroup);
        const isGroupView = yield select(selectSchedulerIsGroupView);

        const { additionalInfo = {}, resource, projectGroupsRemove, projectGroupsAdd, project } = action.payload;
        const item = resource ?? project;

        const combinedAdditionalInfo = {
            ...additionalInfo,
            projectGroupsRemove,
            projectGroupsAdd,
        };

        const modifiedRow = window.schedulerRef.current.control.rows.find(dpRow => {
            return dpRow.id === item?._id;
        });

        if (
            isGroupView &&
            (combinedAdditionalInfo.resourceGroupsRemove?.includes(group?._id) ||
                combinedAdditionalInfo.projectGroupsRemove?.includes(group?._id))
        ) {
            yield put(
                schedulerRemoveRow({
                    item: action.payload?.resource ?? action.payload?.project,
                    additionalInfo: combinedAdditionalInfo,
                })
            );

            if (modifiedRow?.id) {
                window.schedulerRef.current.control.rows.remove(modifiedRow.id);
            }
        } else {
            // waiting for be for groups recalculation
            // might happen that resource needs to be removed from scheduler after update
            yield delay(1000);
            dispatchRefreshDPResourcesEvent({ rowId: item?._id });
        }
    }
}

function reloadSchedulerResources() {
    dispatchRefreshDPResourcesEvent();
    dispatchRefreshUnassignedDPResourcesEvent();
}

function* cleanUpActionOnCreateDependencyError(action) {
    yield put(createBookingLink.failure(action.payload));
}

function* handleCreateBookingLink(action) {
    try {
        yield call(createBookingLinkRequest, action.payload);
        updateBookingsWithNewDependencies(action.payload);
        yield put(
            addNotification({
                message: `Dependency was created`,
                type: 'success',
            })
        );
        yield put(createBookingLink.success());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            linkedBookingsCircularDependencyErrorHandler,
            createBookingLinksDatesErrorHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error.response?.data?.code,
                message: error.response?.data?.properties?.cause,
                defaultMessage: `We couldn't save dependency`,
                cleanUpAction: () => cleanUpActionOnCreateDependencyError(action),
            })
        );
    }
}

function handleCreateBookingLinkSuccess() {
    updateVisibleLinksOnScheduler();
}

function* handleDeleteBookingLink(action) {
    try {
        yield call(deleteBookingLinkRequest, action.payload);
        updateBookingsWithNewDependencies({ ...action.payload, remove: true });
        yield put(deleteBookingLink.success());
        yield put(
            addNotification({
                message: `Dependency was deleted`,
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(deleteBookingLink.failure(action.payload));
        yield put(
            addNotification({
                message: error?.data?.message ?? `We couldn't delete dependency`,
                type: 'danger',
            })
        );
    }
}

function handleDeleteBookingLinkSuccess() {
    updateVisibleLinksOnScheduler();
}

function handleSetSchedulerLinks() {
    updateVisibleLinksOnScheduler();
}

function handleUpdateBookingSelection(action) {
    if (!action.payload.booking?.id || !action.payload.booking?.id) {
        window.schedulerRef?.current?.control.multiselect.clear();
    }
}

function* handleGetDaypilotChildrenResourcesRequest(action) {
    const { parentId, childrenIds } = action.payload;
    try {
        const children = yield call(getDaypilotProjectChildren, parentId, childrenIds);

        yield put(getDaypilotChildrenResources.success({ parentId, children }));
    } catch (error) {
        yield put(
            addNotification({
                message: error?.data?.message ?? `We couldn't load data`,
                type: 'danger',
            })
        );
    }
}

function* optimisticUpdateDPResources(action) {
    const dpResources = yield select(schedulerResourcesSelector);
    const updatedResource = dpResources?.find(resource => resource._id === action.payload.id);

    if (!updatedResource || !window?.schedulerRef?.current?.control) {
        return;
    }

    const control = window.schedulerRef.current.control;
    const row = control.rows.find(r => r.id === action.payload.id);
    row.data.tags.resource = updatedResource;
    control.rows.update(row);
    invalidateRows([row]);
    control.update();
}

function handleUpdateResourceFailure(action) {
    const shouldRollback = optimisticUpdates.some(({ shouldUpdate }) => {
        return shouldUpdate(action);
    });

    if (shouldRollback) {
        dispatchRefreshDPResourcesEvent();
    }
}

function* handleDPUnassignedResourcesRequest(action) {
    const { onSuccess } = action.payload;

    const schedulerDates = yield select(selectSchedulerDates);
    const filterRelation = yield select(selectSchedulerFilterRelation);
    const filtersApplied = yield select(selectSchedulerFilters);

    const transformedFilters = transformFiltersToBackend(filtersApplied, schedulerDates);
    const relation = transformedFilters?.length ? filterRelation : ALL.value;

    try {
        const response = yield call(getDaypilotUnassignedChildren, {
            ...action.payload,
            filters: transformedFilters,
            filterRelation: relation,
            limit: 9999,
            page: 1,
        });

        if (onSuccess) {
            onSuccess(response);
        }
    } catch (error) {
        monitoring.captureException(error);
    }
}

export default function* customerWatcher() {
    yield takeLatest(actionTypes.GET_DAYPILOT_RESOURCES['REQUEST'], handleDPResources);
    yield takeLatest(actionTypes.UPDATE_FINANCIAL['REQUEST'], handleFinancialRequest);
    yield takeLatest(actionTypes.UPDATE_RESOURCE['REQUEST'], optimisticUpdateDPResources);
    yield takeLatest(actionTypes.UPDATE_RESOURCE['SUCCESS'], handleUpdateRow);
    yield takeEvery(actionTypes.UPDATE_RESOURCE['FAILURE'], handleUpdateResourceFailure);
    yield takeLatest(actionTypes.UPDATE_PROJECT['SUCCESS'], handleUpdateRow);
    yield takeLatest(actionTypes.SET_SCHEDULER_FILTER_ITEM['REQUEST'], reloadSchedulerResources);
    yield takeLatest(actionTypes.SET_SCHEDULER_FILTER_ITEMS, reloadSchedulerResources);
    yield takeLatest(actionTypes.SET_SCHEDULER_FILTER_RELATION, reloadSchedulerResources);
    yield takeLatest(actionTypes.SET_FILTER_OPERATOR, reloadSchedulerResources);
    yield takeLatest(actionTypes.REMOVE_FILTER['REQUEST'], reloadSchedulerResources);
    yield takeLatest(actionTypes.REMOVE_FILTERS, reloadSchedulerResources);
    yield takeLatest(actionTypes.CLEAR_APPLIED_FILTERS['REQUEST'], reloadSchedulerResources);
    yield takeLatest(actionTypes.APPLY_BUILDER_DATA['REQUEST'], reloadSchedulerResources);
    yield takeLatest(actionTypes.INIT_SCHEDULER, reloadSchedulerResources);
    yield takeLatest(actionTypes.CREATE_BOOKING_LINK['REQUEST'], handleCreateBookingLink);
    yield takeLatest(actionTypes.CREATE_BOOKING_LINK['SUCCESS'], handleCreateBookingLinkSuccess);
    yield takeLatest(actionTypes.DELETE_BOOKING_LINK['REQUEST'], handleDeleteBookingLink);
    yield takeLatest(actionTypes.DELETE_BOOKING_LINK['SUCCESS'], handleDeleteBookingLinkSuccess);
    yield takeLatest([actionTypes.SET_SCHEDULER_BOOKING_LINKS], handleSetSchedulerLinks);
    yield takeLatest([actionTypes.UPDATE_BOOKING_SELECTION], handleUpdateBookingSelection);
    yield takeLatest(actionTypes.GET_CHILDREN_RESOURCES['REQUEST'], handleGetDaypilotChildrenResourcesRequest);
    yield takeLatest(actionTypes.GET_DAYPILOT_UNASSIGNED_RESOURCES['REQUEST'], handleDPUnassignedResourcesRequest);
}
