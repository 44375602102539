import React, { useCallback, useRef, useEffect, useState } from 'react';
import { ButtonBase, Popover } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton } from '../../../../shared/components/toggleButton/ToggleButton';
import { ALL, ANY } from '../../enums/filterRelationEnum';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks';

const options = [
    {
        label: ALL.name,
        value: ALL.value,
    },
    {
        label: ANY.name,
        value: ANY.value,
    },
];

const useStyles = makeStyles({
    toggleButtonContainer: {
        padding: '20px',
    },
});

export const OperatorToggle = React.memo(props => {
    const { value, buttonClassName, onChange, popoverId } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleFocus = event => {
        event.stopPropagation();
    };

    const handleChange = useCallback(
        (event, operator) => {
            event.stopPropagation();
            onChange(operator);
        },
        [onChange]
    );

    const isPopoverOpen = Boolean(anchorEl);

    const node = useRef();

    const [dropDownSize, setDropDownSize] = useState();

    const handler = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target)) {
                setAnchorEl(null);
            }
        },
        [setAnchorEl]
    );

    useClickOutside(handler);

    useEffect(() => {
        if(node.current?.getBoundingClientRect() && isPopoverOpen){
            const { height, width } = node?.current?.getBoundingClientRect();
            setDropDownSize({
                height,
                width
            })
        }
    }, [isPopoverOpen])

    return (
        <>
            <ButtonBase
                disableRipple
                disableTouchRipple
                onClick={handleOpen}
                onFocus={handleFocus}
                className={buttonClassName}
            >
                <MoreVert />
            </ButtonBase>
            <Popover
                id={popoverId}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                disablePortal
                hideBackdrop
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                style={{
                    position: 'absolute',
                    width: dropDownSize?.width,
                    height: dropDownSize?.height,
                }}
                PaperProps={{
                    style: {
                        position: 'fixed',
                        top: 0,
                        left: 0
                    }
                }}
            >
                <div
                ref={node}  className={classes.toggleButtonContainer}>
                    Matches <ToggleButton options={options} onChange={handleChange} value={value} /> filter(s) below
                </div>
            </Popover>
        </>
    );
});

OperatorToggle.propTypes = {
    value: PropTypes.oneOf([ALL.value, ANY.value]),
    buttonClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    popoverId: PropTypes.string.isRequired,
};
