import { sortBy, values, pluck } from 'underscore';

const timeSheetTypes = [
    { label: 'Unsubmitted Time', value: 'UNSUBMITTED' },
    { label: 'Submitted Time', value: 'SUBMITTED' },
    { label: 'Pending Time', value: 'PENDING' },
    { label: 'Approved Time', value: 'APPROVED' },
    { label: 'Rejected Time', value: 'REJECTED' },
];

const bookingStatuses = [
    { label: 'Scheduled Time', value: 'SCHEDULED' },
    { label: 'Approved Time', value: 'APPROVED' },
    { label: 'Requested Time', value: 'WAITING_FOR_APPROVAL' },
    { label: 'Rejected Time', value: 'REJECTED' },
];

export const APPROVED = { display: 'Approved', value: 'APPROVED' };
export const REJECTED = { display: 'Rejected', value: 'REJECTED' };
export const WAITING_FOR_APPROVAL = {
    display: 'Requested',
    value: 'WAITING_FOR_APPROVAL',
};
export const SCHEDULED = { display: 'Scheduled', value: 'SCHEDULED' };

//Don't remove this variable. We will use it in the future for settings
export const calculationTypes = [
    {
        name: 'Spend (Scheduled Time)',
        value: 'scheduledSpend',
        types: bookingStatuses,
    },
    {
        name: 'Spend (Reported Time)',
        value: 'actualSpend',
        types: timeSheetTypes,
    },
    {
        name: 'Spend (Archived Time)',
        value: 'archivedSpend',
        types: [
            { label: 'Archived Project Time', value: 'PROJECT' },
            { label: 'Archived Resource Time', value: 'RESOURCE' },
            { label: 'Archived Event Time', value: 'EVENT' },
        ],
    },
    {
        name: 'Spend (Unassigned Time)',
        value: 'budgetSpend',
        types: [{ label: 'Unassigned Time', value: 'UNASSIGNED_TIME' }],
    },
];

export const REPORT_OPTION_ZERO_FILL = {
    display: 'Include results with no data for date range',
    subText: 'Checking means that the report will display results that are outside your queried date range.',
    value: 'displayEmptyResults',
};
export const EVENT_TIME = { display: 'Include Event time', value: 'eventTime' };
export const UNASSIGNED_TIME = {
    display: 'Include Unassigned time',
    value: 'unassignedTime',
};

const billableOptions = [
    { display: 'Exclude hours marked as non-billable from spend calculations (default)', subText: 'Hours displayed will still show both billable & non-billable', value: '' },
    { display: 'Include hours marked as non-billable in spend calculations', subText: 'Hours displayed will still show both billable & non-billable', value: 'full-spend' },
    { display: 'Show only billable data', subText: 'Hours and Spend displayed will only use hours marked as billable', value: 'only-billable' },
    { display: 'Show only non-billable data', subText: 'Hours and Spend displayed will only use hours marked as non-billable', value: 'non-billable' },
];

const reportOptions = { REPORT_OPTION_ZERO_FILL, EVENT_TIME, UNASSIGNED_TIME };

export const reportOptionsArray = sortBy(values(reportOptions), status => status.value);
export const timeSheetTypesArray = pluck(timeSheetTypes, 'value');
export const bookingStatusesArray = pluck(bookingStatuses, 'value');
export const reportBillableArray = values(billableOptions);
