import { call, put, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form';
import * as actionTypes from 'actions/actionTypes';
import { createRate } from 'actions/rateActions';
import { addNotification } from 'actions/notificationActions';
import { createRateRequest } from 'api/rate';
import { monitoring } from '../monitoring';

function* handleCreateRate(action) {
    try {
        const { data, formName, fieldName } = action.payload;
        const rate = yield call(createRateRequest, data);

        yield put(createRate.success(rate));
        if (formName && fieldName) {
            yield put(change(formName, fieldName, rate));
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: 'Rate was not created', type: 'danger' }));
    }
}

export default function* rateWatcher() {
    yield takeLatest(actionTypes.CREATE_RATE['REQUEST'], handleCreateRate);
}
