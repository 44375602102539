import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, reduxForm, initialize, getFormValues } from 'redux-form';
import { textField } from 'shared/formFields';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, getDefaultValues, mapFormToRequest } from './milestoneFormSchema';
import { DateToggler, RepeatToggler, ColorToggler } from './../../../../shared/togglers';
import SuccessButton from 'shared/buttons/success';
import { useForm } from 'hooks';
import useUniqueToggler from '../../../../../../../../shared/hooks/useUniqueToggler';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';

const FORM_NAME = 'milestoneForm';

const interDependentFieldsKeys = Object.entries(formSchema.schema || {})
    .filter(arr => arr[1].validatorInterdependent)
    .map(arr => arr[0]);

const MilestoneForm = props => {
    const { project, currentSelection, handleSubmit, submit, onClick, touch } = props;
    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);

    const formValuesSelector = useMemo(() => getFormValues(FORM_NAME), []);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);

    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;
    const invalid = !validDataForSchema;

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
        onlyKeys: interDependentFieldsKeys,
    });

    const { onToggleOpen, dateCloseToggler, repeatCloseToggler, colorCloseToggler } = useUniqueToggler([
        'date',
        'repeat',
        'color',
    ]);

    useEffect(() => {
        dispatch(
            initialize(
                FORM_NAME,
                getDefaultValues(currentSelection.start, project.color, project._id, {
                    date: currentSelection.start,
                    name: currentSelection.name,
                    color: currentSelection.backColor,
                })
            )
        );
    }, [dispatch, currentSelection, project.color, project._id]);

    const isEdit = currentSelection?.id;
    const buttonText = `${isEdit ? 'Update' : 'Add'} milestone`;

    const onSubmit = useCallback(
        values => {
            const mappedValues = mapFormToRequest(values);
            // We don't support yet update all/future. Update single one as not repeated
            onClick({
                ...mappedValues,
                ...(isEdit && currentSelection.repeat
                    ? {
                          interval: 'NONE',
                          repeatTimes: 0,
                          repeat: false,
                          repeatId: null,
                      }
                    : {}),
            });
        },
        [currentSelection.repeat, isEdit, onClick]
    );

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Field name="name" wrapperClassName="px-2" placeholder="Milestone name" component={textField} />
            <DateToggler
                start={formValues.date}
                single={true}
                onDateChange={({ start }) => updateFields({ date: start })}
                onToggleOpen={() => onToggleOpen('date')}
                closeToggle={dateCloseToggler}
            />
            {!currentSelection.repeat && (
                <RepeatToggler
                    interval={formValues.interval}
                    onToggleOpen={() => onToggleOpen('repeat')}
                    closeToggle={repeatCloseToggler}
                    formName={FORM_NAME}
                />
            )}
            <ColorToggler
                color={formValues.color}
                onChange={color => updateFields({ color })}
                onToggleOpen={() => onToggleOpen('color')}
                closeToggle={colorCloseToggler}
            />
            <div className="mx-2 mt-2">
                <SuccessButton
                    disabled={invalid}
                    className="w-100"
                    onClick={submit}
                    name={buttonText}
                    dataCy="button--submit-milestone-dates"
                />
            </div>
        </Form>
    );
};

MilestoneForm.propTypes = {
    project: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    currentSelection: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
    }),
};

MilestoneForm.defaultProps = {
    currentSelection: {
        start: {},
        end: {},
    },
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(MilestoneForm);
