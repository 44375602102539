import React from 'react';
import PropTypes from 'prop-types';
import ProgressBarCell from './progressBarCell';
import { getColumnCurrencies } from 'modules/report/utils/columnHelper';

const MIXED_RATES_THRESHOLD = 2;

const PercentageCell = row => {
    const value = row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />;

    return MIXED_RATES_THRESHOLD >
        getColumnCurrencies(row.original, 'externalConsumedScheduledBudgetCashAllTimePercentage').length
        ? value
        : 'Mixed Rates Used';
};

PercentageCell.propTypes = {
    row: PropTypes.shape({
        columnProps: PropTypes.object,
        original: PropTypes.object,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }).isRequired,
};

export default PercentageCell;
