import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { useAppKeyWords } from 'hooks';
import { useMenuProjectPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import { showProjectModal, showResourceModal } from 'actions/modalActions';
import { updateProject } from 'actions/projectActions';
import { updateResource } from 'actions/resourceActions';
import getPasteBookingDisabled from '../utils/getPasteBookingDisabled';
import ScheduleAndRequestSelection from './scheduleAndRequestSelection';
import { KeyBoardShortCutIcon } from '../../../booking/partials/legacy/styles';
import { NestedMenuItemManager } from '../../../../../../../shared/nestedMenuItem/context';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';

const LegacyChildrenRows = React.forwardRef(
    (
        {
            showRequestResourceOption,
            scale,
            rowTags,
            onClick,
            onPaste,
            isRequestMode,
            combineScheduleRequest,
            onClose,
            showRequestVacationOption,
            isDefaultVacationEventSet,
            onRequestVacation,
        },
        schedulerRef
    ) => {
        const dispatch = useDispatch();
        const { projectKeyWord, resourceKeyWord } = useAppKeyWords();
        const preparedRowTags = rowTags || {};
        const { resource, project } = preparedRowTags;
        const clipboard = useSelector(state => state.scheduler.clipboard);

        const { hasAddEditProjectRights } = useMenuProjectPermissions();

        const projectModal = useCallback(() => {
            dispatch(
                showProjectModal({
                    onSubmit: data => dispatch(updateProject.request(project._id, data)),
                    type: project.type,
                    projectId: project._id,
                })
            );
            if (onClose) {
                onClose();
            }
        }, [dispatch, onClose, project._id, project.type]);

        const resourceModal = useCallback(() => {
            dispatch(
                showResourceModal({
                    onSubmit: data => dispatch(updateResource.request(resource._id, data)),
                    type: resource.type,
                    resourceId: resource._id,
                })
            );
            if (onClose) {
                onClose();
            }
        }, [dispatch, onClose, resource._id, resource.type]);

        let modifierKeyPrefix = 'CTRL +'; // control key
        if (window.navigator.platform.indexOf('Mac') === 0) {
            modifierKeyPrefix = '⌘'; // command key
        }

        return (
            <NestedMenuItemManager>
                <WrappedMenuItem
                    data-cy="button-classic-menu-paste"
                    onClick={onPaste}
                    disabled={getPasteBookingDisabled(clipboard, preparedRowTags)}
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mt-2"
                >
                    Paste
                    <KeyBoardShortCutIcon>{modifierKeyPrefix} V</KeyBoardShortCutIcon>
                </WrappedMenuItem>
                <Divider />
                <ScheduleAndRequestSelection
                    ref={schedulerRef}
                    showRequestResourceOption={showRequestResourceOption}
                    scale={scale}
                    rowTags={preparedRowTags}
                    onClick={onClick}
                    isRequestMode={isRequestMode}
                    combineScheduleRequest={combineScheduleRequest}
                    showRequestVacationOption={showRequestVacationOption}
                    onRequestVacation={onRequestVacation}
                    isDefaultVacationEventSet={isDefaultVacationEventSet}
                    projectId={preparedRowTags.isResourceRow ? preparedRowTags.projectId : project._id}
                    resource={resource}
                    resourceId={
                        preparedRowTags.isResourceRow || preparedRowTags.unassignedRow
                            ? resource._id
                            : preparedRowTags.resourceId
                    }
                    onClose={onClose}
                />
                {hasAddEditProjectRights && project.hasRightsToProject && (
                    <>
                        <Divider />
                        {rowTags.isProjectRow && (
                            <WrappedMenuItem
                                onClick={projectModal}
                                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                                data-cy="scheduler-booking-classic-menu-grouped-rows-edit-project"
                            >
                                Edit {projectKeyWord.toLowerCase()}...
                            </WrappedMenuItem>
                        )}
                        {rowTags.isResourceRow && (
                            <WrappedMenuItem
                                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                                onClick={resourceModal}
                                data-cy="scheduler-booking-classic-menu-grouped-rows-edit-resource"
                            >
                                Edit {resourceKeyWord.toLowerCase()}...
                            </WrappedMenuItem>
                        )}
                    </>
                )}
            </NestedMenuItemManager>
        );
    }
);

LegacyChildrenRows.propTypes = {
    scale: PropTypes.string.isRequired,
    onPaste: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    rowTags: PropTypes.object,
    showRequestResourceOption: PropTypes.bool,
};

LegacyChildrenRows.defaultProps = {
    rowTags: {},
    showRequestResourceOption: false,
};

export default LegacyChildrenRows;
