import React, { useState, useCallback, useMemo, useRef } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStyles } from './../styles';
import { useAccountPreferences, useGroup, useGroupActions } from 'modules/scheduler/hooks';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { Button } from './styles';
import Popup from './popup';
import { Popover, Tooltip } from '@material-ui/core';
import { useIsExtensionInstalled } from 'hooks';
import { SMART_FILTERS } from 'enums/extensionShortIdEnum';
import ClickAwayListener from 'shared/clickAwayListener';

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
};

const transformOrigin = {
    vertical: 'top',
    horizontal: 'center',
};

const Filter = () => {
    const { filters } = useSelector(state => state.scheduler);
    const params = useParams();
    const { current } = useGroup(params);
    const { apply } = useGroupActions();
    const { isProjectView, isProjectGroupView, isSingleProjectView } = getViewObject(params);
    const [anchorEl, setAnchorEl] = useState(null);
    const { gridPreferences } = useAccountPreferences();
    const node = useRef();

    const isSmartFiltersExtensionInstalled = useIsExtensionInstalled(SMART_FILTERS);

    const classes = useStyles();

    const handleOpen = useCallback(e => setAnchorEl(e.currentTarget), []);

    const handleClose = useCallback(() => setAnchorEl(null), []);

    const getNewFilters = useCallback(
        ({ dates, availability, utilization, operator }) => ({
            ...filters,
            smartFilters: {
                operator,
                filters: {
                    ...filters?.smartFilters?.filters,
                    availability: { ...availability },
                    utilization: { ...utilization },
                    dates,
                },
            },
        }),
        [filters]
    );

    const handleClickOutside = useCallback(
        event => {
            const inPortalClick = Array.from(event.path ?? event.composedPath()).some(
                elem => elem.hasAttribute && elem.hasAttribute('data-in-portal')
            );

            // elements from mui rendered in portal can fire event on body element - in such case we should not close popup
            // as well we should not close popup when select/dropdown was opened, 'data-in-portal' attr has to be added
            if (event.target.nodeName === 'BODY' || inPortalClick) {
                return;
            }

            if (node.current && !node.current.contains(event.target) && Boolean(anchorEl)) {
                setAnchorEl(null);
            }
        },
        [anchorEl]
    );

    const getDataToApply = useCallback(
        newFilters => ({
            group: current,
            isSmart: current.isSmart,
            itemType: isProjectView ? PROJECT : RESOURCE,
            filters: newFilters,
            groupsToAdd: [],
            groupsToRemove: [],
            itemsToAdd: [],
            itemsToRemove: [],
            parentGroupId: current.parentGroupId,
        }),
        [current, isProjectView]
    );

    const onApply = useCallback(
        ({ dates, availability, utilization, operator }) => {
            const newFilters = getNewFilters({ dates, availability, utilization, operator });
            const data = getDataToApply(newFilters);

            apply(data);
            handleClose();
        },
        [apply, getDataToApply, getNewFilters, handleClose]
    );

    const utilizationAndAvailabilityDisabled =
        (isProjectGroupView || isSingleProjectView) && gridPreferences.mode === SINGLE.value;

    const toolTipText = useMemo(() => {
        if (isSmartFiltersExtensionInstalled && utilizationAndAvailabilityDisabled) {
            return 'Capacity Finder disabled in project view mode, single rows (project & resource). Switch view mode to apply availability or utilization filters. ';
        }
    }, [isSmartFiltersExtensionInstalled, utilizationAndAvailabilityDisabled]);

    const isFilterOn =
        Number.isInteger(Number.parseFloat(filters?.smartFilters?.filters?.utilization?.value)) ||
        Number.isInteger(Number.parseFloat(filters?.smartFilters?.filters?.availability?.value));

    const CapacityButtonFinder = (
        <Button
            data-cy="scheduler-nav-buttons-utilization-availability"
            aria-controls="schedule-menu"
            aria-haspopup="true"
            classes={classes}
            onClick={handleOpen}
            className={isFilterOn ? 'filter-active' : ''}
            endIcon={<ArrowDropDown />}
            disableRipple
            disabled={isSmartFiltersExtensionInstalled && utilizationAndAvailabilityDisabled}
        >
            Capacity Finder
        </Button>
    );

    return (
        <>
            {toolTipText ? (
                <Tooltip title={toolTipText}>
                    <span>{CapacityButtonFinder}</span>
                </Tooltip>
            ) : (
                CapacityButtonFinder
            )}
            <Popover
                id="capacityFinderPopUp"
                className="pe-none-once"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                hideBackdrop
                PaperProps={{
                    style: { overflow: 'visible' },
                }}
            >
                <ClickAwayListener handler={handleClickOutside}>
                    <div ref={node}> {filters && <Popup onApply={onApply} toggleDatePicker={handleClose} />}</div>
                </ClickAwayListener>
            </Popover>
        </>
    );
};

export default Filter;
