import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import StatusFilterBox from 'modules/report/containers/tableFilters/statusFilterBox';
import { Header, Status } from './../styles';
import {
    AVAILABILITY_STATUSES_ENUM,
    statusesList as availabilityStatusesList,
} from 'modules/report/enums/availabilityStatusEnum';
import {
    UTILIZATION_STATUSES_ENUM,
    statusesList as utilizationStatusesList,
} from 'modules/report/enums/utilizationStatusEnum';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import {
    displayDateState,
    displayStatusByType,
    displayValue,
    isHeaderDisabled,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import { formatNumber } from 'utils/formatingUtil';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';
import ProgressBarCell from 'modules/report/components/tableCells/progressBarCell';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Utilization
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'reportedUtilizationBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationBetween'),
        accessor: row =>
            displayValue(row.reportedUtilizationBetween, formatNumber(row.reportedUtilizationBetween || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Utilization
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'reportedUtilizationPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationPrevious'),
        accessor: row =>
            displayValue(row.reportedUtilizationPrevious, formatNumber(row.reportedUtilizationPrevious || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Utilization
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'reportedUtilizationAfter',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationAfter'),
        accessor: row =>
            displayValue(row.reportedUtilizationAfter, formatNumber(row.reportedUtilizationAfter || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedAvailabilityBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityBetween'),
        accessor: row =>
            displayValue(
                row.reportedAvailabilityBetween,
                unitTypes[unit].calculation(row.reportedAvailabilityBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedAvailabilityPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityPrevious'),
        accessor: row =>
            displayValue(
                row.reportedAvailabilityPrevious,
                unitTypes[unit].calculation(row.reportedAvailabilityPrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedAvailabilityAfter',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityAfter'),
        accessor: row =>
            displayValue(
                row.reportedAvailabilityAfter,
                unitTypes[unit].calculation(row.reportedAvailabilityAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Overtime
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedOvertimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedOvertimeBetween'),
        accessor: row =>
            displayValue(
                row.reportedOvertimeBetween,
                unitTypes[unit].calculation(row.reportedOvertimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Overtime
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedOvertimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'reportedOvertimePrevious'),
        accessor: row =>
            displayValue(
                row.reportedOvertimePrevious,
                unitTypes[unit].calculation(row.reportedOvertimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Overtime
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedOvertimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'reportedOvertimeAfter'),
        accessor: row =>
            displayValue(
                row.reportedOvertimeAfter,
                unitTypes[unit].calculation(row.reportedOvertimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability Status
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedAvailabilityStatusBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityStatusBetween'),
        accessor: row => displayValue(row.reportedAvailabilityStatusBetween),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedAvailabilityStatusPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityStatusPrevious'),
        accessor: row => displayValue(row.reportedAvailabilityStatusPrevious),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported Availability Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedAvailabilityStatusAfter',
        sortable: false,
        show: contains(columnsToLoad, 'reportedAvailabilityStatusAfter'),
        accessor: row => displayValue(row.reportedAvailabilityStatusAfter),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(reported)</p>
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedUtilizationStatusBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationStatusBetween'),
        accessor: row => displayValue(row.reportedUtilizationStatusBetween),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(reported)</p>
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedUtilizationStatusPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationStatusPrevious'),
        accessor: row => displayValue(row.reportedUtilizationStatusPrevious),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(reported)</p>
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedUtilizationStatusAfter',
        sortable: false,
        show: contains(columnsToLoad, 'reportedUtilizationStatusAfter'),
        accessor: row => displayValue(row.reportedUtilizationStatusAfter),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Reported FTE
                        <p className="sub-title">{displayDateState(dateState, false, false)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedFTEUtilizationBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedFTEUtilizationBetween'),
        accessor: row =>
            displayValue(row.reportedFTEUtilizationBetween, formatNumber(row.reportedFTEUtilizationBetween || 0)),
    },
];
