import styled from 'styled-components';
import { Button, PopoverBody, Popover } from 'reactstrap';

export const Wrapper = styled.div`
    text-align: center;
    border-left: 1px dotted #ccc;
`;

export const IconButton = styled(Button)`
    margin: 5px;
    width: 39px;
    border-radius: none;
`;

export const PopoverContent = styled(PopoverBody)`
    overflow-y: auto;
`;

export const StyledPopover = styled(Popover)`
    .popover {
        background-color: #f7f5f5;
        z-index: 90;
        padding: 10px;
    }
`;
