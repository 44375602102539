import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';
import HeaderTitle from './headerTitle';
import HeaderToolbar from './headerToolbar';
import HeaderFilter from './filter';
import SchedulerNav from './schedulerNav';
import SchedulerButtons from './schedulerButtons';
import { useHasRights } from 'hooks';
import { useScroll, useAccountPreferences, useGetModal, useGroup } from 'modules/scheduler/hooks';
import ProjectFinancial from './../projectFinancial';
import { DATA_TAB, ROW_FILTER_TAB } from 'modules/scheduler/modals/builder/tabs';
import { setSchedulerSelection } from '../../../../actions/schedulerActions';
import { dispatchRefreshDPResourcesEvent } from '../../utils/schedulerUtil';
import { getCompanySettings } from '../../../../selectors/company';
import { findByValue, scales } from '../../enums/scale';

const rights = [
    {
        rights: ['gridFilter'],
        rule: 'one',
        name: 'hasGridFilterRights',
    },
];

const Header = React.forwardRef((props, schedulerRef) => {
    const { showFinancialOptions, resource, name, subName, viewObject, viewData } = props;
    const openSchedulerBuilderModal = useGetModal({ schedulerRef, isFilterContext: false, isEditView: true });
    const { gridPreferences, updateGridPreferences } = useAccountPreferences(schedulerRef, viewObject);
    const companySettings = useSelector(getCompanySettings);
    const { scrollTo, scrollRight, scrollCenter, scrollLeft } = useScroll(schedulerRef);
    const { hasGridFilterRights } = useHasRights(rights);
    const currentName = useSelector(state => state.scheduler.name);
    const dispatch = useDispatch();

    const nameToUse = currentName || name;

    const params = useParams();
    const { resourceGroup } = useGroup(params);

    const openSchedulerBuilderModalWrapper = useCallback(() => {
        const initialTab = resourceGroup && resourceGroup.isSmart ? ROW_FILTER_TAB : DATA_TAB;
        openSchedulerBuilderModal(initialTab);
    }, [openSchedulerBuilderModal, resourceGroup]);

    const updateGridScale = useCallback(
        event => {
            dispatch(setSchedulerSelection.request());

            const scale = findByValue(event.target.dataset.scale);
            const eventMoveSkipNonBusiness = [scales.WEEK.value, scales.MONTH.value].includes(scale?.value)
                ? false
                : gridPreferences.eventSmartResize || companySettings.grid.hideNonWorkingDays;

            schedulerRef.current.control.update({
                eventMoveSkipNonBusiness,
            });

            updateGridPreferences({
                defaultScale: event.target.dataset.scale,
                customSearchId: event.target.dataset.customSearchId,
            });
        },
        [
            dispatch,
            gridPreferences.eventSmartResize,
            companySettings.grid.hideNonWorkingDays,
            schedulerRef,
            updateGridPreferences,
        ]
    );

    const updateFilterVisible = useCallback(() => {
        updateGridPreferences({
            filterVisible: !gridPreferences.filterVisible,
        });
    }, [updateGridPreferences, gridPreferences.filterVisible]);

    const updateViewMode = useCallback(
        mode => {
            if (mode !== gridPreferences.mode) {
                updateGridPreferences({
                    mode,
                });

                dispatchRefreshDPResourcesEvent();
            }
        },
        [gridPreferences.mode, updateGridPreferences]
    );

    return (
        <div className="mb-2 scheduler-header">
            <Row style={{ borderBottom: '1px solid #ddd', boxShadow: '0px 1px 0px #fff' }}>
                <Col className="d-flex align-items-center" lg={7} md={4} sm={2}>
                    <HeaderTitle
                        name={nameToUse}
                        subName={subName}
                        openModal={openSchedulerBuilderModal ? openSchedulerBuilderModalWrapper : undefined}
                        resource={resource}
                    />
                </Col>
                <Col lg={5} md={8} sm={10} className="pr-0">
                    <HeaderToolbar
                        ref={schedulerRef}
                        name={nameToUse}
                        toggleFilter={updateFilterVisible}
                        showFinancialOptions={showFinancialOptions}
                        hasFilterRights={hasGridFilterRights}
                    />
                </Col>
            </Row>
            {showFinancialOptions && <ProjectFinancial />}
            {gridPreferences.filterVisible && hasGridFilterRights && (
                <Row className="mt-2">
                    <Col>
                        <HeaderFilter ref={schedulerRef} />
                    </Col>
                </Row>
            )}
            <Row className="mt-2">
                <Col>
                    <SchedulerNav
                        scrollTo={scrollTo}
                        scrollRight={scrollRight}
                        scrollCenter={scrollCenter}
                        scrollLeft={scrollLeft}
                        defaultScale={gridPreferences.defaultScale}
                        viewMode={gridPreferences.mode}
                        onScaleChange={updateGridScale}
                        onViewModeChange={updateViewMode}
                    />
                </Col>
                <Col>
                    <SchedulerButtons ref={schedulerRef} viewObject={viewObject} viewData={viewData} />
                </Col>
            </Row>
        </div>
    );
});

Header.propTypes = {
    viewObject: PropTypes.object.isRequired,
    name: PropTypes.string,
    subName: PropTypes.string,
    resource: PropTypes.object,
    showFinancialOptions: PropTypes.bool,
};

Header.defaultProps = {
    name: '',
    subName: '',
    resource: {},
    showFinancialOptions: false,
};

export default Header;
