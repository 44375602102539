import { createSelector } from 'reselect';
import {
    BOOKING_FILTERS,
    getFiltersHasChanged,
    ROW_FILTERS,
    SMART_FILTERS,
} from '../modules/scheduler/utils/builderFiltersUtil';
import {
    selectBookingLinkDisplay,
    selectBookingLinkPosition,
    selectDeadlineDisplay,
    selectDeadlineLinkPosition,
} from './account';
import { selectIsDeadlinesExtensionInstalled, selectIsDependencyExtensionInstalled } from './company';
import { bookingLinksDisplayEnum } from '../modules/scheduler/enums/bookingLinkEnums';
import {
    deadlineDisplayEnum,
    defaultDeadlineDisplay,
    defaultDeadlineLinkPosition,
} from '../modules/scheduler/enums/deadlines';

const EMPTY_ARRAY = [];
const EMPTY_ARRAY_REF_2 = [];

export const schedulerSelector = state => state.scheduler;

export const selectCurrentSelectedBooking = state => state.scheduler.currentSelectedBooking;

export const schedulerResourcesSelector = state => schedulerSelector(state).dpResources;

export const selectDpChildrenResourcesById = state => schedulerSelector(state).dpChildrenResourcesById;

export const selectSchedulerGroup = state => schedulerSelector(state).group;

export const selectSchedulerIsGroupView = state => schedulerSelector(state).isGroupView;

export const selectSchedulerIsDPResourcesLoading = state => schedulerSelector(state).isDPResourcesLoading;

export const selectSchedulerFilterRelation = state => schedulerSelector(state).filterRelation;

export const selectSchedulerDates = state => schedulerSelector(state).dates;

export const selectSchedulerInitialized = state => schedulerSelector(state).schedulerInitialized;

export const selectSchedulerFilters = state => schedulerSelector(state).filters;

export const selectSchedulerResourcesFilter = state => schedulerSelector(state).filters.resources;

export const selectSchedulerResourceCustomFieldsFilter = state => schedulerSelector(state).filters.resourceCustomFields;

export const selectSchedulerResourceTagsFilter = state => schedulerSelector(state).filters.resourceTags;

export const selectSchedulerResourceStatusesFilter = state => schedulerSelector(state).filters.resourceStatuses;

export const selectSchedulerResourceRolesFilter = state => schedulerSelector(state).filters.resourceRoles;

export const selectSchedulerResourceIsPmFilter = state => schedulerSelector(state).filters.resourceIsPm;

export const selectSchedulerBookingCategoriesFilter = state => schedulerSelector(state).filters.bookingCategories;

export const selectSchedulerBookingStatusesFilter = state => schedulerSelector(state).filters.bookingStatuses;

export const selectSchedulerBookingProjectsEventsFilter = state =>
    schedulerSelector(state).filters.bookingProjectsEvents;

export const selectSchedulerBookingResourcesUWFilter = state => schedulerSelector(state).filters.bookingResourcesUW;

export const selectSchedulerProjectsFilter = state => schedulerSelector(state).filters.projects;

export const selectSchedulerProjectCustomFieldsFilter = state => schedulerSelector(state).filters.projectCustomFields;

export const selectSchedulerProjectTagsFilter = state => schedulerSelector(state).filters.projectTags;

export const selectSchedulerCustomersFilter = state => schedulerSelector(state).filters.customers;

export const selectSchedulerProjectManagersFilter = state => schedulerSelector(state).filters.projectManagers;

export const selectSchedulerProjectStatusesFilter = state => schedulerSelector(state).filters.projectStatuses;

export const selectSchedulerBookingProjectStatusesFilter = state =>
    schedulerSelector(state).filters.bookingProjectStatuses;

export const selectSchedulerCurrenciesFilter = state => schedulerSelector(state).filters.currencies;

export const selectSchedulerSmartFilters = state => schedulerSelector(state).filters.smartFilters;

export const selectSchedulerInitialFilters = state => schedulerSelector(state).initialFilters;

export const selectSchedulerGroupFilters = state => schedulerSelector(state).groupFilters;

export const selectSchedulerGroupSmartFilters = state => schedulerSelector(state).groupFilters.smartFilters;

export const selectRedrawSchedulerFlag = state => schedulerSelector(state).redrawSchedulerFlag;

export const selectBookingLinksMap = state => schedulerSelector(state).links;

export const selectDeadlinesLinksMap = state => schedulerSelector(state).deadlineLinks;

export const selectSchedulerMode = state => state.account.preferences.grid.mode;

export const selectBookingsTree = createSelector(selectBookingLinksMap, linksMap => {
    return Object.keys(linksMap).reduce((acc, linkKey) => {
        const [parentId, childId] = linkKey.split('-');

        if (!acc[parentId]) {
            acc[parentId] = {
                children: [],
                parents: [],
            };
        }

        if (!acc[childId]) {
            acc[childId] = {
                children: [],
                parents: [],
            };
        }

        acc[parentId].children.push(linksMap[linkKey]);
        acc[childId].parents.push(linksMap[linkKey]);

        return acc;
    }, {});
});

export const selectBookingsLinksTreeForBooking = bookingId =>
    createSelector(
        selectIsDependencyExtensionInstalled,
        selectBookingsTree,
        selectBookingLinkPosition,
        (isExtensionInstalled, bookingsTree, linkPosition) => {
            if (!isExtensionInstalled || !bookingId) {
                return EMPTY_ARRAY;
            }

            const node = bookingsTree[bookingId];

            if (!node) {
                return EMPTY_ARRAY;
            }

            let links = [];

            const addLinks = (node, collectionKey, idKey) => {
                if (node[collectionKey]?.length) {
                    links = links.concat(node[collectionKey]);

                    for (let nextNode of node[collectionKey]) {
                        addLinks(bookingsTree[nextNode[idKey]], collectionKey, idKey);
                    }
                }
            };

            addLinks(node, 'parents', 'parentId');
            addLinks(node, 'children', 'childId');

            return links.map(link => ({
                ...link,
                from: link.parentId,
                to: link.childId,
                id: `${link.parentId}-${link.childId}`,
                type: 'FinishToStart',
                layer: linkPosition,
            }));
        }
    );

export const selectBookingLinks = createSelector(
    selectIsDependencyExtensionInstalled,
    selectBookingsTree,
    selectBookingLinksMap,
    selectBookingLinkDisplay,
    selectBookingLinkPosition,
    selectCurrentSelectedBooking,
    (isExtensionInstalled, bookingsTree, linksMap, linkDisplay, linkPosition, currentSelectedBooking) => {
        if (
            !isExtensionInstalled ||
            linkDisplay === bookingLinksDisplayEnum.hide
        ) {
            return EMPTY_ARRAY;
        } else if (
            linkDisplay === bookingLinksDisplayEnum.showForSelectedBooking
        ) {
            const bookingId = currentSelectedBooking?.id;

            if (!bookingId) {
                // we return new ref to empty array so scheduler will properly react to display link config change
                return EMPTY_ARRAY_REF_2;
            }

            const node = bookingsTree[bookingId];

            if (!node) {
                return EMPTY_ARRAY;
            }

            let links = [];

            const addLinks = (node, collectionKey, idKey) => {
                if (node[collectionKey]?.length) {
                    links = links.concat(node[collectionKey]);

                    for (let nextNode of node[collectionKey]) {
                        addLinks(bookingsTree[nextNode[idKey]], collectionKey, idKey);
                    }
                }
            };

            addLinks(node, 'parents', 'parentId');
            addLinks(node, 'children', 'childId');

            return links.map(link => ({
                ...link,
                from: link.parentId,
                to: link.childId,
                id: `${link.parentId}-${link.childId}`,
                type: 'FinishToStart',
                cssClass: 'dependency-link',
                layer: linkPosition,
            }));
        }

        return Object.values(linksMap).map(link => ({
            ...link,
            from: link.parentId,
            to: link.childId,
            id: `${link.parentId}-${link.childId}`,
            type: 'FinishToStart',
            layer: linkPosition,
            cssClass: 'dependency-link',
            dependencyLink: true,
        }));
    }
);

export const makeSelectBookingLink = ({ parentId, childId }) =>
    createSelector(selectBookingLinksMap, linksMap => {
        return linksMap[`${parentId}-${childId}`];
    });

export const selectDeadlineLinks = createSelector(
    selectIsDeadlinesExtensionInstalled,
    selectDeadlinesLinksMap,
    selectDeadlineDisplay,
    selectDeadlineLinkPosition,
    selectCurrentSelectedBooking,
    (
        isDeadlinesExtensionInstalled,
        deadlineLinksMap,
        deadlineDisplay = defaultDeadlineDisplay,
        deadlineLinkPosition = defaultDeadlineLinkPosition,
        currentSelectedBooking
    ) => {
        if (!isDeadlinesExtensionInstalled || deadlineDisplay === deadlineDisplayEnum.hide) {
            return EMPTY_ARRAY;
        } else if (deadlineDisplay === deadlineDisplayEnum.showForSelectedBooking) {
            const bookingId = currentSelectedBooking.deadline
                ? currentSelectedBooking.booking?.id
                : currentSelectedBooking?.id;

            if (!bookingId) {
                // we return new ref to empty array so scheduler will properly react to display link config change
                return EMPTY_ARRAY_REF_2;
            }

            const deadlineLink = deadlineLinksMap[`${bookingId}-${bookingId}_deadline`];

            if (!deadlineLink) {
                return EMPTY_ARRAY_REF_2;
            }

            return [
                {
                    ...deadlineLink,
                    from: deadlineLink.parentId,
                    to: deadlineLink.childId,
                    type: deadlineLink.type || 'FinishToStart',
                    layer: deadlineLinkPosition,
                    cssClass: 'deadline-link',
                    deadlineLink: true,
                },
            ];
        }

        return Object.values(deadlineLinksMap)
            .filter(Boolean)
            .map(link => ({
                ...link,
                from: link.parentId,
                to: link.childId,
                id: `${link.parentId}-${link.childId}`,
                type: link.type || 'FinishToStart',
                layer: deadlineLinkPosition,
                cssClass: 'deadline-link',
                deadlineLink: true,
            }));
    }
);

export const selectHaveAppliedRowFiltersChanged = createSelector(
    selectSchedulerFilters,
    selectSchedulerInitialFilters,
    (current, initial) => {
        return getFiltersHasChanged(current, initial, ROW_FILTERS);
    }
);

export const selectHaveAppliedBookingFiltersChanged = createSelector(
    selectSchedulerFilters,
    selectSchedulerInitialFilters,
    (current, initial) => {
        return getFiltersHasChanged(current, initial, BOOKING_FILTERS);
    }
);

export const selectHaveAppliedSmartFiltersChanged = createSelector(
    selectSchedulerFilters,
    selectSchedulerInitialFilters,
    (current, initial) => {
        return getFiltersHasChanged(current, initial, SMART_FILTERS);
    }
);

export const selectHaveSchedulerAppliedRowOrBookingFiltersChanged = createSelector(
    selectHaveAppliedRowFiltersChanged,
    selectHaveAppliedBookingFiltersChanged,
    (rowFiltersChanged, bookingFiltersChanged) => {
        return rowFiltersChanged || bookingFiltersChanged;
    }
);

export const selectHaveSchedulerAppliedFiltersChanged = createSelector(
    selectHaveAppliedSmartFiltersChanged,
    selectHaveSchedulerAppliedRowOrBookingFiltersChanged,
    (smartFilters, rowOrBookingFilters) => ({
        smartFilters,
        rowOrBookingFilters,
    })
);

const reduceIdsList = (items = []) => {
    return items.reduce((acc, res) => {
        acc[res] = true;
        return acc;
    }, {});
};

const reduceSetValueCollection = (items = []) => {
    return items.reduce((acc, item) => {
        acc[item.value] = item;
        return acc;
    }, {});
};

const reduceNestedSetValueCollection = (items = []) => {
    return items.reduce((acc, item) => {
        acc[item.id] = {};
        item.values.reduce((nestedAcc, option) => {
            nestedAcc[option.value] = option;
            return nestedAcc;
        }, acc[item.id]);
        return acc;
    }, {});
};

export const selectAppliedResourcesFilter = createSelector(selectSchedulerResourcesFilter, resources => {
    return reduceIdsList(resources.filters);
});

export const selectAppliedResourceTagsFilter = createSelector(selectSchedulerResourceTagsFilter, resourceTags => {
    return reduceSetValueCollection(resourceTags.filters);
});

export const selectAppliedResourceStatusesFilter = createSelector(
    selectSchedulerResourceStatusesFilter,
    resourceStatuses => {
        return reduceSetValueCollection(resourceStatuses.filters);
    }
);

export const selectAppliedResourceRolesFilter = createSelector(selectSchedulerResourceRolesFilter, resourceStatuses => {
    return reduceSetValueCollection(resourceStatuses.filters);
});

export const selectAppliedResourceCustomFieldsFilter = createSelector(
    selectSchedulerResourceCustomFieldsFilter,
    resourceCustomFields => {
        return reduceNestedSetValueCollection(resourceCustomFields.filters);
    }
);

export const selectAppliedResourceIsPmFilter = createSelector(selectSchedulerResourceIsPmFilter, resourceIsPm => {
    const isPmFilter = resourceIsPm?.filters;
    if (isPmFilter === undefined || isPmFilter === null) {
        return {};
    } else if (isPmFilter) {
        return {
            'resource-is-pm': true,
        };
    }
    return {
        'resource-is-not-pm': true,
    };
});

export const selectAppliedBookingStatusesFilter = createSelector(
    selectSchedulerBookingStatusesFilter,
    bookingStatuses => {
        return reduceIdsList(bookingStatuses.filters);
    }
);

export const selectAppliedBookingCategoriesFilter = createSelector(
    selectSchedulerBookingCategoriesFilter,
    bookingCategories => {
        return reduceIdsList(bookingCategories.filters);
    }
);

export const selectAppliedBookingProjectsEventsFilter = createSelector(
    selectSchedulerBookingProjectsEventsFilter,
    bookingProjectsEvents => {
        return reduceIdsList(bookingProjectsEvents.filters);
    }
);

export const selectAppliedBookingResourcesUWFilter = createSelector(
    selectSchedulerBookingResourcesUWFilter,
    bookingResourcesUW => {
        return reduceIdsList(bookingResourcesUW.filters);
    }
);

export const selectAppliedProjectsFilter = createSelector(selectSchedulerProjectsFilter, projects => {
    return reduceIdsList(projects.filters);
});

export const selectAppliedProjectTagsFilter = createSelector(selectSchedulerProjectTagsFilter, projectTags => {
    return reduceSetValueCollection(projectTags.filters);
});

export const selectAppliedProjectCustomersFilter = createSelector(selectSchedulerCustomersFilter, customers => {
    return reduceSetValueCollection(customers.filters);
});

export const selectAppliedProjectManagersFilter = createSelector(
    selectSchedulerProjectManagersFilter,
    projectManagers => {
        return reduceSetValueCollection(projectManagers.filters);
    }
);

export const selectAppliedProjectStatusesFilter = createSelector(
    selectSchedulerProjectStatusesFilter,
    projectStatuses => {
        return reduceSetValueCollection(projectStatuses.filters);
    }
);

export const selectAppliedBookingProjectStatusesFilter = createSelector(
    selectSchedulerBookingProjectStatusesFilter,
    bookingProjectStatuses => {
        return reduceSetValueCollection(bookingProjectStatuses.filters);
    }
);

export const selectAppliedCurrenciesFilter = createSelector(selectSchedulerCurrenciesFilter, currencies => {
    return reduceSetValueCollection(currencies.filters);
});

export const selectAppliedProjectCustomFieldsFilter = createSelector(
    selectSchedulerProjectCustomFieldsFilter,
    projectCustomFields => {
        return reduceNestedSetValueCollection(projectCustomFields.filters);
    }
);

export const selectIsAnyResourceFilterApplied = createSelector(
    selectAppliedResourcesFilter,
    selectAppliedResourceCustomFieldsFilter,
    selectAppliedResourceTagsFilter,
    selectAppliedResourceStatusesFilter,
    selectAppliedResourceRolesFilter,
    selectAppliedResourceIsPmFilter,
    (...filters) => {
        return filters.some(filter => Object.keys(filter).length);
    }
)

export const selectIsAnyBookingFilterApplied = createSelector(
    selectAppliedBookingProjectStatusesFilter,
    selectAppliedBookingResourcesUWFilter,
    selectAppliedBookingProjectsEventsFilter,
    selectAppliedBookingCategoriesFilter,
    selectAppliedBookingStatusesFilter,
    (...filters) => {
        return filters.some(filter => Object.keys(filter).length);
    }
)
