import { axiosInstance } from './axiosInstance';

const PATH = '/costCategoryTemplate'

export const getCostCategoryTemplates = (params) => {
    return axiosInstance
        .get(`${PATH}/read`, { params: { showDeleted: params?.showDeleted } })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const createCostCategoryTemplate = (costCategory) => {
    return axiosInstance
        .post(`${PATH}/create`, costCategory)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const updateCostCategoryTemplates = (costCategoryId, costCategory) => {
    return axiosInstance
        .put(`${PATH}/update/${costCategoryId}`, costCategory)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const deleteCostCategoryTemplate = (costCategoryId) => {
    return axiosInstance
        .delete(`${PATH}/${costCategoryId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}
