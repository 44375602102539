import React, { useCallback } from 'react';
import { Button, Icon, Layout, Typography } from '@hub-mono/ui';
import Arrow1 from './arrow-1.svg';
import Arrow3 from './arrow-3.svg';
import styled from 'styled-components';
import { useHasRights } from '../../../hooks';

const rights = [
    {
        rights: ['settingViewExt'],
        rule: 'one',
        name: 'canViewSettingExt',
    },
];

const toilInfoPoints = [
    'Create multiple company policies for TOIL.',
    'Add/remove and switch resource policy.',
    'Requests can be made using vacation or TOIL hours.',
    'Timesheets will show the amount of accrued TOIL.',
    'Vacation dashboard will display TOIL. ',
];

const StyledImage = styled.img`
    object-fit: cover;
    object-position: top center;
    width: 100%;
`;

const Wrapper = styled(Layout)`
    margin: 0 auto;
    max-width: 937px;
`;

const InfoBox = styled(Layout)`
    border-radius: 0px 80px 80px 0px;
    background: rgba(19, 103, 122, 0.1);
    max-width: 380px;
`;

const PolicyBox = styled(Layout)`
    width: 234.221px;
    position: relative;
    left: 85px;
    top: -44px;
`;

const ToilBox = styled(Layout)`
    width: 233.334px;
    position: relative;
    top: -55px;
`;

const toPolicyArrowStyles = {
    position: 'relative',
    left: '-10px',
};

const toToilArrowStyles = {
    position: 'relative',
    top: '-39px',
    left: '30px',
};

export const MissingToilExtensionContent = () => {
    const { canViewSettingExt } = useHasRights(rights);

    const handleInstallBtnClick = useCallback(event => {
        event.preventDefault();
        window.location.href = '/settings#extensions';
    }, []);

    return (
        <Wrapper stack>
            <Layout gap="--Spacing-100" vAlign="center">
                <Layout flex="0 0 32px">
                    <Icon name="lego" size={32} />
                </Layout>
                <Typography variant="label" size="xl" color="on-surface-variant" prominent>
                    Streamline Time off in Lieu with the TOIL extension
                </Typography>
            </Layout>
            <Layout pt={46} gap={48}>
                <Layout flex="0 1 380px" stack>
                    <StyledImage src="https://hubplanner-static.s3.eu-west-1.amazonaws.com/img/toil.png" />
                </Layout>
                <Layout flex="1 1 auto" stack gap={32}>
                    <Layout stack gap="--Spacing-50">
                        <Typography variant="display" size="small" prominent color="on-surface-variant">
                            TOIL in Hub Planner
                        </Typography>
                        <Typography variant="label" size="medium" prominent color="on-surface-variant">
                            Seamless integration with Timesheets, Vacation and Dashboard.
                        </Typography>
                    </Layout>
                    <Layout stack gap="--Spacing-100">
                        {toilInfoPoints.map((item, index) => {
                            return (
                                <Layout key={index} gap="--Spacing-100" vAlign="center">
                                    <Icon name="arrowForward" />
                                    <Typography variant="label" size="large" prominent>
                                        {item}
                                    </Typography>
                                </Layout>
                            );
                        })}
                    </Layout>
                    {canViewSettingExt ? (
                        <Layout>
                            <Button onClick={handleInstallBtnClick} endIcon="arrowForward">
                                Install TOIL Extension
                            </Button>
                        </Layout>
                    ) : null}
                </Layout>
            </Layout>
            <Layout pt={65} gap={48}>
                <Layout flex="0 1 450px" stack gap={100}>
                    <Layout stack gap={16}>
                        <Typography variant="headline" size="small" color="on-surface-variant">
                            Manage the TOIL process with ease
                        </Typography>
                        <Typography variant="body" size="medium" color="on-surface-variant">
                            Track accrued TOIL directly within Timesheets and visualize it on the Vacation dashboard.
                            Create multiple company policies, manage resource settings, and easily request TOIL.
                        </Typography>
                    </Layout>
                    <InfoBox pt={24} pb={24} pr={26} pl={26}>
                        <Layout gap={16}>
                            <Layout>
                                <Icon size={24} name="info" />
                            </Layout>
                            <Layout stack gap={16}>
                                <Typography variant="label" size="large" prominent color="on-surface-variant">
                                    What is TOIL - Time Off In Lieu?
                                </Typography>
                                <Typography variant="body" size="small" color="on-surface-variant">
                                    “In lieu” translates to “instead of”. It refers to the paid time-off work employees
                                    receive “instead of” getting paid for additional hours worked above their normal
                                    contracted working week.
                                </Typography>
                            </Layout>
                        </Layout>
                    </InfoBox>
                </Layout>
                <Layout flex="1 1 auto" stack>
                    <Layout stack gap="--Spacing-100">
                        <Icon name="clockUser" size={36} />
                        <Typography variant="label" size="medium" prominent>
                            8 hours/day capacity
                        </Typography>
                    </Layout>
                    <Arrow3 style={toPolicyArrowStyles} />
                    <PolicyBox stack gap="--Spacing-100">
                        <Icon name="slidersHorizontal" size={36} />
                        <Layout stack gap="--Spacing-50">
                            <Typography variant="label" size="medium" prominent>
                                Company Policy
                            </Typography>
                            <Typography variant="body" size="small">
                                Covers rules around the company TOIL policy
                            </Typography>
                        </Layout>
                    </PolicyBox>
                    <Arrow1 style={toToilArrowStyles} />
                    <ToilBox stack gap="--Spacing-100">
                        <Icon name="paperPlaneTilt" size={36} />
                        <Layout stack gap="--Spacing-50">
                            <Typography variant="label" size="medium" prominent>
                                TOIL
                            </Typography>
                            <Typography variant="body" size="small">
                                Calculates how much a user is entitled to after it has run through the company policy.{' '}
                            </Typography>
                        </Layout>
                    </ToilBox>
                </Layout>
            </Layout>
        </Wrapper>
    );
};
