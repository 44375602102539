import { filter, includes } from 'lodash';

export const getFormDefaultApprovers = ({ resource, mappedResourceGroups, approvers }) => {
    const groups = resource?._id ? Object.values(mappedResourceGroups[resource._id] ?? {}) : [];
    const groupApproverIds = groups.reduce((acc, group) => {
        acc.push(...(group.approvers || []));
        return acc;
    }, []);

    const defaultApproverIds = resource?.currentCompanySpecific?.defaultApproverIds || resource?.defaultApproverIds || []
    const allDefaultApproverIds = Array.from(new Set([...groupApproverIds, ...defaultApproverIds]));

    let defaultApprovers = [];
    if (resource?._id && approvers.length) {
        defaultApprovers = filter(approvers, approver => includes(allDefaultApproverIds, approver._id));
    }

    return defaultApprovers;
};
