import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { makeGetResourceGroups } from 'selectors/resourceGroup';
import { makeGetProjectGroups } from 'selectors/projectGroup';
import { makeGetFilteredResources } from 'selectors/resource';
import { makeGetFilteredProjects } from 'selectors/project';
import { getProjectGroups } from 'actions/projectGroupActions';
import { getResourceGroups } from 'actions/resourceGroupActions';
import { getResources } from 'actions/resourceActions';
import { getProjects } from 'actions/projectActions';

export const useItemsAndGroups = (type, shouldLoad = true, additionalFilters = {}) => {
    const { projectGroupFilter, resourceGroupFilter, projectsFilter, resourcesFilter } = additionalFilters;
    const dispatch = useDispatch();
    const projectGroupSelector = useMemo(() => makeGetProjectGroups(projectGroupFilter), [projectGroupFilter]);
    const projectsSelector = useMemo(() => makeGetFilteredProjects(projectsFilter || 'ALL'), [projectsFilter]);
    const resourceGroupSelector = useMemo(() => makeGetResourceGroups(resourceGroupFilter), [resourceGroupFilter]);
    const resourcesSelector = useMemo(() => makeGetFilteredResources(resourcesFilter), [resourcesFilter]);
    const projects = useSelector(projectsSelector);
    const resources = useSelector(resourcesSelector);
    const resourcesLoading = useSelector(state => state.resourceReducer.loading);
    const projectsLoading = useSelector(state => state.projectReducer.loading);
    const projectGroups = useSelector(projectGroupSelector);
    const resourceGroups = useSelector(resourceGroupSelector);
    
    useEffect(() => {
        if (!shouldLoad || 'project' === type) {
            return;
        }
        dispatch(getResourceGroups.request());
        dispatch(getResources.request());
    }, [dispatch, type, shouldLoad]);

    useEffect(() => {
        if (!shouldLoad || 'resource' === type) {
            return;
        }
        dispatch(getProjectGroups.request());
        dispatch(getProjects.request());
    }, [dispatch, type, shouldLoad]);

    if ('project' === type) {
        return { items: projects, groups: projectGroups, projectsLoading };
    }

    return { items: resources, groups: resourceGroups, resourcesLoading };
};
