import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { getBookingType } from 'enums/bookingTypeEnum';
import {
    StatPods,
    RequestTable,
    PieChart,
    BarChart,
    EventCalendar,
    UpcomingEvents,
    Header,
    PieChartToil,
} from 'modules/request/components';
import { useRequestData } from 'modules/request/hooks';
import { makeResourceByIdFromMap } from '../../../../selectors/resource';
import { useSelector } from 'react-redux';
import { Layout } from '@hub-mono/ui';
import { UpcomingEventsInfoTilesWrapper } from '../../components/upcomingEventsWrapper';
import { selectCompanyAvgDailyCapacity, selectIsToilExtensionInstalled } from 'selectors/company';

const MyVacation = ({ location }) => {
    const {
        changeChartType,
        deleteRequest,
        requestModel,
        companySettings,
        filteredBookings,
        statistics,
        tableActive,
        editRequest,
        resourceId,
    } = useRequestData('MY_VACATION_REQUESTS', location);
    const title = `${'ALL' === requestModel.filter ? 'My' : getBookingType(requestModel.filter)} ${
        companySettings.vacationEventName
    } Days`;

    const statisticsJS = useMemo(() => {
        return statistics.toJS();
    }, [statistics]);

    const resourceSelector = useMemo(() => makeResourceByIdFromMap(requestModel?.resourceId), [
        requestModel?.resourceId,
    ]);

    const resource = useSelector(resourceSelector);

    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);

    return (
        <>
            <Header vacationEventName={companySettings.vacationEventName} />
            <StatPods
                filter={requestModel.filter}
                resourceId={requestModel.resourceId}
                toilPolicyId={resource?.toilPolicyId}
                flow={requestModel.flow}
                statistics={statisticsJS}
            />
            {!tableActive && (
                <Layout stack gap={16} pt={16} pb={16}>
                    <Layout gap={16}>
                        {isToilExtensionInstalled && resource?.toilPolicyId ? (
                            <PieChartToil
                                defaultVacationEventName={companySettings.vacationEventName}
                                totalApprovedDays={statistics.getIn(['total', 'APPROVED'])}
                                totalRemainingDays={statistics.getIn(['total', 'REMAINING'])}
                                totalToilApproved={statisticsJS.toil?.total?.APPROVED || 0}
                                totalToilRemaining={statisticsJS.toil?.total?.REMAINING || 0}
                                totalDays={
                                    statistics.getIn(['total', 'REMAINING']) +
                                    (statisticsJS.toil?.total?.REMAINING
                                        ? statisticsJS.toil?.total?.REMAINING / companyAvgDailyCapacity
                                        : 0)
                                }
                            />
                        ) : (
                            <PieChart
                                defaultVacationEventName={companySettings.vacationEventName}
                                totalApprovedDays={statistics.getIn(['total', 'APPROVED'])}
                                totalRemainingDays={statistics.getIn(['total', 'REMAINING'])}
                            />
                        )}
                        <BarChart
                            flex={isToilExtensionInstalled ? 1 : 2.05}
                            changeChartType={changeChartType}
                            weekStartDay={companySettings.weekStartDay}
                            title={title}
                            requestModel={requestModel}
                            statistics={statistics}
                            companyAvgDailyCapacity={companyAvgDailyCapacity}
                        />
                    </Layout>
                    <UpcomingEventsInfoTilesWrapper
                        resourceId={resourceId}
                        statistics={statistics}
                        companySettings={companySettings}
                    />
                    <div className="upcoming-row-wrapper">
                        <Row>
                            <Col sm={12} md={6}>
                                <EventCalendar
                                    upcomingEvents={statistics.get('upcomingEvents')}
                                    weekStartDay={companySettings.weekStartDay}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <UpcomingEvents
                                    resourceId={resourceId}
                                    vacationEventName={companySettings.vacationEventName}
                                    events={statistics.get('upcomingEvents')}
                                    requestStartDate={requestModel.start}
                                    requestEndDate={requestModel.end}
                                />
                            </Col>
                        </Row>
                    </div>
                </Layout>
            )}
            <Row>
                <Col md={12} className="pt-4">
                    <RequestTable
                        key={requestModel.resourceId ?? 'all'}
                        bookings={filteredBookings}
                        resourceId={resourceId}
                        requestModel={requestModel}
                        deleteRequest={deleteRequest}
                        editRequest={editRequest}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MyVacation;
