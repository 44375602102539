import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SettingsLink = styled(Link)`
    width: 150px;
    margin: 0 auto;
    font-size: 0.86rem;
`;

export const Description = styled.span`
    min-width: 200px;
`;
