import { filter, uniqBy, map, includes, without, omit, sortBy } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import { ACTIVE, ARCHIVED, UNASSIGNED } from 'enums/criteriaEnum';
import createReducer from './helpers/createReducer';
import { updateObject, updateItemInArray, getUpdatedCollection } from './helpers/updater';
import { TYPE_UNASSIGNED, STATUS_ARCHIVED } from 'enums/resourceEnum';

const initialState = {
    groupsInitialized: false,
    isResourceGroupsLoading: false,
    isResourcesLoading: false,
    resourceGroups: [],
    resources: [],
    lastCallContext: undefined,
};

const setResourceGroups = (state, action) =>
    updateObject(state, {
        groupsInitialized: true,
        isResourceGroupsLoading: false,
        resourceGroups: action.payload.resourceGroups,
        lastCallContext: action.payload.lastCallContext,
    });

const updateResourceGroupResources = (state, action) =>
    updateObject(state, {
        isResourcesLoading: false,
        resources: sortBy(uniqBy([...state.resources, ...action.payload.resources], '_id'), res =>
            res.name.toLowerCase()
        ),
    });

const updateResourceGroup = (state, action) =>
    updateObject(state, {
        resourceGroups: updateItemInArray(state.resourceGroups, action.payload.resourceGroup._id, resourceGroup =>
            updateObject(resourceGroup, action.payload.resourceGroup)
        ),
    });

const _addResourceToGroups = ({ resource, resourceGroupsAdd = [], resourceGroups }) => {
    const companySpecific = omit(resource.currentCompanySpecific, '_id');
    const newResource = omit({ ...resource, ...companySpecific }, 'currentCompanySpecific');
    const isUnassigned = newResource.type === TYPE_UNASSIGNED.value;
    const isArchived = newResource.status === STATUS_ARCHIVED.value;

    if (!newResource.name) {
        newResource.name = `${newResource.firstName} ${newResource.lastName}`;
    }

    const newResourceGroups = map(resourceGroups, group => {
        if (
            (resourceGroupsAdd.length && includes(resourceGroupsAdd, group._id)) ||
            (!resourceGroupsAdd.length &&
                ((isUnassigned && group.criteria === UNASSIGNED) ||
                    (!isUnassigned && !isArchived && ACTIVE === group.criteria) ||
                    (isArchived && group.criteria === ARCHIVED)))
        ) {
            return {
                ...group,
                resources: [...group.resources, newResource._id],
                count: group.count + 1,
            };
        }

        return group;
    });

    return { newResourceGroups };
};

const addResources = (state, action) => {
    const { newResourceGroups } = action.payload.created.reduce(
        (acc, resource) => {
            return _addResourceToGroups({
                resource: resource,
                resources: acc.newResources,
                resourceGroups: acc.newResourceGroups,
            });
        },
        {
            newResourceGroups: state.resourceGroups,
        }
    );

    const newResources = getUpdatedCollection({
        initial: state.resources,
        toAddUpdate: action.payload.created.concat(action.payload.updated),
        sortByProp: 'name',
    });

    return updateObject(state, {
        resourceGroups: newResourceGroups,
        resources: newResources,
    });
};

const addResource = (state, action) => {
    const { newResourceGroups } = _addResourceToGroups({
        resource: action.payload.resource,
        resourceGroupsAdd: action.payload.resourceGroupsAdd,
        resources: state.resources,
        resourceGroups: state.resourceGroups,
    });

    const mapWithMissingFields = (collection = []) => {
        return collection.map(resource => {
            return {
                ...resource,
                customFields: resource.currentCompanySpecific.customFields ?? [],
                role: resource.currentCompanySpecific.role,
                name: `${resource.currentCompanySpecific.firstName ?? ''} ${resource.currentCompanySpecific.lastName ??
                    ''}`.trim(),
            };
        });
    };

    const newResources = getUpdatedCollection({
        initial: state.resources,
        toAddUpdate: mapWithMissingFields([action.payload.resource]),
        sortByProp: 'name',
    });

    return updateObject(state, {
        resourceGroups: newResourceGroups,
        resources: newResources,
    });
};

const removeResource = (state, action) => {
    const currentGroups = map(state.resourceGroups, group => {
        const resources = without(group.resources, action.payload.id);

        return {
            ...group,
            resources,
            count: resources.length,
        };
    });

    return updateObject(state, {
        resourceGroups: currentGroups,
        resources: filter(state.resources, resource => resource._id !== action.payload.id),
    });
};

const updateResource = (state, action) => {
    const companySpecific = omit(action.payload.resource.currentCompanySpecific, '_id');
    const resource = omit({ ...action.payload.resource, ...companySpecific }, 'currentCompanySpecific');

    let resourceGroups;
    if (action.payload.additionalInfo) {
        const { resourceGroupsAdd = [], resourceGroupsRemove = [] } = action.payload.additionalInfo;
        const id = action.payload.resource._id;

        const addResource = group => {
            const newResources = [...group.resources, id];
            return {
                ...group,
                resources: newResources,
                count: newResources.length,
            };
        };

        const removeResource = group => {
            const newResources = group.resources.filter(_id => _id !== id);
            return {
                ...group,
                resources: newResources,
                count: newResources.length,
            };
        };

        resourceGroups = state.resourceGroups.map(resourceGroup => {
            if ((resourceGroupsAdd || []).includes(resourceGroup._id)) {
                return addResource(resourceGroup);
            }
            if ((resourceGroupsRemove || []).includes(resourceGroup._id)) {
                return removeResource(resourceGroup);
            }
            return resourceGroup;
        });
    } else {
        resourceGroups = state.resourceGroups;
    }

    return updateObject(state, {
        resources: updateItemInArray(state.resources, action.payload.resource._id, currentResource =>
            updateObject(currentResource, resource)
        ),
        resourceGroups,
    });
};

const createResourceGroup = (state, action) => {
    const currentGroups = [...state.resourceGroups];
    currentGroups.push(action.payload.resourceGroup);

    return updateObject(state, {
        resourceGroups: currentGroups,
    });
};

const deleteResourceGroup = (state, action) => {
    let currentGroups = [...state.resourceGroups];
    if (currentGroups.length) {
        currentGroups = filter(currentGroups, group => group._id !== action.payload.id);
    }

    return updateObject(state, {
        resourceGroups: currentGroups,
    });
};

const setResourcesLoader = state =>
    updateObject(state, {
        isResourcesLoading: true,
    });

const setResourceGroupsLoader = state =>
    updateObject(state, {
        isResourceGroupsLoading: true,
    });

const handleGetResourcesGroupsStop = state =>
    updateObject(state, {
        isResourceGroupsLoading: false,
    });

const handleAddGroupApprovers = (state, action) => {
    const { resourceId, groupIds } = action.payload;

    const groupIdsToUpdateAsMap = groupIds.reduce((acc, id) => {
        acc[id] = id;
        return acc;
    }, {});

    const updatedResourceGroups = state.resourceGroups.map(resourceGroup => {
        if (!groupIdsToUpdateAsMap[resourceGroup._id]) {
            return resourceGroup;
        }

        const approversSet = new Set(resourceGroup.approvers);
        approversSet.add(resourceId);

        return {
            ...resourceGroup,
            approvers: Array.from(approversSet),
        };
    });

    return updateObject(state, {
        resourceGroups: updatedResourceGroups,
    });
};

const handleRemoveGroupApprovers = (state, action) => {
    const { resourceId, groupIds } = action.payload;

    const groupIdsToUpdateAsMap = groupIds.reduce((acc, id) => {
        acc[id] = id;
        return acc;
    }, {});

    const updatedResourceGroups = state.resourceGroups.map(resourceGroup => {
        if (!groupIdsToUpdateAsMap[resourceGroup._id]) {
            return resourceGroup;
        }

        const approversSet = new Set(resourceGroup.approvers);
        approversSet.delete(resourceId);

        return {
            ...resourceGroup,
            approvers: Array.from(approversSet),
        };
    });

    return updateObject(state, {
        resourceGroups: updatedResourceGroups,
    });
};

export default createReducer(initialState, {
    [actionTypes.GET_RESOURCE_GROUP_RESOURCES['REQUEST']]: setResourcesLoader,
    [actionTypes.GET_RESOURCE_GROUP_RESOURCES['SUCCESS']]: updateResourceGroupResources,
    [actionTypes.GET_RESOURCE_GROUPS['REQUEST']]: setResourceGroupsLoader,
    [actionTypes.GET_RESOURCE_GROUPS['SUCCESS']]: setResourceGroups,
    [actionTypes.GET_RESOURCE_GROUPS_STOP]: handleGetResourcesGroupsStop,
    [actionTypes.CREATE_RESOURCE_GROUP['SUCCESS']]: createResourceGroup,
    [actionTypes.DELETE_RESOURCE_GROUP['REQUEST']]: deleteResourceGroup,
    [actionTypes.UPDATE_RESOURCE_GROUP['SUCCESS']]: updateResourceGroup,
    [actionTypes.CREATE_RESOURCE['SUCCESS']]: addResource,
    [actionTypes.CREATE_MULTIPLE_RESOURCES['SUCCESS']]: addResources,
    [actionTypes.DUPLICATE_RESOURCE['SUCCESS']]: addResource,
    [actionTypes.DELETE_RESOURCE['REQUEST']]: removeResource,
    [actionTypes.UPDATE_RESOURCE['SUCCESS']]: updateResource,
    [actionTypes.ADD_GROUP_APPROVERS['REQUEST']]: handleAddGroupApprovers,
    [actionTypes.ADD_GROUP_APPROVERS['FAILURE']]: handleRemoveGroupApprovers,
    [actionTypes.REMOVE_GROUP_APPROVERS['REQUEST']]: handleRemoveGroupApprovers,
    [actionTypes.REMOVE_GROUP_APPROVERS['FAILURE']]: handleAddGroupApprovers,
});
