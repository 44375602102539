import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'fixedCostsScheduled',
        display: 'Fixed Costs Scheduled',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayTimeType: 'Fixed Amount',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'fixedCostsActual',
        display: 'Fixed Costs Reported',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayTimeType: 'Fixed Amount',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'budgetCategoryName',
        display: 'Budget Category Name',
        multiItemsDisplay: 'Categories',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'budgetCash',
        display: 'Budget Cash',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayTimeType: 'Fixed Amount',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'budgetHours',
        display: 'Budget Hours',
        multiItemsDisplay: 'Budgets',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayTimeType: 'Fixed Amount',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'estimatedBudgetHoursRemaining',
        display: 'Estimated Budget Hours Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayTimeType: 'Scheduled & Reported Time',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'externalEstimatedBudgetCashRemaining',
        display: 'Estimated Budget Cash Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayRateType: 'external rate',
        displayTimeType: 'Scheduled Time',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'internalEstimatedBudgetCashRemaining',
        display: 'Estimated Budget Cash Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayColumnType: 'budget',
        displayRateType: 'internal rate',
        displayTimeType: 'Scheduled Time',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
];
