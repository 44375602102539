import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from 'actions/actionTypes';
import { addNotification } from 'actions/notificationActions';
import * as integrationApi from 'api/integration';
import {
    getAuthInformation,
    getAccounts,
    removeAuthorization,
    getResources,
    getProjects,
} from 'actions/integrationActions';
import StorageUtil from 'utils/StorageUtil';
import { monitoring } from '../monitoring';

const getAccountsSelector = provider => state => state.integrationReducer[provider].accounts;

function* handleSendSuggestionIntegration(action) {
    try {
        yield call(integrationApi.sendSuggestionEmailRequest, action.payload.data);

        yield put(
            addNotification({
                message: 'Suggestion has been sent',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... Suggestion has not been sent',
                type: 'danger',
            })
        );
    }
}

function* handleGetProjects(action) {
    const { provider } = action.payload;

    try {
        const accounts = yield select(getAccountsSelector(provider));
        const response = yield call(
            integrationApi.getProjectsRequest,
            provider,
            StorageUtil.getItem('externalUserId', 'localStorage'),
            accounts
        );
        const filteredNewProjects = yield call(integrationApi.filterNewProjectsRequest, response, provider);
        const filteredProjectsToUpdate = yield call(integrationApi.filterProjectsToUpdateRequest, response, provider);

        yield call(integrationApi.updateProjectsRequest, filteredProjectsToUpdate, provider);

        yield put(getProjects.success(provider, filteredNewProjects));
    } catch (error) {
        monitoring.captureException(error);
        getProjects.failure(provider);

        yield put(
            addNotification({
                message: 'Something went wrong...',
                type: 'danger',
            })
        );
    }
}

function* handleGetResources(action) {
    const { provider } = action.payload;

    try {
        const accounts = yield select(getAccountsSelector(provider));
        const response = yield call(
            integrationApi.getResourcesRequest,
            provider,
            StorageUtil.getItem('externalUserId', 'localStorage'),
            accounts
        );
        const filteredNewResources = yield call(integrationApi.filterNewResourcesRequest, response, provider);
        const filteredResourcesToUpdate = yield call(integrationApi.filterResourcesToUpdateRequest, response, provider);

        yield call(integrationApi.updateResourcesRequest, filteredResourcesToUpdate, provider);

        yield put(getResources.success(provider, filteredNewResources));
    } catch (error) {
        monitoring.captureException(error);
        getResources.failure(provider);

        yield put(
            addNotification({
                message:
                    'Sorry, we could not sync your data as it looks like Basecamp is blocking the account as it might be expired, please check with Basecamp',
                type: 'danger',
            })
        );
    }
}

function* handleGetAuthInformation(action) {
    const { provider, identity } = action.payload;

    try {
        const data = yield call(integrationApi.getAuthInformationRequest, provider, identity);

        yield put(getAuthInformation.success({ provider, ...data }));
        yield put(getAccounts.success({ provider, accounts: data.accounts }));
    } catch (error) {
        monitoring.captureException(error);
        yield put(getAccounts.failure(provider));
        yield put(
            addNotification({
                message: 'Something went wrong...',
                type: 'danger',
            })
        );
    }
}

function* handleRemoveAuthorization(action) {
    try {
        const { provider } = action.payload;

        yield call(integrationApi.removeAuthorizationRequest, provider);

        yield put(removeAuthorization.success(provider));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... Could not remove the previous authorization.',
                type: 'danger',
            })
        );
    }
}

export default function* integrationWatcher() {
    yield takeLatest(actionTypes.SEND_SUGGESTION_INTEGRATION, handleSendSuggestionIntegration);
    yield takeLatest(actionTypes.GET_PROJECTS_INTEGRATION['REQUEST'], handleGetProjects);
    yield takeLatest(actionTypes.GET_RESOURCES_INTEGRATION['REQUEST'], handleGetResources);
    yield takeLatest(actionTypes.GET_AUTH_INFORMATION_INTEGRATION['REQUEST'], handleGetAuthInformation);
    yield takeLatest(actionTypes.REMOVE_AUTHORIZATION_INTEGRATION['REQUEST'], handleRemoveAuthorization);
}
