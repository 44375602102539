import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Menu, Button } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { useHasRights } from 'hooks';
import { NEW } from 'enums/contextMenuEnum';
import { useAccountPreferences, useScheduleRights } from 'modules/scheduler/hooks';
import { getContextMenuPosition } from 'utils/browserUtil';
import ScheduleDropdown, { CONTEXT_MENU_WIDTH } from 'modules/scheduler/components/dropdowns/schedule';
import { useStyles } from '../styles';
import { FORM_NAME } from '../../../dropdowns/schedule/partials/resourceSingleMode';
import { store } from '../../../../../../store';
import { useDisabledTooltipLogic } from '../useDisabledTooltipLogic';
import { WrapWithTooltip } from '../wrapWithTooltip';

const rights = [
    {
        rights: ['manageEvents'],
        rule: 'one',
        name: 'hasManageEventsRights',
    },
];

const ScheduleButton = React.forwardRef((props, schedulerRef) => {
    const { windowHeight, windowWidth, disabled, disabledTooltip, viewObject, viewData } = props;
    const [contextPosition, setContextPosition] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const { gridPreferences } = useAccountPreferences();
    const { hasManageEventsRights } = useHasRights(rights);
    const { hasScheduleRights } = useScheduleRights();
    const selectionExists = useSelector(state => state.scheduler.currentSelection.start);

    const isNewContextMenu = gridPreferences.contextMenu === NEW;
    const hasRights = !hasManageEventsRights ? hasScheduleRights.schedule : hasManageEventsRights;

    const { isDisabled, tooltip } = useDisabledTooltipLogic({
        disabled,
        disabledTooltip,
        selectionExists,
        hasRights,
        isNewContextMenu,
        isSchedule: true,
    });

    const classes = useStyles();

    const handleClick = event => {
        const formExists = !!store.getState().form?.[FORM_NAME];

        // First, it will be closed.
        if (formExists) {
            setTimeout(() => handleClick(event), 50);
        } else {
            setContextPosition(
                getContextMenuPosition(
                    { windowHeight, windowWidth },
                    { top: event.clientY, left: event.clientX },
                    CONTEXT_MENU_WIDTH
                )
            );
            setAnchorEl(event.currentTarget || event.target);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <WrapWithTooltip tooltip={tooltip}>
                <Button
                    data-cy="scheduler-nav-buttons-schedule"
                    aria-controls="schedule-menu"
                    aria-haspopup="true"
                    className={classNames({ disabled: isDisabled })}
                    disabled={isDisabled}
                    onClick={handleClick}
                    classes={classes}
                    endIcon={<ArrowDropDown />}
                    disableRipple
                >
                    Schedule
                </Button>
            </WrapWithTooltip>
            <Menu
                id="schedule-menu"
                classes={classes}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {Boolean(anchorEl) && (
                    <ScheduleDropdown
                        schedulerRef={schedulerRef}
                        onClose={handleClose}
                        height={contextPosition.height}
                        combineScheduleRequest={false}
                        isToolbar={true}
                        viewObject={viewObject}
                        viewData={viewData}
                    />
                )}
            </Menu>
        </>
    );
});

ScheduleButton.propTypes = {
    windowHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    disabledTooltip: PropTypes.string,
};

export default ScheduleButton;
