import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@material-ui/core';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { useClickOutside } from 'hooks';
import { createCySelector } from '../../utils/cypressUtil';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { useNestedMenuItemManager } from './context';
import { debounce } from 'lodash';

const RIGHT_POSITION = {
    type: 'RIGHT',
    arrow: { right: '10px' },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

const LEFT_POSITION = {
    type: 'LEFT',
    arrow: { left: '-2px' },
    anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
    },
};

const ChildrenContainer = styled.div`
    max-height: 75vh;
    outline: 0;
`;

const useStyles = makeStyles(() => {
    return {
        paper: {
            borderRadius: '4px !important',
            minWidth: '150px',
            padding: '5px 0',
            border: '1px solid transparent',
            boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
            pointerEvents: 'all',

            '& button:last-of-type': {
                borderBottom: 'none',
            },
        },
    };
});

const NestedMenuItem = React.forwardRef((props, ref) => {
    const {
        parentMenuOpen,
        highlightColor,
        clickable,
        rightIcon,
        leftIcon,
        children,
        label,
        width,
        dataCy,
        className,
        disabled,
    } = props;

    const { currentlyOpened, setCurrentlyOpened } = useNestedMenuItemManager();

    const wrapperRef = useRef();
    const subMenuItem = useRef();

    const subMenuOpen = currentlyOpened === subMenuItem.current && subMenuItem.current;

    useEffect(() => {
        if (subMenuItem.current) {
            if (subMenuOpen) {
                subMenuItem.current.style.backgroundColor = highlightColor;
            } else {
                subMenuItem.current.style.backgroundColor = 'rgba(0,0,0,0.0)';
            }
        }
    }, [highlightColor, subMenuOpen]);

    const styles = useStyles();

    const onMouseOver = useCallback(() => {
        if (disabled) {
            return
        }
        if (!subMenuOpen && subMenuItem.current) {
            setCurrentlyOpened(subMenuItem.current);
        }
    }, [disabled, setCurrentlyOpened, subMenuOpen]);

    const onMouseEnter = useCallback(
        event => {
            if (disabled) {
                return
            }
            if (!subMenuItem.current) {
                return;
            }
            event.stopPropagation();
            setCurrentlyOpened(subMenuItem.current);
        },
        [disabled, setCurrentlyOpened]
    );

    const handleClick = useCallback(
        event => {
            event.stopPropagation();
            if (disabled) {
                return
            }
            if (clickable) {
                if (subMenuOpen) {
                    setCurrentlyOpened(undefined);
                } else {
                    if (!subMenuItem.current) {
                        return;
                    }

                    setCurrentlyOpened(subMenuItem.current);
                }
            }
        },
        [clickable, disabled, setCurrentlyOpened, subMenuOpen]
    );

    const handleClickOutside = useCallback(
        event => {
            if (clickable && subMenuOpen) {
                const refToUser = ref || wrapperRef;
                if (refToUser.current && !refToUser.current.contains(event.target)) {
                    setCurrentlyOpened(undefined);
                }
            }
        },
        [clickable, ref, setCurrentlyOpened, subMenuOpen]
    );

    const handleClose = useCallback(() => {
        setCurrentlyOpened(undefined);
    }, [setCurrentlyOpened]);

    const { innerWidth } = window;

    const current = ref?.current || wrapperRef?.current;
    const { right, width: realWidth } = current ? current?.getBoundingClientRect() : {};

    // Check if popup has space to show up on the right side
    const position = innerWidth - right > realWidth ? RIGHT_POSITION : LEFT_POSITION;

    useClickOutside(handleClickOutside);

    const { onTargetRef } = useInViewPortPosition();

    return (
        <div
            data-cy={`${dataCy ?? createCySelector(label)}`}
            onMouseEnter={!clickable ? onMouseEnter : null}
            onMouseOver={!clickable ? onMouseOver : null}
            ref={ref || wrapperRef}
        >
            <MenuItem ref={subMenuItem} onClick={handleClick} className={className} disabled={disabled}>
                {label}
                <span className="position-absolute" style={position.arrow}>
                    {position.type === RIGHT_POSITION.type ? rightIcon : leftIcon}
                </span>
            </MenuItem>
            <Menu
                disablePortal={true}
                style={{ pointerEvents: 'none', width: width }}
                anchorEl={subMenuItem.current}
                MenuListProps={{
                    style: {
                        maxHeight: '425px',
                    }
                }}
                PaperProps={{
                    className: styles.paper,
                    style: {
                        width,
                        maxHeight: '425px',
                        overflowY: 'hidden',
                        paddingTop: '0.5rem',
                        paddingBottom: '0.5rem',
                        transition: 'transform 53ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    },
                }}
                transitionDuration={80}
                classes={{ paper: 'radius-0' }}
                anchorOrigin={position.anchorOrigin}
                transformOrigin={position.transformOrigin}
                open={subMenuOpen && parentMenuOpen}
                onClose={handleClose}
            >
                <ChildrenContainer ref={onTargetRef}>{children}</ChildrenContainer>
            </Menu>
        </div>
    );
});

NestedMenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    parentMenuOpen: PropTypes.bool.isRequired,
    rightIcon: PropTypes.object,
    leftIcon: PropTypes.object,
    clickable: PropTypes.bool,
    highlightColor: PropTypes.string,
    className: PropTypes.string,
};

NestedMenuItem.defaultProps = {
    clickable: false,
    rightIcon: <KeyboardArrowRight />,
    leftIcon: <KeyboardArrowLeft />,
    highlightColor: 'rgba(0, 0, 0, 0.04)',
    className: 'rounded ml-2 mr-2 pl-4 pt-2 pb-2',
};

export const NESTED_MENU_ITEM_SCROLLBAR_HEIGHT = 400;

export default React.memo(NestedMenuItem);

const useInViewPortPosition = () => {
    const [,render] = useState();
    const targetRef = useRef(null);
    const observerRef = useRef(
        // eslint-disable-next-line no-undef
        new ResizeObserver(
            debounce(() => {

                if(!targetRef.current) {
                    return;
                }

                const rect = targetRef.current.getBoundingClientRect();

                const inViewPort = (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );

                if(!inViewPort) {
                    render({})
                }
            }, 50)
        )
    );

    const onTargetRef = useCallback((ref) => {
        if (ref) {
            targetRef.current = ref;
            observerRef.current.observe(ref);
        }
    }, []);

    useEffect(() => {
        const observer = observerRef.current;

        return () => {
            if(observer) {
                if (targetRef.current) {
                    observer.unobserve(targetRef.current);
                }
                observer.disconnect();
            }
        }
    }, []);

    return {onTargetRef};
};
