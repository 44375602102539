import moment from 'moment';
import moize from 'moize';
import { extend, find, values } from 'underscore';
import StorageUtil from 'utils/StorageUtil';
import { dateTypes } from 'modules/report/enums/dateTypeEnum';
import { findGroupingByName } from 'modules/report/enums/groupingTypeEnum';
import { getGroupingFilter } from 'modules/report/utils/transformer';

const formatDate = date => moment(date, 'YYYY-MM-DD').toDate();

export const REPORT_LEVEL_1 = matchParams => ({
    storageItemName: 'reportQuery',
    storageNextName: 'reportLevel2Params',
    match: matchParams && !matchParams.id,
    contextQueryParams: null,
    reportQueryParams: StorageUtil.getItem('reportQuery'),
});

const REPORT_LEVEL_2 = (matchParams, dates) => {
    const previousReportLevel = REPORT_LEVEL_1();
    const grouping = findGroupingByName(previousReportLevel.reportQueryParams.groupBy);
    const dateType = dateTypes[grouping.value];

    return {
        storageItemName: 'reportLevel2Params',
        storageNextName: 'reportLevel3Params',
        storagePreviousName: 'reportQuery',
        match: matchParams.id && !matchParams.subId,
        reportQueryParams: StorageUtil.getItem('reportLevel2Params'),
        contextQueryParams: extend(
            {},
            REPORT_LEVEL_1(matchParams).reportQueryParams,
            dateType && moment(matchParams.id, dateType.format).isValid() ? {} : { itemIds: [matchParams.id] },
            dates
        ),
    };
};

const REPORT_LEVEL_3 = (matchParams, dates, customFields) => {
    const previousReportLevel = REPORT_LEVEL_2(matchParams, dates);
    const match = matchParams.subId && !matchParams.date;

    return {
        storageItemName: 'reportLevel3Params',
        storageNextName: 'reportLevel4Params',
        storagePreviousName: 'reportLevel2Params',
        match,
        reportQueryParams: StorageUtil.getItem('reportLevel3Params'),
        contextQueryParams:
            previousReportLevel.reportQueryParams &&
            match &&
            extend(
                {},
                previousReportLevel.reportQueryParams,
                { page: 0 },
                {
                    filters: extend(
                        {},
                        previousReportLevel.reportQueryParams.filters,
                        getGroupingFilter(
                            previousReportLevel.reportQueryParams.itemType,
                            matchParams.id,
                            customFields || []
                        )
                    ),
                },
                dates
            ),
    };
};

const REPORT_LEVEL_4 = (matchParams, dates) => {
    const previousReportLevel = REPORT_LEVEL_3(matchParams, dates);

    return {
        storageItemName: 'reportLevel4Params',
        storagePreviousName: 'reportLevel3Params',
        match: matchParams.date,
        reportQueryParams: StorageUtil.getItem('reportLevel4Params'),
        contextQueryParams:
            previousReportLevel.reportQueryParams &&
            extend({}, previousReportLevel.reportQueryParams, {
                id: matchParams.date,
                startDate: formatDate(dates ? dates.startDate : matchParams.date),
                endDate: formatDate(dates ? dates.endDate : matchParams.date),
                page: 0,
            }),
    };
};

const reportLevels = (matchParams, dates, customFields) => ({
    REPORT_LEVEL_1: REPORT_LEVEL_1(matchParams),
    REPORT_LEVEL_2: REPORT_LEVEL_2(matchParams, dates),
    REPORT_LEVEL_3: REPORT_LEVEL_3(matchParams, dates, customFields),
    REPORT_LEVEL_4: REPORT_LEVEL_4(matchParams, dates),
});

export const storageItemNames = ['reportQuery', 'reportLevel2Params', 'reportLevel3Params', 'reportLevel4Params'];

export const getReportLevel = moize(
    (matchParams, dates, customFields) =>
        find(values(reportLevels(matchParams, dates, customFields)), reportLevel => reportLevel.match) ||
        REPORT_LEVEL_1(matchParams),
    { maxSize: 2 }
);
