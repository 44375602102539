import { find, map, isEmpty, filter, pluck } from 'underscore';
import { YEAR as YEAR_TYPE } from 'modules/report/enums/dateTypeEnum';
import { columns as hmrColumns } from 'modules/report/columns/hmrColumns/columns';
import { columns as scheduled } from './time/scheduledColumns';
import { columns as reported } from './time/reportedColumns';
import { columns as specificScheduled } from './time/specificScheduledColumns';
import { columns as specificReported } from './time/specificReportedColumns';
import { columns as burn } from './time/burnColumns';
import { columns as mixedTimeColumns } from './time/mixedColumns';
import { columns as scheduledSpend } from './spend/scheduledSpendColumns';
import { columns as reportedSpend } from './spend/reportedSpendColumns';
import { columns as mixedSpend } from './spend/mixedSpendColumns';
import { columns as specificScheduledSpend } from './spend/specificScheduledColumns';
import { columns as specificReportedSpend } from './spend/specificReportedColumns';
import { columns as capacity } from './capacity/capacityColumns';
import { columns as capacityMixed } from './capacity/mixedColumns';
import { columns as capacityReported } from './capacity/reportedColumns';
import { columns as capacityScheduled } from './capacity/scheduledColumns';
import { columns as customFieldColumns } from './customFields/columns';
import { columns as resourceText } from './resourceText/columns';
import { columns as text } from './text/columns';
import { columns as budgetSpecific } from 'modules/report/columns/projectBudgetSpecific/budgetColumns';
import { columns as reportedBudgetSpecific } from 'modules/report/columns/projectBudgetSpecific/reportedBudgetColumns';
import { columns as scheduledBudgetSpecific } from 'modules/report/columns/projectBudgetSpecific/scheduledBudgetColumns';
import { columns as projectText } from 'modules/report/columns/projectText/columns';
import { columns as common } from 'modules/report/columns/common/columns';
import { columns as billingCommon } from 'modules/report/columns/common/billingColumns';
import { columns as date } from 'modules/report/columns/date/columns';
import { columns as toil } from 'modules/report/columns/time/resourceToilColumns';

/**
 * @param {string}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 * @param {array}          customFields
 * @param {string}         itemType
 * @param {string}         groupBy
 * @param {string}         defaultCurrency
 * @param {object}         columnsToLoadSizes
 * @param {array}          periods
 * @param {string}         split
 *
 * @returns {*[]}
 */
export default (
    dateState,
    unit,
    avgDailyCapacity,
    columnsToLoad,
    customFields,
    itemType,
    groupBy,
    defaultCurrency,
    columnsToLoadSizes,
    { dateColumns: periods, split }
) => {
    const columnsToLoadNames = pluck(columnsToLoad, 'name');
    let columns = [
        ...scheduled(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...reported(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...specificScheduled(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...specificReported(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...burn(dateState, avgDailyCapacity, columnsToLoadNames),
        ...mixedTimeColumns(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...scheduledSpend(dateState, columnsToLoadNames, defaultCurrency),
        ...reportedSpend(dateState, columnsToLoadNames, defaultCurrency),
        ...mixedSpend(dateState, columnsToLoadNames, defaultCurrency),
        ...specificScheduledSpend(dateState, columnsToLoadNames, defaultCurrency),
        ...specificReportedSpend(dateState, columnsToLoadNames, defaultCurrency),
        ...capacity(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...capacityMixed(dateState, columnsToLoadNames),
        ...capacityReported(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...capacityScheduled(dateState, unit, avgDailyCapacity, columnsToLoadNames),
        ...resourceText(columnsToLoadNames),
        ...projectText(columnsToLoadNames),
        ...text(groupBy, !isEmpty(split) && YEAR_TYPE.name !== split, columnsToLoadNames),
        ...budgetSpecific(columnsToLoadNames, defaultCurrency),
        ...reportedBudgetSpecific(columnsToLoadNames, defaultCurrency),
        ...scheduledBudgetSpecific(columnsToLoadNames, defaultCurrency),
        ...common(columnsToLoadNames),
        ...billingCommon(columnsToLoadNames, defaultCurrency, unit, avgDailyCapacity),
        ...date(columnsToLoadNames),
        ...toil(dateState, unit, avgDailyCapacity, columnsToLoadNames)
    ];

    columns = columns.concat(customFieldColumns(customFields, columnsToLoadNames));

    if (columnsToLoadSizes) {
        columns = map(columns, column => ({
            ...column,
            width: columnsToLoadSizes[column.id] || undefined,
        }));
    }

    if (!isEmpty(split)) {
        let columnName = find(columnsToLoad, column => 'name' !== column.name && column.split);
        let specificColumn = find(columns, column => column.id === (columnName && columnName.name));
        columns = columns.concat(
            hmrColumns(unit, avgDailyCapacity, periods, split, specificColumn, columnName && columnName.name)
        );
        columns = filter(
            columns,
            column => !find(columnsToLoad, columnToLoad => columnToLoad.name === column.id && columnToLoad.split)
        );
    }

    return columns;
};
