import * as actionTypes from 'actions/actionTypes';

export default function initialAppReducer(state = { initialLoad: true, isFullScreen: false, geoLocation: {} }, action) {
    switch (action.type) {
        case actionTypes.INITIAL['REQUEST']:
            return { ...state, initialLoad: true };
        case actionTypes.INITIAL['FAILURE']:
        case actionTypes.INITIAL['SUCCESS']:
            return { ...state, initialLoad: false };
        case actionTypes.FULLSCREEN['REQUEST']:
            return { ...state, isFullScreen: action.payload.enabled };
        case actionTypes.GEO_LOCATION['SUCCESS']:
            return { ...state, geoLocation: action.payload.geoLocation };
        default:
            return state;
    }
}
