import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useClickOutside } from 'hooks';

const setRef = (ref, value) => {
    if ('function' === typeof ref) {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
};

const useForkRef = (refA, refB) =>
    React.useMemo(() => {
        if (null === refA && null === refB) {
            return null;
        }
        return refValue => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);

const ClickAwayListener = ({ handler, children }) => {
    const node = useRef();
    const mountedRef = useRef(false);
    const handleRef = useForkRef(children.ref, node);
    const childrenProps = { ref: handleRef };

    const clickHandler = useCallback(
        event => {
            if (!mountedRef.current || !node.current) return;
            const doc = node.current.ownerDocument;
            let insideDOM;
            if (event.composedPath) {
                insideDOM = -1 < event.composedPath().indexOf(node.current);
            } else {
                insideDOM = !doc.documentElement.contains(event.target) || node.current.contains(event.target);
            }

            if (!insideDOM) {
                handler(event);
            }
        },
        [handler]
    );

    useClickOutside(clickHandler, node.current?.ownerDocument || document);

    useEffect(() => {
        const refTimeout = setTimeout(() => {
            mountedRef.current = true;
        }, 0);

        return () => {
            clearTimeout(refTimeout);
            mountedRef.current = false;
        };
    }, []);

    return <React.Fragment>{React.cloneElement(children, childrenProps)}</React.Fragment>;
};

ClickAwayListener.propTypes = {
    handler: PropTypes.func.isRequired,
    children: PropTypes.object.isRequired,
};

export default ClickAwayListener;
