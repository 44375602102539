import { axiosInstance } from './axiosInstance';

export const unsubscribeEstensionRequest = body =>
    axiosInstance
        .post(`extensionRoute/unsubscribe`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const activeExtensionRequest = body =>
    axiosInstance
        .post('extensionRoute/activateTrial', body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateExtensionSubscriptionRequest = body =>
    axiosInstance
        .post('extensionRoute/updateSubscription', body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
