import { createSelector } from 'reselect';
import { filter, find } from 'lodash';

const getBillingExtensions = state => state.billing.billingExtensions;

export const makeGetBillingExtensions = (filterType, shortId) =>
    createSelector(getBillingExtensions, billingExtensions => {
        switch (filterType) {
            case 'BILLABLE_INSTALLED':
                return filter(
                    billingExtensions,
                    extension => extension.extensionStatus && extension.extensionStatus.installed && extension.billable
                );
            case 'BILLABLE':
                return filter(billingExtensions, extension => extension.billable);
            case 'SUBSCRIBED':
                return filter(
                    billingExtensions,
                    extension => extension.extensionStatus && extension.extensionStatus.subscribed
                );
            case 'SPECIFIC':
                return find(billingExtensions, extension => extension.shortId === shortId);
            default:
                return billingExtensions;
        }
    });
