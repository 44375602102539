import React, { useEffect, useRef } from 'react';
import { Menu, ClickAwayListener } from '@material-ui/core';
import { head } from 'lodash';
import { usePressEscape } from 'hooks';
import EditBookingDropDown from 'modules/scheduler/components/dropdowns/booking';
import { useStyles } from './../styles';

const EditBooking = props => {
    const { top, left, schedulerRef, close } = props;
    const node = useRef();
    const mountedRef = useRef(false);
    usePressEscape(close);
    const classes = useStyles();

    useEffect(() => {
        const refTimeout = setTimeout(() => {
            mountedRef.current = true;
        }, 0);

        return () => {
            clearTimeout(refTimeout);
            mountedRef.current = false;
        };
    }, []);

    const handler = event => {
        const datepicker = document.getElementsByClassName('MuiPickersBasePicker-container');
        if (
            !mountedRef.current ||
            node.current.contains(event.target) ||
            (datepicker.length && head(datepicker).contains(event.target))
        ) {
            return;
        }
        close();
    };

    return (
        <Menu
            data-cy="edit-booking-context-menu"
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            keepMounted
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <ClickAwayListener onClickAway={handler}>
                <EditBookingDropDown schedulerRef={schedulerRef} left={left} onClose={close} />
            </ClickAwayListener>
        </Menu>
    );
};

export default EditBooking;
