import { keys } from 'lodash';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject } from 'reducers/helpers/updater';
import * as actionTypes from 'actions/actionTypes';
import { HOURS_DECIMAL } from 'modules/report/enums/reportUnitTypeEnum';
import { objectDiff } from 'utils/dataManipulation';
import { subGroupsPositionEnum } from '../modules/scheduler/enums/subGroupsPositionEnum';

const initialAccount = {
    isPartiallyAuthenticated: false,
    isEmailVerified: false,
    companyId: '',
    resourceId: '',
    firstName: '',
    lastName: '',
    email: '',
    thumbUrl: '',
    _id: '',
    isApprover: false,
    resourceStatus: '',
    resourceRole: '',
    preferences: {
        filter: {},
        grid: {},
        topLevelResourceGroupOrder: {},
        topLevelProjectGroupOrder: {},
        myManagedProjectsGroupId: '',
        myManagedResourcesGroupId: '',
        myProjectsGroupId: '',
        myScheduleGroupId: '',
        notificationDetails: { bookingSummary: {}, timesheetReminder: {} },
        notifications: [],
        schedulerColumns: { projectColumns: {}, resourceColumns: {} },
        subGroupsOrder: [],
        subGroupsPosition: subGroupsPositionEnum.bottom,
        report: {
            unit: HOURS_DECIMAL.value,
            version: 'new',
            tour: { viewed: true },
            groupAggregates: true,
        },
        theme: 'LIGHT_THEME',
    },
    mfaEnabled: false,
    userMFAEnabled: false,
    mfa: {
        mfaEnabled: false,
        mfaRecoveryCodes: [],
        error: '',
        errorCode: '',
        loading: false,
        qrImage: '',
        mfaKey: '',
        shouldShowCompanyReminder: false,
    },
    isProjectManager: false,
    resourceCreatedDate: '',
    resourceRoleRights: {},
    globalCompanySettings: { permissions: [], slack: null },
    loading: true,
    authError: '',
    authenticationMethod: '',
    shouldValidateOTP: false,
};

const setAccount = (state, action) => ({
    ...state,
    ...action.payload.account,
    loading: false,
    authError: '',
});

const setEmail = (state, action) => {
    return {
        ...state,
        email: action.payload?.data?.userName,
    };
};

const setMFALoader = state => ({
    ...state,
    mfa: {
        ...state.mfa,
        loading: true,
        error: '',
        errorCode: '',
    },
});

const clearMFA = state => ({
    ...state,
    mfa: initialAccount.mfa,
});

const setMFAError = (state, action) => ({
    ...state,
    mfa: {
        ...state.mfa,
        loading: false,
        error: action.payload?.error ?? '',
        errorCode: action.payload?.errorCode ?? '',
    },
});

const setMFASuccess = (state, action) => ({
    ...state,
    mfaEnabled: action.payload?.mfa?.mfaEnabled ?? false,
    userMFAEnabled: action.payload?.mfa?.mfaEnabled ?? false,
    mfa: {
        mfaRecoveryCodes: action.payload?.mfa?.mfaRecoveryCodes ?? [],
        loading: false,
        error: '',
        errorCode: '',
    },
});

const showMFACompanyReminder = state => ({
    ...state,
    mfa: {
        shouldShowCompanyReminder: true,
    },
});

const setDisableMFASuccess = state => ({
    ...state,
    mfaEnabled: false,
    userMFAEnabled: false,
});

const setMFADataSuccess = (state, action) => ({
    ...state,
    mfa: {
        ...state.mfa,
        qrImage: action.payload?.qrImage || '',
        mfaKey: action.payload?.mfaKey || '',
        loading: false,
        error: '',
        errorCode: '',
    },
});
const setMFARecoveryCodesSuccess = (state, action) => ({
    ...state,
    mfa: {
        ...state.mfa,
        mfaRecoveryCodes: action.payload?.mfaRecoveryCodes,
        loading: false,
        error: '',
        errorCode: '',
    },
});

const updateReportSettings = (state, action) => ({
    ...state,
    preferences: {
        ...state.preferences,
        report: updateObject(state.preferences.report, action.payload.data),
    },
});

const updatePreferences = (state, action) => {
    const diff = objectDiff(action.payload.data, state.preferences);
    if (!keys(diff).length) {
        return state;
    }

    return {
        ...state,
        preferences: updateObject(state.preferences, action.payload.data),
        schedulerUpdate: action.payload.schedulerUpdate,
    };
};

const clearError = state => ({
    ...state,
    authError: '',
});

const setError = (state, action) => ({
    ...state,
    loading: false,
    authError: action.payload.error ? action.payload.error : 'Authentication error',
});

const setOTPValidation = (state, action) => ({
    ...state,
    shouldValidateOTP: action.payload,
});

const setLoader = state => ({
    ...state,
    loading: false,
});

const updateAccount = (state, action) => updateObject(state, action.payload.data);

const updateAccountNotifications = (state, action) => ({
    ...state,
    preferences: {
        ...state.preferences,
        notifications: action.payload.notifications,
        notificationDetails: updateObject(state.preferences.notificationDetails, action.payload.notificationDetails),
    },
});

const clearSchedulerUpdate = (state) => {
    return {
        ...state,
        schedulerUpdate: null
    }
}

export default createReducer(initialAccount, {
    [actionTypes.GET_ACCOUNT['SUCCESS']]: setAccount,
    [actionTypes.GET_ACCOUNT['FAILURE']]: setLoader,
    [actionTypes.UPDATE_ACCOUNT_REPORT_SETTINGS['REQUEST']]: updateReportSettings,
    [actionTypes.UPDATE_ACCOUNT_PREFERENCES['REQUEST']]: updatePreferences,
    [actionTypes.UPDATE_ACCOUNT_PREFERENCES['SUCCESS']]: updatePreferences, //need to update after saving to get the _id prop for customColumns
    [actionTypes.UPDATE_ACCOUNT['REQUEST']]: updateAccount,
    [actionTypes.UPDATE_ACCOUNT_NOTIFICATIONS['REQUEST']]: updateAccountNotifications,
    [actionTypes.CLEAR_AUTH_ERROR]: clearError,
    [actionTypes.SETUP_MFA['REQUEST']]: setMFALoader,
    [actionTypes.CLEAR_MFA_DATA]: clearMFA,
    [actionTypes.CLEAR_MFA_ERROR]: setMFAError,
    [actionTypes.SET_MFA_ERROR]: setMFAError,
    [actionTypes.SHOW_MFA_COMPANY_REMINDER]: showMFACompanyReminder,
    [actionTypes.SHOW_MFA_RECOVERY_CODES['SUCCESS']]: setMFARecoveryCodesSuccess,
    [actionTypes.SHOW_MFA_RECOVERY_CODES['FAILURE']]: setMFAError,
    [actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['SUCCESS']]: setMFARecoveryCodesSuccess,
    [actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['FAILURE']]: setMFAError,
    [actionTypes.SETUP_MFA['SUCCESS']]: setMFASuccess,
    [actionTypes.SETUP_MFA['FAILURE']]: setMFAError,
    [actionTypes.DISABLE_MFA['SUCCESS']]: setDisableMFASuccess,
    [actionTypes.DISABLE_MFA['FAILURE']]: setMFAError,
    [actionTypes.SETUP_MFA['FAILURE']]: setMFAError,
    [actionTypes.SETUP_MFA_DATA['REQUEST']]: setMFALoader,
    [actionTypes.SETUP_MFA_DATA['SUCCESS']]: setMFADataSuccess,
    [actionTypes.SETUP_MFA_DATA['FAILURE']]: setMFAError,
    [actionTypes.SIGNUP['SUCCESS']]: setAccount,
    [actionTypes.LOGIN['REQUEST']]: setEmail,
    [actionTypes.LOGIN['SUCCESS']]: setAccount,
    [actionTypes.LOGIN['FAILURE']]: setError,
    [actionTypes.SIGNUP['FAILURE']]: setError,
    [actionTypes.VALIDATE_OTP_ON_LOGIN]: setOTPValidation,
    [actionTypes.CLEAR_SCHEDULER_UPDATE]: clearSchedulerUpdate,
});
