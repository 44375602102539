import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    displayCurrencyValue,
    displayDateState,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendBetween,
                displayCurrencyValue(row, 'externalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Previous Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendPrevious'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendPrevious,
                displayCurrencyValue(row, 'externalScheduledSpendPrevious', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Next Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendAfter',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendAfter'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendAfter,
                displayCurrencyValue(row, 'externalScheduledSpendAfter', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend To Date
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendToDate,
                displayCurrencyValue(row, 'externalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Future Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendFuture,
                displayCurrencyValue(row, 'externalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        All Time Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.externalScheduledSpendAllTime,
                displayCurrencyValue(row, 'externalScheduledSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendBetween,
                displayCurrencyValue(row, 'internalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Previous Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendPrevious'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendPrevious,
                displayCurrencyValue(row, 'internalScheduledSpendPrevious', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Next Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendAfter',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendAfter'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendAfter,
                displayCurrencyValue(row, 'internalScheduledSpendAfter', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend To Date
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendToDate,
                displayCurrencyValue(row, 'internalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Future Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendFuture,
                displayCurrencyValue(row, 'internalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        All Time Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.internalScheduledSpendAllTime,
                displayCurrencyValue(row, 'internalScheduledSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Profitability All Time
                        <p className="sub-title">(scheduled - ext - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'scheduledSpendProfitabilityAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledSpendProfitabilityAllTime'),
        accessor: row =>
            displayValue(
                row.scheduledSpendProfitabilityAllTime,
                displayCurrencyValue(row, 'scheduledSpendProfitabilityAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Profitability {displayDateState(dateState)}
                        <p className="sub-title">(scheduled - ext - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'scheduledSpendProfitabilityBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledSpendProfitabilityBetween'),
        accessor: row =>
            displayValue(
                row.scheduledSpendProfitabilityBetween,
                displayCurrencyValue(row, 'scheduledSpendProfitabilityBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Spend
                        <p className="sub-title">(ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalEstimatedSpend',
        sortable: false,
        show: contains(columnsToLoad, 'externalEstimatedSpend'),
        accessor: row =>
            displayValue(
                row.externalEstimatedSpend,
                displayCurrencyValue(row, 'externalEstimatedSpend', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Spend
                        <p className="sub-title">(int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalEstimatedSpend',
        sortable: false,
        show: contains(columnsToLoad, 'internalEstimatedSpend'),
        accessor: row =>
            displayValue(
                row.internalEstimatedSpend,
                displayCurrencyValue(row, 'internalEstimatedSpend', defaultCurrency)
            ),
    },
];
