import { store } from '../../../../../store';
import { updateBooking } from '../../../../../actions/bookingActions';
import { showConfirmationModal } from '../../../../../actions/modalActions';
import { Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';
import React from 'react';
import { getNewApprovalInfo, prepareEvent } from '../onEventMoved/event';
import { dispatchRefreshBookings, eventsIdsMoving, invalidateRowsByIds } from '../../../utils/schedulerUtil';
import { formatBookingToBackend } from '../../../utils/eventUtil';
import { REPEAT_MODAL_TYPES } from '../../../modals/editRepeatModal/modal';

export function handleBookingMove({ args, companyWeekdays, viewObject, reduxState, approvers, mappedResourceGroups }) {
    args.async = true;

    const scheduler = args.control;

    const initialRowId = args.e.resource();
    const destinationRow = scheduler.rows.find(args.newResource);
    const { event, oldEvent } = prepareEvent(args, companyWeekdays, viewObject, scheduler, destinationRow);

    event.id && eventsIdsMoving.add(event.id);

    const newApprovalInfo = getNewApprovalInfo({
        event,
        oldEvent,
        args,
        reduxState,
        approvers,
        mappedResourceGroups,
    });

    const bookingToUpdate = { ...event, ...newApprovalInfo };

    const formattedBooking = formatBookingToBackend(bookingToUpdate);

    const onSuccess = () => {
        invalidateRowsByIds([initialRowId]);
    };

    const onFailure = () => {
        args.preventDefault();
    }

    invalidateRowsByIds([initialRowId]);
    if (!bookingToUpdate.repeat) {
        args.loaded();
        store.dispatch(
            updateBooking.request(formattedBooking.bookingId || formattedBooking._id, formattedBooking, {}, onSuccess, onFailure)
        );
        return;
    }

    store.dispatch(
        showConfirmationModal(
            () => {
                args.loaded();
                store.dispatch(
                    updateBooking.request(
                        formattedBooking.bookingId || formattedBooking._id,
                        {
                            ...formattedBooking,
                            repeat: false,
                            repeatId: null,
                            repeatTimes: 0,
                        },
                        {
                            createRepeatFromExisting: false,
                        },
                        () => {
                            onSuccess();
                            dispatchRefreshBookings();
                        },
                        onFailure
                    )
                );
            },
            REPEAT_MODAL_TYPES.BOOKING.title,
            () => (
                <>
                    <Typography paragraph>{REPEAT_MODAL_TYPES.BOOKING.description(false)}</Typography>
                    <Alert color="warning">
                        <strong>Warning!</strong> If you update just this booking, it will be updated to the new
                        position and removed from the repeat cycle. If you update all, all bookings will be updated to
                        the new position.
                    </Alert>
                </>
            ),
            {
                withEscButton: true,
                withCancel: true,
                confirmButtonText: 'Update this',
                confirmButtonDataCy: 'button--update-this',
                cancelActionOnHide: true,
                onReject: () => {
                    event.id && eventsIdsMoving.delete(event.id);
                    args.preventDefault();
                    args.loaded();
                },
                additionalButtons: [
                    {
                        text: 'Update all',
                        dataCy: 'button--update-all',
                        onClick: () => {
                            args.loaded();
                            store.dispatch(
                                updateBooking.request(
                                    formattedBooking.bookingId || formattedBooking._id,
                                    formattedBooking,
                                    {
                                        createRepeatFromExisting: true,
                                        modifyAllRepeated: true,
                                        removeRemovedRepeated: true,
                                    },
                                    onSuccess,
                                    onFailure
                                )
                            );
                        },
                    },
                ],
            }
        )
    );
}
