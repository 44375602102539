import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'redux-form';
import { Col, Row, FormText } from 'reactstrap';
import Allocation from 'shared/allocation/materialAllocation';
import { dateField, dropdownField, inputGroupTextField } from 'shared/formFields';
import Note from 'modules/modals/common/note';
import CategoryTemplateItem from 'shared/categoryTemplateItem';
import Approvers from 'modules/request/components/approvers';
import CategoryGroupItem from 'shared/categoryGroupItem';
import Repeat from 'shared/repeat';
import { useAppKeyWords, useLoadProjectsAndResources } from 'hooks';
import { useSelector } from 'react-redux';
import { makeGetFilteredProjects } from 'selectors/project';
import { makeGetFilteredResources } from 'selectors/resource';
import { FORM_NAME } from './modal';
import { selectCompanyApprovers } from '../../../../selectors/company';
import { Divider, Layout } from '@hub-mono/ui';

const getFilteredProjects = makeGetFilteredProjects('EXCLUDE_ARCHIVED');
const getFilteredResources = makeGetFilteredResources('EXCLUDE_PARKED');

const RequestModalBody = props => {
    const {
        handleSubmit,
        defaultApprovers,
        onCategoryChange,
        onChange,
        categoryTemplates,
        formValues,
        booking,
        vacationId,
    } = props;

    useLoadProjectsAndResources();
    const { state, interval, startDate, notifyPM, approvers: selectedApprovers, project } = formValues;
    const { resourceKeyWord, resourcePluralKeyWord, projectKeyWord } = useAppKeyWords();
    const approvers = useSelector(selectCompanyApprovers);
    const projects = useSelector(getFilteredProjects);
    const resources = useSelector(getFilteredResources);

    const isVacation = project?._id === vacationId;

    const availableProjects = useMemo(() => {
        if (projects.some(p => p._id === booking?.project._id)) {
            return projects;
        }

        return [...projects, ...(booking?.project ? [booking.project] : [])];
    }, [projects, booking?.project]);

    const availableResources = useMemo(() => {
        if (resources.some(p => p._id === booking?.resourceInfo._id)) {
            return resources;
        }

        return [...resources, ...(booking?.resourceInfo ? [booking.resourceInfo] : [])];
    }, [resources, booking?.resourceInfo]);

    return (
        <Form className="needs-validation" onSubmit={handleSubmit}>
            <Layout stack gap={24}>
                <section>
                    <p className="title">Select {resourceKeyWord}:</p>
                    <Field
                        inline
                        name="resource"
                        component={dropdownField}
                        valueField="_id"
                        busy={!resources.length}
                        filter="contains"
                        textField={item => (Object.keys(item).length ? `${item.firstName} ${item.lastName}` : '')}
                        data={availableResources}
                    />
                    <FormText className="required">
                        Choose which {resourcePluralKeyWord} you would like to request
                    </FormText>
                </section>
                <Divider />
                <section>
                    <p className="title">Select a Project or Event:</p>
                    <Field
                        inline
                        name="project"
                        component={dropdownField}
                        valueField="_id"
                        busy={!projects.length}
                        filter="contains"
                        textField={item => (Object.keys(item).length ? item.name : '')}
                        data={availableProjects}
                    />
                    <FormText className="required">
                        Choose a Project or Event that you would like to request the {resourceKeyWord}
                        to be scheduled on
                    </FormText>
                </section>
                <Divider />
                <section>
                    <p className="title">Booking Title:</p>
                    <Field name="title" icon="fa-font" component={inputGroupTextField} />
                    <FormText>
                        Give your request a title. If blank then the {projectKeyWord} name is displayed on request by
                        default.
                    </FormText>
                </section>
                <Divider />
                <section>
                    <p className="title mb-4">Requested date:</p>
                    <Row>
                        <Col md={6}>
                            <Field className="w-100" name="startDate" label="Start Date" component={dateField} />
                        </Col>
                        <Col md={6}>
                            <Field
                                className="w-100"
                                name="endDate"
                                label="End Date"
                                component={dateField}
                                props={{
                                    shouldDisableDate: date => {
                                        const current = new Date(date).setHours(0, 0, 0, 0);
                                        const newStartDate = new Date(startDate).setHours(0, 0, 0, 0);
                                        return current < newStartDate;
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                </section>
                <Divider />
                <Repeat
                    inputFieldDisabled={'NONE' === interval}
                    title="Requested Booking Repeat:"
                    formText="The number of times the booking should repeat."
                />
                <Divider />
                <Approvers
                    defaultApprovers={defaultApprovers}
                    approvers={approvers}
                    onChange={onChange}
                    notifyPM={notifyPM}
                    selectedApprovers={selectedApprovers}
                    isVacation={isVacation}
                />
                <Divider />
                <Note
                    title="Request Note:"
                    placeholder="Add an optional note here..."
                    formText="An additional message or note can be added here which will be sent along with the request."
                />
                <Divider />
                <Allocation
                    title="Requested Booking Allocation:"
                    subtitle="Select how much allocation you want to request"
                    formText={`The booking allocation amount per ${resourceKeyWord}`}
                    allocationDefaultState={state}
                    onChange={onChange}
                    formName={FORM_NAME}
                />
                <Divider />
                <section>
                    <p className="title">Booking Category:</p>
                    <Field
                        inline
                        name="category"
                        component={dropdownField}
                        valueField="_id"
                        dropUp
                        itemComponent={CategoryTemplateItem}
                        valueComponent={CategoryTemplateItem}
                        textField={item => (Object.keys(item).length ? item.name : '')}
                        data={categoryTemplates}
                        groupBy="groupName"
                        groupComponent={CategoryGroupItem}
                        filter="contains"
                        onChange={onCategoryChange}
                    />
                    <FormText>Choose a booking category from the options above</FormText>
                </section>
            </Layout>
        </Form>
    );
};

RequestModalBody.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    defaultApprovers: PropTypes.array.isRequired,
    onCategoryChange: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    categoryTemplates: PropTypes.array.isRequired,
};

export default RequestModalBody;
