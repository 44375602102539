import styled from 'styled-components';
import { UncontrolledDropdown, Button, DropdownToggle } from 'reactstrap';

export const Dropdown = styled(UncontrolledDropdown)`
    & > a {
        padding: 0.2rem 0.5rem !important;
        border: 1px solid #ddd !important;
        background: #fff;
        border-radius: 0.2rem;
        text-align: center;
        color: #000;
        margin-left: 3px;

        &:hover {
            background-color: #f5f5f5;
            color: #000;
        }
    }
`;

const buttonStyles = `
    padding: 0.2rem 0.5rem !important;
    border-color: #ddd !important;
    width: 40px;
    background-color: #fff !important;
    color: #000 !important;
    height: 30px;
    .center-icon {
        font-size: 14px;
    }
    &:hover {
        background-color: #f5f5f5 !important;
    }
    svg {
        color: #525252;
        font-size: 1.25rem;
    }
`;
export const NavButton = styled(Button)`
    ${buttonStyles}
`;

export const ToggleButton = styled(DropdownToggle)`
    ${buttonStyles}
    &.left {
        border-right-width: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.center {
        border-radius: 0;
    }

    &.right {
        border-left-width: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

export const ViewModesTitle = styled.span`
    font-weight: bold;
    margin-top: 10px;
    font-size: 17px;
    display: block;
    margin-left: 60px;
`;

export const ViewModesDescription = styled.span`
    color: rgb(153, 153, 153);
    font-size: 12px;
    border-bottom: 1px dotted rgb(203, 203, 203);
    line-height: 17px;
    white-space: normal;
    display: block;
    padding-left: 60px;
    padding-bottom: 17px;
`;

export const ViewModesItemTitle = styled.span`
    display: block;
    color: #000;
`;

export const ViewModesItemDescription = styled.span`
    display: block;
    color: #b3b3b3;
    width: 300px;
    white-space: normal;
`;

export const ViewModeActive = styled.span`
    font-size: 12px;
    background-color: #638d63;
    border-radius: 4px;
    padding: 2px 6px;
    margin-top: 4px;
    color: #fff;
    margin-left: 7px;
`;
