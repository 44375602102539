import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import DefaultIconWrapper from 'shared/iconWrapper';
import { Favorite, Home, Settings, Close, Description } from '@material-ui/icons';

export const DatePickerWrapper = styled.div`
    position: absolute;
    top: ${props => props.top || '34px'};
    right: ${props => props.right || '-20px'};
    background-color: #fff;
    padding: 15px;
    box-shadow: 0 12px 40px 5px rgba(0, 0, 0, 0.26);
    z-index: 999;
`;

export const DateWrapper = styled.div`
    svg {
        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
    }

    span {
        margin-top: 2px;
        display: inline-block;
    }

    font-size: 12px;
`;

export const MainWrapper = styled.div`
    right: 20px;
    top: 14px;
    font-size: 13px;
    position: absolute;
`;

export const Date = styled.span`
    cursor: pointer;

    &:hover {
        opacity: 0.5;
    }

    span {
        color: #8a8a8a;
        font-size: 12px;
        margin-right: 3px;
    }
`;

export const Content = styled.div`
    position: absolute;
    width: 300px;
    height: auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    top: 55px;
    right: -127px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    z-index: 9;

    &:after {
        top: -8px;
        left: 133px;
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: #fff;
        transform: rotate(45deg);
        z-index: -1;
    }

    input[type='text'] {
        height: 33px;
        background: #f5f5f5;
    }
`;

export const TemplatePod = styled(Link)`
    position: relative;
    font-size: 0.875rem;
    display: block;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    min-height: 270px;
    max-height: 270px;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
    overflow: hidden;
    background: ${props => ('true' === props.active ? '#ececec' : 'inherit')};

    &:hover {
        cursor: pointer;
        background: #fdfafa;
        text-decoration: none;
    }

    h3 {
        padding-top: 15px;
        font-size: 22px;
        font-weight: 300;
    }

    p {
        color: #999999;
        margin: 0;
        font-size: 14px;
        padding-top: 10px;
    }
`;

export const ContextRowWrapper = styled(Row)`
    height: 40px;
    margin: 10px 0 !important;
    flex-direction: column;

    div:first-child {
        position: relative;
        z-index: 0;
    }
`;

export const ContextRow = styled.div`
    background-color: #458490;
    border-right: 1px solid #fff;
    font-size: 13px;
    padding: 1px 15px;
    height: 40px;
    width: ${props => props.width || '100px'};
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #fff;
    }

    .subtitle {
        color: #d8d1d1 !important;
    }
`;

export const Title = styled.h4`
    margin-top: 10px;
    text-transform: capitalize;

    small {
        border-bottom: 1px solid #e9ecef;
        padding-bottom: 15px;
        display: block;
        font-size: 12px;
        margin-top: 5px;
    }
`;

export const PermissionDeniedImage = styled.img`
    width: 80px;
    height: 80px;
`;

export const SubTemplatePod = styled(Link)`
    position: relative;
    font-size: 0.875rem;
    background: ${props => ('true' === props.active ? '#ececec' : 'inherit')};
    display: block;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    font-family: Poppins, Helvetica, Arial, sans-serif;
    font-weight: 300;
    text-decoration: none;
    margin-top: 10px;
    margin-bottom: 15px;
    padding: 20px;
    overflow: hidden;

    &:hover {
        cursor: pointer;
        background: #fdfafa;
        text-decoration: none;
    }

    h3 {
        padding-top: 15px;
        font-size: 22px;
        font-weight: 300;
    }

    p {
        color: #999;
        margin: 0;
        font-size: 14px;
    }

    span {
        float: left;
        height: 80px;
        width: 80px;
        margin-right: 20px;
        line-height: 50px;

        svg {
            font-size: 34px;
        }
    }
`;

export const IconWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const Favourite = styled(Favorite)`
    display: inline-block;
    color: ${props => ('true' === props.active ? '#ef5350' : '#e0e0e0')};

    &:hover {
        color: #ef5350;
        cursor: pointer;
    }
`;

export const SettingsIcon = styled(Settings)`
    margin-right: 7px;
    color: #e0e0e0;

    &:hover {
        color: #777;
        cursor: pointer;
    }
`;

export const CloseIcon = styled(Close)`
    color: #e0e0e0;

    &:hover {
        color: #777;
        cursor: pointer;
    }
`;

export const ComingSoonRibbon = styled.div`
    position: absolute;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background: #39d;
    top: 22px;
    right: -36px;
    left: auto;
    transform: rotate(45deg);
    color: #fff;
    padding: 7px 40px;
`;

export const HomeIcon = styled(Home)`
    color: #808080;

    &:hover {
        color: #0eb3c8;
    }
`;

export const AuditIcon = styled(Description)`
    color: #808080;
`;

export const ToolbarIconText = styled.span`
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    color: rgba(0, 0, 0, 0.5);
`;

export const HelpText = styled.div`
    color: #999 !important;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    .emphasis-help-text {
        background-color: #dadada;
        color: #313131;
        padding: 1px 5px;
        font-size: 12px;
        border: 1px solid #c1c1c1;
        border-radius: 3px;
    }
`;

export const Red = styled(DefaultIconWrapper)`
    background: linear-gradient(60deg, #ef5350, #e53935);
    box-shadow: 0 12px 20px -10px rgba(244, 67, 54, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(244, 67, 54, 0.2);
`;

export const Green = styled(DefaultIconWrapper)`
    background: linear-gradient(60deg, #66bb6a, #43a047);
    box-shadow: 0 12px 20px -10px rgba(76, 175, 80, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(76, 175, 80, 0.2);
`;

export const Black = styled(DefaultIconWrapper)`
    background: #333;
    box-shadow: 0 12px 20px -10px rgba(51, 51, 51, 0.14), 0 4px 20px 0px rgba(51, 51, 51, 0.2),
        0 7px 8px -5px rgba(51, 51, 51, 0.12);
`;

export const Yellow = styled(DefaultIconWrapper)`
    background: linear-gradient(60deg, #ffa726, #fb8c00);
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(255, 152, 0, 0.2);
`;
