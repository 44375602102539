import styled from 'styled-components';
import { NavLink } from 'reactstrap';

export const TabItem = styled(NavLink)`
    width: 58px;
    height: 44px;
    padding: 5px 14px !important;
    text-align: center;
    border-right: 1px solid #ebebeb !important;
    border-left: 0;
    border-bottom: 0;
    border-top: 0;
    color: #000 !important;
    i:first-child {
        font-size: 20px;
    }
    &.active {
        background-color: #3a87ad !important;
        border: none !important;
        color: #fff !important;
        position: relative;
        &:hover {
            opacity: 1;
            border: none !important;
        }
        &:after {
            font-family: FontAwesome, sans-serif;
            color: #3a87ad;
            content: '\\F0D7';
            position: absolute;
            bottom: -16px;
            right: 23px;
            font-size: 17px;
        }
        .fa-plus {
            color: #000;
        }
    }
    &:hover {
        opacity: 0.7;
        cursor: pointer;
        border-color: transparent !important;
        border-right: 1px solid #ebebeb !important;
    }
`;

export const TabInfo = styled.div`
    border-bottom: 1px dashed #cfcfcf;
`;

export const Stack = styled.span`
    vertical-align: initial;
    height: 24px;
    i:first-child {
        font-size: 25px;
    }
`;

export const TabInfoDesc = styled.p`
    color: #b3b3b3;
    text-align: center;
`;

export const FaPlus = styled.i`
    color: #fff;
    font-size: 13px;
    margin-left: 2px;
    margin-top: 2px;
`;

export const ListWrapper = styled.div`
    border-bottom: 1px dashed #dee2e6;
    padding-bottom: 20px;
    margin-bottom: 15px;
    .nav-tabs {
        border-bottom: none;
    }
`;
