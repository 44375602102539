import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { SanitizeHTML } from './SanitizeHTML';

const useStyles = makeStyles({
    TextAndImageRow_main: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        fontSize: '14px',
    },
    TextAndImageRow_text: {
        width: '100%',
        textAlign: 'initial',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    TextAndImageRow_buttons: {
        margin: '10px -5px 5px -5px',
    },
    TextAndImageRow_title: {
        fontWeight: 600,
        fontSize: '27px',
        lineHeight: 'normal',
        marginBottom: '10px',
    },
    TextAndImageRow_additionalInfo: {
        fontSize: '14px',
        color: '#838383',
        fontWeight: 400,
        marginTop: '8px',
    },
    TextAndImageRow_description: {
        marginBottom: '20px',
    },
    TextAndImageRow_image: {
        width: '100%',
        textAlign: 'left',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    TextAndImageRow_img: {
        width: '100%',
        maxWidth: 'fit-content',
    },
});

export const TextAndImagesListRow = React.memo(props => {
    const { title, description, additionalInfo, images } = props;
    const classes = useStyles(props);

    const textJSX = () => {
        return (
            <div className={classes.TextAndImageRow_text}>
                {title && <div className={classes.TextAndImageRow_title}>{title}</div>}
                {additionalInfo && <div className={classes.TextAndImageRow_additionalInfo}>{additionalInfo}</div>}
                {description && <SanitizeHTML html={description} className={classes.TextAndImageRow_description} />}
            </div>
        );
    };

    const imagesJSX = () => {
        return images.map((image, i) => (
            <div key={i} className={classes.TextAndImageRow_image}>
                {image.label && <SanitizeHTML html={image.label} className={classes.TextAndImageRow_description} />}
                <img className={classes.TextAndImageRow_img} src={image.imageSrc} />
            </div>
        ));
    };

    if (!title && !description && !additionalInfo) {
        return null;
    }

    return (
        <div className={classes.TextAndImageRow_main}>
            {textJSX()}
            {imagesJSX()}
        </div>
    );
});

export const textAndImageRowPropTypes = {
    titleHeading: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    additionalInfo: PropTypes.string,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            imageSrc: PropTypes.string,
        })
    ),
};
TextAndImagesListRow.propTypes = textAndImageRowPropTypes;

export default TextAndImagesListRow;
