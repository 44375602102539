/* eslint-env browser */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import BaseLayout from './base';

const ProtectedRoute = ({
    layout: Layout,
    isAuthenticated,
    isPartiallyAuthenticated,
    isAutoLoginInProgress,
    title,
    ...rest
}) => {
    useEffect(() => {
        document.title = title || 'HUB Planner';
    }, [title]);

    if (isAuthenticated) {
        if (!Layout) {
            Layout = BaseLayout;
        }

        return <Layout {...rest} />;
    }

    if (!isPartiallyAuthenticated && !isAutoLoginInProgress) {
        //is loading
        return <Redirect to="/login" />;
    }

    return null;
};

ProtectedRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isPartiallyAuthenticated: PropTypes.bool.isRequired,
    isAutoLoginInProgress: PropTypes.bool.isRequired,
    title: PropTypes.string,
};

ProtectedRoute.defaultProps = {
    title: 'HUB Planner',
};

export default ProtectedRoute;
