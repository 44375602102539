import { DayPilot } from 'daypilot-pro-react';
import classNames from 'classnames';
import moize from 'moize';
import { forEach, uniq, without, includes } from 'lodash';
import { ACTIVE, UNASSIGNED } from 'enums/criteriaEnum';

const unassignedDayPilotMenu = new DayPilot.Menu({
    menuTitle: 'Add Unassigned Work',
    theme: 'hub-context',
    cssClassPrefix: 'hub-context',
    showMenuTitle: true,
    hideOnMouseOut: false,
    useShadow: true,
    zIndex: 10,
});

export default moize((currentItem = {}, keyWords, unassignedResources, actions, viewObject) => {
    unassignedDayPilotMenu.items = [];

    // do not render unassignedResources on project group view
    if (
        viewObject.isProjectGroupView ||
        (viewObject.isResourceGroupView && currentItem && currentItem.criteria === ACTIVE)
    ) {
        let text = `You cannot manually add Unassigned work to a ${keyWords.projectKeyWord} Group. All Unassigned Work that appears will be inherited from the ${keyWords.projectPluralKeyWord}`;
        if (currentItem && currentItem.criteria === ACTIVE && currentItem.hasOwnProperty('resources')) {
            text = `You cannot manually add Unassigned work to a ${keyWords.resourceKeyWord} Active Group.`;
        }
        unassignedDayPilotMenu.showMenuTitle = false;
        unassignedDayPilotMenu.items.push({
            text,
        });

        return unassignedDayPilotMenu;
    }

    if (currentItem.criteria !== UNASSIGNED) {
        forEach(unassignedResources, resource => {
            let selectedState = 'not-active-unassigned';

            if (
                (viewObject.isProjectView || viewObject.isResourceGroupView) &&
                includes(currentItem.resources, resource._id)
            ) {
                selectedState = 'active-unassigned';
            }

            unassignedDayPilotMenu.items.push({
                text: resource.name,
                cssClass: classNames(selectedState, 'border-right-5'),
                onClick: () => {
                    if (viewObject.isProjectView) {
                        let data;
                        if ('active-unassigned' !== selectedState) {
                            data = uniq((currentItem.resources ?? []).concat(resource._id));
                        } else {
                            data = without(currentItem.resources, resource._id);
                        }
                        actions.updateProject(currentItem._id, { project: { resources: data } });
                    } else if (viewObject.isResourceGroupView) {
                        let data = {
                            isSmart: currentItem.isSmart,
                            type: currentItem.type,
                            name: currentItem.name,
                            queryParams: {
                                groupsToAdd: [],
                                groupsToRemove: [],
                                itemsToAdd: [],
                                itemsToRemove: [],
                                ...(currentItem.queryParams || {}),
                            },
                        };

                        const adding = 'active-unassigned' !== selectedState;
                        if (adding) {
                            data.queryParams.itemsToAdd = [resource._id];
                        } else {
                            data.queryParams.itemsToRemove = [resource._id];
                        }

                        actions.updateResourceGroup(data);
                    }
                },
            });
        });
        // Seperator
        unassignedDayPilotMenu.items.push({
            text: '-',
        });
    }

    unassignedDayPilotMenu.items.push({
        text: 'Add More Categories',
        href: '/settings#unassignedTab',
    });

    return unassignedDayPilotMenu;
});
