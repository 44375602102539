import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Form, reduxForm, initialize, Field, submit, getFormValues } from 'redux-form';
import { updateProject } from 'actions/projectActions';
import { validateSchema } from 'utils/schemaUtil';
import { updateDaypilotMilestonesPhasesDates } from 'modules/scheduler/utils/schedulerUtil';
import { formatStartEndTimesToDP } from 'modules/scheduler/utils/eventUtil';
import { formSchema, mapFormToRequest } from './datesFormSchema';
import { dateField } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { updateBookingSelection } from 'actions/schedulerActions';
import { removeUTCZuluFromDateObject } from '../../../../../../utils/DateUtil';

const FORM_NAME = 'projectDatesForm';

const ProjectDatesForm = props => {
    const { defaults, schedulerRef, handleSubmit, onClose, invalid } = props;
    const dispatch = useDispatch();
    const { start, end } = useSelector(state => state.scheduler.currentSelection);
    const formValues = useSelector(getFormValues(FORM_NAME));

    useEffect(() => {
        dispatch(
            initialize(FORM_NAME, {
                start: defaults.start ? removeUTCZuluFromDateObject(defaults.start) : start,
                end: defaults.end ? removeUTCZuluFromDateObject(defaults.end) : end,
            })
        );
    }, [dispatch, start, end, defaults]);

    const onSubmit = useCallback(
        values => {
            const requestValues = mapFormToRequest(values);
            dispatch(
                updateProject.request(defaults.projectId, requestValues, () => {
                    // fix: https://hubplanner.atlassian.net/browse/HUB-8197
                    const projectValues = {
                        ...defaults,
                        backgroundColor: defaults.color,
                        start: `${requestValues.project.start}T00:00:00.000Z`,
                        end: `${requestValues.project.end}T00:00:00.000Z`,
                        _id: defaults.projectId,
                    };
                    const formattedStartEndToDP = formatStartEndTimesToDP([projectValues]);
                    updateDaypilotMilestonesPhasesDates(formattedStartEndToDP, schedulerRef.current.control);
                    onClose && onClose();
                    schedulerRef.current.control.clearSelection();
                    dispatch(updateBookingSelection.request());
                })
            );
        },
        [dispatch, onClose, defaults, schedulerRef]
    );

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row noGutters className="mb-5">
                <Col xs={6} className="pr-1">
                    <Field
                        name="start"
                        label="Start date"
                        component={dateField}
                        data-cy="scheduler-booking-modern-menu-dates-start-date"
                        componentDataCyPrefix="scheduler-booking-modern-menu-dates-start-date"
                    />
                </Col>
                <Col xs={6} className="pl-1">
                    <Field
                        name="end"
                        label="End date"
                        component={dateField}
                        data-cy="scheduler-booking-modern-menu-dates-end-date"
                        componentDataCyPrefix="scheduler-booking-modern-menu-dates-end-date"
                        props={{
                            shouldDisableDate: date => {
                                const current = new Date(date).setHours(0, 0, 0, 0);
                                const newStartDate = new Date(formValues.start).setHours(0, 0, 0, 0);
                                return current < newStartDate;
                            },
                        }}
                    />
                </Col>
            </Row>
            <SuccessButton
                disabled={invalid}
                className="w-100 mt-5"
                dataCy={defaults.id ? 'button--update-dates' : 'button--add-dates'}
                name={defaults.id ? 'Update dates' : 'Add dates'}
                onClick={() => dispatch(submit(FORM_NAME))}
            />
        </Form>
    );
};

ProjectDatesForm.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    defaults: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        start: PropTypes.object,
        end: PropTypes.object,
        id: PropTypes.string,
    }).isRequired,
};

ProjectDatesForm.defaultProps = {
    onClose: null,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(ProjectDatesForm);
