import React from 'react';
import ResourceColumn from 'modules/scheduler/components/scheduler/resourceColumn';

export default (companyGridSettings, { isPmExtensionActive, isCustomFieldExtensionActive }, systemGroupIds) => args => {
    args.element = (
        <ResourceColumn
            isPmExtensionActive={isPmExtensionActive}
            isCustomFieldExtensionActive={isCustomFieldExtensionActive}
            data={args.row.data}
            toggle={args.row.toggle}
            schedulerColumns={args.control.schedulerColumns}
            systemGroupIds={systemGroupIds}
            companyGridSettings={companyGridSettings}
        />
    );
};
