import * as actionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';
import { each, keys } from 'underscore';
import moment from 'moment';
import StorageUtil from 'utils/StorageUtil';
import { findRangeByStartEndDates, getDateRanges } from 'modules/request/utils/requestUtil';

const initialRequestState = {
    statistics: {
        sick_leave: 0,
        total: {
            APPROVED: 0,
            REJECTED: 0,
            REMAINING: 0,
            WAITING_FOR_APPROVAL: 0,
        },
        weeks: {
            APPROVED: [],
            REJECTED: [],
            REMAINING: [],
            WAITING_FOR_APPROVAL: [],
            ESTIMATED: [],
        },
        months: {
            APPROVED: [],
            REJECTED: [],
            REMAINING: [],
            WAITING_FOR_APPROVAL: [],
            ESTIMATED: [],
        },
        upcomingEvents: [],
        resources: [],
        resourcesLoaded: false,
    },
    sidebarStatistics: {
        totalRequestedDays: 0,
        totalRejectedDays: 0,
        totalApprovedDays: 0,
    },
    filteredBookings: [],
    tableActive: false,
    requestModel: {
        start: moment
            .utc()
            .startOf('year')
            .format('YYYY-MM-DD'),
        end: moment
            .utc()
            .endOf('year')
            .format('YYYY-MM-DD'),
        filter: 'ALL',
        dayCount: 365,
        chartType: 'months',
        isVacation: true,
        isApeprover: false,
        flow: '',
        subFlow: 'DASHBOARD',
        loggedUserIsCreator: false,
        resourceId: '',
    },
};

export default function requestReducer(state = fromJS(initialRequestState), action) {
    let storageDates;
    let dateRanges;
    let rangeExist;

    switch (action.type) {
        case actionTypes.DELETE_BOOKING['REQUEST']:
            return state.update('filteredBookings', bookings => {
                return bookings.map(booking => {
                    if (booking._id === action.payload.bookingId) {
                        return {
                            ...booking,
                            deleted: true,
                        };
                    }
                    return booking;
                });
            });
        case actionTypes.DELETE_BOOKING['FAILURE']:
            return state.update('filteredBookings', bookings => {
                return bookings.map(booking => {
                    if (booking._id === action.payload.bookingId) {
                        return {
                            ...booking,
                            deleted: false,
                        };
                    }
                    return booking;
                });
            });
        case actionTypes.UPDATE_STATISTICS:
            each(keys(action.payload.data), item => {
                state = state.updateIn(['statistics', item], () => fromJS(action.payload.data[item]));
            });

            state = !state.getIn(['statistics', 'total', 'REMAINING'])
                ? state.updateIn(['statistics', 'total', 'REMAINING'], () => 0)
                : state;
            state = !state.getIn(['statistics', 'weeks', 'ESTIMATED'])
                ? state.updateIn(['statistics', 'weeks', 'ESTIMATED'], () => fromJS([]))
                : state;
            state = !state.getIn(['statistics', 'weeks', 'REMAINING'])
                ? state.updateIn(['statistics', 'weeks', 'REMAINING'], () => fromJS([]))
                : state;
            state = !state.getIn(['statistics', 'months', 'ESTIMATED'])
                ? state.updateIn(['statistics', 'months', 'ESTIMATED'], () => fromJS([]))
                : state;
            state = !state.getIn(['statistics', 'months', 'REMAINING'])
                ? state.updateIn(['statistics', 'months', 'REMAINING'], () => fromJS([]))
                : state;

            return state
                .update('filteredBookings', () => action.payload.filteredBookings || [])
                .updateIn(['statistics', 'resources'], () => fromJS(action.payload.resources || []))
                .updateIn(['statistics', 'resourcesLoaded'], () => true);
        case actionTypes.RESET_STATISTICS:
            return state.update('statistics', () => fromJS(initialRequestState.statistics));
        case actionTypes.UPDATE_SIDEBAR_STATISTICS:
            return state
                .updateIn(['sidebarStatistics', 'totalRequestedDays'], () => action.payload.data.WAITING_FOR_APPROVAL)
                .updateIn(['sidebarStatistics', 'totalRejectedDays'], () => action.payload.data.REJECTED)
                .updateIn(['sidebarStatistics', 'totalApprovedDays'], () => action.payload.data.APPROVED);
        case actionTypes.UPDATE_REQUEST_MODEL:
            if (
                action.payload.requestModel &&
                action.payload.requestModel.flow &&
                state.getIn(['requestModel', 'flow']) !== action.payload.requestModel.flow
            ) {
                state = state
                    .updateIn(['requestModel', 'chartType'], () => 'months')
                    .updateIn(['statistics'], () => fromJS(initialRequestState.statistics));
            }
            each(keys(action.payload.requestModel), item => {
                state = state.updateIn(['requestModel', item], () => action.payload.requestModel[item]);
            });

            if ('TABLE' === state.getIn(['requestModel', 'subFlow'])) {
                state = state.update('tableActive', () => true);
            } else if ('DASHBOARD' === state.getIn(['requestModel', 'subFlow'])) {
                state = state.update('tableActive', () => false);
            }

            storageDates = StorageUtil.getItem('request');
            dateRanges = getDateRanges(state.getIn(['requestModel', 'start']));

            if (storageDates) {
                rangeExist = findRangeByStartEndDates(dateRanges, storageDates.start, storageDates.end);
                if (rangeExist) {
                    state = state
                        .updateIn(['requestModel', 'start'], () => storageDates.start)
                        .updateIn(['requestModel', 'end'], () => storageDates.end);
                }
            }

            return state;
        default:
            return state;
    }
}
