import DateFilterBox from './../../components/tableFilters/dateFilterBox';
import { connect } from 'react-redux';

import { updateQueryParams } from 'actions/reportActions';

export default connect(
    state => ({
        queryParams: state.reportReducer.queryParams,
    }),
    dispatch => ({
        updateQueryParams: (queryParams, storageItemName) =>
            dispatch(updateQueryParams.request(queryParams, undefined, false, storageItemName)),
    })
)(DateFilterBox);
