import Schema from 'form-schema-validation';
import { includes } from 'lodash';
import { errorMessages } from 'utils/schemaUtil';
import { formatDate } from 'utils/DateUtil';
import { dynamicRequireValidator, maxValidator, minValidator } from 'utils/formValidators';
import { directions, moveTypes } from './data';

export const formSchema = new Schema(
    {
        date: {
            type: Date,
            defaultValue: new Date(),
            validators: [dynamicRequireValidator('moveType', 'DATE', 'date')],
        },
        action: {
            type: Object,
            required: true,
        },
        numberOfDays: {
            type: Number,
            required: true,
            defaultValue: 1,
            validators: [maxValidator(356), minValidator(1)],
        },
        direction: {
            type: String,
            required: true,
            defaultValue: directions()[0].value,
        },
        moveType: {
            type: String,
            required: true,
            defaultValue: moveTypes()[0].value,
        },
        resource: {
            type: Object,
            validators: [
                dynamicRequireValidator(
                    'action.value',
                    ['moveBookingsBetweenResources', 'moveProjectBookingsBetweenResources'],
                    'resourceId'
                ),
            ],
            defaultValue: null,
        },
    },
    errorMessages,
    false
);

export const mapFormToRequest = (values, action, currentResourceId) => {
    const timeLineShiftEndPoints = ['shiftProjectResourceBookings', 'shiftResourceBookings', 'shiftProjectBookings'];

    return {
        shiftDays: includes(timeLineShiftEndPoints, action)
            ? values.numberOfDays * ('BACKWARD' === values.direction ? -1 : 1)
            : undefined,
        dateFrom: 'DATE' === values.moveType && values.date ? formatDate(values.date, 'MM-dd-yyyy') : undefined,
        resourceTo: includes(timeLineShiftEndPoints, action) ? undefined : values.resource?._id,
        resourceFrom: includes(timeLineShiftEndPoints, action) ? undefined : currentResourceId,
    };
};
