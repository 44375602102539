import { values } from 'lodash';

export const PARENT = {
    value: 'PARENT',
    label: () => 'Grouped rows',
    image: 'https://d329beqc2zk6g7.cloudfront.net/img/Grouped_rows_icon.png',
    description: ({ projectKeyWord, resourceKeyWord }) =>
        `Group and combine the ${projectKeyWord.toLowerCase()} and ${resourceKeyWord.toLowerCase()} rows in a collapsible group level parent -> child relationship.`,
};

export const SINGLE = {
    value: 'SINGLE',
    label: ({ projectKeyWord, resourceKeyWord }) =>
        `Single rows (${projectKeyWord.toLowerCase()} & ${resourceKeyWord.toLowerCase()})`,
    image: 'https://d329beqc2zk6g7.cloudfront.net/img/Project_resource_icon.png',
    description: ({ projectKeyWord, resourceKeyWord }) =>
        `A ${projectKeyWord.toLowerCase()} or ${resourceKeyWord.toLowerCase()} is represented as a single row. All bookings will appear on 1 row to help resolve booking conflicts.`,
};

export const LEGACY = {
    value: 'LEGACY',
    label: ({ resourceKeyWord }) => `Single rows (${resourceKeyWord.toLowerCase()})`,
    image: 'https://d329beqc2zk6g7.cloudfront.net/img/Resource_rows_icon.png',
    description: ({ resourceKeyWord }) =>
        `All rows are ${resourceKeyWord.toLowerCase()} rows. All bookings will appear on 1 row to help resolve booking conflicts.`,
};

export const viewModesTypes = { LEGACY, PARENT, SINGLE };

export const viewModesArray = values(viewModesTypes);
