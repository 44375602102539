import { TYPE_UNASSIGNED } from 'enums/resourceEnum';

export const resourceObject = (resource, contextMenu, additionalParams) => {
    const loadProjects = additionalParams && additionalParams.loadProjects;
    const dpMenu = contextMenu && contextMenu(resource);
    const areas = dpMenu?.items?.length
        ? [
              {
                  visibility: 'Visible',
                  action: 'ContextMenu',
                  bottom: 0,
                  width: 30,
                  html: `<i data-cy="scheduler-row-resource-menu-${resource?._id ||
                      ''}" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                  cssClass: 'resource-action-menu',
                  top: 0,
                  menu: dpMenu,
                  right: 0,
              },
          ]
        : [];

    return {
        role: resource.role,
        name: resource.name,
        id: resource._id,
        tags: {
            type: resource.type,
            parent: loadProjects,
            isEventRow: false,
            menuRow: false,
            resource,
            isProjectRow: false,
            isResourceRow: TYPE_UNASSIGNED.value !== resource.type,
            unassignedRow: TYPE_UNASSIGNED.value === resource.type,
        },
        isParent: loadProjects,
        eventHeight: 0,
        marginBottom: 10,
        minHeight: loadProjects ? 26 : 36,
        cssClass: 'parent-scheduler-row',
        areas,
    };
};
