/*
    IMPORTANT
    All code in this file MUST be documented
*/
import { HPValidationError } from 'shared/lib/errors';

/**
 * Returns ISO 8601 representation of the date, with some options available.
 * includeTimezone will include Z at the end, and computeTimezone will not return
 * in the default UTC representation
 * @param {Date} date Date instance
 * @param {Object} options { includeTimezone: Boolean, computeTimezone: Boolean }
 * @returns {string} the ISO 8601 representation of the date
 */
export const getISODate = (date, options = {}) => {
    if (!(date instanceof Date)) {
        throw new HPValidationError('getISODate: date is not a Date instance');
    }

    let newDate = date;
    const { includeTimezone, computeTimezone } = options;

    if (computeTimezone) {
        // Add UTC timezone
        newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000 * -1);
    }

    if (includeTimezone) {
        return newDate.toISOString();
    }

    return newDate
        .toISOString()
        .split('.')[0]
        .replace('T', ' ');
};
