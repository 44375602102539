import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import classNames from 'classnames';
import InfoHelpTooltip from 'shared/Tooltip';
import { ConditionBtn } from './styles';

const FilterCondition = props => {
    const { onChange, isSet, dataCy, disabled } = props;
    return (
        <ButtonGroup>
            <InfoHelpTooltip
                useChildren
                removePadding
                description={disabled ? `You can't edit filter which was deleted` : 'Is equal to'}
            >
                <ConditionBtn
                    className={classNames({ disabled: undefined === isSet || disabled })}
                    size="sm"
                    onClick={() => onChange(true)}
                    active={true === isSet}
                    data-cy={`${dataCy || ''}-is-equal-to`}
                >
                    =
                </ConditionBtn>
            </InfoHelpTooltip>
            <InfoHelpTooltip
                useChildren
                removePadding
                description={disabled ? `You can't edit filter which was deleted` : 'Is not equal to'}
            >
                <ConditionBtn
                    className={classNames({ disabled: undefined === isSet || disabled })}
                    size="sm"
                    onClick={() => onChange(false)}
                    active={false === isSet}
                    data-cy={`${dataCy || ''}-is-not-equal-to`}
                >
                    ≠
                </ConditionBtn>
            </InfoHelpTooltip>
        </ButtonGroup>
    );
};

FilterCondition.propTypes = {
    onChange: PropTypes.func.isRequired,
    isSet: PropTypes.bool,
};

export default FilterCondition;
