import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { textAndImageRowPropTypes, TextAndImageRow } from './TextAndImageRow';
import { faqPropTypes, Faq } from './Faq';
import { relatedExtensionsPropTypes, RelatedExtensions } from './RelatedExtensions';
import { bulletListPropTypes, BulletList } from './BulletList';
import PropTypes from 'prop-types';
import { TextAndImagesListRow } from './TextAndImagesListRow';
import { HeroImageRow } from './HeroImageRow';

const TextImageTypeWrapper = React.memo(props => {
    const {
        podProps: { title, titleHeading, description, additionalInfo, buttons, imageSrc, imageLeft, imageBottom },
        onClickLinks,
    } = props;
    return (
        <>
            <TextAndImageRow
                title={title}
                titleHeading={titleHeading}
                description={description}
                additionalInfo={additionalInfo}
                buttons={buttons}
                imageSrc={imageSrc}
                imageLeft={imageLeft}
                imageBottom={imageBottom}
                onClickLinks={onClickLinks}
            />
        </>
    );
});
const HeroImageTypeWrapper = React.memo(props => {
    const {
        podProps: { title, titleHeading, description, additionalInfo, buttons, imageSrc, imageLeft, imageBottom },
        onClickLinks,
    } = props;
    return (
        <>
            <HeroImageRow
                title={title}
                titleHeading={titleHeading}
                description={description}
                additionalInfo={additionalInfo}
                buttons={buttons}
                imageSrc={imageSrc}
                imageLeft={imageLeft}
                imageBottom={imageBottom}
                onClickLinks={onClickLinks}
            />
        </>
    );
});

const TextImagesListTypeWrapper = React.memo(props => {
    const {
        podProps: { title, titleHeading, description, additionalInfo, images },
        onClickLinks,
    } = props;
    return (
        <TextAndImagesListRow
            title={title}
            titleHeading={titleHeading}
            description={description}
            additionalInfo={additionalInfo}
            images={images}
            onClickLinks={onClickLinks}
        />
    );
});

const RelatedTypeWrapper = React.memo(props => {
    const {
        podProps: { elements },
    } = props;
    return <RelatedExtensions elements={elements} />;
});

const FaqTypeWrapper = React.memo(props => {
    const {
        podProps: { title, subTitle, elements },
    } = props;
    return <Faq title={title} subtitle={subTitle} elements={elements} />;
});

const ListTypeWrapper = React.memo(props => {
    const {
        podProps: { title, description, elements, note, subtitle },
    } = props;
    return <BulletList title={title} description={description} elements={elements} note={note} subtitle={subtitle} />;
});

export const TYPE_TEXT_IMAGE = 'TYPE_TEXT_IMAGE';
export const TYPE_HERO_IMAGE = 'TYPE_HERO_IMAGE';
export const TYPE_TEXT_IMAGES_LIST = 'TYPE_TEXT_IMAGES_LIST';
export const TYPE_RELATED = 'TYPE_RELATED';
export const TYPE_FAQ = 'TYPE_FAQ';
export const TYPE_LIST = 'TYPE_LIST';

export const podsTypes = {
    [TYPE_TEXT_IMAGE]: TYPE_TEXT_IMAGE,
    [TYPE_HERO_IMAGE]: TYPE_HERO_IMAGE,
    [TYPE_TEXT_IMAGES_LIST]: TYPE_TEXT_IMAGES_LIST,
    [TYPE_RELATED]: TYPE_RELATED,
    [TYPE_FAQ]: TYPE_FAQ,
    [TYPE_LIST]: TYPE_LIST,
};

const podsTypesMapComponents = {
    [TYPE_TEXT_IMAGE]: TextImageTypeWrapper,
    [TYPE_HERO_IMAGE]: HeroImageTypeWrapper,
    [TYPE_TEXT_IMAGES_LIST]: TextImagesListTypeWrapper,
    [TYPE_RELATED]: RelatedTypeWrapper,
    [TYPE_FAQ]: FaqTypeWrapper,
    [TYPE_LIST]: ListTypeWrapper,
};

const useStyles = makeStyles({
    ExtensionTemplate_main: {
        width: '100%',
        maxWidth: '960px',
        paddingTop: '60px',
    },
    ExtensionTemplate_section: {
        margin: '80px 0',
        textAlign: 'left',
        lineHeight: '23px',
        letterSpacing: '0.2px',
    },
});

export const ExtensionTemplate = React.memo(props => {
    const { elements, onClickLinks } = props;
    const classes = useStyles();

    if (!Array.isArray(elements) || elements.length === 0) {
        return null;
    }

    const sortedElements = elements
        .map(el => el)
        .sort((element1, element2) => {
            return element1.order < element2.order ? -1 : 1;
        });

    return (
        <div className={classes.ExtensionTemplate_main}>
            {sortedElements.map((element, index) => {
                const { type, content } = element;
                if (podsTypesMapComponents[type]) {
                    const Component = podsTypesMapComponents[type];
                    const sameTypeNext = sortedElements[index + 1] && sortedElements[index + 1]?.type === type;
                    return (
                        <div key={index} className={classes.ExtensionTemplate_section}>
                            <Component podProps={content} sameTypeNext={sameTypeNext} onClickLinks={onClickLinks} />
                        </div>
                    );
                }
                return null;
            })}
        </div>
    );
});

export const extensionTemplatePropTypes = {
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(podsTypes))),
            order: PropTypes.number,
            content: PropTypes.oneOf([
                textAndImageRowPropTypes,
                faqPropTypes,
                relatedExtensionsPropTypes,
                bulletListPropTypes,
            ]),
        })
    ),
    onClickLinks: PropTypes.func,
};
ExtensionTemplate.propTypes = extensionTemplatePropTypes;

export default ExtensionTemplate;
