import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    HeroImageRow_main: {
        display: 'flex',
        width: '100%',
        fontSize: '14px',
        flexDirection: props => {
            if (props.imageBottom) {
                return 'column';
            } else {
                return 'row';
            }
        },
    },
    HeroImageRow_image: {
        textAlign: 'center',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    HeroImageRow_img: {
        width: '100%',
        maxWidth: 'fit-content',
        borderRadius: '30px',
    },
});

export const HeroImageRow = React.memo(props => {
    const { imageSrc } = props;
    const classes = useStyles(props);

    if (!imageSrc) {
        return null;
    }

    return (
        <div className={classes.HeroImageRow_main}>
            <div className={classes.HeroImageRow_image}>
                <img className={classes.HeroImageRow_img} src={imageSrc} />
            </div>
        </div>
    );
});

export const heroImageRowPropTypes = {
    imageSrc: PropTypes.string.isRequired,
};
HeroImageRow.propTypes = heroImageRowPropTypes;

export default HeroImageRow;
