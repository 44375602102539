export const ACTIVE = 'ACTIVE';
export const UNASSIGNED = 'UNASSIGNED';
export const ARCHIVED = 'ARCHIVED';
export const EVENT = 'EVENT';
export const FLOATING = 'FLOATING';
export const PENDING = 'PENDING';
export const PLANNED = 'PLANNED';
export const MY_PROJECTS = 'MY_PROJECTS';
export const MY_SCHEDULE = 'MY_SCHEDULE';
export const MY_MANAGED_PROJECTS = 'MY_MANAGED_PROJECTS';
export const MY_MANAGED_RESOURCES = 'MY_MANAGED_RESOURCES';
export const SCHEDULE_TEMPLATE_AVAILABLE = 'SCHEDULE_TEMPLATE_AVAILABLE';
export const SCHEDULE_TEMPLATE_FULLY_BOOKED = 'SCHEDULE_TEMPLATE_FULLY_BOOKED';
export const SCHEDULE_TEMPLATE_OVER_UTILIZED = 'SCHEDULE_TEMPLATE_OVER_UTILIZED';
export const SCHEDULE_TEMPLATE_UNDER_UTILIZED = 'SCHEDULE_TEMPLATE_UNDER_UTILIZED';
