import styled from 'styled-components';
import { ListGroupItem, NavLink, Button } from 'reactstrap';

export const Title = styled.h5`
    margin-top: 0.6rem;
    text-transform: uppercase;
    font-size: 15px;
    margin-bottom: 2px;
    text-align: center;
    font-family: 'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    letter-spacing: 1.1px;
    color: #3e3e3e;
`;

export const FilterTabItem = styled(NavLink)`
    text-transform: capitalize;
    position: relative;
    border: none !important;
    color: #000;

    &:hover:not(.active) {
        cursor: pointer;
        border: none !important;
        opacity: 0.6;
        color: #000;
    }

    &.filter-active {
        border: none;

        &:after {
            content: ' ';
            background-color: #0288b0;
            border-radius: 50%;
            width: 9px;
            height: 9px;
            font-size: 11px;
            position: absolute;
            top: calc(50% - 5px);
            right: 15px;
        }
    }
`;

export const FilterTabHeaders = styled(NavLink)`
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #424242 !important;
    background-color: #f5f5f5;
    display: flex !important;
    border: none !important;
    margin-top: 40px;

    div {
        font-weight: 400;
        text-transform: none;
        font-size: 11px;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 0;
        line-height: 22px;
    }

    svg {
        margin-right: 8px;
    }

    &:hover {
        border: none !important;
    }
`;

export const ListItemText = styled.div`
    color: #000;

    &.dots {
        width: calc(100% - 80px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: ${props => (props.center ? '7px' : 'inherit')};
    }
`;

export const HelpText = styled.div`
    color: #999 !important;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 1rem;
    text-align: center;

    .emphasis-help-text {
        background-color: #dadada;
        color: #313131;
        padding: 1px 5px;
        font-size: 12px;
        border: 1px solid #c1c1c1;
        border-radius: 3px;
    }
`;

export const IconWrapper = styled.div`
    color: #0288b0;
    margin-right: 8px;
    margin-top: 8px;
`;

export const ListItemSubText = styled.div`
    clear: both;
    font-size: 11px;
    color: #8c8c8c;

    &.active-column:after {
        content: 'Active Split Column';
        font-size: 10px;
        background-color: #3e773b;
        color: #fff;
        padding: 3px 5px;
        border-radius: 2px;
        margin-top: 3px;
        display: block;
        width: 100px;
    }
`;

export const Column = styled.div`
    height: 130px;
    padding: 20px;
    width: 100px;
    text-align: center;
    background-color: #e2e2e2;
    text-transform: capitalize;
    font-size: 13px;
    line-height: 18px;
    border-radius: 3px;
    border-top: 5px solid #9c9a9a;
    color: #696969;
`;

export const ListGroupElement = styled(ListGroupItem)`
    background: inherit !important;
    margin-right: 8px;
    border-right: none !important;
    border-left: none !important;

    .right-icon {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        border-left: 1px solid #ebebeb;
        color: #464646;
        cursor: pointer;
        text-align: center;
        width: 60px;
        font-size: 11px;

        &.vertical-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &:hover {
            color: #fff;
            background-color: #3e687b;
            border-radius: 4px;
        }

        &.remove:hover {
            background-color: #c55151;
        }

        svg {
            display: block;
            margin: 0 auto;
            font-size: 20px;
        }
    }
`;

export const ListItem = styled(ListGroupItem)`
    .form-text {
        clear: both;
    }

    &.active {
        .form-text {
            color: #fff !important;
        }
    }

    &.selected:after {
        content: ' ';
        background-color: #0288b0;
        border-radius: 50%;
        width: 9px;
        height: 9px;
        font-size: 11px;
        position: absolute;
        top: calc(50% - 3px);
        right: 15px;
    }

    border-bottom: 1px solid #dee2e6 !important;
    border-radius: 0 !important;
`;

export const ConditionBtn = styled(Button)`
    line-height: 10px !important;
    min-width: 34px;
    font-size: 18px !important;
    font-weight: 500 !important;
    color: #d2d2d2 !important;

    &:hover {
        color: #fff !important;
    }
`;
