import { axiosInstance } from './axiosInstance';
import { stringify } from 'services/queryString';

export const getBookings = filters =>
    axiosInstance
        .post('event/getAllBookings', filters)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const getBooking = bookingId =>
    axiosInstance
        .get(`event/${bookingId}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

//ToDo used for vacation/request page. Needs to be switch to getBookings function
export const getAll = filters =>
    axiosInstance
        .get(`event?${filters ? stringify(filters) : ''}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const updateBooking = (id, { booking, options } = { options: {} }) =>
    axiosInstance
        .patch(
            `event/v2/${id}`,
            { booking, options },
            {
                params: options,
            }
        )
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const updateMultipleBookings = bookings =>
    axiosInstance
        .put(`event/`, bookings)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const bulkUpdateBookings = ({ bookings, options = {} } = {}) =>
    axiosInstance
        .patch(
            `event/v2/bulkUpdate`,
            { bookings, options },
            {
                params: {
                    allowOverschedule: options.allowOverschedule,
                    modifyAllRepeated: options.modifyAllRepeated,
                    removeRemovedRepeated: options.removeRemovedRepeated,
                    createRepeatFromExisting: options.createRepeatFromExisting,
                    skipDependencyDateValidation: options.skipDependencyDateValidation,
                    moveRelatedRequests: options.moveRelatedRequests,
                    bookingAfterDeadlineConfirmation: options.bookingAfterDeadlineConfirmation,
                    moveDeadline: options.moveDeadline,
                },
            }
        )
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const duplicateBooking = (bookingId, options) =>
    axiosInstance
        .put(`event/v2/duplicate/${bookingId}`, { options })
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const createBookings = bookings =>
    axiosInstance
        .post('event/v2/create', bookings)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteBooking = bookingId =>
    axiosInstance
        .delete(`event/${bookingId}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteBookings = bookingIds =>
    axiosInstance
        .delete(`event/v2/bulkDelete`, { data: bookingIds })
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteFutureRepeatBookings = (repeatId, date) =>
    axiosInstance
        .delete(`event/repeat/fromdate/${repeatId}/${date}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteAllRepeatBookings = repeatId =>
    axiosInstance
        .delete(`event/repeat/${repeatId}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const shiftProjectResourceBookings = (projectId, resourceId, data) =>
    axiosInstance
        .patch(`event/shift-days/project/${projectId}/resource/${resourceId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const shiftResourceBookings = (resourceId, data) =>
    axiosInstance
        .patch(`event/shift-days/resource/${resourceId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const shiftProjectBookings = (projectId, data) =>
    axiosInstance
        .patch(`event/shift-days/project/${projectId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const moveProjectBookingsBetweenResources = (projectId, data) =>
    axiosInstance
        .patch(`event/move-bookings/project/${projectId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const moveBookingsBetweenResources = data =>
    axiosInstance
        .patch('event/move-bookings', data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const bulkCreateBookings = data =>
    axiosInstance
        .post('event/multiplyEvent', data)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const splitBooking = ({ bookingId, splitDate, options }) =>
    axiosInstance
        .post(
            `event/${bookingId}/split`,
            {
                splitDate,
            },
            {
                params: options,
            }
        )
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
