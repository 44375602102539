import React, { useCallback } from 'react';
import { TOIL_ADJUSTMENT_TYPE_ENUM } from '../../../../toil/enums';
import { normalizeFloatNumber } from '../../../../../utils/formUtil';
import { Layout, DatePickerInput, NumberInput, IconButton } from '@hub-mono/ui';
import styled from 'styled-components';

const StyledRow = styled(Layout)`
    max-width: 480px;
`;

export const AdjustmentRow = React.memo(({ adjustment, index, onDelete, onChange }) => {
    const { effectiveDate, value } = adjustment;

    const handleDateChange = useCallback(
        newDate => {
            onChange({ ...adjustment, effectiveDate: newDate });
        },
        [adjustment, onChange]
    );

    const handleValueChange = useCallback(
        event => {
            onChange({ ...adjustment, value: normalizeFloatNumber(event.target.value) });
        },
        [adjustment, onChange]
    );

    const handleDeleteClick = useCallback(() => {
        onDelete(adjustment);
    }, [adjustment, onDelete]);

    return (
        <StyledRow gap="--Spacing-100" hAlign="flex-start" pb={12}>
            <Layout flex="0 1 40%" vAlign="center">
                <DatePickerInput
                    name={`${index}-adjustment-date-input`}
                    dataCy={`adjustment-${index}-date`}
                    value={effectiveDate}
                    onChange={handleDateChange}
                    label="Effective date"
                />
            </Layout>
            <Layout flex="0 1 40%" vAlign="center">
                <NumberInput
                    name={`${index}-adjustment-value-input`}
                    value={value}
                    onChange={handleValueChange}
                    dataCy={`adjustment-${index}-value`}
                    label={
                        adjustment.type === TOIL_ADJUSTMENT_TYPE_ENUM.MANUAL ? 'Manual Adjustment' : 'Starting Balance'
                    }
                />
            </Layout>
            <Layout vAlign="center">
                <IconButton icon="delete" onClick={handleDeleteClick} dataCy={`adjustment-${index}-delete-btn`} />
            </Layout>
        </StyledRow>
    );
});
