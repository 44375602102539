import { sumBy } from 'lodash';

export const calculateGroupCount = (resourceGroup, subGroups, uniqueSubgroupsCount) => {
    if (uniqueSubgroupsCount) {
        const subGroupsItems = subGroups.reduce((acc, { resources }) => [...acc, ...resources], []);
        return new Set([...resourceGroup.resources, ...subGroupsItems]).size;
    }

    return resourceGroup.count + sumBy(subGroups, subGroup => subGroup.count);
};

export const getScheduleSystemGroupPath = (scheduleSystemGroups, criteria) => {
    return (scheduleSystemGroups || []).find(schedule => schedule.criteria === criteria)?.to || '';
};
