import { DEFAULT, CUSTOM } from 'enums/categoryGroupTypeEnum';

export const mapCategoryTemplateFormDataToRequest = data => ({
    name: data.name,
    gridColor: data.gridColor,
    state: data.state,
    percentAllocation: data.percentage,
    totalBucketMinutesAllocation: data.hours * 60,
    minutesPerDay: data.total * 60,
    type: data.isDefault ? DEFAULT : CUSTOM,
    groupId: data.categoryGroup?._id,
});
