import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, Button } from 'reactstrap';
import { multiSelectField } from 'shared/formFields';
import CreateCustomerForm from './create';

class Customer extends PureComponent {
    static propTypes = {
        customers: PropTypes.array.isRequired,
        formName: PropTypes.string,
        fieldName: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = { isFormOpen: false };
    }

    toggleForm = () => this.setState(state => ({ isFormOpen: !state.isFormOpen }));

    render() {
        const { isFormOpen } = this.state;
        const { customers, formName, fieldName } = this.props;

        return (
            <section className="form-fields">
                {isFormOpen ? (
                    <CreateCustomerForm formName={formName} fieldName={fieldName} onDismiss={this.toggleForm} />
                ) : (
                    <div>
                        <p className="title">Select Client:</p>
                        <Field
                            inline
                            width="col-md-12"
                            name="customers"
                            component={multiSelectField}
                            filter="contains"
                            valueField="_id"
                            textField={item => (Object.keys(item).length ? item.name : '')}
                            data={customers}
                        />
                        <FormText>
                            Click above to choose a Client&nbsp;
                            <Button onClick={this.toggleForm} className="xs p-0" color="link">
                                Add new Client
                            </Button>
                        </FormText>
                    </div>
                )}
            </section>
        );
    }
}

export default Customer;
