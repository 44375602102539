import { sub, set, format, addMinutes } from 'date-fns';
import { DayPilot } from 'daypilot-pro-react';
import { CELLDURATION, MONTH } from 'modules/scheduler/enums/scale';
import { getNewProjectId, getNewResourceId } from '../utils';
import { getRowId, getEventText } from '../../../utils/eventUtil';
import { getISODate } from 'shared/lib/date';
import { cloneDeep, map } from 'lodash';
import { store } from '../../../../../store';
import { selectMappedResourceGroups } from '../../../../../selectors/resourceGroup';
import { selectCompanyApprovers } from '../../../../../selectors/company';
import { getFormDefaultApprovers } from '../../../../request/utils/getFormDefaultApprovers';
import { makeResourceById } from '../../../../../selectors/resource';
import { WAITING_FOR_APPROVAL } from '../../../../../enums/bookingTypeEnum';
import { formatDate } from '../../../../../utils/DateUtil';
import { BOOKING_FORMAT } from '../../../../../global/enums/dateFormat';
import { handleDeadlineMove } from '../onEventMove/deadlines';
import { handleBookingMove } from '../onEventMove/booking';
import { handleMultiMove } from '../onEventMove/multimove';

export default (args, companyWeekdays, viewObject, isDependencyExtensionInstalled) => {
    const reduxState = store.getState();
    const approvers = selectCompanyApprovers(reduxState);
    const mappedResourceGroups = selectMappedResourceGroups(reduxState);

    if (1 < args.multimove.length) {
        handleMultiMove({ args, companyWeekdays, viewObject, isDependencyExtensionInstalled });
    } else {
        if (args.e.data.deadline) {
            handleDeadlineMove({ args });
        } else {
            handleBookingMove({ args, companyWeekdays, viewObject, reduxState, approvers, mappedResourceGroups });
        }
    }
};

export const getNewApprovalInfo = ({ event, oldEvent, args, reduxState, approvers, mappedResourceGroups }) => {
    let result = {};

    if (event.type === WAITING_FOR_APPROVAL.value && event.resourceInfo?._id !== oldEvent.resourceInfo?._id) {
        const r = makeResourceById(args.newResource)(reduxState);

        let defaultApprovers = getFormDefaultApprovers({
            approvers,
            resource: r,
            mappedResourceGroups,
        });

        const newRequestApprovers = map(defaultApprovers, approver => ({ id: approver._id }));
        result.approvalInfo = {
            approvers: newRequestApprovers,
            requesterNote: event.approvalInfo.requesterNote,
        };
    }

    return result;
};

export const prepareEvent = (args, companyWeekdays, viewObject, scheduler, destinationRow) => {
    const newProjectId = getNewProjectId(args, destinationRow, scheduler.mode, viewObject.isProjectView);
    const newResourceId = getNewResourceId(args, destinationRow, scheduler.mode, viewObject.isProjectView);

    const oldEvent = { ...args.e.data, resource: args.e.data.resourceInfo._id };
    const event = cloneDeep(args.e.copy());
    event.project._id = newProjectId;
    event.resourceInfo._id = newResourceId;
    event.resource = {
        _id: newResourceId,
    };
    setStartEnd(args, companyWeekdays, event);
    const text = getEventText(args.e.data.title, event.project, event.resourceInfo, {
        mode: scheduler.mode,
        viewObject,
    });

    return {
        event: {
            ...event,
            text,
            project: {
                ...event.project,
                _id: newProjectId,
            },
            repeat: event.repeat,
            repeatId: event.repeatId,
            repeatTimes: event.repeatTimes,
            resource: getRowId(event, scheduler.mode, viewObject),
            resourceInfo: {
                ...event.resourceInfo,
                _id: newResourceId,
            },
            start: event.start,
            end: event.end,
            deadlineDate: event.deadlineDate?.value
                ? formatDate(event.deadlineDate.value, BOOKING_FORMAT, false)
                : event.deadlineDate,
            id: event.id,
        },
        oldEvent,
    };
};

function setStartEnd(args, companyWeekdays, event) {
    const currentScale = args.control.scale;
    let start = args.newStart.toDate();
    let end = args.newEnd.toDate();

    if (start.getTimezoneOffset() !== 0) {
        start = addMinutes(start, start.getTimezoneOffset());
        end = addMinutes(end, end.getTimezoneOffset());
    }

    if (currentScale !== CELLDURATION.value && event.scale.value !== CELLDURATION.value) {
        // For 24 hours working days
        if (currentScale === MONTH.value && 0 === args.newEnd.getHours() && args.newEnd > args.e.end()) {
            end = sub(end, { days: 1 });
        }

        const dayOfWeek = format(end, 'iiii').toLowerCase();
        const workDay = companyWeekdays[dayOfWeek];

        start = set(start, {
            hours: workDay.startDay,
            minutes: workDay.startMinute,
        });
        end = set(end, {
            hours: workDay.endDay,
            minutes: workDay.endMinute,
        });
    }

    // These are from DP, with added TimeZone, so send UTC
    event.start = new DayPilot.Date(
        getISODate(start, {
            computeTimezone: true,
        })
    );
    event.end = new DayPilot.Date(
        getISODate(end, {
            computeTimezone: true,
        })
    );
}
