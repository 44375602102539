import { updateDeadlinesVisibility, updateVisibleLinksOnScheduler } from '../../utils/schedulerUtil';

export default (showScheduleContextMenu, scheduler) => args => {
    document.activeElement?.blur();

    const cell = scheduler.current.control.cells.find(args.start, args.resource);
    const foundCell = cell.first();

    if (foundCell?.properties?.tags?.hasRights || foundCell?.properties?.tags?.hasProjectParentRowRights) {
        showScheduleContextMenu(foundCell.i, scheduler);
    }

    updateDeadlinesVisibility();
    updateVisibleLinksOnScheduler();
};
