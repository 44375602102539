import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import InfoBar from './InfoBar';
import { store } from '../../store';
import { deleteProject } from 'actions/projectActions';

export const eventsStepKey = 'events';

const uwStepRowElements = [
    {
        name: 'name',
        type: 'text',
        label: 'Event type',
        placeholder: 'E.g. Vacation, Sick Leave',
        validator: emptyStringValidator,
        minWidth: '250px',
    },
];

export const EventsOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteProject.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    const onResetChangesOnCreatedInt = useCallback(
        rowIndex => {
            onResetChangesOnCreated(rowIndex, {
                name: 'name',
            });
        },
        [onResetChangesOnCreated]
    );

    return (
        <div>
            <div className="mb-4">
                <InfoBar type="info">The events can be added later from the Hub Planner Scheduler level.</InfoBar>
            </div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={uwStepRowElements}
                getValue={key => storedData[`${eventsStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreatedInt}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${eventsStepKey}-meta-showValidationErrors`]}
                addMoreLabel="more events"
                objectName="event"
            />
            {storedData[`${eventsStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default EventsOnboardingStepContent;
