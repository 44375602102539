import * as actionTypes from './actionTypes';
import { action } from 'actions/base';

export const getCustomFieldsRequest = type => ({
    type: actionTypes.GET_CUSTOM_FIELDS['REQUEST'],
    payload: {
        type,
    },
});

export const getCustomFieldsSuccess = (customFields, type) => ({
    type: actionTypes.GET_CUSTOM_FIELDS['SUCCESS'],
    payload: {
        customFields,
        type,
    },
});

export const getAllCustomFields = {
    request: () => action(actionTypes.GET_ALL_CUSTOM_FIELDS['REQUEST']),
    success: customFields => action(actionTypes.GET_ALL_CUSTOM_FIELDS['SUCCESS'], { customFields }),
    failure: () => action(actionTypes.GET_ALL_CUSTOM_FIELDS['FAILURE']),
};

export const getAllCustomFieldsV2 = {
    request: () => action(actionTypes.GET_CUSTOM_FIELDS_V2['REQUEST']),
    success: customFields => action(actionTypes.GET_CUSTOM_FIELDS_V2['SUCCESS'], { customFields }),
    failure: () => action(actionTypes.GET_CUSTOM_FIELDS_V2['FAILURE']),
};
