import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const Validator = props => {
    const [isValid, setIsValid] = useState(true);
    const {
        computedMatch: { params },
        paramValidator,
    } = props;
    useEffect(() => {
        if (paramValidator) {
            setIsValid(paramValidator(params) || false);
        }
    }, [params, paramValidator]);

    if (props.computedMatch.params && !isValid) {
        return (
            <Redirect
                to={{
                    pathname: '/404',
                    state: { from: props.location },
                }}
            />
        );
    }

    return <Route {...props} />;
};

export default Validator;
