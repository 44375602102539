import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, initialize, submit, getFormValues } from 'redux-form';
import { useAllocation, useAppKeyWords, useForm } from 'hooks';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import * as templateContent from 'enums/templateItemsEnum';
import { RESOURCE_REQUEST } from 'enums/extensionShortIdEnum';
import { validateSchema } from 'utils/schemaUtil';
import { checkAccessByRolesAndExtensions } from 'utils/rightsUtil';
import { getDefaultCategoryByProject, getCategoryTemplatesByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { getAllocationFieldValuesByCategory } from 'utils/allocationUtil';
import { getDefaultAllocationValues } from 'modules/request/utils/requestUtil';
import { formSchema, mapFormToRequest, getDefaultValues } from 'modules/request/forms/requestResourceForm';
import NoExtension from 'modules/request/components/noExtension';
import CommonModalTemplate from 'shared/modal/commonModalTemplate';
import RequestBody from './requestModalBody';
import {
    makeGetFilteredCategoryTemplates,
    selectCategoryTemplatesLoaded,
} from '../../../../selectors/categoryTemplate';
import { selectMappedResourceGroups } from '../../../../selectors/resourceGroup';
import { put, select, takeLatest } from 'redux-saga/effects';
import { makeCategoryGroups, selectCategoryGroupsLoaded } from '../../../../selectors/categoryGroups';
import { waitFor } from '../../../../sagas/helpers/waitFor';
import { action } from '../../../../actions/base';
import { companyApprovers } from '../../../../actions/companyActions';
import {
    selectCompanyApprovers,
    selectCompanyApproversLoaded,
    selectCcProjectManagersOnRequestCompanyNotificationSetting,
    getCompanySettings,
} from '../../../../selectors/company';
import { getFormDefaultApprovers } from '../../utils/getFormDefaultApprovers';
import { useModalPressEnter } from '../../../../hooks/useModalPressEnter';
import { hideModal } from '../../../../actions/modalActions';

export const FORM_NAME = 'requestResourceForm';

const INIT_REQUEST_RESOURCE_FORM = 'INIT_REQUEST_RESOURCE_FORM';

function* handleFormInit(action) {
    const { state } = action.payload;

    const currentSelection = yield select(state => state.scheduler.currentSelection);
    const companySettings = yield select(getCompanySettings);

    const notifyPM = yield select(selectCcProjectManagersOnRequestCompanyNotificationSetting);
    const initialDefaultData = getDefaultValues({
        categoryGroups: [],
        categoryTemplates: [],
        state,
        currentSelection,
        notifyPM,
    });

    const startDate = currentSelection?.start || initialDefaultData.startDate;
    const endDate = currentSelection?.end || initialDefaultData.endDate;
    const resource = currentSelection?.rowTags?.resource || initialDefaultData.resource;
    const defaultAllocationValues = getDefaultAllocationValues(startDate, endDate, resource, companySettings);
    yield put(initialize(FORM_NAME, { ...initialDefaultData, ...defaultAllocationValues }));

    yield waitFor(selectCompanyApproversLoaded);
    yield waitFor(selectCategoryGroupsLoaded);
    yield waitFor(selectCategoryTemplatesLoaded);

    const mappedResourceGroups = yield select(selectMappedResourceGroups);
    const categoryGroups = yield select(makeCategoryGroups());
    const categoryTemplates = yield select(makeGetFilteredCategoryTemplates());
    const approvers = yield select(selectCompanyApprovers);

    const defaultApprovers = getFormDefaultApprovers({
        resource: currentSelection?.rowTags?.resource,
        approvers,
        mappedResourceGroups,
    });

    const initialData = getDefaultValues({ categoryGroups, categoryTemplates, state, currentSelection, notifyPM });
    initialData.approvers = defaultApprovers;

    yield put(initialize(FORM_NAME, { ...initialData, ...defaultAllocationValues }));
}

export function* schedulerRequestFormSaga() {
    yield takeLatest(INIT_REQUEST_RESOURCE_FORM, handleFormInit);
}

const RequestResourceModal = props => {
    const { onClose } = props;
    const dispatch = useDispatch();
    const { state } = useAllocation();
    const formValues = useSelector(getFormValues(FORM_NAME)) || {};
    const { startDate, endDate, resource, project } = formValues;
    const { resourceKeyWord, resourcePluralKeyWord, projectKeyWord } = useAppKeyWords();
    const [defaultApprovers, setDefaultApprovers] = useState([]);
    const account = useSelector(state => state.account);
    const categoryGroups = useSelector(state => state.categoryGroupReducer.categoryGroups);
    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const categoryTemplates = useSelector(categoryTemplatesSelector);
    const approvers = useSelector(selectCompanyApprovers);
    const { settings, extensions } = useSelector(state => state.companyReducer.company);
    const { useCategoriesAllocation, vacationId } = settings;
    const updateFields = useForm(FORM_NAME);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);

    const access = checkAccessByRolesAndExtensions(
        {
            allowedRoleRights: ['canRequestResource'],
            extensions: [RESOURCE_REQUEST],
            templateDataItem: {
                noExtension: 'NO_RESOURCE_EXT',
                noRights: 'NO_RESOURCE_PAGE_RIGHTS',
            },
        },
        account,
        vacationId,
        extensions
    );

    const onCategoryChange = useCallback(
        categorySelected => {
            if (useCategoriesAllocation && categorySelected?.hasOwnProperty('_id')) {
                updateFields(getAllocationFieldValuesByCategory(categorySelected));
            }
        },
        [updateFields, useCategoriesAllocation]
    );

    useEffect(() => {
        if (!access.hasAccess) {
            return;
        }

        let additionalFields = {};

        let defaultApprovers = getFormDefaultApprovers({ approvers, resource, mappedResourceGroups });

        if (resource?._id && approvers.length) {
            setDefaultApprovers(defaultApprovers);
            additionalFields = {
                approvers: defaultApprovers,
            };
        }

        updateFields({
            ...additionalFields,
        });
    }, [access.hasAccess, approvers, mappedResourceGroups, resource, updateFields]);

    useEffect(() => {
        if (!access.hasAccess) {
            return;
        }
        updateFields({
            ...getDefaultAllocationValues(startDate, endDate, resource, settings),
        });
    }, [access.hasAccess, endDate, resource, settings, startDate, updateFields]);

    useEffect(() => {
        if (!access.hasAccess) {
            return;
        }

        dispatch(companyApprovers.request());
        dispatch(getCategoryGroups.request());
        dispatch(getCategoryTemplates.request());
    }, [dispatch, access.hasAccess]);

    useEffect(() => {
        if (!access.hasAccess) {
            return;
        }

        if (state) {
            dispatch(action(INIT_REQUEST_RESOURCE_FORM, { state }));
        }
    }, [access.hasAccess, dispatch, state]);

    useEffect(() => {
        if (project?._id && access.hasAccess) {
            const categorySelected = getDefaultCategoryByProject(project, categoryTemplates, categoryGroups);

            updateFields({ category: categorySelected });
            onCategoryChange(categorySelected);
        }
    }, [updateFields, project, onCategoryChange, categoryTemplates, categoryGroups, access.hasAccess]);

    const onSubmit = values => {
        const mappedValues = mapFormToRequest(values);
        props.onSubmit(mappedValues);
    };

    const currentCategoryTemplates = getCategoryTemplatesByProject(project, categoryGroups, categoryTemplates);

    const disabledSaveButton = props.submitting || props.invalid || props.pristine;

    useModalPressEnter(() => {
        if (disabledSaveButton) {
            return;
        }

        onSubmit(formValues);
    });

    const onCloseModal = useCallback(() => {
        dispatch(hideModal());
        if (onClose) {
            onClose();
        }
    }, [dispatch, onClose]);

    return (
        <CommonModalTemplate
            submitOnPressEnter
            onHideModalCustom={onCloseModal}
            title={`Request ${resourceKeyWord}`}
            description={`Request to schedule a ${resourceKeyWord} or multiple ${resourcePluralKeyWord} on a ${projectKeyWord}`}
            onSave={() => dispatch(submit(FORM_NAME))}
            windowHeight={props.windowHeight}
            showSaveButton={access.hasAccess}
            saveButtonText="Request"
            saveButtonDataCy="button--request-resource-modal"
            disabledSaveButton={disabledSaveButton}
        >
            {access.hasAccess ? (
                <RequestBody
                    handleSubmit={props.handleSubmit(onSubmit)}
                    categoryTemplates={currentCategoryTemplates}
                    defaultApprovers={defaultApprovers}
                    onCategoryChange={onCategoryChange}
                    onChange={updateFields}
                    formValues={formValues}
                    vacationId={vacationId}
                />
            ) : (
                <NoExtension {...templateContent[access.templateItem]} />
            )}
        </CommonModalTemplate>
    );
};

RequestResourceModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    type: PropTypes.string,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(RequestResourceModal);
