export const APPROVE_REJECT_MODAL = 'APPROVE_REJECT_MODAL';
export const REQUEST_VACATIONS_MODAL = 'REQUEST_VACATIONS_MODAL';
export const REQUEST_RESOURCE_MODAL = 'REQUEST_RESOURCE_MODAL';
export const EDIT_REQUEST_RESOURCE_MODAL = 'EDIT_REQUEST_RESOURCE_MODAL';
export const RESOURCE_MODAL = 'RESOURCE_MODAL';
export const SCHEDULE_RESOURCE_MODAL = 'SCHEDULE_RESOURCE_MODAL';
export const PROJECT_MODAL = 'PROJECT_MODAL';
export const REPORT_BUG_MODAL = 'REPORT_BUG_MODAL';
export const CONTACT_MODAL = 'CONTACT_MODAL';
export const REPORT_MODAL = 'REPORT_MODAL';
export const SUGGEST_REPORT_MODAL = 'SUGGEST_REPORT_MODAL';
export const ORDER_GROUP_MODAL = 'ORDER_GROUP_MODAL';
export const SEARCH_MODAL = 'SEARCH_MODAL';
export const BULK_ADD_MODAL = 'BULK_ADD_MODAL';
export const HOLIDAY_MODAL = 'HOLIDAY_MODAL';
export const CALENDARS_MODAL = 'CALENDARS_MODAL';
export const IMPORT_HOLIDAYS_MODAL = 'IMPORT_HOLIDAYS_MODAL';
export const ICALL_FEED_SUBSCRIBE_MODAL = 'ICALL_FEED_SUBSCRIBE_MODAL';
export const BILLING_MODAL = 'BILLING_MODAL';
export const EDIT_SUBSCRIPTION_MODAL = 'EDIT_SUBSCRIPTION_MODAL';
export const CARD_DETAILS_MODAL = 'CARD_DETAILS_MODAL';
export const DISABLED_ACCOUNT_MODAL = 'DISABLED_ACCOUNT_MODAL';
export const PERMISSIONS_MODAL = 'PERMISSIONS_MODAL';
export const CATEGORY_GROUPS_MODAL = 'CATEGORY_GROUPS_MODAL';
export const CATEGORY_TEMPLATE_MODAL = 'CATEGORY_TEMPLATE_MODAL';
export const FIXED_COST_CATEGORY_TEMPLATE_MODAL = 'FIXED_COST_CATEGORY_TEMPLATE_MODAL';
export const EDIT_BOOKING_TITLE_MODAL = 'EDIT_BOOKING_TITLE_MODAL';
export const INFO_MODAL = 'INFO_MODAL';
export const EXPORT_MODAL = 'EXPORT_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const BULK_MOVE_MODAL = 'BULK_MOVE_MODAL';
export const COLUMN_RESIZE_MODAL = 'COLUMN_RESIZE_MODAL';
export const SCHEDULER_BUILDER_MODAL = 'SCHEDULER_BUILDER_MODAL';
export const REPEAT_MODAL = 'REPEAT_MODAL';
export const EDIT_REPEAT_MODAL = 'EDIT_REPEAT_MODAL';
export const CREATE_PASTED_MODAL = 'CREATE_PASTED_MODAL';
export const MISSING_SMART_SCHEDULES_MODAL = 'MISSING_SMART_SCHEDULES_MODAL';
export const MISSING_MFA_MODAL = 'MISSING_MFA_MODAL';
export const MISSING_SMART_FILTERS_MODAL = 'MISSING_SMART_FILTERS_MODAL';
export const MISSING_BOOKING_CATEGORIES_MODAL = 'MISSING_BOOKING_CATEGORIES_MODAL';
export const MISSING_FIXED_COSTS_CATEGORIES_MODAL = 'MISSING_FIXED_COSTS_CATEGORIES_MODAL';
export const MISSING_DEADLINES_MODAL = 'MISSING_DEADLINES_MODAL';
export const MISSING_DEPENDENCIES_MODAL = 'MISSING_DEPENDENCIES_MODAL';
export const MISSING_UNASSIGNED_MODAL = 'MISSING_UNASSIGNED_MODAL';
export const MISSING_TOIL_EXTENSION_MODAL = 'MISSING_TOIL_EXTENSION_MODAL';
export const REPORTS_GROUP_MODAL = 'REPORTS_GROUP_MODAL';
export const MFA_RECOVERY_CODES_MODAL = 'MFA_RECOVERY_CODES_MODAL';
export const MFA_SETUP_MODAL = 'MFA_SETUP_MODAL';
export const OTP_VERIFICATION_MODAL = 'OTP_VERIFICATION_MODAL';
export const MFA_USER_REMINDER_SOFT_MODAL = 'MFA_USER_REMINDER_SOFT_MODAL';
export const MFA_USER_REMINDER_HARD_MODAL = 'MFA_USER_REMINDER_HARD_MODAL';
export const MFA_COMPANY_OWNER_REMINDER_MODAL = 'MFA_COMPANY_OWNER_REMINDER_MODAL';
export const MANAGE_POLICIES_MODAL = 'MANAGE_POLICIES_MODAL';
export const NEW_POLICY_MODAL = 'NEW_POLICY_MODAL';
export const LEAR_MORE_ABOUT_TOIL_MODAL = 'LEAR_MORE_ABOUT_TOIL_MODAL';
export const EDIT_POLICY_NAME_MODAL = 'EDIT_POLICY_NAME_MODAL';
