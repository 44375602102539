const DAY = 24;

export const closeActionsTitles = {
    SKIP: 'Skip this tip',
    REMIND_TOMORROW: 'Remind me tomorrow',
    SNOOZE_48: 'Snooze for 48 hours',
    SNOOZE_WEEK: 'Snooze for week',
};

export const snoozeHours = {
    DAY,
    TWO_DAYS: DAY * 2,
    WEEK: DAY * 7,
};
