import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { HOURS_DECIMAL } from 'modules/report/enums/reportUnitTypeEnum';
import DataBox from '../dataBox';

const TimeRow = ({ reportData, isLoading, showReportedData }) => {
    const {
        gridPreferences: { singleProjectView },
    } = useAccountPreferences();
    const avgDailyCapacity = useSelector(state => state.companyReducer.company.settings.report.avgDailyCapacity);

    const scheduledValues = useMemo(
        () => [
            {
                value: HOURS_DECIMAL.calculation(reportData.scheduledTimeAllTime, avgDailyCapacity),
                label: 'all',
            },
            {
                value: HOURS_DECIMAL.calculation(reportData.scheduledTimeToDate, avgDailyCapacity),
                label: 'to date',
            },
            {
                value: HOURS_DECIMAL.calculation(reportData.scheduledTimeFuture, avgDailyCapacity),
                label: 'future',
            },
        ],
        [reportData, avgDailyCapacity]
    );

    const reportedValues = useMemo(
        () => [
            {
                value: HOURS_DECIMAL.calculation(reportData.reportedTimeAllTime, avgDailyCapacity),
                label: 'all',
            },
            {
                value: HOURS_DECIMAL.calculation(reportData.reportedTimeToDate, avgDailyCapacity),
                label: 'to date',
            },
            {
                value: HOURS_DECIMAL.calculation(reportData.reportedTimeFuture, avgDailyCapacity),
                label: 'future',
            },
        ],
        [reportData, avgDailyCapacity]
    );

    return (
        <Row className="px-3">
            {singleProjectView.displayScheduledColumn && (
                <DataBox
                    title="scheduled time"
                    fullWidth={!singleProjectView.displayReportedColumn}
                    values={scheduledValues}
                    className={classNames({ 'main-border': singleProjectView.displayReportedColumn })}
                    isLoading={isLoading}
                />
            )}
            {singleProjectView.displayReportedColumn && showReportedData && (
                <DataBox
                    title="reported time"
                    fullWidth={!singleProjectView.displayScheduledColumn}
                    values={reportedValues}
                    isLoading={isLoading}
                />
            )}
        </Row>
    );
};

TimeRow.propTypes = {
    reportData: PropTypes.shape({
        scheduledTimeAllTime: PropTypes.number,
        scheduledTimeToDate: PropTypes.number,
        scheduledTimeFuture: PropTypes.number,
        reportedTimeAllTime: PropTypes.number,
        reportedTimeToDate: PropTypes.number,
        reportedTimeFuture: PropTypes.number,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    showReportedData: PropTypes.bool.isRequired,
};

export default TimeRow;
