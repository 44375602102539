import { keys } from 'underscore';

/**
 * @param {object} keywords
 *
 * @returns {object}
 */
export const replaceMap = keywords => ({
    projects: keywords.projectPluralKeyWord || '',
    Projects: keywords.projectPluralKeyWord,
    project: keywords.projectKeyWord || '',
    Project: keywords.projectKeyWord || '',
    resources: keywords.resourcePluralKeyWord,
    Resources: keywords.resourcePluralKeyWord,
    resource: keywords.resourceKeyWord || '',
    Resource: keywords.resourceKeyWord || '',
    customers: keywords.customerPluralKeyWord || '',
    Customers: keywords.customerPluralKeyWord || '',
    customer: keywords.customerKeyWord || '',
    Customer: keywords.customerKeyWord || '',
    Active: keywords.Active || 'Active',
    Archived: keywords.Archived || 'Archived',
    Floating: keywords.Floating || 'Floating',
    Pending: keywords.Pending || 'Pending',
    Planned: keywords.Planned || 'Planned',
});

/**
 * @param {object} replaceMap
 * @param {string} value
 *
 * @returns {string}
 */
export const replaceKeywords = (replaceMap, value) => {
    const findRegex = new RegExp(keys(replaceMap).join('|'), 'gi');

    return value && value.replace(findRegex, matched => replaceMap[matched]);
};
