import classNames from 'classnames';
import { DAY } from 'modules/scheduler/enums/scale';
import { LEGACY, PARENT, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { getCellUtilizationMinutes } from 'modules/scheduler/utils/schedulerUtil';
import { formatNumber } from 'utils/formatingUtil';
import { getResourcePercentageBooked, getNotificationBackgroundColor } from './helper';
import { eventsForRangeCache } from '../../../../../utils/eventsForRangeCache';

const handleRegular = (args, data, resourceCapacity, companySettings) => {
    const { scale: schedulerScale, mode: schedulerMode } = args.cell.calendar;

    const schedulerNotificationColors = companySettings.grid.schedulerNotificationColors;
    const isLegacyOrSingleMode = schedulerMode === LEGACY.value || schedulerMode === SINGLE.value;
    const isParentMode = schedulerMode === PARENT.value;
    let utilizationWidth = 100;
    let bookedMinutes;

    if (isParentMode) {
        const eventsForRange = eventsForRangeCache.getForRange(args.cell.start, args.cell.end, args.control.events);
        const events = eventsForRange.filter(event => -1 !== event.data.resource.indexOf(args.cell.resource));

        if (events.length > 0) {
            bookedMinutes = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data, events);
        } else {
            bookedMinutes = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data);
        }
    } else {
        const cellEvents = args.cell.events();

        if (cellEvents.length > 0) {
            bookedMinutes = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data, cellEvents);
        } else {
            bookedMinutes = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data);
        }

    }

    const percentageResourceIsBooked = getResourcePercentageBooked(bookedMinutes, resourceCapacity.totalMinutes);

    if (companySettings.grid.dynamicCapacityBarWidthEnabled) {
        utilizationWidth = 100 <= percentageResourceIsBooked ? utilizationWidth : percentageResourceIsBooked;
    }

    const overBookedBar = bookedMinutes > resourceCapacity.totalMinutes && !data.isHoliday;
    const overbookedValue = resourceCapacity.totalMinutes - bookedMinutes;
    const availableBar = bookedMinutes < resourceCapacity.totalMinutes && 0 < bookedMinutes;

    // Set capacity bar color for schedule and export.
    const barColor = getNotificationBackgroundColor(
        schedulerScale,
        schedulerNotificationColors,
        bookedMinutes,
        percentageResourceIsBooked,
        data
    ); // Needed for export

    // Defaults
    args.cell.properties.resourceCapacity = resourceCapacity.totalMinutes;
    args.cell.properties.resourceCapacityHours = resourceCapacity.totalMinutes / 60;
    args.cell.properties.overBooked = false;
    args.cell.properties.available = false;
    args.cell.properties.partial = false;
    args.cell.properties.capacity = false;
    args.cell.properties.actualAvailability = formatNumber(overbookedValue / 60);
    args.cell.properties.barColor = barColor;

    const options = {
        utilizationWidth,
        schedulerScale,
        isLegacyOrSingleMode,
        overBookedBar,
        overbookedValue,
        availableBar,
        atCapacity: overbookedValue === 0,
    };

    applyHtml(args, options, data);
};

const applyHtml = (args, options, data) => {
    const resourceCapacityMinutes = args.cell.properties.resourceCapacity;
    const {
        utilizationWidth,
        schedulerScale,
        isLegacyOrSingleMode,
        overBookedBar,
        overbookedValue,
        availableBar,
        atCapacity,
    } = options;
    let actualAvailability = args.cell.properties.actualAvailability;
    let utilText = 'available';
    let hText = 'hrs';

    if (overBookedBar) {
        args.cell.properties.overBooked = true;
        args.cell.properties.actualOverbooked = actualAvailability = formatNumber(overbookedValue / 60);
        utilText = 'over scheduled';
    } else if (availableBar) {
        args.cell.properties.partial = true;
        utilText = 'under schedule';
    } else if (atCapacity && 0 < resourceCapacityMinutes) {
        args.cell.properties.capacity = true;
        utilText = 'free - at capacity';
        actualAvailability = 0;
    }

    if (schedulerScale === DAY.value && data.isHoliday) {
        utilText = '';
        actualAvailability = '';
        hText = data.holiday.name;
    } else if ((schedulerScale === DAY.value && (!data.isWorkday || data.isHoliday)) || data.isHoliday) {
        utilText = '';
        actualAvailability = '';
        hText = 'non working day';
    }

    const className = classNames('utilisation-holder', { 'single-util-holder': isLegacyOrSingleMode });
    if ('TYPE_NOTIFICATION' === data.notificationType) {
        args.cell.html = `<div title='${actualAvailability}${hText} ${utilText}' class='${className}' style='background-color: ${args.cell.properties.barColor}; width: ${utilizationWidth}%!important; height: inherit;'/>`;
    } else if ('TYPE_AVAILABILITY' === data.notificationType) {
        args.cell.html = `<div title='${actualAvailability}${hText} ${utilText}' class='${className}' style='background-color: ${args.cell.properties.barColor}; width: ${utilizationWidth}% !important; height: inherit;'></div><span class='utilisation-holder-partial'>${actualAvailability}</span>`;
    }
};

export default handleRegular;
