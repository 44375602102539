import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import SuccessButton from './../buttons/success';
import CancelButton from './../buttons/cancel';
import { Wrapper, Overlay } from './styles';

const ConfirmDialog = ({
    title,
    message,
    onConfirm,
    onReject,
    show,
    confirmButtonText,
    cancelButtonText,
    withCancel,
}) =>
    show ? (
        <Overlay>
            <Wrapper className="offset-sm-3 offset-md-4 offset-lg-2 ">
                <Row>
                    <Col>
                        <h1>{title}</h1>
                        <p>{message}</p>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center mt-4">
                    {withCancel && (
                        <CancelButton
                            name={cancelButtonText}
                            className="float-right mr-3 align-self-baseline"
                            onClick={onReject}
                            dataCy="confirm-dialog-cancel-btn"
                        />
                    )}
                    <SuccessButton
                        dataCy="confirm-dialog-confirm-btn"
                        name={confirmButtonText}
                        className="align-self-baseline"
                        onClick={onConfirm}
                    />
                </div>
            </Wrapper>
        </Overlay>
    ) : null;

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onReject: PropTypes.func,
    show: PropTypes.bool,
    confirmButtonColor: PropTypes.string,
    rejectButtonColor: PropTypes.string,
    confirmButtonText: PropTypes.string,
    cancelButtonText: PropTypes.string,
    withCancel: PropTypes.bool,
};

ConfirmDialog.defaultProps = {
    confirmButtonColor: 'danger',
    rejectButtonColor: 'secondary',
    message: '',
    show: false,
    onReject: null,
    confirmButtonText: 'Yes, delete it',
    cancelButtonText: 'Cancel',
    withCancel: true,
};

export default ConfirmDialog;
