import React from 'react';
import zxcvbn from 'zxcvbn';
import PropTypes from 'prop-types';
import { MeterContainer, PasswordStrength } from './styles';

const PasswordStrengthMeter = ({ password }) => {
    if (!password) return null;

    const testedResult = zxcvbn(password);

    const createPasswordLabel = result => {
        switch (result.score) {
            case 0:
                return 'Very weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Good';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    };

    return (
        <div className="d-flex align-items-baseline">
            <MeterContainer>
                <PasswordStrength
                    className={`strength-${testedResult.score}`}
                    style={{ width: (testedResult.score + 1) * 20 }}
                />
            </MeterContainer>
            <label className="small ml-2">{password && <>{createPasswordLabel(testedResult)}</>}</label>
        </div>
    );
};

PasswordStrengthMeter.propTypes = {
    password: PropTypes.string,
};
PasswordStrengthMeter.defaultProps = {
    password: '',
};

export default PasswordStrengthMeter;
