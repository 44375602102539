import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHasRights, useIsExtensionEnabled, useIsExtensionInstalled } from 'hooks';
import { TIME_SHEET, RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { useAccountPreferences, useFinancialData } from 'modules/scheduler/hooks';
import TimeRow from './rows/timeRow';
import SpendRow from './rows/spendRow';
import BudgetCashRow from './rows/budgetCashRow';
import BudgetHoursRow from './rows/budgetHoursRow';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';

const rights = [
    {
        rights: ['settingProjectBudget'],
        rule: 'one',
        name: 'hasRightToSeeFinancialData',
    },
];

const ProjectFinancial = () => {
    const params = useParams();
    const viewObject = getViewObject(params);
    const { data: reportData, loading: isReportDataLoading } = useSelector(state => state.scheduler.financial);
    const isTSExtensionInstalled = useIsExtensionInstalled(TIME_SHEET);
    const isTSExtensionEnabled = useIsExtensionEnabled(TIME_SHEET);
    const isResourceRateExtensionInstalled = useIsExtensionInstalled(RESOURCE_RATE);
    const { hasRightToSeeFinancialData } = useHasRights(rights);
    const financialRequest = useFinancialData(params, viewObject);
    const {
        gridPreferences: { singleProjectView },
    } = useAccountPreferences();

    useEffect(() => {
        financialRequest();
    }, [financialRequest]);

    if (!singleProjectView.informationPanelDisplayed || !hasRightToSeeFinancialData) {
        return null;
    }

    return (
        <div className="mb-2">
            {singleProjectView.displayScheduledReportedTimeRow && (
                <TimeRow
                    reportData={reportData}
                    showReportedData={isTSExtensionInstalled && isTSExtensionEnabled}
                    isLoading={isReportDataLoading}
                />
            )}
            {singleProjectView.displayScheduledReportedExternalSpendRow && hasRightToSeeFinancialData && (
                <SpendRow
                    reportData={reportData}
                    showReportedData={isTSExtensionInstalled && isTSExtensionEnabled}
                    isLoading={isReportDataLoading}
                    type="external"
                />
            )}
            {singleProjectView.displayScheduledReportedInternalSpendRow &&
                hasRightToSeeFinancialData &&
                isResourceRateExtensionInstalled && (
                    <SpendRow
                        reportData={reportData}
                        showReportedData={isTSExtensionInstalled && isTSExtensionEnabled}
                        isLoading={isReportDataLoading}
                        type="internal"
                    />
                )}
            {viewObject.isSingleProjectView &&
                singleProjectView.displayScheduledReportedBudgetCashRow &&
                hasRightToSeeFinancialData && (
                    <BudgetCashRow
                        reportData={reportData}
                        showReportedData={isTSExtensionInstalled && isTSExtensionEnabled}
                        isLoading={isReportDataLoading}
                    />
                )}
            {viewObject.isSingleProjectView &&
                singleProjectView.displayScheduledReportedBudgetHoursRow &&
                hasRightToSeeFinancialData && (
                    <BudgetHoursRow
                        reportData={reportData}
                        showReportedData={isTSExtensionInstalled && isTSExtensionEnabled}
                        isLoading={isReportDataLoading}
                    />
                )}
        </div>
    );
};

export default ProjectFinancial;
