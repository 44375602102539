import * as actionTypes from 'actions/actionTypes';
import createReducer from 'reducers/helpers/createReducer';

const initialIntegrationState = {
    basecamp: {
        isAuthorized: false,
        loading: false,
        accounts: [],
        identity: null,
        newResources: [],
        newProjects: [],
        imported: false,
    },
};

const setProviderAuthStatus = (state, action) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        isAuthorized: !!action.payload.accounts,
        loading: false,
    },
});

const setProviderAccounts = (state, action) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        accounts: action.payload.accounts,
        loading: false,
    },
});

const setProviderLoadingStatus = (state, action, status) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        loading: status,
    },
});

const setProviderImportStatus = (state, action) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        imported: action.payload.status,
        loading: false,
    },
});

const setProviderNewProjects = (state, action) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        newProjects: action.payload.data,
        loading: false,
        imported: true,
    },
});

const setProviderNewResources = (state, action) => ({
    ...state,
    [action.payload.provider]: {
        ...state[action.payload.provider],
        newResources: action.payload.data,
        loading: false,
        imported: true,
    },
});

export default createReducer(initialIntegrationState, {
    [actionTypes.GET_AUTH_INFORMATION_INTEGRATION['SUCCESS']]: setProviderAuthStatus,
    [actionTypes.GET_AUTH_INFORMATION_INTEGRATION['REQUEST']]: (state, action) =>
        setProviderLoadingStatus(state, action, true),
    [actionTypes.REMOVE_AUTHORIZATION_INTEGRATION['SUCCESS']]: setProviderAuthStatus,
    [actionTypes.REMOVE_AUTHORIZATION_INTEGRATION['REQUEST']]: (state, action) =>
        setProviderLoadingStatus(state, action, true),
    [actionTypes.GET_ACCOUNTS_INTEGRATION['SUCCESS']]: setProviderAccounts,
    [actionTypes.GET_ACCOUNTS_INTEGRATION['FAILURE']]: (state, action) =>
        setProviderLoadingStatus(state, action, false),
    [actionTypes.GET_RESOURCES_INTEGRATION['REQUEST']]: (state, action) =>
        setProviderLoadingStatus(state, action, true),
    [actionTypes.GET_RESOURCES_INTEGRATION['FAILURE']]: (state, action) =>
        setProviderLoadingStatus(state, action, false),
    [actionTypes.GET_RESOURCES_INTEGRATION['SUCCESS']]: setProviderNewResources,
    [actionTypes.SET_IMPORTED_STATUS_INTEGRATION['REQUEST']]: setProviderImportStatus,
    [actionTypes.UPDATE_NEW_PROJECTS_INTEGRATION['REQUEST']]: setProviderNewProjects,
    [actionTypes.UPDATE_NEW_RESOURCES_INTEGRATION['REQUEST']]: setProviderNewResources,
    [actionTypes.GET_PROJECTS_INTEGRATION['REQUEST']]: (state, action) => setProviderLoadingStatus(state, action, true),
    [actionTypes.GET_PROJECTS_INTEGRATION['FAILURE']]: (state, action) =>
        setProviderLoadingStatus(state, action, false),
    [actionTypes.GET_PROJECTS_INTEGRATION['SUCCESS']]: setProviderNewProjects,
    [actionTypes.SET_LOADING_STATUS_INTEGRATION['REQUEST']]: (state, action) =>
        setProviderLoadingStatus(state, action, action.payload.status),
});
