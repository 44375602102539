import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getResources } from 'actions/resourceActions';
import { getProjects } from 'actions/projectActions';

// noApiCallsRefresh parameter was introduced to not try to go into saga (and api calls). after saga is called success, it clears the scheduler temporary selection. not the best solution, but a safe one for https://hubplanner.atlassian.net/browse/HUB-7264
// hooks cannot be called conditionally so this parameter was introduced.
export const useLoadProjectsAndResources = noDataRefresh => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (!noDataRefresh) {
            dispatch(getResources.request());
            dispatch(getProjects.request());
        }
    }, [dispatch, noDataRefresh]);
};
