import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Allocation from './materialAllocation';

const ButtonAllocation = props => {
    const { onAllocationChange, allocationDefaultSettings, className, title, formName } = props;

    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className={classNames(className)}>
            <DropdownToggle
                caret
                style={{ minWidth: 137 }}
                title="Default Allocation"
                color="transparent"
                className="d-flex align-items-center justify-content-between btn-white"
            >
                <i className="fa-calendar fa mr-1" />
                {title}
            </DropdownToggle>
            <DropdownMenu>
                <Allocation
                    onEnterPressed={toggle}
                    title="Default Allocation"
                    formText=""
                    allocationDefaultState={allocationDefaultSettings.state}
                    onChange={onAllocationChange}
                    small={true}
                    formName={formName}
                />
            </DropdownMenu>
        </ButtonDropdown>
    );
};
ButtonAllocation.propTypes = {
    title: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onAllocationChange: PropTypes.func.isRequired,
    allocationDefaultSettings: PropTypes.shape({
        state: PropTypes.string,
        values: PropTypes.shape({
            percentage: PropTypes.number,
            hours: PropTypes.number,
            total: PropTypes.number,
        }),
    }).isRequired,
};

ButtonAllocation.defaultProps = {
    title: '',
    className: '',
};

export default ButtonAllocation;
