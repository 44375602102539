const linksAndIconsFields = {
    link1: {
        type: String,
        required: false,
    },
    link2: {
        type: String,
        required: false,
    },
    link3: {
        type: String,
        required: false,
    },
    link4: {
        type: String,
        required: false,
    },
    link5: {
        type: String,
        required: false,
    },
    iconLink1: {
        type: String,
        required: false,
    },
    iconLink2: {
        type: String,
        required: false,
    },
    iconLink3: {
        type: String,
        required: false,
    },
    iconLink4: {
        type: String,
        required: false,
    },
    iconLink5: {
        type: String,
        required: false,
    },
};

export default linksAndIconsFields;
