import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'day',
        display: 'Day',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'week',
        display: 'Week',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'month',
        display: 'Month',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'year',
        display: 'Year',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
];
