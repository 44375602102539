import { filter, find, findIndex, map, includes, without } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import CompanyTreeModel from 'models/CompanyTreeModel';
import { STATUS_ARCHIVED, TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { STATUS_ARCHIVED as PROJECT_STATUS_ARCHIVED, TYPE_REGULAR } from 'enums/projectEnum';
import { ACTIVE, UNASSIGNED } from 'enums/criteriaEnum';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject, updateItemInArray } from 'reducers/helpers/updater';

const getUpdatedCompanyTree = updatedCompanyTree => {
    const companyTree = new CompanyTreeModel();

    return companyTree.updateData(updatedCompanyTree);
};

const getCompanyTree = (state, action) => action.payload.companyTree;

const addRate = (state, action) => {
    if (!state._settings.billingRates) {
        return state;
    }
    const currentRates = [...state._settings.billingRates];
    currentRates.push(action.payload.rate);
    const updatedCompanyTree = updateObject(state, {
        _settings: {
            ...state._settings,
            billingRates: currentRates,
        },
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const addProject = (state, action) => {
    let currentProjects = [...state._projects];
    if (!currentProjects.length) {
        return state;
    }
    const project = action.payload.project;
    const criteria = TYPE_REGULAR.value === project.type ? 'ACTIVE' : project.type;
    const projectGroupId =
        action.payload.projectGroupId ||
        find(state._projectGroupsFlatten, resourceGroup => criteria === resourceGroup.criteria)._id;
    const projectGroupToUpdate = find(state._projectGroupsFlatten, projectGroup => projectGroup._id === projectGroupId);
    currentProjects.push({ ...project, resourceIds: project.resources });
    projectGroupToUpdate.projectIds.push(action.payload.project._id);
    const updatedCompanyTree = updateObject(state, {
        _projects: currentProjects,
        _projectGroups: updateItemInArray(state._projectGroupsFlatten, projectGroupId, projectGroup =>
            updateObject(projectGroup, projectGroupToUpdate)
        ),
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const addResource = (state, action) => {
    let currentResources = [...state._resources];
    if (!currentResources.length) {
        return state;
    }
    const resource = action.payload.resource;
    const criteria = resource.type === TYPE_UNASSIGNED.value ? UNASSIGNED : ACTIVE;
    const resourceGroupToUpdate = find(
        state._resourceGroupsFlatten,
        resourceGroup =>
            (action.payload.resourceGroupId && resourceGroup._id === action.payload.resourceGroupId) ||
            (!action.payload.resourceGroupId && resourceGroup.criteria === criteria)
    );
    currentResources.push({
        ...resource.currentCompanySpecific,
        email: resource.email,
        type: resource.type,
        _id: resource._id,
    });
    resourceGroupToUpdate.resourceIds.push(resource._id);
    const updatedCompanyTree = updateObject(state, {
        _resources: currentResources,
        _resourceGroups: updateItemInArray(state._resourceGroupsFlatten, resourceGroupToUpdate._id, resourceGroup =>
            updateObject(resourceGroup, resourceGroupToUpdate)
        ),
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const updateProjectGroup = (state, action) => {
    let updatedProjectGroup = { ...action.payload.projectGroup, projectIds: action.payload.projectGroup.projects };
    delete updatedProjectGroup.projects;
    const updatedCompanyTree = updateObject(state, {
        _projectGroupsFlatten: updateItemInArray(
            state._projectGroupsFlatten,
            action.payload.projectGroup._id,
            projectGroup => updateObject(projectGroup, updatedProjectGroup)
        ),
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const addProjectGroup = (state, action) => {
    const currentProjectGroups = [...state._projectGroupsFlatten];
    const projectGroup = { ...action.payload.projectGroup, projectIds: action.payload.projectGroup.projects };
    delete projectGroup.projects;
    currentProjectGroups.push(projectGroup);
    const updatedCompanyTree = updateObject(state, {
        _projectGroupsFlatten: currentProjectGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const updateResourceGroup = (state, action) => {
    let updatedResourceGroup = { ...action.payload.resourceGroup, resourceIds: action.payload.resourceGroup.resources };
    delete updatedResourceGroup.resources;
    const updatedCompanyTree = updateObject(state, {
        _resourceGroupsFlatten: updateItemInArray(
            state._resourceGroupsFlatten,
            action.payload.resourceGroup._id,
            resourceGroup => updateObject(resourceGroup, updatedResourceGroup)
        ),
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const addResourceGroup = (state, action) => {
    const currentResourceGroups = [...state._resourceGroupsFlatten];
    const resourceGroup = { ...action.payload.resourceGroup, resourceIds: action.payload.resourceGroup.resources };
    delete resourceGroup.resources;
    currentResourceGroups.push(resourceGroup);
    const updatedCompanyTree = updateObject(state, {
        _resourceGroupsFlatten: currentResourceGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const deleteProjectGroup = (state, action) => {
    const currentProjectGroups = filter(
        [...state._projectGroupsFlatten],
        group => group._id !== action.payload.id && group.parentGroupId !== action.payload.id
    );
    const updatedCompanyTree = updateObject(state, {
        _projectGroupsFlatten: currentProjectGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const deleteResourceGroup = (state, action) => {
    const resourceGroups = filter(
        [...state._resourceGroupsFlatten],
        group => group._id !== action.payload.id && group.parentGroupId !== action.payload.id
    );
    const updatedCompanyTree = updateObject(state, {
        _resourceGroupsFlatten: resourceGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const deleteProject = (state, action) => {
    let currentProjects = [...state._projects];
    let projectGroups = map(state._projectGroupsFlatten, projectGroup => {
        if (includes(projectGroup.projectIds, action.payload.id)) {
            projectGroup.projectIds.splice(
                findIndex(projectGroup.projectIds, project => project === action.payload.id),
                1
            );
        }

        return projectGroup;
    });
    currentProjects = filter(currentProjects, project => project._id !== action.payload.id);
    const updatedCompanyTree = updateObject(state, {
        _projects: currentProjects,
        _projectGroupsFlatten: projectGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const deleteResource = (state, action) => {
    let currentResources = [...state._resources];
    let resourceGroups = map([...state._resourceGroupsFlatten], resourceGroup => {
        if (includes(resourceGroup.resourceIds, action.payload.id)) {
            resourceGroup.resourceIds.splice(
                findIndex(resourceGroup.resourceIds, resource => resource === action.payload.id),
                1
            );
        }

        return resourceGroup;
    });
    currentResources = filter(currentResources, resource => resource._id !== action.payload.id);
    const updatedCompanyTree = updateObject(state, {
        _resources: currentResources,
        _resourceGroupsFlatten: resourceGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const updateProject = (state, action) => {
    const project = action.payload.project;
    let currentProjectGroups = [...state._projectGroupsFlatten];
    const projectToUpdate = {
        ...project,
        resourceIds: project.resources,
        projectManagerIds: project.projectManagers,
        color: project.backgroundColor,
        thumbUrl: project.thumb,
    };
    delete projectToUpdate.resources;
    delete projectToUpdate.projectManagers;
    delete projectToUpdate.backgroundColor;
    delete projectToUpdate.thumb;

    const oldProject = find(state._projects, p => p._id === projectToUpdate?._id);

    if (
        (oldProject && oldProject.status !== projectToUpdate?.status) ||
        action.payload.projectGroupsAdd?.length ||
        action.payload.projectGroupsRemove?.length
    ) {
        currentProjectGroups = map(currentProjectGroups, group => {
            if (
                group.criteria === oldProject?.status.toUpperCase() ||
                (!group.criteria && projectToUpdate?.status === PROJECT_STATUS_ARCHIVED.value)
            ) {
                group.projectIds.splice(
                    findIndex(group.projectIds, p => p === oldProject?._id),
                    1
                );
            }

            if (
                (group.criteria === project.status.toUpperCase() &&
                    !includes(group.resourceIds, projectToUpdate?._id)) ||
                includes(action.payload.projectGroupsAdd, group._id)
            ) {
                group.projectIds.push(project._id);
            }
            if (includes(action.payload.projectGroupsRemove, group._id)) {
                group.projectIds = without(group.projectIds, project._id);
            }

            return group;
        });
    }

    const updatedCompanyTree = updateObject(state, {
        _projects: updateItemInArray(state._projects, projectToUpdate._id, project =>
            updateObject(project, projectToUpdate)
        ),
        _projectGroups: currentProjectGroups,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

const updateProjectResources = (currentProjects, projectsAdd, projectsRemove, resourceId) => {
    for (const projectId of [...projectsAdd, ...projectsRemove]) {
        currentProjects = updateItemInArray(currentProjects, projectId, project => {
            if (includes(projectsAdd, projectId)) {
                return updateObject(project, { resourceIds: project.resourceIds.concat([resourceId]) });
            }

            return updateObject(project, { resourceIds: without(project.resourceIds, resourceId) });
        });
    }

    return currentProjects;
};

const updateResource = (state, action) => {
    const {
        resource,
        additionalInfo: { projectsAdd, projectsRemove, resourceGroupsAdd, resourceGroupsRemove } = {},
    } = action.payload;
    let currentResourceGroups = [...state._resourceGroupsFlatten];
    const resourceToUpdate = resource.currentCompanySpecific
        ? {
              ...resource.currentCompanySpecific,
              _id: resource._id,
              type: resource.type,
              email: resource.email,
              thumbUrl: resource.currentCompanySpecific.thumb,
          }
        : resource;

    if (resourceToUpdate.thumb) {
        delete resourceToUpdate.thumb;
    }

    const oldResource = find(state._resources, resource => resource._id === resourceToUpdate._id);

    if (oldResource && oldResource.status !== resourceToUpdate.status) {
        currentResourceGroups = map(currentResourceGroups, group => {
            if (
                group.criteria === oldResource.status.toUpperCase().replace('STATUS_', '') ||
                (!group.criteria && resourceToUpdate.status === STATUS_ARCHIVED.value)
            ) {
                const resourceIndex = findIndex(group.resourceIds, resource => resource === oldResource._id);
                if (-1 !== resourceIndex) {
                    group.resourceIds.splice(resourceIndex, 1);
                }
            }

            if (
                (group.criteria === resourceToUpdate.status.toUpperCase().replace('STATUS_', '') &&
                    !includes(group.resourceIds, resourceToUpdate._id)) ||
                includes(resourceGroupsAdd, group._id)
            ) {
                group.resourceIds.push(resourceToUpdate._id);
            }

            if (includes(resourceGroupsRemove, group._id)) {
                group.resourceIds = without(group.resourceIds, resourceToUpdate._id);
            }

            return group;
        });
    }

    const updatedCompanyTree = updateObject(state, {
        _resources: updateItemInArray(state._resources, resourceToUpdate._id, resource =>
            updateObject(resource, resourceToUpdate)
        ),
        _resourceGroupsFlatten: currentResourceGroups,
        _projects:
            projectsAdd || projectsRemove
                ? updateProjectResources(state._projects, projectsAdd || [], projectsRemove || [], resource._id)
                : state._projects,
    });

    return getUpdatedCompanyTree(updatedCompanyTree);
};

export default createReducer(new CompanyTreeModel(), {
    [actionTypes.GET_COMPANY_TREE['SUCCESS']]: getCompanyTree,
    [actionTypes.CREATE_RATE['SUCCESS']]: addRate,
    [actionTypes.DUPLICATE_RESOURCE['SUCCESS']]: addResource,
    [actionTypes.CREATE_RESOURCE['SUCCESS']]: addResource,
    [actionTypes.DUPLICATE_PROJECT['SUCCESS']]: addProject,
    [actionTypes.CREATE_PROJECT['SUCCESS']]: addProject,
    [actionTypes.UPDATE_PROJECT_GROUP['SUCCESS']]: updateProjectGroup,
    [actionTypes.CREATE_PROJECT_GROUP['SUCCESS']]: addProjectGroup,
    [actionTypes.UPDATE_RESOURCE_GROUP['SUCCESS']]: updateResourceGroup,
    [actionTypes.CREATE_RESOURCE_GROUP['SUCCESS']]: addResourceGroup,
    [actionTypes.DELETE_PROJECT_GROUP['REQUEST']]: deleteProjectGroup,
    [actionTypes.DELETE_RESOURCE_GROUP['REQUEST']]: deleteResourceGroup,
    [actionTypes.DELETE_PROJECT['SUCCESS']]: deleteProject,
    [actionTypes.DELETE_RESOURCE['REQUEST']]: deleteResource,
    [actionTypes.UPDATE_PROJECT['SUCCESS']]: updateProject,
    [actionTypes.UPDATE_RESOURCE['SUCCESS']]: updateResource,
});
