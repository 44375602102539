import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moize from 'moize';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { contains, filter, map, sortBy } from 'underscore';
import { FormText, Button } from 'reactstrap';
import { useHasRights, useIsExtensionInstalled, useAppKeyWords } from 'hooks';
import { getCurrency } from 'enums/currencyEnum';
import { RESOURCE_RATE, UNASSIGNED } from 'enums/extensionShortIdEnum';
import { dropdownFieldMaterial } from 'shared/formFields';
import MissingExtension from 'shared/warnings/missingExtension';
import HelpButton from './../../common/helpButton';
import RateForm from './../../common/createRate';
import CustomResourceRates from 'modules/modals/projectModal/components/customResourceRates';
import ExistingResourceRates from 'modules/modals/projectModal/components/existingResourceRates';
import { SectionInfo, SectionInfoDesc } from 'modules/modals/styles';
import { FORM_NAME } from './modal';

const getNotDeletedRates = rates =>
    sortBy(
        filter(rates, rate => !rate.deleted),
        item => item.label.toLowerCase()
    );

const getResourceWithRates = resources =>
    filter(
        resources,
        resource => resource.resourceRates?.external?.length > 0 || resource.resourceRates?.internal?.length > 0
    );

const memoizedResourcesWithRates = moize(getResourceWithRates, { maxSize: 2 });
const getDiffExistingAndCustomRates = (resources, selectedResources, resourceRates) => {
    if (selectedResources.length) {
        const resourcesWithRates = memoizedResourcesWithRates(resources);
        const selectedResourceIds = map(selectedResources, '_id');
        const resourceRatesIds = map(resourceRates, rate => rate.resource && rate.resource._id);
        return filter(
            resourcesWithRates,
            resource => contains(selectedResourceIds, resource._id) && !contains(resourceRatesIds, resource._id)
        );
    }

    return [];
};

const memoizedDiffExistingAndCustomRates = moize(getDiffExistingAndCustomRates, { maxSize: 2 });

const InternalExternalProjectRates = props => {
    const { type, resources, projectRates, selectedResources, projectKeyWord } = props;
    const { resourceKeyWord, resourcePluralKeyWord } = useAppKeyWords();
    const isResourceRateExtensionInstalled = useIsExtensionInstalled(RESOURCE_RATE);
    const isUnassignedExtensionInstalled = useIsExtensionInstalled(UNASSIGNED);
    const [isRatesFormVisible, toggleFormVisibility] = useState(false);
    const rates = useSelector(state => state.companyReducer.company.settings.billingRates);
    const toggleForm = () => toggleFormVisibility(prevState => !prevState);
    const { hasSettingProjectRateRights } = useHasRights([
        {
            rights: ['settingProjectRate'],
            rule: 'one',
            name: 'hasSettingProjectRateRights',
        },
    ]);

    const existingResourceRatesAndNotCustom = memoizedDiffExistingAndCustomRates(
        resources,
        selectedResources,
        projectRates.customRates
    );
    const filteredRates = getNotDeletedRates(rates || []);

    return (
        <>
            <SectionInfo>
                <h6 className="text-center text-capitalize">
                    {type} {projectKeyWord} Rates
                </h6>
                <SectionInfoDesc>
                    Manage the {type} {projectKeyWord} rates for this {projectKeyWord}
                </SectionInfoDesc>
            </SectionInfo>
            {hasSettingProjectRateRights &&
            (('external' === type && hasSettingProjectRateRights) ||
                (isResourceRateExtensionInstalled && 'internal' === type)) ? (
                <section className="form-fields">
                    <HelpButton id={type + 1}>
                        <strong className="text-capitalize">
                            {projectKeyWord} {type} Rates
                        </strong>{' '}
                        are used as the active rate on the {projectKeyWord} when there are no custom or set{' '}
                        {resourceKeyWord} rates defined already below.
                    </HelpButton>
                    {isRatesFormVisible ? (
                        <RateForm
                            form={`rate-projectForm${type}`}
                            formName={FORM_NAME}
                            fieldName={`projectRates.${type}.defaultRateId`}
                            onDismiss={toggleForm}
                        />
                    ) : (
                        <div>
                            <p className="title text-capitalize">
                                {type} {projectKeyWord} Hourly Rate:
                            </p>
                            <Field
                                inline
                                name={`projectRates.${type}.defaultRateId`}
                                component={dropdownFieldMaterial}
                                valueField="_id"
                                textField={item =>
                                    item?.label
                                        ? `${item.label}: ${getCurrency(item.currency).symbol} ${item.rate}`
                                        : ''
                                }
                                data={filteredRates}
                            />
                            <FormText>
                                The {type} {projectKeyWord} Rate will be used as the billing rate for all{' '}
                                {resourcePluralKeyWord} who do not have a Custom {resourceKeyWord} Rate or Existing{' '}
                                {resourceKeyWord} Rate specified below&nbsp;
                                <Button onClick={toggleForm} className="xs p-0" color="link">
                                    Add new rate
                                </Button>
                            </FormText>
                        </div>
                    )}
                </section>
            ) : null}
            {isResourceRateExtensionInstalled ? (
                <section className="form-fields">
                    <HelpButton id={type + 2}>
                        <strong className="text-capitalize">
                            {type} {projectKeyWord} Rates
                        </strong>
                        &nbsp; are used on the {projectKeyWord} when a {resourceKeyWord} has an existing rate on their
                        profile. Otherwise the {projectKeyWord} rate is used. If you wish to override this rate for this
                        particular {projectKeyWord}, add a new Custom {type} Rate below.
                    </HelpButton>
                    <span className="title text-capitalize">
                        Existing {type} {resourcePluralKeyWord}
                        {isUnassignedExtensionInstalled ? ' and unassigned rates' : ''}:
                    </span>
                    <FormText>
                        Some {resourcePluralKeyWord}
                        {isUnassignedExtensionInstalled ? ' and unassigned categories' : ''}
                        on this {projectKeyWord} have a unique {type} billing rate. This rate will be used when
                        calculating their effort on this {projectKeyWord}. If you wish to override these add a new
                        Custom Rate below. Click link below to view all existing rates.
                    </FormText>
                    <ExistingResourceRates
                        type={type}
                        existingResourceRatesAndNotCustom={existingResourceRatesAndNotCustom}
                        projectKeyWord={projectKeyWord}
                        resourcePluralKeyWord={resourcePluralKeyWord}
                        rates={rates}
                    />
                </section>
            ) : null}
            {(isResourceRateExtensionInstalled && 'internal' === type) || 'external' === type ? (
                <section className="form-fields border-bottom-0 mb-4">
                    <HelpButton id={type + 3}>
                        <strong className="text-capitalize">
                            {type} {projectKeyWord} Rates
                        </strong>
                        &nbsp; are used on the {projectKeyWord} when a {resourceKeyWord} has an existing rate on their
                        profile. Otherwise the {projectKeyWord} rate is used. If you wish to override this rate for this
                        particular {projectKeyWord}, add a new Custom {type} Rate below.
                    </HelpButton>
                    <p className="title text-capitalize">
                        Custom {type} {resourcePluralKeyWord} Rates:
                    </p>
                    {isResourceRateExtensionInstalled ? (
                        <CustomResourceRates
                            type={type}
                            rates={filteredRates}
                            resourceRates={projectRates.customRates}
                            resources={resources}
                            projectKeyWord={projectKeyWord}
                        />
                    ) : (
                        <MissingExtension
                            description={`You cannot currently adjust an individual ${resourceKeyWord} Rates on a ${projectKeyWord} without the Advanced Billing Rates Extension installed`}
                        />
                    )}
                </section>
            ) : null}
            {!isResourceRateExtensionInstalled && 'internal' === type && (
                <MissingExtension
                    description={`Internal Rates are a great way to track your internal spend on a ${projectKeyWord}. They are not available as the Advanced Billing Rates Extension is not installed`}
                />
            )}
        </>
    );
};

InternalExternalProjectRates.propTypes = {
    type: PropTypes.string,
    onFieldChange: PropTypes.func,
    resources: PropTypes.array,
    projectRates: PropTypes.object,
    selectedResources: PropTypes.array,
    projectKeyWord: PropTypes.string.isRequired,
};

InternalExternalProjectRates.defaultProps = {
    projectRates: {},
    resources: [],
    selectedResources: [],
    onFieldChange: null,
    type: 'external',
};

export default InternalExternalProjectRates;
