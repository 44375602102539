const { useCallback, useMemo } = require('react');
import { updateAccountNotifications } from 'actions/accountActions';
import { hideModal as hideModalAction } from 'actions/modalActions';
import { addHours, addMinutes } from 'date-fns';
import { closeActionsTitles, snoozeHours } from 'shared/constants/snoozeOptions';
import { getAccountNotifications } from 'selectors/account';
import { mfaReminderTypes } from 'shared/constants/mfaReminders';
const { useDispatch, useSelector } = require('react-redux');

export const useMFARemindersLogic = ({
    type,
    afterSkip,
    onConfirmAction,
    postponeOnHide,
    disableOnConfirm,
    skipMinutes = 5,
}) => {
    const dispatch = useDispatch();
    const notifications = useSelector(getAccountNotifications);

    const onSkip = useCallback(
        (snoozedUntilDate, silentUpdate = false) => {
            const otherNotifications = notifications.filter(n => n.category !== type);
            dispatch(
                updateAccountNotifications.request(
                    [
                        ...otherNotifications,
                        {
                            category: type,
                            channel: 'IN_APP',
                            enabled: Boolean(snoozedUntilDate),
                            modifiedDate: new Date().toISOString(),
                            ...(snoozedUntilDate ? { snoozedUntilDate } : {}),
                        },
                    ],
                    undefined,
                    true,
                    silentUpdate
                )
            );
            afterSkip ? afterSkip(silentUpdate) : dispatch(hideModalAction());
        },
        [dispatch, notifications, type, afterSkip]
    );

    const hideModal = useCallback(() => {
        if (postponeOnHide) {
            onSkip(addMinutes(new Date(), skipMinutes).toISOString(), true);
        }
        dispatch(hideModalAction());
    }, [dispatch, skipMinutes, onSkip, postponeOnHide]);

    const skipOptions = useMemo(
        () => [
            ...(type !== mfaReminderTypes.MFA_USER_HARD_REMINDER
                ? [
                      {
                          title: closeActionsTitles.SKIP,
                          action: () => onSkip(),
                      },
                  ]
                : []),
            {
                title: closeActionsTitles.REMIND_TOMORROW,
                action: () => onSkip(addHours(new Date(), snoozeHours.DAY).toISOString()),
            },
            {
                title: closeActionsTitles.SNOOZE_48,
                action: () => onSkip(addHours(new Date(), snoozeHours.TWO_DAYS).toISOString()),
            },
            {
                title: closeActionsTitles.SNOOZE_WEEK,
                action: () => onSkip(addHours(new Date(), snoozeHours.WEEK).toISOString()),
            },
        ],
        [onSkip, type]
    );

    const onConfirm = useCallback(() => {
        onSkip(disableOnConfirm ? undefined : addMinutes(new Date(), skipMinutes).toISOString(), true);

        dispatch(hideModalAction());
        onConfirmAction?.();
    }, [dispatch, onSkip, onConfirmAction, skipMinutes, disableOnConfirm]);

    return { onConfirm, onSkip, skipOptions, hideModal };
};
