import React from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import { Person, CalendarToday } from '@material-ui/icons';
import { formatDate } from 'utils/DateUtil';
import { formatDistanceToNow } from 'date-fns';
import { BookingInfo, BookingInfoItem } from 'modules/modals/styles';

const BookingInfoTab = ({ tabId, booking }) => {
    const createdDate = booking.createdDate ? new Date(booking.createdDate) : null;
    const updatedDate = booking.updatedDate ? new Date(booking.updatedDate) : null;

    return (
        <TabPane tabId={tabId}>
            <BookingInfo>
                <BookingInfoItem>
                    <CalendarToday /> <span>Created Date:</span>{' '}
                    {createdDate && formatDistanceToNow(createdDate, { addSuffix: true })} on{' '}
                    {formatDate(createdDate, 'LLLL dd, yyyy HH:mm', false)}
                </BookingInfoItem>
                <BookingInfoItem>
                    <Person /> <span>Created By:</span>{' '}
                    {booking?.bookingCreator?.firstName
                        ? `${booking.bookingCreator.firstName} ${booking.bookingCreator.lastName}`
                        : '-'}
                </BookingInfoItem>
                <BookingInfoItem>
                    <CalendarToday /> <span>Updated Date:</span>{' '}
                    {updatedDate && formatDistanceToNow(updatedDate, { addSuffix: true })} on{' '}
                    {formatDate(updatedDate, 'LLLL dd, yyyy HH:mm', false)}
                </BookingInfoItem>
                <BookingInfoItem>
                    <Person /> <span>Last Updated By:</span>{' '}
                    {booking?.bookingUpdater?.firstName
                        ? `${booking.bookingUpdater.firstName} ${booking.bookingUpdater.lastName}`
                        : '-'}
                </BookingInfoItem>
            </BookingInfo>
        </TabPane>
    );
};

BookingInfoTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    booking: PropTypes.object,
};

BookingInfoTab.defaultProps = {
    booking: {},
};

export default BookingInfoTab;
