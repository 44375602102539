import * as actionTypes from './actionTypes';
import { TYPE_TAB } from '../tabs';
import { cloneDeep, filter, find } from 'lodash';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject } from 'reducers/helpers/updater';
import { GROUP } from 'enums/groupTypeEnum';
import { getInitialFilters } from 'modules/scheduler/utils/builderFiltersUtil';
import {
    INITIAL_FILTER_RELATION,
    INITIAL_FILTERS,
    INITIAL_TITLE,
    FILTERS_WITH_OPERATOR,
} from 'modules/scheduler/modals/builder/consts';
import { arrayToObjectByKey } from 'utils/mappingUtil';

export const initialState = {
    title: INITIAL_TITLE,
    initialTitle: null,
    visibility: 'PRIVATE',
    filterRelation: INITIAL_FILTER_RELATION,
    initialFilterRelation: INITIAL_FILTER_RELATION,
    groupFilterRelation: INITIAL_FILTER_RELATION,
    itemType: {},
    groupType: GROUP,
    initialGroupType: null,
    isSmart: false,
    initialIsSmart: null,
    parentGroupId: '',
    item: {},
    group: {},
    dates: { start: null, end: null, dateState: 'This', periodType: 'month' },
    initialDates: null,
    filters: INITIAL_FILTERS,
    initialFilters: INITIAL_FILTERS,
    initiallyAppliedFilters: {
        filters: INITIAL_FILTERS,
    },
    groupFilters: {
        filters: INITIAL_FILTERS,
    },
    activeTab: TYPE_TAB,
    selectedItems: [],
    selectedGroups: [],
    initialItems: null,
    initialGroups: null,
    itemsToAdd: [],
    itemsToRemove: [],
    groupsToAdd: [],
    groupsToRemove: [],
};

const setTab = (state, action) => ({
    ...state,
    activeTab: action.payload.tabId,
});

const setFilterOperator = (state, action) => {
    const { name, operator } = action.payload;
    const preparedFilters = { [name]: { ...state.filters[name], operator } };

    const filters = updateObject(state.filters, preparedFilters);
    const initialFilters = getInitialFilters(state, filters);

    return {
        ...state,
        initialFilters,
        filters,
    };
};

const updateFilters = (state, action) => {
    const { filters: actionFilters, options } = action.payload;
    const { appliedFiltersContext } = options || {
        appliedFiltersContext: false,
    };

    let filters;

    if (!appliedFiltersContext) {
        const preparedFilters = Object.fromEntries(
            Object.entries(actionFilters).map(([filterName, filter]) => {
                if (['resourceIsPm'].includes(filterName)) return [filterName, filter];
                if (['projectDates'].includes(filterName)) return [filterName, filter];
                // if all/any operator
                if (FILTERS_WITH_OPERATOR.includes(filterName)) {
                    return [
                        filterName,
                        { ...state.filters[filterName], filters: filter.filters, operator: filter.operator },
                    ];
                }
                return [filterName, { ...state.filters[filterName], filters }];
            })
        );

        filters = updateObject(state.filters, preparedFilters);
    } else {
        filters = state.filters;
    }

    const initialFilters = getInitialFilters(state, filters);

    return {
        ...state,
        initialFilters,
        filters,
    };
};

const initWithGroup = (state, action) => {
    const {
        group,
        useSchedulerAppliedFilters,
        items,
        groups,
        itemType,
        filterRelation,
        schedulerFilters,
        groupFilters,
    } = action.payload;
    const title = group.name || state.title;
    const initialTitle = state.initialTitle ? state.initialTitle : title;

    const itemIds = group?.queryParams?.itemIds || [];
    const groupIds = group?.queryParams?.itemGroupIds || [];
    const selectedItemsFromGroup = items.filter(item => itemIds.includes(item._id));
    const selectedGroupsFromGroup = groups.filter(groupItem => groupIds.includes(groupItem._id));

    const filters = useSchedulerAppliedFilters
        ? {
              ...INITIAL_FILTERS,
              ...groupFilters,
              ...schedulerFilters,
              resourceIsPm: state.filters.resourceIsPm ?? groupFilters.resourceIsPm,
          }
        : groupFilters;

    return {
        ...state,
        group: { ...state.group, ...group },
        isSmart: group.isSmart,
        title,
        initialTitle,
        itemType,
        groupType: group.type || GROUP,
        visibility: group.visibility || 'PRIVATE',
        filterRelation: (useSchedulerAppliedFilters ? filterRelation : group.queryParams.filterRelation) || INITIAL_FILTER_RELATION,
        initialFilterRelation: (useSchedulerAppliedFilters ? filterRelation : group.queryParams.filterRelation) || INITIAL_FILTER_RELATION,
        groupFilterRelation: group.queryParams.filterRelation ?? INITIAL_FILTER_RELATION,
        filters: cloneDeep(filters),
        groupFilters: {
            filters: cloneDeep(groupFilters),
        },
        initiallyAppliedFilters: {
            filters: cloneDeep(useSchedulerAppliedFilters ? schedulerFilters : groupFilters),
        },
        selectedItems: selectedItemsFromGroup,
        selectedGroups: selectedGroupsFromGroup,
        initialGroupType: group.type || GROUP,
        initialIsSmart: group.isSmart,
        initialItems: selectedItemsFromGroup,
        initialGroups: selectedGroupsFromGroup,
        initialized: true,
    };
};

const setTitle = (state, action) => {
    const title = action.payload.title;

    return {
        ...state,
        title,
    };
};

const setItemType = (state, action) => ({
    ...state,
    selectedGroups: [],
    selectedItems: [],
    itemType: action.payload.itemType,
});

const setGroupType = (state, action) => {
    const groupType = action.payload.groupType || GROUP;

    return {
        ...state,
        groupType,
    };
};

const setDates = (state, action) => {
    const dates = action.payload.dateData;

    return {
        ...state,
        dates,
        filters: {
            ...state.filters,
            smartFilters: {
                ...state.filters.smartFilters,
                filters: {
                    ...state.filters.smartFilters.filters,
                    dates,
                },
            },
        },
    };
};

const setIsSmart = (state, action) => {
    const { isSmart } = action.payload;

    if (!isSmart && !state.group?._id) {
        return {
            ...state,
            isSmart,
            filters: {
                ...INITIAL_FILTERS,
                smartFilters: state.filters.smartFilters,
            },
        };
    }

    return {
        ...state,
        isSmart,
    };
};

const setFilterRelation = (state, action) => {
    const filterRelation = action.payload.filterRelation || INITIAL_FILTER_RELATION;
    const initialFilterRelation = !state.initialFilterRelation ? filterRelation : state.initialFilterRelation;

    return {
        ...state,
        filterRelation,
        initialFilterRelation,
    };
};

const addItem = (state, action) => {
    const selectedItems = [...state.selectedItems, action.payload.item];

    return {
        ...state,
        selectedItems,
        itemsToAdd: [...state.itemsToAdd, action.payload.item],
        itemsToRemove: filter(state.itemsToRemove, item => item._id !== action.payload.item._id),
    };
};

const addManyItems = (state, action) => {
    const selectedItems = [...state.selectedItems, ...action.payload.items];
    const itemsById = arrayToObjectByKey(action.payload.items, '_id');

    return {
        ...state,
        selectedItems,
        itemsToAdd: [...state.itemsToAdd, ...action.payload.items],
        itemsToRemove: filter(state.itemsToRemove, item => !itemsById[item._id]),
    };
};

const removeItem = (state, action) => {
    const existsInAdd = find(state.itemsToAdd, item => item._id === action.payload.item._id);
    const selectedItems = filter(state.selectedItems, item => item._id !== action.payload.item._id);

    return {
        ...state,
        selectedItems,
        itemsToAdd: existsInAdd
            ? filter(state.itemsToAdd, item => item._id !== action.payload.item._id)
            : state.itemsToAdd,
        itemsToRemove: !existsInAdd ? [...state.itemsToRemove, action.payload.item] : state.itemsToRemove,
    };
};

const removeManyItems = (state, action) => {
    const itemsById = arrayToObjectByKey(action.payload.items, '_id');

    let groupsState = {
        selectedGroups: state.selectedGroups,
        groupsToAdd: state.groupsToAdd,
        groupsToRemove: state.groupsToRemove,
    };

    const groups = action.payload.items.filter(item => item.listType === 'group');

    if (groups.length) {
        groupsState = {
            selectedGroups: state.selectedGroups.filter(item => !itemsById[item._id]),
            groupsToAdd: state.groupsToAdd.filter(item => !itemsById[item._id]),
            groupsToRemove: [...state.groupsToRemove, ...(groups || [])],
        };
    }

    const itemsToAdd = state.itemsToAdd.filter(item => !itemsById[item._id]);
    const selectedItems = state.selectedItems.filter(item => !itemsById[item._id]);
    const itemsToRemove = [...state.itemsToRemove, ...(action.payload.items || [])];

    return {
        ...state,
        ...groupsState,
        selectedItems,
        itemsToAdd,
        itemsToRemove,
    };
};

const addGroup = (state, action) => {
    const selectedGroups = [...state.selectedGroups, action.payload.group];

    return {
        ...state,
        selectedGroups,
        groupsToAdd: [...state.groupsToAdd, action.payload.group],
        groupsToRemove: filter(state.groupsToRemove, group => group._id !== action.payload.group._id),
    };
};

const addManyGroups = (state, action) => {
    const selectedGroups = [...state.selectedGroups, ...action.payload.groups];
    const groupsById = arrayToObjectByKey(action.payload.groups, '_id');

    return {
        ...state,
        selectedGroups,
        groupsToAdd: [...state.groupsToAdd, ...action.payload.groups],
        groupsToRemove: filter(state.groupsToRemove, group => !groupsById[group._id]),
    };
};

const removeGroup = (state, action) => {
    const existsInAdd = find(state.groupsToAdd, group => group._id === action.payload.group._id);
    const selectedGroups = filter(state.selectedGroups, group => group._id !== action.payload.group._id);

    return {
        ...state,
        selectedGroups,
        groupsToAdd: existsInAdd
            ? filter(state.groupsToAdd, group => group._id !== action.payload.group._id)
            : state.groupsToAdd,
        groupsToRemove: !existsInAdd ? [...state.groupsToRemove, action.payload.group] : state.groupsToRemove,
    };
};

const initWithoutGroup = (state, action) => {
    const {
        itemType,
        title,
        isSmart,
        groupType,
        filterRelation,
        schedulerFilters,
        useSchedulerAppliedFilters,
        initialGroups,
        selectedGroups,
    } = action.payload;

    return {
        ...state,
        itemType,
        title,
        isSmart,
        groupType,
        filters: useSchedulerAppliedFilters ? schedulerFilters : INITIAL_FILTERS,
        groupFilters: {
            filters: INITIAL_FILTERS,
        },
        initiallyAppliedFilters: {
            filters: INITIAL_FILTERS,
        },
        filterRelation,
        initialGroups,
        initialItems: [],
        selectedGroups,
        initialized: true,
    };
};

const preSetData = (state, action) => {
    const { initialTab, filters, itemType, parentGroupId } = action.payload;
    return {
        ...state,
        filters,
        activeTab: initialTab,
        itemType,
        parentGroupId,
    };
};

const resetBuilder = () => {
    return initialState;
};

export const builderReducer = createReducer(initialState, {
    [actionTypes.SET_GROUP]: initWithGroup,
    [actionTypes.SET_TAB]: setTab,
    [actionTypes.SET_TITLE]: setTitle,
    [actionTypes.SET_ITEM_TYPE]: setItemType,
    [actionTypes.SET_GROUP_TYPE]: setGroupType,
    [actionTypes.SET_DATES]: setDates,
    [actionTypes.UPDATE_FILTERS]: updateFilters,
    [actionTypes.SET_IS_SMART]: setIsSmart,
    [actionTypes.SET_FILTER_RELATION]: setFilterRelation,
    [actionTypes.ADD_ITEM]: addItem,
    [actionTypes.ADD_MANY_ITEMS]: addManyItems,
    [actionTypes.ADD_MANY_GROUPS]: addManyGroups,
    [actionTypes.REMOVE_MANY_ITEMS]: removeManyItems,
    [actionTypes.REMOVE_ITEM]: removeItem,
    [actionTypes.ADD_GROUP]: addGroup,
    [actionTypes.REMOVE_GROUP]: removeGroup,
    [actionTypes.SET_FILTER_OPERATOR]: setFilterOperator,
    [actionTypes.RESET_BUILDER]: resetBuilder,
    [actionTypes.PRE_SET_DATA]: preSetData,
    [actionTypes.INITIALIZE_V2_WITH_GROUP]: initWithGroup,
    [actionTypes.INITIALIZE_V2_WITHOUT_GROUP]: initWithoutGroup,
});
