import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getCategoryTemplates = {
    request: () => action(actionTypes.GET_CATEGORY_TEMPLATES['REQUEST']),
    success: (categoryTemplates, withAllocationText) =>
        action(actionTypes.GET_CATEGORY_TEMPLATES['SUCCESS'], { categoryTemplates, withAllocationText }),
    failure: () => action(actionTypes.GET_CATEGORY_TEMPLATES['FAILURE']),
    stop: () => action(actionTypes.GET_CATEGORY_TEMPLATES_STOP),
};

export const createCategoryTemplate = {
    request: (data, callback) => action(actionTypes.CREATE_CATEGORY_TEMPLATE['REQUEST'], { data, callback }),
    success: (categoryTemplate, withAllocationText) =>
        action(actionTypes.CREATE_CATEGORY_TEMPLATE['SUCCESS'], { categoryTemplate, withAllocationText }),
};

export const updateCategoryTemplate = {
    request: (categoryTemplateId, data, callback) =>
        action(actionTypes.UPDATE_CATEGORY_TEMPLATE['REQUEST'], { categoryTemplateId, data, callback }),
    success: (categoryTemplate, withAllocationText) =>
        action(actionTypes.UPDATE_CATEGORY_TEMPLATE['SUCCESS'], { categoryTemplate, withAllocationText }),
};

export const deleteCategoryTemplate = {
    request: (categoryTemplateId, cb) =>
        action(actionTypes.DELETE_CATEGORY_TEMPLATE['REQUEST'], { categoryTemplateId, cb }),
    success: categoryTemplate => action(actionTypes.DELETE_CATEGORY_TEMPLATE['SUCCESS'], { categoryTemplate }),
};
