import { DayPilot } from 'daypilot-pro-react';
import { without } from 'lodash';
import { hasRole } from 'utils/rightsUtil';
import { TYPE_REGULAR } from 'enums/resourceEnum';
import { RESOURCES } from 'modules/scheduler/enums/schedulerFilterEnum';

export default (accountRoleRights, groupBookings, keyWords, actions) => (project, resource) => {
    const projectResourceDayPilotMenu = new DayPilot.Menu({
        theme: 'hub-context',
        cssClassPrefix: 'hub-context',
        hideOnMouseOut: false,
        useShadow: true,
        zIndex: 10,
        items: [],
    });

    if (groupBookings) {
        projectResourceDayPilotMenu.items.push({
            text: 'Toggle grouping',
            onClick: ({ source }) => {
                let hasExpanded = 0 < source.groups.expanded().length;

                if (hasExpanded) {
                    source.groups.collapseAll();
                } else {
                    source.groups.expandAll();
                }
            },
        });
    }

    if (resource.hasRightsToResource && hasRole(accountRoleRights, 'settingProjectRes')) {
        projectResourceDayPilotMenu.items.push({
            text: `Remove From ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                const data = without(project.resources, source.tags.resource._id);
                actions?.updateProject(project._id, { project: { resources: data } });
            },
        });
    }

    if (hasRole(accountRoleRights, 'gridFilter')) {
        projectResourceDayPilotMenu.items.push({
            text: `Filter this ${keyWords.resourceKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                source.calendar.rows.filter(source.tags.resource._id);
                actions?.addFilter(RESOURCES.filterListProp, source.tags.resource._id);
            },
        });
    }

    if (resource.hasRightsToResource && hasRole(accountRoleRights, 'settingAddEditResources')) {
        projectResourceDayPilotMenu.items.push({
            text: '-',
        });
        projectResourceDayPilotMenu.items.push({
            text: `Edit ${keyWords.resourceKeyWord.toLowerCase()}...`,
            onClick: ({ source }) => {
                const resource = source.tags.resource;
                if (resource && TYPE_REGULAR.value === resource.type) {
                    actions?.showResourceModal(source.tags.resource._id, resource.type);
                }
            },
        });
    }

    return projectResourceDayPilotMenu;
};
