import { map, isUndefined } from 'lodash';
import { MIN_COLUMN_WIDTH } from 'constants';

export default (updateSchedulerColumns, schedulerRef) => ({ column }) => {
    const schedulerColumns = schedulerRef.current.control.schedulerColumns;
    const width = column.width < MIN_COLUMN_WIDTH ? MIN_COLUMN_WIDTH : column.width;

    if (!column.id && isUndefined(column.type)) {
        const newDefaultColumn = {
            ...schedulerColumns[`${column.viewType}Columns`].defaultColumn,
            columnWidth: width,
        };
        updateSchedulerColumns(column.viewType, {
            defaultColumn: newDefaultColumn,
            customColumns: schedulerColumns[`${column.viewType}Columns`].customColumns,
        });
    } else {
        const newCustomColumns = map(schedulerColumns[`${column.viewType}Columns`].customColumns, customColumn => {
            if (customColumn._id === column.id) {
                return {
                    ...customColumn,
                    columnWidth: width,
                };
            }

            return customColumn;
        });

        updateSchedulerColumns(column.viewType, {
            defaultColumn: schedulerColumns[`${column.viewType}Columns`].defaultColumn,
            customColumns: newCustomColumns,
        });
    }
};
