import styled, { css } from 'styled-components';
import { Close, Check } from '@material-ui/icons';
import { Navbar, UncontrolledDropdown, DropdownItem, DropdownMenu } from 'reactstrap';

export const EnvName = styled.div`
    display: inline-block;
    background-color: #e68a2b;
    border-radius: 4px;
    color: #7d4204;
    font-size: 12px;
    padding: 7px 20px;
    text-transform: uppercase;
    font-weight: bold;
    vertical-align: middle;
`;

export const Title = styled.p`
    padding-left: 15px;
    color: #ccc;
    font-size: 12px;
    margin-bottom: 0.25rem;
    margin-top: 1rem;
`;

export const AccountList = styled.ul`
    list-style: none;
    padding: 0 0 10px;
`;

export const AccountListItem = styled.li`
    border-left: 5px solid #fff;
    &:last-of-type a {
        border-bottom: none;
    }
    &:hover {
        border-left: 5px solid #717171;
        background: #efefef;
        color: #000;
    }
    ${({ selected }) =>
        selected &&
        css`
            border-left: 5px solid #fafafa;
            background: #fafafa;
            color: #000;
            pointer-events: none;
        `}
`;

export const AccountListLink = styled.a`
    text-decoration: none;
    text-align: left;
    padding: 5px 23px 5px 15px;
    color: #000;
    border-bottom: 1px solid #e5e5e5;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    img {
        width: 50px;
        height: 50px;
        align-self: center;
    }
`;

export const Company = styled.span`
    display: inherit;
    text-shadow: none;
    color: #000;
    font-size: 13px;
`;

export const Url = styled.span`
    text-shadow: none;
    color: #bbb;
    font-size: 13px;
`;

export const HelpWrapper = styled.div`
    width: 300px;
    right: -300px;
    top: 50px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    background-color: #f5f5f5;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    visibility: hidden;
    ul.nav li {
        border-bottom: 1px solid #ebebeb;
    }
    &.fade-in {
        right: 0;
        visibility: visible;
    }

    @media (max-width: 991px) {
        top: 41px;
    }
`;

export const CloseIcon = styled(Close)`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
        color: rgb(0, 0, 0);
    }
`;

export const NavBarFixed = styled(Navbar)`
    padding: 0 0 0 15px !important;
    border-bottom: 1px solid #555;
    box-shadow: 0 1px 0 #333;
    background: #3f3f3f;
    background: -moz-linear-gradient(top, #3f3f3f 0%, #222222 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #3f3f3f), color-stop(100%, #222222));
    background: -webkit-linear-gradient(top, #3f3f3f 0%, #222222 100%);
    background: -o-linear-gradient(top, #3f3f3f 0%, #222222 100%);
    background: -ms-linear-gradient(top, #3f3f3f 0%, #222222 100%);
    background: linear-gradient(to bottom, #3f3f3f 0%, #222222 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3f3f3f', endColorstr='#222222', GradientType=0);

    .navbar-brand {
        color: #fff;
        img {
            margin-right: 10px;
        }
    }
    .navbar-nav > li.vacation-event > a {
        max-width: 120px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: inherit;
    }

    ul > li > a.nav-link {
        color: #9d9d9d;
        position: relative;
        display: inline-block;
        padding: 15px 12px !important;
        font-size: 13px;
        border-left: 1px solid #444;
        text-decoration: none;
        &:hover {
            color: #fff;
        }
        i {
            font-size: 17px;
            vertical-align: middle;
        }

        + .dropdown-toggle {
            display: inline-block;
            padding-top: 0;
            padding-bottom: 0;
            background-color: inherit;
            border: none;
            padding-left: 0;
            color: #9d9d9d;
            &:focus {
                box-shadow: none;
            }
            &:hover {
                color: #fff;
            }
        }
    }

    @media (max-width: 991px) {
        padding: 0 0 0 5px;
        .nav-link {
            border-left: none;
        }
        .navbar-nav {
            height: calc(100vh - 50px);
            overflow-y: scroll;
        }
    }

    @media (min-width: 992px) and (max-width: 1024px) {
        .nav-item.vacation-event > .nav-link {
            max-width: 81px;
        }
    }

    .navbar-toggler {
        background: transparent;
        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgb(157, 157, 157)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }
    }
`;

export const TrialDropdownItem = styled(DropdownItem)`
    &:hover {
        color: #333;
        background-color: transparent !important;
    }
`;

export const TrialDropdown = styled(UncontrolledDropdown)`
    .trial-color {
        color: #468847;
    }
    &.about-to-expire {
        background-color: #c04d31;
        .trial-color {
            color: #fff;
        }
    }
`;

export const PlusDropdown = styled(DropdownMenu)`
    font-size: 14px;
    .dropdown-item.vacation-event {
        max-width: 200px;
    }
`;

export const ProfileWrapper = styled.div`
    font-weight: 400;
    font-size: 14px;
    max-height: 430px;
    min-width: 330px;
    padding-top: 0;
    overflow-y: scroll;
    .dropdown-item:hover {
        background-color: initial;
    }
    .profile-name {
        font-weight: bold;
        padding-top: 9px;
    }
    .dropdown-item {
        padding-left: 0;
        padding-right: 0;
    }
    .profile-email {
        color: #999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .profile-links a {
        text-decoration: none;
        color: #08c !important;
        cursor: pointer;
        &:hover {
            color: #005580 !important;
        }
    }
    .user-thumb-header {
        float: left;
        height: 80px;
        margin: 10px 13px 16px 16px;
        width: 80px;
        border: 2px solid #ebebeb;
        position: relative;
        a img {
            height: auto;
            max-width: 100%;
            vertical-align: middle;
            border: 0;
        }
        &:before {
            color: #333;
            content: 'change photo';
            font-size: 11px;
            bottom: 0;
            padding: 2px 6px;
            position: absolute;
            display: none;
            text-align: center;
            background-color: #43b897;
            opacity: 0.8;
        }
        &:hover:before {
            display: block;
            transition: opacity 0.218s ease-in-out;
            -webkit-transition: opacity 0.218s ease-in-out;
        }
    }
`;

export const NoIcon = styled.span`
    padding-left: 24px;
`;

export const CheckIcon = styled(Check)`
    vertical-align: top;
`;

export const NoExtension = styled.div`
    color: rgb(174, 174, 174);
    text-align: center;
    width: 280px;
    .lockedArea {
        font-size: 22px;
        color: #fff;
        font-weight: 300;
    }
    .locked-area-title {
        font-size: 24px;
        font-weight: 300;
        margin-bottom: 20px;
        color: #333;
        line-height: 30px;
        span {
            margin-left: 5px;
        }
    }
    p {
        font-size: 14px;
        font-weight: 400;
        color: #aeaeae;
    }
    .btn-try-ext {
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;
        padding: 4px 12px;
        color: #333;
        line-height: 30px;
    }
    .locked-area-text i {
        font-size: 25px;
    }
`;
