import { DAY, CELLDURATION } from 'modules/scheduler/enums/scale';
import { max, min } from 'lodash';

const onIncludeTimeCell = (companySettings, schedulerRef) => args => {
    const { scale: currentScale } = schedulerRef.current.control || {};
    const dayOfWeek = new Date(args.cell.start.value).toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
    const workDay = companySettings.weekDays[dayOfWeek];

    //TODO: to be moved to store avoid calc on each cell render
    const start = min(workDay?.intervals.map(interval => interval.start).filter(Boolean));
    const end = max(workDay?.intervals.map(interval => interval.end).filter(Boolean));

    const workDayEndDay = end / 60;
    const workDayStartDay = start / 60;
    const dayPilotCells = parseFloat(args.cell.start.getHours() + args.cell.start.getMinutes() / 100);

    if (
        currentScale === CELLDURATION.value &&
        (start === undefined ||
            end === undefined ||
            dayPilotCells >= workDayEndDay || dayPilotCells < workDayStartDay || !workDay.workDay)
    ) {
        args.cell.visible = false;
    } else if (companySettings.grid.hideNonWorkingDays && currentScale === DAY.value) {
        if (!workDay.workDay) {
            args.cell.visible = false;
        }
    }
};

export default onIncludeTimeCell;
