import { useSchedulerProjectsListContext } from '../../lists/projectList/schedulerProjectsListProvider';
import { NewShortEvent, NewShortProject } from './index';
import React from 'react';
import PropTypes from 'prop-types';

const NewShortProjectOrEvent = props => {
    const { rowTags, slideRef, onCreate, assignResources } = props;
    const { isEventsFilterApplied, projectName, setProjectName } = useSchedulerProjectsListContext();

    if (rowTags.isEventRow || isEventsFilterApplied) {
        return (
            <NewShortEvent
                initialName={projectName}
                onCreate={event => {
                    slideRef.current?.triggerStart();
                    setProjectName('');
                    onCreate({ project: event })
                }}
            />
        );
    }

    return (
        <NewShortProject
            assignResources={assignResources}
            initialName={projectName}
            onCreate={project => {
                slideRef.current?.triggerStart();
                setProjectName('');
                onCreate({ project });
            }}
        />
    );
};

NewShortProjectOrEvent.propTypes = {
    rowTags: PropTypes.object,
    onCreate: PropTypes.func,
    slideRef: PropTypes.object,
    assignResources: PropTypes.array,
};

NewShortProjectOrEvent.defaultProps = {
    rowTags: {},
    onCreate: () => {},
    slideRef: { current: undefined },
    assignResources: [],
};

export default React.memo(NewShortProjectOrEvent);
