import React from 'react';
import { FormControlLabel, Checkbox as MUICheckbox, Radio, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useField} from 'formik';
const useFormControllabelStyles = makeStyles({
    root: {
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        '& .MuiTypography-body1': {
            fontSize: '13px',
        },
        '& .PrivateSwitchBase-root-27': {
            padding: '4px',
        },
    },
});

export const Checkbox = React.memo(props => {
    const { label, disabled, classes, className, type = 'checkbox' } = props;
    const { inputProps, checked, onChange, name, dataCy, ...restProps } = props;
    const formControlLabelClasses = useFormControllabelStyles();

    const Component =
        type === 'checkbox' ? (
            <MUICheckbox
                color="primary"
                {...restProps}
                data-cy={dataCy}
                name={inputProps?.name || name}
                onChange={inputProps?.onChange || onChange}
                checked={inputProps?.checked || checked}
            />
        ) : (
            <Radio
                color="primary"
                data-cy={dataCy}
                {...restProps}
                name={inputProps?.name || name}
                onChange={inputProps?.onChange || onChange}
                checked={inputProps?.checked || checked}
            />
        );

    return disabled ? (
        <Tooltip
            title="You need to first fill out the required fields on the left to enable this option."
            placement="top"
            arrow
        >
            <FormControlLabel
                className={classes?.root || className}
                classes={formControlLabelClasses}
                control={Component}
                label={label}
            />
        </Tooltip>
    ) : (
        <FormControlLabel
            className={classes?.root || className}
            classes={formControlLabelClasses}
            control={Component}
            label={label}
        />
    );
});

export const CheckboxReduxFormWrapper = props => {
    const { input, label, dataCy, disabled, className, type } = props;

    return (
        <Checkbox
            name={input.name}
            checked={input.checked}
            label={label}
            onChange={input.onChange}
            disabled={disabled}
            className={className}
            type={type}
            dataCy={dataCy}
            value={input.value}
        />
    );
};

export const CheckBoxFormikWrapper = React.memo(props => {
    const [field, meta] = useField(props);

    return <Checkbox {...field} {...props} checked={props.value === field.value} meta={meta} />
})

Checkbox.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    classes: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string,
};
