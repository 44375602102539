import { values, find } from 'lodash';

export const DISABLED = { value: 'DISABLED', label: 'Disabled' };
export const INSTANT = { value: 'INSTANT', label: 'Instant' };
export const HALF_SECOND = { value: '500', label: '0.5 seconds' };
export const ONE_SECOND = { value: '1000', label: '1 second' };
export const ONE_HALF_SECONDS = { value: '1500', label: '1.5 seconds' };
export const TWO_SECONDS = { value: '2000', label: '2 seconds' };
export const TWO_HALF_SECONDS = { value: '2500', label: '2.5 seconds' };
export const FIVE_SECONDS = { value: '5000', label: '5 seconds' };

export const bookingToolTipHoverDelays = {
    DISABLED,
    INSTANT,
    HALF_SECOND,
    ONE_SECOND,
    ONE_HALF_SECONDS,
    TWO_SECONDS,
    TWO_HALF_SECONDS,
    FIVE_SECONDS,
};

export const bookingToolTipHoverDelaysArray = values(bookingToolTipHoverDelays);

export const findBookingToolTipHoverDelayByValue = value =>
    find(bookingToolTipHoverDelaysArray, bookingToolTipHoverDelay => bookingToolTipHoverDelay.value === value);
