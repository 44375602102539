import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { values } from 'underscore';
import moment from 'moment';
import { FormText, Row, Col } from 'reactstrap';
import { chooseField, inputGroupTextField, textField } from 'shared/formFields';
import allocationTypes from 'enums/allocationTypeEnum';
import { Section } from './styles';
import getAllocationValuesChanges from './getAllocationValuesChanges';
import { getBookingAllocationValuesFromSingleValue } from 'shared/lib/booking';
import uuid from 'uuid/v1';
import { useSelector } from 'react-redux';
import { getResourceCapacity } from 'utils/capacityCalculation';
import { getResources } from 'selectors/resource';

const allocationTypesArray = values(allocationTypes);

// Deprecated, use formikAllocation instead
const Allocation = React.memo(props => {
    const {
        title,
        formText,
        onChange,
        allocationDefaultState,
        allocationDefaultValues,
        small,
        medium,
        onEnterPressed,
        currentSelectedBooking
    } = props;

    const [state, setState] = useState(allocationTypes.PERCENTAGE.state);

    const avgDailyCapacity = useSelector(state => state.companyReducer.company.settings.report.avgDailyCapacity);
    const resources = useSelector(getResources);

    const [elementsIds] = useState(
        allocationTypesArray.map(() => {
            return uuid();
        })
    );

    useEffect(() => {
        allocationDefaultState && setState(allocationDefaultState);
    }, [allocationDefaultState]);

    const changeAllocationState = useCallback(
        state => {
            setState(state);
            onChange({ state });
        },
        [setState, onChange]
    );

    const onClickChooseField = useCallback(
        (state, i) => {
            changeAllocationState(state);
            document.getElementById(elementsIds[i]) && document.getElementById(elementsIds[i]).focus();
        },
        [changeAllocationState, elementsIds]
    );

    const onKeyPress = useCallback(
        event => {
            if (13 === event.which || 13 === event.keyCode) {
                onEnterPressed && onEnterPressed();
                return false;
            }
            return true;
        },
        [onEnterPressed]
    );

    const normalizeAllocation = useCallback((value, previousValue) => {
        if (!value) {
            return value;
        }
        value = value.replace(/[^0-9\.]/g, '');
        if (2 < value.split('.').length) value = value.replace(/\.+$/, '');
        if (!previousValue || value.length > previousValue.length) {
            return value;
        }
    }, []);

    const onAllocationValuesChangeCurrentSelectedBooking = useCallback(
        event => {
            let nameOfValue, value;
            if (event.currentTarget) {
                nameOfValue = event.currentTarget.name;
                value = Math.abs(parseFloat(event.currentTarget.value));
            } else {
                nameOfValue = event.name;
                value = Math.abs(parseFloat(event.value));
            }   

            const { resourceId, startDate, endDate } = currentSelectedBooking;
            const currentResource = (resources || []).find(({ _id }) => _id === resourceId);

            const startDateTmp = moment(startDate);
            const endDateTmp = moment(endDate);
            const { workDaysCount, totalMinutes: totalBookingMinutes } = getResourceCapacity(
                currentResource,
                startDateTmp,
                endDateTmp
            );

            const { hours, total, percentage } = getBookingAllocationValuesFromSingleValue({
                value: !value || value === '' || Number.isNaN(value) ? 0 : parseFloat(value),
                nameOfValue,
                avgDailyCapacity,
                totalBookingMinutes,
                numberOfWorkDays: workDaysCount,
            });
            onChange({ hours: hours.toString(), total: total.toString(), percentage: percentage.toString() });
        },
        [onChange, avgDailyCapacity,
            currentSelectedBooking, resources]
    );

    const onAllocationValuesChangeDefault = useCallback(
        event => {
            const { hours, total, percentage } = getAllocationValuesChanges(event, allocationDefaultValues);
            onChange({ hours: Math.abs(hours).toString(), total: Math.abs(total).toString(), percentage: Math.abs(percentage).toString() });
        },
        [allocationDefaultValues, onChange]
    );

    if (small || medium) {
        // small block for allocation settings (is used for Dropdown for ex.)
        return (
            <Section className={classNames('pl-3 pt-2 allocation', { 'small-form': small, 'medium-form': medium })}>
                {title && <p className="title">{title}</p>}
                {allocationTypesArray.map((type, i) => (
                    <Row key={type.state}>
                        <Col className="d-flex align-items-center mb-2">
                            <Field
                                type="radio"
                                inline
                                label={type.display}
                                name="state"
                                value={type.state}
                                onChange={() => onClickChooseField(type.state, i)}
                                component={chooseField}
                            />
                            <Field
                                id={elementsIds[i]}
                                width="auto"
                                name={type.valueName}
                                onChange={currentSelectedBooking ? onAllocationValuesChangeCurrentSelectedBooking : onAllocationValuesChangeDefault}
                                onFocus={() => changeAllocationState(type.state)}
                                component={textField}
                                onKeyPress={onKeyPress}
                                normalize={normalizeAllocation}
                                readOnly={type.state !== state}
                            />
                            {'PERCENTAGE' === type.state ? '%' : 'hrs'}
                        </Col>
                    </Row>
                ))}
            </Section>
        );
    }

    // large block for allocation settings (default, is used in modals for ex.)
    return (
        <Section className="form-fields">
            {title && <p className="title">{title}</p>}
            <div className="mb-3">
                {/** state */}
                {allocationTypesArray.map((type, i) => (
                    <Field
                        key={type.state}
                        type="radio"
                        inline
                        label={type.display}
                        name="state"
                        value={type.state}
                        checked={type.state === state}
                        onChange={() => onClickChooseField(type.state, i)}
                        component={chooseField}
                    />
                ))}
            </div>
            {/** values */}
            {allocationTypesArray.map((type, i) => (
                <Field
                    id={elementsIds[i]}
                    key={`${type.state}`}
                    className="mb-2"
                    width="auto"
                    name={type.valueName}
                    icon={'PERCENTAGE' === type.state ? 'fa-percent' : 'fa-clock-o'}
                    onChange={currentSelectedBooking ? onAllocationValuesChangeCurrentSelectedBooking : onAllocationValuesChangeDefault}
                    onFocus={() => changeAllocationState(type.state)}
                    readOnly={type.state !== state}
                    component={inputGroupTextField}
                    normalize={normalizeAllocation}
                />
            ))}
            {formText ? <FormText>{formText}</FormText> : null}
        </Section>
    );
});

Allocation.propTypes = {
    title: PropTypes.string,
    formText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allocationDefaultValues: PropTypes.shape({
        percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    allocationDefaultState: PropTypes.string,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    onEnterPressed: PropTypes.func,
    currentSelectedBooking: PropTypes.object,
};

Allocation.defaultProps = {
    allocationDefaultState: allocationTypes.PERCENTAGE.state,
    small: false,
    medium: false,
    onEnterPressed: null,
};

export default Allocation;
