import React from 'react';
import LegacyResourceSingleMode from './resourceSingleMode';
import LegacyProjectSingleMode from './projectSingleMode';
import LegacyEventRowMode from './eventRowMode';
import { NestedMenuItemManager } from '../../../../../../../shared/nestedMenuItem/context';

const SingleMode = React.forwardRef((props, schedulerRef) => {
    const { rowTags } = props;
    if (rowTags?.isEventRow) {
        return <LegacyEventRowMode {...props} ref={schedulerRef} />;
    }

    if (rowTags?.isProjectRow) {
        return <LegacyProjectSingleMode {...props} ref={schedulerRef} />;
    }

    return <LegacyResourceSingleMode {...props} ref={schedulerRef} />;
});

export default React.forwardRef((props, schedulerRef) => {
    return (
        <NestedMenuItemManager>
            <SingleMode {...props} ref={schedulerRef} />
        </NestedMenuItemManager>
    );
});
