/* eslint-env browser */
import { DayPilot } from 'daypilot-pro-react';
import moize from 'moize';
import { subDays, addDays, format, isWithinInterval } from 'date-fns';
import { forEach, includes, filter, sumBy, map, isString, keys, find, uniq } from 'lodash';
import {
    formatBookingToDP,
    getEventText,
    formatBookingsToDP,
    formatMilestonesToDP,
    formatPhasesToDP,
    formatStartEndTimesToDP,
    getRowId as getSchedulerRowId,
    getDeadlineFromBooking,
} from 'modules/scheduler/utils/eventUtil';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { LEGACY, PARENT, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { DAY, CELLDURATION, WEEK, MONTH } from 'modules/scheduler/enums/scale';
import { SCHEDULER_DATE_BUFFER } from 'constants';
import { WAITING_FOR_APPROVAL, APPROVED, SCHEDULED } from 'enums/bookingTypeEnum';
import { getMilestonesAndPhases, getProjectDates } from '../../../api/project';
import { YEAR_MONTH_DAY_FORMAT } from '../../../global/enums/dateFormat';
import { getProjectColor } from 'shared/lib/projects';
import { eventsForRangeCache } from '../../../utils/eventsForRangeCache';
import { arrayToObject, arrayToObjectByKey } from '../../../utils/mappingUtil';
import { setSchedulerBookingLinks } from '../../../actions/schedulerActions';
import { store } from '../../../store';
import { selectDeadlinesConfig } from '../../../selectors/account';
import {
    selectBookingLinks,
    selectCurrentSelectedBooking,
    selectDeadlineLinks,
    selectSchedulerMode,
} from '../../../selectors/scheduler';
import { PERCENTAGE } from '../../../enums/allocationTypeEnum';
import { getBookings } from '../../../api/booking';
import { deadlineDisplayEnum } from '../enums/deadlines';
import { selectIsDeadlinesExtensionInstalled } from '../../../selectors/company';
import { getViewObjectSelector } from '../../../selectors/router';
import { monitoring } from '../../../monitoring';

const SECONDS_30 = 30;

export const eventsIdsMoving = new Set();

class GetBookingsService {
    // used to dedupe getBookings requests if requested in same time by different components
    _request = false;

    get(filters) {
        if (this._request) {
            return this._request;
        }

        this._request = getBookings(filters);

        this._request.finally(() => {
            this._request = undefined;
        });

        return this._request;
    }
}

export const getBookingsService = new GetBookingsService();

const updateSingleEvent = (event, data) => {
    const currentEvent = event.copy();
    forEach(data, (item, itemName) => {
        if (!includes(['id', 'start', 'end', 'resource', 'project', 'title'], itemName)) {
            currentEvent[itemName] = item;
        } else if (includes(['start', 'end'], itemName)) {
            let date = item;
            if (isString(item)) {
                if (item.length === 'yyyy-M-d HH:mm:ss'.length) {
                    date = DayPilot.Date.parse(item, 'yyyy-M-d HH:mm:ss');
                }
                if (item.length === 'yyyy-MM-dd HH:mm'.length) {
                    date = DayPilot.Date.parse(item, 'yyyy-M-d HH:mm');
                }
            }
            currentEvent[itemName] = !date.value ? new DayPilot.Date(date) : date;
        } else if ('resource' === itemName) {
            currentEvent.resourceInfo = data.resourceInfo || currentEvent.resourceInfo;
            currentEvent[itemName] = item;
        } else if ('project' === itemName) {
            currentEvent[itemName] = item;
            // currentEvent.text = currentEvent.title ? `${currentEvent.title} (${item.name})` : item.name;
        } else if ('title' === itemName) {
            currentEvent[itemName] = item;
            // currentEvent.text = data.title ? `${data.title} (${currentEvent.project.name})` : currentEvent.project.name;
        }
    });

    return currentEvent;
};

/**
 * @param {object} scale
 * @param {bool}   displayWeeksGroup
 * @param {number} slotMinutes
 * @param {number} startMinute
 * @param {number} endMinute
 *
 * @returns {*[]}
 */
export const getTimeHeaders = (scale, displayWeeksGroup, { slotMinutes, startMinute, endMinute }) => {
    let timeHeaders = [
        { groupBy: scale.firstViewHeader, format: scale.firstViewHeaderFormat },
        { groupBy: scale.secondViewHeader, format: scale.secondViewHeaderFormat },
    ];

    if (
        displayWeeksGroup ||
        ('CellDuration' === scale.value && !includes([slotMinutes, startMinute, endMinute], SECONDS_30))
    ) {
        timeHeaders = [
            { groupBy: scale.firstViewHeader, format: scale.firstViewHeaderFormat },
            { groupBy: 'Week' },
            { groupBy: scale.secondViewHeader, format: scale.secondViewHeaderFormat },
        ];
    }

    if ('CellDuration' === scale.value) {
        if (includes([slotMinutes, startMinute, endMinute], SECONDS_30)) {
            timeHeaders = [
                { groupBy: scale.firstViewHeader, format: scale.firstViewHeaderFormat },
                { groupBy: scale.secondViewHeader },
                { groupBy: 'Cell' },
            ];
        } else {
            timeHeaders = [
                { groupBy: scale.firstViewHeader, format: scale.firstViewHeaderFormat },
                { groupBy: scale.secondViewHeader },
            ];
        }
        if (displayWeeksGroup) {
            timeHeaders.splice(1, 0, { groupBy: 'Week' });
        }
    }

    return timeHeaders;
};

/**
 * @param {string} cellWidth
 * @param {string} currentScale
 *
 * @returns {number}
 */
export const scrollToCalculator = (cellWidth, currentScale) => {
    switch (currentScale) {
        case CELLDURATION.value:
            return cellWidth * 9;
        case DAY.value:
            return cellWidth * 7;
        case WEEK.value:
            return cellWidth * 4;
        default:
            return cellWidth * 3;
    }
};

export const getCellUtilizationMinutes = (cell, resourceCapacity, data, preloadedEvents) => {
    const events = preloadedEvents || cell.events();
    const { scale } = cell.calendar;
    let bookedMinutesCell = 0;
    if (!events.length) {
        return 0;
    }

    if (scale === CELLDURATION.value && !data.isNonWorking) {
        const eventsWithIncludeBookedTimeGrid = (events || []).filter(ev => ev?.data?.project?.includeBookedTimeGrid);
        const cellEventsDuration = eventsWithIncludeBookedTimeGrid.reduce((acc, item) => {
            if (item.data.scale.value === CELLDURATION.value) {
                return acc + resourceCapacity;
            }

            if (item?.data?.percentAllocation !== 100) {
                return acc + resourceCapacity * (item?.data?.percentAllocation / 100);
            }

            return acc + resourceCapacity;
        }, 0);

        return cellEventsDuration;
    }

    if (scale === DAY.value) {
        const cellDayDate = cell.start.value.split('T')[0];
        // Sum for each event in the cell the corresponding utilization minutes
        const dayBookedMinutesCell = events.reduce((cellMinutesUtilization, currentEvent) => {
            if (!currentEvent.data?.project?.includeBookedTimeGrid) {
                return cellMinutesUtilization;
            }
            let dayCustomAvailability;
            if (currentEvent.data?.state === PERCENTAGE.state) {
                dayCustomAvailability = {
                    utilizationMinutes: (currentEvent.data?.percentAllocation / 100) * resourceCapacity,
                };
            } else {
                dayCustomAvailability = (currentEvent.data?.minutesPerDayWithCustomAvailability || []).find(
                    dayCustomAvailabilityTemp => dayCustomAvailabilityTemp.date.split('T')[0] === cellDayDate
                );
            }
            const eventUtilization = dayCustomAvailability?.utilizationMinutes || 0;
            return cellMinutesUtilization + eventUtilization;
        }, 0);
        return Math.round((dayBookedMinutesCell + Number.EPSILON) * 1000) / 1000;
    }

    const start = cell.start.toDate();
    const end = cell.end.toDate();

    if (includes([WEEK.value, MONTH.value, DAY.value], scale)) {
        end.setMinutes(-1);
    }

    events.forEach(event => {
        if (!event.data?.project?.includeBookedTimeGrid) {
            return;
        }
        if (event.data.scale.value === CELLDURATION.value && scale !== CELLDURATION.value) {
            //special case due to lunch hour which is deducted from the be created eventMetrics
            bookedMinutesCell = bookedMinutesCell + event.data.details.bookedMinutes;
        } else if ('PERCENTAGE' === event.data.state && scale === DAY.value) {
            bookedMinutesCell += resourceCapacity * (event.data.percentAllocation / 100);
        } else if (includes([WEEK.value, MONTH.value, DAY.value], scale)) {
            bookedMinutesCell =
                bookedMinutesCell +
                sumBy(event.data.minutesPerDayWithCustomAvailability, item => {
                    if (isWithinInterval(new Date(item.date), { start, end })) {
                        return item.utilizationMinutes;
                    }
                    return 0;
                });
        }
    });

    return Math.round((bookedMinutesCell + Number.EPSILON) * 1000) / 1000;
};

/**
 * @returns {{
 *   isResourceGroupView:boolean,
 *   isSingleResourceView:boolean,
 *   isSingleUnassignedView:boolean,
 *   isProjectGroupView:boolean,
 *   isSingleProjectView:boolean,
 *   isResourceView:boolean,
 *   isProjectView:boolean,
 *   isGroupView:boolean,
 *   isSingleView:boolean,
 *   currentView:string,
 * }}
 */
export const getViewObject = moize(
    (params, resourceType) => {
        const viewObject = {
            isResourceGroupView: !!(params.resourceGroupId && !params.resourceId),
            isSingleResourceView: !!params.resourceId,
            isSingleUnassignedView: !!params.resourceId && TYPE_UNASSIGNED.value === resourceType,
            isProjectGroupView: !!(params.projectGroupId && !params.projectId),
            isSingleProjectView: !!params.projectId,
            isResourceView: !!params.resourceGroupId,
            isProjectView: !!params.projectGroupId,
            isGroupView: (params.resourceGroupId && !params.resourceId) || (params.projectGroupId && !params.projectId),
            isSingleView: params.resourceId || params.projectId,
        };
        viewObject['currentView'] = find(keys(viewObject), key => true === viewObject[key]);

        return viewObject;
    },
    { maxSize: 2, maxArgs: 2, isDeepEqual: true }
);

export const invalidateRows = rows => {
    forEach(rows, row => {
        row.cells?.all().invalidate();
    });
};

const getRowId = event => {
    const resource =
        typeof event.resource === 'function'
            ? event.resource()
            : event.resource || event.booking?.resource || event.data?.resource;
    const resourceInfo = event.resourceInfo || event.booking?.resourceInfo || event.data?.resourceInfo;
    const project = event.project || event.booking?.project || event.data?.project;

    if (resource && typeof resource.indexOf === 'function' && -1 !== resource.indexOf('_')) {
        return -1 !== resource.indexOf(resourceInfo?._id) ? resourceInfo?._id : project?._id;
    }

    return resource;
};

export const getRowIdsByEvents = (events, scheduler) => {
    let rowIds = [];
    let rows = [];

    forEach(events, event => {
        if (!event.resource && event.resourceInfo?._id) {
            event.resource = event.resourceInfo._id;
        }

        const row = scheduler.rows.find(getRowId(event));
        if (row && !includes(rowIds, row.id)) {
            rowIds.push(row.id);
            rows.push(row);
        }
        if (row && (row.parent() || row.tags.unassignedRow) && row.parent() && !includes(rowIds, row.parent().id)) {
            rowIds.push(row.parent().id);
            rows.push(row.parent());
        }
    });

    return rows;
};

export const dispatchRefreshDPResourcesEvent = ({ force, cb, rowId } = {}) => {
    window.dispatchEvent(
        new CustomEvent('refreshDPResources', {
            detail: {
                force: force == null ? true : force,
                cb,
                rowId,
            },
        })
    );
};

export const dispatchRefreshBookings = ({ bookingId } = {}) => {
    window.dispatchEvent(
        new CustomEvent('refreshBookings', {
            detail: {
                bookingId,
            },
        })
    );
};

export const dispatchRefreshMilestonesPhases = () => {
    window.dispatchEvent(
        // eslint-disable-next-line no-undef
        new CustomEvent('refreshMilestonesPhases')
    );
};

export const updateVisibleLinksOnScheduler = () => {
    const reduxState = store.getState();

    const links = selectBookingLinks(reduxState);
    const deadlineLinks = selectDeadlineLinks(reduxState);

    window.schedulerRef.current?.control.update({
        links: links.concat(deadlineLinks),
    });
};

export const addDaypilotEvent = (events, scheduler, viewObject, cb) => {
    const dispatch = store.dispatch;
    eventsForRangeCache.clear();
    let newEvents = [];

    let shouldRefreshResources = false;
    forEach(events, event => {
        const currentEvent = scheduler.events.find(event.id || event._id);

        const eventResourceId = event.resource?._id || event.resourceInfo?._id;
        const eventProjectId = event.project?._id || event.projectInfo?._id;

        if (!currentEvent) {
            const [newEvent, deadline] = !event.hasOwnProperty('id')
                ? formatBookingToDP(event, scheduler.mode, viewObject)
                : [event];
            scheduler.events.add(newEvent);
            if (deadline) {
                scheduler.events.add(deadline);
            }
            newEvents.push(newEvent);

            const parentRow = scheduler.rows.find(row => {
                return (
                    row.data.id === eventResourceId ||
                    row.data.id === eventProjectId ||
                    row.children().find(child => {
                        return child.data.id === eventResourceId || child.data.id === eventProjectId;
                    })
                );
            });

            if (!parentRow) {
                shouldRefreshResources = true;
            }
        }
    });

    if (shouldRefreshResources) {
        dispatchRefreshDPResourcesEvent();
    }

    const rows = getRowIdsByEvents(newEvents, scheduler);
    invalidateRows(rows);

    cb && cb();

    dispatch(setSchedulerBookingLinks({ bookings: newEvents }));
};

/**
 * @param {array} eventsToUpdate - start and end dates when string should be formatted in yyyy-M-dTHH:mm
 * @param {object} companySettings
 * @param {DayPilot.scheduler} scheduler
 * @param {array} additionalEvents
 */
export const updateDaypilotEvents = (eventsToUpdate, companySettings, additionalEvents = []) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler) {
        return;
    }

    const dispatch = store.dispatch;
    const reduxState = store.getState();
    const isDeadlinesExtensionInstalled = selectIsDeadlinesExtensionInstalled(reduxState);
    const deadlinesConfig = selectDeadlinesConfig(reduxState);
    let currentSelectedBooking = selectCurrentSelectedBooking(reduxState);

    eventsForRangeCache.clear();
    try {
        const currentEventsToInvalidate = []; //need to invalidate/remove utilization bar when resource has changed
        const newEvents = map(eventsToUpdate, event => {
            if (event.deadline) {
                return null;
            }

            const currentEvent = scheduler.events.find(event.id);
            const schedulerDeadlineObject = scheduler.events.find(`${event.id}_deadline`);

            if (isDeadlinesExtensionInstalled) {
                if (
                    deadlinesConfig.display === deadlineDisplayEnum.show ||
                    (deadlinesConfig.display === deadlineDisplayEnum.showForSelectedBooking &&
                        event.id === currentSelectedBooking.id)
                ) {
                    if (!event.deadlineDate && schedulerDeadlineObject) {
                        scheduler.events.remove(schedulerDeadlineObject);
                    } else if (event.deadlineDate && !schedulerDeadlineObject) {
                        const deadline = getDeadlineFromBooking(event);

                        if (deadline) {
                            scheduler.events.add(deadline);
                        }
                    } else if (event.deadlineDate && schedulerDeadlineObject) {
                        const deadline = getDeadlineFromBooking(event);

                        if (deadline) {
                            scheduler.events.update(deadline);
                        }
                    }
                } else {
                    if (schedulerDeadlineObject) {
                        scheduler.events.remove(schedulerDeadlineObject);
                    }
                }
            }

            if (!currentEvent) {
                addDaypilotEvent([event], scheduler);
                return null;
            }
            const updatedEvent = updateSingleEvent(currentEvent, event);

            if (currentEvent.data.resource !== updatedEvent.resource) {
                currentEventsToInvalidate.push(currentEvent.data);
            }

            if (companySettings) {
                const categoryColorDisplay = companySettings.grid.catColorDisplayNew;

                let bookingColor;
                if (categoryColorDisplay === 'CATEGORY_COLOR_BOOKING') {
                    bookingColor = updatedEvent.categoryTemplate.gridColor;
                } else {
                    if (updatedEvent?.useProjectColor) {
                        bookingColor = getProjectColor(updatedEvent.project);
                    } else {
                        bookingColor = updatedEvent.backgroundColor;
                    }
                }
                updatedEvent.backColor = bookingColor;
            }

            scheduler.events.update(updatedEvent);

            return updatedEvent;
        });

        const eventsToInvalidate = filter(
            [...newEvents, ...additionalEvents, ...currentEventsToInvalidate],
            event => null !== event
        );
        const rows = getRowIdsByEvents(eventsToInvalidate, scheduler);
        rows?.length && invalidateRows(rows);
        dispatch(setSchedulerBookingLinks({ bookings: eventsToInvalidate }));
        updateVisibleLinksOnScheduler();
    } catch (error) {
        monitoring.captureException(error);
        console.log('Event could not be updated. Reason: ', error);
    }
};

export const refreshEvent = (eventId, oldEvent, scheduler, viewObject) => {
    if (!eventId || !scheduler) {
        return;
    }

    const event = scheduler.events.find(eventId);
    const booking = { ...(event?.data || {}), ...(oldEvent || {}) };
    const resource = getSchedulerRowId(booking, scheduler.mode, viewObject);
    scheduler.events.update({ id: eventId, ...booking, resource });
};

export const updateProjectInBookings = (bookings, project, scheduler, viewObject) => {
    eventsForRangeCache.clear();
    forEach(bookings, booking => {
        refreshEvent(
            booking.id,
            { project, text: getEventText(booking.title, project, booking.resource, { mode: scheduler.mode }) },
            scheduler,
            viewObject
        );
    });
    const rows = getRowIdsByEvents(bookings, scheduler);
    invalidateRows(rows);
};

export const updateDaypilotMilestonesPhasesDates = (formattedEvents, scheduler) => {
    eventsForRangeCache.clear();
    const newEvents = map(formattedEvents, event => {
        if (event.previousId) {
            const currentEvent = scheduler.events.find(event.previousId);

            scheduler.events.remove(currentEvent);
            scheduler.events.add({
                ...event,
                previousId: undefined,
            });

            return event;
        }

        const currentEvent = scheduler.events.find(event.id);
        if (currentEvent) {
            scheduler.events.update(event);
        } else {
            scheduler.events.add(event);
        }

        return event;
    });

    const rows = getRowIdsByEvents(newEvents, scheduler);
    invalidateRows(rows);
};

export const addDeadlineToScheduler = ({ booking }) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler) {
        return;
    }

    const schedulerBookingEvent = scheduler.events.find(booking._id);

    if (!schedulerBookingEvent) {
        return;
    }

    const reduxState = store.getState();
    const mode = selectSchedulerMode(reduxState);
    const viewObject = getViewObjectSelector(booking.resource?.type || booking.resourceInfo?.type)(reduxState);

    const [formattedBooking, formattedDeadline] = formatBookingsToDP([booking], mode, viewObject);

    scheduler.events.update(formattedBooking);

    if (formattedDeadline) {
        scheduler.events.add(formattedDeadline);
    }
};

export const addDeadlinesToScheduler = ({ bookings }) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler) {
        return;
    }

    (bookings || []).forEach(booking => {
        addDeadlineToScheduler({ booking });
    });
};

export const updateDeadlinesInScheduler = ({ bookings = [] } = {}) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler) {
        return;
    }

    bookings.forEach(booking => {
        const schedulerBookingEvent = scheduler.events.find(booking._id);

        if (!schedulerBookingEvent) {
            return;
        }

        const reduxState = store.getState();
        const mode = selectSchedulerMode(reduxState);
        const viewObject = getViewObjectSelector(booking.resource?.type || booking.resourceInfo?.type)(reduxState);

        const [formattedBooking, formattedDeadline] = formatBookingsToDP([booking], mode, viewObject);

        scheduler.events.update(formattedBooking);
        if (formattedDeadline) {
            scheduler.events.update(formattedDeadline);
        }
    });
};

export const deleteDeadlineFromScheduler = ({ bookingId }) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler || !bookingId) {
        return;
    }

    const deadlineId = `${bookingId}_deadline`;
    const linkId = `${bookingId}-${deadlineId}`;

    const schedulerDeadlineEvent = scheduler.events.find(deadlineId);
    const schedulerBookingEvent = scheduler.events.find(bookingId);
    const schedulerDeadlineLink = scheduler.links.find(linkId);

    if (schedulerDeadlineEvent) {
        scheduler.events.remove(schedulerDeadlineEvent);
    }

    if (schedulerDeadlineLink) {
        scheduler.links.remove(schedulerDeadlineLink);
    }

    if (schedulerBookingEvent) {
        scheduler.events.update({
            ...schedulerBookingEvent.data,
            deadlineDate: null,
            deadlineName: null,
        });
    }
};

export const deleteDeadlinesFromSchedulerForSeries = ({ repeatId }) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler || !repeatId) {
        return;
    }

    scheduler.events.list
        .filter(event => event.repeatId === repeatId)
        .each(event => {
            deleteDeadlineFromScheduler({ bookingId: event.id });
        });
};

export const deleteRepeatedBookingsFromScheduler = ({ bookingId }) => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler || !bookingId) {
        return;
    }
    const schedulerBookingEvent = scheduler.events.find(bookingId);

    if (schedulerBookingEvent) {
        scheduler.events.remove(schedulerBookingEvent);
    }
};

export const deleteRepeatedBookings = repeatId => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler || !repeatId) {
        return;
    }

    scheduler.events.list
        .filter(event => event.repeatId === repeatId)
        .forEach(event => {
            deleteRepeatedBookingsFromScheduler({ bookingId: event.id });
        });
};

export const removeDaypilotEvent = eventId => {
    const scheduler = window.schedulerRef?.current?.control;
    if (!scheduler) {
        return;
    }

    const removeDeadline = id => {
        const deadline = scheduler.events.find(`${id}_deadline`);
        if (deadline) {
            scheduler.events.remove(deadline);
        }
    };

    eventsForRangeCache.clear();
    try {
        const currentEvent = scheduler.events.find(eventId);
        scheduler.events.remove(currentEvent);

        removeDeadline(eventId);

        const children = (
            currentEvent?.data?.childIds?.map(childId => {
                return scheduler.events.find(childId);
            }) || []
        ).filter(Boolean);

        const parents = (
            currentEvent?.data?.parentIds?.map(parentId => {
                return scheduler.events.find(parentId);
            }) || []
        ).filter(Boolean);

        children.forEach(child => {
            scheduler.events.remove(child);
            scheduler.events.add({
                ...child.data,
                parentIds: child.data.parentIds.filter(id => id !== eventId),
            });
        });

        parents.forEach(parent => {
            scheduler.events.remove(parent);
            scheduler.events.add({
                ...parent.data,
                childIds: parent.data.childIds.filter(id => id !== eventId),
            });
        });

        if (currentEvent) {
            const rows = getRowIdsByEvents([currentEvent.data], scheduler);
            invalidateRows(rows);
        }
    } catch (error) {
        monitoring.captureException(error);
        console.error('removeDaypilotEvent', error);
    }
};

export const removeDaypilotEvents = (eventIds, scheduler) => {
    if (!scheduler) {
        return;
    }
    eventsForRangeCache.clear();
    try {
        let rows = [];
        forEach(eventIds, eventId => {
            const currentEvent = scheduler.events.find(eventId);
            if (currentEvent) {
                const deadline = scheduler.events.find(`${eventId}_deadline`);
                if (deadline) {
                    scheduler.events.remove(deadline).queue();
                }

                scheduler.events.remove(currentEvent).queue();
                rows = rows.concat(getRowIdsByEvents([currentEvent.data], scheduler));
            }
        });
        scheduler.queue?.notify();

        invalidateRows(uniq(rows));
    } catch (error) {
        monitoring.captureException(error);
        console.error('removeDaypilotEvents', error);
    }
};

export const invalidateRowsByIds = (ids = []) => {
    const scheduler = window.schedulerRef?.current?.control;

    if (!scheduler) {
        return;
    }

    const rows = ids
        .map(id => {
            return scheduler.rows.find(id);
        })
        .filter(Boolean);

    invalidateRows(rows);
};

export const getUpdatedEvents = (currentEvents, newEvents) => {
    const currentEventMapped = arrayToObjectByKey(currentEvents, 'id');

    return filter(newEvents, event => currentEventMapped[event.id]);
};

export const getExistingEvents = (currentEvents, newEvents) => {
    const currentEventMapped = arrayToObjectByKey(currentEvents, 'id');

    return filter(newEvents, event => currentEventMapped[event.id]);
};

export const getNewEvents = (currentEvents, newEvents) => {
    const currentEventMapped = arrayToObjectByKey(currentEvents, 'id');

    return filter(newEvents, event => !currentEventMapped[event.id]);
};

export const getRemovedEvents = (currentEvents, newEvents) => {
    const currentEventIds = map(newEvents, 'id');

    return filter(
        currentEvents,
        event =>
            !includes(currentEventIds, event.id) &&
            !event.phase &&
            !event.milestone &&
            !event.datesEvent &&
            !event.deadline
    );
};

export const filterNotValidResources = (resources, mode) => {
    // Fix https://hubplanner.atlassian.net/browse/HUB-8384
    return filter(resources, resource => {
        if (mode === PARENT.value && resource.indexOf('_') >= 0) {
            const splitted = resource.split('_');
            // Make sure there are ids
            return splitted[0].length === 24 && splitted[1].length === 24;
        }
        return resource.length === 24;
    });
};

export const availabilityHandler = (args, gridPreferences, { isSingleResourceView, isResourceGroupView } = {}) => {
    const scheduler = args.cell.calendar;
    let triggerAction = false;
    //display availability based on settings and exclude hours view
    if (
        gridPreferences.availabilityHover &&
        'TYPE_AVAILABILITY' !== gridPreferences.availabilityDisplay &&
        scheduler.scale !== CELLDURATION.value
    ) {
        const row = scheduler.rows.find(args.cell.resource);
        const isUnassignedRow = row && row.tags.unassignedRow;

        // display on grouped rows and exclude unassigned row
        // display on Single rows (Project & Resource)
        // display on Single rows (Resource) bu only on resource or resource group view
        if (
            (scheduler.mode === PARENT.value && args.cell.isParent && !isUnassignedRow) ||
            scheduler.mode === LEGACY.value ||
            (scheduler.mode === SINGLE.value && (isSingleResourceView || isResourceGroupView))
        ) {
            triggerAction = true;
        }
    }

    return triggerAction;
};

export const prepareSchedulerDataToCreate = state => ({
    ...state,
    itemsToAdd: state.selectedItems,
    groupsToAdd: state.selectedGroups,
});

export const getProjectIdsForMilestonesDatesAndPhases = (schedulerRef, rowResourcesIds, isProjectView, mode) => {
    const projectIds = [];

    forEach(rowResourcesIds, rowResource => {
        const row = schedulerRef.rows?.find(rowResource);
        const isUnassignedRow = row && row.tags.unassignedRow;

        if (
            -1 === rowResource.indexOf('_') &&
            !isUnassignedRow &&
            isProjectView &&
            (mode === SINGLE.value || mode === PARENT.value)
        ) {
            projectIds.push(rowResource);
            return;
        }

        let project;
        if (isProjectView) {
            [project] = rowResource.split('_');
        }

        project && project.length === 24 && projectIds.push(project);
    });

    return { projectIds: uniq(projectIds) };
};

export const getResourceIdsByDPResource = (schedulerRef, rowResourcesIds, viewObject, mode, filters) => {
    const resourceIds = new Set();
    const projectIds = new Set();

    if (viewObject.isSingleProjectView && mode === PARENT.value && filters?.entityId) {
        projectIds.add(filters.entityId);
    }

    forEach(rowResourcesIds, rowResource => {
        const row = schedulerRef.rows?.find(rowResource);

        const isUnassignedRow = row?.tags.unassignedRow;

        if (mode === PARENT.value) {
            if (row?.tags.parent) {
                if (viewObject.isProjectView) {
                    (row.tags.project?.resources || []).forEach(id => resourceIds.add(id));
                } else {
                    (row.tags.resource?.projects || []).forEach(id => projectIds.add(id));
                }
            }
        }

        if (-1 === rowResource.indexOf('_') && !isUnassignedRow && viewObject.isProjectView && mode === SINGLE.value) {
            projectIds.add(rowResource);
            return;
        }

        if (
            -1 === rowResource.indexOf('_') &&
            (!viewObject.isProjectView || mode === LEGACY.value || isUnassignedRow)
        ) {
            resourceIds.add(rowResource);
            return;
        }

        if (rowResource === 'unassignedRow' || rowResource.indexOf('_') === -1) {
            return;
        }

        let project, resource;
        if (viewObject.isProjectView) {
            [project, resource] = rowResource.split('_');
        } else {
            [resource, project] = rowResource.split('_');
        }

        // 24 is the length of an id
        resource && resource.length === 24 && resourceIds.add(resource);
        project && project.length === 24 && projectIds.add(project);
    });

    return { resourceIds: Array.from(resourceIds), projectIds: Array.from(projectIds) };
};

export const getDpRowsIdsToLoad = (scheduler, additionalDownRows, all, { resourcesFilter = [] } = {}) => {
    const rows = all ? scheduler.rows?.all() || [] : scheduler.getViewPort().rows();
    const resourcesFilterMapped = arrayToObject(resourcesFilter);

    const viewportDpRowsIds = rows.reduce((acc, row) => {
        if (row.data.id === 'unassignedRow') {
            const childrenIds = row
                .children()
                .map(childRow => {
                    if (childRow.data.tags.unassignedRow && childRow.data.tags.resource) {
                        if (resourcesFilter.length) {
                            return resourcesFilterMapped[childRow.data.tags.resource._id]
                                ? childRow.data.tags.resource._id
                                : undefined;
                        }

                        return childRow.data.tags.resource._id;
                    }

                    return undefined;
                })
                .filter(Boolean);
            return acc.concat(Array.from(childrenIds ?? []));
        }

        if (resourcesFilter.length && row.data.tags.unassignedRow && row.data.tags.resource?._id) {
            if (resourcesFilterMapped[row.data.tags.resource._id]) {
                acc.push(row.data.id);
            }
        } else {
            acc.push(row.data.id);
        }

        return acc;
    }, []);

    if (additionalDownRows) {
        const allDpRowsIds = (scheduler.rows?.all() || [])
            .map(row => {
                if (row.data.tags.unassignedRow) {
                    return resourcesFilterMapped[row.data?.tags?.resource?._id] ? row.id : undefined;
                }

                return row.id;
            })
            .filter(Boolean);
        const indexOfLastElement = allDpRowsIds.findIndex(
            rowId => rowId === viewportDpRowsIds[viewportDpRowsIds.length - 1]
        );
        const nextDpRowsIds = allDpRowsIds.slice(indexOfLastElement + 1, indexOfLastElement + 11);
        return [...viewportDpRowsIds, ...nextDpRowsIds];
    }
    return viewportDpRowsIds;
};

/**
 *  HUB-8151: in general new body of this function was moved from onScroll event
 *  to unify bookings fetch behaviour. (ex milestones or project dates were missing here)
 *  check: client/modules/scheduler/config/events/onScroll.js
 */
export async function refreshBookings({
    viewObject,
    schedulerRef,
    filters,
    canViewMilestonesOrPhases,
    canViewProjectDates,
    dispatch,
    bookingId,
}) {
    eventsForRangeCache.clear();
    if (document.hasOwnProperty('hidden') && document.hidden) {
        return;
    }

    const scheduler = schedulerRef.current.control;
    const viewport = scheduler.getViewPort();
    const { mode, scale } = scheduler;

    // Fix https://hubplanner.atlassian.net/browse/HUB-8384
    const currentRowIds = filterNotValidResources(getDpRowsIdsToLoad(scheduler, 10), mode);

    if (!currentRowIds || !currentRowIds.length) {
        return;
    }

    const startDate = subDays(viewport.start.toDate(), SCHEDULER_DATE_BUFFER);
    const endDate = format(addDays(viewport.end.toDate(), SCHEDULER_DATE_BUFFER), 'yyyy-MM-dd');
    const shouldLoadMilestonesPhasesStartEndTimes =
        (viewObject.isSingleProjectView || viewObject.isProjectGroupView) &&
        (SINGLE.value === mode || PARENT.value === mode);

    const { resourceIds, projectIds } = getResourceIdsByDPResource(scheduler, currentRowIds, viewObject, mode, filters);

    const { projectIds: projectIdsForMilestonesDatesAndPhases } = getProjectIdsForMilestonesDatesAndPhases(
        schedulerRef,
        currentRowIds,
        viewObject.isProjectView,
        mode
    );

    const requests = [];

    const bookingsResourceIds = resourceIds.length ? resourceIds : undefined;
    // Do not filter by project on resource view
    const bookingsProjectIds = viewObject.isResourceView ? undefined : projectIds.length ? projectIds : undefined;

    const bookingsProjectEventsIds = filters?.bookingProjectsEvents?.filters || [];
    const bookingsResourcesUWIds = filters?.bookingResourcesUW?.filters || [];

    // Booking filters applied have priority
    const resourceIdsToAsk = bookingsResourcesUWIds?.length ? bookingsResourcesUWIds : bookingsResourceIds;
    const projectIdsToAsk = bookingsProjectEventsIds?.length ? bookingsProjectEventsIds : bookingsProjectIds;

    requests.push(
        getBookingsService.get({
            startDate: format(startDate, YEAR_MONTH_DAY_FORMAT),
            endDate,
            resourceIds: resourceIdsToAsk?.length ? resourceIdsToAsk : undefined,
            projectIds: projectIdsToAsk?.length ? projectIdsToAsk : undefined,
            type: filters?.bookingStatuses?.filters?.length
                ? filters.bookingStatuses.filters
                : [APPROVED.value, WAITING_FOR_APPROVAL.value, SCHEDULED.value],
            categoryIds: filters?.bookingCategories?.filters?.length ? filters.bookingCategories.filters : undefined,
            schedulerViewProjectIds: filters.schedulerViewProjectIds,
        })
    );

    if (shouldLoadMilestonesPhasesStartEndTimes && canViewMilestonesOrPhases && scale !== CELLDURATION.value) {
        const projectIdsToAsk = bookingsProjectEventsIds?.length
            ? bookingsProjectEventsIds
            : projectIdsForMilestonesDatesAndPhases;
        requests.push(
            getMilestonesAndPhases({
                projectIds: projectIdsToAsk,
            })
        );
    } else {
        requests.push([]);
    }

    if (shouldLoadMilestonesPhasesStartEndTimes && canViewProjectDates && scale !== CELLDURATION.value) {
        const projectIdsToAsk = bookingsProjectEventsIds?.length
            ? bookingsProjectEventsIds
            : projectIdsForMilestonesDatesAndPhases;
        requests.push(
            getProjectDates({
                startDate: format(startDate, YEAR_MONTH_DAY_FORMAT),
                endDate,
                projectIds: projectIdsToAsk,
            })
        );
    } else {
        requests.push([]);
    }

    const [bookings, milestonesAndPhases, projectDates] = await Promise.all(requests);
    const { milestones, phases } = milestonesAndPhases || {};
    const events = formatBookingsToDP(bookings, mode, viewObject);
    // format milestones
    const formattedMilestones = milestones ? formatMilestonesToDP(milestones) : [];
    // format phases
    const formattedPhases = phases ? formatPhasesToDP(phases) : [];
    // format start, end times
    const formattedStartEndTimes = projectDates ? formatStartEndTimesToDP(projectDates) : [];
    const allEvents = events
        .concat(formattedMilestones)
        .concat(formattedPhases)
        .concat(formattedStartEndTimes);

    // get diff between current and new events
    const newEvents = getNewEvents(scheduler.events.list, allEvents);
    const updatedEvents = getExistingEvents(scheduler.events.list, allEvents)?.filter(event => {
        // so we don't refresh bookings which update action is in progress to avoid jumping them
        // in scheduler
        return !eventsIdsMoving.has(event.id);
    });

    let schedulerEventsToRemove = [];
    if (bookingId) {
        const responseEvent = events.find(event => event.id === bookingId);
        const schedulerEvent = scheduler.events.find(bookingId);

        if (!responseEvent && schedulerEvent) {
            schedulerEventsToRemove.push(schedulerEvent);
            scheduler.events.remove(schedulerEvent).queue();
        }
    }

    forEach(newEvents, event => {
        scheduler.events.add(event).queue();
    });

    updatedEvents.forEach(event => {
        const currentEvent = scheduler.events.find(event.id);
        if (currentEvent) {
            // for some reason .update() function didn't work for me
            scheduler.events.remove(currentEvent).queue();
            scheduler.events.add(event).queue();
        }
    });

    const rows = getRowIdsByEvents([...newEvents, ...updatedEvents, ...schedulerEventsToRemove], scheduler);

    scheduler.queue.notify();
    invalidateRows(rows);
    dispatch(setSchedulerBookingLinks({ bookings: events }));
}

export async function refreshMilestonesPhases({ viewObject, schedulerRef, filters, canViewMilestonesOrPhases }) {
    eventsForRangeCache.clear();
    if (document.hasOwnProperty('hidden') && document.hidden) {
        return;
    }

    const scheduler = schedulerRef.current.control;
    const { mode, scale } = scheduler;

    // Fix https://hubplanner.atlassian.net/browse/HUB-8384
    const currentRowIds = filterNotValidResources(getDpRowsIdsToLoad(scheduler, 10), mode);

    if (!currentRowIds || !currentRowIds.length) {
        return;
    }

    const shouldLoadMilestonesPhasesStartEndTimes =
        (viewObject.isSingleProjectView || viewObject.isProjectGroupView) &&
        (SINGLE.value === mode || PARENT.value === mode);

    const { projectIds: projectIdsForMilestonesDatesAndPhases } = getProjectIdsForMilestonesDatesAndPhases(
        schedulerRef,
        currentRowIds,
        viewObject.isProjectView,
        mode
    );

    const requests = [];

    const bookingsProjectEventsIds = (filters?.bookingProjectsEvents?.filters || []).map(idWithSuffix =>
        idWithSuffix.replace('_bpe', '')
    );

    if (shouldLoadMilestonesPhasesStartEndTimes && canViewMilestonesOrPhases && scale !== CELLDURATION.value) {
        const projectIdsToAsk = bookingsProjectEventsIds?.length
            ? bookingsProjectEventsIds
            : projectIdsForMilestonesDatesAndPhases;
        requests.push(
            getMilestonesAndPhases({
                projectIds: projectIdsToAsk,
            })
        );
    } else {
        requests.push([]);
    }

    const [milestonesAndPhases] = await Promise.all(requests);
    const { milestones, phases } = milestonesAndPhases || {};
    // format milestones
    const formattedMilestones = milestones ? formatMilestonesToDP(milestones) : [];
    // format phases
    const formattedPhases = phases ? formatPhasesToDP(phases) : [];
    // format start, end times
    const allEvents = formattedMilestones.concat(formattedPhases);

    // get diff between current and new events
    const newEvents = getNewEvents(scheduler.events.list, allEvents);
    const updatedEvents = getExistingEvents(scheduler.events.list, allEvents)?.filter(event => {
        // so we don't refresh bookings which update action is in progress to avoid jumping them
        // in scheduler
        return !eventsIdsMoving.has(event.id);
    });

    forEach(newEvents, event => {
        scheduler.events.add(event).queue();
    });

    updatedEvents.forEach(event => {
        const currentEvent = scheduler.events.find(event.id);
        if (currentEvent) {
            // for some reason .update() function didn't work for me
            scheduler.events.remove(currentEvent).queue();
            scheduler.events.add(event).queue();
        }
    });

    const rows = getRowIdsByEvents([...newEvents, ...updatedEvents], scheduler);

    scheduler.queue.notify();
    invalidateRows(rows);
    updateVisibleLinksOnScheduler();
}

export const removeDPRowResourceById = id => {
    if (window.schedulerRef?.current?.control) {
        const foundRow = window.schedulerRef.current.control.rows.find(dpRow => dpRow.id === id);
        foundRow && foundRow.remove();
    }
};

export const getRepeatedBookingIdsToRemove = (bookings = []) => {
    const repeatId = (bookings[0] || {}).repeatId;

    if (!repeatId) {
        return [];
    }

    const list = window.schedulerRef?.current?.control.events.list ?? [];
    const bookingsOnScheduler = list.filter(booking => booking.repeatId === repeatId);
    if (bookingsOnScheduler.length > bookings.length) {
        const bookingsIds = bookings.map(({ _id }) => _id);
        return bookingsOnScheduler.filter(({ id }) => !bookingsIds.includes(id)).map(({ id }) => id);
    }

    return [];
};

export const dispatchRefreshUnassignedDPResourcesEvent = () => {
    window.dispatchEvent(new CustomEvent('refreshUnassignedDPResources'));
};

export function updateSchedulerBookings(bookings, viewObject) {
    const scheduler = window?.schedulerRef?.current?.control;

    if (!scheduler) {
        return;
    }

    eventsForRangeCache.clear();
    const { mode } = scheduler;
    const events = formatBookingsToDP(bookings, mode, viewObject);
    const oldRows = [];

    events.forEach(event => {
        const currentEvent = scheduler.events.find(event.id);
        const rowId = currentEvent?.row();

        if (rowId) {
            const oldRow = currentEvent?.calendar.rows.find(rowId);
            const parentOldRow = oldRow.parent();

            if (oldRow) {
                oldRows.push(oldRow);
            }

            if (parentOldRow) {
                oldRows.push(parentOldRow);
            }
        }

        if (currentEvent) {
            scheduler.events.remove(currentEvent).queue();
            scheduler.events.add(event).queue();
        }
    });

    const rows = getRowIdsByEvents(events, scheduler);

    scheduler.queue.notify();
    invalidateRows([...rows, ...oldRows]);
}

export function removeBookingDots() {
    window.schedulerRef?.current?.control?.clearSelection();
    window.schedulerRef?.current?.control?.multiselect.clear();
}

export const removeBookingById = ({ bookingId }) => {
    window.schedulerRef.current?.control.events.removeById(bookingId);
};

export const clearSelection = () => {
    window.schedulerRef.current?.control.clearSelection();
};

export const updateBookingsWithNewDependencies = ({ childId, parentId, remove = false }) => {
    const scheduler = window.schedulerRef.current.control;
    const childToUpdate = scheduler.events.find(childId);
    const parentToUpdate = scheduler.events.find(parentId);

    if (childToUpdate) {
        childToUpdate.data.parentIds = remove
            ? childToUpdate.data.parentIds.filter(id => id !== parentId)
            : [...childToUpdate.data.parentIds, parentId];

        scheduler.events.update(childToUpdate);
    }

    if (parentToUpdate) {
        parentToUpdate.data.childIds = remove
            ? parentToUpdate.data.childIds.filter(id => id !== childId)
            : [...parentToUpdate.data.childIds, childId];

        scheduler.events.update(parentToUpdate);
    }
};

export const removeLinkFromScheduler = ({ childId, parentId }) => {
    const scheduler = window.schedulerRef.current?.control;

    if (!scheduler) {
        return;
    }

    scheduler.update({
        links: scheduler.links.list.filter(link => {
            return link.from !== parentId && link.to !== childId;
        }),
    });
};

export const updateDeadlinesVisibility = () => {
    const scheduler = window.schedulerRef.current?.control;
    const reduxState = store.getState();
    const isDeadlinesExtensionInstalled = selectIsDeadlinesExtensionInstalled(reduxState);

    if (!scheduler || !isDeadlinesExtensionInstalled) {
        return;
    }

    const deadlinesConfig = selectDeadlinesConfig(reduxState);

    switch (deadlinesConfig.display) {
        case deadlineDisplayEnum.hide: {
            const newEvents = scheduler.events
                .all()
                .filter(event => !event.data.deadline)
                .map(event => event.data);

            scheduler.update({
                events: newEvents,
            });
            break;
        }
        case deadlineDisplayEnum.show: {
            const bookings = scheduler.events.all().filter(event => !event.data.deadline);

            const deadlines = bookings
                .map(event => {
                    if (!event.data.deadlineDate) {
                        return undefined;
                    }
                    return getDeadlineFromBooking(event.data);
                })
                .filter(Boolean);

            const newEvents = bookings.map(event => event.data).concat(deadlines);

            scheduler.update({
                events: newEvents,
            });
            break;
        }
        case deadlineDisplayEnum.showForSelectedBooking: {
            let deadlineToDisplay;
            const deadlines = scheduler.events.all().filter(event => event.data.deadline);
            const currentBooking = selectCurrentSelectedBooking(reduxState);

            if (currentBooking.deadline) {
                deadlineToDisplay = getDeadlineFromBooking(currentBooking.booking);
            } else {
                deadlineToDisplay = getDeadlineFromBooking(currentBooking);
            }

            deadlines.forEach(deadline => {
                scheduler.events.remove(deadline);
            });
            if (deadlineToDisplay) {
                scheduler.events.add(deadlineToDisplay);
            }
            break;
        }
    }
};
