import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useBooking } from 'modules/scheduler/hooks';
import ApprovalActions, {
    ApproveWithNote,
    RejectWithNote,
    RequestDashboard,
    ApproveOrRejectWithNoteSlideContent,
} from './approvalActions';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import { APPROVAL_CONTEXT_MENU_WIDTH } from './../index';
import { APPROVED, REJECTED } from 'enums/bookingTypeEnum';
import { Edit } from '@material-ui/icons';
import { ListItem } from './../../../lists/styles';
import { ListItemAvatar, ListItemText } from '@material-ui/core';
import classNames from 'classnames';

const CONTEXT_MENU_MAX_HEIGHT = 262;
const CONTEXT_MENU_MAX_HEIGHT_EDIT = 320;
const CONTEXT_MENU_MAX_HEIGHT_EDIT_ONLY = 160;

const Approval = ({ onClose, onEdit, currentSelectedBooking, hasManageApprovalForBooking }) => {
    const { updateBooking } = useBooking(currentSelectedBooking, onClose);
    const slideRef = useRef();

    const onClickApproveWithNote = () => {
        slideRef.current.triggerSlide(2);
    };

    const onClickRejectWithNote = () => {
        slideRef.current.triggerSlide(3);
    };

    const slideCardItems = [
        {
            id: 7,
            itemElement: (
                <p className="ml-5 mt-1">
                    <strong>Manage request</strong>
                </p>
            ),
        },
        ...(hasManageApprovalForBooking
            ? [
                  {
                      id: 2,
                      itemElement: <ApproveWithNote onClick={onClickApproveWithNote} />,
                      slides: [
                          {
                              content: (
                                  <ApproveOrRejectWithNoteSlideContent
                                      onClick={updateBooking}
                                      currentSelectedBooking={currentSelectedBooking}
                                      type={APPROVED.value}
                                  />
                              ),
                          },
                      ],
                  },
                  {
                      id: 3,
                      itemElement: <RejectWithNote onClick={onClickRejectWithNote} />,
                      slides: [
                          {
                              content: (
                                  <ApproveOrRejectWithNoteSlideContent
                                      onClick={updateBooking}
                                      currentSelectedBooking={currentSelectedBooking}
                                      type={REJECTED.value}
                                  />
                              ),
                          },
                      ],
                  },
              ]
            : []),
        {
            id: 4,
            itemElement: () => (
                <RequestDashboard
                    onClose={onClose}
                    currentSelectedBooking={currentSelectedBooking}
                    onClick={updateBooking}
                />
            ),
        },
        ...(onEdit
            ? [
                  {
                      id: 6,
                      itemElement: () => (
                          <ListItem onClick={onEdit} className={classNames('pt-3 pb-3')}>
                              <ListItemAvatar style={{ minWidth: 42 }}>
                                  <Edit style={{ width: 30, height: 30 }} />
                              </ListItemAvatar>
                              <ListItemText primary="Edit request..." data-cy="button--edit-request" />
                          </ListItem>
                      ),
                  },
              ]
            : []),
        ...(hasManageApprovalForBooking
            ? [
                  {
                      id: 1,
                      itemElement: (
                          <ApprovalActions
                              currentSelectedBooking={currentSelectedBooking}
                              onClick={updateBooking}
                              onClose={onClose}
                          />
                      ),
                      divider: false,
                  },
              ]
            : []),
    ];

    return (
        <SlideCard
            ref={slideRef}
            height={
                onEdit
                    ? !hasManageApprovalForBooking
                        ? CONTEXT_MENU_MAX_HEIGHT_EDIT_ONLY
                        : CONTEXT_MENU_MAX_HEIGHT_EDIT
                    : CONTEXT_MENU_MAX_HEIGHT
            }
            width={APPROVAL_CONTEXT_MENU_WIDTH}
            items={slideCardItems}
        />
    );
};

Approval.propTypes = {
    onClose: PropTypes.func,
    currentSelectedBooking: PropTypes.object,
    height: PropTypes.number,
};

Approval.defaultProps = {
    onClose: null,
    height: CONTEXT_MENU_MAX_HEIGHT,
    currentSelectedBooking: {},
};

export default Approval;
