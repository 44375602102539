import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { map, filter } from 'lodash';
import { deleteProject, getProjects } from 'actions/projectActions';
import { getResources } from 'actions/resourceActions';
import { changeView, initScheduler, setSchedulerSelection } from 'actions/schedulerActions';
import { getProjectGroupProjects, updateProjectGroup } from 'actions/projectGroupActions';
import { getViewObject, dispatchRefreshDPResourcesEvent } from 'modules/scheduler/utils/schedulerUtil';
import {
    useUnassignedMenu,
    useDaypilotConfig,
    useScheduler,
    useAccountPreferences,
    useDPResources,
} from 'modules/scheduler/hooks';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { PARENT, LEGACY, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import Header from 'modules/scheduler/components/header';
import Scheduler from 'modules/scheduler/components/scheduler';
import Footer from 'modules/scheduler/components/footer/footer';
import projectDayPilotMenu from 'modules/scheduler/config/areas/project';
import { getSchedulerResources, getSchedulerProjects } from 'modules/scheduler/config/formater';
import { PROJECT } from 'modules/scheduler/enums/builderTypeEnum';
import { makeGetProjectFromGroupById } from 'selectors/projectGroup';
import Loader from 'shared/loader';
import { hasRole } from 'utils/rightsUtil';
import { UNASSIGNED } from '../../../../enums/criteriaEnum';
import { useUpdateBookings } from '../../hooks/useUpdateBookings';
import { ClickAwayListener } from '@material-ui/core';
import { GROUP } from '../../../../enums/groupTypeEnum';
import { companyApprovers } from '../../../../actions/companyActions';
import { useProjectGroupNameReplacer } from '../../../../shared/projectStatusDisplay';
import projectResourceDayPilotMenu from '../../config/areas/projectResource';
import { selectDpChildrenResourcesById, selectSchedulerFilters } from '../../../../selectors/scheduler';

const ProjectGroup = ({ match }) => {
    const dispatch = useDispatch();
    const schedulerRef = useRef({});
    const viewObject = getViewObject(match.params);
    const schedulerFilters = useSelector(selectSchedulerFilters);
    const {
        accountRoleRights,
        keyWords,
        showResourceModal,
        resourceContextMenu,
        showProjectModal,
        updateProject,
        addFilter,
    } = useScheduler(schedulerRef);
    const projectGroupNameReplacer = useProjectGroupNameReplacer();
    const {
        gridPreferences: { mode, groupBookings },
    } = useAccountPreferences();
    const projectGroup = useSelector(state => state.scheduler.group);
    const currentMenuView = useSelector(state => state.scheduler.currentView);
    const unassignedResourceGroup = useSelector(state => state.scheduler.unassignedWorkGroup);

    const updateProjectGroupRequest = useCallback(
        (projectGroupId, data) =>
            dispatch(
                updateProjectGroup.request({
                    id: projectGroupId,
                    data,
                    callback: () => {
                        dispatch(initScheduler());
                    },
                })
            ),
        [dispatch]
    );

    const updateProjectRequest = useCallback(
        (id, data) => {
            if (viewObject.isSingleProjectView) {
                updateProject(id, data, dispatchRefreshDPResourcesEvent);
            } else {
                updateProjectGroupRequest(id, data);
            }
        },
        [updateProject, updateProjectGroupRequest, viewObject.isSingleProjectView]
    );

    const { dpResources, isLoading, isSchedulerInitialized, redrawSchedulerFlag } = useDPResources(PROJECT.name, {
        match,
        updateProjectRequest,
    });

    const dpChildrenResources = useSelector(selectDpChildrenResourcesById);

    const projectSelector = useMemo(() => makeGetProjectFromGroupById(match.params.projectId), [
        match.params.projectId,
    ]);
    const project = useSelector(projectSelector);
    const deleteProjectRequest = useCallback(
        projectId => dispatch(deleteProject.request(projectId, dispatchRefreshDPResourcesEvent)),
        [dispatch]
    );

    const resourcesUpdateRef = useRef({});

    const resourceIdsInCurrentView = useMemo(() => map(dpResources, '_id'), [dpResources]);
    const { shouldRenderUnassigned, unassignedContextMenu, unassignedChildContextMenu } = useUnassignedMenu(
        viewObject.isSingleProjectView ? project : projectGroup,
        dpResources,
        resourceIdsInCurrentView,
        {
            showResourceModal,
            updateProject: updateProjectRequest,
        },
        viewObject
    );

    const viewData = useMemo(
        () => ({ projectId: match.params.projectId, projectGroupId: match.params.projectGroupId }),
        [match.params.projectGroupId, match.params.projectId]
    );

    useEffect(() => {
        if (
            !project?._id &&
            viewObject.isSingleProjectView &&
            projectGroup?._id &&
            projectGroup?.queryParams?.itemType === PROJECT.name
        ) {
            dispatch(getProjectGroupProjects.request(projectGroup?._id));
        }
    }, [dispatch, project, projectGroup?._id, projectGroup?.queryParams?.itemType, viewObject.isSingleProjectView]);

    useEffect(() => {
        dispatch(getResources.request());
        dispatch(getProjects.request());
        dispatch(companyApprovers.request());
    }, [dispatch]);

    useEffect(() => {
        if (
            isLoading ||
            !isSchedulerInitialized ||
            !redrawSchedulerFlag ||
            (!project?._id && viewObject.isSingleProjectView)
        ) {
            return;
        }
        let resources;
        const unassignedResources = dpResources.filter(dpResource => dpResource.type === UNASSIGNED);

        if (LEGACY.value === mode) {
            resources = getSchedulerResources(
                filter(dpResources, resource => resource.type !== TYPE_UNASSIGNED.value),
                unassignedResources,
                {
                    resourceContextMenu: resourceContextMenu(), //currentGroup arg is not required for project groups
                    unassignedContextMenu,
                    unassignedChildContextMenu,
                },
                {
                    shouldRenderUnassigned,
                    addMenuRow:
                        !viewObject.isProjectGroupView &&
                        LEGACY.value === mode &&
                        (project.edit || project.editPm) &&
                        hasRole(accountRoleRights, 'settingProjectRes'),
                    project,
                    resourceKeyWord: keyWords.resourceKeyWord,
                    accountRoleRights,
                    unassignedResourceGroup,
                }
            );
        } else {
            const projectContextMenu = projectDayPilotMenu(
                viewObject.isProjectGroupView && projectGroup,
                accountRoleRights,
                groupBookings,
                keyWords,
                {
                    showProjectModal,
                    updateProject,
                    addFilter,
                    deleteProject: deleteProjectRequest,
                    updateProjectGroup: updateProjectGroupRequest,
                }
            );

            const projectResourceContextMenu = projectResourceDayPilotMenu(
                accountRoleRights,
                schedulerRef.current.groupConcurrentEvents,
                keyWords,
                {
                    updateProject,
                    showProjectModal,
                    showResourceModal,
                    addFilter,
                    unassignedChildContextMenu,
                }
            );

            resources = getSchedulerProjects(
                dpResources,
                dpChildrenResources,
                unassignedResources,
                {
                    projectContextMenu,
                    unassignedContextMenu,
                    unassignedChildContextMenu,
                    projectResourceContextMenu,
                },
                {
                    shouldRenderUnassigned,
                    showUnassignedParentArrow: PARENT.value === mode || LEGACY.value === mode,
                    loadResources: PARENT.value === mode,
                    unassignedResourceGroup,
                    accountRoleRights,
                    projectGroup,
                    projectKeyWord: keyWords.projectKeyWord,
                    filters: schedulerFilters,
                    keyWords,
                    addMenuRow:
                        viewObject.isProjectGroupView &&
                        (mode === PARENT.value || mode === SINGLE.value) &&
                        projectGroup?.groupType === 'USER' &&
                        !projectGroup?.isSmart &&
                        (!projectGroup?.type || projectGroup?.type === GROUP),
                }
            );
        }

        const currentUnassignedRow = schedulerRef.current.control.rows.find('unassignedRow');
        const isFirstUnassigned = resources[0]?.id === 'unassignedRow';

        if (currentUnassignedRow && isFirstUnassigned) {
            resources[0].expanded = currentUnassignedRow.data?.expanded;
            resources[0].children = currentUnassignedRow.data?.children;
        }

        resourcesUpdateRef.current = {
            resources,
        };
    }, [
        accountRoleRights,
        addFilter,
        deleteProjectRequest,
        dpResources,
        groupBookings,
        isSchedulerInitialized,
        isLoading,
        keyWords,
        mode,
        project,
        projectGroup,
        redrawSchedulerFlag,
        resourceContextMenu,
        shouldRenderUnassigned,
        showProjectModal,
        unassignedChildContextMenu,
        unassignedContextMenu,
        updateProject,
        updateProjectGroupRequest,
        viewObject.isProjectGroupView,
        viewObject.isSingleProjectView,
        unassignedResourceGroup,
        showResourceModal,
        schedulerFilters,
        dpChildrenResources,
    ]);

    const config = useDaypilotConfig(
        {
            schedulerRef,
            viewObject,
            viewData,
            resourcesUpdateRef,
        },
        {
            updateProject,
            showProjectModal,
            showResourceModal,
            addFilter,
            unassignedChildContextMenu,
        }
    );

    useEffect(() => {
        currentMenuView !== 'project' && dispatch(changeView.request('project'));
    }, [currentMenuView, dispatch]);

    // Fires when start Date changes.
    // in day mode, on refresh goes to wrong date despite start date is correct
    // TODO commenting out for now as this introduces new issue that the date is goiong back to the start when you do something like add a filter.
    // you should check the connection between the date changing and the capacity filter to fix original issue.
    // useEffect(() => {
    //     if (!isLoading && isSchedulerInitialized) {
    //         schedulerRef.current.control.scrollTo(config.startDate);
    //     }
    // }, [config.startDate, isLoading, isSchedulerInitialized]);

    useUpdateBookings({
        group: projectGroup,
        viewObject,
        schedulerRef,
    });

    const onClickAway = useCallback(() => {
        if (window.notClearSchedulerSelection) {
            window.notClearSchedulerSelection = false;
            return;
        }
        const schedulerContextMenuOpened = document.getElementById('context-menu-scheduler');
        const modalOpened = document.getElementsByClassName('modal-dialog');

        if (!schedulerContextMenuOpened && !modalOpened.length) {
            dispatch(setSchedulerSelection.request());
            schedulerRef.current.control.clearSelection();
        }
    }, [dispatch]);

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div>
                <Header
                    ref={schedulerRef}
                    viewObject={viewObject}
                    viewData={viewData}
                    showFinancialOptions={true}
                    name={viewObject.isSingleProjectView ? project.name : projectGroupNameReplacer(projectGroup)}
                    subName={viewObject.isSingleProjectView ? project.projectCode : ''}
                />
                <Scheduler ref={schedulerRef} config={config} />
                {isLoading && <Loader center className="mt-3" />}
                <Footer ref={schedulerRef} />
            </div>
        </ClickAwayListener>
    );
};

export default ProjectGroup;
