import { updateAccountNotifications } from 'actions/accountActions';
import { put, select } from 'redux-saga/effects';
import { getAccountNotifications } from 'selectors/account';
import { mfaReminderTypes } from 'shared/constants/mfaReminders';

export const shouldDisplayReminder = (notifications = [], category) => {
    const notification = notifications.find(n => n.category === category);

    if (!notification) {
        return true;
    }

    return (
        notification.enabled && (!notification.snoozedUntilDate || new Date(notification.snoozedUntilDate) < new Date())
    );
};

export function* disableMFAReminderSaga(type) {
    const notifications = yield select(getAccountNotifications);
    const otherNotifications = notifications.filter(n => n.category !== type);

    yield put(
        updateAccountNotifications.request(
            [
                ...otherNotifications,
                {
                    category: mfaReminderTypes.MFA_COMPANY_REMINDER,
                    channel: 'IN_APP',
                    enabled: false,
                },
            ],
            undefined,
            true,
            true
        )
    );
}
