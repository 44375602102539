import React from 'react';
import { useSelector } from 'react-redux';
import { useBookingTitle } from '../../../hooks/useBookingTitle';
import {
    isAbleToApproveRejectVacationOrRequest,
    isAbleToEditResourceOrVacationRequest,
} from 'modules/request/utils/permissions';
import { isMyRequests } from 'modules/request/utils/dataFlowUtil';
import { contains } from 'underscore';
import { REJECTED, APPROVED, WAITING_FOR_APPROVAL, SCHEDULED } from 'enums/bookingTypeEnum';
import { createCySelector } from '../../../../../utils/cypressUtil';
import { YEAR_MONTH_DAY_FORMAT } from '../../../../../global/enums/dateFormat';
import { formatDate } from '@hub-mono/utils';
import { IconButton, MenuIconButton } from '@hub-mono/ui';
import ReSubmitAction from '../reSubmitAction';
import { getCompanySettings, getResourceRoleRight } from 'selectors/company';

export const ActionCell = props => {
    const {
        booking,
        requestModel,
        deleteRequest,
        approveOrRejectModal,
        approveOrRejectRequest,
        editRequest,
        resourceId,
        reSubmitRequest,
        clearSelected,
    } = props;
    let actions;
    const bookingTitle = useBookingTitle({
        booking,
        loggedResourceId: resourceId,
    });
    const { vacationId } = useSelector(getCompanySettings);
    const resourceRoleRights = useSelector(getResourceRoleRight);

    const isAbleToEdit = isAbleToEditResourceOrVacationRequest(booking, resourceId, resourceRoleRights, vacationId);
    const isAbleToApproveReject = isAbleToApproveRejectVacationOrRequest(
        [booking],
        resourceId,
        resourceRoleRights,
        vacationId
    );

    const editBtn = isAbleToEdit ? (
        <IconButton
            icon="edit"
            dataCy={createCySelector('Edit Request')}
            onClick={() => {
                editRequest({
                    ...booking,
                    end: { value: new Date(booking.end) },
                    start: { value: new Date(booking.start) },
                    resourceInfo: {
                        _id: booking.resource,
                        firstName: booking.resourceFirstName || '',
                        lastName: booking.resourceLastName || '',
                    },
                    categoryTemplate: { _id: booking.categoryTemplateId },
                    project: booking.project?._id
                        ? booking.project
                        : { _id: booking.project, name: booking.projectName || '' },
                    id: booking._id,
                });
                clearSelected();
            }}
        />
    ) : null;

    if (
        isMyRequests(requestModel.flow) &&
        contains([REJECTED.value, APPROVED.value, WAITING_FOR_APPROVAL.value], booking.type)
    ) {
        actions = (
            <>
                {editBtn}
                {isAbleToApproveReject && (
                    <IconButton
                        icon="delete"
                        onClick={() => {
                            deleteRequest(booking);
                            clearSelected();
                        }}
                    />
                )}
            </>
        );
    } else if (booking.type === WAITING_FOR_APPROVAL.value) {
        actions = (
            <>
                {editBtn}
                {isAbleToApproveReject ? (
                    <>
                        <IconButton
                            icon="check"
                            dataCy={createCySelector('Approve Request')}
                            onClick={() => {
                                approveOrRejectRequest([booking], APPROVED.value);
                                clearSelected();
                            }}
                        />
                        <IconButton
                            icon="close"
                            dataCy={createCySelector('Reject Request')}
                            onClick={() => {
                                approveOrRejectRequest([booking], REJECTED.value);
                                clearSelected();
                            }}
                        />
                        <MenuIconButton
                            icon="description"
                            density={-4}
                            dataCy="approve-reject-with-note"
                            closeOnSelect
                            options={[
                                {
                                    dataCy: 'approve-with-note',
                                    label: 'Approve with note',
                                    onClick: () => {
                                        approveOrRejectModal(booking, APPROVED.value);
                                        clearSelected();
                                    },
                                },
                                {
                                    dataCy: 'reject-with-note',
                                    label: 'Reject with note',
                                    onClick: () => {
                                        approveOrRejectModal(booking, REJECTED.value);
                                        clearSelected();
                                    },
                                },
                            ]}
                        />
                    </>
                ) : null}

                {isAbleToApproveReject && (
                    <IconButton
                        icon="delete"
                        onClick={() => {
                            deleteRequest(booking);
                            clearSelected();
                        }}
                    />
                )}
            </>
        );
    } else if (contains([REJECTED.value, APPROVED.value, SCHEDULED.value], booking.type)) {
        actions = (
            <>
                {isAbleToApproveReject && (
                    <ReSubmitAction
                        reSubmit={() => {
                            reSubmitRequest(booking);
                            clearSelected();
                        }}
                    />
                )}
                {isAbleToApproveReject && (
                    <IconButton
                        icon="delete"
                        onClick={() => {
                            deleteRequest(booking);
                            clearSelected();
                        }}
                    />
                )}
            </>
        );
    }

    return (
        <div
            data-cy={`[action-${createCySelector(bookingTitle)}]-[start=${formatDate(
                booking.start,
                YEAR_MONTH_DAY_FORMAT
            )}]-[end=${formatDate(booking.end, YEAR_MONTH_DAY_FORMAT)}]`}
        >
            {actions}
        </div>
    );
};
