import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, ModalFooter } from 'reactstrap';
import { Close } from '@material-ui/icons';
import { hideModal as hideModalAction } from 'actions/modalActions';
import { EscButton, ModalContent, Header } from './styles';
import CancelButton from 'shared/buttons/cancel';

const InfoModal = ({ title, description }) => {
    const dispatch = useDispatch();
    const hideModal = () => dispatch(hideModalAction());

    return (
        <Modal
            contentClassName="rounded-0 mt-5 position-relative"
            size="md"
            isOpen={true}
            fade={false}
            style={{ width: '560px' }}
            autoFocus={false}
            toggle={hideModal}
        >
            <Header>Alert</Header>
            <ModalContent>
                <EscButton color="close" onClick={hideModal}>
                    <Close />
                    <div>esc</div>
                </EscButton>
                <div className="clearfix" />
                <div className="mt-4 mr-1 text-center">
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </ModalContent>
            <ModalFooter>
                <CancelButton className="float-right mr-2" name="Close" onClick={hideModal} />
            </ModalFooter>
        </Modal>
    );
};

InfoModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default InfoModal;
