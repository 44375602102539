import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { StatPods, RequestTable, BarChart, Header } from 'modules/request/components';
import { getBookingType } from 'enums/bookingTypeEnum';
import { useRequestData } from 'modules/request/hooks';
import { filterRequestTable } from 'utils/filterUtil';
import { makeResourceByIdFromMap } from '../../../../selectors/resource';
import { useSelector } from 'react-redux';
import {selectCompanyAvgDailyCapacity} from '../../../../selectors/company';

const MyResourceRequests = ({ location }) => {
    const {
        deleteRequest,
        requestModel,
        companySettings,
        filteredBookings,
        textFilter,
        statistics,
        tableActive,
        editRequest,
        resourceId,
    } = useRequestData('MY_RESOURCE_REQUESTS', location);

    const statisticsJS = useMemo(() => {
        return statistics.toJS();
    }, [statistics]);

    const resourceSelector = useMemo(() => makeResourceByIdFromMap(requestModel?.resourceId), [
        requestModel?.resourceId,
    ]);
    const resource = useSelector(resourceSelector);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);

    return (
        <>
            <Header />
            <StatPods
                filter={requestModel.filter}
                resourceId={requestModel.resourceId}
                toilPolicyId={resource?.toilPolicyId}
                flow={requestModel.flow}
                statistics={statisticsJS}
            />
            {!tableActive && (
                <Row className="mt-3">
                    <Col md={12}>
                        <BarChart
                            title={`${getBookingType(requestModel.filter)} Requests`}
                            weekStartDay={companySettings.weekStartDay}
                            requestModel={requestModel}
                            statistics={statistics}
                            companyAvgDailyCapacity={companyAvgDailyCapacity}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={12} className="pt-4">
                    <RequestTable
                        key={requestModel.resourceId ?? 'all'}
                        requestModel={requestModel}
                        deleteRequest={deleteRequest}
                        editRequest={editRequest}
                        bookings={filterRequestTable(filteredBookings, textFilter)}
                        resourceId={resourceId}
                    />
                </Col>
            </Row>
        </>
    );
};

export default MyResourceRequests;
