import styled from 'styled-components';
import { Menu } from '@material-ui/core';

export const Wrapper = styled(Menu)`
    li {
        font-size: 13px;
        &.sorted:before {
            content: ' ';
            background-color: #1896c0;
            font-size: 8px;
            width: 5px;
            height: 5px;
            position: absolute;
            left: 7px;
            border-radius: 50%;
        }
        &.aligned {
            background-color: rgba(0, 0, 0, 0.04);
        }
        &.disable-hover:hover {
            cursor: default;
            background-color: #fff;
        }
    }
`;
