import { createSelector } from 'reselect';
import { chain } from 'underscore';
import { capitalizeFirstLetter } from 'utils/formatingUtil';
import { filter, includes, sortBy, find, uniqWith, map, groupBy } from 'lodash';
import {
    TYPE_UNASSIGNED,
    STATUS_PARKED,
    STATUS_NON_BOOKABLE,
    TYPE_REGULAR,
    STATUS_ACTIVE,
    STATUS_ARCHIVED,
    ROLE_OWNER,
} from 'enums/resourceEnum';
import { getPermittedResourcesByProject, getPermittedResources } from 'modules/scheduler/services/scheduleRights';
import { getCompanyExtensions, selectCompanyOwnerId } from './company';
import { getAccount } from './account';
import { arrayToObjectByKey } from '../utils/mappingUtil';

const EMPTY_OBJECT = {};

const getProjects = createSelector(
    state => state.projectReducer.projects,
    state => state.account.resourceId,
    (projects, accountResourceId) => {
        return filter(
            projects,
            project => project.projectManagerIds && includes(project.projectManagerIds, accountResourceId)
        );
    }
);

export const resourcesLoading = state => state.resourceReducer.loading;
export const resourcesLoaded = state => !state.resourceReducer.loading;
export const selectLastCallContext = state => state.resourceReducer.lastCallContext;

export const getResources = state => state.resourceReducer.resources;
export const getResourcesMap = createSelector(getResources, resources => arrayToObjectByKey(resources, '_id'));
export const selectProjectManagers = state => state.resourceReducer.projectManagers;

export const makeGetFilteredResources = filterType =>
    createSelector(getResources, getProjects, selectProjectManagers, (resources, projects, projectManagers) => {
        let resourceIds = [];
        let filteredResources = resources;

        switch (filterType) {
            case 'ARCHIVED':
                filteredResources = filter(
                    resources,
                    resource =>
                        resource.type === TYPE_REGULAR.value &&
                        resource.status === STATUS_ARCHIVED.value &&
                        resource.hasRightsToResource
                );
                break;
            case 'ACTIVE':
                filteredResources = filter(
                    resources,
                    resource =>
                        resource.type === TYPE_REGULAR.value &&
                        resource.status === STATUS_ACTIVE.value &&
                        resource.hasRightsToResource
                );
                break;
            case 'POSSIBLE_ACCOUNT_OWNERS':
                filteredResources = filter(
                    resources,
                    resource =>
                        resource.type === TYPE_REGULAR.value &&
                        (resource.status === STATUS_ACTIVE.value || resource.status === STATUS_NON_BOOKABLE.value) &&
                        resource.email?.indexOf('@') !== -1 &&
                        resource.hasRightsToResource
                );
                break;
            case 'NOT_ARCHIVED':
                filteredResources = filter(
                    resources,
                    resource => resource.status !== STATUS_ARCHIVED.value && resource.hasRightsToResource
                );
                break;
            case 'IS_PROJECT_MANAGER':
                // resources as fallback
                filteredResources = filter(
                    projectManagers.length ? projectManagers : resources,
                    resource => resource.isProjectManager && resource.hasRightsToResource
                );
                break;
            case 'POTENTIAL_PROJECT_MANAGER':
                // resources as fallback
                filteredResources = filter(
                    resources,
                    resource =>
                        resource.type === TYPE_REGULAR.value &&
                        resource.status === STATUS_ACTIVE.value &&
                        !resource.isProjectManager &&
                        resource.hasRightsToResource
                );
                break;
            case 'EXCLUDE_PARKED':
                filteredResources = filter(
                    resources,
                    resource => resource.status !== STATUS_PARKED.value && resource.hasRightsToResource
                );
                break;
            case 'EXCLUDE_PARKED_NON_BOOKABLE':
                filteredResources = filter(
                    resources,
                    resource =>
                        !includes([STATUS_PARKED.value, STATUS_NON_BOOKABLE.value], resource.status) &&
                        resource.hasRightsToResource
                );
                break;
            case 'EXCLUDE_PARKED_NON_BOOKABLE_ARCHIVED':
                filteredResources = filter(
                    resources,
                    resource =>
                        !includes(
                            [STATUS_PARKED.value, STATUS_NON_BOOKABLE.value, STATUS_ARCHIVED.value],
                            resource.status
                        ) && resource.hasRightsToResource
                );
                break;
            case 'EXCLUDE_UNASSIGNED':
                filteredResources = filter(
                    resources,
                    resource => resource.type !== TYPE_UNASSIGNED.value && resource.hasRightsToResource
                );
                break;
            case 'EXCLUDE_PARKED_UNASSIGNED':
                filteredResources = filter(
                    resources,
                    resource =>
                        resource.status !== STATUS_PARKED.value &&
                        resource.type !== TYPE_UNASSIGNED.value &&
                        resource.hasRightsToResource
                );
                break;
            case 'UNASSIGNED':
                filteredResources = filter(
                    resources,
                    resource => resource.type === TYPE_UNASSIGNED.value && resource.hasRightsToResource
                );
                break;
            case 'MY_MANAGED':
                resourceIds = chain(projects)
                    .map(project => project.resourceIds, {})
                    .reduceRight((a, b) => a.concat(b), [])
                    .value();

                filteredResources = filter(
                    resources,
                    resource => includes(resourceIds, resource.resourceId.toString()) && resource.hasRightsToResource
                );
                break;
            default:
                filteredResources = filter(resources, resource => resource.hasRightsToResource);
                break;
        }

        return sortBy(filteredResources, resource => resource.firstName.toLowerCase());
    });

export const selectNotArchivedResources = makeGetFilteredResources('NOT_ARCHIVED');
export const selectActiveResources = makeGetFilteredResources('ACTIVE');
export const selectPossibleAccountOwners = makeGetFilteredResources('POSSIBLE_ACCOUNT_OWNERS');
export const selectResourcesExcludeUnassigned = makeGetFilteredResources('EXCLUDE_UNASSIGNED');
export const selectPotentialProjectManagers = makeGetFilteredResources('POTENTIAL_PROJECT_MANAGER');

export const selectNotArchivedResourcesMap = createSelector(selectNotArchivedResources, resources => {
    return (
        resources?.reduce((acc, resource) => {
            acc[resource._id] = resource;
            return acc;
        }, {}) ?? EMPTY_OBJECT
    );
});

export const makeSelectResourcesWithGivenPolicy = toilPolicyId =>
    createSelector(selectActiveResources, resources => {
        if (!toilPolicyId) {
            return resources.filter(resource => !resource.toilPolicyId);
        }

        return resources.filter(resource => resource.toilPolicyId === toilPolicyId);
    });

export const makeSelectResourcesWithNotGivenPolicy = toilPolicyId =>
    createSelector(selectActiveResources, resources => {
        if (!toilPolicyId) {
            return resources.filter(resource => !resource.toilPolicyId);
        }

        return resources.filter(resource => resource.toilPolicyId !== toilPolicyId);
    });

export const makeResourceById = id =>
    createSelector(getResources, resources => find(resources, resource => resource._id === id));

export const makeResourceByIdFromMap = id => createSelector(getResourcesMap, resources => resources[id]);

export const selectAccountOwner = createSelector(
    selectCompanyOwnerId,
    getResourcesMap,
    getResources,
    (accountOwnerId, resourcesMap, resources) => {
        if (!accountOwnerId || !resourcesMap) {
            return (resources ?? []).find(resource => resource.role === ROLE_OWNER.value);
        }

        return resourcesMap[accountOwnerId];
    }
);

export const makeGetResourcesByProjectForSchedule = (
    project,
    { onlyUnassigned, removeUnassigned, canRequestResources, groupResources } = {}
) =>
    createSelector(getResources, getAccount, getCompanyExtensions, (resources, account, companyExtensions) => {
        let permittedResources = getPermittedResourcesByProject(
            project,
            account,
            resources,
            companyExtensions,
            onlyUnassigned
        )
            // Exclude always Archived https://hubplanner.atlassian.net/browse/HUB-9534
            .filter(resource => resource.status !== STATUS_ARCHIVED.value);

        if (canRequestResources) {
            permittedResources = uniqWith(
                [
                    ...permittedResources,
                    ...filter(resources, resource => {
                        return (
                            resource.status !== STATUS_ARCHIVED.value &&
                            (resource.hasRightsToResource || includes(project.resources, resource._id))
                        );
                    }),
                ],
                (res1, res2) => res1._id === res2._id
            );
        }

        if (removeUnassigned) {
            permittedResources = filter(permittedResources, resource => resource.type !== TYPE_UNASSIGNED.value);
        }

        if (groupResources) {
            const groupedResources = groupBy(permittedResources, resource => resource.type);

            return map(groupedResources, (groupedResource, type) => ({
                title: capitalizeFirstLetter(type),
                data: groupedResource,
            }));
        }

        return permittedResources;
    });

export const makeGetResourcesForSchedule = ({ removeUnassigned, onlyUnassigned }) =>
    createSelector(getResources, getAccount, getCompanyExtensions, (resources, account, companyExtensions) => {
        const permittedResources = getPermittedResources(account, resources, companyExtensions)
            // Exclude always Archived https://hubplanner.atlassian.net/browse/HUB-9534
            .filter(resource => {
                return resource.status !== STATUS_ARCHIVED.value;
            });

        if (onlyUnassigned) {
            return filter(permittedResources, resource => resource.type === TYPE_UNASSIGNED.value);
        }
        if (removeUnassigned) {
            return filter(permittedResources, resource => resource.type !== TYPE_UNASSIGNED.value);
        }

        return permittedResources;
    });

export const makeGetPermittedResources = (
    excludeIds,
    { removeResources, groupResources, removeUnassigned, removeArchived, removeParked, removeNotBookable } = {}
) =>
    createSelector(getResources, getAccount, getCompanyExtensions, (resources, account, companyExtensions) => {
        const permittedResources = getPermittedResources(account, resources, companyExtensions);

        let filtered = permittedResources;

        if (removeUnassigned) {
            filtered = filtered.filter(resource => resource.type !== TYPE_UNASSIGNED.value);
        }

        if (removeResources) {
            // Not Rights, leave only UW (filtered )
            filtered = filtered.filter(resource => {
                return resource.type === TYPE_UNASSIGNED.value;
            });
        }

        if (removeArchived) {
            filtered = filtered.filter(resource => {
                return resource.status !== STATUS_ARCHIVED.value;
            });
        }

        if (removeParked) {
            filtered = filtered.filter(resource => {
                return resource.status !== STATUS_PARKED.value;
            });
        }

        if (removeNotBookable) {
            filtered = filtered.filter(resource => {
                return resource.status !== STATUS_NON_BOOKABLE.value;
            });
        }

        filtered = (excludeIds?.length ? filtered.filter(({ _id }) => !excludeIds.includes(_id)) : filtered).filter(
            ({ hasRightsToResource }) => hasRightsToResource
        );

        if (groupResources) {
            const groupedResources = groupBy(filtered, resource => resource.type);

            return map(groupedResources, (groupedResource, type) => ({
                title: capitalizeFirstLetter(type),
                data: groupedResource,
            }));
        }

        return filtered;
    });
