import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { isEmpty } from 'lodash';
import MetisMenu from 'modules/sidebar/menu';
import MenuFactory from 'modules/sidebar/services/menuBuilder/MenuFactory';
import { hasRole } from 'utils/rightsUtil';
import { scrollToActive } from '../../../shared/lib/sidebar';

class Sidebar extends Component {
    static propTypes = {
        windowHeight: PropTypes.number.isRequired,
        windowWidth: PropTypes.number.isRequired,
        isSettingsPage: PropTypes.bool,
        isReportPage: PropTypes.bool,
    };

    static defaultProps = {
        isSettingsPage: false,
        isReportPage: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            menuScrolled: '',
            current: '',
            menuContent: [],
        };
    }

    componentDidMount() {
        const { account, billingInfo, isSettingsPage, isReportPage } = this.props;
        const hash = this.props.location.pathname.split('/');
        const menu = this.getMenu(hash[1]);

        this.setState({
            current: hash[1],
            menuContent: menu.getMenu(),
        });

        if (isReportPage && !this.props.reportTemplates.length) {
            this.props.getReportTemplates();
        }

        if (isEmpty(billingInfo) && isSettingsPage && hasRole(account.resourceRoleRights, 'settingBilling')) {
            this.props.getBillingInfo();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.menuScrolled || this.props.location.pathname !== prevProps.location.pathname) {
            const hash = this.props.location.pathname.split('/')

            scrollToActive(hash[hash.length - 1], this.props.location.pathname !== prevProps.location.pathname, 0);
            this.setState({
                menuScrolled: true,
            });
        }

        if (this.shouldBeUpdated(prevProps, prevState)) {
            const hash = this.props.location.pathname.split('/');
            const menu = this.getMenu(hash[1]);

            this.setState({
                current: hash[1],
                menuContent: menu.getMenu(),
            });
        }
    }

    getMenu(type) {
        const {
            account,
            companyTree,
            approvees,
            currentView,
            vacationEventName,
            resourceKeyWord,
            projectKeyWord,
            resourcePluralKeyWord,
            reportTemplates,
            reportsGroups,
            customerKeyWord,
            projectPluralKeyWord,
            customerPluralKeyWord,
            company,
            billingInfo,
        } = this.props;

        return MenuFactory.create(type, {
            account,
            companyTree,
            approvees,
            currentView,
            reportTemplates,
            reportsGroups,
            extensions: company.extensions,
            keyWords: {
                vacationEventName,
                resourceKeyWord,
                resourcePluralKeyWord,
                projectKeyWord,
                customerKeyWord,
                projectPluralKeyWord,
                customerPluralKeyWord,
            },
            company,
            billingInfo,
        });
    }

    shouldBeUpdated(prevProps) {
        const hash = this.props.location.pathname.split('/');

        return (
            !this.state.menuContent.length ||
            !isEqual(prevProps.companyTree, this.props.companyTree) ||
            !isEqual(prevProps.account, this.props.account) ||
            !isEqual(prevProps.approvees, this.props.approvees) ||
            prevProps.company.status !== this.props.company.status ||
            prevProps.windowHeight !== this.props.windowHeight ||
            prevProps.vacationEventName !== this.props.vacationEventName ||
            prevProps.resourceKeyWord !== this.props.resourceKeyWord ||
            prevProps.resourcePluralKeyWord !== this.props.resourcePluralKeyWord ||
            prevProps.currentView !== this.props.currentView ||
            !isEqual(prevProps.reportsGroups, this.props.reportsGroups) ||
            !isEqual(prevProps.reportTemplates, this.props.reportTemplates) ||
            hash[1] !== this.state.current ||
            !isEqual(prevProps.billingInfo, this.props.billingInfo)
        );
    }

    render() {
        const { windowHeight, windowWidth, isReportPage } = this.props;
        const activeLink =
            isReportPage && !this.props.account.preferences.report.tour.viewed ? null : this.props.location.pathname;

        return (
            <div
                className="sidebar sidebar-nav"
                style={{ height: `${windowHeight - (991 >= windowWidth ? 42 : 51)}px` }}
            >
                <MetisMenu
                    rowHeight={this.props.rowHeight}
                    id="report-tour-sidebar"
                    content={this.state.menuContent}
                    activeLinkTo={activeLink}
                />
            </div>
        );
    }
}

export default Sidebar;
