import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { SmallInfoTile } from '@hub-mono/ui';

const NextVacation = ({ defaultVacationName, upcomingEvents }) => {
    const nextVacation = find(upcomingEvents.toJSON(), event => event.isFuture && event.isVacation);
    const daysUntilNextVacation = nextVacation && nextVacation.diff;

    return (
        <SmallInfoTile
            title={`Upcoming ${defaultVacationName}`}
            value={daysUntilNextVacation ?? 0}
            text={nextVacation ? 'Let the countdown begin' : 'Treat yourself!'}
        />
    );
};

NextVacation.propTypes = {
    resourceId: PropTypes.string.isRequired,
    upcomingEvents: PropTypes.object.isRequired,
    defaultVacationName: PropTypes.string,
};

NextVacation.defaultProps = {
    defaultVacationName: '',
};

export default NextVacation;
