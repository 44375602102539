import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { keys } from 'underscore';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import SearchList from 'modules/scheduler/components/searchList/searchList';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { MenuCollapse } from '../menuCollapse';

const ResourceToggler = props => {
    const { resource, keyword, resources, onChange, onToggleOpen, closeToggle } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    const onSelect = useCallback(
        resource => {
            onChange(resource);
            setIsOpen(prevState => !prevState);
        },
        [onChange]
    );

    return (
        <>
            <Toggler data-cy="resource-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    {0 < keys(resource).length && resource.name}{' '}
                    <span>{0 === keys(resource).length && `Select a ${keyword.toLowerCase()}`}</span>
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="px-3 py-2">
                    <SearchList
                        data={resources}
                        onSelect={onSelect}
                        name="resource"
                        height={200}
                        fields={{ label: 'name', value: '_id', wrapWithBrackets: true }}
                        placeholder={`Filter by ${keyword.toLowerCase()} Name`}
                    />
                </div>
            </MenuCollapse>
        </>
    );
};

ResourceToggler.propTypes = {
    resource: PropTypes.object,
    keyword: PropTypes.string,
    resources: PropTypes.array,
    onChange: PropTypes.func,
};

ResourceToggler.defaultProps = {
    resource: null,
    keyword: '',
    resources: [],
    onChange: null,
};

export default ResourceToggler;
