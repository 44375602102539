import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getResourceGroupResources = {
    request: id => action(actionTypes.GET_RESOURCE_GROUP_RESOURCES['REQUEST'], { id }),
    success: resources => action(actionTypes.GET_RESOURCE_GROUP_RESOURCES['SUCCESS'], { resources }),
};

export const getResourceGroups = {
    request: () => action(actionTypes.GET_RESOURCE_GROUPS['REQUEST']),
    success: (resourceGroups, lastCallContext) =>
        action(actionTypes.GET_RESOURCE_GROUPS['SUCCESS'], { resourceGroups, lastCallContext }),
    stop: () => action(actionTypes.GET_RESOURCE_GROUPS_STOP),
};

export const createResourceGroup = {
    request: (data, redirect = false, callback) =>
        action(actionTypes.CREATE_RESOURCE_GROUP['REQUEST'], { data, redirect, callback }),
    success: resourceGroup => action(actionTypes.CREATE_RESOURCE_GROUP['SUCCESS'], { resourceGroup }),
};

export const updateResourceGroup = {
    request: ({ id, data, callback, redirect }) =>
        action(actionTypes.UPDATE_RESOURCE_GROUP['REQUEST'], { data, id, callback, redirect }),
    success: resourceGroup => action(actionTypes.UPDATE_RESOURCE_GROUP['SUCCESS'], { resourceGroup }),
};

export const deleteResourceGroup = {
    request: (id, cb) => action(actionTypes.DELETE_RESOURCE_GROUP['REQUEST'], { id, cb }),
    success: () => action(actionTypes.DELETE_RESOURCE_GROUP['SUCCESS']),
};

export const addGroupApprovers = {
    request: ({ resourceId, groupIds }) => action(actionTypes.ADD_GROUP_APPROVERS['REQUEST'], { resourceId, groupIds }),
    success: ({ resourceId, groupIds }) => action(actionTypes.ADD_GROUP_APPROVERS['SUCCESS'], { resourceId, groupIds }),
    failure: ({ resourceId, groupIds }) => action(actionTypes.ADD_GROUP_APPROVERS['FAILURE'], { resourceId, groupIds }),
};

export const removeGroupApprovers = {
    request: ({ resourceId, groupIds }) =>
        action(actionTypes.REMOVE_GROUP_APPROVERS['REQUEST'], { resourceId, groupIds }),
    success: ({ resourceId, groupIds }) =>
        action(actionTypes.REMOVE_GROUP_APPROVERS['SUCCESS'], { resourceId, groupIds }),
    failure: ({ resourceId, groupIds }) =>
        action(actionTypes.REMOVE_GROUP_APPROVERS['FAILURE'], { resourceId, groupIds }),
};
