import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Field, getFormValues, isDirty } from 'redux-form';
import { isNaN, values, isNumber } from 'underscore';
import { FormText, Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import allocationTypes from 'enums/allocationTypeEnum';
import { Section } from './styles';
import { getBookingAllocationValuesFromSingleValue } from 'shared/lib/booking';
import { getResourceCapacity } from 'utils/capacityCalculation';
import { getResources } from 'selectors/resource';
import { usePrevious } from '../../hooks';
import {
    selectUseCategoriesAllocation,
    selectBookingDefaultState,
    selectCompanyAvgDailyCapacity,
} from 'selectors/company';
import { Layout, NumberInputReduxFormWrapper, Typography } from '@hub-mono/ui';
import { makeStyles } from '@material-ui/core';
import { createCySelector } from '../../utils/cypressUtil';
import styled, { css } from 'styled-components';

const allocationTypesArray = values(allocationTypes);

const StyledNumberInputReduxFormWrapper = styled(NumberInputReduxFormWrapper)`
    ${props => {
        if (!props.inline) {
            return css``;
        }

        return css`
            &.MuiTextField-root {
                .MuiInputBase-root {
                    .MuiOutlinedInput-input {
                        font-size: 13px;
                        padding: 12.5px 8px;
                    }
                }
            }
        `;
    }}
`;

const MaterialAllocation = props => {
    const {
        title,
        subtitle,
        formText,
        wrapperClassName,
        onChange,
        stateValue,
        allocationDefaultState,
        inline,
        disableAll,
        onEnterPressed,
        formName,
        allDay,
        doNotShowAllocationFields,
    } = props;

    const defaultBookingAllocation = useSelector(selectBookingDefaultState);
    const [state, setState] = useState(allocationDefaultState ?? defaultBookingAllocation);
    // stateValue will allow to make this component controlled
    const componentStateValue = stateValue !== undefined && stateValue !== null ? stateValue : state;
    const shouldUseCategoriesAllocation = useSelector(selectUseCategoriesAllocation);

    const avgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);
    const currentFormValues = useSelector(getFormValues(formName));
    const dirty = useSelector(isDirty(formName));
    const resources = useSelector(getResources);

    useEffect(() => {
        if (shouldUseCategoriesAllocation && dirty) {
            setState(currentFormValues?.category?.state ?? state);
        }
    }, [currentFormValues?.category?.state, shouldUseCategoriesAllocation, dirty, state]);

    const getNewBookingAllocationValues = useCallback(
        (nameOfInput, value) => {
            const resourceId =
                (Array.isArray(currentFormValues?.resources) && currentFormValues?.resources[0]?._id) ||
                currentFormValues?.resource?._id ||
                currentFormValues?._id;

            const startDateTmp = moment(currentFormValues?.startDate || currentFormValues?.start);
            const endDateTmp = moment(currentFormValues?.endDate || currentFormValues?.end);
            const currentResource = (resources || []).find(({ _id }) => _id === resourceId);
            const { workDaysCount, totalMinutes: totalBookingMinutes } = getResourceCapacity(
                currentResource,
                startDateTmp,
                endDateTmp
            );

            return getBookingAllocationValuesFromSingleValue({
                value: !value || value === '' || Number.isNaN(value) ? 0 : parseFloat(value),
                nameOfValue: nameOfInput,
                avgDailyCapacity,
                totalBookingMinutes,
                numberOfWorkDays: workDaysCount,
            });
        },
        [
            avgDailyCapacity,
            currentFormValues?._id,
            currentFormValues?.end,
            currentFormValues?.endDate,
            currentFormValues?.resource?._id,
            currentFormValues?.resources,
            currentFormValues?.start,
            currentFormValues?.startDate,
            resources,
        ]
    );

    const percentValue = currentFormValues?.[allocationTypes.PERCENTAGE.valueName];
    const hoursDayValue = currentFormValues?.[allocationTypes.HOURS_DAY.valueName];
    const totalHoursValue = currentFormValues?.[allocationTypes.TOTAL_HOURS.valueName];

    const memoizedValuesForOnDatesUpdate = useMemo(() => {
        return {
            startDate: currentFormValues?.startDate || currentFormValues?.start,
            endDate: currentFormValues?.endDate || currentFormValues?.end,
            [allocationTypes.PERCENTAGE.valueName]: percentValue,
            [allocationTypes.HOURS_DAY.valueName]: hoursDayValue,
            [allocationTypes.TOTAL_HOURS.valueName]: totalHoursValue,
        };
    }, [
        currentFormValues?.end,
        currentFormValues?.endDate,
        currentFormValues?.start,
        currentFormValues?.startDate,
        hoursDayValue,
        percentValue,
        totalHoursValue,
    ]);

    const prevStartDate = usePrevious(memoizedValuesForOnDatesUpdate.startDate);
    const prevEndDate = usePrevious(memoizedValuesForOnDatesUpdate.endDate);

    useEffect(() => {
        // when start / end dates have changed we need to recalculate allocation values
        if (
            (prevStartDate === memoizedValuesForOnDatesUpdate.startDate &&
                prevEndDate === memoizedValuesForOnDatesUpdate.endDate) ||
            !componentStateValue
        ) {
            return;
        }

        const valueName = allocationTypesArray.find(type => type.state === componentStateValue)?.valueName;

        const newBookingAllocationValues = getNewBookingAllocationValues(
            valueName,
            memoizedValuesForOnDatesUpdate[valueName]
        );

        onChange(newBookingAllocationValues);
    }, [
        getNewBookingAllocationValues,
        memoizedValuesForOnDatesUpdate,
        onChange,
        prevEndDate,
        prevStartDate,
        componentStateValue,
    ]);

    const onAllocationValuesChange = event => {
        const { name: nameOfInput, value } = event.currentTarget;
        const newBookingAllocationValues = getNewBookingAllocationValues(nameOfInput, Math.abs(value));
        onChange(newBookingAllocationValues);
    };

    const changeAllocationState = (event, state) => {
        event.currentTarget.select();
        setState(state);
        onChange({ state });
    };

    const onKeyPress = event => {
        if (13 === event.which || 13 === event.keyCode) {
            onEnterPressed && onEnterPressed();
        }
    };

    const normalizeAllocation = value => {
        if (isNumber(value)) {
            return value;
        }

        if (!value || isNaN(value)) {
            return '';
        }

        value = value.replace(/[^0-9.]/g, '');
        if (2 < value.split('.').length) {
            value = value.replace(/\.+$/, '');
        }

        return parseFloat(value);
    };

    const classes = useStyles();

    return (
        <Section className={wrapperClassName}>
            <Layout stack gap={inline ? '--Spacing-50' : 24}>
                <Layout stack gap="--Spacing-50">
                    {title && (
                        <Typography variant="label" size="large" prominent>
                            {title}
                        </Typography>
                    )}
                    {subtitle && (
                        <Typography variant="body" size="small">
                            {subtitle}
                        </Typography>
                    )}
                </Layout>
                {!doNotShowAllocationFields && (
                    <Layout gap={inline ? '--Spacing-50' : 16} stack={!inline}>
                        {allocationTypesArray.map(type => (
                            <Field
                                inline={inline}
                                key={type.state}
                                component={StyledNumberInputReduxFormWrapper}
                                name={type.valueName}
                                label={type.display}
                                disabled={disableAll || allDay === false}
                                onChange={onAllocationValuesChange}
                                type="number"
                                normalize={normalizeAllocation}
                                className={
                                    type.state === componentStateValue || disableAll || allDay === false
                                        ? classes.active
                                        : classes.blurred
                                }
                                onFocus={event => changeAllocationState(event, type.state)}
                                onKeyPress={onKeyPress}
                                startAdornment={'PERCENTAGE' === type.state ? '%' : 'hrs'}
                                dataCy={createCySelector(`allocation-${type.state}`)}
                            />
                        ))}
                    </Layout>
                )}
                {formText && allDay ? <FormText className="ml-3">{formText}</FormText> : null}
                {allDay === false && !inline && (
                    <Alert color="warning mt-3">
                        You cannot edit the allocation of a booking which was created in the hour scale. In order to
                        edit the allocation you need to change your scale to hours and edit the booking manually by
                        resizing it.
                    </Alert>
                )}
            </Layout>
        </Section>
    );
};

const useStyles = makeStyles({
    active: {
        opacity: 1,
    },
    blurred: {
        opacity: 0.5,
    },
});

MaterialAllocation.propTypes = {
    title: PropTypes.string,
    formText: PropTypes.string,
    wrapperClassName: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    allocationDefaultState: PropTypes.string,
    inline: PropTypes.bool,
    disableAll: PropTypes.bool,
    disableGutters: PropTypes.bool,
    onEnterPressed: PropTypes.func,
    formName: PropTypes.string,
};

MaterialAllocation.defaultProps = {
    allocationDefaultState: allocationTypes.PERCENTAGE.state,
    inline: false,
    disableAll: false,
    disableGutters: false,
    onEnterPressed: null,
    wrapperClassName: '',
};

export default MaterialAllocation;
