import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { confirmationValidator, lengthValidator, requiredValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        newPassword: {
            type: String,
            required: true,
            validators: [lengthValidator(8, 'Password')],
        },
        confirmNewPassword: {
            type: String,
            required: true,
            validators: [confirmationValidator('newPassword')],
        },
        confirm: {
            type: Boolean,
            required: true,
            validators: [requiredValidator('You must agree to our terms of service')],
        },
        newFeatureEmailAgreed: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
    },
    errorMessages,
    false
);
