import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { materialInputGroupField as MaterialInputGroupField } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { TYPE_EVENT } from 'enums/projectEnum';
import { eventFormSchema, getDefaultValues } from './form';
import { useDispatch, useSelector } from 'react-redux';
import { createProject } from '../../../../../../actions/projectActions';

const formFields = [
    {
        label: 'Name',
        name: 'name',
    },
    {
        label: 'Code',
        name: 'projectCode',
    },
];

const validate = values => {
    return eventFormSchema.validate(values);
};

const NewShortEvent = props => {
    const { onCreate, initialName = '', submitButtonText } = props;
    const dispatch = useDispatch();
    const isCreating = useSelector(state => state.projectReducer.isCreating);

    const formJSX = formProps => {
        const { values, errors, touched, handleChange, handleBlur, handleSubmit } = formProps;
        const submitDisabled = (initialName.length > 0 && Object.keys(errors).length > 0) || isCreating;

        return (
            <form className="mt-5 mb-5" onSubmit={handleSubmit}>
                {formFields.map((formField, index) => {
                    const { label, name } = formField;
                    const meta = {
                        touched: touched[name],
                        error: errors[name],
                    };

                    return (
                        <Row key={index} className="mt-3">
                            <Col>
                                <MaterialInputGroupField
                                    label={label}
                                    name={name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values[name]}
                                    meta={meta}
                                />
                            </Col>
                        </Row>
                    );
                })}
                <SuccessButton
                    fixed
                    disabled={submitDisabled}
                    name={submitButtonText || 'Create event'}
                    onClick={handleSubmit}
                    dataCy="button--create-event"
                    className="mt-2"
                />
            </form>
        );
    };

    const initialValues = getDefaultValues(initialName);
    const onSubmit = values => {
        const eventToSave = { ...values, type: TYPE_EVENT.value };

        dispatch(
            createProject.request(
                {
                    project: eventToSave,
                    resources: [],
                    options: {
                        skipCustomFieldsValidation: true
                    }
                },
                newEvent => {
                    onCreate(newEvent);
                }
            )
        );
    };

    return (
        <Formik
            key={initialName}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            component={formJSX}
        />
    );
};

NewShortEvent.propTypes = {
    onCreate: PropTypes.func.required,
    initialName: PropTypes.string,
    submitButtonText: PropTypes.string,
};

export default React.memo(NewShortEvent);
