import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TipPopupSlide from '../components/TipPopupSlide';
import PropTypes from 'prop-types';
import { Button, Layout, MenuIconButton, Typography } from '@hub-mono/ui';
import styled from 'styled-components';

const useStyles = makeStyles({
    main: {
        zIndex: 1030,
        position: 'fixed',
        padding: '25px',
        bottom: '20px',
        right: '85px',
        background: '#fff',
        boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        width: '25%',
        minWidth: '450px',
        maxWidth: '500px',
        maxHeight: 'calc(100% - 40px)',
        overflow: 'auto',
    },
    iconsRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
    },
    icon: {
        cursor: 'pointer',
    },
    closeOptions: {
        boxShadow:
            '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        border: 'none',
    },
    closeOption: {
        cursor: 'pointer',
    },
    slideContent: {
        margin: '8px 0 20px 0',
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    dotsNavigation: {
        color: '#4E5252',
        fontSize: '16px',
    },
    dotsNavigationOutline: {
        color: '#ADAEBB',
        fontSize: '16px',
    },
    dotsNavigationWrapper: {
        marginTop: '10px',
    },
});

const StyledModal = styled.div`
    border-radius: 28px;
    padding: 24px;
    overflow: auto;
    width: 25%;
    min-width: 450px;
    max-width: 500px;
    max-height: calc(100% - 40px);
    position: fixed;
    bottom: 80px;
    right: 85px;
    background-color: ${props => props.theme.designTokens.color.m3.sys.light['surface-container'].value};
    box-shadow: ${props => props.theme.shadows[4]};
`;

const TipPopup = React.memo(props => {
    const { slides, closeActions, title, subTitle, contextData, onFinish } = props;
    const classes = useStyles();
    const [currentSlide, setCurrentSlide] = useState(0);

    const slidesJSX = useCallback(() => {
        return (
            <div className={classes.slideContent}>
                {slides[currentSlide] && <TipPopupSlide {...slides[currentSlide]} />}
            </div>
        );
    }, [classes.slideContent, currentSlide, slides]);

    const slidesButtonsJSX = useCallback(() => {
        return (
            <div className={classes.dotsNavigationWrapper}>
                {slides.length > 1 && (
                    <div>
                        {slides.map((slide, index) => {
                            if (index === currentSlide) {
                                return <FiberManualRecordIcon key={index} className={classes.dotsNavigation} />;
                            }
                            return (
                                <FiberManualRecordOutlinedIcon
                                    className={classes.dotsNavigationOutline}
                                    onClick={() => setCurrentSlide(index)}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }, [classes.dotsNavigation, classes.dotsNavigationOutline, classes.dotsNavigationWrapper, currentSlide, slides]);

    const buttonsJSX = useCallback(() => {
        return (
            <div className={classes.buttons}>
                {slidesButtonsJSX()}
                {currentSlide === slides.length - 1 && (
                    <Button onClick={() => onFinish(contextData)} dataCy="button--finish">
                        Finish
                    </Button>
                )}
                {currentSlide !== slides.length - 1 && (
                    <Button
                        onClick={() => setCurrentSlide(currentSlide + 1)}
                        dataCy={`button--next-slide${currentSlide}`}
                    >
                        Next
                    </Button>
                )}
            </div>
        );
    }, [classes.buttons, slidesButtonsJSX, currentSlide, slides, contextData, onFinish]);

    const headerJSX = useCallback(() => {
        const titleFromSlide = slides && slides[currentSlide]?.title;
        const titleToRender = titleFromSlide ? slides[currentSlide]?.title : title;
        const subTitleToRender = titleFromSlide
            ? slides[currentSlide]?.subTitle
            : slides[currentSlide]?.subTitle || subTitle;
        const upperTitle = slides[currentSlide]?.upperTitle;

        const options = closeActions.map(action => {
            return {
                label: action.title,
                onClick: () => action.action(contextData),
            };
        });

        return (
            <Layout stack gap="--Spacing-100">
                <Layout hAlign="space-between">
                    <Layout stack gap="--Spacing-100">
                        {upperTitle && (
                            <Typography variant="title" size="medium">
                                {upperTitle}
                            </Typography>
                        )}
                        {titleToRender && (
                            <Typography variant="headline" size="small">
                                {titleToRender}
                            </Typography>
                        )}
                    </Layout>
                    <Layout>
                        <MenuIconButton icon="close" options={options} dataCy="tips-pod-close-btn" />
                    </Layout>
                </Layout>

                {subTitleToRender && (
                    <Typography variant="body" size="medium" color="on-surface-variant">
                        {subTitleToRender}
                    </Typography>
                )}
            </Layout>
        );
    }, [closeActions, contextData, currentSlide, slides, subTitle, title]);

    if (!title && !subTitle && slides?.length === 0) {
        return null;
    }

    return (
        <StyledModal>
            {headerJSX()}
            <Layout stack gap={24}>
                {slides?.length > 0 && slidesJSX()}
                {slides?.length > 0 && buttonsJSX()}
            </Layout>
        </StyledModal>
    );
});

TipPopup.propTypes = {
    slides: PropTypes.array,
    closeActions: PropTypes.array,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    onFinish: PropTypes.func,
};

export default TipPopup;
