import { makeStyles } from '@material-ui/core';
import { scrollStyles } from '../../../../../shared/scroll/styles';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsToilExtensionInstalled } from '../../../../../selectors/company';
import { useAppKeyWords, useForm, useHasRights } from '../../../../../hooks';
import { selectToilPolicies } from '../../../../toil/store/selectors';
import { resetToilReducer } from '../../../../toil/store/actions';
import { toilApi } from '../../../../../api/toil';
import uuid from 'uuid/v1';
import { TOIL_ADJUSTMENT_TYPE_ENUM } from '../../../../toil/enums';
import { Field } from 'redux-form';
import { AdjustmentRow } from './AdjustmentRow';
import { FORM_NAME, useResourceModalFormValues } from '../modal';
import { Divider, Button, Layout, Typography, SelectReduxFormWrapper, MenuButton, NumberSwitcher } from '@hub-mono/ui';
import { isBefore, removeUTCZuluFromDateTimestamp, toDate } from '@hub-mono/utils';
import { useCheckToil } from '../../../../vacation/useCheckToil';
import { showLearnMoreAboutToilModal } from '../../../../../actions/modalActions';
import { isAfter } from 'date-fns';

const useStyles = makeStyles({
    list: {
        paddingTop: '8px',
        maxHeight: '840px',
        maxWidth: '620px',
        overflow: 'auto',
        ...scrollStyles,
    },
});

const rights = [
    {
        rights: ['settingViewExt'],
        rule: 'one',
        name: 'canViewSettingExt',
    },
];

export const ToilResourceSetting = React.memo(({ resourceId }) => {
    const dispatch = useDispatch();
    const { canViewSettingExt } = useHasRights(rights);
    const updateFields = useForm(FORM_NAME);
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const { resourceKeyWord } = useAppKeyWords();
    const toilPolicies = useSelector(selectToilPolicies);
    const listWrapper = useRef();
    const classes = useStyles();
    const { modifiedToilAdjustments, policy } = useResourceModalFormValues();

    const [year, setYear] = useState(new Date().getFullYear());

    const vacationCheckToilStatistics = useCheckToil({
        resourceId,
        toilPolicyId: policy?._id,
        modifiedToilAdjustments,
        year,
        checkToil: Boolean(policy?._id),
    });

    const handleLearnMoreBtnClick = useCallback(() => {
        dispatch(showLearnMoreAboutToilModal());
    }, [dispatch]);

    const handleAddExtensionBtnClick = useCallback(event => {
        event.preventDefault();
        window.location.href = '/settings#extensions';
    }, []);

    const [adjustments, setAdjustments] = useState([]);

    const adjustmentsToDisplay = useMemo(() => adjustments.filter(adjustment => !adjustment.toDelete), [adjustments]);

    useEffect(() => {
        if (isToilExtensionInstalled) {
            dispatch(resetToilReducer());
        }
    }, [dispatch, isToilExtensionInstalled]);

    useEffect(() => {
        let mounted = true;

        if (isToilExtensionInstalled && resourceId) {
            toilApi.getAllAdjustmentsByResourceId({ resourceId, year: new Date().getFullYear() }).then(response => {
                if (mounted) {
                    setAdjustments(
                        (response || []).map(adjustment => {
                            return {
                                ...adjustment,
                                effectiveDate: toDate(removeUTCZuluFromDateTimestamp(adjustment.effectiveDate)),
                            };
                        })
                    );
                }
            });
        }

        return () => {
            mounted = false;
        };
    }, [dispatch, isToilExtensionInstalled, resourceId]);

    const handleYearChange = useCallback(
        newYear => {
            setYear(newYear);

            if (isToilExtensionInstalled) {
                if (resourceId) {
                    toilApi.getAllAdjustmentsByResourceId({ resourceId, year: newYear }).then(response => {
                        const toCreate = Object.values(modifiedToilAdjustments ?? {}).filter(
                            adjustment => adjustment.toCreate && adjustment.effectiveDate?.getFullYear() === newYear
                        );

                        setAdjustments([
                            ...(response || []).map(adjustment => {
                                const edited = modifiedToilAdjustments[adjustment._id] ?? {};

                                return {
                                    ...adjustment,
                                    effectiveDate: toDate(removeUTCZuluFromDateTimestamp(adjustment.effectiveDate)),
                                    ...edited,
                                };
                            }),
                            ...toCreate,
                        ]);
                    });
                } else {
                    const toCreate = Object.values(modifiedToilAdjustments ?? {}).filter(
                        adjustment => adjustment.toCreate && adjustment.effectiveDate?.getFullYear() === newYear
                    );

                    setAdjustments(toCreate);
                }
            }
        },
        [isToilExtensionInstalled, modifiedToilAdjustments, resourceId]
    );

    const handleAddAdjustmentClick = useCallback(
        type => {
            const effectiveDate = new Date();
            effectiveDate.setFullYear(year);

            const newAdjustment = {
                _id: `${uuid()}-created`,
                type,
                effectiveDate,
                value: 0,
                toCreate: true,
            };

            updateFields({
                modifiedToilAdjustments: {
                    ...modifiedToilAdjustments,
                    [newAdjustment._id]: newAdjustment,
                },
            });
            setAdjustments(prev => prev.concat(newAdjustment));
        },
        [modifiedToilAdjustments, updateFields, year]
    );

    const handleAdjustmentChange = useCallback(
        adjustmentToModify => {
            setAdjustments(prev => {
                const updated = {
                    ...adjustmentToModify,
                    toUpdate: true,
                };

                updateFields({
                    modifiedToilAdjustments: {
                        ...modifiedToilAdjustments,
                        [updated._id]: updated,
                    },
                });

                return prev.map(adjustment => {
                    if (adjustmentToModify._id !== adjustment._id) {
                        return adjustment;
                    }

                    return updated;
                });
            });
        },
        [modifiedToilAdjustments, updateFields]
    );

    const handleDeleteAdjustment = useCallback(
        adjustmentToRemove => {
            setAdjustments(prev => {
                const deleted = {
                    ...adjustmentToRemove,
                    toDelete: true,
                };

                updateFields({
                    modifiedToilAdjustments: {
                        ...modifiedToilAdjustments,
                        [deleted._id]: deleted,
                    },
                });

                return prev.map(adjustment => {
                    if (adjustmentToRemove._id !== adjustment._id) {
                        return adjustment;
                    }

                    return deleted;
                });
            });
        },
        [modifiedToilAdjustments, updateFields]
    );

    const addAdjustmentMenuOptions = useMemo(() => {
        return [
            {
                label: 'Manual Adjustment',
                dataCy: 'manual-adjustment',
                onClick: () => {
                    handleAddAdjustmentClick(TOIL_ADJUSTMENT_TYPE_ENUM.MANUAL);
                },
            },
            {
                label: 'Adjust starting balance',
                dataCy: 'adjust-starting-balance',
                onClick: () => {
                    handleAddAdjustmentClick(TOIL_ADJUSTMENT_TYPE_ENUM.STARTING_BALANCE);
                },
            },
        ];
    }, [handleAddAdjustmentClick]);

    if (!isToilExtensionInstalled) {
        return (
            <Layout stack gap={24}>
                <Layout stack gap="--Spacing-50">
                    <Typography variant="label" size="large" prominent>
                        TOIL - Default Policy
                    </Typography>
                    <Typography variant="body" size="small">
                        Enhance your time-off tracking experience! Integrate this fantastic extension for effortless
                        tracking of your accrued leave.
                    </Typography>
                </Layout>
                <Layout gap={24}>
                    <Button
                        variant="outlined"
                        dataCy="learn-more-toil-extension"
                        onClick={handleLearnMoreBtnClick}
                        endIcon="arrowOutward"
                    >
                        Learn more about TOIL
                    </Button>
                    {canViewSettingExt ? (
                        <Button
                            variant="outlined"
                            dataCy="add-toil-extension"
                            onClick={handleAddExtensionBtnClick}
                            endIcon="add"
                        >
                            Add TOIL Extension today
                        </Button>
                    ) : null}
                </Layout>
            </Layout>
        );
    }

    return (
        <Layout stack gap={24}>
            <Layout stack gap={24}>
                <Layout stack gap="--Spacing-50">
                    <Typography variant="label" size="large" prominent>
                        TOIL - Default Policy
                    </Typography>
                    <Typography variant="body" size="small">
                        Choose the policy for TOIL that the {resourceKeyWord} should be using
                    </Typography>
                </Layout>
                <Field
                    key={policy}
                    label="Policy"
                    name="policy"
                    options={toilPolicies}
                    optionLabel="name"
                    optionValue="_id"
                    placeholder="Select policy..."
                    dataCy="resource-policy-select"
                    component={SelectReduxFormWrapper}
                />
            </Layout>
            {policy?._id && resourceId ? (
                <>
                    <Divider />
                    <Layout stack gap={24} ref={listWrapper}>
                        <Layout stack gap="--Spacing-50">
                            <Typography variant="label" size="large" prominent>
                                TOIL - Adjustments
                            </Typography>
                            <Typography variant="body" size="small">
                                Add adjustments to offset this {resourceKeyWord} Toil balance
                            </Typography>
                        </Layout>

                        <Layout>
                            <NumberSwitcher
                                value={year}
                                onChange={handleYearChange}
                                min={1900}
                                max={new Date().getFullYear() + 5}
                                dataCy="adjustments-year-select"
                            />
                        </Layout>

                        <Layout hAlign="flex-start" vAlign="center" gap={36}>
                            <Typography variant="title" size="medium">
                                Balance at start of year
                            </Typography>
                            <Typography variant="body" size="medium">
                                {vacationCheckToilStatistics?.startingBalance ?? '-'} hours
                            </Typography>
                        </Layout>

                        <Layout stack className={classes.list} gap={16}>
                            {adjustmentsToDisplay.map((adjustment, index) => {
                                const prevAdjustment = adjustmentsToDisplay[index - 1];

                                if (
                                    (!prevAdjustment && isAfter(adjustment.effectiveDate, new Date())) ||
                                    (prevAdjustment &&
                                        isBefore(prevAdjustment.effectiveDate, new Date()) &&
                                        isAfter(adjustment.effectiveDate, new Date()))
                                ) {
                                    return (
                                        <>
                                            <Layout pb={12} gap="--Spacing-100" vAlign="center">
                                                <Layout flex="0 0 42.5px">
                                                    <Divider />
                                                </Layout>
                                                <Typography variant="label" size="small">
                                                    Future adjustments will have no impact on current TOIL amount
                                                </Typography>
                                                <Layout flex="0 0 42.5px">
                                                    <Divider />
                                                </Layout>
                                            </Layout>
                                            <AdjustmentRow
                                                key={adjustment._id}
                                                index={index}
                                                adjustment={adjustment}
                                                onDelete={handleDeleteAdjustment}
                                                onChange={handleAdjustmentChange}
                                            />
                                        </>
                                    );
                                }

                                return (
                                    <AdjustmentRow
                                        key={adjustment._id}
                                        index={index}
                                        adjustment={adjustment}
                                        onDelete={handleDeleteAdjustment}
                                        onChange={handleAdjustmentChange}
                                    />
                                );
                            })}
                        </Layout>

                        <Layout>
                            <MenuButton
                                options={addAdjustmentMenuOptions}
                                variant="outlined"
                                endIcon="add"
                                dataCy="add-adjustment"
                                closeOnSelect
                            >
                                Add adjustment
                            </MenuButton>
                        </Layout>
                    </Layout>
                </>
            ) : null}
        </Layout>
    );
});
