import { endOfWeek, format } from 'date-fns';
import { WEEK } from 'modules/scheduler/enums/scale';

export default (companyWeekStartDay, schedulerRef) => args => {
    const { scale: currentScale, displayWeeks } = schedulerRef.current.control || {};
    if (displayWeeks && 1 === args.header.level) {
        args.header.cssClass = args.header.cssClass + ' week-number-scheduler';
    }
    if (currentScale === WEEK.value) {
        if ((displayWeeks && 2 === args.header.level) || (!displayWeeks && 1 === args.header.level)) {
            const dateFromCell = args.header.start.toDate();
            // Timezone difference
            const startDate = new Date(dateFromCell.getTime() + dateFromCell.getTimezoneOffset() * 60 * 1000);
            const endDate = endOfWeek(startDate, {
                weekStartsOn: companyWeekStartDay,
            });
            const startDateToPrint = format(startDate, 'do');
            const endDateToPrint = format(endDate, 'do');
            args.header.html = `${startDateToPrint} - ${endDateToPrint}`;
        }
    }
};
