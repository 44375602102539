import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isFunction, isObject } from 'lodash';
import { MoreVert } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AWS_S3_URL } from 'constants';
import { showItemContextMenu } from 'actions/contextMenuActions';
import { duplicateProject } from 'actions/projectActions';
import { updateProjectGroup } from 'actions/projectGroupActions';
import { showProjectModal } from 'actions/modalActions';
import { resetScheduler, updateBookingSelection } from 'actions/schedulerActions';
import { TextWrapper, ItemContextMenu, SearchIconWrapper } from './styles';
import { getCustomFieldLabel } from 'utils/customFieldUtil';
import { useIsExtensionInstalled, useRouteParams, useProjectAction } from 'hooks';
import { PM } from 'enums/extensionShortIdEnum';
import { dispatchRefreshDPResourcesEvent } from '../../utils/schedulerUtil';

const ProjectItem = props => {
    const {
        active,
        className,
        classNameActive,
        activateMe,
        to,
        id,
        classStore,
        subMenuVisibility,
        hasSubMenu,
        label,
        icon: Icon,
        searchIcon: SearchIcon,
        subText,
        contextMenu,
        project,
        toggleFilterVisibility,
        ...restOfProps
    } = props;
    const dispatch = useDispatch();
    const { projectId, projectGroupId } = useRouteParams();
    const { updateProject } = useProjectAction();
    const projectSubtitleRefId = useSelector(state => state.companyReducer.company.settings.grid.projectSubtitleRefId);
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const customFieldLabel = getCustomFieldLabel(project.customFields, projectSubtitleRefId);
    const projectManagerNames = project.projectManagerNames?.join(', ');
    const placeholder = -1 === project.thumb?.indexOf('placeholder') ? project.thumb : '/img/placeholder_hub.jpg';

    const showContextMenu = () => {
        dispatch(
            showItemContextMenu(
                restOfProps.projectGroupType,
                restOfProps.projectGroup,
                project,
                {
                    showModal: type =>
                        dispatch(
                            showProjectModal({
                                onSubmit: data => {
                                    return updateProject(project._id, data);
                                },
                                type: type,
                                projectId: project._id,
                            })
                        ),
                    duplicate: () =>
                        dispatch(
                            duplicateProject.request(
                                project._id,
                                restOfProps.projectGroup._id,
                                dispatchRefreshDPResourcesEvent
                            )
                        ),
                    updateGroup: data =>
                        dispatch(
                            updateProjectGroup.request({
                                id: restOfProps.projectGroup._id,
                                data,
                                callback: dispatchRefreshDPResourcesEvent,
                            })
                        ),
                },
                'PROJECT'
            )
        );
    };

    const onItemClick = event => {
        let currentId;
        if (projectId && projectGroupId) {
            currentId = `${projectId}_${projectGroupId}`;
        } else {
            currentId = `${projectGroupId}_group_schedule`;
        }

        if (id === currentId) {
            event.preventDefault();
        } else {
            activateMe({ id, to });
            dispatch(resetScheduler.request());
            dispatch(updateBookingSelection.request());
        }
    };

    return (
        <div className="position-relative">
            <Link
                to={to}
                className={classNames(className, active && classNameActive, 'd-flex pl-3')}
                style={{ alignItems: 'center', height: '69px' }}
                onClick={onItemClick}
                data-cy={`menu--project-item--link--${id}`}
            >
                {isFunction(Icon) || isObject(Icon) ? (
                    <Icon
                        style={{ fontSize: '36px' }}
                        className={classNames(classStore.classIcon, 'float-left mr-3')}
                    />
                ) : (
                    <img
                        loading="lazy"
                        alt=""
                        className="project-image-placeholder"
                        src={`${AWS_S3_URL}${placeholder}`}
                    />
                )}
                <TextWrapper className="float-left">
                    <span className="d-block text-truncate" title={label + ' | ' + subText}>
                        {label}
                    </span>
                    {hasSubMenu && (
                        <i
                            className={classNames(
                                classStore.classStateIcon,
                                classStore.iconNamePrefix +
                                    (subMenuVisibility
                                        ? classStore.iconNameStateVisible
                                        : classStore.iconNameStateHidden)
                            )}
                        />
                    )}
                    {project.backgroundColor && (
                        <span
                            style={{ backgroundColor: project.backgroundColor }}
                            className="project-color"
                            unselectable="on"
                        />
                    )}
                    {subText && (
                        <span className="item-sub-text text-truncate">
                            {isPMExtensionInstalled && projectManagerNames && <span className="pm-text">PM </span>}
                            <span className="resource-count text-truncate">
                                {projectManagerNames || customFieldLabel || subText}
                            </span>
                        </span>
                    )}
                    {project.projectCode && (
                        <span className="item-sub-text project-code clear">{project.projectCode}</span>
                    )}
                </TextWrapper>
                {SearchIcon && (
                    <SearchIconWrapper className="pl-3" onClick={toggleFilterVisibility}>
                        <SearchIcon />
                    </SearchIconWrapper>
                )}
            </Link>
            {contextMenu && (
                <ItemContextMenu onClick={showContextMenu} data-cy={`menu--project-item--context-menu--${id}`}>
                    <MoreVert />
                </ItemContextMenu>
            )}
        </div>
    );
};

ProjectItem.propTypes = {
    project: PropTypes.object,
    projectGroupType: PropTypes.string,
    projectGroup: PropTypes.object,
    subText: PropTypes.string,
    contextMenu: PropTypes.bool,
};

ProjectItem.defaultProps = {
    project: {},
    subText: '',
    projectGroup: {},
    projectGroupType: 'SYSTEM',
    contextMenu: false,
};

export default ProjectItem;
