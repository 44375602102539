import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseFilterBox from './baseFilterBox';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { find, isNaN } from 'underscore';
import NumberFilter from 'shared/numberFilter';
import { FiltersBox, Content, Footer, FilterIcon } from './styles';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';

class NumbersFilterBox extends BaseFilterBox {
    static propTypes = {
        columnName: PropTypes.string.isRequired,
        disableUnits: PropTypes.bool,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disableUnits: false,
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            operator: 'eq',
            value: '',
            toggleBox: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        this.setActiveState(true, prevState.active);
    }

    setActiveState = (activeOnly, prevActive) => {
        const {
            columnName,
            disableUnits,
            avgDailyCapacity,
            unit,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const existFilter = find(resultValue, filter => filter.columnName === columnName);
        const isActive = undefined !== existFilter;
        const value = isActive
            ? disableUnits
                ? existFilter.boundary
                : unitTypes[unit].calculation(existFilter.boundary, avgDailyCapacity)
            : '';
        const operator = isActive ? existFilter.operator : 'eq';

        if (activeOnly) {
            prevActive !== isActive && this.setState({ active: isActive });
        } else {
            this.setState({ active: isActive, value, operator });
        }
    };

    onFilter = () => {
        this.toggle();
        const { columnName, disableUnits, avgDailyCapacity, unit } = this.props;
        const value = disableUnits
            ? parseFloat(this.state.value)
            : unitTypes[unit].reverseCalculation(this.state.value, avgDailyCapacity);
        const currentFilter = {
            columnName,
            operator: this.state.operator,
            boundary: value,
        };

        this.updateFilter(currentFilter);
    };

    render() {
        const { operator, value } = this.state;
        const positions = this.node && this.node.getBoundingClientRect();

        return (
            <div className="position-absolute" style={{ right: '3px', top: '3px' }} ref={node => (this.node = node)}>
                {!this.props.disabled && (
                    <FilterIcon
                        open={this.state.toggleBox}
                        active={this.state.active.toString()}
                        onClick={this.toggle}
                    />
                )}
                <FiltersBox
                    position={positions && 600 < positions.left ? 'right' : 'left'}
                    height={174}
                    className={classNames({ 'd-none': !this.state.toggleBox })}
                >
                    <Content>
                        <NumberFilter defaultValues={{ operator, value }} onChange={this.onChange} />
                    </Content>
                    <Footer>
                        <Button disabled={isNaN(parseFloat(value))} color="success" onClick={this.onFilter}>
                            Filter
                        </Button>
                        <Button disabled={!this.state.active} color="secondary" onClick={this.onClear}>
                            Clear
                        </Button>
                    </Footer>
                </FiltersBox>
            </div>
        );
    }
}

export default withRouter(NumbersFilterBox);
