import Schema from 'form-schema-validation';
import linksAndIconsFields from './linksAndIconsFields';
import { map, keys, filter, contains, each, chain, mapObject, find, difference, every } from 'underscore';
import { isNil } from 'lodash';
import { format } from 'date-fns';
import { errorMessages } from 'utils/schemaUtil';
import {
    arrayFieldRequireValidator,
    numberValidator,
    startDateValidator,
    minValidator,
    dynamicRequireValidator,
    repeatTimesValidator,
    requiredWithCustomErrorMessage,
} from 'utils/formValidators';
import { STATUS_ACTIVE, getProjectStatus } from 'enums/projectEnum';
import { DEFAULT } from 'enums/categoryGroupTypeEnum';
import { findCurrency } from 'enums/currencyEnum';
import { mapCustomFields, getMyCustomFieldsDefaultValues } from 'utils/formUtil';
import { mapMilestonesToBE, mapMilestonesToFE, mapPhasesToBE, mapPhasesToFE } from 'services/project';
import { TYPE_UNASSIGNED, TYPE_REGULAR } from 'enums/resourceEnum';
import { validateCodeRequest } from 'api/project';
import { removeUTCZuluFromDateTimestamp } from '../utils/DateUtil';
import { store } from '../store';

const milestoneFormSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        date: {
            type: Date,
            required: true,
        },
        interval: {
            type: String,
            required: true,
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
    },
    errorMessages,
    false
);

const phaseFormSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        start: {
            type: Date,
            required: true,
            validators: [startDateValidator('end')],
            validatorInterdependent: true,
        },
        end: {
            type: Date,
            required: true,
        },
        interval: {
            type: String,
            required: true,
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
    },
    errorMessages,
    false
);

const budgetCategorySchema = new Schema({
    budgetCashAmount: {
        type: Number,
        required: true,
    },
    budgetCurrency: {
        type: Object,
        required: true,
    },
    budgetHours: {
        type: Number,
        required: true,
        defaultValue: 0,
    },
    category: {
        type: Object,
        required: true,
        defaultValue: 0,
    },
});

const fixedCostSchema = new Schema({
    costCategory: {
        type: Object,
        defaultValue: 0,
        validators: [requiredWithCustomErrorMessage({ errorMessage: `Field 'category' is required` })],
    },
    currency: {
        type: Object,
        required: true,
    },
    scheduled: {
        type: Number,
        required: true,
    },
    actual: {
        type: Number,
        required: true,
    },
    deductFromProjectBudget: {
        type: Boolean,
    },
});

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        projectCode: {
            type: String,
            required: false,
        },
        thumb: {
            type: String,
            required: false,
            defaultValue: '/img/placeholder.png',
        },
        customers: {
            type: Array,
            required: false,
        },
        tags: {
            type: Array,
            required: false,
        },
        projectManagers: {
            type: Array,
            required: false,
        },
        status: {
            type: Object,
            required: true,
            defaultValue: STATUS_ACTIVE,
        },
        backgroundColor: {
            type: String,
            required: true,
        },
        categoryGroups: {
            type: Array,
            required: true,
        },
        defaultCategoryTemplate: {
            type: Object,
            required: true,
        },
        resources: {
            type: Array,
            required: false,
        },
        unassignedWork: {
            type: Array,
            required: false,
        },
        projectGroups: {
            type: Array,
            required: false,
        },
        milestones: {
            type: [milestoneFormSchema],
            required: false,
            defaultValue: [],
            validators: [arrayFieldRequireValidator('milestones')],
        },
        phases: {
            type: [phaseFormSchema],
            required: false,
            defaultValue: [],
            validators: [
                arrayFieldRequireValidator(
                    'phases',
                    "All Fields are required and every start date can't be greater than end date"
                ),
            ],
        },
        timeEntryEnabled: {
            type: Boolean,
            required: false,
            defaultValue: true,
        },
        setStartEndDates: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        startDate: {
            type: Date,
            required: false,
            validators: [startDateValidator('endDate'), dynamicRequireValidator('setStartEndDates', true, 'startDate')],
            defaultValue: null,
        },
        endDate: {
            type: Date,
            required: false,
            validators: [dynamicRequireValidator('setStartEndDates', true, 'endDate')],
            defaultValue: null,
        },
        notesTimeSheet: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        timeEntryApproval: {
            type: Boolean,
            required: false,
        },
        timeEntryLocked: {
            type: Boolean,
            required: false,
        },
        availabilityReports: {
            type: Boolean,
            required: false,
            defaultValue: true,
        },
        availabilityScheduler: {
            type: Boolean,
            required: false,
            defaultValue: true,
        },
        private: {
            type: Boolean,
            required: false,
            defaultValue: true,
        },
        currency: {
            type: Object,
            required: true,
        },
        budgetCategories: {
            type: [budgetCategorySchema],
            required: false,
        },
        fixedCosts: {
            type: [fixedCostSchema],
            required: false,
        },
        budgetHours: {
            type: Number,
            required: false,
            validators: [numberValidator, minValidator(0)],
            defaultValue: 0,
        },
        budgetCashAmount: {
            type: Number,
            required: false,
            validators: [numberValidator, minValidator(0)],
            defaultValue: 0,
        },
        projectRates: {
            type: Object,
            required: false,
            defaultValue: {
                external: { customRates: [], defaultRateId: '' },
                internal: { customRates: [], defaultRateId: '' },
            },
        },
        note: {
            type: Object,
            required: false,
            defaultValue: { message: '', formattedNote: '' },
        },
        nonBillable: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        useStatusColor: {
            type: Boolean,
            required: false,
            defaultValue: true,
        },
        ...linksAndIconsFields,
    },
    errorMessages,
    false
);

const getCustomResourceRatesDefaultValues = ({ ...projectRates }, resources, billingRates) =>
    mapObject(projectRates, projectRate => ({
        defaultRateId: find(billingRates, billingRate => projectRate.defaultRateId === billingRate._id),
        customRates: map(projectRate.customRates, customRate => {
            const resource = find(resources, resource => customRate.resourceId === resource._id);
            const rate = find(billingRates, billingRate => customRate.id === billingRate._id);

            return {
                resource: resource ? resource : { _id: customRate.resourceId },
                rate,
                uuid: `${customRate.id}_${customRate.resourceId}`,
            };
        }),
    }));

export const getDefaultValues = (
    companyTreeSettings,
    project,
    resources,
    projectManagers,
    projectGroups,
    categoryGroups,
    categoryTemplates,
    costsCategoriesMap,
    isApprovalExtensionInstalled
) => {
    if (!project._id) {
        const defaultCategoryGroup = find(categoryGroups, categoryGroup => DEFAULT === categoryGroup.type);
        const defaultCategoryTemplate =
            defaultCategoryGroup &&
            find(
                categoryTemplates,
                categoryTemplate =>
                    DEFAULT === categoryTemplate.type &&
                    contains(defaultCategoryGroup.categoryTemplates, categoryTemplate._id)
            );

        return {
            ...formSchema.getDefaultValues(),
            timeEntryApproval: isApprovalExtensionInstalled,
            backgroundColor: companyTreeSettings.projectStatusColors[STATUS_ACTIVE.value],
            currency: findCurrency(companyTreeSettings.defaultCurrency),
            categoryGroups: defaultCategoryGroup ? [defaultCategoryGroup] : [],
            defaultCategoryTemplate: defaultCategoryTemplate || {},
            budgetCategories: [
                {
                    category:
                        categoryTemplates.find(
                            categoryTemplate => defaultCategoryTemplate?._id === categoryTemplate._id
                        ) || {},
                    budgetCurrency: findCurrency(companyTreeSettings.defaultCurrency),
                    budgetCashAmount: 0,
                    budgetHours: 0,
                },
            ],
            fixedCosts: [],
        };
    }

    if (project && project.status && project.useStatusColor) {
        const projectStatusColor = store.getState().companyReducer?.company?.settings?.projectStatusColors || {};
        project.backgroundColor = projectStatusColor[project.status.toLowerCase()];
    }

    const billingRates = companyTreeSettings.billingRates;
    const {
        projectRate,
        formattedNote,
        note,
        projectManagers: projectManagerIds,
        tags,
        customers,
        billable,
        timeEntryApproval,
        budgetCategories,
        fixedCosts,
        ...rest
    } = project;

    return {
        ...formSchema.getDefaultValues(),
        ...rest,
        // This can come as null
        timeEntryApproval: !!timeEntryApproval,
        nonBillable: !billable,
        resources: filter(
            resources,
            resource => TYPE_REGULAR.value === resource.type && contains(project.resources, resource._id)
        ),
        unassignedWork: filter(
            resources,
            resource => TYPE_UNASSIGNED.value === resource.type && contains(project.resources, resource._id)
        ),
        currency: findCurrency(project.budgetCurrency),
        projectGroups: filter(projectGroups, projectGroup => contains(projectGroup.projects, project._id)),
        projectRates: getCustomResourceRatesDefaultValues(projectRate, resources, billingRates),
        customFields: getMyCustomFieldsDefaultValues(project.customFields),
        status: getProjectStatus(project.status),
        categoryGroups: filter(categoryGroups, categoryGroup => contains(project.categoryGroups, categoryGroup._id)),
        defaultCategoryTemplate:
            find(categoryTemplates, categoryTemplate => project.defaultCategoryTemplate === categoryTemplate._id) || {},
        availabilityScheduler: project.includeBookedTimeGrid,
        availabilityReports: project.includeBookedTimeReports,
        setStartEndDates: !!(project.start && project.end),
        startDate: project.start
            ? new Date(removeUTCZuluFromDateTimestamp(project.start))
            : formSchema.getDefaultValues().startDate,
        endDate: project.end
            ? new Date(removeUTCZuluFromDateTimestamp(project.end))
            : formSchema.getDefaultValues().endDate,
        milestones: mapMilestonesToFE(project.milestones),
        phases: mapPhasesToFE(project.phases),
        notesTimeSheet: rest.timeEntryNoteRequired,
        tags: map(tags, tag => ({ _id: tag.tagId, value: tag.value })),
        projectManagers: filter(projectManagers, pm => contains(projectManagerIds, pm._id)),
        note: {
            message: note,
            formattedNote: formattedNote?.indexOf('{') === 0 ? JSON.parse(formattedNote) : formattedNote,
        },
        customers: map(customers, customer => ({ _id: customer.customerId, name: customer.name })),
        ...project.links,
        budgetCategories: budgetCategories?.length
            ? budgetCategories.map(bc => {
                  const category = categoryTemplates.find(
                      categoryTemplate => bc.categoryId === categoryTemplate._id
                  ) || { name: 'Category not Available', _id: bc.categoryId };
                  return {
                      category,
                      budgetCurrency: findCurrency(bc.budgetCurrency),
                      budgetCashAmount: bc.budgetCashAmount,
                      budgetHours: bc.budgetHours,
                  };
              })
            : [
                  {
                      category:
                          categoryTemplates.find(
                              categoryTemplate => project.defaultCategoryTemplate === categoryTemplate._id
                          ) || {},
                      budgetCurrency: findCurrency(companyTreeSettings.defaultCurrency),
                      budgetCashAmount: 0,
                      budgetHours: 0,
                  },
              ],
        fixedCosts:
            fixedCosts?.map(fc => {
                return {
                    costCategory: costsCategoriesMap[fc.costCategoryId] || {},
                    currency: findCurrency(fc.currency),
                    scheduled: fc.scheduled,
                    actual: fc.actual,
                    deductFromProjectBudget: fc.deductFromProjectBudget,
                };
            }) || [],
    };
};

function mapProjectRatesToBackend(projectRates) {
    let mappedProjectRates = {};
    each(projectRates, (item, type) => {
        const customRates = chain(item.customRates)
            .filter(rates => keys(rates.resource).length && keys(rates.rate).length)
            .map(customRate => ({
                resourceId: customRate.resource._id,
                id: customRate.rate._id,
            }))
            .value();

        mappedProjectRates[type] = {
            customRates,
            defaultRateId: item.defaultRateId ? item.defaultRateId._id : null,
        };
    });
    return mappedProjectRates;
}

export const mapFormToRequest = ({
    projectId,
    values,
    project,
    customFieldTemplates,
    startEndTimes,
    projectType,
    initialProjectGroups,
}) => {
    const tags = values.tags && map(values.tags, tag => ({ tagId: tag._id, value: tag.value }));
    const resources =
        values.resources || values.unassignedWork
            ? filter(map((values.resources || []).concat(values.unassignedWork), '_id'), res => !isNil(res))
            : undefined;
    const projectGroups = values.projectGroups && map(values.projectGroups, '_id');
    const customers =
        values.customers &&
        map(values.customers, customer => ({
            customerId: customer._id,
            name: customer.name,
        }));
    const categoryGroups = values.categoryGroups && map(values.categoryGroups, '_id');
    const defaultCategoryTemplate = values.defaultCategoryTemplate?._id;
    const projectGroupsAdd = projectGroups && difference(projectGroups, initialProjectGroups);
    const projectGroupsRemove = projectGroups && difference(initialProjectGroups, projectGroups);

    return {
        project: {
            name: values.name,
            projectCode: values.projectCode,
            type: projectType,
            status: values.status?.value,
            note: values.note?.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' '),
            formattedNote:
                typeof values.note?.formattedNote === 'object'
                    ? JSON.stringify(values.note?.formattedNote)
                    : values.note?.formattedNote,
            backgroundColor: values.backgroundColor,
            budgetCashAmount: values.budgetCashAmount,
            budgetCurrency: values.currency?.value,
            budgetHours: values.budgetHours,
            projectRate: values.projectRates && mapProjectRatesToBackend(values.projectRates),
            projectManagers: values.projectManagers,
            timeEntryApproval: values.timeEntryApproval,
            timeEntryEnabled: values.timeEntryEnabled,
            timeEntryLocked: values.timeEntryLocked,
            timeEntryNoteRequired: values.notesTimeSheet,
            includeBookedTimeGrid: values.availabilityScheduler,
            includeBookedTimeReports: values.availabilityReports,
            private: values.private,
            phases: values.phases ? mapPhasesToBE(values.phases, project?.phases, startEndTimes) : undefined,
            milestones: values.milestones
                ? mapMilestonesToBE(values.milestones, project?.milestones, projectId)
                : undefined,
            useProjectDuration: values.setStartEndDates,
            start: !values.hasOwnProperty('setStartEndDates')
                ? undefined
                : values.setStartEndDates
                ? format(values.startDate, 'yyyy-MM-dd')
                : null,
            end: !values.hasOwnProperty('setStartEndDates')
                ? undefined
                : values.setStartEndDates
                ? format(values.endDate, 'yyyy-MM-dd')
                : null,
            customers,
            customFields: values.customFields && mapCustomFields(values.customFields, customFieldTemplates),
            tags,
            thumb: values.thumb,
            resources,
            categoryGroups,
            defaultCategoryTemplate,
            billable: isNil(values.nonBillable) ? undefined : !values.nonBillable,
            useStatusColor: values.useStatusColor,
            links: Object.keys(values).some(field => field.toLowerCase().indexOf('link') !== -1)
                ? {
                      iconLink1: values.iconLink1,
                      iconLink2: values.iconLink2,
                      iconLink3: values.iconLink3,
                      iconLink4: values.iconLink4,
                      iconLink5: values.iconLink5,
                      link1: values.link1,
                      link2: values.link2,
                      link3: values.link3,
                      link4: values.link4,
                      link5: values.link5,
                  }
                : undefined,
            budgetCategories: values.budgetCategories
                ?.filter(bc => bc.category)
                .map(bc => {
                    return {
                        categoryId: bc.category._id,
                        budgetCurrency: bc.budgetCurrency.value,
                        budgetCashAmount: bc.budgetCashAmount,
                        budgetHours: bc.budgetHours,
                    };
                }),
            fixedCosts: values.fixedCosts
                ?.filter(fc => fc.costCategory)
                ?.map(fc => {
                    return {
                        costCategoryId: fc.costCategory._id,
                        currency: fc.currency.value,
                        scheduled: fc.scheduled,
                        actual: fc.actual,
                        deductFromProjectBudget: fc.deductFromProjectBudget,
                    };
                }),
        },
        projectGroupsAdd,
        projectGroupsRemove,
    };
};

export const asyncValidate = ({ projectCode }, dispatch, props) =>
    validateCodeRequest({ projectCode }).then(data => {
        if (
            (0 < data.length && !props.projectId) ||
            (0 < data.length && props.projectId && every(data, item => item._id !== props.projectId))
        ) {
            const errObj = { projectCode: 'Project Code is not unique' };
            throw errObj;
        }
    });
