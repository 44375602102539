import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Icon } from './styles';
import { makeStyles } from '@material-ui/core/styles';

const useTooltipStyles = makeStyles({
    tooltip: props => ({
        fontSize: 12,
        padding: !props.removePadding ? 10 : undefined,
    }),
});

const InfoHelpTooltip = ({ description, children, useChildren, removePadding, dataCy, ...props }) => {
    const tooltipClasses = useTooltipStyles({ removePadding });

    if (!description) {
        return useChildren && children ? (
            children
        ) : (
            <Icon data-cy={dataCy}>
                <Info />
            </Icon>
        );
    }

    return (
        <Tooltip placement="top" title={description} arrow classes={tooltipClasses} {...props}>
            {useChildren && children ? (
                children
            ) : (
                <Icon data-cy={dataCy}>
                    <Info />
                </Icon>
            )}
        </Tooltip>
    );
};

InfoHelpTooltip.propTypes = {
    description: PropTypes.string.isRequired,
    useChildren: PropTypes.bool,
    removePadding: PropTypes.bool,
    dataCy: PropTypes.string,
};

InfoHelpTooltip.defaultProps = {
    useChildren: false,
    removePadding: false,
};

export default InfoHelpTooltip;
