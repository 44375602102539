import * as types from 'actions/actionTypes';
import uuid from 'uuid/v1';
import { fromJS } from 'immutable';
import { assign } from 'underscore';

const DEFAULT_TIMEOUT = 5000;
const DEFAULT_MESSAGE = 'Something went wrong...';

const handleNotification = (notifications, notification) => {
    if (notification !== undefined && !notification.hasOwnProperty('timeout')) {
        notification.timeout = DEFAULT_TIMEOUT;
    }

    if (notification && !notification.id) {
        return assign(notification, { id: uuid() });
    }

    return notification;
};

export default function(state = fromJS({ notifications: [] }), action) {
    let notification;
    switch (action.type) {
        case types.ADD_NOTIFICATION:
            if (!action.payload.notification.message) {
                action.payload.notification.message = DEFAULT_MESSAGE;
            }

            notification = state
                .get('notifications')
                .find(notification => notification.get('message') === action.payload.notification.message);

            if (!notification) {
                return state.update('notifications', notifications =>
                    notifications.push(fromJS(handleNotification(notifications.toJSON(), action.payload.notification)))
                );
            }

            return state.update(
                'notifications',
                notifications => notifications.findIndex(item => item.get('id') === notification.id),
                item => item.set('timeout', DEFAULT_TIMEOUT)
            );
        case types.REMOVE_NOTIFICATION:
            return state.update('notifications', notifications =>
                notifications.filter(item => item.get('id') !== action.payload.id)
            );
        default:
            return state;
    }
}
