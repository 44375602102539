import React from 'react';
import { contains } from 'underscore';
import DateFilterBox from 'modules/report/containers/tableFilters/dateFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    dateTypesHandler,
    differentTypesHandler,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import { Header } from './../styles';

/**
 * @returns {*[]}
 */
export const columns = columnsToLoad => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Customer
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectCustomer',
        sortable: false,
        accessor: row => displayValue(row.projectCustomer, differentTypesHandler(row.projectCustomer, 'Customers')),
        show: contains(columnsToLoad, 'projectCustomer'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event Tag
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectTag',
        sortable: false,
        accessor: row => displayValue(row.projectTag, differentTypesHandler(row.projectTag, 'Tags')),
        show: contains(columnsToLoad, 'projectTag'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Status
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectStatus',
        sortable: false,
        accessor: row => displayValue(row.projectStatus, differentTypesHandler(row.projectStatus, 'Statuses')),
        show: contains(columnsToLoad, 'projectStatus'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Manager
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectManager',
        sortable: false,
        accessor: row =>
            displayValue(row.projectManager, differentTypesHandler(row.projectManager, 'Project Managers')),
        show: contains(columnsToLoad, 'projectManager'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event End Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectEndDate',
        sortable: false,
        accessor: row => displayValue(row.projectEndDate, dateTypesHandler(row.projectEndDate, 'YYYY-MM-DD')),
        show: contains(columnsToLoad, 'projectEndDate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event start date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectStartDate',
        sortable: false,
        accessor: row => displayValue(row.projectStartDate, dateTypesHandler(row.projectStartDate, 'YYYY-MM-DD')),
        show: contains(columnsToLoad, 'projectStartDate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectNote',
        sortable: false,
        accessor: row => displayValue(row.projectNote, differentTypesHandler(row.projectNote, 'notes')),
        show: contains(columnsToLoad, 'projectNote'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event Code
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectCode',
        sortable: false,
        accessor: row => displayValue(row.projectCode, differentTypesHandler(row.projectCode, 'Codes')),
        show: contains(columnsToLoad, 'projectCode'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event Groups
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectGroups',
        sortable: false,
        accessor: row => displayValue(row.projectGroups, differentTypesHandler(row.projectGroups, 'Groups')),
        show: contains(columnsToLoad, 'projectGroups'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectName',
        sortable: false,
        accessor: row => displayValue(row.projectName, differentTypesHandler(row.projectName, 'Projects')),
        show: contains(columnsToLoad, 'projectName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project/Event Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectEventName',
        sortable: false,
        accessor: row =>
            displayValue(row.projectEventName, differentTypesHandler(row.projectEventName, 'Projects/Events')),
        show: contains(columnsToLoad, 'projectEventName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Milestone Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projMilestoneName',
        sortable: false,
        accessor: row =>
            displayValue(row.projMilestoneName, differentTypesHandler(row.projMilestoneName, 'Project Milestone Names')),
        show: contains(columnsToLoad, 'projMilestoneName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Milestone Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projMilestoneDate',
        sortable: false,
        accessor: row => displayValue(row.projMilestoneDate, dateTypesHandler(row.projMilestoneDate, 'YYYY-MM-DD')),
        show: contains(columnsToLoad, 'projMilestoneDate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Phase Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projPhaseName',
        sortable: false,
        accessor: row =>
            displayValue(row.projPhaseName, differentTypesHandler(row.projPhaseName, 'Project Phase Names')),
        show: contains(columnsToLoad, 'projPhaseName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Phase Start Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projPhaseStartDate',
        sortable: false,
        accessor: row => displayValue(row.projPhaseStartDate, dateTypesHandler(row.projPhaseStartDate, 'YYYY-MM-DD')),
        show: contains(columnsToLoad, 'projPhaseStartDate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Phase End Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projPhaseEndDate',
        sortable: false,
        accessor: row => displayValue(row.projPhaseEndDate, dateTypesHandler(row.projPhaseEndDate, 'YYYY-MM-DD')),
        show: contains(columnsToLoad, 'projPhaseEndDate'),
    }
];
