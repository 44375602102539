import CreateTag from './createTag';
import { connect } from 'react-redux';
import { submit, isValid } from 'redux-form';
import { createTag } from 'actions/tagActions';
import { formSchema } from 'forms/tagForm';

export default connect(
    state => ({
        valid: isValid('tagForm')(state),
        initialValues: {
            ...formSchema.getDefaultValues(),
        },
    }),
    (dispatch, ownProps) => ({
        onSubmit: data =>
            dispatch(
                createTag.request(
                    {
                        category: ownProps.category,
                        value: data.name,
                    },
                    ownProps.category.toLowerCase(),
                    ownProps.formName,
                    ownProps.fieldName
                )
            ),
        submitForm: () => dispatch(submit('tagForm')),
    })
)(CreateTag);
