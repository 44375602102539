import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { StatPods, RequestTable, BarChart, Header } from 'modules/request/components';
import { useRequestData } from 'modules/request/hooks';
import { getBookingType } from 'enums/bookingTypeEnum';
import { filterRequestTable } from 'utils/filterUtil';
import { isRequestGrid } from 'modules/request/utils/dataFlowUtil';
import { makeResourceByIdFromMap } from '../../../../selectors/resource';
import { useSelector } from 'react-redux';
import { TeamOverview } from '../../components/teamOverview';
import {selectCompanyAvgDailyCapacity} from '../../../../selectors/company';

const TeamResourceRequests = ({ location }) => {
    const {
        deleteBooking,
        requestModel,
        companySettings,
        filteredBookings,
        textFilter,
        statistics,
        tableActive,
        resourceId,
        editRequest,
        approveOrRejectRequest,
        handleApproveOrRejectModal,
        reSubmitRequest,
    } = useRequestData('APPROVAL_FLOW', location);

    const statisticsJS = useMemo(() => {
        return statistics.toJS();
    }, [statistics]);

    const resourceSelector = useMemo(() => makeResourceByIdFromMap(requestModel?.resourceId), [
        requestModel?.resourceId,
    ]);
    const resource = useSelector(resourceSelector);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);

    return (
        <>
            <Header />
            <StatPods
                filter={requestModel.filter}
                resourceId={requestModel.resourceId}
                toilPolicyId={resource?.toilPolicyId}
                flow={requestModel.flow}
                statistics={statisticsJS}
            />
            {!tableActive && 'REQUEST_GRID' !== requestModel.flow && (
                <Row className="mt-3">
                    <Col md={12}>
                        <BarChart
                            title={`${getBookingType(requestModel.filter)} Requests`}
                            weekStartDay={companySettings.weekStartDay}
                            requestModel={requestModel}
                            statistics={statistics}
                            companyAvgDailyCapacity={companyAvgDailyCapacity}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col md={12} className="pt-4">
                    {!isRequestGrid(requestModel.flow) ? (
                        <RequestTable
                            key={requestModel.resourceId ?? 'all'}
                            requestModel={requestModel}
                            deleteRequest={deleteBooking}
                            approveOrRejectModal={handleApproveOrRejectModal}
                            approveOrRejectRequest={approveOrRejectRequest}
                            reSubmitRequest={reSubmitRequest}
                            bookings={filterRequestTable(filteredBookings, textFilter)}
                            editRequest={editRequest}
                            resourceId={resourceId}
                            avgDailyCapacity={companySettings.report.avgDailyCapacity}
                        />
                    ) : (
                        <TeamOverview
                            start={requestModel.start}
                            end={requestModel.end}
                            flow={requestModel.flow}
                            data={statistics.get('resources')}
                            dataLoaded={statistics.get('resourcesLoaded')}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default TeamResourceRequests;
