import React from 'react';
import PropTypes from 'prop-types';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import BookingRepeat from './repeat';
import Notes from './notes';
import Tasks from './tasks';
import Approval from './approval';
import Private from './private';
import DependencyIcon from 'assets/images/icons/multiple_stop.svg';
import { makeStyles } from '@material-ui/styles';
import { RequestedLabel } from '../styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AlarmIcon from '@material-ui/icons/Alarm';

const LargeMode = props => {
    const {
        booking,
        loggedInRights,
        totalEventTime,
        mode,
        isDependencyExtensionInstalled,
        isDeadlinesExtensionInstalled,
        eventHeightMode,
        isAbleToEditRequest,
        renderMenu,
    } = props;

    const displayLinkedIcon = isDependencyExtensionInstalled && (booking.childIds?.length || booking.parentIds?.length);
    const displayDeadlineIcon = isDeadlinesExtensionInstalled && booking.deadlineDate;
    const isApproval = booking.type === WAITING_FOR_APPROVAL.value;
    const classes = useStyles({ isApproval, displayLinkedIcon });

    let type;
    if (booking?.project?.type === 'REGULAR') {
        type = 'project';
    } else if (booking?.project?.type === 'EVENT') {
        if (booking?.project?.name === 'Vacation') {
            type = 'vacation';
        } else {
            type = 'event';
        }
    }

    return (
        <>
            {renderMenu ? <i className="fa fa-ellipsis-v booking-option-dots" title="Options" /> : null}
            <span className="inner-booking-text">
                {isApproval && !isAbleToEditRequest && (
                    <RequestedLabel mode={eventHeightMode}>
                        <LockOutlinedIcon data-cy={`scheduler-[${type}]-request-lock`} className={classes.icon} />
                    </RequestedLabel>
                )}
                {displayLinkedIcon ? (
                    <RequestedLabel mode={eventHeightMode}>
                        <DependencyIcon data-cy={`scheduler-[${type}]-dependencies`} className={classes.icon} />
                    </RequestedLabel>
                ) : null}
                {displayDeadlineIcon && !booking.private ? (
                    <RequestedLabel mode={eventHeightMode}>
                        <AlarmIcon data-cy={`scheduler-[${type}]-deadline`} className={classes.icon} />
                    </RequestedLabel>
                ) : null}
                <BookingRepeat isRepeat={booking.repeat && !booking.private} mode={eventHeightMode} />
                <Tasks booking={booking} loggedInRights={loggedInRights} mode={eventHeightMode} />
                <Notes booking={booking} viewNotes={loggedInRights.viewNotes} mode={eventHeightMode} />
                <div className="booking-time-name">
                    <div className="d-flex align-items-center">
                        {booking.type === WAITING_FOR_APPROVAL.value && (
                            <Approval booking={booking} approvalInfo={booking.approvalInfo} mode={eventHeightMode} />
                        )}
                        {mode === PARENT.value && TYPE_UNASSIGNED.value !== booking.resourceInfo.type ? (
                            totalEventTime
                        ) : (
                            <Private booking={booking} mode={eventHeightMode} displayLinkedIcon={displayLinkedIcon} />
                        )}
                    </div>
                    <div className="inner-booking-text-large">{booking.tags.renderedTextBottom}</div>
                </div>
            </span>
        </>
    );
};

const useStyles = makeStyles(() => ({
    icon: {
        fill: '#232323',
        width: 16,
        height: 16,
    },
}));

LargeMode.propTypes = {
    booking: PropTypes.object.isRequired,
    loggedInRights: PropTypes.object.isRequired,
    totalEventTime: PropTypes.string.isRequired,
    eventHeightMode: PropTypes.string.isRequired,
    mode: PropTypes.string.isRequired,
    isDependencyExtensionInstalled: PropTypes.bool,
};

export default LargeMode;
