import { extend } from 'underscore';
import * as actionTypes from '../actions/actionTypes';
import { updateObject, updateItemInArray } from './helpers/updater';
import createReducer from './helpers/createReducer';
import { getAllocationDisplayValue } from 'utils/allocationUtil';

const initialCategoryTemplateState = {
    categoryTemplates: [],
    loading: false
};

const setAllocationText = categoryTemplates =>
    categoryTemplates.map(category => ({
        ...category,
        allocationText: getAllocationDisplayValue(category),
    }));

const getCategoryTemplates = (state, action) =>
    updateObject(state, {
        categoryTemplates: action.payload.withAllocationText
            ? setAllocationText(action.payload.categoryTemplates)
            : action.payload.categoryTemplates,
        loading: false,
    });

const handleGetCategoryTemplates = (state) => {
    return updateObject(state, {loading: true})
}

const handleCategoryTemplatesStopLoading = (state) => {
    return updateObject(state, {loading: false})
}

const onDeleteCategoryTemplateSuccess = (state, action) =>
    updateObject(state, {
        categoryTemplates: state.categoryTemplates.filter(
            category => category._id !== action.payload.categoryTemplate._id
        ),
    });

const onCreateCategoryTemplateSuccess = (state, action) =>
    updateObject(state, {
        categoryTemplates: action.payload.withAllocationText
            ? setAllocationText([...state.categoryTemplates, action.payload.categoryTemplate])
            : [...state.categoryTemplates, action.payload.categoryTemplate],
    });

const onUpdateCategoryTemplateSuccess = (state, action) => {
    const updatedCategories = updateItemInArray(
        state.categoryTemplates,
        action.payload.categoryTemplate._id,
        category => updateObject(category, action.payload.categoryTemplate)
    );
    return updateObject(state, {
        categoryTemplates: action.payload.withAllocationText ? setAllocationText(updatedCategories) : updatedCategories,
    });
};

export default createReducer(extend({}, initialCategoryTemplateState), {
    [actionTypes.GET_CATEGORY_TEMPLATES['REQUEST']]: handleGetCategoryTemplates,
    [actionTypes.GET_CATEGORY_TEMPLATES['SUCCESS']]: getCategoryTemplates,
    [actionTypes.GET_CATEGORY_TEMPLATES['FAILURE']]: handleCategoryTemplatesStopLoading,
    [actionTypes.GET_CATEGORY_TEMPLATES_STOP]: handleCategoryTemplatesStopLoading,
    [actionTypes.DELETE_CATEGORY_TEMPLATE['SUCCESS']]: onDeleteCategoryTemplateSuccess,
    [actionTypes.CREATE_CATEGORY_TEMPLATE['SUCCESS']]: onCreateCategoryTemplateSuccess,
    [actionTypes.UPDATE_CATEGORY_TEMPLATE['SUCCESS']]: onUpdateCategoryTemplateSuccess,
});
