import React, { PureComponent, useMemo } from 'react';
import PropTypes from 'prop-types';
import { VacationStats } from '../../../modals/resourceModal/components/vacationStats';
import { InfoTile, Layout } from '@hub-mono/ui';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectCompanyAvgDailyCapacity, selectIsToilExtensionInstalled } from '../../../../selectors/company';
import { toFixedTrimmed } from '@hub-mono/utils';
import { useAppKeyWords } from '../../../../hooks';

const InfoTilesWrapper = styled(Layout)`
    & > * {
        flex: 1 1 0;
        width: 0;
    }
`;

class StatPods extends PureComponent {
    static propTypes = {
        filter: PropTypes.string.isRequired,
        flow: PropTypes.string.isRequired,
        resourceId: PropTypes.string.isRequired,
        toilPolicyId: PropTypes.string,
        onFilter: PropTypes.func,
        statistics: PropTypes.object,
    };

    static defaultProps = {
        statistics: null,
    };

    render() {
        const { resourceId, toilPolicyId, statistics, filter, flow } = this.props;

        if (['MY_VACATION_REQUESTS', 'VACATION_FLOW_SINGLE'].includes(flow) && resourceId) {
            return <VacationStats statistics={statistics} resourceId={resourceId} toilPolicyId={toilPolicyId} />;
        }

        return <RequestStats toilPolicyId={toilPolicyId} statistics={statistics} filter={filter} flow={flow} />;
    }
}

const RequestStats = ({ statistics, filter, flow }) => {
    const { vacationEventName: defaultVacationName } = useAppKeyWords();
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);

    const isVacation = ['MY_VACATION_REQUESTS', 'VACATION_FLOW_SINGLE', 'VACATION_FLOW', 'VACATION_GRID'].includes(flow);
    const shouldCountToil = isToilExtensionInstalled;
    // TODO: for now we don't display toil in info box
    const shouldDisplayToil = isVacation && isToilExtensionInstalled && false;

    const { totalRequested, totalApproved } = useMemo(() => {
        const totalRequested = shouldCountToil
            ? statistics.total.WAITING_FOR_APPROVAL +
              (statistics.toil?.total?.WAITING_FOR_APPROVAL ?? 0) / companyAvgDailyCapacity
            : statistics.total.WAITING_FOR_APPROVAL;
        const totalApproved = shouldCountToil
            ? statistics.total.APPROVED + (statistics.toil?.total?.APPROVED ?? 0) / companyAvgDailyCapacity
            : statistics.total.APPROVED;

        const returnValue = { totalRequested, totalApproved };
        return Object.keys(returnValue).reduce((acc, key) => {
            const value = returnValue[key];
            acc[key] = value === undefined || Number.isNaN(value) ? '-' : toFixedTrimmed(value, 2);
            return acc;
        }, {});
    }, [
        companyAvgDailyCapacity,
        shouldCountToil,
        statistics.toil?.total?.APPROVED,
        statistics.toil?.total?.WAITING_FOR_APPROVAL,
        statistics.total.APPROVED,
        statistics.total.WAITING_FOR_APPROVAL,
    ]);

    return (
        <InfoTilesWrapper gap={16}>
            <InfoTile
                dataCy="requested-vacations"
                iconName="pendingActions"
                title={isVacation ? `Requested ${defaultVacationName}` : `Requested`}
                value={totalRequested}
                unit="days"
                tileVariant={filter === 'WAITING_FOR_APPROVAL' ? 'secondary' : 'primary'}
                additionalInfo={
                    shouldDisplayToil
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: statistics.total.WAITING_FOR_APPROVAL,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: statistics.toil?.total?.WAITING_FOR_APPROVAL ?? '0',
                                  unit: 'hours',
                              },
                          }
                        : undefined
                }
            />
            <InfoTile
                iconName="hourglassBottom"
                dataCy="approved-vacations"
                title={isVacation ? `Consumed ${defaultVacationName}` : `Consumed`}
                value={totalApproved}
                unit="days"
                tileVariant={filter === 'APPROVED' ? 'secondary' : 'primary'}
                additionalInfo={
                    shouldDisplayToil
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: statistics.total.APPROVED,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: statistics.toil?.total?.APPROVED ?? '0',
                                  unit: 'hours',
                              },
                          }
                        : undefined
                }
            />
        </InfoTilesWrapper>
    );
};

export default StatPods;
