export default menuRowContextMenu => args => {
    if (!args.row.data.tags.menuRow) {
        return;
    }
    const coords = {
        left: args.originalEvent.clientX,
        top: args.originalEvent.clientY,
    };

    if ('resource' === args.row.data.tags.menuRowSelector) {
        menuRowContextMenu(coords, args.row.data.tags.menuRowSelector, {
            resourceGroup: args.row.data.tags.resourceGroup,
            project: args.row.data.tags.project,
        });
    }
    if ('project' === args.row.data.tags.menuRowSelector) {
        menuRowContextMenu(coords, args.row.data.tags.menuRowSelector, {
            resource: args.row.data.tags.resource,
            projectGroup: args.row.data.tags.projectGroup,
        });
    }
};
