import { updateItemInArray, updateObject } from 'reducers/helpers/updater';
import { filter, uniq, without, find, uniqBy, isNil } from 'lodash';
import { isConditionFilterSupported } from 'modules/scheduler/enums/schedulerFilterEnum';
import { updateAvailabilities, updateOverrides } from './resources';

const knownSmartFiltersKeys = ['availability', 'utilization'];
const knownProjectDateFiltersKeys = ['project-start-date', 'project-end-date'];
const knownResourceIsPM = ['resourceIsPm'];

export const getFiltersWithoutRemovedFilter = (filters, givenFilter) => {
    // Handle removing smart filters
    if (knownSmartFiltersKeys.includes(givenFilter.name)) {
        if (filters.smartFilters?.filters[givenFilter.name]) {
            const newSmartFilters = Object.assign({}, filters.smartFilters?.filters || {});
            newSmartFilters[givenFilter.name] = { operator: '', value: '' };
            const newUpdatedFilters = {
                ...filters,
                smartFilters: {
                    ...(filters?.smartFilters || {}),
                    filters: newSmartFilters,
                },
            };
            return newUpdatedFilters;
        }
        return filters;
    }

    // Handle removing project dates filters
    if (knownProjectDateFiltersKeys.includes(givenFilter.name)) {
        const keyToClear = givenFilter.name === 'project-start-date' ? 'start' : 'end';
        return {
            ...filters,
            projectDates: {
                ...filters.projectDates,
                [keyToClear]: { operator: '', date: null },
            },
        };
    }

    if (knownResourceIsPM.includes(givenFilter.name)) {
        return {
            ...filters,
            resourceIsPm: undefined,
        };
    }

    const filtersToUpdate = filters[givenFilter.name].filters;
    const operator = filters[givenFilter.name].operator;
    let updatedFilters;

    if (givenFilter.parentValue) {
        const appliedFilter = find(filtersToUpdate, _filter => givenFilter.parentValue === _filter.id);

        //more then one value
        if (appliedFilter && 1 < appliedFilter.values.length) {
            updatedFilters = updateObject(filters, {
                [givenFilter.name]: {
                    filters: updateItemInArray(
                        filtersToUpdate,
                        givenFilter.parentValue,
                        item =>
                            updateObject(item, {
                                values: filter(item.values, items => items.value !== givenFilter.value),
                            }),
                        'id'
                    ),
                    operator,
                },
            });
        } else {
            const filterParentIndex = filtersToUpdate.findIndex(parent => givenFilter.parentValue === parent.id);
            const updatedFilterAtParentIndex = {
                ...filtersToUpdate[filterParentIndex],
                values: filtersToUpdate[filterParentIndex].values.filter(
                    _filter => givenFilter.value !== _filter.value
                ),
            };
            filtersToUpdate[filterParentIndex] = updatedFilterAtParentIndex;

            updatedFilters = updateObject(filters, {
                [givenFilter.name]: {
                    ...filters[givenFilter.name],
                    filters: filtersToUpdate.filter(({ values }) => values.length),
                    operator,
                },
            });
        }
    } else {
        updatedFilters = updateObject(filters, {
            [givenFilter.name]: {
                filters: isConditionFilterSupported(givenFilter.name)
                    ? filter(filtersToUpdate, items => items.value !== givenFilter.value)
                    : without(filtersToUpdate, givenFilter.value),
                operator,
            },
        });
    }

    return updatedFilters;
};

export const getFiltersWithAddedFilter = (filters, givenFilter) => {
    if (knownResourceIsPM.includes(givenFilter.name)) {
        return {
            ...filters,
            resourceIsPm: {
                filters: givenFilter.value === 'resource-is-pm',
            },
        };
    }

    let updatedFilters;
    const operator = filters[givenFilter.name].operator;

    if ('projectCustomFields' === givenFilter.name || 'resourceCustomFields' === givenFilter.name) {
        const currentParentFilter =
            filters[givenFilter.name]?.filters.find(f => f.id === givenFilter.parentValue) || {};

        const filtersToUpdate = currentParentFilter.id
            ? filters[givenFilter.name].filters.map(f =>
                  f.id === currentParentFilter.id
                      ? {
                            ...currentParentFilter,
                            values: uniqBy(
                                [
                                    { value: givenFilter.value, set: isNil(givenFilter.set) ? true : givenFilter.set },
                                    ...currentParentFilter.values,
                                ],
                                'value'
                            ),
                        }
                      : f
              )
            : [
                  ...filters[givenFilter.name].filters,
                  {
                      id: givenFilter.parentValue,
                      values: [{ value: givenFilter.value, set: isNil(givenFilter.set) ? true : givenFilter.set }],
                  },
              ];

        const newValues = {
            [givenFilter.name]: {
                operator,
                filters: filtersToUpdate,
            },
        };

        updatedFilters = updateObject(filters, newValues);
    } else if (givenFilter.parentValue) {
        const appliedFilter = find(
            filters[givenFilter.name].filters,
            _filter => givenFilter.parentValue === _filter.id
        );

        if (appliedFilter) {
            updatedFilters = updateObject(filters, {
                [givenFilter.name]: {
                    operator,
                    filters: updateItemInArray(
                        filters[givenFilter.name].filters,
                        givenFilter.parentValue,
                        item => updateObject(item, { values: uniq([...item.values, givenFilter.value]) }),
                        'id'
                    ),
                },
            });
        } else {
            updatedFilters = updateObject(filters, {
                [givenFilter.name]: {
                    operator,
                    filters: [
                        ...filters[givenFilter.name].filters,
                        {
                            id: givenFilter.parentValue,
                            values: [
                                { value: givenFilter.value, set: isNil(givenFilter.set) ? true : givenFilter.set },
                            ],
                        },
                    ],
                },
            });
        }
    } else {
        updatedFilters = updateObject(filters, {
            [givenFilter.name]: {
                operator,
                filters: isConditionFilterSupported(givenFilter.name)
                    ? uniqBy(
                          [
                              { value: givenFilter.value, set: isNil(givenFilter.set) ? true : givenFilter.set },
                              ...filters[givenFilter.name].filters,
                          ],
                          'value'
                      )
                    : uniq([...filters[givenFilter.name].filters, givenFilter.value]),
            },
        });
    }

    return updatedFilters;
};

export const optimisticUpdates = [
    {
        shouldUpdate: action =>
            action.payload.body?.companySpecific?.useResourceAvailability !== undefined ||
            action.payload.body?.companySpecific?.customAvailabilities !== undefined,
        doUpdate: (state, action) => {
            return updateObject(state, {
                dpChildrenResourcesById: {
                    ...state.dpChildrenResourcesById,
                    ...(state.dpChildrenResourcesById[action.payload.id]
                        ? {
                              [action.payload.id]: updateAvailabilities(
                                  action,
                                  state.dpChildrenResourcesById[action.payload.id]
                              ),
                          }
                        : {}),
                },
                dpResources: state.dpResources.map(dpResource => {
                    if (dpResource._id === action.payload.id) {
                        return updateAvailabilities(action, dpResource);
                    }
                    return dpResource;
                }),
            });
        },
    },
    {
        shouldUpdate: action => action.payload.body.companySpecific.customAvailabilityOverridesToSave,
        doUpdate: (state, action) => {
            return updateObject(state, {
                dpChildrenResourcesById: {
                    ...state.dpChildrenResourcesById,
                    ...(state.dpChildrenResourcesById[action.payload.id]
                        ? {
                              [action.payload.id]: updateOverrides(
                                  action,
                                  state.dpChildrenResourcesById[action.payload.id]
                              ),
                          }
                        : {}),
                },
                dpResources: (state.dpResources ?? []).map(dpResource => {
                    if (dpResource._id === action.payload.id) {
                        return updateOverrides(action, dpResource);
                    }

                    return dpResource;
                }),
            });
        },
    },
];
