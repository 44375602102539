import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { displayCellCurrency } from 'modules/report/utils/columnHelper';
import { formatPrice } from 'utils/formatingUtil';
import { getCurrency } from 'enums/currencyEnum';
import { useSelector } from 'react-redux';
import DataBox from '../dataBox';

const SpendRow = ({ reportData, isLoading, showReportedData }) => {
    const {
        gridPreferences: { singleProjectView },
    } = useAccountPreferences();
    const defaultCurrency = useSelector(state => state.companyReducer.company.settings.defaultCurrency);
    const defaultCurrencySymbol = getCurrency(defaultCurrency).symbol;

    const scheduledValues = useMemo(
        () => [
            {
                value: formatPrice(Number(reportData.budgetCash?.toString())),
                label: 'budget',
                currency: displayCellCurrency(
                    reportData?.currencies?.budgetCash,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
            {
                value: formatPrice(reportData.externalRemainingScheduledBudgetCashAllTime),
                label: 'remaining (ext rate)',
                currency: displayCellCurrency(
                    reportData?.currencies?.externalRemainingScheduledBudgetCashAllTime,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
            {
                value: formatPrice(reportData.internalRemainingScheduledBudgetCashAllTime),
                label: 'remaining (int rate)',
                currency: displayCellCurrency(
                    reportData?.currencies?.internalRemainingScheduledBudgetCashAllTime,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
        ],
        [reportData, defaultCurrencySymbol]
    );

    const reportedValues = useMemo(
        () => [
            {
                value: formatPrice(Number(reportData.budgetCash?.toString())),
                label: 'budget',
                currency: displayCellCurrency(
                    reportData?.currencies?.budgetCash,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
            {
                value: formatPrice(reportData.externalRemainingReportedBudgetCashAllTime),
                label: 'remaining (ext rate)',
                currency: displayCellCurrency(
                    reportData?.currencies?.externalRemainingReportedBudgetCashAllTime,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
            {
                value: formatPrice(reportData.internalRemainingReportedBudgetCashAllTime),
                label: 'remaining (int rate)',
                currency: displayCellCurrency(
                    reportData?.currencies?.internalRemainingReportedBudgetCashAllTime,
                    defaultCurrencySymbol,
                    'mixed'
                ),
            },
        ],
        [reportData, defaultCurrencySymbol]
    );

    return (
        <Row className="px-3">
            {singleProjectView.displayScheduledColumn && (
                <DataBox
                    title="scheduled cash budget"
                    unit="cash"
                    fullWidth={!singleProjectView.displayReportedColumn || !showReportedData}
                    values={scheduledValues}
                    isLoading={isLoading}
                    className={classNames({ 'main-border': singleProjectView.displayReportedColumn })}
                />
            )}
            {singleProjectView.displayReportedColumn && showReportedData && (
                <DataBox
                    title="reported cash budget"
                    unit="cash"
                    fullWidth={!singleProjectView.displayScheduledColumn}
                    values={reportedValues}
                    isLoading={isLoading}
                />
            )}
        </Row>
    );
};

SpendRow.propTypes = {
    reportData: PropTypes.shape({
        budgetCash: PropTypes.array,
        externalRemainingScheduledBudgetCashAllTime: PropTypes.number,
        internalRemainingScheduledBudgetCashAllTime: PropTypes.number,
        externalRemainingReportedBudgetCashAllTime: PropTypes.number,
        internalRemainingReportedBudgetCashAllTime: PropTypes.number,
        currencies: PropTypes.object,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    showReportedData: PropTypes.bool.isRequired,
};

export default SpendRow;
