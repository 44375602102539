import { Button, Row } from 'reactstrap';
import styled from 'styled-components';

export const ActionItemsRow = styled(Row)`
    &.divider {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
`;

export const Item = styled.span`
    color: #525252;
    display: inline-block;
    position: relative;
    border: 1px solid #e6e7ed;
    border-radius: 4px;
    height: 74px;
    width: 93%;
    padding: 7px;
    max-width: 80px;

    &:hover {
        opacity: 0.7;
    }

    &.disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    &.edit-booking-mm svg {
        margin-bottom: 7px;
    }

    svg {
        margin-top: 3px;
        font-size: 20px;
    }

    .edit-booking-mm svg {
        margin-top: 15px;
    }

    svg.note-icon {
        position: absolute;
        font-size: 14px;
        top: -1px;
        right: 19px;
    }
`;

export const ModernMenuLabel = styled.span`
    margin-left: 20px;
    font-size: 13px;
    font-weight: 500;
    margin-bottom: -5px;
    display: block;
    margin-top: -4px;
`;

export const HeaderIconText = styled.span`
    font-size: 11px;
    font-weight: 500;
    display: block;
    white-space: initial;
    letter-spacing: 0.1px;
    margin-top: 4px;
    line-height: 13px;
    color: #4e5252;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Toggler = styled.button`
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    text-align: left;
    padding: 0.45rem 0.5rem !important;
    border-bottom: 1px solid #e5e5e5;
    background: none;

    &.active,
    &:hover {
        background-color: #efefef;
    }

    &:focus {
        outline: none;
    }
`;

export const Text = styled.p`
    margin: 0;
    vertical-align: middle;
    display: inline-block;

    span {
        color: #818181;
    }
`;

export const SelectedColor = styled.span`
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: ${props => props.color};
    vertical-align: middle;
`;

export const ScheduleDate = styled.span`
    font-style: italic;
    font-size: 12px;
    text-align: center;
    display: block;
`;

export const ScheduleButton = styled(Button)`
    width: calc(100% - 20px) !important;
    margin: 10px 10px 0 10px;
`;

export const KeyboardShortCut = styled.span`
    float: right;
    color: RGBA(0, 0, 0, 0.42);
    font-size: 12px;
`;
