/* eslint-env browser */

export const createBlobData = (data, type) =>
    new Blob([data], {
        type: 'pdf' !== type ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf',
    });

export default (content, filename) => {
    let data = content;
    let link = document.createElement('a');

    if (navigator.msSaveBlob) {
        return navigator.msSaveBlob(data, filename);
    }

    if (content instanceof Blob) {
        data = window.URL.createObjectURL(content);
    }

    document.body.appendChild(link);
    link.href = data;
    link.className = 'd-none';
    link.download = filename;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(data);
};

export function replaceGroupIdInLink(link, groupId) {
    if (!link || !groupId) {
        return link;
    }

    if (typeof link !== 'string') {
        return link;
    }

    const linkRegExp = new RegExp('/(resource|project)\\/[a-zA-z0-9]+\\/group\\/[a-zA-z0-9]+');

    if (linkRegExp.test(link)) {
        const [empty, entityName, entityId, group] = link.split('/');
        return [empty, entityName, entityId, group, groupId].join('/');
    }

    return link;
}
