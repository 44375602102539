import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        color: '#525252',
        border: '1px solid #dedede',
        padding: '0.3rem 1rem',
        marginLeft: '5px',
        minWidth: '100px',
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#f5f5f5',
            color: '#000',
        },
    },
    iconSizeMedium: {
        marginLeft: 0,
    },
    label: {
        fontSize: '12px',
        textTransform: 'capitalize',
        fontWeight: 'normal',
    },
    paper: {
        borderRadius: '4px !important',
        minWidth: '150px',
        padding: '0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
        margin: 0,
    },
});
