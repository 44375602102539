import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator, emailValidator, emailValidatorIfNotEmpty } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import { store } from '../../store';
import { deleteResource } from 'actions/resourceActions';

export const resourcesStepKey = 'resources';

const resourcesStepRowElements = [
    {
        name: 'firstName',
        type: 'text',
        label: 'First name',
        placeholder: 'First name',
        validator: emptyStringValidator,
        minWidth: '200px',
    },
    {
        name: 'lastName',
        type: 'text',
        label: 'Last name',
        placeholder: 'Last name',
        minWidth: '200px',
    },
    {
        name: 'email',
        type: 'text',
        label: 'Email',
        placeholder: 'E.g. stevo@email.com',
        validator: emailValidatorIfNotEmpty,
        minWidth: '250px',
    },
    {
        disabledFn: ({ email }) => {
            return !emailValidator.validator(email);
        },
        columnTitle: 'Invite',
        name: 'invite',
        type: 'checkbox',
        label: '',
    },
    {
        columnTitle: (
            <>
                User role{' '}
                <Tooltip title="You can change users roles later in the settings" placement="top" arrow>
                    <InfoOutlinedIcon />
                </Tooltip>
            </>
        ),
        valueFn: ({ isAdmin }) => {
            return isAdmin == null ? true : isAdmin;
        },
        disabledFn: ({ email }) => {
            return !emailValidator.validator(email);
        },
        name: 'isAdmin',
        type: 'switch',
        labels: ['Team', 'Admin'],
    },
];

export const resourcesStepMapper = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: entity => {
        if (emailValidator.validator(entity.email)) {
            return entity.email;
        }
        return '';
    },
    isAdmin: entity => {
        return entity?.currentCompanySpecific?.role === 'ROLE_ADMIN';
    },
};

const useStyles = makeStyles({
    bottomText: {
        display: 'flex',
        fontSize: '80%',
        marginTop: '80px',
        justifyContent: 'center',
    },
});

export const ResourcesOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;
    const classes = useStyles();

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteResource.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    const onResetChangesOnCreatedInt = useCallback(
        rowIndex => {
            onResetChangesOnCreated(rowIndex, resourcesStepMapper);
        },
        [onResetChangesOnCreated]
    );

    return (
        <div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={resourcesStepRowElements}
                getValue={key => storedData[`${resourcesStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreatedInt}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${resourcesStepKey}-meta-showValidationErrors`]}
                addMoreLabel="More team members"
                objectName="team member"
            />
            <p className={classes.bottomText}>
                * The selected team members will receive emails with invitations to your Hub Planner Team.
            </p>
            {storedData[`${resourcesStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default ResourcesOnboardingStepContent;
