/* eslint-env browser */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { first } from 'underscore';
import { Field, reduxForm } from 'redux-form';
import { Container, Row, Col, Form, FormGroup, Alert } from 'reactstrap';
import { Warning } from '@material-ui/icons';
import { List, ListItem, ListItemAvatar, Avatar } from '@material-ui/core';

import { getResourceSSOCompanies } from 'api/account';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import SSOError from '../../shared/ssoError';
import { SubmitButton } from '../../shared/buttons';
import { CompanyLink } from './styles';

const SSO = ({ isAuthenticated, handleSubmit, invalid, location }) => {
    const [error, setError] = useState('');
    const [companies, setCompanies] = useState([]);

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    const submit = async values => {
        try {
            const companies = await getResourceSSOCompanies(values.email);
            setError('');
            // don't display list on FE, redirect to sso target url instead
            if (1 === companies.length) {
                window.location.replace(first(companies).url);
                return;
            }
            setCompanies(companies);
        } catch (error) {
            setCompanies([]);
            setError(
                (error && error.data && error.data.message) ||
                    "Something went wrong! We couldn't find any companies with correct settings..."
            );
        }
    };

    return (
        <PageTemplate type="login">
            {error && (
                <Alert color="danger">
                    <b>
                        <Warning />
                    </b>{' '}
                    {error}
                </Alert>
            )}
            <SSOError />
            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup className="mb-0">
                    <Field
                        component={renderTextField}
                        label="Email Address"
                        name="email"
                        variant="outlined"
                        autoComplete="off"
                        fullWidth
                    />
                </FormGroup>
                <List className="pb-0">
                    {companies.map(company => (
                        <ListItem key={company.subdomain}>
                            <ListItemAvatar>
                                <Avatar>
                                    <CompanyLink
                                        onClick={() => {
                                            Cookies.set('sso-redirect', location.pathname);
                                        }}
                                        href={company.url}
                                    >
                                        <img
                                            className="w-100"
                                            src={`https://d329beqc2zk6g7.cloudfront.net${company.thumb}`}
                                            alt={company.subdomain}
                                        />
                                    </CompanyLink>
                                </Avatar>
                            </ListItemAvatar>
                            <CompanyLink
                                onClick={() => {
                                    Cookies.set('sso-redirect', location.pathname);
                                }}
                                href={company.url}
                            >
                                {company.name}
                            </CompanyLink>
                        </ListItem>
                    ))}
                </List>
                <SubmitButton disabled={invalid} type="submit" className="btn-block mb-2 mt-3 shadow-sm border-0 py-2">
                    Sign in
                </SubmitButton>
            </Form>
            <hr className="my-4" />
            <Container className="mt-3">
                <Row className="d-flex">
                    <Col xs="12" className="pr-3 text-right">
                        <Link to="/login">
                            <span className="text-nowrap">Back to Login with Password</span>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </PageTemplate>
    );
};

SSO.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool.isRequired,
};

export default reduxForm({
    form: 'ssoForm',
    validate: validateSchema(formSchema),
})(SSO);
