import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { Menu } from '@material-ui/core';
import { without, includes } from 'lodash';
import { useAppKeyWords, useIsExtensionInstalled, useHasRights } from 'hooks';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { EXTENDED_DASHBOARD, SMART_GROUPS, TIME_SHEET } from 'enums/extensionShortIdEnum';
import NestedMenuItem from 'shared/nestedMenuItem';
import { LinkItem, useStyles } from './styles';
import { SYSTEM } from 'modules/scheduler/enums/groupTypeEnum';
import { NestedMenuItemManager } from '../../../../../shared/nestedMenuItem/context';
import { WrappedMenuItem } from '../../../../../shared/nestedMenuItem/wrappedMenuItem';

const rights = [
    {
        rights: ['manageTs'],
        rule: 'one',
        name: 'hasManageTs',
    },
    {
        rights: ['manageReports'],
        rule: 'one',
        name: 'hasManageReports',
    },
    {
        rights: ['settingResourceGroups'],
        rule: 'one',
        name: 'hasManageResourcesGroups',
    },
    {
        rights: ['settingProjectGroups'],
        rule: 'one',
        name: 'hasManageProjectGroups',
    },
];

const getRequestPayload = (type, currentItem, itemId) => {
    const isInItemIds = currentItem.queryParams && includes(currentItem.queryParams.itemIds, itemId);
    const itemIds = without(currentItem['RESOURCE' === type ? 'resources' : 'projects'], itemId);
    const data = {
        queryParams: {
            itemIds,
            filters: [],
            groupsToRemove: [],
            itemsToRemove: [itemId],
            groupsToAdd: [],
            itemsToAdd: [],
        },
    };
    if (!isInItemIds) {
        data.queryParams.itemGroupIds = [];
    }

    return data;
};

const ItemContextMenu = props => {
    const { top, left, type, groupType, item, group, close, actions } = props;
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const isTimeSheetExtensionInstalled = useIsExtensionInstalled(TIME_SHEET);
    const isExtDashboardExtensionInstalled = useIsExtensionInstalled(EXTENDED_DASHBOARD);
    const isSmartGroupsExtensionInstalled = useIsExtensionInstalled(SMART_GROUPS);

    const classes = useStyles();
    const keyWord =
        'RESOURCE' === type
            ? item.type === TYPE_UNASSIGNED.value
                ? TYPE_UNASSIGNED.display
                : resourceKeyWord
            : projectKeyWord;
    const scheduleUrl =
        'RESOURCE' === type ? `/resource/${item._id}/group/${group._id}` : `/project/${item._id}/group/${group._id}`;
    const timesheetUrl = `/time_sheets#view=actual&${'RESOURCE' === type ? 'rid' : 'pid'}=${item._id}`;
    const dashboardUrl =
        'RESOURCE' === type
            ? `/dashboard#id=${item._id}&type=resource&group=false&page=resGroupDash&tabSide=resource&tabInline=hub_resource_dash_content_wrapper`
            : `/dashboard#id=${item._id}&type=project&group=false&page=proGroupDash&tabSide=project&tabInline=hub_project_dash_content_wrapper`;
    const hasEditRights = item.edit || item.editPm;

    const { hasManageTs, hasManageReports, hasManageProjectGroups, hasManageResourcesGroups } = useHasRights(rights);

    const hasRightsToRemove = 'RESOURCE' === type ? hasManageResourcesGroups : hasManageProjectGroups;

    const openEditModal = () => {
        close();
        actions.showModal(item.type);
    };

    const duplicate = () => {
        close();
        actions.duplicate();
    };

    const updateGroup = () => {
        if ('RESOURCE' === type) {
            actions.updateGroup(getRequestPayload(type, group, item._id));
        } else {
            actions.updateGroup(getRequestPayload(type, group, item._id));
        }
        close();
    };

    return (
        <NestedMenuItemManager>
            <Menu
                keepMounted
                classes={classes}
                open={true}
                onClose={close}
                anchorReference="anchorPosition"
                anchorPosition={{ top, left }}
            >
                <NestedMenuItem label="View" parentMenuOpen={true}>
                    <LinkItem>
                        <Link className="text-decoration-none" to={scheduleUrl}>
                            Schedule
                        </Link>
                    </LinkItem>
                    {isTimeSheetExtensionInstalled && hasManageTs && (
                        <LinkItem>
                            <NavLink className="p-0 text-decoration-none" href={timesheetUrl}>
                                Timesheet
                            </NavLink>
                        </LinkItem>
                    )}
                    {isExtDashboardExtensionInstalled && hasManageReports && (
                        <LinkItem>
                            <NavLink className="p-0 text-decoration-none" href={dashboardUrl}>
                                Dashboard
                            </NavLink>
                        </LinkItem>
                    )}
                </NestedMenuItem>
                {SYSTEM !== groupType &&
                    hasRightsToRemove &&
                    (!isSmartGroupsExtensionInstalled || !group.isSmart) &&
                    group.queryParams &&
                    !group.queryParams.itemGroupIds.length && (
                        <WrappedMenuItem onClick={updateGroup}>Remove from group ...</WrappedMenuItem>
                    )}
                {hasEditRights && (
                    <WrappedMenuItem onClick={duplicate}>Duplicate {keyWord.toLowerCase()}...</WrappedMenuItem>
                )}
                {hasEditRights && (
                    <WrappedMenuItem onClick={openEditModal}>Edit {keyWord.toLowerCase()}...</WrappedMenuItem>
                )}
            </Menu>
        </NestedMenuItemManager>
    );
};

ItemContextMenu.propTypes = {
    item: PropTypes.object.isRequired,
    groupType: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    type: PropTypes.oneOf(['RESOURCE', 'PROJECT']).isRequired,
    actions: PropTypes.shape({
        showModal: PropTypes.func.isRequired,
        duplicate: PropTypes.func.isRequired,
        updateGroup: PropTypes.func.isRequired,
    }).isRequired,
    close: PropTypes.func.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
};

export default ItemContextMenu;
