import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, TabPane, Row, Col } from 'reactstrap';
import { textField } from 'shared/formFields';
import IconPicker from 'shared/iconPicker';
import { iconTypes } from 'enums/iconEnum';
import PermissionDenied from 'shared/permissionDenied';

import WysiwygEditor from 'shared/wysiwygEditor';

const linksArray = [1, 2, 3, 4, 5];

const NotesTab = props => {
    const { tabId, onChange, noteKeyWord, linksKeyWord, hasNoteRights, hasLinksRights, iconLinks, note } = props;
    const tabRef = useRef();

    return (
        <TabPane tabId={tabId}>
            <div ref={tabRef}>
                {hasNoteRights && (
                    <section className="form-fields">
                        <p className="title">{noteKeyWord} Notes:</p>
                        <WysiwygEditor onChange={onChange} initialContent={note} noStringifyFormatted />
                    </section>
                )}
                {hasLinksRights && (
                    <section className="form-fields border-bottom-0">
                        <p className="title">{linksKeyWord} Links:</p>
                        {linksArray.map(item => (
                            <Row key={item} className="mb-2">
                                <Col md={10}>
                                    <Field
                                        width="col-md-12"
                                        placeholder="3rd party link"
                                        name={`link${item}`}
                                        component={textField}
                                    />
                                </Col>
                                <Col md={2}>
                                    <IconPicker
                                        iconKey={item}
                                        title="Choose an Icon"
                                        icons={iconTypes}
                                        onChange={icon => onChange({ [`iconLink${item}`]: icon })}
                                        icon={iconLinks[`iconLink${item}`]}
                                        container={tabRef}
                                    />
                                </Col>
                            </Row>
                        ))}
                        <FormText>
                            Add Links to your {linksKeyWord} that link to other software. Links will be used via the
                            Notes panel.
                        </FormText>
                    </section>
                )}
                {!hasNoteRights && !hasLinksRights && <PermissionDenied />}
            </div>
        </TabPane>
    );
};

NotesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    noteKeyWord: PropTypes.string,
    linksKeyWord: PropTypes.string,
    hasNoteRights: PropTypes.bool,
    hasLinksRights: PropTypes.bool,
    iconLinks: PropTypes.object,
    note: PropTypes.object,
};

NotesTab.defaultProps = {
    noteKeyWord: 'Resource',
    linksKeyWord: 'Resource',
    hasNoteRights: false,
    hasLinksRights: false,
    iconLinks: {},
    note: {
        message: '',
        formattedNote: '',
    },
};

export default NotesTab;
