import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import StatusFilterBox from 'modules/report/containers/tableFilters/statusFilterBox';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { formatNumber } from 'utils/formatingUtil';
import {
    displayCurrencyValue,
    displayStatusByType,
    displayValue,
    isHeaderDisabled,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import { Header, Status } from './../styles';
import { BUDGET_STATUSES_ENUM, statusesList } from 'modules/report/enums/budgetStatusEnum';
import ProgressBarCell from 'modules/report/components/tableCells/progressBarCell';
import PercentageCell from 'modules/report/components/tableCells/percentageCell';

/**
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Remaining
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalRemainingScheduledBudgetCashAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalRemainingScheduledBudgetCashAllTime'),
        accessor: row =>
            displayValue(
                row.externalRemainingScheduledBudgetCashAllTime,
                displayCurrencyValue(row, 'externalRemainingScheduledBudgetCashAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Remaining
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'internalRemainingScheduledBudgetCashAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalRemainingScheduledBudgetCashAllTime'),
        accessor: row =>
            displayValue(
                row.internalRemainingScheduledBudgetCashAllTime,
                displayCurrencyValue(row, 'internalRemainingScheduledBudgetCashAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Cash Consumed
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalConsumedScheduledBudgetCashAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'externalConsumedScheduledBudgetCashAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.externalConsumedScheduledBudgetCashAllTimePercentage,
                formatNumber(row.externalConsumedScheduledBudgetCashAllTimePercentage || 0) + '%'
            ),
        Cell: PercentageCell,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Cash Consumed
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalConsumedScheduledBudgetCashAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'internalConsumedScheduledBudgetCashAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.internalConsumedScheduledBudgetCashAllTimePercentage,
                formatNumber(row.internalConsumedScheduledBudgetCashAllTimePercentage || 0) + '%'
            ),
        Cell: PercentageCell,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Status
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'externalScheduledBudgetCashAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'externalScheduledBudgetCashAllTimeStatus'),
        accessor: row => displayValue(row.externalScheduledBudgetCashAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Status
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'internalScheduledBudgetCashAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'internalScheduledBudgetCashAllTimeStatus'),
        accessor: row => displayValue(row.internalScheduledBudgetCashAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Remaining Spend Difference
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'externalRemainingScheduledDifferenceAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalRemainingScheduledDifferenceAllTime'),
        accessor: row =>
            displayValue(
                row.externalRemainingScheduledDifferenceAllTime,
                displayCurrencyValue(row, 'externalRemainingScheduledDifferenceAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Remaining Spend Difference
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'internalRemainingScheduledDifferenceAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalRemainingScheduledDifferenceAllTime'),
        accessor: row =>
            displayValue(
                row.internalRemainingScheduledDifferenceAllTime,
                displayCurrencyValue(row, 'internalRemainingScheduledDifferenceAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Hours Remaining
                        <p className="sub-title">(scheduled)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'remainingScheduledBudgetHoursAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'remainingScheduledBudgetHoursAllTime'),
        accessor: row =>
            displayValue(
                row.remainingScheduledBudgetHoursAllTime,
                formatNumber(row.remainingScheduledBudgetHoursAllTime || 0, true)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Hours Consumed
                        <p className="sub-title">(scheduled)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'consumedScheduledBudgetHoursAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'consumedScheduledBudgetHoursAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.consumedScheduledBudgetHoursAllTimePercentage,
                formatNumber(row.consumedScheduledBudgetHoursAllTimePercentage || 0) + '%'
            ),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Hour Status
                        <p className="sub-title">(scheduled)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledBudgetHoursAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledBudgetHoursAllTimeStatus'),
        accessor: row => displayValue(row.scheduledBudgetHoursAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
];
