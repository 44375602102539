import { axiosInstance } from './axiosInstance';

export const getDaypilotResources = data =>
    axiosInstance
        .post('scheduler/data', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getDaypilotResourceChildren = (resourceId, projects) =>
    axiosInstance
        .post(
            `scheduler/data/resource/${resourceId}/projects`,
            projects?.length && { filters: [{ name: 'projects', values: projects }] }
        )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getDaypilotProjectChildren = (projectId, resources) =>
    axiosInstance
        .post(
            `scheduler/data/project/${projectId}/resources`,
            resources?.length && { filters: [{ name: 'resources', values: resources }] }
        )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getDaypilotUnassignedChildren = data =>
    axiosInstance
        .post(`scheduler/data/unassigned`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createBookingLinkRequest = ({ parentId, childId, skipDependencyDateValidation }) =>
    axiosInstance
        .post(`bookingTree`, { parentId, childId }, { params: { skipDependencyDateValidation } })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteBookingLinkRequest = ({ parentId, childId }) =>
    axiosInstance
        .delete(`bookingTree`, { data: { parentId, childId } })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
