import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FormText, Button, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { formSchema } from 'forms/customerForm';
import { validateSchema } from 'utils/schemaUtil';
import { textField } from 'shared/formFields';

class CreateCustomer extends PureComponent {
    static propTypes = {
        formName: PropTypes.string,
        fieldName: PropTypes.string,
        onDismiss: PropTypes.func,
    };

    static defaultProps = {
        formName: '',
        fieldName: '',
        onDismiss: null,
    };

    onSubmit() {
        this.props.submitForm();
        if (this.props.valid) {
            if (this.props.onDismiss) {
                setTimeout(this.props.onDismiss);
            }
        }
    }

    render() {
        return (
            <div>
                <p className="title">Add New Client:</p>
                <div className="needs-validation">
                    <Row>
                        <Col className="pr-0">
                            <Field
                                width="col-md-12"
                                name="name"
                                placeholder="Type the name of the new Client here..."
                                component={textField}
                            />
                        </Col>
                        <Col className="pr-0" style={{ marginTop: '4px' }}>
                            <Button type="button" onClick={this.onSubmit.bind(this)} className="rounded-0 mr-2">
                                <i className="fa fa-check" />
                            </Button>
                            <Button type="button" className="rounded-0" color="danger" onClick={this.props.onDismiss}>
                                <i className="fa fa-times" />
                            </Button>
                        </Col>
                    </Row>
                    <FormText>Enter the name of a new Client</FormText>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: 'customerForm',
    validate: validateSchema(formSchema),
})(CreateCustomer);
