import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SuccessButton from 'shared/buttons/success';
import { useAppKeyWords, useHasRights } from 'hooks';
import WysiwygEditor from 'shared/wysiwygEditor';
import PermissionDenied from 'shared/permissionDenied';
import TabLinks from 'shared/tabLinks';
import { useBookingSidebarContext } from './SidebarContext';
import { makeResourceById } from '../../../../selectors/resource';
import { makeProjectById } from '../../../../selectors/project';

const tabs = [
    {
        id: 'booking',
        title: () => `Booking note`,
        icon: 'fa-clock-o',
    },
    {
        id: 'project',
        title: ({ projectKeyWord }) => `${projectKeyWord} note`,
        icon: 'fa-file',
    },
    {
        id: 'resource',
        title: ({ resourceKeyWord }) => `${resourceKeyWord} note`,
        icon: 'fa-user',
    },
];

const rights = [
    {
        rights: ['viewNotes'],
        rule: 'one',
        name: 'hasViewNotesRights',
    },
    {
        rights: ['settingResourceNotes'],
        rule: 'one',
        name: 'hasEditResourceNotesRights',
    },
    {
        rights: ['settingProjectNotes'],
        rule: 'one',
        name: 'hasEditProjectNotesRights',
    },
];

const BookingNote = props => {
    const { currentSelectedBooking } = props;
    const appKeyWords = useAppKeyWords();
    const [tab, setTab] = useState('booking');

    const { formNotes, setFormNotes, updateAllEditedNotes } = useBookingSidebarContext();

    const resourceSelector = useMemo(() => {
        return makeResourceById(currentSelectedBooking?.resourceInfo?._id);
    }, [currentSelectedBooking?.resourceInfo?._id]);

    const projectSelector = useMemo(() => {
        return makeProjectById(currentSelectedBooking?.project?._id);
    }, [currentSelectedBooking?.project?._id]);

    const { hasViewNotesRights, hasEditResourceNotesRights, hasEditProjectNotesRights } = useHasRights(rights);

    const bookingResource = useSelector(resourceSelector);
    const bookingProject = useSelector(projectSelector);

    const canEdit =
        ('booking' === tab && currentSelectedBooking?.hasEditNoteRights) ||
        ('project' === tab && hasEditProjectNotesRights) ||
        ('resource' === tab && hasEditResourceNotesRights);

    const hasViewNotesRightsWithPermissions =
        hasViewNotesRights &&
        ('booking' === tab ||
            ('project' === tab && (bookingProject?.hasRightsToProject || bookingProject?.hasPMRightsToProject)) ||
            ('resource' === tab && bookingResource?.hasRightsToResource));

    const getTabs = useMemo(
        () =>
            tabs.map(tab => ({
                ...tab,
                title: tab.title(appKeyWords),
            })),
        [appKeyWords]
    );

    const handleContentChange = useCallback(
        content => {
            setFormNotes(prevNotes => ({
                ...prevNotes,
                [tab]: {
                    note: content.note,
                    edited: true,
                },
            }));
        },
        [setFormNotes, tab]
    );

    const bookingId = currentSelectedBooking?._id || currentSelectedBooking?.id;
    const someNoteEdited = tabs.some(tab => formNotes[tab.id]?.edited);

    return (
        <>
            <h4>Notes</h4>
            <span className="text-muted d-inline-block mb-2">Notes relevant to this booking</span>
            <TabLinks center={false} tabs={getTabs} toggle={setTab} />
            {hasViewNotesRightsWithPermissions ? (
                <section className="mt-2">
                    <WysiwygEditor
                        key={`${bookingId}-${tab}`}
                        onChange={handleContentChange}
                        initialContent={formNotes[tab].note}
                        readOnly={!canEdit}
                        noStringifyFormatted
                    />
                    <SuccessButton
                        disabled={!canEdit || !someNoteEdited}
                        onClick={updateAllEditedNotes}
                        dataCy="button--save-note"
                        className="rounded-0 btn-request mt-2"
                        name="Save note"
                        tooltipText={canEdit ? undefined : 'You do not have the role rights to edit notes'}
                    />
                </section>
            ) : (
                <PermissionDenied />
            )}
        </>
    );
};

BookingNote.propTypes = {
    currentSelectedBooking: PropTypes.object.isRequired,
};

export default BookingNote;
