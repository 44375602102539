import { call, put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from 'actions/actionTypes';
import { getCategoryGroups, getCategoriesByGroup, saveCategoryGroups } from 'actions/categoryGroupActions';
import { addNotification } from 'actions/notificationActions';
import { getCategoryGroupsRequest, getCategoriesByGroupIdRequest, saveCategoryGroupsRequest } from 'api/categoryGroup';
import { hideModal } from 'actions/modalActions';
import { getCategoryGroups as getCategoryGroupsSelector } from 'selectors/categoryGroups';
import { getProjects } from 'actions/projectActions';
import { monitoring } from '../monitoring';

function* handleGetCategoryGroups(action) {
    const { force } = action.payload ?? {};

    try {
        const currentGroups = yield select(getCategoryGroupsSelector);
        if (currentGroups.length && !force) {
            yield put(getCategoryGroups.stop());
            return;
        }
        const categoryGroups = yield call(getCategoryGroupsRequest);

        action.payload?.callback && action.payload?.callback(categoryGroups);

        yield put(getCategoryGroups.success(categoryGroups));
    } catch (error) {
        monitoring.captureException(error);
        yield put(getCategoryGroups.failure());
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleGetCategoriesByGroup(action) {
    try {
        const categories = yield call(getCategoriesByGroupIdRequest, action.payload.groupId);

        yield put(getCategoriesByGroup.success(categories));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleSaveCategoryGroups(action) {
    try {
        const response = yield call(saveCategoryGroupsRequest, action.payload.data);

        yield put(saveCategoryGroups.success(response));
        // Refresh projects, so UI can has now new associated groups
        yield put(getProjects.request(true));

        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(saveCategoryGroups.failure());
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleCreateCategorySuccess() {
    yield put(getCategoryGroups.request({ force: true }));
}

export default function* categoryGroupsWatcher() {
    yield takeLatest(actionTypes.GET_CATEGORY_GROUPS['REQUEST'], handleGetCategoryGroups);
    yield takeLatest(actionTypes.GET_CATEGORIES_BY_GROUP['REQUEST'], handleGetCategoriesByGroup);
    yield takeLatest(actionTypes.SAVE_CATEGORY_GROUPS['REQUEST'], handleSaveCategoryGroups);
    yield takeLatest(actionTypes.CREATE_CATEGORY_TEMPLATE['SUCCESS'], handleCreateCategorySuccess);
}
