/* eslint-env browser */
import React, { useEffect, useRef } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm, formValueSelector, initialize, touch } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import { parse } from 'services/queryString';

import { signUp } from 'actions/accountActions';
import { getUrl } from 'utils/browserUtil';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, asyncValidate } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import { SubmitButton } from '../../shared/buttons';
import PasswordStrengthMeter from '../../shared/PasswordStrengthMeter';
import { ConfirmCheckbox } from '../../shared/checkboxes';
import { SSOGoogleHref } from '../../shared/buttons/styles';
import Error from '../../shared/error';

const selector = formValueSelector('signupForm');

const SignUp = ({ isAuthenticated, handleSubmit, location }) => {
    const dispatch = useDispatch();
    const recaptchaRef = useRef();
    const authError = useSelector(state => state.account.authError);
    const { password, confirm } = useSelector(state => selector(state, 'password', 'confirm'));
    const { email, failed, emailTaken } = parse(location.search);

    useEffect(() => {
        dispatch(
            initialize('signupForm', {
                email: email,
            })
        );
    }, [email, dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            return;
        }
        if (document.referrer) {
            Cookies.set('hubplanneraccountroute4', document.referrer, { expires: 365, path: '/' });
        }
    }, [dispatch, isAuthenticated]);

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    const onGoogleBtnClick = event => {
        if (!confirm) {
            event.preventDefault();
            dispatch(touch('signupForm', ['confirm']));
            return;
        }
        Cookies.set('sso-redirect', location.pathname);
    };

    const submit = async values => {
        const token = await recaptchaRef.current.executeAsync();
        dispatch(signUp.request({ ...values, token }));
    };

    return (
        <PageTemplate type="signup">
            {emailTaken && <Error message="This email is already used in our application. Try with a different one" />}
            {failed && (
                <Error message="Something went wrong and we couldn't create an account. Please contact support." />
            )}
            {authError && <Error message={authError} />}
            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        autoFocus={true}
                        label="Email Address"
                        name="email"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        name="password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>
                <PasswordStrengthMeter password={password} />
                <div className="mb-3 pl-2">
                    <ConfirmCheckbox />
                </div>
                <SubmitButton type="submit" className="btn-block mb-2 shadow-sm border-0 py-2">
                    Sign up
                </SubmitButton>
                <Container className="mt-3">
                    <Row className="d-flex">
                        <Col className="pr-3 text-right">
                            <Link to="/login">Already have an account? Sign In</Link>
                        </Col>
                    </Row>
                </Container>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={
                        process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_ID || '6LcwWMoZAAAAALA3q2Ssl3djasxMYK0LbdwGEvyV'
                    }
                    size="invisible"
                />
            </Form>

            <hr className="my-4" />

            <SSOGoogleHref
                onClick={onGoogleBtnClick}
                href={`${getUrl('app', 8090)}/sso/login-google?state=signup`}
                className="shadow-sm"
            >
                Sign up with Google
            </SSOGoogleHref>
        </PageTemplate>
    );
};

SignUp.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'signupForm',
    validate: validateSchema(formSchema),
    asyncValidate,
    asyncBlurFields: ['email'],
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(SignUp);
