import { call, put, takeLatest, select, fork, all } from 'redux-saga/effects';
import moment from 'moment';
import { contains, find, isUndefined } from 'underscore';
import * as actionTypes from 'actions/actionTypes';
import { prepareStatsRequestData, prepareTableRequestData } from 'modules/request/services/StatisticService';
import { isSingleFlow, isRequestResourceFlow, isGridFlow, isVacationFlow } from 'modules/request/utils/dataFlowUtil';
import { isActive } from 'utils/extensionUtil';
import { RESOURCE_REQUEST, VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { calculateApprovalDates } from 'modules/request/utils/requestUtil';
import { getAll as getAllBookings, bulkUpdateBookings } from 'api/booking';
import { getRequestData, getRequestResourceData } from 'api/report';
import { hideModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import { updateSubmenuIconContent } from 'actions/menu/content';
import {
    updateStatistics,
    updateSidebarStatistics,
    updateRequestModel,
    rejectRequestsSuccess,
} from 'actions/requestActions';
import {
    getCompanyExtensions,
    getVacationYearStartDate,
    selectCompanyAvgDailyCapacity,
    selectIsToilExtensionInstalled,
} from 'selectors/company';
import { getDefaultNotificationErrorHandler, handleErrors } from './helpers/errorHandler';
import { overscheduleHandler } from './helpers/overschedule';
import { REJECTED } from '../enums/bookingTypeEnum';
import { monitoring } from '../monitoring';
import { toFixedTrimmed } from '@hub-mono/utils';

const getRequestReducer = state => state.requestReducer;
const getMenuContent = state => state.menuContent;

function* handleStatistics(action) {
    try {
        if (false === action.payload.refreshStatistics) {
            return;
        }

        const requestReducer = yield select(getRequestReducer);
        const requestModel = requestReducer.get('requestModel').toJSON();

        const reportObject = prepareStatsRequestData(requestModel);
        let [reportResponse, requestBookings, resources] = yield all([
            call(getRequestData, reportObject),
            !isGridFlow(requestModel.flow)
                ? call(getAllBookings, { ...prepareTableRequestData(requestModel), request: 1 })
                : null,
            isGridFlow(requestModel.flow) ? call(getRequestResourceData, reportObject) : null,
        ]);

        yield put(updateStatistics(reportResponse, requestBookings, resources));
        if (-1 !== requestModel.flow.indexOf('FLOW_SINGLE')) {
            reportResponse = undefined;
        }

        yield fork(handleSidebarStatistics, {
            flow: requestModel.flow,
            subMenuVisibility: true,
            formattedData: reportResponse,
        });
    } catch (error) {
        monitoring.captureException(error);
        console.log(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load your data, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleSidebarStatistics(action) {
    const isToilExtensionInstalled = yield select(selectIsToilExtensionInstalled);
    const companyAvgDailyCapacity = yield select(selectCompanyAvgDailyCapacity);

    try {
        let { subMenuVisibility, flow, id, formattedData } = action;
        if (!subMenuVisibility || !flow || (id && isSingleFlow(flow))) {
            return;
        }
        if (contains(['VACATION_FLOW_SINGLE', 'VACATION_GRID'], flow)) {
            flow = 'VACATION_FLOW';
        } else if (contains(['RES_REQUEST_FLOW_SINGLE', 'REQUEST_GRID'], flow)) {
            flow = 'APPROVAL_FLOW';
        }

        if (!id) {
            while (isUndefined(id)) {
                const menuContent = yield select(getMenuContent);
                const menu = find(menuContent.content, item => item.flow === flow);
                id = menu?.id;
            }
        }

        if (!formattedData || -1 !== flow.indexOf('FLOW_SINGLE')) {
            const requestReducer = yield select(getRequestReducer);
            const requestReducerJson = requestReducer.toJSON();
            const companyExtensions = yield select(getCompanyExtensions);
            const vacationYearStartDate = yield select(getVacationYearStartDate);
            const requestModel = requestReducerJson.requestModel;
            const dates = getRequestDates(requestModel, vacationYearStartDate, flow);

            const hasVacationRequestRights = isVacationFlow(flow) && isActive(companyExtensions, VACATION_REQUEST);
            const hasResourceRequestRights =
                isRequestResourceFlow(flow) && isActive(companyExtensions, RESOURCE_REQUEST);
            if (hasResourceRequestRights || hasVacationRequestRights) {
                const reportObject = prepareStatsRequestData({ ...requestModel, flow, ...dates });
                formattedData = yield call(getRequestData, reportObject);
            }
        }

        if (formattedData) {
            const shouldCountToil = isToilExtensionInstalled;
            const { totalRequested, totalApproved, totalRejected } = (() => {
                const totalRequested = shouldCountToil
                    ? formattedData.total.WAITING_FOR_APPROVAL +
                      (formattedData.toil?.total?.WAITING_FOR_APPROVAL ?? 0) / companyAvgDailyCapacity
                    : formattedData.total.WAITING_FOR_APPROVAL;
                const totalApproved = shouldCountToil
                    ? formattedData.total.APPROVED +
                      (formattedData.toil?.total?.APPROVED ?? 0) / companyAvgDailyCapacity
                    : formattedData.total.APPROVED;
                const totalRejected = shouldCountToil
                    ? formattedData.total.REJECTED +
                      (formattedData.toil?.total?.REJECTED ?? 0) / companyAvgDailyCapacity
                    : formattedData.total.REJECTED;

                const returnValue = { totalRequested, totalApproved, totalRejected };
                return Object.keys(returnValue).reduce((acc, key) => {
                    const value = returnValue[key];
                    acc[key] = value === undefined || Number.isNaN(value) ? '-' : toFixedTrimmed(value, 2);
                    return acc;
                }, {});
            })();

            const data = {
                WAITING_FOR_APPROVAL: totalRequested,
                APPROVED: totalApproved,
                REJECTED: totalRejected,
            };
            yield put(updateSidebarStatistics(data));
            yield put(updateSubmenuIconContent(flow, id, data));
        }
    } catch (error) {
        monitoring.captureException(error);
        console.log('error', error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load your data, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleApproveOrRejectRequest(action) {
    try {
        yield call(bulkUpdateBookings, {
            bookings: action.payload.bookings,
            options: {
                allowOverschedule: true,
                ...(action.payload.options || {}),
            },
        });

        yield put(
            rejectRequestsSuccess({
                bookingIds: (action.payload.bookings ?? [])
                    .filter(booking => booking.type === REJECTED.value)
                    .map(booking => booking._id),
            })
        );
        yield put(updateRequestModel());
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(true),
            getDefaultNotificationErrorHandler(error?.data?.code, "Bookings couldn't be updated")
        );
    }
}

function getRequestDates(requestModel, vacationYear, flow) {
    return isRequestResourceFlow(flow)
        ? {
              ...calculateApprovalDates(
                  moment
                      .utc(requestModel.start, 'YYYY-MM-DD')
                      .month(0)
                      .toDate()
              ),
          }
        : {
              ...calculateApprovalDates(
                  moment
                      .utc(requestModel.start, 'YYYY-MM-DD')
                      .month(vacationYear)
                      .toDate()
              ),
          };
}

export default function* requestWatcher() {
    yield takeLatest(actionTypes.UPDATE_REQUEST_MODEL, handleStatistics);
    yield takeLatest('CHANGE_SUBMENU_VISIBILITY', handleSidebarStatistics);
    yield takeLatest(actionTypes.APPROVE_REJECT_REQUEST, handleApproveOrRejectRequest);
}
