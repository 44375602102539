import { useParams, useRouteMatch } from 'react-router-dom';

export const useParamGroupId = () => {
    const params = useParams();

    const paramGroupId = params.resourceGroupId || params.projectGroupId;

    const matchResource = useRouteMatch({
        path: '/resource/:resourceId?/group/:resourceGroupId',
        strict: true,
        sensitive: true,
    });

    const matchProject = useRouteMatch({
        path: '/project/:projectId?/group/:projectGroupId',
        strict: true,
        sensitive: true,
    });

    return paramGroupId || matchResource?.params.resourceGroupId || matchProject?.params.projectGroupId;
};
