import React from 'react';
import { FormControlLabel, Switch as MUISwitch, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    main: {
        marginBottom: '7px',
        fontSize: '13px',
    },
    formControLabel: {
        marginRight: '-5px',
        marginBottom: '3px',
    },
});

export const Switch = React.memo(props => {
    const { label, labels, disabled } = props;
    const classes = useStyles();

    const { formControLabel: formControLabelClass, main } = classes;

    return disabled ? (
        <Tooltip
            title="You need to first fill out the required fields on the left to enable this option."
            placement="top"
            arrow
        >
            <div className={main}>
                <span className="mr-2">{labels && labels[0]}</span>
                <FormControlLabel className={formControLabelClass} control={<MUISwitch {...props} />} label={label} />
                <span>{labels && labels[1]}</span>
            </div>
        </Tooltip>
    ) : (
        <div className={main}>
            <span className="mr-2">{labels && labels[0]}</span>
            <FormControlLabel className={formControLabelClass} control={<MUISwitch {...props} />} label={label} />
            <span>{labels && labels[1]}</span>
        </div>
    );
});

//  The rest, same as MUI Switch
Switch.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string),
};

export default Switch;
