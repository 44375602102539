import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';

export default styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .Mui-disabled .MuiSwitch-thumb': {
        backgroundColor: '#d5d5d5',
        width: 24,
        height: 24,
        marginTop: -3,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="2 1 19 23"><path fill="${encodeURIComponent(
                '#fff'
            )}" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>')`,
        },
    },
}));
