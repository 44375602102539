import { addHours } from 'date-fns';
import { store } from '../../../store';
import { updateAccountNotifications } from '../../../actions/accountActions';

const dayHoursNumber = 24;

const twoDaysHoursNumber = dayHoursNumber * 2;
const weekHoursNumber = dayHoursNumber * 7;

const updateAccountNotificationsWithUpdatedNotification = updatedNotification => {
    if (!updatedNotification) {
        return;
    }

    const accountPreferences = store.getState().account.preferences;
    const curentNotificationsWithoutUpdated = accountPreferences.notifications.filter(currentNotification => {
        return !(
            currentNotification.channel === updatedNotification.channel &&
            currentNotification.category === updatedNotification.category
        );
    });
    const newNotifications = [...curentNotificationsWithoutUpdated, updatedNotification];
    const notificationDetails = accountPreferences.notificationDetails;

    store.dispatch(updateAccountNotifications.request(newNotifications, notificationDetails, true));
};

// Reusable by other tips configs
const closeActionsTitles = {
    SKIP: 'Skip this tip',
    REMIND_TOMORROW: 'Remind me tomorrow',
    SNOOZE_48: 'Snooze for 48 hours',
    SNOOZE_WEEK: 'Snooze for week',
};

export const closeActions = [
    {
        title: closeActionsTitles.SKIP,
        action: notification => {
            updateAccountNotificationsWithUpdatedNotification({
                ...notification,
                enabled: false,
                modifiedDate: new Date().toISOString(),
            });
        },
    },
    {
        title: closeActionsTitles.REMIND_TOMORROW,
        action: notification => {
            updateAccountNotificationsWithUpdatedNotification({
                ...notification,
                enabled: true,
                snoozedUntilDate: addHours(new Date(), dayHoursNumber).toISOString(),
                modifiedDate: new Date().toISOString(),
            });
        },
    },
    {
        title: closeActionsTitles.SNOOZE_48,
        action: notification => {
            updateAccountNotificationsWithUpdatedNotification({
                ...notification,
                enabled: true,
                snoozedUntilDate: addHours(new Date(), twoDaysHoursNumber).toISOString(),
                modifiedDate: new Date().toISOString(),
            });
        },
    },
    {
        title: closeActionsTitles.SNOOZE_WEEK,
        action: notification => {
            updateAccountNotificationsWithUpdatedNotification({
                ...notification,
                enabled: true,
                snoozedUntilDate: addHours(new Date(), weekHoursNumber).toISOString(),
                modifiedDate: new Date().toISOString(),
            });
        },
    },
];

export const onFinish = notification => {
    updateAccountNotificationsWithUpdatedNotification({
        ...notification,
        enabled: false,
        modifiedDate: new Date().toISOString(),
    });
};
