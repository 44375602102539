import styled, { css } from 'styled-components';

export const Tag = styled.span`
    font-size: 11px;
`;

export const TagBackground = styled.span`
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 0;
    border-radius: 20px;
    left: 0;
    top: 0;
    z-index: 0;

    ${props => props.color === 'green' && css`
        background: #EAF8F2;
        border: 1px solid #ADDBC8;
    `}

    ${props => props.color === 'blue' && css`
        background: #e6f3ff;
        border: 1px solid #93caf7;
    `}

    ${props => props.color === 'purple' && css`
        background: #f4f2fd;
        border: 1px solid #d9cbf6;
    `}
`;

export const Label = styled.span`
    position: relative;
    z-index: 100;

    ${props => props.color === 'green' && css`
        color: #274739;
    `}

    ${props => props.color === 'blue' && css`
        color: #20639a;
    `}

    ${props => props.color === 'purple' && css`
        color: #383838;
    `}
`;

export const IconWrapper = styled.span`
    display: inline-flex;
    vertical-align: middle;
    margin-right: 5px;
    color: #bce9ff;
`;
