import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import scheduleRights, { hasRightsForProjectAndResource } from 'modules/scheduler/services/scheduleRights';
import { selectCurrentSelectedBooking } from '../../../selectors/scheduler';
import { getDisplayArchivedInScheduler } from '../../../selectors/company';

export const useScheduleRights = (rowTags) => {
    const {
        resourceId,
        isProjectManager,
        resourceRoleRights,
        resourceRole,
        preferences: {
            grid: { mode },
        },
    } = useSelector(state => state.account);
    const isParentMode = PARENT.value === mode;
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const currentSelectionRowTags = useSelector(state => state.scheduler.currentSelection.rowTags);
    const companyExtensions = useSelector(state => state.companyReducer.company.extensions);
    const displayArchivedBookings = useSelector(getDisplayArchivedInScheduler);

    const scheduleRightsFn = useMemo(
        () => scheduleRights({ isProjectManager, resourceRoleRights, resourceId, resourceRole }, companyExtensions),
        [isProjectManager, resourceRoleRights, resourceId, resourceRole, companyExtensions]
    );

    const scheduleRightsForResourceAndProjectFn = useCallback(
        (projectId, rowResourceId, isUnassigned, {project, resource} = {}) =>
            hasRightsForProjectAndResource(
                { isProjectManager, resourceRoleRights, resourceId, resourceRole },
                companyExtensions
            )(projectId, rowResourceId, isUnassigned, displayArchivedBookings, {project, resource}),
        [isProjectManager, resourceRoleRights, resourceId, resourceRole, companyExtensions, displayArchivedBookings]
    );

    return {
        scheduleRightsFn,
        hasScheduleRightsForResourceAndProject: scheduleRightsForResourceAndProjectFn,
        hasScheduleRights: scheduleRightsFn(isParentMode, currentSelectionRowTags || rowTags, currentSelectedBooking),
    };
};
