import styled from 'styled-components';
import MaterialSlider from '@material-ui/core/Slider';
import MaterialInput from '@material-ui/core/Input';

export const Input = styled(MaterialInput)`
    margin-bottom: 20px;
    margin-top: 3rem !important;
    & input {
      text-align: center;
    }
`;

export const Slider = styled(MaterialSlider)`
    .MuiSlider-valueLabel {
        left: auto;
    }
`;
