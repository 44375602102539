/* eslint-env browser */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { createCySelector } from '../../../../../utils/cypressUtil';
import { IconButton } from '@hub-mono/ui';

class PrintButton extends PureComponent {
    static propTypes = {
        disabled: PropTypes.bool,
        color: PropTypes.string,
        wrapperClass: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        color: 'secondary',
        wrapperClass: '',
    };

    constructor(props) {
        super(props);
        this.state = { print: false };
    }

    print = () => {
        this.setState(state => ({ print: !state.print }));
    };

    render() {
        const title = 'Print';
        return (
            <span className={this.props.wrapperClass}>
                <ReactToPrint
                    trigger={() => (
                        <IconButton icon="print" disabled={this.props.disabled} dataCy={createCySelector(title)} />
                    )}
                    content={() => this.componentRef}
                    pageStyle={{ margin: '20px' }}
                    onBeforePrint={this.print}
                    onAfterPrint={this.print}
                />
                <div id="printable" ref={el => (this.componentRef = el)}>
                    {this.props.children && (
                        <div>
                            <h4 className="text-center">HUB Planner - Request</h4>
                            {this.props.children}
                        </div>
                    )}
                </div>
            </span>
        );
    }
}

export default PrintButton;
