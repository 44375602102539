import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { emptyStringValidator, negativeNumberValidator } from 'utils/formValidators';
import { first } from 'underscore';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
            validators: [emptyStringValidator],
        },
        gridColor: {
            type: String,
            defaultValue: '#349E71',
        },
        state: {
            type: String,
        },
        percentage: {
            type: Number,
            defaultValue: 100,
            required: true,
            validators: [negativeNumberValidator],
        },
        hours: {
            type: Number,
            required: true,
            validators: [negativeNumberValidator],
        },
        total: {
            type: Number,
            required: true,
            validators: [negativeNumberValidator],
        },
        isDefault: {
            type: Boolean,
            defaultValue: false,
        },
        categoryGroup: {
            type: Object,
            defaultValue: {},
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = (allocationDefaultSettings, groupId, categoryGroups, initialName) => {
    const values = Object.keys(allocationDefaultSettings.values).reduce((acc, key) => {
        acc[key] = allocationDefaultSettings.values[key] || 0;
        return acc;
    }, {});

    return {
        ...formSchema.getDefaultValues(),
        state: allocationDefaultSettings.state,
        ...values,
        name: initialName || formSchema.getDefaultValues().name,
        categoryGroup:
            !groupId && categoryGroups && categoryGroups.length
                ? first(categoryGroups)
                : groupId
                ? { _id: groupId }
                : formSchema.getDefaultValues().categoryGroup,
    };
};
