import { HOURS_DAY, PERCENTAGE, TOTAL_HOURS } from 'enums/allocationTypeEnum';
import { STATUS_ARCHIVED } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { includes } from 'lodash';
import Event from 'modules/scheduler/components/scheduler/event';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { formatNumber } from 'utils/formatingUtil';
import { store } from '../../../../../store';
import { disableBookingLogic } from './helper';
import {
    selectIsDeadlinesExtensionInstalled,
    selectIsDependencyExtensionInstalled, selectKeepBookingCustomColor,
} from '../../../../../selectors/company';
import {
    isAbleToApproveRejectVacationOrRequest,
    isAbleToEditResourceOrVacationRequest
} from 'modules/request/utils/permissions';
import { monitoring } from '../../../../../monitoring';

export const handleBookingEvent = (args, data) => {
    if (!args.e.id) {
        return;
    }

    if (!args.e.resource && args.e.resourceInfo?._id) {
        args.e.resource = args.e.resourceInfo?._id;
    }

    const state = store.getState();
    const isDependencyExtensionInstalled = selectIsDependencyExtensionInstalled(state);
    const isDeadlinesExtensionInstalled = selectIsDeadlinesExtensionInstalled(state);
    const keepBookingCustomColor = selectKeepBookingCustomColor(state);

    try {
        args.e.tags = {};
        const [
            schedulerRef,
            resourceRoleRights,
            companySettings,
            keyWords,
            projectId,
            viewObject = {},
            hasEventRights,
            vacationId,
            currentResourceId,
            hasScheduleRightsForResourceAndProject
        ] = data;
        const scheduler = schedulerRef.current.control;
        const loggedInRights = hasEventRights(args.e);
        const categoryColorDisplay = companySettings.grid.catColorDisplayNew;
        const category = args.e.categoryTemplate;
        const categoryName = category && category.name ? ` - ${category.name}` : '';
        const categoryColor = category ? category.gridColor : '#ccc';
        const totalEventTime = getTotalTime(args.e);

        const isAbleToEditRequest = isAbleToEditResourceOrVacationRequest(
            args.data,
            currentResourceId,
            resourceRoleRights,
            vacationId
        );
        
        const isAbleToApproveRejectRequest = isAbleToApproveRejectVacationOrRequest(
            [args.data].filter(Boolean),
            currentResourceId,
            resourceRoleRights,
            vacationId
        );

        args.data.barHidden =
            'CATEGORY_COLOR_LINE' !== categoryColorDisplay && args.e.project.status !== STATUS_ARCHIVED.value;
        args.data.barColor = categoryColor;
        // Add id as a class to be found in dom easily
        args.e.cssClass = `cat-color-enabled event-id-${
            args.data.id
        } hub-mode-${scheduler.eventHeightMode.toLowerCase()}`;
        const row = scheduler.rows.find(args.e.resource);

        disableBookingLogic({ args, scheduler, row, currentResourceId, resourceRoleRights, vacationId });

        //handle archived color and text
        if (args.e.project.status === STATUS_ARCHIVED.value) {
            args.e.text = `${args.e.text} (Archived ${keyWords.projectKeyWord})`;

            if(keepBookingCustomColor === 'GREY_OUT') {
                args.e.cssClass = 'fc-event-other'; // archived ignore color on old master before rs, so we needed to implement the same here.The class should set the color on the bookings.
            }
        }

        if (args.e.private || !args.e.isPermitted) {
            args.e.categoryName = '';
        }

        if (projectId && projectId !== args.e.project._id) {
            args.e.cssClass = 'fc-event-other hub-mode-' + scheduler.eventHeightMode.toLowerCase();
        } else if (!projectId && scheduler.mode === 'LEGACY') {
            // Group with projects filters applied
            const schedulerReduxState = state.scheduler;
            const projectsIdsAppliedOnScheduler = [
                ...(schedulerReduxState.filters?.projects?.filters || []),
                ...(schedulerReduxState.groupFilters?.projects?.filters || []),
            ];

            if (
                projectsIdsAppliedOnScheduler.length > 0 &&
                !projectsIdsAppliedOnScheduler.includes(args.e.project._id)
            ) {
                args.e.cssClass = 'fc-event-other hub-mode-' + scheduler.eventHeightMode.toLowerCase();
            }
        }

        args.e.toolTip = `${args.e.text} - ${totalEventTime}${categoryName} Category`;
        args.e.tags.renderedTextBottom = getRenderedText(
            args.e,
            scheduler.mode,
            scheduler.eventHeightMode,
            totalEventTime,
            categoryName
        );
        args.data.bubbleTags = {
            loggedInRights,
            totalEventTime,
            keyWords,
        };

        args.e.html = renderToString(
            <Event
                args={args}
                isDependencyExtensionInstalled={isDependencyExtensionInstalled}
                isDeadlinesExtensionInstalled={isDeadlinesExtensionInstalled}
                hasScheduleRightsForResourceAndProject={hasScheduleRightsForResourceAndProject}
                booking={args.e}
                eventHeightMode={scheduler.eventHeightMode}
                loggedInRights={loggedInRights}
                mode={scheduler.mode}
                viewObject={viewObject}
                totalEventTime={totalEventTime}
                isAbleToEditRequest={isAbleToEditRequest}
                isAbleToApproveRejectRequest={isAbleToApproveRejectRequest}
            />
        );
    } catch (error) {
        monitoring.captureException(error);
        console.log('Error on event rendering: ', error);
    }
};

export const getTotalTime = event => {
    if (!event.id) {
        return ``;
    }

    let totalEventTime;
    if (HOURS_DAY.state === event.state) {
        const minutes = event.allDay
            ? event.minutesPerDay
            : event?.minutesPerDayWithCustomAvailability?.[0]?.utilizationMinutes ?? event.details.bookedMinutes;

        totalEventTime = formatNumber(minutes / 60) + ' hrs/day for ' + event.details.workDaysCount + ' days';
    } else if (TOTAL_HOURS.state === event.state) {
        totalEventTime =
            formatNumber(event.totalBucketMinutesAllocation / 60) +
            ' total hours for ' +
            event.details.workDaysCount +
            ' days';
    } else if (PERCENTAGE.state === event.state) {
        totalEventTime = `${event.percentAllocation}% for ${event.details.workDaysCount} days`;
    } else {
        totalEventTime = `${formatNumber(event.minutesPerDay / 60)} hrs/day for ${event.details.workDaysCount} days`;
    }

    return totalEventTime;
};

const getRenderedText = (booking, mode, eventHeightMode, totalEventTime, categoryName) => {
    let renderedTextBottom = '';

    if (includes(['L', 'XL'], eventHeightMode)) {
        if (mode === PARENT.value && TYPE_UNASSIGNED.value !== booking.resourceInfo.type) {
            renderedTextBottom = `${booking.text} ${categoryName || ''}`;
        } else {
            renderedTextBottom = `${totalEventTime} ${categoryName || ''}`;
        }
    }

    return renderedTextBottom;
};
