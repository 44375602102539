/* eslint-env browser */
import React from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Background, Wrapper, H1, Text, Main } from './styles';

const NotFound = () => (
    <Background>
        <Main>
            <Wrapper className="text-center p-3">
                <img src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png" alt="logo" />
                <H1>404</H1>
                <Text>Woops, looks like there is no page here!</Text>
            </Wrapper>
            <div className="text-center">
                <Button color="dark" className="mr-2 rounded-0" onClick={() => history.go(-1)}>
                    <i className="fa fa-reply" /> Back
                </Button>
                <Link className="btn btn-secondary rounded-0" to="/">
                    <i className="fa fa-home" /> Hub Planner Home
                </Link>
            </div>
        </Main>
    </Background>
);

export default NotFound;
