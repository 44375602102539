import React, { useState, useEffect, useMemo } from 'react';
import TipPopup from 'shared/components/TipPopup';
import { useSelector, useDispatch } from 'react-redux';
import { TIPS_FOR_NEW_CUSTOMERS, TIPS_FOR_EXISTING_CUSTOMERS } from 'enums/notificationTypeEnum';
import { IN_APP } from 'enums/notificationChannelType';
import { updateAccountPreferences } from 'actions/accountActions';
import Switch from 'shared/switch';
import { NEW, LEGACY } from 'enums/contextMenuEnum';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { arrayToObjectByKey } from '../../utils/mappingUtil';
import { hasOneOfRoles, hasRole } from '../../utils/rightsUtil';
import { closeActions, onFinish } from './tips/common';
import { TIPS_TOIL } from './tips/toil';

// Re-check every 30 minutes. Some tips may be available now
const reCheckTime = 30 * 60 * 1000;

const launchDateOfReactScheduler = new Date(2023, 0, 28);

const TIPS_FOR_NEW_CUSTOMERS_CONFIG = {
    title: 'Create a booking.',
    subTitle: 'Drag the scheduler to highlight the area and make a booking.',
    closeActions,
    slides: [
        {
            title: 'Create your first booking',
            subTitle: 'Create your first booking simply by selecting a date range on the scheduler.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-modern-menu.mp4',
        },
        {
            title: 'Add a team member to your account',
            subTitle: 'Let’s show you how to quickly add and invite a new resource to your team in Hub Planner.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-new-resource.mp4',
        },
        {
            title: 'Create a new project',
            subTitle: 'Let’s show you how to quickly add a new project in Hub Planner.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-new-project.mp4',
        },
        {
            title: 'Build your own group',
            subTitle: 'Build dynamic project groups and resource teams in only a few clicks via the scheduler builder',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-builder.mp4',
        },
        {
            title: 'Change scheduler view mode',
            subTitle:
                'Click the tv icon to switch between scheduler row layouts, toggle the different view options between projects and resources.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-group-mode.mp4',
        },
        {
            title: 'Customize your preferences',
            subTitle: 'Use the sidebar to configure all the preferences you need to customize your experience.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-preferences.mp4',
        },
    ],
    onFinish,
};

const SwitchChangeMenuType = () => {
    const { gridPreferences } = useAccountPreferences();
    const dispatch = useDispatch();

    const { contextMenu } = gridPreferences;

    const checked = contextMenu === NEW;
    const toggleContextMenu = () => {
        dispatch(
            updateAccountPreferences.request({
                grid: {
                    ...gridPreferences,
                    contextMenu: checked ? LEGACY : NEW,
                },
            })
        );
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={toggleContextMenu}
                    color="primary"
                    inputProps={{
                        'data-cy': 'switch-modern-menu-tip',
                    }}
                />
            }
            label="Enable modern menu"
        />
    );
};

const NEW_FEATURES_TIPS_CONFIG = {
    TIPS_TOIL_POD: TIPS_TOIL,
};

const TIPS_FOR_EXISTING_CUSTOMERS_CONFIG = {
    title: 'Create a booking.',
    subTitle: 'Drag the scheduler to highlight the area and make a booking.',
    closeActions,
    slides: [
        {
            title: 'New Modern Menu',
            subTitle: 'Introducing a new menu design menu making it easier than ever to schedule your team and project',
            upperContent: (
                <div className="mb-2">
                    <SwitchChangeMenuType />
                </div>
            ),
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-modern-menu.mp4',
        },
        {
            title: 'New Capacity Finder',
            subTitle: 'Find out who is available in seconds with the new Capacity Finder extension.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-capacity-finder.mp4',
        },
        {
            title: 'New Scheduler Builder',
            subTitle:
                'Easily build dynamic project groups and resource teams in only a few clicks via our new builder.',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-builder.mp4',
        },
        {
            title: 'New Smart Schedules',
            subTitle: 'Build and save customized project and resource schedules',
            videoUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/rs-smart-schedules.mp4',
        },
    ],
    onFinish,
};

const tipsConfigsMap = {
    [TIPS_FOR_NEW_CUSTOMERS]: TIPS_FOR_NEW_CUSTOMERS_CONFIG,
    [TIPS_FOR_EXISTING_CUSTOMERS]: TIPS_FOR_EXISTING_CUSTOMERS_CONFIG,
    ...NEW_FEATURES_TIPS_CONFIG,
};

const knownHandledTipsNotifications = Object.keys(tipsConfigsMap);

const getTipsConfigsForNotifications = notifications => {
    return notifications.reduce((tipsConfigs, notification) => {
        if (knownHandledTipsNotifications.includes(notification.category)) {
            return [
                ...tipsConfigs,
                {
                    ...tipsConfigsMap[notification.category],
                    contextData: notification,
                },
            ];
        }
        return tipsConfigs;
    }, []);
};

const TipsManager = React.memo(() => {
    // Used to reload. Some notification may be available later in time.
    const [reload, setReload] = useState(false);

    const { modals } = useSelector(state => state.modalReducer);
    // Get notifications from account level
    const account = useSelector(state => state.account);

    const isMFAModalOpen = useMemo(() => modals.some(modal => modal.modalType.includes('MFA_')), [modals]);

    const {
        preferences: { notifications: allNotifications },
        resourceCreatedDate,
    } = account;

    const allNotificationsMapped = useMemo(() => {
        return arrayToObjectByKey(allNotifications, 'category');
    }, [allNotifications]);

    useEffect(() => {
        const interval = setInterval(() => {
            setReload(!reload);
        }, reCheckTime);
        return () => {
            clearInterval(interval);
        };
    }, [reload]);

    const validInAppNotifications = useMemo(() => {
        const displayNewFeaturesRSContentToUser = new Date(resourceCreatedDate) < launchDateOfReactScheduler;

        const validInAppNotificationsTemp = (allNotifications || []).filter(notification => {
            const { category, enabled, channel, snoozedUntilDate } = notification;
            const firstCheck = channel === IN_APP && enabled && knownHandledTipsNotifications.includes(category);

            if (firstCheck) {
                if (!snoozedUntilDate) {
                    return true;
                }
                const now = new Date();
                const convertedSnoozeUntilDate = new Date(snoozedUntilDate);
                return now > convertedSnoozeUntilDate;
            }
            return false;
        });

        if (displayNewFeaturesRSContentToUser) {
            const tipNewFeatureRSPresent = (allNotifications || []).find(notification => {
                return notification.category === TIPS_FOR_EXISTING_CUSTOMERS;
            });
            if (!tipNewFeatureRSPresent) {
                validInAppNotificationsTemp.push({
                    category: TIPS_FOR_EXISTING_CUSTOMERS,
                    channel: IN_APP,
                    enabled: true,
                    modifiedDate: new Date().toISOString(),
                });
            }
        } else {
            const tipCreateBookingPresent = (allNotifications || []).find(notification => {
                return notification.category === TIPS_FOR_NEW_CUSTOMERS;
            });
            // Add if not present at all. Temporary
            if (!tipCreateBookingPresent) {
                validInAppNotificationsTemp.push({
                    category: TIPS_FOR_NEW_CUSTOMERS,
                    channel: IN_APP,
                    enabled: true,
                    modifiedDate: new Date().toISOString(),
                });
            }
        }

        Object.entries(NEW_FEATURES_TIPS_CONFIG).forEach(([featureName, featureConfig]) => {
            const featureNotificationPresent = allNotificationsMapped[featureName];

            const canSeeNotificationByRole = (() => {
                if (!featureConfig.requiredRole || !featureConfig.requiredRole.length) {
                    return true;
                } else if (Array.isArray(featureConfig.requiredRole)) {
                    return hasOneOfRoles(account.resourceRoleRights, featureConfig.requiredRole);
                } else {
                    return hasRole(account.resourceRoleRights, featureConfig.requiredRole);
                }
            })();
            const canSeeNotificationByDate = new Date(resourceCreatedDate) < featureConfig.releaseDate;

            if (!featureNotificationPresent && canSeeNotificationByRole && canSeeNotificationByDate) {
                validInAppNotificationsTemp.push({
                    category: featureName,
                    channel: IN_APP,
                    enabled: true,
                    modifiedDate: new Date().toISOString(),
                });
            }
        });

        return validInAppNotificationsTemp;
    }, [account.resourceRoleRights, allNotifications, allNotificationsMapped, resourceCreatedDate]);

    const availableTipsConfigs = useMemo(() => getTipsConfigsForNotifications(validInAppNotifications), [
        validInAppNotifications,
    ]);

    if (
        !window.location.hash.includes('#/startTour') &&
        !isMFAModalOpen &&
        availableTipsConfigs &&
        availableTipsConfigs.length > 0
    ) {
        const currentTipToShowConfig = availableTipsConfigs[0];
        return <TipPopup {...currentTipToShowConfig} />;
    }

    return null;
});

export default TipsManager;
