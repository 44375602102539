import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import StatusFilterBox from 'modules/report/containers/tableFilters/statusFilterBox';
import { Header, Status } from './../styles';
import { UTILIZATION_STATUSES_ENUM, statusesList } from 'modules/report/enums/utilizationStatusEnum';
import {
    displayDateState,
    displayStatusByType,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'utilizationStatusBetween',
        sortable: false,
        show: contains(columnsToLoad, 'utilizationStatusBetween'),
        accessor: row => displayValue(row.utilizationStatusBetween),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'utilizationStatusPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'utilizationStatusPrevious'),
        accessor: row => displayValue(row.utilizationStatusPrevious),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'utilizationStatusAfter',
        sortable: false,
        show: contains(columnsToLoad, 'utilizationStatusAfter'),
        accessor: row => displayValue(row.utilizationStatusAfter),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
];
