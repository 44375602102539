import Schema from 'form-schema-validation';
import moment from 'moment';
import linksAndIconsFields from './linksAndIconsFields';
import { each, extend, pluck, difference, contains } from 'underscore';
import { some, isString, isNull, keys, filter, map, mapValues, isNil } from 'lodash';
import { mapCustomFields, getMyCustomFieldsDefaultValues } from 'utils/formUtil';
import { errorMessages } from 'utils/schemaUtil';
import { formatNumber } from 'utils/formatingUtil';
import {
    maxValidator,
    dynamicRequireValidator,
    emailValidator,
    minValidator,
    hoursIntervalsOverlappingValidator,
} from 'utils/formValidators';
import { ROLE_TEAM, STATUS_ACTIVE, roles, statuses } from 'enums/resourceEnum';
import * as criterias from 'enums/criteriaEnum';
import { store } from '../store';
import { formatDate, removeUTCZuluFromDateTimestamp } from '../utils/DateUtil';
import { BOOKING_FORMAT } from '../global/enums/dateFormat';
import { daysOrder } from 'shared/components/CustomAvailability/CustomAvailability';

const daysInYear =
    moment
        .utc()
        .endOf('year')
        .diff(moment.utc().startOf('year'), 'days') + 1;

const noEmptyFieldsInRate = values => !values.find(v => !v.defaultRateId);

const ratesValidator = {
    validator: value => {
        if (value && value.external) {
            return noEmptyFieldsInRate(value.external);
        }

        if (value && value.internal) {
            return noEmptyFieldsInRate(value.internal);
        }

        return true;
    },
    errorMessage: 'All fields are required',
};

const checkIfEmailProvided = {
    validator: (value, field, model) => {
        if (value) {
            return Boolean(model.email);
        }
        return true;
    },
    errorMessage: 'To make an approver email is required',
};

export const intervalSchema = new Schema({
    start: {
        type: Number,
        require: true,
        defaultValue: 480,
    },
    end: {
        type: Number,
        require: true,
        defaultValue: 960,
    },
    _id: {
        type: Schema.optionalType(String),
        require: false,
    },
});

intervalSchema.addValidator((model, schema) => {
    if (model.end < model.start) {
        schema.setModelError('range', `End time can't be before start time`);
    }
    if (model.end === model.start) {
        schema.setModelError('range', `End time can't be the same as start time`);
    }
});

export const customAvailabilitySchema = new Schema({
    _id: {
        type: Schema.optionalType(String),
    },
    workDay: {
        type: Boolean,
    },
    name: {
        type: String,
    },
    minutes: {
        type: Schema.optionalType(Number),
    },
    legacyMinutes: {
        type: Schema.optionalType(Number),
    },
    legacy: {
        type: Schema.optionalType(Boolean),
    },
    intervals: {
        type: [intervalSchema],
    },
});

export const availabilityOverridesSchema = new Schema({
    from: {
        type: Object,
    },
    to: {
        type: Object,
    },
    intervals: {
        type: [intervalSchema],
    },
});

const validateIntervalsOverlap = (workDay, intervals) => {
    const validationResult = hoursIntervalsOverlappingValidator.validator(workDay, intervals);
    if (validationResult.length) {
        return '<strong>Overlapping Intervals</strong> It is not possible to have intervals which overlap the same time range as another interval being used. Please update your time range or remove existing.';
    }
    return false;
};

customAvailabilitySchema.addValidator((model, schema) => {
    const intervalError = validateIntervalsOverlap(model.workDay, model.intervals);
    if (intervalError) {
        schema.setModelError('overlap', intervalError);
    }
});

availabilityOverridesSchema.addValidator((model, schema) => {
    const intervalError = validateIntervalsOverlap(true, model.intervals);
    if (intervalError) {
        schema.setModelError('overlap', intervalError);
    }
});

const checkIfApprover = {
    validator: (value, field, model) => {
        if (model.isApprover) {
            return Boolean(value);
        }

        return true;
    },
    errorMessage: 'To make an approver email is required',
};

export const formSchema = new Schema(
    {
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: false,
        },
        email: {
            type: String,
            required: false,
            validators: [emailValidator, checkIfApprover],
        },
        thumb: {
            type: String,
            required: false,
            defaultValue: '/img/placeholder.png',
        },
        useResourceAvailability: {
            type: String,
            required: true,
            defaultValue: 'DEFAULT',
        },
        vacation: {
            type: String,
            required: true,
            defaultValue: 'DEFAULT',
        },
        customVacationAllocationLimit: {
            type: Number,
            validators: [
                dynamicRequireValidator('vacation', 'CUSTOM', 'customVacationAllocationLimit'),
                maxValidator(daysInYear),
                minValidator(0),
            ],
            defaultValue: 0,
        },
        sendInviteEmail: {
            type: Boolean,
            required: false,
        },
        isProjectManager: {
            type: Boolean,
            required: false,
        },
        isApprover: {
            type: Boolean,
            required: false,
            validators: [checkIfEmailProvided],
        },
        resourceRates: {
            type: Object,
            required: false,
            defaultValue: { external: [], internal: [] },
            validators: [ratesValidator],
        },
        status: {
            type: Object,
            required: true,
            defaultValue: STATUS_ACTIVE,
        },
        role: {
            type: Object,
            required: true,
            defaultValue: ROLE_TEAM,
        },
        tags: {
            type: Array,
            required: false,
        },
        projects: {
            type: Array,
            required: false,
        },
        resourceGroups: {
            type: Array,
            required: false,
        },
        note: {
            type: Object,
            required: false,
            defaultValue: { message: '', formattedNote: '' },
        },
        calendars: {
            type: Array,
            required: false,
        },
        permissions: {
            type: Object,
            required: false,
        },
        nonBillable: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        customAvailability: {
            type: [customAvailabilitySchema],
            require: false,
        },
        ...linksAndIconsFields,
    },
    errorMessages,
    false
);

export const geResourceRatesDefaultValues = resourceRates =>
    mapValues(resourceRates, resourceRate =>
        map(resourceRate, rate => ({
            ...rate,
            effectiveFrom: rate.effectiveFrom ? new Date(removeUTCZuluFromDateTimestamp(rate.effectiveFrom)) : null,
            effectiveTo: rate.effectiveTo ? new Date(removeUTCZuluFromDateTimestamp(rate.effectiveTo)) : null,
        }))
    );

export const getDefaultValues = (
    projects,
    resourceGroups,
    resource,
    calendars,
    weekDays,
    globalVacationAllocationLimit,
    { policiesMap, defaultToilPolicy }
) => {
    let currentResource = {};

    const customAvailability = week => {
        return daysOrder.map(day => {
            return {
                workDay: week[day].workDay,
                minutes: week[day].minutes,
                legacy: week[day].legacy,
                legacyMinutes: week[day].legacyMinutes,
                name: day,
                intervals: (week[day].intervals || []).map(interval => ({
                    start: interval.start,
                    end: interval.end,
                })),
            };
        });
    };
    if (keys(resource).length) {
        const {
            _id: resourceId,
            currentCompanySpecific: { customVacationAllocationLimit },
        } = resource;

        currentResource = {
            nonBillable: !resource.currentCompanySpecific.billable,
            firstName: resource.currentCompanySpecific.firstName,
            lastName: resource.currentCompanySpecific.lastName,
            email: -1 !== resource.email.indexOf('@') ? resource.email : '',
            thumb: resource.currentCompanySpecific.thumb,
            sendInviteEmail: false,
            isProjectManager: resource.currentCompanySpecific.isProjectManager,
            isApprover: resource.currentCompanySpecific.isApprover,
            resourceRates: geResourceRatesDefaultValues(resource.currentCompanySpecific.resourceRates),
            status: statuses[resource.currentCompanySpecific.status],
            role: roles[resource.currentCompanySpecific.role],
            tags: map(resource.currentCompanySpecific.tags, tag => ({ _id: tag.tagId, value: tag.value })),
            projects: filter(projects, project => contains(project.resourceIds, resourceId)),
            resourceGroups: filter(resourceGroups, resourceGroup => contains(resourceGroup.resources, resourceId)),
            customFields: getMyCustomFieldsDefaultValues(resource.currentCompanySpecific.customFields),
            customVacationAllocationLimit: !isNil(customVacationAllocationLimit)
                ? customVacationAllocationLimit
                : globalVacationAllocationLimit || 0,
            vacation: isNull(customVacationAllocationLimit) ? 'DEFAULT' : 'CUSTOM',
            useResourceAvailability: resource.currentCompanySpecific.useResourceAvailability ? 'CUSTOM' : 'DEFAULT',
            calendars: filter(calendars, calendar => contains(resource.calendarIds, calendar._id)),
            note: {
                message: resource.currentCompanySpecific.note,
                formattedNote:
                    resource.currentCompanySpecific.formattedNote.indexOf('{') === 0
                        ? JSON.parse(resource.currentCompanySpecific.formattedNote)
                        : resource.currentCompanySpecific.formattedNote,
            },
            customAvailabilityOverrides: {
                create: [],
                update: [],
                delete: [],
            },
            modifiedToilAdjustments: {},
            customAvailability: customAvailability(
                resource?.currentCompanySpecific?.useResourceAvailability
                    ? resource?.currentCompanySpecific?.customAvailabilities[0]?.weekDays
                    : weekDays
            ),
            policy: policiesMap[resource?.currentCompanySpecific?.toilPolicyId],
            ...resource.currentCompanySpecific.links,
        };
    } else {
        const defaultCalendars = (calendars || []).filter(calendar => calendar.calendarType === 'DEFAULT');
        currentResource = {
            customAvailabilityOverrides: {
                create: [],
                update: [],
                delete: [],
            },
            modifiedToilAdjustments: {},
            customAvailability: customAvailability(weekDays),
            ...currentResource,
            calendars: defaultCalendars,
            customVacationAllocationLimit: globalVacationAllocationLimit,
            policy: defaultToilPolicy,
        };
    }

    return extend({}, formSchema.getDefaultValues(), currentResource);
};

const mappingResourceStatusAndSystemResourceGroupCriteria = {
    [statuses.STATUS_ACTIVE.value]: criterias.ACTIVE,
    [statuses.STATUS_ARCHIVED.value]: criterias.ARCHIVED,
};

export const getSystemGroupIdToRemoveAfterStatusChange = (status, systemResourceGroups) => {
    return systemResourceGroups.find(
        systemResourceGroup =>
            systemResourceGroup?.criteria === mappingResourceStatusAndSystemResourceGroupCriteria[status]
    )?._id;
};

export const getSystemGroupIdToAddAfterStatusChange = (newStatus, systemResourceGroups) => {
    return systemResourceGroups.find(
        systemResourceGroup =>
            systemResourceGroup?.criteria === mappingResourceStatusAndSystemResourceGroupCriteria[newStatus]
    )?._id;
};

export const mapModifiedAdjustments = (modifiedToilAdjustments, resourceId) => {
    if (modifiedToilAdjustments && Object.keys(modifiedToilAdjustments).length) {
        const asArray = Object.values(modifiedToilAdjustments);

        return {
            delete: asArray
                .filter(adjustment => adjustment.toDelete && !adjustment.toCreate)
                .map(adjustment => adjustment._id),
            create: asArray
                .filter(adjustment => adjustment.toCreate && !adjustment.toDelete)
                .map(adjustment => ({
                    ...adjustment,
                    resourceId,
                    _id: undefined,
                    toCreate: undefined,
                    toUpdate: undefined,
                    effectiveDate: formatDate(adjustment.effectiveDate, BOOKING_FORMAT, false),
                })),
            update: asArray
                .filter(adjustment => adjustment.toUpdate && !adjustment.toCreate && !adjustment.toDelete)
                .map(adjustment => ({
                    ...adjustment,
                    resourceId,
                    toCreate: undefined,
                    toUpdate: undefined,
                    value: adjustment.value || 0,
                    effectiveDate: formatDate(adjustment.effectiveDate, BOOKING_FORMAT, false),
                })),
        };
    }

    return undefined;
};

function mapResourceRatesToBackend(rates) {
    let resourceRates = { external: [], internal: [] };
    if (!rates) {
        return resourceRates;
    }

    each(rates, (typeRates, type) => {
        each(typeRates, rate => {
            if (rate.defaultRateId._id) {
                resourceRates[type].push({
                    defaultRateId: rate.defaultRateId._id,
                    effectiveFrom: rate.effectiveFrom ? formatDate(rate.effectiveFrom, BOOKING_FORMAT, false) : null,
                    effectiveTo: rate.effectiveTo ? formatDate(rate.effectiveTo, BOOKING_FORMAT, false) : null,
                });
            } else {
                resourceRates[type].push({
                    ...rate,
                    effectiveFrom: rate.effectiveFrom ? formatDate(rate.effectiveFrom, BOOKING_FORMAT, false) : null,
                    effectiveTo: rate.effectiveTo ? formatDate(rate.effectiveTo, BOOKING_FORMAT, false) : null,
                });
            }
        });
    });

    return resourceRates;
}

export const mapFormToRequest = ({
    changedValues,
    values,
    customFieldTemplates,
    resource,
    projects,
    resourceGroups,
    thumb,
    type,
}) => {
    const resourceId = resource._id;
    const tags =
        values.tags &&
        map(values.tags, tag => ({
            tagId: tag._id,
            value: tag.value,
        }));
    const calendarIds = values.calendars && map(values.calendars, calendar => calendar._id);

    const currentResourceProjects = map(values.projects, '_id');
    const initialResourceProjects = pluck(
        filter(projects, project => contains(project.resourceIds, resourceId)),
        '_id'
    );
    const projectsAdd =
        resourceId && values.projects
            ? difference(currentResourceProjects, initialResourceProjects)
            : currentResourceProjects;
    const projectsRemove =
        resourceId && values.projects ? difference(initialResourceProjects, currentResourceProjects) : [];

    const currentResourceGroups = map(values.resourceGroups, '_id');
    const initialResourceGroups = pluck(
        filter(resourceGroups, resourceGroups => contains(resourceGroups.resources, resourceId)),
        '_id'
    );

    const resourceGroupsAdd = resourceId
        ? difference(currentResourceGroups, initialResourceGroups)
        : currentResourceGroups;
    const resourceGroupsRemove = resourceId ? difference(initialResourceGroups, currentResourceGroups) : [];

    // Find system resource groups as well.
    if (values.status?.value !== resource?.status && values.status?.value && resource?.status) {
        const systemResourceGroups = (store.getState()?.resourceGroupReducer?.resourceGroups || []).filter(
            resourceGroup => resourceGroup.groupType === 'SYSTEM'
        );

        // Remove from current system resource group
        if (mappingResourceStatusAndSystemResourceGroupCriteria[resource?.status]) {
            const systemGroupIdToRemove = getSystemGroupIdToRemoveAfterStatusChange(
                resource?.status,
                systemResourceGroups
            );
            systemGroupIdToRemove && resourceGroupsRemove.push(systemGroupIdToRemove);
        }

        // Add to other system resource group
        if (mappingResourceStatusAndSystemResourceGroupCriteria[values.status?.value]) {
            const systemGroupIdToAdd = getSystemGroupIdToAddAfterStatusChange(
                values.status?.value,
                systemResourceGroups
            );
            systemGroupIdToAdd && resourceGroupsAdd.push(systemGroupIdToAdd);
        }
    }

    const requestData = {
        sendInviteEmail: values.sendInviteEmail,
        resource: {
            email:
                isString(values.email) && '' !== values.email ? values.email : '' === values.email ? null : undefined,
            type: type || undefined,
        },
        projectsAdd,
        projectsRemove,
        resourceGroupsAdd,
        resourceGroupsRemove,
        companySpecific: {
            toilPolicyId: values.policy?._id ?? null,
            billable: !values.nonBillable,
            calendarIds,
            tags,
            thumb,
            firstName: values.firstName,
            lastName: values.lastName,
            isApprover: values.isApprover,
            resourceRates: values.resourceRates && mapResourceRatesToBackend(values.resourceRates),
            isProjectManager: values.isProjectManager,
            useResourceAvailability:
                changedValues.useResourceAvailability !== undefined || changedValues.customAvailability !== undefined
                    ? 'CUSTOM' === values.useResourceAvailability
                    : undefined,
            customVacationAllocationLimit:
                values.vacation && 'DEFAULT' !== values.vacation
                    ? formatNumber(values.customVacationAllocationLimit)
                    : null,
            role: values.role?.value,
            status: values.status?.value,
            note: values.note?.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' '),
            formattedNote:
                typeof values.note?.formattedNote === 'object'
                    ? JSON.stringify(values.note?.formattedNote)
                    : values.note?.formattedNote,
            customFields: values.customFields && mapCustomFields(values.customFields, customFieldTemplates),
            links: some(keys(values), field => -1 !== field.toLowerCase().indexOf('link'))
                ? {
                      iconLink1: values.iconLink1,
                      iconLink2: values.iconLink2,
                      iconLink3: values.iconLink3,
                      iconLink4: values.iconLink4,
                      iconLink5: values.iconLink5,
                      link1: values.link1,
                      link2: values.link2,
                      link3: values.link3,
                      link4: values.link4,
                      link5: values.link5,
                  }
                : undefined,
        },
    };

    if (
        (changedValues.useResourceAvailability !== undefined || changedValues.customAvailability !== undefined) &&
        'CUSTOM' === values.useResourceAvailability
    ) {
        requestData.companySpecific.customAvailabilities = [
            {
                weekDays: values.customAvailability.reduce((acc, weekDay) => {
                    const { name, ...rest } = weekDay;
                    acc[name] = rest;
                    return acc;
                }, {}),
            },
        ];
    }

    requestData.companySpecific.toilAdjustmentsToSave = mapModifiedAdjustments(
        values.modifiedToilAdjustments,
        resourceId
    );

    if (values.customAvailabilityOverrides) {
        requestData.companySpecific.customAvailabilityOverridesToSave = {
            create: values.customAvailabilityOverrides.create.map(el => ({
                ...el,
                from: formatDate(el.from, BOOKING_FORMAT, false),
                to: formatDate(el.to, BOOKING_FORMAT, false),
            })),
            update: values.customAvailabilityOverrides.update.map(el => ({
                ...el,
                from: formatDate(el.from, BOOKING_FORMAT, false),
                to: formatDate(el.to, BOOKING_FORMAT, false),
            })),
            delete: values.customAvailabilityOverrides.delete,
        };
    }

    return requestData;
};
