import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isValid, format } from 'date-fns';
import { isUndefined } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import locale from 'date-fns/locale/en-US';
import { useStyles } from './styles';
import { useField } from 'formik';

const DateField = props => {
    const {
        input: { value, onChange, ...restOfInputProps },
        label,
        formHelperText,
        formHelperTextClassName,
        className,
        placeholder,
        required,
        variant,
        disabled,
        meta,
        componentDataCyPrefix = 'date-filed',
        dataCy,
        ...restOfProps
    } = props;

    const weekStart = useSelector(state => state.companyReducer.company.settings.weekStartDay);
    const show = meta && !isUndefined(meta.error);
    const [isOpen, setOpen] = useState(false);
    const classes = useStyles({ variant });

    if (locale && locale.options) {
        locale.options.weekStartsOn = weekStart;
    }

    const toggle = useCallback(() => {
        !disabled && setOpen(!isOpen);
    }, [disabled, isOpen]);

    const renderDay = useCallback(
        (day, selectedDate, dayInCurrentMonth, dayComponent) => {
            if (componentDataCyPrefix && dayInCurrentMonth) {
                const dateFormatted = format(day, 'yyyy-MM-dd');
                return <span data-cy={`${componentDataCyPrefix}-selection-[${dateFormatted}]`}>{dayComponent}</span>;
            }
            return dayComponent;
        },
        [componentDataCyPrefix]
    );

    return (
        <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
            <DatePicker
                {...restOfProps}
                classes={{ root: classes.root }}
                className={classNames(className)}
                disableToolbar
                variant="inline"
                inputVariant={variant}
                format="yyyy-MM-dd"
                mask="__-__-____"
                disabled={disabled}
                open={isOpen}
                onClose={toggle}
                margin="normal"
                label={label}
                renderDay={renderDay}
                error={show}
                required={required}
                placeholder={placeholder}
                value={value && isValid(value) ? value : null}
                onChange={date => {
                    date && isValid(date) && onChange(date);
                    toggle();
                }}
                helperText={(show && meta.error) || formHelperText}
                FormHelperTextProps={{ style: { whiteSpace: 'initial' }, className: formHelperTextClassName }}
                InputProps={{
                    onClick: toggle,
                    onBlur: () => {},
                    classes: {
                        input: classNames(classes.input),
                    },
                    'data-cy': dataCy,
                }}
                InputLabelProps={{
                    className: classes.label,
                }}
                leftArrowButtonProps={{
                    'data-cy': componentDataCyPrefix ? `${componentDataCyPrefix}-left-arrow` : undefined,
                }}
                rightArrowButtonProps={{
                    'data-cy': componentDataCyPrefix ? `${componentDataCyPrefix}-right-arrow` : undefined,
                }}
                {...restOfInputProps}
            />
        </MuiPickersUtilsProvider>
    );
};

export const DateFieldFormikWrapper = props => {
    const [field, meta, helpers] = useField(props);
    const { setValue } = helpers;

    return (
        <DateField
            input={{
                ...field,
                onChange: setValue,
            }}
            {...props}
            meta={meta}
        />
    );
};

DateField.defaultProps = {
    input: {},
    meta: {},
    required: false,
    disabled: false,
    placeholder: 'DD-MM-YYYY',
    variant: 'outlined',
    className: '',
    formHelperText: '',
};

export default DateField;
