import bookingResize from './bookingResize';
import phaseResize from './phaseResize';
import { updateDeadlinesVisibility, updateVisibleLinksOnScheduler } from '../../../utils/schedulerUtil';

export default (
    confirmationModalFn,
    showInfoModalFn,
    account,
    companyStartEndTimes,
    updateBookingFn,
    updatePhaseFn,
    resourceRoleRights,
    vacationId
) => args => {
    if (!args.e.data.phase && !args.e.data.milestone && !args.e.data.datesEvent) {
        // RESIZE A BOOKING OR EVENT
        bookingResize(
            args,
            confirmationModalFn,
            showInfoModalFn,
            account,
            companyStartEndTimes,
            updateBookingFn,
            resourceRoleRights,
            vacationId
        );
    } else {
        // RESIZE A PHASE
        phaseResize(args, companyStartEndTimes, updatePhaseFn);
    }

    updateDeadlinesVisibility();
    updateVisibleLinksOnScheduler();
};
