/* eslint-env browser */
import React, { useEffect } from 'react';
import { FullScreenLayout } from './';

const PublicRoute = ({ layout: Layout, title, ...rest }) => {
    useEffect(() => {
        document.title = title || 'HUB Planner';
    }, [title]);

    if (!Layout) {
        Layout = FullScreenLayout;
    }
    return <Layout {...rest} />;
};

export default PublicRoute;
