import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AWS_S3_URL } from 'constants';

const getInitialsFromName = resource => {
    const [firstName, lastName] = `${resource?.firstName || ''} ${resource?.lastName || ''}`.split(' ');
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
};

const ResourceThumb = ({ resource, small }) => {
    const thumbExists =
        (resource.thumb && -1 === resource.thumb.indexOf('img')) ||
        (resource.thumbUrl && -1 === resource.thumbUrl.indexOf('img'));
    const customIconContent =
        !thumbExists && resource.firstName
            ? `${resource.firstName.charAt(0).toUpperCase()}${resource.lastName.charAt(0).toUpperCase()}`
            : resource.name
            ? getInitialsFromName(resource)
            : '?';

    return (
        <span className={classNames('placeholder', { thumb: thumbExists })} style={{ fontSize: small && '12px' }}>
            {thumbExists ? (
                <img
                    loading="lazy"
                    src={`${AWS_S3_URL}${resource.thumbUrl || resource.thumb}`}
                    className="w-100"
                    alt=""
                />
            ) : (
                customIconContent
            )}
        </span>
    );
};

ResourceThumb.propTypes = {
    resource: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        thumbUrl: PropTypes.string,
        thumb: PropTypes.string,
    }),
    small: PropTypes.bool,
};
ResourceThumb.defaultProps = {
    resource: {},
    small: false,
};

export default ResourceThumb;
