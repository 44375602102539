import React, {createContext, useContext, useMemo, useState} from 'react';

const defaultContextValue = {};

const Context = createContext(defaultContextValue);

const Provider = props => {
    const { children } = props;

    const [currentlyOpened, setCurrentlyOpened] = useState(undefined)

    const value = useMemo(() => ({
        currentlyOpened,
        setCurrentlyOpened
    }), [currentlyOpened]);

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const NestedMenuItemManager = React.memo(Provider);

export const useNestedMenuItemManager = () => {
    const context = useContext(Context);

    if (!context || context === defaultContextValue) {
        throw new Error('useNestedMenuItemManager should be used within NestedMenuItemManager');
    }

    return context;
};

