import { formatDate } from 'utils/DateUtil';
import { disableBookingLogic } from './helper';

export const handleDeadlineEvent = (args, data) => {
    const date = formatDate(args.e.start.toDate(), 'MMM do yyyy');

    /* eslint-disable no-unused-vars */
    const [
        schedulerRef,
        resourceRoleRights,
        companySettings,
        keyWords,
        projectId,
        viewObject = {},
        hasEventRights,
        vacationId,
        currentResourceId,
    ] = data;
    /* eslint-enable */

    const scheduler = schedulerRef.current.control;
    const row = scheduler.rows.find(args.e.resource);

    disableBookingLogic({ args, scheduler, row, currentResourceId, resourceRoleRights, vacationId });

    args.e.moveVDisabled = true;

    const hasText = Boolean(args.data.deadlineText);

    args.data.ariaLabel = args.data.deadlineText || '';
    args.data.htmlRight = `<div class="deadline-html-right">
        <span class="deadline-date">${date}</span>
        <span class="deadline-name ${hasText && 'deadline-name-text'}">${args.data.deadlineText || ''}</span>
    </div>`;
};
