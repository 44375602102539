import { DayPilot } from 'daypilot-pro-react';
import { startOfMonth, endOfMonth } from 'date-fns';
import { without, some, map, includes, filter, omit } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject, updateItemInArray } from 'reducers/helpers/updater';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { ANY, ALL } from 'modules/scheduler/enums/filterRelationEnum';
import {
    getFiltersWithAddedFilter,
    getFiltersWithoutRemovedFilter,
    optimisticUpdates,
} from 'reducers/helpers/schedulerReducerUtils';
import { INITIAL_FILTER_RELATION, INITIAL_FILTERS } from 'modules/scheduler/modals/builder/consts.js';
import { isAfter, isBefore, toUtc } from 'utils/DateUtil';
import { TABS } from '../modules/scheduler/components/footer/SidebarContext';
import { arrayToObject, arrayToObjectByKey } from '../utils/mappingUtil';

const mapDateStringToDate = filters => {
    if (!filters) {
        return filters;
    }
    if (filters.projectDates?.start?.date) {
        filters.projectDates.start.date = new Date(filters.projectDates.start.date);
    }
    if (filters.projectDates?.end?.date) {
        filters.projectDates.end.date = new Date(filters.projectDates.end.date);
    }
    if (filters.start) {
        filters.start = new Date(filters.start);
    }
    if (filters.end) {
        filters.end = new Date(filters.end);
    }

    return filters;
};

const initialFilters = {
    resources: { filters: [], operator: ANY.value },
    resourceCustomFields: { filters: [], operator: ALL.value },
    resourceTags: { filters: [], operator: ALL.value },
    resourceRoles: { filters: [], operator: ALL.value },
    resourceStatuses: { filters: [], operator: ALL.value },
    projects: { filters: [], operator: ANY.value },
    projectCustomFields: { filters: [], operator: ALL.value },
    projectTags: { filters: [], operator: ALL.value },
    customers: { filters: [], operator: ALL.value },
    currencies: { filters: [], operator: ALL.value },
    projectStatuses: { filters: [], operator: ALL.value },
    projectManagers: { filters: [], operator: ALL.value },
    bookingProjectStatuses: { filters: [], operator: ANY.value },
    bookingCategories: { filters: [], operator: ANY.value },
    bookingStatuses: { filters: [], operator: ANY.value },
    bookingProjectsEvents: { filters: [], operator: ANY.value },
    bookingResourcesUW: { filters: [], operator: ANY.value },
    resourceIsPm: undefined,
    smartFilters: {
        filters: {
            utilization: {
                operator: '',
                value: '',
            },
            availability: {
                operator: '',
                value: '',
            },
        },
        operator: ALL.value,
    },
    projectDates: {
        start: { operator: '', date: null },
        end: { operator: '', date: null },
    },
    links: {},
    deadlineLinks: {},
};

const initialSchedulerState = {
    group: {},
    isGroupView: null,
    unassignedWorkGroup: {},
    currentView: 'resource',
    isSidebarOpen: false,
    initialActiveSidebarTab: TABS.NOTE_TAB.id,
    currentSelectedBooking: {
        id: null,
        resourceInfo: {},
        project: {},
        categoryTemplate: {},
        state: '',
        name: '', // for milestones, phases
        title: '', // for regular booking
        percentAllocation: null,
        minutesPerDay: null,
        totalBucketMinutesAllocation: null,
        approvalInfo: {},
        start: new DayPilot.Date(),
        end: new DayPilot.Date(),
        repeat: false,
        repeatTimes: 0,
        links: {},
        bookingCreator: {},
        bookingUpdater: {},
    }, //selected booking
    clipboard: null, //copy/cut booking
    isCutAction: false,
    currentSelection: {
        //click on empty cell
        start: null,
        end: null,
        rowTags: null,
    },
    financial: {
        data: {},
        loading: false,
    },
    dpResources: [],
    dpChildrenResourcesById: {},
    isDPResourcesLoading: true,
    // FIX:
    // this flag is used to avoid full scheduler redraw when one dpResource (resource/project) is updated or deleted
    // scheduler is directly modified with functions like scheduler.rows.update
    // additionally this flag was introduced as well to sync rows / bookings update,
    // cause whenever dpResources from this reducer was updated scheduler.update() function was invoke what led to
    // full scheduler redraw / blink effect
    // https://hubplanner.atlassian.net/browse/HUB-8845
    redrawSchedulerFlag: true,
    initialFilters: INITIAL_FILTERS,
    initialFilterRelation: INITIAL_FILTER_RELATION,
    filterRelation: INITIAL_FILTER_RELATION,
    filters: initialFilters,
    groupFilters: initialFilters,
    schedulerInitialized: false,
    isDataSet: false,
    name: '',
    isSmart: false,
    groupType: '',
    dates: mapDateStringToDate({
        start: startOfMonth(new Date()),
        end: endOfMonth(new Date()),
        dateState: 'This',
        periodType: 'month',
    }),
    data: {
        itemIds: [],
        groupIds: [],
    },
    links: {},
    deadlineLinks: {},
};

const toggleSidebar = state =>
    updateObject(state, {
        isSidebarOpen: !state.isSidebarOpen,
    });

const openSidebar = (state, action) =>
    updateObject(state, {
        isSidebarOpen: true,
        initialActiveSidebarTab: action.payload.tabId,
    });

const closeSidebar = state =>
    updateObject(state, {
        isSidebarOpen: false,
        initialActiveSidebarTab: initialSchedulerState.initialActiveSidebarTab,
    });

const updateView = (state, action) => {
    return updateObject(state, {
        currentView: action.payload.view,
    });
};

const updateSelection = (state, action) => {
    const { currentSelection } = state;
    const { startDate, endDate, rowTags, scaleHour } = action.payload;

    let start = startDate;
    let end = endDate;

    // First time is coming DayPilot Date, also with
    if (start instanceof DayPilot.Date) {
        start = start.toDate();
        end = end && !scaleHour ? end.addMilliseconds(-1).toDate() : end.toDate();
    }

    const newCurrentSelection = {
        start: start ? toUtc(start) : null,
        end: end ? toUtc(end) : null,
        rowTags: rowTags || null,
    };

    if (
        newCurrentSelection.start === currentSelection.start &&
        newCurrentSelection.end === currentSelection.end &&
        newCurrentSelection.rowTags === currentSelection.rowTags
    ) {
        return state;
    }

    return updateObject(state, {
        currentSelection: newCurrentSelection,
    });
};

const updateClipboard = (state, action) =>
    updateObject(state, {
        clipboard: action.payload.booking || null,
        isCutAction: action.payload.isCutAction || false,
    });

const updateBookingSelection = (state, action) =>
    updateObject(state, {
        currentSelectedBooking: action.payload.booking || initialSchedulerState.currentSelectedBooking,
    });

const setSchedulerFilterItem = (state, action) => {
    const filter = action.payload.filter;

    const updatedFilters = getFiltersWithAddedFilter(state.filters, filter);

    return updateObject(state, {
        filters: updatedFilters,
    });
};

const setSchedulerFilterItems = (state, action) => {
    const { filters } = action.payload;

    const updatedFilters = filters.reduce((acc, filter) => {
        return getFiltersWithAddedFilter(acc, filter);
    }, state.filters);

    return updateObject(state, {
        filters: updatedFilters,
    });
};

const removeFilter = (state, action) => {
    const givenFilter = action.payload.filter;

    if (!givenFilter) {
        return state;
    }

    const updatedFilters = getFiltersWithoutRemovedFilter(state.filters, givenFilter);

    return updateObject(state, {
        filterRelation: state.filterRelation,
        filters: updatedFilters,
    });
};

const removeFilters = (state, action) => {
    const { filters } = action.payload;

    const updatedFilters = filters.reduce((acc, givenFilter) => {
        return getFiltersWithoutRemovedFilter(acc, givenFilter);
    }, state.filters);

    return updateObject(state, {
        filterRelation: state.filterRelation,
        filters: updatedFilters,
    });
};

const clearAppliedFilters = state => {
    return updateObject(state, {
        filters: initialFilters,
    });
};

const filterData = data => Object.fromEntries(Object.entries(data).filter(([key]) => 'group' !== key));

const clearSchedulerData = state => {
    return updateObject(state, {
        isDataSet: false,
        name: initialSchedulerState.name,
        data: filterData(initialSchedulerState.data),
        dates: initialSchedulerState.dates,
        filters: initialFilters,
        groupFilters: initialFilters,
    });
};

const builderReset = state =>
    updateObject(state, {
        ...state,
        isDataSet: false,
    });

const resetScheduler = state => {
    return updateObject(state, {
        group: {},
        isGroupView: null,
        isDataSet: false,
        schedulerInitialized: false,
        filterRelation: ANY.value,
        name: initialSchedulerState.name,
        dates: initialSchedulerState.dates,
        data: state.isDataSet ? filterData(initialSchedulerState.data) : state.data,
        filters: initialSchedulerState.filters,
    });
};

const initializeFilters = (state, action) => {
    const { filters, filterRelation } = action.payload;
    const updatedFilters = updateObject(initialFilters, filters);

    return updateObject(state, {
        filterRelation,
        filters: updatedFilters,
        initialFilters: updatedFilters,
        groupFilters: updatedFilters,
        initialFilterRelation: filterRelation,
        schedulerInitialized: true,
    });
};

const applyBuilderData = (state, action) => {
    const { filters, filterRelation, group } = action.payload;

    const updatedFilters = updateObject(state.filters, filters);

    return updateObject(state, {
        group,
        filters: updatedFilters,
        filterRelation,
    });
};

const handleGetDPResourcesRequest = state => {
    return updateObject(state, {
        isDPResourcesLoading: true,
    });
};

const setDPResources = (state, action) => {
    const { mode, dpResources, unassignedWorkGroup } = action.payload;

    if (mode === PARENT.value) {
        const mappedStateResources = arrayToObjectByKey(state.dpResources, '_id');

        return updateObject(state, {
            dpResources: dpResources.map(dpResource => {
                const stateDpResource = mappedStateResources[dpResource._id];

                if (!stateDpResource) {
                    return dpResource;
                }

                return {
                    ...dpResource,
                    expanded: stateDpResource.expanded,
                };
            }),
            unassignedWorkGroup,
            isDPResourcesLoading: false,
            redrawSchedulerFlag: true,
        });
    }

    return updateObject(state, {
        dpResources,
        unassignedWorkGroup,
        isDPResourcesLoading: false,
        redrawSchedulerFlag: true,
    });
};

const resetDPLoader = state =>
    updateObject(state, {
        isDPResourcesLoading: false,
    });

const updateDPResources = (state, action) => {
    const accountGridMode = action.payload.accountGridMode;
    const updatedItem = omit(action.payload.project || action.payload.resource, 'customers');
    const isResourceView = some(state.dpResources, resource => resource.hasOwnProperty('projects'));

    if (isResourceView && accountGridMode === PARENT.value && state.dpResources.length) {
        return updateObject(state, {
            dpResources: map(state.dpResources, resource =>
                resource.hasOwnProperty('projects')
                    ? {
                          ...resource,
                          projects: includes(updatedItem.resources, resource._id)
                              ? [...resource.projects, updatedItem._id]
                              : without(resource.projects, updatedItem._id),
                      }
                    : resource
            ),
        });
    }

    return updateObject(state, {
        dpResources: updateItemInArray(state.dpResources, updatedItem._id, item => ({
            ...item,
            ...updatedItem,
            tags: updatedItem.tags ? updatedItem.tags.map(tag => ('string' === typeof tag ? tag : tag.value)) : [],
        })),
    });
};

const optimisticUpdateDPResources = (state, action) => {
    return optimisticUpdates.reduce(
        (acc, { shouldUpdate, doUpdate }) => {
            if (!shouldUpdate(action)) {
                return acc;
            }

            return doUpdate(acc, action);
        },
        {
            ...state,
            redrawSchedulerFlag: false,
        }
    );
};

const deleteDpResource = (state, action) =>
    updateObject(state, {
        dpResources: filter(state.dpResources, rowResource => rowResource._id !== action.payload.id),
    });

const setFinancialLoader = state =>
    updateObject(state, {
        financial: updateObject(state.financial, { loading: true }),
    });

const updateFinancialData = (state, action) =>
    updateObject(state, {
        financial: updateObject(state.financial, { loading: false, data: action.payload.data }),
    });

const handleGetSchedulerGroupRequest = (state, action) => {
    return updateObject(state, {
        schedulerInitialized: false,
        isDPResourcesLoading: true,
        isGroupView: action.payload.isGroupView,
    });
};

const handleGetSchedulerGroupSuccess = (state, action) => {
    return updateObject(state, {
        group: action.payload.group,
        isGroupView: action.payload.isGroupView,
    });
};

const handleGetSchedulerGroupError = state => {
    return updateObject(state, {
        group: {},
        isGroupView: null,
    });
};

const clearSchedulerGroup = state => {
    return updateObject(state, {
        group: {},
        isGroupView: null,
    });
};

const initScheduler = state => {
    return updateObject(state, {
        isDPResourcesLoading: true,
        schedulerInitialized: false,
        group: {},
        isGroupView: null,
    });
};

const handleRemoveRow = (state, action) => {
    const { item, additionalInfo } = action.payload;
    const { dpResources, group, isGroupView } = state;

    let newDpResources;

    if (
        isGroupView &&
        (additionalInfo.resourceGroupsRemove?.includes(group?._id) ||
            additionalInfo.projectGroupsRemove?.includes(group?._id))
    ) {
        newDpResources = dpResources.filter(resource => resource._id !== item._id);
    }

    return updateObject(state, {
        redrawSchedulerFlag: false,
        dpResources: newDpResources ?? dpResources,
    });
};

const setSchedulerFilterRelation = (state, action) => {
    return updateObject(state, {
        filterRelation: action.payload.filterRelation,
    });
};

const setFilterOperator = (state, action) => {
    const { name, operator } = action.payload;
    return updateObject(state, {
        filters: {
            ...state.filters,
            [name]: {
                ...state.filters[name],
                operator,
            },
        },
    });
};

const setSchedulerBookingLinks = (state, action) => {
    const { bookings = [] } = action.payload;

    const newLinksMap = bookings.reduce(
        (acc, { id, _id, start, parentIds = [], childIds = [], deadlineDate }) => {
            const bookingId = _id || id;
            if (!bookingId) {
                return acc;
            }

            parentIds.forEach(parentId => {
                acc.links[`${parentId}-${bookingId}`] = {
                    parentId: parentId,
                    childId: bookingId,
                };
            });

            childIds.forEach(childId => {
                acc.links[`${bookingId}-${childId}`] = {
                    parentId: bookingId,
                    childId: childId,
                };
            });

            if (deadlineDate) {
                acc.deadlineLinks[`${bookingId}-${bookingId}_deadline`] = {
                    parentId: bookingId,
                    childId: `${bookingId}_deadline`,
                    type: isAfter(start, deadlineDate) ? 'StartToFinish' : 'FinishToStart',
                };
            } else {
                delete acc.deadlineLinks[`${bookingId}-${bookingId}_deadline`];
            }

            return acc;
        },
        { links: {}, deadlineLinks: state.deadlineLinks }
    );

    return {
        ...state,
        links: { ...state.links, ...newLinksMap.links },
        deadlineLinks: { ...newLinksMap.deadlineLinks },
    };
};

const addUpdateDeadlineLink = (state, action) => {
    const { booking } = action.payload;

    if (!booking) {
        return state;
    }

    const id = booking._id || booking.id;

    return {
        ...state,
        deadlineLinks: {
            ...state.deadlineLinks,
            [`${id}-${id}_deadline`]: {
                parentId: id,
                childId: `${id}_deadline`,
                type: isBefore(booking.start, booking.deadlineDate) ? 'FinishToStart' : 'StartToFinish',
            },
        },
    };
};

const addBookingLink = (state, action) => {
    const { parentId, childId } = action.payload;

    return {
        ...state,
        links: {
            ...state.links,
            [`${parentId}-${childId}`]: {
                parentId,
                childId,
            },
        },
    };
};

const deleteBookingLink = (state, action) => {
    const { parentId, childId } = action.payload;

    const newLinks = {
        ...state.links,
    };
    delete newLinks[`${parentId}-${childId}`];

    return {
        ...state,
        links: newLinks,
    };
};

const deleteLinksForBooking = (state, action) => {
    const { bookingId } = action.payload;

    const newLinks = Object.values(state.links)
        .filter(link => {
            return link.parentId !== bookingId && link.childId !== bookingId;
        })
        .reduce((acc, link) => {
            acc[`${link.parentId}-${link.childId}`] = {
                parentId: link.parentId,
                childId: link.childId,
            };
            return acc;
        }, {});

    const newDeadlineLinks = Object.values(state.deadlineLinks)
        .filter(link => {
            return link.parentId !== bookingId;
        })
        .reduce((acc, link) => {
            acc[`${link.parentId}-${link.childId}`] = {
                parentId: link.parentId,
                childId: link.childId,
            };
            return acc;
        }, {});

    return {
        ...state,
        links: newLinks,
        deadlineLinks: newDeadlineLinks,
    };
};

const deleteLinksForBookings = (state, action) => {
    const { bookingIds } = action.payload;
    const bookingIdsMap = arrayToObject(bookingIds);

    const newLinks = Object.values(state.links)
        .filter(link => {
            return !bookingIdsMap[link.parentId] && !bookingIdsMap[link.childId];
        })
        .reduce((acc, link) => {
            acc[`${link.parentId}-${link.childId}`] = {
                parentId: link.parentId,
                childId: link.childId,
            };
            return acc;
        }, {});

    return {
        ...state,
        links: newLinks,
    };
};

const handleGetChildrenResources = (state, action) => {
    const childrenById = arrayToObjectByKey(action.payload.children, '_id');

    return updateObject(state, {
        redrawSchedulerFlag: true,
        dpChildrenResourcesById: {
            ...state.dpChildrenResourcesById,
            ...childrenById,
        },
        dpResources: updateItemInArray(state.dpResources, action.payload.parentId, row => {
            return {
                ...row,
                wasLoaded: true,
                expanded: true,
            };
        }),
    });
};

const handleCollapseRow = (state, action) => {
    return updateObject(state, {
        dpResources: updateItemInArray(state.dpResources, action.payload.parentId, row => {
            return {
                ...row,
                expanded: false,
            };
        }),
    });
};

export default createReducer(initialSchedulerState, {
    [actionTypes.CHANGE_VIEW]: updateView,
    [actionTypes.TOGGLE_SIDEBAR]: toggleSidebar,
    [actionTypes.OPEN_SIDEBAR]: openSidebar,
    [actionTypes.CLOSE_SIDEBAR]: closeSidebar,
    [actionTypes.SET_CURRENT_SELECTION]: updateSelection,
    [actionTypes.UPDATE_CLIPBOARD]: updateClipboard,
    [actionTypes.UPDATE_BOOKING_SELECTION]: updateBookingSelection,
    [actionTypes.CLEAR_DATASET['REQUEST']]: clearSchedulerData,
    [actionTypes.SET_SCHEDULER_FILTER_ITEM['REQUEST']]: setSchedulerFilterItem,
    [actionTypes.SET_SCHEDULER_FILTER_ITEMS]: setSchedulerFilterItems,
    [actionTypes.SET_SCHEDULER_FILTER_RELATION]: setSchedulerFilterRelation,
    [actionTypes.SET_FILTER_OPERATOR]: setFilterOperator,
    [actionTypes.APPLY_BUILDER_DATA['REQUEST']]: applyBuilderData,
    [actionTypes.REMOVE_FILTER['REQUEST']]: removeFilter,
    [actionTypes.REMOVE_FILTERS]: removeFilters,
    [actionTypes.CLEAR_APPLIED_FILTERS['REQUEST']]: clearAppliedFilters,
    [actionTypes.BUILDER_RESET['REQUEST']]: builderReset,
    [actionTypes.RESET['REQUEST']]: resetScheduler,
    [actionTypes.GET_DAYPILOT_RESOURCES['REQUEST']]: handleGetDPResourcesRequest,
    [actionTypes.GET_DAYPILOT_RESOURCES['SUCCESS']]: setDPResources,
    [actionTypes.GET_DAYPILOT_RESOURCES['FAILURE']]: resetDPLoader,
    [actionTypes.UPDATE_PROJECT['SUCCESS']]: updateDPResources,
    [actionTypes.UPDATE_RESOURCE['REQUEST']]: optimisticUpdateDPResources,
    [actionTypes.UPDATE_RESOURCE['SUCCESS']]: updateDPResources,
    [actionTypes.DELETE_PROJECT['SUCCESS']]: deleteDpResource,
    [actionTypes.DELETE_RESOURCE['REQUEST']]: deleteDpResource,
    [actionTypes.UPDATE_FINANCIAL['REQUEST']]: setFinancialLoader,
    [actionTypes.UPDATE_FINANCIAL['SUCCESS']]: updateFinancialData,
    [actionTypes.INITIALIZE_FILTERS['REQUEST']]: initializeFilters,
    [actionTypes.GET_SCHEDULER_GROUP['REQUEST']]: handleGetSchedulerGroupRequest,
    [actionTypes.GET_SCHEDULER_GROUP['SUCCESS']]: handleGetSchedulerGroupSuccess,
    [actionTypes.GET_SCHEDULER_GROUP['FAILURE']]: handleGetSchedulerGroupError,
    [actionTypes.CLEAR_SCHEDULER_GROUP]: clearSchedulerGroup,
    [actionTypes.INIT_SCHEDULER]: initScheduler,
    [actionTypes.SCHEDULER_REMOVE_ROW]: handleRemoveRow,
    [actionTypes.SET_SCHEDULER_BOOKING_LINKS]: setSchedulerBookingLinks,
    [actionTypes.CREATE_BOOKING_LINK['REQUEST']]: addBookingLink,
    [actionTypes.CREATE_BOOKING_LINK['FAILURE']]: deleteBookingLink,
    [actionTypes.DELETE_BOOKING_LINK['REQUEST']]: deleteBookingLink,
    [actionTypes.REMOVE_BOOKING_LINK]: deleteBookingLink,
    [actionTypes.DELETE_BOOKING_LINK['FAILURE']]: addBookingLink,
    [actionTypes.SPLIT_BOOKING['SUCCESS']]: deleteLinksForBooking,
    [actionTypes.DELETE_BOOKING['SUCCESS']]: deleteLinksForBooking,
    [actionTypes.REJECT_BOOKING['SUCCESS']]: deleteLinksForBooking,
    [actionTypes.CREATE_BOOKING_DEADLINE['SUCCESS']]: addUpdateDeadlineLink,
    [actionTypes.UPDATE_BOOKING_DEADLINE['SUCCESS']]: addUpdateDeadlineLink,
    [actionTypes.DELETE_BOOKING_DEADLINE['SUCCESS']]: deleteLinksForBooking,
    [actionTypes.REJECT_REQUESTS_SUCCESS]: deleteLinksForBookings,
    [actionTypes.GET_CHILDREN_RESOURCES['SUCCESS']]: handleGetChildrenResources,
    [actionTypes.SCHEDULER_COLLAPSE_ROW]: handleCollapseRow,
});
