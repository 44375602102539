import { statusesArray as bookingStatuses } from 'enums/bookingTypeEnum';
import { TIME_SHEET as TIMESHEETS_EXTENSION } from 'enums/extensionShortIdEnum';
import { statusesArray as projectStatuses } from 'enums/projectEnum';
import { rolesArray, statusesArray as resourceStatuses } from 'enums/resourceEnum';
import { statusesArray as timeEntryStatuses } from 'enums/timesheetTypeEnum';
import moize from 'moize';
import moment from 'moment';
import { chain, contains, filter, find, values } from 'underscore';
// import { billableArray as billableTypes } from 'enums/billabilityEnum';
import { isActive } from 'utils/extensionUtil';
import { PROJECT_REPORT, RESOURCE_REPORT } from './reportTypeEnum';

export const PROJECT = {
    display: 'Project / Event',
    name: 'project',
    value: 'PROJECT',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project'],
    relation: 'projects',
    idProperty: 'id',
    filterName: 'projects',
    additionalProps: { fields: { label: 'name', value: 'id' } },
    requestItems: props => props.getProjects && props.getProjects(),
    getItems: (props, filterIds) =>
        filterIds ? filter(props.projects, project => contains(filterIds, project.id)) : props.projects,
    requestGroups: props => props.getProjectGroups && props.getProjectGroups(),
    getGroups: props => props.projectGroups || [],
    disabled: (columnType, itemType, secondGroupBy) => 'project' === secondGroupBy,
    support2ndGrouping: true,
};

export const RESOURCE = {
    display: 'Resource / Unassigned',
    name: 'resource',
    value: 'RESOURCE',
    type: RESOURCE_REPORT.value,
    extension: 'ALL',
    itemType: ['resource'],
    relation: 'resources',
    idProperty: 'resourceId',
    filterName: 'resources',
    additionalProps: {
        fields: {
            label: 'firstName',
            value: 'resourceId',
            additional: 'lastName',
        },
    },
    requestItems: props => props.getResources && props.getResources(),
    getItems: (props, filterIds) =>
        filterIds ? filter(props.resources, resource => contains(filterIds, resource.resourceId)) : props.resources,
    requestGroups: props => props.getResourceGroups && props.getResourceGroups(),
    getGroups: props => props.resourceGroups || [],
    disabled: (columnType, itemType, secondGroupBy) => 'resource' === secondGroupBy,
    support2ndGrouping: true,
};

export const BOOKING_CATEGORY = {
    display: 'Booking Category',
    name: 'bookingCategory',
    value: 'BOOKING_CATEGORY',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    idProperty: '_id',
    filterName: 'bookingCategories',
    additionalProps: { fields: { label: 'name', value: '_id' } },
    itemType: ['project', 'event', 'resource', 'unassigned'],
    getItems: props => props.bookingCategories || [],
    disabled: (columnType, itemType, secondGroupBy) =>
        !!secondGroupBy && !contains(['project', 'resource'], secondGroupBy),
    support2ndGrouping: true,
};

export const BUDGET_CATEGORY = {
    display: 'Budget Category',
    name: 'budgetCategory',
    value: 'BOOKING_CATEGORY',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    idProperty: '_id',
    filterName: 'bookingCategories',
    additionalProps: { fields: { label: 'name', value: '_id' } },
    itemType: ['project'],
    getItems: props => props.bookingCategories || [],
    disabled: (columnType, itemType, secondGroupBy) =>
        !!secondGroupBy && !contains(['project', 'resource'], secondGroupBy),
    support2ndGrouping: true,
};

export const PROJECT_TAG = {
    display: 'Project Tag',
    name: 'projectTag',
    value: 'PROJECT_TAG',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event'],
    idProperty: '_id',
    filterName: 'projectTags',
    additionalProps: { fields: { label: 'value', value: '_id' } },
    requestItems: props => props.getTags && props.getTags('project'),
    getItems: props => props.projectTags || [],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const RESOURCE_TAG = {
    display: 'Resource Tag',
    name: 'resourceTag',
    value: 'RESOURCE_TAG',
    type: RESOURCE_REPORT.value,
    extension: 'ALL',
    itemType: ['resource', 'unassigned'],
    idProperty: '_id',
    filterName: 'resourceTags',
    additionalProps: { fields: { label: 'value', value: '_id' } },
    requestItems: props => props.getTags && props.getTags('resource'),
    getItems: props => props.resourceTags || [],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const CUSTOMER = {
    display: 'Customer',
    name: 'customer',
    value: 'CUSTOMER',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event'],
    idProperty: '_id',
    filterName: 'customers',
    additionalProps: { fields: { label: 'name', value: '_id' } },
    getItems: props => props.customers || [],
    requestItems: props => props.getCustomers && props.getCustomers(),
    disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
};

export const PROJECT_MANAGER = {
    display: 'Project Manager',
    name: 'projectManager',
    value: 'PROJECT_MANAGER',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event'],
    idProperty: 'resourceId',
    filterName: 'projectManagers',
    additionalProps: {
        fields: {
            label: 'firstName',
            value: 'resourceId',
            additional: 'lastName',
        },
    },
    getItems: props => props.projectManagers || [],
    requestItems: props => props.getResources && props.getResources(),
    disabled: (columnType, itemType, secondGroupBy) => 'event' === itemType || !!secondGroupBy,
};

export const RESOURCE_STATUS = {
    display: 'Resource Status',
    name: 'resourceStatus',
    value: 'RESOURCE_STATUS',
    type: RESOURCE_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event', 'resource', 'unassigned'],
    idProperty: 'value',
    filterName: 'resourceStatuses',
    additionalProps: { fields: { label: 'display', value: 'value' } },
    getItems: () => resourceStatuses,
    disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
};

export const PROJECT_STATUS = {
    display: 'Project Status',
    name: 'projectStatus',
    value: 'PROJECT_STATUS',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event'],
    idProperty: 'value',
    filterName: 'projectStatuses',
    additionalProps: { fields: { label: 'display', value: 'value' } },
    getItems: () => projectStatuses,
    disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
};

export const RESOURCE_ROLE = {
    display: 'Resource Role',
    name: 'resourceRole',
    value: 'RESOURCE_ROLE',
    type: RESOURCE_REPORT.value,
    extension: 'ALL',
    itemType: ['resource', 'unassigned'],
    idProperty: 'value',
    filterName: 'roles',
    additionalProps: { fields: { label: 'display', value: 'value' } },
    getItems: () => rolesArray,
};

export const REPORTED_TIME_STATE = {
    display: 'Reported Time State',
    name: 'reportedTimeStatus',
    value: 'REPORTED_TIME_STATE',
    type: PROJECT_REPORT.value,
    extension: TIMESHEETS_EXTENSION,
    itemType: ['project', 'event', 'resource', 'unassigned'],
    idProperty: 'value',
    filterName: 'timeEntryStatuses',
    additionalProps: { fields: { label: 'display', value: 'value' } },
    getItems: () => timeEntryStatuses,
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const SCHEDULED_TIME_STATE = {
    display: 'Scheduled Time State',
    name: 'scheduledTimeStatus',
    value: 'SCHEDULED_TIME_STATE',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event', 'resource', 'unassigned'],
    idProperty: 'value',
    filterName: 'bookingStatuses',
    additionalProps: { fields: { label: 'display', value: 'value' } },
    getItems: () => bookingStatuses,
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const PROJECT_GROUP = {
    display: 'Project Group',
    name: 'projectGroup',
    value: 'PROJECT_GROUP',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['project', 'event'],
    idProperty: '_id',
    additionalProps: { fields: { label: 'name', value: '_id' } },
    requestItems: props => props.getProjectGroups && props.getProjectGroups(),
    getItems: props => props.projectGroups || [],
    disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
};

export const RESOURCE_GROUP = {
    display: 'Resource Group',
    name: 'resourceGroup',
    value: 'RESOURCE_GROUP',
    type: PROJECT_REPORT.value,
    extension: 'ALL',
    itemType: ['resource', 'unassigned'],
    idProperty: '_id',
    additionalProps: { fields: { label: 'name', value: '_id' } },
    requestItems: props => props.getResourceGroups && props.getResourceGroups(),
    getItems: props => props.resourceGroups || [],
    disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
};

// export const BILLABLE = {
//     display: 'Billability',
//     name: 'billable',
//     value: 'BILLABLE',
//     extension: 'ALL',
//     itemType: ['project', 'event', 'resource', 'unassigned'],
//     idProperty: 'value',
//     filterName: 'billable',
//     additionalProps: { fields: { label: 'display', value: 'value' } },
//     getItems: () => billableTypes,
//     disabled: (columnType, itemType, secondGroupBy) => !!secondGroupBy,
// };

export const DAY = {
    display: 'Day',
    name: 'day',
    value: 'DAY',
    extension: 'ALL',
    tableDisplay: value => value,
    itemType: ['project', 'event', 'resource', 'unassigned'],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const WEEK = {
    display: 'Week',
    name: 'week',
    value: 'WEEK',
    extension: 'ALL',
    tableDisplay: value => {
        const date = moment(value, 'W-YYYY');

        return `${date.startOf('isoWeek').format('MMM Do YYYY')} - ${date.endOf('isoWeek').format('MMM Do YYYY')}`;
    },
    itemType: ['project', 'event', 'resource', 'unassigned'],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const MONTH = {
    display: 'Month',
    name: 'month',
    value: 'MONTH',
    extension: 'ALL',
    tableDisplay: value => {
        const date = moment(value, 'M-YYYY');

        return date.format('MMM, YYYY');
    },
    itemType: ['project', 'event', 'resource', 'unassigned'],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const YEAR = {
    display: 'Year',
    name: 'year',
    value: 'YEAR',
    extension: 'ALL',
    tableDisplay: value => value,
    itemType: ['project', 'event', 'resource', 'unassigned'],
    disabled: (columnType, itemType, secondGroupBy) => 'DATE' === columnType || !!secondGroupBy,
};

export const dateGroupingTypes = {
    DAY,
    WEEK,
    MONTH,
    YEAR,
};

export const groupingTypes = {
    PROJECT,
    RESOURCE,
    BOOKING_CATEGORY,
    BUDGET_CATEGORY,
    RESOURCE_GROUP,
    PROJECT_GROUP,
    SCHEDULED_TIME_STATE,
    REPORTED_TIME_STATE,
    PROJECT_STATUS,
    RESOURCE_STATUS,
    PROJECT_MANAGER,
    CUSTOMER,
    RESOURCE_TAG,
    PROJECT_TAG,
    ...dateGroupingTypes,
};

export const findGroupingByName = moize(
    name => (name && find(values(groupingTypes), groupType => name === groupType.name)) || {},
    { maxSize: 2 }
);

export const getGroupingsByExtensions = extensions =>
    chain(groupingTypes)
        .values()
        .filter(groupType => 'ALL' === groupType.extension || isActive(extensions, groupType.extension))
        .value() || {};

export const getGroupingsByExtensionsAndSupport2nd = extensions =>
    chain(groupingTypes)
        .values()
        .filter(
            groupType =>
                ('ALL' === groupType.extension || isActive(extensions, groupType.extension)) &&
                groupType.support2ndGrouping
        )
        .value() || {};

export const isDateGrouping = groupBy => contains([DAY.name, WEEK.name, MONTH.name, YEAR.name], groupBy);
