import { hasRole } from 'utils/rightsUtil';
import { disableEvent } from './helper';
import { formatDate } from 'utils/DateUtil';

export const handleMilestoneEvent = (args, data) => {
    const [schedulerRef, resourceRoleRights] = data;
    const startDate = formatDate(args.e.start.toDate(), 'MMM do yyyy');
    const hasSettingProjectMsRights = hasRole(resourceRoleRights, 'settingProjectMs');

    if (!hasSettingProjectMsRights) {
        disableEvent(args, schedulerRef.current.control.eventHeightMode);
    }
    if (!schedulerRef.current.control.displayMilestoneText) {
        args.e.htmlRight = '';
    }

    args.e.bubbleHtml = `Milestone - ${args.e.name} - ${startDate}`;
};
