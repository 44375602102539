import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {*[]}
 */
export default dateState => [
    {
        id: 'utilizationStatusBetween',
        display: `Utilization Status - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Scheduled and Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'utilizationStatusPrevious',
        display: `Utilization Status - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Scheduled and Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'utilizationStatusAfter',
        display: `Utilization Status - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Scheduled and Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
];
