import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem, Divider } from '@material-ui/core';
import { useClickOutside, useIsExtensionInstalled } from 'hooks';
import { showSchedulerBuilderModal, showOrderGroupModal } from 'actions/modalActions';
import { deleteResourceGroup } from 'actions/resourceGroupActions';
import { deleteProjectGroup } from 'actions/projectGroupActions';
import { SIDEBAR_PRO } from 'enums/extensionShortIdEnum';
import { GROUP } from 'enums/groupTypeEnum';
import { SYSTEM } from 'modules/scheduler/enums/groupTypeEnum';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import ConfirmDialog from 'shared/confirmButton/confirmDialog';
import { useStyles } from './../styles';
import { DATA_TAB, ROW_FILTER_TAB, TYPE_TAB } from 'modules/scheduler/modals/builder/tabs';
import { useParamGroupId } from '../../../../../hooks/useParamGroupId';
import { resetScheduler } from '../../../../../actions/schedulerActions';

const GroupContextMenu = ({ close, group, top, left }) => {
    const node = useRef();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isTypeProject = group.hasOwnProperty('projects');
    const [showConfirmDialog, setShowConfirmDialog] = useState();
    const isSidebarProInstalled = useIsExtensionInstalled(SIDEBAR_PRO);
    const paramGroupId = useParamGroupId();

    const handler = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target)) {
                close();
            }
        },
        [close]
    );

    useClickOutside(handler);

    const openGroupModal = parentGroupId => {
        let initialTab;

        if (parentGroupId) {
            initialTab = TYPE_TAB;
        } else {
            initialTab = group && group.isSmart ? ROW_FILTER_TAB : DATA_TAB;
        }

        return dispatch(
            showSchedulerBuilderModal({
                itemTypeName: isTypeProject ? PROJECT.name : RESOURCE.name,
                itemId: !parentGroupId ? group._id : null,
                groupType: GROUP,
                parentGroupId,
                isFilterContext: false,
                initialTab,
            })
        );
    };

    const onConfirm = () => {
        close();
        if (isTypeProject) {
            dispatch(deleteProjectGroup.request(group._id));
        } else {
            dispatch(deleteResourceGroup.request(group._id));
        }

        if (group._id === paramGroupId) {
            dispatch(resetScheduler.request());
        }
    };

    const deleteGroup = () => {
        setShowConfirmDialog(true);
    };

    const closeConfirmDialog = () => {
        close();
        setShowConfirmDialog(false);
    };

    const addSubGroupModal = () => {
        close();
        openGroupModal(group._id);
    };

    const editGroupModal = () => {
        close();
        openGroupModal();
    };

    const openOrderModal = () => {
        close();
        dispatch(showOrderGroupModal(isTypeProject ? 'PROJECT' : 'RESOURCE'));
    };

    return (
        <Menu
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            keepMounted
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <div>
                {SYSTEM !== group.groupType && (
                    <>
                        {!group.parentGroupId && isSidebarProInstalled && (
                            <>
                                <MenuItem onClick={addSubGroupModal}>Add sub group...</MenuItem>
                                <Divider />
                            </>
                        )}
                        <MenuItem onClick={deleteGroup}>Delete group...</MenuItem>
                        <Divider />
                    </>
                )}
                {!group.parentGroupId && isSidebarProInstalled && (
                    <MenuItem onClick={openOrderModal}>Sort groups...</MenuItem>
                )}
                <MenuItem onClick={editGroupModal}>Edit group...</MenuItem>
                <ConfirmDialog
                    title=""
                    message="Are you sure you want to delete this group?"
                    onConfirm={onConfirm}
                    onReject={closeConfirmDialog}
                    show={showConfirmDialog}
                    backgroundColor="#C82333"
                    confirmButtonText="Yes, delete this group"
                    cancelButtonText="Cancel"
                />
            </div>
        </Menu>
    );
};

GroupContextMenu.propTypes = {
    group: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
};

export default GroupContextMenu;
