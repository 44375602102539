import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { getBookingType } from 'enums/bookingTypeEnum';
import {
    StatPods,
    RequestTable,
    PieChart,
    BarChart,
    EventCalendar,
    Header,
    UpcomingEvents,
    PieChartToil,
} from 'modules/request/components';
import { useRequestData } from 'modules/request/hooks';
import { isSingleVacationFlow, isVacationGrid } from 'modules/request/utils/dataFlowUtil';
import { filterRequestTable } from 'utils/filterUtil';
import { UpcomingEventsInfoTilesWrapper } from '../../components/upcomingEventsWrapper';
import { Layout } from '@hub-mono/ui';
import { makeResourceByIdFromMap } from '../../../../selectors/resource';
import { useSelector } from 'react-redux';
import { TeamOverview } from '../../components/teamOverview';
import { selectCompanyAvgDailyCapacity, selectIsToilExtensionInstalled } from 'selectors/company';

const VacationRequests = ({ location }) => {
    const {
        deleteBooking,
        requestModel,
        companySettings,
        filteredBookings,
        textFilter,
        statistics,
        tableActive,
        resourceId,
        editRequest,
        approveOrRejectRequest,
        handleApproveOrRejectModal,
        reSubmitRequest,
    } = useRequestData('VACATION_FLOW', location);

    const statisticsJS = useMemo(() => {
        return statistics.toJS();
    }, [statistics]);

    const resourceSelector = useMemo(() => makeResourceByIdFromMap(requestModel?.resourceId), [
        requestModel?.resourceId,
    ]);
    const resource = useSelector(resourceSelector);
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);

    return (
        <>
            <Header />
            <StatPods
                filter={requestModel.filter}
                resourceId={requestModel.resourceId}
                toilPolicyId={resource?.toilPolicyId}
                flow={requestModel.flow}
                statistics={statisticsJS}
            />
            {!tableActive && 'VACATION_GRID' !== requestModel.flow && (
                <Layout stack gap={16} pt={16} pb={16}>
                    <Layout gap={16}>
                        {isSingleVacationFlow(requestModel.flow) &&
                            (isToilExtensionInstalled ? (
                                <PieChartToil
                                    defaultVacationEventName={companySettings.vacationEventName}
                                    totalApprovedDays={statistics.getIn(['total', 'APPROVED'])}
                                    totalRemainingDays={statistics.getIn(['total', 'REMAINING'])}
                                    totalToilApproved={statisticsJS.toil?.total?.APPROVED || 0}
                                    totalToilRemaining={statisticsJS.toil?.total?.REMAINING || 0}
                                    totalDays={
                                        statistics.getIn(['total', 'REMAINING']) +
                                        (statisticsJS.toil?.total?.REMAINING
                                            ? statisticsJS.toil?.total?.REMAINING / companyAvgDailyCapacity
                                            : 0)
                                    }
                                />
                            ) : (
                                <PieChart
                                    defaultVacationEventName={companySettings.vacationEventName}
                                    totalApprovedDays={statistics.getIn(['total', 'APPROVED'])}
                                    totalRemainingDays={statistics.getIn(['total', 'REMAINING'])}
                                />
                            ))}
                        <BarChart
                            flex={isToilExtensionInstalled ? 1 : 2.05}
                            weekStartDay={companySettings.weekStartDay}
                            title={`${getBookingType(requestModel.filter)} ${companySettings.vacationEventName} Days`}
                            requestModel={requestModel}
                            statistics={statistics}
                            companyAvgDailyCapacity={companyAvgDailyCapacity}
                        />
                    </Layout>
                    <UpcomingEventsInfoTilesWrapper
                        resourceId={resourceId}
                        statistics={statistics}
                        companySettings={companySettings}
                    />
                    <div className="upcoming-row-wrapper">
                        <Row>
                            <Col sm={12} md={6}>
                                <EventCalendar
                                    upcomingEvents={statistics.get('upcomingEvents')}
                                    weekStartDay={companySettings.weekStartDay}
                                />
                            </Col>
                            <Col sm={12} md={6}>
                                <UpcomingEvents
                                    resourceId={resourceId}
                                    vacationEventName={companySettings.vacationEventName}
                                    events={statistics.get('upcomingEvents')}
                                    requestStartDate={requestModel.start}
                                    requestEndDate={requestModel.end}
                                />
                            </Col>
                        </Row>
                    </div>
                </Layout>
            )}
            <Row>
                <Col md={12} className="pt-4">
                    {!isVacationGrid(requestModel.flow) ? (
                        <RequestTable
                            key={requestModel.resourceId ?? 'all'}
                            requestModel={requestModel}
                            deleteRequest={deleteBooking}
                            approveOrRejectModal={handleApproveOrRejectModal}
                            approveOrRejectRequest={approveOrRejectRequest}
                            reSubmitRequest={reSubmitRequest}
                            bookings={filterRequestTable(filteredBookings, textFilter)}
                            editRequest={editRequest}
                            resourceId={resourceId}
                            avgDailyCapacity={companySettings.report.avgDailyCapacity}
                        />
                    ) : (
                        <TeamOverview
                            start={requestModel.start}
                            end={requestModel.end}
                            flow={requestModel.flow}
                            data={statistics.get('resources')}
                            dataLoaded={statistics.get('resourcesLoaded')}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default VacationRequests;
