import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import { ListItemText, ListItemIcon } from '@material-ui/core';
import { Schedule, Edit, Person, FileCopy, EditOutlined } from '@material-ui/icons';
import { ListItem as ListItemStyle } from 'modules/scheduler/components/lists/styles';
import { useAppKeyWords, useProjectAction } from 'hooks';
import { useRequests } from 'modules/request/hooks';
import { WAITING_FOR_APPROVAL, SCHEDULED } from 'enums/bookingTypeEnum';
import { showProjectModal, showResourceModal, showConfirmationModal } from 'actions/modalActions';
import { updateResource } from 'actions/resourceActions';
import { CELLDURATION, findByValue } from 'modules/scheduler/enums/scale';
import { formatApprovers } from 'modules/scheduler/utils/eventUtil';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import ActionItem from 'modules/scheduler/components/dropdowns/shared/actionItem';
import getPasteBookingDisabled from './utils/getPasteBookingDisabled';
import { useMenuProjectPermissions, useMenuResourcePermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import {
    selectBookingDefaultState,
    selectUseCategoriesAllocation,
    selectWeekDaysSetting,
} from '../../../../../../selectors/company';
import { getCategoryTemplates } from '../../../../../../selectors/categoryTemplate';
import { calculateUnavailabilityDays } from './utils/calculateUnavailabilityDays';
import { overrideDatesCheck } from '../../../../../modals/resourceModal/components/AvailabilityOverride/AvailabilityOverrideForm';

const ChildrenRows = React.forwardRef(
    ({ onClick, onClose, showRequestResourceOption, isRequestMode, onPaste, width }, schedulerRef) => {
        const dispatch = useDispatch();
        const slideRef = useRef();

        const { projectKeyWord, resourceKeyWord } = useAppKeyWords();
        const { canRequestResources } = useRequests();
        const { updateProject } = useProjectAction(schedulerRef);
        const { projects } = useSelector(state => state.projectReducer);
        const currentRowTags = useSelector(state => state.scheduler.currentSelection.rowTags);
        const clipboard = useSelector(state => state.scheduler.clipboard);
        const categoryTemplates = useSelector(getCategoryTemplates);
        const shouldUseCategoriesAllocation = useSelector(selectUseCategoriesAllocation);
        const defaultBookingState = useSelector(selectBookingDefaultState);
        const { project, resource, ...rowTags } = currentRowTags || {};
        const currentSelection = useSelector(state => state.scheduler.currentSelection);
        const scale = findByValue(window.schedulerRef.current.control?.scale);
        const companyWeekdays = useSelector(selectWeekDaysSetting);

        const { hasAddEditProjectRights } = useMenuProjectPermissions();
        const { hasCreateResourceRights } = useMenuResourcePermissions();

        const canEditProject = rowTags.isProjectRow && hasAddEditProjectRights && project?.hasRightsToProject;
        const canEditResource = rowTags.isResourceRow && hasCreateResourceRights && resource?.hasRightsToResource;
        const projectModal = useCallback(() => {
            onClose && onClose();
            dispatch(
                showProjectModal({
                    onSubmit: data => updateProject(project._id, data),
                    type: project.type,
                    projectId: project._id,
                })
            );
        }, [dispatch, onClose, project, updateProject]);

        const createBookingRequest = useCallback(
            type => {
                const projectId = rowTags.isResourceRow ? rowTags.projectId : project._id;
                const categoryTemplateId = projects.find(({ id }) => id === projectId)?.defaultCategoryTemplate;

                let state;
                if (shouldUseCategoriesAllocation && categoryTemplateId) {
                    const templateState = categoryTemplates?.find(template => template._id === categoryTemplateId)
                        ?.state;
                    state = templateState ?? defaultBookingState;
                } else {
                    state = defaultBookingState;
                }

                onClick({
                    project: projectId,
                    resource: rowTags.isResourceRow ? resource._id : rowTags.resourceId,
                    type,
                    state,
                    categoryTemplateId,
                    approvalInfo:
                        type === WAITING_FOR_APPROVAL.value
                            ? { approvers: formatApprovers(resource.defaultApproverIds) }
                            : undefined,
                });
            },
            [
                rowTags.isResourceRow,
                rowTags.projectId,
                rowTags.resourceId,
                project._id,
                projects,
                resource._id,
                resource.defaultApproverIds,
                categoryTemplates,
                shouldUseCategoriesAllocation,
                defaultBookingState,
                onClick,
            ]
        );

        const resourceModal = useCallback(() => {
            onClose && onClose();
            dispatch(
                showResourceModal({
                    onSubmit: data => dispatch(updateResource.request(resource._id, data)),
                    type: resource.type,
                    resourceId: resource._id,
                })
            );
        }, [dispatch, onClose, resource]);

        const pasteBookingDisabled = getPasteBookingDisabled(clipboard, rowTags);

        const slideToMore = () => {
            slideRef.current.triggerSlide(2);
        };

        return (
            <SlideCard
                disablePadding
                height={100}
                width={width}
                ref={slideRef}
                items={[
                    {
                        id: 1,
                        itemElement: () => (
                            <Row className="no-row-gutters py-3 ml-2 mr-2 text-center align-items-center">
                                <ActionItem
                                    text="Paste booking"
                                    onClick={onPaste}
                                    disabled={pasteBookingDisabled}
                                    dataCy="button--paste-booking"
                                >
                                    <FileCopy />
                                </ActionItem>
                                {!isRequestMode && (
                                    <ActionItem
                                        disabled={!rowTags.hasOnlyScheduleRights}
                                        text="Schedule selection"
                                        onClick={() => createBookingRequest(SCHEDULED.value)}
                                        dataCy="button--schedule-selection"
                                    >
                                        <Schedule />
                                    </ActionItem>
                                )}
                                {showRequestResourceOption && (
                                    <ActionItem
                                        disabled={!canRequestResources}
                                        text={`Request ${resourceKeyWord.toLowerCase()}`}
                                        dataCy={`button--request-${resourceKeyWord.toLowerCase()}`}
                                        onClick={() => createBookingRequest(WAITING_FOR_APPROVAL.value)}
                                    >
                                        <Person />
                                    </ActionItem>
                                )}
                                {(canEditProject || canEditResource) &&
                                    (rowTags.isResourceRow ? (
                                        <ActionItem text="More" onClick={slideToMore} dataCy="button--menu-more">
                                            <Edit />
                                        </ActionItem>
                                    ) : (
                                        <ActionItem
                                            text={`Edit ${rowTags.isProjectRow ? projectKeyWord : resourceKeyWord}`}
                                            onClick={rowTags.isProjectRow ? projectModal : resourceModal}
                                            dataCy={`button--edit-${
                                                rowTags.isProjectRow ? projectKeyWord : resourceKeyWord
                                            }`}
                                            className="edit-booking-mm"
                                        >
                                            <Edit />
                                        </ActionItem>
                                    ))}
                            </Row>
                        ),
                    },
                    {
                        id: 2,
                        slides: [
                            {
                                content: (
                                    <>
                                        {rowTags.isResourceRow && (
                                            <ListItemStyle
                                                divider
                                                data-cy="mark-as-unavailable"
                                                disabled={!resource.hasRightsToResource}
                                                onClick={() => {
                                                    onClose && onClose();

                                                    const canNotAddOverride = overrideDatesCheck(
                                                        {
                                                            from: currentSelection.start,
                                                            to: currentSelection.end,
                                                        },
                                                        Object.values(resource?.customAvailabilitiesOverridesById || {})
                                                    );

                                                    if (canNotAddOverride) {
                                                        dispatch(
                                                            showConfirmationModal(
                                                                () => {
                                                                    // do nothing
                                                                },
                                                                'Not Allowed',
                                                                `We are sorry, You cannot mark these dates as unavailable as there is already a custom availability date range set on these dates. Please edit the resource and configure the availability there instead.`,
                                                                {
                                                                    withCancel: false,
                                                                    confirmButtonText: 'Cancel',
                                                                }
                                                            )
                                                        );
                                                    } else {
                                                        dispatch(
                                                            updateResource.request(resource._id, {
                                                                companySpecific: {
                                                                    customAvailabilityOverridesToSave: {
                                                                        create: calculateUnavailabilityDays(
                                                                            currentSelection,
                                                                            scale.value === CELLDURATION.value,
                                                                            companyWeekdays,
                                                                            resource
                                                                        ),
                                                                    },
                                                                },
                                                            })
                                                        );
                                                    }
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <EditOutlined />
                                                </ListItemIcon>
                                                <ListItemText primary="Mark as unavailable" />
                                            </ListItemStyle>
                                        )}
                                        {(canEditProject || canEditResource) && (
                                            <ListItemStyle
                                                dataCy={`button--edit-${
                                                    rowTags.isProjectRow ? projectKeyWord : resourceKeyWord
                                                }`}
                                                divider
                                                onClick={rowTags.isProjectRow ? projectModal : resourceModal}
                                            >
                                                <ListItemIcon>
                                                    <Person />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={`Edit ${
                                                        rowTags.isProjectRow ? projectKeyWord : resourceKeyWord
                                                    }`}
                                                />
                                            </ListItemStyle>
                                        )}
                                    </>
                                ),
                            },
                        ],
                    },
                ]}
            />
        );
    }
);

ChildrenRows.propTypes = {
    showRequestResourceOption: PropTypes.bool.isRequired,
    isRequestMode: PropTypes.bool.isRequired,
    onPaste: PropTypes.func.isRequired,
    onClose: PropTypes.func,
};

ChildrenRows.defaultProps = {
    onClose: null,
};

export default ChildrenRows;
