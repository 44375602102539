import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import { Button } from 'reactstrap';
import { find } from 'lodash';
import BaseFilterBox from './baseFilterBox';
import DateFilter from 'shared/dateFilter';
import { FiltersBox, Content, Footer, FilterIcon } from './styles';

class DateFilterBox extends BaseFilterBox {
    static propTypes = {
        columnName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            operator: 'eq',
            value: undefined,
            toggleBox: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { columnName, queryParams } = this.props;
        if (!isEqual(prevProps.queryParams.filters[columnName], queryParams.filters[columnName])) {
            this.setActiveState();
        }
    }

    setActiveState = () => {
        const {
            columnName,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const existFilter = find(resultValue, filter => filter.columnName === columnName);
        const isActive = undefined !== existFilter;
        const value = isActive ? new Date(existFilter.date) : undefined;
        const operator = isActive ? existFilter.operator : 'eq';

        this.setState({ active: isActive, value, operator });
    };

    onFilter = () => {
        this.toggle();
        const currentFilter = {
            columnName: this.props.columnName,
            operator: this.state.operator,
            date: moment(this.state.value)
                .utc()
                .set({ hours: 0, minutes: 0, seconds: 0 })
                .format('YYYY-MM-DD'),
        };
        this.updateFilter(currentFilter);
    };

    render() {
        const { operator, value } = this.state;
        const positions = this.node && this.node.getBoundingClientRect();

        return (
            <div className="position-absolute" style={{ right: '3px', top: '3px' }} ref={node => (this.node = node)}>
                {!this.props.disabled && (
                    <FilterIcon
                        open={this.state.toggleBox}
                        active={this.state.active.toString()}
                        onClick={this.toggle}
                    />
                )}
                <FiltersBox
                    position={positions && 600 < positions.left ? 'right' : 'left'}
                    className={classNames({ 'd-none': !this.state.toggleBox })}
                >
                    <Content>
                        <DateFilter defaultValues={{ operator, value }} onChange={this.onChange} />
                    </Content>
                    <Footer>
                        <Button color="success" onClick={this.onFilter}>
                            Filter
                        </Button>
                        <Button color="secondary" onClick={this.onClear}>
                            Clear
                        </Button>
                    </Footer>
                </FiltersBox>
            </div>
        );
    }
}

export default withRouter(DateFilterBox);
