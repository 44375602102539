import { useEffect, useMemo } from 'react';
import {
    arrowKeyLeftCode,
    arrowKeyUpCode,
    arrowKeyRightCode,
    arrowKeyDownCode,
    enterKeyCode,
} from 'shared/constants/keyCodes';

/**
 * This hook sets key listeners for key codes matching the arrow keyboard keys, only inside a provided HTMLElement. Propagation will stop. When unmounted it will remove them.
 * @param {{onArrowLeft: function, onArrowUp: function, onArrowRight: function, onArrowDown: function,onArrowLeft: onEnter}} keyListeners listeners for specified keys (defaults to empty object)
 * @param {HTMLElement} element HTMLElements (defaults to document)
 */
const useNavigationKeys = (keyListeners = {}, element = document) => {
    const { onArrowLeft, onArrowUp, onArrowRight, onArrowDown, onEnter } = keyListeners;
    const listenersMap = useMemo(() => {
        return {
            [arrowKeyLeftCode]: onArrowLeft,
            [arrowKeyUpCode]: onArrowUp,
            [arrowKeyRightCode]: onArrowRight,
            [arrowKeyDownCode]: onArrowDown,
            [enterKeyCode]: onEnter,
        };
    }, [onArrowUp, onArrowDown, onArrowLeft, onArrowRight, onEnter]);

    useEffect(() => {
        const callback = keydownEvent => {
            keydownEvent.stopPropagation();
            const { keyCode: keyDownKeyCode } = keydownEvent;
            listenersMap[keyDownKeyCode] && listenersMap[keyDownKeyCode]();
        };
        element.addEventListener('keydown', callback);
        return () => {
            element.removeEventListener('keydown', callback);
        };
    }, [listenersMap, element]);
};

export default useNavigationKeys;
