import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Checkbox,
    Radio,
    FormControlLabel,
    FormControl,
    FormLabel,
    RadioGroup,
    FormHelperText,
} from '@material-ui/core';
import InfoTooltip from 'shared/infoTooltip';

const ChooseField = props => {
    const {
        input,
        label,
        className,
        formHelperText,
        indeterminate,
        wrapperClass,
        disabled,
        type,
        meta,
        description,
        options,
        ...restOfProps
    } = props;
    return (
        <>
            {'checkbox' === type ? (
                <div className={wrapperClass}>
                    <FormControlLabel
                        className={classNames(className)}
                        disabled={disabled}
                        control={
                            <Checkbox
                                color="primary"
                                checked={!!input.value}
                                inputProps={{ 'aria-label': label }}
                                indeterminate={indeterminate}
                                {...input}
                                {...restOfProps}
                            />
                        }
                        label={label}
                    />
                    <FormHelperText className="m-0">{(meta.touched && meta.error) || formHelperText}</FormHelperText>
                </div>
            ) : (
                <FormControl component="fieldset" className={wrapperClass} fullWidth>
                    <FormLabel component="legend">{label}</FormLabel>
                    <RadioGroup row aria-label={label} {...input}>
                        {options.map(option => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                    <FormHelperText className="m-0">{(meta.touched && meta.error) || formHelperText}</FormHelperText>
                </FormControl>
            )}
            {description && <InfoTooltip description={description} />}
        </>
    );
};

ChooseField.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    description: PropTypes.string,
    indeterminate: PropTypes.bool,
    type: PropTypes.oneOf(['checkbox', 'radio']),
    label: PropTypes.string,
    wrapperClass: PropTypes.string,
    className: PropTypes.string,
    formHelperText: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            disabled: PropTypes.bool,
        })
    ),
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    }),
};

ChooseField.defaultProps = {
    input: {},
    options: [],
    disabled: false,
    type: 'checkbox',
    indeterminate: false,
    label: '',
    description: '',
    formHelperText: '',
    wrapperClass: '',
    className: '',
    meta: {},
};

export default ChooseField;
