import { formatBookingToBackend } from '../../../utils/eventUtil';
import { getNewApprovalInfo, prepareEvent } from '../onEventMoved/event';
import { store } from '../../../../../store';
import { selectCompanyApprovers } from '../../../../../selectors/company';
import { selectMappedResourceGroups } from '../../../../../selectors/resourceGroup';
import { formatDate } from '../../../../../utils/DateUtil';
import { BOOKING_FORMAT } from '../../../../../global/enums/dateFormat';
import { arrayToObjectByKey } from '../../../../../utils/mappingUtil';
import { eventsIdsMoving, invalidateRowsByIds } from '../../../utils/schedulerUtil';
import { updateMultipleBookings } from '../../../../../actions/bookingActions';
import { showConfirmationModal } from '../../../../../actions/modalActions';
import { REPEAT_MODAL_TYPES } from '../../../modals/editRepeatModal/modal';
import { Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';
import React from 'react';

export function handleMultiMove({ args, companyWeekdays, viewObject, isDependencyExtensionInstalled }) {
    args.async = true;
    const scheduler = args.control;
    const reduxState = store.getState();
    const approvers = selectCompanyApprovers(reduxState);
    const mappedResourceGroups = selectMappedResourceGroups(reduxState);

    const initialRowIds = args.multimove.map(multimoveEntry => {
        return multimoveEntry.resource;
    });

    const multimove = includeDeadlineMoveIntoBooking(args);

    const bookingsToUpdate = multimove.bookings.map(multimoveBookingEntry => {
        const destinationRow = scheduler.rows.find(multimoveBookingEntry.resource);

        const { event, oldEvent } = prepareEvent(
            {
                ...args,
                e: multimoveBookingEntry.event,
                newEnd: multimoveBookingEntry.end,
                newResource: multimoveBookingEntry.resource,
                newStart: multimoveBookingEntry.start,
            },
            companyWeekdays,
            viewObject,
            scheduler,
            destinationRow
        );

        const newApprovalInfo = getNewApprovalInfo({
            event,
            oldEvent,
            args,
            reduxState,
            approvers,
            mappedResourceGroups,
        });

        return formatBookingToBackend({ ...event, ...newApprovalInfo });
    });

    const deadlinesToUpdate = multimove.deadlines.map(multimoveDeadlineEntry => {
        return {
            _id: multimoveDeadlineEntry.event.data.booking.id,
            repeat: multimoveDeadlineEntry.event.data.booking.repeat,
            repeatId: multimoveDeadlineEntry.event.data.booking.repeatId,
            deadlineName: multimoveDeadlineEntry.event.data.booking.deadlineName,
            deadlineDate: formatDate(multimoveDeadlineEntry.start.value, BOOKING_FORMAT, false),
        };
    });

    const allEventsToUpdate = bookingsToUpdate.concat(deadlinesToUpdate);

    const onSuccess = () => {
        allEventsToUpdate.forEach(event => {
            const id = event.id || event._id;
            if (id) {
                eventsIdsMoving.delete(id);
            }
        });
        invalidateRowsByIds(Array.from(initialRowIds));
    };

    const onFailure = () => {
        allEventsToUpdate.forEach(event => {
            const id = event.id || event._id;
            if (id) {
                eventsIdsMoving.delete(id);
            }
        });
    };

    let allEventsToUpdateWithoutChildren;
    if (isDependencyExtensionInstalled) {
        // in case of dependencies / linked bookings we need to send just the oldest items from
        // dependency tree
        // backend recalculates new dates for all children
        const mappedEvents = arrayToObjectByKey(allEventsToUpdate, '_id');

        allEventsToUpdate.forEach(event => {
            if (event.childIds?.length) {
                event.childIds.forEach(childId => {
                    delete mappedEvents[childId];
                });
            }
        });

        allEventsToUpdateWithoutChildren = Object.values(mappedEvents);
    } else {
        allEventsToUpdateWithoutChildren = allEventsToUpdate;
    }

    const areRepeatedUpdated = allEventsToUpdateWithoutChildren.some(event => event.repeat && event.repeatId);

    allEventsToUpdate.forEach(event => {
        const id = event.id || event._id;
        if (id) {
            eventsIdsMoving.add(id);
        }
    });

    invalidateRowsByIds(Array.from(initialRowIds));
    if (!areRepeatedUpdated) {
        args.loaded();
        store.dispatch(updateMultipleBookings.request(allEventsToUpdateWithoutChildren, {}, onSuccess, onFailure));
        return;
    }

    store.dispatch(
        showConfirmationModal(
            () => {
                args.loaded();
                store.dispatch(
                    updateMultipleBookings.request(
                        allEventsToUpdateWithoutChildren.map(event => {
                            if (!event.repeat || !event.repeatId) {
                                return event;
                            }
                            return {
                                ...event,
                                repeat: false,
                                repeatId: null,
                                repeatTimes: 0,
                            };
                        }),
                        {
                            createRepeatFromExisting: false,
                        },
                        onSuccess,
                        onFailure
                    )
                );
            },
            REPEAT_MODAL_TYPES.BOOKING.title,
            () => (
                <>
                    <Typography paragraph>{REPEAT_MODAL_TYPES.BOOKING.description(true)}</Typography>
                    <Alert color="warning">
                        <strong>Warning!</strong> If you update just selected bookings, they will be updated to the new
                        position and removed from the repeat cycle. If you update all, all bookings will be updated to
                        the new position.
                    </Alert>
                </>
            ),
            {
                withEscButton: true,
                withCancel: true,
                confirmButtonText: 'Update selected',
                confirmButtonDataCy: 'button--update-this',
                cancelActionOnHide: true,
                onReject: () => {
                    allEventsToUpdate.forEach(event => {
                        const id = event.id || event._id;
                        if (id) {
                            eventsIdsMoving.delete(id);
                        }
                    });
                    scheduler.clearSelection();
                    args.preventDefault();
                    args.loaded();
                },
                additionalButtons: [
                    {
                        text: 'Update all',
                        dataCy: 'button--update-all',
                        onClick: () => {
                            args.loaded();
                            store.dispatch(
                                updateMultipleBookings.request(
                                    allEventsToUpdateWithoutChildren,
                                    {
                                        createRepeatFromExisting: true,
                                        modifyAllRepeated: true,
                                        removeRemovedRepeated: true,
                                    },
                                    onSuccess,
                                    onFailure
                                )
                            );
                        },
                    },
                ],
            }
        )
    );
}

function includeDeadlineMoveIntoBooking(args) {
    const mapped = args.multimove.reduce(
        (acc, multiMoveEntry) => {
            const eventData = multiMoveEntry.event.data;

            if (eventData.deadline) {
                acc.deadlines[eventData.booking.id] = multiMoveEntry;
            } else if (!eventData.phase && !eventData.milestone && !eventData.datesEvent) {
                acc.bookings[eventData.id] = multiMoveEntry;
            }

            return acc;
        },
        {
            bookings: {},
            deadlines: {},
        }
    );

    Object.values(mapped.deadlines).forEach(deadlineMove => {
        const bookingId = deadlineMove.event.data.booking.id;
        let relatedBooking = mapped.bookings[bookingId];

        if (relatedBooking) {
            relatedBooking.event.data.deadlineDate = deadlineMove.start;
            delete mapped.deadlines[bookingId];
        }
    });

    return {
        bookings: Object.values(mapped.bookings),
        deadlines: Object.values(mapped.deadlines),
    };
}
