import { push } from 'connected-react-router';
import { call, put, takeLatest, takeEvery, select, fork, cancelled, delay } from 'redux-saga/effects';
import { includes } from 'lodash';
import {
    getResourceApprovees,
    createResourceRequest,
    duplicateResourceRequest,
    getResourcesRequest,
    updateResourceRequest,
    getResourceRequest,
    deleteResourceRequest,
    createMultipleResourcesRequest,
    getLoggedInUserRequest,
    generateCalendarKeyRequest,
    updateLoggedInResourceRequest,
    updateResourceApprovees,
    deleteResourceApprovees,
} from 'api/resource';
import {
    getApprovees,
    createResource,
    duplicateResource,
    getResources,
    getResource,
    deleteResource,
    updateResource,
    resetResourceValue,
    getLoggedInUser,
    generateCalendarKey,
    updateLoggedInResource,
    createMultipleResources,
    getProjectManagers,
    addApprovees,
    deleteApprovees,
} from 'actions/resourceActions';
import { changeActiveLinkFromLocation, changeAndOpenActiveLinkFromLocation } from 'actions/menu/content';
import { updateAccount } from 'actions/accountActions';
import { getCompanyTree } from './companyTreeSaga';
import { hideModal, hideModalType } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import * as actionTypes from 'actions/actionTypes';
import { getAccountSaga } from 'sagas/accountSaga';
import { getResources as getResourcesSelector, selectLastCallContext } from 'selectors/resource';
import { getCompanyId, makeGetKeyWords } from 'selectors/company';
import { makeRequestCancellable } from 'api/makeRequestCancellable';
import { isSchedulerView } from '../utils/isSchedulerView';
import { STATUS_ACTIVE, STATUS_NON_BOOKABLE } from '../enums/resourceEnum';
import { resetScheduler } from '../actions/schedulerActions';
import { callResourceGroups } from './resourceGroupSaga';
import { getDefaultNotificationErrorHandler, handleErrors, otpValidationHandler } from './helpers/errorHandler';
import * as modalTypes from 'enums/modalTypeEnum';
import { makeGetResourceGroupById } from '../selectors/resourceGroup';
import { USER } from '../modules/scheduler/enums/groupTypeEnum';
import { GROUP } from '../enums/groupTypeEnum';
import { replaceGroupIdInLink } from '../utils/linkCreatorUtil';
import { getNotificationContent } from './helpers/bulkCreate';
import { getViewObjectSelector, selectRouteParams } from '../selectors/router';
import { PARENT } from '../modules/scheduler/enums/viewModeEnum';
import { dispatchRefreshBookings } from '../modules/scheduler/utils/schedulerUtil';
import { monitoring } from '../monitoring';
import { optimisticUpdates } from '../reducers/helpers/resourceReducerUtils';

const getRoute = state => state.router;
const getAccountResourceId = state => state.account.resourceId;
const getErrorMessage = (error, defaultMessage, email) => {
    let message = defaultMessage;
    const errorData = error?.response ? error?.response.data : error?.data;

    if (
        includes(
            [
                'EXCEEDED_PROJECT_MANAGER_COUNT',
                'EXCEEDED_RESOURCE_COUNT',
                'EXCEEDED_RESOURCE_COUNT_FULL',
                'EXCEEDED_RESOURCE_COUNT_LITE',
                'EXCEEDED_LICENSE_COUNT',
                'PASSWORD_STRENGTH',
            ],
            errorData?.error
        )
    ) {
        message = errorData?.message;
    }
    if ('user.exists.on.company' === errorData?.error) {
        message = errorData.message.replace('%s,', email || '');
    }

    return message;
};

function* handleApproveesRequest(action) {
    try {
        const approvees = yield call(getResourceApprovees, action.payload.resourceId);

        yield put(getApprovees.success(approvees));
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* handleAddApprovees(action) {
    try {
        yield call(updateResourceApprovees, action.payload.resourceId, action.payload.approveeIds);
        yield put(addApprovees.success(action.payload));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addApprovees.failure(action.payload));
        yield put(
            addNotification({ message: "Something went wrong... we couldn't updated approvers", type: 'danger' })
        );
    }
}

function* handleDeleteApprovees(action) {
    try {
        yield call(deleteResourceApprovees, action.payload.resourceId, action.payload.approveeIds);
        yield put(deleteApprovees.success(action.payload));
    } catch (error) {
        monitoring.captureException(error);
        yield put(deleteApprovees.failure(action.payload));
        yield put(
            addNotification({ message: "Something went wrong... we couldn't updated approvers", type: 'danger' })
        );
    }
}

function* handleCreateResource(action) {
    try {
        const response = yield call(createResourceRequest, action.payload.data);

        action.payload.callback && action.payload.callback(response);

        yield put(createResource.success(response, action.payload?.data?.resourceGroupsAdd || []));

        if (action.payload.redirect && response.link) {
            yield put(push(response.link));
            yield put(resetScheduler.request());
            yield put(changeAndOpenActiveLinkFromLocation());
        }
        yield put(hideModal());
        yield delay(1000);
        yield fork(callResourceGroups);
    } catch (error) {
        monitoring.captureException(error);

        yield put(
            addNotification({
                message: getErrorMessage(
                    error,
                    'Something went wrong... Please check if you filled all required fields',
                    action.payload.data.resource.email
                ),
                type: 'danger',
            })
        );
    }
}

function* handleDuplicateResource(action) {
    const { resourceId, resourceGroupId, callback } = action.payload || {};
    const resourceGroup = yield select(makeGetResourceGroupById(resourceGroupId));

    try {
        const duplicatedResource = yield call(duplicateResourceRequest, resourceId);
        const shouldAddToGroup =
            resourceGroup?.type === GROUP && resourceGroup?.groupType === USER && !resourceGroup?.isSmart;

        if (shouldAddToGroup) {
            yield call(updateResourceRequest, duplicatedResource._id, {
                _id: duplicatedResource._id,
                resourceGroupsAdd: [resourceGroupId],
            });
        }

        yield put(getResources.request(true));

        if (callback) {
            callback(duplicatedResource);
        }

        yield put(duplicateResource.success(duplicatedResource, shouldAddToGroup ? [resourceGroupId] : []));

        if (resourceGroup?.isSmart) {
            yield delay(1000);
            yield call(callResourceGroups);
        }

        if (duplicatedResource?.link) {
            yield put(push(replaceGroupIdInLink(duplicatedResource.link, resourceGroupId)));
            yield put(changeActiveLinkFromLocation());
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: getErrorMessage(error, "Something went wrong... We couldn't handle your request"),
                type: 'danger',
            })
        );
    }
}

function* handleGetResources(action) {
    const request = makeRequestCancellable(getResourcesRequest);
    const lastCallContext = yield select(selectLastCallContext);

    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';

    try {
        const resources = yield select(getResourcesSelector);
        if (resources.length && true !== action.payload.force && lastCallContext === callContext) {
            yield put(getResources.stop());
            return;
        }

        // Network call with correct company id.
        const companyId = yield select(getCompanyId);
        const response = yield call(request.call, { companyId, forScheduler: schedulerView });

        yield put(getResources.success(response, callContext));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "We couldn't load your resources", type: 'danger' }));
    } finally {
        if (yield cancelled()) {
            request.cancel();
        }
    }
}

function* handleUpdateResource(action) {
    try {
        yield put(hideModal());
        const accountResourceId = yield select(getAccountResourceId);
        const { body, id } = action.payload;

        // Do not send system groups ids, but return them in app flow for UI changes
        const reduxSystemResourceGroupsIds = (yield select(state => state.resourceGroupReducer.resourceGroups || []))
            .filter(resourceGroup => resourceGroup.groupType === 'SYSTEM')
            .map(systemResourceGroup => systemResourceGroup._id);

        const bodyForRequest = Object.assign({}, body);
        bodyForRequest.resourceGroupsAdd = (bodyForRequest.resourceGroupsAdd || []).filter(resourceGroupId => {
            return !reduxSystemResourceGroupsIds.includes(resourceGroupId);
        });
        bodyForRequest.resourceGroupsRemove = (bodyForRequest.resourceGroupsRemove || []).filter(resourceGroupId => {
            return !reduxSystemResourceGroupsIds.includes(resourceGroupId);
        });

        const resource = yield call(updateResourceRequest, id, bodyForRequest);
        if (accountResourceId === id) {
            yield fork(getAccountSaga);
        }

        action.payload?.callback && action.payload.callback(resource);

        yield put(
            updateResource.success(resource, {
                projectsAdd: body.projectsAdd,
                projectsRemove: body.projectsRemove,
                resourceGroupsAdd: body.resourceGroupsAdd,
                resourceGroupsRemove: body.resourceGroupsRemove,
            })
        );
        yield put(resetResourceValue.request('resource'));

        const shouldInform = optimisticUpdates.some(({ shouldUpdate }) => {
            return shouldUpdate(action);
        });

        if (shouldInform) {
            yield put(
                addNotification({
                    message:
                        'Your new capacity will be recalculated in the background and take a few minutes to update all dates',
                    type: 'info',
                })
            );
        }

        yield delay(1000);
        yield fork(callResourceGroups);
    } catch (error) {
        monitoring.captureException(error);

        yield put(updateResource.failure(action.payload.id, action.payload.body));
        yield put(
            addNotification({ message: getErrorMessage(error, "We couldn't update this resource"), type: 'danger' })
        );
    }
}

function* handleUpdateResourceSuccess(action) {
    const viewObject = yield select(getViewObjectSelector());
    const routeParams = yield select(selectRouteParams);

    const mode = window?.schedulerRef?.current?.control?.mode;

    if (
        mode === PARENT.value &&
        (viewObject.isResourceGroupView ||
            (viewObject.isSingleResourceView && routeParams.resourceId === action.payload.resource._id))
    ) {
        dispatchRefreshBookings();
    } else if (
        window?.schedulerRef?.current?.control?.resources.find(resource => resource.id === action.payload.resource._id)
    ) {
        dispatchRefreshBookings();
    }
}

function* handleUpdateResourceFailure(action) {
    const shouldRollback = optimisticUpdates.some(({ shouldUpdate }) => {
        return shouldUpdate(action);
    });

    if (shouldRollback) {
        yield put(getResources.request(true));
    }
}

function* handleGetResource(action) {
    try {
        const resource = yield call(getResourceRequest, action.payload.id);

        yield put(getResource.success(resource));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "We couldn't load data for this resource", type: 'danger' }));
    }
}

function* handleDeleteResource(action) {
    try {
        yield put(hideModal());
        const route = yield select(getRoute);
        const url = route.location.pathname;
        if (-1 !== route.location.pathname.indexOf(action.payload.id)) {
            yield put(
                push(
                    url.substring(0, url.indexOf(action.payload.id) - 1) +
                        url.substring(url.indexOf(action.payload.id) + action.payload.id.length)
                )
            );
        }
        yield call(deleteResourceRequest, action.payload.id);

        action.payload?.cb && action.payload.cb();

        yield put(deleteResource.success(action.payload.id));

        const modifiedRow = window.schedulerRef?.current?.control?.rows?.find(dpRow => {
            return dpRow.id?.includes(action.payload.id);
        });

        if (modifiedRow) {
            window.schedulerRef?.current?.control.rows.remove(modifiedRow.id);
        }

        dispatchRefreshBookings();
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "We couldn't delete this resource", type: 'danger' }));
    }
}

function* handleDeleteResourceSuccess() {
    const keywords = yield select(makeGetKeyWords());
    yield put(
        addNotification({
            message: `${keywords.resourceKeyWord} was deleted`,
            type: 'success',
        })
    );
}

function* handleCreateMultipleResources(action) {
    try {
        const response = yield call(createMultipleResourcesRequest, action.payload.data);
        yield fork(getCompanyTree);

        const mapWithMissingFields = (collection = []) => {
            return collection.map(resource => {
                return {
                    ...resource,
                    customFields: resource.currentCompanySpecific.customFields ?? [],
                    role: resource.currentCompanySpecific.role,
                    name: `${resource.currentCompanySpecific.firstName ?? ''} ${resource.currentCompanySpecific
                        .lastName ?? ''}`.trim(),
                };
            });
        };

        yield put(
            createMultipleResources.success({
                created: mapWithMissingFields(response.resources.created),
                updated: mapWithMissingFields(response.resources.updated),
            })
        );

        if (!action.payload.withoutNotification) {
            yield put(addNotification(getNotificationContent(response, 'resource')));
        }

        yield put(getResources.request(true));
        yield put(hideModal());

        if (action.payload.callback) {
            action.payload.callback(response.resources.created);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: error?.response?.data?.message || "Something went wrong... We couldn't create resources",
                type: 'danger',
            })
        );
    }
}

function* handleGetLoggedInUser() {
    try {
        const resource = yield call(getLoggedInUserRequest);

        yield put(getLoggedInUser.success(resource));
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* handleGenerateCalendarKey() {
    try {
        const generatedCalendarKey = yield call(generateCalendarKeyRequest);

        yield put(generateCalendarKey.success(generatedCalendarKey.key));
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* handleUpdateLoggedInResource(action) {
    try {
        const response = yield call(updateLoggedInResourceRequest, action.payload.data);

        yield put(updateLoggedInResource.success(response));
        yield put(
            updateAccount.request({
                firstName: response.currentCompanySpecific.firstName,
                lastName: response.currentCompanySpecific.lastName,
                email: response.email,
                thumbUrl: response.currentCompanySpecific.thumb,
            })
        );
        yield put(hideModalType(modalTypes.OTP_VERIFICATION_MODAL));
        yield put(
            addNotification({
                message: 'Your settings has been updated',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: "Something went wrong. Your settings couldn't be updated",
            })
        );
    }
}

function* handleGetProjectManagers() {
    const request = makeRequestCancellable(getResourcesRequest);
    const companyId = yield select(getCompanyId);

    try {
        const response = yield call(request.call, {
            companyId,
            forScheduler: false,
            pmFilter: true,
            status: [STATUS_ACTIVE.value, STATUS_NON_BOOKABLE.value],
        });
        yield put(getProjectManagers.success(response || []));
    } catch (error) {
        monitoring.captureException(error);
        yield put(getProjectManagers.failure());
        yield put(
            addNotification({
                message: getErrorMessage(error, "Something went wrong. We couldn't load project managers"),
                type: 'danger',
            })
        );
    } finally {
        if (yield cancelled()) {
            request.cancel();
        }
    }
}

export default function* resourceWatcher() {
    yield takeLatest(actionTypes.GET_APPROVEES['REQUEST'], handleApproveesRequest);
    yield takeLatest(actionTypes.ADD_APPROVEES['REQUEST'], handleAddApprovees);
    yield takeLatest(actionTypes.DELETE_APPROVEES['REQUEST'], handleDeleteApprovees);
    yield takeEvery(actionTypes.CREATE_RESOURCE['REQUEST'], handleCreateResource);
    yield takeLatest(actionTypes.DUPLICATE_RESOURCE['REQUEST'], handleDuplicateResource);
    yield takeLatest(actionTypes.GET_RESOURCES['REQUEST'], handleGetResources);
    yield takeLatest(actionTypes.GET_RESOURCE['REQUEST'], handleGetResource);
    yield takeEvery(actionTypes.UPDATE_RESOURCE['REQUEST'], handleUpdateResource);
    yield takeEvery(actionTypes.UPDATE_RESOURCE['SUCCESS'], handleUpdateResourceSuccess);
    yield takeEvery(actionTypes.UPDATE_RESOURCE['FAILURE'], handleUpdateResourceFailure);
    yield takeLatest(actionTypes.DELETE_RESOURCE['REQUEST'], handleDeleteResource);
    yield takeLatest(actionTypes.DELETE_RESOURCE['SUCCESS'], handleDeleteResourceSuccess);
    yield takeLatest(actionTypes.CREATE_MULTIPLE_RESOURCES['REQUEST'], handleCreateMultipleResources);
    yield takeLatest(actionTypes.GET_LOGGED_IN_USER['REQUEST'], handleGetLoggedInUser);
    yield takeLatest(actionTypes.UPDATE_LOGGED_IN_RESOURCE['REQUEST'], handleUpdateLoggedInResource);
    yield takeLatest(actionTypes.GENERATE_CALENDAR_KEY['REQUEST'], handleGenerateCalendarKey);
    yield takeLatest(actionTypes.GET_PROJECT_MANAGERS['REQUEST'], handleGetProjectManagers);
}
