import { difference } from 'lodash';

export const haveArraysDiff = (array1, array2) =>
    array1.length !== array2.length
        ? true
        : 0 < difference(array1, array2).length || 0 < difference(array2, array1).length;

export const haveInitialGroupsAndItemsArraysDiff = (initialGroups, groups, initialItems, items) =>
    (!!initialGroups &&
        haveArraysDiff(
            initialGroups.map(i => i._id),
            groups.map(i => i._id)
        )) ||
    (!!initialItems &&
        haveArraysDiff(
            initialItems.map(i => i._id),
            items.map(i => i._id)
        ));

export const hasTitleChanged = (initialTitle, title) => (initialTitle ? initialTitle !== title : false);

const hasGroupTypeChanged = (groupType, initialGroupType, isSmart, initialIsSmart) => {
    if (initialGroupType && null !== initialIsSmart)
        return initialGroupType !== groupType || initialIsSmart !== isSmart;
    return false;
};

export const haveItemsChangedUtil = state => {
    const {
        initialGroups,
        selectedGroups,
        initialItems,
        selectedItems
    } = state;

    return (
        haveInitialGroupsAndItemsArraysDiff(initialGroups, selectedGroups, initialItems, selectedItems)
    );
};


export const hasDataChangedUtil = state => {
    const {
        initialGroups,
        selectedGroups,
        initialItems,
        selectedItems,
        initialTitle,
        title,
        groupType,
        initialGroupType,
        isSmart,
        initialIsSmart,
    } = state;

    return (
        hasTitleChanged(initialTitle, title) ||
        hasGroupTypeChanged(groupType, initialGroupType, isSmart, initialIsSmart) ||
        haveInitialGroupsAndItemsArraysDiff(initialGroups, selectedGroups, initialItems, selectedItems)
    );
};
