import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { subDays, endOfDay } from 'date-fns';
import { FNS_DATE_FORMAT_LONG } from '../../../../../enums/dateFormatEnum';
import { formatDate } from '../../../../../utils/DateUtil';

export const setDayDate = (event, currentScale, newStartDate, newEndDate) => {
    let start = newStartDate.toDateLocal();
    let end = newEndDate.toDateLocal();

    if (currentScale !== CELLDURATION.value) {
        let endDayCheck = subDays(end, 1);
        const startDateChanged = start !== event.start();

        if (!startDateChanged && endDayCheck !== event.end()) {
            event.data.end = end;
        }

        if (newStartDate !== event.start()) {
            event.data.start = start;
        }

        if (newEndDate !== event.end()) {
            event.data.end = endOfDay(subDays(end, 1));
        }
    } else {
        event.data.end = newEndDate;
        event.data.start = newStartDate;
    }

    return {
        ...event,
        data: {
            ...event.data,
            start: formatDate(event.data.start, FNS_DATE_FORMAT_LONG, false),
            end: formatDate(event.data.end, FNS_DATE_FORMAT_LONG, false),
        },
    };
};
