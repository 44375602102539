import { setMFAError, validateOTPOnLogin } from 'actions/accountActions';
import { showOTPVerificationModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import { MFA_STATUS_CODES } from 'enums/authEnum';
import { put } from 'redux-saga/effects';
export const isOTPRequired = e => e?.data?.code === MFA_STATUS_CODES.NO_KEY;

export function* handleErrors(action, err, ...handlers) {
    const h = handlers.find(handler => !handler.predicate || handler.predicate(err));

    if (h) {
        yield h.handler(err, action);
    }
}

export const otpValidationHandler = {
    predicate: err => isOTPRequired(err),
    handler: function*(_, action) {
        yield put(showOTPVerificationModal(action));
    },
};

export const otpValidationOnLoginHandler = {
    predicate: err => isOTPRequired(err),
    handler: function*() {
        yield put(validateOTPOnLogin(true));
    },
};

export const getDefaultErrorHandler = (action, defaultMessage, errorCode) => {
    return {
        predicate: () => true,
        handler: function*(error) {
            const failureAction = typeof action.failure === 'function' ? action.failure : action;

            if ([MFA_STATUS_CODES.TOO_MANY_MFA_REQUESTS].includes(errorCode)) {
                yield put(setMFAError({ message: error?.data?.message ?? defaultMessage, errorCode }));
            }

            yield put(failureAction(error?.data?.message ?? defaultMessage, errorCode));
        },
    };
};

export const getDefaultNotificationErrorHandler = ({
    errorCode,
    message,
    defaultMessage,
    cleanUpAction = () => {},
}) => {
    return {
        predicate: () => true,
        handler: function*() {
            if ([MFA_STATUS_CODES.INCORRECT_OTP, MFA_STATUS_CODES.TOO_MANY_MFA_REQUESTS].includes(errorCode)) {
                yield put(setMFAError({ message: message ?? defaultMessage, errorCode }));
                return;
            }

            if (cleanUpAction) {
                yield cleanUpAction();
            }

            yield put(
                addNotification({
                    type: 'danger',
                    message: message ?? defaultMessage,
                })
            );
        },
    };
};
