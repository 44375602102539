import * as actionTypes from '../actions/actionTypes';
import { updateObject } from 'reducers/helpers/updater';
import createReducer from 'reducers/helpers/createReducer';

const initialCustomerState = {
    customers: [],
};

const setCustomers = (state, action) =>
    updateObject(state, {
        customers: action.payload.customers,
    });

const addCustomer = (state, action) => {
    const currentCustomers = [...state.customers];
    currentCustomers.push(action.payload.customer);

    return updateObject(state, {
        customers: currentCustomers,
    });
};

export default createReducer(initialCustomerState, {
    [actionTypes.GET_CUSTOMERS['SUCCESS']]: setCustomers,
    [actionTypes.CREATE_CUSTOMER['SUCCESS']]: addCustomer,
});
