import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isFunction, isObject } from 'lodash';
import { MoreVert } from '@material-ui/icons';
import { useIsExtensionInstalled, useRouteParams } from 'hooks';
import { PM } from 'enums/extensionShortIdEnum';
import { showResourceModal } from 'actions/modalActions';
import { updateResource, duplicateResource } from 'actions/resourceActions';
import { updateResourceGroup } from 'actions/resourceGroupActions';
import { showItemContextMenu } from 'actions/contextMenuActions';
import { resetScheduler, updateBookingSelection } from 'actions/schedulerActions';
import ResourceThumb from 'modules/scheduler/components/resourceThumb';
import { getCustomFieldLabel } from 'utils/customFieldUtil';
import ProjectManager from 'shared/projectManagerBadge';
import { TextWrapper, ItemContextMenu, SearchIconWrapper } from './styles';
import { dispatchRefreshDPResourcesEvent } from '../../utils/schedulerUtil';

const ResourceItem = props => {
    const {
        active,
        className,
        classNameActive,
        activateMe,
        to,
        id,
        classStore,
        subMenuVisibility,
        hasSubMenu,
        customIcon,
        label,
        icon: Icon,
        searchIcon: SearchIcon,
        subText,
        resource,
        contextMenu,
        toggleFilterVisibility,
        ...restOfProps
    } = props;
    const dispatch = useDispatch();
    const { resourceId, resourceGroupId } = useRouteParams();
    const projectSubtitleRefId = useSelector(state => state.companyReducer.company.settings.grid.resourceSubtitleRefId);
    const customFieldLabel = getCustomFieldLabel(resource.customFields, projectSubtitleRefId);
    const isPmExtensionInstalled = useIsExtensionInstalled(PM);

    const showContextMenu = () => {
        dispatch(
            showItemContextMenu(
                restOfProps.resourceGroupType,
                restOfProps.resourceGroup,
                resource,
                {
                    showModal: type =>
                        dispatch(
                            showResourceModal({
                                onSubmit: data => {
                                    return dispatch(updateResource.request(resource._id, data));
                                },
                                type: type,
                                resourceId: resource._id,
                            })
                        ),
                    duplicate: () =>
                        dispatch(
                            duplicateResource.request(
                                resource._id,
                                restOfProps.resourceGroup._id,
                                dispatchRefreshDPResourcesEvent
                            )
                        ),
                    updateGroup: data =>
                        dispatch(
                            updateResourceGroup.request({
                                id: restOfProps.resourceGroup._id,
                                data,
                                callback: dispatchRefreshDPResourcesEvent,
                            })
                        ),
                },
                'RESOURCE'
            )
        );
    };

    const onItemClick = event => {
        let currentId;
        if (resourceId && resourceGroupId) {
            currentId = `${resourceId}_${resourceGroupId}`;
        } else {
            currentId = `${resourceGroupId}_group_schedule`;
        }

        if (id === currentId) {
            event.preventDefault();
        } else {
            activateMe({ id, to });
            dispatch(resetScheduler.request());
            dispatch(updateBookingSelection.request());
        }
    };

    const toggleFilterVisibilityWrapper = useCallback(
        e => {
            // Prevent going to upper onCLick listener.
            e.stopPropagation();
            toggleFilterVisibility && toggleFilterVisibility();
        },
        [toggleFilterVisibility]
    );

    return (
        <div className="position-relative">
            <Link
                to={to}
                className={classNames(className, active && classNameActive, 'd-flex pl-3')}
                style={{ alignItems: 'center', height: '69px' }}
                onClick={onItemClick}
                data-cy={`menu--resource-item--link--${id}`}
            >
                {customIcon ? (
                    <ResourceThumb resource={resource} />
                ) : (
                    (isFunction(Icon) || isObject(Icon)) && (
                        <Icon
                            style={{ fontSize: '36px' }}
                            className={classNames(classStore.classIcon, 'float-left mr-3')}
                        />
                    )
                )}
                <TextWrapper className="float-left">
                    <span className="d-block text-truncate" title={label + ' | ' + subText}>
                        {isPmExtensionInstalled && resource.isProjectManager && <ProjectManager>PM</ProjectManager>}
                        {label}
                    </span>
                    {hasSubMenu && (
                        <i
                            className={classNames(
                                classStore.classStateIcon,
                                classStore.iconNamePrefix +
                                    (subMenuVisibility
                                        ? classStore.iconNameStateVisible
                                        : classStore.iconNameStateHidden)
                            )}
                        />
                    )}
                    {(subText || customFieldLabel) && (
                        <span className="item-sub-text text-truncate">{customFieldLabel || subText}</span>
                    )}
                </TextWrapper>
                {SearchIcon && (
                    <SearchIconWrapper className="pl-3" onClick={toggleFilterVisibilityWrapper}>
                        <SearchIcon />
                    </SearchIconWrapper>
                )}
            </Link>
            {contextMenu && (
                <ItemContextMenu onClick={showContextMenu} data-cy={`menu--resource-item--context-menu--${id}`}>
                    <MoreVert />
                </ItemContextMenu>
            )}
        </div>
    );
};

ResourceItem.propTypes = {
    resource: PropTypes.object,
    resourceGroupType: PropTypes.string,
    resourceGroup: PropTypes.object,
    subText: PropTypes.string,
    contextMenu: PropTypes.bool,
};

ResourceItem.defaultProps = {
    resource: {},
    subText: '',
    resourceGroup: {},
    resourceGroupType: 'SYSTEM',
    contextMenu: false,
};

export default ResourceItem;
