import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { NavLink } from 'reactstrap';
import { isFunction, isObject } from 'lodash';
import { MoreVert } from '@material-ui/icons';
import { TYPE_REGULAR, TYPE_EVENT } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { GROUP } from 'enums/groupTypeEnum';
import { showAddNewItemContextMenu } from 'actions/contextMenuActions';
import {
    showProjectModal,
    showResourceModal,
    showScheduleResourceModal,
    showSchedulerBuilderModal,
    showMissingSmartSchedulesExtensionModal,
    showMissingUnassignedExtensionModal,
} from 'actions/modalActions';
import { createProject } from 'actions/projectActions';
import { createResource } from 'actions/resourceActions';
import { ItemContextMenu } from './styles';
import { TYPE_TAB } from 'modules/scheduler/modals/builder/tabs';
import { useBooking } from '../../hooks/useBooking';

const NewItem = props => {
    const {
        icon: Icon,
        endIcon: EndIcon,
        disabled,
        builderType,
        itemIdType,
        action,
        classNameActive,
        className,
        active,
        type,
        classStore,
        contextMenu,
        label,
    } = props;
    const dispatch = useDispatch();
    const { createBooking } = useBooking();

    const onClick = () => {
        const shouldRedirect = true;
        const actions = {
            showMissingSmartSchedulesExtensionModal: () => {
                dispatch(showMissingSmartSchedulesExtensionModal());
            },
            showMissingUnassignedWorkExtensionModal: () => {
                dispatch(showMissingUnassignedExtensionModal());
            },
            showScheduleResourceModal: () => {
                window.notClearSchedulerSelection = true;
                dispatch(
                    showScheduleResourceModal({
                        onSubmit: data => {
                            return createBooking(data.bookings, true, data.tasks);
                        },
                    })
                );
            },
            showResourceModal: () =>
                dispatch(
                    showResourceModal({
                        onSubmit: data => dispatch(createResource.request(data, undefined, shouldRedirect)),
                    })
                ),
            showUnassignedModal: () =>
                dispatch(
                    showResourceModal({
                        onSubmit: data => dispatch(createResource.request(data, undefined, shouldRedirect)),
                        type: TYPE_UNASSIGNED.value,
                    })
                ),
            showProjectModal: () =>
                dispatch(
                    showProjectModal({
                        onSubmit: data => dispatch(createProject.request(data, undefined, shouldRedirect)),
                        type: TYPE_REGULAR.value,
                    })
                ),
            showEventModal: () =>
                dispatch(
                    showProjectModal({
                        onSubmit: data => dispatch(createProject.request(data, undefined, shouldRedirect)),
                        type: TYPE_EVENT.value,
                    })
                ),
        };
        if (actions[action]) {
            actions[action]();
        } else if (builderType) {
            dispatch(
                showSchedulerBuilderModal({
                    itemTypeName: builderType,
                    itemId: null,
                    groupType: itemIdType || GROUP,
                    initialTab: TYPE_TAB,
                })
            );
        }
    };

    return (
        <div className="position-relative">
            <NavLink
                href="#"
                className={classNames(
                    className,
                    active && classNameActive,
                    !!EndIcon && 'with-end-icon',
                    disabled && 'menu-link-gray'
                )}
                onClick={onClick}
            >
                {(isFunction(Icon) || isObject(Icon)) && (
                    <Icon className={classNames(classStore.classIcon, disabled && 'menu-link-gray')} />
                )}
                <span>&nbsp;{label}</span>
                {(isFunction(EndIcon) || isObject(EndIcon)) && <EndIcon className={classStore.classEndIcon} />}
            </NavLink>
            {contextMenu && (
                <ItemContextMenu onClick={() => dispatch(showAddNewItemContextMenu(type))}>
                    <MoreVert />
                </ItemContextMenu>
            )}
        </div>
    );
};

NewItem.propTypes = {
    icon: PropTypes.any,
    endIcon: PropTypes.any,
    disabled: PropTypes.bool,
    builderType: PropTypes.any,
    itemIdType: PropTypes.any,
    action: PropTypes.any,
    classNameActive: PropTypes.any,
    className: PropTypes.any,
    active: PropTypes.any,
    type: PropTypes.any,
    classStore: PropTypes.any,
    contextMenu: PropTypes.any,
    label: PropTypes.any,
};

export default NewItem;
