import styled from 'styled-components';
import { NavLink } from 'reactstrap';

export const Label = styled.span`
    color: #b3b3b3;
    font-size: 11px;
    display: block;
    margin-left: ${props => (props.addMargin ? '23px' : undefined)};
`;

export const MenuRow = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0;

    &:hover {
        background-color: #f9f7f7;
    }
`;

export const AdditionalRow = styled.div`
    width: 100%;
    ${props => (!props.unsetHeight ? 'height: 100%' : '')};
    justify-content: left;
    display: flex;
    align-items: center;
    padding: 0.2rem 1.5rem;
`;

export const RequestLabelNote = styled.span`
    font-size: 10px;
    margin-right: 4px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: ${props => (props.mode === 'S' ? '0px' : '5px')};
    color: #ffedba;
    align-items: center;
    margin-top: -2px;
    margin-left: ${props => (props.isToolTip ? '-2px' : '0px')};
    background-color: ${props => (props.isToolTip ? 'rgba(206, 206, 206, 0.4)' : 'rgba(255, 255, 255, 0.3)')};
    padding: ${props => (props.mode === 'M' ? '2px 3px' : props.mode === 'S' ? '0px' : '4px 5px')};
    box-sizing: content-box;
    display: flex;

    & svg {
        font-size: 15px;
    }

    & span {
        margin-left: 2px;
        padding-right: 3px;
    }
`;

export const PrivateLabel = styled.span`
    font-size: 10px;
    padding: ${props => (props.mode === 'M' ? '2px 3px' : props.mode === 'S' ? '0px' : '4px 5px')};
    margin-right: 4px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: ${props => (props.mode === 'S' ? '0px' : '5px')};
    color: #232323;
    display: flex;
    align-items: center;
    margin-top: -2px;
    margin-left: ${props => (props.isToolTip ? '-2px' : '0px')};
    background-color: ${props => (props.isToolTip ? 'rgba(206, 206, 206, 0.4)' : 'rgba(255, 255, 255, 0.3)')};
    box-sizing: content-box;

    & svg {
        font-size: 15px;
    }

    & span {
        margin-left: 2px;
        padding-right: 2px;
    }
`;

export const RequestedLabel = styled(PrivateLabel)``;

export const ActiveNote = styled.i`
    font-size: 14px;
    display: flex;
    align-items: center;
    height: inherit;
    padding-right: 5px;
    color: #ffc;
`;

export const Link = styled(NavLink)`
    &.inactive {
        &:hover {
            color: rgba(108, 108, 108, 0.93) !important;
        }
    }
`;
