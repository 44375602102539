import { axiosInstance } from './axiosInstance';

export const getResourceGroup = id =>
    axiosInstance
        .get(`resourceGroup/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getResourceGroupsRequest = cancelToken => forScheduler =>
    axiosInstance
        .get(`resourceGroup?forScheduler=${forScheduler ? true : false}`, { cancelToken })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateResourceGroupRequest = (data, id) =>
    axiosInstance
        .put(`resourceGroup/v2/${id}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createResourceGroupRequest = data =>
    axiosInstance
        .post('resourceGroup/v2', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const resourceGroupResourcesRequest = id =>
    axiosInstance
        .get(`resourceGroup/${id}/resources`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteProjectGroupRequest = id =>
    axiosInstance
        .delete(`resourceGroup/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateTopLevelResourceGroupOrderRequest = data =>
    axiosInstance
        .put('resourceGroup', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const addApproverToGroupsRequest = (resourceId, groupIds) =>
    axiosInstance
        .put(`resourceGroup/addApproverToGroups/${resourceId}`, {
            groupIds,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const removeApproverFromGroupsRequest = (resourceId, groupIds) =>
    axiosInstance
        .put(`resourceGroup/removeApproverFromGroups/${resourceId}`, {
            groupIds,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
