import styled from 'styled-components';

export const Item = styled.li`
    padding: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    border-bottom: 1px solid #e9e9e9;
    color: #5d5d5d;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    height: 40px !important;

    &:hover {
        background: #efefef;
    }
`;
