import { useCallback } from 'react';
import { useHasRights, useIsExtensionInstalled } from '../../../../hooks';
import { RESOURCE_REQUEST } from '../../../../enums/extensionShortIdEnum';
import { canCreateResourceRequest } from './canCreateResourceRequest';

const resourceRequestsRoleRights = [
    {
        rights: ['manageAllResourceRequests'],
        rule: ['one'],
        name: 'hasManageAllResourceRequestsRights',
    },
    {
        rights: ['canRequestResource'],
        rule: ['one'],
        name: 'hasCreateRequestResourceRights',
    },
    {
        rights: ['canViewResourceRequestsOnScheduler'],
        rule: ['one'],
        name: 'hasViewResourceRequestRights',
    },
];

export function useResourceRequestRoles() {
    const { hasManageAllResourceRequestsRights, hasCreateRequestResourceRights } = useHasRights(
        resourceRequestsRoleRights
    );

    const isRequestExtensionsInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);

    const canRequestResource = useCallback(
        ({ resource, projectOrEvent }) => {
            return canCreateResourceRequest({
                hasManageAllResourceRequestsRights,
                hasCreateRequestResourceRights,
                isRequestExtensionsInstalled,
                resource,
                projectOrEvent,
            });
        },
        [hasCreateRequestResourceRights, hasManageAllResourceRequestsRights, isRequestExtensionsInstalled]
    );

    return {
        canRequestResource,
    };
}
