import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { head } from 'lodash';
import Link from 'shared/link';

class DefaultLink extends Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        classNameActive: PropTypes.string.isRequired,
        classNameHasActiveChild: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        hasActiveChild: PropTypes.bool.isRequired,
        to: PropTypes.string,
        externalLink: PropTypes.bool,
        hasSubMenu: PropTypes.bool.isRequired,
        toggleSubMenu: PropTypes.func,
        activateMe: PropTypes.func.isRequired,
        children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
        openInNewTab: PropTypes.bool,
    };

    componentDidMount() {
        this.to = this.props.to;
        if ('/' !== head(this.to)) this.to = `/${this.to}`;

        this.props.history.listen(this.onLocationChange);
        this.onLocationChange(this.props.location);
    }

    onLocationChange = event => {
        if (!event) {
            return;
        }
        let location = event.hasOwnProperty('location') ? event.location : event;
        if ((location.pathname + location.search || '/') === this.to) {
            this.props.activateMe({
                id: this.props.id,
                to: this.props.to,
            });
        }
    };

    onLinkClick = event => {
        const { onClick, disabled } = this.props;
        !disabled && this.onLocationChange(event);
        onClick && onClick();
    };

    render() {
        const {
            className,
            classNameActive,
            classNameHasActiveChild,
            active,
            hasActiveChild,
            to,
            externalLink,
            hasSubMenu,
            toggleSubMenu,
            children,
            openInNewTab,
            style,
        } = this.props;

        if (hasSubMenu || externalLink) {
            return (
                <a
                    data-cy={this.props.dataCy}
                    className={classNames(className, hasActiveChild && classNameHasActiveChild)}
                    style={style}
                    target={externalLink && openInNewTab ? '_blank' : undefined}
                    href={to}
                    onClick={toggleSubMenu}
                >
                    {children}
                </a>
            );
        }

        if (to && to !== '') {
            return (
                <Link
                    dataCy={this.props.dataCy}
                    style={style}
                    onClick={this.onLinkClick}
                    className={classNames(className, active && classNameActive)}
                    to={to}
                >
                    {children}
                </Link>
            );
        }

        return (
            <span
                data-cy={this.props.dataCy}
                style={style}
                onClick={this.onLinkClick}
                className={classNames(className, active && classNameActive)}
            >
                {children}
            </span>
        );
    }
}

export default withRouter(DefaultLink);
