import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { formatDate, isDaypilotDate } from 'utils/DateUtil';
import { repeatTimesValidator, startDateValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        start: {
            type: Date,
            required: false,
            defaultValue: new Date(),
            validators: [startDateValidator('end')],
            validatorInterdependent: true,
        },
        end: {
            type: Date,
            required: false,
            defaultValue: new Date(),
        },
        interval: {
            type: String,
            required: false,
            defaultValue: 'NONE',
        },
        backgroundColor: {
            type: String,
            required: false,
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = currentSelectedBooking => {
    const currentSelectedBookingStart = isDaypilotDate(currentSelectedBooking.start)
        ? new Date(currentSelectedBooking.start.value)
        : currentSelectedBooking.start;
    const currentSelectedBookingEnd = isDaypilotDate(currentSelectedBooking.end)
        ? new Date(currentSelectedBooking.end.value)
        : currentSelectedBooking.end;

    return Object.assign({}, formSchema.getDefaultValues(), {
        start: currentSelectedBookingStart,
        end: currentSelectedBookingEnd,
        interval: currentSelectedBooking.interval,
        repeatTimes: currentSelectedBooking.repeatTimes,
        backgroundColor: currentSelectedBooking.backColor,
        useProjectColor: (currentSelectedBooking || {}).hasOwnProperty('useProjectColor')
            ? currentSelectedBooking.useProjectColor
            : true,
    });
};

export const mapFormToRequest = values => ({
    start: formatDate(values.start, 'yyyy-MM-dd HH:mm'),
    end: formatDate(values.end, 'yyyy-MM-dd HH:mm'),
    interval: values.interval,
    repeatTimes: parseInt(values.repeatTimes),
    repeat: 0 < values.repeatTimes && 'NONE' !== values.interval,
    backgroundColor: values.backgroundColor,
    useProjectColor: values.useProjectColor,
});
