import React, { useState } from 'react';
import classnames from 'classnames';
import { FormGroup, Label } from 'reactstrap';
import { DropdownList } from 'react-widgets';
import virtualize from 'react-widgets-virtualized';

let VirtualDropdownList = virtualize(DropdownList);

export const DropdownField = props => {
    const {
        input,
        label,
        placeholder,
        disabled,
        valueField,
        filter,
        allowCreate,
        onCreate,
        textField,
        width,
        itemComponent,
        valueComponent,
        dropUp,
        data,
        wrapperClassName,
        meta: { touched, error },
        messages,
        groupBy,
        groupComponent,
        dataCy,
    } = props;

    const [searchTerm, setSearchTerm] = useState();

    return (
        <FormGroup data-cy={dataCy} className={classnames('px-0', wrapperClassName, width)}>
            {label && (
                <Label for={input.name} className="form-control-label">
                    {label}
                </Label>
            )}
            <VirtualDropdownList
                {...input}
                className={classnames({ 'is-invalid': touched && error })}
                containerClassName={classnames('form-control', {
                    'is-invalid': touched && error,
                })}
                onBlur={() => input.onBlur()}
                valueField={valueField}
                filter={filter}
                dropUp={dropUp}
                allowCreate={allowCreate}
                onCreate={onCreate}
                itemComponent={itemComponent}
                valueComponent={valueComponent}
                placeholder={placeholder}
                textField={textField}
                disabled={disabled}
                data={data}
                type="uniform"
                length={data.length}
                renderItem={null}
                messages={messages}
                groupBy={groupBy}
                groupComponent={groupComponent}
                searchTerm={searchTerm}
                onSearch={setSearchTerm}
                onToggle={() => setSearchTerm('')}
            />
            <div className="invalid-feedback text-left">{touched && error && <span>{error}</span>}</div>
        </FormGroup>
    );
};

DropdownField.defaultProps = {
    input: {},
    label: '',
    inline: false,
    placeholder: '',
    itemComponent: null,
    valueComponent: null,
    dropUp: false,
    disabled: false,
    valueField: '',
    filter: true,
    textField: '',
    wrapperClassName: '',
    data: [],
    meta: {},
    groupBy: null,
    groupComponent: null,
    dataCy: null,
};

export default React.memo(DropdownField);
