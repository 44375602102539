/* eslint-env browser */
import { refreshBookings } from 'modules/scheduler/utils/schedulerUtil';
import { store } from '../../../../store';

// Now autorefresh will refresh with filters
export default (viewObject, schedulerRef, canViewMilestonesOrPhases, canViewProjectDates, dispatch) => async () => {
    const {
        bookingStatuses,
        bookingCategories,
        bookingProjectsEvents,
        bookingResourcesUW,
    } = store.getState().scheduler.filters;
    const filters = {
        bookingStatuses,
        bookingCategories,
        bookingProjectsEvents,
        bookingResourcesUW,
    };
    await refreshBookings({
        viewObject,
        schedulerRef,
        filters,
        canViewMilestonesOrPhases,
        canViewProjectDates,
        dispatch,
    });
};
