import { useCallback } from 'react';
import {KEYS} from '../enums/keysEnum';

export const useStopPropagationOnKeyDown = () => useCallback(event => {
    if ([KEYS.ESCAPE, KEYS.ARROW_UP, KEYS.ARROW_DOWN].includes(event.key)) {
        return;
    }

    event.stopPropagation();
}, []);
