import styled from 'styled-components';
import { Layout } from '@hub-mono/ui';
import { NextVacation, SickDays, UpcomingPublicHolidays } from '../index';
import React from 'react';

const StyledUpcomingEventsInfoTilesWrapper = styled(Layout)`
    & > * {
        flex: 1 1 0;
        width: 0;
    }
`;

export const UpcomingEventsInfoTilesWrapper = ({ resourceId, statistics, companySettings }) => {
    return (
        <StyledUpcomingEventsInfoTilesWrapper gap={16}>
            <NextVacation
                resourceId={resourceId}
                upcomingEvents={statistics.get('upcomingEvents')}
                defaultVacationName={companySettings.vacationEventName}
            />
            <UpcomingPublicHolidays upcomingEvents={statistics.get('upcomingEvents')} />
            <SickDays sickDays={statistics.get('sick_leave')} sickDaysId={companySettings.sickLeaveId} />
        </StyledUpcomingEventsInfoTilesWrapper>
    );
};
