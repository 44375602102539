import { useMemo } from 'react';
import {
    REQUEST_MISSING_DATES,
    REQUEST_NO_PERMISSIONS,
    SCHEDULE_MISSING_DATES,
    SCHEDULE_NO_PERMISSIONS
} from './tooltipTexts';

export const useDisabledTooltipLogic = ({
    disabled,
    disabledTooltip,
    hasRights,
    isNewContextMenu,
    selectionExists,
    isSchedule
}) => {
    return useMemo(() => {
        if (disabled && disabledTooltip) {
            return {
                isDisabled: disabled,
                tooltip: disabledTooltip,
            };
        }
        const noPermTooltip = isSchedule ? SCHEDULE_NO_PERMISSIONS : REQUEST_NO_PERMISSIONS
        const missingDatesTooltip = isSchedule ? SCHEDULE_MISSING_DATES : REQUEST_MISSING_DATES

        if (!hasRights) {
            return {
                isDisabled: true,
                tooltip: noPermTooltip,
            };
        }

        if (isNewContextMenu) {
            return {
                isDisabled: false,
            };
        }

        return {
            isDisabled: !selectionExists,
            tooltip: selectionExists ? undefined : missingDatesTooltip,
        };
    }, [disabled, disabledTooltip, hasRights, isNewContextMenu, selectionExists, isSchedule]);
};
