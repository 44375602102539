import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { showConfirmationModal } from 'actions/modalActions';
import ConfirmDialog from './confirmDialog';
import { ButtonElement } from './styles';

const ConfirmButton = props => {
    const { onConfirm, title, message, withModal, ...restOfProps } = props;
    const dispatch = useDispatch();
    const [showDialog, toggleConfirmDialogState] = useState();
    const showModal = () => dispatch(showConfirmationModal(onConfirm, title, message));
    const Icon = restOfProps.buttonIcon;
    const toggleConfirmDialog = () => {
        if (withModal) {
            showModal();
        } else {
            toggleConfirmDialogState(!showDialog);
        }
    };
    const confirm = data => {
        onConfirm(data);
        if (!withModal) {
            toggleConfirmDialogState(!showDialog);
        }
    };

    return (
        <div style={restOfProps.wrapperStyles}>
            <ButtonElement
                backgroundcolor={restOfProps.backgroundColor}
                className={restOfProps.buttonClass}
                fontcolor={restOfProps.color}
                onClick={toggleConfirmDialog}
                style={restOfProps.buttonStyles}
            >
                {Icon && <Icon />}
                {restOfProps.buttonText.length ? restOfProps.buttonText : null}
            </ButtonElement>
            {!withModal && (
                <ConfirmDialog
                    title={title}
                    message={message}
                    onConfirm={confirm}
                    onReject={toggleConfirmDialog}
                    show={showDialog}
                />
            )}
        </div>
    );
};

ConfirmButton.propTypes = {
    backgroundColor: PropTypes.string,
    buttonClass: PropTypes.string,
    buttonIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    buttonStyles: PropTypes.object,
    buttonText: PropTypes.string,
    color: PropTypes.string,
    withModal: PropTypes.bool,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    wrapperStyles: PropTypes.object,
};

ConfirmButton.defaultProps = {
    backgroundColor: 'transparent',
    buttonClass: '',
    buttonIcon: null,
    buttonStyles: {},
    buttonText: '',
    withModal: false,
    color: 'inherit',
    wrapperStyles: {},
};

export default ConfirmButton;
