import styled from 'styled-components';

export const Content = styled.div`
    position: absolute;
    top: 0;
    background: #fff;
    z-index: 9999;
    width: 100%;
    height: 100%;
`;

export const Wrapper = styled.div`
    position: absolute;
    text-align: center;
    width: 237px;
    top: 40%;
    left: 50%;
    margin-left: -118px;
    margin-top: -45px;
    p {
        margin: 10px 0;
        line-height: 20px;
        font-size: 16px;
        font-weight: 400;
    }
`;
