import { addDays, format, subDays } from 'date-fns';
import { formatDate } from 'utils/DateUtil';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import { calculateUnavailabilityIntervals } from './calculateUnavailabilityIntervals';

export const calculateUnavailabilityDays = (currentSelection, isHourScale, companyWeekdays, currentResource) => {
    const create = [];
    if (isHourScale) {
        const from = format(currentSelection.start, 'yyyy-MM-dd');
        const to = format(currentSelection.end, 'yyyy-MM-dd');
        if (from === to) {
            const intervals = calculateUnavailabilityIntervals(
                currentSelection,
                companyWeekdays,
                currentResource.customAvailabilities?.[0]?.weekDays || {},
                currentResource.useResourceAvailability
            );
            create.push({
                from: formatDate(currentSelection.start, BOOKING_FORMAT, false),
                to: formatDate(currentSelection.end, BOOKING_FORMAT, false),
                intervals,
                minutes: intervals.reduce((acc, interval) => {
                    return acc + (interval.end - interval.start);
                }, 0),
            });
        } else {
            const intervals = calculateUnavailabilityIntervals(
                currentSelection,
                companyWeekdays,
                currentResource.customAvailabilities?.[0]?.weekDays || {},
                currentResource.useResourceAvailability
            );
            create.push({
                from: formatDate(currentSelection.start, BOOKING_FORMAT, false),
                to: formatDate(currentSelection.start, BOOKING_FORMAT, false),
                intervals: intervals[0],
                minutes: intervals[0].reduce((acc, interval) => {
                    return acc + (interval.end - interval.start);
                }, 0),
            });
            const dayAfterStart = format(addDays(currentSelection.start, 1), 'yyyy-MM-dd');
            const dayBeforeEnd = format(subDays(currentSelection.end, 1), 'yyyy-MM-dd');
            if (dayAfterStart !== to && dayBeforeEnd !== from) {
                create.push({
                    from: formatDate(addDays(currentSelection.start, 1), BOOKING_FORMAT, false),
                    to: formatDate(subDays(currentSelection.end, 1), BOOKING_FORMAT, false),
                    intervals: [],
                });
            }
            create.push({
                from: formatDate(currentSelection.end, BOOKING_FORMAT, false),
                to: formatDate(currentSelection.end, BOOKING_FORMAT, false),
                intervals: intervals[1],
                minutes: intervals[1].reduce((acc, interval) => {
                    return acc + (interval.end - interval.start);
                }, 0),
            });
        }
    } else {
        create.push({
            from: formatDate(currentSelection.start, BOOKING_FORMAT, false),
            to: formatDate(currentSelection.end, BOOKING_FORMAT, false),
            intervals: [],
        });
    }

    return create;
};
