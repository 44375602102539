import React from 'react';
import PropTypes from 'prop-types';
import { FilterNone, FileCopy } from '@material-ui/icons';
import ActionItem from './../../shared/actionItem';

const ClipBoardActions = ({ onClone, onCopy }) => (
    <>
        <ActionItem text="Duplicate" onClick={onClone} className="edit-booking-mm" dataCy="button--duplicate-booking">
            <FilterNone />
        </ActionItem>
        <ActionItem text="Copy" onClick={onCopy} className="edit-booking-mm" dataCy="button--copy-booking">
            <FileCopy />
        </ActionItem>
    </>
);

ClipBoardActions.propTypes = {
    onClone: PropTypes.func.isRequired,
    onCopy: PropTypes.func.isRequired,
};

export default ClipBoardActions;
