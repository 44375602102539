import { axiosInstance } from './axiosInstance';

export const getTasks = filters =>
    axiosInstance
        .post('task/search', filters)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createTask = data =>
    axiosInstance
        .post('task/create', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateTaskById = (taskId, data) =>
    axiosInstance
        .put(`task/update/${taskId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteTaskById = taskId =>
    axiosInstance
        .delete(`task/${taskId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
