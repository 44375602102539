import moize from 'moize';
import { values, find, map, pluck } from 'underscore';

export const CURRENCY_USD = { currency: 'USD', symbol: '$' };
export const CURRENCY_SEK = { currency: 'SEK', symbol: 'kr' };
export const CURRENCY_EUR = { currency: 'EUR', symbol: '€' };
export const CURRENCY_GBP = { currency: 'GBP', symbol: '£' };
export const CURRENCY_CAD = { currency: 'CAD', symbol: '$' };
export const CURRENCY_NZD = { currency: 'NZD', symbol: '$' };
export const CURRENCY_AUD = { currency: 'AUD', symbol: '$' };
export const CURRENCY_INR = { currency: 'INR', symbol: '₹' };
export const CURRENCY_PLN = { currency: 'PLN', symbol: 'zł' };
export const CURRENCY_CHF = { currency: 'CHF', symbol: 'CHF' };
export const CURRENCY_NOK = { currency: 'NOK', symbol: 'kr' };
export const CURRENCY_DKK = { currency: 'DKK', symbol: 'kr' };
export const CURRENCY_AED = { currency: 'AED', symbol: 'AED' };
export const CURRENCY_CNY = { currency: 'CNY', symbol: '¥' };
export const CURRENCY_TRY = { currency: 'TRY', symbol: '₺' };
export const CURRENCY_MXN = { currency: 'MXN', symbol: '$' };
export const CURRENCY_COP = { currency: 'COP', symbol: '$' };
export const CURRENCY_CLP = { currency: 'CLP', symbol: '$' };
export const CURRENCY_JPY = { currency: 'JPY', symbol: '¥' };
export const CURRENCY_ZAR = { currency: 'ZAR', symbol: 'R' };
export const CURRENCY_SGD = { currency: 'SGD', symbol: '$' };
export const CURRENCY_ANG = { currency: 'ANG', symbol: 'ƒ' };
export const CURRENCY_KRW = { currency: 'KRW', symbol: '₩' };
export const CURRENCY_HUF = { currency: 'HUF', symbol: 'Ft' };
export const CURRENCY_IDR = { currency: 'IDR', symbol: 'Rp' };
export const CURRENCY_CZK = { currency: 'CZK', symbol: 'Kč' };
export const CURRENCY_HRK = { currency: 'HRK', symbol: 'kn' };
export const CURRENCY_ILS = { currency: 'ILS', symbol: '₪' };
export const CURRENCY_THB = { currency: 'THB', symbol: '฿' };

export const currencies = {
    CURRENCY_USD,
    CURRENCY_SEK,
    CURRENCY_EUR,
    CURRENCY_GBP,
    CURRENCY_CAD,
    CURRENCY_NZD,
    CURRENCY_AUD,
    CURRENCY_NOK,
    CURRENCY_DKK,
    CURRENCY_INR,
    CURRENCY_PLN,
    CURRENCY_CHF,
    CURRENCY_AED,
    CURRENCY_CNY,
    CURRENCY_TRY,
    CURRENCY_MXN,
    CURRENCY_COP,
    CURRENCY_CLP,
    CURRENCY_JPY,
    CURRENCY_ZAR,
    CURRENCY_SGD,
    CURRENCY_ANG,
    CURRENCY_KRW,
    CURRENCY_HUF,
    CURRENCY_IDR,
    CURRENCY_CZK,
    CURRENCY_HRK,
    CURRENCY_ILS,
    CURRENCY_THB,
};

export const getCurrencies = () => {
    const arrayOfCurrencies = values(currencies);

    return map(arrayOfCurrencies, item => ({ value: item.currency, name: `${item.currency} (${item.symbol})` }));
};

export const currenciesOptions = getCurrencies();

export const getCurrenciesSymbols = moize(
    () => {
        const arrayOfCurrencies = values(currencies);

        return pluck(arrayOfCurrencies, 'symbol');
    },
    { maxSize: 1 }
);

export const findCurrency = value => find(getCurrencies(), currency => currency.value === value) || {};

export const getCurrency = currency => {
    if (currencies['CURRENCY_' + currency]) {
        return currencies['CURRENCY_' + currency];
    }

    return {};
};

export const getCurrencySymbol = (currency) => {
    if (currencies['CURRENCY_' + currency]) {
        return currencies['CURRENCY_' + currency].symbol;
    }

    return ''
}

export const getDefaultCurrency = () => currencies[`CURRENCY_${CURRENCY_USD.currency}`].symbol;
