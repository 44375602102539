/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Form, FormGroup, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { validateSchema } from 'utils/schemaUtil';
import { validateResetPasswordToken } from 'api/account';
import { formSchema } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import { SubmitButton } from '../../shared/buttons';
import PasswordStrengthMeter from '../../shared/PasswordStrengthMeter';
import { resetPassword } from 'actions/accountActions';

const ResetPassword = ({ isAuthenticated, handleSubmit, match }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);

    const selector = formValueSelector('resetForm');
    const password = useSelector(state => selector(state, 'newPassword'));

    useEffect(() => {
        validateResetPasswordToken(match.params.token)
            .then(response => {
                if (!response.valid) {
                    setError('Your link has expired, please request a new one.');
                }
            })
            .catch(() => {
                setError('Something is wrong. Please check link from email.');
            });
    }, [match.params.token]);

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    const submit = values => {
        dispatch(resetPassword.request({ ...values, token: match.params.token }));
    };

    return (
        <PageTemplate type="reset">
            {error && <Alert color="danger"> {error}</Alert>}

            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        autoFocus={true}
                        name="newPassword"
                        label="New Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>

                <PasswordStrengthMeter password={password} />

                <FormGroup className="mb-4 mt-2">
                    <Field
                        component={renderTextField}
                        name="confirmNewPassword"
                        label="Repeat New Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>

                <SubmitButton type="submit" className="btn-block mb-2 shadow-sm border-0 py-2">
                    Reset Password
                </SubmitButton>
            </Form>
        </PageTemplate>
    );
};

ResetPassword.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'resetForm',
    validate: validateSchema(formSchema),
})(ResetPassword);
