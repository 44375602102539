/* eslint-env browser */
import moment from 'moment';
import { find, values } from 'lodash';
import { DATE_FORMAT_LONG } from 'enums/dateFormatEnum';

const HOUR_BASE_WIDHT = 40;
const DAY_BASE_WIDHT = 40;
const WEEK_BASE_WIDHT = 80;
const MONTH_BASE_WIDHT = 140;

const currentDate = moment().utc();
const getInitialDate = weekStartDay =>
    currentDate
        .clone()
        .subtract(currentDate.clone().diff(moment().startOf(0 === weekStartDay ? 'week' : 'isoWeek'), 'days'), 'days');
const BASE_WINDOW_WIDTH = 1920;

const multiplierForBigWidth = (currentCellWidth, baseWidth) => {
    const windowMultiplier = (window.innerWidth / BASE_WINDOW_WIDTH) * 30;
    const cellWidthMultiplier = (baseWidth / currentCellWidth) * 20;

    return { windowMultiplier, cellWidthMultiplier };
};

const multiplierForSmallWidth = (currentCellWidth, baseWidth) => {
    const windowWidthDiff = BASE_WINDOW_WIDTH - window.innerWidth;
    const windowMultiplier = (windowWidthDiff / BASE_WINDOW_WIDTH) * 100;
    const cellWidthDiff = baseWidth - currentCellWidth;
    const cellWidthMultiplier = (cellWidthDiff / baseWidth) * 100 * (cellWidthDiff / 10);

    return { windowMultiplier, cellWidthMultiplier };
};

/**
 * @returns {number}
 */
export const multiplier = (currentCellWidth, baseWidth) => {
    /** The old multiplier works better on small width
     * but it breaks everything on big widths
     * so it is used when width is smaller than default
     */
    const multiplier = currentCellWidth < baseWidth ? multiplierForSmallWidth : multiplierForBigWidth;
    const { windowMultiplier, cellWidthMultiplier } = multiplier(currentCellWidth, baseWidth);

    return windowMultiplier > cellWidthMultiplier ? windowMultiplier : cellWidthMultiplier;
};

export const CELLDURATION = {
    value: 'CellDuration',
    BEValue: 'SCALE_HOUR',
    daysByMultiplier: (currentCellWidth = HOUR_BASE_WIDHT, meanWorkDayDuration = 8) => {
        const cells = Math.ceil(window.innerWidth / currentCellWidth);
        const days = Math.ceil(cells / meanWorkDayDuration);

        // You need to increase the days value to make sure that the timeline length is greater than the current viewport.
        // It is recommended to use a timeline width at least 3 times the viewport width.
        // Take into account that the viewport size may be different on different screens.
        // Too short timeline may result in an infinite loop  of timeline shifts.
        return days * 6;
    },
    scrollingStepDays: (currentCellWidth, meanWorkDayDuration = 8) => {
        const cells = Math.ceil(window.innerWidth / currentCellWidth);
        const days = Math.ceil(cells / meanWorkDayDuration);
        return Math.floor(days);
    },
    startDate: () =>
        currentDate
            .clone()
            .startOf('day')
            .hour(9)
            .format(DATE_FORMAT_LONG),
    firstViewHeader: 'Day',
    firstViewHeaderFormat: 'dddd, MMM d',
    secondViewHeader: 'Hour',
    secondViewHeaderFormat: '',
    label: 'Hours Scale',
    display: 'Hour',
};

export const WEEK = {
    value: 'Week',
    BEValue: 'SCALE_WEEK',
    baseCellWidth: WEEK_BASE_WIDHT,
    daysByMultiplier: currentCellWidth => {
        const multipliedResult = parseInt(300 + (300 * multiplier(currentCellWidth, WEEK_BASE_WIDHT)) / 100);
        
        const cells = Math.floor(window.innerWidth / currentCellWidth);
        const days = cells * 7; // assume 7 days week

        // You need to increase the days value to make sure that the timeline length is greater than the current viewport.
        // It is recommended to use a timeline width at least 3 times the viewport width.
        // Take into account that the viewport size may be different on different screens.
        // Too short timeline may result in an infinite loop  of timeline shifts.
        // set to at least 4 to support 4k and zoom out customer case
        const recommendedNoOfDays = days * 4;

        return multipliedResult < recommendedNoOfDays ? recommendedNoOfDays : multipliedResult;
    },
    scrollingStepDays: currentCellWidth => parseInt(100 + (100 * multiplier(currentCellWidth, WEEK_BASE_WIDHT)) / 100),
    startDate: weekStartDay => getInitialDate(weekStartDay).format('YYYY-MM-DD'),
    firstViewHeader: 'Month',
    firstViewHeaderFormat: 'MMMM yyyy',
    secondViewHeader: 'Week',
    secondViewHeaderFormat: '',
    label: 'Weeks Scale',
    display: 'Week',
};

export const MONTH = {
    value: 'Month',
    BEValue: 'SCALE_MONTH',
    daysByMultiplier: currentCellWidth => {
        const multipliedResult = parseInt(500 + (500 * multiplier(currentCellWidth, MONTH_BASE_WIDHT)) / 100);

        const cells = Math.floor(window.innerWidth / currentCellWidth);
        const days = cells * 31; // assume 31 days in a month

        // You need to increase the days value to make sure that the timeline length is greater than the current viewport.
        // It is recommended to use a timeline width at least 3 times the viewport width.
        // Take into account that the viewport size may be different on different screens.
        // Too short timeline may result in an infinite loop  of timeline shifts.
        // set to at least 4 to support 4k and zoom out customer case
        const recommendedNoOfDays = days * 4;

        return multipliedResult < recommendedNoOfDays ? recommendedNoOfDays : multipliedResult;
    },
    scrollingStepDays: currentCellWidth => parseInt(100 + (100 * multiplier(currentCellWidth, MONTH_BASE_WIDHT)) / 100),
    startDate: () =>
        currentDate
            .clone()
            .subtract(currentDate.clone().diff(moment().startOf('month'), 'days'), 'days')
            .format('YYYY-MM-DD'),
    firstViewHeader: 'Year',
    firstViewHeaderFormat: 'yyyy',
    secondViewHeader: 'Month',
    secondViewHeaderFormat: 'MMM',
    label: 'Month Scale',
    display: 'Month',
};

export const DAY = {
    value: 'Day',
    BEValue: 'SCALE_DAY',
    daysByMultiplier: currentCellWidth => {
        const multipliedResult = parseInt(100 + (100 * multiplier(currentCellWidth, DAY_BASE_WIDHT)) / 100);
        const cells = Math.floor(window.innerWidth / currentCellWidth);

        // You need to increase the days value to make sure that the timeline length is greater than the current viewport.
        // It is recommended to use a timeline width at least 3 times the viewport width.
        // Take into account that the viewport size may be different on different screens.
        // Too short timeline may result in an infinite loop  of timeline shifts.
        // set to at least 4 to support 4k and zoom out customer case
        const recommendedNoOfDays = cells * 4;

        return multipliedResult < recommendedNoOfDays ? recommendedNoOfDays : multipliedResult;
    },
    scrollingStepDays: currentCellWidth => parseInt(20 + (20 * multiplier(currentCellWidth, DAY_BASE_WIDHT)) / 100),
    startDate: weekStartDay => getInitialDate(weekStartDay).format('YYYY-MM-DD'),
    firstViewHeader: 'Month',
    firstViewHeaderFormat: 'MMMM yyyy',
    secondViewHeader: 'Day',
    secondViewHeaderFormat: 'ddd d',
    label: 'Day Scale',
    display: 'Day',
};

export const THIS_WEEK = {
    ...DAY,
    display: 'This Week',
    label: 'This Week',
    startDate: weekStartDay => {
        return getInitialDate(weekStartDay).format('YYYY-MM-DD');
    },
    customSearchId: 'this_week',
    noActiveClass: true,
};

export const THIS_MONTH = {
    ...DAY,
    display: 'This Month',
    label: 'This Month',
    startDate: () => {
        return currentDate.startOf('month').format('YYYY-MM-DD');
    },
    customSearchId: 'this_month',
    noActiveClass: true,
};

export const scales = { CELLDURATION, DAY, WEEK, MONTH, THIS_WEEK, THIS_MONTH };

export const scalesArray = values(scales);

export const findByValue = value => find(values(scales), scale => scale.value === value);
export const findByBEValue = value => find(values(scales), scale => scale.BEValue === value);

export const findByCustomSearchId = customSearchId =>
    scalesArray.find(scale => scale.customSearchId === customSearchId);
