import React from 'react';

const ProjectDates = ({ renderMenu }) => (
    <>
        {renderMenu ? (
            <i className="fa fa-ellipsis-v booking-option-dots" title="Options" />
        ) : null}
        <div className="hub-dates-holder">
            <i className="fa fa-clock-o" />
        </div>
    </>
);

export default ProjectDates;
