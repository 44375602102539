/* eslint-env browser */
import { hasRole } from 'utils/rightsUtil';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { updateDeadlinesVisibility, updateVisibleLinksOnScheduler } from '../../utils/schedulerUtil';

export default (resourceRoleRights, updateBookingSelection, { editParentContextMenu, showEditContextMenu }) => args => {
    const scheduler = args.e.calendar;
    const selectedEvent = args.e.data;
    const isRegularBooking =
        !selectedEvent.phase && !selectedEvent.milestone && !selectedEvent.datesEvent && !selectedEvent.deadline;

    if (isRegularBooking) {
        if (selectedEvent.scale.BEValue !== CELLDURATION.BEValue && scheduler.scale === CELLDURATION.value) {
            scheduler.message(
                '<i class="fa fa-warning">&nbsp;</i> This booking was created in <strong>Days</strong>, and unfortunately cannot be moved in Hour View.',
                {
                    rawHtml: true,
                }
            );
        }

        updateBookingSelection(selectedEvent);
    } else if (
        (selectedEvent.phase && hasRole(resourceRoleRights, 'settingProjectPhases')) ||
        (selectedEvent.datesEvent && hasRole(resourceRoleRights, 'settingProjectDates')) ||
        (selectedEvent.milestone && hasRole(resourceRoleRights, 'settingProjectMs'))
    ) {
        updateBookingSelection(selectedEvent);
    }

    updateDeadlinesVisibility();
    updateVisibleLinksOnScheduler();

    const coords = {
        left: args.originalEvent.clientX,
        top: args.originalEvent.clientY,
    };

    const clearSelection = () => {
        scheduler.clearSelection();
    };

    const showContext = () => {
        const schedulerRef = {
            current: {
                control: {
                    events: scheduler.events,
                    rows: scheduler.rows,
                    scale: scheduler.scale,
                    mode: scheduler.mode,
                    clearSelection,
                },
            },
        };
        if (!selectedEvent.phase && !selectedEvent.milestone && !selectedEvent.datesEvent && !selectedEvent.deadline) {
            showEditContextMenu(schedulerRef, coords);
        } else if (selectedEvent.phase && hasRole(resourceRoleRights, 'settingProjectPhases')) {
            editParentContextMenu(schedulerRef, coords);
        } else if (selectedEvent.datesEvent && hasRole(resourceRoleRights, 'settingProjectDates')) {
            editParentContextMenu(schedulerRef, coords);
        }
    };

    if (Array.from(args.originalEvent?.srcElement?.classList || []).includes('booking-option-dots')) {
        showContext();
    }
};
