/**
 * src/containers/Item.js
 * Author: H.Alper Tuna <halpertuna@gmail.com>
 * Date: 16.09.2016
 */

import { connect } from 'react-redux';
import {
    changeSubMenuVisibility,
    changeActiveLinkId,
    toggleFilterVisibility,
} from 'actions/menu/content';
import { emitSelected } from 'actions/menu/emitters';
import Item from 'modules/sidebar/menu/components/Item';

export const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleSubMenu(event) {
        if (!ownProps.hasSubMenu) {
            return;
        }
        event.preventDefault();
        dispatch(changeSubMenuVisibility(ownProps.id, ownProps.trace, !ownProps.subMenuVisibility, ownProps.flow));
    },
    activateMe(event) {
        dispatch(emitSelected(event));
        if (!event || !event.isDefaultPrevented || !event.isDefaultPrevented()) {
            dispatch(changeActiveLinkId(ownProps.id));
        }
    },
    toggleFilterVisibility(parentId) {
        dispatch(toggleFilterVisibility(parentId));
    },
});

export default connect(
    () => ({}),
    mapDispatchToProps
)(Item);
