import { findGroupingByName } from 'modules/report/enums/groupingTypeEnum';
import StorageUtil from 'utils/StorageUtil';
import BaseDetailBreadcrumb from './abstract/baseDetailBreadcrumb';

class ReportLevel3Breadcrumb extends BaseDetailBreadcrumb {
    componentDidMount() {
        const storagePreviousReportQuery = StorageUtil.getItem('reportLevel2Params') || {};
        const groupBy = findGroupingByName(storagePreviousReportQuery.groupBy);

        this.setState({ grouping: groupBy, cache: storagePreviousReportQuery }, this.getData);
    }

    matchItem = item => {
        const { grouping } = this.state;

        return this.props.match.params.subId === item[grouping.idProperty];
    };
}

export default ReportLevel3Breadcrumb;
