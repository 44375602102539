/*
    IMPORTANT
    All code in this file MUST be documented
*/

import { STATUS_ACTIVE, STATUS_ARCHIVED } from 'enums/resourceEnum';

/**
 * Given a resource object return a boolean value indicating whether the resource is active or archived
 * @param {object} resource object
 * @returns boolean
 */
export const resourceIsNotNonBookable = resource => {
    return !!resource && [STATUS_ARCHIVED.value, STATUS_ACTIVE.value].includes(resource.status);
};


export const resourceIsActive = resource => {
    return resource?.status === STATUS_ACTIVE.value
}
