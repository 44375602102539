import styled from 'styled-components';
import { textField as TextField } from 'shared/formFields';

export const HeaderIcon = styled.span`
    padding: 15px;
    display: block;
    text-align: center;
    background: linear-gradient(60deg, #26c6da, #00acc1);
    box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 188, 212, 0.2);
    border-radius: 50%;
    margin: 15px auto 10px auto;
    height: 100px;
    width: 100px;
    color: #fff;
    line-height: 70px;
    svg {
        font-size: 50px;
    }
`;

export const ModalHeader = styled.header`
    text-align: center;
`;

export const ModalHeaderDesc = styled.p`
    color: #a9a9a9;
    margin-bottom: 20px;
    text-align: center;
`;

export const Title = styled.h2`
    align-items: center;
    border: 1px solid #fff;
    line-height: 1.1;
    color: #676767;
    margin-top: 20px;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    display: inline-flex;
    padding: 0 10px;
    height: 40px;
    position: relative;
    border-radius: 0.25rem;
    svg {
        position: absolute;
        top: 25%;
        font-size: 17px;
        right: -20px;
        color: #afafaf;
        display: none;
    }
    &:hover {
        ${props => (props.editable ? 'border: 1px solid #7ca9bd;' : '')}
        svg {
            ${props => (props.editable ? 'display: block' : '')}
        }
    }
`;

export const CustomTextField = styled(TextField)`
    margin-top: 20px;
    letter-spacing: 1.2px;
    display: inline-flex !important;
    position: relative;
    width: auto !important;
    min-width: 315px;
    font-size: 30px !important;
    line-height: 1.1;
    padding: 0 10px;
    height: 40px;
    border: 1px solid #fff;
    box-shadow: none !important;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0.5rem;
`;

export const InputError = styled.span`
    margin-left: -97px;
`;
