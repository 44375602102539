import React from 'react';
import { makeStyles } from '@material-ui/core';
import { removeUTCZuluFromDateObject } from 'utils/DateUtil';
import { format } from 'date-fns';

const useStyles = makeStyles({
    dates: {
        fontWeight: 500,
        width: '25%',
    },
});

export const RangeFormat = React.memo(({ from, to, dateFormat }) => {
    const classes = useStyles();
    const isDateString = typeof from === 'string';

    const formattedStart = isDateString
        ? format(removeUTCZuluFromDateObject(new Date(from)), dateFormat)
        : format(new Date(from), dateFormat);
    const formattedEnd = isDateString
        ? format(removeUTCZuluFromDateObject(new Date(to)), dateFormat)
        : format(new Date(to), dateFormat);
    return (
        <div className={classes.dates}>
            {formattedStart === formattedEnd ? formattedStart : `${formattedStart} - ${formattedEnd}`}
        </div>
    );
});
