import React from 'react';
import { Switch } from 'react-router-dom';
import { ErrorLayout, ProtectedRoute, PublicRoute } from 'modules/templates';
import requestRoutes from 'modules/request/routes';
import reportRoutes from 'modules/report/routes';
import schedulerRoutes from 'modules/scheduler/routes';
import settingsRoutes from 'modules/setting/routes';
import authRoutes from 'modules/auth/routes';
import modalRoutes from 'modules/modals/routes';

// routes available for all
const publicRoutes = [...authRoutes.filter(route => !route.protected)];
// routes available only for authenticated users
const protectedRoutes = [
    ...authRoutes.filter(route => route.protected),
    ...modalRoutes,
    ...requestRoutes,
    ...reportRoutes,
    ...settingsRoutes,
    ...schedulerRoutes
];

const Routes = props => (
    <>
        <Switch>
            {publicRoutes.map(route => (
                <PublicRoute key={route.path} {...props} {...route} />
            ))}
            {protectedRoutes.map(route => (
                <ProtectedRoute key={route.path} {...props} {...route} />
            ))}
            <ErrorLayout {...props} />
        </Switch>
    </>
);

export { protectedRoutes as routes };
export default Routes;
