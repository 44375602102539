import { axiosInstance } from './axiosInstance';
import { stringify } from 'services/queryString';

export const getCustomFields = (type, queryParams) =>
    axiosInstance
        .get(`customfieldtemplate/rs-read/${type}?${queryParams ? stringify(queryParams) : ''}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const getCustomFieldsCancellable = cancelToken => (type, queryParams) => {
    return axiosInstance
        .get(`customfieldtemplate/rs-read/${type}?${queryParams ? stringify(queryParams) : ''}`, { cancelToken })
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};
