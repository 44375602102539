import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Formik } from 'formik';
import { materialInputGroupField as MaterialInputGroupField } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { useDispatch, useSelector } from 'react-redux';
import Schema from 'form-schema-validation';
import { errorMessages } from '../../../../../../utils/schemaUtil';
import { TYPE_UNASSIGNED } from '../../../../../../enums/resourceEnum';
import { createResource } from '../../../../../../actions/resourceActions';
import { KEYS } from 'enums/keysEnum';

const unassignedFormSchema = new Schema(
    {
        firstName: {
            type: String,
            required: true,
            defaultValue: '',
        },
    },
    errorMessages,
    false
);

const formFields = [
    {
        label: 'Name',
        name: 'firstName',
    },
];

const getDefaultValues = firstName => {
    return Object.assign({}, unassignedFormSchema.getDefaultValues(), firstName ? { firstName } : {});
};

const validate = values => {
    return unassignedFormSchema.validate(values);
};

const NewShortUnassigned = props => {
    const { onCreate, initialName = '', submitButtonText } = props;
    const dispatch = useDispatch();
    const isCreating = useSelector(state => state.resourceReducer.isCreating);

    const formJSX = formProps => {
        const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formProps;
        const submitDisabled =
            (initialName.length > 0 && Object.keys(errors).length > 0) || isCreating || !isValid;

        return (
            <form className="mt-5 mb-5" onSubmit={handleSubmit}>
                {formFields.map((formField, index) => {
                    const { label, name } = formField;
                    const meta = {
                        touched: touched[name],
                        error: errors[name],
                    };

                    return (
                        <Row key={index} className="mt-3">
                            <Col>
                                <MaterialInputGroupField
                                    label={label}
                                    name={name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={e => {
                                        e.key === KEYS.ENTER && e.preventDefault();
                                    }}
                                    value={values[name]}
                                    meta={meta}
                                />
                            </Col>
                        </Row>
                    );
                })}
                <SuccessButton
                    fixed
                    disabled={submitDisabled}
                    name={submitButtonText || 'Create unassigned'}
                    onClick={handleSubmit}
                    dataCy="button--create-unassigned"
                    className="mt-2"
                />
            </form>
        );
    };

    const initialValues = getDefaultValues(initialName);
    const onSubmit = values => {
        const unassignedToSave = { companySpecific: { ...values }, resource: { type: TYPE_UNASSIGNED.value } };

        dispatch(
            createResource.request(
                {
                    ...unassignedToSave,
                    options: {
                        skipCustomFieldsValidation: true,
                    },
                },
                newUnassigned => {
                    onCreate(newUnassigned);
                },
                false
            )
        );
    };

    return (
        <Formik
            key={initialName}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            component={formJSX}
        />
    );
};

NewShortUnassigned.propTypes = {
    onCreate: PropTypes.func.required,
    initialName: PropTypes.string,
    submitButtonText: PropTypes.string,
};

export default React.memo(NewShortUnassigned);
