import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { includes, keys } from 'lodash';
import { useRouteParams, useWindowDimension } from 'hooks';
import DynamicLoader from 'shared/dynamicLoader';

import ScheduleResourceModal from './scheduleResourceModal';
import ProjectModal from './projectModal';
import ResourceModal from './resourceModal';
import MissingBookingCategoriesModal from '../scheduler/modals/missingBookingCategoryModal/modal';
import MissingFixedCostsCategoriesModal from '../scheduler/modals/missingFixedCostCategoryModal/modal';
import ConfirmationModalNew from './confirmationModalNew';
import { MissingDependenciesModal } from '../scheduler/modals/missingDependenciesModal/modal';
import { MissingDeadlinesModal } from '../scheduler/modals/missingDeadlinesModal/modal';
import InfoModal from 'modules/scheduler/modals/infoModal/modal';
import RepeatModal from 'modules/scheduler/modals/repeatModal/modal';
import EditRepeatModal from 'modules/scheduler/modals/editRepeatModal/modal';
import { MissingToilExtensionModal } from '../scheduler/modals/missingToilExtensionModal/modal';
import { ManagePoliciesModal } from '../toil/settings/managePoliciesModal';
import { NewPolicyModal } from '../toil/settings/NewPolicyModal';
import { EditPolicyNameModal } from '../toil/settings/EditPolicyNameModal';
import LearnMoreAboutToilModal from 'modules/modals/learnMoreAboutToilModal';

/**
 * ProjectModal, MissingBookingCategoriesModal and MissingFixedCostsCategoriesModal are not lazily loaded
 * because MissingBookingCategoriesModal or MissingFixedCostsCategoriesModal can be fired from ProjectModal
 * and with lazy load approach Suspense unmounts ProjectModal what leads to errors with redux-form
 */

const COMPONENTS = {
    APPROVE_REJECT_MODAL: React.lazy(() => import('./approveRejectModal')),
    REQUEST_VACATIONS_MODAL: React.lazy(() => import('modules/request/modals/requestVacationModal/modal')),
    REQUEST_RESOURCE_MODAL: React.lazy(() => import('modules/request/modals/requestResourceModal/modal')),
    EDIT_REQUEST_RESOURCE_MODAL: React.lazy(() => import('modules/request/modals/editRequestResource/modal')),
    RESOURCE_MODAL: ResourceModal,
    SCHEDULE_RESOURCE_MODAL: ScheduleResourceModal,
    PROJECT_MODAL: ProjectModal,
    REPORT_BUG_MODAL: React.lazy(() => import('./reportBugModal')),
    CONTACT_MODAL: React.lazy(() => import('./contactModal')),
    REPORT_MODAL: React.lazy(() => import('modules/report/modal/builder')),
    SUGGEST_REPORT_MODAL: React.lazy(() => import('modules/report/modal/suggestReport')),
    ORDER_GROUP_MODAL: React.lazy(() => import('modules/scheduler/modals/orderGroupModal/modal')),
    SEARCH_MODAL: React.lazy(() => import('modules/scheduler/modals/searchModal/modal')),
    BULK_ADD_MODAL: React.lazy(() => import('./bulkAddModal/bulkAddModal')),
    BILLING_MODAL: React.lazy(() => import('modules/setting/modals/billingModal/container')),
    EDIT_SUBSCRIPTION_MODAL: React.lazy(() => import('modules/setting/modals/editSubscriptionModal/container')),
    CARD_DETAILS_MODAL: React.lazy(() => import('modules/setting/modals/cardDetailsModal/container')),
    DISABLED_ACCOUNT_MODAL: React.lazy(() => import('./disabledAccountModal')),
    HOLIDAY_MODAL: React.lazy(() => import('modules/setting/containers/modals/holidayModal')),
    CALENDARS_MODAL: React.lazy(() => import('modules/setting/containers/modals/calendarsModal')),
    MANAGE_POLICIES_MODAL: ManagePoliciesModal,
    NEW_POLICY_MODAL: NewPolicyModal,
    EDIT_POLICY_NAME_MODAL: EditPolicyNameModal,
    IMPORT_HOLIDAYS_MODAL: React.lazy(() => import('modules/setting/containers/modals/importHolidaysModal')),
    ICALL_FEED_SUBSCRIBE_MODAL: React.lazy(() => import('modules/setting/containers/modals/iCalFeedSubscribeModal')),
    PERMISSIONS_MODAL: React.lazy(() => import('modules/setting/components/modals/permissionsModal')),
    CATEGORY_GROUPS_MODAL: React.lazy(() => import('modules/categoryGroups/components/categoryGroupsModal')),
    CATEGORY_TEMPLATE_MODAL: React.lazy(() => import('modules/categoryGroups/components/categoryTemplateModal')),
    FIXED_COST_CATEGORY_TEMPLATE_MODAL: React.lazy(() => import('modules/costsCategories/editModal')),
    EDIT_BOOKING_TITLE_MODAL: React.lazy(() =>
        import('modules/scheduler/modals/editBookingTitleModal/components/modal')
    ),
    INFO_MODAL: InfoModal,
    EXPORT_MODAL: React.lazy(() => import('modules/scheduler/modals/exportModal/modal')),
    CONFIRMATION_MODAL: ConfirmationModalNew,
    BULK_MOVE_MODAL: React.lazy(() => import('modules/scheduler/modals/bulkMoveModal/modal')),
    COLUMN_RESIZE_MODAL: React.lazy(() => import('modules/scheduler/modals/columnResizeModal/modal')),
    SCHEDULER_BUILDER_MODAL: React.lazy(() => import('modules/scheduler/modals/builder')),
    REPEAT_MODAL: RepeatModal,
    EDIT_REPEAT_MODAL: EditRepeatModal,
    CREATE_PASTED_MODAL: React.lazy(() => import('modules/scheduler/modals/createPastedModal/modal')),
    MISSING_SMART_SCHEDULES_MODAL: React.lazy(() =>
        import('modules/scheduler/modals/missingSmartSchedulesModal/modal')
    ),
    MISSING_MFA_MODAL: React.lazy(() => import('modules/scheduler/modals/missingMFAModal/modal')),
    MISSING_SMART_FILTERS_MODAL: React.lazy(() => import('modules/scheduler/modals/missingSmartFiltersModal/modal')),
    MISSING_BOOKING_CATEGORIES_MODAL: MissingBookingCategoriesModal,
    MISSING_FIXED_COSTS_CATEGORIES_MODAL: MissingFixedCostsCategoriesModal,
    MISSING_DEPENDENCIES_MODAL: MissingDependenciesModal,
    MISSING_DEADLINES_MODAL: MissingDeadlinesModal,
    MISSING_TOIL_EXTENSION_MODAL: MissingToilExtensionModal,
    MISSING_UNASSIGNED_MODAL: React.lazy(() => import('modules/scheduler/modals/missingUnassignedModal/modal')),
    REPORTS_GROUP_MODAL: React.lazy(() => import('modules/report/components/modals/reportsGroupModal')),
    MFA_RECOVERY_CODES_MODAL: React.lazy(() => import('modules/auth/modals/showCodesModal')),
    MFA_SETUP_MODAL: React.lazy(() => import('modules/auth/modals/setupMFAModal')),
    OTP_VERIFICATION_MODAL: React.lazy(() => import('modules/auth/modals/otpVerificationModal')),
    MFA_USER_REMINDER_HARD_MODAL: React.lazy(() => import('modules/modals/mfaUserReminderModal/hard')),
    MFA_USER_REMINDER_SOFT_MODAL: React.lazy(() => import('modules/modals/mfaUserReminderModal/soft')),
    MFA_COMPANY_OWNER_REMINDER_MODAL: React.lazy(() => import('modules/modals/mfaUserReminderModal/owner')),
    LEAR_MORE_ABOUT_TOIL_MODAL: LearnMoreAboutToilModal,
};

const BaseModal = () => {
    const routeParams = useRouteParams();
    const windowDimension = useWindowDimension();
    const { modals } = useSelector(state => state.modalReducer);

    if (!modals.length) {
        return null;
    }

    const modalComponents = modals.map(({ modalType, modalProps }) => {
        if (!modalType || !includes(keys(COMPONENTS), modalType)) {
            return null;
        }

        const ModalComponent = COMPONENTS[modalType];

        return (
            <ModalComponent
                key={modalType}
                modalType={modalType}
                {...(modalProps ?? {})}
                {...windowDimension}
                routeParams={routeParams}
            />
        );
    });

    return <Suspense fallback={DynamicLoader()}>{modalComponents}</Suspense>;
};

export default BaseModal;
