import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';

const CancelButton = props => {
    const { name, onClick, disabled, fixed, className, dataCy, ...rest } = props;
    return (
        <Button
            color="secondary"
            disabled={disabled}
            onClick={onClick}
            data-cy={dataCy}
            className={classNames('btn-cancel', { fixed: fixed }, className)}
            {...rest}
        >
            {name}
        </Button>
    );
};

CancelButton.propTypes = {
    name: PropTypes.string,
    dataCy: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fixed: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CancelButton.defaultProps = {
    name: 'Cancel',
    onClick: null,
    disabled: false,
    fixed: false,
};

export default CancelButton;
