import styled from 'styled-components';
import { UncontrolledDropdown, InputGroupText, DropdownToggle } from 'reactstrap';

export const HeaderNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const HeaderName = styled.p`
    font-size: 22px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
`;

export const HeaderSubName = styled.p`
    font-size: 14px;
    color: #8e8c8c;
    margin: -7px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
`;

export const Dropdown = styled(UncontrolledDropdown)`
    border-right: ${props => ('false' === props.border ? 'none' : '1px solid #e4e4e4')};

    > a {
        color: rgba(0, 0, 0, 0.5);

        &:hover {
            color: rgba(0, 0, 0, 0.7);
        }
    }
`;

export const InputGroupIcon = styled(InputGroupText)`
    border: none !important;
    background-color: #fff !important;
    padding: 0.25rem 0.5rem !important;
    &:hover {
        cursor: pointer;
    }
    svg {
        color: #b5b5b5;
        font-size: 1.25rem;
        margin-right: 6px;
        &:hover {
            color: #444;
        }
    }
`;

export const LaunchBuilderIcon = styled.div`
    svg {
        color: #b8c3cc;
        font-size: 1.25rem;
        &:hover {
            color: #fff;
        }
    }
`;

export const Toggle = styled(DropdownToggle)`
    padding: 5px 10px !important;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const MultiSelectWrapper = styled.div`
    flex: 1;
    max-height: 150px;

    .form-check {
        padding-left: 0;

        .rw-multiselect-tag {
            border: none;
        }

        .form-control {
          border-radius: 0 4px 4px 0;
            border: none;

            .rw-select .rw-i-caret-down {
                display: none !important;
            }
        }
    }
`;
