import { format, getHours, getMinutes } from 'date-fns';

export const calculateUnavailabilityIntervals = (
    currentSelection,
    companyWeekdays,
    customAvailabilityWeekdays,
    useCustomAvailability
) => {
    const start = format(currentSelection.start, 'cccc').toLowerCase();
    const end = format(currentSelection.start, 'cccc').toLowerCase();

    const weekDays = useCustomAvailability ? customAvailabilityWeekdays : companyWeekdays;

    const firstStart = weekDays[start]?.intervals?.at(0).start || 9 * 60;

    const firstEnd = getHours(currentSelection.start) * 60 + getMinutes(currentSelection.start);

    const secondStart = getHours(currentSelection.end) * 60 + getMinutes(currentSelection.end);
    const secondEnd = weekDays[end]?.intervals?.at(-1).end || 18 * 60;

    const intervals = [];
    if (firstStart < firstEnd) {
        intervals.push({ start: firstStart, end: firstEnd });
    }
    if (secondStart < secondEnd) {
        intervals.push({ start: secondStart, end: secondEnd });
    }

    return intervals;
};
