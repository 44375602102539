import { AWS_S3_URL } from 'constants';
import {
    RESOURCE_THUMBNAIL,
    RESOURCE_IS_PM,
    RESOURCE_ROLE,
    RESOURCE_STATUS,
    PROJECT_THUMBNAIL,
    PROJECT_COLOR,
    CUSTOM_FIELDS,
    columnTypes,
} from 'modules/scheduler/enums/customColumnTypeEnum';
import { COLOR } from 'enums/customFieldEnum';

import { hasRole } from 'utils/rightsUtil';

function getRowText(resourceRoleRights, data, rowHeaderColumn) {
    if (!rowHeaderColumn.type || !data || 'NAME' === rowHeaderColumn.type) {
        return '';
    }

    const canViewStartEndDate = hasRole(resourceRoleRights, 'viewStartEndDates');
    if (['PROJECT_END_DATE', 'PROJECT_START_DATE'].includes(rowHeaderColumn.type) && !canViewStartEndDate) {
        return '';
    }

    return columnTypes[rowHeaderColumn.type].accessor(data, rowHeaderColumn.value) || '';
}

export default resourceRoleRights => args => {
    const schedulerRef = args.row.calendar;
    const rowHeaderColumns = schedulerRef.rowHeaderColumns;

    args.row.columns.forEach((column, index) => {
        const rowHeaderColumn = rowHeaderColumns[index];
        column.areas = [];
        if (
            !rowHeaderColumn ||
            args.row.data.tags.isEventRow ||
            args.row.data.tags.isRemoveProjectsRow ||
            args.row.data.tags.menuRow
        ) {
            return column;
        }

        const data = args.row.tags[rowHeaderColumn.viewType];
        if (
            (args.row.data.tags.isProjectRow && 'resource' === rowHeaderColumn.viewType) ||
            (args.row.data.tags.isResourceRow && 'project' === rowHeaderColumn.viewType) ||
            (args.row.data.tags.unassignedRow &&
                [
                    RESOURCE_THUMBNAIL.value,
                    PROJECT_THUMBNAIL.value,
                    RESOURCE_ROLE.value,
                    RESOURCE_STATUS.value,
                ].includes(rowHeaderColumn.type))
        ) {
            return column;
        }

        column.text = getRowText(resourceRoleRights, data, Object.assign({}, rowHeaderColumn));
        column.horizontalAlignment = rowHeaderColumn && rowHeaderColumn.alignment.toLowerCase();
        column.verticalAlignment = 'center';

        if (RESOURCE_IS_PM.value === rowHeaderColumn.type && column.text) {
            column.html = '<span class="project-manager-icon column">PM</span>';
        }
        if (
            PROJECT_COLOR.value === rowHeaderColumn.type ||
            (rowHeaderColumn.type === CUSTOM_FIELDS.value &&
                rowHeaderColumn.customColumnType === COLOR.value &&
                column.text)
        ) {
            column.html = `<span class="project-color" style="background-color: ${column.text}"/>`;
        }
        if ([RESOURCE_THUMBNAIL.value, PROJECT_THUMBNAIL.value].includes(rowHeaderColumn.type) && column.text) {
            column.html = `<img src="${AWS_S3_URL}${column.text}" class="thumb-column" alt=""/>`;
        }
        return column;
    });

    args.row.columns[0].areas = args.row.areas;
};
