import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import classNames from 'classnames';
import { FileCopyOutlined, BeachAccessOutlined, EditOutlined, KeyboardArrowRight } from '@material-ui/icons';
import { useAppKeyWords, useProjectAction, useIsExtensionInstalled } from 'hooks';
import { showProjectModal } from 'actions/modalActions';
import { DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE, VACATION_EVENT_EXTENSION_MISSING } from 'modules/scheduler/consts';
import { useMenuProjectPermissions } from 'modules/scheduler/hooks/useMenuPermissions.js';
import getPasteBookingDisabled from './utils/getPasteBookingDisabled';
import ActionItem from './../../shared/actionItem';
import { ActionItemsRow } from '../../shared/styles';
import { VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { getFormDefaultApprovers } from '../../../../../request/utils/getFormDefaultApprovers';
import { companyApprovers } from '../../../../../../actions/companyActions';
import { selectMappedResourceGroups } from '../../../../../../selectors/resourceGroup';
import { selectSchedulerMode } from '../../../../../../selectors/scheduler';
import { selectCompanyApprovers } from '../../../../../../selectors/company';

const ActionItems = React.forwardRef((props, schedulerRef) => {
    const {
        onClose,
        onPaste,
        onRequestVacation,
        showRequestVacationOption,
        isDefaultVacationEventSet,
        onOpenOtherOptionsMenu,
        onMore,
    } = props;
    const dispatch = useDispatch();
    const { projectKeyWord } = useAppKeyWords();
    const { updateProject } = useProjectAction(schedulerRef);

    const schedulerGridMode = useSelector(selectSchedulerMode);
    const schedulerState = useSelector(state => state.scheduler);
    const { currentSelection, currentView } = schedulerState;
    const { resource: currentResource, project: currentProject, ...rowTags } = currentSelection.rowTags || {};
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const { vacationEventName } = useSelector(state => state.companyReducer.company.settings);
    const vacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const approvers = useSelector(selectCompanyApprovers);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);

    const showRequestButton = showRequestVacationOption && !rowTags.unassignedRow && !currentProject;

    const { hasAddEditProjectRights } = useMenuProjectPermissions();

    useEffect(() => {
        dispatch(companyApprovers.request());
    }, [dispatch]);

    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => updateProject(currentProject._id, data),
                type: currentProject.type,
                projectId: currentProject._id,
            })
        );
    }, [dispatch, onClose, currentProject, updateProject]);

    const canRequestVacation = rowTags.hasRequestVacationRights || !currentResource || rowTags.menuRow;
    const pasteBookingDisabled = getPasteBookingDisabled(clipboard, rowTags);

    const requestVacationTooltipTitle = !vacationExtensionInstalled
        ? VACATION_EVENT_EXTENSION_MISSING
        : DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE;

    return (
        <ActionItemsRow className={classNames('text-center m-3')}>
            <ActionItem
                actionText="Paste"
                text="booking"
                onClick={onPaste}
                disabled={pasteBookingDisabled}
                dataCy="button--paste-booking"
            >
                <FileCopyOutlined />
            </ActionItem>
            {showRequestButton && (
                <ActionItem
                    actionText="Request"
                    text={`${vacationEventName}`}
                    dataCy={`button--request-${vacationEventName}`}
                    disabled={!isDefaultVacationEventSet || !canRequestVacation || !vacationExtensionInstalled}
                    onClick={() => {
                        let defaultApprovers = getFormDefaultApprovers({
                            approvers,
                            resource: currentResource,
                            mappedResourceGroups,
                        });

                        onRequestVacation({
                            resource: currentResource._id,
                            approvalInfo: {
                                approvers:
                                    map(defaultApprovers || currentResource.defaultApproverIds, approverOrId => {
                                        if (approverOrId && typeof approverOrId === 'object') {
                                            return { id: approverOrId._id };
                                        }

                                        return { id: approverOrId };
                                    }) || [],
                            },
                        });
                    }}
                    withTooltip={!isDefaultVacationEventSet || !vacationExtensionInstalled}
                    tooltipTitle={requestVacationTooltipTitle}
                >
                    <BeachAccessOutlined />
                </ActionItem>
            )}
            {(rowTags.isResourceRow || rowTags.unassignedParentRow || rowTags.menuRow || rowTags.isEventRow) && (
                <ActionItem text="More ..." onClick={onMore} dataCy={`button--more-next-slide`}>
                    <EditOutlined />
                </ActionItem>
            )}
            {rowTags.isProjectRow && (
                <ActionItem
                    disabled={!hasAddEditProjectRights || !currentProject}
                    text={`Edit ${projectKeyWord.toLowerCase()}`}
                    dataCy={`button--edit-${projectKeyWord.toLowerCase()}`}
                    onClick={projectModal}
                >
                    <EditOutlined />
                </ActionItem>
            )}
            {onOpenOtherOptionsMenu && schedulerGridMode !== 'LEGACY' && currentView === 'project' && (
                <ActionItem
                    text="More ..."
                    onClick={onOpenOtherOptionsMenu}
                    className="edit-booking-mm"
                    dataCy={`button--more-next-slide`}
                >
                    <KeyboardArrowRight />
                </ActionItem>
            )}
        </ActionItemsRow>
    );
});

ActionItems.propTypes = {
    onClose: PropTypes.func,
    onPaste: PropTypes.func.isRequired,
    onRequestVacation: PropTypes.func.isRequired,
    showRequestVacationOption: PropTypes.bool.isRequired,
    isDefaultVacationEventSet: PropTypes.bool.isRequired,
};

ActionItems.defaultProps = {
    onClose: null,
};

export default ActionItems;
