import { call, put, takeLatest, takeEvery, select, fork, cancelled } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from 'actions/actionTypes';
import { hideModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import {
    projectGroupProjectsRequest,
    getProjectGroupsRequest,
    updateProjectGroupRequest,
    createProjectGroupRequest,
    deleteProjectGroupRequest,
    getProjectGroupsRequestCancellable,
} from 'api/projectGroup';
import {
    getProjectGroupProjects,
    getProjectGroups as getProjectGroupsAction,
    updateProjectGroup,
    createProjectGroup,
    deleteProjectGroup,
} from 'actions/projectGroupActions';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import { getCompanySystemGroupIds } from 'selectors/company';
import { getProjectGroups as getResourceGroupsSelector, selectLastCallContext } from 'selectors/projectGroup';
import { getLoggedInId, getAccountPreferences } from 'selectors/account';
import { makeRequestCancellable } from '../api/makeRequestCancellable';
import { isSchedulerView } from '../utils/isSchedulerView';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { monitoring } from '../monitoring';

const getRoute = state => state.router;

function* handleGetProjectGroupProject(projectGroupId) {
    try {
        let projects = yield call(projectGroupProjectsRequest, projectGroupId);
        projects = replaceProjectsColorWithStatusColor(projects);
        yield put(getProjectGroupProjects.success(projects));
    } catch (error) {
        monitoring.captureException(error);
        if (403 !== error.status) {
            const resourceId = yield select(getLoggedInId);
            const { myScheduleGroupId } = yield select(getAccountPreferences);
            yield put(push(`/resource/${resourceId}/group/${myScheduleGroupId}`));
        }
    }
}

function* handleGetProjectGroupProjects(action) {
    try {
        yield fork(handleGetProjectGroupProject, action.payload.id);
    } catch (error) {
        monitoring.captureException(error);
        if (403 === error.status) {
            const resourceId = yield select(getLoggedInId);
            const { myScheduleGroupId } = yield select(getAccountPreferences);
            yield put(push(`/resource/${resourceId}/group/${myScheduleGroupId}`));
        }
    }
}

export function* callProjectGroups() {
    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';

    const projectGroupResponse = yield call(getProjectGroupsRequest, schedulerView);
    yield put(getProjectGroupsAction.success(projectGroupResponse, callContext));
}

function* handleGetProjectGroupsRequest() {
    const request = makeRequestCancellable(getProjectGroupsRequestCancellable);
    const lastCallContext = yield select(selectLastCallContext);
    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';

    try {
        const projectGroups = yield select(getResourceGroupsSelector);
        if (0 < projectGroups.length && lastCallContext === callContext) {
            yield put(getProjectGroupsAction.stop());
            return;
        }
        const projectGroupResponse = yield call(request.call, schedulerView);
        yield put(getProjectGroupsAction.success(projectGroupResponse, callContext));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Project Groups couldn't be loaded", type: 'danger' }));
    } finally {
        if (yield cancelled()) {
            request.cancel();
        }
    }
}

function* handleProjectGroupUpdate(action) {
    try {
        // we do not allow filters to be saved to manual groups. So adding this condition.
        const isManualGroup = !action.payload.data.isSmart;
        if (isManualGroup) {
            action.payload.data.queryParams.filters = [];
        }

        const projectGroupResponse = yield call(updateProjectGroupRequest, action.payload.data, action.payload.id);
        yield fork(handleGetProjectGroupProject, action.payload.id);

        if (action.payload.redirect) {
            yield put(push(`/project/group/${projectGroupResponse._id}`));
        }

        if (action.payload.callback) {
            action.payload.callback(projectGroupResponse);
        }
        yield put(updateProjectGroup.success(projectGroupResponse));
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Project Group couldn't be updated", type: 'danger' }));
    }
}

function* handleProjectGroupCreate(action) {
    try {
        // we do not allow filters to be saved to manual groups. So adding this condition.
        const isManualGroup = !action.payload.data.isSmart;
        if (isManualGroup) {
            action.payload.data.queryParams.filters = [];
        }

        const projectGroupResponse = yield call(createProjectGroupRequest, action.payload.data);
        yield fork(handleGetProjectGroupProject, projectGroupResponse._id);

        yield put(createProjectGroup.success(projectGroupResponse));
        yield put(hideModal());
        if (action.payload.redirect) {
            yield put(push(`/project/group/${projectGroupResponse._id}`));
        }

        if (action.payload.callback) {
            action.payload.callback(projectGroupResponse);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Project Group couldn't be created", type: 'danger' }));
    }
}

function* handleProjectGroupDelete(action) {
    try {
        yield put(hideModal());
        yield call(deleteProjectGroupRequest, action.payload.id);
        const route = yield select(getRoute);
        const systemGroupIds = yield select(getCompanySystemGroupIds);
        const currentUrl = route.location.pathname;
        if (-1 !== currentUrl.indexOf(action.payload.id)) {
            yield put(push(`/project/group/${systemGroupIds.activeProjectGroupId}`));
            yield put(changeActiveLinkFromLocation());
        }

        action.payload?.cb && action.payload?.cb();

        yield put(deleteProjectGroup.success());
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Project Group couldn't be deleted", type: 'danger' }));
    }
}

export default function* projectGroupWatcher() {
    yield takeLatest(actionTypes.GET_PROJECT_GROUP_PROJECTS['REQUEST'], handleGetProjectGroupProjects);
    yield takeLatest(actionTypes.GET_PROJECT_GROUPS['REQUEST'], handleGetProjectGroupsRequest);
    yield takeEvery(actionTypes.UPDATE_PROJECT_GROUP['REQUEST'], handleProjectGroupUpdate);
    yield takeEvery(actionTypes.CREATE_PROJECT_GROUP['REQUEST'], handleProjectGroupCreate);
    yield takeLatest(actionTypes.DELETE_PROJECT_GROUP['REQUEST'], handleProjectGroupDelete);
}
