import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, getFormInitialValues, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { FormText, TabPane, Col, Row, Alert } from 'reactstrap';
import { useIsExtensionInstalled, useHasRights } from 'hooks';
import { getCustomers } from 'actions/customerActions';
import { TYPE_REGULAR } from 'enums/projectEnum';
import Customer from './customer';
import Tag from './../../common/tag';
import UploadButton from 'shared/upload';
import ProjectStatusItem from './projectStatusItem';
import { multiSelectField, dropdownField, colorInputField, inputGroupTextField } from 'shared/formFields';
import CategoryTemplateItem from 'shared/categoryTemplateItem';
import CategoryGroupItem from 'shared/categoryGroupItem';
import { PM } from 'enums/extensionShortIdEnum';
import { DEFAULT } from 'enums/categoryGroupTypeEnum';
import { FORM_NAME } from './modal';
import PermissionDenied from '../../../../shared/permissionDenied';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import {selectProjectStatuses} from '../../../../selectors/company';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const rights = [
    {
        rights: ['settingProjectClient'],
        rule: 'one',
        name: 'hasSettingProjectClient',
    },
    {
        rights: ['settingProjectTags'],
        rule: 'one',
        name: 'hasSettingProjectTags',
    },
    {
        rights: ['settingProjectPm'],
        rule: 'one',
        name: 'hasSettingProjectPm',
    },
    {
        rights: ['settingProjectStatus'],
        rule: 'one',
        name: 'hasSettingProjectStatus',
    },
    {
        rights: ['settingProjectInfo'],
        rule: 'one',
        name: 'hasSettingProjectInfo',
    },
    {
        rights: ['settingAddEditProjects'],
        rule: 'one',
        name: 'hasSettingAddEditProjects',
    },
];

const ProjectBasicInfoTab = props => {
    const { tabId, type, projectKeyWord, projectManagers, tags, onFieldChange, categoryTemplates } = props;
    const dispatch = useDispatch();
    const { thumb: thumbInitial } = useSelector(getFormInitialValues(FORM_NAME));
    const formValues = useSelector(getFormValues(FORM_NAME));
    const { status: currentStatus, useStatusColor } = formValues;
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const projectStatusColors = useSelector(state => state.companyReducer.company.settings.projectStatusColors);
    const categorySetting = useSelector(state => state.companyReducer.company.settings.grid.catColorDisplayNew);
    const categoryGroups = useSelector(state => state.categoryGroupReducer.categoryGroups);
    const customers = useSelector(state => state.customerReducer.customers);
    const projectStatuses = useSelector(selectProjectStatuses);
    
    const {
        hasSettingProjectClient,
        hasSettingProjectTags,
        hasSettingProjectPm,
        hasSettingProjectStatus,
        hasSettingProjectInfo,
        hasSettingAddEditProjects,
    } = useHasRights(rights);

    useEffect(() => {
        hasSettingProjectClient && dispatch(getCustomers.request());
    }, [dispatch, hasSettingProjectClient]);

    const sortedCategoryTemplates = useMemo(
        () => [
            ...categoryTemplates.filter(({ type }) => DEFAULT === type),
            ...categoryTemplates
                .filter(({ type }) => DEFAULT !== type)
                .sort((a, b) => {
                    var textA = a.name.toUpperCase();
                    var textB = b.name.toUpperCase();
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                }),
        ],
        [categoryTemplates]
    );

    const showProjectInfoSection = hasSettingProjectInfo || hasSettingAddEditProjects;
    const showClientSection = hasSettingProjectClient;
    const showProjectTagsSection = hasSettingProjectTags;
    const showPMSection = type === TYPE_REGULAR.value && isPMExtensionInstalled && hasSettingProjectPm;
    const showStatusSection = hasSettingProjectStatus && type === TYPE_REGULAR.value;
    const showBookingCategorySection = hasSettingProjectInfo;
    const showNoRightsWarning = [
        showProjectInfoSection,
        showClientSection,
        showProjectTagsSection,
        showPMSection,
        showStatusSection,
        showBookingCategorySection,
    ].every(showSection => !showSection);

    const changeProjectColorSource = useCallback(
        event => {
            const newUseStatusColor = event.target.value === 'true' ? true : false;
            onFieldChange({
                backgroundColor: newUseStatusColor ? projectStatusColors[currentStatus.value] : '',
                useStatusColor: newUseStatusColor,
            });
        },
        [currentStatus.value, onFieldChange, projectStatusColors]
    );

    return (
        <TabPane tabId={tabId}>
            {showNoRightsWarning ? <PermissionDenied /> : null}
            {showProjectInfoSection && (
                <section className="form-fields">
                    <Row>
                        <Col md="8">
                            <p className="title">{projectKeyWord} Title:</p>
                            <Field width="col-md-12" name="name" icon="fa-file" component={inputGroupTextField} />
                            <FormText className="required mb-2">{projectKeyWord} name</FormText>
                            <Field
                                width="col-md-12"
                                name="projectCode"
                                icon="fa-terminal"
                                component={inputGroupTextField}
                            />
                            <FormText>{projectKeyWord} Code / ID</FormText>
                        </Col>
                        <Col md="4">
                            <UploadButton name="thumb" defaultThumb={thumbInitial} onChange={onFieldChange} />
                        </Col>
                    </Row>
                </section>
            )}
            {showClientSection && <Customer formName="projectForm" fieldName="customers" customers={customers} />}
            {showProjectTagsSection && <Tag formName="projectForm" fieldName="tags" category="PROJECT" tags={tags} />}
            {showPMSection && (
                <section className="form-fields">
                    <p className="title">Project manager:</p>
                    <Field
                        inline
                        width="col-md-12"
                        name="projectManagers"
                        component={multiSelectField}
                        caseSensitive={false}
                        valueField="_id"
                        textField="name"
                        filter="contains"
                        data={projectManagers}
                    />
                    <FormText>
                        Select a Project Manager for this {projectKeyWord}. You can select more than 1 Project Manager
                        by searching or selecting
                    </FormText>
                </section>
            )}
            {showStatusSection && (
                <>
                    <section className="form-fields">
                        <p className="title">Set {projectKeyWord} Status Label:</p>
                        <Field
                            inline
                            name="status"
                            component={dropdownField}
                            filter={false}
                            valueField="value"
                            textField="display"
                            itemComponent={({ item }) => (
                                <ProjectStatusItem item={item} color={projectStatusColors[item.value]} />
                            )}
                            valueComponent={({ item }) => (
                                <ProjectStatusItem item={item} color={projectStatusColors[item.value]} />
                            )}
                            data={projectStatuses}
                        />
                        <FormText className="required">
                            Set the {projectKeyWord} Status label for this {projectKeyWord}
                        </FormText>
                    </section>
                    <section className="form-fields">
                        <p className="title">Set {projectKeyWord} Color:</p>
                        {categorySetting === 'CATEGORY_COLOR_BOOKING' && (
                            <FormText
                                className={classnames({
                                    required: 'CATEGORY_COLOR_BOOKING' !== categorySetting,
                                })}
                            >
                                <Alert color="warning mt-3">
                                    <b>Color Warning!</b> You are currently using category colors to define the bookings
                                    color, you must first disable this feature under{' '}
                                    <a className="primary" href="/settings#custom">
                                        Settings / Customization
                                    </a>{' '}
                                    to use the custom booking colors feature instead.
                                </Alert>
                            </FormText>
                        )}
                        {categorySetting !== 'CATEGORY_COLOR_BOOKING' && (
                            <>
                                <FormControl component="fieldset" className="ml-1 mt-2">
                                    <RadioGroup value={String(useStatusColor)} onChange={changeProjectColorSource}>
                                        <FormControlLabel
                                            value="true"
                                            control={<GreenRadio dataCy="project-color-inherit-status" />}
                                            className="mb-2"
                                            label={`Use ${projectKeyWord.toLowerCase()} status color`}
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<GreenRadio dataCy="project-custom-color" />}
                                            className="mb-2"
                                            label="Use custom color"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {!useStatusColor && (
                                    <FormText>
                                        <Field
                                            width="col-md-12"
                                            name="backgroundColor"
                                            component={colorInputField}
                                            onColorChange={color =>
                                                onFieldChange({ backgroundColor: color.hex, useStatusColor: false })
                                            }
                                        />
                                        This color will override the {projectKeyWord.toLowerCase()} status color and set
                                        each {projectKeyWord.toLowerCase()} booking to this color.
                                    </FormText>
                                )}
                            </>
                        )}
                    </section>
                </>
            )}
            {showBookingCategorySection && (
                <>
                    <section className="form-fields border-bottom-0">
                        <p className="title">Category Groups:</p>
                        <Field
                            inline
                            width="col-md-12"
                            name="categoryGroups"
                            component={multiSelectField}
                            caseSensitive={false}
                            allowCreate="onFilter"
                            valueField="_id"
                            filter="contains"
                            itemComponent={({ item }) => item.name}
                            textField="name"
                            data={categoryGroups}
                        />
                        <FormText>{`Choose which category group apply to this ${projectKeyWord}.`}</FormText>
                    </section>
                    <section className="form-fields border-bottom-0">
                        <p className="title">Default Booking Category:</p>
                        <Field
                            inline
                            name="defaultCategoryTemplate"
                            component={dropdownField}
                            filter="contains"
                            valueField="_id"
                            data={sortedCategoryTemplates}
                            itemComponent={CategoryTemplateItem}
                            valueComponent={CategoryTemplateItem}
                            textField="name"
                            groupBy="groupName"
                            groupComponent={CategoryGroupItem}
                            dropUp
                        />
                        <FormText>{`Set the default booking category for this ${projectKeyWord}.`}</FormText>
                    </section>
                </>
            )}
        </TabPane>
    );
};

ProjectBasicInfoTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    projectKeyWord: PropTypes.string,
    projectManagers: PropTypes.array,
    tags: PropTypes.array,
    type: PropTypes.string,
};

ProjectBasicInfoTab.defaultProps = {
    projectKeyWord: 'Project',
    type: TYPE_REGULAR.value,
    projectManagers: [],
    tags: [],
};

export default ProjectBasicInfoTab;
