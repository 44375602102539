import { CHECKBOX, RADIO, SELECT } from 'enums/customFieldEnum';
import { createSelector } from 'reselect';
import { filter, isUndefined } from 'lodash';
import { intlCollator } from '../utils/stringUtils';

export const getCustomFields = state => state.customFieldReducer;
export const getResourceCustomFields = state => getCustomFields(state).resourceCustomFields;
export const getProjectCustomFields = state => getCustomFields(state).projectCustomFields;

export const filterFieldsByType = (customFields, filterType, customFieldType) => {
    let customFieldsByType;
    if (customFieldType) {
        customFieldsByType = customFields[customFieldType];
    } else {
        customFieldsByType = customFields.projectCustomFields.concat(customFields.resourceCustomFields);
    }

    switch (filterType) {
        case 'CHOICES':
            return filter(
                customFieldsByType,
                customField =>
                    customField.allowMultipleValues ||
                    customField.type === CHECKBOX.value ||
                    customField.type === RADIO.value ||
                    customField.type === SELECT.value
            );
        case 'CHOICES_FILTER_GRID':
            return filter(
                customFieldsByType,
                customField =>
                    customField.filterGrid &&
                    (customField.allowMultipleValues ||
                        customField.type === CHECKBOX.value ||
                        customField.type === RADIO.value ||
                        customField.type === SELECT.value)
            );
        case 'EDITABLE_FOR_RESOURCE':
            return filter(customFieldsByType, customField => customField.canResourceEdit);
        default:
            return customFieldsByType;
    }
};

export const makeGetFilteredCustomFields = (filterType, customFieldType) =>
    createSelector(getResourceCustomFields, getProjectCustomFields, (resourceCustomFields, projectCustomFields) => {
        return filterFieldsByType(
            {
                resourceCustomFields: resourceCustomFields.map(resource => ({
                    ...resource,
                    choices: (isUndefined(resource.isChoicesSortedAlphabetically) || resource.isChoicesSortedAlphabetically) ? resource.choices.sort((choiceA, choiceB) => intlCollator.compare(choiceA.value, choiceB.value)) : resource.choices
                })),
                projectCustomFields: projectCustomFields.map(project => ({
                    ...project,
                    choices: (isUndefined(project.isChoicesSortedAlphabetically) || project.isChoicesSortedAlphabetically) ? project.choices.sort((choiceA, choiceB) => intlCollator.compare(choiceA.value, choiceB.value)) : project.choices
                })),
            },
            filterType,
            customFieldType
        );
    });
