import { values, assign, filter } from 'underscore';
import moize from 'moize';
import { TextFields, AttachMoney, Money, AccessTime, FormatShapes } from '@material-ui/icons';
import { isActive } from 'utils/extensionUtil';
import { RESOURCE_RATE, TIME_SHEET, CUSTOM_FIELDS } from 'enums/extensionShortIdEnum';

export const TIME_TYPE_COLUMNS = {
    SCHEDULED: 'scheduled',
    REPORTED: 'reported',
    GENERAL: 'general',
};

export const RATE_TYPE_COLUMNS = {
    EXTERNAL: 'external',
    INTERNAL: 'internal',
};

const getRateTypeColumns = rateType => ({
    external: {
        label: 'External',
        value: `${rateType}_${RATE_TYPE_COLUMNS.EXTERNAL}`,
    },
    internal: {
        label: 'Internal',
        value: `${rateType}_${RATE_TYPE_COLUMNS.INTERNAL}`,
        extension: RESOURCE_RATE,
    },
});

const getTimeTypeColumns = (columnType, includeRateType, includeGeneral, includeRateTypeGeneral) => {
    const scheduledChildren = includeRateType
        ? values(getRateTypeColumns(`${columnType}_${TIME_TYPE_COLUMNS.SCHEDULED}`))
        : [];
    const reportedChildren = includeRateType
        ? values(getRateTypeColumns(`${columnType}_${TIME_TYPE_COLUMNS.REPORTED}`))
        : [];
    const generalChild = timeType => [{ label: 'General', value: `${columnType}_${timeType}` }];

    return assign(
        {
            scheduled: {
                label: 'Scheduled',
                value: `${columnType}_scheduled${includeRateType ? '_main' : ''}`,
                children: includeRateTypeGeneral
                    ? generalChild('scheduled').concat(scheduledChildren)
                    : scheduledChildren,
            },
            reported: {
                label: 'Reported',
                value: `${columnType}_reported${includeRateType ? '_main' : ''}`,
                children: includeRateTypeGeneral ? generalChild('reported').concat(reportedChildren) : reportedChildren,
                extension: TIME_SHEET,
            },
        },
        includeGeneral && {
            general: {
                label: 'General',
                value: `${columnType}_${TIME_TYPE_COLUMNS.GENERAL}`,
            },
        }
    );
};

export const COLUMN_TYPES_ENUM = {
    TIME: 'time',
    CAPACITY: 'capacity',
    SPEND: 'spend',
    BUDGET: 'budget',
    TEXT: 'text',
    CUSTOM_FIELD: 'customField',
    properties: {
        time: {
            icon: AccessTime,
            label: 'Time  columns',
            value: 'time',
            children: values(getTimeTypeColumns('time', false, true)),
            order: 1,
        },
        capacity: {
            icon: TextFields,
            label: 'Capacity columns',
            value: 'capacity',
            children: values(getTimeTypeColumns('capacity', false, true)),
            order: 2,
        },
        spend: {
            icon: AttachMoney,
            label: 'Spend  columns',
            value: 'spend',
            children: values(getTimeTypeColumns('spend', true, true, true)),
            order: 3,
        },
        budget: {
            icon: Money,
            label: 'Budget  columns',
            value: 'budget',
            children: values(getTimeTypeColumns('budget', true, true, true)),
            order: 4,
        },
        text: {
            icon: TextFields,
            label: 'Text  columns',
            value: 'text',
            order: 5,
        },
        customField: {
            icon: FormatShapes,
            label: 'Custom fields',
            value: 'customField',
            extension: CUSTOM_FIELDS,
            order: 6,
        },
    },
};

export const columnTypesList = () => values(COLUMN_TYPES_ENUM.properties);

export const columnTypesListByExtensions = (extensions, columnTypes) =>
    filter(columnTypes, columnType => {
        const isColumnActive =
            !columnType.extension || (columnType.extension && isActive(extensions, columnType.extension));

        if (isColumnActive && columnType.children && columnType.children.length) {
            columnType.children = columnTypesListByExtensions(extensions, columnType.children);
        }

        return isColumnActive;
    });

export const getIconByColumnType = moize(type => COLUMN_TYPES_ENUM.properties[type].icon);

export const getOrderByColumnType = moize(type => COLUMN_TYPES_ENUM.properties[type].order);
