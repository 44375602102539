import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import { isDate } from 'date-fns';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import { formatDate } from 'utils/DateUtil';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { YEAR_MONTH_DAY_FORMAT } from '../../../../../../global/enums/dateFormat';
import { MenuCollapse } from '../menuCollapse';

const renderDay = day => {
    const date = day.getDate();
    return <div data-cy={`[${formatDate(day, YEAR_MONTH_DAY_FORMAT)}]`}>{date}</div>;
};

const DateToggler = props => {
    const { start, end, single, onDateChange, onToggleOpen, closeToggle } = props;
    const [whichOpen, setWhichOpen] = useState(null);

    const startDateCy = single ? 'date-toggler' : 'start-date-toggler';
    const endDateCy = 'end-date-toggler';

    useEffect(() => {
        closeToggle && setWhichOpen(null);
    }, [closeToggle]);

    // Start date cannot be greater than end date
    const onStartDateChange = useCallback(
        newStartDate => {
            if (newStartDate > end) {
                onDateChange({ start: newStartDate, end: newStartDate });
            } else {
                onDateChange({ start: newStartDate });
            }
        },
        [end, onDateChange]
    );

    const onEndDateChange = useCallback(
        newEndDate => {
            // set the same hour and minutes that was at the beginning
            // fix timezone issue
            newEndDate.setHours(end.getHours());
            newEndDate.setMinutes(end.getMinutes());
            onDateChange({ end: newEndDate });
        },
        [end, onDateChange]
    );

    const toggleOpen = useCallback(
        which => {
            if (whichOpen === which) {
                setWhichOpen(null);
            } else {
                setWhichOpen(which);
                onToggleOpen && onToggleOpen();
            }
        },
        [whichOpen, onToggleOpen]
    );

    const isStartOpen = useMemo(() => whichOpen === 'start', [whichOpen]);
    const isEndOpen = useMemo(() => whichOpen === 'end', [whichOpen]);

    return (
        <>
            <Toggler data-cy={startDateCy} type="button" onClick={() => toggleOpen('start')}>
                <Text>
                    <span>Start:</span> {isDate(start) ? formatDate(start, 'MMM do, yyyy', false) : 'No Date Set'}
                </Text>
                <ToggleIconHolder>{isStartOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isStartOpen}>
                <div style={{ textAlign: 'center' }}>
                    <DayPicker
                        showOutsideDays
                        selectedDays={isDate(start) ? start : undefined}
                        initialMonth={isDate(start) ? start : undefined}
                        onDayClick={onStartDateChange}
                        renderDay={renderDay}
                    />
                </div>
            </MenuCollapse>
            {!single && (
                <>
                    <Toggler data-cy={endDateCy} type="button" onClick={() => toggleOpen('end')}>
                        <Text>
                            <span>End:</span> {isDate(end) ? formatDate(end, 'MMM do, yyyy', false) : 'No Date Set'}
                        </Text>
                        <ToggleIconHolder>{isEndOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
                    </Toggler>
                    <MenuCollapse isOpen={isEndOpen}>
                        <div style={{ textAlign: 'center' }}>
                            <DayPicker
                                showOutsideDays
                                selectedDays={isDate(end) ? end : undefined}
                                initialMonth={isDate(end) ? end : undefined}
                                onDayClick={onEndDateChange}
                                disabledDays={{
                                    before: isDate(start) ? start : undefined,
                                }}
                                renderDay={renderDay}
                            />
                        </div>
                    </MenuCollapse>
                </>
            )}
        </>
    );
};

DateToggler.propTypes = {
    single: PropTypes.bool,
    start: PropTypes.object,
    end: PropTypes.object,
    onDateChange: PropTypes.func.isRequired,
};

DateToggler.defaultProps = {
    single: false,
    start: null,
    end: null,
};

export default DateToggler;
