import React from 'react';
import classnames from 'classnames';
import moize from 'moize';
import { IconButton } from './styles';

const Icon = ({ onClick, icon, isActive }) => (
    <IconButton onClick={onClick} className={classnames({ active: isActive })}>
        <i className={`fa ${icon}`} />
    </IconButton>
);

export default moize(Icon, { isReact: true, maxSize: 2 });
