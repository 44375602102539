import React from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const ToggleButton = React.memo(props => {
    const { options, onChange, value } = props;

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <StyledToggleButton
                disableRipple
                left
                label={options[0].label}
                active={options[0].value === value}
                onClick={event => onChange(event, options[0].value)}
            />
            <StyledToggleButton
                disableRipple
                right
                label={options[1].label}
                active={options[1].value === value}
                onClick={event => onChange(event, options[1].value)}
            />
        </div>
    );
});

ToggleButton.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })).isRequired,
};

const StyledToggleButton = withStyles(theme => ({
    root: {
        color: ({ active }) => (active ? theme.palette.text.inverted : theme.palette.grey.dark),
        backgroundColor: ({ active }) => (active ? theme.palette.grey.primary : theme.palette.text.inverted),
        border: `1px solid ${theme.palette.grey.light}`,
        height: '24px',
        padding: '0 10px',
        position: 'relative',
        left: ({ left }) => (left ? '10px' : 0),
        right: ({ right }) => (right ? '10px' : 0),
        zIndex: ({ active }) => (active ? 2 : 1),
        '&:focus': {
            backgroundColor: theme.palette.grey.dark,
            color: theme.palette.text.inverted,
        },
    },
}))(Chip);

const useStyles = makeStyles({
    container: {
        display: 'inline-flex',
        justifyContent: 'center',
        paddingRight: '10px',
    },
});
