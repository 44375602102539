import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { setFilterOperator } from '../../../../actions/schedulerActions';
import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, useActiveFilterDotStyles } from './styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Typography } from '@material-ui/core';
import { OperatorToggle } from './OperatorToggle';
import { NestedSelectableList } from './NestedSelectableList';
import { SelectableList } from './SelectableList';
import PeopleAltOutlined from '@material-ui/icons/PeopleAltOutlined';
import LabelOutlined from '@material-ui/icons/LabelOutlined';
import SettingsOutlined from '@material-ui/icons/SettingsOutlined';
import EuroOutlined from '@material-ui/icons/EuroOutlined';
import CategoryOutlined from '@material-ui/icons/CategoryOutlined';
import FolderOpenOutlined from '@material-ui/icons/FolderOpenOutlined';
import VerifiedUserOutlined from '@material-ui/icons/VerifiedUserOutlined';
import FolderOutlined from '@material-ui/icons/FolderOutlined';
import PersonOutlined from '@material-ui/icons/PersonOutlined';
import MoreOutlined from '@material-ui/icons/MoreOutlined';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import TuneOutlined from '@material-ui/icons/TuneOutlined';
import { useDropdownFiltersContext } from './DropdownFiltersContext';
import { withStyles } from '@material-ui/core/styles';

const iconsProps = { fontSize: 'small', style: { marginRight: '10px' } };
const icons = {
    PeopleAltOutlined: <PeopleAltOutlined {...iconsProps} />,
    LabelOutlined: <LabelOutlined {...iconsProps} />,
    SettingsOutlined: <SettingsOutlined {...iconsProps} />,
    EuroOutlined: <EuroOutlined {...iconsProps} />,
    CategoryOutlined: <CategoryOutlined {...iconsProps} />,
    FolderOpenOutlined: <FolderOpenOutlined {...iconsProps} />,
    VerifiedUserOutlined: <VerifiedUserOutlined {...iconsProps} />,
    FolderOutlined: <FolderOutlined {...iconsProps} />,
    PersonOutlined: <PersonOutlined {...iconsProps} />,
    MoreOutlined: <MoreOutlined {...iconsProps} />,
    BuildOutlined: <BuildOutlined {...iconsProps} />,
    TuneOutlined: <TuneOutlined {...iconsProps} />,
};

const FilterItem = props => {
    const { filterItem } = props;
    const dispatch = useDispatch();
    const filter = useSelector(filterItem.filterSelector);
    const activeFilterDotStyles = useActiveFilterDotStyles({ nested: false });

    const { expandedParentAccordion, expandFilter } = useDropdownFiltersContext();

    const handleOperatorChange = useCallback(
        operator => {
            dispatch(
                setFilterOperator({
                    name: filterItem.filterName,
                    operator,
                })
            );
        },
        [dispatch, filterItem.filterName]
    );
    
    if (!filterItem.items?.length) {
        return null;
    }

    const isExpanded = expandedParentAccordion === filterItem.filterName;

    // yes in one case undefined means not selected, false is still selected / active
    const isFilterActive = typeof filter?.filters === 'boolean' ? true : filter?.filters?.length;

    return (
        <StyledAccordion
            elevation={0}
            expanded={isExpanded}
            onChange={() => expandFilter(isExpanded ? undefined : { parentAccordion: filterItem.filterName })}
            key={filterItem.id}
            TransitionProps={{ unmountOnExit: true }}
        >
            <StyledAccordionSummary
                data-cy={`${filterItem.filterName}-dropdown-filter`}
                expandIcon={<ExpandMoreIcon />}
            >
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            {isFilterActive ? <span className={activeFilterDotStyles.activeFilterDot} /> : null}
                            {icons[filterItem.icon]}
                            <SummaryText>{filterItem.title}</SummaryText>
                        </Grid>
                    </Grid>
                    {filterItem.withRelationToggle ? (
                        <Grid item>
                            <OperatorToggle
                                popoverId={`filter-relation-${filterItem.filterName}`}
                                value={filter.operator}
                                buttonClassName="toggleRelation"
                                onChange={handleOperatorChange}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                {filterItem.nested ? (
                    <NestedSelectableList
                        filterName={filterItem.filterName}
                        withSearch={filterItem.withSearch}
                        items={filterItem.items}
                        searchPredicate={filterItem.searchPredicate}
                        itemLabelRenderer={filterItem.itemLabelRenderer}
                        getItemValue={filterItem.getItemValue}
                        childItemLabelRenderer={filterItem.childItemLabelRenderer}
                        getChildItemValue={filterItem.getChildItemValue}
                        childrenSelector={filterItem.childrenSelector}
                        filterValue={filterItem.filterValue}
                        selectedSelector={filterItem.selectedSelector}
                        deletedItemsMapper={filterItem.deletedItemsMapper}
                        withEqualityToggle={filterItem.withEqualityToggle}
                        withSelectAll={filterItem.withSelectAll}
                    />
                ) : (
                    <SelectableList
                        filterName={filterItem.filterName}
                        withSearch={filterItem.withSearch}
                        items={filterItem.items}
                        selectedSelector={filterItem.selectedSelector}
                        deletedItemsMapper={filterItem.deletedItemsMapper}
                        searchPredicate={filterItem.searchPredicate}
                        itemLabelRenderer={filterItem.itemLabelRenderer}
                        getItemValue={filterItem.getItemValue}
                        withEqualityToggle={filterItem.withEqualityToggle}
                        withSelectAll={filterItem.withSelectAll}
                    />
                )}
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

const SummaryText = withStyles({
    root: {
        fontWeight: 400,
        fontSize: `14px`,
        lineHeight: `21px`,
    },
})(Typography);

const memoized = React.memo(FilterItem);

export { memoized as FilterItem };
