import { connect } from 'react-redux';
import { submit, formValueSelector, hasSubmitFailed, getFormMeta } from 'redux-form';
import { keys } from 'lodash';
import { STATUS_ARCHIVED } from 'enums/projectEnum';
import { hideModal } from 'actions/modalActions';
import { getCustomFieldsRequest } from 'actions/customFieldActions';
import { getTags } from 'actions/tagActions';
import { getResources, getProjectManagers as getPMs } from 'actions/resourceActions';
import { getProject, deleteProject, updateProject, resetProjectValue } from 'actions/projectActions';
import { getProjectGroups } from 'actions/projectGroupActions';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { getDefaultValues } from 'forms/projectForm';
import ProjectModal, { FORM_NAME } from './components/modal';
import { makeGetProjectGroups } from 'selectors/projectGroup';
import { makeCategoryGroups } from 'selectors/categoryGroups';
import { makeGetFilteredCategoryTemplates } from 'selectors/categoryTemplate';
import { makeGetFilteredResources } from 'selectors/resource';
import { selectAllCostsCategories } from '../../costsCategories/store';
import { getDisplayArchivedInScheduler, selectIsApprovalExtensionInstalled } from '../../../selectors/company';

const selector = formValueSelector(FORM_NAME);
const getFilteredProjectGroups = makeGetProjectGroups('TYPE_USER_AND_NOT_SMART');
const categoryGroups = makeCategoryGroups();
const categoryTemplates = makeGetFilteredCategoryTemplates('WITH_DELETED');
const getUnassignedResources = makeGetFilteredResources('UNASSIGNED');
const getActiveResources = makeGetFilteredResources('ACTIVE');
const getArchivedResources = makeGetFilteredResources('ARCHIVED');
const getProjectManagers = makeGetFilteredResources('IS_PROJECT_MANAGER');

export default connect(
    state => {
        const activeResources = getActiveResources(state);
        const archivedResources = getArchivedResources(state);
        const projectManagers = getProjectManagers(state);
        const unassignedResources = getUnassignedResources(state);
        const isApprovalExtensionInstalled = selectIsApprovalExtensionInstalled(state);
        const displayArchived = getDisplayArchivedInScheduler(state);

        const costsCategories = selectAllCostsCategories(state);

        const initialValues = getDefaultValues(
            state.companyReducer.company.settings,
            state.projectReducer.project,
            activeResources.concat(unassignedResources).concat(displayArchived ? archivedResources : []),
            projectManagers,
            getFilteredProjectGroups(state),
            categoryGroups(state),
            categoryTemplates(state),
            costsCategories.asMap,
            isApprovalExtensionInstalled
        );

        return {
            projectCustomFields: state.customFieldReducer.projectCustomFields,
            resourceRoleRights: state.account.resourceRoleRights,
            projectTags: state.tagReducer.projectTags,
            projectTabName: state.companyReducer.company.settings.customField?.projectTabName,
            extensions: state.companyReducer.company.extensions,
            projectKeyWord: state.companyReducer.company.settings.keyword.keyword1Singular,
            resourceKeyWord: state.companyReducer.company.settings.keyword.keyword2Singular,
            resourcePluralKeyWord: state.companyReducer.company.settings.keyword.keyword2Plural,
            hasSubmitFailed: hasSubmitFailed(FORM_NAME)(state),
            startEndTimes: state.companyReducer.startEndTimes,
            unassignedResources: unassignedResources,
            resources: activeResources,
            projectManagers,
            project: state.projectReducer.project,
            projectGroups: getFilteredProjectGroups(state),
            isCreating: state.projectReducer.isUpdating,
            isUpdating: state.projectReducer.isUpdating,
            touchedFields: keys(getFormMeta(FORM_NAME)(state)),
            budgetCategories: selector(state, 'budgetCategories'),
            fixedCosts: selector(state, 'fixedCosts'),
            categoryGroups: selector(state, 'categoryGroups'),
            selectedResources: selector(state, 'resources'),
            customFields: selector(state, 'customFields'),
            phases: selector(state, 'phases'),
            projectRates: selector(state, 'projectRates'),
            note: selector(state, 'note'),
            setStartEndDates: selector(state, 'setStartEndDates'),
            selectedCategoryGroups: selector(state, 'categoryGroups'),
            defaultCategoryTemplate: selector(state, 'defaultCategoryTemplate'),
            iconLinks: selector(state, 'iconLink1', 'iconLink2', 'iconLink3', 'iconLink4', 'iconLink5'),
            initialValues: initialValues,
        };
    },
    (dispatch, ownProps) => ({
        hideModal: () => dispatch(hideModal()),
        getTags: () => dispatch(getTags.request('project')),
        getResources: () => dispatch(getResources.request()),
        getProject: () => dispatch(getProject.request(ownProps.projectId)),
        getProjectManagers: () => dispatch(getPMs.request()),
        getProjectGroups: () => dispatch(getProjectGroups.request()),
        getCustomFields: () => dispatch(getCustomFieldsRequest('project')),
        getCategoryTemplates: () => dispatch(getCategoryTemplates.request()),
        getCategoryGroups: () => dispatch(getCategoryGroups.request()),
        deleteProject: callback => dispatch(deleteProject.request(ownProps.projectId, callback)),
        archiveProject: () =>
            dispatch(updateProject.request(ownProps.projectId, { project: { status: STATUS_ARCHIVED.value } })),
        submitForm: () => dispatch(submit(FORM_NAME)),
        resetProjectValue: () => dispatch(resetProjectValue.request('project')),
    })
)(ProjectModal);
