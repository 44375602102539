import { DayPilot } from 'daypilot-pro-react';
import { without, filter, map } from 'lodash';
import { hasRole } from 'utils/rightsUtil';
import { getRowId } from 'modules/scheduler/utils/eventUtil';
import { removeDaypilotEvents, addDaypilotEvent } from 'modules/scheduler/utils/schedulerUtil';
import { PROJECTS } from 'modules/scheduler/enums/schedulerFilterEnum';
import { STATUS_ACTIVE, STATUS_ARCHIVED } from '../../../../enums/projectEnum';

export default (accountRoleRights, groupBookings, keyWords, viewObject, actions) => (
    resource,
    project
) => {
    const projectResourceDayPilotMenu = new DayPilot.Menu({
        theme: 'hub-context',
        cssClassPrefix: 'hub-context',
        hideOnMouseOut: false,
        useShadow: true,
        zIndex: 10,
        items: [],
    });

    if (groupBookings) {
        projectResourceDayPilotMenu.items.push({
            text: 'Toggle grouping',
            onClick: ({ source }) => {
                const hasExpanded = 0 < source.groups.expanded().length;

                if (hasExpanded) {
                    source.groups.collapseAll();
                } else {
                    source.groups.expandAll();
                }
            },
        });
    }

    if (resource.hasRightsToResource && hasRole(accountRoleRights, 'settingResourcePro')) {
        projectResourceDayPilotMenu.items.push({
            text: `Remove from ${keyWords.resourceKeyWord.toLowerCase()}...`,
            onClick: ({ source }) => {
                const filteredEvents = filter(
                    source.calendar.events.list,
                    event => (event.project || {})._id === source.tags.project._id
                );
                const events = map(filteredEvents, event => {
                    const updatedEvent = {
                        ...event,
                        project: {
                            ...event.project,
                            resources: without(event.project.resources, source.tags.resourceId),
                        },
                    };
                    return {
                        ...updatedEvent,
                        resource: getRowId(updatedEvent, source.calendar.mode, viewObject),
                    };
                });
                removeDaypilotEvents(map(filteredEvents, 'id'), source.calendar);
                addDaypilotEvent(events, source.calendar, viewObject);
                const resourceIds = without(source.tags.project.resources, source.tags.resourceId);
                actions?.updateProject(source.tags.project._id, {
                    project: {
                        resources: resourceIds,
                    },
                });
            },
        });
    }

    if (
        ((project.hasRightsToProject && hasRole(accountRoleRights, 'settingAddEditProjects')) ||
            (project.hasPMRightsToProject && hasRole(accountRoleRights, 'pmAddEditProjects'))) &&
        hasRole(accountRoleRights, 'settingProjectStatus')
    ) {
        const label = project.status === STATUS_ARCHIVED.value ? 'Unarchive' : 'Archive';
        const newStatus = project.status === STATUS_ARCHIVED.value ? STATUS_ACTIVE.value : STATUS_ARCHIVED.value;

        projectResourceDayPilotMenu.items.push({
            text: `${label} ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                actions?.updateProject(source.tags.project._id, { project: { status: newStatus } });
            },
        });
    }

    if (hasRole(accountRoleRights, 'gridFilter')) {
        projectResourceDayPilotMenu.items.push({
            text: `Filter this ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                source.calendar.rows.filter(source.tags.project._id);
                actions?.addFilter(PROJECTS.filterListProp, source.tags.project._id);
            },
        });
    }

    // Edit project
    if (
        (project.hasRightsToProject && hasRole(accountRoleRights, 'settingAddEditProjects')) ||
        (project.hasPMRightsToProject && hasRole(accountRoleRights, 'pmAddEditProjects'))
    ) {
        projectResourceDayPilotMenu.items.push({
            text: '-',
        });
        projectResourceDayPilotMenu.items.push({
            text: `Edit ${keyWords.projectKeyWord.toLowerCase()}...`,
            onClick: ({ source }) => {
                actions &&
                    actions.showProjectModal &&
                    actions.showProjectModal(source.tags.project.type, source.tags.project._id);
            },
        });
    }

    return projectResourceDayPilotMenu;
};
