import React from 'react';
import PropTypes from 'prop-types';
import { RequestedLabel } from '../styles';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';

const Repeat = ({ isRepeat, mode }) =>
    isRepeat ? (
        <RequestedLabel data-cy="booking-tasks-icon" mode={mode}>
            <LoopOutlinedIcon />
        </RequestedLabel>
    ) : (
        ''
    );

Repeat.propTypes = {
    isRepeat: PropTypes.bool.isRequired,
    isMilestone: PropTypes.bool,
};

Repeat.defaultProps = {
    isMilestone: false,
};

export default Repeat;
