import { useEffect, useState, useRef, useCallback } from 'react';
export const hubSpotPortalId = '24990999';

export const useHubSpotChat = () => {
    const [hasLoaded, setHasLoaded] = useState(false);
    const [activeConversation, setActiveConversation] = useState(false);
    const eventRef = useRef(null);

    useEffect(() => {
        window.hsConversationsSettings = {
            loadImmediately: true,
            disableAttachment: true,
        };

        // Add event listener.
        if (window.HubSpotConversations) {
            setHasLoaded(true);
        } else {
            window.hsConversationsOnReady = [
                () => {
                    setHasLoaded(true);
                },
            ];
        }

        // Create script component.
        let script = document.createElement('script');
        script.src = `//js.hs-scripts.com/${hubSpotPortalId}.js`;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
            window.hsConversationsOnReady = [];
        };
    }, []);

    // Subscripe to conversation events.
    useEffect(() => {
        eventRef.current = payload => {
            setActiveConversation(payload.conversation.conversationId);
        };

        if (hasLoaded) {
            window.HubSpotConversations.on('conversationStarted', eventRef.current);
        }

        return () => {
            if (window.HubSpotConversations) {
                window.HubSpotConversations.off('conversationStarted', eventRef.current);
            }
        };
    }, [hasLoaded]);

    const openHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.open();
        }
    }, [hasLoaded]);

    const closeHandler = useCallback(() => {
        if (hasLoaded) {
            window.HubSpotConversations.widget.close();
        }
    }, [hasLoaded]);

    return {
        hasLoaded,
        activeConversation,
        openHandler,
        closeHandler,
    };
};
