import styled from 'styled-components';
import { FolderOpen } from '@material-ui/icons';

export const Wrapper = styled.div`
    color: #fff;

    a {
        display: flex;
        height: 50px;
        line-height: 50px;
        margin: 0;

        &:hover,
        &.active {
            background-color: #191918;
            text-decoration: none;
        }

        &.active {
            svg {
                color: #37a6b2;
            }

            span {
                color: #fff;
            }
        }
    }

    svg {
        margin: auto 0 auto 16px;
    }

    span {
        margin-left: 10px;
    }
`;

export const QuickSearch = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;
    cursor: pointer;

    svg {
        margin: 0;
        color: #fff;
        transition: left 0.1s ease-in-out 0s, margin-left 0.1s ease-in-out 0s;
        position: absolute;
        left: 50%;
        line-height: 60px;
        margin-left: -12px;
    }

    .quick-search-text {
        color: #fff;
        opacity: 0;
        transition: all 0.25s cubic-bezier(0.16, 0.85, 0.5, 1) 0s;
    }

    .quick-search-hotkey {
        color: #989797;
        opacity: 0;
        transition: all 0.1s cubic-bezier(0.16, 0.85, 0.5, 1) 0s;
        position: absolute;
        right: 1rem;
    }

    &:hover {
        background-color: #1e1e1e;

        & > svg {
            left: 1rem;
            margin-left: 0;
            transition: left 0.2s ease-in-out 0.1s, margin-left 0.2s ease-in-out 0.1s;
        }

        & > .quick-search-text {
            opacity: 1;
            transition: all 0.25s cubic-bezier(0.16, 0.85, 0.5, 1) 0.25s;
        }

        & > .quick-search-hotkey {
            opacity: 1;
            transition: all 0.25s cubic-bezier(0.16, 0.85, 0.5, 1) 0.35s;
        }
    }
`;

export const TextWrapper = styled.div`
    border-right: ${props => (props.border ? '1px solid #2f2f2f' : 'none')};
    overflow: hidden;
    width: calc(100% - 85px);
`;

export const SearchIconWrapper = styled.div`
    display: flex;
    border-left: 1px solid #2f2f2f;
    height: 100%;
    align-items: center;
    margin-left: auto;
    order: 2;

    svg {
        color: rgba(173, 181, 189, 0.61);

        &:hover {
            color: #fff;
        }
    }
`;

export const ItemContextMenu = styled.div`
    right: 0;
    position: absolute;
    top: 0;
    color: rgba(173, 181, 189, 0.2);
    line-height: 68px;
    padding: 0 7px;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &:hover {
        cursor: pointer;
        border-left: 1px solid #2f2f2f;

        svg {
            color: #fff;
        }
    }
`;

export const GroupItemWrapper = styled.div`
    position: relative;

    .group-context-menu {
        right: 40px;
        position: absolute;
        display: none;
        top: 50%;
        margin-top: -12px;
        width: 20px;
        text-align: center;
    }

    &:hover {
        .group-context-menu {
            color: #fff;
            display: block;
            cursor: pointer;
        }
    }
`;

export const SubGroupIcon = styled(FolderOpen)`
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.26);
    margin-left: 2px;
`;

export const Label = styled.span`
    width: calc(100% - 67px);
    display: inline-block;
    vertical-align: middle;

    &.subgroups {
        width: calc(100% - 95px);
    }
`;

export const EmptyGroupWrapper = styled.div`
    color: #adb5bd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    flex-direction: column;
`;

export const EmptyGroupTitle = styled.span`
    font-size: 17px;
    margin-bottom: 10px;
`;

export const EmptyGroupDesc = styled.p`
    font-size: 12px;
    text-align: center;
    padding: 10px;
    margin-bottom: 0;
`;
