import { DayPilot } from 'daypilot-pro-react';
import { without, includes } from 'lodash';
import { hasRole } from 'utils/rightsUtil';
import { TYPE_REGULAR, STATUS_ARCHIVED, TYPE_UNASSIGNED, STATUS_ACTIVE } from 'enums/resourceEnum';
import { ACTIVE, ARCHIVED } from 'enums/criteriaEnum';
import { getSystemGroupIdToRemoveAfterStatusChange, getSystemGroupIdToAddAfterStatusChange } from 'forms/resourceForm';
import { store } from '../../../../store';
import { removeDPRowResourceById } from 'modules/scheduler/utils/schedulerUtil';

export default (accountRoleRights, accountGridPreferences, keyWords, currentGroup, actions) => resource => {
    const resourceDayPilotMenu = new DayPilot.Menu({
        theme: 'hub-context',
        cssClassPrefix: 'hub-context',
        hideOnMouseOut: false,
        useShadow: true,
        zIndex: 10,
        items: [],
    });
    const isUnassigned = resource?.type === TYPE_UNASSIGNED.value;

    if (accountGridPreferences.groupBookings) {
        resourceDayPilotMenu.items.push({
            text: 'Toggle grouping',
            onClick: ({ source }) => {
                const hasExpanded = 0 < source.groups.expanded().length;

                if (hasExpanded) {
                    source.groups.collapseAll();
                } else {
                    source.groups.expandAll();
                }
            },
        });
    }

    if (hasRole(accountRoleRights, 'gridFilter')) {
        resourceDayPilotMenu.items.push({
            text: `Filter this ${isUnassigned ? 'unassigned' : keyWords.resourceKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                actions && actions.addFilter(source.tags.resource._id);
            },
        });
    }

    if (
        resource &&
        resource.hasRightsToResource &&
        hasRole(accountRoleRights, 'settingAddEditResources') &&
        hasRole(accountRoleRights, 'settingResourceStatus') &&
        !isUnassigned
    ) {
        const isArchived = resource.status === STATUS_ARCHIVED.value;
        const newStatus = isArchived ? STATUS_ACTIVE.value : STATUS_ARCHIVED.value;
        resourceDayPilotMenu.items.push({
            text: `${isArchived ? 'Unarchive' : 'Archive'} ${keyWords.resourceKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                const systemResourceGroups = (store.getState()?.resourceGroupReducer?.resourceGroups || []).filter(
                    resourceGroup => resourceGroup.groupType === 'SYSTEM'
                );
                const resourceGroupsAdd = [];
                const resourceGroupsRemove = [];

                const systemGroupIdToAdd = getSystemGroupIdToAddAfterStatusChange(newStatus, systemResourceGroups);
                systemGroupIdToAdd && resourceGroupsAdd.push(systemGroupIdToAdd);

                const systemGroupIdToRemove = getSystemGroupIdToRemoveAfterStatusChange(
                    resource?.status,
                    systemResourceGroups
                );
                systemGroupIdToRemove && resourceGroupsRemove.push(systemGroupIdToRemove);

                const body = {
                    companySpecific: {
                        status: newStatus,
                    },
                    projectsAdd: [],
                    projectsRemove: [],
                    resourceGroupsAdd,
                    resourceGroupsRemove,
                };

                const resourceFromSourceTags = source.tags.resource;

                if (resourceFromSourceTags && TYPE_REGULAR.value === resourceFromSourceTags.type) {
                    actions &&
                        actions.updateResource(resourceFromSourceTags._id, body, () => {
                            removeDPRowResourceById(resource._id);
                        });
                }
            },
        });
    }

    if (
        currentGroup &&
        !currentGroup.isSmart &&
        ![ACTIVE, ARCHIVED].includes(currentGroup.criteria) &&
        currentGroup.queryParams &&
        !currentGroup.queryParams.itemGroupIds.length &&
        hasRole(accountRoleRights, 'settingResourceGroups')
    ) {
        resourceDayPilotMenu.items.push({
            text: 'Remove from group',
            onClick: ({ source }) => {
                const isInItemIds =
                    currentGroup.queryParams && includes(currentGroup.queryParams.itemIds, source.tags.resource._id);
                const itemIds = without(currentGroup.resources, source.tags.resource._id);
                const data = {
                    queryParams: {
                        itemIds,
                        groupsToAdd: [],
                        groupsToRemove: [],
                        itemsToAdd: [],
                        itemsToRemove: [source.tags.resource._id],
                    },
                };
                if (!isInItemIds) {
                    data.queryParams.itemGroupIds = [];
                }
                actions && actions.updateResourceGroup(currentGroup._id, data);
            },
        });
    }

    if (resource.hasRightsToResource && hasRole(accountRoleRights, 'settingAddEditResources')) {
        resourceDayPilotMenu.items.push({
            text: '-',
        });
        resourceDayPilotMenu.items.push({
            text: `Edit ${isUnassigned ? 'unassigned' : keyWords.resourceKeyWord.toLowerCase()}...`,
            onClick: ({ source }) => {
                const resource = source.tags.resource;
                resource && actions && actions.showResourceModal(resource._id, resource.type);
            },
        });
    }

    return resourceDayPilotMenu;
};
