/* eslint-disable wrap-regex */
import { isNumber, isNaN, isArray, isEmpty, includes, get, isEqual, some, keys, intersection, isNil } from 'lodash';
import moment from 'moment';
import { isDate, isValid } from 'date-fns';
import { overlap } from 'utils/DateUtil';
import {SchemaType} from 'form-schema-validation';

export const maxValidator = (maxValue, message = `This value should be ${maxValue} or less`, optional = false) => ({
    validator: value => {
        if (optional && (value === undefined || value === null || value === '')) {
            return true;
        }

        return parseFloat(value) <= maxValue;
    },
    errorMessage: message,
});

export const minValidator = (minValue, message = `This value should be ${minValue} or greater`, optional = false) => ({
    validator: value => {
        if (optional && (value === undefined || value === null || value === '')) {
            return true;
        }

        return parseFloat(value) >= minValue;
    },
    errorMessage: message,
});

export const positiveValue = (message = `This value should be greater than 0`, optional = false) => ({
    validator: value => {
        if (optional && (value === undefined || value === null || value === '')) {
            return true;
        }

        return parseFloat(value) > 0;
    },
    errorMessage: message,
});

export const exactLengthValidator = maxValue => ({
    validator: value => value && value.length === maxValue,
    errorMessage: `This value should have ${maxValue} digits`,
});

export const minLengthArrayValidator = minValue => ({
    validator: value => value && value.length >= minValue,
    errorMessage: {
        _error: `This Field should have min ${minValue} items`,
    },
});

export const arrayFieldRequireValidator = (arrayFieldName, customErrorMessage) => ({
    validator: (value, field, model, schema) => {
        const isValid = some(value, (item, index) => {
            const fields = keys(item);
            const errorFields = schema.errors[arrayFieldName] && keys(schema.errors[arrayFieldName][index]);

            return !errorFields || (intersection(fields, errorFields).length !== fields.length && 0 === fields.length);
        });

        return (isValid && value?.length) || !value?.length;
    },
    errorMessage: {
        _error: customErrorMessage || `All Fields are required`,
    },
});

export const isMomentDate = {
    validator: value => moment.isMoment(value),
    errorMessage: `Date is Required`,
};

export const lengthValidator = (minLength, fieldName) => ({
    validator: value => value && value.length >= minLength,
    errorMessage: `${fieldName} must have at least ${minLength} characters`,
});

export const cardNumberValidator = {
    validator: value => value && -1 === value.indexOf('*'),
    errorMessage: 'Card Number is not valid',
};

export const dynamicRequireValidator = (dependsOnField, fieldValue, fieldToValidate) => {
    return {
        validator: (value, fieldSchema, formData) => {
            const isValueMatching = isArray(fieldValue)
                ? includes(fieldValue, get(formData, dependsOnField))
                : get(formData, dependsOnField) === fieldValue;

            if (formData.hasOwnProperty(fieldToValidate) && isValueMatching) {
                const valueToValidate = formData[fieldToValidate];

                if (typeof valueToValidate === 'object' && valueToValidate !== null) {
                    return valueToValidate instanceof Date ? isValid(valueToValidate) : isEmpty(valueToValidate);
                }

                return (
                    !isNil(valueToValidate) || Number.isFinite(value) || (typeof value === 'string' && value.length > 0)
                );
            }

            return true;
        },
        errorMessage: 'This field is required',
    };
};

export const startDateValidator = (endDateFieldName, typeFieldName) => ({
    validator: (value, fieldSchema, formData) => {
        if (
            !value ||
            !isDate(value) ||
            !formData.hasOwnProperty(endDateFieldName) ||
            formData[typeFieldName] === 'single'
        ) {
            return true;
        }

        const endDate = new Date(formData[endDateFieldName]).setHours(0, 0, 0, 0);
        const valueToTest = new Date(value).setHours(0, 0, 0, 0);
        return valueToTest <= endDate;
    },
    errorMessage: "Start date can't be greater than end date",
});

export const requiredWithCustomErrorMessage = ({ errorMessage }) => ({
    validator: value => {
        return !!value;
    },
    errorMessage: errorMessage,
});

export const numberValidator = {
    validator: value => {
        if (value && value.length) {
            const number = Number(value);

            return !isNaN(parseFloat(number)) && isNumber(parseFloat(number));
        }

        return undefined;
    },
    errorMessage: 'This is not a number',
};

export const negativeNumberValidator = {
    validator: value => {
        if (value) {
            return 0 <= value;
        }

        return undefined;
    },
    errorMessage: 'This value cannot be a negative number',
};

export const repeatTimesValidator = {
    validator: (value, field, model) => {
        if (model.interval?.value === 'NONE' || model.interval === 'NONE') {
            return true;
        } else {
            const numberInserted = parseFloat(value);
            return Number.isInteger(numberInserted) && numberInserted > 0;
        }
    },
    errorMessage: 'Invalid number',
};

export const emailValidator = {
    validator: value => value && /^[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value),
    errorMessage: 'Invalid email address',
};

export const emailValidatorIfNotEmpty = {
    validator: value => value == null || value === '' || /^[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value),
    errorMessage: 'Invalid email address',
};

export const customOverlapValidator = dateRanges =>
    overlap(dateRanges).overlap
        ? '<strong>Overlapping Dates</strong> It is not possible to have rates which overlap the same dates as another rate being used. Please update your date selection or remove an existing rate.'
        : undefined;

export const customFieldMaxLength = max => value => {
    if (0 < max) {
        return value && value.length > max ? `Must be ${max} characters or less` : undefined;
    }

    return undefined;
};

export const customFieldMaxValidator = maxValue => value => {
    if (0 < maxValue) {
        return value && parseFloat(value) > maxValue ? `This value should be ${maxValue} or less` : undefined;
    }

    return undefined;
};

export const customFieldMinValidator = minValue => value => {
    if (0 < minValue) {
        return value && parseFloat(value) < minValue ? `This value should be ${minValue} or greater` : undefined;
    }
};

export const customFieldEmail = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(value) ? 'Invalid email address' : undefined;

export const fieldColorValidator = value =>
    value && !/^#[0-9A-F]{6}$/i.test(value) ? 'Value is not a valid color' : undefined;

export const customFieldRequiredValidator = value => {
    const msg = `Field is required`;

    switch (true) {
        case value instanceof Date:
            return undefined;
        case Array.isArray(value):
            return value.length === 0 ? msg : undefined;
        case value && typeof value === 'object':
            return Object.values(value).some(Boolean) ? undefined : msg;
        case typeof value === 'string':
            return value === '' ? msg : undefined;
        default:
            return isNil(value) ? msg : undefined;
    }
};

export const confirmationValidator = fieldName => ({
    validator: (value, field, model) => isEqual(model[fieldName], value),
    errorMessage: 'The specified passwords do not match.',
});

export const emptyStringValidator = {
    validator: value => value && value !== '',
    errorMessage: "The value can't be empty",
};

export const requiredValidator = errorMessage => ({
    validator: value => !!value,
    errorMessage,
});

export const hoursIntervalsOverlappingValidator = {
    validator: (workDay, intervals) => {
        const errorMessage = `This hour overlap with another interval`;
        const errorArray = [];
        if (workDay) {
            intervals?.forEach((int, idx) => {
                intervals?.forEach((el, index) => {
                    if (idx !== index) {
                        let startError =
                            (int.start >= el.start && int.start < el.end) ||
                            (int.start === 24 * 60 && int.end === 24 * 60);
                        let endError = int.end > el.start && int.end < el.end;

                        if (startError || endError) {
                            errorArray.push({
                                ...(startError && { start: errorMessage }),
                                ...(endError && { end: errorMessage }),
                            });
                        }
                    }
                });
            });
        }

        return errorArray;
    },
};

// useful for material's optional number input where an empty field returns empty string
export const OptionalNumberFormType = new SchemaType('OptionalNumber', {
    validator(value, key){
        if(value === '' || value === null || value === undefined) {
            return true;
        }

        if (typeof value !== 'number') {
            this.setError(key, 'Value is not a number')
            return false;
        }

        return true;
    },
    requiredValidator(value, key){
        if(value === '' || value === null || value === undefined) {
            return true;
        }

        if (typeof value !== 'number') {
            this.setError(key, 'Value is not a number')
            return false;
        }

        return true;
    },
})
