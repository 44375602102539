import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { Divider } from '@material-ui/core';
import { useAppKeyWords, useHasRights } from 'hooks';
import { useMenuBookingPermissions, useMenuVacationPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import { findByValue, CELLDURATION } from 'modules/scheduler/enums/scale';
import { WAITING_FOR_APPROVAL, SCHEDULED } from 'enums/bookingTypeEnum';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { Alert } from 'reactstrap';
import { DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE } from '../../../../../consts';
import { map } from 'lodash';
import { companyApprovers } from '../../../../../../../actions/companyActions';
import { selectMappedResourceGroups } from '../../../../../../../selectors/resourceGroup';
import { getFormDefaultApprovers } from '../../../../../../request/utils/getFormDefaultApprovers';
import { updateResource } from 'actions/resourceActions';
import { showConfirmationModal } from 'actions/modalActions';
import { selectCompanyApprovers, selectWeekDaysSetting } from 'selectors/company';
import { calculateUnavailabilityDays } from '../utils/calculateUnavailabilityDays';
import { overrideDatesCheck } from '../../../../../../modals/resourceModal/components/AvailabilityOverride/AvailabilityOverrideForm';

const rights = [
    {
        rights: ['settingAddEditResources', 'settingResourceWd'],
        rule: 'all',
        name: 'hasSettingResourceWd',
    },
];

const ScheduleAndRequestSelection = React.forwardRef((props, schedulerRef) => {
    const {
        showRequestResourceOption,
        scale,
        rowTags,
        onClick,
        isRequestMode,
        combineScheduleRequest,
        projectId,
        resourceId,
        resource,
        showRequestVacationOption,
        isDefaultVacationEventSet,
        onRequestVacation,
        hideScheduleRequestItems,
        onClose,
    } = props;

    const { resourceKeyWord } = useAppKeyWords();
    const preparedRowTags = rowTags || {};
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const bookingDefaultState = useSelector(state => state.companyReducer.company.settings.grid.bookingDefaultState);
    const vacationEventName = useSelector(state => state.companyReducer.company.settings.vacationEventName);
    const companyWeekdays = useSelector(selectWeekDaysSetting);
    const { resource: currentResource } = rowTags;

    const { hasSettingResourceWd } = useHasRights(rights);

    const [showDefaultVacationEventTooltip, setShowDefaultVacationEventTooltip] = useState(false);

    const { showScheduleButton, showRequestButton } = useMenuBookingPermissions(
        isRequestMode,
        combineScheduleRequest,
        preparedRowTags
    );

    const approvers = useSelector(selectCompanyApprovers);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(companyApprovers.request());
    }, [dispatch]);

    const { canRequestVacation, hasManageAllVacationsRights } = useMenuVacationPermissions(
        showRequestVacationOption,
        preparedRowTags
    );

    const createVacation = useCallback(() => {
        let defaultApprovers = getFormDefaultApprovers({ approvers, resource, mappedResourceGroups });

        onRequestVacation({
            resource: resource._id,
            approvalInfo: {
                approvers:
                    map(defaultApprovers || resource.defaultApproverIds, approverOrId => {
                        if (approverOrId && typeof approverOrId === 'object') {
                            return { id: approverOrId._id };
                        }

                        return { id: approverOrId };
                    }) || [],
            },
        });
    }, [approvers, mappedResourceGroups, onRequestVacation, resource]);

    const allDay = schedulerRef.current.control.scale !== CELLDURATION.value;
    const createBookingRequest = useCallback(
        type =>
            onClick({
                start: format(currentSelection.start, BOOKING_FORMAT),
                end: format(currentSelection.end, BOOKING_FORMAT),
                project: projectId,
                resource: resourceId,
                scale: findByValue(scale).BEValue,
                state: bookingDefaultState,
                type,
                allDay,
                approvalInfo: type === WAITING_FOR_APPROVAL.value ? { approvers: [] } : undefined,
            }),
        [
            onClick,
            currentSelection.start,
            currentSelection.end,
            projectId,
            resourceId,
            scale,
            bookingDefaultState,
            allDay,
        ]
    );

    return (
        <>
            {hideScheduleRequestItems ? null : (
                <>
                    {showScheduleButton && (
                        <WrappedMenuItem
                            data-cy="schedule-selection"
                            onClick={() => createBookingRequest(SCHEDULED.value)}
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 "
                        >
                            Schedule selection
                        </WrappedMenuItem>
                    )}
                    {showRequestResourceOption && showRequestButton && (
                        <WrappedMenuItem
                            data-cy="request-resource"
                            onClick={() => createBookingRequest(WAITING_FOR_APPROVAL.value)}
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 "
                        >
                            Request {resourceKeyWord.toLowerCase()}
                        </WrappedMenuItem>
                    )}
                </>
            )}
            {(canRequestVacation || hasManageAllVacationsRights) && (
                <>
                    <WrappedMenuItem
                        data-cy="request-vacation"
                        disabled={!preparedRowTags.hasRequestVacationRights}
                        className="rounded ml-2 mr-2 pl-4 pt-2"
                        onClick={() =>
                            isDefaultVacationEventSet ? createVacation() : setShowDefaultVacationEventTooltip(true)
                        }
                    >
                        Request {vacationEventName.toLowerCase()}
                    </WrappedMenuItem>
                    {showDefaultVacationEventTooltip && (
                        <Alert color="warning">{DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE}</Alert>
                    )}
                    <Divider />
                </>
            )}
            {hasSettingResourceWd && resource?.hasRightsToResource && rowTags.isResourceRow && (
                <>
                    <WrappedMenuItem
                        data-cy="mark-as-unavailable"
                        className="rounded ml-2 mr-2 pl-4 pt-2"
                        disabled={!resource.hasRightsToResource}
                        onClick={() => {
                            onClose && onClose();

                            const canNotAddOverride = overrideDatesCheck(
                                {
                                    from: currentSelection.start,
                                    to: currentSelection.end,
                                },
                                Object.values(resource?.customAvailabilitiesOverridesById || {})
                            );

                            if (canNotAddOverride) {
                                dispatch(
                                    showConfirmationModal(
                                        () => {
                                            // do nothing
                                        },
                                        'Not Allowed',
                                        `We are sorry, You cannot mark these dates as unavailable as there is already a custom availability date range set on these dates. Please edit the resource and configure the availability there instead.`,
                                        {
                                            withCancel: false,
                                            confirmButtonText: 'Cancel',
                                        }
                                    )
                                );
                            } else {
                                dispatch(
                                    updateResource.request(currentResource._id, {
                                        companySpecific: {
                                            customAvailabilityOverridesToSave: {
                                                create: calculateUnavailabilityDays(
                                                    currentSelection,
                                                    scale === CELLDURATION.value,
                                                    companyWeekdays,
                                                    currentResource
                                                ),
                                            },
                                        },
                                    })
                                );
                            }
                        }}
                    >
                        Mark as unavailable
                    </WrappedMenuItem>
                </>
            )}
        </>
    );
});

ScheduleAndRequestSelection.propTypes = {
    scale: PropTypes.string.isRequired,
    rowTags: PropTypes.object,
    showRequestResourceOption: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    isRequestMode: PropTypes.bool,
    combineScheduleRequest: PropTypes.bool,
    projectId: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired,
};

ScheduleAndRequestSelection.defaultProps = {
    rowTags: {},
    showRequestResourceOption: false,
    isRequestMode: false,
    combineScheduleRequest: false,
};

export default ScheduleAndRequestSelection;
