import React from 'react';
import MUIButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// TODO: Green Select
// TODO: Green Calendar select

const types = {
    success: {
        color: '#32A675',
        hoverColor: '#6FC5A0',
        textColor: '#ffffff',
    },
    'success-dark': {
        color: '#32A675',
        textColor: '#EAF8F2',
    },
};

const useStyles = makeStyles({
    root: ({ type, inversed, noBorder, inline, size, isLink }) => {
        const { color, textColor, hoverColor } = types[type] || {};
        const buttonTextColor = inversed ? color : textColor;
        const buttonBgColor = inversed ? textColor : color;

        return {
            color: buttonTextColor,
            borderColor: inversed ? color : textColor,
            border: noBorder || inline ? 'none' : undefined,
            backgroundColor: buttonBgColor,
            textTransform: 'unset',
            fontFamily: 'Poppins',
            fontWeight: 'bold',
            ...(inline ? { padding: 0, fontWeight: 500, lineHeight: 'normal' } : {}),
            ...(isLink ? { textDecoration: 'underline' } : {}),
            ...(size === 'small' ? { padding: 0, fontWeight: 400, fontSize: '0.8rem' } : { fontSize: '0.9rem' }),
            '&:hover': {
                backgroundColor: inline ? 'transparent' : hoverColor,
                textDecoration: inline && !isLink ? 'underline' : 'none',
            },
            '&.Mui-disabled': {
                color: buttonTextColor,
                backgroundColor: buttonBgColor,
                opacity: 0.4,
            },
        };
    },
});

export const Button = React.memo(props => {
    const { type, inversed, noBorder, inline, children, size, isLink, nowrap, dataCy, ...otherProps } = props;
    const classes = useStyles({ type, inversed, noBorder, inline, size, isLink, nowrap });

    return (
        <MUIButton
            classes={classes}
            variant="outlined"
            size={size}
            data-cy={dataCy}
            {...otherProps}
            disableElevation
            disableRipple
            disableFocusRipple
        >
            {children}
        </MUIButton>
    );
});

Button.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    inversed: PropTypes.bool,
    noBorder: PropTypes.bool,
    isLink: PropTypes.bool,
};

export default Button;
