import { useTheme } from '@material-ui/styles';
import { OTP_CODE_LENGTH } from 'constants';
import { debounce } from 'lodash';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import ReactOtpInput from 'react-otp-input';
import styled from 'styled-components';

export const OtpInput = memo(
    ({ numInputs = OTP_CODE_LENGTH, isRecoveryInput, onFill, isLoading, hasError, onChange }) => {
        const [otp, setOtp] = useState('');
        const [isSubmitted, setIsSubmitted] = useState(false);
        const theme = useTheme();

        const debouncedOnChange = useMemo(() => {
            return debounce(otp => {
                onFill(otp);
            }, 1000);
        }, [onFill]);

        const handleChange = useCallback(
            enteredOtp => {
                setIsSubmitted(false);
                setOtp(enteredOtp);
                onChange?.(enteredOtp);
            },
            [onChange]
        );

        useEffect(() => {
            if (onFill && !isSubmitted && !isLoading && otp.length === numInputs) {
                setIsSubmitted(true);
                debouncedOnChange(otp);
            }
        }, [otp, onFill, numInputs, isSubmitted, isLoading, isRecoveryInput, debouncedOnChange]);

        return (
            <StyledReactOtpInput
                shouldAutoFocus
                theme={theme}
                isRecoveryInput
                value={otp}
                hasErrored={hasError}
                onChange={handleChange}
                numInputs={numInputs}
                inputStyle={{ width: '28px' }}
                isDisabled={isLoading}
            />
        );
    }
);

const StyledReactOtpInput = styled(ReactOtpInput)`
    input {
        margin: 0 2px;
        font-size: 16px;
        line-height: 32px;
        border: 1px solid ${({ theme }) => theme.palette.grey['400']};
        border-radius: 4px;
    }
`;
