import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { GROUP } from 'enums/groupTypeEnum';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { showProjectModal, showSchedulerBuilderModal, showResourceModal } from 'actions/modalActions';
import { updateResource } from 'actions/resourceActions';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { makeResourceById } from 'selectors/resource';
import { makeProjectById } from 'selectors/project';
import { useGroup } from 'modules/scheduler/hooks/useGroup';
import { useProjectAction } from 'hooks';
import { useDisplayPermissions } from './useDisplayPermissions';

export const useGetModal = (props = {}) => {
    const { isFilterContext = true, schedulerRef, isEditView } = props;
    const dispatch = useDispatch();
    const params = useParams();
    const { updateProject } = useProjectAction(schedulerRef);
    const viewObject = getViewObject(params);
    const { current } = useGroup(params);
    const resourceSelector = useMemo(() => makeResourceById(params.resourceId), [params.resourceId]);
    const resource = useSelector(resourceSelector);
    const projectSelector = useMemo(() => makeProjectById(params.projectId), [params.projectId]);
    const project = useSelector(projectSelector);
    const { shouldDisplayResource, shouldDisplayProject, shouldDisplayBuilder } = useDisplayPermissions();
    let modal;

    if (shouldDisplayResource) {
        modal = () =>
            dispatch(
                showResourceModal({
                    onSubmit: data => dispatch(updateResource.request(params.resourceId, data)),
                    type: resource?.type || null,
                    resourceId: params.resourceId,
                })
            );
    }

    if (shouldDisplayProject) {
        modal = () =>
            dispatch(
                showProjectModal({
                    onSubmit: data => updateProject(params.projectId, data),
                    type: project?.type || TYPE_REGULAR.value,
                    projectId: params.projectId,
                })
            );
    }

    if (shouldDisplayBuilder) {
        modal = initialTab => {
            dispatch(
                showSchedulerBuilderModal({
                    itemTypeName: viewObject.isResourceGroupView ? RESOURCE.name : PROJECT.name,
                    itemId: params.resourceGroupId || params.projectGroupId,
                    groupType: current.type || GROUP,
                    parentGroupId: '',
                    isFilterContext: isFilterContext,
                    initialTab,
                    isEditView,
                })
            );
        };
    }

    return modal;
};
