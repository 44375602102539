import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import Tooltip from '@material-ui/core/Tooltip';

const SuccessButton = props => {
    const { name, dataCy, onClick, disabled, type, btnType = 'button', fixed, className, tooltipText, tooltipEnterDelay, ...restOfProps } = props;

    const buttonJSX = (
        <Button
            color="success"
            data-cy={dataCy}
            disabled={disabled}
            onClick={onClick}
            className={classNames('btn-hub-core', type, { fixed: fixed }, className)}
            type={btnType}
            {...restOfProps}
        >
            {tooltipText && (
                <Tooltip title={tooltipText} placement="top" arrow enterDelay={tooltipEnterDelay}>
                    <div>{name}</div>
                </Tooltip>
            )}
            {!tooltipText && <>{name}</>}
        </Button>
    );

    return buttonJSX;
};

SuccessButton.propTypes = {
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fixed: PropTypes.bool,
    loading: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltipText: PropTypes.string,
    title: PropTypes.string,
    tooltipEnterDelay: PropTypes.number,
};

SuccessButton.defaultProps = {
    name: 'Submit',
    onClick: null,
    disabled: false,
    loading: false,
    fixed: false,
    type: 'btn-primary',
    tooltipEnterDelay: 100,
};

export default SuccessButton;
