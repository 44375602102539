import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const Layout = React.forwardRef((props, ref) => {
    return <LayoutComponent {...props} data-cy={props.dataCy} ref={ref} />;
});

const LayoutComponent = styled.div`
    display: flex;
    flex-direction: ${({ stack }) => (stack ? 'column' : 'row')};
    flex-wrap: ${({ wrap }) => wrap ?? ''};
    padding: ${({ padding }) => padding ?? 0};
    gap: ${({ gap }) => gap ?? 0};
    ${({ flex }) => (flex ? `flex: ${flex}` : '')};
    ${({ vAlign, hAlign, stack }) =>
        stack
            ? css`
                  justify-content: ${vAlign || 'normal'};
                  align-items: ${hAlign || 'normal'};
              `
            : css`
                  justify-content: ${hAlign || 'normal'};
                  align-items: ${vAlign || 'normal'};
              `}
    ${({ stretch, stack }) =>
        stretch && stack
            ? css`
                  flex: 1;
                  height: 100%;
              `
            : stretch
            ? css`
                  flex: 1;
                  width: 100%;
              `
            : ''}
`;

Layout.propTypes = {
    stack: PropTypes.bool,
    stretch: PropTypes.bool,
    padding: PropTypes.string,
    gap: PropTypes.string,
    flex: PropTypes.string,
    vAlign: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'normal', 'space-between', 'space-around', 'stretch']),
    hAlign: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'normal', 'space-between', 'space-around', 'stretch']),
    wrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrp-reverse', 'revert', 'revert-layer']),
};
