import { includes, filter, some, intersection, find, uniqBy, isString } from 'lodash';
import { flow, filter as filterFP, map as mapFP } from 'lodash/fp';
import { hasRole, isOwner } from 'utils/rightsUtil';
import { STATUS_ARCHIVED, TYPE_EVENT, TYPE_REGULAR } from 'enums/projectEnum';
import { TYPE_UNASSIGNED, ROLE_OWNER, STATUS_ACTIVE } from 'enums/resourceEnum';
import { isActive } from 'utils/extensionUtil';
import { PM, UNASSIGNED } from 'enums/extensionShortIdEnum';
import { store } from '../../../store';

const getProject = (rowTags, event) => {
    if (rowTags?.project) {
        return rowTags.project._id;
    }

    if (rowTags?.projectId) {
        return rowTags.projectId;
    }
    const projectId = event?.project?._id || event?.project;

    return !isString(projectId) ? undefined : projectId;
};

const getResourceId = (rowTags, event) => {
    if (rowTags?.resource) {
        return rowTags.resource?._id;
    }

    if (rowTags?.resourceId) {
        return rowTags.resourceId;
    }

    return event?.resourceInfo?._id;
};

class PermittedProjectsCache {
    _cache = {};
    _prevParams = {
        resources: 0,
        projects: 0,
    };

    get(
        { resourceId: loggedInResourceId, resourceRoleRights },
        { projects, resources },
        companyExtensions,
        { excludeUnassigned = false, onlyEvents = false, onlyProjects = false, includeArchived = false } = {}
    ) {
        const keys = { excludeUnassigned, onlyEvents, onlyProjects, includeArchived };
        const cacheKey =
            Object.keys(keys)
                .filter(key => keys[key])
                .join('-') || 'all';

        if (
            this._prevParams.projects !== projects.length ||
            this._prevParams.resources !== resources.length
        ) {
            this._cache = {};
        }

        if (this._cache[cacheKey]) {
            return this._cache[cacheKey];
        }

        const isPMExtensionActive = isActive(companyExtensions, PM);
        const isUnassignedExtensionActive = isActive(companyExtensions, UNASSIGNED);
        const hasIndividualRights = hasRole(resourceRoleRights, 'scheduleMyselfOnAnyProjectOrEvent');
        const hasLimitedIndividualRights = hasRole(resourceRoleRights, 'scheduleMyselfOnProjectsOrEventsIAmPartOf');
        const hasPMManageEventsRights = hasRole(resourceRoleRights, 'pmManageEvents') && isPMExtensionActive;
        const hasManageEventsRights = hasRole(resourceRoleRights, 'manageEvents');
        const hasManageUnassignedRights =
            hasRole(resourceRoleRights, 'manageUnassignedRows') && isUnassignedExtensionActive && !excludeUnassigned;
        const hasPMManageUnassignedRows =
            hasRole(resourceRoleRights, 'pmManageUnassignedRows') &&
            isPMExtensionActive &&
            isUnassignedExtensionActive &&
            !excludeUnassigned;
        const unassignedResourcesByIds = flow(
            filterFP(resource => resource.type === TYPE_UNASSIGNED.value),
            mapFP(resource => resource._id)
        )(resources);

        const excludeUnassignedResourcesFromProject = projectResourceIds => {
            if (excludeUnassigned && 0 < unassignedResourcesByIds.length) {
                return 0 < intersection(unassignedResourcesByIds, projectResourceIds).length;
            }
            return true;
        };

        // 3 phase filtering
        // status
        let statusFiltered = includeArchived
            ? projects
            : projects.filter(project => {
                  return project.status !== STATUS_ARCHIVED.value;
              });

        // type
        const typeFiltered = statusFiltered.filter(project => {
            return (
                (!onlyEvents || project.type === TYPE_EVENT.value) &&
                (!onlyProjects || project.type !== TYPE_EVENT.value)
            );
        });

        // rights / permissions
        const rightPermissionFiltered = typeFiltered.filter(project => {
            return (
                hasIndividualRights ||
                hasManageEventsRights ||
                (hasManageUnassignedRights && excludeUnassignedResourcesFromProject(project.resourceIds)) ||
                ((hasPMManageEventsRights || hasPMManageUnassignedRows) && project.hasPMRightsToProject) ||
                (hasLimitedIndividualRights &&
                    (project.hasRightsToProject || project.resourceIds?.includes(loggedInResourceId)))
            );
        });

        const result = rightPermissionFiltered.map(project => ({
            ...project,
            canScheduleMySelf:
                hasIndividualRights ||
                (hasLimitedIndividualRights &&
                    (project.hasRightsToProject || project.resourceIds?.includes(loggedInResourceId))),
        }));

        this._prevParams.projects = projects.length;
        this._prevParams.resources = resources.length;
        this._cache[cacheKey] = { projects: result, projectIds: result.map(project => project._id) };

        return this._cache[cacheKey];
    }
}

const permittedProjectsCache = new PermittedProjectsCache();

/**
 * @param loggedInResourceId
 * @param isProjectManager
 * @param resourceRoleRights
 * @param projects
 * @param resources
 * @param companyExtensions
 * @param excludeUnassigned - get only projects where ability to ony schedule REGULAR resources
 * @param canRequestResources - get projects for requesting
 * @param onlyEvents
 *
 * @returns {object[]}
 */
export const getPermittedProjects = (
    { resourceId: loggedInResourceId, resourceRoleRights },
    { projects, resources },
    companyExtensions,
    { excludeUnassigned = false, onlyEvents = false, onlyProjects = false, includeArchived = false } = {}
) => {
    return permittedProjectsCache.get(
        { resourceId: loggedInResourceId, resourceRoleRights },
        { projects, resources },
        companyExtensions,
        { excludeUnassigned, onlyEvents, onlyProjects, includeArchived }
    );
};

export const getPermittedProjectsForResource = (
    resourceId,
    account,
    { projects, resources },
    companyExtensions,
    { excludeUnassigned = true, onlyEvents = false, onlyProjects = false, canRequestResources = false }
) => {
    // Owner is permitted to see all projects even if a resource doesn't belong to the project
    // owner is able to auto add a resource to a project
    if (account.resourceRole === ROLE_OWNER.value) {
        return projects.filter(project => {
            return (
                (!onlyEvents || project.type === TYPE_EVENT.value) &&
                (!onlyProjects || project.type === TYPE_REGULAR.value)
            );
        });
    }

    const permittedProjects = getPermittedProjects(account, { projects, resources }, companyExtensions, {
        excludeUnassigned,
        onlyEvents,
        onlyProjects,
    }).projects;

    const filteredProjects = filter(permittedProjects, project =>
        isResourcePermittedOnProject(
            resourceId,
            project._id,
            account,
            { projects, resources },
            companyExtensions,
            !excludeUnassigned,
            permittedProjects
        )
    );

    // if canRequestResources return all project which the resource has permissions to.
    if (canRequestResources) {
        const filteredHasRightsTo = projects.filter(project => {
            return (
                project.hasRightsToProject &&
                (!onlyEvents || project.type === TYPE_EVENT.value) &&
                (!onlyProjects || project.type === TYPE_REGULAR.value)
            );
        });

        return uniqBy([...filteredProjects, ...filteredHasRightsTo], project => project._id);
    }

    return filteredProjects;
};

export const isProjectPermitted = (projectId, account, { projects, resources }, companyExtensions, options) => {
    // Going through all projects, transforming them and using only one seems a overkill
    // especially on big accounts and especially that this function is mostly used per selection
    const permittedProjects = getPermittedProjects(account, { projects, resources }, companyExtensions, options)
        .projectIds;

    return includes(permittedProjects, projectId);
};

class PermittedResourcesByProject {

    _cache = {};
    _prevParams = {
        resources: 0,
    };

    get(project, account, resources, companyExtensions, onlyUnassigned) {

        if (!project?._id) {
            return [];
        }

        if (this._prevParams.resources !== resources?.length) {
            this._prevParams = {
                resources: resources?.length,
            };
            this._cache = {};
        }

        if(!this._prevParams[project._id] || this._prevParams[project._id].resourcesCount !== project.resources) {
            this._cache[project._id] = {
                onlyUnassigned: undefined,
                all: undefined,
            }
            this._prevParams[project._id] = {
                resourcesCount: project.resourcesCount
            }
        }

        if(onlyUnassigned) {
            if(this._cache[project._id]?.onlyUnassigned) {
                return this._cache[project._id].onlyUnassigned
            }
        } else {
            if(this._cache[project._id]?.all) {
                return this._cache[project._id].all
            }
        }

        if (isOwner(account)) {
            const result = onlyUnassigned ? filter(resources, resource => resource.type === TYPE_UNASSIGNED.value) : resources;

            this._cache[project._id][onlyUnassigned ? 'onlyUnassigned' : 'all'] = result;
            return result;
        }


        const isUnassignedExtensionActive = isActive(companyExtensions, UNASSIGNED);
        const loggedInResourceId = account.resourceId;
        const hasIndividualRights = hasRole(account.resourceRoleRights, 'scheduleMyselfOnAnyProjectOrEvent');
        const hasLimitedIndividualRights = hasRole(account.resourceRoleRights, 'scheduleMyselfOnProjectsOrEventsIAmPartOf');
        const hasManageEvents = hasRole(account.resourceRoleRights, 'manageEvents');
        const hasPMManageEvents = hasRole(account.resourceRoleRights, 'pmManageEvents');
        const hasPMManageUnassignedRows =
            isUnassignedExtensionActive && hasRole(account.resourceRoleRights, 'pmManageUnassignedRows');
        const hasManageUnassignedRights =
            isUnassignedExtensionActive && hasRole(account.resourceRoleRights, 'manageUnassignedRows');
        const hasPMRightsOnProject =
            ((hasPMManageEvents || hasPMManageUnassignedRows) && project.hasPMRightsToProject) ||
            (project.projectManagers || []).includes(loggedInResourceId);
        const shouldLoadUnassigned = resourceType =>
            (!onlyUnassigned && resourceType !== TYPE_UNASSIGNED.value) ||
            (onlyUnassigned && resourceType === TYPE_UNASSIGNED.value);
        const isUnassigned = resourceType =>
            ((hasManageEvents || hasPMRightsOnProject) && shouldLoadUnassigned(resourceType)) ||
            ((hasManageUnassignedRights || hasPMRightsOnProject) && shouldLoadUnassigned(resourceType));
        const isResourceOnProject = resourceId => {
            if (project.resourcesMapped !== undefined) {
                return Boolean(project.resourcesMapped[resourceId]);
            }

            return includes(project.resources, resourceId);
        };

        // handler full/PM schedule rights on regular and unassigned
        if (hasManageEvents || hasManageUnassignedRights || hasPMRightsOnProject) {
            const result = filter(resources, resource => {
                const isOnProject = isResourceOnProject(resource._id);

                if (onlyUnassigned) {
                    return (
                        ((hasPMRightsOnProject && (isOnProject || resource.hasRightsToResource)) ||
                            (hasManageUnassignedRights &&
                                project.hasRightsToProject &&
                                (resource.hasRightsToResource || isOnProject))) &&
                        isUnassigned(resource.type) &&
                        resource.status === STATUS_ACTIVE.value
                    );
                }

                if (project.canScheduleMySelf && resource._id === loggedInResourceId) {
                    return true;
                }

                if ((hasManageUnassignedRights || hasPMRightsOnProject) && resource.type === TYPE_UNASSIGNED.value) {
                    return isOnProject || resource.hasRightsToResource;
                }

                if (hasManageEvents) {
                    //is My Project but I have no permissions but have full scheduling rights
                    if (!project.hasRightsToProject && isOnProject) {
                        return resource.hasRightsToResource;
                    }
                    return (project.hasRightsToProject || isOnProject) && (resource.hasRightsToResource || isOnProject);
                }

                if (hasPMRightsOnProject && resource.type !== TYPE_UNASSIGNED.value) {
                    return resource.status === STATUS_ACTIVE.value && (isOnProject || resource.hasRightsToResource);
                }

                return project.canScheduleMySelf && resource._id === loggedInResourceId;
            });


            this._cache[project._id][onlyUnassigned ? 'onlyUnassigned' : 'all'] = result;
            return result;
        }

        // handle hasIndividualRights - return just logged in resource
        if (hasIndividualRights || (hasLimitedIndividualRights && !onlyUnassigned)) {
            const result = filter(resources, resource => resource._id === loggedInResourceId);

            this._cache[project._id][onlyUnassigned ? 'onlyUnassigned' : 'all'] = result;

            return result;
        }

        return [];
    }
}


const permittedResourcesByProject = new PermittedResourcesByProject();

export const getPermittedResourcesByProject = (project, account, resources, companyExtensions, onlyUnassigned) => {
    return permittedResourcesByProject.get(project, account, resources, companyExtensions, onlyUnassigned);
};

export const getPermittedResources = (account, resources, companyExtensions) => {
    const loggedInResourceId = account.resourceId;
    const isPMExtensionActive = isActive(companyExtensions, PM);
    const isUnassignedExtensionActive = isActive(companyExtensions, UNASSIGNED);
    const hasIndividualRights = hasRole(account.resourceRoleRights, 'scheduleMyselfOnAnyProjectOrEvent');
    const hasLimitedIndividualRights = hasRole(account.resourceRoleRights, 'scheduleMyselfOnProjectsOrEventsIAmPartOf');
    const hasManageEvents = hasRole(account.resourceRoleRights, 'manageEvents');
    const hasPMManageEvents = hasRole(account.resourceRoleRights, 'pmManageEvents');
    const hasPMManageUnassignedRows = hasRole(account.resourceRoleRights, 'pmManageUnassignedRows');
    const hasManageUnassignedRows = hasRole(account.resourceRoleRights, 'manageUnassignedRows');
    const hasUnassignedRights =
        isUnassignedExtensionActive &&
        (hasManageUnassignedRows || (isPMExtensionActive && account.isProjectManager && hasPMManageUnassignedRows));
    const hasPMRights = account.isProjectManager && isPMExtensionActive && (hasPMManageEvents || hasUnassignedRights);

    // handle hasIndividualRights - return just logged in resource
    if ((hasIndividualRights || hasLimitedIndividualRights) && !hasManageEvents && !hasPMRights) {
        return [find(resources, resource => resource._id === loggedInResourceId)];
    }

    return filter(resources, resource => {
        if (!hasUnassignedRights && resource.type === TYPE_UNASSIGNED.value) {
            return false;
        }
        return resource.hasRightsToResource;
    });
};

export const isResourcePermittedOnProject = (
    resourceId, // TODO: Rework system so it uses resource | null, project | null for optimization
    projectId,
    account,
    { projects, resources },
    companyExtensions,
    onlyUnassigned,
    permittedProjects = null,
    includeArchived = false
) => {
    if (resourceId && !projectId) {
        const resource = resources.find(({ _id }) => _id === resourceId);
        const canManageUnassignedRows = hasRole(account.resourceRoleRights, 'manageUnassignedRows');
        const pmManageUnassignedRows = hasRole(account.resourceRoleRights, 'pmManageUnassignedRows');

        if (resource?.type === 'UNASSIGNED' && (canManageUnassignedRows || pmManageUnassignedRows)) {
            return true;
        }

        if (!resource || ('UNASSIGNED' === resource.type && !canManageUnassignedRows && !pmManageUnassignedRows)) {
            return false;
        }
    }

    const projectsToUse =
        permittedProjects ||
        getPermittedProjects(account, { projects, resources }, companyExtensions, {
            excludeUnassigned: !onlyUnassigned,
            includeArchived,
        }).projects;
    const filteredProjects = projectId ? filter(projectsToUse, project => project._id === projectId) : projectsToUse;

    return some(filteredProjects, project => {
        const permittedResources = getPermittedResourcesByProject(
            project,
            account,
            resources,
            companyExtensions,
            onlyUnassigned
        );

        return (
            (resourceId === account.resourceId && project.canScheduleMySelf) ||
            some(permittedResources, permittedResource => permittedResource._id === resourceId)
        );
    });
};

export const hasRightsForProjectAndResource = (
    { resourceRoleRights, resourceId, isProjectManager, resourceRole },
    companyExtensions
) => (projectId, rowResourceId, isUnassigned, includeArchived, { project, resource } = {}) => {
    const reduxState = store.getState();
    const projects = reduxState?.projectReducer?.projects ?? [];
    const resources = reduxState?.resourceReducer?.resources ?? [];

    // no need to check owner as operation could be expensive on bigger accounts and owner should have always access to everything
    if (ROLE_OWNER.value === resourceRole) {
        return true;
    }

    let projectsToCheck = projects,
        resourcesToCheck = resources;

    if (!projectsToCheck.length) {
        projectsToCheck = project ? [project] : [];
    }

    if (!resourcesToCheck.length) {
        resourcesToCheck = resource ? [resource] : [];
    }

    const canScheduleOnProject = isProjectPermitted(
        projectId,
        { resourceRoleRights, resourceId, isProjectManager },
        { projects: projectsToCheck, resources: resourcesToCheck },
        companyExtensions,
        { includeArchived }
    );

    const canScheduleOnResource = isResourcePermittedOnProject(
        rowResourceId,
        projectId,
        { resourceRoleRights, resourceId, isProjectManager },
        { projects: projectsToCheck, resources: resourcesToCheck },
        companyExtensions,
        isUnassigned,
        null,
        includeArchived
    );

    return canScheduleOnProject && canScheduleOnResource;
};

export const hasVacationRights = resourceRoleRights =>
    hasRole(resourceRoleRights, 'canRequestVacation') || hasRole(resourceRoleRights, 'manageAllVacations');

export const hasRightsForResource = (rowResourceId, resources) => {
    if (!rowResourceId) {
        return false;
    }

    const resource = resources.find(({ _id }) => _id === rowResourceId);

    return resource && resource.hasRightsToResource;
};

export default ({ resourceRoleRights, resourceId, isProjectManager, resourceRole }, companyExtensions) => (
    isParentMode,
    rowTags,
    event
) => {
    const reduxState = store.getState();
    const projects = reduxState?.projectReducer?.projects ?? [];
    const resources = reduxState?.resourceReducer?.resources ?? [];

    // no need to check owner as operation could be expensive on bigger accounts and owner should have always access to everything
    if (ROLE_OWNER.value === resourceRole) {
        return { schedule: true, requestVacation: true, resource: true };
    }

    // if it is not necessary to check permissions, don't go further
    if (!rowTags || (rowTags && rowTags.menuRow) || (rowTags && rowTags.parent && rowTags.isResourceRow)) {
        return { schedule: false, requestVacation: false, resource: false };
    }
    const projectId = getProject(rowTags, event);
    const rowResourceId = getResourceId(rowTags, event);

    // For unassigned row - when no resourceId and projectId checking only if logged in resource has rights to any project
    // The question is, should it?
    if (!projectId && !rowResourceId) {
        const permission =
            0 <
            getPermittedProjects(
                { resourceRoleRights, resourceId, isProjectManager, resourceRole },
                { projects, resources },
                companyExtensions
            ).projects?.length;
        return { schedule: permission, requestVacation: false, resource: permission };
    }

    const canScheduleOnProject = projectId
        ? isProjectPermitted(
              projectId,
              { resourceRoleRights, resourceId, isProjectManager },
              { projects, resources },
              companyExtensions,
              { excludeUnassigned: true, onlyEvents: false, canRequestResources: false }
          )
        : false;
    const canScheduleOnResource = rowResourceId
        ? isResourcePermittedOnProject(
              rowResourceId,
              projectId,
              { resourceRoleRights, resourceId, isProjectManager },
              { projects, resources },
              companyExtensions,
              rowTags.unassignedRow
          )
        : false;

    const schedule =
        isParentMode && !rowTags.isEventRow && !rowTags.unassignedRow
            ? canScheduleOnProject && canScheduleOnResource
            : canScheduleOnProject || canScheduleOnResource;
    const resource = hasRightsForResource(rowResourceId, resources);
    const requestVacation = !rowTags.unassignedRow && hasVacationRights(resourceRoleRights) && resource;

    return { schedule, requestVacation, resource };
};
