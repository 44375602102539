import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import InitialLoading from 'shared/initialLoading';
import { logout } from 'actions/accountActions';

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    }, [dispatch]);

    return <InitialLoading text="Log out..." />;
};
