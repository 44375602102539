import moment from 'moment';
import {
    extend,
    isUndefined,
    isString,
    union,
    filter,
    mapObject,
    find,
    isNumber,
    each,
    first,
    isObject,
    isArray,
} from 'underscore';
import { updateObject, updateItemInArray } from './helpers/updater';
import createReducer from './helpers/createReducer';
import StorageUtil from 'utils/StorageUtil';
import { HOURS_DECIMAL } from 'modules/report/enums/reportUnitTypeEnum';
import { storageItemNames } from 'modules/report/enums/storageItemEnum';
import { TIME } from 'modules/report/enums/columnUnitTypeEnum';
import { COLUMN } from 'enums/chartTypeEnum';
import * as actionTypes from 'actions/actionTypes';
import { getFiltersBasedOnColumnsToLoad, getItemIdByGrouping } from 'modules/report/utils/transformer';
import uuid from 'uuid/v1';

const filters = {
    checkedGroups: undefined,
    // Below there is a structure, initially we set an undefined to be compatible with old reports which won't have this
    // data, in case of checkedGroups to be undefined all groups will be checked
    // checkedGroups: {
    //     PROJECT: [],
    //     EVENT: [],
    //     RESOURCE: [],
    //     UNASSIGNED: [],
    // },
    projects: [],
    resources: [],
    projectTags: [],
    resourceTags: [],
    resourceCustomFields: [],
    projectCustomFields: [],
    projectManagers: [],
    customers: [],
    projectStatuses: undefined,
    resourceStatuses: undefined,
    bookingStatuses: undefined,
    timeEntryStatuses: undefined,
    bookingCategories: [],
    billable: undefined,
};

export const initialReportState = {
    data: { data: [], total: 0 },
    reportTemplates: [],
    reportsGroups: [],
    loading: false,
    loadingGroups: false,
    loadingTemplates: false,
    token: '',
    reportQueryChanged: false,
    queryParams: {
        filters: {
            ...filters,
            roles: [],
            budgetStatus: '',
            budgetRange: { operator: '', value: '' },
            projectStartDate: { operator: '', date: null },
            projectEndDate: { operator: '', date: null },
            resultValue: [],
        },
        programmaticallyFilters: filters,
        sort: {
            columnName: 'name',
            direction: 'desc',
        },
        showChart: false,
        limit: 20,
        page: 0,
        startDate: moment()
            .startOf('month')
            .toDate(),
        endDate: moment()
            .endOf('month')
            .toDate(),
        type: '',
        chartType: COLUMN.value,
        xAxes: [],
        yAxes: TIME.value,
        chartColor: { h: 203, s: 78, l: 44 },
        subType: '',
        displayEmptyResults: false,
        eventTime: undefined,
        unassignedTime: undefined,
        dateState: 'This',
        periodType: 'month',
        groupBy: 'project',
        secondGroupBy: '',
        itemType: 'project',
        split: '',
        templateType: '',
        context: {},
        heatMapRules: [],
        id: '',
        secondGroupById: '',
        currentTemplateId: '',
        itemIds: [],
        groupIds: [],
        columnsToLoad: [],
        columnsToLoadSizes: {},
        columnType: 'DYNAMIC',
        unit: HOURS_DECIMAL.value,
    },
};

const changeFiltersDateValues = filters =>
    mapObject(filters, filter => {
        if (filter && filter.hasOwnProperty('date') && filter.date) {
            return {
                ...filter,
                date: new Date(filter.date),
            };
        }

        return filter;
    });

const updateQueryParams = (state, action) => {
    const isLoading = isUndefined(action.payload.shouldRequest) || action.payload.shouldRequest;
    let parsedFilters = changeFiltersDateValues(
        extend({}, state.queryParams.filters, action.payload.queryParams.filters)
    );
    let queryParams = extend({}, state.queryParams, action.payload.queryParams, {
        filters: getFiltersBasedOnColumnsToLoad(parsedFilters, action.payload.queryParams.columnsToLoad),
    });

    if (isString(queryParams.startDate)) {
        queryParams.startDate = new Date(queryParams.startDate);
    }

    if (isString(queryParams.endDate)) {
        queryParams.endDate = new Date(queryParams.endDate);
    }

    if (queryParams.groupBy) {
        StorageUtil.setItem('datesStorage', {
            startDate: queryParams.startDate,
            endDate: queryParams.endDate,
            dateState: queryParams.dateState,
            periodType: queryParams.periodType,
        });
    }
    const queryParamsCopy = extend({}, queryParams);

    StorageUtil.setItem('filters', queryParamsCopy.filters);

    StorageUtil.setItem('columns', {
        columnsToLoad: queryParamsCopy.columnsToLoad,
        columnsToLoadSizes: queryParamsCopy.columnsToLoadSizes,
    });
    delete queryParamsCopy.columnsToLoad;
    delete queryParamsCopy.columnsToLoadSizes;

    if (
        !action.payload.storageItemName ||
        (action.payload.storageItemName && 'reportLevel4Params' !== action.payload.storageItemName)
    ) {
        delete queryParamsCopy.startDate;
        delete queryParamsCopy.endDate;
        delete queryParamsCopy.dateState;
        delete queryParamsCopy.periodType;
        delete queryParamsCopy.filters;
    }

    if (!action.payload.storageItemName) {
        clearAllLevelsAbove('reportQuery');
        StorageUtil.setItem('reportQuery', queryParamsCopy);
    } else if (action.payload.storageItemName) {
        clearAllLevelsAbove(action.payload.storageItemName);
        StorageUtil.setItem(action.payload.storageItemName, queryParamsCopy);
    }

    return updateObject(state, {
        reportQueryChanged: true,
        loading: isLoading,
        queryParams,
        data: isLoading ? { data: [], total: 0 } : state.data,
    });
};

/**
 * Remove all items from storage above given
 * e.g all after reportLevel2Params
 *
 * @param {string} currentStorageItemName
 */
const clearAllLevelsAbove = currentStorageItemName => {
    const regex = new RegExp(/\d+/);
    const matchCurrentItem = currentStorageItemName && currentStorageItemName.match(regex);
    if (matchCurrentItem && matchCurrentItem[0]) {
        each(storageItemNames, storageItemName => {
            const matchItem = storageItemName.match(regex);
            if (matchItem && matchItem[0] && parseInt(matchItem[0]) > parseInt(matchCurrentItem[0])) {
                StorageUtil.removeItem(storageItemName);
            }
        });
    } else if ('reportQuery' === currentStorageItemName || !currentStorageItemName) {
        each(storageItemNames, storageItemName => {
            if ((currentStorageItemName && currentStorageItemName !== storageItemName) || !currentStorageItemName) {
                StorageUtil.removeItem(storageItemName);
            }
        });
    }
};

const setReportData = (state, action) =>
    updateObject(state, { data: action.payload.data, loading: false, requestId: uuid() });

const updateContextRowRequest = (state, action) => {
    const itemId = action.payload.item && (action.payload.item.id || action.payload.item.itemId || '');
    if (!itemId) {
        return state;
    }
    const queryParams = updateObject(state.queryParams, {
        id: getItemIdByGrouping(itemId, state.queryParams.groupBy),
    });

    if (isObject(itemId)) {
        queryParams.secondGroupById = getItemIdByGrouping(itemId, state.queryParams.secondGroupBy);
    }

    return updateObject(state, { queryParams });
};

const updateContextRowSuccess = (state, action) => {
    let context = action.payload.data.data;
    if (action.payload.queryParams && isArray(action.payload.data.data)) {
        const { groupBy, secondGroupBy } = action.payload.queryParams;
        context = find(action.payload.data.data, item => {
            if (isObject(item.itemId)) {
                return (
                    item.itemId[groupBy] &&
                    item.itemId[groupBy] === state.queryParams.id &&
                    item.itemId[secondGroupBy] &&
                    item.itemId[secondGroupBy] === state.queryParams.secondGroupById
                );
            }

            return item.itemId === state.queryParams.id;
        });
        if (!context && 1 === action.payload.data.data.length) {
            context = first(action.payload.data.data);
        }
        context = context || mapObject(state.queryParams.context, context => (isNumber(context) ? 0 : context));
    }

    const queryParams = updateObject(state.queryParams, { context });
    if (action.payload.storageItemName) {
        const storageData = StorageUtil.getItem(action.payload.storageItemName) || {};
        StorageUtil.setItem(action.payload.storageItemName, {
            ...storageData,
            context,
            secondGroupById: queryParams.secondGroupById,
        });
    }

    return updateObject(state, { queryParams });
};

const resetReportData = (state, action) => {
    if (action.payload.hardReset) {
        clearAllLevelsAbove();
        StorageUtil.removeItem('token');
        StorageUtil.removeItem('filters');

        return updateObject(initialReportState, {
            reportTemplates: state.reportTemplates,
            reportsGroups: state.reportsGroups,
        });
    }

    return updateObject(state, {
        data: { data: [] },
        loading: false,
        reportsGroups: state.reportsGroups,
    });
};

const updateReportTemplates = (state, action) =>
    updateObject(state, {
        reportTemplates: action.payload.templates || state.reportTemplates,
        loadingTemplates: !action.payload.templates,
    });

const addReportTemplate = (state, action) =>
    updateObject(state, {
        reportTemplates: union(state.reportTemplates, [action.payload.template]),
    });

const updateReportTemplate = (state, action) =>
    updateObject(state, {
        reportTemplates: updateItemInArray(state.reportTemplates, action.payload.template._id, template =>
            updateObject(template, action.payload.template)
        ),
    });

const deleteReportTemplate = (state, action) =>
    updateObject(state, {
        reportTemplates: filter(state.reportTemplates, template => template._id !== action.payload.id),
    });

const shareReport = (state, action) => {
    StorageUtil.setItem('token', action.payload.token);

    return updateObject(state, {
        token: action.payload.token,
        reportQueryChanged: false,
    });
};

const getReportsGroups = (state, action) => {
    return updateObject(state, {
        reportsGroups: action.payload.data,
        loadingGroups: false,
    });
};

const updateReportsGroup = (state, action) => {
    const newGroups = state.reportsGroups.map(group =>
        group._id === action.payload.data._id ? action.payload.data : group
    );

    return updateObject(state, {
        reportsGroups: newGroups,
    });
};

const removeReportsGroup = (state, action) => {
    const newGroups = state.reportsGroups.filter(group => group._id !== action.payload.groupId);

    return updateObject(state, {
        reportsGroups: newGroups,
    });
};

const addReportsGroupRequest = state => {
    return updateObject(state, {
        loadingGroups: true,
    });
};

const checkGroups = (state, action) => {
    return updateObject(state, {
        queryParams: {
            ...(state.queryParams || {}),
            filters: {
                ...(state.queryParams.filters || {}),
                checkedGroups: {
                    ...(state.queryParams?.filters?.checkedGroups || {}),
                    [action.payload.itemTypeName]: action.payload.groupIds,
                },
            },
        },
    });
};

export default createReducer(extend({}, initialReportState), {
    [actionTypes.UPDATE_REPORT_QUERY_PARAMS]: updateQueryParams,
    [actionTypes.GET_REPORT['SUCCESS']]: setReportData,
    [actionTypes.UPDATE_CONTEXT_ROW['REQUEST']]: updateContextRowRequest,
    [actionTypes.UPDATE_CONTEXT_ROW['SUCCESS']]: updateContextRowSuccess,
    [actionTypes.GET_REPORT['FAILURE']]: resetReportData,
    [actionTypes.GET_REPORT_TEMPLATES['REQUEST']]: updateReportTemplates,
    [actionTypes.GET_REPORT_TEMPLATES['SUCCESS']]: updateReportTemplates,
    [actionTypes.CREATE_REPORT['SUCCESS']]: addReportTemplate,
    [actionTypes.UPDATE_REPORT['SUCCESS']]: updateReportTemplate,
    [actionTypes.DELETE_REPORT['SUCCESS']]: deleteReportTemplate,
    [actionTypes.UPDATE_FAVOURITES['SUCCESS']]: updateReportTemplate,
    [actionTypes.SHARE_REPORT['SUCCESS']]: shareReport,
    [actionTypes.GET_REPORTS_GROUPS['SUCCESS']]: getReportsGroups,
    [actionTypes.UPDATE_REPORTS_GROUP['SUCCESS']]: updateReportsGroup,
    [actionTypes.DELETE_REPORTS_GROUP['SUCCESS']]: removeReportsGroup,
    [actionTypes.ADD_REPORTS_GROUP['REQUEST']]: addReportsGroupRequest,
    [actionTypes.RESET_REPORT_DATA]: resetReportData,
    [actionTypes.REPORT_CHECK_GROUPS]: checkGroups,
});
