import { companyBillingInfo } from 'actions/companyActions';
import { connect } from 'react-redux';
import { deleteBooking } from 'actions/bookingActions';
import { updateRequestModel } from 'actions/requestActions';
import { getReportTemplates } from 'actions/reportActions';
import Sidebar from 'modules/sidebar/components/index';
import { getReportsGroups } from 'selectors/reportTemplates';

export default connect(
    state => ({
        account: state.account,
        companyTree: state.companyTree,
        reportTemplates: state.reportReducer.reportTemplates,
        reportsGroups: getReportsGroups(state),
        vacationEventName: state.companyReducer.company.settings.vacationEventName,
        resourceKeyWord: state.companyReducer.company.settings.keyword.keyword2Singular,
        projectKeyWord: state.companyReducer.company.settings.keyword.keyword1Singular,
        resourcePluralKeyWord: state.companyReducer.company.settings.keyword.keyword2Plural,
        projectPluralKeyWord: state.companyReducer.company.settings.keyword.keyword1Plural,
        customerKeyWord: state.companyReducer.company.settings.keyword.keyword3Singular,
        approvees: state.resourceReducer.approvees,
        resources: state.resourceReducer.resources,
        currentView: state.menuContent.currentView,
        customerPluralKeyWord: state.companyReducer.company.settings.keyword.keyword3Plural,
        company: state.companyReducer.company,
        location: state.router.location,
    }),
    dispatch => ({
        deleteBooking: bookingId => dispatch(deleteBooking.request(bookingId)),
        getBillingInfo: () => dispatch(companyBillingInfo.request()),
        updateRequestModel: (itemsToUpdate, refreshStatistics) =>
            dispatch(updateRequestModel(itemsToUpdate, refreshStatistics)),
        getReportTemplates: filters => dispatch(getReportTemplates.request(filters)),
    })
)(Sidebar);
