import styled from 'styled-components';

export const ProjectManager = styled.span`
    background-color: #63acc1;
    color: #394d53;
    font-size: 7px;
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border-radius: 50%;
    font-weight: bold;
    margin-right: 5px;
    text-shadow: none;
    border: 1px solid #fff;
`;
