import { includes } from 'lodash';

export default args => {
    const parent = args.row.parent();
    if (!args.filter?.length) {
        args.visible = true;
        return;
    }
    if (args.row.tags.isResourceRow && parent && !includes(args.filter, args.row.tags.resource?._id)) {
        args.visible = false;
    }
    if (args.row.tags.isProjectRow && parent && !includes(args.filter, args.row.tags.project?._id)) {
        args.visible = false;
    }
    if (args.row.tags.isEventRow || args.row.tags.menuRow) {
        args.visible = true;
    }
};
