import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SanitizeHTML } from './SanitizeHTML';

const useStyles = makeStyles({
    FaqItem_main: {
        cursor: 'pointer',
        border: '1px solid #e9eaee',
        margin: '5px 0px',
        padding: '10px 20px',
        fontSize: '14px',
        backgroundColor: '#fff',
    },
    FaqItem_question: {
        display: 'flex',
        justifyContent: 'space-between',
        fontWeight: 500,
    },
    FaqItem_answer: {
        color: '#838383',
    },
});

export const FaqItem = React.memo(props => {
    const { question, answer } = props;

    const [showAnswer, setShowAnswer] = useState(false);
    const classes = useStyles();

    return (
        <div className={classes.FaqItem_main} onClick={() => setShowAnswer(!showAnswer)}>
            <div className={classes.FaqItem_question}>
                <span>{question}</span>
                {showAnswer && <ExpandLessIcon />}
                {!showAnswer && <ExpandMoreIcon />}
            </div>
            {showAnswer && <SanitizeHTML html={answer} className={classes.FaqItem_answer} />}
        </div>
    );
});

export const faqItemPropTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
};
FaqItem.propTypes = faqItemPropTypes;

export default FaqItem;
