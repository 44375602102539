import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import * as actionTypes from 'actions/actionTypes';
import { getTagsRequest, createTagRequest } from 'api/tag';
import { createTag, getTags } from 'actions/tagActions';
import { addNotification } from 'actions/notificationActions';
import accessDeniedHandler from 'sagas/handlers/accessDeniedHandler';
import { monitoring } from '../monitoring';

const defaultMessage = 'Something went wrong... cannot load tags for you';

const getTagsSelector = type => state => state.tagReducer[`${type}Tags`];
const getFormFieldValue = (formName, fieldName) => state => state.form[formName].values[fieldName];

function* handleCreateTag(action) {
    try {
        const { data, formName, fieldName, type } = action.payload;
        const tagResponse = yield call(createTagRequest, data);

        yield put(createTag.success(tagResponse, type));
        if (formName && fieldName) {
            const currentValues = yield select(getFormFieldValue(formName, fieldName));
            yield put(
                change(formName, fieldName, [...currentValues, { _id: tagResponse._id, value: tagResponse.value }])
            );
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: 'Tag was not created', type: 'danger' }));
    }
}

function* tagsRequest(action) {
    try {
        const tags = yield select(getTagsSelector(action.payload.type));
        if (tags.length) {
            return;
        }
        const tagsResponse = yield call(getTagsRequest, action.payload.type);

        yield put(getTags.success(tagsResponse, action.payload.type));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

export default function* tagWatcher() {
    yield takeEvery(actionTypes.GET_TAGS['REQUEST'], tagsRequest);
    yield takeLatest(actionTypes.CREATE_TAG['REQUEST'], handleCreateTag);
}
