import { updateDeadlinesVisibility, updateVisibleLinksOnScheduler } from '../../utils/schedulerUtil';

export default ({
    removeContextMenu,
    setSchedulerSelectionRequest,
    schedulerRef,
    updateBookingSelectionRequest,
}) => args => {
    document.activeElement?.blur();

    removeContextMenu();
    const cell = schedulerRef.current.control.cells.find(args.start, args.resource);
    setSchedulerSelectionRequest(args.start, args.end, cell.first()?.properties.tags);
    updateBookingSelectionRequest(null);

    updateDeadlinesVisibility();
    updateVisibleLinksOnScheduler();
};
