import React from 'react';
import PropTypes from 'prop-types';
import NestedMenuItem, {NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} from 'shared/nestedMenuItem';
import DatesForm from '../datesForm';
import Scrollbar from 'shared/scrollbar';
import { WrappedMenuItem } from '../../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { Layout } from 'shared/components/layout/index';

const DatesMode = props => {
    const { onClose, deleteDates, currentSelectedBooking, updateProject, project } = props;
    return (
        <Layout stack padding="0.5rem 0">
            <NestedMenuItem label="Start & end dates" parentMenuOpen={true} width={300}>
                <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                    <DatesForm
                        project={project}
                        currentSelection={currentSelectedBooking}
                        updateProject={updateProject}
                        onClose={onClose}
                    />
                </Scrollbar>
            </NestedMenuItem>
            <WrappedMenuItem onClick={deleteDates}>Delete dates</WrappedMenuItem>
        </Layout>
    );
};

DatesMode.propTypes = {
    onClose: PropTypes.func,
};

DatesMode.defaultProps = {
    onClose: null,
};

export default DatesMode;
