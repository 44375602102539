import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { head } from 'lodash';
import { Media } from 'reactstrap';
import { AWS_S3_URL } from 'constants';
import { upload } from 'api/asset';
import { addNotification } from 'actions/notificationActions';
import { Wrapper, Label } from './styles';

const UploadButton = ({ onChange, name, style, className, defaultThumb }) => {
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultThumb);

    useEffect(() => {
        setImagePreviewUrl(defaultThumb);

        onChange({ [name]: defaultThumb });
    }, [name, onChange, defaultThumb]);

    const onUpload = event => {
        const file = head(event.target.files);

        upload(file)
            .then(path => {
                setImagePreviewUrl(path);
                onChange({ [name]: path });
            })
            .catch(() => {
                addNotification({
                    message: "We couldn't upload your file. Please try again...",
                    type: 'danger',
                });
            });
    };

    return (
        <Wrapper className="upload-button">
            <Label style={style} className={className}>
                <input
                    name={name}
                    className="upload-button-file"
                    onChange={onUpload}
                    accept="image/*"
                    type="file"
                    data-cy="res-modal-upload"
                />
                <Media object src={`${AWS_S3_URL}${imagePreviewUrl}`} alt="placeholder image" />
            </Label>
        </Wrapper>
    );
};

UploadButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    name: PropTypes.string,
    defaultThumb: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

UploadButton.defaultProps = {
    value: '',
    name: '',
    defaultThumb: '',
    className: '',
    style: {},
};

export default UploadButton;
