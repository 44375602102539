import React, { createContext, useCallback, useContext } from 'react';
import { getViewObject } from '../../utils/schedulerUtil';
import { useRouteParams } from '../../../../hooks';

const defaultContextValue = {
    expandedParentAccordion: undefined,
    expandedChildAccordion: undefined,
    expandFilter: () => {
        // do nothing
    },
};

const DropdownFiltersContext = createContext(defaultContextValue);

const DropdownFiltersContextProvider = props => {
    const { children } = props;
    const params = useRouteParams();
    const viewObject = getViewObject(params);

    const [expandedFilter, setExpandedFilter] = React.useState({
        parentAccordion: viewObject.isResourceView ? 'resourceCustomFields' : 'projectCustomFields',
        childAccordion: null,
    });

    const expandFilter = useCallback(({ parentAccordion, childAccordion } = {}) => {
        setExpandedFilter(() => ({
            parentAccordion,
            childAccordion,
        }));
    }, []);

    return (
        <DropdownFiltersContext.Provider
            value={{
                expandedParentAccordion: expandedFilter.parentAccordion,
                expandedChildAccordion: expandedFilter.childAccordion,
                expandFilter,
            }}
        >
            {children}
        </DropdownFiltersContext.Provider>
    );
};

const useDropdownFiltersContext = () => {
    return useContext(DropdownFiltersContext);
};

export { useDropdownFiltersContext, DropdownFiltersContextProvider };
