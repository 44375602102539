import Schema from 'form-schema-validation';
import { map } from 'lodash';
import { format, startOfDay, endOfDay, isSameDay } from 'date-fns';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { formatNumber } from 'utils/formatingUtil';
import { errorMessages } from 'utils/schemaUtil';
import { startDateValidator, repeatTimesValidator, negativeNumberValidator } from 'utils/formValidators';
import { getDefaultCategoryTemplate, getDefaultCategoryByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';

export const FORM_NAME = 'requestResourceForm';

export const formSchema = new Schema(
    {
        startDate: {
            type: Date,
            required: true,
            defaultValue: startOfDay(new Date()),
            validators: [startDateValidator('endDate', 'datepickerType')],
            validatorInterdependent: true,
        },
        endDate: {
            type: Date,
            required: true,
            defaultValue: endOfDay(new Date()),
        },
        title: {
            type: String,
            required: false,
        },
        note: {
            type: String,
            required: false,
        },
        approvers: {
            type: Array,
            required: false,
        },
        resource: {
            type: Object,
            required: true,
        },
        project: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
        state: {
            type: String,
            required: true,
            defaultValue: 'PERCENTAGE',
        },
        datepickerType: {
            type: String,
            required: false,
            defaultValue: 'single',
        },
        percentage: {
            type: Number,
            required: true,
            defaultValue: 100,
            validators: [negativeNumberValidator],
        },
        hours: {
            type: Number,
            required: true,
            defaultValue: 0,
            validators: [negativeNumberValidator],
        },
        total: {
            type: Number,
            required: true,
            defaultValue: 0,
            validators: [negativeNumberValidator],
        },
        interval: {
            type: String,
            required: true,
            defaultValue: 'NONE',
        },
        notifyPM: {
            type: Boolean,
            required: false,
            defaultValue: false,
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = ({ categoryGroups, categoryTemplates, state, currentSelection, notifyPM } = {}) => {
    const defaultFormSchemaValues = formSchema.getDefaultValues();
    const startDate = currentSelection?.start || defaultFormSchemaValues.startDate;
    const endDate = currentSelection?.end || defaultFormSchemaValues.endDate;
    const project = currentSelection?.rowTags?.project || defaultFormSchemaValues.project;
    const resource = currentSelection?.rowTags?.resource || defaultFormSchemaValues.resource;

    const projectExists = project?._id || project?.id;
    const category = projectExists
        ? getDefaultCategoryByProject(project, categoryTemplates, categoryGroups)
        : getDefaultCategoryTemplate(categoryGroups, categoryTemplates);

    return Object.assign({}, defaultFormSchemaValues, {
        category,
        startDate,
        endDate,
        project,
        resource,
        notifyPM: notifyPM?.enabled,
        state,
    });
};

export const getBookingValues = (booking, resources, allApprovers = []) => {
    const defaultFormSchemaValues = formSchema.getDefaultValues();
    const startDate = new Date(booking.start.value);
    const endDate = new Date(booking.end.value);
    const project = booking.project;
    const bookingApprovers = booking.approvalInfo.approvers;
    const allApproversById = allApprovers.reduce((acc, approver) => {
        acc[approver._id] = approver;
        return acc;
    }, {});
    const approvers = bookingApprovers.map(a => allApproversById[a.id]).filter(Boolean);
    const resource = booking.resourceInfo;
    const category = booking.categoryTemplate;
    const state = booking.state;
    const datepickerType = isSameDay(startDate, endDate) ? 'single' : 'range';

    return Object.assign({}, defaultFormSchemaValues, {
        datepickerType,
        approvers,
        category,
        startDate,
        endDate,
        project,
        resource,
        state,
        useToil: booking.useToil ? 'true' : 'false',
        notifyPM: booking.ccProjectManagersOnRequest || false,
        ...(booking.minutesPerDay ? { hours: booking.minutesPerDay / 60 } : {}),
        ...(booking.interval ? { interval: booking.interval } : {}),
        ...(booking.approvalInfo.requesterNote ? { note: booking.approvalInfo.requesterNote } : {}),
        ...(booking.percentAllocation ? { percentage: booking.percentAllocation } : {}),
        ...(booking.repeatTimes ? { repeatTimes: booking.repeatTimes } : {}),
        ...(booking.repeat ? { repeat: booking.repeat } : {}),
        ...(booking.title ? { title: booking.title } : {}),
        ...(booking.totalBucketMinutesAllocation ? { total: booking.totalBucketMinutesAllocation / 60 } : {}),
    });
};

export const mapFormToRequest = (values, bookingId) => {
    const approvers = map(values.approvers, approver => ({ id: approver._id }));
    const approvalInfo = {
        approvers,
        requesterNote: values.note,
    };

    return [
        {
            ...(bookingId ? { _id: bookingId } : {}),
            project: values.project?._id,
            resource: values.resource?._id,
            approvalInfo,
            type: WAITING_FOR_APPROVAL.value,
            title: values.title,
            start: format(values.startDate, BOOKING_FORMAT),
            end: format(values.endDate, BOOKING_FORMAT),
            state: values.state,
            percentAllocation: formatNumber(values.percentage),
            minutesPerDay: formatNumber(values.hours * 60),
            totalBucketMinutesAllocation: formatNumber(values.total * 60),
            interval: values.interval,
            repeatTimes: 'NONE' !== values.interval ? formatNumber(values.repeatTimes) : 0,
            repeat: 'NONE' !== values.interval,
            categoryTemplateId: values.category._id || null,
            ccProjectManagersOnRequest: values.notifyPM,
            useToil: values.useToil === 'true'
        },
    ];
};
