import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormText, Row, TabPane, Alert } from 'reactstrap';
import { useIsExtensionInstalled, useAppKeyWords, useHasRights } from 'hooks';
import { format } from 'date-fns';
import { PM, UNASSIGNED } from 'enums/extensionShortIdEnum';
import {
    chooseField,
    multiSelectField,
    dropdownField,
    colorInputField,
    dateField,
    inputGroupTextField,
    materialInputGroupField,
} from 'shared/formFields';
import Allocation from 'shared/allocation/materialAllocation';
import Repeat from 'shared/repeat';
import CategoryTemplateItem from 'shared/categoryTemplateItem';
import CategoryGroupItem from 'shared/categoryGroupItem';
import { isOwner } from 'utils/rightsUtil';
import { useSchedulerData } from 'modules/scheduler/hooks';
import { FORM_NAME } from './modal';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { getAccount } from 'selectors/account';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CELLDURATION } from '../../../scheduler/enums/scale';
import SuccessButton from '../../../../shared/buttons/success';
import { selectIsDeadlinesExtensionInstalled } from '../../../../selectors/company';
import { IconButton } from '../../../../shared/components/iconButton';
import { useValidate } from '../../../../forms/scheduleResourceForm';
import { showMissingDeadlinesExtensionModal } from '../../../../actions/modalActions';
import { addDays } from '../../../../utils/DateUtil';
import { Divider, Layout, Typography } from '@hub-mono/ui';

const HOUR_MODE_SELECTION_INFO =
    'You have made a selection in the scheduler using hours scale and therefore you cannot adjust the allocation via the modal. The allocation is made by your selection.';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const ScheduleBasicInfoTab = props => {
    const {
        tabId,
        isEditMode,
        onFieldChange,
        onCategoryChange,
        onProjectChange,
        categoryTemplates,
        isCategoryColorUsed,
        booking,
    } = props;

    const formValues = useSelector(getFormValues(FORM_NAME));
    const { project, interval, bookingType, state, start, end, useProjectColor } = formValues ?? {};
    
    const isUnassignedExtensionInstalled = useIsExtensionInstalled(UNASSIGNED);
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const { projectKeyWord, resourceKeyWord, resourcePluralKeyWord } = useAppKeyWords();
    const { isProjectManager, resourceRole } = useSelector(getAccount);

    const {
        hasManageUnassignedRowsRights,
        hasPMManageUnassignedRowsRights,
        hasScheduleRights,
        hasPMScheduleRights,
    } = useHasRights([
        {
            rights: ['manageUnassignedRows'],
            rule: 'one',
            name: 'hasManageUnassignedRowsRights',
        },
        {
            rights: ['pmManageUnassignedRows'],
            rule: 'one',
            name: 'hasPMManageUnassignedRowsRights',
        },
        {
            rights: ['manageEvents', 'scheduleMyselfOnAnyProjectOrEvent', 'scheduleMyselfOnProjectsOrEventsIAmPartOf'],
            rule: 'oneOf',
            name: 'hasScheduleRights',
        },
        {
            rights: ['pmManageEvents'],
            rule: 'one',
            name: 'hasPMScheduleRights',
        },
    ]);
    const hasPMUnassignedRights = hasPMManageUnassignedRowsRights && isProjectManager && isPMExtensionInstalled;
    const showBookingTypeSection =
        isUnassignedExtensionInstalled && (hasManageUnassignedRowsRights || hasPMUnassignedRights) && !isEditMode;
    const { projects, resources } = useSchedulerData(null, project, {
        onlyUnassigned: TYPE_UNASSIGNED.value === bookingType,
        onlyEvents: false,
        canRequestResources: false,
        excludeUnassigned: !showBookingTypeSection || TYPE_UNASSIGNED.value !== bookingType,
    });

    const selectProjectJSX = () => {
        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        Select {projectKeyWord} or Event:
                    </Typography>
                    <Layout stack>
                        <Field
                            inline
                            name="project"
                            component={dropdownField}
                            filter="contains"
                            valueField="_id"
                            textField={item => (Object.keys(item).length ? item.name : null)}
                            data={projects}
                            onChange={onProjectChange}
                            wrapperClassName="my-0"
                        />
                        <FormText className="required">
                            Choose a {projectKeyWord} or Event from the field above
                        </FormText>
                    </Layout>
                </Layout>
                <Divider />
            </>
        );
    };

    const bookingTitleJSX = () => {
        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        Booking Title:
                    </Typography>
                    <Layout stack>
                        <Field name="title" icon="fa-font" component={inputGroupTextField} />
                        <FormText>
                            Give your booking a title to help explain the task better. If no title the {projectKeyWord}{' '}
                            name is displayed by default.
                        </FormText>
                    </Layout>
                </Layout>
                <Divider />
            </>
        );
    };

    const bookingTypeJSX = () => {
        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        Booking Type:
                    </Typography>
                    <Layout stack>
                        <Layout gap="--Spacing-100">
                            <Field
                                type="radio"
                                inline
                                label="Scheduled"
                                name="bookingType"
                                value="SCHEDULED"
                                component={chooseField}
                                onChange={() => onFieldChange({ resources: [] })}
                            />
                            <Field
                                type="radio"
                                inline
                                label="Unassigned"
                                name="bookingType"
                                value="UNASSIGNED"
                                component={chooseField}
                                onChange={() => onFieldChange({ resources: [] })}
                            />
                        </Layout>
                        {'SCHEDULED' === bookingType ? (
                            <FormText>Are you requesting a single day or a range of days</FormText>
                        ) : (
                            <FormText>
                                If you do not know the resource but know the dates, use unassigned to create the booking
                                which will be added to the unassigned row
                            </FormText>
                        )}
                    </Layout>
                </Layout>
                <Divider />
            </>
        );
    };

    const getItemTextField = item => {
        if (Object.keys(item).length) {
            if (item.name) {
                return item.name;
            }
            return `${item.firstName} ${item.lastName}`;
        }
        return '';
    };

    const resourcesJSX = () => {
        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        {'SCHEDULED' === bookingType
                            ? `Select ${resourcePluralKeyWord.toLowerCase()}`
                            : 'Add unassigned work'}
                        :
                    </Typography>
                    <Layout stack>
                        <Field
                            wrapperClassName="my-0"
                            inline
                            width="col-md-12"
                            name="resources"
                            component={multiSelectField}
                            caseSensitive={false}
                            messages={{
                                emptyList:
                                    0 < resources.length
                                        ? 'There are no items in this list.'
                                        : 'There are no items in this list. Are you sure you have all required rights and permissions?',
                            }}
                            allowCreate="onFilter"
                            valueField="_id"
                            filter="contains"
                            disabled={
                                (!project?._id && !isOwner({ resourceRole })) ||
                                ('SCHEDULED' === bookingType &&
                                    !(
                                        hasScheduleRights ||
                                        (isProjectManager && isPMExtensionInstalled && hasPMScheduleRights)
                                    ))
                            }
                            textField={getItemTextField}
                            data={resources}
                        />
                        <FormText className="required">
                            {'SCHEDULED' === bookingType
                                ? `Choose which ${resourcePluralKeyWord.toLowerCase()} belong to this booking`
                                : 'Choose an unassigned work category'}
                        </FormText>
                    </Layout>
                </Layout>
                <Divider />
            </>
        );
    };

    const startEndDatesJSX = () => {
        const message =
            booking.scale === CELLDURATION.BEValue
                ? `Info This booking is being edited or has been created in the hours scale and therefore you
cannot adjust the dates via the modal. You must manually resize the booking from the hours
scale to change dates.`
                : formValues?.scale === CELLDURATION.BEValue
                ? HOUR_MODE_SELECTION_INFO
                : undefined;

        if (message) {
            return (
                <>
                    <Layout stack gap="--Spacing-100">
                        <Typography variant="label" size="large" prominent>
                            Booking Start / End Dates:
                        </Typography>
                        <Alert color="warning mt-3">{message}</Alert>
                    </Layout>
                    <Divider />
                </>
            );
        }

        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        Booking Start / End Dates:
                    </Typography>
                    <Layout gap="--Spacing-100">
                        <Layout stack>
                            <Field
                                data-cy={`booking-modal-start-date-[${format(start | new Date(), 'yyyy-MM-dd')}]`}
                                className="w-100"
                                name="start"
                                component={dateField}
                                componentDataCyPrefix="booking-modal-start-date"
                            />
                            <FormText className="required">Start date</FormText>
                        </Layout>
                        <Layout stack>
                            <Field
                                data-cy={`booking-modal-end-date-[${format(end || new Date(), 'yyyy-MM-dd')}]`}
                                className="w-100"
                                name="end"
                                component={dateField}
                                componentDataCyPrefix="booking-modal-end-date"
                                props={{
                                    shouldDisableDate: date => {
                                        const current = new Date(date).setHours(0, 0, 0, 0);
                                        const newStartDate = new Date(start).setHours(0, 0, 0, 0);
                                        return current < newStartDate;
                                    },
                                }}
                            />
                            <FormText className="required">End Date</FormText>
                        </Layout>
                    </Layout>
                </Layout>
                <Divider />
            </>
        );
    };

    const repeatJSX = () => {
        return (
            <>
                <Repeat
                    inputFieldDisabled={'NONE' === interval}
                    title="Booking Repeat:"
                    formText="The number of times the booking should repeat"
                    formName={FORM_NAME}
                />
                <Divider />
            </>
        );
    };

    const allocationJSX = () => {
        const { allDay } = formValues ?? {};

        const message = formValues?.scale === CELLDURATION.BEValue ? HOUR_MODE_SELECTION_INFO : undefined;

        if (message) {
            return (
                <>
                    <Layout stack gap="--Spacing-100">
                        <Typography variant="label" size="large" prominent>
                            Allocation:
                        </Typography>
                        <Alert color="warning mt-3">{message}</Alert>
                    </Layout>
                    <Divider />
                </>
            );
        }

        return (
            <>
                <Allocation
                    title="Allocation:"
                    subtitle="Select how much allocation you want to request"
                    formText={`The booking allocation amount per ${resourceKeyWord}`}
                    stateValue={state}
                    allocationDefaultState={state}
                    onChange={onFieldChange}
                    formName={FORM_NAME}
                    disableAll={!allDay}
                    allDay={allDay}
                    doNotShowAllocationFields={!allDay}
                />
                <Divider />
            </>
        );
    };

    const changeBookingColorSource = useCallback(
        event => {
            const newUseProjectColor = event.target.value === 'true' ? true : false;
            onFieldChange({
                bookingColor: newUseProjectColor ? '' : '#349E71',
                useProjectColor: newUseProjectColor,
            });
        },
        [onFieldChange]
    );

    const customBookingColorJSX = () => {
        return (
            <>
                <Layout stack gap="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        Set Booking Color:
                    </Typography>
                    {isCategoryColorUsed && (
                        <FormText
                            className={classnames({
                                required: !isCategoryColorUsed,
                            })}
                        >
                            <Alert color="warning mt-3">
                                <b>Color Warning!</b> You are currently using category colors to define the bookings
                                color, you must first disable this feature under{' '}
                                <a className="primary" href="/settings#custom">
                                    Settings / Customization
                                </a>{' '}
                                to use the custom booking colors feature instead.
                            </Alert>
                        </FormText>
                    )}
                    {!isCategoryColorUsed && (
                        <>
                            <FormControl component="fieldset" className="ml-1 mt-2">
                                <RadioGroup value={String(useProjectColor)} onChange={changeBookingColorSource}>
                                    <FormControlLabel
                                        value="true"
                                        control={<GreenRadio dataCy="project-color-inherit-status" />}
                                        className="mb-2"
                                        label={`Inherit color from ${projectKeyWord.toLowerCase()}`}
                                    />
                                    <FormControlLabel
                                        value="false"
                                        control={<GreenRadio dataCy="project-custom-color" />}
                                        className="mb-2"
                                        label="Use custom color"
                                    />
                                </RadioGroup>
                            </FormControl>
                            {!useProjectColor && (
                                <FormText>
                                    <Field
                                        width="col-md-12"
                                        name="bookingColor"
                                        component={colorInputField}
                                        onColorChange={color => onFieldChange({ bookingColor: color.hex })}
                                    />
                                    This color will override the inherited {projectKeyWord.toLowerCase()} color used and
                                    set the booking to this color.
                                </FormText>
                            )}
                        </>
                    )}
                </Layout>
                <Divider />
            </>
        );
    };

    const bookingCategoryJSX = () => {
        return (
            <Layout stack gap="--Spacing-100">
                <Typography variant="label" size="large" prominent>
                    Booking Category:
                </Typography>
                <Layout stack>
                    <Field
                        wrapperClassName="my-0"
                        inline
                        name="category"
                        component={dropdownField}
                        valueField="_id"
                        dropUp
                        textField={item => (Object.keys(item).length ? item.name : null)}
                        itemComponent={CategoryTemplateItem}
                        valueComponent={CategoryTemplateItem}
                        data={categoryTemplates}
                        groupBy="groupName"
                        groupComponent={CategoryGroupItem}
                        filter="contains"
                        onChange={onCategoryChange}
                    />
                    <FormText>Choose a booking category from the options above</FormText>
                </Layout>
            </Layout>
        );
    };

    return (
        <TabPane tabId={tabId}>
            <Layout stack gap={24}>
                {selectProjectJSX()}
                {bookingTitleJSX()}
                {showBookingTypeSection && bookingTypeJSX()}
                {!isEditMode && resourcesJSX()}
                {startEndDatesJSX()}
                <DeadlineSection key={booking?._id} onChange={onFieldChange} />
                {repeatJSX()}
                {allocationJSX()}
                {customBookingColorJSX()}
                {bookingCategoryJSX()}
            </Layout>
        </TabPane>
    );
};

const DeadlineSection = ({ onChange }) => {
    const formValues = useSelector(getFormValues(FORM_NAME));
    const dispatch = useDispatch();
    const isExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const { deadlineDate, end } = formValues ?? {};
    const validator = useValidate();

    const validationResponse = useMemo(() => {
        return validator(formValues ?? {});
    }, [formValues, validator]);

    const showDeadlineForm = Boolean(deadlineDate) && isExtensionInstalled;
    const hasDeadlineDateWarning = Boolean(validationResponse.deadlineDate?.[0]);

    const handleDeleteDeadline = useCallback(() => {
        onChange({ deadlineName: null, deadlineDate: null });
    }, [onChange]);

    const handleAddDeadlineBtnClick = useCallback(() => {
        if (isExtensionInstalled) {
            onChange({ deadlineDate: addDays(end || new Date(), 2) });
        } else {
            dispatch(showMissingDeadlinesExtensionModal());
        }
    }, [dispatch, end, isExtensionInstalled, onChange]);

    const classes = useDeadlineStyles();

    return (
        <>
            <Layout stack gap="--Spacing-100">
                <Typography variant="label" size="large" prominent>
                    Booking Deadline:
                </Typography>
                {showDeadlineForm ? (
                    <Row className={classes.row}>
                        <Col md={6}>
                            <Field
                                className="w-100"
                                name="deadlineName"
                                label="Deadline title"
                                component={materialInputGroupField}
                                dataCy="deadline-name"
                            />
                        </Col>
                        <Col md={5}>
                            <Field
                                className="w-100"
                                name="deadlineDate"
                                label="Deadline date"
                                dataCy="deadline-date"
                                componentDataCyPrefix="deadline-date"
                                component={dateField}
                                formHelperText={validationResponse.deadlineDate?.[0]}
                                formHelperTextClassName={hasDeadlineDateWarning ? 'Mui-error' : ''}
                            />
                        </Col>
                        <Col md={1}>
                            <IconButton
                                dataCy="delete-deadline"
                                icon="trash"
                                className={classes.deleteDeadlineBtn}
                                onClick={handleDeleteDeadline}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Layout>
                        <SuccessButton
                            dataCy="add-deadline"
                            name={isExtensionInstalled ? 'Add deadline' : 'See more'}
                            btn-type="button"
                            onClick={handleAddDeadlineBtnClick}
                        />
                    </Layout>
                )}
            </Layout>
            <Divider />
        </>
    );
};

const useDeadlineStyles = makeStyles({
    row: {
        alignItems: 'flex-start',
    },
    deleteDeadlineBtn: {
        marginTop: '4px',
    },
});

ScheduleBasicInfoTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    onCategoryChange: PropTypes.func.isRequired,
    onProjectChange: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    categories: PropTypes.array,
};

ScheduleBasicInfoTab.defaultProps = {
    categories: [],
    onProjectChange: () => {
        // do nothing
    },
};

export default ScheduleBasicInfoTab;
