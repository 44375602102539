import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { faqItemPropTypes, FaqItem } from './FaqItem';

const useStyles = makeStyles({
    Faq_subTitle: {
        color: '#838383',
        marginBottom: '10px',
        marginTop: '-5px',
    },
    Faq_title: {
        lineHeight: 'normal',
    },
});

export const Faq = React.memo(props => {
    const { title, subtitle, elements } = props;
    const classes = useStyles();

    if (!Array.isArray(elements) || elements.length === 0) {
        return null;
    }

    return (
        <div>
            {title && <h4 className={classes.Faq_title}>{title}</h4>}
            {subtitle && <div className={classes.Faq_subTitle}>{subtitle}</div>}
            {elements.map((elementConfig, index) => {
                return <FaqItem key={index} {...elementConfig} />;
            })}
        </div>
    );
});

export const faqPropTypes = PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    elements: PropTypes.arrayOf(faqItemPropTypes).isRequired,
});
Faq.propTypes = faqPropTypes;

export default Faq;
