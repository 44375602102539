import React, { useCallback, useState } from 'react';
import { Chip, Divider, Grid, Switch, Typography, FormControlLabel } from '@material-ui/core';
import DateRangePicker from '../dateRange';
import PropTypes from 'prop-types';
import { OPERATORS } from '../../enums/operatorEnum';
import { SliderFilterV2 } from '../sliderFilter/v2';
import { PredefinedDates } from './predefinedDates';
import { CapacityFinderImg } from './image';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { formatDate } from 'utils/DateUtil';
import { YEAR_MONTH_DAY_FORMAT } from 'global/enums/dateFormat';

const EMPTY_VALUE = { operator: '', value: '' };
const DEFAULT_VALUE = { operator: OPERATORS[0].value, value: 100 };

const utilizationSliderConfig = {
    min: 0,
    max: 200,
    marks: [
        {
            value: 0,
            scaledValue: 0,
            label: '0',
        },
        {
            value: 25,
            scaledValue: 25,
            label: '25',
        },
        {
            value: 50,
            scaledValue: 50,
            label: '50',
        },
        {
            value: 75,
            scaledValue: 75,
            label: '75',
        },
        {
            value: 100,
            scaledValue: 100,
            label: '100',
        },
        {
            value: 125,
            scaledValue: 125,
            label: '125',
        },
        {
            value: 150,
            scaledValue: 150,
            label: '150',
        },
        {
            value: 175,
            scaledValue: 175,
            label: '175',
        },
        {
            value: 200,
            scaledValue: 200,
            label: '200%',
        },
    ],
};

const availabilitySliderConfig = {
    min: 0,
    max: 175,
    marks: [
        {
            value: 0,
            scaledValue: 0,
            label: '0',
        },
        {
            value: 25,
            scaledValue: 25,
            label: '25',
        },
        {
            value: 50,
            scaledValue: 50,
            label: '50',
        },
        {
            value: 75,
            scaledValue: 75,
            label: '75',
        },
        {
            value: 100,
            scaledValue: 100,
            label: '100',
        },
        {
            value: 125,
            scaledValue: 250,
            label: '250',
        },
        {
            value: 150,
            scaledValue: 500,
            label: '500',
        },
        {
            value: 175,
            scaledValue: 1000,
            label: '1000hrs',
        },
    ],
};

const predefinedUtilisationOptions = [
    {
        key: 'full',
        label: 'Full availability',
        values: {
            value: 0,
            operator: OPERATORS[0].value,
        },
    },
    {
        key: 'partial',
        label: 'Partial availability',
        values: {
            value: 50,
            operator: OPERATORS[4].value,
        },
    },
    {
        key: 'overbooked',
        label: 'Overbooked',
        values: {
            value: 100,
            operator: OPERATORS[3].value,
        },
    },
    {
        key: 'fullyBooked',
        label: 'Fully Booked',
        values: {
            value: 100,
            operator: OPERATORS[0].value,
        },
    },
];

const useStyles = makeStyles({
    chipRow: {
        padding: '2px',
    },
    utilButtons: {
        minWidth: 160,
    },
    utilButtonsSupportText: {
        minWidth: 160,
        textAlign: 'center',
        color: '#ADAEBB',
        marginTop: '-12px',
        fontSize: '11px',
    },
});

const SwitchWrapper = withStyles({
    label: {
        fontSize: '12px',
        marginRight: '7px',
        color: '#888',
    },
})(FormControlLabel);

const StyleChip = withStyles({
    root: {
        borderColor: '#ADDBC8',
        color: '#32A675',
    },
})(Chip);

const StyleUtilSwitchChip = withStyles({
    root: {
        color: ({ active }) => (active ? 'white' : '#4E5252'),
        backgroundColor: ({ active }) => (active ? '#4E5252' : 'white'),
        border: '1px solid #E6E7ED',
        '&:focus': {
            backgroundColor: '#4E5252',
            color: 'white',
        },
    },
})(Chip);

const CapacityFinder = props => {
    const {
        variant,
        weekType,
        dates,
        onDatesChange,
        utilization,
        availability,
        onUtilizationChanged,
        onAvailabilityChanged,
        onBothFiltersChange,
    } = props;

    const classes = useStyles();

    const [state, setState] = useState(() => {
        const hasUtilization = Number.isFinite(utilization?.value);
        const isActive = Number.isFinite(availability?.value) || Number.isFinite(utilization?.value);

        return {
            isActive,
            finderCase: hasUtilization ? 'utilization' : 'availability',
        };
    });
    const { isActive, finderCase } = state;
    const isAvailabilityCase = finderCase === 'availability';

    const handleToggleActive = useCallback(() => {
        setState(state => {
            const newIsActive = !state.isActive;
            const newValues = newIsActive ? DEFAULT_VALUE : EMPTY_VALUE;

            if (!newIsActive) {
                onBothFiltersChange({
                    availability: EMPTY_VALUE,
                    utilization: EMPTY_VALUE,
                });
            } else {
                onBothFiltersChange({
                    availability: newValues,
                    utilization: EMPTY_VALUE,
                });
            }

            return {
                ...state,
                isActive: newIsActive,
                finderCase: 'availability',
            };
        });
    }, [onBothFiltersChange]);

    const handlePredefinedRangeClick = selectedRange => {
        const newDatesState = selectedRange.getRange(weekType);
        onDatesChange({
            dateState: newDatesState.dateState,
            end: newDatesState.endDate,
            start: newDatesState.startDate,
            periodType: newDatesState.periodType,
        });
    };

    const handleDateChange = useCallback(
        newDates => {
            onDatesChange({
                dateState: newDates.dateState,
                end: newDates.endDate,
                start: newDates.startDate,
                periodType: newDates.periodType,
            });
        },
        [onDatesChange]
    );

    const handleAvailabilityClick = useCallback(() => {
        onBothFiltersChange({
            availability: DEFAULT_VALUE,
            utilization: EMPTY_VALUE,
        });

        setState(state => ({
            ...state,
            finderCase: 'availability',
        }));
    }, [onBothFiltersChange]);

    const handleUtilisationClick = useCallback(() => {
        onBothFiltersChange({
            availability: EMPTY_VALUE,
            utilization: DEFAULT_VALUE,
        });

        setState(state => ({
            ...state,
            finderCase: 'utilization',
        }));
    }, [onBothFiltersChange]);

    const handlePredefinedAvailabilityClick = option => {
        const { values } = option;
        const method = isAvailabilityCase ? onAvailabilityChanged : onUtilizationChanged;
        method(values);
    };

    const handleSliderChange = useCallback(
        value => {
            const method = isAvailabilityCase ? onAvailabilityChanged : onUtilizationChanged;
            method(value);
        },
        [isAvailabilityCase, onAvailabilityChanged, onUtilizationChanged]
    );

    const sliderState = (isAvailabilityCase ? availability : utilization) ?? { operator: '', value: '' };

    const disabled = !isActive;
    const marks = isAvailabilityCase ? availabilitySliderConfig.marks : utilizationSliderConfig.marks;
    const sliderMin = isAvailabilityCase ? availabilitySliderConfig.min : utilizationSliderConfig.min;
    const sliderMax = isAvailabilityCase ? availabilitySliderConfig.max : utilizationSliderConfig.max;
    const sliderTitle = isAvailabilityCase ? 'Availability' : 'Utilization';
    const datesAlignment = variant !== 'builder' ? 'center' : 'flex-start';
    const getOpacityLevel = { opacity: disabled ? 0.6 : 1 };

    const validateDate = (date, type) => {
        if (!date) return `${type} date required`;
        if (type === 'Start') {
            if (
                date &&
                dates?.end &&
                formatDate(date, YEAR_MONTH_DAY_FORMAT) > formatDate(dates.end, YEAR_MONTH_DAY_FORMAT)
            ) {
                return 'Start date cannot be after end date';
            }
        } else {
            if (
                date &&
                dates?.start &&
                formatDate(date, YEAR_MONTH_DAY_FORMAT) < formatDate(dates.start, YEAR_MONTH_DAY_FORMAT)
            ) {
                return 'End date cannot be before start date';
            }
        }
        return false;
    };

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            className="p-3"
            style={{ maxWidth: variant !== 'builder' ? 'auto' : '600px' }}
        >
            {variant !== 'builder' && (
                <Grid item xs={12} container justifyContent="space-between">
                    <Typography variant="h6" gutterBottom>
                        Capacity finder
                    </Typography>
                    <SwitchWrapper
                        control={
                            <Switch
                                checked={isActive}
                                color="primary"
                                onChange={handleToggleActive}
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                data-cy="btn-switch"
                            />
                        }
                        label={isActive ? '' : 'Filter is off'}
                        labelPlacement="start"
                    />
                    <Grid item xs={12}>
                        <Divider light />
                    </Grid>
                </Grid>
            )}

            {variant === 'builder' && (
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    alignContent="flex-end"
                    style={{ marginBottom: '-50px' }}
                >
                    <SwitchWrapper
                        control={
                            <Switch
                                checked={isActive}
                                size="small"
                                color="primary"
                                onChange={handleToggleActive}
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                data-cy="btn-switch"
                            />
                        }
                        label={isActive ? '' : 'Filter is off'}
                        labelPlacement="start"
                    />
                </Grid>
            )}

            <Grid item xs={12} container justifyContent={datesAlignment}>
                <PredefinedDates onSelect={handlePredefinedRangeClick} disabled={disabled} />
            </Grid>
            <Grid item xs={12} className="mt-2" style={getOpacityLevel}>
                <DateRangePicker
                    disabled={disabled}
                    single={false}
                    initialStartDate={dates.start}
                    initialEndDate={dates.end}
                    initialDateState={dates.dateState}
                    initialPeriodType={dates.periodType}
                    withButtonsV2
                    displayFormText={false}
                    onChange={handleDateChange}
                    weekType={weekType}
                    maxDropdownHeight="250px"
                    dataCy="capacity-finder"
                    startDateError={validateDate(dates.start, 'Start')}
                    endDateError={validateDate(dates.end, 'End')}
                />
            </Grid>
            <Grid item xs={12} container spacing={1} justifyContent="center">
                <Grid item>
                    <StyleUtilSwitchChip
                        disabled={disabled}
                        label="Availability"
                        active={isAvailabilityCase}
                        variant={isAvailabilityCase ? 'default' : 'outlined'}
                        onClick={handleAvailabilityClick}
                        className={classes.utilButtons}
                        data-cy="capacity-filter-availability"
                    />
                </Grid>
                <Grid item>
                    <StyleUtilSwitchChip
                        disabled={disabled}
                        label="Utilization"
                        active={!isAvailabilityCase}
                        variant={isAvailabilityCase ? 'outlined' : 'default'}
                        onClick={handleUtilisationClick}
                        className={classes.utilButtons}
                        data-cy="capacity-filter-utilization"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} justifyContent="center">
                <Grid item>
                    <div className={classes.utilButtonsSupportText}>hrs</div>
                </Grid>
                <Grid item>
                    <div className={classes.utilButtonsSupportText}>%</div>
                </Grid>
            </Grid>
            <Grid item xs={12} className="my-2" style={getOpacityLevel}>
                <CapacityFinderImg
                    isAvailabilityCase={isAvailabilityCase}
                    value={sliderState.value}
                    operator={sliderState.operator}
                    variant={variant}
                />
            </Grid>
            {!isAvailabilityCase ? (
                <Grid item xs={12} container justifyContent="center" style={getOpacityLevel}>
                    {predefinedUtilisationOptions.map(option => {
                        return (
                            <Grid item key={option.key} className={classes.chipRow}>
                                <StyleChip
                                    disabled={disabled}
                                    variant="outlined"
                                    size="small"
                                    label={option.label}
                                    onClick={() => handlePredefinedAvailabilityClick(option)}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            ) : null}
            <Grid item xs={12} style={getOpacityLevel}>
                <SliderFilterV2
                    key={isAvailabilityCase ? 'availability' : 'utilization'}
                    min={sliderMin}
                    max={sliderMax}
                    defaultValues={sliderState}
                    onChange={handleSliderChange}
                    marks={marks}
                    title={sliderTitle}
                    isAvailabilityCase={isAvailabilityCase}
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

CapacityFinder.propTypes = {
    variant: PropTypes.oneOf(['scheduler', 'builder']),
    weekType: PropTypes.string,
    initialDatesState: PropTypes.object,
    onDatesChange: PropTypes.func.isRequired,
    onBothFiltersChange: PropTypes.func.isRequired,
    utilization: PropTypes.object,
    onUtilizationChanged: PropTypes.func.isRequired,
    availability: PropTypes.object,
    onAvailabilityChanged: PropTypes.func.isRequired,
};

const memoized = React.memo(CapacityFinder);

export { memoized as CapacityFinder };
