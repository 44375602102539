import { chain } from 'underscore';
import { filter, keys, find, includes, sortBy, omit } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import { getUpdatedCollection, updateItemInArray, updateObject } from './helpers/updater';
import createReducer from './helpers/createReducer';
import { optimisticUpdates } from './helpers/resourceReducerUtils';
import { toilActions } from '../modules/toil/store/actions';
import { arrayToObjectByKey } from '../utils/mappingUtil';

const initialResourceState = {
    approvees: [],
    projectManagers: [],
    loadingProjectManagers: false,
    resources: [],
    resource: {},
    isCreating: false,
    loggedInUser: {
        currentCompanySpecific: {
            customFields: [],
            landingPage: '',
        },
    },
    generatedCalendarKey: '',
    loading: false,
    lastCallContext: undefined,
};

const setApprovees = (state, action) =>
    updateObject(state, {
        approvees: action.payload.approvees,
    });

const setResources = (state, action) =>
    updateObject(state, {
        resources: sortBy(action.payload.resources, resource => resource.firstName.toLowerCase()),
        lastCallContext: action.payload.lastCallContext,
        loading: false,
    });

const setResourcesRequest = state =>
    updateObject(state, {
        loading: true,
    });

const handleGetResourcesStop = state =>
    updateObject(state, {
        loading: false,
    });

const setResource = (state, action) =>
    updateObject(state, {
        resource: action.payload.resource,
    });

const resetResourceValue = (state, action) =>
    updateObject(state, {
        [action.payload.propertyName]: initialResourceState[action.payload.propertyName],
    });

const addResource = (state, action) => {
    if (!state.resources.length) {
        return state;
    }
    const resources = [...state.resources];

    resources.push({
        calendarIds: action.payload.resource.calendarIds,
        customAvailabilities: action.payload.resource.currentCompanySpecific.customAvailabilities,
        defaultApproverIds: action.payload.resource.defaultApproverIds,
        firstName: action.payload.resource.firstName,
        isProjectManager: action.payload.resource.isProjectManager,
        lastName: action.payload.resource.lastName,
        name: action.payload.resource.name,
        resourceId: action.payload.resource.resourceId,
        role: action.payload.resource.currentCompanySpecific.role,
        status: action.payload.resource.status,
        type: action.payload.resource.type,
        useResourceAvailability: action.payload.resource.currentCompanySpecific.useResourceAvailability,
        _id: action.payload.resource._id,
        hasRightsToResource: action.payload.resource.hasRightsToResource,
    });

    return updateObject(state, {
        resources: sortBy(resources, resource => resource.firstName.toLowerCase()),
        isCreating: false,
    });
};

const setCreateLoader = state =>
    updateObject(state, {
        isCreating: true,
    });

const addResources = (state, action) => {
    const newResources = getUpdatedCollection({
        initial: state.resources,
        toAddUpdate: action.payload.created.concat(action.payload.updated),
        sortByProp: 'firstName',
    });

    return updateObject(state, {
        resources: newResources,
    });
};

const deleteResource = (state, action) => {
    let resources = [...state.resources];
    if (resources.length && action.payload.id) {
        resources = filter(resources, resource => resource._id !== action.payload.id);

        return updateObject(state, {
            resources,
        });
    }

    return state;
};

const updateResource = (state, action) => {
    const companySpecific = omit(action.payload.resource.currentCompanySpecific, '_id');
    const resource = omit(
        {
            ...companySpecific,
            ...action.payload.resource,
            resourceId: action.payload.resource._id,
            type: action.payload.resource.type,
        },
        'currentCompanySpecific'
    );

    if (state.resources.length) {
        const currentResource = find(state.resources, resource => resource.resourceId === action.payload.resource._id);
        const allowedKeys = keys(currentResource);
        const filtered = chain(resource)
            .keys()
            .filter(key => includes(allowedKeys, key))
            .reduce((obj, key) => {
                obj[key] = resource[key];
                return obj;
            }, {})
            .value();

        return updateObject(state, {
            resources: sortBy(
                updateItemInArray(state.resources, action.payload.resource._id, resource =>
                    updateObject(resource, filtered)
                ),
                resource => resource.firstName.toLowerCase()
            ),
            resource: {},
        });
    }

    return updateObject(state, {
        resource: {},
    });
};

const setLoggedInUser = (state, action) =>
    updateObject(state, {
        loggedInUser: action.payload.resource,
    });

const setCalendarKey = (state, action) =>
    updateObject(state, {
        generatedCalendarKey: action.payload.calendarKey,
    });

const updateLoggedInUser = (state, action) =>
    updateObject(state, {
        loggedInUser: action.payload.data,
    });

const getProjectManagers = state => {
    return updateObject(state, {
        loadingProjectManagers: true,
    });
};

const setProjectManagers = (state, action) => {
    return updateObject(state, {
        loadingProjectManagers: false,
        projectManagers: action.payload.projectManagers,
    });
};

const handleProjectManagersFailure = state => {
    return updateObject(state, {
        loadingProjectManagers: false,
    });
};

const handleAddApprovees = (state, action) => {
    const currentResource = find(state.resources, resource => resource.resourceId === action.payload.resourceId);

    if (!currentResource) {
        return state;
    }

    const newApprovees = new Set(currentResource.approveeIds);

    action.payload.approveeIds.forEach(id => {
        newApprovees.add(id);
    });

    return updateObject(state, {
        resources: updateItemInArray(state.resources, action.payload.resourceId, resource => {
            resource.approveeIds = Array.from(newApprovees);
            return resource;
        }),
    });
};

const handleDeleteApprovees = (state, action) => {
    const { resourceId, approveeIds } = action.payload;
    const currentResource = find(state.resources, resource => resource.resourceId === resourceId);

    if (!currentResource) {
        return state;
    }

    const newApprovees = new Set(currentResource.approveeIds);

    approveeIds.forEach(id => {
        newApprovees.delete(id);
    });

    return updateObject(state, {
        resources: updateItemInArray(state.resources, resourceId, resource => {
            resource.approveeIds = Array.from(newApprovees);
            return resource;
        }),
    });
};

const handlePoliciesUpdate = (state, action) => {
    const mappedPayload = arrayToObjectByKey(action.payload.data, 'resourceId');

    return updateObject(state, {
        resources: (state.resources ?? []).map(resource => {
            const { _id } = resource;
            if (!mappedPayload[_id]) {
                return resource;
            }

            return {
                ...resource,
                toilPolicyId: mappedPayload[_id].toilPolicyId,
            };
        }),
    });
};

const handleDeletePolicySuccess = (state, action) => {

    return updateObject(state, {
        resources: (state.resources ?? []).map(resource => {
            if (resource.toilPolicyId !== action.payload.policy._id) {
                return resource;
            }

            return {
                ...resource,
                toilPolicyId: null,
            };
        }),
    });
};

const optimisticUpdateResources = (state, action) => {
    return optimisticUpdates.reduce(
        (acc, { shouldUpdate, doUpdate }) => {
            if (!shouldUpdate(action)) {
                return acc;
            }

            return doUpdate(acc, action);
        },
        {
            ...state,
        }
    );
};

export default createReducer(initialResourceState, {
    [actionTypes.GET_APPROVEES['SUCCESS']]: setApprovees,
    [actionTypes.GET_RESOURCES['SUCCESS']]: setResources,
    [actionTypes.GET_RESOURCES['REQUEST']]: setResourcesRequest,
    [actionTypes.ADD_APPROVEES['REQUEST']]: handleAddApprovees,
    [actionTypes.ADD_APPROVEES['FAILURE']]: handleDeleteApprovees,
    [actionTypes.DELETE_APPROVEES['REQUEST']]: handleDeleteApprovees,
    [actionTypes.DELETE_APPROVEES['FAILURE']]: handleAddApprovees,
    [actionTypes.GET_RESOURCES_STOP]: handleGetResourcesStop,
    [actionTypes.CREATE_RESOURCE['REQUEST']]: setCreateLoader,
    [actionTypes.CREATE_RESOURCE['SUCCESS']]: addResource,
    [actionTypes.CREATE_MULTIPLE_RESOURCES['SUCCESS']]: addResources,
    [actionTypes.GET_RESOURCE['SUCCESS']]: setResource,
    [actionTypes.DELETE_RESOURCE['SUCCESS']]: deleteResource,
    [actionTypes.UPDATE_RESOURCE['REQUEST']]: optimisticUpdateResources,
    [actionTypes.UPDATE_RESOURCE['SUCCESS']]: updateResource,
    [actionTypes.RESET_RESOURCE_VALUE['REQUEST']]: resetResourceValue,
    [actionTypes.GET_LOGGED_IN_USER['SUCCESS']]: setLoggedInUser,
    [actionTypes.GENERATE_CALENDAR_KEY['SUCCESS']]: setCalendarKey,
    [actionTypes.UPDATE_LOGGED_IN_RESOURCE['SUCCESS']]: updateLoggedInUser,
    [actionTypes.GET_PROJECT_MANAGERS['REQUEST']]: getProjectManagers,
    [actionTypes.GET_PROJECT_MANAGERS['SUCCESS']]: setProjectManagers,
    [actionTypes.GET_PROJECT_MANAGERS['FAILURE']]: handleProjectManagersFailure,
    [toilActions.UPDATE_RESOURCE_POLICIES['SUCCESS']]: handlePoliciesUpdate,
    [toilActions.DELETE_POLICY['SUCCESS']]: handleDeletePolicySuccess,
});
