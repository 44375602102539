import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const configs = {
    info: {
        backgroundColor: '#f2f4fb',
        textColor: '#383B48',
        icon: <InfoOutlinedIcon />,
    },
};

const useStyles = makeStyles({
    root: ({ backgroundColor, textColor }) => {
        return {
            color: textColor,
            backgroundColor,
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '80%',
        };
    },
});

export const InfoBar = React.memo(props => {
    const { type, children } = props;

    const { backgroundColor, textColor, icon } = configs[type] || {};

    const classes = useStyles({ backgroundColor, textColor });

    return (
        <div className={classes.root}>
            {icon && <span className="mr-2">{icon}</span>}
            {children}
        </div>
    );
});

InfoBar.propTypes = {
    type: PropTypes.string,
};

// TODO: Stories

export default InfoBar;
