import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormText } from 'reactstrap';
import { Field } from 'formik';
import { Refresh } from '@material-ui/icons';
import {
    chooseField as ChooseField,
    materialInputGroupField as MaterialInputGroupField,
    dropdownFieldMaterial as DropdownFieldMaterial,
} from 'shared/formFields';

export const intervalOptions = [
    {
        name: 'None',
        value: 'NONE',
    },
    {
        name: 'Daily',
        value: 'DAILY',
    },
    {
        name: 'Weekly',
        value: 'WEEKLY',
    },
    {
        name: 'Monthly',
        value: 'MONTHLY',
    },
];

const normalize = value => parseInt(value);

const Repeat = props => {
    const {
        title,
        formText,
        radioInputName,
        intervalAsDropdown,
        textInputName,
        wrapperClass,
        inputFieldDisabled,
        removeBorder,
        inline,
        errors,
    } = props;

    const numberOfRepeatsInputRef = useRef(null);

    return (
        <section
            className={classnames(wrapperClass, {
                'border-bottom-0': removeBorder,
                'form-fields': inline,
                'd-flex flex-column w-100 mt-1': !inline,
            })}
        >
            {title && <p className="title">{title}</p>}
            {!intervalAsDropdown ? (
                intervalOptions.map(interval => (
                    <Field key={interval.value} name={radioInputName}>
                        {({ field }) => (
                            <ChooseField
                                type="radio"
                                inline={inline}
                                label={interval.name}
                                name={radioInputName}
                                value={interval.value}
                                checked={interval.value === field.value}
                                wrapperClass={classnames({ 'mb-2': !inline })}
                                input={field}
                            />
                        )}
                    </Field>
                ))
            ) : (
                <Field name={radioInputName}>
                    {({ field }) => (
                        <DropdownFieldMaterial
                            label="Interval"
                            data={intervalOptions}
                            filter={false}
                            dropUp
                            valueField="value"
                            textField="name"
                            name={field.name}
                            input={field}
                            value={field.value}
                        />
                    )}
                </Field>
            )}

            <Field name={textInputName}>
                {({ field }) => {
                    let metaRepeat;
                    if (errors?.[field.name]) {
                        metaRepeat = {
                            touched: true,
                            error: errors[field.name],
                        };
                    }
                    return (
                        <MaterialInputGroupField
                            className="my-2"
                            disabled={inputFieldDisabled}
                            icon={Refresh}
                            type="number"
                            normalize={normalize}
                            name={field.name}
                            input={field}
                            value={field.value}
                            ref={numberOfRepeatsInputRef}
                            meta={metaRepeat}
                        />
                    );
                }}
            </Field>
            {formText.length ? <FormText>{formText}</FormText> : null}
        </section>
    );
};

Repeat.propTypes = {
    title: PropTypes.string,
    formText: PropTypes.string,
    radioInputName: PropTypes.string,
    textInputName: PropTypes.string,
    wrapperClass: PropTypes.string,
    inputFieldDisabled: PropTypes.bool,
    inline: PropTypes.bool,
    removeBorder: PropTypes.bool,
    intervalAsDropdown: PropTypes.bool,
};

Repeat.defaultProps = {
    radioInputName: 'interval',
    textInputName: 'repeatTimes',
    intervalAsDropdown: false,
    title: '',
    inline: true,
    formText: '',
    wrapperClass: '',
    inputFieldDisabled: false,
    removeBorder: false,
};

export default Repeat;
