/* eslint-env browser */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { createHashHistory } from 'history';

// Can set it during runtime without rebuilding
window.disableReduxLogger = false;

const composeEnhancer =
    'development' === process.env.NODE_ENV && window.hasOwnProperty('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
              trace: true,
              traceLimit: 25,
          })
        : compose;

// During https://hubplanner.atlassian.net/browse/HUB-8123, an invalid actions was dispatched and the flow interrupted. This aims to check the validity of actions.
const checkActionMiddleware = () => next => action => !!action && typeof action === 'object' && next(action);

const loggerMiddleware = store => next => action => {
    if (
        process.env.NODE_ENV === 'development' &&
        process.env.REACT_APP_ENABLE_LOGGER_MIDDLEWARE === 'true' &&
        !window.disableReduxLogger
    ) {
        console.group(`%c - action -> ${action.type}`, 'background: #222; color: #bada55');
        console.log(action);
        console.log('state', store.getState());
        console.groupEnd();
    }
    next(action);
};

const configureStores = history => {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = routerMiddleware(history);

    return {
        ...createStore(
            rootReducer(history),
            composeEnhancer(applyMiddleware(checkActionMiddleware, loggerMiddleware, sagaMiddleware, middleware))
        ),
        runSaga: sagaMiddleware.run(rootSaga),
    };
};

export const browserHistory = createHashHistory();
export const store = configureStores(browserHistory);
