import React from 'react';
import PropTypes from 'prop-types';
import { capitalizeFirstLetter } from 'utils/formatingUtil';
import { COLUMN_UNAVAILABLE } from 'constants';
import { Initials, Image } from './styles';

const getResourceThumbLetters = props => {
    let name;
    if (props.fullName) {
        name = props.fullName.split(' ');

        return capitalizeFirstLetter(name[0] || '?').charAt(0) + capitalizeFirstLetter(name[1] || '').charAt(0);
    }

    return capitalizeFirstLetter(props.firstName).charAt(0) + capitalizeFirstLetter(props.lastName).charAt(0);
};

const Thumbnail = props =>
    props.thumbnailUrl &&
    -1 === props.thumbnailUrl.indexOf('placeholder') &&
    props.thumbnailUrl !== COLUMN_UNAVAILABLE.name ? (
        <Image size={props.size} src={`https://d329beqc2zk6g7.cloudfront.net${props.thumbnailUrl}`} alt="" />
    ) : (
        <Initials size={props.size}>{getResourceThumbLetters(props)}</Initials>
    );

Thumbnail.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    fullName: PropTypes.string,
    size: PropTypes.number,
    thumbnailUrl: PropTypes.string,
};

Thumbnail.defaultProps = {
    size: 35,
    firstName: '?',
    lastName: '',
    fullName: '',
    thumbnailUrl: '',
};

export default Thumbnail;
