import React from 'react';
import PropTypes from 'prop-types';
import NestedMenuItem, {NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} from 'shared/nestedMenuItem';
import MilestoneForm from '../milestoneForm';
import Scrollbar from 'shared/scrollbar';
import { WrappedMenuItem } from '../../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { Layout } from 'shared/components/layout/index';

const LegacyProjectGroupMode = props => {
    const { updateMilestone, deleteMilestone, project, currentSelectedBooking } = props;
    return (
        <Layout stack padding="0.5rem 0">
            <NestedMenuItem label="Edit milestone" parentMenuOpen={true} width={300}>
                <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                    <MilestoneForm
                        project={project}
                        currentSelection={currentSelectedBooking}
                        onClick={updateMilestone}
                    />
                </Scrollbar>
            </NestedMenuItem>
            <WrappedMenuItem onClick={deleteMilestone}>Delete milestone</WrappedMenuItem>
        </Layout>
    );
};

LegacyProjectGroupMode.propTypes = {
    updateMilestone: PropTypes.func,
};

LegacyProjectGroupMode.defaultProps = {
    updateMilestone: null,
};

export default LegacyProjectGroupMode;
