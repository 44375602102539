import React from 'react';
import { SectionHeader, Section, GreenRadio } from './styles';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import {
    bookingLinkPositionEnum,
    bookingLinkStylesEnum,
    defaultLineColor,
    defaultLinkPosition,
    defaultLinkStyle,
    bookingLinksDisplayEnum,
    defaultLinkDisplay,
} from '../../../../../enums/bookingLinkEnums';
import ColorPicker from '../../../../../../../shared/colorPicker';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { selectIsDependencyExtensionInstalled } from '../../../../../../../selectors/company';

export const LinkedBookingsConfig = React.memo(props => {
    const {
        onChange,
        style = defaultLinkStyle,
        position = defaultLinkPosition,
        color = defaultLineColor,
        display = defaultLinkDisplay,
    } = props;

    const classes = useStyles();

    const isExtensionInstalled = useSelector(selectIsDependencyExtensionInstalled);

    if (!isExtensionInstalled) {
        return null;
    }

    return (
        <Section>
            <SectionHeader>Dependency style</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="booking-link-style"
                    name="bookingLinkStyle"
                    value={style}
                    onChange={event => onChange({ bookingLinkStyle: event.target.value })}
                >
                    <FormControlLabel
                        value={bookingLinkStylesEnum.line}
                        control={<GreenRadio dataCy="my-pref-booking-link-style-line" />}
                        className="mb-2"
                        label="Line"
                    />
                    <FormControlLabel
                        value={bookingLinkStylesEnum.dashed}
                        control={<GreenRadio dataCy="my-pref-booking-link-style-dashed" />}
                        className="mb-2"
                        label="Dashed"
                    />
                    <FormControlLabel
                        value={bookingLinkStylesEnum.dotted}
                        control={<GreenRadio dataCy="my-pref-booking-link-style-dotted" />}
                        className="mb-2"
                        label="Dotted"
                    />
                </RadioGroup>
            </FormControl>
            <SectionHeader className={classes.sectionHeader}>Dependency color</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2 mb-2">
                <ColorPicker
                    className={classes.colorPicker}
                    color={color}
                    onColorChange={color => onChange({ bookingLinkColor: color.hex })}
                />
            </FormControl>
            <SectionHeader className={classes.sectionHeader}>Dependency order</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="booking-link-position"
                    name="bookingLinkPosition"
                    value={position}
                    onChange={event => onChange({ bookingLinkPosition: event.target.value })}
                >
                    <FormControlLabel
                        value={bookingLinkPositionEnum.aboveBookings}
                        control={<GreenRadio dataCy="my-pref-booking-link-position-above" />}
                        className="mb-2"
                        label="Bring to front"
                    />
                    <FormControlLabel
                        value={bookingLinkPositionEnum.belowBookings}
                        control={<GreenRadio dataCy="my-pref-booking-link-position-below" />}
                        className="mb-2"
                        label="Send to back"
                    />
                </RadioGroup>
            </FormControl>
            <SectionHeader className={classes.sectionHeader}>Dependency Visibility</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="booking-link-display"
                    name="bookingLinkDisplay"
                    value={display}
                    onChange={event => onChange({ bookingLinkDisplay: event.target.value })}
                >
                    <FormControlLabel
                        value={bookingLinksDisplayEnum.show}
                        control={<GreenRadio dataCy="my-pref-booking-link-display-show" />}
                        className="mb-2"
                        label="Show"
                    />
                    <FormControlLabel
                        value={bookingLinksDisplayEnum.hide}
                        control={<GreenRadio dataCy="my-pref-booking-link-display-hide" />}
                        className="mb-2"
                        label="Hide"
                    />
                    <FormControlLabel
                        value={bookingLinksDisplayEnum.showForSelectedBooking}
                        control={<GreenRadio dataCy="my-pref-booking-link-display-selected-booking" />}
                        className="mb-2"
                        label="Show only when a booking is clicked"
                    />
                </RadioGroup>
            </FormControl>
        </Section>
    );
});

const useStyles = makeStyles(() => ({
    colorPicker: {
        display: 'flex',
        position: 'relative',
        left: '-5px',
    },
    sectionHeader: {
        marginTop: '8px',
    },
    colorPickerWrapper: {
        marginBottom: '8px',
    },
}));
