import React, { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { debounce, isFunction, isObject, isUndefined } from 'lodash';
import Container from 'modules/sidebar/menu/components/Container';
import MenuContext from './../menuContext';
import { FilterWrapper, FilterInput, FilterIcon } from './styles';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { selectText } from '../../../../selectors/menuContent';
import { useDispatch, useSelector } from 'react-redux';
import { filterContent, setText } from '../../../../actions/menu/content';

const useTooltipStyles = makeStyles({
    tooltip: {
        fontSize: 12,
        width: '50%',
    },
});

const SEARCH_DEBOUNCE_TIME = 300;

const SearchItem = React.memo(props => {
    const { parentId } = props;

    const dispatch = useDispatch();
    const displayedText = useSelector(selectText);

    const filterCallback = useCallback(
        (text, parentId) => {
            dispatch(filterContent(text, parentId));
        },
        [dispatch]
    );

    const debouncedFilter = useMemo(() => debounce(filterCallback, SEARCH_DEBOUNCE_TIME), [filterCallback]);

    const handleTextChanged = useCallback(
        event => {
            const text = event?.currentTarget?.value?.toLowerCase();
            dispatch(setText(text, parentId));
            debouncedFilter(text, parentId);
        },
        [debouncedFilter, dispatch, parentId]
    );

    const handleClear = useCallback(() => {
        dispatch(setText('', parentId));
        dispatch(filterContent('', parentId));
    }, [dispatch, parentId]);

    return (
        <FilterWrapper>
            <FilterInput
                type="text"
                name={`filter-${parentId}`}
                value={displayedText}
                onChange={handleTextChanged}
                className="form-control"
                placeholder="Type to Filter"
            />
            <FilterIcon onClick={handleClear} />
        </FilterWrapper>
    );
});

const Item = props => {
    const {
        id,
        parentId,
        icon: Icon,
        endIcon: EndIcon,
        disabled,
        tooltipText,
        label,
        to,
        externalLink,
        itemClass,
        currentClassItem,
        customIconClass,
        customIconContent,
        customIcon,
        clearItems,
        emptyText,
        emptyComponent,
        active,
        filter,
        style,
        hasActiveChild,
        hasSubMenu,
        subMenuVisibility,
        toggleSubMenu,
        activateMe,
        toggleFilterVisibility,
        omitPlaceholder,
        customItem: CustomComponent,
        inputProps,
        openInNewTab,
        lazyLoading,
        filterVisible,
        notClearSchedulerSelection,
        ...restOfProps
    } = props;

    const tooltipClasses = useTooltipStyles();

    const { classStore, LinkComponent } = useContext(MenuContext);
    const Component = CustomComponent ? CustomComponent : LinkComponent;

    const notFilterJSX = () => {
        const withTooltip = innerJSX => {
            return (
                <Tooltip classes={tooltipClasses} title={tooltipText} placement="right" arrow>
                    <div>{innerJSX}</div>
                </Tooltip>
            );
        };

        const innerJSX = (
            <Component
                {...props}
                icon={Icon}
                classStore={classStore}
                className={classNames(
                    itemClass,
                    classStore.classLink,
                    disabled && 'menu-link-gray',
                    !!EndIcon && 'with-end-icon'
                )}
                classNameActive={classStore.classLinkActive}
                classNameHasActiveChild={classStore.classLinkHasActiveChild}
                active={active}
                hasActiveChild={hasActiveChild}
                toggleFilterVisibility={() => toggleFilterVisibility(parentId)}
                id={id}
                to={to}
                customIconClass={customIconClass}
                customIconContent={customIconContent}
                customIcon={customIcon}
                label={label}
                externalLink={externalLink}
                hasSubMenu={hasSubMenu}
                subMenuVisibility={subMenuVisibility}
                toggleSubMenu={toggleSubMenu}
                activateMe={activateMe}
                openInNewTab={openInNewTab}
                {...inputProps}
                {...restOfProps}
            >
                {customIcon ? (
                    <span className={classNames(customIconClass, disabled && 'menu-link-gray')}>
                        {customIconContent}
                    </span>
                ) : isFunction(Icon) || isObject(Icon) ? (
                    <Icon className={classNames(classStore.classIcon, disabled && 'menu-link-gray')} />
                ) : Icon ? (
                    <i
                        className={classNames(
                            classStore.classIcon,
                            classStore.iconNamePrefix + Icon,
                            disabled && 'menu-link-gray'
                        )}
                    />
                ) : (
                    <i className={classNames('icon-mask', disabled && 'menu-link-gray')} />
                )}
                <span>
                    &nbsp;
                    {label.split('\n').map((item, key, array) => (
                        <React.Fragment key={key}>
                            {item} {key < array.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </span>
                {EndIcon && <EndIcon />}
                {hasSubMenu && (
                    <i
                        className={classNames(
                            classStore.classStateIcon,
                            classStore.iconNamePrefix +
                                (subMenuVisibility ? classStore.iconNameStateVisible : classStore.iconNameStateHidden)
                        )}
                    />
                )}
            </Component>
        );

        const onClick = notClearSchedulerSelection
            ? () => {
                  window.notClearSchedulerSelection = true;
              }
            : undefined;

        if (tooltipText) {
            return withTooltip(<span onClick={onClick}>{innerJSX}</span>);
        }

        return <span onClick={onClick}>{innerJSX}</span>;
    };

    return (
        ((filter && filterVisible) || (filter && isUndefined(filterVisible)) || !filter) && (
            <li
                style={style}
                className={classNames(
                    classStore.classItem,
                    currentClassItem,
                    active && classStore.classItemActive,
                    hasActiveChild && classStore.classItemHasActiveChild,
                    hasSubMenu && subMenuVisibility && classStore.classItemHasVisibleChild,
                    { 'd-flex align-items-center': filter }
                )}
            >
                {filter && (filterVisible || isUndefined(filterVisible)) ? (
                    <SearchItem parentId={parentId} />
                ) : (
                    notFilterJSX()
                )}
                {hasSubMenu && (
                    <AnimateHeight duration={300} height={subMenuVisibility ? 'auto' : 0}>
                        <Container
                            itemId={id}
                            virtualize={hasSubMenu}
                            lazyLoading={lazyLoading}
                            omitPlaceholder={omitPlaceholder}
                            clearItems={clearItems}
                            emptyText={emptyText}
                            emptyComponent={emptyComponent}
                            visible={subMenuVisibility}
                        />
                    </AnimateHeight>
                )}
            </li>
        )
    );
};

Item.defaultProps = {
    icon: '',
    label: '',
    emptyText: '',
    currentClassItem: '',
    to: null,
    emptyComponent: null,
    externalLink: false,
    customItem: null,
    inputProps: {},
    style: {},
    toggleSubMenu: null,
    customIcon: false,
    customIconContent: '',
    customIconClass: '',
    itemClass: '',
    openInNewTab: true,
};

Item.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    parentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    emptyText: PropTypes.string,
    emptyComponent: PropTypes.element,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]),
    label: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
    to: PropTypes.string,
    customIcon: PropTypes.bool,
    customIconContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemClass: PropTypes.string,
    currentClassItem: PropTypes.string,
    customIconClass: PropTypes.string,
    externalLink: PropTypes.bool,
    hasSubMenu: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    hasActiveChild: PropTypes.bool.isRequired,
    subMenuVisibility: PropTypes.bool.isRequired,
    toggleSubMenu: PropTypes.func,
    activateMe: PropTypes.func.isRequired,
    style: PropTypes.object,
    customItem: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    inputProps: PropTypes.object,
    openInNewTab: PropTypes.bool,
};

export default React.memo(Item);
