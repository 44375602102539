import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { updateAccountReportSettings } from 'actions/accountActions';
import { showReportBugModal, showContactModal } from 'actions/modalActions';
import { sendBugRequest, sendContactRequest } from 'actions/generalActions';
import Scrollbar from 'shared/scrollbar';
import { HelpWrapper, CloseIcon } from './styles';

const Help = ({ hasReportAccess, onClose, isOpen, companyStatus, windowHeight, windowWidth }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const onReportTourStart = () => {
        onClose();
        dispatch(
            updateAccountReportSettings.request({
                tour: { viewed: false, date: null },
            })
        );
    };

    const reportBugModal = () => {
        onClose();
        dispatch(showReportBugModal(data => dispatch(sendBugRequest(data))));
    };

    const contactModal = () => {
        onClose();
        dispatch(showContactModal(data => dispatch(sendContactRequest(data))));
    };

    return (
        <HelpWrapper className={classNames({ 'fade-in': isOpen })}>
            <Scrollbar height={windowHeight - (991 >= windowWidth ? 42 : 51)}>
                <div className="p-4">
                    <CloseIcon onClick={onClose} />
                    <h2>Help & Support</h2>
                    <Nav vertical>
                        <NavItem>
                            <NavLink href="https://hubplanner.com/knowledge-base/">
                                Have Questions?
                                <div className="text-muted">Find answers here</div>
                            </NavLink>
                        </NavItem>
                        {'STATUS_TRIAL' === companyStatus && (
                            <NavItem>
                                <NavLink href="https://meetings-eu1.hubspot.com/hubplanner/book-a-demo">
                                    Looking for a Demo?
                                    <div className="text-muted">Schedule a demo here</div>
                                </NavLink>
                            </NavItem>
                        )}
                        <NavItem onClick={reportBugModal}>
                            <NavLink href="#">
                                Report a Bug
                                <div className="text-muted">Use our bug report form</div>
                            </NavLink>
                        </NavItem>
                        <NavItem onClick={contactModal}>
                            <NavLink href="#">
                                Contact Us
                                <div className="text-muted">Get in touch with an account handler</div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="https://hubplanner.uservoice.com/forums/199077-what-s-next-for-hub-planner-">
                                Suggest a Feature
                                <div className="text-muted">We would be happy to hear your suggestion</div>
                            </NavLink>
                        </NavItem>
                        {0 === location.pathname.indexOf('/reports') && hasReportAccess && (
                            <>
                                <NavItem>
                                    <NavLink href="https://hubplanner.com/kbtopic/reports/">
                                        What is new in Reports?
                                        <div className="text-muted">Take a glance here</div>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" onClick={onReportTourStart}>
                                        Start Report Tour
                                        <div className="text-muted">Start Report Tour</div>
                                    </NavLink>
                                </NavItem>
                            </>
                        )}
                    </Nav>
                </div>
            </Scrollbar>
        </HelpWrapper>
    );
};

Help.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    hasReportAccess: PropTypes.bool.isRequired,
    companyStatus: PropTypes.string.isRequired,
    windowHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
};

export default Help;
