import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFormMeta } from 'redux-form';

/**
 * Usecase: When component which had fields was unmounted using redux form, sync errors from it disappear, making form valid to submit. Touch untouched fields with errors to show errors (useful when a field error depend on another field)
 * @param {*} param0
 */
const useTouchInterdependentFieldsReduxForm = ({ touch, formName, validationResult = {}, onlyKeys }) => {
    const formMetaSelector = useMemo(() => getFormMeta(formName), [formName]);
    const formMetaFromSelector = useSelector(formMetaSelector);

    useEffect(
        () =>
            Object.keys(validationResult).forEach(key => {
                if (!formMetaFromSelector?.[key]?.touched && touch) {
                    // Some forms start with invalid data.
                    // onlyKeys is used to specify only the ones to touch until submit
                    if (onlyKeys && Array.isArray(onlyKeys)) {
                        if (onlyKeys.includes(key)) {
                            touch(key);
                        }
                    } else {
                        touch(key);
                    }
                }
            }),
        [touch, validationResult, formMetaFromSelector, onlyKeys]
    );
};

export default useTouchInterdependentFieldsReduxForm;
