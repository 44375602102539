import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { map, filter, includes } from 'lodash';
import { createProject, updateProject } from 'actions/projectActions';
import {
    updateDaypilotMilestonesPhasesDates,
    removeDaypilotEvents,
    updateProjectInBookings,
} from 'modules/scheduler/utils/schedulerUtil';
import { formatMilestonesToDP, formatPhasesToDP, formatStartEndTimesToDP } from 'modules/scheduler/utils/eventUtil';
import { useHasRights } from 'hooks';
import { updateBookingSelection } from 'actions/schedulerActions';
import {useGetViewObject} from '../modules/scheduler/hooks';

const rights = [
    {
        rights: ['viewPhases'],
        rule: 'one',
        name: 'canViewPhases',
    },
    {
        rights: ['viewMilestones'],
        rule: 'one',
        name: 'canViewMilestones',
    },
    {
        rights: ['viewStartEndDates'],
        rule: 'one',
        name: 'canViewProjectDates',
    },
];

export const useProjectAction = schedulerRefParam => {
    const dispatch = useDispatch();
    const { canViewPhases, canViewMilestones, canViewProjectDates } = useHasRights(rights);
    const viewObject = useGetViewObject();

    const requestHandler = useCallback(
        project => {
            let schedulerRef = schedulerRefParam;
            if (!schedulerRefParam) {
                if (window.schedulerRef?.current?.control) {
                    schedulerRef = window.schedulerRef;
                } else {
                    return;
                }
            }

            const milestones = schedulerRef.current.control.events.list.filter(
                event => event.milestone && event.projectId === project._id
            );
            const phases = schedulerRef.current.control.events.list.filter(
                event => event.phase && event.projectId === project._id
            );
            const dates = schedulerRef.current.control.events.list.filter(
                event => event.datesEvent && event.projectId === project._id
            );
            const bookings = schedulerRef.current.control.events.list.filter(
                event => !event.milestone && !event.phase && !event.datesEvent && !event.deadline && event.project._id === project._id
            );

            const projectPhases = map(project.phases, '_id');
            const projectMilestones = map(project.milestones, '_id');

            const milestonesToRemove = filter(milestones, milestone => !includes(projectMilestones, milestone.id));
            const phasesToRemove = filter(phases, phase => !includes(projectPhases, phase.id));
            const datesToRemove =
                !project.useProjectDuration && dates.length ? ['start_' + project._id, 'end_' + project._id] : [];

            const datesToAdd = project.useProjectDuration ? formatStartEndTimesToDP([project]) : [];

            removeDaypilotEvents(
                [...map(phasesToRemove, 'id'), ...map(milestonesToRemove, 'id'), ...datesToRemove],
                schedulerRef.current.control
            );

            const toAddToScheduler = [];

            if (canViewMilestones) {
                toAddToScheduler.push(...formatMilestonesToDP(project.milestones));
            }

            if (canViewPhases) {
                toAddToScheduler.push(...formatPhasesToDP(project.phases));
            }

            if (canViewProjectDates) {
                toAddToScheduler.push(...datesToAdd);
            }

            updateDaypilotMilestonesPhasesDates(toAddToScheduler, schedulerRef.current.control);
            updateProjectInBookings(bookings, project, schedulerRef.current.control, viewObject);
        },
        [canViewMilestones, canViewPhases, canViewProjectDates, schedulerRefParam, viewObject]
    );

    const create = useCallback(
        (data, redirect = false) => {
            dispatch(createProject.request(data, requestHandler, redirect));
        },
        [dispatch, requestHandler]
    );

    const update = useCallback(
        (id, data, callback) => {
            dispatch(
                updateProject.request(id, data, project => {
                    callback && callback();
                    dispatch(updateBookingSelection.request());
                    return requestHandler(project);
                })
            );
        },
        [dispatch, requestHandler]
    );

    /**
     * HUB-10114,
     * dispatch(updateBookingSelection.request()) from update function
     * was a bad effect for sidebar and notes, to be sure that HUB-10114
     * won't break anything else and to avoid adding next param or making breaking changes
     * to update() function, created a duplicate without this call
      */
    const updateWithoutSelectionClear = useCallback(
        (id, data, callback) => {
            dispatch(
                updateProject.request(id, data, project => {
                    callback && callback();
                    return requestHandler(project);
                })
            );
        },
        [dispatch, requestHandler]
    );

    return { createProject: create, updateProject: update, updateProjectWithoutSelectionClear: updateWithoutSelectionClear };
};
