import { find, each, filter } from 'underscore';
import {
    EventNote,
    Folder,
    Group,
    AttachMoney,
    Build,
    ColorLens,
    NaturePeople,
    Public,
    Favorite,
    Lock,
    PeopleOutline,
    Person,
    Face,
} from '@material-ui/icons';
import moize from 'moize';
import { hasRole, hasProjectManagerReportRights, hasOneOfRoles, hasReportTemplateRights } from 'utils/rightsUtil';
import { TIME_SHEET, UNASSIGNED } from 'enums/extensionShortIdEnum';
import { Red, Green, Black } from './../components/styles';

export const PROJECT_REPORT = {
    display: 'Project Reports',
    value: 'PROJECT_REPORT',
    roleRights: ['manageReports'],
    additionalRights: hasProjectManagerReportRights,
    icon: EventNote,
    route: 'projectReport',
    description: 'Check project health, project progress and make sure your projects are on track.',
    dataCy: 'project-reports',
    subTypes: [
        {
            route: 'scheduled',
            display: 'Scheduled Time',
            groupBy: 'project',
            itemType: 'project',
            value: 'SCHEDULED_TIME',
            dataCy: 'project-reports-scheduled-time',
        },
        {
            route: 'reported',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'REPORTED_TIME',
            dataCy: 'project-reports-reported-time',
        },
        {
            route: 'categories',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'project',
            value: 'SCHEDULED_TIME',
            dataCy: 'project-reports-booking-category',
        },
        {
            route: 'burn',
            display: 'Project Burn',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'PROJECT_BURN_TIME',
            dataCy: 'project-reports-project-burn',
        },
    ],
};

export const RESOURCE_REPORT = {
    display: 'Resource Reports',
    value: 'RESOURCE_REPORT',
    roleRights: ['manageReports'],
    additionalRights: hasProjectManagerReportRights,
    icon: Group,
    route: 'resourceReport',
    description: 'Find out how well your team are performing and being utilized.',
    dataCy: 'resource-reports',
    subTypes: [
        {
            route: 'scheduled',
            display: 'Scheduled Time',
            roleRights: ['manageReports'],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
            dataCy: 'resource-reports-scheduled-time',
        },
        {
            route: 'reported',
            display: 'Reported Time',
            roleRights: ['manageReports'],
            extensions: [TIME_SHEET],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'REPORTED_TIME',
            dataCy: 'resource-reports-reported-time',
        },
        {
            route: 'utilization',
            display: 'Forecasted Utilization',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_UTILIZATION_TIME',
            dataCy: 'resource-reports-forecasted-utilization',
        },
        {
            route: 'availability',
            display: 'Forecasted Availability',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_AVAILABILITY_TIME',
            dataCy: 'resource-reports-forecasted-availability',
        },
        {
            route: 'categories',
            display: 'Booking Categories',
            roleRights: ['manageReports'],
            groupBy: 'bookingCategory',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
            dataCy: 'resource-reports-booking-categories',
        },
        {
            route: 'burn',
            display: 'Resource Burn',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_BURN_TIME',
            dataCy: 'resource-reports-resource-burn',
        },
    ],
};

export const BILLING_REPORT = {
    display: 'Billing & Spend',
    value: 'BILLING_REPORT',
    icon: AttachMoney,
    route: 'billingReport',
    roleRights: ['manageReports', 'showBilling'],
    additionalRights: account =>
        hasProjectManagerReportRights(account) && hasRole(account.resourceRoleRights, ['showBilling']),
    description: 'A collection of financial reports to check your spend and profits.',
    dataCy: 'billing-reports',
    subTypes: [
        {
            route: 'project-scheduled',
            type: 'PROJECT',
            display: 'Scheduled Spend',
            groupBy: 'project',
            itemType: 'project',
            value: 'SCHEDULED_SPEND',
            dataCy: 'billing-reports-project-scheduled',
        },
        {
            route: 'project-reported',
            type: 'PROJECT',
            display: 'Reported Spend',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'REPORTED_SPEND',
            dataCy: 'billing-reports-project-reported',
        },
        {
            route: 'project-scheduled-reported',
            type: 'PROJECT',
            display: 'Scheduled vs. Reported Spend',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'PROJECT_SCHEDULED_ACTUAL_SPEND',
            dataCy: 'billing-reports-project-scheduled-reported',
        },
        {
            route: 'project-profitability',
            type: 'PROJECT',
            display: 'Project Profitability',
            groupBy: 'project',
            itemType: 'project',
            value: 'PROJECT_PROFITABILITY',
            dataCy: 'billing-reports-project-profitability',
        },
        {
            route: 'resource-scheduled',
            type: 'RESOURCE',
            display: 'Scheduled Spend',
            roleRights: ['manageReports', 'showBilling'],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'SCHEDULED_SPEND',
            dataCy: 'billing-reports-resource-scheduled',
        },
        {
            route: 'resource-reported',
            type: 'RESOURCE',
            display: 'Reported Spend',
            roleRights: ['manageReports', 'showBilling'],
            extensions: [TIME_SHEET],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'REPORTED_SPEND',
            dataCy: 'billing-reports-resource-reported',
        },
        {
            route: 'resource-scheduled-actual',
            type: 'RESOURCE',
            display: 'Scheduled vs. Reported Spend',
            roleRights: ['manageReports', 'showBilling'],
            extensions: [TIME_SHEET],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_SCHEDULED_ACTUAL_SPEND',
            dataCy: 'billing-reports-resource-scheduled-actual',
        },
        {
            route: 'resource-profitability',
            type: 'RESOURCE',
            display: 'Resource Profitability',
            roleRights: ['manageReports', 'showBilling'],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_PROFITABILITY',
            dataCy: 'billing-reports-resource-profitability',
        },
        {
            route: 'budget-scheduled',
            type: 'BUDGET',
            display: 'Scheduled Budget',
            groupBy: 'project',
            itemType: 'project',
            value: 'BUDGET_SCHEDULED',
            dataCy: 'billing-reports-budget-scheduled',
        },
        {
            route: 'budget-actual',
            type: 'BUDGET',
            display: 'Reported Budget',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'BUDGET_ACTUAL',
            dataCy: 'billing-reports-budget-actual',
        },
        {
            route: 'budget-scheduled-actual',
            type: 'BUDGET',
            display: 'Scheduled vs. Reported Budget',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'BUDGET_SCHEDULED_ACTUAL',
            dataCy: 'billing-reports-budget-scheduled-actual',
        },
        {
            route: 'budget-scheduled-category',
            type: 'BUDGET',
            display: 'Scheduled Category Budget',
            groupBy: 'budgetCategory',
            itemType: 'project',
            value: 'BUDGET_CATEGORY_SCHEDULED',
            dataCy: 'billing-reports-budget-scheduled-category',
        },
        {
            route: 'budget-category-actual',
            type: 'BUDGET',
            display: 'Reported Category Budget',
            groupBy: 'budgetCategory',
            itemType: 'project',
            value: 'BUDGET_CATEGORY_ACTUAL',
            dataCy: 'billing-reports-budget-category-actual',
        },
        {
            route: 'budget-scheduled-actual-category',
            type: 'BUDGET',
            display: 'Scheduled vs. Reported Category Budget',
            groupBy: 'budgetCategory',
            itemType: 'project',
            value: 'BUDGET_CATEGORY_SCHEDULED_ACTUAL',
            dataCy: 'billing-reports-budget-scheduled-actual-category',
        },
    ],
};

export const GROUP_REPORT = {
    display: 'Group Reports',
    value: 'GROUP_REPORT',
    icon: Folder,
    route: 'group-report',
    roleRights: ['manageReports'],
    description: 'Check project health, project progress and make sure your projects are on track.',
    dataCy: 'group-reports',
    subTypes: [
        {
            route: 'scheduled-project-group',
            type: 'PROJECT',
            display: 'Scheduled Time',
            groupBy: 'projectGroup',
            itemType: 'project',
            value: 'SCHEDULED_TIME',
            dataCy: 'group-reports-scheduled-project-group',
        },
        {
            route: 'reported-project-group',
            type: 'PROJECT',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'projectGroup',
            itemType: 'project',
            value: 'REPORTED_TIME',
            dataCy: 'group-reports-reported-project-group',
        },
        {
            route: 'burn-project-group',
            type: 'PROJECT',
            display: 'Group Burn',
            extensions: [TIME_SHEET],
            groupBy: 'projectGroup',
            itemType: 'project',
            value: 'PROJECT_BURN_TIME',
            dataCy: 'group-reports-burn-project-group',
        },
        {
            route: 'scheduled-resource-group',
            type: 'RESOURCE',
            display: 'Scheduled Time',
            groupBy: 'resourceGroup',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
            dataCy: 'group-reports-scheduled-resource-group',
        },
        {
            route: 'reported-resource-group',
            type: 'RESOURCE',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'resourceGroup',
            itemType: 'resource',
            value: 'REPORTED_TIME',
            dataCy: 'group-reports-reported-resource-group',
        },
        {
            route: 'utilization-resource-group',
            type: 'RESOURCE',
            display: 'Forecasted Utilization',
            groupBy: 'resourceGroup',
            itemType: 'resource',
            value: 'RESOURCE_UTILIZATION_TIME',
            dataCy: 'group-reports-utilization-resource-group',
        },
        {
            route: 'availability-resource-group',
            type: 'RESOURCE',
            display: 'Forecasted Availability',
            groupBy: 'resourceGroup',
            itemType: 'resource',
            value: 'RESOURCE_AVAILABILITY_TIME',
            dataCy: 'group-reports-availability-resource-group',
        },
        {
            route: 'burn-resource-group',
            type: 'RESOURCE',
            display: 'Resource Burn',
            groupBy: 'resourceGroup',
            itemType: 'resource',
            value: 'RESOURCE_BURN_TIME',
            dataCy: 'group-reports-burn-resource-group',
        },
    ],
};

export const UTILITY_REPORT = {
    display: 'Utility Reports',
    value: 'UTILITY_REPORT',
    icon: Build,
    route: 'utilityReport',
    roleRights: ['manageReports'],
    description: 'Useful & smart templates put together to answer your questions.',
    dataCy: 'utility-report',
    subTypes: [
        {
            route: 'available',
            display: 'Who is available?',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'WHO_AVAILABLE',
            dataCy: 'utility-report-available',
        },
        {
            route: 'over',
            display: 'Who is over scheduled?',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'OVER_SCHEDULED',
            dataCy: 'utility-report-over',
        },
        {
            route: 'behind',
            display: 'Who is behind on time sheets?',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'BEHIND_TIMESHEET',
            dataCy: 'utility-report-behind',
        },
        {
            route: 'over-budget',
            roleRights: ['manageReports', 'showBilling'],
            display: 'What projects are over budget?',
            groupBy: 'project',
            itemType: 'project',
            value: 'OVER_BUDGET',
            dataCy: 'utility-report-over-budget',
        },
    ],
};

export const EVENT_REPORT = {
    display: 'Event Reports',
    value: 'EVENT_REPORT',
    roleRights: ['manageReports'],
    icon: NaturePeople,
    route: 'event',
    description: 'Check event health, event progress and make sure your events are on track.',
    dataCy: 'event-reports',
    subTypes: [
        {
            route: 'scheduled',
            display: 'Scheduled Time',
            groupBy: 'project',
            itemType: 'event',
            value: 'SCHEDULED_TIME',
            dataCy: 'event-reports-scheduled',
        },
        {
            route: 'reported',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'event',
            value: 'REPORTED_TIME',
            dataCy: 'event-reports-reported',
        },
        {
            route: 'categories',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'event',
            value: 'SCHEDULED_TIME',
            dataCy: 'event-reports-categories',
        },
        {
            route: 'burn',
            display: 'Event Burn',
            groupBy: 'project',
            itemType: 'event',
            value: 'EVENT_BURN_TIME',
            dataCy: 'event-reports-burn',
        },
    ],
};

export const UNASSIGNED_REPORT = {
    display: 'Unassigned Work',
    value: 'UNASSIGNED_REPORT',
    roleRights: ['manageReports'],
    additionalRights: hasProjectManagerReportRights,
    icon: PeopleOutline,
    route: 'unassignedReport',
    extensions: [UNASSIGNED],
    description: 'Report on your companies demand using the unassigned categories.',
    dataCy: 'unassigned-work-reports',
    subTypes: [
        {
            route: 'scheduled',
            display: 'Scheduled Time',
            groupBy: 'resource',
            itemType: 'unassigned',
            value: 'SCHEDULED_TIME',
            dataCy: 'unassigned-work-reports-scheduled',
        },
        {
            route: 'categories',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'unassigned',
            value: 'SCHEDULED_TIME',
            dataCy: 'unassigned-work-reports-categories',
        },
    ],
};

export const MY_REPORTS = {
    display: 'My Reports',
    value: 'MY_REPORTS',
    icon: Person,
    route: 'my-reports',
    additionalRights: account =>
        hasProjectManagerReportRights(account) ||
        hasOneOfRoles(account.resourceRoleRights, ['manageReports', 'manageMyReports']),
    description: 'Reports based on my scheduled and reported time.',
    dataCy: 'my-reports',
    subTypes: [
        {
            route: 'scheduled',
            display: 'My Scheduled Time',
            groupBy: 'project',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
            dataCy: 'my-reports-scheduled',
        },
        {
            route: 'reported',
            display: 'My Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'resource',
            value: 'REPORTED_TIME',
            dataCy: 'my-reports-reported',
        },
        {
            route: 'utilization',
            display: 'My Utilization',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_UTILIZATION_TIME',
            dataCy: 'my-reports-utilization',
        },
        {
            route: 'availability',
            display: 'My Availability',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_AVAILABILITY_TIME',
            dataCy: 'my-reports-availability',
        },
        {
            route: 'categories',
            display: 'My Categories',
            groupBy: 'bookingCategory',
            itemType: 'resource',
            value: 'RESOURCE_CATEGORIES',
            dataCy: 'my-reports-categories',
        },
    ],
};

export const CUSTOM_REPORT = {
    display: 'Custom Reports',
    value: 'CUSTOM_REPORT',
    route: 'templates',
    dataCy: 'custom-reports',
    subTypes: [
        {
            route: 'saved',
            display: 'My Saved Reports',
            dataCy: 'my-saved-reports',
            additionalRights: account =>
                hasProjectManagerReportRights(account) ||
                hasRole(account, 'manageMyReports') ||
                hasRole(account, 'manageReports'),
            groupBy: '',
            icon: Lock,
            color: Black,
            value: 'SAVED',
            description: 'Reports which you save and are only visible to you.',
            deleteTitle: 'Remove Saved Report?',
            deleteMessage: 'Removing will permanently remove this report from your saved templates.',
        },
        {
            route: 'favourites',
            display: 'Favorite Reports',
            roleRights: ['manageReports'],
            dataCy: 'favorite-reports',
            additionalRights: hasProjectManagerReportRights,
            groupBy: '',
            icon: Favorite,
            color: Red,
            value: 'FAVOURITES',
            description: 'Find the reports you have marked as a favorite in this section.',
            deleteTitle: 'Remove Favorite Report?',
            deleteMessage: 'Removing will permanently remove this report from your favourites templates.',
        },
        {
            route: 'internal',
            display: 'Internal Reports',
            roleRights: ['manageReports'],
            dataCy: 'internal-reports',
            additionalRights: hasProjectManagerReportRights,
            groupBy: '',
            icon: Public,
            color: Green,
            value: 'INTERNAL',
            description: 'All report templates which are visible for everyone in your company.',
            deleteTitle: 'Remove Internal Report?',
            deleteMessage: 'Removing will permanently remove this report from your internals templates.',
        },
    ],
};

export const HEAT_MAP_REPORT = {
    display: 'Heat Map Reports',
    value: 'HEAT_MAP_REPORT',
    icon: ColorLens,
    route: 'heatMap',
    roleRights: ['manageReports'],
    additionalRights: hasProjectManagerReportRights,
    description: 'Get a color coded view of your metrics using these heat map reports.',
    dataCy: 'heat-map-reports',
    subTypes: [
        {
            dataCy: 'heat-map-reports-project-scheduled',
            route: 'project-scheduled',
            type: 'PROJECT',
            display: 'Scheduled Time',
            groupBy: 'project',
            itemType: 'project',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-project-reported',
            route: 'project-reported',
            type: 'PROJECT',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'project',
            value: 'REPORTED_TIME',
        },
        {
            dataCy: 'heat-map-reports-project-category',
            route: 'project-category',
            type: 'PROJECT',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'project',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-event-scheduled',
            route: 'event-scheduled',
            type: 'EVENT',
            display: 'Scheduled Time',
            groupBy: 'project',
            itemType: 'event',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-event-reported',
            route: 'event-reported',
            type: 'EVENT',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'project',
            itemType: 'event',
            value: 'REPORTED_TIME',
        },
        {
            dataCy: 'heat-map-reports-event-category',
            route: 'event-category',
            type: 'EVENT',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'event',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-resource-scheduled',
            route: 'resource-scheduled',
            type: 'RESOURCE',
            display: 'Scheduled Time',
            roleRights: ['manageReports'],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-resource-reported',
            route: 'resource-reported',
            type: 'RESOURCE',
            display: 'Reported Time',
            roleRights: ['manageReports'],
            extensions: [TIME_SHEET],
            groupBy: 'resource',
            itemType: 'resource',
            value: 'REPORTED_TIME',
        },
        {
            dataCy: 'heat-map-reports-resource-availability',
            route: 'resource-availability',
            type: 'RESOURCE',
            display: 'Availability',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_AVAILABILITY_TIME',
        },
        {
            dataCy: 'heat-map-reports-resource-utilization',
            route: 'resource-utilization',
            type: 'RESOURCE',
            display: 'Utilization',
            groupBy: 'resource',
            itemType: 'resource',
            value: 'RESOURCE_UTILIZATION_TIME',
        },
        {
            dataCy: 'heat-map-reports-resource-category',
            route: 'resource-category',
            type: 'RESOURCE',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            itemType: 'resource',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-unassigned-scheduled',
            route: 'unassigned-scheduled',
            type: 'UNASSIGNED',
            display: 'Scheduled Time',
            groupBy: 'unassigned',
            roleRights: ['manageReports'],
            extensions: [UNASSIGNED],
            itemType: 'unassigned',
            value: 'SCHEDULED_TIME',
        },
        {
            dataCy: 'heat-map-reports-unassigned-category',
            route: 'unassigned-category',
            type: 'UNASSIGNED',
            display: 'Booking Categories',
            groupBy: 'bookingCategory',
            roleRights: ['manageReports'],
            extensions: [UNASSIGNED],
            itemType: 'unassigned',
            value: 'SCHEDULED_TIME',
        },
    ],
};

export const CUSTOMER_REPORT = {
    display: 'Client Reports',
    value: 'CUSTOMER_REPORT',
    icon: Face,
    route: 'customerReport',
    roleRights: ['manageReports'],
    additionalRights: hasProjectManagerReportRights,
    description: 'Check project health, project progress and make sure your projects are on track.',
    dataCy: 'client-reports',
    subTypes: [
        {
            dataCy: 'client-reports-resource-reports',
            route: 'resource-reports',
            type: 'PROJECT',
            display: 'Scheduled Time',
            groupBy: 'customer',
            itemType: 'project',
            value: 'PROJECT_SCHEDULED_TIME',
        },
        {
            dataCy: 'client-reports-project-reported-time',
            route: 'project-reported-time',
            type: 'PROJECT',
            display: 'Reported Time',
            extensions: [TIME_SHEET],
            groupBy: 'customer',
            itemType: 'project',
            value: 'PROJECT_REPORTED_TIME',
        },
        {
            dataCy: 'client-reports-project-scheduled-spend',
            route: 'project-scheduled-spend',
            type: 'PROJECT',
            display: 'Scheduled Spend',
            groupBy: 'customer',
            itemType: 'project',
            value: 'PROJECT_SCHEDULED_SPEND',
            roleRights: ['manageReports', 'showBilling'],
        },
        {
            dataCy: 'client-reports-project-reported-spend',
            route: 'project-reported-spend',
            type: 'PROJECT',
            display: 'Reported Spend',
            extensions: [TIME_SHEET],
            groupBy: 'customer',
            itemType: 'project',
            value: 'PROJECT_REPORTED_SPEND',
            roleRights: ['manageReports', 'showBilling'],
        },
        {
            dataCy: 'client-reports-resource-scheduled-time',
            route: 'resource-scheduled-time',
            type: 'RESOURCE',
            display: 'Scheduled Time',
            roleRights: ['manageReports'],
            groupBy: 'customer',
            itemType: 'resource',
            value: 'RESOURCE_SCHEDULED_TIME',
        },
        {
            dataCy: 'client-reports-resource-reported-time',
            route: 'resource-reported-time',
            type: 'RESOURCE',
            display: 'Reported Time',
            roleRights: ['manageReports'],
            extensions: [TIME_SHEET],
            groupBy: 'customer',
            itemType: 'resource',
            value: 'RESOURCE_REPORTED_TIME',
        },
        {
            dataCy: 'client-reports-resource-scheduled-spend',
            route: 'resource-scheduled-spend',
            type: 'RESOURCE',
            display: 'Scheduled Spend',
            groupBy: 'customer',
            itemType: 'resource',
            value: 'RESOURCE_SCHEDULED_SPEND',
            roleRights: ['manageReports', 'showBilling'],
        },
        {
            dataCy: 'client-reports-resource-reported-spend',
            route: 'resource-reported-spend',
            type: 'RESOURCE',
            display: 'Reported Spend',
            extensions: [TIME_SHEET],
            groupBy: 'customer',
            itemType: 'resource',
            value: 'RESOURCE_REPORTED_SPEND',
            roleRights: ['manageReports', 'showBilling'],
        },
    ],
};

export const reportTypes = {
    PROJECT_REPORT,
    RESOURCE_REPORT,
    BILLING_REPORT,
    UTILITY_REPORT,
    GROUP_REPORT,
    EVENT_REPORT,
    UNASSIGNED_REPORT,
    MY_REPORTS,
    HEAT_MAP_REPORT,
    CUSTOMER_REPORT,
};

export const filterReportTypesByRolesAndExtensions = moize(
    (account, extensions) => filter(reportTypes, report => hasReportTemplateRights(report, account, extensions)),
    { maxSize: 2 }
);

export const findReportTypeByRoute = moize(
    componentName => find(reportTypes, report => componentName === report.route || componentName === report.value),
    { maxSize: 5 }
);

export const findReportTypeBySubTypeAndGroup = moize(
    (reportSubType, groupBy, reportType) => {
        let foundSubType = {};
        each(reportTypes, type => {
            each(type.subTypes, subType => {
                if (!reportType) {
                    reportType = type.value;
                }
                if (
                    reportType === type.value &&
                    subType.value === reportSubType &&
                    groupBy &&
                    subType.groupBy === groupBy
                ) {
                    foundSubType = subType;
                }
            });
        });

        return foundSubType;
    },
    { maxSize: 5 }
);

export const findReportSubTypeByRoute = moize(
    (report, value) => find(report.subTypes, subtype => value === subtype.route || value === subtype.value),
    { maxSize: 5 }
);

export const findReportSubTypeByValueItemTypeAndGrouping = moize(
    (report, value, itemType, groupBy) =>
        find(
            report.subTypes,
            subtype => value === subtype.value && itemType === subtype.itemType && groupBy === subtype.groupBy
        ),
    { maxSize: 5 }
);
