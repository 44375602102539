import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import RepeatFormik from 'shared/repeat/formik';
import Repeat from 'shared/repeat';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { MenuCollapse } from '../menuCollapse';

const RepeatToggler = props => {
    const { interval, isFormik, onToggleOpen, closeToggle, errors, formName = null } = props;

    const [isOpen, setIsOpen] = useState(false);
    const RepeatComponent = isFormik ? RepeatFormik : Repeat;

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    return (
        <>
            <Toggler data-cy="repeat-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    <span>Repeat:</span> {interval}
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="px-3 py-2">
                    <RepeatComponent
                        inline={false}
                        formName={formName}
                        inputFieldDisabled={'NONE' === interval}
                        errors={errors}
                    />
                </div>
            </MenuCollapse>
        </>
    );
};

RepeatToggler.propTypes = {
    interval: PropTypes.string,
};

RepeatToggler.defaultProps = {
    interval: '',
};

export default RepeatToggler;
