import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { head } from 'lodash';
import { Menu, ClickAwayListener } from '@material-ui/core';
import { getContextMenuPosition } from 'utils/browserUtil';
import { usePressEscape } from 'hooks';
import QuickAdd, { CONTEXT_MENU_WIDTH } from 'modules/scheduler/components/dropdowns/quickAdd';
import { useStyles } from './../styles';

const MenuRow = props => {
    const { top, left, close, type, additionalParams, windowHeight, windowWidth } = props;
    const node = useRef();
    const mountedRef = useRef(false);
    const classes = useStyles();
    const contextMenu = getContextMenuPosition({ windowHeight, windowWidth }, { top, left }, CONTEXT_MENU_WIDTH);
    usePressEscape(close);

    useEffect(() => {
        const refTimeout = setTimeout(() => {
            mountedRef.current = true;
        }, 0);

        return () => {
            clearTimeout(refTimeout);
            mountedRef.current = false;
        };
    }, []);

    const handler = event => {
        const datepicker = document.getElementsByClassName('MuiPickersBasePicker-container');
        if (
            !mountedRef.current ||
            node.current.contains(event.target) ||
            (datepicker.length && head(datepicker).contains(event.target))
        ) {
            return;
        }
        close();
    };

    return (
        <Menu
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            keepMounted
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <ClickAwayListener onClickAway={handler}>
                <QuickAdd onClose={close} type={type} height={contextMenu.height} {...additionalParams} />
            </ClickAwayListener>
        </Menu>
    );
};

MenuRow.propTypes = {
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    windowHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
    additionalParams: PropTypes.shape({
        resource: PropTypes.object,
        project: PropTypes.object,
    }).isRequired,
    type: PropTypes.oneOf(['project', 'event', 'resource']).isRequired,
    close: PropTypes.func.isRequired,
};

export default MenuRow;
