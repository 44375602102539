import { store } from '../../store';

export const getProjectColor = (project, projectStatusColors) => {
    if (!projectStatusColors) {
        projectStatusColors = store.getState()?.companyReducer?.company?.settings?.projectStatusColors || {};
    }
    if (project?.useStatusColor && projectStatusColors[project?.status]) {
        return projectStatusColors[project?.status];
    } else {
        return project?.backgroundColor;
    }
};

export const replaceProjectsColorWithStatusColor = projects => {
    const projectStatusColors = store.getState()?.companyReducer?.company?.settings?.projectStatusColors || {};
    if (projectStatusColors && Object.keys(projectStatusColors).length > 0) {
        const checkFn = project => {
            if (project.useStatusColor && projectStatusColors[project.status]) {
                const projectColor = getProjectColor(project, projectStatusColors);
                return {
                    ...project,
                    backgroundColor: projectColor,
                    color: projectColor,
                };
            }
            return project;
        };
        if (Array.isArray(projects)) {
            return projects.map(checkFn);
        } else {
            return checkFn(projects);
        }
    }
    return projects;
};
