import styled from 'styled-components';

export const MeterContainer = styled.div`
    min-width: 100px;
    height: 8px;
    border: 1px solid #cecece;
    position: relative;
`;

export const PasswordStrength = styled.div`
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;

    &.strength-0 {
        background-color: red;
    }
    &.strength-1 {
        background-color: #f25f5c;
    }
    &.strength-2 {
        background-color: #ffe066;
    }
    &.strength-3 {
        background-color: #247ba0;
    }
    &.strength-4 {
        background-color: #70c1b3;
    }
`;
