import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Typography } from '@hub-mono/ui';

class VacationYear extends PureComponent {
    static propTypes = {
        wrapperClassNames: PropTypes.string,
        muted: PropTypes.bool,
        small: PropTypes.bool,
    };

    static defaultProps = {
        wrapperClassNames: '',
        muted: true,
        small: true,
    };

    render() {
        let year = moment.utc(this.props.startDate, 'YYYY-MM-DD').year();

        if (year !== moment.utc(this.props.endDate, 'YYYY-MM-DD').year()) {
            year = `${moment.utc(this.props.startDate, 'YYYY-MM-DD').year()}/${moment
                .utc(this.props.endDate, 'YYYY-MM-DD')
                .year()}`;
        }

        return (
            <Typography variant="body" size="small">
                {year}
            </Typography>
        );
    }
}

VacationYear = connect(state => ({
    startDate: state.requestReducer.getIn(['requestModel', 'start']),
    endDate: state.requestReducer.getIn(['requestModel', 'end']),
}))(VacationYear);

export default VacationYear;
