import { includes, isEmpty, isArray, find, map } from 'lodash';

export const getCustomFieldLabel = (customFields, customColumnSubRefId) => {
    if (isEmpty(customColumnSubRefId)) {
        return '';
    }

    if (customFields && isArray(customFields)) {
        const customField = find(customFields, customField => customField.templateId === customColumnSubRefId);

        if (!customField) {
            return 'Custom Field Not Set';
        }

        if (customField && includes(['SELECT', 'CHECKBOX', 'RADIO'], customField.templateType)) {
            return map(customField.choices, 'value').join(', ');
        }

        return customField?.value || '';
    }

    return '';
};

export const getCustomFieldLabelFromResource = (resource, customColumnSubRefId) => {
    if (isEmpty(customColumnSubRefId) || !resource) {
        return '';
    }

    if (resource[customColumnSubRefId] && isArray(resource[customColumnSubRefId])) {
        return map(resource[customColumnSubRefId], 'value').join(', ');
    } else if (resource[customColumnSubRefId]) {
        return resource[customColumnSubRefId] || '';
    }

    return '';
};
