import React from 'react';
import SettingsLayout from 'modules/setting/template/template';
import authorization from 'services/auth/authorization';
import PermissionDenied from 'modules/setting/components/permissionDenied';
import Home from 'modules/setting/components/home/home';

const Billing = React.lazy(() => import('./containers/billing'));
const Subscription = React.lazy(() => import('./containers/subscription'));
const UpdateCard = React.lazy(() => import('./containers/updateCard'));
const Holidays = React.lazy(() => import('./containers/holidays'));
const MyCustomFields = React.lazy(() => import('./containers/myCustomFields'));
const CategoryGroups = React.lazy(() => import('modules/categoryGroups/categoryGroups'));
const FixedCosts = React.lazy(() => import('modules/costsCategories/settingsView'));
const Permissions = React.lazy(() => import('./components/permissions'));
const Authentication = React.lazy(() => import('./components/authentication'));
const AuditLogs = React.lazy(() => import('./components/auditLog/AuditLogs'));
const AccountSettings = React.lazy(() => import('./components/accountSettings'));
const ToilSettingView = React.lazy(() => import('../toil/settings/View'));

const MyProfile = authorization({})(React.lazy(() => import('./containers/myProfile')));
const Approvers = authorization({})(React.lazy(() => import('./components/approvers')));
const Resources = authorization({})(React.lazy(() => import('./components/resources')));
const Projects = authorization({})(React.lazy(() => import('./components/projects')));
const ProjectManagers = authorization({})(React.lazy(() => import('./components/projectManagers')));
const MyIcalFeed = authorization({})(React.lazy(() => import('./components/myIcalFeed')));
const MyPreferences = authorization({})(React.lazy(() => import('./containers/myPreferences')));
const MyNotifications = authorization({})(React.lazy(() => import('./components/myNotifications')));

const MyIcalFeedAuth = authorization({
    allowedRoleRights: ['calendarFeed'],
    errorComponent: PermissionDenied,
})(MyIcalFeed);

const MyCustomFieldsAuth = authorization({
    allowedRoleRights: ['settingResourceCf'],
    errorComponent: PermissionDenied,
})(MyCustomFields);

const BillingAuth = authorization({
    allowedRoleRights: ['settingBilling'],
    errorComponent: PermissionDenied,
})(Billing);

const SubscriptionAuth = authorization({
    allowedRoleRights: ['settingBilling'],
    errorComponent: PermissionDenied,
})(Subscription);

const UpdateCardAuth = authorization({
    allowedRoleRights: ['settingBilling'],
    errorComponent: PermissionDenied,
})(UpdateCard);

const HolidaysAuth = authorization({
    allowedRoleRights: ['settingHoliday'],
    errorComponent: PermissionDenied,
})(Holidays);

const PermissionsAuth = authorization({
    allowedRoleRights: ['settingResourcePermissions'],
    errorComponent: PermissionDenied,
})(Permissions);

const CategoryGroupsAuth = authorization({
    allowedRoleRights: ['settingBookCat'],
    errorComponent: PermissionDenied,
})(CategoryGroups);

const FixedCostsAuth = authorization({
    allowedRoleRights: ['settingFixedCostsCategories'],
    errorComponent: PermissionDenied,
})(FixedCosts);

const AuthenticationAuth = authorization({
    allowedRoleRights: ['settingAuth'],
    errorComponent: PermissionDenied,
})(Authentication);

const AuditLogAuth = authorization({
    allowedRoleRights: ['canViewAuditLog'],
    errorComponent: PermissionDenied,
})(AuditLogs);

const ApproversAuth = authorization({
    allowedRoleRights: ['settingResourceAsApprover'],
    errorComponent: PermissionDenied,
})(Approvers);

const AccountSettingsAuth = authorization({
    allowedRoleRights: ['settingAccount'],
    errorComponent: PermissionDenied,
})(AccountSettings);

const ResourcesAuth = authorization({
    allowedRoleRights: ['settingAddEditResources'],
    errorComponent: PermissionDenied,
})(Resources);

const ProjectsAuth = authorization({
    allowedRoleRights: ['settingAddEditProjects'],
    errorComponent: PermissionDenied,
})(Projects);

const ProjectManagersAuth = authorization({
    allowedRoleRights: ['settingResourcePm'],
    errorComponent: PermissionDenied,
})(ProjectManagers);

const ToilSettingViewAuth = authorization({
    allowedRoleRights: ['settingToil'],
    errorComponent: PermissionDenied,
})(ToilSettingView);

export default [
    {
        path: '/settings',
        layout: SettingsLayout,
        component: Home,
        title: 'HUB Planner | Settings',
        child: [
            {
                path: '/settings/account',
                exact: true,
                component: AccountSettingsAuth,
            },
            {
                path: '/settings/resources',
                exact: true,
                component: ResourcesAuth,
            },
            {
                path: '/settings/projects',
                exact: true,
                component: ProjectsAuth,
            },
            {
                path: '/settings/project-managers',
                exact: true,
                component: ProjectManagersAuth,
            },
            {
                path: '/settings/billing',
                exact: true,
                component: BillingAuth,
            },
            {
                path: '/settings/billing/subscription',
                component: SubscriptionAuth,
            },
            {
                path: '/settings/billing/disabled',
                component: UpdateCardAuth,
            },
            {
                path: '/settings/holidays',
                component: HolidaysAuth,
            },
            {
                path: '/settings/toil',
                component: ToilSettingViewAuth,
            },
            {
                path: '/settings/profile',
                component: MyProfile,
                helpAreaOptions: {
                    title: 'Help Text Header',
                    description:
                        'Update and edit all of your profile information here. A profile gives you a way to login to the system and based on your role perform certain actions in the tool.',
                },
            },
            {
                path: '/settings/myCustomFields',
                component: MyCustomFieldsAuth,
            },
            {
                path: '/settings/myIcalFeed',
                component: MyIcalFeedAuth,
            },
            {
                path: '/settings/myPreferences',
                component: MyPreferences,
            },
            {
                path: '/settings/myNotifications',
                component: MyNotifications,
            },
            {
                path: '/settings/permissions/:tab(role|resource|options)?',
                component: PermissionsAuth,
            },
            {
                path: '/settings/categoryGroups',
                component: CategoryGroupsAuth,
            },
            {
                path: '/settings/costsCategories',
                component: FixedCostsAuth,
            },
            {
                path: '/settings/authentication',
                component: AuthenticationAuth,
            },
            {
                path: '/settings/auditLog',
                component: AuditLogAuth,
            },
            {
                path: '/settings/approvers',
                component: ApproversAuth,
            },
        ],
    },
];
