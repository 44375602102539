import React from 'react';
import { TIME_SHEET } from 'enums/extensionShortIdEnum';
import { DropdownMenu, NavLink, UncontrolledDropdown, DropdownToggle, NavItem } from 'reactstrap';
import { useIsExtensionInstalled, useIsExtensionEnabled } from 'hooks';
import { NoExtension } from './styles';

const TimesheetItem = () => {
    const isTSActive = useIsExtensionInstalled(TIME_SHEET);
    const isTSEnabled = useIsExtensionEnabled(TIME_SHEET);
    if (isTSActive && isTSEnabled) {
        return (
            <NavItem>
                <NavLink href="/time_sheets">Timesheets</NavLink>
            </NavItem>
        );
    }

    return (
        !isTSActive && (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Timesheets
                </DropdownToggle>
                <DropdownMenu right className="rounded-0">
                    <NoExtension>
                        <div className="locked-area-title">
                            <i className="fa fa-lock" />
                            <span>Locked Area</span>
                        </div>
                        <p>This area is reserved for users with the Timesheets Extension Installed.</p>
                        <NavLink className="btn btn-try-ext" href="/settings#extensions">
                            Try For Free
                        </NavLink>
                    </NoExtension>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    );
};

export default TimesheetItem;
