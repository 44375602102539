import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Modal, ModalFooter } from 'reactstrap';
import { Close } from '@material-ui/icons';
import { hideModal as hideModalAction } from 'actions/modalActions';
import { usePressEscape } from 'hooks';
import SuccessButton from 'shared/buttons/success';
import CancelButton from 'shared/buttons/cancel';
import { EscButton, ModalContent, Header } from './styles';

const REPEAT_MODAL_TYPES = {
    MILESTONE: {
        title: 'Delete milestone',
        description:
            'This is a repeating milestone. Choose if you would like to just delete this milestone, All milestones or all future milestones.',
    },
    PHASE: {
        title: 'Delete phase',
        description:
            'This is a repeating phase. Choose if you would like to just delete this phase, All phases or all future phases.',
    },
    BOOKING: {
        title: 'Delete booking',
        description:
            'This is a repeating booking. Choose if you would like to just delete this booking, All bookings or only future bookings.',
    },
    BOOKING_WITH_DEPENDENCIES: {
        title: 'Delete booking',
        description:
            'This is a repeating booking. Choose if you would like to just delete this booking, All bookings or only future bookings. This booking has multiple parent/child dependencies, after delete, all links will be deleted as well.',
    },
};

const RepeatModal = props => {
    const { type: typeProp, actions, booking } = props;
    const dispatch = useDispatch();
    const hideModal = useCallback(() => dispatch(hideModalAction()), [dispatch]);
    usePressEscape(hideModal);

    const type =
        typeProp === 'BOOKING' && booking?.childIds?.length > 1 && booking?.parentIds?.length > 1
            ? 'BOOKING_WITH_DEPENDENCIES'
            : typeProp;

    const deleteAll = () => {
        actions.deleteAll();
        hideModal();
    };

    const deleteFuture = () => {
        actions.deleteFuture();
        hideModal();
    };

    const deleteOne = () => {
        actions.deleteOne();
        hideModal();
    };

    return (
        <Modal
            contentClassName="rounded-0 mt-5 position-relative"
            size="lg"
            isOpen={true}
            fade={false}
            style={{ width: '790px' }}
            autoFocus={false}
            toggle={hideModal}
        >
            <Header>{REPEAT_MODAL_TYPES[type].title}</Header>
            <ModalContent>
                <EscButton color="close" onClick={hideModal}>
                    <Close />
                    <div>esc</div>
                </EscButton>
                <div className="clearfix" />
                <div className="mt-4 mr-1 text-center">
                    <p>{REPEAT_MODAL_TYPES[type].description}</p>
                </div>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={hideModal} />
                <SuccessButton name="Delete all" onClick={deleteAll} dataCy="button--delete-all" />
                <SuccessButton name="Delete all future" onClick={deleteFuture} dataCy="button--delete-future" />
                <SuccessButton name="Delete this" onClick={deleteOne} dataCy="button--delete-this" />
            </ModalFooter>
        </Modal>
    );
};

RepeatModal.propTypes = {
    type: PropTypes.oneOf(Object.keys(REPEAT_MODAL_TYPES)).isRequired,
    actions: PropTypes.shape({
        deleteAll: PropTypes.func,
        deleteFuture: PropTypes.func,
        deleteOne: PropTypes.func,
    }),
};

export default RepeatModal;
