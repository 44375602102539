import React from 'react';
import { Home } from '@material-ui/icons';
import Link from 'shared/link';
import { Wrapper } from './styles';

export default () => (
    <Wrapper>
        <Link to="/reports">
            <div>
                <Home />
                <span>Home</span>
            </div>
        </Link>
    </Wrapper>
);
