import React from 'react';
import { contains } from 'underscore';
import {
    displayCurrencyValue,
    displayDateState,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import { Header } from './../styles';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendBetween,
                displayCurrencyValue(row, 'externalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Previous Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendPrevious'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendPrevious,
                displayCurrencyValue(row, 'externalReportedSpendPrevious', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Next Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendAfter',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendAfter'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendAfter,
                displayCurrencyValue(row, 'externalReportedSpendAfter', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend To Date
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendToDate,
                displayCurrencyValue(row, 'externalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Future Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendFuture,
                displayCurrencyValue(row, 'externalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        All Time Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.externalReportedSpendAllTime,
                displayCurrencyValue(row, 'externalReportedSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendBetween,
                displayCurrencyValue(row, 'internalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Previous Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendPrevious'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendPrevious,
                displayCurrencyValue(row, 'internalReportedSpendPrevious', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Next Spend ({displayDateState(dateState, false, false)})
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendAfter',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendAfter'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendAfter,
                displayCurrencyValue(row, 'internalReportedSpendAfter', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend To Date
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendToDate,
                displayCurrencyValue(row, 'internalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Future Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendFuture,
                displayCurrencyValue(row, 'internalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        All Time Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.internalReportedSpendAllTime,
                displayCurrencyValue(row, 'internalReportedSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Profitability All Time
                        <p className="sub-title">(reported - ext - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'reportedSpendProfitabilityAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'reportedSpendProfitabilityAllTime'),
        accessor: row =>
            displayValue(
                row.reportedSpendProfitabilityAllTime,
                displayCurrencyValue(row, 'reportedSpendProfitabilityAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Profitability {displayDateState(dateState)}
                        <p className="sub-title">(reported - ext - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'reportedSpendProfitabilityBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedSpendProfitabilityBetween'),
        accessor: row =>
            displayValue(
                row.reportedSpendProfitabilityBetween,
                displayCurrencyValue(row, 'reportedSpendProfitabilityBetween', defaultCurrency)
            ),
    },
];
