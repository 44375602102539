import resourceDayPilotMenu from 'modules/scheduler/config/areas/resource';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RESOURCES } from 'modules/scheduler/enums/schedulerFilterEnum';
import { showProjectModal, showResourceModal } from 'actions/modalActions';
import { updateResource } from 'actions/resourceActions';
import { useAppKeyWords, useHasRights, useProjectAction } from 'hooks';
import { setSchedulerFilterItem } from 'actions/schedulerActions';
import { updateResourceGroup } from 'actions/resourceGroupActions';
import { dispatchRefreshDPResourcesEvent } from '../../scheduler/utils/schedulerUtil';

const rights = [
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasSettingAddEditResources',
    },
];
export const useScheduler = schedulerRef => {
    const dispatch = useDispatch();
    const keyWords = useAppKeyWords();
    const { hasSettingAddEditResources } = useHasRights(rights);
    const { updateProject } = useProjectAction(schedulerRef);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const {
        resourceRoleRights,
        resourceId,
        preferences: { grid: gridPreferences },
    } = useSelector(state => state.account);

    const showResourceModalRequest = useCallback(
        (resourceId, type) =>
            dispatch(
                showResourceModal({
                    onSubmit: data => dispatch(updateResource.request(resourceId, data)),
                    type: type,
                    resourceId: resourceId,
                })
            ),
        [dispatch]
    );

    const showProjectModalRequest = useCallback(
        (type, projectId) =>
            dispatch(showProjectModal({ onSubmit: data => updateProject(projectId, data), type, projectId })),
        [dispatch, updateProject]
    );

    const addFilter = useCallback(
        (filterName, value) =>
            dispatch(
                setSchedulerFilterItem.request({
                    name: filterName,
                    value,
                })
            ),
        [dispatch]
    );

    const resourceContextMenu = useCallback(
        currentGroup =>
            hasSettingAddEditResources &&
            resourceDayPilotMenu(resourceRoleRights, gridPreferences, keyWords, currentGroup, {
                showResourceModal: showResourceModalRequest,
                addFilter: resourceId => addFilter(RESOURCES.filterListProp, resourceId),
                updateResource: (id, data, callback) => dispatch(updateResource.request(id, data, callback)),
                updateResourceGroup: (id, data) =>
                    dispatch(
                        updateResourceGroup.request({
                            id,
                            data,
                            callback: dispatchRefreshDPResourcesEvent,
                        })
                    ),
            }),
        [
            hasSettingAddEditResources,
            dispatch,
            resourceRoleRights,
            gridPreferences,
            addFilter,
            keyWords,
            showResourceModalRequest,
        ]
    );

    return {
        resourceId,
        companySettings,
        resourceContextMenu,
        addFilter,
        accountRoleRights: resourceRoleRights,
        updateProject,
        showResourceModal: showResourceModalRequest,
        showProjectModal: showProjectModalRequest,
        keyWords,
    };
};
