import { createRequestTypes } from 'actions/base';

export const INITIAL = createRequestTypes('INITIAL', true);
export const FULLSCREEN = createRequestTypes('FULLSCREEN');

export const GET_ACCOUNT = createRequestTypes('GET_ACCOUNT', true);
export const LOGIN = createRequestTypes('LOGIN', true);
export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD', true);
export const VALIDATE_OTP = createRequestTypes('VALIDATE_OTP', true);
export const SETUP_MFA = createRequestTypes('SETUP_MFA', true);
export const DISABLE_MFA = createRequestTypes('DISABLE_MFA', true);
export const SHOW_MFA_RECOVERY_CODES = createRequestTypes('SHOW_MFA_RECOVERY_CODES', true);
export const CLEAR_MFA_DATA = 'CLEAR_MFA_DATA';
export const SET_MFA_ERROR = 'SET_MFA_ERROR';
export const CLEAR_MFA_ERROR = 'CLEAR_MFA_ERROR';
export const SHOW_MFA_COMPANY_REMINDER = 'SHOW_MFA_COMPANY_REMINDER';
export const POSTPONE_MFA_COMPANY_REMINDER = 'POSTPONE_MFA_COMPANY_REMINDER';
export const CREATE_NEW_MFA_RECOVERY_CODES = createRequestTypes('CREATE_NEW_MFA_RECOVERY_CODES', true);
export const SETUP_MFA_DATA = createRequestTypes('SETUP_MFA_DATA', true);
export const SEND_OTP_CODE_BY_EMAIL = createRequestTypes('SEND_OTP_CODE_BY_EMAIL', true);
export const SIGNUP = createRequestTypes('SIGNUP', true);
export const LOGOUT = 'LOGOUT';
export const CLEAR_AUTH_ERROR = 'CLEAR_AUTH_ERROR';
export const VALIDATE_OTP_ON_LOGIN = 'VALIDATE_OTP_ON_LOGIN';

export const UPDATE_ACCOUNT = createRequestTypes('UPDATE_ACCOUNT');
export const UPDATE_ACCOUNT_REPORT_SETTINGS = createRequestTypes('UPDATE_ACCOUNT_REPORT_SETTINGS');
export const UPDATE_ACCOUNT_PREFERENCES = createRequestTypes('UPDATE_ACCOUNT_PREFERENCES');
export const CLEAR_SCHEDULER_UPDATE = 'CLEAR_SCHEDULER_UPDATE';
export const UPDATE_LOGGED_IN_RESOURCE = createRequestTypes('UPDATE_LOGGED_IN_RESOURCE');
export const GET_COMPANY_TREE = createRequestTypes('GET_COMPANY_TREE');
export const GET_BOOKINGS = createRequestTypes('GET_BOOKINGS');
export const GET_BOOKING = createRequestTypes('GET_BOOKING');
export const CREATE_BOOKING = createRequestTypes('CREATE_BOOKING');
export const CUT_BOOKING = createRequestTypes('CUT_BOOKING');
export const DUPLICATE_BOOKING = createRequestTypes('DUPLICATE_BOOKING');
export const UPDATE_BOOKING = createRequestTypes('UPDATE_BOOKING');
export const SPLIT_BOOKING = createRequestTypes('SPLIT_BOOKING', true);
export const UPDATE_BOOKINGS = createRequestTypes('UPDATE_BOOKINGS');
export const BULK_CREATE_BOOKINGS = createRequestTypes('BULK_CREATE_BOOKINGS');
export const BULK_MOVE_BOOKINGS = createRequestTypes('BULK_MOVE_BOOKINGS');
export const DELETE_BOOKING = createRequestTypes('DELETE_BOOKING', true);
export const DELETE_BOOKINGS = createRequestTypes('DELETE_BOOKINGS', true);
export const DELETE_ALL_REPEAT_BOOKINGS = createRequestTypes('DELETE_ALL_REPEAT_BOOKINGS', true);
export const DELETE_FUTURE_REPEAT_BOOKINGS = createRequestTypes('DELETE_FUTURE_REPEAT_BOOKINGS', true);
export const REJECT_BOOKING = createRequestTypes('REJECT_BOOKING');
export const GET_COMPANIES = createRequestTypes('GET_COMPANIES');
export const GET_COMPANY = createRequestTypes('GET_COMPANY');
export const GET_COMPANY_APPROVERS = createRequestTypes('GET_COMPANY_APPROVERS', true);
export const GET_COMPANY_BILLING_INFO = createRequestTypes('GET_COMPANY_BILLING_INFO');
export const UPDATE_COMPANY = createRequestTypes('UPDATE_COMPANY');
export const UPDATE_COMPANY_SETTINGS = createRequestTypes('UPDATE_COMPANY_SETTINGS');
export const UPDATE_COMPANY_START_END_TIME = 'UPDATE_COMPANY_START_END_TIME';
export const GET_RESOURCES = createRequestTypes('GET_RESOURCES');
export const GET_CHILDREN_RESOURCES = createRequestTypes('GET_CHILDREN_RESOURCES', true);
export const GET_RESOURCES_STOP = 'GET_RESOURCES_STOP';
export const GET_PROJECT_MANAGERS = createRequestTypes('GET_PROJECT_MANAGERS');
export const GET_RESOURCE = createRequestTypes('GET_RESOURCE');
export const RESET_RESOURCE_VALUE = createRequestTypes('RESET_RESOURCE_VALUE');
export const CREATE_RESOURCE = createRequestTypes('CREATE_RESOURCE');
export const CREATE_MULTIPLE_RESOURCES = createRequestTypes('CREATE_MULTIPLE_RESOURCES');
export const DUPLICATE_RESOURCE = createRequestTypes('DUPLICATE_RESOURCE');
export const UPDATE_RESOURCE = createRequestTypes('UPDATE_RESOURCE', true);
export const DELETE_RESOURCE = createRequestTypes('DELETE_RESOURCE');
export const GET_APPROVEES = createRequestTypes('GET_APPROVEES');
export const ADD_APPROVEES = createRequestTypes('ADD_APPROVEES', true);
export const DELETE_APPROVEES = createRequestTypes('DELETE_APPROVEES', true);
export const ADD_GROUP_APPROVERS = createRequestTypes('ADD_GROUP_APPROVERS', true);
export const REMOVE_GROUP_APPROVERS = createRequestTypes('REMOVE_GROUP_APPROVERS', true);
export const GET_RESOURCE_GROUP_RESOURCES = createRequestTypes('GET_RESOURCE_GROUP_RESOURCES');
export const GET_RESOURCE_GROUPS = createRequestTypes('GET_RESOURCE_GROUPS');
export const GET_RESOURCE_GROUPS_STOP = 'GET_RESOURCE_GROUPS_STOP';
export const CREATE_RESOURCE_GROUP = createRequestTypes('CREATE_RESOURCE_GROUP');
export const UPDATE_RESOURCE_GROUP = createRequestTypes('UPDATE_RESOURCE_GROUP');
export const DELETE_RESOURCE_GROUP = createRequestTypes('DELETE_RESOURCE_GROUP');
export const GET_PROJECTS = createRequestTypes('GET_PROJECTS');
export const GET_PROJECTS_STOP = 'GET_PROJECTS_STOP';
export const CREATE_PROJECT = createRequestTypes('CREATE_PROJECT', true);
export const RESET_PROJECT_VALUE = createRequestTypes('RESET_PROJECT_VALUE');
export const CREATE_MULTIPLE_PROJECTS = createRequestTypes('CREATE_MULTIPLE_PROJECTS');
export const DUPLICATE_PROJECT = createRequestTypes('DUPLICATE_PROJECT');
export const DELETE_PROJECT = createRequestTypes('DELETE_PROJECT');
export const GET_PROJECT = createRequestTypes('GET_PROJECT');
export const UPDATE_PROJECT = createRequestTypes('UPDATE_PROJECT', true);
export const ADD_RESOURCE_TO_PROJECT = createRequestTypes('ADD_RESOURCE_TO_PROJECT', true);
export const GET_PROJECT_GROUP_PROJECTS = createRequestTypes('GET_PROJECT_GROUP_PROJECTS');
export const GET_PROJECT_GROUPS = createRequestTypes('GET_PROJECT_GROUPS');
export const GET_PROJECT_GROUPS_STOP = createRequestTypes('GET_PROJECT_GROUPS');
export const CREATE_PROJECT_GROUP = createRequestTypes('CREATE_PROJECT_GROUP');
export const UPDATE_PROJECT_GROUP = createRequestTypes('UPDATE_PROJECT_GROUP');
export const DELETE_PROJECT_GROUP = createRequestTypes('DELETE_PROJECT_GROUP');
export const GET_CUSTOM_FIELDS = createRequestTypes('GET_CUSTOM_FIELDS', true);
export const GET_CUSTOM_FIELDS_V2 = createRequestTypes('GET_CUSTOM_FIELDS_V2', true);
export const GET_TAGS = createRequestTypes('GET_TAGS');
export const CREATE_TAG = createRequestTypes('CREATE_TAG');
export const CREATE_RATE = createRequestTypes('CREATE_RATE');
export const GET_CUSTOMERS = createRequestTypes('GET_CUSTOMERS');
export const CREATE_CUSTOMER = createRequestTypes('CREATE_CUSTOMER');
export const UPLOAD_THUMB = createRequestTypes('UPLOAD_THUMB');
export const GET_REPORT = createRequestTypes('GET_REPORT', true);
export const CREATE_REPORT = createRequestTypes('CREATE_REPORT');
export const UPDATE_REPORT = createRequestTypes('UPDATE_REPORT');
export const UPDATE_CONTEXT_ROW = createRequestTypes('UPDATE_CONTEXT_ROW');
export const UPDATE_FAVOURITES = createRequestTypes('UPDATE_FAVOURITES');
export const EXPORT_REPORT = createRequestTypes('EXPORT_REPORT');
export const SHARE_REPORT = createRequestTypes('SHARE_REPORT');
export const SHARED_REPORT = createRequestTypes('SHARED_REPORT');
export const GET_REPORT_TEMPLATES = createRequestTypes('GET_REPORT_TEMPLATES');
export const ADD_REPORTS_GROUP = createRequestTypes('ADD_REPORTS_GROUP');
export const UPDATE_REPORTS_GROUP = createRequestTypes('UPDATE_REPORTS_GROUP');
export const DELETE_REPORTS_GROUP = createRequestTypes('DELETE_REPORTS_GROUP');
export const GET_REPORTS_GROUPS = createRequestTypes('GET_REPORTS_GROUPS');
export const DELETE_REPORT = createRequestTypes('DELETE_REPORT');
export const GET_LOGGED_IN_USER = createRequestTypes('GET_LOGGED_IN_USER');
export const REMOVE_REPORT_FROM_GROUP = 'REMOVE_REPORT_FROM_GROUP';
export const UPDATE_REPORT_QUERY_PARAMS = 'UPDATE_REPORT_QUERY_PARAMS';
export const RESET_REPORT_DATA = 'RESET_REPORT_DATA';
export const SEND_SUGGEST_REPORT = 'SEND_SUGGEST_REPORT';
export const CHANGE_VIEW = 'CHANGE_VIEW';
export const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
export const CLOSE_SIDEBAR = 'CLOSE_SIDEBAR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_CURRENT_SELECTION = 'SET_CURRENT_SELECTION';
export const UPDATE_CLIPBOARD = 'UPDATE_CLIPBOARD';
export const UPDATE_BOOKING_SELECTION = 'UPDATE_BOOKING_SELECTION';

export const CHANGE_ACCOUNT_COMPANY_REQUEST = 'CHANGE_ACCOUNT_COMPANY_REQUEST';
export const UPDATE_ACCOUNT_NOTIFICATIONS = createRequestTypes('UPDATE_ACCOUNT_NOTIFICATIONS');

export const UPDATE_STATISTICS = 'UPDATE_STATISTICS';
export const RESET_STATISTICS = 'RESET_STATISTICS';
export const UPDATE_SIDEBAR_STATISTICS = 'UPDATE_SIDEBAR_STATISTICS';
export const UPDATE_REQUEST_MODEL = 'UPDATE_REQUEST_MODEL';

export const APPROVE_REJECT_REQUEST = 'APPROVE_REJECT_REQUEST';
export const REJECT_REQUESTS_SUCCESS = 'REJECT_REQUESTS_SUCCESS';
export const EDIT_RESOURCE_VACATION_REQUEST = 'EDIT_RESOURCE_VACATION_REQUEST';
export const CREATE_VACATION_RESOURCE_REQUEST = 'CREATE_VACATION_RESOURCE_REQUEST';
export const RESET_BOOKING_DATA = 'RESET_BOOKING_DATA';

export const GET_ALL_CUSTOM_FIELDS = createRequestTypes('GET_ALL_CUSTOM_FIELDS', true);

export const SEND_BUG_REQUEST = 'SEND_BUG_REQUEST';
export const SEND_CONTACT_REQUEST = 'SEND_CONTACT_REQUEST';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const HIDE_MODAL_TYPE = 'HIDE_MODAL_TYPE';
export const SHOW_CONTEXT_MENU = 'SHOW_CONTEXT_MENU';
export const HIDE_CONTEXT_MENU = 'HIDE_CONTEXT_MENU';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const CHANGE_TEXT_FILTER = 'CHANGE_TEXT_FILTER';

export const SEND_SUGGESTION_INTEGRATION = 'SEND_SUGGESTION_INTEGRATION';
export const GET_PROJECTS_INTEGRATION = createRequestTypes('GET_PROJECTS_INTEGRATION', true);
export const GET_ACCOUNTS_INTEGRATION = createRequestTypes('GET_ACCOUNTS_INTEGRATION', true);
export const GET_RESOURCES_INTEGRATION = createRequestTypes('GET_RESOURCES_INTEGRATION', true);
export const GET_AUTH_INFORMATION_INTEGRATION = createRequestTypes('GET_AUTH_INFORMATION_INTEGRATION');
export const REMOVE_AUTHORIZATION_INTEGRATION = createRequestTypes('REMOVE_AUTHORIZATION_INTEGRATION');
export const SET_LOADING_STATUS_INTEGRATION = createRequestTypes('SET_LOADING_STATUS_INTEGRATION');
export const UPDATE_NEW_RESOURCES_INTEGRATION = createRequestTypes('UPDATE_NEW_RESOURCES_INTEGRATION');
export const UPDATE_NEW_PROJECTS_INTEGRATION = createRequestTypes('UPDATE_NEW_PROJECTS_INTEGRATION');
export const SET_IMPORTED_STATUS_INTEGRATION = createRequestTypes('SET_IMPORTED_STATUS_INTEGRATION');
export const CREATE_HOLIDAY = createRequestTypes('CREATE_HOLIDAY');
export const UPDATE_HOLIDAY = createRequestTypes('UPDATE_HOLIDAY');
export const DELETE_HOLIDAY = createRequestTypes('DELETE_HOLIDAY');
export const GET_CALENDARS = createRequestTypes('GET_CALENDARS');
export const CREATE_CALENDAR = createRequestTypes('CREATE_CALENDAR');
export const SAVE_CALENDARS = createRequestTypes('SAVE_CALENDARS');
export const GENERATE_CALENDAR_KEY = createRequestTypes('GENERATE_CALENDAR_KEY');
export const IMPORT_COUNTRY_HOLIDAY_REQUEST = 'IMPORT_COUNTRY_HOLIDAY_REQUEST';

export const UPDATE_ACTION_STATE = 'UPDATE_ACTION_STATE';
export const RESET_TO_DEFAULT_BY_PROP = 'RESET_TO_DEFAULT_BY_PROP';
export const GET_BRAINTREE_CLIENT_TOKEN = createRequestTypes('GET_BRAINTREE_CLIENT_TOKEN');
export const GET_BILLING_PLANS = createRequestTypes('GET_BILLING_PLANS');
export const GET_BILLING_EXTENSIONS = createRequestTypes('GET_BILLING_EXTENSIONS');
export const UNSUBSCRIBE_EXTENSION = createRequestTypes('UNSUBSCRIBE_EXTENSION');
export const GET_BILLING_VIEW = createRequestTypes('GET_BILLING_VIEW');
export const VALIDATE_VAT_ID = createRequestTypes('VALIDATE_VAT_ID');
export const PURCHASE = createRequestTypes('PURCHASE');
export const DELETE_SUBSCRIPTION = createRequestTypes('DELETE_SUBSCRIPTION');
export const UPDATE_EXTENSION_SUBSCRIPTION = createRequestTypes('UPDATE_EXTENSION_SUBSCRIPTION');
export const UPDATE_EXTENSION_STATE = createRequestTypes('UPDATE_EXTENSION_STATE');
export const UPDATE_SUBSCRIPTION = createRequestTypes('UPDATE_SUBSCRIPTION');
export const UPDATE_CUSTOMER = createRequestTypes('UPDATE_CUSTOMER');
export const GET_PAYMENT_INFORMATION = createRequestTypes('GET_PAYMENT_INFORMATION');
export const GEO_LOCATION = createRequestTypes('GEO_LOCATION');

export const SAVE_CATEGORY_GROUPS = createRequestTypes('SAVE_CATEGORY_GROUPS', true);
export const GET_CATEGORY_GROUPS = createRequestTypes('GET_CATEGORY_GROUPS', true);
export const GET_CATEGORY_GROUPS_STOP = 'GET_CATEGORY_GROUPS_STOP';
export const GET_CATEGORIES_BY_GROUP = createRequestTypes('GET_CATEGORIES_BY_GROUP');
export const CREATE_CATEGORY_TEMPLATE = createRequestTypes('CREATE_CATEGORY_TEMPLATE');
export const UPDATE_CATEGORY_TEMPLATE = createRequestTypes('UPDATE_CATEGORY_TEMPLATE');
export const DELETE_CATEGORY_TEMPLATE = createRequestTypes('DELETE_CATEGORY_TEMPLATE');
export const GET_CATEGORY_TEMPLATES = createRequestTypes('GET_CATEGORY_TEMPLATES', true);
export const GET_CATEGORY_TEMPLATES_STOP = 'GET_CATEGORY_TEMPLATES_STOP';

export const SET_SCHEDULER_FILTER_ITEM = createRequestTypes('SET_SCHEDULER_FILTER_ITEM');
export const SET_SCHEDULER_FILTER_ITEMS = 'SET_SCHEDULER_FILTER_ITEM';
export const SET_SCHEDULER_FILTER_RELATION = 'SET_SCHEDULER_FILTER_ITEM_RELATION';
export const SET_FILTER_OPERATOR = 'SET_FILTER_OPERATOR';
export const APPLY_BUILDER_DATA = createRequestTypes('APPLY_BUILDER_DATA');
export const REMOVE_FILTER = createRequestTypes('REMOVE_FILTER');
export const REMOVE_FILTERS = 'REMOVE_FILTERS';
export const CLEAR_APPLIED_FILTERS = createRequestTypes('CLEAR_APPLIED_FILTERS');
export const CLEAR_DATASET = createRequestTypes('CLEAR_DATASET');
export const RESET = createRequestTypes('RESET');
export const BUILDER_RESET = createRequestTypes('BUILDER_RESET');
export const GET_DAYPILOT_RESOURCES = createRequestTypes('GET_DAYPILOT_RESOURCES', true);
export const GET_DAYPILOT_UNASSIGNED_RESOURCES = createRequestTypes('GET_DAYPILOT_UNASSIGNED_RESOURCES', true);
export const UPDATE_FINANCIAL = createRequestTypes('UPDATE_FINANCIAL');
export const INITIALIZE_FILTERS = createRequestTypes('INITIALIZE_FILTERS');
export const GET_SCHEDULER_GROUP = createRequestTypes('GET_SCHEDULER_GROUP', true);
export const CLEAR_SCHEDULER_GROUP = 'CLEAR_SCHEDULER_GROUP';
export const INIT_SCHEDULER = 'INIT_SCHEDULER';
export const SCHEDULER_REMOVE_ROW = 'SCHEDULER_REMOVE_ROW';

export const SET_SCHEDULER_BOOKING_LINKS = 'SET_SCHEDULER_BOOKING_LINKS';
export const REMOVE_BOOKING_LINK = 'REMOVE_BOOKING_LINK';
export const CREATE_BOOKING_LINK = createRequestTypes('CREATE_BOOKING_LINK', true);
export const DELETE_BOOKING_LINK = createRequestTypes('DELETE_BOOKING_LINK', true);
export const CREATE_BOOKING_DEADLINE = createRequestTypes('CREATE_BOOKING_DEADLINE', true);
export const UPDATE_BOOKING_DEADLINE = createRequestTypes('UPDATE_BOOKING_DEADLINE', true);
export const UPDATE_BOOKING_DEADLINES = createRequestTypes('UPDATE_BOOKING_DEADLINES', true);
export const DELETE_BOOKING_DEADLINE = createRequestTypes('DELETE_BOOKING_DEADLINE', true);

export const SCHEDULER_COLLAPSE_ROW = 'SCHEDULER_COLLAPSE_ROW';

export const SEND_INVITE_EMAIL = createRequestTypes('SEND_INVITE_EMAIL', true);
export const SEND_INVITE_EMAILS = createRequestTypes('SEND_INVITE_EMAILS', true);
export const SEND_RESET_PASSWORD_EMAIL = createRequestTypes('SEND_RESET_PASSWORD_EMAIL', true);

export const REPORT_CHECK_GROUPS = 'REPORT_CHECK_GROUPS';