import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';

const TemplateBreadcrumb = ({ templateName }) => <span>{templateName}</span>;

export default connect((state, props) => {
    const template = find(
        state.reportReducer.reportTemplates,
        template => template._id === props.match.params.templateId
    );

    return {
        templateName: template ? template.name : 'No template',
    };
})(TemplateBreadcrumb);
