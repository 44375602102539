import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter } from 'lodash';
import { getResourceGroupResources, updateResourceGroup } from 'actions/resourceGroupActions';
import { changeView, initScheduler, setSchedulerSelection } from 'actions/schedulerActions';
import { getResources } from 'actions/resourceActions';
import { getProjects } from 'actions/projectActions';
import { hasRole } from 'utils/rightsUtil';
import { UNASSIGNED } from 'enums/criteriaEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import {
    useScheduler,
    useDaypilotConfig,
    useUnassignedMenu,
    useAccountPreferences,
    useDPResources,
} from 'modules/scheduler/hooks';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { GROUP } from 'enums/groupTypeEnum';
import { getSchedulerResources } from 'modules/scheduler/config/formater';
import Header from 'modules/scheduler/components/header';
import Scheduler from 'modules/scheduler/components/scheduler';
import Footer from 'modules/scheduler/components/footer/footer';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { makeGetResourceFromGroupById } from 'selectors/resourceGroup';
import Loader from 'shared/loader';
import { ClickAwayListener } from '@material-ui/core';
import { useUpdateBookings } from '../../hooks/useUpdateBookings';
import { companyApprovers } from '../../../../actions/companyActions';

const ResourceGroup = ({ match }) => {
    const dispatch = useDispatch();
    const schedulerRef = useRef({});

    const resourceGroup = useSelector(state => state.scheduler.group);
    const unassignedResourceGroup = useSelector(state => state.scheduler.unassignedWorkGroup);
    const currentMenuView = useSelector(state => state.scheduler.currentView);
    const currentSchedulerSelection = useSelector(state => state.scheduler?.currentSelection);
    const validCurrentSchedulerSelection =
        currentSchedulerSelection && Object.values(currentSchedulerSelection || {}).some(value => !!value);

    const viewObject = useMemo(() => getViewObject(match.params, resourceGroup?.criteria), [
        match.params,
        resourceGroup?.criteria,
    ]);

    const {
        accountRoleRights,
        keyWords,
        showResourceModal,
        showProjectModal,
        resourceContextMenu,
        updateProject,
        addFilter,
    } = useScheduler(schedulerRef);
    const {
        gridPreferences: { mode },
    } = useAccountPreferences();

    const viewData = useMemo(
        () => ({ projectId: match.params.projectId, projectGroupId: match.params.projectGroupId }),
        [match.params.projectGroupId, match.params.projectId]
    );

    const { dpResources, isLoading, isSchedulerInitialized, redrawSchedulerFlag } = useDPResources(RESOURCE.name, {
        match,
    });
    const resourcesUpdateRef = useRef({});

    const isParentMode = PARENT.value === mode;
    const resourceSelector = useMemo(() => makeGetResourceFromGroupById(match.params.resourceId), [
        match.params.resourceId,
    ]);
    const resource = useSelector(resourceSelector);

    const updateResourceGroupRequest = useCallback(
        data =>
            dispatch(
                updateResourceGroup.request({
                    id: match.params.resourceGroupId,
                    data,
                    callback: () => {
                        dispatch(initScheduler());
                    },
                })
            ),
        [dispatch, match.params.resourceGroupId]
    );

    //unassigned not used on resource single mode
    const { shouldRenderUnassigned, unassignedContextMenu, unassignedChildContextMenu } = useUnassignedMenu(
        resourceGroup,
        dpResources,
        unassignedResourceGroup.resources,
        {
            updateResourceGroup: updateResourceGroupRequest,
            showResourceModal: showResourceModal,
        },
        viewObject
    );

    useEffect(() => {
        if (
            !resource?._id &&
            viewObject.isSingleResourceView &&
            resourceGroup?._id &&
            resourceGroup?.queryParams?.itemType === RESOURCE.name
        ) {
            dispatch(getResourceGroupResources.request(resourceGroup?._id));
        }
    }, [
        dispatch,
        resource?._id,
        resourceGroup?._id,
        resourceGroup?.queryParams?.itemType,
        viewObject.isSingleResourceView,
    ]);

    useEffect(() => {
        dispatch(getResources.request());
        dispatch(getProjects.request());
        dispatch(companyApprovers.request());
    }, [dispatch]);

    useEffect(() => {
        if (
            isLoading ||
            !isSchedulerInitialized ||
            !redrawSchedulerFlag ||
            (!resource?._id && viewObject.isSingleResourceView)
        ) {
            return;
        }
        const isUnassignedGroup = resourceGroup && UNASSIGNED === resourceGroup?.criteria;
        const resourceItemContextMenu =
            hasRole(accountRoleRights, 'settingAddEditResources') &&
            resourceContextMenu(viewObject.isResourceGroupView && resourceGroup);

        const unassignedResources = dpResources.filter(dpResource => dpResource.type === UNASSIGNED);

        const resources = getSchedulerResources(
            viewObject.isResourceGroupView ||
                (!isUnassignedGroup && viewObject.isSingleResourceView && resource.type !== TYPE_UNASSIGNED.value)
                ? filter(dpResources, resource => resource.type !== TYPE_UNASSIGNED.value)
                : dpResources,
            viewObject.isResourceGroupView ? unassignedResources : [],
            {
                resourceContextMenu: resourceItemContextMenu,
                unassignedContextMenu,
                unassignedChildContextMenu,
            },
            {
                shouldRenderUnassigned: shouldRenderUnassigned || (isUnassignedGroup && viewObject.isResourceGroupView),
                projectKeyWord: keyWords.projectKeyWord,
                projectPluralKeyWord: keyWords.projectPluralKeyWord,
                resourceKeyWord: keyWords.resourceKeyWord,
                loadProjects: isParentMode && !(isUnassignedGroup && viewObject.isResourceGroupView),
                addMenuRow:
                    viewObject.isResourceGroupView &&
                    'USER' === resourceGroup?.groupType &&
                    !resourceGroup?.isSmart &&
                    (!resourceGroup?.type || resourceGroup?.type === GROUP),
                resourceGroup,
                accountRoleRights,
                unassignedResourceGroup,
            }
        );

        resourcesUpdateRef.current = {
            resources,
        };
    }, [
        redrawSchedulerFlag,
        accountRoleRights,
        dpResources,
        isSchedulerInitialized,
        isLoading,
        isParentMode,
        keyWords.projectKeyWord,
        keyWords.projectPluralKeyWord,
        keyWords.resourceKeyWord,
        resource.type,
        resourceContextMenu,
        resourceGroup,
        shouldRenderUnassigned,
        unassignedChildContextMenu,
        unassignedContextMenu,
        viewObject.isResourceGroupView,
        viewObject.isSingleResourceView,
        resource?._id,
        unassignedResourceGroup,
    ]);

    const config = useDaypilotConfig(
        {
            schedulerRef,
            viewObject,
            viewData,
            resourcesUpdateRef,
        },
        {
            updateProject,
            showProjectModal,
            showResourceModal,
            addFilter,
        }
    );

    useEffect(() => {
        currentMenuView !== 'resource' && dispatch(changeView.request('resource'));
    }, [currentMenuView, dispatch]);

    // Fires when start Date changes.
    // in day mode, on refresh goes to wrong date despite start date is correct
    // TODO commenting out for now as this introduces new issue that the date is goiong back to the start when you do something like add a filter.
    // you should check the connection between the date changing and the capacity filter to fix original issue.
    // useEffect(() => {
    //     if (!isLoading && isSchedulerInitialized) {
    //         schedulerRef.current.control.scrollTo(config.startDate);
    //     }
    // }, [config.startDate, isLoading, isSchedulerInitialized]);

    useUpdateBookings({
        group: resourceGroup,
        viewObject,
        schedulerRef,
    });

    const onClickAway = useCallback(() => {
        if (window.notClearSchedulerSelection) {
            window.notClearSchedulerSelection = false;
            return;
        }
        const schedulerContextMenuOpened = document.getElementById('context-menu-scheduler');
        const modalOpened = document.getElementsByClassName('modal-dialog');

        if (!schedulerContextMenuOpened && !modalOpened.length && validCurrentSchedulerSelection) {
            dispatch(setSchedulerSelection.request());
            schedulerRef.current.control.clearSelection();
        }
    }, [dispatch, validCurrentSchedulerSelection]);

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <div>
                <Header
                    ref={schedulerRef}
                    viewObject={viewObject}
                    viewData={viewData}
                    resource={resource}
                    name={
                        viewObject.isSingleResourceView && resource.name
                            ? resource.name
                            : viewObject.isResourceGroupView
                            ? resourceGroup?.name
                            : ''
                    }
                />
                <Scheduler ref={schedulerRef} config={config} />
                {isLoading && <Loader center className="mt-3" />}
                <Footer ref={schedulerRef} />
            </div>
        </ClickAwayListener>
    );
};

export default React.memo(ResourceGroup);
