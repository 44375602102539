import React from 'react';
import { contains } from 'underscore';
import DateFilterBox from 'modules/report/containers/tableFilters/dateFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import {
    displayValue,
    isHeaderDisabled,
    differentTypesHandler,
    dateTypesHandler,
} from 'modules/report/utils/columnHelper';
import { Header } from './../styles';

/**
 * @param {array}  columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = columnsToLoad => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedName',
        sortable: false,
        accessor: row => displayValue(row.unassignedName, differentTypesHandler(row.unassignedName, 'Names')),
        show: contains(columnsToLoad, 'unassignedName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventName',
        sortable: false,
        accessor: row => displayValue(row.eventName, differentTypesHandler(row.eventName, 'Events')),
        show: contains(columnsToLoad, 'eventName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Start Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingStart',
        sortable: false,
        accessor: row =>
            displayValue(row.bookingStart, dateTypesHandler(row.bookingStart, 'YYYY-MM-DD HH:mm', 'Dates')),
        show: contains(columnsToLoad, 'bookingStart'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking End Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingEnd',
        sortable: false,
        accessor: row => displayValue(row.bookingEnd, dateTypesHandler(row.bookingEnd, 'YYYY-MM-DD HH:mm', 'Dates')),
        show: contains(columnsToLoad, 'bookingEnd'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Time Entry Date
                    </Sorting>
                    <DateFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'timeEntryDate',
        sortable: false,
        accessor: row => displayValue(row.timeEntryDate, dateTypesHandler(row.timeEntryDate, 'YYYY-MM-DD', 'Dates')),
        show: contains(columnsToLoad, 'timeEntryDate'),
    },
];
