import { mapObject } from 'underscore';
import moize from 'moize';

export const AVAILABILITY_STATUSES_ENUM = {
    OVER: 'OVER',
    UNDER: 'UNDER',
    ON: 'ON',
    properties: {
        OVER: { name: 'Not available', color: 'rgb(249, 115, 86)' },
        ON: { name: 'Not available', color: 'rgb(249, 115, 86)' },
        UNDER: { name: 'Available', color: 'rgb(10, 214, 169)' },
    },
};

export const statusesList = moize(() => {
    let statuses = [];

    mapObject([AVAILABILITY_STATUSES_ENUM.OVER, AVAILABILITY_STATUSES_ENUM.UNDER], value => {
        statuses.push({
            name: AVAILABILITY_STATUSES_ENUM['properties'][value].name,
            value,
        });
    });

    return statuses;
});
