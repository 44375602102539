import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Typography } from '@material-ui/core';
import { Layout } from '../layout';
import { IconButton } from 'shared/components/iconButton';
import { createCySelector } from '../../../utils/cypressUtil';
import { MinutesToTimeFormat } from '../formats/MinutesToTimeFormat';

const prepareTimes = () => {
    const timeArray = [];
    for (let idx = 0; idx < 25; idx++) {
        timeArray.push({
            label: `${idx}.00`,
            value: idx * 60,
        });
        if (idx !== 24) {
            timeArray.push({
                label: `${idx}.15`,
                value: idx * 60 + 15,
            });
            timeArray.push({
                label: `${idx}.30`,
                value: idx * 60 + 30,
            });
            timeArray.push({
                label: `${idx}.45`,
                value: idx * 60 + 45,
            });
        }
    }

    return timeArray;
};

const data = prepareTimes();

export const calculateHours = (start, end) => {
    return (end - start) / 60;
};

export const calculateMinutes = (start, end) => {
    return end - start;
};

const useSelectStyles = makeStyles({
    root: {
        borderRadius: '10px',
        width: '35px',
    },
});

const useStyles = makeStyles(theme => ({
    textColor: {
        color: theme.palette.grey.hintText,
    },
    icon: {
        cursor: 'pointer',
    },
}));

export const TimeRangePicker = React.memo(props => {
    const { idx, start: startProp, end: endProp, hoursAmount, removeInterval, changeInterval, error } = props;
    const selectClasses = useSelectStyles();
    const classes = useStyles();

    const [start, setStart] = useState(startProp);
    const [end, setEnd] = useState(endProp);

    const name = props.name ?? 'interval';

    const startOptions = useMemo(() => {
        if (startProp % 15 !== 0) {
            const rounded = Math.round(startProp);
            const customOption = {
                value: startProp,
                label: `${Math.floor(rounded / 60)}.${String(rounded % 60).padStart(2, '0')}`,
            };

            const newOptions = data.concat(customOption);

            newOptions.sort((a, b) => a.value - b.value);

            return newOptions;
        }

        return data;
    }, [startProp]);

    const endOptions = useMemo(() => {
        if (endProp % 15 !== 0) {
            const rounded = Math.round(endProp);
            const customOption = {
                value: endProp,
                label: `${Math.floor(rounded / 60)}.${String(rounded % 60).padStart(2, '0')}`,
            };

            const newOptions = data.concat(customOption);

            newOptions.sort((a, b) => a.value - b.value);

            return newOptions;
        }

        return data;
    }, [endProp]);

    return (
        <Layout stack padding="0 0 10px 0">
            <Layout gap="8px" vAlign="center">
                <Select
                    data-cy={`${idx}-from-${createCySelector(name)}`}
                    classes={selectClasses}
                    variant="outlined"
                    MenuProps={{
                        style: { height: '300px' },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        disablePortal: true,
                    }}
                    value={start}
                    onChange={e => {
                        setStart(e.target.value);
                        changeInterval(idx, { start: e.target.value, end: end });
                    }}
                >
                    {startOptions.map(time => (
                        <MenuItem
                            key={time.value}
                            value={time.value}
                            data-cy={`time-range-picker-option-${createCySelector(time.label)}`}
                        >
                            {time.label}
                        </MenuItem>
                    ))}
                </Select>
                <span>to</span>
                <Select
                    data-cy={`${idx}-to-${createCySelector(name)}`}
                    classes={selectClasses}
                    variant="outlined"
                    MenuProps={{
                        style: { height: '300px' },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        disablePortal: true,
                    }}
                    value={end}
                    onChange={e => {
                        setEnd(e.target.value);
                        changeInterval(idx, { start: start, end: e.target.value });
                    }}
                >
                    {endOptions.map(time => (
                        <MenuItem
                            key={time.value}
                            value={time.value}
                            data-cy={`time-range-picker-option-${createCySelector(time.label)}`}
                        >
                            {time.label}
                        </MenuItem>
                    ))}
                </Select>
                <Layout gap="15px" vAlign="center">
                    <IconButton
                        dataCy={`${idx}-delete-${createCySelector(name)}`}
                        icon="trash"
                        className={classes.icon}
                        tooltipText="Delete"
                        onClick={() => removeInterval(idx)}
                    />
                    {hoursAmount && (
                        <span className={classes.textColor} data-cy={`${idx}-label-${createCySelector(name)}`}>
                            <MinutesToTimeFormat minutes={calculateMinutes(start, end)} />
                        </span>
                    )}
                </Layout>
            </Layout>
            {error && typeof error === 'string' && (
                <Typography color="secondary" variant="caption">
                    {error}
                </Typography>
            )}
            {error && Array.isArray(error) && (
                <Typography color="secondary" variant="caption">
                    {error[0]}
                </Typography>
            )}
        </Layout>
    );
});

TimeRangePicker.propTypes = {
    idx: PropTypes.number,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    error: PropTypes.string,
    hoursAmount: PropTypes.bool,
    removeInterval: PropTypes.func.isRequired,
    changeInterval: PropTypes.func.isRequired,
};

TimeRangePicker.defaultProps = {
    hoursAmount: true,
};
