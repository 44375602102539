import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { SanitizeHTML } from './SanitizeHTML';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
    BulletList_icon: {
        color: '#7b61c6',
        marginRight: '10px',
        backgroundColor: '#f4f2fd',
        borderRadius: '50%',
        padding: '3px',
    },
    BulletList_row: {
        padding: '10px 15px',
        display: 'flex',
        fontSize: '14px',
        alignItems: 'center',
    },
    BulletList_rowOdd: {
        backgroundColor: '#F7F7F7',
    },
    BulletList_description: {
        marginBottom: '30px',
        marginTop: '8px',
    },
    BulletList_subTitle: {
        fontSize: '14px',
        color: '#838383',
        fontWeight: 400,
        marginBottom: '30px',
        marginTop: '8px',
    },
    TextAndImageRow_title: {
        fontWeight: 600,
        fontSize: '27px',
        lineHeight: 'normal',
        marginBottom: '10px',
    },
    BulletList_note: {
        marginTop: '20px',
    },
});

export const BulletList = React.memo(props => {
    const { title, description, elements, note, subtitle } = props;
    const classes = useStyles();

    if (!Array.isArray(elements) || elements.length === 0) {
        return null;
    }

    return (
        <div>
            {title && <div className={classes.TextAndImageRow_title}>{title}</div>}
            {subtitle && <SanitizeHTML html={subtitle} className={classes.BulletList_subTitle} />}
            {description && <SanitizeHTML html={description} className={classes.BulletList_description} />}
            {elements.map((element, index) => {
                return (
                    <div className={classes.BulletList_row} key={index}>
                        <ArrowForward size="lg" className={classes.BulletList_icon} />
                        {element}
                    </div>
                );
            })}
            {note && (
                <Alert className={classes.BulletList_note} severity="info">
                    <SanitizeHTML html={note} />
                </Alert>
            )}
        </div>
    );
});

export const bulletListPropTypes = PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    note: PropTypes.string,
    elements: PropTypes.array.isRequired,
});
BulletList.propTypes = bulletListPropTypes;

export default BulletList;
