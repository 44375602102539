import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { CardImg, CardBody } from 'reactstrap';
import { AWS_S3_URL } from 'constants';
import { changeAccountCompany } from 'actions/accountActions';
import { getCompanies } from 'actions/companyActions';
import { Title, AccountList, AccountListItem, AccountListLink, Company, Url } from './styles';
import AuthModeRequirements from 'shared/badge';
import { getCompanyId } from 'selectors/company';

const AccountsList = () => {
    const dispatch = useDispatch();
    const companies = useSelector(state => state.companyReducer.companies);
    const companyId = useSelector(getCompanyId);
    const onCompanyChange = companyId => {
        dispatch(
            changeAccountCompany.request({
                companyId,
                path: window.location.pathname,
            })
        );
    };

    useEffect(() => {
        !companies.length && dispatch(getCompanies.request());
    }, [dispatch, companies]);

    return (
        <>
            <Title>My Accounts</Title>
            {companies.length ? (
                <AccountList>
                    {companies.map(company => (
                        <AccountListItem key={company._id} selected={company._id === companyId}>
                            <AccountListLink
                                onClick={company._id === companyId ? undefined : () => onCompanyChange(company._id)}
                            >
                                <CardImg top src={`${AWS_S3_URL}${company.thumb}`} />
                                <CardBody className="p-0 ml-2">
                                    <Company>{company.name}</Company>
                                    <AuthModeRequirements companyAuthenticationSettings={company.authentication} />
                                    <Url>{company.subdomain}.hubplanner.com</Url>
                                </CardBody>
                            </AccountListLink>
                        </AccountListItem>
                    ))}
                </AccountList>
            ) : null}
            {!companies.length ? (
                <div className="text-center">
                    <img alt="" loading="lazy" src={`${AWS_S3_URL}/img/scheduler_loader.gif`} />
                </div>
            ) : null}
        </>
    );
};

AccountsList.propTypes = {
    onCompanyChange: PropTypes.func,
};

AccountsList.defaultProps = {
    onCompanyChange: null,
};

export default AccountsList;
