import { createSelector } from 'reselect';
import { filter, sortBy } from 'lodash';
import { map, flatten, sortBy as sortByFP, flow } from 'lodash/fp';

const getCalendars = state => state.companyReducer.company.calendars;

export const makeGetSortedCalendars = () =>
    createSelector(getCalendars, calendars => {
        const defaultCalendar = filter(calendars, calendar => 'DEFAULT' === calendar.calendarType);
        const sortedCustomCalendars = sortBy(
            filter(calendars, calendar => 'CUSTOM' === calendar.calendarType),
            customCalendar => customCalendar.calendarName
        );

        return [...defaultCalendar, ...sortedCustomCalendars];
    });

export const makeGetHolidays = calendarIds => {
    return createSelector(getCalendars, calendars => {
        let selectedCalendars = calendars;
        if (calendarIds) {
            selectedCalendars = calendars.filter(calendar => {
                return calendarIds.includes(calendar._id);
            });
        }
        return flow(map('holidays'), flatten, sortByFP('date'))(selectedCalendars);
    });
};
