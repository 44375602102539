import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
    },
    errorMessages,
    false
);
