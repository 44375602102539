/* eslint-env browser */
import { useState, useEffect, useCallback } from 'react';

export const useWindowDimension = () => {
    const [dimensions, setDimensions] = useState({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
    });

    const onResize = useCallback(() => {
        setDimensions({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        });
    }, []);

    useEffect(() => {
        window.addEventListener('resize', onResize, false);

        return () => {
            window.removeEventListener('resize', onResize, false);
        };
    }, [onResize]);

    return dimensions;
};
