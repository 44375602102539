import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HelpOutline, AccountCircle } from '@material-ui/icons';
import { useAppKeyWords, useWindowDimension } from 'hooks';
import Link from 'shared/link';
import {
    Collapse,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
} from 'reactstrap';
import { hasOneOfRoles, hasProjectManagerReportRights } from 'utils/rightsUtil';
import { resetScheduler } from 'actions/schedulerActions';
import { getUrl } from 'utils/browserUtil';
import TrialItem from './trialItem';
import TimesheetItem from './timesheetItem';
import Help from './help';
import Env from './env';
import AddItem from './addItem';
import ReportItem from './reportItem';
import Profile from './profile';
import logo from 'assets/images/Top-left-corner logo.png';
import { NavBarFixed } from './styles';
import { STATUS_TRIAL } from 'enums/companyStatusEnum';
import { selectDefaultVacationEvent } from '../../selectors/project';

const Header = () => {
    const dispatch = useDispatch();

    const [collapsed, setCollapsed] = useState(false);
    const [isHelpMenuOpen, setHelpMenuOpen] = useState(false);
    const [isProfileOpen, setProfileOpen] = useState(false);
    const { windowHeight, windowWidth } = useWindowDimension();
    const company = useSelector(state => state.companyReducer.company);
    const { resourceRoleRights, isProjectManager } = useSelector(state => state.account);
    const defaultVacationEvent = useSelector(selectDefaultVacationEvent);
    const { vacationEventName } = useAppKeyWords();

    const toggleNavbar = () => {
        setCollapsed(prevState => !prevState);
    };

    const toggleProfile = () => {
        setProfileOpen(prevState => !prevState);
    };

    const toggleHelpMenu = () => {
        setHelpMenuOpen(prevState => !prevState);
    };

    const hasReportRights =
        hasOneOfRoles(resourceRoleRights, ['manageReports', 'manageMyReports']) ||
        hasProjectManagerReportRights({ resourceRoleRights, isProjectManager });

    return (
        <>
            <NavBarFixed color="" fixed="top" expand="lg">
                <NavbarBrand href={getUrl(company.subdomain, '8090')} className="text-truncate">
                    <img src={logo} height="30" className="d-inline-block align-top" alt="hubplanner logo" />
                    {company.name}
                </NavbarBrand>
                <Env />
                <NavbarToggler onClick={toggleNavbar} />
                <Collapse isOpen={collapsed} navbar>
                    <Nav className="ml-auto" navbar>
                        {STATUS_TRIAL.value === company.status && (
                            <NavItem>
                                <a
                                    className="nav-link"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://meetings-eu1.hubspot.com/hubplanner/book-a-demo"
                                >
                                    {' '}
                                    <i className="fa fa-tv trial-color" />
                                    <span className="schedule-demo-text">Schedule a private demo</span>
                                </a>
                            </NavItem>
                        )}
                        <AddItem />
                        <TrialItem companyStatus={company.status} trial={company.trial} />
                        <NavItem>
                            <Link
                                className="nav-link"
                                to="/scheduler"
                                onClick={() => dispatch(resetScheduler.request())}
                            >
                                Scheduler
                            </Link>
                        </NavItem>
                        <TimesheetItem />
                        <NavItem className="vacation-event">
                            <Link className="nav-link" to="/requests/my-vacations?subFlow=DASHBOARD&filter=ALL">
                                {defaultVacationEvent?.name ?? vacationEventName}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link className="nav-link" to="/requests/my-resource-requests?subFlow=DASHBOARD&filter=ALL">
                                Requests
                            </Link>
                        </NavItem>
                        <NavItem>
                            <NavLink href="/dashboard">Dashboard</NavLink>
                        </NavItem>
                        <ReportItem />
                        <NavItem>
                            <Link className="nav-link" to="/settings/profile">
                                Settings
                            </Link>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#" onClick={toggleHelpMenu}>
                                <HelpOutline fontSize="small" />
                            </NavLink>
                        </NavItem>
                        <Dropdown nav inNavbar isOpen={isProfileOpen} toggle={toggleProfile}>
                            <DropdownToggle nav>
                                <AccountCircle fontSize="small" />
                            </DropdownToggle>
                            <DropdownMenu right className="rounded-0 p-0">
                                {isProfileOpen && <Profile />}
                            </DropdownMenu>
                        </Dropdown>
                    </Nav>
                </Collapse>
            </NavBarFixed>
            <Help
                windowHeight={windowHeight}
                windowWidth={windowWidth}
                companyStatus={company.status}
                hasReportAccess={hasReportRights}
                onClose={toggleHelpMenu}
                isOpen={isHelpMenuOpen}
            />
        </>
    );
};

export default Header;
