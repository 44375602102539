import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, initialize, reduxForm, submit, getFormValues } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { Loop, EditOutlined, Person } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemText, ListItemIcon } from '@material-ui/core';
import { isString } from 'lodash';
import { useAppKeyWords, useForm, useIsExtensionInstalled, useAllocation, useHasRights } from 'hooks';
import { RESOURCE_REQUEST } from 'enums/extensionShortIdEnum';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { showResourceModal, showConfirmationModal } from 'actions/modalActions';
import { updateResource } from 'actions/resourceActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { makeGetFilteredCategoryTemplates, selectCategoryTemplatesLoaded } from 'selectors/categoryTemplate';
import { makeCategoryGroups, selectCategoryGroupsLoaded } from 'selectors/categoryGroups';
import { validateSchema } from 'utils/schemaUtil';
import { getDefaultCategoryTemplate, getDefaultCategoryByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { roles, TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { formSchema, getDefaultValues, mapFormToCreateRequest } from 'forms/shortBookingForm';
import Allocation from 'shared/allocation/materialAllocation';
import { dateField } from 'shared/formFields';
import Repeat from 'shared/repeat';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import ResourceThumb from 'modules/scheduler/components/resourceThumb';
import { CategoryList } from 'modules/scheduler/components/lists';
import { CategoryForm as NewShortCategory } from 'modules/categoryGroups/components/categoryForm';
import ListItem from 'modules/scheduler/components/lists/listItem';
import { ListItem as ListItemStyle } from 'modules/scheduler/components/lists/styles';
import { SCHEDULED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import ActionItems from './actionItems';
import { checkIsSelectionEmpty, selectTimeRange } from '../../../../utils/schedulerSelectTimeRangeUtil';
import { useMenuBookingPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import { resourceIsNotNonBookable } from 'shared/lib/resources';
import { useSchedulerRef, useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import ImageIcon from 'shared/ImageIcon';
import { SchedulerProjectsList } from '../../../lists/projectList/schedulerProjectsList';
import { SchedulerProjectsListProvider } from '../../../lists/projectList/schedulerProjectsListProvider';
import NewShortProjectOrEvent from '../../shared/shortProjectOrEvent';
import { SchedulerResourcesList } from '../../../lists/resourceList/schedulerResourcesList';
import { SchedulerResourcesListProvider } from '../../../lists/resourceList/schedulerResourcesListProvider';
import NewShortResourceOrUnassigned from '../../shared/shortResourceOrUnassigned';
import { TYPE_EVENT } from '../../../../../../enums/projectEnum';
import { ModernMenuLabel } from '../../shared/styles.js';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { useResourceRequestRoles } from '../../../../services/resourceRequest';
import { put, select, takeLatest } from 'redux-saga/effects';
import { getProjects, projectsLoaded } from '../../../../../../selectors/project';
import { waitFor } from '../../../../../../sagas/helpers/waitFor';
import { action } from '../../../../../../actions/base';
import {
    selectCompanyApprovers,
    selectProjectStatusesMapped,
    selectUseCategoriesAllocation,
    selectWeekDaysSetting,
} from '../../../../../../selectors/company';
import { getFormDefaultApprovers } from '../../../../../request/utils/getFormDefaultApprovers';
import { selectMappedResourceGroups } from '../../../../../../selectors/resourceGroup';
import { companyApprovers } from '../../../../../../actions/companyActions';
import { useMenuResourcePermissions } from 'modules/scheduler/hooks/useMenuPermissions.js';
import { calculateUnavailabilityDays } from './utils/calculateUnavailabilityDays';
import { overrideDatesCheck } from '../../../../../modals/resourceModal/components/AvailabilityOverride/AvailabilityOverrideForm';
import { useCalculateAllocationForCategory } from '../../../../../../shared/allocation/calculateAllocationForCategory';

export const FORM_NAME = 'scheduleForm';

export const useStyles = makeStyles({
    repeatIcon: {
        cursor: 'pointer',
        fontSize: '20px',
        display: 'block',
        margin: 'auto',
        height: '40px',
        '&:hover': {
            opacity: 0.7,
        },
    },
});

const INIT_SCHEDULE_FORM = 'INIT_SCHEDULE_FORM';

const rights = [
    {
        rights: ['settingBookCat'],
        rule: 'one',
        name: 'hasSettingBookCatRight',
    },
    {
        rights: ['settingAddEditResources', 'settingResourceWd'],
        rule: 'all',
        name: 'hasSettingResourceWd',
    },
];

function* handleFormInit(action) {
    const { scale, allocationValues, currentResource, state, projectFromSidebar, currentProject } = action.payload;
    const currentSelection = yield select(state => state.scheduler.currentSelection);

    yield put(
        initialize(FORM_NAME, {
            startDate: currentSelection.start
                ? new Date(currentSelection.start)
                : formSchema.getDefaultValues().startDate,
            endDate: currentSelection.end ? new Date(currentSelection.end) : formSchema.getDefaultValues().endDate,
            ...(scale === CELLDURATION.value ? allocationValues : {}),
        })
    );

    yield waitFor(projectsLoaded);
    yield waitFor(selectCategoryGroupsLoaded);
    yield waitFor(selectCategoryTemplatesLoaded);

    const projects = yield select(getProjects);
    const categoryGroups = yield select(makeCategoryGroups());
    const categoryTemplates = yield select(makeGetFilteredCategoryTemplates());
    const useCategoriesAllocation = yield select(selectUseCategoriesAllocation);

    let projectToInitialize = { ...(projectFromSidebar || {}), ...(currentProject || {}) };

    // try to find the project with defaultCategoryTemplate
    if (!projectToInitialize?.defaultCategoryTemplate) {
        const projectWithMissingInfo = projects.find(p => {
            return p._id === projectToInitialize._id || p._id === projectToInitialize.id;
        });
        projectToInitialize = {
            ...(projectWithMissingInfo || {}),
            ...projectToInitialize,
        };
    }

    let categoryTemplate;
    if (projectToInitialize?.defaultCategoryTemplate) {
        categoryTemplate = getDefaultCategoryByProject(projectToInitialize, categoryTemplates, categoryGroups);
    } else {
        categoryTemplate = getDefaultCategoryTemplate(categoryGroups, categoryTemplates);
    }

    const formDefaultValues = getDefaultValues(
        {
            start: currentSelection.start,
            end: currentSelection.end,
            categoryTemplate,
            state,
            resourceInfo: currentResource,
            project: projectToInitialize,
            useCategoriesAllocation,
        },
        categoryGroups
    );

    const initializeData = Object.assign({}, formDefaultValues, scale === CELLDURATION.value ? allocationValues : {});

    yield put(initialize(FORM_NAME, initializeData));
}

export function* scheduleFormSaga() {
    yield takeLatest(INIT_SCHEDULE_FORM, handleFormInit);
}

const ResourceSingleMode = props => {
    const {
        onClick,
        onClose,
        onPaste,
        onRequestVacation,
        showRequestVacationOption,
        isRequestMode,
        combineScheduleRequest,
        scale,
        height,
        width,
        handleSubmit,
        isDefaultVacationEventSet,
        otherOptionsContentJSX,
        touch,
    } = props;
    const { schedulerRef } = useSchedulerRef();

    const classes = useStyles();
    const dispatch = useDispatch();
    const slideRef = useRef();
    const { gridPreferences } = useAccountPreferences();
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const updateFields = useForm(
        FORM_NAME,
        useCallback(() => slideRef.current && slideRef.current.triggerStart(), [])
    );
    const { canRequestResource } = useResourceRequestRoles();
    const { hasSettingBookCatRight, hasSettingResourceWd } = useHasRights(rights);
    const approvers = useSelector(selectCompanyApprovers);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);

    const formValuesSelector = useMemo(() => getFormValues(FORM_NAME), []);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);
    const { project = {}, resource = {}, category = {}, categoryGroup, startDate, interval } = formValues;

    const canRequestResourceOnProject = useMemo(() => {
        return canRequestResource({
            resource,
            projectOrEvent: project,
        });
    }, [canRequestResource, project, resource]);

    // Validate manually data to show interdependent validation (using useTouchInterdependentFieldsReduxForm hook)
    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
    });

    const isFormInvalid = !validDataForSchema;
    const [categoryName, setCategoryName] = useState('');
    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const projectCategoryGroupsSelector = useMemo(() => makeCategoryGroups(project?.categoryGroups), [
        project?.categoryGroups,
    ]);
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const { state, values: allocationValues } = useAllocation(
        scale === CELLDURATION.value ? currentSelection : undefined
    );

    const rowTags = useMemo(() => currentSelection.rowTags || {}, [currentSelection.rowTags]);
    const { resource: currentResource, project: currentProject } = rowTags;
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);
    const projectCategoryGroups = useSelector(projectCategoryGroupsSelector);
    const categoryTemplates = useSelector(categoryTemplatesSelector);
    const isRequestExtensionsInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const projectStatusesMapped = useSelector(selectProjectStatusesMapped);
    const companyWeekdays = useSelector(selectWeekDaysSetting);

    const {
        checkScheduleRights,
        canRequestResources,
        canRequestOrSchedule,
        hasScheduleRights,
    } = useMenuBookingPermissions(
        isRequestMode,
        combineScheduleRequest,
        !rowTags || Object.keys(rowTags).length === 0
            ? {
                  project,
                  resource,
              }
            : rowTags
    );

    const showItems =
        (isRequestMode && canRequestResources) ||
        (!isRequestMode && (canRequestResources || hasScheduleRights.schedule));

    const hasFullScheduleRights = checkScheduleRights(project, resource);

    useEffect(() => {
        dispatch(companyApprovers.request());
        dispatch(getCategoryTemplates.request());
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    const { content: menuContent } = useSelector(state => state.menuContent);
    const { projects } = useSelector(state => state.projectReducer);
    const currentMenuContent = useMemo(() => menuContent.find(({ active }) => active), [menuContent]);

    const projectFromSidebar = useMemo(() => {
        const currentProjectId = currentMenuContent?.inputProps?.project?._id;
        return projects.find(
            ({ id, _id }) => !!currentProjectId && (_id === currentProjectId || id === currentProjectId)
        );
    }, [projects, currentMenuContent]);

    useEffect(() => {
        if (state) {
            dispatch(
                action(INIT_SCHEDULE_FORM, {
                    scale,
                    allocationValues,
                    currentResource,
                    state,
                    projectFromSidebar,
                    currentProject,
                })
            );
        }
    }, [allocationValues, currentProject, currentResource, dispatch, projectFromSidebar, scale, state]);

    const onSubmit = useCallback(
        values => {
            let defaultApprovers = getFormDefaultApprovers({ approvers, resource, mappedResourceGroups });

            const bookingValues = mapFormToCreateRequest(
                values,
                companyStartEndTimes,
                gridPreferences.defaultScale,
                defaultApprovers
            );
            onClick(bookingValues);
        },
        [approvers, resource, mappedResourceGroups, companyStartEndTimes, gridPreferences.defaultScale, onClick]
    );

    const submitForm = useCallback(
        type => {
            updateFields({
                type: isString(type) ? type : isRequestMode ? WAITING_FOR_APPROVAL.value : SCHEDULED.value,
            });

            setTimeout(() => dispatch(submit(FORM_NAME)));
        },
        [updateFields, isRequestMode, dispatch]
    );

    const { start, end } = currentSelection;

    useEffect(() => {
        const currentSelectionExists = start && end && rowTags;
        if (currentSelectionExists && checkIsSelectionEmpty(schedulerRef)) {
            let resId = (currentResource || currentProject || {})._id;
            if (resId) {
                resId = rowTags.isEventRow ? `eventsRow_${resId}` : resId;
                selectTimeRange(schedulerRef, start, end, resId);
            }
        }
    }, [currentProject, currentResource, end, rowTags, schedulerRef, start]);

    const calculateAllocationForCategory = useCalculateAllocationForCategory();

    const onSelectCategoryList = onSelectCategoryListData => {
        const { percentage, hours, total } = formValues;
        const allocationData = calculateAllocationForCategory({
            percentage,
            hours,
            total,
            category: onSelectCategoryListData?.category,
        });

        updateFields({ ...onSelectCategoryListData, ...allocationData });
    };

    const onSelectProjectEvent = values => {
        if (values.project && values.project.defaultCategoryTemplate) {
            const categoryTemplateFound = categoryTemplates.find(
                categoryTemplate => categoryTemplate._id === values.project.defaultCategoryTemplate
            );
            if (categoryTemplateFound) {
                values.category = categoryTemplateFound;
            }
        }

        onSelectCategoryList(values);
        updateFields(values);
    };

    const slideToRepeatForm = () => {
        slideRef.current.triggerSlide(7);
    };

    const slideToOtherOptions = () => {
        slideRef.current.triggerSlide(8);
    };

    const sliderToMore = () => {
        slideRef.current.triggerSlide(9);
    };

    const { hasCreateResourceRights } = useMenuResourcePermissions();

    const resourceModal = useCallback(() => {
        if (
            !currentResource.hasRightsToResource ||
            !hasCreateResourceRights ||
            !currentResource ||
            (!rowTags.isResourceRow && !rowTags.isEventRow)
        ) {
            return;
        }
        onClose && onClose();
        dispatch(
            showResourceModal({
                onSubmit: data => dispatch(updateResource.request(currentResource._id, data)),
                type: currentResource.type,
                resourceId: currentResource._id,
            })
        );
    }, [hasCreateResourceRights, currentResource, rowTags.isResourceRow, rowTags.isEventRow, onClose, dispatch]);

    // value to subtract from the total height
    const allocationHeight = scale === CELLDURATION.value ? 52 : 0;

    const slideCardItems = [
        {
            id: 1,
            itemElement: (
                <ActionItems
                    divider={canRequestOrSchedule}
                    onPaste={onPaste}
                    ref={schedulerRef}
                    onRequestVacation={onRequestVacation}
                    showRequestVacationOption={showRequestVacationOption}
                    onClose={onClose}
                    isDefaultVacationEventSet={isDefaultVacationEventSet}
                    onOpenOtherOptionsMenu={otherOptionsContentJSX ? slideToOtherOptions : null}
                    onMore={sliderToMore}
                />
            ),
            divider: true,
        },
        {
            id: 2,
            divider: true,
            itemElement: (
                <Row className="m-3 mt-4">
                    <Col xs="5" className="pl-1 pr-0">
                        <Field
                            name="startDate"
                            label="Start"
                            variant="outlined"
                            data-cy="scheduler-booking-modern-menu-start-date"
                            component={dateField}
                            disabled={gridPreferences.defaultScale === CELLDURATION.value}
                            componentDataCyPrefix="scheduler-booking-modern-menu-start-date"
                        />
                    </Col>
                    <Col xs="5" className="pl-1 pr-0 ml-1">
                        <Field
                            name="endDate"
                            label="End"
                            variant="outlined"
                            data-cy="scheduler-booking-modern-menu-end-date"
                            component={dateField}
                            props={{
                                shouldDisableDate: date => {
                                    const current = new Date(date).setHours(0, 0, 0, 0);
                                    const newStartDate = new Date(startDate).setHours(0, 0, 0, 0);
                                    return current < newStartDate;
                                },
                            }}
                            disabled={gridPreferences.defaultScale === CELLDURATION.value}
                            componentDataCyPrefix="scheduler-booking-modern-menu-end-date"
                        />
                    </Col>
                    <Col xs="auto" className="p-0 ml-3">
                        <Loop
                            className={classes.repeatIcon}
                            onClick={slideToRepeatForm}
                            data-cy="scheduler-booking-modern-menu-repeat-icon"
                        />
                    </Col>
                </Row>
            ),
            show: canRequestOrSchedule,
        },
        {
            id: 3,
            itemElement: (
                <>
                    <ModernMenuLabel>Allocation</ModernMenuLabel>
                    <Allocation
                        disableAll={scale === CELLDURATION.value}
                        wrapperClassName="mt-2 p-3"
                        inline={true}
                        onChange={updateFields}
                        allocationDefaultState={state}
                        formName={FORM_NAME}
                    />
                </>
            ),
            // Hide in hour mode
            show: canRequestOrSchedule && scale !== CELLDURATION.value && currentSelection.allDay !== false,
        },
        {
            id: 6,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    data-cy="scheduler-booking-modern-menu-category-selector"
                    {...props}
                    backgroundColor={category.gridColor}
                    primaryText={category.name}
                    secondaryText={categoryGroup && categoryGroup.name}
                    avatarSize={34}
                    wrapperClassName="pt-0"
                >
                    {children}
                </ListItem>
            ),
            show: canRequestOrSchedule,
            slides: [
                {
                    scrollable: false,
                    backTextLabel: ' ',
                    backBtnInline: true,
                    content: (
                        <div className="px-4 py-3">
                            <CategoryList
                                categoryGroups={projectCategoryGroups}
                                onEmptySearch={setCategoryName}
                                onSelect={onSelectCategoryList}
                                height={height - 20 - allocationHeight}
                                selectedIds={category?._id ? [category._id] : []}
                            />
                        </div>
                    ),
                    buttonText: hasSettingBookCatRight
                        ? `Add New Category${categoryName && ` "${categoryName}"`}`
                        : undefined,
                },
                {
                    scrollable: true,
                    content: (
                        <div className="px-4 py-3">
                            <NewShortCategory
                                categoryGroups={projectCategoryGroups}
                                inline={false}
                                inlineAllocation={true}
                                buttonFixed
                                disableGutters
                                colorPickerPosition="right"
                                initialName={categoryName}
                                wrapperClassName=""
                                categories={categoryTemplates}
                                onCreate={({ category, categoryGroup }) => {
                                    slideRef.current && slideRef.current.triggerStart();
                                    updateFields({ category, categoryGroup });
                                    setCategoryName('');
                                }}
                            />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 4,
            itemElement: ({ children, ...props }) => {
                let primaryText;
                let secondaryText;
                if (project?.name) {
                    primaryText = `${project.name}`;
                } else {
                    if (rowTags.isEventRow) {
                        primaryText = `Event`;
                    } else {
                        secondaryText = `No ${projectKeyWord} / Event selected`;
                    }
                }
                if (rowTags.isEventRow) {
                    if (project.name) {
                        secondaryText = 'Event';
                    } else {
                        secondaryText = 'Choose an event';
                    }
                } else {
                    if (project?.status) {
                        secondaryText = `${projectStatusesMapped[project.status]?.display} ${
                            project.type === TYPE_EVENT.value ? 'Event' : projectKeyWord
                        }`;
                    } else {
                        primaryText = `${projectKeyWord} / Event`;
                        secondaryText = `Choose a selection`;
                    }
                }

                return (
                    <ListItem
                        divider
                        data-cy="scheduler-booking-modern-menu-project-selector"
                        icon={<ImageIcon urlString={project.thumb} />}
                        primaryText={primaryText}
                        avatarSize={34}
                        secondaryText={secondaryText}
                        {...props}
                    >
                        {children}
                    </ListItem>
                );
            },
            show: canRequestOrSchedule,
            slides: [
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <SchedulerProjectsList
                                resource={resource}
                                onSelect={onSelectProjectEvent}
                                onlyEvents={rowTags.isEventRow}
                                canRequestResources={showItems && (isRequestMode || combineScheduleRequest)}
                                height={
                                    rowTags.isEventRow ? height + 40 - allocationHeight : height - 20 - allocationHeight
                                }
                                slideRef={slideRef}
                                noDataRefresh
                                selectedIds={project?._id ? [project._id] : []}
                            />
                        </div>
                    ),
                },
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <NewShortProjectOrEvent rowTags={rowTags} onCreate={updateFields} slideRef={slideRef} />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 5,
            itemElement: ({ children, ...props }) => {
                let secondaryText = `Choose ${resourceKeyWord}`;

                if (resource.type === TYPE_UNASSIGNED.value) {
                    secondaryText = TYPE_UNASSIGNED.display;
                } else if (resource.role) {
                    secondaryText = roles[resource.role].display;
                }

                return (
                    <ListItem
                        data-cy="scheduler-booking-modern-menu-resource-selector"
                        icon={<ResourceThumb resource={resource} />}
                        primaryText={resource.name ? resource.name : `No ${resourceKeyWord} selected`}
                        secondaryText={secondaryText}
                        avatarSize={34}
                        {...props}
                    >
                        {children}
                    </ListItem>
                );
            },
            show: canRequestOrSchedule,
            slides: [
                {
                    scrollable: false,
                    content: () => {
                        return (
                            <div className="px-4">
                                <SchedulerResourcesList
                                    project={project?._id ? project : currentProject}
                                    onSelect={updateFields}
                                    canRequestResources={showItems && (isRequestMode || combineScheduleRequest)}
                                    height={height - 20 - allocationHeight}
                                    externalFilterFunction={resourceIsNotNonBookable}
                                    noDataRefresh
                                    slideRef={slideRef}
                                    selectedIds={resource?._id ? [resource._id] : []}
                                />
                            </div>
                        );
                    },
                },
                {
                    scrollable: true,
                    content: (
                        <div className="px-4">
                            <NewShortResourceOrUnassigned onCreate={updateFields} slideRef={slideRef} />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 7,
            slides: [
                {
                    scrollable: true,
                    content: (
                        <>
                            <p className="ml-5 mt-1">
                                <strong>Repeat booking</strong>
                            </p>
                            <div className="pl-3 pr-2">
                                <Repeat
                                    inputFieldDisabled={interval?.value === 'NONE'}
                                    inline={false}
                                    intervalAsDropdown
                                    radioInputName="interval"
                                    textInputName="repeatTimes"
                                    formName={FORM_NAME}
                                />
                            </div>
                        </>
                    ),
                },
            ],
            show: false,
        },
        {
            id: 8,
            slides: [
                {
                    disableBackBtnParent: true,
                    scrollable: true,
                    content: <>{otherOptionsContentJSX && otherOptionsContentJSX()}</>,
                },
            ],
            show: false,
        },
        {
            id: 9,
            slides: [
                {
                    content: (
                        <>
                            {hasSettingResourceWd && resource?.hasRightsToResource && rowTags.isResourceRow && (
                                <ListItemStyle
                                    divider
                                    data-cy="mark-as-unavailable"
                                    disabled={!resource.hasRightsToResource}
                                    onClick={() => {
                                        onClose && onClose();

                                        const canNotAddOverride = overrideDatesCheck(
                                            {
                                                from: currentSelection.start,
                                                to: currentSelection.end,
                                            },
                                            Object.values(resource?.customAvailabilitiesOverridesById || {})
                                        );

                                        if (canNotAddOverride) {
                                            dispatch(
                                                showConfirmationModal(
                                                    () => {
                                                        // do nothing
                                                    },
                                                    'Not Allowed',
                                                    `We are sorry, You cannot mark these dates as unavailable as there is already a custom availability date range set on these dates. Please edit the resource and configure the availability there instead.`,
                                                    {
                                                        withCancel: false,
                                                        confirmButtonText: 'Cancel',
                                                    }
                                                )
                                            );
                                        } else {
                                            dispatch(
                                                updateResource.request(currentResource._id, {
                                                    companySpecific: {
                                                        customAvailabilityOverridesToSave: {
                                                            create: calculateUnavailabilityDays(
                                                                currentSelection,
                                                                scale === CELLDURATION.value,
                                                                companyWeekdays,
                                                                currentResource
                                                            ),
                                                        },
                                                    },
                                                })
                                            );
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <EditOutlined />
                                    </ListItemIcon>
                                    <ListItemText primary="Mark as unavailable" />
                                </ListItemStyle>
                            )}
                            {(rowTags.isResourceRow ||
                                rowTags.unassignedParentRow ||
                                rowTags.menuRow ||
                                rowTags.isEventRow) && (
                                <ListItemStyle
                                    divider
                                    disabled={
                                        !currentResource.hasRightsToResource ||
                                        !hasCreateResourceRights ||
                                        !currentResource ||
                                        (!rowTags.isResourceRow && !rowTags.isEventRow)
                                    }
                                    onClick={resourceModal}
                                    data-cy={`button--edit-${rowTags.unassignedRow ? 'unassigned' : 'resource'}`}
                                >
                                    <ListItemIcon>
                                        <Person />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`Edit ${
                                            rowTags.unassignedRow ? 'unassigned' : resourceKeyWord.toLowerCase()
                                        }`}
                                    />
                                </ListItemStyle>
                            )}
                        </>
                    ),
                },
            ],
        },
    ];
    const actionBtnDisabled =
        isFormInvalid || (isRequestMode ? !showItems : !hasScheduleRights.schedule || !hasFullScheduleRights);

    // reduce height when items and btn is hidden
    const slideCardHeight = (!canRequestOrSchedule ? 105 : height) - allocationHeight;

    const slideCardAdditionalAction =
        combineScheduleRequest && canRequestResources && isRequestExtensionsInstalled
            ? () => submitForm(WAITING_FOR_APPROVAL.value)
            : null;

    return (
        <SchedulerResourcesListProvider>
            <SchedulerProjectsListProvider onlyEvents={rowTags.isEventRow}>
                <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                    <SlideCard
                        ref={slideRef}
                        onClick={submitForm}
                        additionalAction={slideCardAdditionalAction}
                        actionBtnText={isRequestMode ? 'Request' : 'Schedule'}
                        additionalActionBtnText="Request"
                        showActionBtn={canRequestOrSchedule}
                        additionalActionBtnDisabled={isFormInvalid || !showItems || !canRequestResourceOnProject}
                        actionBtnDisabled={actionBtnDisabled}
                        height={slideCardHeight}
                        width={width}
                        items={slideCardItems}
                        listenForChildsEvents
                    />
                </Form>
            </SchedulerProjectsListProvider>
        </SchedulerResourcesListProvider>
    );
};

const propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onPaste: PropTypes.func.isRequired,
    onRequestVacation: PropTypes.func.isRequired,
    showRequestVacationOption: PropTypes.bool.isRequired,
    combineScheduleRequest: PropTypes.bool.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    scale: PropTypes.string.isRequired,
    onClose: PropTypes.func,
    isRequestMode: PropTypes.bool,
    isDefaultVacationEventSet: PropTypes.bool,
};

const defaultProps = {
    isRequestMode: false,
    onClose: null,
};

ResourceSingleMode.propTypes = propTypes;
ResourceSingleMode.defaultProps = defaultProps;

const WithForm = reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(ResourceSingleMode);

export default React.memo(WithForm);
