module.exports = {
    YEAR_MONTH_DAY_FORMAT: 'yyyy-MM-dd',
    YEAR_MONTH_DAY_OLD_FORMAT: 'YYYY-MM-DD',
    READABLE_FORMAT: 'EEE do MMM',
    READABLE_FORMAT_MONTH_NUMBER_YEAR: 'MMM do yyyy',
    EXPORT_FORMAT: 'MMM do yyyy HHmmss',
    DAY_MONTH_YEAR_DOTTED_FORMAT: 'dd.MM.yyyy',
    BOOKING_FORMAT: 'yyyy-MM-dd HH:mm:ss',
    BOOKING_OLD_FORMAT: 'YYYY-MM-DDTHH:mm',
    BOOKING_OLD_UTC_FORMAT: 'YYYY-MM-DDTHH:mmz',
    CUSTOM_AVAILABILITY_OVERRIDES: 'MMM d, yyyy',
    FNS_BACKEND_FORMAT: `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`,
};
