import React, { useMemo } from 'react';
import { OPERATORS } from '../../enums/operatorEnum';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const IMG_PATH = 'https://hubplanner-static.s3.eu-west-1.amazonaws.com/img/';

const useStyles = makeStyles(() => ({
    image: {
        display: 'block',
        '@media (max-height: 970px)': {
            display: 'none',
        },
    },
}));

const CapacityFinderImg = props => {
    const { value, operator, isAvailabilityCase, variant } = props;

    const styles = useStyles();

    const src = useMemo(() => {
        const eq = OPERATORS[0].value;
        const lt = OPERATORS[2].value;
        const gt = OPERATORS[3].value;
        const lte = OPERATORS[4].value;
        const gte = OPERATORS[5].value;

        const imgVariantName = variant === 'scheduler' ? 'Scheduler' : 'Builder';

        if (isAvailabilityCase || !variant) {
            return `${IMG_PATH}${imgVariantName}_greater_equal_0-99.jpg`;
        }

        if (value < 100) {
            if ([eq, lt, lte].includes(operator)) {
                return `${IMG_PATH}${imgVariantName}_lesser_than_100.jpg`;
            }
            if ([gt, gte].includes(operator)) {
                return `${IMG_PATH}${imgVariantName}_greater_equal_0-99.jpg`;
            }
        } else if (value === 100) {
            if (operator === eq) {
                return `${IMG_PATH}${imgVariantName}_equals_100.jpg`;
            }
            if (operator === lt) {
                return `${IMG_PATH}${imgVariantName}_lesser_than_100.jpg`;
            }
            if (operator === lte) {
                return `${IMG_PATH}${imgVariantName}_lesser_equal_100.jpg`;
            }
            if (operator === gt) {
                return `${IMG_PATH}${imgVariantName}_greater_than_100.jpg`;
            }
            if (operator === gte) {
                return `${IMG_PATH}${imgVariantName}_greater_equal_100.jpg`;
            }
        } else {
            if ([lt, lte].includes(operator)) {
                return `${IMG_PATH}${imgVariantName}_greater_equal_100.jpg`;
            }
            if ([eq, gt, gte].includes(operator)) {
                return `${IMG_PATH}${imgVariantName}_greater_than_100.jpg`;
            }
        }

        return `${IMG_PATH}Scheduler_equals_100.jpg`;
    }, [variant, isAvailabilityCase, value, operator]);

    return <img className={classnames(styles.image, 'text-center m-auto')} src={src} />;
};

const memoized = React.memo(CapacityFinderImg);

export { memoized as CapacityFinderImg };
