import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    paper: {
        borderRadius: '4px',
        minWidth: '150px',
        padding: '5px 0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
        zIndex: 9,
    },
    list: {
        padding: 0,
    },
});
