class EventMovingCache {
    _events = {};

    findEvent(scheduler, eventId) {
        const fromCache = this._events[eventId];

        if (fromCache) {
            return fromCache;
        }

        const event = scheduler.events.find(eventId);
        this._events[eventId] = event;
        return event;
    }

    clear(scheduler) {
        this._events = {};
        scheduler.multiselect.clear();
    }
}

export const eventMovingCache = new EventMovingCache();

const onEventMoving = ({
    isDependencyExtensionInstalled,
    isDeadlinesExtensionInstalled,
    shouldMoveDeadline,
}) => args => {
    if (!isDependencyExtensionInstalled && !isDeadlinesExtensionInstalled) {
        return;
    }

    const scheduler = args.control;

    scheduler.multiselect.clear();

    const addDeadlineToMultiMove = event => {
        if (!isDeadlinesExtensionInstalled || !shouldMoveDeadline) {
            return;
        }

        const deadlineEvent = scheduler.events.find(`${event.data.id}_deadline`);

        if (deadlineEvent) {
            scheduler.multiselect.add(deadlineEvent);
        }
    };

    const addDependenciesEventsToMultiMove = event => {
        if (!event?.data?.childIds?.length) {
            return;
        }

        event.data.childIds.forEach(childId => {
            const dpEvent = eventMovingCache.findEvent(scheduler, childId);
            if (dpEvent && !scheduler.multiselect.isSelected(dpEvent)) {
                scheduler.multiselect.add(dpEvent);
                addDeadlineToMultiMove(dpEvent);
                addDependenciesEventsToMultiMove(dpEvent);
            }
        });
    };

    args.multimove.forEach(multimoveEntry => {
        if (multimoveEntry && !scheduler.multiselect.isSelected(multimoveEntry.event)) {
            addDeadlineToMultiMove(multimoveEntry.event);
            scheduler.multiselect.add(multimoveEntry.event);
        }
        if (isDeadlinesExtensionInstalled) {
            addDependenciesEventsToMultiMove(multimoveEntry.event);
        }
    });
};

export default onEventMoving;
