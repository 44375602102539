export const NO_VACATION_EXT = {
    title: 'Extension - Vacation Requests',
    text:
        'A great Hub Planner extension called Vacation Requests allows you to manage all vacation requests within your business. Monitor how many days people have remaining and let the team track and manage their own vacations.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://d329beqc2zk6g7.cloudfront.net/img/extensions/request_extension_image.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_RESOURCE_EXT = {
    title: 'Extension - Resource Requests',
    text:
        'Create Approvers in your Hub Planner account and allow your team to request Resources to work on Projects in the Scheduler. Manage that complete process from start to finish including email notifications.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://d329beqc2zk6g7.cloudfront.net/img/extensions/request_extension_image.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_AUDIT_LOG_EXT = {
    title: 'Extension - Audit Log',
    text:
        'You require the Audit Log extension to use this feature. Audit logs are a great way to keep track of who did what and what happened in your system for the past 90 days.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/extensions/hp_audit_ext_thumb.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_MFA_EXT = {
    title: 'Extension - Multi-factor Authentication (MFA)',
    text:
        'You require the Multi-factor Authentication (MFA) extension to use this feature. It is an authentication method that requires the user to provide two or more verification factors to gain access to application.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_VACATION_ID = {
    title: 'Please Set Default Vacation Event',
    text:
        'In order to use the vacation request flow you need to first assign an event to represent a vacation or annual leave day for your team. Once this event is chosen you can then begin to use the dashboard and manage requests',
    settingsButton: true,
    btnText: 'Set Vacation Event',
    video: false,
    url: '/settings#vacations',
};

export const NO_VACATION_PAGE_RIGHTS = {
    title: 'You have no permission to access Vacation',
    text: 'Please ask your admin to review your role rights under settings.',
    settingsButton: false,
    url: '/settings#vacations',
};

export const NO_RESOURCE_PAGE_RIGHTS = {
    title: 'You have no permissions to access Resource Requests',
    text: 'Please ask your admin to review your role rights under settings.',
    settingsButton: false,
    url: '/settings#vacations',
};

export const NO_HOLIDAY_CALENDARS_EXT = {
    title: 'Extension - Global Public Holiday Calendars',
    text:
        'Create, Assign and Import Multiple Calendars to Hub Planner. Ideal for Global Teams. Map out Project Schedule and Resource Teams Availability and avoid logistic challenges. Easily Manage and Track Diverse International Teams with Hub Planner’s Global Public Holiday Extension. Embrace regional and country cultural customs and patterns to avoid and minimize workflow disruption. Automatically integrates and syncs with the Hub Planner Scheduler, Vacation Dashboard and Reporting functionalities. Multiple editing and management option to align with company policies on a global scale.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://d329beqc2zk6g7.cloudfront.net/img/extensions/request_extension_image.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_CATEGORY_GROUPS_EXT = {
    title: 'Extension - Booking Category Groups',
    text:
        'Maximise the potential of Booking Categories with the category groups Premium Extension. Unlock the ability to create multiple groups of Booking Categories. Set and assign selected Booking Category Groups to specific Projects. Easily manage, customize, edit and adjust Booking Category Groups.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/extensions/BOOKING_CATEGORY_GROUPS.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_FIXED_COSTS_CATEGORY_GROUPS_EXT = {
    title: 'Extension - Fixed Cost Category',
    text:
        'Maximise the potential of Booking Categories with the category groups Premium Extension. Unlock the ability to create multiple groups of Booking Categories. Set and assign selected Booking Category Groups to specific Projects. Easily manage, customize, edit and adjust Booking Category Groups.',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/extensions/BOOKING_CATEGORY_GROUPS.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_DEPENDENCIES_EXT = {
    title: 'Extension - Dependencies',
    text:
        'Lorem ipsum',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/extensions/BOOKING_CATEGORY_GROUPS.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_DEADLINES_EXT = {
    title: 'Extension - Deadlines',
    text:
        'Lorem ipsum',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/extensions/BOOKING_CATEGORY_GROUPS.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_TOIL_EXT = {
    title: 'Extension - Time off in lieu',
    text:
        'Lorem ipsum',
    settingsButton: true,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: 'https://hubplanner-static.s3.eu-west-1.amazonaws.com/img/fixed_costs_extension.png',
    videoSrc: '',
    videoPosterSrc: '',
    url: '/settings#extensions',
};

export const NO_CUSTOM_COLUMNS_EXT = {
    title: 'Extension - Scheduler Custom Columns',
    text:
        'Introducing a powerful Hub Planner extension called Scheduler Custom Columns. Create dynamic columns based on different criteria such as Custom Fields, Roles and then sort your rows by these columns.',
    settingsButton: false,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: '',
    videoSrc: 'https://d329beqc2zk6g7.cloudfront.net/video/hub_planner_custom_columns_video.mp4',
    videoPosterSrc: 'https://d329beqc2zk6g7.cloudfront.net/img/extensions/video_freeze_frame.jpg',
    url: '/settings#extensions',
};

export const NO_SMART_SCHEDULES_EXT = {
    title: 'Extension - Scheduler Smart Schedules',
    text: 'Introducing a powerful Hub Planner extension called Scheduler Smart Schedules.',
    settingsButton: false,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: '',
    url: '/settings#extensions',
};

export const NO_SMART_FILTERS_EXT = {
    title: 'Extension - Capacity Finder',
    text: 'Introducing a powerful Hub Planner extension called Capacity Finder.',
    settingsButton: false,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: '',
    url: '/settings#extensions',
};

export const NO_UNASSIGNED_EXT = {
    title: 'Extension - Unassigned Work',
    text: 'Introducing a powerful Hub Planner extension called Unassigned Work',
    settingsButton: false,
    btnText: 'Try Now',
    video: false,
    posterImageSrc: '',
    url: '/settings#extensions',
};
