import styled from 'styled-components';
import { Button, ModalBody } from 'reactstrap';

export const EscButton = styled(Button)`
    position: absolute;
    top: -20px;
    right: -20px;
`;

export const ModalContent = styled(ModalBody)`
    min-height: 140px;
    max-height: 400px;
    padding: 0 !important;
    margin: 20px 20px 0;
`;

export const Header = styled.header`
    background-color: #191919;
    height: 40px;
    color: #fff;
    padding: 6px 10px;
    font-size: 16px;
`;
