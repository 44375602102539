import React, { useEffect, useRef } from 'react';
import { some } from 'lodash';
import SchedulerLayout from 'modules/scheduler/template/template';
import authorization from 'services/auth/authorization';
import PermissionDenied from 'modules/scheduler/components/permissionDenied/permissionDenied';
import NotFound from 'modules/scheduler/components/notFound/notFound';
import ResourceGroup from './components/resourceGroup';
import ProjectGroup from './components/projectGroup';
import { useSchedulerLink } from '../../hooks/useSchedulerLink';
import { Redirect } from 'react-router-dom';
import { getResourceGroups } from '../../actions/resourceGroupActions';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectGroups } from '../../actions/projectGroupActions';
import { isProjectGroupsLoading as isProjectGroupsLoadingSelector } from '../../selectors/projectGroup';
import { isResourceGroupsLoading as isResourceGroupsLoadingSelector } from '../../selectors/resourceGroup';
import InitialLoading from '../../shared/initialLoading';

const authParameters = {
    allowedRoleRights: [],
    errorComponent: PermissionDenied,
    notFoundComponent: NotFound,
    routeParamsChecker: (params, data) => {
        let isAvailable = true;

        if (params.resourceGroupId && !data.isResourceGroupsLoading && data.resourceGroups.length) {
            isAvailable = some(data.resourceGroups, group => group._id === params.resourceGroupId);
        }
        if (params.resourceId && !data.isResourcesLoading && data.resources.length) {
            isAvailable = some(
                data.resources,
                resource => resource._id === params.resourceId && resource.hasRightsToResource
            );
        }
        if (params.projectGroupId && !data.isProjectGroupsLoading && data.projectGroups.length) {
            isAvailable = some(data.projectGroups, group => group._id === params.projectGroupId);
        }
        if (params.projectId && !data.isProjectsLoading && data.projects.length) {
            isAvailable =
                some(data.projects, project => project._id === params.projectId && project.hasRightsToProject) ||
                data.availableMyProjects?.[params.projectId];
        }

        return isAvailable;
    },
};

const SchedulerRedirect = () => {
    const dispatch = useDispatch();
    const firstRender = useRef(true);

    const url = useSchedulerLink();

    const isProjectGroupsLoading = useSelector(isProjectGroupsLoadingSelector);
    const isResourceGroupsLoading = useSelector(isResourceGroupsLoadingSelector);

    useEffect(() => {
        dispatch(getResourceGroups.request());
        dispatch(getProjectGroups.request());
    }, [dispatch]);

    if (isProjectGroupsLoading || isResourceGroupsLoading || firstRender.current) {
        firstRender.current = false;
        return <InitialLoading />;
    }

    return <Redirect to={url} />;
};

const ResourceGroupAuth = authorization(authParameters)(ResourceGroup);
const ProjectGroupAuth = authorization(authParameters)(ProjectGroup);

export default [
    {
        path: '/',
        layout: SchedulerLayout,
        title: 'HUB Planner',
        child: [
            {
                path: '/resource/:resourceId?/group/:resourceGroupId',
                component: ResourceGroupAuth,
            },
            {
                path: '/project/:projectId?/group/:projectGroupId',
                title: 'HUB Planner',
                component: ProjectGroupAuth,
            },
            {
                path: '/scheduler',
                component: SchedulerRedirect,
            },
        ],
    },
];
