import styled from 'styled-components';

export default styled.span`
    padding: 15px;
    display: block;
    text-align: center;
    background: linear-gradient(60deg, #26c6da, #00acc1);
    box-shadow: 0 12px 20px -10px rgba(0, 188, 212, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12),
        0 7px 8px -5px rgba(0, 188, 212, 0.2);
    border-radius: 50%;
    margin: auto;
    height: 100px;
    width: 100px;
    color: #fff;
    line-height: 70px;
    svg {
        font-size: 50px;
    }
`;
