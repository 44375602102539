import { DAY } from 'modules/scheduler/enums/scale';
import { formatNumber } from 'utils/formatingUtil';
import { getCellUtilizationMinutes } from 'modules/scheduler/utils/schedulerUtil';

export default (args, data, resourceCapacity) => {
    const scheduler = args.cell.calendar;
    const bookedMinutesCell = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data);
    let bookedHours = formatNumber(bookedMinutesCell / 60);

    if (0 === bookedHours) {
        bookedHours = '';
    }

    if ((scheduler.scale === DAY.value && (!data.isWorkday || data.isHoliday)) || data.isHoliday) {
        bookedHours = '-';
    }

    const style = !data.isHoliday ? 'background-color: #fbfbfb;' : '';
    args.cell.html = `<div style="${style}" title="${bookedHours} hrs Unassigned for this day" class="unassigned-holder">${bookedHours}</div>`;
};
