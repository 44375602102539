import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import InfoBar from './InfoBar';
import { store } from '../../store';
import { deleteResource } from 'actions/resourceActions';

export const uwStepKey = 'uw';

const uwStepRowElements = [
    {
        name: 'name',
        type: 'text',
        label: 'Demand category',
        placeholders: [
            'Engineering work',
            'Design work',
            'Marketing strategy',
            'Sales pipeline',
            'Backlog tasks',
            'Priority tasks',
            'Admin forecast',
        ],
        validator: emptyStringValidator,
        minWidth: '250px',
    },
];

export const UnassignedWorkOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteResource.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    return (
        <div>
            <div className="mb-4">
                <InfoBar type="info">
                    The demand categories can be added later from the Hub Planner Scheduler level.
                </InfoBar>
            </div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={uwStepRowElements}
                getValue={key => storedData[`${uwStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreated}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${uwStepKey}-meta-showValidationErrors`]}
                addMoreLabel="more categories"
                objectName="category"
            />
            {storedData[`${uwStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default UnassignedWorkOnboardingStepContent;
