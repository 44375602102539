import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { OPERATORS } from 'enums/operatorEnum';
import { Slider } from './styles';
import { MenuItem, TextField, Avatar, Grid, Tooltip } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        input: {
            height: '37px',
        },
        title: {
            fontFamily: `'Poppins', 'Helvetica Neue', Helvetica, Arial, sans-serif`,
            color: '#3e3e3e',
            fontSize: '15px',
            fontWeight: 500,
        },
        sliderContainer: {
            padding: props => (props.isBuilderVariant ? '' : '0 3rem !important'),
            marginTop: '20px',
        },
        avatar: {
            width: 32,
            height: 32,
            margin: 'auto',
            fontSize: 23,
            backgroundColor: '#E6E7ED',
            color: '#4E5252',
            fontFamily: 'Arial, sans-serif',
        },
    };
});

const StyledSlider = withStyles(() => {
    return {
        root: {
            color: ({ value, isAvailabilityCase }) => {
                if (isAvailabilityCase) {
                    return '#4E5252';
                } else if (value > 100) {
                    return '#DD2F17';
                } else if (value === 100) {
                    return '#32A675';
                } else if (value < 100) {
                    return '#408EFF';
                }
                return '#4E5252';
            },
        },
        track: {
            height: 4,
        },
        rail: {
            height: 4,
            opacity: 0.5,
            backgroundColor: '#bfbfbf',
        },
        mark: {
            backgroundColor: '#bfbfbf',
            height: 8,
            width: 1,
            marginTop: -3,
        },
        thumb: {
            height: '12px !important',
            width: '12px !important',
        },
    };
})(Slider);

function transformScaledValueToValue(scaledValue, marks) {
    for (let i = 0; i < marks.length - 1; i++) {
        const currentMark = marks[i];
        const nextMark = marks[i + 1];

        if (scaledValue >= currentMark.scaledValue && scaledValue <= nextMark.scaledValue) {
            const proportion =
                (scaledValue - currentMark.scaledValue) / (nextMark.scaledValue - currentMark.scaledValue);
            return currentMark.value + proportion * (nextMark.value - currentMark.value);
        }
    }

    if (scaledValue < marks[0].scaledValue) {
        return marks[0].value;
    }
    if (scaledValue > marks[marks.length - 1].scaledValue) {
        return marks[marks.length - 1].value;
    }
}

function transformValueToScaledValue(value, marks) {
    for (let i = 0; i < marks.length - 1; i++) {
        const currentMark = marks[i];
        const nextMark = marks[i + 1];

        if (value >= currentMark.value && value <= nextMark.value) {
            const proportion = (value - currentMark.value) / (nextMark.value - currentMark.value);
            return currentMark.scaledValue + proportion * (nextMark.scaledValue - currentMark.scaledValue);
        }
    }

    if (value < marks[0].value) {
        return marks[0].scaledValue;
    }
    if (value > marks[marks.length - 1].value) {
        return marks[marks.length - 1].scaledValue;
    }
}

const SliderFilter = props => {
    const { defaultValues, onChange, marks, title, isAvailabilityCase, disabled, min, max, variant } = props;

    const isBuilderVariant = variant === 'builder';
    const styles = useStyles({ isBuilderVariant });

    const [availabilityValue, setAvailabilityValue] = useState(
        defaultValues.value !== '' || defaultValues.value === 0 ? defaultValues.value : 100
    );

    const [sliderValue, setSliderValue] = useState(
        defaultValues.value !== '' || defaultValues.value === 0
            ? transformScaledValueToValue(defaultValues.value, marks)
            : 100
    );

    const numFormatter = num => {
        if (num > 999) {
            return (num / 1000).toFixed(0) + 'K';
        } else {
            return num; // if value < 1000, nothing to do
        }
    };

    const handleOperatorSelect = useCallback(
        event => {
            const { value } = event.target;
            onChange && onChange({ operator: value });
        },
        [onChange]
    );

    const setValue = useCallback(
        value => {
            if (onChange) {
                // sending scaled
                onChange({ value });
            }
        },
        [onChange]
    );

    const handleSliderChange = useCallback(
        (event, newValue) => {
            const newAvailabilityValue = transformValueToScaledValue(newValue, marks);

            setValue(newAvailabilityValue);
            setSliderValue(newValue);
            setAvailabilityValue(newAvailabilityValue);
        },
        [setValue, marks]
    );

    const handleInputChange = useCallback(
        event => {
            const sliderValue = transformScaledValueToValue(
                '' === event.target.value ? 0 : Number(event.target.value),
                marks
            );
            const newValue = '' === event.target.value ? '' : Number(event.target.value);

            setValue('' === event.target.value ? '' : Number(event.target.value));
            setSliderValue(sliderValue);
            setAvailabilityValue(newValue);
        },
        [setValue, marks]
    );

    const valuetext = useCallback(value => {
        return `${value}%`;
    }, []);

    const selectedOperator = useMemo(() => {
        return OPERATORS.find(option => option.value === defaultValues.operator);
    }, [defaultValues.operator]);

    useEffect(() => {
        const { value, operator } = defaultValues || {};

        if (value !== undefined && typeof value === 'number' && operator) {
            setSliderValue(transformScaledValueToValue(value, marks));
            setAvailabilityValue(value);
        } else {
            setSliderValue(0);
            setAvailabilityValue(0);
        }
    }, [defaultValues, marks]);

    return (
        <div className={isBuilderVariant ? '' : 'mb-4 mt-2'}>
            <Grid container spacing={1} alignItems="center" justifyContent="center">
                <Grid item xs={4}>
                    <Tooltip
                        placement="bottom"
                        title="Turn on the capacity finder in the top right corner to enable."
                        arrow
                        disableHoverListener={!disabled}
                        disableFocusListener={!disabled}
                        disableTouchListener={!disabled}
                    >
                        <TextField
                            select
                            fullWidth
                            defaultValue={defaultValues.operator ? defaultValues.operator : 'eq'}
                            value={defaultValues.operator ? defaultValues.operator : 'eq'}
                            variant="outlined"
                            label={title}
                            InputProps={{
                                className: styles.input,
                            }}
                            SelectProps={{
                                MenuProps: {
                                    'data-in-portal': 'true',
                                },
                            }}
                            data-cy="operator-dropdown-capacity-filter"
                            onChange={handleOperatorSelect}
                            disabled={disabled}
                        >
                            {OPERATORS.map(option => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    data-cy={`operator-drop-down--${option.value}.`}
                                >
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Tooltip>
                </Grid>
                <Grid item xs={1}>
                    <Avatar className={styles.avatar}>{selectedOperator ? selectedOperator.icon : '='}</Avatar>
                </Grid>
                <Grid item xs={4}>
                    <Tooltip
                        placement="bottom"
                        title="Turn on the capacity finder in the top right corner to enable."
                        arrow
                        disableHoverListener={!disabled}
                        disableFocusListener={!disabled}
                        disableTouchListener={!disabled}
                    >
                        <TextField
                            value={availabilityValue}
                            placeholder="100"
                            variant="outlined"
                            label={isAvailabilityCase ? 'hrs' : '%'}
                            onChange={handleInputChange}
                            disabled={disabled}
                            InputProps={{
                                className: styles.input,
                            }}
                            inputProps={{
                                step: 'any',
                                min: 0,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                            data-cy={`${title}-input`}
                        />
                    </Tooltip>
                </Grid>
                <Grid item xs={12} className={styles.sliderContainer}>
                    <StyledSlider
                        value={sliderValue}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-restrict"
                        step={1}
                        valueLabelDisplay="on"
                        marks={marks}
                        className={'mt-5'}
                        isAvailabilityCase={isAvailabilityCase}
                        onChange={handleSliderChange}
                        disabled={disabled}
                        min={min}
                        max={max}
                        scale={() => availabilityValue}
                        valueLabelFormat={numFormatter}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

SliderFilter.propTypes = {
    defaultValues: PropTypes.shape({
        operator: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onChange: PropTypes.func,
    clearBtn: PropTypes.bool,
    title: PropTypes.string,
    marks: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
};

SliderFilter.defaultProps = {
    onChange: null,
    clearBtn: false,
    defaultValues: {
        operator: '',
        value: '',
    },
    disabled: false,
    min: 0,
    max: 100,
};

const memoized = React.memo(SliderFilter);

export { memoized as SliderFilterV2 };
