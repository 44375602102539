import { call, put, takeLatest, select, fork } from 'redux-saga/effects';
import { first } from 'underscore';
import * as actionTypes from 'actions/actionTypes';
import { updateBillingExtensionState } from 'actions/billingActions';
import {
    getCompanyApprovers,
    getCompanies,
    getCompany as getCompanyRequest,
    updateSettingsRequest,
    createHolidayRequest,
    deleteHolidayRequest,
    updateHolidayRequest,
    getCalendarsRequest,
    updateCalendarsRequest,
    createCalendarRequest,
    importCountryHolidaysRequest,
    getCompanyBillingInfoRequest,
    updateCompanyRequest,
} from 'api/company';
import { getResources } from 'actions/resourceActions';
import {
    companyApprovers,
    getCompanies as getCompaniesAction,
    company,
    updateCompanySettings,
    createHoliday,
    deleteHoliday,
    updateHoliday,
    getCalendars,
    saveCalendars,
    createCalendar,
    companyBillingInfo,
    updateCompanyStartEndTime,
} from 'actions/companyActions';
import { addNotification } from 'actions/notificationActions';
import { hideModal, hideModalType } from 'actions/modalActions';
import { activeExtensionRequest } from 'api/extension';
import { handleBillingViewRequest } from './billingSaga';
import { UNASSIGNED } from 'enums/extensionShortIdEnum';
import { getCompanyId, getCompanySettings } from 'selectors/company';
import { getDefaultNotificationErrorHandler, handleErrors, otpValidationHandler } from './helpers/errorHandler';
import * as modalTypes from 'enums/modalTypeEnum';
import { mfaReminderTypes } from 'shared/constants/mfaReminders';
import { disableMFAReminderSaga } from './helpers/mfa';
import { updateAccountNotifications } from 'actions/accountActions';
import { getAccountNotifications } from 'selectors/account';
import { monitoring } from '../monitoring';

export function* getCompanyForLoggedInUser() {
    const companyId = yield select(getCompanyId);

    try {
        const response = yield call(getCompanyRequest, companyId);
        yield put(company.success(response));
    } catch (error) {
        console.log(error);
    }
}

export function* getCompany(companyId) {
    try {
        const response = yield call(getCompanyRequest, companyId);
        yield put(company.success(response));

        return response;
    } catch (error) {
        monitoring.captureException(error);
        console.log(error);
    }
}

function* getApprovers() {
    try {
        const companyId = yield select(getCompanyId);
        let approvers = yield call(getCompanyApprovers, companyId);

        yield put(companyApprovers.success(approvers));
    } catch (error) {
        monitoring.captureException(error);
        yield put(companyApprovers.failure());
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* getCompaniesRequest() {
    try {
        let response = yield call(getCompanies);

        yield put(getCompaniesAction.success(response));
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* handleUpdateCompanyRequest(action) {
    const { data } = action.payload;
    try {
        yield call(updateCompanyRequest, data);

        yield put(company.request());
        yield put(
            addNotification({
                message: 'Settings have been updated',
                type: 'success',
            })
        );
    } catch (error) {
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: 'Saving company data failed...',
            })
        );
    }
}

function* updateSettings(action) {
    try {
        const companyId = yield select(getCompanyId);
        const settings = yield select(getCompanySettings);
        yield call(updateSettingsRequest, companyId, action.payload.data);

        if (action.payload.data?.authentication?.mfaForceMode !== settings?.authentication?.mfaForceMode) {
            yield disableMFAReminderSaga(mfaReminderTypes.MFA_COMPANY_REMINDER);
        }

        yield put(updateCompanySettings.success(action.payload.data));
        yield handleUpdateCompanyStartEndTime();
        yield put(hideModalType(modalTypes.OTP_VERIFICATION_MODAL));

        yield put(
            addNotification({
                message: 'Settings have been updated',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: 'Saving settings failed...',
            })
        );
    }
}

function* handleCreateHoliday(action) {
    try {
        const response = yield call(createHolidayRequest, action.payload.calendarId, action.payload.data);

        yield put(createHoliday.success(response));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't create holiday, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleDeleteHoliday(action) {
    try {
        const response = yield call(deleteHolidayRequest, action.payload.calendarId, action.payload.holidayId);

        yield put(deleteHoliday.success(response));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't delete this holiday, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleUpdateHoliday(action) {
    try {
        const response = yield call(
            updateHolidayRequest,
            action.payload.calendarId,
            action.payload.holidayId,
            action.payload.data
        );

        yield put(updateHoliday.success(response));
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't update this holiday, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleGetCalendars() {
    try {
        const response = yield call(getCalendarsRequest);

        yield put(getCalendars.success(response));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load your calendars, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleCreateCalendar(action) {
    try {
        const response = yield call(createCalendarRequest, action.payload);

        yield put(createCalendar.success(response));
        yield put(
            addNotification({
                message: 'Calendar has been successfully created',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't create calendar, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleSaveCalendars(action) {
    try {
        const response = yield call(updateCalendarsRequest, action.payload.data);

        yield put(saveCalendars.success(response.calendars));
        // Refresh resources, so UI can has now new associated calendars
        yield put(getResources.request(true));

        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't save calendars, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleImportCountryHolidays(action) {
    try {
        const { calendarId, countryId, typesOfHolidays, holidaysYear } = action.payload;
        const response = yield call(importCountryHolidaysRequest, calendarId, countryId, typesOfHolidays, holidaysYear);

        yield put(saveCalendars.success(response));
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't import hoidays, please try again...",
                type: 'danger',
            })
        );
    }
}

export function* handleCompanyBillingRequest() {
    try {
        const response = yield call(getCompanyBillingInfoRequest);

        yield put(companyBillingInfo.success(response));

        return response;
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

export function* handleActiveExtensionRequest(action) {
    try {
        const { templateId: extensionTemplateId, installed } = action.payload;
        if (!installed) {
            const response = yield call(activeExtensionRequest, { extensionTemplateId });
            if (response.shortId === 'mfa') {
                const notifications = yield select(getAccountNotifications);
                yield put(
                    updateAccountNotifications.request(
                        [
                            ...notifications,
                            {
                                category: mfaReminderTypes.MFA_COMPANY_REMINDER,
                                channel: 'IN_APP',
                                enabled: false,
                            },
                        ],
                        undefined,
                        true,
                        true
                    )
                );
            }
            const extension = first(response);
            yield put(updateBillingExtensionState.success(extensionTemplateId, extension));
            if (extension.shortId === UNASSIGNED) {
                yield fork(handleCompanyBillingRequest);
                yield fork(handleBillingViewRequest);
            }
        }
    } catch (error) {
        monitoring.captureException(error);
        // yield put(addNotification({message: 'default_error_message', type: 'danger'}));
    }
}

function* handleUpdateCompanyStartEndTime() {
    const settings = yield select(getCompanySettings);

    yield call(updateCompanyStartEndTime, {
        company: {
            settings,
        },
    });
}

export default function* getCompanyWatcher() {
    yield takeLatest(actionTypes.GET_COMPANY['REQUEST'], getCompanyForLoggedInUser);
    yield takeLatest(actionTypes.GET_COMPANY_APPROVERS['REQUEST'], getApprovers);
    yield takeLatest(actionTypes.GET_COMPANIES['REQUEST'], getCompaniesRequest);
    yield takeLatest(actionTypes.UPDATE_COMPANY_SETTINGS['REQUEST'], updateSettings);
    yield takeLatest(actionTypes.UPDATE_COMPANY['REQUEST'], handleUpdateCompanyRequest);
    yield takeLatest(actionTypes.CREATE_HOLIDAY['REQUEST'], handleCreateHoliday);
    yield takeLatest(actionTypes.DELETE_HOLIDAY['REQUEST'], handleDeleteHoliday);
    yield takeLatest(actionTypes.UPDATE_HOLIDAY['REQUEST'], handleUpdateHoliday);
    yield takeLatest(actionTypes.GET_CALENDARS['REQUEST'], handleGetCalendars);
    yield takeLatest(actionTypes.CREATE_CALENDAR['REQUEST'], handleCreateCalendar);
    yield takeLatest(actionTypes.SAVE_CALENDARS['REQUEST'], handleSaveCalendars);
    yield takeLatest(actionTypes.IMPORT_COUNTRY_HOLIDAY_REQUEST, handleImportCountryHolidays);
    yield takeLatest(actionTypes.GET_COMPANY_BILLING_INFO['REQUEST'], handleCompanyBillingRequest);
    yield takeLatest(actionTypes.UPDATE_EXTENSION_STATE['REQUEST'], handleActiveExtensionRequest);
}
