import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classNames from 'classnames';
import { values } from 'underscore';
import { FormText, Row, Col } from 'reactstrap';
import {
    chooseField as ChooseField,
    inputGroupTextField as InputGroupTextField,
    textField as TextField,
} from 'shared/formFields';
import allocationTypes from 'enums/allocationTypeEnum';
import { Section } from './styles';

const allocationTypesArray = values(allocationTypes);

const Allocation = React.memo(props => {
    const {
        title,
        formText,
        allocationDefaultState,
        small,
        medium,
        onEnterPressed,
        onAllocationValueChange,
        onAllocationTypeChange,
    } = props;

    const [state, setState] = useState(allocationTypes.PERCENTAGE.state);
    // Store and show if in the middle of inserting (for example the value of '8.' )
    const [notFormattedValues, setNotFormattedValues] = useState(null);

    useEffect(() => {
        allocationDefaultState && setState(allocationDefaultState);
    }, [allocationDefaultState]);

    const changeAllocationState = newState => {
        if (newState !== state) {
            setNotFormattedValues(null);
            setState(newState);
            onAllocationTypeChange(newState);
        }
    };

    const onKeyPress = event => {
        if (13 === event.which || 13 === event.keyCode) {
            onEnterPressed && onEnterPressed();
            return false;
        }
        return true;
    };

    const normalizeAllocation = (value, previousValue) => {
        if (!value) {
            return value;
        }
        value = value.replace(/[^0-9\.]/g, '');
        if (2 < value.split('.').length) {
            value = value.replace(/\.+$/, '');
        }
        if (!previousValue || value.length > previousValue.length) {
            return value;
        }
    };

    const onAllocationValuesChange = event => {
        const { name, value } = event.currentTarget || event;

        const normalized = normalizeAllocation(value);

        if (!isNaN(parseFloat(normalized)) || normalized === '') {
            setNotFormattedValues({
                [name]: normalized,
            });
        } else {
            setNotFormattedValues(null);
        }

        if (Number.isNaN(parseFloat(value))) {
            return;
        }

        onAllocationValueChange({
            name,
            value: Number.isNaN(Math.abs(value)) ? value : Math.abs(value),
        });
    };

    if (small || medium) {
        // small block for allocation settings (is used for Dropdown for ex.)
        return (
            <Section className={classNames('pl-3 pt-2 allocation', { 'small-form': small, 'medium-form': medium })}>
                {title && <p className="title">{title}</p>}
                {allocationTypesArray.map(type => (
                    <Row key={type.state}>
                        <Col className="d-flex align-items-center mb-2">
                            <Field name="state">
                                {({ field }) => (
                                    <ChooseField
                                        inline
                                        type="radio"
                                        value={type.state}
                                        checked={type.state === state}
                                        onChange={() => changeAllocationState(type.state)}
                                        label={type.display}
                                        input={field}
                                        name={field.name}
                                    />
                                )}
                            </Field>
                            <Field name={type.valueName}>
                                {({ field }) => {
                                    const value =
                                        notFormattedValues?.[field.name] != null
                                            ? notFormattedValues[field.name]
                                            : field.value;
                                    return (
                                        <TextField
                                            width="auto"
                                            onChange={onAllocationValuesChange}
                                            onFocus={() => changeAllocationState(type.state)}
                                            onKeyPress={onKeyPress}
                                            normalize={normalizeAllocation}
                                            readOnly={type.state !== state}
                                            input={field}
                                            name={field.name}
                                            value={value}
                                        />
                                    );
                                }}
                            </Field>
                            {'PERCENTAGE' === type.state ? '%' : 'hrs'}
                        </Col>
                    </Row>
                ))}
            </Section>
        );
    }
    // large block for allocation settings (default, is used in modals for ex.)
    return (
        <Section className="form-fields">
            {title && <p className="title">{title}</p>}
            <div className="mb-3">
                {/** state */}
                {allocationTypesArray.map(type => (
                    <Field name="state" key={type.state}>
                        {({ field }) => (
                            <ChooseField
                                type="radio"
                                inline
                                label={type.display}
                                value={type.state}
                                checked={type.state === state}
                                onChange={() => changeAllocationState(type.state)}
                                input={field}
                                name={field.name}
                            />
                        )}
                    </Field>
                ))}
            </div>
            {/** values */}
            {allocationTypesArray.map(type => (
                <Field key={`${type.state}`} name={type.valueName}>
                    {({ field }) => {
                        const value =
                            notFormattedValues?.[field.name] != null ? notFormattedValues[field.name] : field.value;
                        return (
                            <InputGroupTextField
                                className="mb-2"
                                width="auto"
                                icon={'PERCENTAGE' === type.state ? 'fa-percent' : 'fa-clock-o'}
                                onChange={onAllocationValuesChange}
                                onFocus={() => changeAllocationState(type.state)}
                                readOnly={type.state !== state}
                                normalize={normalizeAllocation}
                                input={field}
                                name={field.name}
                                value={value}
                            />
                        );
                    }}
                </Field>
            ))}
            {formText ? <FormText>{formText}</FormText> : null}
        </Section>
    );
});

Allocation.propTypes = {
    title: PropTypes.string,
    formText: PropTypes.string,
    onAllocationValueChange: PropTypes.func.isRequired,
    onAllocationTypeChange: PropTypes.func.isRequired,
    allocationDefaultState: PropTypes.string,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    onEnterPressed: PropTypes.func,
};

Allocation.defaultProps = {
    allocationDefaultState: allocationTypes.PERCENTAGE.state,
    small: false,
    medium: false,
    onEnterPressed: null,
    onAllocationValueChange: () => {},
    onAllocationTypeChange: () => {},
};

export default Allocation;
