import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { SCHEDULER_FOOTER_FIXED_HEIGHT } from 'constants';
import { getResourceGroups, getResourceGroupResources } from 'actions/resourceGroupActions';
import { getProjectGroups, getProjectGroupProjects } from 'actions/projectGroupActions';
import { makeGetProjectGroups, isProjectsLoading } from 'selectors/projectGroup';
import { makeGetResourceGroups, isResourcesLoading } from 'selectors/resourceGroup';
import { useAppKeyWords, useRouteParams, useWindowDimension } from 'hooks';
import MetisMenu from 'modules/sidebar/menu';
import MenuFactory from 'modules/sidebar/services/menuBuilder/MenuFactory';
import { getCurrentSubMenuOpenId } from '../../../selectors/menuContent';
import { useLocation } from 'react-router-dom';
import { viewChanged } from '../../../actions/menu/content';
import { useBeforeMount } from '../../../hooks/useBeforeMount';
import { changeSidebarView, openSidebarGroupId } from 'shared/lib/sidebar';
import { selectProjectStatuses } from '../../../selectors/company';

const Sidebar = props => {
    const { rowHeight } = props;
    const dispatch = useDispatch();
    const params = useRouteParams();
    const appKeyWords = useAppKeyWords();
    const { windowHeight, windowWidth } = useWindowDimension();
    const resourceGroupResources = useSelector(state => state.resourceGroupReducer.resources);
    const projectGroupProjects = useSelector(state => state.projectGroupReducer.projects);
    const projectGroupSelector = useMemo(() => makeGetProjectGroups(), []);
    const projectGroups = useSelector(projectGroupSelector);
    const resourceGroupSelector = useMemo(() => makeGetResourceGroups(), []);
    const resourceGroups = useSelector(resourceGroupSelector);
    const isPLoading = useSelector(isProjectsLoading);
    const isRLoading = useSelector(isResourcesLoading);
    const location = useLocation();
    const currentView = useSelector(state => state.menuContent.currentView);
    const projectStatuses = useSelector(selectProjectStatuses);

    useEffect(() => {
        window.dispatchEvent(new Event('scroll-vlist-index'));
    }, []);

    useBeforeMount(() => {
        const { pathname } = location;
        if (pathname.indexOf('/project') === 0) {
            currentView !== 'project' && dispatch(viewChanged('project'));
        } else if (pathname.indexOf('/resource') === 0) {
            currentView !== 'resource' && dispatch(viewChanged('resource'));
        }
    });

    // When scheduler location changes, adapt sidebar
    useEffect(() => {
        const splitted = location.pathname.split('/');
        if ((splitted.length === 4 || splitted.length === 5) && ['resource', 'project'].includes(splitted[1])) {
            changeSidebarView(splitted[1], () => {
                openSidebarGroupId(splitted[splitted.length - 1], 200, 1, () => {
                    window.dispatchEvent(new Event('scroll-vlist-index'));
                });
            });
        }
    }, [location.pathname]);

    const {
        extensions: companyExtensions,
        settings: { uniqueSubgroupsCount },
    } = useSelector(state => state.companyReducer.company);
    const displayArchivedInScheduler = useSelector(
        state => state.companyReducer.company.settings.grid.displayArchivedInScheduler
    );
    const account = useSelector(state => state.account);

    const currentSubMenuOpenId = useSelector(getCurrentSubMenuOpenId);

    const partialOfAccount = useMemo(
        () => ({
            isProjectManager: account.isProjectManager,
            resourceRoleRights: account.resourceRoleRights,
            resourceId: account.resourceId,
            preferences: {
                topLevelResourceGroupOrder: account.preferences.topLevelResourceGroupOrder,
                topLevelProjectGroupOrder: account.preferences.topLevelProjectGroupOrder,
                myScheduleGroupId: account.preferences.myScheduleGroupId,
            },
        }),
        [
            account.isProjectManager,
            account.preferences.myScheduleGroupId,
            account.preferences.topLevelProjectGroupOrder,
            account.preferences.topLevelResourceGroupOrder,
            account.resourceId,
            account.resourceRoleRights,
        ]
    );

    const menuContent = useMemo(() => {
        const menuCreateOptions = {
            account: partialOfAccount,
            currentView,
            data: {
                projectGroups,
                resourceGroups,
                resources: resourceGroupResources,
                projects: projectGroupProjects,
            },
            extensions: companyExtensions,
            keyWords: appKeyWords,
            options: { displayArchivedInScheduler, uniqueSubgroupsCount },
            projectStatuses,
        };
        return MenuFactory.create('scheduler', menuCreateOptions).getMenu();
    }, [
        partialOfAccount,
        currentView,
        projectGroups,
        resourceGroups,
        resourceGroupResources,
        projectGroupProjects,
        companyExtensions,
        appKeyWords,
        displayArchivedInScheduler,
        uniqueSubgroupsCount,
        projectStatuses,
    ]);

    /**
     * Run on id and view change
     */
    useEffect(() => {
        if ('resource' === currentView && !resourceGroupResources.length && !isPLoading && params.resourceGroupId) {
            dispatch(getResourceGroupResources.request(params.resourceGroupId));
        }
    }, [currentView, params.resourceGroupId, resourceGroupResources.length, dispatch, isPLoading]);

    /**
     * Run on id and view change
     */
    useEffect(() => {
        if ('project' === currentView && !projectGroupProjects.length && !isRLoading && params.projectGroupId) {
            dispatch(getProjectGroupProjects.request(params.projectGroupId));
        }
    }, [currentView, params.projectGroupId, projectGroupProjects.length, dispatch, isRLoading]);

    useEffect(() => {
        if (
            currentSubMenuOpenId &&
            (('resource' === currentView && resourceGroups.length) ||
                ('project' === currentView && projectGroups.length))
        ) {
            return;
        }
        if (!resourceGroups.length) {
            dispatch(getResourceGroups.request());
        }
        if (!projectGroups.length) {
            dispatch(getProjectGroups.request());
        }
    }, [dispatch, currentView, currentSubMenuOpenId, resourceGroups, projectGroups]);

    const loadingSubmenuData = isPLoading || isRLoading;

    return (
        <div
            className="sidebar sidebar-nav"
            style={{ height: `${windowHeight - SCHEDULER_FOOTER_FIXED_HEIGHT - (991 >= windowWidth ? 42 : 51)}px` }}
        >
            <MetisMenu
                usePlaceholder
                rowHeight={rowHeight}
                content={menuContent}
                activeLinkFromLocation
                loadingSubmenuData={loadingSubmenuData}
            />
        </div>
    );
};

Sidebar.propTypes = {
    rowHeight: PropTypes.number.isRequired,
};

export default Sidebar;
