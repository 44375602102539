import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm, initialize, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'hooks';
import { validateSchema } from 'utils/schemaUtil';
import { ColorToggler, DateToggler, RepeatToggler } from 'modules/scheduler/components/dropdowns/shared/togglers';
import { formSchema, getDefaultValues, mapFormToRequest } from './datesFormSchema';
import SuccessButton from 'shared/buttons/success';
import Scrollbar from 'shared/scrollbar';
import useUniqueToggler from '../../../../../../../shared/hooks/useUniqueToggler';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { NESTED_MENU_ITEM_SCROLLBAR_HEIGHT } from '../../../../../../../shared/nestedMenuItem';

const FORM_NAME = 'datesForm';

const interDependentFieldsKeys = Object.entries(formSchema.schema || {})
    .filter(arr => arr[1].validatorInterdependent)
    .map(arr => arr[0]);

const DatesForm = props => {
    const { handleSubmit, onUpdate, submit, currentSelectedBooking, touch, dataCy } = props;
    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);

    const onSubmit = useCallback(
        values => {
            onUpdate(mapFormToRequest(values));
        },
        [onUpdate]
    );

    const formValuesSelector = useMemo(() => getFormValues(FORM_NAME), []);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);

    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
        onlyKeys: interDependentFieldsKeys,
    });

    const submitForm = useCallback(() => dispatch(submit(FORM_NAME)), [dispatch, submit]);

    const { onToggleOpen, dateCloseToggler, repeatCloseToggler, colorCloseToggler } = useUniqueToggler([
        'date',
        'repeat',
        'color',
    ]);

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues(currentSelectedBooking)));
    }, [dispatch, currentSelectedBooking]);

    const onColorChange = useCallback(
        color => {
            return updateFields({ backgroundColor: color, useProjectColor: false });
        },
        [updateFields]
    );

    const submitDisabled = !validDataForSchema;

    const handleInheritProjectColorClick = useCallback(() => {
        updateFields({ backgroundColor: '', useProjectColor: true });
    }, [updateFields]);

    return (
        <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <DateToggler
                    start={formValues.start}
                    end={formValues.end}
                    onDateChange={updateFields}
                    onToggleOpen={() => onToggleOpen('date')}
                    closeToggle={dateCloseToggler}
                    dataCy={`${dataCy || ''}-date-toggler`}
                />
                <RepeatToggler
                    interval={formValues.interval}
                    onToggleOpen={() => onToggleOpen('repeat')}
                    closeToggle={repeatCloseToggler}
                    formName={FORM_NAME}
                />
                <ColorToggler
                    color={
                        formValues.useProjectColor
                            ? currentSelectedBooking.project?.color || currentSelectedBooking.project?.backgroundColor
                            : formValues.backgroundColor
                    }
                    onChange={onColorChange}
                    noCollapseAfterChange
                    pickerWidth={215}
                    onToggleOpen={() => onToggleOpen('color')}
                    closeToggle={colorCloseToggler}
                    useProjectColor={formValues.useProjectColor}
                    onInheritClick={handleInheritProjectColorClick}
                    inheritOption
                />
                <div className="text-center px-2">
                    <SuccessButton
                        className="mt-2 w-100"
                        onClick={submitForm}
                        disabled={submitDisabled}
                        dataCy="button--submit-dates"
                    />
                </div>
            </Form>
        </Scrollbar>
    );
};

DatesForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    currentSelectedBooking: PropTypes.object.isRequired,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(DatesForm);
