import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        marginTop: '0 !important',
        marginBottom: '0 !important',
    },
    input: props => ({
        height: props.variant === 'outlined' ? '3px' : undefined,
    }),
    label: props => ({
        transform: props.variant === 'outlined' ? 'translate(14px, 14px) scale(1)' : undefined
    })
});
