import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, RATE_TYPE_COLUMNS, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {Array}
 */
export default dateState => [
    {
        id: 'externalScheduledSpendBetween',
        display: `${displayDateState(dateState, false, false)} Spend`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'externalScheduledSpendPrevious',
        display: `Previous Spend (${displayDateState(dateState, false, false)})`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'externalScheduledSpendAfter',
        display: `Next Spend (${displayDateState(dateState, false, false)})`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'externalScheduledSpendToDate',
        display: 'Spend To Date',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'externalScheduledSpendFuture',
        display: 'Future Spend',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'externalScheduledSpendAllTime',
        display: 'All Time Spend',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalScheduledSpendBetween',
        display: `${displayDateState(dateState, false, false)} Spend`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'internalScheduledSpendPrevious',
        display: `Previous Spend (${displayDateState(dateState, false, false)})`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'internalScheduledSpendAfter',
        display: `Next Spend (${displayDateState(dateState, false, false)})`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'internalScheduledSpendToDate',
        display: 'Spend To Date',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalScheduledSpendFuture',
        display: 'Future Spend',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalScheduledSpendAllTime',
        display: 'All Time Spend',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'scheduledSpendProfitabilityAllTime',
        display: 'Spend Profitability All Time',
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external - internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'scheduledSpendProfitabilityBetween',
        display: `Spend Profitability ${displayDateState(dateState, false, false)}`,
        displayTimeType: 'Scheduled Time',
        displayColumnType: 'spend',
        displayRateType: 'external - internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'externalEstimatedSpend',
        display: 'Estimated Spend',
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'internalEstimatedSpend',
        display: 'Estimated Spend',
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
];
