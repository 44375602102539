import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isNil, filter, isFunction, head } from 'lodash';
import { Row, Col, DropdownItem } from 'reactstrap';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import Scrollbar from 'shared/scrollbar';
import { Wrapper, Panel, Panels, useStyles } from './styles';
import SuccessButton from 'shared/buttons/success';

export const BACK_BTN_HEIGHT = 51;
export const ACTION_BTN_HEIGHT = 2;

const SlideCard = React.forwardRef((props, ref) => {
    const {
        items,
        height,
        width,
        onClick,
        additionalAction,
        actionBtnText,
        additionalActionBtnText,
        showActionBtn,
        actionBtnDisabled,
        additionalActionBtnDisabled,
        divider,
        disablePadding,
        listenForChildsEvents,
        dispatchParentEvents,
    } = props;

    const [disabledBackBtnFromChild, setDisabledBackBtnFromChild] = useState(false);
    const [activeItemId, setActiveItemId] = useState(null);
    const [activeSlideKey, setActiveSlideKey] = useState(null);

    const timeout = useRef(null);
    const classes = useStyles();
    const filteredItems = filter(items, item => !item.hasOwnProperty('show') || true === item.show);

    // Listen for other elements events
    useEffect(() => {
        if (listenForChildsEvents) {
            const eventListener = event => {
                const {
                    detail: { disableBackButton },
                } = event;
                disabledBackBtnFromChild !== disableBackButton && setDisabledBackBtnFromChild(disableBackButton);
            };

            window.addEventListener('disable-parent-back-button', eventListener);

            return () => {
                window.removeEventListener('disable-parent-back-button', eventListener);
            };
        }
    }, [disabledBackBtnFromChild, listenForChildsEvents, setDisabledBackBtnFromChild]);

    // Dispatch events for parents (SlideCards components)
    useEffect(() => {
        if (dispatchParentEvents) {
            const getDisableBackButtonValue = () => {
                const itemFound = items.find(item => item.id === activeItemId);
                return itemFound && itemFound.slides[activeSlideKey]?.disableParentSlideCardBackButton;
            };

            window.dispatchEvent(
                // eslint-disable-next-line no-undef
                new CustomEvent('disable-parent-back-button', {
                    detail: {
                        disableBackButton: activeSlideKey == null ? false : getDisableBackButtonValue(),
                    },
                })
            );
        }
    }, [dispatchParentEvents, activeSlideKey, activeItemId, items]);

    const setBody = item => {
        if (item.slides) {
            setActiveItemId(item.id);
            setActiveSlideKey(0);
        }
    };

    const actionBtn = (key, backBtnPosition) => {
        if ('bottom' === backBtnPosition) {
            setActiveSlideKey(0 === key ? null : key - 1);
        } else {
            setActiveSlideKey(key + 1);
        }
    };

    const getButtonText = item => {
        if (item.buttonText) {
            return typeof item.buttonText === 'function' ? item.buttonText() : item.buttonText;
        } else {
            return 'Back';
        }
    };

    useEffect(() => {
        if (!ref) {
            return;
        }
        if (ref && isNil(ref.current)) {
            ref.current = {};
        }
        ref.current.triggerStart = () => {
            timeout.current = setTimeout(() => {
                setActiveItemId(null);
                setActiveSlideKey(null);
            });
        };
        ref.current.triggerNext = () => {
            if (null === activeItemId) setBody(head(filteredItems));
            if (null === activeSlideKey) setActiveSlideKey(0);
            if (null !== activeSlideKey) setActiveSlideKey(prevState => prevState + 1);
        };
        ref.current.triggerSlide = itemId => {
            setActiveItemId(itemId);
            setActiveSlideKey(0);
        };
        ref.current.triggerNestedSlide = nestedSlideKey => {
            setActiveSlideKey(nestedSlideKey);
        };
    }, [activeItemId, activeSlideKey, filteredItems, ref]);

    const scrollbarHeight = height - (onClick ? ACTION_BTN_HEIGHT : 0);

    return (
        <Wrapper>
            <Panels
                className={classNames({ [`active-${activeSlideKey}`]: null !== activeSlideKey })}
                slide={null !== activeSlideKey ? activeSlideKey + 1 : 0}
            >
                <Panel width={width} className={classNames({ active: null === activeSlideKey, 'p-0': disablePadding })}>
                    <Scrollbar height={scrollbarHeight}>
                        {filteredItems.map(({ itemElement: ItemElement, ...item }) =>
                            isFunction(ItemElement) ? (
                                <ItemElement
                                    disabled={item.disabled}
                                    key={item.id}
                                    onClick={() => !item.disabled && setBody(item)}
                                >
                                    {item.slides && <KeyboardArrowRight className="navigate-right-icon" />}
                                </ItemElement>
                            ) : (
                                <div key={item.id}>{ItemElement}</div>
                            )
                        )}
                        {divider && <DropdownItem divider />}
                    </Scrollbar>
                    {onClick && showActionBtn && (
                        <Row noGutters={!!additionalAction} className="no-row-gutters pt-2">
                            {additionalAction && (
                                <Col style={{ padding: '0 5px 0 10px' }}>
                                    <SuccessButton
                                        onClick={additionalAction}
                                        disabled={additionalActionBtnDisabled}
                                        name={additionalActionBtnText}
                                        type="btn-secondary"
                                        data-cy={`button--mod-menu-${additionalActionBtnText}`}
                                        className="w-100"
                                    />
                                </Col>
                            )}
                            <Col style={additionalAction ? { padding: '0 10px 0 5px' } : {}}>
                                <SuccessButton
                                    onClick={onClick}
                                    disabled={actionBtnDisabled}
                                    name={actionBtnText}
                                    type="btn-primary"
                                    data-cy={`button--mod-menu-${actionBtnText}`}
                                    className="w-100"
                                />
                            </Col>
                        </Row>
                    )}
                </Panel>

                {null !== activeSlideKey &&
                    null !== activeItemId &&
                    items
                        .find(item => item.id === activeItemId)
                        .slides.map((item, key) => {
                            const scrollHeight =
                                height - ('bottom' === item.backBtnPosition ? BACK_BTN_HEIGHT : -BACK_BTN_HEIGHT);
                            return (
                                <Panel
                                    width={width}
                                    key={key}
                                    className={classNames({ active: activeSlideKey === key })}
                                >
                                    {(!item.backBtnPosition || 'top' === item.backBtnPosition) &&
                                        !disabledBackBtnFromChild &&
                                        (item.backBtnInline ? (
                                            <div
                                                className="panel-header-wrapper"
                                                style={{
                                                    position: 'absolute',
                                                    paddingTop: '18px',
                                                }}
                                                onClick={() => setActiveSlideKey(0 === key ? null : key - 1)}
                                                data-cy="scheduler-booking-modern-menu-back-button"
                                            >
                                                <KeyboardArrowLeft classes={classes} />
                                                <div className="panel-header">
                                                    {item.backTextLabel ? item.backTextLabel : 'Back'}
                                                </div>
                                                {typeof item.header === 'function' ? item.header() : item.header}
                                            </div>
                                        ) : (
                                            <div className="panel-header-wrapper">
                                                <KeyboardArrowLeft
                                                    classes={classes}
                                                    onClick={() => setActiveSlideKey(0 === key ? null : key - 1)}
                                                    data-cy="scheduler-booking-modern-menu-back-button"
                                                />
                                                <div className="panel-header">
                                                    {item.backTextLabel ? item.backTextLabel : 'Back'}
                                                </div>
                                                {typeof item.header === 'function' ? item.header() : item.header}
                                            </div>
                                        ))}
                                    {item.scrollable && (
                                        <Scrollbar height={scrollHeight}>
                                            {typeof item.content === 'function' ? item.content() : item.content}
                                        </Scrollbar>
                                    )}
                                    {!item.scrollable && (
                                        <>{typeof item.content === 'function' ? item.content() : item.content}</>
                                    )}
                                    {(item.buttonText || 'bottom' === item.backBtnPosition) && (
                                        <Row className="no-row-gutters mt-2">
                                            <Col>
                                                <SuccessButton
                                                    onClick={() => actionBtn(key, item.backBtnPosition)}
                                                    name={getButtonText(item)}
                                                    type="btn-secondary"
                                                    className="w-100"
                                                    data-cy={'button--' + item.buttonText}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </Panel>
                            );
                        })}
            </Panels>
        </Wrapper>
    );
});

SlideCard.propTypes = {
    onClick: PropTypes.func,
    additionalAction: PropTypes.func,
    actionBtnText: PropTypes.string,
    additionalActionBtnText: PropTypes.string,
    divider: PropTypes.bool,
    disablePadding: PropTypes.bool,
    actionBtnDisabled: PropTypes.bool,
    showActionBtn: PropTypes.bool,
    additionalActionBtnDisabled: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            itemElement: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]).isRequired,
            slides: PropTypes.array,
            show: PropTypes.bool,
            disabled: PropTypes.bool,
        })
    ),
};

SlideCard.defaultProps = {
    onClick: null,
    additionalAction: null,
    actionBtnDisabled: false,
    additionalActionBtnDisabled: false,
    showActionBtn: true,
    actionBtnText: 'Submit',
    additionalActionBtnText: 'Submit',
    divider: false,
    disablePadding: false,
    width: 320,
    items: [],
};

export default SlideCard;
