/*
    IMPORTANT
    All code in this file MUST be documented
*/

/**
 * Returns the first HTMLElement that matches the selector in the HTMLElement container
 * @param {string} selector selector for querySelector native function
 * @param {HTMLElement} containerDOMElement (defaults to document)
 * @returns HTMLElement
 */
export const findElementInDOMElementBySelector = (selector, containerDOMElement = document) => {
    return containerDOMElement.querySelector(selector);
};
