import { axiosInstance } from './axiosInstance';
import FormData from 'form-data';
import axios from 'axios';
import { each } from 'underscore';

export const sendBug = data => {
    const formData = new FormData();

    each(data, (value, key) => {
        if ('files' === key) {
            formData.append(`${key}`, value[0]);
        } else {
            formData.append(key, value);
        }
    });

    return axiosInstance
        .post('general/sendBugEmail', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const sendContact = data =>
    axiosInstance
        .post('general/sendContactEmail', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getGeoLocationRequest = () => {
    const auth = process.env.REACT_APP_GEOLOCATION_API_KEY || 'd5ee3c1eb52a1f802acaf67cacf07978';

    return axios
        .get(`https://api.ipstack.com/check?access_key=${auth}&format=1`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getCountries = () =>
    axiosInstance
        .get('general/getCountries')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const sendInviteEmailRequest = resourceId =>
    axiosInstance
        .get(`general/sendInviteEmail/${resourceId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const sendResetPasswordEmailRequest = resourceId =>
    axiosInstance
        .get(`accountRoute/sendResetPasswordEmail/${resourceId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const sendInviteEmailsRequest = (resourceIds = []) =>
    axiosInstance
        .post(`resource/sendInviteEmails`, resourceIds)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
