import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, TabPane, Row, Col } from 'reactstrap';
import { useIsExtensionInstalled, useHasRights, useAppKeyWords } from 'hooks';
import Tag from './../../common/tag';
import UploadButton from 'shared/upload';
import { inputGroupTextField, chooseField, dropdownField } from 'shared/formFields';
import { rolesArrayExceptOwner, statusesArray, TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { PM, RESOURCE_REQUEST, VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { useSelector } from 'react-redux';

const roleRights = [
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasSettingAddEditResources',
    },
    {
        rights: ['settingResourceInfo'],
        rule: 'one',
        name: 'hasSettingResourceInfo',
    },
    {
        rights: ['settingResourcePm'],
        rule: 'one',
        name: 'hasSettingResourcePm',
    },
    {
        rights: ['settingResourceAsApprover'],
        rule: 'one',
        name: 'hasSettingResourceAsApprover',
    },
    {
        rights: ['settingResourceRole'],
        rule: 'one',
        name: 'hasSettingResourceRole',
    },
    {
        rights: ['settingResourceStatus'],
        rule: 'one',
        name: 'hasSettingResourceStatus',
    },
    {
        rights: ['settingResourceTags'],
        rule: 'one',
        name: 'hasSettingResourceTags',
    },
];

const ResourceBasicInfoTab = ({
    tabId,
    resourceFirstName,
    resourceKeyWord,
    tags,
    thumb,
    onChange,
    resourceType,
    showInviteSection,
    isAccountOwner,
}) => {
    const { projectKeyWord } = useAppKeyWords();
    const defaultVacationName = useSelector(state => state.companyReducer.company.settings.vacationEventName);
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const isResourceExtensionInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const isResourceOrVacationExtensionInstalled = isResourceExtensionInstalled || isVacationExtensionInstalled;
    const {
        hasSettingAddEditResources,
        hasSettingResourceInfo,
        hasSettingResourcePm,
        hasSettingResourceAsApprover,
        hasSettingResourceRole,
        hasSettingResourceStatus,
        hasSettingResourceTags,
    } = useHasRights(roleRights);

    return (
        <TabPane tabId={tabId}>
            <>
                {(hasSettingAddEditResources || hasSettingResourceInfo) && (
                    <section className="form-fields">
                        <Row>
                            <Col md={resourceType !== TYPE_UNASSIGNED.value ? 8 : 12}>
                                <p className="title">{resourceKeyWord} Name:</p>
                                <Field
                                    width="col-md-12"
                                    name="firstName"
                                    icon="fa-user"
                                    data-cy="res-modal-firstName"
                                    component={inputGroupTextField}
                                />
                                <FormText className="required mb-2">
                                    {resourceType !== TYPE_UNASSIGNED.value
                                        ? 'First Name'
                                        : `${resourceKeyWord} Category name`}
                                </FormText>
                                {resourceType !== TYPE_UNASSIGNED.value && (
                                    <>
                                        <Field
                                            width="col-md-12"
                                            name="lastName"
                                            data-cy="res-modal-lastName"
                                            icon="fa-user"
                                            component={inputGroupTextField}
                                        />
                                        <FormText className="mb-0">Last Name</FormText>
                                    </>
                                )}
                            </Col>
                            {resourceType !== TYPE_UNASSIGNED.value && (
                                <Col md="4">
                                    <UploadButton name="thumb" defaultThumb={thumb} onChange={onChange} />
                                </Col>
                            )}
                        </Row>
                    </section>
                )}
                {resourceType !== TYPE_UNASSIGNED.value && hasSettingResourceInfo && (
                    <section className="form-fields">
                        <p className="title">Email:</p>
                        <Field
                            className="mb-2"
                            width="col-md-12"
                            name="email"
                            icon="fa-envelope"
                            data-cy="res-modal-email"
                            component={inputGroupTextField}
                        />
                        {showInviteSection && (
                            <>
                                <Field
                                    type="checkbox"
                                    inline
                                    label="Automatically Invite User when Added"
                                    name="sendInviteEmail"
                                    data-cy="res-modal-sendInviteEmail"
                                    value={true}
                                    component={chooseField}
                                />
                                <FormText className="mb-0">
                                    You can always choose to invite them later if you wish
                                </FormText>
                            </>
                        )}
                    </section>
                )}
                {hasSettingResourcePm && resourceType !== TYPE_UNASSIGNED.value && (
                    <section className="form-fields">
                        <p className="title">Make Project Manager:</p>
                        <Field
                            type="checkbox"
                            inline
                            label="Make Project Manager"
                            name="isProjectManager"
                            data-cy="res-modal-isProjectManager"
                            disabled={!isPMExtensionInstalled}
                            value={true}
                            component={chooseField}
                        />
                        <FormText className="mb-0">
                            {isPMExtensionInstalled
                                ? `Check to make a Project Manager. From the ${projectKeyWord} you can assign the PM`
                                : 'You must install the Project Manager Extension from the Extensions page in order to create Project Managers'}
                        </FormText>
                    </section>
                )}
                {hasSettingResourceAsApprover && resourceType !== TYPE_UNASSIGNED.value && (
                    <section className="form-fields">
                        <p className="title">Make Approver:</p>
                        <Field
                            type="checkbox"
                            inline
                            label={`Make ${resourceFirstName} an Approver`}
                            name="isApprover"
                            data-cy="res-modal-isApprover"
                            disabled={!isResourceOrVacationExtensionInstalled}
                            value={true}
                            component={chooseField}
                        />
                        <FormText className="mb-0">
                            {isResourceOrVacationExtensionInstalled
                                ? 'Check to make an Approver. This means they can edit, approve, reject, remove & resubmit requests'
                                : `You must install the Request Flow or ${defaultVacationName} Request Extension from the Extensions page in order to set Approvers`}
                        </FormText>
                    </section>
                )}
                {hasSettingResourceRole && resourceType !== TYPE_UNASSIGNED.value && (
                    <section className="form-fields">
                        <p className="title">Set {resourceKeyWord} Role:</p>
                        <Field
                            inline
                            name="role"
                            component={dropdownField}
                            filter={false}
                            valueField="value"
                            textField="display"
                            data={rolesArrayExceptOwner}
                            disabled={isAccountOwner}
                        />
                        <FormText className="required">
                            Roles define what a {resourceKeyWord} can do in the system. For full configuration on roles
                            and permissions, see settings page
                        </FormText>
                    </section>
                )}
                {hasSettingResourceStatus && resourceType !== TYPE_UNASSIGNED.value && (
                    <section className="form-fields">
                        <p className="title">Set {resourceKeyWord} Status:</p>
                        <Field
                            inline
                            name="status"
                            component={dropdownField}
                            filter={false}
                            valueField="value"
                            textField="display"
                            data={statusesArray}
                        />
                        <FormText className="required">
                            Status defines what state a {resourceKeyWord} is in. More details&nbsp;
                            <a
                                href="https://hubplanner.com/reference/resource-status/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                here
                            </a>
                        </FormText>
                    </section>
                )}
                {hasSettingResourceTags && (
                    <Tag formName="resourceForm" fieldName="tags" category="RESOURCE" tags={tags} />
                )}
            </>
        </TabPane>
    );
};

ResourceBasicInfoTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    showInviteSection: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    thumb: PropTypes.string,
    resourceKeyWord: PropTypes.string,
    resourceFirstName: PropTypes.string,
    resourceType: PropTypes.string,
    isAccountOwner: PropTypes.bool,
};

ResourceBasicInfoTab.defaultProps = {
    tags: [],
    thumb: '',
    resourceType: '',
    resourceKeyWord: '',
    resourceFirstName: '',
    onChange: null,
    isAccountOwner: false,
};

export default ResourceBasicInfoTab;
