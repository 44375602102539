import styled from 'styled-components';
import { ListItem as ListItemMaterial } from '@material-ui/core';

export const ListItem = styled(ListItemMaterial)`
    cursor: pointer;
    color: ${props => (props.showDisabledTextState ? '#bababa' : 'inherit')};

    &:hover {
        background-color: #f8f9fa;
    }

    svg {
        margin-left: 15px;
        margin-right: 15px;
    }
`;
