import React, { Component, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { getCompanyId } from './selectors/company';
import { getAccountId, getLoggedInId, getLoggedInRole } from './selectors/account';
import { useSelector } from 'react-redux';
import { Background, H1, Main, Text, Wrapper } from './shared/notFound/styles';
import { Link } from 'react-router-dom';

const isAxiosError = error => {
    return Boolean(error?.isAxiosError);
};

const isNetworkError = error => {
    const statusesToOmit = [400, 401, 403, 404, 500];
    return statusesToOmit.some(status => status === error?.status);
};

const shouldOmitException = error => {
    return [isAxiosError, isNetworkError].some(fnc => {
        return fnc(error);
    });
};

class Monitoring {
    init() {
        if (process.env.REACT_APP_SENTRY_DSN) {
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
                environment: process.env.REACT_APP_ENV_NAME,
                maxBreadcrumbs: 25,
                beforeSend: (errorEvent, errorHint) => {
                    /**
                     * some of the api files rethrow errors like:
                     * throw error.response;
                     * then we should omit those errors where response is undefined
                     */
                    if (errorHint.originalException === undefined || shouldOmitException(errorHint.originalException)) {
                        return null;
                    }

                    return errorEvent;
                },
            });

            this.setUserInfo({
                accountId: '-',
                companyId: '-',
                resourceId: '-',
                role: '-',
            });
        }
    }

    setUserInfo(userInfo = {}) {
        const userInfoTags = Object.keys(userInfo).reduce((acc, key) => {
            acc[`userInfo.${key}`] = userInfo[key] ?? '';
            return acc;
        }, {});

        Sentry.setTags(userInfoTags);
    }

    captureException(error) {
        Sentry.captureException(error);
    }
}

export const monitoring = new Monitoring();

export const useMonitoringLoggedInUserContext = () => {
    const companyId = useSelector(getCompanyId);
    const accountId = useSelector(getAccountId);
    const resourceId = useSelector(getLoggedInId);
    const role = useSelector(getLoggedInRole);

    useEffect(() => {
        monitoring.setUserInfo({
            accountId: accountId ?? '-',
            companyId: companyId ?? '-',
            resourceId: resourceId ?? '-',
            role: role ?? '-',
        });
    }, [accountId, companyId, resourceId, role]);
};

export class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        monitoring.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Background>
                    <Main>
                        <Wrapper className="text-center p-3">
                            <img src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png" alt="logo" />
                            <H1>Error</H1>
                            <Text>Woops, looks like we have a problem!</Text>
                        </Wrapper>
                        <div className="text-center">
                            <Link className="btn btn-secondary rounded-0" to="/">
                                <i className="fa fa-home" /> Hub Planner Home
                            </Link>
                        </div>
                    </Main>
                </Background>
            );
        }

        return this.props.children;
    }
}
