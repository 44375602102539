import { Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { Layout } from 'shared/components/layout';
import MfaWarningBlueImage from 'assets/images/mfa_warning_blue.svg';
import SuccessButton from 'shared/buttons/success';
import { useMFARemindersLogic } from 'modules/modals/common/mfaRemindersLogic';
import { MenuButton } from 'shared/buttons/menuButton';
import { mfaReminderTypes } from 'shared/constants/mfaReminders';
import { useDispatch } from 'react-redux';
import { postponeMFACompanyReminder } from 'actions/accountActions';
import { replace } from 'connected-react-router';

export const MFACompanyReminderContent = () => {
    const dispatch = useDispatch();
    const hideReminder = useCallback(silentUpdate => !silentUpdate && dispatch(postponeMFACompanyReminder()), [
        dispatch,
    ]);
    const onConfirmAction = useCallback(() => dispatch(replace('/settings/authentication')), [dispatch]);
    const { skipOptions, onConfirm } = useMFARemindersLogic({
        type: mfaReminderTypes.MFA_COMPANY_REMINDER,
        afterSkip: hideReminder,
        onConfirmAction,
        disableOnConfirm: true,
        postponeOnHide: true,
    });

    return (
        <Layout gap="30px" padding="20px" stack vAlign="space-between" hAlign="center">
            <Layout stack gap="20px">
                <Layout hAlign="center">
                    <MfaWarningBlueImage width="200" height="160" />
                </Layout>
                <Typography align="center" variant="h6">
                    Introducing multi-factor authentication
                </Typography>
                <Typography align="center">
                    <p>
                        Just letting you know that we’ve implemented the multi-factor authentication (MFA) to Hub
                        Planner.
                    </p>
                    <p>
                        From now you can protect your account with an extra layer of security by requiring access to the
                        mobile device. Once configured, you and your team members will be required to enter both your
                        password and an authentication code from the mobile authentication app in order to sign in to
                        your Hub Planner accounts.
                    </p>
                </Typography>
            </Layout>
            <Layout hAlign="flex-end" gap="20px">
                <MenuButton
                    name="Skip"
                    options={skipOptions}
                    menuPosition={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'bottom', horizontal: 'right' },
                    }}
                />
                <SuccessButton name="Set up MFA" onClick={onConfirm} />
            </Layout>
        </Layout>
    );
};
