import { STATUS_ARCHIVED, TYPE_EVENT, TYPE_REGULAR } from 'enums/projectEnum';
import { createSelector } from 'reselect';
import { filter, includes, sortBy, find } from 'lodash';
import { getPermittedProjects, getPermittedProjectsForResource } from 'modules/scheduler/services/scheduleRights';
import { hasOneOfRoles, hasRole } from 'utils/rightsUtil';
import { getCompanyExtensions, selectVacationId } from './company';
import { getAccount, getLoggedInId } from './account';
import { arrayToObjectByKey } from '../utils/mappingUtil';

export const getProjects = state => state.projectReducer.projects;
export const getProjectsMap = createSelector(getProjects, projects => arrayToObjectByKey(projects, '_id'));

export const projectsLoading = state => state.projectReducer.loading;
export const projectsLoaded = state => !state.projectReducer.loading;
export const selectLastCallContext = state => state.projectReducer.lastCallContext;

export const makeGetFilteredProjects = filterType =>
    createSelector(getProjects, getLoggedInId, (projects, loggedInId) => {
        const projectStatus = filterType && filterType[0].toUpperCase() + filterType.slice(1).toLowerCase();

        switch (filterType) {
            case 'EXCLUDE_ARCHIVED':
                return filter(
                    projects,
                    project => project.status !== STATUS_ARCHIVED.value && project.hasRightsToProject
                );
            case 'EXCLUDE_ARCHIVED_TYPE_REGULAR':
                return filter(
                    projects,
                    project =>
                        project.status !== STATUS_ARCHIVED.value &&
                        project.type !== TYPE_EVENT.value &&
                        project.hasRightsToProject
                );
            case 'EVENTS':
                return filter(projects, project => project.type === TYPE_EVENT.value && project.hasRightsToProject);
            case 'REGULAR':
                return filter(projects, project => project.type === TYPE_REGULAR.value && project.hasRightsToProject);
            case 'MY_MANAGED':
                return filter(
                    projects,
                    project => includes(project.projectManagerIds, loggedInId) && project.hasPMRightsToProject
                );
            default:
                if ('ALL' !== filterType) {
                    return filter(projects, project => project.status !== projectStatus && project.hasRightsToProject);
                }

                return sortBy(
                    filter(projects, project => project.hasRightsToProject),
                    project => project.name.toLowerCase()
                );
        }
    });

export const makeGetActiveEvents = () =>
    createSelector(getProjects, projects => {
        return projects.filter(project => {
            return (
                project.status !== STATUS_ARCHIVED.value &&
                TYPE_EVENT.value === project.type &&
                project.hasRightsToProject
            );
        });
    });

export const makeGetProjectsByResourceIdForSchedule = (
    resourceId,
    includedResources = [],
    { onlyEvents = false, onlyProjects = false, canRequestResources = false, onlyUnassigned = true }
) => {
    return createSelector(getProjects, getAccount, getCompanyExtensions, (projects, account, companyExtensions) => {
        const permittedProjectsForResource = getPermittedProjectsForResource(
            resourceId,
            account,
            { projects, resources: includedResources },
            companyExtensions,
            {
                excludeUnassigned: !onlyUnassigned,
                onlyEvents,
                onlyProjects,
                canRequestResources,
            }
        )
            // Exclude always Archived https://hubplanner.atlassian.net/browse/HUB-9534
            .filter(project => {
                return project.status !== STATUS_ARCHIVED.value;
            });
        return permittedProjectsForResource;
    });
};

export const makeGetProjectsAndEventsForSchedule = (includedResources = [], canRequestResources) => {
    return createSelector(getProjects, getAccount, getCompanyExtensions, (projects, account, companyExtensions) => {
        const permittedProjects = getPermittedProjects(
            account,
            {
                projects,
                resources: includedResources,
            },
            companyExtensions
        )
            // Exclude always Archived https://hubplanner.atlassian.net/browse/HUB-9534
            .projects.filter(project => {
                return project.status !== STATUS_ARCHIVED.value;
            });

        const hasSomeIndividualRights = hasOneOfRoles(account.resourceRoleRights, [
            'scheduleMyselfOnAnyProjectOrEvent',
            'scheduleMyselfOnProjectsOrEventsIAmPartOf',
        ]);

        if (hasSomeIndividualRights) {
            return filter(permittedProjects, project => project.hasRightsToProject || project.canScheduleMySelf);
        }

        if (!permittedProjects.length && canRequestResources) {
            return filter(projects, project => project.hasRightsToProject);
        }

        const fullSchedulingRights = hasRole(account.resourceRoleRights, 'manageEvents');
        if (fullSchedulingRights) {
            return filter(permittedProjects, project => {
                return (
                    project.hasRightsToProject ||
                    (project.resources || []).includes(account.resourceId) ||
                    (project.resourceIds || []).includes(account.resourceId)
                );
            });
        }

        return filter(permittedProjects, project => {
            return project.hasRightsToProject;
        });
    });
};

export const makeProjectById = id =>
    createSelector(getProjects, projects => find(projects, project => project._id === id));

export const makeProjectByIdFromMap = id => createSelector(getProjectsMap, projectsMap => projectsMap[id]);

export const selectDefaultVacationEvent = createSelector(getProjects, selectVacationId, (projects, vacationId) => {
    return (projects || []).find(p => p._id === vacationId);
});
