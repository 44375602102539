export default (args, updatePhase, updateMilestone) => {
    const currentProjectId = args.e.data.projectId;

    if (args.e.data.repeat) {
        //remove repeat when moved
        args.e.data.repeat = false;
        args.e.data.repeatId = null;
        args.e.data.repeatTimes = 0;
        args.e.data.interval = 'NONE';
    }
    args.e.data.projectId = args.newResource;
    args.e.data.start = args.newStart;
    args.e.data.end = args.newEnd;

    const {
        data: { name, start, end, projectId, id, repeatId, repeat, repeatTimes, interval, backColor },
    } = args.e;

    if (args.e.data.milestone) {
        updateMilestone(currentProjectId, {
            name,
            color: backColor,
            date: end,
            start,
            end,
            _id: id,
            project: projectId,
            repeat,
            repeatId,
            repeatTimes,
            interval,
        });
        return;
    }

    updatePhase(id, currentProjectId, {
        name,
        color: backColor,
        start,
        end,
        project: projectId,
        repeat,
        repeatId,
        repeatTimes,
        interval,
    });
};
