import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { find } from 'lodash';
import { getBookingType } from 'enums/bookingTypeEnum';
import { hasRole } from 'utils/rightsUtil';
import { Divider, Icon, Layout, Select, Typography } from '@hub-mono/ui';
import { DATE_FORMATS, formatDate, addYears, startOfYear, endOfYear } from '@hub-mono/utils';
import StorageUtil from '../../../../utils/StorageUtil';
import { updateRequestModel } from '../../../../actions/requestActions';

const rangeOptions = () => {
    const date = new Date();
    const prevYear = addYears(date, -1);
    const nextYear = addYears(date, 1);

    return [
        {
            label: `Last year (${formatDate(prevYear, DATE_FORMATS.YEAR_FORMAT)})`,
            value: 'last',
            range: {
                start: formatDate(startOfYear(prevYear), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                end: formatDate(endOfYear(prevYear), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
            },
        },
        {
            label: `This year (${formatDate(date, DATE_FORMATS.YEAR_FORMAT)})`,
            value: 'this',
            range: {
                start: formatDate(startOfYear(date), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                end: formatDate(endOfYear(date), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
            },
        },
        {
            label: `Next year (${formatDate(nextYear, DATE_FORMATS.YEAR_FORMAT)})`,
            value: 'next',
            range: {
                start: formatDate(startOfYear(nextYear), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                end: formatDate(endOfYear(nextYear), DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
            },
        },
    ];
};

export const Header = props => {
    const { vacationEventName, filter, account, companyTree, flow, resourceId, approvees } = props;
    const dispatch = useDispatch();

    const selectOptions = useMemo(() => {
        return rangeOptions();
    }, []);

    const [range, setRange] = useState(() => {
        return selectOptions.find(option => {
            return option.range.start === props.requestModel?.start;
        }) || selectOptions[1];
    });

    useEffect(() => {
        setRange(selectOptions.find(option => option.range.start === props.requestModel?.start));
    }, [props.requestModel?.start, selectOptions]);

    const text = useMemo(() => {
        return `${vacationEventName && 'ALL' === filter ? 'My' : getBookingType(filter)} ${vacationEventName || ''} ${
            vacationEventName ? 'Dashboard' : 'Requests'
        }`;
    }, [filter, vacationEventName]);

    const title = useMemo(() => {
        if (-1 !== flow.indexOf('FLOW_SINGLE')) {
            if (!hasRole(account.resourceRoleRights, 'manageAllVacations') && account.isApprover) {
                const resource = find(approvees, approvee => approvee._id === resourceId);

                return resource ? `${resource.firstName} ${resource.lastName} Requests` : '';
            }

            return `${companyTree.getResourceNameById(resourceId)} Requests`;
        }

        return undefined;
    }, [account.isApprover, account.resourceRoleRights, approvees, companyTree, flow, resourceId]);

    const handleRangeChange = useCallback(
        (event, value) => {
            setRange(value);
            StorageUtil.setItem('request', value.range);
            dispatch(updateRequestModel(value.range));
        },
        [dispatch]
    );

    return (
        <Layout stack pb={36} gap="--Spacing-100">
            <Layout hAlign="space-between" vAlign="center">
                <Layout gap="--Spacing-100" vAlign="center">
                    <Layout flex="0 0 50px">
                        <Icon name="frame" size={50} />
                    </Layout>
                    <Typography variant="headline" size="medium">
                        {title || text}
                    </Typography>
                </Layout>
                <Layout flex="0 1 210px">
                    <Select
                        value={range}
                        onChange={handleRangeChange}
                        options={selectOptions}
                        label="Choose year"
                        optionLabel="label"
                        optionValue="value"
                        disableClearable
                    />
                </Layout>
            </Layout>
            <Divider />
        </Layout>
    );
};

export default connect(state => ({
    flow: state.requestReducer.getIn(['requestModel', 'flow']),
    filter: state.requestReducer.getIn(['requestModel', 'filter']),
    resourceId: state.requestReducer.getIn(['requestModel', 'resourceId']),
    approvees: state.resourceReducer.approvees,
    account: state.account,
    companyTree: state.companyTree,
    requestModel: state.requestReducer.get('requestModel').toJSON(),
}))(Header);
