import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import { Checkbox } from '../../../../shared/components/checkbox';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const DROPDOWN_WIDTH = 400;
export const SIDE_TEXT_WIDTH = 20;

export const StyledAccordion = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.grey.light}`,

        '&.Mui-expanded': {
            margin: 0,
        },

        '&:last-of-type': {
            borderBottom: `1px solid transparent`,
        },

        '&:before': {
            display: 'none',
        },
    },
}))(Accordion);

export const StyledAccordionSummary = withStyles({
    root: {
        height: '43px',
        minHeight: '43px',
        padding: ({ nested }) => (nested ? '0 0 0 21px' : 0),

        '&.Mui-expanded': {
            height: '43px',
            minHeight: '43px',

            '& .toggleRelation': {
                visibility: 'visible',
            },
        },

        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0,
        },

        '& .toggleRelation': {
            visibility: 'hidden',
        },
    },
})(AccordionSummary);

export const StyledAccordionDetails = withStyles({
    root: {
        padding: 0,
    },
})(AccordionDetails);

const CommonCheckBoxStyles = withStyles({
    root: {
        marginBottom: 0,
    },
})(Checkbox);

export const SelectAllStyledCheckBox = withStyles({
    root: {
        '&.MuiFormControlLabel-root': {
            '& .MuiTypography-body1': {
                fontWeight: 500,
            },
        },
    },
})(CommonCheckBoxStyles);

export const StyledCheckBox = withStyles({
    root: {
        '&.MuiFormControlLabel-root': {
            '& .MuiTypography-body1': {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: ({ withEqualityToggle }) => (withEqualityToggle ? '170px' : '230px'),
            },
        },
    },
})(CommonCheckBoxStyles);

export const AccordionHeader = withStyles({
    root: {
        padding: `10px 0`,
    },
})(Grid);

export const AccordionHeaderText = withStyles({
    root: {
        fontSize: `14px`,
        lineHeight: `21px`,
        fontWeight: 500,
    },
})(Typography);

export const RESOURCES_SIDE_TEXT_RGB_COLOR = [116, 103, 198];
export const PROJECTS_SIDE_TEXT_RGB_COLOR = [74, 174, 255];
export const BOOKINGS_SIDE_TEXT_RGB_COLOR = [50, 166, 117];

export const useActiveFilterDotStyles = makeStyles(theme => ({
    activeFilterDot: ({ nested }) => ({
        position: `absolute`,
        left: nested ? `5px` : `-12px`,
        top: `18px`,
        width: `5px`,
        height: `5px`,
        borderRadius: `100%`,
        backgroundColor: theme.palette.primary.main,
    }),
}));

export const useCommonDropdownFiltersStyles = makeStyles({
    fixedList: {
        '&::-webkit-scrollbar': {
            width: `5px`,
        },
        '&::-webkit-scrollbar-track': {
            background: 'none',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#c8c9d2',
            borderRadius: '4px',
            opacity: 0.6,
            cursor: 'pointer',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#c8c9d2',
            opacity: 1,
        },
    },
    filtersContainer: {
        padding: `0 16px 0 ${26 + SIDE_TEXT_WIDTH}px`,
        position: `relative`,
    },
    sideText: ({ sideTextBgColor = RESOURCES_SIDE_TEXT_RGB_COLOR }) => ({
        position: `absolute`,
        left: 0,
        width: `${SIDE_TEXT_WIDTH}px`,
        height: `100%`,
        backgroundColor: `rgba(${sideTextBgColor}, 0.15)`,
        color: `rgba(${sideTextBgColor}, 1)`,
        textOrientation: `mixed`,
        writingMode: `vertical-rl`,
        transform: `rotate(180deg)`,
        textAlign: `center`,
        fontSize: `14px`,
        lineHeight: `18px`,
    }),
});
