import React, { useMemo } from 'react';
import { showConfirmationModal } from 'actions/modalActions';
import { put, select } from 'redux-saga/effects';
import { store } from '../../store';
import { eventsIdsMoving, removeBookingDots, updateSchedulerBookings } from 'modules/scheduler/utils/schedulerUtil';
import { getViewObjectSelector } from 'selectors/router';
import { getOverscheduleSetting } from 'selectors/company';
import { Layout } from 'shared/components/layout';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate } from 'utils/DateUtil';
import { READABLE_FORMAT, YEAR_MONTH_DAY_FORMAT } from 'global/enums/dateFormat';
import { Paper, makeStyles } from '@material-ui/core';
import { arrayToObjectByKey } from '../../utils/mappingUtil';
import { useAppKeyWords } from '../../hooks';
import classnames from 'classnames';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { createCySelector } from '../../utils/cypressUtil';

const minutesToHours = minutes => {
    const hours = Math.abs(minutes / 60);
    return Number.isInteger(hours) ? hours : hours.toFixed(2);
};

const formatProjectHeader = (project, proKeyWord) => {
    if (!project) {
        return;
    }

    return project?.type ? ('EVENT' === project.type ? 'Event' : proKeyWord) : proKeyWord;
};

const formatResourceName = resource => {
    if (!resource) {
        return;
    }

    return resource?.firstName || resource?.lastName
        ? `${resource?.firstName || ''}${resource?.lastName ? ` ${resource.lastName}` : ''}`
        : undefined;
};

const OverscheduleMessage = ({ additionalInformation, isOverScheduleAllowed }) => {
    const [expanded, setExpanded] = React.useState(false);
    const { projectKeyWord, resourceKeyWord, resourcePluralKeyWord } = useAppKeyWords();

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const areMoreThanOneOverscheduledResources = useMemo(() => {
        const overscheduledResources = additionalInformation.overscheduled.reduce((acc, item) => {
            acc[item.resource._id] = item.resource;
            return acc;
        }, {});

        return Object.keys(overscheduledResources).length > 1;
    }, [additionalInformation.overscheduled]);

    const styles = useStyles({ areMoreThanOneOverscheduledResources });

    const overscheduleByDate = useMemo(() => {
        if (!additionalInformation) {
            return undefined;
        }

        const groupedByDate = additionalInformation.overscheduled.reduce((acc, item) => {
            if (!acc[item['date']]) {
                acc[item['date']] = { [item.resource._id]: item };
            } else {
                acc[item['date']][[item.resource._id]] = item;
            }
            return acc;
        }, {});

        const entries = Object.entries(groupedByDate);

        entries.sort((a, b) => (a[0] > b[0] ? 1 : -1));

        return entries;
    }, [additionalInformation]);

    if (!additionalInformation) {
        return <p>This will lead to booking overschedule. Are you sure, you want to proceed?</p>;
    }

    const calculateOverscheduleValues = overscheduled => {
        return overscheduled.reduce(
            (acc, item) => {
                acc.utilizationMinutes = acc.utilizationMinutes + item.utilizationMinutes;
                acc.capacityMinutes = acc.capacityMinutes + item.capacityMinutes;

                return acc;
            },
            { utilizationMinutes: 0, capacityMinutes: 0 }
        );
    };

    const renderSummary = (date, overscheduled) => {
        const overscheduledResources = Object.values(overscheduled);
        const utilization = calculateOverscheduleValues(overscheduledResources);
        const resource =
            overscheduledResources.length > 1
                ? `${overscheduledResources.length} ${resourcePluralKeyWord}`
                : formatResourceName(overscheduledResources[0].resource);

        const scheduledHours = minutesToHours(utilization.utilizationMinutes);
        const overscheduledHours = minutesToHours(utilization.utilizationMinutes - utilization.capacityMinutes);

        return (
            <Layout
                hAlign="space-between"
                dataCy={`row-${formatDate(
                    date,
                    YEAR_MONTH_DAY_FORMAT
                )}-scheduled-${scheduledHours}hrs-overscheduled-by-${overscheduledHours}hrs`}
            >
                <Layout>
                    <Layout stack className={styles.dateProjectColumn}>
                        <Typography variant="caption">Date</Typography>
                        <Typography variant="subtitle2">{formatDate(new Date(date), READABLE_FORMAT)}</Typography>
                    </Layout>

                    {areMoreThanOneOverscheduledResources ? (
                        <Layout stack className={styles.resourceColumn}>
                            <Typography variant="caption">
                                {overscheduledResources.length > 1 ? resourcePluralKeyWord : resourceKeyWord}
                            </Typography>
                            <Typography variant="subtitle2" className={styles.projectName}>
                                {resource}
                            </Typography>
                        </Layout>
                    ) : null}

                    <Layout stack className={styles.dataColumn}>
                        <Typography variant="caption">Scheduled</Typography>
                        <Typography variant="subtitle2">
                            {scheduledHours}
                            <small> hrs</small>
                        </Typography>
                    </Layout>
                </Layout>
                <Layout stack className={styles.dataColumn}>
                    <Typography variant="caption">
                        {areMoreThanOneOverscheduledResources ? 'Overscheduled' : 'Overscheduled by'}
                    </Typography>
                    <Typography variant="subtitle2" className={styles.overscheduledText}>
                        {overscheduledHours}
                        <small> hrs</small>
                    </Typography>
                </Layout>
            </Layout>
        );
    };

    return (
        <Layout stack className={styles.wrapper} dataCy="overschedule-container">
            {areMoreThanOneOverscheduledResources ? (
                <p data-cy="overschedule-summary-text">
                    Scheduling this booking will overschedule the {resourcePluralKeyWord} below.
                </p>
            ) : (
                <p data-cy="overschedule-summary-text">
                    Scheduling this booking will over schedule{' '}
                    {additionalInformation.overscheduled[0]?.resource ? (
                        <strong>{formatResourceName(additionalInformation.overscheduled[0].resource)}</strong>
                    ) : (
                        resourceKeyWord
                    )}
                </p>
            )}
            <Layout stack className={styles.scrollContainer} padding="2px">
                {overscheduleByDate.map(([date, overscheduled]) => {
                    const getProjectAllocations = bookings => {
                        const allocations = (bookings ?? []).reduce((acc, item) => {
                            if (acc[item.project._id]) {
                                acc[item.project._id].allocation =
                                    acc[item.project._id].allocation +
                                    (item.minutesPerDayWithCustomAvailabilityMappedByDate[date]?.utilizationMinutes ||
                                        0);
                            } else {
                                acc[item.project._id] = {
                                    projectName: item.project.name,
                                    allocation:
                                        item.minutesPerDayWithCustomAvailabilityMappedByDate[date]
                                            ?.utilizationMinutes || 0,
                                    bookingId: item._id,
                                    type: item.project.type,
                                };
                            }

                            return acc;
                        }, {});

                        return Object.values(allocations);
                    };

                    const overscheduledResources = Object.values(overscheduled);

                    return overscheduledResources.length ? (
                        <Accordion
                            key={overscheduled.date}
                            expanded={expanded === `panel${date}`}
                            onChange={handleChange(`panel${date}`)}
                            className={styles.accordion}
                        >
                            <AccordionSummary className={styles.accordionSummary} expandIcon={<ExpandMoreIcon />}>
                                {renderSummary(date, overscheduled)}
                            </AccordionSummary>
                            <AccordionDetails className={styles.dateProjectRowNested}>
                                <Layout stack gap="10px">
                                    {overscheduledResources.map(overscheduledResource => {
                                        const resourceName = formatResourceName(overscheduledResource.resource);
                                        const projectAllocationsArray = getProjectAllocations(
                                            overscheduledResource.bookings
                                        );

                                        return projectAllocationsArray.map((p, i) => (
                                            <Layout
                                                key={i}
                                                dataCy={`${formatDate(date, YEAR_MONTH_DAY_FORMAT)}-${createCySelector(
                                                    resourceName
                                                )}-${createCySelector(p.projectName)}-time-${minutesToHours(
                                                    p.allocation
                                                )}hrs`}
                                            >
                                                <Layout stack className={styles.dateProjectColumnNested}>
                                                    <Typography variant="caption">
                                                        {formatProjectHeader(p, projectKeyWord)}
                                                    </Typography>
                                                    <Typography
                                                        className={classnames(
                                                            styles.projectName,
                                                            p.projectName ? undefined : styles.privateProjectName
                                                        )}
                                                        variant="body1"
                                                    >
                                                        {!p.projectName ? (
                                                            <LockOutlinedIcon className={styles.lockIcon} />
                                                        ) : null}
                                                        {p.projectName ?? 'Private Content'}
                                                    </Typography>
                                                </Layout>

                                                {areMoreThanOneOverscheduledResources ? (
                                                    <Layout stack className={styles.resourceColumn}>
                                                        {i === 0 ? (
                                                            <>
                                                                <Typography variant="caption">
                                                                    {resourceKeyWord}
                                                                </Typography>
                                                                <Typography
                                                                    className={styles.projectName}
                                                                    variant="body1"
                                                                >
                                                                    {resourceName}
                                                                </Typography>
                                                            </>
                                                        ) : null}
                                                    </Layout>
                                                ) : null}

                                                <Layout stack className={styles.dataColumn}>
                                                    <Typography variant="caption">Time</Typography>
                                                    <Typography variant="body1">
                                                        {minutesToHours(p.allocation)}
                                                        <small> hrs</small>
                                                    </Typography>
                                                </Layout>
                                            </Layout>
                                        ));
                                    })}
                                </Layout>
                            </AccordionDetails>
                        </Accordion>
                    ) : (
                        <Layout stack padding="0 0 2px 0">
                            <Paper>
                                <Layout stack padding="10px 16px">
                                    {renderSummary(date, overscheduled)}
                                </Layout>
                            </Paper>
                        </Layout>
                    );
                })}
            </Layout>
            {!isOverScheduleAllowed && <Layout padding="20px 0 0 0">Overschedule is disabled for your company.</Layout>}
        </Layout>
    );
};

const OverscheduleOverlimitMessage = () => {
    return (
        <div>
            Scheduling these dates will create 50 + occurrences of over scheduling, please confirm you would like to
            proceed (there is no undo).
        </div>
    );
};

export const overscheduleHandler = isUpdate => ({
    predicate: err => err?.data?.error === 'OVERSCHEDULE',
    handler: function*(err, action) {
        const viewObject = yield select(getViewObjectSelector());
        const apiOverscheduleType = err?.data?.properties?.additionalInformation?.overscheduleSetting
        const overscheduleType = yield select(getOverscheduleSetting);

        const isOverScheduleAllowed = (apiOverscheduleType || overscheduleType) === 'PARTIAL';
        const newAction = {
            ...action,
            payload: { ...action.payload, options: { ...(action.payload?.options ?? {}), allowOverschedule: true } },
        };

        const onReject = () => {
            if (!isUpdate) {
                return;
            }

            const bookings = err?.data?.properties?.additionalInformation?.originalBookings ?? [];

            removeBookingDots();

            if (window.schedulerRef?.current) {
                updateSchedulerBookings(bookings, viewObject);
            }

            bookings.forEach(booking => {
                eventsIdsMoving.delete(booking._id);
            });
        };

        const additionalInformation = err?.data?.properties?.additionalInformation
            ? {
                  ...err.data.properties.additionalInformation,
                  overscheduled:
                      err.data.properties.additionalInformation.overscheduled?.map(overscheduled => {
                          return {
                              ...overscheduled,
                              bookings:
                                  overscheduled.bookings.map(overscheduledBooking => {
                                      return {
                                          ...overscheduledBooking,
                                          minutesPerDayWithCustomAvailabilityMappedByDate: arrayToObjectByKey(
                                              overscheduledBooking.minutesPerDayWithCustomAvailability || [],
                                              'date'
                                          ),
                                      };
                                  }) || [],
                          };
                      }) || [],
              }
            : undefined;

        yield put(
            showConfirmationModal(
                isOverScheduleAllowed
                    ? () => {
                          store.dispatch(newAction);
                      }
                    : undefined,
                'Booking overschedule warning',
                err.data?.properties?.additionalInformation?.overscheduleOverlimit ? (
                    <OverscheduleOverlimitMessage />
                ) : (
                    <OverscheduleMessage
                        additionalInformation={additionalInformation}
                        isOverScheduleAllowed={isOverScheduleAllowed}
                    />
                ),
                {
                    cancelButtonText: isOverScheduleAllowed ? 'Reject' : 'Close',
                    confirmButtonText: 'Accept',
                    cancelActionOnHide: true,
                    withCancel: true,
                    withEscButton: true,
                    onReject,
                }
            )
        );
    },
});

export const useStyles = makeStyles(theme => ({
    scrollContainer: {
        maxHeight: '360px',
        overflow: 'hidden',
        overflowY: 'auto',
    },
    dateProjectColumn: {
        width: props => (props.areMoreThanOneOverscheduledResources ? '140px' : '200px'),
        paddingRight: '10px',
    },
    dateProjectColumnNested: {
        width: props => (props.areMoreThanOneOverscheduledResources ? '140px' : '200px'),
        paddingLeft: '10px',
        paddingRight: '10px',
        backgroundColor: '#f9f1f1',
    },
    dateProjectRowNested: {
        backgroundColor: '#f9f1f1',
        borderRadius: '4px',
    },
    resourceColumn: {
        width: props => (props.areMoreThanOneOverscheduledResources ? '140px' : '0'),
        paddingRight: '10px',
    },
    dataColumn: {
        width: props => (props.areMoreThanOneOverscheduledResources ? '80px' : '120px'),
    },
    wrapper: { minWidth: '460px' },
    overscheduledText: {
        color: '#cb5050',
    },
    accordion: {
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey.light}`,
        '&.Mui-expanded': {
            margin: 0,
        },
        '&:before': {
            display: 'none',
        },
    },
    accordionSummary: {
        justifyContent: 'flex-start',
    },
    projectName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    privateProjectName: {
        color: theme.palette.grey.hintText,
    },
    lockIcon: {
        fontSize: '.9rem',
        marginTop: '-3px',
    },
}));
