import moment from 'moment';
import { initialReportState } from 'reducers/reportReducer';
import { parse } from 'services/queryString';
import { chain, isNumber, isNaN, isObject, filter, contains, keys, extend, find, pluck, assign } from 'underscore';
import { mergeWith, isNil } from 'lodash';
import {
    DAY,
    WEEK,
    PROJECT_GROUP,
    RESOURCE,
    RESOURCE_GROUP,
    findGroupingByName,
    isDateGrouping,
} from 'modules/report/enums/groupingTypeEnum';
import { dateTypes } from 'modules/report/enums/dateTypeEnum';
import {
    PROJECT as PROJECT_ITEM_TYPE,
    RESOURCE as RESOURCE_ITEM_TYPE,
    replaceMap,
    EVENT as EVENT_ITEM_TYPE,
} from 'modules/report/enums/itemTypeEnum';
import { EVENT_REPORT, findReportTypeBySubTypeAndGroup, UNASSIGNED_REPORT } from 'modules/report/enums/reportTypeEnum';
import StorageUtil from 'utils/StorageUtil';
import { getDateStateBasedOnDates } from 'utils/DateUtil';
import { replaceKeywords } from 'utils/keywordsUtil';
import { mergeCustomizer } from 'utils/mappingUtil';
import { UTILIZATION_STATUSES_NAME_MAP } from '../enums/utilizationStatusEnum';

export const transformFiltersToBackend = filters => {
    const filtered = chain(filters)
        .keys()
        .filter(key => !contains(['projectCustomFields', 'resourceCustomFields'], key))
        .reduce((obj, key) => {
            obj[key] = filters[key];
            return obj;
        }, {})
        .value();

    let mainFilters = chain(filtered)
        .map((values, name) => {
            if ('budgetStatus' === name) {
                return { name, values: values && [values] };
            }
            if ('budgetRange' === name) {
                return {
                    name,
                    values: { ...values, value: parseFloat(values.value) },
                };
            }

            if (name === 'resultValue') {
                return {
                    name,
                    values: values.map(value => {
                        if (
                            [
                                'utilizationStatusAfter',
                                'utilizationStatusPrevious',
                                'utilizationStatusBetween',
                                'utilizationStatusBetween',
                                'reportedUtilizationStatusBetween',
                                'reportedUtilizationStatusPrevious',
                                'scheduledUtilizationStatusAfter',
                                'scheduledUtilizationStatusPrevious',
                                'scheduledUtilizationStatusBetween',
                            ].includes(value.columnName)
                        ) {
                            return {
                                ...value,
                                boundary: (value.boundary ?? []).map(feStatusName => {
                                    return UTILIZATION_STATUSES_NAME_MAP[feStatusName];
                                }),
                            };
                        }

                        if (
                            [
                                'reportedAvailabilityStatusPrevious',
                                'reportedAvailabilityStatusAfter',
                                'reportedAvailabilityStatusBetween',
                                'scheduledAvailabilityStatusAfter',
                                'scheduledAvailabilityStatusBetween',
                                'scheduledAvailabilityStatusPrevious',
                            ].includes(value.columnName)
                        ) {
                            let newValue = {
                                ...value,
                                boundary: [],
                            };
                            const boundary = value.boundary ?? [];

                            if (boundary.includes('Available')) {
                                newValue.boundary.push('UNDER');
                            }

                            if (boundary.includes('Not available')) {
                                newValue.boundary.push('OVER');
                                newValue.boundary.push('ON');
                            }

                            return newValue;
                        }

                        return value;
                    }),
                };
            }

            return { name, values };
        })
        .filter(item => {
            if (
                contains(
                    ['projectStartDate', 'projectEndDate', 'bookingStart', 'bookingEnd', 'timeEntryDate'],
                    item.name
                )
            ) {
                return item.values.operator.length && item.values.date;
            }

            if ('budgetRange' === item.name) {
                return (
                    item.values.operator.length && !isNaN(item.values.value) && isNumber(parseInt(item.values.value))
                );
            }

            return (
                item.values &&
                (contains(['projectStatuses', 'timeEntryStatuses', 'bookingStatuses', 'resourceStatuses'], item.name) ||
                    item.values.length)
            );
        })
        .value();

    let projectCustomFields = chain(filters.projectCustomFields)
        .map(values => ({ name: 'projectCustomFields', values: values.values }))
        .value();

    let resourceCustomFields = chain(filters.resourceCustomFields)
        .map(values => ({
            name: 'resourceCustomFields',
            values: values.values,
        }))
        .value();

    return [...mainFilters, ...projectCustomFields, ...resourceCustomFields];
};

export const formatReportBody = (queryParams, customFields, extensions, accountRoleRights, exportRequest) => {
    const {
        itemIds,
        groupIds: itemGroupIds,
        subType,
        groupBy,
        secondGroupBy,
        itemType,
        startDate,
        endDate,
        sort,
        columnsToLoad,
        filters,
        programmaticallyFilters,
        displayEmptyResults,
        unassignedTime,
        eventTime,
        periodType,
        useCacheOnReportResult,
    } = queryParams;
    const useReportCache = StorageUtil.getItem('useReportCache');

    let currentItemType =
        itemType && itemType.length ? itemType : findReportTypeBySubTypeAndGroup(subType, groupBy).itemType;
    let params = {
        from: moment(startDate).format('YYYY-MM-DD'),
        to: moment(endDate).format('YYYY-MM-DD'),
        groupBy: groupBy && groupBy.length ? replaceKeywords(replaceMap, groupBy) : undefined,
        secondGrouping: secondGroupBy && secondGroupBy.length ? secondGroupBy : undefined,
        itemType: replaceKeywords(replaceMap, currentItemType),
        itemIds,
        itemGroupIds,
        displayEmptyResults,
        periodType,
        unassignedTime,
        eventTime,
        useCacheOnReportResult: useReportCache
            ? 'ON' === useReportCache
            : isNil(useCacheOnReportResult)
            ? true
            : useCacheOnReportResult,
    };

    const allFilters = mergeWith({ ...filters }, programmaticallyFilters, mergeCustomizer);
    const mappedAllFilters = transformFiltersToBackend(allFilters);

    // Load below columns if grouping is 'resource' and there are no present in columnsToLoad
    let resourceColumns = [{ name: 'resourceFirstName' }, { name: 'resourceLastName' }, { name: 'resourceThumbUrl' }];
    let resourceColumnsNotIncluded = filter(
        resourceColumns,
        resourceColumn => !contains(pluck(columnsToLoad, 'name'), resourceColumn.name)
    );
    let resourceColumnsToLoad = RESOURCE.name === groupBy && !exportRequest ? resourceColumnsNotIncluded : [];

    return {
        params,
        filters: mappedAllFilters.length ? mappedAllFilters : undefined,
        columnsToLoad: columnsToLoad.concat(resourceColumnsToLoad),
        sort,
    };
};

export const getItemIdByGrouping = (itemId, groupBy) => (isObject(itemId) ? itemId[groupBy] : itemId);

export const getFiltersBasedOnColumnsToLoad = (filters, columnsToLoad) => {
    const columnsToLoadNames = pluck(columnsToLoad, 'name');
    const splitColumn = find(columnsToLoad, column => column.split);
    const isSplitColumn = columnName => !splitColumn || (splitColumn && splitColumn.name !== columnName);

    return assign({}, filters, {
        resultValue: filter(
            filters.resultValue,
            filter => contains(columnsToLoadNames, filter.columnName) && isSplitColumn(filter.columnName)
        ),
    });
};

export const formatReportDateRange = (periodType, dateState) => {
    const _periodType = 'week' === periodType ? 'isoWeek' : periodType;
    let _startDate = moment().startOf(_periodType);
    let _endDate = moment().endOf(_periodType);

    switch (dateState) {
        case 'Next':
            _startDate.add(1, `${periodType}s`);
            _endDate.add(1, `${periodType}s`).endOf(_periodType);
            break;
        case 'Last':
            _startDate.subtract(1, `${periodType}s`);
            _endDate.subtract(1, `${periodType}s`).endOf(_periodType);
            break;
    }

    return {
        startDate: _startDate.toDate(),
        endDate: _endDate.toDate(),
    };
};

const getFilterName = groupBy => {
    if (-1 !== groupBy.indexOf('project_cf')) {
        return 'projectCustomFields';
    } else if (-1 !== groupBy.indexOf('resource_cf')) {
        return 'resourceCustomFields';
    }

    return groupBy;
};

export const getColumns = groupBy => {
    let columns = StorageUtil.getItem('columns') || {};

    if (!groupBy && find(columns.columnsToLoad, column => 'name' === column.name)) {
        columns.columnsToLoad = filter(columns.columnsToLoad, column => 'name' !== column.name);
        columns.columnsToLoad.unshift({ name: 'date' });
    } else if (groupBy && find(columns.columnsToLoad, column => 'date' === column.name)) {
        columns.columnsToLoad = filter(columns.columnsToLoad, column => 'date' !== column.name);
        columns.columnsToLoad.unshift({ name: 'name' });
    }

    return columns;
};

const isOnlyContextAvailable = storage => 2 <= keys(storage).length && storage.context;

export const getGroupingFilter = (groupBy, value, customFields) => {
    let filter = {};
    const groupingObject = findGroupingByName(groupBy);
    if (keys(groupingObject).length && groupingObject.filterName) {
        filter[groupingObject.filterName] = [value];
    } else {
        let filterName = getFilterName(groupBy);
        if (contains(['projectCustomFields', 'resourceCustomFields'], filterName)) {
            const template = find(customFields, customField => contains(pluck(customField.choices, '_id'), value));
            filter[filterName] = [{ id: template && template._id, values: [value] }];
        } else {
            filter[filterName] = [value];
        }
    }

    return filter;
};

const handleResourceAndProjectGroupGroupBy = (queryParams, id) => {
    let itemType = queryParams.itemType;
    let groupBy = '';
    let groupIds = [id];
    let itemIds = [];
    if (PROJECT_GROUP.name === queryParams.groupBy && RESOURCE_ITEM_TYPE.name === queryParams.itemType) {
        itemType = PROJECT_ITEM_TYPE.name;
        groupBy = RESOURCE_ITEM_TYPE.name;
    } else if (
        RESOURCE_GROUP.name === queryParams.groupBy &&
        contains([PROJECT_ITEM_TYPE.name, EVENT_ITEM_TYPE.name], queryParams.itemType)
    ) {
        itemType = RESOURCE_ITEM_TYPE.name;
        groupBy = PROJECT_ITEM_TYPE.name;
    }

    return {
        itemType,
        groupBy,
        groupIds,
        itemIds,
    };
};

const applySecondGroupingFilter = (storagePreviousReportQuery, storageReportQuery) => {
    if (storagePreviousReportQuery.secondGroupBy && storageReportQuery.secondGroupById) {
        return getGroupingFilter(storagePreviousReportQuery.secondGroupBy, storageReportQuery.secondGroupById);
    }

    return {};
};

/**
 * We are setting the groupBy (only if it's not a itemType e.g Client, BookingCategories)
 * from 1st level as a filter on 2nd level with the value of the clicked row
 * Except resource/project group groupings
 *
 * @param { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps }
 * @param {string}        urlParams
 * @param {object}        reportCalculations
 * @param {string|number} id
 * @param {array}         customFields
 */
const reportDetailsFormatter = (
    { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps },
    urlParams,
    reportCalculations,
    id,
    customFields,
    currentQueryParams
) => {
    const urlQueryParams = parse(urlParams);
    let groupBy = urlQueryParams.groupBy || storageReportQuery.groupBy || '';
    let itemType = storagePreviousReportQuery.itemType || storageReportQuery.itemType;
    let programmaticallyFilters = extend(
        {},
        initialReportState.queryParams.programmaticallyFilters,
        storagePreviousReportQuery.programmaticallyFilters,
        applySecondGroupingFilter(storagePreviousReportQuery, storageReportQuery)
    );
    let groupIds = storageReportQuery.groupIds || storagePreviousReportQuery.groupIds;
    let itemIds = storageReportQuery.itemIds || storagePreviousReportQuery.itemIds;
    const { unassignedTime: companyUnassignedTime, eventTime: companyEventTime } = reportCalculations;
    const columns = getColumns(groupBy);

    if (
        storagePreviousReportQuery.groupBy !== storagePreviousReportQuery.itemType &&
        !contains([RESOURCE_GROUP.name, PROJECT_GROUP.name], storagePreviousReportQuery.groupBy)
    ) {
        programmaticallyFilters = extend(
            {},
            programmaticallyFilters,
            getGroupingFilter(storagePreviousReportQuery.groupBy, id, customFields)
        );
    } else if (contains([RESOURCE_GROUP.name, PROJECT_GROUP.name], storagePreviousReportQuery.groupBy)) {
        let resourceAndProjectGroupHandler = handleResourceAndProjectGroupGroupBy(storagePreviousReportQuery, id);
        itemType = resourceAndProjectGroupHandler.itemType;
        groupBy = resourceAndProjectGroupHandler.itemType;
        groupIds = resourceAndProjectGroupHandler.groupIds;
        itemIds = resourceAndProjectGroupHandler.itemIds;
    } else {
        groupIds = [];
        itemIds = [id];
    }

    return extend({}, storagePreviousReportQuery, storageReportQuery, reportDateProps, columns, {
        startDate: new Date(reportDateProps.startDate),
        endDate: new Date(reportDateProps.endDate),
        type: storageReportQuery.type,
        id: id || '',
        limit: storageReportQuery.limit || 20,
        page: storageReportQuery.page || 0,
        secondGroupBy: storageReportQuery.secondGroupBy || '',
        groupIds,
        itemIds,
        itemType,
        groupBy,
        filters,
        programmaticallyFilters,
        eventTime:
            storagePreviousReportQuery.eventTime !== companyEventTime || EVENT_REPORT.value === storageReportQuery.type
                ? storagePreviousReportQuery.eventTime
                : undefined,
        unassignedTime:
            storagePreviousReportQuery.unassignedTime !== companyUnassignedTime ||
            UNASSIGNED_REPORT.value === storageReportQuery.type
                ? storagePreviousReportQuery.unassignedTime
                : undefined,
        displayEmptyResults:
            storageReportQuery.displayEmptyResults === undefined
                ? currentQueryParams?.displayEmptyResults
                : storageReportQuery.displayEmptyResults,
    });
};

/**
 * Level 3 formatter set the correct filter by previous groupBy and current groupBy by default to DAY
 *
 * @param { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps }
 * @param {object}        reportCalculations
 * @param {string|number} id
 * @param {array}         customFields
 */
const reportLevel3Formatter = (
    { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps },
    reportCalculations,
    id,
    customFields,
    currentQueryParams
) => {
    const { unassignedTime: companyUnassignedTime, eventTime: companyEventTime } = reportCalculations;
    const groupBy =
        isOnlyContextAvailable(storageReportQuery) || storageReportQuery.hasOwnProperty('groupBy')
            ? storageReportQuery.groupBy || DAY.name
            : undefined;
    const columns = getColumns(groupBy);

    let programmaticallyFilters = extend(
        {},
        storagePreviousReportQuery.programmaticallyFilters,
        applySecondGroupingFilter(storagePreviousReportQuery, storageReportQuery)
    );
    let itemIds = [id];
    let groupIds = [];

    if (storagePreviousReportQuery.groupBy !== replaceKeywords(replaceMap, storagePreviousReportQuery.itemType)) {
        programmaticallyFilters = extend(
            {},
            programmaticallyFilters,
            getGroupingFilter(storagePreviousReportQuery.groupBy, id, customFields)
        );
        const previousGroupIds = storagePreviousReportQuery.groupIds;

        itemIds = previousGroupIds.length ? [] : [storagePreviousReportQuery.id];
        groupIds = previousGroupIds.length ? previousGroupIds : [];
    }

    filters.resultValue = storageReportQuery.filters ? storageReportQuery.filters.resultValue : [];

    return extend({}, storagePreviousReportQuery, storageReportQuery, reportDateProps, columns, {
        startDate: new Date(reportDateProps.startDate),
        endDate: new Date(reportDateProps.endDate),
        type: storageReportQuery.type || storagePreviousReportQuery.type,
        id: id || '',
        secondGroupBy: storageReportQuery.secondGroupBy || '',
        limit: storageReportQuery.limit || 20,
        page: storageReportQuery.page || 0,
        groupIds,
        itemIds,
        programmaticallyFilters,
        groupBy,
        filters,
        eventTime:
            storagePreviousReportQuery.eventTime !== companyEventTime || EVENT_REPORT.value === storageReportQuery.type
                ? storagePreviousReportQuery.eventTime
                : undefined,
        unassignedTime:
            storagePreviousReportQuery.unassignedTime !== companyUnassignedTime ||
            UNASSIGNED_REPORT.value === storageReportQuery.type
                ? storagePreviousReportQuery.unassignedTime
                : undefined,
        displayEmptyResults:
            storageReportQuery.displayEmptyResults === undefined
                ? currentQueryParams?.displayEmptyResults
                : storageReportQuery.displayEmptyResults,
    });
};

/**
 * Level 4 formatter
 *
 * @param { storageReportQuery, storagePreviousReportQuery, filters }
 * @param {string} queryDate
 */
const reportLevel4Formatter = (
    { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps },
    queryDate
) => {
    const groupBy = storageReportQuery.groupBy || undefined;
    const columns = getColumns(groupBy);
    const grouping = findGroupingByName(storagePreviousReportQuery.groupBy);
    const dateType = dateTypes[grouping.value];
    const date = dateType && moment(storageReportQuery.context.itemId || queryDate, dateType.format);
    const unitOfTime = grouping.name === WEEK.name ? 'isoWeek' : grouping.name;
    const programmaticallyFilters = extend({}, storagePreviousReportQuery.programmaticallyFilters);
    let startDate, endDate;
    let dateState = {};
    if (date) {
        startDate = date.clone().startOf(unitOfTime);
        endDate = date.clone().endOf(unitOfTime);
        dateState = getDateStateBasedOnDates(startDate, endDate);
    } else {
        startDate = moment(new Date(reportDateProps.startDate));
        endDate = moment(new Date(reportDateProps.endDate));
    }
    filters.resultValue = storageReportQuery.filters ? storageReportQuery.filters.resultValue : [];

    return extend({}, storagePreviousReportQuery, storageReportQuery, reportDateProps, columns, {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        limit: storageReportQuery.limit || 20,
        page: storageReportQuery.page || 0,
        id: queryDate,
        programmaticallyFilters,
        secondGroupBy: '',
        secondGroupById: '',
        filters,
        groupBy,
        periodType: storageReportQuery.periodType || dateState.periodType || 'custom',
        dateState: storageReportQuery.dateState || dateState.dateState || '',
    });
};

const getLastValueFromParams = urlParams => {
    if (urlParams.date) {
        return urlParams.date;
    }

    if (urlParams.subId) {
        return urlParams.subId;
    }

    return urlParams.id;
};

/**
 * Levels formatter
 *
 * @param { storageReportQuery, storagePreviousReportQuery }
 * @param { urlParams, urlQueryParams }
 * @param reportCalculations
 * @param customFields
 *
 * @returns {object}
 */
export const reportLevelsFormatter = (
    { storageReportQuery, storagePreviousReportQuery, currentQueryParams },
    { urlParams, urlQueryParams },
    reportCalculations,
    customFields
) => {
    const filters = StorageUtil.getItem('filters') || {};
    const reportDateProps = StorageUtil.getItem('datesStorage') || {};
    const storageItems = { storageReportQuery, storagePreviousReportQuery, filters, reportDateProps };

    if (urlParams.date || (isDateGrouping(storagePreviousReportQuery.groupBy) && storagePreviousReportQuery.groupBy)) {
        return reportLevel4Formatter(storageItems, getLastValueFromParams(urlParams));
    } else if (urlParams.id && !urlParams.subId && !urlParams.date) {
        return reportDetailsFormatter(
            storageItems,
            urlQueryParams,
            reportCalculations,
            urlParams.id,
            customFields,
            currentQueryParams
        );
    } else if (urlParams.subId && !urlParams.date) {
        return reportLevel3Formatter(
            storageItems,
            reportCalculations,
            urlParams.subId,
            customFields,
            currentQueryParams
        );
    }

    return storageReportQuery;
};
