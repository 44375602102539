import React from 'react';
import PropTypes from 'prop-types';
import NestedMenuItem, {NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} from 'shared/nestedMenuItem';
import PhaseForm from '../phaseForm';
import Scrollbar from 'shared/scrollbar';
import { WrappedMenuItem } from '../../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { Layout } from 'shared/components/layout/index';

const LegacyProjectGroupMode = ({ updatePhase, deletePhase, project, currentSelectedBooking }) => (
    <Layout stack padding="0.5rem 0">
        <NestedMenuItem label="Edit Phase" parentMenuOpen={true} width={300}>
            <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                <PhaseForm project={project} currentSelection={currentSelectedBooking} onClick={updatePhase} />
            </Scrollbar>
        </NestedMenuItem>
        <WrappedMenuItem onClick={deletePhase}>Delete phase</WrappedMenuItem>
    </Layout>
);

LegacyProjectGroupMode.propTypes = {
    updatePhase: PropTypes.func,
};

LegacyProjectGroupMode.defaultProps = {
    updatePhase: null,
};

export default LegacyProjectGroupMode;
