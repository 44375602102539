import React, { useCallback, useState, useMemo, useEffect } from 'react';
import Flow from './Flow';
import { store } from '../../store';
import { createMultipleProjects, deleteProject, getProjects } from '../../actions/projectActions';
import {
    getResources,
    createResource,
    createMultipleResources,
    updateResource,
    deleteResource,
} from '../../actions/resourceActions';
import { createResourceGroup, updateResourceGroup } from '../../actions/resourceGroupActions';
import { createProjectGroup, updateProjectGroup } from '../../actions/projectGroupActions';
import { createCategoryTemplate, updateCategoryTemplate } from '../../actions/categoryTemplateActions';
import { getCategoryGroups } from '../../actions/categoryGroupActions';
import { createBookings } from '../../actions/bookingActions';
import { changeActiveLinkFromLocation } from '../../actions/menu/content';
import { DEFAULT_CATEGORY_COLOR } from '../../enums/colorEnum';
import { TYPE_UNASSIGNED } from '../../enums/resourceEnum';
import { TYPE_EVENT } from '../../enums/projectEnum';
import { SYSTEM } from '../../modules/scheduler/enums/groupTypeEnum';
import { ACTIVE } from '../../enums/criteriaEnum';
import { DEFAULT as DEFAULT_CATEGORY_GROUP_TYPE } from '../../enums/categoryGroupTypeEnum';
import { format } from 'date-fns';
import { BOOKING_FORMAT } from '../../global/enums/dateFormat';
import WelcomeOnboardingStepContent from './WelcomeOnboardingStepContent';
import ResourcesOnboardingStepContent, { resourcesStepKey } from './ResourcesOnboardingStepContent';
import ResourceGroupsOnboardingStepContent, { resourceGroupsStepKey } from './ResourceGroupsOnboardingStepContent';
import ProjectsOnboardingStepContent, { projectsStepKey } from './ProjectsOnboardingStepContent';
import ProjectGroupsOnboardingStepContent, { projectGroupsStepKey } from './ProjectGroupsOnboardingStepContent';
import CategoriesOnboardingStepContent, { categoriesStepKey } from './CategoriesOnboardingStepContent';
import UnassignedWorkOnboardingStepContent, { uwStepKey } from './UnassignedWorkOnboardingStepContent';
import EventsOnboardingStepContent, { eventsStepKey } from './EventsOnboardingStepContent';
import CreateBookingOnboardingStepContent, { bookingStepKey } from './CreateBookingOnboardingStepContent';
import { useLocation } from 'react-router-dom';
import { ROLE_OWNER } from 'enums/resourceEnum';
import { getAccount } from 'selectors/account';
import { useSelector } from 'react-redux';

const getStepData = (sectionKey, storedData) => {
    const valuesTmp = {};
    const metaTmp = {};
    Object.keys(storedData)
        .filter(key => key.indexOf(`${sectionKey}-`) === 0 && storedData[key] != null)
        .sort()
        .forEach(key => {
            const splitted = key.split('-');
            if (key.indexOf(`${sectionKey}-values-`) === 0) {
                const indexOfRow = parseInt(splitted[2]);
                if (!valuesTmp[indexOfRow]) {
                    valuesTmp[indexOfRow] = {};
                }
                if (valuesTmp[indexOfRow]) {
                    valuesTmp[indexOfRow][splitted[3]] = storedData[key];
                }
            } else if (key.indexOf(`${sectionKey}-meta-`) === 0) {
                metaTmp[splitted[2]] = storedData[key];
            }
        });

    const stepData = {
        values: Object.values(valuesTmp),
        meta: metaTmp,
    };
    return stepData;
};

const getNumberOfInitialNumberOfRowsBasedOnKey = (storedData, key) => {
    return getStepData(key, storedData).values.length;
};

// AVailable for optimizations, but not finished.
// Data can be checked between steps to save api calls
// getRowHasChanged,
// getIndexRowChanged,

const getOnboardingFlow = ({
    storedData,
    setKeyData,
    setMultipleKeysData,
    resetData,
    getStepHandlers,
    beforeNextStepChecks,
}) => {
    return {
        id: 'onboarding-flow',
        onLoad: () => {
            store.dispatch(getResources.request());
            store.dispatch(getProjects.request());
            store.dispatch(
                getCategoryGroups.request({
                    force: false,
                    callback: categoryGroups => {
                        const nonOptionalGroupCategories =
                            categoryGroups.find(catGroup => catGroup.type === 'NON_OPTIONAL')?.categories || [];
                        const categoriesToSetAsDefined = nonOptionalGroupCategories.filter((cat, index) => index < 5);
                        const predefinedDataToLoad = {
                            [`${categoriesStepKey}-meta-createdEntities`]: categoriesToSetAsDefined,
                            ...categoriesToSetAsDefined.reduce((acc, cat, index) => {
                                return {
                                    ...acc,
                                    [`${categoriesStepKey}-values-${index}-name`]: cat.name,
                                };
                            }, {}),
                        };
                        setMultipleKeysData(predefinedDataToLoad);
                    },
                })
            );
        },
        steps: [
            {
                content: ({ skip, goNext }) => <WelcomeOnboardingStepContent skip={skip} goNext={goNext} />,
                disabled: false,
                disabledFooter: true,
            },
            {
                title: 'Your team',
                subTitle: 'Add your team members to track their availability.',
                stepKey: resourcesStepKey,
                content: () => (
                    <ResourcesOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, resourcesStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(resourcesStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(resourcesStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${resourcesStepKey}-meta-creating`, true);

                        const stepData = getStepData(resourcesStepKey, storedData);

                        // Delete previous ones.
                        (stepData.meta?.createdEntities || []).forEach(entity => {
                            store.dispatch(deleteResource.request(entity._id));
                        });

                        // Create in one request all
                        const dataTransformedForRequest = stepData.values.map(resourceRow => {
                            return {
                                companySpecific: {
                                    firstName: resourceRow.firstName,
                                    lastName: resourceRow.lastName,
                                    // If not checked, by default is checked.
                                    role:
                                        resourceRow.isAdmin == null
                                            ? 'ROLE_ADMIN'
                                            : resourceRow.isAdmin
                                            ? 'ROLE_ADMIN'
                                            : 'ROLE_TEAM',
                                    invite: !!resourceRow.email && !!resourceRow.invite,
                                    email: resourceRow.email,
                                },
                                resource: {
                                    invite: !!resourceRow.email && !!resourceRow.invite,
                                    email: resourceRow.email,
                                },
                            };
                        });
                        store.dispatch(
                            createMultipleResources.request(
                                dataTransformedForRequest,
                                resourcesCreated => {
                                    setMultipleKeysData({
                                        [`${resourcesStepKey}-meta-createdEntities`]: resourcesCreated,
                                        [`${resourcesStepKey}-meta-creating`]: false,
                                    });
                                },
                                true
                            )
                        );
                    });
                },
                frozen:
                    storedData[`${resourcesStepKey}-meta-creating`] ||
                    (storedData[`${resourcesStepKey}-meta-showValidationErrors`] &&
                        storedData[`${resourcesStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Teams',
                subTitle: 'Create teams to represent departments or groups of resources.',
                stepKey: resourceGroupsStepKey,
                content: () => (
                    <ResourceGroupsOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(
                            storedData,
                            resourceGroupsStepKey
                        )}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(resourceGroupsStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(resourceGroupsStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${resourceGroupsStepKey}-meta-creating`, true);

                        const stepData = getStepData(resourceGroupsStepKey, storedData);
                        const entitiesProcessed = [];

                        const checkCalls = () => {
                            if (entitiesProcessed.length === stepData.values.length) {
                                setMultipleKeysData({
                                    [`${resourceGroupsStepKey}-meta-createdEntities`]: entitiesProcessed,
                                    [`${resourceGroupsStepKey}-meta-creating`]: false,
                                });
                            }
                        };

                        const callback = result => {
                            entitiesProcessed.push(result);
                            checkCalls();
                        };

                        stepData.values.forEach((rowData, rowIndex) => {
                            const { name } = rowData;

                            const toUpdate = !!stepData?.meta?.createdEntities?.[rowIndex]?._id;
                            const dataComputedFromValues = {
                                name,
                                type: 'GROUP',
                                queryParams: {
                                    itemIds: [],
                                    itemGroupIds: [],
                                    groupsToAdd: [],
                                    groupsToRemove: [],
                                    itemsToAdd: [],
                                    itemsToRemove: [],
                                    filterRelation: 'ALL',
                                    itemType: 'resource',
                                    filters: [],
                                },
                            };

                            if (toUpdate) {
                                // Update
                                store.dispatch(
                                    updateResourceGroup.request({
                                        id: stepData.meta?.createdEntities?.[rowIndex]._id,
                                        data: dataComputedFromValues,
                                        callback,
                                    })
                                );
                            } else {
                                // Create
                                store.dispatch(createResourceGroup.request(dataComputedFromValues, false, callback));
                            }
                        });
                    });
                },
                frozen:
                    storedData[`${resourceGroupsStepKey}-meta-creating`] ||
                    (storedData[`${resourceGroupsStepKey}-meta-showValidationErrors`] &&
                        storedData[`${resourceGroupsStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Your projects',
                subTitle: 'Add your projects to start your resource management in a structured way at once.',
                stepKey: projectsStepKey,
                content: () => (
                    <ProjectsOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, projectsStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(projectsStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(projectsStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${projectsStepKey}-meta-creating`, true);

                        const stepData = getStepData(projectsStepKey, storedData);

                        // Delete previous ones.
                        (stepData.meta?.createdEntities || []).forEach(entity => {
                            store.dispatch(deleteProject.request(entity._id));
                        });

                        // Create new ones
                        const dataTransformedForRequest = stepData.values.map(projectRow => {
                            return {
                                project: projectRow,
                            };
                        });
                        store.dispatch(
                            createMultipleProjects.request(
                                dataTransformedForRequest,
                                projectsCreated => {
                                    setMultipleKeysData({
                                        [`${projectsStepKey}-meta-createdEntities`]: projectsCreated,
                                        [`${projectsStepKey}-meta-creating`]: false,
                                    });
                                },
                                true
                            )
                        );
                    });
                },
                frozen:
                    storedData[`${projectsStepKey}-meta-creating`] ||
                    (storedData[`${projectsStepKey}-meta-showValidationErrors`] &&
                        storedData[`${projectsStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Group of projects',
                subTitle: 'Here you can group you projects together in departments, project client, priorities etc.',
                stepKey: projectGroupsStepKey,
                content: () => (
                    <ProjectGroupsOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, projectGroupsStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(projectGroupsStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(projectGroupsStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${projectGroupsStepKey}-meta-creating`, true);

                        const stepData = getStepData(projectGroupsStepKey, storedData);

                        const entitiesProcessed = [];
                        const checkCalls = () => {
                            if (entitiesProcessed.length === stepData.values.length) {
                                setMultipleKeysData({
                                    [`${projectGroupsStepKey}-meta-createdEntities`]: entitiesProcessed,
                                    [`${projectGroupsStepKey}-meta-creating`]: false,
                                });
                            }
                        };

                        const callback = result => {
                            entitiesProcessed.push(result);
                            checkCalls();
                        };

                        stepData.values.forEach((rowData, rowIndex) => {
                            const { name } = rowData;

                            const toUpdate = !!stepData?.meta?.createdEntities?.[rowIndex]?._id;
                            const dataComputedFromValues = {
                                name,
                                type: 'GROUP',
                                queryParams: {
                                    itemIds: [],
                                    itemGroupIds: [],
                                    groupsToAdd: [],
                                    groupsToRemove: [],
                                    itemsToAdd: [],
                                    itemsToRemove: [],
                                    filterRelation: 'ALL',
                                    itemType: 'project',
                                    filters: [],
                                },
                            };
                            if (toUpdate) {
                                store.dispatch(
                                    updateProjectGroup.request({
                                        id: stepData?.meta?.createdEntities?.[rowIndex]?._id,
                                        data: dataComputedFromValues,
                                        callback,
                                    })
                                );
                            } else {
                                store.dispatch(createProjectGroup.request(dataComputedFromValues, false, callback));
                            }
                        });
                    });
                },
                frozen:
                    storedData[`${projectGroupsStepKey}-meta-creating`] ||
                    (storedData[`${projectGroupsStepKey}-meta-showValidationErrors`] &&
                        storedData[`${projectGroupsStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Work categories',
                subTitle:
                    'Please take into consideration all the characteristics of all your projects. Is this creativity, development, ABC, XYZ....',
                stepKey: categoriesStepKey,
                content: () => (
                    <CategoriesOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, categoriesStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(categoriesStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(categoriesStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${categoriesStepKey}-meta-creating`, true);

                        const stepData = getStepData(categoriesStepKey, storedData);
                        const entitiesProcessed = [];
                        const checkCalls = () => {
                            if (entitiesProcessed.length === stepData.values.length) {
                                setMultipleKeysData({
                                    [`${categoriesStepKey}-meta-createdEntities`]: entitiesProcessed,
                                    [`${categoriesStepKey}-meta-creating`]: false,
                                });
                            }
                        };

                        const callback = result => {
                            entitiesProcessed.push(result);
                            checkCalls();
                        };

                        const categoryGroups = store.getState()?.categoryGroupReducer?.categoryGroups || [];
                        const defaultCategoryGroup = categoryGroups.find(categoryGroup => {
                            return categoryGroup.type === DEFAULT_CATEGORY_GROUP_TYPE;
                        });
                        stepData.values.forEach((rowData, rowIndex) => {
                            const toUpdate = !!stepData?.meta?.createdEntities?.[rowIndex]?._id;
                            const dataComputedFromValues = {
                                ...rowData,
                                groupId: defaultCategoryGroup._id,
                                gridColor: DEFAULT_CATEGORY_COLOR,
                            };

                            if (toUpdate) {
                                store.dispatch(
                                    updateCategoryTemplate.request(
                                        stepData?.meta?.createdEntities?.[rowIndex]?._id,
                                        {
                                            ...stepData?.meta?.createdEntities?.[rowIndex],
                                            ...dataComputedFromValues,
                                        },
                                        callback
                                    )
                                );
                            } else {
                                store.dispatch(createCategoryTemplate.request(dataComputedFromValues, callback));
                            }
                        });
                    });
                },
                frozen:
                    storedData[`${categoriesStepKey}-meta-creating`] ||
                    (storedData[`${categoriesStepKey}-meta-showValidationErrors`] &&
                        storedData[`${categoriesStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Demand work',
                subTitle:
                    'Not sure which team member will do the work? Simply schedule the work in by assigning it to a demand category. Here you can add some demand categories that your organization is expecting to come.',
                stepKey: uwStepKey,
                content: () => (
                    <UnassignedWorkOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, uwStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(uwStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(uwStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${uwStepKey}-meta-creating`, true);

                        const stepData = getStepData(uwStepKey, storedData);

                        const entitiesProcessed = [];
                        const checkCalls = () => {
                            if (entitiesProcessed.length === stepData.values.length) {
                                setMultipleKeysData({
                                    [`${uwStepKey}-meta-createdEntities`]: entitiesProcessed,
                                    [`${uwStepKey}-meta-creating`]: false,
                                });
                            }
                        };

                        const callback = result => {
                            entitiesProcessed.push(result);
                            checkCalls();
                        };

                        const dataTransformedForRequest = stepData.values.map(rowData => {
                            return {
                                companySpecific: {
                                    firstName: rowData.name,
                                },
                                resource: {
                                    type: TYPE_UNASSIGNED.value,
                                },
                            };
                        });

                        dataTransformedForRequest.forEach((requestData, rowIndex) => {
                            const toUpdate = !!stepData?.meta?.createdEntities?.[rowIndex]?._id;
                            if (toUpdate) {
                                store.dispatch(
                                    updateResource.request(
                                        stepData?.meta?.createdEntities?.[rowIndex]?._id,
                                        {
                                            currentCompanySpecific: {
                                                ...(stepData?.meta?.createdEntities?.[rowIndex]
                                                    .currentCompanySpecific || {}),
                                                ...requestData.companySpecific,
                                            },
                                            resource: {
                                                ...(stepData?.meta?.createdEntities?.[rowIndex].resource || {}),
                                                ...requestData.resource,
                                            },
                                        },
                                        callback
                                    )
                                );
                            } else {
                                store.dispatch(createResource.request(requestData, callback, false));
                            }
                        });
                    });
                },
                frozen:
                    storedData[`${uwStepKey}-meta-creating`] ||
                    (storedData[`${uwStepKey}-meta-showValidationErrors`] &&
                        storedData[`${uwStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                title: 'Events',
                subTitle:
                    'Create and edit your own events in Hub Planner. Events can be holidays, sick days, personal time or other types of leave.',
                stepKey: eventsStepKey,
                content: () => (
                    <EventsOnboardingStepContent
                        initialNumberOfRows={getNumberOfInitialNumberOfRowsBasedOnKey(storedData, eventsStepKey)}
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        handlers={getStepHandlers(eventsStepKey)}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    beforeNextStepChecks(eventsStepKey, changeToNextStepCb, () => {
                        // do not wait api calls
                        changeToNextStepCb();
                        setKeyData(`${eventsStepKey}-meta-creating`, true);

                        const stepData = getStepData(eventsStepKey, storedData);

                        // Delete previous ones.
                        (stepData.meta?.createdEntities || []).forEach(entity => {
                            store.dispatch(deleteProject.request(entity._id));
                        });

                        // Create in one request all
                        const dataTransformedForRequest = stepData.values.map(projectRow => {
                            return {
                                project: {
                                    ...projectRow,
                                    type: TYPE_EVENT.value,
                                },
                            };
                        });
                        store.dispatch(
                            createMultipleProjects.request(
                                dataTransformedForRequest,
                                eventsCreated => {
                                    setMultipleKeysData({
                                        [`${eventsStepKey}-meta-createdEntities`]: eventsCreated,
                                        [`${eventsStepKey}-meta-creating`]: false,
                                    });
                                },
                                true
                            )
                        );
                    });
                },
                frozen:
                    storedData[`${eventsStepKey}-meta-creating`] ||
                    (storedData[`${eventsStepKey}-meta-showValidationErrors`] &&
                        storedData[`${eventsStepKey}-meta-hasValidationErrors`]),
                disabled: false,
            },
            {
                content: ({ skip, goPrevious, goNext }) => (
                    <CreateBookingOnboardingStepContent
                        storedData={storedData}
                        setKeyData={setKeyData}
                        setMultipleKeysData={setMultipleKeysData}
                        skip={skip}
                        goPrevious={goPrevious}
                        goNext={goNext}
                    />
                ),
                beforeNextStep: changeToNextStepCb => {
                    const resourceSelected = storedData[`${bookingStepKey}-values-resource`];
                    const projectSelected = storedData[`${bookingStepKey}-values-project`];
                    const categorySelected = storedData[`${bookingStepKey}-values-category`];
                    const start = storedData[`${bookingStepKey}-values-start`];
                    const end = storedData[`${bookingStepKey}-values-end`];

                    const bookingObj = {
                        start: format(start, BOOKING_FORMAT),
                        end: format(end, BOOKING_FORMAT),
                        categoryTemplateId: categorySelected._id,
                        resource: resourceSelected._id,
                        project: projectSelected._id,
                    };

                    store.dispatch(
                        createBookings.request([bookingObj], [], () => {
                            const activeResourcesGroup = (
                                store.getState()?.resourceGroupReducer?.resourceGroups || []
                            ).find(resourceGroup => {
                                return resourceGroup.groupType === SYSTEM && resourceGroup.criteria === ACTIVE;
                            });
                            if (activeResourcesGroup?._id && resourceSelected._id) {
                                window.location.hash = `/resource/${resourceSelected._id}/group/${activeResourcesGroup._id}`;
                                store.dispatch(changeActiveLinkFromLocation());
                            }

                            changeToNextStepCb();
                        })
                    );
                },
                allSpace: true,
            },
        ],
        onFinished: () => {
            resetData('onboarding-flow');
            window.location.replace('/main#/?mfa-after-on-boarding');
        },
    };
};

const getFirstAvailableFlow = (
    { storedData, setKeyData, setMultipleKeysData, resetData, getStepHandlers, beforeNextStepChecks, getRowHasChanged },
    account,
    location
) => {
    if (location.search.indexOf('?welcome-to-hubplanner-3213444') > -1 && account?.resourceRole === ROLE_OWNER.value) {
        return getOnboardingFlow({
            storedData,
            setKeyData,
            setMultipleKeysData,
            resetData,
            getStepHandlers,
            beforeNextStepChecks,
            getRowHasChanged,
        });
    }
};

export const Flows = React.memo(() => {
    const [flowStoredData, setFlowStoredData] = useState({});
    const [showId, setShowId] = useState('');

    const account = useSelector(getAccount);
    const location = useLocation();

    const setKeyData = useCallback(
        (key, value) => {
            setFlowStoredData({
                ...flowStoredData,
                [key]: value,
            });
        },
        [flowStoredData, setFlowStoredData]
    );

    const setMultipleKeysData = useCallback(
        obj => {
            setFlowStoredData({
                ...flowStoredData,
                ...obj,
            });
        },
        [flowStoredData, setFlowStoredData]
    );

    const resetData = useCallback(flowId => {
        setFlowStoredData({});
        // hide it immediately
        setShowId(`noShow-${flowId}`);
    }, []);

    const getFlowStoredDataWithDeletedStepData = useCallback(
        stepKey => {
            return Object.entries(flowStoredData).reduce((acc, cv) => {
                if (cv[0].indexOf(`${stepKey}-values-`) === 0) {
                    return acc;
                }
                return {
                    ...acc,
                    [cv[0]]: cv[1],
                };
            }, {});
        },
        [flowStoredData]
    );

    // Orders the rows values inserted by user
    const organizeStepValues = useCallback(
        stepKey => {
            if (!stepKey) {
                return;
            }

            const stepData = getStepData(stepKey, flowStoredData);

            const newStepValues = stepData.values.reduce((acc, cv, index) => {
                const rowValues = Object.entries(cv).reduce((acc2, cv2) => {
                    return {
                        ...acc2,
                        [`${stepKey}-values-${index}-${cv2[0]}`]: cv2[1],
                    };
                }, {});

                return {
                    ...acc,
                    ...rowValues,
                };
            }, {});

            const newFlowStoredData = {
                ...getFlowStoredDataWithDeletedStepData(stepKey),
                ...newStepValues,
            };

            setFlowStoredData(newFlowStoredData);
        },
        [flowStoredData, getFlowStoredDataWithDeletedStepData]
    );

    const getOnValueChange = useCallback(
        stepKey => {
            return (key, value) => {
                setKeyData(`${stepKey}-values-${key}`, value);
            };
        },
        [setKeyData]
    );

    const getOnMultipleValuesChange = useCallback(
        stepKey => {
            return values => {
                const valuesWithKeysPrefixed = Object.entries(values || []).reduce((acc, cv) => {
                    return {
                        ...acc,
                        [`${stepKey}-values-${cv[0]}`]: cv[1],
                    };
                }, {});
                setMultipleKeysData(valuesWithKeysPrefixed);
            };
        },
        [setMultipleKeysData]
    );

    const getOnValidationCheck = useCallback(
        stepKey => {
            return hasValidationErrors => {
                if (!!flowStoredData[`${stepKey}-meta-hasValidationErrors`] !== hasValidationErrors) {
                    setKeyData(`${stepKey}-meta-hasValidationErrors`, hasValidationErrors);
                }
            };
        },
        [flowStoredData, setKeyData]
    );

    const getGetRowIdPreviouslyCreatedByIndex = useCallback(
        stepKey => {
            return rowIndex => {
                return (flowStoredData[`${stepKey}-meta-createdEntities`] || [])[rowIndex]?._id;
            };
        },
        [flowStoredData]
    );

    const getEraseStepRowValues = useCallback(
        stepKey => {
            return rowIndex => {
                const newValuesData = Object.keys(flowStoredData).reduce((acc, key) => {
                    if (key.indexOf(`${stepKey}-values-${rowIndex}-`) === 0) {
                        return {
                            ...acc,
                            [key]: undefined,
                        };
                    }
                    return acc;
                }, {});
                setMultipleKeysData(newValuesData);
            };
        },
        [flowStoredData, setMultipleKeysData]
    );

    const getOnCloseRow = useCallback(
        stepKey => {
            return rowIndex => {
                getEraseStepRowValues(stepKey)(rowIndex);
            };
        },
        [getEraseStepRowValues]
    );

    const getDeleteStepEntityCreated = useCallback(
        stepKey => {
            return rowIndex => {
                setMultipleKeysData({
                    [`${stepKey}-meta-createdEntities`]: flowStoredData[`${stepKey}-meta-createdEntities`].filter(
                        (entity, index) => index !== rowIndex
                    ),
                });
            };
        },
        [flowStoredData, setMultipleKeysData]
    );

    const getOnDeletedCreated = useCallback(
        stepKey => {
            return rowIndex => {
                getEraseStepRowValues(stepKey)(rowIndex);
                getDeleteStepEntityCreated(stepKey)(rowIndex);
            };
        },
        [getDeleteStepEntityCreated, getEraseStepRowValues]
    );

    const getValuesFromCreated = useCallback((originalCreated, mapperValues) => {
        return Object.entries(mapperValues || {}).reduce((acc, cv) => {
            if (typeof cv[1] === 'function') {
                return {
                    ...acc,
                    [cv[0]]: cv[1](originalCreated),
                };
            } else if (originalCreated[cv[1]]) {
                return {
                    ...acc,
                    [cv[0]]: originalCreated[cv[1]],
                };
            }
            return acc;
        }, {});
    }, []);

    const getRowHasChanged = useCallback(
        (stepKey, rowIndex, mapper) => {
            const stepData = getStepData(stepKey, flowStoredData) || {};
            const values = stepData?.values?.[rowIndex] || {};
            const found = (flowStoredData[`${stepKey}-meta-createdEntities`] || [])?.[rowIndex];

            if (Object.keys(values).length === 0) {
                return !!found;
            }
            if (Object.keys(values).length > 0 && !found) {
                return true;
            }

            const valuesFromCreated = getValuesFromCreated(found, mapper);
            return !!Object.entries(values).find(([key, value]) => {
                return (value || '') !== valuesFromCreated[key] || '';
            });
        },
        [flowStoredData, getValuesFromCreated]
    );

    const getGetRowHasChanged = useCallback(
        stepKey => {
            return (rowIndex, mapper) => getRowHasChanged(stepKey, rowIndex, mapper);
        },
        [getRowHasChanged]
    );

    const getOnResetChangesOnCreated = useCallback(
        stepKey => {
            return (rowIndex, mapperValues) => {
                const originalCreated = flowStoredData[`${stepKey}-meta-createdEntities`]?.[rowIndex];
                if (originalCreated) {
                    const previousData = Object.entries(mapperValues || {}).reduce((acc, cv) => {
                        if (typeof cv[1] === 'function') {
                            return {
                                ...acc,
                                [`${stepKey}-values-${rowIndex}-${cv[0]}`]: cv[1](originalCreated),
                            };
                        } else if (originalCreated[cv[1]]) {
                            return {
                                ...acc,
                                [`${stepKey}-values-${rowIndex}-${cv[0]}`]: originalCreated[cv[1]],
                            };
                        }
                        return acc;
                    }, {});

                    setMultipleKeysData(previousData);
                }
            };
        },
        [flowStoredData, setMultipleKeysData]
    );

    const beforeNextStepChecks = useCallback(
        (stepKey, cb, operationsCb) => {
            const stepData = getStepData(stepKey, flowStoredData);

            // No data inserted, no operations
            if (!stepData.values.length) {
                cb();
                return;
            }

            setKeyData(`${stepKey}-meta-showValidationErrors`, true);

            // No error, continue with the operations
            if (!flowStoredData[`${stepKey}-meta-hasValidationErrors`]) {
                operationsCb();
            }
        },
        [flowStoredData, setKeyData]
    );

    const getStepHandlers = useCallback(
        stepKey => {
            return {
                onValueChange: getOnValueChange(stepKey),
                onMultipleValuesChange: getOnMultipleValuesChange(stepKey),
                onValidationCheck: getOnValidationCheck(stepKey),
                getRowIdPreviouslyCreatedByRowIndex: getGetRowIdPreviouslyCreatedByIndex(stepKey),
                getRowHasChanged: getGetRowHasChanged(stepKey),
                onDeletedCreated: getOnDeletedCreated(stepKey),
                onCloseRow: getOnCloseRow(stepKey),
                onResetChangesOnCreated: getOnResetChangesOnCreated(stepKey),
            };
        },
        [
            getGetRowHasChanged,
            getGetRowIdPreviouslyCreatedByIndex,
            getOnCloseRow,
            getOnDeletedCreated,
            getOnMultipleValuesChange,
            getOnResetChangesOnCreated,
            getOnValidationCheck,
            getOnValueChange,
        ]
    );

    const availableFlow = useMemo(() => {
        return getFirstAvailableFlow(
            {
                storedData: flowStoredData,
                setKeyData,
                setMultipleKeysData,
                resetData,
                getStepHandlers,
                beforeNextStepChecks,
                getRowHasChanged,
            },
            account,
            location
        );
    }, [
        flowStoredData,
        setKeyData,
        setMultipleKeysData,
        resetData,
        getStepHandlers,
        beforeNextStepChecks,
        getRowHasChanged,
        account,
        location,
    ]);

    // Sometimes takes longer until finished flow disappears from UI, so use an id to show or hide faster
    useEffect(() => {
        if (availableFlow && showId.indexOf(availableFlow.id) === -1) {
            // show if coming another one
            setShowId(availableFlow.id);
        }
    }, [availableFlow, showId, setShowId]);

    if (!availableFlow || showId !== availableFlow.id) {
        return;
    }

    const { onLoad, steps = [], onFinished } = availableFlow;
    return <Flow onLoad={onLoad} steps={steps} onFinished={onFinished} organizeStepValues={organizeStepValues} />;
});

export default Flows;
