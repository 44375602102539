import React from 'react';
import { GreenRadio, Section, SectionHeader } from './styles';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { subGroupsPositionEnum } from '../../../../../enums/subGroupsPositionEnum';

export const SubGroupsPosition = React.memo(props => {
    const { subGroupsPosition = subGroupsPositionEnum.bottom, onChange } = props;

    return (
        <Section>
            <SectionHeader>Subgroups position in sidebar</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="eventHeightMode"
                    name="eventHeightMode"
                    value={subGroupsPosition}
                    onChange={event => onChange(event.target.value)}
                >
                    <FormControlLabel
                        value={subGroupsPositionEnum.top}
                        control={<GreenRadio dataCy="my-pref-eventHeightMode-xs" />}
                        className="mb-2"
                        label="Top"
                    />
                    <FormControlLabel
                        value={subGroupsPositionEnum.bottom}
                        control={<GreenRadio dataCy="my-pref-eventHeightMode-s" />}
                        className="mb-2"
                        label="Bottom"
                    />
                </RadioGroup>
            </FormControl>
        </Section>
    );
});

SubGroupsPosition.propTypes = {
    subGroupsPosition: PropTypes.oneOf([subGroupsPositionEnum.top, subGroupsPositionEnum.bottom]),
    onChange: PropTypes.func.isRequired,
};
