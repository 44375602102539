import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    displayCurrencyValue,
    displayDateState,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad, defaultCurrency) => [
    // projectSpecific
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalScheduledSpendBetween,
                displayCurrencyValue(row, 'projectSpecificExternalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend To Date
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalScheduledSpendToDate,
                displayCurrencyValue(row, 'projectSpecificExternalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Future Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalScheduledSpendFuture,
                displayCurrencyValue(row, 'projectSpecificExternalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project All Time Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalScheduledSpendAllTime,
                displayCurrencyValue(row, 'projectSpecificExternalScheduledSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalScheduledSpendBetween,
                displayCurrencyValue(row, 'projectSpecificInternalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend To Date
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalScheduledSpendToDate,
                displayCurrencyValue(row, 'projectSpecificInternalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Future Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalScheduledSpendFuture,
                displayCurrencyValue(row, 'projectSpecificInternalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project All Time Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalScheduledSpendAllTime,
                displayCurrencyValue(row, 'projectSpecificInternalScheduledSpendAllTime', defaultCurrency)
            ),
    },
    // eventSpecific
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalScheduledSpendBetween,
                displayCurrencyValue(row, 'eventSpecificExternalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend To Date
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalScheduledSpendToDate,
                displayCurrencyValue(row, 'eventSpecificExternalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Future Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalScheduledSpendFuture,
                displayCurrencyValue(row, 'eventSpecificExternalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event All Time Spend
                        <p className="sub-title">(scheduled - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalScheduledSpendAllTime,
                displayCurrencyValue(row, 'eventSpecificExternalScheduledSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalScheduledSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalScheduledSpendBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalScheduledSpendBetween,
                displayCurrencyValue(row, 'eventSpecificInternalScheduledSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend To Date
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalScheduledSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalScheduledSpendToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalScheduledSpendToDate,
                displayCurrencyValue(row, 'eventSpecificInternalScheduledSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Future Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalScheduledSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalScheduledSpendFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalScheduledSpendFuture,
                displayCurrencyValue(row, 'eventSpecificInternalScheduledSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event All Time Spend
                        <p className="sub-title">(scheduled - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalScheduledSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalScheduledSpendAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalScheduledSpendAllTime,
                displayCurrencyValue(row, 'eventSpecificInternalScheduledSpendAllTime', defaultCurrency)
            ),
    },
];
