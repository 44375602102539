export const PERCENTAGE = {
    state: 'PERCENTAGE',
    display: 'Percentage',
    valueName: 'percentage',
    unit: '%',
};
export const HOURS_DAY = {
    state: 'HOURS_DAY',
    display: 'Hours a day',
    valueName: 'hours',
    unit: 'hrs/day',
};
export const TOTAL_HOURS = {
    state: 'TOTAL_HOURS',
    display: 'Total hours',
    valueName: 'total',
    unit: 'Total hours',
};

export default { PERCENTAGE, HOURS_DAY, TOTAL_HOURS };
