import * as actionTypes from './actionTypes';

export const updateStatistics = (data, filteredBookings, resources) => ({
    type: actionTypes.UPDATE_STATISTICS,
    payload: {
        data,
        filteredBookings,
        resources,
    },
});

export const resetStatistics = () => ({
    type: actionTypes.RESET_STATISTICS,
});

export const updateSidebarStatistics = data => ({
    type: actionTypes.UPDATE_SIDEBAR_STATISTICS,
    payload: {
        data,
    },
});

export const updateRequestModel = (requestModel, refreshStatistics) => ({
    type: actionTypes.UPDATE_REQUEST_MODEL,
    payload: {
        requestModel,
        refreshStatistics,
    },
});

export const approveRejectRequest = ({bookings, options}) => ({
    type: actionTypes.APPROVE_REJECT_REQUEST,
    payload: {
        bookings,
        options
    },
});

export const rejectRequestsSuccess = ({bookingIds}) => ({
    type: actionTypes.REJECT_REQUESTS_SUCCESS,
    payload: {
        bookingIds,
    },
});

export const updateResourceOrVacationRequest = ({ booking, bookingId, options }) => {
    return {
        type: actionTypes.EDIT_RESOURCE_VACATION_REQUEST,
        payload: {
            booking,
            bookingId,
            options,
        },
    };
};
