import React from 'react';
import PropTypes from 'prop-types';

const Requestor = ({ booking, loggedResourceId }) => {
    let text = 'I request ';

    if (booking.bookingCreatorId !== loggedResourceId) {
        text = booking.bookingCreatorId
            ? `${booking.creatorFirstName} ${booking.creatorLastName} requests `
            : 'No Name';
    }

    return <span>{text}</span>;
};

Requestor.propTypes = {
    booking: PropTypes.object.isRequired,
    loggedResourceId: PropTypes.string.isRequired,
};

export default Requestor;
