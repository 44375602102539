import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../utils/useQueryParams';
import React, { useEffect } from 'react';
import { showProjectModal } from '../../../actions/modalActions';
import { updateProject, createProject } from '../../../actions/projectActions';
import { TYPE_EVENT, TYPE_REGULAR } from '../../../enums/projectEnum';
import PermissionDenied from '../../scheduler/components/permissionDenied/permissionDenied';
import authorization from '../../../services/auth/authorization';

const EventProjectModalRoute = ({ type }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const queryParams = useQueryParams();

    useEffect(() => {
        dispatch(
            showProjectModal({
                onSubmit: data =>
                    params.projectId
                        ? dispatch(
                              updateProject.request(params.projectId, data, () => {
                                  if (queryParams.successUrl) {
                                      window.location.href = queryParams.successUrl;
                                  } else {
                                      window.location.href = '/';
                                  }
                              })
                          )
                        : dispatch(createProject.request(data, undefined, true)),
                projectId: params.projectId,
                type: params.type || type,
                onClose: () => {
                    if (queryParams.fallbackUrl) {
                        window.location.href = queryParams.fallbackUrl;
                    } else {
                        window.location.href = '/';
                    }
                },
            })
        );
    }, [dispatch, params.projectId, params.type, queryParams.fallbackUrl, queryParams.successUrl, type]);

    return <></>;
};

const ProjectModalRoute = () => <EventProjectModalRoute type={TYPE_REGULAR.value} />;
const EventModalRoute = () => <EventProjectModalRoute type={TYPE_EVENT.value} />;

const authParameters = {
    allowedRoleRights: ['settingAddEditProjects', 'pmAddEditProjects'],
    redirectOnLackOfRights: true,
    errorComponent: PermissionDenied,
    routeParamsChecker: (params, data) => {
        let isAvailable = true;

        if (params.projectId && !data.isProjectsLoading && data.projects.length) {
            isAvailable = data.projects.some(
                project =>
                    project._id === params.projectId && (project.hasRightsToProject || project.hasPMRightsToProject)
            );
        }

        return isAvailable;
    },
};

export const ProjectModalRouteAuth = authorization(authParameters)(ProjectModalRoute);
export const EventModalRouteAuth = authorization(authParameters)(EventModalRoute);
