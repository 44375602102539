import { OPERATORS } from 'enums/operatorEnum';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import DateRange from 'shared/dateRange';
import { dropdownField as DropdownField } from 'shared/formFields';

class DateFilter extends PureComponent {
    static propTypes = {
        defaultValues: PropTypes.shape({
            operator: PropTypes.string,
            value: PropTypes.object,
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: null,
        defaultValues: {
            operator: undefined,
            value: undefined,
        },
    };

    onOperatorChange = operator => {
        if (this.props.onChange) {
            this.props.onChange({ operator: operator.value });
        }
    };

    onDateChange = value => {
        if (this.props.onChange) {
            this.props.onChange({ value: value.startDate });
        }
    };

    render() {
        return (
            <React.Fragment>
                <DropdownField
                    input={{
                        name: 'filter',
                        value: this.props.defaultValues.operator,
                        onBlur: () => {},
                        onChange: this.onOperatorChange,
                    }}
                    filter={false}
                    textField="name"
                    valueField="value"
                    data={OPERATORS}
                />
                <DateRange
                    onChange={this.onDateChange}
                    initialStartDate={this.props.defaultValues.value}
                    displayFormText={false}
                />
            </React.Fragment>
        );
    }
}

export default DateFilter;
