import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { formatNumber } from 'utils/formatingUtil';
import { displayDateState, displayValue, isHeaderDisabled, getColumnProps } from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';
import ProgressBarCell from 'modules/report/components/tableCells/progressBarCell';

/**
 * @param {object}         dateState
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Burn
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'burnBetween',
        sortable: false,
        show: contains(columnsToLoad, 'burnBetween'),
        accessor: row => displayValue(row.burnBetween, formatNumber(row.burnBetween || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Burn
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'burnPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'burnPrevious'),
        accessor: row => displayValue(row.burnPrevious, formatNumber(row.burnPrevious || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Burn
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'burnAfter',
        sortable: false,
        show: contains(columnsToLoad, 'burnAfter'),
        accessor: row => displayValue(row.burnAfter, formatNumber(row.burnAfter || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Burn
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'burnAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'burnAllTime'),
        accessor: row => displayValue(row.burnAllTime, formatNumber(row.burnAllTime || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
];
