import styled from 'styled-components';
import { Col } from 'reactstrap';

export const BoxTitle = styled.p`
    font-size: 9px;
    letter-spacing: 0.075em;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-top: 20px;
`;

export const Value = styled.span`
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: #565656;
    &.negative {
        color: #e05b5b;
    }
`;

export const Unit = styled.span`
    color: #999;
    font-size: 11px;
    display: inline-block;
    text-transform: none;
    letter-spacing: 0;
    font-weight: 600;
    margin-left: 3px;
    &.currency {
        margin-right: 3px;
        font-size: 18px;
        text-transform: uppercase;
        color: #565656;
    }
`;

export const Range = styled(BoxTitle)`
    color: #939da5;
    margin: 2px 0 0;
`;

export const Column = styled(Col)`
    text-align: center;
    &.col-border {
        border-left: 1px solid #dadada !important;
    }
`;

export const Box = styled(Col)`
    border-bottom: 1px solid #dadada;
    padding-bottom: 14px;
    margin: 5px 0;
    &.main-border {
        border-right: 1px solid #dadada;
    }
`;
