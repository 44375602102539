import { closeActions, onFinish } from './common';
import { useDispatch } from 'react-redux';
import React, { useCallback } from 'react';
import { showLearnMoreAboutToilModal } from '../../../actions/modalActions';
import { Button, Layout } from '@hub-mono/ui';

export const ToilTipCustomContent = () => {
    const dispatch = useDispatch();

    const handleSeeMore = useCallback(() => {
        dispatch(showLearnMoreAboutToilModal());
        onFinish({ category: 'TIPS_TOIL_POD', channel: 'IN_APP' });
    }, [dispatch]);

    return (
        <Layout pb={8}>
            <Button variant="text" onClick={handleSeeMore}>
                Learn more
            </Button>
        </Layout>
    );
};

export const TIPS_TOIL = {
    title: 'Time off in lieu (TOIL)',
    subTitle: '',
    releaseDate: new Date(2024, 3, 14),
    requiredRole: [], // TODO: do we need specific role
    closeActions,
    onFinish,
    slides: [
        {
            upperTitle: 'New Extension!',
            title: 'Time off in lieu (TOIL)',
            upperContent: <ToilTipCustomContent />,
            subTitle:
                'Track accrued TOIL directly within Timesheets and visualize it on the Vacation dashboard. Create multiple company policies, manage resource settings, and easily request TOIL.',
            imageUrl: 'https://hubplanner-static.s3.eu-west-1.amazonaws.com/img/Toil+Announcement.png',
        },
    ],
};
