import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { filter, map, isUndefined, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { FormatAlignCenter, FormatAlignRight, FormatAlignLeft, ArrowRightAlt } from '@material-ui/icons';
import { MenuItem, Divider } from '@material-ui/core';
import { getCustomFieldsRequest } from 'actions/customFieldActions';
import { showColumnResizeModal } from 'actions/modalActions';
import { makeGetFilteredCustomFields } from 'selectors/customField';
import { useIsExtensionInstalled, useHasRights, usePressEscape } from 'hooks';
import { CUSTOM_COLUMNS, CUSTOM_FIELDS, PM } from 'enums/extensionShortIdEnum';
import {
    columnTypesByType,
    CUSTOM_FIELDS as CUSTOM_FIELDS_TYPE,
    NOT_SET,
} from 'modules/scheduler/enums/customColumnTypeEnum';
import Scrollbar from 'shared/scrollbar';
import { Wrapper } from './styles';
import NoExtension from 'modules/request/components/noExtension';
import * as templateContent from 'enums/templateItemsEnum';

const rights = [
    {
        rights: ['settingProjectCf'],
        rule: 'one',
        name: 'hasProjectCFRights',
    },
    {
        rights: ['settingResourceCf'],
        rule: 'one',
        name: 'hasResourceCFRights',
    },
];

const CustomColumns = props => {
    const { top, left, close, column, schedulerRef } = props;
    const dispatch = useDispatch();
    const [showNoColumnsExtension, setShowNoColumnsExtension] = useState(false);
    const { schedulerColumns, updateSchedulerColumns } = useAccountPreferences(schedulerRef);
    const isColumnNotSet = NOT_SET.value === column.data.type;
    const sortedColumn = schedulerColumns[`${column.data.viewType}Columns`].sortedColumn;
    const isColumnSorted =
        sortedColumn.columnId === column.data.id ||
        (!sortedColumn.columnId && !column.data.id && 'NOT_SET' !== column.data.type);
    const isCustomFieldExtensionInstalled = useIsExtensionInstalled(CUSTOM_FIELDS);
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const isCustomColumnsExtensionInstalled = useIsExtensionInstalled(CUSTOM_COLUMNS);
    const { hasProjectCFRights, hasResourceCFRights } = useHasRights(rights);
    const customFieldSelector = useMemo(
        () => makeGetFilteredCustomFields('ALL', `${column.data.viewType}CustomFields`),
        [column.data.viewType]
    );
    const customFields = useSelector(customFieldSelector);
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);
    const columnTypes = columnTypesByType(column.data.viewType, {
        isPMExtensionInstalled,
        isCustomFieldExtensionInstalled,
    });
    const isCFDisabled =
        !isCustomFieldExtensionInstalled ||
        ('resource' === column.data.viewType && !hasResourceCFRights) ||
        ('project' === column.data.viewType && !hasProjectCFRights);

    useEffect(() => {
        isCustomFieldExtensionInstalled && dispatch(getCustomFieldsRequest(column.data.viewType));
    }, [dispatch, column.data.viewType, isCustomFieldExtensionInstalled]);

    const addColumn = useCallback(() => {
        if (isCustomColumnsExtensionInstalled) {
            updateSchedulerColumns(
                column.data.viewType,
                {
                    customColumns: [
                        ...schedulerColumns[`${column.data.viewType}Columns`].customColumns,
                        {
                            columnAlignment: 'CENTER',
                            columnType: NOT_SET.value,
                            columnWidth: 140,
                        },
                    ],
                },
                close
            );
        } else {
            setShowNoColumnsExtension && setShowNoColumnsExtension(true);
        }
    }, [
        updateSchedulerColumns,
        column,
        schedulerColumns,
        close,
        isCustomColumnsExtensionInstalled,
        setShowNoColumnsExtension,
    ]);

    const removeColumn = useCallback(() => {
        const customColumns = filter(
            schedulerColumns[`${column.data.viewType}Columns`].customColumns,
            customColumn => customColumn._id !== column.data.id
        );
        updateSchedulerColumns(
            column.data.viewType,
            {
                customColumns,
            },
            close
        );
    }, [updateSchedulerColumns, column, schedulerColumns, close]);

    const updateCustomColumn = useCallback(
        data => {
            const customColumns = map(
                schedulerColumns[`${column.data.viewType}Columns`].customColumns,
                customColumn => {
                    if (customColumn._id === column.data.id) {
                        return {
                            ...(CUSTOM_FIELDS_TYPE.value !== data.columnType && data.hasOwnProperty('columnType')
                                ? omit(customColumn, 'columnValue')
                                : customColumn),
                            ...data,
                        };
                    }

                    return customColumn;
                }
            );
            const defaultColumn = { ...schedulerColumns[`${column.data.viewType}Columns`].defaultColumn, ...data };
            updateSchedulerColumns(
                column.data.viewType,
                {
                    ...(!column.id && isUndefined(column.data.type) ? { defaultColumn } : { customColumns }),
                },
                close
            );
        },
        [updateSchedulerColumns, column, schedulerColumns, close]
    );

    const updateSorting = useCallback(
        direction => {
            if (isColumnSorted && sortedColumn.sortDirection === direction) {
                close();
                return;
            }
            updateSchedulerColumns(
                column.data.viewType,
                {
                    sortedColumn: {
                        columnId: column.data.id || null,
                        sortDirection: direction,
                    },
                },
                close
            );
        },
        [updateSchedulerColumns, column, isColumnSorted, sortedColumn.sortDirection, close]
    );

    const columnResizeModal = useCallback(() => {
        dispatch(showColumnResizeModal(column, schedulerRef));
        close();
    }, [dispatch, column, close, schedulerRef]);

    const getIsColumnTypeDisabled = columnType => {
        return columnType.disabled && columnType.disabled(resourceRoleRights);
    };

    const getIsColumnTypeAdded = columnType => {
        const columnsTypesAdded = schedulerColumns[`${column.data.viewType}Columns`]?.customColumns || [];

        return !!columnsTypesAdded.find(addedColumn => {
            return (
                addedColumn?.columnType === columnType?.value ||
                (addedColumn?.columnType === 'CUSTOM_FIELDS' && addedColumn?.columnValue?.id === columnType?._id)
            );
        });
    };

    const columnTypesAvailableNotDisabled = columnTypes.filter(columnType => {
        return !getIsColumnTypeDisabled(columnType) && !getIsColumnTypeAdded(columnType);
    });

    const columnTypesJSX = () => {
        const columnTypesAvailable = columnTypes.filter(columnType => !getIsColumnTypeAdded(columnType));
        return (
            columnTypesAvailable.length > 0 && (
                <>
                    <MenuItem className="disable-hover">
                        <strong>Set Column Type</strong>
                    </MenuItem>
                    {columnTypesAvailable.map(columnType => {
                        const isDisabled = getIsColumnTypeDisabled(columnType);
                        return (
                            <MenuItem
                                key={columnType.value}
                                disabled={isDisabled}
                                onClick={() => updateCustomColumn({ columnType: columnType.value })}
                            >
                                {columnType.label}
                                {isDisabled ? ' (no rights)' : ''}
                            </MenuItem>
                        );
                    })}
                </>
            )
        );
    };

    const customFieldsJSX = () => {
        const customFieldsAvailableNotAdded = customFields.filter(customField => !getIsColumnTypeAdded(customField));
        return (
            isCustomFieldExtensionInstalled &&
            customFieldsAvailableNotAdded.length > 0 && (
                <>
                    <MenuItem className="disable-hover">
                        {isCFDisabled && <span className="text-muted">Custom Fields (no rights)</span>}
                        {!isCFDisabled && <strong>Custom Fields</strong>}
                    </MenuItem>
                    {!isCFDisabled &&
                        customFieldsAvailableNotAdded.map(customField => (
                            <MenuItem
                                key={customField._id}
                                onClick={() =>
                                    updateCustomColumn({
                                        columnType: CUSTOM_FIELDS_TYPE.value,
                                        columnValue: { id: customField._id },
                                        customColumnType: customField.type,
                                    })
                                }
                            >
                                {customField.label}
                            </MenuItem>
                        ))}
                </>
            )
        );
    };

    const noOthersColumnsToAdd = columnTypesAvailableNotDisabled.length === 0;

    const onCloseNoColumnsExtension = () => {
        setShowNoColumnsExtension(false);
        close();
    };

    usePressEscape(onCloseNoColumnsExtension);

    const noColumnsExtensionJSX = () => {
        return (
            <NoExtension
                {...templateContent.NO_CUSTOM_COLUMNS_EXT}
                onCloseClick={onCloseNoColumnsExtension}
                fullScreen
                newVersionShortId="custom_columns"
            />
        );
    };

    if (showNoColumnsExtension) {
        return noColumnsExtensionJSX();
    }

    return (
        <Wrapper
            keepMounted
            classes={{ paper: 'radius-0' }}
            open={true}
            disableAutoFocusItem={true}
            onClose={close}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <Scrollbar height={column.data.type && 'NAME' !== column.data.type ? 300 : 196} style={{ width: '173px' }}>
                <div>
                    <MenuItem disabled={noOthersColumnsToAdd} onClick={addColumn}>
                        Add New Column
                    </MenuItem>
                    <Divider />
                    <MenuItem
                        className={classNames({ sorted: isColumnSorted })}
                        disabled={isColumnNotSet}
                        onClick={() => updateSorting('ASCENDING')}
                    >
                        Sort{isColumnSorted ? 'ed' : ''} by this Column
                    </MenuItem>
                    <MenuItem
                        className={classNames({
                            'font-weight-bold': isColumnSorted && 'ASCENDING' === sortedColumn.sortDirection,
                        })}
                        disabled={isColumnNotSet}
                        onClick={() => updateSorting('ASCENDING')}
                    >
                        Sort Column A <ArrowRightAlt /> Z
                    </MenuItem>
                    <MenuItem
                        className={classNames({
                            'font-weight-bold': isColumnSorted && 'DESCENDING' === sortedColumn.sortDirection,
                        })}
                        disabled={isColumnNotSet}
                        onClick={() => updateSorting('DESCENDING')}
                    >
                        Sort Column Z <ArrowRightAlt /> A
                    </MenuItem>
                    <MenuItem onClick={columnResizeModal}>Resize Column</MenuItem>
                    <Divider />
                    <MenuItem
                        disabled={isColumnNotSet}
                        onClick={() => updateCustomColumn({ columnAlignment: 'LEFT' })}
                        className={classNames('d-inline-block', { aligned: 'LEFT' === column.data.alignment })}
                    >
                        <FormatAlignLeft />
                    </MenuItem>
                    <MenuItem
                        disabled={isColumnNotSet}
                        onClick={() => updateCustomColumn({ columnAlignment: 'CENTER' })}
                        className={classNames('d-inline-block', { aligned: 'CENTER' === column.data.alignment })}
                    >
                        <FormatAlignCenter />
                    </MenuItem>
                    <MenuItem
                        disabled={isColumnNotSet}
                        onClick={() => updateCustomColumn({ columnAlignment: 'RIGHT' })}
                        className={classNames('d-inline-block', { aligned: 'RIGHT' === column.data.alignment })}
                    >
                        <FormatAlignRight />
                    </MenuItem>
                    {column.data.type && 'NAME' !== column.data.type && (
                        <>
                            <Divider />
                            <MenuItem onClick={removeColumn}>Remove this column</MenuItem>
                            <Divider />
                            {columnTypesJSX()}
                            {customFieldsJSX()}
                        </>
                    )}
                </div>
            </Scrollbar>
        </Wrapper>
    );
};

CustomColumns.propTypes = {
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
};

export default CustomColumns;
