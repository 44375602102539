import { formatDate } from '../../../../utils/DateUtil';

export const projectChildObject = (resource, project, contextMenu, visible = true) => {
    const dpMenu = contextMenu && contextMenu(project, resource);

    return {
        role: resource.role,
        name: resource.name,
        id: `${project._id}_${resource._id}`,
        tags: {
            menuRow: false,
            isEventRow: false,
            parent: false,
            resource: {
                ...resource,
                holidaysMap: (resource?.holidays || []).reduce((acc, holiday) => {
                    const format = holiday.repeat ? 'MM-dd' : 'yyyy-MM-dd';
                    const formatted = formatDate(holiday.date, format);
                    acc[formatted] = holiday;
                    return acc;
                }, {}),
            },
            projectId: project._id,
            project: project,
            isProjectRow: false,
            isResourceRow: true,
        },
        isParent: false,
        marginTop: 3,
        marginBottom: 10,
        visible,
        cssClass: 'child-scheduler-row',
        areas: dpMenu?.items?.length
            ? [
                  {
                      visibility: 'Visible',
                      action: 'ContextMenu',
                      bottom: 0,
                      width: 30,
                      html: `<i data-cy="scheduler-row-project-resource-menu-${resource?._id ||
                          ''}" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                      cssClass: 'resource-action-menu',
                      top: 0,
                      menu: contextMenu && contextMenu(project, resource),
                      right: 0,
                  },
              ]
            : [],
    };
};
