import { push } from 'connected-react-router';
import { pickBy, identity, includes, extend, assign, keys, find } from 'lodash';
import { omit } from 'underscore';
import { call, put, takeLatest, select, delay, fork } from 'redux-saga/effects';
import {
    getNewReportData,
    createReportRequest,
    updateReportRequest,
    updateFavouritesRequest,
    exportReportRequest,
    reportTemplatesRequest,
    shareReportRequest,
    getSharedReportRequest,
    deleteReportRequest,
    sendSuggestReport,
    addReportsGroupRequest,
    getReportsGroupsRequest,
    updateReportsGroupRequest,
    removeReportsGroupRequest,
    updateReportGroupsRequest,
} from 'api/report';
import * as actionTypes from 'actions/actionTypes';
import { hideModal } from 'actions/modalActions';
import { addNotification, removeNotification } from 'actions/notificationActions';
import {
    deleteReport,
    getReport,
    createReport,
    updateReport,
    exportReport,
    getReportTemplates,
    shareReport,
    updateQueryParams,
    updateFavourites,
    updateContextRow,
    getReportsGroups,
    updateReportsGroup,
    removeReportsGroup,
} from 'actions/reportActions';
import {
    CUSTOM_REPORT,
    findReportTypeByRoute,
    findReportTypeBySubTypeAndGroup,
} from 'modules/report/enums/reportTypeEnum';
import StorageUtil from 'utils/StorageUtil';
import createLink, { createBlobData } from 'utils/linkCreatorUtil';
import {
    makeGetReportTemplates,
    getReportsGroups as selectReportsGroups,
    getReportsGroupById,
} from 'selectors/reportTemplates';
import { formatReportBody } from 'modules/report/utils/transformer';
import { formatDate } from 'utils/DateUtil';
import { getCompanyExtensions, getCompanyId } from 'selectors/company';
import { monitoring } from '../monitoring';
import { PROJECT_GROUP, RESOURCE_GROUP } from '../modules/report/enums/groupingTypeEnum';

const getQueryParams = state => state.reportReducer.queryParams;
const getReportQueryChanged = state => state.reportReducer.reportQueryChanged;
const getRoute = state => state.router;
const getAccountRoleRights = state => state.account.resourceRoleRights;
const getAccountReportPreferences = state => state.account.preferences.report;
const getCustomFields = () => state =>
    state.customFieldReducer.projectCustomFields.concat(state.customFieldReducer.resourceCustomFields);

const redirectToNoGroupUrl = groupId => {
    const groupURL = `/group/${groupId}`;

    if (window.location.hash.includes(groupURL)) {
        const [noGroupURL] = window.location.hash.split(groupURL);

        window.location.replace(noGroupURL);
    }
};

function* getFormattedReportBody(queryParams, exportRequest) {
    const resourceRoleRights = yield select(getAccountRoleRights);
    const extensions = yield select(getCompanyExtensions);
    const customFields = yield select(getCustomFields());

    return formatReportBody(queryParams, customFields, extensions, resourceRoleRights, exportRequest);
}

function* redirectToReportPage(typeName, subTypeName, id, groupBy) {
    const report = findReportTypeByRoute(typeName);
    const route = yield select(getRoute);
    const currentUrl = route.location.pathname;

    if (report) {
        const subType = findReportTypeBySubTypeAndGroup(subTypeName, groupBy, report.value);
        let url = `/reports/${report.route}/${subType.route}`;
        if (!keys(subType).length) {
            return false;
        }
        if (id) {
            url += `/${id}?groupBy=${groupBy}`;
        }
        if (url !== currentUrl) {
            yield put(push(url));
        }

        return true;
    } else {
        if (-1 === currentUrl.indexOf('/reports/customReport/custom')) {
            yield put(push('/reports/customReport/custom'));
        }
    }

    return false;
}

function* redirectToTemplate(templateId, visibility, groups) {
    if (templateId.length) {
        const route = yield select(getRoute);

        const subType = find(CUSTOM_REPORT.subTypes, subType =>
            'PRIVATE' === visibility ? 'SAVED' : 'INTERNAL' === subType.value
        );

        let url = `/reports/templates/${subType.route}/${templateId}`;

        const [, currentGroupId] = route.location.pathname.split('/group/');
        const isInCurrentGroup = currentGroupId && groups?.includes(currentGroupId);

        if (isInCurrentGroup) {
            url = `${url}/group/${currentGroupId}`;

            yield put(push(url));
            return;
        }

        if (groups?.length) {
            const group = groups[0];
            url = `${url}/group/${group}`;

            yield put(push(url));

            return;
        }

        yield put(push(url));
    } else {
        yield put(push('/reports/customReport/custom'));
    }

    return false;
}

function* reportRequest(action) {
    try {
        const queryParams = yield select(getQueryParams);
        const { visibility, currentTemplateId, templateType, limit, page, type, subType, groupBy, id } = queryParams;
        const { shouldRequest, shouldRedirect } = action.payload;

        if (false === shouldRequest) {
            return;
        }

        if (false !== shouldRedirect) {
            'CUSTOM' === templateType
                ? yield call(redirectToTemplate, currentTemplateId, visibility)
                : yield call(redirectToReportPage, type, subType, id, groupBy);
        }

        const body = yield getFormattedReportBody(queryParams);
        const response = yield call(getNewReportData, body, {
            limit,
            page,
        });

        if (!response.data?.length && action.payload.queryParams.page !== 0) {
            const { queryParams, shouldRequest, shouldRedirect, storageItemName } = action.payload;
            yield put(
                updateQueryParams.request(
                    {
                        ...queryParams,
                        page: 0,
                    },
                    shouldRequest,
                    shouldRedirect,
                    storageItemName
                )
            );
        } else {
            yield put(getReport.success(response));
            yield put(hideModal());
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(getReport.failure());
        yield put(
            addNotification({
                message: "Somehow we couldn't load your report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleContextRowUpdate(action) {
    try {
        // Send request only if item type available because context row can be set by clicking a table row
        // so we need a request e.g on page refresh
        if (!action.payload.item) {
            const currentQueryParams = yield select(getQueryParams);
            const queryParams = extend({}, action.payload.queryParams, {
                groupIds: [RESOURCE_GROUP.name, PROJECT_GROUP.name].includes(action.payload.queryParams.groupBy)
                    ? action.payload.queryParams.groupIds
                    : [],
                itemIds: [RESOURCE_GROUP.name, PROJECT_GROUP.name].includes(action.payload.queryParams.groupBy)
                    ? []
                    : action.payload.queryParams.itemIds,
                columnsToLoad: currentQueryParams.columnsToLoad,
                displayEmptyResults: currentQueryParams.displayEmptyResults,
            });
            const body = yield getFormattedReportBody(queryParams);
            const { limit, page } = action.payload.queryParams;
            const response = yield call(getNewReportData, body, {
                limit,
                page,
            });

            yield put(updateContextRow.success(response, queryParams, action.payload.storageItemName));
        } else {
            // we update the context row based on the clicked row on table
            yield put(updateContextRow.success({ data: action.payload.item }, null, action.payload.storageItemName));
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't update the context row...",
                type: 'danger',
            })
        );
    }
}

function* createGroupOnSaveOrUpdateReportSaga(newGroupName, reportResponse) {
    if (newGroupName && reportResponse?.data?._id) {
        const companyId = yield select(getCompanyId);
        yield call(addReportsGroupRequest, {
            name: newGroupName,
            reports: [reportResponse.data._id],
            visibility: reportResponse.data.visibility,
            sortBy: 'CREATED_DATE',
            sortOrder: 'ASC',
            company: companyId,
        });
        yield put(getReportsGroups.request());
    }
}

function* handleCreateReport(action) {
    try {
        const { name, visibility, groups, newGroupName } = action.payload;
        const queryParams = { ...action.payload.queryParams };
        delete queryParams.name;
        delete queryParams.visibility;
        delete queryParams.currentTemplateId;
        queryParams.id = '';
        const response = yield call(createReportRequest, {
            name,
            visibility,
            ...pickBy(queryParams, item => undefined !== item && null !== item && '' !== item),
        });

        yield put(createReport.success(response.data));
        yield redirectToTemplate(response.data._id, response.data.visibility, groups, true);
        yield put(
            addNotification({
                message: 'Report template saved',
                type: 'success',
            })
        );

        yield call(updateReportGroupsRequest, response.data._id, { groupIds: groups });

        if (!newGroupName) {
            yield put(getReportsGroups.request());
        }

        yield call(createGroupOnSaveOrUpdateReportSaga, newGroupName, response);
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't save your report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleUpdateReport(action) {
    try {
        const { id, data, groups, newGroupName } = action.payload;
        const response = yield call(
            updateReportRequest,
            id,
            pickBy(data, item => undefined !== item && null !== item && '' !== item)
        );

        yield put(updateReport.success(response.data));
        yield redirectToTemplate(response.data._id, response.data.visibility, groups);
        yield put(
            addNotification({
                message: `Report template (${data.name}) updated`,
                type: 'success',
            })
        );

        yield call(updateReportGroupsRequest, response.data._id, { groupIds: groups });

        if (!newGroupName) {
            yield put(getReportsGroups.request());
        }

        yield call(createGroupOnSaveOrUpdateReportSaga, newGroupName, response);
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't update your report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* updateTemplateFavourites(action) {
    try {
        const { templateId, data } = action.payload;
        const route = yield select(getRoute);
        const response = yield call(updateFavouritesRequest, templateId, data);
        yield put(updateFavourites.success(response.data));

        const getFilteredReportTemplates = makeGetReportTemplates('FAVOURITES');
        const templates = yield select(getFilteredReportTemplates);
        let url = '';

        if (includes(route.location.pathname, templateId) && 'REMOVE' === data.action) {
            url = '/reports/templates/favourites';
        }

        if (!templates.length) {
            url = '/reports';
        }

        0 < url.length && (yield put(push(url)));

        yield put(
            addNotification({
                message: 'Updated successfully',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't update this report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleExportReport(action) {
    try {
        const type = action.payload.fileFormat || 'xlsx';
        const queryData = action.payload && action.payload.queryData;
        const queryParams = queryData || (yield select(getQueryParams));
        const reportPreferences = yield select(getAccountReportPreferences);
        const { limit, page, startDate, endDate, unit } = queryParams;
        const exportRequest = true;

        yield put(
            addNotification({
                id: 'export-note',
                showLoader: true,
                timeout: false,
                message: 'We are preparing your file...',
                type: 'info',
            })
        );

        const formattedReportBody = yield getFormattedReportBody(queryParams, exportRequest);
        const params = extend({}, formattedReportBody.params, { unit: reportPreferences.unit || unit });
        const response = yield call(exportReportRequest, extend({}, formattedReportBody, { params }), {
            limit,
            page,
            reportType: includes(['xlsx', 'xls'], type) ? 'excel' : type,
        });

        createLink(
            createBlobData(response, type),
            `Hub_Planner_Export_${formatDate(
                new Date(startDate),
                'yyyy-MM-dd',
                action.payload.useUTCinFileName
            )}_${formatDate(new Date(endDate), 'yyyy-MM-dd', action.payload.useUTCinFileName)}.${action.payload
                .fileFormat || 'xlsx'}`
        );

        yield put(removeNotification('export-note'));
        yield put(exportReport.success());
    } catch (error) {
        monitoring.captureException(error);
        yield put(removeNotification('export-note'));
        yield put(
            addNotification({
                message: "Somehow we couldn't export your report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleShareReport() {
    try {
        const reportQueryChanged = yield select(getReportQueryChanged);
        let token = StorageUtil.getItem('token');

        if (reportQueryChanged || !token) {
            let queryParams = yield select(getQueryParams);
            queryParams = omit(queryParams, [
                'company',
                'owner',
                '_id',
                'name',
                'asFavourite',
                'visibility',
                'createdDate',
                'currentTemplateId',
            ]);
            const response = yield call(shareReportRequest, {
                ...pickBy(queryParams, identity),
                dateState: queryParams.dateState,
            });

            token = response.data.token;
        }

        yield put(shareReport.success(token));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load your report templates, please try again...",
                type: 'danger',
            })
        );
    }
}

function* sharedReport(action) {
    try {
        const response = yield call(getSharedReportRequest, action.payload.token);
        const data = assign({}, response.data, {
            groupBy: response.data.groupBy || undefined,
        });

        yield put(updateQueryParams.request(data));
        yield call(redirectToReportPage);
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load the shared report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleReportTemplatesRequest(action) {
    try {
        yield delay();
        const response = yield call(reportTemplatesRequest, action.payload.filters);

        yield put(getReportTemplates.success(response.data));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't load your report templates, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleDeleteReportRequest(action) {
    try {
        yield call(deleteReportRequest, action.payload.template._id);
        yield put(deleteReport.success(action.payload.template._id));

        yield put(
            addNotification({
                message: `Report deleted`,
                type: 'success',
            })
        );

        const route = yield select(getRoute);
        const reportType =
            'PRIVATE' === action.payload.template.visibility ? 'SAVED' : action.payload.template.visibility;
        const getFilteredReportTemplates = makeGetReportTemplates(reportType);
        const templates = yield select(getFilteredReportTemplates);

        const { groupId } = action.payload;

        if (!templates.length) {
            yield put(push('/reports'));

            return;
        }

        if (groupId && window.location.hash.includes(groupId)) {
            const group = yield select(getReportsGroupById(groupId));
            const isNotLastReportInGroup = group?.reports?.length > 1;
            yield fork(handleGetReportsGroupSaga);

            if (window.location.hash.includes(action.payload.template._id) && isNotLastReportInGroup) {
                yield put(push(`/reports/templates/${reportType.toLowerCase()}/group/${groupId}`));
                return;
            }

            if (!isNotLastReportInGroup) {
                yield put(push(`/reports/templates/${reportType.toLowerCase()}`));
                return;
            }
        }

        if (includes(route.location.pathname, action.payload.template._id)) {
            yield put(
                push(
                    `/reports/templates/${find(CUSTOM_REPORT.subTypes, subType => subType.value === reportType).route}`
                )
            );
        }

        if (!templates.length) {
            yield put(push('/reports'));
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't delete report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleSendSuggestReportRequest(action) {
    try {
        yield call(sendSuggestReport, action.payload.data);

        yield put(hideModal());

        yield put(
            addNotification({
                message: 'Suggest report has been sent',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't send your suggest report, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleAddReportsGroupSaga(action) {
    try {
        yield call(addReportsGroupRequest, action.payload.data);
        yield put(getReportsGroups.request());
        yield put(hideModal());

        if (!action.payload.silent) {
            yield put(
                addNotification({
                    message: `Reports group ${action.payload.data.name} has been created.`,
                    type: 'success',
                })
            );
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't create a group, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleGetReportsGroupSaga() {
    try {
        const groups = yield call(getReportsGroupsRequest);
        yield put(getReportsGroups.success(groups));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't fetch reports groups, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleUpdateReportsGroupSaga(action) {
    try {
        const group = yield call(updateReportsGroupRequest, action.payload);
        yield put(updateReportsGroup.success(group));
        yield put(hideModal());

        const path = group.visibility === 'PRIVATE' ? 'saved' : 'internal';

        const isInGroup = window.location.hash.includes('/group/');
        const isReportInGroup = group?.reports.some(r => window.location.hash.includes(r));
        const [, currentReportId] = window.location.hash.split(`/templates/${path}/`);

        if (!isInGroup && currentReportId && isReportInGroup) {
            window.location.replace(`${window.location.hash}/group/${group._id}`);
        }

        if (
            !group?.reports?.length ||
            (!isReportInGroup && !window.location.hash.includes(`/templates/${path}/group/`))
        ) {
            redirectToNoGroupUrl(action.payload.groupId);
        }

        yield put(
            addNotification({
                message: 'Group has been updated',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't update reports group, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleDeleteReportsGroupSaga(action) {
    try {
        yield call(removeReportsGroupRequest, action.payload);
        yield put(removeReportsGroup.success(action.payload.groupId));

        redirectToNoGroupUrl(action.payload.groupId);

        yield put(
            addNotification({
                message: 'Group has been removed',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't remove reports groups, please try again...",
                type: 'danger',
            })
        );
    }
}

function* handleRemoveReportFromGroupSaga(action) {
    try {
        const groups = yield select(selectReportsGroups);
        const groupToUpdate = groups.find(g => g._id === action.payload.groupId);

        if (!groupToUpdate) {
            yield put(
                addNotification({
                    message: "Somehow we couldn't remove the report from groups, please try again...",
                    type: 'danger',
                })
            );
            return;
        }

        const group = yield call(updateReportsGroupRequest, {
            groupId: action.payload.groupId,
            data: { ...groupToUpdate, reports: groupToUpdate.reports.filter(r => r !== action.payload.reportId) },
        });

        if (window.location.hash.includes(action.payload.reportId) || !group?.reports?.length) {
            redirectToNoGroupUrl(action.payload.groupId);
        }

        yield put(updateReportsGroup.success(group));
        yield put(
            addNotification({
                message: 'Report has been removed from the group',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Somehow we couldn't remove the report from groups, please try again...",
                type: 'danger',
            })
        );
    }
}

export default function* reportWatcher() {
    yield takeLatest(actionTypes.UPDATE_REPORT_QUERY_PARAMS, reportRequest);
    yield takeLatest(actionTypes.CREATE_REPORT['REQUEST'], handleCreateReport);
    yield takeLatest(actionTypes.UPDATE_REPORT['REQUEST'], handleUpdateReport);
    yield takeLatest(actionTypes.UPDATE_CONTEXT_ROW['REQUEST'], handleContextRowUpdate);
    yield takeLatest(actionTypes.UPDATE_FAVOURITES['REQUEST'], updateTemplateFavourites);
    yield takeLatest(actionTypes.EXPORT_REPORT['REQUEST'], handleExportReport);
    yield takeLatest(actionTypes.GET_REPORT_TEMPLATES['REQUEST'], handleReportTemplatesRequest);
    yield takeLatest(actionTypes.SHARE_REPORT['REQUEST'], handleShareReport);
    yield takeLatest(actionTypes.SHARED_REPORT['REQUEST'], sharedReport);
    yield takeLatest(actionTypes.DELETE_REPORT['REQUEST'], handleDeleteReportRequest);
    yield takeLatest(actionTypes.SEND_SUGGEST_REPORT, handleSendSuggestReportRequest);
    yield takeLatest(actionTypes.ADD_REPORTS_GROUP['REQUEST'], handleAddReportsGroupSaga);
    yield takeLatest(actionTypes.GET_REPORTS_GROUPS['REQUEST'], handleGetReportsGroupSaga);
    yield takeLatest(actionTypes.UPDATE_REPORTS_GROUP['REQUEST'], handleUpdateReportsGroupSaga);
    yield takeLatest(actionTypes.DELETE_REPORTS_GROUP['REQUEST'], handleDeleteReportsGroupSaga);
    yield takeLatest(actionTypes.REMOVE_REPORT_FROM_GROUP, handleRemoveReportFromGroupSaga);
}
