import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import InfoBar from './InfoBar';
import { store } from '../../store';
import { deleteProjectGroup } from 'actions/projectGroupActions';

export const projectGroupsStepKey = 'projectGroups';

const projectGroupsStepRowElements = [
    {
        name: 'name',
        type: 'text',
        label: 'Project group name',
        placeholders: ['Priority Projects', 'North America', 'APAC', 'Design Only', 'BAU'],
        validator: emptyStringValidator,
        minWidth: '250px',
    },
];

export const ProjectGroupsOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteProjectGroup.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    const onResetChangesOnCreatedInt = useCallback(
        rowIndex => {
            onResetChangesOnCreated(rowIndex, {
                name: 'name',
            });
        },
        [onResetChangesOnCreated]
    );

    return (
        <div>
            <div className="mb-4">
                <InfoBar type="info">You can add the projects to your groups from the Scheduler level</InfoBar>
            </div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={projectGroupsStepRowElements}
                getValue={key => storedData[`${projectGroupsStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreatedInt}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${projectGroupsStepKey}-meta-showValidationErrors`]}
                addMoreLabel="more groups"
                objectName="project group"
            />
            {storedData[`${projectGroupsStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default ProjectGroupsOnboardingStepContent;
