import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeCategoryGroups } from 'selectors/categoryGroups';

const useProjectCategoryGroups = project => {
    const projectCategoryGroupsSelector = useMemo(() => makeCategoryGroups(project?.categoryGroups), [
        project?.categoryGroups,
    ]);
    return useSelector(projectCategoryGroupsSelector);
};

export default useProjectCategoryGroups;
