import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ArrowForward, Close } from '@material-ui/icons';
import { ExtensionTemplate } from '../../../../shared/components/ExtensionTemplate';
import { getBillingExtensions } from '../../../../actions/billingActions';
import { useSelector, useDispatch } from 'react-redux';
import { makeGetBillingExtensions } from '../../../../selectors/billingExtensions';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Scrollbar from '../../../../shared/scrollbar';
import { useWindowDimension } from '../../../../hooks';
import { MissingToilExtensionContent } from '../../../toil/missingExtension';

export const useStyles = makeStyles({
    escBtn: {
        marginTop: `30px !important`,
        marginRight: `30px !important`,
    },
    NoExtension_newVersionContainer: {
        display: 'flex',
        justifyContent: 'center',
        overflowY: 'auto',
        height: '100%',
    },
});

const NoExtension = props => {
    const {
        title,
        text,
        url,
        btnText,
        videoSrc,
        className,
        videoPosterSrc,
        posterImageSrc,
        onCloseClick,
        fullScreen,
        newVersionShortId,
    } = props;

    const billingExtensions = useSelector(makeGetBillingExtensions());
    const dispatch = useDispatch();

    const classes = useStyles();

    const windowDimension = useWindowDimension();

    useEffect(() => {
        newVersionShortId && dispatch(getBillingExtensions.request());
    }, [dispatch, newVersionShortId]);

    const contentJSX = useCallback(() => {
        return (
            <>
                <img
                    style={{ height: '80px', width: '80px' }}
                    src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png"
                />
                <h2>{title}</h2>
                <div className="helpText">{text}</div>
                <div style={{ marginBottom: '20px' }} />

                <div className="d-flex justify-content-center my-4">
                    {onCloseClick && (
                        <Button color="secondary" className="rounded-0 btn-cancel mr-2" onClick={onCloseClick}>
                            <span>
                                <Close />
                            </span>{' '}
                            Cancel
                        </Button>
                    )}

                    {url && btnText && (
                        <Button color="success" className="rounded-0 btn-request" tag="a" href={url}>
                            <span>
                                <ArrowForward />
                            </span>{' '}
                            {btnText}
                        </Button>
                    )}
                </div>
                {videoSrc && videoPosterSrc && (
                    <div>
                        <video
                            className="video-js"
                            controls
                            controlsList="nodownload"
                            preload="none"
                            width="640"
                            height="360"
                            poster={videoPosterSrc}
                            data-setup="{}"
                        >
                            <source src={videoSrc} type="video/mp4" />
                            <source src="" type="video/webm" />
                            <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a web browser
                                that
                                <a
                                    href="http://videojs.com/html5-video-support/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    supports HTML5 video
                                </a>
                            </p>
                        </video>
                    </div>
                )}
                {posterImageSrc && <img src={posterImageSrc} width="500" />}
            </>
        );
    }, [btnText, onCloseClick, posterImageSrc, text, title, url, videoPosterSrc, videoSrc]);

    const newVersionJSX = useCallback(() => {
        if (newVersionShortId === 'toil') {
            return (
                <div>
                    <MissingToilExtensionContent />
                </div>
            );
        }

        // api still loading
        if (billingExtensions.length === 0) {
            return '';
        }

        const extensionInfo = (billingExtensions || []).find(billingExtension => {
            return billingExtension.shortId === newVersionShortId;
        });

        // shortId not found, or missing details. display previous version, retro compatibility
        if (!extensionInfo || !Array.isArray(extensionInfo.details)) {
            return contentJSX();
        }

        return (
            <div className={`${classes.NoExtension_newVersionContainer} ${className} `}>
                <ExtensionTemplate elements={extensionInfo.details} onClickLinks={onCloseClick} />
            </div>
        );
    }, [
        billingExtensions,
        classes.NoExtension_newVersionContainer,
        onCloseClick,
        newVersionShortId,
        contentJSX,
        className,
    ]);

    const innerJSX = useCallback(() => (newVersionShortId ? newVersionJSX() : contentJSX()), [
        newVersionShortId,
        contentJSX,
        newVersionJSX,
    ]);

    if (fullScreen) {
        return (
            <Modal
                isOpen
                size="lg"
                fade={false}
                className={classnames('custom-modal', 'mt-0', 'hub-warning-wrapper', 'hub-warning-wrapper-fullscreen')}
            >
                <Scrollbar height={windowDimension.windowHeight - 5}>
                    <ModalBody>
                        <Button
                            className={classes.escBtn}
                            data-cy="close-extension-modal"
                            color="esc"
                            onClick={onCloseClick}
                        >
                            <Close />
                            <div>esc</div>
                        </Button>
                        {innerJSX()}
                    </ModalBody>
                </Scrollbar>
            </Modal>
        );
    }

    return <div className={`hub-warning-wrapper`}>{innerJSX()}</div>;
};

NoExtension.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    btnText: PropTypes.string,
    videoSrc: PropTypes.string,
    videoPosterSrc: PropTypes.string,
    posterImageSrc: PropTypes.string,
    newVersionShortId: PropTypes.string,
    onCloseClick: PropTypes.func,
};

NoExtension.defaultProps = {
    title: '',
    text: '',
    url: '',
    btnText: '',
    videoSrc: '',
    videoPosterSrc: '',
    posterImageSrc: '',
    newVersionShortId: null,
    onCloseClick: null,
};

export default NoExtension;
