import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { usePressEscape } from 'hooks';
import { Menu, ClickAwayListener } from '@material-ui/core';
import { getContextMenuPosition } from 'utils/browserUtil';
import EditResourceParentItemsDropdown, {
    CONTEXT_MENU_WIDTH,
} from 'modules/scheduler/components/dropdowns/editResourceParentItems';
import { makeStyles } from '@material-ui/core/styles';

const EditResourceParentItems = ({ top, left, close, schedulerRef, windowHeight, windowWidth }) => {
    const node = useRef();
    const contextMenu = getContextMenuPosition({ windowHeight, windowWidth }, { top, left }, CONTEXT_MENU_WIDTH);
    usePressEscape(close);
    const handler = useCallback(
        event => {
            if (node.current && node.current.contains(event.target)) {
                close && close();
            }
        },
        [close]
    );

    const classes = useStyles();

    return (
        <Menu
            keepMounted
            className={classes.paper}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <div>
                <ClickAwayListener onClickAway={handler}>
                    <EditResourceParentItemsDropdown
                        height={contextMenu.height}
                        schedulerRef={schedulerRef}
                        onClose={close}
                    />
                </ClickAwayListener>
            </div>
        </Menu>
    );
};

const useStyles = makeStyles({
    paper: {
        borderRadius: 4,
        minWidth: '150px',
        padding: '5px 0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
    },
});

EditResourceParentItems.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
};

export default EditResourceParentItems;
