import React from 'react';
import PropTypes from 'prop-types';
import moize from 'moize';
import { Field } from 'redux-form';
import { filter } from 'lodash';
import { FormText, TabPane } from 'reactstrap';
import { useIsExtensionInstalled, useHasRights, useAppKeyWords } from 'hooks';
import { multiSelectField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { UNASSIGNED } from 'enums/extensionShortIdEnum';

const filterProjectGroups = projectGroups =>
    filter(projectGroups, projectGroup => 'USER' === projectGroup.groupType && !projectGroup.isSmart);
let filteredProjectGroups = [];

const rights = [
    {
        rights: ['settingProjectRes'],
        rule: 'one',
        name: 'hasSettingProjectRes',
    },
    {
        rights: ['manageUnassignedRows'],
        rule: 'one',
        name: 'hasManageUnassignedRows',
    },
    {
        rights: ['settingProjectGroups'],
        rule: 'one',
        name: 'hasSettingProjectGroups',
    },
];

const ResourceAndGroupsTab = ({
    tabId,
    resources,
    projectGroups,
    unassignedWork,
    projectKeyWord,
    showProjectGroups,
    type,
}) => {
    const { resourcePluralKeyWord } = useAppKeyWords();
    const isUnassignedExtensionInstalled = useIsExtensionInstalled(UNASSIGNED);
    const { hasSettingProjectRes, hasManageUnassignedRows, hasSettingProjectGroups } = useHasRights(rights);
    if (showProjectGroups) {
        filteredProjectGroups = moize(filterProjectGroups, { maxSize: 2 });
    }

    const showAddProjectsToGroupsSection = showProjectGroups && type === TYPE_REGULAR.value && hasSettingProjectGroups;

    return (
        <TabPane tabId={tabId}>
            {hasSettingProjectRes ? (
                <>
                    <section className="form-fields">
                        <p className="title">Add {resourcePluralKeyWord}:</p>
                        <Field
                            inline
                            width="col-md-12"
                            name="resources"
                            component={multiSelectField}
                            caseSensitive={false}
                            valueField="_id"
                            filter="contains"
                            textField={item => (item.firstName ? `${item.firstName} ${item.lastName}` : '')}
                            data={resources}
                        />
                        <FormText>
                            Choose which {resourcePluralKeyWord.toLowerCase()} belong to this{' '}
                            {projectKeyWord.toLowerCase()}
                        </FormText>
                    </section>
                    {hasManageUnassignedRows && isUnassignedExtensionInstalled && (
                        <section className="form-fields">
                            <p className="title">Add unassigned work To {projectKeyWord.toLowerCase()}:</p>
                            <Field
                                inline
                                width="col-md-12"
                                name="unassignedWork"
                                component={multiSelectField}
                                caseSensitive={false}
                                valueField="_id"
                                filter="contains"
                                textField={item => (Object.keys(item).length ? item.firstName : '')}
                                data={unassignedWork}
                            />
                            <FormText>Add unassigned work to this {projectKeyWord.toLowerCase()}</FormText>
                        </section>
                    )}
                </>
            ) : null}
            {showAddProjectsToGroupsSection ? (
                <section className="form-fields border-bottom-0">
                    <p className="title">Add {projectKeyWord.toLowerCase()} To groups:</p>
                    <Field
                        inline
                        width="col-md-12"
                        name="projectGroups"
                        component={multiSelectField}
                        caseSensitive={false}
                        dropUp={true}
                        valueField="_id"
                        filter="contains"
                        textField="name"
                        data={filteredProjectGroups(projectGroups)}
                    />
                    <FormText>Choose which groups belong to this {projectKeyWord.toLowerCase()}</FormText>
                </section>
            ) : null}
            {!hasSettingProjectRes && !showAddProjectsToGroupsSection ? <PermissionDenied /> : null}
        </TabPane>
    );
};

ResourceAndGroupsTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projectKeyWord: PropTypes.string,
    type: PropTypes.string,
    unassignedWork: PropTypes.array,
    resources: PropTypes.array,
    projectGroups: PropTypes.array,
    showProjectGroups: PropTypes.bool,
};

ResourceAndGroupsTab.defaultProps = {
    projectKeyWord: 'Project',
    type: TYPE_REGULAR.value,
    unassignedWork: [],
    resources: [],
    projectGroups: [],
    showProjectGroups: true,
};

export default ResourceAndGroupsTab;
