import { createContext } from 'react';

const menuContext = createContext({
    rowHeight: 0,
    usePlaceholder: 0,
    classStore: {},
    LinkComponent: null,
    loadingSubmenuData: false
});

const { Provider, Consumer } = menuContext;
export { Provider, Consumer };

export default menuContext;
