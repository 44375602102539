import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAppKeyWords, useForm, useHasRights } from 'hooks';
import { TabContent, Alert } from 'reactstrap';
import { Form, reduxForm, submit, getFormValues } from 'redux-form';
import { Archive } from '@material-ui/icons';
import { hideModal } from 'actions/modalActions';
import { deleteResource, updateResource, resetResourceValue } from 'actions/resourceActions';
import { getTags } from 'actions/tagActions';
import { getCustomFieldsRequest } from 'actions/customFieldActions';
import CommonModalTemplate from 'shared/modal/commonModalTemplate';
import ConfirmTooltipButton from 'shared/confirmTooltipButton';
import { TYPE_UNASSIGNED, TYPE_REGULAR, STATUS_ARCHIVED } from 'enums/resourceEnum';
import { formSchema, mapFormToRequest } from 'forms/resourceForm';
import { validateSchema } from 'utils/schemaUtil';
import { pickChangedFields, pickResourceAvailabilityFields } from 'utils/formUtil';
import ResourceBasicInfoTab from './resourceBasicInfo';
import ProjectsTab from './projectsAndGroups';
import ResourceRatesTab from './resourceRates';
import AvailabilityTab from './availability';
import VacationTab from './vacation';
import NotesTab from 'modules/modals/common/notes';
import CustomFieldTab, { useCustomFieldsFormValidator } from 'modules/modals/common/customField';
import TabLinks from 'shared/tabLinks';
import { getTabIcons } from 'modules/modals/resourceModal/tabIcons';
import { getResourceGroups } from 'actions/resourceGroupActions';
import { getProjects } from 'actions/projectActions';
import { useModalPressEnter } from '../../../../hooks/useModalPressEnter';
import { AvailabilityProvider } from './AvailabilityOverride/AvailabilityContext';

export const FORM_NAME = 'resourceForm';

const rights = [
    {
        rights: ['settingResourceNotes'],
        rule: 'one',
        name: 'hasSettingResourceNotes',
    },
    {
        rights: ['settingResourceLinks'],
        rule: 'one',
        name: 'hasSettingResourceLinks',
    },
    {
        rights: ['settingResourceTags'],
        rule: 'one',
        name: 'hasSettingResourceTags',
    },
    {
        rights: ['settingResourceCf'],
        rule: 'one',
        name: 'hasSettingResourceCf',
    },
    {
        rights: ['settingResourceGroups'],
        rule: 'one',
        name: 'hasSettingResourceGroups',
    },
    {
        rights: ['settingResourcePro'],
        rule: 'one',
        name: 'hasSettingResourcePro',
    },
    {
        rights: ['settingDeleteResources'],
        rule: 'one',
        name: 'hasDeleteResourcesRights',
    },
    {
        rights: ['settingResourceStatus'],
        rule: 'one',
        name: 'hasSettingResourceStatus',
    },
];

const valuesSelector = getFormValues(FORM_NAME);

export const useResourceModalFormValues = () => {
    return useSelector(valuesSelector);
};

const ResourceModal = props => {
    const {
        resourceId,
        resourceCustomFields,
        resourceTags,
        windowHeight,
        invalid,
        pristine,
        type,
        resourceKeyWord,
        onClose,
        formSyncErrors,
        resource,
        ...restOfProps
    } = props;

    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('1');
    const [rangesValid, setRangesValid] = useState(true);
    const updateFields = useForm(FORM_NAME);

    const {
        hasSettingResourceNotes,
        hasSettingResourceLinks,
        hasSettingResourceTags,
        hasSettingResourceCf,
        hasSettingResourceGroups,
        hasSettingResourcePro,
        hasDeleteResourcesRights,
        hasSettingResourceStatus,
    } = useHasRights(rights);
    const { resourcePluralKeyWord, projectPluralKeyWord, projectKeyWord, vacationEventName } = useAppKeyWords();
    const accountResourceId = useSelector(state => state.account.resourceId);

    const resourceTabName = useSelector(state => state.companyReducer.company.settings.customField?.resourceTabName);
    const globalVacationAllocationLimit = useSelector(
        state => state.companyReducer.company.settings.globalVacationAllocationLimit
    );

    const formValues = useSelector(valuesSelector);
    const { vacation, customAvailability, customAvailabilityOverrides } = formValues;

    const customFieldsValid = useCustomFieldsFormValidator({
        formCustomFieldsValues: formValues.customFields,
        customFields: resourceCustomFields,
        hasRights: hasSettingResourceCf,
    });

    useEffect(() => {
        if (
            'DEFAULT' === vacation &&
            restOfProps.initialized &&
            resource._id &&
            globalVacationAllocationLimit != null
        ) {
            updateFields({ customVacationAllocationLimit: globalVacationAllocationLimit });
        }
    }, [vacation, updateFields, globalVacationAllocationLimit, restOfProps.initialized, resource._id]);

    useEffect(() => {
        hasSettingResourceCf && dispatch(getCustomFieldsRequest('resource'));
        hasSettingResourceTags && dispatch(getTags.request('resource'));
        hasSettingResourceGroups && dispatch(getResourceGroups.request());
        hasSettingResourcePro && dispatch(getProjects.request());
    }, [
        dispatch,
        resourceId,
        hasSettingResourceTags,
        hasSettingResourceCf,
        hasSettingResourceGroups,
        hasSettingResourcePro,
    ]);

    const toggle = tabId => {
        if (tabId !== activeTab) {
            setActiveTab(tabId);
        }
    };

    const onSubmit = (values, dispatch, { initialValues }) => {
        const changedValues = pickChangedFields(values, initialValues);
        const resourceAvailabilityFields = pickResourceAvailabilityFields(values);
        const toSubmit = mapFormToRequest({
            changedValues,
            values: {
                ...changedValues,
                ...resourceAvailabilityFields,
                resourceGroups: values.resourceGroups,
                projects: values.projects,
                customAvailability,
            },
            customFieldTemplates: resourceCustomFields,
            resource,
            projects: restOfProps.projects,
            resourceGroups: restOfProps.resourceGroups,
            thumb: restOfProps.thumb,
            type,
        });
        restOfProps.onSubmit(toSubmit);
    };

    const onCloseModal = () => {
        dispatch(resetResourceValue.request('resource'));
        dispatch(hideModal());
        if (onClose) {
            onClose();
        }
    };

    const tabIcons = getTabIcons(type, {
        projectKeyWord,
        resourceKeyWord,
        resourcePluralKeyWord,
        projectPluralKeyWord,
        vacationEventName,
        customFieldTitle: resourceTabName,
    });
    const archiveResource = () => {
        dispatch(updateResource.request(resourceId, { companySpecific: { status: STATUS_ARCHIVED.value } }));
        onCloseModal();
    };

    const deleteCurrentResource = () => {
        dispatch(deleteResource.request(resourceId));
        onCloseModal();
    };

    const extendFooter = resourceId && (
        <>
            {hasDeleteResourcesRights && (
                <ConfirmTooltipButton
                    onConfirm={deleteCurrentResource}
                    buttonText={`Delete ${resourceKeyWord.toLowerCase()}`}
                    dataCyPrefix="resource-modal-delete"
                />
            )}
            {resource.status &&
                resource.status !== STATUS_ARCHIVED.value &&
                TYPE_UNASSIGNED.value !== type &&
                hasSettingResourceStatus && (
                    <ConfirmTooltipButton
                        onConfirm={archiveResource}
                        color="secondary"
                        icon={Archive}
                        buttonText={`Archive ${resourceKeyWord.toLowerCase()}`}
                        dataCyPrefix="resource-modal-archive"
                    />
                )}
            {restOfProps.hasSubmitFailed && (
                <Alert className="form-footer-alert ml-3" color="danger">
                    Please fill out all required fields
                </Alert>
            )}
        </>
    );

    const disabledSaveButton =
        invalid || pristine || !rangesValid || !customFieldsValid || formSyncErrors.customAvailability;

    const saveButtonTooltip =
        (!customFieldsValid ? (
            <>
                You must fill in the required custom fields first (<i className="fa fa-pencil" />) to be able to save
                the resource.
            </>
        ) : (
            undefined
        )) || props.formSyncErrors.isApprover;

    const onSave = () => {
        return dispatch(submit(FORM_NAME));
    };

    useModalPressEnter(() => {
        if (disabledSaveButton) {
            return;
        }

        onSave();
    });

    return (
        <CommonModalTemplate
            submitOnPressEnter
            title={resourceKeyWord}
            description={`A ${resourceKeyWord} is a member of your team`}
            onSave={onSave}
            windowHeight={windowHeight}
            extendFooter={extendFooter}
            disabledSaveButton={disabledSaveButton}
            saveButtonTooltip={saveButtonTooltip}
            onHideModalCustom={onCloseModal}
            saveButtonText={resourceId ? 'Update' : 'Add New'}
            saveButtonDataCy={resourceId ? 'button--update--res-modal' : 'button--add-new--res-modal'}
        >
            <AvailabilityProvider>
                <Form className="needs-validation" onSubmit={restOfProps.handleSubmit(onSubmit)}>
                    <TabLinks tabs={tabIcons} toggle={toggle} />
                    <TabContent activeTab={activeTab}>
                        <ResourceBasicInfoTab
                            tabId="1"
                            showInviteSection={0 === resourceId.length}
                            resourceFirstName={restOfProps.firstName}
                            tags={resourceTags}
                            resourceKeyWord={resourceKeyWord}
                            resourceType={type}
                            thumb={restOfProps.thumb}
                            onChange={updateFields}
                            isAccountOwner={resource._id && resource._id === accountResourceId}
                        />
                        {'2' === activeTab && (
                            <ProjectsTab
                                tabId="2"
                                projects={restOfProps.projects}
                                resourceGroups={restOfProps.resourceGroups}
                            />
                        )}
                        {'3' === activeTab && (
                            <ResourceRatesTab
                                tabId="3"
                                resourceRates={restOfProps.resourceRates}
                                onChange={updateFields}
                                onRatesValidChange={setRangesValid}
                            />
                        )}
                        {'4' === activeTab && (
                            <NotesTab
                                tabId="4"
                                noteKeyWord={resourceKeyWord}
                                linksKeyWord={resourceKeyWord}
                                hasNoteRights={hasSettingResourceNotes}
                                hasLinksRights={hasSettingResourceLinks}
                                onChange={updateFields}
                                note={formValues.note}
                                iconLinks={restOfProps.iconLinks}
                            />
                        )}
                        {'5' === activeTab && (
                            <CustomFieldTab tabId="5" onChange={updateFields} customFields={resourceCustomFields} />
                        )}
                        {type !== TYPE_UNASSIGNED.value && '6' === activeTab && (
                            <AvailabilityTab
                                tabId="6"
                                useResourceAvailability={restOfProps.useResourceAvailability}
                                onChange={updateFields}
                                customAvailabilityValues={customAvailability}
                                customAvailabilitiesErrors={formSyncErrors.customAvailability}
                                customAvailabilityOverridesValues={customAvailabilityOverrides}
                                resourceId={resourceId}
                            />
                        )}
                        {type !== TYPE_UNASSIGNED.value && <VacationTab tabId="7" resourceId={resourceId} />}
                    </TabContent>
                </Form>
            </AvailabilityProvider>
        </CommonModalTemplate>
    );
};

ResourceModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    resourceId: PropTypes.string,
    type: PropTypes.string,
};

ResourceModal.defaultProps = {
    resourceId: '',
    type: TYPE_REGULAR.value,
};

export default reduxForm({
    form: FORM_NAME,
    updateUnregisteredFields: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: validateSchema(formSchema),
})(ResourceModal);
