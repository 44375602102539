import { call, takeLatest, put, fork, join, select } from 'redux-saga/effects';
import { keys } from 'lodash';
import { push } from 'connected-react-router';
import * as actionTypes from 'actions/actionTypes';
import { hideModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import {
    getClientToken,
    getBillingPlans,
    getBillingExtensions,
    unsubscribeExtension,
    getBillingView,
    deleteSubscription,
    getPaymentInformation,
    updatePaymentState,
} from 'actions/billingActions';
import {
    getClientTokenRequest,
    getBillingPlansRequest,
    getBillingExtensionsRequest,
    vatIDValidationRequest,
    getBillingViewRequest,
    createSubscriptionRequest,
    deleteSubscriptionRequest,
    updateSubscriptionRequest,
    getPaymentInformationRequest,
    updateCustomerRequest,
} from 'api/billing';
import { unsubscribeEstensionRequest, updateExtensionSubscriptionRequest } from 'api/extension';
import { handleGeoLookupRequest } from 'sagas/generalSaga';
import { getCompany, handleCompanyBillingRequest } from 'sagas/companySaga';
import { getBillingRegion } from 'enums/billingRegionEnum';
import { getCompanyId } from 'selectors/company';
import { disableMFAReminderSaga } from './helpers/mfa';
import { mfaReminderTypes } from 'shared/constants/mfaReminders';
import { monitoring } from '../monitoring';

const getGeoLocation = state => state.initialAppReducer.geoLocation;
const getBillingInfo = state => state.companyReducer.billingInfo.billing;

const defaultBilling = {
    billingPlanRegion: 'USD',
};

function* handleBillingRegion() {

    try {
        let geoLockup = yield select(getGeoLocation);
        let companyBillingInfo = yield select(getBillingInfo);

        if (!keys(companyBillingInfo).length) {
            const companyBillingInfoRequest = yield fork(handleCompanyBillingRequest);
            companyBillingInfo = yield join(companyBillingInfoRequest);

            companyBillingInfo = companyBillingInfo?.billing || defaultBilling;
        }

        if (!keys(geoLockup).length) {
            const geoLockupRequest = yield fork(handleGeoLookupRequest);
            geoLockup = yield join(geoLockupRequest);
        }

        return getBillingRegion(companyBillingInfo.billingPlanRegion, geoLockup);
    } catch (error) {
        monitoring.captureException(error);
        return {};
    }
}

function* handleClientTokenRequest(action) {
    try {
        const getBillingRegion = yield fork(handleBillingRegion);
        const billingRegion = yield join(getBillingRegion);
        const clientToken = yield call(getClientTokenRequest, action.payload.customerId, billingRegion.currencyId);

        yield put(getClientToken.success(clientToken));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Cannot retrieve a billing token',
                type: 'danger',
            })
        );
    }
}

function* handleBillingPlansRequest() {
    try {
        const getBillingRegion = yield fork(handleBillingRegion);
        const billingRegion = yield join(getBillingRegion);
        const billingPlans = yield call(getBillingPlansRequest, billingRegion.region);

        yield put(getBillingPlans.success(billingPlans, billingRegion));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Cannot load billing plans',
                type: 'danger',
            })
        );
    }
}

function* handleBillingExtensionsRequest() {
    try {
        const getBillingRegion = yield fork(handleBillingRegion);
        const billingRegion = yield join(getBillingRegion);
        const billingExtensions = yield call(getBillingExtensionsRequest, billingRegion.region || 'USD');

        yield put(getBillingExtensions.success(billingExtensions));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Cannot load billing plans',
                type: 'danger',
            })
        );
    }
}

function* handleUnsubscribeExtensionRequest(action) {
    try {
        const billingExtension = yield call(unsubscribeEstensionRequest, action.payload.body);

        yield put(unsubscribeExtension.success(billingExtension));
        const companyId = yield select(getCompanyId);
        yield fork(getCompany, companyId);

        if (action.payload.body?.extensionPlanId?.indexOf('hub_extension_mfa_') === 0) {
            yield disableMFAReminderSaga(mfaReminderTypes.MFA_COMPANY_REMINDER);
        }

        yield put(
            addNotification({
                message: 'Unsubscribed successfully',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "We cant' unsubscribe now your extension. Please try again later...",
                type: 'danger',
            })
        );
    }
}

export function* handleBillingViewRequest() {
    try {
        const billingView = yield call(getBillingViewRequest);

        yield put(getBillingView.success(billingView));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Cannot load billing information',
                type: 'danger',
            })
        );
    }
}

function* handleVatIdRequest(action) {
    try {
        const response = yield call(vatIDValidationRequest, action.payload.vatId);

        action.payload.resolve(response);
    } catch (error) {
        monitoring.captureException(error);
        //allow the user to skip the validation when service not available
        action.payload.resolve();
        yield put(
            addNotification({
                message: 'The validation of your VAT ID is not possible now, please check later...',
                type: 'danger',
            })
        );
    }
}

function* handlePurchaseRequest(action) {
    try {
        yield call(createSubscriptionRequest, action.payload.paymentMethodNonce, action.payload.body);
        const companyId = yield select(getCompanyId);

        yield fork(getCompany, companyId);
        yield fork(handleCompanyBillingRequest);
        yield put(hideModal());
        yield put(push('/settings/billing/subscription'));
        yield put(updatePaymentState.request(false));
        yield put(
            addNotification({
                message: 'Payment successfully',
                type: 'success',
            })
        );
        if ('production' === process.env.NODE_ENV) {
            window.gtag('event', 'conversion', {
                send_to: 'AW-994965946/e5z3CPPOgpEBELrzt9oD',
                transaction_id: companyId,
            });
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(updatePaymentState.request(false));
        yield put(
            addNotification({
                timeout: 10000,
                message:
                    error.data.message ||
                    'We could not create your subscription. Please check if you filled out all fields correctly, and try again.',
                type: 'danger',
            })
        );
    }
}

function* handleDeleteSubscriptionRequest(action) {
    try {
        yield call(deleteSubscriptionRequest, action.payload.password);
        const companyId = yield select(getCompanyId);

        yield fork(getCompany, companyId);
        yield fork(handleCompanyBillingRequest);
        yield put(deleteSubscription.success());
        yield put(hideModal());
        yield put(push('/settings/billing'));
        yield put(
            addNotification({
                message: 'Subscription successfully canceled',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(updatePaymentState.request(false));
        yield put(
            addNotification({
                message: 'We could not cancel your subscription...',
                type: 'danger',
            })
        );
    }
}

function* handleUpdateExtensionSubscriptionRequest(action) {
    try {
        yield call(updateExtensionSubscriptionRequest, action.payload.body);

        yield fork(handleBillingExtensionsRequest);
        const companyId = yield select(getCompanyId);
        yield fork(getCompany, companyId);
        yield put(
            addNotification({
                message: 'Your extensions have been successfully updated',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message:
                    'It looks like there was a problem sending us this request. Please try again. If it continues to fail, please contact us via the contact form in the header.',
                type: 'danger',
            })
        );
    }
}

function* handleUpdateSubscriptionRequest(action) {
    try {
        yield call(updateSubscriptionRequest, action.payload.body);

        yield fork(handleBillingExtensionsRequest);
        const companyId = yield select(getCompanyId);
        yield fork(getCompany, companyId);
        yield put(
            addNotification({
                message: 'Your license have been successfully updated.',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message:
                    'It looks like there was a problem sending us this request. Please try again. If it continues to fail, please contact us via the contact form in the header.',
                type: 'danger',
            })
        );
    }
}

function* handlePaymentInformationRequest() {
    try {
        const paymentInformation = yield call(getPaymentInformationRequest);

        yield put(getPaymentInformation.success(paymentInformation));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "We could't load your card details.",
                type: 'danger',
            })
        );
    }
}

function* handleCustomerRequest(action) {
    try {
        const companyId = yield select(getCompanyId);
        yield call(updateCustomerRequest, action.payload.body);
        yield put(hideModal());
        yield put(updatePaymentState.request(false));
        yield fork(getCompany, companyId);
    } catch (error) {
        monitoring.captureException(error);
        yield put(updatePaymentState.request(false));
        yield put(
            addNotification({
                message: "We could't update your card details.",
                type: 'danger',
            })
        );
    }
}

export default function* billingWatcher() {
    yield takeLatest(actionTypes.GET_BRAINTREE_CLIENT_TOKEN['REQUEST'], handleClientTokenRequest);
    yield takeLatest(actionTypes.GET_BILLING_PLANS['REQUEST'], handleBillingPlansRequest);
    yield takeLatest(actionTypes.GET_BILLING_EXTENSIONS['REQUEST'], handleBillingExtensionsRequest);
    yield takeLatest(actionTypes.UNSUBSCRIBE_EXTENSION['REQUEST'], handleUnsubscribeExtensionRequest);
    yield takeLatest(actionTypes.GET_BILLING_VIEW['REQUEST'], handleBillingViewRequest);
    yield takeLatest(actionTypes.VALIDATE_VAT_ID['REQUEST'], handleVatIdRequest);
    yield takeLatest(actionTypes.PURCHASE['REQUEST'], handlePurchaseRequest);
    yield takeLatest(actionTypes.DELETE_SUBSCRIPTION['REQUEST'], handleDeleteSubscriptionRequest);
    yield takeLatest(actionTypes.UPDATE_EXTENSION_SUBSCRIPTION['REQUEST'], handleUpdateExtensionSubscriptionRequest);
    yield takeLatest(actionTypes.UPDATE_SUBSCRIPTION['REQUEST'], handleUpdateSubscriptionRequest);
    yield takeLatest(actionTypes.GET_PAYMENT_INFORMATION['REQUEST'], handlePaymentInformationRequest);
    yield takeLatest(actionTypes.UPDATE_CUSTOMER['REQUEST'], handleCustomerRequest);
}
