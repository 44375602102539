import React, { useCallback, useMemo } from 'react';
import { updateDates } from '../../global/utils/dateTranslator';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    control: {
        width: '100%',
    },
    select: {
        height: '37px',
    },
    label: {
        transform: 'translate(14px, 14px) scale(1)',
    },
}));

const customDropdownDateRangesOptions = {
    dateState: 'custom',
    periodType: 'custom',
    label: 'Custom',
    dataCy: 'range-option-custom',
    disabled: true,
};

const dropdownDateRangesOptions = [
    {
        dateState: 'This',
        periodType: 'week',
        label: 'This week',
        dataCy: 'range-option-this-week',
        getRange: weekType => updateDates('this', null, weekType),
    },
    {
        dateState: 'Last',
        periodType: 'week',
        label: 'Last week',
        dataCy: 'range-option-last-week',
        getRange: weekType => updateDates('sub', { week: 1 }, weekType),
    },
    {
        dateState: 'Next',
        periodType: 'week',
        label: 'Next week',
        dataCy: 'range-option-next-week',
        getRange: weekType => updateDates('add', { week: 1 }, weekType),
    },
    {
        dateState: 'This',
        periodType: 'month',
        label: 'This month',
        dataCy: 'range-option-this-month',
        getRange: () => updateDates('this', null, 'month'),
    },
    {
        dateState: 'Last',
        periodType: 'month',
        label: 'Last month',
        dataCy: 'range-option-last-month',
        getRange: () => updateDates('sub', { months: 1 }, 'month'),
    },
    {
        dateState: 'Next',
        periodType: 'month',
        label: 'Next month',
        dataCy: 'range-option-next-month',
        getRange: () => updateDates('add', { months: 1 }, 'month'),
    },
    {
        dateState: 'This',
        periodType: 'quarter',
        label: 'This quarter',
        dataCy: 'range-option-this-quarter',
        getRange: () => updateDates('this', null, 'quarter'),
    },
    {
        dateState: 'Last',
        periodType: 'quarter',
        label: 'Last quarter',
        dataCy: 'range-option-last-quarter',
        getRange: () => updateDates('sub', { quarters: 1 }, 'quarter'),
    },
    {
        dateState: 'Next',
        periodType: 'quarter',
        label: 'Next quarter',
        dataCy: 'range-option-next-quarter',
        getRange: () => updateDates('add', { quarters: 1 }, 'quarter'),
    },
    {
        dateState: 'This',
        periodType: 'year',
        label: 'This year',
        dataCy: 'range-option-this-year',
        getRange: () => updateDates('this', { years: 1 }, 'year'),
    },
    {
        dateState: 'Last',
        periodType: 'year',
        label: 'Last year',
        dataCy: 'range-option-last-year',
        getRange: () => updateDates('sub', { years: 1 }, 'year'),
    },
    {
        dateState: 'Next',
        periodType: 'year',
        label: 'Next year',
        dataCy: 'range-option-next-year',
        getRange: () => updateDates('add', { years: 1 }, 'year'),
    },
];

const ButtonsV2 = props => {
    const { weekType, dateState, periodType, onDatesChanged, dataCy, disabled } = props;

    const classes = useStyles();

    const handleChange = useCallback(
        event => {
            if (!event.target.value.disabled) {
                const dates = event.target.value.getRange(weekType);
                if (onDatesChanged) {
                    onDatesChanged(dates);
                }
            }
        },
        [onDatesChanged, weekType]
    );

    const value = useMemo(() => {
        return [customDropdownDateRangesOptions, ...dropdownDateRangesOptions].find(
            option => option.dateState === dateState && option.periodType === periodType
        );
    }, [dateState, periodType]);

    return (
        <FormControl variant="outlined" className={classes.control}>
            <InputLabel id="time-range" className={classes.label}>
                Time range
            </InputLabel>
            <Select
                disabled={disabled}
                className={classes.select}
                data-cy={dataCy}
                labelId="time-range"
                value={value}
                onChange={handleChange}
                label="Time range"
                MenuProps={{
                    'data-in-portal': 'true',
                }}
            >
                {/* Appear only when custom */}
                {value &&
                    value.dateState === customDropdownDateRangesOptions.dateState &&
                    value.periodType === customDropdownDateRangesOptions.periodType && (
                        <MenuItem
                            key={customDropdownDateRangesOptions.dataCy}
                            data-cy={customDropdownDateRangesOptions.dataCy}
                            value={customDropdownDateRangesOptions}
                            disabled={customDropdownDateRangesOptions.disabled}
                        >
                            {customDropdownDateRangesOptions.label}
                        </MenuItem>
                    )}
                {dropdownDateRangesOptions.map(option => {
                    return (
                        <MenuItem key={option.dataCy} data-cy={option.dataCy} value={option} disabled={option.disabled}>
                            {option.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

const memoized = React.memo(ButtonsV2);

export { memoized as ButtonsV2 };
