import React from 'react';
import { Redirect } from 'react-router-dom';

const Home = props => {
    if ('/requests' === props.location.pathname) {
        return (
            <Redirect
                to={{
                    pathname: '/requests/my-vacations',
                }}
            />
        );
    }

    return null;
};

export default Home;
