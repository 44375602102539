import React from 'react';
import { Public } from '@material-ui/icons';
import { EnvName } from './styles';

const isNotProdEnv = () => {
    const env = window.location.host.split('.')[1];

    return 'staging' === env || 'staging2' === env || 'sandbox' === env || 'localhost' === window.location.hostname;
};

const getEnvName = () => {
    const env = window.location.host.split('.')[1];

    return 'staging' === env
        ? 'Staging 1'
        : 'staging2' === env
        ? 'Staging 2'
        : 'sandbox' === env
        ? 'SandBox'
        : 'localhost' === window.location.hostname
        ? 'Local'
        : 'Not Defined';
};

export default () =>
    isNotProdEnv() ? (
        <EnvName className="text-truncate">
            <Public />
            {getEnvName()}
        </EnvName>
    ) : null;
