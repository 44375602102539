import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export const useResizeObserver = ({ onResize, debounceTime = 50 }) => {
    const targetRef = useRef(null);

    const observerRef = useRef(
        // eslint-disable-next-line no-undef
        new ResizeObserver(
            debounce(() => {
                if (!targetRef.current) {
                    return;
                }

                onResize();
            }, debounceTime)
        )
    );

    const onResizeTargetRef = useCallback(ref => {
        if (ref) {
            targetRef.current = ref;
            observerRef.current.observe(ref);
        }
    }, []);

    useEffect(() => {
        const observer = observerRef.current;

        return () => {
            if (observer) {
                if (targetRef.current) {
                    observer.unobserve(targetRef.current);
                }
                observer.disconnect();
            }
        };
    }, []);

    return { onResizeTargetRef };
};
