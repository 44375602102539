import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FormText, TabPane } from 'reactstrap';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import { useAppKeyWords, useHasRights } from 'hooks';
import { multiSelectField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';
import { selectAvailableCalendars } from '../../../../selectors/company';
import { CustomAvailability, daysOrder } from '../../../../shared/components/CustomAvailability/CustomAvailability';
import { Checkbox } from '../../../../shared/components/checkbox';
import { RadioGroup } from '@material-ui/core';
import { Layout } from 'shared/components/layout';
import { AvailabilityOverride } from './AvailabilityOverride';
import { getCustomAvailabilityOverride } from 'api/resource';
import { AvailabilityContext } from './AvailabilityOverride/AvailabilityContext';

const StyledCheckBox = withStyles({
    root: {
        '&.MuiFormControlLabel-root': {
            '& .MuiTypography-body1': {
                fontSize: '13px',
            },
        },
    },
})(Checkbox);

const AvailabilityTab = ({
    tabId,
    useResourceAvailability,
    onChange,
    customAvailabilitiesErrors,
    customAvailabilityValues,
    customAvailabilityOverridesValues,
    resourceId,
}) => {
    const useAvailabilityContext = () => useContext(AvailabilityContext);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const calendars = useSelector(selectAvailableCalendars);
    const { resourceKeyWord } = useAppKeyWords();
    const { hasSettingResourceWd } = useHasRights([
        {
            rights: ['settingResourceWd'],
            rule: 'one',
            name: 'hasSettingResourceWd',
        },
    ]);

    const {
        customAvailabilitiesOverrides,
        setCustomAvailabilitiesOverrides,
        isApiDataLoaded,
        setIsApiDataLoaded,
    } = useAvailabilityContext();

    useEffect(() => {
        if (!isApiDataLoaded) {
            (resourceId ? getCustomAvailabilityOverride(resourceId) : Promise.resolve([])).then(data => {
                setCustomAvailabilitiesOverrides(state => [...data, ...state]);
                setIsApiDataLoaded(true);
            });
        }
    }, [resourceId, setCustomAvailabilitiesOverrides, isApiDataLoaded, setIsApiDataLoaded]);

    const isDefaultResourceAvailability = useResourceAvailability === 'DEFAULT';

    return (
        <TabPane tabId={tabId}>
            {hasSettingResourceWd ? (
                <>
                    <section className="form-fields">
                        <p className="title">Daily Capacity:</p>
                        <Field value={useResourceAvailability} name="useResourceAvailability" component={RadioGroup}>
                            <Layout>
                                <StyledCheckBox
                                    checked={isDefaultResourceAvailability}
                                    type="radio"
                                    value="DEFAULT"
                                    label="Default Availability"
                                    dataCy="default-availability"
                                    onClick={() => {
                                        onChange({
                                            customAvailability:
                                                companySettings?.weekDays &&
                                                daysOrder.map(day => {
                                                    const { workDay, intervals, _id, minutes } = companySettings?.weekDays[day];
                                                    return {
                                                        workDay,
                                                        name: day,
                                                        intervals,
                                                        minutes,
                                                        _id,
                                                    };
                                                }),
                                        });
                                    }}
                                    onChange={() =>
                                        onChange({
                                            useResourceAvailability: 'DEFAULT',
                                        })
                                    }
                                />
                                <StyledCheckBox
                                    checked={!isDefaultResourceAvailability}
                                    type="radio"
                                    dataCy="custom-availability"
                                    label="Use Custom Availability"
                                    value="CUSTOM"
                                    onChange={() =>
                                        onChange({
                                            useResourceAvailability: 'CUSTOM',
                                        })
                                    }
                                />
                            </Layout>
                        </Field>
                    </section>
                    <section className="form-fields">
                        <CustomAvailability
                            disabled={isDefaultResourceAvailability}
                            onChange={data =>
                                !isDefaultResourceAvailability &&
                                onChange({
                                    customAvailability: data,
                                })
                            }
                            data={customAvailabilityValues}
                            errors={customAvailabilitiesErrors}
                        />
                        <AvailabilityOverride
                            onChange={(data, apiData) => {
                                setCustomAvailabilitiesOverrides(data);
                                onChange({
                                    customAvailabilityOverrides: apiData,
                                });
                            }}
                            data={customAvailabilitiesOverrides}
                            apiData={customAvailabilityOverridesValues}
                        />
                    </section>
                    <section className="form-fields">
                        <p className="title">Public Holiday Calendars:</p>
                        <Field
                            inline
                            data-cy="public-holiday-calendars"
                            width="col-md-12"
                            name="calendars"
                            component={multiSelectField}
                            caseSensitive={false}
                            dropUp
                            valueField="_id"
                            filter="contains"
                            textField="calendarName"
                            data={calendars}
                        />
                        <FormText>Choose which public calendars apply to this {resourceKeyWord}.</FormText>
                    </section>
                </>
            ) : (
                <PermissionDenied />
            )}
        </TabPane>
    );
};

AvailabilityTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    useResourceAvailability: PropTypes.string,
    customAvailabilitiesErrors: PropTypes.object,
};

AvailabilityTab.defaultProps = {
    useResourceAvailability: 'DEFAULT',
};

export default AvailabilityTab;
