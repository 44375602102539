import React from 'react';
import classnames from 'classnames';
import { isUndefined, isString } from 'underscore';
import { InputGroup, Label, Input, InputGroupAddon, InputGroupText } from 'reactstrap';

const getIcon = (meta, Icon) => {
    if (meta && meta.asyncValidating) {
        return <i className="fa fa-spinner fa-pulse fa-fw" />;
    }

    if (isString(Icon) && -1 !== Icon.indexOf('fa')) {
        return <i className={classnames('fa', Icon)} />;
    }
    if (isString(Icon) && -1 === Icon.indexOf('fa')) {
        return <i>{Icon}</i>;
    }

    return <Icon fontSize="small" />;
};

const inputGroupTextField = React.forwardRef((props, ref) => {
    const { input, label, type, width, icon: Icon, className, disabled, placeholder, meta, dataCy, ...rest } = props;
    const show = meta && meta.touched && !isUndefined(meta.error);
    const icon = getIcon(meta, Icon);

    const iconDataCy = dataCy ? `${dataCy}--icon` : undefined;
    const appendDataCy = dataCy ? `${dataCy}--append` : undefined;

    return (
        <InputGroup className={classnames('addon', className)}>
            {label && (
                <Label for={input.name} className="col-form-label">
                    {label}
                </Label>
            )}
            <Input
                data-cy={dataCy}
                id={input.name}
                disabled={disabled}
                className={classnames(width)}
                placeholder={placeholder || label}
                invalid={show}
                type={type}
                ref={ref}
                {...rest}
                {...input}
            />
            <InputGroupAddon addonType="append" data-cy={appendDataCy}>
                <InputGroupText className={classnames({ disabled })} data-cy={iconDataCy}>
                    {icon}
                </InputGroupText>
            </InputGroupAddon>
            <div className="invalid-feedback text-left">{show && <span>{meta.error}</span>}</div>
        </InputGroup>
    );
});

inputGroupTextField.defaultProps = {
    input: {},
    disabled: false,
    icon: '',
    width: '',
    type: 'text',
    label: '',
    placeholder: '',
    meta: {},
};

export default inputGroupTextField;
