import React, { useCallback } from 'react';
import { useStyles } from '../styles';
import { Menu, MenuItem } from '@material-ui/core';
import { useHasRights, usePressEscape } from '../../../../../hooks';
import { showConfirmationModal, showSchedulerBuilderModal } from '../../../../../actions/modalActions';
import { RESOURCE } from '../../../enums/builderTypeEnum';
import { GROUP } from '../../../../../enums/groupTypeEnum';
import { ROW_FILTER_TAB } from '../../../modals/builder/tabs';
import { useGroupActions } from '../../../hooks';
import { useDispatch } from 'react-redux';

const rights = [
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'canAddNewEditResourceGroup',
    },
];

const SavedScheduleContextMenu = props => {
    const { close, top, left, schedule } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const { deleteGroup } = useGroupActions();
    const { canAddNewEditResourceGroup } = useHasRights(rights);

    usePressEscape(close);

    const handleEdit = useCallback(() => {
        dispatch(
            showSchedulerBuilderModal({
                itemTypeName: schedule?.queryParams?.itemType ?? RESOURCE.name,
                itemId: schedule._id,
                groupType: GROUP,
                initialTab: ROW_FILTER_TAB,
                isFilterContext: false,
            })
        );
        close();
    }, [close, dispatch, schedule._id, schedule?.queryParams?.itemType]);

    const handleDelete = useCallback(() => {
        dispatch(
            showConfirmationModal(
                () => {
                    close();
                    deleteGroup({
                        group: schedule,
                        itemType: {
                            name: schedule.type,
                        },
                    });
                },
                `Remove "${schedule.name}" Schedule`,
                `Removing "${schedule.name}" Schedule will permanently remove this from saved Schedules`,
                {
                    confirmButtonText: 'Yes, delete this schedule',
                    withCancel: true,
                    cancelButtonText: 'Cancel',
                    withEscButton: false,
                    cancelActionOnHide: true,
                    onReject: () => {
                        close();
                    },
                }
            )
        );
    }, [close, deleteGroup, dispatch, schedule]);

    return (
        <>
            <Menu
                keepMounted
                classes={classes}
                open={true}
                onClose={close}
                anchorReference="anchorPosition"
                anchorPosition={{ top, left }}
            >
                {canAddNewEditResourceGroup && <MenuItem onClick={handleEdit}>Edit schedule...</MenuItem>}
                {canAddNewEditResourceGroup && <MenuItem onClick={handleDelete}>Delete schedule...</MenuItem>}
            </Menu>
        </>
    );
};

const memoized = React.memo(SavedScheduleContextMenu);

export { memoized as SavedScheduleContextMenu };
