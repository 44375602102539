import React from 'react';
import PropTypes from 'prop-types';
import { ResourceParent as ProjectGroupMode } from 'modules/scheduler/components/dropdowns/shared';

export const CONTEXT_MENU_WIDTH = 280;

const EditResourceParentItemsDropdown = React.forwardRef(({ onClose, schedulerRef, height }, ref) => (
    <div ref={ref} className="rounded-0 p-0" style={{ width: `${CONTEXT_MENU_WIDTH}px` }}>
        <ProjectGroupMode schedulerRef={schedulerRef} height={height} width={CONTEXT_MENU_WIDTH} onClose={onClose} />
    </div>
));

EditResourceParentItemsDropdown.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    onClose: PropTypes.func,
};

EditResourceParentItemsDropdown.defaultProps = {
    onClose: null,
};

export default EditResourceParentItemsDropdown;
