import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const sendSuggestionEmail = data => ({
    type: actionTypes.SEND_SUGGESTION_INTEGRATION,
    payload: {
        data,
    },
});

export const getProjects = {
    request: provider => action(actionTypes.GET_PROJECTS_INTEGRATION['REQUEST'], { provider }),
    success: (provider, data) => action(actionTypes.GET_PROJECTS_INTEGRATION['SUCCESS'], { provider, data }),
    failure: provider => action(actionTypes.GET_PROJECTS_INTEGRATION['FAILURE'], { provider }),
};

export const getAccounts = {
    success: data => action(actionTypes.GET_ACCOUNTS_INTEGRATION['SUCCESS'], data),
    failure: provider => action(actionTypes.GET_ACCOUNTS_INTEGRATION['FAILURE'], { provider }),
};

export const getResources = {
    request: provider => action(actionTypes.GET_RESOURCES_INTEGRATION['REQUEST'], { provider }),
    success: (provider, data) => action(actionTypes.GET_RESOURCES_INTEGRATION['SUCCESS'], { provider, data }),
    failure: provider => action(actionTypes.GET_RESOURCES_INTEGRATION['FAILURE'], { provider }),
};

export const getAuthInformation = {
    request: (provider, identity) =>
        action(actionTypes.GET_AUTH_INFORMATION_INTEGRATION['REQUEST'], { provider, identity }),
    success: data => action(actionTypes.GET_AUTH_INFORMATION_INTEGRATION['SUCCESS'], data),
};

export const updateNewResources = {
    request: (data, provider) => action(actionTypes.UPDATE_NEW_RESOURCES_INTEGRATION['REQUEST'], { data, provider }),
};

export const updateNewProjects = {
    request: (data, provider) => action(actionTypes.UPDATE_NEW_PROJECTS_INTEGRATION['REQUEST'], { data, provider }),
};

export const removeAuthorization = {
    request: provider => action(actionTypes.REMOVE_AUTHORIZATION_INTEGRATION['REQUEST'], { provider }),
    success: provider => action(actionTypes.REMOVE_AUTHORIZATION_INTEGRATION['SUCCESS'], { provider }),
};

export const setLoadingStatus = {
    request: (provider, status) => action(actionTypes.SET_LOADING_STATUS_INTEGRATION['REQUEST'], { provider, status }),
};

export const setImportedStatus = {
    request: (provider, status) => action(actionTypes.SET_IMPORTED_STATUS_INTEGRATION['REQUEST'], { provider, status }),
};
