import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { assign } from 'lodash';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { getReportLevel } from 'modules/report/enums/storageItemEnum';
import { SortingText } from './styles';

class Sorting extends PureComponent {
    static propTypes = {
        columnName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    onSorting = () => {
        if (this.props.disabled) {
            return;
        }

        const reportLevel = getReportLevel(this.props.match.params);

        this.props.updateQueryParams(
            assign({}, this.props.queryParams, {
                sort: {
                    columnName: this.props.columnName,
                    direction: this.getDirection(),
                },
            }),
            reportLevel.storageItemName
        );
    };

    getDirection() {
        const { columnName, direction } = this.props.queryParams.sort;
        if (columnName !== this.props.columnName || (columnName === this.props.columnName && 'asc' === direction)) {
            return 'desc';
        }

        return 'asc';
    }

    render() {
        const { columnName, direction } = this.props.queryParams.sort;
        let Icon = null;

        if (columnName === this.props.columnName) {
            Icon = 'asc' === direction ? ArrowDropDown : ArrowDropUp;
        }

        return (
            <SortingText onClick={this.onSorting} isDisabled={this.props.disabled}>
                {Icon && <Icon />}
                {this.props.children}
            </SortingText>
        );
    }
}

export default withRouter(Sorting);
