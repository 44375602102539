import styled from 'styled-components';
import { Close } from '@material-ui/icons';

export const TextWrapper = styled.div`
    border-right: none;
    overflow: hidden;
    width: calc(100% - 85px);
`;

export const ItemContextMenu = styled.div`
    right: 0;
    position: absolute;
    top: 0;
    color: rgba(173, 181, 189, 0.2);
    line-height: 68px;
    padding: 0 7px;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const FilterWrapper = styled.div`
    position: relative;
    width: 80%;
    margin: 5px auto;
    display: inherit;
`;

export const FilterInput = styled.input`
    border: 0 !important;
    background-color: #131313 !important;
    font-style: italic;
    box-shadow: none;
`;

export const FilterIcon = styled(Close)`
    color: #585858;
    position: absolute;
    right: 9px;
    top: 13px;
    cursor: pointer;
    font-size: 15px !important;
`;
