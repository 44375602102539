import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TabContent, Alert } from 'reactstrap';
import { Form, getFormValues, reduxForm, getFormInitialValues, initialize } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useAppKeyWords, useForm, useHasRights, useIsExtensionInstalled, useLoadProjectsAndResources } from 'hooks';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { getCustomFieldsRequest } from 'actions/customFieldActions';
import { getBooking } from 'actions/bookingActions';
import { CUSTOM_FIELDS, TASKS } from 'enums/extensionShortIdEnum';
import { CELLDURATION, DAY, findByValue } from 'modules/scheduler/enums/scale';
import { formSchema, mapFormToRequest, getDefaultValues } from 'forms/scheduleResourceForm';
import { validateSchema } from 'utils/schemaUtil';
import { hasRole } from 'utils/rightsUtil';
import ScheduleBasicInfo from './scheduleBasicInfo';
import BookingRatesTab from './bookingRates';
import CustomFieldTab from 'modules/modals/common/customField';
import NotesTab from 'modules/modals/common/notes';
import BookingInfoTab from './bookingInfo';
import TabLinks from 'shared/tabLinks';
import TaskTab from './tasks';
import CommonModalTemplate from 'shared/modal/commonModalTemplate';
import SuccessButton from 'shared/buttons/success';
import { DEPENDENCIES_TAB_ID, getTabIcons } from 'modules/modals/scheduleResourceModal/tabIcons';
import { getDefaultCategoryByProject, getCategoryTemplatesByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { getAllocationFieldValuesByCategory } from 'utils/allocationUtil';
import ConfirmTooltipButton from 'shared/confirmTooltipButton';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { STATUS_ARCHIVED as STATUS_ARCHIVED_RESOURCE } from 'enums/resourceEnum';
import { STATUS_ARCHIVED as STATUS_ARCHIVED_PROJECT } from 'enums/projectEnum';
import { makeGetFilteredCategoryTemplates, selectCategoryTemplatesLoaded } from 'selectors/categoryTemplate';
import { makeCategoryGroups, selectCategoryGroupsLoaded } from 'selectors/categoryGroups';
import { waitFor } from '../../../../sagas/helpers/waitFor';
import { put, select, takeLatest } from 'redux-saga/effects';
import { action } from '../../../../actions/base';
import { LinkedBookingsTab } from './linkedBookings';
import { useModalPressEnter } from '../../../../hooks/useModalPressEnter';
import { getResourceCustomFields } from '../../../../selectors/customField';
import { hideModal } from '../../../../actions/modalActions';
import { getAllocationValuesForNewRequest } from 'modules/request/utils/requestUtil';

export const FORM_NAME = 'scheduleResourceForm';

const INIT_SCHEDULE_RESOURCE_FORM = 'INIT_SCHEDULE_RESOURCE_FORM';

function* handleFormInit(action) {
    const { booking } = action.payload;
    const currentSelection = yield select(state => state.scheduler.currentSelection);
    const companySettings = yield select(state => state.companyReducer.company.settings);
    const currentSchedulerGroup = yield select(state => state?.scheduler?.group);
    const state = companySettings.grid.bookingDefaultState;

    const values = {
        percentage: 100,
        hours: companySettings.report.avgDailyCapacity,
        total: companySettings.report.avgDailyCapacity,
    };
    const schedulerArchivedGroup =
        currentSchedulerGroup?.groupType === 'SYSTEM' && currentSchedulerGroup?.criteria === 'ARCHIVED';

    const bookingTmp = Object.assign({}, booking);

    let categoryGroups = yield select(makeCategoryGroups());
    let categoryTemplates = yield select(makeGetFilteredCategoryTemplates());

    const initializeDefaultValues = getDefaultValues(companySettings, categoryGroups || [], categoryTemplates || [], {
        state,
        ...values,
        scale: DAY.BEValue,
        ...bookingTmp,
    });

    yield put(initialize(FORM_NAME, initializeDefaultValues));

    yield waitFor(selectCategoryGroupsLoaded);
    yield waitFor(selectCategoryTemplatesLoaded);

    categoryGroups = yield select(makeCategoryGroups());
    categoryTemplates = yield select(makeGetFilteredCategoryTemplates());

    // No preselected data, and currentSelection exists from scheduler selection
    if (currentSelection && !booking._id) {
        if (!bookingTmp.start && currentSelection.start && !bookingTmp.end && currentSelection.end) {
            const scale = findByValue(window.schedulerRef.current.control?.scale);
            bookingTmp.scale = scale?.value === CELLDURATION.value ? scale.BEValue : DAY.BEValue;
        }

        !bookingTmp.start && currentSelection.start && (bookingTmp.start = currentSelection.start);
        !bookingTmp.end && currentSelection.end && (bookingTmp.end = currentSelection.end);

        // Preselect project archived if not archived OR user is in Archived group on scheduler
        if (
            !bookingTmp.project &&
            currentSelection?.rowTags?.project?._id &&
            (schedulerArchivedGroup || currentSelection?.rowTags?.project?.status !== STATUS_ARCHIVED_PROJECT.value)
        ) {
            bookingTmp.project = currentSelection?.rowTags?.project;
        }

        // Preselect resource archived if not archived OR user is in Archived group on scheduler
        if (
            !bookingTmp.resource &&
            currentSelection?.rowTags?.resource?._id &&
            (schedulerArchivedGroup || currentSelection?.rowTags?.resource?.status !== STATUS_ARCHIVED_RESOURCE.value)
        ) {
            bookingTmp.resource = currentSelection?.rowTags?.resource;
        }
    }

    let allocation = {};

    if (currentSelection?.start && currentSelection?.end && currentSelection?.rowTags) {
        allocation = getAllocationValuesForNewRequest(
            currentSelection.start,
            currentSelection.end,
            currentSelection.rowTags.resource,
            companySettings
        );
    }

    const initializeData = getDefaultValues(companySettings, categoryGroups, categoryTemplates, {
        state,
        ...allocation,
        scale: DAY.BEValue,
        ...bookingTmp,
    });

    yield put(initialize(FORM_NAME, initializeData));
}

export function* scheduleFormResourceSaga() {
    yield takeLatest(INIT_SCHEDULE_RESOURCE_FORM, handleFormInit);
}

const rights = [
    {
        rights: ['settingResourceCf'],
        rule: 'one',
        name: 'hasSettingResourceCf',
    },
];

const interDependentFieldsKeys = Object.entries(formSchema().schema || {})
    .filter(arr => arr[1].validatorInterdependent)
    .map(arr => arr[0]);

const ScheduleResourceModal = props => {
    const {
        bookingId,
        windowHeight,
        handleSubmit,
        resetData,
        invalid,
        pristine,
        touch,
        formValues,
        onClose,
        ...restOfProps
    } = props;
    const dispatch = useDispatch();
    useLoadProjectsAndResources();
    const [activeTab, setActiveTab] = useState(restOfProps.activeTab);
    const isCFExtensionInstalled = useIsExtensionInstalled(CUSTOM_FIELDS);
    const isTasksExtensionInstalled = useIsExtensionInstalled(TASKS);
    const { projectKeyWord, resourceKeyWord } = useAppKeyWords();
    const updateFields = useForm(FORM_NAME);
    const currentFormValues = useSelector(getFormValues(FORM_NAME));
    const { project = {}, note: currentNote } = currentFormValues || {};
    const initialFormValues = useSelector(getFormInitialValues(FORM_NAME));
    const { project: initialProject, repeat } = initialFormValues || {};
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);
    const booking = useSelector(state => state.bookingReducer.booking);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const resourceCustomFields = useSelector(getResourceCustomFields);
    const categoryGroups = useSelector(state => state.categoryGroupReducer.categoryGroups);
    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const categoryTemplates = useSelector(categoryTemplatesSelector);

    const { hasSettingResourceCf } = useHasRights(rights);
    const isCategoryColorUsed = 'CATEGORY_COLOR_BOOKING' === companySettings.grid.catColorDisplayNew;
    const useCategoriesAllocation = companySettings.useCategoriesAllocation;

    const onCategoryChange = useCallback(
        categorySelected => {
            if (categorySelected && categorySelected.hasOwnProperty('_id')) {
                let newFieldValues = {
                    category: categorySelected,
                };

                if (useCategoriesAllocation) {
                    const allocationFromCategory = getAllocationFieldValuesByCategory(categorySelected);
                    newFieldValues = {
                        ...newFieldValues,
                        ...allocationFromCategory,
                    };
                }

                updateFields(newFieldValues);
            }
        },
        [updateFields, useCategoriesAllocation]
    );

    const handleProjectChange = useCallback(
        project => {
            if (currentFormValues.useProjectColor && project?.backgroundColor) {
                updateFields({ bookingColor: '' });
            }
        },
        [updateFields, currentFormValues]
    );

    useEffect(() => {
        dispatch(getCategoryTemplates.request());
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    useEffect(() => {
        dispatch(action(INIT_SCHEDULE_RESOURCE_FORM, { booking }));
    }, [dispatch, booking]);

    useEffect(() => {
        bookingId && dispatch(getBooking.request(bookingId));
    }, [dispatch, bookingId]);

    useEffect(() => {
        if ('inherited' === currentFormValues?.externalRateType) {
            updateFields({ 'bookingRate.external': initialFormValues.bookingRate.external });
        }
    }, [updateFields, currentFormValues?.externalRateType, initialFormValues?.bookingRate?.external]);

    useEffect(() => {
        if ('inherited' === currentFormValues?.internalRateType) {
            updateFields({ 'bookingRate.internal': initialFormValues.bookingRate.internal });
        }
    }, [updateFields, currentFormValues?.internalRateType, initialFormValues?.bookingRate?.internal]);

    useEffect(() => {
        if (isCFExtensionInstalled && hasSettingResourceCf) {
            dispatch(getCustomFieldsRequest('resource'));
        }
    }, [dispatch, isCFExtensionInstalled, hasSettingResourceCf]);

    useEffect(() => {
        if (initialProject?._id !== project?._id) {
            const categorySelected = getDefaultCategoryByProject(project, categoryTemplates, categoryGroups);

            updateFields({ category: categorySelected });
            onCategoryChange(categorySelected);
        }
    }, [initialProject, project, categoryTemplates, categoryGroups, onCategoryChange, updateFields]);

    useEffect(
        () => () => {
            resetData('booking');
        },
        [resetData]
    );

    // Validate manually data to show interdependent validation (using useTouchInterdependentFieldsReduxForm hook)
    const validationResultInfo = useMemo(() => getSchemaValidationInfo(currentFormValues, formSchema()), [
        currentFormValues,
    ]);
    const { validationResult } = validationResultInfo;
    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
        onlyKeys: interDependentFieldsKeys,
    });

    const onCloseModal = useCallback(() => {
        dispatch(hideModal());
        if (onClose) {
            onClose();
        }
    }, [dispatch, onClose]);

    const onSubmit = values => {
        const mappedValues = mapFormToRequest({ values, customFieldTemplates: resourceCustomFields });
        bookingId && (mappedValues.bookings[0].id = bookingId);
        const data = bookingId ? mappedValues.bookings[0] : mappedValues;
        const updateJustOne = Boolean(bookingId);
        const shouldConvertToNotRepeated =
            (updateJustOne && !booking) ||
            (updateJustOne &&
                Boolean(booking?.repeatId) &&
                values.repeat === booking?.repeat &&
                values.interval === booking?.interval &&
                values.repeatTimes === booking?.repeatTimes);

        restOfProps.onSubmit(
            {
                ...data,
                ...(shouldConvertToNotRepeated
                    ? {
                          repeat: false,
                          repeatId: null,
                          interval: 'NONE',
                          repeatTimes: 0,
                      }
                    : {}),
            },
            null,
            false,
            {
                bookingAfterDeadlineConfirmation: true,
                createRepeatFromExisting: values.interval !== 'NONE' && !shouldConvertToNotRepeated,
                updateJustOne,
            }
        );
    };

    const onUpdateAll = () => {
        const mappedValues = mapFormToRequest({
            values: currentFormValues,
            customFieldTemplates: resourceCustomFields,
        });
        bookingId && (mappedValues.bookings[0].id = bookingId);
        const data = bookingId ? mappedValues.bookings[0] : mappedValues;
        restOfProps.onSubmit(data, null, false, {
            modifyAllRepeated: true,
            removeRemovedRepeated: true,
        });
    };

    const extendFooter = (
        <>
            {bookingId && restOfProps.onDelete && (
                <ConfirmTooltipButton
                    onConfirm={() => restOfProps.onDelete(bookingId, booking)}
                    buttonText={`Delete booking`}
                    dataCyPrefix="booking-modal-delete"
                />
            )}
            {restOfProps.hasSubmitFailed && (
                <Alert className="form-footer-alert" color="danger">
                    Please fill out all required fields
                </Alert>
            )}
        </>
    );

    const saveDisabled = invalid || (bookingId ? pristine : false);

    useModalPressEnter(() => {
        if (saveDisabled) {
            return;
        }

        onSubmit(formValues);
    });

    return (
        <CommonModalTemplate
            submitOnPressEnter
            onHideModalCustom={onCloseModal}
            title={`Schedule ${resourceKeyWord}`}
            description={`Schedule a ${resourceKeyWord} on a ${projectKeyWord}`}
            onSave={restOfProps.submitForm}
            extendFooter={extendFooter}
            windowHeight={windowHeight}
            saveButtonText={bookingId ? (booking?.repeat ? 'Update this' : 'Update') : 'Add New'}
            disabledSaveButton={saveDisabled}
            saveButtonDataCy={bookingId ? 'booking-modal-update' : 'booking-modal-add'}
            additionalButton={
                bookingId && repeat ? (
                    <SuccessButton
                        className="mr-2"
                        title="Update all"
                        name="Update all"
                        dataCy="booking-modal-update-all"
                        onClick={onUpdateAll}
                        disabled={invalid || pristine}
                    />
                ) : null
            }
        >
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <TabLinks activeTab={activeTab} tabs={getTabIcons(isTasksExtensionInstalled)} toggle={setActiveTab} />
                <TabContent activeTab={activeTab}>
                    <ScheduleBasicInfo
                        tabId="1"
                        isEditMode={!!bookingId}
                        categoryTemplates={getCategoryTemplatesByProject(project, categoryGroups, categoryTemplates)}
                        onFieldChange={updateFields}
                        onCategoryChange={onCategoryChange}
                        onProjectChange={handleProjectChange}
                        isCategoryColorUsed={isCategoryColorUsed}
                        booking={booking}
                    />
                    {'2' === activeTab && (
                        <BookingRatesTab
                            tabId="2"
                            externalRateType={restOfProps.externalRateType}
                            internalRateType={restOfProps.internalRateType}
                            onFieldChange={updateFields}
                            booking={booking}
                        />
                    )}
                    {'3' === activeTab && <TaskTab tabId="3" bookingId={bookingId} onFieldChange={updateFields} />}
                    {'4' === activeTab && (
                        <CustomFieldTab
                            tabId="4"
                            onChange={updateFields}
                            type="resource"
                            customFields={resourceCustomFields}
                            bookingContext
                        />
                    )}
                    {'5' === activeTab && (
                        <NotesTab
                            tabId="5"
                            note={currentNote}
                            noteKeyWord="Booking"
                            hasNoteRights={hasRole(resourceRoleRights, 'settingBookingNotes')}
                            hasLinksRights={hasRole(resourceRoleRights, 'settingProjectLinks')}
                            linksKeyWord={projectKeyWord}
                            onChange={updateFields}
                            iconLinks={restOfProps.iconLinks}
                        />
                    )}
                    <BookingInfoTab tabId="6" booking={booking} />
                    <LinkedBookingsTab tabId={DEPENDENCIES_TAB_ID} booking={booking} />
                </TabContent>
            </Form>
        </CommonModalTemplate>
    );
};

ScheduleResourceModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    activeTab: PropTypes.string,
    bookingId: PropTypes.string,
};

ScheduleResourceModal.defaultProps = {
    activeTab: '1',
    bookingId: '',
};

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: (values, props) => {
        return validateSchema(
            formSchema({
                deadlinesCompanyConfig: props.deadlinesCompanyConfig,
                isDeadlinesExtensionInstalled: props.isDeadlinesExtensionInstalled,
            })
        )(values);
    },
})(ScheduleResourceModal);
