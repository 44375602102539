import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List as VirtualizedList } from 'react-virtualized';
import classnames from 'classnames';
import isEqual from 'react-fast-compare';
import { includes, filter, findIndex, map, find } from 'lodash';
import { chooseField as CheckBox, inputGroupTextField as InputGroupTextField } from 'shared/formFields';
import { Title, Selected, ListItem } from './styles';
import FilterCondition from 'shared/builder/filterCondition';
import { Alert } from 'reactstrap';
import ScrollableListComponent from './scrollableList';
import latinize from 'latinize';

export class CheckboxList extends Component {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.object).isRequired,
        selectedItems: PropTypes.array,
        indeterminateItems: PropTypes.array,
        fields: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            additional: PropTypes.string,
            wrapWithBrackets: PropTypes.bool,
        }).isRequired,
        onSelect: PropTypes.func.isRequired,
        searchField: PropTypes.bool,
        showSelected: PropTypes.bool,
        showLoader: PropTypes.bool,
        title: PropTypes.string,
        itemComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]),
        listComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]),
        titleComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.element]),
        customTextComponent: PropTypes.element,
        additionalListProps: PropTypes.object,
        customShortText: PropTypes.string,
        emptyText: PropTypes.string,
        height: PropTypes.number,
        rowHeight: PropTypes.number,
        onOutsideClick: PropTypes.func,
        onSelectAll: PropTypes.func,
        checkAll: PropTypes.bool,
        checkAllLabel: PropTypes.string,
        scrollMargin: PropTypes.bool,
        useFilterCondition: PropTypes.bool,
        dataCy: PropTypes.string,
    };

    static defaultProps = {
        selectedItems: [],
        indeterminateItems: [],
        title: '',
        customShortText: '',
        emptyText: 'No items available for selection.',
        itemComponent: null,
        listComponent: null,
        customTextComponent: null,
        titleComponent: null,
        additionalListProps: {},
        height: 200,
        rowHeight: 44,
        searchField: true,
        showSelected: true,
        showLoader: false,
        onOutsideClick: null,
        onSelectAll: null,
        checkAll: false,
        checkAllLabel: 'Check All Groups',
        scrollMargin: true,
        useFilterCondition: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            filterText: '',
            selectedItems: !this.props.useFilterCondition
                ? [...this.props.selectedItems]
                : map(this.props.selectedItems, 'value'),
        };
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.selectedItems, this.props.selectedItems)) {
            this.setState({
                selectedItems: !this.props.useFilterCondition
                    ? [...this.props.selectedItems]
                    : map(this.props.selectedItems, 'value'),
            });
        }
    }

    onSearch = event => {
        this.setState({ filterText: event.target.value.toLowerCase() });
    };

    onItemChange = itemId => {
        const { useFilterCondition } = this.props;
        const currentSelection = [...this.props.selectedItems];

        if (includes(this.state.selectedItems, itemId)) {
            const index = findIndex(this.state.selectedItems, item => item === itemId);
            currentSelection.splice(index, 1);
            const deletedFromState = [...this.state.selectedItems];
            deletedFromState.splice(index, 1);
        } else {
            currentSelection.push(!useFilterCondition ? itemId : { value: itemId, set: true });
        }

        this.props.onSelect(currentSelection);
    };

    onConditionChange = (itemId, condition) => {
        this.props.onSelect(
            map(this.props.selectedItems, item => (item.value === itemId ? { ...item, set: condition } : item))
        );
    };

    onOutsideClick = item => {
        this.props.onOutsideClick && this.props.onOutsideClick(item);
    };

    renderItem = ({ index, key, style }) => {
        const items = this.getFilteredItems();
        const { fields, indeterminateItems, itemComponent, useFilterCondition } = this.props;
        const { selectedItems } = this.state;
        let label = items[index][fields.label];
        const ItemComponent = itemComponent || ListItem;

        if (fields.additional && items[index][fields.additional]) {
            if (fields.wrapWithBrackets) {
                label += ` (${items[index][fields.additional]})`;
            } else {
                label += ` ${items[index][fields.additional]}`;
            }
        }

        return (
            <ItemComponent
                style={{ ...style, borderTop: 0, borderLeft: 0, borderRight: 0 }}
                className={`${this.className} ${classnames({
                    disabled: items[index][fields.disabled],
                    active: items[index].active,
                    'justify-content-between d-flex': useFilterCondition,
                })}`}
                key={key}
                onClick={() => this.onOutsideClick(items[index])}
            >
                <CheckBox
                    type="checkbox"
                    disabled={items[index][fields.disabled]}
                    wrapperClass="text-truncate"
                    label={label}
                    labelMuted={items[index].deleted}
                    indeterminate={includes(indeterminateItems, items[index][fields.value])}
                    input={{
                        name: items[index][fields.value],
                        checked: includes(selectedItems, items[index][fields.value]),
                        onChange: () => this.onItemChange(items[index][fields.value]),
                        'data-cy': `${this.props.dataCy || ''}-checkbox-${items[index].value || ''}`,
                    }}
                />
                {useFilterCondition && (
                    <FilterCondition
                        disabled={items[index].deleted}
                        isSet={find(this.props.selectedItems, item => item.value === items[index][fields.value])?.set}
                        onChange={condition => this.onConditionChange(items[index][fields.value], condition)}
                        dataCy={`${this.props.dataCy || ''}-${items[index].value}`}
                    />
                )}
                {items[index].selectedItems !== undefined ? (
                    <small className="form-text text-muted">
                        <span className="selected-items">{items[index].selectedItems}</span>
                        <span>&nbsp;</span>
                        items
                    </small>
                ) : null}
                {items[index].subText !== undefined ? (
                    <small className="form-text text-muted text-truncate">{items[index].subText}</small>
                ) : null}
            </ItemComponent>
        );
    };

    getFilteredItems = () => {
        const {
            data,
            fields: { label, additional },
        } = this.props;
        const { filterText } = this.state;

        return filter(
            data,
            item =>
                -1 !==
                latinize(
                    `${item[label] ? item[label] : ''}${
                        additional && item[additional] ? ` ${item[additional]}` : ''
                    }`.toLowerCase()
                ).indexOf(latinize(filterText))
        );
    };

    onCheckAll = event => {
        this.props.onSelectAll && this.props.onSelectAll(event.target.checked);
    };

    handleScroll = ({ target }) => {
        const { scrollTop, scrollLeft } = target;

        const { Grid: grid } = this.list;

        grid.handleScrollEvent({ scrollTop, scrollLeft });
    };

    render() {
        const {
            title,
            height,
            searchField,
            showSelected,
            scrollMargin,
            titleComponent,
            customTextComponent,
            additionalListProps,
            showLoader,
            checkAll,
            data,
            checkAllLabel,
            children,
        } = this.props;
        const { selectedItems } = this.state;
        const items = this.getFilteredItems();
        const TitleComponent = title && titleComponent ? titleComponent : Title;

        return (
            <React.Fragment>
                {title && <TitleComponent>{title}</TitleComponent>}
                {showSelected && <Selected className="text-muted mb-1">{selectedItems.length} selected</Selected>}
                {customTextComponent && customTextComponent}
                {children}
                {searchField && (
                    <InputGroupTextField
                        icon="fa-search"
                        width="col-md-12"
                        placeholder="Search"
                        onChange={this.onSearch}
                        type="search"
                        data-cy={`${this.props.dataCy || ''}-input-search`}
                    />
                )}
                {checkAll && !this.state.filterText && (
                    <CheckBox
                        type="checkbox"
                        wrapperClass="mt-3"
                        label={checkAllLabel}
                        indeterminate={
                            (0 < selectedItems.length && selectedItems.length !== data.length) ||
                            0 < this.props.indeterminateItems.length
                        }
                        input={{
                            name: 'allItems',
                            checked: selectedItems.length === data.length,
                            onChange: this.onCheckAll,
                            'data-cy': 'checkbox-list--check-all',
                        }}
                    />
                )}
                {!showLoader ? (
                    <div ref={ref => (this.wrapper = ref)}>
                        <ScrollableListComponent
                            listComponent={this.props.listComponent}
                            onScroll={this.handleScroll}
                            scrollMargin={scrollMargin}
                            height={height}
                            {...additionalListProps}
                        >
                            {0 < this.props.data.length ? (
                                <VirtualizedList
                                    width={(this.wrapper && this.wrapper.getBoundingClientRect().width) || 250}
                                    rowHeight={this.props.rowHeight}
                                    height={height}
                                    rowCount={items.length}
                                    ref={ref => (this.list = ref)}
                                    style={{ overflowX: false, overflowY: false, width: '100%' }}
                                    rowRenderer={this.renderItem}
                                    containerStyle={{
                                        width: '100%',
                                        maxWidth: '100%',
                                    }}
                                />
                            ) : (
                                ''
                            )}
                            {0 === this.props.data.length && 0 < this.props.emptyText.length && (
                                <Alert color="warning" className="text-center">
                                    {this.props.emptyText}
                                </Alert>
                            )}
                        </ScrollableListComponent>
                    </div>
                ) : (
                    <div className="text-center mt-3">
                        <img src="https://d329beqc2zk6g7.cloudfront.net/img/scheduler_loader.gif" />
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default CheckboxList;
