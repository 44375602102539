import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHasRights } from 'hooks';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { makeProjectById } from 'selectors/project';
import { makeResourceById } from 'selectors/resource';

const rights = [
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasCreateResourceRights',
    },
    {
        rights: ['settingAddEditProjects'],
        rule: 'one',
        name: 'hasCreateProjectRights',
    },
    {
        rights: ['pmAddEditProjects'],
        rule: 'one',
        name: 'hasPmAddEditProjectsRights',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'hasResourceGroupsRights',
    },
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'hasProjectGroupsRights',
    },
];

export const useDisplayPermissions = () => {
    const params = useParams();
    const {
        hasCreateResourceRights,
        hasCreateProjectRights,
        hasPmAddEditProjectsRights,
        hasResourceGroupsRights,
        hasProjectGroupsRights,
    } = useHasRights(rights);
    const viewObject = getViewObject(params);
    const resourceSelector = useMemo(() => makeResourceById(params.resourceId), [params.resourceId]);
    const resource = useSelector(resourceSelector);
    const projectSelector = useMemo(() => makeProjectById(params.projectId), [params.projectId]);
    const project = useSelector(projectSelector);

    const shouldDisplayResource = (viewObject.isSingleResourceView && resource?.hasRightsToResource && hasCreateResourceRights);
    const shouldDisplayProject = (
        viewObject.isSingleProjectView &&
        ((project?.hasRightsToProject && hasCreateProjectRights) ||
            (project?.hasPMRightsToProject && hasPmAddEditProjectsRights))
    );
    const shouldDisplayBuilder = ((viewObject.isGroupView) && ((!viewObject.isResourceGroupView && hasProjectGroupsRights) || (viewObject.isResourceGroupView && hasResourceGroupsRights)))
    
    const shouldDisplayEditButton = shouldDisplayResource || shouldDisplayProject || shouldDisplayBuilder;

    return {
        shouldDisplayResource,
        shouldDisplayProject,
        shouldDisplayBuilder,
        shouldDisplayEditButton
    };
}