import { store } from '../../store';
import { showConfirmationModal } from '../../actions/modalActions';
import { selectCompanyDeadlinesBeforeBookingEndDateConfig } from '../../selectors/company';
import { select } from 'redux-saga/effects';

export const deadlineBeforeBookingEndErrorHandler = ({ isBooking, cleanUpAction }) => ({
    predicate: error => error?.data?.error?.enum === 'ENTITY_MOVE_NOT_ALLOWED_DEADLINE',
    handler: function*(error, action) {
        const isDeadlineBeforeBookingEndDateDisabled =
            (error.data?.properties?.deadlineBeforeBookingEndDateSetting ||
                (yield select(selectCompanyDeadlinesBeforeBookingEndDateConfig))) === 'DISABLED';

        if (isDeadlineBeforeBookingEndDateDisabled) {
            store.dispatch(
                showConfirmationModal(
                    () => {
                        if (cleanUpAction) {
                            cleanUpAction();
                        }
                    },
                    isBooking ? 'Booking move error' : 'Deadline move error',
                    isBooking
                        ? 'You cannot move booking after deadline'
                        : 'You cannot move deadline before end of booking',
                    {
                        withCancel: false,
                        withEscButton: true,
                        confirmButtonText: 'Ok',
                        cancelActionOnHide: true,
                        onReject: () => {
                            if (cleanUpAction) {
                                cleanUpAction();
                            }
                        },
                    }
                )
            );
        } else {
            const newAction = {
                ...action,
                payload: {
                    ...(action.payload || {}),
                    options: {
                        ...(action.payload?.options || {}),
                        bookingAfterDeadlineConfirmation: true,
                    },
                },
            };

            store.dispatch(
                showConfirmationModal(
                    () => {
                        store.dispatch(newAction);
                    },
                    isBooking ? 'Booking move warning' : 'Deadline move warning',
                    isBooking
                        ? 'Do you really want to move booking after deadline'
                        : 'Do you really want to move deadline before end of booking',
                    {
                        withCancel: true,
                        withEscButton: true,
                        confirmButtonText: 'Move',
                        cancelButtonText: 'Cancel',
                        cancelActionOnHide: true,
                        onReject: () => {
                            if (cleanUpAction) {
                                cleanUpAction();
                            }
                        },
                    }
                )
            );
        }
    },
});
