import { Button, makeStyles, Popover, withStyles } from '@material-ui/core';
import React, { useCallback, useRef, useState } from 'react';
import { Layout } from 'shared/components/layout';
import ClickAwayListener from 'shared/clickAwayListener';
import { AvailabilityOverrideForm } from './AvailabilityOverrideForm';
import { RangeFormat } from './RangeFormat';
import { CUSTOM_AVAILABILITY_OVERRIDES } from 'global/enums/dateFormat';
import { IconButton } from 'shared/components/iconButton';
import { useRerenderHack } from '../../../../../hooks/useRerenderHack';
import { useResizeObserver } from '../../../../../hooks/useResizeObserver';
import { scrollStyles } from 'shared/scroll/styles';
import { minutesToTime } from '../../../../../shared/components/formats/MinutesToTimeFormat';

const useStyles = makeStyles({
    popoverPaper: {
        transition:
            'all 146ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 230ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important',
    },
    addButton: {
        fontWeight: 500,
        fontSize: '14px',
        cursor: 'pointer',
    },
    row: {
        borderBottom: `1px solid #cfcfcf`,
        padding: '10px 0',

        '&:last-child': {
            borderBottom: 'none',
        },
    },
    icon: {
        cursor: 'pointer',
    },
    overflow: {
        overflowY: 'auto',
        maxHeight: '40vh',
        padding: '5px',
        ...scrollStyles,
    },
});

const StyledButton = withStyles({
    root: {
        padding: 0,
        '&:active': {
            backgroundColor: 'none',
        },
    },
})(Button);

const anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

const transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

export const AvailabilityOverride = props => {
    const { onChange, data, apiData } = props;
    const { rerender } = useRerenderHack();
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const node = useRef();
    const [editOverride, setEditOverride] = useState();

    const handleClickOutside = useCallback(
        event => {
            const inPortalClick = Array.from(event.path ?? event.composedPath()).some(
                elem => elem.hasAttribute && elem.hasAttribute('data-in-portal')
            );
            if (event.target.nodeName === 'BODY' || inPortalClick) {
                return;
            }

            if (node.current && !node.current.contains(event.target) && Boolean(anchorEl)) {
                setAnchorEl(null);
            }
        },
        [anchorEl]
    );

    const handleOpen = useCallback(e => {
        setAnchorEl(e.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleAddOverride = useCallback(
        override => {
            onChange &&
                onChange([...data, override], {
                    ...apiData,
                    create: [...apiData.create, override],
                });
        },
        [apiData, onChange, data]
    );

    const handleRemoveOverride = useCallback(
        index => {
            const id = data[index]?._id || false;
            onChange &&
                onChange(
                    data.filter((over, idx) => idx !== index),
                    {
                        update: [...apiData.update.filter(over => over._id !== id)],
                        delete: [...apiData.delete, ...(id ? [id] : [])],
                        create: [...data.filter((over, idx) => !over._id && idx !== index)],
                    }
                );
        },
        [data, apiData, onChange]
    );

    const handleChangeOverride = useCallback(
        (override, index) => {
            const id = data[index]?._id;
            onChange &&
                onChange(
                    data.map((over, idx) => {
                        if (idx !== index) return over;
                        return {
                            ...over,
                            ...override,
                        };
                    }),
                    {
                        ...apiData,
                        create: [...data.filter(over => !over._id)],
                        update: [...apiData.update.filter(over => over._id !== id), ...(id ? [override] : [])],
                    }
                );
        },
        [apiData, onChange, data]
    );

    const applyChanges = useCallback(
        override => {
            editOverride !== 'custom' ? handleChangeOverride(override, editOverride) : handleAddOverride(override);
            handleClose();
        },
        [editOverride, handleAddOverride, handleChangeOverride, handleClose]
    );

    const { onResizeTargetRef } = useResizeObserver({
        onResize: () => {
            rerender();
        },
    });

    return (
        <>
            <Layout stack>
                <Layout>
                    <StyledButton
                        dataCy="add-custom-availability-override"
                        className="addButton"
                        disableRipple
                        onClick={e => {
                            setEditOverride('custom');
                            handleOpen(e);
                        }}
                    >
                        + Add custom availability override
                    </StyledButton>
                </Layout>
                {data.length > 0 && (
                    <Layout stack padding="20px 0">
                        <div className={classes.overflow}>
                            {data.map(({ from, to, intervals }, idx) => (
                                <Layout
                                    className={classes.row}
                                    key={`${from}-${to}`}
                                    hAlign="space-between"
                                    vAlign="flex-start"
                                    padding="10px, 0"
                                >
                                    <RangeFormat from={from} to={to} dateFormat={CUSTOM_AVAILABILITY_OVERRIDES} />
                                    {intervals.length > 0 ? (
                                        <ul style={{ paddingLeft: 0 }}>
                                            {intervals.map(({ start, end }) => (
                                                <li key={`${start} - ${end}`}>
                                                    {minutesToTime(start)} - {minutesToTime(end)}
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <span>Not available</span>
                                    )}
                                    <div></div>
                                    <Layout gap="15px" vAlign="center">
                                        <IconButton
                                            icon="edit"
                                            className={classes.icon}
                                            tooltipText="Edit"
                                            onClick={e => {
                                                setEditOverride(idx);
                                                handleOpen(e);
                                            }}
                                        />
                                        <IconButton
                                            icon="trash"
                                            className={classes.icon}
                                            tooltipText="Delete"
                                            onClick={() => handleRemoveOverride(idx)}
                                        />
                                    </Layout>
                                </Layout>
                            ))}
                        </div>
                    </Layout>
                )}
            </Layout>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                hideBackdrop
                classes={{
                    paper: classes.popoverPaper,
                }}
                style={{
                    width: '380px',
                }}
            >
                <ClickAwayListener handler={handleClickOutside}>
                    <div ref={node}>
                        <AvailabilityOverrideForm
                            ref={onResizeTargetRef}
                            key={editOverride}
                            editOverrideIdx={editOverride}
                            editOverride={data[editOverride]}
                            handleClose={handleClose}
                            applyChanges={applyChanges}
                            data={data}
                        />
                    </div>
                </ClickAwayListener>
            </Popover>
        </>
    );
};
