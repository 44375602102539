import MenuItem from 'modules/report/components/menu/menuItem';
import { connect } from 'react-redux';
import { showConfirmationModal, showReportModal } from 'actions/modalActions';
import {
    deleteReport,
    updateQueryParams,
    updateFavourites,
    resetData,
    removeReportFromGroup,
    createReport,
} from 'actions/reportActions';
import { withRouter } from 'react-router';
import {
    selectResourceGroupIds,
    selectProjectGroupIds,
    selectActiveResourceIds,
    selectUnassignedIds,
    selectActiveProjectIds,
    selectActiveEventIds,
    selectIsActiveResourceGroupPermitted,
    selectIsActiveProjectGroupPermitted,
} from '../../../../selectors/companyTree';

export default connect(
    state => ({
        account: state.account,
        projectsIdsIManage: state.companyTree.getProjectsIdsIManage(),
        resourceIdsIManage: state.companyTree.getResourceIdsIManage(),
        projectGroupIds: selectProjectGroupIds(state),
        resourceGroupIds: selectResourceGroupIds(state),
        activeResourceIds: selectActiveResourceIds(state),
        unassignedIds: selectUnassignedIds(state),
        activeProjectIds: selectActiveProjectIds(state),
        eventIds: selectActiveEventIds(state),
        isActiveResourceGroupPermitted: selectIsActiveResourceGroupPermitted(state),
        isActiveProjectGroupPermitted: selectIsActiveProjectGroupPermitted(state),
        queryParams: state.reportReducer.queryParams,
        isReportLoading: state.reportReducer.loading,
        reportTemplates: state.reportReducer.reportTemplates,
        filters: state.reportReducer.queryParams.filters,
        reportCalculations: state.companyReducer.company.settings.reportCalculations,
    }),
    dispatch => ({
        showReportModal: (type, subType, groupBy, activeTab, itemType, initState) =>
            dispatch(showReportModal(type, subType, groupBy, activeTab, itemType, initState)),
        updateQueryParams: (queryParams, shouldRequest, shouldRedirect) =>
            dispatch(updateQueryParams.request(queryParams, shouldRequest, shouldRedirect)),
        deleteReport: template => dispatch(deleteReport.request(template)),
        deleteReportWithConfirmation: (template, title, message, groupId) =>
            dispatch(
                showConfirmationModal(
                    () => {
                        dispatch(deleteReport.request(template, groupId));
                    },
                    title,
                    message
                )
            ),
        removeFromGroup: (templateId, groupId) => {
            dispatch(removeReportFromGroup(templateId, groupId));
        },
        duplicateReport: (name, visibility, queryParams, groups) =>
            dispatch(createReport.request(name, visibility, queryParams, groups)),
        updateFavourites: (templateId, data) => dispatch(updateFavourites.request(templateId, data)),
        resetReportData: () => dispatch(resetData.request()),
    })
)(withRouter(MenuItem));
