import createReducer from '../../../reducers/helpers/createReducer';
import { updateItemInArray, updateObject } from '../../../reducers/helpers/updater';
import { toilActions } from './actions';

const initialState = {
    loadingPolicies: false,
    creating: false,
    policies: [],
};

const handleReset = () => {
    return initialState;
};

const handleGetPoliciesRequest = state => {
    return updateObject(state, { loadingPolicies: true });
};

const handleGetPoliciesSuccess = (state, action) => {
    const { policies } = action.payload;
    return updateObject(state, { loadingPolicies: false, policies });
};

const handleGetPoliciesFailure = state => {
    return updateObject(state, { loadingPolicies: false });
};

const handleCreatePolicyRequest = state => {
    return updateObject(state, { creating: true });
};

const handleCreatePolicySuccess = (state, action) => {
    const { policy } = action.payload;
    const newPolicies = state.policies.concat(policy);
    return updateObject(state, { policies: newPolicies, creating: false });
};

const handleCreatePolicyFailure = state => {
    return updateObject(state, { creating: false });
};

const handleUpdatePolicySuccess = (state, action) => {
    const { policy } = action.payload;
    const newPolicies = updateItemInArray(state.policies, policy._id, statePolicy => {
        return updateObject(statePolicy, policy);
    });
    return updateObject(state, { policies: newPolicies, updating: true });
};

const handleDeletePolicySuccess = (state, action) => {
    const { policy } = action.payload;
    const newPolicies = state.policies.filter(statePolicy => statePolicy._id !== policy._id);
    return updateObject(state, { policies: newPolicies });
};

export const toilReducer = createReducer(initialState, {
    [toilActions.RESET_TOIL_REDUCER]: handleReset,
    [toilActions.GET_TOIL_POLICIES['REQUEST']]: handleGetPoliciesRequest,
    [toilActions.GET_TOIL_POLICIES['SUCCESS']]: handleGetPoliciesSuccess,
    [toilActions.GET_TOIL_POLICIES['FAILURE']]: handleGetPoliciesFailure,
    [toilActions.CREATE_NEW_POLICY['REQUEST']]: handleCreatePolicyRequest,
    [toilActions.CREATE_NEW_POLICY['SUCCESS']]: handleCreatePolicySuccess,
    [toilActions.CREATE_NEW_POLICY['FAILURE']]: handleCreatePolicyFailure,
    [toilActions.UPDATE_POLICY['SUCCESS']]: handleUpdatePolicySuccess,
    [toilActions.DELETE_POLICY['SUCCESS']]: handleDeletePolicySuccess,
});
