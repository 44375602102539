import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageContainer, Logo, LogoText, BgImage } from './styles';

const titles = {
    login: 'Resource Management Software',
    signup: 'Experience 14 days unlimited free trial',
    forgot: 'Recovering passwords since 2013',
    activation: 'Resource Management Software',
    reset: 'Resetting passwords since 2013',
    welcome: 'Resource Management Software',
};

const PageTemplate = ({ noImage, children, type, noLogo }) => (
    <PageContainer fluid>
        <Row noGutters>
            {noImage ? null : (
                <Col md="6" className="d-none d-md-flex">
                    <BgImage className={type} />
                </Col>
            )}
            <Col md={noImage ? 12 : 6} className="bg-light">
                <div className="min-vh-100 d-flex align-items-center py-5">
                    <Container>
                        <Row>
                            <Col lg="10" xl="7" className="mx-auto">
                                {!noLogo && (
                                    <>
                                        <Logo />
                                        <LogoText className="mt-2 mb-5 text-center text-uppercase">
                                            {titles[type]}
                                        </LogoText>
                                    </>
                                )}
                                {children}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Col>
        </Row>
    </PageContainer>
);

PageTemplate.propTypes = {
    noImage: PropTypes.bool,
    noLogo: PropTypes.bool,
    type: PropTypes.oneOf(['login', 'signup', 'forgot', 'reset', 'activation', 'welcome']),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};
PageTemplate.defaultProps = {
    noImage: false,
    type: 'login',
};

export default PageTemplate;
