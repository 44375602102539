import { includes, find } from 'lodash';
import { format, getHours, getMinutes } from 'date-fns';
import { CELLDURATION, DAY, MONTH, WEEK } from 'modules/scheduler/enums/scale';

export const getData = (args, row, companySettings, resourceCapacity) => {
    const _scheduler = args.cell.calendar;
    const isNotWeekAndMonthScale = _scheduler.scale !== WEEK.value && _scheduler.scale !== MONTH.value;
    const isHoliday = resourceCapacity.isHoliday && isNotWeekAndMonthScale;
    const useResourceAvailability = row.tags.resource && row.tags.resource.useResourceAvailability;
    const resourceHasCustomAvailabilityOverrides =
        row.tags.resource && row.tags.resource.resourceHasCustomAvailabilityOverrides;
    const _isWorkday = resourceCapacity.hasOwnProperty('isWorkDay') && resourceCapacity.isWorkDay;

    let _isNonWorking = false;
    let _backColor = '#fff';

    const _noAvailabilityColor = find(
        companySettings.grid.schedulerNotificationColors,
        notification => 'NO_AVAILABILITY' === notification.type
    ).color;

    let totalMinutes = 0;

    const checkIntervals = (intervals, time) => {
        let _backColor = _noAvailabilityColor;
        _isNonWorking = true;
        totalMinutes = 0;
        (intervals || []).forEach(({ start, end }) => {
            if (start <= time && time < end) {
                _backColor = '#fff';
                if (_scheduler.cellDuration === 30) {
                    totalMinutes = 30;
                } else {
                    totalMinutes = end - time < 60 ? 30 : 60;
                }
                _isNonWorking = false;
            }
        });
        return _backColor;
    };

    if (_scheduler.scale === CELLDURATION.value) {
        const TZOffset = args.cell.start.toDate().getTimezoneOffset();
        const dayOfWeek = args.cell.start.toString('dddd').toLowerCase();

        const cellStartTime = args.cell.start.addMinutes(TZOffset).getTime();
        const startTimeMinutes = getHours(cellStartTime) * 60 + getMinutes(cellStartTime);
        if (useResourceAvailability) {
            const workDay = row.tags.resource.customAvailabilities[0].weekDays[dayOfWeek]
            const intervals = workDay.intervals;
            _backColor = workDay.workDay ? checkIntervals(intervals, startTimeMinutes) : _noAvailabilityColor;
        } else {
            const workDay = companySettings.weekDays[dayOfWeek];
            const intervals = workDay.intervals;
            _backColor = workDay.workDay ? checkIntervals(intervals, startTimeMinutes) : _noAvailabilityColor;
        }
        if (resourceHasCustomAvailabilityOverrides) {
            const currentDay = format(cellStartTime, 'yyyy-MM-dd');
            const overrideId = row?.tags?.resource?.customAvailabilitiesOverridesByDay[currentDay];
            if (overrideId) {
                const intervals = row.tags.resource.customAvailabilitiesOverridesById[overrideId].intervals;
                _backColor = checkIntervals(intervals, startTimeMinutes);
            }
        }
    }

    if (!_isWorkday && _scheduler.scale === DAY.value) _backColor = _noAvailabilityColor;

    if (isHoliday) {
        _backColor = resourceCapacity.holiday.backColor
            ? resourceCapacity.holiday.backColor
            : resourceCapacity.holiday.color;
    }

    return {
        isNonWorking: _isNonWorking,
        backColor: _backColor,
        isWorkday: _isWorkday,
        isHoliday: isHoliday,
        holiday: resourceCapacity.holiday,
        noAvailabilityColor: _noAvailabilityColor,
        useResourceAvailability: useResourceAvailability,
        resourceCapacity: {
            ...resourceCapacity,
            totalMinutes,
        },
    };
};

export const getResourcePercentageBooked = (bookedMinutesCell, totalMinutes) =>
    bookedMinutesCell ? (bookedMinutesCell / totalMinutes) * 100 : 0;

export const getNotificationBackgroundColor = (
    currentScale,
    schedulerNotificationColors,
    bookedMinutesCell,
    percentageResourceIsBooked,
    data
) => {
    if (data.isHoliday && includes([DAY.value, CELLDURATION.value], currentScale)) {
        return data.holiday.backColor ? data.holiday.backColor : data.holiday.color;
    }

    if (currentScale === DAY.value && (data.isHoliday || !data.isWorkday)) {
        // when a holiday or not workday in day view / / not available
        return data.noAvailabilityColor;
    }

    if (currentScale === CELLDURATION.value && (data.isHoliday || data.isNonWorking || !data.isWorkday)) {
        // when lunch / holiday or non working day in hour view / / not available
        return data.noAvailabilityColor;
    }

    // 100 - percentageResourceIsBooked < 10e-10
    // is due to legacy custom availability values
    if (100 === percentageResourceIsBooked || (Math.abs(100 - percentageResourceIsBooked) < 10e-10)) {
        // when at 100% booking
        return find(schedulerNotificationColors, notification => 'AT_CAPACITY' === notification.type).color;
    }

    percentageResourceIsBooked = Math.floor(percentageResourceIsBooked / 20) * 20; //rounds down to closest multiple of 20 which is how we set the colors

    if (180 < percentageResourceIsBooked) {
        percentageResourceIsBooked = 180; // in case over scheduled and the number is over 180 we don't have any object for each scenario so will cover anything over this.
    }

    if (0 === bookedMinutesCell) {
        // when no booking
        return find(schedulerNotificationColors, notification => 'FULL_AVAILABILITY' === notification.type).color;
    }

    return find(
        schedulerNotificationColors,
        notification => Math.abs(percentageResourceIsBooked) === notification.minCapacity
    )?.color;
};
