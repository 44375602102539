import React, { useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight, Brightness1, DateRange, ZoomIn, Tv } from '@material-ui/icons';
import DayPicker from 'react-day-picker';
import { ButtonGroup, Col, Row, DropdownItem } from 'reactstrap';
import { viewModesArray } from 'modules/scheduler/enums/viewModeEnum';
import { scalesArray } from 'modules/scheduler/enums/scale';
import { NavButton, ToggleButton, Dropdown, ViewModesTitle, ViewModesDescription } from './styles';
import { DropdownMenu, DropdownButton } from 'shared/dropdownMenu';
import { useAppKeyWords } from 'hooks';
import { renderDayPickerDayWithDataCy } from 'shared/lib/testing';

export const useStyles = makeStyles({
    img: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: { fontWeight: 600 },
    description: { whiteSpace: 'normal', color: '#717171' },
    scaleMenu: {
        borderRadius: '4px !important',
        minWidth: '150px',
        backgroundColor: 'white',
        padding: '5px 0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
    },
    scaleItem: {
        height: '36px',
        '&.active': {
            backgroundColor: '#eff8f4',
            color: '#212529',
            borderLeft: 'none !important',
        },
    },
    divider: {
        borderTop: '1px solid #E6E7ED',
    },
    menuItem: {
        padding: '10px',
        cursor: 'pointer',
        '&.active': {
            backgroundColor: '#eff8f4',
            color: '#212529',
            borderLeft: 'none',
        },
        '&:hover': {
            backgroundColor: 'rgba(239, 248, 244, 0.5)',
        },
    },
    wrapper: {},
});

const SchedulerNav = props => {
    const {
        scrollTo,
        scrollLeft,
        scrollCenter,
        scrollRight,
        onScaleChange,
        defaultScale,
        onViewModeChange,
        viewMode: viewModeFromProps,
    } = props;

    const keyWords = useAppKeyWords();
    const classes = useStyles();

    const [dateRangeOpen, setDateRangeOpen] = useState(false);
    const [datePickerDate, setDatePickerDate] = useState(new Date());

    const dayPickerRef = useRef();

    const handleToggleDateRange = useCallback(() => {
        setDateRangeOpen(open => !open);
    }, []);

    const handleDayRangeSelected = useCallback(
        date => {
            scrollTo(date);
            handleToggleDateRange();
            setDatePickerDate(date);
        },
        [handleToggleDateRange, scrollTo]
    );

    useEffect(() => {
        dateRangeOpen && dayPickerRef.current && dayPickerRef.current.showMonth(datePickerDate);
    }, [datePickerDate, dateRangeOpen]);

    const onScrollCenter = useCallback(() => {
        const date = scrollCenter && scrollCenter();
        date && setDatePickerDate(new Date(date));
    }, [scrollCenter]);

    return (
        <div className="left">
            <ButtonGroup size="sm">
                <NavButton onClick={scrollLeft} color="" data-cy="scheduler-nav-scroll-left">
                    <ChevronLeft />
                </NavButton>
                <NavButton onClick={onScrollCenter} color="" data-cy="scheduler-nav-scroll-center">
                    <Brightness1 className="center-icon" fontSize="small" />
                </NavButton>
                <NavButton onClick={scrollRight} color="" data-cy="scheduler-nav-scroll-right">
                    <ChevronRight />
                </NavButton>
            </ButtonGroup>
            <div className="d-inline-flex ml-2">
                <Dropdown
                    className="float-left"
                    data-cy="scheduler-nav-date-range"
                    isOpen={dateRangeOpen}
                    toggle={handleToggleDateRange}
                >
                    <ToggleButton className="left" color="">
                        <DateRange />
                    </ToggleButton>
                    <DropdownMenu className="rounded-0" width={235}>
                        <DayPicker
                            ref={dayPickerRef}
                            showOutsideDays
                            selectedDays={datePickerDate}
                            onDayClick={handleDayRangeSelected}
                            renderDay={day => renderDayPickerDayWithDataCy(day, 'scheduler-header-daypicker')}
                        />
                    </DropdownMenu>
                </Dropdown>
                <Dropdown className="float-left" data-cy="scheduler-nav-zoom">
                    <ToggleButton className="center" color="">
                        <ZoomIn />
                    </ToggleButton>
                    <DropdownMenu className={`${classes.scaleMenu} rounded-0`}>
                        {scalesArray.slice(0, 4).map((scale, index) => (
                            <DropdownButton
                                key={`${index}-${scale.value}`}
                                data-scale={scale.value}
                                onClick={onScaleChange}
                                className={classNames(
                                    {
                                        active: scale.value === defaultScale,
                                    },
                                    classes.scaleItem
                                )}
                            >
                                {scale.label}
                            </DropdownButton>
                        ))}
                        <DropdownItem divider className={classes.divider} />
                        {scalesArray.slice(4).map((scale, index) => (
                            <DropdownButton
                                key={`${index}-${scale.value}`}
                                data-scale={scale.value}
                                data-custom-search-id={scale.customSearchId}
                                onClick={onScaleChange}
                                className={classNames(classes.scaleItem)}
                            >
                                {scale.label}
                            </DropdownButton>
                        ))}
                    </DropdownMenu>
                </Dropdown>
                <Dropdown className="float-left" data-cy="scheduler-nav-tv">
                    <ToggleButton className="right" color="">
                        <Tv />
                    </ToggleButton>
                    <DropdownMenu className="rounded-0" width={340}>
                        <div>
                            <ViewModesTitle>View modes</ViewModesTitle>
                            <ViewModesDescription>
                                Adjust the way bookings are displayed in the scheduler.
                            </ViewModesDescription>
                        </div>
                        {viewModesArray.map((viewMode, index) => (
                            <React.Fragment key={viewMode.value}>
                                <DropdownItem
                                    onClick={() => onViewModeChange(viewMode.value)}
                                    className={classNames(
                                        {
                                            active: viewMode.value === viewModeFromProps,
                                        },
                                        classes.menuItem
                                    )}
                                    data-cy={`button-view-mode-change--${viewMode.value}`}
                                >
                                    <Row noGutters>
                                        <Col xs={2}>
                                            <img src={viewMode.image} className={classes.img} />
                                        </Col>
                                        <Col xs={10}>
                                            <div className={classes.wrapper}>
                                                <div className={classes.title}>{viewMode.label(keyWords)}</div>
                                                <div className={classes.description}>
                                                    {viewMode.description(keyWords)}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </DropdownItem>
                                {index !== viewMode.length - 1 && <DropdownItem divider />}
                            </React.Fragment>
                        ))}
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

SchedulerNav.propTypes = {
    defaultScale: PropTypes.string,
    viewMode: PropTypes.string,
    onScaleChange: PropTypes.func,
    scrollTo: PropTypes.func,
    scrollRight: PropTypes.func,
    scrollCenter: PropTypes.func,
    scrollLeft: PropTypes.func,
    onViewModeChange: PropTypes.func,
};

SchedulerNav.defaultProps = {
    defaultScale: '',
    viewMode: '',
    onScaleChange: null,
    scrollTo: null,
    scrollRight: null,
    scrollCenter: null,
    scrollLeft: null,
    onViewModeChange: null,
};

export default SchedulerNav;
