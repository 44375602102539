import React from 'react';
import PropTypes from 'prop-types';
import { VisibilityOff } from '@material-ui/icons';

const PermissionDenied = ({ description }) => (
    <div className="text-center">
        <VisibilityOff fontSize="large" />
        <h4 className="text-center mt-2">Permission Denied</h4>
        <div className="text-center">{description}</div>
    </div>
);

PermissionDenied.propTypes = {
    description: PropTypes.string,
};

PermissionDenied.defaultProps = {
    description:
        'Sorry, you do not have the correct role rights to use this feature. Please check with your Account Owner to gain access. They can update this role right for you under settings - Roles.',
};

export default PermissionDenied;
