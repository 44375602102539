import { sortBy, values } from 'underscore';
import { Bar, Line } from 'react-chartjs-2';

export const COLUMN = {
    display: 'Column Chart',
    value: 'COLUMN',
    component: Bar,
};
export const STACKED_COLUMN = {
    display: 'Stacked Column Chart',
    value: 'STACKED_COLUMN',
    component: Bar,
};
export const LINE = { display: 'Line Chart', value: 'LINE', component: Line };

const chartTypes = { COLUMN, STACKED_COLUMN, LINE };

export const chartTypesArray = sortBy(values(chartTypes), status => status.value);
