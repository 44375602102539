import { useCallback, useState } from 'react';

export const useRerenderHack = () => {
    const [, setState] = useState();

    const rerender = useCallback(() => {
        setState({});
    }, []);

    return {
        rerender,
    };
};
