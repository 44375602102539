/* eslint-env browser */
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useWindowDimension } from 'hooks';
import { getCompanyTree } from 'actions/companyTreeActions';
import Sidebar from 'modules/sidebar/container';
import Header from 'modules/header';
import DevelopmentHeader from 'modules/report/containers/developmentHeader';
import RouteValidator from 'services/routeValidator/validator';
import Scrollbar from 'shared/scrollbar';

// order of bootstrapTheme and react-widgets was changed because
// localhost:3000 was not having same styles order like after deploy
import 'react-widgets/dist/css/react-widgets.css';
import 'assets/styles/bootstrapTheme.css';
import 'react-virtualized/styles.css';
import 'assets/styles/common/react-table.css';
import 'assets/styles/common/day-picker.css';
import 'assets/styles/common/react-metismenu-standart.min.css';
import 'assets/styles/common/react-table-hoc-fixed-columns.css';
import 'assets/styles/common/main.css';
import 'assets/styles/common/theme.css';
import 'assets/styles/common/buttons.css';
import 'assets/styles/common/dropdowns.css';
import 'assets/styles/common/modals.css';
import 'assets/styles/common/sidebar.css';
import 'assets/styles/reports.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { getReportsGroups } from 'actions/reportActions';

const FIXED_ITEM_HEIGHT = 50;

const Template = ({ isFullScreen, child, theme, ...rest }) => {
    const dispatch = useDispatch();
    const { windowHeight, windowWidth } = useWindowDimension();
    useEffect(() => {
        dispatch(getCompanyTree.request());
        dispatch(getReportsGroups.request());
    }, [dispatch]);

    return (
        <Route
            {...rest}
            render={() => (
                <div className={rest.location.pathname.split('/')[1]}>
                    <Header />
                    <div className="container-fluid">
                        <Row>
                            <div
                                className={classnames('full-screen', {
                                    on: isFullScreen,
                                    'off col-sm-3 col-md-4 col-lg-3 col-xl-2': !isFullScreen,
                                })}
                            >
                                <Sidebar
                                    windowHeight={windowHeight - FIXED_ITEM_HEIGHT}
                                    windowWidth={windowWidth}
                                    isReportPage={true}
                                />
                            </div>
                            <Scrollbar
                                height={windowHeight - (991 >= windowWidth ? 42 : 51)}
                                className={classnames({
                                    'dark-theme': 'DARK_THEME' === theme,
                                    col: isFullScreen,
                                    'col-sm-9 col-md-8 col-lg-9 col-xl-10': !isFullScreen,
                                })}
                            >
                                <div className="main">
                                    <DevelopmentHeader />
                                    {child && (
                                        <Switch>
                                            {child.map(route => (
                                                <RouteValidator key={route.path} {...route} />
                                            ))}
                                        </Switch>
                                    )}
                                </div>
                            </Scrollbar>
                        </Row>
                    </div>
                </div>
            )}
        />
    );
};

Template.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    path: PropTypes.string.isRequired,
    title: PropTypes.string,
    theme: PropTypes.string,
};

Template.defaultProps = {
    component: null,
};

export default Template;
