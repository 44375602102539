import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useForm from './hooks/useScheduleProjectForm';
import { TYPE_EVENT } from 'enums/projectEnum';
import ScheduleForm from 'modules/scheduler/components/dropdowns/schedule/partials/legacy/scheduleForm';
import NestedMenuItem, { NESTED_MENU_ITEM_SCROLLBAR_HEIGHT } from 'shared/nestedMenuItem';
import Scrollbar from 'shared/scrollbar';
import VacationMenuItem from './vacationMenuItem';
import useKeepTimeRangeSelectedClassicMenu from './hooks/useKeepTimeRangeSelectedClassicMenu';
import getPasteBookingDisabled from '../utils/getPasteBookingDisabled';
import { useMenuVacationPermissions, useMenuBookingPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import { KeyBoardShortCutIcon } from '../../../booking/partials/legacy/styles';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { Divider } from '@material-ui/core';

const LegacyEventMode = React.forwardRef((props, schedulerRef) => {
    const {
        onClick,
        onRequestVacation,
        showRequestVacationOption,
        rowTags,
        isRequestMode,
        combineScheduleRequest,
        isDefaultVacationEventSet,
        onPaste,
    } = props;
    const preparedRowTags = rowTags || {};

    const { resource } = preparedRowTags;
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const scale = schedulerRef.current.control.scale;
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const {
        showScheduleButton,
        checkScheduleRights,
        showRequestButton,
        hasRightsToUseForm,
    } = useMenuBookingPermissions(isRequestMode, combineScheduleRequest, preparedRowTags);
    const { canRequestVacation } = useMenuVacationPermissions(showRequestVacationOption, preparedRowTags);

    const {
        formik,
        handleSubmitSchedule,
        handleSubmitRequest,
        values,
        isSubmitting,
        updateFields,
        updateProject,
        updateCategory,
        projectCategoryGroups,
        projects,
        handleAllocationValueChange,
        handleAllocationTypeChange,
        updateDates,
    } = useForm(onClick, isRequestMode, combineScheduleRequest, currentSelection, scale, resource, TYPE_EVENT);

    const keyWord =
        isRequestMode || (combineScheduleRequest && showRequestButton && !showScheduleButton) ? 'Request' : 'Add';

    useKeepTimeRangeSelectedClassicMenu(schedulerRef, currentSelection, projects?.length);

    const hasFullScheduleRights = checkScheduleRights(values.project, resource);
    const scheduleButton = {
        onClick: handleSubmitSchedule,
        disabled: !hasFullScheduleRights,
    };

    const requestButton = {
        onClick: handleSubmitRequest,
        disabled: false,
    };

    let modifierKeyPrefix = 'CTRL +'; // control key
    if (window.navigator.platform.indexOf('Mac') === 0) {
        modifierKeyPrefix = '⌘'; // command key
    }

    return (
        <>
            <WrappedMenuItem
                data-cy="button-classic-menu-paste"
                onClick={onPaste}
                disabled={getPasteBookingDisabled(clipboard, preparedRowTags)}
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mt-2"
            >
                Paste
                <KeyBoardShortCutIcon>{modifierKeyPrefix} V</KeyBoardShortCutIcon>
            </WrappedMenuItem>
            <Divider />
            {canRequestVacation && (
                <VacationMenuItem
                    onRequestVacation={onRequestVacation}
                    resource={resource}
                    isDefaultVacationEventSet={isDefaultVacationEventSet}
                />
            )}
            {hasRightsToUseForm && (
                <NestedMenuItem label={`${keyWord} Event`} parentMenuOpen={true} width={322}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <ScheduleForm
                            formik={formik}
                            keyword={keyWord}
                            formValues={values}
                            updateFields={updateFields}
                            updateProject={updateProject}
                            updateCategory={updateCategory}
                            updateDates={updateDates}
                            projects={projects}
                            isSubmitting={isSubmitting}
                            currentScale={scale}
                            projectCategoryGroups={projectCategoryGroups}
                            scheduleButton={showScheduleButton ? scheduleButton : undefined}
                            requestButton={showRequestButton ? requestButton : undefined}
                            onAllocationTypeChange={handleAllocationTypeChange}
                            onAllocationValueChange={handleAllocationValueChange}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
        </>
    );
});

LegacyEventMode.propTypes = {
    onClick: PropTypes.func.isRequired,
    onPaste: PropTypes.func.isRequired,
    isRequestMode: PropTypes.bool.isRequired,
    rowTags: PropTypes.object,
    showRequestVacationOption: PropTypes.bool,
    isDefaultVacationEventSet: PropTypes.bool,
};

LegacyEventMode.defaultProps = {
    rowTags: {},
};

export default LegacyEventMode;
