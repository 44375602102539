import styled from 'styled-components';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const LinkItem = styled(MenuItem)`
    & > a {
        color: #000;

        &:hover {
            color: #000;
        }
    }
`;

export const useStyles = makeStyles({
    paper: {
        borderRadius: 4,
        minWidth: '150px',
        padding: '5px 0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
    },
});
