import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { getProjects } from 'actions/projectActions';
import useProjectCategoryGroups from './useProjectCategoryGroups';
import { useAppKeyWords } from 'hooks';
import useForm from './useForm';
import { useSchedulerData } from 'modules/scheduler/hooks';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { SCHEDULED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { useMenuBookingPermissions } from '../../../../../../hooks/useMenuPermissions';
import { makeGetFilteredCategoryTemplates } from 'selectors/categoryTemplate';
import { useAllocationCalc } from './useAllocationCalc';
import { useCalculateAllocationForCategory } from '../../../../../../../../shared/allocation/calculateAllocationForCategory';

const useScheduleProjectForm = (
    onSchedule,
    isRequestMode,
    combineScheduleRequest,
    currentSelection,
    scale,
    resource,
    type
) => {
    const dispatch = useDispatch();
    const defaults = useMemo(() => ({ project: undefined, resource }), [resource]);

    const appKeyWords = useAppKeyWords();
    const keyword = type === TYPE_REGULAR.value ? appKeyWords.projectKeyWord : 'Event';

    const rowTags = useMemo(() => currentSelection.rowTags || {}, [currentSelection.rowTags]);
    const { canRequestResources, hasScheduleRights } = useMenuBookingPermissions(
        isRequestMode,
        combineScheduleRequest,
        rowTags
    );

    const showItems =
        (isRequestMode && canRequestResources) ||
        (!isRequestMode && (canRequestResources || hasScheduleRights.schedule));

    const { projects } = useSchedulerData(resource?._id, null, {
        onlyUnassigned: resource?.type === TYPE_UNASSIGNED.value,
        onlyEvents: type !== TYPE_REGULAR.value,
        canRequestResources: showItems && (isRequestMode || combineScheduleRequest),
        onlyProjects: type === TYPE_REGULAR.value,
        noDataRefresh: true,
    });

    useEffect(() => {
        dispatch(getProjects.request());
    }, [dispatch]);

    const { formik, allocationValues } = useForm(onSchedule, defaults, currentSelection, scale);

    return {
        formik,
        keyword,
        projects,
        allocationValues,
    };
};

/**
 * Create and use form that has initially set resource, and allow user to choose project/event for schedule/request
 * @param {Function} onClick - Callback function used when form is submitted
 * @param {Boolean} isRequestMode - Whether you are in menu after clicking on "request" button
 * @param {Boolean} combineScheduleRequest - Whether you can both request and schedule
 * @param {Object} currentSelection - Current selection on daypilot
 * @param {Object} scale - currently used scale [client/modules/scheduler/enums/scale.js]
 * @param {Object} resource - resource from currentSelection
 * @param {Object} type - Whether you want list of project or resources [enums/projectEnum.js]
 * @returns {Object}
 *  {
 *      formik - formik object;
 *      allocationValues - booking allocation - percentage,hours,total;
 *      keyword - Project/Event;
 *      projects - List of permitted projects to schedule/request for given resource;
 *      handleSubmitSchedule - Callback to submit form for scheduling;
 *      handleSubmitRequest - Callback to submit form for requesting;
 *      values - form values;
 *      isSubmitting - form is submitting;
 *      updateFields - update form fields;
 *      projectCategoryGroups - Groups from which user can choose category for booking/request;
 * }
 */
const useProjectForm = (onClick, isRequestMode, combineScheduleRequest, currentSelection, scale, resource, type) => {
    const { formik, allocationValues, keyword, projects } = useScheduleProjectForm(
        onClick,
        isRequestMode,
        combineScheduleRequest,
        currentSelection,
        scale,
        resource,
        type
    );

    const { handleSubmit, setFieldValue, values, isSubmitting } = formik;

    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const categoryTemplates = useSelector(categoryTemplatesSelector);
    const projectCategoryGroups = useProjectCategoryGroups(values?.project);

    const updateFields = useCallback(
        fieldsData => {
            // Automatically set the default category booking of project
            if (fieldsData.project && fieldsData.project.defaultCategoryTemplate) {
                const categoryTemplateFound = categoryTemplates.find(
                    categoryTemplate => categoryTemplate._id === fieldsData.project.defaultCategoryTemplate
                );
                if (categoryTemplateFound) {
                    fieldsData.category = categoryTemplateFound;
                }
            }

            for (const [key, value] of Object.entries(fieldsData)) {
                setFieldValue(key, value);
            }
        },
        [categoryTemplates, setFieldValue]
    );

    const { handleAllocationValueChange, handleAllocationTypeChange, updateDates } = useAllocationCalc({
        formik,
        allocationValues,
        updateFields,
        scale,
        currentSelection,
        resource: values?.resource,
    });

    const calculateAllocationForCategory = useCalculateAllocationForCategory();

    const updateProject = useCallback(
        newValues => {
            if (newValues.project && newValues.project.defaultCategoryTemplate) {
                const categoryTemplateFound = categoryTemplates.find(
                    categoryTemplate => categoryTemplate._id === newValues.project.defaultCategoryTemplate
                );
                if (categoryTemplateFound) {
                    newValues.category = categoryTemplateFound;
                }
            }

            const allocationData = calculateAllocationForCategory({
                percentage: values.percentage,
                total: values.total,
                hours: values.hours,
                category: newValues.category,
            });

            updateFields({
                ...newValues,
                ...allocationData,
            });
        },
        [calculateAllocationForCategory, categoryTemplates, updateFields, values]
    );

    const updateCategory = useCallback(
        newValues => {
            const allocationData = calculateAllocationForCategory({
                percentage: values.percentage,
                total: values.total,
                hours: values.hours,
                category: newValues.category,
            });

            updateFields({
                ...newValues,
                ...allocationData,
            });
        },
        [calculateAllocationForCategory, updateFields, values.hours, values.percentage, values.total]
    );

    return {
        formik,
        allocationValues,
        keyword,
        projects,
        updateProject,
        updateCategory,
        handleSubmitSchedule: () => {
            setFieldValue('type', SCHEDULED.value);
            handleSubmit();
        },
        handleSubmitRequest: () => {
            setFieldValue('type', WAITING_FOR_APPROVAL.value);
            handleSubmit();
        },
        values,
        isSubmitting,
        updateFields,
        updateDates,
        projectCategoryGroups,
        handleAllocationValueChange,
        handleAllocationTypeChange,
    };
};

export default useProjectForm;
