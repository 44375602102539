import React, { useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useClickOutside } from 'hooks';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import Scrollbar from 'shared/scrollbar';
import ColumnWidth from './components/columnWidth';
import BookingHeight from './components/bookingHeight';
import AvailabilityDisplayOptions from './components/availabilityDisplayOptions';
import DisplayAvailabilityOnCellHover from './components/displayAvailabilityOnCellHover';
import BookingToolTipDisplay from './components/bookingToolTipDelay';
import ContextMenuVersion from './components/contextMenuVersion';
import DefaultLayoutMode from './components/defaultLayoutMode';
import DefaultScale from './components/defaultScale';
import { Wrapper, CloseIcon, Title, Description } from './styles';
import { findByValue, scales } from '../../../../enums/scale';
import { useSelector } from 'react-redux';
import { getCompanySettings } from '../../../../../../selectors/company';
import { SubGroupsPosition } from './components/subGroupsPosition';
import { LinkedBookingsConfig } from './components/linkedBookings';
import { DeadlinesConfig } from './components/deadlines';

const Customization = props => {
    const { onClose, isOpen, schedulerRef } = props;
    const params = useParams();
    const viewObject = getViewObject(params);
    const {
        gridPreferences,
        updateGridPreferences,
        updateSubGroupsPositionSetting,
        subGroupsPositionSetting,
    } = useAccountPreferences(schedulerRef, viewObject);
    const companySettings = useSelector(getCompanySettings);
    const node = useRef();

    const handleClickOutside = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target) && isOpen) {
                onClose();
            }
        },
        [onClose, isOpen]
    );

    const updateGridPreferencesWrapper = newPreferences => {
        if (newPreferences.hasOwnProperty('eventSmartResize')) {
            const currentScale = gridPreferences.defaultScale || 'Day';
            const scale = findByValue(currentScale);
            const eventMoveSkipNonBusiness = [scales.WEEK.value, scales.MONTH.value].includes(scale?.value)
                ? false
                : newPreferences.eventSmartResize || companySettings.grid.hideNonWorkingDays;

            schedulerRef.current.control.update({
                eventMoveSkipNonBusiness,
            });
        } else if (newPreferences.hasOwnProperty('defaultScale')) {
            const scale = findByValue(newPreferences.defaultScale);
            const eventMoveSkipNonBusiness = [scales.WEEK.value, scales.MONTH.value].includes(scale?.value)
                ? false
                : gridPreferences.eventSmartResize || companySettings.grid.hideNonWorkingDays;

            schedulerRef.current.control.update({
                eventMoveSkipNonBusiness,
            });
        }
        updateGridPreferences(newPreferences);
    };

    useClickOutside(handleClickOutside);

    return (
        <Wrapper
            ref={node}
            className={classNames({ 'fade-in': isOpen, 'footer-visible': gridPreferences.schedulerFooterVisible })}
        >
            {isOpen && (
                <Scrollbar height="100%" className="scrollbar-wrapper">
                    <CloseIcon onClick={onClose} />
                    <Title>Personal preferences</Title>
                    <Description>
                        Customize your scheduler as you like. All settings are saved for your user account only. Global
                        Scheduler settings to change colors etc can be found on your settings page.
                    </Description>
                    <ColumnWidth cellWidth={gridPreferences.cellWidth} onChange={updateGridPreferences} />
                    <BookingHeight eventHeightMode={gridPreferences.eventHeightMode} onChange={updateGridPreferences} />
                    <LinkedBookingsConfig
                        style={gridPreferences.bookingLinkStyle}
                        position={gridPreferences.bookingLinkPosition}
                        color={gridPreferences.bookingLinkColor}
                        display={gridPreferences.bookingLinkDisplay}
                        onChange={updateGridPreferences}
                    />
                    <DeadlinesConfig
                        style={gridPreferences.deadlineLinkStyle}
                        position={gridPreferences.deadlineLinkPosition}
                        linkColor={gridPreferences.deadlineLinkColor}
                        deadlineColor={gridPreferences.deadlineColor}
                        display={gridPreferences.deadlineDisplay}
                        tooltipDisplay={gridPreferences.deadlineTooltipDisplay}
                        onChange={updateGridPreferences}
                    />
                    <SubGroupsPosition
                        subGroupsPosition={subGroupsPositionSetting}
                        onChange={updateSubGroupsPositionSetting}
                    />
                    <AvailabilityDisplayOptions
                        availabilityDisplay={gridPreferences.availabilityDisplay}
                        availabilityHover={gridPreferences.availabilityHover}
                        onChange={updateGridPreferences}
                    />
                    <ContextMenuVersion contextMenu={gridPreferences.contextMenu} onChange={updateGridPreferences} />
                    <DefaultLayoutMode mode={gridPreferences.mode} onChange={updateGridPreferences} />
                    <DefaultScale defaultScale={gridPreferences.defaultScale} onChange={updateGridPreferencesWrapper} />
                    <BookingToolTipDisplay
                        bookingToolTipHoverDelay={gridPreferences.bookingToolTipHoverDelay}
                        onChange={updateGridPreferences}
                    />
                    <DisplayAvailabilityOnCellHover
                        schedulerFooterVisible={gridPreferences.schedulerFooterVisible}
                        displayWeeks={gridPreferences.displayWeeks}
                        displayMilestoneText={gridPreferences.displayMilestoneText}
                        groupBookings={gridPreferences.groupBookings}
                        eventSmartResize={gridPreferences.eventSmartResize}
                        groupBookingsLimit={gridPreferences.groupBookingsLimit}
                        onChange={updateGridPreferences}
                        smartOnChange={updateGridPreferencesWrapper}
                    />
                </Scrollbar>
            )}
        </Wrapper>
    );
};

Customization.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
};

export default Customization;
