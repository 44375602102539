import React from 'react';
import PropTypes from 'prop-types';

const RequestProvider = ({ booking, loggedResourceId }) => {
    let text = booking.resource ? `${booking.resourceFirstName} ${booking.resourceLastName} on` : 'No Name';

    if (booking.resource === loggedResourceId) {
        text = '';
    }

    if (booking.resource === booking.bookingCreatorId) {
        text = 'to be on';
    }

    return <span>{text}</span>;
};

RequestProvider.propTypes = {
    booking: PropTypes.object.isRequired,
    loggedResourceId: PropTypes.string.isRequired,
};

export default RequestProvider;
