import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm, submit, initialize } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { createProject } from 'actions/projectActions';
import { materialInputGroupField } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { useAppKeyWords } from 'hooks';
import { validateSchema } from 'utils/schemaUtil';
import { getDefaultValues, formSchema, mapFormToRequest } from './form';

const FORM_NAME = 'newShortProject';

const NewShortProject = props => {
    const { handleSubmit, onCreate, submitting, invalid, initialName, assignResources } = props;
    const { projectKeyWord } = useAppKeyWords();
    const dispatch = useDispatch();
    const isCreating = useSelector(state => state.projectReducer.isCreating);
    const onSubmit = values => {
        dispatch(
            createProject.request({
                ...mapFormToRequest(values, assignResources),
                options: {
                    skipCustomFieldsValidation: true
                }
            }, project => {
                onCreate && onCreate(project);
            })
        );
    };

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues(initialName)));
    }, [dispatch, initialName]);

    return (
        <>
            <Form className="needs-validation mb-5" onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-5">
                    <Col>
                        <Field required label="Name" name="name" component={materialInputGroupField} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Field label="Code" name="projectCode" component={materialInputGroupField} />
                    </Col>
                </Row>
            </Form>
            <SuccessButton
                fixed
                disabled={isCreating || submitting || invalid}
                name={`Create ${projectKeyWord.toLowerCase()}`}
                onClick={() => dispatch(submit(FORM_NAME))}
                dataCy="button--create-project"
                className="mt-2"
            />
        </>
    );
};

NewShortProject.propTypes = {
    onCreate: PropTypes.func,
    initialName: PropTypes.string,
    assignResources: PropTypes.array,
};

NewShortProject.defaultProps = {
    onCreate: null,
    assignResources: [],
    initialName: '',
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(NewShortProject);
