import { put, takeLatest, fork, join, select } from 'redux-saga/effects';
import * as actionTypes from 'actions/actionTypes';
import { initialize } from 'actions/initializeActions';
import { getCompanyTree as getCompanyTreeSaga } from 'sagas/companyTreeSaga';
import { getCompany } from 'sagas/companySaga';
import { monitoring } from '../monitoring';

const getAccount = state => state.account;
const getRouter = state => state.router;

export function* initialRequest() {
    const account = yield select(getAccount);

    if (!account._id) {
        yield put(initialize.failure());
    } else {
        try {
            const router = yield select(getRouter);
            if (-1 === router.location.pathname.indexOf('group')) {
                const companyTree = yield fork(getCompanyTreeSaga);
                yield join(companyTree);
            }
            const companyResponse = yield fork(getCompany, account.companyId);
            yield join(companyResponse);

            yield put(initialize.success());
        } catch (error) {
            monitoring.captureException(error);
            yield put(initialize.failure());
        }
    }
}

export default function* initialWatcher() {
    yield takeLatest(actionTypes.INITIAL['REQUEST'], initialRequest);
}
