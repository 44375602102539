import { TIME_SHEET } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';
import { capitalizeFirstLetter } from 'utils/formatingUtil';

/**
 * @returns {*[]}
 */
export default itemType => [
    {
        id: 'name',
        display: `${capitalizeFirstLetter(itemType)} name`,
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'date',
        display: 'Date',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingCategoryName',
        display: 'Category Name',
        multiItemsDisplay: 'Categories',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingNote',
        display: 'Booking Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'timeEntryNote',
        display: 'Time Entry Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        extensions: [TIME_SHEET],
        heatMapSupport: false,
    },
    {
        id: 'bookingApprovalNote',
        display: 'Booking Approval Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingRejectionNote',
        display: 'Booking Rejection Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingRequestedNote',
        display: 'Booking Requested Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingTitle',
        display: 'Booking Title',
        multiItemsDisplay: 'Titles',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'timeEntryStatus',
        display: 'Time Entry status',
        multiItemsDisplay: 'Statuses',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        extensions: [TIME_SHEET],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingType',
        display: 'Booking Type',
        multiItemsDisplay: 'Types',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
];
