import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { ListIcon } from './styles';

const getLink = link => (-1 === link.indexOf('http') ? `//${link}` : link);

const Links = ({ links }) => (
    <List component="nav" aria-label="booking links">
        {links.link1 && (
            <ListItem button component="a" href={getLink(links.link1)} target="_blank">
                <ListIcon>
                    <i className={`fa ${links.iconLink1}`} />
                </ListIcon>
                <ListItemText primary={links.link1} />
            </ListItem>
        )}
        {links.link2 && (
            <ListItem button component="a" href={getLink(links.link2)} target="_blank">
                <ListIcon>
                    <i className={`fa ${links.iconLink2}`} />
                </ListIcon>
                <ListItemText primary={links.link2} />
            </ListItem>
        )}
        {links.link3 && (
            <ListItem button component="a" href={getLink(links.link3)} target="_blank">
                <ListIcon>
                    <i className={`fa ${links.iconLink3}`} />
                </ListIcon>
                <ListItemText primary={links.link3} />
            </ListItem>
        )}
        {links.link4 && (
            <ListItem button component="a" href={getLink(links.link4)} target="_blank">
                <ListIcon>
                    <i className={`fa ${links.iconLink4}`} />
                </ListIcon>
                <ListItemText primary={links.link4} />
            </ListItem>
        )}
        {links.link5 && (
            <ListItem button component="a" href={getLink(links.link5)} target="_blank">
                <ListIcon>
                    <i className={`fa ${links.iconLink5}`} />
                </ListIcon>
                <ListItemText primary={links.link5} />
            </ListItem>
        )}
    </List>
);

Links.propTypes = {
    links: PropTypes.object.isRequired,
};

export default Links;
