/* eslint-env browser */
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Alert } from 'reactstrap';
import { Field, reduxForm, initialize } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, asyncValidate } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import { SubmitButton } from '../../shared/buttons';
import { saveWelcomeData, updateNotificationsRequest } from 'api/account';
import { getUrl } from 'utils/browserUtil';
import { SubscribeCheckbox } from '../../shared/checkboxes';
import { hubSpotPortalId } from '../../../../hooks';

const Welcome = props => {
    const { handleSubmit } = props;
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const { companyName, subDomain, account } = useSelector(state => ({
        companyName: state.companyReducer.company.name,
        subDomain: state.companyReducer.company.subdomain,
        account: state.account,
    }));
    const { firstName, lastName } = account;

    // after saving welcome data we do full reload page to redirect user on subdomain.hubplanner.com/..., so no need
    // to update redux state, just do api calls that we need
    async function submit(values) {
        try {
            await saveWelcomeData(values);
            const notification = {
                category: 'MARKETING_EMAIL_NEW_FEATURES_NOTIFICATION',
                channel: 'EMAIL',
                enabled: values.newFeatureEmailAgreed,
                modifiedDate: new Date(),
            };
            const notifications = [...account.preferences.notifications, notification];
            await updateNotificationsRequest(account._id, { notifications });

            // redirect to scheduler start tour
            const url = getUrl(values.subDomain);
            window.location.assign(`${url}/main#/startTour?welcome-to-hubplanner-3213444`);
        } catch (e) {
            setError('Something is wrong. Try to save one more time.');
        }
    }

    useEffect(() => {
        let script = document.createElement('script');
        script.src = `//js-eu1.hs-scripts.com/${hubSpotPortalId}.js`;
        script.id = 'hs-script-loader';
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // if production add tracking links only.
        if ('production' !== process.env.NODE_ENV) {
            return;
        }

        //tracking for linkedInAds
        window.lintrk('track', { conversion_id: 10232929 });

        // Tracking info to Google
        window.gtag('event', 'conversion', {
            send_to: 'AW-994965946/C5HACO3a7pABELrzt9oD',
        });

        // tracking for DPM
        const dpmTrackingScript = document.createElement('script');
        dpmTrackingScript.type = 'text/javascript';
        dpmTrackingScript.async = true;
        dpmTrackingScript.src = 'https://trk.crozdesk.com/aEiruanJyshRamXs-vjN';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(dpmTrackingScript, s);

        // tracking for Capterra
        const capterraScript = document.createElement('script');
        capterraScript.type = 'text/javascript';
        capterraScript.async = true;
        const capterra_vkey = '21c98982678f451013d116fa654281f3'; //process.env.REACT_APP_CAPTERRA_KEY
        const capterra_vid = '2099922'; //process.env.REACT_APP_CAPTERRA_VID_KEY;
        capterraScript.src =
            'https://ct.capterra.com/capterra_tracker.gif?vid=' + capterra_vid + '&vkey=' + capterra_vkey;
        const sc = document.getElementsByTagName('script')[0];
        sc.parentNode.insertBefore(capterraScript, sc);

        return () => {
            dpmTrackingScript.remove();
            capterraScript.remove();
        };
    }, []);

    useEffect(() => {
        dispatch(
            initialize('welcomeForm', {
                firstName,
                lastName,
                companyName,
                subDomain,
                newFeatureEmailAgreed: false,
                currentSubDomain: subDomain,
            })
        );
    }, [firstName, lastName, companyName, subDomain, dispatch]);

    return (
        <PageTemplate type="welcome">
            <h1>Final Details</h1>

            <p className="text-muted">{`Let's set up some basic settings`}</p>

            {error && <Alert color="danger"> {error}</Alert>}
            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        label="First Name"
                        name="firstName"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>
                <FormGroup className="mb-3">
                    <Field component={renderTextField} label="Last Name" name="lastName" variant="outlined" fullWidth />
                </FormGroup>

                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        label="Company Name"
                        name="companyName"
                        variant="outlined"
                        fullWidth
                    />
                </FormGroup>

                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        label="Domain Name"
                        name="subDomain"
                        variant="outlined"
                        normalize={v => v.replace(/[^a-zA-Z0-9-]/i, '').substr(0, 30)}
                        fullWidth
                    />
                </FormGroup>

                <div className="mb-3 pl-1">
                    <SubscribeCheckbox />
                </div>

                <SubmitButton type="submit" className="btn-block mb-2 shadow-sm border-0 py-2">
                    Create Account
                </SubmitButton>
            </Form>
        </PageTemplate>
    );
};

Welcome.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'welcomeForm',
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    asyncValidate,
    asyncBlurFields: ['subDomain'],
})(Welcome);
