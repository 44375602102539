import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'reactstrap';
import { isFunction, isObject } from 'lodash';
import { GroupItemWrapper, Label } from './styles';
import { DotsMenuButton } from 'shared/components/dotsMenuButton';
import { showConfirmationModal, showReportsGroupModal } from 'actions/modalActions';
import { hasProjectManagerReportRights, hasRole } from 'utils/rightsUtil';
import { removeReportsGroup, updateReportsGroup } from 'actions/reportActions';

export const ReportsGroupMenuItem = props => {
    const {
        id,
        active,
        className,
        classNameActive,
        classStore,
        icon: Icon,
        toggleSubMenu,
        hasSubMenu,
        subMenuVisibility,
        label,
        group,
        parent,
        content,
        allContent,
    } = props;

    const dispatch = useDispatch();
    const account = useSelector(state => state.account);

    const hasManageReportRights =
        hasRole(account.resourceRoleRights, 'manageReports') ||
        hasProjectManagerReportRights(account) ||
        parent === 'PRIVATE';

    const handleClick = useCallback(
        event => {
            toggleSubMenu(event);
        },
        [toggleSubMenu]
    );

    const allReports = useMemo(() => allContent.filter(item => !!item.templateId), [allContent]);

    return (
        <GroupItemWrapper>
            <NavLink
                href="#"
                className={classNames(className, active && classNameActive)}
                onClick={handleClick}
                data-group-id={id}
            >
                {(isFunction(Icon) || isObject(Icon)) && <Icon className={classStore.classIcon} />}

                <Label title={label}>{label}</Label>
                {hasSubMenu && (
                    <i
                        className={classNames(
                            classStore.classStateIcon,
                            classStore.iconNamePrefix +
                                (subMenuVisibility ? classStore.iconNameStateVisible : classStore.iconNameStateHidden)
                        )}
                    />
                )}
            </NavLink>

            {hasManageReportRights ? (
                <DotsMenuButton
                    menuPosition={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    options={[
                        {
                            title: 'Edit Group',
                            action: () =>
                                dispatch(showReportsGroupModal({ group, allReports, parent, menuContent: content })),
                        },
                        { divider: true },

                        {
                            title: 'Sort by created date',
                            action: () =>
                                dispatch(updateReportsGroup.request(group._id, { ...group, sortBy: 'CREATED_DATE' })),
                        },
                        {
                            title: 'Sort alphabetically',
                            action: () => dispatch(updateReportsGroup.request(group._id, { ...group, sortBy: 'NAME' })),
                        },

                        ...(group.createdBy === account.resourceId || account.resourceRole === 'ROLE_OWNER'
                            ? [
                                  { divider: true },
                                  {
                                      title: 'Remove Group...',
                                      action: () => {
                                          dispatch(
                                              showConfirmationModal(
                                                  () => dispatch(removeReportsGroup.request(group._id)),
                                                  'Removing reports group',
                                                  'Are you sure you want to remove this group permanently? This operation will not affect reports.'
                                              )
                                          );
                                      },
                                  },
                              ]
                            : []),
                    ]}
                />
            ) : null}
        </GroupItemWrapper>
    );
};

ReportsGroupMenuItem.propTypes = {
    groupIg: PropTypes.object.isRequired,
};

export default ReportsGroupMenuItem;
