import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
    width: 100%;
    height: 35px;
    background-color: #dadada;
    border-radius: 2px;
    z-index: -1;
    .progress-value {
        position: absolute;
        z-index: 1;
        text-align: center;
        height: 18px;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
`;

export const ProgressBar = styled.div`
    width: ${props => `${props.value}%`};
    max-width: 100%;
    height: 100%;
    background-color: ${props =>
        100 < props.value ? 'rgb(249, 115, 86)' : 100 === props.value ? 'rgb(10, 214, 169)' : 'rgb(0, 181, 212)'};
    border-radius: 2px;
    transition: 'all .2s ease-out';
`;
