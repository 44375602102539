import { useCallback, useMemo } from 'react';
import { some } from 'lodash';
import { useSelector } from 'react-redux';
import { VACATION_REQUEST, RESOURCE_REQUEST } from 'enums/extensionShortIdEnum';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { useHasRights, useIsExtensionInstalled } from 'hooks';
import { selectGroupIdsWhereIAmApprover, selectMappedGroupResources } from '../../../selectors/resourceGroup';
import { makeResourceById } from '../../../selectors/resource';
import { getAccount } from 'selectors/account';
import { isAbleToEditResourceOrVacationRequest } from '../utils/permissions';

const rights = [
    {
        rights: ['manageAllVacations'],
        rule: 'one',
        name: 'hasManageAllVacationsRights',
    },
    {
        rights: ['manageAllResourceRequests'],
        rule: 'one',
        name: 'hasManageAllResourceRequestsRights',
    },
    {
        rights: ['canRequestVacation'],
        rule: 'one',
        name: 'hasCreateRequestVacationRights',
    },
    {
        rights: ['canRequestResource'],
        rule: 'one',
        name: 'hasCreateRequestResourceRights',
    },
    {
        rights: ['canViewVacationRequestsOnScheduler'],
        rule: 'one',
        name: 'hasViewVacationRequestRights',
    },
    {
        rights: ['canViewResourceRequestsOnScheduler'],
        rule: 'one',
        name: 'hasViewResourceRequestRights',
    },
];

export const useGetRequestRights = booking => {
    const account = useSelector(state => state.account);
    const { isApprover, resourceId } = account;
    const defaultVacationId = useSelector(state => state.companyReducer.company.settings.vacationId);
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const isRequestResourceExtensionInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const {
        hasManageAllVacationsRights,
        hasManageAllResourceRequestsRights,
        hasCreateRequestVacationRights,
        hasCreateRequestResourceRights,
    } = useHasRights(rights);

    const bookingResourceSelector = useMemo(() => makeResourceById(booking?.resourceInfo?._id || booking?.resource), [
        booking?.resource,
        booking?.resourceInfo?._id,
    ]);
    const bookingResource = useSelector(bookingResourceSelector);
    const mappedGroupResources = useSelector(selectMappedGroupResources);
    const groupIdsWhereIAmApprover = useSelector(selectGroupIdsWhereIAmApprover);

    return useCallback(() => {
        const bookingResourceId = booking?.resourceInfo?._id || booking?.resource;

        const canManageVacations = isVacationExtensionInstalled && hasManageAllVacationsRights;
        const canManageResourceRequest = isRequestResourceExtensionInstalled && hasManageAllResourceRequestsRights;
        const canRequestVacations =
            isVacationExtensionInstalled && (hasCreateRequestVacationRights || canManageVacations);
        const canRequestResources =
            isRequestResourceExtensionInstalled && (hasCreateRequestResourceRights || canManageResourceRequest);
        const isRegularBooking = booking && !booking.phase && !booking.milestone && !booking.datesEvent && !booking.deadline;
        const amIApproverOnRequest =
            booking && isRegularBooking
                ? some(booking.approvalInfo.approvers, approver => approver.id === resourceId) ||
                  groupIdsWhereIAmApprover.some(groupId => mappedGroupResources[groupId]?.[bookingResourceId])
                : false;
        const isVacationBooking = booking && isRegularBooking && defaultVacationId === booking.project._id;

        const hasManageResourceApprovalForBooking =
            isRequestResourceExtensionInstalled &&
            (!isVacationExtensionInstalled || !isVacationBooking) &&
            (canManageResourceRequest || (isApprover && amIApproverOnRequest));
        const hasManageVacationApprovalForBooking =
            isVacationExtensionInstalled &&
            booking &&
            isVacationBooking &&
            (canManageVacations || (isApprover && amIApproverOnRequest));

        const hasManageApprovalForBooking =
            hasManageResourceApprovalForBooking ||
            hasManageVacationApprovalForBooking ||
            bookingResource?.approverIds.includes(resourceId);

        return {
            canRequestVacations,
            canManageVacations,
            canRequestResources,
            canManageResourceRequest,
            hasManageApprovalForBooking,
        };
    }, [
        booking,
        bookingResource?.approverIds,
        defaultVacationId,
        groupIdsWhereIAmApprover,
        hasCreateRequestResourceRights,
        hasCreateRequestVacationRights,
        hasManageAllResourceRequestsRights,
        hasManageAllVacationsRights,
        isApprover,
        isRequestResourceExtensionInstalled,
        isVacationExtensionInstalled,
        mappedGroupResources,
        resourceId,
    ]);
};

export const useRequests = booking => {
    const defaultVacationId = useSelector(state => state.companyReducer.company.settings.vacationId);
    const account = useSelector(getAccount);
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const isRequestResourceExtensionInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const {
        hasManageAllVacationsRights,
        hasManageAllResourceRequestsRights,
        hasViewVacationRequestRights,
        hasViewResourceRequestRights,
    } = useHasRights(rights);
    const isAbleToEditRequests = isAbleToEditResourceOrVacationRequest(
        booking,
        account.resourceId,
        account.resourceRoleRights,
        defaultVacationId
    );

    const canViewRequest = useCallback(
        givenBooking => {
            const usedBooking = givenBooking || booking;
            if (usedBooking.type !== WAITING_FOR_APPROVAL.value) {
                return true;
            }
            const canViewVacations =
                isVacationExtensionInstalled &&
                usedBooking.project === defaultVacationId &&
                (hasViewVacationRequestRights || hasManageAllVacationsRights);
            const canViewResourceRequests =
                isRequestResourceExtensionInstalled &&
                usedBooking.project !== defaultVacationId &&
                (hasViewResourceRequestRights || hasManageAllResourceRequestsRights);

            return canViewVacations || canViewResourceRequests;
        },
        [
            booking,
            isVacationExtensionInstalled,
            isRequestResourceExtensionInstalled,
            hasViewVacationRequestRights,
            hasViewResourceRequestRights,
            hasManageAllVacationsRights,
            hasManageAllResourceRequestsRights,
            defaultVacationId,
        ]
    );

    const getRequestRights = useGetRequestRights(booking);

    const {
        canRequestVacations,
        canManageVacations,
        canRequestResources,
        canManageResourceRequest,
        hasManageApprovalForBooking,
    } = getRequestRights();

    return {
        canViewRequest,
        canRequestVacations: canRequestVacations && isVacationExtensionInstalled,
        canManageVacations: canManageVacations && isVacationExtensionInstalled,
        canRequestResources: canRequestResources && isRequestResourceExtensionInstalled,
        canManageResourceRequest: canManageResourceRequest && isRequestResourceExtensionInstalled,
        hasManageApprovalForBooking,
        isAbleToEditRequests,
    };
};
