import React from 'react';
import { Row, Col } from 'reactstrap';
import { PermissionDeniedImage } from './styles';

const PermissionDenied = () => (
    <Row>
        <Col>
            <div className="text-center">
                <PermissionDeniedImage src="https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png" />
                <h2>No Permissions to View</h2>
                <div className="text-muted">
                    You may not have access to these Settings due to a lack of role rights. To access this page please
                    ask your Account Owner to enable Rights for this page under Settings {'->'} Roles.
                </div>
            </div>
        </Col>
    </Row>
);

export default PermissionDenied;
