import { useCallback } from 'react';
import getAllocationValuesChanges from '../../../../../../../../shared/allocation/getAllocationValuesChanges';
import { CELLDURATION } from '../../../../../../enums/scale';
import { getResourceCapacity } from '../../../../../../../../utils/capacityCalculation';
import moment from 'moment';
import { getBookingAllocationValuesFromSingleValue } from '../../../../../../../../shared/lib/booking';
import { useSelector } from 'react-redux';
import allocationTypes from '../../../../../../../../enums/allocationTypeEnum';

export const useAllocationCalc = props => {
    const { currentSelection, allocationValues, updateFields, scale, formik, resource } = props;
    const avgDailyCapacity = useSelector(state => state.companyReducer.company.settings.report.avgDailyCapacity);

    const calculateAllocation = useCallback(
        ({ name, value, start, end }) => {
            const selectedResource = resource ? resource : currentSelection?.rowTags?.resource;
            const newValues = getAllocationValuesChanges({ name, value }, allocationValues);

            // Calculate like in modern menu
            let allocationNonHourMode = {};
            if (scale !== CELLDURATION.value && start && end) {
                const { workDaysCount, totalMinutes } = getResourceCapacity(
                    // selectedResource may be null or undefined, but will calculate based on company settings
                    selectedResource,
                    moment(start),
                    moment(end)
                );

                const parsedValue = parseFloat(value || 0);

                allocationNonHourMode = getBookingAllocationValuesFromSingleValue({
                    value: name === 'totalMinutes' ? parsedValue / 60 : parsedValue,
                    nameOfValue: name === 'totalMinutes' ? 'total' : name,
                    avgDailyCapacity,
                    numberOfWorkDays: workDaysCount,
                    totalBookingMinutes: totalMinutes,
                });
            }

            updateFields({
                ...newValues,
                ...allocationNonHourMode,
            });
        },
        [allocationValues, avgDailyCapacity, currentSelection?.rowTags?.resource, resource, scale, updateFields]
    );

    const updateDates = useCallback(
        fieldsData => {
            if (fieldsData.end || fieldsData.start) {
                const start = formik.values.start;
                const end = formik.values.end;

                const currentState = allocationTypes[formik.values.state];
                const value = formik.values[currentState.valueName];
                updateFields(fieldsData);
                calculateAllocation({
                    value,
                    name: currentState.valueName,
                    start,
                    end,
                    ...fieldsData,
                });
            }
        },
        [calculateAllocation, formik.values, updateFields]
    );

    const handleAllocationValueChange = useCallback(
        ({ name, value }) => {
            calculateAllocation({ name, value, start: formik.values.start, end: formik.values.end });
        },
        [calculateAllocation, formik.values.end, formik.values.start]
    );

    const handleAllocationTypeChange = useCallback(
        type => {
            updateFields({ state: type });
        },
        [updateFields]
    );

    return {
        updateDates,
        handleAllocationTypeChange,
        handleAllocationValueChange,
    };
};
