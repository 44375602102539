import { OPERATORS } from 'enums/textOperatorEnum';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { textField as TextField, dropdownField as DropdownField } from 'shared/formFields';

class TextFilter extends PureComponent {
    static propTypes = {
        defaultValues: PropTypes.shape({
            operator: PropTypes.string,
            value: PropTypes.string,
        }),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: null,
        defaultValues: {
            operator: '',
            value: '',
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            operator: props.defaultValues.operator,
            value: props.defaultValues.value,
        };
    }

    onOperatorChange = operator => {
        if (this.props.onChange) {
            this.props.onChange({ operator: operator.value });
        }
    };

    onTextChange = event => {
        if (this.props.onChange) {
            this.props.onChange({ value: event.target.value });
        }
    };

    render() {
        return (
            <React.Fragment>
                <DropdownField
                    input={{
                        name: 'filter',
                        value: this.props.defaultValues.operator,
                        onBlur: () => {},
                        onChange: this.onOperatorChange,
                    }}
                    filter={false}
                    textField="name"
                    valueField="value"
                    data={OPERATORS}
                />
                <TextField
                    width="col-md-12"
                    placeholder="Enter the value"
                    value={this.props.defaultValues.value}
                    onChange={this.onTextChange}
                    name="text"
                />
            </React.Fragment>
        );
    }
}

export default TextFilter;
