import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, FieldArray } from 'redux-form';
import { TabPane, FormText, Row, Col, Alert } from 'reactstrap';
import { chooseField, dateField, materialInputGroupField } from 'shared/formFields';
import { useHasRights } from 'hooks';
import ColorPicker from 'shared/colorPicker';
import Repeat from 'shared/repeat';
import PermissionDenied from 'shared/permissionDenied';
import SuccessButton from '../../../../shared/buttons/success';

const MilestoneRow = React.memo(({ index, milestone, onFieldChange, fields }) => {
    const handleIntervalChange = useCallback(
        interval => {
            if (interval === 'NONE') {
                onFieldChange({ [`${milestone}.repeatTimes`]: 0 });
            }
        },
        [onFieldChange, milestone]
    );

    return (
        <div key={index}>
            <Row className={classnames({ 'mt-4': 0 < index })}>
                <Col md={1}>
                    <ColorPicker
                        color={fields.get(index).color}
                        onColorChange={color => onFieldChange({ [`${milestone}.color`]: color.hex })}
                    />
                </Col>
                <Col md={6}>
                    <Field
                        name={`${milestone}.name`}
                        placeholder="Milestone title"
                        label="Milestone title"
                        component={materialInputGroupField}
                    />
                </Col>
                <Col md={5}>
                    <Field name={`${milestone}.date`} label="Date" component={dateField} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Repeat
                        removeBorder={true}
                        wrapperClass="px-0 my-0"
                        title="Milestone repeat:"
                        inputFieldDisabled={'NONE' === fields.get(index).interval}
                        radioInputName={`${milestone}.interval`}
                        textInputName={`${milestone}.repeatTimes`}
                        onIntervalChange={handleIntervalChange}
                    />
                </Col>
            </Row>
            <Row className={classnames({ 'pb-4 b-bottom': fields.length - 1 !== index })}>
                <Col md={12}>
                    <SuccessButton
                        onClick={() => fields.remove(index)}
                        name="Remove milestone"
                        dataCy="button--remove-milestone"
                        type="btn-cancel"
                        className="float-right"
                    />
                </Col>
            </Row>
        </div>
    );
});

const renderMilestones = ({ fields, onFieldChange, meta: { error } }) => {
    return (
        <>
            {error && <Alert color="danger">{error}</Alert>}
            {fields.map((milestone, index) => (
                <MilestoneRow
                    key={index}
                    milestone={milestone}
                    fields={fields}
                    index={index}
                    onFieldChange={onFieldChange}
                />
            ))}
            <SuccessButton
                onClick={() =>
                    fields.push({ interval: 'NONE', repeatTimes: 0, date: new Date(), name: '', color: '#349E71' })
                }
                name="Add a milestone"
                dataCy="button--add-milestone"
                type="btn-primary"
            />
        </>
    );
};

const MilestonesTab = ({ tabId, onFieldChange, projectKeyWord, displayDatesInput }) => {
    const { canAddDates, hasSettingProjectMilestones } = useHasRights([
        {
            rights: ['settingProjectDates'],
            rule: 'one',
            name: 'canAddDates',
        },
        {
            rights: ['settingProjectMs'],
            rule: 'one',
            name: 'hasSettingProjectMilestones',
        },
    ]);

    return (
        <TabPane tabId={tabId}>
            {hasSettingProjectMilestones && (
                <section className="form-fields">
                    <p className="title">Milestones:</p>
                    <FieldArray name="milestones" component={renderMilestones} onFieldChange={onFieldChange} />
                </section>
            )}
            {canAddDates && (
                <section className="form-fields">
                    <Row>
                        <Col md={12}>
                            <p className="title">{projectKeyWord} Start / End Dates:</p>
                            <Field
                                type="checkbox"
                                inline
                                label="Set Start and End Dates"
                                name="setStartEndDates"
                                value={true}
                                component={chooseField}
                            />
                            <FormText className="mb-3">
                                Set Start and End dates which will appear on the Scheduler and Reports
                            </FormText>
                            {displayDatesInput ? (
                                <Row>
                                    <Col md={3}>
                                        <Field
                                            className="w-100"
                                            name="startDate"
                                            label="Start date"
                                            component={dateField}
                                            componentDataCyPrefix="project-modal-dates-start-date"
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <Field
                                            name="endDate"
                                            label="End date"
                                            className="ml-2 w-100"
                                            component={dateField}
                                            componentDataCyPrefix="project-modal-dates-end-date"
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                        </Col>
                    </Row>
                </section>
            )}
            {!hasSettingProjectMilestones && !canAddDates && <PermissionDenied />}
        </TabPane>
    );
};

MilestonesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onFieldChange: PropTypes.func.isRequired,
    projectKeyWord: PropTypes.string,
    displayDatesInput: PropTypes.bool,
};

MilestonesTab.defaultProps = {
    projectKeyWord: 'Project',
    displayDatesInput: false,
};

export default MilestonesTab;
