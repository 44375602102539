import React from 'react';

import EqualIcon from '../../../assets/images/icons/equal.svg';
import NotEqualIcon from '../../../assets/images/icons/not-equal.svg';
import { ButtonBase, Grid } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const EqualityToggleRoot = styled(Grid)`
  max-width: 70px;
`

const EqualityButton = styled(ButtonBase)`
    &.MuiButtonBase-root {
        display: inline-flex;
        border-radius: 100%;
        width: 24px;
        height: 24px;
        margin: 0 5px;
        background-color: ${({ active }) => (active ? `rgba(173,219,200, 0.2)` : 'white')};
        padding: 0;
        justify-content: center;
        align-items: center;

        svg path {
            fill: ${({ active }) => (active ? `#32a675` : `#4E5252`)};
        }

        &.Mui-disabled {
            background-color: white;
            svg path {
                fill: #a1a3a3;
            }
        }
    }
`;

const EqualityToggle = props => {
    const { value, onChange, disabled } = props;

    const handleEqualClick = () => {
        if (disabled) {
            return;
        }

        onChange(true);
    };

    const handleNotEqualClick = () => {
        if (disabled) {
            return;
        }

        onChange(false);
    };

    return (
        <EqualityToggleRoot container justifyContent="space-between" alignItems="center">
            <Grid item>
                <EqualityButton disableRipple disabled={disabled} active={value} onClick={handleEqualClick}>
                    <EqualIcon />
                </EqualityButton>
            </Grid>
            <Grid item>
                <EqualityButton disableRipple disabled={disabled} active={!value} onClick={handleNotEqualClick}>
                    <NotEqualIcon />
                </EqualityButton>
            </Grid>
        </EqualityToggleRoot>
    );
};

EqualityToggle.propTypes = {
    value: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

const memoized = React.memo(EqualityToggle);

export { memoized as EqualityToggle };
