import { CancelToken } from 'axios';

export function makeRequestCancellable(request) {
    const source = CancelToken.source();
    const cancelToken = source.token;

    return {
        call: request(cancelToken),
        cancel: () => source.cancel()
    };
}
