import { mapValues, each, map, isFunction } from 'lodash';
import {
    BOOKING_PROJECT_STATUSES,
    BOOKING_PROJECTS_EVENTS,
    BOOKING_RESOURCES_UW,
    PROJECT_MANAGERS, PROJECT_STATUSES
} from '../../../enums/schedulerFilterEnum';

export const initializeFilters = (schedulerFilters, filtersList, keyWords) => {
    const filters = [];

    mapValues(schedulerFilters, val => {
        if (val.nestedItems) {
            each(filtersList[val.filterListProp], filterItem => {
                const toPush = map(filterItem[val.nestedItems], nestedItem => ({
                    title: isFunction(val.itemLabel) ? val.itemLabel(filterItem) : filterItem[val.itemLabel],
                    name: val.filterListProp,
                    parentValue: filterItem[val.itemValue],
                    label: nestedItem[val.nestedItemLabel],
                    value: nestedItem[val.nestedItemValue],
                    color: val.color,
                }));
                filters.push(...toPush);
            });
        } else {
            const toPush = map(filtersList[val.filterListProp], filterItem => {
                let value = filterItem[val.itemValue];
                if (val.value === PROJECT_MANAGERS.value) {
                    value = value + '_pm';
                } else if (val.value === BOOKING_PROJECTS_EVENTS.value) {
                    value = value + '_bpe';
                } else if (val.value === BOOKING_RESOURCES_UW.value) {
                    value = value + '_bru';
                } else if (val.value === BOOKING_PROJECT_STATUSES.value) {
                    value = value + '_bps';
                } else if (val.value === PROJECT_STATUSES.value) {
                    value = value + '_ps';
                }
                return {
                    name: val.filterListProp,
                    label: isFunction(val.itemLabel) ? val.itemLabel(filterItem, keyWords) : filterItem[val.itemLabel],
                    value,
                    title: val.label(keyWords, filterItem),
                    color: val.color,
                };
            });
            filters.push(...toPush);
        }
    });

    return filters;
};
