import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MenuItem, Divider } from '@material-ui/core';
import { useBooking, useHandleApproveOrReject } from 'modules/scheduler/hooks';
import { LinkItem } from './styles';
import { REJECTED, APPROVED } from 'enums/bookingTypeEnum';
import { getRequestUrls } from 'modules/scheduler/utils/getRequestUrls';

const LegacyBooking = ({ onClose, currentSelectedBooking, onEdit, hasManageApprovalForBooking }) => {
    const { requestUrl, vacationUrl } = getRequestUrls(currentSelectedBooking.resourceInfo._id);
    const { updateBooking } = useBooking(currentSelectedBooking, onClose);
    const handleApproveOrReject = useHandleApproveOrReject(updateBooking, currentSelectedBooking);

    const { vacationId, sickLeaveId } = useSelector(state => state.companyReducer.company.settings);
    const requestLink = ![vacationId, sickLeaveId].includes(currentSelectedBooking.project._id)
        ? requestUrl
        : vacationUrl;

    return (
        <>
            {hasManageApprovalForBooking && (
                <>
                    <MenuItem
                        onClick={() => handleApproveOrReject(APPROVED.value)}
                        data-cy="legacy-menu-approve-request"
                    >
                        Approve
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleApproveOrReject(REJECTED.value)}
                        data-cy="legacy-menu-reject-request"
                    >
                        Reject
                    </MenuItem>
                    <Divider />
                </>
            )}
            <LinkItem
                onClick={onClose}
                className="p-0 d-inline-block"
                to={requestLink}
                data-cy="legacy-menu-request-dashboard"
            >
                <MenuItem onClick={() => {}}>Request dashboard...</MenuItem>
            </LinkItem>
            <Divider />
            {onEdit && (
                <MenuItem onClick={onEdit} data-cy="legacy-menu-edit-request">
                    Edit request...
                </MenuItem>
            )}
        </>
    );
};

LegacyBooking.propTypes = {
    onClose: PropTypes.func,
};

LegacyBooking.defaultProps = {
    onClose: null,
};

export default LegacyBooking;
