import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { numberValidator, minValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        currency: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        rate: {
            type: String,
            required: true,
            validators: [numberValidator, minValidator(0)],
        },
    },
    errorMessages,
    false
);

export const mapFormToRequest = values => ({
    rate: values.rate,
    currency: values.currency.value,
    label: values.label,
});
