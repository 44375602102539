import React from 'react';
import PropTypes from 'prop-types';
import { ColorBlock, CategoryAllocation } from './styles';

export const SimpleCategoryTemplateItem = props => {
    const { item } = props;

    return item?.name ? (
        <div className="d-flex align-items-center">
            <ColorBlock color={item.gridColor} />
            <span>{item.name}</span>
        </div>
    ) : null;
};

SimpleCategoryTemplateItem.propTypes = {
    item: PropTypes.object.isRequired,
};

const CategoryTemplateItem = props => {
    const { item } = props;

    return item?.name ? (
        <div className="d-flex align-items-center">
            <ColorBlock color={item.gridColor} />
            <span>{item.name}</span>
            {item.allocationText && <CategoryAllocation>{item.allocationText}</CategoryAllocation>}
        </div>
    ) : null;
};

CategoryTemplateItem.propTypes = {
    item: PropTypes.object.isRequired,
};

export default CategoryTemplateItem;
