import React from 'react';
import { contains } from 'underscore';
import {
    displayCurrencyValue,
    displayDateState,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import { Header } from './../styles';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Difference {displayDateState(dateState)}
                        <p className="sub-title">(ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalSpendDifferenceBetween',
        sortable: false,
        show: contains(columnsToLoad, 'externalSpendDifferenceBetween'),
        accessor: row =>
            displayValue(
                row.externalSpendDifferenceBetween,
                displayCurrencyValue(row, 'externalSpendDifferenceBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Difference
                        <p className="sub-title">(ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalSpendDifferenceAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalSpendDifferenceAllTime'),
        accessor: row =>
            displayValue(
                row.externalSpendDifferenceAllTime,
                displayCurrencyValue(row, 'externalSpendDifferenceAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Difference
                        <p className="sub-title">(int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalSpendDifferenceAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalSpendDifferenceAllTime'),
        accessor: row =>
            displayValue(
                row.internalSpendDifferenceAllTime,
                displayCurrencyValue(row, 'internalSpendDifferenceAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Spend Difference {displayDateState(dateState)}
                        <p className="sub-title">(int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalSpendDifferenceBetween',
        sortable: false,
        show: contains(columnsToLoad, 'internalSpendDifferenceBetween'),
        accessor: row =>
            displayValue(
                row.internalSpendDifferenceBetween,
                displayCurrencyValue(row, 'internalSpendDifferenceBetween', defaultCurrency)
            ),
    },
];
