import { Divider } from '@material-ui/core';
import React from 'react';
import { Collapse } from 'reactstrap';
import styled, { css } from 'styled-components';

export const MenuCollapse = props => {
    return (
        <>
            <StyledCollapse {...props} />
            {props.isOpen && <Divider style={{ margin: '0 0.5rem' }} />}
        </>
    );
};

const StyledCollapse = styled(Collapse)`
    ${({ isOpen }) =>
        isOpen
            ? css`
                  position: relative;
                  margin-top: -1px;
                  z-index: 1;
              `
            : ''}
`;
