import { put } from 'redux-saga/effects';
import { showConfirmationModal } from '../../actions/modalActions';
import { store } from '../../store';
import { createBookingLink } from '../../actions/schedulerActions';
import {
    multiMoveResizeDatesWarning,
    singleMoveResizeDatesWarning,
} from '../../modules/scheduler/config/events/onEventMove';
import { dispatchRefreshBookings } from '../../modules/scheduler/utils/schedulerUtil';

export const linkedBookingsCircularDependencyErrorHandler = {
    predicate: error => error?.response?.data?.properties.cause,
    handler: function*(error, action) {
        const cleanUpAction = () => store.dispatch(createBookingLink.failure(action.payload));

        yield put(
            showConfirmationModal(
                () => {
                    cleanUpAction();
                },
                'Booking dependency create error',
                'Circular dependencies are not allowed',
                {
                    withCancel: false,
                    withEscButton: true,
                    confirmButtonText: 'Ok',
                    onReject: cleanUpAction,
                }
            )
        );
    },
};

export const createBookingLinksDatesErrorHandler = {
    predicate: error => {
        return (
            error?.response?.data?.properties?.additionalInformation?.validationError ===
            'child_start_before_parent_start'
        );
    },
    handler: function*(error, action) {
        const cleanUpAction = () => store.dispatch(createBookingLink.failure(action.payload));

        yield put(
            showConfirmationModal(
                () => {
                    store.dispatch({
                        ...action,
                        payload: {
                            ...(action.payload || {}),
                            skipDependencyDateValidation: true,
                        },
                    });
                },
                'Confirm link creation',
                `You're trying to link bookings where child booking start date will be before parent end date, are you sure?`,
                {
                    withEscButton: true,
                    withCancel: true,
                    cancelButtonText: `Cancel`,
                    confirmButtonText: 'Yes',
                    cancelActionOnHide: true,
                    onReject: cleanUpAction,
                }
            )
        );
    },
};

export const linkedBookingsDatesErrorHandler = ({ cleanUpAction }) => ({
    predicate: error => {
        return (
            error?.data?.properties?.additionalInformation?.validationError === 'child_start_before_parent_start' ||
            error?.response?.data?.properties?.additionalInformation?.validationError ===
                'child_start_before_parent_start'
        );
    },
    handler: function*(error, action) {
        yield put(
            showConfirmationModal(
                () => {
                    store.dispatch({
                        ...action,
                        payload: {
                            ...(action.payload || {}),
                            options: {
                                ...(action.payload.options || {}),
                                skipDependencyDateValidation: true,
                            },
                        },
                    });
                },
                'Booking update error',
                `You're trying to move a child booking before parent, do you really want to do it`,
                {
                    withEscButton: true,
                    withCancel: true,
                    cancelButtonText: `Cancel`,
                    confirmButtonText: 'Yes',
                    cancelActionOnHide: true,
                    onReject: cleanUpAction,
                }
            )
        );
    },
});

export const linkedBookingsRepeatedErrorHandler = {
    predicate: error => {
        return (
            error?.data?.properties?.additionalInformation?.validationError ===
            'cannot_repeat_booking_already_linked_to_repeated'
        );
    },
    handler: function*() {
        yield put(
            showConfirmationModal(
                () => {
                    dispatchRefreshBookings();
                },
                'Booking update error',
                `You're trying to convert depended booking into repeated one, connection between repeated bookings is not allowed.`,
                {
                    withEscButton: true,
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );
    },
};

export const linkedBookingsMoveOutsideWorkingHours = ({ cleanUpAction, bookingsCount }) => ({
    predicate: error => {
        return error?.data?.error?.enum === 'ENTITY_MOVE_NOT_ALLOWED_HOURS';
    },
    handler: function*() {
        yield put(
            showConfirmationModal(
                () => {
                    cleanUpAction();
                },
                `You cannot edit this ${bookingsCount > 1 ? 'bookings' : 'booking'}`,
                bookingsCount > 1 ? multiMoveResizeDatesWarning() : singleMoveResizeDatesWarning(),
                {
                    withEscButton: true,
                    withCancel: false,
                    confirmButtonText: 'Close',
                    cancelActionOnHide: true,
                    onReject: cleanUpAction,
                }
            )
        );
    },
});

export const linkedBookingsMoveRepeated = ({ cleanUpAction }) => ({
    predicate: error => {
        return error?.data?.error?.enum === 'ENTITY_MOVE_NOT_ALLOWED_REPEATED';
    },
    handler: function*(error, action) {
        const moveAll = () =>
            store.dispatch({
                ...action,
                payload: {
                    ...(action.payload || {}),
                    options: {
                        ...(action.payload.options || {}),
                        modifyAllRepeated: true,
                    },
                    callback: () => {
                        dispatchRefreshBookings();
                    },
                },
            });

        const moveThis = () =>
            store.dispatch({
                ...action,
                payload: {
                    ...(action.payload || {}),
                    options: {
                        ...(action.payload.options || {}),
                        modifyAllRepeated: false,
                    },
                    callback: () => {
                        dispatchRefreshBookings();
                    },
                },
            });

        yield put(
            showConfirmationModal(
                () => {
                    moveThis();
                },
                `Booking update warning`,
                'You are trying to move a linked repeating booking. Choose if you would like to update just a linked booking or all bookings.',
                {
                    withEscButton: true,
                    withCancel: true,
                    confirmButtonText: 'Update this',
                    cancelActionOnHide: true,
                    onReject: cleanUpAction,
                    additionalButtons: [
                        {
                            text: 'Update all',
                            onClick: () => {
                                moveAll();
                            },
                        },
                    ],
                }
            )
        );
    },
});

export const linkedBookingsNonMovableBooking = ({ cleanUpAction }) => ({
    predicate: error => {
        return error?.data?.error?.enum === 'ENTITY_MOVE_NOT_ALLOWED';
    },
    handler: function*(error, action) {
        yield put(
            showConfirmationModal(
                () => {
                    store.dispatch({
                        ...action,
                        payload: {
                            ...(action.payload || {}),
                            options: {
                                ...(action.payload.options || {}),
                                moveRelatedRequests: true,
                            },
                        },
                    });
                },
                'Booking update warning',
                `You cannot move all bookings in the dependency chain as some of them are locked due to requests. You can cancel or choose to move only those not locked.`,
                {
                    withEscButton: true,
                    withCancel: true,
                    confirmButtonText: 'Move all other bookings',
                    cancelButtonText: 'Cancel',
                    cancelActionOnHide: true,
                    onReject: cleanUpAction,
                }
            )
        );
    },
});
