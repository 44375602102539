import { PROJECT } from '../../enums/builderTypeEnum';
import React, { useMemo } from 'react';
import { makeGetFilteredCustomFields } from '../../../../selectors/customField';
import { useSelector } from 'react-redux';
import {
    selectAppliedCurrenciesFilter,
    selectAppliedProjectCustomersFilter,
    selectAppliedProjectCustomFieldsFilter,
    selectAppliedProjectManagersFilter,
    selectAppliedProjectsFilter,
    selectAppliedProjectStatusesFilter,
    selectAppliedProjectTagsFilter,
    selectSchedulerCurrenciesFilter,
    selectSchedulerCustomersFilter,
    selectSchedulerProjectCustomFieldsFilter,
    selectSchedulerProjectManagersFilter,
    selectSchedulerProjectsFilter,
    selectSchedulerProjectStatusesFilter,
    selectSchedulerProjectTagsFilter,
} from '../../../../selectors/scheduler';
import {
    AccordionHeader,
    AccordionHeaderText,
    PROJECTS_SIDE_TEXT_RGB_COLOR,
    useCommonDropdownFiltersStyles,
} from './styles';
import { Grid } from '@material-ui/core';
import { FilterItem } from './FilterItem';
import useFilterData from '../../../../shared/builder/filters/hooks/useFilterData';
import { useItemsAndGroups, useRouteParams } from '../../../../hooks';
import { getViewObject } from '../../utils/schedulerUtil';
import { useSchedulerRef } from '../../../../shared/hooks';
import { useAccountPreferences } from '../../hooks';
import { PARENT } from '../../enums/viewModeEnum';
import { arrayToObjectByKey } from '../../../../utils/mappingUtil';
import { filterWithDiacriticsEscape } from 'utils/search';

const ProjectRowFilters = () => {
    const filterData = useFilterData();
    const params = useRouteParams();
    const viewObject = getViewObject(params);
    const { schedulerRef } = useSchedulerRef();
    const { gridPreferences } = useAccountPreferences(schedulerRef, viewObject);

    const classes = useCommonDropdownFiltersStyles({
        sideTextBgColor: PROJECTS_SIDE_TEXT_RGB_COLOR,
    });

    const { items: projects } = useItemsAndGroups(PROJECT.name);

    const projectCustomFieldSelector = useMemo(
        () => makeGetFilteredCustomFields('CHOICES_FILTER_GRID', 'projectCustomFields'),
        []
    );
    const projectCustomFields = useSelector(projectCustomFieldSelector);

    const shouldDisplayProjects =
        viewObject.isGroupView || (viewObject.isSingleResourceView && gridPreferences.mode === PARENT.value);

    const projectsFilterConfig = useMemo(
        () => [
            ...(shouldDisplayProjects
                ? [
                      {
                          id: 1,
                          title: 'Projects',
                          filterName: 'projects',
                          icon: 'FolderOpenOutlined',
                          withSearch: true,
                          withSelectAll: true,
                          items: projects,
                          selectedSelector: selectAppliedProjectsFilter,
                          filterSelector: selectSchedulerProjectsFilter,
                          searchPredicate: (item, filterText) => {
                              return filterWithDiacriticsEscape(item, filterText, 'name');
                          },
                          itemLabelRenderer: item => item.name,
                          getItemValue: item => item._id,
                          deletedItemsMapper: (items, selectedItems) => {
                              const itemsMapped = arrayToObjectByKey(items, '_id');
                              const removed = Object.keys(selectedItems).filter(item => {
                                  return !itemsMapped[item];
                              });
                              return removed.map(item => ({
                                  _id: item,
                                  name: 'Deleted project',
                                  deleted: true,
                              }));
                          },
                      },
                  ]
                : []),
            {
                id: 2,
                title: 'Project custom fields',
                filterName: 'projectCustomFields',
                icon: 'TuneOutlined',
                items: projectCustomFields,
                withSearch: true,
                withSelectAll: true,
                nested: true,
                withRelationToggle: true,
                withEqualityToggle: true,
                selectedSelector: selectAppliedProjectCustomFieldsFilter,
                filterSelector: selectSchedulerProjectCustomFieldsFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'value');
                },
                itemLabelRenderer: item => item.label,
                getItemValue: item => item._id,
                childItemLabelRenderer: item => item.value,
                getChildItemValue: item => item._id,
                childrenSelector: item => item.choices || [],
                deletedItemsMapper: (items, selectedItems) => {
                    if (selectedItems['VIRTUAL_DELETED']) {
                        return [
                            {
                                deleted: true,
                                _id: 'VIRTUAL_DELETED',
                                label: 'Deleted custom fields',
                                choices: Object.values(selectedItems['VIRTUAL_DELETED']).map(choice => ({
                                    _id: choice.value,
                                    value: 'Deleted custom field',
                                })),
                            },
                        ];
                    }

                    return [];
                },
            },
            {
                id: 3,
                title: 'Project tags',
                filterName: 'projectTags',
                icon: 'LabelOutlined',
                items: filterData.projectTags,
                withSearch: true,
                withRelationToggle: true,
                withEqualityToggle: true,
                selectedSelector: selectAppliedProjectTagsFilter,
                filterSelector: selectSchedulerProjectTagsFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'value');
                },
                itemLabelRenderer: item => item.value,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');
                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[typeof item === 'object' ? item.value : item];
                    });

                    return removed.map(item => ({
                        _id: item,
                        value: 'Deleted tag',
                        deleted: true,
                    }));
                },
            },
            {
                id: 4,
                title: 'Clients',
                filterName: 'customers',
                icon: 'PeopleAltOutlined',
                items: filterData.customers,
                withSearch: true,
                withRelationToggle: true,
                withEqualityToggle: true,
                selectedSelector: selectAppliedProjectCustomersFilter,
                filterSelector: selectSchedulerCustomersFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'name');
                },
                itemLabelRenderer: item => item.name,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');
                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[item];
                    });
                    return removed.map(item => ({
                        _id: item,
                        name: 'Deleted customer',
                        deleted: true,
                    }));
                },
            },
            {
                id: 5,
                title: 'Project managers',
                filterName: 'projectManagers',
                icon: 'BuildOutlined',
                items: filterData.projectManagers,
                withSearch: true,
                withRelationToggle: true,
                withEqualityToggle: true,
                selectedSelector: selectAppliedProjectManagersFilter,
                filterSelector: selectSchedulerProjectManagersFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'name');
                },
                itemLabelRenderer: item => item.name,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');

                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[item];
                    });

                    const mappedRemoved = removed.map(item => ({
                        _id: item,
                        name: 'Deleted project manager',
                        deleted: true,
                    }));

                    return mappedRemoved;
                },
            },
            {
                id: 6,
                title: 'Project statuses',
                filterName: 'projectStatuses',
                icon: 'SettingsOutlined',
                withRelationToggle: true,
                withEqualityToggle: true,
                items: filterData.projectStatuses,
                selectedSelector: selectAppliedProjectStatusesFilter,
                filterSelector: selectSchedulerProjectStatusesFilter,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
            {
                id: 7,
                title: 'Currencies',
                filterName: 'currencies',
                icon: 'EuroOutlined',
                withRelationToggle: true,
                withEqualityToggle: true,
                items: filterData.currencies,
                selectedSelector: selectAppliedCurrenciesFilter,
                filterSelector: selectSchedulerCurrenciesFilter,
                itemLabelRenderer: item => item.name,
                getItemValue: item => item.value,
            },
        ],
        [
            filterData.currencies,
            filterData.customers,
            filterData.projectManagers,
            filterData.projectStatuses,
            filterData.projectTags,
            projectCustomFields,
            projects,
            shouldDisplayProjects,
        ]
    );

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.sideText}>Project row filters</div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <AccordionHeader item xs={12}>
                    <AccordionHeaderText>Project row filter</AccordionHeaderText>
                </AccordionHeader>
                <Grid item>
                    {projectsFilterConfig.map(filterItem => (
                        <FilterItem key={filterItem.id} filterItem={filterItem} />
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

const memoized = React.memo(ProjectRowFilters);

export { memoized as ProjectRowFilters };
