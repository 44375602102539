import { DayPilot } from 'daypilot-pro-react';
import moment from 'moment';
import { map, forEach, includes } from 'lodash';
import { TYPE_EVENT } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { findByBEValue } from 'modules/scheduler/enums/scale';
import { SINGLE, LEGACY, PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { isBefore, isSameOrAfter, isSameOrBefore, removeUTCZuluFromDateTimestamp } from 'utils/DateUtil';
import { replaceProjectsColorWithStatusColor } from '../../../shared/lib/projects';
import { store } from '../../../store';
import { selectIsDeadlinesExtensionInstalled } from '../../../selectors/company';
import { selectDeadlineDisplay } from '../../../selectors/account';
import { deadlineDisplayEnum } from '../enums/deadlines';
import { selectCurrentSelectedBooking } from '../../../selectors/scheduler';

const EMPTY_FORMATTED_NOTE = `{"ops":[{"insert":""}]}`;

const isFormattedNoteEmpty = note => {
    if (typeof note !== 'string') {
        return true;
    }

    return (
        note.trim() === '' ||
        note
            .replaceAll(`\\n`, '')
            .replaceAll(`\\t`, '')
            .replace(/\s/g, '') === EMPTY_FORMATTED_NOTE
    );
};

export const getRowId = (booking, viewMode, { isResourceView, isProjectView, isSingleResourceView } = {}) => {
    /**
     * The resource property on the booking is the id of the row and what makes the booking appear in a row.
     * Please note the 'resource' property does not equal the 'resource' in Hub Planner.
     * It is just the prop name given by the DayPilot lib to identify the row.
     * The id can be different depending on what view mode we are in, which is what is calculated below.
     */

    /**
     * viewMode = Controlled by the TV Icon in the scheduler. Can switch between
     * Parent (Grouped)
     * Legacy (Single Rows Resource)
     * Single (Single Rows Project and Resource)
     */

    /**
     * isProjectView = If you are in a single project or a group schedule of a project group.
     * note: isProjectView also applies to Events group schedule and single events.
     * isResourceView = If you are in a single resource or a group schedule of a resource group.
     * note: isResourceView also applies to Unassigned group schedule and single Unassigned.
     */

    /**
     * Unassigned Events = You can add unassigned to the following.
     * Resource Group.
     * Single Project.
     * Project Group you can't add directly but it should inherit the unassigned from projects within the group.
     * You can not add unassigned to a resource. (basically resource on resource does not work)
     */

    /**
     * In Parent/Grouped row there is a dedicated row for handling Events.
     * It is prefixed with `eventsRow_${booking.resource._id}`
     */

    /**
     * If you have removed a resource from a project, the bookings still exist.
     * Some users misunderstand this, therefore we introduced a row to handle the bookings that don't have a row but give context.
     * `removedProjectsRow_${resourceId}`
     */

    const bookingResourceId = booking.resource?._id ?? booking.resourceInfo?._id;

    let rowId = `${bookingResourceId}_${booking.project._id}`;

    if (PARENT.value === viewMode && isProjectView && TYPE_UNASSIGNED.value !== booking.resource.type) {
        rowId = `${booking.project._id}_${bookingResourceId}`;
    }

    if (PARENT.value === viewMode && isResourceView && TYPE_UNASSIGNED.value !== booking.resource.type) {
        rowId = `${bookingResourceId}_${booking.project._id}`;

        const resourceId = booking.resourceInfo?._id || bookingResourceId;
        if (!includes(booking.project.resources, resourceId)) {
            rowId = `removedProjectsRow_${resourceId}`;
        }
    }

    if (LEGACY.value === viewMode) {
        rowId = bookingResourceId;
    }

    if (SINGLE.value === viewMode && isResourceView) {
        rowId = bookingResourceId;
    }

    if (isProjectView && SINGLE.value === viewMode) {
        rowId = booking.project._id;
    }

    if (TYPE_UNASSIGNED.value === booking.resource.type && !isSingleResourceView) {
        rowId = bookingResourceId;
    }

    if (SINGLE.value === viewMode && isProjectView) {
        rowId = booking.project._id;
    }

    if (PARENT.value === viewMode && isResourceView && TYPE_EVENT.value === booking.project.type) {
        if (booking.resource.type === TYPE_UNASSIGNED.value) {
            rowId = `${bookingResourceId}`;
        } else {
            rowId = `eventsRow_${bookingResourceId}`;
        }
    }

    return rowId;
};

export const formatMilestoneToDP = milestone => {
    const { createdDate, updatedDate, ...rest } = milestone;
    return {
        ...rest,
        createdDate: createdDate ? new Date(createdDate) : undefined,
        updatedDate: updatedDate ? new Date(updatedDate) : undefined,
        start: milestone.date,
        end: milestone.date,
        id: milestone._id,
        previousId: milestone.previousId,
        resource: milestone.project,
        resourceId: '',
        htmlRight: milestone.name,
        projectId: milestone.project,
        name: milestone.name,
        scale: {
            display: 'Day',
            name: 'SCALE_DAY',
            view: 'Day',
        },
        milestone: true,
        type: 'Milestone',
        cssClass: 'hub_milestone',
        allDay: true,
        resizeDisabled: true,
        repeat: milestone.repeat,
        backColor: milestone.color,
        repeatId: milestone.repeatId,
        interval: milestone.interval,
        repeatTimes: milestone.repeatTimes,
        linkCreateDisabled: true,
        sort: [4, milestone.date],
    };
};

export const formatPhaseToDP = phase => {
    const { createdDate, updatedDate, ...rest } = phase;
    return {
        ...rest,
        createdDate: createdDate ? new Date(createdDate) : undefined,
        updatedDate: updatedDate ? new Date(updatedDate) : undefined,
        start: removeUTCZuluFromDateTimestamp(phase.start),
        end: removeUTCZuluFromDateTimestamp(phase.end),
        id: phase._id,
        previousId: phase.previousId,
        resource: phase.project,
        projectId: phase.project,
        name: phase.name,
        text: phase.name,
        scale: {
            display: 'Day',
            name: 'SCALE_DAY',
            view: 'Day',
        },
        type: 'REGULAR',
        phase: true,
        allDay: true,
        backColor: phase.color,
        cssClass: 'projectPhase',
        repeat: phase.repeat,
        repeatId: phase.repeatId,
        interval: phase.interval,
        repeatTimes: phase.repeatTimes,
        linkCreateDisabled: true,
        sort: [1, phase.start, phase.end],
    };
};

const formatStartEventToDP = project => {
    const { createdDate, updatedDate, ...rest } = project;
    return {
        ...rest,
        createdDate: createdDate ? new Date(createdDate) : undefined,
        updatedDate: updatedDate ? new Date(updatedDate) : undefined,
        start: project.start,
        end: project.start,
        dateReference: project.end,
        id: 'start_' + project._id,
        resource: project._id,
        resourceId: '',
        projectId: project._id,
        scale: {
            display: 'Day',
            name: 'SCALE_DAY',
            view: 'Day',
        },
        isProjectStartDate: true,
        datesEvent: true,
        bubbleHtml: 'Project Start Date - ' + moment(project.start).format('MMM Do YYYY'),
        type: 'REGULAR',
        allDay: true,
        resizeDisabled: true,
        backColor: project.backgroundColor,
        linkCreateDisabled: true,
        sort: [2],
    };
};

const formatEndEventToDP = project => {
    const { createdDate, updatedDate, ...rest } = project;
    return {
        ...rest,
        createdDate: createdDate ? new Date(createdDate) : undefined,
        updatedDate: updatedDate ? new Date(updatedDate) : undefined,
        start: project.end,
        end: project.end,
        dateReference: project.start,
        id: 'end_' + project._id,
        resource: project._id,
        resourceId: '',
        projectId: project._id,
        scale: {
            display: 'Day',
            name: 'SCALE_DAY',
            view: 'Day',
        },
        isProjectStartDate: false,
        datesEvent: true,
        bubbleHtml: 'Project End Date - ' + moment(project.end).format('MMM Do YYYY'),
        type: 'REGULAR',
        allDay: true,
        resizeDisabled: true,
        backColor: project.backgroundColor,
        linkCreateDisabled: true,
        sort: [3],
    };
};

export const getEventText = (eventTitle, project, resource, { mode, viewObject }) => {
    let eventText = eventTitle && '' !== eventTitle ? `${eventTitle} (${project.name})` : project.name;
    if (mode === SINGLE.value && viewObject?.isProjectView) {
        eventText =
            eventTitle && '' !== eventTitle
                ? `${eventTitle} (${resource.firstName} ${resource.lastName})`
                : `${resource.firstName} ${resource.lastName}`;
    }

    return eventText;
};

export const getDeadlineFromBooking = booking => {
    const { _id, id, isPermitted } = booking;

    if (!booking.deadlineDate || !isPermitted) {
        return null;
    }

    let shouldAddToContainer = true;

    const bookingStart = booking.start.value ? booking.start.value : removeUTCZuluFromDateTimestamp(booking.start);
    const bookingEnd = booking.end.value ? booking.end.value : removeUTCZuluFromDateTimestamp(booking.end);
    const normalizedDeadlinedDate = removeUTCZuluFromDateTimestamp(booking.deadlineDate);

    if (isSameOrAfter(normalizedDeadlinedDate, bookingStart) && isSameOrBefore(normalizedDeadlinedDate, bookingEnd)) {
        shouldAddToContainer = false;
    }

    const deadline = {
        id: `${_id || id}_deadline`,
        cssClass: `hub_deadline`,
        resource: booking.resource,
        booking,
        deadline: true,
        end: booking.deadlineDate,
        start: booking.deadlineDate,
        deadlineText: booking.deadlineName,
        htmlRight: booking.deadlineName,
        allDay: true,
        resizeDisabled: true,
        linkCreateDisabled: true,
        width: 30,
        container: shouldAddToContainer ? _id || id : undefined,
        sort: shouldAddToContainer
            ? undefined
            : [
                  5,
                  booking?.start?.value,
                  booking?.end?.value,
                  booking?.project?._id,
                  booking?.resourceInfo?._id,
                  _id || id,
                  2,
              ],
    };

    if (isBefore(normalizedDeadlinedDate, bookingStart) && shouldAddToContainer) {
        deadline.sort = [
            5,
            booking?.start?.value,
            booking?.end?.value,
            booking?.project?._id,
            booking?.resourceInfo?._id,
            _id || id,
            2,
        ];
    }

    return deadline;
};

export const formatBookingToDP = ({ _id, createdDate, updatedDate, ...rest }, mode, viewObject) => {
    const reduxState = store.getState();
    const isDeadlinesExtensionInstalled = selectIsDeadlinesExtensionInstalled(reduxState);
    const currentSelectedBooking = selectCurrentSelectedBooking(reduxState);
    const deadlineDisplay = selectDeadlineDisplay(reduxState);

    const text =
        rest.private && !rest.permitted
            ? rest.title
            : getEventText(rest.title, rest.project, rest.resource, { mode, viewObject });

    const resource = getRowId(rest, mode, viewObject);

    const start = new DayPilot.Date(rest.start);
    const end = new DayPilot.Date(rest.end);

    const booking = {
        ...rest,
        id: _id,
        _id: _id,
        text: text,
        resourceInfo: rest.resource,
        start,
        end,
        createdDate: createdDate ? new Date(createdDate) : undefined,
        updatedDate: updatedDate ? new Date(updatedDate) : undefined,
        resource,
        scale: findByBEValue(rest.scale),
        hasNotes:
            rest.note &&
            ((typeof rest.note.message === 'string' && rest.note.message.trim() !== '') ||
                !isFormattedNoteEmpty(rest.note.formattedNote)),
        note: rest.note && rest.note.message !== undefined ? rest.note.message : '',
        formattedNote: rest.note && rest.note.formattedNote !== undefined ? rest.note.formattedNote : '',
        linkCreateDisabled: !rest.hasEditRights,
        container: _id,
        sort: [5, start?.value, end?.value, rest?.project?._id, rest?.resource?._id, _id, 1],
    };

    const currentSelectedBookingId = currentSelectedBooking?.deadline
        ? currentSelectedBooking?.id?.replace('_deadline', '')
        : currentSelectedBooking?.id;

    if (
        !isDeadlinesExtensionInstalled ||
        !rest.isPermitted ||
        deadlineDisplay === deadlineDisplayEnum.hide ||
        (deadlineDisplay === deadlineDisplayEnum.showForSelectedBooking && booking.id !== currentSelectedBookingId)
    ) {
        return [booking];
    }

    if (!booking.deadlineDate) {
        return [booking];
    }

    const deadline = getDeadlineFromBooking(booking);

    if (deadline?.container) {
        const bookingStart = booking.start.value ? booking.start.value : removeUTCZuluFromDateTimestamp(booking.start);
        const normalizedDeadlinedDate = removeUTCZuluFromDateTimestamp(booking.deadlineDate);

        if (isBefore(normalizedDeadlinedDate, bookingStart)) {
            booking.sort = undefined;
        }
    }

    return [booking, deadline].filter(Boolean);
};

export const formatBookingsToDP = (bookings, mode, viewObject) => {
    return bookings.reduce((acc, booking) => {
        acc.push(...formatBookingToDP(booking, mode, viewObject));
        return acc;
    }, []);
};

export const formatBookingToBackend = ({ categoryTemplate, project, ...rest }) => ({
    ...rest,
    ...(rest.useProjectColor && project?.backgroundColor
        ? { backColor: project.backgroundColor, backgroundColor: project.backgroundColor }
        : {}),
    start: rest?.start,
    end: rest?.end,
    scale: rest.scale?.BEValue,
    categoryTemplateId: categoryTemplate?._id,
    project: project?._id || project || undefined,
    resource: rest.resourceInfo?._id || rest.resource || undefined,
    resourceInfo: undefined,
    details: undefined,
    bookingCreator: undefined,
    text: undefined,
    id: undefined,
});

export const formatBookingsToBackend = bookings => map(bookings, booking => formatBookingToBackend(booking));

export const formatMilestonesToDP = milestones => map(milestones, milestone => formatMilestoneToDP(milestone));

export const formatPhasesToDP = phases => map(phases, formatPhaseToDP);

export const formatApprovers = approvers =>
    map(approvers, approverOrId => {
        if (approverOrId && typeof approverOrId === 'object') {
            return { id: approverOrId._id };
        }

        return { id: approverOrId };
    });

export const formatStartEndTimesToDP = projects => {
    const startEndTimesEvents = [];
    forEach(projects, project => {
        const transformedProject = replaceProjectsColorWithStatusColor(project);
        startEndTimesEvents.push(formatStartEventToDP(transformedProject));
        startEndTimesEvents.push(formatEndEventToDP(transformedProject));
    });

    return startEndTimesEvents;
};

export const createEventDataFromCopy = copyEvent => ({
    copyFromEventId: copyEvent.id,
    minutesPerDay: copyEvent.minutesPerDay,
    title: copyEvent.title,
    minutesDay: copyEvent.minutesDay,
    totalBucketMinutesAllocation: copyEvent.totalBucketMinutesAllocation,
    percentAllocation: copyEvent.percentAllocation,
    state: copyEvent.state,
    allDay: copyEvent.allDay,
    note: copyEvent.note,
    formattedNote: copyEvent.formattedNote,
    scale: copyEvent.scale.BEValue,
    backgroundColor: copyEvent.backColor,
    tasksTotal: copyEvent.tasksTotal,
    tasksChecked: copyEvent.tasksChecked || 0,
    customFields: copyEvent.customFields,
    categoryName: copyEvent.categoryTemplate.name,
    categoryTemplateId: copyEvent.categoryTemplate._id,
    repeat: copyEvent.repeat,
    repeatId: copyEvent.repeatId,
    interval: copyEvent.interval,
    repeatTimes: copyEvent.repeatTimes,
    useProjectColor: copyEvent.useProjectColor,
});
