import styled from 'styled-components';
import { Button } from 'reactstrap';
import { Form } from 'redux-form';

export const ModalHeader = styled.header`
    h2 {
        line-height: 1.1;
        color: #676767;
        margin-top: 20px;
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }
`;

export const SectionInfo = styled.div`
    border-bottom: 1px dashed #cfcfcf;
`;

export const SectionInfoDesc = styled.p`
    color: #b3b3b3;
    text-align: center;
`;

export const TaskList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0;
`;

export const TaskButton = styled(Button)`
    padding: 3px !important;
    margin: 0;
    width: 20px;
    height: 20px;
    svg {
        width: 13px;
        height: 13px;
        line-height: 11px;
        vertical-align: super;
    }
`;

export const BookingInfo = styled.ul`
    list-style: none;
    padding: 0;
    li {
        &:first-child {
            border-top: none;
        }
    }
`;

export const ExistingResourceRateList = styled.ul`
    list-style: none;
    margin-top: 1rem;
    padding: 0;
`;

export const Toggle = styled.span`
    cursor: pointer;
    display: inline-block;
`;

export const BookingInfoItem = styled.li`
    padding: 10px 0;
    border-top: 1px solid #f3f3f3;
`;

export const NoCustomFieldsTitle = styled.p`
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
`;

export const BugForm = styled(Form)`
    label {
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 0.5rem;
    }
`;

export const ResetColorBtn = styled.button`
    border: 0;
    background: none;
    color: #08c;
    float: right;
    margin-right: 33px;
    &:hover {
        color: #005580;
    }
    &:focus {
        outline: none;
    }
`;
