import styled from 'styled-components';

export const Section = styled.section`
    &.small-form,
    &.medium-form {
        .form-check {
            min-width: 95px;
        }
        .form-group {
            width: 60px;
            margin-bottom: 0;
            margin-right: 0.5rem;
            input {
                height: auto;
                line-height: 20px !important;
            }
        }
    }
    &.medium-form .form-group {
        width: 80px;
    }
`;
