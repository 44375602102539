import { isArray } from 'underscore';

/**
 * If there are some programmatically Filters, use it instead of merging
 *
 * @param objValue // filters
 * @param srcValue // programmaticallyFilters
 *
 * @returns {array}
 */
export const mergeCustomizer = (objValue, srcValue) => {
    if (isArray(objValue) && isArray(srcValue) && !srcValue.length) {
        return objValue.concat(srcValue);
    }

    return srcValue;
};

export const getLabelFromField = (item, fields) => {
    let label = item[fields.label];

    if (fields.additional && item[fields.additional]) {
        if (fields.wrapWithBrackets) {
            label += ` (${item[fields.additional]})`;
        } else {
            label += ` ${item[fields.additional]}`;
        }
    }

    return label;
};

export const arrayToObjectByKey = (array = [], key = 'id') => {
    return array.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, {});
};

export const arrayToObject = (array = []) => {
    return array.reduce((acc, item) => {
        acc[item] = item;
        return acc;
    }, {});
};
