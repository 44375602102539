import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input } from 'reactstrap';

class IndeterminateCheckboxField extends PureComponent {
    static propTypes = {
        input: PropTypes.object.isRequired,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        indeterminate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    };

    static defaultProps = {
        input: {},
        disabled: false,
        className: '',
        indeterminate: false,
    };

    componentDidMount() {
        if (!this.props.indeterminate || 'false' !== this.props.indeterminate) {
            this.inputRef.indeterminate = this.props.indeterminate || 'true' === this.props.indeterminate;
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.indeterminate !== this.props.indeterminate) {
            this.inputRef.indeterminate = this.props.indeterminate;
        }
    }

    render() {
        const { input, className, disabled, ...restOfProps } = this.props;
        delete restOfProps.indeterminate;

        return (
            <Input
                innerRef={ref => (this.inputRef = ref)}
                {...input}
                {...restOfProps}
                type="checkbox"
                id={input.name}
                disabled={disabled}
                name={input.name}
                className={classnames(className)}
            />
        );
    }
}

export default IndeterminateCheckboxField;
