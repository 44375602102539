import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isBefore, isEqual as isDateEqual } from 'date-fns';
import { Form, formValueSelector, reduxForm, initialize } from 'redux-form';
import { useForm } from 'hooks';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, getDefaultValues, mapFormToRequest } from './datesFormSchema';
import { DateToggler } from './../../../../shared/togglers';
import SuccessButton from 'shared/buttons/success';

const FORM_NAME = 'datesForm';
const selector = formValueSelector(FORM_NAME);

function validateDates(startDate, endDate) {
    const startDateTest = new Date(startDate).setHours(0, 0, 0);
    const endDateTest = new Date(endDate).setHours(0, 0, 0);

    return !isBefore(startDateTest, endDateTest) && !isDateEqual(startDateTest, endDateTest);
}

const DatesForm = props => {
    const { project, currentSelection, handleSubmit, submit, updateProject, onClose, invalid } = props;

    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);
    const formValues = useSelector(state => selector(state, 'interval', 'color', 'start', 'end'));

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues(currentSelection)));
    }, [dispatch, currentSelection]);

    const onSubmit = useCallback(
        values => {
            if (project?._id) {
                dispatch(updateProject(project._id, mapFormToRequest(values)));
            }
            onClose && onClose();
        },
        [dispatch, onClose, project, updateProject]
    );

    const isEdit = currentSelection?.id;
    const buttonText = `${isEdit ? 'Update' : 'Save'} dates`;

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <DateToggler start={formValues.start} end={formValues.end} onDateChange={updateFields} />
            <div className="mx-2 mt-2">
                <SuccessButton
                    name={buttonText}
                    disabled={
                        invalid ||
                        !formValues.start ||
                        !formValues.end ||
                        validateDates(formValues.start, formValues.end)
                    }
                    dataCy="button--submit"
                    className="w-100"
                    onClick={submit}
                />
            </div>
        </Form>
    );
};

DatesForm.propTypes = {
    onClose: PropTypes.func,
    project: PropTypes.object.isRequired,
};

DatesForm.defaultProps = {
    onClose: null,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(DatesForm);
