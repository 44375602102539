import { findReportSubTypeByRoute, findReportTypeByRoute } from 'modules/report/enums/reportTypeEnum';
import { valuesArray as resourceStatuses } from 'enums/resourceEnum';
import { valuesArray as projectStatuses } from 'enums/projectEnum';
import { bookingStatusesArray, timeSheetTypesArray } from 'enums/calculationEnum';
import { contains } from 'underscore';
import { concat } from 'lodash';

const ID_LENGTH = 24;
const TOKEN_LENGTH = 24;

export const idUrlParamValidator = (params, key) =>
    params[key] &&
    (params[key].length === ID_LENGTH ||
        contains(concat(resourceStatuses, projectStatuses, bookingStatusesArray, timeSheetTypesArray), params[key]));

export const urlParamValidator = params => {
    let reportType;
    let reportSubType;
    let id;
    let valid = false;

    if (params.reportType) {
        reportType = findReportTypeByRoute(params.reportType) || false;
        valid = !!reportType;
    }
    if (reportType && params.subType) {
        reportSubType = findReportSubTypeByRoute(reportType, params.subType) || false;
        valid = !!reportSubType;
    }

    if (reportType && reportSubType && params.id) {
        id = idUrlParamValidator(params, 'id');
        valid = !!id;
    }

    return !valid && ('customReport' === params.reportType || 'custom' === params.subType) ? true : valid;
};
export const templateTypeUrlParamValidator = params => contains(['internal', 'saved', 'favourites'], params.type);
export const tokenUrlValidator = params => params.token && params.token.length === TOKEN_LENGTH;
