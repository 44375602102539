import { useSelector } from 'react-redux';
import { getPageByValue } from '../enums/startPageEnum';
import { fixLandingPageUrl } from '../utils/landingpage';
import { getProjectGroups } from '../selectors/projectGroup';
import { getResourceGroups } from '../selectors/resourceGroup';

export const useSchedulerLink = () => {
    const { preferences, resourceId, landingPage } = useSelector(state => state.account);
    const systemGroupIds = useSelector(state => state.companyReducer.company.systemGroupIds);
    const resourceGroups = useSelector(getResourceGroups) || [];
    const projectGroups = useSelector(getProjectGroups) || [];

    const hasAccessToGroup = id => {
        return Boolean(resourceGroups.find(group => group._id === id) || projectGroups.find(group => group._id === id));
    };

    const page = getPageByValue(landingPage);
    const mySchedule = `/resource/${resourceId}/group/${preferences.myScheduleGroupId}`;
    const activeResources = `/resource/group/${systemGroupIds.activeResourceGroupId}`;

    if (!page) {
        const groupId = landingPage.split('/')[landingPage.split('/').length - 1];

        if (groupId && groupId === preferences.myScheduleGroupId) {
            return mySchedule;
        }

        if (groupId && hasAccessToGroup(groupId)) {
            return fixLandingPageUrl(landingPage);
        }
    }

    if (hasAccessToGroup(systemGroupIds.activeResourceGroupId)) {
        return activeResources;
    }

    return mySchedule;
};
