import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, TabPane } from 'reactstrap';
import { useHasRights } from 'hooks';
import { chooseField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';

const AdvancedSettingsTab = ({ tabId, projectKeyWord }) => {
    const { hasSettingProjectWd } = useHasRights([
        {
            rights: ['settingProjectWd'],
            rule: 'one',
            name: 'hasSettingProjectWd',
        },
    ]);

    return (
        <TabPane tabId={tabId}>
            {hasSettingProjectWd ? (
                <>
                    <section className="form-fields">
                        <p className="title">Privacy Handling on Bookings:</p>
                        <Field
                            type="checkbox"
                            inline
                            label="Enabled"
                            name="private"
                            value={true}
                            component={chooseField}
                        />
                        <FormText className="mb-2">
                            This setting will anonymize booking data when the booking is loaded in the scheduler for a
                            user with no permissions to this specific {projectKeyWord}. It does not affect bookings that
                            are scheduled on the user themselves or if the user already has permissions to the{' '}
                            {projectKeyWord}.
                        </FormText>
                    </section>
                    <section className="form-fields">
                        <p className="title">Project Booked Time Handling:</p>
                        <Field
                            type="checkbox"
                            inline
                            label="Availability for Reports"
                            name="availabilityReports"
                            value={true}
                            component={chooseField}
                        />
                        <FormText className="mb-2">
                            Time on this {projectKeyWord} is counted towards a Resources Availability, Utilization and Capacity
                        </FormText>
                        <Field
                            type="checkbox"
                            inline
                            label="Availability on Scheduler"
                            name="availabilityScheduler"
                            value={true}
                            component={chooseField}
                        />
                        <FormText>Time on this {projectKeyWord} is counted towards a Resources Availability</FormText>
                    </section>
                </>
            ) : (
                <PermissionDenied />
            )}
        </TabPane>
    );
};

AdvancedSettingsTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projectKeyWord: PropTypes.string,
};

AdvancedSettingsTab.defaultProps = {
    projectKeyWord: 'Project',
};

export default AdvancedSettingsTab;
