import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { createResourceOrVacationRequest } from 'actions/bookingActions';
import { showRequestResourceModal, showRequestVacationModal } from 'actions/modalActions';
import { useRequests } from 'modules/request/hooks';
import { addDaypilotEvent, getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { useRouteParams } from 'hooks';

const RequestItems = () => {
    const dispatch = useDispatch();
    const { canRequestResources, canRequestVacations } = useRequests();
    const params = useRouteParams();
    const viewObject = getViewObject(params);

    const addBookingsInScheduler = useCallback(
        bookings => {
            if (Array.isArray(bookings) && window.schedulerRef?.current?.control) {
                addDaypilotEvent(bookings, window.schedulerRef.current.control, viewObject);
                window.schedulerRef.current.control.clearSelection();
            }
        },
        [viewObject]
    );

    const vacationModal = useCallback(
        () =>
            dispatch(
                showRequestVacationModal({
                    onSubmit: booking => dispatch(createResourceOrVacationRequest(booking, addBookingsInScheduler)),
                })
            ),
        [dispatch, addBookingsInScheduler]
    );

    const requestResourceModal = useCallback(
        () =>
            dispatch(
                showRequestResourceModal({
                    onSubmit: booking => dispatch(createResourceOrVacationRequest(booking, addBookingsInScheduler)),
                })
            ),
        [dispatch, addBookingsInScheduler]
    );

    return (
        <div className="request-sb-btns-wrapper row">
            <div
                data-cy="sidebar-request-resource-button"
                className={classNames('col request-sb-btns approval-side-bar-item', {
                    disabled: !canRequestResources,
                })}
                onClick={canRequestResources ? requestResourceModal : null}
            >
                <span className="fa-stack fa-lg">
                    <i className="fa fa-user-circle-o fa-stack-2x" />
                    <i className="fa fa-plus-circle fa-stack-1x fa-inverse" />
                </span>
                <div className="request-text">request</div>
            </div>

            <div
                data-cy="sidebar-request-vacation-button"
                className={classNames('col request-sb-btns approval-side-bar-item', {
                    disabled: !canRequestVacations,
                })}
                onClick={canRequestVacations ? vacationModal : null}
            >
                <span className="fa-stack fa-lg">
                    <i className="fa fa-suitcase fa-stack-2x" />
                    <i className="fa fa-tag fa-stack-1x fa-inverse" />
                    <i className="fa fa-plus-circle fa-stack-1x " />
                </span>
                <div className="request-text">request</div>
            </div>
        </div>
    );
};

export default RequestItems;
