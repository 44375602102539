import { updateProjectRequest } from 'api/project';
import { formatStartEndTimesToDP } from 'modules/scheduler/utils/eventUtil';
import { updateDaypilotMilestonesPhasesDates } from 'modules/scheduler/utils/schedulerUtil';

export default args => {
    const scheduler = args.control;
    const event = args.e;
    const project = {
        useProjectDuration: true,
    };

    const projectId = event.data.projectId;

    event.data.start = args.newStart;
    event.data.end = args.newEnd;

    if (event.data.isProjectStartDate) {
        project.start = args.newStart.toString('yyyy-MM-dd');
    } else {
        project.end = args.newStart.toString('yyyy-MM-dd');
    }

    updateProjectRequest(projectId, { project }).then(project => {
        updateDaypilotMilestonesPhasesDates(formatStartEndTimesToDP([project]), scheduler);
    });
};
