import React, { useCallback } from 'react';
import { MenuItem } from '@material-ui/core';
import { useNestedMenuItemManager } from './context';
import { withStyles } from '@material-ui/styles';

const WrappedMenuItem = props => {
    const { children } = props;

    const { setCurrentlyOpened } = useNestedMenuItemManager();

    const onMouseEnter = useCallback(() => {
        setCurrentlyOpened(undefined);
    }, [setCurrentlyOpened]);

    return (
        <Item {...props} onMouseEnter={onMouseEnter}>
            {children}
        </Item>
    );
};

const Item = withStyles(() => ({
    root: {
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        borderRadius: 4,
        fontSize: '13px',
        color: props => (props.showDisabledTextState ? '#bababa' : 'inherit'),
    },
}))(MenuItem);

const memoized = React.memo(WrappedMenuItem);

export { memoized as WrappedMenuItem };
