import React from 'react';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    differentTypesHandler,
    displayValue,
    isHeaderDisabled,
    getValueBasedOnEnum,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import { ANONYMIZATION_STRING } from 'constants';
import { statuses as resourceStatuses, roles as resourceRoles, TYPE_UNASSIGNED } from 'enums/resourceEnum';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import Thumbnail from 'shared/thumbnail';
import { contains, isArray } from 'underscore';
import { Header, ThumbnailResourceWrapper, ThumbnailTitle, TextContent } from './../styles';

const flatBooleanValue = row => {
    if (row.value === ANONYMIZATION_STRING) {
        return row.value;
    }
    const flatValue = differentTypesHandler(row.value);

    return row.columnProps && row.columnProps.rest.disabled
        ? row.value
        : flatValue && ('true' === flatValue || true === flatValue)
        ? 'Yes'
        : 'No';
};

/**
 * @param {array}  columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = columnsToLoad => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceName',
        sortable: false,
        accessor: row => displayValue(row.resourceName),
        show: contains(columnsToLoad, 'resourceName'),
        getProps: (state, rowInfo, column) => {
            let defaultStyles = rowInfo && rowInfo.original[column.id] ? { display: 'flex', alignItems: 'left' } : null;

            return getColumnProps(state, rowInfo, column, defaultStyles);
        },
        Cell: row => {
            const firstName = row.original.resourceFirstName;

            return (row.columnProps && row.columnProps.rest.disabled) ||
                isArray(row.value) ||
                isArray(firstName) ||
                !firstName ||
                !row.value ? (
                <TextContent>{differentTypesHandler(row.value, 'Resources')}</TextContent>
            ) : (
                <React.Fragment>
                    <ThumbnailResourceWrapper>
                        <Thumbnail
                            firstName={row.original.resourceFirstName}
                            lastName={row.original.resourceLastName}
                            thumbnailUrl={row.original.resourceThumbUrl}
                            fullName={row.value}
                        />
                        <ThumbnailTitle className="text-container">
                            <span className="title">{row.value}</span>
                            {TYPE_UNASSIGNED.value === row.original.resourceType ? (
                                <span className="subtitle">{TYPE_UNASSIGNED.display.toLowerCase()}</span>
                            ) : null}
                        </ThumbnailTitle>
                    </ThumbnailResourceWrapper>
                </React.Fragment>
            );
        },
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        First Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceFirstName',
        sortable: false,
        accessor: row => displayValue(row.resourceFirstName, differentTypesHandler(row.resourceFirstName, 'Names')),
        show: contains(columnsToLoad, 'resourceFirstName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource/Unassigned Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceUnassignedName',
        sortable: false,
        accessor: row =>
            displayValue(row.resourceUnassignedName, differentTypesHandler(row.resourceUnassignedName, 'Resources')),
        show: contains(columnsToLoad, 'resourceUnassignedName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Last Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceLastName',
        sortable: false,
        accessor: row => displayValue(row.resourceLastName, differentTypesHandler(row.resourceLastName, 'Names')),
        show: contains(columnsToLoad, 'resourceLastName'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        E-mail
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceEmail',
        sortable: false,
        accessor: row => displayValue(row.resourceEmail, differentTypesHandler(row.resourceEmail, 'Emails')),
        show: contains(columnsToLoad, 'resourceEmail'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Is Project Manager
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceIsProjectManager',
        sortable: false,
        accessor: row => displayValue(row.resourceIsProjectManager),
        Cell: flatBooleanValue,
        show: contains(columnsToLoad, 'resourceIsProjectManager'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Approver
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceIsApprover',
        sortable: false,
        accessor: row => displayValue(row.resourceIsApprover),
        Cell: flatBooleanValue,
        show: contains(columnsToLoad, 'resourceIsApprover'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource/Unassigned Tag
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceTag',
        sortable: false,
        accessor: row => displayValue(row.resourceTag, differentTypesHandler(row.resourceTag, 'Tags')),
        show: contains(columnsToLoad, 'resourceTag'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Status
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceStatus',
        sortable: false,
        accessor: row =>
            displayValue(row.resourceStatus, getValueBasedOnEnum(row.resourceStatus, resourceStatuses, 'Statuses')),
        show: contains(columnsToLoad, 'resourceStatus'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Role
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceRole',
        sortable: false,
        accessor: row => displayValue(row.resourceRole, getValueBasedOnEnum(row.resourceRole, resourceRoles, 'Roles')),
        show: contains(columnsToLoad, 'resourceRole'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource/Unassigned Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceNote',
        sortable: false,
        accessor: row => displayValue(row.resourceNote, differentTypesHandler(row.resourceNote, 'Notes')),
        show: contains(columnsToLoad, 'resourceNote'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Groups
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceGroups',
        sortable: false,
        accessor: row => displayValue(row.resourceGroups, differentTypesHandler(row.resourceGroups, 'Groups')),
        show: contains(columnsToLoad, 'resourceGroups'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Calendars
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceCalendar',
        sortable: false,
        accessor: row => displayValue(row.resourceCalendar, differentTypesHandler(row.resourceCalendar, 'Calendars')),
        show: contains(columnsToLoad, 'resourceCalendar'),
    },
];
