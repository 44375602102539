import { isUndefined } from 'lodash';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { CELLDURATION } from '../../../enums/scale';
import {
    isAbleToApproveRejectVacationOrRequest,
    isAbleToEditResourceOrVacationRequest,
} from '../../../../request/utils/permissions';

export const disableEvent = (args, eventHeightMode) => {
    const event = args.e;
    event.moveDisabled = true;
    event.resizeDisabled = true;
    event.doubleClickDisabled = true;
    event.rightClickDisabled = true;
    event.clickDisabled = false;

    if (!args.e.deadline) {
        event.cssClass = 'disabled-event hub-mode-' + eventHeightMode.toLowerCase();

        if (!isUndefined(args.data) && args.data.milestone) {
            event.cssClass = 'disabled-event';
        }

        if (event.type === WAITING_FOR_APPROVAL.value) {
            event.cssClass = 'hub-mode-' + eventHeightMode.toLowerCase();
        }
    } else {
        event.cssClass = event.cssClass + ' disabled-deadline';
    }

    if (args.e.milestone) {
        event.cssClass += ' hub_milestone hub_event_inner';
    }
};

export const disableBookingLogic = ({ args, scheduler, row, currentResourceId, resourceRoleRights, vacationId }) => {
    const isAbleToEditRequest = isAbleToEditResourceOrVacationRequest(
        args.data?.booking || args.data,
        currentResourceId,
        resourceRoleRights,
        vacationId
    );

    const isAbleToApproveReject = isAbleToApproveRejectVacationOrRequest(
        [args.data?.booking || args.data].filter(Boolean),
        currentResourceId,
        resourceRoleRights,
        vacationId
    );

    const bookingData = args.e.deadline ? args.e.booking : args.e;

    if (
        (bookingData.scale.value !== CELLDURATION.value && scheduler.scale === CELLDURATION.value) ||
        (bookingData.type === WAITING_FOR_APPROVAL.value && !isAbleToEditRequest && !isAbleToApproveReject) ||
        (row && row.tags?.isRemoveProjectsRow)
    ) {
        args.e.moveDisabled = true;
        args.e.resizeDisabled = true;
        args.e.doubleClickDisabled = true;
        args.e.rightClickDisabled = row && row.tags.isRemoveProjectsRow;
        args.e.clickDisabled = row && row.tags.isRemoveProjectsRow;
    }

    if (
        (bookingData.type !== WAITING_FOR_APPROVAL.value &&
            bookingData.hasOwnProperty('hasEditRights') &&
            !bookingData.hasEditRights) ||
        bookingData.private ||
        !bookingData.isPermitted
    ) {
        disableEvent(args, scheduler.eventHeightMode);
    } else if (bookingData.type === WAITING_FOR_APPROVAL.value && !isAbleToEditRequest && !isAbleToApproveReject) {
        args.e.moveDisabled = true;
        args.e.resizeDisabled = true;
        args.e.doubleClickDisabled = true;
        args.e.rightClickDisabled = row && row.tags.isRemoveProjectsRow;
        args.e.clickDisabled = row && row.tags.isRemoveProjectsRow;
    }
};
