import React, { useEffect, useMemo, useState } from 'react';
import { sortBy } from 'underscore';
import { teamOverviewCSVColumns } from 'modules/request/utils/csvColumns';
import { isVacationGrid } from 'modules/request/utils/dataFlowUtil';
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { Divider, Layout, Pagination, SearchBar, Table, Typography, useDebouncedValue, Progress } from '@hub-mono/ui';
import { CsvDownloader } from '../requestTable/actionButtons/CSVDownloader';
import PrintButton from '../requestTable/actionButtons/printButton';
import { useSelector } from 'react-redux';
import { selectCompanyAvgDailyCapacity, selectIsToilExtensionInstalled } from '../../../../selectors/company';
import { getNewReportData } from '../../../../api/report';
import { arrayToObjectByKey } from '../../../../utils/mappingUtil';
import { toFixedTrimmed } from '@hub-mono/utils';
import { useAppKeyWords } from '../../../../hooks';
import { monitoring } from '../../../../monitoring';

const getParams = ({ items, start, end }) => {
    return {
        params: {
            from: start,
            to: end,
            calcFutureToilAdjustments: false,
            groupBy: 'resource',
            itemType: 'resource',
            itemIds: items.map(item => item.resourceId),
            itemGroupIds: [],
            displayEmptyResults: true,
            periodType: 'year',
            useCacheOnReportResult: false,
        },
        filters: [
            {
                name: 'resourceStatuses',
                values: [],
            },
        ],
        columnsToLoad: [
            { name: 'resourceToilConsumed' },
            { name: 'resourceToilRequested' },
            {
                name: 'resourceToilAccrued',
            },
            {
                name: 'resourceToilRemaining',
            },
        ],
        sort: {
            columnName: 'name',
            direction: 'asc',
        },
    };
};

const useToilData = ({ end, start, dataStats }) => {
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);
    const [state, setState] = useState({ data: [], dataSorted: [], loading: true });

    useEffect(() => {
        const data = dataStats.toJSON();
        const dataSorted = sortBy(dataStats.toJSON(), 'WAITING_FOR_APPROVAL').reverse();

        setState(prev => ({
            ...prev,
            data: data.map(item => ({
                ...item,
                ACCRUED: item.APPROVED + item.REMAINING,
            })),
            dataSorted: dataSorted.map(item => ({
                ...item,
                ACCRUED: item.APPROVED + item.REMAINING,
            })),
        }));

        if (isToilExtensionInstalled && data.length) {
            setState(prev => ({
                ...prev,
                loading: true,
            }));
            getNewReportData(getParams({ items: data, start, end }), { limit: data.length, page: 0 })
                .then(response => {
                    const mapped = arrayToObjectByKey(response.data, 'itemId');
                    const companyAvgDailyCapacityInMinutes = companyAvgDailyCapacity * 60;

                    setState({
                        data: data.map(item => {
                            return {
                                ...item,
                                ACCRUED: toFixedTrimmed(item.APPROVED + item.REMAINING),
                                ACCRUED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilAccrued || 0) / 60,
                                    2
                                ),
                                ACCRUED_TOTAL: toFixedTrimmed(
                                    item.APPROVED +
                                        item.REMAINING +
                                        Number(mapped[item.resourceId]?.resourceToilAccrued || 0) /
                                            companyAvgDailyCapacityInMinutes,
                                    2
                                ),
                                REQUESTED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilRequested || 0) / 60,
                                    2
                                ),
                                REQUESTED_TOTAL: toFixedTrimmed(
                                    item.WAITING_FOR_APPROVAL +
                                        Number(mapped[item.resourceId]?.resourceToilRequested || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                                CONSUMED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilConsumed || 0) / 60,
                                    2
                                ),
                                CONSUMED_TOTAL: toFixedTrimmed(
                                    item.APPROVED +
                                        Number(mapped[item.resourceId]?.resourceToilConsumed || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                                REMAINING_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilRemaining || 0) / 60,
                                    2
                                ),
                                REMAINING_TOTAL: toFixedTrimmed(
                                    item.REMAINING +
                                        Number(mapped[item.resourceId]?.resourceToilRemaining || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                            };
                        }),
                        dataSorted: dataSorted.map(item => {
                            return {
                                ...item,
                                ACCRUED: toFixedTrimmed(item.APPROVED + item.REMAINING),
                                ACCRUED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilAccrued || 0) / 60,
                                    2
                                ),
                                ACCRUED_TOTAL: toFixedTrimmed(
                                    item.APPROVED +
                                        item.REMAINING +
                                        Number(mapped[item.resourceId]?.resourceToilAccrued || 0) /
                                            companyAvgDailyCapacityInMinutes,
                                    2
                                ),
                                REQUESTED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilRequested || 0) / 60,
                                    2
                                ),
                                REQUESTED_TOTAL: toFixedTrimmed(
                                    item.WAITING_FOR_APPROVAL +
                                        Number(mapped[item.resourceId]?.resourceToilRequested || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                                CONSUMED_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilConsumed || 0) / 60,
                                    2
                                ),
                                CONSUMED_TOTAL: toFixedTrimmed(
                                    item.APPROVED +
                                        Number(mapped[item.resourceId]?.resourceToilConsumed || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                                REMAINING_TOIL: toFixedTrimmed(
                                    Number(mapped[item.resourceId]?.resourceToilRemaining || 0) / 60,
                                    2
                                ),
                                REMAINING_TOTAL: toFixedTrimmed(
                                    item.REMAINING +
                                        Number(mapped[item.resourceId]?.resourceToilRemaining || 0) /
                                            companyAvgDailyCapacityInMinutes
                                ),
                            };
                        }),
                    });
                })
                .catch(error => {
                    monitoring.captureException(error);
                    setState(prev => ({
                        ...prev,
                        loading: false,
                    }));
                });
        } else {
            setState(prev => ({
                ...prev,
                loading: false,
            }));
        }
    }, [companyAvgDailyCapacity, dataStats, end, isToilExtensionInstalled, start]);

    return state;
};

const StyledCard = styled(Card)`
    padding: 24px 8px;

    &.MuiPaper-root {
        background-color: ${props => props.theme.designTokens.color.m3.sys.light['surface'].value};
        border-radius: 16px;
    }
`;

const TableContainer = styled(Layout)`
    overflow-x: auto;
    margin-left: 16px;
    margin-right: 16px;
`;

const StyledTable = styled(Table)`
    td:first-child,
    th:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
    }
    td:first-child {
        background: ${props => props.theme.designTokens.color.m3.sys.light['surface'].value};
    }
    th:first-child {
        background: ${props => props.theme.designTokens.color.m3.sys.light['surface-container-low'].value};
    }
`;

export const TeamOverview = props => {
    const { flow, end, start, dataLoaded } = props;
    const [pageIndex, setPageIndex] = useState(0);
    const [sorting, setSorting] = useState();
    const { value: filterText, setDebouncedValue: setFilterText, debounced: debouncedSearchText } = useDebouncedValue({
        defaultValue: null,
    });
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const { vacationEventName } = useAppKeyWords();

    const { data, dataSorted, loading } = useToilData({ end, start, dataStats: props.data });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize: 10,
        }),
        [pageIndex]
    );

    const paginationExport = useMemo(
        () => ({
            pageIndex,
            pageSize: data.length,
        }),
        [data.length, pageIndex]
    );

    const columns = useMemo(() => {
        return [
            {
                size: 200,
                header: 'Name',
                show: true,
                accessorFn: row => row.resourceName,
                cell: props => (
                    <Typography noWrap variant="label" size="large" prominent>
                        {props.getValue()}
                    </Typography>
                ),
            },
            {
                size: 100,
                header: `Accrued ${vacationEventName}`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow),
                accessorFn: row => row.ACCRUED,
            },
            {
                size: 100,
                header: `Accrued TOIL`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.ACCRUED_TOIL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}hr
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: `Accrued Total`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.ACCRUED_TOTAL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: isVacationGrid(flow) ? `Requested ${vacationEventName}` : `Requested`,
                meta: { className: 'center', headerClassName: 'center' },
                show: true,
                accessorFn: row => row.WAITING_FOR_APPROVAL,
            },
            {
                size: 100,
                header: `Requested TOIL`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.REQUESTED_TOIL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}hr
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: `Requested Total`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.REQUESTED_TOTAL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: isVacationGrid(flow) ? `Consumed ${vacationEventName}` : 'Approved',
                meta: { className: 'center', headerClassName: 'center' },
                show: true,
                accessorFn: row => row.APPROVED,
            },
            {
                size: 100,
                header: `Consumed TOIL`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.CONSUMED_TOIL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}hr
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: `Consumed Total`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.CONSUMED_TOTAL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: `Remaining ${vacationEventName}`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow),
                accessorFn: row => row.REMAINING,
            },
            {
                size: 100,
                header: `Remaining TOIL`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.REMAINING_TOIL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}hr
                        </Typography>
                    );
                },
            },
            {
                size: 100,
                header: `Remaining Total`,
                meta: { className: 'center', headerClassName: 'center' },
                show: isVacationGrid(flow) && isToilExtensionInstalled,
                accessorFn: row => row.REMAINING_TOTAL,
                cell: props => {
                    return (
                        <Typography align="center" noWrap>
                            {props.getValue() ?? '-'}
                        </Typography>
                    );
                },
            },
        ].filter(({ show }) => show);
    }, [vacationEventName, flow, isToilExtensionInstalled]);

    const csvColumns = useMemo(() => {
        return teamOverviewCSVColumns({
            vacationEventName,
            isToilExtensionInstalled,
            isVacation: isVacationGrid(flow),
        });
    }, [flow, isToilExtensionInstalled, vacationEventName]);

    return (
        <StyledCard elevation={0}>
            <Layout stack gap={16}>
                <Layout pr={16} pl={16}>
                    <Typography variant="title" size="large">
                        Team Days Overview
                    </Typography>
                </Layout>
                <Layout
                    pt="--Spacing-200"
                    pr="--Spacing-200"
                    pb="--Spacing-200"
                    pl="--Spacing-200"
                    hAlign="space-between"
                    vAlign="center"
                >
                    <Layout flex="0 0 300px" stack>
                        <SearchBar placeholder="Filter Requests" value={filterText} onChange={setFilterText} />
                    </Layout>
                    <Layout gap="--Spacing-100">
                        <Pagination
                            pageIndex={pageIndex}
                            pageSize={10}
                            itemsAmount={data.length}
                            onChange={setPageIndex}
                        />
                        <div>
                            <Divider type="vertical" />
                        </div>
                        <CsvDownloader
                            columns={csvColumns}
                            data={dataSorted}
                            disabled={!data.length}
                            filename="HUB Planner - Requests"
                        />
                        <PrintButton disabled={!data.length}>
                            <Table
                                data={data}
                                columns={columns}
                                filterText={debouncedSearchText}
                                noDataText="There are no requests for this date range."
                                noFilterDataText="No matching records found"
                                sorting={sorting}
                                rowSelection={{}}
                                pagination={paginationExport}
                            />
                        </PrintButton>
                    </Layout>
                </Layout>

                <TableContainer stack gap="--Spacing-200" pt="--Spacing-200" pb="--Spacing-200">
                    {!dataLoaded || loading ? (
                        <Layout gap="--Spacing-100" vAlign="center">
                            <Typography variant="label" size="small" color="tertiary">
                                Please wait while we finish loading all data...
                            </Typography>
                            <Progress variant="circular" />
                        </Layout>
                    ) : null}
                    {dataLoaded ? (
                        <StyledTable
                            data={data}
                            columns={columns}
                            filterText={debouncedSearchText}
                            noDataText="There are no requests for this date range."
                            noFilterDataText="No matching records found"
                            sorting={sorting}
                            setSorting={setSorting}
                            pagination={pagination}
                        />
                    ) : null}
                </TableContainer>
            </Layout>
        </StyledCard>
    );
};
