import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, RadioGroup, FormControlLabel, Radio, Switch } from '@material-ui/core';
import { Section, SectionHeader } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const AvailabilityDisplayOptions = ({ availabilityDisplay, availabilityHover, onChange }) => (
    <Section>
        <SectionHeader>Availability status over a booking bar</SectionHeader>

        <FormControl component="fieldset" className="ml-1 mt-2">
            <RadioGroup
                aria-label="availabilityDisplay"
                name="availabilityDisplay"
                value={availabilityDisplay}
                onChange={event => onChange({ availabilityDisplay: event.target.value })}
            >
                <FormControlLabel
                    value="TYPE_NOTIFICATION"
                    control={<GreenRadio dataCy="my-pref-radio-bar" />}
                    className="mb-2"
                    label="Display an availability color bar"
                />
                {availabilityDisplay === 'TYPE_NOTIFICATION' && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={availabilityHover}
                                onChange={event => onChange({ availabilityHover: event.target.checked })}
                                name="availabilityHover"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                data-cy="btn-switch-availabilityHover"
                            />
                        }
                        label="Display availability on cell hover"
                    />
                )}
                <FormControlLabel
                    value="TYPE_AVAILABILITY"
                    control={<GreenRadio dataCy="my-pref-radio-numbers" />}
                    className="mb-2"
                    label="Always on - availability color bar"
                />
                <FormControlLabel
                    value="TYPE_OFF"
                    control={<GreenRadio dataCy="my-pref-radio-disable" />}
                    className="mb-2"
                    label="Don’t show availability over a booking "
                />
            </RadioGroup>
        </FormControl>
    </Section>
);

AvailabilityDisplayOptions.propTypes = {
    availabilityDisplay: PropTypes.string.isRequired,
    availabilityHover: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default AvailabilityDisplayOptions;
