import React from 'react';
import { Field } from 'redux-form';
import { renderCheckbox } from './formFields';

const subscribeCheckboxInputProps = {
    'data-cy': 'new-feature-updates-email-checkbox',
};

export const SubscribeCheckbox = ({ withoutDescription }) => (
    <>
        <Field
            component={renderCheckbox}
            name="newFeatureEmailAgreed"
            label="New Feature Updates Email"
            color="primary"
            inputProps={subscribeCheckboxInputProps}
        />
        {!withoutDescription && (
            <div className="text-muted small mt-n3 ml-4 pl-2">
                We would like to keep you up to date on new features being developed in Hub Planner by sending you an
                email now and then. You can disable this later at anytime you wish.
            </div>
        )}
    </>
);

export const htmlLabelConfirmCheckbox = (
    <>
        I agree to the{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://hubplanner.com/terms-of-service">
            Terms of Service
        </a>{' '}
        and{' '}
        <a target="_blank" rel="noopener noreferrer" href="https://hubplanner.com/privacy-policy">
            Privacy Policy
        </a>
    </>
);

const confirmCheckboxInputProps = {
    'data-cy': 'tos-agreement-checkbox',
};

export const ConfirmCheckbox = () => (
    <Field
        component={renderCheckbox}
        name="confirm"
        label={htmlLabelConfirmCheckbox}
        color="primary"
        inputProps={confirmCheckboxInputProps}
    />
);
