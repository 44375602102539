import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getTags = {
    request: type => action(actionTypes.GET_TAGS['REQUEST'], { type }),
    success: (tags, type) => action(actionTypes.GET_TAGS['SUCCESS'], { tags, type }),
};

export const createTag = {
    request: (data, type, formName, fieldName) =>
        action(actionTypes.CREATE_TAG['REQUEST'], {
            data,
            type,
            formName,
            fieldName,
        }),
    success: (tag, type) => action(actionTypes.CREATE_TAG['SUCCESS'], { tag, type }),
};
