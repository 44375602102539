import styled from 'styled-components';
import { Button as MuiButton } from '@material-ui/core';

export const MainWrapper = styled.div`
    right: 40px;
    top: 14px;
    font-size: 13px;
    position: relative;
`;

export const DatePickerWrapper = styled.div`
    position: absolute;
    top: 24px;
    right: 0;
    transform: translateX(50%);
    background-color: #fff;
    padding: 25px;
    box-shadow: 0 12px 40px 5px rgba(0, 0, 0, 0.26);
    z-index: 999;
    border-radius: 8px;
`;

export const Button = styled(MuiButton)`
    &.filter-active {
        &:after {
            content: ' ';
            background-color: #0288b0;
            border-radius: 50%;
            width: 9px;
            height: 9px;
            font-size: 11px;
            position: absolute;
            top: calc(50% - 5px);
            right: 15px;
        }
    }
`;
