const moment = require('moment');

const capitalize = str => str && str[0].toUpperCase() + str.slice(1);
const updateDates = (type, durationObject, durationType) => {
    const startDate = moment();
    const duration = moment.duration(durationObject);
    const periodType = durationType.replace('iso', '').toLowerCase();

    switch (type.trim().toLowerCase()) {
        case 'last':
        case 'sub':
            return {
                startDate: startDate
                    .subtract(duration)
                    .startOf(durationType)
                    .toDate(),
                endDate: startDate.endOf(durationType).toDate(),
                dateState: 'Last',
                periodType,
            };
        case 'next':
        case 'add':
            return {
                endDate: startDate
                    .add(duration)
                    .endOf(durationType)
                    .toDate(),
                startDate: startDate.startOf(durationType).toDate(),
                dateState: 'Next',
                periodType,
            };
        case 'this':
            return {
                startDate: startDate.startOf(durationType).toDate(),
                endDate: startDate.endOf(durationType).toDate(),
                dateState: capitalize(type),
                periodType,
            };
    }
    return null;
};

module.exports = {
    updateDates,
};
