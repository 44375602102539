import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { hideModal } from 'actions/modalActions';
import ConfirmDialog from 'shared/confirmButton/confirmDialog';

export const useUnsavedChangesDialog = (modalHasUnsavedChanges, save) => {
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    const dispatch = useDispatch();

    const closeConfirmDialog = () => {
        dispatch(hideModal());
        setShowConfirmDialog(false);
    };

    const onConfirm = () => {
        save();
        closeConfirmDialog();
    };

    const openDialog = () => {
        if (modalHasUnsavedChanges()) {
            setShowConfirmDialog(true);
        } else {
            dispatch(hideModal());
        }
    };

    const dialog = (
        <ConfirmDialog
            title="You have unsaved changes"
            message="What do you want to do?"
            onConfirm={onConfirm}
            onReject={closeConfirmDialog}
            show={showConfirmDialog}
            backgroundColor="#C82333"
            confirmButtonText="Save and Close"
            cancelButtonText="Close without saving"
        />
    );

    return [dialog, openDialog];
};

export default useUnsavedChangesDialog;
