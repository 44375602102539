import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Settings, Close, FolderOpen } from '@material-ui/icons';

export const Wrapper = styled.div`
    color: #fff;
    a {
        display: flex;
        height: 50px;
        line-height: 50px;
        margin: 0;
        &:hover {
            background-color: #191918;
            text-decoration: none;
        }
    }
    svg {
        margin: auto 0px auto 16px;
    }
    span {
        margin-left: 10px;
    }
`;

export const Separator = styled.div`
    padding: 16px;
    font-size: 12px;
    color: #6b6b6b;
`;

export const Item = styled(Link)`
    cursor: pointer;
    &:hover {
        text-decoration: none;
        & + svg,
        + div button svg {
            display: inline-block !important;
        }
    }
    border: none;
`;

export const SettingIcon = styled(Settings)`
    position: absolute;
    font-size: 20px !important;
    right: 15px;
    top: 15px;
    color: rgba(255, 255, 255, 0.41);
    cursor: pointer;
    z-index: 9999;
    display: none !important;
    &:hover {
        display: inline-block !important;
        color: rgba(255, 255, 255, 0.81);
    }
`;

export const CloseIcon = styled(Close)`
    color: rgba(255, 255, 255, 0.41);
    display: none !important;
    &:hover {
        color: rgba(255, 255, 255, 0.81);
        cursor: pointer;
    }
`;

export const GroupItemWrapper = styled.div`
    position: relative;

    .group-context-menu {
        right: 40px;
        position: absolute;
        opacity: 0;
        top: 50%;
        margin-top: -12px;
        width: 20px;
        text-align: center;
    }

    &:hover {
        .group-context-menu {
            color: #fff;
            opacity: 1;
            cursor: pointer;
        }
    }
`;

export const Label = styled.span`
    width: calc(100% - 67px);
    display: inline-block;
    vertical-align: middle;

    &.subgroups {
        width: calc(100% - 95px);
    }
`;

export const SubGroupIcon = styled(FolderOpen)`
    font-size: 14px !important;
    color: rgba(255, 255, 255, 0.26);
    margin-left: 2px;
`;
