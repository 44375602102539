import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap';
import { useProjectAction } from 'hooks';
import { createProject as createProjectRequest } from 'actions/projectActions';
import { useAppKeyWords } from 'hooks/useAppKeyWords';
import { showProjectModal } from 'actions/modalActions';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { CELLDURATION, findByValue } from 'modules/scheduler/enums/scale';
import { formatDate } from 'utils/DateUtil';
import SuccessButton from 'shared/buttons/success';
import { Details } from './styles';
import { useStopPropagationOnKeyDown } from '../../../../../../../hooks/useStopPropagationOnKeyDown';

const NewProject = React.forwardRef((props, schedulerRef) => {
    const { createBooking, currentSelection, selectedResource, currentScale, onClose } = props;

    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const { createProject } = useProjectAction(schedulerRef);
    const { projectKeyWord } = useAppKeyWords();

    const bookingDefaultState = useSelector(state => state.companyReducer.company.settings.grid.bookingDefaultState);
    const projectModal = useCallback(() => {
        dispatch(
            showProjectModal({
                onSubmit: data => createProject(data, true),
                type: TYPE_REGULAR.value,
                projectId: null,
                initialData: { name },
            })
        );
        if (onClose) {
            onClose();
        }
    }, [createProject, dispatch, name, onClose]);

    const onSchedule = useCallback(() => {
        dispatch(
            createProjectRequest.request(
                {
                    project: { name },
                    options: {
                        skipCustomFieldsValidation: true,
                    },
                },
                project => {
                    const bookingTempValues = {
                        start: formatDate(currentSelection.start, 'yyyy-MM-dd HH:mm', false),
                        end: formatDate(currentSelection.end, 'yyyy-MM-dd HH:mm', false),
                        resource: selectedResource._id,
                        scale: findByValue(currentScale).BEValue,
                        state: bookingDefaultState,
                        allDay: currentScale !== CELLDURATION.value,
                        project: project._id,
                    };
                    createBooking(bookingTempValues);
                }
            )
        );
    }, [dispatch, createBooking, selectedResource, currentScale, currentSelection, bookingDefaultState, name]);

    const handleOnKeyDown = useStopPropagationOnKeyDown();

    return (
        <div className="p-2">
            <Input
                onKeyDown={handleOnKeyDown}
                placeholder={`New ${projectKeyWord.toLowerCase()} name`}
                value={name}
                className="mb-2"
                onChange={event => setName(event.target.value)}
            />
            <SuccessButton
                name={`Schedule new ${projectKeyWord.toLowerCase()}`}
                disabled={!name.length}
                dataCy="button--add-category"
                onClick={onSchedule}
                className="full-width"
            />
            <Details color="link" onClick={projectModal}>
                add more details
            </Details>
        </div>
    );
});

NewProject.propTypes = {
    createBooking: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    currentSelection: PropTypes.shape({
        start: PropTypes.object,
        end: PropTypes.object,
    }).isRequired,
    selectedResource: PropTypes.object.isRequired,
    currentScale: PropTypes.string.isRequired,
};

export default NewProject;
