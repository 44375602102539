import React, { useCallback, memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { DROPDOWN_WIDTH } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectSchedulerFilterRelation } from '../../../../selectors/scheduler';
import { setSchedulerFilterRelation } from '../../../../actions/schedulerActions';

import { OperatorToggle } from './OperatorToggle';
import { BookingRowFilters } from './BookingRowFilters';
import { ProjectRowFilters } from './ProjectRowFilters';
import { ResourcesRowFilters } from './ResourceRowFilter';
import { DropdownFiltersContextProvider } from './DropdownFiltersContext';
import { getViewObject } from '../../utils/schedulerUtil';
import { useRouteParams, useWindowDimension } from '../../../../hooks';
import { useAccountPreferences } from '../../hooks';
import { useSchedulerRef } from '../../../../shared/hooks';
import { LEGACY, PARENT, SINGLE } from '../../enums/viewModeEnum';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Scrollbar from '../../../../shared/scrollbar';

export const SearchDropdownFilters = memo(() => {
    const params = useRouteParams();
    const viewObject = getViewObject(params);
    const dispatch = useDispatch();
    const classes = useStyles();
    const windowDimension = useWindowDimension();

    const { schedulerRef } = useSchedulerRef();
    const { gridPreferences } = useAccountPreferences(schedulerRef, viewObject);

    const relation = useSelector(selectSchedulerFilterRelation);

    const handleRelationChange = useCallback(
        newValue => {
            dispatch(setSchedulerFilterRelation(newValue));
        },
        [dispatch]
    );

    const displayProjectFilters =
        (viewObject.isProjectView && gridPreferences.mode !== LEGACY.value) ||
        (viewObject.isResourceView && gridPreferences.mode === PARENT.value);

    const displayResourceFilters =
        (viewObject.isProjectView && gridPreferences.mode !== SINGLE.value) || viewObject.isResourceView;

        return (
        <DropdownFiltersContextProvider>
            <div className={classes.root}>
                <Header container justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <HeaderText>Filter by:</HeaderText>
                    </Grid>
                    <Grid item>
                        <OperatorToggle
                            popoverId="search-dropdown-filters"
                            value={relation}
                            onChange={handleRelationChange}
                        />
                    </Grid>
                </Header>
                <div className={classes.filtersContainer}>
                    <Scrollbar autoHide autoHeight autoHeightMax={windowDimension.windowHeight - 270}>
                        {viewObject.isProjectView ? (
                            <>
                                {displayProjectFilters ? <ProjectRowFilters /> : null}
                                {displayResourceFilters ? <ResourcesRowFilters /> : null}
                            </>
                        ) : (
                            <>
                                {displayResourceFilters ? <ResourcesRowFilters /> : null}
                                {displayProjectFilters ? <ProjectRowFilters /> : null}
                            </>
                        )}
                        <BookingRowFilters />
                    </Scrollbar>
                </div>
            </div>
        </DropdownFiltersContextProvider>
    );
});

const useStyles = makeStyles({
    root: {
        width: `${DROPDOWN_WIDTH}px`,
        display: `flex`,
        flexDirection: `column`,
    },
    filtersContainer: {
        marginRight: `10px`,
    },
});

const Header = withStyles({
    root: {
        height: '70px',
        padding: '16px 32px 16px 32px',
    },
})(Grid);

const HeaderText = withStyles({
    root: {
        fontSize: '17px',
        lineHeight: '26px',
        fontWeight: '400',
    },
})(Typography);
