import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Add, FileCopy } from '@material-ui/icons';
import { showScheduleResourceModal } from 'actions/modalActions';
import { Wrapper, Item } from './styles';
import { useBooking } from 'modules/scheduler/hooks';

const SelectedRange = () => {
    const dispatch = useDispatch();
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const { pasteBooking, createBooking } = useBooking();

    const bookingModal = useCallback(
        () =>
            dispatch(
                showScheduleResourceModal({ onSubmit: data => createBooking(data.bookings, true, data.tasks || []) })
            ),
        [dispatch, createBooking]
    );

    return (
        <Wrapper>
            <Item onClick={bookingModal} title="Add Booking">
                <Add />
            </Item>
            <Item onClick={clipboard ? pasteBooking : null} title="Paste Booking">
                <FileCopy />
            </Item>
        </Wrapper>
    );
};

export default SelectedRange;
