import { transform, isObject, isEqual, keys } from 'lodash';

export const objectDiff = (object, base) => {
    const changes = (object, base) =>
        transform(object, (result, value, key) => {
            if (!isEqual(value, base[key])) {
                result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });

    return changes(object, base);
};

export const isObjectEqual = (object, base) => 0 === keys(objectDiff(object, base)).length;
