/*
    IMPORTANT
    All code in this file MUST be documented
*/

import Schema from 'form-schema-validation';
import { isObjectMatchingSchema, checkObjectWithSchema } from 'shared/lib/data-validation';
import { generateObjectWithKeysAsNull } from 'shared/lib/object';
import { getISODate } from 'shared/lib/date';
import { eachDayOfInterval, parseISO, set, getDay, differenceInMinutes, addDays } from 'date-fns';
import { store } from '../../store';
import { max, min } from 'lodash';

const bookingAllocationValuesSchema = new Schema(
    {
        percentAllocation: {
            type: Number,
            required: true,
        },
        minutesPerDay: {
            type: Number,
            required: true,
        },
        totalBucketMinutesAllocation: {
            type: Number,
            required: true,
        },
    },
    false,
    false
);

/**
 * Given a booking object with at least 3 keys, returns an allocation object
 * @param {{percentAllocation: number, minutesPerDay: number, totalBucketMinutesAllocation: number }} booking object
 * @returns {{percentage: number, hours: number, total: number }} object
 */
export const getBookingAllocationValues = (booking = {}) => {
    if (isObjectMatchingSchema(booking, bookingAllocationValuesSchema)) {
        const { percentAllocation, minutesPerDay, totalBucketMinutesAllocation } = booking;
        return {
            percentage: percentAllocation,
            hours: minutesPerDay / 60,
            total: totalBucketMinutesAllocation / 60,
        };
    } else {
        return generateObjectWithKeysAsNull(['percentage', 'hours', 'total']);
    }
};

const getBookingAllocationValuesFromSingleValueParameterSchema = new Schema(
    {
        value: {
            type: Number,
            required: true,
        },
        nameOfValue: {
            type: String,
            required: true,
        },
        avgDailyCapacity: {
            type: Number,
            required: true,
        },
        numberOfWorkDays: {
            type: Number,
            required: true,
        },
        totalBookingMinutes: {
            type: Schema.optionalType(Number),
            required: false,
        },
    },
    false,
    false
);

export const getMinutesBetweenStartEnd = (start, end) => {
    return (end.getTime() - start.getTime()) / 1000 / 60;
};

/**
 * Computes allocation values based on work days schedules. Useful especially for cell hour duration scale
 * @param {{ start, end }} currentSelection start end dates object
 * @returns allocation object
 */
export const getAllocationStateForSelection = currentSelection => {
    const companySettings = store.getState().companyReducer.company.settings;
    let startEndDays = [];
    if (eachDayOfInterval(currentSelection).length === 1) {
        startEndDays = [
            {
                start: currentSelection.start,
                end: currentSelection.end,
            },
        ];
    } else {
        startEndDays = splitStartEndByDays(currentSelection.start, currentSelection.end);
    }

    const workStartEndTimesByDayNumber = getWorkStartEndTimesByDayNumber();

    startEndDays.forEach((dayStartEnd, index) => {
        startEndDays[index].durationMinutes = getMinutesBetweenStartEnd(dayStartEnd.start, dayStartEnd.end);

        const dayWorkStartEndTimes = workStartEndTimesByDayNumber[getDay(dayStartEnd.start)];
        startEndDays[index].dayCapacityMinutes =
            dayWorkStartEndTimes.endDay * 60 +
            dayWorkStartEndTimes.endMinute -
            (dayWorkStartEndTimes.startDay * 60 + dayWorkStartEndTimes.startMinute);
    });

    const totalDurationMinutes = startEndDays.reduce((acc, cv) => {
        return acc + cv.durationMinutes;
    }, 0);
    const totalCapacityMinutes = startEndDays.reduce((acc, cv) => {
        return acc + cv.dayCapacityMinutes;
    }, 0);

    const hours = totalDurationMinutes / 60 / startEndDays.length;
    const total = totalDurationMinutes / 60;
    const alloc = {
        state: companySettings.grid.bookingDefaultState,
        values: {
            percentage: totalDurationMinutes / totalCapacityMinutes,
            hours,
            total,
        },
    };

    return alloc;
};

/**
 * This function takes a single property and its value and some dependencies (avgDailyCapacity, numberOfWorkDays) and returns a computed allocation object
 * @param {{value: number, nameOfValue: 'percentage' | 'hours' | 'total', avgDailyCapacity: number, numberOfWorkDays: number, totalBookingMinutes: number}} dataObject
 * @returns {{ percentage: number, hours: number, total: number }} allocation object
 */
export const getBookingAllocationValuesFromSingleValue = dataObject => {
    checkObjectWithSchema(dataObject, getBookingAllocationValuesFromSingleValueParameterSchema);
    const { value, nameOfValue, avgDailyCapacity, numberOfWorkDays, totalBookingMinutes } = dataObject;

    const bookingAllocationValues = {
        [nameOfValue]: parseFloat(typeof value === 'string' ? value : value.toFixed(2)),
    };

    let totalHours, avgHoursPerDay;

    if (Number.isFinite(totalBookingMinutes)) {
        totalHours = totalBookingMinutes / 60;
        // 0/0 is NaN, so handle this. https://hubplanner.atlassian.net/browse/HUB-8374
        avgHoursPerDay = totalHours / numberOfWorkDays || 0;
    } else {
        avgHoursPerDay = avgDailyCapacity;
        totalHours = avgHoursPerDay * numberOfWorkDays;
    }

    switch (nameOfValue) {
        case 'percentage':
            bookingAllocationValues.hours = parseFloat((avgHoursPerDay * (value / 100)).toFixed(2));
            bookingAllocationValues.total = parseFloat((totalHours * (value / 100)).toFixed(2));
            break;
        case 'hours':
            bookingAllocationValues.percentage =
                numberOfWorkDays === 0 ? 100 : parseFloat(((value / avgHoursPerDay || 0) * 100).toFixed(2));
            bookingAllocationValues.total =
                numberOfWorkDays === 0
                    ? bookingAllocationValues[nameOfValue]
                    : parseFloat((value * numberOfWorkDays).toFixed(2));
            break;
        case 'total':
            bookingAllocationValues.percentage =
                numberOfWorkDays === 0 ? 100 : parseFloat(((value / totalHours || 0) * 100).toFixed(2));
            bookingAllocationValues.hours =
                numberOfWorkDays === 0
                    ? bookingAllocationValues[nameOfValue]
                    : parseFloat((value / numberOfWorkDays || 0).toFixed(2));
            break;
    }

    return bookingAllocationValues;
};

/**
 * Get Array of start end work times by day number. Sunday to Saturday.
 * @returns Array of start end work times by day number
 */
export const getWorkStartEndTimesByDayNumber = () => {
    const startEndTimes = store.getState().companyReducer.startEndTimes;
    const startEndTimesByDay = store.getState().companyReducer.company?.settings?.weekDays || {};
    // Week starts on Sunday.
    return ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(dayName => {
        const settingForDay = startEndTimesByDay[dayName];
        const startDay = settingForDay.intervals?.length ? min(settingForDay.intervals.map(el => el.start)) / 60 : 0;
        const endDay = settingForDay.intervals?.length ? max(settingForDay.intervals.map(el => el.end)) / 60 : 0;
        return {
            ...startEndTimes,
            ...settingForDay,
            ...{
                startDay,
                endDay,
                startMinute: startDay % 1 ? 30 : 0,
                endMinute: endDay % 1 ? 30 : 0,
            },
        };
    });
};

/**
 * Returns an array of objects with start end times key values depending on that workday schedule
 * @param {Date} start start date
 * @param {Date} end end dates
 * @returns An array of objects with start end times key values depending on that workday schedule
 */
export const splitStartEndByDays = (start, end) => {
    const workStartEndTimesByDayNumber = getWorkStartEndTimesByDayNumber();
    const dates = eachDayOfInterval({ start, end });

    if (dates.length === 1) {
        return [
            {
                start,
                end,
            },
        ];
    }

    return dates.map((date, index) => {
        const indexDayOfWeek = getDay(date);
        const specificDayWorkStartEndTimes = workStartEndTimesByDayNumber[indexDayOfWeek];
        const { startDay, endDay, startMinute, endMinute } = specificDayWorkStartEndTimes;

        if (index === 0) {
            // First day
            date = set(date, {
                hours: endDay,
                minutes: endMinute,
            });
            return {
                start,
                end: date,
            };
        } else if (index === dates.length - 1) {
            // last day
            date = set(date, {
                hours: startDay,
                minutes: startMinute,
            });
            return {
                start: date,
                end,
            };
        } else {
            // days in the middle
            let dateEnd = new Date(date);
            date = set(date, {
                hours: startDay,
                minutes: startMinute,
            });
            dateEnd = set(dateEnd, {
                hours: endDay,
                minutes: endMinute,
            });
            return {
                start: date,
                end: dateEnd,
            };
        }
    });
};

/**
 * Given a booking, reads the interval days and splits into multiple bookings for each day to the start working hour and end working hour
 * @param {*} booking booking mapped already object
 * @returns Array of new bookings
 */
export const splitBookingIntoMultipleByDays = booking => {
    if (!booking.start || !booking.end) {
        return [booking];
    }

    let bookingStart, bookingEnd;
    if (typeof booking.start !== 'string' || typeof booking.end !== 'string') {
        // it's a DP Date object
        bookingStart = booking.start.value;
        bookingEnd = booking.end.value;
    } else {
        bookingStart = booking.start;
        bookingEnd = booking.end;
    }

    const startBookingDate = bookingStart.split('T')[0];
    const endBookingDate = bookingEnd.split('T')[0];
    if (startBookingDate !== endBookingDate) {
        return splitStartEndByDays(parseISO(bookingStart), parseISO(bookingEnd)).map(date => {
            const { start, end } = date;
            return Object.assign({}, booking, {
                start: getISODate(start, {
                    computeTimezone: true,
                }),
                end: getISODate(end, {
                    computeTimezone: true,
                }),
            });
        });
    }
    return [booking];
};

/**
 *
 * @param {Date} endDate
 * @returns a new Date based on workdays which can end a booking end date.
 */
export const findNewWorkDayEndDate = endDate => {
    const indexDayOfCurrentEndDate = getDay(endDate);
    const weekWorkStartEndTimes = getWorkStartEndTimesByDayNumber();

    const workEndDate = set(endDate, {
        hours: weekWorkStartEndTimes[indexDayOfCurrentEndDate].endDay,
        minutes: weekWorkStartEndTimes[indexDayOfCurrentEndDate].endMinute,
    });

    const minutesDifference = differenceInMinutes(endDate, workEndDate);
    if (minutesDifference > 0) {
        const followingDaysIndexes = new Array(7).fill(null).map((n, index) => {
            return (indexDayOfCurrentEndDate + index + 1) % 7;
        });

        let daysToAddUntilNextWorkDay = 0;
        const nextWorkDayIndex = followingDaysIndexes.find((dayIndex, index) => {
            if (weekWorkStartEndTimes[dayIndex].workDay) {
                daysToAddUntilNextWorkDay = index + 1;
                return true;
            }
            return false;
        });

        if (!nextWorkDayIndex) {
            throw new Error('No workdays found');
        }

        const totalMinutes =
            weekWorkStartEndTimes[nextWorkDayIndex].startDay * 60 +
            weekWorkStartEndTimes[nextWorkDayIndex].startMinute +
            minutesDifference;

        const hoursToSet = parseInt(totalMinutes / 60);
        const minutesToSet = totalMinutes % 60;
        const newWorkDayEndDate = set(addDays(endDate, daysToAddUntilNextWorkDay), {
            hours: hoursToSet,
            minutes: minutesToSet,
        });

        // The number of hours to add can exceed the end hour of the current work day found
        return findNewWorkDayEndDate(newWorkDayEndDate);
    } else {
        return endDate;
    }
};
