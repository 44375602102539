import {
    hideContextMenu,
    showCustomColumnContextMenu,
    showEditBookingContextMenu,
    showEditDeadlineContextMenu,
    showEditParentContextMenu,
    showMenuRowContextMenu,
    showScheduleContextMenu,
} from 'actions/contextMenuActions';
import { getAllCustomFieldsV2 } from 'actions/customFieldActions';
import {
    showConfirmationModal,
    showEditRequestResourceModal,
    showInfoModal,
    showScheduleResourceModal,
} from 'actions/modalActions';
import { setSchedulerSelection, updateBookingSelection } from 'actions/schedulerActions';
import { SCHEDULER_FOOTER_FIXED_HEIGHT } from 'constants';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { CUSTOM_COLUMNS, CUSTOM_FIELDS, PM } from 'enums/extensionShortIdEnum';
import { useAppKeyWords, useIsExtensionInstalled, useWindowDimension } from 'hooks';
import { filter, includes } from 'lodash';
import { useRequests } from 'modules/request/hooks/useRequests';
import daypilotConfiguration from 'modules/scheduler/config/daypilot';
import * as events from 'modules/scheduler/config/events';
import { LEGACY } from 'modules/scheduler/enums/viewModeEnum';
import { useAccountPreferences, useBooking, usePhases, useScheduleRights } from 'modules/scheduler/hooks';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import { getCustomColumnsType, getFormattedColumnsToDp } from 'modules/scheduler/utils/customColumns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeGetFilteredCustomFields } from 'selectors/customField';
import { makeGetTags } from 'selectors/tag';
import { isActive } from 'utils/extensionUtil';
import { hasEventRights, hasOneOfRoles, hasRole } from 'utils/rightsUtil';
import {
    selectCurrentSelectedBooking,
    selectSchedulerInitialized,
    selectSchedulerIsDPResourcesLoading,
} from '../../../selectors/scheduler';
import { store } from '../../../store';
import { findByValue } from '../enums/scale';
import schedulerUpdateObserver from '../utils/schedulerUpdateObserver';
import { eventsForRangeCache } from '../../../utils/eventsForRangeCache';
import { updateResourceOrVacationRequest } from 'actions/requestActions';
import { getLoggedInId } from 'selectors/account';
import { contextMenuLink, onLinkCreate } from '../config/events/bookingLinks';
import {
    selectCompanyDeadlinesMoveWithBookingConfig,
    selectIsDeadlinesExtensionInstalled,
    selectIsDependencyExtensionInstalled,
    selectMeanWorkdayDuration,
} from '../../../selectors/company';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { useRerenderHack } from '../../../hooks/useRerenderHack';
import { TYPE_UNASSIGNED } from '../../../enums/resourceEnum';

export const useDaypilotConfig = ({ schedulerRef, viewObject, viewData, resourcesUpdateRef }, customActions) => {
    const dispatch = useDispatch();
    const { rerender } = useRerenderHack();
    const keyWords = useAppKeyWords();
    const params = useParams();
    const isCFExtensionActive = useIsExtensionInstalled(CUSTOM_FIELDS);
    const { windowHeight } = useWindowDimension();
    const customFieldSelector = useMemo(() => makeGetFilteredCustomFields(), []);
    const tagsSelector = useMemo(() => makeGetTags(), []);
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const currentResourceId = useSelector(getLoggedInId);
    const { vacationId } = useSelector(state => state.companyReducer.company.settings);
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const tags = useSelector(tagsSelector);
    const customFields = useSelector(customFieldSelector);
    const {
        bookingStatuses,
        bookingCategories,
        bookingProjectsEvents,
        bookingResourcesUW,
        bookingProjectStatuses,
    } = useSelector(state => state.scheduler.filters);
    const { canRequestVacations, canRequestResources } = useRequests();
    const { updatePhase, deletePhase } = usePhases(schedulerRef);
    const { updateMilestone, deleteMilestone } = useMilestones(schedulerRef);
    const isLoading = useSelector(selectSchedulerIsDPResourcesLoading);
    const schedulerInitialized = useSelector(selectSchedulerInitialized);
    const isDependencyExtensionInstalled = useSelector(selectIsDependencyExtensionInstalled);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const companyDeadlineMoveConfig = useSelector(selectCompanyDeadlinesMoveWithBookingConfig);
    const shouldMoveDeadline = companyDeadlineMoveConfig === 'ENABLED';
    const { modals } = useSelector(state => state.modalReducer);

    const {
        updateClipboard,
        updateBooking,
        updateBookingOnResize,
        deleteBooking,
        deleteBookingFromModal,
        duplicateBooking,
        pasteBooking,
    } = useBooking(currentSelectedBooking);
    const isCustomColumnsExtensionActive = useIsExtensionInstalled(CUSTOM_COLUMNS);
    const {
        updateSchedulerColumns,
        gridPreferences: { mode },
    } = useAccountPreferences(schedulerRef, viewObject);
    const account = useSelector(state => state.account);
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);
    const { settings: companySettings, extensions: companyExtensions, calendars, systemGroupIds } = useSelector(
        state => state.companyReducer.company
    );
    const {
        resourceRoleRights,
        isProjectManager,
        resourceId,
        preferences: { grid: gridPreferences, schedulerColumns },
        schedulerUpdate,
    } = account;

    const customColumnsType = getCustomColumnsType(gridPreferences.mode, viewObject);
    const { scheduleRightsFn, hasScheduleRightsForResourceAndProject } = useScheduleRights();
    const canViewMilestonesOrPhases = hasOneOfRoles(resourceRoleRights, ['viewMilestones', 'viewPhases']);
    const canViewProjectDates = hasRole(resourceRoleRights, 'viewStartEndDates');
    const hasRightToSeeFinancialData = hasRole(resourceRoleRights, 'settingProjectBudget');
    const setSchedulerSelectionRequest = useCallback(
        (startDate, endDate, rowTags) => {
            return dispatch(setSchedulerSelection.request(startDate, endDate, rowTags));
        },
        [dispatch]
    );
    const showEditContextMenu = useCallback(
        (schedulerRef, coords) => dispatch(showEditBookingContextMenu(schedulerRef, coords)),
        [dispatch]
    );
    const editParentContextMenu = useCallback(
        (schedulerRef, coords) => dispatch(showEditParentContextMenu(schedulerRef, coords)),
        [dispatch]
    );

    const editDeadlineContextMenu = useCallback(
        (schedulerRef, coords) => dispatch(showEditDeadlineContextMenu(schedulerRef, coords)),
        [dispatch]
    );

    const menuRowContextMenu = useCallback(
        (coords, type, additionalParams) => dispatch(showMenuRowContextMenu(coords, type, additionalParams)),
        [dispatch]
    );
    const customColumnContextMenu = useCallback(
        (coords, column, schedulerRef) => dispatch(showCustomColumnContextMenu(coords, column, schedulerRef)),
        [dispatch]
    );
    const removeContextMenu = useCallback(() => dispatch(hideContextMenu()), [dispatch]);

    const showScheduleBookingContextMenu = useCallback(
        (cellId, schedulerRef) => dispatch(showScheduleContextMenu(cellId, schedulerRef, viewObject, viewData)),
        [dispatch, viewObject, viewData]
    );
    const confirmationModal = useCallback(
        (onConfirm, title, message, additional) =>
            dispatch(showConfirmationModal(onConfirm, title, message, additional)),
        [dispatch]
    );

    const updateBookingSelectionRequest = useCallback(booking => dispatch(updateBookingSelection.request(booking)), [
        dispatch,
    ]);
    const rowHeaderColumns = useMemo(
        () =>
            getFormattedColumnsToDp(customColumnsType, schedulerColumns, isCustomColumnsExtensionActive, {
                tags,
                customFields,
            }),
        [customColumnsType, schedulerColumns, isCustomColumnsExtensionActive, tags, customFields]
    );
    const meanWorkDayDuration = useSelector(selectMeanWorkdayDuration);

    const deferredUpdate = useRef({});
    const accountPrefUpdate = useRef({});

    const [config] = useState({
        ...daypilotConfiguration(gridPreferences, schedulerColumns, companySettings, viewObject, {
            ...companyStartEndTimes,
            slotMinutes: companySettings.slotMinutes,
            meanWorkDayDuration,
        }),
        onEventSelect: args => {
            const scheduler = args.e.calendar;
            scheduler.clearSelection();
        },
        customColumnsType,
        rowHeaderColumns,
        onLinkCreate: onLinkCreate({ dispatch, schedulerRef }),
        contextMenuLink: contextMenuLink({ dispatch, schedulerRef }),
        onTimeRangeSelected: events.onTimeRangeSelected({
            removeContextMenu,
            schedulerRef,
            setSchedulerSelectionRequest,
            updateBookingSelectionRequest,
        }),
        onTimeRangeClicked: args => {
            updateBookingSelectionRequest(null);
            removeContextMenu();
            events.onTimeRangeClicked(showScheduleBookingContextMenu, schedulerRef)(args);
        },
        onTimeRangeRightClicked: args => {
            updateBookingSelectionRequest(null);
            removeContextMenu();
            const cell = schedulerRef.current.control.cells.find(args.start, args.resource);
            setSchedulerSelectionRequest(args.start, args.end, cell.first().properties.tags);
            events.onTimeRangeClicked(showScheduleBookingContextMenu, schedulerRef)(args);
        },
        onAutoRefresh: events.onAutoRefresh(
            viewObject,
            schedulerRef,
            canViewMilestonesOrPhases,
            canViewProjectDates,
            dispatch
        ),
        onBeforeCellRender: events.onBeforeCellRender(companySettings, calendars, viewObject),
        onCellMouseOver: events.onCellMouseOver(viewObject),
        onCellMouseOut: events.onCellMouseOut(viewObject),
        onEventMove: events.onEventMove({
            resourceRoleRights,
            resourceId,
            keyWords,
            companyWeekdays: companySettings.weekDays,
            showInfoModal: (title, description) =>
                dispatch(
                    showConfirmationModal(
                        () => {
                            // do nothing
                        },
                        title,
                        description,
                        { confirmButtonText: 'Close', withCancel: false, withEscButton: true }
                    )
                ),
            hasScheduleRights: scheduleRightsFn,
            viewObject,
            isDependencyExtensionInstalled,
        }),
        onEventMoved: events.onEventMoved(companySettings.weekDays, viewObject, isDependencyExtensionInstalled, {
            updatePhase,
            updateMilestone,
        }),
        onEventMoving: events.onEventMoving({
            isDependencyExtensionInstalled,
            isDeadlinesExtensionInstalled,
            shouldMoveDeadline,
        }),
        onEventDoubleClicked: args => {
            document.activeElement?.blur();
            removeContextMenu();
            setSchedulerSelectionRequest();

            if (args.e.data.type === 'WAITING_FOR_APPROVAL') {
                dispatch(
                    showEditRequestResourceModal(data => {
                        dispatch(updateResourceOrVacationRequest(data));
                    }, args.e.data)
                );

                return;
            }

            const canSchedule = hasScheduleRightsForResourceAndProject(
                args.e.data?.project?._id,
                args.e.data?.resourceInfo?._id ?? args.e.data?.resource,
                args.e.data?.resourceInfo?.type === TYPE_UNASSIGNED.value
            );

            if (!canSchedule) {
                return;
            }

            if (!args.e.data.phase && !args.e.data.milestone && !args.e.data.datesEvent && !args.e.data.deadline) {
                updateBookingSelectionRequest(args.e.data);
                dispatch(
                    showScheduleResourceModal({
                        onSubmit: updateBooking,
                        activeTab: undefined,
                        bookingId: args.e.data.id,
                        onDelete: deleteBookingFromModal,
                    })
                );
            }
        },
        onEventClicked: args => {
            document.activeElement?.blur();

            removeContextMenu();
            setSchedulerSelectionRequest();

            return events.onEventClicked(resourceRoleRights, updateBookingSelectionRequest, {
                editParentContextMenu,
                showEditContextMenu,
            })(args);
        },
        onEventRightClick: args => {
            setSchedulerSelectionRequest();
            removeContextMenu();

            return events.onEventRightClick({
                resourceRoleRights,
                showEditContextMenu,
                editParentContextMenu,
                editDeadlineContextMenu,
                updateBookingSelection: updateBookingSelectionRequest,
                hasScheduleRightsForResourceAndProject,
                accountResourceId: account.resourceId,
                vacationId,
            })(args);
        },
        onTimeHeaderClick: () => {
            dispatch(hideContextMenu());
        },
        onBeforeCellExport: events.onBeforeCellExport,
        onBeforeEventExport: events.onBeforeEventExport,
        onBeforeRowHeaderExport: events.onBeforeRowHeaderExport,
        onBeforeTimeHeaderRender: events.onBeforeTimeHeaderRender(companySettings.weekStartDay, schedulerRef),
        onIncludeTimeCell: events.onIncludeTimeCell(companySettings, schedulerRef),
        onResourceHeaderClick: events.onResourceHeaderClick(menuRowContextMenu),
        onBeforeRowHeaderRender: events.onBeforeRowHeaderRender(resourceRoleRights),
        onRowHeaderColumnResized: events.onRowHeaderColumnResized(updateSchedulerColumns, schedulerRef),
        onBeforeRowHeaderColumnRender: events.onBeforeRowHeaderColumnRender(customColumnContextMenu, schedulerRef),
        onEventResized: events.onEventResized(
            confirmationModal,
            (title, description) => dispatch(showInfoModal(title, description)),
            account,
            companyStartEndTimes,
            updateBookingOnResize,
            updatePhase
        ),
        onRowFilter: events.onRowFilter,
        onResourceExpand: events.onResourceExpand(
            { keyWords, accountRoleRights: resourceRoleRights, viewObject },
            customActions
        ),
        onAfterCellRender: args => {
            // Add data-cy attribute
            if (args.div) {
                args.div.dataset.cy = `cell-[res=${args.cell.resource}]-[start=${args.cell.start}]-[end=${args.cell.end}]`;
            }
        },
        onAfterEventRender: args => {
            // Add data-cy attribute only on events rendered
            args.div.dataset.cy = `cell-event-[res=${args.e.data.resource}]-[title=${args.e.data.text ||
                args.e.data.deadlineText}]-[start=${args.e.data.start}]-[end=${args.e.data.end}]`;
        },
        onAfterRender: (...args) => {
            schedulerUpdateObserver.notifyOnce(args);
        },
        onAfterUpdate: () => {
            eventsForRangeCache.clear();
            const scheduler = window.schedulerRef?.current?.control;
            // Fixes: On day cell, if hideNonWorkingDays true, jumping in the future
            if (scheduler && !scheduler.initialRendered) {
                const scaleFound = findByValue(scheduler.scale);
                if (scaleFound) {
                    const weekStartDay = store.getState()?.companyReducer?.company?.settings?.weekStartDay || 1;
                    typeof scaleFound?.startDate === 'function' &&
                        scheduler.scrollTo(scaleFound.startDate(weekStartDay));
                    scheduler.initialRendered = true;
                }
            }

            if (scheduler?.mode === 'PARENT') {
                const { isGroupView, isSingleProjectView, isSingleResourceView } = viewObject;
                if (isGroupView || isSingleProjectView || isSingleResourceView) {
                    const rows = scheduler?.rows?.all();
                    // Grouped rows (PARENT mode) have always first row Unasigned.
                    // Expand the second element
                    if (rows.length === 2) {
                        const state = store.getState();

                        const itemId = rows[1].tags.project?._id || rows[1].tags.resourceInfo?._id;
                        const dpResource = state.scheduler?.dpResources?.find(dpResource => dpResource._id === itemId);

                        if (rows[1] && !dpResource?.wasLoaded) {
                            rows[1].expand();
                        }
                    }
                }
            }
        },
    });

    const handleKeyboardShortcuts = useCallback(
        event => {
            const key = event.keyCode ? event.keyCode : event.which;
            if (currentSelectedBooking.id) {
                const isSelectedRegularBooking =
                    !currentSelectedBooking.phase &&
                    !currentSelectedBooking.milestone &&
                    !currentSelectedBooking.datesEvent &&
                    !currentSelectedBooking.deadline;

                if (currentSelectedBooking.type !== WAITING_FOR_APPROVAL.value) {
                    const canSchedule = hasScheduleRightsForResourceAndProject(
                        currentSelectedBooking.project?._id,
                        currentSelectedBooking.resourceInfo?._id ?? currentSelectedBooking.resource,
                        currentSelectedBooking.resourceInfo?.type === TYPE_UNASSIGNED.value
                    );

                    if (!canSchedule) {
                        return;
                    }

                    // KEYBOARD COPY
                    if (67 === key && (event.metaKey || event.ctrlKey) && isSelectedRegularBooking) {
                        updateClipboard();
                    }
                    // KEYBOARD CUT
                    if (88 === key && (event.metaKey || event.ctrlKey) && isSelectedRegularBooking) {
                        updateClipboard(true);
                    }
                    // KEYBOARD DUPLICATE
                    if (68 === key && (event.metaKey || event.ctrlKey) && isSelectedRegularBooking) {
                        event.preventDefault();
                        duplicateBooking();
                    }
                }

                // KEYBOARD DELETE
                // allow delete even if it is waiting for approval (requested)
                if ((8 === key || 46 === key) && (event.metaKey || event.ctrlKey)) {
                    if (currentSelectedBooking.phase) {
                        deletePhase();
                    } else if (currentSelectedBooking.milestone) {
                        deleteMilestone();
                    } else {
                        deleteBooking();
                    }
                }
            }

            // KEYBOARD PASTE
            if (clipboard && currentSelection.start && 86 === key && (event.metaKey || event.ctrlKey)) {
                const isClipboardRegularBooking =
                    !clipboard.phase && !clipboard.milestone && !clipboard.datesEvent && !clipboard.deadline;

                if (isClipboardRegularBooking && modals.length === 0) {
                    pasteBooking(event);
                }
            }
        },
        [
            clipboard,
            currentSelection,
            pasteBooking,
            deleteBooking,
            duplicateBooking,
            updateClipboard,
            deletePhase,
            deleteMilestone,
            currentSelectedBooking,
            modals,
            hasScheduleRightsForResourceAndProject
        ]
    );

    // Menu functions are set in initial config state and not updated later, so if you
    // want to update them in daypilot you need that useEffect to do that
    useEffect(() => {
        const options = {
            onTimeRangeClicked: args => {
                updateBookingSelectionRequest(null);
                removeContextMenu();
                events.onTimeRangeClicked(showScheduleBookingContextMenu, schedulerRef)(args);
            },
            onTimeRangeRightClicked: args => {
                updateBookingSelectionRequest(null);
                removeContextMenu();
                const cell = schedulerRef.current.control.cells.find(args.start, args.resource);
                setSchedulerSelectionRequest(args.start, args.end, cell.first().properties.tags);
                events.onTimeRangeClicked(showScheduleBookingContextMenu, schedulerRef)(args);
            },
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        // eslint-disable-next-line
    }, [showScheduleBookingContextMenu, updateBookingSelectionRequest]);

    const daysSettingsCallback = useCallback(() => {
        const currentScale = gridPreferences.defaultScale ? gridPreferences.defaultScale : 'Day';
        const scale = findByValue(currentScale);

        const days = scale.daysByMultiplier(gridPreferences.cellWidth[currentScale], meanWorkDayDuration);
        const infiniteScrollingStepDays = scale.scrollingStepDays(
            gridPreferences.cellWidth[currentScale],
            meanWorkDayDuration
        );

        const options = {
            days,
            infiniteScrollingStepDays,
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        rerender();
    }, [gridPreferences.cellWidth, gridPreferences.defaultScale, meanWorkDayDuration, rerender]);

    useEffect(() => {
        daysSettingsCallback();
    }, [daysSettingsCallback]);

    useWindowResize(daysSettingsCallback);

    useEffect(() => {
        const options = {
            onEventResizing: () => {
                removeContextMenu();
            },
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [removeContextMenu]);

    useEffect(() => {
        const shouldUseAvailabilityDisplay =
            (viewObject.isResourceView || (viewObject.isProjectView && gridPreferences.mode === LEGACY.value)) &&
            ['TYPE_NOTIFICATION', 'TYPE_AVAILABILITY'].includes(gridPreferences.availabilityDisplay);

        const options = {
            rowMinHeight: gridPreferences.eventHeight + (shouldUseAvailabilityDisplay ? 4 : 14),
            rowMarginTop:
                (viewObject.isProjectView && gridPreferences.mode !== LEGACY.value) || !shouldUseAvailabilityDisplay
                    ? 0
                    : 20,
            linkBottomMargin: gridPreferences.eventHeight / 2,
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [
        gridPreferences.availabilityDisplay,
        gridPreferences.eventHeight,
        gridPreferences.mode,
        viewObject.isProjectView,
        viewObject.isResourceView,
    ]);

    useEffect(() => {
        const options = {
            onEventResized: events.onEventResized(
                confirmationModal,
                (title, description) => dispatch(showInfoModal(title, description)),
                account,
                companyStartEndTimes,
                updateBookingOnResize,
                updatePhase,
                resourceRoleRights,
                vacationId
            ),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [
        account,
        companyStartEndTimes,
        confirmationModal,
        dispatch,
        schedulerRef,
        showScheduleBookingContextMenu,
        updateBookingOnResize,
        updatePhase,
        vacationId,
        resourceRoleRights,
    ]);

    useEffect(() => {
        const options = {
            onEventMoved: events.onEventMoved(companySettings.weekDays, viewObject, isDependencyExtensionInstalled, {
                updatePhase,
                updateMilestone,
            }),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [companySettings.weekDays, isDependencyExtensionInstalled, updateMilestone, updatePhase, viewObject]);

    useEffect(() => {
        const options = {
            onEventMoving: args => {
                removeContextMenu();
                return events.onEventMoving({
                    isDependencyExtensionInstalled,
                    isDeadlinesExtensionInstalled,
                    shouldMoveDeadline,
                })(args);
            },
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [isDeadlinesExtensionInstalled, isDependencyExtensionInstalled, removeContextMenu, shouldMoveDeadline]);

    const onScrollCache = useRef({ rowIds: {}, currentStartDate: null, currentFilters: {} });

    useEffect(() => {
        const eventRights = hasEventRights({ resourceId, resourceRoleRights, isProjectManager }, companyExtensions);
        const isPmExtensionActive = isActive(companyExtensions, PM);
        const isCustomFieldExtensionActive = isActive(companyExtensions, CUSTOM_FIELDS);

        const options = {
            onScroll: events.onScroll(
                viewObject,
                schedulerRef,
                canViewMilestonesOrPhases,
                canViewProjectDates,
                {
                    groupId: params.resourceGroupId ?? params.projectGroupId,
                    entityId: params.resourceId ?? params.projectId,
                    bookingCategories,
                    bookingStatuses,
                    bookingProjectsEvents,
                    bookingResourcesUW,
                    bookingProjectStatuses,
                    schedulerViewProjectIds:
                        viewObject.isSingleProjectView && params.projectId && mode === LEGACY.value
                            ? [params.projectId]
                            : undefined,
                },
                mode,
                onScrollCache,
                dispatch
            ),
            onBeforeEventRender: events.onBeforeEventRender(
                schedulerRef,
                resourceRoleRights,
                companySettings,
                keyWords,
                params.projectId,
                viewObject,
                eventRights,
                vacationId,
                currentResourceId,
                hasScheduleRightsForResourceAndProject
            ),
            onBeforeRowHeaderDomAdd: events.onBeforeRowHeaderDomAdd(
                companySettings.grid,
                { isPmExtensionActive, isCustomFieldExtensionActive },
                systemGroupIds
            ),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        // eslint-disable-next-line
    }, [
        viewObject,
        canViewMilestonesOrPhases,
        canViewProjectDates,
        resourceRoleRights,
        companySettings,
        companyExtensions,
        keyWords,
        systemGroupIds,
        resourceId,
        isProjectManager,
        params,
        bookingCategories,
        bookingStatuses,
        onScrollCache,
        mode,
        dispatch,
        showEditContextMenu,
    ]);

    useEffect(() => {
        if (!windowHeight) {
            return;
        }

        const amountRows =
            hasRightToSeeFinancialData &&
            viewObject.isProjectView &&
            (gridPreferences.singleProjectView.displayScheduledColumn ||
                gridPreferences.singleProjectView.displayReportedColumn) &&
            gridPreferences.singleProjectView.informationPanelDisplayed
                ? filter(
                      gridPreferences.singleProjectView,
                      (item, keyName) =>
                          true === item &&
                          -1 !== keyName.toLowerCase().indexOf('row') &&
                          (viewObject.isSingleProjectView ||
                              !includes(
                                  ['displayScheduledReportedBudgetCashRow', 'displayScheduledReportedBudgetHoursRow'],
                                  keyName
                              )) // filter out budget rows on group view
                  ).length
                : 0;

        const options = {
            height:
                windowHeight -
                260 -
                (gridPreferences.displayWeeks ? 36 : 0) -
                (gridPreferences.schedulerFooterVisible ? SCHEDULER_FOOTER_FIXED_HEIGHT : 0) -
                (gridPreferences.filterVisible && hasRole(resourceRoleRights, 'gridFilter') ? 48 : 0) -
                amountRows * 115,
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        // eslint-disable-next-line
    }, [
        windowHeight,
        gridPreferences.singleProjectView,
        gridPreferences.filterVisible,
        gridPreferences.schedulerFooterVisible,
        gridPreferences.displayWeeks,
        viewObject.isProjectView,
        viewObject.isSingleProjectView,
        hasRightToSeeFinancialData,
    ]);

    useEffect(() => {
        if (!isCFExtensionActive) {
            return;
        }
        dispatch(getAllCustomFieldsV2.request());
    }, [dispatch, isCFExtensionActive]);

    useEffect(() => {
        deferredUpdate.current = {
            ...deferredUpdate.current,
            rowHeaderColumns,
        };
        // eslint-disable-next-line
    }, [rowHeaderColumns]);
    useEffect(() => {
        const options = {
            onTimeRangeSelecting: events.onTimeRangeSelecting(
                scheduleRightsFn,
                canRequestVacations,
                canRequestResources,
                resourceRoleRights
            ),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        // eslint-disable-next-line
    }, [scheduleRightsFn, canRequestVacations, canRequestResources, resourceRoleRights]);

    useEffect(() => {
        const options = {
            onEventMove: events.onEventMove({
                resourceRoleRights,
                resourceId,
                keyWords,
                companyWeekdays: companySettings.weekDays,
                showInfoModal: (title, description) =>
                    dispatch(
                        showConfirmationModal(
                            () => {
                                // do nothing
                            },
                            title,
                            description,
                            { confirmButtonText: 'Close', withCancel: false, withEscButton: true }
                        )
                    ),
                hasScheduleRights: scheduleRightsFn,
                viewObject,
                isDependencyExtensionInstalled,
            }),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
        // eslint-disable-next-line
    }, [scheduleRightsFn, keyWords, companySettings.weekDays, viewObject, resourceRoleRights, resourceId]);

    useEffect(() => {
        const options = {
            onResourceExpand: events.onResourceExpand(
                {
                    keyWords,
                    accountRoleRights: resourceRoleRights,
                    viewObject,
                },
                customActions
            ),
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };

        // eslint-disable-next-line
    }, [viewObject, keyWords, params.projectId, params.projectGroupId]);

    useEffect(() => {
        const options = {
            linkCreateHandling: isDependencyExtensionInstalled ? 'Update' : 'Disabled',
        };

        deferredUpdate.current = {
            ...deferredUpdate.current,
            ...options,
        };
    }, [isDependencyExtensionInstalled]);

    useEffect(() => {
        accountPrefUpdate.current = {
            ...schedulerUpdate,
        };
    }, [schedulerUpdate]);

    useEffect(() => {
        const shouldUpdate = schedulerInitialized && !isLoading;

        const hasConfigUpdate = Object.keys(deferredUpdate.current).length;
        const hasResourcesUpdate = Object.keys(resourcesUpdateRef.current).length;
        const hasAccountPrefUpdate = Object.keys(accountPrefUpdate.current).length;

        if (shouldUpdate && (hasConfigUpdate || hasResourcesUpdate || hasAccountPrefUpdate)) {
            if (hasResourcesUpdate && !hasConfigUpdate && !hasAccountPrefUpdate) {
                const schedulerRows = schedulerRef.current.control.rows;

                const newResources = new Map();

                const loopNewResources = items => {
                    items.forEach(item => {
                        const { id, tags, children = [] } = item;
                        const { resource, project } = tags;
                        newResources.set(id, {
                            updatedDate: resource?.updatedDate ?? project?.updatedDate,
                            rowItem: resource ?? project,
                        });

                        if (children.length) {
                            loopNewResources(children);
                        }
                    });
                };

                loopNewResources(resourcesUpdateRef.current.resources ?? []);

                const inScheduler = new Map();

                schedulerRows.all().each(row => {
                    const { id, tags } = row;
                    const { resource, project } = tags;

                    inScheduler.set(id, {
                        updatedDate: resource?.updatedDate ?? project?.updatedDate,
                        rowItem: resource ?? project,
                    });
                });

                const toRemove = [],
                    toAdd = [],
                    toUpdate = [];

                inScheduler.forEach((mapElement, id) => {
                    if (!newResources.has(id)) {
                        toRemove.push(id);
                    } else {
                        const newResource = newResources.get(id);

                        if (mapElement.updatedDate !== newResource.updatedDate) {
                            toUpdate.push(id);
                        }
                    }
                });

                newResources.forEach((mapElement, id) => {
                    if (!inScheduler.has(id)) {
                        toAdd.push(id);
                    }
                });

                if (toRemove.length === 1 && toUpdate.length === 0 && toAdd.length === 0) {
                    // for now and testing purpose we will cover HUB-8251
                    // case when user removes just one row from scheduler
                    // eventually we will be able to cover cases with direct rows add/update/remove instead of
                    // passing whole new list each time
                    const [toRemoveId] = toRemove;
                    const rowToRemove = schedulerRows.find(dPResource => {
                        return dPResource.id === toRemoveId;
                    });

                    rowToRemove?.remove();
                } else {
                    schedulerRef.current.control.update({
                        ...deferredUpdate.current,
                        ...resourcesUpdateRef.current,
                        ...accountPrefUpdate.current,
                    });
                }
            } else {
                schedulerRef.current.control.update({
                    ...deferredUpdate.current,
                    ...resourcesUpdateRef.current,
                    ...accountPrefUpdate.current,
                });
            }

            accountPrefUpdate.current = {};
            deferredUpdate.current = {};
            resourcesUpdateRef.current = {};
        }
    });

    useEffect(() => {
        if (currentSelectedBooking.id || (clipboard && currentSelection.start)) {
            document.addEventListener('keydown', handleKeyboardShortcuts, true);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyboardShortcuts, true);
        };
    }, [handleKeyboardShortcuts, currentSelectedBooking, clipboard, currentSelection]);

    return config;
};
