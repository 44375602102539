import React from 'react';
import PropTypes from 'prop-types';
import { SmallInfoTile } from '@hub-mono/ui';

const SickDays = ({ sickDays, sickDaysId }) => {
    return (
        <SmallInfoTile
            title={sickDaysId ? `Sick Days taken out this year` : `Sick Days taken out this year (event not set)`}
            value={sickDays ?? 0}
            text={sickDays && sickDays > 5 ? `` : 'You’re so healthy!'}
        />
    );
};

SickDays.propTypes = {
    sickDays: PropTypes.number,
    sickDaysId: PropTypes.string,
};

SickDays.defaultProps = {
    sickDays: 0,
    sickDaysId: '',
};

export default SickDays;
