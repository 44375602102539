import { call, put, takeLatest } from 'redux-saga/effects';
import {
    createNewPolicy,
    deletePolicy,
    getToilPolicies,
    toilActions,
    updatePolicy,
    updateResourcePolicies
} from './actions';
import { monitoring } from '../../../monitoring';
import { toilApi } from '../../../api/toil';
import { addNotification } from '../../../actions/notificationActions';

function* handleReset() {
    yield put(getToilPolicies.request());
}

function* handleToilPoliciesRequest() {
    try {
        const response = yield call(toilApi.getAllPolicies);
        yield put(
            getToilPolicies.success({
                policies: response,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(getToilPolicies.failure());
        yield put(addNotification({ message: `We couldn't load your TOIL policies`, type: 'danger' }));
    }
}

function* handleCreateToilPolicyRequest(action) {
    const { policy, onSuccess, onFailure } = action.payload;

    try {
        const response = yield call(toilApi.createPolicy, { policy });
        yield put(
            createNewPolicy.success({
                policy: response,
                onSuccess,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(createNewPolicy.failure({ onFailure }));
        yield put(addNotification({ message: `We couldn't create your TOIL policy`, type: 'danger' }));
    }
}

function* handleCreateToilPolicySuccess(action) {
    const { policy, onSuccess } = action.payload;

    yield put(addNotification({ message: `New "${policy.name}" TOIL policy was created`, type: 'success' }));
    if (onSuccess) {
        onSuccess(policy);
    }
}

function* handleUpdateToilPolicyRequest(action) {
    const { policy, onSuccess } = action.payload;

    try {
        const response = yield call(toilApi.updatePolicy, { policy });
        yield put(
            updatePolicy.success({
                policy: response,
                onSuccess,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(updatePolicy.failure());
        yield put(addNotification({ message: `We couldn't update your TOIL policy`, type: 'danger' }));
    }
}

function* handleUpdateToilPolicySuccess(action) {
    const { onSuccess, policy } = action.payload;

    if (onSuccess) {
        onSuccess(policy);
    }

    yield put(addNotification({ message: `"${policy.name}" TOIL policy was updated`, type: 'success' }));
}

function* handleDeleteToilPolicyRequest(action) {
    const { policy, onSuccess } = action.payload;

    try {
        yield call(toilApi.deletePolicy, { policyId: policy._id });
        yield put(
            deletePolicy.success({
                policy,
                onSuccess,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(updatePolicy.failure());
        yield put(addNotification({ message: `We couldn't delete your TOIL policy`, type: 'danger' }));
    }
}

function* handleDeleteToilPolicySuccess(action) {
    const { onSuccess, policy } = action.payload;

    yield put(addNotification({ message: `"${policy.name}" TOIL policy was deleted`, type: 'success' }));
    if (onSuccess) {
        onSuccess();
    }
}

function* handleUpdateResourcePolicies(action) {
    const { onSuccess, data } = action.payload;
    
    try {
        yield call(toilApi.updateResourcePolicies, {data})
        yield put(updateResourcePolicies.success({data, onSuccess}))
    } catch (error) {
        monitoring.captureException(error);
        yield put(updateResourcePolicies.failure());
        yield put(addNotification({ message: `We couldn't update policies`, type: 'danger' }));
    }
}

function* handleUpdateResourcePoliciesSuccess(action) {
    const { onSuccess } = action.payload;
    yield put(addNotification({ message: `Policies were updated`, type: 'success' }));
    if (onSuccess) {
        onSuccess();
    }
}

export function* toilSaga() {
    yield takeLatest(toilActions.RESET_TOIL_REDUCER, handleReset);
    yield takeLatest(toilActions.GET_TOIL_POLICIES['REQUEST'], handleToilPoliciesRequest);
    yield takeLatest(toilActions.CREATE_NEW_POLICY['REQUEST'], handleCreateToilPolicyRequest);
    yield takeLatest(toilActions.CREATE_NEW_POLICY['SUCCESS'], handleCreateToilPolicySuccess);
    yield takeLatest(toilActions.UPDATE_POLICY['REQUEST'], handleUpdateToilPolicyRequest);
    yield takeLatest(toilActions.UPDATE_POLICY['SUCCESS'], handleUpdateToilPolicySuccess);
    yield takeLatest(toilActions.DELETE_POLICY['REQUEST'], handleDeleteToilPolicyRequest);
    yield takeLatest(toilActions.DELETE_POLICY['SUCCESS'], handleDeleteToilPolicySuccess);
    yield takeLatest(toilActions.UPDATE_RESOURCE_POLICIES['REQUEST'], handleUpdateResourcePolicies);
    yield takeLatest(toilActions.UPDATE_RESOURCE_POLICIES['SUCCESS'], handleUpdateResourcePoliciesSuccess);
}
