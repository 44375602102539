import React from 'react';

export const minutesToTime = (minutes) => {

    const timeHours = minutes >= 0 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60);
    const timeMinutes = `${Math.round(Math.abs(minutes) % 60)}`.padStart(2, '0');
    
    return `${timeHours}:${timeMinutes}`
};

export const MinutesToTimeFormat = React.memo(({ minutes }) => {

    if (!Number.isFinite(minutes)) {
        return null;
    }
    
    return <>{minutesToTime(minutes)} hrs</>;
});
