import { useEffect, useMemo } from 'react';
import { debounce } from 'lodash';

export const useWindowResize = resizeCallback => {
    const debouncedFunction = useMemo(() => {
        return debounce(resizeCallback, 50);
    }, [resizeCallback]);

    useEffect(() => {
        window.addEventListener('resize', debouncedFunction);

        return () => {
            if (debouncedFunction && typeof debouncedFunction.cancel === 'function') {
                debouncedFunction.cancel();
            }

            window.removeEventListener('resize', debouncedFunction);
        };
    }, [debouncedFunction]);
};
