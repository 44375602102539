import React from 'react';
import BaseButton from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

//TODO: Use colors from theme once set up correctly
const colors = {
    primary: {
        bgColor: '#32A675',
        hoverColor: '#6FC5A0',
        hoverColorLight: '#eaf8f2',
        txtColor: '#ffffff',
    },
};

const useStyles = makeStyles({
    root: () => ({
        fontWeight: 500,
        textTransform: 'unset',
        fontFamily: 'Poppins',

        '&.Mui-disabled': {
            opacity: 0.7,
        },
    }),
    contained: ({ color = 'primary' }) => {
        const { bgColor, txtColor, hoverColor } = colors[color] || {};

        return {
            backgroundColor: bgColor,
            color: txtColor,
            '&.Mui-disabled': {
                backgroundColor: bgColor,
                borderColor: bgColor,
                color: txtColor,
            },
            '&:hover': {
                backgroundColor: hoverColor,
            },
        };
    },
    outlined: ({ color = 'primary' }) => {
        const { bgColor, hoverColorLight } = colors[color] || {};

        return {
            borderColor: bgColor,
            color: bgColor,
            '&:hover': {
                backgroundColor: hoverColorLight,
            },
            '&.MuiButton-outlined.Mui-disabled': {
                border: `1px solid ${bgColor}`,
                color: bgColor,
            },
        };
    },
});

export const Button = React.memo(props => {
    const classes = useStyles(props);
    const { dataCy, ...restProps } = props;

    return (
        <BaseButton classes={classes} {...restProps} data-cy={dataCy} disableElevation disableRipple disableFocusRipple>
            {props.children}
        </BaseButton>
    );
});

export default Button;
