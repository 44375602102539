import React from 'react';
import { Route } from 'react-router-dom';

const FullScreen = ({ component: Component, path, exact, isAuthenticated, isPartiallyAuthenticated }) => (
    <Route
        path={path}
        exact={exact}
        render={props => (
            <Component
                {...props}
                isAuthenticated={isAuthenticated}
                isPartiallyAuthenticated={isPartiallyAuthenticated}
            />
        )}
    />
);

export default FullScreen;
