import React, { Suspense, useEffect, useState, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import isEqual from 'react-fast-compare';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { useLandingPage, useLoadProjectsAndResources } from 'hooks';
import {
    makeGetResourceGroups,
    isResourceGroupsLoading as isResourceGroupsLoadingSelector,
} from 'selectors/resourceGroup';
import {
    makeGetProjectGroups,
    isProjectGroupsLoading as isProjectGroupsLoadingSelector,
    selectAvailableMyProjectIdsMap,
} from 'selectors/projectGroup';
import { checkAccessByRolesAndExtensions } from 'utils/rightsUtil';
import * as templateContent from 'enums/templateItemsEnum';
import DynamicLoader from 'shared/dynamicLoader';
import { STATUS_TRIAL, STATUS_DISABLED, STATUS_FROZEN, STATUS_CLOSED, STATUS_UNKNOWN } from 'enums/companyStatusEnum';
import { getProjects, projectsLoading } from '../../selectors/project';
import { getResources, resourcesLoading } from '../../selectors/resource';
import { hideModal } from '../../actions/modalActions';

export default ({ routeParamsChecker, ...parameters }) => WrappedComponent => props => {
    const url = useLandingPage();
    const dispatch = useDispatch();
    useLoadProjectsAndResources();
    const [authParams, setAuthParams] = useState({ hasAccess: true, templateItems: {} });
    const account = useSelector(state => state.account);
    const projectGroupSelector = useMemo(() => makeGetProjectGroups(), []);
    const resourceGroupSelector = useMemo(() => makeGetResourceGroups(), []);
    const projectGroups = useSelector(projectGroupSelector);
    const isProjectGroupsLoading = useSelector(isProjectGroupsLoadingSelector);
    const isResourceGroupsLoading = useSelector(isResourceGroupsLoadingSelector);
    const resourceGroups = useSelector(resourceGroupSelector);

    const isProjectsLoading = useSelector(projectsLoading);
    const isResourcesLoading = useSelector(resourcesLoading);

    const projects = useSelector(getProjects);
    const resources = useSelector(getResources);
    const availableMyProjects = useSelector(selectAvailableMyProjectIdsMap);

    const defaultVacationId = useSelector(state => state.companyReducer.company.settings.vacationId);
    const extensions = useSelector(state => state.companyReducer.company.extensions);

    const isAccountClosed = useSelector(state => {
        const company = state.companyReducer.company;

        return (
            includes(
                [STATUS_DISABLED.value, STATUS_FROZEN.value, STATUS_CLOSED.value, STATUS_UNKNOWN.value],
                company.status
            ) ||
            (company.status === STATUS_TRIAL.value && company.trial.hasTrialExpired)
        );
    });

    useEffect(() => {
        const access = checkAccessByRolesAndExtensions(parameters, account, defaultVacationId, extensions, props.match);
        const templateItems = templateContent[access.templateItem] ? templateContent[access.templateItem] : {};

        if (access.hasAccess !== authParams.hasAccess || !isEqual(templateItems, authParams.templateItems)) {
            setAuthParams({
                hasAccess: access.hasAccess,
                templateItems,
            });
        }
    }, [account, defaultVacationId, extensions, authParams, props.match]);

    if (isAccountClosed && -1 === props.location.pathname.indexOf('billing')) {
        return (
            <Redirect
                to={{
                    pathname: '/settings/billing',
                    state: { from: props.location },
                }}
            />
        );
    }

    const isRouteAvailable =
        routeParamsChecker &&
        routeParamsChecker(props.match.params, {
            resources,
            resourceGroups,
            projects,
            projectGroups,
            isProjectGroupsLoading,
            isProjectsLoading,
            isResourceGroupsLoading,
            isResourcesLoading,
            availableMyProjects,
        });

    if (routeParamsChecker && (!isRouteAvailable || (parameters.redirectOnLackOfRights && !authParams.hasAccess))) {
        dispatch(hideModal());

        const NotFoundComponent = parameters.notFoundComponent;

        return NotFoundComponent && props.location.pathname === url ? (
            <NotFoundComponent {...authParams.templateItems} />
        ) : (
            <Redirect
                to={{
                    pathname: url || '/',
                    state: { from: props.location },
                }}
            />
        );
    }

    const ErrorComponent = parameters.errorComponent;

    return (
        <Suspense fallback={DynamicLoader()}>
            {authParams.hasAccess ? <WrappedComponent {...props} /> : <ErrorComponent {...authParams.templateItems} />}
        </Suspense>
    );
};
