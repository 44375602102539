import { createSelector } from 'reselect';
import { filter, find, sortBy } from 'lodash';
import { SYSTEM, USER } from 'modules/scheduler/enums/groupTypeEnum';
import { ACTIVE, MY_SCHEDULE, UNASSIGNED } from 'enums/criteriaEnum';
import { GROUP, SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { getLoggedInId } from './account';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = {};

export const getResourceGroups = state => state.resourceGroupReducer.resourceGroups;
export const getResourceGroupResources = state => state.resourceGroupReducer.resources;
export const isResourceGroupsLoading = state => state.resourceGroupReducer.isResourceGroupsLoading;
export const isResourceGroupsLoaded = state => !state.resourceGroupReducer.isResourceGroupsLoading;
export const isResourcesLoading = state => state.resourceGroupReducer.isResourcesLoading;
export const selectLastCallContext = state => state.resourceGroupReducer.lastCallContext;
export const selectResourceGroupsInitialized = state => state.resourceGroupReducer.groupsInitialized;

export const makeGetResourceGroups = filterType =>
    createSelector(getResourceGroups, resourceGroups => {
        let filteredGroups = resourceGroups;

        switch (filterType) {
            case 'TYPE_USER_OR_SCHEDULE_AND_NOT_SMART':
                filteredGroups = filter(
                    resourceGroups,
                    resourceGroup =>
                        USER === resourceGroup.groupType &&
                        !resourceGroup.isSmart &&
                        (!resourceGroup.type ||
                            resourceGroup.type === GROUP ||
                            resourceGroup.type === SCHEDULER_TEMPLATE)
                );
                break;
            case 'CRITERIA_ACTIVE':
                filteredGroups = filter(resourceGroups, resourceGroup => ACTIVE === resourceGroup.criteria);
                break;
            case 'CRITERIA_NOT_UNASSIGNED':
                filteredGroups = filter(resourceGroups, resourceGroup => UNASSIGNED !== resourceGroup.criteria);
                break;
            case 'NOT_UNASSIGNED_TYPE_GROUP':
                filteredGroups = filter(
                    resourceGroups,
                    resourceGroup =>
                        UNASSIGNED !== resourceGroup.criteria && (!resourceGroup.type || resourceGroup.type === GROUP)
                );
                break;
            case 'TYPE_GROUP':
                filteredGroups = filter(
                    resourceGroups,
                    resourceGroup => !resourceGroup.type || resourceGroup.type === GROUP
                );
                break;
            case 'TYPE_SYSTEM':
                filteredGroups = filter(
                    resourceGroups,
                    resourceGroup => SYSTEM === resourceGroup.groupType && resourceGroup.type !== SCHEDULER_TEMPLATE
                );
                break;
            case 'TYPE_SMART_BASE':
                filteredGroups = filter(
                    resourceGroups,
                    resourceGroup => ACTIVE === resourceGroup.criteria || MY_SCHEDULE === resourceGroup.criteria
                );
                break;
            case 'SMART_GROUPS':
                filteredGroups = filteredGroups.filter(
                    resourceGroup => resourceGroup.type === GROUP && resourceGroup.isSmart
                );
                break;
        }

        return sortBy(filteredGroups, group => group.name.toLowerCase());
    });

export const makeGetUnassignedResourceGroup = () =>
    createSelector(
        getResourceGroups,
        resourceGroups => find(resourceGroups, resourceGroup => UNASSIGNED === resourceGroup.criteria) || {}
    );

export const makeGetResourceGroupById = id =>
    createSelector(
        getResourceGroups,
        resourceGroups => find(resourceGroups, resourceGroup => resourceGroup._id === id) || {}
    );

export const makeGetResourceFromGroupById = id =>
    createSelector(
        getResourceGroupResources,
        resources => (id && find(resources, resource => resource._id === id)) || {}
    );

/**
 * returns data structure first key is groupId and nested resourceId, to quickly determine if resource is part of group
 * to avoid .find() functions
 * ex.
 * {
 *     'groupId1': {
 *         'resourceId1': resourceId1,
 *         'resourceId2': resourceId2
 *     },
 *     'groupId2': {
 *         'resourceId2': resourceId2,
 *         'resourceId3': resourceId3,
 *     }
 * }
 * usage:
 * const isResourcePartOfGroup = mappedGroupResources[groupId][resourceId]
 */
export const selectMappedGroupResources = createSelector(getResourceGroups, resourceGroups => {
    return resourceGroups?.length
        ? resourceGroups.reduce((acc, group) => {
              if (!acc[group._id]) {
                  acc[group._id] = {};
              }

              group.resources?.forEach(resourceId => {
                  acc[group._id][resourceId] = resourceId;
              });

              return acc;
          }, {})
        : EMPTY_OBJECT;
});

/**
 * returns data structure first key is resourceId and nested groupId,
 * ex.
 * {
 *     'resourceId1': {
 *         'groupId1': group1,
 *         'groupId2': group2
 *     },
 *     'resourceId2': {
 *         'groupId2': group2,
 *         'groupId3': group3,
 *     }
 * }
 */
export const selectMappedResourceGroups = createSelector(getResourceGroups, resourceGroups => {
    return resourceGroups?.length
        ? resourceGroups.reduce((acc, group) => {
              group.resources?.forEach(resourceId => {

                  if(!acc[resourceId]) {
                      acc[resourceId] = {}
                  }

                  acc[resourceId][group._id] = group;
              });

              return acc;
          }, {})
        : EMPTY_OBJECT;
});

export const selectGroupIdsWhereIAmApprover = createSelector(
    getLoggedInId,
    getResourceGroups,
    (loggedInId, resourceGroups) => {
        return resourceGroups
            ? resourceGroups
                  .filter(group => {
                      return group.approvers?.includes(loggedInId);
                  })
                  .map(({ _id }) => _id)
            : EMPTY_ARRAY;
    }
);
