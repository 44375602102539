import React from 'react';
import { READABLE_FORMAT_MONTH_NUMBER_YEAR } from 'global/enums/dateFormat';
import { formatDate } from '../../../../utils/DateUtil';

export const DeadlineBubble = React.memo(props => {
    const { text, end } = props;
    return (
        <div>
            Deadline{text ? ` - ${text}` : ``} - {formatDate(end, READABLE_FORMAT_MONTH_NUMBER_YEAR)}
        </div>
    );
});
