import React, { useCallback, useRef, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { hasRole } from 'utils/rightsUtil';
import { showProjectModal } from 'actions/modalActions';
import { useAppKeyWords, useProjectAction } from 'hooks';
import { removeDaypilotEvent } from 'modules/scheduler/utils/schedulerUtil';
import { usePhases, useAccountPreferences } from 'modules/scheduler/hooks';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import { NEW } from 'enums/contextMenuEnum';
import ResourceParent from './resourceParentComponent';
import LegacyResourceParent from 'modules/scheduler/components/dropdowns/schedule/partials/legacy/projectGroupEditMode/index.js';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { updateBookingSelection } from 'actions/schedulerActions';
import { selectCurrentSelectedBooking } from '../../../../../../selectors/scheduler';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

const CONTEXT_MENU_PARENT_MAX_HEIGHT = 228;
const CONTEXT_MENU_PARENT_EDIT_MAX_HEIGHT = 170;

const getMaxHeight = isEditMode => (isEditMode ? CONTEXT_MENU_PARENT_EDIT_MAX_HEIGHT : CONTEXT_MENU_PARENT_MAX_HEIGHT);

const ResourceParentContainer = props => {
    const { onClose, width, height, schedulerRef } = props;
    const dispatch = useDispatch();
    const slideRef = useRef();
    const { projectKeyWord } = useAppKeyWords();
    const { deletePhase } = usePhases(schedulerRef, onClose);
    const { deleteMilestone } = useMilestones(schedulerRef, onClose);
    const [defaults, setDefaults] = useState({});
    const { gridPreferences } = useAccountPreferences();
    const selectedProject = useSelector(state => state.scheduler.currentSelection.rowTags?.project);
    const row = defaults.projectId && schedulerRef.current?.control.rows.find(defaults.projectId);
    const project = useMemo(() => selectedProject || row?.data?.tags?.project || {}, [selectedProject, row]);
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);
    const maxHeight = getMaxHeight(!!defaults.id);
    const calculatedHeight = height > maxHeight ? maxHeight : height;
    const keyWord = defaults.id ? 'Update' : 'Create';

    const hourScale = gridPreferences?.defaultScale === CELLDURATION.value;
    const canAddMilestones =
        hasRole(resourceRoleRights, 'settingProjectMs') && project?.hasRightsToProject && !hourScale;
    const canAddPhases =
        hasRole(resourceRoleRights, 'settingProjectPhases') && project?.hasRightsToProject && !hourScale;
    const canAddProjectDates =
        hasRole(resourceRoleRights, 'settingProjectDates') && project?.hasRightsToProject && !hourScale;

    const { updateProject } = useProjectAction(schedulerRef);
    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => updateProject(defaults.projectId, data),
                type: project?.type,
                projectId: defaults.projectId,
            })
        );
    }, [dispatch, onClose, project, defaults, updateProject]);

    const onDelete = () => {
        if (currentSelectedBooking.milestone) {
            deleteMilestone();
            onClose && onClose();
        }
        if (currentSelectedBooking.phase) {
            deletePhase();
            onClose && onClose();
        }
        if (currentSelectedBooking.datesEvent) {
            dispatch(
                updateProject(currentSelectedBooking.projectId, {
                    project: {
                        start: null,
                        end: null,
                        useProjectDuration: false,
                    },
                })
            );
            removeDaypilotEvent(`start_${currentSelectedBooking.projectId}`);
            removeDaypilotEvent(`end_${currentSelectedBooking.projectId}`);
            onClose && onClose();
            dispatch(updateBookingSelection.request());
        }
    };

    useEffect(() => {
        let start =
            currentSelectedBooking.phase && new Date(removeUTCZuluFromDateTimestamp(currentSelectedBooking.start));
        let end = currentSelectedBooking.phase && new Date(removeUTCZuluFromDateTimestamp(currentSelectedBooking.end));

        if (currentSelectedBooking.datesEvent) {
            if (-1 !== currentSelectedBooking.id.indexOf('start')) {
                start = new Date(currentSelectedBooking.start);
                end = new Date(currentSelectedBooking.dateReference);
            } else {
                start = new Date(currentSelectedBooking.dateReference);
                end = new Date(currentSelectedBooking.end);
            }
        } else if (selectedProject?.start && selectedProject?.end) {
            start = new Date(selectedProject.start);
            end = new Date(selectedProject.end);
        }

        setDefaults({
            projectId: (selectedProject && selectedProject._id) || currentSelectedBooking.projectId,
            color: (selectedProject && selectedProject.color) || currentSelectedBooking.backColor,
            id: currentSelectedBooking.id,
            name: currentSelectedBooking.name,
            date:
                currentSelectedBooking.milestone &&
                new Date(removeUTCZuluFromDateTimestamp(currentSelectedBooking.start)),
            start,
            end,
            repeat: currentSelectedBooking.repeat ? currentSelectedBooking.repeat : false,
            interval: currentSelectedBooking.interval || 'NONE',
            repeatTimes: currentSelectedBooking.repeatTimes ? currentSelectedBooking.repeatTimes : 0,
        });
    }, [selectedProject, currentSelectedBooking]);

    return NEW === gridPreferences.contextMenu ? (
        <div style={{ width }}>
            <ResourceParent
                slideRef={slideRef}
                projectKeyWord={projectKeyWord}
                calculatedHeight={calculatedHeight}
                keyWord={keyWord}
                canAddMilestones={canAddMilestones}
                canAddPhases={canAddPhases}
                canAddProjectDates={canAddProjectDates}
                projectModal={projectModal}
                onDelete={onDelete}
                width={width}
                resourceRoleRights={resourceRoleRights}
                project={project}
                defaults={defaults}
                onClose={onClose}
                currentSelectedBooking={currentSelectedBooking}
                ref={schedulerRef}
            />
        </div>
    ) : (
        <LegacyResourceParent ref={schedulerRef} onClose={onClose} onDelete={onDelete} />
    );
};

ResourceParentContainer.propTypes = {
    onClose: PropTypes.func,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

ResourceParentContainer.defaultProps = {
    onClose: null,
};

export default ResourceParentContainer;
