import { endOfDay, isDate } from 'date-fns';
import { getISODate } from 'shared/lib/date';

export const selectTimeRange = (schedulerRef, startDate, endDate, resourceId) => {
    let start = startDate;
    let end = endDate;

    if (isDate(startDate)) {
        start = getISODate(startDate, {
            computeTimezone: true,
        });
        end = getISODate(endDate, {
            computeTimezone: true,
        });
    }

    // Error on opening nested cells (grouped rows on project group view). Does not affect functional operations. Temporary
    try {
        if (start === end) {
            // Booking a day, entire day. This is for scheduler only to show correct selected.
            const newEnd = getISODate(endOfDay(endDate));
            schedulerRef?.current?.control?.selectTimeRange(start, newEnd, resourceId, true);
        } else {
            schedulerRef?.current?.control?.selectTimeRange(start, end, resourceId, true);
        }
    } catch (err) {
        console.log(err);
    }
};

export const checkIsSelectionEmpty = schedulerRef => schedulerRef?.current?.control?.multirange?.get().isEmpty();
