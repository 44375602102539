import styled from 'styled-components';

export const Wrapper = styled.span`
    display: flex;
    align-items: center;
`;
export const ColorBlock = styled.span`
    background-color: ${props => props.color};
    width: 20px;
    height: 20px;
    border: 1px solid #fff;
    margin-right: 5px;
    float: left;
`;
