import moize from 'moize';
import { includes, every, find, isNil, some } from 'lodash';
import { hasExtensionsInstalled, isActive } from 'utils/extensionUtil';
import { VACATION_REQUEST, RESOURCE_REQUEST, PM, UNASSIGNED, TASKS } from 'enums/extensionShortIdEnum';

export const hasRole = (resourceRoleRights, role) =>
    resourceRoleRights && resourceRoleRights.hasOwnProperty(role) && 'ON' === resourceRoleRights[role];

export const hasOneOfRoles = (resourceRoleRights, roles) => some(roles, role => hasRole(resourceRoleRights, role));
export const hasAllRoles = (resourceRoleRights, roles) => every(roles, role => hasRole(resourceRoleRights, role));

export const hasProjectManagerReportRights = account =>
    account.isProjectManager &&
    hasRole(account.resourceRoleRights, 'pmManageReports') &&
    !hasRole(account.resourceRoleRights, 'manageReports');

export const isOwner = account => 'ROLE_OWNER' === account.resourceRole;

export const hasReportTemplateRights = (report, account, extensions) =>
    ((!report.roleRights || (report.roleRights && hasAllRoles(account.resourceRoleRights, report.roleRights))) &&
        (!report.extensions || (report.extensions && hasExtensionsInstalled(extensions, report.extensions)))) ||
    (report.additionalRights && report.additionalRights(account));

export const hasRightToManageTasks = (account, resource, project, booking) => {
    const loggedInResourceId = account.resourceId;
    const manageTasks = hasRole(account.resourceRoleRights, 'manageTasks');
    const pmManageTasks = hasRole(account.resourceRoleRights, 'pmManageTasks');

    let isOwnTasks = false;
    let isPMOnProject = false;
    let pmManageUnassignedRows = false;
    let isUnassignedResource = false;
    if (!isNil(project)) {
        isPMOnProject = includes(project.projectManagerIds || project.projectManagers, loggedInResourceId);
        pmManageUnassignedRows = isPMOnProject && hasRole(account.resourceRoleRights, 'pmManageUnassignedRows');
    }

    if (!isNil(resource)) {
        isUnassignedResource = 'UNASSIGNED' === resource.type;
        isOwnTasks = resource._id === loggedInResourceId && hasRole(account.resourceRoleRights, 'manageOwnTasks');
    }

    return (
        isOwnTasks ||
        (manageTasks && !booking?.private) ||
        (isPMOnProject && pmManageTasks && !isUnassignedResource) ||
        (isPMOnProject && pmManageTasks && isUnassignedResource && pmManageUnassignedRows)
    );
};

export const checkAccessByRolesAndExtensions = (
    parameters,
    account,
    defaultVacationId,
    companyExtensions,
    routeParams
) => {
    const { allowedRoleRights, extensions, additionalLimits } = parameters;
    const params = routeParams ? routeParams.params : {};
    let hasGivenRoles = !(allowedRoleRights && allowedRoleRights.length);
    let hasExtensions = !(extensions && extensions.length && companyExtensions.length);
    let templateItem = '';

    if (allowedRoleRights && allowedRoleRights.length) {
        hasGivenRoles = hasOneOfRoles(account.resourceRoleRights, allowedRoleRights);
        templateItem = parameters.templateDataItem ? parameters.templateDataItem.noRights : '';
        if (!hasGivenRoles && (includes(extensions, VACATION_REQUEST) || includes(extensions, RESOURCE_REQUEST))) {
            hasGivenRoles = account.isApprover;
        }
    }

    if (extensions && extensions.length && companyExtensions.length) {
        hasExtensions = hasExtensionsInstalled(companyExtensions, extensions);
        if (!hasExtensions) {
            templateItem = parameters.templateDataItem.noExtension;
        }
    }

    if (additionalLimits && additionalLimits.length) {
        hasGivenRoles = every(additionalLimits, additionalLimit => additionalLimit(account, companyExtensions, params));
    }

    if (includes(extensions, VACATION_REQUEST) && companyExtensions.length) {
        hasExtensions = !!defaultVacationId && hasExtensions;
        if (!defaultVacationId) {
            templateItem = 'NO_VACATION_ID';
        }
    }

    return {
        hasAccess: hasGivenRoles && hasExtensions,
        templateItem,
    };
};

export const hasEventRights = (account, companyExtensions) =>
    moize(
        booking => {
            const { resourceId, resourceRoleRights, isProjectManager } = account;
            const isPmOnProject =
                find(booking.project?.projectManagers, projectManager => projectManager === resourceId) || false;
            const isPmExtensionActive = isActive(companyExtensions, PM);
            const isUnassignedExtensionActive = isActive(companyExtensions, UNASSIGNED);
            const isResourceRequestExtensionActive = isActive(companyExtensions, RESOURCE_REQUEST);
            const isVacationRequestExtensionActive = isActive(companyExtensions, VACATION_REQUEST);
            const isTaskExtensionActive = isActive(companyExtensions, TASKS);

            return {
                isTaskExtensionActive,
                isPmExtensionActive,
                isMyBooking: resourceId === booking.resourceInfo?._id,
                scheduleAllProjectsAndResources: hasRole(resourceRoleRights, 'manageEvents'),
                manageAllResourceRequests:
                    hasRole(resourceRoleRights, 'manageAllResourceRequests') && isResourceRequestExtensionActive,
                manageAllVacationRequests:
                    hasRole(resourceRoleRights, 'manageAllVacations') && isVacationRequestExtensionActive,
                pmManageTasks:
                    isProjectManager &&
                    isPmOnProject &&
                    hasRole(resourceRoleRights, 'pmManageTasks') &&
                    isPmExtensionActive,
                pmManageUnassignedRows:
                    isProjectManager && hasRole(resourceRoleRights, 'pmManageUnassignedRows') && isPmExtensionActive,
                manageTasks: hasRole(resourceRoleRights, 'manageTasks'),
                manageOwnTasks: hasRole(resourceRoleRights, 'manageOwnTasks'),
                manageUnassignedRows:
                    hasRole(resourceRoleRights, 'manageUnassignedRows') && isUnassignedExtensionActive,
                viewNotes: hasRole(resourceRoleRights, 'viewNotes'),
            };
        },
        { maxArgs: 1 }
    );
