import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import 'quill/dist/quill.snow.css';
import Quill from 'quill';

const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ['clean'], // remove formatting button
];

function insertContent(editorRef, formattedNote) {
    if (formattedNote == null || !editorRef.current) {
        return;
    }

    const selection = editorRef.current.getSelection();

    if (typeof formattedNote === 'object') {
        editorRef.current.setContents(formattedNote, 'silent');
    } else if (typeof formattedNote === 'string') {
        editorRef.current.setText(formattedNote, 'silent');
    }

    editorRef.current.setSelection(selection);
}

const WysiwygEditor = memo(props => {
    const { onChange, initialContent, readOnly, noStringifyFormatted } = props;
    const editorRef = useRef(null);
    // the parent components don't properly store ref for initial content
    // they can provide new ref to object with same data each render phase

    
    useEffect(() => {
        insertContent(editorRef, initialContent?.formattedNote);
    }, [initialContent, initialContent?.formattedNote]);

    const contentChangeHandler = useCallback(() => {
        const editor = editorRef.current;

        if (!editor) {
            return;
        }

        const contents = editor.getContents();
        const formatted = noStringifyFormatted ? contents : JSON.stringify(contents);
        const text = editor
            .getText()
            .replace(/(\r\n|\\n|\n|\r)/gm, ' ')
            .trim();

        onChange({ note: { message: text, formattedNote: formatted } });
    }, [noStringifyFormatted, onChange]);

    const handleWrapperRef = useCallback(
        elem => {
            if (!elem || editorRef.current) {
                return;
            }

            editorRef.current = new Quill(elem, {
                modules: {
                    toolbar: toolbarOptions,
                },
                theme: 'snow',
                placeholder: 'Add note here...',
                readOnly: readOnly,
            });

            editorRef.current.on('text-change', contentChangeHandler);

            insertContent(editorRef, initialContent?.formattedNote);
        },
        [contentChangeHandler, initialContent?.formattedNote, readOnly]
    );

    useEffect(() => {
        return () => {
            if (editorRef.current) {
                editorRef.current.off('text-change', contentChangeHandler);
            }
        };
    }, [contentChangeHandler]);

    return (
        <div>
            <div ref={handleWrapperRef} className="custom-editor-wrapper" />
        </div>
    );
});

WysiwygEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialContent: PropTypes.object.isRequired,
};

export default WysiwygEditor;
