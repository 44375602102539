import { axiosInstance } from './axiosInstance';
import { getOTPHeader } from './common';

export const getResourceSSOCompanies = email =>
    axiosInstance
        .get(`accountOpenRoute/ssoCompanies?email=${email}`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const authenticate = data => {
    const { otp, ...body } = data ?? {};
    return axiosInstance
        .post('accountOpenRoute/login', body, getOTPHeader(otp))
        .then(response => response)
        .catch(error => {
            throw error.response;
        });
};

export const logout = () =>
    axiosInstance
        .get(`accountOpenRoute/logout`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createAccount = ({ confirm, ...data }) =>
    axiosInstance
        .post('accountOpenRoute/createAccount', {
            ...data,
            serviceTermsAgreed: confirm,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const recoverPasswordForAccount = data =>
    axiosInstance
        .post('accountOpenRoute/sendResetPasswordEmail', {
            email: data.email,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const resetPasswordForAccount = data =>
    axiosInstance
        .post(
            'accountOpenRoute/resetPassword',
            {
                password1: data.newPassword,
                password2: data.confirmNewPassword,
                token: data.token,
            },
            getOTPHeader(data.otp)
        )
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const activateAccount = data =>
    axiosInstance
        .post('accountOpenRoute/setPassword', {
            password1: data.newPassword,
            password2: data.confirmNewPassword,
            token: data.token,
            authMode: data.authMode,
            serviceTermsAgreed: data.confirm,
            newFeaturesEmailNotificationAgreed: data.newFeatureEmailAgreed,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getDedicatedLoginParams = subdomain =>
    axiosInstance
        .get(`accountOpenRoute/dedicatedLogin?subdomain=${subdomain}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const validateActivationToken = token =>
    axiosInstance
        .get(`accountOpenRoute/validateActivationToken/${token}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const validateResetPasswordToken = token =>
    axiosInstance
        .get(`accountOpenRoute/validateResetPasswordToken/${token}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const validateEmailFree = email =>
    axiosInstance
        .get(`accountOpenRoute/validateEmail/${email}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getLoggedInAccount = () =>
    axiosInstance
        .get('company/loggedInUser/account')
        .then(response => {
            // TODO: Temporary fix, remove when fixed on BE side
            if (typeof response?.data === 'string') {
                response.data = JSON.parse(response.data + '}');
                return response;
            }

            return response;
        })
        .catch(error => {
            throw error.response;
        });

export const saveWelcomeData = data =>
    axiosInstance
        .post('accountRoute/updateWelcomeModalData', {
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            subdomain: data.subDomain,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateReportSettings = (accountId, body) =>
    axiosInstance
        .patch(`accountRoute/${accountId}/reportSettings`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updatePreferences = (accountId, body) =>
    axiosInstance
        .patch(`accountRoute/${accountId}/preferences`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const changeAccountCompany = data =>
    axiosInstance
        .post('accountRoute/launchpadCompanySelection', data)
        .then(response => response)
        .catch(error => {
            throw error.response;
        });

export const updateNotificationsRequest = (accountId, body) =>
    axiosInstance
        .patch(`accountRoute/${accountId}/notifications`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateNotificationDetailsRequest = (accountId, body) =>
    axiosInstance
        .patch(`accountRoute/${accountId}/notificationsDetails`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getMFASetupRequest = () =>
    axiosInstance
        .get('resource/mfa/setup', {})
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const getMFARecoveryCodesRequest = data => {
    return axiosInstance
        .get('resource/mfa/recoveryCodes', getOTPHeader(data?.otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const generateMFARecoveryCodesRequest = data => {
    const { otp, ...body } = data ?? {};
    return axiosInstance
        .put('resource/mfa/regenerateRecoveryCodes', body, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const disableMFARequest = data => {
    const { otp, ...body } = data ?? {};
    return axiosInstance
        .put('resource/mfa/disable', body, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const validateSetupMFARequest = otp =>
    axiosInstance
        .put('resource/mfa/setup/verify', {}, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const validateOTPRequest = ({ otp }) =>
    axiosInstance
        .put('resource/mfa/verify', {}, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const requestOTPByEmail = ({ email }) =>
    axiosInstance
        .post('accountOpenRoute/sendOTP', { email })
        .then(response => response)
        .catch(error => {
            throw error.response;
        });
