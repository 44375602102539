import { call, select, put, takeLatest } from 'redux-saga/effects';
import { GET_COMPANY_TREE } from 'actions/actionTypes';
import { getCompanyTree as getCompanyTreeRequest } from 'api/companyTree';
import CompanyTreeModel from 'models/CompanyTreeModel';
import { getCompanyTree as getCompanyTreeActions } from 'actions/companyTreeActions';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { monitoring } from '../monitoring';

const getAccount = state => state.account;
const getCompanyTreeData = state => state.companyTree;

export function* getCompanyTree() {
    try {
        const ct = yield select(getCompanyTreeData);
        if (ct._id) {
            return;
        }
        const account = yield select(getAccount);
        const companyTree = yield call(getCompanyTreeRequest);

        if (companyTree?.projects?.length > 0) {
            companyTree.projects = replaceProjectsColorWithStatusColor(companyTree.projects);
        }

        const companyTreeModel = new CompanyTreeModel(account, companyTree);
        yield put(getCompanyTreeActions.success(companyTreeModel));

        return companyTreeModel;
    } catch (error) {
        monitoring.captureException(error);
        console.log(error);
    }
}

export default function* companyTreeWatcher() {
    yield takeLatest(GET_COMPANY_TREE['REQUEST'], getCompanyTree);
}
