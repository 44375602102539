import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { intervalOptions } from 'shared/repeat';
import { startDateValidator, repeatTimesValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        start: {
            type: Date,
            required: true,
            defaultValue: new Date(),
            validators: [startDateValidator('end')],
            validatorInterdependent: true,
        },
        end: {
            type: Date,
            required: true,
            defaultValue: new Date(),
        },
        color: {
            type: String,
            required: false,
            defaultValue: '#349E71',
        },
        interval: {
            type: Object,
            required: false,
            defaultValue: intervalOptions[0],
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
        project: {
            type: String,
            required: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = ({ start, end }, { projectId: project, ...defaults }) =>
    Object.assign({}, formSchema.getDefaultValues(), defaults, {
        start: start ? start : defaults.start,
        end: end ? end : defaults.end,
        interval: defaults.interval
            ? intervalOptions.find(intervalOption => intervalOption.value === defaults.interval)
            : intervalOptions[0],
        project,
    });

export const mapFormToRequest = values => ({
    start: values.start,
    end: values.end,
    name: values.name,
    interval: values.interval.value,
    repeatTimes: parseInt(values.repeatTimes),
    repeat: 0 < parseInt(values.repeatTimes) && 'NONE' !== values.interval.value,
    color: values.color,
    project: values.project,
});
