import { isEmpty } from 'lodash';
import { SCHEDULED } from 'enums/bookingTypeEnum';

const getPasteBookingDisabled = (clipboard, rowTags) => {
    const isEventCopied = clipboard?.resource.includes('eventsRow_');

    const isPermitted = rowTags.hasOnlyScheduleRights && clipboard?.type === SCHEDULED.value;
    let pasteBookingDisabled =
        !clipboard ||
        isEmpty(rowTags) ||
        rowTags.menuRow ||
        rowTags.isEventRow ||
        rowTags.unassignedParentRow ||
        rowTags.parent ||
        isEventCopied;

    if (isEventCopied && clipboard && !isEmpty(rowTags)) pasteBookingDisabled = !rowTags.isEventRow;

    return pasteBookingDisabled || !isPermitted;
};

export default getPasteBookingDisabled;
