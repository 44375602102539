import styled from 'styled-components';

export const CompanyCard = styled.div`
    display: flex;
    background: #fff;
    flex-direction: ${props => ('list' === props.view ? 'row' : 'column')};
    align-items: ${props => ('list' === props.view ? 'center' : 'start')};
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: ${props => ('list' === props.view ? '100%' : '180px')};
    margin: ${props => ('list' === props.view ? '0 0 10px 0' : '7px')};
    cursor: pointer;
    &:hover {
        background: #f7f7f7;
    }

    .card-body {
        text-align: left;
        padding: ${props => ('list' === props.view ? '0' : '0 5px')};

        b {
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            max-width: ${props => ('list' === props.view ? '250px' : '150px')};
        }
    }

    img {
        align-self: center;
        width: ${props => ('list' === props.view ? '50px' : '150px')};
        border-radius: ${props => ('list' === props.view ? '50%' : 0)};
        margin: ${props => ('list' === props.view ? '0 10px 0 0' : '5px 0 6px 0')};
    }
    span.role-name {
        font-size: 12px;
        color: #c9c9c9;
    }
    small {
        color: #797979;
        font-size: 13px;
        &.text-truncate {
            max-width: 150px;
        }
    }
`;

export const Description = styled.div`
    font-size: 14px;
    width: 90%;
    padding-left: 63px;
    @media (min-width: 768px) {
        font-size: 16px;
    }
`;
