import Schema from 'form-schema-validation';
import { format } from 'date-fns';
import { errorMessages } from 'utils/schemaUtil';
import { intervalOptions } from 'shared/repeat';
import { repeatTimesValidator } from 'utils/formValidators';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        date: {
            type: Date,
            required: true,
            defaultValue: new Date(),
        },
        color: {
            type: String,
            required: false,
            defaultValue: '#349E71',
        },
        interval: {
            type: Object,
            required: false,
            defaultValue: intervalOptions[0],
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
        project: {
            type: String,
            required: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = (date, { projectId: project, ...defaults }) => {
    return Object.assign({}, formSchema.getDefaultValues(), defaults, {
        date: date ? removeUTCZuluFromDateTimestamp(date) : defaults.date,
        project,
        interval: defaults.interval
            ? intervalOptions.find(intervalOption => intervalOption.value === defaults.interval)
            : intervalOptions[0],
        repeatTimes: defaults.repeatTimes ? defaults.repeatTimes : 0,
    });
};

export const mapFormToRequest = values => ({
    date: format(values.date, 'yyyy-MM-dd'),
    start: values.date,
    end: values.date,
    name: values.name,
    interval: values.interval.value,
    repeatTimes: parseInt(values.repeatTimes),
    repeat: 0 < values.repeatTimes && 'NONE' !== values.interval.value,
    color: values.color,
    project: values.project,
});
