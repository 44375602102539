/* eslint-env browser */
import axios from 'axios';

const REQUEST_TIMEOUT = 30000;

const axiosInstance = axios.create({
    baseURL: '/api/',
    timeout: REQUEST_TIMEOUT,
    headers: {
        'Content-type': 'application/json; charset=utf-8',
    },
});

axiosInstance.interceptors.request.use(config => {
    config.headers['hash-url'] = window.location.hash;
    return config;
});

axiosInstance.interceptors.response.use(
    response => response,
    rejection => {
        let redirectUrl =
            rejection.response && rejection.response.data.properties && rejection.response.data.properties.redirectUrl
                ? rejection.response.data.properties.redirectUrl
                : '/auth#/login';

        if (-1 < window.location.href.indexOf('signup')) {
            redirectUrl = redirectUrl.replace('auth#/login', 'auth' + window.location.hash);
        }

        if (
            401 === rejection.response?.status &&
            -1 === window.location.href.indexOf('login') &&
            'localhost' !== window.location.hostname
        ) {
            window.location.replace(redirectUrl);
        }

        if (rejection.message && !rejection.response) {
            console.log(rejection)
        }

        return Promise.reject(rejection);
    }
);

export { axiosInstance };
