import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { Close } from '@material-ui/icons';
import { OPERATORS } from 'enums/operatorEnum';
import { textField as TextField, dropdownField as DropdownField } from 'shared/formFields';
import RemoveButton from 'shared/buttons/smallRemove';

const NumberFilter = ({ defaultValues, clearBtn, inline, onChange }) => {
    const onOperatorChange = operator => {
        onChange && onChange({ operator: operator.value });
    };

    const onNumberChange = event => {
        onChange && onChange({ value: parseFloat(event.target.value) });
    };

    return (
        <Row>
            <Col md={inline ? 5 : 12}>
                <DropdownField
                    input={{
                        name: 'filter',
                        value: defaultValues.operator,
                        onBlur: () => {},
                        onChange: onOperatorChange,
                        'data-cy': 'number-filter--dropdown',
                    }}
                    filter={false}
                    textField="name"
                    valueField="value"
                    data={OPERATORS}
                />
            </Col>
            <Col md={inline ? 5 : 12}>
                <TextField
                    width="col-md-12"
                    placeholder="Enter the value"
                    value={defaultValues.value}
                    onChange={onNumberChange}
                    name="number"
                    type="number"
                    min={0}
                    data-cy="number-filter--input"
                />
            </Col>
            {clearBtn && (
                <Col md={inline ? 2 : 12}>
                    <RemoveButton
                        icon={<Close />}
                        className="mt-1"
                        onClick={() => onChange({ operator: '', value: '' })}
                        data-cy="number-filter--remove"
                    />
                </Col>
            )}
        </Row>
    );
};

NumberFilter.propTypes = {
    defaultValues: PropTypes.shape({
        operator: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    onChange: PropTypes.func,
    inline: PropTypes.bool,
    clearBtn: PropTypes.bool,
};

NumberFilter.defaultProps = {
    onChange: null,
    clearBtn: false,
    inline: false,
    defaultValues: {
        operator: '',
        value: '',
    },
};

export default NumberFilter;
