import { CELLDURATION, DAY, WEEK, findByValue } from 'modules/scheduler/enums/scale';
import { setDayDate } from './util';
import { hasRole } from 'utils/rightsUtil';
import { NO_RIGHTS_RESIZE_THIS_BOOKING } from 'shared/constants/uiMessages';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { UNASSIGNED } from '../../../../../enums/criteriaEnum';

const modalConfig = {
    confirmButtonText: 'Yes, update it',
    cancelButtonText: 'Cancel',
    withCancel: true,
};

const getInfoModalTitle = (eventType = 'Booking') => `You cannot resize this ${eventType}`;

const bookingResizeFn = (
    args,
    confirmationModalFn,
    showInfoModalFn,
    account,
    companyStartEndTimes,
    updateBookingFn,
    resourceRoleRights,
    vacationId
) => {

    const scheduler = args.control;
    const currentScale = scheduler.scale;
    const event = args.e;
    const isEventScaleHours = currentScale !== CELLDURATION.value && event.data.scale.value === CELLDURATION.value;
    const isEventScaleDay =
        currentScale !== DAY.value && currentScale !== CELLDURATION.value && event.data.scale === DAY.value;
    const isEventScaleWeek =
        currentScale !== DAY.value &&
        currentScale !== CELLDURATION.value &&
        currentScale !== WEEK.value &&
        event.data.scale === WEEK.value;

    const canResize = hasRightsToResize(account, args.e.data, resourceRoleRights, vacationId);

    if ((!args.e.data.isProjectPermitted && !args.e.data.isResourcePermitted) || !canResize) {
        showInfoModalFn(getInfoModalTitle(), NO_RIGHTS_RESIZE_THIS_BOOKING);
        return;
    }

    if (isEventScaleHours) {
        confirmationModalFn(
            () => {
                event.data.scale = findByValue(currentScale);
                event.data.minutesPerDay = event.data.details.bookedMinutes;
                event.data.allDay = true;
                doBookingResize(args, companyStartEndTimes, updateBookingFn);
            },
            'Booking Resizing',
            `This booking was created in ${event.data.scale.display} View, resizing it in ${currentScale} View will change the time from ${event.data.scale.display} to ${currentScale}s. Are you sure you want to update it?`,
            modalConfig
        );
    } else if (isEventScaleDay || isEventScaleWeek) {
        confirmationModalFn(
            () => {
                event.data.scale = findByValue(currentScale);
                doBookingResize(args, companyStartEndTimes, updateBookingFn);
            },
            'Booking Resizing',
            `This booking was created in ${event.data.scale.display} View, resizing it in ${currentScale} View will change the time from ${event.data.scale.display} to ${currentScale}s. Are you sure you want to update it?`,
            modalConfig
        );
    } else {
        doBookingResize(args, companyStartEndTimes, updateBookingFn);
    }
};

const doBookingResize = (args, companyStartEndTimes, updateBookingFn) => {
    const currentScale = args.control.scale;
    const oldEvent = { ...args.e.data };
    const event = args.e;

    if (currentScale === CELLDURATION.value) {
        event.data.minutesPerDay = null;
    } else {
        event.data.allDay = true;
    }

    const setDayDateResult = setDayDate(event, currentScale, args.newStart, args.newEnd, companyStartEndTimes);
    const { data: bookingData } = setDayDateResult;
    const { resource, resourceInfo } = bookingData;

    updateBookingFn(
        bookingData,
        {
            start: oldEvent.start,
            end: oldEvent.end,
            resource,
            resourceInfo,
        },
        true,
        {
            isResizingAction: true,
        }
    );
};

const hasRightsToResize = (account, eventData, resourceRoleRights, vacationId) => {
    const fullSchedulingRights = hasRole(account.resourceRoleRights, 'manageEvents');
    const uwSchedulingRights = hasRole(account.resourceRoleRights, 'manageUnassignedRows');
    const limitedIndividualSchedulingRights = hasRole(
        account.resourceRoleRights,
        'scheduleMyselfOnProjectsOrEventsIAmPartOf'
    );
    const individualSchedulingRights = hasRole(account.resourceRoleRights, 'scheduleMyselfOnAnyProjectOrEvent');
    const fullSchedulingRightsOnManagedProjects = hasRole(account.resourceRoleRights, 'pmManageEvents');
    const uwSchedulingRightsOnManagedProjects = hasRole(account.resourceRoleRights, 'pmManageUnassignedRows');

    const isUW = eventData.resourceInfo?.type === UNASSIGNED;
    return (
        (eventData.isPermitted && fullSchedulingRights) ||
        // has permissions to event and has full scheduling rights
        (eventData.resource === account.resourceId &&
            (individualSchedulingRights)) ||
        (eventData.resource === account.resourceId && eventData.isProjectPermitted &&
            (limitedIndividualSchedulingRights)) ||
        // is on project and event and has rights to schedule itself to such project
        (fullSchedulingRightsOnManagedProjects && eventData.project.projectManagers.includes(account.resourceId)) ||
        (isUW && uwSchedulingRights) ||
        (isUW &&
            uwSchedulingRightsOnManagedProjects &&
            eventData.project.projectManagers.includes(account.resourceId)) ||
        // is PM on project and has scheduling rights as PM
        (eventData.type === 'WAITING_FOR_APPROVAL' &&
            isAbleToEditResourceOrVacationRequest(eventData, account.resourceId, resourceRoleRights, vacationId))
        // is R&A flow and user has edit permissions
    );
};

export default bookingResizeFn;
