import { UNASSIGNED } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @returns {*[]}
 */
export default dateState => [
    // PROJECT
    {
        id: 'projectSpecificReportedTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'projectSpecificReportedTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificReportedTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificReportedTimeFuture',
        display: 'Future',
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificReportedTimeToDate',
        display: 'To Date',
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificReportedTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    // EVENT
    {
        id: 'eventSpecificReportedTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'eventSpecificReportedTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificReportedTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificReportedTimeFuture',
        display: 'Future',
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificReportedTimeToDate',
        display: 'To Date',
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificReportedTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    // RESOURCE
    {
        id: 'resourceSpecificReportedTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'resourceSpecificReportedTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificReportedTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificReportedTimeFuture',
        display: 'Future',
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificReportedTimeToDate',
        display: 'To Date',
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificReportedTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Resource Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    // UNASSIGNED
    {
        id: 'unassignedSpecificReportedTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: true,
    },
    {
        id: 'unassignedSpecificReportedTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificReportedTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificReportedTimeFuture',
        display: 'Future',
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificReportedTimeToDate',
        display: 'To Date',
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificReportedTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Unassigned Reported Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
];
