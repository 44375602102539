import { call, put, takeLatest, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { getCustomers as getCustomersRequest, createCustomer as createCustomerRequest } from 'api/customer';
import * as actionTypes from 'actions/actionTypes';
import { createCustomer, getCustomers } from 'actions/customerActions';
import { addNotification } from 'actions/notificationActions';
import accessDeniedHandler from 'sagas/handlers/accessDeniedHandler';
import { monitoring } from '../monitoring';

const defaultMessage = 'Something went wrong... cannot load Clients for you';

const getCustomersSelector = state => state.customerReducer.customers;
const getFormFieldValue = (formName, fieldName) => state => state.form[formName].values[fieldName];

function* handleCreateCustomer(action) {
    try {
        const { data, formName, fieldName } = action.payload;
        const customerResponse = yield call(createCustomerRequest, data);

        yield put(createCustomer.success(customerResponse));
        if (formName && fieldName) {
            const currentValues = yield select(getFormFieldValue(formName, fieldName));
            yield put(change(formName, fieldName, [...currentValues, customerResponse]));
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Client was not created',
                type: 'danger',
            })
        );
    }
}

function* customersRequest() {
    try {
        const customers = yield select(getCustomersSelector);
        if (customers.length) {
            return;
        }
        const customersResponse = yield call(getCustomersRequest);

        yield put(getCustomers.success(customersResponse));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

export default function* customerWatcher() {
    yield takeLatest(actionTypes.GET_CUSTOMERS['REQUEST'], customersRequest);
    yield takeLatest(actionTypes.CREATE_CUSTOMER['REQUEST'], handleCreateCustomer);
}
