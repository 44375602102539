import { store } from '../../store';
import { showConfirmationModal } from '../../actions/modalActions';

export const deadlinesMoveErrorHandler = {
    predicate: error => error?.data?.error?.enum === 'ENTITY_DEADLINE_MOVE',
    handler: function(error, action) {
        store.dispatch(
            showConfirmationModal(
                () => {
                    store.dispatch({
                        ...action,
                        payload: {
                            ...(action.payload || {}),
                            options: {
                                ...(action.payload?.options || {}),
                                moveDeadline: true,
                            },
                        },
                    });
                },
                'Booking update warning',
                'Do you want to move deadline with Booking',
                {
                    withCancel: true,
                    withEscButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    cancelActionOnHide: true,
                    onReject: () => {
                        store.dispatch({
                            ...action,
                            payload: {
                                ...(action.payload || {}),
                                options: {
                                    ...(action.payload?.options || {}),
                                    moveDeadline: false,
                                },
                            },
                        });
                    },
                }
            )
        );
    },
};
