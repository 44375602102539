import * as actionTypes from './actionTypes';
import { action } from 'actions/base';

export const getClientToken = {
    request: customerId => action(actionTypes.GET_BRAINTREE_CLIENT_TOKEN['REQUEST'], { customerId }),
    success: clientToken =>
        action(actionTypes.GET_BRAINTREE_CLIENT_TOKEN['SUCCESS'], {
            clientToken,
        }),
};

export const getBillingPlans = {
    request: () => action(actionTypes.GET_BILLING_PLANS['REQUEST']),
    success: (billingPlans, billingRegion) =>
        action(actionTypes.GET_BILLING_PLANS['SUCCESS'], {
            billingPlans,
            billingRegion,
        }),
};

export const getBillingExtensions = {
    request: () => action(actionTypes.GET_BILLING_EXTENSIONS['REQUEST']),
    success: extensions => action(actionTypes.GET_BILLING_EXTENSIONS['SUCCESS'], { extensions }),
};

export const updateBillingExtensionState = {
    request: (templateId, installed) =>
        action(actionTypes.UPDATE_EXTENSION_STATE['REQUEST'], {
            templateId,
            installed,
        }),
    success: (templateId, data) =>
        action(actionTypes.UPDATE_EXTENSION_STATE['SUCCESS'], {
            templateId,
            data,
        }),
};

export const unsubscribeExtension = {
    request: body => action(actionTypes.UNSUBSCRIBE_EXTENSION['REQUEST'], { body }),
    success: extension => action(actionTypes.UNSUBSCRIBE_EXTENSION['SUCCESS'], { extension }),
};

export const getBillingView = {
    request: () => action(actionTypes.GET_BILLING_VIEW['REQUEST']),
    success: billingView => action(actionTypes.GET_BILLING_VIEW['SUCCESS'], { billingView }),
};

export const validateVatId = {
    request: (vatId, resolve) => action(actionTypes.VALIDATE_VAT_ID['REQUEST'], { vatId, resolve }),
};

export const purchase = {
    request: (paymentMethodNonce, body) => action(actionTypes.PURCHASE['REQUEST'], { paymentMethodNonce, body }),
};

export const deleteSubscription = {
    request: password =>
        action(actionTypes.DELETE_SUBSCRIPTION['REQUEST'], {
            password,
            actionState: true,
        }),
    success: () =>
        action(actionTypes.DELETE_SUBSCRIPTION['SUCCESS'], {
            actionState: false,
        }),
};

export const updateExtensionSubscription = {
    request: body => action(actionTypes.UPDATE_EXTENSION_SUBSCRIPTION['REQUEST'], { body }),
};

export const updateSubscription = {
    request: body => action(actionTypes.UPDATE_SUBSCRIPTION['REQUEST'], { body }),
};

export const updateCustomer = {
    request: (body, actionState) => action(actionTypes.UPDATE_CUSTOMER['REQUEST'], { body, actionState }),
    success: actionState => action(actionTypes.UPDATE_CUSTOMER['REQUEST'], { actionState }),
};

export const getPaymentInformation = {
    request: () => action(actionTypes.GET_PAYMENT_INFORMATION['REQUEST']),
    success: paymentInformation =>
        action(actionTypes.GET_PAYMENT_INFORMATION['SUCCESS'], {
            paymentInformation,
        }),
};

export const setDefault = {
    request: propertyName => action(actionTypes.RESET_TO_DEFAULT_BY_PROP, { propertyName }),
};

export const updatePaymentState = {
    request: actionState => action(actionTypes.UPDATE_ACTION_STATE, { actionState }),
};
