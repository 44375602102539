import styled from 'styled-components';
import BackgroundImage from 'assets/images/Hub_Tablet_Screen.jpg';

export const Background = styled.div`
    background-image: url(${BackgroundImage});
    background-color: #000;
    padding: 0.5rem;
    height: 100%;
`;

export const Main = styled.div`
    top: 50%;
    position: absolute;
    margin-top: -292px;
    left: 50%;
    margin-left: -315px;
    overflow: auto;
`;

export const Wrapper = styled.div`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
    border-radius: 20px;
    float: none;
    margin: 50px auto;
    width: 550px;
`;

export const H1 = styled.h1`
    color: #fff;
    font-size: 210px;
`;

export const Text = styled.p`
    color: #bbb;
`;
