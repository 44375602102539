/* eslint-env browser */
import { useCallback, useEffect } from 'react';

export const ENTER_KEY = 13;

export const usePressEnter = onPressEnter => {
    const handler = useCallback(
        event => {
            const key = event.keyCode ? event.keyCode : event.which;
            if (ENTER_KEY === key) {
                onPressEnter && onPressEnter(event);
            }
        },
        [onPressEnter]
    );

    useEffect(() => {
        document.addEventListener('keydown', handler, true);

        return () => {
            document.removeEventListener('keydown', handler, true);
        };
    }, [handler]);
};
