export const TOIL_EXPIRY_ENUM = {
    NONE: 'NONE',
    ANNUAL: 'ANNUAL',
}

export const TOIL_ADJUSTMENT_TYPE_ENUM = {
    MANUAL: 'MANUAL',
    STARTING_BALANCE: 'STARTING_BALANCE'
}

export const expiryOptions = [
    {
        label: 'No expiry',
        value: TOIL_EXPIRY_ENUM.NONE,
    },
    {
        label: 'Expire annually',
        value: TOIL_EXPIRY_ENUM.ANNUAL,
    },
];



