import styled from 'styled-components';

export const Icon = styled.span`
    background-color: #0288b0;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    display: inline-block;
    color: #fff;
    font-size: 11px;
    line-height: 14px;
`;
