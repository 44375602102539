import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm } from 'redux-form';
import CommonModalTemplate from 'shared/modal/commonModalTemplate';
import { each, isNaN, isArray, map, contains, every, pluck } from 'underscore';
import { TabContent, Alert } from 'reactstrap';
import { Archive } from '@material-ui/icons';
import { mapFormToRequest, formSchema, asyncValidate } from 'forms/projectForm';
import { TYPE_REGULAR, TYPE_EVENT, STATUS_ARCHIVED } from 'enums/projectEnum';
import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { hasRole } from 'utils/rightsUtil';
import { pickChangedFields } from 'utils/formUtil';
import { isActive } from 'utils/extensionUtil';
import { validateSchema } from 'utils/schemaUtil';
import ConfirmTooltipButton from 'shared/confirmTooltipButton';
import ProjectInfoTab from './projectBasicInfo';
import ResourceAndGroupsTab from './resourceAndGroups';
import BillingAndRatesTab from './billingAndRates';
import NotesTab from 'modules/modals/common/notes';
import CustomFieldTab, { customFieldsValidator } from 'modules/modals/common/customField';
import MilestonesTab from './milestones';
import PhasesTab from './phases';
import TimesheetSettingsTab from './timesheetSettings';
import AdvancedSettingsTab from './advancedSettings';
import TabLinks from 'shared/tabLinks';
import { getTabIcons } from 'modules/modals/projectModal/tabIcons';
import { store } from '../../../../store';
import { getAllocationDisplayValue } from 'utils/allocationUtil';
import { FixedCostsTab } from './fixedCost';
import { ENTER_KEY } from '../../../../hooks';
import { shouldSubmitForm } from '../../../../hooks/useModalPressEnter';

export const FORM_NAME = 'projectForm';

class ProjectModal extends PureComponent {
    static propTypes = {
        onSubmit: PropTypes.func.isRequired,
        projectId: PropTypes.string,
        type: PropTypes.oneOf([TYPE_REGULAR.value, TYPE_EVENT.value]),
    };

    static defaultProps = {
        projectId: '',
        type: TYPE_REGULAR.value,
    };

    constructor(props) {
        super(props);
        this.state = { activeTab: '1' };
    }

    handlePressEnter = event => {
        const key = event.keyCode ? event.keyCode : event.which;
        if (ENTER_KEY === key) {
            const { resourceRoleRights, customFields, projectCustomFields } = this.props;

            const hasSettingProjectCf = hasRole(resourceRoleRights, 'settingProjectCf');
            const customFieldsValid = customFieldsValidator({
                formCustomFieldsValues: customFields,
                customFields: projectCustomFields,
                hasRights: hasSettingProjectCf,
            });

            const saveDisabled =
                this.props.invalid ||
                this.props.pristine ||
                this.props.isCreating ||
                this.props.isUpdating ||
                !customFieldsValid;

            if (saveDisabled) {
                return;
            }

            if (shouldSubmitForm(event)) {
                this.onFormSubmit();
            }
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handlePressEnter, true);
        this.props.projectId && this.props.resetProjectValue();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handlePressEnter, true);

        const { resourceRoleRights, extensions } = this.props;
        if (this.props.projectId) {
            this.props.getProject();
        }

        if (hasRole(resourceRoleRights, 'settingProjectCf')) {
            this.props.getCustomFields();
        }

        if (hasRole(resourceRoleRights, 'settingProjectInfo') || hasRole(resourceRoleRights, 'settingProjectBudget')) {
            this.props.getCategoryGroups();
            this.props.getCategoryTemplates();
        }

        if (hasRole(resourceRoleRights, 'settingProjectTags')) {
            this.props.getTags();
        }

        if (hasRole(resourceRoleRights, 'settingProjectBudget') && isActive(extensions, RESOURCE_RATE)) {
            this.props.getResources();
        }

        if (hasRole(resourceRoleRights, 'settingProjectGroups')) {
            this.props.getProjectGroups();
        }

        this.props.getProjectManagers();

        // Kind of workaround to initialize modal from smaller form
        // but initializing from connect does not trigger pristine
        // and does not allow to use button without change
        this.onChange(this.props.initialData);
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.selectedCategoryGroups &&
            prevProps.selectedCategoryGroups.length !== this.props.selectedCategoryGroups.length
        ) {
            if (
                this.props.defaultCategoryTemplate._id &&
                (!this.props.selectedCategoryGroups.length ||
                    every(
                        this.props.selectedCategoryGroups,
                        selectedCategoryGroup =>
                            !contains(selectedCategoryGroup.categoryTemplates, this.props.defaultCategoryTemplate._id)
                    ))
            ) {
                this.props.change('defaultCategoryTemplate', {});
            }
        }
    }

    toggle = tabId => {
        this.setState({
            activeTab: tabId,
        });
    };

    onSubmit = (values, dispatch, { initialValues }) => {
        const onSubmitData = mapFormToRequest({
            projectId: values._id,
            project: this.props.project,
            values: pickChangedFields(values, initialValues),
            customFieldTemplates: this.props.projectCustomFields,
            startEndTimes: this.props.startEndTimes,
            projectType: this.props.type,
            initialProjectGroups: pluck(initialValues.projectGroups, '_id'),
        });
        this.props.onSubmit(onSubmitData);
    };

    onChange = values => {
        each(values, (value, item) => {
            this.props.change(item, (!isArray(value) && isNaN(value)) || 'NaN' === value ? 0 : value);
        });
    };

    getProjectKeyWord() {
        return this.props.type === TYPE_REGULAR.value ? this.props.projectKeyWord : 'Event';
    }

    getCategoryTemplates() {
        const categoryTemplates = [];
        const useCategoriesAllocation = store.getState().companyReducer?.company?.settings?.useCategoriesAllocation;

        each(this.props.selectedCategoryGroups, categoryGroup => {
            const templates = map(categoryGroup.categories, category => ({
                ...category,
                groupName: categoryGroup.name,
                allocationText: useCategoriesAllocation && getAllocationDisplayValue(category),
            }));
            categoryTemplates.push(...templates);
        });

        return categoryTemplates;
    }

    onFormSubmit = form => {
        this.props.submitForm(form);
    };

    onHideModal = () => {
        this.props.hideModal();
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    delete = () => {
        const callback = () => {
            this.onHideModal();
        };

        this.props.deleteProject(callback);
    };

    archive = () => {
        this.props.archiveProject();
        this.onHideModal();
    };

    render() {
        const {
            resourceKeyWord,
            resourcePluralKeyWord,
            projectTabName,
            resourceRoleRights,
            project,
            type,
            customFields,
            projectCustomFields,
            budgetCategories,
            fixedCosts,
        } = this.props;
        const hasSettingProjectCf = hasRole(resourceRoleRights, 'settingProjectCf');

        const projectKeyWord = this.getProjectKeyWord();
        const tabIcons = getTabIcons(type, { projectKeyWord, resourceKeyWord, resourcePluralKeyWord, projectTabName });
        const categoryTemplates = this.getCategoryTemplates();

        const customFieldsValid = customFieldsValidator({
            formCustomFieldsValues: customFields,
            customFields: projectCustomFields,
            hasRights: hasSettingProjectCf,
        });

        const saveButtonTooltip = !customFieldsValid ? (
            <>
                You must fill in the required custom fields first (<i className="fa fa-pencil" />) to be able to save
                the project.
            </>
        ) : (
            undefined
        );

        const extendFooter = this.props.projectId && this.props.project.hasRightsToProject && (
            <>
                {hasRole(resourceRoleRights, 'settingDeleteProjects') && (
                    <ConfirmTooltipButton
                        onConfirm={this.delete}
                        buttonText={`Delete ${projectKeyWord.toLowerCase()}`}
                        dataCyPrefix="project-modal-delete"
                    />
                )}
                {hasRole(resourceRoleRights, 'settingProjectStatus') &&
                    project.status &&
                    project.status !== STATUS_ARCHIVED.value &&
                    this.props.type !== TYPE_EVENT.value && (
                        <ConfirmTooltipButton
                            onConfirm={this.archive}
                            color="secondary"
                            icon={Archive}
                            buttonText={`Archive ${projectKeyWord.toLowerCase()}`}
                            dataCyPrefix="project-modal-archive"
                        />
                    )}
                {this.props.hasSubmitFailed && (
                    <Alert className="form-footer-alert ml-3" color="danger">
                        Please fill out all required fields
                    </Alert>
                )}
            </>
        );

        return (
            <CommonModalTemplate
                submitOnPressEnter
                title={projectKeyWord}
                description={`A ${projectKeyWord} is what you schedule on your team`}
                onSave={this.onFormSubmit}
                windowHeight={this.props.windowHeight}
                extendFooter={extendFooter}
                onHideModalCustom={this.onHideModal}
                disabledSaveButton={
                    this.props.invalid ||
                    this.props.pristine ||
                    this.props.isCreating ||
                    this.props.isUpdating ||
                    !customFieldsValid
                }
                saveButtonTooltip={saveButtonTooltip}
                saveButtonText={this.props.projectId ? 'Update' : 'Add New'}
                saveButtonDataCy={this.props.projectId ? 'button--update--pro-modal' : 'button--add-new--pro-modal'}
            >
                <Form className="needs-validation" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <TabLinks tabs={tabIcons} toggle={this.toggle} />
                    <TabContent activeTab={this.state.activeTab}>
                        <ProjectInfoTab
                            tabId="1"
                            projectKeyWord={projectKeyWord}
                            type={this.props.type}
                            projectManagers={this.props.projectManagers}
                            tags={this.props.projectTags}
                            onFieldChange={this.onChange}
                            categoryTemplates={categoryTemplates}
                        />
                        <ResourceAndGroupsTab
                            tabId="2"
                            type={this.props.type}
                            resources={this.props.resources}
                            unassignedWork={this.props.unassignedResources}
                            projectGroups={this.props.projectGroups}
                            resourcePluralKeyWord={resourcePluralKeyWord}
                            projectKeyWord={projectKeyWord}
                        />
                        <BillingAndRatesTab
                            tabId="3"
                            projectRates={this.props.projectRates}
                            projectKeyWord={projectKeyWord}
                            selectedResources={this.props.selectedResources}
                            categoryGroups={this.props.categoryGroups}
                            budgetCategories={budgetCategories}
                            resources={this.props.resources.concat(this.props.unassignedResources)}
                            onChange={this.props.change}
                        />
                        <FixedCostsTab
                            tabId="4"
                            fixedCosts={fixedCosts}
                            project={this.props.project}
                            onChange={this.props.change}
                        />
                        <NotesTab
                            tabId="5"
                            noteKeyWord={projectKeyWord}
                            linksKeyWord={projectKeyWord}
                            hasNoteRights={hasRole(resourceRoleRights, 'settingProjectNotes')}
                            hasLinksRights={hasRole(resourceRoleRights, 'settingProjectLinks')}
                            onChange={this.onChange}
                            note={this.props.projectId && this.props.note}
                            iconLinks={this.props.iconLinks}
                        />
                        <CustomFieldTab
                            tabId="6"
                            onChange={this.onChange}
                            type="project"
                            customFields={this.props.projectCustomFields}
                        />
                        <MilestonesTab
                            tabId="7"
                            projectKeyWord={projectKeyWord}
                            displayDatesInput={this.props.setStartEndDates}
                            onFieldChange={this.onChange}
                        />
                        <PhasesTab tabId="8" onFieldChange={this.onChange} />
                        <TimesheetSettingsTab tabId="9" projectKeyWord={projectKeyWord} />
                        <AdvancedSettingsTab tabId="10" projectKeyWord={projectKeyWord} />
                    </TabContent>
                </Form>
            </CommonModalTemplate>
        );
    }
}

export default reduxForm({
    form: FORM_NAME,
    asyncValidate,
    asyncBlurFields: ['projectCode'],
    updateUnregisteredFields: true,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: validateSchema(formSchema),
})(ProjectModal);
