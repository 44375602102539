import React, { memo, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, getFormValues } from 'redux-form';
import { TabPane, Button } from 'reactstrap';
import { useHasRights, useAppKeyWords, useIsExtensionInstalled } from 'hooks';
import { findCurrency, getCurrencies, getCurrencySymbol } from 'enums/currencyEnum';
import { materialInputGroupField, dropdownFieldMaterial, dropdownField } from 'shared/formFields';
import { normalizeFloatNumber, normalizePositiveNumbers } from 'utils/formUtil';
import PermissionDenied from 'shared/permissionDenied';
import { Layout } from '../../../../shared/components/layout';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import { selectDefaultCompanyCurrency } from '../../../../selectors/company';
import CancelIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { costsCategoriesActions, selectNotDeletedCostsCategories } from '../../../costsCategories/store';
import { FixedCostCategoryTemplateItem } from '../../../costsCategories/fixedCostCategoryTemplateItem';
import { FIXED_COSTS } from '../../../../enums/extensionShortIdEnum';
import MissingExtension from '../../../../shared/warnings/missingExtension';
import { Checkbox } from '../../../../shared/components/checkbox';
import { FORM_NAME } from './modal';
import { WrapWithTooltip } from '../../../scheduler/components/header/schedulerButtons/wrapWithTooltip';
import { showMissingFixedCostsCategoriesExtensionModal } from '../../../../actions/modalActions';
import classnames from 'classnames';

const rightsToCheck = [
    {
        rights: ['settingProjectFixedCosts'],
        rule: 'one',
        name: 'hasSettingProjectFixedCosts',
    },
    {
        rights: ['settingProjectFixedCostsForPM'],
        rule: 'one',
        name: 'hasSettingProjectFixedCostsForPm',
    },
];

export const FixedCostsTab = memo(({ tabId, categoryGroups, fixedCosts, onChange, project }) => {
    const dispatch = useDispatch();
    const { hasSettingProjectFixedCosts, hasSettingProjectFixedCostsForPm } = useHasRights(rightsToCheck);
    const isExtensionInstalled = useIsExtensionInstalled(FIXED_COSTS);

    useEffect(() => {
        dispatch(costsCategoriesActions.fetchAll.request({ showDeleted: true }));
    }, [dispatch]);

    const handleSeeMore = useCallback(() => {
        if (!isExtensionInstalled) {
            dispatch(showMissingFixedCostsCategoriesExtensionModal());
        }
    }, [dispatch, isExtensionInstalled]);

    if (!isExtensionInstalled) {
        return (
            <TabPane tabId={tabId}>
                <section className="form-fields border-bottom-0 mb-3">
                    <MissingExtension
                        additionalBtnText="Read more"
                        additionalBtnAction={handleSeeMore}
                        description="Fixed costs that you are allowed to edit will appear here. Please enable this extension from extension page"
                    />
                </section>
            </TabPane>
        );
    }

    const canEditFixedCosts =
        hasSettingProjectFixedCosts || (hasSettingProjectFixedCostsForPm && project?.hasPMRightsToProject);

    if (!canEditFixedCosts) {
        return (
            <TabPane tabId={tabId}>
                <section className="form-fields border-bottom-0 mb-3">
                    <PermissionDenied />
                </section>
            </TabPane>
        );
    }

    return (
        <TabPane tabId={tabId}>
            <section className="form-fields border-bottom-0 mb-3">
                <FixedCosts categoryGroups={categoryGroups} fixedCosts={fixedCosts} onChange={onChange} />
            </section>
        </TabPane>
    );
});

FixedCostsTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    fixedCosts: PropTypes.array,
};

FixedCostsTab.defaultProps = {
    fixedCosts: [],
};

const FixedCosts = memo(props => {
    const { fixedCosts, onChange } = props;
    const { projectKeyWord } = useAppKeyWords();
    const { asArray: costsCategories } = useSelector(selectNotDeletedCostsCategories);

    const selectedCategories = useMemo(() => {
        return fixedCosts.reduce((acc, fc) => {
            if (fc.costCategory?._id) {
                acc[fc.costCategory._id] = fc.costCategory;
            }
            return acc;
        }, {});
    }, [fixedCosts]);

    const { availableToSelectCategoryTemplates, maxNumberOfFixedCosts } = useMemo(() => {
        const availableToSelectCategoryTemplates = costsCategories.filter(ct => !selectedCategories[ct._id]);
        const maxNumberOfFixedCosts =
            (availableToSelectCategoryTemplates?.length ?? 0) +
            fixedCosts?.filter(fc => Boolean(fc.costCategory))?.length;

        return {
            availableToSelectCategoryTemplates,
            maxNumberOfFixedCosts,
        };
    }, [costsCategories, fixedCosts, selectedCategories]);

    return (
        <Layout stack gap="16px">
            <Layout stack>
                <p className="title text-capitalize">Add fixed costs to your {projectKeyWord}</p>
                <small className="form-text text-muted">
                    Incorporate fixed costs into the {projectKeyWord} by selecting a designated cost category, currency,
                    and amount. Tick the checkbox to subtract the costs from the {projectKeyWord} budget.
                </small>
            </Layout>
            <FieldArray
                name="fixedCosts"
                component={FixedCostForm}
                props={{
                    availableToSelectCategoryTemplates,
                    fixedCosts,
                    maxNumberOfFixedCosts,
                    onChange,
                }}
            />
        </Layout>
    );
});

const FixedCostForm = props => {
    const { fields, availableToSelectCategoryTemplates, fixedCosts, maxNumberOfFixedCosts, onChange } = props;
    const currency = useSelector(selectDefaultCompanyCurrency);
    const currentFormValues = useSelector(getFormValues(FORM_NAME));

    const handleAddCost = () => {
        fields.push({
            costCategory: null,
            currency: findCurrency(currency),
            scheduled: 0,
            actual: 0,
            deductFromProjectBudget: true,
        });
    };

    const handleRemoveRow = index => {
        fields.splice(index, 1);
    };

    const handleDeductFromBudgetChange = useCallback(
        event => {
            onChange(event.target.name, event.target.checked);
        },
        [onChange]
    );

    const handleBlur = useCallback(
        event => {
            event.preventDefault();
            const val = event.target.value;
            const normalized = normalizePositiveNumbers(val);
            onChange(event.target.name, normalized ? normalized : 0);
        },
        [onChange]
    );

    const handleNumberInputKeyPress = useCallback(event => {
        // we accepts only numbers
        if (!((event.which >= 48 && event.which <= 57) || event.which === 44 || event.which === 46)) {
            event.preventDefault();
        }
    }, []);

    const classes = useStyles();

    return (
        <Layout stack gap="4px">
            {fields?.length ? (
                <Layout gap="16px" vAlign="flex-end">
                    <div className={classes.categoryColumn}>
                        <small className="form-text text-muted required">category</small>
                    </div>
                    <div className={classes.currencyColumn}>
                        <small className="form-text text-muted">currency</small>
                    </div>
                    <Layout className={classes.scheduledColumn}>
                        <small className="form-text text-muted">scheduled</small>
                    </Layout>
                    <small className={classes.actualColumn}>
                        <span className="form-text text-muted">reported</span>
                    </small>
                    <Layout className={classes.deductFromBudgetColumn}>
                        <small className="form-text text-muted">deduct from budget</small>
                    </Layout>
                    <div className={classes.actionsColumn} />
                </Layout>
            ) : null}
            <Layout stack gap="16px">
                {fields.map((cost, index) => {
                    return (
                        <Layout key={cost} gap="16px" vAlign="flex-start">
                            <div className={classes.categoryColumn}>
                                <Field
                                    inline
                                    name={`${cost}.costCategory`}
                                    dataCy={`${cost}.costCategory`}
                                    component={dropdownField}
                                    placeholder="Select category"
                                    valueField="_id"
                                    dropUp
                                    wrapperClassName="mb-0"
                                    itemComponent={FixedCostCategoryTemplateItem}
                                    valueComponent={FixedCostCategoryTemplateItem}
                                    textField={item => (Object.keys(item).length ? item.name : '')}
                                    data={availableToSelectCategoryTemplates}
                                    filter="contains"
                                />
                            </div>
                            <div className={classes.currencyColumn}>
                                <Field
                                    inline
                                    name={`${cost}.currency`}
                                    component={dropdownFieldMaterial}
                                    valueField="value"
                                    textField="name"
                                    data={getCurrencies()}
                                    autocompleteProps={{
                                        disableClearable: true,
                                        disablePortal: false,
                                        filterSelectedOptions: false
                                    }}
                                    disabled={fixedCosts[index]?.costCategory?.deleted}
                                />
                            </div>
                            <div className={classes.scheduledColumn}>
                                <Field
                                    type="number"
                                    step="any"
                                    name={`${cost}.scheduled`}
                                    normalize={normalizeFloatNumber}
                                    icon={getCurrencySymbol(fixedCosts[index]?.currency?.value)}
                                    component={materialInputGroupField}
                                    onKeyPress={handleNumberInputKeyPress}
                                    onBlur={handleBlur}
                                    disabled={fixedCosts[index]?.costCategory?.deleted}
                                />
                            </div>
                            <div className={classes.actualColumn}>
                                <Field
                                    name={`${cost}.actual`}
                                    normalize={normalizeFloatNumber}
                                    icon={getCurrencySymbol(fixedCosts[index]?.currency?.value)}
                                    type="number"
                                    step="any"
                                    component={materialInputGroupField}
                                    onKeyPress={handleNumberInputKeyPress}
                                    onBlur={handleBlur}
                                    disabled={fixedCosts[index]?.costCategory?.deleted}
                                />
                            </div>
                            <Layout className={classes.deductFromBudgetColumn} hAlign="center">
                                <WrapWithTooltip tooltip="If box is checked then reports will also deduct this fixed cost from the budget">
                                    <Field
                                        type="checkbox"
                                        name={`${cost}.deductFromProjectBudget`}
                                        inputProps={{
                                            name: `${cost}.deductFromProjectBudget`,
                                            checked: currentFormValues.fixedCosts[index]?.deductFromProjectBudget,
                                            onChange: handleDeductFromBudgetChange,
                                        }}
                                        component={Checkbox}
                                        disabled={fixedCosts[index]?.costCategory?.deleted}
                                    />
                                </WrapWithTooltip>
                            </Layout>
                            <Layout className={classes.actionsColumn} hAlign="flex-end">
                                <WrapWithTooltip tooltip="Remove">
                                    <ButtonBase
                                        disableRipple
                                        onClick={() => handleRemoveRow(index)}
                                        data-cy={`${cost}-delete-fixed-cost`}
                                    >
                                        <CancelIcon />
                                    </ButtonBase>
                                </WrapWithTooltip>
                            </Layout>
                        </Layout>
                    );
                })}
            </Layout>
            {maxNumberOfFixedCosts > fields?.length ? (
                <Layout gap="16px" vAlign="center">
                    <div className={classes.categoryColumn}>
                        <Button
                            className={classnames(classes.addFixedCostBtn, 'btn btn-link')}
                            color="link"
                            onClick={handleAddCost}
                            data-cy="add-new-fixed-cost"
                        >
                            + add new fixed cost
                        </Button>
                    </div>
                </Layout>
            ) : null}
        </Layout>
    );
};

const useStyles = makeStyles({
    addFixedCostBtn: {
        fontWeight: 'normal !important',
        '&:focus': {
            boxShadow: 'none',
            textDecoration: 'none',
        },
    },
    categoryColumn: {
        width: '30%',
        '& .form-control': {
            height: '37px',
        },
    },
    currencyColumn: {
        width: '20%',
    },
    scheduledColumn: {
        width: '15%',
    },
    actualColumn: {
        width: '15%',
    },
    deductFromBudgetColumn: {
        width: '15%',
        justifyContent: 'center',
        textAlign: 'center',
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
    },
    actionsColumn: {
        width: '5%',
        alignSelf: 'center',
    },
});
