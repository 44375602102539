import { DayPilot } from 'daypilot-pro-react';
import { ACTIVE, UNASSIGNED } from 'enums/criteriaEnum';
import { without, includes } from 'lodash';
import { PROJECT } from 'modules/scheduler/enums/builderTypeEnum';

const unassignedChildDayPilotMenu = new DayPilot.Menu({
    theme: 'hub-context',
    cssClassPrefix: 'hub-context',
    menuTitle: 'Unassigned Categories',
    showMenuTitle: false,
    hideOnMouseOut: false,
    useShadow: true,
    zIndex: 10,
});

export default (
    currentItem,
    hasSettingAddEditResources,
    hasSettingResourceGroupsRights,
    groupBookings,
    actions,
    unassignedGroup
) => {
    unassignedChildDayPilotMenu.items = [];

    if (groupBookings) {
        unassignedChildDayPilotMenu.items.push({
            text: 'Toggle Grouping',
            onClick: ({ source }) => {
                if (0 < source.groups.expanded().length) {
                    source.groups.collapseAll();
                } else {
                    source.groups.expandAll();
                }
            },
        });
    }

    const unassignedGroupId = unassignedGroup?._id;

    // // ONLY ALLOWED TO REMOVE IN SINGLE OR CUSTOM GROUPS.
    if (
        currentItem &&
        currentItem.hasOwnProperty('criteria') &&
        !currentItem.queryParams?.itemGroupIds?.includes(unassignedGroupId) &&
        !includes([UNASSIGNED, ACTIVE], currentItem.criteria) &&
        hasSettingResourceGroupsRights &&
        currentItem.queryParams?.itemType !== PROJECT.name
    ) {
        unassignedChildDayPilotMenu.items.push({
            text: 'Remove Row',
            onClick: ({ source }) => {
                if (actions && actions.updateProject) {
                    const data = without(currentItem.resourceIds, source.tags.resource._id);
                    actions.updateProject({ project: { resources: data } });
                } else if (actions && actions.updateResourceGroup) {
                    let data = {
                        isSmart: currentItem.isSmart,
                        type: currentItem.type,
                        name: currentItem.name,
                        queryParams: {
                            groupsToAdd: [],
                            groupsToRemove: [],
                            itemsToAdd: [],
                            itemsToRemove: [source.tags.resource._id],
                            ...(currentItem.queryParams || {}),
                        },
                    };

                    if (currentItem.isSmart) {
                        data.queryParams.filters.forEach(filterObject => {
                            if (filterObject.name === 'resources') {
                                filterObject.values = (filterObject.values || []).filter(vId => {
                                    return vId !== source.tags.resource._id;
                                });
                            }
                        });
                    }
                    actions.updateResourceGroup(data);
                }
            },
        });
    }

    if (hasSettingAddEditResources) {
        unassignedChildDayPilotMenu.items.push({
            shouldDisplay: ({ resource }) => resource.hasRightsToResource,
            text: 'Edit unassigned...',
            onClick: ({ source }) => {
                actions && actions.showResourceModal(source.tags.resource._id, 'UNASSIGNED');
            },
        });
    }

    return unassignedChildDayPilotMenu;
};
