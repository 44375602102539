import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import ColorPicker from 'shared/colorPicker';
import { Toggler, SelectedColor, Text, ToggleIconHolder } from './styles';
import { useSelector } from 'react-redux';
import { MenuCollapse } from '../menuCollapse';
import { useAppKeyWords } from '../../../../../../hooks';
import SuccessButton from '../../../../../../shared/buttons/success';

const ColorToggler = props => {
    const {
        color,
        onChange,
        noCollapseAfterChange,
        pickerWidth,
        onToggleOpen,
        closeToggle,
        useProjectColor,
        inheritOption,
        onInheritClick,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { projectKeyWord } = useAppKeyWords();

    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const isCategoryColorUsed = 'CATEGORY_COLOR_BOOKING' === companySettings.grid.catColorDisplayNew;

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    const handleColorChange = color => {
        onChange(color.hex);
        !noCollapseAfterChange && setIsOpen(prevState => !prevState);
    };

    return (
        <>
            <Toggler data-cy="color-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    <span>Color:</span> <SelectedColor color={color} />
                    {isCategoryColorUsed && <span> Category Inherited</span>}
                    {!isCategoryColorUsed && useProjectColor && <span> {projectKeyWord}/Event Inherited</span>}
                    {!isCategoryColorUsed && !useProjectColor && <span> Custom Color</span>}
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="px-2 py-2">
                    {isCategoryColorUsed && (
                        <Alert color="warning mt-3">
                            <b>Color Warning!</b> You are currently using category colors to define the bookings color,
                            you must first disable this feature under{' '}
                            <a className="primary" href="/settings#custom">
                                Settings / Customization
                            </a>{' '}
                            to use the custom booking colors feature instead.
                        </Alert>
                    )}
                    {!isCategoryColorUsed && (
                        <>
                            {inheritOption && !useProjectColor && (
                                <SuccessButton
                                    className="mx-3 mt-2"
                                    onClick={onInheritClick}
                                    name={`Inherit from ${projectKeyWord}`}
                                />
                            )}
                            <ColorPicker
                                isOpen={true}
                                pickerWidth={pickerWidth ? pickerWidth : 272}
                                positionAbsolute={false}
                                color={color}
                                showSelectedColor={false}
                                onColorChange={handleColorChange}
                            />
                        </>
                    )}
                </div>
            </MenuCollapse>
        </>
    );
};

ColorToggler.propTypes = {
    color: PropTypes.string,
    onChange: PropTypes.func,
    pickerWidth: PropTypes.number,
    noCollapseAfterChange: PropTypes.bool,
};

ColorToggler.defaultProps = {
    color: '',
    onChange: null,
    pickerWidth: null,
    noCollapseAfterChange: null,
};

export default ColorToggler;
