let schedulerRef = null;
window.schedulerRef = null;

/**
 * Hook that sets or reads the current scheduler reference
 * @returns {{ setSchedulerRef: function, schedulerRef: ref }} setter and getter: setSchedulerRef and schedulerRef
 */
const useSchedulerRef = () => {
    const setSchedulerRef = ref => {
        schedulerRef = ref;
        // Not the preferable approach, but some use cases are using schedulerRef inside memoization (useMemo, useCallback).
        // Since will not receive the updated version, when they are using inside their body schedulerRef, the execution will break
        window.schedulerRef = ref;
    };

    return {
        setSchedulerRef,
        schedulerRef,
    };
};

export default useSchedulerRef;
