import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormControlLabel } from '@material-ui/core';
import { Layout, Typography, Checkbox, SelectReduxFormWrapper } from '@hub-mono/ui';
import { useAppKeyWords } from '../../../../hooks';

const Approvers = React.memo(({ selectedApprovers, approvers, defaultApprovers, notifyPM, onChange, isVacation }) => {
    const { vacationEventName } = useAppKeyWords();

    useEffect(() => {
        if (isVacation) {
            onChange({ notifyPM: false });
        }
    }, [isVacation, onChange]);

    return (
        <Layout stack gap={24}>
            <Layout stack gap="--Spacing-50">
                <Typography variant="label" size="large" prominent>
                    Approver
                </Typography>
                <Typography variant="body" size="small">
                    Select an approver (if no default is provided) to approve the{' '}
                    {isVacation ? `${vacationEventName} request` : 'request'}
                </Typography>
            </Layout>

            <Layout stack gap="--Spacing-100">
                <Field
                    name="approvers"
                    dataCy="approvers-multiselect"
                    label="Approvers"
                    placeholder="Select approvers"
                    optionLabel="name"
                    optionValue="_id"
                    component={SelectReduxFormWrapper}
                    multiple
                    fixedOptions={selectedApprovers?.length ? defaultApprovers : []}
                    options={approvers}
                    disableClearable
                />
                <Typography variant="body" size="small" color="neutral60">
                    Selected approver will be responsible for approving the requests. Notifications will get sent to
                    this approver for this request and they will be able to view, edit, approve, reject, remove &
                    resubmit the request.
                </Typography>
            </Layout>

            {!isVacation && (
                <Layout pl={16}>
                    <FormControlLabel
                        style={{ marginBottom: 0 }}
                        label="Notify project managers on request"
                        control={
                            <Checkbox
                                checked={notifyPM ?? false}
                                onChange={e => {
                                    onChange({ notifyPM: e.target.checked });
                                }}
                                name="notifyPM"
                                dataCy="btn-switch-availabilityHover"
                            />
                        }
                    />
                </Layout>
            )}
        </Layout>
    );
});

Approvers.propTypes = {
    approvers: PropTypes.array.isRequired,
    defaultApprovers: PropTypes.array.isRequired,
};

export default Approvers;
