import { refreshBookings, refreshMilestonesPhases } from '../utils/schedulerUtil';
import { LEGACY } from '../enums/viewModeEnum';
import { useParams } from 'react-router-dom';
import { useAccountPreferences } from './useAccountPreferences';
import { useHasRights } from '../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

const rights = [
    {
        rights: ['viewMilestones', 'viewPhases'],
        rule: 'oneOf',
        name: 'canViewMilestonesOrPhases',
    },
    {
        rights: ['viewStartEndDates'],
        rule: 'one',
        name: 'canViewProjectDates',
    },
];

export const useUpdateBookings = props => {
    const { viewObject, schedulerRef } = props;
    const params = useParams();
    const {
        gridPreferences: { mode },
    } = useAccountPreferences();
    const dispatch = useDispatch();
    const { canViewMilestonesOrPhases, canViewProjectDates } = useHasRights(rights);
    const { bookingStatuses, bookingCategories, bookingProjectsEvents, bookingResourcesUW } = useSelector(
        state => state.scheduler.filters
    );

    const refresh = useCallback(
        ({bookingId}) => {
            if (!schedulerRef.current) {
                return;
            }

            refreshBookings({
                viewObject,
                schedulerRef,
                canViewMilestonesOrPhases,
                canViewProjectDates,
                filters: {
                    bookingCategories,
                    bookingStatuses,
                    bookingProjectsEvents,
                    bookingResourcesUW,
                    schedulerViewProjectIds:
                        viewObject.isSingleProjectView && params.projectId && mode === LEGACY.value
                            ? [params.projectId]
                            : undefined,
                },
                dispatch,
                bookingId,
            });
        },
        [
            bookingCategories,
            bookingProjectsEvents,
            bookingResourcesUW,
            bookingStatuses,
            canViewMilestonesOrPhases,
            canViewProjectDates,
            dispatch,
            mode,
            params.projectId,
            schedulerRef,
            viewObject,
        ]
    );

    useEffect(() => {
        const refreshBookingsListener = event => {
            refresh(event.detail || {});
        };

        window.addEventListener('refreshBookings', refreshBookingsListener);

        return () => {
            window.removeEventListener('refreshBookings', refreshBookingsListener);
        };
    }, [refresh]);


    const refreshMilestonesPhasesCallback = useCallback(() => {
        if (!schedulerRef.current) {
            return;
        }

        refreshMilestonesPhases({
            viewObject,
            schedulerRef,
            canViewMilestonesOrPhases,
            filters: {
                bookingProjectsEvents,
            },
        });
    }, [bookingProjectsEvents, canViewMilestonesOrPhases, schedulerRef, viewObject]);

    useEffect(() => {
        const refreshMilestonesPhasesListener = () => {
            refreshMilestonesPhasesCallback();
        };

        window.addEventListener('refreshMilestonesPhases', refreshMilestonesPhasesListener);

        return () => {
            window.removeEventListener('refreshMilestonesPhases', refreshMilestonesPhasesListener);
        };
    }, [refreshMilestonesPhasesCallback]);
    
    return {
        refresh,
    };
};
