import { call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import * as actionTypes from 'actions/actionTypes';
import {
    getCategoryTemplates,
    createCategoryTemplate,
    updateCategoryTemplate,
    deleteCategoryTemplate,
} from 'actions/categoryTemplateActions';
import { addNotification } from 'actions/notificationActions';
import { hideModal } from 'actions/modalActions';
import { getCompanySettings } from 'selectors/company';
import { getAllocationDisplayValue } from 'utils/allocationUtil';
import {
    getCategoryTemplatesRequest,
    createCategoryTemplateRequest,
    updateCategoryTemplateRequest,
    deleteCategoryTemplateRequest,
} from 'api/categoryTemplate';
import { monitoring } from '../monitoring';

function* handleGetCategoryTemplates() {
    try {
        const companySettings = yield select(getCompanySettings);
        const categoryTemplates = yield call(getCategoryTemplatesRequest, { showDeleted: true });
        yield put(getCategoryTemplates.success(categoryTemplates, companySettings.useCategoriesAllocation));
    } catch (error) {
        monitoring.captureException(error);
        yield put(getCategoryTemplates.failure());
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleCreateCategoryTemplate(action) {
    try {
        const companySettings = yield select(getCompanySettings);
        const categoryTemplate = yield call(createCategoryTemplateRequest, action.payload.data);
        action.payload.callback &&
            action.payload.callback(
                companySettings.useCategoriesAllocation
                    ? {
                          ...categoryTemplate,
                          allocationText: getAllocationDisplayValue(categoryTemplate),
                      }
                    : categoryTemplate
            );
        yield put(createCategoryTemplate.success(categoryTemplate, companySettings.useCategoriesAllocation));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleUpdateCategoryTemplate(action) {
    try {
        const companySettings = yield select(getCompanySettings);
        const { categoryTemplateId, data } = action.payload;
        const categoryTemplate = yield call(updateCategoryTemplateRequest, categoryTemplateId, data);

        action.payload?.callback && action.payload?.callback(categoryTemplate);

        yield put(updateCategoryTemplate.success(categoryTemplate, companySettings.useCategoriesAllocation));
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleDeleteCategoryTemplate(action) {
    try {
        const categoryTemplate = yield call(deleteCategoryTemplateRequest, action.payload.categoryTemplateId);

        action.payload?.cb && action.payload.cb();

        yield put(deleteCategoryTemplate.success(categoryTemplate));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ type: 'danger' }));
    }
}

export default function* categoryTemplatesWatcher() {
    yield takeLatest(actionTypes.GET_CATEGORY_TEMPLATES['REQUEST'], handleGetCategoryTemplates);
    yield takeEvery(actionTypes.CREATE_CATEGORY_TEMPLATE['REQUEST'], handleCreateCategoryTemplate);
    yield takeEvery(actionTypes.UPDATE_CATEGORY_TEMPLATE['REQUEST'], handleUpdateCategoryTemplate);
    yield takeLatest(actionTypes.DELETE_CATEGORY_TEMPLATE['REQUEST'], handleDeleteCategoryTemplate);
}
