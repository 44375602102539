import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider, withStyles } from '@material-ui/core';

export const MenuButton = ({
    name,
    options,
    disabled,
    variant,
    className,
    menuPosition = {},
    dataCy,
    endIcon
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <DropdownButton
                data-cy={dataCy}
                onClick={handleClick}
                variant={variant}
                disabled={disabled || !options.length}
                className={className}
                endIcon={endIcon}
                disableRipple
            >
                {name}
            </DropdownButton>
            <Menu
                anchorEl={anchorEl}
                anchorReference={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                {...menuPosition}
            >
                {options.map(option => {
                    if (option?.divider) return <Divider />;
                    return (
                        <MenuItem
                            key={option.name}
                            onClick={event => {
                                handleClose();
                                option.action(event);
                            }}
                            data-cy={option.dataCy}
                        >
                            {option.title}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

const DropdownButton = withStyles(() => ({
    root: {
        textTransform: 'none',
    },
}))(Button);

MenuButton.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    menuPosition: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            action: PropTypes.func,
            disabled: PropTypes.bool,
        })
    ),
};

MenuButton.defaultProps = {
    name: 'Open',
    options: [],
};
