import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import { FormControlLabel, RadioGroup, FormControl, Radio, Divider, TextField } from '@material-ui/core';
import { useClickOutside, useIsExtensionInstalled, usePressEscape } from 'hooks';
import { SMART_SCHEDULES } from 'enums/extensionShortIdEnum';
import { SYSTEM } from 'modules/scheduler/enums/groupTypeEnum';
import { GROUP, SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { Content, PopUpHeader } from './styles';
import { INTERNAL, PRIVATE } from 'enums/groupVisibilityEnum';
import { Lock } from '@material-ui/icons';
import SuccessButton from '../buttons/success';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';

const SaveAsPopUp = props => {
    const { create, update, config, current, children, ...restOfProps } = props;
    const { saveBtn = {}, saveAsBtn = {}, saveAsGroupBtn = {} } = config;

    const isSmartSchedulesInstalled = useIsExtensionInstalled(SMART_SCHEDULES);
    const { type: currentType, visibility: currentVisibility } = current;
    const initType = currentType || GROUP;
    const initVisibility = currentVisibility || PRIVATE;

    const isSystemGroup = current && SYSTEM === current.groupType;

    const ref = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState(restOfProps.name);
    const [type, setType] = useState(initType);
    const [visibility, setVisibility] = useState(initVisibility);
    const toggle = useCallback(event => {
        event && event.preventDefault();
        setIsOpen(prevState => !prevState);
    }, []);

    const isSaveSchedule = type === SCHEDULER_TEMPLATE;
    const radioType = GROUP === type ? type : visibility;

    const GreenRadio = withStyles({
        root: {
            '&$checked': {
                color: green[600],
            },
            padding: '3px',
        },
        checked: {},
    })(props => {
        const { dataCy, ...restOfProps } = props;
        return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
    });

    useEffect(() => {
        if (isOpen) {
            setType(initType);
        }
    }, [initType, isOpen]);

    const handleClickOutside = useCallback(
        event => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        },
        [ref, setIsOpen]
    );

    const handlePressEscape = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    useClickOutside(handleClickOutside);
    usePressEscape(handlePressEscape);

    useEffect(() => {
        setName(restOfProps.name);
        setType(initType);
        setVisibility(initVisibility);
    }, [initType, initVisibility, restOfProps.name]);

    const onInputChange = event => {
        setName(event.target.value);
    };

    const onTypeChange = event => {
        if ('INTERNAL' === event.target.value || 'PRIVATE' === event.target.value) {
            setVisibility(event.target.value);
            setType(SCHEDULER_TEMPLATE);
            if (props.onTypeChange) {
                props.onTypeChange(SCHEDULER_TEMPLATE);
            }
        } else {
            setType(event.target.value);
            if (props.onTypeChange) {
                props.onTypeChange(event.target.value);
            }
        }
    };

    const onUpdate = () => {
        update({
            title: name,
            groupType: type,
            visibility,
        });
        toggle();
    };

    const onSaveAsNew = () => {
        create({
            title: name,
            groupType: type,
            visibility,
        });
        toggle();
    };

    const getChildrenProps = useMemo(
        () => ({
            toggle,
        }),
        [toggle]
    );

    const InternalLabelJSX = (
        <>
            Internal schedule <Lock style={{ fontSize: '15px', marginTop: '-2px' }} />
        </>
    );

    const PrivateLabelJSX = (
        <>
            Private schedule <Lock style={{ fontSize: '15px', marginTop: '-2px' }} />
        </>
    );

    return (
        <div className="w-100" ref={ref}>
            {children(getChildrenProps)}
            <Content
                ref={ref}
                className={classNames({
                    'd-none': !isOpen,
                    top: !config.directionBottom,
                    bottom: config.directionBottom,
                    'px-4 py-4': isOpen,
                })}
            >
                <PopUpHeader>{!saveBtn.hide ? 'Update/Save as new...' : 'Save as new...'}</PopUpHeader>
                <Divider />
                <Row className="mt-3 mb-2">
                    <Col>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="save as"
                                name="saveAsSelector"
                                value={radioType}
                                onChange={onTypeChange}
                                className="ml-1"
                            >
                                <FormControlLabel
                                    value={GROUP}
                                    disabled={saveAsGroupBtn.disabled}
                                    control={<GreenRadio dataCy={GROUP} />}
                                    label="Group"
                                    className="mb-2"
                                />
                                <FormControlLabel
                                    value={INTERNAL}
                                    disabled={
                                        !isSmartSchedulesInstalled || config.disablePrivateAndInternalRadioOptions
                                    }
                                    control={<GreenRadio dataCy={INTERNAL} />}
                                    label={!isSmartSchedulesInstalled ? InternalLabelJSX : 'Internal schedule'}
                                    className="my-2"
                                />
                                <FormControlLabel
                                    value={PRIVATE}
                                    disabled={
                                        !isSmartSchedulesInstalled || config.disablePrivateAndInternalRadioOptions
                                    }
                                    control={<GreenRadio dataCy={PRIVATE} />}
                                    label={!isSmartSchedulesInstalled ? PrivateLabelJSX : 'Private schedule'}
                                    className="mt-2"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Col>
                </Row>
                <TextField
                    value={name}
                    variant="outlined"
                    label="Name"
                    fullWidth
                    onChange={onInputChange}
                    data-cy="input-field-save-as-value"
                    placeholder="Add a name"
                    className="mb-3 mt-2"
                />
                <>
                    <SuccessButton
                        className="mr-2 pl-0"
                        onClick={toggle}
                        data-cy="button--cancel"
                        type="btn-cancel"
                        name="Cancel"
                    />
                    <SuccessButton
                        onClick={onSaveAsNew}
                        data-cy="button--save-as-new"
                        type="btn-primary"
                        tooltipText={saveAsBtn.tooltip}
                        disabled={!name || saveAsBtn.disabled || (!isSmartSchedulesInstalled && isSaveSchedule)}
                        name="Save as new"
                        className={!saveBtn.hide ? 'float-right' : 'mr-0 float-right'}
                    />
                    {!saveBtn.hide && (
                        <SuccessButton
                            className="mr-2 float-right"
                            onClick={onUpdate}
                            data-cy="button--update"
                            type="btn-secondary"
                            tooltipText={saveBtn.tooltip}
                            disabled={
                                !name ||
                                isSystemGroup ||
                                (name === restOfProps.name && visibility === initVisibility && saveBtn.disabled) ||
                                (!isSmartSchedulesInstalled && isSaveSchedule)
                            }
                            name="Update"
                        />
                    )}
                </>
            </Content>
        </div>
    );
};

SaveAsPopUp.propTypes = {
    name: PropTypes.string,
    config: PropTypes.shape({
        saveAsBtn: PropTypes.shape({
            disabled: PropTypes.bool,
            tooltip: PropTypes.string,
        }),

        saveBtn: PropTypes.shape({
            disabled: PropTypes.bool,
            tooltip: PropTypes.string,
            hide: PropTypes.bool,
        }),

        saveAsGroupBtn: PropTypes.shape({
            disabled: PropTypes.bool,
        }),

        saveAsScheduleBtn: PropTypes.shape({
            disabled: PropTypes.bool,
        }),

        displayPrivateAndInternalRadioOptions: PropTypes.bool,
        disablePrivateAndInternalRadioOptions: PropTypes.bool,
        directionBottom: PropTypes.bool,
        saveAsButtonLabel: PropTypes.string,
    }),
    current: PropTypes.object,
    onTypeChange: PropTypes.func,
};

SaveAsPopUp.defaultProps = {
    name: '',
    config: {},
};

export default SaveAsPopUp;
