/* eslint-env browser */
/* eslint-disable yoda */
import _ from 'underscore';
import { STATUS_ACTIVE } from '../enums/projectEnum';

const EMPTY_ARRAY = [];

class CompanyTreeModel {
    constructor(account, data) {
        this._resourceGroups = [];
        this._resourceGroupsFlatten = [];
        this._projectGroups = [];
        this._projectGroupsFlatten = [];
        this._unpermittedResources = [];
        this._unpermittedProjects = [];
        this._resources = [];
        this._resourcesUnion = [];
        this._projectsUnion = [];
        this._projects = [];
        this._projectsUnion = [];
        this._settings = {
            report: {
                avgDailyCapacity: 0,
            },
        };
        this._customFieldTemplates = [];
        this._calendars = [];
        this._holidays = [];
        this._tagTemplates = [];
        this._categoryTemplates = [];
        this._categoryGroups = [];
        this._companyId = null;
        this.myManagedProjectIdsArray = [];
        this.myManagedResourceIdsArray = [];
        this.systemGroupIds = {};
        this._id = null;

        this.init(data, account);
    }

    getDefaultApproverIdsByResourceId(rid) {
        return rid && this.getResourceById(rid).defaultApproverIds
            ? this.getResourceById(rid).defaultApproverIds
            : EMPTY_ARRAY;
    }

    getProjectManagers() {
        return _.filter(this._resources, res => res.isProjectManager);
    }

    getResourceNameById(resourceId) {
        let resource = _.find(this._resourcesUnion, resource => resource._id === resourceId);

        return resource ? `${resource.firstName} ${resource.lastName}` : '';
    }

    getWeekType() {
        return this._settings.weekStartDay === 0 ? 'week' : 'isoWeek';
    }

    getSettings() {
        return this._settings;
    }

    getFilteredCategoryTemplates() {
        const categoryTemplates = _.filter(this._categoryTemplates, category => !category.deleted);

        return _.chain(categoryTemplates)
            .sortBy(category => category.type !== 'NON_OPTIONAL')
            .sortBy(category => category.name.toLowerCase())
            .value();
    }

    getResourceGroupsBySource(resourceGroups, includeUnassigned, includeArchived = false) {
        let withOutArchived;

        if (includeUnassigned) {
            if (!this.getSettings().grid?.displayArchivedInScheduler && !includeArchived) {
                withOutArchived = _.filter(resourceGroups, group => group.criteria !== 'ARCHIVED');

                return _.sortBy(withOutArchived, 'name');
            }

            return _.sortBy(resourceGroups, 'name');
        }

        if (!this.getSettings().grid?.displayArchivedInScheduler && !includeArchived) {
            let withoutArchivedAndWithoutUnassigned = _.filter(
                resourceGroups,
                group => group.criteria !== 'ARCHIVED' && group.criteria !== 'UNASSIGNED'
            );

            return _.sortBy(withoutArchivedAndWithoutUnassigned, 'name');
        }

        return _.filter(resourceGroups, group => group.criteria !== 'UNASSIGNED');
    }

    getResourceGroupsFlatten(includeUnassigned, includeArchived = false) {
        return this.getResourceGroupsBySource(this._resourceGroupsFlatten, includeUnassigned, includeArchived);
    }

    getAllResourceGroupIds() {
        let resourceGroups = this._resourceGroupsFlatten;
        if (!this.getSettings().grid?.displayArchivedInScheduler) {
            resourceGroups = _.filter(
                resourceGroups,
                group => group.criteria !== 'ARCHIVED' && group.criteria !== 'UNASSIGNED'
            );
        }

        return _.pluck(resourceGroups, '_id');
    }

    isActiveResourceGroupPermitted() {
        return _.some(this._resourceGroupsFlatten, group => group.criteria === 'ACTIVE');
    }

    isActiveProjectGroupPermitted() {
        return _.some(this._projectGroupsFlatten, group => group.criteria === 'ACTIVE');
    }

    getProjectGroupsBySource(projectGroups, includeArchived = false) {
        if (!this.getSettings().grid?.displayArchivedInScheduler && !includeArchived) {
            let withOutArchived = _.filter(projectGroups, group => group.criteria !== 'ARCHIVED');

            return _.sortBy(withOutArchived, 'name');
        }

        return _.sortBy(projectGroups, 'name');
    }

    getProjectGroups() {
        return this.getProjectGroupsBySource(this._projectGroups);
    }

    getProjectGroupsFlatten(includeArchived) {
        return this.getProjectGroupsBySource(this._projectGroupsFlatten, includeArchived);
    }

    getAllProjectGroupIds() {
        return _.pluck(this.getProjectGroupsFlatten(), '_id');
    }

    getActiveResourceIds() {
        return _.map(this.getActiveResources(), resource => resource._id);
    }

    getActiveProjectIds() {
        return _.map(
            _.filter(this._projects, project => project.status === STATUS_ACTIVE.value && project.type === 'REGULAR'),
            project => project._id
        );
    }

    getActiveEventIds() {
        return _.map(
            _.filter(this._projects, project => project.status === STATUS_ACTIVE.value && project.type === 'EVENT'),
            project => project._id
        );
    }

    getUnassignedIds() {
        return _.map(
            _.filter(this._resources, resource => resource.type === 'UNASSIGNED'),
            resource => resource._id
        );
    }

    getActiveResources() {
        return _.filter(
            this._resources,
            resource => resource.type === 'REGULAR' && resource.status === 'STATUS_ACTIVE'
        );
    }

    getResourceById(resourceId) {
        return _.find(this._resources, resource => resource._id === resourceId) || {};
    }

    init(companyTree, account) {
        if (!companyTree) {
            return;
        }

        this._resources = _.sortBy(companyTree.resources, res => res.firstName.toLowerCase());
        this._projects = _.sortBy(companyTree.projects, pro => pro.name.toLowerCase());
        this._unpermittedResources = companyTree.unpermittedResources;
        this._unpermittedProjects = companyTree.unpermittedProjects;
        this._resourcesUnion = _.sortBy(companyTree.resources.concat(companyTree.unpermittedResources), res =>
            res.firstName.toLowerCase()
        );
        this._projectsUnion = _.sortBy(companyTree.projects.concat(companyTree.unpermittedProjects), pro =>
            pro.name.toLowerCase()
        );
        this._resourceGroupsFlatten = companyTree.resourceGroups;
        this._resourceGroups = companyTree.resourceGroups;
        this._projectGroupsFlatten = companyTree.projectGroups;
        this._projectGroups = companyTree.projectGroups;
        this._settings = companyTree.settings;
        this._customFieldTemplates = companyTree.customFieldTemplates;
        this._tagTemplates = companyTree.tagTemplates;
        this._categoryTemplates = companyTree.categoryTemplates;
        this._categoryGroups = companyTree.categoryGroups;
        this._companyId = companyTree.companyId;
        this._calendars = companyTree.calendars;
        this.systemGroupIds = {
            resource: _.find(
                companyTree.resourceGroups,
                group => 'SYSTEM' === group.groupType && 'ACTIVE' === group.criteria
            )?._id,
            project: _.find(
                companyTree.projectGroups,
                group => 'SYSTEM' === group.groupType && 'ACTIVE' === group.criteria
            )?._id,
        };
        this._id = companyTree._id;

        _.each(companyTree.calendars, calendar => (this._holidays = [...this._holidays, ...calendar.holidays]));
        this._createManualGroups(account);
        this._formatData();
    }

    updateData(companyTree) {
        if (!companyTree) {
            return;
        }
        this._id = companyTree._id;
        this._resources = _.sortBy(companyTree._resources, res => res.firstName.toLowerCase());
        this._projects = _.sortBy(companyTree._projects, pro => pro.name.toLowerCase());
        this._projectsUnion = _.sortBy(companyTree._projects.concat(companyTree._unpermittedProjects), pro =>
            pro.name.toLowerCase()
        );
        this._resourcesUnion = _.sortBy(companyTree._resources.concat(companyTree._unpermittedResources), res =>
            res.firstName.toLowerCase()
        );

        this._resourceGroupsFlatten = companyTree._resourceGroupsFlatten;
        this._resourceGroups = companyTree._resourceGroupsFlatten;
        this._projectGroupsFlatten = companyTree._projectGroupsFlatten;
        this._projectGroups = companyTree._projectGroupsFlatten;
        this._settings = companyTree._settings;
        this._customFieldTemplates = companyTree._customFieldTemplates;
        this._categoryGroups = companyTree._categoryGroups;
        this._holidays = companyTree._holidays;
        this._calendars = companyTree._calendars;
        this._tagTemplates = companyTree._tagTemplates;
        this._categoryTemplates = companyTree._categoryTemplates;
        this._companyId = companyTree._companyId;
        this.systemGroupIds = companyTree.systemGroupIds;
        this.myManagedProjectIdsArray = companyTree.myManagedProjectIdsArray;
        this.myManagedResourceIdsArray = companyTree.myManagedResourceIdsArray;

        this._createManualGroups();
        this._formatData();

        return this;
    }

    getProjectsIdsIManage() {
        return this.myManagedProjectIdsArray ? this.myManagedProjectIdsArray : EMPTY_ARRAY;
    }

    getResourceIdsIManage() {
        return this.myManagedResourceIdsArray ? this.myManagedResourceIdsArray : EMPTY_ARRAY;
    }

    _createManualGroups(account = {}) {
        if (account.isProjectManager) {
            this.myManagedProjectIdsArray = _.chain(this._projects)
                .filter(pro => {
                    if (pro.status !== 'Archived') {
                        return _.find(pro.projectManagerIds, id => id === account.resourceId);
                    }

                    return false;
                })
                .map(pro => pro._id, {})
                .value();

            this.myManagedResourceIdsArray = _.chain(this._projects)
                .filter(pro => {
                    if (pro.status !== 'Archived') {
                        return _.find(pro.projectManagerIds, id => id === account.resourceId);
                    }

                    return false;
                })
                .map(project => project.resourceIds, {})
                .reduceRight((a, b) => a.concat(b), [])
                .uniq()
                .value();
        }
    }

    _formatData() {
        this._resourceGroups = this._parseGroups(_.clone(this._resourceGroupsFlatten), 'resourceSubGroups');
        this._projectGroups = this._parseGroups(_.clone(this._projectGroupsFlatten), 'projectSubGroups');
    }

    _parseGroups(array, childProperty) {
        let list = [];
        _.each(array, function(g1) {
            if (!g1.parentGroupId) {
                g1[childProperty] = _.filter(array, function(g2) {
                    return g1._id === g2.parentGroupId;
                });
                list[list.length] = g1;
            }
        });
        return list;
    }
}

export default CompanyTreeModel;
