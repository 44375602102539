import { isNaN } from 'underscore';
import { formatNumber } from '../../utils/formatingUtil';

// objData can be an event or just object with name, value props
const getAllocationValuesChanges = (objData, allocationDefaultValues) => {
    const { hours, total, percentage } = allocationDefaultValues;

    let name, value;
    if (objData.currentTarget) {
        name = objData.currentTarget.name;
        value = parseFloat(objData.currentTarget.value);
    } else {
        name = objData.name;
        value = parseFloat(objData.value);
    }

    let newValues = {
        percentage: 0,
        hours: 0,
        total: 0,
    };

    if (isNaN(value)) return newValues;

    switch (name) {
        case 'percentage':
            newValues = {
                percentage: formatNumber(value),
                hours: formatNumber((value * hours) / percentage),
                total: formatNumber((value * total) / percentage),
            };
            break;
        case 'hours':
            newValues = {
                percentage: isNaN(formatNumber((value * percentage) / hours))
                    ? 0
                    : formatNumber((value * percentage) / hours),
                hours: formatNumber(value),
                total: formatNumber((formatNumber((value * percentage) / hours) * total) / percentage),
            };
            break;
        case 'total':
            newValues = {
                percentage: isNaN(formatNumber((value * percentage) / total))
                    ? 0
                    : formatNumber((value * percentage) / total),
                hours: formatNumber((formatNumber((value * percentage) / total) * hours) / percentage),
                total: formatNumber(value),
            };
            break;
    }

    return newValues;
};

export default getAllocationValuesChanges;
