import createReducer from './helpers/createReducer';
import { updateObject } from './helpers/updater';
import * as actionTypes from '../actions/actionTypes';

const initialCustomFieldState = {
    resourceCustomFields: [],
    projectCustomFields: [],
    loading: false,
    initialized: false,
};

const setLoader = state => updateObject(state, { loading: true });

const setCustomFields = (state, action) =>
    updateObject(state, { loading: false, [`${action.payload.type}CustomFields`]: action.payload.customFields });

const setAllCustomFields = (state, action) =>
    updateObject(state, {
        loading: false,
        initialized: true,
        resourceCustomFields: action.payload.customFields.resourceCustomFields,
        projectCustomFields: action.payload.customFields.projectCustomFields,
    });

const handleGetAllCustomFieldsFailure = (state) => {
    return updateObject(state, {
        loading: false,
        initialized: true,
        resourceCustomFields: state.resourceCustomFields?.length > 0 ? state.resourceCustomFields : [],
        projectCustomFields: state.projectCustomFields?.length > 0 ? state.projectCustomFields : [],
    });
}

export default createReducer(initialCustomFieldState, {
    [actionTypes.GET_CUSTOM_FIELDS['REQUEST']]: setLoader,
    [actionTypes.GET_CUSTOM_FIELDS['SUCCESS']]: setCustomFields,
    [actionTypes.GET_ALL_CUSTOM_FIELDS['REQUEST']]: setLoader,
    [actionTypes.GET_ALL_CUSTOM_FIELDS['SUCCESS']]: setAllCustomFields,
    [actionTypes.GET_ALL_CUSTOM_FIELDS['FAILURE']]: handleGetAllCustomFieldsFailure,
    [actionTypes.GET_CUSTOM_FIELDS_V2['REQUEST']]: setLoader,
    [actionTypes.GET_CUSTOM_FIELDS_V2['SUCCESS']]: setAllCustomFields,
    [actionTypes.GET_CUSTOM_FIELDS_V2['FAILURE']]: handleGetAllCustomFieldsFailure,
});
