import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import addDays from 'date-fns/addDays';
import { startOfDay, endOfDay } from 'date-fns';
import { find, includes, map } from 'lodash';
import DayPicker from 'react-day-picker';
import { MenuItem, Divider } from '@material-ui/core';
import { DropdownItem as DefaultDropdownItem } from 'reactstrap';
import { useAppKeyWords, useHasRights, useIsExtensionInstalled } from 'hooks';
import { DEPENDENCIES, PM, TASKS } from 'enums/extensionShortIdEnum';
import { TYPE_EVENT } from 'enums/projectEnum';
import { SINGLE, PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { DEPENDENCIES_TAB_ID, NOTE_TAB_ID } from 'modules/modals/scheduleResourceModal/tabIcons';
import { useBooking } from 'modules/scheduler/hooks/useBooking';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import { openSidebar, updateBookingSelection } from 'actions/schedulerActions';
import {
    showBulkMoveModal,
    showMissingDeadlinesExtensionModal,
    showProjectModal,
    showResourceModal,
    showScheduleResourceModal,
} from 'actions/modalActions';
import { getProjects, updateProject } from 'actions/projectActions';
import { getResources, updateResource } from 'actions/resourceActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { makeGetFilteredProjects } from 'selectors/project';
import { makeGetPermittedResources } from 'selectors/resource';
import { makeCategoryGroups, selectFlatCategories } from 'selectors/categoryGroups';
import SearchList from 'modules/scheduler/components/searchList/searchList';
import NestedMenuItem, { NESTED_MENU_ITEM_SCROLLBAR_HEIGHT } from 'shared/nestedMenuItem';
import AllocationForm from './allocationForm';
import DatesForm from './datesForm';
import { CategoryColor, KeyBoardShortCutIcon } from './styles';
import { getAllocationDisplayValue } from 'utils/allocationUtil';
import { useMenuPermissions, useGetViewObject } from 'modules/scheduler/hooks';
import { createCySelector } from '../../../../../../../utils/cypressUtil';
import { makeStyles } from '@material-ui/core/styles';
import SuccessButton from '../../../../../../../shared/buttons/success';
import { NestedMenuItemManager } from '../../../../../../../shared/nestedMenuItem/context';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { TABS } from '../../../../footer/SidebarContext';
import { Adjust } from '@material-ui/icons';
import { selectIsDeadlinesExtensionInstalled } from '../../../../../../../selectors/company';
import { DeadlineForm } from '../../../shared/deadlineForm';
import { selectCurrentSelectedBooking } from '../../../../../../../selectors/scheduler';
import Scrollbar from '../../../../../../../shared/scrollbar';
import { isSameDay, isDaypilotDate } from '../../../../../../../utils/DateUtil';
import { calculateAllocationForCategory } from '../../../../../../../shared/allocation/calculateAllocationForCategory';
import { useHistory } from 'react-router-dom';
import { hideContextMenu } from '../../../../../../../actions/contextMenuActions';
import { useEditBookingTitleModal } from '../editBookingTitle';

const rights = [
    {
        rights: ['manageEvents'],
        rule: 'one',
        name: 'hasManageEventsRights',
    },
    {
        rights: ['pmManageEvents'],
        rule: 'one',
        name: 'hasPMManageEventsRights',
    },
    {
        rights: ['manageUnassignedRows'],
        rule: 'one',
        name: 'hasUnassignedScheduleRights',
    },
    {
        rights: ['settingBookCat'],
        rule: 'one',
        name: 'hasSettingBookCatRight',
    },
];

export const useStyles = makeStyles({
    categoryAllocation: {
        marginLeft: '18px',
        color: '#7d7d7d',
    },
});

const LegacyBooking = props => {
    const { schedulerRef, onClose, left } = props;
    const history = useHistory();

    const [leftForceRender, setLeftForceRender] = useState(null);

    useEffect(() => {
        // fix https://hubplanner.atlassian.net/browse/HUB-7727
        if (left !== leftForceRender) {
            setLeftForceRender(left);
        }
    }, [leftForceRender, left]);

    const dispatch = useDispatch();
    const keyWords = useAppKeyWords();
    const {
        preferences: {
            grid: { mode },
        },
        resourceId,
        isProjectManager,
    } = useSelector(state => state.account);

    const useCategoriesAllocation = useSelector(state => state.companyReducer.company.settings.useCategoriesAllocation);
    const viewObject = useGetViewObject();

    const showAssignToAll = !((viewObject.isProjectView && mode === SINGLE.value) || viewObject.isSingleResourceView);
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const dpResources = useSelector(state => state.scheduler.dpResources);
    const categories = useSelector(selectFlatCategories);
    const { resourceInfo, project, categoryTemplate: currentCategoryTemplate } = currentSelectedBooking;
    const {
        hasSettingBookCatRight,
        hasManageEventsRights,
        hasPMManageEventsRights,
        hasUnassignedScheduleRights,
    } = useHasRights(rights);
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const isTasksExtensionActive = useIsExtensionInstalled(TASKS);
    const isDependenciesExtensionInstalled = useIsExtensionInstalled(DEPENDENCIES);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const isPMonProject = includes(project.projectManagers, resourceId);
    const hasPMRights = isPMExtensionInstalled && isProjectManager && isPMonProject;
    const canBulkMoveEvent = hasManageEventsRights || (hasPMRights && hasPMManageEventsRights);
    const {
        updateClipboard,
        updateBooking,
        deleteBooking,
        duplicateBooking,
        bulkCreateBookings,
        splitBooking,
    } = useBooking(currentSelectedBooking, onClose);

    const { hasAddEditProjectRights, hasCreateResourceRights, canEditCreateNotes } = useMenuPermissions();

    const { currentSelectedBookingStart, currentSelectedBookingEnd } = useMemo(() => {
        return {
            currentSelectedBookingStart: isDaypilotDate(currentSelectedBooking.start)
                ? new Date(currentSelectedBooking.start.value)
                : currentSelectedBooking.start,
            currentSelectedBookingEnd: isDaypilotDate(currentSelectedBooking.end)
                ? new Date(currentSelectedBooking.end.value)
                : currentSelectedBooking.end,
        };
    }, [currentSelectedBooking.end, currentSelectedBooking.start]);

    const classes = useStyles();

    const getFilteredProjects = useMemo(() => makeGetFilteredProjects('EXCLUDE_ARCHIVED'), []);

    const allPermittedResourcesSelector = useMemo(
        () =>
            makeGetPermittedResources([], {
                removeResources: !hasManageEventsRights,
                groupResources: true,
                removeUnassigned: !hasUnassignedScheduleRights,
                removeArchived: true,
                removeParked: true,
                removeNotBookable: true,
            }),
        [hasManageEventsRights, hasUnassignedScheduleRights]
    );
    const projectCategoryGroupsSelector = useMemo(() => makeCategoryGroups(project?.categoryGroups), [
        project?.categoryGroups,
    ]);
    const projectCategoryGroups = useSelector(projectCategoryGroupsSelector);
    const projects = useSelector(getFilteredProjects);
    const allPermittedResources = useSelector(allPermittedResourcesSelector);

    const resourceModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showResourceModal({
                onSubmit: data => dispatch(updateResource.request(resourceInfo._id, data)),
                type: resourceInfo.type,
                resourceId: resourceInfo._id,
            })
        );
    }, [dispatch, onClose, resourceInfo.type, resourceInfo._id]);

    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => dispatch(updateProject.request(project._id, data)),
                type: project.type,
                projectId: project._id,
            })
        );
    }, [dispatch, onClose, project.type, project._id]);
    const bookingModal = useCallback(
        activeTab => {
            onClose && onClose();
            dispatch(
                showScheduleResourceModal({ onSubmit: updateBooking, activeTab, bookingId: currentSelectedBooking.id })
            );
        },
        [dispatch, onClose, updateBooking, currentSelectedBooking]
    );

    const goToCategorySettings = useCallback(
        event => {
            event.preventDefault();
            dispatch(hideContextMenu());
            history.push('/settings/categoryGroups');
            dispatch(changeActiveLinkFromLocation());
        },
        [dispatch, history]
    );

    const bulkMoveModal = useCallback(() => {
        onClose && onClose();
        dispatch(showBulkMoveModal(schedulerRef, viewObject));
    }, [dispatch, onClose, schedulerRef, viewObject]);

    const toggleSidebar = useCallback(
        tabId => {
            dispatch(openSidebar.request(tabId));
            onClose();
        },
        [dispatch, onClose]
    );

    const daypilotEventsList = schedulerRef.current.control.events.list;
    const onBookingEdit = useCallback(
        booking => {
            const options = {
                createRepeatFromExisting: booking.repeat && booking.repeat !== currentSelectedBooking,
            };

            const selectedEvent = daypilotEventsList.find(({ id }) => id === currentSelectedBooking.id);
            updateBooking({ ...selectedEvent, ...booking }, selectedEvent, true, options);
        },
        [currentSelectedBooking, updateBooking, daypilotEventsList]
    );

    const editBookingModal = useEditBookingTitleModal({
        onClose,
        currentSelectedBooking,
        updateBooking,
        mode,
        viewObject,
    });

    const disabledDaysSplitCalendar = useMemo(
        () => ({
            before: startOfDay(addDays(currentSelectedBookingStart, 1)),
            after: endOfDay(currentSelectedBookingEnd),
        }),
        [currentSelectedBookingEnd, currentSelectedBookingStart]
    );

    const onSplit = useCallback(
        date => {
            if (disabledDaysSplitCalendar.before <= date && date <= disabledDaysSplitCalendar.after) {
                splitBooking({
                    splitDate: date,
                    bookingId: currentSelectedBooking._id || currentSelectedBooking.id,
                });
            }
        },
        [
            currentSelectedBooking._id,
            currentSelectedBooking.id,
            disabledDaysSplitCalendar.after,
            disabledDaysSplitCalendar.before,
            splitBooking,
        ]
    );

    const assignToAllInGroup = useCallback(() => {
        let resourceIds = map(dpResources, resource => resource._id);
        if (viewObject.isProjectView && mode === PARENT.value) {
            resourceIds =
                find(dpResources, resource => resource._id === currentSelectedBooking.project._id)?.resources || [];
        }
        bulkCreateBookings(currentSelectedBooking.id, {
            resourceIds,
        });
    }, [bulkCreateBookings, currentSelectedBooking, dpResources, mode, viewObject.isProjectView]);

    const handleSwitchProject = useCallback(
        ({ project }) => {
            const categoryTemplate = categories.find(category => category._id === project.defaultCategoryTemplate);

            if (categoryTemplate) {
                updateBooking({ project, categoryTemplate });
            } else {
                updateBooking({ project });
            }
        },
        [categories, updateBooking]
    );

    useEffect(() => {
        dispatch(getResources.request());
        dispatch(getProjects.request());
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    let modifierKeyPrefix = 'CTRL +'; // control key
    if (window.navigator.platform.indexOf('Mac') === 0) {
        modifierKeyPrefix = '⌘'; // command key
    }

    const handleDeadlineUpdateDelete = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(updateBookingSelection.request());
    }, [dispatch, onClose]);

    return (
        <NestedMenuItemManager>
            <WrappedMenuItem
                onClick={duplicateBooking}
                data-cy="scheduler-booking-legacy-menu-duplicate"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mt-2"
            >
                Duplicate
                <KeyBoardShortCutIcon>{modifierKeyPrefix} D</KeyBoardShortCutIcon>
            </WrappedMenuItem>
            <WrappedMenuItem
                onClick={() => updateClipboard()}
                data-cy="scheduler-booking-legacy-menu-copy"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
            >
                Copy
                <KeyBoardShortCutIcon>{modifierKeyPrefix} C</KeyBoardShortCutIcon>
            </WrappedMenuItem>
            <WrappedMenuItem
                onClick={() => updateClipboard(true)}
                data-cy="scheduler-booking-legacy-menu-cut"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
            >
                Cut
                <KeyBoardShortCutIcon>{modifierKeyPrefix} X</KeyBoardShortCutIcon>
            </WrappedMenuItem>
            <WrappedMenuItem
                onClick={deleteBooking}
                data-cy="scheduler-booking-legacy-menu-delete"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
            >
                Delete
                <KeyBoardShortCutIcon>{modifierKeyPrefix} Del</KeyBoardShortCutIcon>
            </WrappedMenuItem>
            <Divider className="m-2" />
            <WrappedMenuItem
                onClick={() => bookingModal(DEPENDENCIES_TAB_ID)}
                data-cy="scheduler-booking-legacy-linked-bookings"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                showDisabledTextState={!isDependenciesExtensionInstalled}
            >
                Dependencies ...
                {!isDependenciesExtensionInstalled && (
                    <Adjust style={{ color: '#e38532', fontSize: 16, position: 'absolute', right: '14px' }} />
                )}
            </WrappedMenuItem>
            <Divider className="m-2" />
            <NestedMenuItem
                label="Category"
                parentMenuOpen={true}
                width={280}
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                dataCy="scheduler-booking-legacy-menu-category"
            >
                <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                    <div className="px-2">
                        {projectCategoryGroups.map(categoryGroup => (
                            <div key={categoryGroup.name}>
                                <strong className="ml-2 mr-2 mb-2 pl-2 pt-2 pb-2">{categoryGroup.name}</strong>
                                {categoryGroup.categories.map(categoryTemplate => (
                                    <MenuItem
                                        onClick={() => {
                                            const newAllocationValues = calculateAllocationForCategory({
                                                useCategoriesAllocation,
                                                category: categoryTemplate,
                                                percentage: currentSelectedBooking.percentAllocation,
                                                hours: currentSelectedBooking.minutesPerDay / 60,
                                                total: currentSelectedBooking.totalBucketMinutesAllocation / 60,
                                            });

                                            updateBooking({
                                                categoryTemplate,
                                                repeatId: currentSelectedBooking?.repeatId,
                                                repeat: currentSelectedBooking?.repeat,
                                                interval: currentSelectedBooking?.interval,
                                                repeatTimes: currentSelectedBooking?.repeatTimes,
                                                ...(useCategoriesAllocation
                                                    ? {
                                                          percentAllocation: newAllocationValues.percentage,
                                                          minutesPerDay: newAllocationValues.hours * 60,
                                                          totalBucketMinutesAllocation: newAllocationValues.total * 60,
                                                          state: newAllocationValues.state,
                                                      }
                                                    : {}),
                                            });
                                        }}
                                        disabled={categoryTemplate._id === currentCategoryTemplate._id}
                                        key={categoryTemplate._id}
                                        className="rounded ml-2 mr-2 pl-2 pt-2 pb-2"
                                        data-cy={`scheduler-booking-legacy-menu-${createCySelector(
                                            categoryTemplate.name
                                        )}`}
                                    >
                                        <div>
                                            <div>
                                                <CategoryColor color={categoryTemplate.gridColor} />
                                                {categoryTemplate.name}
                                            </div>
                                            {useCategoriesAllocation && (
                                                <div className={classes.categoryAllocation}>
                                                    {getAllocationDisplayValue(categoryTemplate)}
                                                </div>
                                            )}
                                        </div>
                                    </MenuItem>
                                ))}
                            </div>
                        ))}
                        {hasSettingBookCatRight && (
                            <>
                                <DefaultDropdownItem divider />
                                <SuccessButton
                                    className="m-auto d-block"
                                    onClick={goToCategorySettings}
                                    data-cy="button--add-new-booking-categories"
                                    type="btn-secondary"
                                    name="Add new category"
                                />
                            </>
                        )}
                    </div>
                </Scrollbar>
            </NestedMenuItem>
            <Divider className="m-2" />
            {currentSelectedBooking.id &&
                CELLDURATION.value !== schedulerRef.current.control.scale &&
                currentSelectedBooking.scale?.value !== CELLDURATION.value && (
                    <NestedMenuItem
                        label="Dates"
                        parentMenuOpen
                        width={280}
                        dataCy="scheduler-booking-legacy-menu-dates"
                    >
                        <DatesForm
                            onUpdate={onBookingEdit}
                            currentSelectedBooking={currentSelectedBooking}
                            dataCy="classic-booking-menu-dates"
                        />
                    </NestedMenuItem>
                )}
            {currentSelectedBooking.id &&
                CELLDURATION.value !== schedulerRef.current.control.scale &&
                currentSelectedBooking.allDay !== false && (
                    <NestedMenuItem
                        label="Allocation"
                        parentMenuOpen={true}
                        width={280}
                        dataCy="scheduler-booking-legacy-menu-allocation"
                    >
                        <AllocationForm onUpdate={onBookingEdit} currentSelectedBooking={currentSelectedBooking} />
                    </NestedMenuItem>
                )}
            {currentSelectedBooking.id &&
                !currentSelectedBooking.repeat &&
                CELLDURATION.value !== schedulerRef.current.control.scale && (
                    <NestedMenuItem
                        label="Split"
                        disabled={isSameDay(currentSelectedBookingStart, currentSelectedBookingEnd)}
                        parentMenuOpen={true}
                        width={280}
                        dataCy="scheduler-booking-legacy-menu-split"
                    >
                        <DayPicker
                            firstDayOfWeek={1}
                            disabledDays={disabledDaysSplitCalendar}
                            month={addDays(currentSelectedBookingStart, 1)}
                            onDayClick={onSplit}
                        />
                    </NestedMenuItem>
                )}
            <Divider className="m-2" />
            {canBulkMoveEvent && (
                <WrappedMenuItem
                    onClick={bulkMoveModal}
                    data-cy="scheduler-booking-legacy-menu-bulk-move"
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                >
                    Bulk move...
                </WrappedMenuItem>
            )}
            {TYPE_EVENT.value !== project.type && !includes([SINGLE.value, PARENT.value], mode) && (
                <NestedMenuItem
                    label={`Switch ${keyWords.projectKeyWord.toLowerCase()}`}
                    parentMenuOpen={true}
                    width={280}
                    dataCy="scheduler-booking-legacy-menu-switch-project"
                >
                    <div className="px-2">
                        <SearchList
                            emptyText={`Sorry there are no ${keyWords.projectPluralKeyWord} to choose from`}
                            data={projects}
                            name="project"
                            onSelect={handleSwitchProject}
                            fields={{ label: 'name', value: '_id' }}
                            placeholder="Type to Filter"
                        />
                    </div>
                </NestedMenuItem>
            )}
            <NestedMenuItem
                label="Reassign booking"
                parentMenuOpen={true}
                width={280}
                dataCy="scheduler-booking-legacy-menu-reassign-booking"
            >
                <div className="px-2">
                    <SearchList
                        emptyText={`Sorry there are no ${keyWords.resourcePluralKeyWord} to choose from`}
                        data={allPermittedResources}
                        onSelect={({ resource }) =>
                            updateBooking({ resourceInfo: { ...resourceInfo, ...resource, _id: resource._id } })
                        }
                        name="resource"
                        isGroupedData={true}
                        fields={{ label: 'firstName', value: '_id', additional: 'lastName' }}
                        placeholder="Type to Filter"
                    />
                </div>
            </NestedMenuItem>
            {showAssignToAll && (
                <WrappedMenuItem
                    onClick={assignToAllInGroup}
                    data-cy="scheduler-booking-legacy-menu-assign-to-all-in-group"
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                >
                    Assign to all in group
                </WrappedMenuItem>
            )}
            {isDeadlinesExtensionInstalled ? (
                <NestedMenuItem
                    dataCy="scheduler-booking-legacy-menu-add-edit-deadline"
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    parentMenuOpen={true}
                    width={350}
                    label={currentSelectedBooking.deadlineDate ? 'Edit deadline' : 'Add deadline'}
                >
                    <div className="px-2">
                        <DeadlineForm
                            booking={currentSelectedBooking}
                            onSave={handleDeadlineUpdateDelete}
                            onDelete={handleDeadlineUpdateDelete}
                            withDelete
                        />
                    </div>
                </NestedMenuItem>
            ) : (
                <WrappedMenuItem
                    onClick={() => dispatch(showMissingDeadlinesExtensionModal())}
                    data-cy="scheduler-booking-legacy-add-deadline"
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    showDisabledTextState
                >
                    Add deadline
                    <Adjust style={{ color: '#e38532', fontSize: 16, position: 'absolute', right: '14px' }} />
                </WrappedMenuItem>
            )}
            <Divider className="m-2" />
            {canEditCreateNotes && (
                <NestedMenuItem label="Notes" parentMenuOpen={true} dataCy="scheduler-booking-legacy-menu-notes">
                    <>
                        <MenuItem
                            onClick={() => bookingModal(NOTE_TAB_ID)}
                            data-cy="scheduler-booking-legacy-menu-edit-note"
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                        >
                            Edit note...
                        </MenuItem>
                        <MenuItem
                            onClick={() => toggleSidebar(TABS.NOTE_TAB.id)}
                            data-cy="scheduler-booking-legacy-menu-note-sidebar"
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                        >
                            Note sidebar...
                        </MenuItem>
                    </>
                </NestedMenuItem>
            )}
            {isTasksExtensionActive && (
                <WrappedMenuItem
                    onClick={() => toggleSidebar(TABS.TASKS_TAB.id)}
                    data-cy="scheduler-booking-legacy-menu-tasks"
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                >
                    Tasks...
                </WrappedMenuItem>
            )}
            <NestedMenuItem
                label="Edit"
                parentMenuOpen={true}
                dataCy="scheduler-booking-legacy-menu-edit"
                className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mb-2"
            >
                <>
                    <MenuItem
                        onClick={() => bookingModal()}
                        data-cy="scheduler-booking-legacy-menu-edit-booking"
                        className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    >
                        Edit booking...
                    </MenuItem>
                    <MenuItem
                        onClick={editBookingModal}
                        data-cy="scheduler-booking-legacy-menu-edit-title"
                        className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    >
                        Edit title...
                    </MenuItem>
                    {hasAddEditProjectRights && currentSelectedBooking.isProjectPermitted && (
                        <MenuItem
                            onClick={projectModal}
                            data-cy="scheduler-booking-legacy-menu-edit-project"
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                        >
                            Edit {keyWords.projectKeyWord.toLowerCase()} ...
                        </MenuItem>
                    )}
                    {hasCreateResourceRights && currentSelectedBooking.isResourcePermitted && (
                        <MenuItem
                            onClick={resourceModal}
                            data-cy="scheduler-booking-legacy-menu-edit-resource"
                            className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                        >
                            Edit {keyWords.resourceKeyWord.toLowerCase()} ...
                        </MenuItem>
                    )}
                </>
            </NestedMenuItem>
        </NestedMenuItemManager>
    );
};

LegacyBooking.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func,
};

LegacyBooking.defaultProps = {
    onClose: null,
};

export default LegacyBooking;
