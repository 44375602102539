import React, { useCallback, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { each, extend } from 'underscore';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { deleteAllRepeatBooking, deleteBooking, deleteFutureRepeatBooking } from 'actions/bookingActions';
import { hasOneOfRoles } from 'utils/rightsUtil';
import { getCurrentRequestModel } from 'modules/request/utils/requestUtil';
import { isVacationFlow, isRequestResourceFlow } from 'modules/request/utils/dataFlowUtil';
import { updateRequestModel, approveRejectRequest, updateResourceOrVacationRequest } from 'actions/requestActions';
import { showApproveOrRejectModal, showConfirmationModal, showEditRequestResourceModal } from 'actions/modalActions';
import { getApprovees } from 'actions/resourceActions';
import { RESOURCE_REQUEST, VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { useIsExtensionInstalled } from 'hooks';
import { Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';

const refreshStats = true;

const editRepeatedRequestsMessage = () => (
    <>
        <Typography paragraph>
            You are trying to edit a repeating request. Choose if you would like to just update selected requests or all
            requests.
        </Typography>
        <Alert color="warning">
            <strong>Warning!</strong> If you update just selected requests, they will be updated and removed from the
            repeat cycle. If you update all, all requests will be updated.
        </Alert>
    </>
);

const editRepeatedRequestMessage = () => (
    <>
        <Typography paragraph>
            This is a repeating request. Choose if you would like to just update this request or all requests.
        </Typography>
        <Alert color="warning">
            <strong>Warning!</strong> If you update just this request, it will be updated and removed from the repeat
            cycle. If you update all, all requests will be updated.
        </Alert>
    </>
);

const deleteRepeatedRequestMessage = () => (
    <>
        <Typography paragraph>
            This is a repeating request. Choose if you would like to just delete this request or all requests from
            series.
        </Typography>
        <Alert color="warning">
            <strong>Warning!</strong> If you delete just this request, it will be deleted and removed from the repeat
            cycle. If you update all, all requests from series will be updated.
        </Alert>
    </>
);

export const useRequestData = (flow, currentLocation) => {
    const dispatch = useDispatch();
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const isResourceRequestExtensionInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const requestModel = useSelector(state => {
        return state.requestReducer.get('requestModel');
    });
    const tableActive = useSelector(state => state.requestReducer.get('tableActive'));
    const { resourceId, resourceRoleRights, isApprover } = useSelector(state => state.account);
    const companyTreeId = useSelector(state => state.companyTree._id);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const statistics = useSelector(state => state.requestReducer.get('statistics'));
    const filteredBookings = useSelector(state => state.requestReducer.get('filteredBookings'));
    const textFilter = useSelector(state => state.filterReducer.get('textFilter'));

    const updateRequestData = useCallback(
        (itemsToUpdate, refreshStatistics) => dispatch(updateRequestModel(itemsToUpdate, refreshStatistics)),
        [dispatch]
    );
    const updateRequest = useCallback(
        requestStartDate => {
            const newRequestModel = getCurrentRequestModel(
                currentLocation.search,
                flow,
                { resourceId, resourceRoleRights },
                companySettings.vacationYearStartDate,
                requestStartDate
            );

            updateRequestData(newRequestModel);
        },
        [currentLocation, flow, resourceId, resourceRoleRights, companySettings, updateRequestData]
    );

    const getRequests = useCallback(() => {
        const today = new Date();
        const hasVacationRequestRights = isVacationFlow(flow) && isVacationExtensionInstalled;
        const hasResourceRequestRights = isRequestResourceFlow(flow) && isResourceRequestExtensionInstalled;
        const date = new Date(
            Date.parse(
                `${isVacationFlow(flow) ? companySettings.vacationYearStartDate : 'January'} 1, ${today.getFullYear()}`
            )
        );
        if (companyTreeId && (hasVacationRequestRights || hasResourceRequestRights)) {
            updateRequest(date);
        }
    }, [
        companySettings.vacationYearStartDate,
        companyTreeId,
        flow,
        isResourceRequestExtensionInstalled,
        isVacationExtensionInstalled,
        updateRequest,
    ]);

    const deleteBookingRequest = useCallback(
        booking => {
            if (booking.repeat) {
                dispatch(
                    showConfirmationModal(
                        () => {
                            dispatch(deleteBooking.request(booking._id, refreshStats, getRequests));
                        },
                        'Delete warning',
                        deleteRepeatedRequestMessage,
                        {
                            withEscButton: true,
                            withCancel: true,
                            confirmButtonText: 'Delete this',
                            additionalButtons: [
                                {
                                    text: 'Delete all',
                                    onClick: () => {
                                        dispatch(
                                            deleteAllRepeatBooking.request(booking.repeatId, refreshStats, getRequests)
                                        );
                                    },
                                },
                                {
                                    text: 'Delete all future',
                                    onClick: () => {
                                        dispatch(
                                            deleteFutureRepeatBooking.request(
                                                booking.repeatId,
                                                booking.start,
                                                refreshStats,
                                                getRequests
                                            )
                                        );
                                    },
                                },
                            ],
                        }
                    )
                );
            } else {
                dispatch(deleteBooking.request(booking._id, refreshStats, getRequests));
            }
        },
        [dispatch, getRequests]
    );

    const deleteRequest = booking => {
        if (!booking.deleted) {
            deleteBookingRequest(booking);
        }
    };

    const changeChartType = chartType => {
        if (chartType !== requestModel.get('chartType')) {
            updateRequestData({ chartType }, false);
        }
    };

    const handleRepeatedRequestEdit = useCallback(
        (bookings, updateFn) => {
            const noOfUpdatedBookings = (bookings ?? []).length;
            const msg = noOfUpdatedBookings === 1 ? editRepeatedRequestMessage : editRepeatedRequestsMessage;
            const confirmButtonText = noOfUpdatedBookings === 1 ? 'Update this' : 'Update selected';
            const isSomeRepeated = (bookings ?? []).some(booking => booking.repeatId);

            if (isSomeRepeated) {
                dispatch(
                    showConfirmationModal(
                        () => {
                            updateFn(
                                bookings.map(booking => ({
                                    ...booking,
                                    repeat: false,
                                    repeatId: null,
                                    interval: 'NONE',
                                    repeatTimes: 0,
                                })),
                                { createRepeatFromExisting: false }
                            );
                        },
                        'Update warning',
                        msg,
                        {
                            withEscButton: true,
                            withCancel: true,
                            confirmButtonText,
                            additionalButtons: [
                                {
                                    text: 'Update all',
                                    onClick: () => {
                                        updateFn(bookings, {
                                            modifyAllRepeated: true,
                                            removeRemovedRepeated: true,
                                            createRepeatFromExisting: true,
                                        });
                                    },
                                },
                            ],
                        }
                    )
                );
            } else {
                updateFn(bookings, {});
            }
        },
        [dispatch]
    );

    const approveOrRejectRequest = useCallback(
        (bookings, type) => {
            let bookingsToUpdate = [];
            each(bookings, booking => {
                const approvalInfo = extend({}, booking.approvalInfo, {
                    approvedOrRejectedDate: moment.utc(),
                    approvedOrRejectedById: resourceId,
                });
                // https://hubplanner.atlassian.net/browse/HUB-9379
                bookingsToUpdate.push({
                    type,
                    approvalInfo,
                    _id: booking._id,
                    categoryTemplateId: booking.categoryTemplateId,
                    repeat: booking.repeat,
                    repeatId: booking.repeatId,
                    interval: booking.interval,
                    repeatTimes: booking.repeatTimes,
                });
            });

            handleRepeatedRequestEdit(bookingsToUpdate, (bookingToRequest, options) => {
                dispatch(approveRejectRequest({ bookings: bookingToRequest, options }));
            });
        },
        [dispatch, handleRepeatedRequestEdit, resourceId]
    );

    const reSubmitRequest = useCallback(
        booking => {
            const approvalInfo = extend({}, booking.approvalInfo, {
                approvedOrRejectedDate: null,
                approverNote: null,
            });

            handleRepeatedRequestEdit(
                [
                    {
                        // https://hubplanner.atlassian.net/browse/HUB-9379
                        type: WAITING_FOR_APPROVAL.value,
                        approvalInfo,
                        _id: booking._id,
                        categoryTemplateId: booking.categoryTemplateId,
                        repeatId: booking.repeatId,
                    },
                ],
                (bookingsToRequest, options) => {
                    dispatch(approveRejectRequest({ bookings: bookingsToRequest, options }));
                }
            );
        },
        [dispatch, handleRepeatedRequestEdit]
    );

    const handleApproveOrRejectModal = useCallback(
        (booking, type) => {
            dispatch(
                showApproveOrRejectModal(note => {
                    const request = (bookingToRequest, options = {}) => {
                        const approvalInfo = extend({}, bookingToRequest.approvalInfo, {
                            approvedOrRejectedDate: moment.utc(),
                            approverNote: note,
                        });

                        // https://hubplanner.atlassian.net/browse/HUB-9379
                        return dispatch(
                            approveRejectRequest({
                                bookings: [
                                    {
                                        type,
                                        approvalInfo,
                                        _id: bookingToRequest._id,
                                        categoryTemplateId: bookingToRequest.categoryTemplateId,
                                        repeat: bookingToRequest.repeat,
                                        repeatId: bookingToRequest.repeatId,
                                        interval: bookingToRequest.interval,
                                        repeatTimes: bookingToRequest.repeatTimes,
                                    },
                                ],
                                options,
                            })
                        );
                    };

                    handleRepeatedRequestEdit([booking], (bookingsToRequest, options) => {
                        request(bookingsToRequest[0], options);
                    });
                }, type)
            );
        },
        [dispatch, handleRepeatedRequestEdit]
    );

    const editRequest = useCallback(
        value => {
            dispatch(showEditRequestResourceModal(data => dispatch(updateResourceOrVacationRequest(data)), value));
        },
        [dispatch]
    );

    useEffect(() => {
        if (!hasOneOfRoles(resourceRoleRights, ['manageAllVacations', 'manageAllResourceRequests']) && isApprover) {
            dispatch(getApprovees.request(resourceId));
        }
    }, [dispatch, resourceRoleRights, resourceId, isApprover]);

    useEffect(() => {
        getRequests();
    }, [getRequests]);

    return {
        requestModel: requestModel.toJSON(),
        tableActive,
        resourceId,
        companySettings,
        statistics,
        filteredBookings,
        textFilter,
        changeChartType,
        deleteRequest,
        approveOrRejectRequest,
        editRequest,
        handleApproveOrRejectModal,
        reSubmitRequest,
        deleteBooking: deleteBookingRequest,
    };
};
