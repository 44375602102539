import { call, put, takeLatest, select, fork, join } from 'redux-saga/effects';
import { cloneDeep, map } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import {
    getBookings as getBookingsAction,
    getBooking as getBookingAction,
    createBookings as createBookingsAction,
    updateBooking as updateBookingAction,
    updateMultipleBookings as updateMultipleBookingsAction,
    deleteBooking as deleteBookingAction,
    deleteBookings as deleteBookingsAction,
    splitBooking as splitBookingAction,
    rejectBooking as rejectBookingAction,
    updateDeadline,
    createDeadline,
    deleteDeadline,
    updateDeadlines,
} from 'actions/bookingActions';
import { updateRequestModel } from 'actions/requestActions';
import { hideModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import { handleCreateProject } from './projectSaga';
import {
    getBookings,
    getBooking,
    createBookings,
    deleteBooking,
    deleteBookings,
    updateBooking,
    bulkUpdateBookings,
    splitBooking,
    duplicateBooking,
    bulkCreateBookings,
    shiftProjectResourceBookings,
    shiftResourceBookings,
    shiftProjectBookings,
    moveProjectBookingsBetweenResources,
    moveBookingsBetweenResources,
    deleteAllRepeatBookings,
    deleteFutureRepeatBookings,
} from 'api/booking';
import {
    updateDaypilotEvents,
    getViewObject,
    eventsIdsMoving,
    dispatchRefreshDPResourcesEvent,
    dispatchRefreshBookings,
    removeBookingById,
    clearSelection,
    removeDaypilotEvent,
    removeDaypilotEvents,
    addDaypilotEvent,
    getRepeatedBookingIdsToRemove,
    deleteDeadlineFromScheduler,
    updateDeadlinesInScheduler,
    updateDeadlinesVisibility,
    updateVisibleLinksOnScheduler,
    deleteDeadlinesFromSchedulerForSeries,
    addDeadlinesToScheduler,
    deleteRepeatedBookings,
} from 'modules/scheduler/utils/schedulerUtil';
import { formatBookingsToDP } from 'modules/scheduler/utils/eventUtil';
import { params, childRoutePaths, routePaths } from 'utils/routeHelper';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { getDefaultNotificationErrorHandler, handleErrors } from './helpers/errorHandler';
import { overscheduleHandler } from './helpers/overschedule';
import { setSchedulerBookingLinks, updateBookingSelection } from 'actions/schedulerActions';
import { getViewObjectSelector } from 'selectors/router';
import { startSubmit, stopSubmit } from 'redux-form';
import { BULK_MOVE_FORM_NAME } from '../modules/scheduler/modals/bulkMoveModal/modal';
import { mapFormToRequest } from '../modules/scheduler/modals/bulkMoveModal/formSchema';
import { getCompanySettings, selectCcProjectManagersOnRequestCompanyNotificationSetting } from '../selectors/company';
import { setSchedulerSelection } from '../actions/schedulerActions';
import {
    linkedBookingsDatesErrorHandler,
    linkedBookingsMoveOutsideWorkingHours,
    linkedBookingsMoveRepeated,
    linkedBookingsNonMovableBooking,
    linkedBookingsRepeatedErrorHandler,
} from './helpers/bookingLinks';
import { WAITING_FOR_APPROVAL, REJECTED } from '../enums/bookingTypeEnum';
import { hideContextMenu } from '../actions/contextMenuActions';
import * as contextMenuTypes from '../modules/scheduler/enums/contextMenuEnum';
import { deadlineBeforeBookingEndErrorHandler } from './helpers/deadlines';
import { deadlinesMoveErrorHandler } from './helpers/deadlinesMove';
import { selectIsAnyBookingFilterApplied, selectSchedulerBookingStatusesFilter } from '../selectors/scheduler';
import { monitoring } from '../monitoring';
import { formatDate } from '../utils/DateUtil';

const getTheParentRowOfBooking = booking => {
    const dpRows = window.schedulerRef?.current?.control?.rows?.all() || [];
    return dpRows.find(dpRow => {
        return (
            dpRow?.data?.tags?.project?._id === booking?.project?._id ||
            dpRow?.data?.tags?.resource?._id === booking?.resource?._id
        );
    });
};

const checkChildrenRowsGroupedMode = newBookings => {
    if (!Array.isArray(newBookings) || newBookings.length === 0) {
        return;
    }

    // If in Grouped mode
    if (window?.schedulerRef?.current?.control?.mode === PARENT.value) {
        // If children for the booking created does not exist on scheduler, refresh
        const dpChildrenRowsIds = new Set();

        (window.schedulerRef?.current?.control?.rows?.all() || [])
            .map(dpRow => dpRow.id)
            // Check only valid children id_id
            .filter(dpRowId => {
                const splitted = dpRowId.split('_');
                return splitted.length === 2 && splitted[0].length === splitted[1].length;
            })
            .forEach(id => dpChildrenRowsIds.add(id));

        const refreshResources =
            dpChildrenRowsIds.size === 0
                ? true
                : newBookings.some(newBooking => {
                      return !(
                          dpChildrenRowsIds.has(`${newBooking?.project?._id}_${newBooking?.resource?._id}`) ||
                          dpChildrenRowsIds.has(`${newBooking?.resource?._id}_${newBooking?.project?._id}`)
                      );
                  });

        // No other valid children present or new children not already inside  the parent
        if (refreshResources) {
            dispatchRefreshDPResourcesEvent({
                force: false,
                cb: () => {
                    const dpParentRowOfBooking = getTheParentRowOfBooking(newBookings[0]);
                    const dpParentRowOfBookingExpanded = dpParentRowOfBooking?.data?.expanded;

                    if (dpParentRowOfBookingExpanded) {
                        dpParentRowOfBooking.collapse();
                        setTimeout(() => {
                            const dpParentRowOfBooking = getTheParentRowOfBooking(newBookings[0]);
                            dpParentRowOfBooking.expand();
                        }, 0);
                    }
                },
            });
        }
    }
};

const getRequestReducer = state => state.requestReducer;

function* handleGetBookings(action) {
    try {
        let bookings = yield call(getBookings, action.payload.filters);
        bookings = bookings.map(booking => {
            if (booking?.project?.useStatusColor) {
                return {
                    ...booking,
                    project: replaceProjectsColorWithStatusColor(booking.project),
                };
            }
            return booking;
        });

        action.payload.resolve(bookings);
        yield put(getBookingsAction.success(bookings));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Something went wrong... We can't load your bookings",
                type: 'danger',
            })
        );
    }
}

function* handleGetBooking(action) {
    try {
        let booking = yield call(getBooking, action.payload.bookingId);
        if (booking?.project?.useStatusColor) {
            booking = {
                ...booking,
                project: replaceProjectsColorWithStatusColor(booking?.project),
            };
        }

        yield put(getBookingAction.success(booking));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Something went wrong... We can't load the booking",
                type: 'danger',
            })
        );
    }
}

function* handleCreateBookings(action) {
    const notifyPM = yield select(selectCcProjectManagersOnRequestCompanyNotificationSetting);
    try {
        let bookings = (action.payload.bookings ?? []).map(booking => {
            if (!booking.hasOwnProperty('ccProjectManagersOnRequest')) {
                return {
                    ...booking,
                    ccProjectManagersOnRequest: notifyPM?.enabled ?? false,
                };
            }
            return booking;
        });

        if (action.payload.projectName) {
            const projectResponse = yield fork(handleCreateProject, {
                payload: { data: { project: { name: action.payload.projectName } } },
            });
            const project = yield join(projectResponse);
            bookings = map(bookings, booking => ({ ...booking, project: project._id }));
        }
        const newBookings = yield call(createBookings, {
            events: bookings,
            tasks: action.payload.tasks,
            options: action.payload.options,
        });

        if (action.type === actionTypes.CUT_BOOKING['REQUEST'] && action.payload.bookings?.[0]) {
            try {
                yield deleteBooking(action.payload.bookings[0].copyFromEventId);
            } catch (error) {
                if (error?.status !== 404) {
                    throw error;
                }
            }
        }

        if (action.payload.callback) {
            action.payload.callback(newBookings);
        }

        yield put(createBookingsAction.success(newBookings));

        checkChildrenRowsGroupedMode(newBookings);

        dispatchRefreshBookings(newBookings?.[0]);

        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler(
                error?.data?.code,
                'Something went wrong... Please check if you filled all required fields'
            )
        );
    }
}

function* handleDuplicateBookings(action) {
    try {
        const newBooking = yield call(duplicateBooking, action.payload.bookingId, action.payload.options);

        if (action.payload.cb) {
            action.payload.cb(newBooking);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            getDefaultNotificationErrorHandler(
                error?.data?.code,
                'Something went wrong... Please check if you filled all required fields'
            )
        );
    }
}

function* createVacationOrResourceRequest(action) {
    const notifyPM = yield select(selectCcProjectManagersOnRequestCompanyNotificationSetting);
    try {
        const bookingsToSend = (action.payload.bookings ?? []).map(booking => {
            if (!booking.hasOwnProperty('ccProjectManagersOnRequest')) {
                return {
                    ...booking,
                    ccProjectManagersOnRequest: notifyPM?.enabled ?? false,
                };
            }
            return booking;
        });

        const newBookings = yield call(createBookings, {
            events: bookingsToSend,
            tasks: [],
            options: action.payload.options,
        });
        const requestReducer = yield select(getRequestReducer);

        yield put(updateRequestModel(requestReducer.toJSON().requestModel));

        if (action.payload.callback) {
            action.payload.callback(newBookings);
        }

        checkChildrenRowsGroupedMode(newBookings);

        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            getDefaultNotificationErrorHandler(
                error?.data?.code,
                'Something went wrong... Please check if you filled all required fields'
            )
        );
    }
}

function* handleDeleteBooking(action) {
    try {
        yield call(deleteBooking, action.payload.bookingId);

        if (action.payload.refreshStats) {
            const requestReducer = yield select(getRequestReducer);
            yield put(updateRequestModel(requestReducer.toJSON().requestModel));
        }

        yield put(deleteBookingAction.success(action.payload.bookingId));

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(deleteBookingAction.failure(action.payload.bookingId));
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleDeleteBookings(action) {
    try {
        yield call(deleteBookings, action.payload.bookingIds);

        if (action.payload.refreshStats) {
            const requestReducer = yield select(getRequestReducer);
            yield put(updateRequestModel(requestReducer.toJSON().requestModel));
        }

        yield put(deleteBookingsAction.success(action.payload.bookingIds));

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(deleteBookingsAction.failure(action.payload.bookingIds));
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleDeleteAllRepeatBookings(action) {
    try {
        yield call(deleteAllRepeatBookings, action.payload.repeatId);

        if (action.payload.refreshStats) {
            const requestReducer = yield select(getRequestReducer);
            yield put(updateRequestModel(requestReducer.toJSON().requestModel));
        }

        yield put(deleteBookingAction.success(action.payload.bookingId));

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        yield put(deleteBookingAction.failure(action.payload.bookingId));
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleDeleteFutureRepeatBookings(action) {
    try {
        yield call(
            deleteFutureRepeatBookings,
            action.payload.repeatId,
            formatDate(action.payload.date, 'yyyy-MM-dd', false)
        );

        if (action.payload.refreshStats) {
            const requestReducer = yield select(getRequestReducer);
            yield put(updateRequestModel(requestReducer.toJSON().requestModel));
        }

        yield put(deleteBookingAction.success(action.payload.bookingId));

        if (action.payload.callback) {
            action.payload.callback();
        }
    } catch (error) {
        yield put(deleteBookingAction.failure(action.payload.bookingId));
        yield put(addNotification({ type: 'danger' }));
    }
}

function* handleUpdateBooking(action) {
    try {
        const bookings = yield call(updateBooking, action.payload.bookingId, {
            booking: action.payload.booking,
            options: action.payload.options,
        });
        const isAnyBookingFilterApplied = yield select(selectIsAnyBookingFilterApplied);

        action.payload.callback && action.payload.callback(bookings);

        const pathname = yield select(state => state.router.location.pathname);
        const params2 = params([...childRoutePaths, ...routePaths], pathname)?.params || {};
        const viewObject = getViewObject(params2);

        const companySettings = yield select(state => state.companyReducer.company.settings);

        if (action.payload.options.createRepeatFromExisting) {
            removeDaypilotEvent(action.payload.bookingId);
        }

        if (bookings?.length === 1 && bookings[0]._id !== action.payload.bookingId) {
            removeDaypilotEvent(action.payload.bookingId);
        }

        if (action.payload.options?.modifyAllRepeated && action.payload.options?.removeRemovedRepeated) {
            deleteRepeatedBookings(action.payload.booking.repeatId);
        }

        if (
            bookings?.some(booking => booking.childIds?.length) ||
            bookings?.some(booking => booking.parentIds?.length)
        ) {
            dispatchRefreshBookings();
        } else if (isAnyBookingFilterApplied) {
            dispatchRefreshBookings({ bookingId: action.payload.bookingId });
        } else {
            updateDaypilotEvents(
                formatBookingsToDP(bookings, window.schedulerRef?.current?.control.mode, viewObject),
                companySettings,
                []
            );
        }

        if (action.payload.booking.type === REJECTED.value) {
            yield put(rejectBookingAction.success(action.payload.bookingId));
        }

        if (action.payload.bookingId) {
            eventsIdsMoving.delete(action.payload.bookingId);
        }
        yield put(updateBookingAction.success(bookings));
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        action.payload.onFailure && action.payload.onFailure();
        const cleanUpAction = () => {
            action.payload.bookingId && eventsIdsMoving.delete(action.payload.bookingId);
            dispatchRefreshBookings();
        };

        yield handleErrors(
            action,
            error,
            overscheduleHandler(true),
            linkedBookingsRepeatedErrorHandler,
            linkedBookingsMoveOutsideWorkingHours({ cleanUpAction, bookingsCount: 1 }),
            linkedBookingsNonMovableBooking({ cleanUpAction }),
            linkedBookingsMoveRepeated({ cleanUpAction }),
            linkedBookingsDatesErrorHandler({
                cleanUpAction,
            }),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true, cleanUpAction }),
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: `Bookings couldn't be updated`,
                cleanUpAction,
            })
        );
    }
}

function* handleUpdateBookingSuccess(action) {
    const bookingStatusesFilters = yield select(selectSchedulerBookingStatusesFilter);

    const booking = action.payload.bookings[0];

    if (bookingStatusesFilters.filters?.length) {
        const shouldRemoveByStatus = !bookingStatusesFilters.filters.includes(booking.type);

        if (shouldRemoveByStatus) {
            removeDaypilotEvent(booking._id);
        }
    }
}

function* handleUpdateBookings(action) {
    try {
        const copyOfOptions = cloneDeep(action.payload.options);
        delete copyOfOptions.allMovingEvents;

        // options to be unified if on queryParams or in body, in both right now due to merge of two independently implemented features
        const bookings = yield call(bulkUpdateBookings, {
            bookings: action.payload.bookings,
            options: copyOfOptions,
        });

        action.payload.callback && action.payload.callback(bookings);
        yield put(updateMultipleBookingsAction.success(bookings));

        dispatchRefreshBookings();

        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        action.payload.onFailure && action.payload.onFailure();
        const cleanUpAction = () => {
            dispatchRefreshBookings();

            const movingBookings = action.payload.options?.allMovingEvents ?? action.payload.bookings ?? [];

            movingBookings.forEach(event => {
                eventsIdsMoving.delete(event.id || event._id);
            });
        };

        const movingBookings = action.payload.options?.allMovingEvents ?? action.payload.bookings ?? [];

        movingBookings.forEach(event => {
            eventsIdsMoving.delete(event.id || event._id);
        });

        yield handleErrors(
            action,
            error,
            linkedBookingsRepeatedErrorHandler,
            overscheduleHandler(true),
            linkedBookingsMoveOutsideWorkingHours({ cleanUpAction, bookingsCount: movingBookings.length }),
            linkedBookingsNonMovableBooking({ cleanUpAction }),
            linkedBookingsMoveRepeated({ cleanUpAction }),
            linkedBookingsDatesErrorHandler({ cleanUpAction }),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true, cleanUpAction }),
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: `Bookings couldn't be updated`,
                cleanUpAction,
            })
        );
    }
}

function* handleUpdateResourceBookings(action) {
    try {
        const bookings = yield call(updateBooking, action.payload.bookingId, {
            booking: action.payload.booking,
            options: action.payload.options,
        });

        action.payload.callback && action.payload.callback(bookings);
        yield put(updateMultipleBookingsAction.success(bookings));
        yield put(updateBookingAction.success(bookings));

        const companySettings = yield select(state => state.companyReducer.company.settings);
        const pathname = yield select(state => state.router.location.pathname);
        const params2 = params([...childRoutePaths, ...routePaths], pathname)?.params || {};
        const viewObject = getViewObject(params2);

        if (window.schedulerRef?.current) {
            updateDaypilotEvents(
                formatBookingsToDP(bookings, window.schedulerRef?.current?.control.mode, viewObject),
                companySettings,
                []
            );
            if (action.payload.options.createRepeatFromExisting) {
                removeDaypilotEvent(action.payload.bookingId);
            } else if (action.payload.options.removeRemovedRepeated) {
                const repeatedBookingsIds = getRepeatedBookingIdsToRemove(bookings);
                removeDaypilotEvents(repeatedBookingsIds, window.schedulerRef?.current?.control);
            }

            if (bookings?.length === 1 && bookings[0]._id !== action.payload.bookingId) {
                removeDaypilotEvent(action.payload.bookingId);
            }
        }

        yield put(updateRequestModel());
        yield put(hideModal());

        if (
            action.payload.booking?.repeat &&
            action.payload.booking?.type === WAITING_FOR_APPROVAL.value &&
            action.payload.options?.updateJustOne
        ) {
            // let's refresh bookings as other repeated requests require to have fresh data in scheduler
            dispatchRefreshBookings();
        }

        yield put(updateBookingSelection.request());
    } catch (error) {
        monitoring.captureException(error);
        const cleanUpAction = () => {
            dispatchRefreshBookings();
        };

        const movingBookings = action.payload.options?.allMovingEvents ?? action.payload.bookings ?? [];

        movingBookings.forEach(event => {
            eventsIdsMoving.delete(event.id || event._id);
        });

        yield handleErrors(
            action,
            error,
            linkedBookingsRepeatedErrorHandler,
            overscheduleHandler(true),
            linkedBookingsMoveOutsideWorkingHours({ cleanUpAction, bookingsCount: movingBookings.length }),
            linkedBookingsNonMovableBooking({ cleanUpAction }),
            linkedBookingsDatesErrorHandler({ cleanUpAction }),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: `Bookings couldn't be updated`,
                cleanUpAction,
            })
        );
    }
}

function* bulkCreateBookingRequest(action) {
    try {
        const { bookingId, data, options } = action.payload;
        const viewObject = yield select(getViewObjectSelector());

        const dto = {
            options,
            id: bookingId,
            ...data,
        };
        const bookings = yield call(bulkCreateBookings, dto);

        if (window.schedulerRef?.current?.control) {
            addDaypilotEvent(bookings, window.schedulerRef?.current?.control, viewObject);
            window.schedulerRef?.current?.control.clearSelection();
        }

        yield put(updateBookingSelection.request());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler(error?.data?.code, "Bookings couldn't be created")
        );
    }
}

function* handleBulkMove(action) {
    const { values, bulkAction, resourceId, projectId, options } = action.payload;
    const companySettings = yield select(getCompanySettings);
    const viewObject = yield select(getViewObjectSelector());

    try {
        yield put(startSubmit(BULK_MOVE_FORM_NAME));
        let newBookings;
        const formattedBody = {
            ...mapFormToRequest(values, bulkAction, resourceId),
            options,
        };

        switch (bulkAction) {
            case 'shiftProjectResourceBookings':
                newBookings = yield call(shiftProjectResourceBookings, projectId, resourceId, formattedBody);
                break;
            case 'shiftResourceBookings':
                newBookings = yield call(shiftResourceBookings, resourceId, formattedBody);
                break;
            case 'shiftProjectBookings':
                newBookings = yield call(shiftProjectBookings, projectId, formattedBody);
                break;
            case 'moveProjectBookingsBetweenResources':
                newBookings = yield call(moveProjectBookingsBetweenResources, projectId, formattedBody);
                break;
            case 'moveBookingsBetweenResources':
                newBookings = yield call(moveBookingsBetweenResources, formattedBody);
                break;
        }
        updateDaypilotEvents(
            formatBookingsToDP(newBookings, window.schedulerRef?.current?.control.mode, viewObject),
            companySettings,
            []
        );
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler(error?.data?.code, "Bookings couldn't be created")
        );
        yield put(stopSubmit(BULK_MOVE_FORM_NAME));
    }
}

function* handleSplitBooking(action) {
    try {
        yield call(splitBooking, {
            ...action.payload,
            options: {
                ...(action.payload.options || {}),
                moveDeadline: false,
            },
        });
        clearSelection();
        yield put(setSchedulerSelection.request);
        yield put(splitBookingAction.success({ bookingId: action.payload.bookingId }));
        removeBookingById({ bookingId: action.payload.bookingId });
        dispatchRefreshBookings();
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            overscheduleHandler(),
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler(error?.data?.code, "Booking couldn't be splitted")
        );
    }
}

function* handleUpdateBookingDeadline(action) {
    const { booking, options, callback } = action.payload;
    const {
        bookingAfterDeadlineConfirmation,
        createRepeatFromExisting,
        modifyAllRepeated,
        removeRemovedRepeated,
    } = options;

    try {
        const response = yield call(updateBooking, booking._id, {
            booking: {
                ...booking,
                ...(options.modifyAllRepeated === false
                    ? {
                          repeat: false,
                          repeatId: null,
                          repeatTimes: 0,
                      }
                    : {}),
            },
            options: {
                bookingAfterDeadlineConfirmation,
                createRepeatFromExisting,
                modifyAllRepeated,
                removeRemovedRepeated,
            },
        });
        if (callback) {
            callback();
        }
        yield put(updateDeadline.success({ bookings: response }));
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            deadlineBeforeBookingEndErrorHandler({ isBooking: false, cleanUpAction: action.payload.onFailure }),
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                defaultMessage: "Deadline couldn't be updated",
            })
        );
    }
}

function* handleUpdateBookingDeadlineSuccess(action) {
    const { bookings = [] } = action.payload;
    updateDeadlinesInScheduler({ bookings });
    yield put(setSchedulerBookingLinks({ bookings }));
    yield put(hideContextMenu(contextMenuTypes.EDIT_BOOKING_CONTEXT_MENU));
}

function* handleUpdateBookingDeadlineFailure() {
    dispatchRefreshBookings();
    yield put(hideContextMenu(contextMenuTypes.EDIT_BOOKING_CONTEXT_MENU));
}

function* handleCreateBookingDeadline(action) {
    const { booking, options, callback } = action.payload;

    try {
        const response = yield call(updateBooking, booking._id, {
            booking: {
                ...booking,
                ...(options.modifyAllRepeated === false
                    ? {
                          repeat: false,
                          repeatId: null,
                          repeatTimes: 0,
                      }
                    : {}),
            },
            options,
        });

        if (callback) {
            callback();
        }
        yield put(createDeadline.success(response, options));
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            deadlinesMoveErrorHandler,
            deadlineBeforeBookingEndErrorHandler({ isBooking: true }),
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: "Deadline couldn't be created",
            })
        );

        yield put(createDeadline.failure());
    }
}

function* handleCreateBookingDeadlineSuccess(action) {
    const { bookings = [] } = action.payload;
    addDeadlinesToScheduler({ bookings });
    yield put(setSchedulerBookingLinks({ bookings }));
    yield put(hideContextMenu(contextMenuTypes.EDIT_BOOKING_CONTEXT_MENU));
}

function* handleDeleteBookingDeadline(action) {
    const { booking, options, callback } = action.payload;
    const { bookingId } = booking;

    try {
        yield call(updateBooking, bookingId, {
            booking: {
                _id: bookingId,
                deadlineDate: null,
                deadlineName: null,
                ...(options.modifyAllRepeated === false
                    ? {
                          repeat: false,
                          repeatId: null,
                          repeatTimes: 0,
                      }
                    : {}),
            },
            options,
        });

        if (callback) {
            callback();
        }

        yield put(deleteDeadline.success(booking, options));
    } catch (error) {
        monitoring.captureException(error);
        yield put(deleteDeadline.failure(booking, options));
        yield put(addNotification({ type: 'danger', message: "Deadline couldn't be deleted" }));
    }
}

function* handleDeleteBookingDeadlineSuccess(action) {
    const { booking, options } = action.payload;

    if (!options.modifyAllRepeated) {
        deleteDeadlineFromScheduler({ bookingId: booking.bookingId });
    } else {
        deleteDeadlinesFromSchedulerForSeries({ repeatId: booking.repeatId });
    }

    dispatchRefreshBookings();

    yield put(hideContextMenu(contextMenuTypes.EDIT_DEADLINE_CONTEXT_MENU));
}

function* handleDeleteBookingDeadlineFailure() {
    yield put(hideContextMenu(contextMenuTypes.EDIT_BOOKING_CONTEXT_MENU));
}

function* handleUpdateBookingDeadlines(action) {
    const { bookings } = action.payload;

    try {
        const response = yield call(bulkUpdateBookings, {
            bookings: bookings,
        });
        yield put(updateDeadlines.success({ bookings: response }));
    } catch (error) {
        monitoring.captureException(error);
        yield put(updateDeadlines.failure());
        yield put(addNotification({ type: 'danger', message: "Deadlines couldn't be updated" }));
    }
}

function handleUpdateBookingDeadlinesSuccess(action) {
    updateDeadlinesInScheduler({
        bookings: action.payload.bookings,
    });
    updateVisibleLinksOnScheduler();
}

function handleUpdateBookingDeadlinesFailure() {
    dispatchRefreshBookings();
}

function updateDeadlinesVisibilitySaga() {
    updateDeadlinesVisibility();
}

export default function* getBookingsWatcher() {
    yield takeLatest(actionTypes.GET_BOOKINGS['REQUEST'], handleGetBookings);
    yield takeLatest(actionTypes.GET_BOOKING['REQUEST'], handleGetBooking);
    yield takeLatest(actionTypes.DELETE_BOOKING['REQUEST'], handleDeleteBooking);
    yield takeLatest(actionTypes.DELETE_ALL_REPEAT_BOOKINGS['REQUEST'], handleDeleteAllRepeatBookings);
    yield takeLatest(actionTypes.DELETE_FUTURE_REPEAT_BOOKINGS['REQUEST'], handleDeleteFutureRepeatBookings);
    yield takeLatest(actionTypes.CREATE_BOOKING['REQUEST'], handleCreateBookings);
    yield takeLatest(actionTypes.CUT_BOOKING['REQUEST'], handleCreateBookings);
    yield takeLatest(actionTypes.DUPLICATE_BOOKING['REQUEST'], handleDuplicateBookings);
    yield takeLatest(actionTypes.UPDATE_BOOKING['REQUEST'], handleUpdateBooking);
    yield takeLatest(actionTypes.UPDATE_BOOKING['SUCCESS'], handleUpdateBookingSuccess);
    yield takeLatest(actionTypes.UPDATE_BOOKINGS['REQUEST'], handleUpdateBookings);
    yield takeLatest(actionTypes.SPLIT_BOOKING['REQUEST'], handleSplitBooking);
    yield takeLatest(actionTypes.BULK_CREATE_BOOKINGS['REQUEST'], bulkCreateBookingRequest);
    yield takeLatest(actionTypes.CREATE_VACATION_RESOURCE_REQUEST, createVacationOrResourceRequest);
    yield takeLatest(actionTypes.BULK_MOVE_BOOKINGS['REQUEST'], handleBulkMove);
    yield takeLatest(actionTypes.EDIT_RESOURCE_VACATION_REQUEST, handleUpdateResourceBookings);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINE['REQUEST'], handleUpdateBookingDeadline);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINE['SUCCESS'], handleUpdateBookingDeadlineSuccess);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINE['FAILURE'], handleUpdateBookingDeadlineFailure);
    yield takeLatest(actionTypes.CREATE_BOOKING_DEADLINE['REQUEST'], handleCreateBookingDeadline);
    yield takeLatest(actionTypes.CREATE_BOOKING_DEADLINE['SUCCESS'], handleCreateBookingDeadlineSuccess);
    yield takeLatest(actionTypes.DELETE_BOOKING_DEADLINE['REQUEST'], handleDeleteBookingDeadline);
    yield takeLatest(actionTypes.DELETE_BOOKING_DEADLINE['SUCCESS'], handleDeleteBookingDeadlineSuccess);
    yield takeLatest(actionTypes.DELETE_BOOKING_DEADLINE['FAILURE'], handleDeleteBookingDeadlineFailure);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINES['REQUEST'], handleUpdateBookingDeadlines);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINES['SUCCESS'], handleUpdateBookingDeadlinesSuccess);
    yield takeLatest(actionTypes.UPDATE_BOOKING_DEADLINES['FAILURE'], handleUpdateBookingDeadlinesFailure);
    yield takeLatest(actionTypes.CREATE_BOOKING['SUCCESS'], updateDeadlinesVisibilitySaga);
    yield takeLatest(actionTypes.DELETE_BOOKINGS['REQUEST'], handleDeleteBookings);
}
