import { useCallback } from 'react';
import { useHasRights, useIsExtensionInstalled } from 'hooks';
import { useRequests } from 'modules/request/hooks';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { useScheduleRights } from 'modules/scheduler/hooks';
import { VACATION_REQUEST, RESOURCE_REQUEST } from 'enums/extensionShortIdEnum';

export function useMenuProjectPermissions() {
    const { hasSettingPhase, hasSettingProjectMilestones, canAddDates, hasAddEditProjectRights } = useHasRights([
        {
            rights: ['settingProjectPhases'],
            rule: 'one',
            name: 'hasSettingPhase',
        },
        {
            rights: ['settingProjectMs'],
            rule: 'one',
            name: 'hasSettingProjectMilestones',
        },
        {
            rights: ['settingProjectDates'],
            rule: 'one',
            name: 'canAddDates',
        },
        {
            rights: ['settingAddEditProjects'],
            rule: 'one',
            name: 'hasAddEditProjectRights',
        },
    ]);

    return { hasSettingPhase, hasSettingProjectMilestones, canAddDates, hasAddEditProjectRights };
}

export function useMenuResourcePermissions() {
    const { hasCreateResourceRights } = useHasRights([
        {
            rights: ['settingAddEditResources'],
            rule: 'one',
            name: 'hasCreateResourceRights',
        },
    ]);

    return { hasCreateResourceRights };
}

export function useMenuBookingPermissions(isRequestMode, combineScheduleRequest, preparedRowTags) {
    const { canRequestResources } = useRequests();
    const isRequestExtensionsInstalled = useIsExtensionInstalled(RESOURCE_REQUEST);
    const { hasScheduleRights, hasScheduleRightsForResourceAndProject } = useScheduleRights(preparedRowTags);

    const checkScheduleRights = useCallback(
        (project, resource) =>
            project &&
            resource &&
            hasScheduleRightsForResourceAndProject(project._id, resource._id, resource.type === TYPE_UNASSIGNED.value),
        [hasScheduleRightsForResourceAndProject]
    );

    const canRequestOrSchedule =
        (isRequestMode && canRequestResources) ||
        (!isRequestMode && (canRequestResources || hasScheduleRights.schedule));

    const showScheduleButton = (combineScheduleRequest || !isRequestMode) && preparedRowTags.hasOnlyScheduleRights;
    const showRequestButton =
        isRequestExtensionsInstalled && canRequestResources && (combineScheduleRequest || isRequestMode);
    const hasRightsToUseForm = showScheduleButton || showRequestButton;

    return {
        hasScheduleRights,
        canRequestResources,
        checkScheduleRights,
        canRequestOrSchedule,
        hasRightsToUseForm,
        showRequestButton,
        showScheduleButton,
    };
}

export function useMenuVacationPermissions(showRequestVacationOption, rowTags) {
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);

    const { canRequestVacation, hasManageAllVacationsRights } = useHasRights([
        {
            rights: ['canRequestVacation'],
            rule: 'one',
            name: 'canRequestVacation',
        },
        {
            rights: ['manageAllVacations'],
            rule: 'one',
            name: 'hasManageAllVacationsRights',
        },
    ]);

    return {
        canRequestVacation:
            showRequestVacationOption && !rowTags.unassignedRow && canRequestVacation && isVacationExtensionInstalled,
        hasManageAllVacationsRights: hasManageAllVacationsRights,
    };
}

export function useMenuPermissions() {
    const projectPermissions = useMenuProjectPermissions();
    const resourcePermissions = useMenuResourcePermissions();

    const { canEditCreateNotes } = useHasRights([
        {
            rights: ['settingBookingNotes'],
            rule: 'one',
            name: 'canEditCreateNotes',
        },
    ]);

    return { ...projectPermissions, ...resourcePermissions, canEditCreateNotes };
}
