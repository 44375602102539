export const iconTypes = [
    'fa-link',
    'fa-trello',
    'fa-google',
    'fa-language',
    'fa-file-pdf-o',
    'fa-file-word-o',
    'fa-file-excel-o',
    'fa-file-powerpoint-o',
    'fa-file-photo-o',
    'fa-file',
    'fa-youtube',
    'fa-dropbox',
    'fa-stack-overflow',
    'fa-instagram',
    'fa-flickr',
    'fa-tumblr-square',
    'fa-apple',
    'fa-windows',
    'fa-android',
    'fa-linux',
    'fa-dribbble',
    'fa-skype',
    'fa-foursquare',
    'fa-female',
    'fa-male',
    'fa-archive',
    'fa-bug',
    'fa-stack-exchange',
    'fa-vimeo-square',
    'fa-slack',
    'fa-envelope-square',
    'fa-yahoo',
    'fa-reddit',
    'fa-stumbleupon',
    'fa-delicious',
    'fa-digg',
    'fa-drupal',
    'fa-git-square',
    'fa-cc-visa',
    'fa-cc-mastercard',
    'fa-cc-discover',
    'fa-cc-amex',
    'fa-cc-paypal',
    'fa-cc-stripe',
    'fa-facebook-official',
    'fa-pinterest-p',
    'fa-whatsapp',
];
