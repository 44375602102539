import { createSelector } from 'reselect';
import { filter } from 'lodash';
import { arrayToObjectByKey } from '../utils/mappingUtil';

export const getCategoryTemplates = state => state.categoryTemplateReducer.categoryTemplates;
export const selectCategoryTemplatesLoaded = state => !state.categoryTemplateReducer.loading;

export const makeGetFilteredCategoryTemplates = filterType =>
    createSelector(getCategoryTemplates, categoryTemplates => {
        switch (filterType) {
            case 'WITH_DELETED':
                return categoryTemplates;
            case 'EXCLUDE_DELETED':
            default:
                return filter(categoryTemplates, category => !category.deleted);
        }
    });

export const selectCategoriesMap = createSelector(getCategoryTemplates, templates =>
    arrayToObjectByKey(templates, '_id')
);

export const makeSelectCategoryById = id =>
    createSelector(selectCategoriesMap, categoryTemplatesMap => {
        return categoryTemplatesMap[id];
    });
