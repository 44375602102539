import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Edit } from '@material-ui/icons';
import { NavItem, NavLink } from 'reactstrap';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { useGroup } from 'modules/scheduler/hooks';
import { useIsExtensionInstalled, useProjectAction } from 'hooks';
import { SMART_GROUPS } from 'enums/extensionShortIdEnum';
import { useDispatch, useSelector } from 'react-redux';
import { showProjectModal, showResourceModal, showSchedulerBuilderModal } from 'actions/modalActions';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { GROUP, ITEM } from 'enums/groupTypeEnum';
import { makeResourceById } from '../../../../../selectors/resource';
import { makeProjectById } from '../../../../../selectors/project';
import { updateResource } from '../../../../../actions/resourceActions';
import { useSchedulerRef } from '../../../../../shared/hooks';

const BuilderOpener = React.memo(() => {
    const params = useParams();
    const groups = useGroup(params);
    const dispatch = useDispatch();
    const { schedulerRef } = useSchedulerRef();
    const { updateProject } = useProjectAction(schedulerRef);
    const isSmartGroupInstalled = useIsExtensionInstalled(SMART_GROUPS);

    const { isProjectView, isGroupView } = getViewObject(params);
    const builderType = isProjectView ? PROJECT.name : RESOURCE.name;

    const itemSelector = useMemo(() => {
        if (params.resourceId) {
            return makeResourceById(params.resourceId);
        }

        if (params.projectId) {
            return makeProjectById(params.projectId);
        }

        return makeResourceById();
    }, [params.projectId, params.resourceId]);

    const item = useSelector(itemSelector);

    const onShowFiltersModal = useCallback(() => {
        if (!isGroupView && item) {
            if (params.resourceId) {
                return dispatch(
                    showResourceModal({
                        onSubmit: data => dispatch(updateResource.request(item._id, data)),
                        type: item.type,
                        resourceId: item._id,
                    })
                );
            } else if (params.projectId) {
                return dispatch(
                    showProjectModal({
                        onSubmit: data => dispatch(updateProject(item._id, data)),
                        type: item.type,
                        projectId: item._id,
                    })
                );
            }
        }

        return dispatch(
            showSchedulerBuilderModal({
                itemTypeName: builderType,
                itemId: isGroupView
                    ? params.resourceGroupId || params.projectGroupId
                    : params.resourceId || params.projectId,
                groupType: isGroupView ? GROUP : ITEM,
                parentGroupId: '',
                isFilterContext: true,
                initialTab: groups.current.isSmart && isSmartGroupInstalled ? 3 : 2,
                isEditView: true,
            })
        );
    }, [
        builderType,
        dispatch,
        groups,
        isGroupView,
        isSmartGroupInstalled,
        item,
        params.projectGroupId,
        params.projectId,
        params.resourceGroupId,
        params.resourceId,
        updateProject,
    ]);

    return (
        <NavItem
            title="Edit View"
            className="py-2 px-3 d-flex align-items-center justify-content-center"
            style={{ borderRight: '1px solid #e4e4e4' }}
        >
            <NavLink href="#" title="Edit View" onClick={onShowFiltersModal}>
                <Edit data-cy="schedule-build-button" />
            </NavLink>
        </NavItem>
    );
});

export default BuilderOpener;
