import moment from 'moment';
import { includes } from 'lodash';
import { isBefore, isAfter, differenceInDays, isSameDay } from 'date-fns';
import { TYPE_EVENT, TYPE_MILESTONE, TYPE_PHASE } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { getNewProjectId } from './utils';
import { hasRole } from 'utils/rightsUtil';
import event from './onEventMoved/event';
import { store } from '../../../../store';
import { eventsIdsMoving } from '../../utils/schedulerUtil';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { eventMovingCache } from './onEventMoving';

const eventMoveMaxDays = 60;

export const getInfoModalTitle = (eventType = 'Booking') => `You cannot move this ${eventType}`;
const getMoveMilestoneOrPhaseToUnassignedRowWarning = () => 'You cannot move milestone/phase to unassigned work row.';
const getMoveEventWarning = (bookingTitle, resourceName) =>
    `Sorry, You are trying to move the booking ${bookingTitle} to the event row for ${resourceName}. Only events can be added to this row. Try change the view mode or add an event here.`;
const getMoveWarning = (currentProjectName, newProjectName, { projectKeyWord }) =>
    `Sorry, You cannot move the ${currentProjectName} booking as it does not match the ${projectKeyWord} where you are moving it to, which is ${newProjectName}. This may be due to the viewing mode you are currently in. Try change the view mode and try again.`;
const getMoveSelectRowWarning = bookingTitle =>
    `Sorry, You are trying to move the ${bookingTitle} booking to a disabled row. No bookings or events can be added to this row.`;
const getMoveRepeatVerticalWarning = bookingType => `Sorry, You cannot move this ${bookingType} to another row.`;
const getMoveInProgressWarning = (bookingType = 'booking') =>
    `Sorry, You cannot move this ${bookingType}, another moving operation for it is in progress`;
const getMoveDisabledLongerBookingsWarning = (bookingType = 'booking') =>
    `Sorry, You can't move a ${bookingType} that exceeds ${eventMoveMaxDays} days (incl. non working days). You will need to edit the dates manually`;
const getMoveParentRowWarning = bookingTitle =>
    `Sorry, You are trying to move the ${bookingTitle} booking to a parent row. No bookings or events can be added to this row.`;
const getNoRightsWarning = (eventType = 'booking') =>
    `Sorry, you do not have permission to move this ${eventType} to this row.`;
const getDatesWarning = () => `Sorry, You cannot move the date event. Start date cannot be greater than end date`;
export const multiMoveDatesWarning = () => `Bookings overlapping the end of day / start of day cannot be moved.`;
export const singleMoveDatesWarning = () => `Bookings overlapping the end of day / start of day cannot be moved.`;
export const multiMoveResizeDatesWarning = () =>
    `Bookings overlapping the end of day / start of day cannot be moved / resized.`;
export const singleMoveResizeDatesWarning = () =>
    `Bookings overlapping the end of day / start of day cannot be moved / resized.`;

const getEventType = eventData => {
    let type = 'Booking';

    if (eventData.phase) {
        type = TYPE_PHASE.value;
    } else if (eventData.milestone) {
        type = TYPE_MILESTONE.value;
    }

    return type;
};

export default ({
    resourceRoleRights,
    resourceId,
    keyWords,
    companyWeekdays,
    showInfoModal,
    hasScheduleRights,
    viewObject,
    isDependencyExtensionInstalled,
}) => args => {
    const { projectKeyWord } = keyWords;

    const multiMoveLenght = args.multimove.length;
    const reduxState = store.getState();
    const account = reduxState?.account;
    const { vacationId } = reduxState?.companyReducer.company.settings;
    const currentUserId = account?.resourceId;
    const smartResizeEnabled = account?.preferences?.grid?.eventSmartResize;

    const fullSchedulingRights = hasRole(resourceRoleRights, 'manageEvents');

    const isPhase = args.e.data.phase;
    const isDatesEvent = args.e.data.datesEvent;
    const isMilestone = args.e.data.milestone;
    const isDeadline = args.e.data.deadline;

    const isNotRegularEvent = isPhase || isDatesEvent || isMilestone;
    const isRegularEvent = !isNotRegularEvent && !isDeadline;

    let doMove = true;

    if (eventsIdsMoving.has(args.e.data.id)) {
        showInfoModal(getInfoModalTitle(), getMoveInProgressWarning());
        doMove = false;
    }

    if (isRegularEvent) {
        const daysOfEvent = differenceInDays(args.e.data.end.toDate(), args.e.data.start.toDate()) + 1;
        if (smartResizeEnabled && daysOfEvent > eventMoveMaxDays) {
            showInfoModal(getInfoModalTitle(), getMoveDisabledLongerBookingsWarning());
            doMove = false;
        }
    }

    const scheduler = args.control;
    const destinationRow = scheduler.rows.find(args.newResource);

    if (!destinationRow) {
        eventMovingCache.clear(args.control);
        return;
    }

    const projectId = args.e.data.projectId || args.e.data.project?._id;
    const isProjectRow = destinationRow.data.tags.isProjectRow;
    const hasResourceRowChanged = !isProjectRow && args.newResource !== args.e.data.resource;
    const hasProjectRowChanged = isProjectRow && args.newResource !== projectId;

    const canChangeResource =
        !destinationRow.tags.unassignedParentRow &&
        (destinationRow.tags.resource?.hasRightsToResource ||
            args.e.data.project?.projectManagers?.includes(currentUserId) ||
            (fullSchedulingRights && args.e.data.project?.resources?.includes(destinationRow.tags?.resource?._id))) &&
        args.e.data.isProjectPermitted;

    const canChangeProject = destinationRow.tags.project?.hasRightsToProject && args.e.data.isResourcePermitted;

    const isParentRow = destinationRow.data.tags.parent;

    if (isParentRow && isRegularEvent) {
        args.preventDefault();
        showInfoModal(getInfoModalTitle(), getMoveParentRowWarning(args.e.data.text));
        eventMovingCache.clear(args.control);
        return;
    }

    if (
        isRegularEvent &&
        ((hasResourceRowChanged && !canChangeResource) || (hasProjectRowChanged && !canChangeProject))
    ) {
        args.preventDefault();
        showInfoModal(getInfoModalTitle(), getNoRightsWarning());
        eventMovingCache.clear(args.control);
        return;
    }

    if (isNotRegularEvent) {
        const managePhaseRights = hasRole(resourceRoleRights, 'settingProjectPhases');
        const manageMilestoneRights = hasRole(resourceRoleRights, 'settingProjectMs');
        const manageStartEndDatesRights = hasRole(resourceRoleRights, 'settingProjectDates');

        // Rights required: Full scheduling rights, project, add/edit phase/milestone/dates
        const conditionForNotRegularEvents =
            fullSchedulingRights &&
            destinationRow.tags?.project?.hasRightsToProject &&
            ((isPhase && managePhaseRights) ||
                (isMilestone && manageMilestoneRights) ||
                (isDatesEvent && manageStartEndDatesRights));

        if (!conditionForNotRegularEvents) {
            const modalItemName = [
                isPhase && 'Phase',
                isMilestone && 'Milestone',
                isDatesEvent && 'Start / End Date',
            ].find(name => name && typeof name === 'string');
            showInfoModal(getInfoModalTitle(modalItemName), getNoRightsWarning(modalItemName));
            doMove = false;
        }
    }

    const currentResource = args.e.data.resourceInfo;
    const isPMonProject = includes(args.e.data.project?.projectManagers, resourceId);
    const pmManageUnassignedRows = hasRole(resourceRoleRights, 'pmManageUnassignedRows');
    const manageUnassignedRows = hasRole(resourceRoleRights, 'manageUnassignedRows');
    const currentResourceFullName = currentResource && `${currentResource.firstName} ${currentResource.lastName}`;
    const newProjectId = getNewProjectId(args, destinationRow, scheduler.mode, viewObject.isProjectView);

    if (isDatesEvent) {
        if (
            args.e.data.isProjectStartDate &&
            !isBefore(args.newStart.toDate(), new Date(args.e.data.dateReference)) &&
            !isSameDay(args.newStart.toDate(), new Date(args.e.data.dateReference))
        ) {
            args.preventDefault();
            showInfoModal(getInfoModalTitle('Event'), getDatesWarning());
            eventMovingCache.clear(args.control);
            return;
        }
        if (
            !args.e.data.isProjectStartDate &&
            !isAfter(args.newStart.toDate(), new Date(args.e.data.dateReference)) &&
            !isSameDay(args.newStart.toDate(), new Date(args.e.data.dateReference))
        ) {
            args.preventDefault();
            showInfoModal(getInfoModalTitle('Event'), getDatesWarning());
            eventMovingCache.clear(args.control);
            return;
        }
    }

    if (
        ((isPhase || isDatesEvent || isMilestone) && !isParentRow && !isProjectRow) ||
        (isDatesEvent && newProjectId !== args.e.data.projectId)
    ) {
        args.preventDefault();
        const type = getEventType(args.e.data);

        showInfoModal(getInfoModalTitle(type), getMoveRepeatVerticalWarning(type));
        eventMovingCache.clear(args.control);
        return;
    }

    scheduler.clearSelection();

    let doMoveDatesMultimove = true;
    for (let i = 0; i < multiMoveLenght; i++) {
        const start = args.multimove[i].start;
        const end = args.multimove[i].end;
        if (scheduler.scale === CELLDURATION.value) {
            const dayOfWeek = moment(start.value)
                .format('dddd')
                .toLowerCase();
            const workDay = companyWeekdays[dayOfWeek];
            const workDayEndDay = workDay.endDay;
            const workDayStartDay = workDay.startDay;

            doMoveDatesMultimove = !(
                start.getDay() !== end.getDay() ||
                start.getHours() < workDayStartDay ||
                end.getHours() > workDayEndDay
            );

            doMove = doMoveDatesMultimove;
        }

        if (!doMoveDatesMultimove) {
            multiMoveLenght === 1
                ? showInfoModal(getInfoModalTitle('booking'), singleMoveDatesWarning())
                : showInfoModal(getInfoModalTitle('bookings'), multiMoveDatesWarning());
        }

        if (
            args.e.data.resourceType === TYPE_UNASSIGNED.value &&
            scheduler.mode === PARENT.value &&
            projectId !== newProjectId
        ) {
            const newProjectName = destinationRow.parent();
            doMove = false;
            showInfoModal(
                getInfoModalTitle(),
                getMoveWarning(args.e.data.project.name, newProjectName, { projectKeyWord })
            );
        }

        if (
            (isRegularEvent &&
                destinationRow.data.tags.type === TYPE_UNASSIGNED.value &&
                !manageUnassignedRows &&
                (!pmManageUnassignedRows || (pmManageUnassignedRows && !isPMonProject))) ||
            (isRegularEvent &&
                !hasScheduleRights(scheduler.mode === PARENT.value, destinationRow.tags, args.e.data).schedule) ||
            (isDeadline &&
                !hasScheduleRights(scheduler.mode === PARENT.value, destinationRow.tags, args.e.data.booking).schedule)
        ) {
            if (
                args.e.data.type === WAITING_FOR_APPROVAL.value &&
                isAbleToEditResourceOrVacationRequest(args.e.data, resourceId, resourceRoleRights, vacationId)
            ) {
                doMove = true;
            } else {
                doMove = false;
                showInfoModal(getInfoModalTitle(), getNoRightsWarning());
            }
        }

        const showMoveEventWarning =
            args.e.data.project &&
            ((args.e.data.project.type !== TYPE_EVENT.value &&
                destinationRow.tags.isEventRow &&
                scheduler.mode === PARENT.value) ||
                (args.e.data.project.type === TYPE_EVENT.value && // stop events adding to resource rows.
                    !destinationRow.tags.isEventRow &&
                    !destinationRow.tags.unassignedRow &&
                    !destinationRow.tags.isResourceRow &&
                    scheduler.mode === PARENT.value));

        if (destinationRow.data.tags.isRemoveProjectsRow) {
            doMove = false;
        }

        if ('unassignedRow' === destinationRow.id) {
            const type = getEventType(args.e.data);

            if (TYPE_MILESTONE.value === type || TYPE_PHASE.value === type) {
                doMove = false;
                showInfoModal(getInfoModalTitle('object'), getMoveMilestoneOrPhaseToUnassignedRowWarning());
            }
        }

        if (showMoveEventWarning) {
            doMove = false;
            showInfoModal(getInfoModalTitle(), getMoveEventWarning(args.e.data.text, currentResourceFullName));
        } else if (-1 !== args.newResource.indexOf('selectRow')) {
            doMove = false;
            showInfoModal(getInfoModalTitle(), getMoveSelectRowWarning(args.e.data.text));
        }
        if (!doMove) {
            args.preventDefault();
            break;
        }
    }

    /**
     *  https://hubplanner.atlassian.net/browse/HUB-7867
     *  so we need to change a place for repeated booking check as in onEventMoved callback we
     *  don't have old event data to support onCancel
     *  The args.e object is already updated (it uses the newStart, newEnd, and newResource values) in onEventMoved.
     *  https://api.daypilot.org/daypilot-scheduler-oneventmoved/
     */

    if (!doMove) {
        return;
    }

    const shouldHandleBookingsMove = args.multimove.every(multiMoveEntry => {
        const eventData = multiMoveEntry.event.data;
        return !(eventData.phase || eventData.milestone || eventData.datesEvent);
    });

    if (shouldHandleBookingsMove) {
        event(args, companyWeekdays, viewObject, isDependencyExtensionInstalled);
    }

    eventMovingCache.clear(args.control);
};
