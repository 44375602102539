import React from 'react';
import PropTypes from 'prop-types';
import { RequestLabelNote } from './../styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const Notes = ({ viewNotes, booking, mode }) =>
    viewNotes && booking.hasNotes ? (
        <RequestLabelNote data-cy="booking-note-icon" mode={mode}>
            <InsertDriveFileIcon />
        </RequestLabelNote>
    ) : (
        ''
    );

Notes.propTypes = {
    viewNotes: PropTypes.bool.isRequired,
    booking: PropTypes.object.isRequired,
};

export default Notes;
