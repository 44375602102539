import React from 'react';
import { VacationYear } from 'modules/request/components';
import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { Layout, Typography } from '@hub-mono/ui';
import DoughnutChart from './doughnutChart';

const StyledCard = styled(Card)`
    padding: 24px 16px;
    flex: 0.95;

    &.MuiPaper-root {
        background-color: ${props => props.theme.designTokens.color.m3.sys.light['surface-container'].value};
        border-radius: 8px;
    }
`;

const Legend = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 33.75px;
    background: #64d2ff;
`;

const PieChart = props => {
    const { defaultVacationEventName, totalApprovedDays, totalRemainingDays } = props;

    return (
        <StyledCard elevation={0}>
            <Layout stack gap={16}>
                <Layout stack gap="--Spacing-50">
                    <Typography variant="title" size="medium">
                        Remaining {defaultVacationEventName} Days
                    </Typography>
                    <VacationYear />
                </Layout>
                <div className="row">
                    <DoughnutChart
                        id="single"
                        labels={['Approved Days', 'Remaining Days']}
                        colors={['#E5E2E1', '#64D2FF']}
                        height={235}
                        totalApprovedDays={totalApprovedDays}
                        totalRemainingDays={totalRemainingDays}
                        bottomText="days"
                        centerTextMaxSize={120}
                    />
                </div>
                <Layout gap={12} hAlign="center">
                    <Legend />
                    <Typography variant="title" size="medium">
                        Allocated Balance
                    </Typography>
                </Layout>
            </Layout>
        </StyledCard>
    );
};

export default PieChart;
