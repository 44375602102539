import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes, filter } from 'lodash';
import { useAppKeyWords, useIsExtensionInstalled, useHasRights } from 'hooks';
import unassignedDayPilotMenu from 'modules/scheduler/config/areas/unassigned';
import unassignedChildDayPilotMenu from 'modules/scheduler/config/areas/unassignedChild';
import { SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { UNASSIGNED } from 'enums/extensionShortIdEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { ACTIVE } from 'enums/criteriaEnum';
import { makeGetFilteredResources } from 'selectors/resource';
import { getResources } from 'actions/resourceActions';
import { makeGetUnassignedResourceGroup } from '../../../selectors/resourceGroup';

const rights = [
    {
        rights: ['settingResourceGroups'],
        rule: 'one',
        name: 'hasSettingResourceGroupsRights',
    },
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasSettingAddEditResources',
    },
];

export const useUnassignedMenu = (currentItem, resources, resourceIds, unassignedActions, viewObject) => {
    const dispatch = useDispatch();
    const gridPreferences = useSelector(state => state.account.preferences.grid);
    const resourcesSelector = useMemo(() => makeGetFilteredResources(TYPE_UNASSIGNED.value), []);
    const unassignedGroupSelector = useMemo(() => makeGetUnassignedResourceGroup(), []);
    const unassignedGroup = useSelector(unassignedGroupSelector)

    const unassignedResourcesForMenu = useSelector(resourcesSelector);
    const unassignedResources = useMemo(() => filter(resources, resource => resource.type === TYPE_UNASSIGNED.value), [
        resources,
    ]);
    const { projectKeyWord, projectPluralKeyWord, resourceKeyWord } = useAppKeyWords();
    const isUnassignedActive = useIsExtensionInstalled(UNASSIGNED);
    const { hasSettingResourceGroupsRights, hasSettingAddEditResources } = useHasRights(rights);
    const filteredUnassignedResources = useMemo(
        () => filter(unassignedResources, resource => includes(resourceIds, resource._id)),
        [unassignedResources, resourceIds]
    );
    const shouldRenderUnassigned =
        viewObject.isResourceGroupView || (!viewObject.isResourceView && SINGLE.value !== gridPreferences.mode);
    let unassignedContextMenu;
    let unassignedChildContextMenu;

    useEffect(() => {
        dispatch(getResources.request());
    }, [dispatch]);

    if (isUnassignedActive && shouldRenderUnassigned) {
        unassignedContextMenu = unassignedDayPilotMenu(
            currentItem,
            {
                projectKeyWord,
                projectPluralKeyWord,
                resourceKeyWord,
            },
            unassignedResourcesForMenu,
            unassignedActions,
            viewObject
        );
        unassignedChildContextMenu = unassignedChildDayPilotMenu(
            currentItem,
            hasSettingAddEditResources,
            hasSettingResourceGroupsRights,
            gridPreferences.groupBookings,
            unassignedActions,
            unassignedGroup
        );
    }

    return {
        shouldRenderUnassigned: isUnassignedActive && shouldRenderUnassigned,
        unassignedResources:
            shouldRenderUnassigned &&
            isUnassignedActive &&
            (viewObject.isResourceGroupView && ACTIVE === currentItem?.criteria
                ? unassignedResources
                : filteredUnassignedResources), //exception do not filter unassigned resources on active resource group
        unassignedContextMenu,
        unassignedChildContextMenu,
    };
};
