import { mapObject } from 'underscore';
import moize from 'moize';

export const BUDGET_STATUSES = [
    {
        name: 'Not set',
        value: 'not_set',
    },
    {
        name: 'Over Budget',
        value: 'over',
    },
    {
        name: 'Under Budget',
        value: 'under',
    },
    {
        name: 'On Budget',
        value: 'on',
    },
    {
        name: 'No Budget',
        value: 'no',
    },
];

export const BUDGET_STATUSES_ENUM = {
    OVER: 'OVER',
    UNDER: 'UNDER',
    ON: 'ON_BUDGET',
    NO: 'NO_BUDGET',
    properties: {
        OVER: { name: 'Over budget', value: 'OVER' },
        UNDER: { name: 'Under budget', value: 'UNDER' },
        ON_BUDGET: { name: 'On budget', value: 'ON_BUDGET' },
        NO_BUDGET: { name: 'No budget', value: 'NO_BUDGET' },
    },
};

export const statusesList = moize(() => {
    let statuses = [];

    mapObject(BUDGET_STATUSES_ENUM, (value, key) => {
        'properties' !== key &&
            statuses.push({
                name: BUDGET_STATUSES_ENUM['properties'][value].name,
                value,
            });
    });

    return statuses;
});
