import React from 'react';
import PropTypes from 'prop-types';
import * as DOMPurify from 'dompurify';

const defaultOptions = {
    allowedTags: ['b', 'br', 'hr', 'i', 'em', 'strong', 'a', 'p'],
    selfClosing: ['br', 'hr'],
    allowedAttributes: { a: ['href', 'name', 'rel'] },
};

DOMPurify.addHook('afterSanitizeAttributes', node => {
    if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
    if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
        node.setAttribute('xlink:show', 'new');
    }
});

const sanitize = (dirty, options) => ({
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
});

export const SanitizeHTML = ({ html, options, className }) => (
    <div className={className} dangerouslySetInnerHTML={sanitize(html, options)} />
);

SanitizeHTML.propTypes = {
    html: PropTypes.string.isRequired,
    options: PropTypes.shape({
        allowProtocolRelative: PropTypes.bool,
        enforceHtmlBoundary: PropTypes.bool,
        parseStyleAttributes: PropTypes.bool,
        disallowedTagsMode: PropTypes.string,
        allowedStyles: PropTypes.object,
        nestingLimit: PropTypes.number,
        allowedTags: PropTypes.arrayOf(PropTypes.string),
        selfClosing: PropTypes.arrayOf(PropTypes.string),
        allowedSchemesAppliedToAttributes: PropTypes.arrayOf(PropTypes.string),
        allowedSchemes: PropTypes.arrayOf(PropTypes.string),
        allowedSchemesByTag: PropTypes.shape({
            [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
        }),
        allowedAttributes: PropTypes.shape({
            [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
        }),
        allowedClasses: PropTypes.shape({
            [PropTypes.string]: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
    className: PropTypes.string,
};
