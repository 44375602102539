import React from 'react';
import { contains } from 'underscore';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import { Header } from './../styles';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import { displayDateState, displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';

/**
 * @param {object} dateState
 * @param {string} unit
 * @param {number} avgDailyCapacity
 * @param {array}  columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Capacity
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'capacityBetween',
        sortable: false,
        show: contains(columnsToLoad, 'capacityBetween'),
        accessor: row =>
            displayValue(row.capacityBetween, unitTypes[unit].calculation(row.capacityBetween, avgDailyCapacity)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Capacity
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'capacityPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'capacityPrevious'),
        accessor: row =>
            displayValue(row.capacityPrevious, unitTypes[unit].calculation(row.capacityPrevious, avgDailyCapacity)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Capacity
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'capacityAfter',
        sortable: false,
        show: contains(columnsToLoad, 'capacityAfter'),
        accessor: row =>
            displayValue(row.capacityAfter, unitTypes[unit].calculation(row.capacityAfter, avgDailyCapacity)),
    },
];
