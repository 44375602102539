import styled from 'styled-components';
import { FilterList } from '@material-ui/icons';

export const FiltersBox = styled.div`
    height: ${props => `${props.height || 180}px`};
    width: 255px;
    position: absolute;
    top: 25px;
    ${props => props.position}: 10px;
    z-index: 999;
    background: #fff;
    display: block;
    padding: 15px;
    text-align: left;
    box-shadow: 0 12px 40px 5px rgba(0, 0, 0, 0.26);
    label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        p {
            margin: 10px;
        }
    }
`;

export const FilterIconTable = styled(FilterList)`
    color: ${props => {
        if (props.open && props.active === 'true') {
            return '#000';
        }
        if (props.open) {
            return '#4fbc9d';
        } else if (props.active === 'true') {
            return '#fff';
        }
        return '#c7c7c7';
    }};
    background-color: ${props => {
        if (props.active === 'true') {
            return '#67bfa7';
        }
        return '#fff';
    }};
    border-radius: 50%;
    padding: 3px;
    margin-top: -6px;
    cursor: pointer;
    &:hover {
        color: ${props => {
            if (props.open === 'true') {
                return undefined;
            }
            return '#000';
        }};
    }
`;

export const FilterIcon = styled(FilterList)`
    color: ${props => (props.open ? '#4fbc9d' : 'true' === props.active ? '#fff' : '#c7c7c7')};
    background-color: ${props => (props.open || 'false' === props.active ? '#fff' : '#67bfa7')};
    padding: ${props => (props.open || 'false' === props.active ? '0' : '3px')};
    border-radius: ${props => (props.open || 'false' === props.active ? '0' : '50%')};
    font-size: ${props => (props.open || 'false' === props.active ? '16px' : '18px')} !important;
    cursor: pointer;
    margin-top: -4px;
    &:hover {
        color: #000;
    }
`;

export const Content = styled.div`
    min-height: calc(100% - 45px);
`;

export const Footer = styled.div`
    height: 50px;
    margin-top: 10px;
    button {
        float: right;
        border-radius: 0;
        margin: 0 5px;
    }
`;
