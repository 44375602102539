import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter, isNil } from 'lodash';
import { useSelector } from 'react-redux';
import { Close, Edit } from '@material-ui/icons';
import { Progress, Button } from 'reactstrap';
import classNames from 'classnames';
import { getTasks, createTask, updateTaskById, deleteTaskById } from 'api/task';
import { hasRightToManageTasks } from 'utils/rightsUtil';
import { chooseField as ChooseField, textField as TextField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';
import SmallRemoveButton from 'shared/buttons/smallRemove';
import { TaskList, TaskButton } from 'modules/modals/styles';
import { useIsExtensionInstalled } from 'hooks';
import { TASKS } from 'enums/extensionShortIdEnum';
import MissingExtension from 'shared/warnings/missingExtension';
import { updateDaypilotEvents } from 'modules/scheduler/utils/schedulerUtil';

const TasksList = props => {
    const { bookingId, onFieldChange, project, resource, booking: bookingInitial, schedulerRef, initialTasks } = props;

    // Keep updated with latest DP stored event info
    const booking = {
        ...bookingInitial,
        ...(schedulerRef?.current?.control?.events?.find(event => event?.data?.id === bookingId)?.data || {}),
    };

    const isTaskExtensionInstalled = useIsExtensionInstalled(TASKS);
    const { resourceRoleRights, resourceId, isProjectManager } = useSelector(state => state.account);
    const [tasks, setTask] = useState(initialTasks);
    const [itemBoxVisible, setBoxVisible] = useState(false);
    const [text, setText] = useState('');
    const [editIndex, setEditIndex] = useState(null);
    const taskCompleted = filter(tasks, task => task.checked);
    const completedPercentage = 0 < tasks.length ? (taskCompleted.length * 100) / tasks.length : 0;
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const hasRightsToManageTasks = hasRightToManageTasks(
        { resourceRoleRights, resourceId, isProjectManager },
        bookingId && resource,
        project,
        booking
    );

    useEffect(() => {
        if (bookingId) {
            getTasks({ event: bookingId }).then(setTask);
        }
    }, [bookingId]);

    const updateSchedulerTasks = (tasksTotal, tasksChecked) => {
        booking &&
        updateDaypilotEvents(
            [{ ...booking, tasksTotal, tasksChecked }],
            companySettings,
            []
        );
    };

    const onChecked = event => {
        const currentTasks = [...tasks];
        const [, fieldIndex] = event.target.name.split('_');
        currentTasks[fieldIndex].checked = event.target.checked;
        onFieldChange && onFieldChange({ tasks: currentTasks });
        setTask(currentTasks);
        bookingId && updateTaskById(currentTasks[fieldIndex]._id, currentTasks[fieldIndex]);
        updateSchedulerTasks(tasks.length, tasks.filter(({ checked }) => checked).length);
    };

    const onTextChange = event => {
        setText(event.target.value);
    };

    const addTask = () => {
        if ('' !== text) {
            const currentTasks = [...tasks];
            const task = {
                order: currentTasks.length,
                value: text,
                event: bookingId || null,
                project: project?._id,
                resource: resource?._id,
                checked: false,
            };
            setText('');
            if (!bookingId) {
                currentTasks.push(task);
                setTask(currentTasks);
                onFieldChange && onFieldChange({ taskItem: '', tasks: currentTasks });
            }
            bookingId &&
                createTask(task).then(task => {
                    currentTasks.push(task);
                    setTask(currentTasks);
                    onFieldChange && onFieldChange({ taskItem: '', tasks: currentTasks });
                    updateSchedulerTasks(tasks.length + 1, tasks.filter(({ checked }) => checked).length);
                });
        }
    };

    const toggleBox = () => {
        setEditIndex(null);
        setText('');
        setBoxVisible(prevState => !prevState);
    };

    const addItem = () => {
        setEditIndex(null);
        setBoxVisible(prevState => !prevState);
    };

    const updateItem = () => {
        const currentTasks = [...tasks];
        const task = currentTasks[editIndex];
        task.value = text;
        bookingId && updateTaskById(currentTasks[editIndex]._id, task);
        toggleBox();
    };

    const onEdit = (task, index) => {
        setBoxVisible(true);
        setText(task.value);
        setEditIndex(index);
    };

    const onDelete = fieldIndex => {
        const currentTasks = [...tasks];
        const isChecked = currentTasks[fieldIndex].checked;
        bookingId && deleteTaskById(currentTasks[fieldIndex]._id);
        currentTasks.splice(fieldIndex, 1);
        setText('');
        setTask(currentTasks);
        onFieldChange && onFieldChange({ taskItem: '', tasks: currentTasks });
        updateSchedulerTasks(tasks.length - 1, tasks.filter(({ checked }) => checked).length - isChecked);
        toggleBox();
    };

    const saveBtnDisabled = !text?.trim().length;



    return (
        <>
            {isTaskExtensionInstalled && hasRightsToManageTasks && (
                <div>
                    <section className="form-fields">
                        <span>
                            {taskCompleted.length}/{tasks.length}
                        </span>
                        <Progress value={completedPercentage} />
                        {tasks.length ? (
                            <TaskList>
                                {tasks.map((task, i) => (
                                    <li className="d-flex mb-1" key={i}>
                                        <TaskButton className="mr-2 rounded-0" onClick={() => onEdit(task, i)}>
                                            <Edit />
                                        </TaskButton>
                                        <ChooseField
                                            labelClassName={classNames({ 'text-line-through': task.checked })}
                                            type="checkbox"
                                            inline
                                            label={task.value}
                                            input={{ name: `task_${i}` }}
                                            checked={task.checked}
                                            onChange={onChecked}
                                        />
                                    </li>
                                ))}
                            </TaskList>
                        ) : null}
                        {!itemBoxVisible ? (
                            <Button className="mt-2 sm rounded-0" onClick={addItem}>
                                Add an item
                            </Button>
                        ) : null}
                    </section>
                    {itemBoxVisible ? (
                        <section className="form-fields">
                            <TextField
                                name="taskItem"
                                type="textarea"
                                inline
                                width="col-md-12"
                                rows={2}
                                value={text}
                                wrapperClassName="mb-2"
                                onChange={onTextChange}
                            />
                            <Button
                                className="sm rounded-0"
                                onClick={!isNil(editIndex) ? updateItem : addTask}
                                disabled={saveBtnDisabled}
                            >
                                {!isNil(editIndex) ? 'Save' : 'Add'}
                            </Button>
                            <SmallRemoveButton className="ml-2" icon={<Close fontSize="small" />} onClick={toggleBox} />
                            {!isNil(editIndex) && (
                                <SmallRemoveButton className="float-right" onClick={() => onDelete(editIndex)} />
                            )}
                        </section>
                    ) : null}
                </div>
            )}
            {isTaskExtensionInstalled && !hasRightsToManageTasks && (
                <PermissionDenied
                    description="Sorry, you have no role rights to see these tasks, please contact your
                        Account Owner to adjust your role permissions."
                />
            )}
            {!isTaskExtensionInstalled && (
                <MissingExtension description="Booking Tasks requires Tasks extension. Please enable this extension from extension page" />
            )}
        </>
    );
};

TasksList.propTypes = {
    onFieldChange: PropTypes.func,
    bookingId: PropTypes.string,
    project: PropTypes.object,
    resource: PropTypes.object,
    tasks: PropTypes.array,
    initialTasks: PropTypes.array,
};

TasksList.defaultProps = {
    onFieldChange: null,
    bookingId: '',
    project: null,
    resource: null,
    tasks: [],
    initialTasks: [],
};

export default TasksList;
