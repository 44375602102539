import React from 'react';
import Error from './error';
import { useQueryParams } from '../../../utils/useQueryParams';

const SSOError = () => {
    const { ssoFailed, email } = useQueryParams();

    if (ssoFailed && email) {
        return <Error message={`Email (${email}) returned from your SSO provider does not match any user in the organization. Please contact your IT department.`} />;
    } else if (ssoFailed) {
        return <Error message="Please ensure that your SAML configuration is correct." />;
    }

    return null;
};

export default SSOError;