import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, map, some } from 'lodash';
import styled from 'styled-components';
import SectionList from 'react-virtualized-sectionlist';
import { List as VirtualizedList } from 'react-virtualized';
import { Button } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import { materialInputGroupField as Input } from 'shared/formFields';
import ScrollableListComponent from 'shared/checkboxList/scrollableList';
import { getLabelFromField } from 'utils/mappingUtil';
import { Item } from './styles';
import { useUpDownNavigation } from 'shared/hooks';
import Close from '@material-ui/icons/Close';
import FiltersButtons from 'shared/FiltersButtons';

export const useStyles = makeStyles({
    hoverCssClass: {
        backgroundColor: '#f8f9fa',
    },
});

export const List = styled.div``;

const FILTER_INPUT_HEIGHT = 65;
const FILTER_BUTTONS_HEIGHT = 40;

const search = (filterText, searchArea, fields, searchBy) => {
    const filterTextLowerCase = filterText.toLowerCase();

    return (
        !filterTextLowerCase ||
        (filterTextLowerCase &&
            searchArea[fields.label] &&
            -1 !== searchArea[fields.label].toLowerCase().indexOf(filterTextLowerCase)) ||
        (fields.additional &&
            searchArea[fields.additional] &&
            -1 !== searchArea[fields.additional].toLowerCase().indexOf(filterTextLowerCase)) ||
        some(searchBy, field => -1 !== searchArea[field].toLowerCase().indexOf(filterTextLowerCase))
    );
};

const SearchList = props => {
    const {
        height,
        name,
        itemComponent: ItemComponent,
        fields,
        data,
        isGroupedData,
        searchBy,
        inputSearchClass,
        onSelect,
        onEmptySearch,
        onAddNew,
        addNewBtnText,
        emptyText,
        placeholder,
        scrollMargin,
        rowHeight,
        noArrowsKeysNavigation,
        filtersButtons,
        filterButtonApplied,
        onFilterButtonClicked,
        selectedIds: selectedIdsProp,
        hideSelected,
    } = props;
    const [filterText, setFilterText] = useState('');
    const [isAddNewDisabled, setAddNewDisabled] = useState(false);
    const wrapper = useRef();
    const list = useRef();

    const selectedIds = useMemo(() => (Array.isArray(selectedIdsProp) ? selectedIdsProp : [selectedIdsProp]), [
        selectedIdsProp,
    ]);

    const cssClasses = useStyles();
    const searchListElementRef = useRef();

    const filteredItems = useMemo(() => {
        let filteredItemsTmp = [];

        if (hideSelected) {
            const selectedIdsMap = selectedIds.reduce((acc, id) => {
                acc[id] = true;
                return acc;
            }, {});

            filteredItemsTmp = data.filter(item => !selectedIdsMap[item._id]);
        } else {
            filteredItemsTmp = data;
        }

        if (!isGroupedData) {
            filteredItemsTmp = filter(filteredItemsTmp, item => search(filterText, item, fields, searchBy)).map(
                (item, index) => {
                    return {
                        ...item,
                        arrowsNavigationId: index,
                    };
                }
            );
        } else {
            filteredItemsTmp = map(filteredItemsTmp, (item, index) => ({
                ...item,
                data: filter(item.data, subItem => search(filterText, subItem, fields, searchBy)),
                arrowsNavigationId: index,
            }));
        }

        if (filterButtonApplied) {
            // Filter Buttons
            filteredItemsTmp = filteredItemsTmp.filter(filteredItem => {
                return filteredItem[filterButtonApplied.property] === filterButtonApplied.value;
            });
        }

        return filteredItemsTmp;
    }, [hideSelected, isGroupedData, filterButtonApplied, data, selectedIds, filterText, fields, searchBy]);

    const getNavigableItemsList = useCallback(() => {
        return [
            {
                selector: `[data-arrows-navigation-id='searchInput']`,
                action: 'focus',
            },
            ...filteredItems.map(filteredItem => {
                return {
                    selector: `[data-arrows-navigation-id='${filteredItem.arrowsNavigationId}']`,
                    action: 'toggleClass',
                };
            }),
        ];
    }, [filteredItems]);

    useUpDownNavigation(
        {
            navigableItemsList: noArrowsKeysNavigation ? [] : getNavigableItemsList(),
            toggleCssClass: cssClasses.hoverCssClass,
        },
        searchListElementRef.current
    );

    useEffect(() => {
        onAddNew && setAddNewDisabled(false);
    }, [onAddNew, data.length]);

    useEffect(() => {
        onEmptySearch &&
            ((0 < filterText.length && 0 === filteredItems.length) || !filterText.length) &&
            onEmptySearch(filterText);
    }, [filteredItems, onEmptySearch, filterText]);

    const onClick = useCallback(
        item => {
            if (onSelect && name) {
                onSelect({ [name]: item });
            }
        },
        [onSelect, name]
    );

    const addNew = useCallback(() => {
        onAddNew(filterText);
        setAddNewDisabled(true);
    }, [onAddNew, filterText]);

    const handleScroll = useCallback(
        ({ target }) => {
            const { scrollTop, scrollLeft } = target;
            const listRef = isGroupedData ? list.current._listRef : list.current;

            listRef && listRef.Grid.handleScrollEvent({ scrollTop, scrollLeft });
        },
        [isGroupedData]
    );

    const onFilter = useCallback(event => {
        setFilterText(event.currentTarget.value);
    }, []);

    const ListComponent = isGroupedData ? SectionList : VirtualizedList;
    const calculatedHeight =
        onAddNew && 0 === filteredItems.length
            ? 0
            : height - FILTER_INPUT_HEIGHT - (filtersButtons ? FILTER_BUTTONS_HEIGHT : 0);

    const searchListItemJSX = useCallback(
        ({ item, key, style, index }) => {
            if (!isGroupedData) {
                item = filteredItems[index];
            }
            const label = getLabelFromField(item, fields);

            let innerJSX;

            const selected = selectedIds.includes(item._id);

            if (ItemComponent) {
                innerJSX = (
                    <ItemComponent
                        data-arrows-navigation-id={item.arrowsNavigationId}
                        primaryText={label}
                        style={style}
                        key={key}
                        item={item}
                        data-item={item[fields.value]}
                        onClick={() => onClick(item)}
                        selected={selected}
                    />
                );
            } else {
                innerJSX = (
                    <Item
                        data-arrows-navigation-id={item.arrowsNavigationId}
                        style={style}
                        key={key}
                        data-item={item[fields.value]}
                        onClick={() => onClick(item)}
                        selected={selected}
                    >
                        {label}
                    </Item>
                );
            }

            return <div title={label}>{innerJSX}</div>;
        },
        [fields, onClick, filteredItems, ItemComponent, isGroupedData, selectedIds]
    );

    const headerJSX = ({ title, key, style }) => (
        <div key={key} className="list--header d-flex align-items-center" style={style}>
            <strong>{title}</strong>
        </div>
    );

    const clearInput = () => {
        setFilterText('');
    };

    return (
        <div ref={searchListElementRef}>
            <Input
                className={inputSearchClass}
                placeholder={placeholder}
                onChange={onFilter}
                variant="outlined"
                data-arrows-navigation-id="searchInput"
                endIcon={filterText && filterText !== '' ? <Close /> : undefined}
                endIconAction={clearInput}
                value={filterText}
            />
            {filtersButtons?.length > 1 && (
                <FiltersButtons
                    selected={filterButtonApplied}
                    filtersButtons={filtersButtons}
                    onFilterChanged={onFilterButtonClicked}
                />
            )}
            <div ref={wrapper}>
                <ScrollableListComponent
                    listComponent={List}
                    onScroll={handleScroll}
                    scrollMargin={scrollMargin}
                    height={calculatedHeight}
                >
                    {filteredItems.length > 0 && (
                        <ListComponent
                            width={250}
                            height={height - FILTER_INPUT_HEIGHT}
                            sections={filteredItems}
                            rowCount={filteredItems.length}
                            sectionHeaderRenderer={headerJSX}
                            sectionHeaderHeight={rowHeight}
                            rowHeight={rowHeight}
                            ref={list}
                            style={{ overflowX: false, overflowY: false, width: '100%' }}
                            rowRenderer={searchListItemJSX}
                            containerStyle={{
                                width: '100%',
                                maxWidth: '100%',
                            }}
                        />
                    )}
                    {filteredItems.length === 0 && emptyText.length > 0 && (
                        <h6 className="text-center w-100 wrap-word">{emptyText}</h6>
                    )}
                </ScrollableListComponent>
                {onAddNew && 0 === filteredItems.length && (
                    <Button disabled={isAddNewDisabled} className="w-100 radius-0 text-truncate" onClick={addNew}>
                        {addNewBtnText} {`"${filterText}"`}
                    </Button>
                )}
            </div>
        </div>
    );
};

SearchList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedIds: PropTypes.oneOf([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    hideSelected: PropTypes.bool,
    fields: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        additional: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        wrapWithBrackets: PropTypes.bool,
    }).isRequired,
    searchBy: PropTypes.array,
    onSelect: PropTypes.func,
    onEmptySearch: PropTypes.func,
    onAddNew: PropTypes.func,
    addNewBtnText: PropTypes.string,
    height: PropTypes.number,
    rowHeight: PropTypes.number,
    scrollMargin: PropTypes.bool,
    isGroupedData: PropTypes.bool,
    emptyText: PropTypes.string,
    name: PropTypes.string,
    inputSearchClass: PropTypes.string,
    placeholder: PropTypes.string,
};

SearchList.defaultProps = {
    selectedIds: [],
    hideSelected: false,
    onSelect: null,
    onEmptySearch: null,
    onAddNew: null,
    addNewBtnText: 'Add new',
    height: 350,
    rowHeight: 40,
    scrollMargin: true,
    isGroupedData: false,
    searchBy: [],
    emptyText: '',
    placeholder: '',
    inputSearchClass: '',
    name: '',
};

export default React.memo(SearchList);
