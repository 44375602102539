import React from 'react';
import { VacationYear } from 'modules/request/components';
import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { Icon, Layout, Typography } from '@hub-mono/ui';
import { toFixedTrimmed } from '@hub-mono/utils';
import DoughnutChart from '../pieChart/doughnutChart';

const StyledCard = styled(Card)`
    padding: 24px 16px;
    flex: 0.95;

    &.MuiPaper-root {
        background-color: ${props => props.theme.designTokens.color.m3.sys.light['surface-container'].value};
        border-radius: 8px;
    }
`;

const Legend = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 33.75px;
    background: ${props => props.color};
`;

const StyledLayout = styled(Layout)`
    padding: 16px 28px 0 28px;
`;

const ChartsContainer = styled.div`
    display: block;
`;

const ChartContainer = styled.div`
    display: inline-block;
    width: 50%;
    padding: 10px;

    div:first-child {
        padding-bottom: 16px;
    }
`;

const PieChartToil = props => {
    const {
        defaultVacationEventName,
        totalApprovedDays,
        totalRemainingDays,
        totalToilApproved,
        totalToilRemaining,
        totalDays,
    } = props;

    return (
        <StyledCard elevation={0}>
            <Layout stack gap={16}>
                <Layout stack gap="--Spacing-50">
                    <Typography variant="title" size="medium">
                        Remaining {defaultVacationEventName} Days
                    </Typography>
                    <VacationYear />
                </Layout>
                <ChartsContainer>
                    <ChartContainer>
                        <div>
                            <DoughnutChart
                                id="double"
                                labels={['Approved Days', 'Remaining Days']}
                                colors={['#E5E2E1', '#64D2FF']}
                                height={145}
                                totalApprovedDays={totalApprovedDays}
                                totalRemainingDays={totalRemainingDays}
                                bottomText="days"
                                centerTextMaxSize={65}
                            />
                        </div>
                        <Layout gap={12} hAlign="center">
                            <Legend color="#64D2FF" />
                            <Typography variant="title" size="medium">
                                Allocated Balance
                            </Typography>
                        </Layout>
                    </ChartContainer>
                    <ChartContainer>
                        <div>
                            <DoughnutChart
                                id="double"
                                labels={['Approved Hours', 'Remaining Hours']}
                                colors={['#E5E2E1', '#81BBDC']}
                                height={145}
                                totalApprovedDays={totalToilApproved}
                                totalRemainingDays={totalToilRemaining}
                                bottomText="hours"
                                centerTextMaxSize={65}
                            />
                        </div>
                        <Layout gap={12} hAlign="center">
                            <Legend color="#81BBDC" />
                            <Typography variant="title" size="medium">
                                Toil Balance
                            </Typography>
                        </Layout>
                    </ChartContainer>
                </ChartsContainer>
                <StyledLayout hAlign="space-between">
                    <Layout vAlign="center" gap="--Spacing-100">
                        <Icon size={32} name="avgPace" />
                        <Typography variant="label" size="large">
                            Remaining Total
                        </Typography>
                    </Layout>
                    <Layout vAlign="flex-end" gap="--Spacing-100">
                        <Typography variant="display" size="medium">
                            {toFixedTrimmed(totalDays, 2)}
                        </Typography>
                        <Typography variant="body" size="small">
                            Days
                        </Typography>
                    </Layout>
                </StyledLayout>
            </Layout>
        </StyledCard>
    );
};

export default PieChartToil;
