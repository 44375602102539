import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { TimeRangePicker, calculateHours } from '../TimeRangePicker';
import { Layout } from '../layout';
import { Checkbox } from '../checkbox';
import { createCySelector } from '../../../utils/cypressUtil';
import { IconButton } from 'shared/components/iconButton';
import { MinutesToTimeFormat } from '../formats/MinutesToTimeFormat';

const MAX_NUMBER_OF_INTERVALS_A_DAY = 96;

export const calculateTotalHours = intervals => {
    let sum = 0;
    intervals?.forEach(({ start, end }) => {
        sum += calculateHours(start, end);
    });
    return sum;
};

const useStyles = makeStyles(theme => ({
    container: {
        borderBottom: `1px dashed #cfcfcf`,
        padding: '10px 0',

        '&:last-child': {
            borderBottom: 'none',
        },

        '& label': {
            marginBottom: '0',
        },
    },
    checkboxContainerSpan: {
        fontWeight: '500',
        fontSize: '12px',
    },
    action: {
        cursor: 'pointer',
        paddingTop: '10px',
    },
    intervalContainer: {
        paddingLeft: '40px',
    },
    label: {
      marginBottom: '.5rem',
    },
    subtitle: {
        color: theme.palette.grey.hintText,
    },
    checkbox: {
        label: {
            '&:first-letter': {
                textTransform: 'uppercase',
            },
        },
    },
}));

const StyledCheckBox = withStyles({
    root: {
        '&.MuiFormControlLabel-root': {
            '& .MuiTypography-body1': {
                fontSize: '13px',
                paddingRight: '10px',
            },
            '& .MuiTypography-body1:first-letter': {
                textTransform: 'uppercase',
            },
        },
    },
})(Checkbox);

export const DayInterval = React.memo(props => {
    const {
        name,
        intervals,
        workDay,
        addInterval,
        removeInterval,
        changeInterval,
        changeWorkDay,
        intervalsError,
        overlapError,
        minutes,
    } = props;
    const classes = useStyles();

    const displayOverlapError = workDay && overlapError && typeof overlapError === 'string' && !intervalsError;

    return (
        <div className={classes.container}>
            <Layout hAlign="space-between">
                <Layout hAlign="space-between" vAlign="center" className={classes.label}>
                    <StyledCheckBox
                        dataCy={`availability-${createCySelector(name)}`}
                        name={name}
                        checked={workDay}
                        onChange={(e, checked) => changeWorkDay(name, checked)}
                        label={name}
                    />
                    <div className={classes.subtitle} data-cy={`availability-label-${createCySelector(name)}`}>
                        {workDay ? <MinutesToTimeFormat minutes={minutes} /> : `Not available`}
                    </div>
                </Layout>
                {workDay && intervals.length < MAX_NUMBER_OF_INTERVALS_A_DAY && (
                    <IconButton
                        dataCy={`add-interval-${createCySelector(name)}`}
                        text="Add interval"
                        icon="addCircleOutline"
                        className={`${classes.action}`}
                        onClick={() => addInterval(name)}
                    />
                )}
            </Layout>
            {workDay && (
                <div className={classes.intervalContainer}>
                    {intervals?.map(({ start, end }, idx) => {
                        const rangeError = intervalsError && typeof intervalsError === 'object' && intervalsError[idx];

                        return (
                            <TimeRangePicker
                                key={`${idx}-${name}-${start}-${end}`}
                                idx={idx}
                                name={name}
                                start={start}
                                end={end}
                                removeInterval={index => removeInterval(name, index)}
                                addInterval={addInterval}
                                changeInterval={(index, interval) => changeInterval(name, index, interval)}
                                error={rangeError?.range}
                            />
                        );
                    })}
                </div>
            )}
            {displayOverlapError && (
                <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: overlapError }} />
            )}
        </div>
    );
});

DayInterval.propTypes = {
    name: PropTypes.string,
    workDay: PropTypes.boolean,
    intervals: PropTypes.arrayOf(
        PropTypes.shape({
            start: PropTypes.string,
            end: PropTypes.string,
        })
    ).isRequired,
    addInterval: PropTypes.func.isRequired,
    removeInterval: PropTypes.func.isRequired,
    changeInterval: PropTypes.func.isRequired,
    hoursAmount: PropTypes.bool,
    errors: PropTypes.object,
};

DayInterval.defaultProps = {
    hoursAmount: true,
};
