import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const company = {
    request: company => action(actionTypes.GET_COMPANY['REQUEST'], { company }),
    success: company => action(actionTypes.GET_COMPANY['SUCCESS'], { company }),
};

export const companyApprovers = {
    request: () => action(actionTypes.GET_COMPANY_APPROVERS['REQUEST']),
    success: approvers => action(actionTypes.GET_COMPANY_APPROVERS['SUCCESS'], { approvers }),
    failure: () => action(actionTypes.GET_COMPANY_APPROVERS['FAILURE']),
};

export const companyBillingInfo = {
    request: () => action(actionTypes.GET_COMPANY_BILLING_INFO['REQUEST']),
    success: billingInfo => action(actionTypes.GET_COMPANY_BILLING_INFO['SUCCESS'], { billingInfo }),
};

export const getCompanies = {
    request: () => action(actionTypes.GET_COMPANIES['REQUEST']),
    success: companies => action(actionTypes.GET_COMPANIES['SUCCESS'], { companies }),
};

export const updateCompanySettings = {
    request: data => action(actionTypes.UPDATE_COMPANY_SETTINGS['REQUEST'], { data }),
    success: data => action(actionTypes.UPDATE_COMPANY_SETTINGS['SUCCESS'], { data }),
};

export const updateCompany = {
    request: data => action(actionTypes.UPDATE_COMPANY['REQUEST'], { data }),
    success: data => action(actionTypes.UPDATE_COMPANY['SUCCESS'], { data }),
};

export const createHoliday = {
    request: (calendarId, data) => action(actionTypes.CREATE_HOLIDAY['REQUEST'], { calendarId, data }),
    success: calendar => action(actionTypes.CREATE_HOLIDAY['SUCCESS'], { calendar }),
};

export const updateHoliday = {
    request: (calendarId, holidayId, data) =>
        action(actionTypes.UPDATE_HOLIDAY['REQUEST'], {
            calendarId,
            holidayId,
            data,
        }),
    success: calendar => action(actionTypes.UPDATE_HOLIDAY['SUCCESS'], { calendar }),
};

export const deleteHoliday = {
    request: (calendarId, holidayId) =>
        action(actionTypes.DELETE_HOLIDAY['REQUEST'], {
            calendarId,
            holidayId,
        }),
    success: calendar => action(actionTypes.DELETE_HOLIDAY['SUCCESS'], { calendar }),
};

export const getCalendars = {
    request: () => action(actionTypes.GET_CALENDARS['REQUEST']),
    success: calendars => action(actionTypes.GET_CALENDARS['SUCCESS'], { calendars }),
};

export const createCalendar = {
    request: data => action(actionTypes.CREATE_CALENDAR['REQUEST'], data),
    success: calendars => action(actionTypes.CREATE_CALENDAR['SUCCESS'], { calendars }),
};

export const saveCalendars = {
    request: data => action(actionTypes.SAVE_CALENDARS['REQUEST'], { data }),
    success: calendars => action(actionTypes.SAVE_CALENDARS['SUCCESS'], { calendars }),
};

export const importCountryHolidays = {
    request: (countryId, calendarId, typesOfHolidays, holidaysYear) =>
        action(actionTypes.IMPORT_COUNTRY_HOLIDAY_REQUEST, {
            countryId,
            calendarId,
            typesOfHolidays,
            holidaysYear,
        }),
};

export const updateCompanyStartEndTime = company => action(actionTypes.UPDATE_COMPANY_START_END_TIME, company);
