import createReducer from 'reducers/helpers/createReducer';
import { updateObject, updateItemInArray } from 'reducers/helpers/updater';
import { values } from 'underscore';
import { max, min, map } from 'lodash';
import * as actionTypes from './../actions/actionTypes';

const initialCompanyState = {
    company: {
        _id: '',
        subdomain: '',
        settings: {
            reportCalculations: { status: {}, time: {} },
            keyword: { keyword3Plural: 'Clients', keyword3Singular: 'Client' },
            vacationEventName: 'Vacation',
            sickEventName: 'Sick',
            authentication: { mode: 'REGULAR_AND_GOOGLE_ONLY', endpointUrl: '', issuer: '', cert: '' },
            useCategoriesAllocation: false,
            weekStartDay: 0,
            vacationYearStartDate: 'January',
            report: { avgDailyCapacity: 8 },
            billingRates: [],
        },
        holidays: [],
        extensions: [],
        calendars: [],
        status: '',
        trial: {
            daysLeft: 0,
            hasTrialExpired: true,
            active: false,
            days: 0,
            startDate: new Date(),
        },
    },
    startEndTimes: {
        startDay: 0,
        endDay: 0,
        startMinute: 0,
        endMinute: 0,
    },
    approvers: [],
    approversLoading: false,
    companies: [],
    billingInfo: {
        billing: {},
        extensionLicenses: [],
    },
};

const setCompany = (state, action) => {
    const weekDays = values(action.payload.company.settings.weekDays);
    const intervals = map(weekDays, 'intervals');
    const startDay = min(intervals.flatMap(el => map(el, 'start'))) / 60;
    const endDay = max(intervals.flatMap(el => map(el, 'end'))) / 60;

    return updateObject(state, {
        company: {
            ...action.payload.company,
            trial: {
                ...action.payload.company.trial,
                startDate: new Date(action.payload.company.trial.startDate),
            },
        },
        startEndTimes: updateObject(state.startEndTimes, {
            startDay,
            endDay,
            startMinute: startDay % 1 ? 30 : 0,
            endMinute: endDay % 1 ? 30 : 0,
        }),
    });
};

const handleApproversRequest = state => {
    return updateObject(state, {
        approversLoading: true,
    });
};

const setApprovers = (state, action) =>
    updateObject(state, {
        approvers: action.payload.approvers,
        approversLoading: false,
    });

const handleApproversFailure = state => {
    return updateObject(state, {
        approversLoading: false,
    });
};

const setCompanies = (state, action) =>
    updateObject(state, {
        companies: action.payload.companies,
    });

const updateHolidays = (state, action) =>
    updateObject(state, {
        company: {
            ...state.company,
            calendars: updateItemInArray(state.company.calendars, action.payload.calendar._id, calendar =>
                updateObject(calendar, { holidays: action.payload.calendar.holidays })
            ),
        },
    });

const updateHoliday = (state, action) =>
    updateObject(state, {
        company: {
            ...state.company,
            calendars: updateItemInArray(state.company.calendars, action.payload.calendarId, calendar => ({
                ...calendar,
                holidays: updateItemInArray(calendar.holidays, action.payload.holidayId, holiday =>
                    updateObject(holiday, action.payload.data)
                ),
            })),
        },
    });

const updateCalendars = (state, action) =>
    updateObject(state, {
        company: {
            ...state.company,
            calendars: action.payload.calendars,
        },
    });

const updateBillingInfo = (state, action) =>
    updateObject(state, {
        billingInfo: action.payload.billingInfo,
    });

const updateSettings = (state, action) =>
    updateObject(state, {
        company: {
            ...state.company,
            settings: updateObject(state.company.settings, action.payload.data),
        },
    });

const addRate = (state, action) => {
    if (!state.company.settings.billingRates) {
        return state;
    }
    const currentRates = [...state.company.settings.billingRates];
    currentRates.push(action.payload.rate);

    return updateObject(state, {
        company: {
            ...state.company,
            settings: {
                ...state.company.settings,
                billingRates: currentRates,
            },
        },
    });
};

export default createReducer(initialCompanyState, {
    [actionTypes.GET_COMPANY['SUCCESS']]: setCompany,
    [actionTypes.GET_COMPANY_APPROVERS['REQUEST']]: handleApproversRequest,
    [actionTypes.GET_COMPANY_APPROVERS['SUCCESS']]: setApprovers,
    [actionTypes.GET_COMPANY_APPROVERS['FAILURE']]: handleApproversFailure,
    [actionTypes.GET_COMPANIES['SUCCESS']]: setCompanies,
    [actionTypes.DELETE_HOLIDAY['SUCCESS']]: updateHolidays,
    [actionTypes.UPDATE_HOLIDAY['SUCCESS']]: updateHolidays,
    [actionTypes.CREATE_HOLIDAY['SUCCESS']]: updateHolidays,
    [actionTypes.UPDATE_HOLIDAY['REQUEST']]: updateHoliday,
    [actionTypes.SAVE_CALENDARS['SUCCESS']]: updateCalendars,
    [actionTypes.GET_CALENDARS['SUCCESS']]: updateCalendars,
    [actionTypes.CREATE_CALENDAR['SUCCESS']]: updateCalendars,
    [actionTypes.CREATE_RATE['SUCCESS']]: addRate,
    [actionTypes.GET_COMPANY_BILLING_INFO['SUCCESS']]: updateBillingInfo,
    [actionTypes.UPDATE_COMPANY_SETTINGS['SUCCESS']]: updateSettings,
    [actionTypes.UPDATE_COMPANY_START_END_TIME]: setCompany,
});
