import React from 'react';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const DefaultLayoutMode = ({ mode, onChange }) => (
    <Section>
        <SectionHeader>Default Layout Mode</SectionHeader>
        <FormControl component="fieldset" className="ml-1 mt-2">
            <RadioGroup
                aria-label="mode"
                name="mode"
                value={mode}
                onChange={event => onChange({ mode: event.target.value })}
            >
                <FormControlLabel
                    value="LEGACY"
                    control={<GreenRadio dataCy="my-pref-view-resource-single" />}
                    className="mb-2"
                    label="Resource single mode (default)"
                />
                <FormControlLabel
                    value="PARENT"
                    control={<GreenRadio dataCy="my-pref-view-group" />}
                    className="mb-2"
                    label="Group mode"
                />
                <FormControlLabel
                    value="SINGLE"
                    control={<GreenRadio dataCy="my-pref-view-resource-project" />}
                    className="mb-2"
                    label="Project & resource single mode"
                />
            </RadioGroup>
        </FormControl>
    </Section>
);

DefaultLayoutMode.propTypes = {
    mode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DefaultLayoutMode;
