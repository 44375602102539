import { sortBy, values } from 'underscore';
import { capitalizeFirstLetter } from 'utils/formatingUtil';

export const APPROVED = { display: 'Approved', value: 'APPROVED' };
export const REJECTED = { display: 'Rejected', value: 'REJECTED' };
export const WAITING_FOR_APPROVAL = {
    display: 'Requested',
    value: 'WAITING_FOR_APPROVAL',
};
export const SCHEDULED = { display: 'Scheduled', value: 'SCHEDULED' };

export const bookingTypes = {
    APPROVED,
    REJECTED,
    WAITING_FOR_APPROVAL,
    SCHEDULED,
};

export const statusesArray = sortBy(values(bookingTypes), status => status.value);

export const getBookingType = type => (bookingTypes[type] ? bookingTypes[type].display : capitalizeFirstLetter(type));
