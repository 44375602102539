import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { keys } from 'underscore';
import { KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import SearchList from 'modules/scheduler/components/searchList/searchList';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { MenuCollapse } from '../menuCollapse';

const ProjectToggler = props => {
    const { project, keyword, projects, onChange, onToggleOpen, closeToggle } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    const onSelect = useCallback(
        project => {
            onChange(project);
            setIsOpen(prevState => !prevState);
        },
        [onChange]
    );

    return (
        <>
            <Toggler data-cy="project-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    {0 < keys(project).length && project.name}{' '}
                    <span>
                        {0 === keys(project).length &&
                            `Select ${'Event' === keyword ? 'an' : 'a'} ${keyword.toLowerCase()}`}
                    </span>
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="p-4">
                    <SearchList
                        data={projects}
                        onSelect={onSelect}
                        name="project"
                        height={200}
                        fields={{ label: 'name', value: '_id', additional: 'projectCode', wrapWithBrackets: true }}
                        placeholder={`Filter by ${keyword.toLowerCase()} Name/Code`}
                        inputSearchClass=""
                    />
                </div>
            </MenuCollapse>
        </>
    );
};

ProjectToggler.propTypes = {
    project: PropTypes.object,
    keyword: PropTypes.string,
    projects: PropTypes.array,
    onChange: PropTypes.func,
};

ProjectToggler.defaultProps = {
    project: null,
    keyword: '',
    projects: [],
    onChange: null,
};

export default ProjectToggler;
