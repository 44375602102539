import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getProjectGroupProjects = {
    request: id => action(actionTypes.GET_PROJECT_GROUP_PROJECTS['REQUEST'], { id }),
    success: projects => action(actionTypes.GET_PROJECT_GROUP_PROJECTS['SUCCESS'], { projects }),
};

export const getProjectGroups = {
    request: filters => action(actionTypes.GET_PROJECT_GROUPS['REQUEST'], { filters }),
    success: (projectGroups, lastCallContext) => action(actionTypes.GET_PROJECT_GROUPS['SUCCESS'], { projectGroups,lastCallContext }),
    stop: () => action(actionTypes.GET_PROJECT_GROUPS_STOP),
};

export const createProjectGroup = {
    request: (data, redirect = false, callback) =>
        action(actionTypes.CREATE_PROJECT_GROUP['REQUEST'], { data, redirect, callback }),
    success: projectGroup => action(actionTypes.CREATE_PROJECT_GROUP['SUCCESS'], { projectGroup }),
};

export const updateProjectGroup = {
    request: ({ id, data, callback, redirect }) =>
        action(actionTypes.UPDATE_PROJECT_GROUP['REQUEST'], { data, id, callback, redirect }),
    success: projectGroup => action(actionTypes.UPDATE_PROJECT_GROUP['SUCCESS'], { projectGroup }),
};

export const deleteProjectGroup = {
    request: (id, cb) => action(actionTypes.DELETE_PROJECT_GROUP['REQUEST'], { id, cb }),
    success: id => action(actionTypes.DELETE_PROJECT_GROUP['SUCCESS'], { id }),
};
