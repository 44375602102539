/* eslint-env browser */
import { isArray, contains, filter } from 'underscore';

class StorageUtil {
    static getItem(itemName, storage) {
        let item = window[storage || 'sessionStorage'].getItem(itemName);

        try {
            return JSON.parse(item);
        } catch (e) {
            return item;
        }
    }

    static setItem(itemName, data, storage) {
        try {
            if ('object' === typeof data) {
                data = JSON.stringify(data);
            }

            return window[storage || 'sessionStorage'].setItem(itemName, data);
        } catch (e) {
            return false;
        }
    }

    static removeItem(itemName, storage) {
        return window[storage || 'sessionStorage'].removeItem(itemName);
    }

    static searchItemBy(itemName, searchKey, searchBy) {
        let data = this.getItem(itemName);

        return filter(data, function(item) {
            if (isArray(searchBy)) {
                return contains(searchBy, item[searchKey]);
            }

            return item[searchKey] === searchBy;
        });
    }
}

export default StorageUtil;
