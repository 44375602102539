import moize from 'moize';
import { isObjectEqual } from 'utils/dataManipulation';
import {
    filter,
    forEach,
    includes,
    isArray,
    isEmpty,
    isEqual,
    isNil,
    isObject,
    keys,
    map,
    reduce,
    some,
    sortBy,
    values,
    flatten,
} from 'lodash';
import { haveArraysDiff } from 'modules/scheduler/utils/builderDataUtil';
import { ANY } from '../enums/filterRelationEnum';
import { formatDate, removeUTCZuluFromDateTimestamp, transformToDate } from '../../../utils/DateUtil';
import { endOfDay, startOfDay } from 'date-fns';
import { getISODate } from '../../../shared/lib/date';

export const ROW_FILTERS = {
    projects: 'projects',
    projectTags: 'projectTags',
    projectStatuses: 'projectStatuses',
    projectDates: 'projectDates',
    projectCustomFields: 'projectCustomFields',
    projectManagers: 'projectManagers',
    customers: 'customers',
    currencies: 'currencies',
    resources: 'resources',
    resourceTags: 'resourceTags',
    resourceStatuses: 'resourceStatuses',
    resourceDates: 'resourceDates',
    resourceRoles: 'resourceRoles',
    resourceIsPm: 'resourceIsPm',
    resourceCustomFields: 'resourceCustomFields',
};

export const SMART_FILTERS = {
    smartFilters: 'smartFilters',
};

export const SMART_FILTERS_NAMES = ['utilization', 'availability'];

export const BOOKING_FILTERS = {
    bookingProjectStatuses: 'bookingProjectStatuses',
    bookingCategories: 'bookingCategories',
    bookingStatuses: 'bookingStatuses',
    bookingProjectsEvents: 'bookingProjectsEvents',
    bookingResourcesUW: 'bookingResourcesUW',
};

export const mapDateFiltersDtoToForm = ({ group }) => {
    const start = group?.queryParams?.filters?.find(filter => filter.name === 'projectStartDate');
    const end = group?.queryParams?.filters?.find(filter => filter.name === 'projectEndDate');

    return {
        projectDates: {
            start: {
                operator: start?.values?.operator,
                date: start?.values?.date ? new Date(start?.values?.date) : undefined,
            },
            end: {
                operator: end?.values?.operator,
                date: end?.values?.date ? new Date(end?.values?.date) : undefined,
            },
        },
    };
};

export const mapCustomFieldsToFilters = (customFieldsFilters = [], customFields = []) => {
    const customFieldsSelectedMap = customFieldsFilters.reduce((acc, item) => {
        acc.set(item.value, item.set);
        return acc;
    }, new Map());

    // in this variable we will store all selected custom field choices which were removed
    // and there is no definition for it
    const remainingCustomFieldsSelectedMap = new Map(customFieldsSelectedMap);

    const mapped = customFields
        .map(customField => {
            return {
                id: customField._id,
                values: customField.choices
                    .map(choice => {
                        if (customFieldsSelectedMap.has(choice._id)) {
                            remainingCustomFieldsSelectedMap.delete(choice._id);
                        }

                        return {
                            value: choice._id,
                            set: Boolean(customFieldsSelectedMap.get(choice._id)),
                        };
                    })
                    .filter(value => customFieldsSelectedMap.get(value.value) !== undefined),
            };
        })
        .filter(customField => Boolean(customField.values.length));

    if (remainingCustomFieldsSelectedMap.size) {
        mapped.push({
            id: 'VIRTUAL_DELETED',
            deleted: true,
            values: Array.from(remainingCustomFieldsSelectedMap.keys()).map(choiceId => {
                return {
                    value: choiceId,
                    set: true,
                    deleted: true,
                };
            }),
        });
    }

    return mapped;
};

export const getQueryFiltersFromQueryParams = ({ queryParams }) => {
    const filtersConverted = (queryParams?.filters || []).reduce((computedFilters, currentValue) => {
        if (currentValue.name) {
            return {
                ...computedFilters,
                [currentValue.name]: {
                    ...(computedFilters[currentValue.name] || {}),
                    filters: currentValue.values,
                    operator: currentValue.operator,
                    ...(currentValue.dates ? { dates: currentValue.dates } : {}),
                },
            };
        }
        return computedFilters;
    }, {});

    const filtersConvertedKeys = Object.keys(filtersConverted);
    SMART_FILTERS_NAMES.forEach(smartFilterKey => {
        if (filtersConvertedKeys.includes(smartFilterKey)) {
            const value = Number.parseFloat(filtersConverted[smartFilterKey].filters.value);

            filtersConverted.smartFilters = {
                ...(filtersConverted?.smartFilters || {}),
                filters: {
                    ...(filtersConverted?.smartFilters?.filters || {}),
                    [smartFilterKey]: {
                        ...filtersConverted[smartFilterKey].filters,
                        value,
                    },
                    dates: {
                        ...filtersConverted[smartFilterKey].dates,
                        end: new Date(removeUTCZuluFromDateTimestamp(filtersConverted[smartFilterKey].dates.end)),
                        start: new Date(removeUTCZuluFromDateTimestamp(filtersConverted[smartFilterKey].dates.start)),
                    },
                },
                operator: filtersConverted[smartFilterKey].operator || 'ANY',
            };
            delete filtersConverted[smartFilterKey];
        }
    });

    const datesKeysMap = {
        projectStartDate: 'start',
        projectEndDate: 'end',
    };

    ['projectStartDate', 'projectEndDate'].forEach(projectDatesFilterKey => {
        if (filtersConvertedKeys.includes(projectDatesFilterKey)) {
            const filter = filtersConverted[projectDatesFilterKey].filters;
            filtersConverted.projectDates = {
                ...filtersConverted.projectDates,
                [datesKeysMap[projectDatesFilterKey]]: {
                    ...filter,
                    date: new Date(removeUTCZuluFromDateTimestamp(filter.date)),
                },
            };
        } else {
            filtersConverted.projectDates = {
                ...filtersConverted.projectDates,
                [datesKeysMap[projectDatesFilterKey]]: {},
            };
        }
    });

    return filtersConverted;
};

const checkIfSmartFilterExists = filter => filter && 0 <= filter.value && filter.operator;

export const formatDates = dates => ({
    ...dates,
    start: dates.start && formatDate(dates.start, 'yyyy-MM-dd', false),
    end: dates.end && formatDate(dates.end, 'yyyy-MM-dd', false),
});

const toStartOfDay = dateLike => {
    const date = transformToDate(dateLike);
    const start = startOfDay(date);
    return getISODate(start, { computeTimezone: true, includeTimezone: true });
};

const toEndOfDay = dateLike => {
    const date = transformToDate(dateLike);
    const end = endOfDay(date);
    return getISODate(end, { computeTimezone: true, includeTimezone: true });
};

const transformSmartFiltersToBackend = (smartFilters, dates) => {
    if (!smartFilters) return [];

    const { operator, filters } = smartFilters;
    const { availability, utilization } = filters;

    const sm = [];

    const newDates =
        dates?.start && dates?.end
            ? {
                  ...dates,
                  start: toStartOfDay(dates.start),
                  end: toEndOfDay(dates.end),
              }
            : dates;

    if (checkIfSmartFilterExists(availability)) {
        sm.push({
            name: 'availability',
            values: { ...availability, units: 'hours' },
            dates: newDates,
            operator: operator,
        });
    }

    if (checkIfSmartFilterExists(utilization)) {
        sm.push({
            name: 'utilization',
            values: utilization,
            dates: newDates,
            operator: operator,
        });
    }

    return sm;
};

const transformCustomFieldsFiltersToBackend = (name, customFields) => {
    const customFieldsFilters = customFields?.filters;
    const customFieldsFiltersValues =
        customFieldsFilters?.length && flatten(map(customFieldsFilters, value => value.values));

    return customFieldsFiltersValues?.length
        ? [
              {
                  name,
                  operator: customFields?.operator,
                  values: customFieldsFiltersValues,
              },
          ]
        : [];
};

export const transformFiltersToBackend = moize((filters, dates) => {
    const removedCustomFieldsFilters = filter(
        keys(filters),
        key => !includes(['projectCustomFields', 'resourceCustomFields', 'smartFilters'], key)
    );

    const filtered = reduce(
        removedCustomFieldsFilters,
        (obj, key) => {
            switch (key) {
                case 'projectManagers':
                    obj[key] = {
                        ...filters[key],
                        filters: map(filters[key].filters, item => ({
                            ...item,
                            value: item.value,
                        })),
                    };
                    break;
                case 'bookingResourcesUW':
                    obj[key] = {
                        ...filters[key],
                        filters: filters[key].filters,
                    };
                    break;
                case 'bookingProjectsEvents':
                    obj[key] = {
                        ...filters[key],
                        filters: filters[key].filters,
                    };
                    break;
                default:
                    obj[key] = filters[key];
            }
            return obj;
        },
        {}
    );

    const mainFilters = filter(
        map(filtered, (values, name) => {
            if (
                values !== undefined &&
                (('resourceIsPm' === name && values.filters !== undefined) ||
                    ('projectDates' !== name && 0 < values?.filters?.length))
            ) {
                return { name, values: values.filters, operator: values.operator };
            }
        })
    );

    const projectCustomFieldsFilters = filters.projectCustomFields?.filters;
    projectCustomFieldsFilters?.length && flatten(map(projectCustomFieldsFilters, value => value.values));

    const projectCustomFields = transformCustomFieldsFiltersToBackend(
        'projectCustomFields',
        filters.projectCustomFields
    );
    const resourceCustomFields = transformCustomFieldsFiltersToBackend(
        'resourceCustomFields',
        filters.resourceCustomFields
    );

    const smartFilters = transformSmartFiltersToBackend(
        filters.smartFilters,
        filters.smartFilters?.filters?.dates ?? dates
    );

    if (filtered.projectDates && filtered.projectDates.start?.operator && filtered.projectDates.start?.date) {
        mainFilters.push({
            name: 'projectStartDate',
            values: {
                operator: filtered.projectDates.start.operator,
                date: toStartOfDay(filtered.projectDates.start.date),
            },
        });
    }
    if (filtered.projectDates && filtered.projectDates.end?.operator && filtered.projectDates.end?.date) {
        mainFilters.push({
            name: 'projectEndDate',
            values: {
                operator: filtered.projectDates.end.operator,
                date: toStartOfDay(filtered.projectDates.end.date),
            },
        });
    }

    return [...mainFilters, ...projectCustomFields, ...resourceCustomFields, ...smartFilters];
});

// Should return structure like this
// {
//     resources: {
//         filters: [];
//         operator: ANY;
//     }
// }
export const transformFilters = (filters, customFields) => {
    const smartFilterMapper = (smartFilters, filter) => {
        const { values, dates, operator, name } = filter;
        return {
            ...smartFilters,
            filters: {
                ...(smartFilters?.filters || {}),
                [name]: {
                    operator: values?.operator || '',
                    value: values?.value,
                },
                dates,
            },
            operator: operator,
        };
    };

    const customFieldMapper = filter => {
        const mappedCustomFields = [];
        forEach(filter.values, val => {
            const customField = customFields?.find(customField =>
                some(customField.choices, choice => choice._id === val.value)
            );

            const mappedCustomFieldIndex = mappedCustomFields.findIndex(
                mappedCustomField => mappedCustomField.id === customField._id
            );
            if (customField && -1 === mappedCustomFieldIndex) {
                mappedCustomFields.push({
                    id: customField._id,
                    values: [val],
                });
            } else if (customField) {
                mappedCustomFields[mappedCustomFieldIndex].values.push(val);
            }
        });

        return mappedCustomFields;
    };

    const fieldWithAllAnyOperatorMapper = (obj, filter) => ({
        filters: filter.values,
        operator: filter.operator || ANY.value,
    });

    const mapper = {
        projectStartDate: (obj, filter) => {
            obj.projectDates = {
                ...(obj.projectDates || {}),
                start: {
                    ...filter.values,
                    date: new Date(filter.values.date),
                },
                end: {
                    operator: '',
                    date: null,
                    ...(obj.projectDates || {}).end,
                },
            };
        },
        projectEndDate: (obj, filter) => {
            obj.projectDates = {
                ...(obj.projectDates || {}),
                end: {
                    ...filter.values,
                    date: new Date(filter.values.date),
                },
                start: {
                    operator: '',
                    date: null,
                    ...(obj.projectDates || {}).start,
                },
            };
        },
        projectCustomFields: (obj, filter) => {
            obj.projectCustomFields = {
                filters: [...(obj.projectCustomFields || []), ...customFieldMapper(filter)],
                operator: filter.operator,
            };
        },
        resourceCustomFields: (obj, filter) => {
            obj.resourceCustomFields = {
                filters: [...(obj.resourceCustomFields || []), ...customFieldMapper(filter)],
                operator: filter.operator,
            };
        },
        utilization: (obj, filter) => (obj.smartFilters = smartFilterMapper(obj.smartFilters, filter)),
        availability: (obj, filter) => (obj.smartFilters = smartFilterMapper(obj.smartFilters, filter)),
        projectTags: (obj, filter) => (obj.projectTags = fieldWithAllAnyOperatorMapper(obj.projectTags, filter)),
        resourceTags: (obj, filter) => (obj.resourceTags = fieldWithAllAnyOperatorMapper(obj.resourceTags, filter)),
        customers: (obj, filter) => (obj.customers = fieldWithAllAnyOperatorMapper(obj.customers, filter)),
        projectManagers: (obj, filter) => {
            const pms = fieldWithAllAnyOperatorMapper(obj.projectManagers, filter);
            obj.projectManagers = {
                ...pms,
            };
        },
        resourceStatuses: (obj, filter) =>
            (obj.resourceStatuses = fieldWithAllAnyOperatorMapper(obj.resourceStatuses, filter)),
        resourceRoles: (obj, filter) => (obj.resourceRoles = fieldWithAllAnyOperatorMapper(obj.resourceRoles, filter)),
        currencies: (obj, filter) => (obj.currencies = fieldWithAllAnyOperatorMapper(obj.currencies, filter)),
        projectStatuses: (obj, filter) =>
            (obj.projectStatuses = fieldWithAllAnyOperatorMapper(obj.projectStatuses, filter)),
    };

    return reduce(
        filters,
        (obj, filter) => {
            if (mapper.hasOwnProperty(filter.name)) {
                mapper[filter.name](obj, filter);
            } else {
                obj[filter.name] = {
                    filters: filter.values,
                    operator: filter.operator,
                };
            }

            return obj;
        },
        {}
    );
};

const isFilterNotEmpty = filter => !isNil(filter) && !isEmpty(filter);

export const hasAnyFiltersActive = currentFilters =>
    some(
        values(currentFilters),
        filter =>
            (isArray(filter) && 0 < filter.length) ||
            (isObject(filter) &&
                (isFilterNotEmpty(filter.start) ||
                    isFilterNotEmpty(filter.end) ||
                    (filter.operator && isFilterNotEmpty(filter.value))))
    );

const arrayContainBooleansSet = array =>
    array[0] && 'string' === typeof array[0].value && 'boolean' === typeof array[0].set;

const getBooleansSetHaveDiff = (array, initialArray) =>
    JSON.stringify(sortBy(array, 'value')) !== JSON.stringify(sortBy(initialArray, 'value'));

const getProjectDatesHaveDiff = (projectDate, initProjectDate) =>
    (projectDate.start &&
        projectDate.start.operator &&
        projectDate.start.date &&
        !isEqual(projectDate.start, initProjectDate.start)) ||
    (projectDate.end &&
        projectDate.end.operator &&
        projectDate.end.date &&
        !isEqual(projectDate.end, initProjectDate.end));

// check if boolean array has changes: array [ {value: string, set: boolean } ]
const getCustomFieldsHaveDiff = (cfFilters, initialCfFilters) => {
    // compare selected cf groups if length is the same
    const cfContainsDifferentFields = haveArraysDiff(
        cfFilters.map(cf => cf.id),
        initialCfFilters.map(cf => cf.id)
    );

    if (cfContainsDifferentFields) return true;

    let changed = false;

    // cf has same lengths and keys -> compare sorted value arrays as jsons
    cfFilters.forEach(cf => {
        if (changed) return;

        const initCf = initialCfFilters.find(icf => icf.id === cf.id);

        changed = getBooleansSetHaveDiff(cf.values, initCf.values);
    });

    return changed;
};

export const hasSmartFiltersChanged = (filters, toCompare) => {
    const { dates: currentDates, ...currentWithoutDates } = filters.smartFilters?.filters || {};
    const { dates: datesToCompare, ...toCompareWithoutDates } = toCompare.smartFilters?.filters || {};

    const haveValuesChanged = getFiltersHasChanged(
        {
            smartFilters: {
                filters: {
                    ...currentWithoutDates,
                },
            },
        },
        {
            smartFilters: {
                filters: {
                    ...toCompareWithoutDates,
                },
            },
        },
        SMART_FILTERS
    );

    if (haveValuesChanged) {
        return true;
    }

    const hasDatesChanged = !isEqual(currentDates, datesToCompare);
    const hasAnySmartFilterValue =
        currentWithoutDates.availability?.operator || currentWithoutDates.utilization?.operator;

    // if any of avail./util. filters checked we need to compare if dates changed
    // if not checked then we have nothing to apply/save
    return hasAnySmartFilterValue && hasDatesChanged;
};

export const getFiltersHasChanged = (filters, initialFilters, filterGroupToCheck) => {
    if (!isObject(initialFilters) || !isObject(filters)) return false;

    const res = Object.entries(filters)
        .filter(([filterName]) => (filterGroupToCheck ? filterGroupToCheck[filterName] : true))
        .some(([filterName, filter]) => {
            const initialFilter = initialFilters[filterName];

            if ('projectDates' === filterName) {
                if (!isObject(filter) || !isObject(initialFilter)) {
                    return false;
                }

                return getProjectDatesHaveDiff(filter, initialFilter);
            }

            if (isArray(filter?.filters)) {
                if (initialFilter?.operator !== filter?.operator && filter?.filters.length) {
                    return true;
                }

                if ('resourceCustomFields' === filterName || 'projectCustomFields' === filterName) {
                    return getCustomFieldsHaveDiff(filter.filters, initialFilter.filters);
                }

                if (!isArray(initialFilter?.filters)) {
                    return false;
                }

                if (arrayContainBooleansSet(filter.filters) || arrayContainBooleansSet(initialFilter.filters)) {
                    return getBooleansSetHaveDiff(filter.filters, initialFilter.filters);
                }

                return haveArraysDiff(filter.filters, initialFilter.filters);
            }

            if (isObject(filter)) {
                if (initialFilter?.operator !== filter?.operator) {
                    return true;
                }

                return !isEqual(filter, initialFilter);
            }

            return filter !== initialFilter;
        });

    return res;
};

const hasFilterRelationChanged = state => {
    const { initialFilterRelation, filterRelation } = state;
    return initialFilterRelation !== filterRelation;
};

const haveDatesChanged = state => {
    const { initialDates, dates } = state;
    return !isObjectEqual(initialDates, dates);
};

export const haveBookingFiltersChanged = state => {
    const { filters, initialFilters } = state;
    return getFiltersHasChanged(filters, initialFilters, BOOKING_FILTERS);
};

export const haveSmartFiltersChanged = state => {
    const { filters, initialFilters } = state;
    return getFiltersHasChanged(filters, initialFilters, SMART_FILTERS) || haveDatesChanged(state);
};

export const haveRowFiltersChanged = state => {
    const { initialFilters, filters } = state;
    return getFiltersHasChanged(filters, initialFilters, ROW_FILTERS) || hasFilterRelationChanged(state);
};

export const haveFiltersFiltersRelationOrDatesChanged = state => {
    const { initialFilters, filters } = state;

    return getFiltersHasChanged(filters, initialFilters) || hasFilterRelationChanged(state) || haveDatesChanged(state);
};

const copyFiltersWithNewCustomFieldsReference = filters => {
    // bugfix: create new reference of each custom field to keep initial state
    const resourceCustomFields = filters['resourceCustomFields'];
    const projectCustomFields = filters['projectCustomFields'];
    return {
        ...filters,
        resourceCustomFields: {
            ...resourceCustomFields,
            filters:
                resourceCustomFields.filters && resourceCustomFields.filters.length
                    ? resourceCustomFields.filters.map(cf => ({ ...cf }))
                    : [],
        },
        projectCustomFields: {
            ...projectCustomFields,
            filters:
                projectCustomFields.filters && projectCustomFields.filters.length
                    ? projectCustomFields.filters.map(cf => ({ ...cf }))
                    : [],
        },
    };
};

export const getInitialFilters = (state, filters) => {
    if (!state.initialFilters) {
        return copyFiltersWithNewCustomFieldsReference(filters);
    } else {
        return state.initialFilters;
    }
};

const smartFilterValid = smartFilter => smartFilter?.operator && 0 <= smartFilter?.value && '' !== smartFilter?.value;

// value compared to '' because 0 is allowed
const smartFilterValidOrEmpty = smartFilter =>
    smartFilterValid(smartFilter) || (!smartFilter?.operator && '' === smartFilter?.value);

export const isDataValid = state => {
    const { filters, dates, initialDates } = state;

    if (filters.smartFilters && filters.smartFilters.filters) {
        const { utilization, availability } = filters.smartFilters.filters;

        if (
            (utilization && !smartFilterValidOrEmpty(utilization)) ||
            (availability && !smartFilterValidOrEmpty(availability))
        )
            return false;

        if (
            initialDates &&
            dates.start &&
            dates.end &&
            (dates.start !== initialDates.start || dates.end !== initialDates.end)
        ) {
            return smartFilterValid(utilization) || smartFilterValid(availability);
        }
    }

    return !!(dates.start && dates.end);
};
