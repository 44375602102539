import React from 'react';
import { SectionHeader, Section, GreenRadio } from './styles';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import ColorPicker from '../../../../../../../shared/colorPicker';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { selectIsDeadlinesExtensionInstalled } from '../../../../../../../selectors/company';
import {
    deadlineDisplayEnum,
    deadlineLinkPositionEnum,
    deadlineLinkStylesEnum,
    deadlineTooltipDisplayEnum,
    defaultDeadlineColor,
    defaultDeadlineDisplay,
    defaultDeadlineLineColor,
    defaultDeadlineLinkPosition,
    defaultDeadlineLinkStyle,
    defaultDeadlineTooltipDisplay,
} from '../../../../../enums/deadlines';
import { Layout } from '../../../../../../../shared/components/layout';
import classnames from 'classnames';

export const DeadlinesConfig = React.memo(props => {
    const {
        onChange,
        style = defaultDeadlineLinkStyle,
        position = defaultDeadlineLinkPosition,
        linkColor = defaultDeadlineLineColor,
        deadlineColor = defaultDeadlineColor,
        display = defaultDeadlineDisplay,
        tooltipDisplay = defaultDeadlineTooltipDisplay,
    } = props;

    const classes = useStyles();

    const isExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);

    if (!isExtensionInstalled) {
        return null;
    }

    return (
        <Section>
            <SectionHeader>Deadline options</SectionHeader>
            <SectionHeader>Style</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="deadline-link-style"
                    name="deadlineLinkStyle"
                    value={style}
                    onChange={event => onChange({ deadlineLinkStyle: event.target.value })}
                >
                    <FormControlLabel
                        value={deadlineLinkStylesEnum.line}
                        control={<GreenRadio dataCy="my-pref-deadline-link-style-line" />}
                        className="mb-2"
                        label="Line"
                    />
                    <FormControlLabel
                        value={deadlineLinkStylesEnum.dashed}
                        control={<GreenRadio dataCy="my-pref-deadline-link-style-dashed" />}
                        className="mb-2"
                        label="Dashed"
                    />
                    <FormControlLabel
                        value={deadlineLinkStylesEnum.dotted}
                        control={<GreenRadio dataCy="my-pref-deadline-link-style-dotted" />}
                        className="mb-2"
                        label="Dotted"
                    />
                </RadioGroup>
            </FormControl>
            <div>
                <FormControl component="fieldset" className={classnames('mb-2', classes.colorsFormControl)}>
                    <Layout stretch>
                        <Layout stack flex="1 1 50%">
                            <SectionHeader className={classnames(classes.sectionHeader, 'mt-1')}>Line Color</SectionHeader>
                            <ColorPicker
                                className={classes.colorPicker}
                                color={linkColor}
                                onColorChange={color => onChange({ deadlineLinkColor: color.hex })}
                            />
                        </Layout>
                        <Layout stack flex="1 1 50%">
                            <SectionHeader className={classnames(classes.sectionHeader, 'mt-1')}>Deadline Color</SectionHeader>
                            <ColorPicker
                                className={classes.colorPicker}
                                color={deadlineColor}
                                onColorChange={color => onChange({ deadlineColor: color.hex })}
                            />
                        </Layout>
                    </Layout>
                </FormControl>
            </div>
            <SectionHeader className={classes.sectionHeader}>Position</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="deadline-link-position"
                    name="deadlineLinkPosition"
                    value={position}
                    onChange={event => onChange({ deadlineLinkPosition: event.target.value })}
                >
                    <FormControlLabel
                        value={deadlineLinkPositionEnum.aboveBookings}
                        control={<GreenRadio dataCy="my-pref-deadline-link-position-above" />}
                        className="mb-2"
                        label="Show line above bookings"
                    />
                    <FormControlLabel
                        value={deadlineLinkPositionEnum.belowBookings}
                        control={<GreenRadio dataCy="my-pref-deadline-link-position-below" />}
                        className="mb-2"
                        label="Show line below bookings"
                    />
                </RadioGroup>
            </FormControl>
            <SectionHeader className={classes.sectionHeader}>Show / Hide Deadlines</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="booking-deadline-display"
                    name="deadlineDisplay"
                    value={display}
                    onChange={event => onChange({ deadlineDisplay: event.target.value })}
                >
                    <FormControlLabel
                        value={deadlineDisplayEnum.show}
                        control={<GreenRadio dataCy="my-pref-deadline-display-show" />}
                        className="mb-2"
                        label="Show deadlines"
                    />
                    <FormControlLabel
                        value={deadlineDisplayEnum.hide}
                        control={<GreenRadio dataCy="my-pref-deadline-display-hide" />}
                        className="mb-2"
                        label="Hide deadlines"
                    />
                    <FormControlLabel
                        value={deadlineDisplayEnum.showForSelectedBooking}
                        control={<GreenRadio dataCy="my-pref-deadline-display-show-for-selected-booking" />}
                        className="mb-2"
                        label="Show deadlines on clicking of booking"
                    />
                </RadioGroup>
            </FormControl>
            <SectionHeader className={classes.sectionHeader}>Show / Hide Deadline Tooltip</SectionHeader>
            <FormControl component="fieldset" className="ml-1 mt-2">
                <RadioGroup
                    aria-label="booking-deadline-tooltip-display"
                    name="deadlineTooltipDisplay"
                    value={tooltipDisplay}
                    onChange={event => onChange({ deadlineTooltipDisplay: event.target.value })}
                >
                    <FormControlLabel
                        value={deadlineTooltipDisplayEnum.hide}
                        control={<GreenRadio dataCy="my-pref-deadline-tooltip-display-hide" />}
                        className="mb-2"
                        label="Hide"
                    />
                    <FormControlLabel
                        value={deadlineTooltipDisplayEnum.showDeadLineDate}
                        control={<GreenRadio dataCy="my-pref-deadline-tooltip-display-show-date" />}
                        className="mb-2"
                        label="Show Date"
                    />
                    <FormControlLabel
                        value={deadlineTooltipDisplayEnum.showDeadlineName}
                        control={<GreenRadio dataCy="my-pref-deadline-tooltip-display-show-name" />}
                        className="mb-2"
                        label="Show deadline title"
                    />
                </RadioGroup>
            </FormControl>
        </Section>
    );
});

const useStyles = makeStyles(() => ({
    colorPicker: {
        display: 'flex',
        position: 'relative',
    },
    sectionHeader: {
        marginTop: '8px',
    },
    colorsFormControl: {
        width: '100%',
    },
    colorPickerWrapper: {
        marginBottom: '8px',
    },
}));
