import { availabilityHandler } from 'modules/scheduler/utils/schedulerUtil';

export default viewObject => args => {
    const { availabilityHover, availabilityDisplay } = args.cell.calendar;
    const shouldRemoveAvailability = availabilityHandler(args, { availabilityHover, availabilityDisplay }, viewObject);
    if (shouldRemoveAvailability && args.cell.div) {
        const utilisationHolder = args.cell.div.querySelector('.utilisation-holder .utilisation-holder-partial');
        if (utilisationHolder) {
            utilisationHolder.remove();
        }
    }
};
