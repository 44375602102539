import { forEach, last, without, every, isString } from 'lodash';

export const errorMessages = {
    notDefinedKey(key) {
        return `Key '${key}' is not defined in schema`;
    },
    modelIsUndefined() {
        return 'Validated model is undefined';
    },
    validateRequired() {
        return `This field is required`;
    },
    validateString() {
        return 'This field has incorrect value';
    },
    validateNumber() {
        return 'This field has incorrect value';
    },
    validateObject() {
        return 'This field has incorrect value';
    },
    validateArray() {
        return 'This field has incorrect value';
    },
    validateBoolean() {
        return 'This field has incorrect value';
    },
    validateDate() {
        return 'This field has incorrect value';
    },
};

/**
 * @param {*} formSchema
 * @returns
 */
export const validateSchema = formSchema => (values, props) => {
    if ('object' !== typeof values) {
        return {};
    }
    const mapErrors = (errors, mappedErrors = {}) => {
        forEach(errors, (error, key) => {
            if (every(error, errorItem => isString(errorItem))) {
                const lastError = last(without(error, '')); // last, because required validation (that should be prior) on last place in error array
                if (lastError) {
                    mappedErrors[key] = lastError;
                }
            } else {
                mappedErrors[key] = {};
                mappedErrors[key] = mapErrors(error, mappedErrors[key]);
            }
        });

        return mappedErrors;
    };

    return mapErrors(formSchema.validate(values, props));
};
