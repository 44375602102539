export const STATUS_TRIAL = { display: 'Active', value: 'STATUS_TRIAL' };
export const STATUS_CLOSED = { display: 'Planned', value: 'STATUS_CLOSED' };
export const STATUS_SUBSCRIBER = {
    display: 'Pending',
    value: 'STATUS_SUBSCRIBER',
};
export const STATUS_DISABLED = {
    display: 'Floating',
    value: 'STATUS_DISABLED',
};
export const STATUS_FROZEN = { display: 'Archived', value: 'STATUS_FROZEN' };
export const STATUS_HIDDEN = { display: 'Archived', value: 'STATUS_HIDDEN' };
export const STATUS_UNKNOWN = { display: 'Unknown', value: 'STATUS_UNKNOWN' };
