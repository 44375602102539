import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteValidator from '../../services/routeValidator/validator';
import { EventModalRouteAuth, ProjectModalRouteAuth } from './modalRoutes/projectRoute';
import { ScheduleModalRouteAuth } from './modalRoutes/scheduleRoute';
import { RequestResourceRouteAuth } from './modalRoutes/requestResourceRoute';
import { RequestVacationRouteAuth } from './modalRoutes/requestVacationRoute';
import { ResourceModalRouteAuth } from './modalRoutes/resourceRoute';

const ModalsLayout = props => {
    return (
        <Route
            {...props}
            render={() => {
                return (
                    props.child && (
                        <Switch>
                            {props.child.map(route => (
                                <RouteValidator key={route.path} {...route} />
                            ))}
                        </Switch>
                    )
                );
            }}
        />
    );
};

export default [
    {
        path: '/modal',
        title: 'HUB Planner | Modals',
        layout: ModalsLayout,
        child: [
            {
                path: '/modal/schedule',
                title: 'HUB Planner | Schedule',
                component: ScheduleModalRouteAuth,
            },
            {
                path: '/modal/request/resource',
                title: 'HUB Planner | Request Resource',
                component: RequestResourceRouteAuth,
            },
            {
                path: '/modal/request/vacation',
                title: 'HUB Planner | Request Vacation',
                component: RequestVacationRouteAuth,
            },
            {
                path: '/modal/resource/new',
                title: 'HUB Planner | New resource',
                component: ResourceModalRouteAuth,
            },
            {
                path: '/modal/resource/edit/:resourceId/:type',
                title: 'HUB Planner | New resource',
                component: ResourceModalRouteAuth,
            },
            {
                path: '/modal/project/new',
                title: 'HUB Planner | New resource',
                component: ProjectModalRouteAuth,
            },
            {
                path: '/modal/event/new',
                title: 'HUB Planner | New resource',
                component: EventModalRouteAuth,
            },
            {
                path: '/modal/project/edit/:projectId/:type',
                title: 'HUB Planner | New resource',
                component: ProjectModalRouteAuth,
            },
        ],
    },
];
