import { Tooltip } from '@material-ui/core';
import React, { memo } from 'react';

export const WrapWithTooltip = memo(props => {
    const { tooltip, children, arrow = true } = props;

    if (!tooltip) {
        return children;
    }

    return (
        <Tooltip title={tooltip} placement="top" arrow={arrow}>
            <div>{children}</div>
        </Tooltip>
    );
});
