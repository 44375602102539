import { schedulerFiltersArray, isConditionFilterSupported } from 'modules/scheduler/enums/schedulerFilterEnum';
import { find, map, isFunction, mapValues } from 'lodash';
import { capitalizeFirstLetter } from 'utils/formatingUtil';
import { OPERATORS } from 'enums/operatorEnum';
import { formatDate } from '../../../../../utils/DateUtil';

const smartFiltersUnits = {
    availability: 'hrs',
    utilization: '%',
};

const getOperatorString = operator => {
    const operatorFound = OPERATORS.find(OP => {
        return OP.value === operator;
    });

    if (operatorFound) {
        return operatorFound.name;
    }

    return operator;
};

export const mapResourceIsPmToTag = appliedFilters => {
    const { resourceIsPm } = appliedFilters;

    if (resourceIsPm?.filters !== undefined) {
        return [
            {
                value: `resource${resourceIsPm.filters ? '-is-' : '-is-not-'}pm`,
                name: `resourceIsPm`,
            },
        ];
    }

    return [];
};

export const mapSmartFiltersToTags = appliedFilters => {
    const tags = [];

    const smartFilters = appliedFilters?.smartFilters?.filters || [];
    const projectDates = appliedFilters?.projectDates || {};

    if (smartFilters) {
        Object.keys(smartFilters).forEach(smartFilterKey => {
            const { operator, value } = smartFilters[smartFilterKey];
            if (operator != null && operator !== '' && value != null && value !== '') {
                tags.push({
                    value: `${smartFilterKey}-${operator}-${value}`,
                    label: `${capitalizeFirstLetter(smartFilterKey)}: ${getOperatorString(operator)} ${value} ${
                        smartFiltersUnits[smartFilterKey]
                    }`,
                    name: `${smartFilterKey}`,
                });
            }
        });
    }

    if (projectDates) {
        Object.keys(projectDates).forEach(projectDateKey => {
            const { operator, date } = projectDates[projectDateKey];
            if (operator && date) {
                const formatted = formatDate(date, 'yyyy-MM-dd', false);

                tags.push({
                    value: `project-${projectDateKey}-date`,
                    label: `Project ${projectDateKey} date: ${getOperatorString(operator)} ${formatted}`,
                    name: `project-${projectDateKey}-date`,
                });
            }
        });
    }
    return tags;
};

export const mapDataToTags = (appliedFilters, filtersList) => {
    const tags = [];

    mapValues(appliedFilters, (appliedFilterValues, appliedFilterKey) => {
        if (!appliedFilterValues || !appliedFilterValues?.filters?.length) {
            return;
        }
        const schedulerFilter = find(
            schedulerFiltersArray,
            _schedulerFilter => appliedFilterKey === _schedulerFilter.filterListProp
        );
        const valuesList = filtersList[schedulerFilter.filterListProp];

        if (schedulerFilter.nestedItemValue) {
            map(appliedFilterValues.filters, appliedFilterValue => {
                const item = find(valuesList, value => appliedFilterValue.id === value[schedulerFilter.itemValue]);

                if (item) {
                    map(appliedFilterValue.values, _nestedValue => {
                        const nestedItem = find(
                            item[schedulerFilter.nestedItems],
                            nestedValue => _nestedValue.value === nestedValue[schedulerFilter.nestedItemValue]
                        );

                        tags.push({
                            value: nestedItem[schedulerFilter.nestedItemValue],
                            parentValue: item[schedulerFilter.itemValue],
                            label: nestedItem[schedulerFilter.nestedItemLabel],
                            name: schedulerFilter.filterListProp,
                        });
                    });
                }
            });
        } else {
            map(appliedFilterValues.filters, appliedFilterValue => {
                const item = find(valuesList, value => {
                    const temp = isConditionFilterSupported(appliedFilterKey)
                        ? appliedFilterValue.value
                        : appliedFilterValue;
                    return (
                        temp
                            ?.replace('_pm', '')
                            .replace('_bpe', '')
                            .replace('_bru', '')
                            .replace('_bps', '')
                            .replace('_ps', '')
                        === value[schedulerFilter.itemValue]
                    );
                });

                if (item) {
                    let valueProp = item[schedulerFilter.itemValue];
                    if (schedulerFilter.value === 'PROJECT_MANAGERS') {
                        valueProp = valueProp + '_pm';
                    } else if (schedulerFilter.value === 'BOOKING_PROJECTS_EVENTS') {
                        valueProp = valueProp + '_bpe';
                    } else if (schedulerFilter.value === 'BOOKING_RESOURCES_UW') {
                        valueProp = valueProp + '_bru';
                    } else if (schedulerFilter.value === 'BOOKING_PROJECT_STATUSES') {
                        valueProp = valueProp + '_bps';
                    } else if (schedulerFilter.value === 'PROJECT_STATUSES') {
                        valueProp = valueProp + '_ps';
                    }

                    tags.push({
                        value: valueProp,
                        label: isFunction(schedulerFilter.itemLabel)
                            ? schedulerFilter.itemLabel(item)
                            : item[schedulerFilter.itemLabel],
                        name: schedulerFilter.filterListProp,
                    });
                }
            });
        }
    });

    return tags;
};
