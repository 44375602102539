import { axiosInstance } from './axiosInstance';

const getAllPolicies = () => {
    return axiosInstance.get('toil/policy/all').then(response => response.data);
};

const createPolicy = ({ policy }) => {
    return axiosInstance.post('toil/policy', policy).then(response => response.data);
};

const updatePolicy = ({ policy }) => {
    return axiosInstance.put(`toil/policy/${policy._id}`, policy).then(response => response.data);
};

const deletePolicy = ({ policyId }) => {
    return axiosInstance.delete(`toil/policy/${policyId}`).then(response => response.data);
};

const getAllAdjustmentsByResourceId = ({ resourceId, year }) => {
    return axiosInstance
        .get(`toil/adjustment/all/resource/${resourceId}`, {
            params: {
                year,
            },
        })
        .then(response => response.data);
};

const createAdjustment = ({ adjustment }) => {
    return axiosInstance.post('toil/adjustment', adjustment).then(response => response.data);
};

const updateAdjustment = ({ adjustment }) => {
    return axiosInstance.put(`toil/adjustment/${adjustment._id}`, adjustment).then(response => response.data);
};

const deleteAdjustment = ({ adjustmentId }) => {
    return axiosInstance.delete(`toil/adjustment/${adjustmentId}`).then(response => response.data);
};

const updateResourcePolicies = ({ data }) => {
    return axiosInstance.put(`toil/policies/resources`, data).then(response => response.data);
};

export const getEventStatsCheckToil = (params) => {
    return axiosInstance
        .post(`event/stats/checkToil`, params)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const toilApi = {
    getAllPolicies,
    createPolicy,
    updatePolicy,
    deletePolicy,
    getAllAdjustmentsByResourceId,
    createAdjustment,
    updateAdjustment,
    deleteAdjustment,
    updateResourcePolicies,
    getEventStatsCheckToil,
};
