import { formatDate } from 'utils/DateUtil';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';

export default args => {
    const textBottom = args.e.cache?.tags?.renderedTextBottom ? ' - ' + args.e.cache.tags.renderedTextBottom : '';
    if (args.e.data.phase) {
        const startDate =
            typeof args.e.data.start === 'string' ? new Date(args.e.data.start) : args.e.data.start.toDate();
        const endDate = typeof args.e.data.start === 'string' ? new Date(args.e.data.end) : args.e.data.end.toDate();

        args.text =
            args.e.data.name +
            ' \n ' +
            formatDate(startDate, 'MMM do yyyy') +
            ' - ' +
            formatDate(endDate, 'MMM do yyyy');
    } else if (args.e.data.milestone) {
        args.backColor = args.e.data.backColor;
        args.barHeight = 10;
    } else if (args.e.data.datesEvent) {
        args.horizontalAlignment = 'center';
        args.text = 'D';
    } else if (WAITING_FOR_APPROVAL.value === args.e.data.type) {
        args.text = '(requested) ' + args.e.data.text + textBottom;
    } else {
        args.text = args.e.data.text + textBottom;
    }

    args.fontSize = '14px';
    args.fontColor = '#fff';
    args.borderColor = '#fff';
    args.textPadding = 0;
};
