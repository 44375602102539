import { extend } from 'underscore';
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from './helpers/updater';
import createReducer from './helpers/createReducer';
import { DEFAULT, CUSTOM } from 'enums/categoryGroupTypeEnum';

const initialCategoryGroupState = {
    categoryGroups: [],
    bookingCategoriesByGroup: [],
    loading: false,
};

const handleGetCategoryGroups = (state) => {
    return updateObject(state, {loading: true})
}

const handleCategoryGroupsStopLoading = (state) => {
    return updateObject(state, {loading: false})
}

const updateCategoryGroups = (state, action) => {
    return updateObject(state, {
        categoryGroups: action.payload.categoryGroups,
        loading: false,
    });
}

const updateBookingCategoriesByGroup = (state, action) =>
    updateObject(state, {
        bookingCategoriesByGroup: action.payload.categories,
    });

const onSaveCategoryGroupsRequest = state =>
    updateObject(state, {
        loading: true,
    });

const onSaveCategoryGroupsFailure = state =>
    updateObject(state, {
        loading: false,
    });

const onCreateCategoryTemplate = (state, action) =>
    updateObject(state, {
        bookingCategoriesByGroup: [...state.bookingCategoriesByGroup, action.payload.categoryTemplate],
    });

const onDeleteCategoryTemplate = (state, action) =>
    updateObject(state, {
        bookingCategoriesByGroup: state.bookingCategoriesByGroup.filter(
            category => category._id !== action.payload.categoryTemplate._id
        ),
    });

const onUpdateCategoryTemplate = (state, action) => {
    const updatedCategoryTemplate = action.payload.categoryTemplate;

    return updateObject(state, {
        bookingCategoriesByGroup: state.bookingCategoriesByGroup.map(item => {
            if (
                updatedCategoryTemplate.type === DEFAULT &&
                item.type === DEFAULT &&
                item._id !== updatedCategoryTemplate._id
            )
                return { ...item, type: CUSTOM };
            else if (item._id === updatedCategoryTemplate._id) return updatedCategoryTemplate;
            else return item;
        }),
    });
};

export default createReducer(extend({}, initialCategoryGroupState), {
    [actionTypes.GET_CATEGORY_GROUPS['REQUEST']]: handleGetCategoryGroups,
    [actionTypes.GET_CATEGORY_GROUPS['SUCCESS']]: updateCategoryGroups,
    [actionTypes.GET_CATEGORY_GROUPS['FAILURE']]: handleCategoryGroupsStopLoading,
    [actionTypes.GET_CATEGORY_GROUPS_STOP]: handleCategoryGroupsStopLoading,
    [actionTypes.GET_CATEGORIES_BY_GROUP['SUCCESS']]: updateBookingCategoriesByGroup,
    [actionTypes.SAVE_CATEGORY_GROUPS['SUCCESS']]: updateCategoryGroups,
    [actionTypes.SAVE_CATEGORY_GROUPS['REQUEST']]: onSaveCategoryGroupsRequest,
    [actionTypes.SAVE_CATEGORY_GROUPS['FAILURE']]: onSaveCategoryGroupsFailure,
    [actionTypes.CREATE_CATEGORY_TEMPLATE['SUCCESS']]: onCreateCategoryTemplate,
    [actionTypes.DELETE_CATEGORY_TEMPLATE['SUCCESS']]: onDeleteCategoryTemplate,
    [actionTypes.UPDATE_CATEGORY_TEMPLATE['SUCCESS']]: onUpdateCategoryTemplate,
});
