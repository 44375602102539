const checkAndAddDataCy = (id, dataCy, retry) => {
    const elements = document.getElementsByClassName(id);

    if (elements[0]) {
        elements[0].dataset.cy = dataCy;
    } else if (retry <= 10) {
        setTimeout(() => checkAndAddDataCy(id, dataCy, retry + 1), 10);
    }
};

export default (showColumnsContextMenu, schedulerRef) => args => {
    const column = args.column;
    const caretId = 'scheduler-columns-context-menu-button';
    args.column.areas = [
        {
            width: 20,
            height: 20,
            right: 0,
            top: 5,
            action: 'ContextMenu',
            visibility: 'Visible',
            icon: 'fa fa-caret-down',
            cssClass: 'cursor-p ' + caretId,
            onClick: args => {
                showColumnsContextMenu(
                    {
                        left: args.originalEvent.clientX,
                        top: args.originalEvent.clientY,
                    },
                    column,
                    {
                        current: {
                            control: {
                                update: schedulerRef.current.control.update,
                            },
                        },
                    }
                );
            },
        },
    ];

    checkAndAddDataCy(caretId, caretId, 1);
};
