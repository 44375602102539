import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dehaze } from '@material-ui/icons';
import { toggleSidebar as toggleSidebarRequest } from 'actions/schedulerActions';
import { useBooking, useHandleApproveOrReject, useScheduleRights } from 'modules/scheduler/hooks';
import { useRequests } from 'modules/request/hooks';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { Item, BookingSidebarWrapper, Footer as FooterStyles } from './styles';
import SelectedRange from './selectedRange';
import SelectedBooking from './selectedBooking';
import SelectedEvent from './selectedEvent';
import BookingSidebar from './bookingSidebar';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { getAccount } from 'selectors/account';
import { showEditRequestResourceModal } from 'actions/modalActions';
import { updateResourceOrVacationRequest } from 'actions/requestActions';
import { selectCurrentSelectedBooking } from '../../../../selectors/scheduler';

const Footer = React.forwardRef((props, schedulerRef) => {
    const dispatch = useDispatch();
    const schedulerFooterVisible = useSelector(state => state.account.preferences.grid.schedulerFooterVisible);
    const isFullScreen = useSelector(state => state.initialAppReducer.isFullScreen);
    const { rowTags, ...currentSelection } = useSelector(state => state.scheduler.currentSelection);
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const isSidebarOpen = useSelector(state => state.scheduler.isSidebarOpen);
    const initialActiveSidebarTab = useSelector(state => state.scheduler.initialActiveSidebarTab);
    const defaultVacationId = useSelector(state => state.companyReducer.company.settings.vacationId);
    const account = useSelector(getAccount);
    const isRegularBooking =
        currentSelectedBooking.id &&
        currentSelectedBooking.type !== WAITING_FOR_APPROVAL.value &&
        !currentSelectedBooking.phase &&
        !currentSelectedBooking.milestone &&
        !currentSelectedBooking.datesEvent;
    const isEventBooking = currentSelectedBooking.id && currentSelectedBooking.type === WAITING_FOR_APPROVAL.value;
    const { hasManageApprovalForBooking } = useRequests(currentSelectedBooking);
    const { hasScheduleRights } = useScheduleRights();
    const { updateClipboard, updateBooking, deleteBooking, deleteBookingFromModal, duplicateBooking } = useBooking(
        currentSelectedBooking
    );
    const isVacationFlow = defaultVacationId === currentSelectedBooking.project?._id;
    const isAbleToEdit = isAbleToEditResourceOrVacationRequest(
        currentSelectedBooking,
        account.resourceId,
        account.resourceRoleRights,
        defaultVacationId
    );
    const handleApproveOrReject = useHandleApproveOrReject(updateBooking, currentSelectedBooking);

    const toggleSidebar = useCallback(() => {
        dispatch(toggleSidebarRequest.request());
    }, [dispatch]);

    const handleEdit = useCallback(() => {
        dispatch(
            showEditRequestResourceModal(data => {
                dispatch(updateResourceOrVacationRequest(data));
            }, currentSelectedBooking)
        );
    }, [dispatch, currentSelectedBooking]);

    return (
        <>
            {schedulerFooterVisible && !isFullScreen ? (
                <FooterStyles className="p-0 offset-sm-3 col-sm-9 offset-md-4 col-md-8 offset-lg-3 col-lg-9 offset-xl-2 col-xl-10">
                    {currentSelection.start &&
                        currentSelection.end &&
                        rowTags &&
                        !rowTags.unassignedParentRow &&
                        !rowTags.menuRow &&
                        !rowTags.isEventRow &&
                        hasScheduleRights.schedule && <SelectedRange schedulerRef={schedulerRef} />}
                    {isRegularBooking && currentSelectedBooking.hasEditRights && (
                        <SelectedBooking
                            onNotesClick={!isSidebarOpen ? toggleSidebar : null}
                            bookingId={currentSelectedBooking.id}
                            booking={currentSelectedBooking}
                            updateClipboard={updateClipboard}
                            deleteBooking={deleteBooking}
                            deleteBookingFromModal={deleteBookingFromModal}
                            duplicateBooking={duplicateBooking}
                            updateBooking={updateBooking}
                        />
                    )}
                    {isEventBooking && (
                        <SelectedEvent
                            onClick={handleApproveOrReject}
                            onEdit={isAbleToEdit ? handleEdit : undefined}
                            isVacationFlow={isVacationFlow}
                            hasManageApprovalForBooking={hasManageApprovalForBooking}
                            bookingResourceId={currentSelectedBooking.resourceInfo?._id}
                        />
                    )}
                    <BookingSidebarWrapper>
                        <Item title="View Sidebar" onClick={toggleSidebar}>
                            <Dehaze />
                        </Item>
                    </BookingSidebarWrapper>
                </FooterStyles>
            ) : null}
            <BookingSidebar
                schedulerRef={schedulerRef}
                currentSelectedBooking={currentSelectedBooking}
                initialActiveTab={initialActiveSidebarTab}
                isOpen={isSidebarOpen}
            />
        </>
    );
});

export default Footer;
