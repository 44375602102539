import React, {useEffect, useRef} from 'react';
import { Menu, ClickAwayListener } from '@material-ui/core';
import { usePressEscape } from '../../../../../hooks';
import { useStyles } from '../styles';
import { head } from 'lodash';
import { DeadlineForm } from '../../dropdowns/shared/deadlineForm';
import { useSelector } from 'react-redux';
import { selectCurrentSelectedBooking } from '../../../../../selectors/scheduler';

export const EditDeadlineContextMenu = React.memo(({ top, left, close }) => {
    usePressEscape(close);
    const node = useRef();
    const mountedRef = useRef(false);
    const classes = useStyles();

    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);

    usePressEscape(close);

    useEffect(() => {
        const refTimeout = setTimeout(() => {
            mountedRef.current = true;
        }, 0);

        return () => {
            clearTimeout(refTimeout);
            mountedRef.current = false;
        };
    }, []);

    const handler = event => {
        const datepicker = document.getElementsByClassName('MuiPickersBasePicker-container');

        if (
            !mountedRef.current ||
            node.current.contains(event.target) ||
            (datepicker.length && head(datepicker).contains(event.target))
        ) {
            return;
        }
        close();
    };

    if(!currentSelectedBooking.booking) {
        return null;
    }

    return (
        <Menu
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            keepMounted
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <ClickAwayListener onClickAway={handler}>
                <div className="pt-4 pb-4" style={{ height: 360, minWidth: 300 }}>
                    <DeadlineForm key={currentSelectedBooking.booking?.id} booking={currentSelectedBooking.booking} withDelete />
                </div>
            </ClickAwayListener>
        </Menu>
    );
});
