import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { addNotification } from 'actions/notificationActions';
import { showRepeatModal } from 'actions/modalActions';
import { updateBookingSelection } from 'actions/schedulerActions';
import {
    createMilestoneRequest,
    updateMilestoneRequest,
    deleteMilestoneRequest,
    deleteFutureMilestoneRequest,
    deleteAllMilestonesRequest,
} from 'api/project';
import { formatMilestonesToDP } from 'modules/scheduler/utils/eventUtil';
import {
    dispatchRefreshMilestonesPhases,
    removeDaypilotEvent,
    removeDaypilotEvents,
    updateDaypilotMilestonesPhasesDates,
} from 'modules/scheduler/utils/schedulerUtil';
import { formatDate } from 'utils/DateUtil';
import { selectCurrentSelectedBooking } from '../../../selectors/scheduler';

export const useMilestones = (schedulerRef, onClose) => {
    const dispatch = useDispatch();
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const callback = useCallback(
        milestone => {
            const formattedMilestone = formatMilestonesToDP(isArray(milestone) ? milestone : [milestone]);
            updateDaypilotMilestonesPhasesDates(formattedMilestone, schedulerRef.current.control);
            onClose && onClose();
            schedulerRef.current.control.clearSelection();
        },
        [schedulerRef, onClose]
    );

    const createMilestone = useCallback(
        data => {
            createMilestoneRequest([data])
                .then(callback)
                .catch(() => {
                    dispatch(
                        addNotification({
                            message: 'Something went wrong... We could not create the milestone',
                            type: 'danger',
                        })
                    );
                });
        },
        [dispatch, callback]
    );

    const updateMilestone = useCallback(
        (projectId, data) => {
            updateMilestoneRequest(projectId, data)
                .then(ms => {
                    callback(ms);
                    dispatchRefreshMilestonesPhases();
                    dispatch(updateBookingSelection.request());
                })
                .catch(() => {
                    dispatch(
                        addNotification({
                            message: 'Something went wrong... We could not update the milestone',
                            type: 'danger',
                        })
                    );
                });
        },
        [dispatch, callback]
    );

    const deleteMilestone = useCallback(() => {
        const removeBookings = eventIds => {
            onClose && onClose();
            removeDaypilotEvents(eventIds, schedulerRef.current.control);
        };
        if (currentSelectedBooking.repeat) {
            dispatch(
                showRepeatModal({
                    type: 'MILESTONE',
                    actions: {
                        deleteAll: () =>
                            deleteAllMilestonesRequest(currentSelectedBooking.repeatId).then(response => {
                                removeBookings(response);
                                dispatchRefreshMilestonesPhases();
                            }),
                        deleteFuture: () =>
                            deleteFutureMilestoneRequest(
                                currentSelectedBooking.repeatId,
                                formatDate(currentSelectedBooking.start, 'yyyy-MM-dd')
                            ).then(response => {
                                removeBookings(response);
                                dispatchRefreshMilestonesPhases();
                            }),
                        deleteOne: () =>
                            deleteMilestoneRequest(currentSelectedBooking.id).then(() => {
                                onClose && onClose();
                                removeDaypilotEvent(currentSelectedBooking.id);
                                dispatchRefreshMilestonesPhases();
                                dispatch(updateBookingSelection.request());
                            }),
                    },
                })
            );
            return;
        }
        deleteMilestoneRequest(currentSelectedBooking.id)
            .then(() => {
                onClose && onClose();
                removeDaypilotEvent(currentSelectedBooking.id, schedulerRef.current.control);
                dispatchRefreshMilestonesPhases();
                dispatch(updateBookingSelection.request());
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        message: 'Something went wrong... We could not delete the milestone',
                        type: 'danger',
                    })
                );
            });
    }, [dispatch, currentSelectedBooking, schedulerRef, onClose]);

    return {
        createMilestone,
        updateMilestone,
        deleteMilestone,
    };
};
