import { contains, find, values } from 'underscore';

export const GEO_SEK = {
    region: 'SEK',
    currencyId: 'hubplannerSEK',
    currency: 'SEK',
    vatIdRequired: false,
    inSweden: true,
    vatCountryId: 'GB',
    countries: ['Sweden'],
};
export const GEO_GBP = {
    region: 'GBP',
    currencyId: 'hubplannerGBP',
    currency: '£',
    vatIdRequired: false,
    inSweden: false,
    vatCountryId: 'GB',
    countries: ['United Kingdom'],
};
export const GEO_EUR_NO_TAX = {
    region: 'EUR',
    currencyId: 'hubplannerEUR',
    currency: '€',
    vatIdRequired: false,
    inSweden: false,
    vatCountryId: 'GB',
    countries: ['Switzerland', 'Norway'],
};
export const GEO_EUR = {
    region: 'EUR',
    currencyId: 'hubplannerEUR',
    currency: '€',
    vatIdRequired: true,
    inSweden: false,
    vatCountryId: 'GB',
    countries: [
        'Austria',
        'Belgium',
        'Bulgaria',
        'Croatia',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Estonia',
        'Finland',
        'France',
        'Germany',
        'Greece',
        'Hungary',
        'Ireland',
        'Italy',
        'Latvia',
        'Lithuania',
        'Luxembourg',
        'Malta',
        'Netherlands',
        'Poland',
        'Portugal',
        'Romania',
        'Slovakia',
        'Slovenia',
        'Spain',
    ],
};
export const GEO_USD = {
    region: 'USD',
    currencyId: 'hubplannerUSD',
    currency: '$',
    vatIdRequired: false,
    inSweden: false,
    vatCountryId: 'GB',
};

const billingRegions = {
    GEO_SEK,
    GEO_GBP,
    GEO_EUR_NO_TAX,
    GEO_EUR,
    GEO_USD,
};

export const getBillingRegion = (region, geoLookup) => {
    const geo = billingRegions[`GEO_${region}`];
    const country = geoLookup.country_name;

    // we add this logic due to the region been set to USD (Potentially EUR or GBP) and we need to add VAT field for those within the EU.
    if (
        geo &&
        region !== 'EUR' &&
        geoLookup.continent_code === 'EU' &&
        contains(billingRegions.GEO_EUR.countries, country)
    ) {
        geo.vatIdRequired = true;
    }

    if (geo) {
        return geo;
    }

    const billingRegion = find(values(billingRegions), billingRegion => contains(billingRegion.countries, country));

    if (billingRegion) {
        billingRegion.vatCountryId = geoLookup.country_code;

        return billingRegion;
    }

    return GEO_USD;
};
