import { useCallback } from 'react';
import { change, touch } from 'redux-form';
import { useDispatch } from 'react-redux';
import { forEach } from 'lodash';

export const useForm = (formName, callback) => {
    const dispatch = useDispatch();

    return useCallback(
        values => {
            forEach(values, (value, item) => {
                dispatch(touch(formName, item));
                dispatch(change(formName, item, value));
            });
            callback && callback();
        },
        [formName, callback, dispatch]
    );
};
