import React from 'react';
import moment from 'moment';
import { removeUTCZuluFromDateTimestamp } from '../../../../utils/DateUtil';

const ProjectDatesBubble = React.memo(props => {
    const { start, end, isProjectStartDate } = props;
    return (
        <div>
            Project dates - {isProjectStartDate ? 'Start' : 'End'} Date -{' '}
            {moment(
                new Date(
                    isProjectStartDate ? removeUTCZuluFromDateTimestamp(start) : removeUTCZuluFromDateTimestamp(end)
                )
            ).format('MMM, Do YYYY')}
        </div>
    );
});

export default ProjectDatesBubble;
