import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TYPE_REGULAR } from '../../../../../enums/projectEnum';
import { useAppKeyWords } from '../../../../../hooks';
import SearchList from '../../searchList/searchList';
import { useSchedulerData } from '../../../hooks';
import { TYPE_UNASSIGNED } from '../../../../../enums/resourceEnum';
import ImageIcon from '../../../../../shared/ImageIcon';
import { capitalizeFirstLetter } from '../../../../../utils/formatingUtil';
import ListItem from '../listItem';
import { useSchedulerProjectsListContext } from './schedulerProjectsListProvider';
import { useMenuProjectPermissions } from '../../../hooks/useMenuPermissions';
import SuccessButton from 'shared/buttons/success';
import { useProjectStatusDisplay } from '../../../../../shared/projectStatusDisplay';

const ProjectListItem = React.memo(props => {
    const { projectKeyWord } = useAppKeyWords();

    const statusDisplay = useProjectStatusDisplay(props.item.status);

    return (
        <ListItem
            divider
            disableGutters
            icon={<ImageIcon urlString={props.item?.thumb} />}
            avatarSize={30}
            secondaryText={`${props.item.projectCode ? props.item.projectCode + ' - ' : ''}${
                TYPE_REGULAR.value === props.item.type
                    ? `${statusDisplay} ${projectKeyWord}`
                    : capitalizeFirstLetter(props.item.type)
            }`}
            {...props}
        />
    );
});

const SchedulerProjectsList = props => {
    const {
        resource,
        height,
        onSelect,
        onlyEvents,
        canRequestResources,
        selectedIds,
        noDataRefresh,
        slideRef,
        hideSelected,
        allPermitted,
        hideArchived,
    } = props;

    const { hasAddEditProjectRights } = useMenuProjectPermissions();
    const {
        filterButtons,
        filterButtonApplied,
        setFilterButtonApplied,
        isEventsFilterApplied,
        projectName,
        setProjectName,
    } = useSchedulerProjectsListContext();

    const { projectKeyWord } = useAppKeyWords();

    const { projects } = useSchedulerData(resource?._id, null, {
        onlyUnassigned: resource?.type === TYPE_UNASSIGNED.value,
        onlyEvents,
        canRequestResources,
        noDataRefresh,
        allPermittedProjects: allPermitted,
        hideArchived,
    });

    const handleClick = useCallback(() => {
        slideRef.current?.triggerNext();
    }, [slideRef]);

    return (
        <>
            <SearchList
                itemComponent={ProjectListItem}
                filtersButtons={filterButtons}
                filterButtonApplied={filterButtonApplied}
                onFilterButtonClicked={setFilterButtonApplied}
                height={height}
                emptyText="Sorry, there are no results matching your filter."
                data={projects}
                name="project"
                rowHeight={60}
                onEmptySearch={setProjectName}
                onSelect={onSelect}
                searchBy={['status', 'projectCode']}
                fields={{ label: 'name', value: '_id' }}
                placeholder="Type to filter ..."
                selectedIds={selectedIds}
                hideSelected={hideSelected}
            />
            {hasAddEditProjectRights ? (
                <SuccessButton
                    onClick={handleClick}
                    name={
                        isEventsFilterApplied
                            ? `Add new event${projectName && ` "${projectName}"`}`
                            : `Add new ${projectKeyWord.toLowerCase()}${projectName && ` "${projectName}"`}`
                    }
                    type="btn-secondary"
                    className="w-100"
                    data-cy="button--add-new-project"
                />
            ) : null}
        </>
    );
};

SchedulerProjectsList.propTypes = {
    onSelect: PropTypes.func,
    resource: PropTypes.object,
    height: PropTypes.number,
    onlyEvents: PropTypes.bool,
    allPermitted: PropTypes.bool,
    canRequestResources: PropTypes.bool,
    selectedIds: PropTypes.array,
    hideSelected: PropTypes.bool,
    noDataRefresh: PropTypes.bool,
    slideRef: PropTypes.object,
};

SchedulerProjectsList.defaultProps = {
    selectedIds: [],
    hideSelected: false,
    onSelect: null,
    onlyEvents: false,
    allPermitted: false,
    canRequestResources: false,
    height: 100,
    resource: {},
    noDataRefresh: false,
    slideRef: { current: undefined },
};

const memoized = React.memo(SchedulerProjectsList);

export { memoized as SchedulerProjectsList };
