import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { validateSubDomainFree } from 'api/company';
import { lengthValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        firstName: {
            type: String,
            required: true,
        },
        lastName: {
            type: String,
            required: true,
        },
        companyName: {
            type: String,
            required: true,
        },
        subDomain: {
            type: String,
            required: true,
            validators: [lengthValidator(2, 'Domain Name')],
        },
        newFeatureEmailAgreed: {
            type: Boolean,
            required: false,
        },
    },
    errorMessages,
    false
);

export const asyncValidate = values => {
    if (values.currentSubDomain === values.subDomain) {
        return Promise.resolve();
    }
    return validateSubDomainFree(values.subDomain).then(data => {
        if (!data.available) {
            const errObj = { subDomain: 'That domain is taken or not valid' };
            throw errObj;
        }
    });
};
