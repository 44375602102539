import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const createProject = {
    request: (data, callback, redirect = false) =>
        action(actionTypes.CREATE_PROJECT['REQUEST'], { data, callback, redirect }),
    success: (project, projectGroupsAdd = []) =>
        action(actionTypes.CREATE_PROJECT['SUCCESS'], { project, projectGroupsAdd }),
    failure: () => action(actionTypes.CREATE_PROJECT['FAILURE']),
};

export const duplicateProject = {
    request: (projectId, projectGroupId, callback) =>
        action(actionTypes.DUPLICATE_PROJECT['REQUEST'], { projectId, projectGroupId, callback }),
    success: (project, projectGroupsAdd) =>
        action(actionTypes.DUPLICATE_PROJECT['SUCCESS'], { project, projectGroupsAdd }),
};

export const getProject = {
    request: id => action(actionTypes.GET_PROJECT['REQUEST'], { id }),
    success: project => action(actionTypes.GET_PROJECT['SUCCESS'], { project }),
};

export const addResourceToProject = {
    request: ({ projectId, resourceId, onSuccess }) =>
        action(actionTypes.ADD_RESOURCE_TO_PROJECT['REQUEST'], { projectId, resourceId, onSuccess }),
    success: ({ updatedProjects, projectId, resourceId }) =>
        action(actionTypes.ADD_RESOURCE_TO_PROJECT['SUCCESS'], {
            updatedProjects,
            projectId,
            resourceId,
        }),
    failure: () => action(actionTypes.ADD_RESOURCE_TO_PROJECT['FAILURE']),
};

export const updateProject = {
    request: (id, data, callback) => action(actionTypes.UPDATE_PROJECT['REQUEST'], { id, data, callback }),
    success: (project, projectGroupsAdd, projectGroupsRemove, accountGridMode) =>
        action(actionTypes.UPDATE_PROJECT['SUCCESS'], {
            project,
            projectGroupsAdd,
            projectGroupsRemove,
            accountGridMode,
        }),
    failure: () => action(actionTypes.UPDATE_PROJECT['FAILURE']),
};

export const getProjects = {
    request: force => action(actionTypes.GET_PROJECTS['REQUEST'], { force }),
    success: (projects, lastCallContext) => action(actionTypes.GET_PROJECTS['SUCCESS'], { projects, lastCallContext }),
    stop: () => action(actionTypes.GET_PROJECTS_STOP),
};

export const deleteProject = {
    request: (id, callback) => action(actionTypes.DELETE_PROJECT['REQUEST'], { id, callback }),
    success: id => action(actionTypes.DELETE_PROJECT['SUCCESS'], { id }),
};

export const createMultipleProjects = {
    request: (data, callback, withoutNotification) =>
        action(actionTypes.CREATE_MULTIPLE_PROJECTS['REQUEST'], { data, callback, withoutNotification }),
    success: data => action(actionTypes.CREATE_MULTIPLE_PROJECTS['SUCCESS'], { data }),
};

export const resetProjectValue = {
    request: propertyName => action(actionTypes.RESET_PROJECT_VALUE['REQUEST'], { propertyName }),
};
