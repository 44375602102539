import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS, RATE_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';

/**
 * @returns {Array}
 */
export default () => [
    {
        id: 'externalRemainingScheduledBudgetCashAllTime',
        display: 'Budget Cash Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalRemainingScheduledBudgetCashAllTime',
        display: 'Budget Cash Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'externalConsumedScheduledBudgetCashAllTimePercentage',
        display: '% Budget Cash Consumed',
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalConsumedScheduledBudgetCashAllTimePercentage',
        display: '% Budget Cash Consumed',
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'externalScheduledBudgetCashAllTimeStatus',
        display: 'Budget Cash Status',
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalScheduledBudgetCashAllTimeStatus',
        display: 'Budget Cash Status',
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'externalRemainingScheduledDifferenceAllTime',
        display: 'Remaining Spend Difference',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'internalRemainingScheduledDifferenceAllTime',
        display: 'Remaining Spend Difference',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'remainingScheduledBudgetHoursAllTime',
        display: 'Budget Hours Remaining',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'consumedScheduledBudgetHoursAllTimePercentage',
        display: '% Budget Hours Consumed',
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'scheduledBudgetHoursAllTimeStatus',
        display: 'Budget Hour Status',
        displayTimeType: 'Scheduled',
        displayColumnType: 'budget',
        columnType: COLUMN_TYPES_ENUM.BUDGET,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
];
