import React from 'react';
import { withRouter } from 'react-router-dom';
import BaseFilterBox from './baseFilterBox';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { assign, find, findIndex, isEmpty } from 'underscore';
import TextFilter from './../textFilter';
import { getReportLevel } from 'modules/report/enums/storageItemEnum';
import { FiltersBox, Content, Footer, FilterIcon } from './styles';

class TextsFilterBox extends BaseFilterBox {
    static propTypes = {
        columnName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            active: false,
            operator: 'eq',
            value: '',
            toggleBox: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        this.setActiveState(true, prevState.active);
    }

    setActiveState = (activeOnly, prevActive) => {
        const {
            columnName,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const existFilter = find(resultValue, filter => filter.columnName === columnName);
        const isActive = undefined !== existFilter;
        const value = isActive ? existFilter.boundary : '';
        const operator = isActive ? existFilter.operator : 'eq';

        if (activeOnly) {
            prevActive !== isActive && this.setState({ active: isActive });
        } else {
            this.setState({ active: isActive, value, operator });
        }
    };

    onFilter = () => {
        this.toggle();
        this.addTextFilter();
    };

    addTextFilter = () => {
        const currentFilter = {
            columnName: this.props.columnName,
            operator: this.state.operator,
            boundary: this.state.value,
        };
        const reportLevel = getReportLevel(this.props.match.params);
        this.props.updateQueryParams(
            assign({}, this.props.queryParams, {
                filters: {
                    ...this.props.queryParams.filters,
                    resultValue: this.getCurrentFilters(currentFilter),
                },
            }),
            reportLevel.storageItemName
        );
        this.setState({ active: true });
    };

    onClear = () => {
        this.toggle();

        const {
            columnName,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const existTextFilterIndex = findIndex(resultValue, filter => filter.columnName === columnName);
        const reportLevel = getReportLevel(this.props.match.params);

        -1 < existTextFilterIndex && resultValue.splice(existTextFilterIndex, 1);

        this.props.updateQueryParams(
            assign({}, this.props.queryParams, {
                filters: {
                    ...this.props.queryParams.filters,
                    resultValue,
                },
            }),
            reportLevel.storageItemName
        );
        this.setState({ active: false });
    };

    onChange = value => {
        this.setState(value);
    };

    toggle = () => {
        this.setState(state => ({
            toggleBox: !state.toggleBox,
        }));

        if (!this.state.toggleBox) {
            this.setActiveState();
        }
    };

    render() {
        const { operator, value } = this.state;
        const positions = this.node && this.node.getBoundingClientRect();

        return (
            <div className="position-absolute" style={{ right: '3px', top: '3px' }} ref={node => (this.node = node)}>
                {!this.props.disabled && (
                    <FilterIcon
                        open={this.state.toggleBox}
                        active={this.state.active.toString()}
                        onClick={this.toggle}
                    />
                )}
                <FiltersBox
                    position={positions && 600 < positions.left ? 'right' : 'left'}
                    height={174}
                    className={classnames({ 'd-none': !this.state.toggleBox })}
                >
                    <Content>
                        <TextFilter defaultValues={{ operator, value }} onChange={this.onChange} />
                    </Content>
                    <Footer>
                        <Button disabled={isEmpty(value)} color="success" onClick={this.onFilter}>
                            Filter
                        </Button>
                        <Button disabled={!this.state.active} color="secondary" onClick={this.onClear}>
                            Clear
                        </Button>
                    </Footer>
                </FiltersBox>
            </div>
        );
    }
}

export default withRouter(TextsFilterBox);
