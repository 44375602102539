import { setDayDate } from './util';

export default (args, companyStartEndTimes, updatePhase) => {
    const currentScale = args.control.scale;

    if (args.e.data.repeat) {
        //remove repeat when moved
        args.e.data.repeat = false;
        args.e.data.repeatId = null;
        args.e.data.repeatTimes = 0;
        args.e.data.interval = 'NONE';
    }

    const {
        data: { start, end, projectId, id, repeatId, repeat, repeatTimes, interval },
    } = setDayDate(args.e, currentScale, args.newStart, args.newEnd, companyStartEndTimes);

    updatePhase(id, projectId, {
        start,
        end,
        repeatId,
        repeat,
        repeatTimes,
        interval,
    });
};
