import Schema from 'form-schema-validation';
import { format } from 'date-fns';
import { repeatTimesValidator } from 'utils/formValidators';
import { errorMessages } from 'utils/schemaUtil';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        date: {
            type: Date,
            required: true,
            defaultValue: new Date(),
        },
        color: {
            type: String,
            required: false,
            defaultValue: '#349E71',
        },
        interval: {
            type: String,
            required: false,
            defaultValue: 'NONE',
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
        project: {
            type: String,
            required: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = (date, color, projectId, values) => {
    return Object.assign(
        {},
        formSchema.getDefaultValues(),
        {
            date,
            color,
            project: projectId,
        },
        // Put props that are not null
        Object.keys(values).reduce((acc, cv) => {
            if (values[cv] != null) {
                return { ...acc, [cv]: values[cv] };
            }
            return acc;
        }, {})
    );
};

export const mapFormToRequest = values => ({
    date: format(values.date, 'yyyy-MM-dd'),
    name: values.name,
    interval: values.interval,
    repeatTimes: parseInt(values.repeatTimes),
    repeat: 0 < values.repeatTimes && 'NONE' !== values.interval,
    color: values.color,
    project: values.project,
});
