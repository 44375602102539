import { createSelector } from 'reselect';
import { filter, includes } from 'lodash';

export const getCategoryGroups = state => state.categoryGroupReducer.categoryGroups;
export const selectCategoryGroupsLoaded = state => !state.categoryGroupReducer.loading;

export const makeCategoryGroups = categoryGroupIds =>
    createSelector(getCategoryGroups, categoryGroups => {
        if (categoryGroupIds) {
            return filter(categoryGroups, categoryGroup => includes(categoryGroupIds, categoryGroup._id));
        }

        return categoryGroups;
    });

export const selectFlatCategories = createSelector(getCategoryGroups, categoryGroups =>
    (categoryGroups || []).map(categoryGroup => categoryGroup.categories).flat()
);
