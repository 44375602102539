import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, RATE_TYPE_COLUMNS, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {Array}
 */
export default dateState => [
    // projectSpecific
    {
        id: 'projectSpecificExternalReportedSpendBetween',
        display: `${displayDateState(dateState, false, false)} Project Spend`,
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'projectSpecificExternalReportedSpendToDate',
        display: 'Project Spend To Date',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificExternalReportedSpendFuture',
        display: 'Project Future Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificExternalReportedSpendAllTime',
        display: 'Project All Time Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificInternalReportedSpendBetween',
        display: `${displayDateState(dateState, false, false)} Project Spend`,
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'projectSpecificInternalReportedSpendToDate',
        display: 'Project Spend To Date',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificInternalReportedSpendFuture',
        display: 'Project Future Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificInternalReportedSpendAllTime',
        display: 'Project All Time Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Project Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    // eventSpecific
    {
        id: 'eventSpecificExternalReportedSpendBetween',
        display: `${displayDateState(dateState, false, false)} Event Spend`,
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'eventSpecificExternalReportedSpendToDate',
        display: 'Event Spend To Date',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificExternalReportedSpendFuture',
        display: 'Event Future Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificExternalReportedSpendAllTime',
        display: 'Event All Time Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.EXTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificInternalReportedSpendBetween',
        display: `${displayDateState(dateState, false, false)} Event Spend`,
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: true,
    },
    {
        id: 'eventSpecificInternalReportedSpendToDate',
        display: 'Event Spend To Date',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificInternalReportedSpendFuture',
        display: 'Event Future Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificInternalReportedSpendAllTime',
        display: 'Event All Time Spend',
        unitType: columnUnitTypes.CURRENCY.value,
        displayTimeType: 'Event Reported Time',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        rateType: RATE_TYPE_COLUMNS.INTERNAL,
        heatMapSupport: false,
    },
];
