import React from 'react';
import { Tag, TagBackground, Label, IconWrapper } from './styles';

const getPillColor = (name) => {
    if(name.includes('booking')) {
        return 'green';  
    } else if(name.includes('project') || name === 'currencies') {
        return 'blue';
    } else {
        return 'purple';
    }
}

const TagItem = ({ item: { label, icon: Icon, name } }) => {
    const color = getPillColor(name);
    return (
        <Tag>
            <IconWrapper>{Icon && <Icon />}</IconWrapper>
            <Label color={color}>{label}</Label>
            <TagBackground color={color} />
        </Tag>
    )
};

export default TagItem;
