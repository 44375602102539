import React, { useCallback, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import DayPicker, { DateUtils } from 'react-day-picker';
import { set, subDays, addDays, format } from 'date-fns';
import { Layout } from 'shared/components/layout';
import SuccessButton from 'shared/buttons/success';
import { Intervals } from './Intervals';
import { getEndTIme } from '../../../../../shared/components/CustomAvailability/CustomAvailability';
import { availabilityOverridesSchema } from 'forms/resourceForm';
import { calculateTotalHours } from 'shared/components/CustomAvailability/DayInterval';
import { removeUTCZuluFromDateObject } from 'utils/DateUtil';
import { YEAR_MONTH_DAY_FORMAT } from 'global/enums/dateFormat';
import { scrollStyles } from 'shared/scroll/styles';

const prepareDisabledDays = (data, editOverride, editOverrideIdx) => {
    return data
        ?.filter((el, idx) => (editOverride ? idx !== editOverrideIdx : true))
        .map(el => {
            const { from, to } = el;
            const isDateString = typeof from === 'string';
            return {
                after: isDateString
                    ? removeUTCZuluFromDateObject(subDays(new Date(from), 1))
                    : subDays(new Date(from), 1),
                before: isDateString ? removeUTCZuluFromDateObject(addDays(new Date(to), 1)) : addDays(new Date(to), 1),
            };
        });
};

const DEFAULT_DAY_TIME_INTERVAL = {
    start: 8 * 60,
    end: 16 * 60,
};

const useStyles = makeStyles({
    overflow: {
        overflowY: 'auto',
        maxHeight: '85vh',
        ...scrollStyles,
    },
});

const getRange = editOverride => {
    if (editOverride) {
        const { from, to } = editOverride;
        const isDateString = typeof from === 'string';
        return {
            from: isDateString ? removeUTCZuluFromDateObject(new Date(from)) : new Date(from),
            to: isDateString ? removeUTCZuluFromDateObject(new Date(to)) : new Date(to),
        };
    }
    return {
        from: null,
        to: null,
    };
};

export const AvailabilityOverrideForm = React.memo(
    React.forwardRef((props, ref) => {
        const { editOverride, editOverrideIdx, handleClose, applyChanges, data } = props;
        const classes = useStyles();
        const [range, setRange] = useState(getRange(editOverride));
        const [intervals, setIntervals] = useState(editOverride ? editOverride.intervals : []);
        const [errors, setErrors] = useState({
            overlap: false,
            intervals: false,
            overrideOverlap: false,
        });
        const { from, to } = range;

        const checkOverrideOverlap = useCallback(
            newOverride => {
                return overrideDatesCheck(newOverride, data, editOverride, editOverrideIdx);
            },
            [data, editOverride, editOverrideIdx]
        );

        useEffect(() => {
            const err = availabilityOverridesSchema.validate({
                ...range,
                intervals,
            });
            setErrors({ ...err, overrideOverlap: checkOverrideOverlap(range) });
        }, [intervals, range, checkOverrideOverlap]);

        const handleDayClick = day => {
            const newRange = DateUtils.addDayToRange(day, range);

            if (!newRange.to) {
                newRange.to = newRange.from;
            }

            const rangeData =
                newRange.from && newRange.to
                    ? {
                          from: set(newRange.from, { hours: 0, minutes: 0, seconds: 0 }),
                          to: set(newRange.to, { hours: 23, minutes: 59, seconds: 59 }),
                      }
                    : newRange;
            setRange(rangeData);
        };

        const addInterval = useCallback(() => {
            setIntervals(state => {
                const lastEnd = state?.at(-1)?.end;
                const endTime = getEndTIme(lastEnd);
                return [
                    ...state,
                    ...(lastEnd
                        ? [
                              {
                                  start: lastEnd,
                                  end: lastEnd + endTime,
                              },
                          ]
                        : [DEFAULT_DAY_TIME_INTERVAL]),
                ];
            });
        }, []);

        const changeInterval = useCallback((index, interval) => {
            setIntervals(state =>
                state.map((int, idx) => {
                    if (idx !== index) return int;
                    return interval;
                })
            );
        }, []);

        const removeInterval = useCallback(index => {
            setIntervals(state => state.filter((int, idx) => idx !== index));
        }, []);

        const apply = useCallback(() => {
            applyChanges &&
                applyChanges({
                    ...editOverride,
                    ...range,
                    intervals,
                    minutes: calculateTotalHours(intervals).toFixed(2) * 60,
                });
        }, [applyChanges, intervals, editOverride, range]);

        const modifiers = { start: from, end: to };

        const displayOverlapError =
            errors.overlap && typeof errors.overlap[0] === 'string' && !errors.intervals && !errors.overrideOverlap;

        return (
            <Layout stack padding="20px" className="dynamic-cap-dp" ref={ref}>
                <div className={classes.overflow}>
                    <Layout padding="0px" vAlign="center" hAlign="center">
                        <DayPicker
                            month={to}
                            className="Overrides"
                            selectedDays={[from, { from, to }]}
                            onDayClick={handleDayClick}
                            modifiers={modifiers}
                            disabledDays={prepareDisabledDays(data, editOverride, editOverrideIdx)}
                        />
                    </Layout>
                    <Intervals
                        intervals={intervals}
                        addInterval={addInterval}
                        changeInterval={changeInterval}
                        removeInterval={removeInterval}
                        error={errors.intervals}
                    />
                    {displayOverlapError && (
                        <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errors.overlap[0] }} />
                    )}
                    {errors.overrideOverlap && (
                        <div className="alert alert-danger">
                            <strong>Overlapping Dates</strong> There is already custom availability set on these dates.
                        </div>
                    )}
                </div>
                <Layout hAlign="space-between" padding="20px 0 0 0">
                    <SuccessButton
                        dataCy="availability-override-cancel"
                        name="Cancel"
                        type="btn-cancel"
                        onClick={handleClose}
                    />
                    <SuccessButton
                        dataCy="availability-override-apply"
                        name={intervals.length > 0 ? 'Set availability' : 'Mark as unavailable'}
                        disabled={!from || !to || errors.overlap || errors.intervals || errors.overrideOverlap}
                        onClick={apply}
                    />
                </Layout>
            </Layout>
        );
    })
);

export const overrideDatesCheck = (newOverride, data, editOverride, editOverrideIdx) => {
    const overFrom = newOverride?.from && format(newOverride.from, YEAR_MONTH_DAY_FORMAT);
    const overTo = newOverride?.to && format(newOverride.to, YEAR_MONTH_DAY_FORMAT);
    let overrideOverlap = false;
    overrideOverlap = data
        ?.filter((el, idx) => (editOverride ? idx !== editOverrideIdx : true))
        .some(el => {
            const isDateString = typeof el.from === 'string';
            const elFrom = isDateString
                ? format(removeUTCZuluFromDateObject(new Date(el.from)), YEAR_MONTH_DAY_FORMAT)
                : format(new Date(el.from), YEAR_MONTH_DAY_FORMAT);
            const elTo = isDateString
                ? format(removeUTCZuluFromDateObject(new Date(el.to)), YEAR_MONTH_DAY_FORMAT)
                : format(new Date(el.to), YEAR_MONTH_DAY_FORMAT);
            return (
                (overFrom >= elFrom && overFrom <= elTo) ||
                (overTo >= elFrom && overTo <= elTo) ||
                (elFrom >= overFrom && elFrom <= overTo) ||
                (elTo >= overFrom && elTo <= overTo)
            );
        });

    return overrideOverlap;
};
