import React, { useCallback, useEffect, useRef, useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import ReactPlaceholder from 'react-placeholder';
import { List as VirtualizedList } from 'react-virtualized';
import { filter, isNil } from 'lodash';
import Item from 'modules/sidebar/menu/containers/Item';
import EmptyText from 'modules/sidebar/menu/components/EmptyText';
import ItemPlaceholder from './itemPlaceholder';
import MenuContext from './../menuContext';
import 'assets/styles/placeholder.css';
import { selectSubGroupsPosition } from '../../../../selectors/account';
import { subGroupsPositionEnum } from '../../../scheduler/enums/subGroupsPositionEnum';
import latinize from 'latinize';

const placeholderItems = Array.from(Array(5).keys());

const Placeholder = () => (
    <div className="show-loading-animation">
        {placeholderItems.map(item => (
            <ItemPlaceholder key={item} />
        ))}
    </div>
);

const getScrollIndexFromHash = items => {
    const currentHash = window.location.hash;
    const indexFound = (items || []).findIndex(item => currentHash.indexOf(item?.to || '') === 1);
    return indexFound;
};

const isNavigationTab = item => 'menu-top-actions' === item.currentClassItem && !item.parentId;

const renderListItem = (item, i) => <Item key={item.id || `_${i}`} {...item} />;

const Container = props => {
    const {
        visible,
        itemId,
        omitPlaceholder,
        emptyText,
        emptyComponent,
        lazyLoading,
        clearItems,
        virtualize,
    } = props;

    const isReportPage = window.location.hash.includes('reports');
    const LIMIT_FOR_VIRTUALIZATION = isReportPage ? 1000 : 12;
    const context = useContext(MenuContext);
    const wrapper = useRef();
    const list = useRef();
    const { filterText, parentId, content } = useSelector(state => state.menuContent);
    const subGroupsPositionSetting = useSelector(selectSubGroupsPosition);
    const [loaded, setLoaded] = useState(false);
    const [visibility, setVisibility] = useState(!lazyLoading || (lazyLoading && visible));
    const isEmptyAreaDefined = 0 < emptyText.length || !isNil(emptyComponent);

    const items = useMemo(() => filter(content, item => item.parentId === itemId && !isNavigationTab(item)), [
        content,
        itemId,
    ]);

    const navTabs = useMemo(() => filter(content, isNavigationTab), [content]);

    const itemsCountExcludingSearch = items.length - 1;
    const renderWithoutVirtualization = useMemo(() => {
        return (
            (0 >= itemsCountExcludingSearch && !isEmptyAreaDefined) ||
            (LIMIT_FOR_VIRTUALIZATION > items.length && 0 < itemsCountExcludingSearch) ||
            !virtualize
        );
    }, [LIMIT_FOR_VIRTUALIZATION, isEmptyAreaDefined, items.length, itemsCountExcludingSearch, virtualize]);

    const filtered = useMemo(
        () =>
            !lazyLoading || (lazyLoading && visibility)
                ? filter(items, item => {
                      if (0 < filterText.length && item.parentId === parentId && !item.filter && !item.skipSearch) {
                          const searchable = item.textSearch || item.label || '';
                          return -1 !== latinize(searchable.toLowerCase()).indexOf(latinize(filterText));
                      }
                      return !item.filter || (item.filter && item.filterVisible);
                  })
                : [],
        [lazyLoading, items, parentId, filterText, visibility]
    );

    const { filteredItems, filteredSubGroups, filteredTopItems } = useMemo(() => {
        return filtered.reduce(
            (acc, item) => {
                switch (item.type) {
                    case 'GROUP':
                        acc.filteredSubGroups.push(item);
                        return acc;
                    case 'ITEM':
                        acc.filteredItems.push(item);
                        return acc;
                    default:
                        acc.filteredTopItems.push(item);
                        return acc;
                }
            },
            {
                filteredTopItems: [],
                filteredItems: [],
                filteredSubGroups: [],
            }
        );
    }, [filtered]);

    const [scrollToIndex, setScrollToIndex] = useState(visible ? getScrollIndexFromHash(filteredItems) : undefined);

    const conditionToPlaceholder =
        !omitPlaceholder && context.usePlaceholder && !loaded && visible && 0 >= itemsCountExcludingSearch - 1;

    useEffect(() => {
        const moveCb = () => {
            const indexFound = getScrollIndexFromHash(filteredItems);
            setScrollToIndex && setScrollToIndex !== scrollToIndex && setScrollToIndex(indexFound);
        };

        visible && window.addEventListener('scroll-vlist-index', moveCb);
        return () => {
            window.removeEventListener('scroll-vlist-index', moveCb);
        };
    }, [loaded, filteredItems, items, visible, scrollToIndex]);

    useEffect(() => {
        let widthTimeout = null;
        if (lazyLoading && clearItems) {
            widthTimeout = setTimeout(() => {
                setVisibility(false);
            }, 1500);
        } else if (lazyLoading) {
            clearTimeout(widthTimeout);
            setVisibility(!clearItems);
        }

        return () => {
            clearTimeout(widthTimeout);
        };
    }, [visible, lazyLoading, clearItems, visibility]);

    useEffect(() => {
        if (!context.usePlaceholder) {
            return;
        }
        if (!omitPlaceholder && context.usePlaceholder && !loaded && visible && !context.loadingSubmenuData) {
            setLoaded(true);
        }
    }, [loaded, context.usePlaceholder, visible, omitPlaceholder, context.loadingSubmenuData]);

    const renderItem = useCallback(
        ({ index, key, style }) => <Item style={style} key={key} {...filteredItems[index]} />,
        [filteredItems]
    );

    const renderWithoutVirtualizationJSX = useCallback(() => {
        // change order of subgroups and items based on config
        return (
            <>
                {filteredTopItems.map(renderListItem)}
                {subGroupsPositionSetting === subGroupsPositionEnum.top ? (
                    <>
                        {filteredSubGroups.map(renderListItem)}
                        {filteredItems.map(renderListItem)}
                    </>
                ) : (
                    <>
                        {filteredItems.map(renderListItem)}
                        {filteredSubGroups.map(renderListItem)}
                    </>
                )}
            </>
        );
    }, [filteredItems, filteredSubGroups, filteredTopItems, subGroupsPositionSetting]);

    const onRowsRendered = useCallback(() => {
        if (visible && 0 < filteredItems.length && visibility && !scrollToIndex) {
            setTimeout(() => {
                window.dispatchEvent(new Event('scroll-vlist-index'));
            }, 0);
        }
    }, [filteredItems.length, scrollToIndex, visibility, visible]);

    if (conditionToPlaceholder) {
        return (
            <ul
                ref={wrapper}
                className={classnames(
                    'function' === typeof context.classStore.classContainer
                        ? context.classStore.classContainer({
                              itemId,
                              visible,
                              items,
                          })
                        : context.classStore.classContainer,
                    visible && context.classStore.classContainerVisible
                )}
            >
                <ReactPlaceholder ready={true} customPlaceholder={<Placeholder />}>
                    <Placeholder />
                </ReactPlaceholder>
            </ul>
        );
    }

    const renderWithVirtualizationJSX = () => {
        if (0 === filter(items, item => !item.filter).length && isEmptyAreaDefined) {
            return 0 < emptyText.length ? <EmptyText emptyText={emptyText} /> : emptyComponent;
        } else {
            const height =
                filteredItems.length > LIMIT_FOR_VIRTUALIZATION
                    ? LIMIT_FOR_VIRTUALIZATION * context.rowHeight
                    : filteredItems.length * context.rowHeight;
            return (
                <>
                    {filteredTopItems.map(renderListItem)}
                    {subGroupsPositionSetting === subGroupsPositionEnum.top ? (
                        <>
                            {filteredSubGroups.map(renderListItem)}
                            {0 < filteredItems.length && visibility && (
                                <VirtualizedList
                                    className="sidebar-virtualized"
                                    width={(wrapper.current && wrapper.current.getBoundingClientRect().width) || 420}
                                    rowHeight={context.rowHeight}
                                    height={height}
                                    ref={list}
                                    rowCount={filteredItems.length}
                                    rowRenderer={renderItem}
                                    scrollToIndex={scrollToIndex}
                                    scrollToAlignment="center"
                                    onRowsRendered={onRowsRendered}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {0 < filteredItems.length && visibility && (
                                <VirtualizedList
                                    className="sidebar-virtualized"
                                    width={(wrapper.current && wrapper.current.getBoundingClientRect().width) || 420}
                                    rowHeight={context.rowHeight}
                                    height={height}
                                    ref={list}
                                    rowCount={filteredItems.length}
                                    rowRenderer={renderItem}
                                    scrollToIndex={scrollToIndex}
                                    scrollToAlignment="center"
                                    onRowsRendered={onRowsRendered}
                                />
                            )}
                            {filteredSubGroups.map(renderListItem)}
                        </>
                    )}
                </>
            );
        }
    };

    return (
        <>
            {undefined === itemId && Boolean(navTabs.length) && (
                <ul className="menu-nav-tabs">
                    {navTabs.map((item, i) => (
                        <Item key={item.id || `_${i}`} {...item} />
                    ))}
                </ul>
            )}
            <ul
                ref={wrapper}
                className={classnames(
                    'function' === typeof context.classStore.classContainer
                        ? context.classStore.classContainer({
                              itemId,
                              visible,
                              items,
                          })
                        : context.classStore.classContainer,
                    visible && context.classStore.classContainerVisible
                )}
            >
                {renderWithoutVirtualization && renderWithoutVirtualizationJSX()}
                {!renderWithoutVirtualization && renderWithVirtualizationJSX()}
            </ul>
        </>
    );
};

Container.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    visible: PropTypes.bool,
    virtualize: PropTypes.bool,
    emptyComponent: PropTypes.element,
};

Container.defaultProps = {
    itemId: undefined,
    emptyText: '',
    visible: false,
    emptyComponent: null,
    virtualize: false,
};

export default Container;
