import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isUndefined, isString } from 'underscore';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStopPropagationOnKeyDown } from '../../../hooks/useStopPropagationOnKeyDown';
import { useField } from 'formik';

const useStyles = makeStyles({
    input: props => ({
        paddingLeft: !props.hasIcon ? '14px !important' : 0,
        paddingBottom: 0,
        paddingRight: 0,
        paddingTop: 0,
        height: !props.isMultiline ? ('standard' === props.variant ? '2rem !important' : '35px !important') : undefined,
    }),
    inputNumber: {},
    formHelper: {
        whiteSpace: 'initial',
        marginLeft: 0,
        marginRight: 0,
    },
});

const getAddon = (meta, Icon) => {
    if (!Icon) {
        return null;
    }

    switch (true) {
        case meta && meta.asyncValidating:
            return (
                <InputAdornment position="start">
                    <i className="fa fa-spinner fa-pulse fa-fw" />
                </InputAdornment>
            );
        case isString(Icon) && -1 !== Icon.indexOf('fa'):
            return (
                <InputAdornment position="start">
                    <i className={classNames('fa', Icon)} />
                </InputAdornment>
            );
        case isString(Icon) && -1 === Icon.indexOf('fa'):
            return <InputAdornment position="start">{Icon}</InputAdornment>;
        default:
            return <Icon fontSize="small" />;
    }
};

const materialInputGroupField = React.forwardRef((props, ref) => {
    const {
        input,
        variant,
        label,
        type,
        icon: Icon,
        inputClass,
        formHelperText,
        className,
        disabled,
        placeholder,
        meta,
        min,
        max,
        step,
        endIcon,
        endIconAction,
        endInputAdornment,
        onKeyPress,
        dataCy,
        shrinkLabel,
        ...restOfProps
    } = props;

    const show = meta && meta.touched && !isUndefined(meta.error);
    const classes = useStyles({ hasIcon: !!Icon, isMultiline: restOfProps.multiline, variant });

    const getEndAdornment = () => {
        if (endInputAdornment) {
            return <InputAdornment position="end">{endInputAdornment}</InputAdornment>;
        }

        if (endIcon) {
            return (
                <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={endIconAction} edge="end">
                        {typeof endIcon === 'function' ? endIcon() : endIcon}
                    </IconButton>
                </InputAdornment>
            );
        }
    };

    React.useEffect(() => {
        const handleWheel = e => e.preventDefault();
        const inputRef = ref?.current;
        inputRef?.addEventListener('wheel', handleWheel);

        return () => {
            inputRef?.removeEventListener('wheel', handleWheel);
        };
    }, [ref]);

    const handleOnKeyDown = useStopPropagationOnKeyDown();

    const errorMsg = Array.isArray(meta.error) ? meta.error[0] : meta.error;

    return (
        <TextField
            onKeyDown={handleOnKeyDown}
            className={classNames(className)}
            label={label}
            type={type}
            inputRef={ref}
            disabled={disabled}
            placeholder={placeholder}
            fullWidth
            data-cy={dataCy}
            {...restOfProps}
            {...input}
            error={show}
            variant={variant}
            helperText={(show && errorMsg) || formHelperText}
            FormHelperTextProps={{
                className: classes.formHelper,
            }}
            InputLabelProps={{
                shrink: shrinkLabel
            }}
            InputProps={{
                onKeyPress,
                inputProps: { min, max, step },
                startAdornment: getAddon(meta, Icon),
                endAdornment: getEndAdornment(),
                classes: { input: classNames(classes.input, inputClass, { 'hide-arrows': 'number' === type }) },
            }}
        />
    );
});

materialInputGroupField.propTypes = {
    input: PropTypes.object,
    disabled: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
    type: PropTypes.string,
    label: PropTypes.string,
    inputClass: PropTypes.string,
    placeholder: PropTypes.string,
    formHelperText: PropTypes.string,
    variant: PropTypes.string,
    meta: PropTypes.object,
    step: PropTypes.string,
};

materialInputGroupField.defaultProps = {
    input: {},
    disabled: false,
    icon: '',
    inputClass: '',
    step: '',
    formHelperText: '',
    type: 'text',
    variant: 'outlined',
    label: '',
    placeholder: '',
    meta: {},
};


export const MaterialInputGroupFormikWrapper = props => {
    const [field, meta] = useField(props);

    const inputRef = useRef();

    return <MaterialInputGroupField variant="outlined" fullWidth {...field} {...props} meta={meta} ref={inputRef} />;
};

export const MaterialInputGroupField = materialInputGroupField;
export default materialInputGroupField;
