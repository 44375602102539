import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { max, map, unzip, sum } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { isMyResourceRequests, isMyVacationRequests } from 'modules/request/utils/dataFlowUtil';
import { getChartLabels } from 'modules/request/utils/requestUtil';
import { VacationYear } from 'modules/request/components';
import { Layout, Typography, Button } from '@hub-mono/ui';
import { ButtonGroup, Card } from '@material-ui/core';
import styled from 'styled-components';
import { toFixedTrimmed } from '@hub-mono/utils';

const StyledCard = styled(Card)`
    padding: 24px 16px;
    border: 1px solid rgba(54, 64, 74, 0.08);
    flex: ${props => props.flex};
`;

const StyledButtonGroup = styled(ButtonGroup)`
    .MuiButton-root {
        height: 35px;
    }
`;

const getChartValues = (statistics, chartType, status, companyAvgDailyCapacity) => {
    const vacation = statistics.getIn([chartType, status]).toJSON();
    const toil = statistics.getIn(['toil', chartType, status])?.toJSON() || [];
    const total = vacation.map((val, index) => {
        return val + toFixedTrimmed((toil[index] || 0) / companyAvgDailyCapacity, 2);
    });

    return {
        vacation,
        toil,
        total,
    };
};

class BarChart extends PureComponent {
    static propTypes = {
        changeChartType: PropTypes.func,
        requestModel: PropTypes.object.isRequired,
        statistics: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        weekStartDay: PropTypes.number.isRequired,
        companyAvgDailyCapacity: PropTypes.number,
    };

    static defaultProps = {
        changeChartType: null,
        companyAvgDailyCapacity: 8,
    };

    formatLabels() {
        const { chartType, start, end } = this.props.requestModel;

        return getChartLabels(chartType, start, end, this.props.weekStartDay);
    }

    getChartValues() {
        const { requestModel, statistics, companyAvgDailyCapacity } = this.props;

        const { total: remainingTotal } = isMyVacationRequests(requestModel.flow)
            ? getChartValues(statistics, requestModel.chartType, 'REMAINING', companyAvgDailyCapacity)
            : { total: [] };
        const { total: estimatedTotal } = isMyVacationRequests(requestModel.flow)
            ? getChartValues(statistics, requestModel.chartType, 'ESTIMATED', companyAvgDailyCapacity)
            : { total: [] };
        const { total: approvedTotal } = getChartValues(
            statistics,
            requestModel.chartType,
            'APPROVED',
            companyAvgDailyCapacity
        );
        const { total: rejectedTotal } = getChartValues(
            statistics,
            requestModel.chartType,
            'REJECTED',
            companyAvgDailyCapacity
        );
        const { total: waitingForApprovalTotal } = getChartValues(
            statistics,
            requestModel.chartType,
            'WAITING_FOR_APPROVAL',
            companyAvgDailyCapacity
        );

        return {
            remainingTotal,
            estimatedTotal,
            approvedTotal,
            rejectedTotal,
            waitingForApprovalTotal,
        };
    }

    getChartMaxValue() {
        const { requestModel } = this.props;

        const {
            estimatedTotal,
            remainingTotal,
            approvedTotal,
            rejectedTotal,
            waitingForApprovalTotal,
        } = this.getChartValues();

        let totalsArray = [approvedTotal, waitingForApprovalTotal, rejectedTotal];

        if (isMyVacationRequests(requestModel.flow)) {
            const maxRemainingEstimated = parseInt(max(remainingTotal.concat(estimatedTotal)));
            const maxRegular = parseInt(max(map(unzip(totalsArray), sum)));

            return maxRegular > maxRemainingEstimated ? maxRegular : maxRemainingEstimated;
        }

        return parseInt(max(map(unzip(totalsArray), sum))) + 1 || 0;
    }

    dataSet() {
        const { requestModel } = this.props;

        const {
            estimatedTotal,
            remainingTotal,
            approvedTotal,
            rejectedTotal,
            waitingForApprovalTotal,
        } = this.getChartValues();

        return [
            {
                type: 'line',
                yAxisID: 'line-not-stacked',
                label: 'Remaining Days',
                fill: false,
                hidden: !isMyResourceRequests(requestModel.flow),
                data: remainingTotal,
                borderColor: 'rgba(82, 188, 220, 1)',
                lineTension: 0.2,
                borderWidth: 1,
            },
            {
                type: 'line',
                yAxisID: 'line-not-stacked',
                label: 'Estimated Remaining',
                fill: false,
                hidden: !isMyVacationRequests(requestModel.flow),
                data: estimatedTotal,
                borderColor: 'rgba(82, 188, 220, 1)',
                borderWidth: 1,
                lineTension: 0.2,
                borderDash: [10, 5],
            },
            {
                type: 'bar',
                label: 'Approved',
                data: approvedTotal,
                hidden: this.getVisibility('APPROVED'),
                backgroundColor: '#006C47',
                borderColor: '#006C47',
                borderWidth: 1,
            },
            {
                type: 'bar',
                label: 'Requested',
                data: waitingForApprovalTotal,
                hidden: this.getVisibility('WAITING_FOR_APPROVAL'),
                backgroundColor: '#FFAD1E',
                borderColor: '#FFAD1E',
                borderWidth: 1,
            },
            {
                type: 'bar',
                label: 'Rejected',
                data: rejectedTotal,
                hidden: this.getVisibility('REJECTED'),
                backgroundColor: '#AD1817',
                borderColor: '#AD1817',
                borderWidth: 1,
            },
        ];
    }

    getVisibility(filter) {
        if ('ALL' === this.props.requestModel.filter) {
            return false;
        }

        return this.props.requestModel.filter !== filter;
    }

    render() {
        const {
            title,
            flex,
            requestModel: { chartType },
            changeChartType,
        } = this.props;

        const isChartWeeks = chartType === 'weeks';

        return (
            <StyledCard elevation={0} flex={flex}>
                <Layout stack gap={16}>
                    <Layout hAlign="space-between">
                        <Layout vAlign="center" gap={10}>
                            <Typography variant="title" size="medium">
                                {title}
                            </Typography>
                            <VacationYear />
                        </Layout>
                        {changeChartType && (
                            <StyledButtonGroup>
                                <Button
                                    startIcon={isChartWeeks ? 'check' : null}
                                    variant={isChartWeeks ? 'filled' : 'outlined'}
                                    onClick={() => changeChartType('weeks')}
                                >
                                    Week
                                </Button>
                                <Button
                                    startIcon={!isChartWeeks ? 'check' : null}
                                    variant={!isChartWeeks ? 'filled' : 'outlined'}
                                    onClick={() => changeChartType('months')}
                                >
                                    Month
                                </Button>
                            </StyledButtonGroup>
                        )}
                    </Layout>
                    <div className="row">
                        <Bar
                            data={{
                                labels: this.formatLabels(),
                                datasets: this.dataSet(),
                            }}
                            height={295}
                            options={{
                                animation: {
                                    duration: 200,
                                },
                                maintainAspectRatio: false,
                                legend: {
                                    display: false,
                                },
                                scales: {
                                    xAxes: [
                                        {
                                            stacked: true,
                                            gridLines: {
                                                drawOnChartArea: false,
                                            },
                                        },
                                    ],
                                    yAxes: [
                                        {
                                            stacked: true,
                                            ticks: {
                                                beginAtZero: 0 === this.getChartMaxValue(),
                                                min: 0,
                                                max: 0 === this.getChartMaxValue() ? 1 : this.getChartMaxValue(),
                                            },
                                        },
                                        {
                                            id: 'line-not-stacked',
                                            stacked: false,
                                            display: false,
                                            ticks: {
                                                beginAtZero: 0 === this.getChartMaxValue(),
                                                min: 0,
                                                max: this.getChartMaxValue(),
                                            },
                                            type: 'linear',
                                        },
                                    ],
                                },
                            }}
                        />
                    </div>
                </Layout>
            </StyledCard>
        );
    }
}

export default BarChart;
