import moment from 'moment';
import { isUndefined } from 'lodash';
import 'moment-duration-format';
import { formatNumber } from 'utils/formatingUtil';

export const HOURS = {
    display: 'Hours (hh:mm)',
    value: 'HOURS',
    calculation: value => moment.duration({ minutes: value }).format('h:mm', { trim: false }),
    reverseCalculation: value => value * 60,
};

export const HOURS_DECIMAL = {
    display: 'Hours (decimal)',
    value: 'HOURS_DECIMAL',
    calculation: (value, avgDailyCapacity, commas) =>
        formatNumber((value || 0) / 60, !isUndefined(commas) ? commas : true),
    reverseCalculation: value => value * 60,
};

export const DAYS = {
    display: 'Days',
    value: 'DAYS',
    calculation: (value, avgDailyCapacity, commas) =>
        formatNumber((value || 0) / 60 / avgDailyCapacity, !isUndefined(commas) ? commas : true),
    reverseCalculation: (value, avgDailyCapacity) => value * 60 * avgDailyCapacity,
    ratesCalculation: (value, avgDailyCapacity) =>
        formatNumber(Math.round((value * avgDailyCapacity + Number.EPSILON) * 100) / 100, true),
};
