import { includes, without } from 'lodash';
import { DayPilot } from 'daypilot-pro-react';
import { hasRole } from 'utils/rightsUtil';
import { STATUS_ACTIVE, STATUS_ARCHIVED, TYPE_REGULAR } from 'enums/projectEnum';
import { ACTIVE, ARCHIVED, EVENT, FLOATING, MY_MANAGED_PROJECTS, MY_PROJECTS, PENDING, PLANNED } from 'enums/criteriaEnum';
import { PROJECTS } from 'modules/scheduler/enums/schedulerFilterEnum';
import { removeDPRowResourceById } from 'modules/scheduler/utils/schedulerUtil';

export default (currentItem, accountRoleRights, groupBookings, keyWords, actions) => project => {
    const projectDayPilotMenu = new DayPilot.Menu({
        theme: 'hub-context',
        cssClassPrefix: 'hub-context',
        hideOnMouseOut: false,
        useShadow: true,
        zIndex: 99,
        items: [],
    });

    if (groupBookings) {
        projectDayPilotMenu.items.push({
            text: 'Toggle grouping',
            onClick: ({ source }) => {
                let hasExpanded = 0 < source.groups.expanded().length;

                if (hasExpanded) {
                    source.groups.collapseAll();
                } else {
                    source.groups.expandAll();
                }
            },
        });
    }

    if (hasRole(accountRoleRights, 'gridFilter')) {
        projectDayPilotMenu.items.push({
            text: `Filter this ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                actions?.addFilter(PROJECTS.filterListProp, source.tags.project._id);
            },
        });
    }

    if (
        currentItem &&
        !currentItem.isSmart &&
        !currentItem.queryParams?.itemGroupIds?.length &&
        !includes([ACTIVE, PENDING, PLANNED, FLOATING, EVENT, MY_PROJECTS, MY_MANAGED_PROJECTS, ARCHIVED], currentItem.criteria) &&
        hasRole(accountRoleRights, 'settingProjectGroups')
    ) {
        projectDayPilotMenu.items.push({
            text: 'Remove from group',
            onClick: ({ source }) => {
                const isInItemIds =
                    currentItem.queryParams && includes(currentItem.queryParams.itemIds, source.tags.project._id);
                const itemIds = without(currentItem.projects, source.tags.project._id);
                const data = {
                    queryParams: {
                        itemIds,
                        groupsToAdd: [],
                        groupsToRemove: [],
                        itemsToAdd: [],
                        itemsToRemove: [source.tags.project._id],
                    },
                };
                if (!isInItemIds) {
                    data.queryParams.itemGroupIds = [];
                }
                actions?.updateProjectGroup(currentItem._id, data);
            },
        });
    }

    if (project?.hasRightsToProject && hasRole(accountRoleRights, 'settingDeleteProjects')) {
        projectDayPilotMenu.items.push({
            text: `Delete ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                if (source.tags.project && source.tags.project._id) {
                    actions?.deleteProject(source.tags.project._id);
                }
            },
        });
    }

    if (
        project.type === TYPE_REGULAR.value &&
        ((project?.hasRightsToProject && hasRole(accountRoleRights, 'settingAddEditProjects')) ||
            (project?.hasPMRightsToProject && hasRole(accountRoleRights, 'pmAddEditProjects'))) &&
        hasRole(accountRoleRights, 'settingProjectStatus')
    ) {
        const isArchived = project.status === STATUS_ARCHIVED.value;
        const newStatus = isArchived ? STATUS_ACTIVE.value : STATUS_ARCHIVED.value;
        projectDayPilotMenu.items.push({
            text: `${isArchived ? 'Unarchive' : 'Archive'} ${keyWords.projectKeyWord.toLowerCase()}`,
            onClick: ({ source }) => {
                if (source.tags.project && source.tags.project._id) {
                    actions?.updateProject(
                        source.tags.project._id,
                        {
                            project: {
                                status: newStatus,
                            },
                        },
                        () => {
                            removeDPRowResourceById(project._id);
                        }
                    );
                }
            },
        });
    }

    if (
        (project?.hasRightsToProject && hasRole(accountRoleRights, 'settingAddEditProjects')) ||
        (project?.hasPMRightsToProject && hasRole(accountRoleRights, 'pmAddEditProjects'))
    ) {
        projectDayPilotMenu.items.push({
            text: '-',
        });
        projectDayPilotMenu.items.push({
            text: `Edit ${keyWords.projectKeyWord.toLowerCase()}...`,
            onClick: ({ source }) => {
                actions?.showProjectModal(source.tags.project.type, source.tags.project._id);
            },
        });
    }

    return projectDayPilotMenu;
};
