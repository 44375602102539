import React from 'react';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Divider, withStyles } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';

export const DotsMenuButton = ({ options, disabled, variant, className, menuPosition = {}, style }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOptionClick = action => {
        action();
        handleClose();
    };

    return (
        <div className={className} style={style}>
            <div className="group-context-menu" onClick={disabled ? undefined : handleClick}>
                <MoreVert />
            </div>
            <DropdownMenu
                anchorEl={anchorEl}
                variant={variant}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    disablePadding: true,
                }}
                {...menuPosition}
            >
                {options.map(option =>
                    option.divider ? (
                        <Divider />
                    ) : (
                        <MenuItem
                            key={option.title}
                            disabled={option.disabled}
                            onClick={() => handleOptionClick(option.action)}
                        >
                            {option.title}
                        </MenuItem>
                    )
                )}
            </DropdownMenu>
        </div>
    );
};

const DropdownMenu = withStyles(() => ({
    root: {
        borderRadius: '5px',
    },
}))(Menu);

DotsMenuButton.propTypes = {
    disabled: PropTypes.bool,
    menuPosition: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            action: PropTypes.func,
            disabled: PropTypes.bool,
        })
    ),
};

DotsMenuButton.defaultProps = {
    options: [],
};
