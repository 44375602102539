import { axiosInstance } from './axiosInstance';

export const sendSuggestionEmailRequest = data =>
    axiosInstance
        .post('integration/suggestionEmail', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getProjectsRequest = (provider, identity, accounts) =>
    axiosInstance
        .get(`integration/${provider}/projects?accounts=${JSON.stringify(accounts)}&id=${identity}`)
        .then(response => {
            if (!response.statusCode || 404 !== response.statusCode) {
                return response.data;
            }

            throw new Error('Error occurred while fetching projects');
        })
        .catch(error => {
            throw error;
        });

export const getAccountsRequest = (provider, identity) =>
    axiosInstance
        .get(`integration/${provider}/accounts/${identity}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getResourcesRequest = (provider, identity, accounts) =>
    axiosInstance
        .get(`integration/${provider}/resources?accounts=${JSON.stringify(accounts)}&id=${identity}`)
        .then(response => {
            if (!response.statusCode || 404 !== response.statusCode) {
                return response.data;
            }

            throw new Error('Error occurred while fetching resources');
        })
        .catch(error => {
            throw error;
        });

export const getAuthInformationRequest = (provider, identity) =>
    axiosInstance
        .get(`integration/${provider}/authStatus/${identity}`)
        .then(response => {
            if (401 === response.status) {
                throw new Error('Unauthorized Access');
            }

            return response.data;
        })
        .catch(error => {
            throw error;
        });

export const updateResourcesRequest = (data, provider) =>
    axiosInstance
        .put(`integration/${provider}/resources`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const filterNewResourcesRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/new-resources`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const filterResourcesToUpdateRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/resources-to-update`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createNewResourcesRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/resources`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateProjectsRequest = (data, provider) =>
    axiosInstance
        .put(`integration/${provider}/projects`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const filterNewProjectsRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/new-projects`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const filterProjectsToUpdateRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/projects-to-update`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createNewProjectsRequest = (data, provider) =>
    axiosInstance
        .post(`integration/${provider}/projects`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const removeAuthorizationRequest = provider =>
    axiosInstance
        .delete(`integration/${provider}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
