import CreateRate from './createRate';
import { connect } from 'react-redux';
import { submit, isValid } from 'redux-form';
import { createRate } from 'actions/rateActions';
import { mapFormToRequest, formSchema } from 'forms/rateForm';

export default connect(
    (state, ownProps) => ({
        valid: isValid(ownProps.form || 'rateForm')(state),
        initialValues: formSchema.getDefaultValues(),
    }),
    (dispatch, ownProps) => ({
        onSubmit: data => dispatch(createRate.request(mapFormToRequest(data), ownProps.formName, ownProps.fieldName)),
        submitForm: () => dispatch(submit(ownProps.form || 'rateForm')),
    })
)(CreateRate);
