import React from 'react';
import { contains, map, isArray, filter } from 'underscore';
import { COLOR } from 'enums/customFieldEnum';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import { Header, Color } from 'modules/report/columns/styles';
import { separateData, displayValue, isHeaderDisabled, isAggregated } from 'modules/report/utils/columnHelper';
import { ANONYMIZATION_STRING, REPORTS_ITEMS_COUNT } from 'constants';

/**
 * @param {array}  customFields
 * @param {array}  columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (customFields, columnsToLoad) =>
    map(customFields, customField => ({
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        {customField.label}
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: `${customField.category.toLowerCase()}_cf_${customField._id}`,
        sortable: false,
        show: contains(columnsToLoad, `${customField.category.toLowerCase()}_cf_${customField._id}`),
        accessor: row => {
            const customFieldValue = row[`${customField.category.toLowerCase()}_cf_${customField._id}`];
            const filteredEmptyValues = isArray(customFieldValue)
                ? filter(customFieldValue, value => value)
                : customFieldValue;
            let displayedValue = '';

            if (isAggregated(filteredEmptyValues)) {
                displayedValue = REPORTS_ITEMS_COUNT.text(filteredEmptyValues.length);
            } else {
                const displayMultipleValues = isArray(filteredEmptyValues) ? (
                    filteredEmptyValues.map((value, index) =>
                        value === ANONYMIZATION_STRING ? value : <Color key={index} color={value} />
                    )
                ) : customFieldValue === ANONYMIZATION_STRING ? (
                    customFieldValue
                ) : (
                    <Color color={customFieldValue} />
                );
                displayedValue =
                    COLOR.value === customField.type ? displayMultipleValues : separateData(filteredEmptyValues || []);
            }

            return displayValue(customFieldValue, displayedValue);
        },
        minWidth: 100,
    }));
