import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TabPane } from 'reactstrap';
import { makeResourceByIdFromMap } from '../../../../selectors/resource';
import { makeProjectByIdFromMap } from '../../../../selectors/project';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalTime } from '../../../scheduler/config/events/onBeforeEventRender/booking';
import { makeSelectCategoryById } from '../../../../selectors/categoryTemplate';
import { Layout } from '../../../../shared/components/layout';
import { ButtonBase, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import classnames from 'classnames';
import { removeBookingLink } from '../../../../actions/schedulerActions';
import { showConfirmationModal, showMissingDependenciesExtensionModal } from '../../../../actions/modalActions';
import {
    selectCompanyCategoryDisplaySetting,
    selectIsDependencyExtensionInstalled,
} from '../../../../selectors/company';
import MissingExtension from '../../../../shared/warnings/missingExtension';
import { STATUS_ARCHIVED } from '../../../../enums/projectEnum';
import { deleteBookingLinkRequest } from '../../../../api/scheduler';
import { addNotification } from '../../../../actions/notificationActions';
import { formatDate } from '../../../../utils/DateUtil';
import { YEAR_MONTH_DAY_FORMAT } from '../../../../global/enums/dateFormat';
import DependencyIcon from 'assets/images/icons/multiple_stop.svg';
import { removeLinkFromScheduler, updateBookingsWithNewDependencies } from '../../../scheduler/utils/schedulerUtil';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { PrivateLabel, RequestedLabel } from '../../../scheduler/components/scheduler/styles';
import { WAITING_FOR_APPROVAL } from '../../../../enums/bookingTypeEnum';
import BookingRepeat from '../../../scheduler/components/scheduler/partials/repeat';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AlarmIcon from '@material-ui/icons/Alarm';

const Booking = ({ linkedBooking, parent, child, onDelete }) => {
    const dispatch = useDispatch();

    const resourceSelector = useMemo(() => makeResourceByIdFromMap(linkedBooking.resource?._id), [
        linkedBooking.resource?._id,
    ]);
    const projectSelector = useMemo(() => makeProjectByIdFromMap(linkedBooking.project?._id), [
        linkedBooking.project?._id,
    ]);
    const categorySelector = useMemo(() => makeSelectCategoryById(linkedBooking.categoryTemplate._id), [
        linkedBooking.categoryTemplate._id,
    ]);

    const resource = useSelector(resourceSelector);
    const project = useSelector(projectSelector);
    const category = useSelector(categorySelector);
    const categoryColorDisplay = useSelector(selectCompanyCategoryDisplaySetting);

    const categoryColor = category ? category.gridColor : '#ccc';
    const barHidden = 'CATEGORY_COLOR_LINE' !== categoryColorDisplay && linkedBooking.status !== STATUS_ARCHIVED.value;

    const classes = useBookingStyles({ linkedBooking, barHidden, categoryColor });

    const projectName = project?.name ?? '';
    const resourceName = resource?.name ?? '';

    const bookingTitle =
        linkedBooking.title && projectName ? `${linkedBooking.title} (${projectName})` : projectName || '';
    const totalTime = getTotalTime(linkedBooking);

    const handleDelete = () => {
        const parentId = parent?._id || linkedBooking._id;
        const childId = child?._id || linkedBooking._id;

        dispatch(
            showConfirmationModal(
                () =>
                    onDelete({
                        parentId,
                        childId,
                    }),
                'Delete dependency',
                `Do you really want to remove this dependency to ${bookingTitle} ${resourceName}`,
                {
                    withCancel: true,
                    confirmButtonText: 'Yes, remove it',
                    cancelButtonText: 'Cancel',
                }
            )
        );
    };

    const isApproval = linkedBooking.type === WAITING_FOR_APPROVAL.value;
    const hasTasks = 0 < linkedBooking.tasksTotal;
    const hasNote = linkedBooking.note?.message !== '';
    const displayLinkedIcon = linkedBooking.childIds?.length || linkedBooking.parentIds?.length;
    const displayDeadLine = linkedBooking.deadlineDate;

    return (
        <div className={classnames('inner-booking-text', classes.bookingText, classes.bar)}>
            {linkedBooking.isPermitted ? (
                <ButtonBase
                    onClick={handleDelete}
                    className={classes.deleteBtn}
                    disableRipple
                    disableTouchRipple
                    disableElevation
                >
                    <CancelIcon className={classes.cancelIcon} />
                </ButtonBase>
            ) : null}
            {isApproval && (
                <RequestedLabel mode={'L'}>
                    <LockOutlinedIcon className={classes.icon} />
                </RequestedLabel>
            )}
            {displayLinkedIcon ? (
                <RequestedLabel mode={'L'}>
                    <DependencyIcon className={classes.icon} />
                </RequestedLabel>
            ) : null}
            {displayDeadLine && !linkedBooking.private ? (
                <RequestedLabel mode={'L'}>
                    <AlarmIcon className={classes.icon} />
                </RequestedLabel>
            ) : null}
            <BookingRepeat isRepeat={linkedBooking.repeat && !linkedBooking.private} mode={'L'} />
            {hasTasks ? (
                <RequestedLabel mode={'L'}>
                    <FormatListBulletedOutlinedIcon className={classes.icon} />
                </RequestedLabel>
            ) : null}
            {hasNote ? (
                <RequestedLabel mode={'L'}>
                    <InsertDriveFileIcon className={classes.icon} />
                </RequestedLabel>
            ) : null}
            <div className="d-flex ml-2">
                <>
                    <div>
                        <Typography variant="subtitle2">
                            {linkedBooking.private ? (
                                <PrivateLabel className={classes.privateLabel}>
                                    <LockOutlinedIcon />
                                    <span>Private Content</span>
                                </PrivateLabel>
                            ) : (
                                `${resourceName} - ${bookingTitle}`
                            )}
                        </Typography>
                        <Typography variant="body2">
                            {totalTime}
                            {category ? ` - ${category.name}` : ''}
                        </Typography>
                        <Typography variant="body2">
                            <span>
                                {formatDate(linkedBooking.start, YEAR_MONTH_DAY_FORMAT)} -{' '}
                                {formatDate(linkedBooking.end, YEAR_MONTH_DAY_FORMAT)}
                            </span>
                        </Typography>
                    </div>
                </>
            </div>
        </div>
    );
};

export const LinkedBookingsTab = React.memo(({ tabId, booking }) => {
    const dispatch = useDispatch();

    const { childrenLinks, parentLinks, deleteLinkToParent, deleteLinkToChild } = useBookingLinks({ booking });

    const isExtensionInstalled = useSelector(selectIsDependencyExtensionInstalled);

    const classes = useTabStyles();

    const handleSeeMore = useCallback(() => {
        if (!isExtensionInstalled) {
            dispatch(showMissingDependenciesExtensionModal());
        }
    }, [dispatch, isExtensionInstalled]);

    if (!isExtensionInstalled) {
        return (
            <TabPane tabId={tabId}>
                <section className="form-fields border-bottom-0 mb-3">
                    <MissingExtension
                        additionalBtnText="Read more"
                        additionalBtnAction={handleSeeMore}
                        description="Missing dependencies extension"
                    />
                </section>
            </TabPane>
        );
    }

    return (
        <TabPane tabId={tabId}>
            <Layout hAlign="space-between" gap="8px">
                <Layout flex="0 1 400px" stack gap="4px">
                    <Typography className={classnames(classes.bookingsHeader, 'title', 'text-capitalize')}>
                        Parent dependencies
                    </Typography>
                    {parentLinks.map(parent => {
                        return (
                            <Booking
                                key={parent._id}
                                linkedBooking={parent}
                                child={booking}
                                onDelete={deleteLinkToParent}
                            />
                        );
                    })}
                    {!parentLinks?.length ? (
                        <Alert severity="info">There are no parent bookings with dependencies for this booking.</Alert>
                    ) : null}
                </Layout>
                <Layout flex="0">
                    <DependencyIcon className={classes.dependencyIcon} />
                </Layout>
                <Layout flex="0 1 400px" stack gap="4px">
                    <Typography className={classnames(classes.bookingsHeader, 'title', 'text-capitalize')}>
                        Child dependencies
                    </Typography>
                    {childrenLinks.map(child => {
                        return (
                            <Booking
                                key={child._id}
                                linkedBooking={child}
                                parent={booking}
                                onDelete={deleteLinkToChild}
                            />
                        );
                    })}
                    {!childrenLinks?.length ? (
                        <Alert severity="info">There are no child bookings with dependencies on this booking.</Alert>
                    ) : null}
                </Layout>
            </Layout>
        </TabPane>
    );
});

const useBookingLinks = ({ booking }) => {
    const dispatch = useDispatch();
    const [parentLinks, setParentLinks] = useState(booking.parents || []);
    const [childrenLinks, setChildrenLinks] = useState(booking.children || []);

    const manuallyRemoveLinkFromScheduler = useCallback(({ parentId, childId }) => {
        removeLinkFromScheduler({ parentId, childId });
    }, []);

    useEffect(() => {
        if (booking._id) {
            setParentLinks(booking.parents || []);
            setChildrenLinks(booking.children || []);
        }
    }, [booking._id, booking.children, booking.parents]);

    const deleteLinkToParent = useCallback(
        ({ parentId, childId }) => {
            setParentLinks(prevParentLinks => {
                return prevParentLinks.map(link => {
                    return {
                        ...link,
                        deleted: link.deleted || link._id === parentId,
                    };
                });
            });

            deleteBookingLinkRequest({ parentId, childId })
                .then(() => {
                    updateBookingsWithNewDependencies({ parentId, childId, remove: true });
                    manuallyRemoveLinkFromScheduler({ parentId, childId });
                    dispatch(
                        removeBookingLink({
                            parentId,
                            childId,
                        })
                    );
                    dispatch(
                        addNotification({
                            message: `Dependency was removed`,
                            type: 'success',
                        })
                    );
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            message: e?.data?.message ?? `We couldn't delete dependency`,
                            type: 'danger',
                        })
                    );

                    setParentLinks(prevParentLinks => {
                        return prevParentLinks.map(link => {
                            return {
                                ...link,
                                deleted: !(link._id === parentId || link._id === childId),
                            };
                        });
                    });
                });
        },
        [dispatch, manuallyRemoveLinkFromScheduler]
    );

    const deleteLinkToChild = useCallback(
        ({ parentId, childId }) => {
            setChildrenLinks(prevChildrenLinks => {
                return prevChildrenLinks.map(link => {
                    return {
                        ...link,
                        deleted: link.deleted || link._id === childId,
                    };
                });
            });

            deleteBookingLinkRequest({ parentId, childId })
                .then(() => {
                    manuallyRemoveLinkFromScheduler({ parentId, childId });
                    updateBookingsWithNewDependencies({ parentId, childId, remove: true });
                    dispatch(
                        removeBookingLink({
                            parentId,
                            childId,
                        })
                    );

                    dispatch(
                        addNotification({
                            message: `Dependency was removed`,
                            type: 'success',
                        })
                    );
                })
                .catch(e => {
                    dispatch(
                        addNotification({
                            message: e?.data?.message ?? `We couldn't delete dependency`,
                            type: 'danger',
                        })
                    );

                    setChildrenLinks(prevChildrenLinks => {
                        return prevChildrenLinks.map(link => {
                            return {
                                ...link,
                                deleted: !(link._id === parentId || link._id === childId),
                            };
                        });
                    });
                });
        },
        [dispatch, manuallyRemoveLinkFromScheduler]
    );

    const parentLinksFiltered = useMemo(() => {
        return parentLinks
            .filter(({ deleted }) => !deleted)
            .sort((a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1));
    }, [parentLinks]);

    const childrenLinksFiltered = useMemo(() => {
        return childrenLinks
            .filter(({ deleted }) => !deleted)
            .sort((a, b) => (new Date(a.start) > new Date(b.start) ? 1 : -1));
    }, [childrenLinks]);

    return {
        parentLinks: parentLinksFiltered,
        childrenLinks: childrenLinksFiltered,
        deleteLinkToParent,
        deleteLinkToChild,
    };
};

const useBookingStyles = makeStyles(() => ({
    bar: ({ barHidden, categoryColor }) =>
        barHidden
            ? {
                  border: `1px solid #fff`,
              }
            : {
                  border: `1px solid #fff`,
                  borderTop: `5px solid ${categoryColor}`,
              },
    bookingText: ({ linkedBooking }) => ({
        backgroundColor: linkedBooking?.backgroundColor,
        display: 'flex',
        alignItems: 'center',
        color: '#ffffff',
        borderRadius: 4,
        maxWidth: 400,
        padding: '12px 10px',
        position: 'relative',
    }),
    deleteBtn: {
        height: 'fit-content',
        width: 'fit-content',
        position: 'absolute',
        top: '-10px',
        right: '-8px',
        color: '#000',
    },
    cancelIcon: {
        backgroundColor: '#fff',
        borderRadius: '100%',
    },
    privateLabel: {
        width: 'fit-content',
        marginBottom: 4,
        paddingRight: 8,
    },
    icon: {
        fill: '#232323',
        width: 16,
        height: 16,
    },
}));

const useTabStyles = makeStyles(() => ({
    dependencyIcon: {
        fontSize: 48,
        marginTop: 36,
    },
    bookingsHeader: {
        marginBottom: 16,
    },
    scrollBarWrapper: {
        paddingTop: 9,
    },
}));
