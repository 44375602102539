import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { usePhases } from 'modules/scheduler/hooks';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import { updateBookingSelection } from 'actions/schedulerActions';
import { useProjectAction } from 'hooks';
import { ObjectID } from 'bson';
import PhaseMode from './phaseMode';
import MilestoneMode from './milestoneMode';
import DatesMode from './datesMode';
import { mapMilestonesToBE, mapPhasesToBE } from '../../../../../../../../services/project';
import { NestedMenuItemManager } from '../../../../../../../../shared/nestedMenuItem/context';
import { selectCurrentSelectedBooking } from '../../../../../../../../selectors/scheduler';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

const parseCurrentSelectedBooking = currentSelectedBooking => ({
    ...currentSelectedBooking,
    start: new Date(removeUTCZuluFromDateTimestamp(currentSelectedBooking.start)),
    end: new Date(removeUTCZuluFromDateTimestamp(currentSelectedBooking.end)),
});

const LegacyProjectGroupMode = React.forwardRef(({ onClose, onDelete }, schedulerRef) => {
    const dispatch = useDispatch();
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);
    const projects = useSelector(state => state.projectReducer.projects);

    const project = useMemo(() => projects.find(({ _id }) => _id === currentSelectedBooking.projectId), [
        currentSelectedBooking,
        projects,
    ]);

    const { updateMilestone } = useMilestones(schedulerRef, onClose);
    const { updatePhase } = usePhases(schedulerRef, onClose);
    const { updateProject } = useProjectAction(schedulerRef);

    const parsedBooking = useMemo(() => parseCurrentSelectedBooking(currentSelectedBooking), [currentSelectedBooking]);

    const handleDelete = () => {
        onDelete();
        dispatch(updateBookingSelection.request());
    };

    useEffect(() => {
        if (!project || !project._id) {
            onClose && onClose();
        }
    }, [onClose, project]);

    const onUpdateMilestone = useCallback(
        data => {
            if (data.repeat) {
                const repeatId = new ObjectID().toString();
                const mappedMilestone = mapMilestonesToBE([{ ...data, repeatId }], project.milestones, project._id);
                if (mappedMilestone && 0 < mappedMilestone.length) {
                    mappedMilestone[0]._id = currentSelectedBooking.id;
                    updateMilestone(project._id, mappedMilestone);
                }
            } else {
                updateMilestone(project._id, { ...data, _id: currentSelectedBooking.id });
            }
        },
        [currentSelectedBooking.id, project._id, project.milestones, updateMilestone]
    );

    const onUpdatePhase = useCallback(
        data => {
            if (data.repeat) {
                const repeatId = new ObjectID().toString();
                const phase = { ...data, start: new Date(data.start), end: new Date(data.end), repeatId };

                const mappedPhase = mapPhasesToBE([phase], project.phases, companyStartEndTimes);
                if (mappedPhase && 0 < mappedPhase.length) {
                    mappedPhase[0]._id = currentSelectedBooking.id;
                    updatePhase(currentSelectedBooking.id, project._id, mappedPhase);
                }
            } else {
                updatePhase(currentSelectedBooking.id, project._id, { ...data, _id: currentSelectedBooking.id });
            }
        },
        [project.phases, project._id, companyStartEndTimes, currentSelectedBooking.id, updatePhase]
    );

    if (currentSelectedBooking.milestone) {
        return (
            <NestedMenuItemManager>
                <MilestoneMode
                    project={project}
                    currentSelectedBooking={parsedBooking}
                    updateMilestone={onUpdateMilestone}
                    deleteMilestone={handleDelete}
                />
            </NestedMenuItemManager>
        );
    }

    if (currentSelectedBooking.phase) {
        return (
            <NestedMenuItemManager>
                <PhaseMode
                    project={project}
                    currentSelectedBooking={parsedBooking}
                    updatePhase={onUpdatePhase}
                    deletePhase={handleDelete}
                />
            </NestedMenuItemManager>
        );
    }

    if (project && project._id) {
        return (
            <NestedMenuItemManager>
                <DatesMode
                    project={project}
                    currentSelectedBooking={parsedBooking}
                    onClose={onClose}
                    updateProject={updateProject}
                    deleteDates={handleDelete}
                />
            </NestedMenuItemManager>
        );
    }

    return null;
});

LegacyProjectGroupMode.propTypes = {
    onClose: PropTypes.func,
};

LegacyProjectGroupMode.defaultProps = {
    onClose: null,
};

export default LegacyProjectGroupMode;
