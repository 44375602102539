import styled from 'styled-components';
import { Row } from 'reactstrap';

export const DateRangeButtons = styled.div`
    margin-top: 5px;
    button {
        font-size: 13px;
        &.dropdown-toggle {
            margin-bottom: 5px;
            background: none;
            border: none;
            color: #5a5a5a;
        }
    }
`;

export const Wrapper = styled.div`
    width: ${props => ('inherit' !== props.width ? `${props.width}px` : 'inherit')};
    margin: ${props => (props.centered ? '0 auto 20px auto' : 'initial')};
`;

export const ButtonsRowWrapper = styled(Row)`
    justify-content: ${({ centered }) => (centered ? 'center' : 'initial')};
`;
