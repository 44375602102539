import Schema from 'form-schema-validation';
import { formatNumber } from 'utils/formatingUtil';
import { errorMessages } from 'utils/schemaUtil';
import { minValidator, requiredValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        state: {
            type: String,
            required: true,
            defaultValue: 'PERCENTAGE',
        },
        percentage: {
            type: String,
            required: true,
            defaultValue: '100',
            validators: [minValidator(0, 'Value cannot be negative'), requiredValidator('This field is required')]
        },
        hours: {
            type: String,
            required: true,
            validators: [minValidator(0, 'Value cannot be negative'), requiredValidator('This field is required')]
        },
        total: {
            type: String,
            required: true,
            validators: [minValidator(0, 'Value cannot be negative'), requiredValidator('This field is required')],
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = currentSelectedBooking => {
    let actualBookingMinutes;
    // https://hubplanner.atlassian.net/browse/HUB-8796 - in comments why modify for PERCENTAGE case
    if(currentSelectedBooking.state === 'PERCENTAGE') {
        actualBookingMinutes = currentSelectedBooking.totalBucketMinutesAllocation * (currentSelectedBooking.percentAllocation / 100)
    } else {
        actualBookingMinutes = currentSelectedBooking.totalBucketMinutesAllocation
    }

    return Object.assign({}, formSchema.getDefaultValues(), {
        state: currentSelectedBooking.state,
        percentage: currentSelectedBooking.percentAllocation.toString(),
        hours: formatNumber(currentSelectedBooking.minutesPerDay / 60).toString(),
        total: formatNumber(actualBookingMinutes / 60).toString(),
    });
}

export const mapFormToRequest = values => ({
    state: values.state,
    percentAllocation: formatNumber(values.percentage),
    minutesPerDay: formatNumber(values.hours * 60),
    totalBucketMinutesAllocation: formatNumber(values.total * 60),
});
