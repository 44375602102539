import React, { useEffect, useMemo, useState } from 'react';
import { Layout, SearchBar, Table, Typography, Pagination, Divider, Button, useDebouncedValue } from '@hub-mono/ui';
import { formatDate, DATE_FORMATS } from '@hub-mono/utils';
import { Card } from '@material-ui/core';
import styled from 'styled-components';
import { ActionCell } from './actionCell';
import { CsvDownloader } from './actionButtons/CSVDownloader';
import { requestTableCSVColumns } from 'modules/request/utils/csvColumns';
import { EditSelected } from './editSelected';
import PrintButton from './actionButtons/printButton';
import Requestor from './requestor';
import RequestProvider from './requestProvider';
import RequestNotes from './requestNotes';
import Approver from './approver';
import { APPROVED, REJECTED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { isMyRequests } from 'modules/request/utils/dataFlowUtil';
import { useDispatch, useSelector } from 'react-redux';
import { deleteBookings } from 'actions/bookingActions';
import { createCySelector } from '../../../../utils/cypressUtil';
import { useBookingTitle } from '../../hooks/useBookingTitle';
import { isAbleToApproveRejectVacationOrRequest } from 'modules/request/utils/permissions';
import { getCompanySettings, getResourceRoleRight } from 'selectors/company';

const StyledCard = styled(Card)`
    padding: 24px 8px;

    &.MuiPaper-root {
        background-color: ${props => props.theme.designTokens.color.m3.sys.light['surface'].value};
        border-radius: 16px;
    }
`;

const SelectedContainer = styled(Layout)`
    padding-left: 16px;
`;

const StateCell = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 33.75px;
    background: black;

    &.approved,
    &.scheduled {
        background: #71b158;
    }

    &.waiting_for_approval {
        background: #eda33b;
    }

    &.rejected {
        background: #dc5949;
    }
`;

const resourceName = (row, resourceId) => {
    if (row.resource === resourceId) return `My Request`;
    if (row.resource) return `${row.resourceFirstName} ${row.resourceLastName}`;
    return 'No Name';
};

const RequestTable = props => {
    const dispatch = useDispatch();
    const [pageIndex, setPageIndex] = useState(0);
    const [selected, setSelected] = useState({});
    const [sorting, setSorting] = useState([
        {
            id: 'Request',
            desc: false,
        },
    ]);

    const {
        bookings,
        requestModel,
        resourceId,
        deleteRequest,
        editRequest,
        approveOrRejectModal,
        approveOrRejectRequest,
        reSubmitRequest,
    } = props;

    const { selectedBookingsAll, selectedBookingsWaitingForApproval } = useMemo(() => {
        const all = Object.keys(selected).map(key => bookings[key]);

        const waitingForApproval = all.filter(el => el?.type === WAITING_FOR_APPROVAL.value);

        return { selectedBookingsAll: all, selectedBookingsWaitingForApproval: waitingForApproval };
    }, [bookings, selected]);

    const { vacationId } = useSelector(getCompanySettings);
    const resourceRoleRights = useSelector(getResourceRoleRight);

    const isAbleToApproveReject = isAbleToApproveRejectVacationOrRequest(
        selectedBookingsAll,
        resourceId,
        resourceRoleRights,
        vacationId
    );

    const clearSelected = () => {
        setSelected({});
    };

    const columns = useMemo(
        () => [
            {
                header: 'Request',
                accessorFn: row => {
                    return resourceName(row, resourceId);
                },
                sortingFn: (rowA, rowB) =>
                    rowA.original.createdDate < rowB.original.createdDate
                        ? 1
                        : rowA.original.createdDate > rowB.original.createdDate
                        ? -1
                        : 0,
                size: 200,
                cell: props => {
                    return <DetailsCell {...props} resourceId={resourceId} />;
                },
            },
            {
                header: 'State',
                accessorKey: 'type',
                meta: {
                    className: 'center',
                    headerClassName: 'center',
                },
                size: 80,
                cell: props => (
                    <Layout hAlign="center">
                        <StateCell className={props.getValue().toLowerCase()} />
                    </Layout>
                ),
            },
            {
                header: 'Actions',
                id: 'action',
                meta: {
                    className: 'center',
                    headerClassName: 'center',
                },
                size: 100,
                cell: props => {
                    return (
                        <ActionCell
                            resourceId={resourceId}
                            booking={props.row.original}
                            deleteRequest={deleteRequest}
                            editRequest={editRequest}
                            approveOrRejectModal={approveOrRejectModal}
                            approveOrRejectRequest={approveOrRejectRequest}
                            requestModel={requestModel}
                            reSubmitRequest={reSubmitRequest}
                            clearSelected={clearSelected}
                        />
                    );
                },
            },
        ],
        [
            resourceId,
            editRequest,
            deleteRequest,
            requestModel,
            approveOrRejectModal,
            approveOrRejectRequest,
            reSubmitRequest,
        ]
    );

    const { value: filterText, setDebouncedValue: setFilterText, debounced: debouncedSearchText } = useDebouncedValue({
        defaultValue: null,
    });

    useEffect(() => {
        setFilterText(null);
        setPageIndex(0);
        setSelected({});
    }, [requestModel.flow, requestModel.subFlow, requestModel.resourceId, requestModel.filter, setFilterText]);

    const approveOrRejectSelected = type => {
        approveOrRejectRequest(selectedBookingsWaitingForApproval, type);
        clearSelected();
    };

    const columnVisibility = {
        action: false,
    };

    const paginationExport = {
        pageIndex,
        pageSize: bookings.length,
    };

    const pagination = {
        pageIndex,
        pageSize: 10,
    };

    return (
        <StyledCard elevation={0}>
            <Layout stack gap={16}>
                <Layout pr={16} pl={16}>
                    <Typography variant="title" size="large">
                        Request Status
                    </Typography>
                </Layout>
                <Layout
                    pt="--Spacing-200"
                    pr="--Spacing-200"
                    pb="--Spacing-200"
                    pl="--Spacing-200"
                    hAlign="space-between"
                    vAlign="center"
                >
                    <Layout stack flex="0 0 300px">
                        <SearchBar placeholder="Filter Requests" value={filterText} onChange={setFilterText} />
                    </Layout>
                    <Layout gap="--Spacing-100">
                        <Pagination
                            pageIndex={pageIndex}
                            pageSize={10}
                            itemsAmount={bookings.length}
                            onChange={setPageIndex}
                        />
                        <div>
                            <Divider type="vertical" />
                        </div>
                        <CsvDownloader
                            columns={requestTableCSVColumns}
                            data={bookings}
                            disabled={!bookings.length}
                            filename="HUB Planner - Requests"
                        />
                        <PrintButton color="bordered" disabled={!bookings.length}>
                            <Table
                                data={bookings}
                                columns={columns}
                                filterText={debouncedSearchText}
                                enableSelection
                                rowSelection={selected}
                                noDataText="There are no requests for this date range."
                                noFilterDataText="No matching records found"
                                sorting={sorting}
                                columnVisibility={columnVisibility}
                                pagination={paginationExport}
                            />
                        </PrintButton>
                    </Layout>
                </Layout>
                <Layout stack gap="--Spacing-50">
                    {Object.keys(selected).length > 0 && (
                        <SelectedContainer vAlign="center" gap="--Spacing-50">
                            <Typography variant="body" size="small">
                                {Object.keys(selected).length} selected
                            </Typography>
                            {!isMyRequests(requestModel.flow) &&
                                approveOrRejectSelected &&
                                selectedBookingsWaitingForApproval.length > 0 &&
                                isAbleToApproveReject && (
                                    <>
                                        <Button
                                            variant="text"
                                            endIcon="check"
                                            onClick={() => approveOrRejectSelected(APPROVED.value)}
                                        >
                                            Approve Selected
                                        </Button>
                                        <Button
                                            variant="text"
                                            endIcon="close"
                                            onClick={() => {
                                                approveOrRejectSelected(REJECTED.value);
                                            }}
                                        >
                                            Reject Selected
                                        </Button>
                                    </>
                                )}
                            {Object.keys(selected).length === 1 && (
                                <EditSelected
                                    editRequest={editRequest}
                                    booking={selectedBookingsAll[0]}
                                    resourceId={resourceId}
                                    requestModel={requestModel}
                                    clearSelected={clearSelected}
                                />
                            )}
                            {isAbleToApproveReject && (
                                <Button
                                    variant="text"
                                    endIcon="delete"
                                    onClick={() => {
                                        dispatch(
                                            deleteBookings.request(
                                                selectedBookingsAll.map(el => el._id),
                                                true
                                            )
                                        );
                                        clearSelected();
                                    }}
                                >
                                    Remove Selected
                                </Button>
                            )}
                        </SelectedContainer>
                    )}
                    <Layout pt="--Spacing-200" pr="--Spacing-200" pb="--Spacing-200" pl="--Spacing-200">
                        <Table
                            data={bookings}
                            columns={columns}
                            filterText={debouncedSearchText}
                            enableSelection
                            rowSelection={selected}
                            setRowSelection={setSelected}
                            noDataText="There are no requests for this date range."
                            noFilterDataText="No matching records found"
                            sorting={sorting}
                            setSorting={setSorting}
                            pagination={pagination}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </StyledCard>
    );
};

const DetailsCell = props => {
    const daysAmount = props.row.original.details.workDaysCount;
    const resourceId = props.resourceId;
    const booking = props.row.original;

    const bookingTitle = useBookingTitle({
        booking,
        loggedResourceId: resourceId,
    });

    return (
        <div
            data-cy={`[details-${createCySelector(bookingTitle)}]-[start=${formatDate(
                booking.start,
                DATE_FORMATS.YEAR_MONTH_DAY_FORMAT
            )}]-[end=${formatDate(booking.end, DATE_FORMATS.YEAR_MONTH_DAY_FORMAT)}]`}
        >
            <Layout stack>
                <Typography variant="label" size="large" color="on-surface">
                    {bookingTitle}
                </Typography>
                <Layout vAlign="center" gap="--Spacing-50">
                    <Typography variant="label" size="large" color="neutral40">
                        {daysAmount} {daysAmount === 1 ? 'Day' : 'Days'} Requested
                    </Typography>
                    <Approver booking={props.row.original} />
                </Layout>
                <Typography variant="body" size="small" color="neutral40">
                    <Requestor booking={props.row.original} loggedResourceId={resourceId} />
                    <RequestProvider booking={props.row.original} loggedResourceId={resourceId} />
                    &nbsp;
                    {props.row.original.project
                        ? props.row.original.title
                            ? props.row.original.projectName + ' (' + props.row.original.title + ')'
                            : props.row.original.projectName
                        : 'No Name'}{' '}
                </Typography>
                <Layout gap={2}>
                    <Typography variant="body" size="small" color="neutral40">
                        Dates:
                    </Typography>
                    <Typography variant="label" size="medium" prominent color="neutral40">
                        {formatDate(props.row.original.start, DATE_FORMATS.MONTH_DAY_YEAR)} -{' '}
                        {formatDate(props.row.original.end, DATE_FORMATS.MONTH_DAY_YEAR)}
                    </Typography>
                </Layout>
                <Layout gap={2}>
                    <Typography variant="body" size="small" color="neutral40">
                        Category:
                    </Typography>
                    <Typography variant="label" size="medium" prominent color="neutral40">
                        {props.row.original.categoryName}
                    </Typography>
                </Layout>
                <Layout gap={2}>
                    <Typography variant="body" size="small" color="neutral40">
                        Request Submitted:
                    </Typography>
                    <Typography variant="label" size="medium" prominent color="neutral40">
                        {formatDate(props.row.original.createdDate, DATE_FORMATS.MONTH_DAY_YEAR)}
                    </Typography>
                </Layout>{' '}
                <RequestNotes approvalInfo={props.row.original.approvalInfo} />
            </Layout>
        </div>
    );
};

export default RequestTable;
