/* eslint-env browser */
import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useWindowDimension } from 'hooks';
import { getCompanyTree } from 'actions/companyTreeActions';
import Sidebar from 'modules/sidebar/container';
import { Row } from 'reactstrap';
import RouteValidator from 'services/routeValidator/validator';
import Scrollbar from 'shared/scrollbar';
import Header from 'modules/header';

// order of bootstrapTheme and react-widgets was changed because
// localhost:3000 was not having same styles order like after deploy
import 'react-widgets/dist/css/react-widgets.css';
import 'assets/styles/bootstrapTheme.css';
import 'react-virtualized/styles.css';
import 'assets/styles/common/react-table.css';
import 'assets/styles/common/day-picker.css';
import 'assets/styles/common/react-metismenu-standart.min.css';
import 'assets/styles/common/main.css';
import 'assets/styles/common/theme.css';
import 'assets/styles/common/buttons.css';
import 'assets/styles/common/dropdowns.css';
import 'assets/styles/common/modals.css';
import 'assets/styles/common/sidebar.css';
import 'assets/styles/settings.css';

const Template = ({ component: Component, isFullScreen, subComponent, child, helpAreaOptions, ...rest }) => {
    const dispatch = useDispatch();
    const { windowHeight, windowWidth } = useWindowDimension();
    useEffect(() => {
        dispatch(getCompanyTree.request());
    }, [dispatch]);

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className={rest.location.pathname.split('/')[1]}>
                    <Header />
                    <div className="container-fluid">
                        <Row>
                            <div className={classNames('col-sm-3 col-md-4 col-lg-3 col-xl-2')}>
                                <Sidebar
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    isFullScreen={isFullScreen}
                                    isSettingsPage={true}
                                />
                            </div>
                            <Scrollbar
                                height={windowHeight - (991 >= windowWidth ? 42 : 51)}
                                className={classNames('col-sm-9 col-md-8 col-lg-9 col-xl-10')}
                            >
                                <div className="main">
                                    {Component && <Component {...matchProps} />}
                                    {child && (
                                        <Switch>
                                            {child.map(route => (
                                                <RouteValidator
                                                    key={route.path}
                                                    subComponent={subComponent}
                                                    helpAreaOptions={helpAreaOptions}
                                                    {...route}
                                                />
                                            ))}
                                        </Switch>
                                    )}
                                </div>
                            </Scrollbar>
                        </Row>
                    </div>
                </div>
            )}
        />
    );
};

Template.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    subComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    helpAreaOptions: PropTypes.object,
    title: PropTypes.string,
    path: PropTypes.string.isRequired,
};

Template.defaultProps = {
    component: null,
    subComponent: null,
    helpAreaOptions: {},
};

export default Template;
