import { DropdownMenu as DefaultDropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import styled from 'styled-components';

export const DropdownMenu = styled(DefaultDropdownMenu)`
    width: ${props => (props.width ? `${props.width}px` : '200px')};

    & > a,
    & li > a {
        color: #000 !important;
    }
`;

export const SubMenu = styled(DropdownToggle)`
    border-radius: 0;
    margin-left: 0;
    text-align: left;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 30px;
    color: #000 !important;
`;

export const DropdownButton = styled(DropdownItem)`
    padding: 0.25rem 1rem !important;
    height: ${props => (props.height ? props.height : '30px')};
    &.active {
        border-left: 5px solid #efefef !important;
        background-color: #efefef !important;
        color: #000 !important;
    }
    &:focus {
        outline: none !important;
    }
`;
