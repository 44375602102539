import { axiosInstance } from './axiosInstance';
import { stringify } from 'services/queryString';

export const getRequestData = ({ type, ...reportObject }) =>
    axiosInstance
        .get(`event/stats/${type}?` + stringify(reportObject))
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getRequestResourceData = ({ type, ...reportObject }) =>
    axiosInstance
        .get(`event/stats/resources/${type}?` + stringify(reportObject))
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getNewReportData = (reportObject, queryParams) =>
    axiosInstance
        .post(`newreport2/reports?${stringify(queryParams)}`, reportObject, {
            timeout: 120000,
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createReportRequest = data =>
    axiosInstance
        .post('newreport2/report', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateReportRequest = (id, data) =>
    axiosInstance
        .patch(`newreport2/report/${id}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateReportGroupsRequest = (id, data) =>
    axiosInstance
        .put(`newreport2/report/${id}/groups`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const exportReportRequest = (reportObject, queryParams) =>
    axiosInstance
        .post(`newreport2/download?${stringify(queryParams)}`, reportObject, {
            responseType: 'blob',
            timeout: 60000 * 5, //5 mins
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const shareReportRequest = data =>
    axiosInstance
        .post('newreport2/share-report', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getSharedReportRequest = token =>
    axiosInstance
        .get(`newreport2/shared-report/${token}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateFavouritesRequest = (templateId, data) =>
    axiosInstance
        .patch(`newreport2/report/${templateId}/favourites`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const reportTemplatesRequest = queryParams =>
    axiosInstance
        .get(`newreport2/reports?${stringify(queryParams)}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteReportRequest = id =>
    axiosInstance
        .delete(`newreport2/report/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const sendSuggestReport = data =>
    // TODO Waiting for backend, change to proper endpoint
    axiosInstance
        .post(`newreport2/report/suggest`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getReportsGroupsRequest = () =>
    axiosInstance
        .get('reportGroup/')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const addReportsGroupRequest = data =>
    axiosInstance
        .post('reportGroup/', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateReportsGroupRequest = ({ groupId, data }) =>
    axiosInstance
        .put(`reportGroup/${groupId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const removeReportsGroupRequest = ({ groupId }) =>
    axiosInstance
        .delete(`reportGroup/${groupId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
