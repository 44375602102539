/*
    IMPORTANT
    All code in this file MUST be documented
*/

/**
 * Custom Error to throw. This is meant to be used when some data is not valid during data flows.
 */
export class HPValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'HP-ValidationError';
    }
}
