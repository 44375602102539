import { chain, sortBy } from 'underscore';
import * as actionTypes from './../actions/actionTypes';
import createReducer from './helpers/createReducer';
import { updateObject, updateItemInArray } from './helpers/updater';

const initialBillingState = {
    billingPlans: [],
    billingExtensions: [],
    billingRegion: {},
    billingView: {
        billableResourceCount: {},
        accountResourceLimit: 0,
        projectManagerCount: 0,
        billing: {},
        resourceCountRestriction: '',
        additionalResourceLimit: 0,
        accountResourceLiteLimit: 0,
        additionalResourceLiteLimit: 0,
    },
    clientToken: '',
    actionInProgress: false,
    paymentInformation: {},
};

const setBillingPlans = (state, action) => {
    let sortedPlans = chain(action.payload.billingPlans)
        .map(billingPlan => ({
            ...billingPlan,
            annually: {
                ...billingPlan.annually,
                price: '?' !== billingPlan.annually.price ? billingPlan.annually.price : 0,
            },
        }))
        .sortBy(billingPlan => billingPlan.annually.price)
        .value();
    if (action.payload.billingPlans.length) {
        sortedPlans.push(sortedPlans.shift());
    }

    return updateObject(state, {
        billingPlans: sortedPlans,
        billingRegion: action.payload.billingRegion,
    });
};

const setBillingExtensions = (state, action) =>
    updateObject(state, {
        billingExtensions: sortBy(action.payload.extensions, extension => extension.associatedPlan),
    });

const updateBillingExtensions = (state, action) =>
    updateObject(state, {
        billingExtensions: updateItemInArray(
            state.billingExtensions,
            action.payload.extension.extensionTemplateId,
            extension => ({
                ...extension,
                extensionStatus: updateObject(extension.extensionStatus, action.payload.extension),
            })
        ),
    });

const _sortExtensions = updatedState => ({
    ...updatedState,
    billingExtensions: sortBy(updatedState.billingExtensions, extension => extension.associatedPlan),
});

const updateBillingExtensionStateRequest = (state, action) => {
    let updatedState = updateObject(state, {
        billingExtensions: updateItemInArray(state.billingExtensions, action.payload.templateId, extension => ({
            ...extension,
            extensionStatus: {
                ...extension.extensionStatus,
                installed: !extension.extensionStatus.installed,
            },
        })),
    });

    return _sortExtensions(updatedState);
};

const updateBillingExtensionStateSuccess = (state, action) => {
    let updatedState = updateObject(state, {
        billingExtensions: updateItemInArray(state.billingExtensions, action.payload.templateId, extension => ({
            ...extension,
            extensionStatus: {
                ...extension.extensionStatus,
                ...action.payload.data,
            },
        })),
    });
    return _sortExtensions(updatedState);
};

const setClientToken = (state, action) =>
    updateObject(state, {
        clientToken: action.payload.clientToken,
    });

const clearClientToken = state =>
    updateObject(state, {
        clientToken: '',
    });

const setPaymentInformation = (state, action) =>
    updateObject(state, {
        paymentInformation: action.payload.paymentInformation,
    });

const setBillingView = (state, action) =>
    updateObject(state, {
        billingView: {
            billableResourceCount: action.payload.billingView[0],
            accountResourceLimit: action.payload.billingView[1],
            projectManagerCount: action.payload.billingView[2],
            billing: action.payload.billingView[3],
            resourceCountRestriction: action.payload.billingView[4],
            additionalResourceLimit: action.payload.billingView[5],
            accountResourceLiteLimit: action.payload.billingView[6],
            additionalResourceLiteLimit: action.payload.billingView[7],
        },
    });

const setDefault = (state, action) =>
    updateObject(state, {
        [action.payload.propertyName]: initialBillingState[action.payload.propertyName],
    });

const updateActionState = (state, action) =>
    updateObject(state, {
        actionInProgress: action.payload.actionState,
    });

export default createReducer(initialBillingState, {
    [actionTypes.GET_BRAINTREE_CLIENT_TOKEN['REQUEST']]: clearClientToken,
    [actionTypes.GET_BILLING_PLANS['SUCCESS']]: setBillingPlans,
    [actionTypes.GET_BILLING_EXTENSIONS['SUCCESS']]: setBillingExtensions,
    [actionTypes.UNSUBSCRIBE_EXTENSION['SUCCESS']]: updateBillingExtensions,
    [actionTypes.UPDATE_EXTENSION_STATE['REQUEST']]: updateBillingExtensionStateRequest,
    [actionTypes.UPDATE_EXTENSION_STATE['SUCCESS']]: updateBillingExtensionStateSuccess,
    [actionTypes.GET_BRAINTREE_CLIENT_TOKEN['SUCCESS']]: setClientToken,
    [actionTypes.GET_BILLING_VIEW['SUCCESS']]: setBillingView,
    [actionTypes.GET_PAYMENT_INFORMATION['SUCCESS']]: setPaymentInformation,
    [actionTypes.RESET_TO_DEFAULT_BY_PROP]: setDefault,
    [actionTypes.DELETE_SUBSCRIPTION['REQUEST']]: updateActionState,
    [actionTypes.DELETE_SUBSCRIPTION['SUCCESS']]: updateActionState,
    [actionTypes.UPDATE_ACTION_STATE]: updateActionState,
});
