import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { hideModal as hideModalAction } from 'actions/modalActions';
import Button from 'shared/components/mui-button';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    Typography,
    makeStyles,
} from '@material-ui/core';
import { Layout } from 'shared/components/layout/index';
import { useModalOffset } from 'hooks/useModalOffset';
import { Close } from '@material-ui/icons';

const ConfirmationModalNew = ({ title, message = '', onConfirm, additional }) => {
    const {
        withCancel = true,
        onReject,
        confirmButtonText = 'Yes, delete it',
        confirmButtonDataCy = 'confirm-modal-confirm',
        cancelButtonText = 'Cancel',
        cancelActionOnHide,
        withEscButton,
        additionalButtons = [],
    } = additional;

    const dispatch = useDispatch();
    const styles = useStyles({
        withEscButton,
    });
    const offsetClassNames = useModalOffset();

    const hideModal = () => {
        dispatch(hideModalAction());
        cancelActionOnHide && onReject?.();
    };

    const reject = () => {
        hideModal();
        !cancelActionOnHide && onReject?.();
    };

    const confirm = () => {
        dispatch(hideModalAction());
        onConfirm && onConfirm();
    };

    return (
        <Dialog open onClose={reject} maxWidth="sm" className={offsetClassNames}>
            <Layout stack padding="20px">
                <DialogTitle>
                    <Layout stack gap="10px">
                        <Layout hAlign="space-between">
                            <Typography variant="h6" className={styles.title}>
                                {title}
                            </Typography>
                            {withEscButton && (
                                <IconButton
                                    className={styles.escButton}
                                    data-cy="modal-close"
                                    color="esc"
                                    onClick={hideModal}
                                >
                                    <Close className={styles.escIcon} />
                                    <div className={styles.escText}>esc</div>
                                </IconButton>
                            )}
                        </Layout>
                        <Divider />
                    </Layout>
                </DialogTitle>
                <DialogContent>
                    <Layout stack>
                        {message && typeof message === 'function' ? message() : (<Typography paragraph>{message}</Typography>)}
                    </Layout>
                </DialogContent>
                <DialogActions>
                    {withCancel && (
                        <Button variant="outlined" onClick={reject} dataCy="confirm-modal-cancel">
                            {cancelButtonText}
                        </Button>
                    )}
                    {additionalButtons.map((button, index) => {
                        return (
                            <Button
                                key={index}
                                variant="contained"
                                onClick={() => {
                                    dispatch(hideModalAction());
                                    button.onClick();
                                }}
                                dataCy={button.dataCy}
                            >
                                {button.text}
                            </Button>
                        );
                    })}
                    {onConfirm && (
                        <Button variant="contained" onClick={confirm} dataCy={confirmButtonDataCy}>
                            {confirmButtonText}
                        </Button>
                    )}
                </DialogActions>
            </Layout>
        </Dialog>
    );
};

export const useStyles = makeStyles({
    title: ({ withEscButton }) =>
        withEscButton
            ? {
                  paddingRight: '35px',
              }
            : {},
    escButton: {
        position: 'absolute',
        right: '30px',
        top: '26px',
    },
    escText: {
        position: 'absolute',
        bottom: '5px',
        fontSize: '10px',
    },
    escIcon: {
        transform: 'translateY(-5px);',
    },
});

ConfirmationModalNew.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    additional: PropTypes.shape({
        onReject: PropTypes.func,
        confirmButtonText: PropTypes.string,
        confirmButtonDataCy: PropTypes.string,
        cancelButtonText: PropTypes.string,
        withCancel: PropTypes.bool,
    }),
};

ConfirmationModalNew.defaultProps = {
    additional: {
        onReject: null,
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'Cancel',
        withCancel: true,
    },
};

export default ConfirmationModalNew;
