import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { displayCellCurrency } from 'modules/report/utils/columnHelper';
import { getCurrency } from 'enums/currencyEnum';
import { formatPrice } from 'utils/formatingUtil';
import { useSelector } from 'react-redux';
import DataBox from '../dataBox';

const SpendRow = ({ reportData, isLoading, type, showReportedData }) => {
    const {
        gridPreferences: { singleProjectView },
    } = useAccountPreferences();
    const defaultCurrency = useSelector(state => state.companyReducer.company.settings.defaultCurrency);
    const defaultCurrencySymbol = getCurrency(reportData.currencies?.budgetCash?.[0] || defaultCurrency).symbol;

    const scheduledValues = useMemo(() => {
        const allTimeCurrency = reportData?.currencies && reportData.currencies[`${type}ScheduledSpendAllTime`];
        const toDateCurrency =
            reportData?.currencies && (reportData.currencies[`${type}ScheduledSpendToDate`] || allTimeCurrency);
        const futureCurrency =
            reportData?.currencies && (reportData.currencies[`${type}ScheduledSpendFuture`] || allTimeCurrency);

        return [
            {
                value: formatPrice(reportData[`${type}ScheduledSpendAllTime`]),
                label: 'all',
                currency: displayCellCurrency(allTimeCurrency, defaultCurrencySymbol, 'mixed'),
            },
            {
                value: formatPrice(reportData[`${type}ScheduledSpendToDate`]),
                label: 'to date',
                currency: displayCellCurrency(toDateCurrency, defaultCurrencySymbol, 'mixed'),
            },
            {
                value: formatPrice(reportData[`${type}ScheduledSpendFuture`]),
                label: 'future',
                currency: displayCellCurrency(futureCurrency, defaultCurrencySymbol, 'mixed'),
            },
        ];
    }, [reportData, defaultCurrencySymbol, type]);

    const reportedValues = useMemo(() => {
        const allTimeCurrency = reportData?.currencies && reportData.currencies[`${type}ReportedSpendAllTime`];
        const toDateCurrency =
            reportData?.currencies && (reportData.currencies[`${type}ReportedSpendToDate`] || allTimeCurrency);
        const futureCurrency =
            reportData?.currencies && (reportData.currencies[`${type}ReportedSpendFuture`] || allTimeCurrency);

        return [
            {
                value: formatPrice(reportData[`${type}ReportedSpendAllTime`]),
                label: 'all',
                currency: displayCellCurrency(allTimeCurrency, defaultCurrencySymbol, 'mixed'),
            },
            {
                value: formatPrice(reportData[`${type}ReportedSpendToDate`]),
                label: 'to date',
                currency: displayCellCurrency(toDateCurrency, defaultCurrencySymbol, 'mixed'),
            },
            {
                value: formatPrice(reportData[`${type}ReportedSpendFuture`]),
                label: 'future',
                currency: displayCellCurrency(futureCurrency, defaultCurrencySymbol, 'mixed'),
            },
        ];
    }, [reportData, defaultCurrencySymbol, type]);

    return (
        <Row className="px-3">
            {singleProjectView.displayScheduledColumn && (
                <DataBox
                    title={`scheduled spend (${'external' === type ? 'ext rate' : 'int rate'})`}
                    unit="cash"
                    fullWidth={!singleProjectView.displayReportedColumn || !showReportedData}
                    values={scheduledValues}
                    isLoading={isLoading}
                    className={classNames({ 'main-border': singleProjectView.displayReportedColumn })}
                />
            )}
            {singleProjectView.displayReportedColumn && showReportedData && (
                <DataBox
                    title={`reported spend (${'external' === type ? 'ext rate' : 'int rate'})`}
                    unit="cash"
                    fullWidth={!singleProjectView.displayScheduledColumn}
                    values={reportedValues}
                    isLoading={isLoading}
                />
            )}
        </Row>
    );
};

SpendRow.propTypes = {
    reportData: PropTypes.shape({
        externalReportedSpendAllTime: PropTypes.number,
        externalReportedSpendToDate: PropTypes.number,
        externalReportedSpendFuture: PropTypes.number,
        externalScheduledSpendAllTime: PropTypes.number,
        externalScheduledSpendToDate: PropTypes.number,
        externalScheduledSpendFuture: PropTypes.number,
        internalReportedSpendAllTime: PropTypes.number,
        internalReportedSpendToDate: PropTypes.number,
        internalReportedSpendFuture: PropTypes.number,
        internalScheduledSpendAllTime: PropTypes.number,
        internalScheduledSpendToDate: PropTypes.number,
        internalScheduledSpendFuture: PropTypes.number,
        currencies: PropTypes.object,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    showReportedData: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
};

export default SpendRow;
