import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import {
    removeFilter,
    removeFilters,
    setSchedulerFilterItem,
    setSchedulerFilterItems,
} from '../../../../actions/schedulerActions';
import { DROPDOWN_WIDTH, SelectAllStyledCheckBox, StyledCheckBox, useCommonDropdownFiltersStyles } from './styles';
import { EqualityToggle } from '../../../../shared/components/equalityToggle/EqualityToggle';
import { FixedSizeList } from 'react-window';
import { makeStyles } from '@material-ui/core/styles';
import { SearchTextInput } from '../../../../shared/components/searchTextInput';

const SelectableList = props => {
    const {
        filterName,
        items,
        searchPredicate,
        itemLabelRenderer,
        getItemValue,
        withSearch,
        selectedSelector,
        deletedItemsMapper,
        withEqualityToggle,
        withSelectAll,
    } = props;

    const selected = useSelector(selectedSelector);

    const deletedItems = useMemo(() => {
        if (!deletedItemsMapper) {
            return [];
        }
        return deletedItemsMapper(items, selected);
    }, [deletedItemsMapper, items, selected]);

    const [filteredItems, setFilteredItems] = useState((items || []).concat(deletedItems));
    const [filterText, setFilterText] = useState('');
    const dispatch = useDispatch();
    const commonDropdownFiltersStyles = useCommonDropdownFiltersStyles();
    const classes = useStyles();

    const handleSearchInputChange = useCallback(filterText => {
        setFilterText(filterText);
    }, []);

    useEffect(() => {
        if (!filterText || !withSearch) {
            setFilteredItems(items.concat(deletedItems));
            return;
        }

        const text = filterText.toLowerCase();
        const filtered = items.concat(deletedItems).filter(item => searchPredicate(item, text));

        setFilteredItems(filtered);
        setFilterText(filterText);
    }, [deletedItems, filterText, items, searchPredicate, withSearch]);

    const handleItemCheck = (event, item) => {
        const filtersToModify = {
            name: filterName,
            value: getItemValue(item),
        };

        if (event.target.checked) {
            dispatch(setSchedulerFilterItem.request(filtersToModify));
        } else {
            dispatch(removeFilter.request(filtersToModify));
        }
    };

    const handleItemEqualityCheck = (equalityValue, item) => {
        const value = getItemValue(item);

        const filtersToModify = {
            name: filterName,
            value,
            set: equalityValue,
        };

        dispatch(setSchedulerFilterItem.request(filtersToModify));
    };

    const handleSelectAll = event => {
        const filtersToModify = filteredItems.map(item => {
            return {
                name: filterName,
                value: getItemValue(item),
            };
        });

        if (event.target.checked) {
            dispatch(setSchedulerFilterItems(filtersToModify));
        } else {
            dispatch(removeFilters(filtersToModify));
        }
    };

    const renderRow = props => {
        const { index, style } = props;
        const item = filteredItems[index];
        const value = getItemValue(item);

        return (
            <Grid container justifyContent="space-between" alignItems="center" style={style}>
                <Grid item>
                    <StyledCheckBox
                        withEqualityToggle={withEqualityToggle}
                        label={itemLabelRenderer(item)}
                        checked={selected[value]}
                        onChange={event => handleItemCheck(event, item)}
                    />
                </Grid>
                {withEqualityToggle ? (
                    <Grid item>
                        <EqualityToggle
                            disabled={!selected[value]}
                            onChange={equalityValue => handleItemEqualityCheck(equalityValue, item)}
                            value={selected[value]?.set}
                        />
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    const noOfSelectedVisibleItems = filteredItems.reduce(
        (acc, item) => (selected[getItemValue(item)] ? acc + 1 : acc),
        0
    );

    const filteredItemsCount = filteredItems.length;

    const allSelected = noOfSelectedVisibleItems === filteredItemsCount;
    const indeterminate = !allSelected && noOfSelectedVisibleItems !== 0;

    return (
        <Grid container direction="column">
            {withSearch ? (
                <Grid item>
                    <div className={classes.searchInputWrapper}>
                        <SearchTextInput onChange={handleSearchInputChange} />
                    </div>
                </Grid>
            ) : null}
            <Grid item style={{ padding: '0 20px' }}>
                {withSelectAll ? (
                    <SelectAllStyledCheckBox
                        label="Select All"
                        indeterminate={indeterminate}
                        checked={allSelected}
                        onChange={event => handleSelectAll(event)}
                    />
                ) : null}
                <FixedSizeList
                    className={commonDropdownFiltersStyles.fixedList}
                    height={filteredItems.length > 10 ? 400 : filteredItems.length * 40}
                    width={DROPDOWN_WIDTH - 112}
                    itemSize={40}
                    itemCount={filteredItems.length}
                >
                    {renderRow}
                </FixedSizeList>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    searchInputWrapper: {
        padding: '14px 16px 23px 28px',
    },
});

const memoized = React.memo(SelectableList);

export { memoized as SelectableList };
