import { all, fork } from 'redux-saga/effects';
import billingSaga from './billingSaga';
import initialSaga from './initialAppSaga';
import accountSaga from './accountSaga';
import requestSaga from './requestSaga';
import bookingSaga from './bookingSaga';
import companySaga from './companySaga';
import companyTreeSaga from './companyTreeSaga';
import resourceSaga from './resourceSaga';
import resourceGroupSaga from './resourceGroupSaga';
import projectGroupSaga from './projectGroupSaga';
import customFieldSaga from './customFieldSaga';
import projectSaga from './projectSaga';
import tagSaga from './tagSaga';
import customerSaga from './customerSaga';
import rateSaga from './rateSaga';
import generalSaga from './generalSaga';
import reportSaga from './reportSaga';
import integrationSaga from './integrationSaga';
import categoryGroupSaga from './categoryGroupSaga';
import categoryTemplateSaga from './categoryTemplateSaga';
import schedulerSaga from './schedulerSaga';
import builderSaga from '../modules/scheduler/modals/builder/reducer/saga';
import { costsCategoriesSaga } from '../modules/costsCategories/store';
import { scheduleFormSaga } from '../modules/scheduler/components/dropdowns/schedule/partials/resourceSingleMode';
import { scheduleFormResourceSaga } from '../modules/modals/scheduleResourceModal/components/modal';
import { schedulerRequestFormSaga } from '../modules/request/modals/requestResourceModal/modal';
import { toilSaga } from '../modules/toil/store/saga';

export default function* rootSaga() {
    yield all([
        fork(projectGroupSaga),
        fork(initialSaga),
        fork(accountSaga),
        fork(requestSaga),
        fork(bookingSaga),
        fork(companySaga),
        fork(companyTreeSaga),
        fork(resourceSaga),
        fork(resourceGroupSaga),
        fork(customFieldSaga),
        fork(projectSaga),
        fork(tagSaga),
        fork(rateSaga),
        fork(generalSaga),
        fork(customerSaga),
        fork(reportSaga),
        fork(integrationSaga),
        fork(billingSaga),
        fork(categoryGroupSaga),
        fork(categoryTemplateSaga),
        fork(schedulerSaga),
        fork(builderSaga),
        fork(costsCategoriesSaga),
        fork(scheduleFormSaga),
        fork(scheduleFormResourceSaga),
        fork(schedulerRequestFormSaga),
        fork(toilSaga),
    ]);
}
