import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';
import { FormText, TabPane } from 'reactstrap';
import { useHasRights, useIsExtensionInstalled } from 'hooks';
import { APPROVAL, TIME_SHEET } from 'enums/extensionShortIdEnum';
import Link from 'shared/link';
import { chooseField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';
import { normalizeBoolean } from 'utils/formUtil';
import MissingExtension from 'shared/warnings/missingExtension';

const TimesheetSettingsTab = props => {
    const { tabId, projectKeyWord } = props;
    const isNoteRequiredSetting = useSelector(state => state.companyReducer.company.settings.tsNoteRequired);
    const isApprovalExtensionInstalled = useIsExtensionInstalled(APPROVAL);
    const isTimeSheetExtensionInstalled = useIsExtensionInstalled(TIME_SHEET);

    const { hasSettingProjectTimesheet } = useHasRights([
        {
            rights: ['settingProjectTs'],
            rule: 'one',
            name: 'hasSettingProjectTimesheet',
        },
    ]);

    return (
        <TabPane tabId={tabId}>
            {!isTimeSheetExtensionInstalled && (
                <MissingExtension description="Timesheets settings that you are allowed to edit will appear here. Please enable this extension from extension page" />
            )}
            {isTimeSheetExtensionInstalled && (
                <>
                    {hasSettingProjectTimesheet ? (
                        <section className="form-fields">
                            <p className="title">Timesheets:</p>
                            <Field
                                type="radio"
                                inline
                                label="Enabled"
                                name="timeEntryEnabled"
                                value={true}
                                component={chooseField}
                                normalize={normalizeBoolean}
                            />
                            <Field
                                type="radio"
                                inline
                                label="Disabled"
                                name="timeEntryEnabled"
                                value={false}
                                component={chooseField}
                                normalize={normalizeBoolean}
                            />
                            <FormText className="mb-2">Enable or Disable Timesheets for this {projectKeyWord}</FormText>
                            <Field
                                type="checkbox"
                                inline
                                label="Require Notes on Submission"
                                name="notesTimeSheet"
                                disabled={!isNoteRequiredSetting}
                                value={true}
                                component={chooseField}
                            />
                            <FormText className="mb-2">
                                {!isNoteRequiredSetting
                                    ? 'This setting is globally disabled. To use it, enable it from Settings -> Timesheets'
                                    : `Notes must be submitted on all time entries submitted for this ${projectKeyWord}`}
                            </FormText>
                            <Field
                                type="checkbox"
                                inline={isApprovalExtensionInstalled}
                                label="Time Approval Required"
                                name="timeEntryApproval"
                                disabled={!isApprovalExtensionInstalled}
                                value={true}
                                component={chooseField}
                            />
                            <FormText className={classnames('mb-2', { 'd-none': !isApprovalExtensionInstalled })}>
                                If enabled time entries for this {projectKeyWord} need to be approved by PM or Admin
                            </FormText>
                            <Field
                                type="checkbox"
                                inline={isApprovalExtensionInstalled}
                                label="Lock All Time Entries"
                                name="timeEntryLocked"
                                disabled={!isApprovalExtensionInstalled}
                                value={true}
                                component={chooseField}
                            />
                            <FormText className={classnames({ 'd-none': !isApprovalExtensionInstalled })}>
                                No more time can be entered on this {projectKeyWord} once locked
                            </FormText>
                            <p className={classnames('small text-danger', { 'd-none': isApprovalExtensionInstalled })}>
                                Approval is Disabled on a company Level. You must first enable it in&nbsp;
                                <Link className="btn btn-primary rounded-1 px-3" to="/settings#features">
                                    Settings
                                </Link>{' '}
                                to use this approval feature.
                            </p>
                        </section>
                    ) : (
                        <PermissionDenied />
                    )}
                </>
            )}
        </TabPane>
    );
};

TimesheetSettingsTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projectKeyWord: PropTypes.string,
};

TimesheetSettingsTab.defaultProps = {
    projectKeyWord: 'Project',
};

export default TimesheetSettingsTab;
