import React from 'react';
import PropTypes from 'prop-types';

import { scalesArray } from 'modules/scheduler/enums/scale';
import SliderComponent from './sliderComponent';
import { Section, SectionDescription, SectionHeader } from './styles';

const ColumnWidth = ({ cellWidth, onChange }) => (
    <Section>
        <SectionHeader>Column Width</SectionHeader>
        <SectionDescription>Set the default width of cells in each scale.</SectionDescription>
        {scalesArray
            .filter(scale => scale.customSearchId == null)
            .map((scale, index) => (
                <SliderComponent
                    key={`${index}-${scale.value}`}
                    name={scale.value}
                    label={scale.label}
                    onChange={value => onChange({ cellWidth: { ...cellWidth, ...value } })}
                    min={10}
                    max={250}
                    step={1}
                    value={cellWidth[scale.value]}
                />
            ))}
    </Section>
);

ColumnWidth.propTypes = {
    cellWidth: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ColumnWidth;
