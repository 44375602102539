import { values, sortBy, filter, map, includes } from 'lodash';

export const STATUS_ARCHIVED = {
    display: 'Archived',
    value: 'STATUS_ARCHIVED',
    color: '#c5594c',
};
export const STATUS_NON_BOOKABLE = {
    display: 'Non Bookable',
    value: 'STATUS_NON_BOOKABLE',
    color: '#d29137',
};
export const STATUS_PARKED = { display: 'Parked', value: 'STATUS_PARKED', color: '#ff5722' };
export const STATUS_ACTIVE = { display: 'Active', value: 'STATUS_ACTIVE', color: '#5a884a' };

export const ROLE_ACCOUNT_HANDLER = {
    display: 'Account Handler 1',
    value: 'ROLE_ACCOUNT_HANDLER',
};
export const ROLE_ACCOUNT_HANDLER_1 = {
    display: 'Account Handler 2',
    value: 'ROLE_ACCOUNT_HANDLER_1',
};
export const ROLE_ADMIN = { display: 'Admin', value: 'ROLE_ADMIN' };
export const ROLE_OWNER = { display: 'Account Owner', value: 'ROLE_OWNER' };
export const ROLE_TEAM = { display: 'Team', value: 'ROLE_TEAM' };
export const ROLE_CONTRACTOR = { display: 'Contractor', value: 'ROLE_CONTRACTOR' };

export const TYPE_UNASSIGNED = { display: 'Unassigned', value: 'UNASSIGNED' };
export const TYPE_ARCHIVED = { display: 'Archived', value: 'ARCHIVED' };
export const TYPE_REGULAR = { display: 'Resource', value: 'REGULAR' };

export const roles = {
    ROLE_OWNER,
    ROLE_ADMIN,
    ROLE_ACCOUNT_HANDLER,
    ROLE_ACCOUNT_HANDLER_1,
    ROLE_TEAM,
    ROLE_CONTRACTOR,
};
export const statuses = {
    STATUS_ACTIVE,
    STATUS_PARKED,
    STATUS_NON_BOOKABLE,
    STATUS_ARCHIVED,
};

export const statusesArray = sortBy(values(statuses), status => status.value);
export const statusesArrayExceptParked = filter(statusesArray, status => status.value !== STATUS_PARKED.value);
export const statusesArrayExceptParkedNonBookable = filter(
    statusesArray,
    status => !includes([STATUS_PARKED.value, STATUS_NON_BOOKABLE.value], status.value)
);
export const rolesArray = values(roles);
export const rolesArrayExceptOwner = filter(rolesArray, role => role.value !== ROLE_OWNER.value);
export const valuesArray = map(values(statuses), 'value');

export const getRoleDisplay = roleValue => {
    if (roleValue) {
        return roles[roleValue].display;
    }
};

export const getStatusDisplay = statusValue => {
    if (statusValue) {
        return statuses[statusValue].display;
    }
};

const RESOURCE_IS_PM = { value: 'resource-is-pm', display: 'Resource is project manager' };
const RESOURCE_IS_NOT_PM = { value: 'resource-is-not-pm', display: 'Resource is not project manager' };

export const resourceIsPmFilterStatuses = [RESOURCE_IS_PM, RESOURCE_IS_NOT_PM];
