import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

const Scrollbar = React.forwardRef((props, ref) => {
    const { height, onScroll, children, autoHide, marginLeft, noHorizontalScrollbar, style, ...restOfProps } = props;
    const styles = style;
    height && (styles.height = -1 === height.toString().indexOf('%') ? `${height}px` : height.toString());
    restOfProps.autoHeightMax && (styles.height = undefined);

    return (
        <Scrollbars
            ref={ref}
            {...restOfProps}
            style={styles}
            onScroll={onScroll}
            autoHide={autoHide}
            autoHideTimeout={1000}
            renderTrackHorizontal={noHorizontalScrollbar ? () => <div></div> : undefined}
            renderThumbVertical={({ style, ...props }) => (
                <div
                    {...props}
                    style={{
                        ...style,
                        backgroundColor: '#808080',
                        opacity: '.6',
                        marginLeft: marginLeft,
                    }}
                />
            )}
        >
            {children}
        </Scrollbars>
    );
});

Scrollbar.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onScroll: PropTypes.func,
    style: PropTypes.object,
    autoHide: PropTypes.bool,
    marginLeft: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

Scrollbar.defaultProps = {
    onScroll: null,
    autoHide: false,
    marginLeft: '0px',
    style: {},
};

export default Scrollbar;
