import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { addDays } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { Close } from '@material-ui/icons';
import Button from './Button';
import { useSelector } from 'react-redux';
import { makeGetFilteredProjects } from '../../selectors/project';
import { makeGetFilteredResources } from '../../selectors/resource';
import { getCategoryGroups } from '../../selectors/categoryGroups';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/en-US';
import { uniqBy } from 'lodash';
import { resourcesStepKey } from './ResourcesOnboardingStepContent';
import { projectsStepKey } from './ProjectsOnboardingStepContent';
import { categoriesStepKey } from './CategoriesOnboardingStepContent';

export const bookingStepKey = 'booking';

const greyBackgroundColor = '#e6e7ed';

const useStyles = makeStyles({
    clickable: {
        cursor: 'pointer',
    },
    iconsRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '20px',
    },
    left: {
        padding: '50px',
    },
    right: {
        padding: '30px',
        backgroundColor: greyBackgroundColor,
    },
    dropdown: {
        width: '100%',
    },
    beforeField: {
        paddingRight: '10px',
    },
    field: {
        flexGrow: 1,
    },
});

const useSelectStyles = makeStyles({
    root: {
        color: '#32A675',
        borderBottomColor: '#32A675',
        fontWeight: 'bold',
        '& label.Mui-focused': {
            color: '#32A675',
        },
        '& .MuiSelect:after': {
            borderBottomColor: '#32A675',
        },
        '& .MuiInput:after': {
            borderBottomColor: '#32A675',
        },
    },
});

const sortFn = (a, b) => {
    if ((a?.name || '').toLowerCase() < (b?.name || '').toLowerCase()) {
        return -1;
    }
    if ((a?.name || '').toLowerCase() > (b?.name || '').toLowerCase()) {
        return 1;
    }
    return 0;
};

export const CreateBookingOnboardingStepContent = React.memo(props => {
    const { storedData, setKeyData, setMultipleKeysData, goPrevious, goNext, skip } = props;

    const [showErrors, setShowErrors] = useState(false);

    const classes = useStyles();
    const selectClasses = useSelectStyles();

    const selections = useMemo(() => {
        return {
            resource: storedData[`${bookingStepKey}-values-resource`],
            project: storedData[`${bookingStepKey}-values-project`],
            category: storedData[`${bookingStepKey}-values-category`],
            start: storedData[`${bookingStepKey}-values-start`],
            end: storedData[`${bookingStepKey}-values-end`],
        };
    }, [storedData]);

    useEffect(() => {
        setMultipleKeysData({
            [`${bookingStepKey}-values-start`]: new Date(),
            [`${bookingStepKey}-values-end`]: addDays(new Date(), 7),
        });
        // eslint-disable-next-line
    }, []);

    const projectsFromReduxStore = useSelector(makeGetFilteredProjects('REGULAR'));
    const allProjects = useMemo(() => {
        return uniqBy(
            [...projectsFromReduxStore, ...(storedData?.[`${projectsStepKey}-meta-createdEntities`] || [])],
            '_id'
        ).sort(sortFn);
    }, [projectsFromReduxStore, storedData]);

    const resourcesFromReduxStore = useSelector(makeGetFilteredResources('ACTIVE'));

    const allResources = useMemo(() => {
        return [...resourcesFromReduxStore, ...(storedData?.[`${resourcesStepKey}-meta-createdEntities`] || [])].sort(
            sortFn
        );
    }, [resourcesFromReduxStore, storedData]);

    const categoriesFromReduxStore = useSelector(getCategoryGroups);

    const allCategories = uniqBy(
        categoriesFromReduxStore.reduce(
            (acc, categoryGroup) => {
                return [...acc, ...(categoryGroup.categories || [])];
            },
            [...(storedData?.[`${categoriesStepKey}-meta-createdEntities`] || [])]
        ),
        '_id'
    ).sort(sortFn);

    const onSave = useCallback(() => {
        if (Object.values(selections).every(selection => !!selection)) {
            return goNext();
        } else {
            setShowErrors(true);
        }
    }, [goNext, selections]);

    const calendarFieldJSX = useCallback(
        key => {
            const handleDateChange = date => {
                setKeyData(`${bookingStepKey}-values-${key}`, date);
            };

            return (
                <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
                    <FormControl className={classes.dropdown} error={showErrors && !selections[key]}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="EEEE, do LLLL yyyy"
                            label="Date"
                            value={selections[key]}
                            onChange={handleDateChange}
                        />
                        <FormHelperText>{showErrors && !selections[key] ? 'Please select' : ' '}</FormHelperText>
                    </FormControl>
                </MuiPickersUtilsProvider>
            );
        },
        [classes.dropdown, selections, setKeyData, showErrors]
    );

    const dropdownJSX = useCallback(
        (arrayOfObjects, key, label, labelItemFn) => {
            const textFn = (obj, labelItemFn) => (labelItemFn ? labelItemFn(obj) : obj?.name);

            const handleChange = e => {
                const selectedObj = arrayOfObjects.find(obj => obj._id === e.target.value);
                setKeyData(`${bookingStepKey}-values-${key}`, selectedObj);
            };

            return (
                <FormControl className={classes.dropdown} error={showErrors && !selections[key]}>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        classes={selectClasses}
                        value={selections[key]?._id || ''}
                        onChange={handleChange}
                        label={label}
                    >
                        {(arrayOfObjects || []).map(object => {
                            return (
                                <MenuItem key={object._id} value={object._id}>
                                    {textFn(object, labelItemFn)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <FormHelperText>{showErrors && !selections[key] ? 'Please select' : ' '}</FormHelperText>
                </FormControl>
            );
        },
        [classes.dropdown, selectClasses, selections, setKeyData, showErrors]
    );

    const resourceLabelFn = useCallback(resourceObj => `${resourceObj?.firstName} ${resourceObj?.lastName}`, []);

    const bookingContentJSX = useCallback(() => {
        return (
            <div>
                <div className="mt-5">
                    <div className="mt-2 mb-2 d-flex align-items-center">
                        <span className={classes.beforeField}>Let&apos;s schedule </span>
                        <span className={classes.field}>
                            {dropdownJSX(allResources, 'resource', 'Team Member', resourceLabelFn)}{' '}
                        </span>
                    </div>
                    <div className="mt-2 mb-2 d-flex align-items-center">
                        <span className={classes.beforeField}>on</span>
                        <span className={classes.field}>{dropdownJSX(allProjects, 'project', 'Project name')}</span>
                    </div>
                    <div className="mt-2 mb-2 d-flex align-items-center">
                        <span className={classes.beforeField}>starting on</span>
                        <span className={classes.field}>{calendarFieldJSX('start')}</span>
                    </div>
                    <div className="mt-2 mb-2 d-flex align-items-center">
                        <span className={classes.beforeField}>finishing on</span>
                        <span className={classes.field}>{calendarFieldJSX('end')}</span>
                    </div>
                    <div className="mt-2 mb-2 d-flex align-items-center">
                        <span className={classes.beforeField}>under the category </span>
                        <span className={classes.field}>
                            {' '}
                            {dropdownJSX(allCategories, 'category', 'Work category')}
                        </span>
                    </div>
                </div>
                <div className="mt-5 d-flex justify-content-between">
                    <div>
                        <Button inversed type="success" className="mr-1" onClick={goPrevious}>
                            Previous
                        </Button>
                    </div>
                    <div>
                        <Button inversed type="success" className="mr-1" onClick={skip}>
                            Skip for now
                        </Button>
                        <Button type="success" className="mr-1 ml-1" onClick={onSave}>
                            Save booking
                        </Button>
                    </div>
                </div>
            </div>
        );
    }, [
        classes.beforeField,
        classes.field,
        dropdownJSX,
        allResources,
        resourceLabelFn,
        allProjects,
        calendarFieldJSX,
        allCategories,
        goPrevious,
        skip,
        onSave,
    ]);

    return (
        <div className="d-flex w-100 h-100">
            <div className={`${classes.left} d-flex flex-column align-center justify-content-center w-60 h-100`}>
                <div className="pl-5 pr-5 w-100">
                    <h1>My first booking</h1>
                    {bookingContentJSX()}
                </div>
            </div>
            <div className={`${classes.right} w-40 h-100`}>
                <div className={classes.iconsRow}>
                    <Close className={classes.clickable} onClick={skip} />
                </div>
                <div></div>
            </div>
        </div>
    );
});

export default CreateBookingOnboardingStepContent;
