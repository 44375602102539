import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAllocationStateForSelection } from 'shared/lib/booking';

export const useAllocation = currentSelection => {
    const companySettings = useSelector(state => state.companyReducer.company.settings);

    return useMemo(() => {
        const avgDailyCapacity = companySettings.report.avgDailyCapacity;

        if (currentSelection && currentSelection.start && currentSelection.end) {
            // Calculate based on  each day work schedule
            return getAllocationStateForSelection(currentSelection);
        }

        return {
            state: companySettings.grid.bookingDefaultState,
            values: {
                percentage: 100,
                hours: avgDailyCapacity,
                total: avgDailyCapacity,
            },
        };
    }, [companySettings, currentSelection]);
};
