import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { SCHEDULER_FOOTER_FIXED_HEIGHT } from 'constants';

export const Wrapper = styled.div`
    width: 380px;
    right: -380px;
    top: 52px;
    bottom: 0;
    position: fixed;
    z-index: 99999;
    background-color: #fff;
    padding: 20px;
    transition: 0.5s;
    visibility: hidden;
    box-shadow: 0 12px 40px 5px rgba(0, 0, 0, 0.26);

    &.footer-visible {
        bottom: ${SCHEDULER_FOOTER_FIXED_HEIGHT + 1}px;
    }

    ul.nav li {
        border-bottom: 1px solid #ebebeb;
    }

    &.fade-in {
        right: 0;
        visibility: visible;
    }
`;

export const CloseIcon = styled(Close)`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);

    &:hover {
        color: rgb(0, 0, 0);
    }
`;

export const Title = styled.h5`
    margin: 15px 0;
    padding: 0px 20px;
`;

export const Description = styled.p`
    font-size: 11px;
    color: #4e5252;
    line-height: 14px;
    margin-bottom: 20px;
    padding: 0px 20px;
`;

export const Section = styled.div`
    border-bottom: 1px solid #ececec;
    margin-bottom: 10px;
    padding-bottom: 10px;
`;
