/* eslint-env browser */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import Header from 'modules/header';
import NotFound from 'shared/notFound';
import { useWindowDimension } from 'hooks';

const Error = ({ isAuthenticated }) => {
    const { windowHeight } = useWindowDimension();
    useEffect(() => {
        document.title = '404 - Page not found';
    }, []);

    return (
        <Route
            render={() => (
                <div>
                    {isAuthenticated && <Header />}

                    <div
                        style={{
                            height: `${windowHeight - (isAuthenticated ? 51 : 0)}px`,
                            marginTop: isAuthenticated ? 0 : '-51px',
                        }}
                    >
                        <NotFound />
                    </div>
                </div>
            )}
        />
    );
};

export default Error;
