import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { isUndefined } from 'underscore';
import { TwitterPicker } from 'react-color';
import { InputGroup, Label, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { COLORS } from 'enums/colorEnum';
import { Cover, Popover } from './styles';

class ColorInputField extends PureComponent {
    static defaultProps = {
        input: {},
        onColorChange: null,
        disabled: false,
        icon: '',
        width: '',
        inline: 'false',
        type: 'text',
        label: '',
        placeholder: '',
        meta: {},
        position: 'absolute',
    };

    constructor(props) {
        super(props);
        this.onInputClick = this.onInputClick.bind(this);
        this.state = {
            visible: false,
            color: '',
        };
    }

    componentDidMount() {
        this.setState({ color: this.props.input.value });
    }

    componentDidUpdate() {
        if (this.props.input.value !== this.state.color) {
            this.setState({ color: this.props.input.value });
        }
    }

    onChange = color => {
        this.setState({ color: color.hex });
        if (this.props.onColorChange) {
            this.props.onColorChange(color);
        }
        this.onInputClick();
    };

    onInputClick() {
        this.setState(state => ({ visible: !state.visible }));
    }

    render() {
        const { input, label, inline, width, className, disabled, placeholder, meta, position } = this.props;
        let show = meta && meta.touched && !isUndefined(meta.error);

        return (
            <InputGroup
                check={(!width).toString()}
                inline={width ? 'false' : inline}
                className={classnames('addon', className)}
            >
                {label && (
                    <Label for={input.name} className="form-control-label">
                        {label}
                    </Label>
                )}
                <Input
                    id={input.name}
                    disabled={disabled}
                    className={classnames(width)}
                    placeholder={placeholder}
                    invalid={show}
                    onClick={this.onInputClick}
                    type="text"
                    {...input}
                />
                <InputGroupAddon addonType="append">
                    <InputGroupText
                        className={classnames({ disabled })}
                        style={{
                            width: '38px',
                            backgroundColor: this.state.color,
                            borderColor: this.state.color,
                        }}
                    />
                </InputGroupAddon>
                {this.state.visible ? (
                    <Popover position={position}>
                        <Cover onClick={this.onInputClick} />
                        <TwitterPicker
                            triangle="hide"
                            width="313px"
                            colors={COLORS}
                            color={this.state.color}
                            className={classnames('color-picker')}
                            onChange={this.onChange}
                        />
                    </Popover>
                ) : null}
                <div className="invalid-feedback text-left">{show && <span>{meta.error}</span>}</div>
            </InputGroup>
        );
    }
}

export default ColorInputField;
