import styled from 'styled-components';
import { Label } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Radio } from '@material-ui/core';
import React from 'react';

export const SectionHeader = styled.div`
    font-weight: 500;
`;

export const Section = styled.div`
    border: 1px solid #ececec;
    margin-bottom: 10px;
    padding: 20px 30px;
    border-radius: 5px;
`;

export const SectionHelperText = styled.div`
    margin-left: 48px;
    font-size: 11px;
    margin-right: 10px;
    margin-top: -10px;
    color: #4e5252;
    margin-bottom: 10px;
`;

export const SectionDescription = styled.div`
    font-size: 11px;
    color: rgb(164, 164, 164);
    line-height: 14px;
    margin-bottom: 20px;
`;

export const RadioButton = styled(Label)`
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;

    input {
        cursor: pointer;
        position: static;
        margin: 0 auto;
    }

    span {
        text-align: center;
    }
`;

export const SliderWrapper = styled.div`
    padding-bottom: 12px;
    position: relative;

    .MuiTypography-root {
        font-size: 13px;
    }

    .MuiSlider-root {
        width: 240px;
        margin-left: 20px;
    }

    .current-value {
        position: absolute;
        top: ${props => (props.withLabel ? '42px' : '16px')};
        left: -30px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 240px;
        text-align: center;
    }
`;

export const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});
