import React, { useCallback, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectToilPolicies } from '../../store/selectors';
import {
    Modal,
    Layout,
    Typography,
    Select,
    Button,
    TextField,
    BasicDialog,
    useOpenState,
    Checkbox,
    CheckboxList,
    useDebouncedValue,
    RadioListDialog,
} from '@hub-mono/ui';
import { searchStringCompare } from '@hub-mono/utils';
import { useModalOffset } from '../../../../hooks/useModalOffset';
import { isModalView } from '../../../../utils/isModalView';
import { useAppKeyWords } from '../../../../hooks';
import { hideModal } from '../../../../actions/modalActions';
import { makeSelectResourcesWithGivenPolicy } from '../../../../selectors/resource';
import { deletePolicy, updateResourcePolicies } from '../../store/actions';
import classNames from 'classnames';
import { AddResourceDialog } from './addResourceDialog';

export const ManagePoliciesModal = React.memo(props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { resourceKeyWord, resourcePluralKeyWord } = useAppKeyWords();

    const policies = useSelector(selectToilPolicies);

    const [selectedPolicy, setSelectedPolicy] = useState(
        policies.find(policy => policy._id === props.initialPolicy._id)
    );

    const fullScreen = isModalView();
    const offsetClassNames = useModalOffset(`rounded-0 col-sm-9 col-lg-9 col-md-8 col-xl-10 px-0`, fullScreen);

    const {
        close: closeRemoveDialog,
        anchorElement: removeDialogAnchorElement,
        isOpen: isRemoveDialogOpen,
        open: openRemoveDialog,
    } = useOpenState();

    const {
        close: closeMoveResourcesToPolicyDialog,
        anchorElement: moveResourcesToPolicyDialogAnchorElement,
        isOpen: isMoveResourceToPolicyDialogOpen,
        open: openMoveResourcesToPolicyDialog,
    } = useOpenState();

    const {
        close: closeRemoveResourceFromPolicyDialog,
        anchorElement: removeResourceFromPolicyDialogAnchorElement,
        isOpen: isRemoveResourceFromPolicyDialogOpen,
        open: openRemoveResourceFromPolicyDialog,
    } = useOpenState();

    const {
        value: displaySearchText,
        setDebouncedValue: setSearchText,
        debounced: debouncedSearchText,
    } = useDebouncedValue({
        defaultValue: '',
    });

    const resourcesSelector = useMemo(() => {
        return makeSelectResourcesWithGivenPolicy(selectedPolicy?._id);
    }, [selectedPolicy?._id]);

    const policyResources = useSelector(resourcesSelector);

    const [selectedPolicyResources, setSelectedPolicyResources] = useState([]);

    const handleClose = useCallback(() => {
        dispatch(hideModal());
    }, [dispatch]);

    const handlePolicyChange = useCallback((event, policy) => {
        setSelectedPolicy(policy);
        setSelectedPolicyResources([]);
    }, []);

    const handleDeletePolicy = useCallback(() => {
        dispatch(
            deletePolicy.request({
                policy: selectedPolicy,
                onSuccess: () => {
                    setSelectedPolicy(policies.find(policy => policy.default));
                    closeRemoveDialog();
                },
            })
        );

        closeRemoveDialog();
    }, [closeRemoveDialog, dispatch, policies, selectedPolicy]);

    const handleMoveResourcesToPolicy = useCallback(
        policy => {
            if (!policy) {
                return;
            }

            dispatch(
                updateResourcePolicies.request({
                    data: (selectedPolicyResources ?? []).map(resource => ({
                        resourceId: resource._id,
                        toilPolicyId: policy._id,
                    })),
                    onSuccess: () => {
                        setSelectedPolicyResources([]);
                        closeMoveResourcesToPolicyDialog();
                    },
                })
            );
        },
        [closeMoveResourcesToPolicyDialog, dispatch, selectedPolicyResources]
    );

    const policyOptionLabel = useCallback(policy => {
        if (!policy) {
            return '-';
        }

        if (policy.default) {
            if (!searchStringCompare(policy.name, 'default policy')) {
                return `${policy.name} (Default)`;
            }
        }

        return policy.name;
    }, []);

    const title = (
        <Layout stack gap={24} flex="1 1 100%">
            <Layout stack gap={8}>
                <Typography variant="headline" size="medium">
                    TOIL Policies
                </Typography>
                <Typography variant="body" size="medium">
                    Time off in lieu policy setting for your company.
                </Typography>
            </Layout>
            <Layout gap={16}>
                <Layout flex="0 1 50%">
                    <Select
                        options={policies}
                        value={selectedPolicy}
                        disableClearable
                        optionLabel={policyOptionLabel}
                        optionValue="_id"
                        label="Policy"
                        onChange={handlePolicyChange}
                    />
                </Layout>
                {selectedPolicy && !selectedPolicy.default ? (
                    <Button variant="text" onClick={openRemoveDialog} endIcon="delete">
                        Remove policy
                    </Button>
                ) : null}
                <BasicDialog
                    open={isRemoveDialogOpen}
                    anchorElement={removeDialogAnchorElement}
                    icon="error"
                    title="Are you sure you want to remove the selected policy?"
                    content="This action is undoable if removed."
                    action1Text="Yes, remove"
                    onAction1={handleDeletePolicy}
                    action2Text="No, cancel"
                    onAction2={closeRemoveDialog}
                    onClose={closeRemoveDialog}
                />
            </Layout>
        </Layout>
    );

    const filteredPolicyResources = useMemo(() => {
        return policyResources.filter(option => {
            return searchStringCompare(option.name, debouncedSearchText);
        });
    }, [debouncedSearchText, policyResources]);

    const handleSelectAllPolicyResourcesClick = useCallback(
        event => {
            if (event.target.checked) {
                setSelectedPolicyResources(filteredPolicyResources);
            } else {
                setSelectedPolicyResources([]);
            }
        },
        [filteredPolicyResources]
    );

    const handleResourceListChange = useCallback(selected => {
        setSelectedPolicyResources(selected);
    }, []);

    const handleRemoveResourcesFromPolicy = useCallback(() => {
        if (!selectedPolicyResources?.length) {
            return;
        }

        dispatch(
            updateResourcePolicies.request({
                data: selectedPolicyResources.map(resource => ({
                    resourceId: resource._id,
                    toilPolicyId: null,
                })),
                onSuccess: () => {
                    setSelectedPolicyResources([]);
                    closeRemoveResourceFromPolicyDialog();
                },
            })
        );
    }, [closeRemoveResourceFromPolicyDialog, dispatch, selectedPolicyResources]);

    const moveToPolicies = useMemo(() => {
        return policies.filter(policy => policy._id !== selectedPolicy._id);
    }, [policies, selectedPolicy._id]);

    const content = (
        <Layout stack stretch gap={24}>
            <Layout gap={16} vAlign="center">
                <Layout flex="0 1 60%">
                    <TextField
                        name="filter"
                        label="Filter"
                        placeholder={`Name of ${resourceKeyWord}`}
                        value={displaySearchText}
                        onChange={event => setSearchText(event.target.value)}
                    />
                </Layout>
                {selectedPolicy ? (
                    <Layout flex="0 0 40%">
                        <AddResourceDialog selectedPolicy={selectedPolicy} />
                    </Layout>
                ) : null}
            </Layout>
            <Layout stack flex="1 0 100%">
                <Layout gap={16} vAlign="center" className={classes.resourcesHeader}>
                    <div>
                        <Checkbox
                            name="select-all"
                            label={
                                <Typography variant="label" size="medium" prominent>
                                    Select All
                                </Typography>
                            }
                            dataCy="select-all-resources"
                            checked={
                                selectedPolicyResources.length !== 0 &&
                                filteredPolicyResources.length === selectedPolicyResources.length
                            }
                            indeterminate={
                                selectedPolicyResources.length !== 0 &&
                                selectedPolicyResources.length !== filteredPolicyResources.length
                            }
                            onChange={handleSelectAllPolicyResourcesClick}
                        />
                    </div>
                    <div>
                        <Typography variant="body" size="small">
                            {selectedPolicyResources.length} selected
                        </Typography>
                    </div>
                    {selectedPolicyResources.length ? (
                        <>
                            {policies.length > 1 ? (
                                <div>
                                    <Button variant="text" endIcon="moveItem" onClick={openMoveResourcesToPolicyDialog}>
                                        Move to
                                    </Button>
                                    <RadioListDialog
                                        open={isMoveResourceToPolicyDialogOpen}
                                        anchorElement={moveResourcesToPolicyDialogAnchorElement}
                                        icon="moveItem"
                                        title={`Move ${resourcePluralKeyWord}`}
                                        subtitle="Please choose where you would like to move the selected items."
                                        options={moveToPolicies}
                                        optionLabel="name"
                                        optionValue="_id"
                                        action1Text="Move"
                                        onAction1={handleMoveResourcesToPolicy}
                                        action2Text="Cancel"
                                        onAction2={closeMoveResourcesToPolicyDialog}
                                        onClose={closeMoveResourcesToPolicyDialog}
                                    />
                                </div>
                            ) : null}
                            <div>
                                <Button variant="text" onClick={openRemoveResourceFromPolicyDialog} endIcon="delete">
                                    Remove
                                </Button>
                                <BasicDialog
                                    open={isRemoveResourceFromPolicyDialogOpen}
                                    anchorElement={removeResourceFromPolicyDialogAnchorElement}
                                    icon="error"
                                    title={`Are you sure you want to remove the selected ${resourcePluralKeyWord}?`}
                                    content="This action is undoable if removed."
                                    action1Text="Yes, remove"
                                    onAction1={handleRemoveResourcesFromPolicy}
                                    action2Text="No, cancel"
                                    onAction2={closeRemoveResourceFromPolicyDialog}
                                    onClose={closeRemoveResourceFromPolicyDialog}
                                />
                            </div>
                        </>
                    ) : null}
                </Layout>
                <Layout stack pl={25} flex="1 0 100%">
                    <CheckboxList
                        optionLabel="name"
                        optionValue="_id"
                        selected={selectedPolicyResources}
                        onChange={handleResourceListChange}
                        options={filteredPolicyResources}
                        labelRenderer={resource => (
                            <Typography variant="label" size="large" prominent>
                                {resource.name}
                            </Typography>
                        )}
                        emptyStateText={`No ${resourcePluralKeyWord} in this policy`}
                    />
                </Layout>
            </Layout>
        </Layout>
    );

    return (
        <Modal
            className={classNames(offsetClassNames, classes.modal)}
            title={title}
            content={content}
            onClose={handleClose}
        />
    );
});

const useStyles = makeStyles({
    modal: {
        '& .MuiPaper-root': {
            minHeight: '600px',
        },
    },
    resourcesHeader: {
        minHeight: '40px',
    },
});
