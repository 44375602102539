import { values, filter, includes, map, isObject } from 'lodash';
import { format } from 'date-fns';
import { statuses as resourceStatuses, roles } from 'enums/resourceEnum';
import { hasRole } from 'utils/rightsUtil';

export const NOT_SET = { value: 'NOT_SET', label: 'New Column', type: 'custom', accessor: () => '' };

export const CUSTOM_FIELDS = {
    value: 'CUSTOM_FIELDS',
    label: 'Custom Field',
    type: 'custom',
    accessor: (data, customFieldId) => {
        return isObject(data[customFieldId]) ? map(data[customFieldId], 'value').join(', ') : data[customFieldId] || '';
    },
};

export const RESOURCE_STATUS = {
    value: 'RESOURCE_STATUS',
    label: 'Status',
    type: 'resource',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingResourceStatus'),
    accessor: resource => resourceStatuses[resource.status]?.display || '',
};
export const RESOURCE_ROLE = {
    value: 'RESOURCE_ROLE',
    label: 'Role',
    type: 'resource',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingResourceRole'),
    accessor: resource => roles[resource.role] && roles[resource.role].display,
};
export const RESOURCE_IS_PM = {
    value: 'RESOURCE_IS_PM',
    label: 'PM',
    type: 'resource',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingResourcePro'),
    accessor: resource => resource.isProjectManager,
};
export const RESOURCE_TAGS = {
    value: 'RESOURCE_TAGS',
    label: 'Tags',
    type: 'resource',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingResourceTags'),
    accessor: resource => resource.tags?.join(', ') || '',
};
export const RESOURCE_THUMBNAIL = {
    value: 'RESOURCE_THUMBNAIL',
    label: 'Profile Image',
    type: 'resource',
    accessor: resource => resource.thumb,
};

export const PROJECT_CUSTOMER = {
    value: 'PROJECT_CUSTOMER',
    label: 'Customer',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectClient'),
    accessor: project => project.customers?.join(', '),
};
export const PROJECT_TAGS = {
    value: 'PROJECT_TAGS',
    label: 'Tags',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectTags'),
    accessor: project => project.tags?.join(', ') || '',
};
export const PROJECT_STATUS = {
    value: 'PROJECT_STATUS',
    label: 'Status',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectStatus'),
    accessor: project => project.status,
};
export const PROJECT_END_DATE = {
    value: 'PROJECT_END_DATE',
    label: 'End Date',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectDates'),
    accessor: project => (project.end ? format(new Date(project.end), 'dd/MM/yyyy') : ''),
};
export const PROJECT_START_DATE = {
    value: 'PROJECT_START_DATE',
    label: 'Start Date',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectDates'),
    accessor: project => (project.start ? format(new Date(project.start), 'dd/MM/yyyy') : ''),
};
export const PROJECT_PROJECT_MANAGERS = {
    value: 'PROJECT_PROJECT_MANAGERS',
    label: "PM's",
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectPm'),
    accessor: project => project.projectManagerNames?.join(', '),
};
export const PROJECT_THUMBNAIL = {
    value: 'PROJECT_THUMBNAIL',
    label: 'Image',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectInfo'),
    accessor: project => project.thumb,
};
export const PROJECT_COLOR = {
    value: 'PROJECT_COLOR',
    label: 'Project Color',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectInfo'),
    accessor: project => project.backgroundColor,
};
export const PROJECT_CODE = {
    value: 'PROJECT_CODE',
    label: 'Project Code',
    type: 'project',
    disabled: resourceRoleRights => !hasRole(resourceRoleRights, 'settingProjectInfo'),
    accessor: project => project.projectCode,
};

export const columnTypes = {
    NOT_SET,
    CUSTOM_FIELDS,
    RESOURCE_STATUS,
    RESOURCE_ROLE,
    RESOURCE_IS_PM,
    RESOURCE_TAGS,
    RESOURCE_THUMBNAIL,
    PROJECT_CUSTOMER,
    PROJECT_TAGS,
    PROJECT_STATUS,
    PROJECT_START_DATE,
    PROJECT_END_DATE,
    PROJECT_PROJECT_MANAGERS,
    PROJECT_THUMBNAIL,
    PROJECT_COLOR,
    PROJECT_CODE,
};

export const columnTypesByType = (type, { isPMExtensionInstalled, isCustomFieldExtensionInstalled }) =>
    filter(values(columnTypes), column => {
        if (
            (!isPMExtensionInstalled &&
                includes([RESOURCE_IS_PM.value, PROJECT_PROJECT_MANAGERS.value], column.value)) ||
            (!isCustomFieldExtensionInstalled && CUSTOM_FIELDS.value === column.value)
        ) {
            return false;
        }
        return column.type === type;
    });
