import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDaypilotChildrenResources,
    getDaypilotResources,
    getDaypilotUnassignedResources,
    initScheduler,
} from 'actions/schedulerActions';
import { useParams } from 'react-router-dom';
import {
    schedulerResourcesSelector,
    selectSchedulerIsDPResourcesLoading,
    selectRedrawSchedulerFlag,
    selectSchedulerInitialized,
    selectSchedulerFilters,
    selectSchedulerMode,
    selectSchedulerDates,
    selectSchedulerFilterRelation,
} from '../../../selectors/scheduler';
import { getViewObject } from '../utils/schedulerUtil';
import { LEGACY, PARENT } from '../enums/viewModeEnum';
import useSchedulerRef from '../../../shared/hooks/useSchedulerRef';
import { map } from 'lodash';
import { unassignedChildObject } from '../config/formater/unassignedChild';
import { useUnassignedMenu } from './useUnassignedMenu';
import { makeGetProjectFromGroupById } from '../../../selectors/projectGroup';
import { useScheduler } from './useScheduler';
import { PROJECT, RESOURCE } from '../enums/builderTypeEnum';

export const useDPResources = (itemTypeParam, { match, updateProjectRequest }) => {
    const dispatch = useDispatch();
    const dpResources = useSelector(schedulerResourcesSelector);
    const isDPResourcesLoading = useSelector(selectSchedulerIsDPResourcesLoading);
    const filtersApplied = useSelector(selectSchedulerFilters);
    const viewMode = useSelector(selectSchedulerMode);
    const { schedulerRef } = useSchedulerRef();
    const itemType = itemTypeParam === PROJECT.name && viewMode === LEGACY.value ? RESOURCE.name : itemTypeParam;

    const sortedColumn = useSelector(
        state => state.account.preferences.schedulerColumns[`${itemType}Columns`].sortedColumn
    );

    const isSchedulerInitialized = useSelector(selectSchedulerInitialized);
    const redrawSchedulerFlag = useSelector(selectRedrawSchedulerFlag);
    const routeParams = useParams();
    const viewObject = getViewObject(routeParams);
    const { isGroupView, isProjectView, isResourceView } = viewObject;
    const groupId = routeParams.resourceGroupId || routeParams.projectGroupId;
    const itemId = routeParams.resourceId || routeParams.projectId;
    const schedulerDates = useSelector(selectSchedulerDates);
    const filterRelation = useSelector(selectSchedulerFilterRelation);

    const dpResourcesFiltered = useMemo(() => {
        // Filter DP Rows based on filtered nested children
        if (viewMode === PARENT.value) {
            // View mode - Grouped, project view, resource row filters applied,
            // show only projects that have one of those resource based filters as nested
            if (isProjectView) {
                const resourceFiltersKeys = Object.keys(filtersApplied || {}).filter(
                    filterKey => filterKey.indexOf('resource') === 0
                );

                const resourceFiltersApplied = resourceFiltersKeys.some(resourceFilterKey => {
                    return (
                        (filtersApplied[resourceFilterKey]?.filters || []).length > 0 ||
                        (!Array.isArray(filtersApplied[resourceFilterKey]?.filters) &&
                            !!filtersApplied[resourceFilterKey]?.filters)
                    );
                });

                if (resourceFiltersApplied) {
                    return dpResources.filter(dpResource => (dpResource?.resources || []).length > 0);
                }
            }

            // View mode - Grouped, resource view, project row filters applied,
            // show only resources that have one of those projects as nested
            if (isResourceView) {
                const projectFiltersKeys = [
                    ...Object.keys(filtersApplied || {}).filter(filterKey => filterKey.indexOf('project') === 0),
                    // Other row filters project based
                    'currencies',
                    'customers',
                ];
                const projectFiltersApplied = projectFiltersKeys.some(projectFilterKey => {
                    return (
                        (filtersApplied[projectFilterKey]?.filters || []).length > 0 ||
                        (!Array.isArray(filtersApplied[projectFilterKey]?.filters) &&
                            !!filtersApplied[projectFilterKey]?.filters)
                    );
                });
                if (projectFiltersApplied) {
                    return dpResources.filter(dpResource => (dpResource?.projects || []).length > 0);
                }
            }
        }
        // return normal dpResources
        return dpResources;
    }, [dpResources, filtersApplied, isProjectView, isResourceView, viewMode]);

    useEffect(() => {
        const refreshDPResources = ({ cb, rowId } = {}) => {
            let row;

            if (rowId) {
                row = schedulerRef?.current?.control.resources.find(row => row.id === rowId);
            }

            if (rowId && !row) {
                const parent = schedulerRef?.current?.control.resources.find(
                    row => row.expanded && row.tags.project?.resources?.includes(rowId)
                );

                if (parent) {
                    dispatch(
                        getDaypilotChildrenResources.request({
                            parentId: parent.tags.project._id,
                            childrenIds: parent.tags.project.resources,
                        })
                    );

                    return;
                }
            }

            const requestPayload = {
                isGroupView,
                itemId,
                groupId,
                itemType: itemTypeParam,
                page: 1,
                limit: 9999,
                sortBy: {
                    column: sortedColumn.columnId,
                    direction: 'ASCENDING' === sortedColumn.sortDirection ? 1 : -1,
                },
            };

            requestPayload.projectGroupId = routeParams.projectGroupId;
            dispatch(getDaypilotResources.request(requestPayload, cb));
        };

        const listener = customEvent => {
            refreshDPResources(customEvent?.detail || {});
        };

        window.addEventListener('refreshDPResources', listener);

        return () => {
            window.removeEventListener('refreshDPResources', listener);
        };
    }, [
        dispatch,
        itemTypeParam,
        sortedColumn,
        viewMode,
        itemId,
        routeParams.projectGroupId,
        groupId,
        isGroupView,
        schedulerRef,
    ]);

    const projectSelector = useMemo(() => makeGetProjectFromGroupById(match.params.projectId), [
        match.params.projectId,
    ]);
    const projectGroup = useSelector(state => state.scheduler.group);
    const project = useSelector(projectSelector);
    const resourceIdsInCurrentView = useMemo(() => map(dpResourcesFiltered, '_id'), [dpResourcesFiltered]);
    const { showResourceModal } = useScheduler(schedulerRef);
    const { unassignedChildContextMenu } = useUnassignedMenu(
        viewObject.isSingleProjectView ? project : projectGroup,
        dpResourcesFiltered,
        resourceIdsInCurrentView,
        {
            showResourceModal,
            updateProject: updateProjectRequest,
        },
        viewObject
    );

    useEffect(() => {
        const refreshUnassignedDPResources = () => {
            if (!schedulerRef?.current?.control || !schedulerRef?.current?.__isMounted) {
                return;
            }

            const schedulerInstance = schedulerRef.current.control;

            if (viewObject.isProjectView && schedulerInstance.mode === PARENT.value) {
                const unassignedRow = schedulerInstance.rows.find('unassignedRow');

                if (!unassignedRow || !unassignedRow.data?.expanded) {
                    return;
                }

                dispatch(
                    getDaypilotUnassignedResources.request({
                        itemId: itemId,
                        groupId: groupId,
                        onSuccess: response => {
                            unassignedRow.data.children = [];
                            unassignedRow.data.children = response.scheduler.records.map(resource => {
                                return unassignedChildObject(resource, unassignedChildContextMenu);
                            });

                            schedulerInstance.update();
                        },
                    })
                );
            }
        };

        const listener = customEvent => {
            refreshUnassignedDPResources(customEvent?.detail || {});
        };

        window.addEventListener('refreshUnassignedDPResources', listener);

        return () => {
            window.removeEventListener('refreshUnassignedDPResources', listener);
        };
    }, [
        dispatch,
        filterRelation,
        filtersApplied,
        groupId,
        itemId,
        schedulerDates,
        schedulerRef,
        unassignedChildContextMenu,
        viewObject,
    ]);

    useEffect(() => {
        dispatch(initScheduler());
    }, [itemId, groupId, dispatch]);

    return {
        dpResources: dpResourcesFiltered,
        isLoading: isDPResourcesLoading,
        isSchedulerInitialized,
        redrawSchedulerFlag,
    };
};
