import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    makeGetProjectsByResourceIdForSchedule,
    makeGetProjectsAndEventsForSchedule,
    makeGetActiveEvents,
    makeGetFilteredProjects,
} from 'selectors/project';
import {
    makeGetResourcesByProjectForSchedule,
    makeGetResourcesForSchedule,
    getResources as getResourcesSelector,
    makeGetFilteredResources,
} from 'selectors/resource';
import { SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { useGetViewObject } from 'modules/scheduler/hooks/useGetViewObject';
import { useLoadProjectsAndResources } from 'hooks';
import { selectSchedulerMode } from '../../../selectors/scheduler';

export const useSchedulerData = (
    resourceId,
    project,
    {
        allPermittedResources,
        allPermittedProjects,
        onlyProjects,
        onlyEvents,
        onlyUnassigned,
        canRequestResources,
        excludeUnassigned,
        filterResourcesFunction,
        noDataRefresh,
        hideArchived,
    } = {}
) => {
    useLoadProjectsAndResources(noDataRefresh);

    const { isProjectView, isSingleResourceView } = useGetViewObject();
    const mode = useSelector(selectSchedulerMode);

    const removeUnassigned =
        excludeUnassigned === undefined
            ? (mode === SINGLE.value && !isProjectView) || isSingleResourceView
            : excludeUnassigned;

    const resourcesSelector = useMemo(() => {
        if (allPermittedResources) {
            return makeGetFilteredResources();
        }

        if (project && project._id) {
            return makeGetResourcesByProjectForSchedule(project, {
                onlyUnassigned,
                removeUnassigned,
                canRequestResources,
            });
        }

        return makeGetResourcesForSchedule({
            onlyUnassigned,
            removeUnassigned,
        });
    }, [allPermittedResources, project, onlyUnassigned, removeUnassigned, canRequestResources]);

    const resourcesFromSelector = useSelector(resourcesSelector);
    const resources = (resourcesFromSelector || []).filter(resourceTmp => {
        if (filterResourcesFunction) {
            return filterResourcesFunction(resourceTmp);
        }
        return true;
    });

    const allResources = useSelector(getResourcesSelector);
    const projectsSelector = useMemo(() => {
        if (hideArchived) {
            return makeGetFilteredProjects('EXCLUDE_ARCHIVED');
        }

        if (allPermittedProjects) {
            return makeGetFilteredProjects('ALL');
        }

        if (resourceId) {
            return makeGetProjectsByResourceIdForSchedule(resourceId, allResources, {
                onlyUnassigned,
                onlyEvents,
                onlyProjects,
                canRequestResources,
            });
        }
        return onlyEvents
            ? makeGetActiveEvents()
            : makeGetProjectsAndEventsForSchedule(allResources, canRequestResources);
    }, [
        allPermittedProjects,
        resourceId,
        onlyEvents,
        allResources,
        canRequestResources,
        onlyUnassigned,
        onlyProjects,
        hideArchived,
    ]);

    const projects = useSelector(projectsSelector);

    return {
        resources,
        projects,
    };
};
