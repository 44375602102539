import * as actionTypes from './actionTypes';
import { action } from 'actions/base';

export const sendBugRequest = data => ({
    type: actionTypes.SEND_BUG_REQUEST,
    payload: {
        data,
    },
});

export const sendContactRequest = data => ({
    type: actionTypes.SEND_CONTACT_REQUEST,
    payload: {
        data,
    },
});

export const getGeoLocation = {
    success: geoLocation => action(actionTypes.GEO_LOCATION['SUCCESS'], { geoLocation }),
};

export const sendInviteEmail = {
    request: ({ resourceId }) => action(actionTypes.SEND_INVITE_EMAIL['REQUEST'], { resourceId }),
    success: () => action(actionTypes.SEND_INVITE_EMAIL['SUCCESS']),
    failure: () => action(actionTypes.SEND_INVITE_EMAIL['FAILURE']),
};

export const sendInviteEmails = {
    request: ({resourceIds}) => action(actionTypes.SEND_INVITE_EMAILS['REQUEST'], {resourceIds}),
    success: () => action(actionTypes.SEND_INVITE_EMAILS['SUCCESS']),
    failure: () => action(actionTypes.SEND_INVITE_EMAILS['FAILURE']),
};

export const sendResetPasswordEmail = {
    request: ({ resourceId }) => action(actionTypes.SEND_RESET_PASSWORD_EMAIL['REQUEST'], { resourceId }),
    success: () => action(actionTypes.SEND_RESET_PASSWORD_EMAIL['SUCCESS']),
    failure: () => action(actionTypes.SEND_RESET_PASSWORD_EMAIL['FAILURE']),
};
