import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getCustomers = {
    request: () => action(actionTypes.GET_CUSTOMERS['REQUEST']),
    success: customers => action(actionTypes.GET_CUSTOMERS['SUCCESS'], { customers }),
};

export const createCustomer = {
    request: (data, formName, fieldName) =>
        action(actionTypes.CREATE_CUSTOMER['REQUEST'], { data, formName, fieldName }),
    success: customer => action(actionTypes.CREATE_CUSTOMER['SUCCESS'], { customer }),
};
