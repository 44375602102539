import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    displayCurrencyValue,
    displayStatusByType,
    displayValue,
    isHeaderDisabled,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { formatNumber } from 'utils/formatingUtil';
import { Header, Status } from './../styles';
import { BUDGET_STATUSES_ENUM, statusesList } from 'modules/report/enums/budgetStatusEnum';
import StatusFilterBox from './../../containers/tableFilters/statusFilterBox';
import ProgressBarCell from 'modules/report/components/tableCells/progressBarCell';
import PercentageCell from 'modules/report/components/tableCells/percentageCell';

/**
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Remaining
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'externalRemainingReportedBudgetCashAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'externalRemainingReportedBudgetCashAllTime'),
        accessor: row =>
            displayValue(
                row.externalRemainingReportedBudgetCashAllTime,
                displayCurrencyValue(row, 'externalRemainingReportedBudgetCashAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Remaining
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'internalRemainingReportedBudgetCashAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'internalRemainingReportedBudgetCashAllTime'),
        accessor: row =>
            displayValue(
                row.internalRemainingReportedBudgetCashAllTime,
                displayCurrencyValue(row, 'internalRemainingReportedBudgetCashAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Cash
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'externalConsumedReportedBudgetCashAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'externalConsumedReportedBudgetCashAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.externalConsumedReportedBudgetCashAllTimePercentage,
                formatNumber(row.externalConsumedReportedBudgetCashAllTimePercentage || 0) + '%'
            ),
        Cell: PercentageCell,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Cash
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalConsumedReportedBudgetCashAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'internalConsumedReportedBudgetCashAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.internalConsumedReportedBudgetCashAllTimePercentage,
                formatNumber(row.internalConsumedReportedBudgetCashAllTimePercentage || 0) + '%'
            ),
        Cell: PercentageCell,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Status
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'externalReportedBudgetCashAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'externalReportedBudgetCashAllTimeStatus'),
        accessor: row => displayValue(row.externalReportedBudgetCashAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Cash Status
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'internalReportedBudgetCashAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'internalReportedBudgetCashAllTimeStatus'),
        accessor: row => displayValue(row.internalReportedBudgetCashAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Hours Remaining
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'remainingReportedBudgetHoursAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'remainingReportedBudgetHoursAllTime'),
        accessor: row =>
            displayValue(
                row.remainingReportedBudgetHoursAllTime,
                formatNumber(row.remainingReportedBudgetHoursAllTime || 0, true)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        % Budget Hours
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'consumedReportedBudgetHoursAllTimePercentage',
        sortable: false,
        show: contains(columnsToLoad, 'consumedReportedBudgetHoursAllTimePercentage'),
        accessor: row =>
            displayValue(
                row.consumedReportedBudgetHoursAllTimePercentage,
                formatNumber(row.consumedReportedBudgetHoursAllTimePercentage || 0) + '%'
            ),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Hour Status
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'value' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={statusesList()}
                    />
                </Header>
            );
        },
        id: 'reportedBudgetHoursAllTimeStatus',
        sortable: false,
        show: contains(columnsToLoad, 'reportedBudgetHoursAllTimeStatus'),
        accessor: row => displayValue(row.reportedBudgetHoursAllTimeStatus),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={BUDGET_STATUSES_ENUM}>
                    {BUDGET_STATUSES_ENUM.NO !== row.value && <span className="status-circle" />}
                    <span className="status-text">{displayStatusByType(row.value, BUDGET_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
];
