import { UNASSIGNED } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @returns {*[]}
 */
export default dateState => [
    // PROJECT
    {
        id: 'projectSpecificScheduledTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: true,
    },
    {
        id: 'projectSpecificScheduledTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificScheduledTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificScheduledTimeFuture',
        display: 'Future',
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificScheduledTimeToDate',
        display: 'To Date',
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificScheduledTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Project Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    // EVENT
    {
        id: 'eventSpecificScheduledTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: true,
    },
    {
        id: 'eventSpecificScheduledTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificScheduledTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificScheduledTimeFuture',
        display: 'Future',
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificScheduledTimeToDate',
        display: 'To Date',
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificScheduledTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Event Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    // RESOURCE
    {
        id: 'resourceSpecificScheduledTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: true,
    },
    {
        id: 'resourceSpecificScheduledTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificScheduledTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificScheduledTimeFuture',
        display: 'Future',
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificScheduledTimeToDate',
        display: 'To Date',
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificScheduledTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Resource Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        heatMapSupport: false,
    },
    // UNASSIGNED
    {
        id: 'unassignedSpecificScheduledTimeBetween',
        display: displayDateState(dateState, false, false),
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: true,
    },
    {
        id: 'unassignedSpecificScheduledTimeAfter',
        display: `Next ${displayDateState(dateState)}`,
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificScheduledTimePrevious',
        display: `Previous ${displayDateState(dateState)}`,
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificScheduledTimeFuture',
        display: 'Future',
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificScheduledTimeToDate',
        display: 'To Date',
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificScheduledTimeAllTime',
        display: 'All Time',
        displayTimeType: 'Unassigned Scheduled Time',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.SCHEDULED,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
];
