/* eslint-env browser */
import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { Button } from 'reactstrap';
import { find, isUndefined } from 'underscore';
import BaseFilterBox from './baseFilterBox';
import CheckboxList from 'shared/checkboxList';
import { FiltersBox, Content, Footer, FilterIcon } from './styles';

class StatusFilterBox extends BaseFilterBox {
    static propTypes = {
        columnName: PropTypes.string.isRequired,
        disabled: PropTypes.bool,
        fields: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string,
            additional: PropTypes.string,
            wrapWithBrackets: PropTypes.bool,
        }).isRequired,
    };

    static defaultProps = {
        disabled: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            toggleBox: false,
            filterText: '',
            selectedItems: [],
            active: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { columnName, queryParams } = this.props;
        if (!isEqual(prevProps.queryParams.filters[columnName], queryParams.filters[columnName])) {
            this.setActiveState();
        }
    }

    onFilter = () => {
        this.toggle();
        const currentFilter = {
            columnName: this.props.columnName,
            operator: this.state.operator,
            boundary: this.state.selectedItems,
        };
        this.updateFilter(currentFilter);
    };

    setActiveState = () => {
        const {
            columnName,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const foundFilter = find(resultValue, filter => filter.columnName === columnName);
        const selectedItems = foundFilter ? foundFilter.boundary : [];
        const operator = selectedItems && selectedItems.length ? foundFilter.operator : 'eq';
        this.setState({
            active: !isUndefined(foundFilter),
            selectedItems,
            operator,
        });
    };

    render() {
        const { selectedItems, active, toggleBox } = this.state;
        const positions = this.node && this.node.getBoundingClientRect();

        return (
            <div className="position-absolute" style={{ right: '3px', top: '3px' }} ref={node => (this.node = node)}>
                {!this.props.disabled && (
                    <FilterIcon
                        open={toggleBox}
                        active={active.toString()}
                        className="fa fa-filter"
                        aria-hidden="true"
                        onClick={this.toggle}
                    />
                )}
                {toggleBox && (
                    <FiltersBox
                        position={positions && 600 < positions.left ? 'right' : 'left'}
                        height={185}
                        className="mr-2"
                    >
                        <Content>
                            <CheckboxList
                                searchField={false}
                                showSelected={false}
                                data={this.props.data}
                                fields={this.props.fields}
                                height={100}
                                scrollMargin={false}
                                rowHeight={25}
                                selectedItems={selectedItems}
                                onSelect={selectedItems => this.setState({ selectedItems })}
                                dataCy="status-filters-box-list"
                            />
                        </Content>
                        <Footer>
                            <Button disabled={!selectedItems.length} color="success" onClick={this.onFilter}>
                                Filter
                            </Button>
                            <Button disabled={!active} color="secondary" onClick={this.onClear}>
                                Clear
                            </Button>
                        </Footer>
                    </FiltersBox>
                )}
            </div>
        );
    }
}

export default withRouter(StatusFilterBox);
