/* eslint-env browser */
import { availabilityHandler } from 'modules/scheduler/utils/schedulerUtil';

export default viewObject => args => {
    if (!args.cell?.calendar) {
        return;
    }
    const { availabilityHover, availabilityDisplay } = args.cell.calendar;
    const shouldAddAvailability = availabilityHandler(args, { availabilityHover, availabilityDisplay }, viewObject);
    if (shouldAddAvailability) {
        showAvailability(args, availabilityDisplay);
    }
};

const showAvailability = (args, availabilityDisplay) => {
    const utilisationHolder = args.cell.div && args.cell.div.querySelector('.utilisation-holder');
    if (utilisationHolder && 'TYPE_OFF' !== availabilityDisplay) {
        let actualAvailability = args.cell.properties.actualAvailability;

        if (0 === args.cell.properties.resourceCapacity) {
            actualAvailability = 0;
        }
        const tag = document.createElement('div');
        const text = document.createTextNode(actualAvailability);
        tag.className = 'utilisation-holder-partial';
        tag.appendChild(text);

        utilisationHolder.appendChild(tag);
    }
};
