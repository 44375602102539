import React from 'react';
import { Modal, Button } from 'reactstrap';
import { hideModal } from 'actions/modalActions';
import { useDispatch } from 'react-redux';
import { useModalOffset } from 'hooks/useModalOffset';
import { isModalView } from 'utils/isModalView';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { MissingToilExtensionContent } from 'modules/toil/missingExtension';

export const useStyles = makeStyles({
    escBtn: {
        marginTop: `30px !important`,
        marginRight: `30px !important`,
    },
});

const StyledContainer = styled.div`
    padding-top: 20px;
    min-height: calc(100vh - 100px);
`;

export const LearnMoreAboutToilModal = () => {
    const dispatch = useDispatch();
    const fullScreen = isModalView();

    const offsetClassNames = useModalOffset(`rounded-0 col-sm-9 col-lg-9 col-md-8 col-xl-10 px-0`, fullScreen);

    const classes = useStyles();

    return (
        <Modal
            className="custom-modal"
            contentClassName={offsetClassNames || 'rounded-0 col-sm-12 col-lg-12 col-md-12 col-xl-12 px-0'}
            size="lg"
            isOpen
            fade={false}
            toggle={() => dispatch(hideModal())}
        >
            <Button
                className={classes.Btn}
                data-cy="close-extension-modal"
                color="esc"
                onClick={() => dispatch(hideModal())}
            >
                <Close />
                <div>esc</div>
            </Button>
            <StyledContainer>
                <MissingToilExtensionContent />
            </StyledContainer>
        </Modal>
    );
};

export default LearnMoreAboutToilModal;
