import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import TextField from '../TextField';

const SEARCH_DEBOUNCE_TIME = 300; // [ms];

export const SearchTextInput = memo(props => {
    const [filterText, setFilterText] = useState('');

    const { onChange, dataCy, value, isControlled, placeholder = "I'm looking for..." } = props;

    const debouncedFilter = useMemo(() => debounce(onChange, isControlled ? 0 : SEARCH_DEBOUNCE_TIME), [
        onChange,
        isControlled,
    ]);

    useEffect(() => {
        return () => {
            if (debouncedFilter && typeof debouncedFilter.cancel === 'function') {
                debouncedFilter.cancel();
            }
        };
    }, [debouncedFilter]);

    const handleSearchInputChange = useCallback(
        event => {
            const text = event.target.value;
            setFilterText(text);
            debouncedFilter(text);
        },
        [debouncedFilter]
    );

    return (
        <TextField
            variant="outlined"
            label="Search"
            inputProps={{
                'data-cy': dataCy,
            }}
            placeholder={placeholder}
            value={value ?? filterText}
            onChange={handleSearchInputChange}
            inputLabelProps={{ shrink: true }}
            fullWidth
        />
    );
});

SearchTextInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    dataCy: PropTypes.string,
};
