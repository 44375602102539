import { createSelector } from 'reselect';
import { filter, find, sortBy } from 'lodash';
import { ACTIVE, EVENT, MY_PROJECTS } from 'enums/criteriaEnum';
import { GROUP } from 'enums/groupTypeEnum';
import { SYSTEM, USER } from 'modules/scheduler/enums/groupTypeEnum';

export const getProjectGroups = state => state.projectGroupReducer.projectGroups;
export const isProjectGroupsLoading = state => state.projectGroupReducer.isProjectGroupsLoading;
export const isProjectGroupsLoaded = state => !state.projectGroupReducer.isProjectGroupsLoading;
export const getProjectGroupsProjects = state => state.projectGroupReducer.projects;
export const isProjectsLoading = state => state.projectGroupReducer.isProjectsLoading;
export const selectLastCallContext = state => state.projectGroupReducer.lastCallContext;
export const selectProjectGroupsInitialized = state => state.projectGroupReducer.groupsInitialized;

export const makeGetProjectGroups = filterType =>
    createSelector(getProjectGroups, projectGroups => {
        let filteredGroups = projectGroups;

        switch (filterType) {
            case 'TYPE_USER_AND_NOT_SMART':
                filteredGroups = filter(
                    projectGroups,
                    projectGroup => USER === projectGroup.groupType && !projectGroup.isSmart
                );
                break;
            case 'CRITERIA_ACTIVE':
                filteredGroups = filter(projectGroups, projectGroup => ACTIVE === projectGroup.criteria);
                break;
            case 'CRITERIA_NOT_EVENT':
                filteredGroups = filter(projectGroups, projectGroup => EVENT !== projectGroup.criteria);
                break;
            case 'TYPE_SYSTEM':
                filteredGroups = filter(projectGroups, projectGroup => SYSTEM === projectGroup.groupType);
                break;
            case 'TYPE_GROUP':
                filteredGroups = filter(
                    projectGroups,
                    projectGroup => !projectGroup.type || GROUP === projectGroup.type
                );
                break;
        }

        return sortBy(filteredGroups, group => group.name.toLowerCase());
    });

export const makeGetProjectGroupById = id =>
    createSelector(
        getProjectGroups,
        projectGroups => find(projectGroups, projectGroup => projectGroup._id === id) || {}
    );

// for object reference stability
const EMPTY_OBJECT = {};

export const makeGetProjectFromGroupById = id =>
    createSelector(
        getProjectGroupsProjects,
        projects => (id && find(projects, project => project._id === id)) || EMPTY_OBJECT
    );

export const selectMyProjectsGroup = createSelector(
    getProjectGroups, projectGroups => {
        return projectGroups?.find(group => group.criteria === MY_PROJECTS);
    }
)

export const selectAvailableMyProjectIdsMap = createSelector(selectMyProjectsGroup, myProjectGroup => {
    if (!myProjectGroup?.projects?.length) {
        return EMPTY_OBJECT;
    }

    return myProjectGroup.projects.reduce((acc, projectId) => {
        acc[projectId] = true;
        return acc;
    }, {});
});
