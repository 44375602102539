import StorageUtil from 'utils/StorageUtil';
import { map } from 'lodash';
import { unassignedChildObject } from './unassignedChild';

export const unassignedObject = ({
    unassignedResources,
    contextMenu,
    unassignedChildContextMenu,
    hasManageUnassignedRowsRights,
    showUnassignedParentArrow,
    additionalParams,
}) => ({
    name: 'Unassigned Work',
    id: 'unassignedRow',
    expanded: 'collapsed' !== StorageUtil.getItem('unassignedRow', 'localStorage') && !showUnassignedParentArrow,
    tags: {
        type: 'UNASSIGNED_PARENT',
        isEventRow: false,
        isResourceRow: false,
        unassignedRow: false,
        unassignedParentRow: true,
        menuRow: false,
        parent: 0 < unassignedResources.length || showUnassignedParentArrow,
        childrenIds: additionalParams.unassignedResourceGroup?.resources ?? [],
    },
    areas: hasManageUnassignedRowsRights
        ? [
              {
                  visibility: 'Visible',
                  action: 'ContextMenu',
                  bottom: 0,
                  width: 30,
                  html: `<i data-cy="scheduler-row-uw-menu" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                  cssClass: 'resource-action-menu',
                  top: 0,
                  menu: contextMenu,
                  right: 0,
              },
          ]
        : [],
    children: unassignedResources
        ? map(unassignedResources, resource => unassignedChildObject(resource, unassignedChildContextMenu))
        : [],
});
