import { formatNumber } from 'utils/formatingUtil';
import { DAY } from 'modules/scheduler/enums/scale';
import { getCellUtilizationMinutes } from 'modules/scheduler/utils/schedulerUtil';
import { eventsForRangeCache } from '../../../../../utils/eventsForRangeCache';
import { UNASSIGNED } from '../../../../../enums/criteriaEnum';
import { arrayToObject } from '../../../../../utils/mappingUtil';

export default (args, row, data, resourceCapacity) => {
    const bookedMinutesChildCell = addUpUnassignedHours(args, row, data, resourceCapacity);

    if (0 < bookedMinutesChildCell) {
        const hours = formatNumber(bookedMinutesChildCell / 60);
        args.cell.html = `<div title="${hours} hrs Unassigned for this day" class="unassigned-holder-parent">${hours} <span class="hours">hrs</span></div>`;
    } else {
        args.cell.html = '';
    }
};

const addUpUnassignedHours = (args, row, data, resourceCapacity) => {
    const scheduler = args.cell.calendar;
    let bookedMinutes = 0;

    if (scheduler.scale !== DAY && data.isWorkday) {
        const eventsForRange = eventsForRangeCache.getForRange(args.cell.start, args.cell.end, args.control.events);

        const childrenIds = row.children().map(row => row.id);
        const childrenIdsMapped = arrayToObject(childrenIds)

        const events = eventsForRange.filter(event => {
            if (args.cell.resource === 'unassignedRow') {
                return event.data.resourceInfo?.type === UNASSIGNED && childrenIdsMapped[event.data.resourceInfo?._id];
            }

            return -1 !== event.data.resource.indexOf(args.cell.resource);
        });

        bookedMinutes = getCellUtilizationMinutes(args.cell, resourceCapacity.totalMinutes, data, events);
    }

    return bookedMinutes;
};
