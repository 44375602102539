import Schema from 'form-schema-validation';
import { includes, find, isNil, isObject } from 'lodash';
import { format } from 'date-fns';
import moment from 'moment';
import { formatNumber } from 'utils/formatingUtil';
import { startDateValidator, negativeNumberValidator, repeatTimesValidator } from 'utils/formValidators';
import { errorMessages } from 'utils/schemaUtil';
import { SCHEDULED } from 'enums/calculationEnum';
import { CELLDURATION, findByValue } from 'modules/scheduler/enums/scale';
import { formatApprovers } from 'modules/scheduler/utils/eventUtil';
import { WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import { intervalOptions } from 'shared/repeat';
import { getBookingAllocationValuesFromSingleValue } from 'shared/lib/booking';
import { getResourceCapacity } from 'utils/capacityCalculation';
import { PERCENTAGE, HOURS_DAY, TOTAL_HOURS } from 'enums/allocationTypeEnum';
import { getISODate } from 'shared/lib/date';
import { store } from '../store';
import { getDefaultCategoryByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { getAllocationValuesFromCategory } from '../shared/allocation/getAllocationValuesFromCategory';

export const formSchema = new Schema(
    {
        project: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            required: false,
            defaultValue: null,
        },
        startDate: {
            type: Date,
            required: true,
            defaultValue: new Date(),
            validators: [startDateValidator('endDate')],
            validatorInterdependent: true,
        },
        endDate: {
            type: Date,
            required: true,
            defaultValue: new Date(),
        },
        resource: {
            type: Object,
            required: true,
            defaultValue: null,
        },
        state: {
            type: String,
            required: true,
        },
        type: {
            type: String,
        },
        percentage: {
            type: Number,
            required: true,
            defaultValue: 100,
            validators: [negativeNumberValidator],
        },
        hours: {
            type: Number,
            required: true,
            validators: [negativeNumberValidator],
        },
        total: {
            type: Number,
            required: true,
            validators: [negativeNumberValidator],
        },
        interval: {
            type: Object,
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
    },
    errorMessages,
    false
);

export const allocationTypeMap = {
    [PERCENTAGE.state]: {
        name: 'percentage',
        propertyValueFn: data => (!isNil(data.percentAllocation) ? formatNumber(data.percentAllocation) : 100),
    },
    [HOURS_DAY.state]: {
        name: 'hours',
        propertyValueFn: (data, avgDailyCapacity) =>
            !isNil(data.minutesPerDay) ? formatNumber(data.minutesPerDay / 60) : avgDailyCapacity,
    },
    [TOTAL_HOURS.state]: {
        name: 'total',
        propertyValueFn: (data, avgDailyCapacity, workDaysCount) => {
            return !isNil(data.totalBucketMinutesAllocation)
                ? formatNumber(data.totalBucketMinutesAllocation / 60)
                : typeof workDaysCount === 'number'
                ? workDaysCount * avgDailyCapacity
                : avgDailyCapacity;
        },
    },
};

export const getDefaultValues = (
    { resourceInfo, categoryTemplate, project, useCategoriesAllocation, ...data } = {},
    categoryGroups
) => {
    const startDate = data.start ? new Date(data.start) : formSchema.getDefaultValues().startDate;
    const endDate = data.end ? new Date(data.end) : formSchema.getDefaultValues().endDate;

    const { workDaysCount, averageWorkDay } = getResourceCapacity(resourceInfo, moment(startDate), moment(endDate));

    const {
        value, nameOfValue
    } = (() => {
        if (useCategoriesAllocation) {
            const allocationValuesFromCategory = getAllocationValuesFromCategory(categoryTemplate);

            return {
                value: allocationValuesFromCategory.value,
                nameOfValue: allocationValuesFromCategory.name,
            }
        }

        const value = allocationTypeMap[data.state].propertyValueFn(
            data,
            averageWorkDay,
            workDaysCount
        );
        return {
            value: typeof value === 'string' ? parseFloat(value) : value,
            nameOfValue: allocationTypeMap[data.state].name,
        }
    })();

    const allocationValues = getBookingAllocationValuesFromSingleValue({
        value,
        nameOfValue,
        avgDailyCapacity: averageWorkDay,
        numberOfWorkDays: workDaysCount,
    });

    let defaultCategoryTemplate = categoryTemplate;
    if (project?._id && !categoryTemplate) {
        // data.project might not have property defaultCategoryTemplate. take updated from store
        const projectFromStore = (store.getState()?.projectReducer?.projects || []).find(
            proj => proj._id === project._id
        );
        const categoryByProject = getDefaultCategoryByProject(
            { ...project, ...(projectFromStore || {}) },
            store.getState()?.categoryTemplateReducer?.categoryTemplates || [],
            categoryGroups || []
        );
        if (categoryByProject) {
            defaultCategoryTemplate = categoryByProject;
        }
    }

    return Object.assign({}, formSchema.getDefaultValues(), {
        startDate,
        endDate,
        resource: resourceInfo,
        project: project || {},
        category: defaultCategoryTemplate,
        categoryGroup: find(categoryGroups, categoryGroup =>
            includes(categoryGroup.categoryTemplates, defaultCategoryTemplate._id)
        ),
        state: data.state,
        interval: data.interval
            ? intervalOptions.find(intervalOption => intervalOption.value === data.interval)
            : intervalOptions[0],
        repeatTimes: data.repeatTimes ? data.repeatTimes : 0,
        ...allocationValues,
    });
};

export const mapFormToCreateRequest = (values, startEndTimes, scale, defaultApprovers) => {
    
    return {
        project: values.project._id,
        resource: values.resource._id,
        start:
            values.startDate instanceof Date
                ? getISODate(values.startDate, { computeTimezone: true })
                : values.startDate,
        end: values.endDate instanceof Date ? getISODate(values.endDate, { computeTimezone: true }) : values.endDate,
        type: values.type || SCHEDULED.value,
        state: values.state,
        allDay: scale !== CELLDURATION.value,
        scale: findByValue(scale).BEValue,
        percentAllocation: formatNumber(values.percentage),
        minutesPerDay: formatNumber(values.hours * 60),
        totalBucketMinutesAllocation: formatNumber(values.total * 60),
        categoryTemplateId: values.category._id,
        interval: values.interval.value,
        repeat: values.repeatTimes > 0 && values.interval !== 'NONE',
        repeatTimes: values.repeatTimes,
        ...(values.type === WAITING_FOR_APPROVAL.value && isObject(values.resource)
            ? {
                  approvalInfo: {
                      approvers: formatApprovers(defaultApprovers || values.resource.defaultApproverIds),
                  },
              }
            : {}),
    };
};

export const mapFormToRequest = values => {
    return {
        project: values.project,
        resource: values.resource._id,
        resourceInfo: values.resource,
        start: format(values.startDate, BOOKING_FORMAT),
        end: format(values.endDate, BOOKING_FORMAT),
        state: values.state,
        percentAllocation: formatNumber(values.percentage),
        minutesPerDay: formatNumber(values.hours * 60),
        totalBucketMinutesAllocation: formatNumber(values.total * 60),
        categoryTemplate: values.category,
        interval: values.interval.value,
        repeatTimes: values.repeatTimes,
        repeat: values.repeatTimes > 0 && values.interval !== 'NONE',
    };
};
