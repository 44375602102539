import { useSelector } from 'react-redux';
import getLandingPage, { fixLandingPageUrl } from 'utils/landingpage';
import { getProjectGroups, selectProjectGroupsInitialized } from '../selectors/projectGroup';
import { getResourceGroups, selectResourceGroupsInitialized } from '../selectors/resourceGroup';

export const useLandingPage = () => {
    const resourceGroups = useSelector(getResourceGroups);
    const projectGroups = useSelector(getProjectGroups);
    const resourceGroupsInitialized = useSelector(selectProjectGroupsInitialized);
    const projectGroupsInitialized = useSelector(selectResourceGroupsInitialized);

    const { preferences, resourceId, landingPage } = useSelector(state => state.account);
    const systemGroupIds = useSelector(state => state.companyReducer.company.systemGroupIds);

    if (
        (landingPage?.startsWith('resource/') || landingPage?.startsWith('project/')) &&
        (!resourceGroupsInitialized || !projectGroupsInitialized)
    ) {
        return fixLandingPageUrl(landingPage);
    }

    return getLandingPage({ landingPage, resourceId, preferences, resourceGroups, projectGroups }, systemGroupIds);
};
