export const actions = (hasManageEvents, projectName, resourceName, keyWords) => {
    let actions = [
        {
            label: `Move All "${projectName}" bookings for "${resourceName}" along timeline`,
            value: 'shiftProjectResourceBookings',
            type: 'SAME_RESOURCE',
        },
        {
            label: `Move All "${projectName}" bookings for all ${keyWords.resourcePluralKeyWord} along timeline`,
            value: 'shiftProjectBookings',
            type: 'SAME_RESOURCE',
        },
    ];

    if (hasManageEvents) {
        actions = actions.concat([
            {
                label: `Move All ${keyWords.projectKeyWord} bookings for "${resourceName}" along timeline`,
                value: 'shiftResourceBookings',
                type: 'SAME_RESOURCE',
            },
            {
                label: `Move All "${projectName}" bookings for "${resourceName}" to another ${keyWords.resourceKeyWord}`,
                value: 'moveProjectBookingsBetweenResources',
                type: 'NEW_RESOURCE',
            },
            {
                label: `Move All ${keyWords.projectKeyWord} bookings for "${resourceName}" to another ${keyWords.resourceKeyWord}`,
                value: 'moveBookingsBetweenResources',
                type: 'NEW_RESOURCE',
            },
        ]);
    }

    return actions;
};

export const directions = () => [
    {
        label: 'Forward',
        value: 'FORWARD',
    },
    {
        label: 'Backward',
        value: 'BACKWARD',
    },
];

export const moveTypes = () => [
    {
        label: 'Move all bookings.',
        value: 'ALL',
    },
    {
        label: 'Specify date to move bookings from.',
        value: 'DATE',
    },
];
