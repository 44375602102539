import React, { useState, useCallback, useRef } from 'react';
import classnames from 'classnames';
import { FormGroup, Label } from 'reactstrap';
import { isString } from 'lodash';
import { Multiselect } from 'react-widgets';
import virtualize from 'react-widgets-virtualized';
import { makeStyles } from '@material-ui/core/styles';
import { Close, Layers } from '@material-ui/icons';
import { useClickOutside } from 'hooks';

const VirtualMultiselectList = virtualize(Multiselect);

const MultiSelectField = props => {
    const {
        input,
        label,
        inline,
        width,
        placeholder,
        disabled,
        showPlaceholderWithValues,
        caseSensitive,
        valueField,
        itemComponent,
        filter,
        allowCreate,
        onCreate,
        textField,
        messages,
        dropUp,
        data,
        meta: { touched, error },
        ...restOfProps
    } = props;
    input.value = isString(input.value) ? [] : input.value;

    const containerClassName = classnames('form-control', { 'is-invalid': touched && error });

    return (
        <FormGroup check={!width} inline={width ? false : inline} className={props.wrapperClassName}>
            {label && (
                <Label for={input.name} className="form-control-label">
                    {label}
                </Label>
            )}
            <VirtualMultiselectList
                {...input}
                className={classnames({ 'is-invalid': touched && error })}
                containerClassName={containerClassName}
                onBlur={() => input.onBlur && input.onBlur()}
                showPlaceholderWithValues={showPlaceholderWithValues}
                caseSensitive={caseSensitive}
                valueField={valueField}
                filter={filter}
                allowCreate={allowCreate}
                onCreate={onCreate}
                placeholder={placeholder}
                itemComponent={itemComponent}
                textField={textField}
                disabled={disabled}
                dropUp={dropUp}
                messages={messages}
                data={data}
                type="uniform"
                useStaticSize={true}
                length={data.length}
                {...restOfProps}
            />
            <div className="invalid-feedback text-left">{touched && error && <span>{error}</span>}</div>
        </FormGroup>
    );
};

const defaultProps = {
    input: {},
    label: '',
    inline: false,
    dropUp: false,
    width: '',
    placeholder: '',
    itemComponent: null,
    disabled: false,
    showPlaceholderWithValues: false,
    caseSensitive: false,
    valueField: '',
    filter: 'contains',
    textField: '',
    data: [],
    meta: {},
};
MultiSelectField.defaultProps = defaultProps;

const MoizedMultiSelectField = React.memo(MultiSelectField);

const useStyles = makeStyles({
    main: {
        width: '100%',
        backgroundColor: '#fff',
        height: '100%',
        padding: '0.5rem 0.75rem',
        fontSize: '14px',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    closeDiv: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        marginBottom: '10px',
    },
    icon: {
        cursor: 'pointer',
    },
    multipleTitle: {
        backgroundColor: '#f4f2fd',
        color: '#383838',
        border: '1px solid #d9cbf6',
        borderRadius: '20px',
        cursor: 'pointer',
        marginTop: 0,
        fontSize: '12px',
        lineHeight: '20px',
    },
    popup: {
        top: '50px',
        zIndex: 1,
        position: 'absolute',
        padding: '25px',
        background: '#fff',
        borderRadius: '8px',
        boxShadow: '0 12px 40px 5px rgb(0 0 0 / 26%)',
        maxWidth: '100%',
        minWidth: '400px',
        minHeight: '200px',
        '& .rw-multiselect-tag': {
            marginBottom: '5px',
        },
    },
    inputMultiSelect: {
        border: '1px solid #dedede',
        overflowY: 'auto',
        height: '400px',
        width: '600px',
        overflowX: 'hidden',
        padding: '10px',
    },
    editFilters: {
        cursor: 'pointer',
        padding: '0 5px',
        color: '#8c8c8c',
        marginLeft: '5px',
        fontSize: '11px',
    },
});

const MultiSelectFieldWrapper = React.memo(props => {
    const { value, popupWhenNumberOfItems } = props;
    const [popupOpened, setPopupOpened] = useState(false);
    const classes = useStyles();
    const node = useRef();

    const handler = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target)) {
                setPopupOpened(false);
            }
        },
        [setPopupOpened]
    );

    useClickOutside(handler);

    const popupJSX = () => {
        return (
            <div ref={node} className={classes.popup}>
                <div className={classes.closeDiv}>
                    <div>Add or remove filters</div>
                    <Close onClick={() => setPopupOpened(false)} className={classes.icon} />
                </div>
                <div className={classes.inputMultiSelect}>
                    <MoizedMultiSelectField {...props} autoFocus />
                </div>
            </div>
        );
    };

    const multipleJSX = () => {
        return (
            <div className={classes.main}>
                <span
                    className={`px-2 rw-multiselect-tag ${classes.multipleTitle}`}
                    onClick={() => setPopupOpened(true)}
                >
                    <Layers style={{ marginRight: '5px', fontSize: '15px' }} /> Multiple filters applied
                </span>
                {!popupOpened && (
                    <span className={classes.editFilters} onClick={() => setPopupOpened(true)}>
                        Edit filters
                    </span>
                )}
                {popupOpened && popupJSX()}
            </div>
        );
    };

    const showMultiple = popupWhenNumberOfItems != null && value?.length >= popupWhenNumberOfItems;

    if (showMultiple) {
        return multipleJSX();
    }

    return <MoizedMultiSelectField {...props} />;
});

MultiSelectFieldWrapper.defaultProps = {
    ...defaultProps,
    popupWhenNumberOfItems: null,
};

export default MultiSelectFieldWrapper;
