import { axiosInstance } from './axiosInstance';

export const getAllProjects = cancelToken => (companyId, forScheduler) =>
    axiosInstance
        .get(`company/${companyId}/projectsBasicInfo?forScheduler=${forScheduler ? true : false}`, { cancelToken })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getProjectRequest = projectId =>
    axiosInstance
        .get(`project/${projectId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createProjectRequest = data =>
    axiosInstance
        .post('project', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const validateCodeRequest = data =>
    axiosInstance
        .post('project/validCode', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const duplicateProjectRequest = projectId =>
    axiosInstance
        .post(`project/${projectId}`, {})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteProjectRequest = projectId =>
    axiosInstance
        .delete(`project/${projectId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const addResourceToProjectRequest = (projectId, resourceId) =>
    axiosInstance
        .post(`project/addResourceToProject`, { projectId, resourceId })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateProjectRequest = (projectId, data) =>
    axiosInstance
        .put(`project/${projectId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createMultipleProjectsRequest = data =>
    axiosInstance
        .post('project/multiple', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createMilestoneRequest = data =>
    axiosInstance
        .post('milestone', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateMilestoneRequest = (projectId, data) =>
    axiosInstance
        .put(`milestone/${projectId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteMilestoneRequest = id =>
    axiosInstance
        .delete(`milestone/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteFutureMilestoneRequest = (repeatId, date) =>
    axiosInstance
        .delete(`milestone/repeat/fromdate/${repeatId}/${date}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteAllMilestonesRequest = repeatId =>
    axiosInstance
        .delete(`milestone/repeat/${repeatId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createPhasesRequest = data =>
    axiosInstance
        .post('phase', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updatePhasesRequest = (projectId, data) =>
    axiosInstance
        .put(`phase/${projectId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deletePhaseRequest = id =>
    axiosInstance
        .delete(`phase/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteFuturePhasesRequest = (repeatId, date) =>
    axiosInstance
        .delete(`phase/repeat/fromdate/${repeatId}/${date}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteAllPhasesRequest = repeatId =>
    axiosInstance
        .delete(`phase/repeat/${repeatId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getMilestonesAndPhases = filters =>
    axiosInstance
        .post('project/getMilestonesAndPhases', filters)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getProjectDates = filters =>
    axiosInstance
        .post('project/getProjectDates', filters)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
