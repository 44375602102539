import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Layout } from 'shared/components/layout';
import { Button } from 'shared/components/Button';
import { OtpInput } from '../otpInput';
import { clearAuthError, clearMFAError, sendOTPCodeByEmail, validateOTPOnLogin } from 'actions/accountActions';
import { useDispatch } from 'react-redux';
import { OTP_RECOVERY_CODE_LENGTH, OTP_CODE_LENGTH } from '../../../../constants';
import { push } from 'connected-react-router';
import { ArrowBack } from '@material-ui/icons';
import { MFA_STATUS_CODES } from 'enums/authEnum';

export const OTPVerificationModalContent = ({ onSubmit, error, errorCode, actionType, email }) => {
    const dispatch = useDispatch();
    const timerRef = useRef();
    const [isRecoveryCodeValidation, setIsRecoveryCodeValidation] = useState(false);
    const [isLimitExceeded, setIsLimitExceeded] = useState(false);
    const title = isRecoveryCodeValidation ? 'Enter your recovery code' : 'Enter your authentication code!';
    const isLoginPage = actionType === 'LOGIN';

    useEffect(() => {
        if (errorCode !== MFA_STATUS_CODES.TOO_MANY_MFA_REQUESTS) {
            return;
        }

        setIsLimitExceeded(true);

        timerRef.current = setTimeout(() => {
            setIsLimitExceeded(false);
            dispatch(clearMFAError());
        }, 1000 * 60);

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, [errorCode, dispatch]);

    const getDescriptionText = type => {
        if (isRecoveryCodeValidation) {
            return `Enter a ${OTP_RECOVERY_CODE_LENGTH}-sign authentication recovery code that was generated for you when configuring authentication app.`;
        }

        const textPrefix = `Enter a ${OTP_CODE_LENGTH}-digit authentication code generated in your mobile authentication app in order to`;

        switch (type) {
            case 'LOGIN':
            case 'CHANGE_ACCOUNT_COMPANY_REQUEST':
                return `${textPrefix} sign to your company.`;
            case 'DISABLE_MFA_REQUEST':
                return `${textPrefix} disable MFA for your company.`;
            case 'SHOW_MFA_RECOVERY_CODES_REQUEST':
                return `${textPrefix} display recovery codes.`;
            case 'CREATE_NEW_MFA_RECOVERY_CODES_REQUEST':
                return `${textPrefix} generate new recovery codes.`;

            default:
                return `${textPrefix} complete the Two-Factor Authentication process`;
        }
    };

    const onUseRecoveryCodesClick = () => {
        dispatch(clearMFAError());
        dispatch(clearAuthError());
        setIsRecoveryCodeValidation(true);
    };

    const onGoToLoginPageClick = () => {
        dispatch(validateOTPOnLogin(false));
        dispatch(clearAuthError());
        dispatch(push('/login'));
    };

    const onEmailCodeClick = () => {
        setIsRecoveryCodeValidation(false);
        dispatch(sendOTPCodeByEmail.request(email));
    };

    const onBackToOTPClick = () => {
        dispatch(clearMFAError());
        setIsRecoveryCodeValidation(false);
    };

    const getSwitchButton = () => {
        return isRecoveryCodeValidation ? (
            <Button isLink inline onClick={onBackToOTPClick}>
                Back to OTP code
            </Button>
        ) : (
            <Button isLink inline onClick={onUseRecoveryCodesClick}>
                Use emergency backup code
            </Button>
        );
    };
    const handleSubmit = useCallback(
        code => {
            onSubmit({ otp: code });
        },
        [onSubmit]
    );

    return (
        <Layout gap="30px" padding="30px 20px" stack vAlign="center" hAlign="center">
            {isLoginPage && (
                <Button inline onClick={onGoToLoginPageClick}>
                    <Layout gap="5px" vAlign="center">
                        <ArrowBack style={{ height: '18px' }} />
                        <span>Back to log in</span>
                    </Layout>
                </Button>
            )}
            <Layout stack>
                <Typography paragraph align="center" variant="h6">
                    {title}
                </Typography>
                <Typography align="center" variant="body2">
                    {getDescriptionText(actionType)}
                </Typography>
            </Layout>
            {error && (
                <Alert icon={false} severity="error">
                    <Typography align="center">{error}</Typography>
                </Alert>
            )}
            {!isLimitExceeded && (
                <>
                    {/* Re-mount input on switch from OTP to RecoveryCode in order to clean up the value */}
                    {isRecoveryCodeValidation && (
                        <OtpInput
                            hasError={Boolean(error)}
                            numInputs={OTP_RECOVERY_CODE_LENGTH}
                            onFill={handleSubmit}
                            isRecoveryInput
                        />
                    )}
                    {!isRecoveryCodeValidation && <OtpInput hasError={Boolean(error)} onFill={handleSubmit} />}

                    <Layout stack padding="20px 0">
                        <Typography align="center">{getSwitchButton()}</Typography>
                    </Layout>
                </>
            )}
            <Layout gap="10px" stack stretch hAlign="center">
                {!isLimitExceeded && (
                    <Layout hAlign="center" gap="0.5rem">
                        <Typography component="div" variant="body2">
                            Problems with authentication app?
                        </Typography>

                        <Button size="small" inline onClick={onEmailCodeClick}>
                            <strong>Email me a code</strong>
                        </Button>
                    </Layout>
                )}

                <Layout>
                    <Button size="small" inline target="_blank" href="https://hubplanner.com/privacy-policy/">
                        Privacy policy
                    </Button>
                </Layout>
            </Layout>
        </Layout>
    );
};

OTPVerificationModalContent.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    actionType: PropTypes.string,
    error: PropTypes.string,
};
