import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, Row, Col, Button } from 'reactstrap';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';
import { values } from 'lodash';
import { useAppKeyWords, useWindowDimension } from 'hooks';
import Scrollbar from 'shared/scrollbar';
import BookingLinks from './bookingLinks';
import BookingNote from './bookingNote';
import BookingInfo from './bookingInfo';
import TasksList from 'shared/tasksList';
import { SidebarWrapper, CloseIcon, MissingBooking, Content } from './styles';
import { BookingSidebarContextProvider, TABS, useBookingSidebarContext } from './SidebarContext';

const BookingSidebar = ({ isOpen, schedulerRef }) => {
    const { activeTab, currentSelectedBooking, changeActiveTab, closeSidebar } = useBookingSidebarContext();
    const { projectKeyWord } = useAppKeyWords();
    const { windowHeight, windowWidth } = useWindowDimension();

    const isRegularBooking =
        currentSelectedBooking.id &&
        !currentSelectedBooking.phase &&
        !currentSelectedBooking.milestone &&
        !currentSelectedBooking.datesEvent &&
        !currentSelectedBooking.deadline;

    return (
        <SidebarWrapper className={classNames({ 'fade-in': isOpen })}>
            {isOpen && (
                <Scrollbar height={windowHeight - (991 >= windowWidth ? 42 : 51)}>
                    <div className="p-4">
                        <CloseIcon onClick={closeSidebar} style={{ zIndex: 999 }} />
                        <Nav className="d-block">
                            <Row>
                                {values(TABS).map(tab => (
                                    <Col key={tab.id} className="d-flex justify-content-center">
                                        <NavItem
                                            active={tab.id === activeTab}
                                            onClick={() => changeActiveTab(tab.id)}
                                            className="cursor-p"
                                        >
                                            <tab.icon fontSize="large" />
                                        </NavItem>
                                    </Col>
                                ))}
                            </Row>
                        </Nav>
                        {isRegularBooking && (
                            <>
                                <Content active={TABS.NOTE_TAB.id === activeTab}>
                                    {TABS.NOTE_TAB.id === activeTab ? (
                                        <BookingNote
                                            schedulerRef={schedulerRef}
                                            key={currentSelectedBooking?.id}
                                            currentSelectedBooking={currentSelectedBooking}
                                        />
                                    ) : null}
                                </Content>
                                <Content active={TABS.TASKS_TAB.id === activeTab}>
                                    <h4>Booking Tasks</h4>
                                    <span className="text-muted">Tasks relevant to this booking</span>
                                    <TasksList
                                        bookingId={currentSelectedBooking.id}
                                        resource={currentSelectedBooking.resourceInfo}
                                        project={currentSelectedBooking.project}
                                        schedulerRef={schedulerRef}
                                        booking={currentSelectedBooking}
                                    />
                                </Content>
                                <Content active={TABS.LINKS_TAB.id === activeTab}>
                                    <BookingLinks
                                        ref={schedulerRef}
                                        onClose={closeSidebar}
                                        currentSelectedBooking={currentSelectedBooking}
                                    />
                                </Content>
                                <Content active={TABS.INFO_TAB.id === activeTab}>
                                    <BookingInfo currentSelectedBooking={currentSelectedBooking} />
                                </Content>
                            </>
                        )}
                        {!isRegularBooking && (
                            <MissingBooking>
                                <Info fontSize="large" />
                                <span className="title">Select a Project Booking</span>
                                <span className="desc text-muted">
                                    You need to first select a booking from the scheduler to view the booking
                                    information here.
                                </span>
                                <Button className="mt-3">{projectKeyWord} Booking</Button>
                            </MissingBooking>
                        )}
                    </div>
                </Scrollbar>
            )}
        </SidebarWrapper>
    );
};

BookingSidebar.propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    currentSelectedBooking: PropTypes.object.isRequired,
};

const WithContext = props => {
    const { initialActiveTab, schedulerRef, currentSelectedBooking: currentSelectedBookingProp, ...rest } = props;

    // Keep updated with latest DP stored event info
    const currentSelectedBooking = {
        ...currentSelectedBookingProp,
        ...(schedulerRef?.current?.control?.events?.find(event => event?.data?.id === currentSelectedBookingProp?.id)
            ?.data || {}),
    };

    return (
        <BookingSidebarContextProvider
            initialActiveTab={initialActiveTab}
            currentSelectedBooking={currentSelectedBooking}
            schedulerRef={schedulerRef}
        >
            <BookingSidebar {...rest} schedulerRef={schedulerRef} />
        </BookingSidebarContextProvider>
    );
};

export default WithContext;
