import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';

/**
 * @param {object}         dateState
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        TOIL
                        <p className="sub-title">(Accrued)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceToilAccrued',
        sortable: false,
        show: contains(columnsToLoad, 'resourceToilAccrued'),
        accessor: row => displayValue(
            row.resourceToilAccrued,
            unitTypes[unit].calculation(row.resourceToilAccrued, avgDailyCapacity)
        ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        TOIL
                        <p className="sub-title">(Remaining)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceToilRemaining',
        sortable: false,
        show: contains(columnsToLoad, 'resourceToilRemaining'),
        accessor: row => displayValue(
            row.resourceToilRemaining,
            unitTypes[unit].calculation(row.resourceToilRemaining, avgDailyCapacity)
        ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        TOIL
                        <p className="sub-title">(Consumed)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceToilConsumed',
        sortable: false,
        show: contains(columnsToLoad, 'resourceToilConsumed'),
        accessor: row => displayValue(
            row.resourceToilConsumed,
            unitTypes[unit].calculation(row.resourceToilConsumed, avgDailyCapacity)
        ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        TOIL
                        <p className="sub-title">(Requested)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceToilRequested',
        sortable: false,
        show: contains(columnsToLoad, 'resourceToilRequested'),
        accessor: row => displayValue(
            row.resourceToilRequested,
            unitTypes[unit].calculation(row.resourceToilRequested, avgDailyCapacity)
        ),
    },
];
