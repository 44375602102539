import { contains, pick } from 'underscore';
import { isVacationFlow, isSingleFlow, isMyRequests, isMyResourceRequests } from 'modules/request/utils/dataFlowUtil';

export const prepareStatsRequestData = requestModel => {
    let reportObject = {
        start: requestModel.start,
        end: requestModel.end, //days in current year
        type: isVacationFlow(requestModel.flow) ? 'vacation' : 'request',
    };

    if (isMyResourceRequests(requestModel.flow)) {
        reportObject.isCreator = 1;
    } else if (isSingleFlow(requestModel.flow) || isMyRequests(requestModel.flow)) {
        reportObject.resourceId = requestModel.resourceId;
    }

    return reportObject;
};

export const prepareTableRequestData = requestModel => {
    let queryObject = {
        startDate: requestModel.start,
        dayCount: requestModel.dayCount,
        resourceStatuses: ['STATUS_ACTIVE', 'STATUS_NON_BOOKABLE'],
        status: 'ALL' !== requestModel.filter ? [requestModel.filter] : ['APPROVED', 'WAITING_FOR_APPROVAL'],
        loggedUserIsApprover: requestModel.isApprover && !isSingleFlow(requestModel.flow),
        isVacation: requestModel.isVacation,
        loggedUserIsCreator: requestModel.loggedUserIsCreator,
    };

    if (contains(['MY_VACATION_REQUESTS', 'VACATION_FLOW_SINGLE', 'RES_REQUEST_FLOW_SINGLE'], requestModel.flow)) {
        queryObject['resourceId'] = requestModel.resourceId;
    }

    if (requestModel.isVacation && contains(['ALL', 'APPROVED'], requestModel.filter)) {
        queryObject.status.push('SCHEDULED');
    }

    return pick(queryObject, item => null !== item);
};
