import React from 'react';
import moment from 'moment';

const PhaseBubble = React.memo(props => {
    const { name, start, end } = props;
    return (
        <div>
            Phase - {name} - {moment(new Date(start)).format('MMM, Do yyyy')} -{' '}
            {moment(new Date(end)).format('MMM, Do yyyy')}
        </div>
    );
});

export default PhaseBubble;
