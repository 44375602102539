/* eslint-env browser */
import { useCallback, useEffect } from 'react';

export const usePressEscape = onPressEscape => {
    const handler = useCallback(
        event => {
            const key = event.keyCode ? event.keyCode : event.which;
            if (27 === key) {
                onPressEscape && onPressEscape();
            }
        },
        [onPressEscape]
    );

    useEffect(() => {
        document.addEventListener('keydown', handler, true);

        return () => {
            document.removeEventListener('keydown', handler, true);
        };
    }, [handler]);
};
