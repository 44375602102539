import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @returns {Array}
 */
export default dateState => [
    {
        id: 'externalSpendDifferenceAllTime',
        display: 'Spend Difference All Time',
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'externalSpendDifferenceBetween',
        display: `Spend Difference ${displayDateState(dateState, false, false)}`,
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'external rate',
        unitType: columnUnitTypes.CURRENCY.value,
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'internalSpendDifferenceAllTime',
        display: 'Spend Difference All Time',
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'internalSpendDifferenceBetween',
        display: `Spend Difference ${displayDateState(dateState, false, false)}`,
        displayTimeType: 'Scheduled & Reported Spend',
        displayColumnType: 'spend',
        displayRateType: 'internal rate',
        unitType: columnUnitTypes.CURRENCY.value,
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.SPEND,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
];
