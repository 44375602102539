import { sortBy, values } from 'underscore';

export const UNSUBMITTED = {
    display: 'Unsubmitted Time',
    value: 'UNSUBMITTED',
};
export const SUBMITTED = { display: 'Submitted Time', value: 'SUBMITTED' };
export const PENDING = { display: 'Pending Time', value: 'PENDING' };
export const APPROVED = { display: 'Approved Time', value: 'APPROVED' };
export const REJECTED = { display: 'Rejected Time', value: 'REJECTED' };

export const timeEntryTypes = {
    UNSUBMITTED,
    SUBMITTED,
    PENDING,
    APPROVED,
    REJECTED,
};

export const statusesArray = sortBy(values(timeEntryTypes), status => status.value);

export const mapValuesToOptions = values => {
    if (!values) {
        return [];
    }

    return values.map(value => {
        return timeEntryTypes[value];
    });
};
