import * as types from 'actions/actionTypes';

export const addNotification = notification => ({
    type: types.ADD_NOTIFICATION,
    payload: {
        notification,
    },
});

export const removeNotification = id => ({
    type: types.REMOVE_NOTIFICATION,
    payload: {
        id,
    },
});
