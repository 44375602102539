import styled from 'styled-components';
import { Form } from 'redux-form';

export const FormWrapper = styled(Form)`
    .form-group {
        margin-right: 0.5rem;
        width: 250px;
        margin-bottom: 0;
    }
    .btn-group {
        height: 40px;
        margin-right: 0.5rem;
    }
    .color-input {
        padding-top: 1px;
    }
    .dropdown-menu {
        width: 220px;
    }
`;
