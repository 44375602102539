import styled from 'styled-components';

export const Popover = styled.div`
    position: absolute;

    .color-picker {
        position: ${props => (props.position ? props.position : 'absolute')} !important;
        bottom: 0;
        z-index: 999;
        box-shadow: none;
    }
`;

export const Cover = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;
