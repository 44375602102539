/* eslint-env browser */
import { HEADER_HEIGHT } from 'constants';

export const getUrl = (subdomain = '', customPort = '') => {
    const portDivider = '' !== window.location.port ? ':' : '';
    let domain = document.domain;

    if ('' !== subdomain && !portDivider) {
        if (-1 < domain.indexOf('staging.')) {
            domain = 'staging.hubplanner.com';
        } else if (-1 < domain.indexOf('staging2.')) {
            domain = 'staging2.hubplanner.com';
        } else if (-1 < domain.indexOf('sandbox.')) {
            domain = 'sandbox.hubplanner.com';
        } else {
            domain = 'hubplanner.com';
        }
        domain = subdomain + '.' + domain;
    }

    let port = window.location.port && customPort ? customPort : window.location.port;

    return window.location.protocol + '//' + domain + portDivider + port;
};

export const getSubdomain = () => {
    const host = window.location.hostname;
    const subdomainIndex = host.indexOf('.');
    const subdomain = -1 !== subdomainIndex ? host.substring(0, subdomainIndex) : '';

    return 'localhost' !== host && 'login' !== subdomain ? subdomain : null;
};

export const getContextMenuPosition = ({ windowHeight, windowWidth }, { top, left }, menuWidth = 0) => {
    let height = top - HEADER_HEIGHT;
    let directionY = 'up';
    let isDirectionXRight = true;
    if (windowHeight - top > top) {
        height = windowHeight - top - HEADER_HEIGHT;
        directionY = 'down';
    }

    if (windowWidth - left > menuWidth) {
        isDirectionXRight = false;
    }
    return { height, directionY, isDirectionXRight };
};
