import { useCallback } from 'react';
import { SINGLE } from '../../../../enums/viewModeEnum';
import { showEditBookingTitleModal } from '../../../../../../actions/modalActions';
import { useDispatch } from 'react-redux';

export const useEditBookingTitleModal = ({ onClose, currentSelectedBooking, updateBooking, mode, viewObject }) => {
    const dispatch = useDispatch();

    const editBookingModal = useCallback(() => {
        onClose && onClose();

        let title = currentSelectedBooking.title;
        if (!title) {
            if (mode === SINGLE.value) {
                title = viewObject.isResourceView
                    ? currentSelectedBooking.project.name
                    : `${currentSelectedBooking.resourceInfo.firstName} ${currentSelectedBooking.resourceInfo.lastName}`;
            } else {
                title = currentSelectedBooking.project.name;
            }
        }

        dispatch(showEditBookingTitleModal(updateBooking, title));
    }, [
        onClose,
        currentSelectedBooking.title,
        currentSelectedBooking.project.name,
        currentSelectedBooking.resourceInfo.firstName,
        currentSelectedBooking.resourceInfo.lastName,
        dispatch,
        updateBooking,
        mode,
        viewObject.isResourceView,
    ]);

    return editBookingModal;
};
