import React from 'react';
import PropTypes from 'prop-types';
import { RequestedLabel, RequestLabelNote } from './../styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const Approval = props => {
    const { booking, approvalInfo, mode } = props;

    let type;
    if (booking?.project?.type === 'REGULAR') {
        type = 'project';
    } else if (booking?.project?.type === 'EVENT') {
        if (booking?.project?.name === 'Vacation') {
            type = 'vacation';
        } else {
            type = 'event';
        }
    }

    return (
        <>
            <RequestedLabel mode={mode}>
                <span data-cy={`scheduler-[${type}]-request`}> requested</span>
            </RequestedLabel>
            {approvalInfo && approvalInfo.requesterNote && (
                <RequestLabelNote mode={mode}>
                    <InsertDriveFileIcon />
                </RequestLabelNote>
            )}
        </>
    );
};

Approval.propTypes = {
    booking: PropTypes.object.isRequired,
    approvalInfo: PropTypes.object.isRequired,
};

export default Approval;
