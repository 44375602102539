import { forEach } from 'lodash';

const extractHTMLContent = htmlString => {
    const span = document.createElement('span');
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
};

export default args => {
    if (args.row.columns.length) {
        forEach(args.row.columns, column => {
            if (typeof column.text !== 'string' && column.html) {
                column.text = extractHTMLContent(column.html);
            }
        });
    }
    args.text = args.row.name;
};
