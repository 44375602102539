import styled from 'styled-components';
import { Alert as BootstrapAlert } from 'reactstrap';

export const AlertWrapper = styled.ul`
    min-width: 200px;
    max-width: 400px;
    position: fixed;
    right: 20px;
    top: 80px;
    z-index: 99999;
    word-break: break-word;
`;

export const AlertItem = styled(BootstrapAlert)`
    word-break: break-word;
    border-radius: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
`;
