import { handleBookingEvent } from './booking';
import { handlePhaseEvent } from './phase';
import { handleMilestoneEvent } from './milestone';
import { handleDatesEvent } from './dates';
import { handleDeadlineEvent } from './deadline';

export default (...args) => daypilotArgs => {

    switch (true) {
        case daypilotArgs.data.phase:
            handlePhaseEvent(daypilotArgs, args);
            break;
        case daypilotArgs.data.milestone:
            handleMilestoneEvent(daypilotArgs, args);
            break;
        case daypilotArgs.data.datesEvent:
            handleDatesEvent(daypilotArgs, args);
            break;
        case daypilotArgs.data.deadline:
            handleDeadlineEvent(daypilotArgs, args);
            break;
        default:
            handleBookingEvent(daypilotArgs, args);
    }
};
