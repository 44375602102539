import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { MoreVert } from '@material-ui/icons';
import { ConfirmationWrapper } from './styles';
import { useClickOutside } from 'hooks/useClickOutside';

const ConfirmTooltipButton = props => {
    const { onConfirm, buttonText, icon: Icon, color, className, dataCyPrefix } = props;
    let node = useRef();
    const [tooltipOpen, toggleTooltip] = useState(false);
    const handleClickOutside = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target) && tooltipOpen) {
                toggleTooltip(!tooltipOpen);
            }
        },
        [tooltipOpen]
    );

    useClickOutside(handleClickOutside);

    return (
        <div className="float-left position-relative" ref={node}>
            <Button
                color={color}
                className={classNames(className)}
                onClick={() => toggleTooltip(!tooltipOpen)}
                data-cy={`${dataCyPrefix}-elipsis-button`}
            >
                <Icon />
            </Button>
            {tooltipOpen && (
                <ConfirmationWrapper>
                    <div>
                        <a data-cy={`${dataCyPrefix}-confirm-button`} onClick={onConfirm}>
                            {buttonText}
                        </a>
                    </div>
                </ConfirmationWrapper>
            )}
        </div>
    );
};

ConfirmTooltipButton.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    className: PropTypes.string,
    buttonText: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    color: PropTypes.oneOf(['danger', 'info', 'secondary', 'success', 'warning', 'primary', 'link']),
};

ConfirmTooltipButton.defaultProps = {
    buttonText: 'Confirm',
    icon: MoreVert,
    color: '',
    className: 'btn-hub-core-simple-hover',
};

export default ConfirmTooltipButton;
