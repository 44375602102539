import PermissionDenied from '../../scheduler/components/permissionDenied/permissionDenied';
import authorization from '../../../services/auth/authorization';
import { useEffect } from 'react';
import { showScheduleResourceModal } from '../../../actions/modalActions';
import { useDispatch } from 'react-redux';
import { useBooking } from '../../scheduler/hooks';
import {useQueryParams} from '../../../utils/useQueryParams';

const ScheduleModalRoute = () => {
    const dispatch = useDispatch();
    const { createBooking, deleteBookingFromModal } = useBooking();
    const queryParams = useQueryParams();

    useEffect(() => {
        dispatch(
            showScheduleResourceModal({
                onSubmit: data => createBooking(data.bookings,false, data.tasks || [], () => {
                    if (queryParams.fallbackUrl) {
                        window.location.href = queryParams.fallbackUrl;
                    } else {
                        window.location.href = '/';
                    }
                }),
                onDelete: deleteBookingFromModal,
                onClose: () => {
                    if (queryParams.fallbackUrl) {
                        window.location.href = queryParams.fallbackUrl;
                    } else {
                        window.location.href = '/';
                    }
                }
            })
        );
    }, [createBooking, deleteBookingFromModal, dispatch, queryParams.fallbackUrl]);
};

const authParameters = {
    allowedRoleRights: ['manageEvents', 'scheduleMyselfOnAnyProjectOrEvent', 'manageUnassignedRows'],
    redirectOnLackOfRights: true,
    errorComponent: PermissionDenied,
    routeParamsChecker: () => {
        return true;
    },
};

export const ScheduleModalRouteAuth = authorization(authParameters)(ScheduleModalRoute);
