import { action, createRequestTypes } from '../../actions/base';
import createReducer from '../../reducers/helpers/createReducer';
import { extend } from 'underscore';
import { call, put, takeLatest } from 'redux-saga/effects';
import { addNotification } from '../../actions/notificationActions';
import {
    createCostCategoryTemplate,
    deleteCostCategoryTemplate,
    getCostCategoryTemplates,
    updateCostCategoryTemplates,
} from '../../api/costCategoryTemplate';
import { updateObject } from '../../reducers/helpers/updater';
import { hideModal } from '../../actions/modalActions';
import { createSelector } from 'reselect';

// ACTIONS

export const FETCH_ALL_COSTS_CATEGORIES = createRequestTypes('FETCH_ALL_COSTS_CATEGORIES', true);
export const CREATE_COST_CATEGORY = createRequestTypes('CREATE_COST_CATEGORY', true);
export const UPDATE_COST_CATEGORY = createRequestTypes('UPDATE_COST_CATEGORY', true);
export const DELETE_COST_CATEGORY = createRequestTypes('DELETE_COST_CATEGORY', true);

export const costsCategoriesActions = {
    fetchAll: {
        request: ({ showDeleted }) => action(FETCH_ALL_COSTS_CATEGORIES.REQUEST, { showDeleted }),
        success: ({ costsCategories }) => action(FETCH_ALL_COSTS_CATEGORIES.SUCCESS, { costsCategories }),
        failure: () => action(FETCH_ALL_COSTS_CATEGORIES.FAILURE),
    },
    create: {
        request: ({ costCategory }) => action(CREATE_COST_CATEGORY.REQUEST, { costCategory }),
        success: ({ costCategory }) => action(CREATE_COST_CATEGORY.SUCCESS, { costCategory }),
        failure: () => action(CREATE_COST_CATEGORY.FAILURE),
    },
    update: {
        request: ({ id, costCategory }) => action(UPDATE_COST_CATEGORY.REQUEST, { id, costCategory }),
        success: ({ costCategory }) => action(UPDATE_COST_CATEGORY.SUCCESS, { costCategory }),
        failure: () => action(UPDATE_COST_CATEGORY.FAILURE),
    },
    delete: {
        request: ({ id }) => action(DELETE_COST_CATEGORY.REQUEST, { id }),
        success: () => action(DELETE_COST_CATEGORY.SUCCESS),
        failure: () => action(DELETE_COST_CATEGORY.FAILURE),
    },
};

// REDUCER

const initialState = {
    costsCategories: [],
    loading: false,
};

export const costsCategoriesReducer = createReducer(extend({}, initialState), {
    [FETCH_ALL_COSTS_CATEGORIES.REQUEST]: state => {
        return updateObject(state, {
            loading: true,
        });
    },
    [FETCH_ALL_COSTS_CATEGORIES.SUCCESS]: (state, action) => {
        return updateObject(state, {
            loading: false,
            costsCategories: action.payload.costsCategories,
        });
    },
    [FETCH_ALL_COSTS_CATEGORIES.FAILURE]: state => {
        return updateObject(state, {
            loading: false,
        });
    },
});

// SELECTORS

export const selectCostCategories = state => state.costsCategoriesReducer.costsCategories;

export const makeSelectCostsCategories = type =>
    createSelector(selectCostCategories, (costsCategories = []) => {
        let asArray = [];
        switch (type) {
            case 'WITH_DELETED':
                asArray = costsCategories;
                break;
            default:
                asArray = costsCategories.filter(category => !category.deleted);
                break;
        }

        return {
            asArray: asArray,
            asMap: asArray.reduce((acc, category) => {
                acc[category._id] = category;
                return acc;
            }, {}),
        };
    });

export const selectNotDeletedCostsCategories = makeSelectCostsCategories();
export const selectAllCostsCategories = makeSelectCostsCategories('WITH_DELETED');

// SAGAS

function* handleFetchAllCostsCategories(action) {
    const { showDeleted } = action.payload;
    try {
        const response = yield call(getCostCategoryTemplates, { showDeleted });
        yield put(costsCategoriesActions.fetchAll.success({ costsCategories: response }));
    } catch (err) {
        yield put(costsCategoriesActions.fetchAll.failure());
        yield put(
            addNotification({
                message: `We couldn't save fixed cost category`,
                type: 'danger',
            })
        );
    }
}

function* handleCreateCostCategory(action) {
    try {
        const response = yield call(createCostCategoryTemplate, action.payload.costCategory);
        yield put(costsCategoriesActions.create.success(response));
        yield put(costsCategoriesActions.fetchAll.request({showDeleted: false}));
    } catch (err) {
        yield put(costsCategoriesActions.create.failure());
        yield put(
            addNotification({
                message: `We couldn't save fixed cost category`,
                type: 'danger',
            })
        );
    }
}

function* handleUpdateCostCategory(action) {
    try {
        const response = yield call(updateCostCategoryTemplates, action.payload.id, action.payload.costCategory);
        yield put(costsCategoriesActions.update.success(response));
        yield put(costsCategoriesActions.fetchAll.request({showDeleted: false}));
        yield put(hideModal());
    } catch (err) {
        yield put(costsCategoriesActions.update.failure());
        yield put(
            addNotification({
                message: `We couldn't save fixed cost category`,
                type: 'danger',
            })
        );
    }
}

function* handleDeleteCostCategory(action) {
    try {
        yield call(deleteCostCategoryTemplate, action.payload.id);
        yield put(costsCategoriesActions.delete.success());
        yield put(costsCategoriesActions.fetchAll.request({showDeleted: false}));
    } catch (err) {
        yield put(costsCategoriesActions.delete.failure());
        yield put(
            addNotification({
                message: `We couldn't fixed delete cost category`,
                type: 'danger',
            })
        );
    }
}

export function* costsCategoriesSaga() {
    yield takeLatest(FETCH_ALL_COSTS_CATEGORIES.REQUEST, handleFetchAllCostsCategories);
    yield takeLatest(CREATE_COST_CATEGORY.REQUEST, handleCreateCostCategory);
    yield takeLatest(UPDATE_COST_CATEGORY.REQUEST, handleUpdateCostCategory);
    yield takeLatest(DELETE_COST_CATEGORY.REQUEST, handleDeleteCostCategory);
}
