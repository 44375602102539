import { forEach, find, includes } from 'lodash';
import { LEGACY } from 'modules/scheduler/enums/viewModeEnum';
import { columnTypes, CUSTOM_FIELDS } from 'modules/scheduler/enums/customColumnTypeEnum';

const getCustomText = (id, columnType, viewType, { customFields, tags }) => {
    let selected = {};

    if ('CUSTOM_FIELDS' === columnType) {
        selected = find(customFields, cf => cf._id === id && cf.category === viewType.toUpperCase());
    }

    if (-1 !== columnType.indexOf('TAGS')) {
        selected = find(tags, tag => tag._id === id && tag.category === viewType.toUpperCase());
    }

    return selected ? selected.label || selected.value : '';
};

const formatHeaderTitle = (column, type, data) => {
    if (includes(['CUSTOM_FIELDS', 'PROJECT_TAGS', 'RESOURCE_TAGS'], column.columnType) && column.columnValue) {
        return getCustomText(column.columnValue.id, column.columnType, type, data);
    }

    return columnTypes[column.columnType]?.label || 'Name';
};

export const getCustomColumnsType = (mode, viewObject) =>
    viewObject.isResourceView || LEGACY.value === mode ? 'resource' : 'project';

export const getColumnsByType = (type, schedulerColumns, isCustomColumnsExtensionInstalled) => {
    const defaultColumn =
        'resource' === type
            ? schedulerColumns.resourceColumns.defaultColumn
            : schedulerColumns.projectColumns.defaultColumn;

    if (isCustomColumnsExtensionInstalled) {
        return [
            defaultColumn,
            ...('resource' === type
                ? schedulerColumns.resourceColumns.customColumns
                : schedulerColumns.projectColumns.customColumns),
        ];
    }

    return [defaultColumn];
};

const getDefaultColumnObject = (col, type, text) => {
    const columnObject = {
        id: col._id,
        type: col.columnType,
        viewType: type,
        text,
        name: text,
        alignment: col.columnAlignment,
        display:
            col.columnType === CUSTOM_FIELDS.value
                ? `${col.columnType}_${col.columnValue?.id}`
                : col.columnType || 'name',
        value: col.columnValue && col.columnValue?.id,
        width: parseInt(col.columnWidth),
        customColumnType: col.customColumnType,
    };

    return columnObject;
};

export const getFormattedColumnsToDp = (type, schedulerColumns, isCustomColumnsExtensionInstalled, data) => {
    let formattedColumns = [];
    try {
        const cols = getColumnsByType(type, schedulerColumns, isCustomColumnsExtensionInstalled);

        forEach(cols, col => {
            const title = formatHeaderTitle(col, type, data);

            if (col.columnType && 'NOT_SET' !== col.columnType) {
                if ('CUSTOM_FIELDS' === col.columnType) {
                    const notFound = !find(data.customFields, cf => cf._id === col._id);
                    if (notFound) {
                        const findCf = find(data.customFields, cf => cf._id === col.columnValue?.id) || {};
                        formattedColumns.push(
                            getDefaultColumnObject({ ...col, customColumnType: findCf?.type }, type, title)
                        );
                    }
                } else {
                    formattedColumns.push(getDefaultColumnObject(col, type, title));
                }
            } else {
                formattedColumns.push(
                    getDefaultColumnObject(col, type, 'NOT_SET' === col.columnType ? 'New Column' : 'Name')
                );
            }
        });

        // Some of CF are deleted, and the columns based on them are empty string. Inform the user with a different title.
        formattedColumns = formattedColumns.map(formattedColumn => {
            if (formattedColumn.text === '') {
                return {
                    ...formattedColumn,
                    text: 'Column of deleted Custom Field',
                };
            }
            return { ...formattedColumn, html: formattedColumn.text, column: formattedColumn.text };
        });
        return formattedColumns;
    } catch {
        return formattedColumns;
    }
};
