import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { showProjectModal } from 'actions/modalActions';
import { useProjectAction } from 'hooks';
import { useMenuProjectPermissions } from 'modules/scheduler/hooks/useMenuPermissions';

import { useAppKeyWords } from 'hooks/useAppKeyWords';
import NestedMenuItem, {NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} from 'shared/nestedMenuItem';
import MilestoneForm from './milestoneForm';
import PhaseForm from './phaseForm';
import DatesForm from './datesForm';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import { usePhases } from 'modules/scheduler/hooks';
import Scrollbar from 'shared/scrollbar';
import { NestedMenuItemManager } from '../../../../../../../shared/nestedMenuItem/context';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { Divider } from '@material-ui/core';

const LegacyProjectGroupMode = React.forwardRef(({ onClose }, schedulerRef) => {
    const dispatch = useDispatch();
    const {
        hasSettingPhase,
        hasSettingProjectMilestones,
        canAddDates,
        hasAddEditProjectRights,
    } = useMenuProjectPermissions();

    const { projectKeyWord } = useAppKeyWords();
    const { createMilestone } = useMilestones(schedulerRef, onClose);
    const { createPhase } = usePhases(schedulerRef, onClose);
    const { updateProject } = useProjectAction(schedulerRef);
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const { project } = currentSelection?.rowTags || {};
    const openProjectModal = useCallback(
        () =>
            dispatch(
                showProjectModal({
                    onSubmit: data => updateProject(project._id, data),
                    type: project.type,
                    projectId: project._id,
                })
            ),
        [dispatch, updateProject, project]
    );

    const scale = schedulerRef.current.control.scale;
    const hourScale = scale === CELLDURATION.value;

    return (
        <NestedMenuItemManager>
            {hasSettingProjectMilestones && !hourScale && (
                <NestedMenuItem label="Create milestone" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <MilestoneForm
                            project={project}
                            currentSelection={currentSelection}
                            onClick={createMilestone}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {hasSettingPhase && !hourScale && (
                <NestedMenuItem label="Create phase" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <PhaseForm project={project} currentSelection={currentSelection} onClick={createPhase} />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {canAddDates && !hourScale && (
                <NestedMenuItem label="Start & end dates" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <DatesForm
                            project={project}
                            onClose={onClose}
                            updateProject={updateProject}
                            currentSelection={currentSelection}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {hasAddEditProjectRights && project.hasRightsToProject && <Divider />}
            {hasAddEditProjectRights && project.hasRightsToProject && (
                <WrappedMenuItem
                    onClick={openProjectModal}
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mb-2"
                    data-cy="button-edit"
                >
                    Edit {projectKeyWord.toLowerCase()}...
                </WrappedMenuItem>
            )}
        </NestedMenuItemManager>
    );
});

LegacyProjectGroupMode.propTypes = {
    onClose: PropTypes.func,
};

LegacyProjectGroupMode.defaultProps = {
    onClose: null,
};

export default LegacyProjectGroupMode;
