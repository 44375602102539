import React from 'react';
import classnames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';
import { omit } from 'lodash';

const textField = ({
    input,
    label,
    type,
    inline,
    width,
    placeholder,
    disabled,
    wrapperStyles,
    wrapperClassName,
    inputStyles,
    inputClass,
    autoFocus,
    invalid,
    rows,
    setRef,
    meta: { touched, error },
    ...rest
}) => {
    const inputProps = omit(input, ['value']);
    'file' !== type && (inputProps['value'] = input.value);

    return (
        <FormGroup
            check={!width}
            inline={width ? false : inline}
            className={classnames(wrapperClassName)}
            style={wrapperStyles}
        >
            {label && (
                <Label for={input.name} className="form-control-label">
                    {label}
                </Label>
            )}
            <Input
                id={input.name}
                {...inputProps}
                style={inputStyles}
                autoFocus={autoFocus}
                disabled={disabled}
                rows={'textarea' === type ? rows || false : undefined}
                invalid={(touched && !!error) || invalid}
                className={classnames(width, inputClass)}
                placeholder={placeholder}
                type={type}
                innerRef={setRef}
                {...rest}
            />
            <div className="invalid-feedback text-left pl-1">{touched && error && <span>{error}</span>}</div>
        </FormGroup>
    );
};

textField.defaultProps = {
    input: {},
    disabled: false,
    width: '',
    rows: 5,
    inline: false,
    autoFocus: false,
    invalid: false,
    type: 'text',
    label: '',
    inputClass: '',
    wrapperStyles: {},
    inputStyles: {},
    placeholder: '',
    meta: {},
};

export default React.memo(textField);
