import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { UNASSIGNED } from '../../../enums/criteriaEnum';

export const getTabIcons = (
    resourceType,
    { projectKeyWord, resourceKeyWord, projectPluralKeyWord, vacationEventName, customFieldTitle }
) => {
    const dataCyKeyWord = resourceType === UNASSIGNED ? 'unassigned' : 'resource';
    let icons = [
        {
            id: '1',
            title: 'Basic Information',
            desc: `Fill out basic info about this ${resourceKeyWord}`,
            icon: 'fa-vcard',
            dataCy: `${dataCyKeyWord}-modal-basic-information`,
        },
        {
            id: '2',
            title: `${projectKeyWord} & Groups Management`,
            desc: `Add ${projectPluralKeyWord} to ${resourceKeyWord} and manage Groups`,
            icon: 'fa-stack',
            dataCy: `${dataCyKeyWord}-modal-project-groups-management`,
        },
        {
            id: '3',
            title: 'Rates Management',
            desc: `Manage the rates and budgets for the ${resourceKeyWord}`,
            icon: 'fa-dollar',
            dataCy: `${dataCyKeyWord}-modal-rates-management`,
        },
        {
            id: '4',
            title: 'Notes and Links',
            desc: `Add ${resourceKeyWord} Notes and ${resourceKeyWord} Links`,
            icon: 'fa-file',
            dataCy: `${dataCyKeyWord}-modal-notes-and-links`,
        },
        {
            id: '5',
            title: customFieldTitle || 'Custom Fields',
            desc: `${customFieldTitle} for your ${resourceKeyWord}`,
            icon: 'fa-pencil',
            dataCy: `${dataCyKeyWord}-modal-custom-fields`,
        },
    ];

    if (TYPE_UNASSIGNED.value !== resourceType) {
        icons = icons.concat(
            {
                id: '6',
                title: `${resourceKeyWord} Availability`,
                desc: 'Update the custom availability',
                icon: 'fa-calendar-plus-o',
                dataCy: `${dataCyKeyWord}-modal-resource-availability`,
            },
            {
                id: '7',
                title: `${vacationEventName} & Toil`,
                desc: `${vacationEventName} and time off in lieu settings.`,
                icon: 'fa-calendar',
                dataCy: `${dataCyKeyWord}-modal-resource-vacation`,
            },
        );
    }

    return icons;
};
