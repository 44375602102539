import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Button from './Button';
import { Fade } from '@material-ui/core';

const useStyles = makeStyles({
    main: {
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '1030',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        fontFamily: 'Poppins',
        letterSpacing: '0.04rem',
        '& h1': {
            fontWeight: 'bolder',
        },
        '& p': {
            color: '#4E5252',
        },
    },
    contentAllSpace: {
        width: '90%',
        height: '90%',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
        borderRadius: '4px',
    },
    content: {
        width: '90%',
        height: '90%',
        backgroundColor: '#fff',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
        borderRadius: '4px',
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
    },
    contentInner: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflow: 'auto',
    },
    iconsRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'relative',
        marginBottom: '20px',
    },
    footer: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        cursor: 'pointer',
    },
    notEnterable: {
        cursor: 'not-allowed !important',
    },
    stepContent: {
        margin: '20px 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    stepContentHeader: {
        display: 'flex',
        width: '80%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    stepContentBody: {
        margin: '30px auto',
        width: '80%',
    },
    subTitle: {
        maxWidth: '840px',
    },
    point: {
        width: '0.8em',
        height: '0.8em',
        color: '#4E5252',
    },
});

export const Flow = React.memo(props => {
    const { onLoad, initialStepIndex, steps: stepsProps, onFinished, organizeStepValues } = props;
    const classes = useStyles();

    const steps = stepsProps.filter(stepProps => !stepProps.disabled);
    const [currentStep, setCurrentStep] = useState(initialStepIndex || 0);

    useEffect(() => {
        !!onLoad && onLoad();
    }, [onLoad]);

    const canEnterStep = useCallback(
        stepIndex => {
            if (!steps[stepIndex]) {
                return true;
            }
            return !steps[stepIndex].hasOwnProperty('canEnter') || steps[stepIndex].canEnter === true;
        },
        [steps]
    );

    const currentStepFrozen = useMemo(() => steps[currentStep].frozen, [steps, currentStep]);

    const notEnterablePrevious = useMemo(() => !canEnterStep(currentStep - 1) || currentStepFrozen, [
        canEnterStep,
        currentStep,
        currentStepFrozen,
    ]);
    const notEnterableNext = useMemo(() => !canEnterStep(currentStep + 1) || currentStepFrozen, [
        canEnterStep,
        currentStep,
        currentStepFrozen,
    ]);

    const onFinish = useCallback(() => {
        if (currentStepFrozen) {
            return;
        }
        if (steps[currentStep].beforeNextStep) {
            steps[currentStep].beforeNextStep(() => {
                onFinished && onFinished();
            });
        } else {
            onFinished && onFinished();
        }
    }, [currentStep, onFinished, steps, currentStepFrozen]);

    const onNext = useCallback(() => {
        if (currentStep === steps.length - 1) {
            onFinish();
            return;
        }

        if (currentStepFrozen || notEnterableNext) {
            return;
        }
        if (steps[currentStep].beforeNextStep) {
            steps[currentStep].beforeNextStep(() => {
                setCurrentStep(currentStep + 1);
            });
        } else {
            setCurrentStep(currentStep + 1);
        }
    }, [currentStep, steps, currentStepFrozen, notEnterableNext, onFinish]);

    const onPrevious = useCallback(() => {
        if (currentStepFrozen || notEnterablePrevious) {
            return;
        }
        organizeStepValues && steps?.[currentStep - 1]?.stepKey && organizeStepValues(steps[currentStep - 1].stepKey);
        setCurrentStep(currentStep - 1);
    }, [currentStepFrozen, notEnterablePrevious, organizeStepValues, steps, currentStep]);

    const propsToContent = useMemo(
        () => ({
            goPrevious: onPrevious,
            goNext: onNext,
            skip: onFinished,
        }),
        [onPrevious, onNext, onFinished]
    );

    const closeIconJSX = useCallback(() => {
        return <Close className={classes.icon} onClick={onFinished} />;
    }, [classes.icon, onFinished]);

    const stepJSX = useCallback(() => {
        const { title, subTitle, content } = steps[currentStep];

        return (
            <div className={classes.stepContent}>
                <div className={classes.stepContentHeader}>
                    {title && <h3 className="font-weight-bold">{title}</h3>}
                    {subTitle && <p className={`${classes.subTitle} mt-2`}>{subTitle}</p>}
                </div>
                <div className={classes.stepContentBody}>
                    {typeof content === 'function' ? content(propsToContent) : content}
                </div>
            </div>
        );
    }, [
        steps,
        currentStep,
        classes.stepContent,
        classes.stepContentHeader,
        classes.subTitle,
        classes.stepContentBody,
        propsToContent,
    ]);

    const pointsJSX = useCallback(() => {
        return (
            <div>
                {steps.map((step, index) => {
                    if (index === currentStep) {
                        return <FiberManualRecordIcon className={classes.point} key={index} />;
                    }

                    const enterable = canEnterStep(index) && !currentStepFrozen;
                    const onClick = () => setCurrentStep(index);
                    return (
                        <FiberManualRecordOutlinedIcon
                            key={index}
                            className={`${classes.point} ${!enterable ? classes.notEnterable : classes.icon}`}
                            onClick={!enterable ? null : onClick}
                        />
                    );
                })}
            </div>
        );
    }, [steps, currentStep, canEnterStep, currentStepFrozen, classes.point, classes.notEnterable, classes.icon]);

    const buttonsLeftJSX = useCallback(() => {
        return (
            <div>
                {currentStep !== 0 && (
                    <Button
                        type="success"
                        inversed
                        className={`${notEnterablePrevious ? classes.notEnterable : ''}`}
                        onClick={onPrevious}
                    >
                        Previous
                    </Button>
                )}
            </div>
        );
    }, [classes.notEnterable, currentStep, notEnterablePrevious, onPrevious]);

    const buttonsRightJSX = useCallback(() => {
        return (
            <div>
                {currentStep !== steps.length - 1 && (
                    <Button type="success" className={notEnterableNext ? classes.notEnterable : null} onClick={onNext}>
                        Next
                    </Button>
                )}
                {currentStep === steps.length - 1 && (
                    <Button
                        type="success"
                        className={currentStepFrozen ? classes.notEnterable : null}
                        onClick={onFinish}
                    >
                        Finish
                    </Button>
                )}
            </div>
        );
    }, [classes.notEnterable, currentStep, steps, currentStepFrozen, notEnterableNext, onNext, onFinish]);

    const { allSpace, content } = steps[currentStep];

    return (
        <Fade in timeout={500}>
            <div className={classes.main}>
                {allSpace && (
                    <div className={classes.contentAllSpace}>
                        {typeof content === 'function' ? content(propsToContent) : content}
                    </div>
                )}
                {!allSpace && (
                    <div className={classes.content}>
                        <div className={classes.iconsRow}>{closeIconJSX()}</div>
                        {steps?.length > 0 && <div className={classes.contentInner}>{stepJSX()}</div>}
                        {steps?.length > 0 && !steps[currentStep].disabledFooter && (
                            <div className={classes.footer}>
                                {buttonsLeftJSX()}
                                {!steps[currentStep].disabledPoints && pointsJSX()}
                                {buttonsRightJSX()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Fade>
    );
});

Flow.propTypes = {
    initialStepIndex: PropTypes.number,
    onLoad: PropTypes.func,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            subTitle: PropTypes.string,
            content: PropTypes.any,
            beforeNextStep: PropTypes.func,
            frozen: PropTypes.bool,
            disabled: PropTypes.bool,
            disabledPoints: PropTypes.bool,
            disabledFooter: PropTypes.bool,
        })
    ),
    onFinished: PropTypes.func,
};

export default Flow;
