export const GROUP_CONTEXT_MENU = 'GROUP_CONTEXT_MENU';
export const ITEM_CONTEXT_MENU = 'ITEM_CONTEXT_MENU';
export const ADD_NEW_ITEM_CONTEXT_MENU = 'ADD_NEW_ITEM_CONTEXT_MENU';
export const EDIT_BOOKING_CONTEXT_MENU = 'EDIT_BOOKING_CONTEXT_MENU';
export const EDIT_RESOURCE_PARENT_ITEMS_CONTEXT_MENU = 'EDIT_RESOURCE_PARENT_ITEMS_CONTEXT_MENU';
export const EDIT_DEADLINE_CONTEXT_MENU = 'EDIT_DEADLINE_CONTEXT_MENU';
export const SCHEDULE_CONTEXT_MENU = 'SCHEDULE_CONTEXT_MENU';
export const MENU_ROW = 'MENU_ROW';
export const CUSTOM_COLUMNS = 'CUSTOM_COLUMNS';
export const SAVED_SCHEDULE_CONTEXT_MENU = 'SAVED_SCHEDULE_CONTEXT_MENU';
