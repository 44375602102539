import moize from 'moize';
import { filter, values, chain, find, contains, pluck } from 'underscore';
import { EventNote, NaturePeople, PeopleOutline, Person } from '@material-ui/icons';
import { TYPE_EVENT } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { UNASSIGNED as UNASSIGNED_EXTENSION } from 'enums/extensionShortIdEnum';
import { isActive } from 'utils/extensionUtil';
import { replaceKeywords } from 'utils/keywordsUtil';

export const PROJECT = {
    display: 'Project',
    name: 'project',
    value: 'PROJECT',
    default: false,
    propItem: 'projects',
    propGroupItem: 'projectGroups',
    idProperty: '_id',
    filteredCustomFieldProp: 'filteredProjectCustomFields',
    icon: EventNote,
    itemFilter: (projects, selectedItems) =>
        filter(
            projects,
            item => item.type !== TYPE_EVENT.value && selectedItems && !contains(pluck(selectedItems, '_id'), item._id)
        ),
    groupFilter: (projectGroups, replaceKeyWordsMap, selectedGroups) =>
        chain(projectGroups)
            .filter(
                group =>
                    group.criteria !== TYPE_EVENT.value &&
                    selectedGroups &&
                    !contains(pluck(selectedGroups, '_id'), group._id)
            )
            .map(group => ({
                ...group,
                name: replaceKeywords(replaceKeyWordsMap, group.name),
            }))
            .value(),
    additionalGroupProps: props => ({
        fields: { label: 'name', value: '_id' },
        title: `${props && props.projectKeyWord} Groups`,
        subTitle: `Add ${props && props.projectKeyWord} Groups to Report`,
        groupSubItem: 'projects',
    }),
    additionalProps: props => ({
        fields: {
            label: 'name',
            value: '_id',
            additional: 'projectCode',
            wrapWithBrackets: true,
        },
        filterTitle: `Filter by ${props && props.projectKeyWord} or group name`,
        title: `Single ${props && props.projectPluralKeyWord}`,
        icon: Person,
        subTitle: `Add ${props && props.projectPluralKeyWord} to Report`,
    }),
    extension: 'ALL',
    default2ndLevelGrouping: 'resource',
};

export const RESOURCE = {
    display: 'Resource',
    name: 'resource',
    value: 'RESOURCE',
    propItem: 'resources',
    propGroupItem: 'resourceGroups',
    idProperty: '_id',
    filteredCustomFieldProp: 'filteredResourceCustomFields',
    icon: Person,
    itemFilter: (resources, selectedItems) =>
        filter(
            resources,
            item =>
                item.type !== TYPE_UNASSIGNED.value && selectedItems && !contains(pluck(selectedItems, '_id'), item._id)
        ),
    groupFilter: (resourceGroups, replaceKeyWordsMap, selectedGroups) =>
        chain(resourceGroups)
            .filter(
                group =>
                    group.criteria !== TYPE_UNASSIGNED.value &&
                    selectedGroups &&
                    !contains(pluck(selectedGroups, '_id'), group._id)
            )
            .map(group => ({
                ...group,
                name: replaceKeywords(replaceKeyWordsMap, group.name),
            }))
            .value(),
    additionalGroupProps: props => ({
        fields: { label: 'name', value: '_id' },
        title: `${props && props.resourceKeyWord} Groups`,
        subTitle: `Add ${props && props.resourceKeyWord} Groups to Report`,
        groupSubItem: 'resources',
    }),
    additionalProps: props => ({
        fields: {
            label: 'firstName',
            value: '_id',
            additional: 'lastName',
        },
        filterTitle: `Filter by ${props && props.resourceKeyWord} or group name`,
        title: `Single ${props && props.resourcePluralKeyWord}`,
        subTitle: `Add ${props && props.resourcePluralKeyWord} to Report`,
    }),
    extension: 'ALL',
    default2ndLevelGrouping: 'project',
};

export const EVENT = {
    display: 'Event',
    value: 'EVENT',
    name: 'event',
    default: false,
    propItem: 'projects',
    propGroupItem: 'projectGroups',
    idProperty: '_id',
    icon: NaturePeople,
    itemFilter: (projects, selectedItems) =>
        filter(
            projects,
            item => item.type === TYPE_EVENT.value && selectedItems && !contains(pluck(selectedItems, '_id'), item._id)
        ),
    groupFilter: projectGroups => filter(projectGroups, group => group.criteria === TYPE_EVENT.value),
    additionalProps: props => ({
        fields: {
            label: 'name',
            value: '_id',
            additional: 'projectCode',
            wrapWithBrackets: true,
        },
        filterTitle: `Filter by ${props && props.projectKeyWord} or group name`,
        title: 'Events',
        subTitle: 'Add Events to Report',
    }),
    extension: 'ALL',
    default2ndLevelGrouping: 'resource',
};

export const UNASSIGNED = {
    display: 'Unassigned',
    value: 'UNASSIGNED',
    name: 'unassigned',
    default: false,
    propItem: 'resources',
    propGroupItem: 'resourceGroups',
    idProperty: '_id',
    filteredCustomFieldProp: 'filteredResourceCustomFields',
    icon: PeopleOutline,
    itemFilter: (resources, selectedItems) =>
        filter(
            resources,
            item =>
                item.type === TYPE_UNASSIGNED.value && selectedItems && !contains(pluck(selectedItems, '_id'), item._id)
        ),
    groupFilter: resourceGroups => filter(resourceGroups, group => group.criteria === TYPE_UNASSIGNED.value),
    additionalProps: props => ({
        fields: {
            label: 'firstName',
            value: '_id',
        },
        filterTitle: `Filter by ${props && props.resourceKeyWord} or group name`,
        title: 'Unassigned work',
        subTitle: `Add Unassigned work to Report`,
    }),
    extension: UNASSIGNED_EXTENSION,
    default2ndLevelGrouping: 'project',
};

export const itemTypes = {
    PROJECT,
    EVENT,
    RESOURCE,
    UNASSIGNED,
};

export const replaceMap = {
    [EVENT.name]: PROJECT.name,
    [UNASSIGNED.name]: RESOURCE.name,
};

export const findItemTypeByName = moize(name => find(itemTypes, itemType => name === itemType.name) || {}, {
    maxSize: 2,
});

export const getItemTypesByExtensions = moize(
    extensions =>
        filter(
            values(itemTypes),
            groupType => 'ALL' === groupType.extension || isActive(extensions, groupType.extension)
        ) || {},
    { maxSize: 2 }
);
