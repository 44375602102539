import React from 'react';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, ListSubheader, Typography, FormHelperText, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';

const LIST_BOX_PADDING = 8; // px

const renderRow = ({ data, index, style }) =>
    React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LIST_BOX_PADDING,
        },
    });

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);

    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (null !== ref.current) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);

    return ref;
}

// Adapter for react-window
const ListBoxComponent = React.forwardRef(function ListBoxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = child => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (8 < itemCount) {
            return 8 * itemSize;
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LIST_BOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={index => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

ListBoxComponent.propTypes = {
    children: PropTypes.node,
};

const useStyles = makeStyles({
    inputRoot: {
        height: '37px',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
    listbox: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
    input: {
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
    },
});

export const DropdownField = props => {
    const {
        data,
        label,
        input,
        meta,
        formHelperText,
        textField,
        valueField,
        wrapperClassName,
        disabled,
        autocompleteProps = {},
        placeholder,
        filterSelectedOptions = true,
    } = props;
    const { input: inputClass, ...classes } = useStyles();
    const { onChange, onBlur, ...inputRest } = input;

    const onChangeInt = (event, value) => {
        onChange(value);
    };

    const getOptionSelected = (option, value) => {
        return 0 < Object.keys(option).length && option[valueField] === value[valueField];
    };

    return (
        <div className={wrapperClassName}>
            <Autocomplete
                disablePortal={true}
                id={input.name}
                disableListWrap
                classes={classes}
                fullWidth
                getOptionLabel={option => (isFunction(textField) ? textField(option) : option[textField] || '')}
                getOptionSelected={getOptionSelected}
                freeSolo
                ListboxComponent={ListBoxComponent}
                {...inputRest}
                filterSelectedOptions={filterSelectedOptions}
                clearOnBlur={true}
                onChange={onChangeInt}
                options={data}
                disabled={disabled}
                {...autocompleteProps}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            placeholder={placeholder}
                            error={!!(meta?.touched && meta?.error)}
                            label={label}
                            variant="outlined"
                            className={inputClass}
                            onBlur={() => onBlur()}
                        />
                    );
                }}
                renderOption={option => (
                    <Typography noWrap>
                        {isFunction(textField) ? textField(option) : option[textField] || ''}
                    </Typography>
                )}
            />
            {(meta?.touched && meta?.error) || formHelperText ? (
                <FormHelperText className="mt-1" error={!!(meta?.touched && meta?.error)}>
                    {(meta?.touched && meta?.error) || formHelperText}
                </FormHelperText>
            ) : null}
        </div>
    );
};

export default DropdownField;
