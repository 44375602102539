import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { includes, isNil, isUndefined } from 'lodash';
import { Field } from 'redux-form';
import { TabPane, FormText } from 'reactstrap';
import { useIsExtensionInstalled, useHasRights } from 'hooks';
import { CUSTOM_FIELDS } from 'enums/extensionShortIdEnum';
import * as customFieldTypes from 'enums/customFieldEnum';
import {
    chooseField,
    dropdownField,
    multiSelectField,
    colorInputField,
    inputGroupTextField,
    dateField,
    textField,
} from 'shared/formFields';
import MissingExtension from 'shared/warnings/missingExtension';
import PermissionDenied from 'shared/permissionDenied';
import {
    customFieldEmail,
    fieldColorValidator,
    customFieldMaxLength,
    customFieldMaxValidator,
    customFieldMinValidator,
    customFieldRequiredValidator,
} from 'utils/formValidators';
import { NoCustomFieldsTitle } from 'modules/modals/styles';
import { intlCollator } from '../../../utils/stringUtils';

export const customFieldsValidator = ({
    formCustomFieldsValues = {},
    customFields = [],
    onlyResourceEdit = false,
    hasRights,
}) => {
    if (!hasRights) {
        return true;
    }

    const requiredCustomFields = customFields
        .filter(cf => {
            if (onlyResourceEdit) {
                return cf.canResourceEdit;
            }

            return true;
        })
        .filter(cf => cf.isRequired);

    const isSomeEmpty = requiredCustomFields.some(reqCf => {
        const value = formCustomFieldsValues[reqCf._id];
        return customFieldRequiredValidator(value);
    });

    return !isSomeEmpty;
};

export const useCustomFieldsFormValidator = ({
    formCustomFieldsValues = {},
    customFields = [],
    onlyResourceEdit = false,
    hasRights,
}) => {
    return useMemo(() => {
        return customFieldsValidator({ formCustomFieldsValues, customFields, onlyResourceEdit, hasRights });
    }, [customFields, formCustomFieldsValues, hasRights, onlyResourceEdit]);
};

const numberValidators = customField => {
    let validators = [];

    if (!isNil(customField.maxValue)) {
        validators = validators.concat([customFieldMaxValidator(customField.maxValue)]);
    }
    if (!isNil(customField.minValue)) {
        validators = validators.concat([customFieldMinValidator(customField.minValue)]);
    }

    if (!isNil(customField.characterLimit)) {
        validators = validators.concat([customFieldMaxLength(customField.characterLimit)]);
    }

    return validators;
};

const textValidators = customField =>
    !isNil(customField.characterLimit) ? [customFieldMaxLength(customField.characterLimit)] : [];

const extendValidatorsWithRequired = (customField, shouldValidate, validators) => {
    if (!shouldValidate) {
        return validators;
    }

    if (customField.isRequired) {
        return validators.concat([customFieldRequiredValidator]);
    }

    return validators;
};

const getFieldComponent = (customField, onFieldChange, shouldValidate) => {
    let field = null;

    switch (customField.type) {
        case customFieldTypes.EMAIL.value:
        case customFieldTypes.TEXT.value:
            field = (
                <Field
                    width="col-md-12"
                    name={`customFields.${customField._id}`}
                    icon="fa-file"
                    type={customField.type.toLowerCase()}
                    validate={extendValidatorsWithRequired(
                        customField,
                        shouldValidate,
                        customFieldTypes.EMAIL.value === customField.type
                            ? [customFieldEmail]
                            : textValidators(customField)
                    )}
                    placeholder={customField.placeholderText}
                    component={inputGroupTextField}
                />
            );
            break;
        case customFieldTypes.TEXTAREA.value:
            field = (
                <Field
                    width="col-md-12"
                    name={`customFields.${customField._id}`}
                    icon="fa-file"
                    type={customField.type.toLowerCase()}
                    placeholder={customField.placeholderText}
                    component={textField}
                    validate={extendValidatorsWithRequired(customField, shouldValidate, textValidators(customField))}
                />
            );
            break;
        case customFieldTypes.NUMBER.value:
            field = (
                <Field
                    width="col-md-12"
                    name={`customFields.${customField._id}`}
                    icon="fa-file"
                    step={customField.stepValue}
                    max={customField.maxValue}
                    min={customField.minValue}
                    type={customField.type.toLowerCase()}
                    placeholder={customField.placeholderText}
                    validate={extendValidatorsWithRequired(customField, shouldValidate, numberValidators(customField))}
                    component={inputGroupTextField}
                />
            );
            break;
        case customFieldTypes.DATE.value: {
            field = (
                <Field
                    name={`customFields.${customField._id}`}
                    component={dateField}
                    validate={extendValidatorsWithRequired(customField, shouldValidate, [])}
                />
            );
            break;
        }
        case customFieldTypes.COLOR.value:
            field = (
                <Field
                    width="col-md-12"
                    name={`customFields.${customField._id}`}
                    placeholder={customField.placeholderText}
                    component={colorInputField}
                    validate={extendValidatorsWithRequired(customField, shouldValidate, [fieldColorValidator])}
                    onColorChange={color =>
                        onFieldChange({
                            [`customFields.${customField._id}`]: color.hex,
                        })
                    }
                />
            );
            break;
        case customFieldTypes.SELECT.value:
            if (customField.allowMultipleValues) {
                field = (
                    <Field
                        width="col-md-12"
                        name={`customFields.${customField._id}`}
                        placeholder={customField.placeholderText}
                        component={multiSelectField}
                        filter="contains"
                        valueField="_id"
                        textField="value"
                        data={customField.choices}
                        validate={extendValidatorsWithRequired(customField, shouldValidate, [])}
                    />
                );
            } else {
                field = (
                    <Field
                        name={`customFields.${customField._id}`}
                        placeholder={customField.placeholderText}
                        component={dropdownField}
                        valueField="_id"
                        textField={item => (Object.keys(item).length ? item.value : null)}
                        filter="contains"
                        data={customField.choices}
                        validate={extendValidatorsWithRequired(customField, shouldValidate, [])}
                    />
                );
            }
            break;
    }

    return field;
};

const CustomFieldTab = React.memo(({ tabId, customFields, onChange, type, bookingContext }) => {
    const isExtensionInstalled = useIsExtensionInstalled(CUSTOM_FIELDS);
    const { hasCustomFieldRights } = useHasRights([
        {
            rights: ['resource' === type ? 'settingResourceCf' : 'settingProjectCf'],
            rule: 'one',
            name: 'hasCustomFieldRights',
        },
    ]);

    const orderedCustomFields = useMemo(
        () =>
            customFields.map(cf => {
                cf.choices =
                    isUndefined(cf.isChoicesSortedAlphabetically) || cf.isChoicesSortedAlphabetically
                        ? [...cf.choices].sort((choiceA, choiceB) => intlCollator.compare(choiceA.value, choiceB.value))
                        : [...cf.choices];
                return cf;
            }),
        [customFields]
    );

    return (
        <TabPane tabId={tabId}>
            {!isExtensionInstalled && (
                <MissingExtension description="Custom fields that you are allowed to edit will appear here. Please enable this extension from extension page" />
            )}
            {isExtensionInstalled && orderedCustomFields.length && hasCustomFieldRights
                ? orderedCustomFields.map((customField, index) => (
                      <section
                          key={index}
                          className={classnames('form-fields', {
                              'border-bottom-0': orderedCustomFields.length - 1 === index,
                          })}
                      >
                          <p className="title">
                              {customField.label}
                              {!bookingContext && customField.isRequired ? <span className="required">*</span> : null}
                          </p>
                          {includes([customFieldTypes.RADIO.value, customFieldTypes.CHECKBOX.value], customField.type)
                              ? customField.choices.map((choice, choiceIndex) => (
                                    <Field
                                        key={choiceIndex}
                                        type={customField.type.toLowerCase()}
                                        label={choice.value}
                                        name={
                                            customField.type === customFieldTypes.RADIO.value
                                                ? `customFields.${customField._id}`
                                                : `customFields.${customField._id}.${choice._id}`
                                        }
                                        value={choice._id}
                                        wrapperClass="mb-2"
                                        placeholder={customField.placeholderText}
                                        component={chooseField}
                                    />
                                ))
                              : getFieldComponent(customField, onChange, !bookingContext)}
                          {customField.instructions ? <FormText>{customField.instructions}</FormText> : null}
                      </section>
                  ))
                : null}
            {isExtensionInstalled && hasCustomFieldRights && !orderedCustomFields.length && (
                <div>
                    <NoCustomFieldsTitle>No Custom Fields Available</NoCustomFieldsTitle>
                    <span className="text-center">
                        Custom fields that you are allowed to edit will appear here. At the moment there are no editable
                        custom fields available for your profile. This needs to be configured on each field by an Admin
                        with access to the Custom Fields settings.
                    </span>
                </div>
            )}
            {isExtensionInstalled && !hasCustomFieldRights && <PermissionDenied />}
        </TabPane>
    );
});

CustomFieldTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    customFields: PropTypes.array,
    type: PropTypes.oneOf(['project', 'resource']),
    bookingContext: PropTypes.bool,
};

CustomFieldTab.defaultProps = {
    customFields: [],
    onChange: null,
    type: 'resource',
    bookingContext: false,
};

export default CustomFieldTab;
