import React from 'react';
import Video from './Video';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledImage = styled.img`
    width: 402px;
    max-height: 276px;
    object-fit: cover;
    object-position: top center;
`;

const TipPopupSlide = React.memo(props => {
    const { videoPosterUrl, videoUrl, imageUrl, upperContent, lowerContent } = props;
    return (
        <div>
            {upperContent && <div>{upperContent}</div>}
            {videoUrl && <Video videoPosterSrc={videoPosterUrl} videoSrc={videoUrl} controls={false} autoPlay />}
            {imageUrl && <StyledImage src={imageUrl} />}
            {lowerContent && <div>{lowerContent}</div>}
        </div>
    );
});

TipPopupSlide.propTypes = {
    videoPosterUrl: PropTypes.string,
    videoUrl: PropTypes.string,
};

export default TipPopupSlide;
