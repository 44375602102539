const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export const createRequestTypes = (base, includeFailure) => {
    let typesArray = [REQUEST, SUCCESS];
    if (includeFailure) {
        typesArray = [...typesArray, FAILURE];
    }

    return typesArray.reduce((acc, type) => {
        acc[type] = `${base}_${type}`;

        return acc;
    }, {});
};

export const action = (type, payload = {}) => ({ type, payload });
