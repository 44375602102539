import moize from 'moize';
import { find } from 'underscore';

export const OPERATORS = [
    {
        name: 'Equal',
        description: 'Is equal to',
        value: 'eq',
        match: (match, value) => value === match,
        icon: '=',
    },
    {
        name: 'Not equal',
        description: 'Is not equal to',
        value: 'ne',
        match: (match, value) => value !== match,
        icon: '≠',
    },
    {
        name: 'Less than',
        description: 'Less than',
        value: 'lt',
        match: (match, value) => value < match,
        icon: '<',
    },
    {
        name: 'Greater than',
        description: 'Greater than',
        value: 'gt',
        match: (match, value) => value > match,
        icon: '>',
    },
    {
        name: 'Less than or equal',
        description: 'Less than or equal',
        value: 'lte',
        match: (match, value) => value <= match,
        icon: '≤',
    },
    {
        name: 'Greater than or equal',
        description: 'Greater than or equal',
        value: 'gte',
        match: (match, value) => value >= match,
        icon: '≥',
    },
];

export const findOperatorByValue = moize(value => find(OPERATORS, operator => value === operator.value), {
    maxSize: 2,
});
