import { connect } from 'react-redux';
import { submit, formValueSelector, hasSubmitFailed, getFormValues } from 'redux-form';
import { resetData } from 'actions/bookingActions';
import ScheduleResourceModal, { FORM_NAME } from './components/modal';
import {
    selectCompanyDeadlinesBeforeBookingEndDateConfig,
    selectIsDeadlinesExtensionInstalled
} from '../../../selectors/company';

const selector = formValueSelector(FORM_NAME);

export default connect(
    state => {
        return ({
            isDeadlinesExtensionInstalled: selectIsDeadlinesExtensionInstalled(state),
            deadlinesCompanyConfig: selectCompanyDeadlinesBeforeBookingEndDateConfig(state),
            formValues: getFormValues(FORM_NAME)(state),
            hasSubmitFailed: hasSubmitFailed(FORM_NAME)(state),
            externalRateType: selector(state, 'externalRateType'),
            internalRateType: selector(state, 'internalRateType'),
            iconLinks: selector(state, 'iconLink1', 'iconLink2', 'iconLink3', 'iconLink4', 'iconLink5'),
        })
    },
    dispatch => ({
        resetData: prop => dispatch(resetData(prop)),
        submitForm: () => dispatch(submit(FORM_NAME)),
    })
)(ScheduleResourceModal);
