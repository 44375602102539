import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    root: {
        fontSize: '30px',
        cursor: 'pointer',
        marginLeft: '6px',
    },
});

export const Wrapper = styled.div`
    overflow-x: hidden;
    z-index: 999;
`;

export const Panels = styled.div`
    white-space: nowrap;
    transform: translate3d(0, 0, 0);
    transition: 0.3s;
    display: flex;
    flex-direction: row;

    &[class*='active-'] {
        transform: ${props => `translate3d(-${props.slide * 100 + 0.6}%, 0, 0)`};
    }
`;

export const Panel = styled.div`
    transition: 0.3s opacity ease-in;
    flex: 0 0 ${props => props.width || 200}px;
    position: relative;
    opacity: 0;
    overflow-x: hidden;
    padding: 10px 0;
    height: 0;
    overflow-y: hidden;

    &.active {
        opacity: 1;
        height: 100%;
    }

    .panel-header-wrapper {
        display: flex;
        align-items: center;
    }

    .panel-header {
        font-weight: 600;
        display: inline-block;
    }
`;
