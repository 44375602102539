import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEqual from 'react-fast-compare';
import { isDate } from 'underscore';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { FormText, Col } from 'reactstrap';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { isAboveMaxRange } from 'utils/DateUtil';
import DateRangeButtons from './buttons';
import DatepickerInput from './datepickerInput';
import { ButtonsRowWrapper, Wrapper } from './styles';
import { Field } from 'redux-form';
import getDatePeriods from './getDatePeriods';
import { format } from 'date-fns';
import { ButtonsV2 } from './buttonsV2';

class DateRange extends PureComponent {
    static propTypes = {
        clearable: PropTypes.bool,
        single: PropTypes.bool,
        disabled: PropTypes.bool,
        sideBySide: PropTypes.bool,
        onChange: PropTypes.func,
        initialStartDate: PropTypes.object,
        initialEndDate: PropTypes.object,
        initialDateState: PropTypes.string,
        initialPeriodType: PropTypes.string,
        startDateText: PropTypes.string,
        endDateText: PropTypes.string,
        withButtons: PropTypes.bool,
        withButtonsV2: PropTypes.bool,
        width: PropTypes.string,
        centered: PropTypes.bool,
        displayFormText: PropTypes.bool,
        startDateRequired: PropTypes.bool,
        endDateRequired: PropTypes.bool,
        placeholder: PropTypes.string,
        singleDateError: PropTypes.string,
        weekType: PropTypes.string,
        maxRange: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string,
        }),
        isReduxFormField: PropTypes.bool,
        customDatesPicker: PropTypes.array,
        minAllowedStartDate: PropTypes.object,
        maxAllowedEndDate: PropTypes.object,
        maxDropdownHeight: PropTypes.string,
        dataCy: PropTypes.string,
        transformTimezone: PropTypes.bool,
    };

    static defaultProps = {
        clearable: false,
        single: true,
        disabled: false,
        sideBySide: true,
        withButtons: false,
        startDateRequired: false,
        endDateRequired: false,
        onChange: null,
        startDateText: 'Start date',
        endDateText: 'End date',
        centered: false,
        displayFormText: true,
        width: 'inherit',
        placeholder: 'MMM D, YYYY',
        weekType: 'isoWeek',
        maxRange: {},
        isReduxFormField: false,
        transformTimezone: true,
    };

    constructor(props) {
        super(props);

        const datePeriods = getDatePeriods(props.initialDateState, props.initialPeriodType, props.weekType);

        this.state = {
            startDate: datePeriods ? datePeriods.startDate : props.initialStartDate || undefined,
            endDate: datePeriods ? datePeriods.endDate : props.initialEndDate || undefined,
            dateState: props.initialDateState || undefined,
            periodType: props.initialPeriodType || undefined,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            !isEqual(prevProps.initialStartDate, this.props.initialStartDate) ||
            !isEqual(prevProps.initialEndDate, this.props.initialEndDate) ||
            prevProps.initialDateState !== this.props.initialDateState ||
            prevProps.initialPeriodType !== this.props.initialPeriodType
        ) {
            this.setState({
                startDate: this.props.initialStartDate || undefined,
                endDate: this.props.initialEndDate || undefined,
                dateState: this.props.initialDateState || undefined,
                periodType: this.props.initialPeriodType || undefined,
            });
        }
    }

    handleFromChange = startDate => {
        if (isDate(startDate)) {
            startDate = new Date(
                startDate.valueOf() - (this.props.transformTimezone ? startDate.getTimezoneOffset() * 60 * 1000 : 0)
            );
        }
        let endDate = this.state.endDate;
        let currentDateState = this.state.dateState;
        if (this.props.single) {
            endDate = startDate;
        }
        let newState = { ...this.state, startDate, endDate };

        if (this.props.withButtons) {
            currentDateState = '';
            newState.dateState = currentDateState;
            newState.periodType = 'custom';
        }

        // Manual date, so custom
        if (this.props.withButtonsV2) {
            newState.dateState = 'custom';
            newState.periodType = 'custom';
        }

        this.setState(newState);
        this.props.onChange(newState);
    };

    handleToChange = endDate => {
        if (isDate(endDate)) {
            endDate = new Date(
                endDate.valueOf() - (this.props.transformTimezone ? endDate.getTimezoneOffset() * 60 * 1000 : 0)
            );
        }
        let newState = { ...this.state, endDate };
        let currentDateState = this.state.dateState;

        if (this.props.withButtons) {
            currentDateState = '';
            newState.dateState = currentDateState;
            newState.periodType = 'custom';
        }

        // Manual date, so custom
        if (this.props.withButtonsV2) {
            newState.dateState = 'custom';
            newState.periodType = 'custom';
        }

        this.setState(newState);
        this.props.onChange(newState);
    };

    updateDates = dates => {
        this.setState({ ...dates });
        this.props.onChange({ ...dates });
    };

    render() {
        const { disabled, clearable } = this.props;
        const { dateState, periodType, startDate, endDate } = this.state;
        const modifiers = { start: startDate, end: endDate };

        const renderStartDateField = ({ input, meta } = {}) => {
            const inputProps = {
                ...(clearable ? { type: 'search' } : {}),
                input: { name: 'start-date', ...input },
                className: disabled ? 'disabled-input-group' : '',
                errorText: this.props.singleDateError,
                meta,
                'data-cy': `${this.props.dataCy}-start-input`,
                disabled: disabled,
                label: this.props.single ? 'Date' : 'Start date',
                height: this.props?.height,
                helperText: this.props.startDateError,
                error: this.props.startDateError ? true : false,
            };
            let disabledDays;
            if (this.props.minAllowedStartDate) {
                disabledDays = {
                    before: this.props.minAllowedStartDate,
                };
            } else {
                disabledDays = {
                    after: this.props.single ? this.props.maxAllowedEndDate : endDate,
                };
            }
            const dayPickerProps = {
                selectedDays: [
                    startDate,
                    {
                        from: this.props.single ? null : startDate,
                        to: this.props.single ? null : endDate,
                    },
                ],
                disabledDays,
                toMonth: this.props.single ? null : endDate,
                modifiers,
                numberOfMonths: 1,
                firstDayOfWeek: 'week' === this.props.weekType ? 0 : 1,
                renderDay: day => {
                    if (this.props.dataCy) {
                        const date = format(day, 'dd/MM/yyyy');
                        return <span data-cy={`${this.props.dataCy}-start-[${date}]`}>{day.getDate()}</span>;
                    }
                    return day.getDate();
                },
            };

            return (
                <DayPickerInput
                    component={DatepickerInput}
                    value={startDate}
                    format="MMM D, YYYY"
                    inputProps={inputProps}
                    placeholder={this.props.placeholder}
                    showOutsideDays
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={dayPickerProps}
                    onDayChange={this.handleFromChange}
                />
            );
        };

        const renderEndDateField = ({ input, meta } = {}) => {
            const inputProps = {
                ...(clearable ? { type: 'search' } : {}),
                input: { name: 'end-date', ...input },
                meta,
                'data-cy': `${this.props.dataCy}-end-input`,
                disabled: disabled,
                label: 'End date',
                height: this.props?.height,
                helperText: this.props.endDateError,
                error: this.props.endDateError ? true : false,
            };
            let disabledDays;
            if (this.props.maxAllowedEndDate) {
                disabledDays = { after: this.props.maxAllowedEndDate };
            } else {
                disabledDays = { before: startDate };
            }
            const dayPickerProps = {
                selectedDays: [startDate, { from: startDate, to: endDate }],
                disabledDays,
                modifiers,
                month: startDate,
                fromMonth: startDate,
                numberOfMonths: 1,
                firstDayOfWeek: 'week' === this.props.weekType ? 0 : 1,
                renderDay: day => {
                    if (this.props.dataCy) {
                        const date = `${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`;
                        return <span data-cy={`${this.props.dataCy}-end-[${date}]`}>{day.getDate()}</span>;
                    }
                    return day.getDate();
                },
            };
            return (
                <DayPickerInput
                    component={DatepickerInput}
                    value={endDate}
                    format="MMM D, YYYY"
                    inputProps={inputProps}
                    placeholder={this.props.placeholder}
                    showOutsideDays
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={dayPickerProps}
                    onDayChange={this.handleToChange}
                />
            );
        };

        return (
            <Wrapper width={this.props.width} centered={this.props.centered}>
                {this.props.withButtons && (
                    <ButtonsRowWrapper noGutters centered={this.props.centered}>
                        <Col md={12} className={this.props.centered ? 'text-center' : ''}>
                            <DateRangeButtons
                                dataCy={`${this.props.dataCy}-date-range-buttons`}
                                dateState={dateState}
                                periodType={periodType}
                                onClick={this.updateDates}
                                weekType={this.props.weekType}
                                customDatesPicker={this.props.customDatesPicker}
                                maxHeight={this.props.maxDropdownHeight}
                            />
                        </Col>
                    </ButtonsRowWrapper>
                )}
                <ButtonsRowWrapper centered={this.props.centered}>
                    {this.props.withButtonsV2 && (
                        <Col md={4} className="pr-1">
                            <ButtonsV2
                                disabled={disabled}
                                dataCy={`${this.props.dataCy}-date-range-buttons`}
                                dateState={dateState}
                                periodType={periodType}
                                onDatesChanged={this.updateDates}
                                weekType={this.props.weekType}
                            />
                        </Col>
                    )}
                    <Col md={this.props.sideBySide ? 4 : 12} className="px-1">
                        <div
                            className={classnames('InputFromTo', {
                                single: this.props.single,
                            })}
                        >
                            {this.props.single && this.props.label && <label>{this.props.label}</label>}
                            {this.props.isReduxFormField ? (
                                <Field name="startDate" component={renderStartDateField} />
                            ) : (
                                renderStartDateField()
                            )}
                        </div>
                        {this.props.displayFormText && (
                            <FormText className={classnames({ required: this.props.startDateRequired })}>
                                {!this.props.single ? this.props.startDateText : 'Date'}
                            </FormText>
                        )}
                    </Col>
                    {!this.props.single ? (
                        <Col md={4} className="pl-1">
                            <div className="InputFromTo-to">
                                {this.props.isReduxFormField ? (
                                    <Field name="endDate" component={renderEndDateField} />
                                ) : (
                                    renderEndDateField()
                                )}
                            </div>
                            {this.props.displayFormText && (
                                <FormText className={classnames({ required: this.props.endDateRequired })}>
                                    {this.props.endDateText}
                                </FormText>
                            )}
                        </Col>
                    ) : null}
                    {this.props.maxRange && isAboveMaxRange(startDate, endDate, this.props.maxRange) ? (
                        <div className="invalid-feedback text-center d-block">
                            <span>
                                The date range can be set to a maximum of {this.props.maxRange.value}{' '}
                                {1 === this.props.maxRange.value
                                    ? this.props.maxRange.unit.slice(0, -1)
                                    : this.props.maxRange.unit}
                            </span>
                        </div>
                    ) : null}
                </ButtonsRowWrapper>
            </Wrapper>
        );
    }
}

export default DateRange;
