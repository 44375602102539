import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { store } from '../store';

const getTagsFromRedux = () => {
    const currentTags = store.getState()?.tagReducer || {};
    return {
        PROJECT: currentTags.projectTags || [],
        RESOURCE: currentTags.resourceTags || [],
    };
};

const getDuplicateValidator = category => {
    return {
        validator: value => {
            const currentTags = getTagsFromRedux()[category];
            return !currentTags.find(ct => ct.value === value);
        },
        errorMessage: 'This tag already exists',
    };
};

// Check duplicates of: PROJECT / RESOURCE / ALL
export const getSchema = category => {
    return new Schema(
        {
            name: {
                type: String,
                required: true,
                validators: ['PROJECT', 'RESOURCE'].includes(category || '') ? [getDuplicateValidator(category)] : [],
            },
        },
        errorMessages,
        false
    );
};

// Backwards compatibility
export const formSchema = getSchema();
