import BaseMenu from 'modules/sidebar/services/menuBuilder/BaseMenu';
import { STATUS_TRIAL, STATUS_SUBSCRIBER } from 'enums/companyStatusEnum';
import { hasRole, hasOneOfRoles } from 'utils/rightsUtil';
import { isActive } from 'utils/extensionUtil';
import { UNASSIGNED, PM } from 'enums/extensionShortIdEnum';
import { TYPE_BRAINTREE } from 'modules/setting/enums/paymentTypeEnum';

/**
 * Each Menu Class need to have a items() function
 */
class SettingsMenu extends BaseMenu {
    setProps(keyWords, account, company, billingInfo) {
        this.keyWords = keyWords || {};
        this.account = account || [];
        this.company = company || {};
        this.billingInfo = billingInfo || {};

        return this;
    }

    items() {
        const isSubscribedOrOnTrial =
            STATUS_SUBSCRIBER.value === this.company.status || STATUS_TRIAL.value === this.company.status;

        return [
            {
                label: 'My Settings',
                defaultVisibility: true,
                display: isSubscribedOrOnTrial,
                content: [
                    {
                        label: 'My Profile',
                        to: '/settings/profile',
                    },
                    {
                        label: 'My Tags',
                        to: '/settings#myTags',
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'My Custom Fields',
                        to: '/settings/myCustomFields',
                        display: hasRole(this.account.resourceRoleRights, 'settingResourceCf'),
                    },
                    {
                        label: 'My iCal Feed',
                        to: '/settings/myIcalFeed',
                        display: hasRole(this.account.resourceRoleRights, 'calendarFeed'),
                    },
                    {
                        label: 'My Preferences',
                        to: '/settings/myPreferences',
                    },
                    {
                        label: 'My Notifications',
                        to: '/settings/myNotifications',
                    },
                ],
            },
            {
                label: 'Manage',
                defaultVisibility: true,
                display: hasOneOfRoles(this.account.resourceRoleRights, [
                    'settingAddEditResources',
                    'settingAddEditProjects',
                    'settingProjectClient',
                    'settingResourcePm',
                    'settingResourceAsApprover',
                ]),
                content: [
                    {
                        label: `Manage ${this.keyWords.resourcePluralKeyWord}`,
                        to: '/settings/resources',
                        display: hasRole(this.account.resourceRoleRights, 'settingAddEditResources'),
                    },
                    {
                        label: `Manage ${this.keyWords.projectPluralKeyWord}`,
                        to: '/settings/projects',
                        display:
                            isSubscribedOrOnTrial && hasRole(this.account.resourceRoleRights, 'settingAddEditProjects'),
                    },
                    {
                        label: `Manage ${this.keyWords.customerPluralKeyWord}`,
                        to: '/settings#clients',
                        display:
                            isSubscribedOrOnTrial && hasRole(this.account.resourceRoleRights, 'settingProjectClient'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Manage Project Managers',
                        to: '/settings/project-managers',
                        display:
                            isSubscribedOrOnTrial &&
                            isActive(this.company.extensions, PM) &&
                            hasRole(this.account.resourceRoleRights, 'settingResourcePm'),
                    },
                    {
                        label: 'Manage Approvers',
                        to: '/settings/approvers',
                        display:
                            isSubscribedOrOnTrial &&
                            hasRole(this.account.resourceRoleRights, 'settingResourceAsApprover'),
                    },
                ],
            },
            {
                label: 'Integrations',
                defaultVisibility: true,
                display:
                    isSubscribedOrOnTrial &&
                    hasOneOfRoles(this.account.resourceRoleRights, ['manageEvents', 'settingApi']),
                content: [
                    {
                        label: 'Custom Integrations',
                        to: '/settings#integration',
                        display: hasRole(this.account.resourceRoleRights, 'manageEvents'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Zapier',
                        to: '/settings#zapier',
                        display: hasRole(this.account.resourceRoleRights, 'manageEvents'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Hub Planner API',
                        to: '/settings#api',
                        display: hasRole(this.account.resourceRoleRights, 'settingApi'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                ],
            },
            {
                label: 'Manage Extensions',
                defaultVisibility: true,
                display:
                    isSubscribedOrOnTrial &&
                    hasOneOfRoles(this.account.resourceRoleRights, [
                        'settingViewExt',
                        'settingAddExt',
                        'manageUnassignedRows',
                        'settingCf',
                        'settingProjectCf',
                    ]),
                content: [
                    {
                        label: 'Add / Remove Extensions',
                        to: '/settings#extensions',
                        openInNewTab: false,
                        externalLink: true,
                        display: hasOneOfRoles(this.account.resourceRoleRights, ['settingViewExt', 'settingAddExt']),
                    },
                    {
                        label: 'Unassigned Work',
                        to: '/settings#unassignedTab',
                        display:
                            isActive(this.company.extensions, UNASSIGNED) &&
                            hasRole(this.account.resourceRoleRights, 'manageUnassignedRows'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Timesheets',
                        to: '/settings#features',
                        display: hasRole(this.account.resourceRoleRights, 'settingTs'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: `${this.keyWords.projectKeyWord} Custom Fields`,
                        to: '/settings#customFieldsProject',
                        display: hasRole(this.account.resourceRoleRights, 'settingCf'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord} Custom Fields`,
                        to: '/settings#customFieldsResource',
                        display: hasRole(this.account.resourceRoleRights, 'settingCf'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                ],
            },
            {
                label: 'Account Setup',
                defaultVisibility: true,
                display:
                    isSubscribedOrOnTrial &&
                    hasOneOfRoles(this.account.resourceRoleRights, [
                        'manageReports',
                        'manageEventSet',
                        'manageAllVacations',
                        'settingProjectMs',
                        'settingPhases',
                        'settingHoliday',
                        'settingToil',
                        'settingBookCat',
                        'settingFixedCostsCategories',
                        'settingBillingRate',
                        'settingTag',
                    ]),
                content: [
                    {
                        label: 'Calculations',
                        to: '/settings#calculations',
                        display: hasRole(this.account.resourceRoleRights, 'manageReports'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Events',
                        to: '/settings#events',
                        display: hasRole(this.account.resourceRoleRights, 'manageEventSet'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Vacation & Sick Days',
                        to: '/settings#vacations',
                        display: hasRole(this.account.resourceRoleRights, 'manageAllVacations'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Milestones',
                        to: '/settings#milestones',
                        display: hasRole(this.account.resourceRoleRights, 'settingProjectMs'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: `${this.keyWords.projectKeyWord} Phases`,
                        to: '/settings#phases',
                        display: hasRole(this.account.resourceRoleRights, 'settingPhases'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Public Holidays',
                        to: '/settings/holidays',
                        display: hasRole(this.account.resourceRoleRights, 'settingHoliday'),
                    },
                    {
                        label: 'Time off in lieu (TOIL)',
                        to: '/settings/toil',
                        display: hasRole(this.account.resourceRoleRights, 'settingToil'),
                    },
                    {
                        label: 'Booking Categories',
                        to: '/settings/categoryGroups',
                        display: hasRole(this.account.resourceRoleRights, 'settingBookCat'),
                    },
                    {
                        label: 'Fixed Costs Categories',
                        to: '/settings/costsCategories',
                        display: hasRole(this.account.resourceRoleRights, 'settingFixedCostsCategories'),
                    },
                    {
                        label: 'Billing Rate Card',
                        to: '/settings#billingRateCard',
                        display: hasRole(this.account.resourceRoleRights, 'settingBillingRate'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: `${this.keyWords.projectKeyWord} Tags`,
                        to: '/settings#customTagsProject',
                        display: hasRole(this.account.resourceRoleRights, 'settingTag'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord} Tags`,
                        to: '/settings#customTagsResource',
                        display: hasRole(this.account.resourceRoleRights, 'settingTag'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                ],
            },
            {
                label: 'Custom Settings',
                defaultVisibility: true,
                display:
                    isSubscribedOrOnTrial &&
                    hasOneOfRoles(this.account.resourceRoleRights, ['settingApi', 'settingGridCus', 'settingNotes']),
                content: [
                    {
                        label: 'Customization',
                        to: '/settings#custom',
                        display: hasRole(this.account.resourceRoleRights, 'settingGridCus'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Notifications',
                        to: '/settings#notifications',
                        display: hasRole(this.account.resourceRoleRights, 'settingGridCus'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                ],
            },
            {
                label: 'System Settings',
                defaultVisibility: true,
                display:
                    isSubscribedOrOnTrial &&
                    hasOneOfRoles(this.account.resourceRoleRights, [
                        'settingRoles',
                        'settingAccount',
                        'settingAuth',
                        'settingResourcePermissions',
                    ]),
                content: [
                    {
                        label: 'Account Settings',
                        to: '/settings/account',
                        display: hasRole(this.account.resourceRoleRights, 'settingAccount'),
                    },
                    {
                        label: 'Authentication',
                        to: '/settings/authentication',
                        display: hasRole(this.account.resourceRoleRights, 'settingAuth'),
                    },
                    {
                        label: 'Roles',
                        to: '/settings#roles',
                        display: hasRole(this.account.resourceRoleRights, 'settingRoles'),
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Permissions',
                        to: '/settings/permissions',
                        display: hasRole(this.account.resourceRoleRights, 'settingResourcePermissions'),
                    },
                ],
            },
            {
                label: 'Auditing',
                defaultVisibility: true,
                display: hasRole(this.account.resourceRoleRights, 'canViewAuditLog'),
                content: [
                    {
                        label: 'Audit Log',
                        to: '/settings/auditLog',
                    },
                ],
            },
            {
                label: 'Billing',
                defaultVisibility: true,
                display: hasRole(this.account.resourceRoleRights, 'settingBilling'),
                content: [
                    {
                        label: 'Billing History',
                        to: '/settings#billingHistory',
                        display: isSubscribedOrOnTrial && TYPE_BRAINTREE === this.billingInfo.paymentType,
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Billing Details',
                        to: '/settings#billingDetails',
                        display: isSubscribedOrOnTrial,
                        openInNewTab: false,
                        externalLink: true,
                    },
                    {
                        label: 'Billing',
                        to: '/settings/billing',
                    },
                ],
            },
        ];
    }
}

export default new SettingsMenu();
