/* eslint-env browser */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { includes, keys, head } from 'lodash';
import { useAppKeyWords, useWindowDimension } from 'hooks';
import { hideContextMenu } from 'actions/contextMenuActions';
import NewItemContextMenu from './addNewItem/addNewItem';
import GroupContextMenu from './group';
import ItemContextMenu from './item';
import EditBooking from './editBooking';
import Schedule from './schedule';
import EditResourceParentItems from './editResourceParentItems';
import MenuRow from './menuRow';
import CustomColumns from './customColumns';
import { SavedScheduleContextMenu } from './savedSchedule';
import { EditDeadlineContextMenu } from './editDeadlineContextMenu';

const COMPONENTS = {
    GROUP_CONTEXT_MENU: GroupContextMenu,
    ITEM_CONTEXT_MENU: ItemContextMenu,
    ADD_NEW_ITEM_CONTEXT_MENU: NewItemContextMenu,
    EDIT_BOOKING_CONTEXT_MENU: EditBooking,
    SCHEDULE_CONTEXT_MENU: Schedule,
    SAVED_SCHEDULE_CONTEXT_MENU: SavedScheduleContextMenu,
    EDIT_RESOURCE_PARENT_ITEMS_CONTEXT_MENU: EditResourceParentItems,
    EDIT_DEADLINE_CONTEXT_MENU: EditDeadlineContextMenu,
    MENU_ROW: MenuRow,
    CUSTOM_COLUMNS: CustomColumns,
};

const BaseContextMenu = props => {
    const { top, left, ...restOfProps } = props;

    const keywords = useAppKeyWords();
    const windowDimension = useWindowDimension();
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const dispatch = useDispatch();
    const { contextMenuType, additionalProps } = useSelector(state => state.contextMenu);

    const contextMenuNotExist = !contextMenuType || !includes(keys(COMPONENTS), contextMenuType);

    const getIsDatePickerOpen = () => !!head(document.getElementsByClassName('MuiPickersBasePicker-container'));

    const hideContextMenuAction = useCallback(() => {
        const isDatePickerOpen = getIsDatePickerOpen();

        !isDatePickerOpen && dispatch(hideContextMenu());
    }, [dispatch]);

    const handler = useCallback(
        event => {
            if (top && left) {
                return;
            }
            //prevent changing position on right click on context menu content or on date picker
            const isDatePickerOpen = getIsDatePickerOpen();
            const popover = head(document.getElementsByClassName('MuiPopover-root'));
            if (popover && popover.contains(event.target) && 'contextmenu' === event.type) {
                event.preventDefault();
            }
            if (!popover || (!popover.contains(event.target) && !isDatePickerOpen)) {
                setPosition({
                    top: event.clientY,
                    left: event.clientX,
                });
            }
        },
        [top, left]
    );

    useEffect(() => {
        if (top && left) {
            setPosition({ top, left });
        }
    }, [top, left]);

    useEffect(() => {
        document.addEventListener('click', handler, true);
        document.addEventListener('contextmenu', handler, true);

        return () => {
            document.removeEventListener('click', handler, true);
            document.removeEventListener('contextmenu', handler, true);
        };
    }, [handler]);

    if (contextMenuNotExist) {
        return null;
    }
    const ContextMenuComponent = COMPONENTS[contextMenuType];

    return (
        <div id="context-menu-scheduler">
            <ContextMenuComponent
                close={hideContextMenuAction}
                {...restOfProps}
                {...windowDimension}
                {...position}
                {...keywords}
                {...additionalProps}
            />
        </div>
    );
};

BaseContextMenu.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
};

export default BaseContextMenu;
