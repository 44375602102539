import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeAccountCompany } from 'actions/accountActions';
import PageTemplate from '../../shared/pageTemplate';
import { getAccount } from 'selectors/account';
import { OTPVerificationModalContent } from '../otpVerificationModalContent';
import { getCompanyFromSubdomain } from 'modules/auth/shared/utils';
import { useLocation } from 'react-router-dom';
import { parse } from 'services/queryString';

const LoginOTP = ({ match }) => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const account = useSelector(getAccount);
    const accountCompanies = useSelector(state => state.companyReducer.companies);
    const { authError, email, mfa } = account ?? {};

    const getCompanyId = () =>
        match?.params?.companyId ?? getCompanyFromSubdomain(accountCompanies)?._id ?? accountCompanies[0]?._id;

    const submitWithOTP = verificationData => {
        const { path } = parse(search);

        dispatch(
            changeAccountCompany.request({
                companyId: getCompanyId(),
                path: path || '/',
                ...verificationData,
            })
        );
    };

    return (
        <PageTemplate type="login">
            <OTPVerificationModalContent
                error={authError || mfa.error}
                errorCode={mfa.errorCode}
                onSubmit={submitWithOTP}
                actionType="LOGIN"
                email={email}
            />
        </PageTemplate>
    );
};

export default LoginOTP;
