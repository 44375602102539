import React from 'react';
import PropTypes from 'prop-types';
import { Button, UncontrolledCollapse, Alert } from 'reactstrap';

const HelpButton = ({ id, children }) => (
    <div>
        <UncontrolledCollapse toggler={`#toggler${id}`}>
            <Alert color="info">{children ? children : null}</Alert>
        </UncontrolledCollapse>
        <Button color="link sm float-right m-0 p-0" id={`toggler${id}`}>
            help?
        </Button>
    </div>
);

HelpButton.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

HelpButton.defaultProps = {
    id: Math.floor(Math.random() * 10) + 1,
};

export default HelpButton;
