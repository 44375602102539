import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { hasOneOfRoles } from 'utils/rightsUtil';
import { subDays, format } from 'date-fns';
import { store } from '../../../../store';

function isProjectRowAllowed(args, resourceRoleRights) {
    const isProjectParentRow = args.row.tags.parent && args.row.tags.isProjectRow;

    const hasProjectParentRowRights =
        isProjectParentRow &&
        hasOneOfRoles(resourceRoleRights, ['settingProjectMs', 'settingProjectPhases', 'settingProjectDates']) &&
        args.row.tags.project?.hasRightsToProject;

    return {
        isProjectParentRow,
        hasProjectParentRowRights,
    };
}

export default (scheduleRights, canRequestVacations, canRequestResources, resourceRoleRights) => args => {
    const reduxState = store.getState();
    const scaleHour = reduxState?.account?.preferences?.grid?.defaultScale === 'CellDuration';

    if (args.right?.html && !scaleHour) {
        const oneDayBefore = subDays(new Date(args.right.html), 1);
        args.right.html = format(oneDayBefore, 'MMMM d, yyyy');
    }

    const rowTags = args.row.data.tags;
    const scheduler = args.row.calendar;
    const { schedule: hasOnlyScheduleRights, requestVacation, resource: resourcePermission } = scheduleRights(
        scheduler.mode === PARENT.value,
        rowTags
    );
    const hasSchedulerRights =
        ((canRequestVacations && !rowTags.unassignedRow) || canRequestResources || hasOnlyScheduleRights) &&
        !rowTags.unassignedParentRow;
    const { isProjectParentRow, hasProjectParentRowRights } = isProjectRowAllowed(args, resourceRoleRights);

    scheduler.multiselect.clear();

    if ((!hasSchedulerRights && !rowTags.unassignedParentRow) || (isProjectParentRow && !hasProjectParentRowRights)) {
        scheduler.message(
            '<i class="fa fa-warning">&nbsp;</i> You are not able to schedule on this row. Please contact your account owner and ask for rights.',
            {
                rawHtml: true,
            }
        );
    }

    if (
        ((hasSchedulerRights && !isProjectParentRow) || (isProjectParentRow && hasProjectParentRowRights)) &&
        !rowTags.unassignedParentRow &&
        !(args.row.tags.parent && args.row.tags.isResourceRow) &&
        !args.row.tags.menuRow
    ) {
        args.cssClass = 'selected-range-dots';
    }

    const validRow =
        !args.row.tags.parent &&
        !rowTags.unassignedParentRow &&
        !(args.row.tags.parent && args.row.tags.isResourceRow) &&
        !args.row.tags.menuRow &&
        !args.row.tags.isRemoveProjectsRow;

    args.row.tags.hasProjectParentRowRights = hasProjectParentRowRights;
    args.row.tags.hasRights = validRow && hasSchedulerRights; //Has Rights to Schedule or Request of selection
    args.row.tags.hasOnlyScheduleRights = validRow && hasOnlyScheduleRights; //Has Rights to Schedule of selection
    args.row.tags.hasRequestVacationRights = validRow && requestVacation;
    args.row.tags.hasResourceRights = validRow && resourcePermission; // Is resource in permissions

    return hasSchedulerRights;
};
