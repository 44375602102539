import { filter, find, includes, map, values } from 'lodash';
import { Description, Person } from '@material-ui/icons';
import { STATUS_NON_BOOKABLE, STATUS_PARKED, statuses } from 'enums/resourceEnum';
import { statuses as projectStatuses } from 'enums/projectEnum';
import { MY_PROJECTS, MY_MANAGED_PROJECTS } from 'enums/criteriaEnum';
import { replaceKeywords } from 'utils/keywordsUtil';

export const PROJECT = {
    display: ({ projectKeyWord }) => projectKeyWord,
    name: 'project',
    value: 'PROJECT',
    propItem: 'projects',
    propGroupItem: 'projectGroups',
    idProperty: '_id',
    icon: Description,
    itemFilter: (projects, selectedItems) =>
        filter(projects, item => selectedItems && !includes(map(selectedItems, '_id'), item._id)),
    groupFilter: (projectGroups, replaceKeyWordsMap, selectedGroups) => {
        const filteredGroups = filter(
            projectGroups,
            group =>
                selectedGroups &&
                !includes(map(selectedGroups, '_id'), group._id) &&
                !includes([MY_PROJECTS, MY_MANAGED_PROJECTS], group.criteria)
        );

        return map(filteredGroups, group => ({ ...group, name: replaceKeywords(replaceKeyWordsMap, group.name) }));
    },
    additionalGroupProps: props => ({
        fields: { label: 'name', value: '_id' },
        title: `${props && props.projectKeyWord} Groups`,
        subTitle: `Add ${props && props.projectKeyWord} Groups`,
        groupSubItem: 'projects',
    }),
    additionalProps: props => ({
        fields: {
            label: 'name',
            value: '_id',
            additional: 'projectCode',
            wrapWithBrackets: true,
            subText: project => {
                const status = `status_${project.status}`.toUpperCase();
                return 'EVENT' === project.type ? 'Event' : projectStatuses[status]?.display;
            },
        },
        filterTitle: `Filter by ${props && props.projectKeyWord} or group name`,
        title: `Single ${props && props.projectPluralKeyWord}`,
        icon: Person,
        subTitle: `Add ${props && props.projectPluralKeyWord}`,
    }),
};

export const RESOURCE = {
    display: ({ resourceKeyWord }) => resourceKeyWord,
    name: 'resource',
    value: 'RESOURCE',
    propItem: 'resources',
    propGroupItem: 'resourceGroups',
    idProperty: '_id',
    icon: Person,
    itemFilter: (resources, selectedItems) =>
        filter(
            resources,
            item =>
                !includes([STATUS_NON_BOOKABLE.value, STATUS_PARKED.value], item.status) &&
                selectedItems &&
                !includes(map(selectedItems, '_id'), item._id)
        ),
    groupFilter: (resourceGroups, replaceKeyWordsMap, selectedGroups) => {
        const filteredResourceGroups = filter(
            resourceGroups,
            group => selectedGroups && !includes(map(selectedGroups, '_id'), group._id)
        );

        return map(filteredResourceGroups, group => ({
            ...group,
            name: replaceKeywords(replaceKeyWordsMap, group.name),
        }));
    },
    additionalGroupProps: props => ({
        fields: { label: 'name', value: '_id' },
        title: `${props && props.resourceKeyWord} Groups`,
        subTitle: `Add ${props && props.resourceKeyWord} Groups`,
        groupSubItem: 'resources',
    }),
    additionalProps: props => ({
        fields: {
            label: 'name',
            value: '_id',
            additional: '',
            subText: resource => ('UNASSIGNED' === resource.type ? 'Unassigned' : statuses[resource.status]?.display),
        },
        filterTitle: `Filter by ${props && props.resourceKeyWord} or group name`,
        title: `Single ${props && props.resourcePluralKeyWord}`,
        subTitle: `Add ${props && props.resourcePluralKeyWord}`,
    }),
};

export const itemTypes = {
    RESOURCE,
    PROJECT,
};

export const itemTypesArray = values(itemTypes);

export const findItemTypeByName = name => find(itemTypes, itemType => name === itemType.name) || {};
