import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AlertItem } from './styles';
import Loader from 'shared/loader';

class Alert extends PureComponent {
    static propTypes = {
        onDismiss: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        message: PropTypes.string,
        html: PropTypes.bool,
        showLoader: PropTypes.bool,
    };

    static defaultProps = {
        message: '',
    };

    constructor(props) {
        super(props);
        this.timeout = undefined;
    }

    componentDidMount() {
        if (this.props.timeout) {
            this.timeout = setTimeout(this.props.onDismiss, this.props.timeout);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {
        const { type, onDismiss, html, message, showLoader } = this.props;
        const finalMessage = html ? <span dangerouslySetInnerHTML={{ __html: message }} /> : message;

        return (
            <AlertItem color={type} toggle={onDismiss}>
                {showLoader && <Loader className="mr-1" size={30} />} {finalMessage}
            </AlertItem>
        );
    }
}

export default Alert;
