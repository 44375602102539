import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DayInterval } from './DayInterval';
import { makeStyles } from '@material-ui/core';
import { selectWeekDaysSetting } from 'selectors/company';

const DEFAULT_DAY_TIME_INTERVAL = {
    start: 480,
    end: 960,
};

export const daysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative',
    },
    disabled: {
        position: 'absolute',
        backgroundColor: theme.palette.white,
        zIndex: 1,
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        opacity: 0.7,
        display: 'block',
        padding: 0,
    },
}));

export const getEndTIme = time => {
    if (time === 24 * 60 - 45) return 45;
    if (time === 24 * 60 - 30) return 30;
    if (time === 24 * 60 - 15) return 15;
    if (time === 24 * 60) return 0;
    return 60;
};

export const CustomAvailability = React.memo(props => {
    const classes = useStyles();
    const { hoursAmount, disabled, onChange, errors, data } = props;
    const weekDays = useSelector(selectWeekDaysSetting);

    const addInterval = useCallback(
        name => {
            onChange &&
                onChange(
                    data.map(day => {
                        if (day.name !== name) return day;
                        const lastEnd = day.intervals?.at(-1)?.end;
                        const newLastEnd = lastEnd % 15 === 0 ? lastEnd : lastEnd + (15 - (lastEnd % 15));
                        const endTime = getEndTIme(newLastEnd);

                        const intervals = [
                            ...day.intervals,
                            lastEnd
                                ? {
                                      start: newLastEnd,
                                      end: newLastEnd + endTime,
                                  }
                                : {
                                      ...DEFAULT_DAY_TIME_INTERVAL,
                                  },
                        ];

                        return {
                            ...day,
                            intervals: intervals,
                            minutes: intervals.reduce((acc, interval) => {
                                return acc + (interval.end - interval.start);
                            }, 0),
                        };
                    })
                );
        },
        [onChange, data]
    );

    const removeInterval = useCallback(
        (name, index) => {
            onChange &&
                onChange(
                    data.map(day => {
                        if (day.name !== name) return day;
                        const intervals = day.intervals.filter((int, idx) => idx !== index);
                        const minutes = intervals.reduce((acc, interval) => {
                            return acc + (interval.end - interval.start);
                        }, 0);
                        return {
                            ...day,
                            ...(intervals.length === 0 && { workDay: false }),
                            intervals,
                            legacy: minutes % 15 !== 0,
                            minutes: minutes,
                        };
                    })
                );
        },
        [onChange, data]
    );

    const changeInterval = useCallback(
        (name, index, interval) => {
            onChange &&
                onChange(
                    data.map(day => {
                        if (day.name !== name) return day;

                        const intervals = day.intervals.map((int, idx) => {
                            if (idx !== index) return int;
                            return {
                                ...day.intervals[idx],
                                ...interval,
                            };
                        });
                        const minutes = intervals.reduce((acc, interval) => {
                            return acc + (interval.end - interval.start);
                        }, 0);
                        return {
                            ...day,
                            intervals: intervals,
                            legacy: minutes % 15 !== 0,
                            minutes: minutes,
                        };
                    })
                );
        },
        [onChange, data]
    );

    const changeWorkDay = useCallback(
        (name, checked) => {
            onChange &&
                onChange(
                    data.map(day => {
                        if (day.name !== name) return day;

                        const intervals = weekDays[name].intervals?.length
                            ? [...weekDays[name].intervals]
                            : [
                                  {
                                      ...DEFAULT_DAY_TIME_INTERVAL,
                                  },
                              ];

                        const minutes = checked
                            ? intervals.reduce((acc, interval) => {
                                  return acc + (interval.end - interval.start);
                              }, 0)
                            : 0;

                        return {
                            ...day,
                            intervals: intervals,
                            legacy: minutes % 15 !== 0,
                            workDay: checked,
                            minutes,
                        };
                    })
                );
        },
        [onChange, data, weekDays]
    );

    return (
        <div className={classes.container}>
            {disabled && <div className={classes.disabled} />}
            {data?.map((day, idx) => {
                const overlapError = Array.isArray(errors?.[idx]?.overlap)
                    ? errors[idx].overlap[0]
                    : errors?.[idx]?.overlap;

                const intervalsError = errors?.[idx]?.intervals;

                return (
                    <DayInterval
                        key={day.name}
                        {...day}
                        addInterval={addInterval}
                        removeInterval={removeInterval}
                        changeInterval={changeInterval}
                        changeWorkDay={changeWorkDay}
                        hoursAmount={hoursAmount}
                        intervalsError={intervalsError}
                        overlapError={overlapError}
                    />
                );
            })}
        </div>
    );
});

CustomAvailability.propTypes = {
    hoursAmount: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    errors: PropTypes.object,
};

CustomAvailability.defaultProps = {
    hoursAmount: true,
    disabled: false,
};
