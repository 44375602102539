import { RESOURCE_RATE, UNASSIGNED } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'resourceSpecificExternalRate',
        display: 'Resource External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        extensions: [RESOURCE_RATE],
        heatMapSupport: false,
    },
    {
        id: 'resourceSpecificInternalRate',
        display: 'Resource Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificExternalRate',
        display: 'Unassigned External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        extensions: [RESOURCE_RATE, UNASSIGNED],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'unassignedSpecificInternalRate',
        display: 'Unassigned Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE, UNASSIGNED],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificExternalRate',
        display: 'Project External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectSpecificInternalRate',
        display: 'Project Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificExternalRate',
        display: 'Event External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'eventSpecificInternalRate',
        display: 'Event Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingExternalRate',
        display: 'Booking External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingInternalRate',
        display: 'Booking Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'activeExternalRate',
        display: 'Active External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'activeInternalRate',
        display: 'Active Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        extensions: [RESOURCE_RATE],
        heatMapSupport: false,
    },
    {
        id: 'resourceExternalRate',
        display: 'External Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'external rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceInternalRate',
        display: 'Internal Rate',
        multiItemsDisplay: 'Rates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        displayRateType: 'internal rate',
        extensions: [RESOURCE_RATE],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
];
