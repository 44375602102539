import { PM } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {Array}
 */
export default () => [
    {
        id: 'projectCustomer',
        display: 'Customer',
        multiItemsDisplay: 'Customers',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectTag',
        display: 'Project/Event Tag',
        multiItemsDisplay: 'Tags',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectStatus',
        display: 'Project Status',
        multiItemsDisplay: 'Statuses',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectManager',
        display: 'Project Manager',
        multiItemsDisplay: 'Project Managers',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        extensions: [PM],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectEndDate',
        display: 'Project/Event End Date',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectStartDate',
        display: 'Project/Event Start Date',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectNote',
        display: 'Project/Event Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectCode',
        display: 'Project/Event Code',
        multiItemsDisplay: 'Codes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectGroups',
        display: 'Project/Event Groups',
        multiItemsDisplay: 'Groups',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projectName',
        display: 'Project Name',
        multiItemsDisplay: 'Projects',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
    },
    {
        id: 'projectEventName',
        display: 'Project/Event Name',
        multiItemsDisplay: 'Projects/Events',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
    },
    {
        id: 'projMilestoneName',
        display: 'Project Milestone Name',
        multiItemsDisplay: 'Project Milestone Names',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
    },
    {
        id: 'projMilestoneDate',
        display: 'Project Milestone Date',
        multiItemsDisplay: 'Project Milestone Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projPhaseName',
        display: 'Project Phase Name',
        multiItemsDisplay: 'Project Phase Names',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
    },
    {
        id: 'projPhaseStartDate',
        display: 'Project Phase Start Date',
        multiItemsDisplay: 'Project Phase Start Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'projPhaseEndDate',
        display: 'Project Phase End Date',
        multiItemsDisplay: 'Project Phase End Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    }
];
