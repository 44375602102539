import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes, map } from 'lodash';
import * as itemIdTypes from 'enums/groupTypeEnum';
import { makeGetResourceGroups } from 'selectors/resourceGroup';
import { makeGetProjectGroups } from 'selectors/projectGroup';
import { RESOURCE, PROJECT } from 'modules/scheduler/enums/builderTypeEnum';
import { SYSTEM } from 'modules/scheduler/enums/groupTypeEnum';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { mapStateToRequest } from 'modules/scheduler/modals/builder/utils';
import { resetScheduler, resetBuilder, applyBuilderData, initScheduler } from 'actions/schedulerActions';
import { updateResourceGroup, createResourceGroup, deleteResourceGroup } from 'actions/resourceGroupActions';
import { createProjectGroup, updateProjectGroup, deleteProjectGroup } from 'actions/projectGroupActions';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import { useIsExtensionInstalled } from '../../../hooks';
import { SMART_GROUPS, SMART_SCHEDULES } from '../../../enums/extensionShortIdEnum';
import { useParamGroupId } from '../../../hooks/useParamGroupId';
import { haveSmartFiltersChanged } from '../utils/builderFiltersUtil';

const shouldSaveGroupAsSmart = ({
    isSystem,
    isSchedule,
    isSmartGroupExtensionInstalled,
    haveRowOrBookingFiltersChanged,
    haveSmartFiltersChanged,
    isSmartSchedulesExtensionInstalled,
}) => {
    if (isSystem && (haveRowOrBookingFiltersChanged || haveSmartFiltersChanged)) {
        return isSchedule ? isSmartSchedulesExtensionInstalled : isSmartGroupExtensionInstalled;
    }

    return false;
};

const convertToSmartOnSystemGroupSaveAs = ({
    isSmart,
    isSystem,
    isSchedule,
    isSmartGroupExtensionInstalled,
    haveRowOrBookingFiltersChanged,
    haveSmartFiltersChanged,
    isSmartSchedulesExtensionInstalled,
}) => {
    if (
        shouldSaveGroupAsSmart({
            isSystem,
            isSchedule,
            isSmartGroupExtensionInstalled,
            haveRowOrBookingFiltersChanged,
            haveSmartFiltersChanged,
            isSmartSchedulesExtensionInstalled,
        })
    ) {
        return true;
    }

    return isSmart;
};

export const useGroupActions = callback => {
    const dispatch = useDispatch();
    const projectGroupSelector = useMemo(() => makeGetProjectGroups('TYPE_SYSTEM'), []);
    const resourceGroupSelector = useMemo(() => makeGetResourceGroups('TYPE_SMART_BASE'), []);
    const projectSystemGroups = useSelector(projectGroupSelector);
    const resourceSystemGroups = useSelector(resourceGroupSelector);
    const isSmartGroupExtensionInstalled = useIsExtensionInstalled(SMART_GROUPS);
    const isSmartSchedulesExtensionInstalled = useIsExtensionInstalled(SMART_SCHEDULES);
    const paramGroupId = useParamGroupId();

    const clearData = () => {
        dispatch(resetScheduler.request());
    };

    const clearBuilder = () => {
        dispatch(resetBuilder.request());
    };

    const create = (state, parentGroupId, haveRowOrBookingFiltersChanged) => {
        const redirectAfterCreation = true;
        if (includes([itemIdTypes.GROUP, itemIdTypes.SCHEDULER_TEMPLATE], state.groupType)) {
            const isSchedule = state.groupType === itemIdTypes.SCHEDULER_TEMPLATE;
            const isSystem = state.group?.groupType === SYSTEM;

            const shouldBeSmart = convertToSmartOnSystemGroupSaveAs({
                isSmart: state.isSmart,
                isSchedule,
                isSystem,
                haveRowOrBookingFiltersChanged,
                haveSmartFiltersChanged,
                isSmartSchedulesExtensionInstalled,
                isSmartGroupExtensionInstalled,
            });

            state.itemType.name === PROJECT.name &&
                dispatch(
                    createProjectGroup.request(
                        mapStateToRequest(
                            { ...state, isSmart: shouldBeSmart },
                            parentGroupId,
                            map(projectSystemGroups, '_id')
                        ),
                        redirectAfterCreation
                    )
                );
            state.itemType.name === RESOURCE.name &&
                dispatch(
                    createResourceGroup.request(
                        mapStateToRequest(
                            { ...state, isSmart: shouldBeSmart },
                            parentGroupId,
                            map(resourceSystemGroups, '_id')
                        ),
                        redirectAfterCreation
                    )
                );
        }
        clearData();
        callback && callback();
    };

    const update = (state, parentGroupId) => {
        if (state.group._id && SYSTEM !== state.group.groupType) {
            if (state.itemType.name === PROJECT.name) {
                dispatch(
                    updateProjectGroup.request({
                        id: state.group._id,
                        data: mapStateToRequest(state, parentGroupId, map(projectSystemGroups, '_id')),
                        callback: () => {
                            dispatch(initScheduler());
                        },
                        redirect: true,
                    })
                );
            }

            if (state.itemType.name === RESOURCE.name) {
                dispatch(
                    updateResourceGroup.request({
                        id: state.group._id,
                        data: mapStateToRequest(state, parentGroupId, map(resourceSystemGroups, '_id')),
                        callback: () => {
                            dispatch(initScheduler());
                        },
                        redirect: true,
                    })
                );
            }
        }

        callback && callback();
    };

    const apply = state => {
        if (state.group._id !== paramGroupId) {
            let url;
            if (state.itemType.name === PROJECT.name) {
                url = `/project/group/${state.group._id}`;
            } else {
                url = `/resource/group/${state.group._id}`;
            }

            // main#/project/group/62f6113ece9159675c6c96d1
            // main#/resource/group/63034ea9bed13c145b133190
            window.location.hash = url;
            dispatch(changeActiveLinkFromLocation());
        }

        dispatch(
            applyBuilderData.request({
                group: state.group,
                filters: state.filters,
                dates: state.dates,
                filterRelation: state.filterRelation,
            })
        );

        clearBuilder();
        callback && callback();
    };

    const deleteGroup = state => {
        if (SYSTEM === state.group.groupType) {
            return;
        }

        if (state.itemType.name === SCHEDULER_TEMPLATE) {
            const itemTypeFromQueryParams = state.group?.queryParams?.itemType;
            itemTypeFromQueryParams === 'project' && dispatch(deleteProjectGroup.request(state.group._id));
            itemTypeFromQueryParams === 'resource' && dispatch(deleteResourceGroup.request(state.group._id));
        } else {
            state.itemType.name === PROJECT.name && dispatch(deleteProjectGroup.request(state.group._id));
            state.itemType.name === RESOURCE.name && dispatch(deleteResourceGroup.request(state.group._id));
        }

        if (state.group._id === paramGroupId) {
            clearData();
        }
    };

    return { create, update, apply, deleteGroup };
};
