import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppKeyWords, useIsExtensionInstalled } from '../../../../../hooks';
import SearchList from '../../searchList/searchList';
import { useSchedulerData } from '../../../hooks';
import { roles, TYPE_UNASSIGNED } from '../../../../../enums/resourceEnum';
import ListItem from '../listItem';
import { useSchedulerResourcesListContext } from './schedulerResourcesListProvider';
import { useMenuResourcePermissions } from '../../../hooks/useMenuPermissions';
import SuccessButton from 'shared/buttons/success';
import ResourceThumb from '../../resourceThumb';
import { UNASSIGNED } from '../../../../../enums/extensionShortIdEnum';

const ResourceListItem = React.memo(props => {
    const { item } = props;

    return (
        <ListItem
            disableGutters
            secondaryText={item.type === TYPE_UNASSIGNED.value ? TYPE_UNASSIGNED.display : roles[item.role].display}
            divider
            icon={<ResourceThumb small resource={item} />}
            avatarSize={30}
            {...props}
        />
    );
});

const SchedulerResourcesList = props => {
    const {
        project,
        height,
        onSelect,
        externalFilterFunction,
        canRequestResources,
        noDataRefresh,
        slideRef,
        selectedIds,
        hideSelected,
        allPermitted
    } = props;

    const { hasCreateResourceRights } = useMenuResourcePermissions();
    const isUnassignedExtensionInstalled = useIsExtensionInstalled(UNASSIGNED);
    const {
        filterButtons,
        filterButtonApplied,
        setFilterButtonApplied,
        isUnassignedFilterApplied,
        resourceName,
        setResourceName,
    } = useSchedulerResourcesListContext();

    const { resourceKeyWord } = useAppKeyWords();

    const { resources } = useSchedulerData(null, project, {
        canRequestResources,
        filterResourcesFunction: externalFilterFunction,
        noDataRefresh,
        excludeUnassigned: false,
        allPermittedResources: allPermitted
    });

    const handleClick = useCallback(() => {
        slideRef.current?.triggerNext();
    }, [slideRef]);

    const btn = useMemo(() => {
        if (!hasCreateResourceRights) {
            return null;
        }

        if (isUnassignedFilterApplied) {
            return {
                text: isUnassignedExtensionInstalled
                    ? `Add new unassigned ${resourceName && ` "${resourceName}"`}`
                    : `You need to install Unassigned Work extension`,
                disabled: !isUnassignedExtensionInstalled,
            };
        }

        return {
            text: `Add new ${resourceKeyWord}${resourceName && ` "${resourceName}"`}`,
        };
    }, [
        hasCreateResourceRights,
        isUnassignedExtensionInstalled,
        isUnassignedFilterApplied,
        resourceName,
        resourceKeyWord,
    ]);

    return (
        <>
            <SearchList
                itemComponent={ResourceListItem}
                filtersButtons={filterButtons}
                filterButtonApplied={filterButtonApplied}
                onFilterButtonClicked={setFilterButtonApplied}
                height={height}
                emptyText="Sorry, there are no results matching your filter."
                data={resources}
                name="resource"
                rowHeight={60}
                onEmptySearch={setResourceName}
                onSelect={onSelect}
                fields={{ label: 'name', value: '_id', }}
                placeholder="Type to filter ..."
                selectedIds={selectedIds}
                hideSelected={hideSelected}
            />
            {btn ? (
                <SuccessButton
                    onClick={handleClick}
                    disabled={btn.disabled}
                    name={btn.text}
                    type="btn-secondary"
                    className="w-100"
                    data-cy="button--add-new-resource"
                />
            ) : null}
        </>
    );
};

SchedulerResourcesList.propTypes = {
    onSelect: PropTypes.func,
    height: PropTypes.number,
    canRequestResources: PropTypes.bool,
    allPermitted: PropTypes.bool,
    project: PropTypes.object,
    externalFilterFunction: PropTypes.func,
    noDataRefresh: PropTypes.bool,
    slideRef: PropTypes.object,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    hideSelected: PropTypes.bool,
};

SchedulerResourcesList.defaultProps = {
    selectedIds: [],
    hideSelected: false,
    allPermitted: false,
    onSelect: null,
    canRequestResources: false,
    height: 100,
    project: {},
    externalFilterFunction: null,
    noDataRefresh: false,
    slideRef: { current: undefined },
};

const memoized = React.memo(SchedulerResourcesList);
export { memoized as SchedulerResourcesList };
