import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { displayValue, isHeaderDisabled, displayRateCurrencyValues } from 'modules/report/utils/columnHelper';
import { Header } from './../styles';

const displayAggregation = true;

const calculateRate = (unit, avgDailyCapacity) => rateValue =>
    unitTypes.DAYS.value === unit ? unitTypes[unit].ratesCalculation(rateValue, avgDailyCapacity) : rateValue;

const displayRate = (rowValue, defaultCurrency, unit, avgDailyCapacity) =>
    displayValue(
        rowValue,
        displayRateCurrencyValues(rowValue, defaultCurrency, displayAggregation, calculateRate(unit, avgDailyCapacity))
    );

/**
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 * @param {string} unit
 * @param {number} avgDailyCapacity
 *
 * @returns {*[]}
 */
export const columns = (columnsToLoad, defaultCurrency, unit, avgDailyCapacity) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'resourceSpecificExternalRate',
        sortable: false,
        accessor: row => displayRate(row.resourceSpecificExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'resourceSpecificExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'resourceSpecificInternalRate',
        sortable: false,
        accessor: row => displayRate(row.resourceSpecificInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'resourceSpecificInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'unassignedSpecificExternalRate',
        sortable: false,
        accessor: row => displayRate(row.unassignedSpecificExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'unassignedSpecificExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'unassignedSpecificInternalRate',
        sortable: false,
        accessor: row => displayRate(row.unassignedSpecificInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'unassignedSpecificInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalRate',
        sortable: false,
        accessor: row => displayRate(row.projectSpecificExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'projectSpecificExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalRate',
        sortable: false,
        accessor: row => displayRate(row.projectSpecificInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'projectSpecificInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalRate',
        sortable: false,
        accessor: row => displayRate(row.eventSpecificExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'eventSpecificExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalRate',
        sortable: false,
        accessor: row => displayRate(row.eventSpecificInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'eventSpecificInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'bookingExternalRate',
        sortable: false,
        accessor: row => displayRate(row.bookingExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'bookingExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'bookingInternalRate',
        sortable: false,
        accessor: row => displayRate(row.bookingInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'bookingInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Active External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'activeExternalRate',
        sortable: false,
        accessor: row => displayRate(row.activeExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'activeExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Active Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'activeInternalRate',
        sortable: false,
        accessor: row => displayRate(row.activeInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'activeInternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        External Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'resourceExternalRate',
        sortable: false,
        accessor: row => displayRate(row.resourceExternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'resourceExternalRate'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Internal Rate
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'resourceInternalRate',
        sortable: false,
        accessor: row => displayRate(row.resourceInternalRate, defaultCurrency, unit, avgDailyCapacity),
        show: contains(columnsToLoad, 'resourceInternalRate'),
    },
];
