import moment from 'moment';
import moize from 'moize';
import { filter, chain, contains, find } from 'underscore';

export const getResourceHolidays = moize(
    function getResourceHolidays(resource, calendars) {
        return resource
            ? chain(calendars)
                  .filter(calendar => contains(resource.calendarIds, calendar._id))
                  .pluck('holidays')
                  .flatten()
                  .value()
            : [];
    },
    { maxSize: 5 }
);

/**
 * @param {moment} date
 * @param {array}  holidays
 */
export const getHoliday = moize(
    function getHoliday(date, holidays) {
        return (
            holidays &&
            holidays.length &&
            find(holidays, holiday => {
                const format = holiday.repeat ? 'MM-DD' : 'YYYY-MM-DD';

                return moment(holiday.date).format(format) === date.format(format);
            })
        );
    },
    { maxSize: 5 }
);

/**
 * @param {moment} date
 * @param {array}  holidaysMap
 */
export function getHolidayFromMap(date, holidaysMap) {
    
    if (!holidaysMap) {
        return false;
    }

    const repeatFormat = 'MM-DD';
    const singleFormat = 'YYYY-MM-DD';

    return holidaysMap[date.format(repeatFormat)] || holidaysMap[date.format(singleFormat)];
}

/**
 * @param {moment} date
 * @param {array}  holidays
 */
export function isHoliday(date, holidays) {
    return !!getHoliday(date, holidays);
}

/**
 * @param {array} holidays
 */
export function getUniqHolidays(holidays) {
    let uniqHolidaysMap = {};

    for(let holiday of holidays) {
        const holidayDayMonthDate = moment(holiday.date, 'YYYY-MM-DD').format('MM-DD');

        if(!uniqHolidaysMap[holidayDayMonthDate]) {
            uniqHolidaysMap[holidayDayMonthDate] = []
            uniqHolidaysMap[holidayDayMonthDate].push(holiday);
            continue;
        }


        const holidayExists = find(uniqHolidaysMap[holidayDayMonthDate], uniqHoliday => {
            if ((uniqHoliday.repeat && !holiday.repeat) || (uniqHoliday.repeat && holiday.repeat)) {
                return (
                    moment(uniqHoliday.date, 'YYYY-MM-DD').format('MM-DD') ===
                    holidayDayMonthDate
                );
            }

            return uniqHoliday.date === holiday.date;
        });

        if (!holidayExists) {
            uniqHolidaysMap[holidayDayMonthDate].push(holiday);
        }
    }

    return Object.values(uniqHolidaysMap).reduce((acc, uniqHolidays) => {
        return acc.concat(uniqHolidays);
    }, [])
}

/**
 * @param {object} holiday
 * @param {moment.Moment} start
 * @param {moment.Moment} end
 *
 * @returns {boolean}
 */
export function isHolidayBetweenDates(holiday, start, end) {
    const date = moment.utc(holiday.date);
    if (holiday.repeat) {
        return (
            date.year(start.year()).isBetween(start, end, undefined, []) ||
            date.year(end.year()).isBetween(start, end, undefined, [])
        );
    }

    return date.isBetween(start, end, undefined, []);
}

/**
 * @param {object} holiday
 * @param {moment.Moment} start
 * @param {moment.Moment} end
 *
 * @returns {moment.Moment}
 */
export function getHolidayDateBetweenDates(holiday, start, end) {
    const date = moment.utc(holiday.date);
    if (holiday.repeat) {
        if (date.year(start.year()).isBetween(start, end, undefined, [])) {
            return date.year(start.year());
        }

        return date.year(end.year());
    }

    return date;
}

/**
 * @param {array} calendars
 * @param {array} calendarIds
 *
 * @returns {Array}
 */
export function getHolidaysByCalendarIds(calendars, calendarIds) {
    return chain(calendars)
        .filter(calendar => (calendarIds ? contains(calendarIds, calendar._id) : true))
        .pluck('holidays')
        .values()
        .flatten()
        .value();
}

/**
 * @param {array}         calendars
 * @param {array}         calendarIds
 * @param {moment.Moment} start
 * @param {moment.Moment} end
 *
 * @returns {Array}
 */
export function getFutureHolidaysByCalendarIds(calendars, calendarIds, start, end) {
    return filter(getHolidaysByCalendarIds(calendars, calendarIds), holiday =>
        getHolidayDateBetweenDates(holiday, start, end).isAfter(moment.utc())
    );
}
