import React from 'react';
import { Redirect } from 'react-router-dom';

const Home = ({ location }) => {
    if ('/settings' === location.pathname) {
        return (
            <Redirect
                to={{
                    pathname: '/settings/profile',
                }}
            />
        );
    }

    return null;
};

export default Home;
