import { ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { findCurrency, getCurrencies, getCurrencySymbol } from 'enums/currencyEnum';
import { useAppKeyWords, useHasRights, useIsExtensionEnabled } from 'hooks';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormText, TabPane } from 'reactstrap';
import { Field, FieldArray } from 'redux-form';
import { chooseField, dropdownField, dropdownFieldMaterial, materialInputGroupField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';
import { normalizeFloatNumber, normalizePositiveNumbers } from 'utils/formUtil';
import { getCategoryTemplates as getCategoryTemplatesActions } from '../../../../actions/categoryTemplateActions';
import { getCategoryGroups } from '../../../../selectors/categoryGroups';
import { getCategoryTemplates } from '../../../../selectors/categoryTemplate';
import { selectDefaultCompanyCurrency } from '../../../../selectors/company';
import CategoryGroupItem from '../../../../shared/categoryGroupItem';
import { SimpleCategoryTemplateItem } from '../../../../shared/categoryTemplateItem';
import { Layout } from '../../../../shared/components/layout';
import { getCategoryTemplatesByProject } from '../../../categoryGroups/categoryGroupUtil';
import InternalExternalProjectRates from './internalExternalProjectRates';
import { CATEGORY_GROUPS } from '../../../../enums/extensionShortIdEnum';
import { WrapWithTooltip } from '../../../scheduler/components/header/schedulerButtons/wrapWithTooltip';
import { Adjust } from '@material-ui/icons';
import { showMissingBookingCategoriesExtensionModal } from '../../../../actions/modalActions';
import classnames from 'classnames';
import { NumberFormat } from '../../../../shared/components/formats/NumberFormat';
import { sum } from '../../../../utils/numberUtils';

const rightsToCheck = [
    {
        rights: ['settingProjectRate'],
        rule: 'one',
        name: 'hasSettingProjectRate',
    },
    {
        rights: ['settingProjectBudget'],
        rule: 'one',
        name: 'hasSettingProjectBudget',
    },
];

const BillingAndRatesTab = ({ tabId, projectRates, categoryGroups, budgetCategories, onChange, ...rest }) => {
    const dispatch = useDispatch();
    const { projectKeyWord } = useAppKeyWords();
    const { hasSettingProjectRate, hasSettingProjectBudget } = useHasRights(rightsToCheck);

    useEffect(() => {
        dispatch(getCategoryTemplatesActions.request());
    }, [dispatch]);

    const nonBillableJSX = () => {
        return (
            <section className="form-fields border-bottom-0 border-top-0 mb-3">
                <Field
                    type="checkbox"
                    name="nonBillable"
                    label={`Non-billable ${projectKeyWord}`}
                    component={chooseField}
                />
                <FormText>
                    Checking the box above will mark the {projectKeyWord} as non-billable. This means that the spend on
                    this {projectKeyWord} will not be counted in reports if checked. You will however be able to
                    calculate the non-billable spend in reports using filters if you wish.
                </FormText>
            </section>
        );
    };

    if (!hasSettingProjectRate && !hasSettingProjectBudget) {
        return (
            <TabPane tabId={tabId}>
                <PermissionDenied />
            </TabPane>
        );
    }

    return (
        <TabPane tabId={tabId}>
            {hasSettingProjectRate ? nonBillableJSX() : null}

            {hasSettingProjectBudget ? (
                <section className="form-fields border-bottom-0 border-top-0 mb-3">
                    <BudgetCategories
                        onChange={onChange}
                        categoryGroups={categoryGroups}
                        budgetCategories={budgetCategories}
                    />
                </section>
            ) : null}

            {hasSettingProjectRate ? (
                <div>
                    <InternalExternalProjectRates projectRates={projectRates.external} type="external" {...rest} />
                    <InternalExternalProjectRates projectRates={projectRates.internal} type="internal" {...rest} />
                </div>
            ) : null}
        </TabPane>
    );
};

BillingAndRatesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rates: PropTypes.array,
    projectRates: PropTypes.object,
    selectedResources: PropTypes.array,
    projectKeyWord: PropTypes.string,
};

BillingAndRatesTab.defaultProps = {
    rates: [],
    selectedResources: [],
    projectRates: {},
    projectKeyWord: 'Project',
};

export default BillingAndRatesTab;

const BudgetCategories = memo(({ categoryGroups, budgetCategories, onChange }) => {
    const allCategoryGroups = useSelector(getCategoryGroups);
    const allCategoryTemplates = useSelector(getCategoryTemplates);
    const { projectKeyWord } = useAppKeyWords();

    const selectedCategoriesInBudgets = useMemo(() => {
        return budgetCategories.reduce((acc, bc) => {
            if (bc.category?._id) {
                acc[bc.category._id] = bc.category;
            }
            return acc;
        }, {});
    }, [budgetCategories]);

    const { availableToSelectCategoryTemplates, maxNumberOfBudgetCategories } = useMemo(() => {
        const projectCategoryTemplates = getCategoryTemplatesByProject(
            { categoryGroups: categoryGroups?.map(cg => cg._id) },
            allCategoryGroups,
            allCategoryTemplates
        );

        const availableToSelectCategoryTemplates = projectCategoryTemplates.filter(
            ct => !selectedCategoriesInBudgets[ct._id]
        );
        const maxNumberOfBudgetCategories =
            (availableToSelectCategoryTemplates?.length ?? 0) +
            (budgetCategories?.filter(bc => Boolean(bc.category))?.length ?? 0);

        return {
            availableToSelectCategoryTemplates,
            maxNumberOfBudgetCategories,
        };
    }, [allCategoryGroups, allCategoryTemplates, budgetCategories, categoryGroups, selectedCategoriesInBudgets]);

    return (
        <Layout stack gap="16px">
            <Layout stack>
                <p className="title text-capitalize">Budget</p>
                <small className="form-text text-muted">
                    Add a {projectKeyWord} budget to better track your spend against different areas your team work in.
                </small>
            </Layout>
            <FieldArray
                name="budgetCategories"
                component={BudgetCategoriesForm}
                props={{
                    availableToSelectCategoryTemplates,
                    budgetCategories,
                    maxNumberOfBudgetCategories,
                    onChange,
                }}
            />
        </Layout>
    );
});

const BudgetCategoriesForm = ({
    fields,
    availableToSelectCategoryTemplates,
    budgetCategories,
    maxNumberOfBudgetCategories,
    onChange,
}) => {
    const currency = useSelector(selectDefaultCompanyCurrency);
    const isExtensionInstalled = useIsExtensionEnabled(CATEGORY_GROUPS);
    const dispatch = useDispatch();

    const handleAddBudget = () => {
        if (!isExtensionInstalled) {
            dispatch(showMissingBookingCategoriesExtensionModal());
        } else {
            fields.push({ budgetCurrency: findCurrency(currency), budgetCashAmount: 0, budgetHours: 0 });
        }
    };

    const handleRemoveRow = index => {
        fields.splice(index, 1);
    };

    const classes = useStyles();

    const { totalCashAmount, totalBudgetHours, budgetCurrency } = useMemo(() => {
        return budgetCategories.reduce(
            (acc, bc) => {
                acc.totalCashAmount = sum(acc.totalCashAmount, bc.budgetCashAmount || 0);
                acc.totalBudgetHours = sum(acc.totalBudgetHours, bc.budgetHours || 0);

                if (acc.budgetCurrency === null && bc.budgetCurrency?.value) {
                    acc.budgetCurrency = bc.budgetCurrency?.value;
                } else if (acc.budgetCurrency !== bc.budgetCurrency?.value) {
                    // many currencies, display nothing
                    acc.budgetCurrency = '';
                }

                return acc;
            },
            {
                totalCashAmount: 0,
                totalBudgetHours: 0,
                budgetCurrency: null,
            }
        );
    }, [budgetCategories]);

    const totalCurrencySymbol = getCurrencySymbol(budgetCurrency);

    const addBtn = () => {
        if (!isExtensionInstalled) {
            return (
                <WrapWithTooltip tooltip="You need to install booking category groups extension before using this feature">
                    <Button className={classnames('p-0', classes.missingExtBtn)} color="link" onClick={handleAddBudget}>
                        + add budget category
                        <Adjust className={classes.endIcon} />
                    </Button>
                </WrapWithTooltip>
            );
        }

        if (maxNumberOfBudgetCategories <= fields?.length) {
            return null;
        }

        return (
            <Button className="p-0" color="link" onClick={handleAddBudget}>
                + add budget category
            </Button>
        );
    };

    const handleBlur = useCallback(
        event => {
            event.preventDefault();
            const val = event.target.value;
            const normalized = normalizePositiveNumbers(val);
            onChange(event.target.name, normalized ? normalized : 0);
        },
        [onChange]
    );

    const handleNumberInputKeyPress = useCallback(event => {
        // we accepts only numbers
        if (!((event.which >= 48 && event.which <= 57) || event.which === 44 || event.which === 46)) {
            event.preventDefault();
        }
    }, []);

    return (
        <Layout stack gap="4px">
            {fields.length ? (
                <Layout gap="16px" vAlign="center">
                    <div className={classes.categoryColumn}>
                        <span className="text-muted">category</span>
                    </div>
                    <div className={classes.budgetCurrencyColumn}>
                        <span className="text-muted">currency</span>
                    </div>
                    <Layout className={classes.budgetCashAmountColumn}>
                        <span className="form-text text-muted">amount</span>
                    </Layout>
                    <Layout className={classes.budgetHoursColumn}>
                        <span className="form-text text-muted">hours</span>
                    </Layout>
                    <div className={classes.actionsColumn} />
                </Layout>
            ) : null}
            <Layout stack gap="16px">
                {fields.map((categoryBudgetField, index) => {
                    return (
                        <Layout key={categoryBudgetField} gap="16px" vAlign="flex-start">
                            <div className={classes.categoryColumn}>
                                <Field
                                    inline
                                    name={`${categoryBudgetField}.category`}
                                    component={dropdownField}
                                    placeholder="Select category"
                                    valueField="_id"
                                    dropUp
                                    wrapperClassName="mb-0"
                                    itemComponent={SimpleCategoryTemplateItem}
                                    valueComponent={SimpleCategoryTemplateItem}
                                    textField={item => (Object.keys(item).length ? item.name : '')}
                                    data={availableToSelectCategoryTemplates}
                                    groupBy="groupName"
                                    groupComponent={CategoryGroupItem}
                                    filter="contains"
                                    disabled={index !== 0 && !isExtensionInstalled}
                                />
                            </div>
                            <div className={classes.budgetCurrencyColumn}>
                                <Field
                                    inline
                                    name={`${categoryBudgetField}.budgetCurrency`}
                                    component={dropdownFieldMaterial}
                                    valueField="value"
                                    textField="name"
                                    data={getCurrencies()}
                                    disabled={index !== 0 && !isExtensionInstalled}
                                />
                            </div>
                            <div className={classes.budgetCashAmountColumn}>
                                <Field
                                    type="number"
                                    step="any"
                                    normalize={normalizeFloatNumber}
                                    name={`${categoryBudgetField}.budgetCashAmount`}
                                    icon={getCurrencySymbol(budgetCategories[index]?.budgetCurrency?.value)}
                                    component={materialInputGroupField}
                                    disabled={index !== 0 && !isExtensionInstalled}
                                    onKeyPress={handleNumberInputKeyPress}
                                    onBlur={handleBlur}
                                    min={0}
                                    forwardRef
                                />
                            </div>
                            <div className={classes.budgetHoursColumn}>
                                <Field
                                    name={`${categoryBudgetField}.budgetHours`}
                                    normalize={normalizeFloatNumber}
                                    endInputAdornment={<span className={classes.endInputAdornment}>hrs</span>}
                                    className={classes.budgetHoursField}
                                    type="number"
                                    step="any"
                                    component={materialInputGroupField}
                                    disabled={index !== 0 && !isExtensionInstalled}
                                    onKeyPress={handleNumberInputKeyPress}
                                    onBlur={handleBlur}
                                    min={0}
                                    forwardRef
                                />
                            </div>
                            <Layout className={classes.actionsColumn} hAlign="flex-end">
                                {index !== 0 ? (
                                    <WrapWithTooltip tooltip="Remove">
                                        <ButtonBase disableRipple onClick={() => handleRemoveRow(index)}>
                                            <CancelIcon />
                                        </ButtonBase>
                                    </WrapWithTooltip>
                                ) : null}
                            </Layout>
                        </Layout>
                    );
                })}
                <Layout gap="16px" vAlign="center">
                    <div className={classes.categoryColumn}>{addBtn()}</div>
                    <div className={classes.budgetCurrencyColumn} />
                    <Layout className={classnames(classes.budgetCashAmountColumn)}>
                        <div className={classnames('form-text text-muted', classes.totalsPlaceHolder)}>
                            {totalCurrencySymbol ? (
                                <>
                                    <span className={classes.totalCurrencyWrapper}>{totalCurrencySymbol}</span>
                                    <NumberFormat value={totalCashAmount} />
                                </>
                            ) : (
                                'mixed'
                            )}
                        </div>
                    </Layout>
                    <Layout className={classnames(classes.budgetHoursColumn)}>
                        <div
                            className={classnames(
                                'form-text text-muted',
                                classes.totalsPlaceHolder,
                                classes.totalHoursPlaceholder
                            )}
                        >
                            <NumberFormat value={totalBudgetHours} />
                            <span className={classes.endInputAdornment}>hrs</span>
                        </div>
                    </Layout>
                    <div className={classes.actionsColumn} />
                </Layout>
            </Layout>
        </Layout>
    );
};

const useStyles = makeStyles({
    categoryColumn: {
        width: '45%',
        '& .form-control': {
            height: '37px',
        },
    },
    budgetCurrencyColumn: {
        width: '20%',
    },
    budgetCashAmountColumn: {
        width: '20%',
    },
    budgetHoursColumn: {
        width: '20%',
    },
    actionsColumn: {
        width: '5%',
        alignSelf: 'center',
    },
    totalCurrencyWrapper: {
        display: 'inline-block',
        minWidth: '16px',
    },
    endIcon: {
        fontSize: 18,
        marginTop: 2,
        marginLeft: 4,
        color: '#e6892c',
    },
    missingExtBtn: {
        color: '#6c757d',
    },
    budgetHoursField: {
        '& .MuiOutlinedInput-root input': {
            paddingLeft: '25px !important',
        },
    },
    endInputAdornment: {
        fontSize: '12px',
    },
    totalsPlaceHolder: {
        width: '100%',
        backgroundColor: '#f4f4f4',
        borderRadius: '4px',
        padding: '3px 0 3px 16px',
        fontWeight: 500,
        fontSize: '13px',
        border: `1px solid #eee`,
        marginTop: '0',
    },
    totalHoursPlaceholder: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '3px 12px 3px 25px',
    },
});
