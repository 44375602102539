import { hasRole } from 'utils/rightsUtil';
import { GROUP } from '../../../../enums/groupTypeEnum';
import { RESOURCE } from '../../../report/enums/itemTypeEnum';

export const addMenuRow = (row, { accountRoleRights, projectKeyWord, resourceKeyWord, isChildRow = true }) => {
    if (!row) {
        return null;
    }

    const rowPropName = (() => {
        if (row.type === GROUP) {
            return row.queryParams?.itemType === RESOURCE.name ? 'resourceGroup' : 'projectGroup';
        } else {
            return projectKeyWord ? 'resource' : 'project';
        }
    })();

    if (
        (rowPropName === 'projectGroup' && hasRole(accountRoleRights, 'settingProjectGroups')) ||
        (rowPropName === 'resourceGroup' && hasRole(accountRoleRights, 'settingResourceGroups')) ||
        (rowPropName === 'project' && hasRole(accountRoleRights, 'settingProjectRes')) ||
        (rowPropName === 'resource' && hasRole(accountRoleRights, 'settingResourcePro'))
    ) {
        return {
            name: `Add ${projectKeyWord || resourceKeyWord}`,
            id: 'selectRow_' + (row ? row._id : ''),
            tags: {
                menuRow: true,
                isEventRow: false,
                isChildRow, // where the menu row is rendered
                menuRowSelector: projectKeyWord ? 'project' : 'resource',
                [rowPropName]: row,
            },
            cssClass: 'child-scheduler-row cursor-p',
            marginTop: 3,
            marginBottom: 10,
        };
    }

    return null;
};

export const addRemovedProjectsRow = (row, { projectPluralKeyWord, resourceKeyWord }) => ({
    name: `Removed ${projectPluralKeyWord}`,
    id: 'removedProjectsRow_' + (row ? row._id : ''),
    tags: {
        menuRow: true,
        isRemoveProjectsRow: true,
        isEventRow: false,
        label: `Bookings for ${projectPluralKeyWord} previously removed from ${resourceKeyWord}`,
    },
    cssClass: 'child-scheduler-row',
    marginTop: 3,
    marginBottom: 10,
});

export const addEventRow = (row, { resourceKeyWord }) => ({
    name: 'Events',
    id: 'eventsRow_' + (row ? row._id : ''),
    tags: {
        menuRow: false,
        isEventRow: true,
        resource: row,
        label: `All ${resourceKeyWord} Events`,
    },
    cssClass: 'child-scheduler-row',
    marginTop: 3,
    marginBottom: 10,
});
