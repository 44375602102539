import { createSelector } from 'reselect';
import { isActive } from '../utils/extensionUtil';
import {
    APPROVAL,
    DEADLINES,
    DEPENDENCIES,
    HOLIDAY_CALENDARS,
    RESOURCE_REQUEST,
    TOIL,
    VACATION_REQUEST,
} from '../enums/extensionShortIdEnum';
import { DEFAULT } from '../enums/calendarTypeEnum';
import { CC_PROJECT_MANAGERS_ON_REQUESTS } from '../enums/notificationTypeEnum';
import {
    STATUS_ACTIVE,
    STATUS_ARCHIVED,
    STATUS_FLOATING,
    STATUS_PENDING,
    STATUS_PLANNED,
    statusesArray,
} from '../enums/projectEnum';
import { arrayToObjectByKey } from '../utils/mappingUtil';

export const getOverscheduleSetting = state => state.companyReducer.company.settings.grid.overschedule;
export const getVacationYearStartDate = state => state.companyReducer.company.settings.vacationYearStartDate;
export const getCompanySystemGroupIds = state => state.companyReducer.company.systemGroupIds;
export const getCompanyId = state => state.companyReducer.company._id;
const getCompany = state => state.companyReducer.company;
export const getCompanyExtensions = state => state.companyReducer.company.extensions;
export const getCompanySettings = state => state.companyReducer.company.settings;
export const getCompanyWeekType = state =>
    0 === state.companyReducer.company.settings.weekStartDay ? 'week' : 'isoWeek';
export const getDisplayArchivedInScheduler = state =>
    state.companyReducer.company.settings.grid.displayArchivedInScheduler;

export const getResourceRoleRight = state => state.account.resourceRoleRights;

export const selectVacationName = state => state.companyReducer.company.settings.vacationEventName;
export const selectCompanyOwnerId = state => getCompany(state).accountOwner;
export const selectVacationId = state => state.companyReducer.company.settings.vacationId;
export const selectCompanyGridSettings = state => getCompany(state).settings.grid;
export const selectCompanyCategoryDisplaySetting = state => selectCompanyGridSettings(state).catColorDisplayNew;
export const selectKeepBookingCustomColor = state => selectCompanyGridSettings(state).keepBookingCustomColor;
export const selectProjectStatusColors = state => state.companyReducer.company.settings.projectStatusColors;
export const selectIsExtensionEnabled = id =>
    createSelector(getCompanyExtensions, (extensions = []) => {
        return extensions.some(ext => ext.shortId === id);
    });
export const selectCalendars = state => state.companyReducer.company.calendars;
export const selectUseCategoriesAllocation = state => state.companyReducer.company.settings.useCategoriesAllocation;
export const selectBookingDefaultState = state => state.companyReducer.company.settings.grid.bookingDefaultState;
export const selectDefaultCompanyCurrency = state => state.companyReducer.company.settings.defaultCurrency;

const selectCompanyApproversBase = state => state.companyReducer.approvers;
export const selectCompanyApprovers = createSelector(selectCompanyApproversBase, approvers => {
    return (approvers ?? []).map(approver => {
        return {
            ...approver,
            name: `${approver.firstName} ${approver.lastName}`,
        };
    });
});
export const selectCompanyApproversLoaded = state => !state.companyReducer.approversLoading;

export const selectCompanyDeadlinesBeforeBookingEndDateConfig = state =>
    state.companyReducer.company.settings.grid.deadlineBeforeBookingEndDate;
export const selectCompanyDeadlinesMoveWithBookingConfig = state =>
    state.companyReducer.company.settings.grid.deadlineMoveWithBooking;

export const selectCompanyName = state => getCompany(state).name;
export const selectCompanySubdomain = state => getCompany(state).subdomain;

export const selectReportSettings = state => getCompany(state).settings.report;
export const selectWeekStartDaySetting = state => getCompany(state).settings.weekStartDay;
export const selectSlotMinutesSetting = state => getCompany(state).settings.slotMinutes;
export const selectGlobalDisplayProjectCodeSetting = state => getCompany(state).settings.globalDisplayProjectCode;
export const selectCalSubEnabledSetting = state => getCompany(state).settings.calSubEnabled;
export const selectWeekDaysSetting = state => getCompany(state).settings.weekDays;
export const selectCompanyThumb = state => getCompany(state).thumb;
export const selectCompanyAvgDailyCapacity = state => state.companyReducer.company.settings.report.avgDailyCapacity;
export const selectCompanyAuthenticationMode = state => state.companyReducer.company.settings.authentication.mode;
export const selectGlobalVacationAllocationLimit = state =>
    state.companyReducer.company.settings.globalVacationAllocationLimit;

export const selectAvailableCalendars = createSelector(
    getCompanyExtensions,
    selectCalendars,
    (extensions, calendars) => {
        if (!isActive(extensions, HOLIDAY_CALENDARS)) {
            return calendars?.filter(calendar => calendar.calendarType === DEFAULT);
        }

        return calendars;
    }
);

export const makeGetKeyWords = () =>
    createSelector(getCompany, company => {
        const keyWords = company.settings.keyword;

        return {
            resourceKeyWord: keyWords.keyword2Singular,
            resourcePluralKeyWord: keyWords.keyword2Plural,
            projectKeyWord: keyWords.keyword1Singular,
            projectPluralKeyWord: keyWords.keyword1Plural,
            customerKeyWord: keyWords.keyword3Singular,
            customerPluralKeyWord: keyWords.keyword3Plural,
            vacationEventName: company.settings.vacationEventName,
        };
    });

export const selectCompanyNotificationSettings = state => state.companyReducer.company.settings.notifications;

export const makeSelectCompanyNotificationSetting = notificationCategory =>
    createSelector(selectCompanyNotificationSettings, (notifications = []) => {
        return notifications.find(notification => notification.category === notificationCategory);
    });
export const selectCcProjectManagersOnRequestCompanyNotificationSetting = makeSelectCompanyNotificationSetting(
    CC_PROJECT_MANAGERS_ON_REQUESTS
);

export const makeIsExtensionInstalled = extensionName =>
    createSelector(getCompanyExtensions, extensions => {
        return isActive(extensions, extensionName);
    });

export const selectIsDependencyExtensionInstalled = makeIsExtensionInstalled(DEPENDENCIES);
export const selectIsVacationExtensionInstalled = makeIsExtensionInstalled(VACATION_REQUEST);
export const selectIsRequestResourceExtensionInstalled = makeIsExtensionInstalled(RESOURCE_REQUEST);
export const selectIsDeadlinesExtensionInstalled = makeIsExtensionInstalled(DEADLINES);
export const selectIsApprovalExtensionInstalled = makeIsExtensionInstalled(APPROVAL);
export const selectIsToilExtensionInstalled = makeIsExtensionInstalled(TOIL);

export const selectProjectStatuses = createSelector(getCompany, company => {
    if (!company?.settings?.projectStatus) {
        return statusesArray;
    }

    return company.settings.projectStatus.map(status => ({
        display: status.name,
        value: status.status,
    }));
});

export const selectProjectStatusesMapped = createSelector(selectProjectStatuses, statuses => {
    return arrayToObjectByKey(statuses, 'value');
});

export const selectProjectStatusesKeywords = createSelector(selectProjectStatusesMapped, projectStatusesMapped => {
    return {
        Active: projectStatusesMapped[STATUS_ACTIVE.value]?.display || 'Active',
        Archived: projectStatusesMapped[STATUS_ARCHIVED.value]?.display || 'Archived',
        Floating: projectStatusesMapped[STATUS_FLOATING.value]?.display || 'Floating',
        Pending: projectStatusesMapped[STATUS_PENDING.value]?.display || 'Pending',
        Planned: projectStatusesMapped[STATUS_PLANNED.value]?.display || 'Planned',
    };
});

export const selectMeanWorkdayDuration = createSelector(selectWeekDaysSetting, settings => {
    if (!settings) {
        return 8;
    }

    const { minutes, noOfWorkdays } = Object.values(settings).reduce(
        (acc, workDay) => {
            acc.minutes += workDay.workDay && workDay.minutes ? workDay.minutes : 0;
            acc.noOfWorkdays += workDay.workDay ? 1 : 0;
            return acc;
        },
        { minutes: 0, noOfWorkdays: 0 }
    );

    if (!noOfWorkdays) {
        return 8;
    }

    return minutes / 60 / noOfWorkdays;
});
