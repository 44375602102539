import React, { useCallback, useMemo } from 'react';
import { Button, useOpenState, AddResourcesDialog } from '@hub-mono/ui';
import { makeSelectResourcesWithNotGivenPolicy } from '../../../../selectors/resource';
import { useDispatch, useSelector } from 'react-redux';
import { useAppKeyWords } from '../../../../hooks';
import { updateResourcePolicies } from '../../store/actions';
import { selectToilPoliciesMapById } from '../../store/selectors';

export const AddResourceDialog = React.memo(({ selectedPolicy }) => {
    const dispatch = useDispatch();
    const { resourceKeyWord } = useAppKeyWords();
    const resourcesSelector = useMemo(() => {
        return makeSelectResourcesWithNotGivenPolicy(selectedPolicy?._id);
    }, [selectedPolicy?._id]);

    const resources = useSelector(resourcesSelector);
    const policiesMapById = useSelector(selectToilPoliciesMapById);

    const resourceWithPoliciesNames = useMemo(() => {
        return resources.map(resource => {
            let toilPolicyName;

            if (resource.toilPolicyId) {
                toilPolicyName = policiesMapById[resource.toilPolicyId]?.name ?? 'Deleted policy';
            }

            return {
                ...resource,
                toilPolicyName,
            };
        });
    }, [policiesMapById, resources]);

    const {
        close: closeAddResourceDialog,
        anchorElement: addResourceDialogAnchorElement,
        isOpen: isAddResourceDialogOpen,
        open: openAddResourceDialog,
    } = useOpenState();

    const handleAddResources = useCallback(
        selectedResources => {
            if (!selectedResources?.length || !selectedPolicy) {
                return;
            }

            dispatch(
                updateResourcePolicies.request({
                    data: selectedResources.map(resource => ({
                        resourceId: resource._id,
                        toilPolicyId: selectedPolicy._id,
                    })),
                    onSuccess: () => {
                        closeAddResourceDialog();
                    },
                })
            );
        },
        [closeAddResourceDialog, dispatch, selectedPolicy]
    );

    return (
        <>
            <Button variant="outlined" onClick={openAddResourceDialog} endIcon="add">
                Add {resourceKeyWord}
            </Button>
            <AddResourcesDialog
                resourceKeyWord={resourceKeyWord}
                open={isAddResourceDialogOpen}
                anchorElement={addResourceDialogAnchorElement}
                resources={resourceWithPoliciesNames}
                onClose={closeAddResourceDialog}
                onAdd={handleAddResources}
            />
        </>
    );
});
