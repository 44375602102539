import { DASHBOARD, getPageByValue, REPORTS, SETTINGS, TIMESHEETS } from '../enums/startPageEnum';

export function fixLandingPageUrl(url) {
    if (!url) {
        return '/';
    } else if (!url.startsWith('/')) {
        return `/${url}`;
    } else {
        return url;
    }
}

export default (
    { landingPage = '', resourceId, preferences: accountPreferences, resourceGroups = [], projectGroups = [] },
    { activeResourceGroupId } = {}
) => {
    if (!activeResourceGroupId && !accountPreferences) {
        return '';
    }

    const hasAccessToGroup = id => {
        return Boolean(resourceGroups.find(group => group._id === id) || projectGroups.find(group => group._id === id));
    };

    const page = getPageByValue(landingPage);

    const mySchedule = `/resource/${resourceId}/group/${accountPreferences.myScheduleGroupId}`;
    const activeResources = `/resource/group/${activeResourceGroupId}`;

    if (!page) {
        const groupId = landingPage.split('/')[landingPage.split('/').length - 1];

        if (groupId && groupId === accountPreferences.myScheduleGroupId) {
            return mySchedule;
        }

        if (hasAccessToGroup(groupId)) {
            return fixLandingPageUrl(landingPage);
        }
    }

    if ([TIMESHEETS.value, DASHBOARD.value, SETTINGS.value, REPORTS.value].includes(page?.value)) {
        return landingPage;
    }

    if (hasAccessToGroup(activeResourceGroupId)) {
        return activeResources;
    }

    return mySchedule;
};
