import { action, createRequestTypes } from '../../../actions/base';

const GET_TOIL_POLICIES = createRequestTypes('GET_TOIL_POLICIES', true);
const CREATE_NEW_POLICY = createRequestTypes('CREATE_NEW_POLICY', true);
const UPDATE_POLICY = createRequestTypes('UPDATE_POLICY', true);
const DELETE_POLICY = createRequestTypes('DELETE_POLICY', true);
const RESET_TOIL_REDUCER = 'RESET_TOIL_REDUCER';

const UPDATE_RESOURCE_POLICIES = createRequestTypes('UPDATE_RESOURCE_POLICIES', true);

export const toilActions = {
    GET_TOIL_POLICIES,
    CREATE_NEW_POLICY,
    UPDATE_POLICY,
    DELETE_POLICY,
    RESET_TOIL_REDUCER,
    UPDATE_RESOURCE_POLICIES
};

export const resetToilReducer = () => action(RESET_TOIL_REDUCER);

export const getToilPolicies = {
    request: () => action(GET_TOIL_POLICIES['REQUEST']),
    success: ({ policies }) => action(GET_TOIL_POLICIES['SUCCESS'], { policies }),
    failure: () => action(GET_TOIL_POLICIES['FAILURE']),
};

export const createNewPolicy = {
    request: ({ policy, onSuccess, onFailure }) =>
        action(CREATE_NEW_POLICY['REQUEST'], { policy, onSuccess, onFailure }),
    success: ({ policy, onSuccess }) => action(CREATE_NEW_POLICY['SUCCESS'], { policy, onSuccess }),
    failure: ({ onFailure }) => action(CREATE_NEW_POLICY['FAILURE'], { onFailure }),
};

export const updatePolicy = {
    request: ({ policy, onSuccess, onFailure }) => action(UPDATE_POLICY['REQUEST'], { policy, onSuccess, onFailure }),
    success: ({ policy, onSuccess }) => action(UPDATE_POLICY['SUCCESS'], { policy, onSuccess }),
    failure: () => action(UPDATE_POLICY['FAILURE']),
};

export const deletePolicy = {
    request: ({ policy, onSuccess }) => action(DELETE_POLICY['REQUEST'], { policy, onSuccess }),
    success: ({ policy, onSuccess }) => action(DELETE_POLICY['SUCCESS'], { policy, onSuccess }),
    failure: () => action(DELETE_POLICY['FAILURE']),
};

export const updateResourcePolicies = {
    request: ({ data, onSuccess }) => action(UPDATE_RESOURCE_POLICIES['REQUEST'], { data, onSuccess }),
    success: ({ data, onSuccess }) => action(UPDATE_RESOURCE_POLICIES['SUCCESS'], { data, onSuccess }),
    failure: () => action(UPDATE_RESOURCE_POLICIES['FAILURE']),
};
