import { updateDates } from 'global/utils/dateTranslator';

const getDatePeriods = (dateState, periodType, weekType) => {
    const datePeriod = `${dateState?.toLowerCase()} ${periodType?.toLowerCase()}`;

    switch (datePeriod) {
        case 'this week':
            return updateDates('this', null, weekType);
        case 'last week':
            return updateDates('sub', { week: 1 }, weekType);
        case 'next week':
            return updateDates('add', { week: 1 }, weekType);
        case 'this month':
            return updateDates('this', null, 'month');
        case 'last month':
            return updateDates('sub', { months: 1 }, 'month');
        case 'next month':
            return updateDates('add', { months: 1 }, 'month');
        case 'this quarter':
            return updateDates('this', null, 'quarter');
        case 'last quarter':
            return updateDates('sub', { quarters: 1 }, 'quarter');
        case 'next quarter':
            return updateDates('add', { quarters: 1 }, 'quarter');
        case 'this year':
            return updateDates('this', null, 'year');
        case 'last year':
            return updateDates('sub', { years: 1 }, 'year');
        case 'next year':
            return updateDates('add', { years: 1 }, 'year');
        default:
            return null;
    }
};

export default getDatePeriods;
