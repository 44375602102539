import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { useAppKeyWords, useHasRights } from 'hooks';
import { TYPE_EVENT, TYPE_REGULAR } from 'enums/projectEnum';
import { showResourceModal } from 'actions/modalActions';
import { updateResource } from 'actions/resourceActions';
import NestedMenuItem, {NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} from 'shared/nestedMenuItem';
import NewProject from './newProject';
import Scrollbar from 'shared/scrollbar';
import useScheduleProjectForm from './hooks/useScheduleProjectForm';
import ScheduleForm from 'modules/scheduler/components/dropdowns/schedule/partials/legacy/scheduleForm';
import getPasteBookingDisabled from '../utils/getPasteBookingDisabled';
import useKeepTimeRangeSelectedClassicMenu from './hooks/useKeepTimeRangeSelectedClassicMenu';
import { useMenuProjectPermissions, useMenuBookingPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import ScheduleAndRequestSelection from './scheduleAndRequestSelection';
import { KeyBoardShortCutIcon } from '../../../booking/partials/legacy/styles';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { useResourceRequestRoles } from '../../../../../services/resourceRequest';
import { PARENT } from '../../../../../enums/viewModeEnum';
import { makeStyles } from '@material-ui/core/styles';

const roles = [
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'canAddEditResources',
    },
];

const useStyles = makeStyles({
    nestedMenuItem: {
        fontSize: '13px',
        paddingLeft: '1.5rem',
        marginTop: '4px',
        marginBottom: '4px',
        marginLeft: '0.5rem',
    },
});

const LegacyResourceSingleMode = React.forwardRef((props, schedulerRef) => {
    const {
        onClick,
        onRequestVacation,
        onPaste,
        showRequestVacationOption,
        rowTags,
        isRequestMode,
        combineScheduleRequest,
        isDefaultVacationEventSet,
        showRequestResourceOption,
        viewObject,
        viewData,
        onClose,
    } = props;

    const dispatch = useDispatch();
    const preparedRowTags = rowTags || {};
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const initialProject = viewObject.isSingleProjectView ? viewData.projectId : null;

    const { canAddEditResources } = useHasRights(roles);

    const { resource } = preparedRowTags;
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const { hasAddEditProjectRights } = useMenuProjectPermissions();

    const {
        hasScheduleRights,
        checkScheduleRights,
        showScheduleButton,
        showRequestButton,
        hasRightsToUseForm,
    } = useMenuBookingPermissions(isRequestMode, combineScheduleRequest, preparedRowTags);
    const { canRequestResource } = useResourceRequestRoles();

    const resourceModal = useCallback(() => {
        onClose && onClose();
        return dispatch(
            showResourceModal({
                onSubmit: data => dispatch(updateResource.request(resource._id, data)),
                type: resource.type,
                resourceId: resource._id,
            })
        );
    }, [dispatch, resource, onClose]);

    const typeKeyWord =
        isRequestMode || (combineScheduleRequest && showRequestButton && !showScheduleButton) ? 'Request' : 'Schedule';
    const scale = schedulerRef.current.control.scale;
    const mode = schedulerRef.current.control.mode;

    const regularForm = useScheduleProjectForm(
        onClick,
        isRequestMode,
        combineScheduleRequest,
        currentSelection,
        scale,
        resource,
        TYPE_REGULAR.value
    );
    const eventForm = useScheduleProjectForm(
        onClick,
        isRequestMode,
        combineScheduleRequest,
        currentSelection,
        scale,
        resource,
        TYPE_EVENT.value
    );
    const hasFullScheduleProjectRights = checkScheduleRights(regularForm.values.project, resource);
    const hasFullScheduleEventRights = checkScheduleRights(eventForm.values.project, resource);

    useKeepTimeRangeSelectedClassicMenu(schedulerRef, currentSelection, regularForm.projects?.length);

    let modifierKeyPrefix = 'CTRL +'; // control key
    if (window.navigator.platform.indexOf('Mac') === 0) {
        modifierKeyPrefix = '⌘'; // command key
    }

    const canRequest = useMemo(() => {
        return canRequestResource({
            resource,
            projectOrEvent: regularForm.values.project || eventForm.values.project,
        });
    }, [canRequestResource, eventForm.values.project, regularForm.values.project, resource]);

    const shouldHideScheduleProjectEvent = initialProject && viewObject.isSingleProjectView && mode === PARENT.value;
    const shouldHideAddNewProject = initialProject && viewObject.isSingleProjectView && mode === PARENT.value;

    const classes = useStyles();

    return (
        <div data-cy="scheduler-menu-list">
            {!isRequestMode && (
                <WrappedMenuItem
                    onClick={onPaste}
                    data-cy="button-classic-menu-paste"
                    disabled={getPasteBookingDisabled(clipboard, preparedRowTags)}
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mt-2"
                >
                    Paste
                    <KeyBoardShortCutIcon>{modifierKeyPrefix} V</KeyBoardShortCutIcon>
                </WrappedMenuItem>
            )}
            {!isRequestMode ? <Divider /> : null}
            <ScheduleAndRequestSelection
                ref={schedulerRef}
                showRequestResourceOption={showRequestResourceOption}
                scale={scale}
                rowTags={preparedRowTags}
                onClick={onClick}
                isRequestMode={isRequestMode}
                combineScheduleRequest={combineScheduleRequest}
                projectId={initialProject}
                showRequestVacationOption={showRequestVacationOption}
                isDefaultVacationEventSet={isDefaultVacationEventSet}
                onRequestVacation={onRequestVacation}
                hideScheduleRequestItems={!initialProject}
                resource={resource}
                resourceId={
                    preparedRowTags.isResourceRow || preparedRowTags.unassignedRow
                        ? resource._id
                        : preparedRowTags.resourceId
                }
                onClose={onClose}
            />
            {hasRightsToUseForm && !shouldHideScheduleProjectEvent && (
                <NestedMenuItem
                    label={`${typeKeyWord} ${projectKeyWord.toLowerCase()}`}
                    parentMenuOpen
                    width={322}
                    className={classes.nestedMenuItem}
                >
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <ScheduleForm
                            formik={regularForm.formik}
                            keyword={regularForm.keyword}
                            formValues={regularForm.values}
                            updateFields={regularForm.updateFields}
                            updateProject={regularForm.updateProject}
                            updateCategory={regularForm.updateCategory}
                            updateDates={regularForm.updateDates}
                            projects={regularForm.projects}
                            isSubmitting={regularForm.isSubmitting}
                            currentScale={scale}
                            projectCategoryGroups={regularForm.projectCategoryGroups}
                            scheduleButton={
                                showScheduleButton && {
                                    onClick: regularForm.handleSubmitSchedule,
                                    disabled: !hasFullScheduleProjectRights || !hasScheduleRights.schedule,
                                }
                            }
                            requestButton={
                                showRequestButton && {
                                    onClick: regularForm.handleSubmitRequest,
                                    disabled: !canRequest,
                                }
                            }
                            onAllocationTypeChange={regularForm.handleAllocationTypeChange}
                            onAllocationValueChange={regularForm.handleAllocationValueChange}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {hasRightsToUseForm && !shouldHideScheduleProjectEvent && (
                <NestedMenuItem
                    label={`${typeKeyWord} event`}
                    data-cy="schedule-event"
                    className={classes.nestedMenuItem}
                    parentMenuOpen
                    width={322}
                >
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <ScheduleForm
                            formik={eventForm.formik}
                            keyword={eventForm.keyword}
                            formValues={eventForm.values}
                            updateFields={eventForm.updateFields}
                            updateProject={eventForm.updateProject}
                            updateCategory={eventForm.updateCategory}
                            updateDates={eventForm.updateDates}
                            projects={eventForm.projects}
                            isSubmitting={eventForm.isSubmitting}
                            currentScale={scale}
                            projectCategoryGroups={eventForm.projectCategoryGroups}
                            scheduleButton={
                                showScheduleButton && {
                                    onClick: eventForm.handleSubmitSchedule,
                                    disabled: !hasFullScheduleEventRights || !hasScheduleRights.schedule,
                                }
                            }
                            requestButton={
                                showRequestButton && {
                                    onClick: eventForm.handleSubmitRequest,
                                    disabled: !canRequest,
                                }
                            }
                            onAllocationTypeChange={eventForm.handleAllocationTypeChange}
                            onAllocationValueChange={eventForm.handleAllocationValueChange}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {!isRequestMode &&
                hasAddEditProjectRights &&
                preparedRowTags.hasResourceRights &&
                hasRightsToUseForm &&
                !shouldHideAddNewProject && (
                    <NestedMenuItem
                        label={`Add new ${projectKeyWord.toLowerCase()}`}
                        data-cy="add-new-project"
                        className={classes.nestedMenuItem}
                        parentMenuOpen
                    >
                        <NewProject
                            ref={schedulerRef}
                            selectedResource={resource}
                            currentScale={scale}
                            createBooking={onClick}
                            currentSelection={currentSelection}
                            onClose={onClose}
                        />
                    </NestedMenuItem>
                )}
            {!isRequestMode && preparedRowTags.resource?.hasRightsToResource && canAddEditResources ? (
                <>
                    <Divider />
                    <WrappedMenuItem
                        onClick={resourceModal}
                        data-cy={`edit-${preparedRowTags.unassignedRow ? 'unassigned' : 'resource'}`}
                        className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    >
                        Edit {preparedRowTags.unassignedRow ? 'unassigned' : resourceKeyWord.toLowerCase()}...
                    </WrappedMenuItem>
                </>
            ) : null}
        </div>
    );
});

LegacyResourceSingleMode.propTypes = {
    onClick: PropTypes.func.isRequired,
    onPaste: PropTypes.func.isRequired,
    isRequestMode: PropTypes.bool.isRequired,
    rowTags: PropTypes.object,
    showRequestVacationOption: PropTypes.bool,
    isDefaultVacationEventSet: PropTypes.bool,
};

LegacyResourceSingleMode.defaultProps = {
    rowTags: {},
};

export default LegacyResourceSingleMode;
