import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { includes, filter } from 'lodash';
import { Save } from '@material-ui/icons';
import { NavLink, NavItem } from 'reactstrap';
import { getLoggedInId } from 'selectors/account';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { useGroup, useGroupActions } from 'modules/scheduler/hooks';
import { getViewObject, prepareSchedulerDataToCreate } from 'modules/scheduler/utils/schedulerUtil';
import SaveAsPopup from 'shared/saveAsPopup';
import { useHasRights, useIsExtensionInstalled } from 'hooks';
import { SMART_FILTERS, SMART_GROUPS, SMART_SCHEDULES } from 'enums/extensionShortIdEnum';
import {
    selectHaveSchedulerAppliedFiltersChanged,
    selectHaveSchedulerAppliedRowOrBookingFiltersChanged,
} from '../../../../../../selectors/scheduler';
import { buttonStates } from '../../../../modals/builder/buttonStates/buttonStates';
import ConfirmDialog from '../../../../../../shared/confirmButton/confirmDialog';

const rightsToCheck = [
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'canAddNewEditProjectGroups',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'canAddNewEditResourceGroups',
    },
];

const ScheduleSaver = props => {
    const { disabled, name } = props;
    const params = useParams();
    const { isGroupView, isProjectView, isSingleResourceView } = getViewObject(params);
    const { current } = useGroup(params);
    const [confirmDialogState, setConfirmDialogState] = useState({
        show: false,
        message: undefined,
        action: () => {
            // by default do nothing
        },
    });

    const loggedInResourceId = useSelector(getLoggedInId);
    const { isDataSet, filters, data } = useSelector(state => state.scheduler);
    const haveRowOrBookingFiltersChanged = useSelector(selectHaveSchedulerAppliedRowOrBookingFiltersChanged);
    const haveFiltersChanged = useSelector(selectHaveSchedulerAppliedFiltersChanged);

    const [type, setType] = useState(current.type);

    const handleTypeChange = useCallback(type => {
        setType(type);
    }, []);

    const { create, update } = useGroupActions();

    const title = disabled ? 'There is nothing to save or update' : 'Save Schedule / Group';

    const isSavedTemplate = current.type === SCHEDULER_TEMPLATE;
    const isSavedTemplateAndPrivate = isSavedTemplate && current.visibility === 'PRIVATE';
    const isSavedTemplateAndPrivateAndCreatedByUser =
        isSavedTemplateAndPrivate && loggedInResourceId === current.createdBy;

    const isSmartGroupInstalled = useIsExtensionInstalled(SMART_GROUPS);
    const isSmartSchedulesInstalled = useIsExtensionInstalled(SMART_SCHEDULES);
    const isSmartFiltersExtensionInstalled = useIsExtensionInstalled(SMART_FILTERS);

    const { canAddNewEditProjectGroups, canAddNewEditResourceGroups } = useHasRights(rightsToCheck);

    const {
        enableSave,
        enableSaveAsGroup,
        enableSaveAsSchedule,
        enableSavePrivateInternal,
        enableSaveAs,
    } = useMemo(() => {
        return buttonStates({
            itemId: params.resourceGroupId || params.projectGroupId,
            type: type ?? current.type,
            groupType: current.groupType,
            isSmart: current.isSmart,
            itemTypeName: params.resourceGroupId ? 'resource' : 'project',
            isSmartGroupInstalled,
            isSmartSchedulesExtensionInstalled: isSmartSchedulesInstalled,
            isSmartFiltersExtensionInstalled,
            haveAppliedFiltersChanged: haveFiltersChanged,
            haveSavedDataOrFiltersChanged: {
                ...haveFiltersChanged,
                groupType: type ? type !== current.type : false,
            },
            canAddNewEditProjectGroups,
            canAddNewEditResourceGroups,
        });
    }, [
        canAddNewEditProjectGroups,
        canAddNewEditResourceGroups,
        current.groupType,
        current.isSmart,
        current.type,
        haveFiltersChanged,
        isSmartFiltersExtensionInstalled,
        isSmartGroupInstalled,
        isSmartSchedulesInstalled,
        params.projectGroupId,
        params.resourceGroupId,
        type,
    ]);

    const onConfirm = () => {
        confirmDialogState.action();
    };

    const closeConfirmDialog = () => {
        setConfirmDialogState({
            show: false,
            message: undefined,
            action: () => {},
        });
    };

    const onUpdate = popupData => {
        const action = () =>
            update(
                {
                    group: current,
                    isSmart: current.isSmart,
                    itemType: isProjectView ? PROJECT : RESOURCE,
                    filters,
                    groupsToAdd: isDataSet ? data.groupIds : [],
                    groupsToRemove:
                        isDataSet && current.queryParams?.groupIds
                            ? filter(current.queryParams?.groupIds, id => !includes(data.groupIds, id))
                            : [],
                    itemsToAdd: isDataSet ? data.itemIds : [],
                    itemsToRemove: isDataSet
                        ? filter(
                              current.queryParams?.itemIds || current[isProjectView ? 'projects' : 'resources'],
                              id => !includes(data.itemIds, id)
                          )
                        : [],
                    ...popupData,
                },
                current.parentGroupId,
                haveRowOrBookingFiltersChanged
            );

        if (enableSave.message?.confirm) {
            setConfirmDialogState({
                show: true,
                message: enableSave.message,
                action,
            });
        } else {
            action();
        }
    };

    const onSaveAsNew = popupData => {
        const isSchedule = popupData.groupType === SCHEDULER_TEMPLATE;

        const items = isProjectView ? current.projects : current.resources;
        let selectedGroups = data.groupIds;
        let selectedItems = data.itemIds;
        if (!isDataSet && isGroupView) {
            selectedGroups = current.queryParams?.itemGroupIds;
        }
        if (!isDataSet && isGroupView) {
            selectedItems = current.queryParams?.itemIds || items;
        }
        if (!isDataSet && isSingleResourceView) {
            selectedItems = [isProjectView ? params.projectId : params.resourceId];
        }

        const action = () =>
            create(
                prepareSchedulerDataToCreate({
                    ...popupData,
                    group: current,
                    isSmart: current.isSmart,
                    itemType: isProjectView ? PROJECT : RESOURCE,
                    filters,
                    selectedGroups,
                    selectedItems,
                }),
                undefined,
                haveRowOrBookingFiltersChanged
            );

        if (isSchedule && enableSaveAsSchedule.message?.confirm) {
            setConfirmDialogState({
                show: true,
                message: enableSaveAsSchedule.message,
                action,
            });
        } else if (enableSaveAsGroup.message?.confirm) {
            setConfirmDialogState({
                show: true,
                message: enableSaveAsGroup.message,
                action,
            });
        } else {
            action();
        }
    };

    return (
        <div className="position-relative" data-cy="scheduler-header-saver">
            <SaveAsPopup
                name={name}
                create={onSaveAsNew}
                update={onUpdate}
                current={current}
                onTypeChange={handleTypeChange}
                config={{
                    displayPrivateAndInternalRadioOptions: isSmartSchedulesInstalled,
                    disablePrivateAndInternalRadioOptions: !enableSavePrivateInternal.enabled,
                    saveBtn: {
                        disabled: !enableSave ? !isSavedTemplateAndPrivateAndCreatedByUser : !enableSave.enabled,
                        tooltip: enableSave.message?.tooltip,
                    },
                    saveAsBtn: {
                        disabled: !enableSaveAs.enabled,
                        tooltip: enableSaveAs.message?.tooltip,
                    },
                }}
            >
                {props => (
                    <NavItem title={title} className="py-2 px-3" style={{ borderRight: '1px solid #e4e4e4' }}>
                        <NavLink
                            href="#"
                            className={classNames({ disabled })}
                            disabled={disabled}
                            title={title}
                            onClick={!disabled ? props.toggle : null}
                        >
                            <Save />
                        </NavLink>
                    </NavItem>
                )}
            </SaveAsPopup>
            <ConfirmDialog
                title=""
                message={confirmDialogState.message?.confirm}
                onConfirm={onConfirm}
                onReject={closeConfirmDialog}
                show={confirmDialogState.show}
                backgroundColor="#C82333"
                confirmButtonText="Confirm"
                cancelButtonText="Cancel"
            />
        </div>
    );
};

ScheduleSaver.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
};

ScheduleSaver.defaultProps = {
    name: '',
    disabled: false,
};

export default ScheduleSaver;
