import { map } from 'underscore';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {*[]}
 */
export default customFields =>
    map(customFields, customField => ({
        id: `${customField.category.toLowerCase()}_cf_${customField._id}`,
        display: customField.label,
        displayTimeType: 'Text',
        displayColumnType: 'custom field',
        columnType: COLUMN_TYPES_ENUM.CUSTOM_FIELD,
        heatMapSupport: false,
    }));
