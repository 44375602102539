/*
    IMPORTANT
    All code in this file MUST be documented
*/

import { HPValidationError } from 'shared/lib/errors';

/**
 * Gets validations results after validating an object against the schema.
 * @param {*} object Object to get validation results
 * @param {Schema} schema to validate
 * @returns {object} schema validation results
 */
export const getObjectSchemaValidationResult = (object = {}, schema) => {
    if (!schema) {
        throw new HPValidationError('checkObjectWithSchema: please provide schema');
    }
    return schema.validate(object);
};

/**
 * Throws an error if validation of the object fails given the schema. Errors will be printed and execution ends.
 * @param {*} object Object to check
 * @param {*} schema Schema to validate the object
 */
export const checkObjectWithSchema = (object, schema) => {
    const validationResult = getObjectSchemaValidationResult(object, schema);
    if (validationResult && Object.keys(validationResult).length > 0) {
        const errorMessage = Object.entries(validationResult).reduce((acc, cv) => {
            const [, errors] = cv;
            const propLineError = `--> ${errors.join('\n--> ')}`;
            return `${acc} \n${propLineError}`;
        }, 'checkObjectWithSchema: validate failed');
        throw new HPValidationError(errorMessage);
    }
};

/**
 * Checks if objects matches schema. Returns true/false
 * @param {*} object Object to check
 * @param {Schema} schema Schema to check
 * @returns {boolean} boolean
 */
export const isObjectMatchingSchema = (object, schema) => {
    const validationResult = getObjectSchemaValidationResult(object, schema);
    return !(validationResult && Object.keys(validationResult).length > 0);
};

/**
 * Checks if objects matches schema. Returns true/false and validation result
 * @param {*} object Object to check
 * @param {Schema} schema Schema to check
 * @returns {object} schema validation info
 */
export const getSchemaValidationInfo = (object, schema) => {
    const validationResult = getObjectSchemaValidationResult(object, schema);
    return {
        validationResult,
        validDataForSchema: !(validationResult && Object.keys(validationResult).length > 0),
    };
};
