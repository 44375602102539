import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetKeyWords } from 'selectors/company';

/**
 * @returns {{
 *   resourceKeyWord:string,
 *   resourcePluralKeyWord:string,
 *   projectKeyWord:string,
 *   projectPluralKeyWord:string,
 *   customerKeyWord:string,
 *   customerPluralKeyWord:string,
 *   vacationEventName:string,
 * }}
 */
export const useAppKeyWords = () => {
    const keyWordsSelector = useMemo(() => makeGetKeyWords(), []);

    return useSelector(keyWordsSelector);
};
