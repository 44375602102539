import React from 'react';
import moment from 'moment';
import { REJECTED, APPROVED } from 'enums/bookingTypeEnum';
import { Chip } from '@hub-mono/ui';

const Approver = ({ booking }) => {
    let approvedOrRejectedBy =
        booking.approvalInfo && booking.approvalInfo.approvedOrRejectedById
            ? `${booking.approverFirstName} ${booking.approverLastName}`
            : 'No Name';
    let type = booking.type === REJECTED.value ? REJECTED.display : APPROVED.display;

    return (
        booking.approvalInfo &&
        booking.approvalInfo.approvedOrRejectedDate && (
            <Chip
                label={`${type} by: ${approvedOrRejectedBy} on ${moment
                    .utc(booking.approvalInfo.approvedOrRejectedDate)
                    .format('MMM Do YYYY')}`}
            />
        )
    );
};

export default Approver;
