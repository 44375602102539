import { createSelector } from 'reselect';
import { arrayToObjectByKey } from '../../../utils/mappingUtil';

export const selectToilPolicies = state => state.toilReducer.policies;

export const selectIsCreating = state => state.toilReducer.creating

export const selectToilPoliciesMapById = createSelector(selectToilPolicies, policies => {
    return arrayToObjectByKey(policies ?? [], '_id');
});

export const selectDefaultToilPolicy = createSelector(selectToilPolicies, policies => {
    return (policies || []).find(policy => policy.default);
})

export const selectToilPolicyById = id =>
    createSelector(selectToilPoliciesMapById, policiesMap => {
        return policiesMap[id];
    });
