/* eslint-env browser */
import { usePressEnter } from './usePressEnter';

export const shouldSubmitForm = event => {
    const isQuillEditor = event.target.getAttribute('class')?.includes('ql-editor');
    const isAutoComplete = event.target.hasAttribute('aria-autocomplete');
    const isTextArea = event.target.type === 'textarea';
    const isPicker = event.target.getAttribute('class')?.includes('MuiPickers');
    const isColorPickerInput = event.target.getAttribute('id')?.includes('rc-editable-input');
    const isSelectReactWidget = event.target.getAttribute('class')?.includes('rw-input-reset');

    let elem = event.target;
    let pressedInModal = false;

    while (elem && !pressedInModal) {
        if (elem?.getAttribute('class')?.includes('modal-submit-enter')) {
            pressedInModal = true;
        }

        elem = elem?.parentElement;
    }

    return (
        !(isTextArea || isQuillEditor || isAutoComplete || isPicker || isColorPickerInput || isSelectReactWidget) &&
        pressedInModal
    );
};

export const useModalPressEnter = callback => {
    usePressEnter(event => {
        if (shouldSubmitForm(event)) {
            callback();
        }
    });
};
