import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import Routes from './Routes';
import { initialize } from 'actions/initializeActions';
import { getAccount } from 'actions/accountActions';
import { getAccount as selectAccount, getAccountPreferences, getLoggedInRole } from 'selectors/account';
import Notifications from 'modules/notifications/Notifications';
import InitialLoading from 'shared/initialLoading';
import BaseModal from 'modules/modals';
import BaseContextMenu from 'modules/scheduler/components/contextMenu';
import Flows from 'shared/components/Flows';
import { useLocation } from 'react-router-dom';
import { showCompanyOwnerMFAReminderModal } from './actions/modalActions';
import { shouldDisplayReminder } from './sagas/helpers/mfa';
import { MFA_LAUNCH_DATE, mfaReminderTypes } from './shared/constants/mfaReminders';
import { isBefore } from 'date-fns';
import { ErrorBoundary, monitoring, useMonitoringLoggedInUserContext } from './monitoring';

monitoring.init();

const App = () => {
    const dispatch = useDispatch();
    const initialLoad = useSelector(state => state.initialAppReducer.initialLoad);
    const isFullScreen = useSelector(state => state.initialAppReducer.isFullScreen);

    const { resourceCreatedDate } = useSelector(selectAccount);
    const accountPreferences = useSelector(getAccountPreferences);
    const accountRole = useSelector(getLoggedInRole);
    const location = useLocation();

    useMonitoringLoggedInUserContext();

    useEffect(() => {
        const shouldDisplay = shouldDisplayReminder(
            accountPreferences?.notifications,
            mfaReminderTypes.MFA_COMPANY_REMINDER
        );

        if (
            location.search.includes('mfa-after-on-boarding') &&
            shouldDisplay &&
            accountRole === 'ROLE_OWNER' &&
            isBefore(new Date(resourceCreatedDate), new Date(MFA_LAUNCH_DATE))
        ) {
            dispatch(showCompanyOwnerMFAReminderModal());
        }
    }, [accountPreferences?.notifications, accountRole, dispatch, location.search, resourceCreatedDate]);

    const { loading: isAutoLoginInProgress, isPartiallyAuthenticated, _id, preferences } = useSelector(
        state => state.account
    );
    const isAuthenticated = !!_id;
    const theme = preferences.theme;

    useEffect(() => {
        // on first render we try to auto authenticate user (if there is cookie rememberme)
        dispatch(getAccount.request());
    }, [dispatch]);

    // if user is authenticated then we get all data that we need for init app (companyTree, etc.)
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(initialize.request());
        } else if (!isAutoLoginInProgress && !isAuthenticated) {
            dispatch(initialize.failure());
        }
    }, [dispatch, isAuthenticated, isAutoLoginInProgress]);

    if (isAutoLoginInProgress || initialLoad) {
        return <InitialLoading />;
    }

    return (
        <ErrorBoundary>
            <Routes
                isAuthenticated={isAuthenticated}
                isPartiallyAuthenticated={isPartiallyAuthenticated}
                isAutoLoginInProgress={isAutoLoginInProgress}
                theme={theme}
                isFullScreen={isFullScreen}
            />
            <BaseModal />
            {createPortal(<Notifications />, document.body)}
            {isAuthenticated && <Flows />}
            <BaseContextMenu />
        </ErrorBoundary>
    );
};

export default App;
