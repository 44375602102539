/* eslint-env browser */
import { PureComponent } from 'react';
import { assign, findIndex, map } from 'underscore';
import { getReportLevel } from 'modules/report/enums/storageItemEnum';

class BaseFilterBox extends PureComponent {
    constructor(props) {
        super(props);
        this.node = undefined;
        this.state = { toggleBox: false, filterText: '' };
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);

        this.setActiveState && this.setActiveState();
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = event => {
        const domNode = this.node;
        if (domNode && !domNode.contains(event.target) && this.state.toggleBox) {
            this.setState({ toggleBox: false });
        }
    };

    toggle = () => {
        if (!this.props.disabled) {
            this.setState(state => ({ toggleBox: !state.toggleBox }));
        }
    };

    onChange = value => {
        this.setState(value);
    };

    onClear = () => {
        this.toggle();
        const {
            columnName,
            queryParams: {
                filters: { resultValue },
            },
        } = this.props;
        const currentInlineFilters = [...resultValue];
        const existFilterIndex = findIndex(currentInlineFilters, filter => filter.columnName === columnName);
        const reportLevel = getReportLevel(this.props.match.params);

        -1 < existFilterIndex && currentInlineFilters.splice(existFilterIndex, 1);

        this.props.updateQueryParams(
            assign({}, this.props.queryParams, {
                filters: {
                    ...this.props.queryParams.filters,
                    resultValue: currentInlineFilters,
                },
            }),
            reportLevel.storageItemName
        );
        this.setState({ active: true });
    };

    updateFilter(currentFilter) {
        const reportLevel = getReportLevel(this.props.match.params);
        this.props.updateQueryParams(
            assign({}, this.props.queryParams, {
                filters: {
                    ...this.props.queryParams.filters,
                    resultValue: this.getCurrentFilters(currentFilter),
                },
            }),
            reportLevel.storageItemName
        );
    }

    getCurrentFilters = currentFilter => {
        const {
            queryParams: {
                filters: { resultValue },
            },
            columnName,
        } = this.props;
        let filterExist = false;

        const filters = map(resultValue, filter => {
            if (filter.columnName === columnName) {
                filterExist = true;
                return currentFilter;
            }

            return filter;
        });

        !filterExist && filters.push(currentFilter);

        return filters;
    };
}

export default BaseFilterBox;
