import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { emailValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        email: {
            type: String,
            required: true,
            validators: [emailValidator],
        },
    },
    errorMessages,
    false
);
