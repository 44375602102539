import { keys, isEmpty, intersection, filter, map, pickBy, includes } from 'lodash';
import {
    PROJECT as PROJECT_GROUPING,
    PROJECT_GROUP,
    PROJECT_MANAGER,
    RESOURCE,
} from 'modules/report/enums/groupingTypeEnum';
import { PROJECT } from 'modules/report/enums/itemTypeEnum';
import { MY_MANAGED_PROJECTS, MY_PROJECTS } from 'enums/criteriaEnum';
import { RESOURCE_RATE, TIME_SHEET } from 'enums/extensionShortIdEnum';
import { hasExtensionsInstalled } from 'utils/extensionUtil';

const columnsList = [
    {
        name: 'scheduledTimeAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow', 'displayScheduledReportedBudgetHoursRow'],
    },
    {
        name: 'scheduledTimeToDate',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow'],
    },
    {
        name: 'scheduledTimeFuture',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow'],
    },
    {
        name: 'reportedTimeAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow', 'displayScheduledReportedBudgetHoursRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'reportedTimeToDate',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'reportedTimeFuture',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedTimeRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'externalScheduledSpendAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
    },
    {
        name: 'externalScheduledSpendToDate',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
    },
    {
        name: 'externalScheduledSpendFuture',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
    },
    {
        name: 'externalReportedSpendAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'externalReportedSpendToDate',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'externalReportedSpendFuture',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedExternalSpendRow'],
        extensions: [TIME_SHEET],
    },
    {
        name: 'internalScheduledSpendAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [RESOURCE_RATE],
    },
    {
        name: 'internalScheduledSpendToDate',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [RESOURCE_RATE],
    },
    {
        name: 'internalScheduledSpendFuture',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [RESOURCE_RATE],
    },
    {
        name: 'internalReportedSpendAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [TIME_SHEET, RESOURCE_RATE],
    },
    {
        name: 'internalReportedSpendToDate',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [TIME_SHEET, RESOURCE_RATE],
    },
    {
        name: 'internalReportedSpendFuture',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedInternalSpendRow'],
        extensions: [TIME_SHEET, RESOURCE_RATE],
    },
    {
        name: 'budgetCash',
        displayColumnProps: ['displayScheduledColumn', 'displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedBudgetCashRow'],
        projectGroupViewSupport: false,
    },
    {
        name: 'externalRemainingScheduledBudgetCashAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedBudgetCashRow'],
        projectGroupViewSupport: false,
    },
    {
        name: 'internalRemainingScheduledBudgetCashAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedBudgetCashRow'],
        projectGroupViewSupport: false,
    },
    {
        name: 'budgetHours',
        displayColumnProps: ['displayScheduledColumn', 'displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedBudgetHoursRow'],
        projectGroupViewSupport: false,
    },
    {
        name: 'remainingScheduledBudgetHoursAllTime',
        displayColumnProps: ['displayScheduledColumn'],
        displayRowProps: ['displayScheduledReportedBudgetHoursRow'],
        projectGroupViewSupport: false,
    },
    {
        name: 'externalRemainingReportedBudgetCashAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedBudgetCashRow'],
        projectGroupViewSupport: false,
        extensions: [TIME_SHEET],
    },
    {
        name: 'remainingReportedBudgetHoursAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedBudgetHoursRow'],
        projectGroupViewSupport: false,
        extensions: [TIME_SHEET],
    },
    {
        name: 'internalRemainingReportedBudgetCashAllTime',
        displayColumnProps: ['displayReportedColumn'],
        displayRowProps: ['displayScheduledReportedBudgetCashRow'],
        projectGroupViewSupport: false,
        extensions: [TIME_SHEET],
    },
];

const getColumnsToLoad = ({ ...singleProjectViewSettings }, companyExtensions, isSingleProjectView) => {
    delete singleProjectViewSettings.informationPanelDisplayed;
    const displayProps = keys(pickBy(singleProjectViewSettings, item => true === item));
    const filteredColumnsList = filter(
        columnsList,
        column =>
            !isEmpty(intersection(displayProps, column.displayColumnProps)) &&
            !isEmpty(intersection(displayProps, column.displayRowProps)) &&
            (!column.extensions || hasExtensionsInstalled(companyExtensions, column.extensions)) &&
            (!column.hasOwnProperty('projectGroupViewSupport') ||
                (isSingleProjectView && !column.projectGroupViewSupport))
    );

    return map(filteredColumnsList, column => ({ name: column.name }));
};

export default (
    { projectId, projectGroupId },
    loggedInResourceId,
    projectGroup,
    singleProjectView,
    extensions,
    { isSingleProjectView, isProjectGroupView }
) => {
    let filters;
    let itemIds = projectId ? [projectId] : [];
    let itemGroupIds = !projectId && projectGroupId ? [projectGroupId] : [];
    let groupBy = PROJECT_GROUPING.name;
    if (isProjectGroupView) {
        groupBy = PROJECT_GROUP.name;
        if (includes([MY_MANAGED_PROJECTS, MY_PROJECTS], projectGroup.criteria)) {
            itemIds = projectGroup.projects;
            itemGroupIds = [];
        }
        if (projectGroup.criteria === MY_MANAGED_PROJECTS) {
            groupBy = PROJECT_MANAGER.name;
            filters = [{ name: 'projectManagers', values: [loggedInResourceId] }];
        }
        if (projectGroup.criteria === MY_PROJECTS) {
            groupBy = RESOURCE.name;
            filters = [{ name: 'resources', values: [loggedInResourceId] }];
        }
    }

    return {
        params: {
            itemIds,
            itemGroupIds,
            itemType: PROJECT.name,
            groupBy,
            displayEmptyResults: !isProjectGroupView,
            useCacheOnReportResult: false,
        },
        filters,
        columnsToLoad: [
            { name: 'name' },
            { name: 'scheduledTimeBetween' },
            ...getColumnsToLoad(singleProjectView, extensions, isSingleProjectView),
        ],
        sort: {
            columnName: 'name',
            direction: 'asc',
        },
    };
};
