import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import { SliderWrapper } from './styles';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => {
    return {
        root: {
            color: '#32A675',
            width: '150px !important',
            verticalAlign: 'middle',
            '& .MuiSlider-rail': {
                backgroundColor: '#000',
            },
            '& .MuiSlider-valueLabel': {
                color: 'transparent',
                marginBottom: '-20px',
                top: '-20px',
                fontSize: '12px',
                '& span span': {
                    color: '#000',
                },
            },
        },
        title: {
            display: 'inline-block',
            width: '90px',
        },
    };
});

const SliderComponent = ({ onChange, min, max, step, value: defaultValue, label, name }) => {
    const styles = useStyles();

    const [value] = useState(defaultValue);

    const onUpdate = (event, sliderValue) => {
        onChange({ [name]: sliderValue });
    };

    return (
        <SliderWrapper withLabel={label}>
            {label && (
                <Typography gutterBottom className={styles.title}>
                    {label}
                </Typography>
            )}
            <Slider
                defaultValue={value}
                aria-labelledby={label}
                step={step}
                max={max}
                min={min}
                onChangeCommitted={onUpdate}
                className={styles.root}
                valueLabelDisplay="on"
            />
        </SliderWrapper>
    );
};

SliderComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
};

SliderComponent.defaultProps = {
    onChange: null,
    min: 0,
    max: 100,
    step: 10,
    value: 0,
    label: '',
};

export default SliderComponent;
