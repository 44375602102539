import { add, set } from 'date-fns';
import { forEach, map, groupBy, head, sortBy, values, filter } from 'lodash';
import { formatDate, getDateDuration } from 'utils/DateUtil';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

export const mapPhasesToBE = (phases, projectPhases = [], startEndTimes) => {
    const mappedPhases = [];

    forEach(phases, phase => {
        const start = set(phase.start, {
            hours: startEndTimes.startDay,
            minutes: startEndTimes.startMinute,
        });
        const end = set(phase.end, {
            hours: startEndTimes.endDay,
            minutes: startEndTimes.endMinute,
        });

        const projectPhase = projectPhases.find(projectPhase => {
            return projectPhase._id === phase._id;
        });

        const shouldRecalculateSeries =
            !projectPhase ||
            phase.interval !== projectPhase?.interval ||
            phase.repeatTimes !== projectPhase?.repeatTimes ||
            phase.repeat !== projectPhase?.repeat ||
            formatDate(phase.start, 'yyyy-MM-dd') !== formatDate(projectPhase.start, 'yyyy-MM-dd') ||
            formatDate(phase.end, 'yyyy-MM-dd') !== formatDate(projectPhase.end, 'yyyy-MM-dd');

        if ('NONE' !== phase.interval && 1 < phase.repeatTimes && shouldRecalculateSeries) {
            delete phase._id;
            const { repeatedChildrenIds = [] } = phase;
            for (let i = 0; i < phase.repeatTimes; i++) {
                const duration = getDateDuration(phase.interval);
                const modifiedValues = {
                    start: add(start, { [duration]: i }),
                    end: add(end, { [duration]: i }),
                };
                mappedPhases.push({
                    ...phase,
                    ...modifiedValues,
                    _id: repeatedChildrenIds[i],
                    repeat: true,
                });
            }
        } else if ('NONE' !== phase.interval && 1 < phase.repeatTimes && !shouldRecalculateSeries) {
            const repeated = projectPhases.filter(projectPhase => projectPhase.repeatId === phase.repeatId);

            for (const repeatedPhase of repeated) {
                mappedPhases.push({
                    ...phase,
                    repeat: repeatedPhase.repeat,
                    repeatId: repeatedPhase.repeatId,
                    repeatTimes: repeatedPhase.repeatTimes,
                    interval: repeatedPhase.interval,
                    start: repeatedPhase.start,
                    end: repeatedPhase.end,
                    _id: repeatedPhase._id,
                });
            }
        } else {
            mappedPhases.push({
                ...phase,
                repeat: false,
                start: formatDate(start, BOOKING_FORMAT),
                end: formatDate(end, BOOKING_FORMAT),
            });
        }

        delete phase.repeatedChildrenIds;
    });

    return mappedPhases;
};

export const mapMilestonesToBE = (milestones, projectMilestones = [], projectId) => {
    const mappedMilestones = [];

    forEach(milestones, milestone => {
        const overwriteValues = {
            project: projectId,
        };

        const projectMilestone = projectMilestones.find(projectMilestone => {
            return projectMilestone._id === milestone._id;
        });

        const shouldRecalculateSeries =
            !projectMilestone ||
            milestone.interval !== projectMilestone.interval ||
            milestone.repeatTimes !== projectMilestone.repeatTimes ||
            milestone.repeat !== projectMilestone.repeat ||
            formatDate(milestone.date, 'yyyy-MM-dd') !== formatDate(projectMilestone.date, 'yyyy-MM-dd');

        if ('NONE' !== milestone.interval && 1 < milestone.repeatTimes && shouldRecalculateSeries) {
            delete milestone._id;
            const { repeatedChildrenIds = [] } = milestone;
            for (let i = 1; i <= milestone.repeatTimes; i++) {
                if (1 < i) {
                    const duration = getDateDuration(milestone.interval);
                    const date = add(new Date(milestone.date), { [duration]: i - 1 });
                    overwriteValues.date = formatDate(date, 'yyyy-MM-dd', false);
                }
                mappedMilestones.push({
                    ...milestone,
                    ...overwriteValues,
                    _id: repeatedChildrenIds[i],
                    repeat: true,
                });
            }
        } else if ('NONE' !== milestone.interval && 1 < milestone.repeatTimes && !shouldRecalculateSeries) {
            const repeated = projectMilestones.filter(projectPhase => projectPhase.repeatId === milestone.repeatId);

            for (const repeatedMilestone of repeated) {
                mappedMilestones.push({
                    ...milestone,
                    ...overwriteValues,
                    repeat: repeatedMilestone.repeat,
                    repeatId: repeatedMilestone.repeatId,
                    repeatTimes: repeatedMilestone.repeatTimes,
                    interval: repeatedMilestone.interval,
                    date: repeatedMilestone.date,
                    _id: repeatedMilestone._id,
                });
            }
        } else {
            overwriteValues.date = formatDate(milestone.date, 'yyyy-MM-dd', false);
            mappedMilestones.push({
                ...milestone,
                ...overwriteValues,
                repeat: false,
            });
        }
        delete milestones.repeatedChildrenIds;
    });
    return mappedMilestones;
};

export const mapPhasesToFE = phases => {
    const mappedPhases = map(phases, phase => ({
        ...phase,
        start: new Date(removeUTCZuluFromDateTimestamp(phase.start)),
        end: new Date(removeUTCZuluFromDateTimestamp(phase.end)),
    }));
    const repeatedPhases = filter(mappedPhases, phase => phase.repeat);
    const notRepeatedPhases = filter(mappedPhases, phase => !phase.repeat);
    const groupedPhases = groupBy(repeatedPhases, 'repeatId');

    const mappedGroupPhases = map(groupedPhases, phases => {
        const toEdit = head(sortBy(phases, 'start'));
        toEdit.repeatedChildrenIds = phases.map(phase => phase._id);
        return toEdit;
    });

    return values(mappedGroupPhases).concat(notRepeatedPhases);
};

export const mapMilestonesToFE = milestones => {
    const mappedMilestones = map(milestones, milestone => ({
        ...milestone,
        date: new Date(removeUTCZuluFromDateTimestamp(milestone.date)),
    }));
    const repeatedMilestones = filter(mappedMilestones, milestone => milestone.repeat);
    const notRepeatedMilestones = filter(mappedMilestones, milestone => !milestone.repeat);
    const groupedMilestones = groupBy(repeatedMilestones, 'repeatId');

    const mappedGroupMilestones = map(groupedMilestones, milestones => {
        const toEdit = head(sortBy(milestones, 'date'));
        toEdit.repeatedChildrenIds = milestones.map(phase => phase._id);
        return toEdit;
    });

    return mappedGroupMilestones.concat(notRepeatedMilestones);
};
