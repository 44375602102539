import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import InfoBar from './InfoBar';
import { store } from '../../store';
import { deleteCategoryTemplate } from 'actions/categoryTemplateActions';

export const categoriesStepKey = 'categories';

const categoriesStepRowElements = [
    {
        name: 'name',
        type: 'text',
        label: 'Work category',
        validator: emptyStringValidator,
        minWidth: '250px',
        placeholders: ['Development', 'Creativity', 'Engineering', 'BAU'],
    },
];

export const CategoriesOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteCategoryTemplate.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    const onResetChangesOnCreatedInt = useCallback(
        rowIndex => {
            onResetChangesOnCreated(rowIndex, {
                name: 'name',
            });
        },
        [onResetChangesOnCreated]
    );

    return (
        <div>
            <div className="mb-4">
                <InfoBar type="info">
                    The work categories can be added later from the Hub Planner Scheduler level.
                </InfoBar>
            </div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={categoriesStepRowElements}
                getValue={key => storedData[`${categoriesStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreatedInt}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${categoriesStepKey}-meta-showValidationErrors`]}
                addMoreLabel="more categories"
                objectName="category"
            />
            {storedData[`${categoriesStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default CategoriesOnboardingStepContent;
