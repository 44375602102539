import { filter, uniqBy, map, without, sortBy } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject, updateItemInArray, getUpdatedCollection } from 'reducers/helpers/updater';
import { getUpdatedProjectGroups } from 'reducers/helpers/projectGroup';
import { arrayToObjectByKey } from '../utils/mappingUtil';

const initialState = {
    isProjectGroupsLoading: false,
    groupsInitialized: false,
    isProjectsLoading: false,
    projectGroups: [],
    projects: [],
    lastCallContext: undefined,
};

const updateProjects = (state, action) =>
    updateObject(state, {
        isProjectsLoading: false,
        projects: sortBy(uniqBy([...state.projects, ...action.payload.projects], '_id'), pro => pro.name.toLowerCase()),
    });

const addProject = (state, action) => {
    const { projectGroupsAdd, project } = action.payload;
    const updatedProjectGroups = getUpdatedProjectGroups(state, [{ projectGroups: projectGroupsAdd, project }]);

    const updatedProjects = getUpdatedCollection({
        initial: state.projects,
        toAddUpdate: [project],
        sortByProp: 'name',
    });

    return updateObject(state, {
        projectGroups: updatedProjectGroups,
        projects: updatedProjects,
    });
};

const addMultipleProjects = (state, action) => {
    const { data } = action.payload;

    if (data && data.length) {
        const projects = data.map(project => ({
            ...project,
            resourcesCount: project.resources?.length ?? 0,
        }));
        const projectGroups = projects.map(project => ({ projectGroups: [], project }));

        const updatedProjectGroups = getUpdatedProjectGroups(state, projectGroups);
        const updatedProjects = getUpdatedCollection({
            initial: state.projects,
            toAddUpdate: projects,
            sortByProp: 'name',
        });

        return updateObject(state, {
            projectGroups: updatedProjectGroups,
            projects: updatedProjects,
        });
    }

    return state;
};

const updateProjectGroup = (state, action) =>
    updateObject(state, {
        projectGroups: updateItemInArray(state.projectGroups, action.payload.projectGroup._id, projectGroup =>
            updateObject(projectGroup, action.payload.projectGroup)
        ),
    });

const updateProject = (state, action) => {
    const { project } = action.payload;

    return updateObject(state, {
        projects: updateItemInArray(state.projects, project._id, projectToUpdate => ({
            ...projectToUpdate,
            ...project,
        })),
    });
};

const removeProject = (state, action) => {
    const currentGroups = map(state.projectGroups, group => {
        const projects = without(group.projects, action.payload.id);

        return {
            ...group,
            projects,
            count: projects.length,
        };
    });

    return updateObject(state, {
        projectGroups: currentGroups,
        projects: filter(state.projects, project => project._id !== action.payload.id),
    });
};

const setProjectGroups = (state, action) =>
    updateObject(state, {
        isProjectGroupsLoading: false,
        groupsInitialized: true,
        projectGroups: action.payload.projectGroups,
        lastCallContext: action.payload.lastCallContext,
    });

const createProjectGroup = (state, action) => {
    const currentGroups = [...state.projectGroups];
    currentGroups.push(action.payload.projectGroup);

    return updateObject(state, {
        projectGroups: currentGroups,
    });
};

const deleteProjectGroup = (state, action) => {
    if (state.projectGroups.length) {
        return updateObject(state, {
            projectGroups: filter(state.projectGroups, group => group._id !== action.payload.id),
        });
    }

    return state;
};

const setProjectsLoader = state =>
    updateObject(state, {
        isProjectsLoading: true,
    });

const setProjectGroupsLoader = state =>
    updateObject(state, {
        isProjectGroupsLoading: true,
    });

const handleGetProjectGroupsStop = state =>
    updateObject(state, {
        isProjectGroupsLoading: false,
    });

const addResourceToProject = (state, action) => {
    const mappedUpdatedProject = arrayToObjectByKey(action.payload.updatedProjects, '_id');

    return {
        ...state,
        projects: (state.projects ?? []).map(project => {
            const updatedProject = mappedUpdatedProject[project._id];

            if (!updatedProject) {
                return project;
            }

            return {
                ...project,
                resources: updatedProject.resources || [],
                resourcesCount: updatedProject.resources?.length || 0,
            };
        }),
    };
};

const handleCreateBookings = (state, action) => {
    const { bookings = [] } = action.payload;

    const resourcesToAdd = bookings.reduce((acc, booking) => {
        if (!acc[booking.project._id]) {
            acc[booking.project._id] = [];
        }

        acc[booking.project._id].push(booking.resource._id);

        return acc;
    }, {});

    return {
        ...state,
        projects: (state.projects ?? []).map(project => {
            const resourcesToAddForProject = resourcesToAdd[project._id];

            if (!resourcesToAdd[project._id]) {
                return project;
            }

            const newResources = Array.from(new Set([...(project.resources || []), ...resourcesToAddForProject]));

            return {
                ...project,
                resources: newResources || [],
                resourcesCount: newResources?.length || 0,
            };
        }),
    };
};

export default createReducer(initialState, {
    [actionTypes.GET_PROJECT_GROUP_PROJECTS['REQUEST']]: setProjectsLoader,
    [actionTypes.GET_PROJECT_GROUP_PROJECTS['SUCCESS']]: updateProjects,
    [actionTypes.GET_PROJECT_GROUPS['REQUEST']]: setProjectGroupsLoader,
    [actionTypes.GET_PROJECT_GROUPS['SUCCESS']]: setProjectGroups,
    [actionTypes.GET_PROJECT_GROUPS_STOP]: handleGetProjectGroupsStop,
    [actionTypes.CREATE_PROJECT_GROUP['SUCCESS']]: createProjectGroup,
    [actionTypes.DELETE_PROJECT_GROUP['REQUEST']]: deleteProjectGroup,
    [actionTypes.UPDATE_PROJECT_GROUP['SUCCESS']]: updateProjectGroup,
    [actionTypes.CREATE_PROJECT['SUCCESS']]: addProject,
    [actionTypes.DUPLICATE_PROJECT['SUCCESS']]: addProject,
    [actionTypes.CREATE_MULTIPLE_PROJECTS['SUCCESS']]: addMultipleProjects,
    [actionTypes.UPDATE_PROJECT['SUCCESS']]: updateProject,
    [actionTypes.DELETE_PROJECT['SUCCESS']]: removeProject,
    [actionTypes.ADD_RESOURCE_TO_PROJECT['SUCCESS']]: addResourceToProject,
    [actionTypes.CREATE_BOOKING['SUCCESS']]: handleCreateBookings,
});
