import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ListItemText, ListItemAvatar, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from './styles';
import { Check } from '@material-ui/icons';
import MultipleLinesText from 'shared/components/MultipleLinesText';

const useStyles = makeStyles({
    primary: {
        fontSize: '13px',
        color: '#333',
    },
    secondary: {
        fontSize: '12px',
        color: '#a7a7a7',
    },
});

const CustomListItem = props => {
    const {
        icon: Icon,
        children,
        backgroundColor,
        wrapperClassName,
        primaryText,
        secondaryText,
        style,
        avatarSize,
        selected,
        color,
        ...restOfProps
    } = props;
    const classes = useStyles();

    const iconStyle = avatarSize ? { width: avatarSize * 0.5, marginLeft: 0 } : { marginLeft: 0 };

    const primaryNode = <MultipleLinesText lines={2} text={primaryText} notResizeable withEllipsis />;

    return (
        <ListItem {...restOfProps} style={style} className={wrapperClassName}>
            <ListItemAvatar>
                <Avatar
                    style={{ backgroundColor: backgroundColor, width: avatarSize, height: avatarSize, color: color }}
                >
                    {Icon && !React.isValidElement(Icon) ? <Icon style={iconStyle} /> : Icon || ''}
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                classes={{
                    primary: classNames('text-truncate', classes.primary),
                    secondary: classNames('text-truncate', classes.secondary),
                }}
                primary={primaryNode}
                secondary={secondaryText}
            />
            {children && children}
            {selected && <Check />}
        </ListItem>
    );
};

CustomListItem.propTypes = {
    selected: PropTypes.bool,
    backgroundColor: PropTypes.string,
    primaryText: PropTypes.string,
    style: PropTypes.object,
    avatarSize: PropTypes.number,
    secondaryText: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.element]),
    wrapperClassName: PropTypes.string,
};

CustomListItem.defaultProps = {
    selected: false,
    icon: null,
    backgroundColor: '#E6E7ED',
    color: '#4E5252',
    secondaryText: '',
    primaryText: '',
    style: {},
    avatarSize: null,
    wrapperClassName: '',
};

export default CustomListItem;
