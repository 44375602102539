/* eslint-disable */
import moment from 'moment';
import { PARENT, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { CELLDURATION, DAY, WEEK } from 'modules/scheduler/enums/scale';

export default (args, viewObject) => {
    const scheduler = args.control;
    const { scale, companyStartEndTimes } = scheduler;
    const projectID = args.e.data.id;
    let startDate = moment(args.newStart.value);
    let endDate;
    let doExtMove = true;

    const destinationRow = scheduler.rows.find(args.newResource);

    if (destinationRow.tags.parent) {
        // can't drag and event to a parent row.
        return;
    }

    if (scale === CELLDURATION.value) {
        endDate = moment(args.newEnd.value);
    } else if (scale === DAY.value) {
        endDate = moment(args.newStart.value).endOf('day');
    } else if (scale === WEEK.value) {
        endDate = moment(args.newStart.value)
            .endOf('week')
            .add(1, 'days');
    } else {
        //month
        endDate = moment(args.newStart.value).endOf('month');
    }
    if (scale !== CELLDURATION.value) {
        startDate.hours(companyStartEndTimes.startDay);
        startDate.minutes(companyStartEndTimes.startMinute);
        endDate.hours(companyStartEndTimes.endDay);
        endDate.minutes(companyStartEndTimes.endMinute);
    }

    const resourceId = destinationRow.tags.resourceId;
    const pidDrop = destinationRow.tags.projectId;

    if (
        (scheduler.mode === PARENT.value &&
            (projectID !== pidDrop ||
                destinationRow.tags.parent ||
                destinationRow.tags.isEventRow ||
                destinationRow.tags.menuRow)) ||
        (scheduler.mode === SINGLE.value && viewObject.isProjectView)
    ) {
        doExtMove = false;
    }

    if (doExtMove) {
        const newEvent = com.hubplanner.pages.main.grid.lib.GridEventUtil.createEventData(
            projectID,
            resourceId,
            startDate,
            endDate
        );

        gridController.createAndAddEvent(newEvent);
    }
};
