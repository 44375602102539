import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { findReportSubTypeByRoute, findReportTypeByRoute } from 'modules/report/enums/reportTypeEnum';
import { keys } from 'lodash';

class ReportTypeBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.replaceMap = {
            projects: props.projectPluralKeyWord || '',
            Project: props.projectKeyWord || '',
            Resource: props.resourceKeyWord || '',
            Client: props.customerKeyWord || '',
        };
        this.findRegex = new RegExp(keys(this.replaceMap).join('|'), 'gi');
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.match.params, this.props.match.params);
    }

    render() {
        let display = 'Custom Report';
        let reportType;
        if (this.props.match.params.reportType && 'customReport' !== this.props.match.params.reportType) {
            reportType = findReportTypeByRoute(this.props.match.params.reportType);
            display = reportType ? reportType.display : '';
        }

        if (this.props.match.params.subType && 'custom' !== this.props.match.params.subType) {
            let subType = findReportSubTypeByRoute(reportType || {}, this.props.match.params.subType) || {};
            display = subType.display;
        } else if ('custom' === this.props.match.params.subType) {
            display = 'Custom';
        }

        return <span>{(display || '').replace(this.findRegex, matched => this.replaceMap[matched])}</span>;
    }
}

export default ReportTypeBreadcrumb;
