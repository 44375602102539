import React, { useCallback } from 'react';
import InputsList from './InputsList';
import { emptyStringValidator } from 'utils/formValidators';
import CreatingDataError from './CreatingDataError';
import InfoBar from './InfoBar';
import { deleteResourceGroup } from 'actions/resourceGroupActions';
import { store } from '../../store';

export const resourceGroupsStepKey = 'resourceGroups';

const resourceGroupsStepRowElements = [
    {
        name: 'name',
        type: 'text',
        label: 'Team name',
        validator: emptyStringValidator,
        minWidth: '250px',
        placeholders: ['Developers Team', 'Designers Team', 'Marketing Team', 'US Team', 'UK Team'],
    },
];

export const ResourceGroupsOnboardingStepContent = React.memo(props => {
    const { initialNumberOfRows, storedData, handlers } = props;

    const {
        onValueChange,
        onValidationCheck,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeletedCreated,
        onResetChangesOnCreated,
        onCloseRow,
    } = handlers;

    const onDeleteCreated = useCallback(
        (rowIndex, rowIdPreviouslyCreated) => {
            store.dispatch(
                deleteResourceGroup.request(rowIdPreviouslyCreated, () => {
                    onDeletedCreated(rowIndex, rowIdPreviouslyCreated);
                })
            );
        },
        [onDeletedCreated]
    );

    const onResetChangesOnCreatedInt = useCallback(
        rowIndex => {
            onResetChangesOnCreated(rowIndex, {
                name: 'name',
            });
        },
        [onResetChangesOnCreated]
    );

    return (
        <div>
            <div className="mb-4">
                <InfoBar type="info">The members can be added later from the Hub Planner Scheduler level.</InfoBar>
            </div>
            <InputsList
                initialNumberOfRows={initialNumberOfRows}
                rowElements={resourceGroupsStepRowElements}
                getValue={key => storedData[`${resourceGroupsStepKey}-values-${key}`]}
                getRowIdPreviouslyCreatedByRowIndex={getRowIdPreviouslyCreatedByRowIndex}
                onDeleteCreated={onDeleteCreated}
                onResetChangesOnCreated={onResetChangesOnCreatedInt}
                onValueChange={onValueChange}
                onValidationCheck={onValidationCheck}
                onCloseRow={onCloseRow}
                showValidationErrors={storedData[`${resourceGroupsStepKey}-meta-showValidationErrors`]}
                addMoreLabel="more teams"
                objectName="team"
            />
            {storedData[`${resourceGroupsStepKey}-meta-creatingError`] && <CreatingDataError />}
        </div>
    );
});

export default ResourceGroupsOnboardingStepContent;
