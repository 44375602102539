import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Field, Form, reduxForm, initialize, getFormValues } from 'redux-form';
import { useForm } from 'hooks';
import { usePhases } from 'modules/scheduler/hooks';
import { dateField, materialInputGroupField } from 'shared/formFields';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, getDefaultValues, mapFormToRequest } from './phaseFormSchema';
import SuccessButton from 'shared/buttons/success';
import Repeat from 'shared/repeat';
import ColorPicker from 'shared/colorPicker';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { ObjectID } from 'bson';
import { mapPhasesToBE } from 'services/project';
import { makeProjectByIdFromMap } from '../../../../../../selectors/project';

const FORM_NAME = 'phaseForm';

const interDependentFieldsKeys = Object.entries(formSchema.schema || {})
    .filter(arr => arr[1].validatorInterdependent)
    .map(arr => arr[0]);

const PhaseForm = props => {
    const { defaults, schedulerRef, handleSubmit, submit, onClose, touch } = props;

    const projectSelector = useMemo(() => {
        return makeProjectByIdFromMap(defaults?.projectId);
    }, [defaults?.projectId]);

    const project = useSelector(projectSelector);

    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);
    const { createPhase, updatePhase } = usePhases(schedulerRef, onClose);
    const { start, end } = useSelector(state => state.scheduler.currentSelection);
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);

    const formValuesSelector = useMemo(() => getFormValues(FORM_NAME), []);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);

    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;
    const invalid = !validDataForSchema;

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
        onlyKeys: interDependentFieldsKeys,
    });

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues({ start, end }, defaults)));
    }, [dispatch, start, end, defaults]);

    const onSubmit = useCallback(
        values => {
            const data = mapFormToRequest(values);
            if (defaults.id) {
                // Handling update of phases that were not repeated previously
                if (data.repeat && !defaults.repeat) {
                    const repeatId = new ObjectID().toString();
                    const phase = { ...data, start: new Date(data.start), end: new Date(data.end), repeatId };
                    const mappedPhase = mapPhasesToBE([phase], project?.phases, companyStartEndTimes);
                    if (mappedPhase && 0 < mappedPhase.length) {
                        mappedPhase[0]._id = defaults.id;
                        updatePhase(defaults.id, defaults.projectId, mappedPhase);
                    }
                } else {
                    // Change only one if trying to edit a single phase
                    // Currently no endpoints to change repeated phase
                    updatePhase(defaults.id, defaults.projectId, {
                        ...data,
                        interval: 'NONE',
                        repeatTimes: 0,
                        repeat: false,
                        repeatId: null,
                    });
                }
            } else {
                createPhase(data);
            }
        },
        [
            companyStartEndTimes,
            createPhase,
            defaults.id,
            defaults.projectId,
            defaults.repeat,
            project?.phases,
            updatePhase,
        ]
    );

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row noGutters>
                <Col xs={12}>
                    <Field
                        name="name"
                        className="mb-1"
                        placeholder="E.g. Research Sprint"
                        component={materialInputGroupField}
                        label="Phase Name"
                        variant="outlined"
                        data-cy="scheduler-booking-modern-menu-phase-name"
                    />
                </Col>
                <Col xs={6} className="pr-2 mt-4">
                    <Field
                        name="start"
                        component={dateField}
                        label="Start date"
                        variant="outlined"
                        data-cy="scheduler-booking-modern-menu-phase-start-date"
                        componentDataCyPrefix="scheduler-booking-modern-menu-phase-start-date"
                    />
                </Col>
                <Col xs={6} className="pl-1 mt-4">
                    <Field
                        name="end"
                        component={dateField}
                        label="End date"
                        variant="outlined"
                        data-cy="scheduler-booking-modern-menu-phase-end-date"
                        componentDataCyPrefix="scheduler-booking-modern-menu-phase-end-date"
                        props={{
                            shouldDisableDate: date => {
                                const current = new Date(date).setHours(0, 0, 0, 0);
                                const newStartDate = new Date(formValues.start).setHours(0, 0, 0, 0);

                                return current < newStartDate;
                            },
                        }}
                    />
                </Col>
                <Col xs={10} className="mt-4">
                    <Field
                        name="color"
                        component={materialInputGroupField}
                        label="Color"
                        variant="outlined"
                        data-cy="scheduler-booking-modern-menu-phase-hex-color"
                    />
                </Col>
                <Col xs={2} className="mt-4">
                    <ColorPicker
                        pickerPosition="left"
                        pickerWidth={180}
                        maxHeight={defaults.id ? 160 : 200}
                        color={formValues.color}
                        leftPosition={-187}
                        topPosition={-35}
                        onColorChange={color => updateFields({ color: color.hex })}
                    />
                </Col>
                {/* Show repeat only if new, or existing without repeat, currently we don't have endpoints to choose to update all/future  */}
                {!(defaults.id && defaults.repeat) && (
                    <Col className="mt-3">
                        <Repeat
                            inline={false}
                            removeBorder
                            intervalAsDropdown
                            inputFieldDisabled={'NONE' === formValues.interval?.value}
                        />
                    </Col>
                )}
                <SuccessButton
                    className="w-100 mt-4"
                    disabled={invalid}
                    onClick={submit}
                    dataCy="button--update-phase"
                    name={!defaults.id ? 'Add phase' : 'Update phase'}
                />
            </Row>
        </Form>
    );
};

PhaseForm.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    defaults: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        name: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
};

PhaseForm.defaultProps = {
    onClose: null,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(PhaseForm);
