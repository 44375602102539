import { HOURS_DAY, PERCENTAGE, TOTAL_HOURS } from '../../enums/allocationTypeEnum';

export const getAllocationValuesFromCategory = category => {
    if (!category) {
        return {
            name: PERCENTAGE.valueName,
            value: 100,
        };
    }

    const { state, minutesPerDay, percentAllocation, totalBucketMinutesAllocation } = category;

    switch (state) {
        case HOURS_DAY.state:
            return {
                name: HOURS_DAY.valueName,
                value: minutesPerDay / 60,
            };
        case TOTAL_HOURS.state:
            return {
                name: TOTAL_HOURS.valueName,
                value: totalBucketMinutesAllocation / 60,
            };
        case PERCENTAGE.state:
            return {
                name: PERCENTAGE.valueName,
                value: percentAllocation,
            };
        default:
            return {
                name: PERCENTAGE.valueName,
                value: 100,
            };
    }
};
