/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';

const CustomLink = props => {
    const { to, children, onClick, dataCy, ...restOfProps } = props;
    const history = useHistory();

    return (
        <Link
            data-cy={dataCy}
            to={to}
            {...restOfProps}
            onClick={e => {
                onClick && onClick(e);
                if (e.metaKey || e.ctrlKey) {
                    return;
                }
                e.preventDefault();
                // if there is new code available reload the page on switching routes
                if (window.swUpdate) {
                    window.location = `/main#${to}`;
                    window.location.reload();
                    return;
                }

                return history.push(to);
            }}
        >
            {children}
        </Link>
    );
};

CustomLink.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]).isRequired,
    onClick: PropTypes.func,
};

CustomLink.defaultProps = {
    onClick: null,
};

export default CustomLink;
