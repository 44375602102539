import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { Close } from '@material-ui/icons';
import { isEmpty, uniq } from 'lodash';
import { clearAppliedFilters, removeFilter, setSchedulerFilterItem } from 'actions/schedulerActions';
import { PROJECTS, RESOURCES, schedulerFilters } from 'modules/scheduler/enums/schedulerFilterEnum';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { multiSelectField as MultiSelectField } from 'shared/formFields';
import { mapDataToTags, mapResourceIsPmToTag, mapSmartFiltersToTags } from './mapDataToTags';
import { initializeFilters } from './initializeFilters';
import TagItem from './tags/tagItem';
import GroupComponent from './tags/groupComponent';
import { MultiSelectWrapper, InputGroupIcon } from './../styles';
import useFilterData from 'shared/builder/filters/hooks/useFilterData';
import { useAppKeyWords, useClickOutside } from 'hooks';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { ButtonBase, Popover } from '@material-ui/core';
import FilterList from '@material-ui/icons/FilterList';
import { SearchDropdownFilters } from '../../searchDropdownFilters';
import { withStyles } from '@material-ui/core/styles';
import { filterWithDiacriticsEscape, searchWithDiacriticsEscape } from 'utils/search';

const getIsValueContainedInDPResourcesIds = (ids, value) => {
    return ids[value];
};

const getNestedChildrenIds = (dpResources, viewObject) => {
    return uniq(
        dpResources
            .map(dpResource => {
                return (viewObject.isProjectView ? dpResource.resources : dpResource.projects) || [];
            })
            .flat()
    );
};

const getContextBasedSchedulerFilters = (filters, viewObject, reduxDpResources, initialGroupDpResources) => {
    const dpResourcesIds = reduxDpResources.map(dpResource => dpResource._id);
    const initialDpResourcesIds = initialGroupDpResources.map(dpResource => dpResource._id);

    const checkChildrenIds = window.schedulerRef?.current?.control?.mode === 'PARENT';

    const idsToBeChecked = [
        ...dpResourcesIds,
        ...(checkChildrenIds ? getNestedChildrenIds(reduxDpResources, viewObject) : []),
    ].reduce((acc, id) => {
        acc[id] = true;
        return acc;
    }, {});

    const idsToBeCheckedInitialGroup = [
        ...initialDpResourcesIds,
        ...(checkChildrenIds ? getNestedChildrenIds(initialGroupDpResources, viewObject) : [initialGroupDpResources]),
    ].reduce((acc, id) => {
        acc[id] = true;
        return acc;
    }, {});

    // Filters available depending on context
    return filters.filter(filter => {
        let available = true;

        // Error on getting dpList resources, return true, all filters available
        if (!dpResourcesIds.length) {
            return available;
        }

        const filterValue = filter.value.split('_')[0];
        const isValueContainedInDPResourcesIds = getIsValueContainedInDPResourcesIds(idsToBeChecked, filterValue);

        // 1) exclude booking project filters that are not in the rows list rendered (project view)
        if (available && viewObject.isProjectView && filter.name === 'bookingProjectsEvents') {
            available = isValueContainedInDPResourcesIds;
        }

        // 2) exclude booking resource filters that are not in the rows list rendered (resource view)
        if (available && viewObject.isResourceView && filter.name === 'bookingResourcesUW') {
            available = isValueContainedInDPResourcesIds;
        }

        // 3) exclude row filters resource if in single resource view
        if (available && filter.name === 'resources' && viewObject.isSingleResourceView) {
            available = false;
        }

        // 4) exclude row filters resource if in single resource view
        if (available && filter.name === 'projects' && viewObject.isSingleProjectView) {
            available = false;
        }

        const isValueContainedInInitialDPResourcesIds =
            available && getIsValueContainedInDPResourcesIds(idsToBeCheckedInitialGroup, filterValue);

        // 5) exclude row filters resource that are not in the rows list rendered
        if (available && filter.name === 'resources' && !viewObject.isSingleResourceView) {
            // Check if it is contained in the initial group dp resources ids, not after filtered
            available = isValueContainedInInitialDPResourcesIds;
        }

        // 6) exclude row filters project that are not in the rows list rendered
        if (available && filter.name === 'projects' && !viewObject.isSingleProjectView) {
            // Check if it is contained in the initial group dp resources ids, not after filtered
            available = isValueContainedInInitialDPResourcesIds;
        }

        return available;
    });
};

const HeaderFilter = React.forwardRef((props, schedulerRef) => {
    const dispatch = useDispatch();
    const params = useParams();
    const appKeyWords = useAppKeyWords();
    const viewObject = getViewObject(params);
    const { isProjectView, isResourceView } = viewObject;
    const [allFilters, setAllFilters] = useState([]);
    const [filteredFilters, setFilteredFilters] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [tags, setTags] = useState([]);
    const [toggleFiltersList, setToggleFiltersList] = useState(false);
    const appliedFilters = useSelector(state => state.scheduler.filters);
    const reduxDpResources = useSelector(state => state.scheduler.dpResources);
    const filterData = useFilterData(true, true);

    const [initialDpResourcesGroup, setInitialDpResourcesGroup] = useState(reduxDpResources);

    // Make sure initialDpResourcesGroup are the initial, also when rows filters are applied
    useEffect(() => {
        appliedFilters.resources?.filters?.length === 0 &&
            appliedFilters.projects?.filters?.length === 0 &&
            setInitialDpResourcesGroup(reduxDpResources);
    }, [appliedFilters.projects?.filters?.length, appliedFilters.resources?.filters?.length, reduxDpResources]);

    useEffect(() => {
        let initialFilters = initializeFilters(schedulerFilters, filterData, appKeyWords);
        initialFilters = getContextBasedSchedulerFilters(
            initialFilters,
            viewObject,
            reduxDpResources,
            initialDpResourcesGroup
        );

        const filteredFiltersToSet = [
            ...initialFilters,
            // Add smart filters data. this avoids warnings from library
            ...mapSmartFiltersToTags(appliedFilters).map(appliedFilter => {
                return {
                    ...appliedFilter,
                    title: 'Capacity Finder',
                };
            }),
        ];
        setFilteredFilters(filteredFiltersToSet);
        setAllFilters(initialFilters);
    }, [filterData, appKeyWords, appliedFilters, viewObject, reduxDpResources, initialDpResourcesGroup]);

    useEffect(() => {
        const tagsToSet = [
            ...mapDataToTags(appliedFilters, {
                resourceGroups: [],
                projectGroups: [],
                ...filterData,
            }),
            ...mapSmartFiltersToTags(appliedFilters),
            ...mapResourceIsPmToTag(appliedFilters),
        ];
        setTags(tagsToSet);
    }, [appliedFilters, filterData]);

    useEffect(() => {
        if (!searchText || !allFilters.length) {
            return;
        }
        const filters = searchWithDiacriticsEscape(allFilters, searchText, 'label');

        setFilteredFilters(filters);

        if (!filters.length || isEmpty(searchText)) {
            setToggleFiltersList(false);
        }

        if (filters.length && 1 < searchText.length) {
            setToggleFiltersList(true);
        }
    }, [searchText, allFilters]);

    const onChooseFilter = itemFilter => {
        setToggleFiltersList(false);
        setSearchText('');

        const itemFilterValue = itemFilter.value
            ?.replace('_bpe', '')
            ?.replace('_bps', '')
            ?.replace('_bru', '')
            ?.replace('_pm', '')
            ?.replace('_ps', '');

        if (schedulerRef.current.control.mode === PARENT.value) {
            if (isProjectView && itemFilter.name === RESOURCES.filterListProp) {
                schedulerRef.current.control.rows.filter([
                    ...appliedFilters[RESOURCES.filterListProp].filters,
                    itemFilterValue,
                ]);
            }
            if (isResourceView && itemFilter.name === PROJECTS.filterListProp) {
                schedulerRef.current.control.rows.filter([
                    ...appliedFilters[PROJECTS.filterListProp].filters,
                    itemFilterValue,
                ]);
            }
        }
        dispatch(
            setSchedulerFilterItem.request({
                name: itemFilter.name,
                value: itemFilterValue,
                parentValue: itemFilter.parentValue,
            })
        );
    };

    const onFilterType = searchText => {
        setSearchText(searchText.toLowerCase());
        !searchText && setToggleFiltersList(false);
    };

    const onChangeTags = (dataItem, metadata) => {
        if (metadata.action === 'remove' && metadata.dataItem) {
            schedulerRef.current.control.rows.filter([]);

            dispatch(
                removeFilter.request({
                    ...(metadata.dataItem || {}),
                    value: metadata.dataItem?.value
                        ?.replace('_bpe', '')
                        ?.replace('_bps', '')
                        ?.replace('_bru', '')
                        ?.replace('_pm', '')
                        ?.replace('_ps', ''),
                })
            );
        }
    };

    const onClearAppliedFilters = () => {
        schedulerRef.current.control.rows.filter([]);
        dispatch(clearAppliedFilters.request());
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const dropdownFiltersOpen = Boolean(anchorEl);

    const node = useRef();

    const [dropDownSize, setDropDownSize] = useState();

    const handler = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target)) {
                setAnchorEl(null);
            }
        },
        [setAnchorEl]
    );

    useClickOutside(handler);

    useEffect(() => {
        if (node.current?.getBoundingClientRect() && dropdownFiltersOpen) {
            const { left, top, height, width } = node?.current?.getBoundingClientRect();
            setDropDownSize({
                left,
                top,
                height,
                width,
            });
        }
    }, [dropdownFiltersOpen]);

    return (
        <InputGroup>
            <Popover
                id="search-dropdown-filters"
                open={dropdownFiltersOpen}
                anchorEl={anchorEl}
                hideBackdrop
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{
                    position: 'absolute',
                    left: dropDownSize?.left,
                    top: dropDownSize?.top,
                    width: dropDownSize?.width,
                    height: dropDownSize?.height,
                }}
                PaperProps={{
                    style: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    },
                }}
            >
                <div ref={node}>
                    <SearchDropdownFilters />
                </div>
            </Popover>
            <FiltersDropdownButton
                onClick={handleOpen}
                disableRipple
                disableTouchRipple
                data-cy="scheduler-dropdown-filters"
            >
                <FilterList />
            </FiltersDropdownButton>
            <MultiSelectWrapper className="multiselect">
                <MultiSelectField
                    data={filteredFilters}
                    className="border-0"
                    placeholder="Filter the schedule"
                    onSearch={onFilterType}
                    open={toggleFiltersList}
                    onToggle={() => null}
                    textField="label"
                    valueField="value"
                    groupBy="title"
                    onChange={onChangeTags}
                    value={tags}
                    groupComponent={GroupComponent}
                    onSelect={onChooseFilter}
                    tagComponent={TagItem}
                    popupWhenNumberOfItems={7}
                    data-cy="schedule-filter"
                    filter={(item, keyword) => {
                        return filterWithDiacriticsEscape(item, keyword, 'label');
                    }}
                />
            </MultiSelectWrapper>
            {tags?.length ? (
                <InputGroupAddon addonType="append">
                    <InputGroupIcon onClick={onClearAppliedFilters}>
                        <Close />
                    </InputGroupIcon>
                </InputGroupAddon>
            ) : null}
        </InputGroup>
    );
});

const FiltersDropdownButton = withStyles({
    root: {
        backgroundColor: 'white',
        marginRight: '2px',
        padding: `0 16px`,
        borderRadius: `4px 0 0 4px`,
    },
})(ButtonBase);

export default HeaderFilter;
