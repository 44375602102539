import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    displayCurrencyValue,
    displayDateState,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';

/**
 * @param {object} dateState
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (dateState, columnsToLoad, defaultCurrency) => [
    // projectSpecific
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalReportedSpendBetween,
                displayCurrencyValue(row, 'projectSpecificExternalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend To Date
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalReportedSpendToDate,
                displayCurrencyValue(row, 'projectSpecificExternalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Future Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalReportedSpendFuture,
                displayCurrencyValue(row, 'projectSpecificExternalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project All Time Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificExternalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificExternalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificExternalReportedSpendAllTime,
                displayCurrencyValue(row, 'projectSpecificExternalReportedSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalReportedSpendBetween,
                displayCurrencyValue(row, 'projectSpecificInternalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Spend To Date
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalReportedSpendToDate,
                displayCurrencyValue(row, 'projectSpecificInternalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Future Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalReportedSpendFuture,
                displayCurrencyValue(row, 'projectSpecificInternalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project All Time Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'projectSpecificInternalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificInternalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificInternalReportedSpendAllTime,
                displayCurrencyValue(row, 'projectSpecificInternalReportedSpendAllTime', defaultCurrency)
            ),
    },
    // eventSpecific
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalReportedSpendBetween,
                displayCurrencyValue(row, 'eventSpecificExternalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend To Date
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalReportedSpendToDate,
                displayCurrencyValue(row, 'eventSpecificExternalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Future Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalReportedSpendFuture,
                displayCurrencyValue(row, 'eventSpecificExternalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event All Time Spend
                        <p className="sub-title">(reported - ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificExternalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificExternalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificExternalReportedSpendAllTime,
                displayCurrencyValue(row, 'eventSpecificExternalReportedSpendAllTime', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalReportedSpendBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalReportedSpendBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalReportedSpendBetween,
                displayCurrencyValue(row, 'eventSpecificInternalReportedSpendBetween', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Spend To Date
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalReportedSpendToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalReportedSpendToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalReportedSpendToDate,
                displayCurrencyValue(row, 'eventSpecificInternalReportedSpendToDate', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Future Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalReportedSpendFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalReportedSpendFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalReportedSpendFuture,
                displayCurrencyValue(row, 'eventSpecificInternalReportedSpendFuture', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event All Time Spend
                        <p className="sub-title">(reported - int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'eventSpecificInternalReportedSpendAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificInternalReportedSpendAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificInternalReportedSpendAllTime,
                displayCurrencyValue(row, 'eventSpecificInternalReportedSpendAllTime', defaultCurrency)
            ),
    },
];
