import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    removeFilter,
    removeFilters,
    setSchedulerFilterItem,
    setSchedulerFilterItems,
} from '../../../../actions/schedulerActions';
import { Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    DROPDOWN_WIDTH,
    SelectAllStyledCheckBox,
    StyledAccordion,
    StyledAccordionDetails,
    StyledAccordionSummary,
    StyledCheckBox,
    useActiveFilterDotStyles,
    useCommonDropdownFiltersStyles,
} from './styles';
import { useDropdownFiltersContext } from './DropdownFiltersContext';
import { EqualityToggle } from '../../../../shared/components/equalityToggle/EqualityToggle';
import { FixedSizeList } from 'react-window';
import { makeStyles } from '@material-ui/core/styles';
import { SearchTextInput } from '../../../../shared/components/searchTextInput';

const ChildItem = props => {
    const {
        filterName,
        parentFilter,
        searchPredicate = () => true,
        itemLabelRenderer,
        getItemValue,
        childItemLabelRenderer,
        getChildItemValue,
        withSearch,
        childrenSelector,
        filterValue,
        selectedSelector,
        withSelectAll,
        withEqualityToggle,
    } = props;

    const childrenItems = childrenSelector(parentFilter);
    const [filteredChildren, setFilteredChildren] = useState(childrenItems);
    const [filterText, setFilterText] = useState('');
    const { expandedChildAccordion, expandFilter } = useDropdownFiltersContext();
    const selected = useSelector(selectedSelector);
    const commonDropdownFiltersStyles = useCommonDropdownFiltersStyles();
    const classes = useStyles();
    const activeFilterDotStyles = useActiveFilterDotStyles({ nested: true });
    const parentValue = getItemValue(parentFilter);

    const dispatch = useDispatch();

    const handleSearchInputChange = useCallback(filterText => {
        setFilterText(filterText);
    }, []);

    useEffect(() => {
        if (!filterText || !withSearch) {
            setFilteredChildren(childrenItems);
            return;
        }

        const text = filterText.toLowerCase();
        setFilteredChildren(childrenItems.filter(item => searchPredicate(item, text)));
    }, [childrenItems, filterText, searchPredicate, withSearch]);

    const handleChildCheck = useCallback(
        (event, child) => {
            const filterToModify = filterValue
                ? filterValue(child, parentFilter)
                : {
                      name: filterName,
                      parentValue,
                      value: getChildItemValue(child),
                  };

            if (event.target.checked) {
                dispatch(setSchedulerFilterItem.request(filterToModify));
            } else {
                dispatch(removeFilter.request(filterToModify));
            }
        },
        [filterValue, parentFilter, filterName, parentValue, getChildItemValue, dispatch]
    );

    const handleSelectAll = event => {
        const filtersToModify = filteredChildren.map(child => {
            return filterValue
                ? filterValue(child, parentFilter)
                : {
                      name: filterName,
                      parentValue,
                      value: getChildItemValue(child),
                  };
        });

        if (event.target.checked) {
            dispatch(setSchedulerFilterItems(filtersToModify));
        } else {
            dispatch(removeFilters(filtersToModify));
        }
    };

    const handleItemEqualityCheck = (equalityValue, childItem) => {
        const childValue = getChildItemValue(childItem);

        const filterToModify = {
            ...(filterValue
                ? filterValue(childItem, parentFilter)
                : {
                      name: filterName,
                      value: childValue,
                      parentValue,
                  }),
            set: equalityValue,
        };

        dispatch(setSchedulerFilterItem.request(filterToModify));
    };

    const renderRow = props => {
        const { index, style } = props;
        const child = filteredChildren[index];
        const childValue = getChildItemValue(child);

        const selectedItemForGivenRow = selected[parentValue]?.[childValue] || selected[childValue];
        const isSelected = Boolean(selectedItemForGivenRow);

        return (
            <Grid container justifyContent="space-between" alignItems="center" style={style}>
                <Grid item>
                    <StyledCheckBox
                        withEqualityToggle={withEqualityToggle}
                        label={childItemLabelRenderer(child)}
                        checked={isSelected}
                        onChange={event => handleChildCheck(event, child)}
                    />
                </Grid>
                {withEqualityToggle ? (
                    <Grid item>
                        <EqualityToggle
                            disabled={!isSelected}
                            onChange={equalityValue => handleItemEqualityCheck(equalityValue, child)}
                            value={selectedItemForGivenRow?.set}
                        />
                    </Grid>
                ) : null}
            </Grid>
        );
    };

    const noOfSelectedVisibleItems = filteredChildren.reduce(
        (acc, child) =>
            selected[parentValue]?.[getChildItemValue(child)] || selected[getChildItemValue(child)] ? acc + 1 : acc,
        0
    );

    const isFilterActive = childrenItems.reduce(
        (acc, child) =>
            selected[parentValue]?.[getChildItemValue(child)] || selected[getChildItemValue(child)] ? acc + 1 : acc,
        0
    );
    const filteredChildrenItemsCount = filteredChildren.length;

    const allSelected = noOfSelectedVisibleItems === filteredChildrenItemsCount;
    const indeterminate = !allSelected && noOfSelectedVisibleItems !== 0;

    const isExpanded = expandedChildAccordion === getItemValue(parentFilter);

    return (
        <StyledAccordion
            expanded={expandedChildAccordion === getItemValue(parentFilter)}
            onChange={() =>
                expandFilter(
                    isExpanded
                        ? { parentAccordion: filterName }
                        : { parentAccordion: filterName, childAccordion: getItemValue(parentFilter) }
                )
            }
            elevation={0}
            TransitionProps={{ unmountOnExit: true }}
            style={{ margin: '0 0 0 8px' }}
        >
            <StyledAccordionSummary nested expandIcon={<ExpandMoreIcon />}>
                {isFilterActive ? <span className={activeFilterDotStyles.activeFilterDot} /> : null}
                {itemLabelRenderer(parentFilter)}
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Grid container direction="column">
                    {withSearch ? (
                        <Grid item>
                            <div className={classes.searchInputWrapper}>
                                <SearchTextInput onChange={handleSearchInputChange} />
                            </div>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <div className={classes.rowWrapper}>
                            {withSelectAll ? (
                                <SelectAllStyledCheckBox
                                    label="Select All"
                                    indeterminate={indeterminate}
                                    checked={allSelected}
                                    onChange={event => handleSelectAll(event)}
                                />
                            ) : null}
                            <FixedSizeList
                                className={commonDropdownFiltersStyles.fixedList}
                                height={filteredChildren.length > 10 ? 400 : filteredChildren.length * 40}
                                width={DROPDOWN_WIDTH - 92}
                                itemSize={40}
                                itemCount={filteredChildren.length}
                            >
                                {renderRow}
                            </FixedSizeList>
                        </div>
                    </Grid>
                </Grid>
            </StyledAccordionDetails>
        </StyledAccordion>
    );
};

const NestedSelectableList = props => {
    const {
        items,
        filterName,
        searchPredicate,
        itemLabelRenderer,
        getItemValue,
        childItemLabelRenderer,
        getChildItemValue,
        withSearch,
        childrenSelector,
        filterValue,
        selectedSelector,
        withEqualityToggle,
        withSelectAll,
        deletedItemsMapper,
    } = props;

    const classes = useStyles();

    const selected = useSelector(selectedSelector);

    const deletedItems = useMemo(() => {
        if (!deletedItemsMapper) {
            return [];
        }
        return deletedItemsMapper(items, selected);
    }, [deletedItemsMapper, items, selected]);
    
    return (
        <div className={classes.nestedSelectableListContainer}>
            {items.concat(deletedItems).map(item => {
                return (
                    <ChildItem
                        key={getItemValue(item)}
                        parentFilter={item}
                        filterName={filterName}
                        searchPredicate={searchPredicate}
                        itemLabelRenderer={itemLabelRenderer}
                        getItemValue={getItemValue}
                        childItemLabelRenderer={childItemLabelRenderer}
                        getChildItemValue={getChildItemValue}
                        withSearch={withSearch}
                        childrenSelector={childrenSelector}
                        filterValue={filterValue}
                        selectedSelector={selectedSelector}
                        withEqualityToggle={withEqualityToggle}
                        withSelectAll={withSelectAll}
                    />
                );
            })}
        </div>
    );
};

const useStyles = makeStyles({
    nestedSelectableListContainer: {
        width: '100%',
    },
    searchInputWrapper: {
        padding: '14px 16px 23px 16px',
    },
    rowWrapper: {
        paddingLeft: '8px',
    },
});

const memoized = React.memo(NestedSelectableList);

export { memoized as NestedSelectableList };
