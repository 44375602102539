export const GOOGLE_ONLY = 'GOOGLE_ONLY';
export const REGULAR_AND_GOOGLE_ONLY = 'REGULAR_AND_GOOGLE_ONLY';
export const SAML_ONLY = 'SAML_ONLY';

// constants for authentication method that is used for current session
export const AUTH_METHOD_PASSWORD = 'password';
export const AUTH_METHOD_GOOGLE = 'google';
export const AUTH_METHOD_SAML = 'saml';

export const AUTH_MFA_DISABLED = 'NONE';
export const AUTH_MFA_OPTIONAL = 'SOFT';
export const AUTH_MFA_MANDATORY = 'HARD';

export const MFA_STATUS_CODES = {
    NO_KEY: 'A31',
    INCORRECT_OTP: 'A32',
    SESSION_OTP_EXPIRED: 'A36',
    TOO_MANY_MFA_REQUESTS: 'TOO_MANY_MFA_REQUESTS',
};
