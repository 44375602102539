import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import bookingReducer from './bookingReducer';
import companyReducer from './companyReducer';
import accountReducer from './accountReducer';
import companyTree from './companyTreeReducer';
import filterReducer from './filterReducer';
import initialAppReducer from './initialAppReducer';
import modalReducer from './modalReducer';
import requestReducer from './requestReducer';
import resourceReducer from './resourceReducer';
import projectReducer from './projectReducer';
import resourceGroupReducer from './resourceGroupReducer';
import projectGroupReducer from './projectGroupReducer';
import customFieldReducer from './customFieldReducer';
import tagReducer from './tagReducer';
import customerReducer from './customerReducer';
import notificationReducer from './notificationReducer';
import reportReducer from './reportReducer';
import schedulerReducer from './schedulerReducer';
import contextMenuReducer from './contextMenuReducer';
import integrationReducer from './integrationReducer';
import { reducer as formReducer } from 'redux-form';
import content from './menu/content';
import emitters from './menu/emitters';
import billingReducer from './billingReducer';
import categoryGroupReducer from './categoryGroupReducer';
import categoryTemplateReducer from './categoryTemplateReducer';
import { LOGOUT } from 'actions/actionTypes';
import { builderReducer } from '../modules/scheduler/modals/builder/reducer/reducer';
import { costsCategoriesReducer } from '../modules/costsCategories/store';
import { toilReducer } from '../modules/toil/store/reducer';

const reducers = history => ({
    initialAppReducer,
    account: accountReducer,
    companyTree,
    requestReducer,
    bookingReducer,
    reportReducer,
    scheduler: schedulerReducer,
    contextMenu: contextMenuReducer,
    resourceReducer,
    resourceGroupReducer,
    projectGroupReducer,
    customFieldReducer,
    projectReducer,
    companyReducer,
    modalReducer,
    filterReducer,
    tagReducer,
    customerReducer,
    notificationReducer,
    integrationReducer,
    form: formReducer,
    router: connectRouter(history),
    emitters,
    menuContent: content,
    billing: billingReducer,
    categoryGroupReducer,
    categoryTemplateReducer,
    builderReducer,
    costsCategoriesReducer,
    toilReducer,
});

export default history => (state, action) => {
    // on logout clear state
    if (action.type === LOGOUT) {
        state = { router: state.router };
    }
    const appReducer = combineReducers(reducers(history));
    return appReducer(state, action);
};
