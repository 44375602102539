import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Field, Form, reduxForm, initialize, submit } from 'redux-form';
import { useIsExtensionEnabled, useIsExtensionInstalled, useHasRights } from 'hooks';
import { TIME_SHEET, RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { chooseField } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { useAccountPreferences, useFinancialData } from 'modules/scheduler/hooks';
import { Wrapper, Title } from './styles';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';

const FORM_NAME = 'financialOptionsForm';

const rights = [
    {
        rights: ['settingProjectBudget'],
        rule: 'one',
        name: 'hasRightToSeeFinancialData',
    },
];

const FinancialOptions = ({ onUpdate, handleSubmit }) => {
    const dispatch = useDispatch();
    const params = useParams();
    const viewObject = getViewObject(params);
    const { isProjectGroupView } = viewObject;
    const { updateGridPreferences, gridPreferences } = useAccountPreferences();
    const { hasRightToSeeFinancialData } = useHasRights(rights);
    const isResourceRateExtensionInstalled = useIsExtensionInstalled(RESOURCE_RATE);
    const isTSExtensionInstalled = useIsExtensionInstalled(TIME_SHEET);
    const isTSExtensionEnabled = useIsExtensionEnabled(TIME_SHEET);
    const financialRequest = useFinancialData(params, viewObject);
    const onSubmit = values => {
        updateGridPreferences({ singleProjectView: values });
        onUpdate();
    };
    const submitForm = () => {
        dispatch(submit(FORM_NAME));
        financialRequest?.(true);
    };

    useEffect(() => {
        dispatch(initialize(FORM_NAME, gridPreferences.singleProjectView));
    }, [dispatch, gridPreferences]);

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Wrapper className="pt-0">
                <section>
                    <Title className="pt-0">Columns</Title>
                    <p className="text-muted m-0">
                        Scheduled {isTSExtensionInstalled && isTSExtensionEnabled ? 'and Reported ' : ''}Time Columns.
                    </p>
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        label="Scheduled Time / Spend Columns"
                        name="displayScheduledColumn"
                        component={chooseField}
                    />
                    {isTSExtensionInstalled && isTSExtensionEnabled && (
                        <Field
                            wrapperClass="my-2"
                            type="checkbox"
                            inline
                            label="Reported Time / Spend Columns"
                            name="displayReportedColumn"
                            component={chooseField}
                        />
                    )}
                </section>
                <section>
                    <Title>Rows</Title>
                    <p className="text-muted m-0">Toggle the different available information rows.</p>
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        label="Row 1 - Time"
                        name="displayScheduledReportedTimeRow"
                        component={chooseField}
                    />
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        disabled={!hasRightToSeeFinancialData}
                        title={!hasRightToSeeFinancialData ? 'You have no rights for this area.' : ''}
                        label="Row 2 - Spend (External Rates)"
                        name="displayScheduledReportedExternalSpendRow"
                        component={chooseField}
                    />
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        disabled={!isResourceRateExtensionInstalled || !hasRightToSeeFinancialData}
                        inline
                        title={
                            !isResourceRateExtensionInstalled || !hasRightToSeeFinancialData
                                ? 'Resource Rate extension not installed or missing rights for this area'
                                : ''
                        }
                        label="Row 3 - Spend (Internal Rates)"
                        name="displayScheduledReportedInternalSpendRow"
                        component={chooseField}
                    />
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        disabled={!hasRightToSeeFinancialData || isProjectGroupView}
                        title={
                            !hasRightToSeeFinancialData || isProjectGroupView
                                ? 'You have no rights for this area or row is not supported in current view.'
                                : ''
                        }
                        label="Row 4 - Budget (Cash)"
                        name="displayScheduledReportedBudgetCashRow"
                        component={chooseField}
                    />
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        disabled={!hasRightToSeeFinancialData || isProjectGroupView}
                        title={
                            !hasRightToSeeFinancialData || isProjectGroupView
                                ? 'You have no rights for this area or row is not supported in current view.'
                                : ''
                        }
                        label="Row 5 - Budget (Hours)"
                        name="displayScheduledReportedBudgetHoursRow"
                        component={chooseField}
                    />
                </section>
                <section>
                    <Title>Options</Title>
                    <p className="text-muted m-0">Additional options.</p>
                    <Field
                        wrapperClass="my-2"
                        type="checkbox"
                        inline
                        label="Information Always Visible"
                        name="informationPanelDisplayed"
                        component={chooseField}
                    />
                </section>
                <div className="text-center">
                    <SuccessButton onClick={submitForm} className="mt-2" name="Apply" dataCy="button--apple-changes" />
                </div>
            </Wrapper>
        </Form>
    );
};

FinancialOptions.propTypes = {
    onUpdate: PropTypes.func.isRequired,
};

export default reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(FinancialOptions);
