import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline, Close, Email, Autorenew } from '@material-ui/icons';
import { IconButton as MuiIconButton, SvgIcon, Tooltip } from '@material-ui/core';

import TrashIcon from '../../../assets/images/icons/trash.svg';
import EditIcon from '../../../assets/images/icons/edit.svg';
import LockReset from '../../../assets/images/icons/lock_reset.svg';

import AddIcon from '../../../assets/images/icons/add.svg';

const icons = {
    trash: TrashIcon,
    edit: EditIcon,
    close: Close,
    email: Email,
    autorenew: Autorenew,
    addCircleOutline: AddCircleOutline,
    lockReset: LockReset,
    add: AddIcon,
};

export const IconButton = React.memo(({
    icon,
    onClick,
    className,
    tooltipText = '',
    dataCy,
    text,
    disabled,
    textPosition = 'left'
}) => {
    const classes = useStyles({text, textPosition});
    const Icon = icons[icon];

        if (!Icon) {
            return null;
        }

    return (
        <Tooltip title={tooltipText} placement="top" enterDelay={500}>
            <MuiIconButton
                data-cy={dataCy}
                className={classnames(classes.button, className)}
                disableElevation
                disableRipple
                disableFocusRipple
                onClick={onClick}
                disabled={disabled}
            >
                {textPosition === 'right' ? (
                    <>
                        <SvgIcon>
                            <Icon />
                        </SvgIcon>
                        {text}
                    </>
                ) : (
                    <>
                        {text}
                        <SvgIcon>
                            <Icon />
                        </SvgIcon>
                    </>
                )}
            </MuiIconButton>
        </Tooltip>
    );
});

const useStyles = makeStyles({
    button: {
        padding: 0,
        color: 'black',

        '&:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiIconButton-label': {
            fontSize: '13px',
            '& svg': {
                margin: ({ text, textPosition }) => {
                    if (!text) {
                        return 0;
                    }

                    if(textPosition === 'left') {
                        return `0 0 0 10px`;
                    }

                    if(textPosition === 'right') {
                        return `0 10px 0 0`;
                    }

                    return 0;
                },
            },
        },
    },
});
