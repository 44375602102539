import { call, put, select, takeEvery, takeLatest, fork, cancelled } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as actionTypes from 'actions/actionTypes';
import {
    resourceGroupResourcesRequest,
    getResourceGroupsRequest,
    updateResourceGroupRequest,
    createResourceGroupRequest,
    deleteProjectGroupRequest,
    addApproverToGroupsRequest,
    removeApproverFromGroupsRequest,
} from 'api/resourceGroup';
import {
    getResourceGroupResources,
    getResourceGroups,
    updateResourceGroup,
    createResourceGroup,
    deleteResourceGroup,
    addGroupApprovers,
    removeGroupApprovers,
} from 'actions/resourceGroupActions';
import { addNotification } from 'actions/notificationActions';
import { hideModal } from 'actions/modalActions';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import { getCompanySystemGroupIds } from 'selectors/company';
import { getResourceGroups as getResourceGroupsSelector, selectLastCallContext } from 'selectors/resourceGroup';
import { getLoggedInId, getAccountPreferences } from 'selectors/account';
import { makeRequestCancellable } from '../api/makeRequestCancellable';
import { isSchedulerView } from '../utils/isSchedulerView';
import { monitoring } from '../monitoring';

const getRoute = state => state.router;

function* getResourceGroupResource(resourceGroupId) {
    const response = yield call(resourceGroupResourcesRequest, resourceGroupId);

    yield put(getResourceGroupResources.success(response));
}

function* handleGetResourceGroupResources(action) {
    try {
        yield call(getResourceGroupResource, action.payload.id);
    } catch (error) {
        monitoring.captureException(error);
        if (403 === error.status) {
            const resourceId = yield select(getLoggedInId);
            const { myScheduleGroupId } = yield select(getAccountPreferences);
            yield put(push(`/resource/${resourceId}/group/${myScheduleGroupId}`));
        }
    }
}

export function* callResourceGroups() {
    const request = makeRequestCancellable(getResourceGroupsRequest);
    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';
    const resourceGroupResponse = yield call(request.call, schedulerView);
    yield put(getResourceGroups.success(resourceGroupResponse, callContext));
}

function* handleGetResourceGroups() {
    const request = makeRequestCancellable(getResourceGroupsRequest);
    const lastCallContext = yield select(selectLastCallContext);
    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';

    try {
        const resourceGroups = yield select(getResourceGroupsSelector);
        if (0 < resourceGroups.length && lastCallContext === callContext) {
            yield put(getResourceGroups.stop());
            return;
        }
        const resourceGroupResponse = yield call(request.call, schedulerView);

        yield put(getResourceGroups.success(resourceGroupResponse, callContext));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Resource Groups couldn't be loaded", type: 'danger' }));
    } finally {
        if (yield cancelled()) {
            request.cancel();
        }
    }
}

function* handleResourceGroupUpdate(action) {
    try {
        // we do not allow filters to be saved to manual groups. So adding this condition.
        const isManualGroup = !action.payload.data.isSmart;
        if (isManualGroup) {
            action.payload.data.queryParams.filters = [];
        }

        const resourceGroupResponse = yield call(updateResourceGroupRequest, action.payload.data, action.payload.id);
        yield fork(getResourceGroupResource, action.payload.id);

        yield put(updateResourceGroup.success(resourceGroupResponse));
        yield put(hideModal());

        if (action.payload.redirect) {
            yield put(push(`/resource/group/${resourceGroupResponse._id}`));
        }

        action.payload.callback && action.payload.callback(resourceGroupResponse);
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Resource Group couldn't be update", type: 'danger' }));
    }
}

function* handleResourceGroupCreate(action) {
    try {
        // we do not allow filters to be saved to manual groups. So adding this condition.
        const isManualGroup = !action.payload.data.isSmart;
        if (isManualGroup) {
            action.payload.data.queryParams.filters = [];
        }

        const resourceGroupResponse = yield call(createResourceGroupRequest, action.payload.data);
        yield fork(getResourceGroupResource, resourceGroupResponse._id);
        yield put(createResourceGroup.success(resourceGroupResponse));
        yield put(hideModal());
        if (action.payload.redirect) {
            yield put(push(`/resource/group/${resourceGroupResponse._id}`));
        }

        if (action.payload.callback) {
            action.payload.callback(resourceGroupResponse);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Resource Group couldn't be created", type: 'danger' }));
    }
}

function* handleResourceGroupDelete(action) {
    try {
        yield put(hideModal());
        yield call(deleteProjectGroupRequest, action.payload.id);
        const route = yield select(getRoute);
        const systemGroupIds = yield select(getCompanySystemGroupIds);
        const currentUrl = route.location.pathname;
        if (-1 !== currentUrl.indexOf(action.payload.id)) {
            yield put(push(`/resource/group/${systemGroupIds.activeResourceGroupId}`));
            yield put(changeActiveLinkFromLocation());
        }

        action.payload?.cb && action.payload?.cb();

        yield put(deleteResourceGroup.success());
    } catch (error) {
        monitoring.captureException(error);
        yield put(addNotification({ message: "Resource Group couldn't be deleted", type: 'danger' }));
    }
}

function* handleAddApproverToGroups(action) {
    const { resourceId, groupIds } = action.payload;
    try {
        yield call(addApproverToGroupsRequest, resourceId, groupIds);
        yield put(addGroupApprovers.success({ resourceId, groupIds }));
    } catch (error) {
        monitoring.captureException(error);
        yield put(addGroupApprovers.failure({ resourceId, groupIds }));
        yield put(
            addNotification({ message: "Something went wrong... we couldn't updated approvers", type: 'danger' })
        );
    }
}

function* handleRemoveApproverFfomGroups(action) {
    const { resourceId, groupIds } = action.payload;
    try {
        yield call(removeApproverFromGroupsRequest, resourceId, groupIds);
        yield put(removeGroupApprovers.success({ resourceId, groupIds }));
    } catch (error) {
        monitoring.captureException(error);
        yield put(removeGroupApprovers.failure({ resourceId, groupIds }));
        yield put(
            addNotification({ message: "Something went wrong... we couldn't updated approvers", type: 'danger' })
        );
    }
}

export default function* resourceGroupWatcher() {
    yield takeLatest(actionTypes.GET_RESOURCE_GROUP_RESOURCES['REQUEST'], handleGetResourceGroupResources);
    yield takeLatest(actionTypes.GET_RESOURCE_GROUPS['REQUEST'], handleGetResourceGroups);
    yield takeEvery(actionTypes.UPDATE_RESOURCE_GROUP['REQUEST'], handleResourceGroupUpdate);
    yield takeEvery(actionTypes.CREATE_RESOURCE_GROUP['REQUEST'], handleResourceGroupCreate);
    yield takeLatest(actionTypes.DELETE_RESOURCE_GROUP['REQUEST'], handleResourceGroupDelete);
    yield takeLatest(actionTypes.ADD_GROUP_APPROVERS['REQUEST'], handleAddApproverToGroups);
    yield takeLatest(actionTypes.REMOVE_GROUP_APPROVERS['REQUEST'], handleRemoveApproverFfomGroups);
}
