import React from 'react';
import PropTypes from 'prop-types';
import { formatDistanceToNow } from 'date-fns';
import { List, ListItem, ListItemText, ListItemAvatar } from '@material-ui/core';
import Thumbnail from 'shared/thumbnail';
import { formatDate } from 'utils/DateUtil';
import { getTotalTime } from '../../config/events/onBeforeEventRender/booking';
import { makeStyles } from '@material-ui/core/styles';
import { DAY_MONTH_YEAR_DOTTED_FORMAT } from '../../../../global/enums/dateFormat';

const useStyles = makeStyles({
    listItemText: {
        backgroundColor: '#56A379',
        padding: '16px',
        '& .MuiListItemText-primary': {
            color: 'white',
        },
        '& .MuiListItemText-secondary': {
            opacity: '0.7',
            color: 'white',
        },
    },
});

const BookingInfo = props => {
    const { currentSelectedBooking } = props;

    const totalTime = getTotalTime(currentSelectedBooking);
    const classes = useStyles();

    const secondaryText = `${totalTime}, ${formatDate(
        currentSelectedBooking.start,
        DAY_MONTH_YEAR_DOTTED_FORMAT
    )} - ${formatDate(currentSelectedBooking.end, DAY_MONTH_YEAR_DOTTED_FORMAT)}`;

    return (
        <>
            <List>
                <ListItem>
                    <ListItemText
                        className={classes.listItemText}
                        primary={currentSelectedBooking.text}
                        secondary={secondaryText}
                    />
                </ListItem>
                {currentSelectedBooking.bookingCreator ? (
                    <ListItem alignItems="center">
                        <ListItemAvatar>
                            <Thumbnail
                                size={50}
                                thumbnailUrl={currentSelectedBooking.bookingCreator.thumb}
                                firstName={currentSelectedBooking.bookingCreator.firstName}
                                lastName={currentSelectedBooking.bookingCreator.lastName}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${currentSelectedBooking.bookingCreator.firstName} ${currentSelectedBooking.bookingCreator.lastName}`}
                            secondary={`created ${currentSelectedBooking.createdDate &&
                                formatDistanceToNow(currentSelectedBooking.createdDate, {
                                    addSuffix: true,
                                })} on ${formatDate(currentSelectedBooking.createdDate, 'LLLL dd, yyyy HH:mm', false)}`}
                        />
                    </ListItem>
                ) : null}
                {currentSelectedBooking.bookingUpdater && (
                    <ListItem alignItems="center" className="mt-3">
                        <ListItemAvatar>
                            <Thumbnail
                                size={50}
                                thumbnailUrl={currentSelectedBooking.bookingUpdater.thumb}
                                firstName={currentSelectedBooking.bookingUpdater.firstName}
                                lastName={currentSelectedBooking.bookingUpdater.lastName}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`${currentSelectedBooking.bookingUpdater.firstName} ${currentSelectedBooking.bookingUpdater.lastName}`}
                            secondary={`last updated ${currentSelectedBooking.updatedDate &&
                                formatDistanceToNow(currentSelectedBooking.updatedDate, {
                                    addSuffix: true,
                                })} on ${formatDate(currentSelectedBooking.updatedDate, 'LLLL dd, yyyy HH:mm', false)}`}
                        />
                    </ListItem>
                )}
            </List>
        </>
    );
};

BookingInfo.propTypes = {
    currentSelectedBooking: PropTypes.object.isRequired,
};

export default React.memo(BookingInfo);
