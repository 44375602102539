import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal as hideModalAction } from 'actions/modalActions';
import { usePressEscape } from 'hooks';
import NoExtension from 'modules/request/components/noExtension';

export const MissingExtensionModal = ({ extensionShortId, templateContent }) => {
    const dispatch = useDispatch();

    const hideModal = useCallback(() => {
        dispatch(hideModalAction());
    }, [dispatch]);

    usePressEscape(hideModal);


    return (
        <NoExtension
            {...templateContent}
            onCloseClick={hideModal}
            fullScreen
            newVersionShortId={extensionShortId}
        />
    );
};

