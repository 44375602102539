import React from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash';
import { ProgressBar, ProgressBarWrapper } from './styles';

const ProgressBarCell = ({ value }) => {
    const parsedValue = parseFloat(value.replace(/%|$/g, ''));

    return (
        <ProgressBarWrapper>
            <span className="progress-value">{value}</span>
            <ProgressBar value={isNaN(parsedValue) ? 0 : parsedValue} />
        </ProgressBarWrapper>
    );
};

ProgressBarCell.propTypes = {
    value: PropTypes.string.isRequired,
};

export default ProgressBarCell;
