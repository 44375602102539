import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 305px;
    padding: 15px;
`;

export const Title = styled.p`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
`;
