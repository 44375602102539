import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ArrowDropDown } from '@material-ui/icons';
import { Menu, Button } from '@material-ui/core';
import { getContextMenuPosition } from 'utils/browserUtil';
import BookingDropdown from 'modules/scheduler/components/dropdowns/booking';
import ParentDropdown from 'modules/scheduler/components/dropdowns/shared/resourceParent';
import { useStyles } from './../styles';
import { CONTEXT_MENU_WIDTH, CONTEXT_MENU_MAX_HEIGHT } from 'modules/scheduler/components/dropdowns/schedule';
import { selectCurrentSelectedBooking } from '../../../../../../selectors/scheduler';

const Edit = React.forwardRef((props, schedulerRef) => {
    const { windowHeight, windowWidth } = props;
    const [contextPosition, setContextPosition] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const currentSelectedSchedulerEntity = useSelector(selectCurrentSelectedBooking);

    const isRegularBooking =
        currentSelectedSchedulerEntity.id &&
        !currentSelectedSchedulerEntity.phase &&
        !currentSelectedSchedulerEntity.milestone &&
        !currentSelectedSchedulerEntity.datesEvent;

    const isPhMsOrDates =
        currentSelectedSchedulerEntity.phase ||
        currentSelectedSchedulerEntity.milestone ||
        currentSelectedSchedulerEntity.datesEvent;

    const isEditableBooking =
        (isRegularBooking && currentSelectedSchedulerEntity.id && currentSelectedSchedulerEntity.hasEditRights) ||
        isPhMsOrDates;

    const handleClick = event => {
        setContextPosition(
            getContextMenuPosition({ windowHeight, windowWidth }, { top: event.clientY, left: event.clientX })
        );
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                data-cy="scheduler-nav-buttons-edit"
                aria-controls="edit-menu"
                aria-haspopup="true"
                className={classNames({ disabled: !isEditableBooking })}
                disabled={!isEditableBooking}
                onClick={handleClick}
                endIcon={<ArrowDropDown />}
                classes={classes}
                disableRipple
            >
                Edit
            </Button>
            <Menu
                id="edit-menu"
                classes={classes}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {Boolean(anchorEl) &&
                    (isRegularBooking ? (
                        <BookingDropdown
                            form="editBtnContext"
                            schedulerRef={schedulerRef}
                            onClose={handleClose}
                            height={contextPosition.height}
                        />
                    ) : (
                        <ParentDropdown
                            onClose={handleClose}
                            schedulerRef={schedulerRef}
                            height={CONTEXT_MENU_MAX_HEIGHT}
                            width={CONTEXT_MENU_WIDTH}
                        />
                    ))}
            </Menu>
        </>
    );
});

Edit.propTypes = {
    windowHeight: PropTypes.number.isRequired,
    windowWidth: PropTypes.number.isRequired,
};

export default Edit;
