import { includes, keys } from 'lodash';
import Request from 'modules/request/menu';
import Report from 'modules/report/menu';
import Scheduler from 'modules/scheduler/menu';
import Setting from 'modules/setting/menu';

const DEFAULT_MODULE = 'scheduler';
const SUPPORTED_MENUS = {
    requests: Request,
    reports: Report,
    scheduler: Scheduler,
    settings: Setting,
};

class MenuFactory {
    static create(routePath, props) {
        const module =
            !routePath || !includes(keys(SUPPORTED_MENUS), routePath) ? DEFAULT_MODULE : routePath?.toLowerCase();
        const menu = SUPPORTED_MENUS[module];

        switch (module) {
            case 'requests':
                return menu.setProps(props.account, props.companyTree, props.approvees, props.keyWords);
            case 'reports':
                return menu.setProps(props.reportTemplates, props.account, props.keyWords, props.extensions, props.reportsGroups);
            case 'scheduler':
                return menu.setProps(
                    props.account,
                    props.data,
                    props.keyWords,
                    props.extensions,
                    props.currentView,
                    props.options,
                    props.projectStatuses
                );
            case 'settings':
                return menu.setProps(props.keyWords, props.account, props.company, props.billingInfo);
        }
    }
}

export default MenuFactory;
