import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { MoreVert } from '@material-ui/icons';
import { resetScheduler, updateBookingSelection } from 'actions/schedulerActions';
import { useRouteParams } from 'hooks';
import { TextWrapper, ItemContextMenu } from './styles';
import { showSavedScheduleContextMenu } from '../../../../actions/contextMenuActions';

const ScheduleItem = props => {
    const { active, className, classNameActive, activateMe, to, id, label, contextMenu, schedule } = props;

    const dispatch = useDispatch();
    const { resourceGroupId } = useRouteParams();

    const onItemClick = event => {
        if (-1 === id.indexOf(resourceGroupId)) {
            activateMe({ id, to });
            dispatch(resetScheduler.request());
            dispatch(updateBookingSelection.request());
        } else {
            event.preventDefault();
        }
    };

    const showContextMenu = useCallback(() => {
        dispatch(showSavedScheduleContextMenu(schedule));
    }, [dispatch, schedule]);

    return (
        <div className="position-relative">
            <Link
                to={to}
                className={classNames(className, active && classNameActive, 'd-flex pl-3')}
                onClick={onItemClick}
            >
                <TextWrapper className="float-left">
                    <span className="d-block text-truncate" title={label}>
                        {label}
                    </span>
                </TextWrapper>
            </Link>
            {contextMenu && (
                <ItemContextMenu onClick={showContextMenu} data-cy={`menu--schedule-item--context-menu--${id}`}>
                    <MoreVert />
                </ItemContextMenu>
            )}
        </div>
    );
};

ScheduleItem.propTypes = {
    contextMenu: PropTypes.bool,
};

ScheduleItem.defaultProps = {
    contextMenu: false,
};

export default ScheduleItem;
