import Schema from 'form-schema-validation';
import linksAndIconsFields from 'forms/linksAndIconsFields';
import { forEach, find, isNil, isObject } from 'lodash';
import { formatNumber } from 'utils/formatingUtil';
import { getMyCustomFieldsDefaultValues, mapCustomFields } from 'utils/formUtil';
import { startDateValidator, negativeNumberValidator, repeatTimesValidator } from 'utils/formValidators';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { errorMessages } from 'utils/schemaUtil';
import { getAllocationDisplayValue } from 'utils/allocationUtil';
import { getDefaultCategoryTemplate, getDefaultCategoryByProject } from 'modules/categoryGroups/categoryGroupUtil';
import { SCHEDULED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { formatDate, isSameOrAfter, removeUTCZuluFromDateTimestamp, toMidnight } from 'utils/DateUtil';
import { getISODate } from 'shared/lib/date';
import { store } from '../store';
import { startOfDay, endOfDay } from 'date-fns';
import { formatApprovers } from '../modules/scheduler/utils/eventUtil';
import { BOOKING_FORMAT } from '../global/enums/dateFormat';
import { useSelector } from 'react-redux';
import {
    selectCompanyDeadlinesBeforeBookingEndDateConfig,
    selectIsDeadlinesExtensionInstalled,
} from '../selectors/company';
import { useCallback, useMemo } from 'react';
import { getResourceCapacity } from '../utils/capacityCalculation';
import moment from 'moment/moment';
import { allocationTypeMap } from './shortBookingForm';
import { getBookingAllocationValuesFromSingleValue } from '../shared/lib/booking';
import {getAllocationValuesFromCategory} from "../shared/allocation/getAllocationValuesFromCategory";

export const deadlineDateValidator = ({
    deadlinesCompanyConfig,
    isDeadlinesExtensionInstalled,
    validatePartial = false,
}) => (model, schema) => {
    if (!isDeadlinesExtensionInstalled) {
        return;
    }

    if (deadlinesCompanyConfig === 'ENABLED' || (deadlinesCompanyConfig === 'PARTIAL' && !validatePartial)) {
        return;
    }

    if (model.deadlineDate && model.end && !isSameOrAfter(model.deadlineDate, model.end)) {
        schema.setError(
            'deadlineDate',
            validatePartial
                ? `Warning. Deadline is before booking end date`
                : `Deadline can't be before booking end date`
        );
    }
};

export const formSchema = ({ deadlinesCompanyConfig, isDeadlinesExtensionInstalled, validatePartial = false } = {}) => {
    const schema = new Schema(
        {
            project: {
                type: Object,
                required: true,
            },
            bookingColor: {
                type: String,
                required: false,
                defaultValue: '',
            },
            category: {
                type: Object,
                required: false,
            },
            title: {
                type: String,
                required: false,
            },
            start: {
                type: Date,
                required: true,
                defaultValue: startOfDay(new Date()),
                validators: [startDateValidator('end')],
                validatorInterdependent: true,
            },
            end: {
                type: Date,
                required: true,
                defaultValue: endOfDay(new Date()),
            },
            deadlineName: {
                type: Schema.optionalType(String),
                required: false,
                default: null,
            },
            deadlineDate: {
                type: Schema.optionalType(Date),
                required: false,
                default: null,
            },
            externalRateType: {
                type: String,
                required: false,
                defaultValue: 'inherited',
            },
            bookingRate: {
                type: Object,
                required: false,
                defaultValue: { external: {}, internal: {} },
            },
            internalRateType: {
                type: String,
                required: false,
                defaultValue: 'inherited',
            },
            resources: {
                type: Array,
                required: true,
            },
            tasks: {
                type: Array,
                required: false,
            },
            bookingType: {
                type: String,
                required: true,
                defaultValue: 'SCHEDULED',
            },
            note: {
                type: Object,
                required: false,
                defaultValue: { message: '', formattedNote: '' },
            },
            state: {
                type: String,
                required: true,
            },
            percentage: {
                type: Number,
                required: true,
                defaultValue: 100,
                validators: [negativeNumberValidator],
            },
            hours: {
                type: Number,
                required: true,
                validators: [negativeNumberValidator],
            },
            total: {
                type: Number,
                required: true,
                validators: [negativeNumberValidator],
            },
            interval: {
                type: String,
                required: true,
                defaultValue: 'NONE',
            },
            repeatTimes: {
                type: Number,
                required: false,
                defaultValue: 0,
                validators: [repeatTimesValidator],
                validatorInterdependent: true,
            },
            scale: {
                type: String,
            },
            type: {
                type: String,
                required: false,
                defaultValue: SCHEDULED.value,
            },
            billable: {
                type: String,
                required: true,
                defaultValue: 'null',
            },
            ...linksAndIconsFields,
        },
        errorMessages,
        false
    );

    schema.addValidator(
        deadlineDateValidator({ isDeadlinesExtensionInstalled, deadlinesCompanyConfig, validatePartial })
    );

    return schema;
};

export const useValidate = () => {
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const deadlinesCompanyConfig = useSelector(selectCompanyDeadlinesBeforeBookingEndDateConfig);

    const schema = useMemo(() => {
        return formSchema({ isDeadlinesExtensionInstalled, deadlinesCompanyConfig, validatePartial: true });
    }, [deadlinesCompanyConfig, isDeadlinesExtensionInstalled]);

    return useCallback(
        values => {
            return schema.validate(values);
        },
        [schema]
    );
};

// Form schema and redux-form don't sync well if set null, true or false, so convert to string when getting from api, and back when sending to api.
const convertBillableString = billableValue => {
    const mapper = {
        null: null,
        true: true,
        false: false,
    };
    return mapper[billableValue];
};

const convertBillableValueToString = apiBillableValue => {
    if (typeof apiBillableValue === 'boolean') {
        if (apiBillableValue === true) {
            return 'true';
        }
        return 'false';
    }
    // default is null, even if not set
    return 'null';
};

export const getDefaultRateByType = (companyRates, bookingRate, inheritedRate, type) => {
    const rateExists = inheritedRate && !isNil(inheritedRate[type].rateExcludingBookingRate.value);
    const rate = {
        label: rateExists ? 'Default' : 'None',
        rate: rateExists ? inheritedRate[type].rateExcludingBookingRate.value : '',
        currency: rateExists ? inheritedRate[type].rateExcludingBookingRate.currency : '',
    };

    if (bookingRate && bookingRate[type].defaultRateId) {
        const companyRate = find(companyRates, rate => rate._id === bookingRate[type].defaultRateId);
        return {
            ...(companyRate ?? {}),
            label: companyRate?.label,
            rate: companyRate?.rate,
            currency: companyRate?.currency,
        };
    }

    return rate;
};

function mapRates({ rates, externalRateType, internalRateType }) {
    let bookingRate = { external: {}, internal: {} };

    forEach(rates, (rate, key) => {
        const shouldRemoveId = key === 'internal' ? internalRateType === 'inherited' : externalRateType === 'inherited';
        bookingRate[key] = { defaultRateId: shouldRemoveId ? null : rate._id || null };
    });

    return bookingRate;
}

export const getDefaultValues = (companySettings, categoryGroups, categoryTemplates, dataObject = {}) => {
    const {
        resource,
        inheritedRate,
        bookingRate,
        customFields,
        backColor,
        categoryTemplate,
        note,
        links,
        ...data
    } = dataObject;

    const formSchemaDefaultValues = formSchema().getDefaultValues();

    let category = categoryTemplate;
    let bookingColor = backColor || formSchemaDefaultValues.bookingColor;

    // No specific category input from params
    if (!category && data?.project) {
        const reduxState = store.getState();
        // data.project might not have property defaultCategoryTemplate. take updated from store
        const projectFromStore = (reduxState?.projectReducer?.projects || []).find(
            proj => proj._id === data.project._id
        );

        const proj = { ...(projectFromStore || {}), ...data.project };

        const categoryByProject = getDefaultCategoryByProject(proj, categoryTemplates, categoryGroups);

        if (categoryByProject) {
            category = categoryByProject;
        }
    }

    if (!category) {
        category = getDefaultCategoryTemplate(categoryGroups, categoryTemplates);
    }

    const start = data.start ? new Date(removeUTCZuluFromDateTimestamp(data.start)) : formSchemaDefaultValues.start;
    const end = data.end ? new Date(removeUTCZuluFromDateTimestamp(data.end)) : formSchemaDefaultValues.end;

    let allocationValues;
    if (!(data.bookingId || data._id) && resource) {
        // do nothing
        const { workDaysCount, averageWorkDay } = getResourceCapacity(resource, moment(start), moment(end));

        const { value, nameOfValue } = (() => {
            if (companySettings.useCategoriesAllocation) {
                const allocationValuesFromCategory = getAllocationValuesFromCategory(categoryTemplate);

                return {
                    value: allocationValuesFromCategory.value,
                    nameOfValue: allocationValuesFromCategory.name,
                }
            }

            const value = allocationTypeMap[data.state].propertyValueFn(data, averageWorkDay, workDaysCount);

            return {
                value: typeof value === 'string' ? parseFloat(value) : value,
                nameOfValue: allocationTypeMap[data.state].name,
            };
        })();

        allocationValues = getBookingAllocationValuesFromSingleValue({
            value,
            nameOfValue,
            avgDailyCapacity: averageWorkDay,
            numberOfWorkDays: workDaysCount,
        });
    }

    return Object.assign({}, formSchemaDefaultValues, {
        allDay: true,
        ...data,
        billable: convertBillableValueToString(dataObject.billable),
        bookingRate: {
            external: getDefaultRateByType(companySettings.billingRates, bookingRate, inheritedRate, 'external'),
            internal: getDefaultRateByType(companySettings.billingRates, bookingRate, inheritedRate, 'internal'),
        },
        ...links,
        internalRateType: bookingRate && bookingRate.internal.defaultRateId ? 'custom' : 'inherited',
        externalRateType: bookingRate && bookingRate.external.defaultRateId ? 'custom' : 'inherited',
        start: start,
        end: end,
        note: {
            message: note?.message,
            formattedNote:
                note?.formattedNote?.indexOf('{') === 0 ? JSON.parse(note.formattedNote) : note?.formattedNote,
        },
        bookingColor,
        resources: (resource && [resource]) || [],
        customFields: getMyCustomFieldsDefaultValues(customFields),
        category: companySettings.useCategoriesAllocation
            ? {
                  ...category,
                  allocationText: getAllocationDisplayValue(category),
              }
            : category,
        repeatTimes: (data.repeatTimes && data.repeatTimes) || 0,
        percentage: allocationValues?.percentage ?? (!isNil(data.percentage) ? data.percentage : 100),
        hours:
            allocationValues?.hours ??
            ((data.bookingId || data._id) && !isNil(data.minutesPerDay) ? data.minutesPerDay / 60 : data.hours),
        total:
            allocationValues?.total ??
            ((data.bookingId || data._id) && !isNil(data.totalBucketMinutesAllocation)
                ? data.totalBucketMinutesAllocation / 60
                : data.total),
        useProjectColor: (data || {}).hasOwnProperty('useProjectColor') ? data.useProjectColor : true,
        deadlineName: data.deadlineName,
        deadlineDate: data.deadlineDate
            ? new Date(removeUTCZuluFromDateTimestamp(data.deadlineDate))
            : data.deadlineDate,
    });
};

export const mapFormToRequest = ({ values, customFieldTemplates, defaultApprovers }) => {
    let bookings = [];
    const bookingRate = mapRates({
        rates: values.bookingRate,
        externalRateType: values.externalRateType,
        internalRateType: values.internalRateType,
    });

    const data = {
        billable: convertBillableString(values.billable),
        project: values.project._id || values.project.id,
        resource: values.resource?._id || values.resource?.id,
        title: values.title,
        note: values.note && {
            formattedNote:
                values?.note?.formattedNote && typeof values.note.formattedNote === 'object'
                    ? JSON.stringify(values?.note?.formattedNote)
                    : values?.note?.formattedNote || '',
            message: values.note.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' ') || '',
        },
        bookingRate,
        start:
            values.start instanceof Date
                ? getISODate(values.start, {
                      computeTimezone: true,
                  })
                : values.start,
        end:
            values.end instanceof Date
                ? getISODate(values.end, {
                      computeTimezone: true,
                  })
                : values.end,
        state: values.state,
        customFields: values.customFields && mapCustomFields(values.customFields, customFieldTemplates),
        percentAllocation: formatNumber(values.percentage),
        minutesPerDay: formatNumber(values.hours * 60),
        totalBucketMinutesAllocation: formatNumber(values.total * 60),
        interval: values.interval,
        scale: values.scale,
        repeatTimes: 'NONE' !== values.interval ? formatNumber(values.repeatTimes) : 0,
        repeat: 'NONE' !== values.interval,
        categoryTemplateId: (typeof values.category === 'string' ? values.category : values.category?._id) || undefined,
        type: values.type,
        allDay: values.scale !== CELLDURATION.BEValue,
        links: {
            iconLink1: values.iconLink1,
            iconLink2: values.iconLink2,
            iconLink3: values.iconLink3,
            iconLink4: values.iconLink4,
            iconLink5: values.iconLink5,
            link1: values.link1,
            link2: values.link2,
            link3: values.link3,
            link4: values.link4,
            link5: values.link5,
        },
        ...(values.type === WAITING_FOR_APPROVAL.value && isObject(values.resource)
            ? {
                  approvalInfo: {
                      approvers: formatApprovers(defaultApprovers || values.resource.defaultApproverIds),
                  },
              }
            : {}),
        deadlineName: values.deadlineName,
        deadlineDate:
            values.deadlineDate instanceof Date
                ? formatDate(toMidnight(values.deadlineDate), BOOKING_FORMAT, false)
                : values.deadlineDate,
        useProjectColor: values.useProjectColor
    };

    const categorySetting = store.getState().companyReducer.company.settings.grid.catColorDisplayNew;
    const usingCategorySetting = categorySetting === 'CATEGORY_COLOR_BOOKING';

    // Updating a booking when using CATEGORY_COLOR_BOOKING is ON results in changing the color of booking with one of category in DB.
    if (!usingCategorySetting) {
        data.backgroundColor = values.useProjectColor
            ? // Booking uses project color. This booking has empty color
              ''
            : values.bookingColor;
        data.backColor = data.backgroundColor;
    }

    if (values.resources && values.resources.length) {
        forEach(values.resources, resource => {
            bookings.push({ ...data, resource: resource._id });
        });
    } else {
        bookings.push(data);
    }

    return {
        bookings,
        tasks: values.tasks,
    };
};
