import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'actions/actionTypes';
import { addNotification } from 'actions/notificationActions';
import { getGeoLocation, sendInviteEmail, sendInviteEmails, sendResetPasswordEmail } from 'actions/generalActions';
import { hideModal } from 'actions/modalActions';
import {
    sendBug,
    sendContact,
    getGeoLocationRequest,
    sendInviteEmailRequest,
    sendResetPasswordEmailRequest,
    sendInviteEmailsRequest,
} from 'api/general';
import { monitoring } from '../monitoring';

function* sendBugRequest(action) {
    try {
        yield call(sendBug, action.payload.data);

        yield put(
            addNotification({
                html: true,
                message: '<strong>Bug Sent</strong> Thank you, we will investigate soon!',
                type: 'success',
            })
        );
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... Please check if you filled all required fields',
                type: 'danger',
            })
        );
    }
}

function* sendContactRequest(action) {
    try {
        yield call(sendContact, action.payload.data);

        yield put(
            addNotification({
                html: true,
                message: '<strong>Message Sent</strong> Thank you, we will be in touch soon!',
                type: 'success',
            })
        );
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... Please check if you filled all required fields',
                type: 'danger',
            })
        );
    }
}

export function* handleGeoLookupRequest() {
    try {
        const geoLocation = yield call(getGeoLocationRequest);
        yield put(getGeoLocation.success(geoLocation));

        return geoLocation;
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... (Localization request)',
                type: 'danger',
            })
        );
    }
}

function* handleSendInviteEmail(action) {
    const { resourceId } = action.payload;

    try {
        yield call(sendInviteEmailRequest, resourceId);
        yield put(sendInviteEmail.success());
    } catch (error) {
        yield put(sendInviteEmail.failure());
        monitoring.captureException(error);
    }
}

function* handleSendInviteEmailSuccess() {
    yield put(
        addNotification({
            message: `Email was sent`,
            type: 'success',
        })
    );
}

function* handleSendInviteEmailFailure() {
    yield put(
        addNotification({
            message: `Something went wrong... we couldn't send email`,
            type: 'danger',
        })
    );
}

function* handleSendInviteEmails(action) {
    const { resourceIds = [] } = action.payload;

    try {
        yield call(sendInviteEmailsRequest, resourceIds);
        yield put(sendInviteEmails.success());
    } catch (error) {
        yield put(sendInviteEmails.failure());
        monitoring.captureException(error);
    }
}

function* handleSendInviteEmailsSuccess() {
    yield put(
        addNotification({
            message: `Emails were sent`,
            type: 'success',
        })
    );
}

function* handleSendInviteEmailsFailure() {
    yield put(
        addNotification({
            message: `Something went wrong... we couldn't send emails`,
            type: 'danger',
        })
    );
}

function* handleSendResetPasswordEmail(action) {
    const { resourceId } = action.payload;

    try {
        yield call(sendResetPasswordEmailRequest, resourceId);
        yield put(sendResetPasswordEmail.success());
    } catch (error) {
        yield put(sendResetPasswordEmail.failure());
        monitoring.captureException(error);
    }
}

function* handleSendResetPasswordEmailSuccess() {
    yield put(
        addNotification({
            message: `Email was sent`,
            type: 'success',
        })
    );
}

function* handleSendResetPasswordEmailFailure() {
    yield put(
        addNotification({
            message: `Something went wrong... we couldn't send email`,
            type: 'danger',
        })
    );
}

export default function* generalWatcher() {
    yield takeLatest(actionTypes.SEND_BUG_REQUEST, sendBugRequest);
    yield takeLatest(actionTypes.SEND_CONTACT_REQUEST, sendContactRequest);
    yield takeLatest(actionTypes.SEND_INVITE_EMAIL['REQUEST'], handleSendInviteEmail);
    yield takeLatest(actionTypes.SEND_INVITE_EMAIL['SUCCESS'], handleSendInviteEmailSuccess);
    yield takeLatest(actionTypes.SEND_INVITE_EMAIL['FAILURE'], handleSendInviteEmailFailure);
    yield takeLatest(actionTypes.SEND_INVITE_EMAILS['REQUEST'], handleSendInviteEmails);
    yield takeLatest(actionTypes.SEND_INVITE_EMAILS['SUCCESS'], handleSendInviteEmailsSuccess);
    yield takeLatest(actionTypes.SEND_INVITE_EMAILS['FAILURE'], handleSendInviteEmailsFailure);
    yield takeLatest(actionTypes.SEND_RESET_PASSWORD_EMAIL['REQUEST'], handleSendResetPasswordEmail);
    yield takeLatest(actionTypes.SEND_RESET_PASSWORD_EMAIL['SUCCESS'], handleSendResetPasswordEmailSuccess);
    yield takeLatest(actionTypes.SEND_RESET_PASSWORD_EMAIL['FAILURE'], handleSendResetPasswordEmailFailure);
}
