import CreateCustomer from './createCustomer';
import { connect } from 'react-redux';
import { submit, isValid } from 'redux-form';
import { createCustomer } from 'actions/customerActions';
import { formSchema } from 'forms/customerForm';

export default connect(
    state => ({
        valid: isValid('customerForm')(state),
        initialValues: formSchema.getDefaultValues(),
    }),
    (dispatch, ownProps) => ({
        onSubmit: data => dispatch(createCustomer.request(data, ownProps.formName, ownProps.fieldName)),
        submitForm: () => dispatch(submit('customerForm')),
    })
)(CreateCustomer);
