import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { includes } from 'lodash';
import styled from 'styled-components';
import { CheckCircle, Clear, KeyboardArrowRight, Dashboard } from '@material-ui/icons';
import SuccessButton from 'shared/buttons/success';
import { Col } from 'reactstrap';
import { REJECTED, APPROVED } from 'enums/bookingTypeEnum';
import { ListItem } from './../../../lists/styles';
import { ListItemText, ListItemIcon, TextField, ListItemAvatar } from '@material-ui/core';
import { useHandleApproveOrReject } from 'modules/scheduler/hooks';
import { getRequestUrls } from 'modules/scheduler/utils/getRequestUrls';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import classNames from 'classnames';
import { ActionItemsRow } from '../../shared/styles';

export const IconContainer = styled(ListItemIcon)`
    position: relative;
`;

const ApprovalActions = props => {
    const { onClick, currentSelectedBooking } = props;
    const handleApproveOrReject = useHandleApproveOrReject(onClick, currentSelectedBooking);

    return (
        <ActionItemsRow className={classNames('no-gutters text-center mt-2')}>
            <Col style={{ padding: '0px 5px 0px 10px' }}>
                <SuccessButton
                    onClick={() => handleApproveOrReject(REJECTED.value)}
                    data-cy="button--reject"
                    type="btn-secondary"
                    className="w-100"
                    name="Reject"
                />
            </Col>
            <Col style={{ padding: '0px 10px 0px 5px' }}>
                <SuccessButton
                    onClick={() => handleApproveOrReject(APPROVED.value)}
                    data-cy="button--approve"
                    type="btn-primary"
                    className="w-100"
                    name="Approve"
                />
            </Col>
        </ActionItemsRow>
    );
};

export const ApproveWithNote = props => {
    const { onClick } = props;
    return (
        <ListItem divider onClick={onClick} className={classNames('pt-3 pb-3')}>
            <ListItemAvatar style={{ width: 42 }}>
                <CheckCircle style={{ width: 30, height: 30, color: '#4E5252' }} />
            </ListItemAvatar>
            <ListItemText primary="Approve with a note" data-cy="button--approve-with-note" />
            <KeyboardArrowRight className="navigate-right-icon" />
        </ListItem>
    );
};

const actionsMap = {
    [APPROVED.value]: 'Approve',
    [REJECTED.value]: 'Reject',
};

export const ApproveOrRejectWithNoteSlideContent = props => {
    const { onClick, currentSelectedBooking, type } = props;
    const [note, setNote] = useState('');
    const [touched, setTouched] = useState(false);
    const handleApproveOrReject = useHandleApproveOrReject(onClick, currentSelectedBooking);

    const onChange = useCallback(event => {
        setNote(event.target.value);
        setTouched(true);
    }, []);

    const onSubmit = useCallback(() => {
        handleApproveOrReject(type, {
            approverNote: note,
        });
    }, [handleApproveOrReject, note, type]);

    const submitDisabled = note.length === 0;
    const error = touched && submitDisabled;
    const helperText = error ? 'Please add a note' : undefined;

    return (
        <div className="pl-3 pr-2 pt-3">
            <TextField
                variant="outlined"
                label="Note"
                multiline
                fullWidth
                minRows={4}
                maxRows={8}
                onChange={onChange}
                error={error}
                helperText={helperText}
            />
            <div className="mt-2">
                <SuccessButton
                    onClick={onSubmit}
                    data-cy={`button--mod-menu-with-note-${actionsMap[type]}`}
                    type="btn-primary"
                    name={actionsMap[type] + ' with note'}
                    disabled={submitDisabled}
                />
            </div>
        </div>
    );
};

ApproveOrRejectWithNoteSlideContent.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    currentSelectedBooking: PropTypes.any,
};

export const RejectWithNote = ({ onClick }) => {
    return (
        <ListItem divider onClick={onClick} className={classNames('pt-3 pb-3')}>
            <ListItemAvatar style={{ minWidth: 42 }}>
                <Clear style={{ width: 30, height: 30 }} />
            </ListItemAvatar>
            <ListItemText primary="Reject with a note" data-cy="button--reject-with-note" />
            <KeyboardArrowRight className="navigate-right-icon" />
        </ListItem>
    );
};

export const RequestDashboard = ({ currentSelectedBooking, onClose }) => {
    const { requestUrl, vacationUrl } = getRequestUrls(currentSelectedBooking.resourceInfo._id);
    const { vacationId, sickLeaveId } = useSelector(state => state.companyReducer.company.settings);
    const requestLink = !includes([vacationId, sickLeaveId], currentSelectedBooking.project._id)
        ? requestUrl
        : vacationUrl;
    const dispatch = useDispatch();

    const onClick = () => {
        onClose && onClose();
        window.location.hash = requestLink;
        dispatch(changeActiveLinkFromLocation());
    };

    return (
        <ListItem onClick={onClick} className={classNames('pt-3 pb-3')}>
            <ListItemAvatar style={{ minWidth: 42 }}>
                <Dashboard style={{ width: 30, height: 30 }} />
            </ListItemAvatar>
            <ListItemText primary="Request dashboard" data-cy="button--request-dashboard" />
        </ListItem>
    );
};

ApprovalActions.propTypes = {
    onClick: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    currentSelectedBooking: PropTypes.object.isRequired,
};

export default ApprovalActions;
