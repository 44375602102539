import latinize from 'latinize';
import { get } from 'lodash';

export const filterWithDiacriticsEscape = (item, keyword = '', path) => {
    const itemText = (path ? get(item, path) : item) ?? '';

    return latinize(itemText.toLowerCase()).includes(latinize(keyword.toLowerCase()));
};

export const searchWithDiacriticsEscape = (collection = [], keyword = '', path) =>
    collection.filter(item => filterWithDiacriticsEscape(item, keyword, path));
