import { updateObject } from './updater';
import { updateAvailabilities, updateOverrides } from './resources';

export const optimisticUpdates = [
    {
        shouldUpdate: action =>
            action.payload.body?.companySpecific?.useResourceAvailability !== undefined ||
            action.payload.body?.companySpecific?.customAvailabilities !== undefined,
        doUpdate: (state, action) => {
            return updateObject(state, {
                resources: state.resources.map(resource => {
                    if (resource._id === action.payload.id) {
                        return updateAvailabilities(action, resource);
                    }
                    return resource;
                }),
            });
        },
    },
    {
        shouldUpdate: action => action.payload.body.companySpecific.customAvailabilityOverridesToSave,
        doUpdate: (state, action) => {
            return updateObject(state, {
                resources: (state.resources ?? []).map(resource => {
                    if (resource._id === action.payload.id) {
                        return updateOverrides(action, resource);
                    }

                    return resource;
                }),
            });
        },
    },
];
