import { values } from 'lodash';

export const TIME = {
    display: 'Time',
    value: 'time',
};

export const CURRENCY = {
    display: 'Spend',
    value: 'currency',
};

export const PERCENTAGE = {
    display: 'Percentage',
    value: 'percentage',
};

export const TEXT = {
    display: 'Text',
    value: 'text',
};

export const columnUnitTypes = values({ TIME, CURRENCY, PERCENTAGE });

export const columnUnitTypesIncludingText = values({
    TIME,
    CURRENCY,
    PERCENTAGE,
    TEXT,
});
