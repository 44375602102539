import Schema from 'form-schema-validation';
import { format } from 'date-fns';
import { isMomentDate } from 'utils/formValidators';
import { errorMessages } from 'utils/schemaUtil';
import { removeUTCZuluFromDateObject, removeUTCZuluFromDateTimestamp } from 'utils/DateUtil';

export const formSchema = new Schema(
    {
        start: {
            type: Object,
            required: true,
            validators: [isMomentDate],
        },
        end: {
            type: Object,
            required: true,
            validators: [isMomentDate],
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = ({ start, end, datesEvent, dateReference, isProjectStartDate }) => {
    if (!datesEvent) {
        return { ...formSchema.getDefaultValues(), start, end };
    }

    const parsedDateReference = new Date(removeUTCZuluFromDateTimestamp(dateReference));

    const newStart = isProjectStartDate ? removeUTCZuluFromDateObject(start) : parsedDateReference;
    const newEnd = !isProjectStartDate ? removeUTCZuluFromDateObject(end) : parsedDateReference;

    return { ...formSchema.getDefaultValues(), start: newStart, end: newEnd };
};

export const mapFormToRequest = values => ({
    project: {
        start: format(values.start, 'yyyy-MM-dd'),
        end: format(values.end, 'yyyy-MM-dd'),
        useProjectDuration: true,
    },
});
