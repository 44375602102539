import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set, isDate, format, addHours, differenceInHours, subDays } from 'date-fns';
import { isArray, isNil } from 'lodash';
import { useAppKeyWords, useRouteParams } from 'hooks';
import { REJECTED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { updateClipboard, updateBookingSelection, setSchedulerSelection } from 'actions/schedulerActions';
import { deleteFutureRepeatBookings, deleteAllRepeatBookings, deleteBooking } from 'api/booking';
import {
    createBookings,
    updateBooking,
    updateMultipleBookings,
    deleteBooking as deleteBookingAction,
    splitBooking as splitBookingAction,
    duplicateBooking as duplicateBookingAction,
    bulkCreateBookings as bulkCreateBookingsAction,
    cutBooking,
} from 'actions/bookingActions';
import {
    showInfoModal,
    showRepeatModal,
    showEditRepeatModal,
    hideModal,
    showPasteHourModal,
    showConfirmationModal,
} from 'actions/modalActions';
import { addDays, differenceInDays, formatDate, removeUTCZuluFromDateTimestamp, toMidnight } from 'utils/DateUtil';
import { CELLDURATION, findByValue } from 'modules/scheduler/enums/scale';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { formatBookingToBackend, createEventDataFromCopy } from 'modules/scheduler/utils/eventUtil';
import {
    removeDaypilotEvent,
    removeDaypilotEvents,
    updateDaypilotEvents,
    addDaypilotEvent,
    refreshEvent,
    getViewObject,
    dispatchRefreshBookings,
    eventsIdsMoving,
} from 'modules/scheduler/utils/schedulerUtil';
import { useFinancialData } from './useFinancialData';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import {
    splitBookingIntoMultipleByDays,
    findNewWorkDayEndDate,
    getAllocationStateForSelection,
} from 'shared/lib/booking';
import { store } from '../../../store';
import { getAllocationValuesForNewRequest } from 'modules/request/utils/requestUtil';
import { getResourcesMap } from 'selectors/resource';
import { selectIsDeadlinesExtensionInstalled } from '../../../selectors/company';
import { selectCurrentSelectedBooking } from '../../../selectors/scheduler';

const useUpdateBooking = ({
    useCurrentSelectedBooking,
    companySettings,
    financialRequest,
    getRepeatedBookingIdsToRemove,
    dispatch,
    viewObject,
}) => {

    return useCallback(
        (data, oldEvent, skipFEUpdate = false, options, cb) => {
            // https://hubplanner.atlassian.net/browse/HUB-12003
            // this function had stale data when passed to the daypilots onEventDoubleClicked function
            // currentSelectedBooking could contain previously selected booking
            const currentSelectedBooking = useCurrentSelectedBooking
                ? selectCurrentSelectedBooking(store.getState())
                : undefined;

            const selectedBooking = currentSelectedBooking || {};
            const categorySetting = store.getState().companyReducer.company.settings.grid.catColorDisplayNew;
            const usingCategorySetting = categorySetting === 'CATEGORY_COLOR_BOOKING';
            // Booking has color category, don't pass color (color for booking is the category color);
            if (usingCategorySetting) {
                delete selectedBooking.backgroundColor;
                delete selectedBooking.backColor;
            }

            const bookingTmp = selectedBooking.id ? { ...selectedBooking, ...data } : data;

            if (
                selectedBooking.categoryTemplate &&
                data.categoryTemplateId &&
                selectedBooking.categoryTemplate._id !== data.categoryTemplateId
            ) {
                const categoriesTemplates = store.getState().categoryTemplateReducer?.categoryTemplates || [];

                const foundCategoryTemplate = categoriesTemplates.find(
                    categoryTemplate => categoryTemplate._id === data.categoryTemplateId
                );
                if (foundCategoryTemplate) {
                    bookingTmp.categoryTemplate = foundCategoryTemplate;
                }
            }

            let multiple = [bookingTmp];
            if (bookingTmp?.scale?.BEValue === 'SCALE_HOUR') {
                multiple = splitBookingIntoMultipleByDays(bookingTmp);
            }

            const [booking, ...bookingsToCreate] = multiple;
            const bookingId = booking.id || selectedBooking.id || booking._id || selectedBooking._id;

            try {
                if (booking.type === REJECTED.value) {
                    removeDaypilotEvent(bookingId);
                } else {
                    if (!skipFEUpdate) {
                        updateDaypilotEvents(
                            [
                                {
                                    ...booking,
                                    id: bookingId,
                                },
                            ],
                            companySettings,
                            oldEvent ? [oldEvent] : booking.id ? [booking] : []
                        );
                    }
                }

                const formattedBooking = formatBookingToBackend(booking);

                dispatch(
                    updateBooking.request(bookingId, formattedBooking, options, bookings => {
                        if (booking.type !== REJECTED.value) {
                            financialRequest();
                            if (options.removeRemovedRepeated) {
                                const repeatedBookingsIds = getRepeatedBookingIdsToRemove(
                                    bookings,
                                    window.schedulerRef?.current?.control?.events.list
                                );
                                removeDaypilotEvents(repeatedBookingsIds, window.schedulerRef?.current?.control);
                                dispatchRefreshBookings();
                            }
                        } else if (
                            data.type === REJECTED.value &&
                            data.id === booking.id &&
                            booking.repeatId &&
                            options.removeRemovedRepeated
                        ) {
                            // Remove all repeated requests rejected
                            const repeatedBookingsIds = getRepeatedBookingIdsToRemove(
                                [booking],
                                window.schedulerRef?.current?.control?.events?.list
                            );
                            removeDaypilotEvents(repeatedBookingsIds, window.schedulerRef?.current?.control);
                            dispatchRefreshBookings();
                        }
                        cb && cb();
                    })
                );
                if (options.clearSelection !== false) {
                    dispatch(updateBookingSelection.request());
                }

                // Create other bookings if spliited into multiple
                if (bookingsToCreate.length > 0) {
                    const formattedBookingsToCreate = bookingsToCreate.map(bookingToCreate => {
                        return formatBookingToBackend(bookingToCreate);
                    });
                    dispatch(
                        createBookings.request(formattedBookingsToCreate, [], bookingsCreated => {
                            financialRequest();
                            if (window.schedulerRef?.current?.control) {
                                addDaypilotEvent(bookingsCreated, window.schedulerRef.current.control, viewObject);
                                window.schedulerRef.current.control.clearSelection();
                            }
                        })
                    );
                    dispatch(updateBookingSelection.request());
                }
            } catch (error) {
                console.log('updateBookingRequest, error', error, data);
            }
        },
        [companySettings, useCurrentSelectedBooking, dispatch, financialRequest, getRepeatedBookingIdsToRemove, viewObject]
    );
};

const useDispatchShowBookingModal = ({
    dispatch,
    updateBookingRequest,
    updateBookingsRequests,
    currentSelectedBooking,
    viewObject,
}) => {
    return useCallback(
        (data, oldEvent, skipFEUpdate = false, options = {}, cb) => {
            const shouldConvertToNotRepeated =
                !currentSelectedBooking ||
                (Boolean(currentSelectedBooking?.repeatId) &&
                    data.repeat === currentSelectedBooking?.repeat &&
                    data.interval === currentSelectedBooking?.interval &&
                    data.repeatTimes === currentSelectedBooking?.repeatTimes);

            dispatch(
                showEditRepeatModal(
                    'BOOKING',
                    {
                        updateAll: () =>
                            Array.isArray(data)
                                ? updateBookingsRequests(
                                      data,
                                      oldEvent,
                                      skipFEUpdate,
                                      {
                                          modifyAllRepeated: true,
                                          removeRemovedRepeated: true,
                                          ...options,
                                      },
                                      cb
                                  )
                                : updateBookingRequest(
                                      data,
                                      oldEvent,
                                      skipFEUpdate,
                                      {
                                          modifyAllRepeated: true,
                                          removeRemovedRepeated: true,
                                          ...options,
                                      },
                                      cb
                                  ),
                        updateOne: () =>
                            Array.isArray(data)
                                ? updateBookingsRequests(
                                      data.map(booking => ({
                                          ...booking,
                                          ...(shouldConvertToNotRepeated
                                              ? {
                                                    repeat: false,
                                                    repeatId: null,
                                                    interval: 'NONE',
                                                    repeatTimes: 0,
                                                }
                                              : {}),
                                      })),
                                      oldEvent,
                                      skipFEUpdate,
                                      { createRepeatFromExisting: false, modifyAllRepeated: false },
                                      () => {
                                          if (cb) {
                                              cb();
                                          }
                                          dispatchRefreshBookings();
                                      }
                                  )
                                : updateBookingRequest(
                                      {
                                          ...data,
                                          ...(shouldConvertToNotRepeated
                                              ? {
                                                    repeat: false,
                                                    repeatId: null,
                                                    interval: 'NONE',
                                                    repeatTimes: 0,
                                                }
                                              : {}),
                                      },
                                      oldEvent,
                                      skipFEUpdate,
                                      { createRepeatFromExisting: data.repeat && !shouldConvertToNotRepeated },
                                      () => {
                                          if (cb) {
                                              cb();
                                          }
                                          dispatchRefreshBookings();
                                      }
                                  ),
                        onCancel: () => {
                            const movingEvents = options.allMovingEvents || data;

                            if (Array.isArray(movingEvents)) {
                                movingEvents.forEach((booking, index) => {
                                    if (oldEvent[index] && !oldEvent[index].resource && booking?.resource) {
                                        oldEvent[index].resource = booking?.resource;
                                    }
                                    refreshEvent(
                                        booking.id,
                                        oldEvent[index],
                                        window.schedulerRef?.current?.control,
                                        viewObject
                                    );
                                    eventsIdsMoving.delete(booking.id);
                                });
                            } else {
                                if (oldEvent && !oldEvent.resource && data?.resource) {
                                    oldEvent.resource = data?.resource;
                                }
                                refreshEvent(data.id, oldEvent, window.schedulerRef?.current?.control, viewObject);
                                eventsIdsMoving.delete(data.id);
                            }
                        },
                    },
                    {
                        bookings: data,
                    }
                )
            );
        },
        [currentSelectedBooking, dispatch, updateBookingsRequests, updateBookingRequest, viewObject]
    );
};

const useDispatchShowBookingPasteMessageModal = ({ dispatch, createBookingRequest }) => {
    return useCallback(
        (data, shouldUpdateFinancialData, cb, options, isCutAction) => {
            dispatch(
                showPasteHourModal(
                    {
                        create: () =>
                            createBookingRequest(data, shouldUpdateFinancialData, [], cb, options, isCutAction),
                    },
                    splitBookingIntoMultipleByDays(data).length
                )
            );
        },
        [dispatch, createBookingRequest]
    );
};

export const useBooking = (currentSelectedBooking = {}, onClose) => {
    const dispatch = useDispatch();
    const { projectKeyWord } = useAppKeyWords();
    const params = useRouteParams();
    const viewObject = getViewObject(params);
    const financialRequest = useFinancialData(params, viewObject);
    const { start, end, rowTags } = useSelector(state => state.scheduler.currentSelection);

    const clipboard = useSelector(state => state.scheduler.clipboard);
    const isCutAction = useSelector(state => state.scheduler.isCutAction);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const allResourcesMap = useSelector(getResourcesMap);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);

    const createBookingRequest = useCallback(
        (data, shouldUpdateFinancialData = true, tasks = [], cb, options = {}, isCutAction = false) => {
            const bookings = [];
            (isArray(data) ? data : [data]).forEach(booking => {
                if (booking?.scale === 'SCALE_HOUR') {
                    bookings.push(...splitBookingIntoMultipleByDays(booking));
                } else {
                    if (
                        isNil(booking.minutesPerDay) &&
                        isNil(booking.totalBucketMinutesAllocation) &&
                        isNil(booking.percentAllocation)
                    ) {
                        const resourceInfo = allResourcesMap[data.resource];
                        const allocation = getAllocationValuesForNewRequest(
                            booking.start,
                            booking.end,
                            resourceInfo,
                            companySettings
                        );

                        bookings.push({
                            ...booking,
                            totalBucketMinutesAllocation: allocation.total * 60,
                            minutesPerDay: allocation.hours * 60,
                            percentAllocation: allocation.percentage,
                        });
                    } else {
                        bookings.push(booking);
                    }
                }
            });

            dispatch(
                (isCutAction ? cutBooking : createBookings).request(
                    bookings,
                    tasks || [],
                    bookings => {
                        shouldUpdateFinancialData && financialRequest();
                        if (window.schedulerRef?.current?.control) {
                            addDaypilotEvent(bookings, window.schedulerRef.current.control, viewObject);
                            window.schedulerRef.current.control.clearSelection();
                        }
                        cb && cb();
                    },
                    undefined,
                    options
                )
            );
            dispatch(updateBookingSelection.request());
            onClose && onClose();
        },
        [dispatch, onClose, allResourcesMap, companySettings, financialRequest, viewObject]
    );

    const bulkCreateBookingRequest = useCallback(
        (bookingId, data) => {
            onClose && onClose();

            dispatch(
                bulkCreateBookingsAction.request({
                    bookingId,
                    data,
                })
            );
        },
        [dispatch, onClose]
    );

    const requestProject = useCallback(
        data => {
            let startDate = start;
            let endDate = end;

            if (isDate(startDate) && window.schedulerRef?.current?.control?.scale !== CELLDURATION.value) {
                startDate = set(start, { hours: 0, minutes: 0, seconds: 0 });
            }

            if (isDate(endDate) && window.schedulerRef?.current?.control?.scale !== CELLDURATION.value) {
                endDate = set(end, { hours: 23, minutes: 59, seconds: 59 });
            }

            const resourceInfo = allResourcesMap[data.resource];

            let allocation;
            if (window.schedulerRef?.current?.control.scale === CELLDURATION.value) {
                allocation = getAllocationStateForSelection({ start, end }).values;
            } else {
                allocation = getAllocationValuesForNewRequest(startDate, endDate, resourceInfo, companySettings);
            }

            createBookingRequest({
                start: formatDate(startDate, BOOKING_FORMAT, false),
                end: formatDate(endDate, BOOKING_FORMAT, false),
                project: companySettings.vacationId,
                scale: findByValue(window.schedulerRef?.current?.control?.scale).BEValue,
                state: companySettings.grid.bookingDefaultState.state,
                categoryTemplateId: companySettings.vacationBookingCategoryId,
                percentAllocation: 100,
                totalBucketMinutesAllocation: allocation.total * 60,
                minutesPerDay: allocation.hours * 60,
                type: WAITING_FOR_APPROVAL.value,
                allDay: window.schedulerRef?.current?.control?.scale !== CELLDURATION.value,
                approvalInfo: { approvers: [] },
                ...data,
            });
        },
        [createBookingRequest, start, end, companySettings, allResourcesMap]
    );

    const getRepeatedBookingIdsToRemove = useCallback((bookings = [], list = []) => {
        const repeatId = (bookings[0] || {}).repeatId;

        if (!repeatId) {
            return [];
        }

        const bookingsOnScheduler = list.filter(booking => booking.repeatId === repeatId);
        if (bookingsOnScheduler.length) {
            const bookingsIds = bookings.map(({ _id }) => _id);
            return bookingsOnScheduler.filter(({ id }) => !bookingsIds.includes(id)).map(({ id }) => id);
        }

        return [];
    }, []);

    const updateBookingsRequests = useCallback(
        (bookings, oldEvents, skipFEUpdate = false, options, cb) => {
            try {
                const bookingsToRemove = bookings.filter(({ type }) => type === REJECTED.value);

                if (bookingsToRemove.length) {
                    removeDaypilotEvents(bookingsToRemove, window.schedulerRef?.current?.control);
                }

                if (!skipFEUpdate) {
                    updateDaypilotEvents(
                        bookings.filter(({ type }) => type !== REJECTED.value),
                        companySettings,
                        oldEvents ? oldEvents : []
                    );
                }

                const formattedBookings = bookings.map(booking => ({
                    ...formatBookingToBackend(booking),
                    _id: booking.id,
                }));

                dispatch(
                    updateMultipleBookings.request(formattedBookings, options, bookings => {
                        if (bookingsToRemove.length !== bookings.length) {
                            financialRequest();
                            if (options.removeRemovedRepeated) {
                                const repeatedBookingsIds = getRepeatedBookingIdsToRemove(
                                    bookings,
                                    window.schedulerRef?.current?.control?.events.list
                                );
                                removeDaypilotEvents(repeatedBookingsIds, window.schedulerRef?.current?.control);
                            }
                        }

                        if (cb) {
                            cb();
                        }
                    })
                );
                if (false !== options.clearSelection) {
                    dispatch(updateBookingSelection.request());
                }
            } catch (error) {
                console.log('updateBookingRequest, error', error, bookings);
            }
        },
        [dispatch, companySettings, financialRequest, getRepeatedBookingIdsToRemove]
    );

    const updateBookingRequest = useUpdateBooking({
        useCurrentSelectedBooking: true,
        financialRequest,
        getRepeatedBookingIdsToRemove,
        dispatch,
        companySettings,
        viewObject,
    });

    const updateBookingRequestWithoutGlobalSelectedBooking = useUpdateBooking({
        companySettings,
        financialRequest,
        getRepeatedBookingIdsToRemove,
        dispatch,
        viewObject,
    });

    const dispatchShowBookingModal = useDispatchShowBookingModal({
        updateBookingRequest,
        updateBookingsRequests,
        dispatch,
        currentSelectedBooking,
        viewObject,
    });

    const dispatchShowBookingModalWithoutGlobalSelectedBooking = useDispatchShowBookingModal({
        updateBookingRequest: updateBookingRequestWithoutGlobalSelectedBooking,
        updateBookingsRequests,
        dispatch,
        viewObject,
    });

    const dispatchShowBookingPasteMessageModal = useDispatchShowBookingPasteMessageModal({
        createBookingRequest,
        dispatch,
    });

    const handleResizeBooking = useCallback(
        (data, oldEvent, skipFEUpdate = false, { isResizingAction, ...options } = {}) => {
            // If is repeat and createRepeatFromExisting was not set previously, set it to true
            if (data.repeat && options.createRepeatFromExisting == null) {
                options.createRepeatFromExisting = true;
            }

            if (!options.modifyAllRepeated && isResizingAction && data.repeat) {
                dispatchShowBookingModalWithoutGlobalSelectedBooking(data, oldEvent, skipFEUpdate, options);
            } else {
                updateBookingRequestWithoutGlobalSelectedBooking(data, oldEvent, skipFEUpdate, options);
            }
        },
        [dispatchShowBookingModalWithoutGlobalSelectedBooking, updateBookingRequestWithoutGlobalSelectedBooking]
    );

    /**
     * Options:
     * isMultiMove - Move many bookings at once
     * modifyAllRepeated - Modify all by repeatId
     * removeRemovedRepeated - Remove repeated bookings that are not in array
     * clearSelection - Clear currently selected booking
     * createRepeatFromExisting - Create repeated booking from existing booking
     */
    const handleUpdateRepeatBooking = useCallback(
        (data, oldEvent, skipFEUpdate = false, { isMovingAction, isResizingAction, ...options } = {}) => {
            const foundEvent = (window.schedulerRef?.current?.control?.events?.list || []).find(
                event => event.id === data.id
            );

            if (!oldEvent && data.id && window.schedulerRef?.current?.control?.events?.list) {
                // This create inconsistencies in future checks and flow, so search in scheduler and take its data if not present here.
                if (foundEvent) {
                    oldEvent = { ...foundEvent, resource: foundEvent.resourceInfo._id };
                }
            }

            // If is repeat and createRepeatFromExisting was not set previously, set it to true
            if (data.repeat && options.createRepeatFromExisting == null) {
                options.createRepeatFromExisting = true;
            }

            onClose && onClose();

            if (options.isMultiMove) {
                updateBookingsRequests(data, oldEvent, skipFEUpdate, options);
            } else if (
                !options.updateJustOne &&
                !options.modifyAllRepeated &&
                ((currentSelectedBooking.id && currentSelectedBooking.repeat) ||
                    (isMovingAction && data.repeat) ||
                    (isResizingAction && data.repeat))
            ) {
                // when user double clicks on booking currentSelectedBooking is empty and confirm modal
                // with question to update one/all is not open
                // but when user edit booking via context menu then
                // currentSelectedBooking.id and currentSelectedBooking.repeat
                // contains values and opens confirm modal with question to update one or all
                // so passed from builder additional prop updateJustOne
                dispatchShowBookingModal(data, oldEvent, skipFEUpdate, options);
            } else {
                updateBookingRequest(data, oldEvent, skipFEUpdate, options);
            }
        },
        [
            currentSelectedBooking?.id,
            currentSelectedBooking?.repeat,
            onClose,
            updateBookingsRequests,
            dispatchShowBookingModal,
            updateBookingRequest,
        ]
    );

    const updateClipboardRequest = useCallback(
        shouldRemove => {
            onClose && onClose();
            if (shouldRemove) {
                removeDaypilotEvent(currentSelectedBooking.id);
            }
            dispatch(updateClipboard.request(currentSelectedBooking, shouldRemove));
        },
        [dispatch, onClose, currentSelectedBooking]
    );

    const _deleteBooking = useCallback(
        (id, booking, onDelete) => {
            const removeBookings = bookingIds =>
                removeDaypilotEvents(bookingIds, window.schedulerRef?.current?.control);

            const deleteOneBooking = () => {
                return deleteBooking(id).then(() => {
                    dispatch(deleteBookingAction.success(id));
                    removeBookings([id]);
                    dispatchRefreshBookings();
                });
            };

            const cleanUp = () => {
                if (onDelete) {
                    onDelete();
                }
                deleteOneBooking();
                dispatch(updateBookingSelection.request());
                financialRequest();
            };

            if (booking.repeat) {
                dispatch(
                    showRepeatModal({
                        type: 'BOOKING',
                        booking,
                        actions: {
                            deleteAll: () =>
                                deleteAllRepeatBookings(booking.repeatId).then(ids => {
                                    removeBookings(ids);
                                    dispatchRefreshBookings();
                                    dispatch(hideModal());
                                }),
                            deleteFuture: () =>
                                deleteFutureRepeatBookings(
                                    booking.repeatId,
                                    formatDate(booking.start, 'yyyy-MM-dd', false)
                                ).then(ids => {
                                    removeBookings(ids);
                                    dispatchRefreshBookings();
                                    dispatch(hideModal());
                                }),
                            deleteOne: () =>
                                deleteOneBooking().then(() => {
                                    dispatch(hideModal());
                                }),
                        },
                    })
                );
                return;
            } else if (booking.childIds?.length > 1 && booking.parentIds?.length > 1) {
                dispatch(
                    showConfirmationModal(
                        () => {
                            cleanUp();
                        },
                        'Delete booking',
                        'This booking has multiple parent/child dependencies, after delete, all links will be deleted as well',
                        {
                            withCancel: true,
                            cancelButtonText: 'Cancel',
                            confirmButtonText: 'Yes, delete it',
                        }
                    )
                );

                return;
            }

            cleanUp();
        },
        [dispatch, financialRequest]
    );

    const deleteBookingFromModalRequest = useCallback(
        (bookingId, booking) => {
            _deleteBooking(bookingId, booking, () => {
                dispatch(hideModal());
            });
        },
        [_deleteBooking, dispatch]
    );

    const deleteBookingRequest = useCallback(() => {
        onClose && onClose();
        _deleteBooking(currentSelectedBooking.id, currentSelectedBooking);
    }, [_deleteBooking, currentSelectedBooking, onClose]);

    const duplicateBookingRequest = useCallback(() => {
        onClose && onClose();

        const onBookingDuplicated = booking => {
            financialRequest();

            if (window.schedulerRef?.current?.control) {
                addDaypilotEvent([booking], window.schedulerRef?.current?.control, viewObject, () => {
                    // Not the best approach with setTimeout, but this assures the event is still selected after duplicating it (and table is re-rendered)
                    setTimeout(() => {
                        const elementsWithBookingIdClass = document.getElementsByClassName(
                            `event-id-${currentSelectedBooking.id}`
                        );
                        if (elementsWithBookingIdClass[0]) {
                            //  exact behavior of selecting, focusing the element after duplicating it.
                            elementsWithBookingIdClass[0].click();
                        } else {
                            // select into redux, no three dots. this should never happen but the functionality remains intact
                            dispatch(updateBookingSelection.request(currentSelectedBooking));
                        }
                    }, 0);
                });
            }
        };
        dispatch(duplicateBookingAction.request(currentSelectedBooking.id, onBookingDuplicated));
    }, [onClose, dispatch, currentSelectedBooking, financialRequest, viewObject]);

    const pasteBooking = useCallback(
        domEvent => {
            // Copying on input, not valid for events, abort
            if (clipboard && (domEvent?.target?.tagName || '').toLowerCase() === 'input') {
                return;
            }

            const dpEvent = { ...clipboard };
            const dpEventRow = window.schedulerRef?.current?.control?.rows.find(dpEvent.resource)?.tags;
            const isRegularBooking = !dpEvent.phase && !dpEvent.milestone && !dpEvent.datesEvent && !dpEvent.deadline;

            const cannotPaste =
                !dpEventRow ||
                !isRegularBooking ||
                ((dpEventRow.isProjectRow || dpEventRow.unassignedRow) &&
                    window.schedulerRef?.current?.control === PARENT.value &&
                    viewObject.isProjectGroupView);

            if (cannotPaste || !dpEvent) {
                dispatch(
                    showInfoModal(
                        'You Cannot Paste this Booking',
                        `Sorry, You cannot paste this booking into this row. Please check you are not trying to add a ${projectKeyWord} booking to a ${projectKeyWord} row for example.`
                    )
                );
                return;
            }

            const projectId = (rowTags.project && rowTags.project._id) || dpEvent.project._id;
            const resourceId =
                (rowTags.resource && rowTags.resource._id) || rowTags.resourceId || dpEvent.resourceInfo._id;
            const dpEventStartDate = dpEvent.start.toDate();
            let dpEventEndDate = dpEvent.end.toDate();
            const hoursDiffBetweenStartAndEnd = differenceInHours(dpEventEndDate, dpEventStartDate);

            let newPasteStartDate = start;
            let newPasteEndDate = addHours(start, hoursDiffBetweenStartAndEnd);

            // For 24 hours working days
            if (0 === dpEventEndDate.getHours()) {
                dpEventEndDate = subDays(dpEventEndDate, 1);
            }

            if (window.schedulerRef?.current?.control?.scale === CELLDURATION.value) {
                newPasteEndDate = findNewWorkDayEndDate(
                    addHours(newPasteStartDate, differenceInHours(dpEventEndDate, dpEventStartDate))
                );
            } else if (!dpEvent.allDay && window.schedulerRef?.current?.control?.scale !== CELLDURATION.value) {
                // Fix pasting booking not in hour mode but made in hour mode
                // Timezone fix
                const timezoneOffsetHours = newPasteStartDate.getTimezoneOffset() / 60;
                const fromStartOfDayToStartEventHoursDiff =
                    differenceInHours(addHours(newPasteStartDate, dpEventStartDate.getHours()), newPasteStartDate) +
                    timezoneOffsetHours;
                newPasteStartDate = addHours(newPasteStartDate, fromStartOfDayToStartEventHoursDiff);
                const fromEndToStartHoursDiff = differenceInHours(dpEventEndDate, dpEventStartDate);
                newPasteEndDate = addHours(newPasteStartDate, fromEndToStartHoursDiff);
            }
            const newEvent = createEventDataFromCopy(dpEvent);
            newEvent.start = format(newPasteStartDate, 'yyyy-MM-dd HH:mm:ss');
            newEvent.end = format(newPasteEndDate, 'yyyy-MM-dd HH:mm:ss');
            newEvent.resource = resourceId;
            newEvent.project = projectId;

            if (isDeadlinesExtensionInstalled && dpEvent.deadlineDate) {
                newEvent.deadlineName = dpEvent.deadlineName;
                const diff = differenceInDays(newPasteEndDate, dpEvent.end);

                newEvent.deadlineDate = formatDate(
                    toMidnight(addDays(removeUTCZuluFromDateTimestamp(dpEvent.deadlineDate), diff)),
                    BOOKING_FORMAT,
                    false
                );
            }

            const cb = () => {
                window.schedulerRef?.current?.control?.clearSelection();
                dispatch(setSchedulerSelection.request());
            };

            if (
                window.schedulerRef?.current?.control?.scale === CELLDURATION.value &&
                splitBookingIntoMultipleByDays(newEvent).length > 1
            ) {
                dispatchShowBookingPasteMessageModal(newEvent, !isCutAction, cb, {}, isCutAction);
            } else {
                createBookingRequest(newEvent, !isCutAction, [], cb, {}, isCutAction);
            }
        },
        [
            clipboard,
            viewObject,
            rowTags,
            start,
            isDeadlinesExtensionInstalled,
            dispatch,
            projectKeyWord,
            isCutAction,
            dispatchShowBookingPasteMessageModal,
            createBookingRequest,
        ]
    );

    const splitBooking = useCallback(
        ({ bookingId, splitDate }) => {
            onClose && onClose();
            dispatch(splitBookingAction.request({ bookingId, splitDate }));
        },
        [dispatch, onClose]
    );

    return {
        splitBooking,
        createBooking: createBookingRequest,
        updateBooking: handleUpdateRepeatBooking,
        // updateBookingOnMove: handleMoveBooking,
        updateBookingOnResize: handleResizeBooking,
        deleteBooking: deleteBookingRequest,
        deleteBookingFromModal: deleteBookingFromModalRequest,
        bulkCreateBookings: bulkCreateBookingRequest,
        requestProject,
        pasteBooking,
        duplicateBooking: duplicateBookingRequest,
        updateClipboard: updateClipboardRequest,
    };
};
