import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { includes } from 'lodash';
import BookingRepeat from './partials/repeat';

const Phase = ({ booking, eventHeightMode, startDateFormatted, endDateFormatted, renderMenu }) => (
    <>
        {renderMenu ? <i className="fa fa-ellipsis-v booking-option-dots" title="Options" /> : null}
        <span className="inner-booking-text" title={`${booking.text} - ${startDateFormatted} - ${endDateFormatted}`}>
            <BookingRepeat isRepeat={booking.repeatId && booking.repeat && booking.repeatTimes} />
            <div className="booking-time-name">
                {booking.text}
                <div className={classNames({ 'inner-booking-text-large': includes(['L', 'XL'], eventHeightMode) })}>
                    {startDateFormatted} - {endDateFormatted}
                </div>
            </div>
        </span>
    </>
);

Phase.propTypes = {
    booking: PropTypes.object,
    eventHeightMode: PropTypes.string,
    startDateFormatted: PropTypes.string,
    endDateFormatted: PropTypes.string,
};

Phase.defaultProps = {
    booking: {},
    eventHeightMode: '',
    startDateFormatted: '',
    endDateFormatted: '',
};

export default Phase;
