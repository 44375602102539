import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, FormControlLabel, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, Close, Delete, RotateLeft } from '@material-ui/icons';
import TextField from './TextField';
import Button from './Button';
import { Checkbox } from './checkbox';
import Switch from './Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '40px',
    },
    element: {
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    icon: {
        cursor: 'pointer',
    },
    closeRowIcon: {
        marginTop: '-23px',
        color: '#4E5252',
        marginLeft: '20px',
        '&:hover': {
            color: '#000000',
        },
    },
    additional: {
        marginRight: '43.2px',
    },
    radio: {
        display: 'flex',
        alignItems: 'center',
    },
    notText: {
        marginTop: '-40px',
    },
    columnTitle: {
        marginBottom: '15px',
    },
    hidden: {
        visibility: 'hidden',
    },
    alreadyCreated: {
        color: '#4E5252',
    },
});

const getValidationErrorMessage = (value, validator) => {
    if (validator && !validator?.validator(value)) {
        return validator.errorMessage;
    }
};

export const InputsList = React.memo(props => {
    const {
        initialNumberOfRows,
        rowElements,
        getValue,
        onValueChange,
        showValidationErrors,
        onValidationCheck,
        addMoreLabel,
        getRowIdPreviouslyCreatedByRowIndex,
        onDeleteCreated,
        onResetChangesOnCreated,
        onCloseRow,
        objectName,
    } = props;
    const [numberOfRows, setNumberOfRows] = useState(initialNumberOfRows || 5);

    const classes = useStyles();

    const thereAreChangesOnSpecificRow = useCallback(
        rowIndex => {
            return !!rowElements.find(({ name }) => {
                const keyValue = `${rowIndex}-${name}`;
                return getValue(keyValue) != null;
            });
        },
        [getValue, rowElements]
    );

    const rowsValues = useMemo(() => {
        return new Array(numberOfRows).fill(null).map((v, rowIndex) => {
            return rowElements.map(rowElementSpecs => {
                const { name, validator, type, choices } = rowElementSpecs;

                const keyValue = `${rowIndex}-${name}`;
                const value = getValue(keyValue);

                let validationErrorMessage;

                // validate a row only if that row was 'touched'
                const rowChanged = thereAreChangesOnSpecificRow(rowIndex);
                if (rowChanged) {
                    if (validator) {
                        validationErrorMessage = getValidationErrorMessage(value, validator);
                    } else if (type === 'radio') {
                        validationErrorMessage = getValidationErrorMessage(value, {
                            validator: valueToBeIncluded =>
                                !!(choices || []).find(choice => {
                                    return choice.value === valueToBeIncluded;
                                }),
                            errorMessage: 'Please select a value',
                        });
                    }
                }

                return {
                    ...rowElementSpecs,
                    value,
                    errorMessage: validationErrorMessage,
                    hasError: !!validationErrorMessage,
                    key: keyValue,
                    rowChanged,
                    onChange: e => {
                        if (type === 'checkbox' || type === 'switch') {
                            onValueChange(keyValue, e.target.checked);
                        } else {
                            onValueChange(keyValue, e.target.value);
                        }
                    },
                };
            });
        });
    }, [getValue, numberOfRows, onValueChange, rowElements, thereAreChangesOnSpecificRow]);

    const addRows = useCallback(() => {
        setNumberOfRows(numberOfRows + 5);
    }, [numberOfRows]);

    const onCloseRowInt = useCallback(
        rowIndex => {
            onCloseRow(rowIndex);
            if (!(rowIndex === 0 && numberOfRows === 1)) {
                setNumberOfRows(numberOfRows - 1);
            }
        },
        [numberOfRows, onCloseRow]
    );

    useEffect(() => {
        const validationResult = !!rowsValues.find(rowValues => {
            return !!rowValues.find(({ hasError }) => hasError);
        });
        onValidationCheck && onValidationCheck(validationResult);
    }, [onValidationCheck, rowsValues]);

    const rowElementsJSX = useCallback(
        (rowValues, rowIndex) => {
            const currentRowValues = rowValues.reduce((acc, cv) => {
                return {
                    ...acc,
                    [cv.name]: cv.value,
                };
            }, {});

            return rowValues.map(rowValue => {
                const {
                    key,
                    value,
                    name,
                    type,
                    label,
                    placeholder,
                    choices,
                    errorMessage,
                    hasError,
                    onChange,
                    labels,
                    minWidth,
                    columnTitle,
                    disabled,
                    disabledFn,
                    valueFn,
                    placeholders,
                } = rowValue;

                let componentJSX;

                const valueTmp = valueFn ? valueFn(currentRowValues) : value;
                const placeholderTmp = Array.isArray(placeholders)
                    ? placeholders[rowIndex % placeholders.length]
                    : placeholder;

                if (type === 'text') {
                    componentJSX = (
                        <TextField
                            variant="outlined"
                            label={label}
                            placeholder={placeholderTmp}
                            value={valueTmp || ''}
                            onChange={onChange}
                            error={showValidationErrors ? hasError : undefined}
                            helperText={showValidationErrors ? errorMessage || ' ' : ' '}
                            size="small"
                            minWidth={minWidth}
                            inputLabelProps={{ shrink: true }}
                        ></TextField>
                    );
                } else if (type === 'radio') {
                    componentJSX = (
                        <div>
                            <RadioGroup className={classes.radio} row onChange={onChange} value={value || ''}>
                                {choices.map(choice => {
                                    const { value, label } = choice;
                                    return (
                                        <FormControlLabel
                                            key={`${name}-${value}`}
                                            value={valueTmp}
                                            control={<Radio color="primary" />}
                                            label={label}
                                        />
                                    );
                                })}
                            </RadioGroup>
                            {hasError && showValidationErrors && <FormHelperText error>{errorMessage}</FormHelperText>}
                        </div>
                    );
                } else if (type === 'checkbox') {
                    componentJSX = (
                        <Checkbox
                            disabled={disabled || disabledFn(currentRowValues)}
                            checked={valueTmp || false}
                            label={label}
                            onChange={onChange}
                        />
                    );
                } else if (type === 'switch') {
                    componentJSX = (
                        <Switch
                            checked={valueTmp || false}
                            disabled={disabled || disabledFn(currentRowValues)}
                            color="secondary"
                            label={label}
                            onChange={onChange}
                            labels={labels}
                        />
                    );
                }

                if (componentJSX) {
                    return (
                        <span key={key} className={`${classes.element} ${type !== 'text' && classes.notText}`}>
                            <div className={rowIndex === 0 && columnTitle ? classes.columnTitle : classes.hidden}>
                                {columnTitle}
                            </div>
                            {componentJSX}
                        </span>
                    );
                }
                return null;
            });
        },
        [showValidationErrors, classes.radio, classes.element, classes.notText, classes.columnTitle, classes.hidden]
    );

    return (
        <div>
            {rowsValues.map((rowValues, rowIndex) => {
                const rowIdPreviouslyCreated = getRowIdPreviouslyCreatedByRowIndex
                    ? getRowIdPreviouslyCreatedByRowIndex(rowIndex)
                    : undefined;

                return (
                    <div key={rowIndex}>
                        {rowIdPreviouslyCreated && (
                            <div className="d-flex justify-content-center mb-2">
                                <Typography
                                    className={classes.alreadyCreated}
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                >
                                    * {objectName} previously created
                                </Typography>
                            </div>
                        )}
                        <div className={classes.row}>
                            {rowElementsJSX(rowValues, rowIndex)}
                            {rowIdPreviouslyCreated && onDeleteCreated && (
                                <Tooltip title={`Delete created ${objectName}`} placement="top" arrow>
                                    <Delete
                                        className={`${classes.icon} ${classes.closeRowIcon}`}
                                        onClick={() => onDeleteCreated(rowIndex, rowIdPreviouslyCreated)}
                                    />
                                </Tooltip>
                            )}
                            {rowIdPreviouslyCreated && onResetChangesOnCreated && (
                                <Tooltip title={`Reset changes`} placement="top" arrow>
                                    <RotateLeft
                                        className={`${classes.icon} ${classes.closeRowIcon}`}
                                        onClick={() => onResetChangesOnCreated(rowIndex)}
                                    />
                                </Tooltip>
                            )}
                            {!rowIdPreviouslyCreated && (
                                <Tooltip title="Close row" placement="top" arrow>
                                    <Close
                                        className={`${classes.icon} ${classes.closeRowIcon} ${
                                            onResetChangesOnCreated ? classes.additional : ''
                                        }`}
                                        onClick={() => onCloseRowInt(rowIndex)}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    </div>
                );
            })}
            <div className="mt-3 d-flex justify-content-center">
                <Button type="success-dark" inversed noBorder onClick={addRows}>
                    <Add /> {addMoreLabel || 'more'}
                </Button>
            </div>
        </div>
    );
});

InputsList.propTypes = {
    initialNumberOfRows: PropTypes.number,
    rowElements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    getValue: PropTypes.func.isRequired,
    onValueChange: PropTypes.func,
    onValidationCheck: PropTypes.func,
    showValidationErrors: PropTypes.bool,
    addMoreLabel: PropTypes.string,
    getRowIdPreviouslyCreatedByRowIndex: PropTypes.func,
    objectName: PropTypes.string,
    onDeleteCreated: PropTypes.func,
    onResetChangesOnCreated: PropTypes.func,
    onCloseRow: PropTypes.func,
};

export default InputsList;
