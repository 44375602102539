import styled from 'styled-components';

export const Initials = styled.span`
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    border-radius: 50%;
    width: ${props => props.size}px;
    min-width: ${props => props.size}px;
    height: ${props => props.size}px;
    letter-spacing: 1px;
    margin-right: 6px;
`;

export const Image = styled.img`
    width: ${props => props.size}px;
    min-width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    margin-right: 6px;
`;
