import React from 'react';
import { disableEvent } from 'modules/scheduler/config/events/onBeforeEventRender/helper';
import { hasRole } from 'utils/rightsUtil';
import { renderToString } from 'react-dom/server';
import ProjectDates from '../../../components/scheduler/projectDates';

export const handleDatesEvent = (args, data) => {
    const [schedulerRef, resourceRoleRights] = data;
    const canEdit = hasRole(resourceRoleRights, 'settingProjectDates');

    if (!canEdit) {
        disableEvent(args, schedulerRef.current.control.eventHeightMode);
    }

    args.e.html = renderToString(<ProjectDates renderMenu={canEdit} />);
};
