export const deadlineLinkStylesEnum = {
    line: 'solid',
    dotted: 'dotted',
    dashed: 'dashed',
};

export const defaultDeadlineLinkStyle = deadlineLinkStylesEnum.line;

export const deadlineLinkPositionEnum = {
    aboveBookings: 'Above',
    belowBookings: 'Below',
};

export const defaultDeadlineLinkPosition = deadlineLinkPositionEnum.aboveBookings;

export const defaultDeadlineLineColor = '#000';

export const defaultDeadlineColor = '#000';

export const deadlineDisplayEnum = {
    show: 'show',
    hide: 'hide',
    showForSelectedBooking: 'showForSelected',
};

export const defaultDeadlineDisplay = deadlineDisplayEnum.show;

export const deadlineTooltipDisplayEnum = {
    hide: 'hide',
    showDeadLineDate: 'showDeadLineDate',
    showDeadlineName: 'showDeadlineName',
};

export const defaultDeadlineTooltipDisplay = deadlineTooltipDisplayEnum.showDeadlineName;

export const deadlineOptionsKeys = {
    deadlineLinkColor: 'deadlineLinkColor',
    deadlineLinkStyle: 'deadlineLinkStyle',
    deadlineLinkPosition: 'deadlineLinkPosition',
    deadlineDisplay: 'deadlineDisplay',
    deadlineTooltipDisplay: 'deadlineTooltipDisplay',
};
