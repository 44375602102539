import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, reduxForm, initialize, getFormValues } from 'redux-form';
import { useForm } from 'hooks';
import { Col, Row } from 'reactstrap';
import { materialInputGroupField, dateField } from 'shared/formFields';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, getDefaultValues, mapFormToRequest } from './milestoneFormSchema';
import Repeat from 'shared/repeat';
import ColorPicker from 'shared/colorPicker';
import SuccessButton from 'shared/buttons/success';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { mapMilestonesToBE } from 'services/project';
import { ObjectID } from 'bson';
import { makeProjectByIdFromMap } from '../../../../../../selectors/project';

const FORM_NAME = 'milestoneForm';

const interDependentFieldsKeys = Object.entries(formSchema.schema || {})
    .filter(arr => arr[1].validatorInterdependent)
    .map(arr => arr[0]);

const MilestoneForm = props => {
    const { defaults, schedulerRef, handleSubmit, submit, onClose, touch } = props;
    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);
    const { createMilestone, updateMilestone } = useMilestones(schedulerRef, onClose);
    const start = useSelector(state => state.scheduler.currentSelection.start);

    const formValuesSelector = useMemo(() => getFormValues(FORM_NAME), []);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);

    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;
    const invalid = !validDataForSchema;

    const projectSelector = useMemo(() => {
        return makeProjectByIdFromMap(defaults?.projectId);
    }, [defaults?.projectId]);

    const project = useSelector(projectSelector);

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName: FORM_NAME,
        validationResult,
        onlyKeys: interDependentFieldsKeys,
    });

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues(start, defaults)));
    }, [dispatch, start, defaults]);

    const onSubmit = useCallback(
        values => {
            const data = mapFormToRequest(values);
            if (defaults.id) {
                // Handling update of milestones that were not repeated previously
                if (data.repeat && !defaults.repeat) {
                    const repeatId = new ObjectID().toString();
                    const mappedMilestone = mapMilestonesToBE([{ ...data, repeatId }], project?.milestones, defaults.projectId);
                    if (mappedMilestone && 0 < mappedMilestone.length) {
                        mappedMilestone[0]._id = defaults.id;
                        updateMilestone(defaults.projectId, mappedMilestone);
                    }
                } else {
                    // Change only one if trying to edit a single milestone
                    // Currently no endpoints to change repeated milestone
                    updateMilestone(defaults.projectId, {
                        ...data,
                        _id: defaults.id,
                        interval: 'NONE',
                        repeatTimes: 0,
                        repeat: false,
                        repeatId: null,
                    });
                }
            } else {
                createMilestone(data);
            }
        },
        [createMilestone, defaults.id, defaults.projectId, defaults.repeat, project?.milestones, updateMilestone]
    );

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row noGutters>
                <Col xs={12}>
                    <Field
                        name="name"
                        className="mb-1"
                        label="Milestone name"
                        placeholder="E.g. Main Delivery"
                        variant="outlined"
                        component={materialInputGroupField}
                        data-cy="scheduler-booking-modern-menu-milestone-name"
                    />
                </Col>
                <Col xs={12} className="mt-3">
                    <Field
                        name="date"
                        label="Date"
                        component={dateField}
                        data-cy="scheduler-booking-modern-menu-milestone-date"
                        className="text-left w-100"
                    />
                </Col>
                <Col xs={10} className="mt-4">
                    <Field
                        name="color"
                        component={materialInputGroupField}
                        label="Color"
                        variant="outlined"
                        data-cy="scheduler-booking-modern-menu-ms-hex-color"
                    />
                </Col>
                <Col xs={2} className="mt-4">
                    <ColorPicker
                        pickerPosition="left"
                        pickerWidth={179}
                        maxHeight={170}
                        leftPosition={-187}
                        topPosition={-85}
                        color={formValues.color}
                        onColorChange={color => updateFields({ color: color.hex })}
                    />
                </Col>
                {/* Show repeat only if new, or existing without repeat, currently we don't have endpoints to choose to update all/future  */}
                {!(defaults.id && defaults.repeat) && (
                    <Col xs={12} className="mt-3">
                        <Repeat
                            inline={false}
                            removeBorder
                            intervalAsDropdown
                            inputFieldDisabled={'NONE' === formValues.interval?.value}
                            formName={FORM_NAME}
                        />
                    </Col>
                )}

                <SuccessButton
                    className="w-100 mt-5"
                    style={defaults.id ? { marginTop: '2rem' } : {}}
                    name={!defaults.id ? 'Add milestone' : 'Update milestone'}
                    disabled={invalid}
                    dataCy={!defaults.id ? 'button--add-milestone' : 'button--update-milestone'}
                    onClick={submit}
                />
            </Row>
        </Form>
    );
};

MilestoneForm.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    defaults: PropTypes.shape({
        projectId: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        name: PropTypes.string,
        id: PropTypes.string,
    }).isRequired,
    onClose: PropTypes.func,
};

MilestoneForm.defaultProps = {
    onClose: null,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(MilestoneForm);
