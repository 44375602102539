import * as actionTypes from 'actions/actionTypes';
import { call, put, takeLatest, all, takeEvery, select, cancelled } from 'redux-saga/effects';
import { getCustomFields, getCustomFieldsCancellable } from 'api/customField';
import { addNotification } from 'actions/notificationActions';
import { getCustomFieldsSuccess, getAllCustomFields, getAllCustomFieldsV2 } from 'actions/customFieldActions';
import { getCustomFields as getCustomFieldsSelector } from 'selectors/customField';
import accessDeniedHandler from './handlers/accessDeniedHandler';
import { makeRequestCancellable } from '../api/makeRequestCancellable';
import { isActive } from '../utils/extensionUtil';
import { CUSTOM_FIELDS } from '../enums/extensionShortIdEnum';
import { monitoring } from '../monitoring';

const defaultMessage = 'Something went wrong... cannot load custom fields for you';

function* customFieldsRequest(action) {
    try {
        const currentCustomFields = yield select(getCustomFieldsSelector);
        if (currentCustomFields[`${action.payload.type}CustomFields`]?.length) {
            return;
        }
        const sortBy = { sortBy: JSON.stringify({ order: 'asc' }) };
        const customFieldsResponse = yield call(getCustomFields, action.payload.type, sortBy);

        yield put(getCustomFieldsSuccess(customFieldsResponse, action.payload.type));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

function* allCustomFieldsRequest() {
    try {
        const sortBy = { sortBy: JSON.stringify({ order: 'asc' }) };
        const [resourceCustomFields, projectCustomFields] = yield all([
            call(getCustomFields, 'resource', sortBy),
            call(getCustomFields, 'project', sortBy),
        ]);

        yield put(
            getAllCustomFields.success({
                resourceCustomFields,
                projectCustomFields,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(getAllCustomFields.failure());
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    }
}

function* handleGetAllCustomFieldsV2() {
    const resourceRequest = makeRequestCancellable(getCustomFieldsCancellable);
    const projectRequest = makeRequestCancellable(getCustomFieldsCancellable);

    const extensions = yield select(state => state.companyReducer.company.extensions);

    if (!isActive(extensions, CUSTOM_FIELDS)) {
        yield put(getAllCustomFieldsV2.failure());
    }

    try {
        const sortBy = { sortBy: JSON.stringify({ order: 'asc' }) };
        const [resourceCustomFields, projectCustomFields] = yield all([
            call(resourceRequest.call, 'resource', sortBy),
            call(projectRequest.call, 'project', sortBy),
        ]);

        yield put(
            getAllCustomFieldsV2.success({
                resourceCustomFields,
                projectCustomFields,
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(getAllCustomFields.failure());
        yield put(
            addNotification({
                message: accessDeniedHandler(error, defaultMessage),
                type: 'danger',
            })
        );
    } finally {
        if (yield cancelled()) {
            resourceRequest.cancel();
            projectRequest.cancel();
        }
    }
}

export default function* customFieldWatcher() {
    yield takeEvery(actionTypes.GET_CUSTOM_FIELDS['REQUEST'], customFieldsRequest);
    yield takeLatest(actionTypes.GET_ALL_CUSTOM_FIELDS['REQUEST'], allCustomFieldsRequest);
    yield takeLatest(actionTypes.GET_CUSTOM_FIELDS_V2['REQUEST'], handleGetAllCustomFieldsV2);
}
