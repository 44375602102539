import styled from 'styled-components';
import Link from 'shared/link';

export const CategoryColor = styled.span`
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    background-color: ${props => props.color};
`;

export const KeyBoardShortCutIcon = styled.span`
    position: absolute;
    right: 0;
    top: 10px;
    margin-right: 10px;
    color: #c9c9c9;
    font-size: 12px;
`;

export const LinkItem = styled(Link)`
    color: rgba(0, 0, 0, 0.87);

    &:hover {
        color: rgba(0, 0, 0, 0.87);
        opacity: 0.7;
        text-decoration: none;
    }
`;

export const MenuNote = styled.div`
    padding: 10px 20px;
    background-color: #e6e6e6;
    margin-top: -5px;
`;

export const MenuNoteInfo = styled.div`
    font-size: 11px;
    color: #7d7d7d;
    font-style: italic;
    font-weight: bold;
    border-top: 1px solid #cacaca;
    margin-top: 4px;
    padding-top: 2px;
`;
