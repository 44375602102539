import { map, some, isString } from 'lodash';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { transformFiltersToBackend } from 'modules/scheduler/utils/builderFiltersUtil';
import { arrayToObjectByKey } from 'utils/mappingUtil';

const getValues = (values, prop) => {
    if (some(values, val => isString(val))) {
        return values;
    }

    return map(values, prop);
};

export const mapStateToRequest = (state, parentGroupId, systemGroupIds) => ({
    name: state.title,
    parentGroupId: parentGroupId || undefined,
    type: state.groupType,
    isSmart: state.isSmart,
    visibility: state.groupType === SCHEDULER_TEMPLATE ? state.visibility : undefined,
    queryParams: {
        itemIds: getValues(state.selectedItems, state.itemType.idProperty),
        itemGroupIds: getValues(state.selectedGroups, state.itemType.additionalGroupProps().fields.value),
        groupsToAdd: state.isSmart
            ? systemGroupIds
            : getValues(state.groupsToAdd, state.itemType.additionalGroupProps().fields.value),
        groupsToRemove: !state.isSmart
            ? getValues(state.groupsToRemove, state.itemType.additionalGroupProps().fields.value)
            : [],
        itemsToAdd: !state.isSmart ? getValues(state.itemsToAdd, state.itemType.idProperty) : [],
        itemsToRemove: !state.isSmart ? getValues(state.itemsToRemove, state.itemType.idProperty) : [],
        filterRelation: state.filterRelation,
        itemType: state.itemType.name,
        filters: transformFiltersToBackend(state.filters, state.dates),
    },
});

export const filterGroupFilters = (filters, data = {}) => {
    const resourcesById = arrayToObjectByKey(data.resources, '_id');
    const projectsById = arrayToObjectByKey(data.projects, '_id');
    const categoriesById = arrayToObjectByKey(data.categories, '_id');

    return {
        ...filters,
        projects: {
            ...(filters?.projects || {}),
            filters: (filters?.projects?.filters ?? []).filter(item => !!projectsById[item]),
        },
        resources: {
            ...(filters?.resources || {}),
            filters: (filters?.resources?.filters ?? []).filter(item => !!resourcesById[item]),
        },
        bookingResourcesUW: {
            ...(filters?.bookingResourcesUW || {}),
            filters: (filters?.bookingResourcesUW?.filters ?? []).filter(item => {
                const [id] = item.split('_');
                return !!resourcesById[id];
            }),
        },
        bookingProjectsEvents: {
            ...(filters?.bookingProjectsEvents || {}),
            filters: (filters?.bookingProjectsEvents?.filters ?? []).filter(item => {
                const [id] = item.split('_');
                return !!projectsById[id];
            }),
        },
        bookingCategories: {
            ...(filters?.bookingCategories || {}),
            filters: (filters?.bookingCategories?.filters ?? []).filter(item => {
                return !!categoriesById[item];
            }),
        },
    };
};
