import { hasRights } from 'hooks';

const editRights = [
    {
        rights: ['canEditRequestResource'],
        rule: 'one',
        name: 'hasEditResourceRequestRights',
    },
    {
        rights: ['canEditRequestVacation'],
        rule: 'one',
        name: 'hasEditVacationRequestRights',
    },
];

export const isAbleToEditResourceOrVacationRequest = (booking, resource, resourceRoleRights, vacationId) => {
    const isApprover = booking?.approvalInfo?.approvers?.find(a => a.id === resource);
    const projectId = typeof booking?.project === 'object' ? booking.project?._id : booking?.project;
    const isVacation = projectId === vacationId;

    if (isApprover) {
        return true;
    }

    const { hasEditVacationRequestRights, hasEditResourceRequestRights } = hasRights(editRights, resourceRoleRights);

    return isVacation ? hasEditVacationRequestRights : hasEditResourceRequestRights;
};

const approveRejectRights = [
    {
        rights: ['manageAllResourceRequests'],
        rule: 'one',
        name: 'hasManageAllResourceRequestsRights',
    },
    {
        rights: ['manageAllVacations'],
        rule: 'one',
        name: 'hasManageAllVacationsRights',
    },
];

export const isAbleToApproveRejectVacationOrRequest = (bookings, resource, resourceRoleRights, vacationId) => {
    const isApprover = bookings.every(el => el?.approvalInfo?.approvers?.find(a => a.id === resource));
    const projectIds = bookings.map(el => (typeof el?.project === 'object' ? el.project._id : el?.project));
    const isVacation = projectIds.every(el => el === vacationId);

    if (isApprover) {
        return true;
    }

    const { hasManageAllVacationsRights, hasManageAllResourceRequestsRights } = hasRights(
        approveRejectRights,
        resourceRoleRights
    );

    return isVacation ? hasManageAllVacationsRights : hasManageAllResourceRequestsRights;
};
