import { axiosInstance } from './axiosInstance';

export const getCustomers = () =>
    axiosInstance
        .get(`customer`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const createCustomer = data =>
    axiosInstance
        .post('customer', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
