import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { forEach } from 'lodash';
import { getAllocationDisplayValue } from 'utils/allocationUtil';
import SearchList from 'modules/scheduler/components/searchList/searchList';
import ListItem from './listItem';
import { sortObjectsArrayByNameFn } from 'shared/lib/array';
import { selectUseCategoriesAllocation } from 'selectors/company';

const CategoryList = props => {
    const { categoryGroups, height, onSelect, onEmptySearch, selectedIds } = props;
    const useCategoriesAllocation = useSelector(selectUseCategoriesAllocation);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let categories = [];
        forEach(sortObjectsArrayByNameFn(categoryGroups), categoryGroup => {
            forEach(sortObjectsArrayByNameFn(categoryGroup.categories), category => {
                categories.push({
                    ...category,
                    allocationText: useCategoriesAllocation && getAllocationDisplayValue(category),
                    groupName: categoryGroup.name,
                    categoryGroup,
                });
            });
        });
        setCategories(categories);
    }, [useCategoriesAllocation, categoryGroups]);

    const onSelectCategory = ({ category }) => {
        return onSelect({ category, categoryGroup: category.categoryGroup });
    };

    return (
        <SearchList
            itemComponent={props => (
                <ListItem
                    divider
                    disableGutters
                    avatarSize={28}
                    backgroundColor={props.item.gridColor}
                    secondaryText={`${props.item.groupName}${
                        props.item.allocationText ? ' - ' + props.item.allocationText : ''
                    }`}
                    {...props}
                />
            )}
            height={height}
            inputSearchClass="pl-4"
            emptyText={`Sorry, there are no categories to choose from`}
            data={categories}
            name="category"
            rowHeight={60}
            onEmptySearch={onEmptySearch}
            onSelect={onSelectCategory}
            searchBy={['groupName']}
            fields={{ label: 'name', value: '_id' }}
            placeholder="Search for category"
            selectedIds={selectedIds}
            variant="outlined"
        />
    );
};

CategoryList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    categoryGroups: PropTypes.array,
    onSelect: PropTypes.func,
    onEmptySearch: PropTypes.func,
    height: PropTypes.number,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
    allocationDefaultState: PropTypes.string,
};

CategoryList.defaultProps = {
    data: [],
    categoryGroups: [],
    onSelect: null,
    onEmptySearch: null,
    height: 100,
    selectedIds: [],
};

export default CategoryList;
