import styled from 'styled-components';
import { Button } from 'reactstrap';

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6);
`;

export const Wrapper = styled.div`
    text-align: center;
    width: 600px;
    padding: 85px;
    background: #fff;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    font-weight: 500;
    .btn {
        min-width: 160px !important;
        padding-right: 11px !important;
    }
    z-index: 9999;
`;

export const ButtonElement = styled(Button)`
    background-color: ${props => `${props.backgroundcolor} !important`};
    border-color: ${props => `${props.backgroundcolor} !important`};
    color: ${props => `${props.fontcolor} !important`};
    &:hover {
        svg {
            display: inline-block !important;
        }
        background: rgba(0, 0, 0, 0.7);
    }
    &:focus {
        box-shadow: none !important;
    }
`;
