import React from 'react';
import PropTypes from 'prop-types';
import { createCySelector } from '../../../../../utils/cypressUtil';
import { IconButton } from '@hub-mono/ui';

const ReSubmitAction = ({ reSubmit }) => {
    const title = 'Re-submit Request for Approval';
    return <IconButton icon="resubmit" dataCy={createCySelector(title)} onClick={reSubmit} />;
};

ReSubmitAction.propTypes = {
    reSubmit: PropTypes.func.isRequired,
};

export default ReSubmitAction;
