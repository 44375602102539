import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    Image_main: props => props,
});

export const Image = React.memo(props => {
    const { imageSrc, height, className } = props;
    const classes = useStyles({
        height,
    });
    return <img className={`${classes.Image_main} ${className ? className : ''}`} src={imageSrc}></img>;
});

export const propTypes = {
    imageSrc: PropTypes.string.isRequired,
    height: PropTypes.string,
    className: PropTypes.string,
};
Image.propTypes = propTypes;

export default Image;
