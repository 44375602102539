import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { Button, Row, Col, FormText } from 'reactstrap';
import { dropdownField } from 'shared/formFields';
import DateRange from 'shared/dateRange';
import ConfirmButton from 'shared/confirmButton';
import { getCurrency } from 'enums/currencyEnum';
import { CloseIcon } from './styles';

const InternalExternalResourceRates = ({ rates, type, name, onChange }) => (
    <div className="py-3">
        <FieldArray
            name={name}
            component={({ fields }) => (
                <React.Fragment>
                    {fields.map((rate, i) => {
                        let itemValues = fields.get(i);

                        return (
                            <Row key={i} className="mb-2">
                                <Col xs={12} md={5} className="pr-0">
                                    <Field
                                        inline
                                        name={`resourceRates.${type}.${i}.defaultRateId`}
                                        component={dropdownField}
                                        filter
                                        placeholder="Search for Rate"
                                        valueField="_id"
                                        textField={item =>
                                            Object.keys(item).length
                                                ? `${item.label}: ${getCurrency(item.currency).symbol} ${item.rate}`
                                                : null
                                        }
                                        data={rates}
                                    />
                                    <FormText className="required">Rate</FormText>
                                </Col>
                                <Col xs={12} md={6} className="pr-0">
                                    <DateRange
                                        clearable
                                        single={false}
                                        startDateText="Rate Starts"
                                        endDateText="Rate Ends"
                                        startDateRequired={false}
                                        endDateRequired={false}
                                        initialStartDate={
                                            itemValues.effectiveFrom ? new Date(itemValues.effectiveFrom) : undefined
                                        }
                                        initialEndDate={
                                            itemValues.effectiveTo ? new Date(itemValues.effectiveTo) : undefined
                                        }
                                        onChange={values =>
                                            onChange({
                                                [`${rate}.effectiveFrom`]: values.startDate,
                                                [`${rate}.effectiveTo`]: values.endDate,
                                            })
                                        }
                                        height="42px"
                                        transformTimezone={false}
                                    />
                                </Col>
                                <Col xs={12} md={1} className="pr-0">
                                    <ConfirmButton
                                        buttonIcon={CloseIcon}
                                        buttonClass="rounded-0"
                                        message="You are about to delete a rate from the system. This cannot be undone. The rate will be removed from all reports. If you want to maintain it, please change the effective dates"
                                        title="Delete rate?"
                                        color="#fff"
                                        backgroundColor="#C82333"
                                        onConfirm={() => fields.remove(i)}
                                    />
                                </Col>
                            </Row>
                        );
                    })}
                    <Button color="link" className="mt-2 btn-rates text-uppercase" onClick={() => fields.push({})}>
                        <i className="fa fa-chevron-right" /> Add new {type} Rate
                    </Button>
                </React.Fragment>
            )}
        />
    </div>
);

InternalExternalResourceRates.propTypes = {
    name: PropTypes.string.isRequired,
    rates: PropTypes.array,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

InternalExternalResourceRates.defaultProps = {
    rates: [],
    onChange: null,
    type: 'external',
};

export default InternalExternalResourceRates;
