import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { addResourceToProject } from 'actions/projectActions';
import { updateResourceGroup as updateResourceGroupAction } from 'actions/resourceGroupActions';
import { updateProjectGroup as updateProjectGroupAction } from 'actions/projectGroupActions';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import { resourceIsActive } from 'shared/lib/resources';
import { dispatchRefreshDPResourcesEvent } from '../../../utils/schedulerUtil';
import { SchedulerProjectsListProvider } from '../../lists/projectList/schedulerProjectsListProvider';
import { SchedulerProjectsList } from '../../lists/projectList/schedulerProjectsList';
import { SchedulerResourcesListProvider } from '../../lists/resourceList/schedulerResourcesListProvider';
import { SchedulerResourcesList } from '../../lists/resourceList/schedulerResourcesList';
import NewShortProjectOrEvent from '../shared/shortProjectOrEvent';
import NewShortResourceOrUnassigned from '../shared/shortResourceOrUnassigned';
import { useSchedulerRef } from '../../../../../shared/hooks';

const CONTEXT_MENU_MAX_HEIGHT = 600;
export const CONTEXT_MENU_WIDTH = 320;

const QuickAdd = React.forwardRef((props, ref) => {
    const { project, resource, resourceGroup, onClose, type, height, projectGroup } = props;

    const { schedulerRef } = useSchedulerRef();
    const dispatch = useDispatch();
    const slideRef = useRef();
    const selectedResourcesIds = resourceGroup?.resources || project.resources;
    const selectedProjectsIds = resource?.projects;

    const updateProjectOnResource = useCallback(
        selectedProject => {
            dispatch(
                addResourceToProject.request({
                    projectId: selectedProject._id,
                    resourceId: resource._id,
                    onSuccess: dispatchRefreshDPResourcesEvent,
                })
            );

            onClose();
        },
        [dispatch, onClose, resource._id]
    );

    const handleProjectCreate = useCallback(
        createResult => {
            updateProjectOnResource(createResult.project);
            onClose && onClose();
        },
        [onClose, updateProjectOnResource]
    );

    const updateProjectOnProject = useCallback(
        selectedResource => {
            dispatch(
                addResourceToProject.request({
                    projectId: project._id,
                    resourceId: selectedResource._id,
                    onSuccess: () => {
                        const row = schedulerRef.current?.control.rows.find(project._id);
                        if (row) {
                            row.data.tags.project.resources?.push(selectedResource._id);
                            schedulerRef.current?.control.rows.update(row);
                        }

                        dispatchRefreshDPResourcesEvent({ rowId: selectedResource._id });
                    },
                })
            );

            onClose();
        },
        [dispatch, onClose, project._id, schedulerRef]
    );

    const updateResourceGroup = useCallback(
        selectedResource => {
            dispatch(
                updateResourceGroupAction.request({
                    id: resourceGroup._id,
                    data: {
                        queryParams: {
                            itemIds: [...resourceGroup.resources, selectedResource._id],
                            itemsToAdd: [selectedResource._id],
                        },
                    },
                    callback: dispatchRefreshDPResourcesEvent,
                })
            );
            onClose();
        },
        [dispatch, onClose, resourceGroup]
    );

    const handleResourceCreate = useCallback(
        createResult => {
            onClose && onClose();
            resourceGroup ? updateResourceGroup(createResult.resource) : updateProjectOnProject(createResult.resource);
        },
        [onClose, resourceGroup, updateProjectOnProject, updateResourceGroup]
    );

    const updateProjectGroup = useCallback(
        selectedProject => {
            dispatch(
                updateProjectGroupAction.request({
                    id: projectGroup._id,
                    data: {
                        queryParams: {
                            itemIds: [...projectGroup.projects, selectedProject._id],
                            itemsToAdd: [selectedProject._id],
                        },
                    },
                    callback: dispatchRefreshDPResourcesEvent,
                })
            );
            onClose();
        },
        [dispatch, onClose, projectGroup]
    );

    const dropDownHeight = height > CONTEXT_MENU_MAX_HEIGHT ? CONTEXT_MENU_MAX_HEIGHT : height;

    const projectListJSX = useMemo(
        () => (
            <div className="px-4">
                <SchedulerProjectsList
                    selectedIds={selectedProjectsIds}
                    onSelect={data =>
                        projectGroup ? updateProjectGroup(data.project) : updateProjectOnResource(data.project)
                    }
                    height={dropDownHeight + 35}
                    slideRef={slideRef}
                    hideSelected
                    allPermitted
                    hideArchived
                />
            </div>
        ),
        [dropDownHeight, projectGroup, selectedProjectsIds, updateProjectGroup, updateProjectOnResource]
    );

    const resourceListJSX = useMemo(
        () => (
            <div className="px-4">
                <SchedulerResourcesList
                    selectedIds={selectedResourcesIds}
                    onSelect={data =>
                        resourceGroup ? updateResourceGroup(data.resource) : updateProjectOnProject(data.resource)
                    }
                    height={dropDownHeight + 35}
                    externalFilterFunction={resourceIsActive}
                    slideRef={slideRef}
                    hideSelected
                    allPermitted
                />
            </div>
        ),
        [selectedResourcesIds, dropDownHeight, resourceGroup, updateResourceGroup, updateProjectOnProject]
    );

    const slideCardItems = [
        {
            id: 1,
            itemElement: projectListJSX,
            show: 'project' === type,
            slides: [
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <NewShortProjectOrEvent onCreate={handleProjectCreate} assignResources={[resource._id]} />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 2,
            itemElement: resourceListJSX,
            show: 'resource' === type,
            slides: [
                {
                    scrollable: true,
                    content: (
                        <div className="px-4">
                            <NewShortResourceOrUnassigned onCreate={handleResourceCreate} />
                        </div>
                    ),
                },
            ],
        },
    ];

    return (
        <SchedulerResourcesListProvider onlyResources>
            <SchedulerProjectsListProvider onlyProjects>
                <div ref={ref} className="rounded-0 p-0" style={{ width: `${CONTEXT_MENU_WIDTH}px` }}>
                    <SlideCard
                        ref={slideRef}
                        showActionBtn={false}
                        height={dropDownHeight}
                        width={CONTEXT_MENU_WIDTH - 2}
                        items={slideCardItems}
                    />
                </div>
            </SchedulerProjectsListProvider>
        </SchedulerResourcesListProvider>
    );
});

QuickAdd.propTypes = {
    height: PropTypes.number.isRequired,
    onClose: PropTypes.func,
    project: PropTypes.object,
    resource: PropTypes.object,
    resourceGroup: PropTypes.object,
    type: PropTypes.oneOf(['project', 'resource']),
};

QuickAdd.defaultProps = {
    type: 'project',
    onClose: null,
    project: {},
    resource: {},
    resourceGroup: null,
};

export default QuickAdd;
