import { store } from '../../../../store';
import { showConfirmationModal } from '../../../../actions/modalActions';
import { makeSelectBookingLink } from '../../../../selectors/scheduler';
import { createBookingLink, deleteBookingLink } from '../../../../actions/schedulerActions';
import { DayPilot } from 'daypilot-pro-react';
import { CELLDURATION } from '../../enums/scale';

export const onLinkCreate = ({ dispatch, schedulerRef }) => args => {
    const state = store.getState();

    const fromEvent = schedulerRef.current.control.events.find(args.from);
    const toEvent = schedulerRef.current.control.events.find(args.to);

    if (!fromEvent || !toEvent) {
        args.preventDefault();
        return;
    }

    if (fromEvent?.data?.id === toEvent?.data?.id) {
        args.preventDefault();
        return;
    }

    if (!fromEvent?.data?.isPermitted || !toEvent?.data?.isPermitted) {
        dispatch(
            showConfirmationModal(
                () => {
                    // do nothing
                },
                'Action not allowed',
                `You can't link bookings to which you have no permissions`,
                {
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );

        args.preventDefault();
        return;
    }

    if (fromEvent?.data?.repeat && toEvent?.data?.repeat) {
        dispatch(
            showConfirmationModal(
                () => {
                    // do nothing
                },
                'Action not allowed',
                `You can't link repeated booking with another repeated booking`,
                {
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );

        args.preventDefault();
        return;
    }

    const link = makeSelectBookingLink({ parentId: args.from, childId: args.to })(state);

    if (link) {
        dispatch(
            showConfirmationModal(
                () => {
                    // do nothing
                },
                'Action not allowed',
                `Dependency already exists`,
                {
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );

        args.preventDefault();
        return;
    }

    const fromEventScale = fromEvent.data.scale;
    const toEventScale = toEvent.data.scale;

    if (
        (fromEventScale.value === CELLDURATION.value && toEventScale.value !== CELLDURATION.value) ||
        (fromEventScale.value !== CELLDURATION.value && toEventScale.value === CELLDURATION.value)
    ) {
        dispatch(
            showConfirmationModal(
                () => {
                    // do nothing
                },
                'Action not allowed',
                `You can't link booking which was created in Hour View with a one created in another view`,
                {
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );
        args.preventDefault();
        return;
    }

    if (args.type !== 'FinishToStart') {
        dispatch(
            showConfirmationModal(
                () => {
                    // do nothing
                },
                'Action not allowed',
                'You can only link end of booking with the start of another booking',
                {
                    withCancel: false,
                    confirmButtonText: 'Ok',
                }
            )
        );
        args.preventDefault();
        return;
    }

    dispatch(
        createBookingLink.request({
            parentId: args.from,
            childId: args.to,
        })
    );
    args.preventDefault();
};

export const contextMenuLink = ({ dispatch, schedulerRef }) =>
    new DayPilot.Menu({
        theme: 'dependency',
        onShow: args => {
            if (!schedulerRef.current) {
                args.preventDefault();
            }

            const { from, to } = args.source.data;
            const fromEvent = schedulerRef.current.control.events.find(from);
            const toEvent = schedulerRef.current.control.events.find(to);

            // if user don't have edit permission to linked booking, then hide context menu link
            if (!fromEvent.data.hasEditRights || !toEvent.data.hasEditRights) {
                args.preventDefault();
            }
        },
        items: [
            {
                text: 'Delete dependency',
                onClick: args => {
                    const { from, to } = args.source.data;

                    const action = () =>
                        dispatch(
                            deleteBookingLink.request({
                                parentId: from,
                                childId: to,
                            })
                        );

                    dispatch(
                        showConfirmationModal(
                            action,
                            'Delete dependency',
                            `Do you really want to remove this dependency`,
                            {
                                withCancel: true,
                                confirmButtonText: 'Yes, remove it',
                                cancelButtonText: 'Cancel',
                            }
                        )
                    );
                },
            },
        ],
    });
