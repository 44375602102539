import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { map, filter, sortBy } from 'lodash';
import { TabPane, FormText, Alert } from 'reactstrap';
import { useIsExtensionInstalled, useHasRights, useAppKeyWords } from 'hooks';
import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { customOverlapValidator } from 'utils/formValidators';
import HelpButton from './../../common/helpButton';
import MissingExtension from 'shared/warnings/missingExtension';
import PermissionDenied from 'shared/permissionDenied';
import InternalExternalResourceRates from './internalExternalResourceRates';
import { chooseField } from 'shared/formFields';

const getNotDeletedRates = rates =>
    sortBy(
        filter(rates, rate => !rate.deleted),
        item => item.label.toLowerCase()
    );

const Rates = props => {
    const {
        input: { value: resourceRates },
        onChange: updateFields,
        onRatesValidChange,
    } = props;
    const filteredRates = getNotDeletedRates;
    const rates = useSelector(state => state.companyReducer.company.settings.billingRates);
    const { projectKeyWord, resourceKeyWord } = useAppKeyWords();

    const externalErrorMessage = customOverlapValidator(
        map(resourceRates.external, rate => ({
            start: rate.effectiveFrom,
            end: rate.effectiveTo,
        }))
    );
    const internalErrorMessage = customOverlapValidator(
        map(resourceRates.internal, rate => ({
            start: rate.effectiveFrom,
            end: rate.effectiveTo,
        }))
    );

    const extValid = !externalErrorMessage
    const intValid = !internalErrorMessage

    useEffect(() => {
        onRatesValidChange(extValid && intValid);
    }, [extValid, intValid, onRatesValidChange])


    const onChange = dates => {
        const datesArray = Object.entries(dates);

        updateFields(
            Object.fromEntries(
                datesArray.map(([key, value]) => [
                    key,
                    value ? new Date(value.setHours(0, 0, 0, 0).valueOf()) : undefined,
                ])
            )
        );
    };

    const nonBillableJSX = () => {
        return (
            <section className="form-fields">
                <Field
                    type="checkbox"
                    name="nonBillable"
                    label={`Non-billable ${resourceKeyWord}`}
                    component={chooseField}
                />
                <FormText>
                    Checking the box above will mark the {resourceKeyWord} as non-billable. This means that the spend on
                    this {resourceKeyWord} will not be counted in reports if checked. You will however be able to
                    calculate the non-billable spend in reports using filters if you wish.
                </FormText>
            </section>
        );
    };

    return (
        <>
            {nonBillableJSX()}
            <section className="form-fields">
                <HelpButton id={1}>
                    <strong>External {resourceKeyWord} Rates:</strong> Setting a {resourceKeyWord} rate can be useful if
                    a {resourceKeyWord} has their own rate. If the rate for a {resourceKeyWord} changes you can easily
                    add a new rate and set the date range for when this rate is valid. This is used when a rate changes
                    at any point in time. You can still override this rate on a Project level.
                </HelpButton>
                <p className="title">External {resourceKeyWord} Rate:</p>
                <FormText className="mb-2">
                    The external {resourceKeyWord} rate is used normally for outgoing work. You can override this on a{' '}
                    {projectKeyWord} Basis
                </FormText>
                <InternalExternalResourceRates
                    rates={filteredRates(rates)}
                    type="external"
                    name="resourceRates.external"
                    onChange={onChange}
                />
                <FormText className="mb-0 mt-4">
                    Adding a new rate is useful if rate change at some point and you want to maintain the historic rate
                    used up until a specific date
                </FormText>
                {externalErrorMessage && (
                    <Alert color="danger" className="mt-2">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: externalErrorMessage,
                            }}
                        />
                    </Alert>
                )}
            </section>
            <section className="form-fields">
                <HelpButton id={2}>
                    <strong>Internal {resourceKeyWord} Rates:</strong> Setting a {resourceKeyWord} rate can be useful if
                    a {resourceKeyWord} has their own rate internally or you want to calculate a {resourceKeyWord} cost.
                    You can add here the rate you may pay internally for a {resourceKeyWord} or their salary equivalent.
                </HelpButton>
                <p className="title">Internal {resourceKeyWord} Rate:</p>
                <FormText className="mb-2">
                    The internal {resourceKeyWord} rate is used normally if you bill a different rate internally or want
                    to gauge your costs. You can override this on a {projectKeyWord} Basis.
                </FormText>
                <InternalExternalResourceRates
                    rates={filteredRates(rates)}
                    type="internal"
                    name="resourceRates.internal"
                    onChange={onChange}
                />
                <FormText className="mb-0 mt-4">
                    Adding a new rate us useful if rate changes at some point and you want to maintain the historic rate
                    used up until a specific date.
                </FormText>
                {internalErrorMessage && (
                    <Alert color="danger" className="mt-2">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: internalErrorMessage,
                            }}
                        />
                    </Alert>
                )}
            </section>
        </>
    );
};

const ResourceRatesTab = ({ tabId, onChange, onRatesValidChange }) => {
    const { resourceKeyWord } = useAppKeyWords();
    const isRatesExtensionInstalled = useIsExtensionInstalled(RESOURCE_RATE);
    const { hasSettingResourceRate } = useHasRights([
        {
            rights: ['settingResourceRate'],
            rule: 'one',
            name: 'hasSettingResourceRate',
        },
    ]);

    return (
        <TabPane tabId={tabId}>
            {isRatesExtensionInstalled ? (
                hasSettingResourceRate ? (
                    <Field
                        name="resourceRates"
                        component={props => <Rates {...props} onChange={onChange} onRatesValidChange={onRatesValidChange} />}
                        onChange={onChange}
                    />
                ) : (
                    <PermissionDenied />
                )
            ) : (
                <MissingExtension
                    description={`${resourceKeyWord} Rates are Disabled on a company Level. You must first enable it in settings`}
                />
            )}
        </TabPane>
    );
};

ResourceRatesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    resourceRates: PropTypes.objectOf(PropTypes.array),
};

ResourceRatesTab.defaultProps = {
    resourceRates: {},
};

export default ResourceRatesTab;
