import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
    padding: 20px 0px;
    .progress {
        width: 50%;
        margin: auto;
    }
`;

export const DateWrapper = styled.span`
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 8px;
    display: block;
`;

export const UpgradeLink = styled(Link)`
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #61b775;
    border-color: #5fb574;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    padding: 8px 16px !important;
    margin-top: 20px;
    &:hover {
        color: #fff;
        background-color: #78c389;
        border-color: #71ca86;
    }
`;
