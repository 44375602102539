import * as actionTypes from './actionTypes';
import { action } from 'actions/base';

export const getReport = {
    success: data => action(actionTypes.GET_REPORT['SUCCESS'], { data }),
    failure: () => action(actionTypes.GET_REPORT['FAILURE']),
};

export const createReport = {
    request: (name, visibility, queryParams, groups, newGroupName) =>
        action(actionTypes.CREATE_REPORT['REQUEST'], {
            name,
            visibility,
            queryParams,
            groups,
            newGroupName,
        }),
    success: template => action(actionTypes.CREATE_REPORT['SUCCESS'], { template }),
};

export const updateReport = {
    request: (id, data, groups, newGroupName) =>
        action(actionTypes.UPDATE_REPORT['REQUEST'], { id, data, groups, newGroupName }),
    success: template => action(actionTypes.UPDATE_REPORT['SUCCESS'], { template }),
};

export const updateContextRow = {
    request: (item, queryParams, storageItemName) =>
        action(actionTypes.UPDATE_CONTEXT_ROW['REQUEST'], {
            item,
            queryParams,
            storageItemName,
        }),
    success: (data, queryParams, storageItemName) =>
        action(actionTypes.UPDATE_CONTEXT_ROW['SUCCESS'], {
            data,
            queryParams,
            storageItemName,
        }),
};

export const updateFavourites = {
    request: (templateId, data) => action(actionTypes.UPDATE_FAVOURITES['REQUEST'], { templateId, data }),
    success: template => action(actionTypes.UPDATE_FAVOURITES['SUCCESS'], { template }),
};

export const exportReport = {
    request: (fileFormat, queryData, useUTCinFileName = true) =>
        action(actionTypes.EXPORT_REPORT['REQUEST'], { fileFormat, queryData, useUTCinFileName }),
    success: () => action(actionTypes.EXPORT_REPORT['SUCCESS']),
};

export const shareReport = {
    request: data => action(actionTypes.SHARE_REPORT['REQUEST'], { data }),
    success: token => action(actionTypes.SHARE_REPORT['SUCCESS'], { token }),
};

export const sharedReport = {
    request: token => action(actionTypes.SHARED_REPORT['REQUEST'], { token }),
};

export const getReportTemplates = {
    request: () => action(actionTypes.GET_REPORT_TEMPLATES['REQUEST']),
    success: templates => action(actionTypes.GET_REPORT_TEMPLATES['SUCCESS'], { templates }),
};

export const updateQueryParams = {
    request: (queryParams, shouldRequest, shouldRedirect, storageItemName) =>
        action(actionTypes.UPDATE_REPORT_QUERY_PARAMS, {
            queryParams,
            shouldRequest,
            shouldRedirect,
            storageItemName,
        }),
};

export const resetData = {
    request: hardReset => action(actionTypes.RESET_REPORT_DATA, { hardReset }),
};

export const deleteReport = {
    request: (template, groupId) => action(actionTypes.DELETE_REPORT['REQUEST'], { template, groupId }),
    success: id => action(actionTypes.DELETE_REPORT['SUCCESS'], { id }),
};

export const sendSuggestReportRequest = {
    request: data => action(actionTypes.SEND_SUGGEST_REPORT, { data }),
};

export const addReportsGroup = {
    request: (data, silent) => action(actionTypes.ADD_REPORTS_GROUP['REQUEST'], { data, silent }),
    success: data => action(actionTypes.ADD_REPORTS_GROUP['SUCCESS'], { data }),
};

export const updateReportsGroup = {
    request: (groupId, data, menuId) => action(actionTypes.UPDATE_REPORTS_GROUP['REQUEST'], { groupId, data, menuId }),
    success: data => action(actionTypes.UPDATE_REPORTS_GROUP['SUCCESS'], { data }),
};

export const removeReportsGroup = {
    request: groupId => action(actionTypes.DELETE_REPORTS_GROUP['REQUEST'], { groupId }),
    success: groupId => action(actionTypes.DELETE_REPORTS_GROUP['SUCCESS'], { groupId }),
};

export const getReportsGroups = {
    request: () => action(actionTypes.GET_REPORTS_GROUPS['REQUEST']),
    success: data => action(actionTypes.GET_REPORTS_GROUPS['SUCCESS'], { data }),
};

export const removeReportFromGroup = (reportId, groupId) => ({
    type: actionTypes.REMOVE_REPORT_FROM_GROUP,
    payload: {
        reportId,
        groupId,
    },
});

export const checkGroups = ({ groupIds, itemTypeName }) =>
    action(actionTypes.REPORT_CHECK_GROUPS, { groupIds, itemTypeName });
