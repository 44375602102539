import * as actionTypes from 'actions/actionTypes';
import createReducer from 'reducers/helpers/createReducer';
import { updateObject } from 'reducers/helpers/updater';

const initialTagState = {
    projectTags: [],
    resourceTags: [],
};

const setTags = (state, action) =>
    updateObject(state, {
        [`${action.payload.type}Tags`]: action.payload.tags,
    });

const addTag = (state, action) => {
    if (!action.payload.tag) {
        return state;
    }

    return updateObject(state, {
        [`${action.payload.type}Tags`]: [...state[`${action.payload.type}Tags`], action.payload.tag],
    });
};

export default createReducer(initialTagState, {
    [actionTypes.GET_TAGS['SUCCESS']]: setTags,
    [actionTypes.CREATE_TAG['SUCCESS']]: addTag,
});
