import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';

export const PrimaryButton = styled(Button)`
    background-color: #295461 !important;
    &:hover {
        background-color: #1f404a !important;
    }
    font-size: 15px !important;
    font-weight: normal !important;
    text-transform: capitalize !important;
    width: 100%;
    &:disabled {
        opacity: 0.8;
        color: rgba(255, 255, 255, 0.46) !important;
    }
`;

export const SecondaryButton = styled.button`
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: #444;
    border: none;
    width: ${props => (props.isSmall ? '260px' : '100%')} !important;
    text-align: center;
    height: 42px;

    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1) !important;
    }

    &:focus {
        outline: none;
    }
`;

const SSOLinkStyles = `
    border-radius: 4px;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 14px;
    color: #444;
    height: 42px;
    text-decoration: none !important;

    &:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1) !important;
        color: inherit;
    }

    &:focus {
        outline: none;
    }
`;
export const SSOLink = styled(Link)`
    ${SSOLinkStyles}
`;

export const SSOLinkHref = styled.a`
    ${SSOLinkStyles}
`;
export const SSOGoogleHref = styled.a`
    ${SSOLinkStyles}
    @media only screen and (min-width: 400px) and (max-width: 1420px) {
        padding-left: 26px;
    }

    background: url('https://hubplanner-static.s3-eu-west-1.amazonaws.com/img/g-normal.png') #fff 5px 50% no-repeat;

    @media only screen and (min-width: 991px) and (max-width: 1280px) {
        background-position: 0 50% !important;
    }

    @media only screen and (max-width: 400px) {
        background: #fff;
    }
`;
