import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddCircle, Adjust } from '@material-ui/icons';
import { DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useRequests } from 'modules/request/hooks';
import { useAppKeyWords, useHasRights, useRouteParams, useIsExtensionInstalled } from 'hooks';
import { PM, SMART_SCHEDULES, UNASSIGNED } from 'enums/extensionShortIdEnum';
import { TYPE_EVENT, TYPE_REGULAR } from 'enums/projectEnum';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { createResourceOrVacationRequest } from 'actions/bookingActions';
import { createResource } from 'actions/resourceActions';
import { createProject } from 'actions/projectActions';
import { updateBookingSelection } from 'actions/schedulerActions';
import {
    showRequestResourceModal,
    showRequestVacationModal,
    showResourceModal,
    showScheduleResourceModal,
    showProjectModal,
    showSchedulerBuilderModal,
    showMissingSmartSchedulesExtensionModal,
    showMissingUnassignedExtensionModal,
} from 'actions/modalActions';
import { PlusDropdown } from './styles';
import { DropdownButton } from 'shared/dropdownMenu';
import { useBooking } from 'modules/scheduler/hooks';
import { TYPE_TAB } from 'modules/scheduler/modals/builder/tabs';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { RESOURCE, PROJECT } from 'modules/scheduler/enums/builderTypeEnum';
import { GROUP } from '../../enums/groupTypeEnum';
import { makeStyles } from '@material-ui/core/styles';
import { addDaypilotEvent, getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import {
    smartSchedulesExtensionTooltipMessage,
    unassignedWorkExtensionTooltipMessage,
} from 'shared/constants/tooltipsMessages';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles({
    buttonDropdownWithEndIcon: {
        display: 'flex',
        justifyContent: 'space-between',
        color: 'rgba(0, 0, 0, 0.26)',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.26)',
        },
    },
    endIcon: {
        fontSize: 18,
        marginTop: 2,
        color: '#e6892c',
    },
});

const shouldRedirect = true;
const rights = [
    {
        rights: ['settingAddEditProjects'],
        rule: 'one',
        name: 'hasCreateProjectRights',
    },
    {
        rights: ['manageEvents', 'scheduleMyselfOnAnyProjectOrEvent', 'scheduleMyselfOnProjectsOrEventsIAmPartOf'],
        rule: 'oneOf',
        name: 'hasScheduleRights',
    },
    {
        rights: ['pmManageEvents'],
        rule: 'one',
        name: 'hasPMScheduleRights',
    },
    {
        rights: ['pmManageUnassignedRows'],
        rule: 'one',
        name: 'hasPMUnassignedScheduleRights',
    },
    {
        rights: ['manageUnassignedRows'],
        rule: 'one',
        name: 'hasUnassignedScheduleRights',
    },
    {
        rights: ['settingAddEditResources'],
        rule: 'one',
        name: 'hasCreateResourceRights',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'hasCreateResourceGroupRights',
    },
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'hasCreateProjectGroupRights',
    },
    {
        rights: ['manageUnassignedRows'],
        rule: 'one',
        name: 'hasManageUnassignedRights',
    },
];

const AddItem = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account);
    const { canRequestResources, canRequestVacations } = useRequests();
    const { resourceKeyWord, projectKeyWord, vacationEventName } = useAppKeyWords();
    const isPMExtensionInstalled = useIsExtensionInstalled(PM);
    const isSmartSchedulesInstalled = useIsExtensionInstalled(SMART_SCHEDULES);
    const isUnassignedExtensionInstalled = useIsExtensionInstalled(UNASSIGNED);
    const params = useRouteParams();
    const viewObject = getViewObject(params);

    const classes = useStyles();

    const {
        hasCreateProjectRights,
        hasScheduleRights,
        hasPMScheduleRights,
        hasCreateResourceRights,
        hasPMUnassignedScheduleRights,
        hasUnassignedScheduleRights,
        hasCreateResourceGroupRights,
        hasCreateProjectGroupRights,
        hasManageUnassignedRights,
    } = useHasRights(rights);

    const canScheduleRights =
        hasScheduleRights ||
        hasUnassignedScheduleRights ||
        (account.isProjectManager && (hasPMScheduleRights || hasPMUnassignedScheduleRights) && isPMExtensionInstalled);

    const hasOneOfRights =
        canScheduleRights ||
        hasCreateProjectRights ||
        hasCreateResourceRights ||
        canRequestVacations ||
        hasCreateResourceGroupRights ||
        hasCreateProjectGroupRights ||
        hasManageUnassignedRights ||
        canRequestResources;

    const { createBooking } = useBooking();

    const addBookingsInScheduler = useCallback(
        bookings => {
            if (Array.isArray(bookings) && window.schedulerRef?.current?.control) {
                addDaypilotEvent(bookings, window.schedulerRef.current.control, viewObject);
                window.schedulerRef.current.control.clearSelection();
            }
        },
        [viewObject]
    );

    const vacationModal = useCallback(() => {
        window.schedulerRef?.current?.control?.multiselect.clear();
        dispatch(updateBookingSelection.request());
        dispatch(
            showRequestVacationModal({
                onSubmit: booking => {
                    return dispatch(createResourceOrVacationRequest(booking, addBookingsInScheduler));
                },
            })
        );
    }, [dispatch, addBookingsInScheduler]);

    const requestResourceModal = useCallback(() => {
        window.schedulerRef?.current?.control?.multiselect.clear();
        dispatch(updateBookingSelection.request());
        dispatch(
            showRequestResourceModal({
                onSubmit: booking => {
                    return dispatch(createResourceOrVacationRequest(booking, addBookingsInScheduler));
                },
            })
        );
    }, [dispatch, addBookingsInScheduler]);

    const resourceModal = useCallback(
        () =>
            dispatch(
                showResourceModal({
                    onSubmit: data => dispatch(createResource.request(data, undefined, true)),
                })
            ),
        [dispatch]
    );

    const scheduleResourceModal = useCallback(() => {
        return dispatch(
            showScheduleResourceModal({
                onSubmit: data => {
                    return createBooking(data.bookings, true, data.tasks);
                },
            })
        );
    }, [dispatch, createBooking]);

    const projectModal = useCallback(
        type =>
            dispatch(
                showProjectModal({ onSubmit: data => dispatch(createProject.request(data, undefined, true)), type })
            ),
        [dispatch]
    );

    const resourceGroupModal = useCallback(() => {
        dispatch(
            showSchedulerBuilderModal({
                itemTypeName: RESOURCE.name,
                itemId: null,
                groupType: GROUP,
                initialTab: TYPE_TAB,
            })
        );
    }, [dispatch]);

    const projectGroupModal = useCallback(() => {
        dispatch(
            showSchedulerBuilderModal({
                itemTypeName: PROJECT.name,
                itemId: null,
                groupType: GROUP,
                initialTab: TYPE_TAB,
            })
        );
    }, [dispatch]);

    const unassignedModal = useCallback(() => {
        if (!isUnassignedExtensionInstalled) {
            dispatch(showMissingUnassignedExtensionModal());
            return;
        }
        dispatch(
            showResourceModal({
                onSubmit: data => dispatch(createResource.request(data, undefined, shouldRedirect)),
                type: TYPE_UNASSIGNED.value,
            })
        );
    }, [dispatch, isUnassignedExtensionInstalled]);

    const scheduleModal = useCallback(() => {
        if (!isSmartSchedulesInstalled) {
            dispatch(showMissingSmartSchedulesExtensionModal());
            return;
        }

        // If has only rights to create project groups, then init with Project type
        const itemTypeName =
            hasCreateProjectGroupRights && !hasCreateResourceGroupRights ? PROJECT.name : RESOURCE.name;

        dispatch(
            showSchedulerBuilderModal({
                itemTypeName: itemTypeName,
                itemId: null,
                groupType: SCHEDULER_TEMPLATE,
                initialTab: TYPE_TAB,
            })
        );
    }, [dispatch, hasCreateProjectGroupRights, hasCreateResourceGroupRights, isSmartSchedulesInstalled]);

    const withTooltip = useCallback((innerJSX, tooltipText) => {
        return (
            <Tooltip title={tooltipText} placement="right" arrow>
                {innerJSX}
            </Tooltip>
        );
    }, []);

    const getNewScheduleItem = useCallback(() => {
        const innerJSX = (
            <DropdownButton
                onClick={scheduleModal}
                className={!isSmartSchedulesInstalled && classes.buttonDropdownWithEndIcon}
            >
                New schedule
                {!isSmartSchedulesInstalled && <Adjust className={classes.endIcon} />}
            </DropdownButton>
        );

        if (!isSmartSchedulesInstalled) {
            return withTooltip(innerJSX, smartSchedulesExtensionTooltipMessage);
        }

        return innerJSX;
    }, [classes.buttonDropdownWithEndIcon, classes.endIcon, withTooltip, isSmartSchedulesInstalled, scheduleModal]);

    const getNewUnassignedItem = useCallback(() => {
        const innerJSX = (
            <DropdownButton
                onClick={unassignedModal}
                className={!isUnassignedExtensionInstalled && classes.buttonDropdownWithEndIcon}
            >
                New unassigned
                {!isUnassignedExtensionInstalled && <Adjust className={classes.endIcon} />}
            </DropdownButton>
        );

        if (!isUnassignedExtensionInstalled) {
            return withTooltip(innerJSX, unassignedWorkExtensionTooltipMessage);
        }

        return innerJSX;
    }, [
        classes.buttonDropdownWithEndIcon,
        classes.endIcon,
        withTooltip,
        isUnassignedExtensionInstalled,
        unassignedModal,
    ]);

    const onClickDropdown = () => {
        window.notClearSchedulerSelection = true;
    };

    return (
        hasOneOfRights && (
            <UncontrolledDropdown nav inNavbar onClick={onClickDropdown}>
                <DropdownToggle nav caret>
                    <AddCircle fontSize="small" />
                </DropdownToggle>
                <PlusDropdown right className="rounded-0">
                    {canScheduleRights && (
                        <DropdownButton onClick={scheduleResourceModal}>
                            Schedule {resourceKeyWord.toLowerCase()}
                        </DropdownButton>
                    )}
                    {canScheduleRights && <DropdownItem divider />}
                    {hasCreateProjectRights && (
                        <DropdownButton onClick={() => projectModal(TYPE_REGULAR.value)}>
                            New {projectKeyWord.toLowerCase()}
                        </DropdownButton>
                    )}
                    {hasCreateResourceRights && (
                        <DropdownButton onClick={resourceModal}>New {resourceKeyWord.toLowerCase()}</DropdownButton>
                    )}
                    {(hasCreateProjectGroupRights || hasCreateResourceGroupRights) && getNewScheduleItem()}
                    {hasCreateProjectGroupRights && (
                        <DropdownButton onClick={() => projectGroupModal(TYPE_REGULAR.value)}>
                            New {projectKeyWord.toLowerCase()} group...
                        </DropdownButton>
                    )}
                    {hasCreateResourceGroupRights && (
                        <DropdownButton onClick={resourceGroupModal}>
                            New {resourceKeyWord.toLowerCase()} group...
                        </DropdownButton>
                    )}
                    {hasCreateProjectRights && (
                        <DropdownButton onClick={() => projectModal(TYPE_EVENT.value)}>New event</DropdownButton>
                    )}
                    {hasCreateResourceRights && getNewUnassignedItem()}
                    {(hasCreateResourceRights || hasCreateProjectRights || hasCreateProjectRights) && (
                        <DropdownItem divider />
                    )}
                    {canRequestVacations && (
                        <DropdownButton className="vacation-event text-truncate" onClick={vacationModal}>
                            Request {vacationEventName.toLowerCase()}
                        </DropdownButton>
                    )}
                    {canRequestResources && (
                        <DropdownButton onClick={requestResourceModal}>
                            Request a {resourceKeyWord.toLowerCase()}
                        </DropdownButton>
                    )}
                </PlusDropdown>
            </UncontrolledDropdown>
        )
    );
};

export default AddItem;
