import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, Form, Field, initialize, submit, formValueSelector } from 'redux-form';
import { Alarm } from '@material-ui/icons';
import { getResources } from 'actions/resourceActions';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema } from './formSchema';
import { chooseFieldMaterial, dateField, dropdownFieldMaterial, materialInputGroupField } from 'shared/formFields';
import CommonModalTemplate from 'shared/modal/commonModalTemplate';
import { actions, directions, moveTypes } from './data';
import { useAppKeyWords, useHasRights } from 'hooks';
import { normalizeIntNumber } from 'utils/formUtil';
import { makeGetPermittedResources } from 'selectors/resource';
import { bulkMoveBookings } from '../../../../actions/bookingActions';
import { selectCurrentSelectedBooking } from '../../../../selectors/scheduler';

export const BULK_MOVE_FORM_NAME = 'bulkMoveForm';
const selector = formValueSelector(BULK_MOVE_FORM_NAME);
const rights = [
    {
        rights: ['manageEvents'],
        rule: 'one',
        name: 'hasManageEventsRights',
    },
];

const BulkMoveModal = ({ handleSubmit, windowHeight, invalid, submitting }) => {
    const dispatch = useDispatch();
    const keyWords = useAppKeyWords();
    const { project, resourceInfo } = useSelector(selectCurrentSelectedBooking);
    const resourceSelector = useMemo(() => makeGetPermittedResources([resourceInfo._id]), [resourceInfo._id]);
    const resources = useSelector(resourceSelector);
    const { moveType, action, direction } = useSelector(state => selector(state, 'moveType', 'action', 'direction'));
    const resourceName = `${resourceInfo.firstName}${resourceInfo.lastName ? ` ${resourceInfo.lastName}` : ''}`;
    const { hasManageEventsRights } = useHasRights(rights);

    useEffect(() => {
        dispatch(getResources.request());
    }, [dispatch]);

    useEffect(() => {
        dispatch(initialize(BULK_MOVE_FORM_NAME, formSchema.getDefaultValues()));
    }, [dispatch]);

    const onSubmit = ({ action, ...values }) => {
        dispatch(
            bulkMoveBookings.request({
                values,
                bulkAction: action.value,
                resourceId: resourceInfo._id,
                projectId: project._id,
            })
        );
    };

    return (
        <CommonModalTemplate
            title="Bulk move bookings"
            description="Use the following options to move all bookings."
            windowHeight={windowHeight}
            disabledSaveButton={invalid || submitting}
            onSave={() => dispatch(submit(BULK_MOVE_FORM_NAME))}
            saveButtonText="Bulk update"
            saveButtonDataCy="button--bulk-update-modal"
        >
            <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                <Field
                    wrapperClassName="form-fields mt-4 px-2 pb-4"
                    label="What would you like to move?"
                    data={actions(hasManageEventsRights, project.name, resourceName, keyWords)}
                    filter={false}
                    valueField="value"
                    textField="label"
                    formHelperText="Choose the bookings you would like to move."
                    name="action"
                    component={dropdownFieldMaterial}
                />
                {(!action || 'NEW_RESOURCE' !== action?.type) && (
                    <Field
                        className="form-fields mt-4 px-2 pb-4"
                        name="numberOfDays"
                        icon={Alarm}
                        type="number"
                        label="Number of Days to Move Booking By:"
                        formHelperText="Enter the number of calendar days to move the bookings by."
                        min={1}
                        max={365}
                        normalize={normalizeIntNumber}
                        component={materialInputGroupField}
                    />
                )}
                {'NEW_RESOURCE' === action?.type && (
                    <Field
                        wrapperClassName="form-fields mt-4 px-2 pb-4"
                        label={`Choose ${keyWords.resourceKeyWord} to move bookings to:`}
                        data={resources}
                        filter={false}
                        valueField="_id"
                        textField={item => (item?.firstName ? `${item.firstName} ${item.lastName}` : '')}
                        formHelperText="choose the resource to move these bookings to."
                        name="resource"
                        component={dropdownFieldMaterial}
                    />
                )}
                {(!action || 'NEW_RESOURCE' !== action?.type) && (
                    <Field
                        wrapperClass="form-fields mt-4 px-2 pb-4"
                        name="direction"
                        type="radio"
                        value={direction}
                        options={directions()}
                        label="Which direction to move bookings?"
                        formHelperText="Direction to move bookings."
                        component={chooseFieldMaterial}
                    />
                )}
                <Field
                    wrapperClass="form-fields mt-4 px-2 pb-4"
                    name="moveType"
                    type="radio"
                    value={moveType}
                    options={moveTypes()}
                    label="Move Type"
                    formHelperText="Direction to move bookings."
                    component={chooseFieldMaterial}
                />
                {'DATE' === moveType && (
                    <Field
                        name="date"
                        className="form-fields mt-4 px-2 pb-4"
                        label="Choose Date to move bookings from:"
                        formHelperText="if the booking starts on this date or after it will be moved."
                        component={dateField}
                    />
                )}
            </Form>
        </CommonModalTemplate>
    );
};

BulkMoveModal.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    viewObject: PropTypes.object.isRequired,
};

export default reduxForm({
    form: BULK_MOVE_FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(BulkMoveModal);
