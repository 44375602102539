import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';
import { emailValidator, requiredValidator, lengthValidator } from 'utils/formValidators';
import { validateEmailFree } from 'api/account';

export const formSchema = new Schema(
    {
        email: {
            type: String,
            required: true,
            validators: [emailValidator],
        },
        password: {
            type: String,
            required: true,
            validators: [lengthValidator(8, 'Password')],
        },
        confirm: {
            type: Boolean,
            required: true,
            validators: [requiredValidator('You must agree to our terms of service')],
        },
    },
    errorMessages,
    false
);

export const asyncValidate = values =>
    validateEmailFree(values.email).then(data => {
        if (!data.available) {
            const errObj = { email: 'That email is taken' };
            throw errObj;
        }
    });
