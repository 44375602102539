import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { List, ListItem, ListItemText, Grid } from '@material-ui/core';
import { Toggle } from 'modules/modals/styles';
import { filter } from 'lodash';
import moment from 'moment';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

class ExistingResourceRates extends PureComponent {
    static propTypes = {
        existingResourceRatesAndNotCustom: PropTypes.array,
        projectKeyWord: PropTypes.string,
        resourcePluralKeyWord: PropTypes.string,
        type: PropTypes.string,
        rates: PropTypes.array,
    };

    static defaultProps = {
        existingResourceRatesAndNotCustom: [],
        type: 'external',
        projectKeyWord: '',
        resourcePluralKeyWord: '',
        rates: [],
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: false };
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    render() {
        const { existingResourceRatesAndNotCustom, projectKeyWord, resourcePluralKeyWord, type } = this.props;

        const resourcesWithExternalRates = filter(
            existingResourceRatesAndNotCustom,
            resource => resource.resourceRates.external.length > 0
        );

        const resourcesWithInternalRates = filter(
            existingResourceRatesAndNotCustom,
            resource => resource.resourceRates.internal.length > 0
        );

        const resourceRateJSX = (resource, i, rate) => {
            const rateInfo = this.props.rates.find(rateTmp => rateTmp._id === rate.defaultRateId);
            const name = `${resource.firstName} ${resource.lastName}`;
            const ratePrice = rateInfo ? `${rateInfo.currency} ${rateInfo.rate}` : resource.companyBillingRate;
            const from = rate.effectiveFrom ? moment(rate.effectiveFrom).format('YYYY-MM-DD') : null;
            const to = rate.effectiveTo ? moment(rate.effectiveTo).format('YYYY-MM-DD') : null;
            const rangeString = from || to ? `${from} to ${to || 'No End Date'}` : '';

            return (
                <ListItem key={`${i}-${rate.defaultRateId}`}>
                    <PersonOutlineIcon style={{ marginRight: '5px' }} />
                    <ListItemText primary={`${name} - ${ratePrice}/hr`} secondary={rangeString ? rangeString : null} />
                </ListItem>
            );
        };

        return (
            <div>
                <i style={{ width: '8px' }} className={`fa fa-caret-${this.state.collapse ? 'down' : 'right'}`} />
                &nbsp;
                <Toggle onClick={this.toggle} className="mt-1 d-inline">
                    {(type === 'external' ? resourcesWithExternalRates : resourcesWithInternalRates).length}{' '}
                    {resourcePluralKeyWord} / Unassigned Category on this {projectKeyWord} have existing {type} Rates
                </Toggle>
                <Collapse isOpen={this.state.collapse}>
                    <Grid item xs={12} md={6}>
                        <div>
                            <List>
                                {(type === 'external' ? resourcesWithExternalRates : resourcesWithInternalRates).map(
                                    (resource, i) => {
                                        const resourceRates =
                                            type === 'external'
                                                ? resource.resourceRates.external
                                                : resource.resourceRates.internal;
                                        return resourceRates.map(rate => {
                                            return resourceRateJSX(resource, i, rate);
                                        });
                                    }
                                )}
                            </List>
                        </div>
                    </Grid>
                </Collapse>
            </div>
        );
    }
}

export default ExistingResourceRates;
