import styled from 'styled-components';

export const ConfirmationWrapper = styled.div`
    position: absolute;
    top: -48px;
    left: -59px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px -1px 6px 0px;
    background: #fff;
    font-size: 13px;
    width: 165px;
    text-align: left;
    z-index: 9;
    cursor: pointer;
    &:hover {
        background-color: #f4f4f6;
    }
    .arrow {
        width: 22px;
        position: absolute;
        height: 13px;
        bottom: -13px;
        left: 50%;
        margin-left: -11px;
        overflow: hidden;
        &:after {
            top: -8px;
            left: 3px;
            content: '';
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: #fff;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            box-shadow: rgba(0, 0, 0, 0.3) 0px -1px 6px 0px;
        }
    }
`;
