import React from 'react';
import PropTypes from 'prop-types';

const EmptyText = ({ emptyText }) => (
    <li className="metismenu-item">
        <span className="metismenu-link empty-text">{emptyText}</span>
    </li>
);

EmptyText.defaultProps = {
    emptyText: '',
};

EmptyText.propTypes = {
    emptyText: PropTypes.string,
};

export default EmptyText;
