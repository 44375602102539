import { includes } from 'lodash';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import {
    differentTypesHandler,
    displayCurrencyValue,
    displayValue,
    isHeaderDisabled,
} from 'modules/report/utils/columnHelper';
import React from 'react';
import { contains } from 'underscore';
import { formatNumber } from 'utils/formatingUtil';
import { Header } from './../styles';

/**
 * @param {array}  columnsToLoad
 * @param {string} defaultCurrency
 *
 * @returns {*[]}
 */
export const columns = (columnsToLoad, defaultCurrency) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Fixed Costs - Scheduled
                        <p className="sub-title">(cash amount)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'fixedCostsScheduled',
        sortable: false,
        show: contains(columnsToLoad, 'fixedCostsScheduled'),
        accessor: row =>
            displayValue(row.fixedCostsScheduled, displayCurrencyValue(row, 'fixedCostsScheduled', defaultCurrency)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Fixed Costs - Reported
                        <p className="sub-title">(cash amount)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'fixedCostsActual',
        sortable: false,
        show: contains(columnsToLoad, 'fixedCostsActual'),
        accessor: row =>
            displayValue(row.fixedCostsActual, displayCurrencyValue(row, 'fixedCostsActual', defaultCurrency)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget Category Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'budgetCategoryName',
        sortable: false,
        show: includes(columnsToLoad, 'budgetCategoryName'),
        accessor: row =>
            displayValue(row.budgetCategoryName, differentTypesHandler(row.budgetCategoryName, 'Categories')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget
                        <p className="sub-title">(cash amount)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'budgetCash',
        sortable: false,
        show: contains(columnsToLoad, 'budgetCash'),
        accessor: row => displayValue(row.budgetCash, displayCurrencyValue(row, 'budgetCash', defaultCurrency)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Budget
                        <p className="sub-title">(hours amount)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'budgetHours',
        sortable: false,
        show: contains(columnsToLoad, 'budgetHours'),
        accessor: row => differentTypesHandler(displayValue(row.budgetHours), 'Budgets'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Budget Hours Remaining
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'estimatedBudgetHoursRemaining',
        sortable: false,
        show: contains(columnsToLoad, 'estimatedBudgetHoursRemaining'),
        accessor: row =>
            differentTypesHandler(
                displayValue(row.estimatedBudgetHoursRemaining, formatNumber(row.estimatedBudgetHoursRemaining)),
                'Budgets'
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Budget Cash Remaining
                        <p className="sub-title">(ext rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'externalEstimatedBudgetCashRemaining',
        sortable: false,
        show: contains(columnsToLoad, 'externalEstimatedBudgetCashRemaining'),
        accessor: row =>
            displayValue(
                row.externalEstimatedBudgetCashRemaining,
                displayCurrencyValue(row, 'externalEstimatedBudgetCashRemaining', defaultCurrency)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Budget Cash Remaining
                        <p className="sub-title">(int rate)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'internalEstimatedBudgetCashRemaining',
        sortable: false,
        show: contains(columnsToLoad, 'internalEstimatedBudgetCashRemaining'),
        accessor: row =>
            displayValue(
                row.internalEstimatedBudgetCashRemaining,
                displayCurrencyValue(row, 'internalEstimatedBudgetCashRemaining', defaultCurrency)
            ),
    },
];
