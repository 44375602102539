import datesEvent from './datesEvent';
import milestonePhase from './milestonePhase';
import external from './external';

export default (
    companyWeekdays,
    viewObject,
    isDependencyExtensionInstalled,
    { updatePhase, updateMilestone }
) => args => {
    if (args.external) {
        external(args, viewObject);
    } else if (args.e.data.phase || args.e.data.milestone) {
        milestonePhase(args, updatePhase, updateMilestone);
    } else if (args.e.data.datesEvent) {
        datesEvent(args);
    }

    if (args.multimove.length > 1) {
        args.control.multiselect.clear();
    }
};
