import { filter, sortBy } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import createReducer from 'reducers/helpers/createReducer';
import { updateItemInArray, updateObject } from 'reducers/helpers/updater';
import { removeUTCZuluFromDateTimestamp } from 'utils/DateUtil';
import { arrayToObjectByKey } from '../utils/mappingUtil';

const initialProjectState = {
    isCreating: false,
    isUpdating: false,
    projects: [],
    project: {},
    loading: false,
    lastCallContext: undefined,
};

const setCreateLoader = state =>
    updateObject(state, {
        isCreating: true,
    });

const updateLoaders = state =>
    updateObject(state, {
        isCreating: false,
        isUpdating: false,
    });

const setUpdateLoader = state =>
    updateObject(state, {
        isUpdating: true,
    });

const setProjects = (state, action) =>
    updateObject(state, {
        projects: sortBy(action.payload.projects, project => project.name.toLowerCase()).map(project => {
            return {
                ...project,
                resourcesMapped: (project.resources || []).reduce((acc, resId) => {
                    acc[resId] = resId;
                    return acc;
                }, {})
            }
        }),
        lastCallContext: action.payload.lastCallContext,
        loading: false,
    });

const setProjectsRequest = state =>
    updateObject(state, {
        loading: true,
    });

const handleGetProjectsStop = state =>
    updateObject(state, {
        loading: false,
    });

const setProject = (state, action) => {
    const { project } = action.payload;

    const newProject = {
        ...project,
        phases: project.phases?.length
            ? project.phases.map(phase => ({
                  ...phase,
                  start: removeUTCZuluFromDateTimestamp(phase.start),
                  end: removeUTCZuluFromDateTimestamp(phase.end),
              }))
            : [],
    };

    return updateObject(state, {
        project: newProject,
    });
};

const resetProjectValue = (state, action) =>
    updateObject(state, {
        [action.payload.propertyName]: initialProjectState[action.payload.propertyName],
    });

const addProject = (state, action) => {
    const projects = [...state.projects];
    if (!projects.length) {
        return updateObject(state, {
            isCreating: false,
        });
    }
    projects.push(action.payload.project);

    return updateObject(state, {
        projects: sortBy(projects, project => project.name.toLowerCase()),
        isCreating: false,
    });
};

const deleteProject = (state, action) => {
    if (state.projects.length && action.payload.id) {
        const projects = filter(state.projects, project => project._id !== action.payload.id);

        return updateObject(state, {
            projects,
        });
    }

    return state;
};

const updateProject = (state, action) =>
    updateObject(state, {
        projects: sortBy(
            updateItemInArray(state.projects, action.payload.project._id, project =>
                updateObject(project, action.payload.project)
            ),
            project => project.name.toLowerCase()
        ),
        project:
            state.project._id === action.payload.project._id
                ? { ...state.project, ...action.payload.project }
                : state.project,
        isUpdating: false,
    });

const updateResource = (state, action) => {
    const id = action.payload.resource._id;
    if (!action.payload.additionalInfo) {
        return state;
    }
    const { projectsAdd = [], projectsRemove = [] } = action.payload.additionalInfo;

    const addResource = project => ({
        ...project,
        resourceIds: [...(project.resourceIds || []), id],
        resources: [...(project.resources || []), id],
    });
    const removeResource = project => ({
        ...project,
        resourceIds: (project.resourceIds || []).filter(_id => _id !== id),
        resources: (project.resources || []).filter(_id => _id !== id),
    });

    const updateProject = project =>
        (projectsAdd || []).includes(project._id)
            ? addResource(project)
            : projectsRemove.includes(project._id)
            ? removeResource(project)
            : project;

    const projects = state.projects.map(updateProject);
    const project = updateProject(state.project);

    return updateObject(state, {
        projects,
        project,
    });
};

const addResourceToProject = (state, action) => {
    const mappedUpdatedProject = arrayToObjectByKey(action.payload.updatedProjects, '_id');

    return {
        ...state,
        projects: (state.projects ?? []).map(project => {
            const updatedProject = mappedUpdatedProject[project._id];

            if (!updatedProject) {
                return project;
            }

            return {
                ...project,
                resourceIds: updatedProject.resources,
                resources: updatedProject.resources,
            };
        }),
    };
};

const handleCreateBookings = (state, action) => {
    const { bookings = [] } = action.payload;

    const resourcesToAdd = bookings.reduce((acc, booking) => {
        if (!acc[booking.project._id]) {
            acc[booking.project._id] = [];
        }

        acc[booking.project._id].push(booking.resource._id);

        return acc;
    }, {});

    return {
        ...state,
        projects: (state.projects ?? []).map(project => {
            const resourcesToAddForProject = resourcesToAdd[project._id];

            if (!resourcesToAdd[project._id]) {
                return project;
            }

            const newResources = Array.from(new Set([...(project.resourceIds || []), ...resourcesToAddForProject]));

            return {
                ...project,
                resourceIds: newResources || [],
                resources: newResources || [],
            };
        }),
    };
};

export default createReducer(initialProjectState, {
    [actionTypes.GET_PROJECTS['SUCCESS']]: setProjects,
    [actionTypes.GET_PROJECTS['REQUEST']]: setProjectsRequest,
    [actionTypes.GET_PROJECTS_STOP]: handleGetProjectsStop,
    [actionTypes.CREATE_PROJECT['REQUEST']]: setCreateLoader,
    [actionTypes.CREATE_PROJECT['SUCCESS']]: addProject,
    [actionTypes.GET_PROJECT['SUCCESS']]: setProject,
    [actionTypes.DELETE_PROJECT['SUCCESS']]: deleteProject,
    [actionTypes.UPDATE_PROJECT['SUCCESS']]: updateProject,
    [actionTypes.UPDATE_RESOURCE['SUCCESS']]: updateResource,
    [actionTypes.UPDATE_PROJECT['REQUEST']]: setUpdateLoader,
    [actionTypes.UPDATE_PROJECT['FAILURE']]: updateLoaders,
    [actionTypes.CREATE_PROJECT['FAILURE']]: updateLoaders,
    [actionTypes.RESET_PROJECT_VALUE['REQUEST']]: resetProjectValue,
    [actionTypes.ADD_RESOURCE_TO_PROJECT['SUCCESS']]: addResourceToProject,
    [actionTypes.CREATE_BOOKING['SUCCESS']]: handleCreateBookings,
});
