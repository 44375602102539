import React from 'react';
import PropTypes from 'prop-types';
import { Description } from './styles';
import Button from 'shared/components/mui-button';
import { Layout } from '../components/layout';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { useHasRights } from '../../hooks';

const rights = [
    {
        rights: ['settingViewExt', 'settingAddExt'],
        rule: 'oneOf',
        name: 'canViewSettingExt',
    },
];

const MissingExtension = ({ description, imgUrl, title, additionalBtnText, additionalBtnAction, className }) => {
    const classes = useStyles();

    const { canViewSettingExt } = useHasRights(rights);

    return (
        <Layout className={classnames(className, 'mt-3 offset-md-2 col-md-8')}>
            <Layout stack gap="16px" flex="1 1 auto">
                <div className={classes.imgWrapper}>
                    <img src={imgUrl} width="300px" />
                </div>
                <h5 className="text-center mb-3">{title}</h5>
                <Description className="text-center d-inline-block mb-4">{description}</Description>
                <Layout hAlign="center" gap="8px">
                    {additionalBtnText && additionalBtnAction ? (
                        <Button
                            className="mt-4"
                            variant="outlined"
                            dataCy="button--missing-extension-additional"
                            onClick={additionalBtnAction}
                        >
                            {additionalBtnText}
                        </Button>
                    ) : null}
                    {canViewSettingExt ? (
                        <Button
                            className="mt-4"
                            variant="contained"
                            dataCy="button--manage-extensions"
                            onClick={e => {
                                e.preventDefault();
                                window.location.href = '/settings#extensions';
                            }}
                        >
                            Manage Extensions
                        </Button>
                    ) : null}
                </Layout>
            </Layout>
        </Layout>
    );
};

const useStyles = makeStyles({
    imgWrapper: {
        textAlign: 'center',
    },
});

MissingExtension.propTypes = {
    description: PropTypes.string,
    imgUrl: PropTypes.string,
    title: PropTypes.string,
};

MissingExtension.defaultProps = {
    description: '',
    title: 'Missing Extension',
    imgUrl: 'https://d329beqc2zk6g7.cloudfront.net/img/mising_extension_generic.png',
};

export default MissingExtension;
