import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Search, Fullscreen, FullscreenExit, Settings, AttachMoney } from '@material-ui/icons';
import { DropdownToggle, Nav, Navbar, NavItem, NavLink, Dropdown as ControlledDropdown } from 'reactstrap';
import { useHasRights } from 'hooks';
import { fullScreen } from 'actions/initializeActions';
import FinancialOptions from 'modules/scheduler/components/dropdowns/financialOptions';
import { useGetViewObject, useDisplayPermissions } from 'modules/scheduler/hooks';
import Customization from './customization/customization';
import ScheduleSaver from './scheduleSaver';
import BuilderOpener from './BuilderOpener';
import { DropdownMenu } from 'shared/dropdownMenu';
import Export from './export';

const rights = [
    {
        rights: ['settingProjectBudget'],
        rule: 'one',
        name: 'hasRightToSeeFinancialData',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'hasResourceGroupsRights',
    },
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'hasProjectGroupsRights',
    },
];

const HeaderToolbar = React.forwardRef((props, schedulerRef) => {
    const { showFinancialOptions, hasFilterRights, toggleFilter, name } = props;
    const dispatch = useDispatch();
    const { isProjectView, isResourceView } = useGetViewObject();
    const [dropdownOpen, toggle] = useState(false);
    const [customizationSectionOpen, toggleCustomizationSection] = useState(false);
    const { hasRightToSeeFinancialData, hasProjectGroupsRights, hasResourceGroupsRights } = useHasRights(rights);
    const isFullScreen = useSelector(state => state.initialAppReducer.isFullScreen);
    const { shouldDisplayEditButton } = useDisplayPermissions();

    const toggleFullScreen = useCallback(
        event => {
            event.preventDefault();
            dispatch(fullScreen.request(!isFullScreen));
        },
        [dispatch, isFullScreen]
    );

    return (
        <div className="float-right">
            <Navbar color="" light expand="sm" className="p-0">
                <Nav className="ml-auto" navbar>
                    {shouldDisplayEditButton && (
                        <BuilderOpener />
                    )}
                    {((isProjectView && hasProjectGroupsRights) || (isResourceView && hasResourceGroupsRights)) && (
                        <ScheduleSaver name={name} />
                    )}
                    {showFinancialOptions && hasRightToSeeFinancialData && (
                        <ControlledDropdown
                            className="py-2 px-3"
                            isOpen={dropdownOpen}
                            toggle={() => toggle(!dropdownOpen)}
                            style={{ borderRight: '1px solid #e4e4e4' }}
                            data-cy="scheduler-header-finance"
                        >
                            <DropdownToggle nav title="Financial">
                                <AttachMoney />
                            </DropdownToggle>
                            <DropdownMenu className="rounded-0" width={305}>
                                {dropdownOpen && <FinancialOptions onUpdate={() => toggle(!dropdownOpen)} />}
                            </DropdownMenu>
                        </ControlledDropdown>
                    )}
                    <Export schedulerRef={schedulerRef} />
                    {hasFilterRights && (
                        <NavItem
                            className="py-2 px-3"
                            style={{ borderRight: '1px solid #e4e4e4' }}
                            title="Search"
                            data-cy="scheduler-header-search"
                        >
                            <NavLink href="#" onClick={toggleFilter}>
                                <Search />
                            </NavLink>
                        </NavItem>
                    )}
                    <NavItem
                        className="py-2 px-3"
                        style={{ borderRight: '1px solid #e4e4e4' }}
                        data-cy="scheduler-header-fullscreen"
                    >
                        <NavLink
                            href="#"
                            onClick={toggleFullScreen}
                            title={`${isFullScreen ? 'Exit ' : ''}Full Screen`}
                        >
                            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                        </NavLink>
                    </NavItem>
                    <NavItem className="py-2 px-3" data-cy="scheduler-header-edit">
                        <NavLink
                            href="#"
                            onClick={() => toggleCustomizationSection(!customizationSectionOpen)}
                            title="Customization"
                        >
                            <Settings />
                        </NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
            <Customization
                onClose={() => toggleCustomizationSection(!customizationSectionOpen)}
                isOpen={customizationSectionOpen}
                schedulerRef={schedulerRef}
            />
        </div>
    );
});

HeaderToolbar.propTypes = {
    showFinancialOptions: PropTypes.bool.isRequired,
    hasFilterRights: PropTypes.bool.isRequired,
    toggleFilter: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
};

export default HeaderToolbar;
