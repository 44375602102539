import { Divider } from '@material-ui/core';
import { showProjectModal } from 'actions/modalActions';
import { useAppKeyWords, useProjectAction } from 'hooks';
import ScheduleForm from 'modules/scheduler/components/dropdowns/schedule/partials/legacy/scheduleForm';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { usePhases } from 'modules/scheduler/hooks';
import { useMenuBookingPermissions, useMenuProjectPermissions } from 'modules/scheduler/hooks/useMenuPermissions';
import { useMilestones } from 'modules/scheduler/hooks/useMilestones';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NestedMenuItem, { NESTED_MENU_ITEM_SCROLLBAR_HEIGHT } from 'shared/nestedMenuItem';
import Scrollbar from 'shared/scrollbar';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { KeyBoardShortCutIcon } from '../../../booking/partials/legacy/styles';
import getPasteBookingDisabled from '../utils/getPasteBookingDisabled';
import DatesForm from './datesForm';
import useKeepTimeRangeSelectedClassicMenu from './hooks/useKeepTimeRangeSelectedClassicMenu';
import useForm from './hooks/useScheduleResourceForm';
import MilestoneForm from './milestoneForm';
import PhaseForm from './phaseForm';
import { useResourceRequestRoles } from '../../../../../services/resourceRequest';

const LegacyProjectSingleMode = React.forwardRef((props, schedulerRef) => {
    const { onClose, onClick, rowTags, isRequestMode, combineScheduleRequest, onPaste } = props;
    const dispatch = useDispatch();
    const preparedRowTags = rowTags || {};

    const {
        hasSettingPhase,
        hasSettingProjectMilestones,
        canAddDates,
        hasAddEditProjectRights,
    } = useMenuProjectPermissions();

    const {
        showScheduleButton,
        checkScheduleRights,
        showRequestButton,
        hasRightsToUseForm,
    } = useMenuBookingPermissions(isRequestMode, combineScheduleRequest, preparedRowTags);
    const { canRequestResource } = useResourceRequestRoles();

    const { project } = preparedRowTags;
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const currentSelection = useSelector(state => state.scheduler.currentSelection);
    const clipboard = useSelector(state => state.scheduler.clipboard);
    const keyWord =
        isRequestMode || (combineScheduleRequest && showRequestButton && !showScheduleButton) ? 'Request' : 'Schedule';
    const scale = schedulerRef.current.control.scale;
    const hourScale = scale === CELLDURATION.value;

    const { updateProject } = useProjectAction(schedulerRef);
    const { createPhase } = usePhases(schedulerRef, onClose);
    const { createMilestone } = useMilestones(schedulerRef, onClose);

    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => updateProject(project._id, data),
                type: project.type,
                projectId: project._id,
            })
        );
    }, [dispatch, project, updateProject, onClose]);

    const {
        formik,
        handleSubmitSchedule,
        handleSubmitRequest,
        values,
        isSubmitting,
        updateFields,
        updateProject: updateProjectFormValue,
        updateCategory,
        projectCategoryGroups,
        resources,
        updateDates,
        handleAllocationTypeChange,
        handleAllocationValueChange,
    } = useForm(onClick, isRequestMode, combineScheduleRequest, currentSelection, scale, project);

    useKeepTimeRangeSelectedClassicMenu(schedulerRef, currentSelection);

    const hasFullScheduleRights = checkScheduleRights(project, values.resource);

    const canRequest = useMemo(() => {
        return canRequestResource({
            resource: values.resource,
            projectOrEvent: project,
        });
    }, [canRequestResource, project, values.resource]);

    const scheduleButton = {
        onClick: handleSubmitSchedule,
        disabled: !hasFullScheduleRights,
    };

    const requestButton = {
        onClick: handleSubmitRequest,
        disabled: !canRequest,
    };

    let modifierKeyPrefix = 'CTRL +'; // control key
    if (window.navigator.platform.indexOf('Mac') === 0) {
        modifierKeyPrefix = '⌘'; // command key
    }

    return (
        <>
            {!isRequestMode && (
                <WrappedMenuItem
                    data-cy="button-classic-menu-paste"
                    onClick={onPaste}
                    disabled={getPasteBookingDisabled(clipboard, rowTags)}
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2 mt-2"
                >
                    Paste
                    <KeyBoardShortCutIcon>{modifierKeyPrefix} V</KeyBoardShortCutIcon>
                </WrappedMenuItem>
            )}
            <Divider />
            {hasRightsToUseForm && (
                <NestedMenuItem label={`${keyWord} ${resourceKeyWord.toLowerCase()}`} parentMenuOpen={true} width={322}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <ScheduleForm
                            formik={formik}
                            keyword={resourceKeyWord}
                            isSubmitting={isSubmitting}
                            formValues={values}
                            updateFields={updateFields}
                            updateProject={updateProjectFormValue}
                            updateCategory={updateCategory}
                            updateDates={updateDates}
                            resources={resources}
                            currentScale={scale}
                            projectCategoryGroups={projectCategoryGroups}
                            scheduleButton={showScheduleButton ? scheduleButton : undefined}
                            requestButton={showRequestButton ? requestButton : undefined}
                            onAllocationTypeChange={handleAllocationTypeChange}
                            onAllocationValueChange={handleAllocationValueChange}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {hasSettingProjectMilestones && !hourScale && (
                <NestedMenuItem label="Create milestone" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <MilestoneForm
                            project={project}
                            currentSelection={currentSelection}
                            onClick={createMilestone}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {hasSettingPhase && !hourScale && (
                <NestedMenuItem label="Create phase" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <PhaseForm project={project} currentSelection={currentSelection} onClick={createPhase} />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {canAddDates && !hourScale && (
                <NestedMenuItem label="Start & end dates" parentMenuOpen={true} width={300}>
                    <Scrollbar autoHeight autoHeightMax={NESTED_MENU_ITEM_SCROLLBAR_HEIGHT} noHorizontalScrollbar>
                        <DatesForm
                            project={project}
                            onClose={onClose}
                            updateProject={updateProject}
                            currentSelection={currentSelection}
                        />
                    </Scrollbar>
                </NestedMenuItem>
            )}
            {!isRequestMode && project.hasRightsToProject && hasAddEditProjectRights && <Divider />}
            {!isRequestMode && project.hasRightsToProject && hasAddEditProjectRights && (
                <WrappedMenuItem
                    onClick={projectModal}
                    className="rounded ml-2 mr-2 pl-4 pt-2 pb-2"
                    data-cy="button-edit"
                >
                    Edit {projectKeyWord.toLowerCase()}...
                </WrappedMenuItem>
            )}
        </>
    );
});

LegacyProjectSingleMode.propTypes = {
    onClick: PropTypes.func.isRequired,
    scale: PropTypes.string.isRequired,
    isRequestMode: PropTypes.bool.isRequired,
    onPaste: PropTypes.func.isRequired,
    rowTags: PropTypes.object,
};

LegacyProjectSingleMode.defaultProps = {
    rowTags: {},
};

export default LegacyProjectSingleMode;
