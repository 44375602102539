import { find, contains, filter, each } from 'underscore';
import { DEFAULT } from 'enums/categoryGroupTypeEnum';
import { store } from '../../store';

export const getDefaultCategoryByProject = (project, categoryTemplates, categoryGroups) => {
    if (!project || !project.categoryGroups || categoryGroups.length === 0 || categoryTemplates.length === 0) {
        return {};
    }

    if (!project.defaultCategoryTemplate) {
        const projFromStore = (store.getState()?.projectReducer?.projects || []).find(
            proj => proj._id === project?._id || proj._id === project?.id
        );
        if (projFromStore?.defaultCategoryTemplate) {
            project.defaultCategoryTemplate = projFromStore?.defaultCategoryTemplate;
        }
    }

    if (project.defaultCategoryTemplate) {
        const defaultCategoryTemplateForProject = find(
            categoryTemplates,
            categoryTemplate => categoryTemplate._id === project.defaultCategoryTemplate && !categoryTemplate.deleted
        );
        // check that this defaultCategoryTemplateForProject is still in project groups
        if (defaultCategoryTemplateForProject) {
            const groupToCheck = find(categoryGroups, group =>
                contains(group.categoryTemplates, defaultCategoryTemplateForProject._id)
            );
            if (contains(project.categoryGroups, groupToCheck._id)) {
                return defaultCategoryTemplateForProject;
            }
        }
    }

    const defaultCategoryGroup = find(categoryGroups, categoryGroup => DEFAULT === categoryGroup.type);
    const defaultCategoryTemplate = find(
        categoryTemplates,
        categoryTemplate =>
            DEFAULT === categoryTemplate.type && contains(defaultCategoryGroup.categoryTemplates, categoryTemplate._id)
    );

    // if project is not assigned to any group or is assigned in particular to default group
    // we return default category from default group
    if (0 === project.categoryGroups.length || contains(project.categoryGroups, defaultCategoryGroup._id)) {
        return defaultCategoryTemplate;
    }
    // else if project is assigned to some non-default group we return default category from first assigned group

    const firstAssignedCategoryGroup = find(
        categoryGroups,
        categoryGroup => project.categoryGroups[0] === categoryGroup._id && !categoryGroup.deleted
    );
    if (firstAssignedCategoryGroup) {
        return find(
            categoryTemplates,
            categoryTemplate =>
                DEFAULT === categoryTemplate.type &&
                contains(firstAssignedCategoryGroup.categoryTemplates, categoryTemplate._id)
        );
    }

    return defaultCategoryTemplate;
};
export const getDefaultCategoryTemplate = (categoryGroups, categoryTemplates) => {
    if (!categoryGroups || !categoryTemplates) {
        return {};
    }

    const defaultCategoryGroup = find(categoryGroups, categoryGroup => DEFAULT === categoryGroup.type);

    return defaultCategoryGroup
        ? find(
              categoryTemplates,
              categoryTemplate =>
                  DEFAULT === categoryTemplate.type &&
                  contains(defaultCategoryGroup.categoryTemplates, categoryTemplate._id)
          ) || {}
        : {};
};

function getCategoryTemplatesWithGroupNames(categoryGroups, categoryTemplates) {
    const _categoryTemplates = [];
    each(categoryTemplates, categoryTemplate => {
        const foundSelectedGroup = find(categoryGroups, categoryGroup =>
            contains(categoryGroup.categoryTemplates, categoryTemplate._id)
        );
        if (foundSelectedGroup) {
            _categoryTemplates.push({ ...categoryTemplate, groupName: foundSelectedGroup.name });
        }
    });

    _categoryTemplates.sort((a, b) => {
        if (a.type === DEFAULT && b.type !== DEFAULT) return -1;
        else if (b.type === DEFAULT && a.type !== DEFAULT) return 1;
        else return a.name.localeCompare(b.name);
    });

    return _categoryTemplates;
}

export const getCategoryTemplatesByProject = (project, categoryGroups, categoryTemplates) => {
    if (!project || !project.categoryGroups) {
        return getCategoryTemplatesWithGroupNames(categoryGroups, categoryTemplates);
    }

    const projectCategoryGroups = filter(categoryGroups, categoryGroup =>
        project && project.categoryGroups && project.categoryGroups.length
            ? contains(project.categoryGroups, categoryGroup._id)
            : DEFAULT === categoryGroup.type
    );
    return getCategoryTemplatesWithGroupNames(projectCategoryGroups, categoryTemplates);
};
