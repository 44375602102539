/* eslint-env browser */
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showSearchModal } from 'actions/modalActions';
import { Wrapper, QuickSearch } from './styles';
import { detect } from 'detect-browser';

const K_KEY_CODE = 75;

const SearchItem = ({ icon: Icon }) => {
    const dispatch = useDispatch();
    const openSearchModal = useCallback(() => dispatch(showSearchModal()), [dispatch]);
    const handleKeyDown = useCallback(
        event => {
            const key = event.keyCode ? event.keyCode : event.which;
            if ((K_KEY_CODE === key && event.metaKey) || (K_KEY_CODE === key && event.ctrlKey)) {
                event.preventDefault();
                openSearchModal();
            }
        },
        [openSearchModal]
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown, true);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, true);
        };
    });

    return (
        <Wrapper>
            <QuickSearch onClick={openSearchModal}>
                <Icon />
                <span className="quick-search-text">Quick Navi</span>
                <span className="quick-search-hotkey">{'Mac OS' === detect().os ? '⌘K' : 'Ctrl + K'}</span>
            </QuickSearch>
        </Wrapper>
    );
};

export default SearchItem;
