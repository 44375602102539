import { MANUAL, DESCENDING } from 'modules/scheduler/enums/orderTypesEnum';
import { fromPairs, toPairs, invert, flow, sortBy } from 'lodash/fp';

/**
 * @param {object} groupOrder
 * @param {array}  groups
 *
 * @returns {*[]}
 */
export const orderGroups = (groupOrder, groups) => {
    try {
        let sortedGroups;
        if (MANUAL.value !== groupOrder.orderType) {
            sortedGroups = flow(
                sortBy(group => group.name.toLowerCase()),
                sortBy(group => 'SYSTEM' !== group.groupType)
            )(groups);

            if (DESCENDING.value === groupOrder.orderType) {
                sortedGroups.reverse();
            }

            return sortedGroups;
        }

        const sortObj = flow(toPairs, fromPairs, invert)(groupOrder.sortedIds);

        return sortBy(group => parseInt(sortObj[group._id]), groups);
    } catch {
        return flow(
            sortBy(group => group.name.toLowerCase()),
            sortBy(group => 'SYSTEM' !== group.groupType)
        )(groups);
    }
};
