export const scrollStyles = {
    '&::-webkit-scrollbar': {
        width: `5px`,
    },
    '&::-webkit-scrollbar-track': {
        background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#c8c9d2',
        borderRadius: '4px',
        opacity: 0.6,
        cursor: 'pointer',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#c8c9d2',
        opacity: 1,
    },
};
