/* eslint-env browser */
import { hasRole } from 'utils/rightsUtil';
import { hasManageApprovalForBooking } from './utils/hasManageApprovalForBooking';
import { WAITING_FOR_APPROVAL } from '../../../../enums/bookingTypeEnum';
import { isAbleToEditResourceOrVacationRequest } from '../../../request/utils/permissions';
import { updateDeadlinesVisibility, updateVisibleLinksOnScheduler } from '../../utils/schedulerUtil';
import { TYPE_UNASSIGNED } from '../../../../enums/resourceEnum';

export default ({
    resourceRoleRights,
    showEditContextMenu,
    editParentContextMenu,
    editDeadlineContextMenu,
    updateBookingSelection,
    accountResourceId,
    vacationId,
    hasScheduleRightsForResourceAndProject,
}) => args => {
    const selectedEvent = args.e.data;
    const scheduler = args.e.calendar;
    const isRegularBooking =
        !selectedEvent.phase && !selectedEvent.milestone && !selectedEvent.datesEvent && !selectedEvent.deadline;

    scheduler.clearSelection();
    scheduler.multiselect.clear();
    scheduler.multiselect.add(args.e);

    const isAbleToEditRequest = isAbleToEditResourceOrVacationRequest(
        selectedEvent,
        accountResourceId,
        resourceRoleRights,
        vacationId
    );

    const projectId = selectedEvent.project?._id || selectedEvent.booking?.project?._id;
    const resourceId = selectedEvent.resourceInfo?._id || selectedEvent.resource;

    const hasScheduleRight = hasScheduleRightsForResourceAndProject(
        projectId,
        resourceId,
        selectedEvent.type === TYPE_UNASSIGNED.value
    );

    const shouldBeBookingMenuDisabled =
        !args.e.data.hasEditRights || args.e.data.private || !args.e.data.isPermitted || !hasScheduleRight;

    updateBookingSelection(selectedEvent);

    updateDeadlinesVisibility();
    updateVisibleLinksOnScheduler();

    const clearSelection = () => {
        scheduler.clearSelection();
    };

    const schedulerRef = {
        current: {
            control: {
                events: scheduler.events,
                rows: scheduler.rows,
                scale: scheduler.scale,
                mode: scheduler.mode,
                clearSelection,
            },
        },
    };

    const coords = {
        left: args.originalEvent.clientX,
        top: args.originalEvent.clientY,
    };

    if (isRegularBooking) {
        if (
            (selectedEvent.type !== WAITING_FOR_APPROVAL.value && !shouldBeBookingMenuDisabled) ||
            (selectedEvent.type === WAITING_FOR_APPROVAL.value && hasManageApprovalForBooking(args)) ||
            (selectedEvent.type === WAITING_FOR_APPROVAL.value && isAbleToEditRequest)
        ) {
            showEditContextMenu(schedulerRef, coords);
        }
    } else if (selectedEvent.phase && hasRole(resourceRoleRights, 'settingProjectPhases')) {
        editParentContextMenu(schedulerRef, coords);
    } else if (selectedEvent.milestone && hasRole(resourceRoleRights, 'settingProjectMs')) {
        editParentContextMenu(schedulerRef, coords);
    } else if (selectedEvent.datesEvent && hasRole(resourceRoleRights, 'settingProjectDates')) {
        editParentContextMenu(schedulerRef, coords);
    } else if (selectedEvent.deadline) {
        const bookingData = args.e.data.booking

        const shouldBeDeadlineMenuDisabled =
            !bookingData?.hasEditRights || bookingData?.private || !bookingData?.isPermitted || !hasScheduleRight;

        if (!shouldBeDeadlineMenuDisabled) {
            editDeadlineContextMenu(schedulerRef, coords);
        }
    }
};
