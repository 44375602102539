import { subDays, addDays, isEqual, format } from 'date-fns';
import { includes, filter } from 'lodash';
import { SCHEDULER_DATE_BUFFER } from 'constants';
import { getMilestonesAndPhases, getProjectDates } from 'api/project';
import { isObjectEqual } from 'utils/dataManipulation';
import {
    formatBookingsToDP,
    formatMilestonesToDP,
    formatPhasesToDP,
    formatStartEndTimesToDP,
} from 'modules/scheduler/utils/eventUtil';
import { WAITING_FOR_APPROVAL, APPROVED, SCHEDULED } from 'enums/bookingTypeEnum';
import { PARENT, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import {
    getRowIdsByEvents,
    invalidateRows,
    getNewEvents,
    filterNotValidResources,
    getResourceIdsByDPResource,
    getProjectIdsForMilestonesDatesAndPhases,
    getDpRowsIdsToLoad,
    getExistingEvents,
    eventsIdsMoving,
    getBookingsService,
} from 'modules/scheduler/utils/schedulerUtil';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { store } from '../../../../store';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { eventsForRangeCache } from '../../../../utils/eventsForRangeCache';
import { setSchedulerBookingLinks } from '../../../../actions/schedulerActions';
import { monitoring } from '../../../../monitoring';

export default (
    viewObject,
    scheduler,
    canViewMilestonesOrPhases,
    canViewProjectDates,
    filters,
    mode,
    cache,
    dispatch
) => async args => {
    eventsForRangeCache.clear();
    const state = store.getState();
    const schedulerState = state.scheduler;
    const schedulerFilters = schedulerState?.filters ?? {};
    const clipboard = schedulerState?.clipboard;
    const isCutAction = schedulerState?.isCutAction;

    const bookingsProjectEventsIds = schedulerFilters?.bookingProjectsEvents?.filters || [];
    const bookingsResourcesUWIds = schedulerFilters?.bookingResourcesUW?.filters || [];
    const resourcesFilter = schedulerFilters?.resources?.filters || [];

    try {
        const { rowIds, currentStartDate, currentFilters, currentMode, currentSchedulerFilters } = cache.current;
        const schedulerRef = scheduler.current.control;
        const { scale } = schedulerRef;

        const currentRowIds = filterNotValidResources(
            getDpRowsIdsToLoad(schedulerRef, 10, false, {
                resourcesFilter,
            }),
            mode
        );
        const startDate = subDays(args.viewport.start.toDate(), SCHEDULER_DATE_BUFFER);
        const endDate = format(addDays(args.viewport.end.toDate(), SCHEDULER_DATE_BUFFER), 'yyyy-MM-dd');
        const shouldLoadMilestonesPhasesStartEndTimes =
            (viewObject.isSingleProjectView || viewObject.isProjectGroupView) &&
            (SINGLE.value === mode || PARENT.value === mode);
        const { resourceIds, projectIds } = getResourceIdsByDPResource(
            schedulerRef,
            currentRowIds,
            viewObject,
            mode,
            filters
        );
        const resourceIdsToAsk = (() => {
            const temp = bookingsResourcesUWIds?.length ? bookingsResourcesUWIds : resourceIds;

            if (temp?.length) {
                return temp;
            }

            return undefined;
        })();

        const projectIdsToAsk = (() => {
            if (!viewObject?.isProjectView) {
                return bookingsProjectEventsIds?.length ? bookingsProjectEventsIds : undefined;
            }

            const temp = bookingsProjectEventsIds?.length ? bookingsProjectEventsIds : projectIds;

            if (temp?.length) {
                return temp;
            }

            return undefined;
        })();

        const { projectIds: projectIdsForMilestonesDatesAndPhases } = getProjectIdsForMilestonesDatesAndPhases(
            schedulerRef,
            currentRowIds,
            viewObject.isProjectView,
            mode
        );

        const resourceDifference = filter(
            resourceIds,
            row => !includes(rowIds[viewObject.currentView]?.resourceIds, row)
        );
        const projectDifference = filter(projectIds, row => !includes(rowIds[viewObject.currentView]?.projectIds, row));

        const areFiltersSame = isObjectEqual(
            {
                inputFilters: currentFilters,
                schedulerFilters: currentSchedulerFilters,
            },
            { inputFilters: filters, schedulerFilters }
        );

        if (
            !resourceDifference.length &&
            !projectDifference.length &&
            currentStartDate &&
            isEqual(startDate, currentStartDate) &&
            areFiltersSame &&
            mode === currentMode
        ) {
            args.clearEvents = false;
            args.loaded();
            return;
        }
        cache.current = {
            ...cache.current,
            currentStartDate: startDate,
            currentFilters: filters,
            currentSchedulerFilters: schedulerFilters,
            currentMode: mode,
            rowIds: { ...rowIds, [viewObject.currentView]: { resourceIds, projectIds } },
        };

        const requests = [];

        if (resourceIds.length || projectIds.length) {
            requests.push(
                getBookingsService.get({
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate,
                    resourceIds: resourceIdsToAsk,
                    projectIds: projectIdsToAsk,
                    type: filters?.bookingStatuses?.filters?.length
                        ? filters.bookingStatuses.filters
                        : [APPROVED.value, WAITING_FOR_APPROVAL.value, SCHEDULED.value],
                    categoryIds: filters?.bookingCategories?.filters?.length
                        ? filters.bookingCategories.filters
                        : undefined,
                    schedulerViewProjectIds: filters.schedulerViewProjectIds,
                    projectStatuses: [schedulerFilters.bookingProjectStatuses],
                })
            );
        } else {
            requests.push([]);
        }

        if (shouldLoadMilestonesPhasesStartEndTimes && canViewMilestonesOrPhases && scale !== CELLDURATION.value) {
            const projectIdsToAsk = bookingsProjectEventsIds?.length
                ? bookingsProjectEventsIds
                : projectIdsForMilestonesDatesAndPhases;
            requests.push(
                getMilestonesAndPhases({
                    projectIds: projectIdsToAsk,
                })
            );
        } else {
            requests.push([]);
        }

        if (shouldLoadMilestonesPhasesStartEndTimes && canViewProjectDates && scale !== CELLDURATION.value) {
            const projectIdsToAsk = bookingsProjectEventsIds?.length
                ? bookingsProjectEventsIds
                : projectIdsForMilestonesDatesAndPhases;
            requests.push(
                getProjectDates({
                    startDate: format(startDate, 'yyyy-MM-dd'),
                    endDate,
                    projectIds: projectIdsToAsk,
                })
            );
        } else {
            requests.push([]);
        }

        let [bookings, milestonesAndPhases, projectDates] = await Promise.all(requests);

        if (!window.schedulerRef?.current?.__isMounted) {
            return;
        }

        bookings = bookings.map(booking => {
            if (booking?.project?.useStatusColor) {
                return {
                    ...booking,
                    project: replaceProjectsColorWithStatusColor(booking.project),
                };
            }
            return booking;
        });
        const { milestones, phases } = milestonesAndPhases || {};
        const events = formatBookingsToDP(bookings, mode, viewObject);
        // format milestones
        const formattedMilestones = milestones ? formatMilestonesToDP(milestones) : [];
        // format phases
        const formattedPhases = phases ? formatPhasesToDP(phases) : [];
        // format start, end times
        const formattedStartEndTimes = projectDates ? formatStartEndTimesToDP(projectDates) : [];
        const allEvents = events
            .concat(formattedMilestones)
            .concat(formattedPhases)
            .concat(formattedStartEndTimes);

        // get diff between current and new events
        let newEvents = getNewEvents(schedulerRef.events.list, allEvents);

        if (clipboard?.id && isCutAction) {
            // don't insert event which is cut/paste process
            newEvents = newEvents.filter(event => event.id !== clipboard.id);
        }

        const toUpdateEvents = getExistingEvents(schedulerRef.events.list, allEvents)?.filter(event => {
            // so we don't refresh bookings which update action is in progress to avoid jumping them
            // in scheduler
            return !eventsIdsMoving.has(event.id);
        });
        // get rows by events
        const rows = getRowIdsByEvents(
            areFiltersSame ? newEvents : [...schedulerRef.events.list, ...allEvents],
            schedulerRef
        );
        args.clearEvents = 0 < newEvents.length || !areFiltersSame;

        const existingToUpdateEventsIds = toUpdateEvents.reduce((acc, event) => {
            acc[event.id] = true;
            return acc;
        }, {});

        const currentEventsWithoutUpdated = schedulerRef.events.list.filter(
            event => !existingToUpdateEventsIds[event.id]
        );

        // if we remove the events schedulerRef.events.list from the events array that were already there
        // jumping appears on the scheduler. Therefore we are combining new with old
        // however we are not cleaning up and with a lot of scrolling can lead to a lot of events added to DP on big accounts
        // it fixes the issue with jumping but may introduce a new perf issue.
        const combinedEvents = areFiltersSame
            ? currentEventsWithoutUpdated.concat(toUpdateEvents).concat(newEvents)
            : allEvents;

        const unassignedRow = schedulerRef.rows.find('unassignedRow');

        args.events = combinedEvents;

        // update rows with new events
        invalidateRows(unassignedRow ? rows.concat(unassignedRow) : rows);
        args.loaded();
        dispatch(setSchedulerBookingLinks({ bookings: events }));
    } catch (error) {
        monitoring.captureException(error);
        args.loaded();
    }
};
