import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, reduxForm, initialize, getFormInitialValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'hooks';
import { validateSchema } from 'utils/schemaUtil';
import { formSchema, getDefaultValues, mapFormToRequest } from './allocationFormSchema';
import Allocation from 'shared/allocation';
import SuccessButton from 'shared/buttons/success';

const FORM_NAME = 'allocationForm';

const AllocationForm = props => {
    const { handleSubmit, onUpdate, submit, currentSelectedBooking } = props;
    const dispatch = useDispatch();
    const updateFields = useForm(FORM_NAME);
    const onSubmit = useCallback(
        values => {
            onUpdate(mapFormToRequest(values));
        },
        [onUpdate]
    );
    const { state, ...defaultValues } = useSelector(getFormInitialValues(FORM_NAME)) || {};

    const submitForm = useCallback(() => dispatch(submit(FORM_NAME)), [dispatch, submit]);

    useEffect(() => {
        const { state, ...defaultValues } = getDefaultValues(currentSelectedBooking);
        dispatch(initialize(FORM_NAME, { ...defaultValues, state }));
    }, [dispatch, currentSelectedBooking]);

    return (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Allocation
                medium
                inline={true}
                onChange={updateFields}
                allocationDefaultValues={defaultValues}
                state={state}
                currentSelectedBooking={{
                    resourceId: currentSelectedBooking.resource,
                    startDate: currentSelectedBooking.start.value,
                    endDate: currentSelectedBooking.end.value
                }}
            />
            <div className="text-center px-2">
                <SuccessButton className="mt-2 w-100" onClick={submitForm} dataCy="button--submit-allocation" />
            </div>
        </Form>
    );
};

AllocationForm.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    currentSelectedBooking: PropTypes.object.isRequired,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
})(AllocationForm);
