import Sorting from './../../components/tableSorting/sorting';
import { connect } from 'react-redux';
import { updateQueryParams } from 'actions/reportActions';

export default connect(
    state => ({
        queryParams: state.reportReducer.queryParams,
    }),
    dispatch => ({
        updateQueryParams: (queryParams, storageItemName) =>
            dispatch(updateQueryParams.request(queryParams, true, false, storageItemName)),
    })
)(Sorting);
