import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCompanyWeekType } from 'selectors/company';
import { useGroup } from 'modules/scheduler/hooks';
import MissingExtension from 'shared/warnings/missingExtension';
import { endOfMonth, startOfMonth } from 'date-fns';
import { selectSchedulerSmartFilters } from '../../../../../../selectors/scheduler';
import { buttonStates } from '../../../../modals/builder/buttonStates/buttonStates';
import { useHasRights, useIsExtensionInstalled } from '../../../../../../hooks';
import { SMART_FILTERS, SMART_GROUPS, SMART_SCHEDULES } from '../../../../../../enums/extensionShortIdEnum';
import { useParams } from 'react-router-dom';
import {
    getFiltersHasChanged,
    SMART_FILTERS as SMART_FILTERS_BUILDER_KEYS,
} from '../../../../utils/builderFiltersUtil';
import { INITIAL_SMART_FILTERS } from '../../../../modals/builder/consts';
import SuccessButton from '../../../../../../shared/buttons/success';
import { CapacityFinder } from '../../../../../../shared/capacityFinder';
import { Box } from '@material-ui/core';
import { Layout } from 'shared/components/layout/index';
import { formatDate } from 'utils/DateUtil';
import { YEAR_MONTH_DAY_FORMAT } from 'global/enums/dateFormat';

const rightsToCheck = [
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'canAddNewEditProjectGroups',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'canAddNewEditResourceGroups',
    },
];

const MISSING_EXTENSION_TEXT = 'Capacity finder extension is not installed. Please enable it from extension page';

const initSmartFilterState = filter => {
    const initialValue = Number.parseFloat(filter.value);

    if (Number.isInteger(initialValue)) {
        return {
            ...filter,
            value: initialValue,
        };
    }

    return filter;
};

const Filter = props => {
    const { toggleDatePicker, onApply } = props;

    const weekType = useSelector(getCompanyWeekType);
    const schedulerSmartFilters = useSelector(selectSchedulerSmartFilters);
    const params = useParams();
    const { current } = useGroup(params);
    const isSystemGroup = 'SYSTEM' === current.groupType;

    const isSmartGroupInstalled = useIsExtensionInstalled(SMART_GROUPS);
    const isSmartSchedulesInstalled = useIsExtensionInstalled(SMART_SCHEDULES);
    const isSmartFiltersExtensionInstalled = useIsExtensionInstalled(SMART_FILTERS);
    const { canAddNewEditProjectGroups, canAddNewEditResourceGroups } = useHasRights(rightsToCheck);

    const { filters, operator } = schedulerSmartFilters;
    const { availability, utilization } = filters;

    const initialDate = useRef(
        filters.dates ?? {
            start: startOfMonth(new Date()),
            end: endOfMonth(new Date()),
            dateState: 'This',
            periodType: 'month',
        }
    );

    const [dateState, setDateState] = useState(initialDate.current);

    const [operatorState] = useState(operator);
    const [utilizationState, setUtilizationState] = useState(() =>
        initSmartFilterState(utilization ?? INITIAL_SMART_FILTERS.filters.utilization)
    );
    const [availabilityState, setAvailabilityState] = useState(() =>
        initSmartFilterState(availability ?? INITIAL_SMART_FILTERS.filters.availability)
    );

    const handleAvailabilityChange = useCallback(value => {
        setAvailabilityState(state => ({
            ...state,
            ...value,
        }));
    }, []);

    const handleUtilisationChange = useCallback(value => {
        setUtilizationState(state => ({
            ...state,
            ...value,
        }));
    }, []);

    const handleCapacityFinderDatesChange = useCallback(dates => {
        setDateState(dates);
    }, []);

    const handleSmartFiltersChange = useCallback(values => {
        setAvailabilityState(state => ({
            ...state,
            ...values.availability,
        }));
        setUtilizationState(state => ({
            ...state,
            ...values.utilization,
        }));
    }, []);

    const haveAppliedFiltersChanged = useMemo(() => {
        return {
            smartFilters: getFiltersHasChanged(
                {
                    smartFilters: {
                        filters: {
                            dates: dateState,
                            availability: availabilityState,
                            utilization: utilizationState,
                        },
                        operator: operatorState,
                    },
                },
                {
                    smartFilters: {
                        ...schedulerSmartFilters,
                        filters: {
                            ...INITIAL_SMART_FILTERS.filters,
                            ...schedulerSmartFilters.filters,
                            dates: schedulerSmartFilters.filters.dates
                                ? schedulerSmartFilters.filters.dates
                                : initialDate.current,
                        },
                    },
                },
                SMART_FILTERS_BUILDER_KEYS
            ),
        };
    }, [availabilityState, dateState, operatorState, schedulerSmartFilters, utilizationState]);

    const { enableApply } = useMemo(() => {
        return buttonStates({
            itemId: params.resourceGroupId || params.projectGroupId,
            type: current.type,
            groupType: current.groupType,
            isSmart: current.isSmart,
            itemTypeName: params.resourceGroupId ? 'resource' : 'project',
            isSmartGroupInstalled,
            isSmartSchedulesExtensionInstalled: isSmartSchedulesInstalled,
            isSmartFiltersExtensionInstalled,
            haveAppliedFiltersChanged,
            haveSavedDataOrFiltersChanged: haveAppliedFiltersChanged,
            canAddNewEditProjectGroups,
            canAddNewEditResourceGroups,
        });
    }, [
        canAddNewEditProjectGroups,
        canAddNewEditResourceGroups,
        current.groupType,
        current.isSmart,
        current.type,
        haveAppliedFiltersChanged,
        isSmartFiltersExtensionInstalled,
        isSmartGroupInstalled,
        isSmartSchedulesInstalled,
        params.projectGroupId,
        params.resourceGroupId,
    ]);

    if (!isSmartFiltersExtensionInstalled) {
        return (
            <Layout stack padding="25px">
                <MissingExtension
                    description={MISSING_EXTENSION_TEXT}
                    title="Who is available?"
                    imgUrl="https://d329beqc2zk6g7.cloudfront.net/img/Missing_capacity_finder_extension.png"
                />
            </Layout>
        );
    }

    return (
        <Layout stack padding="25px">
            <Box sx={{ width: 500 }}>
                <CapacityFinder
                    weekType={weekType}
                    dates={dateState}
                    onDatesChange={handleCapacityFinderDatesChange}
                    utilization={utilizationState}
                    onUtilizationChanged={handleUtilisationChange}
                    availability={availabilityState}
                    onAvailabilityChanged={handleAvailabilityChange}
                    onBothFiltersChange={handleSmartFiltersChange}
                    variant="scheduler"
                />
            </Box>
            <footer>
                <SuccessButton
                    onClick={() =>
                        onApply({
                            dates: dateState,
                            utilization: utilizationState,
                            availability: availabilityState,
                            operator: operatorState,
                        })
                    }
                    disabled={
                        !enableApply.enabled ||
                        !dateState.start ||
                        !dateState.end ||
                        formatDate(dateState.start, YEAR_MONTH_DAY_FORMAT) >
                            formatDate(dateState.end, YEAR_MONTH_DAY_FORMAT)
                    }
                    color={`success float-right${isSystemGroup ? '' : ' mr-2'}`}
                    name="Preview"
                    type="btn-secondary"
                    dataCy="button--preview"
                />
                <SuccessButton
                    onClick={toggleDatePicker}
                    name="Cancel"
                    type="btn-cancel"
                    dataCy="button--cancel"
                    className="float-right"
                />
            </footer>
        </Layout>
    );
};

export default Filter;
