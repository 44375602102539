import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export const useHandleApproveOrReject = (onClick, currentSelectedBooking) => {
    const resourceId = useSelector(state => state.account.resourceId);

    return useCallback(
        (type, approvalInfo = {}) => {
            onClick({
                type,
                id: currentSelectedBooking.id,
                repeatId: currentSelectedBooking.repeatId,
                repeat: currentSelectedBooking.repeat,
                interval: currentSelectedBooking.interval,
                repeatTimes: currentSelectedBooking.repeatTimes,
                approvalInfo: {
                    approvedOrRejectedById: resourceId,
                    approvedOrRejectedDate: new Date(),
                    ...approvalInfo,
                },
            });
        },
        [onClick, resourceId, currentSelectedBooking]
    );
};
