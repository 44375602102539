import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { MoreVert } from '@material-ui/icons';
import { DropdownToggle, DropdownItem, NavLink } from 'reactstrap';
import { startOfMonth, endOfMonth } from 'date-fns';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import { useIsExtensionInstalled, useAppKeyWords } from 'hooks';
import { useGroup } from 'modules/scheduler/hooks';
import { TIME_SHEET } from 'enums/extensionShortIdEnum';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { formatDate } from 'utils/DateUtil';
import { DropdownButton, DropdownMenu } from 'shared/dropdownMenu';
import { HeaderName, Dropdown, HeaderSubName, HeaderNameWrapper } from './../styles';

const DATES = {
    startOfMonth: formatDate(startOfMonth(new Date()), 'yyyy-MM-dd'),
    endOfMonth: formatDate(endOfMonth(new Date()), 'yyyy-MM-dd'),
};

const HeaderTitle = props => {
    const { name, subName, openModal, resource } = props;
    const params = useParams();
    const groups = useGroup(params);
    const { isProjectGroupView, isSingleProjectView, isResourceGroupView, isSingleResourceView } = getViewObject(
        params
    );
    const isTimesheetExtensionInstalled = useIsExtensionInstalled(TIME_SHEET);
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    let timsheetUrl = '';
    let dashboardUrl = '';
    if (isSingleResourceView) {
        dashboardUrl = `${params.resourceId}&view=SINGLE_RESOURCE&start=${DATES.startOfMonth}&end=${DATES.endOfMonth}&side=RESOURCE`;
        timsheetUrl = `&rid=${params.resourceId}`;
    }
    if (isSingleProjectView) {
        dashboardUrl = `${params.projectId}&view=SINGLE_PROJECT&start=${DATES.startOfMonth}&end=${DATES.endOfMonth}&side=PROJECT`;
        timsheetUrl = `&pid=${params.projectId}`;
    }
    if (isResourceGroupView) {
        dashboardUrl = `${params.resourceGroupId}&view=RESOURCE_GROUP&start=${DATES.startOfMonth}&end=${DATES.endOfMonth}&side=RESOURCE`;
        timsheetUrl = `&rgid=${params.resourceGroupId}`;
    }
    if (isProjectGroupView) {
        dashboardUrl = `${params.projectGroupId}&view=PROJECT_GROUP&start=${DATES.startOfMonth}&end=${DATES.endOfMonth}&side=PROJECT`;
        timsheetUrl = `&pgid=${params.projectGroupId}`;
    }

    const getKeyWord = () => {
        if (params.resourceId) {
            return resource?.type === TYPE_UNASSIGNED.value ? TYPE_UNASSIGNED.display : resourceKeyWord;
        }

        if (params.projectId) {
            return projectKeyWord;
        }
        const groupToUse = params.projectGroupId ? groups.projectGroup : groups.resourceGroup;

        return groupToUse.type === SCHEDULER_TEMPLATE ? 'schedule' : 'group';
    };

    return (
        <>
            <Dropdown className="float-left py-2 mr-3" data-cy="scheduler-header-dropdown">
                <DropdownToggle nav>
                    <MoreVert />
                </DropdownToggle>
                <DropdownMenu className="rounded-0" width={100}>
                    <NavLink
                        href={`/dashboard#id=${dashboardUrl}&label=This%20Month&period=month&subgroup=NONE&subTab=HOME&split=week`}
                        data-cy="three-dots-dashboard"
                    >
                        Dashboard
                    </NavLink>
                    {isTimesheetExtensionInstalled && (
                        <NavLink href={`/time_sheets#view=actual${timsheetUrl}`} data-cy="three-dots-timesheets">
                            Timesheets
                        </NavLink>
                    )}
                    {openModal && (
                        <>
                            <DropdownItem divider />
                            <DropdownButton onClick={openModal} data-cy="three-dots-edit-group">
                                Edit {getKeyWord().toLowerCase()}...
                            </DropdownButton>
                        </>
                    )}
                </DropdownMenu>
            </Dropdown>
            <HeaderNameWrapper data-cy="scheduler-header-name">
                <HeaderName>{name}</HeaderName>
                {subName && <HeaderSubName>{subName}</HeaderSubName>}
            </HeaderNameWrapper>
        </>
    );
};

HeaderTitle.propTypes = {
    name: PropTypes.string,
    subName: PropTypes.string,
    resource: PropTypes.object,
    openModal: PropTypes.func,
};

HeaderTitle.defaultProps = {
    name: '',
    subName: '',
    resource: {},
    openModal: null,
};

export default HeaderTitle;
