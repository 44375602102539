import { REPORTS_ITEMS_COUNT } from 'constants';
import moize from 'moize';
import { find, isArray } from 'underscore';
import { escapeRegExp } from 'utils/formatingUtil';

const aggregateHandler = (value, multiItemsDisplay) => {
    if (isArray(value) && value.length < REPORTS_ITEMS_COUNT.value) {
        return value.toString();
    } else if (isArray(value) && value.length >= REPORTS_ITEMS_COUNT.value) {
        return REPORTS_ITEMS_COUNT.text(value.length, multiItemsDisplay);
    }

    return value;
};

export const OPERATORS = [
    {
        name: 'Starts With',
        value: 'startsWith',
        match: (match, value, multiItemsDisplay) =>
            aggregateHandler(value, multiItemsDisplay).match(new RegExp(`^${escapeRegExp(match)}`, 'i')),
    },
    {
        name: 'Ends With',
        value: 'endsWith',
        match: (match, value, multiItemsDisplay) =>
            aggregateHandler(value, multiItemsDisplay).match(new RegExp(`${escapeRegExp(match)}$`, 'i')),
    },
    {
        name: 'Contains',
        value: 'contains',
        match: (match, value, multiItemsDisplay) =>
            aggregateHandler(value, multiItemsDisplay).match(new RegExp(escapeRegExp(match), 'i')),
    },
    {
        name: 'Equal',
        value: 'eq',
        match: (match, value, multiItemsDisplay) =>
            aggregateHandler(value, multiItemsDisplay).match(new RegExp(`^${escapeRegExp(match)}$`, 'i')),
    },
    {
        name: 'Not Equal',
        value: 'ne',
        match: (match, value, multiItemsDisplay) =>
            !aggregateHandler(value, multiItemsDisplay).match(new RegExp(`^${escapeRegExp(match)}$`, 'i')),
    },
];

export const findTextOperatorByValue = moize(value => find(OPERATORS, operator => value === operator.value), {
    maxSize: 2,
});
