import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useAllocation } from 'hooks';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { findByValue, CELLDURATION } from 'modules/scheduler/enums/scale';
import { validateSchema } from 'utils/schemaUtil';
import { getDefaultValues, mapFormToRequest, formSchema } from 'forms/scheduleResourceForm';
import { makeCategoryGroups } from 'selectors/categoryGroups';
import { makeGetFilteredCategoryTemplates } from 'selectors/categoryTemplate';
import { SCHEDULED } from 'enums/bookingTypeEnum';
import { getBookingAllocationValuesFromSingleValue } from 'shared/lib/booking';
import { getResourceCapacity } from 'utils/capacityCalculation';
import { allocationTypeMap } from 'forms/shortBookingForm';
import moment from 'moment';
import { formatNumber } from '../../../../../../../../utils/formatingUtil';
import { getFormDefaultApprovers } from '../../../../../../../request/utils/getFormDefaultApprovers';
import { selectMappedResourceGroups } from '../../../../../../../../selectors/resourceGroup';
import { companyApprovers } from '../../../../../../../../actions/companyActions';
import { getCategoryGroups } from '../../../../../../../../actions/categoryGroupActions';
import {
    selectCompanyApprovers,
    selectCompanyDeadlinesBeforeBookingEndDateConfig,
    selectIsDeadlinesExtensionInstalled,
    selectUseCategoriesAllocation,
} from '../../../../../../../../selectors/company';
import { getResourceCustomFields } from '../../../../../../../../selectors/customField';
import { getAllocationValuesFromCategory } from '../../../../../../../../shared/allocation/getAllocationValuesFromCategory';
import { getDefaultCategoryTemplate } from '../../../../../../../categoryGroups/categoryGroupUtil';

const validation = (values, validator) => {
    const errors = validator(values);
    delete errors.note; // Workarounds for keeping schema consistent for both
    delete errors.resources; // formik and redux-form, fixing it properly require moving to formik entirely

    return errors;
};

/**
 * Create and use formik form for scheduling booking
 * @param {Function} onSchedule - callback sending http request to schedule
 * @param {Object} containerDefaults - default values for form
 * @param {Object} currentSelection - current selection on scheduler
 * @param {Object} currentScale - currently used scale [client/modules/scheduler/enums/scale.js]
 * @returns {Object}
 *  {
 *      formik - formik object;
 *      allocationValues - booking allocation - percentage,hours,total;
 * }
 */
const useForm = (onSchedule, containerDefaults, currentSelection, currentScale) => {
    const dispatch = useDispatch();

    const deadlinesCompanyConfig = useSelector(selectCompanyDeadlinesBeforeBookingEndDateConfig);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const { state: defaultState, values: allocationValues } = useAllocation(
        currentScale === CELLDURATION.value ? currentSelection : undefined
    );

    const categoryGroupsSelector = useMemo(() => makeCategoryGroups(), []);
    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const companySettings = useSelector(state => state.companyReducer.company.settings);
    const avgDailyCapacity = companySettings.report.avgDailyCapacity;
    const categoryGroups = useSelector(categoryGroupsSelector);
    const categoryTemplates = useSelector(categoryTemplatesSelector);
    const approvers = useSelector(selectCompanyApprovers);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);
    const resourceCustomFields = useSelector(getResourceCustomFields);
    const useCategoriesAllocation = useSelector(selectUseCategoriesAllocation);

    const onSubmit = useCallback(
        values => {
            const resource = values.resource || currentSelection?.rowTags?.resource;
            let defaultApprovers = getFormDefaultApprovers({ approvers, resource, mappedResourceGroups });

            const scheduleValues = mapFormToRequest({
                values: { ...values, type: values.type || SCHEDULED.value, resource },
                defaultApprovers,
                customFieldTemplates: resourceCustomFields,
            }).bookings;

            onSchedule(scheduleValues);
        },
        [approvers, currentSelection?.rowTags?.resource, mappedResourceGroups, onSchedule, resourceCustomFields]
    );

    useEffect(() => {
        dispatch(companyApprovers.request());
        dispatch(getCategoryTemplates.request());
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    const initialValues = useMemo(() => {
        const scale = findByValue(currentScale).BEValue;
        const defaults = {
            note: { message: '' },
            ...allocationValues,
            ...currentSelection,
            ...containerDefaults,
            state: defaultState,
            scale,
        };

        // Calculate like in modern menu
        let allocationNonHourMode = {};
        if (currentScale !== CELLDURATION.value) {
            const resourceInfo = currentSelection.rowTags?.resource;
            const { workDaysCount, totalMinutes } = getResourceCapacity(
                resourceInfo,
                moment(currentSelection.start),
                moment(currentSelection.end)
            );

            defaults.totalBucketMinutesAllocation = totalMinutes;
            defaults.minutesPerDay = workDaysCount ? formatNumber(totalMinutes / workDaysCount) : 0;

            const { value, nameOfValue } = (() => {
                if (useCategoriesAllocation) {
                    const allocationValuesFromCategory = getAllocationValuesFromCategory(
                        defaults.categoryTemplate || getDefaultCategoryTemplate(categoryGroups, categoryTemplates)
                    );
                    return {
                        value: allocationValuesFromCategory.value,
                        nameOfValue: allocationValuesFromCategory.name,
                    };
                }

                return {
                    value: allocationTypeMap[defaults.state].propertyValueFn(defaults, avgDailyCapacity),
                    nameOfValue: allocationTypeMap[defaults.state].name,
                };
            })();

            allocationNonHourMode = getBookingAllocationValuesFromSingleValue({
                value,
                nameOfValue,
                avgDailyCapacity,
                numberOfWorkDays: workDaysCount,
                totalBookingMinutes: totalMinutes,
            });
        }

        const defaultValues = getDefaultValues(companySettings, categoryGroups, categoryTemplates, defaults);

        return {
            ...defaultValues,
            ...allocationNonHourMode,
        };
    }, [
        currentScale,
        allocationValues,
        currentSelection,
        containerDefaults,
        defaultState,
        companySettings,
        categoryGroups,
        categoryTemplates,
        avgDailyCapacity,
        useCategoriesAllocation,
    ]);

    const formik = useFormik({
        validate: values =>
            validation(
                values,
                validateSchema(
                    formSchema({
                        deadlinesCompanyConfig,
                        isDeadlinesExtensionInstalled,
                    })
                )
            ),
        initialValues,
        onSubmit,
        enableReinitialize: true,
    });

    return { formik, allocationValues };
};

export default useForm;
