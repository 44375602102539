import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { CardImg, CardBody, Button, Alert } from 'reactstrap';
import classNames from 'classnames';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewListIcon from '@material-ui/icons/ViewList';
import AuthModeRequirements from 'shared/badge';

import PageTemplate from '../../shared/pageTemplate';
import { getCompanies } from 'actions/companyActions';
import { changeAccountCompany } from 'actions/accountActions';
import { CompanyCard, Description } from './styles';
import { getRoleDisplay } from 'enums/resourceEnum';
import { getCompanyFromSubdomain, getSingleCompanyId } from 'modules/auth/shared/utils';
import Loader from 'shared/loader';
import { Layout } from 'shared/components/layout';

const Launchpad = ({ isAuthenticated, isPartiallyAuthenticated }) => {
    const [view, setView] = useState('list');
    const dispatch = useDispatch();

    const accountCompanies = useSelector(state => state.companyReducer.companies);
    const authError = useSelector(state => state.account.authError);

    const onCompanyChange = body => {
        dispatch(changeAccountCompany.request(body));
    };

    useEffect(() => {
        dispatch(getCompanies.request());
    }, [dispatch]);

    if (!isAuthenticated && !isPartiallyAuthenticated) {
        return <Redirect to="/login" />;
    }

    if (accountCompanies.length === 1 || !!getCompanyFromSubdomain(accountCompanies)) {
        dispatch(
            changeAccountCompany.request({
                companyId: getSingleCompanyId(accountCompanies),
                path: '/',
            })
        );

        return (
            <Layout hAlign="center" vAlign="center" stretch stack>
                <Loader size={25} center />
            </Layout>
        );
    }

    return (
        <PageTemplate noImage>
            <div className="d-flex justify-content-between my-3">
                <Description className="text-center launchpad-descr">
                    Please choose the Company you would like to access:
                </Description>
                <div className="small d-flex flex-nowrap">
                    <Button onClick={() => setView('list')} color="link">
                        <ViewListIcon color={'list' === view ? 'primary' : 'action'} />
                    </Button>
                    <Button onClick={() => setView('module')} color="link">
                        <ViewModuleIcon color={'module' === view ? 'primary' : 'action'} />
                    </Button>
                </div>
            </div>

            {authError && (
                <Alert color="danger">
                    <b>Error!</b> Please check your credentials and try again.
                </Alert>
            )}

            <div
                className={classNames('d-flex flex-wrap justify-content-center', {
                    'flex-column': 'list' === view,
                    'flex-row': 'module' === view,
                })}
            >
                {accountCompanies.map(company => (
                    <CompanyCard
                        view={view}
                        key={company._id}
                        onClick={() =>
                            onCompanyChange({
                                companyId: company._id,
                                path: '/',
                            })
                        }
                    >
                        <CardImg top src={`https://d329beqc2zk6g7.cloudfront.net${company.thumb}`} alt={company.name} />
                        <CardBody>
                            <b>{company.name}</b>
                            <AuthModeRequirements
                                className="d-block"
                                companyAuthenticationSettings={company.authentication}
                            />
                            <span className="d-block role-name">{getRoleDisplay(company.role)}</span>
                            <small
                                className={classNames('text-muted d-inline-block', {
                                    'text-truncate': 'module' === view,
                                })}
                            >
                                {`${company.subdomain}.hubplanner.com`}
                            </small>
                        </CardBody>
                    </CompanyCard>
                ))}
            </div>
        </PageTemplate>
    );
};

export default Launchpad;
