import { useState, useEffect, useCallback } from 'react';
import { useNavigationKeys } from 'shared/hooks';
import { findElementInDOMElementBySelector } from 'shared/lib/dom';
import { findItemIndexInArrayByStringify } from 'shared/lib/array';

/**
 * Applies transition when leaving this item. Function will search it in dom and removes css class or blurs it.
 * @param {{ selector: string, action: String}} navigableItem configuration of navigable item
 * @param {string} toggleCssClass toggleCssClass to be used
 * @param {HTMLElement} containerDOMElement HTMLElement to search in
 */
const executeTransitionForOldItem = (navigableItem, toggleCssClass, containerDOMElement) => {
    const oldElement = findElementInDOMElementBySelector(navigableItem.selector, containerDOMElement);
    if (navigableItem.action === 'toggleClass') {
        oldElement && oldElement.classList.remove(toggleCssClass);
    } else if (navigableItem.action === 'focus') {
        if (oldElement) {
            const inputElements = oldElement.getElementsByTagName('input');
            inputElements.length === 1 && inputElements[0].blur();
        }
    }
};

/**
 * Applies transition when entering this item. Function will search it in dom and adds css class or focuses it.
 * @param {{ selector: string, action: String}} navigableItem configuration of navigable item
 * @param {string} toggleCssClass toggleCssClass to be used
 * @param {HTMLElement} containerDOMElement HTMLElement to search in
 */
const executeTransitionForNewItem = (navigableItem, toggleCssClass, containerDOMElement) => {
    const newElement = findElementInDOMElementBySelector(navigableItem.selector, containerDOMElement);

    if (navigableItem.action === 'toggleClass') {
        newElement && newElement.classList.add(toggleCssClass);
    } else if (navigableItem.action === 'focus') {
        if (newElement) {
            const inputElements = newElement.getElementsByTagName('input');
            inputElements.length === 1 && inputElements[0].focus();
        }
    }
};

/**
 *  This hooks takes a list of configurations of navigable items and stores current selected, applies changes on them (focus, blur, add, remove css class depending on the case)
 * @param {{navigableItemsList: Array, toggleCssClass: String}} param0 Object of navigable items list (defaults to empty array []), and toggleCssClass (defaults to empty string)
 * @param {HTMLElement} containerDOMElement HTMLElement to search in for navigable elements. Defaults to document.
 */
const useUpDownNavigation = ({ navigableItemsList = [], toggleCssClass = '' }, containerDOMElement = document) => {
    // TODO: Validation. navigableItemsList
    const [currentNavigatedItem, setCurrentNavigatedItem] = useState(null);

    const setPreviousItem = useCallback(() => {
        if (navigableItemsList.length > 1) {
            if (currentNavigatedItem) {
                const currentSelectedIndex = findItemIndexInArrayByStringify(currentNavigatedItem, navigableItemsList);
                if (currentSelectedIndex !== 0) {
                    executeTransitionForOldItem(currentNavigatedItem, toggleCssClass, containerDOMElement);
                    setCurrentNavigatedItem(navigableItemsList[currentSelectedIndex - 1]);
                }
            } else {
                setCurrentNavigatedItem(navigableItemsList[0]);
            }
        }
    }, [navigableItemsList, containerDOMElement, currentNavigatedItem, toggleCssClass]);

    const setNextItem = useCallback(() => {
        if (navigableItemsList.length > 1) {
            if (currentNavigatedItem) {
                const currentSelectedIndex = findItemIndexInArrayByStringify(currentNavigatedItem, navigableItemsList);
                if (currentSelectedIndex !== navigableItemsList.length - 1) {
                    executeTransitionForOldItem(currentNavigatedItem, toggleCssClass, containerDOMElement);
                    setCurrentNavigatedItem(navigableItemsList[currentSelectedIndex + 1]);
                }
            } else {
                setCurrentNavigatedItem(navigableItemsList[0]);
            }
        }
    }, [navigableItemsList, containerDOMElement, currentNavigatedItem, toggleCssClass]);

    const setItemByIndex = useCallback(
        index => {
            index && navigableItemsList?.[index] && setCurrentNavigatedItem(navigableItemsList[index]);
        },
        [navigableItemsList]
    );

    // Executes only once, After css transition of sliding finished
    useEffect(() => {
        if (navigableItemsList.length && navigableItemsList[0]) {
            setTimeout(() => {
                setCurrentNavigatedItem(navigableItemsList[0]);
            }, 500);
        }
        // eslint-disable-next-line
    }, []);

    // After each change for currentNavigatedItem, its applying transition
    useEffect(() => {
        !!currentNavigatedItem &&
            executeTransitionForNewItem(currentNavigatedItem, toggleCssClass, containerDOMElement);

        if (currentNavigatedItem) {
            const elementFound = findElementInDOMElementBySelector(currentNavigatedItem.selector, containerDOMElement);
            elementFound && elementFound.scrollIntoView(false);
        }
    }, [currentNavigatedItem, containerDOMElement, toggleCssClass]);

    useNavigationKeys({
        onArrowUp: () => {
            setPreviousItem();
        },
        onArrowDown: () => {
            setNextItem();
        },
        onEnter: () => {
            if (!currentNavigatedItem) {
                return;
            }

            const currentDOMElement = findElementInDOMElementBySelector(
                currentNavigatedItem.selector,
                containerDOMElement
            );

            if (currentDOMElement) {
                currentDOMElement.click();
            }
        },
    });

    return { currentNavigatedItem, setItemByIndex };
};

export default useUpDownNavigation;
