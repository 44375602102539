import React from 'react';
import PropTypes from 'prop-types';
import { FormikProvider } from 'formik';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import {
    DateToggler,
    CategoryToggler,
    RepeatToggler,
    AllocationToggler,
    ColorTogglerBooking,
    ProjectToggler,
    ResourceToggler,
} from '../../../shared/togglers';
import SuccessButton from 'shared/buttons/success';
import { Row, Col } from 'reactstrap';
import useUniqueToggler from '../../../../../../../shared/hooks/useUniqueToggler';

const ScheduleForm = props => {
    const {
        keyword,
        formValues,
        formik,
        updateFields,
        updateProject,
        updateCategory,
        updateDates,
        projects,
        resources,
        projectCategoryGroups,
        isSubmitting,
        currentScale,
        scheduleButton,
        requestButton,
        onAllocationTypeChange,
        onAllocationValueChange,
    } = props;

    const {
        onToggleOpen,
        projectCloseToggler,
        resourceCloseToggler,
        dateCloseToggler,
        allocationCloseToggler,
        categoryCloseToggler,
        repeatCloseToggler,
        colorCloseToggler,
    } = useUniqueToggler(['project', 'resource', 'date', 'allocation', 'category', 'repeat', 'color']);

    let disabledButtons = !formValues.project || (!formValues.resource && !formValues.resources[0]) || isSubmitting;
    if (
        !disabledButtons &&
        // Even if project if choosen, the first time appears as invalid and has error
        Object.keys(formik.errors || {}).length > 0 &&
        !(Object.keys(formik.errors || {}).length === 1 && formik?.errors?.project != null)
    ) {
        disabledButtons = true;
    }

    return (
        <FormikProvider className="needs-validation" value={formik}>
            <form data-cy="schedule-form">
                {projects ? (
                    <ProjectToggler
                        project={formValues.project}
                        onChange={updateProject}
                        keyword={keyword}
                        projects={projects}
                        onToggleOpen={() => onToggleOpen('project')}
                        closeToggle={projectCloseToggler}
                    />
                ) : (
                    <ResourceToggler
                        resource={formValues.resource}
                        onChange={updateFields}
                        keyword={keyword}
                        resources={resources}
                        onToggleOpen={() => onToggleOpen('resource')}
                        closeToggle={resourceCloseToggler}
                    />
                )}
                {currentScale !== CELLDURATION.value && (
                    <DateToggler
                        start={formValues.start}
                        end={formValues.end}
                        onDateChange={updateDates}
                        onToggleOpen={() => onToggleOpen('date')}
                        closeToggle={dateCloseToggler}
                    />
                )}
                {currentScale !== CELLDURATION.value && (
                    <AllocationToggler
                        state={formValues.state}
                        onToggleOpen={() => onToggleOpen('allocation')}
                        closeToggle={allocationCloseToggler}
                        onAllocationValueChange={onAllocationValueChange}
                        onAllocationTypeChange={onAllocationTypeChange}
                    />
                )}
                <CategoryToggler
                    category={formValues.category}
                    onChange={updateCategory}
                    bookingCategories={projectCategoryGroups}
                    onToggleOpen={() => onToggleOpen('category')}
                    closeToggle={categoryCloseToggler}
                />
                <RepeatToggler
                    isFormik={true}
                    interval={formValues.interval}
                    errors={formik.errors}
                    onToggleOpen={() => onToggleOpen('repeat')}
                    closeToggle={repeatCloseToggler}
                />
                <ColorTogglerBooking
                    useProjectColor={formValues.useProjectColor}
                    color={
                        formValues.useProjectColor
                            ? formValues?.project?.color || formValues?.project?.backgroundColor
                            : formValues.bookingColor
                    }
                    onChange={bookingColor => updateFields({ bookingColor, useProjectColor: false })}
                    onToggleOpen={() => onToggleOpen('color')}
                    closeToggle={colorCloseToggler}
                    onInheritClick={() => updateFields({ bookingColor: '', useProjectColor: true })}
                    inheritOption
                    categoryColor={formValues?.category?.gridColor}
                />
                <Row noGutters={true} className="no-row-gutters mt-2 mr-1">
                    {requestButton && (
                        <Col style={{ padding: '0 5px 0 10px' }}>
                            <SuccessButton
                                name="Request"
                                disabled={disabledButtons || requestButton.disabled}
                                type="btn-secondary"
                                dataCy="classic-menu-button-request"
                                className={`${scheduleButton ? 'btn-no-min-width' : ''} w-100`}
                                onClick={requestButton.onClick}
                            />
                        </Col>
                    )}
                    {scheduleButton && (
                        <Col style={{ padding: '0 5px 0 10px' }}>
                            <SuccessButton
                                name="Schedule"
                                disabled={disabledButtons || scheduleButton.disabled}
                                dataCy="classic-menu-button-schedule"
                                className={`${requestButton ? 'btn-no-min-width' : ''} w-100`}
                                onClick={scheduleButton.onClick}
                            />
                        </Col>
                    )}
                </Row>
            </form>
        </FormikProvider>
    );
};

ScheduleForm.propTypes = {
    type: PropTypes.string.isRequired,
    keyword: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired,
    updateFields: PropTypes.func.isRequired,
    updateDates: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
    resources: PropTypes.arrayOf(PropTypes.object),
    projectCategoryGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    isSubmitting: PropTypes.bool,
    currentScale: PropTypes.string.isRequired,
    scheduleButton: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }),
    requestButton: PropTypes.shape({
        isSubmitting: PropTypes.bool,
        onClick: PropTypes.func.isRequired,
    }),
    onAllocationValueChange: PropTypes.func.isRequired,
    onAllocationTypeChange: PropTypes.func.isRequired,
};

ScheduleForm.defaultProps = {
    type: TYPE_REGULAR.value,
};

export default ScheduleForm;
