export const APPROVAL = 'approval';
export const TIME_SHEET = 'ts';
export const MY_DASHBOARD = 'my_dashboard';
export const EXTENDED_DASHBOARD = 'extended_dashboard';
export const PM = 'pm';
export const CUSTOM_FIELDS = 'cf';
export const RESOURCE_RATE = 'rc';
export const UNASSIGNED = 'unassigned';
export const TASKS = 'tasks';
export const SMART_GROUPS = 'smart_groups';
export const SMART_FILTERS = 'smart_filters';
export const SMART_SCHEDULES = 'smart_schedules';
export const CUSTOM_COLUMNS = 'custom_columns';
export const RESOURCE_REQUEST = 'resource_request';
export const VACATION_REQUEST = 'vacation_request';
export const UNKNOWN = 'UNKNOWN';
export const HOLIDAY_CALENDARS = 'holiday_calendars';
export const SINGLE_SIGN_ON = 'single_sign_on';
export const CATEGORY_GROUPS = 'category_groups';
export const SIDEBAR_PRO = 'sidebar_pro';
export const AUDIT_LOGS = 'audit_log';
export const MFA = 'mfa';
export const FIXED_COSTS = 'fixed_costs';
export const DEPENDENCIES = 'dependencies';
export const DEADLINES = 'deadlines';
export const TOIL = 'toil';
