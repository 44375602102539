class EventsForRangeCache {
    _cache = {};
    _events = undefined;

    constructor() {}

    clear() {
        this._cache = {};
        this._events = undefined;
    }

    getForRange(start, end, events = []) {
        const startValue = start?.value;
        const endValue = end?.value;
        const fromCache = this._cache[`${startValue}-${endValue}`];

        if (this._events && this._events === events && this._events.length === events.length && fromCache) {
            return fromCache;
        }

        if (!this._events) {
            this._events = events;
        }

        const filtered = events.forRange(start, end);

        this._cache[`${startValue}-${endValue}`] = filtered;

        return filtered;
    }
}

export const eventsForRangeCache = new EventsForRangeCache();
