import styled from 'styled-components';

export const Header = styled.div`
    position: relative;
    height: 100%;
    padding: 5px;
    &.disabled {
        background-color: #c7c7c7;
    }
`;

export const HeatMapHeader = styled(Header)`
    font-size: 11px;
    color: #636363;
    line-height: 24px;
    background-color: ${props => (props.disabled ? 'rgb(249, 249, 249)' : undefined)};
`;

export const columnStyle = {
    alignItems: 'center',
    display: 'grid',
    justifyContent: 'center',
};
export const disabledStyle = { backgroundColor: '#f9f9f9', color: '#909090' };
export const darkThemeDisabledStyle = { backgroundColor: '#2d2d2d' };

export const HeatMapMainHeader = styled.span`
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 16px;
`;

export const ThumbnailResourceWrapper = styled.div`
    display: flex;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;

    .text-container {
        width: calc(100% - 35px);

        .title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;

const handleStatusColor = (value, enumObj) => {
    const props = enumObj.properties[value];

    switch (value) {
        case enumObj.OVER:
            return props && props.color ? props.color : 'rgb(249, 115, 86)';
        case enumObj.UNDER:
            return props && props.color ? props.color : 'rgb(0, 181, 212)';
        case enumObj.ON:
            return props && props.color ? props.color : 'rgb(10, 214, 169)';
        default:
            return 'rgb(217, 218, 218)';
    }
};

export const Status = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .status-circle {
        border-radius: 50%;
        height: 15px;
        width: 15px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        background-color: ${props => handleStatusColor(props.value, props.enum)};
    }
`;

export const Color = styled.span`
    border-radius: 50%;
    height: 15px;
    width: 15px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    background-color: ${props => props.color};
`;

export const ThumbnailTitle = styled.span`
    display: flex;
    justify-content: center;
    flex-direction: column;

    .subtitle {
        font-size: 10px;
        color: #848282;
    }
`;

export const TextContent = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
