import * as actionTypes from '../actions/actionTypes';

const initialModalState = {
    modals: [],
};

export default function modalReducer(state = initialModalState, action) {
    switch (action.type) {
        case actionTypes.SHOW_MODAL:
            return {
                modals: [
                    ...state.modals,
                    {
                        modalType: action.modalType,
                        modalProps: {
                            ...action.payload,
                        },
                    },
                ],
            };
        case actionTypes.HIDE_MODAL:
            if (state.modals.length <= 1) {
                return initialModalState;
            }

            return { modals: state.modals.slice(0, -1) };

        case actionTypes.HIDE_MODAL_TYPE:
            if (!state.modals.length) {
                return initialModalState;
            }

            return { modals: state.modals.filter(modal => modal.modalType !== action.payload.modalType) };

        default:
            return state;
    }
}
