import React from 'react';
import PropTypes from 'prop-types';
import { FormText } from 'reactstrap';
import { Field } from 'redux-form';
import { Layout, Typography, TextFieldReduxFormWrapper } from '@hub-mono/ui';

const Note = ({ title, subtitle, label, formText, placeholder }) => (
    <Layout stack gap={24}>
        <Layout stack gap="--Spacing-100">
            <Typography variant="label" size="large" prominent>
                {title}
            </Typography>
            {subtitle ? (
                <Typography variant="body" size="small">
                    {subtitle}
                </Typography>
            ) : null}
        </Layout>
        <Layout>
            <Field
                name="note"
                type="textarea"
                label={label}
                multiline
                minRows={6}
                placeholder={placeholder}
                component={TextFieldReduxFormWrapper}
            />
        </Layout>
        {formText ? <FormText>{formText}</FormText> : null}
    </Layout>
);

Note.propTypes = {
    title: PropTypes.string.isRequired,
    formText: PropTypes.string,
    placeholder: PropTypes.string,
};

Note.defaultProps = {
    placeholder: '',
};

export default Note;
