import React from 'react';
import { contains } from 'underscore';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { displayDateState, displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    // PROJECT
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimeBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimeBetween,
                unitTypes[unit].calculation(row.projectSpecificReportedTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimeAfter'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimeAfter,
                unitTypes[unit].calculation(row.projectSpecificReportedTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimePrevious'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimePrevious,
                unitTypes[unit].calculation(row.projectSpecificReportedTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimeFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimeFuture,
                unitTypes[unit].calculation(row.projectSpecificReportedTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimeToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimeToDate,
                unitTypes[unit].calculation(row.projectSpecificReportedTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Reported Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificReportedTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificReportedTimeAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificReportedTimeAllTime,
                unitTypes[unit].calculation(row.projectSpecificReportedTimeAllTime, avgDailyCapacity)
            ),
    },
    // EVENT
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimeBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimeBetween,
                unitTypes[unit].calculation(row.eventSpecificReportedTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimeAfter'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimeAfter,
                unitTypes[unit].calculation(row.eventSpecificReportedTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimePrevious'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimePrevious,
                unitTypes[unit].calculation(row.eventSpecificReportedTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimeFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimeFuture,
                unitTypes[unit].calculation(row.eventSpecificReportedTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimeToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimeToDate,
                unitTypes[unit].calculation(row.eventSpecificReportedTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Reported Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificReportedTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificReportedTimeAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificReportedTimeAllTime,
                unitTypes[unit].calculation(row.eventSpecificReportedTimeAllTime, avgDailyCapacity)
            ),
    },
    // RESOURCE
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimeBetween'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimeBetween,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimeAfter'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimeAfter,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimePrevious'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimePrevious,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimeFuture'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimeFuture,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimeToDate'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimeToDate,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Reported Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificReportedTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificReportedTimeAllTime'),
        accessor: row =>
            displayValue(
                row.resourceSpecificReportedTimeAllTime,
                unitTypes[unit].calculation(row.resourceSpecificReportedTimeAllTime, avgDailyCapacity)
            ),
    },
    // UNASSIGNED
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimeBetween'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimeBetween,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimeAfter'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimeAfter,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimePrevious'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimePrevious,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimeFuture'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimeFuture,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimeToDate'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimeToDate,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Reported Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificReportedTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificReportedTimeAllTime'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificReportedTimeAllTime,
                unitTypes[unit].calculation(row.unassignedSpecificReportedTimeAllTime, avgDailyCapacity)
            ),
    },
];
