import { sortBy } from 'lodash';

export const updateObject = (oldObject, newValues) => ({
    ...oldObject,
    ...newValues,
});

export const updateItemInArray = (array, itemId, updateItemCallback, itemIdPropName = '_id') =>
    array.map(item => {
        if (item[itemIdPropName] !== itemId) {
            return item;
        }

        return updateItemCallback(item);
    });

export const getUpdatedCollection = ({ initial = [], toAddUpdate = [], idProp = '_id', sortByProp }) => {
    const map = new Map(initial.map(item => [item[idProp], item]));

    toAddUpdate.forEach(resource => {
        map.set(resource._id, resource);
    });

    const merged = Array.from(map.values());

    if (sortByProp) {
        return sortBy(merged, item => item[sortByProp].toLowerCase());
    }

    return merged;
};
