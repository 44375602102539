import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { scrollToCalculator } from 'modules/scheduler/utils/schedulerUtil';
import moment from 'moment';
import { DATE_FORMAT_LONG } from 'enums/dateFormatEnum';

const scrollTo = schedulerRef => date => {
    const schedulerInstance = schedulerRef.current.control;
    const scrollToDate = moment(date).format('YYYY-MM-DD');
    schedulerInstance.scrollTo(scrollToDate);
    return scrollToDate;
};

const scrollLeft = schedulerRef => () => {
    const schedulerInstance = schedulerRef.current.control;
    const scrollableWidth = schedulerInstance.nav.scroll.getBoundingClientRect().left;
    const currentX = schedulerInstance.getScrollX();

    if (currentX <= scrollableWidth) {
        schedulerInstance.setScrollX(
            currentX - scrollToCalculator(schedulerInstance.cellWidth, schedulerInstance.scale)
        );
    }

    const scrollTo = currentX - scrollToCalculator(schedulerInstance.cellWidth, schedulerInstance.scale);
    schedulerInstance.scrollTo(scrollTo);
};

const scrollCenter = schedulerRef => () => {
    const schedulerInstance = schedulerRef.current.control;

    let scrollToDate;
    if (schedulerInstance.scale === CELLDURATION.value) {
        scrollToDate = moment().format(DATE_FORMAT_LONG);
    } else {
        scrollToDate = moment()
            .startOf('week')
            .add(1, 'days')
            .format('YYYY-MM-DD');
    }
    schedulerInstance.scrollTo(scrollToDate);
    return scrollToDate;
};

const scrollRight = schedulerRef => () => {
    const schedulerInstance = schedulerRef.current.control;

    const scrollTo =
        schedulerInstance.getScrollX() + scrollToCalculator(schedulerInstance.cellWidth, schedulerInstance.scale);
    schedulerInstance.scrollTo(scrollTo);
};

export const useScroll = schedulerRef => ({
    scrollTo: scrollTo(schedulerRef),
    scrollLeft: scrollLeft(schedulerRef),
    scrollCenter: scrollCenter(schedulerRef),
    scrollRight: scrollRight(schedulerRef),
});
