import React from 'react';
import PropTypes from 'prop-types';
import { Done, Clear, List, Edit } from '@material-ui/icons';
import { APPROVED, REJECTED } from 'enums/bookingTypeEnum';
import { Wrapper, Item, ItemLink } from './styles';

const SelectedEvent = ({ onClick, bookingResourceId, isVacationFlow, onEdit, hasManageApprovalForBooking }) => {
    let flow = 'RES_REQUEST_FLOW_SINGLE';
    let flowDir = 'team-resource-requests';

    if (isVacationFlow) {
        flow = 'VACATION_FLOW_SINGLE';
        flowDir = 'vacation-requests';
    }

    return (
        <Wrapper>
            {hasManageApprovalForBooking && (
                <>
                    <Item title="Approve Request" data-cy="footer-approve-request" onClick={() => onClick(APPROVED.value)}>
                        <Done />
                    </Item>
                    <Item title="Reject Request" data-cy="footer-reject-request" onClick={() => onClick(REJECTED.value)}>
                        <Clear />
                    </Item>
                </>
            )}
            {onEdit && (
                <Item title="Edit request" onClick={() => onEdit()}>
                    <Edit />
                </Item>
            )}

            {hasManageApprovalForBooking ||
                (onEdit && (
                    <ItemLink
                        title="View request dashboard"
                        to={`/requests/${flowDir}?flow=${flow}&resourceId=${bookingResourceId}`}
                    >
                        <List />
                    </ItemLink>
                ))}
        </Wrapper>
    );
};

SelectedEvent.propTypes = {
    onClick: PropTypes.func.isRequired,
    bookingResourceId: PropTypes.string.isRequired,
    isVacationFlow: PropTypes.bool.isRequired,
};

export default SelectedEvent;
