import React from 'react';
import { sumBy, forEach, includes, filter, map } from 'lodash';
import {
    Group,
    Folder,
    AddCircle,
    InsertDriveFile,
    Person,
    List,
    Search,
    Save,
    FormatAlignLeft,
    Public,
    Event,
    PersonAddDisabled,
    Schedule,
    EmojiObjects,
    Adjust,
} from '@material-ui/icons';
import { isActive } from 'utils/extensionUtil';
import { PM, UNASSIGNED, SMART_SCHEDULES, SMART_FILTERS } from 'enums/extensionShortIdEnum';
import {
    roles,
    TYPE_ARCHIVED,
    TYPE_UNASSIGNED,
    STATUS_PARKED,
    STATUS_NON_BOOKABLE,
    STATUS_ARCHIVED,
    STATUS_ACTIVE,
} from 'enums/resourceEnum';
import {
    TYPE_ARCHIVED as TYPE_ARCHIVED_PROJECT,
    TYPE_FLOATING,
    TYPE_PLANNED,
    TYPE_PENDING,
    STATUS_ARCHIVED as PROJECT_STATUS_ARCHIVED,
    STATUS_ACTIVE as PROJECT_STATUS_ACTIVE,
    STATUS_FLOATING as PROJECT_STATUS_FLOATING,
    STATUS_PLANNED as PROJECT_STATUS_PLANNED,
    STATUS_PENDING as PROJECT_STATUS_PENDING,
} from 'enums/projectEnum';
import { PROJECT, RESOURCE } from 'modules/scheduler/enums/builderTypeEnum';
import { SCHEDULER_TEMPLATE } from 'enums/groupTypeEnum';
import {
    ACTIVE,
    ARCHIVED,
    FLOATING,
    MY_SCHEDULE,
    PENDING,
    PLANNED,
    SCHEDULE_TEMPLATE_AVAILABLE,
    SCHEDULE_TEMPLATE_FULLY_BOOKED,
    SCHEDULE_TEMPLATE_OVER_UTILIZED,
    SCHEDULE_TEMPLATE_UNDER_UTILIZED,
} from 'enums/criteriaEnum';
import { orderGroups } from 'modules/scheduler/utils/groupOrderUtil';
import BaseMenu from 'modules/sidebar/services/menuBuilder/BaseMenu';
import {
    SwitcherMenuItem,
    NewItem,
    GroupItem,
    ResourceItem,
    ProjectItem,
    SearchItem,
    ScheduleItem,
} from 'modules/scheduler/components/menu';
import SeparatorItem from 'modules/menu/components/seperatorMenuItem';
import EmptyGroup from './../components/menu/emptyGroup';
import { hasOneOfRoles, hasRole } from 'utils/rightsUtil';
import { SYSTEM, USER } from '../enums/groupTypeEnum';
import { calculateGroupCount, getScheduleSystemGroupPath } from './utils';
import { store } from '../../../store';
import { replaceKeywords, replaceMap } from '../../../utils/keywordsUtil';
import { showMissingSmartSchedulesExtensionModal, showMissingSmartFiltersExtensionModal } from 'actions/modalActions';
import {
    smartSchedulesExtensionTooltipMessage,
    smartFiltersExtensionTooltipMessage,
    unassignedWorkExtensionTooltipMessage,
} from 'shared/constants/tooltipsMessages';

const endIconStyles = { fontSize: 18, marginTop: 2, color: '#e6892c', position: 'absolute', right: 3 };

/**
 * Each Menu Class need to have a items() function
 */
class SchedulerMenu extends BaseMenu {
    setProps(account, data, keyWords, extensions, currentView, options, projectStatuses) {
        this.account = account || [];
        this.keyWords = keyWords || {};
        this.extensions = extensions || [];
        this.currentView = currentView;
        this.resourceGroups = data ? data.resourceGroups.filter(({ criteria }) => MY_SCHEDULE !== criteria) : [];
        this.projectGroups = data ? data.projectGroups : [];
        this.resources = data ? data.resources : [];
        this.projects = data ? data.projects : [];
        this.options = options;
        this.replaceMap = replaceMap(keyWords);
        this.projectStatuses = projectStatuses;

        return this;
    }

    renderResourceGroups(groups, trace = []) {
        let resourceGroupItems = [];
        const filteredResourceGroups = filter(this.resourceGroups, resourceGroup => {
            if (!this.options.displayArchivedInScheduler && !isActive(this.extensions, UNASSIGNED)) {
                return (
                    TYPE_ARCHIVED.value !== resourceGroup.criteria &&
                    TYPE_UNASSIGNED.value !== resourceGroup.criteria &&
                    (!resourceGroup.type || resourceGroup.type !== SCHEDULER_TEMPLATE)
                );
            }

            if (!this.options.displayArchivedInScheduler) {
                return (
                    TYPE_ARCHIVED.value !== resourceGroup.criteria &&
                    (!resourceGroup.type || resourceGroup.type !== SCHEDULER_TEMPLATE)
                );
            }

            if (!isActive(this.extensions, UNASSIGNED)) {
                return (
                    TYPE_UNASSIGNED.value !== resourceGroup.criteria &&
                    (!resourceGroup.type || resourceGroup.type !== SCHEDULER_TEMPLATE)
                );
            }

            return !resourceGroup.type || resourceGroup.type !== SCHEDULER_TEMPLATE;
        });

        const filterResources = resourceGroup => {
            const statusesToExclude = [STATUS_PARKED.value, STATUS_NON_BOOKABLE.value];
            if (resourceGroup.criteria === ARCHIVED) {
                statusesToExclude.push(STATUS_ACTIVE.value);
            } else {
                if (
                    !this.options.displayArchivedInScheduler ||
                    (this.options.displayArchivedInScheduler && resourceGroup.criteria === ACTIVE)
                ) {
                    statusesToExclude.push(STATUS_ARCHIVED.value);
                }
            }

            return filter(
                this.resources,
                resource =>
                    includes(resourceGroup.resources, resource._id) && !includes(statusesToExclude, resource.status)
            );
        };

        const resourceGroupsOrdered =
            groups ||
            orderGroups(
                this.account.preferences.topLevelResourceGroupOrder,
                filter(filteredResourceGroups, group => !group.parentGroupId)
            );

        forEach(resourceGroupsOrdered, resourceGroup => {
            const filteredResources = filterResources(resourceGroup);
            const subGroups = filter(filteredResourceGroups, group => group.parentGroupId === resourceGroup._id);

            const newLabel = this.getResourceGroupNameWithReplacedKeywords(resourceGroup);

            const content = this.renderResourceItems(resourceGroup, groups, filteredResources, [
                ...trace,
                resourceGroup._id,
            ]);

            resourceGroupItems.push({
                type: 'GROUP',
                id: resourceGroup._id,
                trace: trace,
                parentId: trace[trace.length - 1],
                hasSubMenu: true,
                label: newLabel,
                icon: null,
                customItem: GroupItem,
                lazyLoading: false,
                search: true,
                searchIndex: 1,
                virtualize: 30 < filteredResources.length,
                searchVisible: false,
                emptyComponent: <EmptyGroup />,
                itemClass: groups ? 'tree-3' : '',
                to: `resource/group/${resourceGroup._id}`,
                content,
                inputProps: {
                    group: resourceGroup,
                    itemsCount: calculateGroupCount(resourceGroup, subGroups, this.options.uniqueSubgroupsCount),
                    subGroupsCount: subGroups.length,
                },
            });
        });

        return resourceGroupItems;
    }

    renderProjectGroups(groups, trace = []) {
        let projectGroupItems = [];
        const filteredProjectGroups = filter(this.projectGroups, projectGroup => {
            if (!this.options.displayArchivedInScheduler) {
                return (
                    TYPE_ARCHIVED_PROJECT !== projectGroup.criteria &&
                    (!projectGroup.type || projectGroup.type !== SCHEDULER_TEMPLATE)
                );
            }

            return (
                (!projectGroup.type || projectGroup.type !== SCHEDULER_TEMPLATE) &&
                (!includes([TYPE_PENDING, TYPE_FLOATING, TYPE_PLANNED], projectGroup.criteria) ||
                    0 !== projectGroup.projects.length)
            );
        });

        forEach(
            groups ||
                orderGroups(
                    this.account.preferences.topLevelProjectGroupOrder,
                    filter(filteredProjectGroups, group => !group.parentGroupId)
                ),
            projectGroup => {
                const subGroups = filter(filteredProjectGroups, group => group.parentGroupId === projectGroup._id);
                const filteredProjects = filter(
                    this.projects,
                    project =>
                        includes(projectGroup.projects, project._id) &&
                        (this.options.displayArchivedInScheduler || project.status !== PROJECT_STATUS_ARCHIVED.value)
                );
                const newLabel = this.getProjectGroupNameWithReplacedKeywords(projectGroup);
                const itemsCount = projectGroup.count + sumBy(subGroups, subGroup => subGroup.count);

                const isStatusGroup =
                    projectGroup.groupType === SYSTEM &&
                    [ARCHIVED, FLOATING, PENDING, PLANNED].includes(projectGroup.criteria);

                const content = this.renderProjectItems(projectGroup, groups, filteredProjects, [
                    ...trace,
                    projectGroup._id,
                ]);

                if (!(isStatusGroup && itemsCount === 0)) {
                    projectGroupItems.push({
                        type: 'GROUP',
                        id: projectGroup._id,
                        parentId: trace[trace.length - 1],
                        trace: trace,
                        hasSubMenu: true,
                        label: newLabel,
                        icon: null,
                        customItem: GroupItem,
                        lazyLoading: false,
                        search: true,
                        searchIndex: 1,
                        searchVisible: false,
                        emptyComponent: <EmptyGroup type="PROJECT" />,
                        itemClass: groups ? 'tree-3' : '',
                        to: `project/group/${projectGroup._id}`,
                        content,
                        inputProps: {
                            group: projectGroup,
                            itemsCount,
                            subGroupsCount: subGroups.length,
                        },
                    });
                }
            }
        );

        return projectGroupItems;
    }

    renderResourceItems(resourceGroup, isSecondLevel, filteredResources, trace) {
        const resourceItems =
            MY_SCHEDULE !== resourceGroup.criteria && filteredResources.length
                ? [
                      {
                          id: `${resourceGroup._id}_group_schedule`,
                          trace,
                          hasSubMenu: false,
                          label: 'Group schedule',
                          icon: Group,
                          itemClass: isSecondLevel ? 'tree-3' : 'tree-2',
                          customItem: ResourceItem,
                          skipSearch: true,
                          to: `/resource/group/${resourceGroup._id}`,
                          inputProps: {
                              subText: 'View all bookings for group',
                              searchIcon: Search,
                          },
                      },
                  ]
                : [];
        const subGroups = filter(this.resourceGroups, group => resourceGroup._id === group.parentGroupId);

        forEach(filteredResources, resource => {
            resourceItems.push({
                type: 'ITEM',
                id: `${resource._id}_${resourceGroup._id}`,
                trace,
                parentId: resourceGroup._id,
                hasSubMenu: false,
                label: resource.name,
                itemClass: isSecondLevel ? 'tree-3' : 'tree-2',
                customItem: ResourceItem,
                customIcon: true,
                to: `/resource/${resource._id}/group/${resourceGroup._id}`,
                inputProps: {
                    resource,
                    resourceGroupType: resourceGroup.groupType,
                    resourceGroup: resourceGroup,
                    subText:
                        TYPE_UNASSIGNED.value === resource.type
                            ? TYPE_UNASSIGNED.display
                            : roles[resource.role].display,
                    contextMenu: true,
                },
            });
        });

        return subGroups.length
            ? resourceItems.concat(this.renderResourceGroups(subGroups, [...trace, resourceGroup._id]))
            : resourceItems;
    }

    getProjectManagersNames(projectManagersIds) {
        return (projectManagersIds || []).map(projectManagerId => {
            const resourcesRedux = store.getState().resourceReducer?.resources || [];
            const pmResource = resourcesRedux.find(resource => resource._id === projectManagerId);
            if (pmResource) {
                return `${pmResource.firstName} ${pmResource.lastName}`;
            }
            return '';
        });
    }

    renderProjectItems(projectGroup, isSecondLevel, filteredProjects, trace) {
        const projectItems = filteredProjects.length
            ? [
                  {
                      id: `${projectGroup._id}_group_schedule`,
                      trace,
                      parentId: trace[trace.length - 1],
                      hasSubMenu: false,
                      label: 'Group schedule',
                      icon: Group,
                      itemClass: isSecondLevel ? 'tree-3' : 'tree-2',
                      customItem: ProjectItem,
                      skipSearch: true,
                      to: `/project/group/${projectGroup._id}`,
                      inputProps: {
                          subText: 'View all bookings for group',
                          searchIcon: Search,
                      },
                  },
              ]
            : [];
        const subGroups = filter(this.projectGroups, group => projectGroup._id === group.parentGroupId);
        forEach(filteredProjects, project => {
            projectItems.push({
                type: 'ITEM',
                id: `${project._id}_${projectGroup._id}`,
                trace,
                parentId: projectGroup._id,
                hasSubMenu: false,
                label: project.name,
                textSearch: `${project.name} ${project.projectCode}`,
                icon: null,
                itemClass: isSecondLevel ? 'tree-3' : 'tree-2',
                customItem: ProjectItem,
                to: `/project/${project._id}/group/${projectGroup._id}`,
                inputProps: {
                    project: {
                        ...project,
                        projectManagerNames: this.getProjectManagersNames(project.projectManagers),
                    },
                    projectGroupType: projectGroup.groupType,
                    projectGroup: projectGroup,
                    subText: `${project.resourcesCount} ${this.keyWords.resourcePluralKeyWord}`,
                    contextMenu: true,
                },
            });
        });

        return subGroups.length
            ? projectItems.concat(this.renderProjectGroups(subGroups, [...trace, projectGroup._id]))
            : projectItems;
    }

    renderSmartFiltersExtensionNotInstalled() {}

    renderScheduleTemplates(trace) {
        const scheduleSystemGroups = map(
            filter(this.resourceGroups, group => group.groupType === SYSTEM),
            schedule => ({ criteria: schedule.criteria, to: `/${this.currentView}/group/${schedule._id}` })
        );

        const isSmartFiltersInstalled = isActive(this.extensions, SMART_FILTERS);
        const templates = [
            {
                id: `0001`,
                trace,
                hasSubMenu: false,
                label: `Available ${this.keyWords.resourcePluralKeyWord.toLowerCase()}`,
                itemClass: 'tree-3',
                display: 'resource' === this.currentView,
                to: isSmartFiltersInstalled
                    ? getScheduleSystemGroupPath(scheduleSystemGroups, SCHEDULE_TEMPLATE_AVAILABLE)
                    : undefined,
                icon: Person,
                inputProps: {
                    subText: 'This Month',
                },
            },
            {
                label: `Fully booked ${this.keyWords.resourcePluralKeyWord.toLowerCase()}`,
                id: `0004`,
                trace,
                hasSubMenu: false,
                itemClass: 'tree-3',
                display: 'resource' === this.currentView,
                to: isSmartFiltersInstalled
                    ? getScheduleSystemGroupPath(scheduleSystemGroups, SCHEDULE_TEMPLATE_FULLY_BOOKED)
                    : undefined,
                icon: Person,
                inputProps: {
                    subText: 'This month',
                },
            },
            {
                label: `Over utilized ${this.keyWords.resourcePluralKeyWord.toLowerCase()}`,
                id: `0002`,
                trace,
                hasSubMenu: false,
                itemClass: 'tree-3',
                display: 'resource' === this.currentView,
                to: isSmartFiltersInstalled
                    ? getScheduleSystemGroupPath(scheduleSystemGroups, SCHEDULE_TEMPLATE_OVER_UTILIZED)
                    : undefined,
                icon: Person,
                inputProps: {
                    subText: 'This month',
                },
            },
            {
                label: `Under utilized ${this.keyWords.resourcePluralKeyWord.toLowerCase()}`,
                id: `0003`,
                trace,
                hasSubMenu: false,
                itemClass: 'tree-3',
                display: 'resource' === this.currentView,
                to: isSmartFiltersInstalled
                    ? getScheduleSystemGroupPath(scheduleSystemGroups, SCHEDULE_TEMPLATE_UNDER_UTILIZED)
                    : undefined,
                icon: Person,
                inputProps: {
                    subText: 'This month',
                },
            },
        ];

        if (!isSmartFiltersInstalled) {
            return templates.map(template => {
                return {
                    ...template,
                    endIcon: () => {
                        return <Adjust style={endIconStyles} />;
                    },
                    disabled: true,
                    tooltipText: smartFiltersExtensionTooltipMessage,
                    onClick: () => {
                        store.dispatch(showMissingSmartFiltersExtensionModal());
                    },
                };
            });
        }

        return templates;
    }

    items() {
        const items = 'resource' === this.currentView ? this.renderResourceGroups() : this.renderProjectGroups();
        const isPMExtensionActive = isActive(this.extensions, PM);
        const isUnassignedExtensionActive = isActive(this.extensions, UNASSIGNED);
        const hasPMManageEventsRights =
            this.account.isProjectManager &&
            hasRole(this.account.resourceRoleRights, 'pmManageEvents') &&
            isPMExtensionActive;
        const hasManageUnassignedRights =
            isUnassignedExtensionActive && hasRole(this.account.resourceRoleRights, 'manageUnassignedRows');
        const hasPMManageUnassignedRows =
            this.account.isProjectManager &&
            isPMExtensionActive &&
            isUnassignedExtensionActive &&
            hasRole(this.account.resourceRoleRights, 'pmManageUnassignedRows');
        const hasScheduleRights =
            hasOneOfRoles(this.account.resourceRoleRights, [
                'scheduleMyselfOnAnyProjectOrEvent',
                'scheduleMyselfOnProjectsOrEventsIAmPartOf',
                'manageEvents',
            ]) ||
            hasPMManageEventsRights ||
            hasManageUnassignedRights ||
            hasPMManageUnassignedRows;

        const hasCreateProjectRights = hasRole(this.account.resourceRoleRights, 'settingAddEditProjects');
        const hasCreateResourceRights = hasRole(this.account.resourceRoleRights, 'settingAddEditResources');
        const hasCreateProjectGroupRights = hasRole(this.account.resourceRoleRights, 'settingAddNewEditProjectGroups');
        const hasCreateResourceGroupRights = hasRole(
            this.account.resourceRoleRights,
            'settingAddNewEditResourceGroups'
        );

        const isSmartSchedulesInstalled = isActive(this.extensions, SMART_SCHEDULES);
        const smartTemplatesContent = this.renderScheduleTemplates([
            `saved_schedules_${this.currentView}`,
            `smart_template_schedules_${this.currentView}`,
        ]);

        const smartTemplatesItems = isSmartSchedulesInstalled
            ? map(
                  filter(
                      'resource' === this.currentView ? this.resourceGroups : this.projectGroups,
                      group =>
                          group.type === SCHEDULER_TEMPLATE &&
                          group.groupType !== SYSTEM &&
                          'PRIVATE' === group.visibility
                  ),
                  schedule => ({
                      id: `schedule_${schedule._id}`,
                      trace: [`saved_schedules_${this.currentView}`, `my_saved_schedules_${this.currentView}`],
                      hasSubMenu: false,
                      label: schedule.name,
                      customItem: ScheduleItem,
                      to: `/${this.currentView}/group/${schedule._id}`,
                      itemClass: 'tree-3',
                      schedule,
                      inputProps: {
                          contextMenu: true,
                      },
                  })
              )
            : [];

        const internalSchedules = isSmartSchedulesInstalled
            ? map(
                  filter(
                      'resource' === this.currentView ? this.resourceGroups : this.projectGroups,
                      group =>
                          group.type === SCHEDULER_TEMPLATE &&
                          group.groupType !== SYSTEM &&
                          'INTERNAL' === group.visibility
                  ),
                  schedule => ({
                      id: `schedule_${schedule._id}`,
                      trace: [`saved_schedules_${this.currentView}`, `saved_schedules_${this.currentView}`],
                      hasSubMenu: false,
                      label: schedule.name,
                      customItem: ScheduleItem,
                      to: `/${this.currentView}/group/${schedule._id}`,
                      itemClass: 'tree-3',
                      schedule,
                      inputProps: {
                          contextMenu: true,
                      },
                  })
              )
            : [];

        return [
            {
                id: 1,
                trace: [],
                label: this.keyWords.projectPluralKeyWord,
                customItem: SwitcherMenuItem,
                icon: Folder,
                currentClassItem: 'menu-top-actions',
                inputProps: {
                    view: 'project',
                },
                hasSubMenu: false,
            },
            {
                id: 2,
                trace: [],
                label: this.keyWords.resourcePluralKeyWord,
                customItem: SwitcherMenuItem,
                icon: Group,
                currentClassItem: 'menu-top-actions',
                inputProps: {
                    view: 'resource',
                },
                hasSubMenu: false,
            },
            {
                id: 3,
                trace: [],
                label: 'Add new',
                icon: AddCircle,
                omitPlaceholder: true,
                hasSubMenu: true,
                notClearSchedulerSelection: true,
                display:
                    hasScheduleRights ||
                    hasCreateResourceRights ||
                    hasCreateProjectRights ||
                    hasCreateProjectGroupRights ||
                    hasCreateResourceGroupRights,
                content: [
                    {
                        id: 4,
                        trace: [3],
                        hasSubMenu: false,
                        label: `Schedule ${this.keyWords.resourceKeyWord.toLowerCase()}`,
                        icon: List,
                        customItem: NewItem,
                        display: hasScheduleRights,
                        inputProps: {
                            action: 'showScheduleResourceModal',
                        },
                    },
                    {
                        id: 5,
                        trace: [3],
                        hasSubMenu: false,
                        label: `New ${this.keyWords.projectKeyWord.toLowerCase()}`,
                        icon: InsertDriveFile,
                        customItem: NewItem,
                        display: hasCreateProjectRights,
                        inputProps: {
                            action: 'showProjectModal',
                            contextMenu: true,
                            type: 'PROJECT',
                        },
                    },
                    {
                        id: 6,
                        trace: [3],
                        hasSubMenu: false,
                        label: `New ${this.keyWords.resourceKeyWord.toLowerCase()}`,
                        icon: Person,
                        customItem: NewItem,
                        display: hasCreateResourceRights,
                        inputProps: {
                            action: 'showResourceModal',
                            contextMenu: true,
                            type: 'RESOURCE',
                        },
                    },
                    {
                        id: 7,
                        trace: [3],
                        hasSubMenu: false,
                        label: 'New schedule',
                        icon: Schedule,
                        endIcon: !isSmartSchedulesInstalled
                            ? () => {
                                  return <Adjust style={endIconStyles} />;
                              }
                            : undefined,
                        tooltipText: !isSmartSchedulesInstalled ? smartSchedulesExtensionTooltipMessage : undefined,
                        disabled: !isSmartSchedulesInstalled,
                        customItem: NewItem,
                        display: hasCreateProjectGroupRights || hasCreateResourceGroupRights,
                        inputProps: {
                            action: isSmartSchedulesInstalled
                                ? 'groupModal'
                                : 'showMissingSmartSchedulesExtensionModal',
                            builderType:
                                hasCreateProjectGroupRights && !hasCreateResourceGroupRights
                                    ? PROJECT.name
                                    : RESOURCE.name,
                            itemIdType: SCHEDULER_TEMPLATE,
                        },
                    },
                    {
                        id: 8,
                        trace: [],
                        hasSubMenu: false,
                        label: `New ${this.keyWords.projectKeyWord.toLowerCase()} group`,
                        icon: Folder,
                        customItem: NewItem,
                        display: hasCreateProjectGroupRights,
                        inputProps: {
                            action: 'groupModal',
                            builderType: PROJECT.name,
                        },
                    },
                    {
                        id: 9,
                        trace: [],
                        hasSubMenu: false,
                        label: `New ${this.keyWords.resourceKeyWord.toLowerCase()} group`,
                        icon: Group,
                        customItem: NewItem,
                        display: hasCreateResourceGroupRights,
                        inputProps: {
                            action: 'groupModal',
                            builderType: RESOURCE.name,
                        },
                    },
                    {
                        id: 10,
                        trace: [],
                        hasSubMenu: false,
                        label: `New event`,
                        icon: Event,
                        customItem: NewItem,
                        display: hasCreateProjectRights,
                        inputProps: {
                            action: 'showEventModal',
                        },
                    },
                    {
                        id: 11,
                        trace: [],
                        hasSubMenu: false,
                        label: `New unassigned`,
                        icon: PersonAddDisabled,
                        customItem: NewItem,
                        display: !isUnassignedExtensionActive || hasCreateResourceRights,
                        inputProps: {
                            action: isUnassignedExtensionActive
                                ? 'showUnassignedModal'
                                : 'showMissingUnassignedWorkExtensionModal',
                        },
                        endIcon: !isUnassignedExtensionActive
                            ? () => {
                                  return <Adjust style={endIconStyles} />;
                              }
                            : undefined,
                        disabled: !isUnassignedExtensionActive,
                        tooltipText: !isUnassignedExtensionActive ? unassignedWorkExtensionTooltipMessage : undefined,
                    },
                ],
            },
            {
                id: 12,
                trace: [],
                hasSubMenu: false,
                customItem: SeparatorItem,
                inputProps: {
                    text: 'My schedules',
                },
            },
            {
                id: `my_schedule_${this.currentView}`,
                trace: [],
                hasSubMenu: false,
                label: 'My schedule',
                display: 'resource' === this.currentView,
                to: `/resource/${this.account.resourceId}/group/${this.account.preferences.myScheduleGroupId}`,
                icon: Person,
            },
            {
                id: `saved_schedules_${this.currentView}`,
                trace: [],
                hasSubMenu: true,
                label: 'Smart schedules',
                icon: FormatAlignLeft,
                omitPlaceholder: true,
                content: [
                    {
                        id: `smart_template_schedules_${this.currentView}`,
                        trace: [`saved_schedules_${this.currentView}`],
                        hasSubMenu: Boolean(smartTemplatesContent.length),
                        label: 'Smart templates',
                        icon: EmojiObjects,
                        itemClass: 'tree-2',
                        omitPlaceholder: true,
                        search: true,
                        emptyText: !smartTemplatesContent.length ? 'No Smart Templates' : undefined,
                        content: smartTemplatesContent,
                        display: 'resource' === this.currentView,
                    },
                    {
                        id: `my_saved_schedules_${this.currentView}`,
                        trace: [`saved_schedules_${this.currentView}`],
                        hasSubMenu: Boolean(smartTemplatesItems.length),
                        label: 'Saved schedules',
                        icon: Save,
                        endIcon: !isSmartSchedulesInstalled
                            ? () => {
                                  return <Adjust style={endIconStyles} />;
                              }
                            : undefined,
                        tooltipText: !isSmartSchedulesInstalled ? smartSchedulesExtensionTooltipMessage : undefined,
                        disabled: !isSmartSchedulesInstalled,
                        itemClass: 'tree-2',
                        omitPlaceholder: true,
                        emptyComponent: <EmptyGroup />,
                        onClick: !isSmartSchedulesInstalled
                            ? () => {
                                  store.dispatch(showMissingSmartSchedulesExtensionModal());
                              }
                            : undefined,
                        content: smartTemplatesItems,
                    },
                    {
                        label: 'Internal schedules',
                        trace: [`saved_schedules_${this.currentView}`],
                        hasSubMenu: Boolean(internalSchedules.length),
                        icon: Public,
                        endIcon: !isSmartSchedulesInstalled
                            ? () => {
                                  return <Adjust style={endIconStyles} />;
                              }
                            : undefined,
                        tooltipText: !isSmartSchedulesInstalled ? smartSchedulesExtensionTooltipMessage : undefined,
                        disabled: !isSmartSchedulesInstalled,
                        itemClass: 'tree-2',
                        omitPlaceholder: true,
                        id: `internal_${this.currentView}`,
                        emptyComponent: <EmptyGroup />,
                        onClick: !isSmartSchedulesInstalled
                            ? () => {
                                  store.dispatch(showMissingSmartSchedulesExtensionModal());
                              }
                            : undefined,
                        content: internalSchedules,
                    },
                ],
            },
            {
                id: 13,
                trace: [],
                hasSubMenu: false,
                customItem: SearchItem,
                icon: Search,
                currentClassItem: 'search-item col-sm-3 col-md-4 col-lg-3 col-xl-2',
            },
            ...items,
        ];
    }

    getResourceGroupNameWithReplacedKeywords(resourceGroup = {}) {
        const { groupType, name } = resourceGroup;
        if (groupType === USER) {
            return name;
        }

        if (name === 'Active') {
            return `${name} ${this.keyWords.resourcePluralKeyWord}`;
        }

        return replaceKeywords(this.replaceMap, name);
    }

    getProjectGroupNameWithReplacedKeywords(projectGroup = {}) {
        const projectStatuses = this.projectStatuses || [];
        const { groupType, name } = projectGroup;
        if (groupType === USER) {
            return name;
        }
        if (groupType === SYSTEM) {
            return replaceKeywords(
                {
                    ...this.replaceMap,
                    Active:
                        projectStatuses.find(status => status.value === PROJECT_STATUS_ACTIVE.value)?.display ||
                        PROJECT_STATUS_ACTIVE.display,
                    Archived:
                        projectStatuses.find(status => status.value === PROJECT_STATUS_ARCHIVED.value)?.display ||
                        PROJECT_STATUS_ARCHIVED.display,
                    Floating:
                        projectStatuses.find(status => status.value === PROJECT_STATUS_FLOATING.value)?.display ||
                        PROJECT_STATUS_FLOATING.display,
                    Pending:
                        projectStatuses.find(status => status.value === PROJECT_STATUS_PENDING.value)?.display ||
                        PROJECT_STATUS_PENDING.display,
                    Planned:
                        projectStatuses.find(status => status.value === PROJECT_STATUS_PLANNED.value)?.display ||
                        PROJECT_STATUS_PLANNED.display,
                },
                name
            );
        }

        return replaceKeywords(this.replaceMap, name);
    }
}

export default new SchedulerMenu();
