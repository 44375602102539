import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Scrollbar from 'shared/scrollbar';

const ScrollableListComponent = ({
    height,
    listComponent: ListComponent,
    onScroll,
    children,
    scrollMargin,
    ...rest
}) => {
    const inner = (
        <Scrollbar
            className={classNames('scrollbar-wrapper', { 'mt-2': scrollMargin })}
            height={height}
            onScroll={onScroll}
        >
            {children}
        </Scrollbar>
    );

    if (!ListComponent) {
        return inner;
    }

    return <ListComponent {...rest}>{inner}</ListComponent>;
};

ScrollableListComponent.propTypes = {
    height: PropTypes.number.isRequired,
    listComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    onScroll: PropTypes.func.isRequired,
    scrollMargin: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default ScrollableListComponent;
