import React from 'react';
import { useSelector } from 'react-redux';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { Button } from '@hub-mono/ui';
import { toDate } from '@hub-mono/utils';
import { contains } from 'underscore';
import { REJECTED, APPROVED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { getCompanySettings, getResourceRoleRight } from 'selectors/company';

export const EditSelected = ({ booking, resourceId, editRequest, clearSelected }) => {
    const { vacationId } = useSelector(getCompanySettings);
    const resourceRoleRights = useSelector(getResourceRoleRight);

    const isAbleToEdit = isAbleToEditResourceOrVacationRequest(booking, resourceId, resourceRoleRights, vacationId);

    if (
        isAbleToEdit &&
        contains([REJECTED.value, APPROVED.value, WAITING_FOR_APPROVAL.value], booking?.type || '')
    ) {
        return (
            <Button
                variant="text"
                endIcon="edit"
                onClick={() => {
                    editRequest({
                        ...booking,
                        end: { value: toDate(booking.end) },
                        start: { value: toDate(booking.start) },
                        resourceInfo: {
                            _id: booking.resource,
                            firstName: booking.resourceFirstName || '',
                            lastName: booking.resourceLastName || '',
                        },
                        categoryTemplate: { _id: booking.categoryTemplateId },
                        project: booking.project?._id
                            ? booking.project
                            : { _id: booking.project, name: booking.projectName || '' },
                        id: booking._id,
                    });
                    clearSelected();
                }}
            >
                Edit Selected
            </Button>
        );
    }
};
