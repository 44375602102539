import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const initialize = {
    request: () => action(actionTypes.INITIAL['REQUEST']),
    success: () => action(actionTypes.INITIAL['SUCCESS']),
    failure: () => action(actionTypes.INITIAL['FAILURE']),
};

export const fullScreen = {
    request: enabled => action(actionTypes.FULLSCREEN['REQUEST'], { enabled }),
};
