import styled from 'styled-components';

export const Icon = styled.span`
    svg {
        font-size: 16px;
        vertical-align: middle;
        margin-left: 5px;
        color: #bbbbbb;
        &:hover {
            color: #afabab;
        }
    }
`;
