import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'react-fast-compare';
import Scrollbar from 'shared/scrollbar';
import classnames from 'classnames';
import { usePrevious } from 'hooks';
import Container from 'modules/sidebar/menu/components/Container';
import Link from 'modules/sidebar/menu/components/DefaultLink';
import {
    updateMenuContent,
    changeActiveLinkId,
    changeActiveLinkTo,
    changeActiveLinkLabel,
    changeActiveLinkFromLocation,
    changeSubMenuVisibility,
} from 'actions/menu/content';
import { updateListener } from 'actions/menu/emitters';
import { Provider as MenuProvider } from './../menuContext';

const MetisMenu = props => {
    const { content, LinkComponent, rowHeight, onSelected, ...restOfProps } = props;
    const dispatch = useDispatch();
    const { currentSubMenuOpenId, currentSubMenuOpenTrace } = useSelector(state => state.menuContent);
    const prevContent = usePrevious(content);

    useEffect(() => {
        onSelected && dispatch(updateListener(onSelected));
    }, [dispatch, onSelected]);

    const classStore = {
        classMainWrapper: classnames({ metismenu: !restOfProps.noBuiltInClassNames }, restOfProps.className),
        classContainer:
            'function' === typeof restOfProps.classNameContainer
                ? restOfProps.classNameContainer
                : classnames(
                      { 'metismenu-container': !restOfProps.noBuiltInClassNames },
                      restOfProps.classNameContainer
                  ),
        classContainerVisible: classnames(
            { visible: !restOfProps.noBuiltInClassNames },
            restOfProps.classNameContainerVisible
        ),
        classItem: classnames({ 'metismenu-item': !restOfProps.noBuiltInClassNames }, restOfProps.classNameItem),
        classLink: classnames({ 'metismenu-link': !restOfProps.noBuiltInClassNames }, restOfProps.classNameLink),
        classItemActive: restOfProps.classNameItemActive,
        classItemHasActiveChild: restOfProps.classNameItemHasActiveChild,
        classItemHasVisibleChild: restOfProps.classNameItemHasVisibleChild,
        classLinkActive: classnames({ active: !restOfProps.noBuiltInClassNames }, restOfProps.classNameLinkActive),
        classLinkHasActiveChild: classnames(
            { 'has-active-child': !restOfProps.noBuiltInClassNames },
            restOfProps.classNameLinkHasActiveChild
        ),
        classIcon: classnames({ 'metismenu-icon': !restOfProps.noBuiltInClassNames }, restOfProps.classNameIcon),
        classStateIcon: classnames(
            { 'metismenu-state-icon': !restOfProps.noBuiltInClassNames },
            restOfProps.classNameStateIcon
        ),

        iconNamePrefix: restOfProps.iconNamePrefix,
        iconNameStateHidden: restOfProps.iconNameStateHidden,
        iconNameStateVisible: restOfProps.iconNameStateVisible,
    };

    const updateActiveLink = useCallback(() => {
        if (restOfProps.activeLinkId) dispatch(changeActiveLinkId(restOfProps.activeLinkId));
        else if (restOfProps.activeLinkTo) dispatch(changeActiveLinkTo(restOfProps.activeLinkTo));
        else if (restOfProps.activeLinkLabel) dispatch(changeActiveLinkLabel(restOfProps.activeLinkLabel));
        else if (restOfProps.activeLinkFromLocation) dispatch(changeActiveLinkFromLocation());
    }, [
        dispatch,
        restOfProps.activeLinkId,
        restOfProps.activeLinkTo,
        restOfProps.activeLinkLabel,
        restOfProps.activeLinkFromLocation,
    ]);

    useEffect(() => {
        if (content.length && !isEqual(prevContent, content)) {
            dispatch(updateMenuContent(content));
            if (!currentSubMenuOpenId) {
                updateActiveLink();
                return;
            }
            dispatch(changeSubMenuVisibility(currentSubMenuOpenId, currentSubMenuOpenTrace, true));
        }
    }, [dispatch, prevContent, content, currentSubMenuOpenId, updateActiveLink, currentSubMenuOpenTrace]);

    useEffect(() => {
        if (!content.length) {
            return;
        }
        updateActiveLink();
    }, [updateActiveLink, content.length]);

    return (
        <MenuProvider
            value={{
                usePlaceholder: restOfProps.usePlaceholder,
                rowHeight,
                classStore,
                LinkComponent,
                loadingSubmenuData: restOfProps.loadingSubmenuData,
            }}
        >
            <Scrollbar height={'100%'} autoHide>
                <div className={classStore.classMainWrapper} id={restOfProps.id}>
                    <Container />
                </div>
            </Scrollbar>
        </MenuProvider>
    );
};

MetisMenu.defaultProps = {
    content: [],
    LinkComponent: Link,
    noBuiltInClassNames: false,
    usePlaceholder: false,
    className: null,
    id: null,
    classNameContainer: null,
    classNameContainerVisible: null,
    classNameItem: null,
    classNameItemActive: null,
    classNameItemHasActiveChild: null,
    classNameItemHasVisibleChild: null,
    classNameLink: null,
    classNameLinkActive: null,
    classNameLinkHasActiveChild: null,
    classNameIcon: null,
    classNameStateIcon: 'fa-angle-left',
    iconNamePrefix: 'fa fa-',
    iconNameStateHidden: 'caret-left',
    iconNameStateVisible: 'caret-left rotate-minus-90',
    activeLinkId: null,
    activeLinkTo: null,
    activeLinkLabel: null,
    activeLinkFromLocation: false,
    onSelected: null,
    rowHeight: 49,
};

MetisMenu.propTypes = {
    content: PropTypes.arrayOf(PropTypes.object),
    LinkComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    usePlaceholder: PropTypes.bool,
    noBuiltInClassNames: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    classNameContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    classNameContainerVisible: PropTypes.string,
    classNameItem: PropTypes.string,
    classNameItemActive: PropTypes.string,
    classNameItemHasActiveChild: PropTypes.string,
    classNameItemHasVisibleChild: PropTypes.string,
    classNameLink: PropTypes.string,
    classNameLinkActive: PropTypes.string,
    classNameLinkHasActiveChild: PropTypes.string,
    classNameIcon: PropTypes.string,
    classNameStateIcon: PropTypes.string,
    iconNamePrefix: PropTypes.string,
    iconNameStateHidden: PropTypes.string,
    iconNameStateVisible: PropTypes.string,

    activeLinkId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    activeLinkTo: PropTypes.string,
    activeLinkLabel: PropTypes.string,
    activeLinkFromLocation: PropTypes.bool,
    onSelected: PropTypes.func,
    rowHeight: PropTypes.number,
    loading: PropTypes.bool,
};

export default React.memo(MetisMenu);
