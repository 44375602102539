import Schema from 'form-schema-validation';
import { assign } from 'lodash';
import { errorMessages } from 'utils/schemaUtil';
import { TYPE_REGULAR } from 'enums/projectEnum';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        projectCode: {
            type: String,
            required: false,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = name => assign({}, formSchema.getDefaultValues(), name ? { name } : {});

export const mapFormToRequest = (values, resources) => ({
    project: { name: values.name, projectCode: values.projectCode, type: TYPE_REGULAR.value, resources },
});
