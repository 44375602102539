import { Cached } from '@material-ui/icons';
import React, { PureComponent } from 'react';
import { Button, Nav, Navbar } from 'reactstrap';
import { contains } from 'underscore';
import StorageUtil from 'utils/StorageUtil';

const testingCopmanyIds = [
    '5d414b23c0881e733f85f3ae', // HUB PLANNER - Ania (Prod)
    '5d414bf50c505f0c4f113734', // HUB PLANNER - Ania (Staging Specific as DB dump not done, so Prod account is not on S1 yet.)
    '57a368f21d478b1f089817b5', // HUB PLANNER - hubplannershow
    '5a031e96e1b0700c2beb6a57', // HUB PLANNER - mikesienn
    '5a7b8c165e8cf075f4fd3abd', // HUB PLANNER - helemartin
    '5a6b2d772959980c5559d5b0', // HUB PLANNER - miahub
    '5c7634306cd7bd7b1472a2b1', // HUB PLANNER - Helen
    '5d933d160996c2123f58950e', // HUB PLANNER - Max
    '5d637de5b4d2030c61d20dc6', // HUB PLANNER - Anna - heatmap branch
    '5d6e661d20b97c0c94bd7a86', // HUB PLANNER - Max - heatmap branch
    '5d839258930ff30c84e928c1', // HUB PLANNER - Mike - heatmap branch
    '5d83837649c6c10c45d91486', // HUB PLANNER - Stevo - heatmap branch
    '5ea82d8fe55e80552a5e0638', // HUB PLANNER - maksym prod
    '5ea825d900c9260d058c29da', // HUB PLANNER - maksym s1
    '5ea825d900c9260d058c29da', // HUB PLANNER - maksym s2
    '63c65c7406da14109995d256', // HUB PLANNER - jakub local
    '63cfa1f6734f69b404b65b5c', // HUB PLANNER - roman local
];

class DevelopmentHeader extends PureComponent {
    constructor(props) {
        super(props);

        this.state = { useReportCache: undefined };
    }

    componentDidMount() {
        if (
            contains(testingCopmanyIds, this.props.companyId) ||
            window.location.hostname.includes('staging') ||
            'development' === process.env.NODE_ENV
        ) {
            this.setState({
                useReportCache: StorageUtil.getItem('useReportCache') || 'ON',
            });
        }
    }

    toggleReportCache = () => {
        if ('ON' === this.state.useReportCache) {
            StorageUtil.setItem('useReportCache', 'OFF');
            this.setState({ useReportCache: 'OFF' });
        } else {
            StorageUtil.setItem('useReportCache', 'ON');
            this.setState({ useReportCache: 'ON' });
        }
    };

    render() {
        return this.state.useReportCache ? (
            <Navbar className="early-access-toolbar" color="light" light expand="md mb-2">
                <div>
                    <strong style={{ color: '#fc940e' }}>Development options</strong>
                </div>
                <Nav className="ml-auto" navbar>
                    <Button onClick={this.toggleReportCache} color="warning mr-2">
                        <Cached /> Turn {'ON' === this.state.useReportCache ? 'OFF' : 'ON'} report cache
                    </Button>
                </Nav>
            </Navbar>
        ) : null;
    }
}

export default DevelopmentHeader;
