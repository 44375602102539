import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RateForm from './../../common/createRate';
import HelpButton from './../../common/helpButton';
import { Field } from 'redux-form';
import { FormText, Button } from 'reactstrap';
import { chooseField, dropdownField } from 'shared/formFields';
import { getCurrency } from 'enums/currencyEnum';
import { FORM_NAME } from './modal';

const InternalExternalBookingRates = props => {
    const { rateType, rates, projectKeyWord, resourceKeyWord, type, helpButtonId } = props;
    const [isRatesFormOpen, toggleRatesForm] = useState(false);

    return (
        <section className="form-fields">
            <HelpButton id={helpButtonId}>
                <strong className="text-capitalize">{type} booking Rates:</strong>
                The {type} booking rate is what is actually going to be used on the {projectKeyWord} when it comes to
                reports to calculate totals for the {type} costs. The rate is inherited from the rate set on the{' '}
                {projectKeyWord} or {resourceKeyWord} (view {projectKeyWord} {type} Rates). You can override the rate
                used at this point. This can be useful if you want to set a different rate on this particular booking.
            </HelpButton>
            {isRatesFormOpen ? (
                <RateForm
                    form={`scheduleResource-${type}`}
                    formName={FORM_NAME}
                    fieldName={`bookingRate.${type}`}
                    onDismiss={() => toggleRatesForm(!isRatesFormOpen)}
                />
            ) : (
                <div>
                    <p className="title text-capitalize">{type} Booking Rate:</p>
                    <FormText className="mb-1">The {type} booking rate is used normally for outgoing work</FormText>
                    <Field
                        type="radio"
                        inline
                        label="Inherited Rate"
                        name={`${type}RateType`}
                        value="inherited"
                        component={chooseField}
                    />
                    <Field
                        type="radio"
                        inline
                        label="Custom Rate"
                        name={`${type}RateType`}
                        value="custom"
                        component={chooseField}
                    />
                    <Field
                        inline
                        name={`bookingRate.${type}`}
                        component={dropdownField}
                        valueField="_id"
                        disabled={'inherited' === rateType}
                        filter
                        textField={item =>
                            Object.keys(item).length
                                ? `${item.label}${
                                      item.currency ? ': ' + (getCurrency(item.currency).symbol || item.currency) : ''
                                  } ${item.rate}`
                                : null
                        }
                        data={rates}
                    />
                    {'custom' === rateType ? (
                        <FormText>
                            You can set the rate of this particular booking to use a custom rate. This can be useful if
                            a scheduled booking within a needs to be billed out differently than the inherited
                            rate&nbsp;
                            <Button onClick={() => toggleRatesForm(!isRatesFormOpen)} className="xs p-0" color="link">
                                Add new rate
                            </Button>
                        </FormText>
                    ) : (
                        <FormText>
                            This booking currently has no rate. You can override that by using the custom booking rate
                            option above or set a rate on the {projectKeyWord} or {resourceKeyWord}
                        </FormText>
                    )}
                </div>
            )}
        </section>
    );
};

InternalExternalBookingRates.propTypes = {
    helpButtonId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rates: PropTypes.array,
    projectKeyWord: PropTypes.string.isRequired,
    resourceKeyWord: PropTypes.string.isRequired,
    type: PropTypes.string,
    rateType: PropTypes.string.isRequired,
};

InternalExternalBookingRates.defaultProps = {
    rates: [],
    type: 'external',
};

export default InternalExternalBookingRates;
