import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { some, isEmpty, values } from 'lodash';
import { Edit } from '@material-ui/icons';
import { useBooking } from 'modules/scheduler/hooks';
import { useAppKeyWords, useLoadProjectsAndResources, useProjectAction } from 'hooks';
import { updateResource } from 'actions/resourceActions';
import { showScheduleResourceModal, showProjectModal, showResourceModal } from 'actions/modalActions';
import { makeProjectById } from 'selectors/project';
import { makeResourceById } from 'selectors/resource';
import { NOTE_TAB_ID } from 'modules/modals/scheduleResourceModal/tabIcons';
import Links from './links';
import { LinksHelpText } from './styles';

const BookingLinks = React.forwardRef(({ currentSelectedBooking, onClose }, schedulerRef) => {
    const dispatch = useDispatch();
    useLoadProjectsAndResources();
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const { updateBooking } = useBooking(currentSelectedBooking);
    const { updateProject } = useProjectAction(schedulerRef);
    const projectSelector = useMemo(() => makeProjectById(currentSelectedBooking.project._id), [
        currentSelectedBooking.project._id,
    ]);
    const resourceSelector = useMemo(() => makeResourceById(currentSelectedBooking.resourceInfo._id), [
        currentSelectedBooking.resourceInfo._id,
    ]);
    const project = useSelector(projectSelector);
    const resource = useSelector(resourceSelector);

    const hasBookingLinks = some(
        values(currentSelectedBooking.links),
        link => -1 === link.indexOf('fa-') && !isEmpty(link)
    );
    const hasProjectLinks =
        project && some(values(project.links), link => -1 === link.indexOf('fa-') && !isEmpty(link));
    const hasResourceLinks =
        resource && some(values(resource.links), link => -1 === link.indexOf('fa-') && !isEmpty(link));
    const bookingModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showScheduleResourceModal({
                onSubmit: updateBooking,
                activeTab: NOTE_TAB_ID,
                bookingId: currentSelectedBooking.id,
            })
        );
    }, [dispatch, updateBooking, onClose, currentSelectedBooking.id]);
    const resourceModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showResourceModal({
                onSubmit: data => dispatch(updateResource.request(currentSelectedBooking.resourceInfo._id, data)),
                type: currentSelectedBooking.resourceInfo.type,
                resourceId: currentSelectedBooking.resourceInfo._id,
            })
        );
    }, [dispatch, onClose, currentSelectedBooking.resourceInfo]);
    const projectModal = useCallback(() => {
        onClose && onClose();
        dispatch(
            showProjectModal({
                onSubmit: data => updateProject(currentSelectedBooking.project._id, data),
                type: currentSelectedBooking.project.type,
                projectId: currentSelectedBooking.project._id,
            })
        );
    }, [dispatch, onClose, updateProject, currentSelectedBooking.project]);

    return (
        <>
            <h4>Links</h4>
            <span className="text-muted">Links to this booking</span>
            <h6 className="mt-4">
                Booking Links {hasBookingLinks && <Edit fontSize="small" className="cursor-p" onClick={bookingModal} />}
            </h6>
            {hasBookingLinks ? (
                <Links links={currentSelectedBooking.links} />
            ) : (
                <LinksHelpText className="text-muted">No Links set</LinksHelpText>
            )}
            <h6 className="mt-4">
                {projectKeyWord} Links{' '}
                {hasProjectLinks && <Edit fontSize="small" className="cursor-p" onClick={projectModal} />}
            </h6>
            {hasProjectLinks ? (
                <Links links={project.links} />
            ) : (
                <LinksHelpText className="text-muted">No Links set</LinksHelpText>
            )}
            <h6 className="mt-4">
                {resourceKeyWord} Links{' '}
                {hasResourceLinks && <Edit fontSize="small" className="cursor-p" onClick={resourceModal} />}
            </h6>
            {hasResourceLinks ? (
                <Links links={resource.links} />
            ) : (
                <LinksHelpText className="text-muted">No Links set</LinksHelpText>
            )}
        </>
    );
});

BookingLinks.propTypes = {
    currentSelectedBooking: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BookingLinks;
