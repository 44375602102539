import React, { useCallback, useMemo } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import Schema from 'form-schema-validation';
import { MaterialInputGroupFormikWrapper } from '../../../../../shared/formFields/inputGroupField/material';
import { Layout } from '../../../../../shared/components/layout';
import { addDays, formatDate, removeUTCZuluFromDateTimestamp, toMidnight } from '../../../../../utils/DateUtil';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCompanyDeadlinesBeforeBookingEndDateConfig,
    selectIsDeadlinesExtensionInstalled,
} from '../../../../../selectors/company';
import { DateFieldFormikWrapper } from '../../../../../shared/formFields/dateField';
import Button from '../../../../../shared/components/mui-button';
import { deadlineDateValidator } from '../../../../../forms/scheduleResourceForm';
import classnames from 'classnames';
import { IconButton } from '../../../../../shared/components/iconButton';
import { BOOKING_FORMAT } from '../../../../../global/enums/dateFormat';
import { createDeadline, deleteDeadline, updateDeadline } from '../../../../../actions/bookingActions';
import { showConfirmationModal } from '../../../../../actions/modalActions';
import { Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { dispatchRefreshBookings } from '../../../utils/schedulerUtil';

const formSchema = ({ isDeadlinesExtensionInstalled, deadlinesCompanyConfig, validatePartial = false }) => {
    const schema = new Schema({
        end: {
            type: Date,
            required: false,
        },
        deadlineName: {
            type: Schema.optionalType(String),
            required: false,
            default: null,
        },
        deadlineDate: {
            type: Schema.optionalType(Date),
            required: false,
            default: null,
        },
    });

    schema.addValidator(
        deadlineDateValidator({ isDeadlinesExtensionInstalled, deadlinesCompanyConfig, validatePartial })
    );

    return schema;
};

const useValidate = ({ validatePartial = false }) => {
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);
    const deadlinesCompanyConfig = useSelector(selectCompanyDeadlinesBeforeBookingEndDateConfig);

    const schema = useMemo(() => {
        return formSchema({ isDeadlinesExtensionInstalled, deadlinesCompanyConfig, validatePartial });
    }, [deadlinesCompanyConfig, isDeadlinesExtensionInstalled, validatePartial]);

    return useCallback(
        values => {
            return schema.validate(values);
        },
        [schema]
    );
};

export const DeadlineForm = React.memo(
    ({ booking, withDelete, formClassName, inputsClassName, deleteBtnClassName, onSave, onDelete }) => {
        const isEdit = Boolean(booking?.deadlineDate);
        const dispatch = useDispatch();

        const initialValues = useMemo(
            () =>
                booking
                    ? {
                          deadlineName: booking.deadlineName,
                          deadlineDate: booking.deadlineDate
                              ? new Date(removeUTCZuluFromDateTimestamp(booking.deadlineDate))
                              : addDays(new Date(booking.end), 2),
                          end: new Date(booking.end),
                      }
                    : {},
            [booking]
        );

        const handleSubmit = useCallback(
            values => {
                const action = isEdit ? updateDeadline.request : createDeadline.request;

                if (booking.repeat && booking.repeatId) {
                    dispatch(
                        showConfirmationModal(
                            () => {
                                dispatch(
                                    action(
                                        {
                                            _id: booking._id || booking.id,
                                            deadlineName: values.deadlineName,
                                            deadlineDate: formatDate(
                                                toMidnight(values.deadlineDate),
                                                BOOKING_FORMAT,
                                                false
                                            ),
                                            repeatId: booking.repeatId,
                                        },
                                        {
                                            createRepeatFromExisting: false,
                                            modifyAllRepeated: false,
                                        },
                                        () => {
                                            dispatchRefreshBookings();
                                            if (onSave) {
                                                onSave();
                                            }
                                        }
                                    )
                                );
                            },
                            isEdit ? 'Deadline edit warning' : 'Deadline create warning',
                            <>
                                <Typography paragraph>
                                    You are trying to {isEdit ? 'update' : 'create'} a deadline on a repeating booking.
                                    Choose if you would like to update just this booking or all bookings from repeat
                                    series.
                                </Typography>
                                <Alert color="warning">
                                    <strong>Warning!</strong> If you {isEdit ? 'update' : 'create'} on this booking,
                                    booking will be updated and removed from the repeat cycle. If you update all, all
                                    bookings will be updated.
                                </Alert>
                            </>,
                            {
                                withEscButton: true,
                                withCancel: true,
                                confirmButtonText: 'Update this',
                                cancelActionOnHide: true,
                                onReject: () => {
                                    // do nothing
                                },
                                additionalButtons: [
                                    {
                                        text: 'Update all',
                                        onClick: () => {
                                            dispatch(
                                                action(
                                                    {
                                                        _id: booking._id || booking.id,
                                                        deadlineName: values.deadlineName,
                                                        deadlineDate: formatDate(
                                                            toMidnight(values.deadlineDate),
                                                            BOOKING_FORMAT,
                                                            false
                                                        ),
                                                        repeatId: booking.repeatId,
                                                    },
                                                    {
                                                        createRepeatFromExisting: true,
                                                        modifyAllRepeated: true,
                                                        removeRemovedRepeated: true,
                                                        bookingAfterDeadlineConfirmation: true,
                                                    },
                                                    () => {
                                                        if (onSave) {
                                                            onSave();
                                                        }
                                                    }
                                                )
                                            );
                                        },
                                    },
                                ],
                            }
                        )
                    );
                } else {
                    dispatch(
                        action(
                            {
                                _id: booking._id || booking.id,
                                deadlineName: values.deadlineName,
                                deadlineDate: formatDate(toMidnight(values.deadlineDate), BOOKING_FORMAT, false),
                            },
                            {
                                bookingAfterDeadlineConfirmation: true,
                            },
                            () => {
                                if (onSave) {
                                    onSave();
                                }
                            }
                        )
                    );
                }
            },
            [booking._id, booking.id, booking.repeat, booking.repeatId, dispatch, isEdit, onSave]
        );

        const validate = useValidate({ validatePartial: false });

        if (!booking) {
            return null;
        }

        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validate={validate}
                style={{ height: '100%' }}
                className={formClassName}
            >
                <Form style={{ height: '100%' }}>
                    <FormInputs
                        isEdit={isEdit}
                        booking={booking}
                        withDelete={withDelete}
                        className={inputsClassName}
                        deleteBtnClassName={deleteBtnClassName}
                        onDelete={onDelete}
                    />
                </Form>
            </Formik>
        );
    }
);

const FormInputs = React.memo(({ isEdit, booking, withDelete, className, deleteBtnClassName, onDelete }) => {
    const formikContext = useFormikContext();
    const { values } = formikContext;

    const validateWithWarning = useValidate({ validatePartial: true });

    const validationResponse = useMemo(() => {
        return validateWithWarning(values);
    }, [values, validateWithWarning]);

    const hasDeadlineDateWarning = Boolean(validationResponse.deadlineDate?.[0]);

    return (
        <Layout
            stack
            gap="24px"
            className={classnames(className ?? 'pr-4 pl-4', isEdit ? '' : 'pt-4')}
            style={{ height: '100%' }}
        >
            {isEdit && withDelete && (
                <DeleteDeadlineBtn booking={booking} className={deleteBtnClassName} onDelete={onDelete} />
            )}
            <MaterialInputGroupFormikWrapper
                id="deadlineName"
                name="deadlineName"
                placeholder="Deadline title (optional)"
                label="Deadline title"
                dataCy="deadline-title"
            />
            <DateFieldFormikWrapper
                id="deadlineDate"
                name="deadlineDate"
                placeholder="Deadline date"
                label="Deadline date"
                formHelperText={validationResponse.deadlineDate?.[0]}
                formHelperTextClassName={hasDeadlineDateWarning ? 'Mui-error' : ''}
                dataCy="deadline-date-picker"
                componentDataCyPrefix="deadline-date-picker"
            />
            <div style={{ flexGrow: 1 }} />
            <SubmitButton booking={booking} />
        </Layout>
    );
});

export const DeleteDeadlineBtn = ({ booking, className, onDelete }) => {
    const dispatch = useDispatch();

    const handleDelete = useCallback(() => {
        if (booking.repeat && booking.repeatId) {
            dispatch(
                showConfirmationModal(
                    () => {
                        dispatch(
                            deleteDeadline.request(
                                {
                                    bookingId: booking._id || booking.id,
                                    repeatId: booking.repeatId,
                                },
                                {
                                    createRepeatFromExisting: false,
                                    modifyAllRepeated: false,
                                },
                                onDelete
                            )
                        );
                    },
                    'Delete deadline warning',
                    <>
                        <Typography paragraph>
                            You are trying to remove a deadline from a repeating booking. Choose if you would like to
                            update just this deadline or all bookings from repeat series.
                        </Typography>
                        <Alert color="warning">
                            <strong>Warning!</strong> If you delete this deadline, booking will be updated and removed
                            from the repeat cycle. If you update all, all bookings will be updated.
                        </Alert>
                    </>,
                    {
                        withEscButton: true,
                        withCancel: true,
                        confirmButtonText: 'Update this',
                        cancelActionOnHide: true,
                        onReject: () => {
                            // do nothing
                        },
                        additionalButtons: [
                            {
                                text: 'Update all',
                                onClick: () => {
                                    dispatch(
                                        deleteDeadline.request(
                                            {
                                                bookingId: booking._id || booking.id,
                                                repeatId: booking.repeatId,
                                            },
                                            {
                                                createRepeatFromExisting: true,
                                                modifyAllRepeated: true,
                                                removeRemovedRepeated: true,
                                            },
                                            onDelete
                                        )
                                    );
                                },
                            },
                        ],
                    }
                )
            );
        } else {
            dispatch(
                deleteDeadline.request(
                    {
                        bookingId: booking._id || booking.id,
                    },
                    {},
                    onDelete
                )
            );
        }
    }, [booking._id, booking.id, booking.repeat, booking.repeatId, dispatch, onDelete]);

    if (!booking.deadlineDate) {
        return null;
    }

    return (
        <Layout hAlign="flex-end" className={className}>
            <IconButton icon="trash" onClick={handleDelete} dataCy="deadline-delete" />
        </Layout>
    );
};

const SubmitButton = React.memo(({ booking }) => {
    const isEdit = Boolean(booking?.deadlineDate);
    const formikContext = useFormikContext();
    const { errors, submitForm, dirty } = formikContext;

    const hasErrors = errors && Object.keys(errors).length;

    const disableBtn = isEdit ? hasErrors || !dirty : hasErrors;

    return (
        <Button
            dataCy="add-update-deadline"
            variant="contained"
            type="button"
            disabled={disableBtn}
            onClick={submitForm}
        >
            {isEdit ? 'Update deadline' : 'Add deadline'}
        </Button>
    );
});
