import { ANY, ALL } from 'modules/scheduler/enums/filterRelationEnum';

export const INITIAL_SMART_FILTERS = {
    filters: {
        utilization: { operator: '', value: '' },
        availability: { operator: '', value: '' },
    },
    operator: ANY.value,
};

export const INITIAL_FILTERS = {
    bookingCategories: { filters: [], operator: ALL.value },
    bookingStatuses: { filters: [], operator: ANY.value },
    bookingProjectsEvents: { filters: [], operator: ANY.value },
    bookingResourcesUW: { filters: [], operator: ANY.value },
    bookingProjectStatuses: { filters: [], operator: ANY.value },
    currencies: { filters: [], operator: ANY.value },
    customers: { filters: [], operator: ANY.value },
    projectCustomFields: { filters: [], operator: ALL.value },
    projectDates: { start: {}, end: {} },
    projectManagers: { filters: [], operator: ANY.value },
    projectStatuses: { filters: [], operator: ANY.value },
    projectTags: { filters: [], operator: ANY.value },
    projects: { filters: [], operator: ANY.value },
    resourceCustomFields: { filters: [], operator: ALL.value },
    resourceRoles: { filters: [], operator: ANY.value },
    resourceStatuses: { filters: [], operator: ANY.value },
    resourceTags: { filters: [], operator: ANY.value },
    resources: { filters: [], operator: ANY.value },
    smartFilters: INITIAL_SMART_FILTERS,
};

export const FILTERS_WITH_OPERATOR = Object.entries(INITIAL_FILTERS)
    .filter(entry => entry[1].operator)
    .map(([key]) => key);

export const INITIAL_TITLE = 'Scheduler Builder';

export const INITIAL_FILTER_RELATION = ALL.value;

export const NEW_GROUP_INITIAL_TITLE = 'New Group';
export const NEW_SCHEDULE_INITIAL_TITLE = 'New Schedule';
