import React from 'react';
import moment from 'moment';
import { map, groupBy, contains, find, isFunction, isString } from 'underscore';
import { dateTypes } from 'modules/report/enums/dateTypeEnum';
import { getColumnProps } from 'modules/report/utils/columnHelper';
import { HeatMapHeader, HeatMapMainHeader } from 'modules/report/columns/styles';
import { COLUMN_UNAVAILABLE, ANONYMIZATION_STRING } from 'constants';

/**
 * @param {string} period
 * @param {string} unit
 * @param {number} avgDailyCapacity
 * @param {object} splitObject
 * @param {number} width
 * @param {string} columnName
 * @param {object} column
 *
 * @returns {object}
 */
const specificColumn = (period, unit, avgDailyCapacity, splitObject, width, columnName, column) => ({
    Header: () => (
        <HeatMapHeader>
            {dateTypes.YEAR.name === splitObject.name && period}
            {dateTypes.WEEK.name === splitObject.name &&
                `${moment(period, splitObject.format)
                    .startOf('isoWeek')
                    .format(splitObject.displayFormat)} - ${moment(period, splitObject.format)
                    .endOf('isoWeek')
                    .format(splitObject.displayFormat)}`}
            {!contains([dateTypes.YEAR.name, dateTypes.WEEK.name], splitObject.name) &&
                moment(period, splitObject.format).format(splitObject.displayFormat)}
        </HeatMapHeader>
    ),
    id: `dateColumns-${period}/${columnName}`,
    sortable: false,
    show: true,
    getProps: (state, rowInfo, columnProps) =>
        (column.getProps && column.getProps(state, rowInfo, columnProps)) ||
        getColumnProps(state, rowInfo, columnProps),
    accessor: row => {
        if (row[columnName] === ANONYMIZATION_STRING) {
            return row[columnName];
        }
        const item = isString(row[columnName])
            ? row[columnName]
            : find(row[columnName], value => value.period === period) || {};
        const value = item && item === COLUMN_UNAVAILABLE.name ? COLUMN_UNAVAILABLE.name : item.value || 0;

        return isFunction(column.accessor)
            ? column.accessor({
                  [columnName]: value,
                  currencies: { [columnName]: (item && item.currencies) || [] },
              })
            : value;
    },
    Cell: row => (isFunction(column.Cell) ? column.Cell(row) : row.value),
    width: width,
});

/**
 * @param {string} unit
 * @param {number} avgDailyCapacity
 * @param {array}  periods
 * @param {string} split
 * @param {object} column
 * @param {string} columnName
 *
 * @returns {*[]}
 */
export const columns = (unit, avgDailyCapacity, periods, split, column, columnName) => {
    let splitObject = dateTypes[split];
    let groupedPeriods = groupBy(periods, splitObject.groupBy);
    let width = periods && 30 <= periods.length ? 60 : undefined;

    if (!columnName) {
        return [];
    }

    return map(groupedPeriods, (group, name) => {
        if (dateTypes.YEAR.name === split) {
            return specificColumn(name, unit, avgDailyCapacity, splitObject, width, columnName, column);
        }

        const headerName = moment(name, splitObject.groupedFormat).format(splitObject.displayGroupFormat);

        return {
            id: `dateColumns-${name}`,
            Header: () => (
                <React.Fragment>
                    <HeatMapMainHeader title={headerName}>{headerName}</HeatMapMainHeader>
                    <div className="clear" />
                </React.Fragment>
            ),
            columns: map(group, period =>
                specificColumn(period, unit, avgDailyCapacity, splitObject, width, columnName, column)
            ),
        };
    });
};
