import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useWindowDimension } from 'hooks';
import { getCompanyTree } from 'actions/companyTreeActions';
import Sidebar from 'modules/sidebar/container';
import Header from 'modules/header';
import RouteValidator from 'services/routeValidator/validator';
import Scrollbar from 'shared/scrollbar';

// order of bootstrapTheme and react-widgets was changed because
// localhost:3000 was not having same styles order like after deploy
import 'react-widgets/dist/css/react-widgets.css';
import 'assets/styles/bootstrapTheme.css';
import 'react-virtualized/styles.css';
import 'assets/styles/common/react-table.css';
import 'assets/styles/common/day-picker.css';
import 'assets/styles/common/react-metismenu-standart.min.css';
import 'assets/styles/common/main.css';
import 'assets/styles/common/buttons.css';
import 'assets/styles/common/dropdowns.css';
import 'assets/styles/common/modals.css';
import 'assets/styles/common/sidebar.css';
import 'assets/styles/requests.css';

const Base = ({ component: Component, isFullScreen, child, ...rest }) => {
    const dispatch = useDispatch();
    const { windowHeight, windowWidth } = useWindowDimension();
    useEffect(() => {
        dispatch(getCompanyTree.request());
    }, [dispatch]);

    return (
        <Route
            {...rest}
            render={matchProps => (
                <div className={rest.location.pathname.split('/')[1]}>
                    <Header />
                    <div className="container-fluid">
                        <Row>
                            <div
                                className={classnames('full-screen-sidebar off col-sm-3 col-md-4 col-lg-3 col-xl-2', {
                                    on: isFullScreen,
                                })}
                            >
                                <Sidebar
                                    windowHeight={windowHeight}
                                    windowWidth={windowWidth}
                                    isFullScreen={isFullScreen}
                                />
                            </div>
                            <Scrollbar
                                height={windowHeight - (991 >= windowWidth ? 42 : 51)}
                                className={classnames('col-sm-9 col-md-8 col-lg-9 col-xl-10 full-screen-main', {
                                    on: isFullScreen,
                                })}
                            >
                                <div className="main">
                                    {Component && <Component {...matchProps} />}
                                    {child && (
                                        <Switch>
                                            {child.map(route => (
                                                <RouteValidator key={route.path} {...route} />
                                            ))}
                                        </Switch>
                                    )}
                                </div>
                            </Scrollbar>
                        </Row>
                    </div>
                </div>
            )}
        />
    );
};

Base.propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
    path: PropTypes.string.isRequired,
};

Base.defaultProps = {
    component: null,
};

export default Base;
