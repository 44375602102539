import { axiosInstance } from './axiosInstance';

export const getProjectGroup = id =>
    axiosInstance
        .get(`projectGroup/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getProjectGroupsRequestCancellable = cancelToken => forScheduler =>
    axiosInstance
        .get(`projectGroup?forScheduler=${forScheduler ? true : false}`, { cancelToken })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getProjectGroupsRequest = forScheduler =>
    axiosInstance
        .get(`projectGroup?forScheduler=${forScheduler ? true : false}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateProjectGroupRequest = (data, id) =>
    axiosInstance
        .put(`projectGroup/v2/${id}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createProjectGroupRequest = data =>
    axiosInstance
        .post('projectGroup/v2', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const projectGroupProjectsRequest = id =>
    axiosInstance
        .get(`projectGroup/${id}/projects`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteProjectGroupRequest = id =>
    axiosInstance
        .delete(`projectGroup/${id}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateTopLevelProjectGroupOrderRequest = data =>
    axiosInstance
        .put('projectGroup', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
