import { formatDate } from '../../../../../utils/DateUtil';
import { BOOKING_FORMAT } from '../../../../../global/enums/dateFormat';
import { store } from '../../../../../store';
import { showConfirmationModal } from '../../../../../actions/modalActions';
import { updateDeadline } from '../../../../../actions/bookingActions';
import { Typography } from '@material-ui/core';
import { Alert } from 'reactstrap';
import React from 'react';
import { dispatchRefreshBookings } from '../../../utils/schedulerUtil';

export function handleDeadlineMove({ args }) {
    args.async = true;

    const bookingId = args.e.data.booking.id;
    const repeat = args.e.data.booking.repeat;
    const deadlineToUpdate = {
        _id: bookingId,
        repeat: args.e.data.booking.repeat,
        repeatId: args.e.data.booking.repeatId,
        deadlineName: args.e.data.booking.deadlineName,
        deadlineDate: formatDate(args.newStart.value, BOOKING_FORMAT, false),
    };

    if (!repeat) {
        args.loaded();
        store.dispatch(
            updateDeadline.request(
                {
                    ...deadlineToUpdate,
                },
                {},
                () => {
                },
                () => {
                    dispatchRefreshBookings();
                },
            )
        );
        return;
    }

    const onSuccess = () => {
        dispatchRefreshBookings();
    };

    const onFailure = () => {
        dispatchRefreshBookings();
    }

    store.dispatch(
        showConfirmationModal(
            () => {
                args.loaded();
                store.dispatch(
                    updateDeadline.request(
                        {
                            ...deadlineToUpdate,
                            repeat: false,
                            repeatId: null,
                            repeatTimes: 0,
                        },
                        {
                            createRepeatFromExisting: false,
                        },
                        onSuccess,
                        onFailure
                    )
                );
            },
            'Deadline update warning',
            () => (
                <>
                    <Typography paragraph>
                        You are trying to move a deadline from repeating booking. Choose if you would like to update
                        just this deadline or all bookings from repeat series.
                    </Typography>
                    <Alert color="warning">
                        <strong>Warning!</strong> If you update just selected deadline, booking will be updated and
                        removed from the repeat cycle. If you update all, all bookings will be updated.
                    </Alert>
                </>
            ),
            {
                withEscButton: true,
                withCancel: true,
                confirmButtonText: 'Update this',
                confirmButtonDataCy: 'button--update-this',
                dataCy: 'button--update-all',
                cancelActionOnHide: true,
                onReject: () => {
                    args.preventDefault();
                    args.loaded();
                },
                additionalButtons: [
                    {
                        text: 'Update all',
                        dataCy: 'button--update-all',
                        onClick: () => {
                            args.loaded();
                            store.dispatch(
                                updateDeadline.request(
                                    deadlineToUpdate,
                                    {
                                        createRepeatFromExisting: true,
                                        modifyAllRepeated: true,
                                        removeRemovedRepeated: true,
                                    },
                                    onSuccess,
                                    onFailure
                                )
                            );
                        },
                    },
                ],
            }
        )
    );
}
