import { hasOneOfRoles } from 'utils/rightsUtil';

export const resourceChildObject = (project, resource, contextMenu, accountRoleRights, visible = true) => {
    const contextMenuRights = hasOneOfRoles(accountRoleRights, ['settingAddEditProjects', 'pmAddEditProjects']);
    const dpMenu = contextMenuRights && contextMenu && contextMenu(resource, project);

    return {
        name: project.name,
        id: `${resource._id}_${project._id}`,
        tags: {
            type: project.type,
            isEventRow: false,
            parent: false,
            menuRow: false,
            project,
            resource,
            resourceId: resource._id,
            isProjectRow: true,
            isResourceRow: false,
        },
        cssClass: 'child-scheduler-row',
        marginTop: 3,
        marginBottom: 10,
        visible,
        areas: dpMenu?.items?.length
            ? [
                  {
                      visibility: 'Visible',
                      action: 'ContextMenu',
                      bottom: 0,
                      width: 30,
                      html: `<i data-cy="scheduler-row-resource-project-menu-${project?._id ||
                          ''}" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                      cssClass: 'resource-action-menu',
                      top: 0,
                      menu: dpMenu,
                      right: 0,
                  },
              ]
            : [],
    };
};
