import styled from 'styled-components';

export const PopUpHeader = styled.div`
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
    letter-spacing: 1px;
    color: #000;
`;

export const Content = styled.div`
    position: absolute;
    width: 340px;
    height: auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
    z-index: 9;

    &.top {
        top: 56px;
        right: -37px;
    }

    &.bottom {
        bottom: 45px;
        right: -14px;
    }

    &.top:after {
        top: -8px;
    }

    &.bottom:after {
        bottom: -8px;
    }

    input[type='text'] {
        height: 33px;
        background: #f5f5f5;
    }
`;
