import { store } from '../../store';

export const moveSidebarElementToMiddle = element => {
    let elementToMove = element;

    // search the li parent element in the UL
    while (elementToMove && elementToMove.parentNode && elementToMove.tagName !== 'LI') {
        elementToMove = elementToMove.parentNode;
    }

    const item = element.closest('.metismenu-item');
    const activeLink = item.querySelector('.metismenu-link.active');

    if (activeLink) {
        typeof activeLink?.scrollIntoView === 'function' &&
            activeLink.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    } else if (elementToMove && elementToMove.tagName === 'LI') {
        // Store the element. When data ready, it will be used to be scrolled in the middle
        typeof elementToMove?.scrollIntoView === 'function' &&
            elementToMove.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
    }
};

/**
 * Change sidebar view (resource or project)
 */
export const changeSidebarView = (newView, cb) => {
    if (newView !== store.getState()?.menuContent?.currentView) {
        const sidebarMenuParent = document.querySelector('.sidebar');
        const newViewSidebarElement =
            sidebarMenuParent && sidebarMenuParent.querySelector(`[data-sidebar-view="${newView}"]`);
        if (newViewSidebarElement) {
            newViewSidebarElement.click();
        }
    }
    cb && cb();
};

export const scrollToActive = (groupId, openGroup, retry) => {
    const fn = () => {
        const sidebarMenuParent = document.querySelector('.sidebar');

        if (!sidebarMenuParent) {
            return;
        }

        if (groupId && openGroup) {
            const theGroupElement = sidebarMenuParent.querySelector(`[data-group-id="${groupId}"]`);

            const caretElement = theGroupElement && theGroupElement.getElementsByClassName(`fa-angle-left`);

            if (theGroupElement && !caretElement?.[0].classList?.contains('rotate-minus-90')) {
                theGroupElement.click();
            }
        }

        const activeLink = sidebarMenuParent.querySelector('.metismenu-link.active');

        if (activeLink && typeof activeLink?.scrollIntoView === 'function') {
            activeLink.scrollIntoView({ behavior: 'auto', block: 'nearest' });
        }

        if (!activeLink && retry < 30) {
            scrollToActive(groupId, openGroup, retry + 1);
        }
    };

    setTimeout(() => {
        fn();
    }, 50);
};

/**
 * Finds group element in sidebar and clicks it, to open, then moves to middle
 */
export const openSidebarGroupId = (groupId, timeout, retry, cb) => {
    const fn = groupId => {
        const sidebarMenuParent = document.querySelector('.sidebar');
        const theGroupElement = sidebarMenuParent && sidebarMenuParent.querySelector(`[data-group-id="${groupId}"]`);

        if (theGroupElement) {
            // Open
            const alreadyOpened = Object.keys(store.getState()?.menuContent?.activeContentItems || []).includes(
                groupId
            );
            // Open
            theGroupElement && !alreadyOpened && theGroupElement.click();
            // Move to middle
            theGroupElement && moveSidebarElementToMiddle(theGroupElement);

            cb && cb();
        } else {
            if (retry && retry < 30) {
                openSidebarGroupId(groupId, timeout, retry + 1, cb);
            } else {
                cb && cb();
            }
        }
    };

    if (timeout && typeof timeout === 'number' && timeout >= 0) {
        setTimeout(() => {
            fn(groupId);
        }, timeout);
    } else {
        fn(groupId);
    }
};
