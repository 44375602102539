import styled from 'styled-components';

export const Title = styled.h4`
    font-size: 14px;
    font-weight: bold;
    margin-top: 0.6rem;
    margin-bottom: 0.2rem;
`;

export const Selected = styled.p`
    margin-bottom: 14px;
`;

export const List = styled.div``;

export const ListItem = styled.div`
    .form-check-input {
        margin-top: 0.33rem;
    }
`;
