import { forEach, filter, assign } from 'lodash';
import { removeNeighboursDuplicatesFromArray } from 'shared/lib/array';

let uid;
const flattenLevel = (content, parentId, activeContentItems, shouldAddTrace) => {
    let flatContent = [];
    forEach(content, item => {
        const id = item.id || uid;
        const currentItem = item.id && activeContentItems[item.id];
        uid += 1;
        const toPush = assign(
            {
                id,
                parentId: item.parentId || parentId,
                icon: item.icon,
                label: item.label,
                to: item.to || '',
                customIconClass: item.customIconClass,
                customIconContent: item.customIconContent,
                customIcon: item.customIcon,
                currentClassItem: item.currentClassItem,
                externalLink: item.externalLink,
                active: false,
                hasActiveChild: false,
                clearItems: false,
                defaultVisibility: item.defaultVisibility,
                subMenuVisibility: item.defaultVisibility || false,
                trace: item.trace,
                hasSubMenu: item.trace,
            },
            item,
            currentItem
                ? {
                      hasActiveChild: currentItem.hasActiveChild,
                      subMenuVisibility: currentItem.subMenuVisibility,
                      active: currentItem.active,
                  }
                : {}
        );
        flatContent.push(toPush);

        if (item.content) {
            if (item.search) {
                item.content.splice(item.searchIndex || 0, 0, {
                    hasSubMenu: false,
                    label: 'search',
                    filter: true,
                    filterVisible: item.searchVisible || false,
                    ...(shouldAddTrace
                        ? {
                              trace: item.trace ? [...item.trace, item.id] : undefined,
                              parentId: item.id,
                          }
                        : {}),
                });
            }
            const flattenLevelResults = flattenLevel(item.content, id, activeContentItems, shouldAddTrace);
            flatContent = flatContent.concat(flattenLevelResults);
        }
    });

    return removeNeighboursDuplicatesFromArray(flatContent, (a, b) => a.id === b.id);
};

let trace;
const mapTrace = (content, parentId) => {
    const subItems = filter(content, item => item.parentId === parentId);
    forEach(subItems, item => {
        item.trace = [...trace];
        trace.push(item.id);
        item.hasSubMenu = mapTrace(content, item.id) || (item.hasOwnProperty('emptyText') && 0 < item.emptyText.length);
        if (item.hasSubMenu) {
            item.to = '#';
        }
        trace.pop();
    });
    return 0 < subItems.length;
};

export default (content, activeContentItems) => {
    uid = 1;
    trace = [];
    const shouldAddTrace = Boolean(content[0].trace);

    const flatContent = flattenLevel(content, undefined, activeContentItems, shouldAddTrace);

    if (!shouldAddTrace) {
        mapTrace(flatContent);
    }

    return flatContent;
};
