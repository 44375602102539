/* eslint-disable */
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, reset, initialize, submit, change, getFormValues } from 'redux-form';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';
import { validateSchema } from 'utils/schemaUtil';
import { createCategoryTemplate } from 'actions/categoryTemplateActions';
import { formSchema, getDefaultValues } from 'modules/categoryGroups/categoryTemplateForm';
import { materialInputGroupField, dropdownFieldMaterial } from 'shared/formFields';
import colorPicker from 'shared/colorPicker';
import SuccessButton from 'shared/buttons/success';
import ButtonAllocation from 'shared/allocation/buttonAllocation';
import Allocation from 'shared/allocation/materialAllocation';
import { FormWrapper } from './styles';
import { mapCategoryTemplateFormDataToRequest } from '../../categoryTemplateService';
import { getAllocationDisplayValueFromFields } from 'utils/allocationUtil';
import { useAllocation } from 'hooks';

const formName = 'categoryForm';

const CategoryForm = props => {
    const {
        groupId,
        buttonFixed,
        disableGutters,
        inline,
        inlineAllocation,
        initialName,
        categories,
        categoryGroups,
        handleSubmit,
        invalid,
        onCreate,
        wrapperClassName,
        openSaveConfirmDialog,
    } = props;

    const [buttonTitle, setButtonTitle] = useState('');
    const dispatch = useDispatch();
    const currentFormValues = useSelector(getFormValues(formName));
    const allocationDefaultSettings = useAllocation();
    const useCategoriesAllocation = useSelector(state => state.companyReducer.company.settings.useCategoriesAllocation);

    useEffect(() => {
        dispatch(
            initialize(formName, getDefaultValues(allocationDefaultSettings, groupId, categoryGroups, initialName))
        );
    }, [dispatch, allocationDefaultSettings, categoryGroups, groupId, initialName]);

    useEffect(() => {
        if (!currentFormValues?.state) return;
        const { state, ...rest } = currentFormValues;
        setButtonTitle(getAllocationDisplayValueFromFields(state, rest));
    }, [currentFormValues]);

    const addCategory = data => {
        dispatch(
            createCategoryTemplate.request(mapCategoryTemplateFormDataToRequest(data), category => {
                dispatch(reset(formName));
                onCreate && onCreate({ category, categoryGroup: data.categoryGroup });
            })
        );
    };

    const onAddCategorySubmit = data => {
        if (openSaveConfirmDialog && categories.find(category => category.name === data.name)) {
            openSaveConfirmDialog(() => addCategory(data), data.name);
        } else {
            addCategory(data);
        }
    };

    const onColorChange = useCallback(
        values => {
            dispatch(change(formName, 'gridColor', values.hex));
        },
        [dispatch]
    );

    const onAllocationChange = useCallback(
        data => {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if ('state' !== key) {
                        data[key] = data[key];
                    }
                    data[key] = '' === data[key] ? '0' : data[key];
                    dispatch(change(formName, key, data[key]));
                }
            }
        },
        [dispatch]
    );

    return (
        <FormWrapper onSubmit={handleSubmit(onAddCategorySubmit)} className={classNames(wrapperClassName)}>
            <Row className={classNames({ 'no-gutters': disableGutters })}>
                <Col className="d-flex pr-0 flex-sm-wrap align-items-top" xs="auto">
                    <Field
                        fullWidth={false}
                        className={classNames('mb-2', { 'mr-2': inline, 'mr-2 w-75': !inline })}
                        name="name"
                        component={materialInputGroupField}
                        placeholder="Category name"
                    />

                    <Field
                        className="color-input mr-2 mb-2"
                        name="gridColor"
                        component={colorPicker}
                        pickerWidth={225}
                        pickerPosition="left"
                        maxHeight={230}
                        onColorChange={onColorChange}
                        color={currentFormValues?.gridColor}
                    />
                    {useCategoriesAllocation && !inlineAllocation && (
                        <ButtonAllocation
                            className={classNames({ 'mt-2 mr-0 w-100': !inline })}
                            title={buttonTitle}
                            allocationDefaultSettings={allocationDefaultSettings}
                            onAllocationChange={onAllocationChange}
                            formName={formName}
                        />
                    )}
                    {useCategoriesAllocation && inlineAllocation && (
                        <Allocation
                            inline={inline}
                            disableGutters={disableGutters}
                            onChange={onAllocationChange}
                            allocationDefaultState={allocationDefaultSettings.state}
                            formName={formName}
                        />
                    )}
                    {0 < categoryGroups.length && (
                        <Field
                            label="Category Group"
                            wrapperClassName="w-100 my-4"
                            data={categoryGroups}
                            filter={false}
                            valueField="_id"
                            dropUp
                            textField="name"
                            name="categoryGroup"
                            component={dropdownFieldMaterial}
                        />
                    )}
                    <SuccessButton
                        fixed={buttonFixed}
                        className="align-self-baseline"
                        disabled={invalid}
                        dataCy="button--add-category"
                        onClick={() => dispatch(submit(formName))}
                        name="Add category"
                    />
                </Col>
            </Row>
        </FormWrapper>
    );
};

CategoryForm.propTypes = {
    groupId: PropTypes.string,
    wrapperClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    categories: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    openSaveConfirmDialog: PropTypes.func,
    onCreate: PropTypes.func,
    categoryGroups: PropTypes.array,
    inline: PropTypes.bool,
    inlineAllocation: PropTypes.bool,
    buttonFixed: PropTypes.bool,
    disableGutters: PropTypes.bool,
    initialName: PropTypes.string,
};

CategoryForm.defaultProps = {
    categoryGroups: [],
    groupId: '',
    wrapperClassName: '',
    initialName: '',
    openSaveConfirmDialog: null,
    onCreate: null,
    inline: true,
    buttonFixed: false,
    inlineAllocation: false,
    disableGutters: false,
};

export default reduxForm({
    form: formName,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate: validateSchema(formSchema),
})(CategoryForm);
