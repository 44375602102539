import React from 'react';
import { AWS_S3_URL } from 'constants';

/**
 * Given a url image, returns back a valid image url or placeholder url image
 * @param {string} urlString
 * @returns string url of image file
 */
const getIconSource = (urlString = '') => {
    if (urlString.indexOf('http') === 0) {
        return urlString;
    } else if (urlString.indexOf('/uploads') === 0) {
        return `${AWS_S3_URL}${urlString}`;
    }
    return `${AWS_S3_URL}/img/placeholder_hub.jpg`;
};

const ImageIcon = props => {
    const { urlString, className, alt } = props;
    const iconSource = getIconSource(urlString);
    return <img src={iconSource} className={className ? className : 'w-100'} alt={alt || ''} />;
};

export default ImageIcon;
