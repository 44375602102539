import { store } from '../../../../../store';
import {
    selectIsRequestResourceExtensionInstalled,
    selectIsVacationExtensionInstalled,
} from '../../../../../selectors/company';
import { selectGroupIdsWhereIAmApprover, selectMappedGroupResources } from '../../../../../selectors/resourceGroup';
import { makeResourceById } from '../../../../../selectors/resource';
import { hasRights } from '../../../../../hooks';
import { some } from 'lodash';

const rights = [
    {
        rights: ['manageAllVacations'],
        rule: 'one',
        name: 'hasManageAllVacationsRights',
    },
    {
        rights: ['manageAllResourceRequests'],
        rule: 'one',
        name: 'hasManageAllResourceRequestsRights',
    },
    {
        rights: ['canRequestVacation'],
        rule: 'one',
        name: 'hasCreateRequestVacationRights',
    },
    {
        rights: ['canRequestResource'],
        rule: 'one',
        name: 'hasCreateRequestResourceRights',
    },
    {
        rights: ['canViewVacationRequestsOnScheduler'],
        rule: 'one',
        name: 'hasViewVacationRequestRights',
    },
    {
        rights: ['canViewResourceRequestsOnScheduler'],
        rule: 'one',
        name: 'hasViewResourceRequestRights',
    },
];

export const hasManageApprovalForBooking = args => {
    const booking = args.e.data;
    const reduxState = store.getState();
    const { isApprover, resourceId, resourceRoleRights } = reduxState.account;
    const defaultVacationId = reduxState.companyReducer.company.settings.vacationId;
    const isRequestResourceExtensionInstalled = selectIsRequestResourceExtensionInstalled(reduxState);
    const isVacationExtensionInstalled = selectIsVacationExtensionInstalled(reduxState);
    const mappedGroupResources = selectMappedGroupResources(reduxState);
    const groupIdsWhereIAmApprover = selectGroupIdsWhereIAmApprover(reduxState);
    const bookingResourceId = booking?.resourceInfo?._id || booking?.resource;
    const bookingResource = makeResourceById(booking?.resourceInfo?._id || booking?.resource)(reduxState);

    const { hasManageAllVacationsRights, hasManageAllResourceRequestsRights } = hasRights(rights, resourceRoleRights);

    const isRegularBooking = booking && !booking.phase && !booking.milestone && !booking.datesEvent && !booking.deadline;

    const amIApproverOnRequest =
        booking && isRegularBooking
            ? some(booking.approvalInfo.approvers, approver => approver.id === resourceId) ||
              groupIdsWhereIAmApprover.some(groupId => mappedGroupResources[groupId]?.[bookingResourceId])
            : false;

    const isVacationBooking = booking && isRegularBooking && defaultVacationId === booking.project._id;

    const canManageVacations = isVacationExtensionInstalled && hasManageAllVacationsRights;
    const canManageResourceRequest = isRequestResourceExtensionInstalled && hasManageAllResourceRequestsRights;

    const hasManageResourceApprovalForBooking =
        isRequestResourceExtensionInstalled &&
        (!isVacationExtensionInstalled || !isVacationBooking) &&
        (canManageResourceRequest || (isApprover && amIApproverOnRequest));

    const hasManageVacationApprovalForBooking =
        isVacationExtensionInstalled &&
        booking &&
        isVacationBooking &&
        (canManageVacations || (isApprover && amIApproverOnRequest));

    const hasManageApprovalForBooking =
        hasManageResourceApprovalForBooking ||
        hasManageVacationApprovalForBooking ||
        bookingResource?.approverIds.includes(resourceId);

    return hasManageApprovalForBooking;
};
