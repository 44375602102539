import React from 'react';
import PropTypes from 'prop-types';
import { eventHeights } from 'modules/scheduler/enums/eventHeightEnum';
import { GreenRadio, Section, SectionHeader } from './styles';
import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';

const BookingHeight = ({ eventHeightMode, onChange }) => (
    <Section>
        <SectionHeader>Booking bar height</SectionHeader>

        <FormControl component="fieldset" className="ml-1 mt-2">
            <RadioGroup
                aria-label="eventHeightMode"
                name="eventHeightMode"
                value={eventHeightMode}
                onChange={event => onChange(eventHeights[event.target.value])}
            >
                <FormControlLabel
                    value="XS"
                    control={<GreenRadio dataCy="my-pref-eventHeightMode-xs" />}
                    className="mb-2"
                    label="Mini"
                />
                <FormControlLabel
                    value="S"
                    control={<GreenRadio dataCy="my-pref-eventHeightMode-s" />}
                    className="mb-2"
                    label="Small"
                />
                <FormControlLabel
                    value="M"
                    control={<GreenRadio dataCy="my-pref-eventHeightMode-m" />}
                    className="mb-2"
                    label="Regular"
                />
                <FormControlLabel
                    value="L"
                    control={<GreenRadio dataCy="my-pref-eventHeightMode-l" />}
                    className="mb-2"
                    label="Wide"
                />
                <FormControlLabel
                    value="XL"
                    control={<GreenRadio dataCy="my-pref-eventHeightMode-xl" />}
                    className="mb-2"
                    label="Extra wide"
                />
            </RadioGroup>
        </FormControl>
    </Section>
);

BookingHeight.propTypes = {
    eventHeightMode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default BookingHeight;
