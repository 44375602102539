import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray } from 'lodash';
import { set } from 'date-fns';
import { addNotification } from 'actions/notificationActions';
import {
    createPhasesRequest,
    updatePhasesRequest,
    deletePhaseRequest,
    deleteFuturePhasesRequest,
    deleteAllPhasesRequest,
} from 'api/project';
import { formatPhasesToDP } from 'modules/scheduler/utils/eventUtil';
import { showRepeatModal } from 'actions/modalActions';
import {
    dispatchRefreshMilestonesPhases,
    removeDaypilotEvent,
    removeDaypilotEvents,
    updateDaypilotMilestonesPhasesDates,
} from 'modules/scheduler/utils/schedulerUtil';
import { formatDate, transformToDate } from 'utils/DateUtil';
import { BOOKING_FORMAT } from '../../../global/enums/dateFormat';
import { updateBookingSelection } from 'actions/schedulerActions';
import { selectCurrentSelectedBooking } from '../../../selectors/scheduler';

export const usePhases = (schedulerRef, onClose) => {
    const dispatch = useDispatch();
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);
    const callback = useCallback(
        phase => {
            const formattedPhases = formatPhasesToDP(isArray(phase) ? phase : [phase]);
            updateDaypilotMilestonesPhasesDates(formattedPhases, schedulerRef.current.control);
            onClose && onClose();
            schedulerRef.current.control.clearSelection();
        },
        [schedulerRef, onClose]
    );

    const preparePhaseDatesToRequest = useCallback(
        data => {
            if (Array.isArray(data)) {
                return data;
            }

            const start = formatDate(
                set(transformToDate(data.start), {
                    hours: companyStartEndTimes.startDay,
                    minutes: companyStartEndTimes.startMinute,
                    seconds: 0,
                }),
                BOOKING_FORMAT,
                false
            );

            const end = formatDate(
                set(transformToDate(data.end), {
                    hours: companyStartEndTimes.endDay,
                    minutes: companyStartEndTimes.endMinute,
                    seconds: 0,
                }),
                BOOKING_FORMAT,
                false
            );

            return {
                ...data,
                start,
                end,
            };
        },
        [
            companyStartEndTimes.endDay,
            companyStartEndTimes.endMinute,
            companyStartEndTimes.startDay,
            companyStartEndTimes.startMinute,
        ]
    );

    const createPhase = useCallback(
        data => {
            createPhasesRequest([preparePhaseDatesToRequest(data)])
                .then(phase => {
                    callback && callback(phase);
                })
                .catch(() => {
                    dispatch(
                        addNotification({
                            message: 'Something went wrong... We could not create the phase',
                            type: 'danger',
                        })
                    );
                });
        },
        [preparePhaseDatesToRequest, callback, dispatch]
    );

    const updatePhase = useCallback(
        (id, projectId, data) => {
            updatePhasesRequest(
                projectId,
                Array.isArray(data) ? data : { ...preparePhaseDatesToRequest(data), _id: id }
            )
                .then(phase => {
                    callback && callback(phase);
                    dispatch(updateBookingSelection.request());
                    dispatchRefreshMilestonesPhases();
                })
                .catch(() => {
                    dispatch(
                        addNotification({
                            message: 'Something went wrong... We could not update the phase',
                            type: 'danger',
                        })
                    );
                });
        },
        [preparePhaseDatesToRequest, callback, dispatch]
    );

    const deletePhase = useCallback(() => {
        const removePhases = eventIds => {
            onClose && onClose();
            removeDaypilotEvents(eventIds, schedulerRef.current.control);
        };
        if (currentSelectedBooking.repeat) {
            dispatch(
                showRepeatModal({
                    type: 'PHASE',
                    actions: {
                        deleteAll: () =>
                            deleteAllPhasesRequest(currentSelectedBooking.repeatId).then(response => {
                                removePhases(response);
                                dispatchRefreshMilestonesPhases();
                            }),
                        deleteFuture: () =>
                            deleteFuturePhasesRequest(
                                currentSelectedBooking.repeatId,
                                formatDate(currentSelectedBooking.start, 'yyyy-MM-dd', false)
                            ).then(response => {
                                removePhases(response);
                                dispatchRefreshMilestonesPhases();
                            }),
                        deleteOne: () =>
                            deletePhaseRequest(currentSelectedBooking.id).then(() => {
                                onClose && onClose();
                                removeDaypilotEvent(currentSelectedBooking.id);
                                dispatch(updateBookingSelection.request());
                                dispatchRefreshMilestonesPhases();
                            }),
                    },
                })
            );
            return;
        }

        deletePhaseRequest(currentSelectedBooking.id)
            .then(() => {
                onClose && onClose();
                removeDaypilotEvent(currentSelectedBooking.id);
                dispatch(updateBookingSelection.request());
                dispatchRefreshMilestonesPhases();
            })
            .catch(() => {
                dispatch(
                    addNotification({
                        message: 'Something went wrong... We could not delete the phase',
                        type: 'danger',
                    })
                );
            });
    }, [dispatch, currentSelectedBooking, schedulerRef, onClose]);

    return {
        createPhase,
        updatePhase,
        deletePhase,
    };
};
