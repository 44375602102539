import { values } from 'lodash';

export const ASCENDING = {
    value: 'ASCENDING',
    name: 'Ascending A-Z',
};

export const DESCENDING = {
    value: 'DESCENDING',
    name: 'Descending Z-A',
};

export const MANUAL = {
    value: 'MANUAL',
    name: 'Manual (Drag&Drop)',
};

const orderTypes = { ASCENDING, DESCENDING, MANUAL };

export const orderTypesArray = values(orderTypes);
