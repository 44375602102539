import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Container, Row, Col, Form, FormGroup } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { contains } from 'underscore';
import { Lock } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'services/queryString';
import { SAML_ONLY, REGULAR_AND_GOOGLE_ONLY, GOOGLE_ONLY } from 'enums/authEnum';
import Cookies from 'js-cookie';
import { login, clearAuthError } from 'actions/accountActions';
import { getDedicatedLoginParams } from 'api/account';
import { validateSchema } from 'utils/schemaUtil';
import { getSubdomain, getUrl } from 'utils/browserUtil';
import { formSchema } from './formSchema';
import { renderCheckbox, renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import SSOError from '../../shared/ssoError';
import Error from '../../shared/error';
import { SubmitButton } from '../../shared/buttons';
import { SSOLink, SSOLinkHref, SSOGoogleHref } from 'modules/auth/shared/buttons/styles';
import { OTPVerificationModalContent } from '../otpVerificationModalContent';
import { MFACompanyReminderContent } from '../mfaCompanyReminderContent';
import { selectAccountMFA } from 'selectors/account';

const Login = ({ isAuthenticated, handleSubmit, location }) => {
    const dispatch = useDispatch();
    const [loginParams, setLoginParams] = useState({ authMode: '', url: '' });
    const [isLoading, setLoading] = useState(false);
    const [values, setValues] = useState();
    const authError = useSelector(state => state.account.authError);
    const shouldValidateOTP = useSelector(state => state.account.shouldValidateOTP);
    const { shouldShowCompanyReminder, errorCode } = useSelector(selectAccountMFA);

    const { subdomain: querySubdomain, failed, authChanged, redirectUrl } = parse(location.search);

    useEffect(() => {
        if (isAuthenticated) {
            return;
        }
        dispatch(clearAuthError());
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        if (!isAuthenticated) {
            const subdomain = getSubdomain() || querySubdomain;
            if (subdomain) {
                setLoading(true);
                getDedicatedLoginParams(subdomain)
                    .then(data => {
                        setLoginParams(data);
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoginParams({ authMode: '', url: '' });
                        setLoading(false);
                    });
            }
        }

        dispatch(clearAuthError());
    }, [dispatch, querySubdomain, isAuthenticated]);

    if (isAuthenticated && !shouldShowCompanyReminder) {
        return <Redirect to="/" />;
    }

    const setCookie = () => {
        if (redirectUrl) {
            Cookies.set('redirect_url', redirectUrl);
        }
    };

    const submit = formData => {
        const { email, password, remember, otp, recoveryCode } = formData;

        if (!otp && !recoveryCode) {
            setValues(formData);
        }

        dispatch(clearAuthError());
        setCookie();
        dispatch(login.request({ userName: email, password, remember, otp, recoveryCode }));
    };

    const submitWithOTP = verificationData => {
        submit({ ...values, ...verificationData });
    };

    if (isLoading) {
        return null;
    }

    if (shouldValidateOTP) {
        return (
            <PageTemplate type="login">
                <OTPVerificationModalContent
                    error={authError}
                    errorCode={errorCode}
                    onSubmit={submitWithOTP}
                    actionType="LOGIN"
                    email={values?.email}
                />
            </PageTemplate>
        );
    }

    if (shouldShowCompanyReminder) {
        return (
            <PageTemplate type="login" noLogo>
                <MFACompanyReminderContent />
            </PageTemplate>
        );
    }

    return (
        <PageTemplate type="login">
            {authError && <Error message={authError} />}
            {failed && <Error message="You are not able to login." />}
            {authChanged && (
                <Error message="Your Account Owner changed the authentication configuration and you need to login again." />
            )}
            <SSOError />
            {(!loginParams.authMode || REGULAR_AND_GOOGLE_ONLY === loginParams.authMode) && (
                <Form onSubmit={handleSubmit(submit)}>
                    <FormGroup className="mb-3">
                        <Field
                            component={renderTextField}
                            label="Email Address"
                            name="email"
                            autoFocus={true}
                            variant="outlined"
                            autoComplete="email"
                            fullWidth
                        />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Field
                            component={renderTextField}
                            name="password"
                            label="Password"
                            type="password"
                            variant="outlined"
                            autoComplete="current-password"
                            fullWidth
                        />
                    </FormGroup>
                    <div className="mb-3 pl-2">
                        <Field component={renderCheckbox} name="remember" label="Remember me" color="primary" />
                    </div>

                    <SubmitButton type="submit" className="btn-block mb-2 shadow-sm border-0 py-2">
                        Sign in
                    </SubmitButton>

                    <Container className="mt-3">
                        <Row className="d-flex">
                            <Col xs="6" className="pl-3">
                                <Link to="/forgot-password">Forgot password?</Link>
                            </Col>
                            <Col xs="6" className="pr-3 text-right">
                                <Link to="/signup">
                                    {"Don't have an account? "}
                                    <span className="text-nowrap">Sign Up</span>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <hr className="my-4" />
                </Form>
            )}
            <div className="d-flex justify-content-center">
                {(!loginParams.authMode || contains([REGULAR_AND_GOOGLE_ONLY, GOOGLE_ONLY], loginParams.authMode)) && (
                    <div className="w-50 pl-1">
                        <SSOGoogleHref
                            href={loginParams.url || `${getUrl('app', 8090)}/sso/login-google`}
                            className="shadow-sm"
                            onClick={setCookie}
                        >
                            Sign in with Google
                        </SSOGoogleHref>
                    </div>
                )}
                {(!loginParams.authMode || (loginParams.authMode && SAML_ONLY === loginParams.authMode)) && (
                    <div className="w-50 pl-1">
                        {!loginParams.authMode ? (
                            <SSOLink to="/sso-login" className="shadow-sm" onClick={setCookie}>
                                <Lock className="mr-1" /> Sign in with SSO
                            </SSOLink>
                        ) : (
                            <SSOLinkHref href={loginParams.url} className="shadow-sm" onClick={setCookie}>
                                <Lock className="mr-1" />
                                Sign in with SSO
                            </SSOLinkHref>
                        )}
                    </div>
                )}
            </div>
        </PageTemplate>
    );
};

Login.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'loginForm',
    validate: validateSchema(formSchema),
})(Login);
