import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FormText } from 'reactstrap';
import { Field } from 'redux-form';
import { chooseField, materialInputGroupField, dropdownFieldMaterial } from 'shared/formFields';
import { useForm } from 'hooks';
import { Layout, Typography } from '@hub-mono/ui';

export const intervalOptions = [
    {
        name: 'None',
        value: 'NONE',
    },
    {
        name: 'Daily',
        value: 'DAILY',
    },
    {
        name: 'Weekly',
        value: 'WEEKLY',
    },
    {
        name: 'Monthly',
        value: 'MONTHLY',
    },
];

const normalize = value => parseInt(value);

const Repeat = props => {
    const {
        title,
        formText,
        radioInputName,
        intervalAsDropdown,
        textInputName,
        wrapperClass,
        inputFieldDisabled,
        removeBorder,
        inline,
        formName,
        onIntervalChange,
    } = props;
    const numberOfRepeatsInputRef = useRef(null);

    const updateFields = useForm(formName || '');

    const onChange = useCallback(
        event => {
            if (onIntervalChange) {
                onIntervalChange(event.target.value);
            } else {
                const interval = event?.target?.value || event;
                if (
                    (interval == null ||
                        interval === intervalOptions[0].value ||
                        interval?.value === intervalOptions[0].value) &&
                    formName
                ) {
                    typeof updateFields === 'function' &&
                        updateFields({
                            repeatTimes: 0,
                        });
                }
            }
        },
        [formName, onIntervalChange, updateFields]
    );

    return (
        <section
            className={classnames(wrapperClass, {
                'border-bottom-0': removeBorder,
                'd-flex flex-column w-100 mt-1': !inline,
            })}
        >
            {title && (
                <Layout pb="--Spacing-100">
                    <Typography variant="label" size="large" prominent>
                        {title}
                    </Typography>
                </Layout>
            )}
            {intervalAsDropdown && (
                <Field
                    label="Interval"
                    data={intervalOptions}
                    filter={false}
                    dropUp
                    valueField="value"
                    textField="name"
                    name={radioInputName}
                    variant="outlined"
                    component={dropdownFieldMaterial}
                    onChange={onChange}
                />
            )}
            {!intervalAsDropdown &&
                intervalOptions.map(interval => (
                    <Field
                        key={interval.value}
                        type="radio"
                        inline={inline}
                        label={interval.name}
                        name={radioInputName}
                        value={interval.value}
                        onChange={onChange}
                        component={chooseField}
                        wrapperClass={classnames({ 'mb-2': !inline })}
                    />
                ))}

            <Field
                className="mt-4"
                name={textInputName}
                disabled={inputFieldDisabled}
                type="number"
                normalize={normalize}
                label="Number of repeat times"
                ref={numberOfRepeatsInputRef}
                forwardRef
                component={materialInputGroupField}
                variant="outlined"
            />
            {formText.length ? <FormText>{formText}</FormText> : null}
        </section>
    );
};

Repeat.propTypes = {
    title: PropTypes.string,
    formText: PropTypes.string,
    radioInputName: PropTypes.string,
    textInputName: PropTypes.string,
    wrapperClass: PropTypes.string,
    inputFieldDisabled: PropTypes.bool,
    inline: PropTypes.bool,
    removeBorder: PropTypes.bool,
    intervalAsDropdown: PropTypes.bool,
};

Repeat.defaultProps = {
    radioInputName: 'interval',
    textInputName: 'repeatTimes',
    intervalAsDropdown: false,
    title: '',
    inline: true,
    formText: '',
    wrapperClass: '',
    inputFieldDisabled: false,
    removeBorder: false,
};

export default Repeat;
