import { useMemo, useCallback, useState } from 'react';

/**
 * A mechanism that helps to maintain only one toggler opened at time.
 * @param {Array} togglersIds
 * @returns
 */
export const useUniqueToggler = togglersIds => {
    const [toggleOpen, setToggleOpen] = useState(null);

    const onToggleOpen = useCallback(toggleId => {
        setToggleOpen(toggleId);
    }, []);

    return useMemo(
        () =>
            (togglersIds || []).reduce(
                (acc, cv) => {
                    return {
                        ...acc,
                        [`${cv}CloseToggler`]: toggleOpen !== cv,
                    };
                },
                {
                    toggleOpen,
                    onToggleOpen,
                }
            ),
        [onToggleOpen, toggleOpen, togglersIds]
    );
};

export default useUniqueToggler;
