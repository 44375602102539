import * as actionTypes from './actionTypes';
import * as modalTypes from 'enums/modalTypeEnum';

export const showApproveOrRejectModal = (onSubmit, type) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.APPROVE_REJECT_MODAL,
    payload: {
        onSubmit,
        type,
    },
});

export const hideModal = modalType => ({
    type: actionTypes.HIDE_MODAL,
    modalType,
});

export const showRequestVacationModal = ({ onSubmit, booking, onClose }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REQUEST_VACATIONS_MODAL,
    payload: {
        onSubmit,
        booking,
        onClose,
    },
});

export const showRequestResourceModal = ({ onSubmit, onClose }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REQUEST_RESOURCE_MODAL,
    payload: {
        onSubmit,
        onClose,
    },
});

export const showEditRequestResourceModal = (onSubmit, booking) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EDIT_REQUEST_RESOURCE_MODAL,
    payload: {
        onSubmit,
        booking,
    },
});

export const showResourceModal = ({ onSubmit, onClose, type, resourceId }) => {
    return {
        type: actionTypes.SHOW_MODAL,
        modalType: modalTypes.RESOURCE_MODAL,
        payload: {
            onSubmit,
            type,
            resourceId,
            onClose,
        },
    };
};

export const showScheduleResourceModal = ({ onSubmit, activeTab, bookingId, onDelete, onClose }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.SCHEDULE_RESOURCE_MODAL,
    payload: {
        onSubmit,
        activeTab,
        bookingId,
        onDelete,
        onClose,
    },
});

export const showProjectModal = ({ onSubmit, type, onClose, projectId, initialData = {} } = {}) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.PROJECT_MODAL,
    payload: {
        onSubmit,
        type,
        projectId,
        initialData,
        onClose,
    },
});

export const showReportBugModal = onSubmit => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REPORT_BUG_MODAL,
    payload: {
        onSubmit,
    },
});

export const showContactModal = onSubmit => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CONTACT_MODAL,
    payload: {
        onSubmit,
    },
});

export const showReportModal = (reportType, subType, groupBy, activeTab, itemType, initState) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REPORT_MODAL,
    payload: {
        reportType,
        subType,
        groupBy,
        activeTab,
        itemType,
        initState,
    },
});

export const showSuggestReportModal = onSubmit => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.SUGGEST_REPORT_MODAL,
    payload: {
        onSubmit,
    },
});

export const showHolidayModal = (holiday, onSave) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.HOLIDAY_MODAL,
    payload: {
        holiday,
        onSave,
    },
});

export const showCalendarsModal = (onSave, addCalendarFocus, activeCalendarId) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CALENDARS_MODAL,
    payload: {
        onSave,
        addCalendarFocus,
        activeCalendarId,
    },
});

export const showManagePoliciesModal = ({ initialPolicy }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MANAGE_POLICIES_MODAL,
    payload: { initialPolicy },
});

export const showNewPolicyModal = ({ onSubmit, onReject }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.NEW_POLICY_MODAL,
    payload: { onSubmit, onReject },
});

export const showEditPolicyNameModal = ({ onSubmit, onReject, policy }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EDIT_POLICY_NAME_MODAL,
    payload: { onSubmit, onReject, policy },
});

export const showImportHolidaysModal = (onImport, activeCalendarId) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.IMPORT_HOLIDAYS_MODAL,
    payload: { onImport, activeCalendarId },
});

export const showICalFeedSubscribeModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.ICALL_FEED_SUBSCRIBE_MODAL,
});

export const showBillingModal = billingPlan => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.BILLING_MODAL,
    payload: {
        billingPlan,
    },
});

export const showOrderGroupModal = type => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.ORDER_GROUP_MODAL,
    payload: {
        type,
    },
});

export const showSearchModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.SEARCH_MODAL,
});

export const showBulkAddModal = type => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.BULK_ADD_MODAL,
    payload: {
        type,
    },
});

export const showEditSubscriptionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EDIT_SUBSCRIPTION_MODAL,
});

export const showCardDetailsModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CARD_DETAILS_MODAL,
});

export const showDisabledAccountModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.DISABLED_ACCOUNT_MODAL,
});

export const showPermissionsModal = (type, item, onSave) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.PERMISSIONS_MODAL,
    payload: {
        type,
        item,
        onSave,
    },
});

export const showCategoryGroupsModal = (addCategoryGroupFocus, activeCategoryGroupId) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CATEGORY_GROUPS_MODAL,
    payload: {
        addCategoryGroupFocus,
        activeCategoryGroupId,
    },
});

export const showCategoryTemplateModal = (category, onSave) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CATEGORY_TEMPLATE_MODAL,
    payload: {
        category,
        onSave,
    },
});

export const showFixedCostCategoryTemplateModal = (costCategory, onSave) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.FIXED_COST_CATEGORY_TEMPLATE_MODAL,
    payload: {
        costCategory,
        onSave,
    },
});

export const showEditBookingTitleModal = (onSubmit, currentTitle) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EDIT_BOOKING_TITLE_MODAL,
    payload: {
        onSubmit,
        currentTitle,
    },
});

export const showInfoModal = (title, description) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.INFO_MODAL,
    payload: {
        title,
        description,
    },
});

export const showExportModal = (viewPortDates, schedulerUnassignedResourceIds) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EXPORT_MODAL,
    payload: {
        viewPortDates,
        schedulerUnassignedResourceIds,
    },
});

export const showConfirmationModal = (
    onConfirm,
    title,
    message,
    {
        withCancel,
        onReject,
        confirmButtonText,
        confirmButtonDataCy,
        cancelButtonText,
        withEscButton,
        cancelActionOnHide,
        additionalButtons,
    } = {}
) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CONFIRMATION_MODAL,
    payload: {
        onConfirm,
        title,
        message,
        additional: {
            withCancel,
            onReject,
            confirmButtonText,
            confirmButtonDataCy,
            cancelButtonText,
            withEscButton,
            cancelActionOnHide,
            additionalButtons,
        },
    },
});

export const showBulkMoveModal = (schedulerRef, viewObject) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.BULK_MOVE_MODAL,
    payload: {
        schedulerRef,
        viewObject,
    },
});

export const showColumnResizeModal = (column, schedulerRef) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.COLUMN_RESIZE_MODAL,
    payload: {
        column,
        schedulerRef,
    },
});

/**
 * @param {string} itemTypeName data source which should be loaded on select data tab
 * @param {string} itemId Is representing id of group, resource, project or template
 * @param {string} groupType Is the itemId a group, item(project, resource) or template
 * @param {string} parentGroupId
 * @param {boolean} isFilterContext When modal is open from filters in header
 *
 * @returns {{modalType: string, payload: {itemTypeName, activeTab, itemId, itemIdType, parentGroupId}, type: string}}
 */
export const showSchedulerBuilderModal = options => {
    const {
        itemTypeName,
        itemId = null,
        groupType = null,
        parentGroupId,
        isFilterContext = false,
        initialTab = null,
        isEditView,
    } = options;
    return {
        type: actionTypes.SHOW_MODAL,
        modalType: modalTypes.SCHEDULER_BUILDER_MODAL,
        payload: {
            itemTypeName,
            itemId,
            groupType,
            parentGroupId,
            isFilterContext,
            initialTab,
            isEditView,
        },
    };
};

export const hideModalType = modalType => ({
    type: actionTypes.HIDE_MODAL_TYPE,
    payload: {
        modalType,
    },
});

export const showRepeatModal = ({ type, actions, booking }) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REPEAT_MODAL,
    payload: {
        type,
        actions,
        booking,
    },
});

export const showEditRepeatModal = (type, actions, { bookings } = {}) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.EDIT_REPEAT_MODAL,
    payload: {
        type,
        actions,
        bookings,
    },
});

export const showPasteHourModal = (actions, numberOfBookings) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.CREATE_PASTED_MODAL,
    payload: {
        actions,
        numberOfBookings,
    },
});

export const showMissingSmartSchedulesExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_SMART_SCHEDULES_MODAL,
});

export const showMissingMFAExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_MFA_MODAL,
});

export const showMissingSmartFiltersExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_SMART_FILTERS_MODAL,
});

export const showMissingBookingCategoriesExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_BOOKING_CATEGORIES_MODAL,
});

export const showMissingFixedCostsCategoriesExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_FIXED_COSTS_CATEGORIES_MODAL,
});

export const showMissingDependenciesExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_DEPENDENCIES_MODAL,
});

export const showMissingToilExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_TOIL_EXTENSION_MODAL,
});

export const showMissingDeadlinesExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_DEADLINES_MODAL,
});

export const showMissingUnassignedExtensionModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MISSING_UNASSIGNED_MODAL,
});

export const showReportsGroupModal = data => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.REPORTS_GROUP_MODAL,
    payload: data,
});

export const showMFARecoveryCodesModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MFA_RECOVERY_CODES_MODAL,
});

export const showMFASetupModal = (onSuccess, type) => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MFA_SETUP_MODAL,
    payload: {
        onSuccess,
        type,
    },
});

export const showOTPVerificationModal = action => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.OTP_VERIFICATION_MODAL,
    payload: { action },
});

export const showUserMFAReminderModal = type => ({
    type: actionTypes.SHOW_MODAL,
    modalType: type === 'SOFT' ? modalTypes.MFA_USER_REMINDER_SOFT_MODAL : modalTypes.MFA_USER_REMINDER_HARD_MODAL,
});

export const showCompanyOwnerMFAReminderModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.MFA_COMPANY_OWNER_REMINDER_MODAL,
});

export const showLearnMoreAboutToilModal = () => ({
    type: actionTypes.SHOW_MODAL,
    modalType: modalTypes.LEAR_MORE_ABOUT_TOIL_MODAL,
});
