import React from 'react';
import { DayPilot } from 'daypilot-pro-react';
import { getTimeHeaders } from 'modules/scheduler/utils/schedulerUtil';
import { LEGACY } from 'modules/scheduler/enums/viewModeEnum';
import { findByValue, scales } from 'modules/scheduler/enums/scale';
import EventBubble from 'modules/scheduler/components/scheduler/eventBubble';
import ProjectDatesBubble from 'modules/scheduler/components/scheduler/projectDatesBubble';
import MilestoneBubble from 'modules/scheduler/components/scheduler/milestoneBubble';
import PhaseBubble from 'modules/scheduler/components/scheduler/phaseBubble';
import { onResourceCollapse } from 'modules/scheduler/config/events';
import { renderToString } from 'react-dom/server';
import { store } from '../../../store';
import { selectIsDeadlinesExtensionInstalled, selectIsDependencyExtensionInstalled } from '../../../selectors/company';
import { getTotalTime } from './events/onBeforeEventRender/booking';
import { DeadlineBubble } from '../components/scheduler/deadlineBubble';

const DEFAULT_BUBBLE_TIMEOUT = 2000;
const SEPARATORS = [{ color: '#b8deff', location: new DayPilot.Date(), width: 1 }];
// https://api.daypilot.org/daypilot-bubble-class/
const bubble = new DayPilot.Bubble();

const onBeforeGroupRender = args => {
    args.group.html = '<i class="fa fa-plus">&nbsp;</i> ' + args.group.count + ' Overlapping Bookings';
};

export const getBubble = accountGridPreferences => {
    if ('DISABLED' === accountGridPreferences.bookingToolTipHoverDelay) {
        return null;
    }

    const state = store.getState();
    const isDependencyExtensionInstalled = selectIsDependencyExtensionInstalled(state);
    const isDeadlinesExtensionInstalled = selectIsDeadlinesExtensionInstalled(state);

    const timeout =
        'INSTANT' === accountGridPreferences.bookingToolTipHoverDelay
            ? 0
            : parseInt(accountGridPreferences.bookingToolTipHoverDelay);
    bubble.animated = false;
    bubble.showAfter = accountGridPreferences.hasOwnProperty('bookingToolTipHoverDelay')
        ? timeout
        : DEFAULT_BUBBLE_TIMEOUT;
    bubble.hideAfter = 100;
    bubble.theme = 'hub-tooltip';
    bubble.hideOnClick = false;

    bubble.onLoad = args => {
        const bubbleTags = args.source.data;

        bubbleTags.keyWords = bubbleTags.keyWords || args.source.cache?.bubbleTags?.keyWords || {};
        bubbleTags.loggedInRights = {
            ...bubbleTags.loggedInRights,
            ...(args.source.cache?.bubbleTags?.loggedInRights || {}),
        };

        // Project dates
        if (bubbleTags?.datesEvent) {
            args.html = renderToString(<ProjectDatesBubble {...bubbleTags} />);
        }
        // Milestone
        else if (bubbleTags?.milestone) {
            args.html = renderToString(<MilestoneBubble {...bubbleTags} />);
        }
        // Phase
        else if (bubbleTags?.phase) {
            args.html = renderToString(<PhaseBubble {...bubbleTags} />);
        } else if (bubbleTags?.deadline) {
            args.html = renderToString(<DeadlineBubble {...bubbleTags} />);
        }
        // Event
        else {
            bubbleTags.totalEventTime = getTotalTime(bubbleTags);
            args.html = renderToString(
                <EventBubble
                    {...bubbleTags}
                    isDependencyExtensionInstalled={isDependencyExtensionInstalled}
                    isDeadlinesExtensionInstalled={isDeadlinesExtensionInstalled}
                />
            );
        }
    };

    return bubble;
};

export default (
    accountGridPreferences,
    schedulerColumns,
    companySettings,
    viewObject,
    { slotMinutes, startMinute, endMinute, meanWorkDayDuration, ...rest } = {}
) => {
    const currentScale = accountGridPreferences.defaultScale ? accountGridPreferences.defaultScale : 'Day';
    const scale = findByValue(currentScale);

    const eventMoveSkipNonBusiness =
        scale && [scales.WEEK.value, scales.MONTH.value].includes(scale.value)
            ? false
            : accountGridPreferences.eventSmartResize || companySettings.grid.hideNonWorkingDays;

    const shouldUseAvailabilityDisplay =
        (viewObject.isResourceView || (viewObject.isProjectView && accountGridPreferences.mode === LEGACY.value)) &&
        ['TYPE_NOTIFICATION', 'TYPE_AVAILABILITY'].includes(accountGridPreferences.availabilityDisplay);

    const configToReturn = {
        theme: 'hub',
        bubble: getBubble(accountGridPreferences),
        startDate: scale.startDate(companySettings.weekStartDay),
        days: scale.daysByMultiplier(accountGridPreferences.cellWidth[currentScale], meanWorkDayDuration),
        scale: currentScale,
        schedulerColumns,
        mode: accountGridPreferences.mode,
        displayMilestoneText: accountGridPreferences.displayMilestoneText,
        eventHeightMode: accountGridPreferences.eventHeightMode,
        displayWeeks: accountGridPreferences.displayWeeks,
        availabilityDisplay: accountGridPreferences.availabilityDisplay,
        availabilityHover: accountGridPreferences.availabilityHover,
        companyStartEndTimes: {
            startMinute,
            endMinute,
            ...rest,
        },
        timeHeaders: getTimeHeaders(scale, accountGridPreferences.displayWeeks, {
            slotMinutes,
            startMinute,
            endMinute,
        }),
        infiniteScrollingEnabled: true,
        infiniteScrollingMargin: 300,
        infiniteScrollingStepDays: scale.scrollingStepDays(
            accountGridPreferences.cellWidth[currentScale],
            meanWorkDayDuration
        ),
        rowHeaderWidthAutoFit: false,
        loadingLabelVisible: false,
        beforeCellRenderCaching: true,
        // autoRefreshEnabled: false,
        // autoRefreshInterval: 20,
        // autoRefreshMaxCount: 100,
        eventHeight: accountGridPreferences.eventHeight,
        linkBottomMargin: accountGridPreferences.eventHeight / 2,
        rowMinHeight: accountGridPreferences.eventHeight + (shouldUseAvailabilityDisplay ? 4 : 14),
        headerHeight: 36,
        rowMarginTop:
            (viewObject.isProjectView && accountGridPreferences.mode !== LEGACY.value) || !shouldUseAvailabilityDisplay
                ? 0
                : 20,
        rowMarginBottom: 10,
        cellWidth: accountGridPreferences.cellWidth[currentScale],
        eventClickHandling: 'Select',
        cellDuration: 30 === slotMinutes || 30 === startMinute || 30 === endMinute ? 30 : 60,
        eventEndSpec: 'DateTime',
        weekStarts: companySettings.weekStartDay,
        groupConcurrentEvents: accountGridPreferences.groupBookings,
        groupConcurrentEventsLimit: accountGridPreferences.groupBookingsLimit,
        treeEnabled: true,
        treeIndent: 0,
        treeAnimation: false,
        treeAutoExpand: false,
        treeImageMarginTop: 0,
        treePreventParentUsage: false,
        width: '100%',
        heightSpec: 'Max',
        allowMultiMove: true,
        multiMoveVerticalMode: 'Master',
        height: window.innerHeight - 287 - (accountGridPreferences.filterVisible ? 48 : 0),
        durationBarVisible: true,
        drawBlankCells: true,
        eventMovingStartEndEnabled: true,
        eventMovingStartEndFormat: 'MMMM d, yyyy',
        eventResizingStartEndEnabled: true,
        timeRangeSelectingStartEndEnabled: true,
        progressiveRowRendering: true,
        progressiveRowRenderingPreload: 25,
        dynamicLoading: true,
        dynamicEventRendering: 'Progressive',
        dynamicEventRenderingCacheSweeping: true,
        dynamicEventRenderingCacheSize: 3000,
        scrollDelayDynamic: 150,
        scrollDelayCells: 5,
        scrollDelayFloats: 5,
        scrollDelayRows: 0,
        scrollDelayEvents: 0,
        sortDirections: ['asc', 'asc', 'desc', 'asc', 'asc', 'asc', 'asc'],
        eventResizeHandling: 'JavaScript',
        eventDoubleClickHandling: 'Enabled',
        timeRangeDoubleClickHandling: 'Disabled',
        eventMoveHandling: 'Update',
        allowMultiResize: false,
        separators: SEPARATORS,
        eventMoveSkipNonBusiness,
        eventUpdateInplaceOptimization: false,
        onResourceCollapse,
        onBeforeGroupRender,
        rowHeaderColumnsMergeParents: false,
        linkCreateHandling: 'Update',
    };

    return configToReturn;
};
