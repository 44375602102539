import { useEffect } from 'react';
import { checkIsSelectionEmpty, selectTimeRange } from '../../../../../../utils/schedulerSelectTimeRangeUtil';

const useKeepTimeRangeSelectedClassicMenu = (schedulerRef, currentSelection, projectsLength) => {
    const isSelectionEmpty = checkIsSelectionEmpty(schedulerRef);
    const { resource: currentResource, project: currentProject } = currentSelection.rowTags || {};

    useEffect(() => {
        const { start, end, rowTags } = currentSelection;
        const currentSelectionExists = start && end && rowTags;
        if (currentSelectionExists) {
            let resId = (currentResource || currentProject || {})._id;
            if (resId) {
                resId = rowTags.isEventRow ? `eventsRow_${resId}` : resId;
                selectTimeRange(schedulerRef, start, end, resId);
            }
        }
    }, [
        currentSelection,
        schedulerRef,
        currentResource,
        currentProject,
        /* "projectsLength" and "isSelectionEmpty" are not used in hook, but they are required in dependencies to keep time range selection update on the first projects load (for events and resources classic menu) */
        projectsLength,
        isSelectionEmpty,
    ]);
};

export default useKeepTimeRangeSelectedClassicMenu;
