import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { initializeV2, initWithGroup, initWithoutGroup, preSetData } from './actions';
import { findItemTypeByName, PROJECT, RESOURCE } from '../../../enums/builderTypeEnum';
import { getResourceGroup } from '../../../../../api/resourceGroup';
import { getProjectGroup } from '../../../../../api/projectGroup';
import * as groupTypes from '../../../../../enums/groupTypeEnum';
import {
    getCompanyExtensions,
    getCompanySystemGroupIds,
    getDisplayArchivedInScheduler,
    makeGetKeyWords,
    selectProjectStatusesKeywords,
} from '../../../../../selectors/company';
import { isActive } from '../../../../../utils/extensionUtil';
import { SMART_GROUPS } from '../../../../../enums/extensionShortIdEnum';
import { makeGetFilteredResources, resourcesLoaded } from '../../../../../selectors/resource';
import { makeGetFilteredProjects, projectsLoaded } from '../../../../../selectors/project';
import { isResourceGroupsLoaded, makeGetResourceGroups } from '../../../../../selectors/resourceGroup';
import { isProjectGroupsLoaded, makeGetProjectGroups } from '../../../../../selectors/projectGroup';
import { filterFieldsByType, getCustomFields } from '../../../../../selectors/customField';
import {
    INITIAL_FILTER_RELATION,
    INITIAL_FILTERS,
    INITIAL_TITLE,
    NEW_GROUP_INITIAL_TITLE,
    NEW_SCHEDULE_INITIAL_TITLE,
} from '../consts';
import * as tabs from '../tabs';
import * as projectGroupActions from '../../../../../actions/projectGroupActions';
import * as projectActions from '../../../../../actions/projectActions';
import * as resourceGroupActions from '../../../../../actions/resourceGroupActions';
import * as resourceActions from '../../../../../actions/resourceActions';
import { waitFor } from '../../../../../sagas/helpers/waitFor';
import { schedulerSelector, selectSchedulerFilters } from '../../../../../selectors/scheduler';
import { getQueryFiltersFromQueryParams, mapCustomFieldsToFilters } from '../../../utils/builderFiltersUtil';
import { addNotification } from '../../../../../actions/notificationActions';
import { SYSTEM } from '../../../enums/groupTypeEnum';
import { replaceKeywords, replaceMap } from '../../../../../utils/keywordsUtil';

function* init(action) {
    const { itemTypeName, groupId, groupType, isFilterContext, initialTab, parentGroupId } = action.payload;
    const itemType = findItemTypeByName(itemTypeName);

    const extensions = yield select(getCompanyExtensions);
    const schedulerState = yield select(schedulerSelector);
    const companySystemGroupIds = yield select(getCompanySystemGroupIds);
    const displayArchivedInScheduler = yield select(getDisplayArchivedInScheduler);
    const schedulerFilters = yield select(selectSchedulerFilters);
    const isSmartGroupInstalled = isActive(extensions, SMART_GROUPS);
    const projectStatusesKeywords = yield select(selectProjectStatusesKeywords);
    const appKeyWords = yield select(makeGetKeyWords());

    if (isFilterContext) {
        yield put(
            preSetData({
                filters: schedulerFilters,
                initialTab: initialTab ?? tabs.TYPE_TAB,
                itemType,
                parentGroupId,
            })
        );
    } else {
        yield put(
            preSetData({
                filters: INITIAL_FILTERS,
                initialTab: initialTab ?? tabs.TYPE_TAB,
                itemType,
                parentGroupId,
            })
        );
    }

    let items, groups;
    if (itemType.name === 'resource') {
        yield all([put(resourceGroupActions.getResourceGroups.request()), put(resourceActions.getResources.request())]);
        yield waitFor(state => isResourceGroupsLoaded(state) && resourcesLoaded(state));
        groups = yield select(makeGetResourceGroups('makeGetResourceGroups'));
        items = yield select(
            makeGetFilteredResources(!displayArchivedInScheduler && 'EXCLUDE_PARKED_NON_BOOKABLE_ARCHIVED')
        );
    } else if (itemType.name === 'project') {
        yield all([put(projectGroupActions.getProjectGroups.request()), put(projectActions.getProjects.request())]);
        yield waitFor(state => isProjectGroupsLoaded(state) && projectsLoaded(state));
        groups = yield select(makeGetProjectGroups('makeGetResourceGroups'));
        items = yield select(makeGetFilteredProjects(!displayArchivedInScheduler && 'EXCLUDE_ARCHIVED'));
    }

    const mapGroup = group => {
        let newName = group.name;

        if (group.groupType === SYSTEM && group?.queryParams?.itemType === PROJECT.name) {
            newName = replaceKeywords(
                replaceMap({
                    ...appKeyWords,
                    ...projectStatusesKeywords,
                }),
                group.name || ''
            );
        }

        return {
            ...group,
            isSmart: isSmartGroupInstalled && group.isSmart,
            name: newName,
        };
    };

    if (!groupId || groupType === groupTypes.ITEM) {
        const initialGroups = groups.filter(group => {
            return (
                group._id ===
                (itemType.name === RESOURCE.name
                    ? companySystemGroupIds.activeResourceGroupId
                    : companySystemGroupIds.activeProjectGroupId)
            );
        });

        yield put(
            initWithoutGroup({
                itemType,
                filterRelation: INITIAL_FILTER_RELATION,
                isSmart: isSmartGroupInstalled,
                groupType: groupType === groupTypes.ITEM ? groupTypes.SCHEDULER_TEMPLATE : groupType,
                initialGroups,
                selectedGroups: initialGroups,
                title:
                    groupType === groupTypes.GROUP
                        ? NEW_GROUP_INITIAL_TITLE
                        : groupType === groupTypes.SCHEDULER_TEMPLATE
                        ? NEW_SCHEDULE_INITIAL_TITLE
                        : INITIAL_TITLE,
                useSchedulerAppliedFilters: isFilterContext,
                schedulerFilters,
            })
        );
        return;
    }

    const requestPromise = itemTypeName === RESOURCE.name ? getResourceGroup : getProjectGroup;

    let group;

    try {
        group = yield call(requestPromise, groupId);
    } catch (error) {
        yield put(addNotification({ message: "Resource Group couldn't be loaded", type: 'danger' }));
    }

    if (!group) {
        return;
    }

    const customFields = yield select(getCustomFields);
    const convertedGroupFilters = getQueryFiltersFromQueryParams({ queryParams: group.queryParams });
    const filteredResourceCustomFields = filterFieldsByType(customFields, 'CHOICES', `resourceCustomFields`);
    const filteredProjectCustomFields = filterFieldsByType(customFields, 'CHOICES', `projectCustomFields`);

    const groupConvertedResourceCustomFields = convertedGroupFilters.resourceCustomFields;
    const groupConvertedProjectCustomFields = convertedGroupFilters.projectCustomFields;

    const groupResourceCustomFieldFilters = mapCustomFieldsToFilters(
        groupConvertedResourceCustomFields?.filters,
        filteredResourceCustomFields
    );

    const groupProjectCustomFieldFilters = mapCustomFieldsToFilters(
        groupConvertedProjectCustomFields?.filters,
        filteredProjectCustomFields
    );

    const groupFilters = {
        ...INITIAL_FILTERS,
        ...convertedGroupFilters,
        resourceCustomFields: {
            ...(convertedGroupFilters.resourceCustomFields || { operator: INITIAL_FILTER_RELATION }),
            filters: groupResourceCustomFieldFilters,
        },
        projectCustomFields: {
            ...(convertedGroupFilters.projectCustomFields || { operator: INITIAL_FILTER_RELATION }),
            filters: groupProjectCustomFieldFilters,
        },
    };

    yield put(
        initWithGroup({
            group: mapGroup(group),
            filterRelation: schedulerState.filterRelation,
            useSchedulerAppliedFilters: isFilterContext,
            schedulerFilters,
            groupFilters,
            items,
            groups,
            itemType,
            groupType,
        })
    );

    yield put(initializeV2.success());
}

export default function* builderWatcher() {
    yield takeLatest(actionTypes.INITIALIZE_V2['REQUEST'], init);
}
