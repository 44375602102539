import * as actionTypes from './actionTypes';
import * as contextMenuTypes from 'modules/scheduler/enums/contextMenuEnum';

export const showGroupContextMenu = (group, actions) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.GROUP_CONTEXT_MENU,
    payload: {
        group,
        actions,
    },
});

export const showItemContextMenu = (groupType, group, item, actions, type) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.ITEM_CONTEXT_MENU,
    payload: {
        item,
        groupType,
        group,
        actions,
        type,
    },
});

export const hideContextMenu = contextMenuType => ({
    type: actionTypes.HIDE_CONTEXT_MENU,
    contextMenuType,
});

export const showAddNewItemContextMenu = type => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.ADD_NEW_ITEM_CONTEXT_MENU,
    payload: {
        type,
    },
});

export const showEditBookingContextMenu = (schedulerRef, coords) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.EDIT_BOOKING_CONTEXT_MENU,
    payload: {
        schedulerRef,
        ...coords,
    },
});

export const showEditParentContextMenu = (schedulerRef, coords) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.EDIT_RESOURCE_PARENT_ITEMS_CONTEXT_MENU,
    payload: {
        schedulerRef,
        ...coords,
    },
});

export const showEditDeadlineContextMenu = (schedulerRef, coords) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.EDIT_DEADLINE_CONTEXT_MENU,
    payload: {
        schedulerRef,
        ...coords,
    },
});

export const showScheduleContextMenu = (cellId, schedulerRef, viewObject, viewData) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.SCHEDULE_CONTEXT_MENU,
    payload: {
        cellId,
        schedulerRef,
        viewObject,
        viewData,
    },
});

export const showMenuRowContextMenu = (coords, type, additionalParams) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.MENU_ROW,
    payload: {
        ...coords,
        type,
        additionalParams,
    },
});

export const showCustomColumnContextMenu = (coords, column, schedulerRef) => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.CUSTOM_COLUMNS,
    payload: {
        ...coords,
        column,
        schedulerRef,
    },
});

export const showSavedScheduleContextMenu = schedule => ({
    type: actionTypes.SHOW_CONTEXT_MENU,
    contextMenuType: contextMenuTypes.SAVED_SCHEDULE_CONTEXT_MENU,
    payload: {
        schedule,
    },
});
