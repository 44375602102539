import { UNASSIGNED, VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'resourceFirstName',
        display: 'First Name',
        multiItemsDisplay: 'Names',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceName',
        display: 'Resource Name',
        multiItemsDisplay: 'Resources',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceUnassignedName',
        display: 'Resource/Unassigned Name',
        multiItemsDisplay: 'Resources',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'resourceLastName',
        display: 'Last Name',
        multiItemsDisplay: 'Names',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceEmail',
        display: 'E-mail',
        multiItemsDisplay: 'Emails',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceIsProjectManager',
        display: 'Is Project Manager',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceIsApprover',
        display: 'Approver',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        extensions: [VACATION_REQUEST],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceTag',
        display: 'Resource/Unassigned Tag',
        multiItemsDisplay: 'Tags',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceStatus',
        display: 'Resource/Unassigned Status',
        multiItemsDisplay: 'Statuses',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceRole',
        display: 'Role',
        multiItemsDisplay: 'Roles',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceNote',
        display: 'Resource/Unassigned Note',
        multiItemsDisplay: 'Notes',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceGroups',
        display: 'Groups',
        multiItemsDisplay: 'Groups',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'resourceCalendar',
        display: 'Resource Calendars',
        multiItemsDisplay: 'Calendars',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
];
