import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { showScheduleResourceModal } from 'actions/modalActions';
import { hasRole } from 'utils/rightsUtil';
import { FileCopy, Edit, Delete, RemoveCircleOutline, FilterNone, Description } from '@material-ui/icons';
import { Wrapper, Item } from './styles';
import { Tooltip } from '@material-ui/core';
import { useScheduleRights } from '../../hooks';
import { TYPE_UNASSIGNED } from '../../../../enums/resourceEnum';

const SelectedBooking = props => {
    const {
        updateBooking,
        updateClipboard,
        deleteBooking,
        deleteBookingFromModal,
        duplicateBooking,
        bookingId,
        booking,
        onNotesClick,
    } = props;
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);

    const dispatch = useDispatch();

    const { hasScheduleRightsForResourceAndProject } = useScheduleRights();

    const canSchedule = useMemo(() => {
        if (!booking) {
            return false;
        }

        return hasScheduleRightsForResourceAndProject(
            booking.project?._id,
            booking.resourceInfo?._id ?? booking.resource,
            booking.resourceInfo?.type === TYPE_UNASSIGNED.value,
            {
                resource:  booking.resourceInfo,
                project: booking.project
            }
        );
    }, [booking, hasScheduleRightsForResourceAndProject]);

    const bookingModal = useCallback(
        () =>
            dispatch(
                showScheduleResourceModal({
                    onSubmit: updateBooking,
                    activeTab: undefined,
                    bookingId,
                    onDelete: deleteBookingFromModal,
                })
            ),
        [dispatch, bookingId, deleteBookingFromModal, updateBooking]
    );

    if (!canSchedule) {
        return null;
    }

    return (
        <Wrapper>
            <Tooltip placement="top" title="Cut booking" arrow>
                <Item onClick={() => updateClipboard(true)} data-cy="scheduler-footer-cut-booking">
                    <RemoveCircleOutline />
                </Item>
            </Tooltip>
            <Tooltip placement="top" title="Copy booking" arrow>
                <Item onClick={() => updateClipboard()} data-cy="scheduler-footer-copy-booking">
                    <FileCopy />
                </Item>
            </Tooltip>
            <Tooltip placement="top" title="Delete booking" arrow>
                <Item onClick={deleteBooking} data-cy="scheduler-footer-delete-booking">
                    <Delete />
                </Item>
            </Tooltip>
            <Tooltip placement="top" title="Duplicate booking" arrow>
                <Item onClick={duplicateBooking} data-cy="scheduler-footer-duplicate-booking">
                    <FilterNone />
                </Item>
            </Tooltip>
            {hasRole(resourceRoleRights, 'viewNotes') && (
                <Tooltip placement="top" title="Notes & Links" arrow>
                    <Item data-action="VIEW_NOTES" onClick={onNotesClick} data-cy="scheduler-footer-notes-links">
                        <Description />
                    </Item>
                </Tooltip>
            )}
            <Tooltip placement="top" title="Edit booking" arrow>
                <Item onClick={bookingModal} data-cy="scheduler-footer-edit-booking">
                    <Edit />
                </Item>
            </Tooltip>
        </Wrapper>
    );
};

SelectedBooking.propTypes = {
    updateClipboard: PropTypes.func.isRequired,
    deleteBooking: PropTypes.func.isRequired,
    duplicateBooking: PropTypes.func.isRequired,
    bookingId: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    onNotesClick: PropTypes.func,
};

SelectedBooking.defaultProps = {
    onNotesClick: null,
};

export default SelectedBooking;
