import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import { Icon } from './styles';

const InfoTooltip = ({ description }) => (
    <span className="ml-1">
        <Icon id="tooltip">?</Icon>
        <UncontrolledTooltip placement="right" target="tooltip">
            {description}
        </UncontrolledTooltip>
    </span>
);

InfoTooltip.propTypes = {
    description: PropTypes.string.isRequired,
};

export default InfoTooltip;
