import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem, NavLink } from 'reactstrap';
import { AWS_S3_URL } from 'constants';
import { getRoleDisplay } from 'enums/resourceEnum';
import AccountsList from 'modules/header/accountsList';
import Link from 'shared/link';
import { ProfileWrapper } from './styles';

const Profile = () => {
    const { thumbUrl, firstName, lastName, email, resourceRole } = useSelector(state => state.account);

    return (
        <ProfileWrapper>
            <div className="mb-3 pr-1">
                <div className="user-thumb-header">
                    <NavLink className="d-inline px-0" href="#/settings/profile">
                        <img loading="lazy" src={`${AWS_S3_URL}${thumbUrl}`} alt="" />
                    </NavLink>
                </div>
                <div className="profile-name text-truncate">{`${firstName} ${lastName}`}</div>
                <div className="profile-email text-truncate">{email}</div>
                <div>{getRoleDisplay(resourceRole)}</div>
                <div className="profile-links">
                    <NavLink className="d-inline px-0" href="#/settings/profile">
                        <i className="fa fa-caret-right" /> Profile Settings
                    </NavLink>{' '}
                    |&nbsp;
                    <NavLink className="d-inline px-0" id="cp-logout" tag={Link} to="/logout">
                        Logout
                    </NavLink>
                </div>
            </div>
            <DropdownItem divider />
            <AccountsList />
        </ProfileWrapper>
    );
};

export default Profile;
