import { axiosInstance } from './axiosInstance';

export const getCategoryGroupsRequest = () =>
    axiosInstance
        .get('categoryGroup')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getCategoriesByGroupIdRequest = groupId =>
    axiosInstance
        .get(`categoryGroup/${groupId}/categories`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const saveCategoryGroupsRequest = data =>
    axiosInstance
        .put(`/categoryGroup/bulk`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
