import React from 'react';
import PropTypes from 'prop-types';
import { TabPane, FormText } from 'reactstrap';
import { filter, sortBy } from 'lodash';
import { Field } from 'redux-form';
import InternalExternalBookingRates from 'modules/modals/scheduleResourceModal/components/internalExternalBookingRates';
import PermissionDenied from 'shared/permissionDenied';
import MissingExtension from 'shared/warnings/missingExtension';
import { useAppKeyWords, useHasRights, useIsExtensionInstalled } from 'hooks';
import { useSelector } from 'react-redux';
import { RESOURCE_RATE } from 'enums/extensionShortIdEnum';
import { chooseField } from 'shared/formFields';

const getNotDeletedRates = rates =>
    sortBy(
        filter(rates, rate => !rate.deleted),
        item => item.label.toLowerCase()
    );

const BookingRatesTab = props => {
    const { tabId, externalRateType, internalRateType, booking } = props;

    const projects = useSelector(state => state.projectReducer.projects);
    const resources = useSelector(state => state.resourceReducer.resources);
    const { resourceKeyWord, projectKeyWord } = useAppKeyWords();
    const isRCExtensionInstalled = useIsExtensionInstalled(RESOURCE_RATE);
    const billingRates = useSelector(state => state.companyReducer.company.settings.billingRates);
    const { hasBookingRateRights } = useHasRights([
        {
            rights: ['settingBookingRate'],
            rule: 'one',
            name: 'hasBookingRateRights',
        },
    ]);
    const filteredRates = getNotDeletedRates(billingRates || []);

    const billabilityJSX = () => {
        const currentProjectId = booking.project?._id;
        const currentResourceId = booking.resource?._id;

        const projectBillable = projects.find(project => project._id === currentProjectId)?.billable;
        const resourceBillable = resources.find(resource => resource._id === currentResourceId)?.billable;

        const inheritedBillable = projectBillable && resourceBillable ? `Billable` : `Non Billable`;
        const inheritedText = `Inherit (${inheritedBillable})`;
        return (
            <section className="form-fields">
                <Field type="radio" inline label={inheritedText} name="billable" value="null" component={chooseField} />
                <Field type="radio" inline label="Billable" name="billable" value="true" component={chooseField} />
                <Field type="radio" inline label="Non-Billable" name="billable" value="false" component={chooseField} />
                <FormText className="helpText helpTextAdjustDisplay">
                    Select if this booking should inherit the billability or choose to force it to non-billable or
                    billable. Setting it to non-billable means that the spend on this booking will not be counted in
                    reports. You will however be able to calculate the non-billable spend in reports using filters if
                    you wish.
                </FormText>
            </section>
        );
    };

    return (
        <TabPane tabId={tabId}>
            {isRCExtensionInstalled && hasBookingRateRights && (
                <div>
                    {billabilityJSX()}
                    <InternalExternalBookingRates
                        helpButtonId={1}
                        rates={filteredRates}
                        rateType={externalRateType}
                        type="external"
                        projectKeyWord={projectKeyWord}
                        resourceKeyWord={resourceKeyWord}
                    />
                    <InternalExternalBookingRates
                        helpButtonId={2}
                        rates={filteredRates}
                        rateType={internalRateType}
                        type="internal"
                        projectKeyWord={projectKeyWord}
                        resourceKeyWord={resourceKeyWord}
                    />
                </div>
            )}
            {!isRCExtensionInstalled && (
                <MissingExtension description="Booking Rates are Disabled on a company Level. You must first enable it in Settings to be able to set a custom billable booking rate" />
            )}
            {isRCExtensionInstalled && !hasBookingRateRights && (
                <PermissionDenied description="Sorry, you do not have the rights to use this booking rates feature. Please consult with your Account Owner to gain access." />
            )}
        </TabPane>
    );
};

BookingRatesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    internalRateType: PropTypes.string,
    externalRateType: PropTypes.string,
};

BookingRatesTab.defaultProps = {
    internalRateType: 'inherited',
    externalRateType: 'inherited',
};

export default BookingRatesTab;
