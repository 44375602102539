import { axiosInstance } from './axiosInstance';

export const getTagsRequest = type =>
    axiosInstance
        .get(`tag/${type}/search?q=`)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const createTagRequest = data =>
    axiosInstance
        .post('tag/create', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
