import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useLandingPage } from 'hooks';
import InitialLoading from 'shared/initialLoading';

const Index = () => {
    const { isPartiallyAuthenticated, _id, loading } = useSelector(state => state.account);
    const isAuthenticated = !!_id;
    const subdomain = useSelector(state => state.companyReducer.company.subdomain);
    const initialLoad = useSelector(state => state.initialAppReducer.initialLoad);
    const url = useLandingPage();
    const companyExists = !!subdomain;
    const [redirectLink, setRedirectLink] = useState(companyExists ? url : '');

    useEffect(() => {
        if (isAuthenticated && companyExists) {
            setRedirectLink(url);
        }
    }, [isAuthenticated, companyExists, url]);

    if (!isAuthenticated && !loading && !initialLoad) {
        return <Redirect to={!isPartiallyAuthenticated ? '/login' : '/launchpad'} />;
    } else if (redirectLink) {
        return <Redirect to={redirectLink} />;
    } else if (!isPartiallyAuthenticated && !isAuthenticated) {
        return <InitialLoading />;
    }

    return null;
};

export default Index;
