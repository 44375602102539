import { statuses } from 'enums/projectEnum';
import * as criteria from 'enums/criteriaEnum';

export const getCriteriaByProjectStatus = projectStatus => {
    const statusMap = {
        [statuses.STATUS_ACTIVE.value]: criteria.ACTIVE,
        [statuses.STATUS_PENDING.value]: criteria.PENDING,
        [statuses.STATUS_PLANNED.value]: criteria.PLANNED,
        [statuses.STATUS_ARCHIVED.value]: criteria.ARCHIVED,
        [statuses.STATUS_FLOATING.value]: criteria.FLOATING,
    };

    return statusMap[projectStatus] || '';
};
