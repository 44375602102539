import { map, includes } from 'lodash';
import StorageUtil from 'utils/StorageUtil';
import { getDaypilotResourceChildren } from 'api/scheduler';
import resourceProjectDayPilotMenu from './../areas/resourceProject';
import { resourceChildObject } from '../formater/resourceChild';
import { addMenuRow, addRemovedProjectsRow, addEventRow } from '../formater/additionalRows';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { store } from '../../../../store';
import {
    dispatchRefreshBookings,
    dispatchRefreshUnassignedDPResourcesEvent,
} from 'modules/scheduler/utils/schedulerUtil';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { eventsForRangeCache } from '../../../../utils/eventsForRangeCache';
import { getDaypilotChildrenResources } from '../../../../actions/schedulerActions';

export default ({ keyWords, accountRoleRights, viewObject }, actions) => args => {
    eventsForRangeCache.clear();
    const reduxState = store.getState();
    const dispatch = store.dispatch;
    const schedulerState = reduxState.scheduler;
    const { isProjectRow } = args.row.tags;

    // fix
    // lets read directly from store to avoid whole scheduler redraw
    // if dpResources or filters has changed
    const dpResources = schedulerState?.dpResources;
    const filters = schedulerState?.filters ?? {};

    const schedulerRef = args.row.calendar;
    schedulerRef.clearSelection();
    const isResourceAndNotUnassignedParent = args.row.tags.isResourceRow && !args.row.tags.unassignedParentRow;
    const isResourceUnassignedAndParent = args.row.tags.unassignedRow && args.row.tags.parent; // Unassigned single view and group rows mode

    const schedulerColumns = reduxState.account.preferences.schedulerColumns;

    const sortDirection = isProjectRow
        ? schedulerColumns.projectColumns.sortedColumn.sortDirection
        : schedulerColumns.resourceColumns.sortedColumn.sortDirection;

    if (isResourceAndNotUnassignedParent || isResourceUnassignedAndParent) {
        const resourceProjectContextMenu = resourceProjectDayPilotMenu(
            accountRoleRights,
            schedulerRef.groupConcurrentEvents,
            keyWords,
            viewObject,
            {
                addFilter: actions?.addFilter,
                showProjectModal: actions?.showProjectModal,
                updateProject: actions?.updateProject,
            }
        );
        const resourceProjectIds = dpResources.find(({ _id }) => _id === args.resource.id)?.projects;

        const updateDPProjects = projects => {
            projects.sort((a, b) =>
                sortDirection === 'ASCENDING'
                    ? a.nameLower.localeCompare(b.nameLower)
                    : b.nameLower.localeCompare(a.nameLower)
            );

            const childrenProjects = map(replaceProjectsColorWithStatusColor(projects), project => {
                return resourceChildObject(
                    project,
                    args.resource.data.tags.resource,
                    resourceProjectContextMenu,
                    accountRoleRights,
                    includes(filters.projects?.filters, project._id)
                );
            });
            args.row.data.children = childrenProjects;
            const removedProjectsRow = addRemovedProjectsRow(args.resource.data.tags.resource, keyWords);
            args.row.data.children.push(removedProjectsRow);

            const menuRow = addMenuRow(args.resource.data.tags.resource, {
                accountRoleRights,
                projectKeyWord: keyWords.projectKeyWord,
            });
            if (menuRow && args.resource.data.tags.resource.hasRightsToResource) {
                args.row.data.children.push(menuRow);
            }

            const eventRow = addEventRow(args.resource.data.tags.resource, keyWords);
            if (eventRow) {
                args.row.data.children.unshift(eventRow);
            }

            schedulerRef.update();
        };

        if (resourceProjectIds?.length) {
            getDaypilotResourceChildren(args.resource.id, resourceProjectIds).then(updateDPProjects);
        } else {
            updateDPProjects([]);
        }
    }

    if (isProjectRow && !args.row.tags.unassignedParentRow) {
        const projectResourceIds = dpResources.find(({ _id }) => _id === args.resource.id)?.resources;
        if (projectResourceIds?.length) {
            dispatch(
                getDaypilotChildrenResources.request({
                    parentId: args.resource.id,
                    childrenIds: projectResourceIds,
                })
            );
        } else {
            dispatch(getDaypilotChildrenResources.success({ parentId: args.resource.id, children: [] }));
        }
    }

    if (args.row.tags.unassignedParentRow) {
        StorageUtil.setItem('unassignedRow', 'expanded', 'localStorage');
        if (viewObject.isProjectView && schedulerRef.mode === PARENT.value) {
            dispatchRefreshUnassignedDPResourcesEvent();
        } else {
            dispatchRefreshBookings();
        }
    }
};
