import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { SmallInfoTile } from '@hub-mono/ui';

const UpcomingPublicHolidays = ({ upcomingEvents }) => {
    const nextHoliday = find(upcomingEvents.toJSON(), event => event.isFuture && !event.isVacation);
    const daysUntilNextPublicHoliday = nextHoliday && nextHoliday.diff;
    const nameOfNextPublicHoliday = nextHoliday?.name || 'Empty';

    return (
        <SmallInfoTile
            title={`Upcoming Public Holiday`}
            value={nameOfNextPublicHoliday}
            text={nextHoliday ? `in ${daysUntilNextPublicHoliday} days!` : 'No Upcoming Public Holidays'}
        />
    );
};

UpcomingPublicHolidays.propTypes = {
    upcomingEvents: PropTypes.object.isRequired,
};

export default UpcomingPublicHolidays;
