import React from 'react';
import { Button } from 'reactstrap';

const FiltersButtons = React.memo(props => {
    const { selected, filtersButtons, onFilterChanged } = props;

    const onFilterButtonClick = filterButtonClicked => {
        onFilterChanged(filterButtonClicked);
    };

    return (
        <div className="mt-2 d-flex">
            {filtersButtons.map(filterButton => {
                const { value, text } = filterButton;
                if (!text) {
                    return null;
                }
                const isFilterApplied = value === selected?.value;

                return (
                    <Button
                        key={value}
                        data-cy={`menu-filter-${text}`}
                        className="w-100 ml-1 mr-1"
                        onClick={() => onFilterButtonClick(filterButton)}
                        outline={!isFilterApplied}
                    >
                        {text}
                    </Button>
                );
            })}
        </div>
    );
});

export default FiltersButtons;
