export const bookingLinkStylesEnum = {
    line: 'solid',
    dotted: 'dotted',
    dashed: 'dashed',
};

export const defaultLinkStyle = bookingLinkStylesEnum.line;

export const bookingLinkPositionEnum = {
    aboveBookings: 'Above',
    belowBookings: 'Below',
};

export const defaultLinkPosition = bookingLinkPositionEnum.aboveBookings;

export const defaultLineColor = '#000';

export const bookingLinksDisplayEnum = {
    show: 'show',
    hide: 'hide',
    showForSelectedBooking: 'showForSelected',
};

export const defaultLinkDisplay = bookingLinksDisplayEnum.show;

export const bookingLinksOptionsKeys = {
    bookingLinkColor: 'bookingLinkColor',
    bookingLinkStyle: 'bookingLinkStyle',
    bookingLinkPosition: 'bookingLinkPosition',
    bookingLinkDisplay: 'bookingLinkDisplay',
};
