import { List, fromJS } from 'immutable';

export const filterByText = (arr, filterText, properties, resultAsImmutable) => {
    if (!filterText.length) {
        return arr;
    }

    filterText = filterText.toLowerCase();

    if (!List.isList(arr)) {
        arr = fromJS(arr);
    }

    arr = arr.filter(item => {
        for (let property in properties) {
            let value = item.getIn(properties[property].split('.'));
            if (value && -1 !== value.toLowerCase().indexOf(filterText)) {
                return true;
            }
        }

        return false;
    });

    if (resultAsImmutable) {
        return arr;
    }

    return arr.toJSON();
};

export const filterRequestTable = (arr, filterText) => {
    let properties = [
        'approverFirstName',
        'approverLastName',
        'resourceFirstName',
        'resourceLastName',
        'projectName',
        'type',
        'approvalInfo.requesterNote',
        'approvalInfo.approverNote',
    ];

    return filterByText(arr, filterText, properties);
};
