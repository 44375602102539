import { useRef, useEffect } from 'react';

export const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
        if (value) {
            ref.current = value;
        }
    }, [value]);

    return ref.current;
};
