import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, initialize, reduxForm, submit, getFormValues } from 'redux-form';
import { KeyboardArrowRight, Loop } from '@material-ui/icons';
import DayPicker from 'react-day-picker';
import { addDays, differenceInDays } from 'date-fns';
import { Row, Col } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from 'shared/ImageIcon';
import { useIsExtensionInstalled, useForm, useAppKeyWords, useRouteParams, useHasRights } from 'hooks';
import { getCategoryTemplates } from 'actions/categoryTemplateActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { CATEGORY_GROUPS } from 'enums/extensionShortIdEnum';
import { roles } from 'enums/resourceEnum';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { useBooking } from 'modules/scheduler/hooks';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import ResourceThumb from 'modules/scheduler/components/resourceThumb';
import { CategoryList } from 'modules/scheduler/components/lists';
import { makeGetFilteredCategoryTemplates } from 'selectors/categoryTemplate';
import { makeCategoryGroups } from 'selectors/categoryGroups';
import {
    selectCompanyAvgDailyCapacity,
    selectIsDeadlinesExtensionInstalled,
    selectProjectStatusesMapped,
    selectUseCategoriesAllocation,
} from 'selectors/company';
import Allocation from 'shared/allocation/materialAllocation';
import { dateField } from 'shared/formFields';
import { validateSchema } from 'utils/schemaUtil';
import { allocationTypeMap, formSchema, getDefaultValues, mapFormToRequest } from 'forms/shortBookingForm';
import ClipBoardActions from './clipBoardActions';
import ActionItem from './../../shared/actionItem';
import MoreItems from './moreItems';
import NewShortCategory from 'modules/categoryGroups/components/categoryForm/categoryForm';
import ListItem from './../../../lists/listItem';
import { CONTEXT_MENU_MAX_HEIGHT, SCHEDULED_CONTEXT_MENU_WIDTH } from './../index';
import { ActionItemsRow, ModernMenuLabel } from '../../shared/styles';
import { usePressEnter } from 'hooks/usePressEnter';
import Repeat from 'shared/repeat';
import { resourceIsNotNonBookable } from 'shared/lib/resources';
import { SchedulerProjectsList } from '../../../lists/projectList/schedulerProjectsList';
import { SchedulerProjectsListProvider } from '../../../lists/projectList/schedulerProjectsListProvider';
import NewShortProjectOrEvent from '../../shared/shortProjectOrEvent';
import { SchedulerResourcesList } from '../../../lists/resourceList/schedulerResourcesList';
import NewShortResourceOrUnassigned from '../../shared/shortResourceOrUnassigned';
import { SchedulerResourcesListProvider } from '../../../lists/resourceList/schedulerResourcesListProvider';
import classNames from 'classnames';
import { getSchemaValidationInfo } from 'shared/lib/data-validation';
import { useTouchInterdependentFieldsReduxForm } from 'shared/hooks';
import { DeadlineForm, DeleteDeadlineBtn } from '../../shared/deadlineForm';
import { selectCurrentSelectedBooking } from '../../../../../../selectors/scheduler';
import { showMissingDeadlinesExtensionModal } from '../../../../../../actions/modalActions';
import { updateBookingSelection } from '../../../../../../actions/schedulerActions';
import { isDaypilotDate } from '../../../../../../utils/DateUtil';
import { useCalculateAllocationForCategory } from '../../../../../../shared/allocation/calculateAllocationForCategory';
import moment from 'moment/moment';
import { getResourceCapacity } from '../../../../../../utils/capacityCalculation';
import { getBookingAllocationValuesFromSingleValue } from '../../../../../../shared/lib/booking';

const FORM_NAME = 'editBookingContext';

export const useStyles = makeStyles({
    repeatIcon: {
        cursor: 'pointer',
        fontSize: '20px',
        display: 'block',
        margin: 'auto',
        height: '40px',
        '&:hover': {
            opacity: 0.7,
        },
    },
    deleteDeadlineBtn: {
        flex: '1 0 auto',
    },
});

const rights = [
    {
        rights: ['settingBookCat'],
        rule: 'one',
        name: 'hasSettingBookCatRight',
    },
];

const Schedule = props => {
    const { handleSubmit, schedulerRef, onClose, height, invalid, pristine, form, touch } = props;
    const dispatch = useDispatch();
    const formName = form || FORM_NAME;
    const { projectKeyWord } = useAppKeyWords();
    const slideRef = useRef();
    const [rowTags, setRowTags] = useState({});
    const [categoryName, setCategoryName] = useState('');
    const params = useRouteParams();
    const classes = useStyles();
    const updateFields = useForm(
        formName,
        useCallback(() => slideRef.current && slideRef.current.triggerStart(), [])
    );
    const isCategoryGroupExtActive = useIsExtensionInstalled(CATEGORY_GROUPS);
    const viewObject = getViewObject(params);

    const formValuesSelector = useMemo(() => getFormValues(form), [form]);
    const formValuesFromSelector = useSelector(formValuesSelector);
    const formValues = useMemo(() => formValuesFromSelector || {}, [formValuesFromSelector]);
    const { project = {}, resource = {}, category = {}, categoryGroup, startDate, interval } = formValues;

    // Validate manually data to show interdependent validation (using useTouchInterdependentFieldsReduxForm hook);
    const validationResultInfo = useMemo(() => getSchemaValidationInfo(formValues, formSchema), [formValues]);
    const { validDataForSchema, validationResult } = validationResultInfo;

    useTouchInterdependentFieldsReduxForm({
        touch,
        formName,
        validationResult,
    });

    const submitting = useSelector(state => {
        return !!state.form?.editBookingContext?.triggerSubmit || !!state.form?.editBookingContext?.submitSucceeded;
    });
    const useCategoriesAllocation = useSelector(selectUseCategoriesAllocation);
    const avgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const shouldCalculateAllocation = useCategoriesAllocation && isCategoryGroupExtActive;
    const projectStatusesMapped = useSelector(selectProjectStatusesMapped);
    const currentResourceFullName = resource && `${resource.firstName} ${resource.lastName}`;
    const {
        updateClipboard,
        updateBooking,
        duplicateBooking,
        bulkCreateBookings,
        deleteBooking,
        splitBooking,
    } = useBooking(currentSelectedBooking, onClose);

    const {
        currentSelectedBookingStart,
        currentSelectedBookingEndLocal,
        currentSelectedBookingStartLocal,
        currentSelectedBookingEnd,
    } = useMemo(() => {
        return {
            currentSelectedBookingStart: isDaypilotDate(currentSelectedBooking.start)
                ? new Date(currentSelectedBooking.start.value)
                : currentSelectedBooking.start,
            currentSelectedBookingStartLocal:
                typeof currentSelectedBooking.start.toDateLocal === 'function'
                    ? currentSelectedBooking.start.toDateLocal()
                    : currentSelectedBooking.start,
            currentSelectedBookingEnd: isDaypilotDate(currentSelectedBooking.end)
                ? new Date(currentSelectedBooking.end.value)
                : currentSelectedBooking.end,
            currentSelectedBookingEndLocal:
                typeof currentSelectedBooking.end.toDateLocal === 'function'
                    ? currentSelectedBooking.end.toDateLocal()
                    : currentSelectedBooking.end,
        };
    }, [currentSelectedBooking.end, currentSelectedBooking.start]);

    const isSplitOptionDisabled = !differenceInDays(currentSelectedBookingStart, currentSelectedBookingEnd);
    const scale = schedulerRef.current.control.scale;
    const displaySplitBooking = !currentSelectedBooking.repeat && CELLDURATION.value !== scale;
    const maxHeight = displaySplitBooking ? CONTEXT_MENU_MAX_HEIGHT : CONTEXT_MENU_MAX_HEIGHT - 60;
    const dropDownHeight = height > maxHeight ? maxHeight : height;
    const categoryGroupsSelector = useMemo(() => makeCategoryGroups(), []);
    const projectCategoryGroupsSelector = useMemo(() => makeCategoryGroups(project.categoryGroups), [
        project.categoryGroups,
    ]);
    const categoryTemplatesSelector = useMemo(() => makeGetFilteredCategoryTemplates(), []);
    const { hasSettingBookCatRight } = useHasRights(rights);

    const categoryGroups = useSelector(categoryGroupsSelector);
    const projectCategoryGroups = useSelector(projectCategoryGroupsSelector);
    const categoryTemplates = useSelector(categoryTemplatesSelector);
    const isDeadlinesExtensionInstalled = useSelector(selectIsDeadlinesExtensionInstalled);

    const currentFormValues = useSelector(getFormValues(formName));

    useEffect(() => {
        dispatch(getCategoryTemplates.request());
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    useEffect(() => {
        try {
            if (currentSelectedBooking.resource) {
                const row = schedulerRef.current.control.rows.find(currentSelectedBooking.resource);
                setRowTags(row.tags);
            }
        } catch (e) {
            console.error('Row cannot be found for: ' + currentSelectedBooking.resource);
        }
    }, [currentSelectedBooking.resource, schedulerRef]);

    useEffect(() => {
        if (!submitting && currentSelectedBooking.id) {
            const booking = {
                ...currentSelectedBooking,
                start: currentSelectedBookingStartLocal,
                end: currentSelectedBookingEndLocal,
            };
            const initializeEditBookingFormValues = getDefaultValues(
                { ...booking, useCategoriesAllocation: false },
                categoryGroups
            );
            dispatch(initialize(formName, initializeEditBookingFormValues));
        }
    }, [
        dispatch,
        avgDailyCapacity,
        categoryGroups,
        currentSelectedBooking,
        formName,
        submitting,
        currentSelectedBookingStartLocal,
        currentSelectedBookingEndLocal,
    ]);

    const calculateAllocationForCategory = useCalculateAllocationForCategory();

    const onSelectCategoryList = onSelectCategoryListData => {
        let allocationData = {};

        if (shouldCalculateAllocation) {
            const { percentage, hours, total } = formValues;

            const startDateTmp = moment(currentFormValues?.startDate || currentFormValues?.start);
            const endDateTmp = moment(currentFormValues?.endDate || currentFormValues?.end);
            const currentResource = currentFormValues?.resource;
            const { workDaysCount, totalMinutes: totalBookingMinutes } = getResourceCapacity(
                currentResource,
                startDateTmp,
                endDateTmp
            );

            const categoryAllocationData = calculateAllocationForCategory({
                percentage,
                hours,
                total,
                category: onSelectCategoryListData?.category,
            });

            allocationData = getBookingAllocationValuesFromSingleValue({
                value: categoryAllocationData[allocationTypeMap[onSelectCategoryListData?.category.state].name],
                nameOfValue: allocationTypeMap[onSelectCategoryListData?.category.state].name,
                avgDailyCapacity,
                totalBookingMinutes,
                numberOfWorkDays: workDaysCount,
            });

            if (onSelectCategoryListData?.category?.state) {
                allocationData.state = onSelectCategoryListData?.category?.state;
            }
        }

        updateFields({ ...onSelectCategoryListData, ...allocationData });
    };

    const onSelectProjectEvent = values => {
        // Automatically set the default category booking of project
        if (values.project && values.project.defaultCategoryTemplate) {
            const categoryTemplateFound = categoryTemplates.find(
                categoryTemplate => categoryTemplate._id === values.project.defaultCategoryTemplate
            );
            if (categoryTemplateFound) {
                values.category = categoryTemplateFound;
            }
        }

        onSelectCategoryList(values);
        updateFields(values);
    };

    const onSplit = useCallback(
        (date, modifiers) => {
            if (modifiers.disabled) {
                return;
            }

            splitBooking({
                splitDate: date,
                bookingId: currentSelectedBooking._id || currentSelectedBooking.id,
            });
        },
        [currentSelectedBooking, splitBooking]
    );

    const submitDisabled = invalid || pristine || !validDataForSchema;

    const dispatchSubmit = useCallback(() => {
        if (!submitDisabled) {
            dispatch(submit(formName));
        }
    }, [dispatch, formName, submitDisabled]);

    // not via button type submit because list items aren't part of a form
    usePressEnter(dispatchSubmit);

    const onSubmit = useCallback(
        values => {
            const requestValues = mapFormToRequest(values);
            updateBooking(requestValues);
        },
        [updateBooking]
    );

    const slideToRepeatForm = () => {
        slideRef.current && slideRef.current.triggerSlide(8);
    };

    const goBack = () => {
        slideRef.current && slideRef.current.triggerStart();
    };

    const goToSplit = () => {
        slideRef.current && slideRef.current.triggerSlide(7);
    };

    const goToDeadlineForm = () => {
        if (!isDeadlinesExtensionInstalled) {
            dispatch(showMissingDeadlinesExtensionModal());
        } else {
            slideRef.current?.triggerNestedSlide(1);
        }
    };

    const handleDeadlineUpdateDelete = useCallback(() => {
        if (onClose) {
            onClose();
        }
        dispatch(updateBookingSelection.request());
    }, [dispatch, onClose]);

    const slideCardItems = [
        {
            id: 1,
            itemElement: ({ ...props }) => (
                <ActionItemsRow className={classNames('text-center m-3')}>
                    <ClipBoardActions onClone={duplicateBooking} onCopy={() => updateClipboard()} />
                    <ActionItem text="More ..." {...props} className="edit-booking-mm" dataCy="button--more-next-slide">
                        <KeyboardArrowRight />
                    </ActionItem>
                </ActionItemsRow>
            ),
            divider: true,
            slides: [
                {
                    scrollable: true,
                    backBtnPosition: 'none',
                    content: (
                        <MoreItems
                            viewObject={viewObject}
                            currentSelectedBooking={currentSelectedBooking}
                            updateClipboard={updateClipboard}
                            updateBooking={updateBooking}
                            onDelete={deleteBooking}
                            bulkCreateBookings={bulkCreateBookings}
                            onClose={onClose}
                            schedulerRef={schedulerRef}
                            projectName={project.name}
                            resourceName={currentResourceFullName}
                            goBack={goBack}
                            goToSplit={goToSplit}
                            showSplit={displaySplitBooking}
                            isSplitOptionDisabled={isSplitOptionDisabled}
                            onDeadlineItemClick={goToDeadlineForm}
                        />
                    ),
                },
                {
                    scrollable: true,
                    content: (
                        <DeadlineForm
                            booking={currentSelectedBooking}
                            onSave={handleDeadlineUpdateDelete}
                            onDelete={handleDeadlineUpdateDelete}
                            inputsClassName="pt-4 pl-4 pr-3"
                        />
                    ),
                    header: (
                        <DeleteDeadlineBtn
                            booking={currentSelectedBooking}
                            className={classNames('pr-2', classes.deleteDeadlineBtn)}
                            onDelete={handleDeadlineUpdateDelete}
                        />
                    ),
                },
            ],
        },
        {
            id: 2,
            divider: true,
            itemElement: (
                <Row className="m-3 mt-4">
                    <Col xs="5" className="pl-1 pr-0">
                        <Field
                            name="startDate"
                            variant="outlined"
                            label="Start"
                            component={dateField}
                            disabled={
                                scale === CELLDURATION.value ||
                                currentSelectedBooking?.scale?.value === CELLDURATION.value
                            }
                            componentDataCyPrefix="scheduler-booking-modern-menu-start-date"
                        />
                    </Col>
                    <Col xs="5" className="pl-1 pr-0 ml-1">
                        <Field
                            name="endDate"
                            variant="outlined"
                            label="End"
                            component={dateField}
                            props={{
                                shouldDisableDate: date => {
                                    const current = new Date(date).setHours(0, 0, 0, 0);
                                    const newStartDate = new Date(startDate).setHours(0, 0, 0, 0);
                                    return current < newStartDate;
                                },
                            }}
                            disabled={
                                scale === CELLDURATION.value ||
                                currentSelectedBooking?.scale?.value === CELLDURATION.value
                            }
                            componentDataCyPrefix="scheduler-booking-modern-menu-end-date"
                        />
                    </Col>
                    <Col xs="auto" className="p-0 ml-3">
                        <Loop className={classes.repeatIcon} onClick={slideToRepeatForm} />
                    </Col>
                </Row>
            ),
        },
        {
            id: 3,
            itemElement: (
                <>
                    <ModernMenuLabel>Allocation</ModernMenuLabel>
                    <Allocation
                        disableAll={scale === CELLDURATION.value}
                        wrapperClassName="mt-2 p-3"
                        inline={true}
                        onChange={updateFields}
                        allocationDefaultState={currentSelectedBooking.state}
                        formName={formName}
                    />
                </>
            ),
            show: currentSelectedBooking.allDay !== false,
        },
        {
            id: 4,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    {...props}
                    backgroundColor={category.gridColor}
                    primaryText={category.name}
                    secondaryText={categoryGroup && categoryGroup.name}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <CategoryList
                                categoryGroups={projectCategoryGroups}
                                onEmptySearch={setCategoryName}
                                onSelect={onSelectCategoryList}
                                height={dropDownHeight - 20}
                                selectedIds={category?._id ? [category._id] : []}
                            />
                        </div>
                    ),
                    buttonText: hasSettingBookCatRight
                        ? `Add New Category${categoryName && ` "${categoryName}"`}`
                        : undefined,
                },
                {
                    scrollable: true,
                    content: (
                        <div className="px-4">
                            <NewShortCategory
                                categoryGroups={project ? projectCategoryGroups : []}
                                inline={false}
                                inlineAllocation={true}
                                buttonFixed
                                disableGutters
                                initialName={categoryName}
                                wrapperClassName="mt-4 mb-5"
                                categories={categoryTemplates}
                                onCreate={({ category, categoryGroup }) => {
                                    slideRef.current && slideRef.current.triggerStart;
                                    updateFields({ category, categoryGroup });
                                    setCategoryName('');
                                }}
                            />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 5,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    icon={<ImageIcon urlString={project.thumb} />}
                    primaryText={project.name}
                    avatarSize={34}
                    secondaryText={`${projectStatusesMapped[project.status]?.display} ${projectKeyWord}`}
                    {...props}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <SchedulerProjectsList
                                rowResource={resource}
                                onSelect={onSelectProjectEvent}
                                onlyEvents={rowTags.isEventRow}
                                height={dropDownHeight - 20}
                                slideRef={slideRef}
                                selectedIds={project?._id ? [project._id] : []}
                            />
                        </div>
                    ),
                },
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <NewShortProjectOrEvent rowTags={rowTags} onCreate={updateFields} slideRef={slideRef} />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 6,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    icon={<ResourceThumb resource={resource} />}
                    primaryText={currentResourceFullName}
                    secondaryText={resource.role ? roles[resource.role].display : ''}
                    {...props}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    scrollable: false,
                    content: (
                        <div className="px-4">
                            <SchedulerResourcesList
                                onSelect={updateFields}
                                height={dropDownHeight - 20}
                                externalFilterFunction={resourceIsNotNonBookable}
                                slideRef={slideRef}
                                selectedIds={resource?._id ? [resource._id] : []}
                            />
                        </div>
                    ),
                },
                {
                    scrollable: true,
                    content: (
                        <div className="px-4">
                            <NewShortResourceOrUnassigned onCreate={updateFields} slideRef={slideRef} />
                        </div>
                    ),
                },
            ],
        },
        {
            id: 7,
            disabled: isSplitOptionDisabled,
            slides: [
                {
                    content: (
                        <DayPicker
                            className="w-100"
                            firstDayOfWeek={1}
                            disabledDays={{
                                before: addDays(currentSelectedBookingStart, 1),
                                after: currentSelectedBookingEnd,
                            }}
                            month={currentSelectedBookingStart}
                            onDayClick={onSplit}
                        />
                    ),
                },
            ],
            show: false,
        },
        {
            id: 8,
            slides: [
                {
                    scrollable: true,
                    content: (
                        <>
                            <p className="ml-5 mt-1">
                                <strong>Repeat booking</strong>
                            </p>
                            <div className="pl-3 pr-2">
                                <Repeat
                                    inputFieldDisabled={interval?.value === 'NONE'}
                                    inline={false}
                                    intervalAsDropdown
                                    radioInputName="interval"
                                    textInputName="repeatTimes"
                                    formName={FORM_NAME}
                                />
                            </div>
                        </>
                    ),
                },
            ],
            show: false,
        },
    ];

    return (
        <SchedulerResourcesListProvider>
            <SchedulerProjectsListProvider onlyEvents={rowTags.isEventRow}>
                <div className="rounded-0 p-0" style={{ width: `${SCHEDULED_CONTEXT_MENU_WIDTH}px` }}>
                    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
                        <SlideCard
                            ref={slideRef}
                            onClick={dispatchSubmit}
                            actionBtnText="Update Schedule"
                            actionBtnDisabled={submitDisabled}
                            height={dropDownHeight}
                            width={SCHEDULED_CONTEXT_MENU_WIDTH - 2}
                            items={slideCardItems}
                        />
                    </Form>
                </div>
            </SchedulerProjectsListProvider>
        </SchedulerResourcesListProvider>
    );
};

Schedule.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    height: PropTypes.number.isRequired,
    form: PropTypes.string,
};

Schedule.defaultProps = {
    onClose: undefined,
    form: FORM_NAME,
};

export default reduxForm({
    form: FORM_NAME,
    validate: validateSchema(formSchema),
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(Schedule);
