import React from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import { isBefore, parseISO } from 'date-fns';
import { includes } from 'lodash';
import { ALLOWED_ACCESS_TO_OLD_REPORTS } from 'constants';
import { useHasRights } from 'hooks';
import { reportVersions } from 'modules/report/enums/reportVersionEnum';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { hasProjectManagerReportRights } from 'utils/rightsUtil';
import Link from 'shared/link';
import { DropdownButton } from 'shared/dropdownMenu';
import { NoIcon, CheckIcon } from './styles';

const rights = [
    {
        rights: ['manageReports', 'manageMyReports'],
        rule: 'oneOf',
        name: 'hasReportRights',
    },
];

const LAUNCH_DATE = parseISO('2019-10-10', 'yyyy-MM-dd');

const ReportItem = () => {
    const account = useSelector(state => state.account);
    const { creationDate: companyCreationDate, _id: companyId } = useSelector(state => state.companyReducer.company);
    const reportVersion = account.preferences.report
        ? account.preferences.report.version
        : reportVersions.REPORT_VERSION_NEW;
    const { hasReportRights } = useHasRights(rights);
    const { updateReportSettings } = useAccountPreferences();
    const canShowReport = hasReportRights || hasProjectManagerReportRights(account);
    const canSwitchReportsVersion =
        isBefore(new Date(companyCreationDate), LAUNCH_DATE) && includes(ALLOWED_ACCESS_TO_OLD_REPORTS, companyId);

    return (
        canShowReport && (
            <UncontrolledDropdown nav inNavbar>
                {(() => {
                    switch (reportVersion) {
                        case reportVersions.REPORT_VERSION_LEGACY:
                            return <NavLink href="/reports">Reports </NavLink>;
                        default:
                            return (
                                <Link className="nav-link" to="/reports">
                                    Reports{' '}
                                </Link>
                            );
                    }
                })()}
                {canSwitchReportsVersion && (
                    <>
                        <DropdownToggle caret />
                        <DropdownMenu right className="rounded-0 plus-item">
                            <DropdownButton
                                className="px-2"
                                onClick={() => updateReportSettings({ version: reportVersions.REPORT_VERSION_NEW })}
                            >
                                {reportVersion === reportVersions.REPORT_VERSION_NEW ? <CheckIcon /> : <NoIcon />}
                                <span className="d-inline-block">Use New Reports as Default</span>
                            </DropdownButton>
                            <DropdownButton
                                className="px-2"
                                onClick={() => updateReportSettings({ version: reportVersions.REPORT_VERSION_LEGACY })}
                            >
                                {reportVersion === reportVersions.REPORT_VERSION_LEGACY ? <CheckIcon /> : <NoIcon />}
                                <span className="d-inline-block">Use Legacy Reports as Default</span>
                            </DropdownButton>
                        </DropdownMenu>
                    </>
                )}
            </UncontrolledDropdown>
        )
    );
};

export default ReportItem;
