import { SINGLE } from 'modules/scheduler/enums/viewModeEnum';

export const getNewProjectId = (args, row, mode, isProjectView) => {
    let newProjectId = args.e.data.projectId || args.e.data.project?._id;

    if (row.tags.project?._id) {
        newProjectId = row.tags.project?._id;
    }

    if (mode === SINGLE.value && isProjectView) {
        newProjectId = args.newResource;
    }

    return newProjectId;
};

export const getNewResourceId = (args, row, mode, isProjectView) => {
    let newResourceId = args.newResource;

    if (row.tags.resourceId) {
        newResourceId = row.tags.resourceId;
    }

    if (row.tags.resource?._id) {
        newResourceId = row.tags.resource._id;
    }
    if (mode === SINGLE.value && isProjectView) {
        newResourceId = args.e.data.resourceInfo._id;
    }

    return newResourceId;
};

export const countMovingObjects = args => {
    const moveCounter = args.multimove.reduce(
        (acc, multiMoveEntry) => {
            const eventData = multiMoveEntry.event.data;

            if (eventData.phase || eventData.milestone) {
                acc.phasesAndMilestones += 1;
                return acc;
            } else if (eventData.datesEvent) {
                acc.dates += 1;
                return acc;
            } else if (eventData.deadline) {
                acc.deadlines += 1;
                return acc;
            } else if (eventData.repeat) {
                acc.repeatedBookings += 1;
                return acc;
            }

            acc.nonRepeatedBookings += 1;
            return acc;
        },
        {
            nonRepeatedBookings: 0,
            repeatedBookings: 0,
            deadlines: 0,
            phasesAndMilestones: 0,
            dates: 0,
        }
    );

    return {
        moveCounter,
    };
};

