import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Wrapper } from './styles';
import { createCySelector } from 'utils/cypressUtil';
import { viewChanged } from '../../../../actions/menu/content';

const SwitcherMenuItem = ({ icon: Icon, label, view }) => {
    const dispatch = useDispatch();
    const currentView = useSelector(state => state.menuContent.currentView);

    const onViewChange = event => {
        event.preventDefault();
        currentView !== view && dispatch(viewChanged(view));
    };

    return (
        <Wrapper>
            <Link
                className={classNames({ active: view === currentView })}
                to="#"
                onClick={onViewChange}
                data-sidebar-view={view}
                data-cy={`menu--${createCySelector(label)}`}
            >
                <div>
                    <Icon />
                    <span>{label}</span>
                </div>
            </Link>
        </Wrapper>
    );
};

export default SwitcherMenuItem;
