import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { TYPE_REGULAR } from 'enums/projectEnum';
import { showProjectModal, showResourceModal, showBulkAddModal } from 'actions/modalActions';
import { createResource } from 'actions/resourceActions';
import { createProject } from 'actions/projectActions';
import { useClickOutside } from 'hooks';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    paper: {
        borderRadius: '4px',
        minWidth: '150px',
        padding: '5px 0',
        border: '1px solid transparent',
        boxShadow: '0 2px 6px 2px rgba(60, 64, 67, 0.15)',
        zIndex: 9,
    },
    list: {
        padding: 0,
    },
});


const AddNewItemContextMenu = ({ type, close, top, left, ...rest }) => {
    const classes = useStyles();
    const node = useRef();
    const dispatch = useDispatch();
    const handler = useCallback(
        event => {
            if (node.current && !node.current.contains(event.target)) {
                close();
            }
        },
        [close]
    );

    useClickOutside(handler);

    const showModal = () => {
        close();
        'RESOURCE' === type
            ? dispatch(showResourceModal({ onSubmit: data => dispatch(createResource.request(data, undefined, true)) }))
            : dispatch(
                  showProjectModal({
                      onSubmit: data => dispatch(createProject.request(data, undefined, true)),
                      type: TYPE_REGULAR.value,
                  })
              );
    };

    const showBulkAdd = () => {
        close();
        dispatch(showBulkAddModal(type));
    };

    let keyWord = rest.projectKeyWord;
    let pluralKeyWord = rest.projectPluralKeyWord;

    if ('RESOURCE' === type) {
        keyWord = rest.resourceKeyWord;
        pluralKeyWord = rest.resourcePluralKeyWord;
    }

    return (
        <Menu
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            keepMounted
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <div>
                <MenuItem onClick={showBulkAdd}>Bulk add {pluralKeyWord.toLowerCase()}...</MenuItem>
                <MenuItem onClick={showModal}>Add single {keyWord.toLowerCase()}</MenuItem>
            </div>
        </Menu>
    );
};

AddNewItemContextMenu.propTypes = {
    type: PropTypes.oneOf(['RESOURCE', 'PROJECT']).isRequired,
    projectPluralKeyWord: PropTypes.string.isRequired,
    projectKeyWord: PropTypes.string.isRequired,
    resourceKeyWord: PropTypes.string.isRequired,
    resourcePluralKeyWord: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
};

export default AddNewItemContextMenu;
