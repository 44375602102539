import React, { useEffect, useState } from 'react';
import { TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { connect, useDispatch } from 'react-redux';
import { formValueSelector, getFormSyncErrors, hasSubmitFailed } from 'redux-form';
import { makeGetFilteredProjects } from 'selectors/project';
import { makeGetResourceGroups } from 'selectors/resourceGroup';
import { getDefaultValues } from 'forms/resourceForm';
import ResourceModal, { FORM_NAME } from './components/modal';
import { selectAvailableCalendars, selectWeekDaysSetting } from '../../../selectors/company';
import { getResourceRequest } from '../../../api/resource';
import { monitoring } from '../../../monitoring';
import { addNotification } from '../../../actions/notificationActions';
import {selectDefaultToilPolicy, selectToilPoliciesMapById} from '../../toil/store/selectors';

const selector = formValueSelector(FORM_NAME);
const getFilteredProjects = makeGetFilteredProjects('EXCLUDE_ARCHIVED_TYPE_REGULAR');
const getFilteredResourceGroups = makeGetResourceGroups('TYPE_USER_OR_SCHEDULE_AND_NOT_SMART');

const useResource = resourceId => {
    const dispatch = useDispatch();
    const [resource, setResource] = useState({});

    useEffect(() => {
        let mounted = true;

        if (resourceId) {
            getResourceRequest(resourceId)
                .then(resource => {
                    if (mounted) {
                        setResource(resource);
                    }
                })
                .catch(error => {
                    if (mounted) {
                        monitoring.captureException(error);
                        dispatch(
                            addNotification({ message: "We couldn't load data for this resource", type: 'danger' })
                        );
                    }
                });
        }

        return () => {
            mounted = false;
        };
    }, [dispatch, resourceId]);

    return resource;
};

const Connected = connect((state, ownProps) => {
    const companySettings = state.companyReducer.company.settings;
    const weekDays = selectWeekDaysSetting(state);
    const calendars = selectAvailableCalendars(state);
    const projects = getFilteredProjects(state);
    const resourceGroups = getFilteredResourceGroups(state);
    const policiesMap = selectToilPoliciesMapById(state);
    const defaultToilPolicy = selectDefaultToilPolicy(state);

    return {
        resourceCustomFields: state.customFieldReducer.resourceCustomFields,
        projects,
        resourceTags: state.tagReducer.resourceTags,
        resourceGroups,
        resourceKeyWord:
            ownProps.type !== TYPE_UNASSIGNED.value
                ? companySettings.keyword.keyword2Singular
                : TYPE_UNASSIGNED.display,
        hasSubmitFailed: hasSubmitFailed(FORM_NAME)(state),
        thumb: selector(state, 'thumb'),
        firstName: selector(state, 'firstName'),
        useResourceAvailability: selector(state, 'useResourceAvailability'),
        formSyncErrors: getFormSyncErrors(FORM_NAME)(state),
        resourceRates: selector(state, 'resourceRates'),
        iconLinks: selector(state, 'iconLink1', 'iconLink2', 'iconLink3', 'iconLink4', 'iconLink5'),
        initialValues: getDefaultValues(
            projects,
            resourceGroups,
            ownProps.resource,
            calendars,
            weekDays,
            companySettings.globalVacationAllocationLimit,
            {policiesMap, defaultToilPolicy}
        ),
    };
})(ResourceModal);

const WrappedResourceModal = props => {
    const resource = useResource(props.resourceId);

    return <Connected resource={resource} {...props} />;
};

export default WrappedResourceModal;
