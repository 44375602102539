import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { MoreVert } from '@material-ui/icons';
import { NavLink } from 'reactstrap';
import { isFunction, isObject } from 'lodash';
import { showGroupContextMenu } from 'actions/contextMenuActions';
import { GroupItemWrapper, SubGroupIcon, Label } from './styles';
import { useHasRights } from 'hooks';
import { createCySelector } from 'utils/cypressUtil';
import { getProjectGroupProjects } from '../../../../actions/projectGroupActions';
import { getResourceGroupResources } from '../../../../actions/resourceGroupActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    menuItemText: {
        whiteSpace: 'pre-wrap',
        marginLeft: '5px',
    },
});

const rights = [
    {
        rights: ['settingAddNewEditProjectGroups'],
        rule: 'one',
        name: 'canEditProjectGroup',
    },
    {
        rights: ['settingAddNewEditResourceGroups'],
        rule: 'one',
        name: 'canEditResourceGroup',
    },
];

const GroupItem = props => {
    const {
        id,
        active,
        className,
        classNameActive,
        classStore,
        icon: Icon,
        label,
        group,
        toggleSubMenu,
        hasSubMenu,
        subMenuVisibility,
        itemsCount,
        subGroupsCount,
    } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const isTypeProject = group.hasOwnProperty('projects');
    const { canEditProjectGroup, canEditResourceGroup } = useHasRights(rights);
    const canEdit = (isTypeProject && canEditProjectGroup) || (!isTypeProject && canEditResourceGroup);
    const showContextMenu = () => {
        dispatch(showGroupContextMenu(group));
    };

    const handleClick = useCallback(
        event => {
            toggleSubMenu(event);
            if (subMenuVisibility) {
                return;
            }
            if (isTypeProject) {
                dispatch(getProjectGroupProjects.request(id));
            } else {
                dispatch(getResourceGroupResources.request(id));
            }
        },
        [dispatch, id, isTypeProject, subMenuVisibility, toggleSubMenu]
    );

    return (
        <GroupItemWrapper data-cy={`menu--group-item--${createCySelector(label)}`}>
            <NavLink
                href="#"
                className={classNames(className, active && classNameActive)}
                onClick={handleClick}
                data-group-id={id}
                data-cy={`menu--group-item--link--${createCySelector(label)}`}
            >
                {(isFunction(Icon) || isObject(Icon)) && <Icon className={classStore.classIcon} />}
                <span className="groups-count">
                    {itemsCount}
                    {0 < subGroupsCount && (
                        <>
                            {' '}
                            | <SubGroupIcon /> {subGroupsCount}
                        </>
                    )}
                </span>
                <Label className={classNames(classes.menuItemText, { subgroups: 0 < subGroupsCount })} title={label}>
                    {label}
                </Label>
                {hasSubMenu && (
                    <i
                        className={classNames(
                            classStore.classStateIcon,
                            classStore.iconNamePrefix +
                                (subMenuVisibility ? classStore.iconNameStateVisible : classStore.iconNameStateHidden)
                        )}
                    />
                )}
            </NavLink>
            {canEdit && (
                <div
                    className="group-context-menu"
                    onClick={showContextMenu}
                    data-cy={`menu--group-item--context-menu--${createCySelector(label)}`}
                >
                    <MoreVert />
                </div>
            )}
        </GroupItemWrapper>
    );
};

GroupItem.propTypes = {
    group: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
    subGroupsCount: PropTypes.number,
};

GroupItem.defaultProps = {
    subGroupsCount: 0,
};

export default GroupItem;
