import React from 'react';
import { Link } from 'react-router-dom';
import { Chat } from '@material-ui/icons';
import { Wrapper, Separator } from './styles';

const SuggestReportItem = ({ id, ...props }) => (
    <Wrapper>
        {props.hasRights ? (
            <Link to="#" onClick={props.showSuggestReportModal}>
                <Chat />
                <span>Suggest a Report ...</span>
            </Link>
        ) : (
            <Separator style={{ height: '50px' }} id={id} />
        )}
    </Wrapper>
);

export default SuggestReportItem;
