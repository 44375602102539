import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, Button } from 'reactstrap';
import { multiSelectField } from 'shared/formFields';
import CreateTagForm from './create';

class Tag extends PureComponent {
    static propTypes = {
        tags: PropTypes.array.isRequired,
        formName: PropTypes.string,
        fieldName: PropTypes.string,
        category: PropTypes.oneOf(['PROJECT', 'RESOURCE']).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = { isFormOpen: false };
    }

    toggleForm = () => this.setState(state => ({ isFormOpen: !state.isFormOpen }));

    render() {
        const { isFormOpen } = this.state;
        const { tags, formName, fieldName, category } = this.props;

        return (
            <section className="form-fields">
                {isFormOpen ? (
                    <CreateTagForm
                        formName={formName}
                        fieldName={fieldName}
                        category={category}
                        onDismiss={this.toggleForm}
                    />
                ) : (
                    <div>
                        <p className="title">Add Tags:</p>
                        <Field
                            inline
                            width="col-md-12"
                            name="tags"
                            component={multiSelectField}
                            caseSensitive={false}
                            dropUp
                            valueField="_id"
                            filter="contains"
                            textField={item => (Object.keys(item).length ? item.value : '')}
                            data={tags}
                        />
                        <FormText>
                            Type to Enter a Tag. Press or Enter to add a new tag if this option is available&nbsp;
                            <Button onClick={this.toggleForm} className="xs p-0" color="link">
                                Add new Tag
                            </Button>
                        </FormText>
                    </div>
                )}
            </section>
        );
    }
}

export default Tag;
