import { mapObject } from 'underscore';
import moize from 'moize';

export const UTILIZATION_STATUSES_ENUM = {
    OVER: 'OVER',
    UNDER: 'UNDER',
    ON: 'ON',
    properties: {
        OVER: { name: 'Over' },
        UNDER: { name: 'Under' },
        ON: { name: 'On track' },
    },
};

export const UTILIZATION_STATUSES_NAME_MAP = {
    'Over': UTILIZATION_STATUSES_ENUM.OVER,
    'Under': UTILIZATION_STATUSES_ENUM.UNDER,
    'On track': UTILIZATION_STATUSES_ENUM.ON,
}

export const statusesList = moize(() => {
    let statuses = [];

    mapObject(UTILIZATION_STATUSES_ENUM, (value, key) => {
        'properties' !== key &&
            statuses.push({
                name: UTILIZATION_STATUSES_ENUM['properties'][value].name,
                value,
            });
    });

    return statuses;
});
