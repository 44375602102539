import { useCallback, useMemo } from 'react';
import { pick, includes } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { updateAccountPreferences } from 'actions/accountActions';
import { useIsExtensionInstalled } from 'hooks';
import { CUSTOM_COLUMNS } from 'enums/extensionShortIdEnum';
import { findByValue, findByCustomSearchId } from 'modules/scheduler/enums/scale';
import { SINGLE, LEGACY, PARENT } from 'modules/scheduler/enums/viewModeEnum';
import {
    dispatchRefreshDPResourcesEvent,
    getTimeHeaders,
    updateDeadlinesVisibility,
    updateVisibleLinksOnScheduler,
} from 'modules/scheduler/utils/schedulerUtil';
import { getBubble } from 'modules/scheduler/config/daypilot';
import { getFormattedColumnsToDp, getCustomColumnsType } from 'modules/scheduler/utils/customColumns';
import { makeGetFilteredCustomFields } from 'selectors/customField';
import { makeGetTags } from 'selectors/tag';
import { CUSTOM_FIELDS } from 'modules/scheduler/enums/customColumnTypeEnum';
import moment from 'moment';
import { setSchedulerSelection } from 'actions/schedulerActions';
import { selectSubGroupsPosition } from '../../../selectors/account';
import { bookingLinksOptionsKeys } from '../enums/bookingLinkEnums';
import { deadlineOptionsKeys } from '../enums/deadlines';
import { selectMeanWorkdayDuration } from '../../../selectors/company';

const preferencesToUpdateInScheduler = [
    'availabilityDisplay',
    'eventHeight',
    'availabilityHover',
    'displayMilestoneText',
    'displayWeeks',
    'eventHeightMode',
    'bookingLinkStyle',
    'bookingLinkPosition',
    'bookingLinkColor',
    'bookingLinkDisplay',
    'deadlineLinkStyle',
    'deadlineLinkPosition',
    'deadlineLinkColor',
    'deadlineColor',
    'deadlineDisplay',
    'deadlineTooltipDisplay',
];

const getDataToUpdate = (newPreferences, oldPreferences, viewObject, data, schedulerRef) => {
    let newData = {};
    const { companyTimeData, schedulerColumns, isCustomColumnsExtensionActive, tags, customFields } = data;
    const availabilityDisplay = newPreferences.availabilityDisplay || oldPreferences.availabilityDisplay;
    if (newPreferences.availabilityDisplay || newPreferences.eventHeight) {
        newData.rowMinHeight =
            (newPreferences.eventHeight || oldPreferences.eventHeight) +
            (includes(['TYPE_NOTIFICATION', 'TYPE_AVAILABILITY'], availabilityDisplay) ? 4 : 14);
        newData.rowMarginTop =
            (viewObject.isProjectView && oldPreferences.mode === SINGLE.value) ||
            !includes(['TYPE_NOTIFICATION', 'TYPE_AVAILABILITY'], availabilityDisplay)
                ? 0
                : 20;
    }

    if (newPreferences.defaultScale) {
        const viewportStartDate = schedulerRef?.current?.control?.getViewPort().start;
        const shouldKeepCurrentStartDate = !newPreferences.customSearchId && viewportStartDate;

        const scale = newPreferences.customSearchId
            ? findByCustomSearchId(newPreferences.customSearchId)
            : findByValue(newPreferences.defaultScale);
        newData.days = scale.daysByMultiplier(
            oldPreferences.cellWidth[newPreferences.defaultScale],
            data.meanWorkDayDuration
        );
        newData.scale = newPreferences.defaultScale;
        newData.infiniteScrollingStepDays = scale.scrollingStepDays(
            oldPreferences.cellWidth[newPreferences.defaultScale],
            data.meanWorkDayDuration
        );
        newData.cellWidth = oldPreferences.cellWidth[newPreferences.defaultScale];
        newData.startDate = shouldKeepCurrentStartDate ? viewportStartDate : scale.startDate(data.weekStartDay);
        newData.timeHeaders = getTimeHeaders(scale, oldPreferences.displayWeeks, companyTimeData);
    }

    if (newPreferences.cellWidth) {
        const scale = findByValue(oldPreferences.defaultScale);
        newData.cellWidth = newPreferences.cellWidth[oldPreferences.defaultScale];
        newData.days = scale.daysByMultiplier(
            newPreferences.cellWidth[oldPreferences.defaultScale],
            data.meanWorkDayDuration
        );
        newData.infiniteScrollingStepDays = scale.scrollingStepDays(
            newPreferences.cellWidth[oldPreferences.defaultScale],
            data.meanWorkDayDuration
        );
    }

    if (newPreferences.hasOwnProperty('displayWeeks')) {
        const scale = findByValue(oldPreferences.defaultScale);
        newData.timeHeaders = getTimeHeaders(scale, newPreferences.displayWeeks, companyTimeData);
    }
    if (newPreferences.mode) {
        if (newPreferences.mode === PARENT.value || oldPreferences.mode === PARENT.value) {
            newData.events = [];
        }
        newData.mode = newPreferences.mode;
        newData.rowMarginTop =
            (viewObject.isProjectView && newPreferences.mode === SINGLE.value) ||
            !includes(['TYPE_NOTIFICATION', 'TYPE_AVAILABILITY'], availabilityDisplay)
                ? 0
                : 20;
        newData.customColumnsType = getCustomColumnsType(newPreferences.mode, viewObject);
        newData.rowHeaderColumns = getFormattedColumnsToDp(
            viewObject.isResourceView || LEGACY.value === newPreferences.mode ? 'resource' : 'project',
            schedulerColumns,
            isCustomColumnsExtensionActive,
            {
                tags,
                customFields,
            }
        );
    }

    newPreferences.hasOwnProperty('groupBookings') && (newData.groupConcurrentEvents = newPreferences.groupBookings);
    newPreferences.groupBookingsLimit && (newData.groupConcurrentEventsLimit = newPreferences.groupBookingsLimit);
    newPreferences.bookingToolTipHoverDelay && (newData.bubble = getBubble({ ...oldPreferences, ...newPreferences }));

    return { ...newData, ...pick(newPreferences, preferencesToUpdateInScheduler) };
};

export const useAccountPreferences = (schedulerRef, viewObject) => {
    const dispatch = useDispatch();
    const isCustomColumnsExtensionActive = useIsExtensionInstalled(CUSTOM_COLUMNS);
    const { report: reportPreferences, grid: gridPreferences, schedulerColumns } = useSelector(
        state => state.account.preferences
    );
    const subGroupsPositionSetting = useSelector(selectSubGroupsPosition);
    const customFieldSelector = useMemo(() => makeGetFilteredCustomFields(), []);
    const tagsSelector = useMemo(() => makeGetTags(), []);
    const companyStartEndTimes = useSelector(state => state.companyReducer.startEndTimes);
    const tags = useSelector(tagsSelector);
    const companySettings = useSelector(state => state.companyReducer.company.settings);

    const meanWorkDayDuration = useSelector(selectMeanWorkdayDuration);

    const {
        slotMinutes: companySlotMinutes,
        weekStartDay,
        weekDays,
        grid: { hideNonWorkingDays },
    } = companySettings;

    const customFields = useSelector(customFieldSelector);

    const updateGridPreferences = useCallback(
        data => {
            const dataToUpdate = getDataToUpdate(
                data,
                gridPreferences,
                viewObject,
                {
                    companyTimeData: { ...companyStartEndTimes, slotMinutes: companySlotMinutes },
                    schedulerColumns,
                    isCustomColumnsExtensionActive,
                    tags,
                    customFields,
                    weekStartDay,
                    meanWorkDayDuration,
                },
                schedulerRef
            );

            if (data.customSearchId) {
                const weekDaysArray = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
                const validWeekDays = !!weekDays;
                let daysDisplayedInDp;

                const dpWidth = document.querySelector('.hub_scrollable')?.clientWidth;
                if (dpWidth && data.customSearchId === 'this_week') {
                    daysDisplayedInDp = 7;
                    if (hideNonWorkingDays && validWeekDays) {
                        daysDisplayedInDp = weekDaysArray.filter(weekDay => !!weekDays[weekDay].workDay).length;
                    }
                } else if (dpWidth && data.customSearchId === 'this_month') {
                    const daysInMonth = moment(dataToUpdate.startDate).daysInMonth();
                    daysDisplayedInDp = daysInMonth;
                    if (hideNonWorkingDays && validWeekDays) {
                        // It has 28 days for sure
                        daysDisplayedInDp = weekDaysArray.filter(weekDay => !!weekDays[weekDay].workDay).length * 4;
                        // Calculate the other potentially 3
                        if (daysInMonth > 28) {
                            const howMany = daysInMonth - 28;
                            const nameOfFirst = moment(dataToUpdate.startDate)
                                .format('dddd')
                                .toLowerCase();

                            const indexOfFirstDayAfter = weekDaysArray.findIndex(day => day === nameOfFirst);
                            const nameOfSecond =
                                howMany > 1
                                    ? weekDaysArray[(indexOfFirstDayAfter + 1) % weekDaysArray.length]
                                    : undefined;
                            const nameOfThird =
                                howMany > 2
                                    ? weekDaysArray[(indexOfFirstDayAfter + 2) % weekDaysArray.length]
                                    : undefined;
                            const nextDays = [nameOfFirst, nameOfSecond, nameOfThird];

                            daysDisplayedInDp =
                                daysDisplayedInDp +
                                nextDays.filter(nextDay => nextDay && !!weekDays[nextDay].workDay).length;
                        }
                    }
                }
                if (daysDisplayedInDp) {
                    dataToUpdate.cellWidth = dpWidth / daysDisplayedInDp;
                }
            }

            dispatch(
                updateAccountPreferences.request(
                    {
                        grid: { ...gridPreferences, ...data },
                    },
                    dataToUpdate,
                    () => {
                        schedulerRef?.current?.control?.update &&
                            dataToUpdate.startDate &&
                            schedulerRef?.current?.control?.scrollTo(dataToUpdate.startDate);

                        if (
                            Object.keys(dataToUpdate).some(
                                key => bookingLinksOptionsKeys[key] || deadlineOptionsKeys[key]
                            )
                        ) {
                            updateVisibleLinksOnScheduler();
                        }

                        if (Object.keys(dataToUpdate).some(key => key === deadlineOptionsKeys.deadlineDisplay)) {
                            updateDeadlinesVisibility();
                        }
                    }
                )
            );
            dispatch(setSchedulerSelection.request());
        },
        [
            gridPreferences,
            viewObject,
            companyStartEndTimes,
            companySlotMinutes,
            schedulerColumns,
            isCustomColumnsExtensionActive,
            tags,
            customFields,
            weekStartDay,
            meanWorkDayDuration,
            schedulerRef,
            dispatch,
            weekDays,
            hideNonWorkingDays,
        ]
    );

    const updateSchedulerColumns = useCallback(
        (type, data, callback) => {
            const updatedSchedulerColumns = {
                ...schedulerColumns,
                [`${type}Columns`]: {
                    ...schedulerColumns[`${type}Columns`],
                    ...data,
                },
            };

            let newSchedulerConfig = {};

            if (schedulerRef && schedulerRef.current && schedulerRef.current.control) {
                newSchedulerConfig = {
                    schedulerColumns: updatedSchedulerColumns,
                    rowHeaderColumns: getFormattedColumnsToDp(
                        type,
                        updatedSchedulerColumns,
                        isCustomColumnsExtensionActive,
                        {
                            tags,
                            customFields,
                        }
                    ),
                };

                callback && callback();
            }

            const checkCallback = () => {
                // Trigger with force only if new custom column based on CF added/changed (other data than CF is available, and network call is not required)
                const newCustomColumns = (updatedSchedulerColumns?.[`${type}Columns`]?.customColumns || [])
                    .filter(c => c.columnType === CUSTOM_FIELDS.value)
                    .map(c => pick(c, ['columnValue']).columnValue);
                const previousCustomColumns = (schedulerColumns?.[`${type}Columns`]?.customColumns || [])
                    .filter(c => c.columnType === CUSTOM_FIELDS.value)
                    .map(c => pick(c, ['columnValue']).columnValue);

                if (newCustomColumns.length < previousCustomColumns.length) {
                    return;
                }

                const force = JSON.stringify(newCustomColumns) !== JSON.stringify(previousCustomColumns);
                dispatchRefreshDPResourcesEvent({ force });
            };

            dispatch(
                updateAccountPreferences.request(
                    {
                        schedulerColumns: updatedSchedulerColumns,
                    },
                    newSchedulerConfig,
                    checkCallback
                )
            );
        },
        [dispatch, schedulerColumns, isCustomColumnsExtensionActive, tags, customFields, schedulerRef]
    );

    const updateReportSettings = useCallback(
        data => {
            dispatch(
                updateAccountPreferences.request({
                    report: { ...reportPreferences, ...data },
                })
            );
        },
        [dispatch, reportPreferences]
    );

    const updateSubGroupsPositionSetting = useCallback(
        subGroupsPosition => {
            dispatch(
                updateAccountPreferences.request({
                    subGroupsPosition,
                })
            );
        },
        [dispatch]
    );

    return {
        gridPreferences,
        schedulerColumns,
        updateGridPreferences,
        updateSchedulerColumns,
        updateReportSettings,
        updateSubGroupsPositionSetting,
        subGroupsPositionSetting,
    };
};
