import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Delete } from '@material-ui/icons';
import { Button } from 'reactstrap';

const SmallRemoveButton = ({ icon, onClick, disabled, fixed, className, ...rest }) => (
    <Button
        color="danger"
        disabled={disabled}
        onClick={onClick}
        className={classNames('sm rounded-0', { fixed: fixed }, className)}
        {...rest}
    >
        <span>{icon || <Delete fontSize="small" />}</span>
    </Button>
);

SmallRemoveButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    fixed: PropTypes.bool,
    icon: PropTypes.element,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SmallRemoveButton.defaultProps = {
    onClick: null,
    disabled: false,
    icon: null,
    fixed: false,
};

export default SmallRemoveButton;
