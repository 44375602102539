import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    Video_main: {
        width: '100%',
    },
});

export const Video = React.memo(props => {
    const { videoPosterSrc, videoSrc, preload } = props;

    const classes = useStyles();
    return (
        <video
            className={classes.Video_main}
            controls={true}
            controlsList="nodownload"
            preload={preload}
            poster={videoPosterSrc}
            autoPlay={true}
            src={videoSrc}
        />
    );
});

export const propTypes = {
    videoPosterSrc: PropTypes.string,
    videoSrc: PropTypes.string,
    videoType: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    preload: PropTypes.string,
};

Video.defaultProps = {
    videoPosterSrc: '',
    controls: false,
    preload: 'auto',
};

Video.propTypes = propTypes;

export default Video;
