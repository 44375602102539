import { contains, each, filter, find, keys } from 'underscore';
import BaseMenu from 'modules/sidebar/services/menuBuilder/BaseMenu';
import { hasExtensionsInstalled } from 'utils/extensionUtil';
import { hasAllRoles, hasRole, hasProjectManagerReportRights, hasOneOfRoles } from 'utils/rightsUtil';
import HomeMenuItem from './../components/menu/homeMenuItem';
import SeparatorItem from 'modules/menu/components/seperatorMenuItem';
import MenuItem from 'modules/report/containers/menu/menuItem';
import SuggestReportItem from 'modules/report/containers/menu/suggestReportItem';
import BuilderMenuItem from 'modules/report/components/menu/builderMenuItem';
import {
    PROJECT_REPORT,
    BILLING_REPORT,
    GROUP_REPORT,
    CUSTOM_REPORT,
    CUSTOMER_REPORT,
    reportTypes,
    HEAT_MAP_REPORT,
} from 'modules/report/enums/reportTypeEnum';
import { CUSTOM, SYSTEM } from 'modules/report/enums/templateTypeEnum';
import { PROJECT } from 'modules/report/enums/groupingTypeEnum';
import {
    AttachMoney,
    Folder,
    Build,
    ColorLens,
    EventNote,
    Favorite,
    Group,
    Lock,
    NaturePeople,
    PeopleOutline,
    PermDataSetting,
    Person,
    Public,
    TrendingUp,
    Face,
    Apps,
} from '@material-ui/icons';
import MenuItemWithGroups from '../components/menu/menuItemWithGroups';
import ReportsGroupMenuItem from '../components/menu/reportsGroupMenuItem';

const templatesOrder = {
    ASC: (a, b) => a?.label?.localeCompare(b?.label),
    DESC: (a, b) => b?.label?.localeCompare(a?.label),
};

/**
 * Each Menu Class need to have a items() function
 */

const getReportsGroupContent = (type, group, templatesRenderer, reportsById) => {
    const groupReportsById = (group.reports || []).reduce((acc, item) => {
        acc[item] = item;

        delete reportsById[item];

        return acc;
    }, {});

    const groupContent = templatesRenderer(type, group._id, groupReportsById, {
        level: 4,
        orderBy: group.sortBy,
        order: group.sortOrder,
    });

    return [
        ...(groupContent.length
            ? [
                  {
                      label: 'All report templates',
                      icon: Apps,
                      to: `/reports/templates/${type.toLowerCase()}/group/${group._id}`,
                      itemClass: 'tree-2',
                  },
              ]
            : []),
        ...groupContent,
    ];
};
class ReportsMenu extends BaseMenu {
    setProps(reportTemplates, account, keyWords, extensions, reportsGroups) {
        this.reportTemplates = reportTemplates || [];
        this.reportsGroups = reportsGroups || [];
        this.account = account || [];
        this.keyWords = keyWords || {};
        this.extensions = extensions || [];
        this.replaceMap = {
            project: keyWords.projectKeyWord || '',
            Project: keyWords.projectKeyWord || '',
            resource: keyWords.resourceKeyWord || '',
            Resource: keyWords.resourceKeyWord || '',
            projects: keyWords.projectPluralKeyWord || '',
            Customer: keyWords.customerKeyWord || '',
        };
        this.findRegex = new RegExp(keys(this.replaceMap).join('|'), 'gi');

        return this;
    }

    filterReportTemplates(filterType, reportIds) {
        switch (filterType) {
            case 'INTERNAL':
                return filter(
                    this.reportTemplates,
                    template =>
                        'INTERNAL' === template.visibility &&
                        CUSTOM.value === template.templateType &&
                        (reportIds ? reportIds[template._id] : true)
                );
            case 'SAVED':
                return filter(
                    this.reportTemplates,
                    template =>
                        template.owner === this.account.resourceId &&
                        'PRIVATE' === template.visibility &&
                        CUSTOM.value === template.templateType &&
                        (reportIds ? reportIds[template._id] : true)
                );
            case 'FAVOURITES':
                return filter(this.reportTemplates, template =>
                    contains(template.asFavourite, this.account.resourceId)
                );
            default:
                return this.reportTemplates;
        }
    }

    renderTemplates(subType, groupId, reportIds, options = {}) {
        const { level = 2, orderBy, order } = options;
        const currentSubType = find(CUSTOM_REPORT.subTypes, reportSubType => reportSubType.value === subType);
        const templateItems = [];
        const filteredTemplates = this.filterReportTemplates(currentSubType.value, reportIds);
        each(filteredTemplates, template => {
            templateItems.push({
                label: template.name,
                templateId: template._id,
                itemClass: groupId ? `reports-group-item tree-${level}` : `tree-${level}`,
                to: `/reports/templates/${currentSubType.route}/${template._id}${groupId ? `/group/${groupId}` : ''}`,
                customItem: MenuItem,
                inputProps: {
                    groupId,
                    deleteIcon: true,
                    template: template,
                    deleteTitle: currentSubType.deleteTitle,
                    deleteMessage: currentSubType.deleteMessage,
                    subType: currentSubType.value,
                },
            });
        });

        if (orderBy === 'NAME') {
            templateItems.sort(templatesOrder[order]);
        }

        templateItems.length &&
            !reportIds &&
            !['SAVED', 'INTERNAL'].includes(subType) &&
            templateItems.unshift({
                label: 'All report templates',
                icon: Apps,
                to: `/reports/templates/${currentSubType.route}`,
                itemClass: 'tree-2',
            });

        return templateItems;
    }

    renderSubTypes(reportType, filterBy) {
        const subTypeItems = !contains(
            [BILLING_REPORT.value, GROUP_REPORT.value, CUSTOMER_REPORT.value, HEAT_MAP_REPORT.value],
            reportType.value
        )
            ? [
                  {
                      label: 'All report templates',
                      icon: Apps,
                      to: `/reports/${reportType.route}`,
                      itemClass: 'tree-2',
                      dataCy: `${reportType.dataCy}-menu-item`
                  },
              ]
            : [];
        const filtered = filter(reportType.subTypes, report =>
            filterBy && report.type ? report.type === filterBy : true
        );

        each(filtered, subType => {
            let dbTemplate = find(
                this.reportTemplates,
                template =>
                    template.type === reportType.value &&
                    template.subType === subType.value &&
                    template.groupBy === subType.groupBy &&
                    template.itemType === subType.itemType &&
                    SYSTEM.value === template.templateType
            );

            if (dbTemplate) {
                subTypeItems.push({
                    label: subType.display.replace(this.findRegex, matched => this.replaceMap[matched]),
                    itemClass: filterBy ? 'tree-3' : 'tree-2',
                    icon: null,
                    customItem: MenuItem,
                    dataCy: subType.dataCy,
                    display:
                        (!dbTemplate.extensions || hasExtensionsInstalled(this.extensions, dbTemplate.extensions)) &&
                        (!subType.roleRights ||
                            (subType.roleRights && hasAllRoles(this.account.resourceRoleRights, subType.roleRights))),
                    inputProps: {
                        id:
                            reportType.value === PROJECT_REPORT.value &&
                            'SCHEDULED_TIME' === subType.value &&
                            PROJECT.name === subType.groupBy
                                ? 'report-tour-generate'
                                : '',
                        template: dbTemplate,
                        settingIcon:
                            hasRole(this.account.resourceRoleRights, 'manageReports') ||
                            hasProjectManagerReportRights(this.account),
                    },
                    to: `/reports/${reportType.route}/${subType.route}`,
                });
            }
        });

        return subTypeItems;
    }

    items() {
        const isProjectManager = hasProjectManagerReportRights(this.account);
        const hasManageReportsRights = hasRole(this.account.resourceRoleRights, 'manageReports');
        const showProjectReports =
            hasAllRoles(this.account.resourceRoleRights, reportTypes.PROJECT_REPORT.roleRights) ||
            reportTypes.PROJECT_REPORT.additionalRights(this.account);
        const showResourceReports =
            hasAllRoles(this.account.resourceRoleRights, reportTypes.RESOURCE_REPORT.roleRights) ||
            reportTypes.RESOURCE_REPORT.additionalRights(this.account);
        const showBillingReports =
            hasAllRoles(this.account.resourceRoleRights, reportTypes.BILLING_REPORT.roleRights) ||
            reportTypes.BILLING_REPORT.additionalRights(this.account);
        const showUtilityReports = hasAllRoles(this.account.resourceRoleRights, reportTypes.UTILITY_REPORT.roleRights);
        const showGroupReports = hasAllRoles(this.account.resourceRoleRights, reportTypes.GROUP_REPORT.roleRights);
        const showEventReports = hasAllRoles(this.account.resourceRoleRights, reportTypes.EVENT_REPORT.roleRights);
        const showCustomerReports =
            hasAllRoles(this.account.resourceRoleRights, reportTypes.CUSTOMER_REPORT.roleRights) ||
            reportTypes.CUSTOMER_REPORT.additionalRights(this.account);
        const showMyReports =
            hasOneOfRoles(this.account.resourceRoleRights, reportTypes.MY_REPORTS.roleRights) ||
            reportTypes.MY_REPORTS.additionalRights(this.account);
        const showUnassignedReports =
            hasExtensionsInstalled(this.extensions, reportTypes.UNASSIGNED_REPORT.extensions) &&
            hasAllRoles(this.account.resourceRoleRights, reportTypes.UNASSIGNED_REPORT.roleRights);

        const subGroups = (this.reportsGroups ?? []).reduce((acc, group) => {
            acc[group.visibility] = [...(acc[group.visibility] || []), group];

            return acc;
        }, {});

        const savedReportsById = (this.renderTemplates('SAVED') ?? []).reduce((acc, report) => {
            acc[report.templateId] = report;

            return acc;
        }, {});

        const internalReportsById = (this.renderTemplates('INTERNAL') ?? []).reduce((acc, report) => {
            acc[report.templateId] = report;

            return acc;
        }, {});

        const savedReports = Object.values(savedReportsById);
        const internalReports = Object.values(internalReportsById);

        return [
            {
                customItem: HomeMenuItem,
                currentClassItem:
                    hasRole(this.account.resourceRoleRights, 'manageReports') || isProjectManager
                        ? 'menu-top-actions'
                        : 'menu-top-actions menu-top-actions-single',
                dataCy: 'home-menu-item',
            },
            {
                customItem: BuilderMenuItem,
                display: hasRole(this.account.resourceRoleRights, 'manageReports') || isProjectManager,
                currentClassItem: 'menu-top-actions',
                dataCy: 'builder-menu-item',
            },
            {
                customItem: SeparatorItem,
                display:
                    showProjectReports ||
                    showResourceReports ||
                    showBillingReports ||
                    showUtilityReports ||
                    showGroupReports ||
                    showEventReports ||
                    showUnassignedReports ||
                    showCustomerReports,
                inputProps: {
                    text: 'TEMPLATES',
                },
            },
            {
                label: reportTypes.PROJECT_REPORT.display.replace(this.findRegex, matched => this.replaceMap[matched]),
                display: showProjectReports,
                content: this.renderSubTypes(reportTypes.PROJECT_REPORT, null),
                icon: EventNote,
                subMenuVisibility: !this.account.preferences.report.tour.viewed,
                dataCy: 'project-report-menu-item',
            },
            {
                label: reportTypes.RESOURCE_REPORT.display.replace(this.findRegex, matched => this.replaceMap[matched]),
                display: showResourceReports,
                content: this.renderSubTypes(reportTypes.RESOURCE_REPORT, null),
                icon: Group,
                dataCy: 'resource-reports-menu-item',
            },
            {
                label: reportTypes.EVENT_REPORT.display,
                display: showEventReports,
                content: this.renderSubTypes(reportTypes.EVENT_REPORT),
                icon: NaturePeople,
                dataCy: 'event-reports-menu-item',
            },
            {
                label: reportTypes.BILLING_REPORT.display,
                display: showBillingReports,
                icon: AttachMoney,
                dataCy: 'billing-report-menu-item',
                content: [
                    {
                        label: 'All report templates',
                        dataCy: 'billing-report-all-templates-menu-item',
                        icon: Apps,
                        to: `/reports/${BILLING_REPORT.route}`,
                        itemClass: 'tree-2',
                    },
                    {
                        label: `${this.keyWords.projectKeyWord} Spend`,
                        dataCy: 'billing-report-project-spend-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.BILLING_REPORT, 'PROJECT'),
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord} Spend`,
                        dataCy: 'billing-report-resource-spend-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.BILLING_REPORT, 'RESOURCE'),
                    },
                    {
                        label: 'Budget Reports',
                        dataCy: 'billing-report-budget-reports-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.BILLING_REPORT, 'BUDGET'),
                    },
                ],
            },
            {
                label: reportTypes.UTILITY_REPORT.display,
                display: showUtilityReports,
                content: this.renderSubTypes(reportTypes.UTILITY_REPORT),
                icon: Build,
                dataCy: 'utility-report-menu-item',
            },
            {
                label: reportTypes.GROUP_REPORT.display,
                display: showGroupReports,
                icon: Folder,
                dataCy: 'group-reports-menu-item',
                content: [
                    {
                        label: 'All report templates',
                        dataCy: 'group-reports-all-templates-menu-item',
                        icon: Apps,
                        to: `/reports/${GROUP_REPORT.route}`,
                        itemClass: 'tree-2',
                    },
                    {
                        label: `${this.keyWords.projectKeyWord} Groups`,
                        dataCy: 'group-reports-project-groups-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.GROUP_REPORT, 'PROJECT'),
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord} Groups`,
                        dataCy: 'group-reports-resource-group-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.GROUP_REPORT, 'RESOURCE'),
                    },
                ],
            },
            {
                label: `${this.keyWords.customerKeyWord} Reports`,
                icon: Face,
                display: showCustomerReports,
                dataCy: 'customer-reports-menu-item',
                content: [
                    {
                        label: 'All report templates',
                        dataCy: 'customer-reports-all-templates-menu-item',
                        icon: Apps,
                        to: `/reports/${CUSTOMER_REPORT.route}`,
                        itemClass: 'tree-2',
                    },
                    {
                        label: `${this.keyWords.projectKeyWord}`,
                        dataCy: 'customer-reports-project-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.CUSTOMER_REPORT, 'PROJECT'),
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord}`,
                        dataCy: 'customer-reports-resource-menu-item',
                        itemClass: 'tree-2',
                        display: hasManageReportsRights,
                        content: this.renderSubTypes(reportTypes.CUSTOMER_REPORT, 'RESOURCE'),
                    },
                ],
            },
            {
                label: reportTypes.UNASSIGNED_REPORT.display,
                display: showUnassignedReports,
                content: this.renderSubTypes(reportTypes.UNASSIGNED_REPORT),
                icon: PeopleOutline,
                dataCy: 'unassigned-reports-menu-item',
            },
            {
                label: `${reportTypes.HEAT_MAP_REPORT.display}`,
                display: hasManageReportsRights || isProjectManager,
                icon: ColorLens,
                dataCy: 'heat-map-reports-menu-item',
                content: [
                    {
                        label: 'All report templates',
                        dataCy: 'heat-map-reports-all-templates-menu-item',
                        icon: Apps,
                        to: `/reports/${reportTypes.HEAT_MAP_REPORT.route}`,
                        itemClass: 'tree-2',
                    },
                    {
                        label: `${this.keyWords.projectKeyWord}`,
                        dataCy: 'heat-map-reports-project-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.HEAT_MAP_REPORT, 'PROJECT'),
                    },
                    {
                        label: 'Event',
                        dataCy: 'heat-map-reports-event-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.HEAT_MAP_REPORT, 'EVENT'),
                    },
                    {
                        label: `${this.keyWords.resourceKeyWord}`,
                        dataCy: 'heat-map-reports-resource-menu-item',
                        itemClass: 'tree-2',
                        content: this.renderSubTypes(reportTypes.HEAT_MAP_REPORT, 'RESOURCE'),
                    },
                    {
                        label: 'Unassigned',
                        dataCy: 'heat-map-reports-unassigned-menu-item',
                        itemClass: 'tree-2',
                        display: showUnassignedReports,
                        content: this.renderSubTypes(reportTypes.HEAT_MAP_REPORT, 'UNASSIGNED'),
                    },
                ],
            },
            {
                customItem: SeparatorItem,
                display: showMyReports,
                inputProps: {
                    text: 'MY REPORTS',
                },
            },
            {
                label: reportTypes.MY_REPORTS.display,
                dataCy: 'my-reports-menu-item',
                display: showMyReports,
                content: this.renderSubTypes(reportTypes.MY_REPORTS),
                icon: Person,
            },
            {
                label: 'My Saved Reports',
                dataCy: 'my-saved-reports-menu-item',
                display: showMyReports,
                emptyText: 'It looks like you have no saved reports yet. They will appear here',
                customItem: MenuItemWithGroups,
                inputProps: {
                    type: 'PRIVATE',
                },
                allContent: savedReports,
                content: [
                    ...(savedReports.length
                        ? [
                              {
                                  label: 'All report templates',
                                  dataCy: 'my-saved-reports-all-templates-menu-item',
                                  icon: Apps,
                                  to: '/reports/templates/saved',
                                  itemClass: 'tree-2',
                              },
                          ]
                        : []),
                    ...(showMyReports
                        ? (subGroups['PRIVATE'] ?? []).map(group => ({
                              label: group.name,
                              group,
                              id: group._id,
                              parent: 'PRIVATE',
                              customItem: ReportsGroupMenuItem,
                              itemClass: 'reports-group tree-2',
                              icon: Folder,
                              allContent: savedReports,
                              content: getReportsGroupContent(
                                  'SAVED',
                                  group,
                                  this.renderTemplates.bind(this),
                                  savedReportsById
                              ),
                          }))
                        : []),
                    ...Object.values(savedReportsById),
                ],
                icon: Lock,
            },
            {
                label: 'Favorite Reports',
                dataCy: 'favorite-reports-menu-item',
                display: hasManageReportsRights || isProjectManager,
                emptyText: 'It looks like you have no favorite reports yet. They will appear here',
                content: this.renderTemplates('FAVOURITES'),
                icon: Favorite,
            },
            {
                label: 'Internal Reports',
                dataCy: 'internal-reports-menu-item',
                display: hasManageReportsRights || isProjectManager,
                emptyText: 'It looks like there are no internal reports yet. They will appear here',
                customItem: MenuItemWithGroups,
                inputProps: {
                    type: 'INTERNAL',
                },
                allContent: internalReports,
                content: [
                    ...(internalReports.length
                        ? [
                              {
                                  label: 'All report templates',
                                  dataCy: 'internal-reports--all-templates-menu-item',
                                  icon: Apps,
                                  to: '/reports/templates/internal',
                                  itemClass: 'tree-2',
                              },
                          ]
                        : []),
                    ...(subGroups['INTERNAL'] ?? []).map(group => ({
                        group,
                        id: group._id,
                        parent: 'INTERNAL',
                        label: group.name,
                        customItem: ReportsGroupMenuItem,
                        itemClass: 'tree-2',
                        icon: Folder,
                        allContent: internalReports,
                        content: getReportsGroupContent(
                            'INTERNAL',
                            group,
                            this.renderTemplates.bind(this),
                            internalReportsById
                        ),
                    })),
                    ...Object.values(internalReportsById),
                ],
                icon: Public,
            },
            {
                customItem: SuggestReportItem,
                dataCy: 'suggest-report-menu-item',
                currentClassItem: 'suggest-report-item col-sm-3 col-md-4 col-lg-3 col-xl-2',
                inputProps: {
                    hasRights: hasManageReportsRights || isProjectManager,
                },
            },
            {
                customItem: SeparatorItem,
                display: hasManageReportsRights,
                inputProps: {
                    text: 'SETTINGS',
                },
            },
            {
                label: 'Global Calculations',
                dataCy: 'global-calculations-menu-item',
                display: hasManageReportsRights,
                to: '/settings#calculations',
                itemClass: 'report-tour-global-calculations',
                icon: PermDataSetting,
                externalLink: true,
            },
            {
                customItem: SeparatorItem,
                display: hasManageReportsRights || isProjectManager,
                inputProps: {
                    text: 'COMING SOON',
                },
            },
            {
                label: `Most Popular`,
                dataCy: 'most-popular-menu-item',
                display: hasManageReportsRights || isProjectManager,
                to: '#',
                itemClass: 'disabled',
                icon: TrendingUp,
            },
        ];
    }
}

export default new ReportsMenu();
