import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { Warning } from '@material-ui/icons';

const Error = ({ message }) => (
    <Alert color="danger">
        <b>
            <Warning />
        </b>{' '}
        {message}
    </Alert>
);

Error.propTypes = {
    message: PropTypes.string.isRequired,
};

export default Error;
