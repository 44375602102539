import { selectProjectStatusesKeywords, selectProjectStatusesMapped } from '../../selectors/company';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useAppKeyWords } from '../../hooks';
import { SYSTEM } from '../../modules/scheduler/enums/groupTypeEnum';
import { replaceKeywords, replaceMap } from '../../utils/keywordsUtil';

export const useProjectStatusDisplayReplacer = () => {
    const statuses = useSelector(selectProjectStatusesMapped);

    return useCallback(
        value => {
            if (!value) {
                return '';
            }
            return statuses[value]?.display;
        },
        [statuses]
    );
};

export const useProjectStatusDisplay = value => {
    const replacer = useProjectStatusDisplayReplacer();

    return useMemo(() => {
        return replacer(value);
    }, [replacer, value]);
};

export const useProjectGroupNameReplacer = () => {
    const projectStatusesKeywords = useSelector(selectProjectStatusesKeywords);
    const appKeyWords = useAppKeyWords();

    return useCallback(
        group => {
            if (!group) {
                return '';
            }

            if (group.groupType !== SYSTEM) {
                return group.name;
            }

            return replaceKeywords(
                replaceMap({
                    ...appKeyWords,
                    ...projectStatusesKeywords,
                }),
                group.name || ''
            );
        },
        [appKeyWords, projectStatusesKeywords]
    );
};
