import { createRequestTypes } from '../../../../../actions/base';

export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const SET_GROUP = 'SET_GROUP';
export const SET_TAB = 'SET_TAB';
export const SET_ITEM_TYPE = 'SET_ITEM_TYPE';
export const SET_TITLE = 'SET_TITLE';
export const SET_GROUP_TYPE = 'SET_GROUP_TYPE';
export const SET_IS_SMART = 'SET_IS_SMART';
export const SET_DATES = 'SET_DATES';
export const SET_FILTER_RELATION = 'SET_FILTER_RELATION';
export const ADD_ITEM = 'ADD_ITEM';
export const ADD_MANY_ITEMS = 'ADD_MANY_ITEMS';
export const REMOVE_MANY_ITEMS = 'REMOVE_MANY_ITEMS';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_MANY_GROUPS = 'ADD_MANY_GROUPS';
export const REMOVE_GROUP = 'REMOVE_GROUP';
export const SET_FILTER_OPERATOR = 'SET_FILTER_OPERATOR';

export const PRE_SET_DATA = 'PRE_SET_DATA';
export const INITIALIZE_V2 = createRequestTypes('INITIALIZE_V2');
export const INITIALIZE_V2_WITH_GROUP = 'INITIALIZE_V2_WITH_GROUP';
export const INITIALIZE_V2_WITHOUT_GROUP = 'INITIALIZE_V2_WITHOUT_GROUP';
export const RESET_BUILDER = 'RESET_BUILDER';
