import React from 'react';
import PropTypes from 'prop-types';
import { TabPane } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import { useIsExtensionInstalled, useHasRights, useAppKeyWords } from 'hooks';
import { FORM_NAME } from './modal';
import { VACATION_REQUEST } from 'enums/extensionShortIdEnum';
import { normalizeFloatNumber } from 'utils/formUtil';
import MissingExtension from 'shared/warnings/missingExtension';
import PermissionDenied from 'shared/permissionDenied';
import { ToilResourceSetting } from './toil';
import { NumberInputReduxFormWrapper, RadioReduxFormWrapper, Layout, Typography, Divider } from '@hub-mono/ui';
import { VacationStats } from './vacationStats';
import { useVacationStatistics } from '../../../vacation/useVacationStatistics';

const VacationTab = ({ tabId, resourceId }) => {
    const formValues = useSelector(getFormValues(FORM_NAME));
    const { vacation } = formValues;
    const { resourceKeyWord, vacationEventName: defaultVacationName } = useAppKeyWords();
    const isVacationExtensionInstalled = useIsExtensionInstalled(VACATION_REQUEST);
    const { hasSettingResourceVacationDays } = useHasRights([
        {
            rights: ['settingResourceVacationDays'],
            rule: 'one',
            name: 'hasSettingResourceVacationDays',
        },
    ]);
    const vacationStats = useVacationStatistics({ resource: { _id: resourceId } });

    return (
        <TabPane tabId={tabId}>
            {hasSettingResourceVacationDays && isVacationExtensionInstalled && (
                <>
                    <Layout stack gap={24}>
                        {resourceId ? (
                            <VacationStats
                                resourceId={resourceId}
                                statistics={vacationStats}
                                toilPolicyId={formValues.policy?._id}
                                modifiedToilAdjustments={formValues.modifiedToilAdjustments}
                                checkToil
                            />
                        ) : null}
                        <Layout stack gap="--Spacing-100">
                            <Layout stack gap="--Spacing-50">
                                <Typography variant="label" size="large" prominent>
                                    Annual {defaultVacationName} Days Allowance
                                </Typography>
                                <Typography variant="body" size="small">
                                    Choose to use the company default or add a custom amount
                                </Typography>
                            </Layout>
                            <Layout stack pl={12}>
                                <Field
                                    type="radio"
                                    label={`Company Default`}
                                    name="vacation"
                                    value="DEFAULT"
                                    dataCy="vacation-company-default"
                                    component={RadioReduxFormWrapper}
                                />
                                <Field
                                    type="radio"
                                    label={`Custom Amount`}
                                    name="vacation"
                                    value="CUSTOM"
                                    dataCy="vacation-custom-amount"
                                    component={RadioReduxFormWrapper}
                                />
                            </Layout>
                        </Layout>
                        <Layout stack>
                            <Field
                                type="number"
                                name="customVacationAllocationLimit"
                                disabled={'DEFAULT' === vacation}
                                normalize={normalizeFloatNumber}
                                component={NumberInputReduxFormWrapper}
                                variant="outlined"
                                label="Days per year"
                            />
                        </Layout>
                    </Layout>

                    <Layout pt={24} pb={24}>
                        <Divider type="horizontal" />
                    </Layout>
                </>
            )}
            {hasSettingResourceVacationDays ? (
                <ToilResourceSetting key={resourceId} resourceId={resourceId} />
            ) : null}
            {!isVacationExtensionInstalled && (
                <MissingExtension
                    description={`You require the vacation and annual leave extension installed to be able to set the custom Vacation days on a ${resourceKeyWord}`}
                />
            )}
            {isVacationExtensionInstalled && !hasSettingResourceVacationDays && <PermissionDenied />}
        </TabPane>
    );
};

VacationTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default VacationTab;
