import React from 'react';
import PropTypes from 'prop-types';
import { max, filter, uniq, each } from 'underscore';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import Link from 'shared/link';
import { getChartLabels } from 'modules/request/utils/requestUtil';
import { VacationYear } from 'modules/request/components';

const getHolidayChartData = (events, labels) => {
    const data = [];
    each(labels, date => {
        const days = filter(
            events.toJSON(),
            event => !event.isVacation && format(new Date(event.date), 'MMM') === date
        );
        data.push(uniq(days, event => event.date).length);
    });

    return data;
};

const UpcomingPublicHolidaysChart = ({ data, requestStartDate, requestEndDate, weekStartDay }) => {
    const labels = data.size && getChartLabels('months', requestStartDate, requestEndDate, weekStartDay);
    const mappedData = data.size && getHolidayChartData(data, labels);

    return (
        <div className="card-box pb-0">
            <h6 className="card-title">Public Holidays</h6>
            <VacationYear wrapperClassNames="mb-2" />
            {mappedData ? (
                <Bar
                    data={{
                        labels,
                        datasets: [
                            {
                                type: 'bar',
                                label: 'Public Holidays',
                                data: mappedData,
                                backgroundColor: 'rgb(112, 159, 181)',
                                borderColor: 'rgb(112, 159, 181)',
                                borderWidth: 1,
                            },
                        ],
                    }}
                    height={265}
                    options={{
                        animation: {
                            duration: 200,
                        },
                        maintainAspectRatio: true,
                        responsive: true,
                        legend: {
                            display: false,
                        },
                        scales: {
                            xAxes: [
                                {
                                    gridLines: {
                                        display: false,
                                    },
                                },
                            ],
                            yAxes: [
                                {
                                    gridLines: {
                                        display: false,
                                    },
                                    ticks: {
                                        callback: value => {
                                            if (Math.floor(value) === value) {
                                                return value;
                                            }
                                        },
                                        beginAtZero: true,
                                        max: max(mappedData) + 1,
                                        min: 0,
                                    },
                                },
                            ],
                        },
                    }}
                />
            ) : (
                <div className="holiday-warning text-center p-2">
                    <i className="fa fa-calendar" />
                    There are no Public Holidays added yet, they will appear here once added.
                    <Link className="d-block" to="/main#/settings/holidays">
                        Add now
                    </Link>
                </div>
            )}
        </div>
    );
};

UpcomingPublicHolidaysChart.propTypes = {
    data: PropTypes.object.isRequired,
    weekStartDay: PropTypes.number.isRequired,
    requestStartDate: PropTypes.string.isRequired,
    requestEndDate: PropTypes.string.isRequired,
};

export default UpcomingPublicHolidaysChart;
