import styled from 'styled-components';

export const Wrapper = styled.div`
    label > * {
        pointer-events: none;
    }
    input {
        width: 1px;
        height: 0.1;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -99999;
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const Label = styled.label`
    color: #fff;
    border: 1px solid transparent;
    white-space: nowrap;
    border-radius: 0;
    position: relative;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    &:hover:after {
        content: 'Upload Image';
        position: absolute;
        line-height: 22px;
        width: 100%;
        text-align: center;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.14);
        color: #fff;
        font-size: 11px;
    }
`;
