import { createSelector } from 'reselect';
import { includes, filter } from 'lodash';
import { hasExtensionsInstalled } from 'utils/extensionUtil';

export const getReportGroupsLoadingState = state => state.reportReducer.loadingGroups;
const getReportTemplates = state => state.reportReducer.reportTemplates;
const getAccountResourceId = state => state.account.resourceId;
const getExtensions = state => state.companyReducer.company.extensions;
export const getReportsGroups = state => {
    const { resourceId } = state.account;
    const { reportsGroups } = state.reportReducer;

    return reportsGroups.filter(rg => (rg.visibility === 'PRIVATE' ? rg.createdBy === resourceId : true));
};
export const getReportsGroupById = groupId => state => state.reportReducer.reportsGroups.find(g => g._id === groupId);

export const selectLoading = state => state.reportReducer.loading;

export const makeGetReportTemplates = filterType =>
    createSelector(
        [getReportTemplates, getAccountResourceId, getExtensions],
        (reportTemplates, resourceId, extensions) => {
            switch (filterType) {
                case 'INTERNAL':
                    return filter(
                        reportTemplates,
                        template => 'INTERNAL' === template.visibility && 'CUSTOM' === template.templateType
                    );
                case 'SAVED':
                    return filter(
                        reportTemplates,
                        template =>
                            template.owner === resourceId &&
                            'PRIVATE' === template.visibility &&
                            'CUSTOM' === template.templateType
                    );
                case 'FAVOURITES':
                    return filter(reportTemplates, template => includes(template.asFavourite, resourceId));
                case 'PROJECT_REPORT':
                case 'RESOURCE_REPORT':
                case 'BILLING_REPORT':
                case 'GROUP_REPORT':
                case 'UTILITY_REPORT':
                case 'HEAT_MAP_REPORT':
                case 'EVENT_REPORT':
                case 'UNASSIGNED_REPORT':
                case 'MY_REPORTS':
                case 'CUSTOMER_REPORT':
                    return filter(
                        reportTemplates,
                        template =>
                            filterType === template.type &&
                            'SYSTEM' === template.templateType &&
                            (!template.extensions || hasExtensionsInstalled(extensions, template.extensions))
                    );
                default:
                    return reportTemplates;
            }
        }
    );
