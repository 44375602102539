/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { contains } from 'underscore';
import { Link } from 'react-router-dom';

import { validateSchema } from 'utils/schemaUtil';
import { SAML_ONLY, GOOGLE_ONLY, REGULAR_AND_GOOGLE_ONLY } from 'enums/authEnum';
import { activateAccount, validateActivationToken } from 'api/account';
import { formSchema } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import { SubmitButton } from '../../shared/buttons';
import PasswordStrengthMeter from '../../shared/PasswordStrengthMeter';
import { ConfirmCheckbox, SubscribeCheckbox } from '../../shared/checkboxes';
import Loader from 'shared/loader';

const errorMessages = {
    default: (
        <>
            This activation link is not valid. Contact the Account Owner to resend a new link to activate your Hub
            Planner account.
        </>
    ),
    'already activated': (
        <>
            Your account is already activated. Please, <Link to="/login">log in</Link>
        </>
    ),
    expired: (
        <>
            This activation link is expired. Contact the Account Owner to resend a new link to activate your Hub Planner
            account.
        </>
    ),
};

const errorMessage = errorMessages['default'];

const selector = formValueSelector('actForm');

const Activation = ({ isAuthenticated, handleSubmit, match }) => {
    const [authMode, setAuthMode] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isActivating, setActivating] = useState(false);
    const password = useSelector(state => selector(state, 'newPassword'));

    const activateToken = match.params.token;

    const [error, setError] = useState(null);

    useEffect(() => {
        if (isAuthenticated) {
            setError('Log out before activation of new account');
        } else {
            validateActivationToken(activateToken)
                .then(response => {
                    if (!response.valid) {
                        setError(errorMessages[response.message] ? errorMessages[response.message] : errorMessage);
                    } else {
                        setAuthMode(response.authenticationMode);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    setError(errorMessage);
                });
        }
    }, [activateToken, isAuthenticated]);

    const submit = async values => {
        try {
            setActivating(true);
            const response = await activateAccount({
                ...values,
                token: activateToken,
                authMode,
            });
            setActivating(false);
            if (response.location) {
                window.location.replace(response.location);
            }
        } catch (error) {
            setActivating(false);
            if (error.response && error.response.data && 'PASSWORD_STRENGTH' === error.response.data.error) {
                setError(error.response.data.message);
            } else {
                setError(errorMessage);
            }
        }
    };

    const form = (
        <>
            <div className="mt-3 mb-4">
                {!contains([SAML_ONLY, GOOGLE_ONLY], authMode) && (
                    <h5>
                        <b>To activate your account please create password:</b>{' '}
                    </h5>
                )}
            </div>
            <Form onSubmit={handleSubmit(submit)}>
                {!contains([SAML_ONLY, GOOGLE_ONLY], authMode) && (
                    <>
                        <FormGroup className="mb-3">
                            <Field
                                component={renderTextField}
                                name="newPassword"
                                label="Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                            />
                        </FormGroup>

                        <PasswordStrengthMeter password={password} />

                        <FormGroup className="mb-1 mt-2">
                            <Field
                                component={renderTextField}
                                name="confirmNewPassword"
                                label="Repeat Password"
                                type="password"
                                variant="outlined"
                                fullWidth
                            />
                        </FormGroup>
                    </>
                )}

                <ConfirmCheckbox />
                <SubscribeCheckbox withoutDescription />

                <div className="text-center">
                    <SubmitButton
                        disabled={isActivating}
                        type="submit"
                        className="btn-block mb-2 mt-3 shadow-sm border-0 py-2 w-50"
                    >
                        {isActivating ? (
                            <Loader size={25} center />
                        ) : REGULAR_AND_GOOGLE_ONLY === authMode ? (
                            'Save Password and Continue'
                        ) : (
                            'Submit'
                        )}
                    </SubmitButton>
                </div>
            </Form>
        </>
    );

    return (
        <PageTemplate title="Activation of account" type="activation">
            <h2 className="text-center">Activation of account</h2>
            {error ? <Alert color="danger my-3">{error}</Alert> : ''}
            {!isLoading ? form : <Loader center />}
        </PageTemplate>
    );
};

Activation.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'actForm',
    validate: validateSchema(formSchema),
    initialValues: formSchema.getDefaultValues(),
})(Activation);
