/* eslint-env browser */
import { useEffect } from 'react';

export const useClickOutside = (handler, docOwner) => {
    useEffect(() => {
        const doc = docOwner || document;
        doc.addEventListener('click', handler, { capture: true });
        doc.addEventListener('contextmenu', handler, { capture: true });

        return () => {
            doc.removeEventListener('click', handler, { capture: true });
            doc.removeEventListener('contextmenu', handler, { capture: true });
        };
    }, [handler, docOwner]);
};
