import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row } from 'reactstrap';
import Loader from 'shared/loader';
import { BoxTitle, Value, Unit, Range, Column, Box } from './styles';

const DataBox = memo(({ fullWidth, title, unit, values, className, isLoading }) => (
    <Box sm={fullWidth ? 12 : 6} className={className}>
        <BoxTitle className="text-muted">{title}</BoxTitle>
        <Row>
            {values.map(value => {
                return (
                    <Column key={value.label}>
                        {!isLoading && (
                            <>
                                {'cash' === unit && <Unit className="currency">{value.currency}</Unit>}
                                {'MIXED' !== value.currency?.toUpperCase() && (
                                    <Value className={classNames({ negative: 0 > parseFloat(value.value) })}>
                                        {value.value}
                                    </Value>
                                )}
                                {'hrs' === unit && <Unit>{unit}</Unit>}
                            </>
                        )}
                        {isLoading && <Loader grayedZero />}
                        <Range>{value.label}</Range>
                    </Column>
                );
            })}
        </Row>
    </Box>
));

DataBox.propTypes = {
    title: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    values: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string,
            currency: PropTypes.string,
        })
    ).isRequired,
    unit: PropTypes.oneOf(['hrs', 'cash']),
};

DataBox.defaultProps = {
    isLoading: true,
    fullWidth: false,
    unit: 'hrs',
    className: '',
};

export default DataBox;
