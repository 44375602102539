import React from 'react';
import { contains } from 'underscore';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { displayDateState, displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    // PROJECT
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimeBetween'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimeBetween,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimeAfter'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimeAfter,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimePrevious'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimePrevious,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimeFuture'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimeFuture,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimeToDate'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimeToDate,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Project Scheduled Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'projectSpecificScheduledTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'projectSpecificScheduledTimeAllTime'),
        accessor: row =>
            displayValue(
                row.projectSpecificScheduledTimeAllTime,
                unitTypes[unit].calculation(row.projectSpecificScheduledTimeAllTime, avgDailyCapacity)
            ),
    },
    // EVENT
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimeBetween'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimeBetween,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimeAfter'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimeAfter,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimePrevious'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimePrevious,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimeFuture'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimeFuture,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimeToDate'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimeToDate,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Event Scheduled Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'eventSpecificScheduledTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'eventSpecificScheduledTimeAllTime'),
        accessor: row =>
            displayValue(
                row.eventSpecificScheduledTimeAllTime,
                unitTypes[unit].calculation(row.eventSpecificScheduledTimeAllTime, avgDailyCapacity)
            ),
    },
    // RESOURCE
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimeBetween'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimeBetween,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimeAfter'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimeAfter,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimePrevious'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimePrevious,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimeFuture'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimeFuture,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimeToDate'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimeToDate,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Resource Scheduled Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'resourceSpecificScheduledTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'resourceSpecificScheduledTimeAllTime'),
        accessor: row =>
            displayValue(
                row.resourceSpecificScheduledTimeAllTime,
                unitTypes[unit].calculation(row.resourceSpecificScheduledTimeAllTime, avgDailyCapacity)
            ),
    },
    // UNASSIGNED
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimeBetween'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimeBetween,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimeAfter'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimeAfter,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimePrevious'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimePrevious,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">(Future)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimeFuture'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimeFuture,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimeFuture, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">(To Date)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimeToDate'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimeToDate,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimeToDate, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Unassigned Scheduled Time
                        <p className="sub-title">(All Time)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'unassignedSpecificScheduledTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'unassignedSpecificScheduledTimeAllTime'),
        accessor: row =>
            displayValue(
                row.unassignedSpecificScheduledTimeAllTime,
                unitTypes[unit].calculation(row.unassignedSpecificScheduledTimeAllTime, avgDailyCapacity)
            ),
    },
];
