import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, Form, reduxForm, submit, initialize, getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { createResource } from 'actions/resourceActions';
import { materialInputGroupField, chooseFieldMaterial, dropdownFieldMaterial } from 'shared/formFields';
import SuccessButton from 'shared/buttons/success';
import { useAppKeyWords, useHasRights } from 'hooks';
import { rolesArrayExceptOwner } from 'enums/resourceEnum';
import { validateSchema } from 'utils/schemaUtil';
import { getDefaultValues, getFormSchema, mapFormToRequest } from './form';

const FORM_NAME = 'newShortResource';
const rights = [
    {
        rights: ['settingResourceRole'],
        rule: 'one',
        name: 'hasSettingResourceRole',
    },
];

const NewShortResource = ({ handleSubmit, onCreate, submitting, invalid, initialName }) => {
    const { resourceKeyWord } = useAppKeyWords();
    const dispatch = useDispatch();
    const { hasSettingResourceRole } = useHasRights(rights);
    const isCreating = useSelector(state => state.resourceReducer.isCreating);
    const currentFormValues = useSelector(getFormValues(FORM_NAME));
    const { sendInviteEmail } = currentFormValues || {};

    const onSubmit = values => {
        dispatch(
            createResource.request(
                {
                    ...mapFormToRequest(values),
                    options: {
                        skipCustomFieldsValidation: true
                    }
                },
                resource => {
                    onCreate && onCreate(resource);
                },
                false
            )
        );
    };

    useEffect(() => {
        dispatch(initialize(FORM_NAME, getDefaultValues(initialName)));
    }, [dispatch, initialName]);

    const submitDisabled = isCreating || submitting || invalid;

    return (
        <>
            <Form className="needs-validation mb-5" onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-5">
                    <Col>
                        <Field required label="First Name" name="firstName" component={materialInputGroupField} />
                    </Col>
                    <Col>
                        <Field label="Last Name" name="lastName" component={materialInputGroupField} />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Field
                            required={sendInviteEmail}
                            label="Email"
                            name="email"
                            component={materialInputGroupField}
                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Field label="Invite user" name="sendInviteEmail" component={chooseFieldMaterial} />
                    </Col>
                </Row>
                {hasSettingResourceRole && (
                    <Row>
                        <Col>
                            <Field
                                label="Role"
                                data={rolesArrayExceptOwner}
                                filter={false}
                                dropUp
                                valueField="value"
                                textField="display"
                                name="role"
                                component={dropdownFieldMaterial}
                            />
                        </Col>
                    </Row>
                )}
            </Form>
            <SuccessButton
                fixed
                disabled={submitDisabled}
                name={`Create ${resourceKeyWord.toLowerCase()}`}
                dataCy="button--create-resource"
                onClick={() => dispatch(submit(FORM_NAME))}
                className="mt-2"
            />
        </>
    );
};

NewShortResource.propTypes = {
    onCreate: PropTypes.func,
    initialName: PropTypes.string,
};

NewShortResource.defaultProps = {
    onCreate: null,
    initialName: '',
};

const dynamicValidate = (formValues, otherDetails) => {
    const { sendInviteEmail } = formValues || {};
    return validateSchema(getFormSchema(sendInviteEmail))(formValues, otherDetails);
};

export default reduxForm({
    form: FORM_NAME,
    validate: dynamicValidate,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
})(NewShortResource);
