import React from 'react';
import PropTypes from 'prop-types';
import { MoreVert, Person, Folder } from '@material-ui/icons';
import { EmptyGroupWrapper, EmptyGroupDesc, EmptyGroupTitle } from './styles';

const EmptyGroup = ({ type }) => (
    <EmptyGroupWrapper>
        {'RESOURCE' === type ? <Person fontSize="large" /> : <Folder fontSize="large" />}
        <EmptyGroupTitle>Empty Group</EmptyGroupTitle>
        <EmptyGroupDesc>
            Click the <MoreVert /> icon beside the group name to edit this group and start adding to it.
        </EmptyGroupDesc>
    </EmptyGroupWrapper>
);

EmptyGroup.propTypes = {
    type: PropTypes.oneOf(['PROJECT', 'RESOURCE']),
};

EmptyGroup.defaultProps = {
    type: 'RESOURCE',
};

export default EmptyGroup;
