import { InfoTile, Layout } from '@hub-mono/ui';
import { toFixedTrimmed } from '@hub-mono/utils';
import React, { useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useAppKeyWords, useIsExtensionInstalled } from '../../../../hooks';
import { TOIL } from '../../../../enums/extensionShortIdEnum';
import { selectCompanyAvgDailyCapacity } from '../../../../selectors/company';
import { useSelector } from 'react-redux';
import { useCheckToil } from '../../../vacation/useCheckToil';

const InfoBlocksWrapper = styled(Layout)`
    > * {
        flex: 1 1 0;
        width: 0;
    }
`;

export const VacationStats = ({ resourceId, toilPolicyId, modifiedToilAdjustments, statistics, checkToil }) => {
    const { vacationEventName: defaultVacationName } = useAppKeyWords();

    const companyAvgDailyCapacity = useSelector(selectCompanyAvgDailyCapacity);
    const isToilExtensionInstalled = useIsExtensionInstalled(TOIL);
    const currentYear = useRef(new Date().getFullYear());

    const vacationCheckToilStatistics = useCheckToil({
        resourceId,
        toilPolicyId,
        modifiedToilAdjustments,
        year: currentYear.current,
        checkToil
    });

    const stats = useMemo(() => {
        let remainingTotal, remainingVacation, remainingToil;
        let accruedTotal, accruedVacation, accruedToil;
        let requestedTotal, requestedVacation, requestedToil;
        let consumedTotal, consumedVacation, consumedToil;

        const toilStats = vacationCheckToilStatistics || statistics?.toil;
        const vacationStats = vacationCheckToilStatistics?.vacationTotal || statistics?.total;

        remainingVacation = vacationStats?.REMAINING;
        remainingToil = toilStats?.total?.REMAINING;

        accruedVacation = vacationStats?.REMAINING + vacationStats?.APPROVED;
        accruedToil = toilStats?.total?.REMAINING + toilStats?.total?.APPROVED;

        requestedVacation = vacationStats?.WAITING_FOR_APPROVAL;
        requestedToil = toilStats?.total?.WAITING_FOR_APPROVAL;

        consumedVacation = vacationStats?.APPROVED;
        consumedToil = toilStats?.total?.APPROVED;

        if (isToilExtensionInstalled) {
            remainingTotal = remainingVacation + (remainingToil ? remainingToil / companyAvgDailyCapacity : 0);
            accruedTotal = accruedVacation + (accruedToil ? accruedToil / companyAvgDailyCapacity : 0);
            requestedTotal = requestedVacation + (requestedToil ? requestedToil / companyAvgDailyCapacity : 0);
            consumedTotal = consumedVacation + (consumedToil ? consumedToil / companyAvgDailyCapacity : 0);
        } else {
            remainingTotal = remainingVacation;
            accruedTotal = accruedVacation;
            requestedTotal = requestedVacation;
            consumedTotal = consumedVacation;
        }

        const returnValue = {
            remainingTotal,
            remainingVacation,
            remainingToil,
            accruedTotal,
            accruedVacation,
            accruedToil,
            requestedTotal,
            requestedVacation,
            requestedToil,
            consumedTotal,
            consumedVacation,
            consumedToil,
        };

        return Object.keys(returnValue).reduce((acc, key) => {
            const value = returnValue[key];
            acc[key] = value === undefined || Number.isNaN(value) ? '-' : toFixedTrimmed(value, 2);
            return acc;
        }, {});
    }, [companyAvgDailyCapacity, isToilExtensionInstalled, statistics, vacationCheckToilStatistics]);

    return (
        <InfoBlocksWrapper gap={16}>
            <InfoTile
                dataCy="remaining-vacation"
                iconName="avgPace"
                title={`Remaining ${defaultVacationName}`}
                value={stats.remainingTotal}
                unit="Days"
                tileVariant="secondary"
                additionalInfo={
                    isToilExtensionInstalled && toilPolicyId
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: stats.remainingVacation,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: stats.remainingToil,
                                  unit: 'hours',
                              },
                          }
                        : undefined
                }
            />
            <InfoTile
                dataCy="accrued-vacation"
                iconName="savings"
                title={`Accrued ${defaultVacationName}`}
                value={stats.accruedTotal}
                unit="Days"
                tileVariant="primary"
                additionalInfo={
                    isToilExtensionInstalled && toilPolicyId
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: stats.accruedVacation,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: stats.accruedToil,
                                  unit: 'hours',
                              },
                          }
                        : null
                }
            />
            <InfoTile
                dataCy="requested-vacation"
                iconName="pendingActions"
                title={`Requested ${defaultVacationName}`}
                value={stats.requestedTotal}
                unit="Days"
                tileVariant="primary"
                additionalInfo={
                    isToilExtensionInstalled && toilPolicyId
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: stats.requestedVacation,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: stats.requestedToil,
                                  unit: 'hours',
                              },
                          }
                        : undefined
                }
            />
            <InfoTile
                dataCy="consumed-vacation"
                iconName="hourglassBottom"
                title={`Consumed ${defaultVacationName}`}
                value={stats.consumedTotal}
                unit="Days"
                tileVariant="primary"
                additionalInfo={
                    isToilExtensionInstalled && toilPolicyId
                        ? {
                              left: {
                                  title: defaultVacationName,
                                  value: stats.consumedVacation,
                                  unit: 'days',
                              },
                              right: {
                                  title: 'TOIL',
                                  value: stats.consumedToil,
                                  unit: 'hours',
                              },
                          }
                        : undefined
                }
            />
        </InfoBlocksWrapper>
    );
};
