import moize from 'moize';
import moment from 'moment';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';
import columnIds from 'modules/report/columns/columnIds';
import columns from 'modules/report/columns/columns';
import { first, isArray, chain, contains, pluck, flatten, isObject, each } from 'underscore';
import { isNil, filter, includes, map } from 'lodash';
import { UNASSIGNED } from 'enums/extensionShortIdEnum';
import { getCurrency, getDefaultCurrency } from 'enums/currencyEnum';
import {
    PROJECT as PROJECT_GROUPING,
    RESOURCE as RESOURCE_GROUPING,
    BOOKING_CATEGORY as BC_GROUPING,
    BUDGET_CATEGORY as BUDGET_CATEGORY_GROUPING
} from 'modules/report/enums/groupingTypeEnum';
import { isActive } from 'utils/extensionUtil';
import { formatNumber, capitalizeFirstLetter } from 'utils/formatingUtil';
import { disabledStyle, columnStyle as style, darkThemeDisabledStyle } from 'modules/report/columns/styles';
import { ANONYMIZATION_STRING, COLUMN_UNAVAILABLE, REPORTS_ITEMS_COUNT } from 'constants';
import { store } from '../../../store';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

export const getColumns = moize(
    (customFields, avgDailyCapacity, defaultCurrency, queryParams, dateColumns, columnIds = []) => {
        const {
            dateState,
            periodType,
            unit,
            columnsToLoad,
            itemType,
            groupBy,
            columnsToLoadSizes,
            startDate,
            endDate,
            split,
        } = queryParams;
        const generatedColumns = columns(
            { dateState, periodType, startDate, endDate },
            unit,
            avgDailyCapacity,
            columnsToLoad,
            customFields,
            itemType,
            groupBy,
            defaultCurrency,
            columnsToLoadSizes,
            { dateColumns, split }
        );

        if (columnIds && 0 < columnIds.length) {
            const columnIdNames = map(columnIds, 'id');

            return filter(
                generatedColumns,
                column => includes(columnIdNames, column.id) || !column.id || -1 !== column.id.indexOf('date')
            );
        }

        return generatedColumns;
    },
    { maxSize: 2 }
);

export const getColumnIds = moize(columnIds, { maxSize: 2 });

export const hasCapacityColumns = moize(
    (columnsIds, columnsToLoad) =>
        chain(columnsIds)
            .filter(columnId => contains(pluck(columnsToLoad, 'name'), columnId.id))
            .pluck('displayColumnType')
            .some(columnId => 'capacity' === columnId)
            .value(),
    { maxSize: 2 }
);

export const addColumnBySecondGrouping = (extensions, columns, secondGroupBy, itemType, groupBy) => {
    const resourceColumnName = isActive(extensions, UNASSIGNED) ? 'resourceUnassignedName' : 'resourceName';
    secondGroupBy === PROJECT_GROUPING.name &&
        groupBy !== PROJECT_GROUPING.name &&
        !contains(pluck(columns, 'name'), 'projectEventName') &&
        columns.splice(1, 0, { name: 'projectEventName' });
    secondGroupBy === RESOURCE_GROUPING.name &&
        groupBy !== RESOURCE_GROUPING.name &&
        !contains(pluck(columns, 'name'), resourceColumnName) &&
        columns.splice(1, 0, { name: resourceColumnName });

    secondGroupBy === BC_GROUPING.name &&
        groupBy !== BC_GROUPING.name &&
        !contains(pluck(columns, 'name'), 'bookingCategoryName') &&
        columns.splice(1, 0, { name: 'bookingCategoryName' });

    secondGroupBy === BUDGET_CATEGORY_GROUPING.name &&
        groupBy !== BUDGET_CATEGORY_GROUPING.name &&
        !contains(pluck(columns, 'name'), 'budgetCategoryName') &&
        columns.splice(1, 0, { name: 'budgetCategoryName' });

    -1 !== secondGroupBy.indexOf('_cf_') &&
        !contains(pluck(columns, 'name'), secondGroupBy) &&
        columns.splice(1, 0, { name: secondGroupBy });
};

export const displayCellCurrency = (currencies, defaultCurrency, mixedText = 'Mixed Rates Used') => {
    let currencyName = first(currencies);
    let currency = getCurrency(currencyName);

    return currencies && 1 < currencies.length ? mixedText : currency.symbol || defaultCurrency || getDefaultCurrency();
};

export const isAggregated = value => {
    const state = store.getState();
    const groupAggregates = state.account.preferences.report.groupAggregates;
    const itemsCount = isArray(value) ? value.length : 0;

    return groupAggregates && REPORTS_ITEMS_COUNT.value <= itemsCount;
};

export const getRateCurrencyValue = (rate, defaultCurrency, calculateFunc) => {
    if (rate === ANONYMIZATION_STRING) {
        return rate;
    }
    return `${getCurrency(rate.currency).symbol || defaultCurrency || getDefaultCurrency()}${
        calculateFunc ? calculateFunc(rate.value) : rate.value
    }`;
};

export const getColumnCurrencies = (row, columnName) =>
    row.currencies && row.currencies[columnName] ? row.currencies[columnName] : [];

export const getMappedArrayByEnum = (array, enumObj) =>
    map(array, value => (enumObj[value] ? enumObj[value].display : value)).join(', ');

export const displayRateCurrencyValues = (rates, defaultCurrency, displayAggregation = true, calculateFunc) => {
    if (!isArray(rates) && isObject(rates)) {
        return getRateCurrencyValue(rates, defaultCurrency, calculateFunc);
    }

    if (isAggregated(rates) && displayAggregation) {
        return REPORTS_ITEMS_COUNT.text(rates.length, 'Rates');
    }

    return rates && isArray(rates) && rates.length
        ? map(rates, rate => getRateCurrencyValue(rate, defaultCurrency, calculateFunc)).join(', ')
        : '-';
};

export const separateData = value => (value && isArray(value) && value.length ? flatten([...value]).join(', ') : '-');

export const differentTypesHandler = (value, itemsName, valueToDisplay) => {
    if (!value || (isArray(value) && !value.length)) {
        return '-';
    }

    const displayArrayHandler = isAggregated(value)
        ? REPORTS_ITEMS_COUNT.text(value.length, itemsName)
        : valueToDisplay || separateData(value);

    return !isArray(value) ? value : displayArrayHandler || '-';
};

export const displayCurrencyValue = (row, columnName, defaultCurrency) => {
    const value = row[columnName];

    if ('-' === value) {
        return value;
    }

    const currency = getColumnCurrencies(row, columnName);

    if (isArray(value) && value.length && 1 >= currency.length) {
        let text = [];
        each(value, item => {
            text.push(`${displayCellCurrency(currency, defaultCurrency)}${formatNumber(item, true)}`);
        });

        return text.join(', ');
    }

    const prefixValue = 0 > value && 1 >= currency.length ? '-' : '';
    const displayCurrency = displayCellCurrency(currency, defaultCurrency);
    const displayValue =
        !currency || 1 >= currency.length ? (value ? formatNumber(Math.abs(value), true) || 0 : 0).toString() : '';

    return `${prefixValue}${displayCurrency}${displayValue}`;
};


export const displayDateState = moize(
    (dateState, prefix, parenthesis = true) => {
        let startDate = moment(dateState.startDate);
        let endDate = moment(dateState.endDate);
        const customDateRangeText =
            dateState && ('custom' === dateState.periodType || prefix)
                ? `${startDate.format('MMM DD, YYYY')} - ${endDate.format('MMM DD, YYYY')}`
                : '';
        let rangeText = '';

        if (prefix) {
            const diff = endDate.diff(startDate, 'd') + 1;
            const unitOfTime = 'week' === dateState.periodType ? 'isoWeek' : dateState.periodType;
            if (prefix === DATE_STATE.LAST) {
                if ('custom' !== dateState.periodType) {
                    startDate = endDate;
                }
                rangeText = `${startDate
                    .clone()
                    .subtract(diff, 'd')
                    .startOf(unitOfTime)
                    .format('MMM DD, YYYY')} - ${endDate
                    .clone()
                    .subtract(diff, 'd')
                    .endOf(unitOfTime)
                    .format('MMM DD, YYYY')}`;
            } else if (prefix === DATE_STATE.NEXT) {
                if ('custom' !== dateState.periodType) {
                    endDate = startDate;
                }
                rangeText = `${startDate
                    .clone()
                    .add(diff, 'd')
                    .startOf(unitOfTime)
                    .format('MMM DD, YYYY')} - ${endDate
                    .clone()
                    .add(diff, 'd')
                    .endOf(unitOfTime)
                    .format('MMM DD, YYYY')}`;
            }
        } else {
            rangeText = `${
                'custom' !== dateState.periodType
                    ? `${capitalizeFirstLetter(dateState.dateState)} ${capitalizeFirstLetter(dateState.periodType)}`
                    : ''
            }${customDateRangeText}`;
        }

        parenthesis && (rangeText = `(${rangeText})`);

        return rangeText;
    },
    { maxSize: 4 }
);

export const displayStatusByType = (value, type) => (type.properties[value] ? type.properties[value].name : '');

export const displayValue = (value, displayedValue) => {
    if (value === ANONYMIZATION_STRING) {
        return value;
    }

    return COLUMN_UNAVAILABLE.name === value || undefined === value
        ? COLUMN_UNAVAILABLE.value
        : !isNil(displayedValue)
        ? displayedValue
        : value;
};

export const isColumnDisabled = (row, columnName) =>
    row &&
    columnName &&
    (COLUMN_UNAVAILABLE.name === row[columnName] ||
        COLUMN_UNAVAILABLE.name === row[columnName.substring(columnName.indexOf('/') + 1)]);

export const getColumnProps = (state, rowInfo, column, defaultStyles) => {
    const isDisabled = isColumnDisabled(state.data[0], column.id);
    let darkTheme = {};

    let cellStyles = style;

    if (defaultStyles) {
        const originalValue = rowInfo && rowInfo.row._original[column.id];
        cellStyles = !isArray(originalValue) ? defaultStyles : cellStyles;
    }

    if (state && state.isDarkTheme) {
        darkTheme = isDisabled ? darkThemeDisabledStyle : darkTheme;
    }

    return {
        style: isDisabled ? { ...style, ...disabledStyle, ...darkTheme } : { ...cellStyles, ...darkTheme },
        disabled: isDisabled,
    };
};

export const getHeaderStyles = (state, rowInfo, column) => {
    const disabledStyles = state && state.isDarkTheme ? darkThemeDisabledStyle : disabledStyle;

    return {
        style: column.id && isColumnDisabled(state.data[0], column.id) ? disabledStyles : undefined,
    };
};

export const getValueBasedOnEnum = (value, enumObj, itemsName) => {
    if (itemsName && isAggregated(value)) {
        return REPORTS_ITEMS_COUNT.text(value.length, itemsName);
    }

    return isArray(value) ? getMappedArrayByEnum(value, enumObj) : enumObj[value] ? enumObj[value].display : value;
};

export const isHeaderDisabled = (row, columnName) =>
    row && row._original && columnName && COLUMN_UNAVAILABLE.name === row._original[columnName];

export const dateTypesHandler = (values, format, itemsName) => {
    if (isArray(values) && values.length) {
        return isAggregated(values)
            ? REPORTS_ITEMS_COUNT.text(values.length, itemsName)
            : map(values, value => (value === ANONYMIZATION_STRING ? value : moment(removeUTCZuluFromDateTimestamp(value)).format(format))).join(', ');
    }

    if (values && !isArray(values) && values === ANONYMIZATION_STRING) {
        return values;
    }

    return values && !isArray(values) ? moment(removeUTCZuluFromDateTimestamp(values)).format(format) : '-';
};
