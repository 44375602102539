import uuid from 'uuid/v1';
import { getDateRange } from '../../utils/DateUtil';
import moment from 'moment';
import { YEAR_MONTH_DAY_OLD_FORMAT } from '../../global/enums/dateFormat';

export const updateAvailabilities = (action, resource) => {
    if (
        action.payload.body?.companySpecific?.useResourceAvailability === true &&
        action.payload.body?.companySpecific?.customAvailabilities
    ) {
        return {
            ...resource,
            useResourceAvailability: action.payload.body.companySpecific.useResourceAvailability,
            customAvailabilities: action.payload.body.companySpecific.customAvailabilities,
        };
    } else if (action.payload.body?.companySpecific?.useResourceAvailability === false) {
        return {
            ...resource,
            useResourceAvailability: false,
            customAvailabilities: undefined,
        };
    }

    return resource;
};

export const updateOverrides = (action, resource) => {
    const customAvailabilityOverridesToSave = action.payload.body.companySpecific.customAvailabilityOverridesToSave;

    const { customAvailabilitiesOverridesByDay, customAvailabilitiesOverridesById } = resource;

    const {
        create: createdOverrides,
        update: updatedOverrides = [],
        delete: deletedOverrides = [],
    } = customAvailabilityOverridesToSave;

    const createdUpdatedById = createdOverrides.concat(updatedOverrides).reduce((acc, created) => {
        const newId = `${uuid()}-created`;

        acc[newId] = {
            _id: newId,
            minutes: created.minutes || 0,
            from: `${created.from.replace(' ', 'T')}Z`,
            to: `${created.to.replace(' ', 'T')}Z`,
            ...created,
        };

        return acc;
    }, {});

    const createdUpdatedByDate = Object.values(createdUpdatedById).reduce((acc, created) => {
        const range = getDateRange(moment(created.from), moment(created.to), 'days', YEAR_MONTH_DAY_OLD_FORMAT);
        range.forEach(date => {
            acc[date] = created._id;
        });

        return acc;
    }, {});

    const newCustomAvailabilitiesOverridesById = {
        ...customAvailabilitiesOverridesById,
        ...createdUpdatedById,
    };

    const newCustomAvailabilitiesOverridesByDay = {
        ...customAvailabilitiesOverridesByDay,
        ...createdUpdatedByDate,
    };

    deletedOverrides.forEach(deletedId => {
        for (let day in newCustomAvailabilitiesOverridesByDay) {
            if (newCustomAvailabilitiesOverridesByDay[day] === deletedId) {
                delete newCustomAvailabilitiesOverridesByDay[day];
            }
        }
        delete newCustomAvailabilitiesOverridesById[deletedId];
    });

    return {
        ...resource,
        customAvailabilitiesOverridesById: newCustomAvailabilitiesOverridesById,
        customAvailabilitiesOverridesByDay: newCustomAvailabilitiesOverridesByDay,
        resourceHasCustomAvailabilityOverrides: Boolean(Object.keys(newCustomAvailabilitiesOverridesById).length),
    };
};
