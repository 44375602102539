import * as actionTypes from './actionTypes';
import { action } from './base';
import { store } from '../store';

export const changeView = {
    request: view => action(actionTypes.CHANGE_VIEW, { view }),
};

export const toggleSidebar = {
    request: () => action(actionTypes.TOGGLE_SIDEBAR),
};

export const openSidebar = {
    request: tabId => action(actionTypes.OPEN_SIDEBAR, { tabId }),
};

export const closeSidebar = {
    request: () => action(actionTypes.CLOSE_SIDEBAR),
};

export const setSchedulerSelection = {
    request: (startDate = null, endDate = null, rowTags = null) => {
        const scaleHour = store.getState().account?.preferences?.grid?.defaultScale === 'CellDuration';
        return action(actionTypes.SET_CURRENT_SELECTION, { startDate, endDate, rowTags, scaleHour });
    },
};

export const updateClipboard = {
    request: (booking, isCutAction) => action(actionTypes.UPDATE_CLIPBOARD, { booking, isCutAction }),
};

export const updateBookingSelection = {
    request: booking => action(actionTypes.UPDATE_BOOKING_SELECTION, { booking }),
};

export const setSchedulerFilterItem = {
    request: filter => action(actionTypes.SET_SCHEDULER_FILTER_ITEM['REQUEST'], { filter }),
};

export const setSchedulerFilterItems = filters => action(actionTypes.SET_SCHEDULER_FILTER_ITEMS, { filters });

export const setSchedulerFilterRelation = filterRelation =>
    action(actionTypes.SET_SCHEDULER_FILTER_RELATION, { filterRelation });

export const setFilterOperator = ({ operator, name }) => action(actionTypes.SET_FILTER_OPERATOR, { operator, name });

export const applyBuilderData = {
    request: ({ filters, appliedFilters, dates, filterRelation, hasFiltersChanged, group }) =>
        action(actionTypes.APPLY_BUILDER_DATA['REQUEST'], {
            filters,
            appliedFilters,
            dates,
            filterRelation,
            hasFiltersChanged,
            group,
        }),
};

export const removeFilter = {
    request: filter => action(actionTypes.REMOVE_FILTER['REQUEST'], { filter }),
};

export const removeFilters = filters => action(actionTypes.REMOVE_FILTERS, { filters });

export const clearAppliedFilters = {
    request: () => action(actionTypes.CLEAR_APPLIED_FILTERS['REQUEST']),
};

export const clearDataset = {
    request: () => action(actionTypes.CLEAR_DATASET['REQUEST']),
};

export const resetScheduler = {
    request: () => action(actionTypes.RESET['REQUEST']),
};

export const resetBuilder = {
    request: () => action(actionTypes.BUILDER_RESET['REQUEST']),
};

export const getDaypilotUnassignedResources = {
    request: ({ itemId, groupId, onSuccess }) =>
        action(actionTypes.GET_DAYPILOT_UNASSIGNED_RESOURCES['REQUEST'], { itemId, groupId, onSuccess }),
    success: data => action(actionTypes.GET_DAYPILOT_UNASSIGNED_RESOURCES['SUCCESS'], data),
    failure: () => action(actionTypes.GET_DAYPILOT_UNASSIGNED_RESOURCES['FAILURE']),
};

export const getDaypilotResources = {
    request: (data, cb) => action(actionTypes.GET_DAYPILOT_RESOURCES['REQUEST'], { data, cb }),
    success: data => action(actionTypes.GET_DAYPILOT_RESOURCES['SUCCESS'], data),
    failure: () => action(actionTypes.GET_DAYPILOT_RESOURCES['FAILURE']),
};

export const getFinancialData = {
    request: ({ projectId, projectGroupId, resourceId, singleProjectView, isSingleProjectView, isProjectGroupView }) =>
        action(actionTypes.UPDATE_FINANCIAL['REQUEST'], {
            projectId,
            projectGroupId,
            resourceId,
            singleProjectView,
            isSingleProjectView,
            isProjectGroupView,
        }),
    success: data => action(actionTypes.UPDATE_FINANCIAL['SUCCESS'], { data }),
};

export const initializeFilters = {
    request: data => action(actionTypes.INITIALIZE_FILTERS['REQUEST'], { ...data }),
};

export const getSchedulerGroup = {
    request: ({ groupId, builderType, isGroupView, force }) =>
        action(actionTypes.GET_SCHEDULER_GROUP['REQUEST'], { groupId, builderType, isGroupView, force }),
    success: ({ group, isGroupView }) => action(actionTypes.GET_SCHEDULER_GROUP['SUCCESS'], { group, isGroupView }),
    failure: () => action(actionTypes.GET_SCHEDULER_GROUP['FAILURE']),
};

export const schedulerRemoveRow = ({ item, additionalInfo }) => {
    return action(actionTypes.SCHEDULER_REMOVE_ROW, { item, additionalInfo });
};

export const clearSchedulerGroup = () => action(actionTypes.CLEAR_SCHEDULER_GROUP);
export const initScheduler = () => action(actionTypes.INIT_SCHEDULER);

export const setSchedulerBookingLinks = ({ bookings }) => action(actionTypes.SET_SCHEDULER_BOOKING_LINKS, { bookings });

export const createBookingLink = {
    request: ({ parentId, childId }) => action(actionTypes.CREATE_BOOKING_LINK['REQUEST'], { parentId, childId }),
    success: () => action(actionTypes.CREATE_BOOKING_LINK['SUCCESS']),
    failure: ({ parentId, childId }) => action(actionTypes.CREATE_BOOKING_LINK['FAILURE'], { parentId, childId }),
};

export const deleteBookingLink = {
    request: ({ parentId, childId }) => action(actionTypes.DELETE_BOOKING_LINK['REQUEST'], { parentId, childId }),
    success: () => action(actionTypes.DELETE_BOOKING_LINK['SUCCESS']),
    failure: ({ parentId, childId }) => action(actionTypes.DELETE_BOOKING_LINK['FAILURE'], { parentId, childId }),
};

export const removeBookingLink = ({ parentId, childId }) =>
    action(actionTypes.REMOVE_BOOKING_LINK, { parentId, childId });

export const getDaypilotChildrenResources = {
    request: ({ parentId, childrenIds }) =>
        action(actionTypes.GET_CHILDREN_RESOURCES['REQUEST'], { parentId, childrenIds }),
    success: ({ parentId, children }) => action(actionTypes.GET_CHILDREN_RESOURCES['SUCCESS'], { parentId, children }),
    failure: ({ parentId, childrenIds }) =>
        action(actionTypes.GET_CHILDREN_RESOURCES['FAILURE'], { parentId, childrenIds }),
};

export const collapseRow = ({ parentId }) => action(actionTypes.SCHEDULER_COLLAPSE_ROW, { parentId });
