import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getAccount = {
    request: () => action(actionTypes.GET_ACCOUNT['REQUEST']),
    success: account => action(actionTypes.GET_ACCOUNT['SUCCESS'], { account }),
    failure: () => action(actionTypes.GET_ACCOUNT['FAILURE']),
};

export const logout = () => action(actionTypes.LOGOUT);
export const clearAuthError = () => action(actionTypes.CLEAR_AUTH_ERROR);

export const login = {
    request: data => action(actionTypes.LOGIN['REQUEST'], { data }),
    success: account => action(actionTypes.LOGIN['SUCCESS'], { account }),
    failure: (error, errorCode) => action(actionTypes.LOGIN['FAILURE'], { error, errorCode }),
};

export const resetPassword = {
    request: data => action(actionTypes.RESET_PASSWORD['REQUEST'], { data }),
    success: account => action(actionTypes.RESET_PASSWORD['SUCCESS'], { account }),
    failure: (error, errorCode) => action(actionTypes.RESET_PASSWORD['FAILURE'], { error, errorCode }),
};

export const validateOTPOnLogin = payload => action(actionTypes.VALIDATE_OTP_ON_LOGIN, payload);

export const validateOTP = {
    request: code => action(actionTypes.VALIDATE_OTP['REQUEST'], { code }),
    success: account => action(actionTypes.VALIDATE_OTP['SUCCESS'], { account }),
    failure: (error, errorCode) => action(actionTypes.VALIDATE_OTP['FAILURE'], { error, errorCode }),
};

export const loadMFASetupData = {
    request: () => action(actionTypes.SETUP_MFA_DATA['REQUEST']),
    success: data => action(actionTypes.SETUP_MFA_DATA['SUCCESS'], data),
    failure: (error, errorCode) => action(actionTypes.SETUP_MFA_DATA['FAILURE'], { error, errorCode }),
};

export const setupMFA = {
    request: code => action(actionTypes.SETUP_MFA['REQUEST'], { code }),
    success: mfa => action(actionTypes.SETUP_MFA['SUCCESS'], { mfa }),
    failure: (error, errorCode) => action(actionTypes.SETUP_MFA['FAILURE'], { error, errorCode }),
};

export const disableMFA = {
    request: data => action(actionTypes.DISABLE_MFA['REQUEST'], { data }),
    success: mfa => action(actionTypes.DISABLE_MFA['SUCCESS'], { mfa }),
    failure: (error, errorCode) => action(actionTypes.DISABLE_MFA['FAILURE'], { error, errorCode }),
};

export const showMFARecoveryCodes = {
    request: data => action(actionTypes.SHOW_MFA_RECOVERY_CODES['REQUEST'], { data }),
    success: mfa => action(actionTypes.SHOW_MFA_RECOVERY_CODES['SUCCESS'], mfa),
    failure: (error, errorCode) => action(actionTypes.SHOW_MFA_RECOVERY_CODES['FAILURE'], { error, errorCode }),
};

export const generateMFARecoveryCodes = {
    request: data => action(actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['REQUEST'], { data }),
    success: mfa => action(actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['SUCCESS'], mfa),
    failure: (error, errorCode) => action(actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['FAILURE'], { error, errorCode }),
};

export const clearMFAData = () => action(actionTypes.CLEAR_MFA_DATA);
export const clearMFAError = () => action(actionTypes.CLEAR_MFA_ERROR);
export const setMFAError = ({ message, errorCode }) => action(actionTypes.SET_MFA_ERROR, { error: message, errorCode });
export const showMFACompanyReminder = () => action(actionTypes.SHOW_MFA_COMPANY_REMINDER);
export const postponeMFACompanyReminder = () => action(actionTypes.POSTPONE_MFA_COMPANY_REMINDER);

export const signUp = {
    request: data => action(actionTypes.SIGNUP['REQUEST'], { data }),
    success: account => action(actionTypes.SIGNUP['SUCCESS'], { account }),
    failure: error => action(actionTypes.SIGNUP['FAILURE'], { error }),
};
export const sendOTPCodeByEmail = {
    request: email => action(actionTypes.SEND_OTP_CODE_BY_EMAIL['REQUEST'], { email }),
    success: () => action(actionTypes.SEND_OTP_CODE_BY_EMAIL['SUCCESS']),
    failure: error => action(actionTypes.SEND_OTP_CODE_BY_EMAIL['FAILURE'], { error }),
};

export const updateAccountReportSettings = {
    request: data => action(actionTypes.UPDATE_ACCOUNT_REPORT_SETTINGS['REQUEST'], { data }),
    success: () => action(actionTypes.UPDATE_ACCOUNT_REPORT_SETTINGS['SUCCESS']),
};

export const updateAccountPreferences = {
    request: (data, schedulerUpdate, callback) =>
        action(actionTypes.UPDATE_ACCOUNT_PREFERENCES['REQUEST'], { data, schedulerUpdate, callback }),
    success: data => action(actionTypes.UPDATE_ACCOUNT_PREFERENCES['SUCCESS'], { data }),
};

export const clearSchedulerUpdate = () => action(actionTypes.CLEAR_SCHEDULER_UPDATE)

export const updateAccount = {
    request: data => action(actionTypes.UPDATE_ACCOUNT['REQUEST'], { data }),
    success: () => action(actionTypes.UPDATE_ACCOUNT['SUCCESS']),
};

export const changeAccountCompany = {
    request: data => action(actionTypes.CHANGE_ACCOUNT_COMPANY_REQUEST, { data }),
};

export const updateAccountNotifications = {
    request: (notifications, notificationDetails, onlyNotifications, silentUpdate) =>
        action(actionTypes.UPDATE_ACCOUNT_NOTIFICATIONS['REQUEST'], {
            notifications,
            notificationDetails,
            onlyNotifications,
            silentUpdate,
        }),
    success: data => action(actionTypes.UPDATE_ACCOUNT_NOTIFICATIONS['SUCCESS'], { data }),
};
