import React from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { formatDate } from 'utils/DateUtil';
import { DateWrapper, UpgradeLink, Wrapper } from './styles';

const TrialInfo = ({ trial, showLink }) => {
    const expireDate = addDays(trial.startDate, trial.days);

    return (
        <Wrapper>
            <DateWrapper>
                <span>Trial Expires: </span>
                <span>{formatDate(expireDate, 'do MMMM yyyy')}</span>
            </DateWrapper>
            <div title={trial.daysLeft + ' days left'} className="progress">
                <div className="progress-bar" style={{ width: `${100 - (trial.daysLeft / trial.days) * 100}%` }} />
            </div>
            {showLink && (
                <UpgradeLink className="nav-link" to="/settings/billing">
                    View Upgrade Options
                </UpgradeLink>
            )}
        </Wrapper>
    );
};

TrialInfo.propTypes = {
    showLink: PropTypes.bool,
    trial: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date),
        days: PropTypes.number,
        daysLeft: PropTypes.number,
    }).isRequired,
};

TrialInfo.defaultProps = {
    showLink: true,
};

export default TrialInfo;
