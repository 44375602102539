import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Add, ArrowRight, ArrowDropDown } from '@material-ui/icons';
import { getCustomFieldLabelFromResource } from 'utils/customFieldUtil';
import {
    TYPE_UNASSIGNED,
    STATUS_ACTIVE as STATUS_ACTIVE_RESOURCE,
    STATUS_ARCHIVED as STATUS_ARCHIVED_RESOURCE,
} from 'enums/resourceEnum';
import {
    TYPE_EVENT,
    STATUS_ACTIVE as STATUS_ACTIVE_PROJECT,
    STATUS_ARCHIVED as STATUS_ARCHIVED_PROJECT,
    STATUS_PENDING as STATUS_PENDING_PROJECT,
    STATUS_PLANNED as STATUS_PLANNED_PROJECT,
    STATUS_FLOATING as STATUS_FLOATING_PROJECT,
} from 'enums/projectEnum';
import ProjectManager from 'shared/projectManagerBadge';
import { Label, MenuRow, AdditionalRow, Link } from './styles';
import { changeActiveLinkFromLocation } from 'actions/menu/content';
import { store } from '../../../../store';

const getColumnAlignment = (rowTags, schedulerColumns) => {
    if (rowTags.isResourceRow || rowTags.unassignedRow) {
        return schedulerColumns.resourceColumns.defaultColumn.columnAlignment.toLowerCase();
    }

    return schedulerColumns.projectColumns.defaultColumn.columnAlignment.toLowerCase();
};

// Logic for DP getting rows links
const computeLinkWithSystemGroupsMapper = ({ entityKind, entityType, entityStatus, entityId }, systemGroupIds) => {
    let groupId;

    const systemGroupIdMapper = [
        // Order matters. Type checked first, then status
        [`resource^${TYPE_UNASSIGNED.value}^*`, systemGroupIds.unassignedGroupId],
        [`resource^*^${STATUS_ACTIVE_RESOURCE.value}`, systemGroupIds.activeResourceGroupId],
        [`resource^*^${STATUS_ARCHIVED_RESOURCE.value}`, systemGroupIds.archivedResourceGroupId],
        [`project^${TYPE_EVENT.value}^*`, systemGroupIds.eventsProjectGroupId],
        [`project^*^${STATUS_ACTIVE_PROJECT.value}`, systemGroupIds.activeProjectGroupId],
        [`project^*^${STATUS_ARCHIVED_PROJECT.value}`, systemGroupIds.archivedProjectGroupId],
        [`project^*^${STATUS_PENDING_PROJECT.value}`, systemGroupIds.pendingProjectGroupId],
        [`project^*^${STATUS_FLOATING_PROJECT.value}`, systemGroupIds.floatingProjectGroupId],
        [`project^*^${STATUS_PLANNED_PROJECT.value}`, systemGroupIds.plannedProjectGroupId],
    ];

    const key = `${entityKind}^${entityType}^${entityStatus}`;
    const keySplitted = key.split('^');

    groupId = systemGroupIdMapper.find(([rule, systemGroupId]) => {
        const ruleSplitted = rule.split('^');
        if (ruleSplitted.length !== 3) {
            return false;
        }

        return ruleSplitted.every((rulePart, index) => {
            return rulePart === '*' || rulePart === keySplitted[index];
        })
            ? systemGroupId
            : undefined;
    })?.[1];

    return groupId ? `/main#/${entityKind}/${entityId}/group/${groupId}` : '#';
};

const computeLinkWithCurrentGroupId = ({ entityKind, entityId }) => {
    const currentGroupId = window.location.hash.split('/group/')[1];
    return currentGroupId ? `/main#/${entityKind}/${entityId}/group/${currentGroupId}` : '#';
};

const ResourceColumn = props => {
    const {
        toggle,
        data,
        schedulerColumns,
        companyGridSettings,
        isPmExtensionActive,
        isCustomFieldExtensionActive,
        systemGroupIds,
    } = props;
    const { tags, name, expanded } = data;

    let customColumnSubRefId = null;
    if (tags.isResourceRow && isCustomFieldExtensionActive) {
        customColumnSubRefId = companyGridSettings.resourceSubtitleRefId;
    }
    if (tags.isProjectRow && isCustomFieldExtensionActive) {
        customColumnSubRefId = companyGridSettings.projectSubtitleRefId;
    }
    const label = getCustomFieldLabelFromResource(
        tags[tags.isProjectRow ? 'project' : 'resource'],
        customColumnSubRefId
    );

    if (tags.isRemoveProjectsRow) {
        return (
            <span>
                <AdditionalRow unsetHeight={tags.isRemoveProjectsRow}>{name}</AdditionalRow>
                <Label className="px-4">{tags.label}</Label>
            </span>
        );
    }

    if (tags.isEventRow) {
        return (
            <span>
                <AdditionalRow unsetHeight={tags.isEventRow}>{name}</AdditionalRow>
                <Label className="px-4">{tags.label}</Label>
            </span>
        );
    }

    if (tags.menuRow) {
        return (
            <MenuRow>
                <Add />
                {name}
            </MenuRow>
        );
    }

    const columnAlignment = getColumnAlignment(tags, schedulerColumns);
    const dpResourceType = tags.isResourceRow || tags.unassignedRow ? 'resource' : 'project';

    const mainElement = (
        <div
            className="w-100 text-truncate"
            style={{ textAlign: columnAlignment, height: 'auto', paddingRight: '25px' }}
            data-cy={`row-[res=${tags[dpResourceType]?._id}]`}
        >
            {isPmExtensionActive && tags.isResourceRow && tags.resource?.isProjectManager && (
                <ProjectManager>PM</ProjectManager>
            )}
            {name}
            {label && (
                <Label
                    className="w-100 text-truncate"
                    addMargin={isPmExtensionActive && tags.resource?.isProjectManager}
                >
                    {label}
                </Label>
            )}
        </div>
    );

    if (tags.parent) {
        return (
            <div
                className="d-flex align-items-center py-2 pl-2 w-100 cursor-p"
                style={{ textAlign: columnAlignment, height: 'auto', paddingRight: '30px' }}
                onClick={toggle}
            >
                {expanded ? <ArrowDropDown /> : <ArrowRight />}
                {mainElement}
            </div>
        );
    }

    const hasNoAccessToItem =
        ('resource' === dpResourceType && tags[dpResourceType] && !tags[dpResourceType].hasRightsToResource) ||
        ('project' === dpResourceType &&
            tags[dpResourceType] &&
            !tags[dpResourceType].hasRightsToProject &&
            !tags[dpResourceType].hasPMRightsToProject);

    if (hasNoAccessToItem || !tags[dpResourceType]?._id) {
        return <span className={classNames('text-truncate w-75 pl-4')}>{mainElement}</span>;
    }

    // Taking from Redux is not updated on the read time, but url it is.
    // We can subscribe to redux but too many updates would be triggered
    const groupType = window.location.hash.split('#/')?.[1].split('/')?.[0];
    const link =
        // Redirect to default system group, if child of currentView is different
        groupType === dpResourceType && tags?.[dpResourceType]?.type !== TYPE_UNASSIGNED.value
            ? // Redirect to element and same group id
              computeLinkWithCurrentGroupId({
                  entityKind: dpResourceType || '',
                  entityId: tags?.[dpResourceType]?._id || '',
              })
            : computeLinkWithSystemGroupsMapper(
                  {
                      entityKind: dpResourceType,
                      entityType: tags?.[dpResourceType]?.type || '',
                      entityStatus: tags?.[dpResourceType]?.status || '',
                      entityId: tags?.[dpResourceType]?._id || '',
                  },
                  systemGroupIds
              );

    const canNavigateToItem = link && link !== '#';

    if (!canNavigateToItem) {
        return <span className={classNames('text-truncate w-75 pl-4')}>{mainElement}</span>;
    }

    const onLinkClick = event => {
        event.preventDefault();
        const linkHashOfResource = `#${link.split('#')[1]}`;
        if (window.location.hash !== linkHashOfResource) {
            window.location.hash = linkHashOfResource;
            store.dispatch(changeActiveLinkFromLocation());
        }
    };

    return (
        <Link
            className={classNames('text-truncate w-75 pl-4', { 'cursor-d inactive': !canNavigateToItem })}
            onClick={onLinkClick}
            href={link}
        >
            {mainElement}
        </Link>
    );
};

ResourceColumn.propTypes = {
    data: PropTypes.object.isRequired,
    schedulerColumns: PropTypes.object.isRequired,
    companyGridSettings: PropTypes.object.isRequired,
    isPmExtensionActive: PropTypes.bool.isRequired,
    isCustomFieldExtensionActive: PropTypes.bool.isRequired,
    systemGroupIds: PropTypes.object.isRequired,
    toggle: PropTypes.func.isRequired,
};

export default ResourceColumn;
