import React from 'react';
import { contains } from 'underscore';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { displayDateState, displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        {displayDateState(dateState, false, false)}
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedTimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'reportedTimeBetween'),
        accessor: row =>
            displayValue(
                row.reportedTimeBetween,
                unitTypes[unit].calculation(row.reportedTimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        To Date
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedTimeToDate',
        sortable: false,
        show: contains(columnsToLoad, 'reportedTimeToDate'),
        accessor: row =>
            displayValue(row.reportedTimeToDate, unitTypes[unit].calculation(row.reportedTimeToDate, avgDailyCapacity)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Future
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedTimeFuture',
        sortable: false,
        show: contains(columnsToLoad, 'reportedTimeFuture'),
        accessor: row =>
            displayValue(row.reportedTimeFuture, unitTypes[unit].calculation(row.reportedTimeFuture, avgDailyCapacity)),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        All Time
                        <p className="sub-title">(reported)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'reportedTimeAllTime',
        sortable: false,
        show: contains(columnsToLoad, 'reportedTimeAllTime'),
        accessor: row =>
            displayValue(
                row.reportedTimeAllTime,
                unitTypes[unit].calculation(row.reportedTimeAllTime, avgDailyCapacity)
            ),
    },
];
