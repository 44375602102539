import React from 'react';
import PropTypes from 'prop-types';
import { useSchedulerResourcesListContext } from '../../lists/resourceList/schedulerResourcesListProvider';
import NewShortResource from './shortResource/newShortResource';
import NewShortUnassigned from './shortUnassigned/newShortUnassigned';

const NewShortResourceOrUnassigned = props => {
    const { slideRef, onCreate } = props;
    const { isUnassignedFilterApplied, resourceName, setResourceName } = useSchedulerResourcesListContext();

    if (isUnassignedFilterApplied) {
        return (
            <NewShortUnassigned
                initialName={resourceName}
                onCreate={unassigned => {
                    slideRef.current?.triggerStart();
                    setResourceName('');
                    onCreate({ resource: unassigned });
                }}
            />
        );
    }

    return (
        <NewShortResource
            initialName={resourceName}
            onCreate={resource => {
                slideRef.current?.triggerStart();
                setResourceName('');
                onCreate({ resource });
            }}
        />
    );
};

NewShortResourceOrUnassigned.propTypes = {
    onCreate: PropTypes.func,
    slideRef: PropTypes.object,
};

NewShortResourceOrUnassigned.defaultProps = {
    onCreate: () => {},
    slideRef: { current: undefined },
};

export default React.memo(NewShortResourceOrUnassigned);
