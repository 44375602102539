import styled from 'styled-components';

export const ColorBlock = styled.span`
    background-color: ${props => props.color};
    width: 20px;
    height: 20px;
    border: 1px solid ${props => props.color};
    margin-top: 0;
    margin-right: 5px;
    float: left;
    border-radius: 2px;
`;

export const CategoryAllocation = styled.span`
    background: #ecf0f1;
    border-radius: 5px;
    padding: 3px 7px;
    color: #2c3e50;
    height: 20px;
    line-height: 14px;
    font-size: 11px;
    margin: -1px 0 0 5px;
`;
