import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResources } from 'actions/resourceActions';
import useProjectCategoryGroups from './useProjectCategoryGroups';
import { useAppKeyWords } from 'hooks';
import useForm from './useForm';
import { useSchedulerData } from 'modules/scheduler/hooks';
import { SCHEDULED, WAITING_FOR_APPROVAL } from 'enums/bookingTypeEnum';
import { resourceIsNotNonBookable } from 'shared/lib/resources';
import { useAllocationCalc } from './useAllocationCalc';
import {
    useCalculateAllocationForCategory
} from "../../../../../../../../shared/allocation/calculateAllocationForCategory";

const useScheduleResourceForm = (onSchedule, canRequestResources, currentSelection, scale, project) => {
    const dispatch = useDispatch();

    const defaults = useMemo(() => ({ resource: undefined, project }), [project]);

    const { resources } = useSchedulerData(null, project, {
        canRequestResources: canRequestResources,
        filterResourcesFunction: resourceIsNotNonBookable,
    });

    const { resourceKeyWord: keyWord } = useAppKeyWords();

    useEffect(() => {
        dispatch(getResources.request());
    }, [dispatch]);

    const { formik, allocationValues } = useForm(onSchedule, defaults, currentSelection, scale);

    return {
        formik,
        resources,
        keyWord,
        allocationValues,
    };
};

/**
 * Create and use form that has initially set resource, and allow user to choose project/event for schedule/request
 * @param {Function} onClick - Callback function used when form is submitted
 * @param {Boolean} isRequestMode - Whether you are in menu after clicking on "request" button
 * @param {Boolean} combineScheduleRequest - Whether you can both request and schedule
 * @param {Object} currentSelection - Current selection on daypilot
 * @param {Object} scale - currently used scale [client/modules/scheduler/enums/scale.js]
 * @param {Object} resource - resource from currentSelection
 * @param {Object} type - Whether you want list of project or resources [enums/projectEnum.js]
 * @returns {Object}
 *  {
 *      formik - formik object;
 *      allocationValues - booking allocation - percentage,hours,total;
 *      keyword - resource keyword;
 *      resources - List of permitted resources to schedule/request for given project/event;
 *      handleSubmitSchedule - Callback to submit form for scheduling;
 *      handleSubmitRequest - Callback to submit form for requesting;
 *      values - form values;
 *      isSubmitting - form is submitting;
 *      updateFields - update form fields;
 *      projectCategoryGroups - Groups from which user can choose category for booking/request;
 * }
 */
const useResourceForm = (onClick, isRequestMode, combineScheduleRequest, currentSelection, scale, project) => {
    const { formik, allocationValues, keyword, resources } = useScheduleResourceForm(
        onClick,
        isRequestMode || combineScheduleRequest,
        currentSelection,
        scale,
        project
    );
    const { handleSubmit, setFieldValue, values, isSubmitting } = formik;

    const categorySetting = useSelector(state => state.companyReducer.company.settings.grid.catColorDisplayNew);

    const projectCategoryGroups = useProjectCategoryGroups(values?.project);

    const updateFields = useCallback(
        fieldsData => {
            // Project cannot be changed from here, already preselected from eow.

            // If category changed, change booking color, inherit from category
            if (fieldsData.category && categorySetting === 'CATEGORY_COLOR_BOOKING') {
                fieldsData.bookingColor = fieldsData.category.gridColor;
            }

            for (const [key, value] of Object.entries(fieldsData)) {
                setFieldValue(key, value);
            }
        },
        [categorySetting, setFieldValue]
    );

    const { handleAllocationValueChange, handleAllocationTypeChange, updateDates } = useAllocationCalc({
        formik,
        allocationValues,
        updateFields,
        scale,
        currentSelection,
        resource: values?.resource,
    });

    const calculateAllocationForCategory = useCalculateAllocationForCategory();
    const updateCategory = useCallback(
        newValues => {
            const allocationData = calculateAllocationForCategory({
                percentage: values.percentage,
                total: values.total,
                hours: values.hours,
                category: newValues.category,
            });

            updateFields({
                ...newValues,
                ...allocationData,
            });
        },
        [calculateAllocationForCategory, updateFields, values.hours, values.percentage, values.total]
    );

    return {
        formik,
        keyword,
        resources,
        handleSubmitSchedule: () => {
            setFieldValue('type', SCHEDULED.value);
            handleSubmit();
        },
        handleSubmitRequest: () => {
            setFieldValue('type', WAITING_FOR_APPROVAL.value);
            handleSubmit();
        },
        values,
        isSubmitting,
        updateFields,
        updateDates,
        updateCategory,
        projectCategoryGroups,
        handleAllocationValueChange,
        handleAllocationTypeChange,
    };
};

export default useResourceForm;
