import StorageUtil from 'utils/StorageUtil';
import { dispatchRefreshBookings } from 'modules/scheduler/utils/schedulerUtil';
import { collapseRow } from '../../../../actions/schedulerActions';
import { store } from '../../../../store';

export default args => {
    const dispatch = store.dispatch;
    const schedulerRef = args.resource.calendar;
    schedulerRef.clearSelection();

    // Remember resource is collapsed (refresh for example)
    if (args.resource.tags.unassignedParentRow) {
        StorageUtil.setItem('unassignedRow', 'collapsed', 'localStorage');
        dispatchRefreshBookings();
    } else {
        dispatch(collapseRow({ parentId: args.resource.id }));
    }
};
