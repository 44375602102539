/* eslint-env browser */
import { changeSubMenuVisibility, changeActiveLinkFromLocation } from 'actions/menu/content';
import flattenContent from './flattenContent';
import { findIndex, find, forEach, includes, assign, findLast } from 'lodash';

const defaultVisibility = (state, action) =>
    state.defaultVisibility ? state.subMenuVisibility : -1 !== action.trace.indexOf(state.id);

const item = (state, action) => {
    switch (action.type) {
        case 'CHANGE_SUBMENU_VISIBILITY': {
            const visibility = state.id === action.id ? action.subMenuVisibility : defaultVisibility(state, action);
            return assign({}, state, {
                subMenuVisibility: visibility,
                clearItems: state.id === action.id && state.lazyLoading && !visibility,
            });
        }
        case 'CHANGE_ACTIVE_LINK_FROM_LOCATION':
        case 'CHANGE_ACTIVE_LINK': {
            return assign({}, state, {
                active: state.id === action.id,
                hasActiveChild: -1 !== action.trace.indexOf(state.id),
            });
        }
        default: {
            return state;
        }
    }
};

const getContentAfterLinkSelection = (state, action) => {
    let activeItem;
    if ('CHANGE_ACTIVE_LINK_FROM_LOCATION' === action.type) {
        const locationSets = [
            window.location.hash, // #hash
            window.location.pathname + window.location.search, // /path?search
            window.location.pathname + window.location.search + window.location.hash, // /path?s#hash
            window.location.pathname.slice(1) + window.location.search, // path?search
            window.location.pathname.slice(1) + window.location.search + window.location.hash,
            // path?s#hash
        ];
        if (window.location.hash.startsWith('#')) {
            locationSets.push(window.location.hash.substring(1));
        }
        activeItem = state.content.find(i => i.to && locationSets.includes(i.to));
    } else {
        activeItem = findItem(state.content, action.value, action.propName);
        if (!activeItem) {
            let lastIndex = window.location.hash.lastIndexOf('/');
            activeItem = findItem(
                state.content,
                window.location.hash.slice(1).substr(0, lastIndex - 1),
                action.propName
            );
        }
    }

    // If metismenu user tries to activate non-exist item
    if (!activeItem) {
        return state;
    }

    const { id, parentId, trace } = activeItem;
    const stage = state.content.map(i => item(i, assign({}, { id, trace }, action)));

    // Trace also keeps parentId nonetheless it doesn't matter
    return content(assign({}, state, { content: stage }), changeSubMenuVisibility(parentId, trace, true));
};

const findItem = (content, value, prop) => content.find(i => i[prop] === value);

const content = (state = [], action) => {
    switch (action.type) {
        case 'VIEW_CHANGED': {
            return assign({}, state, {
                currentView: action.currentView,
            });
        }
        case 'UPDATE_MENU_CONTENT': {
            const flatten = flattenContent(action.content, state.activeContentItems);
            const updatedState = assign({}, state, {
                content: flatten,
            });
            const activeItem = findItem(updatedState.content, true, 'active');
            // when removed item from sidebar
            if (!activeItem || activeItem.to !== window.location.hash.slice(1)) {
                return getContentAfterLinkSelection(updatedState, changeActiveLinkFromLocation());
            }

            return updatedState;
        }
        case 'UPDATE_SUBMENU_ICON_CONTENT': {
            let id = action.id;
            state = { ...state };
            if (!id) {
                let menuItem = find({}, state.content, content => content.flow === action.flow);
                if (includes(['VACATION_FLOW_SINGLE', 'RES_REQUEST_FLOW_SINGLE'], action.flow)) {
                    id = menuItem.parentId;
                } else {
                    id = menuItem.id;
                }
            }
            let submenuIndex = findIndex(state.content, content => content.id === id);
            let submenu = state.content[submenuIndex];

            if (submenu && submenu.content && submenu.content.length) {
                forEach(action.data, (data, key) => {
                    let itemIndex = findIndex(submenu.content, item => -1 !== item.to.indexOf(key));
                    if (-1 !== itemIndex) {
                        submenu.content[itemIndex].customIconContent = data;
                    }
                });
            }

            state.content[submenuIndex] = submenu;

            forEach(action.data, (data, key) => {
                let itemIndex = findIndex(state.content, item => item.parentId === id && -1 !== item.to.indexOf(key));
                if (-1 !== itemIndex) {
                    state.content[itemIndex].customIconContent = data;
                }
            });

            return state;
        }
        case 'CHANGE_SUBMENU_VISIBILITY': {
            const updatedContent = state.content.map(i => item(i, action));

            const activeContentItems = updatedContent
                .filter(elem => {
                    return elem.hasActiveChild || elem.subMenuVisibility || elem.active;
                })
                .reduce((acc, elem) => Object.assign(acc, { [elem.id]: elem }), {});
            return assign({}, state, {
                activeContentItems,
                content: updatedContent,
                currentSubMenuOpenId: findLast(updatedContent, item => true === item.subMenuVisibility)?.id || null,
                currentSubMenuOpenTrace: action.trace,
            });
        }

        case 'SET_TEXT': {
            return assign({}, state, {
                text: action.text,
                parentId: action.parentId,
            });
        }

        case 'FILTER_CONTENT': {
            return assign({}, state, {
                filterText: action.text,
                parentId: action.parentId,
            });
        }
        case 'TOGGLE_FILTER_VISIBILITY': {
            return assign({}, state, {
                text: '',
                content: state.content.map(item =>
                    item.parentId === action.parentId && item.hasOwnProperty('filterVisible')
                        ? { ...item, filterVisible: !item.filterVisible }
                        : item
                ),
            });
        }
        case 'CHANGE_ACTIVE_LINK_FROM_LOCATION':
        case 'CHANGE_ACTIVE_LINK': {
            return getContentAfterLinkSelection(state, action);
        }
        case 'CHANGE_AND_OPEN_ACTIVE_LINK_FROM_LOCATION': {
            const res = getContentAfterLinkSelection(state, action);
            return {
                ...res,
                currentSubMenuOpenId: undefined,
                currentSubMenuOpenTrace: [],
            };
        }
        default: {
            return state;
        }
    }
};

export default (
    state = {
        content: [],
        text: '',
        filterText: '',
        currentSubMenuOpenId: null,
        currentView: 'resource',
        activeContentItems: {},
    },
    action
) => content(state, action);
