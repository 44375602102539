import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { EmojiFlags, GolfCourse, DateRange, DeleteOutlined, CreateOutlined } from '@material-ui/icons';
import { hasRole } from 'utils/rightsUtil';
import { ProjectDatesForm, MilestoneForm, PhaseForm } from './../';
import SlideCard from 'modules/scheduler/components/slideCard/slideCard';
import ListItem from 'modules/scheduler/components/lists/listItem';

const ResourceParent = React.forwardRef((props, schedulerRef) => {
    const {
        slideRef,
        projectKeyWord,
        calculatedHeight,
        keyWord,
        canAddMilestones,
        canAddPhases,
        canAddProjectDates,
        projectModal,
        onDelete,
        resourceRoleRights,
        project,
        defaults,
        width,
        onClose,
        currentSelectedBooking,
    } = props;

    const slideCardItems = [
        {
            id: 1,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    icon={EmojiFlags}
                    divider
                    {...props}
                    primaryText={`${keyWord} milestone`}
                    data-cy={`scheduler-booking-modern-menu-edit-${keyWord}-milestone`}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    disableParentSlideCardBackButton: true,
                    backTextLabel: `${keyWord} milestone`,
                    content: useMemo(
                        () => (
                            <div className="py-3 px-4">
                                <MilestoneForm schedulerRef={schedulerRef} defaults={defaults} onClose={onClose} />
                            </div>
                        ),
                        [defaults, onClose, schedulerRef]
                    ),
                },
            ],
            show: canAddMilestones && (!defaults.id || currentSelectedBooking.milestone),
        },
        {
            id: 2,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    icon={GolfCourse}
                    {...props}
                    primaryText={`${keyWord} phase`}
                    data-cy={`scheduler-booking-modern-menu-edit-${keyWord}-phase`}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    disableParentSlideCardBackButton: true,
                    backTextLabel: `${keyWord} phase`,
                    content: useMemo(
                        () => (
                            <div className="py-3 px-4">
                                <PhaseForm schedulerRef={schedulerRef} defaults={defaults} onClose={onClose} />
                            </div>
                        ),
                        [defaults, onClose, schedulerRef]
                    ),
                },
            ],
            show: canAddPhases && (!defaults.id || currentSelectedBooking.phase),
        },
        {
            id: 3,
            itemElement: ({ children, ...props }) => (
                <ListItem
                    divider
                    icon={DateRange}
                    {...props}
                    primaryText={`${defaults.id ? 'Edit start & end dates' : 'Start & end dates'}`}
                    data-cy={`scheduler-booking-modern-menu-edit-${keyWord}-start-end`}
                >
                    {children}
                </ListItem>
            ),
            slides: [
                {
                    disableParentSlideCardBackButton: true,
                    backTextLabel: `${defaults.id ? 'Edit start & end dates' : 'Start & end dates'}`,
                    content: (
                        <div className="py-3 px-4">
                            <ProjectDatesForm schedulerRef={schedulerRef} defaults={defaults} onClose={onClose} />
                        </div>
                    ),
                },
            ],
            show: canAddProjectDates && (!defaults.id || currentSelectedBooking.datesEvent),
        },
        {
            id: 4,
            itemElement: <ListItem icon={DeleteOutlined} divider onClick={onDelete} primaryText={`Delete...`} />,
            show: !!defaults.id,
        },
        {
            id: 5,
            itemElement: (
                <ListItem
                    icon={CreateOutlined}
                    onClick={projectModal}
                    primaryText={`Edit ${projectKeyWord.toLowerCase()}...`}
                    data-cy={`scheduler-booking-modern-menu-edit-${projectKeyWord.toLowerCase()}`}
                />
            ),
            show: hasRole(resourceRoleRights, 'settingAddEditProjects') && project.hasRightsToProject,
        },
    ];

    return (
        <SlideCard ref={slideRef} height={calculatedHeight} width={width} items={slideCardItems} dispatchParentEvents />
    );
});

ResourceParent.propTypes = {
    onClose: PropTypes.func,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
};

ResourceParent.defaultProps = {
    onClose: null,
};

export default ResourceParent;
