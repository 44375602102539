import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownMenu, DropdownToggle } from 'reactstrap';
import { STATUS_TRIAL } from 'enums/companyStatusEnum';
import TrialInfo from 'shared/trialInfo';
import { TrialDropdown, TrialDropdownItem } from './styles';

const TrialItem = ({ companyStatus, trial }) =>
    STATUS_TRIAL.value === companyStatus ? (
        <TrialDropdown nav inNavbar className={classNames({ 'about-to-expire': 5 >= trial.daysLeft })}>
            <DropdownToggle nav caret>
                <i className="fa fa-time" />
                <span className="trial-color">
                    Trial: <span>{trial.daysLeft}</span> Days Left
                </span>
            </DropdownToggle>
            <DropdownMenu right className="rounded-0 trial-item py-0">
                <TrialDropdownItem>
                    <TrialInfo trial={trial} />
                </TrialDropdownItem>
            </DropdownMenu>
        </TrialDropdown>
    ) : null;

TrialItem.propTypes = {
    companyStatus: PropTypes.string.isRequired,
    trial: PropTypes.object.isRequired,
};

export default TrialItem;
