import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Container, Row, Col, Form, FormGroup, Alert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { validateSchema } from 'utils/schemaUtil';
import { recoverPasswordForAccount } from 'api/account';
import { formSchema } from './formSchema';
import { renderTextField } from '../../shared/formFields';
import PageTemplate from '../../shared/pageTemplate';
import { SubmitButton } from '../../shared/buttons';

const ForgotPassword = ({ isAuthenticated, handleSubmit, submitSucceeded }) => {
    const [isEmailSend, setIsEmailSend] = useState(false);
    const [error, setError] = useState(null);

    if (isAuthenticated) {
        return <Redirect to="/" />;
    }

    const submit = values => {
        recoverPasswordForAccount(values)
            .then(data => {
                if (data.status) {
                    setIsEmailSend(true);
                }
            })
            .catch(() => {
                setIsEmailSend(false);
                setError('Error! The application has encountered an unknown error.');
            });
    };

    if (submitSucceeded && isEmailSend)
        return (
            <PageTemplate type="forgot">
                <Alert color="success">
                    Email with instructions to recover password is sent. Please check your mailbox.
                </Alert>
            </PageTemplate>
        );

    return (
        <PageTemplate type="forgot">
            {error && <Alert color="danger"> {error}</Alert>}
            <Form onSubmit={handleSubmit(submit)}>
                <FormGroup className="mb-3">
                    <Field
                        component={renderTextField}
                        label="Email Address"
                        name="email"
                        variant="outlined"
                        autoComplete="email"
                        autoFocus={true}
                        fullWidth
                    />
                </FormGroup>

                <SubmitButton type="submit" className="btn-block mb-2 shadow-sm border-0 py-2">
                    Recover Password
                </SubmitButton>

                <Container className="mt-3">
                    <Row>
                        <Col className="pl-3 text-right">
                            <Link to="/login">Back to login</Link>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </PageTemplate>
    );
};

ForgotPassword.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
    form: 'forgotForm',
    validate: validateSchema(formSchema),
})(ForgotPassword);
