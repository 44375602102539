import { RESOURCE_REQUEST, VACATION_REQUEST } from '../../../../enums/extensionShortIdEnum';
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import {
    selectAppliedBookingCategoriesFilter,
    selectAppliedBookingProjectsEventsFilter,
    selectAppliedBookingResourcesUWFilter,
    selectAppliedBookingStatusesFilter,
    selectAppliedBookingProjectStatusesFilter,
    selectSchedulerBookingCategoriesFilter,
    selectSchedulerBookingProjectsEventsFilter,
    selectSchedulerBookingResourcesUWFilter,
    selectSchedulerBookingStatusesFilter,
    selectSchedulerBookingProjectStatusesFilter,
} from '../../../../selectors/scheduler';
import {
    AccordionHeader,
    AccordionHeaderText,
    BOOKINGS_SIDE_TEXT_RGB_COLOR,
    useCommonDropdownFiltersStyles,
} from './styles';
import { Grid } from '@material-ui/core';
import { FilterItem } from './FilterItem';
import useFilterData from '../../../../shared/builder/filters/hooks/useFilterData';
import { useAppKeyWords, useIsExtensionInstalled, useItemsAndGroups } from '../../../../hooks';
import { PROJECT, RESOURCE } from '../../enums/builderTypeEnum';
import { arrayToObject, arrayToObjectByKey } from '../../../../utils/mappingUtil';
import { filterWithDiacriticsEscape } from 'utils/search';

const BookingRowFilters = () => {
    const filterData = useFilterData();
    const { items: projects } = useItemsAndGroups(PROJECT.name);
    const { items: resources } = useItemsAndGroups(RESOURCE.name);
    const { projectKeyWord, resourceKeyWord } = useAppKeyWords();

    const hasVacationExtension = useIsExtensionInstalled(VACATION_REQUEST);
    const hasResourceExtension = useIsExtensionInstalled(RESOURCE_REQUEST);
    const isNoExtensionInstalled = !hasVacationExtension && !hasResourceExtension;

    const classes = useCommonDropdownFiltersStyles({
        sideTextBgColor: BOOKINGS_SIDE_TEXT_RGB_COLOR,
    });

    const categoryGroups = useSelector(state => state.categoryGroupReducer.categoryGroups);

    const bookingsFilterConfig = useMemo(
        () => [
            {
                id: 1,
                title: 'Project statuses',
                filterName: 'bookingProjectStatuses',
                icon: 'SettingsOutlined',
                withEqualityToggle: true,
                items: filterData.projectStatuses,
                selectedSelector: selectAppliedBookingProjectStatusesFilter,
                filterSelector: selectSchedulerBookingProjectStatusesFilter,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
            {
                id: 2,
                title: 'Booking category',
                filterName: 'bookingCategories',
                icon: 'CategoryOutlined',
                items: categoryGroups,
                nested: true,
                selectedSelector: selectAppliedBookingCategoriesFilter,
                filterSelector: selectSchedulerBookingCategoriesFilter,
                itemLabelRenderer: item => item.name,
                getItemValue: item => item._id,
                childItemLabelRenderer: item => item.name,
                getChildItemValue: item => item._id,
                childrenSelector: item => item.categories || [],
                filterValue: child => ({
                    name: 'bookingCategories',
                    value: child._id,
                    parentValue: undefined,
                }),
                deletedItemsMapper: (items, selectedItems) => {
                    const existingCategoryTemplateIds = items.reduce((acc, item) => {
                        return {
                            ...acc,
                            ...arrayToObject(item.categoryTemplates),
                        };
                    }, {});

                    const deletedCategoryIds = Object.keys(selectedItems).reduce((acc, removedId) => {
                        if (!existingCategoryTemplateIds[removedId]) {
                            acc.push(removedId);
                        }
                        return acc;
                    }, []);

                    if (deletedCategoryIds.length) {
                        return [
                            {
                                _id: 'VIRTUAL_DELETED',
                                name: 'Deleted booking categories',
                                categories: deletedCategoryIds.map(item => ({
                                    _id: item,
                                    name: 'Deleted booking category',
                                    groupName: 'Deleted booking categories',
                                })),
                            },
                        ];
                    }

                    return [];
                },
            },
            {
                id: 3,
                title: 'Booking Status',
                filterName: 'bookingStatuses',
                icon: 'SettingsOutlined',
                selectedSelector: selectAppliedBookingStatusesFilter,
                filterSelector: selectSchedulerBookingStatusesFilter,
                items: isNoExtensionInstalled
                    ? filterData.bookingStatuses.map(status => ({
                          ...status,
                          disabled: 'SCHEDULED' !== status.value,
                      }))
                    : filterData.bookingStatuses,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
            {
                id: 4,
                title: 'Project & Events',
                icon: 'FolderOpenOutlined',
                filterName: 'bookingProjectsEvents',
                items: projects,
                withSearch: true,
                withSelectAll: true,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'name');
                },
                selectedSelector: selectAppliedBookingProjectsEventsFilter,
                filterSelector: selectSchedulerBookingProjectsEventsFilter,
                itemLabelRenderer: item => item.name,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');
                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[item];
                    });
                    return removed.map(item => ({
                        _id: item,
                        name: `Deleted ${projectKeyWord}`,
                        deleted: true,
                    }));
                },
            },
            {
                id: 5,
                title: 'Resources & unassigned',
                icon: 'PeopleAltOutlined',
                filterName: 'bookingResourcesUW',
                items: resources,
                withSearch: true,
                withSelectAll: true,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'name');
                },
                selectedSelector: selectAppliedBookingResourcesUWFilter,
                filterSelector: selectSchedulerBookingResourcesUWFilter,
                itemLabelRenderer: item => item.name,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');
                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[item];
                    });
                    return removed.map(item => ({
                        _id: item,
                        name: `Deleted  ${resourceKeyWord}`,
                        deleted: true,
                    }));
                },
            },
        ],
        [
            categoryGroups,
            filterData.bookingStatuses,
            filterData.projectStatuses,
            isNoExtensionInstalled,
            projectKeyWord,
            projects,
            resourceKeyWord,
            resources,
        ]
    );

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.sideText}>Booking filters</div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <AccordionHeader item xs={12}>
                    <AccordionHeaderText>Booking filters</AccordionHeaderText>
                </AccordionHeader>
                {bookingsFilterConfig.map(filterItem => (
                    <FilterItem key={filterItem.id} filterItem={filterItem} />
                ))}
            </Grid>
        </div>
    );
};

const memoized = React.memo(BookingRowFilters);

export { memoized as BookingRowFilters };
