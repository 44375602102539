import { UNASSIGNED, TIME_SHEET } from 'enums/extensionShortIdEnum';
import { COLUMN_TYPES_ENUM } from 'modules/report/enums/columnTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'unassignedName',
        display: 'Unassigned Name',
        multiItemsDisplay: 'Names',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        extensions: [UNASSIGNED],
        heatMapSupport: false,
    },
    {
        id: 'eventName',
        display: 'Event Name',
        multiItemsDisplay: 'Events',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingStart',
        display: 'Booking Start Date',
        multiItemsDisplay: 'Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'bookingEnd',
        display: 'Booking End Date',
        multiItemsDisplay: 'Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
    {
        id: 'timeEntryDate',
        display: 'Time Entry Date',
        multiItemsDisplay: 'Dates',
        displayTimeType: 'Text',
        displayColumnType: 'text',
        extensions: [TIME_SHEET],
        columnType: COLUMN_TYPES_ENUM.TEXT,
        heatMapSupport: false,
    },
];
