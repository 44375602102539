import React, { PureComponent } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyledTextField = withStyles({
    root: {
        '& .MuiInputBase-input': {
            padding: '10px',
        },
        '& .MuiInputBase-root': {
            height: ({height}) => height,
        },
    },
})(TextField);

class DatepickerInput extends PureComponent {
    render() {
        const { input, label, className, disabled, value, height, ...rest } = this.props;

        return (
            <StyledTextField
                label={label}
                value={value}
                className={className}
                variant="outlined"
                disabled={disabled}
                placeholder={label}
                height={height}
                {...rest}
                {...input}
            />
        );
    }
}

export default DatepickerInput;
