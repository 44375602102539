import styled from 'styled-components';

export const Badge = styled.span`
    background-color: #2980b9;
    font-size: 11px;
    padding: 3px 5px;
    color: #fff;
    border-radius: 0;
    display: inline-block;
    margin: 3px 0;
`;
