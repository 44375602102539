import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { updateDaypilotEvents } from '../../utils/schedulerUtil';
import { updateResource } from '../../../../actions/resourceActions';
import { Alert, Modal, ModalFooter } from 'reactstrap';
import { EscButton, Header, ModalContent } from '../../modals/styles';
import { Close, Info, Link, Note, PlaylistAdd } from '@material-ui/icons';
import CancelButton from '../../../../shared/buttons/cancel';
import SuccessButton from '../../../../shared/buttons/success';
import { useDispatch } from 'react-redux';
import { useBooking } from '../../hooks';
import { usePressEscape, useProjectAction } from '../../../../hooks';
import { closeSidebar as closeSidebarRequest } from '../../../../actions/schedulerActions';

export const TABS = {
    NOTE_TAB: {
        id: 1,
        icon: Note,
    },
    TASKS_TAB: {
        id: 2,
        icon: PlaylistAdd,
    },
    LINKS_TAB: {
        id: 3,
        icon: Link,
    },
    INFO_TAB: {
        id: 4,
        icon: Info,
    },
};

const noteSelector = {
    booking: booking => {
        return {
            message: booking.note || '',
            formattedNote:
                (booking.formattedNote || '').indexOf('{') === 0
                    ? JSON.parse(booking.formattedNote)
                    : booking.formattedNote || '',
        };
    },
    project: booking => {
        const { project } = booking;
        const note = project?.formattedNote || project?.note || '';
        return {
            message: note,
            formattedNote: note.indexOf('{') === 0 ? JSON.parse(note) : note || '',
        };
    },
    resource: booking => {
        const { resourceInfo } = booking;
        const note = resourceInfo?.note || resourceInfo?.formattedNote || '';
        return {
            message: note,
            formattedNote: note.indexOf('{') === 0 ? JSON.parse(note) : note || '',
        };
    },
};

const BookingSidebarContext = createContext({
    formNotes: {
        booking: {
            note: '',
            edited: false,
        },
        project: {
            note: '',
            edited: false,
        },
        resource: {
            note: '',
            edited: false,
        },
    },
    updateAllEditedNotes: () => {
        // do nothing
    },
    setFormNotes: () => {
        // do nothing
    },
    getNoteUpdater: () => {
        // do nothing
    },
    activeTab: 1,
    setActiveTab: () => {
        // do nothing
    },
    changeActiveTab: () => {
        // do nothing
    },
    closeSidebar: () => {
        // do nothing
    },
    currentSelectedBooking: {},
});

function defaultNotesValues(currentSelectedBooking) {
    return {
        selectedBooking: currentSelectedBooking,
        booking: {
            note: noteSelector.booking(currentSelectedBooking),
            edited: false,
        },
        project: {
            note: noteSelector.project(currentSelectedBooking),
            edited: false,
        },
        resource: {
            note: noteSelector.resource(currentSelectedBooking),
            edited: false,
        },
    };
}

export const BookingSidebarContextProvider = React.memo(props => {
    const { children, initialActiveTab, currentSelectedBooking, schedulerRef } = props;

    const dispatch = useDispatch();
    const { updateBooking } = useBooking(currentSelectedBooking);
    const { updateProjectWithoutSelectionClear: updateProject } = useProjectAction(schedulerRef);

    const [showUpdateNoteConfirmModal, setShowUpdateNoteConfirmModal] = useState(false);
    const [unsavedContentActions, setUnsavedContentActions] = useState({
        cancel: () => {
            // do nothing
        },
        discard: () => {
            // do nothing
        },
        save: () => {
            // do nothing
        },
    });
    const [activeTab, setActiveTab] = useState(initialActiveTab);

    useEffect(() => {
        setActiveTab(initialActiveTab);
    }, [initialActiveTab]);

    const [formNotes, setFormNotes] = useState(() => defaultNotesValues(currentSelectedBooking));

    const getNoteUpdater = useCallback((formNotes, dispatch, updateBooking, updateProject, tab, schedulerRef) => {
        const note = formNotes?.[tab]?.note || {};
        const updaters = {
            booking: () => {
                const newBooking = {
                    ...formNotes.selectedBooking,
                    note: {
                        formattedNote: note?.formattedNote ? JSON.stringify(note?.formattedNote) : '',
                        message: note?.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' ') || '',
                    },
                };
                updateBooking(newBooking, null, false, {
                    clearSelection: false,
                });
                setFormNotes({
                    ...formNotes,
                    booking: {
                        note: {
                            ...newBooking.note,
                            formattedNote: note?.formattedNote,
                        },
                        edited: false,
                    },
                });
            },
            project: () => {
                const formattedNoteAsString = note?.formattedNote ? JSON.stringify(note?.formattedNote) : '';
                const dataUpdated = {
                    project: {
                        formattedNote: formattedNoteAsString,
                        note: note?.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' ') || '',
                    },
                    options: {
                        skipCustomFieldsValidation: true,
                    },
                };
                updateProject(formNotes.selectedBooking.project._id, dataUpdated, () => {
                    setFormNotes({
                        ...formNotes,
                        project: {
                            note: {
                                ...dataUpdated.project,
                                formattedNote: note?.formattedNote,
                            },
                            edited: false,
                        },
                    });
                });
                const projectBookings = schedulerRef.current.control.events.findAll(
                    event => event.data.project?._id === formNotes.selectedBooking.project._id
                );
                updateDaypilotEvents(
                    projectBookings.map(booking => ({
                        ...booking.data,
                        project: {
                            ...formNotes.selectedBooking.project,
                            formattedNote: formattedNoteAsString,
                        },
                    })),
                    null,
                );
            },
            resource: () => {
                const formattedNoteAsString = note?.formattedNote ? JSON.stringify(note?.formattedNote) : '';
                const dataUpdated = {
                    companySpecific: {
                        formattedNote: formattedNoteAsString,
                        note: note?.message?.replace(/<[^>]*>/g, '').replace(/[\n\r]+/g, ' ') || '',
                    },
                    options: {
                        skipCustomFieldsValidation: true,
                    },
                };
                dispatch(
                    updateResource.request(formNotes.selectedBooking.resourceInfo._id, dataUpdated, () => {
                        setFormNotes({
                            ...formNotes,
                            resource: {
                                note: {
                                    ...dataUpdated.companySpecific,
                                    formattedNote: note?.formattedNote,
                                },
                                edited: false,
                            },
                        });
                    })
                );
                const resourceBookings = schedulerRef.current.control.events.findAll(
                    event => event.data?.resourceInfo?._id === formNotes.selectedBooking.resourceInfo._id
                );
                updateDaypilotEvents(
                    resourceBookings.map(booking => ({
                        ...booking.data,
                        resourceInfo: {
                            ...formNotes.selectedBooking.resourceInfo,
                            note: formattedNoteAsString,
                        },
                    })),
                    null,
                );
            },
        };

        return updaters[tab];
    }, []);

    const update = useCallback(() => {
        if (formNotes.booking.edited) {
            const updater = getNoteUpdater(formNotes, dispatch, updateBooking, updateProject, 'booking', schedulerRef);
            updater();
        }
        if (formNotes.resource.edited) {
            const updater = getNoteUpdater(formNotes, dispatch, updateBooking, updateProject, 'resource', schedulerRef);
            updater();
        }
        if (formNotes.project.edited) {
            const updater = getNoteUpdater(formNotes, dispatch, updateBooking, updateProject, 'project', schedulerRef);
            updater();
        }
    }, [dispatch, formNotes, getNoteUpdater, schedulerRef, updateBooking, updateProject]);

    const changeActiveTab = useCallback(
        tabId => {
            const anyNoteChanged = Object.values(formNotes).some(note => note?.edited);

            if (activeTab === TABS.NOTE_TAB.id && anyNoteChanged) {
                setShowUpdateNoteConfirmModal(true);
                setUnsavedContentActions({
                    cancel: () => {
                        setShowUpdateNoteConfirmModal(false);
                    },
                    discard: () => {
                        setFormNotes(defaultNotesValues(currentSelectedBooking));
                        setShowUpdateNoteConfirmModal(false);
                        setActiveTab(tabId);
                    },
                    save: () => {
                        update();
                        setShowUpdateNoteConfirmModal(false);
                        setActiveTab(tabId);
                    },
                });
            } else {
                setActiveTab(tabId);
            }
        },
        [activeTab, currentSelectedBooking, formNotes, update]
    );

    const closeSidebar = useCallback(() => {
        const anyNoteChanged = Object.values(formNotes).some(note => note?.edited);

        if (anyNoteChanged) {
            setShowUpdateNoteConfirmModal(true);
            setUnsavedContentActions({
                cancel: () => {
                    setShowUpdateNoteConfirmModal(false);
                },
                discard: () => {
                    setFormNotes(defaultNotesValues(currentSelectedBooking));
                    setShowUpdateNoteConfirmModal(false);
                    dispatch(closeSidebarRequest.request());
                },
                save: () => {
                    update();
                    setShowUpdateNoteConfirmModal(false);
                    dispatch(closeSidebarRequest.request());
                },
            });
        } else {
            dispatch(closeSidebarRequest.request());
        }
    }, [currentSelectedBooking, dispatch, formNotes, update]);

    useEffect(() => {
        setFormNotes(prevValues => {
            if (currentSelectedBooking?.id === prevValues.bookingId) {
                return prevValues;
            }

            return defaultNotesValues(currentSelectedBooking);
        });
    }, [currentSelectedBooking]);

    usePressEscape(unsavedContentActions.cancel);

    return (
        <BookingSidebarContext.Provider
            value={{
                formNotes,
                setFormNotes,
                getNoteUpdater,
                activeTab,
                setActiveTab,
                currentSelectedBooking,
                changeActiveTab,
                closeSidebar,
                updateAllEditedNotes: update,
            }}
        >
            <Modal
                contentClassName="rounded-0 mt-5 position-relative"
                isOpen={showUpdateNoteConfirmModal}
                fade={false}
                style={{ width: '590px' }}
            >
                <Header />
                <ModalContent>
                    <EscButton color="close" onClick={unsavedContentActions.cancel}>
                        <Close />
                        <div>esc</div>
                    </EscButton>
                    <div className="clearfix" />
                    <div className="my-4 mr-1 text-center">
                        You have unsaved notes changes, would like to save them?
                    </div>
                    <Alert color="warning" className="mb-4">
                        <strong>Warning!</strong> If you click discard all unsaved changes would be removed
                    </Alert>
                </ModalContent>
                <ModalFooter>
                    <CancelButton
                        name="Discard"
                        onClick={unsavedContentActions.discard}
                        dataCy="button--cancel-save-notes"
                    />
                    <SuccessButton name="Update" onClick={unsavedContentActions.save} dataCy="button--save-notes" />
                </ModalFooter>
            </Modal>
            {children}
        </BookingSidebarContext.Provider>
    );
});

export function useBookingSidebarContext() {
    return useContext(BookingSidebarContext);
}
