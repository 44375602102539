import { projectChildObject } from './projectChild';
import { addMenuRow } from './additionalRows';
import { store } from '../../../../store';

export const projectObject = ({
    project,
    dpChildrenResources,
    contextMenu,
    loadResources,
    accountRoleRights,
    projectResourceContextMenu,
    filters,
    keyWords,
}) => {
    const reduxState = store.getState();
    const sortDirection = reduxState.account.preferences.schedulerColumns.projectColumns.sortedColumn.sortDirection;

    const dpMenu = contextMenu && contextMenu(project);

    const projectChildren = project.expanded
        ? project.resources
              ?.map(resourceId => {
                  const resource = dpChildrenResources[resourceId];

                  if (!resource) {
                      return undefined;
                  }

                  return projectChildObject(
                      resource,
                      project,
                      projectResourceContextMenu,
                      filters.resources?.filters?.includes?.(resourceId)
                  );
              })
              .filter(Boolean)
        : [];

    projectChildren.sort((a, b) =>
        sortDirection === 'ASCENDING' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );

    const menuRow = addMenuRow(project, {
        accountRoleRights,
        resourceKeyWord: keyWords.resourceKeyWord,
    });
    if (
        project.expanded &&
        projectChildren &&
        menuRow &&
        (project.hasRightsToProject || project.hasPMRightsToProject)
    ) {
        projectChildren.push(menuRow);
    }

    return {
        name: project.name,
        id: project._id,
        tags: {
            type: project.type,
            parent: loadResources,
            menuRow: false,
            isEventRow: false,
            project,
            isProjectRow: true,
            isResourceRow: false,
        },
        children: projectChildren,
        expanded: project.expanded,
        isParent: loadResources,
        eventHeight: 0,
        marginBottom: 10,
        minHeight: 36,
        cssClass: 'parent-scheduler-row',
        areas: dpMenu?.items?.length
            ? [
                  {
                      visibility: 'Visible',
                      action: 'ContextMenu',
                      bottom: 0,
                      width: 30,
                      html: `<i data-cy="scheduler-row-project-menu-${project?._id ||
                          ''}" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                      cssClass: 'resource-action-menu',
                      top: 0,
                      menu: contextMenu(project),
                      right: 0,
                  },
              ]
            : [],
    };
};
