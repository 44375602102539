import React, { createContext, useContext, useMemo, useState } from 'react';
import { TYPE_EVENT, TYPE_REGULAR } from '../../../../../enums/projectEnum';
import { useAppKeyWords } from '../../../../../hooks';

const useFilterButtons = ({ projectPluralKeyWord, onlyEvents, onlyProjects }) => {
    return useMemo(() => {

        const projectsFilterBtn = {
            text: projectPluralKeyWord,
            property: 'type',
            value: TYPE_REGULAR.value,
        };

        const eventsFilterBtn = {
            text: `${TYPE_EVENT.display}s`,
            property: 'type',
            value: TYPE_EVENT.value,
        };

        if (onlyProjects) {
            return [{
                ...projectsFilterBtn,
                selected: true
            }];
        }

        if (onlyEvents) {
            return [{
                ...eventsFilterBtn,
                selected: true
            }];
        }


        return [{
            ...projectsFilterBtn,
            selected: true
        }, {
            ...eventsFilterBtn
        }];
    }, [onlyEvents, onlyProjects, projectPluralKeyWord]);
};

const defaultContextValue = {};

const Context = createContext(defaultContextValue);

const Provider = props => {
    const { children, onlyEvents, onlyProjects } = props;

    const { projectPluralKeyWord } = useAppKeyWords();

    const [projectName, setProjectName] = useState('');

    const filterButtons = useFilterButtons({ projectPluralKeyWord, onlyEvents, onlyProjects });

    const [filterButtonApplied, setFilterButtonApplied] = useState(() => {
        return filterButtons?.find(button => button.selected);
    });

    const value = useMemo(
        () => ({
            filterButtons,
            filterButtonApplied,
            setFilterButtonApplied,
            isEventsFilterApplied: filterButtonApplied?.value === TYPE_EVENT.value,
            projectName,
            setProjectName
        }),
        [filterButtonApplied, filterButtons, projectName]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const SchedulerProjectsListProvider = React.memo(Provider);

export const useSchedulerProjectsListContext = () => {
    const context = useContext(Context);

    if (!context || context === defaultContextValue) {
        throw new Error('useSchedulerProjectsListContext should be used within SchedulerProjectsListProvider');
    }

    return context;
};
