class SchedulerUpdateObserver {
    constructor() {
        this.observers = [];
    }

    addObserver(observer) {
        if (!this.observers.includes(observer)) {
            this.observers.push(observer);
        }
    }

    removeObserver(observer) {
        const index = this.observers.indexOf(observer);
        if (index !== -1) {
            this.observers.splice(index, 1);
        }
    }

    notify(args) {
        this.observers.forEach(observer => observer(args));
    }

    notifyOnce(args) {
        this.observers.forEach(observer => {
            observer(args);
            this.removeObserver(observer);
        });
    }
}

export default new SchedulerUpdateObserver();
