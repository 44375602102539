import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import LargeMode from './partials/largeMode';
import MediumMode from './partials/mediumMode';
import { WAITING_FOR_APPROVAL } from '../../../../enums/bookingTypeEnum';
import { TYPE_UNASSIGNED } from '../../../../enums/resourceEnum';

const Event = ({
    booking,
    eventHeightMode,
    loggedInRights,
    mode,
    viewObject,
    totalEventTime,
    isDependencyExtensionInstalled,
    isDeadlinesExtensionInstalled,
    isAbleToEditRequest,
    hasScheduleRightsForResourceAndProject,
    isAbleToApproveRejectRequest,
}) => {
    const categoryName = booking.categoryTemplate ? ` - ${booking.categoryTemplate.name}` : '';

    const projectId = booking.project?._id;
    const resourceId = booking.resourceInfo?._id || booking.resource;
    const hasScheduleRight = hasScheduleRightsForResourceAndProject(
        projectId,
        resourceId,
        booking.resourceInfo?.type === TYPE_UNASSIGNED.value,
        {
            resource: booking.resourceInfo,
            project: booking.project,
        }
    );

    const shouldBeBookingMenuDisabled =
        !booking.hasEditRights || booking.private || !booking.isPermitted || !hasScheduleRight;

    const renderMenu =
        (booking.type !== WAITING_FOR_APPROVAL.value && !shouldBeBookingMenuDisabled) ||
        (booking.type === WAITING_FOR_APPROVAL.value && (isAbleToApproveRejectRequest || isAbleToEditRequest));

    if (includes(['L', 'XL'], eventHeightMode)) {
        return (
            <LargeMode
                booking={booking}
                totalEventTime={totalEventTime}
                loggedInRights={loggedInRights}
                mode={mode}
                isDependencyExtensionInstalled={isDependencyExtensionInstalled}
                isDeadlinesExtensionInstalled={isDeadlinesExtensionInstalled}
                eventHeightMode={eventHeightMode}
                isAbleToEditRequest={isAbleToEditRequest}
                renderMenu={renderMenu}
            />
        );
    } else if (includes(['S', 'M'], eventHeightMode)) {
        return (
            <MediumMode
                booking={booking}
                viewNotes={loggedInRights.viewNotes}
                totalEventTime={totalEventTime}
                categoryName={categoryName}
                loggedInRights={loggedInRights}
                mode={mode}
                viewObject={viewObject}
                isDependencyExtensionInstalled={isDependencyExtensionInstalled}
                isDeadlinesExtensionInstalled={isDeadlinesExtensionInstalled}
                eventHeightMode={eventHeightMode}
                isAbleToEditRequest={isAbleToEditRequest}
                renderMenu={renderMenu}
            />
        );
    }

    return null;
};

Event.propTypes = {
    booking: PropTypes.object,
    totalEventTime: PropTypes.string,
    loggedInRights: PropTypes.object,
    eventHeightMode: PropTypes.string,
    isDependencyExtensionInstalled: PropTypes.bool,
    mode: PropTypes.string,
    viewObject: PropTypes.shape({
        isSingleResourceView: PropTypes.bool,
        isResourceGroupView: PropTypes.bool,
    }),
};

Event.defaultProps = {
    booking: {},
    totalEventTime: '',
    loggedInRights: {},
    eventHeightMode: '',
    mode: '',
    viewObject: {},
    isDependencyExtensionInstalled: false,
};

export default Event;
