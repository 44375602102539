import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SuccessButton from '../buttons/success';
import { SanitizeHTML } from './SanitizeHTML';
import { useHasRights } from '../../hooks';

const useStyles = makeStyles({
    TextAndImageRow_main: {
        display: 'flex',
        width: '100%',
        fontSize: '14px',
        flexDirection: props => {
            if (props.imageBottom) {
                return 'column';
            } else {
                return 'row';
            }
        },
    },
    TextAndImageRow_text: {
        width: props => {
            if (props.imageBottom) {
                return '100%';
            }

            return props.imageSrc ? '50%' : '100%';
        },
        textAlign: 'initial',
        padding: '10px',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    TextAndImageRow_buttons: {
        margin: '10px -5px 5px -5px',
    },
    TextAndImageRow_title: {
        fontWeight: 600,
        fontSize: '27px',
        lineHeight: 'normal',
        marginBottom: '10px',
    },
    TextAndImageRow_description: {
        marginBottom: '20px',
        fontSize: '14px',
    },
    TextAndImageRow_image: {
        width: props => {
            if (props.imageBottom) {
                return '100%';
            }

            return '50%';
        },
        textAlign: 'center',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TextAndImageRow_img: {
        width: '100%',
        maxWidth: 'fit-content',
        borderRadius: '30px',
    },
});

const rights = [
    {
        rights: ['settingAddExt'],
        rule: 'one',
        name: 'canAddExt',
    },
];

export const TextAndImageRow = React.memo(props => {
    const { title, description, additionalInfo, buttons, imageSrc, imageLeft, onClickLinks, imageBottom } = props;
    const classes = useStyles(props);
    const { canAddExt } = useHasRights(rights);

    const textJSX = useCallback(() => {
        return (
            <div className={classes.TextAndImageRow_text}>
                {title && <div className={classes.TextAndImageRow_title}>{title}</div>}
                {additionalInfo && <div className={classes.TextAndImageRow_additionalInfo}>{additionalInfo}</div>}
                {description && <SanitizeHTML html={description} className={classes.TextAndImageRow_description} />}
                {Array.isArray(buttons) && (
                    <div className={classes.TextAndImageRow_buttons}>
                        {buttons.map((buttonConfig, index) => {
                            const { link, label, onClick } = buttonConfig;
                            let inner = (
                                <SuccessButton
                                    onClick={onClick || onClickLinks}
                                    name={label}
                                    type="btn-secondary"
                                    dataCy={`button--preview-${label}`}
                                />
                            );

                            const isInstallBtn = label?.toLowerCase() === 'install' && link === '/settings#extensions';

                            if (isInstallBtn) {
                                if (canAddExt) {
                                    inner = <a href={link}>{inner}</a>;
                                } else {
                                    inner = '';
                                }
                            } else if (link) {
                                inner = <a href={link}>{inner}</a>;
                            }
                            return <span key={index}>{inner}</span>;
                        })}
                    </div>
                )}
            </div>
        );
    }, [
        classes.TextAndImageRow_text,
        classes.TextAndImageRow_title,
        classes.TextAndImageRow_additionalInfo,
        classes.TextAndImageRow_description,
        classes.TextAndImageRow_buttons,
        title,
        additionalInfo,
        description,
        buttons,
        onClickLinks,
        canAddExt,
    ]);

    const imageJSX = useCallback(() => {
        return (
            <div className={classes.TextAndImageRow_image}>
                <img className={classes.TextAndImageRow_img} src={imageSrc}></img>
            </div>
        );
    }, [classes.TextAndImageRow_image, classes.TextAndImageRow_img, imageSrc]);

    if (!title && !description && !additionalInfo && !imageSrc) {
        return null;
    }

    if (imageSrc && imageBottom) {
        return (
            <div className={classes.TextAndImageRow_main}>
                {textJSX()}
                {imageJSX()}
            </div>
        );
    }

    return (
        <div className={classes.TextAndImageRow_main}>
            {!imageLeft && textJSX()}
            {imageSrc && imageJSX()}
            {imageLeft && textJSX()}
        </div>
    );
});

export const textAndImageRowPropTypes = {
    titleHeading: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    additionalInfo: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
    imageSrc: PropTypes.string.isRequired,
    imageLeft: PropTypes.bool,
    onClickLinks: PropTypes.func,
};
TextAndImageRow.propTypes = textAndImageRowPropTypes;

export default TextAndImageRow;
