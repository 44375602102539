import styled from 'styled-components';

export const PageContainer = styled.div`
    margin-top: -51px;
    padding: 0 !important;

    a,
    a:active,
    a:visited {
        color: #28283c;
        text-decoration: underline;

        :hover {
            text-decoration: none;
        }
    }
`;

export const LogoText = styled.p`
    font-weight: 700;
    font-size: 11px;
    color: #a9a9a9;
`;
export const Logo = styled.div`
    background: url('https://d329beqc2zk6g7.cloudfront.net/img/Loading-screen.png') no-repeat center center;
    width: 150px;
    height: 82px;
    background-size: contain;
    margin: auto;
`;
export const BgImage = styled.div`
    background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/cover/hub_planner_signup_cover.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;

    &.activation {
        background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/cover/hub_planner_signup_cover.jpg');
    }

    &.login {
        background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/cover/hub_planner_signup_cover.jpg');
    }

    &.signup {
        background-size: 100%;
        background-repeat: no-repeat;
        width: 80%;
        height: 100%;
        margin: auto;
        background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/signup_ep.jpg');
    }

    &.forgot {
        background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/cover/hub_planner_signup_cover.jpg');
    }

    &.reset {
        background-image: url('https://d329beqc2zk6g7.cloudfront.net/img/cover/hub_planner_signup_cover.jpg');
    }
`;
