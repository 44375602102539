import React from 'react';
import { format } from 'date-fns';
import { renderToString } from 'react-dom/server';
import { hasRole } from 'utils/rightsUtil';
import { disableEvent } from './helper';
import Phase from 'modules/scheduler/components/scheduler/phase';

export const handlePhaseEvent = (args, data) => {
    const [schedulerRef, resourceRoleRights] = data;
    const scheduler = schedulerRef.current.control;

    const parseArgsDate = argsDate => format(new Date(argsDate.value || argsDate), 'LLL do yyyy')

    const startDate = parseArgsDate(args.e.start);
    const endDate = parseArgsDate(args.e.end);
    const canEdit = hasRole(resourceRoleRights, 'settingProjectPhases')

    if (!canEdit) {
        disableEvent(args, scheduler.eventHeightMode);
    }

    args.e.bubbleHtml = `Phase - ${args.e.text} -  ${startDate} - ${endDate}`;

    args.cssClass = 'hub-mode-' + scheduler.eventHeightMode.toLowerCase();

    //Html is used for event floating
    args.e.html = renderToString(
        <Phase
            booking={args.e}
            eventHeightMode={scheduler.eventHeightMode}
            startDateFormatted={startDate}
            endDateFormatted={endDate}
            renderMenu={canEdit}
        />
    );
};
