import { axiosInstance } from './axiosInstance';
import FormData from 'form-data';

export const upload = file => {
    const data = new FormData();
    data.append('thumbnail', file);

    return axiosInstance
        .post('asset/upload', data, { 'Content-Type': 'multipart/form-data' })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const sendBookings = file => {
    const formData = new FormData();
    formData.append('csvFile', file);

    return axiosInstance
        .post('/importEvents', formData, { 'Content-Type': 'multipart/form-data' })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const sendTimeEntries = file => {
    const formData = new FormData();
    formData.append('csvFile', file);

    return axiosInstance
        .post('/importTimeEntries', formData, { 'Content-Type': 'multipart/form-data' })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};
