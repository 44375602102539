import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';

/**
 * @returns {*[]}
 */
export default () => [
    {
        id: 'resourceToilAccrued',
        display: `TOIL (accrued)`,
        displayTimeType: 'TOIL',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'resourceToilRemaining',
        display: `TOIL (remaining)`,
        displayTimeType: 'TOIL',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'resourceToilConsumed',
        display: `TOIL (consumed)`,
        displayTimeType: 'TOIL',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'resourceToilRequested',
        display: `TOIL (requested)`,
        displayTimeType: 'TOIL',
        displayColumnType: 'time',
        unitType: columnUnitTypes.TIME.value,
        columnType: COLUMN_TYPES_ENUM.TIME,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
];
