import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {*[]}
 */
export default dateState => [
    {
        id: 'reportedUtilizationBetween',
        display: `Reported Utilization - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'reportedUtilizationPrevious',
        display: `Reported Utilization - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedUtilizationAfter',
        display: `Reported Utilization - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.PERCENTAGE.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedAvailabilityBetween',
        display: `Reported Availability - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'reportedAvailabilityPrevious',
        display: `Reported Availability - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedAvailabilityAfter',
        display: `Reported Availability - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedOvertimeBetween',
        display: `Reported Overtime - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'reportedOvertimePrevious',
        display: `Reported Overtime - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedOvertimeAfter',
        display: `Reported Overtime - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedAvailabilityStatusBetween',
        display: `Reported Availability Status - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
    {
        id: 'reportedAvailabilityStatusPrevious',
        display: `Reported Availability Status - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedAvailabilityStatusAfter',
        display: `Reported Availability Status - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedUtilizationStatusBetween',
        display: `Utilization Status - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedUtilizationStatusPrevious',
        display: `Utilization Status - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedUtilizationStatusAfter',
        display: `Utilization Status - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: false,
    },
    {
        id: 'reportedFTEUtilizationBetween',
        display: 'Reported FTE',
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Reported Time',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.REPORTED,
        heatMapSupport: true,
    },
];
