import authorization from 'services/auth/authorization';
import MyVacation from './containers/myVacation';
import VacationRequests from './containers/vacationRequests';
import MyResourceRequests from './containers/myResourceRequests';
import TeamResourceRequests from './containers/teamResourceRequests';
import Home from './components/home';
import NoExtension from './components/noExtension';
import { VACATION_REQUEST, RESOURCE_REQUEST } from 'enums/extensionShortIdEnum';

const MyVacationAuth = authorization({
    extensions: [VACATION_REQUEST],
    errorComponent: NoExtension,
    templateDataItem: { noExtension: 'NO_VACATION_EXT' },
})(MyVacation);

const VacationRequestsAuth = authorization({
    allowedRoleRights: ['manageAllVacations', 'canEditRequestVacation'],
    extensions: [VACATION_REQUEST],
    errorComponent: NoExtension,
    templateDataItem: {
        noExtension: 'NO_VACATION_EXT',
        noRights: 'NO_VACATION_PAGE_RIGHTS',
    },
})(VacationRequests);

const MyResourceRequestsAuth = authorization({
    extensions: [RESOURCE_REQUEST],
    errorComponent: NoExtension,
    templateDataItem: { noExtension: 'NO_RESOURCE_EXT' },
})(MyResourceRequests);

const TeamResourceRequestsAuth = authorization({
    allowedRoleRights: ['manageAllResourceRequests', 'canEditRequestResource'],
    extensions: [RESOURCE_REQUEST],
    errorComponent: NoExtension,
    templateDataItem: {
        noExtension: 'NO_RESOURCE_EXT',
        noRights: 'NO_RESOURCE_PAGE_RIGHTS',
    },
})(TeamResourceRequests);

export default [
    {
        path: '/requests',
        component: Home,
        title: 'HUB Planner | Requests',
        child: [
            {
                path: '/requests/my-vacations',
                component: MyVacationAuth,
            },
            {
                path: '/requests/vacation-requests',
                component: VacationRequestsAuth,
            },
            {
                path: '/requests/my-resource-requests',
                component: MyResourceRequestsAuth,
            },
            {
                path: '/requests/team-resource-requests',
                component: TeamResourceRequestsAuth,
            },
        ],
    },
];
