import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Image from './Image';

const useStyles = makeStyles({
    RelatedExtensions_main: {
        width: '100%',
    },
    RelatedExtensions_extensions: {
        display: 'flex',
        margin: '0px -10px',
        justifyContent: 'space-between',
    },
    RelatedExtensions_img: {
        margin: '0px 10px',
    },
});

// TODO: Manage Video and GIF as well.
export const RelatedExtensions = React.memo(props => {
    const { elements } = props;
    const classes = useStyles();

    if (!Array.isArray(elements) || elements.length === 0) {
        return null;
    }

    return (
        <div className={classes.RelatedExtensions_main}>
            <h4>Related Extensions</h4>
            <div className={classes.RelatedExtensions_extensions}>
                {elements.slice(0, 3).map((elementConfig, index) => {
                    const { imageSrc, link } = elementConfig;
                    let inner = (
                        <Image className={classes.RelatedExtensions_img} height={'150px'} imageSrc={imageSrc} />
                    );
                    if (link) {
                        inner = <a href={link}>{inner}</a>;
                    }
                    return <span key={index}>{inner}</span>;
                })}
            </div>
        </div>
    );
});

export const relatedExtensionsPropTypes = PropTypes.shape({
    elements: PropTypes.arrayOf(
        PropTypes.shape({
            imageSrc: PropTypes.string,
            videoSrc: PropTypes.string,
            link: PropTypes.string,
        })
    ),
});
RelatedExtensions.propTypes = relatedExtensionsPropTypes;

export default RelatedExtensions;
