import { isNumber, isString } from 'underscore';

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

function toFixed(val, n) {
    let result = val.toFixed(n);

    if (result === val) {
        return val.toString();
    }

    let strIndex = result.indexOf('.00');
    if (-1 !== strIndex) {
        result = parseInt(result.substring(0, strIndex));
    }

    return parseFloat(result);
}

export const formatNumber = (number, commas) => {
    if (isNumber(number)) {
        number = toFixed(parseFloat(number), 2);
        if (commas) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
    return number;
};

export const formatPrice = price => {
    const priceToUser = price && isNumber(price) ? price : 0;

    return priceToUser.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const capitalizeFirstLetter = string =>
    string && isString(string) ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';

export const capitalizeSentence = words =>
    words
        .split(' ')
        .map(capitalizeFirstLetter)
        .join(' ');

export const escapeRegExp = match => {
    if ('string' !== typeof match) {
        throw new TypeError('Expected a string');
    }

    return match.replace(matchOperatorsRegex, '\\$&');
};

export const removeNonAsciiChars = string => string.replace(/[^\x20-\x7E]+/g, '');

export const removeSpecialChars = string => string.replace(/[&\/\\#,+()\-$~%.'":*?<>{}]/g, '');

export const normalizeString = (stringValue = '') => {
    return stringValue.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
