import Schema from 'form-schema-validation';
import { errorMessages } from 'utils/schemaUtil';

export const eventFormSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
            defaultValue: '',
        },
        projectCode: {
            type: String,
            required: false,
            defaultValue: '',
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = name => {
    return Object.assign({}, eventFormSchema.getDefaultValues(), name ? { name } : {});
};
