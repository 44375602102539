import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { APPROVED } from 'enums/bookingTypeEnum';
import { ListTile } from '@hub-mono/ui';
import { formatDate, DATE_FORMATS, removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';
import styled from 'styled-components';

const StyledListTile = styled(ListTile)`
    height: 100%;
`;

const UpcomingEvents = ({ events, vacationEventName, resourceId, requestStartDate, requestEndDate }) => {
    const { startYear, endYear } = useMemo(() => {
        return {
            startYear: formatDate(requestStartDate, DATE_FORMATS.YEAR_FORMAT),
            endYear: formatDate(requestEndDate, DATE_FORMATS.YEAR_FORMAT),
        };
    }, [requestStartDate, requestEndDate]);

    const formattedEvents = useMemo(() => {
        return (events.size ? events.toJSON() : [])
            .filter(
                event => event.isFuture && ((event.isVacation && APPROVED.value === event.type) || !event.isVacation)
            )
            .map(event => {
                return {
                    icon: event.isVacation ? 'event' : 'featuredSeasonalAndGifts',
                    title: event.isVacation && event.resourceId === resourceId ? `My ${vacationEventName}` : event.name,
                    subtitle: event.isVacation ? vacationEventName : 'Public Holiday',
                    text: `in ${event.diff} days`,
                    caption: formatDate(removeUTCZuluFromDateTimestamp(event.date), DATE_FORMATS.MONTH_SHORT_DAY),
                };
            });
    }, [events, resourceId, vacationEventName]);

    return (
        <StyledListTile
            title="Upcoming Events & Holidays"
            subtitle={startYear === endYear ? startYear : ''}
            options={formattedEvents}
            emptyText="Approved Events and Public Holidays will appear here."
        />
    );
};

UpcomingEvents.propTypes = {
    events: PropTypes.object.isRequired,
    vacationEventName: PropTypes.string.isRequired,
    resourceId: PropTypes.string.isRequired,
};

export default UpcomingEvents;
