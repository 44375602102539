import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeGetResourceGroupById } from 'selectors/resourceGroup';
import { makeGetProjectGroupById } from 'selectors/projectGroup';

export const useGroup = params => {
    const resourceGroupSelector = useMemo(() => makeGetResourceGroupById(params.resourceGroupId), [
        params.resourceGroupId,
    ]);
    const projectGroupSelector = useMemo(() => makeGetProjectGroupById(params.projectGroupId), [params.projectGroupId]);
    const resourceGroup = useSelector(resourceGroupSelector);
    const projectGroup = useSelector(projectGroupSelector);

    return { resourceGroup, projectGroup, current: resourceGroup.hasOwnProperty('_id') ? resourceGroup : projectGroup };
};
