import React from 'react';
import { first } from 'underscore';
import ReportLayout from './template/template';
import PermissionDenied from 'modules/report/components/permissionDenied';
import TemplateBreadcrumb from './components/breadcrumbs/templateBreadcrumb';
import ReportDetailBreadcrumb from './containers/breadcrumbs/reportDetailBreadcrumb';
import ReportLevel3Breadcrumb from './containers/breadcrumbs/reportLevel3Breadcrumb';
import ReportLevel4Breadcrumb from './components/breadcrumbs/reportLevel4Breadcrumb';
import ReportTypeBreadcrumb from './containers/breadcrumbs/reportTypeBreadcrumb';
import { HomeIcon } from './components/styles';
import {
    urlParamValidator,
    idUrlParamValidator,
    templateTypeUrlParamValidator,
    tokenUrlValidator,
} from './utils/validator';
import { CUSTOM_REPORT, findReportTypeByRoute } from './enums/reportTypeEnum';
import { capitalizeFirstLetter } from 'utils/formatingUtil';
import { hasOneOfRoles, hasRole, hasReportTemplateRights } from 'utils/rightsUtil';
import authorization from 'services/auth/authorization';

const authParameters = {
    allowedRoleRights: ['manageReports', 'manageMyReports'],
    errorComponent: PermissionDenied,
    additionalLimits: [
        account =>
            hasOneOfRoles(account.resourceRoleRights, ['manageReports', 'manageMyReports']) ||
            (!hasRole(account.resourceRoleRights, 'manageReports') &&
                hasRole(account.resourceRoleRights, 'pmManageReports') &&
                account.isProjectManager),
        (account, extensions, params) =>
            !params.reportType ||
            (params.reportType &&
                hasReportTemplateRights(findReportTypeByRoute(params.reportType) || {}, account, extensions)),
    ],
};

const HomeAuth = authorization(authParameters)(React.lazy(() => import('./containers/home')));
const MyDownloadsAuth = authorization(authParameters)(React.lazy(() => import('./containers/myDownloads')));
const ShareAuth = authorization(authParameters)(React.lazy(() => import('./containers/share')));
const MyTemplateAuth = authorization(authParameters)(React.lazy(() => import('./containers/myTemplate')));
const ReportLevelAuth = authorization(authParameters)(React.lazy(() => import('./components/reportLevel')));
const TemplateDetailsAuth = authorization(authParameters)(React.lazy(() => import('./containers/templateDetails')));
const ReportAuth = authorization(authParameters)(React.lazy(() => import('./containers/report')));

export default [
    {
        path: '/reports',
        layout: ReportLayout,
        breadcrumb: HomeIcon,
        title: 'HUB Planner | Reports',
        child: [
            {
                path: '/reports',
                exact: true,
                component: HomeAuth,
                breadcrumb: HomeIcon,
            },
            {
                path: '/reports/my-downloads',
                component: MyDownloadsAuth,
            },
            {
                path: '/reports/share/:token',
                paramValidator: tokenUrlValidator,
                component: ShareAuth,
            },
            {
                path: '/reports/templates/:type',
                paramValidator: templateTypeUrlParamValidator,
                exact: true,
                breadcrumb: ({ match }) =>
                    `${match.params.type === first(CUSTOM_REPORT.subTypes).value ? 'My ' : ''}${capitalizeFirstLetter(
                        match.params.type
                    )} Templates`,
                component: TemplateDetailsAuth,
            },
            {
                path: '/reports/templates/:type/group/:reportsGroup',
                paramValidator: templateTypeUrlParamValidator,
                exact: true,
                breadcrumb: ({ match }) =>
                    `${match.params.type === first(CUSTOM_REPORT.subTypes).value ? 'My ' : ''}${capitalizeFirstLetter(
                        match.params.type
                    )} Templates`,
                component: TemplateDetailsAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/group/:groupId',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: false,
                component: MyTemplateAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/group/:groupId/:id',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: ReportDetailBreadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/group/:groupId/:id/:subId',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: ReportLevel3Breadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/group/:groupId/:id/:subId/:date',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: ReportLevel4Breadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/templates/:type/:templateId',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: TemplateBreadcrumb,
                component: MyTemplateAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/:id',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: ReportDetailBreadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/:id/:subId',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                exact: true,
                breadcrumb: ReportLevel3Breadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/templates/:type/:templateId/:id/:subId/:date',
                paramValidator: params => idUrlParamValidator(params, 'templateId'),
                breadcrumb: ReportLevel4Breadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/:reportType',
                exact: true,
                paramValidator: urlParamValidator,
                breadcrumb: ReportTypeBreadcrumb,
                component: TemplateDetailsAuth,
            },
            {
                path: '/reports/:reportType/:subType',
                exact: true,
                paramValidator: urlParamValidator,
                breadcrumb: ReportTypeBreadcrumb,
                component: ReportAuth,
            },
            {
                path: '/reports/:reportType/:subType/:id',
                exact: true,
                breadcrumb: ReportDetailBreadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/:reportType/:subType/:id/:subId',
                exact: true,
                breadcrumb: ReportLevel3Breadcrumb,
                component: ReportLevelAuth,
            },
            {
                path: '/reports/:reportType/:subType/:id/:subId/:date',
                breadcrumb: ReportLevel4Breadcrumb,
                component: ReportLevelAuth,
            },
        ],
    },
];
