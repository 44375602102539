export const unassignedChildObject = (resource, contextMenu) => {
    const contextMenuItems =
        (contextMenu?.items || []).filter(item => (item.shouldDisplay ? item.shouldDisplay({ resource }) : true)) || [];
    return {
        name: resource.name,
        id: resource._id,
        marginTop: 24,
        marginBottom: 0,
        tags: {
            isEventRow: false,
            menuRow: false,
            isProjectRow: false,
            parent: false,
            unassignedRow: true,
            isResourceRow: false,
            resource: resource,
        },
        cssClass: 'unassigned-child',
        areas: contextMenuItems.length
            ? [
                  {
                      visibility: 'Visible',
                      action: 'ContextMenu',
                      bottom: 0,
                      width: 30,
                      html: `<i data-cy="scheduler-row-uw-resource-menu-${resource?._id ||
                          ''}" class="resource-context-menu-icon fa fa-ellipsis-v"/>`,
                      cssClass: 'resource-action-menu',
                      top: 0,
                      menu: contextMenu,
                      right: 0,
                  },
              ]
            : [],
    };
};
