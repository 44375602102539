import { values, sortBy, map, find } from 'lodash';

export const SCHEDULER = { display: 'Scheduler', value: 'scheduler' };
export const TIMESHEETS = { display: 'Timesheets', value: 'time_sheets' };
export const DASHBOARD = { display: 'Dashboard', value: 'dashboard' };
export const SETTINGS = { display: 'Settings', value: 'main#/settings' };
export const REPORTS = { display: 'Reports', value: 'main#/reports' };

const pages = { SCHEDULER, TIMESHEETS, DASHBOARD, SETTINGS, REPORTS };

export const pagesArray = sortBy(values(pages), page => page.value);
export const valuesArray = map(values(pages), 'value');
export const getPageByValue = pageValue => find(pagesArray, page => pageValue === page.value);
