import { subGroupsPositionEnum } from '../modules/scheduler/enums/subGroupsPositionEnum';
import { createSelector } from 'reselect';
import {
    defaultLineColor,
    defaultLinkPosition,
    defaultLinkStyle,
    defaultLinkDisplay,
} from '../modules/scheduler/enums/bookingLinkEnums';
import {
    defaultDeadlineColor,
    defaultDeadlineDisplay,
    defaultDeadlineLineColor,
    defaultDeadlineLinkPosition,
    defaultDeadlineLinkStyle,
    defaultDeadlineTooltipDisplay,
} from '../modules/scheduler/enums/deadlines';

export const getAccountId = state => state.account._id;
export const getLoggedInId = state => state.account.resourceId;
export const getLoggedInRole = state => state.account.resourceRole;
export const getAccount = state => state.account;
export const getAccountGridPreferences = state => state.account.preferences.grid;
export const getAccountPreferences = state => state.account.preferences;
export const getAccountNotifications = state => state.account.preferences.notifications;
export const selectAccountLandingPage = state => state.account.landingPage;
export const selectAccountMFA = state => state.account.mfa;
export const selectSubGroupsPosition = state =>
    state.account.preferences.subGroupsPosition || subGroupsPositionEnum.bottom;
export const selectEventHeightMode = state => getAccountGridPreferences(state).eventHeightMode;

export const selectBookingLinkStyle = state => getAccountGridPreferences(state).bookingLinkStyle;
export const selectBookingLinkColor = state => getAccountGridPreferences(state).bookingLinkColor;
export const selectBookingLinkPosition = state => getAccountGridPreferences(state).bookingLinkPosition;
export const selectBookingLinkDisplay = state => getAccountGridPreferences(state).bookingLinkDisplay;

export const selectBookingLinkConfig = createSelector(
    selectBookingLinkStyle,
    selectBookingLinkColor,
    selectBookingLinkPosition,
    selectBookingLinkDisplay,
    (
        style = defaultLinkStyle,
        color = defaultLineColor,
        position = defaultLinkPosition,
        display = defaultLinkDisplay
    ) => {
        return {
            style,
            color,
            position,
            display,
        };
    }
);

export const selectDeadlineLinkStyle = state => getAccountGridPreferences(state).deadlineLinkStyle;
export const selectDeadlineLinkColor = state => getAccountGridPreferences(state).deadlineLinkColor;
export const selectDeadlineColor = state => getAccountGridPreferences(state).deadlineColor;
export const selectDeadlineLinkPosition = state => getAccountGridPreferences(state).deadlineLinkPosition;
export const selectDeadlineDisplay = state => getAccountGridPreferences(state).deadlineDisplay;
export const selectDeadlineLinkTooltipDisplay = state => getAccountGridPreferences(state).deadlineTooltipDisplay;

export const selectDeadlinesConfig = createSelector(
    selectDeadlineLinkStyle,
    selectDeadlineLinkColor,
    selectDeadlineLinkPosition,
    selectDeadlineDisplay,
    selectDeadlineLinkTooltipDisplay,
    selectDeadlineColor,
    (
        style = defaultDeadlineLinkStyle,
        linkColor = defaultDeadlineLineColor,
        position = defaultDeadlineLinkPosition,
        display = defaultDeadlineDisplay,
        tooltipDisplay = defaultDeadlineTooltipDisplay,
        deadlineColor = defaultDeadlineColor,
    ) => {
        return {
            style,
            linkColor,
            position,
            display,
            tooltipDisplay,
            deadlineColor
        };
    }
);
