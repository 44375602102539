import React from 'react';
import PropTypes from 'prop-types';

const CategoryGroupItem = ({ item }) => <span>{item.group}</span>;

CategoryGroupItem.propTypes = {
    item: PropTypes.shape({
        group: PropTypes.string,
        searchTerm: PropTypes.string,
    }).isRequired,
};

export default CategoryGroupItem;
