import { contains } from 'underscore';

export function isSingleVacationFlow(flow) {
    return 'VACATION_FLOW_SINGLE' === flow;
}

export function isSingleRequestFlow(flow) {
    return 'RES_REQUEST_FLOW_SINGLE' === flow;
}

export function isMyVacationRequests(flow) {
    return 'MY_VACATION_REQUESTS' === flow;
}

export function isMyResourceRequests(flow) {
    return 'MY_RESOURCE_REQUESTS' === flow;
}

export function isSingleFlow(flow) {
    return isSingleVacationFlow(flow) || isSingleRequestFlow(flow);
}

export function isNotSingleFlow(flow) {
    return !isSingleVacationFlow(flow) && !isSingleRequestFlow(flow);
}

export function isMyRequests(flow) {
    return isMyResourceRequests(flow) || isMyVacationRequests(flow);
}

export function isNotMyRequests() {
    return !isMyResourceRequests() && !isMyVacationRequests();
}

export function isVacationFlow(flow) {
    return contains(['MY_VACATION_REQUESTS', 'VACATION_FLOW', 'VACATION_GRID', 'VACATION_FLOW_SINGLE'], flow);
}

export function isRequestResourceFlow(flow) {
    return contains(['MY_RESOURCE_REQUESTS', 'APPROVAL_FLOW', 'REQUEST_GRID', 'RES_REQUEST_SINGLE'], flow);
}

export function isGridFlow(flow) {
    return contains(['VACATION_GRID', 'REQUEST_GRID'], flow);
}

export function isVacationGrid(flow) {
    return 'VACATION_GRID' === flow;
}

export function isRequestGrid(flow) {
    return 'REQUEST_GRID' === flow;
}
