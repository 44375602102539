import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import SearchList from 'modules/scheduler/components/searchList/searchList';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { sortObjectsArrayByNameFn } from 'shared/lib/array';
import { MenuCollapse } from '../menuCollapse';

const CategoryToggler = props => {
    const { category, bookingCategories, onChange, onToggleOpen, closeToggle } = props;
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCategoryGroups.request());
    }, [dispatch]);

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    const onSelect = useCallback(
        project => {
            onChange(project);
            setIsOpen(prevState => !prevState);
        },
        [onChange]
    );

    const data = useMemo(() => {
        return sortObjectsArrayByNameFn(
            bookingCategories.map(({ categories, ...bookingCategory }) => {
                return {
                    ...bookingCategory,
                    title: bookingCategory.name,
                    data: sortObjectsArrayByNameFn(categories),
                };
            })
        );
    }, [bookingCategories]);

    return (
        <>
            <Toggler data-cy="category-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    <span>Category:</span> {category && category.name}
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="px-3 py-2">
                    <SearchList
                        data={data}
                        onSelect={onSelect}
                        isGroupedData
                        name="category"
                        height={200}
                        fields={{ label: 'name', value: '_id' }}
                        placeholder="Filter by Category Name"
                    />
                </div>
            </MenuCollapse>
        </>
    );
};

CategoryToggler.propTypes = {
    category: PropTypes.object,
    bookingCategories: PropTypes.array,
    onChange: PropTypes.func,
};

CategoryToggler.defaultProps = {
    category: null,
    bookingCategories: [],
    onChange: null,
};

export default CategoryToggler;
