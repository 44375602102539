import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { useHasRights, useIsExtensionInstalled } from 'hooks';
import { filter, map, flatten } from 'lodash';
import { CUSTOM_FIELDS, PM } from 'enums/extensionShortIdEnum';
import { getAllCustomFieldsV2 } from 'actions/customFieldActions';
import { getCategoryGroups } from 'actions/categoryGroupActions';
import { getTags } from 'actions/tagActions';
import { getCustomers } from 'actions/customerActions';
import { getProjects } from 'actions/projectActions';
import { getResources } from 'actions/resourceActions';
import { getCurrencies } from 'enums/currencyEnum';
import { WAITING_FOR_APPROVAL, APPROVED, SCHEDULED } from 'enums/bookingTypeEnum';
import {
    statusesArrayExceptParkedNonBookable as resourceStatuses,
    rolesArray as resourceRoles,
    resourceIsPmFilterStatuses,
} from 'enums/resourceEnum';
import { makeGetFilteredProjects } from 'selectors/project';
import { makeGetFilteredResources } from 'selectors/resource';
import { makeGetFilteredCustomFields } from 'selectors/customField';
import { getDisplayArchivedInScheduler, selectProjectStatuses } from '../../../../selectors/company';

const rights = [
    {
        rights: ['settingProjectClient'],
        rule: 'one',
        name: 'hasSettingProjectClient',
    },
    {
        rights: ['settingProjectPm'],
        rule: 'one',
        name: 'hasSettingProjectPm',
    },
    {
        rights: ['settingProjectRate'],
        rule: 'one',
        name: 'hasSettingProjectRate',
    },
];

const useFilterData = (shouldLoadData, loadProjectAndResources) => {
    const dispatch = useDispatch();
    const isCFExtensionActive = useIsExtensionInstalled(CUSTOM_FIELDS);
    const isPMExtensionActive = useIsExtensionInstalled(PM);
    const dataRights = useHasRights(rights);
    const displayArchivedInScheduler = useSelector(getDisplayArchivedInScheduler);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const projectSelector = useMemo(
        () => makeGetFilteredProjects(displayArchivedInScheduler ? 'ALL' : 'EXCLUDE_ARCHIVED'),
        [displayArchivedInScheduler]
    );
    const resourceSelector = useMemo(
        () => makeGetFilteredResources(displayArchivedInScheduler ? 'ALL' : 'EXCLUDE_PARKED_NON_BOOKABLE_ARCHIVED'),
        [displayArchivedInScheduler]
    );
    const projectTags = useSelector(state => state.tagReducer.projectTags);
    const resourceTags = useSelector(state => state.tagReducer.resourceTags);
    const customers = useSelector(state => state.customerReducer.customers);
    const projectCustomFieldSelector = useMemo(() => makeGetFilteredCustomFields('CHOICES', 'projectCustomFields'), []);
    const resourceCustomFieldSelector = useMemo(
        () => makeGetFilteredCustomFields('CHOICES', 'resourceCustomFields'),
        []
    );
    const projectCustomFields = useSelector(projectCustomFieldSelector);
    const resourceCustomFields = useSelector(resourceCustomFieldSelector);
    const categoryGroups = useSelector(state => state.categoryGroupReducer.categoryGroups);
    const projects = useSelector(projectSelector);
    const resources = useSelector(resourceSelector);
    const projectStatuses = useSelector(selectProjectStatuses);

    useEffect(() => {
        if (false === shouldLoadData || isDataLoaded) {
            return;
        }
        const { hasSettingProjectClient, hasSettingProjectPm } = dataRights;
        if (loadProjectAndResources) {
            dispatch(getProjects.request());
            dispatch(getResources.request());
        }

        !loadProjectAndResources && isPMExtensionActive && hasSettingProjectPm && dispatch(getResources.request());
        dispatch(getTags.request('resource'));
        dispatch(getTags.request('project'));

        hasSettingProjectClient && dispatch(getCustomers.request());
        isCFExtensionActive && dispatch(getAllCustomFieldsV2.request());
        dispatch(getCategoryGroups.request());
        setDataLoaded(true);
    }, [
        dispatch,
        shouldLoadData,
        loadProjectAndResources,
        isDataLoaded,
        isCFExtensionActive,
        isPMExtensionActive,
        dataRights,
    ]);

    const filterData = useMemo(() => {
        const filterData = {
            projectTags: projectTags || [],
            resourceTags: resourceTags || [],
            customers: dataRights.hasSettingProjectClient ? customers : [],
            projectCustomFields: (projectCustomFields || []).filter(projCF => projCF.filterGrid),
            resourceCustomFields: (resourceCustomFields || []).filter(resCF => resCF.filterGrid),
            resourceRoles: resourceRoles || [],
            resourceStatuses: resourceStatuses || [],
            projectStatuses: projectStatuses || [],
            bookingProjectStatuses: projectStatuses || [],
            bookingStatuses: [WAITING_FOR_APPROVAL, APPROVED, SCHEDULED],
            bookingCategories: flatten(map(categoryGroups, 'categories')),
            bookingProjectsEvents: projects || [],
            bookingResourcesUW: resources || [],
            currencies: dataRights.hasSettingProjectRate ? getCurrencies() : [],
            resourceIsPm: isPMExtensionActive ? resourceIsPmFilterStatuses : [],
        };
        if (isPMExtensionActive && dataRights.hasSettingProjectPm) {
            filterData.projectManagers = filter(resources, resource => resource.isProjectManager);
        }
        if (loadProjectAndResources) {
            filterData.projects = projects;
            filterData.resources = resources;
        }

        return filterData;
    }, [
        projectTags,
        resourceTags,
        dataRights.hasSettingProjectClient,
        dataRights.hasSettingProjectRate,
        dataRights.hasSettingProjectPm,
        customers,
        projectCustomFields,
        resourceCustomFields,
        projectStatuses,
        categoryGroups,
        projects,
        resources,
        isPMExtensionActive,
        loadProjectAndResources,
    ]);

    return filterData;
};

export default useFilterData;
