import React from 'react';
import PropTypes from 'prop-types';
import { RequestedLabel } from './../styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

const Private = ({ booking, text, mode, displayLinkedIcon }) =>
    booking.private ? (
        <RequestedLabel data-cy="booking-note-icon" mode={mode} displayLinkedIcon={displayLinkedIcon}>
            <LockOutlinedIcon />
            <span data-cy="private-booking-text">{booking.text}</span>
        </RequestedLabel>
    ) : (
        <span>{text || booking.text || null}</span>
    );

Private.propTypes = {
    booking: PropTypes.object.isRequired,
    text: PropTypes.string,
};

Private.defaultProps = {
    text: '',
};

export default Private;
