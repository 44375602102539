import styled from 'styled-components';

export const CurrentColor = styled.div`
    background-color: ${props => props.color};
    width: 37px;
    height: 37px;
    cursor: pointer;
    border-radius: 4px;
    float: right;
`;

export const Popover = styled.div`
    position: ${props => (props.positionAbsolute ? 'absolute' : 'relative')};
    max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : undefined)};
    left: ${props =>
        'left' === props.position && !props.leftPosition ? `-${props.width - 46}px` : `${props.leftPosition || 0}px`};
    top: ${props => (props.topPosition ? `${props.topPosition}px` : '2px')};
    overflow-y: ${props => (props.maxHeight ? 'scroll' : undefined)};
    z-index: 999;

    .color-picker {
        position: absolute;
        z-index: 999;
        box-shadow: none !important;
        margin: auto;
        padding-top: ${props => props.slider && '74px !important'};

        & div span > div {
            width: 25px !important;
            height: 25px !important;
        }
    }
`;

export const Cover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const SliderWrapper = styled.div`
    padding: 18px 20px 20px;
    top: 0;
    position: absolute;
    z-index: 9999;
    width: ${props => props.width}px;
`;
