import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/light.css';
import { Calendar } from '@hub-mono/ui';
import uuid from 'uuid/v1';
import { removeUTCZuluFromDateTimestamp, toDate } from '@hub-mono/utils';

const holidayCalendarStyles = {
    color: 'white',
    backgroundColor: 'rgba(255, 102, 0, 1)',
};

const approvedCalendarStyles = {
    color: 'white',
    backgroundColor: 'rgba(0, 108, 71, 1)',
};

const requestedCalendarStyles = {
    color: 'white',
    backgroundColor: 'rgba(255, 204, 10, 1)',
};

const legend = {
    holiday: {
        label: 'Holiday',
        styles: holidayCalendarStyles,
    },
    requested: {
        label: 'Requested',
        styles: requestedCalendarStyles,
    },
    approved: {
        label: 'Approved',
        styles: approvedCalendarStyles,
    },
};

const EventCalendar = ({ upcomingEvents }) => {
    const upcomingEventsArray = upcomingEvents.toJSON();

    const eventRanges = useMemo(() => {
        return (upcomingEventsArray || []).reduce(
            (acc, event) => {
                if (!event._id) {
                    acc[uuid()] = {
                        from: toDate(removeUTCZuluFromDateTimestamp(event.date)),
                        to: toDate(removeUTCZuluFromDateTimestamp(event.date)),
                        type: 'holiday',
                    };
                    return acc;
                }

                if (!acc[event._id]) {
                    acc[event._id] = {
                        from: toDate(removeUTCZuluFromDateTimestamp(event.date)),
                        to: toDate(removeUTCZuluFromDateTimestamp(event.date)),
                        type: event.type === 'WAITING_FOR_APPROVAL' ? 'requested' : 'approved',
                    };
                } else {
                    acc[event._id].to = toDate(removeUTCZuluFromDateTimestamp(event.date));
                }

                return acc;
            },
            {}
        );
    }, [upcomingEventsArray]);

    return <Calendar ranges={eventRanges} legend={legend} />;
};

EventCalendar.propTypes = {
    upcomingEvents: PropTypes.object.isRequired,
    weekStartDay: PropTypes.number.isRequired,
};

EventCalendar.defaultProps = {
    weekStartDay: 1,
};

export default EventCalendar;
