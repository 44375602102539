import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavItem, Nav } from 'reactstrap';
import { find, head } from 'lodash';
import { TabItem, TabInfo, TabInfoDesc, ListWrapper, FaPlus, Stack } from './styles';

class TabLinks extends PureComponent {
    static propTypes = {
        toggle: PropTypes.func.isRequired,
        tabs: PropTypes.array.isRequired,
        activeTab: PropTypes.string,
        center: PropTypes.bool,
    };

    static defaultProps = {
        center: true,
    };

    constructor(props) {
        super(props);
        const currentTab = props.activeTab ? find(props.tabs, tab => tab.id === props.activeTab) : head(props.tabs);
        this.state = {
            activeTab: currentTab.id,
            title: currentTab.title,
            desc: currentTab.desc,
        };
    }

    toggle = tabId => {
        if (this.state.activeTab !== tabId) {
            const currentItem = find(this.props.tabs, tab => tab.id === tabId);
            this.setState({
                activeTab: tabId,
                title: currentItem.title || '',
                desc: currentItem.desc || '',
            });

            this.props.toggle(tabId);
        }
    };

    onLinkHover = (event, item) => {
        if ('mouseenter' === event.type && item.id !== this.state.activeTab) {
            this.setState({
                title: item.title,
                desc: item.desc,
            });
        } else if ('mouseleave' === event.type) {
            const selectedItem = find(this.props.tabs, tab => tab.id === this.state.activeTab);
            this.setState({
                title: selectedItem.title,
                desc: selectedItem.desc,
            });
        }
    };

    render() {
        const { tabs } = this.props;
        const { title, desc, activeTab } = this.state;

        return (
            <div className={classNames({ 'text-center': this.props.center })}>
                <ListWrapper>
                    <Nav tabs className="d-inline-block">
                        {tabs.map((item, index) => (
                            <NavItem key={index} className="mb-0 d-inline-block">
                                <TabItem
                                    className={classNames({
                                        active: activeTab === item.id.toString(),
                                    })}
                                    onMouseEnter={event => this.onLinkHover(event, item)}
                                    onMouseLeave={event => this.onLinkHover(event, item)}
                                    onClick={() => this.toggle(item.id)}
                                    data-cy={item.dataCy || ''}
                                >
                                    {'fa-stack' === item.icon ? (
                                        <Stack className={classNames('py-2', item.icon)}>
                                            <i className="fa fa-folder fa-stack-2x" />
                                            <FaPlus className="fa fa-plus fa-stack-1x" />
                                        </Stack>
                                    ) : (
                                        <i className={classNames('py-2 fa', item.icon)} />
                                    )}
                                </TabItem>
                            </NavItem>
                        ))}
                    </Nav>
                </ListWrapper>
                <TabInfo>
                    <h5 className="text-center">{title}</h5>
                    <TabInfoDesc>{desc}</TabInfoDesc>
                </TabInfo>
            </div>
        );
    }
}

export default TabLinks;
