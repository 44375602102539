import React from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'redux-form';
import { flow, map as mapFP, filter as filterFP } from 'lodash/fp';
import { filter, includes } from 'lodash';
import { Button, Row, Col, FormText } from 'reactstrap';
import { useAppKeyWords } from 'hooks';
import { getCurrency } from 'enums/currencyEnum';
import { dropdownFieldMaterial } from 'shared/formFields';

const RenderRates = ({ fields, type, resources, resourceIdsInCustomRates, rates }) => {
    const { resourceKeyWord } = useAppKeyWords();
    return (
        <>
            {fields.map((rate, index) => (
                <Row key={index} className="mb-2">
                    <Col xs={12} md={6}>
                        <Field
                            inline
                            name={`${rate}.resource`}
                            component={dropdownFieldMaterial}
                            valueField="_id"
                            textField={item => {
                                if (Object.keys(item).length) {
                                    if (!item.firstName && !item.firstName) {
                                        return `Deleted / archived ${resourceKeyWord}`;
                                    }

                                    const baseName = `${item.firstName || ''} ${item.lastName || ''}`;
                                    if ('UNASSIGNED' === item.type) {
                                        return `${baseName} (unassigned)`;
                                    }

                                    return baseName;
                                }

                                return ``;
                            }}
                            data={filter(resources, resource => !includes(resourceIdsInCustomRates, resource._id))}
                        />
                    </Col>
                    <Col xs={12} md={5}>
                        <Field
                            inline
                            name={`${rate}.rate`}
                            component={dropdownFieldMaterial}
                            valueField="_id"
                            textField={item =>
                                item?.label ? `${item.label}: ${getCurrency(item.currency).symbol} ${item.rate}` : ''
                            }
                            data={rates}
                        />
                    </Col>
                    <Col xs={12} md={1}>
                        <Button color="danger" onClick={() => fields.remove(index)}>
                            <i className="fa fa-times" />
                        </Button>
                    </Col>
                </Row>
            ))}
            <Button className="mt-2 rounded-0 sm" onClick={() => fields.push({})}>
                Add a Custom {type} Rate
            </Button>
        </>
    );
};

const CustomResourceRates = ({ resourceRates, resources, rates, type, projectKeyWord }) => {
    const { resourceKeyWord, resourcePluralKeyWord } = useAppKeyWords();

    const resourceIdsInCustomRates = flow(
        filterFP(rate => rate.resource && rate.resource._id),
        mapFP(rate => rate.resource._id)
    )(resourceRates);

    return (
        <div>
            <FieldArray
                name={`projectRates.${type}.customRates`}
                component={RenderRates}
                type={type}
                rates={rates}
                resources={resources}
                resourceIdsInCustomRates={resourceIdsInCustomRates}
            />
            <FormText>
                Enter a Custom {type} {resourceKeyWord} Rate for this {projectKeyWord}.&nbsp; These will be the {type}{' '}
                billing rates used for the {resourcePluralKeyWord} listed above.
            </FormText>
        </div>
    );
};

CustomResourceRates.propTypes = {
    rates: PropTypes.array,
    resourceRates: PropTypes.array,
    resources: PropTypes.array,
    type: PropTypes.string,
    projectKeyWord: PropTypes.string,
};

CustomResourceRates.defaultProps = {
    rates: [],
    resourceRates: [],
    resources: [],
    type: 'external',
    projectKeyWord: '',
};

export default CustomResourceRates;
