import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

export const CreatingDataError = React.memo(() => {
    return (
        <div className="d-flex w-100 flex-column align-items-center mt-2 mb-2">
            <ErrorIcon style={{ color: '#db5949' }} fontSize="large" />
            <h5>Error while creating data</h5>
        </div>
    );
});

export default CreatingDataError;
