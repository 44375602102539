import React, { createContext, useContext, useMemo, useState } from 'react';
import { TYPE_REGULAR } from '../../../../../enums/projectEnum';
import { useAppKeyWords } from '../../../../../hooks';
import { TYPE_UNASSIGNED } from '../../../../../enums/resourceEnum';

const useFilterButtons = ({ resourcePluralKeyWord, onlyResources, onlyUnassigned }) => {
    return useMemo(() => {
        const resourcesFilterBtn = {
            text: resourcePluralKeyWord,
            property: 'type',
            value: TYPE_REGULAR.value,
        };

        const unassignedFilterBtn = {
            text: TYPE_UNASSIGNED.display,
            property: 'type',
            value: TYPE_UNASSIGNED.value,
        };

        if (onlyResources) {
            return [
                {
                    ...resourcesFilterBtn,
                    selected: true,
                },
            ];
        }

        if (onlyUnassigned) {
            return [
                {
                    ...unassignedFilterBtn,
                    selected: true,
                },
            ];
        }

        return [
            {
                ...resourcesFilterBtn,
                selected: true,
            },
            {
                ...unassignedFilterBtn,
            },
        ];
    }, [onlyResources, onlyUnassigned, resourcePluralKeyWord]);
};

const defaultContextValue = {};

const Context = createContext(defaultContextValue);

const Provider = props => {
    const { children, onlyResources, onlyUnassigned } = props;

    const { resourcePluralKeyWord } = useAppKeyWords();

    const [resourceName, setResourceName] = useState('');

    const filterButtons = useFilterButtons({
        resourcePluralKeyWord,
        onlyResources,
        onlyUnassigned,
    });

    const [filterButtonApplied, setFilterButtonApplied] = useState(() => {
        return filterButtons?.find(button => button.selected);
    });

    const value = useMemo(
        () => ({
            filterButtons,
            filterButtonApplied,
            setFilterButtonApplied,
            isUnassignedFilterApplied: filterButtonApplied?.value === TYPE_UNASSIGNED.value,
            resourceName,
            setResourceName
        }),
        [filterButtonApplied, filterButtons, resourceName]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const SchedulerResourcesListProvider = React.memo(Provider);

export const useSchedulerResourcesListContext = () => {
    const context = useContext(Context);

    if (!context || context === defaultContextValue) {
        throw new Error('useSchedulerResourcesListContext should be used within SchedulerResourcesListProvider');
    }

    return context;
};
