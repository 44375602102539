import { COLUMN_TYPES_ENUM, TIME_TYPE_COLUMNS } from 'modules/report/enums/columnTypeEnum';
import * as columnUnitTypes from 'modules/report/enums/columnUnitTypeEnum';
import { displayDateState } from 'modules/report/utils/columnHelper';

/**
 * @param {object} dateState
 *
 * @returns {*[]}
 */
export default dateState => [
    {
        id: 'capacityBetween',
        display: `Capacity - ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Fixed Amount',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: true,
    },
    {
        id: 'capacityPrevious',
        display: `Capacity - Previous ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Fixed Amount',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
    {
        id: 'capacityAfter',
        display: `Capacity - Next ${displayDateState(dateState)}`,
        unitType: columnUnitTypes.TIME.value,
        displayTimeType: 'Fixed Amount',
        displayColumnType: 'capacity',
        columnType: COLUMN_TYPES_ENUM.CAPACITY,
        timeType: TIME_TYPE_COLUMNS.GENERAL,
        heatMapSupport: false,
    },
];
