import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Doughnut, Chart } from 'react-chartjs-2';
import { toFixedTrimmed } from '@hub-mono/utils';

const centerTextSizes = {
    0: 57,
    1: 45,
    2: 36,
    3: 32,
    4: 28,
    5: 24,
    6: 22,
    7: 18,
    8: 16,
    9: 14,
    10: 12,
    11: 11,
};

class DoughnutChart extends Component {
    static propTypes = {
        defaultVacationEventName: PropTypes.string.isRequired,
        totalApprovedDays: PropTypes.number.isRequired,
        totalRemainingDays: PropTypes.number.isRequired,
    };

    componentDidMount() {
        const { centerTextMaxSize } = this.props;
        Chart.pluginService._plugins = Chart.pluginService._plugins.filter(el => !el?.afterDraw);
        Chart.pluginService.register({
            afterDraw: chart => {
                if (
                    chart.config.options.elements.center &&
                    'undefined' !== typeof chart.config.options.elements.center.display &&
                    chart.config.options.elements.center.display
                ) {
                    this.drawTotals(chart, centerTextMaxSize);
                }
            },
        });
    }

    shouldComponentUpdate(nextProps) {
        const { totalApprovedDays, totalRemainingDays, centerTextMaxSize } = this.props;

        return (
            nextProps.totalApprovedDays !== totalApprovedDays ||
            nextProps.totalRemainingDays !== totalRemainingDays ||
            nextProps.centerTextMaxSize !== centerTextMaxSize
        );
    }

    drawTotals(chart, centerTextMaxSize) {
        let width = chart.chart.width,
            height = chart.chart.height,
            ctx = chart.chart.ctx,
            textCenter = chart.config.options.elements.center.text;
        ctx.globalCompositeOperation = 'destination-over';
        ctx.restore();
        let idx = 0;
        const fontFamily = 'px Poppins';
        ctx.font = centerTextSizes[idx] + fontFamily;

        while (ctx.measureText(textCenter).width > centerTextMaxSize && idx !== 12) {
            idx++;
            ctx.font = centerTextSizes[idx] + fontFamily;
        }

        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#171D19';

        let textCenterX = Math.round((width - ctx.measureText(textCenter).width) / 2),
            textY = height / 2;

        ctx.fillText(textCenter, textCenterX, textY - 5);

        ctx.restore();
        ctx.font = 12 + fontFamily;
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#171D19';

        let textBottom = chart.config.options.elements.bottom.text;
        let textBottomX = Math.round((width - ctx.measureText(textBottom).width) / 2);

        ctx.fillText(textBottom, textBottomX, textY + 20);
        ctx.save();
        ctx.globalCompositeOperation = 'source-over';
    }

    render() {
        const { totalApprovedDays, totalRemainingDays, bottomText, colors, height, labels } = this.props;

        return (
            <Doughnut
                data={{
                    labels,
                    datasets: [
                        {
                            data: [toFixedTrimmed(totalApprovedDays, 2), toFixedTrimmed(totalRemainingDays, 2)],
                            backgroundColor: colors,
                            borderColor: colors,
                        },
                    ],
                }}
                height={height}
                options={{
                    maintainAspectRatio: false,
                    cutoutPercentage: 60,
                    legend: {
                        display: false,
                    },
                    elements: {
                        center: {
                            display: true,
                            text: toFixedTrimmed(this.props.totalRemainingDays, 2),
                        },
                        bottom: {
                            display: true,
                            text: bottomText,
                        },
                    },
                    tooltips: {
                        backgroundColor: '#000',
                    },
                }}
            />
        );
    }
}

export default DoughnutChart;
