import styled from 'styled-components';

export const Toggler = styled.button`
    border-top: none;
    border-left: none;
    border-right: none;
    text-align: left;
    background: none;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 1rem;
    padding-bottom: 0.7rem;
    padding-top: 0.7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    display: flex;
    width: calc(100% - 2rem);
    font-size: 13px;

    div + &:first-of-type {
        margin-top: 0.5rem;
    }

    &:focus {
        outline: none;
    }
`;

export const ToggleIconHolder = styled.span`
    position: absolute;
    right: 5px;
    top: 5px;
    color: #adaebb;
`;

export const Text = styled.p`
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;

    span {
        color: #818181;
    }
`;

export const SelectedColor = styled.span`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: inline-block;
    background-color: ${props => props.color};
    vertical-align: middle;
`;

export const ScheduleDate = styled.span`
    font-style: italic;
    font-size: 12px;
    text-align: center;
    display: block;
`;
