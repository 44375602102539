import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DayPilotScheduler, DayPilot } from 'daypilot-pro-react';
import { Col, Row } from 'reactstrap';
import useSchedulerRef from 'shared/hooks/useSchedulerRef';
import { CELLDURATION } from 'modules/scheduler/enums/scale';
import { selectBookingLinkConfig, selectDeadlinesConfig, selectEventHeightMode } from '../../../../selectors/account';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { clearSchedulerUpdate } from '../../../../actions/accountActions';

const Wrapper = React.memo(({ children }) => {
    const linksConfig = useSelector(selectBookingLinkConfig);
    const deadlinesConfig = useSelector(selectDeadlinesConfig);
    const eventHeightMode = useSelector(selectEventHeightMode);

    return (
        <Col
            className={classnames(
                'pb-1',
                `link-style-${linksConfig.style}`,
                `deadline-link-style-${deadlinesConfig.style}`,
                `deadline-tooltip-${deadlinesConfig.tooltipDisplay}`,
                `event-height-mode-${eventHeightMode}`
            )}
            style={{
                '--link-color': linksConfig.color,
                '--deadline-link-color': deadlinesConfig.linkColor,
                '--deadline-color': deadlinesConfig.deadlineColor,
            }}
        >
            {children}
        </Col>
    );
});

const Scheduler = React.forwardRef((props, ref) => {
    // Add reference to scheduler to be global available without confusion with passing refs
    const { setSchedulerRef } = useSchedulerRef();
    const dispatch = useDispatch();

    const { config } = props;

    const onSchedulerRef = schedulerRef => {
        setSchedulerRef({
            current: schedulerRef,
        });
        ref.current = schedulerRef;
    };

    let startDate = new DayPilot.Date(props.config.startDate);
    // Small bug of DayPilot. Loading with hour scale the app it is adding 7 more days, so we subtract 7.
    if (props.config?.scale === CELLDURATION.value) {
        startDate = startDate.addDays(-7);
    }

    useEffect(() => {
        return () => {
            dispatch(clearSchedulerUpdate());
        };
    }, [dispatch]);

    return (
        <Row>
            <Wrapper>
                <DayPilotScheduler ref={onSchedulerRef} {...config} startDate={startDate} />
            </Wrapper>
        </Row>
    );
});

Scheduler.propTypes = {
    config: PropTypes.object,
};

Scheduler.defaultProps = {
    config: {},
};

export default React.memo(Scheduler);
