import ReportDetailBreadcrumb from './../../components/breadcrumbs/reportDetailBreadcrumb';
import { connect } from 'react-redux';
import { getProjects } from 'actions/projectActions';
import { getAllCustomFields } from 'actions/customFieldActions';
import { getResources } from 'actions/resourceActions';
import { getProjectGroups } from 'actions/projectGroupActions';
import { getResourceGroups } from 'actions/resourceGroupActions';
import { getCustomers } from 'actions/customerActions';
import { getTags } from 'actions/tagActions';
import { makeGetFilteredProjects } from 'selectors/project';
import { makeGetFilteredResources } from 'selectors/resource';
import { hasRole } from 'utils/rightsUtil';

export default connect(
    state => {
        let account = state.account;
        let hasManageReportsRights = hasRole(account.resourceRoleRights, 'manageReports');
        let PMFilterType =
            hasRole(account.resourceRoleRights, 'pmManageReports') &&
            account.isProjectManager &&
            !hasManageReportsRights
                ? 'MY_MANAGED'
                : '';
        let getFilteredProjects = makeGetFilteredProjects(hasManageReportsRights ? 'ALL' : PMFilterType);
        let getFilteredResources = makeGetFilteredResources(hasManageReportsRights ? 'ALL' : PMFilterType);
        let getProjectManagers = makeGetFilteredResources('IS_PROJECT_MANAGER');

        return {
            bookingCategories: state.companyTree.getFilteredCategoryTemplates(),
            customers: state.customerReducer.customers,
            projectGroups: state.projectGroupReducer.projectGroups,
            projectManagers: getProjectManagers(state),
            projects: getFilteredProjects(state),
            projectTags: state.tagReducer.projectTags,
            queryParams: state.reportReducer.queryParams,
            resourceGroups: state.resourceGroupReducer.resourceGroups,
            resources: getFilteredResources(state),
            resourceTags: state.tagReducer.resourceTags,
            isCustomFieldLoading: state.customFieldReducer.loading,
            projectCustomFields: state.customFieldReducer.projectCustomFields,
            resourceCustomFields: state.customFieldReducer.resourceCustomFields,
        };
    },
    dispatch => ({
        getCustomers: () => dispatch(getCustomers.request()),
        getProjectGroups: () => dispatch(getProjectGroups.request()),
        getProjects: () => dispatch(getProjects.request()),
        getResourceGroups: () => dispatch(getResourceGroups.request()),
        getResources: () => dispatch(getResources.request()),
        getTags: type => dispatch(getTags.request(type)),
        getAllCustomFields: () => dispatch(getAllCustomFields.request()),
    })
)(ReportDetailBreadcrumb);
