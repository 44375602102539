import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    Loader_grayedZero: {
        color: '#838383',
        fontSize: '18px',
        fontWeight: 400,
    },
});

const Loader = props => {
    const { size, center, className, verticalCenter, grayedZero } = props;

    const classes = useStyles();

    if (grayedZero) {
        return <span className={classes.Loader_grayedZero}>0</span>;
    }

    if (center) {
        return (
            <span
                className={classNames(
                    `${verticalCenter ? 'h-100' : ''} d-flex justify-content-center align-items-center`,
                    className
                )}
            >
                <CircularProgress style={{ color: '#53c3a3' }} size={size} />
            </span>
        );
    }

    return <CircularProgress className={className} style={{ color: '#53c3a3' }} size={size} />;
};

Loader.propTypes = {
    size: PropTypes.number,
    center: PropTypes.bool,
    className: PropTypes.string,
    verticalCenter: PropTypes.bool,
};

Loader.defaultProps = {
    size: 40,
    center: false,
    className: '',
};

export default Loader;
