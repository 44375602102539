import moize from 'moize';
import { cloneDeep, filter, forEach, head, mapValues } from 'lodash';
import { formatDate, removeUTCZuluFromDateObject } from 'utils/DateUtil';
import {
    isHolidayBetweenDates,
    getHolidayDateBetweenDates,
    getHolidayFromMap,
    getUniqHolidays,
} from 'utils/holidayUtil';
import { store } from '../store';
import { makeGetHolidays } from 'selectors/holidays';
import { daysOrder } from 'shared/components/CustomAvailability/CustomAvailability';
import moment from 'moment';

export const getResourceCapacityByDay = moize(
    (resource, companySettings, date) => {
        const holiday = getHolidayFromMap(date, resource.holidaysMap);
        const isHoliday = Boolean(holiday);
        const _dayOfWeek = date.format('dddd').toLowerCase();
        let workDayDetails = { totalMinutes: 0, isWorkDay: false, isHoliday, holiday };

        if (isHoliday) {
            // do nothing
        } else if (resource?.useResourceAvailability) {
            const resourceWeekDay = head(resource.customAvailabilities).weekDays[_dayOfWeek];
            workDayDetails.totalMinutes = resourceWeekDay.workDay ? resourceWeekDay.minutes : 0;
            workDayDetails.isWorkDay = resourceWeekDay.workDay;
        } else {
            const companyWeekDay = companySettings.weekDays[_dayOfWeek];
            workDayDetails.totalMinutes = companyWeekDay.workDay ? companyWeekDay.minutes : 0;
            workDayDetails.isWorkDay = companyWeekDay.workDay;
        }
        if (resource?.resourceHasCustomAvailabilityOverrides) {
            const currentDay = date.format('YYYY-MM-DD');
            const overrideId = resource.customAvailabilitiesOverridesByDay[currentDay];
            const override = overrideId ? resource.customAvailabilitiesOverridesById[overrideId] : undefined;
            if (override) {
                const { minutes } = override;
                workDayDetails.totalMinutes = minutes;
                workDayDetails.isWorkDay = !!minutes;
                workDayDetails.isHoliday = false;
                workDayDetails.holiday = undefined;
            }
        }

        return workDayDetails;
    },
    { maxSize: 5 }
);

/**
 *  isScheduler - param to be used when calculating resource capacity for scheduler needs
 *  another use case is schedule/request forms
 *  those two entry points uses different types od dates moment/Daypilot.Date/normal js date
 *  so isScheduler will be used to determine date time zone logic
 *  https://hubplanner.atlassian.net/browse/HUB-12884
 *  https://hubplanner.atlassian.net/browse/HUB-12790
 *  proper fix will come with new implementations from @hub-mono
 */
export const getResourceCapacity = (resource, startDate, endDate, isScheduler = false) => {
    const reduxState = store.getState();

    let workWeekDetails;
    if (resource && resource.useResourceAvailability) {
        workWeekDetails = head(resource.customAvailabilities).weekDays;
    } else {
        const companySettings = reduxState.companyReducer.company.settings;
        workWeekDetails = getCompanyWorkWeekMinutes(companySettings);
    }
    let holidays = [];

    if (resource?.holidaysMap) {
        holidays = Object.values(resource.holidaysMap);
    } else if (resource?.calendarIds) {
        const getHolidays = makeGetHolidays(resource.calendarIds);
        holidays = getHolidays(reduxState);
    }

    const weekDetailsWithRange = getWeekDetailsWithinRange(
        startDate,
        endDate,
        workWeekDetails,
        holidays,
        resource,
        isScheduler
    );
    return weekDetailsWithRange;
};

/**
 * @param {object} companySettings
 *
 * @returns {{sunday, monday, tuesday, wednesday, thursday, friday, saturday}}
 */
export const getCompanyWorkWeekHours = companySettings => {
    const companyWorkWeekMinutes = getCompanyWorkWeekMinutes(companySettings);

    return mapValues(companyWorkWeekMinutes, item => ({
        minutes: item.minutes / 60,
        workDay: item.workDay,
    }));
};

/**
 * @param {object} companySettings
 *
 * @returns {object}
 */
export const getCompanyWorkWeekMinutes = companySettings => {
    let companyWeekDays = companySettings.weekDays;

    return mapValues(companyWeekDays, day => ({
        minutes: day.workDay ? day.minutes : 0,
        workDay: day.workDay,
    }));
};

/**
 *
 * @param {moment} rangeStart
 * @param {moment} rangeEnd
 * @param {object} workWeekDetails
 * @param {array}  holidays
 *
 * @returns {{offDaysCount: number, workDaysCount: number, holidayCount: number, workWeekDetails: object, totalMinutes: number, averageWorkDay: number, averageDay: number}}
 */
const getWeekDetailsWithinRange = (
    rangeStartArg,
    rangeEndArg,
    { ...workWeekDetails },
    holidays,
    resource,
    isScheduler = false
) => {
    let offDaysCount = 0,
        workDaysCount = 0,
        totalMinutes = 0,
        filteredHolidays = [];

    const rangeStartForHoliday = moment(rangeStartArg).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const rangeEndForHoliday = moment(rangeEndArg).set({ hour: 23, minute: 59, second: 59, millisecond: 0 });

    if (holidays && 0 < holidays.length) {
        filteredHolidays = filter(getUniqHolidays(holidays), holiday =>
            isHolidayBetweenDates(holiday, rangeStartForHoliday, rangeEndForHoliday)
        );
        workWeekDetails = includeHolidaysForRange(
            rangeStartForHoliday,
            rangeEndForHoliday,
            filteredHolidays,
            workWeekDetails
        );
    }

    let rangeStart, rangeEnd;

    if (isScheduler) {
        rangeStart = moment(removeUTCZuluFromDateObject(rangeStartArg.toDate()));
        rangeEnd = moment(removeUTCZuluFromDateObject(rangeEndArg.toDate()));
    } else {
        rangeStart = moment(rangeStartArg.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
        rangeEnd = moment(rangeEndArg.set({ hour: 23, minute: 59, second: 59, millisecond: 0 }));
    }

    let currentDay = rangeStart;
    const rangeEndFormat = rangeEnd.format('YYYY-MM-DD');

    while (currentDay.format('YYYY-MM-DD') <= rangeEndFormat) {

        let dayMinutes = 0;
        let offDay = false;

        const dayName = daysOrder[currentDay.isoWeekday() - 1];
        const workDay = workWeekDetails[dayName];

        if (!workDay.workDay) {
            offDay = true;
        } else {
            if (workDay.hasOwnProperty('holidaysCount') && 0 < workDay.holidaysCount) {
                workDay.amountOfDaysWithinRange -= workDay.holidaysCount;
            }
            dayMinutes = workDay.minutes;
        }

        const isHoliday = filteredHolidays?.filter(el => {
            return (
                formatDate(el.date, 'yyyy-MM-dd') === currentDay.format('YYYY-MM-DD') ||
                (el.repeat && formatDate(el.date, 'MM-dd') === currentDay.format('MM-DD'))
            );
        })?.length;

        if (isHoliday) {
            dayMinutes = 0;
        }

        if (resource?.resourceHasCustomAvailabilityOverrides) {
            const overrideId = resource?.customAvailabilitiesOverridesByDay?.[currentDay.format('YYYY-MM-DD')];
            if (overrideId && resource.customAvailabilitiesOverridesById[overrideId]) {
                const { minutes } = resource.customAvailabilitiesOverridesById[overrideId];
                dayMinutes = minutes;
                offDay = !!minutes;
            }
        }

        offDaysCount = offDay ? offDaysCount++ : offDaysCount;
        workDaysCount = dayMinutes > 0 ? workDaysCount + 1 : workDaysCount;
        totalMinutes += dayMinutes;
        currentDay = rangeStart.add(1, 'd');
    }

    return {
        offDaysCount,
        workDaysCount,
        holidayCount: filteredHolidays.length,
        workWeekDetails,
        totalMinutes,
        averageWorkDay: totalMinutes > 0 ? totalMinutes / workDaysCount / 60 : 0,
        averageDay: totalMinutes > 0 ? totalMinutes / (workDaysCount + offDaysCount) / 60 : 0,
    };
};

/**
 * Add holiday property for working days within range
 *
 * @param {moment} start
 * @param {moment} end
 * @param {array}  holidays
 * @param {object}  workDays
 *
 * @returns {array} workDays
 */
export const includeHolidaysForRange = (start, end, holidays, workDays) => {
    const currentWorkDays = cloneDeep(workDays);
    forEach(holidays, holiday => {
        const holidayDate = getHolidayDateBetweenDates(holiday, start, end).toDate();
        if (!isHolidayBetweenDates(holiday, start, end)) {
            return;
        }
        if (!currentWorkDays[formatDate(holidayDate, 'iiii').toLowerCase()].hasOwnProperty('holidaysCount')) {
            currentWorkDays[formatDate(holidayDate, 'iiii').toLowerCase()].holidaysCount = 1;
        } else {
            currentWorkDays[formatDate(holidayDate, 'iiii').toLowerCase()].holidaysCount++;
        }
    });

    return currentWorkDays;
};
