import moment from 'moment';
import moize from 'moize';
import { find, every } from 'underscore';

export const getTrialDaysLeft = date => {
    let a = moment.utc(date);
    let b = moment.utc();

    return a.diff(b, 'd') + 1; // add + 1 to include day.
};

/**
 * Will return undefined if extension is not installed ever
 *
 * @param extensions
 * @param shortId
 */
export const getExtensionByShortId = (extensions, shortId) =>
    find(extensions, extension => extension.shortId === shortId);

/**
 * Check trial status of passed in extension
 *
 * @param shortId
 * @param companyTrialActive
 * @returns {boolean}
 */
export const hasTrialExpired = (companyTrialActive, shortId) => {
    const extension = getExtensionByShortId(shortId);

    if (!extension) {
        return true;
    }

    if (companyTrialActive || !extension.trialActive) {
        return false;
    }

    const days = getTrialDaysLeft(extension.trialEndDate);

    return 0 >= days;
};

/**
 * This will check logic for trial, enabled, installed to make it simpler for places where we only need to either show or hide. And not know the exact state.
 */
export const isActive = moize(
    (extensions, shortId) => {
        const extension = getExtensionByShortId(extensions, shortId);

        //is extension never installed
        if (!extension) {
            return false;
        }

        return extension.installed;
    },
    { maxSize: 2 }
);

export const isEnabled = moize(
    (extensions, shortId) => {
        const extension = getExtensionByShortId(extensions, shortId);

        return extension?.enabled;
    },
    { maxSize: 2 }
);

/**
 * @param {array} companyExtensions
 * @param {array} extensions
 *
 * @returns {boolean}
 */
export const hasExtensionsInstalled = (companyExtensions, extensions) =>
    every(extensions, extension => isActive(companyExtensions, extension));
