import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import { map } from 'lodash';

import { DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE } from 'modules/scheduler/consts';
import { WrappedMenuItem } from '../../../../../../../shared/nestedMenuItem/wrappedMenuItem';
import { getFormDefaultApprovers } from '../../../../../../request/utils/getFormDefaultApprovers';
import { companyApprovers } from '../../../../../../../actions/companyActions';
import { selectMappedResourceGroups } from '../../../../../../../selectors/resourceGroup';
import { selectCompanyApprovers } from '../../../../../../../selectors/company';

const VacationMenuItem = props => {
    const { onRequestVacation, resource, isDefaultVacationEventSet } = props;
    const vacationEventName = useSelector(state => state.companyReducer.company.settings.vacationEventName);
    const [showDefaultVacationEventTooltip, setShowDefaultVacationEventTooltip] = useState(false);

    const approvers = useSelector(selectCompanyApprovers);
    const mappedResourceGroups = useSelector(selectMappedResourceGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(companyApprovers.request());
    }, [dispatch]);

    const createVacation = useCallback(() => {
        let defaultApprovers = getFormDefaultApprovers({ approvers, resource, mappedResourceGroups });

        onRequestVacation({
            resource: resource._id,
            approvalInfo: {
                approvers:
                    map(defaultApprovers || resource.defaultApproverIds, approverOrId => {
                        if (approverOrId && typeof approverOrId === 'object') {
                            return { id: approverOrId._id };
                        }

                        return { id: approverOrId };
                    }) || [],
            },
        });
    }, [approvers, mappedResourceGroups, onRequestVacation, resource]);

    return (
        <>
            <WrappedMenuItem
                onClick={() =>
                    isDefaultVacationEventSet ? createVacation() : setShowDefaultVacationEventTooltip(true)
                }
                className="rounded ml-2 mr-2 pl-4 pt-2"
                data-cy="scheduler-booking-classic-menu-grouped-rows-event-request-vacation"
            >
                Request {vacationEventName}
            </WrappedMenuItem>
            {showDefaultVacationEventTooltip && <Alert color="warning">{DEFAULT_VACATION_EVENT_TOOLTIP_MESSAGE}</Alert>}
        </>
    );
};

VacationMenuItem.propTypes = {
    resource: PropTypes.object.isRequired,
    isDefaultVacationEventSet: PropTypes.bool,
    onRequestVacation: PropTypes.func.isRequired,
};

export default VacationMenuItem;
