export const TASKS_TAB_ID = '3';
export const NOTE_TAB_ID = '5';
export const DEPENDENCIES_TAB_ID = '7';

export const getTabIcons = isTasksExtensionInstalled => {
    const tabs = [
        {
            id: '1',
            title: 'Basic Information',
            desc: `Fill out basic info about this Booking`,
            icon: 'fa-vcard',
        },
        {
            id: '2',
            title: 'Booking Rate',
            desc: 'Define the rate on this booking',
            icon: 'fa-dollar',
        },
    ];
    if (isTasksExtensionInstalled) {
        tabs.push({
            id: TASKS_TAB_ID,
            title: 'Booking Task List',
            desc: 'A task list is a great way to add more finer details',
            icon: 'fa-tasks',
        });
    }

    return [
        ...tabs,
        {
            id: '4',
            title: 'Custom Fields',
            desc: 'Custom Fields for your scheduled Booking.',
            icon: 'fa-pencil',
        },
        {
            id: NOTE_TAB_ID,
            title: 'Notes and Links',
            desc: 'Add Notes and Links to your scheduled Booking',
            icon: 'fa-file',
        },
        {
            id: '6',
            title: 'Booking Info',
            desc: 'View information about this Booking',
            icon: 'fa-info',
        },
        {
            id: DEPENDENCIES_TAB_ID,
            title: `Booking Dependencies`,
            desc: `Create dependent links between bookings`,
            icon: 'fa-link',
            dataCy: `booking-modal-dependencies`,
        },
    ];
};
