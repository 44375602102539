import { useMemo } from 'react';

export const useBookingTitle = ({ booking, loggedResourceId }) => {
    const { resource, resourceFirstName, resourceLastName } = booking;
    return useMemo(() => {
        if (resource === loggedResourceId) {
            return 'My Request';
        }

        if (resource) {
            return `${resourceFirstName} ${resourceLastName}`;
        }

        return 'No Name';
    }, [resource, resourceLastName, resourceFirstName, loggedResourceId]);
};
