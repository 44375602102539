import PermissionDenied from '../../scheduler/components/permissionDenied/permissionDenied';
import authorization from '../../../services/auth/authorization';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showRequestVacationModal } from '../../../actions/modalActions';
import { useQueryParams } from '../../../utils/useQueryParams';
import { createResourceOrVacationRequest } from '../../../actions/bookingActions';

const RequestVacationRoute = () => {
    const dispatch = useDispatch();
    const queryParams = useQueryParams();

    useEffect(() => {
        const redirect = () => {
            if (queryParams.fallbackUrl) {
                window.location.href = queryParams.fallbackUrl;
            } else {
                window.location.href = '/';
            }
        };

        dispatch(
            showRequestVacationModal({
                onSubmit: booking => {
                    return dispatch(createResourceOrVacationRequest(booking, redirect));
                },
                onClose: () => {
                    redirect();
                },
            })
        );
    }, [dispatch, queryParams.fallbackUrl]);
};

const authParameters = {
    allowedRoleRights: ['canRequestVacation'],
    redirectOnLackOfRights: true,
    errorComponent: PermissionDenied,
    routeParamsChecker: () => {
        return true;
    },
};

export const RequestVacationRouteAuth = authorization(authParameters)(RequestVacationRoute);
