import { SCHEDULER_TEMPLATE } from '../../../../../enums/groupTypeEnum';
import { PROJECT, RESOURCE } from '../../../enums/builderTypeEnum';
import { SYSTEM } from '../../../enums/groupTypeEnum';

const NOT_SMART_SCHEDULE_AND_FILTERS_MESSAGE =
    'Smart Schedule Extension must be installed and Smart Schedule enabled in the Type tab';
const NOT_SMART_GROUP_AND_FILTERS_MESSAGE =
    'Smart Group Extension must be installed and Smart Group enabled in the Type tab';

export const buttonStates = ({
    itemId,
    type,
    groupType,
    isSmart,
    itemTypeName,
    canAddNewEditProjectGroups,
    canAddNewEditResourceGroups,
    isSmartGroupInstalled,
    isSmartSchedulesExtensionInstalled,
    isSmartFiltersExtensionInstalled,
    haveAppliedFiltersChanged,
    haveSavedDataOrFiltersChanged,
}) => {
    const isNew = !itemId;
    const isSchedule = type === SCHEDULER_TEMPLATE;
    const isSystem = groupType === SYSTEM;
    const isProject = itemTypeName === PROJECT.name;
    const isResource = itemTypeName === RESOURCE.name;

    const canAddNewEditGroup = isProject
        ? canAddNewEditProjectGroups
        : isResource
        ? canAddNewEditResourceGroups
        : false;
    const isEditing = !isNew;

    const canNotUpdateSchedule = isSchedule && !isSmartSchedulesExtensionInstalled;

    const hasAnyAppliedFilterChanged = Object.values(haveAppliedFiltersChanged).some(Boolean);
    const hasAnyDataOrSavedFilterChanged = Object.values(haveSavedDataOrFiltersChanged).some(Boolean);

    const getMessageForRowOrBookingFiltersChange = () => {
        if (!haveSavedDataOrFiltersChanged.rowOrBookingFilters) {
            return undefined;
        }

        if (isSchedule && !isSmartSchedulesExtensionInstalled) {
            return {
                tooltip: NOT_SMART_SCHEDULE_AND_FILTERS_MESSAGE,
            };
        }

        if (!isSchedule && !isSmartGroupInstalled) {
            return { tooltip: NOT_SMART_GROUP_AND_FILTERS_MESSAGE };
        }

        if (isSystem) {
            // when we save as system group we convert it to smart so no tooltip
            return undefined;
        }

        if (!isSmart) {
            // when we edit or create new
            return { tooltip: NOT_SMART_GROUP_AND_FILTERS_MESSAGE };
        }
    };

    const getMessageForRowOrBookingFiltersChangeForSaveAsGroup = () => {
        if (!haveSavedDataOrFiltersChanged.rowOrBookingFilters) {
            return undefined;
        }

        if (!isSmartGroupInstalled) {
            return { tooltip: NOT_SMART_GROUP_AND_FILTERS_MESSAGE };
        }
    };

    const getMessageForRowOrBookingFiltersChangeForSaveAsSchedule = () => {
        if (!haveSavedDataOrFiltersChanged.rowOrBookingFilters) {
            return undefined;
        }

        if (!isSmartSchedulesExtensionInstalled) {
            return { tooltip: NOT_SMART_SCHEDULE_AND_FILTERS_MESSAGE };
        }
    };

    const getMessageForSmartFiltersChange = () => {
        if (!haveSavedDataOrFiltersChanged.smartFilters) {
            return undefined;
        }

        if (isSchedule && !isSmartSchedulesExtensionInstalled) {
            return { tooltip: NOT_SMART_SCHEDULE_AND_FILTERS_MESSAGE };
        }

        if (isSchedule && !isSmart) {
            return {
                tooltip: NOT_SMART_SCHEDULE_AND_FILTERS_MESSAGE,
            };
        }

        if (!isSchedule && !isSmartGroupInstalled) {
            return { tooltip: NOT_SMART_GROUP_AND_FILTERS_MESSAGE };
        }

        if (isSystem) {
            // when we save as system group we convert it to smart so no tooltip
            return undefined;
        }

        if (!isSmart) {
            return { tooltip: NOT_SMART_GROUP_AND_FILTERS_MESSAGE };
        }
    };

    const predicates = {
        hasExtension: () => {
            if (isSchedule) {
                return isSmartSchedulesExtensionInstalled;
            }

            return isSmartGroupInstalled;
        },
        isSmartGroupInstalled: () => isSmartGroupInstalled,
        isSmartSchedulesExtensionInstalled: () => isSmartSchedulesExtensionInstalled,
        isSmart: () => isSmart,
        isSchedule: () => isSchedule,
        canSaveRowOrBookingFiltersFromSystemGroup: () => {
            // when user tries to save system group with filters we transform it to smart
            // so we make sure that proper extension is installed
            return predicates.hasExtension();
        },
        canSaveRowOrBookingFilters: () => {
            // group / schedule has to be smart and proper extension is installed
            return predicates.hasExtension() && isSmart;
        },
        canSaveRowOrBookingFiltersToSchedule: () => {
            return isSchedule && isSmartSchedulesExtensionInstalled;
        },
        canSaveRowOrBookingFiltersToGroup: () => {
            return !isSchedule && isSmartGroupInstalled;
        },
        canSaveSmartFilters: () => {
            const extensionToBeInstalled = isSchedule ? isSmartSchedulesExtensionInstalled : isSmartGroupInstalled;

            if (!extensionToBeInstalled) {
                return false;
            }

            if (haveSavedDataOrFiltersChanged.rowOrBookingFilters) {
                // in this case we ask user for automated change to smart group / schedule
                return isSmartFiltersExtensionInstalled;
            }

            return isSmart && isSmartFiltersExtensionInstalled;
        },
        canSaveSmartFiltersFromSystemGroup: () => {
            // when user tries to save system group with filters we transform it to smart
            // so we make sure that proper extension is installed
            return predicates.hasExtension();
        },
        canSaveSmartFiltersToGroup: () => isSmartFiltersExtensionInstalled && isSmartGroupInstalled && !isSchedule,
        canSaveSmartFiltersToSchedule: () =>
            isSmartFiltersExtensionInstalled && isSmartSchedulesExtensionInstalled && isSchedule,
        canApplySmartFilters: () => isSmartFiltersExtensionInstalled,
        canSaveGroupType: () => !isSmart || (isSmart && isSmartGroupInstalled),
    };

    const combineEnabledAndGetMessage = (...enabledFunction) => {
        return enabledFunction.reduce(
            (acc, fnc) => {
                const result = fnc();

                return {
                    enabled: acc.enabled && result.enabled,
                    message: acc.message || result.message,
                };
            },
            {
                enabled: true,
                message: undefined,
            }
        );
    };

    const getEnabled = (key, haveChangedMap, predicates, message) => () => {
        if (!haveChangedMap[key]) {
            return { enabled: true };
        }

        if (!predicates.length) {
            return { enabled: true };
        }

        return {
            enabled: predicates.every(predicate => (typeof predicate === 'function' ? predicate() : predicate)),
            message: typeof message === 'function' ? message() : message,
        };
    };

    const getApply = () => {
        if (isSystem || isEditing) {
            if (!hasAnyAppliedFilterChanged) {
                return { enabled: false };
            }

            return combineEnabledAndGetMessage(
                getEnabled('rowOrBookingFilters', haveAppliedFiltersChanged, []),
                getEnabled('smartFilters', haveAppliedFiltersChanged, [predicates.canApplySmartFilters])
            );
        }

        return false;
    };

    const getSave = () => {
        if (!canAddNewEditGroup) {
            return { enabled: false };
        }

        if (isEditing) {
            if (!hasAnyDataOrSavedFilterChanged) {
                return { enabled: false };
            }

            return combineEnabledAndGetMessage(
                getEnabled('title', haveSavedDataOrFiltersChanged, []),
                getEnabled(
                    'rowOrBookingFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.isSmart, predicates.isSmartGroupInstalled],
                    getMessageForRowOrBookingFiltersChange
                ),
                getEnabled(
                    'smartFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.isSmart, predicates.canSaveSmartFilters],
                    getMessageForSmartFiltersChange
                )
            );
        }

        return false;
    };

    const getSaveAs = () => {
        if (!canAddNewEditGroup) {
            return { enabled: false };
        }

        if (isNew) {
            if (canNotUpdateSchedule) {
                return { enabled: false };
            }

            if (!hasAnyDataOrSavedFilterChanged) {
                return { enabled: true };
            }

            return combineEnabledAndGetMessage(
                getEnabled(
                    'rowOrBookingFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveRowOrBookingFilters],
                    getMessageForRowOrBookingFiltersChange
                ),
                getEnabled(
                    'smartFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveSmartFilters],
                    getMessageForSmartFiltersChange
                )
            );
        } else if (isSystem) {
            if (canNotUpdateSchedule) {
                return { enabled: false };
            }

            if (!hasAnyDataOrSavedFilterChanged) {
                return { enabled: true };
            }

            return combineEnabledAndGetMessage(
                getEnabled('groupType', haveSavedDataOrFiltersChanged, [predicates.canSaveGroupType]),
                getEnabled('title', haveSavedDataOrFiltersChanged, []),
                getEnabled(
                    'rowOrBookingFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveRowOrBookingFiltersFromSystemGroup],
                    getMessageForRowOrBookingFiltersChange
                ),
                getEnabled(
                    'smartFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveSmartFiltersFromSystemGroup],
                    getMessageForSmartFiltersChange
                )
            );
        } else if (isEditing) {
            if (canNotUpdateSchedule) {
                return { enabled: false };
            }

            if (!hasAnyDataOrSavedFilterChanged) {
                return { enabled: true };
            }

            return combineEnabledAndGetMessage(
                getEnabled('groupType', haveSavedDataOrFiltersChanged, [predicates.canSaveGroupType]),
                getEnabled('title', haveSavedDataOrFiltersChanged, []),
                getEnabled(
                    'rowOrBookingFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveRowOrBookingFilters],
                    getMessageForRowOrBookingFiltersChange
                ),
                getEnabled(
                    'smartFilters',
                    haveSavedDataOrFiltersChanged,
                    [predicates.canSaveSmartFilters],
                    getMessageForSmartFiltersChange
                )
            );
        }

        return false;
    };

    const getSaveAsGroup = () => {
        if (!canAddNewEditGroup) {
            return { enabled: false };
        }

        if (!hasAnyDataOrSavedFilterChanged) {
            return { enabled: true };
        }

        return combineEnabledAndGetMessage(
            getEnabled('groupType', haveSavedDataOrFiltersChanged, [predicates.canSaveGroupType]),
            getEnabled('title', haveSavedDataOrFiltersChanged, []),
            getEnabled(
                'rowOrBookingFilters',
                haveSavedDataOrFiltersChanged,
                [predicates.isSmartGroupInstalled],
                getMessageForRowOrBookingFiltersChangeForSaveAsGroup
            ),
            getEnabled(
                'smartFilters',
                haveSavedDataOrFiltersChanged,
                [predicates.canSaveSmartFiltersToGroup],
                getMessageForSmartFiltersChange
            )
        );
    };

    const getSaveAsSchedule = () => {
        if (!canAddNewEditGroup) {
            return { enabled: false };
        }

        if (!hasAnyDataOrSavedFilterChanged) {
            return { enabled: true };
        }

        return combineEnabledAndGetMessage(
            getEnabled('groupType', haveSavedDataOrFiltersChanged, [predicates.canSaveGroupType]),
            getEnabled('title', haveSavedDataOrFiltersChanged, []),
            getEnabled(
                'rowOrBookingFilters',
                haveSavedDataOrFiltersChanged,
                [predicates.isSmartSchedulesExtensionInstalled],
                getMessageForRowOrBookingFiltersChangeForSaveAsSchedule
            ),
            getEnabled(
                'smartFilters',
                haveSavedDataOrFiltersChanged,
                [predicates.canSaveSmartFiltersToSchedule],
                getMessageForSmartFiltersChange
            )
        );
    };

    const getSavePrivateInternal = () => {
        if (!canAddNewEditGroup) {
            return { enabled: false };
        }

        return { enabled: isSmartSchedulesExtensionInstalled };
    };

    return {
        enableApply: getApply(),
        enableSave: getSave(),
        enableSaveAs: getSaveAs(),
        enableSaveAsGroup: getSaveAsGroup(),
        enableSaveAsSchedule: getSaveAsSchedule(),
        enableSavePrivateInternal: getSavePrivateInternal(),
    };
};
