import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { FormText, TabPane } from 'reactstrap';
import { useAppKeyWords, useHasRights } from 'hooks';
import { multiSelectField } from 'shared/formFields';
import PermissionDenied from 'shared/permissionDenied';

const ProjectTab = ({ tabId, projects, resourceGroups }) => {
    const { resourceKeyWord, projectPluralKeyWord } = useAppKeyWords();
    const { hasSettingResourceGroups, hasSettingResourcePro } = useHasRights([
        {
            rights: ['settingResourceGroups'],
            rule: 'one',
            name: 'hasSettingResourceGroups',
        },
        {
            rights: ['settingResourcePro'],
            rule: 'one',
            name: 'hasSettingResourcePro',
        },
    ]);
    return (
        <TabPane tabId={tabId}>
            {hasSettingResourcePro && (
                <section className="form-fields">
                    <p className="title">Add {projectPluralKeyWord}:</p>
                    <Field
                        inline
                        width="col-md-12"
                        name="projects"
                        component={multiSelectField}
                        caseSensitive={false}
                        valueField="id"
                        filter="contains"
                        textField="name"
                        data={projects}
                    />
                    <FormText>Choose which {projectPluralKeyWord} belong to this {resourceKeyWord}</FormText>
                </section>
            )}
            {hasSettingResourceGroups && (
                <section className="form-fields">
                    <p className="title">Add to Groups:</p>
                    <Field
                        inline
                        width="col-md-12"
                        name="resourceGroups"
                        component={multiSelectField}
                        caseSensitive={false}
                        dropUp={true}
                        valueField="_id"
                        filter="contains"
                        textField="name"
                        data={resourceGroups}
                    />
                    <FormText>Choose which Groups belong to this {resourceKeyWord}</FormText>
                </section>
            )}
            {!hasSettingResourceGroups && !hasSettingResourcePro && <PermissionDenied />}
        </TabPane>
    );
};

ProjectTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    projects: PropTypes.array,
    resourceGroups: PropTypes.array,
};

ProjectTab.defaultProps = {
    projects: [],
    resourceGroups: [],
};

export default ProjectTab;
