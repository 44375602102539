import React from 'react';
import { contains } from 'underscore';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import { Header } from './../styles';
import { displayDateState, displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        {displayDateState(dateState, false, false)}
                        <p className="sub-title">(scheduled & reported difference)</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledReportedDifferenceBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledReportedDifferenceBetween'),
        accessor: row =>
            displayValue(
                row.scheduledReportedDifferenceBetween,
                unitTypes[unit].calculation(row.scheduledReportedDifferenceBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Estimated Time
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'estimatedTime',
        sortable: false,
        show: contains(columnsToLoad, 'estimatedTime'),
        accessor: row =>
            displayValue(row.estimatedTime, unitTypes[unit].calculation(row.estimatedTime, avgDailyCapacity)),
    },
];
