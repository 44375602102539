import { push } from 'connected-react-router';
import { call, put, takeLatest, select, fork, cancelled, delay } from 'redux-saga/effects';
import { intersection, keys } from 'lodash';
import * as actionTypes from 'actions/actionTypes';
import {
    getAllProjects,
    createProjectRequest,
    getProjectRequest,
    duplicateProjectRequest,
    deleteProjectRequest,
    updateProjectRequest,
    createMultipleProjectsRequest,
    addResourceToProjectRequest,
} from 'api/project';
import {
    getProjects,
    createProject,
    getProject,
    duplicateProject,
    deleteProject,
    updateProject,
    createMultipleProjects,
    addResourceToProject,
} from 'actions/projectActions';
import { changeActiveLinkFromLocation, changeAndOpenActiveLinkFromLocation } from 'actions/menu/content';
import { hideModal } from 'actions/modalActions';
import { addNotification } from 'actions/notificationActions';
import { getFinancialData, resetScheduler } from 'actions/schedulerActions';
import { getProjects as getProjectsSelector, selectLastCallContext } from 'selectors/project';
import { getCompanyTree } from './companyTreeSaga';
import { callProjectGroups } from './projectGroupSaga';
import { getCompanyId } from 'selectors/company';
import { getAccountGridPreferences, getLoggedInId } from 'selectors/account';
import { params, childRoutePaths, routePaths } from 'utils/routeHelper';
import { getViewObject } from 'modules/scheduler/utils/schedulerUtil';
import { makeRequestCancellable } from '../api/makeRequestCancellable';
import { isSchedulerView } from '../utils/isSchedulerView';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { dispatchRefreshBookings } from '../modules/scheduler/utils/schedulerUtil';
import { makeGetProjectGroupById } from '../selectors/projectGroup';
import { GROUP } from '../enums/groupTypeEnum';
import { USER } from '../modules/scheduler/enums/groupTypeEnum';
import { replaceGroupIdInLink } from '../utils/linkCreatorUtil';
import { getNotificationContent } from './helpers/bulkCreate';
import { monitoring } from '../monitoring';

const getRoutePathName = state => state.router.location.pathname;
const getErrorMessage = error => {
    let message = null;
    if ('project.name.or.code.exists' === error?.response?.data.error) {
        message = error.response.data.message;
    }

    return message;
};

function* handleGetProjects(action) {
    const request = makeRequestCancellable(getAllProjects);
    const lastCallContext = yield select(selectLastCallContext);

    const schedulerView = isSchedulerView();
    const callContext = schedulerView ? 'SCHEDULER' : 'NOT_SCHEDULER';

    try {
        const projects = yield select(getProjectsSelector);
        if (projects.length && true !== action.payload.force && lastCallContext === callContext) {
            yield put(getProjects.stop());
            return;
        }

        // Network call with correct company id.
        const companyId = yield select(getCompanyId);
        let projectsResponse = yield call(request.call, companyId, schedulerView);

        // Replace with status color (projects that are using them) when getting them
        projectsResponse = replaceProjectsColorWithStatusColor(projectsResponse);

        yield put(getProjects.success(projectsResponse, callContext));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Projects can't be loaded",
                type: 'danger',
            })
        );
    } finally {
        if (cancelled()) {
            request.cancel();
        }
    }
}

export function* handleCreateProject(action) {
    try {
        const projectResponse = yield call(createProjectRequest, action.payload.data);
        yield put(createProject.success(projectResponse, action.payload.data.projectGroupsAdd));
        action.payload.callback && action.payload.callback(projectResponse);
        yield put(hideModal());
        if (action.payload.redirect && projectResponse.link) {
            yield put(push(projectResponse.link));
            yield put(resetScheduler.request());
            yield put(changeAndOpenActiveLinkFromLocation());
        }
        yield delay(1000);
        yield fork(callProjectGroups);
        return projectResponse;
    } catch (error) {
        monitoring.captureException(error);
        yield put(createProject.failure());
        yield put(
            addNotification({
                message:
                    getErrorMessage(error) || 'Something went wrong... Please check if you filled all required fields',
                type: 'danger',
            })
        );
    }
}

function* handleDuplicateProject(action) {
    const { projectId, callback, projectGroupId } = action.payload || {};
    const projectGroup = yield select(makeGetProjectGroupById(projectGroupId));

    try {
        let duplicatedProject = yield call(duplicateProjectRequest, projectId);
        duplicatedProject = replaceProjectsColorWithStatusColor(duplicatedProject);

        const shouldAddToGroup =
            projectGroup?.type === GROUP && projectGroup?.groupType === USER && !projectGroup?.isSmart;

        if (shouldAddToGroup) {
            yield call(updateProjectRequest, duplicatedProject._id, {
                project: {
                    _id: duplicatedProject._id,
                },
                projectGroupsAdd: [projectGroupId],
            });
        }

        yield put(getProjects.request(true));

        if (callback) {
            callback(duplicatedProject);
        }

        yield put(duplicateProject.success(duplicatedProject, shouldAddToGroup ? [projectGroupId] : []));

        if (projectGroup?.isSmart) {
            yield delay(1000);
            yield call(callProjectGroups);
        }

        if (duplicatedProject.link) {
            yield put(push(replaceGroupIdInLink(duplicatedProject.link, projectGroupId)));
            yield put(changeActiveLinkFromLocation());
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Something went wrong... We couldn't handle your request",
                type: 'danger',
            })
        );
    }
}

function* handleGetProject(action) {
    try {
        let project = yield call(getProjectRequest, action.payload.id);
        project = replaceProjectsColorWithStatusColor(project);

        yield put(getProject.success(project));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... We could not load data for this project',
                type: 'danger',
            })
        );
    }
}

function* handleDeleteProject(action) {
    try {
        yield call(deleteProjectRequest, action.payload.id);
        yield put(hideModal());

        const pathname = yield select(getRoutePathName);
        if (-1 !== pathname.indexOf(action.payload.id)) {
            yield put(
                push(
                    pathname.substring(0, pathname.indexOf(action.payload.id) - 1) +
                        pathname.substring(pathname.indexOf(action.payload.id) + action.payload.id.length)
                )
            );
        }
        action.payload?.callback && action.payload.callback();

        yield put(deleteProject.success(action.payload.id));

        dispatchRefreshBookings();
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: error.response?.data?.message ?? 'Something went wrong... We could not delete this project',
                type: 'danger',
            })
        );
    }
}

function* handleUpdateProject(action) {
    try {
        let project = yield call(updateProjectRequest, action.payload.id, action.payload.data);
        project = replaceProjectsColorWithStatusColor(project);

        const { mode, singleProjectView } = yield select(getAccountGridPreferences);
        const pathname = yield select(getRoutePathName);
        const loggedInResourceId = yield select(getLoggedInId);
        const { projectId, projectGroupId } = params([...childRoutePaths, ...routePaths], pathname)?.params || {};
        const fields = ['budgetCashAmount', 'budgetCurrency', 'budgetHours'];
        const updatedFields = intersection(keys(action.payload.data.project), fields);
        const { isSingleProjectView, isProjectGroupView } = getViewObject({ projectId, projectGroupId });
        try {
            if (
                isSingleProjectView &&
                singleProjectView.informationPanelDisplayed &&
                (singleProjectView.displayScheduledColumn || singleProjectView.displayReportedColumn) &&
                0 < updatedFields.length &&
                updatedFields.length <= fields.length
            ) {
                yield put(
                    getFinancialData.request({
                        projectId,
                        projectGroupId,
                        resourceId: loggedInResourceId,
                        singleProjectView,
                        isSingleProjectView,
                        isProjectGroupView,
                    })
                );
            }
        } catch (error) {
            console.log('error during updating financial data', error);
        }

        yield put(
            updateProject.success(
                project,
                action.payload.data.projectGroupsAdd,
                action.payload.data.projectGroupsRemove,
                mode
            )
        );

        action.payload.callback && action.payload.callback(project);
        yield put(hideModal());
        yield delay(1000);
        yield fork(callProjectGroups);

        const shouldRefreshBookings = !!(window?.schedulerRef?.current?.control?.events?.all() || []).find(ev => {
            return ev?.data?.project?._id === project._id || ev?.data?.project?.id === project._id;
        });
        shouldRefreshBookings &&
            dispatchRefreshBookings({
                row: project,
            });
    } catch (error) {
        monitoring.captureException(error);
        yield put(updateProject.failure());
        yield put(
            addNotification({
                message: getErrorMessage(error) || 'Something went wrong... We could not update this project',
                type: 'danger',
            })
        );
    }
}

function* handleCreateMultipleProjects(action) {
    try {
        const response = yield call(createMultipleProjectsRequest, action.payload.data);
        yield fork(getCompanyTree);
        yield put(getProjects.request(true));

        if (!action.payload.withoutNotification) {
            yield put(addNotification(getNotificationContent(response, 'project')));
        }

        const replaced = replaceProjectsColorWithStatusColor(
            (response.projects.created ?? []).concat(response.projects.updated)
        );

        yield put(createMultipleProjects.success(replaced));
        yield put(hideModal());

        if (action.payload.callback) {
            action.payload.callback(response.projects.created);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: 'Something went wrong... We could not create projects',
                type: 'danger',
            })
        );
    }
}

function* handleAddResourceToProject(action) {
    const { projectId, resourceId, onSuccess } = action.payload;

    let updatedProjects;

    try {
        updatedProjects = yield call(addResourceToProjectRequest, projectId, resourceId);
    } catch (error) {
        monitoring.captureException(error);

        yield put(
            addNotification({
                message: 'Something went wrong... We could not update project',
                type: 'danger',
            })
        );

        return;
    }

    yield put(
        addResourceToProject.success({
            updatedProjects,
            projectId,
            resourceId,
        })
    );

    if (onSuccess) {
        onSuccess();
    }
}

export default function* projectWatcher() {
    yield takeLatest(actionTypes.GET_PROJECTS['REQUEST'], handleGetProjects);
    yield takeLatest(actionTypes.GET_PROJECT['REQUEST'], handleGetProject);
    yield takeLatest(actionTypes.CREATE_PROJECT['REQUEST'], handleCreateProject);
    yield takeLatest(actionTypes.DUPLICATE_PROJECT['REQUEST'], handleDuplicateProject);
    yield takeLatest(actionTypes.DELETE_PROJECT['REQUEST'], handleDeleteProject);
    yield takeLatest(actionTypes.UPDATE_PROJECT['REQUEST'], handleUpdateProject);
    yield takeLatest(actionTypes.CREATE_MULTIPLE_PROJECTS['REQUEST'], handleCreateMultipleProjects);
    yield takeLatest(actionTypes.ADD_RESOURCE_TO_PROJECT['REQUEST'], handleAddResourceToProject);
}
