import Login from './components/login';
import SSO from './components/sso';
import SignUp from './components/signUp';
import ForgotPassword from './components/forgotPassword';
import ResetPassword from './components/resetPassword';
import Activation from './components/activation';
import Welcome from './components/welcome';
import Main from 'modules/index';
import Launchpad from './components/launchpad';
import Logout from './components/logout';
import { FullScreenLayout } from 'modules/templates';
import LoginOTP from './components/login-otp';

export default [
    {
        path: '/',
        exact: true,
        component: Main,
    },
    {
        path: '/login/otp/:companyId',
        component: LoginOTP,
        title: 'HUB Planner | Sign In',
        exact: true,
    },
    {
        path: '/login',
        component: Login,
        title: 'HUB Planner | Sign In',
        exact: true,
    },
    {
        path: '/sso-login',
        component: SSO,
        title: 'HUB Planner | SSO Sign In',
        exact: true,
    },
    {
        path: '/signup',
        component: SignUp,
        title: 'HUB Planner | Sign Up',
        exact: true,
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        title: 'HUB Planner | Forgot password',
    },
    {
        path: '/reset-password/:token',
        component: ResetPassword,
        title: 'HUB Planner | Reset password',
    },
    {
        path: '/activation/:token',
        component: Activation,
        title: 'HUB Planner | Activate account',
    },
    {
        path: '/welcome',
        component: Welcome,
        title: 'Hub Planner | Welcome',
        protected: true,
        layout: FullScreenLayout,
    },
    {
        path: '/launchpad',
        component: Launchpad,
        title: 'Hub Planner | Select a Company',
        layout: FullScreenLayout,
    },
    {
        path: '/logout',
        component: Logout,
        exact: true,
    },
];
