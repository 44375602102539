import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import { APPROVED, SCHEDULED } from 'enums/bookingTypeEnum';
import ScheduleMenu from './partials/schedule';
import ApprovalMenu from './partials/approval';
import LegacyBookingMenu from './partials/legacy/schedule';
import LegacyApprovalMenu from './partials/legacy/approval';
import { useAccountPreferences } from 'modules/scheduler/hooks';
import { NEW } from 'enums/contextMenuEnum';
import { getLoggedInId } from 'selectors/account';
import { showEditRequestResourceModal } from 'actions/modalActions';
import { updateResourceOrVacationRequest } from 'actions/requestActions';
import { isAbleToEditResourceOrVacationRequest } from 'modules/request/utils/permissions';
import { useRequests } from 'modules/request/hooks';
import { selectCurrentSelectedBooking } from '../../../../../selectors/scheduler';

export const CONTEXT_MENU_MAX_HEIGHT = 446;
export const SCHEDULED_CONTEXT_MENU_WIDTH = 280;
export const APPROVAL_CONTEXT_MENU_WIDTH = 280;
export const CLASSIC_CONTEXT_MENU_WIDTH = 240;

const Booking = React.forwardRef((props, ref) => {
    const { schedulerRef, onClose, height, form, left } = props;
    const currentSelectedBooking = useSelector(selectCurrentSelectedBooking);
    const currentResourceId = useSelector(getLoggedInId);
    const { vacationId } = useSelector(state => state.companyReducer.company.settings);
    const dispatch = useDispatch();
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);
    const { hasManageApprovalForBooking } = useRequests(currentSelectedBooking);
    const { gridPreferences } = useAccountPreferences();
    const width = includes([SCHEDULED.value, APPROVED.value], currentSelectedBooking.type)
        ? SCHEDULED_CONTEXT_MENU_WIDTH
        : APPROVAL_CONTEXT_MENU_WIDTH;

    const isAbleToEdit = isAbleToEditResourceOrVacationRequest(
        currentSelectedBooking,
        currentResourceId,
        resourceRoleRights,
        vacationId
    );

    const onEdit = useCallback(() => {
        dispatch(
            showEditRequestResourceModal(data => {
                dispatch(updateResourceOrVacationRequest(data));
            }, currentSelectedBooking)
        );
        onClose?.();
    }, [dispatch, currentSelectedBooking, onClose]);

    if (includes([SCHEDULED.value, APPROVED.value], currentSelectedBooking.type)) {
        return (
            <div
                ref={ref}
                className="rounded-0 p-0"
                style={
                    gridPreferences.contextMenu === NEW
                        ? { width: `${width}px` }
                        : { width: `${CLASSIC_CONTEXT_MENU_WIDTH}px` }
                }
            >
                {gridPreferences.contextMenu === NEW ? (
                    <ScheduleMenu form={form} onClose={onClose} schedulerRef={schedulerRef} height={height} />
                ) : (
                    <LegacyBookingMenu schedulerRef={schedulerRef} onClose={onClose} left={left} />
                )}
            </div>
        );
    }

    if (!hasManageApprovalForBooking && !isAbleToEdit) {
        return null;
    }

    return (
        <div
            ref={ref}
            className="rounded-0 p-0"
            style={
                gridPreferences.contextMenu === NEW
                    ? { width: `${width}px` }
                    : { width: `${CLASSIC_CONTEXT_MENU_WIDTH}px` }
            }
        >
            {gridPreferences.contextMenu === NEW ? (
                <ApprovalMenu
                    schedulerRef={schedulerRef}
                    onClose={onClose}
                    onEdit={isAbleToEdit ? onEdit : undefined}
                    hasManageApprovalForBooking={hasManageApprovalForBooking}
                    currentSelectedBooking={currentSelectedBooking}
                />
            ) : (
                <LegacyApprovalMenu
                    schedulerRef={schedulerRef}
                    onClose={onClose}
                    hasManageApprovalForBooking={hasManageApprovalForBooking}
                    onEdit={isAbleToEdit ? onEdit : undefined}
                    currentSelectedBooking={currentSelectedBooking}
                />
            )}
        </div>
    );
});

Booking.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    form: PropTypes.string,
    onClose: PropTypes.func,
    height: PropTypes.number,
};

Booking.defaultProps = {
    onClose: null,
    form: undefined,
    height: CONTEXT_MENU_MAX_HEIGHT,
};

export default Booking;
