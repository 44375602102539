import moment from 'moment';
import { assign, find, includes, map } from 'lodash';
import { parse } from 'services/queryString';
import { isVacationFlow, isMyRequests, isMyResourceRequests } from 'modules/request/utils/dataFlowUtil';
import { getResourceCapacity } from 'utils/capacityCalculation';
import { hasRole } from 'utils/rightsUtil';
import { getWeekNumbersObject, getMonthsObject, getMonthFromString } from 'utils/DateUtil';
import { endOfYear, differenceInDays, setMonth, format, addYears, subDays, subYears } from 'date-fns';

export const daysUntilEndOfYear = differenceInDays(endOfYear(new Date()), new Date());
const currentYear = moment().format('YYYY');

export function getDateRanges(start) {
    let currentStart = moment.utc(start, 'YYYY-MM-DD').set({ year: currentYear });
    let currentEnd = currentStart
        .clone()
        .add(1, 'y')
        .subtract(1, 'd');
    if (currentStart.isAfter(moment.utc())) {
        currentStart = currentStart.subtract(1, 'y');
        currentEnd = currentEnd.subtract(1, 'y');
    }
    return [
        {
            label: 'This Year',
            value: 'this',
            start: currentStart.clone().format('YYYY-MM-DD'),
            end: currentEnd.clone().format('YYYY-MM-DD'),
        },
        {
            label: 'Next Year',
            value: 'next',
            start: currentStart
                .clone()
                .add(1, 'y')
                .format('YYYY-MM-DD'),
            end: currentEnd
                .clone()
                .add(1, 'y')
                .format('YYYY-MM-DD'),
        },
        {
            label: 'Last Year',
            value: 'last',
            start: currentStart
                .clone()
                .subtract(1, 'y')
                .format('YYYY-MM-DD'),
            end: currentEnd
                .clone()
                .subtract(1, 'y')
                .format('YYYY-MM-DD'),
        },
    ];
}

export function calculateApprovalDates(startDate) {
    const endDate = subDays(addYears(startDate, 1), 1);
    const now = Date.now();

    if (now < startDate.getTime()) {
        const start = subYears(startDate, 1);
        const end = subDays(startDate, 1);

        return {
            start: format(start, 'yyyy-MM-dd'),
            end: format(end, 'yyyy-MM-dd'),
            dayCount: differenceInDays(end, start) + 1,
        };
    }
    return {
        end: format(endDate, 'yyyy-MM-dd'),
        dayCount: differenceInDays(endDate, startDate) + 1,
        start: format(startDate, 'yyyy-MM-dd'),
    };
}

export function findRangeByStartEndDates(dateRanges, start, end) {
    return find(dateRanges, item => item.start === start && item.end === end);
}

export function getDefaultAllocationValues(startDate, endDate, resource, companySettings) {
    const defaultValues = { percentage: 100, hours: 0, total: 0 };
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (!momentStartDate.isValid() || !momentEndDate.isValid()) {
        return defaultValues;
    }

    const resourceCapacity = getResourceCapacity(resource, momentStartDate, momentEndDate);
    if (momentStartDate.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD')) {
        const startDateDayName = momentStartDate.format('dddd').toLowerCase();
        defaultValues.hours =
            resourceCapacity.workWeekDetails[startDateDayName].minutes / 60 || companySettings.report.avgDailyCapacity;
    } else {
        defaultValues.hours = resourceCapacity.averageWorkDay;
    }

    defaultValues.total =
        0 === resourceCapacity.totalMinutes ? defaultValues.hours : resourceCapacity.totalMinutes / 60;

    return defaultValues;
}

export function getAllocationValuesForNewRequest(startDate, endDate, resource, companySettings) {
    const defaultValues = { percentage: 100, hours: 0, total: 0 };
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);

    if (!momentStartDate.isValid() || !momentEndDate.isValid()) {
        return defaultValues;
    }

    const resourceCapacity = getResourceCapacity(resource, momentStartDate, momentEndDate);
    const totalMinutes = resourceCapacity.totalMinutes === 0 ? defaultValues.hours : resourceCapacity.totalMinutes;

    if (momentStartDate.format('YYYY-MM-DD') === momentEndDate.format('YYYY-MM-DD')) {
        const startDateDayName = momentStartDate.format('dddd').toLowerCase();
        const minutes = resourceCapacity.workWeekDetails[startDateDayName].minutes / 60;

        defaultValues.hours = typeof minutes === 'number' ? minutes : companySettings.report.avgDailyCapacity;
    } else {
        defaultValues.hours = totalMinutes / resourceCapacity.workDaysCount / 60;
    }

    defaultValues.total = totalMinutes / 60;

    return defaultValues;
}

function isVacationApprover(account, flow) {
    return (
        hasRole(account.resourceRoleRights, 'manageAllVacations') &&
        includes(['VACATION_FLOW', 'VACATION_GRID', 'VACATION_FLOW_SINGLE'], flow)
    );
}

function isResourceApprover(account, flow) {
    return (
        hasRole(account.resourceRoleRights, 'manageAllResourceRequests') &&
        includes(['APPROVAL_FLOW', 'REQUEST_GRID', 'RES_REQUEST_FLOW_SINGLE'], flow)
    );
}

export function getCurrentRequestModel(searchParams, flow, account, vacationYearStartDate, requestStartDate) {
    const queryParams = parse(searchParams);
    const currentFlow = queryParams.flow || flow;
    const month = isVacationFlow(currentFlow) && vacationYearStartDate ? vacationYearStartDate : 'January';
    const approvalDates = calculateApprovalDates(setMonth(requestStartDate, getMonthFromString(month)));

    return assign(
        {},
        {
            ...queryParams,
            ...approvalDates,
            flow: queryParams.flow || flow,
            filter: queryParams.filter || 'ALL',
            resourceId: queryParams.resourceId || account.resourceId,
            loggedUserIsCreator: isMyResourceRequests(currentFlow),
            isVacation: isVacationFlow(currentFlow),
            isApprover: !(
                isMyRequests(currentFlow) ||
                isVacationApprover(account, currentFlow) ||
                isResourceApprover(account, currentFlow)
            ),
        }
    );
}

export const getChartLabels = (type, start, end, companyWeekType) => {
    const dateStart = moment.utc(start, 'YYYY-MM-DD');
    const dateEnd = moment.utc(end, 'YYYY-MM-DD');
    const weekType = 0 === companyWeekType ? 'w' : 'W';

    if ('months' !== type) {
        return map(getWeekNumbersObject(dateStart, dateEnd, weekType), 'shortFormat');
    }

    return map(getMonthsObject(dateStart, dateEnd, 'MMM'), 'shortFormat');
};
