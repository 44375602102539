import { axiosInstance } from './axiosInstance';
import { getOTPHeader } from './common';

export const getResourceApprovees = resourceId =>
    axiosInstance
        .get(`resource/${resourceId}/approvees`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateResourceApprovees = (resourceId, approveeIds) =>
    axiosInstance
        .put(`resource/${resourceId}/approvees`, { approveeIds })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteResourceApprovees = (resourceId, approveeIds) =>
    axiosInstance
        .delete(`resource/${resourceId}/approvees`, { data: { approveeIds } })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createResourceRequest = data =>
    axiosInstance
        .post('resource', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const duplicateResourceRequest = resourceId =>
    axiosInstance
        .post(`resource/${resourceId}`, {})
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getResourcesRequest = cancelToken => ({ companyId, forScheduler = false, pmFilter, status } = {}) =>
    axiosInstance
        .get(`company/${companyId}/resourcesBasicInfo`, {
            cancelToken,
            params: {
                forScheduler,
                pmFilter,
                status,
            },
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getResourceRequest = resourceId =>
    axiosInstance
        .get(`resource/${resourceId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getLoggedInUserRequest = () =>
    axiosInstance
        .get('resource/getLoggedInUser')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const addProjectsToResourceRequest = (resourceId, projects) =>
    axiosInstance
        .put(`resource/${resourceId}/addProjects`, projects)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateResourceRequest = (id, body) =>
    axiosInstance
        .put(`resource/${id}`, body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateLoggedInResourceRequest = data => {
    const { otp, ...body } = data ?? {};

    return axiosInstance
        .put('resource/profile', body, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const deleteResourceRequest = resourceId =>
    axiosInstance
        .delete(`resource/${resourceId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createMultipleResourcesRequest = data =>
    axiosInstance
        .post('resource/multiple', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const generateCalendarKeyRequest = () =>
    axiosInstance
        .get('resource/generateCalendarKey')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getResourceByEmail = email =>
    axiosInstance
        .get(`resource/search?q=${email}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getCustomAvailabilityOverride = id =>
    axiosInstance
        .get(`customAvailabilityOverride/resource/${id}`)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
