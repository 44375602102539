import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import allocationTypes from 'enums/allocationTypeEnum';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import Allocation from 'shared/allocation/formikAllocation';
import { Toggler, Text, ToggleIconHolder } from './styles';
import { MenuCollapse } from '../menuCollapse';

const AllocationToggler = props => {
    const { state, onToggleOpen, closeToggle, onAllocationValueChange, onAllocationTypeChange } = props;
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        closeToggle && setIsOpen(false);
    }, [closeToggle]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
        !isOpen && onToggleOpen && onToggleOpen();
    };

    return (
        <>
            <Toggler data-cy="allocation-toggler" type="button" onClick={toggleOpen}>
                <Text>
                    <span>Allocation:</span> {(allocationTypes[state] && allocationTypes[state].display) || state}
                </Text>
                <ToggleIconHolder>{isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</ToggleIconHolder>
            </Toggler>
            <MenuCollapse isOpen={isOpen}>
                <div className="px-3 py-2">
                    <Allocation
                        medium
                        inline={true}
                        onAllocationValueChange={onAllocationValueChange}
                        onAllocationTypeChange={onAllocationTypeChange}
                        allocationDefaultState={state}
                    />
                </div>
            </MenuCollapse>
        </>
    );
};

AllocationToggler.propTypes = {
    state: PropTypes.string,
    onAllocationValueChange: PropTypes.func.isRequired,
    onAllocationTypeChange: PropTypes.func.isRequired,
};

AllocationToggler.defaultProps = {
    state: '',
    onAllocationValueChange: () => {},
    onAllocationTypeChange: () => {},
};

export default AllocationToggler;
