import React from 'react';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const ContextMenuVersion = ({ contextMenu, onChange }) => (
    <Section>
        <SectionHeader>Scheduler menu type</SectionHeader>

        <FormControl component="fieldset" className="ml-1 mt-2">
            <RadioGroup
                aria-label="contextMenu"
                name="contextMenu"
                value={contextMenu}
                onChange={event => onChange({ contextMenu: event.target.value })}
            >
                <FormControlLabel
                    value="LEGACY"
                    control={<GreenRadio dataCy="my-pref-radio-classic-menu" />}
                    className="mb-2"
                    label="Classic menu"
                />
                <FormControlLabel
                    value="NEW"
                    control={<GreenRadio dataCy="my-pref-radio-modern-menu" />}
                    className="mb-2"
                    label="Modern menu"
                />
            </RadioGroup>
        </FormControl>
    </Section>
);

ContextMenuVersion.propTypes = {
    contextMenu: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ContextMenuVersion;
