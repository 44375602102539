import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEqual from 'react-fast-compare';
import Alert from './Alert';
import { removeNotification } from 'actions/notificationActions';
import { AlertWrapper } from './styles';

class Notifications extends Component {
    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.notifications, this.props.notifications);
    }

    handleAlertDismiss(alertId) {
        this.props.remove(alertId);
    }

    render() {
        return 0 < this.props.notifications.length ? (
            <AlertWrapper>
                {this.props.notifications.map(notification => (
                    <Alert
                        key={notification.id}
                        id={notification.id}
                        type={notification.type}
                        message={notification.message}
                        showLoader={notification.showLoader}
                        timeout={notification.timeout}
                        html={notification.html}
                        onDismiss={() => this.handleAlertDismiss(notification.id)}
                    />
                ))}
            </AlertWrapper>
        ) : null;
    }
}

Notifications = connect(
    state => ({
        notifications: state.notificationReducer.get('notifications').toJSON(),
    }),
    dispatch => ({
        remove: alertId => dispatch(removeNotification(alertId)),
    })
)(Notifications);

export default Notifications;
