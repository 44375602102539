/* eslint-env browser */
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import './styles/global.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { isUndefined } from 'lodash';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, browserHistory } from './store';
import App from './App';
import { ThemeProvider } from '@hub-mono/ui';

window.devLog = () => {};
window.devUtils = () => {
    window.getReduxState = () => {
        const reduxState = store.getState();
        return reduxState;
    };

    window.addEventListener(
        'keydown',
        function(e) {
            if (e.key === 'F8') {
                // eslint-disable-next-line
                debugger;
                console.log('Debugger triggered');
            }
        },
        false
    );

    // Logs that on production are not visible, until are turned on
    window.devLog = console.log;
};

process.env.NODE_ENV === 'development' && window.devUtils();

// Support for IE9+
if ('function' !== typeof NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}
if (isUndefined(Number.EPSILON)) {
    Number.EPSILON = Math.pow(2, -52);
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ConnectedRouter history={browserHistory}>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </ConnectedRouter>
    </Provider>
);
