import React from 'react';
import { DATE_FORMATS, formatDate, removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

const MilestoneBubble = React.memo(props => {
    const { name, date } = props;
    return (
        <div>
            Milestone - {name} - {formatDate(removeUTCZuluFromDateTimestamp(date), DATE_FORMATS.MONTH_DAY_YEAR)}
        </div>
    );
});

export default MilestoneBubble;
