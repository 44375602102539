import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field, FieldArray } from 'redux-form';
import { TabPane, Row, Col } from 'reactstrap';
import { useHasRights } from 'hooks';
import { dateField, materialInputGroupField } from 'shared/formFields';
import Repeat from 'shared/repeat';
import ColorPicker from 'shared/colorPicker';
import PermissionDenied from 'shared/permissionDenied';
import SuccessButton from '../../../../shared/buttons/success';

const PhaseRow = React.memo(({ index, phase, onFieldChange, fields }) => {
    const handleIntervalChange = useCallback(
        interval => {
            if (interval === 'NONE') {
                onFieldChange({ [`${phase}.repeatTimes`]: 0 });
            }
        },
        [onFieldChange, phase]
    );

    return (
        <div>
            <Row className={classnames('mb-3', { 'mt-4': 0 < index })}>
                <Col md={1}>
                    <ColorPicker
                        color={fields.get(index).color}
                        onColorChange={color => onFieldChange({ [`${phase}.color`]: color.hex })}
                    />
                </Col>
                <Col md={5}>
                    <Field
                        name={`${phase}.name`}
                        type="text"
                        label="Phase Title"
                        placeholder="Phase Title"
                        component={materialInputGroupField}
                    />
                </Col>
                <Col md={6}>
                    <Field className="pb-4" name={`${phase}.start`} label="Start date" component={dateField} />
                    <Field name={`${phase}.end`} label="End Date" component={dateField} />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <Repeat
                        removeBorder={true}
                        wrapperClass="px-0 my-0"
                        title="Phase Repeat:"
                        inputFieldDisabled={'NONE' === fields.get(index).interval}
                        radioInputName={`${phase}.interval`}
                        textInputName={`${phase}.repeatTimes`}
                        onIntervalChange={handleIntervalChange}
                    />
                </Col>
            </Row>
            <Row className={classnames({ 'pb-4 b-bottom': fields.length - 1 !== index })}>
                <Col md={12}>
                    <SuccessButton
                        onClick={() => fields.remove(index)}
                        name="Remove phase"
                        dataCy="button--remove-phase"
                        type="btn-cancel"
                        className="float-right"
                    />
                </Col>
            </Row>
        </div>
    );
});

const renderPhases = ({ fields, onFieldChange }) => {
    return (
        <>
            {fields.map((phase, index) => {
                return (
                    <PhaseRow key={index} phase={phase} fields={fields} index={index} onFieldChange={onFieldChange} />
                );
            })}
            <SuccessButton
                onClick={() =>
                    fields.push({
                        interval: 'NONE',
                        repeatTimes: 0,
                        start: new Date(),
                        end: new Date(),
                        name: '',
                        color: '#349E71',
                    })
                }
                name="Add a phase"
                dataCy="button--add-phase"
                type="btn-primary"
            />
        </>
    );
};

const PhasesTab = ({ tabId, onFieldChange }) => {
    const { hasSettingPhase } = useHasRights([
        {
            rights: ['settingProjectPhases'],
            rule: 'one',
            name: 'hasSettingPhase',
        },
    ]);

    return (
        <TabPane tabId={tabId}>
            {hasSettingPhase ? (
                <section className="form-fields">
                    <p className="title">Phases:</p>
                    <FieldArray name="phases" component={renderPhases} onFieldChange={onFieldChange} />
                </section>
            ) : (
                <PermissionDenied />
            )}
        </TabPane>
    );
};

PhasesTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onFieldChange: PropTypes.func.isRequired,
};

export default PhasesTab;
