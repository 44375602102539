import { useSelector } from 'react-redux';
import { selectUseCategoriesAllocation } from '../../selectors/company';
import { useCallback } from 'react';
import getAllocationValuesChanges from './getAllocationValuesChanges';
import { getAllocationValuesFromCategory } from './getAllocationValuesFromCategory';

export const calculateAllocationForCategory = ({ percentage, hours, total, category, useCategoriesAllocation }) => {

    if (useCategoriesAllocation && category) {
        return {
            ...getAllocationValuesChanges(getAllocationValuesFromCategory(category), { percentage, hours, total }),
            state: category.state,
        };
    }

    return {};
};

export const useCalculateAllocationForCategory = () => {
    const useCategoriesAllocation = useSelector(selectUseCategoriesAllocation);

    return useCallback(
        ({ percentage, hours, total, category }) => {
            return calculateAllocationForCategory({
                percentage,
                hours,
                total,
                category,
                useCategoriesAllocation,
            });
        },
        [useCategoriesAllocation]
    );
};
