import { matchPath } from 'react-router-dom';
import moize from 'moize';
import { flow, map, flatten, filter } from 'lodash/fp';
import { routes } from './../Routes';

export const params = moize(
    (routes, path) =>
        matchPath(path, {
            path: routes,
            exact: true,
        }),
    { maxSize: 2 }
);

export const childRoutePaths = flow(
    filter(route => route.hasOwnProperty('child')),
    map(route => route.child),
    flatten,
    map(route => route.path)
)(routes);

export const routePaths = map('path', routes);
