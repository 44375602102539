import React from 'react';
import { useHubSpotChat } from 'hooks';

const HubSpotChat = React.memo(() => {
    useHubSpotChat();
    return (
        <></>
    );
});

export default HubSpotChat;
