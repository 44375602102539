import React, { memo } from 'react';

export const FixedCostCategoryTemplateItem = memo(props => {
    const { item } = props;

    if (!item?.name) {
        return null;
    }

    return (
        <div className="d-flex align-items-center">
            <span className="text-truncate">{item.name}</span>
        </div>
    );
});
