import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { filter, map, flow } from 'lodash/fp';
import { GetApp, Info } from '@material-ui/icons';
import { DropdownToggle } from 'reactstrap';
import { getBookings } from 'api/booking';
import {
    filterNotValidResources, getDpRowsIdsToLoad,
    getResourceIdsByDPResource,
    getViewObject
} from 'modules/scheduler/utils/schedulerUtil';
import {
    formatBookingsToDP,
    formatMilestonesToDP,
    formatPhasesToDP,
    formatStartEndTimesToDP,
} from 'modules/scheduler/utils/eventUtil';
import { DropdownMenu, DropdownButton, SubMenu } from 'shared/dropdownMenu';
import { Dropdown } from '../styles';
import {LEGACY, PARENT, SINGLE} from 'modules/scheduler/enums/viewModeEnum';
import { hasOneOfRoles, hasRole } from '../../../../../utils/rightsUtil';
import { CELLDURATION } from '../../../enums/scale';
import { getMilestonesAndPhases, getProjectDates } from '../../../../../api/project';
import schedulerUpdateObserver from '../../../utils/schedulerUpdateObserver';
import { replaceProjectsColorWithStatusColor } from 'shared/lib/projects';
import { selectSchedulerMode } from '../../../../../selectors/scheduler';
import {store} from '../../../../../store';
import {APPROVED, SCHEDULED, WAITING_FOR_APPROVAL} from '../../../../../enums/bookingTypeEnum';

const loadBookingsAndUpdate = async ({
    params,
    mode,
    schedulerControl,
    canViewMilestonesOrPhases,
    canViewProjectDates,
    onAfterRender,
}) => {
    const state = store.getState();
    const schedulerState = state.scheduler;
    const schedulerFilters = schedulerState?.filters ?? {};
    const {bookingStatuses,
        bookingCategories,
        bookingProjectsEvents,
        bookingResourcesUW,
        bookingProjectStatuses,} = state.scheduler.filters;
    const bookingsProjectEventsIds = schedulerFilters?.bookingProjectsEvents?.filters || [];
    const bookingsResourcesUWIds = schedulerFilters?.bookingResourcesUW?.filters || [];

    const viewObject = getViewObject(params);
    const { scale } = schedulerControl;

    const shouldLoadMilestonesPhasesStartEndTimes =
        (viewObject.isSingleProjectView || viewObject.isProjectGroupView) &&
        (SINGLE.value === mode || PARENT.value === mode);

    const resources = flow(
        filter(
            resource => !resource.tags.menuRow && !resource.tags.unassignedParentRow && -1 === resource.id.indexOf('_')
        ),
        map(resource => resource.id)
    )(schedulerControl.resources);

    const startDate = schedulerControl.getViewPort().start.toString('yyyy-MM-d');
    const endDate = schedulerControl.getViewPort().end.toString('yyyy-MM-d');
    const currentRowIds = filterNotValidResources(getDpRowsIdsToLoad(window.schedulerRef.current?.control, 0, true), mode);

    const { resourceIds, projectIds } = getResourceIdsByDPResource(
        window.schedulerRef,
        currentRowIds,
        viewObject,
        mode,
        {
            groupId: params.resourceGroupId ?? params.projectGroupId,
            entityId: params.resourceId ?? params.projectId,
            bookingCategories,
            bookingStatuses,
            bookingProjectsEvents,
            bookingResourcesUW,
            bookingProjectStatuses,
            schedulerViewProjectIds:
                viewObject.isSingleProjectView && params.projectId && mode === LEGACY.value
                    ? [params.projectId]
                    : undefined,
        }
    );

    const resourceIdsToAsk = (() => {
        const temp = bookingsResourcesUWIds?.length ? bookingsResourcesUWIds : resourceIds;

        if (temp?.length) {
            return temp;
        }

        return undefined;
    })();

    const projectIdsToAsk = (() => {
        if (!viewObject?.isProjectView) {
            return bookingsProjectEventsIds?.length ? bookingsProjectEventsIds : undefined;
        }

        const temp = bookingsProjectEventsIds?.length ? bookingsProjectEventsIds : projectIds;

        if (temp?.length) {
            return temp;
        }

        return undefined;
    })();

    const requests = [
        getBookings({
            startDate,
            endDate,
            resourceIds: resourceIdsToAsk,
            projectIds: projectIdsToAsk,
            type: bookingStatuses?.filters?.length
                ? bookingStatuses.filters
                : [APPROVED.value, WAITING_FOR_APPROVAL.value, SCHEDULED.value],
            categoryIds: bookingCategories?.filters?.length
                ? bookingCategories.filters
                : undefined,
            schedulerViewProjectIds: viewObject.isSingleProjectView && params.projectId && mode === LEGACY.value
                ? [params.projectId]
                : undefined,
            projectStatuses: [schedulerFilters.bookingProjectStatuses],
        }),
    ];

    if (shouldLoadMilestonesPhasesStartEndTimes && canViewMilestonesOrPhases && scale !== CELLDURATION.value) {
        requests.push(
            getMilestonesAndPhases({
                projectIds: resources,
            })
        );
    } else {
        requests.push([]);
    }

    if (shouldLoadMilestonesPhasesStartEndTimes && canViewProjectDates && scale !== CELLDURATION.value) {
        requests.push(
            getProjectDates({
                startDate,
                endDate,
                projectIds: resources,
            })
        );
    } else {
        requests.push([]);
    }

    let [bookings, milestonesAndPhases, projectDates] = await Promise.all(requests);
    bookings = bookings.map(booking => {
        if (booking?.project?.useStatusColor) {
            return {
                ...booking,
                project: replaceProjectsColorWithStatusColor(booking.project),
            };
        }
        return booking;
    });
    const { milestones, phases } = milestonesAndPhases || {};
    const events = formatBookingsToDP(bookings, mode, viewObject);
    const formattedMilestones = milestones ? formatMilestonesToDP(milestones) : [];
    const formattedPhases = phases ? formatPhasesToDP(phases) : [];
    const formattedStartEndTimes = projectDates ? formatStartEndTimesToDP(projectDates) : [];

    const allEvents = events
        .concat(formattedMilestones)
        .concat(formattedPhases)
        .concat(formattedStartEndTimes);

    schedulerUpdateObserver.addObserver(() => {
        onAfterRender(schedulerControl);
    });

    schedulerControl.update({
        events: allEvents,
    });
};

const print = () => schedulerControl => {
    schedulerControl
        .exportAs({
            area: 'range',
            dateFrom: schedulerControl.getViewPort().start,
            dateTo: schedulerControl.getViewPort().end,
            scale: 1,
            quality: 1,
        })
        .print();
};

const downloadAsImage = fileType => schedulerControl => {
    schedulerControl
        .exportAs(fileType, {
            area: 'range',
            dateFrom: schedulerControl.getViewPort().start,
            dateTo: schedulerControl.getViewPort().end,
            scale: 1,
            quality: 1,
        })
        .download(
            `Hub Planner ${schedulerControl
                .getViewPort()
                .start.toString('MMMM d yyyy')} - ${schedulerControl.getViewPort().end.toString('MMMM d yyyy')}`
        );
};

const Export = ({ schedulerRef }) => {
    const account = useSelector(state => state.account);
    const params = useParams();
    const mode = useSelector(selectSchedulerMode);
    const canViewMilestonesOrPhases = hasOneOfRoles(account.resourceRoleRights, ['viewMilestones', 'viewPhases']);
    const canViewProjectDates = hasRole(account.resourceRoleRights, 'viewStartEndDates');

    const onPrint = useCallback(async () => {
        try {
            await loadBookingsAndUpdate({
                params,
                mode,
                schedulerControl: schedulerRef.current.control,
                canViewMilestonesOrPhases,
                canViewProjectDates,
                onAfterRender: print(),
            });
        } catch (e) {
            console.log('print error', e);
        }
    }, [params, mode, schedulerRef, canViewMilestonesOrPhases, canViewProjectDates]);

    const onDownloadAsImage = useCallback(
        async event => {
            try {
                const fileType = event.currentTarget.dataset.type;

                await loadBookingsAndUpdate({
                    params,
                    mode,
                    schedulerControl: schedulerRef.current.control,
                    canViewMilestonesOrPhases,
                    canViewProjectDates,
                    onAfterRender: downloadAsImage(fileType),
                });
            } catch (e) {
                console.log('download image error', e);
            }
        },
        [params, mode, schedulerRef, canViewMilestonesOrPhases, canViewProjectDates]
    );

    return (
        <Dropdown className="py-2 px-3" data-cy="scheduler-header-print">
            <DropdownToggle nav title="Print / Export">
                <GetApp />
            </DropdownToggle>
            <DropdownMenu className="rounded-0">
                <DropdownButton onClick={onPrint}>Print</DropdownButton>
                <Dropdown border="false" direction="left">
                    <SubMenu caret nav>
                        Download Image
                    </SubMenu>
                    <DropdownMenu className="rounded-0" width={300}>
                        <DropdownButton data-type="png" onClick={onDownloadAsImage}>
                            Download PNG
                        </DropdownButton>
                        <DropdownButton data-type="jpeg" onClick={onDownloadAsImage}>
                            Download JPEG
                        </DropdownButton>
                        <div className="mx-3 my-1">
                            <span>
                                <Info /> <strong>JPEG</strong> is compressed so it may not be the optimal export format.{' '}
                            </span>
                            <span className="mt-2 d-block">
                                The same image exported as <strong>PNG</strong> usually has about 50% smaller size and
                                better image quality than JPEG.
                            </span>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </DropdownMenu>
        </Dropdown>
    );
};

Export.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
};

export default Export;
