import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { MoreVert } from '@material-ui/icons';
import { TextWrapper, ItemContextMenu } from './styles';
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders';

const ItemPlaceholder = () => (
    <div className="position-relative">
        <Link to={'#'} className={classNames('d-flex pl-3')} style={{ alignItems: 'center', height: '69px' }}>
            <RectShape
                className="project-image-placeholder"
                color="#5a5a5a"
                style={{ width: '45px', height: '45px' }}
            />
            <TextWrapper className="float-left">
                <span className="d-block">
                    <TextBlock
                        rows={1}
                        color="#5a5a5a"
                        style={{ height: '.6em', marginBottom: '8px', borderRadius: '3px' }}
                    />
                </span>
                <TextBlock rows={1} color="#5a5a5a" style={{ height: '.6em', borderRadius: '3px' }} />
            </TextWrapper>
        </Link>
        <ItemContextMenu>
            <MoreVert />
        </ItemContextMenu>
    </div>
);

export default ItemPlaceholder;
