import React, { createContext, useState } from 'react';

const AvailabilityContext = createContext({
    customAvailabilitiesOverrides: [],
    setCustomAvailabilitiesOverrides: customAvailabilitiesOverrides => [...customAvailabilitiesOverrides],
    isApiDataLoaded: false,
    setIsApiDataLoaded: isLoaded => isLoaded,
});

const AvailabilityProvider = ({ children }) => {
    const [customAvailabilitiesOverrides, setCustomAvailabilitiesOverrides] = useState([]);
    const [isApiDataLoaded, setIsApiDataLoaded] = useState(false);
    return (
        <AvailabilityContext.Provider
            value={{
                customAvailabilitiesOverrides,
                setCustomAvailabilitiesOverrides,
                isApiDataLoaded,
                setIsApiDataLoaded,
            }}
        >
            {children}
        </AvailabilityContext.Provider>
    );
};

export { AvailabilityProvider, AvailabilityContext };
