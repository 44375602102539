import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const getBookings = {
    request: (filters, resolve) => action(actionTypes.GET_BOOKINGS['REQUEST'], { filters, resolve }),
    success: bookings => action(actionTypes.GET_BOOKINGS['SUCCESS'], { bookings }),
};

export const getBooking = {
    request: bookingId => action(actionTypes.GET_BOOKING['REQUEST'], { bookingId }),
    success: booking => action(actionTypes.GET_BOOKING['SUCCESS'], { booking }),
};

export const deleteBooking = {
    request: (bookingId, refreshStats, callback) =>
        action(actionTypes.DELETE_BOOKING['REQUEST'], { bookingId, refreshStats, callback }),
    success: bookingId => action(actionTypes.DELETE_BOOKING['SUCCESS'], { bookingId }),
    failure: bookingId => action(actionTypes.DELETE_BOOKING['FAILURE'], { bookingId }),
};

export const deleteBookings = {
    request: (bookingIds, refreshStats, callback) =>
        action(actionTypes.DELETE_BOOKINGS['REQUEST'], { bookingIds, refreshStats, callback }),
    success: bookingIds => action(actionTypes.DELETE_BOOKINGS['SUCCESS'], { bookingIds }),
    failure: bookingIds => action(actionTypes.DELETE_BOOKINGS['FAILURE'], { bookingIds }),
};

export const deleteAllRepeatBooking = {
    request: (repeatId, refreshStats, callback) =>
        action(actionTypes.DELETE_ALL_REPEAT_BOOKINGS['REQUEST'], { repeatId, refreshStats, callback }),
    success: bookingId => action(actionTypes.DELETE_ALL_REPEAT_BOOKINGS['SUCCESS'], { bookingId }),
    failure: bookingId => action(actionTypes.DELETE_ALL_REPEAT_BOOKINGS['FAILURE'], { bookingId }),
};

export const deleteFutureRepeatBooking = {
    request: (repeatId, date, refreshStats, callback) =>
        action(actionTypes.DELETE_FUTURE_REPEAT_BOOKINGS['REQUEST'], { repeatId, date, refreshStats, callback }),
    success: bookingId => action(actionTypes.DELETE_FUTURE_REPEAT_BOOKINGS['SUCCESS'], { bookingId }),
    failure: bookingId => action(actionTypes.DELETE_FUTURE_REPEAT_BOOKINGS['FAILURE'], { bookingId }),
};

export const rejectBooking = {
    success: bookingId => action(actionTypes.DELETE_BOOKING['SUCCESS'], { bookingId }),
};

export const createBookings = {
    request: (bookings, tasks, callback, projectName, options) =>
        action(actionTypes.CREATE_BOOKING['REQUEST'], { bookings, tasks, projectName, callback, options }),
    success: bookings => action(actionTypes.CREATE_BOOKING['SUCCESS'], { bookings }),
};

export const cutBooking = {
    request: (bookings, tasks, callback, projectName, options) =>
        action(actionTypes.CUT_BOOKING['REQUEST'], { bookings, tasks, callback, projectName, options }),
};

export const bulkCreateBookings = {
    request: dto => action(actionTypes.BULK_CREATE_BOOKINGS['REQUEST'], dto),
};

export const updateBooking = {
    request: (bookingId, booking, options, callback, onFailure) =>
        action(actionTypes.UPDATE_BOOKING['REQUEST'], { bookingId, booking, options, callback, onFailure }),
    success: bookings => action(actionTypes.UPDATE_BOOKING['SUCCESS'], { bookings }),
};
export const duplicateBooking = {
    request: (bookingId, cb) => action(actionTypes.DUPLICATE_BOOKING['REQUEST'], { bookingId, cb }),
    success: bookings => action(actionTypes.DUPLICATE_BOOKING['SUCCESS'], { bookings }),
};

export const updateMultipleBookings = {
    request: (bookings, options, callback, onFailure) =>
        action(actionTypes.UPDATE_BOOKINGS['REQUEST'], { bookings, callback, options, onFailure }),
    success: bookings => action(actionTypes.UPDATE_BOOKINGS['SUCCESS'], { bookings }),
};

export const splitBooking = {
    request: ({ bookingId, splitDate }) => action(actionTypes.SPLIT_BOOKING['REQUEST'], { bookingId, splitDate }),
    success: ({ bookingId }) => action(actionTypes.SPLIT_BOOKING['SUCCESS'], { bookingId }),
};

export const createResourceOrVacationRequest = (bookings, callback) => ({
    type: actionTypes.CREATE_VACATION_RESOURCE_REQUEST,
    payload: {
        bookings,
        callback,
    },
});

export const resetData = prop => ({
    type: actionTypes.RESET_BOOKING_DATA,
    payload: {
        prop,
    },
});

export const bulkMoveBookings = {
    request: ({ bulkAction, values, resourceId, projectId }) =>
        action(actionTypes.BULK_MOVE_BOOKINGS['REQUEST'], { bulkAction, values, resourceId, projectId }),
    success: bookings => action(actionTypes.BULK_MOVE_BOOKINGS['SUCCESS'], { bookings }),
};

export const createDeadline = {
    request: (booking, options = {}, callback) =>
        action(actionTypes.CREATE_BOOKING_DEADLINE['REQUEST'], {
            booking,
            options,
            callback,
        }),
    success: (bookings, options) => action(actionTypes.CREATE_BOOKING_DEADLINE['SUCCESS'], { bookings, options }),
    failure: () => action(actionTypes.CREATE_BOOKING_DEADLINE['FAILURE']),
};

export const updateDeadline = {
    request: (booking, options = {}, callback, onFailure) =>
        action(actionTypes.UPDATE_BOOKING_DEADLINE['REQUEST'], {
            booking,
            options,
            callback,
            onFailure,
        }),
    success: ({ bookings }) => action(actionTypes.UPDATE_BOOKING_DEADLINE['SUCCESS'], { bookings }),
    failure: ({ bookings }) => action(actionTypes.UPDATE_BOOKING_DEADLINE['FAILURE'], { bookings }),
};

export const updateDeadlines = {
    request: ({ bookings }) => action(actionTypes.UPDATE_BOOKING_DEADLINES['REQUEST'], { bookings }),
    success: ({ bookings }) => action(actionTypes.UPDATE_BOOKING_DEADLINES['SUCCESS'], { bookings }),
    failure: () => action(actionTypes.UPDATE_BOOKING_DEADLINES['FAILURE']),
};

export const deleteDeadline = {
    request: (booking, options = {}, callback) =>
        action(actionTypes.DELETE_BOOKING_DEADLINE['REQUEST'], {
            booking,
            options: options,
            callback,
        }),
    success: (booking, options = {}) =>
        action(actionTypes.DELETE_BOOKING_DEADLINE['SUCCESS'], {
            booking,
            options: options,
        }),
    failure: (booking, options = {}) =>
        action(actionTypes.DELETE_BOOKING_DEADLINE['FAILURE'], {
            booking,
            options: options,
        }),
};
