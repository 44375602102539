import BaseMenu from 'modules/sidebar/services/menuBuilder/BaseMenu';
import RequestItems from 'modules/request/menu/reuqestItems';
import moize from 'moize';
import { each, unique, sortBy } from 'underscore';
import { hasOneOfRoles } from 'utils/rightsUtil';
import { getRequestUrls } from 'modules/scheduler/utils/getRequestUrls';
import React from 'react';

const RequestItem = React.memo(props => {
    const { to, label } = props;

    return (
        <div style={{ position: 'relative', paddingLeft: 20 }}>
            <a href={`#${to}`} className="metismenu-link">
                {label}
            </a>
        </div>
    );
});

/**
 * Each Menu Class need to have a items() function
 */
class RequestsMenu extends BaseMenu {
    setProps(account, companyTree, approvees, keyWords) {
        this.account = account;
        this.companyTree = companyTree;
        this.approvees = approvees || [];
        this.keyWords = keyWords || {};

        return this;
    }

    getVacationResources() {
        if (
            hasOneOfRoles(this.account.resourceRoleRights, ['manageAllVacations', 'canEditRequestVacation']) &&
            this.account.isApprover
        ) {
            const resources = this.companyTree.getActiveResources().concat(this.approvees);
            return unique(
                sortBy(resources, resource => resource.firstName + resource.lastName),
                true,
                resource => resource._id
            );
        }
        if (hasOneOfRoles(this.account.resourceRoleRights, ['manageAllVacations', 'canEditRequestVacation'])) {
            return this.companyTree.getActiveResources();
        }
        return this.account.isApprover
            ? sortBy(this.approvees, resource => resource.firstName + resource.lastName)
            : [];
    }

    getRequestResources() {
        if (
            hasOneOfRoles(this.account.resourceRoleRights, ['manageAllResourceRequests', 'canEditRequestResource']) &&
            this.account.isApprover
        ) {
            const resources = this.companyTree.getActiveResources().concat(this.approvees);
            return unique(
                sortBy(resources, resource => resource.firstName + resource.lastName),
                true,
                resource => resource._id
            );
        }
        if (hasOneOfRoles(this.account.resourceRoleRights, ['manageAllResourceRequests', 'canEditRequestResource'])) {
            return this.companyTree.getActiveResources();
        }
        return this.account.isApprover
            ? sortBy(this.approvees, resource => resource.firstName + resource.lastName)
            : [];
    }

    vacationTeam() {
        let vacationResources = [];
        let resources = this.getVacationResources();

        each(resources, resource => {
            const { vacationUrl } = getRequestUrls(resource._id);
            vacationResources.push({
                customItem: RequestItem,
                label: `${resource.firstName} ${resource.lastName}`,
                icon: 'empty',
                to: vacationUrl,
                textHeader: `${resource.firstName} ${resource.lastName} Requests`,
            });
        });

        return vacationResources;
    }

    requestTeam() {
        let vacationResources = [];
        let resources = this.getRequestResources();

        each(resources, resource => {
            const { requestUrl } = getRequestUrls(resource._id);
            vacationResources.push({
                customItem: RequestItem,
                label: `${resource.firstName} ${resource.lastName}`,
                icon: 'empty',
                to: requestUrl,
                textHeader: `${resource.firstName} ${resource.lastName} Requests`,
            });
        });

        return vacationResources;
    }

    items() {
        const vacationTeam = moize(this.vacationTeam.bind(this), { maxSize: 2 });
        const requestTeam = moize(this.requestTeam.bind(this), { maxSize: 2 });

        return [
            {
                customItem: RequestItems,
            },
            {
                label: `My ${this.keyWords.vacationEventName}`,
                display: true,
                flow: 'MY_VACATION_REQUESTS',
                content: [
                    {
                        dataCy: 'sidebar-myVacation-dashboard',
                        icon: 'bar-chart',
                        label: 'Dashboard',
                        to: '/requests/my-vacations?subFlow=DASHBOARD&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-myVacation-requestTable',
                        icon: 'table',
                        label: 'Request Table',
                        to: '/requests/my-vacations?subFlow=TABLE&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-myVacation-pending',
                        label: 'Requested',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod waiting-for-approval-pod',
                        to: '/requests/my-vacations?filter=WAITING_FOR_APPROVAL',
                    },
                    {
                        dataCy: 'sidebar-myVacation-approved',
                        label: 'Consumed',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod approved-pod',
                        to: '/requests/my-vacations?filter=APPROVED',
                    },
                    {
                        dataCy: 'sidebar-myVacation-reject',
                        label: 'Rejected',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod rejected-pod',
                        to: '/requests/my-vacations?filter=REJECTED',
                    },
                ],
            },
            {
                label: `${this.keyWords.vacationEventName} Request`,
                flow: 'VACATION_FLOW',
                display:
                    hasOneOfRoles(this.account.resourceRoleRights, ['manageAllVacations', 'canEditRequestVacation']) ||
                    this.account.isApprover,
                content: [
                    {
                        dataCy: 'sidebar-vacationRequests-dashboard',
                        icon: 'bar-chart',
                        label: 'Dashboard',
                        to: '/requests/vacation-requests?subFlow=DASHBOARD&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-requestTable',
                        icon: 'table',
                        label: 'Request Table',
                        to: '/requests/vacation-requests?subFlow=TABLE&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-pending',
                        label: 'Requested',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod waiting-for-approval-pod',
                        to: '/requests/vacation-requests?filter=WAITING_FOR_APPROVAL',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-approved',
                        label: 'Consumed',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod approved-pod',
                        to: '/requests/vacation-requests?filter=APPROVED',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-rejected',
                        label: 'Rejected',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod rejected-pod',
                        to: '/requests/vacation-requests?filter=REJECTED',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-allResources',
                        icon: 'users',
                        label: `All ${this.keyWords.resourcePluralKeyWord}`,
                        flow: 'VACATION_GRID',
                        to: '/requests/vacation-requests?flow=VACATION_GRID',
                    },
                    {
                        dataCy: 'sidebar-vacationRequests-team',
                        label: 'Team',
                        icon: 'empty',
                        flow: 'VACATION_FLOW_SINGLE',
                        search: true,
                        searchVisible: true,
                        content: vacationTeam(),
                    },
                ],
            },
            {
                label: `My ${this.keyWords.resourceKeyWord} Requests`,
                display: true,
                flow: 'MY_RESOURCE_REQUESTS',
                content: [
                    {
                        dataCy: 'sidebar-myResourceRequests-dashboard',
                        icon: 'bar-chart',
                        label: 'Dashboard',
                        to: '/requests/my-resource-requests?subFlow=DASHBOARD&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-myResourceRequests-requestTable',
                        icon: 'table',
                        label: 'Request Table',
                        to: '/requests/my-resource-requests?subFlow=TABLE&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-myResourceRequests-pending',
                        label: 'Requested',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod waiting-for-approval-pod',
                        to: '/requests/my-resource-requests?filter=WAITING_FOR_APPROVAL',
                    },
                    {
                        dataCy: 'sidebar-myResourceRequests-approved',
                        label: 'Consumed',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod approved-pod',
                        to: '/requests/my-resource-requests?filter=APPROVED',
                    },
                    {
                        dataCy: 'sidebar-myResourceRequests-rejected',
                        label: 'Rejected',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod rejected-pod',
                        to: '/requests/my-resource-requests?filter=REJECTED',
                    },
                ],
            },
            {
                label: `Team ${this.keyWords.resourceKeyWord} Request`,
                display:
                    hasOneOfRoles(this.account.resourceRoleRights, [
                        'manageAllResourceRequests',
                        'canEditRequestResource',
                    ]) || this.account.isApprover,
                flow: 'APPROVAL_FLOW',
                content: [
                    {
                        dataCy: 'sidebar-teamResourceRequests-dashboard',
                        icon: 'bar-chart',
                        label: 'Dashboard',
                        to: '/requests/team-resource-requests?subFlow=DASHBOARD&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-requestTable',
                        icon: 'table',
                        label: 'Request Table',
                        to: '/requests/team-resource-requests?subFlow=TABLE&filter=ALL',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-pending',
                        label: 'Requested',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod waiting-for-approval-pod',
                        to: '/requests/team-resource-requests?filter=WAITING_FOR_APPROVAL',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-approved',
                        label: 'Consumed',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod approved-pod',
                        to: '/requests/team-resource-requests?filter=APPROVED',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-rejected',
                        label: 'Rejected',
                        customIcon: true,
                        customIconContent: 0,
                        customIconClass: 'sidebar-pod rejected-pod',
                        to: '/requests/team-resource-requests?filter=REJECTED',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-allResources',
                        icon: 'users',
                        label: `All ${this.keyWords.resourcePluralKeyWord}`,
                        flow: 'REQUEST_GRID',
                        to: '/requests/team-resource-requests?flow=REQUEST_GRID',
                    },
                    {
                        dataCy: 'sidebar-teamResourceRequests-team',
                        label: 'Team',
                        icon: 'empty',
                        search: true,
                        searchVisible: true,
                        flow: 'RES_REQUEST_FLOW_SINGLE',
                        content: requestTeam(),
                    },
                ],
            },
        ];
    }
}

export default new RequestsMenu();
