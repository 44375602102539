/* eslint-disable no-unmodified-loop-condition */
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';
import moment from 'moment';
import {
    format,
    addMinutes,
    isEqual as isEqualFns,
    isAfter as isAfterFns,
    isBefore as isBeforeFns,
    addDays as addDaysFns,
    differenceInDays as differenceInDaysFns,
    startOfYear as startOfYearFns,
    endOfYear as endOfYearFns,
    startOfDay as startOfDayFns,
    endOfDay as endOfDayFns,
} from 'date-fns';
import { each } from 'underscore';
import { BOOKING_FORMAT } from 'global/enums/dateFormat';
import { DayPilot } from 'daypilot-pro-react';

export function getMonthsObject(startDate, endDate, format) {
    let labels = [];

    while (endDate > startDate || startDate.format('M') === endDate.format('M')) {
        labels.push({
            shortFormat: startDate.format(format),
            uniqueFormat: startDate.format('YYYY-MM'),
            value: 0,
        });
        startDate.add(1, 'month');
    }

    return labels;
}

export function getWeekNumbersObject(startDate, endDate, weekType) {
    let labels = [];
    startDate = startDate.utc();
    endDate = endDate.utc();

    while (endDate > startDate || startDate.format(weekType) === endDate.format(weekType)) {
        labels.push({
            shortFormat: startDate.week(),
            uniqueFormat:
                11 === startDate.month() && 1 === startDate.week()
                    ? startDate
                          .clone()
                          .add(1, 'y')
                          .format('YYYY') + startDate.format(`-${weekType}`)
                    : startDate.format(`YYYY-${weekType}`),
            value: 0,
        });
        startDate.add(1, 'week');
    }

    return labels;
}

/**
 * @param {array} dateRanges //start, end
 *
 * @returns {object}
 */
export const overlap = dateRanges => {
    let sortedRanges = dateRanges.sort((previous, current) => {
        let previousTime = previous.start ? previous.start.getTime() : 0;
        let currentTime = current.start ? current.start.getTime() : 0;

        if (previousTime < currentTime) {
            return -1;
        }

        if (previousTime === currentTime) {
            return 0;
        }

        return 1;
    });

    return sortedRanges.reduce(
        (result, current, idx, arr) => {
            if (0 === idx) {
                return result;
            }
            let previous = arr[idx - 1];

            let previousEnd = previous.end ? previous.end.getTime() : 0;
            let currentStart = current.start ? current.start.getTime() : 0;
            let overlap = !previousEnd || previousEnd >= currentStart;

            if (overlap) {
                result.overlap = true;
            }

            return result;
        },
        { overlap: false }
    );
};

/**
 * Returns array of dates within range
 *
 * @param {moment} startDate
 * @param {moment} endDate
 * @param {string} dateSplit
 * @param {string} dateFormat
 *
 * @returns {array}
 */
export const getDateRange = (startDate, endDate, dateSplit, dateFormat) => {
    let dates = [],
        start = startDate.clone(),
        diff =
            endDate.diff(
                'weeks' === dateSplit ? startDate.startOf('isoWeek') : startDate.startOf(dateSplit.slice(0, -1)),
                dateSplit
            ) + 1;

    if ('years' === dateSplit) {
        diff = endDate.endOf('year').diff(startDate.clone().startOf('year'), dateSplit) + 1;
    }

    if (!startDate.isValid() || !endDate.isValid() || 0 >= diff) {
        return [];
    }

    let updatedDate = start;
    for (let i = 0; i < diff; i++) {
        if ('years' !== dateSplit || ('years' === dateSplit && 0 < i)) {
            if (0 === i) {
                dates.push(updatedDate.format(dateFormat));
                continue;
            }

            if ('weeks' === dateSplit) {
                updatedDate = start
                    .clone()
                    .startOf('isoWeek')
                    .add(i, dateSplit);
            } else {
                updatedDate = start.clone().add(i, dateSplit);
            }
            dates.push(updatedDate.format(dateFormat));
        } else {
            dates.push(start.format(dateFormat));
        }
    }

    return dates;
};

/**
 * @param {moment.Moment} startDate
 * @param {moment.Moment} endDate
 * @param {string}        weekType
 *
 * @returns {string}
 */
export const getPeriodType = (startDate, endDate, weekType = 'isoWeek') => {
    let periodType = 'custom';

    each(['week', 'month', 'quarter', 'year'], period => {
        if (
            startDate
                .clone()
                .startOf('week' === period ? weekType : period)
                .dayOfYear() === startDate.dayOfYear() &&
            endDate.dayOfYear() ===
                endDate
                    .clone()
                    .endOf('week' === period ? weekType : period)
                    .dayOfYear()
        ) {
            periodType = 'week' === period ? weekType : period;
        }
    });

    return periodType;
};

/**
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {object} maxRange
 *
 * @returns {bool}
 */
export const isAboveMaxRange = (startDate, endDate, maxRange) =>
    maxRange.value <= moment(endDate).diff(moment(startDate), maxRange.unit);

const getData = (startDate, endDate) => {
    const diff = endDate.diff(startDate, 'd') + 1;
    const periodType = getPeriodType(startDate, endDate);
    const today = moment().startOf(periodType);
    const diffByPeriod = startDate.diff(today, 'isoWeek' === periodType ? 'week' : periodType);

    return {
        diff,
        diffByPeriod,
        periodType,
    };
};

export const getDateStateBasedOnDates = (startDate, endDate) => {
    const data = getData(startDate, endDate);
    let dateState = DATE_STATE.THIS;
    let periodType = 'isoWeek' === data.periodType ? 'week' : data.periodType;

    if (0 > data.diffByPeriod && -2 < data.diffByPeriod) {
        dateState = DATE_STATE.LAST;
    } else if (1 === data.diffByPeriod) {
        dateState = DATE_STATE.NEXT;
    } else if (2 <= data.diffByPeriod || -2 >= data.diffByPeriod) {
        dateState = '';
        periodType = 'custom';
    }

    return {
        dateState,
        periodType,
        data,
    };
};

export const getMonthFromString = monthName => new Date(Date.parse(monthName + ' 1, 2020')).getMonth();

export const formatDate = (date, dateFormat = BOOKING_FORMAT, utc = true) => {
    if (!(date && date instanceof Date)) {
        date = date ? new Date(date) : new Date();
    }

    return format(utc ? addMinutes(date, date.getTimezoneOffset()) : date, dateFormat || BOOKING_FORMAT);
};

export const toUtc = date => new Date(date.getTime() + date.getTimezoneOffset() * 60000);

export const getDateDuration = interval => {
    switch (interval) {
        case 'WEEKLY':
            return 'weeks';
        case 'MONTHLY':
            return 'months';
    }

    return 'days';
};

// transform to Date - string, Date or DayPilot.Date event
export const transformToDate = date => {
    if ('string' === typeof date) return new Date(date);
    // DayPilot.Date:
    if (date && date.value) {
        return new Date(date.value);
    }
    return date;
};

export const removeUTCZuluFromDateTimestamp = date => {
    if ('string' !== typeof date) return date;
    return date.replace('Z', '');
};

export const removeUTCZuluFromDateObject = date => {
    if (date instanceof Date) {
        return new Date(removeUTCZuluFromDateTimestamp(date.toISOString()));
    }

    return date;
};

// gets DayPilot.Date and returns it without timezone
export const getDayPilotDateWithoutTimezone = dayPilotDate =>
    new DayPilot.Date(removeUTCZuluFromDateTimestamp(dayPilotDate.toDateLocal().toISOString()));

export const toMidnight = date => {
    const d = new Date(date);
    return new Date(d.setHours(0, 0, 0, 0));
};

export const toNoon = date => {
    const d = new Date(date);
    return new Date(d.setHours(12, 0, 0, 0));
};

export const addDays = (date, noOfDays) => {
    return addDaysFns(new Date(date), noOfDays);
};

export const isSameDay = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);

    return isEqualFns(dateLeftToCheck, dateRightToCheck);
}

export const isAfter = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);

    return isAfterFns(dateLeftToCheck, dateRightToCheck);
};

export const isBefore = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);

    return isBeforeFns(dateLeftToCheck, dateRightToCheck);
};

export const isSameOrAfter = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);

    return isEqualFns(dateLeftToCheck, dateRightToCheck) || isAfterFns(dateLeftToCheck, dateRightToCheck);
};

export const isSameOrBefore = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);

    return isEqualFns(dateLeftToCheck, dateRightToCheck) || isBeforeFns(dateLeftToCheck, dateRightToCheck);
};

export const differenceInDays = (dateLeft, dateRight) => {
    const dateLeftToCheck = toMidnight(dateLeft);
    const dateRightToCheck = toMidnight(dateRight);
    return differenceInDaysFns(dateLeftToCheck, dateRightToCheck);
};

export const isDaypilotDate = dateLike => {
    return dateLike && dateLike.value && typeof dateLike.toDate === 'function';
}

export const startOfYear = dateLike => {
    return startOfYearFns(dateLike)
}

export const endOfYear = dateLike => {
    return endOfYearFns(dateLike)
}

export const startOfDay = dateLike => {
    return startOfDayFns(dateLike)
}

export const endOfDay = dateLike => {
    return endOfDayFns(dateLike)
}
