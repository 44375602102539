import Schema from 'form-schema-validation';
import { format } from 'date-fns';
import { errorMessages } from 'utils/schemaUtil';
import { startDateValidator } from 'utils/formValidators';

export const formSchema = new Schema(
    {
        start: {
            type: Date,
            required: true,
            validators: [startDateValidator('end')],
        },
        end: {
            type: Date,
            required: true,
        },
    },
    errorMessages,
    false
);

export const mapFormToRequest = values => ({
    project: {
        start: format(values.start, 'yyyy-MM-dd'),
        end: format(values.end, 'yyyy-MM-dd'),
        useProjectDuration: true,
    },
});
