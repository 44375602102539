/* eslint-env browser */
import * as actionTypes from 'actions/actionTypes';
import { call, put, takeLatest, select, all, fork, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
    authenticate,
    logout,
    createAccount,
    getLoggedInAccount,
    changeAccountCompany,
    updateReportSettings,
    updatePreferences,
    getMFASetupRequest,
    validateSetupMFARequest,
    updateNotificationsRequest,
    updateNotificationDetailsRequest,
    disableMFARequest,
    getMFARecoveryCodesRequest,
    generateMFARecoveryCodesRequest,
    resetPasswordForAccount,
    requestOTPByEmail,
    validateOTPRequest,
} from 'api/account';
import {
    getAccount,
    login,
    signUp,
    updateAccountPreferences,
    setupMFA,
    loadMFASetupData,
    validateOTPOnLogin,
    disableMFA,
    showMFARecoveryCodes,
    generateMFARecoveryCodes,
    showMFACompanyReminder,
    resetPassword,
    sendOTPCodeByEmail,
} from 'actions/accountActions';
import { addNotification } from 'actions/notificationActions';
import { getAccountId } from 'selectors/account';
import {
    hideModal,
    hideModalType,
    showCompanyOwnerMFAReminderModal,
    showMFARecoveryCodesModal,
    showOTPVerificationModal,
    showUserMFAReminderModal,
} from 'actions/modalActions';
import {
    handleErrors,
    otpValidationHandler,
    getDefaultErrorHandler,
    otpValidationOnLoginHandler,
    getDefaultNotificationErrorHandler,
    isOTPRequired,
} from './helpers/errorHandler';
import { OTP_VERIFICATION_MODAL } from 'enums/modalTypeEnum';
import { MFA_LAUNCH_DATE, mfaReminderTypes } from 'shared/constants/mfaReminders';
import { shouldDisplayReminder } from './helpers/mfa';
import { isBefore } from 'date-fns';
import { monitoring } from '../monitoring';

function* signUpSaga(action) {
    try {
        const response = yield call(createAccount, action.payload.data);

        if (response.success) {
            // get new created account data and save it in redux
            const { data: responseAccount } = yield call(getLoggedInAccount);
            yield put(signUp.success(responseAccount.account));

            // redirect to welcome page
            yield put(push('/welcome'));
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(signUp.failure(error.response && error.response.data && error.response.data.message));
    }
}

function* loginSaga(action) {
    try {
        const response = yield call(authenticate, action.payload.data);
        const isLaunchpad = response.data?.location?.includes('launchpad');
        yield put(validateOTPOnLogin(false));
        yield fork(getAccountSaga);
        const { payload } = yield take(actionTypes.GET_ACCOUNT['SUCCESS']);

        if (isLaunchpad) {
            yield put(push('/launchpad'));
            return;
        }

        if (
            !payload?.account?.userMFAEnabled &&
            payload?.account?.resourceRole === 'ROLE_OWNER' &&
            isBefore(new Date(payload?.account?.resourceCreatedDate), new Date(MFA_LAUNCH_DATE)) &&
            shouldDisplayReminder(payload?.account?.preferences?.notifications, mfaReminderTypes.MFA_COMPANY_REMINDER)
        ) {
            yield put(showMFACompanyReminder());
            yield take(actionTypes.POSTPONE_MFA_COMPANY_REMINDER);
        }

        window.location.replace(response.data?.location ?? '/');
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationOnLoginHandler,
            getDefaultErrorHandler(login, 'Authentication failed', error?.data?.code)
        );
    }
}

function* sendOTPByEmail(action) {
    try {
        yield call(requestOTPByEmail, action.payload);
        yield put(sendOTPCodeByEmail.success());
        yield put(
            addNotification({
                message: 'Code was requested, please check your email.',
                type: 'success',
            })
        );
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: error?.data?.message ?? 'Error requesting code by email',
                type: 'danger',
            })
        );
    }
}

function* setupMFASaga(action) {
    try {
        const mfa = yield call(validateSetupMFARequest, action.payload.code);
        yield put(setupMFA.success(mfa));
    } catch (error) {
        monitoring.captureException(error);
        yield put(setupMFA.failure(error?.data?.message ?? 'Multi-factor authentication setup failed.'));
    }
}

function* validateOTPSaga(action) {
    try {
        const response = yield call(validateOTPRequest, action.payload.code);
        if (response.valid === true) {
            const prevURL = sessionStorage.getItem('prevURL');
            if (prevURL?.includes('main#')) {
                sessionStorage.removeItem('prevURL');
                window.location.replace(prevURL);
                return;
            }
            window.location.replace('/main');
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(setupMFA.failure(error?.data?.message ?? 'Multi-factor authentication setup failed.'));
    }
}

function* disableMFASaga(action) {
    try {
        yield call(disableMFARequest, action.payload.data);

        yield put(disableMFA.success());
        yield put(hideModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultErrorHandler(disableMFA, 'Multi-factor authentication disable failed', error?.data?.code)
        );
    }
}

function* resetPasswordSaga(action) {
    try {
        const response = yield call(resetPasswordForAccount, action.payload.data);

        yield put(resetPassword.success());
        yield put(hideModal());

        window.location.replace(response.data?.location ?? '/');
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: `Something went wrong and we couldn't reset your password. Please contact support.`,
            })
        );
    }
}

function* showRecoveryCodesSaga(action) {
    try {
        const recoveryCodes = yield call(getMFARecoveryCodesRequest, action.payload.data);

        yield put(showMFARecoveryCodes.success(recoveryCodes));
        yield put(hideModalType(OTP_VERIFICATION_MODAL));
        yield put(showMFARecoveryCodesModal());
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: 'Load recovery codes failed',
            })
        );
    }
}

function* generateRecoveryCodesSaga(action) {
    try {
        const recoveryCodes = yield call(generateMFARecoveryCodesRequest, action.payload.data);

        yield put(generateMFARecoveryCodes.success(recoveryCodes));
        yield put(hideModalType(OTP_VERIFICATION_MODAL));
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            otpValidationHandler,
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: 'Generate recovery codes failed',
            })
        );
    }
}

function* loadSetupMFADataSaga() {
    try {
        const mfa = yield call(getMFASetupRequest);
        yield put(loadMFASetupData.success(mfa));
    } catch (error) {
        monitoring.captureException(error);
        yield put(loadMFASetupData.failure(error?.data?.message ?? 'Multi-factor authentication setup failed.'));
    }
}

function* logoutSaga() {
    window.history.go(-history.length);
    yield call(logout);
    yield fork(getAccountSaga);
    yield put(push('/login'));
}

/**
 * function we execute on first loading of App when try auto sign in user
 */
export function* getAccountSaga() {
    /**
     * ideally we should make call only if we have cookie rememberme.
     * But as we can't read it because of it is http-only we do this call always on start
     * and get response with account data or 403 error if fail
     */
    try {
        const response = yield call(getLoggedInAccount);
        const { resourceRole, userMFAEnabled, resourceCreatedDate } = response?.data?.account ?? {};
        const modals = yield select(state => state.modalReducer.modals);

        if (
            !modals.length &&
            resourceRole !== 'ROLE_OWNER' &&
            !userMFAEnabled &&
            window.location.hash !== '#/settings/profile' &&
            response.headers['hubplanner-mfa-show-soft-reminder'] &&
            shouldDisplayReminder(
                response?.data?.account?.preferences?.notifications,
                mfaReminderTypes.MFA_USER_SOFT_REMINDER
            )
        ) {
            yield put(showUserMFAReminderModal('SOFT'));
        }

        if (
            !modals.length &&
            resourceRole !== 'ROLE_OWNER' &&
            !userMFAEnabled &&
            window.location.hash !== '#/settings/profile' &&
            response.headers['hubplanner-mfa-show-hard-reminder'] &&
            shouldDisplayReminder(
                response?.data?.account?.preferences?.notifications,
                mfaReminderTypes.MFA_USER_HARD_REMINDER
            )
        ) {
            yield put(showUserMFAReminderModal('HARD'));
        }

        if (
            !modals.length &&
            !userMFAEnabled &&
            resourceRole === 'ROLE_OWNER' &&
            isBefore(new Date(resourceCreatedDate), new Date(MFA_LAUNCH_DATE)) &&
            !window.location.hash.includes('#/login') &&
            !window.location.hash.includes('#/startTour') &&
            !window.location.hash.includes('#/welcome') &&
            shouldDisplayReminder(
                response?.data?.account?.preferences?.notifications,
                mfaReminderTypes.MFA_COMPANY_REMINDER
            )
        ) {
            yield put(showCompanyOwnerMFAReminderModal());
        }

        yield put(getAccount.success(response?.data?.account));
    } catch (error) {
        monitoring.captureException(error);
        yield put(getAccount.failure());
    }
}

export function* updateAccountReportSettings(action) {
    try {
        const accountId = yield select(getAccountId);
        yield call(updateReportSettings, accountId, action.payload.data);
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Report settings couldn't be updated",
                type: 'danger',
            })
        );
    }
}

export function* handleUpdateAccountPreferences(action) {
    delete action.payload.data?.grid?.customSearchId;

    try {
        const accountId = yield select(getAccountId);
        const response = yield call(updatePreferences, accountId, action.payload.data);

        action.payload.callback && action.payload.callback();
        yield put(updateAccountPreferences.success(response));
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Account settings couldn't be updated",
                type: 'danger',
            })
        );
    }
}

function* changeAccountCompanyRequest(action) {
    try {
        let { path, ...rest } = action.payload.data;

        if ('/' === path.substr(0, 1)) {
            path = path.substr(1);
        }

        const response = yield call(changeAccountCompany, { path, ...rest });

        if (response.data?.location) {
            window.location.replace(response.data.location);
        }
    } catch (error) {
        monitoring.captureException(error);
        yield handleErrors(
            action,
            error,
            {
                predicate: err => isOTPRequired(err),
                handler: function*(_, action) {
                    const accountId = yield select(getAccountId);

                    yield accountId
                        ? put(showOTPVerificationModal(action))
                        : put(
                              push(
                                  action?.payload?.data?.companyId
                                      ? `/login/otp/${action.payload.data.companyId}?path=${action?.payload?.data?.path}`
                                      : '/login/otp'
                              )
                          );
                },
            },
            getDefaultNotificationErrorHandler({
                errorCode: error?.data?.code,
                message: error?.data?.message,
                defaultMessage: 'Company sign in failed',
            })
        );
    }
}

function* handleUpdateNotifications(action) {
    try {
        const accountId = yield select(getAccountId);

        const callElementsArgs = [
            [
                updateNotificationsRequest,
                accountId,
                {
                    notifications: action.payload.notifications,
                },
            ],
        ];
        if (!action.payload.onlyNotifications) {
            callElementsArgs.push([
                updateNotificationDetailsRequest,
                accountId,
                {
                    notificationDetails: action.payload.notificationDetails,
                },
            ]);
        }
        yield all(
            callElementsArgs.map(args => {
                return call(...args);
            })
        );

        if (!action.payload.silentUpdate) {
            yield put(
                addNotification({
                    message: 'Notifications have been updated',
                    type: 'success',
                })
            );
        }
    } catch (error) {
        monitoring.captureException(error);
        yield put(
            addNotification({
                message: "Notifications couldn't be updated",
                type: 'danger',
            })
        );
    }
}

export default function* accountWatcher() {
    yield takeLatest(actionTypes.GET_ACCOUNT['REQUEST'], getAccountSaga);
    yield takeLatest(actionTypes.LOGIN['REQUEST'], loginSaga);
    yield takeLatest(actionTypes.RESET_PASSWORD['REQUEST'], resetPasswordSaga);
    yield takeLatest(actionTypes.SEND_OTP_CODE_BY_EMAIL['REQUEST'], sendOTPByEmail);
    yield takeLatest(actionTypes.SETUP_MFA_DATA['REQUEST'], loadSetupMFADataSaga);
    yield takeLatest(actionTypes.SETUP_MFA['REQUEST'], setupMFASaga);
    yield takeLatest(actionTypes.VALIDATE_OTP['REQUEST'], validateOTPSaga);
    yield takeLatest(actionTypes.DISABLE_MFA['REQUEST'], disableMFASaga);
    yield takeLatest(actionTypes.SHOW_MFA_RECOVERY_CODES['REQUEST'], showRecoveryCodesSaga);
    yield takeLatest(actionTypes.CREATE_NEW_MFA_RECOVERY_CODES['REQUEST'], generateRecoveryCodesSaga);
    yield takeLatest(actionTypes.LOGOUT, logoutSaga);
    yield takeLatest(actionTypes.SIGNUP['REQUEST'], signUpSaga);
    yield takeLatest(actionTypes.CHANGE_ACCOUNT_COMPANY_REQUEST, changeAccountCompanyRequest);
    yield takeLatest(actionTypes.UPDATE_ACCOUNT_REPORT_SETTINGS['REQUEST'], updateAccountReportSettings);
    yield takeLatest(actionTypes.UPDATE_ACCOUNT_PREFERENCES['REQUEST'], handleUpdateAccountPreferences);
    yield takeLatest(actionTypes.UPDATE_ACCOUNT_NOTIFICATIONS['REQUEST'], handleUpdateNotifications);
}
