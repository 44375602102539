import { values, sortBy, pluck, find } from 'underscore';

export const STATUS_ACTIVE = { display: 'Active', value: 'STATUS_ACTIVE', legacyValue: 'Active' };
export const STATUS_PLANNED = { display: 'Planned', value: 'STATUS_PLANNED', legacyValue: 'Planned' };
export const STATUS_PENDING = { display: 'Pending', value: 'STATUS_PENDING', legacyValue: 'Pending' };
export const STATUS_FLOATING = { display: 'Floating', value: 'STATUS_FLOATING', legacyValue: 'Floating' };
export const STATUS_ARCHIVED = { display: 'Archived', value: 'STATUS_ARCHIVED', legacyValue: 'Archived' };

export const TYPE_REGULAR = { display: 'Regular', value: 'REGULAR' };
export const TYPE_EVENT = { display: 'Event', value: 'EVENT' };
export const TYPE_MILESTONE = { display: 'Milestone', value: 'Milestone' };
export const TYPE_PHASE = { display: 'Phase', value: 'Phase' };

export const TYPE_FLOATING = 'FLOATING';
export const TYPE_PENDING = 'PENDING';
export const TYPE_PLANNED = 'PLANNED';
export const TYPE_ARCHIVED = 'ARCHIVED';

export const statuses = { STATUS_ACTIVE, STATUS_PLANNED, STATUS_PENDING, STATUS_FLOATING, STATUS_ARCHIVED };

export const statusesArray = sortBy(values(statuses), status => status.value);
export const valuesArray = pluck(values(statuses), 'value');
export const getProjectStatus = value => find(statusesArray, status => value === status.value);

export const getStatusDisplay = statusValue => {
    if (statusValue) {
        return statuses[statusValue].display;
    }
};
