export function canCreateResourceRequest({
    hasManageAllResourceRequestsRights,
    hasCreateRequestResourceRights,
    isRequestExtensionsInstalled,
    resource = {},
    projectOrEvent = {},
}) {
    if (!isRequestExtensionsInstalled) {
        return false;
    }

    if (!hasManageAllResourceRequestsRights && !hasCreateRequestResourceRights) {
        return false;
    }

    const projectResources = projectOrEvent.resources || [];

    return (
        projectOrEvent.hasRightsToProject && (resource.hasRightsToResource || projectResources.includes(resource._id))
    );
}
