import moment from 'moment';
import { LEGACY, PARENT, SINGLE } from 'modules/scheduler/enums/viewModeEnum';
import { WEEK, MONTH, CELLDURATION } from 'modules/scheduler/enums/scale';
import {getResourceCapacityByDay, getResourceCapacity} from 'utils/capacityCalculation';
import handleUnassignedParent from './unassignedParent';
import handleUnassigned from './unassigned';
import handleRegular from './regular';
import { getData } from './helper';

export default (companySettings, calendars, { isSingleResourceView, isResourceGroupView } = {}) => args => {
    const schedulerInstance = args.control;
    const row = schedulerInstance.rows.find(args.cell.resource);

    if (!row) {
        return;
    }

    const scale = schedulerInstance.scale;
    let resourceCapacity;

    if (scale === WEEK.value || scale === MONTH.value) {
        resourceCapacity = getResourceCapacity(
            row.tags.resource || {},
            moment((args.cell.start.toDate())),
            // subtract because DP adds one more day
            moment((args.cell.end.toDate())).subtract(1, 'd'),
            true,
        );
    } else {
        resourceCapacity = getResourceCapacityByDay(
            row.tags.resource || {},
            companySettings,
            moment.utc(args.cell.start.toDate())
        );
    }

    // select dropdown row is undefined, so need to check for this.
    args.cell.properties.tags = row.tags;
    const data = getData(args, row, companySettings, resourceCapacity);

    let capacity = resourceCapacity;

    if (scale === CELLDURATION.value) {
        capacity = data.resourceCapacity;
    }

    if (
        isSingleResourceView ||
        isResourceGroupView ||
        (LEGACY.value === schedulerInstance.mode && !isResourceGroupView && !isSingleResourceView) ||
        'UNASSIGNED_PARENT' === row.tags.type ||
        'UNASSIGNED' === row.tags.type
    ) {
        args.cell.properties.barColor = data.backColor;
        args.cell.business = data.isWorkday;

        data.notificationType = schedulerInstance.availabilityDisplay;

        if (row.tags.unassignedParentRow || (row.tags.unassignedRow && schedulerInstance.mode === PARENT.value)) {
            handleUnassignedParent(args, row, data, capacity);
        } else if (row.tags.unassignedRow) {
            handleUnassigned(args, data, capacity);
        } else if (
            'TYPE_OFF' !== data.notificationType &&
            ((row.tags.parent && schedulerInstance.mode === PARENT.value) ||
                schedulerInstance.mode === LEGACY.value ||
                schedulerInstance.mode === SINGLE.value)
        ) {
            handleRegular(args, data, capacity, companySettings);
        }

        args.cell.backColor = data.backColor;
    } else {
        args.cell.backColor = data.backColor;
        args.cell.properties.barColor = data.backColor;
    }

};
