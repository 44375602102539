import React from 'react';
import { includes, isArray, map } from 'lodash';
import moment from 'moment';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
// import ItemTypeFilterBox from 'modules/report/containers/tableFilters/itemTypeFilterBox';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import { bookingTypes } from 'enums/bookingTypeEnum';
import { timeEntryTypes } from 'enums/timesheetTypeEnum';
import {
    differentTypesHandler,
    displayValue,
    isHeaderDisabled,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import {
    REPORTED_TIME_STATE,
    SCHEDULED_TIME_STATE,
    RESOURCE_STATUS,
    findGroupingByName,
    isDateGrouping,
} from 'modules/report/enums/groupingTypeEnum';
import { statuses as resourceStatuses, TYPE_UNASSIGNED } from 'enums/resourceEnum';
import { RESOURCE } from 'modules/report/enums/itemTypeEnum';
import Thumbnail from 'shared/thumbnail';
import { Header, ThumbnailResourceWrapper, ThumbnailTitle, TextContent } from './../styles';

const getStatusDisplayByType = (rowValue, statuses) =>
    isArray(rowValue) ? map(rowValue, type => statuses[type].display).join(', ') : rowValue;

const columnsTemplate = (groupBy, columnsToLoad) => ({
    Header: props => (
        <Header>
            <Sorting columnName={props.column.id}>{findGroupingByName(groupBy).display || 'Custom Field'}</Sorting>
            {/*<ItemTypeFilterBox disabled={isDisabled} columnName={props.column.id}/>*/}
        </Header>
    ),
    id: 'name',
    show: includes(columnsToLoad, 'name'),
    sortable: false,
    accessor: row => {
        let displayedValue;

        if (isDateGrouping(groupBy)) {
            displayedValue = findGroupingByName(groupBy).tableDisplay(row.name);
        }

        if (groupBy === REPORTED_TIME_STATE.name && timeEntryTypes[row.name]) {
            displayedValue = timeEntryTypes[row.name].display;
        }

        if (groupBy === SCHEDULED_TIME_STATE.name && bookingTypes[row.name]) {
            displayedValue = bookingTypes[row.name].display;
        }

        if (groupBy === RESOURCE_STATUS.name && resourceStatuses[row.name]) {
            displayedValue = resourceStatuses[row.name].display;
        }

        displayedValue = displayedValue || row.name || '';

        return displayValue(row.name, displayedValue);
    },
    getProps: (state, rowInfo, column) => {
        let columnStyles;

        if (
            rowInfo &&
            !isDateGrouping(groupBy) &&
            RESOURCE.name === groupBy &&
            (rowInfo.original.resourceFirstName || rowInfo.row[column.id]) &&
            !isArray(rowInfo.value)
        ) {
            columnStyles = { display: 'flex', alignItems: 'left' };
        }

        return getColumnProps(state, rowInfo, column, columnStyles);
    },
    Cell: row =>
        row.columnProps && row.columnProps.rest.disabled ? (
            row.value
        ) : RESOURCE.name === groupBy && (row.original.resourceFirstName || row.value) && !isArray(row.value) ? (
            <React.Fragment>
                <ThumbnailResourceWrapper>
                    <Thumbnail
                        firstName={row.original.resourceFirstName}
                        lastName={row.original.resourceLastName}
                        thumbnailUrl={row.original.resourceThumbUrl}
                        fullName={row.value}
                    />
                    <ThumbnailTitle className="text-container">
                        <span className="title">{row.value}</span>
                        {TYPE_UNASSIGNED.value === row.original.resourceType ? (
                            <span className="subtitle">{TYPE_UNASSIGNED.display.toLowerCase()}</span>
                        ) : null}
                    </ThumbnailTitle>
                </ThumbnailResourceWrapper>
            </React.Fragment>
        ) : (
            <TextContent>{row.value || '-'}</TextContent>
        ),
});

const otherColumns = columnsToLoad => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Date
                    </Sorting>
                </Header>
            );
        },
        id: 'date',
        sortable: false,
        accessor: row => displayValue(row.date, moment(row.date).format('YYYY-MM-DD')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Category Name
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingCategoryName',
        sortable: false,
        show: includes(columnsToLoad, 'bookingCategoryName'),
        accessor: row =>
            displayValue(row.bookingCategoryName, differentTypesHandler(row.bookingCategoryName, 'Categories')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingNote',
        sortable: false,
        show: includes(columnsToLoad, 'bookingNote'),
        accessor: row => displayValue(row.bookingNote, differentTypesHandler(row.bookingNote, 'Notes')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Time Entry Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'timeEntryNote',
        sortable: false,
        show: includes(columnsToLoad, 'timeEntryNote'),
        accessor: row => displayValue(row.timeEntryNote, differentTypesHandler(row.timeEntryNote, 'Notes')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Approval Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingApprovalNote',
        sortable: false,
        show: includes(columnsToLoad, 'bookingApprovalNote'),
        accessor: row => displayValue(row.bookingApprovalNote, differentTypesHandler(row.bookingApprovalNote, 'Notes')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Rejection Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingRejectionNote',
        sortable: false,
        show: includes(columnsToLoad, 'bookingRejectionNote'),
        accessor: row =>
            displayValue(row.bookingRejectionNote, differentTypesHandler(row.bookingRejectionNote, 'Notes')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Requested Note
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingRequestedNote',
        sortable: false,
        show: includes(columnsToLoad, 'bookingRequestedNote'),
        accessor: row =>
            displayValue(row.bookingRequestedNote, differentTypesHandler(row.bookingRequestedNote, 'Notes')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Title
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingTitle',
        sortable: false,
        show: includes(columnsToLoad, 'bookingTitle'),
        accessor: row => displayValue(row.bookingTitle, differentTypesHandler(row.bookingTitle, 'Titles')),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Time Entry status
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'timeEntryStatus',
        sortable: false,
        show: includes(columnsToLoad, 'timeEntryStatus'),
        accessor: row =>
            displayValue(
                row.timeEntryStatus,
                differentTypesHandler(
                    row.timeEntryStatus,
                    'Statuses',
                    getStatusDisplayByType(row.timeEntryStatus, timeEntryTypes)
                )
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Booking Type
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'bookingType',
        sortable: false,
        show: includes(columnsToLoad, 'bookingType'),
        accessor: row =>
            displayValue(
                row.bookingType,
                differentTypesHandler(row.bookingType, 'Types', getStatusDisplayByType(row.bookingType, bookingTypes))
            ),
    },
];

/**
 * @param {string}  groupBy
 * @param {boolean} fixed
 * @param {array}   columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (groupBy, fixed, columnsToLoad) =>
    fixed
        ? [
              {
                  Header: '',
                  fixed: 'left',
                  columns: [{ ...columnsTemplate(groupBy, columnsToLoad) }],
              },
              ...otherColumns(columnsToLoad),
          ]
        : [columnsTemplate(groupBy, columnsToLoad), ...otherColumns(columnsToLoad)];
