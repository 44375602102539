import moment from 'moment';
import { some, find, includes, keys, isDate, map, forEach, filter, pick, cloneDeep } from 'lodash';
import { CHECKBOX, SELECT, RADIO, DATE, TEXT, NUMBER, TEXTAREA, EMAIL } from 'enums/customFieldEnum';
import { objectDiff } from './dataManipulation';

/**
 * this function is supporting templateAllowMultipleValues property name
 */
export const getMyCustomFieldsDefaultValues = customFields => {
    let customFieldsInitialValues = {};

    forEach(customFields, customField => {
        const hasChoices = customField.choices?.length;

        if (customField.templateType === CHECKBOX.value) {
            if (hasChoices) {
                let choices = {};

                forEach(customField.choices, choice => {
                    choices[choice.choiceId] = true;
                });

                customFieldsInitialValues[customField.templateId] = choices;
            }
        } else if (DATE.value === customField.templateType) {
            const date = moment(customField.value);
            customFieldsInitialValues[customField.templateId] = date.isValid() ? date.toDate() : '';
        } else if (customField.templateAllowMultipleValues) {
            customFieldsInitialValues[customField.templateId] = map(customField.choices, choice => ({
                value: choice.value,
                _id: choice.choiceId,
            }));
        } else if (customField.templateType === SELECT.value) {
            if (hasChoices) {
                const choice = customField.choices[0] || {};
                customFieldsInitialValues[customField.templateId] = {
                    value: choice.value,
                    _id: choice.choiceId,
                };
            }
        } else if (customField.templateType === RADIO.value) {
            if (hasChoices) {
                const choice = customField.choices[0] || {};
                customFieldsInitialValues[customField.templateId] = choice.choiceId;
            }
        } else {
            customFieldsInitialValues[customField.templateId] = customField.value;
        }
    });

    return customFieldsInitialValues;
};

export const mapCustomFields = (customFieldsValues, customFieldTemplates) => {
    let mappedCustomFields = [];
    forEach(customFieldsValues, (value, customFieldId) => {
        let template = find(customFieldTemplates, customField => customField._id === customFieldId);
        if (
            template &&
            ((value && (value.length || ('object' === typeof value && keys(value).length))) || isDate(value))
        ) {
            template = cloneDeep(template);
            if (template.type === CHECKBOX.value) {
                const onlyCheckedValues = map(value, (item, choiceId) => (item ? choiceId : false));
                const onlyCheckedChoices = filter(template.choices, choice => includes(onlyCheckedValues, choice._id));
                template.choices = map(onlyCheckedChoices, choice => ({
                    choiceId: choice._id,
                    value: choice.value,
                }));
            } else if (template.allowMultipleValues) {
                template.choices = map(value, choice => ({
                    choiceId: choice._id,
                    value: choice.value,
                }));
            } else if (template.type === SELECT.value) {
                template.choices = [{ value: value.value, choiceId: value._id }];
            } else if (template.type === RADIO.value) {
                const choice = find(template.choices, choice => choice._id === value);
                choice && (template.choices = [{ value: choice.value, choiceId: choice._id }]);
            } else if (template.type === DATE.value) {
                template.choices = [];
                template.value = moment(value).format('YYYY-MM-DD');
            } else {
                template.choices = [];
                template.value = value;
            }

            mappedCustomFields.push(template);
        } else if (
            !value &&
            template &&
            [TEXT.value, EMAIL.value, NUMBER.value, TEXTAREA.value].includes(template.type)
        ) {
            template = cloneDeep(template);
            template.choices = [];
            template.value = value;
            mappedCustomFields.push(template);
        } else if (value && !value.length && template && [SELECT.value].includes(template.type)) {
            template = cloneDeep(template);
            template.choices = [];
            template.value = null;
            mappedCustomFields.push(template);
        }
    });

    return map(mappedCustomFields, customField => ({
        choices: customField.choices,
        templateAllowMultipleValues: customField.allowMultipleValues,
        templateId: customField._id,
        templateLabel: customField.label,
        templateType: customField.type,
        value: customField.value || '',
    }));
};

export const pickResourceAvailabilityFields = values => {
    const { monday, tuesday, wednesday, thursday, friday, useResourceAvailability } = values;

    return { monday, tuesday, wednesday, thursday, friday, useResourceAvailability };
};

export const pickChangedFields = (values, initialValues) => {
    const filteredValues = objectDiff(values, initialValues);
    const changedFieldNames = keys(filteredValues);
    const hasIconField = some(changedFieldNames, field => -1 !== field.toLowerCase().indexOf('link'));
    if (includes(changedFieldNames, 'unassignedWork') && !includes(changedFieldNames, 'resources')) {
        changedFieldNames.push('resources'); // exception for project modal
    }
    if (includes(changedFieldNames, 'resources') && !includes(changedFieldNames, 'unassignedWork')) {
        changedFieldNames.push('unassignedWork'); // exception for project modal
    }
    if (!includes(changedFieldNames, 'setStartEndDates')) {
        changedFieldNames.push('setStartEndDates'); // exception for project modal
    }
    if (!includes(changedFieldNames, 'vacation')) {
        changedFieldNames.push('vacation'); // exception for resource modal
    }
    if (!includes(changedFieldNames, 'policy')) {
        changedFieldNames.push('policy'); // exception for policy, we need to always pass it because it is clearable field
    }
    if (values.setStartEndDates) {
        changedFieldNames.push('startDate');
        changedFieldNames.push('endDate');
    }

    // Always send useStatusColor
    changedFieldNames.push('useStatusColor');

    if (!hasIconField) {
        return pick(values, changedFieldNames);
    }

    return {
        ...pick(values, changedFieldNames),
        ...(hasIconField
            ? {
                  iconLink1: values.iconLink1,
                  iconLink2: values.iconLink2,
                  iconLink3: values.iconLink3,
                  iconLink4: values.iconLink4,
                  iconLink5: values.iconLink5,
                  link1: values.link1,
                  link2: values.link2,
                  link3: values.link3,
                  link4: values.link4,
                  link5: values.link5,
              }
            : {}),
    };
};

export const normalizeFloatNumber = value => (value ? parseFloat(value) : value);

export const normalizeIntNumber = value => (value ? parseInt(value) : value);

export const normalizePositiveNumbers = value => {
    if (!value) {
        return value;
    }

    const numsWithSeparator = value.replace(/[^\d\.\,]/g, '');

    return parseFloat(numsWithSeparator);
};

export const normalizeId = value => (value ? value._id : value);

export const normalizeBoolean = value => {
    if ('true' === value) {
        return true;
    }

    if ('false' === value) {
        return false;
    }

    return value;
};
