export const viewChanged = currentView => ({
    type: 'VIEW_CHANGED',
    currentView,
});

export const updateMenuContent = content => ({
    type: 'UPDATE_MENU_CONTENT',
    content,
});

export const changeSubMenuVisibility = (id, trace, subMenuVisibility, flow) => ({
    type: 'CHANGE_SUBMENU_VISIBILITY',
    id,
    trace,
    subMenuVisibility,
    flow,
});

export const updateSubmenuIconContent = (flow, id, data) => ({
    type: 'UPDATE_SUBMENU_ICON_CONTENT',
    flow,
    id,
    data,
});

export const setText = (text, parentId) => ({
    type: 'SET_TEXT',
    text,
    parentId,
});

export const filterContent = (text, parentId) => ({
    type: 'FILTER_CONTENT',
    text,
    parentId,
});

export const toggleFilterVisibility = parentId => ({
    type: 'TOGGLE_FILTER_VISIBILITY',
    parentId,
});

export const changeActiveLinkId = value => ({
    type: 'CHANGE_ACTIVE_LINK',
    propName: 'id',
    value,
});

export const changeActiveLinkTo = value => ({
    type: 'CHANGE_ACTIVE_LINK',
    propName: 'to',
    value,
});

export const changeActiveLinkLabel = value => ({
    type: 'CHANGE_ACTIVE_LINK',
    propName: 'label',
    value,
});

export const changeActiveLinkFromLocation = () => ({
    type: 'CHANGE_ACTIVE_LINK_FROM_LOCATION',
});

export const changeAndOpenActiveLinkFromLocation = () => ({
    type: 'CHANGE_AND_OPEN_ACTIVE_LINK_FROM_LOCATION',
});
