import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useAccountPreferences, useBooking } from 'modules/scheduler/hooks';
import { PARENT } from 'modules/scheduler/enums/viewModeEnum';
import { NEW } from 'enums/contextMenuEnum';
import LegacySingleMode from './partials/legacy/singleMode';
import ResourceSingleMode from './partials/resourceSingleMode';
import LegacyChildrenRows from './partials/legacy/childrenRows';
import ChildrenRows from './partials/childrenRows';
import { ResourceParent as ProjectGroupMode } from './../shared';
import LegacyProjectGroupMode from './partials/legacy/projectGroupMode';

export const CONTEXT_MENU_MAX_HEIGHT = 440;
export const CONTEXT_MENU_WIDTH = 280;
export const CONTEXT_MENU_WIDTH_EXTENDED = 370;

const ScheduleDropdown = React.forwardRef((props, ref) => {
    const {
        onClose,
        height,
        schedulerRef,
        showRequestResourceOption,
        showRequestVacationOption,
        isRequestMode,
        combineScheduleRequest,
        isToolbar,
        viewObject,
        viewData,
    } = props;

    const rowTags = useSelector(state => state.scheduler.currentSelection.rowTags);
    const { gridPreferences } = useAccountPreferences();
    const { createBooking, pasteBooking, requestProject } = useBooking(null, onClose);

    const mode = schedulerRef.current.control.mode;
    const isDefaultVacationEventSet = !!useSelector(
        state => state.companyReducer.company.settings.vacationBookingCategoryId
    );

    const showChildrenContextMenu =
        rowTags &&
        !isToolbar &&
        !rowTags.parent &&
        mode === PARENT.value &&
        (rowTags.isProjectRow || rowTags.isResourceRow);

    const childrenContextMenuWidth =
        showChildrenContextMenu && !isRequestMode && showRequestResourceOption && NEW === gridPreferences.contextMenu
            ? CONTEXT_MENU_WIDTH_EXTENDED
            : CONTEXT_MENU_WIDTH;

    let condition1 =
        isToolbar ||
        !rowTags ||
        (mode !== PARENT.value && (rowTags.isResourceRow || rowTags.isProjectRow)) ||
        (mode === PARENT.value && rowTags.isEventRow) ||
        rowTags.unassignedRow ||
        rowTags.unassignedParentRow ||
        rowTags.menuRow;
    let condition2 = showChildrenContextMenu;
    let condition3 = rowTags && rowTags.parent && rowTags.isProjectRow && !isToolbar;

    const section3JSX = () => {
        return NEW === gridPreferences.contextMenu ? (
            <ProjectGroupMode
                schedulerRef={schedulerRef}
                height={height}
                width={CONTEXT_MENU_WIDTH}
                onClose={onClose}
            />
        ) : (
            <LegacyProjectGroupMode onClose={onClose} ref={schedulerRef} />
        );
    };

    const section1JSX = () => {
        return NEW === gridPreferences.contextMenu ? (
            <ResourceSingleMode
                height={height > CONTEXT_MENU_MAX_HEIGHT ? CONTEXT_MENU_MAX_HEIGHT : height}
                width={CONTEXT_MENU_WIDTH}
                onClose={onClose}
                onClick={createBooking}
                onRequestVacation={requestProject}
                isDefaultVacationEventSet={isDefaultVacationEventSet}
                onPaste={pasteBooking}
                showRequestVacationOption={showRequestVacationOption}
                isRequestMode={isRequestMode}
                combineScheduleRequest={combineScheduleRequest}
                scale={schedulerRef.current.control.scale}
                otherOptionsContentJSX={section3JSX}
            />
        ) : (
            <LegacySingleMode
                rowTags={rowTags}
                onClick={createBooking}
                onPaste={pasteBooking}
                onClose={onClose}
                onRequestVacation={requestProject}
                isDefaultVacationEventSet={isDefaultVacationEventSet}
                showRequestVacationOption={showRequestVacationOption}
                combineScheduleRequest={combineScheduleRequest}
                ref={schedulerRef}
                isRequestMode={isRequestMode}
                viewObject={viewObject}
                viewData={viewData}
                showRequestResourceOption={showRequestResourceOption}
            />
        );
    };

    const section2JSX = () => {
        return NEW === gridPreferences.contextMenu ? (
            <ChildrenRows
                ref={schedulerRef}
                width={childrenContextMenuWidth}
                onPaste={pasteBooking}
                onClick={requestProject}
                isRequestMode={isRequestMode}
                showRequestResourceOption={showRequestResourceOption}
                onClose={onClose}
            />
        ) : (
            <LegacyChildrenRows
                ref={schedulerRef}
                onClick={createBooking}
                rowTags={rowTags}
                onPaste={pasteBooking}
                onClose={onClose}
                isRequestMode={isRequestMode}
                scale={schedulerRef.current.control.scale}
                showRequestResourceOption={showRequestResourceOption}
                combineScheduleRequest={combineScheduleRequest}
                showRequestVacationOption={showRequestVacationOption}
                isDefaultVacationEventSet={isDefaultVacationEventSet}
                onRequestVacation={requestProject}
            />
        );
    };

    return (
        <div ref={ref} style={{ width: `${childrenContextMenuWidth}px` }}>
            {condition1 && section1JSX()}
            {/* project & resource view on group mode */}
            {condition2 && section2JSX()}
            {/* Handling creating milestones, phases, dates */}
            {condition3 && section3JSX()}
        </div>
    );
});

ScheduleDropdown.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    height: PropTypes.number,
    showRequestResourceOption: PropTypes.bool,
    showRequestVacationOption: PropTypes.bool,
    isRequestMode: PropTypes.bool,
    combineScheduleRequest: PropTypes.bool,
    isToolbar: PropTypes.bool,
};

ScheduleDropdown.defaultProps = {
    onClose: null,
    height: CONTEXT_MENU_MAX_HEIGHT,
    showRequestResourceOption: false,
    showRequestVacationOption: false,
    isToolbar: false,
    isRequestMode: false,
    combineScheduleRequest: true,
};

export default ScheduleDropdown;
