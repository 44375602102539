import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TimeRangePicker } from 'shared/components/TimeRangePicker';
import { Layout } from 'shared/components/layout';
import { IconButton } from 'shared/components/iconButton';

const useStyles = makeStyles({
    icon: {
        cursor: 'pointer',
    },
    margin: {
        marginTop: '2px',
        marginBottom: '10px',
        minWidth: 0,
    },
});

export const Intervals = React.memo(props => {
    const { intervals, addInterval, changeInterval, removeInterval, error } = props;
    const classes = useStyles();

    return (
        <Layout stack padding="0 15px">
            {intervals.length > 0 ? (
                intervals.map(({ start, end }, idx) => {
                    const isLastElement = intervals.length - 1 === idx;
                    const rangeError = error && typeof error === 'object' && error[idx];

                    return (
                        <Layout key={`${idx}-${start}-${end}`} hAlign={isLastElement ? 'start' : 'start'} vAlign="top">
                            <TimeRangePicker
                                idx={idx}
                                start={start}
                                end={end}
                                hoursAmount={false}
                                changeInterval={changeInterval}
                                removeInterval={removeInterval}
                                error={rangeError?.range}
                            />
                            {isLastElement && intervals.length < 48 && (
                                <IconButton
                                    dataCy="add-interval"
                                    icon="addCircleOutline"
                                    tooltipText="Add interval"
                                    className={`${classes.icon} ${classes.margin}`}
                                    onClick={addInterval}
                                />
                            )}
                        </Layout>
                    );
                })
            ) : (
                <Layout hAlign="flex-end" vAlign="top">
                    <IconButton
                        dataCy="add-interval"
                        data
                        text="Add interval"
                        icon="addCircleOutline"
                        className={`${classes.icon}`}
                        onClick={addInterval}
                    />
                </Layout>
            )}
        </Layout>
    );
});
