import Schema from 'form-schema-validation';
import { startDateValidator, repeatTimesValidator } from 'utils/formValidators';
import { errorMessages } from 'utils/schemaUtil';
import { removeUTCZuluFromDateTimestamp } from '@hub-mono/utils';

export const formSchema = new Schema(
    {
        name: {
            type: String,
            required: true,
        },
        start: {
            type: Date,
            required: true,
            defaultValue: new Date(),
            validators: [startDateValidator('end')],
            validatorInterdependent: true,
        },
        end: {
            type: Date,
            required: true,
            defaultValue: new Date(),
        },
        color: {
            type: String,
            required: false,
            defaultValue: 'NONE',
        },
        interval: {
            type: String,
            required: false,
            defaultValue: 'NONE',
        },
        repeatTimes: {
            type: Number,
            required: false,
            defaultValue: 0,
            validators: [repeatTimesValidator],
            validatorInterdependent: true,
        },
        project: {
            type: String,
            required: true,
        },
    },
    errorMessages,
    false
);

export const getDefaultValues = (selection, color, projectId) => {
    return Object.fromEntries(
        Object.entries(formSchema.getDefaultValues()).map(([key, value]) => {
            if (['start', 'end'].includes(key)) {
                return [key, removeUTCZuluFromDateTimestamp(selection[key]) || value];
            }

            if (['name', 'interval', 'repeatTimes'].includes(key)) {
                return [key, selection[key] || value];
            }

            if ('color' === key) {
                return [key, selection.backColor || color || value];
            }

            if ('project' === key) {
                return [key, projectId || value];
            }

            return [key, value];
        })
    );
};

export const mapFormToRequest = values => ({
    start: values.start,
    end: values.end,
    name: values.name,
    interval: values.interval,
    repeatTimes: parseInt(values.repeatTimes),
    repeat: 0 < values.repeatTimes && 'NONE' !== values.interval,
    color: values.color,
    project: values.project,
});
