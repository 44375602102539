/* eslint-env browser */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { first } from 'underscore';
import { PopoverHeader } from 'reactstrap';
import { iconTypes } from 'enums/iconEnum';
import { Wrapper, PopoverContent, StyledPopover, IconButton } from './styles';
import Icon from './iconButton';

function IconPicker(props) {
    const [state, setState] = useState({
        popoverOpen: false,
        currentIcon: props.icon || first(iconTypes),
    });
    let popoverNode = null;

    const handleClickOutside = useCallback(
        event => {
            if (popoverNode && !popoverNode.contains(event.target) && state.popoverOpen) {
                setState(state => ({ ...state, popoverOpen: false }));
            }
        },
        [popoverNode, state.popoverOpen]
    );

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);

        return document.removeEventListener('click', handleClickOutside, true);
    }, [handleClickOutside]);

    useEffect(() => {
        setState(state => ({ ...state, currentIcon: props.icon }));
    }, [props.icon]);

    const toggle = () => {
        setState(state => ({
            ...state,
            popoverOpen: !state.popoverOpen,
        }));
    };

    const onIconSelect = icon => {
        setState(state => ({
            ...state,
            currentIcon: icon,
        }));
        toggle();
        props.onChange(icon);
    };

    return (
        <Wrapper>
            <IconButton id={`iconPopover${props.iconKey}`} onClick={toggle}>
                <i className={`fa ${state.currentIcon}`} />
            </IconButton>
            <StyledPopover
                placement="top"
                isOpen={state.popoverOpen}
                target={`iconPopover${props.iconKey}`}
                toggle={toggle}
                container={props.container || 'body'}
            >
                <div ref={node => (popoverNode = node)}>
                    <PopoverHeader>{props.title}</PopoverHeader>
                    <PopoverContent>
                        {props.icons.map((icon, key) => (
                            <Icon
                                key={key}
                                onClick={() => onIconSelect(icon)}
                                isActive={state.currentIcon === icon}
                                icon={icon}
                            />
                        ))}
                    </PopoverContent>
                </div>
            </StyledPopover>
        </Wrapper>
    );
}

IconPicker.propTypes = {
    onChange: PropTypes.func,
    icon: PropTypes.string,
    title: PropTypes.string,
    iconKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IconPicker.defaultProps = {
    onChange: () => {},
    icon: '',
    title: '',
    iconKey: '',
};

export default IconPicker;
