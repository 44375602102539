import React, { useCallback, useMemo, useState } from 'react';
import { useModalOffset } from '../../../hooks/useModalOffset';
import { startOfYear } from '../../../utils/DateUtil';
import { TOIL_EXPIRY_ENUM } from '../enums';
import { APPROVED, SUBMITTED } from '../../../enums/timesheetTypeEnum';
import { Modal, TextField, Typography, Layout, Button, Icon } from '@hub-mono/ui';
import { useSelector } from 'react-redux';
import { selectIsCreating, selectToilPolicies } from '../store/selectors';
import styled from 'styled-components';

const getDefaultToilValues = () => ({
    reportedTimeStatuses: [APPROVED, SUBMITTED],
    expiry: TOIL_EXPIRY_ENUM.ANNUAL,
    start: startOfYear(new Date()),
    dailyLimitExclude: true,
});

const StyledModal = styled(Modal)`
    .MuiPaper-root {
        width: 366px;
    }
`;

export const NewPolicyModal = React.memo(props => {
    const { onReject, onSubmit } = props;

    const offsetClassNames = useModalOffset();
    const toilPolicies = useSelector(selectToilPolicies);
    const isCreating = useSelector(selectIsCreating);
    const [name, setName] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const error = name ? null : 'Name cannot be empty';
    const hasError = Boolean(error);

    const handleNameChange = useCallback(event => {
        setName(event.target.value);
    }, []);

    const reject = useCallback(() => {
        onReject();
    }, [onReject]);

    const confirm = useCallback(() => {
        setSubmitted(true);

        if (hasError) {
            return;
        }

        onSubmit({
            name,
            ...getDefaultToilValues(),
        });
    }, [hasError, name, onSubmit]);

    const repeatedName = useMemo(() => {
        return toilPolicies.some(policy => policy.name === name);
    }, [name, toilPolicies]);

    return (
        <StyledModal
            onClose={reject}
            className={offsetClassNames}
            variant="dialog"
            title={
                <Typography variant="headline" size="large">
                    Add new policy
                </Typography>
            }
            content={
                <Layout stack gap={24}>
                    <TextField
                        name="new-policy-name"
                        dataCy="new-policy-name"
                        label="Policy name"
                        placeholder="eg: German TOIL Policy"
                        value={name}
                        onChange={handleNameChange}
                        error={submitted && hasError}
                        helperText={submitted ? error : undefined}
                    />
                    {repeatedName ? (
                        <Layout gap={16}>
                            <Layout flex="0 0 24px">
                                <Icon name="error" color="error-container" size={24} />
                            </Layout>
                            <Typography variant="body" size="small">
                                Attention: This policy name already exists. If you want duplicates please proceed.
                                Otherwise choose a new name.
                            </Typography>
                        </Layout>
                    ) : null}
                </Layout>
            }
            actions={
                <Layout hAlign="flex-end" gap={16}>
                    <Button variant="ttext" dataCy="add-new-policy-dialog-cancel" onClick={reject}>
                        Cancel
                    </Button>
                    <Button dataCy="add-new-policy-dialog-save" onClick={confirm} disabled={isCreating}>
                        Save
                    </Button>
                </Layout>
            }
        />
    );
});
