import React, { useEffect, useRef, useState } from 'react';

// Simulates render, and return width of rendered text
const getTextToBeRenderedWidth = (text, font) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
};

// Returns the css property of a rendered element on DOM
const getCssPropertyOfElement = (element, property) => {
    return window.getComputedStyle(element, null).getPropertyValue(property);
};

// Returns font properties of a rendered element on DOM
const getElementFont = (el = document.body) => {
    const fontWeight = getCssPropertyOfElement(el, 'font-weight') || 'normal';
    const fontSize = getCssPropertyOfElement(el, 'font-size') || '16px';
    const fontFamily = getCssPropertyOfElement(el, 'font-family') || 'Times New Roman';
    return `${fontWeight} ${fontSize} ${fontFamily}`;
};

// Given a width, string, fontStyle, Returns an array of strings that can fit that width, without overflow.
const splitStringToLines = (elementWidth, str, fontStyle) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = fontStyle;
    let lineWidth = 0;
    let line = '';
    let lines = [];

    // Split the string into words
    const words = str.split(' ');

    for (let i = 0; i < words.length; i++) {
        const wordWidth = context.measureText(words[i]).width;
        if (lineWidth + wordWidth > elementWidth) {
            // If the word is too long to fit on the current line,
            // add the current line to the array of lines and start a new line
            lines.push(line.trim());
            line = '';
            lineWidth = 0;
        }

        // Add the word to the current line and update the line width
        line += words[i] + ' ';
        lineWidth += wordWidth + context.measureText(' ').width;
    }

    // Push the last line to the array of lines
    lines.push(line.trim());

    return lines;
};

const getTruncatedText = (text, containerWidth, font, lines, withEllipsis) => {
    const stringSplitted = splitStringToLines(containerWidth, text, font);
    // All of them
    if (lines == null || lines > stringSplitted.length) {
        return stringSplitted.join('\n');
    }
    // Only number of #lines
    else {
        const str = stringSplitted.slice(0, lines).join('\n');
        return withEllipsis ? `${str.substring(0, str.length - 3)}...` : str;
    }
};

// This components splits text, based on his fons and his parent width, into multiple lines.
export const MultipleLinesText = React.memo(props => {
    const { text, lines, notResizeable, withEllipsis } = props;
    const [containerWidth, setContainerWidth] = useState(0);
    const divRef = useRef();

    const containerWidthChange = () => {
        if (divRef?.current) {
            setContainerWidth(divRef.current.offsetWidth);
        }
    };

    useEffect(() => {
        containerWidthChange();
        const divRefCurrent = divRef?.current;
        !notResizeable && divRefCurrent && divRefCurrent.addEventListener('resize', containerWidthChange);
        return () => {
            !notResizeable && divRefCurrent && divRefCurrent.removeEventListener('resize', containerWidthChange);
        };
    }, [notResizeable]);

    const font = divRef?.current ? getElementFont(divRef?.current) : null;
    const textWidth = font ? getTextToBeRenderedWidth(text, font) : 0;

    const truncated = textWidth > containerWidth;
    [containerWidth, font, lines, withEllipsis];
    const finalText = truncated ? getTruncatedText(text, containerWidth, font, lines, withEllipsis) : text;
    return <div ref={divRef}>{finalText}</div>;
});

export default MultipleLinesText;
