import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'reactstrap';
import { isFunction, isObject } from 'lodash';
import { GroupItemWrapper, Label } from './styles';
import { createCySelector } from 'utils/cypressUtil';
import { makeStyles } from '@material-ui/core/styles';
import { DotsMenuButton } from 'shared/components/dotsMenuButton';
import { showReportsGroupModal } from 'actions/modalActions';
import { hasProjectManagerReportRights, hasRole } from 'utils/rightsUtil';
import { getAccount } from 'selectors/account';

const useStyles = makeStyles({
    menuItemText: {
        whiteSpace: 'pre-wrap',
        marginLeft: '5px',
    },
});

export const MenuItemWithGroups = props => {
    const {
        id,
        active,
        className,
        classNameActive,
        classStore,
        icon: Icon,
        label,
        toggleSubMenu,
        hasSubMenu,
        subMenuVisibility,
        subGroupsCount,
        inputProps,
        allContent,
    } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const account = useSelector(getAccount);

    const handleClick = useCallback(
        event => {
            toggleSubMenu(event);
        },
        [toggleSubMenu]
    );

    const reportItems = useMemo(() => allContent.filter(item => !!item.templateId), [allContent]);

    const hasManageReportRights =
        hasRole(account.resourceRoleRights, 'manageReports') ||
        hasProjectManagerReportRights(account) ||
        inputProps.type === 'PRIVATE';

    return (
        <GroupItemWrapper data-cy={`menu--group-item--${createCySelector(label)}`}>
            <NavLink
                href="#"
                className={classNames(className, active && classNameActive)}
                onClick={handleClick}
                data-group-id={id}
                data-cy={`menu--group-item--link--${createCySelector(label)}`}
            >
                {(isFunction(Icon) || isObject(Icon)) && <Icon className={classStore.classIcon} />}

                <Label className={classNames(classes.menuItemText, { subgroups: 0 < subGroupsCount })} title={label}>
                    {label}
                </Label>
                {hasSubMenu && (
                    <i
                        className={classNames(
                            classStore.classStateIcon,
                            classStore.iconNamePrefix +
                                (subMenuVisibility ? classStore.iconNameStateVisible : classStore.iconNameStateHidden)
                        )}
                    />
                )}
            </NavLink>

            {hasManageReportRights ? (
                <DotsMenuButton
                    menuPosition={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                        transformOrigin: { vertical: 'top', horizontal: 'left' },
                    }}
                    options={[
                        {
                            title: 'Create Sub Group',
                            action: () =>
                                dispatch(showReportsGroupModal({ allReports: reportItems, parent: inputProps.type })),
                        },
                    ]}
                />
            ) : null}
        </GroupItemWrapper>
    );
};

MenuItemWithGroups.propTypes = {
    group: PropTypes.object.isRequired,
    itemsCount: PropTypes.number.isRequired,
    subGroupsCount: PropTypes.number,
};

MenuItemWithGroups.defaultProps = {
    subGroupsCount: 0,
};

export default MenuItemWithGroups;
