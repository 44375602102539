import { EVENT } from '../../../enums/criteriaEnum';

export const getTabIcons = (type, { projectKeyWord, resourceKeyWord, resourcePluralKeyWord, projectTabName }) => {
    const dataCyKeyWord = type === EVENT ? 'event' : 'project';

    return [
        {
            id: '1',
            title: 'Basic Information',
            desc: `Fill out basic info about this ${projectKeyWord}`,
            icon: 'fa-file',
            dataCy: `${dataCyKeyWord}-modal-basic-information`,
        },
        {
            id: '2',
            title: `${resourceKeyWord} & Groups Management`,
            desc: `Add ${resourcePluralKeyWord} to ${projectKeyWord} and manage Groups`,
            icon: 'fa-user-plus',
            dataCy: `${dataCyKeyWord}-modal-resource-groups-management`,
        },
        {
            id: '3',
            title: 'Billing, Budgets and Rates Management',
            desc: `Manage the rates and budgets for the ${projectKeyWord}`,
            icon: 'fa-dollar',
            dataCy: `${dataCyKeyWord}-modal-billing-budgets-rates-management`,
        },
        {
            id: '4',
            title: 'Fixed cost',
            desc: `Manage the fixed costs for the ${projectKeyWord}`,
            icon: 'fa-money',
            dataCy: `${dataCyKeyWord}-modal-fixed-management`,
        },
        {
            id: '5',
            title: 'Notes and Links',
            desc: `Add ${projectKeyWord} Notes and ${projectKeyWord} Links`,
            icon: 'fa-file',
            dataCy: `${dataCyKeyWord}-modal-notes-links`,
        },
        {
            id: '6',
            title: projectTabName || 'Custom Fields',
            desc: `${projectTabName} for your ${projectKeyWord}`,
            icon: 'fa-pencil',
            dataCy: `${dataCyKeyWord}-modal-custom-fields`,
        },
        {
            id: '7',
            title: `${projectKeyWord} Milestones`,
            desc: `Create and Manage your ${projectKeyWord} Milestones`,
            icon: 'fa-calendar-plus-o',
            dataCy: `${dataCyKeyWord}-modal-project-milestones`,
        },
        {
            id: '8',
            title: `${projectKeyWord} Phases`,
            desc: `Create and Manage your ${projectKeyWord} Phases`,
            icon: 'fa-tasks',
            dataCy: `${dataCyKeyWord}-modal-project-phases`,
        },
        {
            id: '9',
            title: 'Timesheet Settings',
            desc: `Edit your timesheet settings for this ${projectKeyWord}`,
            icon: 'fa-clock-o',
            dataCy: `${dataCyKeyWord}-modal-timesheet-settings`,
        },
        {
            id: '10',
            title: `Advanced ${projectKeyWord} Settings`,
            desc: `Use advanced settings below for this ${projectKeyWord}`,
            icon: 'fa-gears',
            dataCy: `${dataCyKeyWord}-modal-advanced-project-settings`,
        },
    ];
};
