import { useEffect, useRef, useState } from 'react';
import { toilApi } from '../../api/toil';
import { endOfYear, formatDate, startOfYear, DATE_FORMATS } from '@hub-mono/utils';
import { mapModifiedAdjustments } from '../../forms/resourceForm';
import { useSelector } from 'react-redux';
import { selectIsToilExtensionInstalled } from '../../selectors/company';
import { isEqual } from 'lodash';

export const useCheckToil = ({ resourceId, toilPolicyId, modifiedToilAdjustments, year, checkToil }) => {
    const [statistics, setStatistics] = useState();
    const isToilExtensionInstalled = useSelector(selectIsToilExtensionInstalled);
    const prevParams = useRef(undefined);

    useEffect(() => {
        let mounted = true;

        if (checkToil && isToilExtensionInstalled && resourceId) {
            const startDate = new Date();
            startDate.setFullYear(year);
            const endDate = new Date();
            endDate.setFullYear(year);

            const start = startOfYear(startDate);
            const end = endOfYear(endDate);

            const params = {
                toilPolicyId: toilPolicyId ?? null,
                resourceId,
                start: formatDate(start, DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                end: formatDate(end, DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                toilAdjustmentsToSave: mapModifiedAdjustments(modifiedToilAdjustments),
            };

            if (!prevParams.current || !isEqual(prevParams.current, params)) {
                toilApi.getEventStatsCheckToil(params).then(response => {
                    if (mounted) {
                        prevParams.current = {
                            params,
                        };
                        setStatistics(response);
                    }
                });
            }
        }

        return () => {
            mounted = false;
        };
    }, [checkToil, isToilExtensionInstalled, modifiedToilAdjustments, resourceId, toilPolicyId, year]);

    return statistics;
};
