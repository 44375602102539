import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { assign, isFunction, contains } from 'underscore';
import { initialReportState } from 'reducers/reportReducer';
import { hasProjectManagerReportRights, hasRole } from 'utils/rightsUtil';
import StorageUtil from 'utils/StorageUtil';
import { MY_REPORTS, GROUP_REPORT } from './../../enums/reportTypeEnum';
import { RESOURCE, UNASSIGNED } from './../../enums/itemTypeEnum';
import { CUSTOM, SYSTEM } from 'modules/report/enums/templateTypeEnum';
import { isRemovable } from 'modules/report/utils/templateHelper';
import { Item, SettingIcon, GroupItemWrapper } from './styles';
import { DotsMenuButton } from 'shared/components/dotsMenuButton';

class MenuItem extends PureComponent {
    static propTypes = {
        deleteIcon: PropTypes.bool,
        settingIcon: PropTypes.bool,
        template: PropTypes.object,
    };

    static defaultProps = {
        deleteIcon: false,
        settingIcon: false,
        template: {},
    };

    onOpenReportModal = (activeTab = null) => {
        const { template } = this.props;
        this.props.showReportModal(
            template.type,
            template.subType,
            template.groupBy,
            activeTab,
            template.itemType,
            true
        );
    };

    onConfirmDelete = () => {
        switch (this.props.subType) {
            case 'FAVOURITES':
                this.updateFavourites(this.props.template);
                break;
            case 'SAVED':
            case 'INTERNAL':
                this.props.deleteReport(this.props.template);
                break;
        }
    };

    updateFavourites = template => {
        this.props.updateFavourites(template._id, {
            resourceId: this.props.account.resourceId,
            action: contains(template.asFavourite, this.props.account.resourceId) ? 'REMOVE' : 'ADD',
        });
    };

    /**
     * updateQueryParams should be fired only on SYSTEM templates
     */
    getItemIds = ({ type, itemType, itemIds: defaultItemIds }) => {
        let itemIds = MY_REPORTS.value === type ? [this.props.account.resourceId] : [];
        if (MY_REPORTS.value !== type) {
            if (hasProjectManagerReportRights(this.props.account)) {
                itemIds = contains([RESOURCE.name, UNASSIGNED.name], itemType)
                    ? this.props.resourceIdsIManage
                    : this.props.projectsIdsIManage;
            } else if (hasRole(this.props.account.resourceRoleRights, 'manageReports')) {
                itemIds = defaultItemIds;
            }
        }

        return itemIds;
    };

    getGroupIds = ({ type, itemType, groupIds: defaultGroupIds, templateType }) => {
        if (
            hasRole(this.props.account.resourceRoleRights, 'manageReports') &&
            GROUP_REPORT.value === type &&
            templateType !== CUSTOM.value
        ) {
            if (RESOURCE.name === itemType) {
                return this.props.resourceGroupIds;
            }

            return this.props.projectGroupIds;
        }

        return hasProjectManagerReportRights(this.props.account) ? [] : defaultGroupIds;
    };

    updateQueryParams = template => {
        const isSystemTemplateType = SYSTEM.value === template.templateType;
        const storageReportQuery = StorageUtil.getItem('reportQuery') || {};

        const pathSegments = this.props.location.pathname.split('/');
        const currentReportId = pathSegments[pathSegments.length - 1];

        if (this.props.isReportLoading || currentReportId === template._id) {
            return;
        }

        this.props.resetReportData();
        this.props.updateQueryParams(
            assign(
                {},
                !isSystemTemplateType
                    ? {
                          startDate: new Date(template.startDate),
                          endDate: new Date(template.endDate),
                          dateState: template.dateState,
                          periodType: template.periodType,
                      }
                    : {},
                {
                    limit: 20,
                    page: 0,
                    itemIds: this.getItemIds(template),
                    groupIds: this.getGroupIds(template),
                    type: template.type,
                    subType: template.subType,
                    xAxes: template.xAxes,
                    yAxes: template.yAxes,
                    chartColor: template.chartColor || storageReportQuery.chartColor,
                    groupBy: template.groupBy,
                    secondGroupBy: template.secondGroupBy || '',
                    secondGroupById: '',
                    columnsToLoad: template.columnsToLoad,
                    columnsToLoadSizes: {},
                    sort: template.sort,
                    split: template.split || '',
                    columnType: template.columnType || 'DYNAMIC',
                    id: '',
                    currentTemplateId: template._id,
                    itemType: template.itemType,
                    templateType: template.templateType,
                    eventTime: template.eventTime,
                    unassignedTime: template.unassignedTime,
                    displayEmptyResults: template.displayEmptyResults,
                    filters: assign({}, initialReportState.queryParams.filters, template.filters),
                    programmaticallyFilters: initialReportState.queryParams.programmaticallyFilters,
                }
            ),
            isSystemTemplateType,
            isSystemTemplateType
        );
    };

    showReportModal = (activeTab = null) => {
        const { type, subType, groupBy, itemType } = this.props.queryParams;
        this.props.showReportModal(type, subType, groupBy, activeTab, itemType, false);
    };

    render() {
        const {
            active,
            className,
            classNameActive,
            classStore,
            settingIcon,
            groupId,
            icon: Icon,
            label,
            id,
            to,
            deleteTitle,
            deleteMessage,
            style,
            template,
            deleteReportWithConfirmation,
            removeFromGroup,
            dataCy,
            duplicateReport,
        } = this.props;

        const isTemplateRemovable = isRemovable(this.props.template, this.props.account);

        return (
            <GroupItemWrapper style={style} className="position-relative" id={id}>
                <Item
                    data-cy={dataCy}
                    to={to}
                    className={classnames(className, active && classNameActive)}
                    onClick={!active ? () => this.updateQueryParams(template) : undefined}
                >
                    {isFunction(Icon) ? (
                        <Icon className={classStore.classIcon} />
                    ) : Icon ? (
                        <i className={classnames(classStore.classIcon, classStore.iconNamePrefix + Icon)} />
                    ) : (
                        <i className="icon-mask" />
                    )}
                    <span>{label}</span>
                </Item>
                {settingIcon ? (
                    <SettingIcon className="float-right" onClick={this.onOpenReportModal} />
                ) : (
                    <DotsMenuButton
                        style={{
                            position: 'absolute',
                            right: '-20px',
                            top: '20px',
                            marginTop: '2px',
                        }}
                        menuPosition={{
                            anchorOrigin: { vertical: 'top', horizontal: 'right' },
                            transformOrigin: { vertical: 'top', horizontal: 'left' },
                        }}
                        options={[
                            {
                                title: 'Duplicate report',
                                action: () => {
                                    return duplicateReport(
                                        `${template.name} (copy)`,
                                        template.visibility,
                                        template,
                                        groupId ? [groupId] : []
                                    );
                                },
                            },
                            ...(groupId
                                ? [
                                      {
                                          title: 'Remove from group',
                                          action: () => removeFromGroup(template._id, groupId),
                                      },
                                  ]
                                : []),
                            ...(isTemplateRemovable
                                ? [
                                      {
                                          title: 'Delete report...',
                                          action: () =>
                                              deleteReportWithConfirmation(
                                                  template,
                                                  deleteTitle,
                                                  deleteMessage,
                                                  groupId
                                              ),
                                      },
                                  ]
                                : []),
                        ]}
                    />
                )}
            </GroupItemWrapper>
        );
    }
}

export default MenuItem;
