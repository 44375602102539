export const getNotificationContent = (r, keyword = 'project') => {
    const pluralKeyword = `${keyword}s`;
    const created = r?.[pluralKeyword]?.created || [];
    const updated = r?.[pluralKeyword]?.updated || [];
    const createdGroups = r?.groups?.created || [];
    const updatedGroups = r?.groups?.updated || [];
    const groupsErrors = r?.groups?.errors || [];
    const resourcesErrors = r?.resources?.errors || [];
    const projectErrors = r?.projects?.errors || [];
    const hasErrors = projectErrors.length || resourcesErrors.length || groupsErrors.length;
    const wasDataAddedOrUpdated = created.length || updated.length || groupsErrors.length || createdGroups.length;

    let message = '';

    if (created.length || updated.length) {
        message = `${created.length + updated.length} ${keyword}(s)${
            createdGroups.length || updatedGroups.length
                ? ` and ${createdGroups.length + updatedGroups.length} group(s)`
                : ''
        } was created/updated`;
    } else {
        if (createdGroups.length || updatedGroups.length) {
            message = `${createdGroups.length + updatedGroups.length} group(s) was created/updated`;
        }
    }

    if (hasErrors) {
        message += `${message ? '. ' : 'All '}${projectErrors.length +
            groupsErrors.length} item(s) was ignored due to parsing error`;
    }

    return {
        message,
        type: hasErrors ? (wasDataAddedOrUpdated ? 'info' : 'danger') : 'success',
    };
};
