import React from 'react';
import { TextField as MUITextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: ({ minWidth, maxWidth }) => {
        return {
            maxWidth: maxWidth,
            minWidth: minWidth,
            '& .MuiFormHelperText-marginDense': {
                marginTop: 0,
            },
        };
    },
});

export const TextField = React.memo(props => {
    const { minWidth, maxWidth, inputProps, inputLabelProps, ...otherProps } = props;
    const classes = useStyles({ minWidth, maxWidth });

    return (
        <MUITextField InputLabelProps={inputLabelProps} InputProps={inputProps} classes={classes} {...otherProps} />
    );
});

// Same as MUI TextField
TextField.propTypes = {};

export default TextField;
