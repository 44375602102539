import React from 'react';
import PropTypes from 'prop-types';
import { Section, SectionDescription, SectionHelperText } from './styles';
import { FormControlLabel, Switch } from '@material-ui/core';
import SliderComponent from './sliderComponent';

const DisplayAvailabilityOnCellHover = ({
    schedulerFooterVisible,
    displayWeeks,
    displayMilestoneText,
    groupBookings,
    eventSmartResize,
    groupBookingsLimit,
    onChange,
    smartOnChange,
}) => (
    <Section>
        <FormControlLabel
            control={
                <Switch
                    checked={displayWeeks}
                    onChange={event => onChange({ displayWeeks: event.target.checked })}
                    name="displayWeeks"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    data-cy="btn-switch-displayWeeks"
                />
            }
            label="Display week numbers in header"
        />
        <FormControlLabel
            control={
                <Switch
                    checked={schedulerFooterVisible}
                    onChange={event => onChange({ schedulerFooterVisible: event.target.checked })}
                    name="schedulerFooterVisible"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    data-cy="btn-switch-schedulerFooterVisible"
                />
            }
            label="Display Scheduler footer"
        />
        <FormControlLabel
            control={
                <Switch
                    checked={displayMilestoneText}
                    onChange={event => onChange({ displayMilestoneText: event.target.checked })}
                    name="displayMilestoneText"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    data-cy="btn-switch-displayMilestoneText"
                />
            }
            label="Display milestone name"
        />
        <FormControlLabel
            control={
                <Switch
                    checked={eventSmartResize}
                    onChange={event => smartOnChange({ eventSmartResize: event.target.checked })}
                    name="eventSmartResize"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    data-cy="btn-switch-eventSmartResize"
                />
            }
            label="Enable smart moving"
        />
        <SectionHelperText>
            When moving / dragging a booking, it will jump over the non working days that are displayed. Booking
            duration is maintained.
        </SectionHelperText>
        <FormControlLabel
            control={
                <Switch
                    checked={groupBookings}
                    onChange={event => onChange({ groupBookings: event.target.checked })}
                    name="groupBookings"
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    data-cy="btn-switch-groupBookings"
                />
            }
            label="Group overlapping bookings"
        />
        <SectionHelperText>
            In the settings you can choose how many bookings need to overlap so they auto group on page load.
        </SectionHelperText>
        {groupBookings && (
            <div className="mt-4">
                <SliderComponent
                    name="groupBookingsLimit"
                    onChange={onChange}
                    min={1}
                    max={25}
                    step={1}
                    value={groupBookingsLimit}
                    label="Limit"
                />
                <SectionDescription>On Page load the number of overlapping bookings to be grouped.</SectionDescription>
            </div>
        )}
    </Section>
);

DisplayAvailabilityOnCellHover.propTypes = {
    groupBookings: PropTypes.bool.isRequired,
    eventSmartResize: PropTypes.bool.isRequired,
    displayMilestoneText: PropTypes.bool.isRequired,
    schedulerFooterVisible: PropTypes.bool.isRequired,
    displayWeeks: PropTypes.bool.isRequired,
    groupBookingsLimit: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    smartOnChange: PropTypes.func.isRequired,
};

export default DisplayAvailabilityOnCellHover;
