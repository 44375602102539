import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    AUTH_METHOD_PASSWORD,
    AUTH_METHOD_GOOGLE,
    AUTH_METHOD_SAML,
    GOOGLE_ONLY,
    SAML_ONLY,
    REGULAR_AND_GOOGLE_ONLY,
} from 'enums/authEnum';
import { Badge } from './styles';

const getText = (authenticationMethod, companyAuthMode) => {
    const isLoggedWithPassword = AUTH_METHOD_PASSWORD === authenticationMethod;
    const isSSOAuthentication = AUTH_METHOD_SAML === authenticationMethod;
    const isGoogleAuthentication = AUTH_METHOD_GOOGLE === authenticationMethod;

    switch (true) {
        case (isLoggedWithPassword || isSSOAuthentication) && companyAuthMode && GOOGLE_ONLY === companyAuthMode:
            return 'Google Login Required';
        case (isLoggedWithPassword || isGoogleAuthentication) && companyAuthMode && SAML_ONLY === companyAuthMode:
            return 'SSO (SAML) Required';
        case isSSOAuthentication && companyAuthMode && REGULAR_AND_GOOGLE_ONLY === companyAuthMode:
            return 'Google or password Login Required';
        default:
            return '';
    }
};

const AuthModeRequirements = ({ companyAuthenticationSettings, className }) => {
    const authenticationMethod = useSelector(state => state.account.authenticationMethod);
    const text = getText(authenticationMethod, companyAuthenticationSettings.mode);

    return text ? <Badge className={className}>{text}</Badge> : null;
};

AuthModeRequirements.propTypes = {
    companyAuthenticationSettings: PropTypes.shape({
        mode: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
};

export default AuthModeRequirements;
