/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-body-style */
import { each, isArray, filter, keys, isUndefined, isNull } from 'underscore';
import { join, reduce } from 'lodash';

const newLine = '\r\n';

const getDeepValue = (obj, path) =>
    reduce(
        path.split('.'),
        (item, key) => (item && !isUndefined(item[key]) && !isNull(item[key]) ? item[key] : ''),
        obj
    );

export const csv = (columns, data, separator = ',') => {
    let columnOrder;
    const content = [];
    const column = [];

    if (columns) {
        columnOrder = columns.map(v => {
            if ('string' === typeof v) {
                return v;
            }

            column.push('undefined' !== typeof v.displayName ? v.displayName : v.id);

            return v.id;
        });

        if (0 < column.length) {
            content.push(join(column, separator));
        }
    } else {
        columnOrder = [];
        each(data, v => {
            if (!isArray(v)) {
                columnOrder = columnOrder.concat(keys(v));
            }
        });
        if (0 < columnOrder.length) {
            columnOrder = filter(columnOrder, (value, index, self) => self.indexOf(value) === index);
            content.push(join(columnOrder, separator));
        }
    }

    if (isArray(data)) {
        data = data.map(v => {
            return columnOrder.map(k => {
                let value = getDeepValue(v, k);

                return value.toString();
            });
        });
        each(data, v => {
            content.push(join(v, separator));
        });
    }

    return join(content, newLine);
};
