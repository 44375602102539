import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';
import { DateRangeButtons } from './styles';
import { capitalizeFirstLetter } from 'utils/formatingUtil';
import { createCySelector } from '../../utils/cypressUtil';

const operations = {
    this: 'this',
    last: 'custom-sub',
    next: 'custom-add',
};

const periods = {
    day: 'days',
    week: 'weeks',
    month: 'months',
    quarter: 'quarters',
    year: 'years',
};

const typeOfPeriods = {
    day: 'day',
    days: 'day',
    week: 'week',
    weeks: 'week',
    month: 'month',
    months: 'month',
    quarter: 'quarter',
    quarters: 'quarter',
    year: 'year',
    years: 'year',
};

const configNotValid = buttonConfig => {
    console.error(`config date not valid for ${JSON.stringify(buttonConfig)}, see docs`);
    return null;
};

class Buttons extends PureComponent {
    static propTypes = {
        dateState: PropTypes.string,
        periodType: PropTypes.string,
        weekType: PropTypes.string,
        customDatesPicker: PropTypes.array,
        dataCy: PropTypes.string,
    };

    updateDates = (type, durationObject, durationType, periodToDisplay) => {
        const startDate = moment();
        const duration = moment.duration(durationObject);
        const periodType = durationType.replace('iso', '').toLowerCase();

        switch (type) {
            case 'custom-sub':
                this.props.onClick({
                    startDate: moment()
                        .subtract(duration)
                        .toDate(),
                    endDate: moment().toDate(),
                    dateState: 'Last',
                    periodType: periodToDisplay || periodType,
                });
                break;
            case 'sub':
                this.props.onClick({
                    startDate: startDate
                        .subtract(duration)
                        .startOf(durationType)
                        .toDate(),
                    endDate: startDate.endOf(durationType).toDate(),
                    dateState: 'Last',
                    periodType: periodToDisplay || periodType,
                });
                break;
            case 'custom-add':
                this.props.onClick({
                    endDate: moment()
                        .add(duration)
                        .toDate(),
                    startDate: moment().toDate(),
                    dateState: 'Next',
                    periodType: periodToDisplay || periodType,
                });
                break;
            case 'add':
                this.props.onClick({
                    endDate: startDate
                        .add(duration)
                        .endOf(durationType)
                        .toDate(),
                    startDate: startDate.startOf(durationType).toDate(),
                    dateState: 'Next',
                    periodType: periodToDisplay || periodType,
                });
                break;

            case 'this':
                this.props.onClick({
                    startDate: startDate.startOf(durationType).toDate(),
                    endDate: startDate.endOf(durationType).toDate(),
                    dateState: capitalizeFirstLetter(type),
                    periodType: periodToDisplay || periodType,
                });
                break;
        }
    };

    customButtonsListJSX(customDatesPicker) {
        return (
            <DropdownMenu>
                {(customDatesPicker || []).map((buttonConfig, index) => {
                    const key = `custom-date-${index}`;
                    if (buttonConfig === 'divider') {
                        return <DropdownItem key={key} divider />;
                    }

                    const splitted = buttonConfig.split('-');
                    if (splitted.length >= 2) {
                        let positionOperator = splitted[0];
                        let numberOperator;
                        let periodOperator;
                        if (splitted.length === 2) {
                            numberOperator = 1;
                            periodOperator = splitted[1];
                        } else if (splitted.length >= 3) {
                            numberOperator = parseInt(splitted[1]);
                            periodOperator = splitted[2];
                        }

                        if (
                            !operations[positionOperator] ||
                            !typeOfPeriods[periodOperator] ||
                            typeof numberOperator !== 'number'
                        ) {
                            return configNotValid(buttonConfig);
                        }

                        const text = capitalizeFirstLetter(splitted.join(' '));
                        const isThis = positionOperator === 'this';
                        const params = [
                            operations[positionOperator],
                            isThis
                                ? null
                                : {
                                      [periods[typeOfPeriods[periodOperator]]]: numberOperator,
                                  },
                            periodOperator,
                            `${isThis || numberOperator === 1 ? '' : `${numberOperator} `}${periodOperator}`,
                        ];

                        return (
                            <DropdownItem
                                key={key}
                                onClick={() => this.updateDates(params[0], params[1], params[2], params[3])}
                                data-cy={`range-option-${createCySelector(text)}`}
                            >
                                {text}
                            </DropdownItem>
                        );
                    }
                    return configNotValid(buttonConfig);
                })}
            </DropdownMenu>
        );
    }

    regularButtonsListJSX() {
        return (
            <DropdownMenu>
                <DropdownItem
                    data-cy="range-option-this-week"
                    onClick={() => this.updateDates('this', null, this.props.weekType)}
                >
                    This Week
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-last-week"
                    onClick={() => this.updateDates('sub', { week: 1 }, this.props.weekType)}
                >
                    Last Week
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-next-week"
                    onClick={() => this.updateDates('add', { week: 1 }, this.props.weekType)}
                >
                    Next Week
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem data-cy="range-option-this-month" onClick={() => this.updateDates('this', null, 'month')}>
                    This Month
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-last-month"
                    onClick={() => this.updateDates('sub', { months: 1 }, 'month')}
                >
                    Last Month
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-next-month"
                    onClick={() => this.updateDates('add', { months: 1 }, 'month')}
                >
                    Next Month
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                    data-cy="range-option-this-quarter"
                    onClick={() => this.updateDates('this', null, 'quarter')}
                >
                    This Quarter
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-last-quarter"
                    onClick={() => this.updateDates('sub', { quarters: 1 }, 'quarter')}
                >
                    Last Quarter
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-next-quarter"
                    onClick={() => this.updateDates('add', { quarters: 1 }, 'quarter')}
                >
                    Next Quarter
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem data-cy="range-option-this-year" onClick={() => this.updateDates('this', null, 'year')}>
                    This Year
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-last-year"
                    onClick={() => this.updateDates('sub', { years: 1 }, 'year')}
                >
                    Last Year
                </DropdownItem>
                <DropdownItem
                    data-cy="range-option-next-year"
                    onClick={() => this.updateDates('add', { years: 1 }, 'year')}
                >
                    Next Year
                </DropdownItem>
            </DropdownMenu>
        );
    }

    render() {
        return (
            <DateRangeButtons>
                <UncontrolledDropdown>
                    <DropdownToggle data-cy={this.props.dataCy} caret className="remove-bs-button-style">
                        {this.props.dateState} {capitalizeFirstLetter(this.props.periodType)}
                        {'custom' === this.props.periodType ? ' Range' : ''}
                    </DropdownToggle>
                    {!this.props.customDatesPicker && this.regularButtonsListJSX()}
                    {this.props.customDatesPicker && this.customButtonsListJSX(this.props.customDatesPicker)}
                </UncontrolledDropdown>
            </DateRangeButtons>
        );
    }
}

export default Buttons;
