import React from 'react';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import { displayValue, isHeaderDisabled } from 'modules/report/utils/columnHelper';
import TextsFilterBox from 'modules/report/containers/tableFilters/textsFilterBox';
import { contains } from 'underscore';
import { Header } from './../styles';

/**
 * @param {array} columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = columnsToLoad => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Day
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'day',
        sortable: false,
        accessor: row => displayValue(row.day),
        show: contains(columnsToLoad, 'day'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Week
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'week',
        sortable: false,
        accessor: row => displayValue(row.week),
        show: contains(columnsToLoad, 'week'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Month
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'month',
        sortable: false,
        accessor: row => displayValue(row.month),
        show: contains(columnsToLoad, 'month'),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Year
                    </Sorting>
                    <TextsFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'year',
        sortable: false,
        accessor: row => displayValue(row.year),
        show: contains(columnsToLoad, 'year'),
    },
];
