import React, { Component } from 'react';
import isEqual from 'react-fast-compare';
import { keys, find } from 'underscore';
import { isDateGrouping } from 'modules/report/enums/groupingTypeEnum';
import ReportLevel4Breadcrumb from './../reportLevel4Breadcrumb';

class BaseDetailBreadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = { grouping: {}, cache: undefined };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            nextProps.queryParams.groupBy !== this.props.queryParams.groupBy ||
            nextProps.projectCustomFields.length !== this.props.projectCustomFields.length ||
            nextProps.resourceCustomFields.length !== this.props.resourceCustomFields.length ||
            (keys(this.state.grouping).length &&
                this.state.grouping.getItems &&
                !isEqual(this.state.grouping.getItems(nextProps), this.state.grouping.getItems(this.props))) ||
            !isEqual(nextState.grouping, this.state.grouping)
        );
    }

    getData = () => {
        const { getItems, requestItems } = this.state.grouping;

        if (keys(this.state.grouping).length && requestItems && !getItems(this.props).length) {
            requestItems(this.props);
        } else {
            if (
                !this.props.isCustomFieldLoading &&
                (!this.props.resourceCustomFields.length || !this.props.projectCustomFields.length)
            ) {
                this.props.getAllCustomFields();
            }
        }
    };

    matchItem = () => {};

    render() {
        const currentItem =
            keys(this.state.grouping).length &&
            this.state.grouping.getItems &&
            find(this.state.grouping.getItems(this.props), this.matchItem);
        const name = currentItem && currentItem[this.state.grouping.additionalProps.fields.label];
        const additionalName = currentItem && currentItem[this.state.grouping.additionalProps.fields.additional];
        let displayedName = additionalName ? `${name} ${additionalName}` : name;
        let customFieldGrouping = this.state.cache && this.state.cache.groupBy && this.state.cache.groupBy.split('_');
        let customField;
        let choice;

        if (
            this.state.cache &&
            this.state.cache.groupBy &&
            -1 !== this.state.cache.groupBy.indexOf('project_cf') &&
            !displayedName
        ) {
            customField = find(
                this.props.projectCustomFields,
                customField => customField._id === customFieldGrouping[2]
            );
        } else if (
            this.state.cache &&
            this.state.cache.groupBy &&
            -1 !== this.state.cache.groupBy.indexOf('resource_cf') &&
            !displayedName
        ) {
            customField = find(
                this.props.resourceCustomFields,
                customField => customField._id === customFieldGrouping[2]
            );
        }
        if (customField) {
            choice = find(customField.choices, choice => choice._id === this.props.match.params.id);
        }

        if (this.state.cache && isDateGrouping(this.state.cache.groupBy)) {
            return <ReportLevel4Breadcrumb />;
        }

        return <span>{displayedName || (choice && choice.value)}</span>;
    }
}

export default BaseDetailBreadcrumb;
