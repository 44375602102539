import React from 'react';
import { Divider, Icon, Layout, Typography } from '@hub-mono/ui';
import styled from 'styled-components';

const StyledLayout = styled(Layout)`
    padding: ${props => props.theme.designSpacing['--Spacing-100']}px 0px;
`;
const NoteContainer = styled.div`
    padding-top: ${props => props.theme.designSpacing['--Spacing-100']}px;
`;

const RequestNotes = ({ approvalInfo }) => {
    return (
        <div>
            {approvalInfo && approvalInfo.requesterNote ? (
                <NoteContainer>
                    <Divider />
                    <StyledLayout vAlign="center" gap={2}>
                        <Icon name="description" size={15} />
                        <Typography variant="label" size="medium" prominent color="neutral40">
                            {' '}
                            Request Note:{' '}
                        </Typography>
                        <Typography variant="body" size="small" color="neutral40">
                            {approvalInfo.requesterNote.replace(/</g, '&lt;').replace(/>/g, '&gt;')}
                        </Typography>
                    </StyledLayout>
                </NoteContainer>
            ) : (
                ''
            )}
            {approvalInfo && approvalInfo.approverNote && typeof approvalInfo.approverNote === 'string' ? (
                <NoteContainer>
                    <Divider />
                    <StyledLayout>
                        <Icon name="description" size={15} />
                        <Typography variant="label" size="medium" prominent color="neutral40">
                            {' '}
                            Approver Note:{' '}
                        </Typography>
                        <Typography variant="body" size="small" color="neutral40">
                            {approvalInfo.approverNote?.replace(/</g, '&lt;').replace(/>/g, '&gt;')}
                        </Typography>
                    </StyledLayout>
                </NoteContainer>
            ) : (
                ''
            )}
        </div>
    );
};

export default RequestNotes;
