import { includes, map } from 'lodash';
import { ACTIVE, EVENT } from 'enums/criteriaEnum';
import { STATUS_ACTIVE, TYPE_EVENT, TYPE_REGULAR } from 'enums/projectEnum';
import { getCriteriaByProjectStatus } from 'utils/projectStatusMapper';

export const getUpdatedProjectGroups = (state, projectGroupsAndProjectArr) => {
    const updatedProjectGroups = {};
    map(state.projectGroups, group =>
        projectGroupsAndProjectArr.forEach(({ projectGroups, project }) => {
            if (
                (projectGroups?.length && includes(projectGroups, group._id)) ||
                (ACTIVE === group.criteria && project.type === TYPE_REGULAR.value) ||
                (EVENT === group.criteria && project.type === TYPE_EVENT.value) ||
                (!projectGroups?.length &&
                    project.status !== STATUS_ACTIVE.value &&
                    getCriteriaByProjectStatus(project.status) === group.criteria)
            ) {
                if (!updatedProjectGroups[group._id]) {
                    updatedProjectGroups[group._id] = {
                        ...group,
                        projects: [...group.projects, project._id],
                        count: group.count + 1,
                    };
                } else {
                    updatedProjectGroups[group._id] = {
                        ...updatedProjectGroups[group._id],
                        projects: [...updatedProjectGroups[group._id].projects, project._id],
                        count: updatedProjectGroups[group._id].count + 1,
                    };
                }
            } else {
                updatedProjectGroups[group._id] = group;
            }
        })
    );
    return Object.values(updatedProjectGroups).filter(v => !!v);
};
