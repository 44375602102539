import { values } from 'lodash';

export const ALL = {
    value: 'ALL',
    name: 'All',
};

export const ANY = {
    value: 'ANY',
    name: 'Any',
};

const filterRelations = { ALL, ANY };

export const filterRelationsArray = values(filterRelations);
