import React from 'react';
import PropTypes from 'prop-types';
import { FormText, Button, Col, Row } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { getCurrencies } from 'enums/currencyEnum';
import { dropdownField, inputGroupTextField, textField } from 'shared/formFields';
import { formSchema } from 'forms/rateForm';
import { validateSchema } from 'utils/schemaUtil';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-basis: 100%;
`;

const CreateRate = props => {
    const onSubmit = () => {
        props.submitForm();
        if (props.valid && props.onDismiss) {
            setTimeout(props.onDismiss);
        }
    };

    return (
        <>
            <p className="title">Add New Rate:</p>
            <Wrapper>
                <Row className="flex-basis-100" style={{ flexBasis: '100%' }}>
                    <Col className="pr-0">
                        <Field
                            inline
                            width="col-md-12"
                            name="currency"
                            component={dropdownField}
                            filter
                            valueField="value"
                            textField={item => (Object.keys(item).length ? item.name : null)}
                            data={getCurrencies()}
                        />
                    </Col>
                    <Col className="pr-0">
                        <Field
                            width="col-md-12"
                            name="rate"
                            icon="/hr"
                            placeholder="amount"
                            component={inputGroupTextField}
                        />
                    </Col>
                    <Col className="pr-0">
                        <Field width="col-md-12" name="label" placeholder="name this rate" component={textField} />
                    </Col>
                    <Col className="pr-0" styl={{ paddingTop: '5px' }}>
                        <Button type="button" onClick={onSubmit} className="rounded-0 mr-2">
                            <i className="fa fa-check" />
                        </Button>
                        <Button type="button" className="rounded-0" color="danger" onClick={props.onDismiss}>
                            <i className="fa fa-times" />
                        </Button>
                    </Col>
                </Row>
                <FormText>This rate will be added to your rate card under settings.</FormText>
            </Wrapper>
        </>
    );
};

CreateRate.propTypes = {
    formName: PropTypes.string,
    fieldName: PropTypes.string,
    onDismiss: PropTypes.func,
};

CreateRate.defaultProps = {
    formName: '',
    fieldName: '',
    onDismiss: null,
};

export default reduxForm({
    validate: validateSchema(formSchema),
})(CreateRate);
