import { axiosInstance } from './axiosInstance';
import { stringify } from 'services/queryString';
import { getOTPHeader } from './common';

export const getCompany = companyId =>
    axiosInstance
        .get(`company/${companyId}/basic`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getCompanyBillingInfoRequest = () =>
    axiosInstance
        .get('company/billingInfo')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getCompanies = () =>
    axiosInstance
        .get(`company`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateCompanyRequest = (data) => {
    const { otp, ...body } = data ?? {};

    return axiosInstance
        .post(`company`, body, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const updateSettingsRequest = (companyId, data) => {
    const { otp, ...body } = data ?? {};

    return axiosInstance
        .patch(`company/${companyId}/settings`, body, getOTPHeader(otp))
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });
};

export const getCompanyApprovers = companyId =>
    axiosInstance
        .get(`company/${companyId}/approvers`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createHolidayRequest = (calendarId, data) =>
    axiosInstance
        .post(`/company/calendar/${calendarId}/holiday`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteHolidayRequest = (calendarId, holidayId) =>
    axiosInstance
        .delete(`/company/calendar/${calendarId}/holiday/${holidayId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateHolidayRequest = (calendarId, holidayId, data) =>
    axiosInstance
        .put(`/company/calendar/${calendarId}/holiday/${holidayId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getCalendarsRequest = () =>
    axiosInstance
        .get('/company/calendar')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createCalendarRequest = data =>
    axiosInstance
        .post('/company/calendar', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateCalendarsRequest = data =>
    axiosInstance
        .put('/company/calendars', data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const importCountryHolidaysRequest = (calendarId, countryId, typesOfHolidays, holidaysYear) =>
    axiosInstance
        .get(
            `/company/importCountryHolidays/${calendarId}/${countryId}/${holidaysYear}?${stringify({
                typesOfHolidays: typesOfHolidays,
            })}`
        )
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const validateSubDomainFree = domain =>
    axiosInstance
        .get(`/accountOpenRoute/checkDomainName/${domain}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
