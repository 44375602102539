import { CUSTOM } from 'modules/report/enums/templateTypeEnum';
import { contains } from 'underscore';
import { isOwner } from 'utils/rightsUtil';

export const isRemovable = (template, account, templateType) => {
    const isCustomTemplate = CUSTOM.value === template.templateType;
    const isFavourite =
        contains(template.asFavourite, account.resourceId) && 'FAVOURITES' === (template.type || templateType);

    return (
        (isCustomTemplate && template.owner === account.resourceId) ||
        isFavourite ||
        (isCustomTemplate && isOwner(account))
    );
};
