import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { head } from 'lodash';
import { Menu, ClickAwayListener } from '@material-ui/core';
import { usePressEscape } from 'hooks';
import ScheduleDropDown from 'modules/scheduler/components/dropdowns/schedule';
import { useStyles } from './../styles';

const Schedule = ({ top, left, schedulerRef, close, viewObject, viewData }) => {
    const node = useRef();
    const mountedRef = useRef(false);
    usePressEscape(close);
    const classes = useStyles();

    useEffect(() => {
        const refTimeout = setTimeout(() => {
            mountedRef.current = true;
        }, 0);

        return () => {
            clearTimeout(refTimeout);
            mountedRef.current = false;
        };
    }, []);

    const handler = event => {
        const datepicker = document.getElementsByClassName('MuiPickersBasePicker-container');
        if (
            !mountedRef.current ||
            node.current.contains(event.target) ||
            (datepicker.length && head(datepicker).contains(event.target))
        ) {
            return;
        }
        close();
    };

    return (
        <Menu
            data-cy="scheduler-menu"
            style={{ position: 'initial' }}
            hideBackdrop
            disableScrollLock
            classes={classes}
            open={true}
            ref={node}
            anchorReference="anchorPosition"
            anchorPosition={{ top, left }}
        >
            <ClickAwayListener onClickAway={handler}>
                <ScheduleDropDown
                    showRequestResourceOption={true}
                    showRequestVacationOption={true}
                    schedulerRef={schedulerRef}
                    viewObject={viewObject}
                    viewData={viewData}
                    onClose={close}
                />
            </ClickAwayListener>
        </Menu>
    );
};

Schedule.propTypes = {
    schedulerRef: PropTypes.object.isRequired,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
};

export default Schedule;
