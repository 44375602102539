import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHasRights } from 'hooks';
import { getFinancialData } from 'actions/schedulerActions';
import { useAccountPreferences, useGroup } from 'modules/scheduler/hooks';

export const useFinancialData = (params, { isSingleProjectView, isProjectGroupView }) => {
    const dispatch = useDispatch();
    const {
        gridPreferences: { singleProjectView },
    } = useAccountPreferences();
    const resourceId = useSelector(state => state.account.resourceId);
    const { hasRightToSeeFinancialData } = useHasRights([
        {
            rights: ['settingProjectBudget'],
            rule: 'one',
            name: 'hasRightToSeeFinancialData',
        },
    ]);
    const { projectGroup } = useGroup(params);
    const storeProjectGroupId = projectGroup?._id;
    const { projectId, projectGroupId } = params;

    return useCallback(
        forceUpdate => {
            if (
                hasRightToSeeFinancialData &&
                (forceUpdate || singleProjectView.informationPanelDisplayed) &&
                storeProjectGroupId
            ) {
                dispatch(
                    getFinancialData.request({
                        projectId,
                        projectGroupId,
                        resourceId,
                        singleProjectView,
                        isSingleProjectView,
                        isProjectGroupView,
                    })
                );
            }
        },
        [
            hasRightToSeeFinancialData,
            singleProjectView,
            storeProjectGroupId,
            dispatch,
            projectId,
            projectGroupId,
            resourceId,
            isSingleProjectView,
            isProjectGroupView,
        ]
    );
};
