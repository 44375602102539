export const COLORS = [
    '#000',
    '#404040',
    '#7f7f7f',
    '#bfbfbf',
    '#fff',
    '#c00000',
    '#349E71',
    '#CF921A',
    '#DB6060',
    '#7A75C9',
    '#696C7A',
    '#ff0000',
    '#ffc000',
    '#ffff00',
    '#92d050',
    '#00b050',
    '#00b0f0',
    '#0070c0',
    '#002060',
    '#7030a0',
    '#EFC94C',
    '#F59C31',
    '#98CC96',
    '#17607D',
    '#F06C6C',
    '#CCCCCC',
    '#3F5666',
    '#EA6045',
    '#FEB41C',
    '#45B29D',
    '#c0504d',
    '#E8A386',
    '#4f81bd',
    '#52CFEA',
    '#9bbb59',
    '#8CD1B3',
    '#F2C572',
    '#81A489',
    '#E74C3C',
    '#90afb8',
    '#F2A054',
    '#C04D31',
    '#3498DB',
    '#C5F7D7',
    '#AFEFC8',
    '#99E7B9',
    '#83DFAA',
    '#6DD79B',
    '#57CF8C',
    '#41C77D',
    '#2BBF6E',
    '#15B75F',
    '#00B050',
    '#C7F0F9',
    '#B4E4F4',
    '#A0D8EE',
    '#8DCCE9',
    '#7AC1E3',
    '#66B5DE',
    '#53A9D8',
    '#3F9DD3',
    '#2C91CD',
    '#1986C8',
    '#F9C7CC',
    '#F4B4BB',
    '#EEA0A9',
    '#E98D98',
    '#E37A87',
    '#DE6675',
    '#D85364',
    '#D33F52',
    '#CD2C41',
    '#C81930',
];

export const DEFAULT_CATEGORY_COLOR = '#349E71';
