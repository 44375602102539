import React from 'react';
import PropTypes from 'prop-types';
import { Section, SectionHeader } from './styles';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

const GreenRadio = withStyles({
    root: {
        '&$checked': {
            color: green[600],
        },
        padding: '3px',
    },
    checked: {},
})(props => {
    const { dataCy, ...restOfProps } = props;
    return <Radio color="default" inputProps={{ 'data-cy': dataCy }} {...restOfProps} />;
});

const DefaultScale = ({ defaultScale, onChange }) => (
    <Section>
        <SectionHeader>Default Scheduler Scale</SectionHeader>
        <FormControl component="fieldset" className="ml-1 mt-2">
            <RadioGroup
                aria-label="defaultScale"
                name="defaultScale"
                value={defaultScale}
                onChange={event => onChange({ defaultScale: event.target.value })}
            >
                <FormControlLabel
                    value="CellDuration"
                    control={<GreenRadio dataCy="my-pref-defaultScale-hour" />}
                    className="mb-2"
                    label="Hour"
                />
                <FormControlLabel
                    value="Day"
                    control={<GreenRadio dataCy="my-pref-defaultScale-day" />}
                    className="mb-2"
                    label="Day"
                />
                <FormControlLabel
                    value="Week"
                    control={<GreenRadio dataCy="my-pref-defaultScale-week" />}
                    className="mb-2"
                    label="Week"
                />
                <FormControlLabel
                    value="Month"
                    control={<GreenRadio dataCy="my-pref-defaultScale-month" />}
                    className="mb-2"
                    label="Month"
                />
            </RadioGroup>
        </FormControl>
    </Section>
);

DefaultScale.propTypes = {
    mode: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default DefaultScale;
