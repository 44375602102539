import { values } from 'lodash';

export const XS = {
    eventHeight: 10,
    eventHeightMode: 'XS',
};

export const S = {
    eventHeight: 20,
    eventHeightMode: 'S',
};

export const M = {
    eventHeight: 32,
    eventHeightMode: 'M',
};

export const L = {
    eventHeight: 60,
    eventHeightMode: 'L',
};

export const XL = {
    eventHeight: 100,
    eventHeightMode: 'XL',
};

export const eventHeights = { XS, S, M, L, XL };

export const eventHeightsArray = values(eventHeights);
