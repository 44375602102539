import { CUSTOM_FIELDS, TIME_SHEET, TOIL } from 'enums/extensionShortIdEnum';
import budgetSpecific from 'modules/report/columns/projectBudgetSpecific/budgetColumnIds';
import reportedBudgetSpecific from 'modules/report/columns/projectBudgetSpecific/reportedBudgetColumnIds';
import scheduledBudgetSpecific from 'modules/report/columns/projectBudgetSpecific/scheduledBudgetColumnIds';
import projectText from 'modules/report/columns/projectText/columnIds';
import { filter } from 'lodash';
import { hasExtensionsInstalled } from 'utils/extensionUtil';
import { hasAllRoles } from 'utils/rightsUtil';
import scheduled from './time/scheduledColumnIds';
import reported from './time/reportedColumnIds';
import specificScheduled from './time/specificScheduledIds';
import specificReported from './time/specificReportedIds';
import burn from './time/burnColumnIds';
import mixedTime from './time/mixedColumnIds';
import scheduledSpend from './spend/scheduledSpendColumnIds';
import reportedSpend from './spend/reportedSpendColumnIds';
import mixedSpend from './spend/mixedSpendColumnIds';
import specificScheduledSpend from './spend/specificScheduledIds';
import specificReportedSpend from './spend/specificReportedIds';
import capacity from './capacity/capacityColumnIds';
import capacityMixed from './capacity/mixedColumnIds';
import capacityReported from './capacity/reportedColumnIds';
import capacityScheduled from './capacity/scheduledColumnIds';
import resourceText from './resourceText/columnIds';
import text from './text/columnIds';
import customFieldColumnIds from 'modules/report/columns/customFields/columnIds';
import common from 'modules/report/columns/common/columnIds';
import billingCommon from 'modules/report/columns/common/billingColumnIds';
import date from 'modules/report/columns/date/columnIds';
import resourceToil from 'modules/report/columns/time/resourceToilColumnIds';

/**
 * @param {string}  itemType
 * @param {array}   customFields
 * @param {array}   extensions
 * @param {array}   accountRoleRights
 * @param {object}  dateState
 * @param {string}  groupBy
 *
 * @returns {*[]}
 */
export default (itemType, customFields, extensions, accountRoleRights, dateState, groupBy) => {
    let columnIds = [
        ...text(itemType),
        ...resourceText(),
        ...projectText(),
        ...scheduled(dateState),
        ...mixedTime(dateState),
        ...specificScheduled(dateState),
        ...capacity(dateState),
        ...capacityScheduled(dateState),
        ...common(),
        ...date()
    ];

    if (hasAllRoles(accountRoleRights, ['showBilling'])) {
        columnIds = columnIds.concat(
            scheduledSpend(dateState),
            specificScheduledSpend(dateState),
            scheduledBudgetSpecific(),
            budgetSpecific(),
            billingCommon()
        );
    }

    if (hasExtensionsInstalled(extensions, [TIME_SHEET])) {
        columnIds = columnIds.concat(
            capacityReported(dateState),
            capacityMixed(dateState),
            reported(dateState),
            specificReported(dateState),
            burn(dateState)
        );
        if (hasAllRoles(accountRoleRights, ['showBilling'])) {
            columnIds = columnIds.concat(reportedBudgetSpecific(), mixedSpend(dateState), reportedSpend(dateState), specificReportedSpend(dateState));
        }
    }

    if (hasExtensionsInstalled(extensions, [CUSTOM_FIELDS])) {
        columnIds = columnIds.concat(customFieldColumnIds(customFields)); // resource_cf for unassigned item type
    }

    if (hasExtensionsInstalled(extensions, [TOIL])) {
        columnIds = columnIds.concat(resourceToil())
    }

    if (groupBy) {
        columnIds = columnIds.filter(columnId => 'date' !== columnId.id);
    }

    return filter(columnIds, column => !column.extensions || hasExtensionsInstalled(extensions, column.extensions));
};
