import { RESOURCE } from '../../enums/builderTypeEnum';
import React, { useMemo } from 'react';
import { makeGetFilteredCustomFields } from '../../../../selectors/customField';
import { useSelector } from 'react-redux';
import {
    selectAppliedResourceCustomFieldsFilter,
    selectAppliedResourceIsPmFilter,
    selectAppliedResourceRolesFilter,
    selectAppliedResourcesFilter,
    selectAppliedResourceStatusesFilter,
    selectAppliedResourceTagsFilter,
    selectSchedulerResourceCustomFieldsFilter,
    selectSchedulerResourceIsPmFilter,
    selectSchedulerResourceRolesFilter,
    selectSchedulerResourcesFilter,
    selectSchedulerResourceStatusesFilter,
    selectSchedulerResourceTagsFilter,
} from '../../../../selectors/scheduler';
import {
    AccordionHeader,
    AccordionHeaderText,
    RESOURCES_SIDE_TEXT_RGB_COLOR,
    useCommonDropdownFiltersStyles,
} from './styles';
import { Grid, Typography } from '@material-ui/core';
import { FilterItem } from './FilterItem';
import useFilterData from '../../../../shared/builder/filters/hooks/useFilterData';
import { useItemsAndGroups, useRouteParams } from '../../../../hooks';
import { getViewObject } from '../../utils/schedulerUtil';
import { LEGACY, PARENT } from '../../enums/viewModeEnum';
import { useSchedulerRef } from '../../../../shared/hooks';
import { useAccountPreferences } from '../../hooks';
import { arrayToObjectByKey } from '../../../../utils/mappingUtil';
import { filterWithDiacriticsEscape } from 'utils/search';

const ResourcesRowFilters = () => {
    const filterData = useFilterData();
    const params = useRouteParams();
    const viewObject = getViewObject(params);
    const { schedulerRef } = useSchedulerRef();
    const { gridPreferences } = useAccountPreferences(schedulerRef, viewObject);

    const classes = useCommonDropdownFiltersStyles({
        sideTextBgColor: RESOURCES_SIDE_TEXT_RGB_COLOR,
    });

    const { items: resources } = useItemsAndGroups(RESOURCE.name);

    const resourceCustomFieldSelector = useMemo(
        () => makeGetFilteredCustomFields('CHOICES_FILTER_GRID', 'resourceCustomFields'),
        []
    );

    const resourceCustomFields = useSelector(resourceCustomFieldSelector);

    const shouldDisplayResourcesUW =
        viewObject.isGroupView ||
        (viewObject.isSingleProjectView && gridPreferences.mode === PARENT.value) ||
        gridPreferences.mode === LEGACY.value;

    const resourceRowFiltersConfig = useMemo(
        () => [
            ...(shouldDisplayResourcesUW
                ? [
                      {
                          id: 1,
                          title: 'Resources & unassigned',
                          filterName: 'resources',
                          icon: 'PeopleAltOutlined',
                          withSelectAll: true,
                          withSearch: true,
                          items: resources,
                          selectedSelector: selectAppliedResourcesFilter,
                          filterSelector: selectSchedulerResourcesFilter,
                          deletedItemsMapper: (items, selectedItems) => {
                              const itemsMapped = arrayToObjectByKey(items, '_id');
                              const removed = Object.keys(selectedItems).filter(item => {
                                  return !itemsMapped[item];
                              });

                              return removed.map(item => ({
                                  _id: item,
                                  name: 'Deleted resource',
                                  deleted: true,
                              }));
                          },
                          searchPredicate: (item, filterText) => {
                              return filterWithDiacriticsEscape(item, filterText, 'name');
                          },
                          itemLabelRenderer: item => item.name,
                          getItemValue: item => item._id,
                      },
                  ]
                : []),
            {
                id: 2,
                title: 'Resource custom fields',
                filterName: 'resourceCustomFields',
                icon: 'TuneOutlined',
                items: resourceCustomFields,
                withSelectAll: true,
                withSearch: true,
                nested: true,
                withRelationToggle: true,
                withEqualityToggle: true,
                selectedSelector: selectAppliedResourceCustomFieldsFilter,
                filterSelector: selectSchedulerResourceCustomFieldsFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'value');
                },
                itemLabelRenderer: item => item.label,
                getItemValue: item => item._id,
                childItemLabelRenderer: item => item.value,
                getChildItemValue: item => item._id,
                childrenSelector: item => item.choices || [],
                deletedItemsMapper: (items, selectedItems) => {
                    if (selectedItems['VIRTUAL_DELETED']) {
                        return [
                            {
                                deleted: true,
                                _id: 'VIRTUAL_DELETED',
                                label: 'Deleted custom fields',
                                choices: Object.values(selectedItems['VIRTUAL_DELETED']).map(choice => ({
                                    _id: choice.value,
                                    value: 'Deleted custom field',
                                })),
                            },
                        ];
                    }

                    return [];
                },
            },
            {
                id: 3,
                title: 'Tags',
                filterName: 'resourceTags',
                icon: 'LabelOutlined',
                items: filterData.resourceTags,
                withSearch: true,
                withEqualityToggle: true,
                withRelationToggle: true,
                selectedSelector: selectAppliedResourceTagsFilter,
                filterSelector: selectSchedulerResourceTagsFilter,
                searchPredicate: (item, filterText) => {
                    return filterWithDiacriticsEscape(item, filterText, 'value');
                },
                itemLabelRenderer: item => item.value,
                getItemValue: item => item._id,
                deletedItemsMapper: (items, selectedItems) => {
                    const itemsMapped = arrayToObjectByKey(items, '_id');
                    const removed = Object.keys(selectedItems).filter(item => {
                        return !itemsMapped[item];
                    });

                    return removed.map(item => ({
                        _id: item,
                        value: 'Deleted tag',
                        deleted: true,
                    }));
                },
            },
            {
                id: 4,
                title: 'Resource Statuses',
                filterName: 'resourceStatuses',
                icon: 'SettingsOutlined',
                items: filterData.resourceStatuses,
                selectedSelector: selectAppliedResourceStatusesFilter,
                withRelationToggle: true,
                withEqualityToggle: true,
                filterSelector: selectSchedulerResourceStatusesFilter,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
            {
                id: 5,
                title: 'Resource Roles',
                filterName: 'resourceRoles',
                icon: 'VerifiedUserOutlined',
                items: filterData.resourceRoles,
                selectedSelector: selectAppliedResourceRolesFilter,
                withRelationToggle: true,
                withEqualityToggle: true,
                filterSelector: selectSchedulerResourceRolesFilter,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
            {
                id: 6,
                title: 'Is project manager',
                filterName: 'resourceIsPm',
                icon: 'TuneOutlined',
                items: filterData.resourceIsPm,
                selectedSelector: selectAppliedResourceIsPmFilter,
                filterSelector: selectSchedulerResourceIsPmFilter,
                itemLabelRenderer: item => item.display,
                getItemValue: item => item.value,
            },
        ],
        [
            filterData.resourceIsPm,
            filterData.resourceRoles,
            filterData.resourceStatuses,
            filterData.resourceTags,
            resourceCustomFields,
            resources,
            shouldDisplayResourcesUW,
        ]
    );

    return (
        <div className={classes.filtersContainer}>
            <div className={classes.sideText}>
                <Typography>Resource row filters</Typography>
            </div>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
                <AccordionHeader item>
                    <AccordionHeaderText>Resource row filter</AccordionHeaderText>
                </AccordionHeader>
                <Grid item>
                    {resourceRowFiltersConfig.map(filterItem => (
                        <FilterItem key={filterItem.id} filterItem={filterItem} />
                    ))}
                </Grid>
            </Grid>
        </div>
    );
};

const memoized = React.memo(ResourcesRowFilters);

export { memoized as ResourcesRowFilters };
