import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { ListItemIcon } from '@material-ui/core';
import Link from 'shared/link';

export const Wrapper = styled.div`
    display: inline-block;
`;

export const Footer = styled.footer`
    height: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed !important;
    background-color: #333;
    text-align: center;
`;

const itemStyles = `
    padding: 14px;
    color: rgba(255, 255, 255, 0.77);
    height: 100%;
    display: inline-block;
    span {
        font-weight: 500;
    }
    svg,
    span {
        color: #fff;
    }
    &:hover {
        cursor: pointer;
        background-color: #000;
    }
`;

export const Item = styled.a`
    ${itemStyles}
`;

export const ItemLink = styled(Link)`
    ${itemStyles}
`;

export const BookingSidebarWrapper = styled.div`
    float: right;
    border-left: 1px solid #4a4a4a;
`;

export const SidebarWrapper = styled.div`
    width: 600px;
    right: -600px;
    top: 50px;
    bottom: 0;
    position: fixed;
    z-index: 999;
    background-color: #f5f5f5;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    visibility: hidden;
    &.fade-in {
        right: 0;
        visibility: visible;
    }
    .nav {
        .col {
            border-right: 1px solid #eaeaea;
            &:last-child {
                border: none;
            }
            li {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                &.active {
                    background-color: #5a5a5a;
                    color: #fff;
                    border-radius: 50%;
                }
            }
        }
    }

    @media (max-width: 991px) {
        top: 41px;
    }
`;

export const Content = styled.section`
    margin: 40px;
    text-align: left;
    display: ${props => (props.active ? 'block' : 'none')};
`;

export const MissingBooking = styled.div`
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(50% - 75px);
    span {
        display: block;
        &.title {
            font-size: 20px;
            margin-bottom: 5px;
        }
        &.desc {
            font-size: 12px;
        }
    }
    button {
        width: 200px;
        &:hover {
            cursor: default !important;
            background-color: #6c757d !important;
        }
        &:active {
            border: none !important;
        }
    }
`;

export const CloseIcon = styled(Close)`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.3);
    &:hover {
        color: rgb(0, 0, 0);
    }
`;

export const ListIcon = styled(ListItemIcon)`
    min-width: 30px;
    i {
        font-size: 20px;
    }
`;

export const LinksHelpText = styled.span`
    font-size: 12px;
`;
