import { axiosInstance } from './axiosInstance';

export const getCategoryTemplatesRequest = params =>
    axiosInstance
        .get(`/categoryTemplate/read`, { params: { showDeleted: params.showDeleted } })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createCategoryTemplateRequest = data =>
    axiosInstance
        .post(`/categoryTemplate/create`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateCategoryTemplateRequest = (categoryTemplateId, data) =>
    axiosInstance
        .put(`/categoryTemplate/update/${categoryTemplateId}`, data)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const deleteCategoryTemplateRequest = categoryTemplateId =>
    axiosInstance
        .delete(`/categoryTemplate/${categoryTemplateId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
