export const REQUEST_FOR_VACATION_APPROVAL_NOTIFICATION = 'REQUEST_FOR_VACATION_APPROVAL_NOTIFICATION';
export const REQUEST_FOR_APPROVAL_NOTIFICATION = 'REQUEST_FOR_APPROVAL_NOTIFICATION';
export const VACATION_REQUEST_APPROVED_NOTIFICATION = 'VACATION_REQUEST_APPROVED_NOTIFICATION';
export const REQUEST_APPROVED_NOTIFICATION = 'REQUEST_APPROVED_NOTIFICATION';
export const VACATION_REQUEST_REJECTED_NOTIFICATION = 'VACATION_REQUEST_REJECTED_NOTIFICATION';
export const REQUEST_REJECTED_NOTIFICATION = 'REQUEST_REJECTED_NOTIFICATION';
export const BOOKING_CREATE_NOTIFICATION = 'BOOKING_CREATE_NOTIFICATION';
export const BOOKING_UPDATE_NOTIFICATION = 'BOOKING_UPDATE_NOTIFICATION';
export const BOOKING_UPDATE_CREATOR_NOTIFICATION = 'BOOKING_UPDATE_CREATOR_NOTIFICATION';
export const BOOKING_DELETE_NOTIFICATION = 'BOOKING_DELETE_NOTIFICATION';
export const SCHEDULE_SUMMARY_NOTIFICATION = 'SCHEDULE_SUMMARY_NOTIFICATION';
export const TIMESHEET_REMINDER_NOTIFICATION = 'TIMESHEET_REMINDER_NOTIFICATION';
export const MARKETING_EMAIL_NEW_FEATURES_NOTIFICATION = 'MARKETING_EMAIL_NEW_FEATURES_NOTIFICATION';
export const CC_PROJECT_MANAGERS_ON_VACATION_REQUESTS = 'CC_PROJECT_MANAGERS_ON_VACATION_REQUESTS';
export const CC_REQUESTED_RESOURCE_ON_VACATION_REQUESTS = 'CC_REQUESTED_RESOURCE_ON_VACATION_REQUESTS';
export const CC_PROJECT_MANAGERS_ON_BOOKINGS = 'CC_PROJECT_MANAGERS_ON_BOOKINGS';
export const CC_PROJECT_MANAGERS_ON_REQUESTS = 'CC_PROJECT_MANAGERS_ON_REQUESTS';
export const REQUEST_UPDATE_CREATOR_NOTIFICATION = 'REQUEST_UPDATE_CREATOR_NOTIFICATION';

// In app tips
export const TIPS_FOR_NEW_CUSTOMERS = 'TIPS_FOR_NEW_CUSTOMERS';
export const TIPS_FOR_EXISTING_CUSTOMERS = 'TIPS_FOR_EXISTING_CUSTOMERS';

export const notificationTypes = {
    REQUEST_FOR_VACATION_APPROVAL_NOTIFICATION,
    REQUEST_FOR_APPROVAL_NOTIFICATION,
    VACATION_REQUEST_APPROVED_NOTIFICATION,
    REQUEST_APPROVED_NOTIFICATION,
    VACATION_REQUEST_REJECTED_NOTIFICATION,
    REQUEST_REJECTED_NOTIFICATION,
    BOOKING_CREATE_NOTIFICATION,
    BOOKING_UPDATE_NOTIFICATION,
    BOOKING_UPDATE_CREATOR_NOTIFICATION,
    BOOKING_DELETE_NOTIFICATION,
    SCHEDULE_SUMMARY_NOTIFICATION,
    TIMESHEET_REMINDER_NOTIFICATION,
    MARKETING_EMAIL_NEW_FEATURES_NOTIFICATION,
    TIPS_FOR_NEW_CUSTOMERS,
    TIPS_FOR_EXISTING_CUSTOMERS,
    CC_PROJECT_MANAGERS_ON_VACATION_REQUESTS,
    CC_REQUESTED_RESOURCE_ON_VACATION_REQUESTS,
    CC_PROJECT_MANAGERS_ON_REQUESTS,
    CC_PROJECT_MANAGERS_ON_BOOKINGS,
    REQUEST_UPDATE_CREATOR_NOTIFICATION,
};

export const settingsNotifications = [
    REQUEST_FOR_VACATION_APPROVAL_NOTIFICATION,
    REQUEST_FOR_APPROVAL_NOTIFICATION,
    REQUEST_APPROVED_NOTIFICATION,
    REQUEST_REJECTED_NOTIFICATION,
    VACATION_REQUEST_APPROVED_NOTIFICATION,
    VACATION_REQUEST_REJECTED_NOTIFICATION,
    BOOKING_CREATE_NOTIFICATION,
    BOOKING_UPDATE_NOTIFICATION,
    BOOKING_UPDATE_CREATOR_NOTIFICATION,
    BOOKING_DELETE_NOTIFICATION,
    SCHEDULE_SUMMARY_NOTIFICATION,
    TIMESHEET_REMINDER_NOTIFICATION,
    MARKETING_EMAIL_NEW_FEATURES_NOTIFICATION,
    CC_PROJECT_MANAGERS_ON_VACATION_REQUESTS,
    CC_REQUESTED_RESOURCE_ON_VACATION_REQUESTS,
    CC_PROJECT_MANAGERS_ON_REQUESTS,
    CC_PROJECT_MANAGERS_ON_BOOKINGS,
    REQUEST_UPDATE_CREATOR_NOTIFICATION,
];
