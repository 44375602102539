import React from 'react';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from 'reactstrap';

// render material-ui components for redux-form

export const renderTextField = ({ label, input, meta: { touched, invalid, error }, ...custom }) => (
    <TextField
        label={label}
        placeholder={label}
        error={touched && invalid}
        helperText={touched && error}
        {...input}
        {...custom}
    />
);

export const renderCheckbox = ({ input, label, meta: { touched, error }, ...custom }) => (
    <div>
        <FormControlLabel
            control={<Checkbox checked={!!input.value} onChange={input.onChange} {...custom} />}
            label={label}
        />

        {touched && error && <Alert color="warning">{error}</Alert>}
    </div>
);
