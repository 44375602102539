import { useSelector } from 'react-redux';
import { forEach } from 'lodash';
import moize from 'moize';
import { hasRole, hasAllRoles, hasOneOfRoles } from 'utils/rightsUtil';

const getFuncByRole = rule => {
    switch (rule) {
        case 'one':
            return (resourceRoleRights, rights) => hasRole(resourceRoleRights, rights[0]);
        case 'oneOf':
            return (resourceRoleRights, rights) => hasOneOfRoles(resourceRoleRights, rights);
        case 'all':
            return (resourceRoleRights, rights) => hasAllRoles(resourceRoleRights, rights);
        default:
            return (resourceRoleRights, rights) => hasRole(resourceRoleRights, rights[0]);
    }
};

export const hasRights = moize(
    (rightsToCheck2, resourceRoleRights) => {
        const rights = {};

        forEach(rightsToCheck2, rightToCheck => {
            const func = getFuncByRole(rightToCheck.rule);
            rights[rightToCheck.name] = func(resourceRoleRights, rightToCheck.rights);
        });

        return rights;
    },
    { maxSize: 2 }
);

/**
 * @returns {object}
 */
export const useHasRights = rightsToCheck => {
    const resourceRoleRights = useSelector(state => state.account.resourceRoleRights);

    return hasRights(rightsToCheck, resourceRoleRights);
};
