import { each, filter } from 'underscore';
import moize from 'moize';

class BaseMenu {
    getMenu() {
        let memoized = moize(this.items.bind(this), { maxSize: 2 });

        return this.filterMenuItems(memoized());
    }

    filterMenuItems(menu) {
        let currentMenu = filter(
            menu,
            item => !item.hasOwnProperty('display') || (item.hasOwnProperty('display') && item.display)
        );
        each(currentMenu, item => {
            if (item && item.content && 0 < item.content.length) {
                item.content = this.filterMenuItems(item.content);
            }
        });

        return currentMenu;
    }

    items() {}

    setProps() {}
}

export default BaseMenu;
