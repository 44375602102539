import { useEffect, useState } from 'react';
import { endOfYear, formatDate, startOfYear, DATE_FORMATS } from '@hub-mono/utils';
import { getRequestData } from '../../api/report';

export const useVacationStatistics = ({ resource }) => {
    const [statistics, setStatistics] = useState();

    useEffect(() => {
        let mounted = true;

        if (resource?._id) {
            const start = startOfYear(new Date());
            const end = endOfYear(new Date());

            getRequestData({
                type: 'vacation',
                resourceId: resource._id,
                start: formatDate(start, DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
                end: formatDate(end, DATE_FORMATS.YEAR_MONTH_DAY_FORMAT),
            }).then(response => {
                if (mounted) {
                    setStatistics(response);
                }
            });
        }

        return () => {
            mounted = false;
        };
    }, [resource._id]);

    return statistics;
};
