import { action } from 'actions/base';
import * as actionTypes from './actionTypes';

export const setActiveTab = tabId => action(actionTypes.SET_TAB, { tabId });
export const setGroup = ({ group, useSchedulerAppliedFilters, items, groups, customFields, itemType }) =>
    action(actionTypes.SET_GROUP, { group, useSchedulerAppliedFilters, items, groups, customFields, itemType });
export const updateFilters = (filters, options) => action(actionTypes.UPDATE_FILTERS, { filters, options });
export const setTitle = title => action(actionTypes.SET_TITLE, { title });
export const setDates = dateData => action(actionTypes.SET_DATES, { dateData });
export const setItemType = itemType => action(actionTypes.SET_ITEM_TYPE, { itemType });
export const setGroupType = groupType => action(actionTypes.SET_GROUP_TYPE, { groupType });
export const setIsSmart = isSmart => action(actionTypes.SET_IS_SMART, { isSmart });
export const setFilterOperator = (name, operator) => action(actionTypes.SET_FILTER_OPERATOR, { name, operator });
export const setFilterRelation = filterRelation => action(actionTypes.SET_FILTER_RELATION, { filterRelation });
export const addItem = item => action(actionTypes.ADD_ITEM, { item });
export const addManyItems = items => action(actionTypes.ADD_MANY_ITEMS, { items });
export const removeManyItems = items => action(actionTypes.REMOVE_MANY_ITEMS, { items });
export const removeItem = item => action(actionTypes.REMOVE_ITEM, { item });
export const addGroup = group => action(actionTypes.ADD_GROUP, { group });
export const addManyGroups = groups => action(actionTypes.ADD_MANY_GROUPS, { groups });
export const removeGroup = group => action(actionTypes.REMOVE_GROUP, { group });

export const initializeV2 = {
    request: ({ itemTypeName, groupId, groupType, isFilterContext, initialTab, parentGroupId }) =>
        action(actionTypes.INITIALIZE_V2['REQUEST'], {
            itemTypeName,
            groupId,
            groupType,
            isFilterContext,
            initialTab,
            parentGroupId,
        }),
    success: () => action(actionTypes.INITIALIZE_V2['SUCCESS']),
};

export const preSetData = ({ filters, initialTab, itemType, parentGroupId }) =>
    action(actionTypes.PRE_SET_DATA, { filters, initialTab, itemType, parentGroupId });

export const initWithGroup = ({
    group,
    useSchedulerAppliedFilters,
    filterRelation,
    schedulerFilters,
    groupFilters,
    items,
    groups,
    itemType,
    groupType,
}) =>
    action(actionTypes.INITIALIZE_V2_WITH_GROUP, {
        group,
        useSchedulerAppliedFilters,
        filterRelation,
        schedulerFilters,
        groupFilters,
        items,
        groups,
        itemType,
        groupType,
    });

export const initWithoutGroup = ({
    itemType,
    useSchedulerAppliedFilters,
    schedulerFilters,
    title,
    isSmart,
    groupType,
    filterRelation,
    initialGroups,
    selectedGroups,
}) =>
    action(actionTypes.INITIALIZE_V2_WITHOUT_GROUP, {
        itemType,
        useSchedulerAppliedFilters,
        schedulerFilters,
        title,
        isSmart,
        groupType,
        filterRelation,
        initialGroups,
        selectedGroups,
    });

export const resetBuilder = () => action(actionTypes.RESET_BUILDER);
