import { fromJS } from 'immutable';
import * as actionTypes from '../actions/actionTypes';

const initialFilterState = {
    textFilter: '',
};

export default function filterReducer(state = fromJS(initialFilterState), action) {
    switch (action.type) {
        case actionTypes.CHANGE_TEXT_FILTER:
            return state.update('textFilter', () => action.payload.text);
        default:
            return state;
    }
}
