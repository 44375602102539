import React from 'react';
import { contains } from 'underscore';
import Sorting from 'modules/report/containers/tableSorting/sorting';
import NumbersFilterBox from 'modules/report/containers/tableFilters/numbersFilterBox';
import StatusFilterBox from 'modules/report/containers/tableFilters/statusFilterBox';
import { Header, Status } from './../styles';
import {
    AVAILABILITY_STATUSES_ENUM,
    statusesList as availabilityStatusesList,
} from 'modules/report/enums/availabilityStatusEnum';
import {
    UTILIZATION_STATUSES_ENUM,
    statusesList as utilizationStatusesList,
} from 'modules/report/enums/utilizationStatusEnum';
import * as unitTypes from 'modules/report/enums/reportUnitTypeEnum';
import { formatNumber } from 'utils/formatingUtil';
import {
    displayDateState,
    displayStatusByType,
    displayValue,
    isHeaderDisabled,
    getColumnProps,
} from 'modules/report/utils/columnHelper';
import { DATE_STATE } from 'modules/report/enums/dateStateEnum';
import ProgressBarCell from 'modules/report/components/tableCells/progressBarCell';

/**
 * @param {object}         dateState
 * @param {string}         unit
 * @param {string, number} avgDailyCapacity
 * @param {array}          columnsToLoad
 *
 * @returns {*[]}
 */
export const columns = (dateState, unit, avgDailyCapacity, columnsToLoad) => [
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Utilization
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'scheduledUtilizationBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationBetween'),
        accessor: row =>
            displayValue(row.scheduledUtilizationBetween, formatNumber(row.scheduledUtilizationBetween || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Utilization
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'scheduledUtilizationPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationPrevious'),
        accessor: row =>
            displayValue(row.scheduledUtilizationPrevious, formatNumber(row.scheduledUtilizationPrevious || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Utilization
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} disableUnits={true} />
                </Header>
            );
        },
        id: 'scheduledUtilizationAfter',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationAfter'),
        accessor: row =>
            displayValue(row.scheduledUtilizationAfter, formatNumber(row.scheduledUtilizationAfter || 0) + '%'),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? row.value : <ProgressBarCell value={row.value} />,
        getProps: (state, rowInfo, column) => getColumnProps(state, rowInfo, column, { display: 'block' }),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledAvailabilityBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityBetween'),
        accessor: row =>
            displayValue(
                row.scheduledAvailabilityBetween,
                unitTypes[unit].calculation(row.scheduledAvailabilityBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledAvailabilityPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityPrevious'),
        accessor: row =>
            displayValue(
                row.scheduledAvailabilityPrevious,
                unitTypes[unit].calculation(row.scheduledAvailabilityPrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledAvailabilityAfter',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityAfter'),
        accessor: row =>
            displayValue(
                row.scheduledAvailabilityAfter,
                unitTypes[unit].calculation(row.scheduledAvailabilityAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Overtime
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledOvertimeBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledOvertimeBetween'),
        accessor: row =>
            displayValue(
                row.scheduledOvertimeBetween,
                unitTypes[unit].calculation(row.scheduledOvertimeBetween, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Overtime
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledOvertimePrevious',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledOvertimePrevious'),
        accessor: row =>
            displayValue(
                row.scheduledOvertimePrevious,
                unitTypes[unit].calculation(row.scheduledOvertimePrevious, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Overtime
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledOvertimeAfter',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledOvertimeAfter'),
        accessor: row =>
            displayValue(
                row.scheduledOvertimeAfter,
                unitTypes[unit].calculation(row.scheduledOvertimeAfter, avgDailyCapacity)
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability Status
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledAvailabilityStatusBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityStatusBetween'),
        accessor: row => displayValue(row.scheduledAvailabilityStatusBetween),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledAvailabilityStatusPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityStatusPrevious'),
        accessor: row => displayValue(row.scheduledAvailabilityStatusPrevious),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Forecasted Availability Status
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={availabilityStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledAvailabilityStatusAfter',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledAvailabilityStatusAfter'),
        accessor: row => displayValue(row.scheduledAvailabilityStatusAfter),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={AVAILABILITY_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, AVAILABILITY_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(scheduled)</p>
                        <p className="sub-title">{displayDateState(dateState)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledUtilizationStatusBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationStatusBetween'),
        accessor: row => displayValue(row.scheduledUtilizationStatusBetween),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(scheduled)</p>
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.LAST)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledUtilizationStatusPrevious',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationStatusPrevious'),
        accessor: row => displayValue(row.scheduledUtilizationStatusPrevious),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Utilization Status
                        <p className="sub-title">(scheduled)</p>
                        <p className="sub-title">{displayDateState(dateState, DATE_STATE.NEXT)}</p>
                    </Sorting>
                    <StatusFilterBox
                        fields={{ label: 'name', value: 'name' }}
                        disabled={isDisabled}
                        columnName={props.column.id}
                        data={utilizationStatusesList()}
                    />
                </Header>
            );
        },
        id: 'scheduledUtilizationStatusAfter',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledUtilizationStatusAfter'),
        accessor: row => displayValue(row.scheduledUtilizationStatusAfter),
        Cell: row =>
            row.columnProps && row.columnProps.rest.disabled ? (
                row.value
            ) : (
                <Status value={row.value} enum={UTILIZATION_STATUSES_ENUM}>
                    <span className="status-circle" />
                    <span className="status-text">{displayStatusByType(row.value, UTILIZATION_STATUSES_ENUM)}</span>
                </Status>
            ),
    },
    {
        Header: props => {
            const isDisabled = isHeaderDisabled(props.data[0], props.column.id);

            return (
                <Header>
                    <Sorting disabled={isDisabled} columnName={props.column.id}>
                        Scheduled FTE
                        <p className="sub-title">{displayDateState(dateState, false, false)}</p>
                    </Sorting>
                    <NumbersFilterBox disabled={isDisabled} columnName={props.column.id} />
                </Header>
            );
        },
        id: 'scheduledFTEUtilizationBetween',
        sortable: false,
        show: contains(columnsToLoad, 'scheduledFTEUtilizationBetween'),
        accessor: row =>
            displayValue(row.scheduledFTEUtilizationBetween, formatNumber(row.scheduledFTEUtilizationBetween || 0)),
    },
];
