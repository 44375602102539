import axios from 'axios';
import { axiosInstance } from './axiosInstance';

export const getClientTokenRequest = (customerId, merchantAccountId) => {
    return axiosInstance
        .get('billing/clientToken', {
            params: { customerId, merchantAccountId },
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};

export const getBillingPlansRequest = currency =>
    axiosInstance
        .get(`billingPlanTemplateRoute/read/${currency}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getBillingExtensionsRequest = currency =>
    axiosInstance
        .get(`extensionTemplateRoute/read/${currency}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getBillingViewRequest = () =>
    axiosInstance
        .get('views/getBillingView')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const createCustomerRequest = body =>
    axiosInstance
        .post('billing/createBTCustomer', body)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const getPaymentMethodNonce = () =>
    axiosInstance
        .get('billing/generatePaymentMethodNonce')
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const createSubscriptionRequest = (paymentNonce, body) =>
    axiosInstance
        .post(`billing/createSubscription/${paymentNonce}`, body)
        .then(response => response.data)
        .catch(error => {
            throw error.response;
        });

export const deleteSubscriptionRequest = password =>
    axiosInstance
        .post('billing/deleteCustomer', { password })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateSubscriptionRequest = body =>
    axiosInstance
        .post('billing/updateSubscription', body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const updateCustomerRequest = body =>
    axiosInstance
        .post('billing/updateCustomer', body)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const getPaymentInformationRequest = () =>
    axiosInstance
        .post('billing/getCustomerPaymentInformation')
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

export const vatIDValidationRequest = vatId => {
    const auth = 'c772bcbc493c19aa828ba3e7ef95e244';

    return axios
        .get(`https://www.apilayer.net/api/validate?access_key=${auth}&vat_number=${vatId}&format=1`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};
