import { useSelector } from 'react-redux';
import { isSchedulerView } from 'utils/isSchedulerView';
import { isReportsView } from 'utils/isReportsView';
import { useMemo } from 'react';
import { isModalView } from '../utils/isModalView';

export const useModalOffset = (additionalClassNames = '', fullScreen = false) => {
    const isFullScreen = useSelector(state => state.initialAppReducer.isFullScreen);
    const offsetClassNames = useMemo(() => {
        if ((fullScreen || isFullScreen) && (isReportsView() || isSchedulerView() || isModalView())) {
            return '';
        }

        return `${additionalClassNames} offset-sm-3 offset-md-4 offset-lg-3 offset-xl-2`;
    }, [fullScreen, isFullScreen, additionalClassNames]);

    return offsetClassNames;
};
