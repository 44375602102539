import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup, Label, Input } from 'reactstrap';
import { isUndefined } from 'underscore';
import InfoTooltip from 'shared/infoTooltip';
import IndeterminateCheckboxField from './../indeterminateCheckboxField';
import { makeStyles } from '@material-ui/core/styles';

const ChooseField = props => {
    const {
        input,
        label,
        inline,
        className,
        labelClassName,
        wrapperClass,
        type,
        meta,
        description,
        disabled,
        labelMuted,
        ...restOfProps
    } = props;

    const showError = meta?.touched && !isUndefined(meta?.error);

    const classes = useStyles();

    return (
        <>
            <FormGroup
                check
                inline={inline}
                className={classNames(wrapperClass, 'form-group-checkbox', { 'is-invalid': showError })}
            >
                <Label check className={classNames('d-flex align-items-center', labelClassName)}>
                    {'checkbox' === type ? (
                        <IndeterminateCheckboxField
                            input={input}
                            name={input.name}
                            invalid={showError}
                            className={classNames('mr-2 mt-0', classes.input, className)}
                            disabled={disabled}
                            {...restOfProps}
                        />
                    ) : (
                        <Input
                            {...input}
                            type={type}
                            name={input.name}
                            invalid={showError}
                            className={classNames('mr-2 mt-0', classes.input, className)}
                            disabled={disabled}
                            {...restOfProps}
                        />
                    )}{' '}
                    <span
                        className={classNames('form-check-label-text align-middle', {
                            'text-muted': disabled || labelMuted,
                        })}
                    >
                    {label}
                </span>
                </Label>
                {description && <InfoTooltip description={description} />}
            </FormGroup>
            {showError && (
                <div className="invalid-feedback text-left">
                    <span>{meta.error}</span>
                </div>
            )}
        </>

    );
};

ChooseField.propTypes = {
    input: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    description: PropTypes.string,
    indeterminate: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    type: PropTypes.oneOf(['checkbox', 'radio']),
    label: PropTypes.string,
    wrapperClass: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    }),
};

ChooseField.defaultProps = {
    input: {},
    disabled: false,
    inline: false,
    type: 'checkbox',
    indeterminate: 'false',
    label: '',
    labelClassName: '',
    description: '',
    wrapperClass: '',
    className: '',
    meta: {},
};

const useStyles = makeStyles({
    input: {
        cursor: 'pointer',
    },
});

export default React.memo(ChooseField);
