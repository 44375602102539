import * as actionTypes from '../actions/actionTypes';

const initialContextMenuState = {
    contextMenuType: null,
    additionalProps: {},
};

export default function contextMenuReducer(state = initialContextMenuState, action) {
    switch (action.type) {
        case actionTypes.SHOW_CONTEXT_MENU:
            return {
                contextMenuType: action.contextMenuType,
                additionalProps: {
                    ...action.payload,
                },
            };
        case actionTypes.HIDE_CONTEXT_MENU:
            return initialContextMenuState;
        default:
            return state;
    }
}
