import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useQueryParams } from '../../../utils/useQueryParams';
import React, { useEffect } from 'react';
import { showResourceModal } from '../../../actions/modalActions';
import { createResource, updateResource } from '../../../actions/resourceActions';
import PermissionDenied from '../../scheduler/components/permissionDenied/permissionDenied';
import authorization from '../../../services/auth/authorization';

const ResourceModalRoute = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const queryParams = useQueryParams();

    useEffect(() => {
        dispatch(
            showResourceModal({
                onSubmit: data =>
                    params.resourceId
                        ? dispatch(
                              updateResource.request(params.resourceId, data, () => {
                                  if (queryParams.successUrl) {
                                      window.location.href = queryParams.successUrl;
                                  } else {
                                      window.location.href = '/';
                                  }
                              })
                          )
                        : dispatch(createResource.request(data, undefined, true)),
                resourceId: params.resourceId,
                type: params.type,
                onClose: () => {
                    if (queryParams.fallbackUrl) {
                        window.location.href = queryParams.fallbackUrl;
                    } else {
                        window.location.href = '/';
                    }
                },
            })
        );
    }, [dispatch, params.resourceId, params.type, queryParams.fallbackUrl, queryParams.successUrl]);

    return <></>;
};

const authParameters = {
    allowedRoleRights: ['settingAddEditResources'],
    redirectOnLackOfRights: true,
    errorComponent: PermissionDenied,
    routeParamsChecker: (params, data) => {
        let isAvailable = true;

        if (params.resourceId && !data.isResourcesLoading && data.resources.length) {
            isAvailable = data.resources.some(
                resource => resource._id === params.resourceId && resource.hasRightsToResource
            );
        }

        return isAvailable;
    },
};

export const ResourceModalRouteAuth = authorization(authParameters)(ResourceModalRoute);
