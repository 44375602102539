import React from 'react';
import PropTypes from 'prop-types';
import { getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import { head } from 'lodash';
import { TabPane } from 'reactstrap';
import { FORM_NAME } from './modal';
import TasksList from 'shared/tasksList';
import useSchedulerRef from '../../../../shared/hooks/useSchedulerRef';

const TaskTab = ({ tabId, bookingId, onFieldChange }) => {
    const { schedulerRef } = useSchedulerRef();
    const { resources, project, tasks } = useSelector(getFormValues(FORM_NAME));

    return (
        <TabPane tabId={tabId}>
            <TasksList
                bookingId={bookingId}
                onFieldChange={onFieldChange}
                resource={head(resources)}
                project={project}
                initialTasks={tasks}
                schedulerRef={schedulerRef}
            />
        </TabPane>
    );
};

TaskTab.propTypes = {
    tabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    bookingId: PropTypes.string,
};

TaskTab.defaultProps = {
    bookingId: '',
};

export default TaskTab;
